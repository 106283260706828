import axios from 'axios';
import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import BrokerAdmin, { AccessibleEmployers } from 'model/BrokerAdmin';
import * as BrokerAdminListService from 'modules/admins/services/AdminService';
import PaginationConfig from 'model/PaginationConfig';
import MetaData from 'model/MetaData';
import ErrorDetails from 'model/ErrorDetails';
import { STATUS_202 } from 'constants/commonConstants';

export interface BrokerAdminListState {
  inProgress: boolean;
  error: any;
  requestType: string;
  brokerAdmins: { content: Array<BrokerAdmin>; metadata: MetaData };
  brokerAdminDetails: BrokerAdmin;
  removeMemberInProgress: boolean;
  removeMemberCompleted: boolean;
  addMemberInProgress: boolean;
  addMemberCompleted: boolean;
  removeMemberError: ErrorDetails | null;
  teamUpdateError: ErrorDetails | null;
}

const initialState = {
  inProgress: false,
  error: null,
  removeMemberError: null,
  requestType: '',
  brokerAdmins: { content: [], metadata: {} },
  brokerAdminDetails: {} as BrokerAdmin,
  removeMemberInProgress: false,
  removeMemberCompleted: false,
  addMemberInProgress: false,
  addMemberCompleted: false,
  teamUpdateError: null,
} as BrokerAdminListState;

const brokerAdminListSlice = createSlice({
  name: 'brokerAdmins',
  initialState,
  reducers: {
    getBrokerAdminsInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.brokerAdmins = { content: [], metadata: {} };
      state.error = null;
      state.requestType = action.type;
    },
    getBrokerAdminsCompleted: (
      state,
      action: PayloadAction<{ content: Array<BrokerAdmin>; metadata: {} }>
    ) => {
      state.inProgress = false;
      state.brokerAdmins = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getBrokerAdminsFailed: (state, action) => {
      state.inProgress = false;
      state.brokerAdmins = { content: [], metadata: {} };
      state.error = { response: action.payload };
      state.requestType = action.type;
    },
    getBrokerAdminDetailsInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.brokerAdminDetails = {} as BrokerAdmin;
      state.error = null;
      state.requestType = action.type;
    },
    getBrokerAdminDetailsSuccess: (
      state,
      action: PayloadAction<BrokerAdmin>
    ) => {
      state.inProgress = false;
      state.brokerAdminDetails = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getBrokerAdminDetailsFailed: (state, action) => {
      state.inProgress = false;
      state.brokerAdminDetails = {} as BrokerAdmin;
      state.error = { response: action.payload };
      state.requestType = action.type;
    },
    addTeamMemberInProgress: (state) => {
      state.addMemberInProgress = true;
      state.teamUpdateError = null;
    },
    addTeamMemberSuccess: (state, action) => {
      state.addMemberInProgress = false;
      state.addMemberCompleted = action.payload === STATUS_202;
      state.teamUpdateError = null;
    },
    addTeamMemberFailed: (state, action) => {
      state.addMemberInProgress = false;
      state.addMemberCompleted = true;
      state.teamUpdateError = action.payload;
    },
    removeTeamMemberInProgress: (state) => {
      state.removeMemberInProgress = true;
    },
    removeTeamMemberSuccess: (state) => {
      state.removeMemberInProgress = false;
      state.removeMemberCompleted = true;
      state.removeMemberError = null;
    },
    removeTeamMemberFailed: (state, action) => {
      state.removeMemberInProgress = false;
      state.removeMemberCompleted = false;
      state.removeMemberError = action.payload;
    },
    clearManagingTeamMember: (state) => {
      state.addMemberCompleted = false;
      state.removeMemberInProgress = false;
      state.removeMemberCompleted = false;
      state.removeMemberError = null;
    },
    setBrokerAdminDetailsLocal: (
      state,
      action: PayloadAction<AccessibleEmployers[]>
    ) => {
      state.brokerAdminDetails.accessibleEmployers = action.payload;
    },
  },
});

export const {
  getBrokerAdminsInProgress,
  getBrokerAdminsCompleted,
  getBrokerAdminsFailed,
  getBrokerAdminDetailsInProgress,
  getBrokerAdminDetailsSuccess,
  setBrokerAdminDetailsLocal,
  getBrokerAdminDetailsFailed,
  addTeamMemberInProgress,
  addTeamMemberSuccess,
  addTeamMemberFailed,
  removeTeamMemberInProgress,
  removeTeamMemberSuccess,
  removeTeamMemberFailed,
  clearManagingTeamMember,
} = brokerAdminListSlice.actions;

export default brokerAdminListSlice.reducer;

export const getBrokerAdmins = (
  { page, size, sort, query }: PaginationConfig,
  organizationId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getBrokerAdminsInProgress());
    try {
      const response = await BrokerAdminListService.getBrokerAdmins(
        page,
        size,
        sort,
        query,
        organizationId
      );
      const data = response.data;
      dispatch(getBrokerAdminsCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getBrokerAdminsFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const getBrokerAdminDetails = (teamMemberId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getBrokerAdminDetailsInProgress());
    try {
      const response = await BrokerAdminListService.getBrokerAdminDetails(
        teamMemberId
      );
      const data = response.data;
      dispatch(getBrokerAdminDetailsSuccess(data));
    } catch (error) {
      dispatch(getBrokerAdminDetailsFailed(error));
    }
  };
};

export const addTeamMember = (employerId: string, teamMemberId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(addTeamMemberInProgress());
    try {
      const response = await BrokerAdminListService.addTeamMember(
        employerId,
        teamMemberId
      );
      dispatch(addTeamMemberSuccess(response.status));
    } catch (error: any) {
      dispatch(addTeamMemberFailed(error.response));
    }
  };
};

export const removeTeamMember = (employerId: string, teamMemberId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(removeTeamMemberInProgress());
    try {
      await BrokerAdminListService.removeTeamMember(employerId, teamMemberId);
      dispatch(removeTeamMemberSuccess());
    } catch (error: any) {
      dispatch(removeTeamMemberFailed(error.response));
      dispatch(removeTeamMemberSuccess());
    }
  };
};

export const updateBrokerAdminDetails =
  (data: AccessibleEmployers[]) => (dispatch: Dispatch) => {
    dispatch(setBrokerAdminDetailsLocal(data));
  };
