import React, { ChangeEvent, useEffect, useState } from 'react';
import { Row, Table, Col } from 'antd';
import { useParams } from 'react-router-dom';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import SearchBar from 'components/SearchBar/SearchBar';
import SelectedCarrierContactsTable from 'modules/employers/components/SelectedCarrierContactsTable/SelectedCarrierContactsTable';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import rightArrow from 'assets/images/rightArrow.svg';
import {
  useLazyGetCarrierContactsByOrganizationIdQuery,
  useUpdateCarrierContactVisibilityStatusMutation,
  useUpdateSelectedCarrierContactsMutation,
} from 'modules/renewals/slices/carrierContactsSlice';
import CheckboxSelect from 'components/CheckboxSelect/CheckboxSelect';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import styles from './EditCarrierContacts.module.less';

type Props = {
  closeModal: () => void;
  isCheckedByDefault?: boolean;
  refetchCarrierContacts?: () => void;
  selectedSortedCarrierContacts?: any[];
};

const EditCarrierContacts = (props: Props) => {
  const [searchText, setSearchText] = useState('');
  const [remainingCarrierContacts, setRemainingCarrierContacts] = useState<
    any[]
  >([]);
  const [selectedCarrierContacts, setSelectedCarrierContacts] = useState<any[]>(
    []
  );
  const {
    isCheckedByDefault,
    closeModal,
    refetchCarrierContacts,
    selectedSortedCarrierContacts,
  } = props;
  const [isCarrierContactsVisible, setIsCarrierContactsVisible] =
    useState<boolean>(true);
  const { brokerId, employerId } = useParams();

  const [
    getCarrierContactsByOrgId,
    {
      data: carrierContactsByOrgId,
      isSuccess: isGetCarrierContactsByOrgIdSuccess,
      isFetching: isGetCarrierContactsByOrgIdFetching,
      isLoading: isGetCarrierContactsByOrgIdLoading,
    },
  ] = useLazyGetCarrierContactsByOrganizationIdQuery();

  const [
    updateCarrierContactsVisibility,
    { isLoading: isCarrierContactUpdateVisibilityRequestLoading },
  ] = useUpdateCarrierContactVisibilityStatusMutation();

  const [
    updateSelectedCarrierContactsByEmployerId,
    { isLoading: isCarrierContactUpdateRequestLoading },
  ] = useUpdateSelectedCarrierContactsMutation();

  useEffect(() => {
    getCarrierContactsByOrgId({ organizationId: String(brokerId) });
    // eslint-disable-next-line
  }, [brokerId]);

  useEffect(() => {
    setIsCarrierContactsVisible(isCheckedByDefault ?? true);
  }, [isCheckedByDefault]);

  useEffect(() => {
    if (isGetCarrierContactsByOrgIdSuccess && employerId) {
      if (carrierContactsByOrgId) {
        const { remaining } = filterCarrierContactsByEmployerId(
          carrierContactsByOrgId,
          employerId
        );
        const selectedMapped = selectedSortedCarrierContacts?.map((x, index) =>
          mapToSelectedCarrierContact(x, index)
        );
        const remainingMapped = remaining.map((x) =>
          mapToRemainingCarrierContact(x)
        );

        setRemainingCarrierContacts(remainingMapped);
        selectedMapped && setSelectedCarrierContacts(selectedMapped);
      }
    }
    // eslint-disable-next-line
  }, [
    isGetCarrierContactsByOrgIdSuccess,
    employerId,
    isGetCarrierContactsByOrgIdFetching,
    isGetCarrierContactsByOrgIdLoading,
    selectedSortedCarrierContacts,
  ]);

  const filterCarrierContactsByEmployerId = (
    allCarrierContacts: any[],
    employerId: string
  ) => {
    const selected = [];
    const remaining = [];

    for (const contact of allCarrierContacts) {
      if (contact.employersIds.includes(employerId)) {
        selected.push(contact);
      } else {
        remaining.push(contact);
      }
    }

    return { selected, remaining };
  };

  const handleManageCarrierContactsClick = () => {
    window.open(
      `/brokers/${brokerId}/carriers?carrier-contacts=true`,
      '_blank'
    );
  };

  const mapToSelectedCarrierContact = (carrierVo: any, index: number) => {
    return {
      phoneNumber: carrierVo.phone,
      fullName: carrierVo.fullName,
      associatedCarrierName: carrierVo.associatedCarrierName,
      title: carrierVo.title,
      email: carrierVo.email,
      id: carrierVo.id,
      index: index,
    };
  };

  const mapToRemainingCarrierContact = (carrierVo: any) => {
    return {
      phoneNumber: carrierVo.phone,
      fullName: carrierVo.fullName,
      associatedCarrierName: carrierVo.associatedCarrierName,
      title: carrierVo.title,
      email: carrierVo.email,
      id: carrierVo.id,
    };
  };

  const getSelectedCarrierContactIdList = async (
    selectedContactsArray: any[]
  ) => {
    const carrierContactIdList = selectedContactsArray.map((x) => x.id);
    await updateSelectedCarrierContactsByEmployerId({
      employerId: String(employerId),
      carrierContacts: carrierContactIdList,
    }).unwrap();
  };

  const handleSave = async () => {
    await getSelectedCarrierContactIdList(selectedCarrierContacts);
    if (isCheckedByDefault !== isCarrierContactsVisible) {
      await updateCarrierContactsVisibility({
        employerId: String(employerId),
        isCarrierContactsVisible: isCarrierContactsVisible,
      }).unwrap();
    }
    setRemainingCarrierContacts([]);
    setSelectedCarrierContacts([]);
    refetchCarrierContacts?.();
    closeModal();
  };

  const updateRemainingCarrierContacts = (contact: any) => {
    contact.isSelected = !contact.isSelected;
    const updatedArray = remainingCarrierContacts.filter(
      (remainingContact) => remainingContact.id !== contact.id
    );
    setRemainingCarrierContacts(updatedArray);
    const newSelectedContact = selectedCarrierContactTransformer(contact);
    const indexUpdatedArray = [...selectedCarrierContacts, newSelectedContact];
    for (let i = 0; i < indexUpdatedArray.length; i++) {
      indexUpdatedArray[i].index = i;
    }
    setSelectedCarrierContacts(indexUpdatedArray);
  };

  const arrowSign = () => (
    <img src={rightArrow} alt="move-icon" className={styles.arrowIcon} />
  );
  const selectedCarrierContactTransformer = (contact: any) => {
    const newSelectedContact = {
      phoneNumber: contact.phone,
      fullName: contact.fullName,
      associatedCarrierName: contact.associatedCarrierName,
      title: contact.title,
      email: contact.email,
      id: contact.id,
      index: selectedCarrierContacts.length ?? 1,
    };
    return newSelectedContact;
  };

  const updateSelectedCarrierContacts = (contact: any) => {
    contact.isSelected = !contact.isSelected;
    const updatedArray = selectedCarrierContacts.filter(
      (selectedContact) => selectedContact.id !== contact.id
    );
    setSelectedCarrierContacts(updatedArray);
    const newRemainingContact = remainingCarrierContactsTransformer(contact);
    setRemainingCarrierContacts([
      ...remainingCarrierContacts,
      newRemainingContact,
    ]);
  };

  const remainingCarrierContactsTransformer = (contact: any) => {
    const newSelectedContact = {
      phoneNumber: contact.phone,
      fullName: contact.fullName,
      associatedCarrierName: contact.associatedCarrierName,
      title: contact.title,
      email: contact.email,
      id: contact.id,
      index: null,
    };
    return newSelectedContact;
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = e.target;
    const _searchText = value.trim().toLowerCase();

    setSearchText(_searchText);
  };

  const searchCarrierContacts = (_searchText: string, data: any[]): any[] => {
    const searchWorkArray = _searchText.trim().toLowerCase();
    const filteredMembers = data?.filter(
      (tm) =>
        tm.fullName?.toLowerCase().includes(searchWorkArray) ||
        tm.associatedCarrierName?.toLowerCase().includes(searchWorkArray)
    );

    return filteredMembers;
  };

  const toggleCarrierContactsVisibility = (obj: any) => {
    setIsCarrierContactsVisible(obj.target.checked);
  };

  const updateSelectedCarrierContactOrder = (updatedListWithOrder: any[]) => {
    setSelectedCarrierContacts(updatedListWithOrder);
  };

  const teamMembersColumns = [
    {
      title: 'Broker Admin Name',
      dataIndex: 'id',
      key: 'id',
      width: '50%',
      render: (_: any, record: any) => {
        return (
          <div
            className={styles.carrierContactText}
            onClick={() => {
              updateRemainingCarrierContacts(record);
            }}
          >
            <OverflowPopover maxWidth="200px">
              {record.fullName}
            </OverflowPopover>

            <div className={styles.carrierContactCarrierText}>
              <OverflowPopover maxWidth="200px">
                {` - ${record.associatedCarrierName}`}
              </OverflowPopover>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className={styles.tableContainerWrapper}>
      <Row className={styles.transferWrapper}>
        <Col className={styles.allCarrierTableWrapper}>
          <h2 className={styles.allTitle}>
            All Carrier Contacts ({remainingCarrierContacts.length})
          </h2>
          <div className={styles.searchBarWrapper}>
            <SearchBar
              placeholder="Search to filter contacts or carrier"
              isLarge={false}
              onChange={handleSearch}
              allowClear={true}
              searchStyleClassName={styles.searchBar}
            />
          </div>

          <Table
            showHeader={false}
            columns={teamMembersColumns}
            dataSource={searchCarrierContacts(
              searchText,
              remainingCarrierContacts
            )}
            pagination={false}
            scroll={{ y: 300 }}
            className={styles.scrollableTableWrapper}
          />

          <div className={styles.manageCarrierWrapper}>
            <span
              className={styles.manageCarrierContactsButton}
              onClick={() => {
                handleManageCarrierContactsClick();
              }}
            >
              Manage Carrier Contacts
            </span>
          </div>
        </Col>
        <Col xs={2} className={styles.arrowIconWrapper}>
          {arrowSign()}
        </Col>
        <Col className={styles.selectedCarrierWrapper}>
          <h2 className={styles.addedContactTitle}>
            Added Carrier Contacts ({selectedCarrierContacts.length})
          </h2>
          <SelectedCarrierContactsTable
            data={selectedCarrierContacts}
            updateSelectedCarrierContacts={updateSelectedCarrierContacts}
            updateSelectedCarrierContactListOrder={
              updateSelectedCarrierContactOrder
            }
          />
        </Col>
      </Row>

      <div className={styles.footerStyle}>
        <Row justify="center">
          <CheckboxSelect
            onChange={(e: CheckboxChangeEvent) =>
              toggleCarrierContactsVisibility(e)
            }
            defaultChecked={isCheckedByDefault}
          >
            Make Carrier Team visible to Employer
          </CheckboxSelect>
        </Row>
        <Row justify="center" className={styles.rowStyle}>
          <SubmitButton
            type="primary"
            onClick={() => handleSave()}
            loading={
              isCarrierContactUpdateVisibilityRequestLoading ||
              isCarrierContactUpdateRequestLoading
            }
          >
            Save
          </SubmitButton>
        </Row>
        <Row justify="center" className={styles.cancelWrapper}>
          <CancelButton disabled={false} onClick={() => closeModal()}>
            Cancel
          </CancelButton>
        </Row>
      </div>
    </div>
  );
};

export default EditCarrierContacts;
