import { ReactElement, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import { Collapse } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import OutlinedText from 'components/OutlinedText/OutlinedText';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  deleteBenefitCarrier,
  deleteCarrier,
  resetCarrierDeleteStatus,
  validateCarrierDelete,
} from 'modules/carriers/slices/carrierSlice';
import CarrierType from 'modules/carriers/enums/CarrierType';
import { getCarrierLibraryType } from 'modules/carriers/components/util/carrierUtil';
import { BenefitCategory, BenefitKind } from 'constants/commonConstants';

import styles from 'modules/carriers/components/DeleteCarrier/carrierDelete.module.less';

const { Panel } = Collapse;

type DeleteCarrierProps = {
  isConfirmOpen: boolean;
  cancelModal: Function;
  isBenefitCarrier: boolean;
  benefitCategories: ReactElement[] | string;
  allCarrierUrl: string;
  carrierIdFromList?: string;
};

const DeleteCarrier = (props: DeleteCarrierProps) => {
  const {
    isConfirmOpen,
    cancelModal,
    benefitCategories,
    isBenefitCarrier,
    allCarrierUrl,
    carrierIdFromList,
  } = props;

  const dispatch = useAppDispatch();
  const {
    carrierDeleteValidation,
    carrierDeleteInProgress,
    carrierDeleteSuccess,
    carrierDeleteValidationInProgress,
  } = useAppSelector((state) => state.carriers);
  const params = useParams();
  const navigate = useNavigate();
  const { brokerId, employerId, carrierId, id } = params;

  useEffect(() => {
    if (isConfirmOpen && employerId && brokerId) {
      dispatch(
        validateCarrierDelete(
          isBenefitCarrier ? id : carrierId ? carrierId : carrierIdFromList,
          brokerId,
          employerId,
          isBenefitCarrier
        )
      );
    }
  }, [
    dispatch,
    isConfirmOpen,
    carrierId,
    isBenefitCarrier,
    brokerId,
    employerId,
    id,
    carrierIdFromList,
  ]);

  useEffect(() => {
    if (carrierDeleteSuccess) {
      cancelModal(false);
      navigate(allCarrierUrl);
      dispatch(resetCarrierDeleteStatus());
    }
  }, [
    cancelModal,
    carrierDeleteSuccess,
    carrierDeleteInProgress,
    navigate,
    allCarrierUrl,
    dispatch,
  ]);

  const handleCloseConfirm = () => {
    if (
      !isEmpty(carrierDeleteValidation) &&
      getCarrierLibraryType(employerId, brokerId) === CarrierType.EMPLOYER
    ) {
      cancelModal(false);
      return;
    }
    if (isBenefitCarrier) {
      dispatch(deleteBenefitCarrier(id));
    } else {
      dispatch(
        deleteCarrier(
          carrierIdFromList ? carrierIdFromList : carrierId,
          getCarrierLibraryType(employerId, brokerId),
          employerId,
          brokerId
        )
      );
    }
  };

  const getPlanUrlPotion = (benefitCategory: string, plan: any) => {
    console.log(benefitCategory, plan);
    if (benefitCategory === BenefitCategory.MEDICAL.value) {
      return 'medical';
    } else if (benefitCategory === BenefitCategory.DENTAL.value) {
      return 'dental';
    } else if (benefitCategory === BenefitCategory.VISION.value) {
      return 'vision';
    } else if (benefitCategory === BenefitCategory.TELEHEALTH.value) {
      return 'telehealth-rx';
    } else if (benefitCategory === BenefitCategory.WELLBEING.value) {
      return 'wellbeing';
    } else if (benefitCategory === BenefitCategory.ADDITIONAL_PERK.value) {
      return 'additional-perks';
    } else if (benefitCategory === BenefitCategory.RETIREMENT.value) {
      return '401k';
    } else if (benefitCategory === BenefitCategory.LIFE.value) {
      return 'life';
    } else if (
      benefitCategory === BenefitCategory.HOLIDAYS_AND_TIME_OFF.value
    ) {
      return 'holiday-time-off';
    } else if (benefitCategory === BenefitCategory.FAMILY_AND_LEAVE.value) {
      return 'family-and-leave';
    } else if (benefitCategory === BenefitCategory.VOLUNTARY_BENEFIT.value) {
      return 'voluntary';
    } else if (benefitCategory === BenefitCategory.TAX_ADVANTAGE.value) {
      return 'tax-advantaged-accts';
    } else if (benefitCategory === BenefitCategory.SAVING.value) {
      return 'tax-advantaged-accts';
    } else if (benefitCategory === BenefitCategory.WORK_LIFE.value) {
      if (
        plan.benefitKind === BenefitKind.PAID_TIME_OFF ||
        plan.benefitKind === BenefitKind.SICK ||
        plan.benefitKind === BenefitKind.HOLIDAY ||
        plan.benefitKind === BenefitKind.FLEXIBLE_WORKING_HOURS ||
        plan.benefitKind === BenefitKind.OTHER_TIME_OFF
      ) {
        return 'holiday-time-off';
      } else {
        return 'family-and-leave';
      }
    }
  };

  const getCarrierDeleteMessage = () => {
    const defaultActiveKeys: string[] = [];
    carrierDeleteValidation.forEach((validation, index) => {
      defaultActiveKeys[index] = validation.planYear.id;
    });
    const sortedCarrierDeleteValidation = [...carrierDeleteValidation].sort(
      (a, b) => {
        return a.planYear.startDate.localeCompare(b.planYear.startDate);
      }
    );

    if (employerId) {
      if (isBenefitCarrier && isEmpty(sortedCarrierDeleteValidation)) {
        return <span>Are you sure you’d like to delete this carrier?</span>;
      } else if (!isBenefitCarrier && isEmpty(sortedCarrierDeleteValidation)) {
        return (
          <>
            <span>
              All benefit categories within this carrier will be deleted. Are
              you sure you’d like to delete this one?
            </span>
            <div className={styles.planContainer}>{benefitCategories}</div>
          </>
        );
      } else if (!isEmpty(sortedCarrierDeleteValidation)) {
        return (
          <div className={styles.deleteCarrierCollapseWrapper}>
            <span>
              To delete this carrier, you must remove it from the following
              plans first:
            </span>
            <Collapse
              bordered={false}
              defaultActiveKey={defaultActiveKeys}
              className={styles.deleteCarrierCollapse}
            >
              {sortedCarrierDeleteValidation?.map((carrierPlanInfo) => {
                const { planYear, plans } = carrierPlanInfo;
                return (
                  <Panel
                    header={
                      <div className={styles.planInfoSectionContainer}>
                        <div className={styles.planInfoSections}>
                          <span>{planYear.name}</span>
                        </div>
                        {planYear.current && (
                          <div
                            className={`${styles.planInfoSections} ${styles.currentPlanYearSection}`}
                          >
                            <OutlinedText text="CURRENT PLAN YEAR" />
                          </div>
                        )}
                      </div>
                    }
                    key={planYear.id}
                    showArrow={true}
                  >
                    <div className={styles.planContainerCollapse}>
                      {plans.map((plan) => {
                        return (
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={`/brokers/${brokerId}/employers/${employerId}/${getPlanUrlPotion(
                              plan.benefitCategory,
                              plan
                            )}/${plan.id}/overview`}
                            className={styles.planLink}
                            key={plan.id}
                          >
                            <OverflowPopover>
                              <span className={styles.planName}>
                                {plan.name}
                              </span>
                            </OverflowPopover>
                          </a>
                        );
                      })}
                    </div>
                  </Panel>
                );
              })}
            </Collapse>
          </div>
        );
      }
    } else {
      return isBenefitCarrier ? (
        <>
          <span>
            This carrier will be deleted from all libraries except in libraries
            that have plans with this carrier in use.
          </span>
          <div className={styles.planContainer}>{benefitCategories}</div>
        </>
      ) : (
        <>
          <span>
            All benefit categories within this carrier will be deleted from all
            libraries except in libraries that have plans with this carrier in
            use.
          </span>
          <div className={styles.planContainer}>{benefitCategories}</div>
        </>
      );
    }
  };

  if (carrierDeleteValidationInProgress) {
    return <></>;
  }
  return (
    <ConfirmationDialog
      title="Delete Carrier"
      confirmText={
        !isEmpty(carrierDeleteValidation) &&
        getCarrierLibraryType(employerId, brokerId) === CarrierType.EMPLOYER
          ? 'Close'
          : 'Yes - Delete Carrier'
      }
      cancelText="Cancel"
      isCancelLink={
        !(
          !isEmpty(carrierDeleteValidation) &&
          getCarrierLibraryType(employerId, brokerId) === CarrierType.EMPLOYER
        )
      }
      closeModal={() => cancelModal(false)}
      onConfirm={handleCloseConfirm}
      visible={isConfirmOpen}
      isCloseOnly={
        !isEmpty(carrierDeleteValidation) &&
        getCarrierLibraryType(employerId, brokerId) === CarrierType.EMPLOYER
      }
    >
      <p
        className={`${
          employerId
            ? styles.warningConfirmation
            : styles.warningConfirmationEmployer
        }`}
      >
        {getCarrierDeleteMessage()}
      </p>
    </ConfirmationDialog>
  );
};
export default DeleteCarrier;
