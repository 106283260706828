import { FC } from 'react';
import { VOLUNTARY_BENEFIT } from 'modules/renewals/constants/renewalsConstants';
import iconAdd from 'assets/images/icon-add-black.svg';

import styles from './addPlanButton.module.less';

type AddPlanButtonProps = {
  fixedWidth?: boolean;
  flexWrapWidth: number;
  listWrapHeight: number;
  height: number;
  benefitType?: string;
  addButtonRef: any;
  handleAddPlan: () => void;
  handleManualPlanAdd: () => void;
  disabled?: boolean;
};

const AddPlanButton: FC<AddPlanButtonProps> = ({
  fixedWidth,
  flexWrapWidth,
  listWrapHeight,
  height,
  benefitType,
  addButtonRef,
  handleAddPlan,
  handleManualPlanAdd,
  disabled = false,
}) => {
  const customWidth = fixedWidth ? 194 : flexWrapWidth / 5;
  const customHeight = listWrapHeight - (height + 65);
  return (
    <div
      style={{
        width: `${customWidth}px`,
        maxWidth: `${customWidth}px`,
        minWidth: `${customWidth <= 198 ? 198 : flexWrapWidth / 5}px`,
        height: `${customHeight}px`,
        maxHeight: `${customHeight}px`,
        minHeight: `${customHeight}px`,
        marginTop: `${
          benefitType === VOLUNTARY_BENEFIT ? height + 65 : height + 65
        }px`,
        paddingTop: `${
          benefitType === VOLUNTARY_BENEFIT
            ? listWrapHeight / 9
            : listWrapHeight / 2
        }px`,
        overflow: 'auto',
        marginLeft: '30px',
      }}
    >
      <div
        className={`${disabled ? styles.disabledButton : styles.addButton}`}
        onClick={handleAddPlan}
      >
        <img src={iconAdd} alt="add-icon" className={styles.iconAdd} />{' '}
        <div className={styles.addPlanText}>Plan</div>
        <div
          className={styles.addPlanHiddenDiv}
          ref={addButtonRef}
          onClick={handleManualPlanAdd}
        ></div>
      </div>
    </div>
  );
};

export default AddPlanButton;
