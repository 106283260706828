import React, { FC, useState, useEffect, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Input, Row, Form, Select, Popover, Checkbox } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isEqual from 'lodash/isEqual';

import { useNavContext } from 'hooks/useNavContext';
import { usePermitIf } from 'hooks/usePermitIf';
import Broker from 'model/Broker';
import DraftEmployer from 'model/DraftEmployer';
import EmployerLocation from 'model/EmployerLocation';
import {
  emailDomainVerificationMsg,
  formVerificationMsg,
  logoUploadHelperText,
  multiSelectTypes,
} from 'constants/commonConstants';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import InputForm from 'components/InputForm/InputForm';
import NextButton from 'components/buttons/NextButton/NextButton';
import StateSelect from 'components/StateSelect/StateSelect';
import InputPatternFormat from 'components/InputPatternFormat/InputPatternFormat';
import ImageUploader, {
  FORMAT_VALIDATE,
  SIZE_VALIDATE,
} from 'components/ImageUploader/ImageUploader';
import AlertMessage from 'components/Alert/AlertMessage';
import {
  EMPTY_MESSAGE,
  basicInfoFormFields,
} from 'modules/employers/constants/employerConstants';
import MultiSelectDropdown from 'components/MultiSelectDropdown/MultiSelectDropdown';
import {
  attachEmployerLogo,
  clearEmployerLogo,
  clearSavedTeamMembers,
  getBrokerList,
  getBrokerLocations,
  handleEmployerLogoUpload,
  processFindBroker,
  updateDraftEmployer,
  uploadEmployerLogoStart,
} from 'modules/employers/slices/employerCreateStepperSlice';
import {
  EMPLOYER_EMAILDOMAIN_ERROR_FOUND,
  EXISTING_EMAIL_DOMAIN_FOUND,
  EXISTING_EMPLOYER_FOUND,
  RESTRICTED_EMAIDOMAIN_ERROR_FOUND,
  RESTRICTED_EMAIL_DOMAIN_FOUND,
} from 'modules/brokers/constants/brokerConstants';
import {
  validateEmployer,
  validateEmployerEmailDomain,
} from 'modules/employers/services/EmployerService';
import {
  hasDuplicates,
  isValidEINTaxID,
  isValidEmail,
  splitAndTrim,
} from 'util/commonUtil';
import Employer from 'model/Employer';
import ErrorDetails from 'model/ErrorDetails';
import {
  clearEmployerError,
  editEmployer,
  editEmployerFailed,
} from 'modules/employers/slices/employerSlice';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { employerEditPermittedTypes } from 'constants/permittedConstants';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import { getAllBrokerConsultants } from 'modules/benefitsConsultation/slices/employerLevelSlice';
import ConsultantType from 'modules/benefitsConsultation/enums/ConsultantType';
import { loginTypes } from 'constants/authConstants';
import { useLazyGetEmployerLevelDisabledLocationsQuery } from 'api/featureControl';
import EmployerLocationChangeModal from 'modules/tools/components/EmployerLocationChangeModal/EmployerLocationChangeModal';
import { useLazyGetNavigationsQuery } from 'api/navigationSlice';

import styles from './basicInfo.module.less';

type BasicInfoProps = {
  nextStep: () => void;
  isModalOpen?: boolean;
  isEditMode?: boolean;
  employerEditData?: Employer | null;
};

const locationTooltipContent = () => (
  <div className={styles.popoverContent}>
    <p>
      Broker Admins in
      <br />
      these locations will
      <br /> have access to this
      <br />
      client.
    </p>
  </div>
);

const BasicInfo: FC<BasicInfoProps> = (props: BasicInfoProps) => {
  const params = useParams();
  const { nextStep, isModalOpen, isEditMode, employerEditData } = props;

  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [duplicateEmployerNameFound, setDuplicateEmployerNameFound] =
    useState<boolean>(false);
  const [duplicateEmailDomainsFound, setDuplicateEmailDomainsFound] =
    useState<boolean>(false);
  const [invalidTaxId, setInvalidTaxId] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [requireFieldErrorFound, setRequireFieldErrorFound] =
    useState<boolean>(false);
  const [locationsError, setLocationsError] = useState(false);
  const [isNextClicked, setIsNextClicked] = useState<boolean>(false);
  const [imageValidateSetting, setImageValidateSetting] = useState<string>('');
  const [brokerChanged, setBrokerChanged] = useState<boolean>(false);
  const [
    isEmployerLogoValidationMsgRemoved,
    setEmployerLogoValidationMsgRemoved,
  ] = useState<boolean>(true);
  const [cropArea, setCropArea] = useState<OriginalImageCropParams>();
  const [consultantAssignedWarning, setConsultantAssignedWarning] =
    useState<boolean>(false);
  const [updatedEmployer, setUpdatedEmployer] = useState<DraftEmployer>(
    {} as DraftEmployer
  );
  const [taxIdError, setTaxidError] = useState<boolean>(false);
  const [locationUpdateWarning, setLocationUpdateWarning] =
    useState<boolean>(false);
  const [isLocationSaveModal, setIsLocationSaveModal] =
    useState<boolean>(false);
  const [featureKeys, setFeatureKeys] = useState<string[]>([]);
  const [editedEmployer, setEditedEmployer] = useState<DraftEmployer>(
    {} as DraftEmployer
  );
  const [form] = Form.useForm();

  const { brokerId } = useNavContext();

  const dispatch = useAppDispatch();
  const employerData = useAppSelector(
    (state) => state.employer.employerCreateStepper.employer
  );
  const brokers = useAppSelector(
    (state) => state.employer.employerCreateStepper.brokerList
  );
  const brokerLocations = useAppSelector(
    (state) => state.employer.employerCreateStepper.brokerLocationList
  );
  const employerState = useAppSelector(
    (state) => state.employer.employerCreateStepper
  );
  const logoUrl = useAppSelector(
    (state) => state.employer.employerCreateStepper.employerLogo
  );
  const originalLogoUrl = useAppSelector(
    (state) => state.employer.employerCreateStepper.originalLogoUrl
  );
  const originalLogoEncodedString = useAppSelector(
    (state) => state.employer.employerCreateStepper.originalLogo
  );
  const brokerConsultantsList = useAppSelector(
    (state) => state.benefitsConsultation.employerLevel.brokerConsultantsList
  );
  const { employerEditInProgress, requestType, error } = useAppSelector(
    (state) => state.employer.employer
  );
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);

  useEffect(() => {
    if (
      !employerEditInProgress &&
      error &&
      requestType === editEmployerFailed.type &&
      !locationUpdateWarning
    ) {
      setLocationUpdateWarning(true);
    }
    // eslint-disable-next-line
  }, [employerEditInProgress, error, requestType]);

  useEffect(() => {
    if (logoUrl) {
      const currentValues = form.getFieldsValue();
      form.setFieldsValue({ ...currentValues, logoUrl });
      setEmployerLogoValidationMsgRemoved(true);
    }
  }, [logoUrl, form]);

  useEffect(() => {
    if (!isModalOpen) {
      form.resetFields();
      setSelectedLocations([]);
      setAllSelected(false);
      setRequireFieldErrorFound(false);
      setAlertMessage('');
      setDuplicateEmployerNameFound(false);
      setIsNextClicked(false);
      setLocationsError(false);
      setImageValidateSetting('');
      setInvalidTaxId(false);
      setEmployerLogoValidationMsgRemoved(true);
      setTaxidError(false);
      setDuplicateEmailDomainsFound(false);
    }
  }, [form, isModalOpen]);

  // get broker list when opening modal
  useEffect(() => {
    if (isModalOpen) {
      if (employerData && isEmpty(employerData.name)) {
        dispatch(clearEmployerLogo());
      }
      if (!brokerId) {
        dispatch(getBrokerList());
      } else {
        dispatch(processFindBroker(brokerId));
      }
    }
  }, [dispatch, brokerId, isModalOpen, employerData]);

  useEffect(() => {
    if (!isEmpty(brokers) && brokerId) {
      form.setFieldsValue({ managingBroker: brokerId });
      dispatch(getBrokerLocations(brokerId));
    }
  }, [dispatch, brokers, brokerId, form]);

  useEffect(() => {
    if (!isEmpty(employerData) && !isEmpty(employerData.name)) {
      form.setFieldsValue({
        employerName: employerData.name,
        einTaxId: employerData.einTaxId,
        addressLine1: employerData.address?.addressLine1,
        addressLine2: employerData.address?.addressLine2,
        city: employerData.address?.city,
        state: employerData.address?.state,
        zip: employerData.address?.zipCode,
        emailDomains: employerData?.emailDomains?.toString(),
        managingBroker: employerData.organizationId,
        isDemoAccount: employerData?.isDemoAccount,
      });
      employerData.locations
        ? setSelectedLocations(employerData.locations)
        : setSelectedLocations([]);
      setAllSelected(Boolean(employerData.allLocationsSelected));
      setCropArea(employerData.originalImageCropParams);
    }
  }, [employerData, employerData.name, form]);

  useEffect(() => {
    if (isEditMode && employerEditData) {
      dispatch(attachEmployerLogo(employerEditData.logoUrl));
      form.resetFields();
      setRequireFieldErrorFound(false);
      setAlertMessage('');
      setDuplicateEmployerNameFound(false);
      setInvalidTaxId(false);
      setDuplicateEmailDomainsFound(false);
      setLocationsError(false);
      setTaxidError(false);
      form.setFieldsValue({
        employerName: employerEditData.name,
        einTaxId: employerEditData.einTaxId,
        addressLine1: employerEditData.address?.addressLine1,
        addressLine2: employerEditData.address?.addressLine2,
        city: employerEditData.address?.city,
        state: employerEditData.address?.state,
        zip: employerEditData.address?.zipCode,
        emailDomains: employerEditData?.emailDomains?.toString(),
        managingBroker: employerEditData.organizationId,
        logoUrl: employerEditData.logoUrl,
        isDemoAccount: employerEditData?.isDemoAccount,
      });
      if (employerEditData.logoUrl) {
        setEmployerLogoValidationMsgRemoved(true);
      }
      isEmpty(employerEditData.locations)
        ? setSelectedLocations(
            brokerLocations.employerLocations.map(
              ({ locationId }) => locationId
            )
          )
        : setSelectedLocations(employerEditData.locations || []);
      setAllSelected(isEmpty(employerEditData.locations));
      setCropArea(employerEditData.originalImageCropParams);
    }
  }, [
    brokerLocations.employerLocations,
    dispatch,
    employerEditData,
    form,
    isEditMode,
  ]);

  useEffect(() => {
    if (employerState.employerLogo) {
      form.setFieldsValue({ employerLogo: employerState.employerLogo });
      setEmployerLogoValidationMsgRemoved(true);
    }
  }, [employerState.employerLogo, form]);

  useEffect(() => {
    if (isEditMode && brokerId && employerEditData?.id) {
      dispatch(getAllBrokerConsultants(brokerId, employerEditData.id));
    }
  }, [brokerId, dispatch, employerEditData?.id, isEditMode]);

  const updateSelected = (
    selectedOptions: string[],
    allOptionsSelected: boolean
  ) => {
    setAllSelected(allOptionsSelected);
    setSelectedLocations(selectedOptions);

    if (allOptionsSelected || !isEmpty(selectedOptions)) {
      setLocationsError(false);
    }
    if (isNextClicked) {
      removeAlert();
    }
  };

  const convertBrokerToOptions = (brokers: Broker[]) => {
    if (brokers) {
      return brokers.map((broker, index) => {
        return {
          key: index,
          label: broker.employerLimitReached
            ? `${broker.name} (Employer capacity reached)`
            : broker.name,
          value: broker.id,
          title: broker.employerLimitReached
            ? `Employer capacity reached`
            : broker.name,
          disabled: broker.employerLimitReached,
        };
      });
    }
    return [];
  };

  const convertBrokerLocationsToOptions = (locations: EmployerLocation[]) => {
    if (locations) {
      return locations.map((location) => ({
        label: location.locationName,
        value: location.locationId,
      }));
    }
    return [];
  };

  const validateBasicInfo = async () => {
    try {
      await form.validateFields(basicInfoFormFields.requiredFields);
      if (!employerState.employerLogo || isEmpty(employerState.employerLogo)) {
        return false;
      }
      return true;
    } catch (errorInfo: any) {
      return isEmpty(errorInfo.errorFields);
    }
  };

  const removeAlert = async () => {
    const valid = await validateBasicInfo();
    setDuplicateEmailDomainsFound(false);
    setDuplicateEmployerNameFound(false);
    setInvalidTaxId(false);
    setTaxidError(false);
    if (valid && isEmployerLogoValidationMsgRemoved) {
      setRequireFieldErrorFound(false);
    }
  };

  const handleNextClick = async () => {
    setIsNextClicked(true);
    if (isNull(employerState.employerLogo)) {
      dispatch(clearEmployerLogo());
    }
    const isFormValid = await validateBasicInfo();
    if (
      isFormValid &&
      (allSelected || (!allSelected && !isEmpty(selectedLocations)))
    ) {
      const values = form.getFieldsValue();
      const employerAddress = {
        addressLine1: values.addressLine1.trim(),
        addressLine2: values.addressLine2?.trim(),
        city: values.city.trim(),
        state: values.state,
        zipCode: values.zip.trim(),
      };
      if (
        values.einTaxId &&
        !isEmpty(values.einTaxId.trim()) &&
        !isValidEINTaxID(values.einTaxId)
      ) {
        setInvalidTaxId(true);
        setTaxidError(true);
        setAlertMessage('Invalid EIN/Tax ID found.');
        return;
      } else {
        setInvalidTaxId(false);
        setTaxidError(false);
      }

      const isValidDomains = isValidEmail(values?.emailDomains.split(',')); // Validate email domains
      if (!isValidDomains) {
        form.setFields([{ name: 'emailDomains', errors: [EMPTY_MESSAGE] }]);
        setRequireFieldErrorFound(true);
        setAlertMessage(emailDomainVerificationMsg);
        return;
      }
      const duplicatesExist = hasDuplicates(splitAndTrim(values?.emailDomains));
      if (duplicatesExist) {
        form.setFields([{ name: 'emailDomains', errors: [EMPTY_MESSAGE] }]);
        setRequireFieldErrorFound(true);
        setAlertMessage(EMPLOYER_EMAILDOMAIN_ERROR_FOUND);
        return;
      }

      const employer = {
        name: values.employerName.trim(),
        address: employerAddress,
        einTaxId: values.einTaxId,
        emailDomains: splitAndTrim(values.emailDomains?.toLowerCase()),
        organizationId: values.managingBroker,
        locations: allSelected
          ? brokerLocations.employerLocations.map(
              (location) => location.locationId
            )
          : selectedLocations,
        logoUrl: employerState.employerLogoReference,
        originalLogoUrl: originalLogoUrl,
        originalImageCropParams: cropArea,
        allLocationsSelected: allSelected,
        isDemoAccount: values?.isDemoAccount,
      } as DraftEmployer;
      const validateEmployerResult = await validateEmployer(
        employer.name,
        employer.organizationId,
        employer.id
      );
      const validateEmailDomainResult = await validateEmployerEmailDomain(
        values?.emailDomains,
        employer.id
      );
      if (
        validateEmployerResult &&
        validateEmployerResult.data &&
        validateEmployerResult.data.code &&
        validateEmployerResult.data.code === EXISTING_EMPLOYER_FOUND
      ) {
        form.setFields([{ name: 'employerName', errors: [EMPTY_MESSAGE] }]);
        setDuplicateEmployerNameFound(true);
        setAlertMessage('A employer with this name already exists.');
      } else if (
        validateEmailDomainResult &&
        validateEmailDomainResult.data &&
        validateEmailDomainResult.data.code &&
        validateEmailDomainResult.data.code === EXISTING_EMAIL_DOMAIN_FOUND
      ) {
        form.setFields([{ name: 'emailDomains', errors: [EMPTY_MESSAGE] }]);
        setDuplicateEmailDomainsFound(true);
        setAlertMessage(EMPLOYER_EMAILDOMAIN_ERROR_FOUND);
      } else if (
        validateEmailDomainResult &&
        validateEmailDomainResult.data &&
        validateEmailDomainResult.data.code &&
        validateEmailDomainResult.data.code === RESTRICTED_EMAIL_DOMAIN_FOUND
      ) {
        form.setFields([{ name: 'emailDomains', errors: [EMPTY_MESSAGE] }]);
        setDuplicateEmailDomainsFound(true);
        setAlertMessage(RESTRICTED_EMAIDOMAIN_ERROR_FOUND);
      } else {
        setDuplicateEmployerNameFound(false);
        dispatch(updateDraftEmployer(employer));
        nextStep();
        brokerChanged && dispatch(clearSavedTeamMembers());
      }
    } else {
      if (imageValidateSetting) {
        setImageValidateSetting('');
      }
      setRequireFieldErrorFound(true);
      setAlertMessage(formVerificationMsg);
      setBrokerChanged(false);
    }
  };

  const [getNavigations] = useLazyGetNavigationsQuery();

  const handleCloseModal = () => {
    nextStep();
    getNavigations({
      brokerId: params?.brokerId || null,
      employerId: params?.employerId || null,
    });
  };

  const [getLocation, { isLoading }] =
    useLazyGetEmployerLevelDisabledLocationsQuery();

  const saveEmployer = async (employer: any) => {
    setDuplicateEmployerNameFound(false);
    const employerHasAssignedBrokerConsultants = brokerConsultantsList.find(
      (consultant) =>
        consultant.type === ConsultantType.BROKER && consultant.isAssigned
    );
    if (
      employerHasAssignedBrokerConsultants &&
      !isEqual(employerEditData?.locations, employer.locations)
    ) {
      setUpdatedEmployer(employer);
      setConsultantAssignedWarning(true);
    } else {
      await dispatch(editEmployer(employer, handleCloseModal));
    }
  };

  const updateEmployer = async () => {
    setIsNextClicked(true);
    if (isNull(employerState.employerLogo)) {
      dispatch(clearEmployerLogo());
    }
    const isFormValid = await validateBasicInfo();
    if (
      isFormValid &&
      (allSelected || (!allSelected && !isEmpty(selectedLocations)))
    ) {
      const values = form.getFieldsValue();
      const employerAddress = {
        addressLine1: values.addressLine1?.trim(),
        addressLine2: values.addressLine2?.trim(),
        city: values.city.trim(),
        state: values.state,
        zipCode: values.zip.trim(),
      };

      const isValidDomains = isValidEmail(values?.emailDomains); // Validate email domains
      if (!isValidDomains) {
        form.setFields([{ name: 'emailDomains', errors: [EMPTY_MESSAGE] }]);
        setRequireFieldErrorFound(true);
        setAlertMessage(emailDomainVerificationMsg);
        return;
      }
      const duplicatesExist = hasDuplicates(splitAndTrim(values?.emailDomains));
      if (duplicatesExist) {
        form.setFields([{ name: 'emailDomains', errors: [EMPTY_MESSAGE] }]);
        setRequireFieldErrorFound(true);
        setAlertMessage(EMPLOYER_EMAILDOMAIN_ERROR_FOUND);
        return;
      }
      if (
        values.einTaxId &&
        !isEmpty(values.einTaxId.trim()) &&
        !isValidEINTaxID(values.einTaxId)
      ) {
        setInvalidTaxId(true);
        setTaxidError(true);
        setAlertMessage('Invalid EIN/Tax ID found.');
        return;
      } else {
        setInvalidTaxId(false);
        setTaxidError(false);
      }
      const employer = {
        ...employerEditData,
        name: values.employerName || '',
        address: employerAddress,
        einTaxId: values.einTaxId,
        locations: allSelected ? [] : selectedLocations,
        logoUrl: employerState.employerLogoReference,
        allLocationsSelected: allSelected,
        originalLogoUrl: originalLogoUrl,
        emailDomains: splitAndTrim(values.emailDomains?.toLowerCase()),
        originalImageCropParams: originalLogoUrl ? cropArea : {},
        isDemoAccount: values?.isDemoAccount,
      } as DraftEmployer;
      let validateEmployerResult = {} as ErrorDetails | null;

      const validateEmailDomainResult: any = await validateEmployerEmailDomain(
        values.emailDomains,
        employer.id
      );
      if (employerEditData && employerEditData.name !== values.employerName) {
        validateEmployerResult = await validateEmployer(
          employer.name,
          employer.organizationId,
          employer.id
        );
      }
      if (
        validateEmployerResult &&
        validateEmployerResult.data &&
        validateEmployerResult.data.code &&
        validateEmployerResult.data.code === EXISTING_EMPLOYER_FOUND
      ) {
        form.setFields([{ name: 'employerName', errors: [EMPTY_MESSAGE] }]);
        setDuplicateEmployerNameFound(true);
        setAlertMessage('A employer with this name already exists.');
      }
      if (
        validateEmailDomainResult &&
        validateEmailDomainResult.data &&
        validateEmailDomainResult.data.code &&
        validateEmailDomainResult.data.code === EXISTING_EMAIL_DOMAIN_FOUND
      ) {
        form.setFields([{ name: 'emailDomains', errors: [EMPTY_MESSAGE] }]);
        setDuplicateEmailDomainsFound(true);
        setAlertMessage(EMPLOYER_EMAILDOMAIN_ERROR_FOUND);
      }
      if (
        validateEmailDomainResult &&
        validateEmailDomainResult.data &&
        validateEmailDomainResult.data.code &&
        validateEmailDomainResult.data.code === RESTRICTED_EMAIL_DOMAIN_FOUND
      ) {
        form.setFields([{ name: 'emailDomains', errors: [EMPTY_MESSAGE] }]);
        setDuplicateEmailDomainsFound(true);
        setAlertMessage(RESTRICTED_EMAIDOMAIN_ERROR_FOUND);
      }
      if (
        validateEmailDomainResult?.data?.code !== EXISTING_EMAIL_DOMAIN_FOUND &&
        validateEmployerResult?.data?.code !== EXISTING_EMPLOYER_FOUND &&
        validateEmailDomainResult?.data?.code !== RESTRICTED_EMAIL_DOMAIN_FOUND
      ) {
        const data = (await getLocation({
          isAllEnabled: allSelected,
          locations: selectedLocations.toString(),
          employerId: employerEditData?.id,
        }).unwrap()) as any;
        if (
          !isEqual(employerEditData?.locations, employer.locations) &&
          data?.disabled
        ) {
          if (!isLoading && data?.disabled) {
            setFeatureKeys(data?.featureKeys);
            setEditedEmployer(employer);
            setIsLocationSaveModal(true);
          }
        } else {
          saveEmployer(employer);
        }
      }
    } else {
      if (imageValidateSetting) {
        setImageValidateSetting('');
      }
      setRequireFieldErrorFound(true);
      setAlertMessage(formVerificationMsg);
    }
  };

  const handleFormChange = (changedValues: any, values: any) => {
    if (changedValues.managingBroker) {
      setBrokerChanged(true);
      dispatch(getBrokerLocations(changedValues.managingBroker));
      updateSelected([], false);
    }
    if (changedValues.logoUrl && imageValidateSetting) {
      form.setFieldsValue({ ...form, logoUrl: '' });
      setEmployerLogoValidationMsgRemoved(false);
    }
    form.setFieldsValue(values);
    if (isNextClicked) {
      removeAlert();
    }
  };

  const closeAlert = () => {
    setRequireFieldErrorFound(false);
    setDuplicateEmployerNameFound(false);
    setInvalidTaxId(false);
    if (imageValidateSetting) {
      setImageValidateSetting('');
    }
  };

  const onConfirmCrop = async (
    image: string,
    originalImage?: string,
    croppedArea?: OriginalImageCropParams
  ) => {
    dispatch(handleEmployerLogoUpload(image, originalImage)).then(() => {
      setCropArea(croppedArea);
    });
    setEmployerLogoValidationMsgRemoved(true);
    setImageValidateSetting('');
    if (isNextClicked) {
      removeAlert();
    }
  };

  const onLogoRemove = () => {
    dispatch(clearEmployerLogo());
    form.setFieldsValue({ ...form, logoUrl: '' });
    setEmployerLogoValidationMsgRemoved(false);
    setImageValidateSetting('');
  };

  const onValidateFails = (validateSetting: string) => {
    setImageValidateSetting(validateSetting);
    if (requireFieldErrorFound) {
      setRequireFieldErrorFound(false);
    }
  };

  const getValidationMessage = (validateSetting: string): ReactNode => {
    switch (validateSetting) {
      case FORMAT_VALIDATE:
        return 'This image file type is not supported.';
      case SIZE_VALIDATE:
        return 'Maximum size allowed for this upload is 2 MB.';
      default:
        setImageValidateSetting('');
        return;
    }
  };

  const securedBrokerSelect = usePermitIf(
    <Form.Item
      name="managingBroker"
      label="Managing Broker"
      labelCol={{ span: 24 }}
      rules={[
        {
          required: true,
          message: EMPTY_MESSAGE,
        },
      ]}
    >
      <Select
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        options={convertBrokerToOptions(brokers)}
        className={styles.managingBroker}
        disabled={Boolean(brokerId)}
        placeholder="Select"
      />
    </Form.Item>,
    employerEditPermittedTypes,
    []
  );

  const securedLocationSelect = usePermitIf(
    <Form.Item
      name="locations"
      className={styles.locationSelect}
      labelCol={{ span: 24 }}
      label={
        <Row>
          <div className={styles.tooltipLabel}>
            What Broker Locations Should Have Access To This Client? *
            <Popover
              content={locationTooltipContent}
              placement="left"
              overlayClassName="locationPopoverInner"
            >
              <span
                className={
                  locationsError ? styles.errorTooltipIcon : styles.tooltipIcon
                }
              >
                <QuestionCircleOutlined />
              </span>
            </Popover>
          </div>
        </Row>
      }
      validateStatus={locationsError ? 'error' : 'validating'}
      rules={[
        {
          validator: () => {
            if (!allSelected && isEmpty(selectedLocations)) {
              setLocationsError(true);
              return Promise.reject(new Error(''));
            }
            setLocationsError(false);
            return Promise.resolve();
          },

          validateTrigger: 'onSubmit',
        },
      ]}
    >
      <MultiSelectDropdown
        options={convertBrokerLocationsToOptions(
          brokerLocations.employerLocations
        )}
        mode="vertical"
        updateSelected={updateSelected}
        selected={selectedLocations}
        allOptionsSelected={allSelected}
        type={multiSelectTypes.ALL_SELECTABLE}
        allOption="Access to All"
        description="(including any new locations in the future by default.)"
        displayAllOption={brokerLocations.selectAll}
        disabledDropDown={isEmpty(form.getFieldValue('managingBroker'))}
      />
    </Form.Item>,
    employerEditPermittedTypes,
    []
  );

  return (
    <div className={styles.container}>
      {(requireFieldErrorFound ||
        duplicateEmployerNameFound ||
        invalidTaxId ||
        duplicateEmailDomainsFound ||
        imageValidateSetting) && (
        <AlertMessage
          type="error"
          message={
            imageValidateSetting
              ? getValidationMessage(imageValidateSetting)
              : alertMessage
          }
          closeAlert={closeAlert}
        />
      )}
      <InputForm form={form} onValuesChange={handleFormChange}>
        <Row gutter={48}>
          <Col span={12}>{securedBrokerSelect}</Col>
          <Col span={12}>{securedLocationSelect}</Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="employerName"
              label="Employer Name"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="einTaxId"
              label="EIN / Tax ID"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={taxIdError ? styles.labelError : ''}
            >
              <InputPatternFormat
                id="einTaxId"
                format="##-#######"
                placeholder="XX-XXXXXXX"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="addressLine1"
              label="Address Line 1"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="addressLine2"
              label="Address Line 2"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="city"
              label="City"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Row gutter={48}>
              <Col span={10}>
                <Form.Item
                  name="state"
                  label="State"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: EMPTY_MESSAGE,
                    },
                  ]}
                >
                  <StateSelect />
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="zip"
                  label="Zip"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: EMPTY_MESSAGE,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="emailDomains"
              label="Company Email Domains"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                },
              ]}
            >
              <Input placeholder="companyname.com" />
            </Form.Item>
          </Col>
        </Row>
        <p className={styles.domainFiledMsg}>
          Use a comma to delimit multiple domains
        </p>
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              name="logoUrl"
              label="Employer Logo"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                },
              ]}
              validateStatus={
                (!isNextClicked ||
                  isNull(employerState.employerLogo) ||
                  !isEmpty(employerState.employerLogo)) &&
                !imageValidateSetting &&
                isEmployerLogoValidationMsgRemoved
                  ? ''
                  : 'error'
              }
            >
              <Row>
                <Col span={16}>
                  <ImageUploader
                    cropShape="rect"
                    aspect={4 / 3}
                    onConfirm={onConfirmCrop}
                    loading={
                      employerState.inProgress &&
                      employerState.requestType === uploadEmployerLogoStart.type
                    }
                    uploadedImage={employerState.employerLogo}
                    onRemove={onLogoRemove}
                    onValidateFails={onValidateFails}
                    showOperations
                    title="Upload Logo"
                    helpText={logoUploadHelperText}
                    unCroppedImage={
                      originalLogoEncodedString ||
                      (employerEditData
                        ? employerEditData.originalLogoUrl || ''
                        : undefined)
                    }
                    defaultCropArea={
                      cropArea ||
                      (employerEditData
                        ? employerEditData.originalImageCropParams
                        : undefined)
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item
            name="isDemoAccount"
            labelCol={{ span: 24 }}
            valuePropName="checked"
            hidden={appBootInfo?.type !== loginTypes.platform}
          >
            <Checkbox className={styles.demoAccountCheck}>
              Demo Account
            </Checkbox>
          </Form.Item>
        </Row>
      </InputForm>
      {isEditMode ? (
        <SubmitButton
          onClick={updateEmployer}
          disabled={
            employerState.inProgress &&
            employerState.requestType === uploadEmployerLogoStart.type
          }
          loading={employerEditInProgress || employerState.inProgress}
          dataCy="data-erBasicInfoEdit"
          type="primary"
        >
          Save
        </SubmitButton>
      ) : (
        <NextButton
          nextStep={handleNextClick}
          disabled={
            employerState.inProgress &&
            employerState.requestType === uploadEmployerLogoStart.type
          }
        />
      )}
      {consultantAssignedWarning && (
        <ConfirmationDialog
          title="This Employer has Broker Consultants"
          confirmText="Continue"
          cancelText="Cancel"
          closeModal={() => setConsultantAssignedWarning(false)}
          onConfirm={() => {
            setConsultantAssignedWarning(false);
            dispatch(editEmployer(updatedEmployer, handleCloseModal));
          }}
          visible={consultantAssignedWarning}
          titleClassName={styles.warningTitle}
          modalClassName={styles.warningConfirmationModal}
        >
          <div>
            <p>
              If you are adding or removing locations, make sure that the
              correct broker consultants are assigned to the employer
              afterwards.
            </p>
            <p>Do you wish to continue?</p>
          </div>
        </ConfirmationDialog>
      )}
      {locationUpdateWarning && (
        <ConfirmationDialog
          title="Edit Employer"
          confirmText="Close"
          closeModal={() => {
            setLocationUpdateWarning(false);
            dispatch(clearEmployerError());
          }}
          onConfirm={() => {
            setLocationUpdateWarning(false);
            dispatch(clearEmployerError());
          }}
          visible={locationUpdateWarning}
          modalClassName={styles.employerLocationWarningModal}
          recordName={employerEditData?.name}
          isCloseOnly
          isRecordApplicable
        >
          <div>
            <p>
              This locations update cannot be completed as it will remove all
              team members from this employer. You must add at least one team
              member whose access won’t be revoked in order to make this update.
            </p>
          </div>
        </ConfirmationDialog>
      )}
      {isLocationSaveModal && (
        <EmployerLocationChangeModal
          isModalOpen={isLocationSaveModal}
          title={
            params?.employerId
              ? 'Change Employer Location'
              : 'Re-Assign Broker Location'
          }
          setIsLocationSaveModal={setIsLocationSaveModal}
          isEmployerEdit={params?.employerId ? true : false}
          featureKeys={featureKeys}
          saveData={saveEmployer}
          updatedEmployer={editedEmployer}
        />
      )}
    </div>
  );
};

export default BasicInfo;
