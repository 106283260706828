import { useState } from 'react';
import { Col, Row } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';

import LinkButton from 'components/buttons/LinkButton/LinkButton';
import SummeryCommonTable from 'modules/renewals/components/helperComponents/SummeryCommonTable/SummeryCommonTable';
import { NewPlanTag } from 'modules/renewals/components/NewPlanTag/NewPlanTag';
import ComparePlans from 'modules/renewals/pages/offers/ComparePlans/ComparePlans';

import { currencyFormatter } from 'util/commonUtil';
import { ProposalOfferDetailsCardDataType } from 'modules/renewals/types/renewalsTypes';
import {
  PROPOSAL_DETAILED_RENEWAL_TYPES,
  N_TIER_PRIORITY_ORDER,
  ER_HSA_CONTRIBUTIONS,
  PREMIUM,
  HRA_CONTRIBUTION,
  HRA_FACE_VALUE,
} from 'modules/renewals/constants/renewalsConstants';

import styles from 'modules/renewals/components/ProposalOfferDetailsCard/ProposalOfferDetailsCard.module.less';
import { FundingTypeTag } from 'components/StdFundingTypeTag/FundingTypeTag';
import FundingType from 'modules/plans/enums/FundingType';

type Props = {
  onPlanDetailsClick?: () => void;
  data: ProposalOfferDetailsCardDataType;
  type: string;
};

const ProposalOfferDetailsCard = ({
  onPlanDetailsClick = () => {},
  data,
  type,
}: Props) => {
  const [comparePlansVisible, setComparePlansVisible] =
    useState<boolean>(false);

  const getSummary = () => {
    switch (data?.type) {
      case PROPOSAL_DETAILED_RENEWAL_TYPES.medical.toUpperCase():
      case PROPOSAL_DETAILED_RENEWAL_TYPES.dental.toUpperCase():
      case PROPOSAL_DETAILED_RENEWAL_TYPES.vision.toUpperCase():
        return (
          <Table.Summary.Row className={styles.summaryRow}>
            <Table.Summary.Cell index={0} colSpan={3}>
              Total Cost
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              <div>{`${currencyFormatter(
                data?.summaryData?.erPaysPerMonth?.month || 0
              )}/month`}</div>
              <div>{`${currencyFormatter(
                data?.summaryData?.erPaysPerMonth?.year || 0
              )}/year`}</div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              <div>
                {`${currencyFormatter(
                  data?.summaryData?.eePaysPerMonth?.month || 0
                )}/month`}
              </div>
              <div>
                {`${currencyFormatter(
                  data?.summaryData?.eePaysPerMonth?.year || 0
                )}/year`}
              </div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              <div>{`${currencyFormatter(
                isHsa()
                  ? data?.summaryData?.premiumContribution?.month || 0
                  : data?.summaryData?.totalMonthlyCost?.month || 0
              )}/month`}</div>
              <div>{`${currencyFormatter(
                isHsa()
                  ? data?.summaryData?.premiumContribution?.year || 0
                  : data?.summaryData?.totalMonthlyCost?.year || 0
              )}/year`}</div>
            </Table.Summary.Cell>
            {data?.isHra && (
              <>
                <Table.Summary.Cell index={4}>
                  <div>{`-`}</div>
                  <div>{`${currencyFormatter(
                    data?.summaryData?.erHraFaceValueContributions?.year || 0
                  )}/year`}</div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  <div>{`${currencyFormatter(
                    data?.summaryData?.erHraContributions?.month || 0
                  )}/month`}</div>
                  <div>{`${currencyFormatter(
                    data?.summaryData?.erHraContributions?.year || 0
                  )}/year`}</div>
                </Table.Summary.Cell>
              </>
            )}
            {data?.tableData?.rowData?.length > 0 &&
              (data?.tableData?.rowData?.[0]?.erHsaContributions ||
                data?.summaryData?.erHsaContributions?.month !== 0 ||
                data?.summaryData?.erHsaContributions?.year !== 0 ||
                data?.isHsa) &&
              data?.isHsa && (
                <Table.Summary.Cell index={2}>
                  <div>{`${currencyFormatter(
                    data?.summaryData?.erHsaContributions?.month || 0
                  )}/month`}</div>
                  <div>{`${currencyFormatter(
                    data?.summaryData?.erHsaContributions?.year || 0
                  )}/year`}</div>
                </Table.Summary.Cell>
              )}
            {data?.tableData?.rowData?.length > 0 && isHsa() && data?.isHsa && (
              <Table.Summary.Cell index={2}>
                <div>{`${currencyFormatter(
                  data?.summaryData?.totalMonthlyCost?.month || 0
                )}/month`}</div>
                <div>{`${currencyFormatter(
                  data?.summaryData?.totalMonthlyCost?.year || 0
                )}/year`}</div>
              </Table.Summary.Cell>
            )}
          </Table.Summary.Row>
        );
      case PROPOSAL_DETAILED_RENEWAL_TYPES.life.toUpperCase():
      case PROPOSAL_DETAILED_RENEWAL_TYPES.ltd.toUpperCase():
      case PROPOSAL_DETAILED_RENEWAL_TYPES.std.toUpperCase():
        return (
          <Table.Summary.Row className={styles.summaryRow}>
            <Table.Summary.Cell
              index={0}
              colSpan={
                data?.type ===
                PROPOSAL_DETAILED_RENEWAL_TYPES.life.toUpperCase()
                  ? 5
                  : 4
              }
            >
              Total Cost
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              <div>{`${currencyFormatter(
                data.summaryData.totalMonthlyCost.month || 0
              )}/month`}</div>
              <div>{`${currencyFormatter(
                data.summaryData.totalMonthlyCost.year || 0
              )}/year`}</div>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      default:
        return <></>;
    }
  };

  const getFromCurrentValueClasses = (value: number): string => {
    let classes = '';
    if (value === 0) {
      classes += `offset`;
    } else if (value <= 0) {
      classes += `successText`;
    } else {
      classes += `errorText`;
    }
    return classes;
  };

  const getFromCurrentValue = (value: number): string => {
    const str = currencyFormatter(value);
    if (value > 0) {
      return `+${str}`;
    } else {
      return `${str}`;
    }
  };

  const isHsa = (): boolean => {
    return (
      data?.tableData?.rowData?.[0]?.erHsaContributions ||
      data?.summaryData?.erHsaContributions?.month !== 0 ||
      data?.summaryData?.erHsaContributions?.year !== 0 ||
      data?.isHsa
    );
  };

  const getTableColumns = (): ColumnsType<any> => {
    if (
      data?.type === PROPOSAL_DETAILED_RENEWAL_TYPES.medical.toUpperCase() ||
      data?.type === PROPOSAL_DETAILED_RENEWAL_TYPES.vision.toUpperCase() ||
      data?.type === PROPOSAL_DETAILED_RENEWAL_TYPES.dental.toUpperCase()
    ) {
      const columns: ColumnsType<any> = [
        {
          title: <img className={styles.logo} src={data.tableData?.imageUrl} />,
          key: 'name',
          dataIndex: 'name',
          width: '20%',
          render: (data) => data || '-',
        },
        {
          title: 'Enrollment',
          key: 'enrollment',
          dataIndex: 'enrollment',
          width: '10%',
          render: (data) => data || '-',
        },
        {
          title: 'ER/EE Splits %',
          key: 'splits',
          dataIndex: 'splits',
          width: '15%',
          render: (data) => data || '-',
        },
      ];

      if (data?.tableData?.rowData?.length > 0) {
        let hraHsaData = [
          {
            title: 'ER Pays/Month',
            key: 'erPaysPerMonth',
            dataIndex: 'erPaysPerMonth',
            width: '10%',
            render: (data) => (data ? currencyFormatter(data) : '-'),
          },
          {
            title: 'EE Pays/Month (Vs Current)',
            key: 'eePaysPerMonth',
            dataIndex: 'eePaysPerMonth',
            width: '15%',
            render: (row) => (
              <div>
                {row ? (
                  <>
                    {`${currencyFormatter(row.value)} `}
                    <span
                      className={
                        styles?.[
                          getFromCurrentValueClasses(row?.fromCurrent ?? 0)
                        ]
                      }
                    >
                      {!data?.newPlan &&
                        `(${getFromCurrentValue(row.fromCurrent)})`}
                    </span>
                  </>
                ) : (
                  '-'
                )}
              </div>
            ),
          },
          {
            title: 'Total Monthly Cost',
            key: 'totalMonthlyCost',
            dataIndex: isHsa() ? 'premium' : 'totalMonthlyCost',
            width: '20%',
            render: (data) => (data ? currencyFormatter(data) : '-'),
          },
          {
            title: 'HRA Allowance / Yr',
            key: 'hraFaceValue',
            dataIndex: 'hraFaceValue',
            width: '15%',
            render: (data) => (data ? currencyFormatter(data) : '-'),
          },
          {
            title: 'HRA Cost / Yr',
            key: 'hraContribution',
            dataIndex: 'hraContribution',
            width: '15%',
            render: (data) => (data ? currencyFormatter(data) : '-'),
          },
          {
            title: 'ER Hsa Contributions',
            key: 'erHsaContributions',
            dataIndex: 'erHsaContributions',
            width: '10%',
            render: (data) =>
              data ? currencyFormatter(data) : currencyFormatter(0),
          },
          {
            title: 'Total Cost W/HSA',
            key: 'premium',
            dataIndex: 'totalMonthlyCost',
            width: '15%',
            render: (data) => (data ? currencyFormatter(data) : '-'),
          },
        ] as ColumnsType<any>;

        if (isHsa() && data?.isHra) {
          hraHsaData = hraHsaData.filter(
            (obj) => obj.key !== ER_HSA_CONTRIBUTIONS && obj.key !== PREMIUM
          );
          columns.push(...hraHsaData);
        } else if (isHsa()) {
          hraHsaData = hraHsaData.filter(
            (obj) => obj.key !== HRA_CONTRIBUTION && obj.key !== HRA_FACE_VALUE
          );
          columns.push(...hraHsaData);
        } else if (data?.isHra) {
          hraHsaData = hraHsaData.filter(
            (obj) => obj.key !== ER_HSA_CONTRIBUTIONS && obj.key !== PREMIUM
          );
          columns.push(...hraHsaData);
        } else {
          hraHsaData = hraHsaData.filter(
            (obj) =>
              obj.key !== HRA_CONTRIBUTION &&
              obj.key !== HRA_FACE_VALUE &&
              obj.key !== ER_HSA_CONTRIBUTIONS &&
              obj.key !== PREMIUM
          );
          columns.push(...hraHsaData);
        }
      } else {
        columns.push(
          ...([
            {
              title: 'ER Pays/Month',
              key: 'erPaysPerMonth',
              dataIndex: 'erPaysPerMonth',
              width: '20%',
              render: (data) =>
                data || data === 0 ? currencyFormatter(data) : '-',
            },
            {
              title: 'EE Pays/Month (Vs Current)',
              key: 'eePaysPerMonth',
              dataIndex: 'eePaysPerMonth',
              width: '15%',
              render: (row) => (
                <div>
                  {row ? (
                    <>
                      {`${currencyFormatter(row.value)} `}
                      <span
                        className={
                          styles?.[
                            getFromCurrentValueClasses(row?.fromCurrent ?? 0)
                          ]
                        }
                      >
                        {!data?.newPlan &&
                          `(${getFromCurrentValue(row.fromCurrent)})`}
                      </span>
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              ),
            },
            {
              title: 'Total Monthly Cost',
              key: 'totalMonthlyCost',
              dataIndex: 'totalMonthlyCost',
              width: '20%',
              render: (data) =>
                data || data === 0 ? currencyFormatter(data) : '-',
            },
          ] as ColumnsType<any>)
        );
      }
      return columns;
    }
    if (
      data?.type === PROPOSAL_DETAILED_RENEWAL_TYPES.life.toUpperCase() ||
      data?.type === PROPOSAL_DETAILED_RENEWAL_TYPES.ltd.toUpperCase() ||
      data?.type === PROPOSAL_DETAILED_RENEWAL_TYPES.std.toUpperCase()
    ) {
      const columns: ColumnsType<any> = [
        {
          title: <img className={styles.logo} src={data.tableData?.imageUrl} />,
          key: 'name',
          dataIndex: 'name',
          width: '15%',
          render: (data: string) => data || '-',
        },
      ];

      if (
        data?.type === PROPOSAL_DETAILED_RENEWAL_TYPES.ltd.toUpperCase() ||
        (data?.type === PROPOSAL_DETAILED_RENEWAL_TYPES.std.toUpperCase() &&
          data?.stdFundingType === FundingType.FULLY_INSURED)
      ) {
        columns.push(
          ...[
            {
              title: 'Enrollment',
              key: 'enrollment',
              dataIndex: 'enrollment',
              width: '20%',
              render: (data: number) => data || '-',
            },
            {
              title: 'Volume',
              key: 'volume',
              dataIndex: 'volume',
              width: '15%',
              render: (data: number) => currencyFormatter(data) || '-',
            },
            {
              title: 'Rate',
              key: 'rate',
              dataIndex: 'rate',
              width: '30%',
              render: (data: number) => data || '-',
            },
            {
              title: 'Total Monthly Cost',
              key: 'totalMonthlyCost',
              dataIndex: 'totalMonthlyCost',
              width: '20%',
              render: (data: number) => currencyFormatter(data) || '-',
            },
          ]
        );
      } else if (
        data?.type === PROPOSAL_DETAILED_RENEWAL_TYPES.std.toUpperCase() &&
        data?.stdFundingType === FundingType.SELF_FUNDED
      ) {
        columns.push(
          ...[
            {
              title: 'Total Eligible Employees',
              key: 'enrollment',
              dataIndex: 'enrollment',
              width: '20%',
              render: (data: number) => data || '-',
            },
            {
              title: 'Admin Fees',
              key: 'administrationFee',
              dataIndex: 'administrationFee',
              width: '15%',
              render: (data: number) => currencyFormatter(data) || '-',
            },
            {
              title: 'Annual Est. Claims',
              key: 'annualEstimatedClaims',
              dataIndex: 'annualEstimatedClaims',
              width: '15%',
              render: (data: number) => currencyFormatter(data) || '-',
            },
            {
              title: 'Total Monthly Cost',
              key: 'totalMonthlyCost',
              dataIndex: 'totalMonthlyCost',
              width: '20%',
              render: (_: any) =>
                data?.summaryData?.totalMonthlyCost?.month
                  ? currencyFormatter(
                      data?.summaryData?.totalMonthlyCost?.month
                    )
                  : '-',
            },
          ]
        );
      } else {
        columns.push(
          ...[
            {
              title: 'Enrollment',
              key: 'enrollment',
              dataIndex: 'enrollment',
              width: '20%',
              render: (data: number) => data || '-',
            },
            {
              title: 'Volume',
              key: 'volume',
              dataIndex: 'volume',
              width: '15%',
              render: (data: number) => currencyFormatter(data) || '-',
            },
            {
              title: 'Life Rate',
              key: 'lifeRate',
              dataIndex: 'lifeRate',
              width: '15%',
              render: (data: number) => data || '-',
            },
            {
              title: 'AD&D Rate',
              key: 'addRate',
              dataIndex: 'addRate',
              width: '15%',
              render: (data: number) => data || '-',
            },
            {
              title: 'Total Monthly Cost',
              key: 'totalMonthlyCost',
              dataIndex: 'totalMonthlyCost',
              width: '20%',
              render: (data: number) => currencyFormatter(data) || '-',
            },
          ]
        );
      }

      return columns;
    } else {
      return [];
    }
  };

  const getArrangedRows = (rowData: any[]) => {
    return rowData?.sort(
      (x: any, y: any) =>
        N_TIER_PRIORITY_ORDER?.indexOf(x?.name) -
        N_TIER_PRIORITY_ORDER?.indexOf(y?.name)
    );
  };

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.proposalCardTagArea}>
        <div className={styles.proposalTag}>
          <span className={styles.header}>{data.title}</span>
          {data?.newPlan && <NewPlanTag className={styles.newPlanTagWrapper} />}

          {['STD', 'DENTAL', 'VISION', 'MEDICAL'].includes(data?.type) && (
            <Row className={styles.stdOfferFundingTypeTag}>
              <FundingTypeTag fundingType={data?.stdFundingType} />
            </Row>
          )}
        </div>

        {!data?.newPlan &&
          (data?.type ===
            PROPOSAL_DETAILED_RENEWAL_TYPES.medical.toUpperCase() ||
            data?.type ===
              PROPOSAL_DETAILED_RENEWAL_TYPES.dental.toUpperCase() ||
            data?.type ===
              PROPOSAL_DETAILED_RENEWAL_TYPES.vision.toUpperCase()) && (
            <LinkButton onClick={() => setComparePlansVisible(true)}>
              Compare Similar Plans
            </LinkButton>
          )}
      </div>

      <Row justify={'space-between'}>
        <LinkButton onClick={onPlanDetailsClick}>Plan Details</LinkButton>
        <Col>
          {data?.isHra && (
            <span className={styles.subHeader}>
              HRA Utilization : {data?.hraUtilization} %
            </span>
          )}
        </Col>
      </Row>
      <SummeryCommonTable
        columns={getTableColumns()}
        tableClassName={styles.table}
        data={getArrangedRows(data?.tableData?.rowData)}
        summary={() => getSummary()}
        scroll={{ x: 'auto' }}
      />
      <ComparePlans
        visible={comparePlansVisible}
        onClose={setComparePlansVisible}
        initialSelectedPlanIndex={0}
        selectedPlanId={data?.planId}
        benefitType={data?.type}
        offerStatus={'RENEWAL'}
        planYearId={data?.planYearId}
        isOffer={false}
      />
    </div>
  );
};

export default ProposalOfferDetailsCard;
