import { FC, MutableRefObject } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import PlanDocuments from 'modules/plans/components/PlanDocuments/PlanDocuments';
import { uploadDentalTemporaryDocument } from 'modules/plans/slices/dentalPlanSlice';

import { DentalPlanDocumentType } from 'modules/plans/constants';

type PlanDocumentUPWrapperProps = {
  onChange: Function;
  onFileChange?: Function;
  wrappedRef: MutableRefObject<any>;
  plan?: any;
  onValidateFails?: (validateSetting: string) => void;
  isDisable?: boolean;
  isAFPReview?: boolean;
  isOnlyAfpVisible?: boolean;
};

const PlanDocumentUPWrapper: FC<PlanDocumentUPWrapperProps> = (props) => {
  const {
    plan,
    onChange,
    wrappedRef,
    onValidateFails,
    isDisable = false,
    isOnlyAfpVisible = false,
    onFileChange = (document: File, docType: string) => {
      dispatch(uploadDentalTemporaryDocument(document, docType));
    },
  } = props;
  const { dentalPlan } = useAppSelector((state) => state.plan.dentalPlan);
  const dispatch = useAppDispatch();

  return (
    <PlanDocuments
      ref={wrappedRef}
      planDocumentTypes={DentalPlanDocumentType}
      onFileChange={onFileChange}
      plan={plan ?? dentalPlan}
      onPlanChange={onChange}
      onValidateFails={onValidateFails}
      isDisable={isDisable}
      isOnlyAfpVisible={isOnlyAfpVisible}
    />
  );
};

export default PlanDocumentUPWrapper;
