import { FC } from 'react';
import { Button, Row } from 'antd';

import { NativeButtonProps } from 'antd/lib/button/button';

import styles from './nextButton.module.less';

interface NextButtonProps extends NativeButtonProps {
  nextStep: () => void;
  className?: string;
  buttonText?: React.ReactNode;
}

const NextButton: FC<NextButtonProps> = ({
  nextStep,
  disabled,
  className = styles.nextBtn,
  buttonText = 'Next',
  ...rest
}) => {
  return (
    <div>
      <Row className={styles.nextBtnWrapper}>
        <Button
          type="primary"
          onClick={nextStep}
          className={
            !disabled ? className : `${styles.disabledBtn} ${className}`
          }
          disabled={disabled}
          {...rest}
          data-cy="nextBtn"
        >
          {buttonText}
        </Button>
      </Row>
    </div>
  );
};

export default NextButton;
