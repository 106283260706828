import { FC, useCallback, useEffect, useState } from 'react';

import { Form, Input, Modal, Select, Spin } from 'antd';

import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import SelectOptions from 'components/SelectOptions/SelectOptions';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import BenefitClassMultiSelect from 'components/BenefitClassMultiSelect/BenefitClassMultiSelect';
import PanelInputForm from 'modules/plans/components/PanelInputForm/PanelInputForm';
import {
  BenefitCategory,
  maxPlanNameSize,
  RENEWALS_STARTED_WARNING,
  VolBenefitBenefitKinds,
} from 'constants/commonConstants';
import { getPlanYearRangeWithCurrent } from 'util/commonUtil';
import PlanYear from 'model/PlanYear';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import {
  clearAdditionalPerksApiErrors,
  saveAdditionalPerkPlan,
} from 'modules/plans/slices/additionalPerkPlanSlice';
import {
  clearMedicalPlanApiErrors,
  saveMedicalPlan,
} from 'modules/plans/slices/medicalPlanSlice';
import {
  clearDentalPlanApiErrors,
  saveDentalPlan,
} from 'modules/plans/slices/dentalPlanSlice';
import {
  clearVisionPlanApiErrors,
  saveVisionPlan,
} from 'modules/plans/slices/visionPlanSlice';
import {
  clearTelehealthPlanApiErrors,
  saveTelehealthRxPlan,
} from 'modules/plans/slices/telehealthRxPlanSlice';
import {
  clearRetirementPlanApiErrors,
  saveRetirementPlan,
} from 'modules/plans/slices/retirementPlanSlice';
import {
  clearLifePlanApiErrors,
  saveLifePlan,
} from 'modules/plans/slices/lifePlanSlice';
import {
  clearApiErrors,
  saveWorkLifePlan,
} from 'modules/plans/slices/workLifePlanSlice';
import {
  clearWellBeingPlanApiErrors,
  saveWellbeingPlan,
} from 'modules/plans/slices/wellbeingPlanSlice';
import {
  saveTaxAdvantagedAccountPlan,
  unsetApiError,
} from 'modules/plans/slices/taxAdvantagedAccountPlanSlice';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import {
  clearVolBenefitPlanApiErrors,
  saveVoluntaryBenefitPlan,
} from 'modules/plans/slices/voluntaryBenefitPlanSlice';
import {
  TaxAdvantagedAccountPlanType,
  VALIDATION_NAME_DUPLICATED,
} from 'modules/plans/constants';
import { buildRates, copyServicesForCloning } from 'modules/plans/utils';

import styles from './clonePlanModal.module.less';

type ClonePlanModalProps = {
  visible: boolean;
  onCancel: Function;
  plan: any;
  benefitCategory: string;
  onSaveClose: Function;
  isRenewalStartedWarning: boolean;
  isLoading?: boolean;
};

type MultiSelect = {
  groups: string[];
};

const basicPlanDefaultValues = {
  planName: '',
  planYear: '',
  benefitClass: [],
};

const { Option } = Select;

export type BasicPlanData = {
  planName: string;
  planYear: string;
  benefitClass: string[];
};

export const planBasicRequiredFields = ['planName', 'planYear', 'benefitClass'];

const ClonePlanModal: FC<ClonePlanModalProps> = (
  props: ClonePlanModalProps
) => {
  const {
    visible,
    onCancel,
    plan,
    benefitCategory,
    onSaveClose,
    isRenewalStartedWarning = false,
    isLoading = false,
  } = props;

  const [benefitClassesList, setBenefitClassesList] = useState<string[]>([]);
  const [multiSelect, setMultiSelect] = useState<MultiSelect>({
    groups: [],
  });
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<BasicPlanData>({
    ...basicPlanDefaultValues,
  });

  const dispatch = useAppDispatch();
  const { employerId } = useNavContext();
  const { planYearsList } = useAppSelector((state) => state.employer.employer);
  const { inProgress } = useAppSelector((state) => state.employer.employer);
  const { error: medicalError } = useAppSelector((state) => state.plan.plans);
  const { error: dentalError } = useAppSelector(
    (state) => state.plan.dentalPlan
  );
  const { error: additionalError } = useAppSelector(
    (state) => state.plan.additionalPerk
  );
  const { error: teleHealthError } = useAppSelector(
    (state) => state.plan.telehealthRxPlan
  );
  const { error: wellbeingError } = useAppSelector(
    (state) => state.plan.wellbeing
  );
  const { error: visionError } = useAppSelector(
    (state) => state.plan.visionPlan
  );
  const { error: workLifeError } = useAppSelector(
    (state) => state.plan.holidaysTimeOffPlan
  );
  const { error: lifeError } = useAppSelector((state) => state.plan.lifePlan);
  const { error: taxAdvantagedError } = useAppSelector(
    (state) => state.plan.taxAdvantagedAccountPlan
  );
  const { error: volBenefitError } = useAppSelector(
    (state) => state.plan.voluntaryBenefitPlan
  );
  const { error: retirementError } = useAppSelector(
    (state) => state.plan.retirementPlan
  );

  useEffect(() => {
    if (plan) {
      const { name, planYearId, groups } = plan;

      const editPlanData = {
        planName: `CLONE - ${name}`,
        planYear: planYearId,

        benefitClass: groups,
      } as BasicPlanData;

      setFormData(editPlanData);
      form.setFieldsValue({ ...editPlanData });
    }
  }, [form, plan]);

  useEffect(() => {
    if (employerId) {
      dispatch(getPlanYears(employerId));
    }
  }, [dispatch, employerId]);

  useEffect(() => {
    if (formData.benefitClass) {
      setMultiSelect({ groups: formData.benefitClass });
    }
  }, [formData.benefitClass]);

  useEffect(() => {
    if (formData.planYear && !isEmpty(planYearsList)) {
      const planYear = planYearsList.find((t) => t.id === formData.planYear);
      setBenefitClassesList(planYear?.benefitGroups || []);
    }
  }, [formData.planYear, planYearsList]);

  useEffect(() => {
    let exists;
    switch (benefitCategory) {
      case BenefitCategory.MEDICAL.value:
        exists = medicalError?.data?.code === VALIDATION_NAME_DUPLICATED;
        dispatch(clearMedicalPlanApiErrors());
        break;
      case BenefitCategory.DENTAL.value:
        exists = dentalError?.data?.code === VALIDATION_NAME_DUPLICATED;
        dispatch(clearDentalPlanApiErrors());
        break;
      case BenefitCategory.ADDITIONAL_PERK.value:
        exists = additionalError?.data?.code === VALIDATION_NAME_DUPLICATED;
        dispatch(clearAdditionalPerksApiErrors());
        break;
      case BenefitCategory.TELEHEALTH.value:
        exists = teleHealthError?.data?.code === VALIDATION_NAME_DUPLICATED;
        dispatch(clearTelehealthPlanApiErrors());
        break;
      case BenefitCategory.LIFE.value:
        exists = lifeError?.data?.code === VALIDATION_NAME_DUPLICATED;
        dispatch(clearLifePlanApiErrors());
        break;
      case BenefitCategory.WELLBEING.value:
        exists = wellbeingError?.data?.code === VALIDATION_NAME_DUPLICATED;
        dispatch(clearWellBeingPlanApiErrors());
        break;
      case BenefitCategory.VISION.value:
        exists = visionError?.data?.code === VALIDATION_NAME_DUPLICATED;
        dispatch(clearVisionPlanApiErrors());
        break;
      case BenefitCategory.HOLIDAYS_AND_TIME_OFF.value:
      case BenefitCategory.FAMILY_AND_LEAVE.value:
        exists = workLifeError?.data?.code === VALIDATION_NAME_DUPLICATED;
        dispatch(clearApiErrors());
        break;
      case BenefitCategory.SAVING.value:
        exists = taxAdvantagedError?.data?.code === VALIDATION_NAME_DUPLICATED;
        dispatch(unsetApiError());
        break;
      case BenefitCategory.RETIREMENT.value:
        exists = retirementError?.data?.code === VALIDATION_NAME_DUPLICATED;
        dispatch(clearRetirementPlanApiErrors());
        break;
      case VolBenefitBenefitKinds.CUSTOM_PLAN.value:
        exists = volBenefitError?.data?.code === VALIDATION_NAME_DUPLICATED;
        dispatch(clearVolBenefitPlanApiErrors());
        break;
      default:
        break;
    }
    if (exists) {
      form.setFields([{ name: 'planName', errors: ['Name already exists'] }]);
    }
  }, [
    dispatch,
    benefitCategory,
    form,
    additionalError,
    dentalError,
    medicalError,
    visionError,
    volBenefitError,
    lifeError,
    taxAdvantagedError,
    retirementError,
    teleHealthError,
    workLifeError,
    wellbeingError,
  ]);

  const onPlanYearSelect = useCallback(
    (value: string) => {
      const planYear = planYearsList.find((t) => t.id === value);
      if (planYear) {
        setFormData((prevData: any) => ({
          ...prevData,
          planYear: planYear.id,
        }));
        setBenefitClassesList(planYear.benefitGroups);
        const benefitClasses =
          planYear.benefitGroups.length === 1 ? planYear.benefitGroups : [];
        form.setFieldsValue({
          planYear: planYear.id,
          benefitClass: benefitClasses,
        });
        setMultiSelect({
          groups: benefitClasses,
        });
        form.setFieldsValue({
          benefitClass: benefitClasses,
        });
        setFormData((prevData: any) => ({
          ...prevData,
          benefitClass: benefitClasses,
        }));
      }
    },
    [planYearsList, form, setFormData]
  );

  const getCheckboxDataForGroups = (
    checkboxValues: string[],
    value: string,
    checked: boolean
  ): string[] => {
    let newCheckboxValues = cloneDeep(checkboxValues);
    if (checked) {
      newCheckboxValues = [...checkboxValues, value];
    } else {
      const index = checkboxValues.findIndex((element) => element === value);
      if (index > -1) {
        newCheckboxValues.splice(index, 1);
      }
    }
    return newCheckboxValues;
  };

  const onCheckboxSelection = (event: any) => {
    const { name, value, checked } = event.target;
    const newCheckboxValues = getCheckboxDataForGroups(
      multiSelect.groups,
      value,
      checked
    );
    setMultiSelect((prevState) => ({
      ...prevState,
      [name]: newCheckboxValues,
    }));
    form.setFieldsValue({ benefitClass: newCheckboxValues });
    setFormData({
      ...formData,
      benefitClass: newCheckboxValues,
    });
  };

  const footerComponent = (
    <div>
      <SubmitButton
        type="primary"
        onClick={() => {
          handleSubmit();
        }}
        className={styles.cloneBtn}
        loading={isLoading}
      >
        Clone Plan
      </SubmitButton>
      <CancelButton onClick={() => onCancel(false)}>Cancel</CancelButton>
    </div>
  );

  const validateBasicInfo = async () => {
    try {
      await form.validateFields(planBasicRequiredFields);
      return true;
    } catch (errorInfo: any) {
      return errorInfo.errorFields.length === 0;
    }
  };

  const getValidationResult = async () => {
    const isFormValid = await validateBasicInfo();
    if (isFormValid) {
      return true;
    } else {
      return false;
    }
  };

  const onSave = () => {
    onCancel();
    onSaveClose();
  };

  const handleSubmit = async () => {
    const isFormValid = await getValidationResult();
    const selectedPlanYear = planYearsList.find(
      (t) => t.id === formData.planYear
    );

    const newPlan = cloneDeep(plan);
    const originPlanYearId = plan.planYearId;
    newPlan.id = null;
    newPlan.benGuideIDRevisions = null;
    newPlan.endDate = selectedPlanYear?.endDate;
    newPlan.name = formData.planName;
    newPlan.planYearId = selectedPlanYear?.id;
    newPlan.startDate = selectedPlanYear?.startDate;
    newPlan.groups = formData.benefitClass;
    newPlan.rates = buildRates(formData.benefitClass, newPlan);
    newPlan.hasSameContributions = plan.hasSameContributions;
    newPlan.benefitCarrierId = newPlan?.benefitCarrier
      ? newPlan?.benefitCarrier?.id.trim()
      : null;
    if (newPlan.customServices) {
      newPlan.customServices = copyServicesForCloning(newPlan.customServices);
    }

    if (isEmpty(newPlan?.documentReferences)) {
      delete newPlan?.documentReferences;
    }

    if (isFormValid) {
      switch (benefitCategory) {
        case BenefitCategory.MEDICAL.value:
          await dispatch(saveMedicalPlan(newPlan, onSave, '', true, plan.id));
          break;
        case BenefitCategory.DENTAL.value:
          await dispatch(saveDentalPlan(newPlan, onSave, true, plan.id));
          break;
        case BenefitCategory.ADDITIONAL_PERK.value:
          await dispatch(
            saveAdditionalPerkPlan(newPlan, onSave, true, plan.id)
          );
          break;
        case BenefitCategory.TELEHEALTH.value:
          await dispatch(saveTelehealthRxPlan(newPlan, onSave, true, plan.id));
          break;
        case BenefitCategory.LIFE.value:
          await dispatch(saveLifePlan(newPlan, onSave, true, plan.id));
          break;
        case BenefitCategory.WELLBEING.value:
          await dispatch(saveWellbeingPlan(newPlan, onSave, true, plan.id));
          break;
        case BenefitCategory.VISION.value:
          await dispatch(saveVisionPlan(newPlan, onSave, true, plan.id));
          break;
        case BenefitCategory.HOLIDAYS_AND_TIME_OFF.value:
        case BenefitCategory.FAMILY_AND_LEAVE.value:
          await dispatch(
            saveWorkLifePlan(newPlan, onSave, true, plan.id, benefitCategory)
          );
          break;
        case BenefitCategory.SAVING.value:
          newPlan.selectedMedicalPlans =
            newPlan.planYearId !== originPlanYearId
              ? []
              : newPlan.selectedMedicalPlans;

          // Reset contributions and selected plans if cloned cause there can't be two tagged plans
          if (newPlan?.benefitKind === TaxAdvantagedAccountPlanType.HRA.value) {
            newPlan.selectedMedicalPlans = [];
            newPlan.selectedDentalPlans = [];
            newPlan.selectedVisionPlans = [];
            newPlan.employerContribution = {};
          }

          await dispatch(
            saveTaxAdvantagedAccountPlan(newPlan, onSave, true, plan.id)
          );
          break;
        case BenefitCategory.RETIREMENT.value:
          await dispatch(saveRetirementPlan(newPlan, onSave, true, plan.id));
          break;
        case VolBenefitBenefitKinds.CUSTOM_PLAN.value:
          await dispatch(
            saveVoluntaryBenefitPlan(newPlan, onSave, true, plan.id)
          );
          break;
        default:
          break;
      }
    }
  };

  const onInputChange = (changedValues: any, allValues: any) => {
    const { planName, planYear } = allValues;

    form.setFieldsValue({ planName, planYear });
    setFormData({
      ...formData,
      ...changedValues,
      benefitClass: formData.benefitClass,
    });
  };

  const trimInput = (event: any) => {
    const { value } = event.target;
    form.setFieldsValue({ ['planName']: value.trim() });
  };

  return inProgress ? (
    <div className={styles.loadingIconStyle}>
      <Spin />
    </div>
  ) : (
    <Modal
      wrapClassName={styles.modalWrapper}
      title="Clone Plan"
      width={550}
      centered
      visible={visible}
      footer={footerComponent}
    >
      {isRenewalStartedWarning && (
        <FixedAlertMessage
          className={styles.alertMessage}
          type="warning"
          message={RENEWALS_STARTED_WARNING}
        />
      )}
      <PanelInputForm
        form={form}
        onValuesChange={onInputChange}
        className={styles.formWrapper}
      >
        <Form.Item
          className={styles.planName}
          name="planName"
          label="Plan Name"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: 'Plan Name required',
              validateTrigger: ['onSubmit'],
            },
          ]}
        >
          <Input
            onBlur={trimInput}
            title="planName"
            maxLength={maxPlanNameSize}
          />
        </Form.Item>
        <Form.Item
          className={styles.filterWrapper}
          label="Effective Dates"
          labelCol={{ span: 24 }}
          name="planYear"
          rules={[{ required: true, message: 'Plan Year required' }]}
        >
          <SelectOptions
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            onSelect={onPlanYearSelect}
          >
            {planYearsList &&
              planYearsList
                .filter((planYear) => !planYear.previous)
                .map((planYear: PlanYear) => (
                  <Option value={planYear.id} key={planYear.id}>
                    {`${planYear.name} ${getPlanYearRangeWithCurrent(
                      planYear
                    )}`}
                  </Option>
                ))}
          </SelectOptions>
        </Form.Item>
        <Form.Item
          name="benefitClass"
          className={styles.filterWrapper}
          label="Benefit Classes"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: 'Benefit Classes required',
            },
          ]}
        >
          <>
            <Input hidden value={multiSelect.groups.join(', ')} />
            <Form.Item name="benefitClass" className={styles.benefitClasses}>
              <BenefitClassMultiSelect
                options={benefitClassesList}
                onChange={onCheckboxSelection}
                name="groups"
                disabled={!formData.planYear}
                selectedItemValues={multiSelect.groups}
              />
            </Form.Item>
          </>
        </Form.Item>
      </PanelInputForm>
    </Modal>
  );
};

export default ClonePlanModal;
