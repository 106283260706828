import { BenefitCategory } from 'constants/commonConstants';
import { DentalPlan } from 'model/plans/DentalPlan';
import MedicalPlan from 'model/plans/MedicalPlan';
import { VisionPlan } from 'model/plans/VisionPlan';
import EditDentalPlanModal from 'modules/plans/dental/components/EditDentalPlanModal/EditDentalPlanModal';
import EditMedicalPlanModal from 'modules/plans/medical/components/EditMedicalPlanModal/EditMedicalPlanModal';
import EditVisionPlanModal from 'modules/plans/vision/components/EditVisionPlanModal/EditVisionPlanModal';

type EditType = {
  value: string;
  title: string;
  width: number;
};

type EnrollmentEditProps = {
  editedPlan?: DentalPlan | VisionPlan | MedicalPlan;
  benefitKind: string;
  fetchPlanWhenUpdated?: any;
  viewPlanEnrollmentModal: boolean;
  setPlanEnrollmentModal: (value: boolean) => void;
  onClose: Function;
  editType: EditType;
};

const EditEnrollmentsMDVModal = (props: EnrollmentEditProps) => {
  const {
    benefitKind,
    editedPlan,
    setPlanEnrollmentModal,
    viewPlanEnrollmentModal = false,
    onClose,
    editType,
  } = props;
  const renderMDVEditModal = () => {
    switch (benefitKind) {
      case BenefitCategory.MEDICAL.value:
        return (
          <EditMedicalPlanModal
            plan={editedPlan}
            onClose={onClose}
            isModalVisible={viewPlanEnrollmentModal}
            editType={editType}
            setViewModal={setPlanEnrollmentModal}
          />
        );

      case BenefitCategory.DENTAL.value:
        return (
          <EditDentalPlanModal
            plan={editedPlan}
            onClose={onClose}
            isModalVisible={viewPlanEnrollmentModal}
            editType={editType}
            setViewModal={setPlanEnrollmentModal}
          />
        );

      case BenefitCategory.VISION.value:
        return (
          <EditVisionPlanModal
            plan={editedPlan}
            onClose={onClose}
            isModalVisible={viewPlanEnrollmentModal}
            editType={editType}
            setViewModal={setPlanEnrollmentModal}
          />
        );

      default:
        return null;
    }
  };
  return <>{renderMDVEditModal()}</>;
};

export default EditEnrollmentsMDVModal;
