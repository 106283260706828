import { Dispatch, createSlice } from '@reduxjs/toolkit';
import {
  IBenGuide,
  IPlanBGCloneInitialState,
  ITransformedResult,
} from 'modules/employers/types/planBGCloneTypes';
import { transformedPlanBGForModal } from 'modules/employers/utils/clonePlanUtils';
import * as PlanBGCloneService from 'modules/employers/services/PlanBGCloneService';
import { RootState } from 'app/store';

const initialState: IPlanBGCloneInitialState = {
  selectedPlanYear: {},
  clonePlanBGList: {
    plans: {},
    benGuides: [],
  },
  clonePlanBGListLoading: false,
  clonePlanBGListError: null,
};

const planBGCloneSlice = createSlice({
  name: 'planBGClone',
  initialState: initialState,
  reducers: {
    setSelectedPlanYear: (state, { payload }) => {
      state.selectedPlanYear = payload;
    },
    clearSelectedPlanYear: (state) => {
      state.selectedPlanYear = {};
    },
    setClonePlanBGListLoading: (state) => {
      state.clonePlanBGListLoading = true;
    },
    setClonePlanBGList: (state, { payload }) => {
      state.clonePlanBGList = payload;
      state.clonePlanBGListLoading = false;
    },
    setClonePlanBGError: (state, { payload }) => {
      state.clonePlanBGListLoading = false;
      state.clonePlanBGListError = payload;
    },
    clearClonePlanBG: () => initialState,
    updatePlanList: (state, { payload }) => {
      state.clonePlanBGList.plans = payload;
    },
    updateBenGuideList: (state, { payload }) => {
      state.clonePlanBGList.benGuides = payload;
    },
  },
});

export const {
  setSelectedPlanYear,
  clearSelectedPlanYear,
  setClonePlanBGListLoading,
  setClonePlanBGList,
  setClonePlanBGError,
  clearClonePlanBG,
  updatePlanList,
  updateBenGuideList,
} = planBGCloneSlice.actions;

export default planBGCloneSlice.reducer;

export const getCurrentPlansBG =
  ({
    currentPlanYearId,
    employerId,
  }: {
    currentPlanYearId: string;
    employerId: string;
  }) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(setClonePlanBGListLoading());
      const { data } = await PlanBGCloneService.fetchCurrentPlansBG({
        currentPlanYearId,
        employerId,
      });
      const newBenefitClasses =
        getState().employer.planBGClone.selectedPlanYear.benefitGroups;

      const transformedData: {
        plans: Partial<ITransformedResult>;
        benGuides: IBenGuide[];
      } = transformedPlanBGForModal(data, newBenefitClasses!);

      dispatch(setClonePlanBGList(transformedData));
    } catch (error: any) {
      dispatch(setClonePlanBGError(error?.response?.data));
    }
  };
