import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Row } from 'antd';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { useRetryAutomatedEmailVarificationMutation } from 'modules/tools/slices/issuesLogProvisioningSlice';
import { downloadInstructionPdf } from 'modules/issueslog/slices/issuesLogSlice';
import { usePrevious } from 'hooks/usePrevious';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';

import styles from './resendAutomatedEmailModal.module.less';

type ResendAutomatedEmailModal = {
  visible: boolean;
  onCancel: () => void;
  email: string;
  onComplete: () => void;
};

const ResendAutomatedEmailModal: FC<ResendAutomatedEmailModal> = ({
  visible,
  onCancel,
  email,
  onComplete,
}) => {
  const params = useParams();
  const [retyVerification, { isLoading, isSuccess }] =
    useRetryAutomatedEmailVarificationMutation();

  const previousLoading = usePrevious(isLoading);

  useEffect(() => {
    if (previousLoading && !isLoading && isSuccess) {
      onComplete();
    }
  }, [previousLoading, isLoading, isSuccess, onComplete]);

  return (
    <Modal
      centered
      visible={visible}
      width={600}
      closeIcon={<></>}
      footer={false}
      className={styles.resendAutomatedEmailModal}
      destroyOnClose={true}
    >
      <div>
        <h1 className={styles.title}>Send Test Email</h1>
        <div className={styles.email}>{email}</div>
        <p className={styles.description}>
          The connection between this email address and the PlanYear account log
          hasn’t been confirmed yet. The confirmation process may take a few
          minutes so if you haven&#39;t done so already, please wait and refresh
          the page to see if the connection was confirmed.
        </p>
        <p>
          Otherwise, please double-check that the
          <span
            className={styles.setupInstructions}
            onClick={() => {
              downloadInstructionPdf(params.brokerId);
            }}
          >
            email setup instructions
          </span>
          were properly followed for this email address. Once you confirm that
          they are correct, you can use the button below to send another test
          email to check the connection.
        </p>
        <PageActionButton
          loading={isLoading}
          type="primary"
          className={styles.sendButton}
          onClick={() => {
            retyVerification({ email: email });
          }}
        >
          Send Test Email
        </PageActionButton>
        <Row justify="center" className={styles.cancelWrapper}>
          <CancelButton disabled={false} onClick={onCancel}>
            Cancel
          </CancelButton>
        </Row>
      </div>
    </Modal>
  );
};

export default ResendAutomatedEmailModal;
