import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { loginTypes } from 'constants/authConstants';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  ISSUES_LOG_FILTER_TYPES,
  ISSUE_DAYS_OPEN_DEFAULT_MAP,
  ISSUE_DAYS_OPEN_MAP,
  ISSUE_LAST_MODIFIED_DEFAULT_MAP,
  ISSUE_LAST_MODIFIED_MAP,
  ISSUE_STATUS_BROKER_TECH_DEFAULT_MAP,
  ISSUE_STATUS_BROKER_TECH_MAP,
  ISSUE_SUBTYPE_DEFAULT_MAP,
  ISSUE_SUBTYPE_MAP,
  PRIORITY_DEFAULT_MAP,
  PRIORITY_MAP,
  SUPPORT_TEAM_LABEL_UNKNOWN,
  SUPPORT_TEAM_VALUE_UNKNOWN,
  VIEWABLE_DEFAULT_MAP,
  VIEWABLE_MAP,
  WATCHING_FILTER_DEFAULT_MAP,
  WATCHING_MAP,
} from 'modules/issueslog/constants/IssueLogFilterConstants';
import {
  addOrReplace,
  createUniqueId,
  issueType,
  jsonParseStringArray,
  jsonStringifyNestedArray,
} from 'modules/issueslog/utils/issueLogUtils';
import { saveFilter } from 'modules/issueslog/slices/issuesLogFilterSlice';
import FilterDropDown from 'components/FilterDropDown/FilterDropDown';
import styles from './brOrTechAdminFilter.module.less';

type BrTechAdminFiltersProps = {
  filterRefs: any;
  showMoreFilters: boolean;
  employerOptions: any[];
  employerTeamMembersOptions: any[];
  supportTeamOptions: any[];
  brokeragesOptions: any[];
  assigneeOptions: any[];
  assigneeArchivedOption: any[];
  supportArchivedOption: any[];
  supportTeamAllOptionsToReset: any[];
};

const BrTechAdminFilters = ({
  filterRefs,
  showMoreFilters,
  employerOptions,
  employerTeamMembersOptions,
  supportTeamOptions,
  brokeragesOptions,
  assigneeOptions,
  assigneeArchivedOption,
  supportArchivedOption,
  supportTeamAllOptionsToReset,
}: BrTechAdminFiltersProps) => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const appBootInfo: any = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );

  const isEmployerView = !!params?.employerId;
  const isPlatformAdmin: boolean = appBootInfo?.type === loginTypes.platform;
  const isBrokerAdmin: boolean = appBootInfo?.type === loginTypes.bokerAdmin;

  const saveFiltersID = createUniqueId(
    params,
    isEmployerView,
    appBootInfo?.type
  );

  const savedFilterState = useAppSelector(
    (state) => state?.filters[saveFiltersID]
  );

  const getSupportTeamIntersectionOptions = (
    originalArray: any[],
    valueArray: string[],
    intersectionKey: string
  ) => {
    if (valueArray.includes(SUPPORT_TEAM_VALUE_UNKNOWN)) {
      valueArray = [...valueArray, SUPPORT_TEAM_LABEL_UNKNOWN];
    }

    let intersection = originalArray?.filter((item: any) =>
      valueArray?.includes(item[intersectionKey])
    );

    if (supportArchivedOption) {
      const foundObjList = supportArchivedOption?.filter((item: any) => {
        const foundObj = valueArray.find((obj) => obj === item.label);
        return undefined != foundObj;
      });
      intersection = [...intersection, ...foundObjList];
    }

    return intersection;
  };

  const getFilterDefaultFromState = (type: string) => {
    const optionArray: any[] =
      savedFilterState?.filters?.filters?.find(
        (item: any) => item?.name === type
      )?.values || [];

    return optionArray;
  };

  const handelOkayOrReset = ({
    filterType,
    values,
  }: {
    filterType: string;
    values: any[];
  }) => {
    const addReplaceFilterArray = addOrReplace(
      savedFilterState?.filters?.filters || [],
      { name: filterType, values: values }
    );
    dispatch(
      saveFilter({
        clickedSaved: true,
        filters: {
          searchKey: savedFilterState?.filters?.searchKey || '',
          filters: addReplaceFilterArray,
        },
        filterSaveType: saveFiltersID,
      })
    );
  };

  const isPartOfTeam = () => {
    return isBrokerAdmin && employerTeamMembersOptions?.length === 0;
  };

  return (
    <>
      {(isBrokerAdmin && !isEmployerView) ||
      (isEmployerView && params.type === issueType.SUPPORT) ? (
        <Col span={isEmployerView ? 6 : 5}>
          <FilterDropDown
            ref={filterRefs?.watchingRef}
            filterName={ISSUES_LOG_FILTER_TYPES.WATCHING}
            placeholder="Watching"
            showSearch={false}
            showCustomButton={false}
            showSelectDeselect={false}
            optionSort={false}
            options={WATCHING_MAP}
            defaultOptions={getFilterDefaultFromState(
              ISSUES_LOG_FILTER_TYPES.WATCHING
            )}
            resetOptions={WATCHING_FILTER_DEFAULT_MAP}
            handleOkayCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
            handleResetCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
          />
        </Col>
      ) : null}
      {isPlatformAdmin && !isEmployerView ? (
        <Col span={5}>
          <FilterDropDown
            ref={filterRefs?.brokerageRef}
            filterName={ISSUES_LOG_FILTER_TYPES.BROKERAGE}
            placeholder="Brokerage"
            searchPlaceholder="Search Brokerage"
            showCustomButton={false}
            options={brokeragesOptions}
            defaultOptions={
              getFilterDefaultFromState(
                ISSUES_LOG_FILTER_TYPES.BROKERAGE
              ).includes('all')
                ? brokeragesOptions?.map(({ value }) => value)
                : getFilterDefaultFromState(ISSUES_LOG_FILTER_TYPES.BROKERAGE)
            }
            resetOptions={brokeragesOptions?.map(({ value }) => value)}
            handleOkayCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
            handleResetCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
          />
        </Col>
      ) : null}
      {(params.type === issueType.SUPPORT && isEmployerView) ||
      (isBrokerAdmin &&
        params?.type === issueType.SUPPORT &&
        !isEmployerView) ? (
        <Col span={isEmployerView ? 6 : 5}>
          <FilterDropDown
            ref={filterRefs?.supportTeamRef}
            filterName={ISSUES_LOG_FILTER_TYPES.SUPPORT_TEAM}
            placeholder="Support Team"
            searchPlaceholder="Search Support Team"
            showCustomButton={false}
            options={supportTeamOptions}
            defaultOptions={
              getSupportTeamIntersectionOptions(
                supportTeamOptions,
                getFilterDefaultFromState(ISSUES_LOG_FILTER_TYPES.SUPPORT_TEAM),
                'label'
              )
                ?.map((item: any) => item?.value)
                .includes('all')
                ? supportTeamAllOptionsToReset
                : getSupportTeamIntersectionOptions(
                    supportTeamOptions,
                    getFilterDefaultFromState(
                      ISSUES_LOG_FILTER_TYPES.SUPPORT_TEAM
                    ),
                    'label'
                  )?.map((item: any) => item?.value)
            }
            resetOptions={supportTeamAllOptionsToReset}
            handleOkayCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: getSupportTeamIntersectionOptions(
                  supportTeamOptions,
                  values,
                  'value'
                )?.map((item: any) =>
                  item?.label === SUPPORT_TEAM_LABEL_UNKNOWN
                    ? SUPPORT_TEAM_VALUE_UNKNOWN
                    : item?.label
                ),
              });
            }}
            archivedOptions={supportArchivedOption}
            archivedText={'ARCHIVED TEAMS'}
            handleResetCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
          />
        </Col>
      ) : null}

      {!isEmployerView ? (
        <Col span={5}>
          <FilterDropDown
            ref={filterRefs?.employerRef}
            filterName={ISSUES_LOG_FILTER_TYPES.EMPLOYER}
            placeholder="Employer"
            searchPlaceholder="Search Employers"
            showCustomButton={!isPlatformAdmin}
            customButtonLabel={'My Employers'}
            customButtonOptions={employerTeamMembersOptions?.map(
              ({ value }) => value
            )}
            customButtonDisable={isPartOfTeam()}
            options={employerOptions}
            defaultOptions={
              getFilterDefaultFromState(
                ISSUES_LOG_FILTER_TYPES.EMPLOYER
              ).includes('all')
                ? employerOptions?.map(({ value }) => value)
                : getFilterDefaultFromState(ISSUES_LOG_FILTER_TYPES.EMPLOYER)
            }
            resetOptions={employerOptions?.map(({ value }) => value)}
            handleOkayCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
            handleResetCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
          />
        </Col>
      ) : null}

      {(params.type === issueType.ACCOUNT && isEmployerView) ||
      (!isEmployerView &&
        isBrokerAdmin &&
        params?.type === issueType.ACCOUNT) ||
      (isPlatformAdmin && !isEmployerView) ? (
        <Col span={!isEmployerView ? 5 : 6}>
          <FilterDropDown
            ref={filterRefs?.statusRef}
            filterName={ISSUES_LOG_FILTER_TYPES.STATUS}
            placeholder="Status"
            showSearch={false}
            optionSort={false}
            showCustomButton={false}
            options={ISSUE_STATUS_BROKER_TECH_MAP}
            defaultOptions={getFilterDefaultFromState(
              ISSUES_LOG_FILTER_TYPES.STATUS
            )}
            resetOptions={ISSUE_STATUS_BROKER_TECH_DEFAULT_MAP}
            handleOkayCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
            handleResetCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
          />
        </Col>
      ) : null}

      {isEmployerView && params.type === issueType.ACCOUNT ? (
        <Col span={6}>
          <FilterDropDown
            ref={filterRefs?.priorityRef}
            filterName={ISSUES_LOG_FILTER_TYPES.PRIORITY}
            placeholder="Priority"
            showSearch={false}
            showCustomButton={false}
            optionSort={false}
            options={PRIORITY_MAP}
            defaultOptions={getFilterDefaultFromState(
              ISSUES_LOG_FILTER_TYPES.PRIORITY
            )}
            resetOptions={PRIORITY_DEFAULT_MAP}
            handleOkayCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
            handleResetCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
          />
        </Col>
      ) : null}

      {isEmployerView && params.type === issueType.ACCOUNT ? (
        <Col span={7}>
          <FilterDropDown
            ref={filterRefs?.typeRef}
            filterName={ISSUES_LOG_FILTER_TYPES.SUB_TYPE}
            placeholder="Type"
            searchPlaceholder="Search Type"
            showCustomButton={false}
            optionSort={false}
            options={ISSUE_SUBTYPE_MAP}
            defaultOptions={getFilterDefaultFromState(
              ISSUES_LOG_FILTER_TYPES.SUB_TYPE
            )}
            resetOptions={ISSUE_SUBTYPE_DEFAULT_MAP}
            handleOkayCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
            handleResetCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
          />
        </Col>
      ) : null}
      {(isEmployerView && params.type === issueType.SUPPORT) ||
      !isEmployerView ? (
        <Col span={isEmployerView ? 7 : 5}>
          <FilterDropDown
            ref={filterRefs?.assignedToRef}
            filterName={ISSUES_LOG_FILTER_TYPES.ASSIGNED_TO}
            placeholder="Assigned To"
            showSearch={true}
            searchPlaceholder="Search Admins"
            showCustomButton={!isPlatformAdmin}
            customButtonLabel="Assigned to Me"
            customButtonOptions={[appBootInfo.individualId]}
            optionSort={true}
            options={assigneeOptions}
            defaultOptions={
              getFilterDefaultFromState(
                ISSUES_LOG_FILTER_TYPES.ASSIGNED_TO
              ).includes('all')
                ? [...assigneeOptions, ...assigneeArchivedOption]?.map(
                    (item: any) => item?.value
                  )
                : getFilterDefaultFromState(ISSUES_LOG_FILTER_TYPES.ASSIGNED_TO)
            }
            archivedOptions={assigneeArchivedOption}
            resetOptions={[...assigneeOptions, ...assigneeArchivedOption]?.map(
              (item: any) => item?.value
            )}
            handleOkayCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
            handleResetCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
          />
        </Col>
      ) : null}

      {showMoreFilters ? (
        <Col className={styles.moreFilterRow} xs={24}>
          <Row gutter={16}>
            {params.type === issueType.SUPPORT && isPlatformAdmin ? (
              <Col span={4}>
                <FilterDropDown
                  ref={filterRefs?.supportTeamRef}
                  filterName={ISSUES_LOG_FILTER_TYPES.SUPPORT_TEAM}
                  placeholder="Support Team"
                  searchPlaceholder="Search Support Team"
                  showCustomButton={false}
                  options={[...supportTeamOptions, ...supportArchivedOption]}
                  defaultOptions={
                    getSupportTeamIntersectionOptions(
                      supportTeamOptions,
                      getFilterDefaultFromState(
                        ISSUES_LOG_FILTER_TYPES.SUPPORT_TEAM
                      ),
                      'label'
                    )
                      ?.map((item: any) => item?.value)
                      .includes('all')
                      ? supportTeamAllOptionsToReset
                      : getSupportTeamIntersectionOptions(
                          supportTeamOptions,
                          getFilterDefaultFromState(
                            ISSUES_LOG_FILTER_TYPES.SUPPORT_TEAM
                          ),
                          'label'
                        )?.map((item: any) => item?.value)
                  }
                  resetOptions={supportTeamAllOptionsToReset}
                  handleOkayCallback={(values: any, filterName) => {
                    handelOkayOrReset({
                      filterType: filterName,
                      values: getSupportTeamIntersectionOptions(
                        supportTeamOptions,
                        values,
                        'value'
                      )?.map((item: any) =>
                        item?.label === SUPPORT_TEAM_LABEL_UNKNOWN
                          ? SUPPORT_TEAM_VALUE_UNKNOWN
                          : item?.label
                      ),
                    });
                  }}
                  handleResetCallback={(values, filterName) => {
                    handelOkayOrReset({
                      filterType: filterName,
                      values: values,
                    });
                  }}
                />
              </Col>
            ) : null}

            {params.type === issueType.SUPPORT && isBrokerAdmin ? (
              <Col span={4}>
                <FilterDropDown
                  ref={filterRefs?.statusRef}
                  filterName={ISSUES_LOG_FILTER_TYPES.STATUS}
                  placeholder="Status"
                  showSearch={false}
                  optionSort={false}
                  showCustomButton={false}
                  options={ISSUE_STATUS_BROKER_TECH_MAP}
                  defaultOptions={getFilterDefaultFromState(
                    ISSUES_LOG_FILTER_TYPES.STATUS
                  )}
                  resetOptions={ISSUE_STATUS_BROKER_TECH_DEFAULT_MAP}
                  handleOkayCallback={(values, filterName) => {
                    handelOkayOrReset({
                      filterType: filterName,
                      values: values,
                    });
                  }}
                  handleResetCallback={(values, filterName) => {
                    handelOkayOrReset({
                      filterType: filterName,
                      values: values,
                    });
                  }}
                />
              </Col>
            ) : null}

            <Col span={params.type === issueType.SUPPORT ? 5 : 4}>
              <FilterDropDown
                ref={filterRefs?.priorityRef}
                filterName={ISSUES_LOG_FILTER_TYPES.PRIORITY}
                placeholder="Priority"
                showSearch={false}
                showCustomButton={false}
                optionSort={false}
                options={PRIORITY_MAP}
                defaultOptions={getFilterDefaultFromState(
                  ISSUES_LOG_FILTER_TYPES.PRIORITY
                )}
                resetOptions={PRIORITY_DEFAULT_MAP}
                handleOkayCallback={(values, filterName) => {
                  handelOkayOrReset({
                    filterType: filterName,
                    values: values,
                  });
                }}
                handleResetCallback={(values, filterName) => {
                  handelOkayOrReset({
                    filterType: filterName,
                    values: values,
                  });
                }}
              />
            </Col>

            <Col span={5}>
              <FilterDropDown
                ref={filterRefs?.typeRef}
                filterName={ISSUES_LOG_FILTER_TYPES.SUB_TYPE}
                placeholder="Type"
                searchPlaceholder="Search Type"
                showCustomButton={false}
                optionSort={false}
                options={ISSUE_SUBTYPE_MAP}
                defaultOptions={getFilterDefaultFromState(
                  ISSUES_LOG_FILTER_TYPES.SUB_TYPE
                )}
                resetOptions={ISSUE_SUBTYPE_DEFAULT_MAP}
                handleOkayCallback={(values, filterName) => {
                  handelOkayOrReset({
                    filterType: filterName,
                    values: values,
                  });
                }}
                handleResetCallback={(values, filterName) => {
                  handelOkayOrReset({
                    filterType: filterName,
                    values: values,
                  });
                }}
              />
            </Col>

            <Col span={5}>
              <FilterDropDown
                ref={filterRefs?.daysOpenRef}
                filterName={ISSUES_LOG_FILTER_TYPES.DAYS_OPEN}
                placeholder="Days Open"
                showSearch={false}
                showCustomButton={false}
                optionSort={false}
                options={ISSUE_DAYS_OPEN_MAP}
                defaultOptions={jsonStringifyNestedArray(
                  getFilterDefaultFromState(ISSUES_LOG_FILTER_TYPES.DAYS_OPEN)
                )}
                resetOptions={ISSUE_DAYS_OPEN_DEFAULT_MAP}
                handleOkayCallback={(values, filterName) => {
                  handelOkayOrReset({
                    filterType: filterName,
                    values: jsonParseStringArray(values),
                  });
                }}
                handleResetCallback={(values, filterName) => {
                  handelOkayOrReset({
                    filterType: filterName,
                    values: jsonParseStringArray(values),
                  });
                }}
              />
            </Col>

            <Col span={5}>
              <FilterDropDown
                ref={filterRefs?.lastModifiedRef}
                filterName={ISSUES_LOG_FILTER_TYPES.LAST_MODIFIED}
                placeholder="Last Modified"
                showSearch={false}
                showCustomButton={false}
                optionSort={false}
                options={ISSUE_LAST_MODIFIED_MAP}
                defaultOptions={jsonStringifyNestedArray(
                  getFilterDefaultFromState(
                    ISSUES_LOG_FILTER_TYPES.LAST_MODIFIED
                  )
                )}
                resetOptions={ISSUE_LAST_MODIFIED_DEFAULT_MAP}
                handleOkayCallback={(values, filterName) => {
                  handelOkayOrReset({
                    filterType: filterName,
                    values: jsonParseStringArray(values),
                  });
                }}
                handleResetCallback={(values, filterName) => {
                  handelOkayOrReset({
                    filterType: filterName,
                    values: jsonParseStringArray(values),
                  });
                }}
              />
            </Col>
            {!isEmployerView && params.type === issueType.ACCOUNT ? (
              <Col span={5}>
                <FilterDropDown
                  ref={filterRefs?.viewableByEmployerRef}
                  filterName={ISSUES_LOG_FILTER_TYPES.VIEWABLE_BY_EMPLOYER}
                  placeholder="Viewable by Employer"
                  showSearch={false}
                  showCustomButton={false}
                  optionSort={false}
                  showSelectDeselect={false}
                  options={VIEWABLE_MAP}
                  defaultOptions={getFilterDefaultFromState(
                    ISSUES_LOG_FILTER_TYPES.VIEWABLE_BY_EMPLOYER
                  )}
                  resetOptions={VIEWABLE_DEFAULT_MAP}
                  handleOkayCallback={(values, filterName) => {
                    handelOkayOrReset({
                      filterType: filterName,
                      values: values,
                    });
                  }}
                  handleResetCallback={(values, filterName) => {
                    handelOkayOrReset({
                      filterType: filterName,
                      values: values,
                    });
                  }}
                />
              </Col>
            ) : null}
          </Row>
        </Col>
      ) : null}
    </>
  );
};

export default BrTechAdminFilters;
