import { Col, Row, Spin, Tabs } from 'antd';
import { FC, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ReactComponent as PieChart } from 'assets/images/plans-empty.svg';
import { ReactComponent as PopoutIcon } from 'assets/images/icon-popout.svg';

import PlanYear from 'model/PlanYear';
import PlanYearDropdown from 'components/PlanYearDropdown/PlanYearDropdown';
import BenefitClassDropdown from 'components/BenefitClassDropdown/BenefitClassDropdown';
import PlanRatesList from 'modules/plans/planRates/pages/PlanRatesList/PlanRatesList';
import {
  getMedicalPlanRates,
  getDentalPlanRates,
  getVisionPlanRates,
  getLifeDisabilityPlanRates,
  exportPlanRatesExcel,
} from 'modules/plans/slices/planRatesSlice';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import styles from './planRates.module.less';

export const MEDICAL = 'MEDICAL';
export const DENTAL = 'DENTAL';
export const VISION = 'VISION';
export const LIFE_DISABILITY = 'LIFE_DISABILITY';

const { TabPane } = Tabs;

const PlanRates: FC = () => {
  const { medicalRates, dentalRates, visionRates, lifeDisabilityRates } =
    useAppSelector((state) => state.plan.planRates);
  const [selectedPlanYear, setSelectedPlanYear] = useState<PlanYear>({
    id: '',
    benefitGroups: [],
    employerId: '',
    startDate: '',
    endDate: '',
    current: false,
    name: '',
    next: false,
    previous: false,
  });
  const [selectedBenefitGroups, setSelectedBenefitGroups] = useState<string[]>(
    []
  );
  const [hasMedicalPlans, setHasMedicalPlans] = useState<boolean | null>(null);
  const [hasDentalPlans, setHasDentalPlans] = useState<boolean | null>(null);
  const [hasVisionPlans, setHasVisionPlans] = useState<boolean | null>(null);
  const [hasLifeDisabilityPlans, setHasLifeDisabilityPlans] = useState<
    boolean | null
  >(null);
  const [selectedTab, setSelectedTab] = useState(MEDICAL);
  const dispatch = useAppDispatch();
  const { brokerId, employerId, employer } = useNavContext();
  const navigate = useNavigate();

  const onChangePlanYear = useCallback(
    (value: PlanYear) => {
      if (selectedPlanYear !== value) {
        setHasMedicalPlans(null);
        setHasDentalPlans(null);
        setHasVisionPlans(null);
        setHasLifeDisabilityPlans(null);
        setSelectedPlanYear(value);
        setSelectedBenefitGroups(value.benefitGroups);
      }
    },
    [selectedPlanYear]
  );

  const onChangeBenefitClasses = useCallback((value: Array<string>) => {
    setSelectedBenefitGroups(value);
  }, []);

  useEffect(() => {
    if (selectedPlanYear.id && selectedBenefitGroups.length > 0) {
      dispatch(
        getMedicalPlanRates(
          selectedPlanYear.employerId,
          selectedPlanYear.id,
          selectedBenefitGroups
        )
      );

      dispatch(
        getDentalPlanRates(
          selectedPlanYear.employerId,
          selectedPlanYear.id,
          selectedBenefitGroups
        )
      );

      dispatch(
        getVisionPlanRates(
          selectedPlanYear.employerId,
          selectedPlanYear.id,
          selectedBenefitGroups
        )
      );

      dispatch(
        getLifeDisabilityPlanRates(
          selectedPlanYear.employerId,
          selectedPlanYear.id,
          selectedBenefitGroups
        )
      );
    }
  }, [dispatch, selectedPlanYear, selectedBenefitGroups]);

  useEffect(() => {
    if (
      selectedPlanYear.benefitGroups.length === selectedBenefitGroups.length &&
      medicalRates &&
      dentalRates &&
      visionRates &&
      lifeDisabilityRates
    ) {
      setHasMedicalPlans(Object.values(medicalRates).length > 0);
      setHasDentalPlans(Object.values(dentalRates).length > 0);
      setHasVisionPlans(Object.values(visionRates).length > 0);
      setHasLifeDisabilityPlans(Object.values(lifeDisabilityRates).length > 0);
    }
  }, [
    selectedPlanYear.benefitGroups.length,
    selectedBenefitGroups.length,
    medicalRates,
    dentalRates,
    visionRates,
    lifeDisabilityRates,
  ]);

  useEffect(() => {
    if (medicalRates && dentalRates && visionRates && lifeDisabilityRates) {
      if (Object.values(medicalRates).length === 0 && selectedTab === MEDICAL) {
        setSelectedTab(DENTAL);
      }
      if (Object.values(dentalRates).length === 0 && selectedTab === DENTAL) {
        setSelectedTab(VISION);
      }
      if (Object.values(visionRates).length === 0 && selectedTab === VISION) {
        setSelectedTab(LIFE_DISABILITY);
      }
      if (
        Object.values(lifeDisabilityRates).length === 0 &&
        selectedTab === LIFE_DISABILITY
      ) {
        setSelectedTab(MEDICAL);
      }
    }
  }, [
    medicalRates,
    dentalRates,
    visionRates,
    lifeDisabilityRates,
    selectedTab,
  ]);

  return (
    <div className={styles.planRates}>
      <Row>
        <Col span={24}>
          <Row className={styles.headerRow}>
            <Col span={12}>
              <h1 className={styles.titleWrapper}>
                <OverflowPopover>
                  {employer ? employer.name : ''} Plan Rates
                </OverflowPopover>
              </h1>
            </Col>
          </Row>
          <Row className={styles.planFilter}>
            <PlanYearDropdown
              onChange={onChangePlanYear}
              className={styles.planFilterLabel}
              defaultLatest={true}
            />
            <BenefitClassDropdown
              onChange={onChangeBenefitClasses}
              className={styles.planFilterLabel}
              allBenefitGroups={selectedPlanYear.benefitGroups}
            />
            {hasMedicalPlans ||
            hasDentalPlans ||
            hasVisionPlans ||
            hasLifeDisabilityPlans ? (
              <a
                className={styles.exportPlans}
                onClick={() => {
                  dispatch(
                    exportPlanRatesExcel(
                      selectedPlanYear.employerId,
                      selectedPlanYear.id,
                      selectedBenefitGroups
                    )
                  );
                }}
              >
                Export to Excel&nbsp;&nbsp;
                <PopoutIcon className={styles.popoutIcon} />
              </a>
            ) : null}
          </Row>
          {!medicalRates ||
          !dentalRates ||
          !visionRates ||
          !lifeDisabilityRates ? (
            <Spin />
          ) : (
            <>
              {!hasMedicalPlans &&
              !hasDentalPlans &&
              !hasVisionPlans &&
              !hasLifeDisabilityPlans ? (
                <div className={styles.noPlanView}>
                  <div className={styles.noPlanViewWrapper}>
                    <Row className={styles.description}>
                      <PieChart />
                    </Row>
                    <Row className={styles.description}>
                      <h2>You don’t have any Plans</h2>
                    </Row>
                    <Row className={styles.description}>
                      <p>Start by creating plans below:</p>
                    </Row>
                    <Row className={styles.description}>
                      <PageActionButton
                        type="primary"
                        className={styles.createPlansButton}
                        onClick={() =>
                          navigate(
                            `/brokers/${brokerId}/employers/${employerId}/medical`
                          )
                        }
                      >
                        Create Plans
                      </PageActionButton>
                    </Row>
                  </div>
                </div>
              ) : (
                <Tabs
                  type="card"
                  defaultActiveKey={selectedTab}
                  size="large"
                  className={styles.planTabs}
                  onChange={(key) => setSelectedTab(key)}
                >
                  {hasMedicalPlans && (
                    <TabPane tab="Medical" key={MEDICAL}>
                      <PlanRatesList
                        benefitKind={MEDICAL}
                        mdvRates={
                          selectedBenefitGroups.length === 0 ? {} : medicalRates
                        }
                        noGroupsSelected={selectedBenefitGroups.length === 0}
                      />
                    </TabPane>
                  )}
                  {hasDentalPlans && (
                    <TabPane tab="Dental" key={DENTAL}>
                      <PlanRatesList
                        benefitKind={DENTAL}
                        mdvRates={
                          selectedBenefitGroups.length === 0 ? {} : dentalRates
                        }
                        noGroupsSelected={selectedBenefitGroups.length === 0}
                      />
                    </TabPane>
                  )}
                  {hasVisionPlans && (
                    <TabPane tab="Vision" key={VISION}>
                      <PlanRatesList
                        benefitKind={VISION}
                        mdvRates={
                          selectedBenefitGroups.length === 0 ? {} : visionRates
                        }
                        noGroupsSelected={selectedBenefitGroups.length === 0}
                      />
                    </TabPane>
                  )}
                  {hasLifeDisabilityPlans && (
                    <TabPane tab="Life & Disability" key={LIFE_DISABILITY}>
                      <PlanRatesList
                        benefitKind={LIFE_DISABILITY}
                        lifeDisabilityRates={
                          selectedBenefitGroups.length === 0
                            ? {}
                            : lifeDisabilityRates
                        }
                        noGroupsSelected={selectedBenefitGroups.length === 0}
                      />
                    </TabPane>
                  )}
                </Tabs>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PlanRates;
