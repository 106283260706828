import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, notification } from 'antd';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import DocumentUploader from 'components/DocumentUploader/DocumentUploader';
import IconError from 'assets/images/alert-failure.svg';

import {
  CARRIER_DOCUMENT_TYPES,
  CARRIER_DOCUMENTS,
  NO_FILE_UPLOADED,
  CARRIER_FILE_UPLOAD_Z_INDEX,
  CARRIER_DOCUMENTS_FILE_SIZE_EXCEED,
} from 'modules/renewals/constants/renewalsConstants';
import { baseApi } from 'util/apiUtil';
import {
  useDeleteCarrierDocumentMutation,
  useLazyDownloadCarrierDocumentQuery,
  useUploadCarrierDocumentMutation,
} from 'modules/renewals/slices/offersSlice';
import { openNotificationWithIcon } from 'components/Notification/Notification';
import { SIZE_VALIDATE } from 'util/commonUtil';

import styles from './carrierDocuments.module.less';

type Props = {
  isModalVisible: boolean;
  setIsModalVisible: (flag: boolean) => void;
  offerId: string;
  carrierQuote: any | null;
  otherDocumentsFile: any | null;
  isPreviewScreen?: boolean;
  editOfferId?: string;
};

const CarrierDocuments = (props: Props) => {
  const {
    isModalVisible,
    setIsModalVisible,
    offerId,
    carrierQuote,
    otherDocumentsFile,
  } = props;

  const params = useParams();
  const documentUploaderRef = useRef(null);

  const [
    uploadCarrierDocuments,
    { reset: resetUpload, isLoading: carrierDocumentLoading },
  ] = useUploadCarrierDocumentMutation();

  const [downloadCarrierDocuments] = useLazyDownloadCarrierDocumentQuery();

  const [deleteCarrierDocument] = useDeleteCarrierDocumentMutation();

  const [selectedCarrierQuoteFile, setSelectedCarrierQuoteFile] =
    useState<File | null>(null);
  const [selectedOtherDocumentsFile, setSelectedOtherDocumentsFile] =
    useState<File | null>(null);
  const [removeCarrierQuoteFile, setRemoveCarrierQuoteFile] =
    useState<boolean>(false);
  const [removeOtherDocumentsFile, setRemoveOtherDocumentsFile] =
    useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
  const downloadCarrierDocument = async (
    carrierDocumentType: string,
    file: File
  ) => {
    // backend fetching
    if (-1 == file.lastModified) {
      try {
        await downloadCarrierDocuments({
          offerId: offerId,
          carrierDocumentType: carrierDocumentType,
        });
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        const url = `${baseApi}/v1/renewals/offer-files/offer/${offerId}/carrier-documents/${carrierDocumentType}`;
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        notification.open({
          message: 'Download Failed',
          description:
            'The requested download failed. Please check your connection and try again.',
          placement: 'topRight',
          duration: 4,
          className: styles.notification,
          closeIcon: <></>,
          icon: (
            <img className={styles.notificationIcon} src={IconError} alt="" />
          ),
        });
      }
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      const url = window.URL.createObjectURL(file);
      a.href = url;
      a.download = file.name;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const uploadCarrierQuote = async () => {
    await uploadCarrierDocuments({
      employerId: String(params.employerId),
      offerId: String(offerId),
      carrierDocumentType: CARRIER_DOCUMENT_TYPES.CARRIER_QUOTE,
      file: selectedCarrierQuoteFile,
    });
  };

  const deleteCarrierQuote = async () => {
    await deleteCarrierDocument({
      offerId: offerId,
      carrierDocumentType: CARRIER_DOCUMENT_TYPES.CARRIER_QUOTE,
    });
  };

  const uploadOtherDocumentsFile = async () => {
    await uploadCarrierDocuments({
      employerId: String(params.employerId),
      offerId: String(offerId),
      carrierDocumentType: CARRIER_DOCUMENT_TYPES.OTHER_DOCUMENTS_FILE,
      file: selectedOtherDocumentsFile,
    });
  };

  const deleteOtherDocumentsFile = async () => {
    await deleteCarrierDocument({
      offerId: offerId,
      carrierDocumentType: CARRIER_DOCUMENT_TYPES.OTHER_DOCUMENTS_FILE,
    });
  };

  const handleSaveForAllOffers = async () => {
    if (selectedCarrierQuoteFile !== null) {
      await uploadCarrierQuote();
      if (selectedOtherDocumentsFile !== null) {
        await uploadOtherDocumentsFile();
        setIsModalVisible(false);
      } else if (removeOtherDocumentsFile) {
        await deleteOtherDocumentsFile();
        setIsModalVisible(false);
      } else {
        setIsModalVisible(false);
      }
    } else if (removeCarrierQuoteFile) {
      await deleteCarrierQuote();
      if (selectedOtherDocumentsFile !== null) {
        await uploadOtherDocumentsFile();
        setIsModalVisible(false);
      } else if (removeOtherDocumentsFile) {
        await deleteOtherDocumentsFile();
        setIsModalVisible(false);
      } else {
        setIsModalVisible(false);
      }
    } else {
      if (selectedOtherDocumentsFile !== null) {
        await uploadOtherDocumentsFile();
        setIsModalVisible(false);
      } else if (removeOtherDocumentsFile) {
        await deleteOtherDocumentsFile();
        setIsModalVisible(false);
      } else {
        setIsModalVisible(false);
      }
    }
    setDisableSaveButton(false);
  };

  const fileValidationFailHandle = (validateSetting: string) => {
    let description: React.ReactFragment | string = '';
    let message: string = '';
    if (validateSetting === SIZE_VALIDATE) {
      description = (
        <Col>
          <span>{CARRIER_DOCUMENTS_FILE_SIZE_EXCEED}</span>
        </Col>
      );
      message = 'File Upload Failed';
    }
    openNotificationWithIcon('error', description, message);
  };

  return (
    <ConfirmationDialog
      className={styles.carrierDocuments}
      title={CARRIER_DOCUMENTS}
      onConfirm={() => {
        setDisableSaveButton(true);
        handleSaveForAllOffers();
      }}
      confirmLoading={carrierDocumentLoading}
      visible={isModalVisible}
      confirmText={'Save'}
      centered={true}
      width={600}
      confirmBtnFullWidth={true}
      cancelText={'Cancel'}
      isCancelLink={true}
      isCancelApplicable={true}
      closeModal={() => setIsModalVisible(!isModalVisible)}
      disableConfirmButton={disableSaveButton}
    >
      <div className={styles.documentContainer}>
        <div className={styles.carrierQuote}>Carrier Quote</div>
        <div className={styles.carrierQuoteDocument}>
          <DocumentUploader
            ref={documentUploaderRef}
            onChange={(file: File) => {
              setSelectedCarrierQuoteFile(file);
              resetUpload();
            }}
            onRemove={() => {
              if (carrierQuote !== null) {
                setRemoveCarrierQuoteFile(true);
                if (selectedCarrierQuoteFile) {
                  setSelectedCarrierQuoteFile(null);
                }
                resetUpload();
              } else {
                setSelectedCarrierQuoteFile(null);
                resetUpload();
              }
            }}
            maxFileSizeMB={100}
            defaultFile={
              carrierQuote !== null && !removeCarrierQuoteFile
                ? new File(
                    [],
                    carrierQuote?.fileName ? carrierQuote?.fileName : '',
                    {
                      lastModified: -1,
                    }
                  )
                : null
            }
            disabled={false}
            hideNoFileMessage={selectedCarrierQuoteFile !== null}
            onFileNameClick={(file: any) => {
              downloadCarrierDocument(
                CARRIER_DOCUMENT_TYPES.CARRIER_QUOTE,
                file
              );
            }}
            isRemoved={selectedCarrierQuoteFile === null}
            onValidateFails={fileValidationFailHandle}
            selectFileName={'+ File'}
            showCancelConfirmationBody={false}
            isFileNameVisible={true}
            overflowPopoverMaxWidth={'255px'}
            wrapperClassName={styles.documentUploader}
            noFileUploadedMessage={NO_FILE_UPLOADED}
            showCancelConfirmation={true}
            removeConfirmationPopUpZIndex={CARRIER_FILE_UPLOAD_Z_INDEX}
            fileNamePopoverZIndex={CARRIER_FILE_UPLOAD_Z_INDEX}
          />
        </div>

        <div>
          <div className={styles.otherDocumentsFile}>Other Documents</div>
          <div className={styles.otherDocumentsFileDocument}>
            <DocumentUploader
              ref={documentUploaderRef}
              onChange={(file: File) => {
                setSelectedOtherDocumentsFile(file);
                resetUpload();
              }}
              onRemove={() => {
                if (otherDocumentsFile !== null) {
                  setRemoveOtherDocumentsFile(true);
                  if (selectedOtherDocumentsFile) {
                    setSelectedOtherDocumentsFile(null);
                  }
                } else {
                  setSelectedOtherDocumentsFile(null);
                  resetUpload();
                }
              }}
              maxFileSizeMB={100}
              defaultFile={
                otherDocumentsFile !== null && !removeOtherDocumentsFile
                  ? new File(
                      [],
                      otherDocumentsFile?.fileName
                        ? otherDocumentsFile?.fileName
                        : '',
                      {
                        lastModified: -1,
                      }
                    )
                  : null
              }
              disabled={false}
              hideNoFileMessage={selectedOtherDocumentsFile !== null}
              onFileNameClick={(file: any) => {
                downloadCarrierDocument(
                  CARRIER_DOCUMENT_TYPES.OTHER_DOCUMENTS_FILE,
                  file
                );
              }}
              isRemoved={selectedOtherDocumentsFile === null}
              onValidateFails={fileValidationFailHandle}
              selectFileName={'+ File'}
              showCancelConfirmationBody={false}
              isFileNameVisible={true}
              overflowPopoverMaxWidth={'255px'}
              wrapperClassName={styles.documentUploader}
              noFileUploadedMessage={NO_FILE_UPLOADED}
              showCancelConfirmation={true}
              removeConfirmationPopUpZIndex={CARRIER_FILE_UPLOAD_Z_INDEX}
              fileNamePopoverZIndex={CARRIER_FILE_UPLOAD_Z_INDEX}
            />
          </div>
        </div>
      </div>
    </ConfirmationDialog>
  );
};

export default CarrierDocuments;
