import { FC, useState } from 'react';
import { Col, Row } from 'antd';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';
import { FourOOneKPlan } from 'model/plans/FourOOneKPlan';

import PlanDocumentsOverview from 'modules/plans/components/OverviewPage/PlanDocumentsOverview/PlanDocumentOverview';

import { displayNumberWithDecimals } from 'modules/plans/utils';
import styles from './fourOOneKPlanOverviewDetails.module.less';

type FourOOneKPlanOverviewDetailsProps = {
  plan: FourOOneKPlan;
  heading?: string;
};

const FourOOneKPlanOverviewDetails: FC<FourOOneKPlanOverviewDetailsProps> = (
  props
) => {
  const { plan } = props;

  const [viewPlanDocumentsModal, setViewPlanDocumentsModal] = useState(false);

  const updateViewPlanDocumentsModal = (value: boolean) => {
    setViewPlanDocumentsModal(value);
  };

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col span={24}>
          <InfoLabelSection
            labelText="Annual Max Contribution"
            value={
              plan?.annualMaxContribution
                ? `$${displayNumberWithDecimals(plan?.annualMaxContribution)}`
                : '-'
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <InfoLabelSection
            labelText="Annual Catchup Contribution"
            value={
              plan?.annualCatchupContribution
                ? `$${displayNumberWithDecimals(
                    plan?.annualCatchupContribution
                  )}`
                : '-'
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <InfoLabelSection
            labelText="401(k) Match Policy"
            value={plan?.details ? `${plan?.details}` : '-'}
          />
        </Col>
      </Row>
      <div className={styles.overview} id={'Plan_Doc'}>
        <PlanDocumentsOverview
          heading={'Plan Resources'}
          plan={plan}
          documents={plan?.documents}
          benefitKind={plan.benefitKind}
          viewPlanDocumentsModal={viewPlanDocumentsModal}
          setViewPlanDocumentsModal={(value) =>
            updateViewPlanDocumentsModal(value)
          }
        />
      </div>
    </div>
  );
};

export default FourOOneKPlanOverviewDetails;
