import { FC, useState, useRef, useEffect } from 'react';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import Option from 'model/Option';
import AddTaxAdvantagedPlan from 'modules/plans/taxAdvantaged/components/AddTaxAdvantagedPlan/AddTaxAdvantagedPlan';
import { exitWithoutSavingMsg } from 'constants/commonConstants';
import { useAppDispatch } from 'hooks/redux';
import { clearDocumentsAttached } from 'modules/plans/slices/taxAdvantagedAccountPlanSlice';

type Props = {
  visible: boolean;
  employerName?: string | null;
  planYearId?: string;
  planType: Option;
  onClose: Function;
  onSaveClose: Function;
  defaultPlanYear?: string;
  dbgPlanYear?: string;
  isDBGPlan?: boolean;
  isSaveDisabled?: boolean;
};

const AddTaxAdvantagedPlanModal: FC<Props> = (props) => {
  const {
    visible,
    employerName = '',
    planType,
    onClose,
    onSaveClose,
    defaultPlanYear,
    dbgPlanYear,
    isDBGPlan,
    isSaveDisabled = false,
  } = props;

  const [visibleConfirmation, setVisibleConfirmation] = useState(false);
  const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);

  const addTaxAdvantagedPlanRef = useRef<any>();

  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      setIsCancelButtonClicked(false);
    }
  }, [visible]);

  const onConfirm = () => {
    addTaxAdvantagedPlanRef.current.reset();
    setIsCancelButtonClicked(true);
    onClose(false);
    setVisibleConfirmation(false);
    dispatch(clearDocumentsAttached());
  };

  return (
    <>
      <FullScreenModal
        visible={visible}
        onCancel={() => setVisibleConfirmation(true)}
        footer={false}
        title={`${employerName} - Add ${planType.label} Plan`}
      >
        <AddTaxAdvantagedPlan
          ref={addTaxAdvantagedPlanRef}
          onReset={() => setVisibleConfirmation(true)}
          closePlanAddModal={onConfirm}
          isCancelButtonClickConfirm={isCancelButtonClicked}
          planType={planType.value}
          onSaveClose={onSaveClose}
          defaultPlanYear={defaultPlanYear}
          dbgPlanYear={dbgPlanYear}
          isPlanUpdateMode={false}
          isDBGPlan={isDBGPlan}
          isModalVisible={isModalVisible}
          isSaveDisabled={isSaveDisabled}
        />
      </FullScreenModal>
      <ConfirmationDialog
        title="Are you sure you want to close?"
        confirmText="Yes - Close and do not save plan"
        cancelText="Cancel"
        closeModal={() => setVisibleConfirmation(false)}
        onConfirm={onConfirm}
        visible={visibleConfirmation}
      >
        <p>{exitWithoutSavingMsg}</p>
      </ConfirmationDialog>
    </>
  );
};

export default AddTaxAdvantagedPlanModal;
