import { CloseCircleOutlined } from '@ant-design/icons';
import { Col, Modal, Row, Select, Table, TablePaginationConfig } from 'antd';
import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useNavContext } from 'hooks/useNavContext';
import { DataColumn } from 'components/DataTable/DataColumn';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { useMonthlyBillingHistoryQuery } from 'modules/billing/slices/billingSlice';
import { fileTypes } from 'modules/billing/constants/constants';
import { baseApi } from 'util/apiUtil';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { formatDate } from 'util/dateUtil';
import selectIcon from 'assets/images/icon-caret-down.svg';
import tableStyles from 'components/DataTable/datatable.module.less';
import styles from './billingMonthHistory.module.less';

type BillingMonthHistoryProps = {
  isVisible: boolean;
  billingMonth: number;
  onClose: Function;
  year: String;
  month: String;
};

const BillingMonthHistory: FC<BillingMonthHistoryProps> = (props) => {
  const { isVisible, billingMonth, onClose, year, month } = props;

  const { employer } = useNavContext();
  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState(5);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: pageSize,
    current: currentIndex,
  });
  const [sorterValue, setSorterValue] = useState<any>({});

  const {
    data: BillingFileHistory,
    isFetching,
    refetch: refetchFileHistory,
  } = useMonthlyBillingHistoryQuery({
    employerId: employer?.id ?? '',
    month: billingMonth,
  });

  const fileHistoryTableColumns: DataColumn[] = [
    {
      title: 'FILE NAME',
      dataIndex: 'files',
      key: 'files',
      width: '28%',
      align: 'left',
      showSorterTooltip: false,
      render: (data: any) => {
        return (
          <>
            {data?.map((item: any, index: any) => {
              return (
                <div className={styles.tableTitle} key={index}>
                  <OverflowPopover showPopover={false}>
                    {item.fileName}
                  </OverflowPopover>
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'updatedTs',
      key: 'updatedTs',
      width: '25%',
      align: 'left',
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      sortOrder: !isEmpty(sorterValue) ? sorterValue.order : 'descend',
      sorter: (a: any, b: any) => {
        return dayjs(a.updatedTs).isBefore(dayjs(b.updatedTs)) ? -1 : 1;
      },
      render: (text: string, data: any) => {
        return (
          <>
            <div className={styles.updatedTime}>
              <OverflowPopover>
                {formatDate(data.updatedTs, 'MMMM D, YYYY h:mm A')}
              </OverflowPopover>
            </div>
            <div className={styles.updatedAt}>
              <OverflowPopover>{data.lastUpdatedByLoginId}</OverflowPopover>
            </div>
          </>
        );
      },
    },
    {
      title: 'FILE TYPE',
      dataIndex: 'fileType',
      key: 'fileType',
      width: '20%',
      align: 'left',
      showSorterTooltip: false,
      render: (text: string) => {
        return (
          <>
            <div className={styles.updatedTime}>{fileTypes[text]}</div>
          </>
        );
      },
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '12%',
      align: 'left',
      showSorterTooltip: false,
      render: (text: string, data: any) => {
        return (
          <a
            className={styles.billingDownloadFileLink}
            href={downloadFile(data.id)}
          >
            <LinkButton>Download</LinkButton>
          </a>
        );
      },
    },
  ];

  const downloadFile = (id: string) => {
    return `${baseApi}/v2/billing/history/employer/${employer?.id}/files/${id}/download`;
  };

  useEffect(() => {
    setPagination({
      pageSize: pageSize,
      current: currentIndex,
    });
  }, [currentIndex, pageSize]);

  useEffect(() => {
    refetchFileHistory();
  }, [isVisible, refetchFileHistory]);

  return (
    <div className={styles.modalWrapper}>
      <Modal
        wrapClassName={styles.modalWrapper}
        width="55%"
        centered={true}
        visible={isVisible}
        footer={null}
        closeIcon={
          <div className={styles.closeIcon}>
            <CloseCircleOutlined
              style={{
                fontSize: '24px',
                color: '#000000',
              }}
            />
            <div className={styles.closeText}>close</div>
          </div>
        }
        onCancel={() => {
          setSorterValue({});
          setCurrentIndex(1);
          setPageSize(5);
          onClose(false);
        }}
        bodyStyle={{ maxHeight: 700, overflowX: 'scroll' }}
      >
        <div>
          <div className={styles.title}>File History</div>
          <div className={styles.monthYear}>{month + ' ' + year}</div>
          <Table
            className={`${tableStyles.tableWrapper} ${styles.tableWrapper}`}
            dataSource={BillingFileHistory}
            columns={fileHistoryTableColumns}
            pagination={pagination}
            loading={isFetching}
            tableLayout="fixed"
            onChange={(pagination, filter, sorter) => {
              const { current } = pagination;
              setCurrentIndex(current ?? 0);
              setSorterValue(sorter);
            }}
          />
          <Row className={styles.pageSizeRow}>
            <Col>
              <Select
                value={pagination.pageSize}
                onChange={(e) => {
                  setPageSize(e);
                  setCurrentIndex(1);
                }}
                bordered={false}
                className={`${tableStyles.rowDropdown} ${styles.rowDropdown}`}
                suffixIcon={<img src={selectIcon} alt="select-icon" />}
              >
                {[5, 10, 15].map((pageSize) => (
                  <Select.Option key={pageSize} value={pageSize}>
                    View: {pageSize} Rows
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default BillingMonthHistory;
