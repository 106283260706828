import React, { FC, useEffect, useState, ReactNode } from 'react';

import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import { Col, Form, Input, Row, Popover, Select } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
// eslint-disable-next-line import/no-unresolved
import { Area } from 'react-easy-crop/types';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import NextButton from 'components/buttons/NextButton/NextButton';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import InputForm from 'components/InputForm/InputForm';
import ImageUploader, {
  FORMAT_VALIDATE,
  SIZE_VALIDATE,
} from 'components/ImageUploader/ImageUploader';
import StateSelect from 'components/StateSelect/StateSelect';

import {
  addBroker,
  clearBrokerLogo,
  uploadBrokerLogo,
  clearUpdateBrokerUpdation,
  updateBroker,
} from 'modules/brokers/slices/brokerBasicInfoSlice';
import {
  validateBroker,
  validateEmailDomain,
} from 'modules/brokers/services/BrokerService';
import Address from 'model/Address';
import Broker from 'model/Broker';
import BrokerLocation from 'model/BrokerLocation';
import {
  basicFormFields,
  EXISTING_BROKER_FOUND,
  EMPTY_MESSAGE,
  EXISTING_LOCATION_FOUND,
  EXISTING_EMAIL_DOMAIN_FOUND,
  EMPLOYER_LIMIT_DROPDOWN,
  EMPLOYER_LIMIT_CONSTANTS,
  EMPLOYER_UNLIMITED_PASSING_VALUE,
  BROKER_EMAILDOMAIN_ERROR_FOUND,
  RESTRICTED_EMAIL_DOMAIN_FOUND,
  RESTRICTED_EMAIDOMAIN_ERROR_FOUND,
} from 'modules/brokers/constants/brokerConstants';
import AlertMessage from 'components/Alert/AlertMessage';
import {
  emailDomainVerificationMsg,
  formVerificationMsg,
  logoUploadHelperText,
} from 'constants/commonConstants';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import {
  hasDuplicates,
  isValidEmail,
  onlyNumberInputUtil,
  splitAndTrim,
} from 'util/commonUtil';
import InputNumber from 'components/InputNumber/InputNumber';

import styles from './createBrokerInfoForm.module.less';

type CreateBrokerInfoFormProps = {
  edit: boolean;
  nextStep?: () => void;
  closeModal?: () => void;
  brokerId?: string | null | undefined;
};

type FromDataTypes = {
  name: string;
  addressLine1: string;
  addressLine2: string;
  id: string;
  city: string;
  state: string;
  zipCode: string;
  locationName: string;
  employerLimitCount: number | undefined;
  employerLimit: string;
  emailDomains: string;
};

const CreateBrokerInfoForm: FC<CreateBrokerInfoFormProps> = (
  props: CreateBrokerInfoFormProps
) => {
  const { edit, nextStep, closeModal, brokerId } = props;

  const [duplicateBrokerNameFound, setDuplicateBrokerNameFound] =
    useState<boolean>(false);
  const [requireFieldErrorFound, setRequireFieldErrorFound] =
    useState<boolean>(false);
  const [duplicateEmailDomainsFound, setDuplicateEmailDomainsFound] =
    useState<boolean>(false);

  const [alertMessage, setAlertMessage] = useState<string>('');
  const [imageValidateSetting, setImageValidateSetting] = useState<string>('');
  const [isAlertVisible, setAlertVisibility] = useState<boolean>(false);
  const [duplicateLocationFound, setDuplicateLocationFound] =
    useState<boolean>(false);
  const [existingLogoUrl, setExistingLogoUrl] = useState<string>('');
  const [cropSuccess, setCropSuccess] = useState<boolean>(false);
  const [cropArea, setCropArea] = useState<OriginalImageCropParams>();

  const dispatch = useAppDispatch();
  const brokerObj = useAppSelector(
    (state) => state.brokers.brokerBasicInfo.brokerObj
  );
  const brokerBasicInfo = useAppSelector(
    (state) => state.brokers.brokerBasicInfo
  );
  const logoUrl = useAppSelector(
    (state) => state.brokers.brokerBasicInfo.logoUrl
  );
  const logoUploading = useAppSelector(
    (state) => state.brokers.brokerBasicInfo.logoUploadInProgress
  );
  const logoEncodedString = useAppSelector(
    (state) => state.brokers.brokerBasicInfo.brokerLogo
  );
  const originalLogoUrl = useAppSelector(
    (state) => state.brokers.brokerBasicInfo.originalLogoUrl
  );
  const originalLogoEncodedString = useAppSelector(
    (state) => state.brokers.brokerBasicInfo.originalLogo
  );

  const [form] = Form.useForm();
  const [formData, setFormData] = useState<FromDataTypes>({
    name: '',
    addressLine1: '',
    addressLine2: '',
    id: '',
    city: '',
    state: '',
    zipCode: '',
    emailDomains: '',
    locationName: '',
    employerLimitCount: undefined,
    employerLimit: '',
  });

  useEffect(() => {
    if (brokerObj && (brokerObj.tempId || brokerObj.id)) {
      const primaryBrokerLocation = getPrimaryBrokerLocation(
        brokerObj.locations
      );
      if (primaryBrokerLocation) {
        const { addressLine1, addressLine2, city, zipCode, state } =
          primaryBrokerLocation.address;
        const { name } = primaryBrokerLocation;
        const data = {
          name: brokerObj.name,
          id: brokerObj.id,
          addressLine1,
          addressLine2,
          city,
          state,
          zipCode,
          locationName: name,
          emailDomains: brokerObj?.emailDomains?.toString(),
          logoUrl: brokerObj.logoUrl,
          employerLimitCount:
            brokerObj?.allowedEmployerCount === EMPLOYER_UNLIMITED_PASSING_VALUE
              ? undefined
              : brokerObj?.allowedEmployerCount,
          employerLimit:
            brokerObj?.allowedEmployerCount === EMPLOYER_UNLIMITED_PASSING_VALUE
              ? EMPLOYER_LIMIT_CONSTANTS.UNLIMITED
              : EMPLOYER_LIMIT_CONSTANTS.LIMITED,
        };
        form.setFieldsValue(data);
        setExistingLogoUrl(brokerObj.logoUrl !== null ? brokerObj.logoUrl : '');
        setCropArea(brokerObj.originalImageCropParams);
        if (brokerObj.logoUrl) {
          setCropSuccess(true);
        }
        setFormData(data);
      }
    } else {
      form.resetFields(basicFormFields.formFields);
      setExistingLogoUrl('');
    }
  }, [brokerObj, brokerObj?.id, form]);

  useEffect(() => {
    if (!brokerBasicInfo.updateInProgress && brokerBasicInfo.updateCompleted) {
      if (
        brokerBasicInfo.brokerUpdateError &&
        brokerBasicInfo.brokerUpdateError.data.code
      ) {
        dispatch(clearUpdateBrokerUpdation());
        if (
          brokerBasicInfo.brokerUpdateError.data.code ===
          EXISTING_LOCATION_FOUND
        ) {
          setDuplicateLocationFound(true);
          setRequireFieldErrorFound(true);
          setAlertMessage('Location Already Exists');
        }
      } else {
        if (closeModal) {
          closeModal();
        }
        setDuplicateLocationFound(false);
        setRequireFieldErrorFound(false);
        form.resetFields(basicFormFields.formFields);
      }
    }
  }, [
    dispatch,
    brokerBasicInfo?.brokerObj,
    brokerBasicInfo?.updateInProgress,
    brokerBasicInfo?.updateCompleted,
    brokerBasicInfo?.brokerUpdateError,
    closeModal,
    form,
    brokerId,
  ]);

  // sets form value once logo is uploaded
  useEffect(() => {
    if (logoUrl) {
      const currentValues = form.getFieldsValue();
      form.setFieldsValue({ ...currentValues, logoUrl });
      setCropSuccess(true);
    }
  }, [logoUrl, form]);

  useEffect(() => {
    if (brokerObj) {
      setRequireFieldErrorFound(false);
      setDuplicateBrokerNameFound(false);
      setDuplicateLocationFound(false);
      setDuplicateEmailDomainsFound(false);
      setAlertVisibility(false);
      setImageValidateSetting('');
    }
  }, [brokerObj]);

  const removeAlert = async () => {
    const valid = await validateBasicInfo();
    setDuplicateBrokerNameFound(false);
    setDuplicateLocationFound(false);
    if (valid) {
      setRequireFieldErrorFound(false);
    }
  };

  const getPrimaryBrokerLocation = (
    brokeLocations: BrokerLocation[]
  ): BrokerLocation | null => {
    if (brokeLocations) {
      return brokeLocations.filter(
        (location: BrokerLocation) => location.primary
      )[0];
    } else {
      return null;
    }
  };

  const getAllAdditionalLocations = (
    brokeLocations: BrokerLocation[]
  ): BrokerLocation[] => {
    if (brokeLocations) {
      return brokeLocations.filter(
        (location: BrokerLocation) => !location.primary
      );
    } else {
      return [];
    }
  };

  const isLocationAlreadyAdded = (name: string): boolean => {
    if (!isEmpty(name) && !isEmpty(brokerObj.locations)) {
      const otherLocations = brokerObj.locations.filter(
        (item) => !item.primary
      );
      if (!isEmpty(otherLocations)) {
        const locations = otherLocations.filter(
          (location: BrokerLocation) =>
            location.name.trim().toLowerCase() === name.trim().toLowerCase()
        );
        if (!isEmpty(locations)) {
          return true;
        }
      }
    }
    return false;
  };

  const updateAlertVisibility = (visibility: boolean) => {
    setAlertVisibility(visibility);
  };

  const onInputChange = (changedValues: any, allValues: any) => {
    form.setFieldsValue(allValues);
    setFormData({ ...formData, ...allValues });
    if (isAlertVisible) {
      removeAlert();
    }
  };

  const onConfirmCrop = async (
    image: string,
    originalImage?: string,
    croppedArea?: Area
  ) => {
    dispatch(uploadBrokerLogo(image, originalImage)).then(() => {
      setCropArea(croppedArea);
    });

    setCropSuccess(true);
    setImageValidateSetting('');
    if (isAlertVisible) {
      removeAlert();
    }
  };

  const onLogoRemove = () => {
    dispatch(clearBrokerLogo());
    form.setFieldsValue({ ...formData, logoUrl: '' });
    setImageValidateSetting('');
    setCropSuccess(false);
    setExistingLogoUrl('');
  };
  const closeAlert = () => {
    setDuplicateBrokerNameFound(false);
    setRequireFieldErrorFound(false);
    setDuplicateLocationFound(false);
  };

  const onValidateFails = (validateSetting: string) => {
    if (requireFieldErrorFound) {
      setRequireFieldErrorFound(false);
    }
    if (duplicateBrokerNameFound) {
      setDuplicateBrokerNameFound(false);
    }
    if (duplicateLocationFound) {
      setDuplicateLocationFound(false);
    }
    if (duplicateEmailDomainsFound) {
      setDuplicateEmailDomainsFound(false);
    }
    setImageValidateSetting(validateSetting);
    form.setFieldsValue({ logoUrl: null });
  };

  const getValidationMessage = (validateSetting: string): ReactNode => {
    switch (validateSetting) {
      case FORMAT_VALIDATE:
        return 'This image file type is not supported.';
      case SIZE_VALIDATE:
        return 'Maximum size allowed for this upload is 2 MB.';
      default:
        setImageValidateSetting('');
        return;
    }
  };

  const locationTooltipContent = () => (
    <div className={styles.popoverContent}>
      <p>
        Location Names are for categorizing
        <br /> different broker locations and clients
        <br /> served by people at those locations.
      </p>
      <p className={styles.contentText}>
        A primary broker location is required.
      </p>
    </div>
  );

  const validateBasicInfo = async () => {
    try {
      await form.validateFields(basicFormFields.requiredFields);
      return cropSuccess;
    } catch (errorInfo: any) {
      return errorInfo.errorFields.length === 0;
    }
  };

  const closeBrokerInfoModal = async () => {
    form.resetFields(basicFormFields.formFields);
    onLogoRemove();
    closeAlert();
    if (closeModal) {
      closeModal();
    }
  };

  const setPrimaryLocationInPlaceId = () => {
    if (!isEmpty(brokerObj.locations)) {
      return brokerObj.locations.find((item) => item.primary)?.inPlaceId;
    }
    return uuidv4();
  };

  const onClickNext = async () => {
    updateAlertVisibility(true);
    if (isNull(logoEncodedString)) {
      dispatch(clearBrokerLogo());
    }
    const isFormValid = await validateBasicInfo();
    if (isFormValid) {
      const {
        id,
        addressLine1,
        addressLine2,
        city,
        zipCode,
        name,
        state,
        emailDomains,
        locationName,
        employerLimitCount,
        employerLimit,
      } = formData;
      const isEmailDomainValid = isValidEmail(emailDomains); // Validate email domains
      if (!isEmailDomainValid) {
        form.setFields([{ name: 'emailDomains', errors: [EMPTY_MESSAGE] }]);
        setRequireFieldErrorFound(true);
        setAlertMessage(emailDomainVerificationMsg);
        return;
      }

      const duplicatesExist = hasDuplicates(splitAndTrim(emailDomains));
      if (duplicatesExist) {
        form.setFields([{ name: 'emailDomains', errors: [EMPTY_MESSAGE] }]);
        setRequireFieldErrorFound(true);
        setAlertMessage(BROKER_EMAILDOMAIN_ERROR_FOUND);
        return;
      }
      const address = {
        addressLine1: addressLine1.trim(),
        addressLine2: addressLine2?.trim(),
        city: city.trim(),
        state,
        zipCode: zipCode.trim(),
      } as Address;
      let primaryLocationId = null;
      if (edit) {
        const primaryBrokerLocation = getPrimaryBrokerLocation(
          brokerObj.locations
        );
        if (primaryBrokerLocation) {
          primaryLocationId = primaryBrokerLocation.id;
        }
      }
      const primaryLocation = {
        inPlaceId: setPrimaryLocationInPlaceId(),
        name: locationName.trim(),
        address: address,
        primary: true,
        organizationId: edit ? brokerObj.id : null,
        id: edit ? primaryLocationId : null,
      } as BrokerLocation;
      const existingAllAdditionalLocations = getAllAdditionalLocations(
        brokerObj.locations
      );
      const newLocations = [primaryLocation, ...existingAllAdditionalLocations];

      // emp limit logic
      const empLimitCount =
        employerLimit === EMPLOYER_LIMIT_CONSTANTS.UNLIMITED
          ? EMPLOYER_UNLIMITED_PASSING_VALUE
          : employerLimitCount === undefined
          ? 1
          : employerLimitCount;

      const broker = {
        tempId: uuidv4(),
        id: edit ? brokerObj.id : id,
        name: name.trim(),
        logoUrl,
        locations: newLocations,
        emailDomains: splitAndTrim(emailDomains?.toLowerCase()),
        admins: brokerObj && brokerObj.admins ? [...brokerObj.admins] : [],
        primaryContact: edit ? brokerObj.primaryContact : null,
        employersCount: edit ? brokerObj.employersCount : null,
        originalLogoUrl: originalLogoUrl,
        originalImageCropParams: originalLogoUrl ? cropArea : {},
        allowedEmployerCount: empLimitCount,
      } as Broker;
      if (broker) {
        if (edit) {
          const organizationId: string = brokerObj.id;
          const validateEmailDomainResult = await validateEmailDomain(
            emailDomains,
            organizationId
          );
          if (broker.name !== brokerObj.name) {
            const validateBrokerNameResult = await validateBroker(name.trim());
            if (
              validateBrokerNameResult &&
              validateBrokerNameResult.data &&
              validateBrokerNameResult.data.code &&
              validateBrokerNameResult.data.code === EXISTING_BROKER_FOUND
            ) {
              form.setFields([{ name: 'name', errors: [EMPTY_MESSAGE] }]);
              setDuplicateBrokerNameFound(true);
              setAlertMessage('A broker with this name already exists.');
            } else {
              setDuplicateBrokerNameFound(false);
              setDuplicateLocationFound(false);
              dispatch(updateBroker(brokerObj.id, broker));
            }
          } else if (
            validateEmailDomainResult &&
            validateEmailDomainResult.data &&
            validateEmailDomainResult.data.code &&
            validateEmailDomainResult.data.code === EXISTING_EMAIL_DOMAIN_FOUND
          ) {
            form.setFields([{ name: 'emailDomains', errors: [EMPTY_MESSAGE] }]);
            setDuplicateEmailDomainsFound(true);
            setAlertMessage(BROKER_EMAILDOMAIN_ERROR_FOUND);
          } else if (
            validateEmailDomainResult &&
            validateEmailDomainResult.data &&
            validateEmailDomainResult.data.code &&
            validateEmailDomainResult.data.code ===
              RESTRICTED_EMAIL_DOMAIN_FOUND
          ) {
            form.setFields([{ name: 'emailDomains', errors: [EMPTY_MESSAGE] }]);
            setDuplicateEmailDomainsFound(true);
            setAlertMessage(RESTRICTED_EMAIDOMAIN_ERROR_FOUND);
          } else {
            setDuplicateBrokerNameFound(false);
            setDuplicateLocationFound(false);
            dispatch(updateBroker(brokerObj.id, broker));
          }
        } else {
          const validateBrokerNameResult = await validateBroker(name.trim());
          const organizationId: string = brokerObj.id;
          const validateEmailDomainResult = await validateEmailDomain(
            emailDomains,
            organizationId
          );
          if (
            validateBrokerNameResult &&
            validateBrokerNameResult.data &&
            validateBrokerNameResult.data.code &&
            validateBrokerNameResult.data.code === EXISTING_BROKER_FOUND
          ) {
            form.setFields([{ name: 'name', errors: [EMPTY_MESSAGE] }]);
            setDuplicateBrokerNameFound(true);
            setAlertMessage('A broker with this name already exists.');
          } else if (isLocationAlreadyAdded(locationName)) {
            form.setFields([{ name: 'locationName', errors: [EMPTY_MESSAGE] }]);
            setDuplicateLocationFound(true);
            setAlertMessage('Location name already exists.');
          } else if (
            validateEmailDomainResult &&
            validateEmailDomainResult.data &&
            validateEmailDomainResult.data.code &&
            validateEmailDomainResult.data.code === EXISTING_EMAIL_DOMAIN_FOUND
          ) {
            form.setFields([{ name: 'emailDomains', errors: [EMPTY_MESSAGE] }]);
            setDuplicateEmailDomainsFound(true);
            setAlertMessage(BROKER_EMAILDOMAIN_ERROR_FOUND);
          } else if (
            validateEmailDomainResult &&
            validateEmailDomainResult.data &&
            validateEmailDomainResult.data.code &&
            validateEmailDomainResult.data.code ===
              RESTRICTED_EMAIL_DOMAIN_FOUND
          ) {
            form.setFields([{ name: 'emailDomains', errors: [EMPTY_MESSAGE] }]);
            setDuplicateEmailDomainsFound(true);
            setAlertMessage(RESTRICTED_EMAIDOMAIN_ERROR_FOUND);
          } else {
            setDuplicateBrokerNameFound(false);
            setDuplicateLocationFound(false);
            dispatch(addBroker(JSON.parse(JSON.stringify(broker))));
            if (nextStep) {
              nextStep();
            }
          }
        }
      }
    } else {
      setRequireFieldErrorFound(true);
      setAlertMessage(formVerificationMsg);
    }
  };

  return (
    <div className={styles.brokerBasicInfoView}>
      {(duplicateBrokerNameFound ||
        requireFieldErrorFound ||
        duplicateLocationFound ||
        duplicateEmailDomainsFound ||
        imageValidateSetting) && (
        <AlertMessage
          type="error"
          message={
            isEmpty(imageValidateSetting)
              ? alertMessage
              : getValidationMessage(imageValidateSetting)
          }
          closeAlert={() => {
            if (!isEmpty(imageValidateSetting)) {
              setImageValidateSetting('');
            }
            closeAlert();
          }}
        />
      )}
      <InputForm form={form} onValuesChange={onInputChange}>
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Broker Name"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
              className={duplicateBrokerNameFound ? styles.labelError : ''}
            >
              <Input data-cy="brokerName" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="addressLine1"
              label="Address Line 1"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
            >
              <Input data-cy="brokerAddressLine1" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="addressLine2"
              label="Address Line 2"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input data-cy="brokerAddressLine2" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="city"
              label="City"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
            >
              <Input data-cy="brokerCity" />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Row gutter={48}>
              <Col span={10}>
                <Form.Item
                  name="state"
                  label="State"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: EMPTY_MESSAGE,
                    },
                  ]}
                >
                  <StateSelect dataCy="brokerState" />
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="zipCode"
                  label="Zip"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: EMPTY_MESSAGE,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input data-cy="brokerZip" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              name="locationName"
              label={
                <div>
                  Primary Location
                  <Popover
                    content={locationTooltipContent}
                    placement="right"
                    overlayClassName="popoverInner"
                  >
                    <QuestionCircleOutlined className={styles.popoverIcon} />
                  </Popover>
                </div>
              }
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
              className={`${styles.locationStyle} ${
                duplicateLocationFound ? styles.labelError : ''
              }`}
            >
              <Input data-cy="brokerPLocation" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="emailDomains"
              label="Company Email Domains"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                },
              ]}
              className={duplicateEmailDomainsFound ? styles.labelError : ''}
            >
              <Input placeholder="companyname.com" />
            </Form.Item>
            <p className={styles.domainFiledMsg}>
              Use a comma to delimit multiple domains
            </p>
          </Col>
          {!edit ? (
            <Col span={12}>
              <Row gutter={48}>
                <Col span={16}>
                  <Form.Item
                    name="employerLimit"
                    label="Employer Limit"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: EMPTY_MESSAGE,
                        whitespace: true,
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      onChange={(value) => {
                        if (value === EMPLOYER_LIMIT_CONSTANTS.LIMITED) {
                          form.setFieldsValue({ employerLimitCount: 1 });
                        } else {
                          form.setFieldsValue({
                            employerLimitCount: undefined,
                          });
                        }
                      }}
                    >
                      {EMPLOYER_LIMIT_DROPDOWN.map((item) => {
                        return (
                          <Select.Option key={item?.value} value={item?.value}>
                            {item?.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="employerLimitCount"
                    label="Count"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      ({ getFieldValue }) => {
                        return {
                          required:
                            getFieldValue('employerLimit') ===
                            EMPLOYER_LIMIT_CONSTANTS.LIMITED
                              ? true
                              : false,
                        };
                      },
                      ({ getFieldValue }) => {
                        return {
                          validator: (_, value) => {
                            if (
                              value < 0 &&
                              getFieldValue('employerLimit') ===
                                EMPLOYER_LIMIT_CONSTANTS.LIMITED
                            )
                              return Promise.reject(
                                new Error('Count value cannot less than 0')
                              );
                            return Promise.resolve();
                          },
                        };
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      disabled={
                        form.getFieldValue('employerLimit') ===
                        EMPLOYER_LIMIT_CONSTANTS.LIMITED
                          ? false
                          : true
                      }
                      onKeyDown={onlyNumberInputUtil}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          ) : null}
        </Row>
        <Row gutter={48}>
          <Col span={16}>
            <Form.Item
              name="logoUrl"
              label="Broker Logo"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                },
              ]}
              validateStatus={
                (isNull(logoEncodedString) ||
                  !isEmpty(logoEncodedString) ||
                  !isEmpty(existingLogoUrl)) &&
                !imageValidateSetting
                  ? ''
                  : 'error'
              }
            >
              <div className={styles.imageUploadWrapper}>
                <ImageUploader
                  cropShape="rect"
                  aspect={4 / 3}
                  onConfirm={onConfirmCrop}
                  loading={logoUploading}
                  uploadedImage={
                    logoEncodedString ? logoEncodedString : existingLogoUrl
                  }
                  showOperations={true}
                  onRemove={onLogoRemove}
                  title="Upload Logo"
                  onValidateFails={onValidateFails}
                  helpText={logoUploadHelperText}
                  dataCyUpload="brokerLogoBtn"
                  dataCySave="logoSaveBtn"
                  defaultCropArea={
                    cropArea || brokerObj.originalImageCropParams
                  }
                  unCroppedImage={
                    originalLogoEncodedString ||
                    brokerObj.originalLogoUrl ||
                    undefined
                  }
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </InputForm>
      {edit ? (
        <>
          <Row justify="center" className={styles.margins}>
            <Col>
              <PageActionButton
                onClick={onClickNext}
                type="primary"
                className={styles.confirmButton}
              >
                Save Changes
              </PageActionButton>
            </Col>
          </Row>
          <Row justify="center" className={styles.margins}>
            <Col>
              <CancelButton
                htmlType="button"
                onClick={closeBrokerInfoModal}
                block
              >
                Cancel
              </CancelButton>
            </Col>
          </Row>
        </>
      ) : (
        <NextButton nextStep={onClickNext} disabled={logoUploading} />
      )}
    </div>
  );
};

export default CreateBrokerInfoForm;
