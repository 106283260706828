import starIcon from 'assets/images/icon-star.svg';
import {
  ActionStatus,
  OfferBenefitType,
} from 'modules/renewals/types/renewalsTypes';
import RenewalType from 'modules/renewals/enums/RenewalType';
import QuoteProcessStatus from 'modules/renewals/enums/QuoteUploadStatus';
import { ReviewType } from 'modules/plans/enums/ReviewType';

// Carrier Data

export const NO_RENEWAL_DATA = "You don't have any Renewal Data";
export const NO_RENEWAL_DATA_DESCRIPTION =
  'Get started by beginning a renewal process.';
export const NO_RENEWAL_PRIMARY_BTN_TXT = 'Start New Renewal';
export const NO_CATEGORY_OFFER_DATA = 'Get started by adding a new offer.';

export const INDIVIDUAL_STOP_LOSS_FIELD = 'individualStopLoss';
export const INDIVIDUAL_STOP_LOSS_NAME = 'Individual Stop Loss';

export const INDIVIDUAL_STOP_LOSS_FEE_FIELD = 'individualStopLossFee';
export const INDIVIDUAL_STOP_LOSS_FEE_NAME = 'Individual Stop Loss Fee';

export const AGGREGATE_STOP_LOSS_FIELD = 'aggregateStopLoss';
export const AGGREGATE_STOP_LOSS_NAME = 'Aggregate Stop Loss';

export const AGGREGATE_STOP_LOSS_FEE_FIELD = 'aggregateStopLossFee';
export const AGGREGATE_STOP_LOSS_FEE_NAME = 'Aggregate Stop Loss Fee';

export const THIRD_PARTY_ADMIN_FEE_FIELD = 'thirdPartyAdministrationFee';
export const THIRD_PARTY_ADMIN_FEE_NAME = '3rd Party Admin Fee';

export const OTHER_FEES_FIELD = 'otherFees';
export const OTHER_FEES_NAME = 'Other Fees';

export const OFFER_TAGS = {
  CURRENT: 'CURRENT',
  PENDING: 'PENDING',
  DTQ: 'DTQ',
  DRAFT: 'DRAFT',
  RECOMMENDED: 'RECOMMENDED',
} as const;
export const OFFER_TAG_RENEWAL = 'CURRENT';
export const OFFER_TAGS_PENDING = 'PENDING';
export const OFFER_TAGS_DTQ = 'DTQ';
export const OFFER_TAGS_RECOMMENDED = 'RECOMMENDED';

export const RECOMMENDED_OFFER = 'Recommended Offer';

export const NO_OFFERS = 'Currently no offers';

export const OFFER_BENEFIT_SUMMARY_TYPES = {
  MEDICAL: 'medical',
  DENTAL: 'dental',
  VISION: 'vision',
  LIFE: 'lifeAdd',
  STD: 'std',
  LTD: 'ltd',
  VOLUNTARY_BENEFIT: 'voluntaryBenefits',
  LIFE_AND_DISABILITY: 'lifeAndDisability',
} as any;

export const OFFER_BENEFIT_TYPES = {
  MEDICAL: 'Medical',
  DENTAL: 'Dental',
  VISION: 'Vision',
  LIFE: 'Life/AD&D',
  STD: 'Short Term Disability',
  LTD: 'Long Term Disability',
  VOLUNTARY_BENEFIT: 'Voluntary Benefits',
  LIFE_AND_DISABILITY: 'Life & Disability',
  TAX_ADVANTAGE_ACCOUNTS: 'Tax Advantage Accounts',
} as const;

export const OFFER_BENEFIT_TYPE_LABELS = {
  MEDICAL: 'Medical',
  DENTAL: 'Dental',
  VISION: 'Vision',
  LIFE: 'Life/AD&D',
  SHORT_TERM_DISABILITY: 'Short Term Disability',
  STD: 'Short Term Disability',
  LONG_TERM_DISABILITY: 'Long Term Disability',
  LTD: 'Long Term Disability',
  VOLUNTARY_BENEFITS: 'Voluntary Benefits',
  VOLUNTARY_BENEFIT: 'Voluntary Benefits',
  VOLUNTARY: 'Voluntary Benefits',
  LIFE_AND_DISABILITY: 'Life & Disability',
};

export const OFFER_BENEFIT_TYPES_PROPOSAL_DETAIL = {
  MEDICAL: 'Medical',
  DENTAL: 'Dental',
  VISION: 'Vision',
  LIFE: 'Life & Disability',
} as any;

export const OFFER_BENEFIT_TYPE_NAVIGATION_LABELS = {
  MEDICAL: 'medical',
  DENTAL: 'dental',
  VISION: 'vision',
  LIFE: 'life-add',
  STD: 'std',
  LTD: 'ltd',
  VOLUNTARY_BENEFIT: 'vol-life',
} as any;

export const URL_PARAM_TO_BENEFIT_TYPE = {
  medical: 'MEDICAL',
  dental: 'DENTAL',
  vision: 'VISION',
  'life-add': 'LIFE',
  life: 'LIFE',
  std: 'STD',
  ltd: 'LTD',
  'vol-life': 'VOLUNTARY_BENEFIT',
  voluntary_benefit: 'VOLUNTARY_BENEFIT',
} as any;

export const SYSTEM_BENEFIT_TYPE = {
  medical: 'MEDICAL',
  dental: 'DENTAL',
  vision: 'VISION',
  'life-add': 'LIFE',
  std: 'LIFE',
  ltd: 'LIFE',
  'vol-life': 'VOLUNTARY_BENEFIT',
} as any;

// Carrier Data
export const START_RENEWAL_DESCRIPTION =
  'To begin the renewal process for your upcoming plan year, please add the effective dates for your renewing plans. Once you select your final proposal, we will create a new plan year based on the selected dates.';
export const START_RENEWAL_DESCRIPTION_WITH_NO_CURRENT_PLANS =
  'To begin the process for your upcoming plan year, please add the effective dates for your upcoming plans. Once you select your final proposal, we will create a new plan year based on the selected dates.';

export const UNIQUE_RENEWAL_DATE_RANGE_ERROR_MSG = 'Date Ranges must be unique';
export const NO_EFFECTIVE_END_DATE_WARNING = 'Date Required';
export const START_RENEWAL_SUBHEADER = 'Upcoming Plan Year';
export const EFFECTIVE_START_DATE_FIELD_NAME = 'effectiveStartDate';
export const DYNAMIC_FORM_ITEM_NAME_SEPARATOR = '~';
export const EFFECTIVE_END_DATE_FIELD_NAME = 'effectiveEndDate';
export const ALL_DROPDOWN_ITEM = { label: 'All', value: 'All' };
export const UPCOMING_DROPDOWN_ITEM = { label: 'Upcoming', value: 'Value' };
export const UPCOMING_DROPDOWN_OFFER = { label: 'Upcoming', value: null };
export const UPCOMING_DROPDOWN_PROPOSAL = { label: 'Upcoming', value: null };
export const UPCOMING_DROPDOWN_ITEM_CARRIER = {
  label: 'Upcoming',
  value: null,
};

export const OfferType = {
  RENEWAL: 'RENEWAL',
} as const;

export const OFFER_CARD_STATUS = {
  IN_PROGRESS: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  VERSIONED: 'VERSIONED',
};

export const UPLOAD_PANEL_KEY = 'UPLOAD';
export const PREVIEW_PANEL_KEY = 'PREVIEW';

export const ADDITIONAL_DATE_RANGE_POPOVER_TXT_LINE_1 =
  'You can add additional date ranges if you need to evaluate';

export const ADDITIONAL_DATE_RANGE_POPOVER_TXT_LINE_2 =
  'multiple date ranges for the upcoming renewal.';

export const OFFERS_IMPORT_FILE_UPLOAD_ERROR_MSG = `The submitted file contains errors. Please fix any
                              errors and try uploading again.`;

export const OFFERS_IMPORT_FILE_TYPE_ERROR_MSG = `Invalid file type uploaded. Submitted files must be in .xlsx format.`;
export const OFFERS_IMPORT_FILE_SIZE_ERROR_MSG = `The submitted file exceeds the file size limit of 100 MB. Please confirm that you are uploading the correct file or reduce the file’s size`;

export const CREATE_NEW_PROPOSAL_BTN_TXT = `+ Create New Proposal`;
export const CREATE_NEW_PROPOSAL_TXT = `Create New Proposal`;
export const EDIT_PROPOSAL_TXT = `Edit Proposal`;
export const CLONE_PROPOSAL_TXT = `Clone Proposal`;
export const CREATE_NEW_RENEWAL_TXT = `Start New Renewal`;
export const IMPORT_OFFERS_TXT = `Go to Offers`;

export const FOR_REVIEW_TAB = `Published to Employer`;
export const DRAFTS_TAB = `Drafts`;
export const ARCHIVED_TAB = `Archived`;

export const PROPOSAL_DRAFT_NO_DATA_MAIN_TXT = `You don't have any Proposals saved as Drafts`;
export const PROPOSAL_DRAFT_NO_DATA_SUB_TXT = `Get started by beginning a proposal.`;
export const PROPOSAL_FOR_REVIEW_NO_DATA_MAIN_TXT = `You don't have any Proposals published to Employer`;
export const PROPOSAL_FOR_REVIEW_NO_DATA_SUB_TXT = `Get started by beginning a proposal.`;
export const RENEWAL_NO_DATA_MAIN_TXT = `You don't have any Renewal Data`;
export const RENEWAL_NO_DATA_SUB_TXT = `Get started by beginning a renewal process.`;
export const OFFER_NO_DATA_MAIN_TXT = `You don't have any Offers Data.`;
export const OFFER_NO_DATA_SUB_TXT = `Get started by creating offers in the Offers section`;

export const DISCOUNT_DISCLAIMER_TXT = `This amount may vary based on the annual premium of the offer`;

export const MARK_AS_FINAL_APPROVED_POPOVER_TXT = `Notifications will be sent to the broker team and the employer admins when this proposal is marked as final approved`;

export const ID_FIELD_PROPERTY = 'id';
export const NAME_FIELD_PROPERTY = 'name';
export const CARRIER_LOGO_FIELD_PROPERTY = 'carrierLogo';
export const CARRIER_ID_FIELD_PROPERTY = 'carriedId';
export const REFERENCE_ID_FIELD_PROPERTY = 'referenceId';
export const RX_DEDUCTIBLE_FIELD_PROPERTY = 'rxDeductible';
export const RX_OOP_FIELD_PROPERTY = 'rxOop';
export const MONTHLY_HSA_FIELD_PROPERTY = 'monthlyHsa';
export const OFFER_TYPES_FIELD_PROPERTY = 'offerTypes';
export const CARRIER_NAME_FIELD_PROPERTY = 'carrierName';
export const PLAN_TYPE_FIELD_PROPERTY = 'planType';
export const OOP_FIELD_PROPERTY = 'oops';
export const OOP_IND_WITHIN_FAMILY_FIELD_PROPERTY = 'indWithinFamOopMax';
export const PREMIUMS_FIELD_PROPERTY = 'monthlyPremiums';
export const DEDUCTIBLES_FIELD_PROPERTY = 'deductibles';
export const DEDUCTIBLES_IND_WITHIN_FAMILY_FIELD_PROPERTY =
  'indWithinFamDeductibles';
export const SERVICES_FIELD_PROPERTY = 'services';
export const RX_TIERS_FIELD_PROPERTY = 'rxTiers';
export const OUT_NETWORK_RX_TIER_PROPERTY = 'rxTiersOutNetwork';
export const OUT_NETWORK_DEDUCTIBLE_FIELD_PROPERTY = 'outNetworkDeductibles';
export const OUT_NETWORK_OOP_FIELD_PROPERTY = 'outNetworkOOPS';
export const OUT_NETWORK_RX_DEDUCTIBLE_PROPERTY = 'rxDeductibleOutNetwork';
export const OUT_NETWORK_RX_OOP_PROPERTY = 'rxOopOutNetwork';
export const PLAN_DOCUMENTS_FIELD_PROPERTY = 'attachedDocuments';
export const MAXIMUM_BENEFITS_FIELD_PROPERTY = 'maximumBenefits';
export const ORTHODONTIA_MAX_FIELD_PROPERTY = 'orthodontiaMax';
export const PLAN_NETWORK_FIELD_PROPERTY = 'planNetwork';
export const LIFE_AND_ADD_MAXIMUM_FIELD_PROPERTY = 'lifeAndAddMaximum';
export const ENROLLMENT_FIELD_PROPERTY = 'enrollment';
export const GUARANTEED_ISSUE_FIELD_PROPERTY = 'guaranteedIssue';
export const AGE_REDUCTION_FIELD_PROPERTY = 'ageReductionFromBase';
export const VOLUME_FIELD_PROPERTY = 'volume';
export const LIFE_RATE_FIELD_PROPERTY = 'lifeRate';
export const MAX_MONTHLY_BENEFIT_FIELD_PROPERTY = 'monthlyBenefitMax';
export const WAITING_PERIOD_FIELD_PROPERTY = 'eliminationPeriod';
export const BENEFIT_DURATION_FIELD_PROPERTY = 'benefitDuration';
export const DEFINITION_OF_DISABILITY_FIELD_PROPERTY = 'definitionOfDisability';
export const OWN_OCCUPATION_PERIOD_FIELD_PROPERTY = 'ownOccupationPeriod';
export const W2_PREPARATION = 'w2Preparation';
export const BENEFIT_FIELD_PROPERTY = 'benefitLevel';
export const LIFE_ADD_BENEFIT_FIELD_PROPERTY = 'lifeAndAddBenefit';
export const MAX_WEEKLY_BENEFIT_FIELD_PROPERTY = 'weeklyBenefitMax';
export const BASIC_ADD_RATE_FIELD_PROPERTY = 'basicAddRate';
export const PRE_EXISTING_CONDITIONS_PROPERTY = 'preExistingConditions';
export const STATE_DISABILITY_INTEGRATION_PROPERTY =
  'stateDisabilityIntegration';
export const NON_MDV_ENROLLMENT = 'totalEligibleEnrollments';
export const PLAN_ATTACHMENTS_LABEL = 'Attached';
export const OOP_FAM_OUT_NETWORK_PROPERTY = 'indWithinFamOopMaxOutNetwork';
export const DEDUCTIBLE_FAM_OUT_NETWORK_PROPERTY =
  'indWithinFamDeductiblesOutNetwork';
export const MONTHLY_PREMIUM_PROPERTY = 'totalMonthlyPremium';
export const ANNUAL_PREMIUM_PROPERTY = 'totalAnnualPremium';
export const FROM_CURRENT_PROPERTY = 'fromCurrent';
export const FROM_CURRENT_PERCENTAGE_PROPERTY = 'fromCurrentPercentage';
export const OUT_NETWORK_MAXIMUM_BENEFITS_FIELD_PROPERTY =
  'outNetworkMaximumBenefits';
export const OUT_NETWORK_ORTHODONTIA_MAX_FIELD_PROPERTY =
  'outNetworkOrthodontiaMax';
export const TOTAL_ELIGIBLE_EMPLOYEES = 'totalEligibleEmployees';
export const ANNUAL_ESTIMATED_CLAIMS = 'annualEstimatedClaims';
export const ADMIN_FEE = 'administrationFee';
export const FUNDING_TYPE = 'fundingType';
export const ADDITIONAL_SERVICES_FIELD_PROPERTY = 'additionalServices';
export const MULTIPLIER_FIELD_PROPERTY = 'multiplier';
export const FLAT_AMOUNT_FIELD_PROPERTY = 'flatAmount';
export const RATE_GUARANTEE_FIELD_PROPERTY = 'rateGuarantee';
export const REQUIRED_PARTICIPATION_FIELD_PROPERTY = 'requiredParticipation';
export const REQUIRED_EMPLOYER_CONTRIBUTION_FIELD_PROPERTY =
  'requiredEmployerContribution';

export const SERVICES_SECTION = 'services';
export const INN_OON_SUFFIX = '(In/Out)';
export const IN_NETWORK_VALUE = 'In Network';
export const OUT_NETWORK_VALUE = 'Out of Network';

export const FIELDS_SUB_TYPES = {
  SINGLE_WITHIN_FAMILY: 'SingleWithinFamily',
  SINGLE: 'Single',
  FAMILY: 'Family',
  RX_DEDUCTIBLES: 'RxDeductible',
  RX_OOP: 'RxOop',
};

export const PLAN_TYPE = 'Plan Type';
export const PLAN_NETWORK = 'Plan Network';
export const EE = 'EE Only';
export const ES = 'EE + Spouse';
export const EC = 'EE + Child';
export const EF = 'EE + Family';
export const MONTH = '/ mo';

export const TIER_TYPES = {
  STANDARD_TIER: 'FOUR_TIER',
  N_TIER: 'N_TIER',
  AGE_BAND: 'AGE_BAND',
};

export const STANDARD_TIER_NAMES_LIST = [EE, ES, EC, EF];

export const PAYLOAD_PLAN_TIER_TYPES = {
  STANDARD_TIER: 'STANDARD_TIER',
  N_TIER: 'N_TIER',
};
export const N_TIERS = [
  'employeeOnly',
  'employeePlus1',
  'employeePlus2',
  'employeePlus3',
  'employeePlus4',
  'employeePlus5',
  'employeePlus6',
];

export const OFFER_PLAN_PROPERTY: any = {
  MEDICAL: 'offeredMedicalPlans',
  DENTAL: 'offeredDentalPlans',
  VISION: 'offeredVisionPlans',
  LIFE: 'offeredLifePlans',
  LTD: 'offeredLifePlans',
  STD: 'offeredLifePlans',
  VOLUNTARY_BENEFIT: 'offeredVoluntaryPlans',
};

export const OFFER_SINGLE_PLAN_PROPERTY: any = {
  MEDICAL: 'offeredMedicalPlan',
  DENTAL: 'offeredDentalPlan',
  VISION: 'offeredVisionPlan',
  LIFE: 'lifeProposalPlan',
  LTD: 'lifeProposalPlan',
  STD: 'lifeProposalPlan',
  VOLUNTARY_BENEFIT: 'lifeProposalPlan',
};

export const BENEFIT_CARRIER_DROPDOWN_BENEFIT_TYPE: any = {
  MEDICAL: 'MEDICAL',
  DENTAL: 'DENTAL',
  VISION: 'VISION',
  'LIFE-ADD': 'LIFE',
  LIFE: 'LIFE',
  VOLUNTARY_BENEFIT: 'VOLUNTARY_BENEFIT',
  LTD: 'LIFE',
  STD: 'LIFE',
  'VOL-LIFE': 'VOLUNTARY_BENEFIT',
};

export const PLAN_TABLE_HEADER_TITLES: any = {
  monthlyPremiums: 'Monthly Premiums',
  deductibles: 'Deductibles & OOP Max',
  deductiblesAndMaximums: 'Deductible & Maximums',
  additionalServices: 'Frequencies',
  services: 'Services',
  rxDeductible: 'RX',
  rxTiers: 'RX',
  rxOop: 'RX',
  volume: 'RATES',
  administrationFee: 'RATES',
  attachedDocuments: 'Plan Documents',
  outNetworkDeductibles: 'out of network',
  totalMonthlyPremium: 'Total Cost',
};

export const PLAN_TABLE_INITIAL_TITLES: any = {
  planType: 'Plan Type',
  planNetwork: 'Plan Network',
  deductibles: 'Deductible',
  outNetworkDeductibles: 'Deductible',
  indWithinFamDeductibles: 'Ind within Fam Deductible',
  oops: 'OOP Max',
  outNetworkOOPS: 'OOP Max',
  indWithinFamOopMax: 'Ind within Fam OOP Max',
  rxTiers: 'Cost',
  rxTiersOutNetwork: 'Cost',
  rxDeductible: 'Rx Deductible',
  rxOop: 'Rx OOP Max',
  maximumBenefits: 'Plan Year Max',
  outNetworkMaximumBenefits: 'Plan Year Max',
  orthodontiaMax: 'Orthodontia Max',
  outNetworkOrthodontiaMax: 'Orthodontia Max',
  frequencyOfServices: 'Frequency of Services',
  lifeAndAddMaximum: 'Benefit Maximum',
  guaranteedIssue: 'Guaranteed Issue',
  ageReductionFromBase: 'Age Reduction',
  volume: 'Volume',
  lifeRate: 'Life Rate',
  eliminationPeriod: 'Waiting Period',
  benefitDuration: 'Max Benefit Duration',
  definitionOfDisability: 'Definition of Disability',
  ownOccupationPeriod: 'Own Occupation Period',
  w2Preparation: 'W-2 Preparation',
  monthlyBenefitMax: 'Max Monthly Benefit',
  benefitLevel: 'Benefit',
  weeklyBenefitMax: 'Max Weekly Benefit',
  basicAddRate: 'AD&D Rate',
  lifeAndAddBenefit: 'Benefit',
  preExistingConditions: 'Pre Existing Conditions',
  stateDisabilityIntegration: 'State Disability Integration',
  totalEligibleEnrollments: 'Enrollment',
  indWithinFamDeductiblesOutNetwork: 'Ind within Fam Deductible',
  indWithinFamOopMaxOutNetwork: 'Ind within Fam OOP Max ',
  rxDeductibleOutNetwork: 'Rx Deductible',
  rxOopOutNetwork: 'Rx OOP Max',
  totalMonthlyPremium: 'Total Monthly Premium',
  totalAnnualPremium: 'Total Annual Premium',
  fromCurrent: 'vs Current',
  totalEligibleEmployees: 'Enrollments',
  annualEstimatedClaims: 'Annual Est. Claims',
  administrationFee: 'Admin Fee',
  fundingType: 'Funding Type',
  flatAmount: 'Flat Amount',
  multiplier: 'Multiplier',
  [INDIVIDUAL_STOP_LOSS_FIELD]: INDIVIDUAL_STOP_LOSS_NAME,
  [INDIVIDUAL_STOP_LOSS_FEE_FIELD]: INDIVIDUAL_STOP_LOSS_FEE_NAME,
  [AGGREGATE_STOP_LOSS_FIELD]: AGGREGATE_STOP_LOSS_NAME,
  [AGGREGATE_STOP_LOSS_FEE_FIELD]: AGGREGATE_STOP_LOSS_FEE_NAME,
  [THIRD_PARTY_ADMIN_FEE_FIELD]: THIRD_PARTY_ADMIN_FEE_NAME,
  [OTHER_FEES_FIELD]: OTHER_FEES_NAME,
};

export const DEDUCTIBLE_TYPE: any = {
  Single: 'Ind',
  Family: 'Fam',
  Embedded: 'Emb',
};

export const MEDICAL_SERVICE_PRIORITY_ORDER = [
  'Primary Care Visit',
  'Specialist Visit',
  'Emergency Room',
  'Infertility Treatment',
  'Urgent Care',
  'Inpatient',
  'Outpatient',
];

export const DENTAL_ADDITIONAL_SERVICE_PRIORITY_ORDER = [
  'Waiting Period',
  'Endodontic Care',
  'Periodontic Care',
  'Implants',
];

export const DENTAL_SERVICE_PRIORITY_ORDER = [
  'Preventive Dental Care',
  'Basic Dental Care',
  'Major Dental Care',
  'Orthodontia',
  'Orthodontia Coinsurance',
  'OON Reimbursement',
  ...DENTAL_ADDITIONAL_SERVICE_PRIORITY_ORDER,
];

type TooltipContent = {
  title: string;
  subtitle: string;
};

export const TOOLTIP_CONST: { [key: string]: TooltipContent } = {
  'Preventive Dental Care': {
    title: 'Preventive Dental Care',
    subtitle: 'Includes services such as exams, X-rays, and cleanings.',
  },
  'Basic Dental Care': {
    title: 'Basic Dental Care',
    subtitle:
      'Includes services such as fillings, extractions, and root canals.',
  },
  'Major Dental Care': {
    title: 'Major Dental Care',
    subtitle:
      'Includes extensive procedures such as crowns, bridges, and dentures.',
  },
};

export const TOOLTIP_INOUT_CONST: { [key: string]: TooltipContent } = {
  'Preventive Dental Care (In/Out)': {
    title: 'Preventive Dental Care',
    subtitle: 'Includes services such as exams, X-rays, and cleanings.',
  },
  'Basic Dental Care (In/Out)': {
    title: 'Basic Dental Care',
    subtitle:
      'Includes services such as fillings, extractions, and root canals.',
  },
  'Major Dental Care (In/Out)': {
    title: 'Major Dental Care',
    subtitle:
      'Includes extensive procedures such as crowns, bridges, and dentures.',
  },
};

export const DENTAL_SERVICE_PRIORITY_DEFAULT_SERVICES = [
  'Preventive Dental Care',
  'Basic Dental Care',
  'Major Dental Care',
  'Orthodontia',
  'OON Reimbursement',
];
export const VISION_ADDITIONAL_SERVICE_PRIORITY_ORDER = [
  'Routine Eye Exam Frequency',
  'Lenses Frequency',
  'Frames Frequency',
  'Contact Lenses Frequency',
];

export const VISION_ADDITIONAL_SERVICE_BASIC_PROFILE_PRIORITY_ORDER = [
  'Routine Eye Exam Frequency',
  'Lenses Frequency',
  'Frames Frequency',
];

export const VISION_SERVICE_PRIORITY_ORDER = [
  'Frequency of Services',
  'Routine Eye Exam',
  'Frames',
  'Lenses (Single)',
  'Lenses (Bi)',
  'Lenses (Tri)',
  'Medical Nec. Contacts',
  'Elective Contacts',
  ...VISION_ADDITIONAL_SERVICE_PRIORITY_ORDER,
];
export const VISION_SERVICE_PRIORITY_ORDER_DEFAULT_SERVICES = [
  'Frequency of Services',
  'Routine Eye Exam',
  'Frames',
  'Lenses (Single)',
  'Lenses (Bi)',
  'Lenses (Tri)',
  'Medical Nec. Contacts',
  'Elective Contacts',
];

const ADDITIONAL_DENTAL_OPTIONS = [
  {
    value: 'Waiting Period',
    label: 'Waiting Period',
  },
  {
    value: 'Endodontic Care',
    label: 'Endodontic Care',
  },
  {
    value: 'Periodontic Care',
    label: 'Periodontic Care',
  },
  {
    value: 'Implants',
    label: 'Implants',
  },
];

const ADDITIONAL_VISION_OPTIONS = [
  {
    value: 'Routine Eye Exam Frequency',
    label: 'Routine Eye Exam Frequency',
  },
  {
    value: 'Lenses Frequency',
    label: 'Lenses Frequency',
  },
  {
    value: 'Frames Frequency',
    label: 'Frames Frequency',
  },
  {
    value: 'Contact Lenses Frequency',
    label: 'Contact Lenses Frequency',
  },
];

export const ADDITIONAL_OPTIONS: any = {
  DENTAL: ADDITIONAL_DENTAL_OPTIONS,
  VISION: ADDITIONAL_VISION_OPTIONS,
};

export const SERVICE_PRIORITY_ORDER: any = {
  MEDICAL: MEDICAL_SERVICE_PRIORITY_ORDER,
  DENTAL: DENTAL_SERVICE_PRIORITY_ORDER,
  VISION: VISION_SERVICE_PRIORITY_ORDER,
};

/**
 * Contains all the default services (Without additional services)
 */
export const SERVICE_PRIORITY_ORDER_DEFAULT_SERVICES: any = {
  MEDICAL: MEDICAL_SERVICE_PRIORITY_ORDER,
  DENTAL: DENTAL_SERVICE_PRIORITY_DEFAULT_SERVICES,
  VISION: VISION_SERVICE_PRIORITY_ORDER_DEFAULT_SERVICES,
};

export const ADDITIONAL_SERVICE_PRIORITY_ORDER: any = {
  DENTAL: DENTAL_ADDITIONAL_SERVICE_PRIORITY_ORDER,
  VISION: VISION_ADDITIONAL_SERVICE_PRIORITY_ORDER,
};

export const VISION_FREQUENCY_OPTIONS = [
  {
    value: 'Routine Eye Exam Frequency',
    label: 'Routine Eye Exam Frequency',
  },
  {
    value: 'Lenses Frequency',
    label: 'Lenses Frequency',
  },
  {
    value: 'Frames Frequency',
    label: 'Frames Frequency',
  },
];

export const PREMIUMS_SHORT_FORM: any = {
  'Total Monthly Rate Tier 1*': 'EE Only',
  'Total Monthly Rate Tier 2*': 'EE + Spouse',
  'Total Monthly Rate Tier 3*': 'EE + Child',
  'Total Monthly Rate Tier 4*': 'EE + Family',
};

export const PREMIUMS_PRIORITY_ORDER = [
  'EE Only',
  'EE + Spouse',
  'EE + Child',
  'EE + Family',
  'EE + 1',
  'EE + 2',
  'EE + 3',
  'EE + 4',
  'EE + 5',
  'EE + 6',
];

export const OFFER_CATEGORY = {
  MEDICAL: 'MEDICAL',
  DENTAL: 'DENTAL',
  VISION: 'VISION',
  LIFE_AD: 'LIFE',
  SHORT_TERM_DISABILITY: 'STD',
  LONG_TERM_DISABILITY: 'LTD',
  VOLUNTARY: 'VOLUNTARY_BENEFIT',
};

export const OFFER_TYPE_NAMES = {
  MEDICAL: 'MEDICAL',
  DENTAL: 'DENTAL',
  VISION: 'VISION',
  LIFE_AD: 'LIFE',
  SHORT_TERM_DISABILITY: 'STD',
  LONG_TERM_DISABILITY: 'LTD',
  VOLUNTARY: 'VOLUNTARY_BENEFIT',
};

export const CREDIT_DROPDOWN_ENUMS = [
  {
    label: 'Credits Excluded',
    value: 'CREDITS_EXCLUDED',
  },
  {
    label: 'Credits Included',
    value: 'CREDITS_INCLUDED',
  },
];

export const OFFER_TYPES = {
  PRIMARY: 'PRIMARY',
  SUPPLEMENTARY: 'SUPPLEMENTARY',
};

export const CREDITS_INCLUSION = {
  EXCLUDED: { value: 'EXCLUDED', label: 'Costs & Credits Excluded' },
  INCLUDED: { value: 'INCLUDED', label: 'Costs & Credits Included' },
} as const;

export const ONLY_CREDITS_INCLUSION = {
  EXCLUDED: { value: 'EXCLUDED', label: 'Credits Excluded' },
  INCLUDED: { value: 'INCLUDED', label: 'Credits Included' },
} as const;

export const PROPOSAL_SORT_METHODS = {
  RECOMMENDED: { value: 'RECOMMENDED', label: 'Recommended' },
  MOST_RECENT: { value: 'MOST_RECENT', label: 'Most Recent' },
  FROM_CURRENT: { value: 'FROM_CURRENT', label: '% From Current' },
  TOTAL_ANNUAL_PREMIUM: {
    value: 'TOTAL_ANNUAL_PREMIUM',
    label: 'Total Annual Premium',
  },
  RENEWAL_DATE_LENGTH: {
    value: 'RENEWAL_DATE_LENGTH',
    label: 'Renewal Date Length',
  },
} as const;

export const PROPOSAL_CARD_ACTION_OPTIONS = {
  UPDATE_PROPOSAL: { value: 'UPDATE_PROPOSAL', label: 'Update Proposal' },
  EXPORT_PROPOSAL: {
    value: 'EXPORT_PROPOSAL',
    label: 'Export Offers and Proposals',
  },
  EDIT_PROPOSAL: { value: 'EDIT_PROPOSAL', label: 'Edit Proposal' },
  CLONE_PROPOSAL: { value: 'CLONE_PROPOSAL', label: 'Clone Proposal' },
  DELETE_PROPOSAL: { value: 'DELETE_PROPOSAL', label: 'Delete Proposal' },
  RECOMMEND: { value: 'RECOMMEND', label: 'Recommend' },
  REMOVE_RECOMMEND: {
    value: 'REMOVE_RECOMMEND',
    label: 'Remove Recommendation',
  },
  MOVE_TO_DRAFTS: { value: 'MOVE_TO_DRAFTS', label: 'Move to Drafts' },
  PUBLISH: { value: 'PUBLISH', label: 'Publish Proposal' },
  FINAL_APPROVED: {
    value: 'FINAL_APPROVED',
    label: 'Mark as Final Approved',
  },
  FINAL_APPROVED_DISABLED: {
    value: 'FINAL_APPROVED',
    label: 'Mark as Final Approved',
    disable: true,
  },
  UNMARK_FINAL_APPROVED: {
    value: 'UNMARK_FINAL_APPROVED',
    label: 'Unmark as Final Approved',
  },
  EXPORT_AUDIT_LOG: { value: 'EXPORT_AUDIT_LOG', label: 'Export Activity Log' },
} as const;

export const PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS = {
  EDIT_PROPOSAL: {
    value: 'EDIT_PROPOSAL',
    label: 'Edit Proposal',
  },
  DELETE_PROPOSAL: { value: 'DELETE_PROPOSAL', label: 'Delete Proposal' },
  CLONE_PROPOSAL: { value: 'CLONE_PROPOSAL', label: 'Clone Proposal' },
  EXPORT_PROPOSAL: {
    value: 'EXPORT_PROPOSAL',
    label: 'Export Offers and Proposals',
  },
  RECOMMEND: { value: 'RECOMMEND', label: 'Recommend' },
  REMOVE_RECOMMEND: {
    value: 'REMOVE_RECOMMEND',
    label: 'Remove Recommendation',
  },
  MOVE_TO_DRAFTS: { value: 'MOVE_TO_DRAFTS', label: 'Move to Drafts' },
  FINAL_APPROVED: {
    value: 'FINAL_APPROVED',
    label: 'Mark as Final Approved',
  },
  PUBLISH: {
    value: 'PUBLISH',
    label: 'Publish Proposal',
  },
  UNMARK_FINAL_APPROVED: {
    value: 'UNMARK_FINAL_APPROVED',
    label: 'Unmark as Final Approved',
  },
  COMPARE_PLANS: {
    value: 'COMPARE_PLANS',
    label: 'Compare Plans',
  },
} as const;

export const PROPOSAL_STATUS_FINALISED = 'FINALISED';

export const PROPOSAL_CARD_TAGS = {
  RECOMMENDED: 'RECOMMENDED',
  DRAFT: 'DRAFT',
  FINAL_APPROVED: 'FINAL_APPROVED',
  FINALISED: PROPOSAL_STATUS_FINALISED,
  QUOTE_PROCESSING: 'QUOTE PROCESSING',
} as const;

export const PROPOSAL_CARD_DESCRIPTION_TEXT_LIMIT = 250;

export const OUTDATED_PROPOSALS_TOOLTIP_TEXT = `This section is for proposals that no longer have the up-to-date financial data for one or more offers within it. To make these proposals active again, please review the changes and update the proposal.`;

export const OFFER_STATUSES = {
  CURRENT: { value: 'CURRENT', label: 'RENEWAL', color: 'blue' },
  PENDING: { value: 'PENDING', label: 'PENDING', color: 'grey' },
  DTQ: { value: 'DTQ', label: 'DECLINED TO QUOTE', color: 'darkGrey' },
  RECEIVED: { value: 'RECEIVED', label: 'RECEIVED', color: 'grey' },
  DRAFT: { value: 'DRAFT', label: 'DRAFT', color: 'darkGrey' },
  RECOMMENDED: {
    value: 'RECOMMENDED',
    label: 'RECOMMENDED',
    color: 'yellow',
    startIcon: starIcon,
  },
  NEW_PLAN: { value: 'NEW_PLAN', label: 'NEW PLAN', color: 'whiteBlueBorder' },
  FINAL_APPROVED: {
    value: 'FINAL_APPROVED',
    label: 'FINAL APPROVED',
    color: 'black',
  },
  PUBLISH: {
    value: 'PUBLISH',
    label: 'PUBLISH',
    color: 'whiteBlueBorder',
  },
  QUOTE_PROCESSING: {
    value: 'QUOTE PROCESSING',
    label: 'QUOTE PROCESSING',
    color: 'black',
  },
} as const;

export const MDV_PLANS = ['MEDICAL', 'DENTAL', 'VISION'];
export const DV_PLANS = ['DENTAL', 'VISION'];
export const LIFE_PLANS = ['LIFE', 'STD', 'LTD'];
export const STD_LTD_PLANS = ['STD', 'LTD'];
export const BENEFIT_TYPE_OPTIONS = [
  { value: 'MEDICAL', label: 'Medical' },
  { value: 'DENTAL', label: 'Dental' },
  { value: 'VISION', label: 'Vision' },
  { value: 'LIFE', label: 'Life & Disability' },
  { value: 'STD', label: 'Short Term Disability' },
  { value: 'LTD', label: 'Long Term Disability' },
  { value: 'VOLUNTARY_BENEFIT', label: 'Voluntary Benefits' },
];

export const RENEWAL_DATE_OPTIONS = [
  { value: 'LE_THIRTY_DAYS', label: '< 30 days' },
  { value: 'GT_ONE_LE_TWO_MONTH', label: '1-2 months' },
  { value: 'GT_TWO_LE_FOUR_MONTH', label: '3-4 months' },
  { value: 'GT_FOUR_MONTH', label: '4+ months' },
];

export const LAST_MODIFIED_DATE_OPTIONS = [
  { value: 'LT_FIVE_DAYS', label: '< 5 days' },
  { value: 'GE_FIVE_LT_TEN_DAYS', label: '5-10 days' },
  { value: 'GE_TEN_LT_THIRTY_DAYS', label: '11-30 days' },
  { value: 'GE_THIRTY_DAYS', label: '30+ days' },
];

export const BID_STATUSES = [
  { value: 'RECEIVED', label: 'Received' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'DTQ', label: 'Decline to Quote' },
];

export const RESET_BID_STATUSES = ['RECEIVED', 'PENDING', 'DTQ'];
export const RESET_BENEFIT_TYPES = ['RENEWAL', 'ALTERNATIVE'];

export const BENEFIT_TYPES = [
  { value: 'RENEWAL', label: 'Renewal' },
  { value: 'ALTERNATIVE', label: 'Alternative' },
];

export const STATUS_FIVE_DAYS = '[0,4]';
export const STATUS_FIVE_TO_TEN_DAYS = '[5,10]';
export const STATUS_ELEVEN_THIRTY_DAYS = '[11,30]';
export const STATUS_THIRTY_DAYS = '[31,100]';

export const OFFERS_DAYS_OPEN_MAP = [
  { value: 'FIVE_DAYS', label: '< 5 days' },
  { value: 'TEN_DAYS', label: '5 - 10 days' },
  { value: 'THIRTY_DAYS', label: '11 - 30 days' },
  { value: 'MONTH_PLUS', label: '30+ days' },
];

export const CREDIT_DROPDOWN_LABELS = ['Credits Excluded', 'Credits Included'];

export const PER_TIER = 'PER_TIER';
export const BASE_PLAN_BUY_UP = 'BASE_PLAN_BUY_UP';
export const PASS_THROUGH = 'PASS_THROUGH';
export const PASS_THROUGH_EE = 'PASS_THROUGH_EE';
export const FIFTY_FIFTY = 'FIFTY_FIFTY';

export const SORT_MODELING_CARRIER = 'CARRIER';
export const SORT_MODELING_PLAN_TYPE = 'PLAN_TYPE';
export const SORT_MODELING_TOTAL_ENROLLMENTS = 'ENROLLMENT';
export const SORT_MODELING_RATES = 'RATE';
export const SORT_MODELING_CUSTOM = {
  label: 'Custom',
  value: 'CUSTOM',
};

export const SORT_PLANS_LIST = {
  MEDICAL: [
    { value: SORT_MODELING_CARRIER, label: 'Carrier' },
    { value: SORT_MODELING_PLAN_TYPE, label: 'Plan Type' },
    { value: SORT_MODELING_TOTAL_ENROLLMENTS, label: 'Total Enrollments' },
    { value: SORT_MODELING_RATES, label: 'Rates' },
  ],
  OTHER: [
    { value: SORT_MODELING_CARRIER, label: 'Carrier' },
    { value: SORT_MODELING_TOTAL_ENROLLMENTS, label: 'Total Enrollments' },
    { value: SORT_MODELING_RATES, label: 'Rates' },
  ],
};

export const MODELING_STRATEGY_LIST = [
  { value: PER_TIER, label: 'Proportional' },
  { value: BASE_PLAN_BUY_UP, label: 'Base Plan Buy Up' },
  { value: PASS_THROUGH, label: 'Pass through to ER' },
  { value: PASS_THROUGH_EE, label: 'Pass through to EE' },
  { value: FIFTY_FIFTY, label: '50/50 Split' },
];

export const TIER_LIST_ENUMS = {
  ENROLLMENTS: 'ENROLLMENTS',
  ER_SPLIT: 'ER_SPLIT',
  EE_SPLIT: 'EE_SPLIT',
  HSA_CONTRIBUTION: 'HSA_CONTRIBUTION',
  ER_PREMIUM: 'ER_PREMIUM',
  EE_PREMIUM: 'EE_PREMIUM',
  HRA_CONTRIBUTION: 'HRA_CONTRIBUTION',
};

export const EE_TIER = 'EE';
export const ES_TIER = 'ES';
export const EC_TIER = 'EC';
export const EF_TIER = 'EF';
export const EE1_TIER = 'EE1';
export const EE2_TIER = 'EE2';
export const EE3_TIER = 'EE3';
export const EE4_TIER = 'EE4';
export const EE5_TIER = 'EE5';
export const EE6_TIER = 'EE6';
export const TIER_NAME_LIST = [EE_TIER, ES_TIER, EC_TIER, EF_TIER];
export const N_TIER_NAME_LIST = [
  EE_TIER,
  EE1_TIER,
  EE2_TIER,
  EE3_TIER,
  EE4_TIER,
  EE5_TIER,
  EE6_TIER,
];
export const RENEWAL_SPLIT_ABS = 'erSplitAbs';
export const HSA_CONTRIBUTION_KEY = 'hsaContribution';
export const TOTAL_WITHOUT_HSA_KEY = 'premiumWithoutHsa';
export const HRA_FACE_VALUE = 'hraFaceValue';
export const HRA_CONTRIBUTION = 'hraContribution';
export const ER_HSA_CONTRIBUTIONS = 'erHsaContributions';
export const PREMIUM = 'premium';
export const PLAN_TYPES = {
  HDHP: 'HDHP',
};
export const MODELING_KEYS = {
  enrollments: 'enrollments',
  ER_SPLIT: 'ER_SPLIT',
  EE_SPLIT: 'EE_SPLIT',
  HSA_CONTRIBUTION: 'HSA_CONTRIBUTION',
  ER_PREMIUM: 'ER_PREMIUM',
  EE_PREMIUM: 'EE_PREMIUM',
};
export const RESET_OFFERS_DAYS_OPEN_MAP = [
  'FIVE_DAYS',
  'TEN_DAYS',
  'THIRTY_DAYS',
  'MONTH_PLUS',
];

export const RESET_BENEFIT_TYPE_OPTIONS = [
  'MEDICAL',
  'DENTAL',
  'VISION',
  'LIFE',
  'LTD',
  'STD',
  'VOLUNTARY_BENEFIT',
];

export const OFFER_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  PREVIEW: 'PREVIEW',
  VERSIONED: 'VERSIONED',
  PUBLISH: 'PUBLISH',
  IN_PROGRESS: 'IN_PROGRESS', // This status only use in FE
};

export const OFFER_TYPE_SPECIFICS = {
  CURRENT: 'CURRENT',
  RENEWAL: 'RENEWAL',
  OTHER: 'OTHER',
  ALTERNATIVE: 'ALTERNATIVE',
};

export const PROPOSAL_DETAILED_RENEWAL_TYPES = {
  medical: 'MEDICAL',
  dental: 'DENTAL',
  vision: 'VISION',
  life: 'LIFE',
  ltd: 'LTD',
  std: 'STD',
  basic_add: 'LIFE',
  basic_life_and_add: 'LIFE',
  basic_life: 'LIFE',
} as any;

export const PROPOSAL_DETAILED_RENEWAL_NAVIGATION_TYPES = {
  medical: 'medical',
  dental: 'dental',
  vision: 'vision',
  'life & disability': 'life',
} as any;

export const BENEFIT_TYPES_DISCOUNT_DROPDOWN_OPTIONS = [
  {
    value: PROPOSAL_DETAILED_RENEWAL_TYPES.medical,
    label: OFFER_BENEFIT_TYPE_LABELS.MEDICAL,
  },
  {
    value: PROPOSAL_DETAILED_RENEWAL_TYPES.dental,
    label: OFFER_BENEFIT_TYPE_LABELS.DENTAL,
  },
  {
    value: PROPOSAL_DETAILED_RENEWAL_TYPES.vision,
    label: OFFER_BENEFIT_TYPE_LABELS.VISION,
  },
  {
    value: PROPOSAL_DETAILED_RENEWAL_TYPES.life,
    label: OFFER_BENEFIT_TYPE_LABELS.LIFE,
  },
  {
    value: PROPOSAL_DETAILED_RENEWAL_TYPES.std,
    label: OFFER_BENEFIT_TYPE_LABELS.SHORT_TERM_DISABILITY,
  },
  {
    value: PROPOSAL_DETAILED_RENEWAL_TYPES.ltd,
    label: OFFER_BENEFIT_TYPE_LABELS.LONG_TERM_DISABILITY,
  },
];

export const PROPOSAL_OFFER_LIST_PROPERTIES = {
  MEDICAL: 'supplementaryMedicalOffers',
  DENTAL: 'supplementaryDentalOffers',
  VISION: 'supplementaryVisionOffers',
  LIFE: 'lifeAddOffers',
  STD: 'lifeSTDOffers',
  LTD: 'lifeLTDOffers',
} as any;

export const LIFE_PLAN_TYPES = [
  'LIFE',
  'BASIC_ADD',
  'BASIC_LIFE_AND_ADD',
  'BASIC_LIFE',
];

export const LIFE_INCLUDING_PLAN_TYPES = [
  'LIFE',
  'BASIC_ADD',
  'BASIC_LIFE_AND_ADD',
  'BASIC_LIFE',
  'LTD',
  'STD',
];

export const TIME_PERIOD_VALIDATION = {
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS',
};

export const PLAN_TYPES_DROPDOWN_MEDICAL = [
  { label: 'HMO', value: 'HMO' },
  { label: 'PPO', value: 'PPO' },
  { label: 'EPO', value: 'EPO' },
  { label: 'POS', value: 'POS' },
];

export const PLAN_TYPES_DROPDOWN_DENTAL = [
  { label: 'PPO', value: 'PPO' },
  { label: 'HMO', value: 'HMO' },
  { label: 'Other', value: 'Other' },
];

export const PLAN_TYPES_MD = {
  MEDICAL: PLAN_TYPES_DROPDOWN_MEDICAL,
  DENTAL: PLAN_TYPES_DROPDOWN_DENTAL,
} as any;

export const LIFE_DISABILITY_BENEFITS = [
  OFFER_CATEGORY.LIFE_AD,
  OFFER_CATEGORY.LONG_TERM_DISABILITY,
  OFFER_CATEGORY.SHORT_TERM_DISABILITY,
] as OfferBenefitType[];

export const PLAN_DROPDOWN_TYPES = {
  MEDICAL: 'medicals',
  DENTAL: 'dentals',
  VISION: 'visions',
  'LIFE-ADD': 'insurances',
  LIFE: 'insurances',
  STD: 'insurances',
  LTD: 'insurances',
  'VOL-LIFE': 'insurances',
  VOLUNTARY_BENEFIT: 'insurances',
} as any;

export const QUOTE_ADDING_PROPERTY = {
  MEDICAL: 'plans',
  DENTAL: 'dentalPlans',
  VISION: 'visionPlans',
  'LIFE-ADD': 'lifePlans',
  LIFE: 'lifePlans',
  STD: 'lifePlans',
  LTD: 'lifePlans',
  'VOL-LIFE': 'voluntaryPlans',
  VOLUNTARY_BENEFIT: 'voluntaryPlans',
} as any;

export const QUOTE_ADDING_BUNDLE_PROPERTY = {
  MEDICAL: 'medicalBundles',
  DENTAL: 'dentalBundles',
  VISION: 'visionBundles',
  'LIFE-ADD': 'lifeBundles',
  LIFE: 'lifeBundles',
  STD: 'lifeBundles',
  LTD: 'lifeBundles',
  'VOL-LIFE': 'voluntaryBundles',
  VOLUNTARY_BENEFIT: 'voluntaryBundles',
} as any;
export const OPEN_OFFERS_SEARCH: string = 'OPEN_OFFERS_SEARCH';

export const CARRIER_DOCUMENTS = 'Carrier Documents';

export const NO_FILE_UPLOADED = 'No file uploaded';
export const CARRIER_FILE_UPLOAD_Z_INDEX = 10000;

export const CARRIER_DOCUMENT_TYPES = {
  CARRIER_QUOTE: 'CARRIER_QUOTE',
  DISRUPTION_REPORT: 'DISRUPTION_REPORT',
  OTHER_DOCUMENTS_FILE: 'OTHER_DOCUMENTS_FILE',
};

export const PREMIUMS = {
  'EE Monthly Premium *': {
    tier: {
      property: 'monthlyPremiums',
      name: 'EE Only',
      fieldType: null,
      fieldSubType: '1/4',
    },
    enrollment: {
      property: 'enrolments',
      name: 'ENROLLMENT_TIER_1',
      fieldType: null,
      fieldSubType: '1/4',
    },
  },
  'ES Monthly Premium *': {
    tier: {
      property: 'monthlyPremiums',
      name: 'EE + Spouse',
      fieldType: null,
      fieldSubType: '2/4',
    },
    enrollment: {
      property: 'enrolments',
      name: 'ENROLLMENT_TIER_2',
      fieldType: null,
      fieldSubType: '2/4',
    },
  },
  'EC Monthly Premium *': {
    tier: {
      property: 'monthlyPremiums',
      name: 'EE + Child',
      fieldType: null,
      fieldSubType: '3/4',
    },
    enrollment: {
      property: 'enrolments',
      name: 'ENROLLMENT_TIER_3',
      fieldType: null,
      fieldSubType: '3/4',
    },
  },
  'EF Monthly Premium *': {
    tier: {
      property: 'monthlyPremiums',
      name: 'EE + Family',
      fieldType: null,
      fieldSubType: '4/4',
    },
    enrollment: {
      property: 'enrolments',
      name: 'ENROLLMENT_TIER_4',
      fieldType: null,
      fieldSubType: '4/4',
    },
  },
};

export const DEDUCTIBLES_DENTAL = {
  'Individual Deductible': {
    inNetwork: {
      property: 'deductibles',
      name: 'Deductible',
      fieldType: 'In Network',
      fieldSubType: 'Single',
    },
    outNetwork: {
      property: 'deductibles',
      name: 'Deductible',
      fieldType: 'Out of Network',
      fieldSubType: 'Single',
    },
  },
  'Family Deductible': {
    inNetwork: {
      property: 'deductibles',
      name: 'Deductible',
      fieldType: 'In Network',
      fieldSubType: 'Family',
    },
    outNetwork: {
      property: 'deductibles',
      name: 'Deductible',
      fieldType: 'Out of Network',
      fieldSubType: 'Family',
    },
  },
  'Plan Year Max': {
    inNetwork: {
      property: 'maximumBenefits',
      name: 'Plan Year Max',
      fieldType: 'In Network',
      fieldSubType: null,
    },
    outNetwork: {
      property: 'maximumBenefits',
      name: 'Plan Year Max',
      fieldType: 'Out of Network',
      fieldSubType: null,
    },
  },
  'Orthodontia Max': {
    inNetwork: {
      property: 'orthodontiaMax',
      name: 'Orthodontia Max',
      fieldType: 'In Network',
      fieldSubType: null,
    },
    outNetwork: {
      property: 'orthodontiaMax',
      name: 'Orthodontia Max',
      fieldType: 'Out of Network',
      fieldSubType: null,
    },
  },
};

export const DEDUCTIBLES_MEDICAL = {
  'Individual Deductible': {
    inNetwork: {
      property: 'deductibles',
      name: 'Deductible',
      fieldType: 'In Network',
      fieldSubType: 'Single',
    },
    outNetwork: {
      property: 'deductibles',
      name: 'Deductible',
      fieldType: 'Out of Network',
      fieldSubType: 'Single',
    },
  },
  'Family Deductible': {
    inNetwork: {
      property: 'deductibles',
      name: 'Deductible',
      fieldType: 'In Network',
      fieldSubType: 'Family',
    },
    outNetwork: {
      property: 'deductibles',
      name: 'Deductible',
      fieldType: 'Out of Network',
      fieldSubType: 'Family',
    },
  },
  'Ind. within Family Deductible': {
    inNetwork: {
      property: 'deductibles',
      name: 'Deductible',
      fieldType: 'In Network',
      fieldSubType: 'SingleWithinFamily',
    },
    outNetwork: {
      property: 'deductibles',
      name: 'Deductible',
      fieldType: 'Out of Network',
      fieldSubType: 'SingleWithinFamily',
    },
  },
  'Individual OOP Max': {
    inNetwork: {
      property: 'oops',
      name: 'OOP Max',
      fieldType: 'In Network',
      fieldSubType: 'Single',
    },
    outNetwork: {
      property: 'oops',
      name: 'OOP Max',
      fieldType: 'Out of Network',
      fieldSubType: 'Single',
    },
  },
  'Family OOP Max': {
    inNetwork: {
      property: 'oops',
      name: 'OOP Max',
      fieldType: 'In Network',
      fieldSubType: 'Family',
    },
    outNetwork: {
      property: 'oops',
      name: 'OOP Max',
      fieldType: 'Out of Network',
      fieldSubType: 'Family',
    },
  },
  'Ind. within Family OOP Max': {
    inNetwork: {
      property: 'oops',
      name: 'OOP Max',
      fieldType: 'In Network',
      fieldSubType: 'SingleWithinFamily',
    },
    outNetwork: {
      property: 'oops',
      name: 'OOP Max',
      fieldType: 'Out of Network',
      fieldSubType: 'SingleWithinFamily',
    },
  },
};

export const RX_DEDUCTIBLES = {
  rxDeductiblesAndOopMax: {
    'Individual Rx Deductible': {
      inNetwork: {
        property: 'rxDeductible',
        name: 'RxDeductibleAndOopIndividual',
        fieldType: 'In Network',
        fieldSubType: 'Single',
      },
      outNetwork: {
        property: 'rxDeductible',
        name: 'RxDeductibleAndOopIndividual',
        fieldType: 'Out of Network',
        fieldSubType: 'Single',
      },
    },
    'Family Rx Deductible': {
      inNetwork: {
        property: 'rxDeductible',
        name: 'RxDeductibleAndOopFamily',
        fieldType: 'In Network',
        fieldSubType: 'Family',
      },
      outNetwork: {
        property: 'rxDeductible',
        name: 'RxDeductibleAndOopFamily',
        fieldType: 'Out of Network',
        fieldSubType: 'Family',
      },
    },
    'Individual Rx OOP Max': {
      inNetwork: {
        property: 'rxOop',
        name: 'RxDeductibleAndOopIndividual',
        fieldType: 'In Network',
        fieldSubType: 'Single',
      },
      outNetwork: {
        property: 'rxOop',
        name: 'RxDeductibleAndOopIndividual',
        fieldType: 'Out of Network',
        fieldSubType: 'Single',
      },
    },
    'Family Rx OOP Max': {
      inNetwork: {
        property: 'rxOop',
        name: 'RxDeductibleAndOopFamily',
        fieldType: 'In Network',
        fieldSubType: 'Family',
      },
      outNetwork: {
        property: 'rxOop',
        name: 'RxDeductibleAndOopFamily',
        fieldType: 'Out of Network',
        fieldSubType: 'Family',
      },
    },
  },
  rxTiers: {
    'Tier 1': {
      inNetwork: {
        property: 'rxTiers',
        name: 'Rx Tier 1',
        fieldType: 'In Network',
        fieldSubType: null,
      },
      outNetwork: {
        property: 'rxTiers',
        name: 'Rx Tier 1',
        fieldType: 'Out of Network',
        fieldSubType: null,
      },
    },
    'Tier 2': {
      inNetwork: {
        property: 'rxTiers',
        name: 'Rx Tier 2',
        fieldType: 'In Network',
        fieldSubType: null,
      },
      outNetwork: {
        property: 'rxTiers',
        name: 'Rx Tier 2',
        fieldType: 'Out of Network',
        fieldSubType: null,
      },
    },
    'Tier 3': {
      inNetwork: {
        property: 'rxTiers',
        name: 'Rx Tier 3',
        fieldType: 'In Network',
        fieldSubType: null,
      },
      outNetwork: {
        property: 'rxTiers',
        name: 'Rx Tier 3',
        fieldType: 'Out of Network',
        fieldSubType: null,
      },
    },
    'Tier 4': {
      inNetwork: {
        property: 'rxTiers',
        name: 'Rx Tier 4',
        fieldType: 'In Network',
        fieldSubType: null,
      },
      outNetwork: {
        property: 'rxTiers',
        name: 'Rx Tier 4',
        fieldType: 'Out of Network',
        fieldSubType: null,
      },
    },
  },
};

export const LIFE_INFO = {
  Multiplier: {
    property: 'multiplier',
    name: 'Multiplier',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Flat Amount': {
    property: 'flatAmount',
    name: 'Flat Amount',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  Benefit: {
    property: 'lifeAndAddBenefit',
    name: 'Benefit',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Benefit Maximum': {
    property: 'lifeAndAddMaximum',
    name: 'Benefit Maximum',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Guaranteed Issue': {
    property: 'guaranteedIssue',
    name: 'Guaranteed Issue',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Age Reduction': {
    property: 'ageReductionFromBase',
    name: 'Age Reduction from Base',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Volume *': {
    property: 'volume',
    name: 'Volume*',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
    validations: {
      currency: true,
      number: true,
      decimalScale: 2,
    },
  },
  'Life Rate *': {
    property: 'lifeRate',
    name: 'Basic Life Rate*',
    fieldType: null,
    fieldSubType: null,
    validations: {
      number: true,
    },
  },
  'AD&D Rate *': {
    property: 'basicAddRate',
    name: 'Basic AD&D Rate*',
    fieldType: null,
    fieldSubType: null,
    validations: {
      number: true,
    },
  },
};

export const STD_FULLY_INSURED_INFO = {
  'STD Benefit': {
    property: 'benefitLevel',
    name: 'Benefit',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Max Weekly Benefit': {
    property: 'weeklyBenefitMax',
    name: 'Max Weekly Benefit',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Waiting Period': {
    property: 'eliminationPeriod',
    name: 'Waiting Period',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Max Benefit Duration': {
    property: 'benefitDuration',
    name: 'Max Benefit Duration',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Definition of Disability': {
    property: 'definitionOfDisability',
    name: 'Definition of Disability',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Pre Existing Conditions': {
    property: 'preExistingConditions',
    name: 'Pre Existing Conditions',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'State Disability Integration': {
    property: 'stateDisabilityIntegration',
    name: 'State Disability Integration',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Volume *': {
    property: 'volume',
    name: 'Volume*',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
    validations: {
      currency: true,
      number: true,
      decimalScale: 2,
    },
  },
  'STD Rate *': {
    property: 'lifeRate',
    name: 'STD Rate*',
    fieldType: null,
    fieldSubType: null,
    validations: {
      currency: true,
      number: true,
    },
  },
};

export const STD_SELF_FUNDED_INFO = {
  'STD Benefit': {
    property: 'benefitLevel',
    name: 'Benefit',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Max Weekly Benefit': {
    property: 'weeklyBenefitMax',
    name: 'Max Weekly Benefit',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Waiting Period': {
    property: 'eliminationPeriod',
    name: 'Waiting Period',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Max Benefit Duration': {
    property: 'benefitDuration',
    name: 'Max Benefit Duration',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Definition of Disability': {
    property: 'definitionOfDisability',
    name: 'Definition of Disability',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Pre Existing Conditions': {
    property: 'preExistingConditions',
    name: 'Pre Existing Conditions',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'State Disability Integration': {
    property: 'stateDisabilityIntegration',
    name: 'State Disability Integration',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Annual Est. Claims *': {
    property: 'annualEstimatedClaims',
    name: 'Annual Est. Claims*',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
    validations: {
      currency: true,
      number: true,
      decimalScale: 2,
    },
  },
  'Enrollments *': {
    property: 'totalEligibleEmployees',
    name: 'Total Eligible Employees*',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
    validations: {
      number: true,
      decimalScale: 0,
    },
  },
};

export const LTD_INFO = {
  'LTD Benefit': {
    property: 'benefitLevel',
    name: 'Benefit',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Max Monthly Benefit': {
    property: 'monthlyBenefitMax',
    name: 'Max Monthly Benefit',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Waiting Period': {
    property: 'eliminationPeriod',
    name: 'Waiting Period',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Max Benefit Duration': {
    property: 'benefitDuration',
    name: 'Max Benefit Duration',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Definition of Disability': {
    property: 'definitionOfDisability',
    name: 'Definition of Disability',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Own Occupation Period': {
    property: 'ownOccupationPeriod',
    name: 'Own Occupation Period',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'W-2 Preparation': {
    property: 'w2Preparation',
    name: 'W-2 Preparation',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
  },
  'Volume *': {
    property: 'volume',
    name: 'Volume*',
    fieldType: null,
    fieldSubType: null,
    isObj: true,
    validations: {
      currency: true,
      number: true,
      decimalScale: 2,
    },
  },
  'LTD Rate *': {
    property: 'lifeRate',
    name: 'LTD Rate*',
    fieldType: null,
    fieldSubType: null,
    validations: {
      number: true,
    },
  },
};

export const EDIT_PLAN_TABLE = {
  MEDICAL: {
    PREMIUMS: PREMIUMS,
    DEDUCTIBLES: DEDUCTIBLES_MEDICAL,
    RX_DEDUCTIBLES: RX_DEDUCTIBLES,
  },
  DENTAL: {
    PREMIUMS: PREMIUMS,
    DEDUCTIBLES: DEDUCTIBLES_DENTAL,
  },
  DENTAL_SELF_FUNDED: {
    PREMIUMS: PREMIUMS,
    DEDUCTIBLES: DEDUCTIBLES_DENTAL,
  },
  VISION: {
    PREMIUMS: PREMIUMS,
  },
  LIFE: {
    INFO: LIFE_INFO,
  },
  STD_FULLY_INSURED: {
    INFO: STD_FULLY_INSURED_INFO,
  },
  STD_SELF_FUNDED: {
    INFO: STD_SELF_FUNDED_INFO,
  },
  LTD: {
    INFO: LTD_INFO,
  },
} as any;

export const PROPOSAL_MODELING_SECTION = 'MODELING';
export const PROPOSAL_OFFER_SELECT_SECTION = 'OFFER_SELECT';

export const NO_OFFERS_ER_ADMIN = "You don't have any Offers";

export const GUARANTEED = 'GUARANTEE';
export const CAP = 'CAP';
export const ALTERNATIVE = 'ALTERNATIVE';
export const RENEWAL = 'RENEWAL';

export const FIXED_ALERT_MESSAGE_IF_FINAL_APPROVED =
  'This offer is associated with a finalized proposal. To publish any changes, the proposal must be unmarked as final. You may still save this offer as a draft.';

export const DUPLICATE_PLANS_DETECTED =
  'There are duplicate plan names in the latest file upload. Please make sure all plans have unique names before importing the file.';

export const FORMATTING_ERROR_IN_TABLE =
  'There are formatting errors in certain plans below. Please fix by editing any of the plans with the highlighted fields.';

export const ALL_PLAN_MAPPUING_INFO =
  'Please make sure all plans have assigned plan mappings.';

export const OPS_ADMIN_PLAN_PARAMETER = `Review the information presented below from the initial
  processed file upload. You can review the `;

export const BROKER_ADMIN_EXTRACTION_SUCCESS = `Your carrier offer upload is complete. Please review the plan information presented below.`;

export const WARNING_IS_ELIGIBLE_MODAL_DES =
  'This offer exists in the proposals listed below. Changes to Effective Dates or Offer Status can potentially remove them from these proposals.';

export const WARNING_OFFERED_PLAN_UPDATE = `This plan offer exists in the proposals listed below. Any rate changes to the plan offer will automatically update the associated proposals. `;

export const PROPOSAL_CRUD_DONE_BY_KEY = {
  PROPOSAL_UPDATED: { value: 'PROPOSAL_UPDATED', label: '(via proposal)' },
  PROPOSAL_CREATED: { value: 'PROPOSAL_CREATED', label: '' },
  OFFERS_UPDATED: { value: 'OFFERS_UPDATED', label: '(via offers)' },
};
export const NON_MDV_NUMBER_ONLY_FIELDS = [
  'lifeAndAddMaximum',
  'guaranteedIssue',
  'volume',
  'lifeRate',
  'basicAddRate',
  'benefitLevel',
  'weeklyBenefitMax',
  'eliminationPeriod',
  'benefitDuration',
  'monthlyBenefitMax',
];

export const NON_MDV_REQUIRED_FIELDS = [
  'volume',
  'lifeRate',
  'basicAddRate',
  'totalEligibleEnrollments',
  'totalEligibleEmployees',
  'administrationFee',
  'annualEstimatedClaims',
];

export const LIFE = 'LIFE';
export const BASIC_ADD = 'BASIC_ADD';
export const BASIC_LIFE = 'BASIC_LIFE';
export const BASIC_LIFE_AND_ADD = 'BASIC_LIFE_AND_ADD';
export const STD = 'STD';
export const LTD = 'LTD';
export const BASIC_ADD_RATE = 'basicAddRate';
export const BASIC_LIFE_RATE = 'lifeRate';
export const VOLUNTARY_BENEFIT = 'VOLUNTARY_BENEFIT';

export const LIFE_BENEFIT_TYPES = [
  { label: 'Basic Life', value: 'BASIC_LIFE' },
  { label: 'Basic AD&D', value: 'BASIC_ADD' },
  { label: 'Basic Life w/AD&D', value: 'BASIC_LIFE_AND_ADD' },
];

export const VOL_LIFE_BENEFIT_TYPES = [
  { label: 'Voluntary Life', value: 'VOLUNTARY_LIFE' },
  { label: 'Voluntary AD&D', value: 'VOLUNTARY_ADD' },
  { label: 'Voluntary Life w/AD&D', value: 'VOLUNTARY_LIFE_ADD' },
  { label: 'Voluntary STD', value: 'VOLUNTARY_STD' },
  { label: 'Voluntary LTD', value: 'VOLUNTARY_LTD' },
];

export const VOLUNTARY_BENEFIT_TYPES = [
  { label: 'Critical Illness', value: 'CRITICAL_ILLNESS' },
  { label: 'Accident', value: 'ACCIDENT' },
  { label: 'Hospital', value: 'HOSPITAL' },
  { label: 'Custom Plan', value: 'CUSTOM_VOLUNTARY_BENEFIT' },
  ...VOL_LIFE_BENEFIT_TYPES,
];

export const DETAILED = 'Detailed';
export const DETAILED_EXCEL = 'Detailed Excel';

export const SUMMARY = 'Summary';
export const SUMMARY_EXCEL = 'Summary Excel';

export const EXPORT_PROPOSAL_DROPDOWN_OPTIONS = [
  {
    value: 'SUMMARY',
    label: 'Summary Excel',
  },
];

export const EXPORT_PROPOSAL_BENEFIT_TYPE_DROPDOWN_OPTIONS = [
  {
    value: 'MEDICAL',
    label: 'Medical',
  },
  {
    value: 'DENTAL',
    label: 'Dental',
  },
  {
    value: 'VISION',
    label: 'Vision',
  },
  {
    value: 'LIFE',
    label: 'Life/AD&D ',
  },
  {
    value: 'LTD',
    label: 'Long Term Disability',
  },
  {
    value: 'STD',
    label: 'Short Term Disability',
  },
];

export const EXPORT_FILE_TYPES = {
  Summary: 'SUMMARY',
  Detail: 'DETAIL',
};

export const EXPORT_OPTIONS_TAB = 'Export Options';
export const STYLING_TAB = 'Styling';

export const PREMIUM_NAMES = {
  'EE Only': 'Total Monthly Rate Tier 1*',
  'EE + Spouse': 'Total Monthly Rate Tier 2*',
  'EE + Child': 'Total Monthly Rate Tier 3*',
  'EE + Family': 'Total Monthly Rate Tier 4*',
} as any;

export const ORTHODONTIA_MAX = 'Orthodontia Max';
export const QUOTE_PROCESSING_COMPLETED = 'COMPLETED';
export const QUOTE_PROCESSING_PENDING = 'PROCESSING';
export const CARRIER_QUOTE = 'CARRIER_QUOTE';
export const OON_REIMBURSEMENT = 'OON Reimbursement';

export const FILE_SIZE_EXCEEDED = 'file.size.exceeded';

export const FROM_EXISTING_PLANS = 'FROM_EXISTING_PLANS';
export const FILE_IMPORT = 'FILE_IMPORT';
export const MANUAL_PLANS = 'MANUAL_PLANS';
export const NONE = 'NONE';

export const NOTIFICATION_PUBLISH_TOOLTIP = `Notifications will be sent to the broker team and the employer admins when this proposal is published.`;

export const MARK_AS_FINAL_PROPOSAL_MODAL_BROKER_BODY_TEXT =
  'Marking this proposal as Final Approved will communicate to the Employer that you’ve approved this proposal. Here you can send the Employer all the details about the Final Approved proposal.';
export const MARK_AS_FINAL_APPROVED_MODAL_BROKER_BODY_TEXT_SECOND_PARA =
  'Once the Employer has been notified, you will have the options of sending Sold Confirmations to Carriers, as well as initiate the process of adding the finalized proposal plans to the upcoming Plan Year.';
export const MARK_AS_FINAL_PROPOSAL_MODAL_EMPLOYER_BODY_TEXT =
  "Marking this proposal as 'Final Approved' will enable your broker to initiate the process of sending sold confirmations to carriers, and adding the new proposal plans to a new plan year.";
export const MARK_AS_FINAL_PROPOSAL_MODAL_CONFIRMATION_TEXT =
  'Mark as Final Approved';

export const EXPORT_OFFERS_AND_PROPOSALS = 'Export Offers and Proposals';

export const N_TIER_PRIORITY_ORDER = [
  'EE only',
  'EE + 1',
  'EE + 2',
  'EE + 3',
  'EE + 4',
  'EE + 5',
  'EE + 6',
];

export const CLOSE_TYPE_TEXT = {
  [RenewalType.OFFER]:
    'Are you sure you want to close this offer? You can publish, save as a draft, or close without saving. Drafts will show up in the Drafts tab.',
  [RenewalType.PROPOSAL]:
    'Are you sure you want to close this proposal? You can publish, save as a draft, or close without saving. Drafts will show up in the Drafts tab.',
};

export const CLOSE_ERROR_TEXT =
  'Are you sure you want to close this offer? If you leave now, you will lose any unsaved data. Do you wish to continue?';

export const N_TIER_PLAN_PROPERTIES = [
  'employerEE1Contribution',
  'employerEE2Contribution',
  'employerEE3Contribution',
  'employerEE4Contribution',
  'employerEE5Contribution',
  'employerEE6Contribution',
];

export const STANDARD_TIER = 'STANDARD_TIER';
export const FOUR_TIER = 'FOUR_TIER';
export const N_TIER = 'N_TIER';
export const MEDICAL_PLAN_TYPES = ['HMO', 'PPO', 'EPO'];

export const PROPOSAL_EXISTS_INFO_TEXT =
  'You have an active proposal linked to this offer. Any change in the enrollment will affect the calculations in the proposal.';

export const PREMIUM_TYPES = [
  { value: 'STANDARD_TIER', label: 'Standard (EE, ES, EC, EF)' },
  { value: 'EE1', label: '2-tier' },
  { value: 'EE2', label: '3-tier' },
  { value: 'EE3', label: '4-tier' },
  { value: 'EE4', label: '5-tier' },
  { value: 'EE5', label: '6-tier' },
  { value: 'EE6', label: '7-tier' },
];

export const SOLD_CONFIRMATION_ACTIONS_OPTIONS = {
  SEND_CARRIER_SOLD_CONFIRMATION: {
    value: 'SEND_CARRIER_SOLD_CONFIRMATION',
    label: 'Send Sold Confirmation to Carriers',
  },
  VIEW_CARRIER_SOLD_CONFIRMATION: {
    value: 'VIEW_CARRIER_SOLD_CONFIRMATION',
    label: 'View Sold Confirmations',
  },
  SEND_EMPLOYER_SOLD_CONFIRMATION: {
    value: 'SEND_EMPLOYER_SOLD_CONFIRMATION',
    label: 'Send Confirmation to Employer',
  },
  SEND_EMPLOYER_SOLD_CONFIRMATION_DISABLED: {
    value: 'SEND_EMPLOYER_SOLD_CONFIRMATION',
    label: 'Send Confirmation to Employer',
    disable: true,
    hover: true,
  },
};

export const DENTAL_OFFER = 'DENTAL';
export const RENEWAL_OFFER = 'RENEWAL OFFER';
export const OFFERED_PLAN = 'OFFERED PLAN';

export const CARRIER_ALREADY_EXISTS_CODE =
  'This carrier already exists. If it is not showing for this benefit type, go back to the Carrier library and edit the existing carrier.';

export const FUNDING_TYPES = {
  FULLY_INSURED: { label: 'Fully-Insured', value: 'FULLY_INSURED' },
  SELF_FUNDED: { label: 'Self-Funded', value: 'SELF_FUNDED' },
  LEVEL_FUNDED: { label: 'Level-Funded', value: 'LEVEL_FUNDED' },
};

export const STD_SELF_FUNDED = 'STD_SELF_FUNDED';
export const STD_FULLY_INSURED = 'STD_FULLY_INSURED';
export const DENTAL_SELF_FUNDED = 'DENTAL_SELF_FUNDED';
export const VISION_SELF_FUNDED = 'VISION_SELF_FUNDED';
export const MEDICAL_SELF_FUNDED = 'MEDICAL_SELF_FUNDED';

export const CARRIER_DOCUMENTS_FILE_SIZE_EXCEED =
  'The submitted file exceeds the file size limit of 100 MB. Please confirm that you are uploading the correct file or reduce the file’s size.';

export const QUOTE_UPLOADER_BANNER_TEXT_WITH_PLAN_MAPPING = `This file is in queue to be processed and can take up to 24 hours to complete.Please provide identifiers for the plans you wish to include in this offer, along with the corresponding plan in the current year. Once you've entered this information, click on "Next" to save the offer as a draft and you will be notified when processing completes.`;

export const QUOTE_UPLOADER_BANNER_TEXT_WITHOUT_PLAN_MAPPING = `This file is in queue to be processed and can take up to 24 hours to complete. Please click on "Next" to save the offer as a draft and you will be notified when processing completes.`;

interface OfferPlanValidationFields {
  [key: string]: string[];
}

export const NO_VALIDATION_FIELDS: OfferPlanValidationFields = {
  LIFE: ['Max Benefit', 'Guaranteed Issue'],
  STD: [
    'STD Benefit',
    'Max Weekly Benefit',
    'Waiting Period',
    'Max Benefit Duration',
  ],
  LTD: [
    'LTD Benefit',
    'Max Monthly Benefit',
    'Waiting Period',
    'Max Benefit Duration',
  ],
};

export const ADD_PLAN_OPTIONS = {
  // Hide option until release the feature
  MANUAL_PLANS: {
    key: 'MANUAL_PLANS',
    label: 'Add plans manually',
  },
  EXISTING_PLANS: {
    key: 'FROM_EXISTING_PLANS',
    label: 'Import from existing plans',
  },
  CARRIER_QUOTE: {
    key: 'CARRIER_QUOTE',
    label: 'Import insurance carrier quotes',
  },
};

export const QUOTES_MANUAL_PROCESSING_WARNING =
  "This file requires additional processing and may take up to 24 hours to finish. We recommend that you choose to wait for additional processing for data accuracy. You'll be notified when the processing is complete. Please click on “Next” to continue.";

export const QUOTES_MANUAL_ALTERNATE_WARNING = ` Alternatively, you can continue without uploading the document and `;

export const QUOTES_IDENTIFIER_ERROR = `The plan names highlighted above are not found in the uploaded document. Please verify the uploaded quote or the plan names.`;

export const QUOTES_MANUAL_GENERAL_ERROR = `Unexpected error occurred. `;

export const QUOTE_UPLOAD_OPS_REVIEW_MODAL_TITLE =
  'Finalize AI Offer Upload Review';

export const QUOTE_UPLOAD_OPS_REVIEW_MODAL_WARNING_MESSAGE =
  'Finalizing this document review will send a notification to the broker admin that this file is ready to review.';

export const OFFER_NAME_DUPLICATED_IN_PROPOSAL_ERROR_MESSAGE =
  'Only one of the draft or published versions of the same offer can be selected.';
export const OFFER_PUBLISH_OVERWRITTEN_WITH_PROPOSAL =
  'A published version of this offer exists in the proposals listed below. Any rate changes to the offer will automatically update the associated proposals.';

export const OFFER_PUBLISH_NOT_OVERWRITTEN_WITH_PROPOSAL =
  'This offer exists in the proposals listed below. Any rate changes to the offer will automatically update the associated proposals.';

export const PLAN_IDENTIFIER_TOOLTIP_CONTENT = `Please enter the full, unique name of each plan you want to upload into the system. Plan names that are misspelled or abbreviated may result in errors.`;
export const DRAFT_OFFER_EXIST_ON_PROPOSAL_WARNING_MESSAGE = `This proposal includes draft offers that will be published upon confirmation. Are you ready to proceed?`;
export const DRAFT_OFFER_EXIST_ON_PROPOSAL_WITH_SAME_NAME_WARNING_MESSAGE = `Please note that if published versions of these offers already exist, they will be overwritten.`;
export const DRAFT_OFFER_EXIST_ON_PROPOSAL_BUTTON_TITLE = `Yes - Publish Proposal & Offers`;

export const QUOTES_NEXT_PROCESSING = `You may click on ‘Next’ below to save progress and you’ll be notified when processing is complete.`;

export const getQuoteReviewMessage = (
  status: QuoteProcessStatus,
  reviewType: ReviewType,
  isSelfReviewProcessing: boolean,
  isPlanNamesValid: boolean
): string | null => {
  switch (status) {
    case QuoteProcessStatus.VALIDATING:
      return 'Validating plan names...';
    case QuoteProcessStatus.VALIDATED:
      return reviewType === ReviewType.MANUAL && isPlanNamesValid
        ? QUOTES_NEXT_PROCESSING
        : isSelfReviewProcessing
        ? QUOTES_NEXT_PROCESSING
        : null;
    case QuoteProcessStatus.PROCESSING:
      return QUOTES_NEXT_PROCESSING;
    case QuoteProcessStatus.UPLOADING:
    case QuoteProcessStatus.UPLOADED:
    case QuoteProcessStatus.SUCCESS:
    case QuoteProcessStatus.FAILED:
    case QuoteProcessStatus.REVIEWED:
    case QuoteProcessStatus.SAVED:
    case QuoteProcessStatus.MANUAL:
    case QuoteProcessStatus.REJECTED:
    default:
      return null;
  }
};

export const PROCESSING_STARTED_TEXT = 'Processing started.';

export const LLM_EXTRACTION_POPOVER_TEXT_ENABLED = `Click the AI icon to locate the source of the 
extracted data within the document.`;

export const LLM_EXTRACTION_POPOVER_TEXT_DISABLED = `This data was extracted with AI`;

export const LLM_EXTRACTION_POPOVER_TEXT_INFO = `This value has been flagged as lower confidence by the AI. You may modify the value accordingly.`;

export const LLM_WARNING_TITLE_TEXT = `Flagged items`;

type BasicLifeAdditionalServices = {
  [key: string]: { value: string; label: string };
};

type STDServices = {
  [key: string]: { value: string; label: string };
};

type LTDServices = {
  [key: string]: { value: string; label: string };
};

export const BASIC_LIFE_ADDITIONAL_SERVICES: BasicLifeAdditionalServices = {
  ACCELERATED_LIFE_BENEFIT: {
    value: 'ACCELERATED_LIFE_BENEFIT',
    label: 'Accelerated Life Benefit',
  },
  PORTABILITY: { value: 'PORTABILITY', label: 'Portability' },
  WAIVER_OF_PREMIUM: { value: 'WAIVER_OF_PREMIUM', label: 'Waiver of Premium' },
  DEFINITION_OF_EARNINGS: {
    value: 'DEFINITION_OF_EARNINGS',
    label: 'Definition of Earnings',
  },
};

export const STD_SERVICES: STDServices = {
  DEFINITION_OF_EARNINGS: {
    value: 'DEFINITION_OF_EARNINGS',
    label: 'Definition of Earnings',
  },
  WAITING_PERIOD_INJURY: {
    value: 'WAITING_PERIOD_INJURY',
    label: 'Waiting Period Injury',
  },
  WAITING_PERIOD_ILLNESS: {
    value: 'WAITING_PERIOD_ILLNESS',
    label: 'Waiting Period Illness',
  },
  SOCIAL_SECURITY_INTEGRATION: {
    value: 'SOCIAL_SECURITY_INTEGRATION',
    label: 'Social Security Integration',
  },
  W2_PREPARATION: {
    value: 'W2_PREPARATION',
    label: 'W-2 Preparation',
  },
  FICA_MATCH: {
    value: 'FICA_MATCH',
    label: 'FICA Match',
  },
  PORTABILITY: {
    value: 'PORTABILITY',
    label: 'Portability',
  },
  RECURRENT_DISABILITY_PROVISION: {
    value: 'RECURRENT_DISABILITY_PROVISION',
    label: 'Recurrent Disability Provision',
  },
  CONTINUITY_OF_COVERAGE: {
    value: 'CONTINUITY_OF_COVERAGE',
    label: 'Continuity of Coverage',
  },
  CONVERTIBILITY: {
    value: 'CONVERTIBILITY',
    label: 'Convertibility',
  },
};

export const LTD_SERVICES: LTDServices = {
  MIN_MONTHLY_BENEFIT: {
    value: 'MIN_MONTHLY_BENEFIT',
    label: 'Min Monthly Benefit',
  },
  DEFINITION_OF_EARNINGS: {
    value: 'DEFINITION_OF_EARNINGS',
    label: 'Definition of Earnings',
  },
  PRE_EXISTING_CONDITIONS: {
    value: 'PRE_EXISTING_CONDITIONS',
    label: 'Pre-Existing Conditions',
  },
  SOCIAL_SECURITY_INTEGRATION: {
    value: 'SOCIAL_SECURITY_INTEGRATION',
    label: 'Social Security Integration',
  },
  STATE_INTEGRATION: {
    value: 'STATE_INTEGRATION',
    label: 'State Integration',
  },
  FICA_MATCH: {
    value: 'FICA_MATCH',
    label: 'FICA Match',
  },
  REHABILITATION_BENEFIT: {
    value: 'REHABILITATION_BENEFIT',
    label: 'Rehabilitation Benefit',
  },
  WORKSITE_MODIFICATION_BENEFIT: {
    value: 'WORKSITE_MODIFICATION_BENEFIT',
    label: 'Worksite Modification Benefit',
  },
  RECURRENT_DISABILITY_PROVISION: {
    value: 'RECURRENT_DISABILITY_PROVISION',
    label: 'Recurrent Disability Provision',
  },
  RETURN_TO_WORK: {
    value: 'RETURN_TO_WORK',
    label: 'Return to Work',
  },
  WAIVER_OF_PREMIUM: {
    value: 'WAIVER_OF_PREMIUM',
    label: 'Waiver of Premium',
  },
  CONTINUITY_OF_COVERAGE: {
    value: 'CONTINUITY_OF_COVERAGE',
    label: 'Continuity of Coverage',
  },
  EARNINGS_TEST: {
    value: 'EARNINGS_TEST',
    label: 'Earnings Test',
  },
  DISABILITY_LIMITATIONS_MENTAL_HEALTH: {
    value: 'DISABILITY_LIMITATIONS_MENTAL_HEALTH',
    label: 'Disability Limitations-Mental Health',
  },
  DISABILITY_LIMITATIONS_SUBSTANCE_ABUSE: {
    value: 'DISABILITY_LIMITATIONS_SUBSTANCE_ABUSE',
    label: 'Disability Limitations-Substance Abuse',
  },
  DISABILITY_LIMITATIONS_SELF_REPORTED: {
    value: 'DISABILITY_LIMITATIONS_SELF_REPORTED',
    label: 'Disability Limitations-Self-Reported',
  },
  EAP_OFFERED: {
    value: 'EAP_OFFERED',
    label: 'EAP Offered',
  },
};

export const CREDIT_COST_TYPES = {
  COST: 'COST',
  CREDIT: 'CREDIT',
};

export const CONTACT_LENSES_FREQUENCY = 'Contact Lenses Frequency';

export const TOOLTIP_IND_WITH_FAM_DED =
  'The portion of the family deductible each member must meet individually';

export const TOOLTIP_IND_WITH_FAM_OOP =
  "The maximum each member pays as part of the family's total out-of-pocket limit";

export const LLM_WARNING_TITLE_TEXT_OPS = `Please check this value to ensure accuracy`;

export const LLM_WARNING_TITLE_TEXT_QUOTE_READING = `Flagged items`;

export const BROKER_ADMIN_QUOTE_THRESHOLD_MESSAGE = `Our AI has processed your file and generated the output below. We recommend you carefully review all information for accuracy. If the information looks correct, proceed to the next step to continue with your task. If you're unsure or notice discrepancies, you may request a manual review by our team. This may take up to 1 business day, and we'll notify you once it's complete.`;

const QUOTE_SAVE_PLANS_TITLE = 'Save Progress & Close';

const QUOTE_UPLOAD_OPS_REVIEW_MODAL_SAVE_MESSAGE =
  'You can find this saved progress for this document upload in the Offer Uploads link on the top-right of the offers page.';

const QUOTE_APPROVE_PLANS_TITLE = 'Approve Plans';

const QUOTE_UPLOAD_OPS_REVIEW_MODAL_APPROVE_MESSAGE =
  'Are you sure you want to approve these plans?';

export const OFFERED_PLAN_WARNING_WARNING_MESSAGE = 'Save Plan Parameters';

export const PLAN_WARNING_WARNING_MESSAGE = 'Save Plan';

export const UPDATE_PROPOSAL_TITLE_WARNING_NAME =
  'Update Plan Offer in Proposal';

export const PLAN_UPDATE_WARNING_MESSAGE =
  'By saving this plan, all previous plan parameter data will be overwritten, and you confirm that the updated data is accurate.';

export const OFFERED_PLAN_UPDATE_WARNING_MESSAGE =
  'By saving this plan offer, all previous plan parameter data will be overwritten, and you confirm that the updated data is accurate.';

export const OFFERED_PLAN_DOC_DESCRIPTION_MESSAGE = `Are you sure you want to save this plan offer?`;

export const PLAN_DOC_DESCRIPTION_MESSAGE = `Are you sure you want to save this plan`;

const QUOTE_UPLOAD_OPS_REVIEW_MODAL_APPROVE_WARNING_MESSAGE =
  'By approving these plans, you confirm that all data entered through our carrier quote processing tool is accurate.';

export const OFFERED_PLAN_PROCESSING_POP_UP = `Another plan offer is currently being 
updated. Please try again later.`;

export const OFFERED_PLAN_PROCESSING_WARNING = `This offer has an ongoing document processing. If you proceed to save this offer, the document processing will be canceled.`;

export const reviewModalContent = {
  [ActionStatus.SAVE]: {
    title: QUOTE_SAVE_PLANS_TITLE,
    description: QUOTE_UPLOAD_OPS_REVIEW_MODAL_SAVE_MESSAGE,
  },
  [ActionStatus.APPROVE]: {
    title: QUOTE_APPROVE_PLANS_TITLE,
    description: QUOTE_UPLOAD_OPS_REVIEW_MODAL_APPROVE_MESSAGE,
    warning: QUOTE_UPLOAD_OPS_REVIEW_MODAL_APPROVE_WARNING_MESSAGE,
  },
};

export const QUOTES_SELF_REVIEW_PROCESSING_WARNING =
  "This file requires additional processing that may take up to 1 business day to complete. We recommend opting for additional processing as this option ensures the highest data accuracy. You'll be notified when the processing is finished. ";

export const QUOTES_SELF_REVIEW_PROCESSING_WARNING_BOLD =
  'Please opt for additional processing below to continue.';

export const QUOTES_SELF_REVIEW_ALTERNATE_WARNING = ` Alternatively, you can proceed without uploading the carrier quote and `;

export const QUOTE_UPLOAD_FILE_WARNING_PROMPT =
  'Upload quote documents only. Our AI will extract relevant information from the quote.';

export const QUOTE_UPLOAD_FILE_PROMPT_TWO =
  'Please note: SBCs, Benefit Summaries, or other non-quote documents are not accepted here.';

export const MEDICAL_PLAN_UPLOAD_WARNING = {
  mainMessage:
    'Upload a Medical SBC or Plan Summary to allow our AI to auto-populate Deductibles, Services, and RX details. ',
  subMessage:
    'Please note: Quotes or Benefit Summaries for other coverage types are not accepted here.',
};

export const DENTAL_PLAN_UPLOAD_WARNING = {
  mainMessage:
    'Upload a Dental Benefit Summary document to allow our AI to auto-populate Deductibles and Services details.',
  subMessage:
    'Please note: Only Dental Benefit Summary documents are accepted here. Do not upload documents for other coverage types.',
};

export const VISION_PLAN_UPLOAD_WARNING = {
  mainMessage:
    'Upload a Vision Benefit Summary document to allow our AI to auto-populate Services details.',
  subMessage:
    'Please note: Only Vision Benefit Summary documents are accepted here. Do not upload documents for other coverage types.',
};

export const OTHER_PLAN_UPLOAD_WARNING = {
  mainMessage:
    'Upload a Benefit Summary document to allow our AI to auto-populate coverage details.',
  subMessage:
    'Please note: Only relevant Benefit Summary documents are accepted here. Do not upload documents for other coverage types.',
};

export const offeredPlanUpdate = 'Successfully updated offered plan.';
