import { isEmpty, size } from 'lodash';
import Markdown from 'markdown-to-jsx';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import ChatBubbleLayout from 'modules/assistant/layout/ChatBubbleLayout/ChatBubbleLayout';
import TypeWriter from 'modules/assistant/components/TextBubble/TypeWriter';
import { setResetAnimation } from 'modules/assistant/slices/conversationSlice';

import { NEW_CHAT_NAV_CONSTANT } from 'modules/assistant/constants/constants';

type TextBubbleProps = {
  id?: string;
  content: string;
  animate: boolean;
  layoutRef?: React.RefObject<any>;
  isPending?: boolean;
  isQuestion?: boolean;
};

const TextBubble = ({
  id,
  content,
  animate,
  layoutRef,
  isPending,
  isQuestion = false,
}: TextBubbleProps) => {
  const { messages, conversationId } = useAppSelector(
    (state) => state.assistant.current
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onFinish = (messageId: string) => {
    const currentUrl = location.pathname;

    if (isEmpty(messageId)) {
      return;
    }

    // Check if there's only one message and the current URL includes the NEW_CHAT_NAV_CONSTANT
    if (size(messages) === 1 && currentUrl.includes(NEW_CHAT_NAV_CONSTANT)) {
      // Replace NEW_CHAT_NAV_CONSTANT with conversationId in the URL
      const newUrl = currentUrl.replace(NEW_CHAT_NAV_CONSTANT, conversationId!);

      // We need to replace the URL after the animation finishes to avoid any bugs.
      // If we navigate before the animation completes, it will rerender the app,
      // causing the animation to be skipped.
      navigate(newUrl, { replace: true, state: { initialize: true } });
    }

    // Dispatch the fallback initialize action
    dispatch(setResetAnimation());
  };

  return (
    <ChatBubbleLayout
      isQuestion={isQuestion}
      content={content}
      isPending={isPending}
    >
      <TypeWriter
        animate={animate}
        onFinish={() => onFinish(id ?? '')}
        layoutRef={layoutRef}
      >
        {isQuestion ? content : <Markdown>{content}</Markdown>}
      </TypeWriter>
    </ChatBubbleLayout>
  );
};

export default TextBubble;
