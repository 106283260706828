import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Popover,
  Row,
  Select,
  Spin,
  Switch,
} from 'antd';
import {
  CheckOutlined,
  DownOutlined,
  QuestionCircleOutlined,
  UpOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';

import { useNavContext } from 'hooks/useNavContext';
import DocumentUploader from 'modules/billing/components/DocumentUploader/DocumentUploader';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import alert from 'assets/images/alert-info-error.svg';
import SelectOptions from 'components/SelectOptions/SelectOptions';
import {
  useBillingPreviewMutation,
  useBillingUploadFileMutation,
  usePublishUploadFileMutation,
} from 'modules/billing/slices/billingSlice';
import {
  billingStatus,
  monthsShortNumbers,
  PUBLISH,
  UPLOAD,
} from 'modules/billing/constants/constants';
import { baseApi } from 'util/apiUtil';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import BillingTableExpandable from 'modules/billing/components/BillingTableExpandable/BillingTableExpandable';
import { currencyFormatter } from 'util/commonUtil';
import styles from './billingUpload.module.less';

const { Option } = Select;

type BillingUploadProps = {
  isUploaderOpen: boolean;
  setIsUploaderOpen: Function;
  openBilling: Function;
  isDashboardUpload?: boolean;
  selectedDetailedFile?: File | null;
  seclectedSummaryFile?: File | null;
  isPreviewFile?: boolean;
  isMonthDisabled?: boolean;
  recordData?: any;
  setSelectedDetailFile?: Function;
  setSelectedSummaryFile?: Function;
  setIsPreviewFile?: Function;
};

const BillingUpload = forwardRef((props: BillingUploadProps, ref) => {
  const {
    isUploaderOpen,
    setIsUploaderOpen,
    openBilling,
    isDashboardUpload = false,
    seclectedSummaryFile = null,
    selectedDetailedFile = null,
    isPreviewFile = false,
    isMonthDisabled = false,
    recordData = null,
    setSelectedDetailFile,
    setSelectedSummaryFile,
    setIsPreviewFile,
  } = props;
  const { employer } = useNavContext();
  const navigate = useNavigate();
  const { brokerId } = useParams();
  const { employerId } = useParams();
  const [
    billingUploadFile,
    {
      isLoading: fileUploadInProgress,
      isError: fileUploadFailed,
      isSuccess: fileUploadCompleted,
      isUninitialized: fileUploadNotInitialized,
      data,
      error: fileUploadError,
    },
  ] = useBillingUploadFileMutation({});

  const [billingPreview, { isLoading: billingPreviewInProgress }] =
    useBillingPreviewMutation({});

  const [
    publishUploadFile,
    {
      isLoading: filePublishInProgress,
      isError: filePublishFailed,
      isSuccess: filePublishCompleted,
    },
  ] = usePublishUploadFileMutation({});

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [activePanel, setActivePanel] = useState<string | string[]>(UPLOAD);
  const [summaryFile, setSummaryFile] = useState<File | null>(null);
  const [detailFile, setDetailFile] = useState<File | null>(null);
  const [summaryFileRemoved, setSummaryFileRemoved] = useState<boolean>(false);
  const [sendNotificationEnabled, setSendNotificationEnabled] =
    useState<boolean>(true);
  const [detailFileRemoved, setDetailFileRemoved] = useState<boolean>(false);
  const [fileUploadMonth, setFileUploadMonth] = useState<string>(
    dayjs().format('M YYYY')
  );
  const [errorCode, setErrorCode] = useState<any>(null);
  const [monthText, setMonthText] = useState<string>(dayjs().format('MMMM'));
  const [uploadError, setUploadError] = useState<boolean>(fileUploadFailed);
  const [uploadSuccess, setUploadSuccess] =
    useState<boolean>(fileUploadCompleted);

  useEffect(() => {
    setErrorCode(fileUploadError);
  }, [fileUploadError, errorCode]);

  useEffect(() => {
    setMonthText(
      dayjs()
        .month(+fileUploadMonth.split(' ')[0] - 1)
        .format('MMMM')
    );
  }, [fileUploadMonth]);

  useEffect(() => {
    if (fileUploadCompleted) setActivePanel(PUBLISH);
  }, [fileUploadCompleted]);

  useEffect(() => {
    setActivePanel(UPLOAD);
    setUploadError(fileUploadFailed);
    if (fileUploadFailed) {
      if (
        errorCode?.data?.code === 'invalid.headers.summary' ||
        errorCode?.data?.code === 'no.data.summary_and_detail' ||
        errorCode?.data?.code === 'invalid.data.summary'
      ) {
        setSummaryFileRemoved(true);
        setSummaryFile(null);
      }

      if (
        errorCode?.data?.code === 'invalid.headers.detail' ||
        errorCode?.data?.code === 'no.data.summary_and_detail' ||
        errorCode?.data?.code === 'invalid.data.detail'
      ) {
        setDetailFileRemoved(true);
        setDetailFile(null);
      }
    }
  }, [fileUploadFailed, errorCode]);

  useEffect(() => {
    setUploadSuccess(fileUploadCompleted);
  }, [fileUploadCompleted]);

  const reset = useCallback(() => {
    setUploadSuccess(false);
    setUploadError(false);
    setSummaryFileRemoved(true);
    setDetailFileRemoved(true);
    setFileUploadMonth(dayjs().format('M YYYY'));
    setOpenModal(false);
    setDetailFile(null);
    setSummaryFile(null);
    setIsUploaderOpen(false);
  }, [setIsUploaderOpen]);

  useEffect(() => {
    if (filePublishFailed || filePublishCompleted) {
      reset();
      if (isDashboardUpload) {
        navigate(`/brokers/${brokerId}/employers/${employerId}/billing`);
      }
    }
  }, [
    filePublishFailed,
    filePublishCompleted,
    reset,
    brokerId,
    employerId,
    navigate,
    isDashboardUpload,
  ]);

  useEffect(() => {
    if (fileUploadCompleted && !uploadError && !fileUploadNotInitialized) {
      setActivePanel(PUBLISH);
    }
  }, [fileUploadCompleted, uploadError, fileUploadNotInitialized]);

  useEffect(() => {
    if (isUploaderOpen) {
      if (isPreviewFile) {
        setActivePanel(PUBLISH);
      } else {
        setActivePanel(UPLOAD);
      }
    }
  }, [isUploaderOpen, setIsUploaderOpen, isPreviewFile]);

  useEffect(() => {
    if (!billingPreviewInProgress) {
      reset();
      setOpenModal(false);
      setIsUploaderOpen(false);
    }
  }, [billingPreviewInProgress, setIsUploaderOpen, reset]);

  const documentUploaderRef = useRef(null);

  useImperativeHandle(ref, () => ({}));

  const closeBillingUploadPopup = () => {
    setOpenModal(!openModal);
  };

  const { Panel } = Collapse;

  const downloadTemplateFile = (type: string) => {
    return `${baseApi}/v2/billing/templates/download?type=${type}`;
  };

  const onPreviewBillingFileClick = () => {
    const fileType = getBillingFileUploadType();
    if (fileType) {
      billingUploadFile({
        employerId: employer?.id ?? '',
        detailFile: detailFile,
        summaryFile: summaryFile,
        type: fileType,
        month: isMonthDisabled
          ? parseInt(monthsShortNumbers[recordData?.billingMonth ?? ''])
          : parseInt(fileUploadMonth.split(' ')[0]),
        year: isMonthDisabled
          ? parseInt(recordData?.year ?? '')
          : parseInt(fileUploadMonth.split(' ')[1]),
        billingId: recordData?.id ?? '',
        dummySummaryFile: seclectedSummaryFile !== null,
        dummyDetailFile: selectedDetailedFile !== null,
      });
    }
  };

  useEffect(() => {
    if (isPreviewFile) onPreviewBillingFileClick();
    // eslint-disable-next-line
  }, [seclectedSummaryFile, selectedDetailedFile]);

  const getFileUploadYears = () => {
    const end = dayjs().add(2, 'months');
    const monthOptions = [];
    for (let i = 0; i < 14; i++) {
      monthOptions.push({
        label: end.subtract(i, 'months').format('MMMM YYYY'),
        value: end.subtract(i, 'months').format('M YYYY'),
      });
    }
    return monthOptions;
  };

  const getBillingFileUploadType = () => {
    if (
      (summaryFile?.name || seclectedSummaryFile !== null) &&
      (detailFile?.name || selectedDetailedFile !== null)
    ) {
      return 'SUMMARY_AND_DETAIL';
    } else if (summaryFile?.name || seclectedSummaryFile !== null) {
      return 'SUMMARY';
    } else if (detailFile?.name || selectedDetailedFile !== null) {
      return 'DETAIL';
    } else {
      return '';
    }
  };

  const billingPublishTooltipContent = () => (
    <div>
      Notifications will be sent to the <br />
      broker team and the employer admins <br />
      when this file is published.
    </div>
  );

  const getBillingUploader = () => {
    const monthsOptions = getFileUploadYears();
    return (
      <div className={styles.billingUpload}>
        <Collapse
          bordered={false}
          activeKey={activePanel}
          onChange={(key) => {
            setActivePanel(key);
          }}
          accordion
        >
          <Panel
            header={
              <div className={styles.collapseSectionHeader}>
                {uploadSuccess && (
                  <CheckOutlined className={styles.billingUploadSuccessIcon} />
                )}
                <span className={styles.collapseTitle}>
                  Step 1: Select Billing File
                </span>
              </div>
            }
            key={UPLOAD}
            showArrow={false}
            extra={activePanel === '1' ? <UpOutlined /> : <DownOutlined />}
            className={styles.billingUploadCollapse}
            collapsible={undefined}
          >
            <div className={styles.billingUploadWrapper}>
              <div className={styles.billingUploadContent}>
                <div className={styles.summaryFileWrapper}>
                  <span className={styles.billingFileText}>Billing Month</span>
                  <div>
                    <SelectOptions
                      className={styles.selectOptions}
                      value={
                        isMonthDisabled
                          ? dayjs(
                              `01/${recordData?.billingMonth}/${recordData?.year}`
                            ).format('M YYYY')
                          : fileUploadMonth
                      }
                      onChange={setFileUploadMonth}
                      disabled={fileUploadInProgress || isMonthDisabled}
                    >
                      {monthsOptions.map((option: any, key: any) => (
                        <Option
                          value={option.value}
                          key={key}
                          label={option.label}
                          defaultValue={dayjs().format('MMMM YYYY')}
                        >
                          {option.label}
                        </Option>
                      ))}
                    </SelectOptions>
                  </div>
                </div>
                <div className={styles.summaryFileWrapper}>
                  <span className={styles.billingFileText}>
                    Select Summary File
                  </span>
                  <Popover
                    content="You can populate and upload the template summary file below to import billing data."
                    placement="right"
                    overlayClassName="popoverInner"
                  >
                    <QuestionCircleOutlined className={styles.popoverIcon} />
                  </Popover>
                  <Row className={styles.billingUploadButtonContent}>
                    <Col span={6}>
                      <DocumentUploader
                        ref={documentUploaderRef}
                        onChange={(file: File) => {
                          setSummaryFileRemoved(false);
                          setSummaryFile(file);
                          setUploadError(false);
                          setSelectedSummaryFile &&
                            setSelectedSummaryFile(null);
                          setIsPreviewFile && setIsPreviewFile(false);
                          setUploadSuccess(false);
                        }}
                        onRemove={() => {
                          setSummaryFileRemoved(true);
                          setSelectedSummaryFile &&
                            setSelectedSummaryFile(null);
                          setIsPreviewFile && setIsPreviewFile(false);
                          setUploadSuccess(false);
                        }}
                        isRemoved={summaryFileRemoved}
                        allowedFileTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        maxFileSizeMB={100}
                        dummyFile={seclectedSummaryFile}
                        defaultFile={null}
                        disabled={fileUploadInProgress}
                        hideNoFileMessage={true}
                        onFileNameClick={(file: File) => {}}
                        onValidateFails={() => {}}
                        uploadError={''}
                      />
                    </Col>

                    {uploadError &&
                      ((errorCode?.data?.code ?? null) ===
                        'invalid.headers.summary' ||
                        errorCode?.data?.code ===
                          'no.data.summary_and_detail' ||
                        errorCode?.data?.code === 'invalid.data.summary') && (
                        <Col className={styles.errorFileNotification} span={18}>
                          <Row className={styles.errorFileRow}>
                            <Col span={2}>
                              <img
                                className={styles.errorFileIcon}
                                src={alert}
                              ></img>
                            </Col>
                            <Col className={styles.errorFileText} span={18}>
                              The submitted file contains errors. Please fix any
                              errors and try uploading again.
                            </Col>
                          </Row>
                        </Col>
                      )}
                  </Row>
                  <Row>
                    <Col className={styles.templateFile}>
                      <LinkButton href={downloadTemplateFile('SAAS_SUMMARY')}>
                        Template File
                      </LinkButton>
                    </Col>
                  </Row>
                </div>
                <div className={styles.detailFileWrapper}>
                  <span className={styles.billingFileText}>
                    Select Detail File
                  </span>
                  <Popover
                    content="You can populate and upload the template detail file below to import billing data."
                    placement="right"
                    overlayClassName="popoverInner"
                  >
                    <QuestionCircleOutlined className={styles.popoverIcon} />
                  </Popover>
                  <Row className={styles.billingUploadButtonContent}>
                    <Col span={6}>
                      <DocumentUploader
                        ref={documentUploaderRef}
                        disabled={fileUploadInProgress}
                        onChange={(file: File) => {
                          setDetailFileRemoved(false);
                          setDetailFile(file);
                          setUploadError(false);
                          setSelectedDetailFile && setSelectedDetailFile(null);
                          setIsPreviewFile && setIsPreviewFile(false);
                          setUploadSuccess(false);
                        }}
                        onRemove={() => {
                          setDetailFileRemoved(true);
                          setSelectedDetailFile && setSelectedDetailFile(null);
                          setIsPreviewFile && setIsPreviewFile(false);
                          setUploadSuccess(false);
                        }}
                        dummyFile={selectedDetailedFile}
                        isRemoved={detailFileRemoved}
                        allowedFileTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        maxFileSizeMB={100}
                        defaultFile={null}
                        hideNoFileMessage={true}
                        onFileNameClick={(file: File) => {}}
                        onValidateFails={() => {}}
                        uploadError={''}
                      />
                    </Col>

                    {uploadError &&
                    ((errorCode?.data?.code ?? null) ===
                      'invalid.headers.detail' ||
                      errorCode?.data?.code === 'no.data.summary_and_detail' ||
                      errorCode?.data?.code === 'invalid.data.detail') ? (
                      <Col className={styles.errorFileNotification} span={18}>
                        <Row className={styles.errorFileRow}>
                          <Col span={2}>
                            <img
                              className={styles.errorFileIcon}
                              src={alert}
                            ></img>
                          </Col>
                          <Col className={styles.errorFileText} span={18}>
                            The submitted file contains errors. Please fix any
                            errors and try uploading again.
                          </Col>
                        </Row>
                      </Col>
                    ) : (
                      ''
                    )}
                  </Row>
                  <Row>
                    <Col className={styles.templateFile}>
                      <LinkButton href={downloadTemplateFile('SAAS_DETAIL')}>
                        Template File
                      </LinkButton>
                    </Col>
                  </Row>
                </div>
                <Button
                  loading={fileUploadInProgress}
                  onClick={() => {
                    onPreviewBillingFileClick();
                  }}
                  className={styles.previewBillingButton}
                  disabled={
                    (!Boolean(summaryFile?.name) &&
                      !Boolean(detailFile?.name)) ||
                    uploadError ||
                    isPreviewFile
                  }
                >
                  Import & Preview Billing File
                </Button>
              </div>
            </div>
          </Panel>
          <Panel
            header={
              <div className={styles.collapseSectionHeader}>
                <span
                  className={`${styles.collapseTitle} ${
                    !uploadSuccess ? styles.collapseTitleDisabled : ''
                  }`}
                >
                  Step 2 : Preview Billing File
                </span>
              </div>
            }
            key={PUBLISH}
            showArrow={false}
            extra={activePanel === PUBLISH ? <UpOutlined /> : <DownOutlined />}
            className={styles.billingPreviewCollapse}
            collapsible={undefined}
            disabled={!uploadSuccess && !isPreviewFile}
          >
            {fileUploadInProgress ? (
              <Spin />
            ) : (
              <div className={styles.previewBillingWrapper}>
                <div className={styles.monthInvoice}>
                  {isMonthDisabled
                    ? `${dayjs(
                        `01/${recordData?.billingMonth}/${recordData?.year}`
                      ).format('MMMM YYYY')} Invoice`
                    : `${monthText} ${fileUploadMonth.split(' ')[1]} Invoice`}
                </div>
                {data !== undefined
                  ? data.carrierSummaries.map((item: any, index: any) => {
                      return (
                        <BillingTableExpandable
                          key={index}
                          benifitSummary={item ?? {}}
                          isModel={true}
                        />
                      );
                    })
                  : ''}

                <div>
                  <hr className={styles.seperator} />
                  <div className={styles.totalDueText}>Total Due</div>
                  <div className={styles.totalDueContent}>
                    {(data?.monthlyTotal ?? 0) !== 0
                      ? data.monthlyTotal.toString().indexOf('-') !== -1
                        ? '(' + currencyFormatter(data?.monthlyTotal ?? 0) + ')'
                        : currencyFormatter(data?.monthlyTotal ?? 0)
                      : '-'}
                  </div>
                </div>
                <div className={styles.sendNotificationWrapper}>
                  <Row>
                    <div className={styles.sendNotificationText}>
                      Send Notification after Publish &nbsp;
                      <Popover
                        content={billingPublishTooltipContent()}
                        placement="top"
                        overlayClassName="popoverInner billingPublishTooltip"
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </div>
                    <Switch
                      className={styles.sendNotificationSwitch}
                      checked={sendNotificationEnabled}
                      onChange={(checked) => {
                        setSendNotificationEnabled(checked);
                      }}
                    />
                  </Row>
                </div>
                <PageActionButton
                  type="primary"
                  onClick={() => {
                    publishUploadFile({
                      employerId: employer?.id ?? '',
                      billingId: data?.billingId ?? '',
                      isNotificationEnabled: sendNotificationEnabled,
                    });
                  }}
                  className={styles.publishBillingButton}
                  loading={filePublishInProgress}
                  disabled={
                    !fileUploadCompleted ||
                    billingPreviewInProgress ||
                    (isPreviewFile &&
                      (recordData?.status ?? null) === billingStatus.PUBLISHED)
                  }
                >
                  {(recordData?.status ?? null) === billingStatus.PUBLISHED
                    ? 'Re-Publish Billing File'
                    : 'Publish Billing File'}
                </PageActionButton>
                <div className={styles.saveAsDraft}>
                  <Button
                    loading={fileUploadInProgress}
                    className={styles.saveAsDraftButton}
                    onClick={() => {
                      billingPreview({
                        employeeId: data?.employerId ?? '',
                        billingId: data?.billingId ?? '',
                      });
                      openBilling(true);
                    }}
                    disabled={isPreviewFile}
                  >
                    Save as Draft
                  </Button>
                </div>
                <div className={styles.cancelBillingButton}>
                  <LinkButton
                    onClick={() => {
                      reset();
                      setOpenModal(false);
                      setIsUploaderOpen(false);
                      openBilling(true);
                    }}
                    disabled={billingPreviewInProgress}
                  >
                    Cancel
                  </LinkButton>
                </div>
              </div>
            )}
          </Panel>
        </Collapse>
      </div>
    );
  };

  return (
    <>
      <FullScreenModal
        visible={isUploaderOpen}
        onCancel={() => {
          if (
            fileUploadMonth === dayjs().format('M YYYY') &&
            summaryFile === null &&
            detailFile === null
          ) {
            setIsUploaderOpen(false);
            openBilling(true);
          } else {
            openBilling(true);
            closeBillingUploadPopup();
          }
        }}
        footer={false}
        title="Import Billing File"
      >
        <div className={styles.billingUploadBody}>
          <div className={styles.employerName}>{employer?.name}</div>
          {getBillingUploader()}
        </div>
      </FullScreenModal>
      <ConfirmationDialog
        confirmText="Yes - close and do not save"
        onConfirm={() => {
          reset();
          setOpenModal(false);
          setIsUploaderOpen(false);
          openBilling(true);
        }}
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        closeModal={() => setOpenModal(false)}
        title="Are you sure you want to close?"
        cancelText="Cancel"
      >
        <p className={styles.claimsConfirmation}>
          <div>
            If you leave now, you will lose any unsaved data. Do you wish to
            continue?
          </div>
        </p>
      </ConfirmationDialog>
    </>
  );
});

BillingUpload.displayName = 'BillingUpload';
export default BillingUpload;
