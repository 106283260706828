import { useState } from 'react';
import { Select, Row, Col, notification } from 'antd';
import momentTz from 'moment-timezone';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import SelectOptions from 'components/SelectOptions/SelectOptions';
import {
  DETAILED,
  DETAILED_EXCEL,
} from 'modules/renewals/constants/renewalsConstants';
import IconError from 'assets/images/alert-failure.svg';
import { useLazyGetDetailedOfferExportQuery } from 'modules/renewals/slices/renewalsSlice';
import { ReactComponent as DownloadIcon } from 'assets/images/icon-download-black.svg';
import { baseApi } from 'util/apiUtil';

import styles from './exportOffers.module.less';

const { Option } = Select;

type Props = {
  isModalVisible: boolean;
  handleClose: (flag: boolean) => void;
  upcomingPlanYearId: string;
  employerId: string | undefined;
};

const ExportOffers = (props: Props) => {
  const { isModalVisible, handleClose, upcomingPlanYearId, employerId } = props;

  const [exportType, setExportType] = useState<string>('');

  const [getDetailedOfferExport] = useLazyGetDetailedOfferExportQuery();

  const downloadDetailedExportFile = async () => {
    if (
      !(employerId === '' || employerId === undefined) &&
      upcomingPlanYearId !== ''
    ) {
      notification['info']({
        closeIcon: <></>,
        top: 40,
        message: 'Download Started',
        description:
          'Please wait while we export detailed excel. This process may take a few minutes',
        icon: <DownloadIcon className={styles.downloadIcon} />,
      });
      try {
        const zone = momentTz.tz.guess();
        await getDetailedOfferExport({ employerId, upcomingPlanYearId, zone });
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        const url = `${baseApi}/v1/renewals/exports/detailed-offers?employer-id=${employerId}&upcoming-planyear-id=${upcomingPlanYearId}&zone=${zone}`;
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);
        handleClose(!isModalVisible);
        setExportType('');
      } catch (error) {
        notification.open({
          message: 'Download Failed',
          description:
            'The requested download failed. Please check your connection and try again.',
          placement: 'topRight',
          duration: 4,
          className: styles.notification,
          closeIcon: <></>,
          icon: (
            <img className={styles.notificationIcon} src={IconError} alt="" />
          ),
        });
      }
    }
  };

  const processExport = () => {
    if (exportType === DETAILED) {
      downloadDetailedExportFile();
    }
  };

  return (
    <ConfirmationDialog
      title="Export Offers"
      isRecordApplicable={false}
      onConfirm={processExport}
      visible={isModalVisible}
      isCloseOnly={false}
      confirmText={'Export'}
      centered={true}
      width={850}
      confirmBtnFullWidth={true}
      cancelText={'Cancel'}
      isCancelLink={true}
      destroyOnClose={true}
      isCancelApplicable={true}
      isConfirmApplicable={true}
      closeModal={() => {
        setExportType('');
        handleClose(!isModalVisible);
      }}
      disableConfirmButton={exportType === ''}
    >
      <div className={styles.exportContainer}>
        <Row>
          <Col span={3}>
            <span className={styles.exportLabel}>Export Type: </span>
          </Col>
          <Col span={21}>
            <SelectOptions
              className={styles.selectOptions}
              customClass={styles.exportOffersSelectOptions}
              onChange={setExportType}
            >
              <Option value={DETAILED} key={DETAILED} label={DETAILED_EXCEL}>
                <span className={styles.optionText}>{DETAILED_EXCEL}</span>
              </Option>
            </SelectOptions>
          </Col>
        </Row>
      </div>
    </ConfirmationDialog>
  );
};

export default ExportOffers;
