import React from 'react';
import { Divider, Row } from 'antd';
import {
  EDIT_PLAN_TABLE,
  OFFER_CATEGORY,
} from 'modules/renewals/constants/renewalsConstants';
import CurrencyInputFields from 'modules/renewals/components/AddPlanDetailsModal/AddPlanForm/CurrencyInputFields/CurrencyInputFields';
import styles from 'modules/renewals/components/AddPlanDetailsModal/AddPlanForm/addPlanForm.module.less';
import { Plan } from 'modules/renewals/types/planTypes';

type Props = {
  selectedData: Plan | any;
  benefitType: string;
  updateObject: (
    property: string,
    value: string | any,
    isArray: boolean,
    findObj?: any
  ) => void;
  isQuotePlansApproved?: boolean;
};

const DeductiblesSection = (props: Props) => {
  const { selectedData, updateObject, benefitType, isQuotePlansApproved } =
    props;

  return (
    <>
      <div className={styles.subTitle}>
        {benefitType === OFFER_CATEGORY.MEDICAL
          ? 'Deductibles & OOP Max'
          : 'Deductibles & Maximums'}
      </div>
      <Divider className={styles.divider} />
      <div className={styles.innerWrapper}>
        <Row justify={'end'}>
          <div className={styles.inNetworkText}>In-Network</div>
          <div className={styles.outNetworkText}>Out-of-Network</div>
        </Row>
        {Object.keys(
          EDIT_PLAN_TABLE?.[benefitType?.toUpperCase()]?.DEDUCTIBLES ?? {}
        ).map((item, index: number) => {
          return (
            <CurrencyInputFields
              key={index}
              item={item}
              type="DEDUCTIBLES"
              benefitType={benefitType}
              updateObject={updateObject!}
              selectedData={selectedData}
              isQuotePlansApproved={isQuotePlansApproved}
            />
          );
        })}
      </div>
    </>
  );
};

export default DeductiblesSection;
