import { ChangeEvent, FC } from 'react';
import { Row, Input } from 'antd';
import { findInArray } from 'modules/renewals/utils/addPlanDetailUtil';
import { PartialArrayItemTemplate } from 'modules/renewals/types/planTypes';
import { InNetworkOutNetwork } from 'modules/renewals/types/renewalsTypes';
import iconRemove from 'assets/images/icon-remove-red.svg';
import { ReactComponent as AIFeedbackRedFlag } from 'assets/images/ai-red-flag.svg';
import styles from 'modules/renewals/components/AddPlanDetailsModal/AddPlanForm/addPlanForm.module.less';

type AddPlanInputFieldProps = {
  updateObject: Function;
  selectedData?: any;
  item: string;
  section: {
    inNetwork: InNetworkOutNetwork;
    outNetwork: InNetworkOutNetwork;
  };
  enableRemoveRow?: boolean;
  handleRemoveRow?: (
    value: string,
    inNetworkObj: PartialArrayItemTemplate,
    outOfNetworkObj: PartialArrayItemTemplate
  ) => void;
  isQuotePlansApproved?: boolean;
};

const AddPlanInputField: FC<AddPlanInputFieldProps> = (
  props: AddPlanInputFieldProps
) => {
  const {
    item,
    section,
    updateObject,
    selectedData,
    handleRemoveRow,
    enableRemoveRow,
    isQuotePlansApproved,
  } = props;

  const inNetwork = section?.inNetwork;
  const outNetwork = section?.outNetwork;

  const inNetworkObj = findInArray(
    selectedData?.[inNetwork?.property],
    inNetwork?.name,
    inNetwork?.fieldType,
    inNetwork?.fieldSubType
  );
  const outOfNetworkObj = findInArray(
    selectedData?.[outNetwork?.property],
    outNetwork?.name,
    outNetwork?.fieldType,
    outNetwork?.fieldSubType
  );

  const inNetworkLLMObject = findInArray(
    selectedData?.llmExtractionInfo?.[inNetwork?.property],
    inNetwork?.name,
    inNetwork?.fieldType,
    inNetwork?.fieldSubType
  );
  const outOfNetworkLLMObject = findInArray(
    selectedData?.llmExtractionInfo?.[outNetwork?.property],
    outNetwork?.name,
    outNetwork?.fieldType,
    outNetwork?.fieldSubType
  );

  return (
    <>
      <Row justify="space-between" className={styles.subFieldItems}>
        <div className={styles.subFieldItemLabelWrapper}>
          {enableRemoveRow && (
            <img
              src={iconRemove}
              alt="remove-tier-icon"
              className={styles.iconRemove}
              onClick={(e) => {
                handleRemoveRow?.(item, inNetworkObj, outOfNetworkObj);
              }}
            />
          )}
          {item}
        </div>

        <div className={styles.rxInputWrapper}>
          <div
            className={`${
              !isQuotePlansApproved &&
              inNetworkLLMObject?.different &&
              !inNetwork?.added
                ? styles.llmDifferentWrapper
                : ''
            }`}
          >
            <Input
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                updateObject(inNetwork?.property, e.target.value, true, {
                  fieldSubType: inNetwork?.fieldSubType,
                  fieldType: inNetwork?.fieldType,
                  name: inNetwork?.name,
                  added: inNetwork?.added,
                })
              }
              value={
                (inNetworkObj?.value ?? '') === 'null'
                  ? ''
                  : inNetworkObj?.value ?? ''
              }
              className={styles.premiumInput}
            />
            {!isQuotePlansApproved &&
              inNetworkLLMObject?.different &&
              !inNetwork?.added && (
                <AIFeedbackRedFlag
                  className={styles.aiFeedbackFlagAdditionalPadding}
                />
              )}
          </div>
          <div
            className={`${
              !isQuotePlansApproved &&
              outOfNetworkLLMObject?.different &&
              !outNetwork?.added
                ? styles.llmDifferentWrapper
                : ''
            }`}
          >
            <Input
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                updateObject(outNetwork?.property, e.target.value, true, {
                  fieldSubType: outNetwork?.fieldSubType,
                  fieldType: outNetwork?.fieldType,
                  name: outNetwork?.name,
                  added: outNetwork?.added,
                })
              }
              value={
                (outOfNetworkObj?.value ?? '') === 'null'
                  ? ''
                  : outOfNetworkObj?.value ?? ''
              }
              className={'ant-input'}
            />
            {!isQuotePlansApproved &&
              outOfNetworkLLMObject?.different &&
              !outNetwork?.added && (
                <AIFeedbackRedFlag className={styles.aiFeedbackFlag} />
              )}
          </div>
        </div>
      </Row>
    </>
  );
};

export default AddPlanInputField;
