import Stepper from 'components/Stepper/Stepper';

import ReviewAndComplete from 'modules/renewals/components/AppProposalToPlanYearComponents/ReviewAndComplete/ReviewAndComplete';
import PlanYearSetup from 'modules/renewals/components/AppProposalToPlanYearComponents/PlanYearSetup/PlanYearSetup';
import PlanDetails from 'modules/renewals/components/AppProposalToPlanYearComponents/PlanDetails/PlanDetails';

import styles from 'modules/renewals/components/AppProposalToPlanYearComponents/StepController/StepController.module.less';

type Props = {
  isOpen: boolean;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  onCloseModal?: (showConfirm?: boolean) => void;
  updateProposalStatus?: Function;
  setIsProposalAddToPlanYearVisible?: Function;
};

const StepController = ({
  isOpen,
  currentStep,
  setCurrentStep,
  onCloseModal = () => {},
  updateProposalStatus = () => {},
  setIsProposalAddToPlanYearVisible = () => {},
}: Props) => {
  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleChangeStep = (step: number) => {
    setCurrentStep(step);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className={styles.step}>
            <PlanYearSetup
              isOpen={isOpen}
              onNextStep={handleNextStep}
              onCloseModal={onCloseModal}
              onPreviousStep={handlePreviousStep}
            />
          </div>
        );
      case 1:
        return (
          <div className={styles.step}>
            <PlanDetails
              onNextStep={handleNextStep}
              onCloseModal={onCloseModal}
              onPreviousStep={handlePreviousStep}
            />
          </div>
        );
      case 2:
        return (
          <div className={styles.step}>
            <ReviewAndComplete
              onNextStep={handleNextStep}
              onCloseModal={onCloseModal}
              onPreviousStep={handlePreviousStep}
              updateProposalStatus={updateProposalStatus}
              setIsProposalAddToPlanYearVisible={
                setIsProposalAddToPlanYearVisible
              }
            />
          </div>
        );
      default:
        return;
    }
  };

  return (
    <div className={styles.stepWrapper}>
      <Stepper
        activeStep={currentStep}
        changeCurrentStep={handleChangeStep}
        renderStepContent={renderStepContent}
        headers={['Plan Year Set Up', 'Plan Details', 'Review & Complete']}
        isLastStepActive={true}
      />
    </div>
  );
};

export default StepController;
