import { FC } from 'react';
import { default as NumberFormat } from 'react-number-format';
import styles from './inputphonenumber.module.less';

type InputPhoneNumberProps = {};

const InputPhoneNumber: FC<InputPhoneNumberProps> = (props) => {
  const { ...rest } = props;
  return (
    <NumberFormat
      id="phone"
      className={styles.numberformat}
      format="#-###-###-####"
      placeholder="1-XXX-XXX-XXXX"
      type={'tel'}
      {...rest}
    />
  );
};

export default InputPhoneNumber;
