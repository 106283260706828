import { useEffect, useMemo, useState } from 'react';

import { indexOf, sortBy } from 'lodash';
import { Card, Col, Divider, Input, Popover, Row, Select, Spin } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';

import LinkButton from 'components/buttons/LinkButton/LinkButton';
import InputNumber from 'components/InputNumber/InputNumber';
import Button from 'components/buttons/Button/Button';
import SelectOptions from 'components/SelectOptions/SelectOptions';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import { checkIfEmpty, isNumber } from 'util/stringUtil';
import { formatHeaderValues } from 'modules/renewals/utils/renewalsUtils';

import { useGetOffersByEffectiveDateQuery } from 'modules/renewals/slices/offersSlice';
import {
  useGetProposalCreditsQuery,
  useGetProposalDiscountsQuery,
  useProposalStatusUpdateMutation,
  useSaveOfferDiscountMutation,
} from 'modules/renewals/slices/proposalSlice';
import TextButton from 'components/buttons/TextButton/TextButton';
import SingleCreditsDiscountsDescriptionModal from 'modules/renewals/components/SingleCreditsDiscountsDescriptionModal/SingleCreditsDiscountsDescriptionModal';

import {
  BENEFIT_TYPES_DISCOUNT_DROPDOWN_OPTIONS,
  CREDIT_COST_TYPES,
  DISCOUNT_DISCLAIMER_TXT,
  DRAFTS_TAB,
  OFFER_BENEFIT_TYPES,
  OFFER_CATEGORY,
  OFFER_STATUS,
  PROPOSAL_DETAILED_RENEWAL_TYPES,
  PROPOSAL_OFFER_LIST_PROPERTIES,
} from 'modules/renewals/constants/renewalsConstants';
import ActionButtonContainer from 'modules/renewals/components/addProposalsComponents/ActionButtonContainer/ActionButtonContainer';
import { ICreditsDiscountsDescriptionItem } from 'modules/renewals/types/renewalsTypes';
import { OfferCreditVO } from 'model/ProposalCreditVO';
import { removeDuplicates } from 'util/arrayUtil';
import { setAntDModalScrollToTop } from 'util/domUtils';

import { ReactComponent as IconInfo } from 'assets/images/icon-info.svg';
import styles from './addDiscounts.module.less';

const CardTitleComp = ({
  title,
  amount,
}: {
  title: string;
  amount: number | string;
}) => {
  return (
    <Row justify="space-between">
      <Col>
        <h2 className={styles.title}>{title}</h2>
      </Col>
      <Col>
        <h2 className={styles.title}>{amount}</h2>
      </Col>
    </Row>
  );
};

type AddDiscountsProps = {
  nextStep: () => void;
  onPreviousStep: () => void;
  closeModal: (
    showConfirmMessage: any,
    proposalStatus?: string | undefined
  ) => void;
  isProposalDetailedView?: boolean;
  proposalId: string;
};

type additionalCreditsTypes = {
  type: any;
  description: any;
  total: any;
  erAmount: any;
  eeAmount: any;
  saved: boolean;
};
type bundleDiscountsTypes = {
  bundleDiscountAmount: any;
  bundleDescription: any;
  benefitType: any;
  offer: any;
  saved: boolean;
};

const AddDiscounts = (props: AddDiscountsProps) => {
  const {
    nextStep,
    onPreviousStep,
    closeModal,
    isProposalDetailedView,
    proposalId,
  } = props;

  const proposalDetails = useAppSelector(
    (state) => state.proposalOnBoarding.proposal
  );
  const params = useParams();

  const [additionalCredits, setAdditionalCredits] = useState<
    additionalCreditsTypes[]
  >([]);
  const [bundleDiscounts, setBundleDiscounts] = useState<
    bundleDiscountsTypes[]
  >([]);

  const [removeCreditModel, setRemoveCreditModel] = useState<{
    id: any;
    visible: boolean;
  }>({
    id: undefined,
    visible: false,
  });
  const [removeDiscountModel, setRemoveDiscountModel] = useState<{
    id: any;
    visible: boolean;
  }>({
    id: undefined,
    visible: false,
  });
  const [creditClickedIndex, setCreditClickedIndex] = useState<number>(0);
  const [discountClickedIndex, setDiscountClickedIndex] = useState<number>(0);
  const [isCreditsDescriptionModalOpen, setIsCreditsDescriptionModalOpen] =
    useState<boolean>(false);
  const [creditsDescriptionModalData, setCreditsDescriptionModalData] =
    useState<ICreditsDiscountsDescriptionItem>({
      description: '',
      value: 0,
    });
  const [isCreditSaved, setCreditSaved] = useState<boolean>(false);

  const { data: medicalOffers, isFetching: medicalOffersIsFetching } =
    useGetOffersByEffectiveDateQuery(
      {
        employerId: params?.employerId ?? '',
        category: OFFER_CATEGORY.MEDICAL,
        upcomingPlanYearId: proposalDetails?.payload?.effectiveDateId,
        basic: true,
      },
      {
        skip:
          proposalDetails?.payload?.primaryMedicalOffer === null &&
          proposalDetails?.payload?.supplementaryMedicalOffers?.length === 0,
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: dentalOffers, isFetching: dentalOffersIsFetching } =
    useGetOffersByEffectiveDateQuery(
      {
        employerId: params?.employerId ?? '',
        category: OFFER_CATEGORY.DENTAL,
        upcomingPlanYearId: proposalDetails?.payload?.effectiveDateId,
        basic: true,
      },
      {
        skip:
          proposalDetails?.payload?.primaryDentalOffer === null &&
          proposalDetails?.payload?.supplementaryDentalOffers?.length === 0,
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: visionOffers, isFetching: visionOffersIsFetching } =
    useGetOffersByEffectiveDateQuery(
      {
        employerId: params?.employerId ?? '',
        category: OFFER_CATEGORY.VISION,
        upcomingPlanYearId: proposalDetails?.payload?.effectiveDateId,
        basic: true,
      },
      {
        skip:
          proposalDetails?.payload?.primaryVisionOffer === null &&
          proposalDetails?.payload?.supplementaryVisionOffers?.length === 0,
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: lifeOffers, isFetching: lifeOffersIsFetching } =
    useGetOffersByEffectiveDateQuery(
      {
        employerId: params?.employerId ?? '',
        category: OFFER_CATEGORY.LIFE_AD,
        upcomingPlanYearId: proposalDetails?.payload?.effectiveDateId,
        basic: true,
      },
      {
        skip: proposalDetails?.payload?.lifeAddOffers?.length === 0,
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: stdOffers, isFetching: stdOffersIsFetching } =
    useGetOffersByEffectiveDateQuery(
      {
        employerId: params?.employerId ?? '',
        category: OFFER_CATEGORY.SHORT_TERM_DISABILITY,
        upcomingPlanYearId: proposalDetails?.payload?.effectiveDateId,
        basic: true,
      },
      {
        skip: proposalDetails?.payload?.lifeSTDOffers?.length === 0,
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: ltdOffers, isFetching: ltdOffersIsFetching } =
    useGetOffersByEffectiveDateQuery(
      {
        employerId: params?.employerId ?? '',
        category: OFFER_CATEGORY.LONG_TERM_DISABILITY,
        upcomingPlanYearId: proposalDetails?.payload?.effectiveDateId,
        basic: true,
      },
      {
        skip: proposalDetails?.payload?.lifeLTDOffers?.length === 0,
        refetchOnMountOrArgChange: true,
      }
    );

  const [
    saveDiscount,
    {
      data: saveDiscountData,
      isLoading: saveDiscountIsLoading,
      reset: resetSaveDiscountData,
    },
  ] = useSaveOfferDiscountMutation();

  const [
    saveCredit,
    {
      data: saveCreditData,
      isLoading: saveCreditIsLoading,
      reset: resetSaveCreditData,
    },
  ] = useSaveOfferDiscountMutation();

  const { data: offerDiscountData, isFetching: offerDiscountDataIsLoading } =
    useGetProposalDiscountsQuery(
      {
        proposalId: proposalDetails?.payload?.id,
      },
      { refetchOnMountOrArgChange: true }
    );

  const {
    data: offerCreditsData,
    isFetching: offerCreditsIsLoading,
    isSuccess,
  } = useGetProposalCreditsQuery(
    {
      proposalId: proposalDetails?.payload?.id,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [publishProposal, { isLoading: isPublishStatusUpdating }] =
    useProposalStatusUpdateMutation();

  const isSavedBundleDiscountPresent = bundleDiscounts.some(
    (item) => item.saved
  );

  const navigate = useNavigate();

  const handleNavigation = (): void => {
    navigate(
      `/brokers/${params.brokerId}/employers/${params.employerId}/renewals/proposals`,
      { state: { proposalStatus: DRAFTS_TAB } }
    );
  };

  const offerDropDownData = (): any => {
    return {
      MEDICAL: medicalOffers,
      DENTAL: dentalOffers,
      VISION: visionOffers,
      LIFE: lifeOffers,
      LTD: ltdOffers,
      STD: stdOffers,
    };
  };

  const getTotalCreditsFromOffers = () => {
    return offerCreditsData?.totalOfferCredits;
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text/plain');
    const pastedNumber = parseFloat(pastedText);
    if (!isNaN(pastedNumber) && pastedNumber >= 0) {
      const roundedNumber = Math.round(pastedNumber * 100) / 100;
      document.execCommand('insertText', false, roundedNumber.toString());
    }
  };

  useEffect(() => {
    if (offerDiscountData !== undefined) {
      const discount = (offerDiscountData?.discounts ?? []).map((item: any) => {
        return {
          bundleDiscountAmount: item?.discount,
          bundleDescription: item?.description,
          benefitType: item?.category,
          offer: item?.offerId,
          saved: true,
        };
      });
      setBundleDiscounts(discount);
    }
  }, [offerDiscountData]);

  useEffect(() => {
    if (offerCreditsData !== undefined) {
      const credits = (offerCreditsData?.credits ?? []).map((item: any) => {
        const { type, description, total, erAmount, eeAmount } = item ?? {};
        return {
          type,
          description,
          total,
          erAmount,
          eeAmount,
          saved: true,
        };
      });
      setAdditionalCredits(credits);
    }
  }, [offerCreditsData, isSuccess]);

  const handleAddNewAdditionalCredits = () => {
    setAdditionalCredits((prev) => [
      ...prev,
      {
        type: CREDIT_COST_TYPES.COST,
        description: undefined,
        total: undefined,
        erAmount: undefined,
        eeAmount: undefined,
        saved: false,
      },
    ]);
  };

  const handleSaveCredits = (discounts: additionalCreditsTypes[]) => {
    const creditsRearrange = discounts?.map((item) => {
      const { type, description, total, erAmount, eeAmount, saved } =
        item ?? {};
      return {
        type,
        description,
        total,
        erAmount,
        eeAmount,
        saved,
      };
    });
    saveCredit({
      planId: proposalDetails?.payload?.id,
      proposalDiscount: {
        id: proposalDetails?.payload?.id,
        discountCreditType: 'CREDIT',
        credits: creditsRearrange,
      },
      type: 'credits',
    });
  };

  const handelOnChangeAdditionalCredits = (
    value: any,
    fieldName: keyof additionalCreditsTypes,
    idx: any
  ) => {
    const updatedCredit = { ...additionalCredits[idx], [fieldName]: value };

    // Update the 'total' if 'eeAmount' or 'erAmount' is changed
    if (fieldName === 'eeAmount' || fieldName === 'erAmount') {
      const eeAmount = parseFloat(updatedCredit.eeAmount ?? 0);
      const erAmount = parseFloat(updatedCredit.erAmount ?? 0);
      updatedCredit.total = eeAmount + erAmount;
    }

    // Update the main array with the updated object
    const updatedCredits = [...additionalCredits];
    updatedCredits[idx] = updatedCredit;

    setAdditionalCredits(updatedCredits);

    if (fieldName === 'saved' && value) {
      setCreditSaved(true);
    }
  };

  useEffect(() => {
    if (additionalCredits.length > 0 && isCreditSaved) {
      handleSaveCredits(additionalCredits);
      setCreditSaved(false);
    }
    // eslint-disable-next-line
  }, [additionalCredits, isCreditSaved]);

  const handelRemoveAdditionalCredits = (idx: number) => {
    const copyArray = [...additionalCredits];
    copyArray.splice(idx, 1);
    setAdditionalCredits(copyArray);
    handleSaveCredits(copyArray);
  };

  const getAdditionalCreditCostTotal = useMemo(() => {
    if (saveCreditData !== undefined) {
      return {
        creditTotal: saveCreditData?.creditTotal ?? 0,
        costTotal: saveCreditData?.costTotal ?? 0,
      };
    } else if (offerCreditsData !== undefined) {
      return {
        creditTotal: offerCreditsData?.creditTotal ?? 0,
        costTotal: offerCreditsData?.costTotal ?? 0,
      };
    } else if (!saveCreditIsLoading) {
      return { creditTotal: 0, costTotal: 0 };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCreditData, offerCreditsData, additionalCredits]);

  const handleAddNewBundleDiscount = () => {
    setBundleDiscounts((prev) => [
      ...prev,
      {
        bundleDiscountAmount: undefined,
        bundleDescription: undefined,
        benefitType: undefined,
        offer: undefined,
        saved: false,
      },
    ]);
  };

  const handleSaveDiscount = (discounts: bundleDiscountsTypes[]) => {
    const discountsRearrange = discounts?.map((item) => {
      return {
        category: item?.benefitType?.toUpperCase(),
        discountType: 'PERCENT',
        discount: item?.bundleDiscountAmount?.toString(),
        description: item?.bundleDescription,
        offerId: item?.offer,
      };
    });
    saveDiscount({
      planId: proposalDetails?.payload?.id,
      proposalDiscount: {
        id: proposalDetails?.payload?.id,
        discountCreditType: discounts?.length === 0 ? 'NONE' : 'BUNDLE',
        discounts: discountsRearrange,
      },
      type: 'discounts',
    });
  };

  const handelOnChangeBundleDiscount = (
    value: any,
    fieldName: keyof bundleDiscountsTypes,
    idx: any
  ) => {
    const copyList = [...bundleDiscounts];
    copyList[idx][fieldName] = value;
    if (fieldName === 'bundleDiscountAmount') {
      const numericValue = value.replace(/[^0-9.]/g, '');
      const floatValue = parseFloat(numericValue);

      if (floatValue > 100) {
        return;
      }
    }

    if (fieldName === 'benefitType') {
      copyList[idx].offer = '';
    }

    setBundleDiscounts(copyList);

    if (fieldName === 'saved' && value) {
      handleSaveDiscount(copyList);
    }
  };

  const handelRemoveBundleDiscount = (idx: number) => {
    const copyArray = [...bundleDiscounts];
    copyArray.splice(idx, 1);
    setBundleDiscounts(copyArray);
    handleSaveDiscount(copyArray);
  };

  const handleNext = () => {
    resetSaveDiscountData();
    resetSaveCreditData();
    nextStep();
    setAntDModalScrollToTop();
  };

  const handlePrevious = () => {
    resetSaveDiscountData();
    resetSaveCreditData();
    onPreviousStep();
    setAntDModalScrollToTop();
  };

  const handleSaveAsDraft = async () => {
    await publishProposal({
      proposalId: proposalId,
      proposalStatus: OFFER_STATUS.DRAFT,
    });
    if (isProposalDetailedView && params?.proposalId) {
      navigate(
        `/brokers/${params.brokerId}/employers/${params.employerId}/renewals/proposals/details/${proposalId}`
      );
    }
    closeModal(true, OFFER_STATUS.DRAFT);
  };

  const showOffer = (benefitType: string, offerId: string): boolean => {
    const isOfferSelected =
      proposalDetails?.payload?.[
        PROPOSAL_OFFER_LIST_PROPERTIES?.[benefitType.toUpperCase()]
      ]?.includes(offerId) ||
      (benefitType.toUpperCase() === PROPOSAL_DETAILED_RENEWAL_TYPES.medical &&
        proposalDetails?.payload?.primaryMedicalOffer === offerId) ||
      (benefitType.toUpperCase() === PROPOSAL_DETAILED_RENEWAL_TYPES.dental &&
        proposalDetails?.payload?.primaryDentalOffer === offerId) ||
      (benefitType.toUpperCase() === PROPOSAL_DETAILED_RENEWAL_TYPES.vision &&
        proposalDetails?.payload?.primaryVisionOffer === offerId);

    const isOfferDiscounted = bundleDiscounts.some(
      (discount) => discount.offer === offerId
    );
    hasRemainingOffersOfType(benefitType);
    return isOfferSelected && !isOfferDiscounted;
  };

  /**
   * Confirms whether proposal has offers of the given type that
   * do not have discounts yet. Always returns true if benefitType is undefined
   * @param {string} benefitType
   * @return {boolean}
   */
  const hasRemainingOffersOfType = (benefitType?: string): boolean => {
    if (benefitType === undefined) return true;
    let selectedOffersCountOfType =
      proposalDetails?.payload?.[
        PROPOSAL_OFFER_LIST_PROPERTIES?.[benefitType.toUpperCase()]
      ].length;

    // Medical offers can have a single primary offer
    if (
      benefitType.toUpperCase() === PROPOSAL_DETAILED_RENEWAL_TYPES.medical &&
      proposalDetails?.payload?.primaryMedicalOffer
    ) {
      selectedOffersCountOfType += 1;
    }
    if (
      benefitType.toUpperCase() === PROPOSAL_DETAILED_RENEWAL_TYPES.dental &&
      proposalDetails?.payload?.primaryDentalOffer
    ) {
      selectedOffersCountOfType += 1;
    }
    if (
      benefitType.toUpperCase() === PROPOSAL_DETAILED_RENEWAL_TYPES.vision &&
      proposalDetails?.payload?.primaryVisionOffer
    ) {
      selectedOffersCountOfType += 1;
    }

    /**
     * Once a benefitType is selected bundleDiscounts will have an extra, empty
     * offer of the the type. '-1' is to cancel out the difference.
     */
    const discountedOffersCountOfType =
      bundleDiscounts.filter((discount) => discount.benefitType === benefitType)
        .length - 1;

    return selectedOffersCountOfType > discountedOffersCountOfType;
  };

  const getBundleDiscountTotal = useMemo(() => {
    if (saveDiscountData !== undefined) {
      return saveDiscountData?.discountTotal ?? 0;
    } else if (offerDiscountData !== undefined) {
      return offerDiscountData?.discountTotal;
    } else if (!saveDiscountIsLoading) {
      return 0;
    }
    // eslint-disable-next-line
  }, [saveDiscountData, offerDiscountData]);

  const handleCreditsFromOffersValueClick = (idx: number) => {
    const item = getSortedOfferCredits()[idx];
    if (item) {
      setCreditsDescriptionModalData({
        description: item.description ?? '',
        offerName: item.offerName,
        value: item.credit ?? 0,
        type: 'CREDITS',
      });
      setIsCreditsDescriptionModalOpen(true);
    }
  };

  /**
   * Sorts array of offer credits according to order of
   * keys in {@link OFFER_BENEFIT_TYPES}
   * @return {[]} A sorted array
   */
  const getSortedOfferCredits = (): OfferCreditVO[] => {
    const order = Object.keys(OFFER_BENEFIT_TYPES);
    const offerCredits = offerCreditsData?.offerCredits ?? [];

    const sortedOfferCredits = sortBy(removeDuplicates(offerCredits), (item) =>
      indexOf(order, item.category)
    );
    return sortedOfferCredits;
  };

  return (
    <div className={styles.container}>
      <Card
        className={styles.cardMainStyles}
        bordered={false}
        title={
          <CardTitleComp
            title="Credits (from Offers)"
            amount={formatHeaderValues(
              getTotalCreditsFromOffers() as any,
              'CURRENCY',
              false
            )}
          />
        }
        headStyle={{ padding: 0 }}
        bodyStyle={{ padding: '24px 0' }}
      >
        {getSortedOfferCredits().map((offerCreditItem, offerCreditIndex) =>
          offerCreditItem?.credit !== null ? (
            <Row key={offerCreditIndex}>
              <span className={styles.carrierPlanName}>
                {`${offerCreditItem?.offerName} : `}
              </span>
              <span className={styles.carrierPlanDiscountOffers}>
                <TextButton
                  type="primary"
                  className={
                    offerCreditItem.description
                      ? styles.creditsFromOffersValue
                      : styles.noCreditDescriptionValue
                  }
                  label={formatHeaderValues(
                    offerCreditItem?.credit || 0,
                    'CURRENCY',
                    false
                  )}
                  onClick={() =>
                    offerCreditItem.description ? (
                      handleCreditsFromOffersValueClick(offerCreditIndex)
                    ) : (
                      <></>
                    )
                  }
                />
              </span>
            </Row>
          ) : (
            ''
          )
        )}
      </Card>
      {/* Bundle Discounts Section */}
      <Card
        className={styles.cardMainStyles}
        bordered={false}
        title={
          <Row wrap={false} className={styles.discountsHeader}>
            <div className={styles.headerRow}>
              <CardTitleComp
                title="Bundle Discounts"
                amount={formatHeaderValues(
                  getBundleDiscountTotal ?? 0,
                  'CURRENCY',
                  false
                )}
              />
            </div>
            {isSavedBundleDiscountPresent && (
              <Popover
                content={DISCOUNT_DISCLAIMER_TXT}
                overlayClassName={`popoverInner recommendedtooltip`}
              >
                <div className={styles.tooltipWrapper}>
                  <IconInfo />
                </div>
              </Popover>
            )}
          </Row>
        }
        headStyle={{ padding: 0 }}
        bodyStyle={{ padding: '24px 0' }}
      >
        {offerDiscountDataIsLoading ||
        medicalOffersIsFetching ||
        dentalOffersIsFetching ||
        visionOffersIsFetching ||
        lifeOffersIsFetching ||
        stdOffersIsFetching ||
        ltdOffersIsFetching ? (
          <Spin />
        ) : (
          <div>
            {bundleDiscounts?.map((item, idx) => {
              return (
                <div key={idx}>
                  <Row gutter={16}>
                    <Col span={1} className={styles.removeIconWrapper}>
                      <MinusCircleOutlined
                        className={`${styles.removeIcon} ${styles.removeIconBundle}`}
                        onClick={() =>
                          setRemoveDiscountModel({ id: idx, visible: true })
                        }
                      />
                    </Col>
                    <Col span={4}>
                      <label
                        className={`${styles.inputLabel} ${styles.inputLabelBundle}`}
                      >
                        Discount *{' '}
                      </label>
                      <Input
                        className={styles.inputDiscount}
                        value={item.bundleDiscountAmount}
                        onChange={(event) => {
                          handelOnChangeBundleDiscount(
                            event.target.value,
                            'bundleDiscountAmount',
                            idx
                          );
                        }}
                        disabled={item.saved}
                        onKeyPress={isNumber}
                        onPaste={handlePaste}
                        suffix={'%'}
                      />
                    </Col>
                    <Col span={16}>
                      <label
                        className={`${styles.inputLabel} ${styles.inputLabelBundle}`}
                      >
                        Discount Description
                      </label>
                      <Input
                        className={styles.inputDescription}
                        value={item.bundleDescription}
                        onChange={(value) =>
                          handelOnChangeBundleDiscount(
                            value.target.value,
                            'bundleDescription',
                            idx
                          )
                        }
                        disabled={item.saved}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className={styles.bundleDiscountBenefitRow}>
                    <Col span={5} />
                    <Col span={4} className={styles.inputSelectWrapper}>
                      <label
                        className={`${styles.inputLabel} ${styles.inputLabelBundle}`}
                      >
                        Benefit *
                      </label>
                      <SelectOptions
                        value={item.benefitType}
                        onChange={(value) =>
                          handelOnChangeBundleDiscount(
                            value,
                            'benefitType',
                            idx
                          )
                        }
                        disabled={item.saved}
                      >
                        {BENEFIT_TYPES_DISCOUNT_DROPDOWN_OPTIONS.map(
                          (item, key) =>
                            offerDropDownData()?.[item?.value] !== undefined ? (
                              <Select.Option value={item.value} key={key}>
                                {item.label}
                              </Select.Option>
                            ) : (
                              ''
                            )
                        )}
                      </SelectOptions>
                    </Col>
                    <Col span={12} className={styles.inputSelectWrapper}>
                      <label
                        className={`${styles.inputLabel} ${styles.inputLabelBundle}`}
                      >
                        Offer *
                      </label>
                      <SelectOptions
                        value={item.offer}
                        onChange={(value) =>
                          handelOnChangeBundleDiscount(value, 'offer', idx)
                        }
                        disabled={
                          item.saved ||
                          !hasRemainingOffersOfType(item.benefitType)
                        }
                      >
                        {offerDropDownData()?.[
                          item?.benefitType?.toUpperCase()
                        ]?.map((offerItem: any, key: number) =>
                          showOffer(item?.benefitType, offerItem?.id) ||
                          item?.offer === offerItem?.id ? (
                            <Select.Option value={offerItem?.id} key={key}>
                              {offerItem?.name}
                            </Select.Option>
                          ) : (
                            ''
                          )
                        )}
                      </SelectOptions>
                    </Col>
                    <Col span={3}>
                      <div className={styles.bundleDiscountSaveBtnWrapper} />
                      <Button
                        className={`${styles.buttonSave} ${
                          item.saved ? styles.editButton : ''
                        }`}
                        label={item?.saved ? 'Edit' : 'Save'}
                        type={item.saved ? 'default' : 'primary'}
                        onClick={() => {
                          handelOnChangeBundleDiscount(
                            !item?.saved,
                            'saved',
                            idx
                          );
                          setDiscountClickedIndex(idx);
                        }}
                        disabled={
                          checkIfEmpty(item.benefitType) ||
                          checkIfEmpty(item.bundleDiscountAmount) ||
                          checkIfEmpty(item.offer) ||
                          saveDiscountIsLoading
                        }
                        loading={
                          idx === discountClickedIndex && saveDiscountIsLoading
                        }
                      />
                    </Col>
                  </Row>
                  <Divider className={styles.rowDivider} />
                </div>
              );
            })}

            <Row justify="center">
              <LinkButton
                onClick={handleAddNewBundleDiscount}
                disabled={
                  (bundleDiscounts?.length ?? 0) === 0
                    ? false
                    : !bundleDiscounts[bundleDiscounts.length - 1]?.saved
                }
                className={styles.addNewBtn}
              >
                + New Bundle Discount
              </LinkButton>
            </Row>
          </div>
        )}
      </Card>

      {/* Additional Discount Section */}
      <Card
        className={styles.cardMainStyles}
        bordered={false}
        title={
          offerCreditsIsLoading ? (
            <Spin />
          ) : (
            <Row justify={'space-between'}>
              <Col span={8}>
                <h2 className={styles.title}>Additional Costs/Credits</h2>
              </Col>
              <Col>
                <h2 className={styles.subTitle}>
                  Total Costs{' '}
                  {formatHeaderValues(
                    getAdditionalCreditCostTotal?.costTotal ?? 0,
                    'CURRENCY',
                    false
                  )}{' '}
                </h2>
                <h2 className={styles.subTitle}>
                  Total Credits{' '}
                  {formatHeaderValues(
                    getAdditionalCreditCostTotal?.creditTotal ?? 0,
                    'CURRENCY',
                    false
                  )}
                </h2>
              </Col>
            </Row>
          )
        }
        headStyle={{ padding: 0 }}
        bodyStyle={{ padding: '24px 0' }}
      >
        {offerCreditsIsLoading ? (
          <Spin />
        ) : (
          <div>
            {additionalCredits?.map((item, idx) => {
              return (
                <>
                  {/* Initial Row Header - start */}
                  <Row gutter={16} className={styles.labelWrapper}>
                    <Col span={1} />
                    <Col span={4}>
                      <label className={styles.inputLabel}>Type *</label>
                    </Col>
                    <Col span={16}>
                      <label className={styles.inputLabel}>Description *</label>
                    </Col>
                  </Row>
                  {/* Initial Row Header - end */}

                  {/* Initial User input row - start */}
                  <Row gutter={16} key={idx}>
                    <Col span={1} className={styles.removeIconWrapper}>
                      <MinusCircleOutlined
                        className={styles.removeIcon}
                        onClick={() =>
                          setRemoveCreditModel({ id: idx, visible: true })
                        }
                      />
                    </Col>
                    <Col span={4}>
                      <Select
                        size="large"
                        getPopupContainer={(trigger) => trigger.parentNode}
                        className={styles.costCreditSelect}
                        disabled={item?.saved}
                        value={item?.type}
                        onChange={(value) => {
                          handelOnChangeAdditionalCredits(value, 'type', idx);
                        }}
                      >
                        <Select.Option value={CREDIT_COST_TYPES.COST}>
                          Cost
                        </Select.Option>
                        <Select.Option value={CREDIT_COST_TYPES.CREDIT}>
                          Credit
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={16}>
                      <Input
                        className={styles.inputDescription}
                        value={item.description}
                        onChange={(value) =>
                          handelOnChangeAdditionalCredits(
                            value.target.value,
                            'description',
                            idx
                          )
                        }
                        disabled={item.saved}
                      />
                    </Col>
                  </Row>
                  {/* Initial User input row - end */}

                  {/* Second Row header - start */}
                  <Row gutter={16} className={styles.bottomLabelWrapper}>
                    <Col span={5} />
                    <Col span={5}>
                      <label className={styles.inputLabel}>Total Amount</label>
                    </Col>
                    <Col span={5}>
                      <label className={styles.inputLabel}>
                        Employer (ER) Amount *
                      </label>
                    </Col>
                    <Col span={5}>
                      <label className={styles.inputLabel}>
                        Employee (EE) Amount *
                      </label>
                    </Col>
                  </Row>
                  {/* Second Row header - end */}

                  {/* Second  User input row - start */}
                  <Row gutter={16} key={idx}>
                    <Col span={5}></Col>
                    <Col span={5}>
                      <InputNumber
                        className={styles.inputDiscount}
                        value={item?.total}
                        min={0}
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        controls={false}
                        disabled={true}
                      />
                    </Col>
                    <Col span={5}>
                      <InputNumber
                        className={styles.inputDescription}
                        value={item.erAmount}
                        onChange={(value) =>
                          handelOnChangeAdditionalCredits(
                            value,
                            'erAmount',
                            idx
                          )
                        }
                        disabled={item.saved}
                        min={0}
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        controls={false}
                        onKeyPress={isNumber}
                        onPaste={handlePaste}
                      />
                    </Col>
                    <Col span={6}>
                      <InputNumber
                        className={styles.inputDescription}
                        value={item.eeAmount}
                        onChange={(value) =>
                          handelOnChangeAdditionalCredits(
                            value,
                            'eeAmount',
                            idx
                          )
                        }
                        disabled={item.saved}
                        min={0}
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        controls={false}
                        onKeyPress={isNumber}
                        onPaste={handlePaste}
                      />
                    </Col>
                    <Col span={3}>
                      <Button
                        className={`${styles.buttonSave} ${
                          item.saved ? styles.editButton : ''
                        }`}
                        label={item?.saved ? 'Edit' : 'Save'}
                        type={item.saved ? 'default' : 'primary'}
                        onClick={() => {
                          handelOnChangeAdditionalCredits(
                            !item?.saved,
                            'saved',
                            idx
                          );
                          setCreditClickedIndex(idx);
                        }}
                        disabled={
                          saveCreditIsLoading ||
                          checkIfEmpty(item?.type) ||
                          checkIfEmpty(item?.erAmount) ||
                          checkIfEmpty(item?.eeAmount) ||
                          checkIfEmpty(item?.description)
                        }
                        loading={
                          creditClickedIndex === idx && saveCreditIsLoading
                        }
                      />
                    </Col>

                    <Divider className={styles.rowDivider} />
                  </Row>
                  {/* Second  User input row - end */}
                </>
              );
            })}
            <Row justify="center">
              <LinkButton
                onClick={handleAddNewAdditionalCredits}
                disabled={
                  additionalCredits?.length === 0
                    ? false
                    : !additionalCredits?.[additionalCredits?.length - 1]?.saved
                }
                className={styles.addNewBtn}
              >
                + New Cost/Credit
              </LinkButton>
            </Row>
          </div>
        )}
      </Card>

      <ActionButtonContainer
        onClickNext={handleNext}
        onClickBack={handlePrevious}
        onClickDraft={handleSaveAsDraft}
        handleNavigation={handleNavigation}
        isProposalDetailedView={isProposalDetailedView}
        submitButtonIsLoading={isPublishStatusUpdating}
      />
      <ConfirmationDialog
        title="Remove Cost/Credit"
        width={464}
        centered
        confirmText="Yes - Remove"
        onConfirm={() => {
          handelRemoveAdditionalCredits(removeCreditModel.id);
          setRemoveCreditModel({ id: undefined, visible: false });
        }}
        isCancelLink
        cancelText="Cancel"
        closeModal={() =>
          setRemoveCreditModel({ id: undefined, visible: false })
        }
        visible={removeCreditModel.visible}
      >
        <div className={styles.removeTextWrapper}>
          Are you sure you want to remove this cost/credit from the proposal?
        </div>
      </ConfirmationDialog>
      <ConfirmationDialog
        title="Remove Discount"
        width={464}
        centered
        confirmText="Yes - Remove Discount"
        onConfirm={() => {
          handelRemoveBundleDiscount(removeDiscountModel.id);
          setRemoveDiscountModel({ id: undefined, visible: false });
        }}
        isCancelLink
        cancelText="Cancel"
        closeModal={() =>
          setRemoveDiscountModel({ id: undefined, visible: false })
        }
        visible={removeDiscountModel.visible}
      >
        <div className={styles.removeTextWrapper}>
          Are you sure you want to remove this discount from the proposal?
        </div>
      </ConfirmationDialog>
      <SingleCreditsDiscountsDescriptionModal
        isOpen={isCreditsDescriptionModalOpen}
        setIsOpen={setIsCreditsDescriptionModalOpen}
        data={creditsDescriptionModalData}
      />
    </div>
  );
};

export default AddDiscounts;
