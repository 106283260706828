import WeekTimeConfig from 'model/benefitsConsultation/WeekTimeConfig';

// OnSched API payload expects 0 as start, end time for isOpen unavailable.
export const resetStartEndTimeForUnavailable = (dayConfig: WeekTimeConfig) =>
  Object.values(dayConfig)
    .filter((config) => !config.isOpen)
    .forEach((config) => {
      config.startTime = 0;
      config.endTime = 0;
    });
