import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbProps, notification } from 'antd';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import { useAppDispatch } from 'hooks/redux';
import { PLAN_NOTIFICATION_KEY } from 'constants/benguideCollaborationConstants';
import { ReactComponent as VectorLeft } from 'assets/images/vector-left.svg';
import { clearEmployer } from 'layout/slices/layoutSlice';
import styles from './breadcrumbNavigation.module.less';

interface BreadcrumbNavigationProps extends BreadcrumbProps {
  breadCrumbData?: { name: string; link: string; state?: any }[];
  clearEmployerContext?: boolean;
}

const BreadcrumbNavigation: FC<BreadcrumbNavigationProps> = ({
  breadCrumbData,
  clearEmployerContext,
}) => {
  const [notificationKey] = useState(PLAN_NOTIFICATION_KEY);
  const dispatch = useAppDispatch();

  const closeNotifications = () => {
    notification.close(notificationKey);
    if (clearEmployerContext) {
      dispatch(clearEmployer());
    }
  };
  return (
    <Breadcrumb>
      {breadCrumbData?.map((data, index) => (
        <BreadcrumbItem key={index}>
          <Link
            className={styles.breadCrumbWrapper}
            to={data.link}
            state={data.state}
            onClick={closeNotifications}
          >
            <VectorLeft />
            <span className={styles.breadCumbName}>{data.name}</span>
          </Link>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbNavigation;
