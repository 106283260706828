import { Badge, Row } from 'antd';
import React from 'react';
import {
  COLOR_BADGE_BLUE,
  COLOR_BADGE_RED,
  COLOR_BADGE_YELLOW,
} from 'modules/claims/constants/constants';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import styles from './IssuePriorityBadge.module.less';

type Props = {
  issuePriority: string;
  label: string;
};

const issuePriorityDataSet: any = {
  HIGH: { value: 'HIGH', label: 'HIGH', color: COLOR_BADGE_RED, default: true },
  MEDIUM: {
    value: 'MEDIUM',
    label: 'MEDIUM',
    color: COLOR_BADGE_YELLOW,
    default: true,
  },
  LOW: { value: 'LOW', label: 'LOW', color: COLOR_BADGE_BLUE, default: true },
};

const IssuePriorityBadge = (props: Props) => {
  const { issuePriority } = props;
  const foundPriorityObj = issuePriorityDataSet[issuePriority];

  if (!foundPriorityObj && !props.label) {
    return <div className={styles.noPriorityDiv}></div>;
  }

  const { color } = foundPriorityObj || {};

  return (
    <div>
      <Row className={styles.issueTitle}>
        <OverflowPopover>
          {foundPriorityObj ? (
            <Badge color={color} className={styles.issueBadge} />
          ) : (
            <div className={styles.noPriorityDiv}></div>
          )}
          <span className={styles.label}>{props.label}</span>
        </OverflowPopover>
      </Row>
    </div>
  );
};

export default IssuePriorityBadge;
