import { lumityApi } from 'api/apiSlice';

const employerRTKSlice = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    archiveEmployer: builder.mutation<any, { employerId: string }>({
      query: ({ employerId }) => ({
        url: `v2/employers/archive-employer/${employerId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useArchiveEmployerMutation } = employerRTKSlice;
