import axios from 'axios';
import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as PlanService from 'modules/plans/services/PlanService';
import FileType from 'model/plans/FileType';
import WebLinkType from 'model/plans/WebLinkType';
import ClonePlanDocument from 'model/plans/ClonePlanDocument';

export interface PlanDocumentsState {
  inProgress: boolean;
  error: any;
  requestType: string;
  planDocuments: {};
  documentList: any[];
}

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  planDocuments: {},
  documentList: [],
} as PlanDocumentsState;

const planDocumentsInProgress = (
  state: PlanDocumentsState,
  action: PayloadAction
) => {
  state.inProgress = true;
  state.planDocuments = {};
  state.error = null;
  state.requestType = action.type;
};

const planDocumentsCompleted = (
  state: PlanDocumentsState,
  action: PayloadAction<object>
) => {
  state.inProgress = false;
  state.planDocuments = action.payload;
  state.error = null;
  state.requestType = action.type;
  state.documentList.push(action.payload);
};

const planDocumentsFailed = (
  state: PlanDocumentsState,
  action: PayloadAction
) => {
  state.inProgress = false;
  state.planDocuments = {};
  state.error = { response: action.payload };
  state.requestType = action.type;
};

const planDocumentsSlice = createSlice({
  name: 'planDocuments',
  initialState,
  reducers: {
    planDocumentsUploadInProgress: (state, action: PayloadAction) => {
      planDocumentsInProgress(state, action);
    },
    planDocumentsUploadCompleted: (state, action: PayloadAction<object>) => {
      planDocumentsCompleted(state, action);
    },
    planDocumentsUploadFailed: (state, action) => {
      planDocumentsFailed(state, action);
    },
    planDocumentsUpdateInProgress: (state, action: PayloadAction) => {
      planDocumentsInProgress(state, action);
    },
    planDocumentsUpdateCompleted: (state, action: PayloadAction<object>) => {
      planDocumentsCompleted(state, action);
    },
    planDocumentsUpdateFailed: (state, action) => {
      planDocumentsFailed(state, action);
    },
    planDocumentsCloneInProgress: (state, action: PayloadAction) => {
      planDocumentsInProgress(state, action);
    },
    planDocumentsCloneCompleted: (state, action: PayloadAction<object>) => {
      planDocumentsCompleted(state, action);
    },
    planDocumentsCloneFailed: (state, action) => {
      planDocumentsFailed(state, action);
    },
  },
});

export const {
  planDocumentsUploadInProgress,
  planDocumentsUploadCompleted,
  planDocumentsUploadFailed,
  planDocumentsUpdateInProgress,
  planDocumentsUpdateCompleted,
  planDocumentsUpdateFailed,
  planDocumentsCloneInProgress,
  planDocumentsCloneCompleted,
  planDocumentsCloneFailed,
} = planDocumentsSlice.actions;

export default planDocumentsSlice.reducer;

export const removePlanDocuments = (
  planId: string,
  benefitKind: string,
  documentTypeList: { docType: string; planDocumentName: string }[]
) => {
  return async (dispatch: Dispatch) => {
    const removeDocs = async () => {
      for (const element of documentTypeList) {
        dispatch(planDocumentsUpdateInProgress());
        const response = await PlanService.updatePlanDocuments(
          planId,
          benefitKind,
          element.docType,
          element.planDocumentName
        );
        dispatch(planDocumentsUpdateCompleted(response.data));
      }
    };
    return removeDocs();
  };
};

export const updateWeblinks = (
  weblinkList: WebLinkType[],
  planId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(planDocumentsUpdateInProgress());
    try {
      const response = await PlanService.updateWeblinks(
        planId,
        benefitKind,
        weblinkList
      );
      dispatch(planDocumentsUpdateCompleted(response.data));
      return response;
    } catch (error) {
      dispatch(planDocumentsUpdateFailed(error));
      throw error;
    }
  };
};

export const uploadPlanDocument = (
  documentList: FileType[],
  planId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    const docUpload = async () => {
      for (const element of documentList) {
        dispatch(planDocumentsUploadInProgress());
        const response = await PlanService.editUploadPlanDocuments(
          element.file,
          element.file.name,
          planId,
          benefitKind,
          element.docType,
          element.planDocumentName
        );
        dispatch(planDocumentsUploadCompleted(response.data));
      }
    };

    return docUpload();
  };
};

export const uploadRemovePlanDocument = (
  documentList: FileType[],
  planId: string,
  benefitKind: string,
  removedDocList: { docType: string; planDocumentName: string }[]
) => {
  return async (dispatch: Dispatch) => {
    const removePlanDocs = async () => {
      for (const removeDocType of removedDocList) {
        dispatch(planDocumentsUploadInProgress());
        const removeResponse = await PlanService.updatePlanDocuments(
          planId,
          benefitKind,
          removeDocType.docType,
          removeDocType.planDocumentName
        );
        dispatch(planDocumentsUploadCompleted(removeResponse.data));
        if (
          removeResponse.status === 200 &&
          removeDocType === removedDocList[removedDocList.length - 1]
        ) {
          const planDocumentUpload = async () => {
            for (const uploadDoc of documentList) {
              dispatch(planDocumentsUpdateInProgress());
              const uploadResponse = await PlanService.editUploadPlanDocuments(
                uploadDoc.file,
                uploadDoc.file.name,
                planId,
                benefitKind,
                uploadDoc.docType,
                uploadDoc.planDocumentName
              );
              dispatch(planDocumentsUpdateCompleted(uploadResponse.data));
            }
          };
          return planDocumentUpload();
        }
      }
    };
    return removePlanDocs();
  };
};

export const clonePlanDocuments: any = (req: ClonePlanDocument) => {
  return async (dispatch: Dispatch) => {
    dispatch(planDocumentsCloneInProgress());
    try {
      const response = await PlanService.clonePlanDocuments(req);
      dispatch(planDocumentsCloneCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          planDocumentsCloneFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};
