import { FC, useEffect, useState } from 'react';
import { Alert, Form, Spin } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';

import alertError from 'assets/images/alert-error.svg';
import AuthContainer from 'containers/AuthContainer/AuthContainer';
import AlertMessage from 'components/Alert/AlertMessage';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import AuthHeading from 'modules/auth/components/AuthHeading/AuthHeading';
import CreatePasswordForm from 'modules/auth/components/CreatePasswordForm/CreatePasswordForm';
import {
  createNewPassword,
  createPwdSuccess,
  validatePwdResetToken,
  validateResetTokenFailed,
  validateResetTokenStarted,
} from 'modules/auth/slices/createPwdSlice';
import {
  CREATE_PASSWORD_ERROR_MSG,
  PASSWORD_VALIDATION_ERROR_MSG,
  SET_PASSWORD_ERROR_MESSAGES,
} from 'modules/auth/constants/authConstants';
import { LOGIN_PATH } from 'modules/auth/routes';
import { validatePasswordComplexity } from 'util/passwordUtil';

import styles from './createPassword.module.less';

const CreatePassword: FC = () => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const [isTokenValid, setIsTokenValid] = useState(true);

  const [params] = useSearchParams();

  const createPwdState = useAppSelector((state) => state.auth.createPwd);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const token = params.get('token');

  useEffect(() => {
    if (!token) {
      navigate('/');
      return;
    }
    dispatch(validatePwdResetToken(token));
  }, [token, dispatch, navigate]);

  useEffect(() => {
    if (
      !createPwdState.inProgress &&
      createPwdState.error &&
      createPwdState.requestType === validateResetTokenFailed.type
    ) {
      setIsTokenValid(false);
    }
  }, [
    createPwdState.inProgress,
    createPwdState.error,
    createPwdState.requestType,
  ]);

  // navigate to login after successful sign up
  useEffect(() => {
    if (
      !createPwdState.inProgress &&
      createPwdState.requestType === createPwdSuccess.type
    ) {
      navigate(LOGIN_PATH, {
        state: {
          isPasswordReset: true,
        },
        replace: true,
      });
    }
  }, [createPwdState.inProgress, createPwdState.requestType, navigate]);

  // handle api errors
  useEffect(() => {
    if (!createPwdState.inProgress && createPwdState.error) {
      if (
        Object.keys(SET_PASSWORD_ERROR_MESSAGES).includes(
          createPwdState.error.data.code
        )
      ) {
        setErrorMessage(
          SET_PASSWORD_ERROR_MESSAGES[createPwdState.error.data.code]
        );
        setIsAlertVisible(true);
      }
    }
  }, [createPwdState.inProgress, createPwdState.error]);

  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    setIsSubmitClicked(true);
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }

    const { password } = values || {};

    const passwordValid = validatePasswordComplexity(password);

    if (!passwordValid) {
      setIsAlertVisible(true);
      setErrorMessage(PASSWORD_VALIDATION_ERROR_MSG);
      return;
    }

    if (token) {
      dispatch(createNewPassword(token, password));
    }
  };

  const handleSubmitFailed = () => {
    setIsSubmitClicked(true);
    setErrorMessage(CREATE_PASSWORD_ERROR_MSG);
    setIsAlertVisible(true);
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    form.setFieldsValue({ ...allValues });
    if (isSubmitClicked) {
      setIsSubmitClicked(false);
      setIsAlertVisible(false);
    }
  };

  if (
    createPwdState.inProgress &&
    createPwdState.requestType === validateResetTokenStarted.type
  ) {
    return (
      <AuthContainer>
        <Spin />
      </AuthContainer>
    );
  }

  return (
    <AuthContainer>
      <div className={styles.container}>
        {isAlertVisible && isTokenValid && (
          <AlertMessage
            wrapperClassName="authAlertWrapper"
            type="error"
            message={errorMessage}
            closeAlert={() => setIsAlertVisible(false)}
          />
        )}
        <AuthHeading header="Create New Password" />
        {!isTokenValid ? (
          <div className={styles.tokenError}>
            {/* TODO: change once design is updated */}
            <Alert
              className={styles.errorAlert}
              type="error"
              icon={<img src={alertError} alt="error icon" />}
              showIcon
              message="Invalid Link"
              description="The Link is either invalid or expired. Please contact support."
            />
          </div>
        ) : (
          <CreatePasswordForm
            form={form}
            onSubmit={handleSubmit}
            onSubmitFailed={handleSubmitFailed}
            onValuesChange={handleValuesChange}
            loading={createPwdState.inProgress}
            submitButtonText="Set New Password"
            passwordLabel="New Password"
          />
        )}
      </div>
    </AuthContainer>
  );
};

export default CreatePassword;
