import { FC, useState } from 'react';
import { Row, Col, Input, Form } from 'antd';
import { useStateCallback } from 'hooks/updateState';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

import iconRemove from 'assets/images/icon-remove-red.svg';
import iconWarning from 'assets/images/icon-warning.svg';
import { ReactComponent as AIFeedbackRedFlag } from 'assets/images/ai-red-flag.svg';

import { isNullOrUndefined } from 'modules/plans/utils';
import styles from './lifeServiceRow.module.less';

type LifeServiceRowProps = {
  service?: string;
  title: string;
  isDefault: boolean;
  value: any;
  onOkRemove: () => void;
  onChangeFieldValue: Function;
  isSystemPlan?: boolean;
  isLLMDifferent?: boolean;
};

const LifeServiceRow: FC<LifeServiceRowProps> = (
  props: LifeServiceRowProps
) => {
  const {
    isDefault,
    title,
    value,
    onOkRemove,
    onChangeFieldValue,
    service,
    isSystemPlan,
    isLLMDifferent = false,
  } = props;

  const [visible, setVisible] = useStateCallback(false);
  const [serviceValue, setServiceValue] = useState<string>(value ?? '');

  const onCancel = () => {
    setVisible(false);
  };

  const onConfirmClick = () => {
    setVisible(false, () => {
      onOkRemove();
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setServiceValue(newValue);
    if (!isNullOrUndefined(service)) {
      onChangeFieldValue(service, newValue);
    }
  };

  return (
    <div className={styles.tableSelector}>
      <Row gutter={6} className={styles.inputRowWrapper}>
        <Col>
          {!isDefault && (
            <ConfirmationModal
              className={styles.confirmationModalPopup}
              onVisibleChange={() => {
                !visible && setVisible(true);
              }}
              title="Are you sure you want to remove this service?"
              okText="Yes, remove service"
              icon={
                <img
                  src={iconWarning}
                  alt="warning-icon"
                  className={styles.iconWarning}
                />
              }
              confirmModalTrigger={
                <img
                  src={iconRemove}
                  alt="remove-service-icon"
                  className={styles.iconRemove}
                />
              }
              visible={visible}
              onConfirm={onConfirmClick}
              onCancel={onCancel}
              placement="topLeft"
            />
          )}
        </Col>
        {isSystemPlan ? (
          <Col span={22}>
            <Form.Item
              label={<span className={styles.labelWithLineBreak}>{title}</span>}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              <Input value={serviceValue} onChange={handleInputChange} />
            </Form.Item>
          </Col>
        ) : (
          <Col flex="auto" className={styles.quotesInputWrapper}>
            <div>{title}</div>
            <div
              className={`${isLLMDifferent ? styles.llmDifferentWrapper : ''}`}
            >
              <Input value={serviceValue} onChange={handleInputChange} />

              {isLLMDifferent && (
                <AIFeedbackRedFlag className={styles.aiFeedbackFlag} />
              )}
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default LifeServiceRow;
