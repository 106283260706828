import { Skeleton, Image } from 'antd';

import { loginTypes } from 'constants/authConstants';
import PlanYearLogo from 'assets/images/navigation/PlanYearLogo.svg';
import Broker from 'model/Broker';

import styles from './headerLogo.module.less';

type HeaderLogoProps = {
  loginType: string;
  broker?: Broker | null;
};

const headerSkelton = (
  <div className={styles.headerSkelton}>
    <Skeleton.Input style={{ width: 200 }} />
  </div>
);

const HeaderLogo = ({ loginType, broker }: HeaderLogoProps) => {
  if (loginType === loginTypes.platform) {
    return <img src={PlanYearLogo} alt="Tech Admin" />;
  } else if (broker) {
    return broker.logoUrl ? (
      <div className={styles.headerLogo}>
        <Image
          height={42}
          src={broker.logoUrl}
          preview={false}
          placeholder={headerSkelton}
        />
      </div>
    ) : (
      <div>{broker.name}</div>
    );
  } else {
    return headerSkelton;
  }
};
export default HeaderLogo;
