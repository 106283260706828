import axios from 'axios';
import moment from 'moment';
import { lumityApi } from 'api/apiSlice';
import { BenefitCategory } from 'constants/commonConstants';
import ProposalListItemVO from 'model/ProposalListItemVO';
import UpcomingPlanYearDto from 'model/UpcomingPlanYearDto';
import { OFFER_CARD_STATUS } from 'modules/renewals/constants/renewalsConstants';
import { RenewalPlanDto } from 'modules/renewals/models/RenewalPlanDto';
import { CurrentSystemPlansRequestDTO } from 'modules/renewals/models/CurrentSystemPlansRequestDto';
import { CONTENT_TYPE_MULTIPART_FORM, baseApi } from 'util/apiUtil';
import CurrentPlanEnrollmentsDto from 'modules/renewals/models/CurrentPlanEnrollmentsDto';
import { RGBAModel } from 'util/exportStylingUtil';

const RENEWAL_UPCOMING_API_PREFIX = 'v1/renewals/upcoming-plan-years';
const RENEWAL_PLAN_API_PREFIX = 'v1/plans/renewals';
const EMPLOYERS_API_PREFIX = 'v1/employers';

const renewalsApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getUpcomingPlanYearsByEmployer: builder.query<
      UpcomingPlanYearDto,
      { employerId: string }
    >({
      query: (args) => {
        const { employerId } = args;
        return {
          url: `${RENEWAL_UPCOMING_API_PREFIX}/employers/${employerId}`,
          method: 'GET',
        };
      },
    }),
    createUpcomingPlanYear: builder.mutation<
      any,
      { upcomingPlanYear: UpcomingPlanYearDto }
    >({
      query: (data) => {
        return {
          url: `${RENEWAL_UPCOMING_API_PREFIX}`,
          method: 'POST',
          data: data.upcomingPlanYear,
        };
      },
    }),
    createPlansWithPlanYear: builder.mutation<
      any,
      { renewalPlanList: RenewalPlanDto }
    >({
      query: (data) => {
        return {
          url: `${RENEWAL_PLAN_API_PREFIX}`,
          method: 'POST',
          data: data.renewalPlanList,
        };
      },
    }),
    getCarrierOfferSummary: builder.query<
      any,
      {
        employerId: string;
        allDates: boolean;
        fromDate: string | null;
        toDate: string | null;
        planYearId?: string | null;
      }
    >({
      query: (args) => {
        const { employerId, allDates, fromDate, toDate, planYearId } = args;
        return {
          url: `v1/renewals/offers/carrier-summary`,
          method: 'GET',
          params: {
            'employer-id': employerId,
            'all-dates': allDates,
            'from-date': fromDate,
            'to-date': toDate,
            'plan-year-id': planYearId,
          },
        };
      },
    }),
    getCarrierMedicalPublishedOffers: builder.query<
      any,
      {
        employerId: string;
        fromEffectiveDate: string | null;
        toEffectiveDate: string | null;
        planYearId?: string | null;
        // this was added when we create a plan year and finalize it we must fetch  versioned offers when going through history
        isVersioned?: boolean;
      }
    >({
      query: (args) => {
        const {
          employerId,
          fromEffectiveDate,
          toEffectiveDate,
          planYearId,
          isVersioned = true,
        } = args;
        return {
          url: `v1/renewals/offers`,
          method: 'GET',
          params: {
            employerId: employerId,
            categories: BenefitCategory.MEDICAL.value,
            statuses: getApplicableStatus(isVersioned),
            types: `${'RENEWAL'}, ${'ALTERNATIVE'}`,
            fromEffectiveDate: fromEffectiveDate,
            toEffectiveDate: toEffectiveDate,
            planYearId,
          },
        };
      },
    }),
    getCarrierMedicalDraftOffers: builder.query<
      any,
      {
        employerId: string;
        fromEffectiveDate: string | null;
        toEffectiveDate: string | null;
        planYearId?: string | null;
      }
    >({
      query: (args) => {
        const { employerId, fromEffectiveDate, toEffectiveDate, planYearId } =
          args;
        return {
          url: `v1/renewals/offers`,
          method: 'GET',
          params: {
            employerId: employerId,
            categories: BenefitCategory.MEDICAL.value,
            statuses: OFFER_CARD_STATUS.IN_PROGRESS,
            types: `${'RENEWAL'}, ${'ALTERNATIVE'}`,
            fromEffectiveDate: fromEffectiveDate,
            toEffectiveDate: toEffectiveDate,
            planYearId,
          },
        };
      },
    }),
    getCarrierDentalPublishedOffers: builder.query<
      any,
      {
        employerId: string;
        fromEffectiveDate: string | null;
        toEffectiveDate: string | null;
        planYearId?: string | null;
        isVersioned?: boolean;
      }
    >({
      query: (args) => {
        const {
          employerId,
          fromEffectiveDate,
          toEffectiveDate,
          planYearId,
          isVersioned = true,
        } = args;
        return {
          url: `v1/renewals/offers`,
          method: 'GET',
          params: {
            employerId: employerId,
            categories: BenefitCategory.DENTAL.value,
            statuses: getApplicableStatus(isVersioned),
            types: `${'RENEWAL'}, ${'ALTERNATIVE'}`,
            fromEffectiveDate: fromEffectiveDate,
            toEffectiveDate: toEffectiveDate,
            planYearId,
          },
        };
      },
    }),
    getCarrierDentalDraftOffers: builder.query<
      any,
      {
        employerId: string;
        fromEffectiveDate: string | null;
        toEffectiveDate: string | null;
        planYearId?: string | null;
      }
    >({
      query: (args) => {
        const { employerId, fromEffectiveDate, toEffectiveDate, planYearId } =
          args;
        return {
          url: `v1/renewals/offers`,
          method: 'GET',
          params: {
            employerId: employerId,
            categories: BenefitCategory.DENTAL.value,
            statuses: OFFER_CARD_STATUS.IN_PROGRESS,
            types: `${'RENEWAL'}, ${'ALTERNATIVE'}`,
            fromEffectiveDate: fromEffectiveDate,
            toEffectiveDate: toEffectiveDate,
            planYearId,
          },
        };
      },
    }),
    getCarrierVisionPublishedOffers: builder.query<
      any,
      {
        employerId: string;
        fromEffectiveDate: string | null;
        toEffectiveDate: string | null;
        planYearId?: string | null;
        isVersioned?: boolean;
      }
    >({
      query: (args) => {
        const {
          employerId,
          fromEffectiveDate,
          toEffectiveDate,
          planYearId,
          isVersioned = true,
        } = args;
        return {
          url: `v1/renewals/offers`,
          method: 'GET',
          params: {
            employerId: employerId,
            categories: BenefitCategory.VISION.value,
            statuses: getApplicableStatus(isVersioned),
            types: `${'RENEWAL'}, ${'ALTERNATIVE'}`,
            fromEffectiveDate: fromEffectiveDate,
            toEffectiveDate: toEffectiveDate,
            planYearId,
          },
        };
      },
    }),
    getCarrierVisionDraftOffers: builder.query<
      any,
      {
        employerId: string;
        fromEffectiveDate: string | null;
        toEffectiveDate: string | null;
        planYearId?: string | null;
      }
    >({
      query: (args) => {
        const { employerId, fromEffectiveDate, toEffectiveDate, planYearId } =
          args;
        return {
          url: `v1/renewals/offers`,
          method: 'GET',
          params: {
            employerId: employerId,
            categories: BenefitCategory.VISION.value,
            statuses: OFFER_CARD_STATUS.IN_PROGRESS,
            types: `${'RENEWAL'}, ${'ALTERNATIVE'}`,
            fromEffectiveDate: fromEffectiveDate,
            toEffectiveDate: toEffectiveDate,
            planYearId,
          },
        };
      },
    }),
    getCarrierLifePublishedOffers: builder.query<
      any,
      {
        employerId: string;
        fromEffectiveDate: string | null;
        toEffectiveDate: string | null;
        planYearId?: string | null;
        isVersioned?: boolean;
      }
    >({
      query: (args) => {
        const {
          employerId,
          fromEffectiveDate,
          toEffectiveDate,
          planYearId,
          isVersioned = true,
        } = args;
        return {
          url: `v1/renewals/offers`,
          method: 'GET',
          params: {
            employerId: employerId,
            categories: BenefitCategory.LIFE.value,
            statuses: getApplicableStatus(isVersioned),
            types: `${'RENEWAL'}, ${'ALTERNATIVE'}`,
            fromEffectiveDate: fromEffectiveDate,
            toEffectiveDate: toEffectiveDate,
            planYearId,
          },
        };
      },
    }),
    getCarrierLifeDraftOffers: builder.query<
      any,
      {
        employerId: string;
        fromEffectiveDate: string | null;
        toEffectiveDate: string | null;
        planYearId?: string | null;
      }
    >({
      query: (args) => {
        const { employerId, fromEffectiveDate, toEffectiveDate, planYearId } =
          args;
        return {
          url: `v1/renewals/offers`,
          method: 'GET',
          params: {
            employerId: employerId,
            categories: BenefitCategory.LIFE.value,
            statuses: OFFER_CARD_STATUS.IN_PROGRESS,
            types: `${'RENEWAL'}, ${'ALTERNATIVE'}`,
            fromEffectiveDate: fromEffectiveDate,
            toEffectiveDate: toEffectiveDate,
            planYearId,
          },
        };
      },
    }),
    getCarrierStdPublishedOffers: builder.query<
      any,
      {
        employerId: string;
        fromEffectiveDate: string | null;
        toEffectiveDate: string | null;
        planYearId?: string | null;
        isVersioned?: boolean;
      }
    >({
      query: (args) => {
        const {
          employerId,
          fromEffectiveDate,
          toEffectiveDate,
          planYearId,
          isVersioned = true,
        } = args;
        return {
          url: `v1/renewals/offers`,
          method: 'GET',
          params: {
            employerId: employerId,
            categories: 'STD',
            statuses: getApplicableStatus(isVersioned),
            types: `${'RENEWAL'}, ${'ALTERNATIVE'}`,
            fromEffectiveDate: fromEffectiveDate,
            toEffectiveDate: toEffectiveDate,
            planYearId,
          },
        };
      },
    }),
    getCarrierStdDraftOffers: builder.query<
      any,
      {
        employerId: string;
        fromEffectiveDate: string | null;
        toEffectiveDate: string | null;
        planYearId?: string | null;
      }
    >({
      query: (args) => {
        const { employerId, fromEffectiveDate, toEffectiveDate, planYearId } =
          args;
        return {
          url: `v1/renewals/offers`,
          method: 'GET',
          params: {
            employerId: employerId,
            categories: 'STD',
            statuses: OFFER_CARD_STATUS.IN_PROGRESS,
            types: `${'RENEWAL'}, ${'ALTERNATIVE'}`,
            fromEffectiveDate: fromEffectiveDate,
            toEffectiveDate: toEffectiveDate,
            planYearId,
          },
        };
      },
    }),
    getCarrierLtdPublishedOffers: builder.query<
      any,
      {
        employerId: string;
        fromEffectiveDate: string | null;
        toEffectiveDate: string | null;
        planYearId?: string | null;
        isVersioned?: boolean;
      }
    >({
      query: (args) => {
        const {
          employerId,
          fromEffectiveDate,
          toEffectiveDate,
          planYearId,
          isVersioned = true,
        } = args;
        return {
          url: `v1/renewals/offers`,
          method: 'GET',
          params: {
            employerId: employerId,
            categories: 'LTD',
            statuses: getApplicableStatus(isVersioned),
            types: `${'RENEWAL'}, ${'ALTERNATIVE'}`,
            fromEffectiveDate: fromEffectiveDate,
            toEffectiveDate: toEffectiveDate,
            planYearId,
          },
        };
      },
    }),
    getCarrierLtdDraftOffers: builder.query<
      any,
      {
        employerId: string;
        fromEffectiveDate: string | null;
        toEffectiveDate: string | null;
        planYearId?: string | null;
      }
    >({
      query: (args) => {
        const { employerId, fromEffectiveDate, toEffectiveDate, planYearId } =
          args;
        return {
          url: `v1/renewals/offers`,
          method: 'GET',
          params: {
            employerId: employerId,
            categories: 'LTD',
            statuses: OFFER_CARD_STATUS.IN_PROGRESS,
            types: `${'RENEWAL'}, ${'ALTERNATIVE'}`,
            fromEffectiveDate: fromEffectiveDate,
            toEffectiveDate: toEffectiveDate,
            planYearId,
          },
        };
      },
    }),
    getCarrierVoluntaryBenefitPublishedOffers: builder.query<
      any,
      {
        employerId: string;
        fromEffectiveDate: string | null;
        toEffectiveDate: string | null;
        planYearId?: string | null;
        isVersioned?: boolean;
      }
    >({
      query: (args) => {
        const {
          employerId,
          fromEffectiveDate,
          toEffectiveDate,
          planYearId,
          isVersioned = true,
        } = args;
        return {
          url: `v1/renewals/offers`,
          method: 'GET',
          params: {
            employerId: employerId,
            categories: BenefitCategory.VOLUNTARY_BENEFIT.value,
            statuses: getApplicableStatus(isVersioned),
            types: `${'RENEWAL'}, ${'ALTERNATIVE'}`,
            fromEffectiveDate: fromEffectiveDate,
            toEffectiveDate: toEffectiveDate,
            planYearId,
          },
        };
      },
    }),
    getCarrierVoluntaryBenefitDraftOffers: builder.query<
      any,
      {
        employerId: string;
        fromEffectiveDate: string | null;
        toEffectiveDate: string | null;
        planYearId?: string | null;
      }
    >({
      query: (args) => {
        const { employerId, fromEffectiveDate, toEffectiveDate, planYearId } =
          args;
        return {
          url: `v1/renewals/offers`,
          method: 'GET',
          params: {
            employerId: employerId,
            categories: BenefitCategory.VOLUNTARY_BENEFIT.value,
            statuses: OFFER_CARD_STATUS.IN_PROGRESS,
            types: `${'RENEWAL'}, ${'ALTERNATIVE'}`,
            fromEffectiveDate: fromEffectiveDate,
            toEffectiveDate: toEffectiveDate,
            planYearId,
          },
        };
      },
    }),
    getProposals: builder.query<
      ProposalListItemVO[],
      {
        employerId: string;
        effectiveDateId?: string;
        planYearId?: string | null;
      }
    >({
      query: ({ employerId, effectiveDateId, planYearId }) => ({
        url: `v1/renewals/offer-proposals`,
        method: 'GET',
        params: { employerId, effectiveDateId, planYearId },
      }),
    }),
    getDetailedOfferExport: builder.query<
      any,
      { employerId: string; upcomingPlanYearId: string; zone: string }
    >({
      query: ({ employerId, upcomingPlanYearId, zone }) => ({
        url: `v1/renewals/exports/detailed-offers`,
        method: 'GET',
        params: { employerId, upcomingPlanYearId, zone },
      }),
    }),
    getAfpPlanYears: builder.query<any, { employerId: string }>({
      query: ({ employerId }) => ({
        url: `v1/employers/${employerId}/plan-years/afp`,
        method: 'GET',
      }),
    }),
    modifyCarrierOfferDueDate: builder.mutation<
      any,
      { employerId: string; dueDate: any; planYearId: string | null }
    >({
      query: ({ employerId, dueDate, planYearId }) => {
        return {
          url: `v1/renewals/offers/offer-due-date/${employerId}`,
          method: 'PUT',
          data: {
            carrierOfferDueDate: dueDate,
          },
          params: {
            'plan-year-id': planYearId,
          },
        };
      },
    }),
    submitCurrentPlans: builder.mutation<any, CurrentSystemPlansRequestDTO>({
      query: (request) => {
        return {
          url: `v1/renewals/offers/create-from-system-plans`,
          method: 'POST',
          data: request,
        };
      },
    }),
    deleteOfferDocuments: builder.mutation<any, { references: string[] }>({
      query: ({ references }) => {
        return {
          url: `v1/renewals/offers/temp-document`,
          method: 'PUT',
          data: {
            references,
          },
        };
      },
    }),
    getUploadFileReference: builder.mutation<
      { referenceId: string; fileName: string },
      { uploadedFile: any; employerId: string }
    >({
      query: (data) => {
        const { uploadedFile, employerId } = data;
        const formData = new FormData();
        formData.append('file', uploadedFile);
        return {
          url: `v1/renewals/offers/temp-document`,
          method: 'POST',
          headers: {
            'Content-Type': CONTENT_TYPE_MULTIPART_FORM,
          },
          data: formData,
          params: {
            'employer-id': employerId,
          },
        };
      },
    }),
    getCurrentPlanEnrollments: builder.query<
      CurrentPlanEnrollmentsDto,
      { employerId: string }
    >({
      query: ({ employerId }) => ({
        url: `v1/renewals/offers/current-plans/enrollments`,
        method: 'GET',
        params: {
          'employer-id': employerId,
        },
      }),
    }),
    editCurrentPlanEnrollments: builder.mutation<
      void,
      CurrentSystemPlansRequestDTO
    >({
      query: ({
        employerId,
        benefitPlans,
        medicalCurrentPlanType,
        dentalCurrentPlanType,
        visionCurrentPlanType,
      }) => ({
        url: `v1/renewals/offers/create-from-system-plans`,
        method: 'PUT',
        params: {
          'employer-id': employerId,
        },
        data: {
          benefitPlans,
          medicalCurrentPlanType,
          dentalCurrentPlanType,
          visionCurrentPlanType,
        },
      }),
    }),
    editUpcomingPlanYears: builder.mutation<void, UpcomingPlanYearDto>({
      query: ({ employerId, upcomingPlanYears }) => ({
        url: `${RENEWAL_UPCOMING_API_PREFIX}`,
        method: 'PUT',
        data: { employerId, upcomingPlanYears },
      }),
    }),
    getOffersExportFilters: builder.query<
      any,
      {
        employerId: string;
        upcomingPlanYearId: string | undefined | null;
        planYearId: string | undefined | null;
      }
    >({
      query: (args) => {
        const { employerId, upcomingPlanYearId, planYearId } = args;
        return {
          url: `v1/renewals/offers/export-filters?employer-id=${employerId}`,
          method: 'GET',
          params: {
            'upcoming-plan-year-id':
              upcomingPlanYearId === undefined || upcomingPlanYearId === 'all'
                ? undefined
                : upcomingPlanYearId,
            'plan-year-id': planYearId,
          },
        };
      },
    }),
    getPublishedOffers: builder.query<
      any,
      {
        employerId: string;
        planYearId?: string | null;
        isDraft: boolean;
      }
    >({
      query: (args) => {
        const { employerId, planYearId, isDraft } = args;
        return {
          url: `v1/renewals/offers/published-offers`,
          method: 'GET',
          params: {
            'employer-id': employerId,
            'plan-year-id': planYearId,
            'is-draft-include': isDraft,
          },
        };
      },
    }),
    getPlanYears: builder.query<any, { employerId: string }>({
      query: ({ employerId }) => ({
        url: `${EMPLOYERS_API_PREFIX}/${employerId}/plan-years`,
        method: 'GET',
      }),
    }),

    getLatestPlanYear: builder.query<any, { employerId: string }>({
      query: ({ employerId }) => ({
        url: `${EMPLOYERS_API_PREFIX}/${employerId}/plan-years/latest`,
        method: 'GET',
      }),
    }),

    validatePlanYear: builder.query<
      any,
      { employerId: string; upcomingPlanYearId: string }
    >({
      query: ({ employerId, upcomingPlanYearId }) => ({
        url: `${EMPLOYERS_API_PREFIX}/${employerId}/validate`,
        method: 'GET',
        params: {
          'upcoming-plan-year-id': upcomingPlanYearId,
        },
      }),
    }),

    closeRenewal: builder.query<
      any,
      { planYearId: string; employerId: string }
    >({
      query: ({ planYearId, employerId }) => ({
        url: `${EMPLOYERS_API_PREFIX}/close/${planYearId}`,
        method: 'PUT',
        params: {
          employerId: employerId,
        },
      }),
    }),
  }),
});

export const exportOfferSummary = async (
  employerId: string | undefined,
  upcomingPlanYearId: string | undefined | null,
  benefitType: string[],
  offerIds: string[],
  employerName: string,
  selectedFont: string,
  tableHeaderColor: RGBAModel,
  tableHeaderFontColor: RGBAModel
) => {
  try {
    const response = await axios.post(
      `${baseApi}/v1/renewals/exports/offer-summary`,
      {
        employerId: employerId,
        upcomingPlanYearId:
          upcomingPlanYearId === 'all' ? undefined : upcomingPlanYearId,
        benefitCategories: benefitType,
        offerIds: offerIds,
        selectedFont,
        tableHeaderColor,
        tableHeaderFontColor,
      },
      {
        responseType: 'blob',
      }
    );
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Export_offers_${employerName}_${moment().format('MM-DD-YYYY')}.xlsx`
    );
    document.body && document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (e) {
    if (e && (e as any).response?.status === 500) {
      let errorMessage =
        'Oops! An unexpected error occurred. Please try again.';
      try {
        const errorData = await new Response((e as any).response.data).json();
        if (errorData?.code === 'no.data.to.generate.offer.summary') {
          errorMessage =
            'The file you are looking for is empty. Please update filters and try again.';
        }
      } catch (jsonError) {
        console.warn('Error parsing JSON response:', jsonError);
      }
      throw new Error(errorMessage);
    } else {
      console.error(e);
      const errorMessage =
        'Oops! An unexpected error occurred. Please try again.';
      throw new Error(errorMessage);
    }
  }
};

const getApplicableStatus = (isRevisioned: boolean) => {
  return isRevisioned
    ? OFFER_CARD_STATUS.PUBLISHED
    : `${OFFER_CARD_STATUS.PUBLISHED}, ${OFFER_CARD_STATUS.VERSIONED}`;
};
export const {
  useLazyGetUpcomingPlanYearsByEmployerQuery,
  useLazyGetAfpPlanYearsQuery,
  useCreateUpcomingPlanYearMutation,
  useCreatePlansWithPlanYearMutation,
  useLazyGetCarrierOfferSummaryQuery,
  useLazyGetCarrierMedicalPublishedOffersQuery,
  useLazyGetCarrierMedicalDraftOffersQuery,
  useLazyGetCarrierDentalPublishedOffersQuery,
  useLazyGetCarrierDentalDraftOffersQuery,
  useLazyGetCarrierVisionPublishedOffersQuery,
  useLazyGetCarrierVisionDraftOffersQuery,
  useLazyGetCarrierLifePublishedOffersQuery,
  useLazyGetCarrierLifeDraftOffersQuery,
  useLazyGetCarrierStdPublishedOffersQuery,
  useLazyGetCarrierStdDraftOffersQuery,
  useLazyGetCarrierLtdPublishedOffersQuery,
  useLazyGetCarrierLtdDraftOffersQuery,
  useLazyGetCarrierVoluntaryBenefitPublishedOffersQuery,
  useLazyGetCarrierVoluntaryBenefitDraftOffersQuery,
  useLazyGetProposalsQuery,
  useLazyGetDetailedOfferExportQuery,
  useModifyCarrierOfferDueDateMutation,
  useSubmitCurrentPlansMutation,
  useGetUploadFileReferenceMutation,
  useDeleteOfferDocumentsMutation,
  useLazyGetOffersExportFiltersQuery,
  useLazyGetCurrentPlanEnrollmentsQuery,
  useEditCurrentPlanEnrollmentsMutation,
  useEditUpcomingPlanYearsMutation,
  useLazyGetPublishedOffersQuery,
  useLazyGetPlanYearsQuery,
  useLazyValidatePlanYearQuery,
  useLazyCloseRenewalQuery,
  useLazyGetLatestPlanYearQuery,
} = renewalsApi;
