import { FC } from 'react';
import { Row } from 'antd';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { ReactComponent as NoteBook } from 'assets/images/claims-empty.svg';
import ClaimsView from 'modules/claims/pages/ClaimsView';

import { IndividualSubTypes } from 'constants/commonConstants';
import { useAppSelector } from 'hooks/redux';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import styles from './noClaimsView.module.less';

type NoClaimsViewProps = {
  className: string;
  primaryButtonAction: () => void;
};

const NoClaimsView: FC<NoClaimsViewProps> = (props) => {
  const { primaryButtonAction } = props;

  const individualSubType = useAppSelector(
    (state) => state?.auth?.auth?.appBootupInfo?.individualSubType
  );

  return (
    <>
      <div className={styles.noClaimsPreviewContent}>
        <ClaimsView preview={false} displayDummyData={true} />
      </div>
      <div className={styles.noUploadContent}>
        <div className={styles.noClaimsView}>
          <div className={styles.noClaimsViewWrapper}>
            <Row className={styles.description}>
              <NoteBook />
            </Row>
            <Row className={styles.description}>
              <h2>You don’t have any Claims Data</h2>
            </Row>
            <Row className={styles.description}>
              <p>Start by importing claims data below:</p>
            </Row>
            <Row className={styles.description}>
              {individualSubType === IndividualSubTypes.EMPLOYER_MEMBER ? (
                <PlanyearPopover content={'Only admins may import claims data'}>
                  <PageActionButton
                    type="primary"
                    className={styles.uploadClaimsButtonDisabled}
                  >
                    Import Claims
                  </PageActionButton>
                </PlanyearPopover>
              ) : (
                <PageActionButton
                  type="primary"
                  onClick={primaryButtonAction}
                  className={styles.uploadClaimsButton}
                >
                  Import Claims
                </PageActionButton>
              )}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoClaimsView;
