import { FC } from 'react';

import MedicalPlan from 'model/plans/MedicalPlan';
import DeductiblesOOPMax from 'modules/plans/components/DeductiblesOOPMax/DeductiblesOOPMax';

type DeductiblesOOPMaxUPWrapperProps = {
  medicalPlan: MedicalPlan;
  setMedicalPlan: Function;
};

const DeductiblesOOPMaxUPWrapper: FC<DeductiblesOOPMaxUPWrapperProps> = (
  props: DeductiblesOOPMaxUPWrapperProps
) => {
  const { medicalPlan, setMedicalPlan } = props;

  return (
    <DeductiblesOOPMax
      onChange={setMedicalPlan}
      medicalPlan={medicalPlan}
      isTouched={false}
    />
  );
};

export default DeductiblesOOPMaxUPWrapper;
