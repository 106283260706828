import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import isEmpty from 'lodash/isEmpty';
import { CheckOutlined } from '@ant-design/icons';

import iconSendInvite from 'assets/images/icon-send-invite.svg';
import DataTable from 'components/DataTable/DataTable';
import InfoBanner from 'components/InfoBanner/InfoBanner';
import { DataColumn } from 'components/DataTable/DataColumn';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import CheckboxSelect from 'components/CheckboxSelect/CheckboxSelect';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import AlertMessage from 'components/Alert/AlertMessage';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import BrokerAdmin from 'model/BrokerAdmin';

import { SendInvitesForm } from 'modules/brokers/components/SendInvitesForm/SendInvitesForm';
import {
  addBroker,
  clearBrokerCreateErr,
  createBroker,
} from 'modules/brokers/slices/brokerBasicInfoSlice';
import { BROKER_ADMIN_TYPE_LABELS } from 'constants/commonConstants';

import styles from './sendInvites.module.less';

type SendInvitesProps = {
  nextStep: () => void;
};

const SendInvites: FC<SendInvitesProps> = (props: SendInvitesProps) => {
  const { nextStep } = props;
  const [visibleAPIError, setVisibleAPIError] = useState<boolean>(false);
  const { brokerObj, brokerCreationSuccess, brokerCreateError } =
    useAppSelector((state) => state.brokers.brokerBasicInfo);
  const avatarMap = useAppSelector(
    (state) => state.brokers.brokerAdmins.avatarImageMap
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (brokerCreationSuccess && !brokerCreateError) {
      nextStep();
    } else {
      setVisibleAPIError(true);
    }
  }, [brokerCreationSuccess, brokerCreateError, nextStep]);

  const getSaveButtonText = () => {
    if (!isEmpty(brokerObj.admins)) {
      const invitedAdmins = brokerObj.admins.filter((item) => item.invite);
      if (!isEmpty(invitedAdmins)) {
        return 'Save Broker and Send Invites';
      }
    }
    return 'Save Broker';
  };

  const getLocationName = (locationPlaceId: string) => {
    const location = brokerObj.locations.filter(
      (item) => item.inPlaceId === locationPlaceId
    );
    if (location && location.length === 1) {
      return location[0].name;
    }
  };

  const getBrokerAdminLocationColumnData = (brokerAdmin: BrokerAdmin) => {
    if (isEmpty(brokerAdmin.locations)) {
      return 'All';
    }
    return brokerAdmin.locations
      .map((item) => getLocationName(item))
      .join(', ');
  };

  const convertBrokerAdminForTableRender = (
    brokerAdmins: BrokerAdmin[]
  ): any[] => {
    if (brokerAdmins != null) {
      return brokerAdmins.map((element) => ({
        key: element.inPlaceId,
        brokerAdmin: element.firstName.trim() + ' ' + element.lastName.trim(),
        location: getBrokerAdminLocationColumnData(element),
        email: element.email,
        isPrimary: element.primary,
        isSendInvite: element.invite,
        individualSubType: element.individualSubType,
      }));
    }
    return [];
  };

  const columns: DataColumn[] = [
    {
      title: 'BROKER ADMIN',
      dataIndex: 'brokerAdmin',
      key: 'brokerAdmin',
      width: '25%',
      sorter: (a, b) => {
        a = a.brokerAdmin || '';
        b = b.brokerAdmin || '';
        return a.localeCompare(b);
      },
      ellipsis: {
        showTitle: false,
      },
      className: styles.nameCell,
      render: (colData: any, record: any, index: number) => {
        let avatarImage = null;
        if (Object.keys(avatarMap).includes(record.key)) {
          avatarImage = avatarMap[record.key].croppedAvatar;
        }
        return (
          <>
            <ProfileAvatar content={record.brokerAdmin} src={avatarImage} />
            <div className={styles.nameColStyle}>
              <OverflowPopover popoverContent={colData}>
                <span>{colData}</span>
              </OverflowPopover>
            </div>
          </>
        );
      },
    },
    {
      title: 'USER TYPE',
      dataIndex: 'individualSubType',
      key: 'individualSubType',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (individualSubType) => {
        return <>{BROKER_ADMIN_TYPE_LABELS[individualSubType]}</>;
      },
    },
    {
      title: 'LOCATION',
      dataIndex: 'location',
      key: 'location',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        a = a.location || '';
        b = b.location || '';
        return a.localeCompare(b);
      },
      render: (locations) => <OverflowPopover>{locations}</OverflowPopover>,
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (email) => <OverflowPopover>{email}</OverflowPopover>,
    },
    {
      title: 'PRIMARY CONTACT',
      dataIndex: 'isPrimary',
      key: 'primaryContact',
      width: '18%',
      className: styles.primaryContactCol,
      render: (colData) => (colData ? <CheckOutlined /> : <></>),
      align: 'center',
    },
    {
      title: 'SEND INVITES',
      dataIndex: 'isSendInvite',
      key: 'sendInvite',
      width: '25%',
      className: styles.sendInviteCol,
      render: (colData: any, record: any, index: number) => (
        <CheckboxSelect
          onChange={(e) => onCheckBoxSelectChange(record, e)}
          checked={colData}
        />
      ),
      align: 'center',
    },
  ];

  const onCheckBoxSelectChange = (record: any, e: CheckboxChangeEvent) => {
    const adminInPlaceId = record.key;

    const updatedBrokerAdmins = brokerObj.admins.map((admin) => {
      if (admin.inPlaceId === adminInPlaceId) {
        return { ...admin, invite: e.target.checked };
      }
      return { ...admin };
    });
    const broker = {
      ...brokerObj,
      admins: [...updatedBrokerAdmins],
    };
    dispatch(addBroker(broker));
  };

  const onSaveBrokerClick = () => {
    dispatch(createBroker(brokerObj));
  };

  return (
    <div className={styles.sendInvites}>
      <InfoBanner
        title="Send Invitation to New Broker Admins"
        description="The broker admin will receive a registration link via email to join the PlanYear platform"
        logo={<img src={iconSendInvite} alt="send invite icon" />}
      />
      {visibleAPIError && brokerCreateError && (
        <AlertMessage
          type="error"
          // TODO - This is a temporary error message. We need to have proper message once we get
          message={`Broker Creation Failed : ${brokerCreateError?.data?.code}`}
          closeAlert={() => {
            setVisibleAPIError(false);
            dispatch(clearBrokerCreateErr());
          }}
        />
      )}
      <DataTable
        data={convertBrokerAdminForTableRender(brokerObj.admins)}
        columns={columns}
      />
      <div>
        <Row className={styles.rowWrapper}>
          <Col>
            <SubmitButton type="primary" onClick={onSaveBrokerClick}>
              {getSaveButtonText()}
            </SubmitButton>
          </Col>
        </Row>
      </div>
      <div className={styles.formWrapper}>
        <SendInvitesForm />
      </div>
    </div>
  );
};

export default SendInvites;
