import { useEffect, useState } from 'react';
import { Row } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  Cell,
} from 'recharts';
import {
  COLOR_BILLING_CHART_NON_HOVER,
  COLOR_BILLING_CHART_STOKE,
  COLOR_LEGEND_LABEL_SHADE,
  COLOR_LIGHT_GREY,
  COLOR_MEDIUM_SEA_GREEN,
  COLOR_DARK_GREY,
} from 'modules/claims/constants/constants';
import { shortenCurrencyWithSuffix } from 'util/commonUtil';
import { monthsShort } from 'modules/billing/constants/constants';
import styles from './billingBarChart.module.less';

type BillingBarChartProps = {
  selectedBar: string;
  setSelectedBar: Function;
  chartData: any;
  trendToClose: Function;
  isNoView?: boolean;
};

const BillingBarChart = (props: BillingBarChartProps) => {
  const { selectedBar, setSelectedBar, chartData, trendToClose, isNoView } =
    props;

  const [posData, setposData] = useState({ name: '', x: -1000, y: -1000 });

  const defaultScreenData = [
    {
      name: `Jan '22`,
      amount: 2400,
    },
    {
      name: `Feb '22`,
      amount: 1398,
    },
    {
      name: `Mar '22`,
      amount: 9800,
    },
    {
      name: `Apr '22`,
      amount: 6908,
    },
    {
      name: `May '22`,
      amount: 7900,
    },
    {
      name: `Jun '22`,
      amount: 7800,
    },
    {
      name: `Jul '22`,
      amount: 7300,
    },
    {
      name: `Aug '22`,
      amount: 8000,
    },
    {
      name: `Sep '22`,
      amount: 9100,
    },
    {
      name: `Oct '22`,
      amount: 8200,
    },
    {
      name: `Nov '22`,
      amount: 8300,
    },
    {
      name: `Dec '22`,
      amount: 4300,
    },
  ];

  const [chartObj, setChartObj] = useState<any>(defaultScreenData);

  const xAxisTick = (value: string, x: number, y: number) => {
    const splitted = value.split(' ');
    return (
      value && (
        <>
          <text x={x - 10} y={y + 15} fill={COLOR_LEGEND_LABEL_SHADE}>
            {splitted[0]}
          </text>
          <text
            x={x - 10}
            y={y + 35}
            fill={COLOR_LEGEND_LABEL_SHADE}
            fontWeight={400}
          >
            {splitted[1]}
          </text>
        </>
      )
    );
  };

  useEffect(() => {
    if (chartData) {
      const arr = [] as any;
      chartData.map((data: any) => {
        const currentYear = data?.year.toString() as string;
        arr.push({
          name: monthsShort[data.month] + ` '` + currentYear.slice(-2),
          amount: data.amount,
        });
      });
      setChartObj(arr);
    }
  }, [chartData]);

  const chartDateFormat = (date: any) => {
    const month = date.format('MMM');
    const year = date.format('YY');
    return month + ` '` + year;
  };

  return (
    <div className={styles.billingView}>
      <Row>
        <ResponsiveContainer width="99%" height={321}>
          <BarChart
            data={chartObj}
            onMouseLeave={() => {
              setposData({ name: '', x: -1000, y: -10000 });
            }}
          >
            <CartesianGrid
              vertical={false}
              stroke={COLOR_BILLING_CHART_STOKE}
            />
            <XAxis
              dataKey="name"
              hide={false}
              axisLine={false}
              minTickGap={0}
              tickMargin={10}
              interval={0}
              padding={{ left: 0, right: 0 }}
              label={''}
              tick={({ payload, x, y }: any) => {
                const { value } = payload;
                return xAxisTick(value, x, y);
              }}
              tickLine={{ height: 40, stroke: COLOR_LIGHT_GREY }}
              height={50}
            />
            <YAxis
              hide={false}
              axisLine={false}
              tickFormatter={(value: number) => {
                return `${shortenCurrencyWithSuffix(value, 2)}`;
              }}
              tick={(data) => {
                return (
                  <g transform={`translate(${0},${data.y})`}>
                    <text
                      x={0}
                      y={data.index === 4 ? -2 : 5}
                      textAnchor="start"
                      fill="#666"
                      className={styles.yAxisTick}
                    >
                      <>
                        $
                        {shortenCurrencyWithSuffix(
                          data.payload.value,
                          2
                        ).substring(1)}
                      </>
                    </text>
                  </g>
                );
              }}
              minTickGap={0}
              width={70}
              tickMargin={10}
              label={''}
              tickLine={{ height: 40, stroke: COLOR_LIGHT_GREY }}
              height={50}
            />
            <ChartTooltip
              cursor={false}
              position={{ x: posData.x - 110, y: posData.y }}
              active={false}
              content={(data: any) => {
                return (
                  <div className={styles.tooltipWrapper}>
                    <div className={styles.amount}>
                      $
                      {!isEmpty(data.payload)
                        ? data?.payload[0]?.payload?.amount?.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          ) ?? 0
                        : 0}
                      <br />
                      <div className={styles.amountLabel}>
                        Total Billed Amount
                      </div>
                    </div>
                  </div>
                );
              }}
            />
            <Bar
              className={styles.barChart}
              fill={COLOR_BILLING_CHART_NON_HOVER}
              dataKey="amount"
              maxBarSize={60}
              onMouseOver={(data) => {
                setposData(data);
              }}
              onMouseLeave={() => {
                setposData({ name: '', x: -1000, y: -10000 });
              }}
              onMouseOut={() => {
                setposData({ name: '', x: -1000, y: -10000 });
              }}
              onClick={(data) => {
                trendToClose(false);
                setposData(data);
                const month = data.name.substring(0, data.name.indexOf(' '));
                const year = data.name.slice(-2);
                setSelectedBar(moment(month + '/01/' + year));
              }}
            >
              {chartObj.map((data: any, index: any) => {
                return (
                  <Cell
                    fill={
                      isNoView
                        ? COLOR_DARK_GREY
                        : data.name === chartDateFormat(selectedBar)
                        ? COLOR_MEDIUM_SEA_GREEN
                        : COLOR_BILLING_CHART_NON_HOVER
                    }
                    key={index}
                  />
                );
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Row>
    </div>
  );
};
export default BillingBarChart;
