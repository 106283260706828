import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import {
  BENEFIT_KIND_LIST,
  BENIFIT_TYPES,
  COLOR_GREY,
  COLOR_LIGHT_GREEN,
  COLOR_WHITE,
} from 'modules/claims/constants/constants';
import expandedIcon from 'assets/images/icon-expanded.svg';
import collapseIcon from 'assets/images/icon-collapse.svg';
import styles from './billingTable.module.less';

type BillingTableProps = {
  benifitSummary: any;
  fileType: string;
  isNoView?: boolean;
};

const BillingTable = (props: BillingTableProps) => {
  const { benifitSummary, fileType, isNoView = false } = props;

  const getData = () => {
    const aggridData: {}[] = [];

    if (
      benifitSummary?.benefitSummary !== undefined &&
      benifitSummary?.benefitSummary !== null
    ) {
      Object.keys(benifitSummary?.benefitSummary ?? []).map(
        (key: any, index: any) => {
          if (
            (benifitSummary?.benefitSummary?.[key]?.billingStatusSummaries ??
              undefined) !== undefined &&
            (benifitSummary?.benefitSummary?.[key]?.billingStatusSummaries ??
              null) !== null
          ) {
            const arr =
              benifitSummary?.benefitSummary?.[key]?.billingStatusSummaries;
            arr.map((item: any) => {
              if (item.planSummaries.length === 0) {
                aggridData.push({
                  benifit: BENIFIT_TYPES?.[key]?.title ?? key,
                  type: item.status,
                  plan: item.planName,
                  count: item.count,
                  current_volume: item.totalCurrentVolume,
                  current_premium: item.totalCurrentPremium,
                  retro_volume: item.totalRetroVolume,
                  retro_premium: item.totalRetroPremium,
                  total_volume: item.totalVolume,
                  total_premium: item.totalPremium,
                });
              } else {
                item.planSummaries.map((plan: any) => {
                  if (
                    fileType === 'SUMMARY' ||
                    plan.tierSummaries.length === 0
                  ) {
                    aggridData.push({
                      benifit: BENIFIT_TYPES?.[key]?.title ?? key,
                      type: item.status,
                      plan: plan.planName,
                      count: plan.count,
                      current_volume: plan.totalCurrentVolume,
                      current_premium: plan.totalCurrentPremium,
                      retro_volume: plan.totalRetroVolume,
                      retro_premium: plan.totalRetroPremium,
                      total_volume: plan.totalVolume,
                      total_premium: plan.totalPremium,
                    });
                  } else {
                    plan.tierSummaries.map((tier: any) => {
                      aggridData.push({
                        benifit: BENIFIT_TYPES?.[key].title ?? key,
                        type: item.status,
                        plan: plan.planName,
                        tier: tier.tier,
                        count: tier.count,
                        current_volume: tier.totalCurrentVolume,
                        current_premium: tier.totalCurrentPremium,
                        retro_volume: tier.totalRetroVolume,
                        retro_premium: tier.totalRetroPremium,
                        total_volume: tier.totalVolume,
                        total_premium: tier.totalPremium,
                      });
                    });
                  }
                });
              }
            });
          }
        }
      );
      aggridData.push({
        benifit: 'SUB-TOTAL',
        count: benifitSummary.count,
        current_volume: benifitSummary.totalCurrentVolume,
        current_premium: benifitSummary.totalCurrentPremium,
        retro_volume: benifitSummary.totalRetroVolume,
        retro_premium: benifitSummary.totalRetroPremium,
        total_volume: benifitSummary.totalVolume,
        total_premium: benifitSummary.totalPremium,
      });
    }

    return aggridData;
  };

  getData();

  const rowData = [
    {
      benifit: 'Medical',
      type: 'Active',
      plan: 'Plan 1',
      tier: 'EE',
      count: null,
      current_volume: 0,
      current_premium: 0,
      retro_volume: 0,
      retro_premium: 0,
      total_premium: 0,
      total_volume: 0,
    },
    {
      benifit: 'Medical',
      type: 'Active',
      plan: 'Plan 1',
      tier: 'ES',
      count: null,
      current_volume: 0,
      current_premium: 0,
      retro_volume: 0,
      retro_premium: 0,
      total_premium: 0,
      total_volume: 0,
    },
    {
      benifit: 'Medical',
      type: 'Active',
      plan: 'Plan 1',
      tier: 'EC',
      count: null,
      current_volume: 0,
      current_premium: 0,
      retro_volume: 0,
      retro_premium: 0,
      total_premium: 0,
      total_volume: 0,
    },
    {
      benifit: 'Medical',
      type: 'Active',
      plan: 'Plan 2',
      tier: 'ES',
      count: null,
      current_volume: 0,
      current_premium: 0,
      retro_volume: 0,
      retro_premium: 0,
      total_premium: 0,
      total_volume: 0,
    },
    {
      benifit: 'Medical',
      type: 'Cobra',
      plan: 'Plan 1',
      tier: 'ES',
      count: null,
      current_volume: 0,
      current_premium: 0,
      retro_volume: 0,
      retro_premium: 0,
      total_premium: 0,
      total_volume: 0,
    },
  ];

  return (
    <div className={styles.agGridWrapper}>
      <div
        id="myGrid"
        style={{
          height: 'auto',
          width: '100%',
        }}
        className="ag-theme-alpine"
      >
        <div className="carrierImage">
          {isNoView ? '' : <img src={benifitSummary?.carrierUrl ?? ''} />}
        </div>
        <AgGridReact
          groupDefaultExpanded={isNoView ? 3 : 0}
          suppressAggFuncInHeader={true}
          pivotColumnGroupTotals={'always'}
          animateRows={true}
          rowHeight={49}
          headerHeight={70}
          className={styles['ag-theme-alpine']}
          domLayout={'autoHeight'}
          suppressCellSelection={true}
          onGridReady={(params) => {
            params.api.sizeColumnsToFit();
          }}
          onRowGroupOpened={(params) => {
            params.api.sizeColumnsToFit();
          }}
          onColumnResized={(params) => {
            params.api.sizeColumnsToFit();
          }}
          suppressRowHoverHighlight={true}
          autoGroupColumnDef={{
            headerName: 'Carrier/Benefit',
            suppressColumnsToolPanel: false,
            suppressMenu: true,
            menuTabs: [],
            sortable: false,
            suppressMovable: true,
            cellRendererParams: {
              suppressCount: true,
            },
            resizable: false,
          }}
          rowClassRules={{
            'footer-node': function (params: any) {
              return params.node.key === 'SUB-TOTAL';
            },
            'no-expand-icon-tier': function (params: any) {
              return params.node.level === (fileType === 'SUMMARY' ? 2 : 3);
            },
            'parent-node': function (params) {
              return params.node.level === 0 && params.node.key !== 'SUB-TOTAL';
            },
            'child-node': function (params) {
              return params.node.level !== 0;
            },
            benifit_node: function (params) {
              return params.node.level === 0;
            },
          }}
          rowData={isNoView ? rowData : getData()}
          icons={{
            groupExpanded:
              '<img src=' +
              expandedIcon +
              ' style="height: 16px; width: 16px;padding-right: 2px"/>',
            groupContracted:
              '<img src=' +
              collapseIcon +
              ' alt="aa" style="height: 16px; width: 16px;padding-right: 2px"/>',
          }}
        >
          <AgGridColumn
            headerName="Benifit"
            field="benifit"
            rowGroupIndex={0}
            resizable={true}
            hide={true}
            cellStyle={(params) => {
              if (BENEFIT_KIND_LIST.includes(params?.node.key ?? ''))
                return {
                  backgroundColor: COLOR_WHITE,
                  borderColor: COLOR_WHITE,
                  ['font-weight']: 'bold',
                  ['font-family']: 'Chivo',
                  ['font-style']: 'normal',
                  ['font-size']: 13,
                  ['line-height']: 50,
                };
              else
                return {
                  backgroundColor: COLOR_GREY,
                  borderColor: COLOR_GREY,
                  ['font-weight']: 'bold',
                  ['font-family']: 'Chivo',
                  ['font-style']: 'normal',
                  ['font-size']: 13,
                  ['line-height']: 50,
                };
            }}
            rowGroup={true}
            enableRowGroup={true}
            enableValue={false}
            menuTabs={[]}
            sortable={false}
            suppressMovable={true}
          />
          <AgGridColumn
            headerName="Type"
            field="type"
            rowGroupIndex={0}
            resizable={true}
            hide={true}
            rowGroup={true}
            enableRowGroup={true}
            suppressMenu={true}
            menuTabs={[]}
            rowDrag={false}
            suppressMovable={true}
            cellStyle={(params) => {
              if (BENEFIT_KIND_LIST.includes(params?.node.key ?? ''))
                return {
                  backgroundColor: COLOR_WHITE,
                  borderColor: COLOR_WHITE,
                  ['font-weight']: 'bold',
                  ['font-family']: 'Chivo',
                  ['font-style']: 'normal',
                  ['font-size']: 13,
                  ['line-height']: 50,
                };
              else
                return {
                  backgroundColor: COLOR_GREY,
                  borderColor: COLOR_GREY,
                  ['font-weight']: 'bold',
                  ['font-family']: 'Chivo',
                  ['font-style']: 'normal',
                  ['font-size']: 13,
                  ['line-height']: 50,
                };
            }}
          />
          <AgGridColumn
            headerName="Plan"
            field="plan"
            rowGroupIndex={0}
            resizable={true}
            hide={true}
            rowGroup={true}
            enableRowGroup={true}
            suppressMenu={true}
            menuTabs={[]}
            rowDrag={false}
            suppressMovable={true}
            cellStyle={(params) => {
              if (BENEFIT_KIND_LIST.includes(params?.node.key ?? ''))
                return {
                  backgroundColor: COLOR_WHITE,
                  borderColor: COLOR_WHITE,
                };
              else
                return { backgroundColor: COLOR_GREY, borderColor: COLOR_GREY };
            }}
          />
          <AgGridColumn
            headerName="Tier"
            field="tier"
            rowGroupIndex={0}
            resizable={true}
            hide={true}
            rowGroup={true}
            enableRowGroup={true}
            suppressMenu={true}
            menuTabs={[]}
            rowDrag={false}
            suppressMovable={true}
            cellStyle={(params) => {
              if (BENEFIT_KIND_LIST.includes(params?.node.key ?? ''))
                return {
                  backgroundColor: COLOR_WHITE,
                  borderColor: COLOR_WHITE,
                };
              else if (params.node.key === 'Sub-Total')
                return {
                  backgroundColor: COLOR_GREY,
                  borderColor: COLOR_WHITE,
                };
              else
                return { backgroundColor: COLOR_GREY, borderColor: COLOR_GREY };
            }}
          />
          <AgGridColumn
            headerName="Current"
            field="current"
            key={'col_1'}
            rowGroupIndex={0}
            resizable={true}
            hide={true}
            rowGroup={true}
            enableRowGroup={true}
            suppressMenu={true}
            menuTabs={[]}
            rowDrag={false}
            suppressMovable={true}
            cellStyle={(params) => {
              if (BENEFIT_KIND_LIST.includes(params?.node.key ?? ''))
                return {
                  backgroundColor: COLOR_WHITE,
                  borderColor: COLOR_WHITE,
                };
              else
                return { backgroundColor: COLOR_GREY, borderColor: COLOR_GREY };
            }}
            marryChildren={true}
          >
            <AgGridColumn
              headerName="Count"
              field="count"
              width={130}
              rowGroupIndex={0}
              aggFunc={(params) => {
                if (BENEFIT_KIND_LIST.includes(params?.rowNode?.key ?? '')) {
                  return '';
                } else {
                  let sum = 0;
                  params.values.forEach((value) => (sum += value));
                  return isNoView ? '-' : sum;
                }
              }}
              resizable={false}
              hide={false}
              rowGroup={false}
              enableRowGroup={false}
              suppressMenu={true}
              menuTabs={[]}
              sortable={false}
              rowDrag={false}
              suppressMovable={true}
              cellStyle={(params) => {
                if (BENEFIT_KIND_LIST.includes(params?.node.key ?? ''))
                  return {
                    backgroundColor: COLOR_WHITE,
                    borderColor: COLOR_WHITE,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: 0,
                  };
                else if (params.node.key === 'SUB-TOTAL')
                  return {
                    backgroundColor: COLOR_LIGHT_GREEN,
                    borderColor: COLOR_LIGHT_GREEN,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: params.value === 0 ? -2 : 0,
                  };
                else
                  return {
                    backgroundColor: COLOR_GREY,
                    borderColor: COLOR_GREY,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: params.value === 0 ? -2 : 0,
                  };
              }}
            />
            <AgGridColumn
              headerName="Volume"
              field="current_volume"
              width={130}
              aggFunc={(params) => {
                if (BENEFIT_KIND_LIST.includes(params?.rowNode?.key ?? '')) {
                  return 'Empty';
                } else {
                  let sum = 0;
                  params.values.forEach((value) => (sum += value));
                  return sum === NaN ? 0 : sum;
                }
              }}
              valueFormatter={(params) => {
                return params.value !== 'Empty'
                  ? params.value === 0
                    ? `---`
                    : '$' +
                      params.value.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                  : '';
              }}
              rowGroupIndex={0}
              resizable={false}
              hide={false}
              rowGroup={false}
              suppressMenu={true}
              enableRowGroup={false}
              enableValue={false}
              menuTabs={[]}
              sortable={false}
              rowDrag={false}
              suppressMovable={true}
              cellStyle={(params) => {
                if (BENEFIT_KIND_LIST.includes(params?.node.key ?? ''))
                  return {
                    backgroundColor: COLOR_WHITE,
                    borderColor: COLOR_WHITE,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: 0,
                  };
                else if (params.node.key === 'SUB-TOTAL')
                  return {
                    backgroundColor: COLOR_LIGHT_GREEN,
                    borderColor: COLOR_LIGHT_GREEN,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: params.value === 0 ? -2 : 0,
                  };
                else
                  return {
                    backgroundColor: COLOR_GREY,
                    borderColor: COLOR_GREY,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: params.value === 0 ? -2 : 0,
                  };
              }}
            />
            <AgGridColumn
              headerName="Premium"
              field="current_premium"
              width={130}
              aggFunc={(params) => {
                if (BENEFIT_KIND_LIST.includes(params?.rowNode?.key ?? '')) {
                  return 'Empty';
                } else {
                  let sum = 0;
                  params.values.forEach((value) => (sum += value));
                  return sum === NaN ? 0 : sum;
                }
              }}
              valueFormatter={(params) => {
                return params?.value !== 'Empty'
                  ? params?.value === 0
                    ? `---`
                    : '$' +
                      params.value.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                  : '';
              }}
              resizable={false}
              rowGroupIndex={1}
              hide={false}
              rowGroup={false}
              enableRowGroup={false}
              enableValue={false}
              menuTabs={[]}
              sortable={false}
              rowDrag={false}
              suppressMovable={true}
              cellStyle={(params) => {
                if (BENEFIT_KIND_LIST.includes(params?.node.key ?? ''))
                  return {
                    backgroundColor: COLOR_WHITE,
                    borderColor: COLOR_WHITE,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: 0,
                  };
                else if (params.node.key === 'SUB-TOTAL')
                  return {
                    backgroundColor: COLOR_LIGHT_GREEN,
                    borderColor: COLOR_LIGHT_GREEN,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: params.value === 0 ? -2 : 0,
                  };
                else
                  return {
                    backgroundColor: COLOR_GREY,
                    borderColor: COLOR_GREY,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: params.value === 0 ? -2 : 0,
                  };
              }}
            />
          </AgGridColumn>
          <AgGridColumn
            headerName="Retroactivity"
            field="retroactivity"
            rowGroupIndex={0}
            resizable={true}
            hide={true}
            rowGroup={true}
            enableRowGroup={true}
            suppressMenu={true}
            menuTabs={[]}
            rowDrag={false}
            suppressMovable={true}
            cellStyle={(params) => {
              if (BENEFIT_KIND_LIST.includes(params?.node.key ?? ''))
                return {
                  backgroundColor: COLOR_WHITE,
                  borderColor: COLOR_WHITE,
                };
              else
                return { backgroundColor: COLOR_GREY, borderColor: COLOR_GREY };
            }}
            marryChildren={true}
          >
            <AgGridColumn
              headerName="Volume"
              field="retro_volume"
              width={130}
              aggFunc={(params) => {
                if (BENEFIT_KIND_LIST.includes(params?.rowNode?.key ?? '')) {
                  return 'Empty';
                } else {
                  let sum = 0;
                  params.values.forEach((value) => (sum += value));
                  return sum === NaN ? 0 : sum;
                }
              }}
              valueFormatter={(params) => {
                return params.value !== 'Empty'
                  ? params.value === 0
                    ? `---`
                    : '$' +
                      params.value.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                  : '';
              }}
              rowGroupIndex={0}
              resizable={false}
              hide={false}
              rowGroup={false}
              enableRowGroup={false}
              enableValue={false}
              menuTabs={[]}
              sortable={false}
              rowDrag={false}
              suppressMovable={true}
              cellStyle={(params) => {
                if (BENEFIT_KIND_LIST.includes(params?.node.key ?? ''))
                  return {
                    backgroundColor: COLOR_WHITE,
                    borderColor: COLOR_WHITE,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: 0,
                  };
                else if (params.node.key === 'SUB-TOTAL')
                  return {
                    backgroundColor: COLOR_LIGHT_GREEN,
                    borderColor: COLOR_LIGHT_GREEN,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: params.value === 0 ? -2 : 0,
                  };
                else
                  return {
                    backgroundColor: COLOR_GREY,
                    borderColor: COLOR_GREY,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: params.value === 0 ? -2 : 0,
                  };
              }}
            />
            <AgGridColumn
              headerName="Premium"
              field="retro_premium"
              width={130}
              aggFunc={(params) => {
                if (BENEFIT_KIND_LIST.includes(params?.rowNode?.key ?? '')) {
                  return 'Empty';
                } else {
                  let sum = 0;
                  params.values.forEach((value) => (sum += value));
                  return sum === NaN ? 0 : sum;
                }
              }}
              valueFormatter={(params) => {
                return params?.value !== 'Empty'
                  ? params?.value == 0
                    ? `---`
                    : '$' +
                      params.value.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                  : '';
              }}
              resizable={false}
              rowGroupIndex={1}
              hide={false}
              rowGroup={false}
              enableRowGroup={false}
              enableValue={false}
              menuTabs={[]}
              sortable={false}
              rowDrag={false}
              suppressMovable={true}
              cellStyle={(params) => {
                if (BENEFIT_KIND_LIST.includes(params?.node.key ?? ''))
                  return {
                    backgroundColor: COLOR_WHITE,
                    borderColor: COLOR_WHITE,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['letter-spacing']: 0,
                    ['line-height']: 50,
                  };
                else if (params.node.key === 'SUB-TOTAL')
                  return {
                    backgroundColor: COLOR_LIGHT_GREEN,
                    borderColor: COLOR_LIGHT_GREEN,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: params.value === 0 ? -2 : 0,
                  };
                else
                  return {
                    backgroundColor: COLOR_GREY,
                    borderColor: COLOR_GREY,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: params.value === 0 ? -2 : 0,
                  };
              }}
            />
          </AgGridColumn>
          <AgGridColumn
            headerName="Total"
            field="totalactivity"
            rowGroupIndex={0}
            resizable={true}
            hide={true}
            rowGroup={true}
            enableRowGroup={true}
            suppressMenu={true}
            menuTabs={[]}
            rowDrag={false}
            suppressMovable={true}
            cellStyle={(params) => {
              if (BENEFIT_KIND_LIST.includes(params?.node.key ?? ''))
                return {
                  backgroundColor: COLOR_WHITE,
                  borderColor: COLOR_WHITE,
                };
              else
                return { backgroundColor: COLOR_GREY, borderColor: COLOR_GREY };
            }}
            marryChildren={true}
          >
            <AgGridColumn
              headerName="Volume"
              field="total_volume"
              width={130}
              aggFunc={(params) => {
                if (BENEFIT_KIND_LIST.includes(params?.rowNode?.key ?? '')) {
                  return 'Empty';
                } else {
                  let sum = 0;
                  params.values.forEach((value) => (sum += value));
                  return sum === NaN ? 0 : sum;
                }
              }}
              valueFormatter={(params) => {
                return params.value !== 'Empty'
                  ? params.value === 0
                    ? `---`
                    : '$' +
                      params.value.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                  : '';
              }}
              rowGroupIndex={0}
              resizable={false}
              hide={false}
              rowGroup={false}
              enableRowGroup={false}
              enableValue={false}
              menuTabs={[]}
              sortable={false}
              rowDrag={false}
              suppressMovable={true}
              cellStyle={(params) => {
                if (BENEFIT_KIND_LIST.includes(params?.node.key ?? ''))
                  return {
                    backgroundColor: COLOR_WHITE,
                    borderColor: COLOR_WHITE,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: 0,
                  };
                else if (params.node.key === 'SUB-TOTAL')
                  return {
                    backgroundColor: COLOR_LIGHT_GREEN,
                    borderColor: COLOR_LIGHT_GREEN,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: params.value === 0 ? -2 : 0,
                  };
                else
                  return {
                    backgroundColor: COLOR_GREY,
                    borderColor: COLOR_GREY,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: params.value === 0 ? -2 : 0,
                  };
              }}
            />
            <AgGridColumn
              headerName="Premium"
              field="total_premium"
              width={130}
              aggFunc={(params) => {
                if (BENEFIT_KIND_LIST.includes(params?.rowNode?.key ?? '')) {
                  return 'Empty';
                } else {
                  let sum = 0;
                  params.values.forEach((value) => (sum += value));
                  return sum === NaN ? 0 : sum;
                }
              }}
              valueFormatter={(params) => {
                return params.value !== 'Empty'
                  ? params.value === 0
                    ? `---`
                    : '$' +
                      params.value.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                  : '';
              }}
              resizable={false}
              rowGroupIndex={1}
              hide={false}
              rowGroup={false}
              enableRowGroup={false}
              enableValue={false}
              menuTabs={[]}
              sortable={false}
              rowDrag={false}
              suppressMovable={true}
              cellStyle={(params) => {
                if (BENEFIT_KIND_LIST.includes(params?.node.key ?? ''))
                  return {
                    backgroundColor: COLOR_WHITE,
                    borderColor: COLOR_WHITE,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: 0,
                  };
                else if (params.node.key === 'SUB-TOTAL')
                  return {
                    backgroundColor: COLOR_LIGHT_GREEN,
                    borderColor: COLOR_LIGHT_GREEN,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: params.value === 0 ? -2 : 0,
                  };
                else
                  return {
                    backgroundColor: COLOR_GREY,
                    borderColor: COLOR_GREY,
                    ['font-family']: 'Chivo',
                    ['font-style']: 'normal',
                    ['font-weight']: 400,
                    ['font-size']: 13,
                    ['line-height']: 50,
                    ['letter-spacing']: params.value === 0 ? -2 : 0,
                  };
              }}
            />
          </AgGridColumn>
        </AgGridReact>
      </div>
    </div>
  );
};
export default BillingTable;
