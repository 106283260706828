import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
} from 'react';

import { Form } from 'antd';
import { useAppSelector } from 'hooks/redux';
import Rates from 'modules/plans/components/Rates/Rates';
import BenguideBasicData from 'model/BenguideBasicData';
import { BenefitCategory } from 'constants/commonConstants';
import { VisionPlan } from 'model/plans/VisionPlan';
import {
  updateVisionPlanByPath,
  getPlanContributionByFrequency,
  getPlanContributionInProgress,
} from 'modules/plans/slices/visionPlanSlice';

import styles from './ratesUPWrapper.module.less';

type RatesUPEditWrapperProps = {
  wrappedRef: MutableRefObject<any>;
  currentSection: string;
  visionPlan: VisionPlan;
  setVisionPlan: Function;
};

const RatesUPEditWrapper = forwardRef((props: RatesUPEditWrapperProps, ref) => {
  const { wrappedRef, currentSection, visionPlan, setVisionPlan } = props;
  const { visionRates, editedVisionPlan } = useAppSelector(
    (state) => state.plan.visionPlan
  );
  const requestType = useAppSelector(
    (state) => state.plan.visionPlan.requestType
  );
  const [ratesForm] = Form.useForm();

  useEffect(() => {
    if (visionRates) {
      setVisionPlan({
        ...visionPlan,
        rates: visionRates,
        hasSameContributions: editedVisionPlan.hasSameContributions,
      });
    }
    // eslint-disable-next-line
  }, [setVisionPlan, visionRates, editedVisionPlan.hasSameContributions]);

  useImperativeHandle(ref, () => ({
    validate() {
      return wrappedRef.current?.validate();
    },
  }));

  return (
    <div className={styles.ratesWrapper}>
      <Rates
        benefitCategory={BenefitCategory.VISION.value}
        updatePlanByPathAction={updateVisionPlanByPath}
        getContributionAction={getPlanContributionByFrequency}
        benefitGuideObj={{} as BenguideBasicData}
        plan={editedVisionPlan}
        ref={wrappedRef}
        currentSection={currentSection}
        form={ratesForm}
        isDBGView={false}
        isUPEdit
        getContributionInProgress={
          requestType === getPlanContributionInProgress.type
        }
      />
    </div>
  );
});

RatesUPEditWrapper.displayName = 'RatesUPEditWrapper';
export default RatesUPEditWrapper;
