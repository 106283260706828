import { FC, useCallback, useEffect, useState } from 'react';
import { useSubscription } from 'react-stomp-hooks';
import { Button, notification } from 'antd';
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
  DOWNLOAD_READY_MESSAGE_BODY,
  DOWNLOAD_READY_MESSAGE_TITLE,
} from 'constants/commonConstants';
import styles from './documentNotificationProps.module.less';

interface DocumentNotificationProps {
  individualId?: string | null;
}

const DocumentNotification: FC<DocumentNotificationProps> = (
  props: DocumentNotificationProps
) => {
  const [lastMessage, setLastMessage] = useState<any>({});

  useSubscription(`/topic/common/${props.individualId}`, (message) =>
    setLastMessage(JSON.parse(message.body))
  );

  const downloadZip = useCallback((preSignedUrl: string, key: string) => {
    notification.close(key);
    window.open(preSignedUrl, '_blank');
  }, []);

  const downloadButton = useCallback(
    (preSignedUrl: string) => {
      return (
        <Button
          type="link"
          size="small"
          onClick={() => downloadZip(preSignedUrl, preSignedUrl)}
        >
          Download
        </Button>
      );
    },
    [downloadZip]
  );

  const openNotification = useCallback(
    (preSignedUrl: string) => {
      notification.open({
        message: DOWNLOAD_READY_MESSAGE_TITLE,
        description: DOWNLOAD_READY_MESSAGE_BODY,
        btn: downloadButton(preSignedUrl),
        placement: 'bottomLeft',
        duration: null,
        className: styles.notification,
        closeIcon: <CloseCircleOutlined />,
        key: preSignedUrl, // using pre signed URL as the notification key
        icon: <CheckOutlined className={styles.downloadIcon} size={20} />,
      });
    },
    [downloadButton]
  );

  useEffect(() => {
    if (lastMessage && lastMessage.preSignedUrl) {
      openNotification(lastMessage.preSignedUrl);
      setLastMessage(''); // clear message after consuming
    }
  }, [lastMessage, openNotification]);

  return <></>;
};

export default DocumentNotification;
