import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { navContexts } from 'constants/authConstants';

export type Organization = {
  id: string | null;
  logoUrl?: string | null;
  name?: string | null;
};
export type Carrier = {
  id: string | null;
  logoUrl?: string | null;
  name?: string | null;
};

type NavigationContextHolder = {
  context: string | null;
  brokerId: string | null | undefined; // TODO remove this and use broker
  employerId: string | null;
  broker?: Organization | null;
  employer?: Organization | null;
  setBrokerId: (brokerId: string | null) => void;
  setEmployerId: (employerId: string | null, brokerId: string | null) => void;
  setBroker: (
    brokerId: string | null,
    name?: string | null,
    logoUrl?: string | null
  ) => void;
  setEmployer: (
    employerId: string | null,
    name?: string | null,
    logoUrl?: string | null
  ) => void;
  setCarrier: (
    carrierId: string | null,
    name?: string | null,
    logoUrl?: string | null
  ) => void;
  carrier: Carrier;
  issueLogType: string;
  issueId: string;
  setIssueLogType: (type: string) => void;
  setIssueId: (type: string) => void;
  setChatContext: (context: string) => void;
  chatId: string;
};

const initialState: NavigationContextHolder = {
  context: null,
  brokerId: null,
  employerId: null,
  broker: null,
  employer: null,
  carrier: {} as Carrier,
  setBrokerId: () => {},
  setEmployerId: () => {},
  setBroker: () => {},
  setEmployer: () => {},
  setCarrier: () => {},
  setIssueLogType: () => {},
  setIssueId: () => {},
  issueLogType: '',
  issueId: '',
  setChatContext: () => {},
  chatId: '',
};

export const NavigationContext =
  createContext<NavigationContextHolder>(initialState);

export const NavigationContextProvider: FC = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [navigationContext, setNavigationContext] =
    useState<NavigationContextHolder>(initialState);

  const isIssueLogContext = () => {
    return (
      !window.location.pathname.includes('employers') &&
      (window.location.pathname.includes('issues-log/account') ||
        window.location.pathname.includes('issues-log/support'))
    );
  };

  const isAiAssistantContext = () => {
    return (
      !window.location.pathname.includes('configuration') &&
      window.location.pathname.includes('assistant')
    );
  };

  const setBrokerId = useCallback((brokerId: string | null) => {
    setNavigationContext((current) => {
      return {
        ...current,
        brokerId: brokerId,
        context: isAiAssistantContext()
          ? navContexts.assistant
          : isIssueLogContext()
          ? navContexts.issuesLog
          : brokerId
          ? navContexts.broker
          : current.carrier.id
          ? navContexts.carrier
          : navContexts.platform,
      };
    });
    // eslint-disable-next-line
  }, []);

  const setBroker = useCallback(
    (
      brokerId: string | null,
      name?: string | null,
      logoUrl?: string | null
    ) => {
      setNavigationContext((current) => {
        return {
          ...current,
          broker: { id: brokerId, name: name, logoUrl: logoUrl },
          context: isAiAssistantContext()
            ? navContexts.assistant
            : isIssueLogContext()
            ? navContexts.issuesLog
            : current.carrier.id
            ? navContexts.carrier
            : brokerId
            ? navContexts.broker
            : navContexts.platform,
        };
      });
    },
    // eslint-disable-next-line
    []
  );

  const setEmployerId = useCallback(
    (employerId: string | null, brokerId: string | null) => {
      setNavigationContext((current) => {
        return {
          ...current,
          context: isIssueLogContext()
            ? navContexts.issuesLog
            : current.carrier.id
            ? navContexts.carrier
            : employerId
            ? navContexts.er
            : navContexts.broker,
          employerId: employerId,
          brokerId: brokerId,
        };
      });
    },
    // eslint-disable-next-line
    []
  );

  const setEmployer = useCallback(
    (
      employerId: string | null,
      name?: string | null,
      logoUrl?: string | null
    ) => {
      setNavigationContext((current) => {
        return {
          ...current,
          context: isIssueLogContext()
            ? navContexts.issuesLog
            : current.carrier.id
            ? navContexts.carrier
            : employerId
            ? navContexts.er
            : navContexts.broker,
          employer: { id: employerId, name: name, logoUrl: logoUrl },
        };
      });
    },
    // eslint-disable-next-line
    []
  );
  const setCarrier = useCallback(
    (
      carrierId: string | null,
      name?: string | null,
      logoUrl?: string | null
    ) => {
      setNavigationContext((current) => {
        return {
          ...current,
          context: carrierId ? navContexts.carrier : current.context,
          carrier: { id: carrierId, name: name, logoUrl: logoUrl },
        };
      });
    },
    []
  );

  const setIssueLogType = useCallback((type: string) => {
    setNavigationContext((current) => {
      return {
        ...current,
        context: type ? navContexts.issuesLog : current.context,
        issueLogType: type,
      };
    });
  }, []);

  const setIssueId = useCallback((issueId: string) => {
    setNavigationContext((current) => {
      return {
        ...current,
        context: issueId ? navContexts.issuesLog : current.context,
        issueId: issueId,
      };
    });
  }, []);

  const setChatContext = useCallback((chatId: string) => {
    setNavigationContext((current) => {
      return {
        ...current,
        context: chatId ? navContexts.assistant : current.context,
        chatId: chatId,
      };
    });
  }, []);

  useEffect(() => {
    setNavigationContext((current) => {
      return {
        ...current,
        setBrokerId: setBrokerId,
        setEmployerId: setEmployerId,
        setBroker: setBroker,
        setEmployer: setEmployer,
        setCarrier: setCarrier,
        setIssueLogType: setIssueLogType,
        setIssueId: setIssueId,
        setChatContext: setChatContext,
      };
    });
  }, [
    setBrokerId,
    setEmployerId,
    setBroker,
    setEmployer,
    setCarrier,
    setIssueLogType,
    setIssueId,
    setChatContext,
  ]);

  return (
    <NavigationContext.Provider value={navigationContext}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContextProvider;
