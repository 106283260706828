import { FC } from 'react';

import Stepper from 'components/Stepper/Stepper';
import BasicInfo from 'modules/brokers/components/BasicInfo/BasicInfo';
import AddBrokerAdmins from 'modules/brokers/components/BrokerAdmins/AddBrokerAdmins';
import BrokerLocation from 'modules/brokers/components/BrokerLocation/BrokerLocationList';
import CompleteStep from 'modules/brokers/components/CompleteStep/CompleteStep';
import SendInvites from 'modules/brokers/components/SendInvites/SendInvites';
import styles from './addBroker.module.less';

type Props = {
  currentStep: number;
  closeModal: () => void;
  nextStep: () => void;
  changeCurrentStep: (step: number) => any;
};

const AddBroker: FC<Props> = (props) => {
  const { closeModal, nextStep, currentStep, changeCurrentStep } = props;

  const closeAndResetStepper = () => {
    closeModal();
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className={styles.step}>
            <BasicInfo nextStep={nextStep} />
          </div>
        );
      case 1:
        return (
          <div className={styles.step}>
            <BrokerLocation nextStep={nextStep} />
          </div>
        );
      case 2:
        return (
          <div className={styles.step}>
            <AddBrokerAdmins nextStep={nextStep} />
          </div>
        );
      case 3:
        return (
          <div className={styles.step}>
            <SendInvites nextStep={nextStep} />
          </div>
        );
      case 4:
        return (
          <div className={styles.step}>
            <CompleteStep closeModal={closeAndResetStepper} />
          </div>
        );
      default:
        return;
    }
  };

  return (
    <div className={styles.stepWrapper}>
      <Stepper
        headers={[
          'Basic Info',
          'Locations & Access',
          'Add Broker Admins',
          'Send Invites',
          'Complete',
        ]}
        activeStep={currentStep}
        changeCurrentStep={changeCurrentStep}
        renderStepContent={renderStepContent}
      />
    </div>
  );
};

export default AddBroker;
