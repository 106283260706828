import { useSelector } from 'react-redux';
import { Collapse, Form, Spin } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { cloneDeep, get, isEmpty, some } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import PanelFooter from 'modules/plans/components/PanelFooter/PanelFooter';
import AddCustomServiceModal from 'modules/plans/components/AddCustomServiceModal/AddCustomServiceModal';
import MedicalPlan from 'model/plans/MedicalPlan';
import {
  CostType,
  VALIDATION_NAME_DUPLICATED,
  PlanApproach,
  TooltipHeadings,
  Tooltips,
  VALIDATION_FAILED,
  MDV_FEEDBACK_PANEL_TYPES,
  PLAN_ADDITIONAL_DOCUMENT,
} from 'modules/plans/constants';

import PanelSection from 'modules/plans/enums/PanelSection';
import {
  removeUserAiFeedback,
  resetPlanReduxStore,
  saveMedicalPlan,
  setUserAiFeedback,
  updateMedicalPlan,
} from 'modules/plans/slices/medicalPlanSlice';
import BasicPlanInfoSBCWrapper from 'modules/plans/components/BasicPlanInfo/BasicPlanInfoSBCWrapper';
import PlanDocumentUPWrapper from 'modules/plans/medical/components/PlanDocuments/PlanDocumentsUPWrapper';
import DeductiblesOOPMaxSBCWrapper from 'modules/plans/components/DeductiblesOOPMax/DeductiblesOOPMaxSBCWrapper';
import ServicesSBCWrapper from 'modules/plans/components/Services/ServicesSBCWrapper';
import RatesSBCWrapper from 'modules/plans/components/Rates/RatesSBCWrapper';
import RxCardInfo from 'modules/plans/components/RxCardInfo/RxCardInfo';

import {
  BenefitCategory,
  maxMedicalDeductibleValue,
  maxMedicalRXValue,
  maxMedicalServiceValue,
} from 'constants/commonConstants';
import { useNavContext } from 'hooks/useNavContext';

import styles from 'modules/plans/medical/components/AddPlan/addPlan.module.less';
import { getValidationMessage } from 'util/commonUtil';
import {
  buildRates,
  getDefaultPlanRates,
  handleScrollToNextPanel,
  createMockPlanObject,
  isEmptyValue,
} from 'modules/plans/utils';
import AddEnrollments from 'modules/plans/components/Enrollments/AddEnrollments/AddEnrollments';
import PanelHeaderExtra from 'modules/plans/components/PanelHeaderExtra/PanelHeaderExtra';
import NextButton from 'components/buttons/NextButton/NextButton';
import { AIUploaderFeedback } from 'model/plans/UserFeedback';
import AiFeedbackReceiver from 'modules/plans/components/AiFeedbackReceiver/aiFeedbackReceiver';
import UploaderType from 'modules/plans/enums/UploaderType';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import { TDocumentExtractionSource } from 'modules/plans/components/AddPlanModal/AddPlanModal';
import PlanUpdateWarningModal, {
  UpdateDocumentExtractionSource,
} from 'modules/plans/components/PlanUpldateWarningModal/PlanUpdateWarningModal';
import { DocumentExtractionSource } from 'modules/plans/enums/DocumentExtractionSource';
import AdditionalPlanResources from 'modules/plans/components/AdditionalPlanResources/AdditionalPlanResources';
import FileType from 'model/plans/FileType';
import WebLinkType from 'model/plans/WebLinkType';
import {
  removePlanDocuments,
  updateWeblinks,
  uploadPlanDocument,
  uploadRemovePlanDocument,
} from 'modules/plans/slices/planDocumentsSlice';
import UpdatePlanParametersInfo from 'modules/plans/UpdatePlanParametersInfo/UpdatePlanParametersInfo';

const { Panel } = Collapse;

type PanelHeaderProps = {
  optional: boolean;
};

const PanelHeader = (props: PanelHeaderProps) => {
  const { optional } = props;
  return (
    <div>
      <span className="text form-info">
        {optional &&
          'You can save all the above information and complete the medical plan creation process.'}
        {!optional &&
          ' This info is not available from the SBC. Please fill in.'}
      </span>
    </div>
  );
};

type TrackPanelNext = {
  basicPlanInfo: boolean;
  rates: boolean;
  deductiblesOopMax: boolean;
  services: boolean;
  rx: boolean;
  planDocuments: boolean;
  idCardInfo: boolean;
  enrollments: boolean;
};

type TrackPanelChanges = {
  deductiblesOopMax: boolean;
  services: boolean;
  rx: boolean;
};

const getDefaultNextStates = (): TrackPanelNext => {
  return {
    basicPlanInfo: false,
    rates: false,
    deductiblesOopMax: false,
    services: false,
    rx: false,
    planDocuments: false,
    idCardInfo: false,
    enrollments: false,
  };
};

const getSBCDefaultValues = (): TrackPanelChanges => {
  return {
    deductiblesOopMax: true,
    services: true,
    rx: true,
  };
};

const getDefaultNonSBCValues = (): TrackPanelChanges => {
  return {
    deductiblesOopMax: false,
    services: false,
    rx: false,
  };
};

const getDefaultIncompleteStates = (): TrackPanelNext => {
  return {
    basicPlanInfo: true,
    rates: true,
    deductiblesOopMax: true,
    services: true,
    rx: true,
    planDocuments: true,
    idCardInfo: true,
    enrollments: true,
  };
};

type AddPlanProps = {
  isOpen: boolean;
  onClose: () => void;
  benguideId?: string;
  dbgPlanYear?: string;
  isDisable?: boolean;
  isLoading?: boolean;
  isRenewalProcessStarted: boolean;
  planApproach?: string;
  onError?: (message?: string) => void;
  planUploaderType?: UploaderType;
  status?: ProcessStatus;
  jobId?: string;
  isReview?: boolean;
  docExtractionSource?: TDocumentExtractionSource;
  onSave?: (isSuccess: boolean) => void;
  skipFailedDocument?: boolean;
  formRightWrapperRef?: React.MutableRefObject<HTMLDivElement | null>;
};

const AddPlan = forwardRef((props: AddPlanProps, ref) => {
  const {
    isOpen,
    onClose,
    benguideId,
    dbgPlanYear,
    isDisable: isStepperDisabled = false,
    isRenewalProcessStarted,
    onError,
    planApproach,
    isLoading,
    status = ProcessStatus.CANCELLED,
    jobId,
    isReview,
    skipFailedDocument,
    formRightWrapperRef,
    docExtractionSource,
    onSave,
  } = props;
  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );
  const [ratesForm] = Form.useForm();
  const [inProgress] = useState<boolean>(false);
  const [touchedSection, setTouchedSection] = useState<string>('');
  const [showUpdatePlanWarning, setShowUpdatePlanWarning] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const plan = useSelector((state: any) => state.plan.plans);
  const {
    medicalPlan,
    inProgress: updatingMedicalPlan,
    error: medicalPlanError,
    editedMedicalPlan,
  } = useAppSelector((state) => state.plan.plans);

  const { inProgress: documentUploadInProgress } = useAppSelector(
    (state) => state.plan.planDocuments
  );

  const { sbcUpload = {}, displayedServices } = plan;
  const { employerId } = useNavContext();

  const sbcUploadRef = useRef(sbcUpload);
  sbcUploadRef.current = sbcUpload;

  const [activePanel, setActivePanel] = useState<string>('');
  const [isNextClicked, setIsNextClicked] = useState<TrackPanelNext>(
    getDefaultNextStates()
  );
  const [isSBCPanelVsited, setSBCPanelVisited] = useState<TrackPanelChanges>(
    getDefaultNonSBCValues()
  );

  const [isIncomplete, setIsIncomplete] = useState<TrackPanelNext>(
    getDefaultIncompleteStates()
  );
  const [isAddCustomServiceModalOpen, setIsAddCustomServiceModalOpen] =
    useState<boolean>(false);
  const [isFormValidBasicPlanInfo, setIsFormValidBasicPlanInfo] =
    useState<boolean>(true);
  const [isFormValidRates, setIsFormValidRates] = useState<boolean>(true);

  const [selectedFileList, setSelectedFileList] = useState<FileType[]>([]);
  const [isDocRemoved, setIsDocRemoved] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedWeblink, setSelectedWeblink] = useState<WebLinkType[]>([]);

  const isDisable = isStepperDisabled || updatingMedicalPlan || isLoading;

  const ratesRef = useRef<any>();
  const rxRef = useRef<any>();
  const basicPlanInfoRef = useRef<any>();
  const rxCardInfoRef = useRef<any>();
  const enrollmentRef = useRef<any>();
  const deductiblesRef = useRef<any>();

  const isAiEnabled = !isEmpty(jobId) && !skipFailedDocument;

  useImperativeHandle(ref, () => ({
    setActivePanel,
    getPlan: () => medicalPlan,
    cancelUpload: () => {},
  }));

  useEffect(() => {
    dispatch(resetPlanReduxStore(BenefitCategory.MEDICAL.value, employerId));
  }, [dispatch, isOpen, employerId]);

  useEffect(() => {
    setIsNextClicked(getDefaultNextStates());
  }, [planApproach]);

  // Below triggers to set the values as AI when job id is available
  useEffect(() => {
    if (
      [
        ProcessStatus.PROCESSING,
        ProcessStatus.REVIEWED,
        isOpsAdmin && (ProcessStatus.SUCCESS || ProcessStatus.SAVED),
      ].includes(status)
    ) {
      setSBCPanelVisited(getSBCDefaultValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, jobId]);

  useEffect(() => {
    if (
      medicalPlanError?.data?.code === VALIDATION_NAME_DUPLICATED ||
      medicalPlanError?.data?.code === VALIDATION_FAILED
    ) {
      setActivePanel(PanelSection.BASIC_PLAN_INFO);
      const { current = {} } = basicPlanInfoRef as any;
      const { validate } = current;
      validate?.();
      setShowUpdatePlanWarning(false);
    }
  }, [medicalPlanError?.data?.code]);

  const onChange = useCallback(
    async (newMedicalPlanObject: MedicalPlan) => {
      dispatch(updateMedicalPlan(newMedicalPlanObject));
    },
    [dispatch]
  );

  const savePlanAndInitiateWarning = async () => {
    setShowUpdatePlanWarning(false);
    if (!isEmpty(docExtractionSource)) {
      return setShowUpdatePlanWarning(true);
    } else {
      await handlePlanSave();
    }
  };

  const savePlan = async (skipForUpdate?: boolean) => {
    setShowUpdatePlanWarning(false);

    // Deep clone the medicalPlan object
    let updatedPlan = cloneDeep(medicalPlan);

    if (skipForUpdate) {
      // If skipForUpdate is true, merge the deep cloned medicalPlan with the mock plan object
      updatedPlan = { ...updatedPlan, ...createMockPlanObject() };
    }
    await dispatch(
      saveMedicalPlan(
        updatedPlan,
        (isSuccess: boolean, id: string) => {
          uploadDocument(id, isSuccess);
        },
        benguideId
      )
    );
  };

  const handlePlanSave = async () => {
    try {
      const { current = {} } = ratesRef as any;
      const { validate } = current;
      const { invalidFormFields = [] } = validate();

      if (invalidFormFields.length === 0) {
        // If modal was confirmed, proceed with the save operation
        await savePlan();
      } else {
        // If form validation failed, update the form validity state
        setIsFormValidRates(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validateFieldLength = (medicalPlan: MedicalPlan) => {
    const fields = [
      'individualInNetworkCost',
      'individualOutOfNetworkCost',
      'familyInNetworkCost',
      'familyOutOfNetworkCost',
    ] as const;

    const isInvalid = [medicalPlan?.deductibles, medicalPlan?.outOfPocket].some(
      (deductible) =>
        fields.some(
          (field) =>
            parseFloat(deductible?.[field] ?? '0') >= maxMedicalDeductibleValue
        )
    );

    return isFieldValid(isInvalid);
  };

  const isFromUpdatePan = !isEmpty(docExtractionSource);

  const isAnUpdateOfferedPlan =
    isFromUpdatePan &&
    docExtractionSource?.source === DocumentExtractionSource.UPDATE_QUOTE_PLAN;

  const disableOnUpdatePlan =
    !isEmpty(docExtractionSource) &&
    !isOpsAdmin &&
    [ProcessStatus.REVIEWED].includes(status);

  const showFeedbackPanel =
    planApproach === PlanApproach.SBC &&
    !isOpsAdmin &&
    docExtractionSource?.source !== DocumentExtractionSource.UPDATE_QUOTE_PLAN;

  const validateCustomServices = (medicalPlan: MedicalPlan) => {
    const costType = ['inNetwork', 'outOfNetwork'] as const;
    const isInvalid =
      medicalPlan.customServices?.some(({ serviceValue }) =>
        costType.some(
          (type) =>
            parseFloat(serviceValue?.[type]?.copay) >= maxMedicalServiceValue
        )
      ) ?? false;

    return isFieldValid(isInvalid);
  };

  const validationRX = (medicalPlan: MedicalPlan) => {
    const costType = ['inNetwork', 'outOfNetwork'] as const;
    const tiers = ['TIER_1', 'TIER_2', 'TIER_3', 'TIER_4'] as const;
    const isValid = costType.some((type) =>
      tiers.some(
        (tier) =>
          parseFloat(medicalPlan.rxCosts?.[type]?.[tier]?.copay) >=
          maxMedicalRXValue
      )
    );

    return isFieldValid(isValid);
  };

  const isFieldValid = (inValid: boolean) => {
    if (inValid) onError?.('Value must be less than $1,000,000');
    return inValid;
  };

  const onValidateFails = (validateSetting: string) =>
    onError?.(getValidationMessage(validateSetting));

  const checkValidation = async (currentPanel: PanelSection) => {
    let [isFormValidBasicInfo, isFormValidRates, validationError] = [
      true,
      true,
      false,
    ];
    try {
      if (currentPanel === PanelSection.BASIC_PLAN_INFO) {
      } else {
        await ratesForm.validateFields();
      }
    } catch (errorInfo) {
      validationError = true;
    }
    if (currentPanel === PanelSection.BASIC_PLAN_INFO) {
      isFormValidBasicInfo = !validationError;
    } else {
      isFormValidRates = !validationError;
    }

    return { isFormValidBasicInfo, isFormValidRates };
  };

  const nextStep = async (
    nextPanel: PanelSection,
    currentPanel: PanelSection
  ) => {
    if (currentPanel === PanelSection.RATES && editedMedicalPlan.rates) {
      dispatch(
        updateMedicalPlan({
          ...medicalPlan,
          rates: editedMedicalPlan.rates,
          hasSameContributions: editedMedicalPlan.hasSameContributions,
        })
      );
    }
    setIsNextClicked({ ...isNextClicked, [currentPanel]: true });

    const { isFormValidBasicInfo } = await checkValidation(currentPanel);

    if (isFormValidBasicInfo) {
      if (!inProgress || nextPanel !== PanelSection.DEDUCTIBLES_OOP_MAX) {
        setActivePanel(nextPanel);
        handleScrollToNextPanel(currentPanel, formRightWrapperRef);
      }
    }
    setIsFormValidBasicPlanInfo(isFormValidBasicInfo);

    let isInfoIncomplete = false;
    switch (currentPanel) {
      case PanelSection.BASIC_PLAN_INFO:
        // Basic Plan Info validations are in BasicPlanInfo.tsx
        return;
      case PanelSection.RATES:
        // Rates validations is done in Rates.tsx
        return;
      case PanelSection.DEDUCTIBLES_OOP_MAX:
        return;
      case PanelSection.SERVICES:
        if (medicalPlan.customServices?.length !== displayedServices.length) {
          isInfoIncomplete = true;
          break;
        }
        isInfoIncomplete = some(medicalPlan.customServices, (service) => {
          const { inNetwork, outOfNetwork } = service.serviceValue;
          return some([inNetwork, outOfNetwork], (costSharingObject) => {
            if (costSharingObject.costSharingPolicy === CostType.NOT_COVERED) {
              return false;
            }

            if (costSharingObject.costSharingPolicy === CostType.OTHER) {
              return (
                isEmptyValue(costSharingObject.info) ||
                isEmptyValue(costSharingObject.copayPriorToDeductible)
              );
            }

            if (costSharingObject.costSharingPolicy === CostType.COPAY) {
              return (
                isEmptyValue(costSharingObject.copay) ||
                isEmptyValue(costSharingObject.copayPriorToDeductible)
              );
            }
            if (costSharingObject.costSharingPolicy === CostType.COINSURANCE) {
              return (
                isEmptyValue(costSharingObject.coinsurance) ||
                isEmptyValue(costSharingObject.copayPriorToDeductible)
              );
            }

            return isEmptyValue(costSharingObject.costSharingPolicy);
          });
        });
        break;
      case PanelSection.RX:
        // RX validations is done in Services.tsx
        return;
      case PanelSection.ID_CARD_INFO:
        // validations handled in RxCardInfo.tsx
        return;
      case PanelSection.ENROLLMENTS:
        // validations handled in Addenrollments.tsx
        return;
      case PanelSection.PLAN_DOCUMENTS:
        // validations handled in AddPlan.tsx
        return;
    }

    setIsIncomplete({ ...isIncomplete, [currentPanel]: isInfoIncomplete });
    setTouchedSection('');
  };

  const collapseOnChange = (key: any) => {
    setActivePanel(key);
    const keysOfIsNext = Object.keys(isNextClicked);
    const panelsBelow = keysOfIsNext.splice(
      keysOfIsNext.indexOf(key),
      keysOfIsNext.length - 1
    );
    const newIsNextObj = Object.assign(
      isNextClicked,
      ...panelsBelow.map((panelIsNextClicked) => ({
        [panelIsNextClicked]: false,
      }))
    );
    setIsNextClicked(newIsNextObj);
  };

  const disabledCompleteAndSave =
    (isReview && !disableOnUpdatePlan) ||
    (!disableOnUpdatePlan && !isNextClicked.basicPlanInfo) ||
    inProgress ||
    isDisable ||
    isLoading ||
    documentUploadInProgress ||
    updatingMedicalPlan;

  const popoverTooltipContent = (heading: string, content: string) => (
    <div>
      <QuestionCircleOutlined />
      <span className="text popover-title"> {heading} </span>
      <p> {content}</p>
    </div>
  );

  const getCollapsible = (isNextClicked: boolean): 'disabled' | undefined => {
    const disablCollapsible = [
      ProcessStatus.VALIDATING,
      ProcessStatus.VALIDATED,
      ProcessStatus.PROCESSING,
      !isOpsAdmin && ProcessStatus.SUCCESS,
    ].includes(status);
    if (!(planApproach !== PlanApproach.NOT_SELECTED && isNextClicked))
      return 'disabled';
    else if (disablCollapsible) return 'disabled';
  };

  const getCollapsibleWithValidation = (
    isNextClicked: boolean,
    panel: PanelSection
  ): 'disabled' | undefined => {
    const disablCollapsible = [
      ProcessStatus.VALIDATING,
      ProcessStatus.VALIDATED,
      ProcessStatus.PROCESSING,
      !isOpsAdmin && ProcessStatus.SUCCESS,
    ].includes(status);
    let finalValue = getCollapsible(isNextClicked);
    if (panel === PanelSection.RATES && !isFormValidBasicPlanInfo) {
      finalValue = 'disabled';
    }
    if (panel === PanelSection.DEDUCTIBLES_OOP_MAX && inProgress) {
      finalValue = 'disabled';
    }
    if (disablCollapsible) {
      finalValue = 'disabled';
    }
    return finalValue;
  };

  const getPanelClassName = (
    activePanel: string,
    panel: PanelSection
  ): string => {
    const defaultValues = getSBCDefaultValues();
    const isPanelInDefaultValues = Object.keys(defaultValues).includes(panel);

    const showUpdatePlanPanels = isFromUpdatePan && !isPanelInDefaultValues;

    return showUpdatePlanPanels
      ? styles.disabledPanelColor
      : activePanel === panel
      ? styles.visitPanel
      : styles.notVisitPanel;
  };

  const isPlanSaveDisabled = some(
    Object.values(get(medicalPlan, 'documentReferences', {})),
    (value) => get(value, 'uploading', false)
  );

  const getPanelHeaderExtra = (
    panel: keyof typeof PanelSection,
    isAiCheck?: boolean,
    isSBC?: boolean
  ) => {
    if (panel === 'ADDITIONAL_RESOURCES' || panel === 'PLAN_INFORMATION')
      return <></>;
    return (
      planApproach !== PlanApproach.NOT_SELECTED && (
        <PanelHeaderExtra
          isSBC={isSBC}
          isAi={isAiCheck}
          isOpsAdmin={isOpsAdmin}
          isActive={activePanel === PanelSection[panel]}
          isComplete={!isIncomplete[PanelSection[panel]]}
          popoverContent={
            panel !== 'ENROLLMENTS' &&
            popoverTooltipContent(TooltipHeadings[panel], Tooltips[panel])
          }
          isFromUpdatePan={isFromUpdatePan}
          isUploading={[ProcessStatus.PROCESSING].includes(status)}
        />
      )
    );
  };

  const addAiFeedback = (planFeedback: AIUploaderFeedback) => {
    dispatch(setUserAiFeedback(planFeedback));
  };

  const removeAiFeedback = (feedbackPanel: string) => {
    dispatch(removeUserAiFeedback(feedbackPanel));
  };
  const uploadDocument = async (id: string, isSuccess?: boolean) => {
    const documentUploadSuccess = () => {
      setSelectedFileList([]);
      setSelectedWeblink([]);
      onSave?.(isSuccess ?? false);
      onClose();
    };
    const planId = id ?? plan.id;
    const removeDocs = Object.keys(isDocRemoved)
      .filter((documentType) => isDocRemoved[documentType])
      .map((documentType) => ({
        docType: PLAN_ADDITIONAL_DOCUMENT,
        planDocumentName: documentType,
      }));
    if (!isEmpty(selectedFileList) && isEmpty(removeDocs)) {
      await dispatch(
        uploadPlanDocument(
          selectedFileList,
          planId,
          BenefitCategory.MEDICAL.value
        )
      );
    } else if (!isEmpty(removeDocs) && isEmpty(selectedFileList)) {
      await dispatch(
        removePlanDocuments(planId, BenefitCategory.MEDICAL.value, removeDocs)
      );
    } else if (!isEmpty(removeDocs) && !isEmpty(selectedFileList)) {
      await dispatch(
        uploadRemovePlanDocument(
          selectedFileList,
          planId,
          BenefitCategory.MEDICAL.value,
          removeDocs
        )
      );
    }
    if (selectedWeblink.length > 0) {
      await dispatch(
        updateWeblinks(selectedWeblink, planId, BenefitCategory.MEDICAL.value)
      );
    }
    documentUploadSuccess();
  };

  return (
    <>
      <div className={styles.addPlanWrapper}>
        <Collapse
          activeKey={activePanel}
          bordered={false}
          accordion
          defaultActiveKey={PanelSection.BASIC_PLAN_INFO}
          onChange={collapseOnChange}
          className={styles.collapseWrapper}
        >
          <Panel
            header={
              <span
                className={getPanelClassName(
                  activePanel,
                  PanelSection.BASIC_PLAN_INFO
                )}
              >
                1. Basic Plan Info *
              </span>
            }
            collapsible={getCollapsible(isReview ? false : true)}
            key={PanelSection.BASIC_PLAN_INFO}
            showArrow={false}
            extra={getPanelHeaderExtra('BASIC_PLAN_INFO')}
          >
            <PanelHeader optional={false} />
            <BasicPlanInfoSBCWrapper
              wrappedRef={basicPlanInfoRef}
              onChange={onChange}
              dbgPlanYear={dbgPlanYear}
            />
            <PanelFooter
              nextStep={async () => {
                setTouchedSection(PanelSection.BASIC_PLAN_INFO);

                const { current = {} } = basicPlanInfoRef as any;
                const { validate } = current;
                const { isComplete, isValid } = await validate();
                if (isValid) {
                  await onChange({
                    ...medicalPlan,
                    rates: buildRates(
                      editedMedicalPlan.groups,
                      getDefaultPlanRates(editedMedicalPlan)
                    ),
                  });
                  nextStep(PanelSection.RATES, PanelSection.BASIC_PLAN_INFO);
                  setIsIncomplete({
                    ...isIncomplete,
                    [PanelSection.BASIC_PLAN_INFO]: !isComplete,
                  });

                  setActivePanel(PanelSection.RATES);
                }
              }}
              isDisable={isDisable}
            />
          </Panel>
          <Panel
            header={
              <span
                className={getPanelClassName(activePanel, PanelSection.RATES)}
              >
                2. Premiums & Contributions
              </span>
            }
            collapsible={getCollapsibleWithValidation(
              isNextClicked.basicPlanInfo,
              PanelSection.RATES
            )}
            key={PanelSection.RATES}
            showArrow={false}
            extra={getPanelHeaderExtra('RATES')}
            className={styles.ratesWrapper}
          >
            <PanelHeader optional={false} />
            <div className={styles.groupCollapse}>
              <RatesSBCWrapper
                wrappedRef={ratesRef}
                form={ratesForm}
                currentSection={activePanel}
                updateValidationMsg={(valid: boolean) =>
                  setIsFormValidRates(valid)
                }
              />
            </div>
            <PanelFooter
              validationWarning={
                !isFormValidRates
                  ? 'Please fill in the remaining fields to continue'
                  : ''
              }
              showValidationInfo={inProgress && isNextClicked.rates}
              validationInfo="Please wait till the file upload completes to navigate to the next
                section"
              nextStep={() => {
                setTouchedSection(PanelSection.RATES);

                const { current = {} } = ratesRef as any;
                const { validate, nextClicked } = current;
                nextClicked(true);
                const { invalidFormFields = [], isComplete } = validate();
                if (invalidFormFields.length === 0) {
                  nextStep(
                    PanelSection.DEDUCTIBLES_OOP_MAX,
                    PanelSection.RATES
                  );

                  setIsIncomplete({
                    ...isIncomplete,
                    [PanelSection.RATES]: !isComplete,
                  });
                  setIsFormValidRates(true);
                } else {
                  setIsFormValidRates(false);
                }
              }}
              isDisable={isDisable}
            />
          </Panel>
          <Panel
            header={
              <span
                className={getPanelClassName(
                  activePanel,
                  PanelSection.DEDUCTIBLES_OOP_MAX
                )}
              >
                3. Deductibles & OOP Max
              </span>
            }
            collapsible={
              isReview
                ? undefined
                : getCollapsibleWithValidation(
                    isNextClicked.rates,
                    PanelSection.DEDUCTIBLES_OOP_MAX
                  )
            }
            key={PanelSection.DEDUCTIBLES_OOP_MAX}
            showArrow={false}
            extra={getPanelHeaderExtra(
              'DEDUCTIBLES_OOP_MAX',
              isSBCPanelVsited[PanelSection.DEDUCTIBLES_OOP_MAX],
              isAiEnabled
            )}
          >
            {isFromUpdatePan && <UpdatePlanParametersInfo />}
            <DeductiblesOOPMaxSBCWrapper
              wrappedRef={deductiblesRef}
              onChange={onChange}
              isTouched={PanelSection.DEDUCTIBLES_OOP_MAX === touchedSection}
            />
            <PanelFooter
              nextStep={async () => {
                const { current = {} } = deductiblesRef as any;
                const { validate } = current;
                setTouchedSection(PanelSection.DEDUCTIBLES_OOP_MAX);
                setSBCPanelVisited({
                  ...isSBCPanelVsited,
                  [PanelSection.DEDUCTIBLES_OOP_MAX]: false,
                });
                const isInvalidValues = validateFieldLength(medicalPlan);
                if (!isInvalidValues) {
                  const isComplete = await validate();
                  nextStep(
                    PanelSection.SERVICES,
                    PanelSection.DEDUCTIBLES_OOP_MAX
                  );
                  setIsIncomplete({
                    ...isIncomplete,
                    [PanelSection.DEDUCTIBLES_OOP_MAX]: !isComplete,
                  });
                }
              }}
              isDisable={isDisable}
              buttonText={isAiEnabled ? 'Approve' : undefined}
            />
            {showFeedbackPanel && (
              <AiFeedbackReceiver
                planSection={
                  MDV_FEEDBACK_PANEL_TYPES[PanelSection.DEDUCTIBLES_OOP_MAX]
                }
                setFeedback={addAiFeedback}
                removeFeedback={removeAiFeedback}
              />
            )}
          </Panel>
          <Panel
            id="services"
            header={
              <span
                className={getPanelClassName(
                  activePanel,
                  PanelSection.SERVICES
                )}
              >
                4. Services
              </span>
            }
            collapsible={
              isReview
                ? undefined
                : getCollapsible(isNextClicked.deductiblesOopMax)
            }
            key={PanelSection.SERVICES}
            showArrow={false}
            extra={getPanelHeaderExtra(
              'SERVICES',
              isSBCPanelVsited[PanelSection.SERVICES],
              isAiEnabled
            )}
          >
            {isFromUpdatePan && <UpdatePlanParametersInfo />}
            <ServicesSBCWrapper
              type={PanelSection.SERVICES}
              isTouched={PanelSection.SERVICES === touchedSection}
            />

            <PanelFooter
              serviceName="Service"
              onAddBtnClicked={(e: any) => setIsAddCustomServiceModalOpen(true)}
              nextStep={() => {
                setTouchedSection(PanelSection.SERVICES);
                const isInvalid = validateCustomServices(medicalPlan);
                setSBCPanelVisited({
                  ...isSBCPanelVsited,
                  [PanelSection.SERVICES]: false,
                });

                if (!isInvalid) {
                  nextStep(PanelSection.RX, PanelSection.SERVICES);
                } else {
                  onError?.('Value must be less than $1,000,000');
                }
              }}
              isDisable={isDisable}
              buttonText={isAiEnabled ? 'Approve' : undefined}
            />
            <AddCustomServiceModal
              planTypeName="Medical"
              benefitKind={BenefitCategory.MEDICAL.value}
              visible={isAddCustomServiceModalOpen}
              displayedServices={displayedServices}
              onClose={() => setIsAddCustomServiceModalOpen(false)}
              hsaCompatible={medicalPlan?.hsaCompatible}
            />
            {showFeedbackPanel && (
              <AiFeedbackReceiver
                planSection={MDV_FEEDBACK_PANEL_TYPES[PanelSection.SERVICES]}
                setFeedback={addAiFeedback}
                removeFeedback={removeAiFeedback}
              />
            )}
          </Panel>
          <Panel
            header={
              <span className={getPanelClassName(activePanel, PanelSection.RX)}>
                5. RX
              </span>
            }
            collapsible={
              isReview ? undefined : getCollapsible(isNextClicked.services)
            }
            key={PanelSection.RX}
            showArrow={false}
            extra={getPanelHeaderExtra(
              'RX',
              isSBCPanelVsited[PanelSection.RX],
              isAiEnabled
            )}
          >
            {isFromUpdatePan && <UpdatePlanParametersInfo />}
            <ServicesSBCWrapper
              wrappedRef={rxRef}
              type={PanelSection.RX}
              isTouched={PanelSection.RX === touchedSection}
            />
            <PanelFooter
              nextStep={() => {
                setTouchedSection(PanelSection.RX);
                setSBCPanelVisited({
                  ...isSBCPanelVsited,
                  [PanelSection.RX]: false,
                });
                const { current = {} } = rxRef as any;
                const { validate } = current;
                const { isComplete } = validate();
                const isInvalid = validationRX(medicalPlan);

                if (!isInvalid) {
                  nextStep(PanelSection.ID_CARD_INFO, PanelSection.RX);
                  setIsIncomplete({
                    ...isIncomplete,
                    [PanelSection.RX]: !isComplete,
                  });
                }
              }}
              isDisable={isDisable || isReview || disableOnUpdatePlan}
              buttonText={isAiEnabled ? 'Approve' : undefined}
            />
            {showFeedbackPanel && (
              <AiFeedbackReceiver
                planSection={MDV_FEEDBACK_PANEL_TYPES[PanelSection.RX]}
                setFeedback={addAiFeedback}
                removeFeedback={removeAiFeedback}
              />
            )}
          </Panel>
          <Panel
            header={
              <span
                className={getPanelClassName(
                  activePanel,
                  PanelSection.ID_CARD_INFO
                )}
              >
                6. ID Card Information
              </span>
            }
            collapsible={getCollapsible(isNextClicked.rx)}
            key={PanelSection.ID_CARD_INFO}
            showArrow={false}
            extra={getPanelHeaderExtra('ID_CARD_INFO')}
          >
            <RxCardInfo onChange={onChange} ref={rxCardInfoRef} />
            <PanelFooter
              nextStep={async () => {
                setTouchedSection(PanelSection.ID_CARD_INFO);
                const { current = {} } = rxCardInfoRef as any;
                const { validate } = current;
                const { isComplete } = await validate();

                nextStep(PanelSection.ENROLLMENTS, PanelSection.ID_CARD_INFO);
                setIsIncomplete({
                  ...isIncomplete,
                  [PanelSection.ID_CARD_INFO]: !isComplete,
                });
              }}
              isDisable={isDisable}
            />
          </Panel>
          <Panel
            header={
              <span
                className={getPanelClassName(
                  activePanel,
                  PanelSection.ENROLLMENTS
                )}
              >
                7. Enrollments
              </span>
            }
            collapsible={getCollapsible(isNextClicked.idCardInfo)}
            key={PanelSection.ENROLLMENTS}
            showArrow={false}
            extra={getPanelHeaderExtra('ENROLLMENTS')}
          >
            <AddEnrollments
              benefitKind={BenefitCategory.MEDICAL.value}
              onChange={onChange}
              plan={medicalPlan}
              isEdit={false}
              ref={enrollmentRef}
            />
            <PanelFooter
              nextStep={async () => {
                setTouchedSection(PanelSection.ENROLLMENTS);
                const { current = {} } = enrollmentRef as any;
                const { validate } = current;
                const { isComplete } = await validate();

                nextStep(PanelSection.PLAN_DOCUMENTS, PanelSection.ENROLLMENTS);
                setIsIncomplete({
                  ...isIncomplete,
                  [PanelSection.ENROLLMENTS]: !isComplete,
                });
              }}
              isDisable={isDisable}
            />
          </Panel>
          <Panel
            header={
              <span
                className={getPanelClassName(
                  activePanel,
                  PanelSection.PLAN_DOCUMENTS
                )}
              >
                8. Plan Resources
              </span>
            }
            collapsible={getCollapsible(isNextClicked.enrollments)}
            key={PanelSection.PLAN_DOCUMENTS}
            showArrow={false}
            extra={getPanelHeaderExtra('PLAN_DOCUMENTS')}
          >
            <PanelHeader optional={true} />
            <PlanDocumentUPWrapper
              onValidateFails={onValidateFails}
              isDisable={isDisable}
              isAFPReview
            />
            <AdditionalPlanResources
              ref={ref}
              plan={plan}
              benefitKind={BenefitCategory.MEDICAL.value}
              isCloseConfirmed={false}
              selectedFileList={selectedFileList}
              setSelectedFileList={setSelectedFileList}
              selectedWebLinkList={selectedWeblink}
              setSelectedWebLinkList={setSelectedWeblink}
              setIsDocRemoved={setIsDocRemoved}
            />
            <PanelFooter
              nextStep={() => {
                setTouchedSection(PanelSection.PLAN_DOCUMENTS);
                nextStep('' as PanelSection, PanelSection.PLAN_DOCUMENTS);
                setIsIncomplete({
                  ...isIncomplete,
                  [PanelSection.PLAN_DOCUMENTS]:
                    Object.values(medicalPlan?.documentReferences ?? {})
                      .length !== 3,
                });
              }}
              showLoadingIcon={
                updatingMedicalPlan || isPlanSaveDisabled || inProgress
              }
              isLastStep={false}
              isDisable={isDisable}
              isRenewalProcessStarted={isRenewalProcessStarted}
              planName={editedMedicalPlan?.name ?? ''}
            />
          </Panel>
        </Collapse>
      </div>
      <NextButton
        type="primary"
        buttonText={
          <>
            {(inProgress ||
              updatingMedicalPlan ||
              isLoading ||
              documentUploadInProgress) && <Spin />}
            Complete and Save Plan {isAnUpdateOfferedPlan ? 'Parameters' : ''}
          </>
        }
        className={styles.completeButton}
        disabled={disabledCompleteAndSave}
        nextStep={savePlanAndInitiateWarning}
      />
      {!isEmpty(docExtractionSource) && (
        <PlanUpdateWarningModal
          type={docExtractionSource?.source as UpdateDocumentExtractionSource}
          isLoading={false}
          isOpen={showUpdatePlanWarning}
          onClose={() => setShowUpdatePlanWarning(false)}
          onConfirm={savePlan}
          confirmText="Continue"
        />
      )}
    </>
  );
});

AddPlan.displayName = 'AddPlan';

export default AddPlan;
