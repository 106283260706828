import { useEffect, useMemo, useState } from 'react';
import { Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { InfoCircleOutlined } from '@ant-design/icons';

import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import TextButton from 'components/buttons/TextButton/TextButton';
import SummeryCommonTable from 'modules/renewals/components/helperComponents/SummeryCommonTable/SummeryCommonTable';
import { useProposalStatusUpdateMutation } from 'modules/renewals/slices/proposalSlice';
import styles from 'modules/renewals/components/AppProposalToPlanYearComponents/ReviewAndComplete/ReviewAndComplete.module.less';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import ReviewModal from 'modules/renewals/components/AppProposalToPlanYearComponents/ReviewModal/ReviewModal';
import { showSuccessNotification } from 'components/Notification/Notification';
import { clearData } from 'modules/renewals/slices/addProposalToPlanYearSlice';

import { getTypeIcon } from 'modules/renewals/utils/renewalsUtils';
import {
  OFFER_BENEFIT_TYPES,
  PROPOSAL_STATUS_FINALISED,
} from 'modules/renewals/constants/renewalsConstants';
import {
  GENERAL_ERROR_PLAN_YEAR_FAILED,
  OVERLAP_ERROR_PLAN_YEAR_CODE,
  OVERLAP_ERROR_PLAN_YEAR_FAILED,
  PLAN_HAS_BEEN_MERGED_WARNING,
  PLANS_HAVE_BEEN_MERGED_WARNING,
} from 'modules/renewals/constants/addToPlanYearConstants';

import {
  IStepperStep,
  OfferBenefitType,
  TransformedPlan,
} from 'modules/renewals/types/renewalsTypes';
import { transformToFinalizedRenewalList } from 'modules/renewals/utils/addToPlanYearUtil';
import { useCreatePlansWithPlanYearMutation } from 'modules/renewals/slices/renewalsSlice';
import { PLAN_YEARS_TAB } from 'modules/employers/constants/employerConstants';
import { resetPlanReduxStore } from 'modules/plans/slices/lifePlanSlice';

interface Props extends IStepperStep {}

const ReviewAndComplete = ({
  onCloseModal = () => {},
  updateProposalStatus,
  setIsProposalAddToPlanYearVisible,
}: Props) => {
  const plans = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.plans
  );
  const proposalSummary = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.proposalSummary
  );

  const [errorMsg, setErrorMsgTxt] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);

  const proposalToPlanYearBasicInfo = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.basicDetails
  );

  const planCreateType = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.selectPlanCreateType
  );

  const [
    createPlanYearWithPlans,
    {
      isLoading: isPlanYearCreateLoading,
      isSuccess: isPlanYearCreateSuccess,
      isError: isPlanYearCreateError,
      error: planYearError,
    },
  ] = useCreatePlansWithPlanYearMutation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isPlanYearCreateError) {
      const errorObj: any = planYearError;
      if (OVERLAP_ERROR_PLAN_YEAR_CODE == errorObj?.data.code) {
        setErrorMsgTxt(
          errorObj?.data?.details?.msg ?? OVERLAP_ERROR_PLAN_YEAR_FAILED
        );
      } else {
        setErrorMsgTxt(GENERAL_ERROR_PLAN_YEAR_FAILED);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlanYearCreateError]);

  const [showReviewPlanModal, setShowReviewPlanModal] = useState<{
    show: boolean;
    planData: TransformedPlan | null;
  }>({
    show: false,
    planData: null,
  });

  const [finalizeProposal, { isSuccess: isFinalizeProposalSuccess, reset }] =
    useProposalStatusUpdateMutation();

  const isAllMergePlansReviewed = useMemo(
    () =>
      Object.values(plans)
        .flat()
        .filter((item) => item.isMerged)
        .every((item) => item.isReviewed),
    [plans]
  );

  const isMerging = planCreateType === 'MERGE' && !isAllMergePlansReviewed;

  const handleCreateNewPlanYearClick = () => {
    setErrorMsgTxt('');
    const finalizedRenewalList = transformToFinalizedRenewalList(
      proposalToPlanYearBasicInfo,
      proposalSummary.data!,
      plans
    );

    createPlanYearWithPlans({ renewalPlanList: finalizedRenewalList });
  };

  useEffect(() => {
    if (
      isPlanYearCreateSuccess &&
      proposalSummary?.data?.proposalId &&
      updateProposalStatus
    ) {
      // TODO - Change this if not necessary
      if (setIsProposalAddToPlanYearVisible) {
        setIsProposalAddToPlanYearVisible();
      }
      finalizeProposal({
        proposalId: proposalSummary?.data?.proposalId,
        proposalStatus: PROPOSAL_STATUS_FINALISED,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlanYearCreateLoading, isPlanYearCreateSuccess]);

  useEffect(() => {
    let duplicateEntry = false;
    Object?.entries(plans)?.forEach(([benefit, planitems]) => {
      const nameArray: any[] = [];
      if (benefit === 'LIFE_AND_DISABILITY') {
        let exists = false;
        planitems.forEach((plan) => {
          nameArray.map((item) => {
            if (item[0] === plan.planName && item[1] === plan.planType) {
              exists = true;
            }
          });
          if (exists) {
            duplicateEntry = true;
          } else {
            nameArray.push([plan.planName ?? '', plan.planType ?? '']);
          }
        });
      } else {
        planitems.forEach((plan) => {
          if (nameArray.includes(plan.planName ?? '')) {
            duplicateEntry = true;
          } else {
            nameArray.push(plan.planName ?? '');
          }
        });
      }
    });

    setIsError(duplicateEntry);
    setErrorMsgTxt(
      duplicateEntry ? 'Duplicate plan name for same benefit type found' : ''
    );
  }, [plans]);

  const navigate = useNavigate();
  const { brokerId, employerId } = useParams();

  useEffect(() => {
    if (isFinalizeProposalSuccess) {
      onCloseModal();
      dispatch(clearData());
      showSuccessNotification({
        message: 'Successfully Created',
        description: 'Successfully created new plan year and saved plans',
      });
      dispatch(resetPlanReduxStore());
      reset();
      navigate(`/brokers/${brokerId}/employers/${employerId}/info`, {
        state: { tab: PLAN_YEARS_TAB, afpPlanYearCreated: true },
      });
    }
    // eslint-disable-next-line
  }, [isFinalizeProposalSuccess]);

  const handleCloseReviewModal = () => {
    setShowReviewPlanModal({ show: false, planData: null });
  };

  const planTableColumns: ColumnsType<TransformedPlan> = [
    {
      title: 'Proposal Plans',
      key: 'planName',
      dataIndex: 'planName',

      render: (data) => <b>{`${data || '-'}`}</b>,
    },
    {
      title: '',
      key: '',
      dataIndex: '',
      align: 'right',
      render: (_, record) => {
        return (
          <Row justify="end" align="middle" wrap={false}>
            {!record?.isReviewed && record?.isMerged && (
              <PlanyearPopover
                content={PLAN_HAS_BEEN_MERGED_WARNING}
                width={260}
                placement="left"
              >
                <InfoCircleOutlined className={styles.popoverIcon} />
              </PlanyearPopover>
            )}
            <TextButton
              type="primary"
              label="Review Plan Details"
              onClick={() =>
                setShowReviewPlanModal({ show: true, planData: record })
              }
            />
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <div className={styles.container}>
        {errorMsg && <FixedAlertMessage message={errorMsg} type="error" />}
        <h2 className={styles.headerTextTop}>Review Plans</h2>
        <p className={styles.subText}>
          Plans are ready to be added to the new Plan Year. You can review each
          plans details below.
        </p>
        {isMerging && (
          <FixedAlertMessage
            message={PLANS_HAVE_BEEN_MERGED_WARNING}
            type="warning"
          />
        )}
        {Object?.entries(plans)?.map(([benefit, plans]) => {
          return (
            <div key={benefit} className={styles.tableComponentWrapper}>
              <Row align="middle">
                <img
                  className={styles.headerIcon}
                  src={
                    getTypeIcon(
                      OFFER_BENEFIT_TYPES[benefit as OfferBenefitType]
                    ).icon
                  }
                />
                <span className={styles.headerText}>
                  {OFFER_BENEFIT_TYPES[benefit as OfferBenefitType]}
                </span>
              </Row>

              <SummeryCommonTable
                data={plans || []}
                columns={planTableColumns}
                emptyMessage={
                  <span className={styles.tableEmptyText}>
                    There are no plans for this benefit type
                  </span>
                }
              />
            </div>
          );
        })}
        <PageActionButton
          type="primary"
          className={styles.nextBtn}
          onClick={() => handleCreateNewPlanYearClick()}
          loading={isPlanYearCreateLoading}
          disabled={isError}
        >
          Create New Plan Year and Save Plans
        </PageActionButton>
        <div className={styles.cancelBtnWrapper}>
          <TextButton
            label="Cancel"
            className={styles.cancelButton}
            onClick={() => onCloseModal(true)}
            type="primary"
          />
        </div>
      </div>
      {showReviewPlanModal.show && showReviewPlanModal.planData && (
        <ReviewModal
          visible={showReviewPlanModal.show}
          data={showReviewPlanModal.planData}
          handleClose={handleCloseReviewModal}
        />
      )}
    </>
  );
};

export default ReviewAndComplete;
