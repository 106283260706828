import { lumityApi } from 'api/apiSlice';

const baseCarrierUrl = `v2/plans/carrier`;
const baseEmployerUrl = `v2/plans/employers`;
const basePlansUrl = `v2/plans`;

const policyNumberCarrierContactsApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getCarrierPlanListByEmployerId: builder.query<any, { employerId: string }>({
      query: ({ employerId }) => {
        return {
          url: `${baseEmployerUrl}/${employerId}/plan-list`,
          method: 'GET',
        };
      },
    }),
    getCarrierContactListByEmployerId: builder.query<
      any,
      { employerId: string; carrierId: string }
    >({
      query: ({ employerId, carrierId }) => {
        return {
          url: `${baseCarrierUrl}/carrier-contact/employer-carrier-contacts`,
          method: 'GET',
          params: {
            'carrier-id': carrierId,
            'employer-id': employerId,
          },
        };
      },
    }),
    updateGroupId: builder.mutation<
      any,
      { masterId: string; benefitCategory: string; groupId: string }
    >({
      query: ({ masterId, benefitCategory, groupId }) => {
        return {
          url: `${basePlansUrl}/${masterId}/update-group-id`,
          method: 'PUT',
          params: {
            'benefit-category': benefitCategory,
            'group-id': groupId,
          },
        };
      },
    }),
    updateCarrierContactPlans: builder.mutation<
      any,
      {
        employerId: string;
        benefitCategory: string;
        carrierId: string;
        carrierContactId: string;
      }
    >({
      query: ({ employerId, benefitCategory, carrierId, carrierContactId }) => {
        return {
          url: `${baseCarrierUrl}/benefit-category-carrier-contact`,
          method: 'POST',
          params: {
            'employer-id': employerId,
            'benefit-category': benefitCategory,
            'carrier-id': carrierId,
            'carrier-contact-id': carrierContactId,
          },
        };
      },
    }),
  }),
});

export const {
  useLazyGetCarrierPlanListByEmployerIdQuery,
  useLazyGetCarrierContactListByEmployerIdQuery,
  useUpdateGroupIdMutation,
  useUpdateCarrierContactPlansMutation,
} = policyNumberCarrierContactsApi;
