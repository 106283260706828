import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import ErrorDetails from 'model/ErrorDetails';
import { validateEmailAndOtp } from 'modules/auth/services/AuthService';

interface RegisterWithCodeAndEmailValidationState {
  inProgress: boolean;
  error: ErrorDetails | null;
  token: string | null;
}

const initialState: RegisterWithCodeAndEmailValidationState = {
  inProgress: false,
  error: null,
  token: null,
};

const registerWithCodeAndEmailValidationSlice = createSlice({
  name: 'registerWithCodeAndEmailValidation',
  initialState,
  reducers: {
    validationStarted(state) {
      state.inProgress = true;
      state.error = null;
      state.token = null;
    },
    validationSuccess(state, action: PayloadAction<string>) {
      state.inProgress = false;
      state.error = null;
      state.token = action.payload;
    },
    validationFailed(state, action: PayloadAction<ErrorDetails>) {
      state.inProgress = false;
      state.error = action.payload;
      state.token = null;
    },
    resetValidationState(state) {
      state.inProgress = false;
      state.error = null;
      state.token = null;
    },
  },
});

export const {
  validationStarted,
  validationSuccess,
  validationFailed,
  resetValidationState,
} = registerWithCodeAndEmailValidationSlice.actions;

export default registerWithCodeAndEmailValidationSlice.reducer;

export const validateEmailAndCode =
  (email: string, otp: string, callback: Function) =>
  async (dispatch: Dispatch) => {
    dispatch(validationStarted());
    try {
      const response = await validateEmailAndOtp(email, otp);
      dispatch(validationSuccess(response.data.token));
      callback(response.data.token);
    } catch (error: any) {
      if (error?.response) {
        dispatch(validationFailed(error?.response.data));
        console.error(error);
      } else {
        console.error(error);
        dispatch(validationFailed(error?.message));
      }
    }
  };
