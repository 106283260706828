import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Select } from 'antd';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import {
  EMPLOYER_ADMIN_TOOLTIP,
  EMPLOYER_MEMBER_TOOLTIP,
  EMPTY_MESSAGE,
} from 'constants/commonConstants';
import Admin from 'model/admin/Admin';
import styles from './customEmployerUser.module.less';

const CustomEmployerUserDropDown = (props: {
  options: any;
  formData?: Admin;
  isEmptyField?: Function;
}) => {
  const { options, formData, isEmptyField } = props;

  return (
    <Form.Item
      name="individualSubType"
      label="User Type"
      labelCol={{ span: 24 }}
      rules={[
        {
          required: true,
          message: EMPTY_MESSAGE,
        },
      ]}
      className={isEmptyField!(formData?.individualSubType)}
    >
      <Select className={styles.brokerAdminTypeWrapper} optionLabelProp="label">
        {options.map((item: any) => (
          <Select.Option
            value={item?.value}
            key={item?.value}
            label={item?.label}
          >
            <span className={styles.dropDownOption}>
              {item.label}
              <div className={styles.popOverArea}>
                <PlanyearPopover
                  zIndex={99999}
                  content={
                    item?.label === 'Employer Admin'
                      ? EMPLOYER_ADMIN_TOOLTIP
                      : EMPLOYER_MEMBER_TOOLTIP
                  }
                >
                  <QuestionCircleOutlined />
                </PlanyearPopover>
              </div>
            </span>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default CustomEmployerUserDropDown;
