import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { Col, Form, Input, Row, Select, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import InputForm from 'components/InputForm/InputForm';
import { EMPTY_MESSAGE } from 'modules/employers/constants/employerConstants';
import Employer from 'model/Employer';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import AlertMessage from 'components/Alert/AlertMessage';
import { formVerificationMsg } from 'constants/commonConstants';
import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import {
  useCloneProposalByIdMutation,
  useCreateProposalMutation,
  useLazyGetProposalByIdQuery,
  useSaveOfferDiscountMutation,
  useUpdateProposalMutation,
} from 'modules/renewals/slices/proposalSlice';
import ProposalDto from 'model/ProposalDto';
import { attachProposal } from 'modules/renewals/slices/proposalCreateStepperSlice';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import TextButton from 'components/buttons/TextButton/TextButton';
import styles from './proposalBasicInfo.module.less';

type BasicInfoProps = {
  nextStep: () => void;
  closeModal: (
    notShowConfirmMessage: any,
    proposalStatus?: string | undefined
  ) => void;
  isModalOpen?: boolean;
  isEditMode?: boolean;
  employerEditData?: Employer | null;
  proposalId?: string;
  cloneProposal?: boolean;
  handleProposalId?: (proposalId: string) => void;
  isProposalCreateModalOpen?: boolean;
  isNext?: boolean;
  setIsNextStep?: () => void;
  isLoading?: boolean;
};

const ProposalBasicInfo = (props: BasicInfoProps, ref: any) => {
  const {
    proposalId,
    closeModal,
    cloneProposal = false,
    handleProposalId,
    isNext = false,
    setIsNextStep,
    isLoading = false,
    isModalOpen,
  } = props;
  const [form] = Form.useForm();

  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [effectiveDates, setEffectiveDates] = useState<string>('');
  const [isCloneMode, setCloneMode] = useState<boolean>(false);
  const [isClose, setIsClose] = useState<boolean>(false);
  const { employerId, brokerId } = useParams();
  const dispatch = useAppDispatch();

  const proposalDetails = useAppSelector(
    (state) => state.proposalOnBoarding.proposal
  );

  const [
    updateProposal,
    {
      isLoading: isProposalUpdateLoading,
      isSuccess: isProposalUpdateSuccess,
      data: updatedProposal,
    },
  ] = useUpdateProposalMutation();

  // fetch the effective dates
  const [
    getUpcomingPlanYears,
    {
      isSuccess: isUpcomingFetchSuccess,
      isLoading: isUpcomingFetchLoading,
      data: upcomingData,
    },
  ] = useLazyGetUpcomingPlanYearsByEmployerQuery();

  const [
    getProposalById,
    {
      isSuccess: isProposalFetchSuccess,
      isFetching: isProposalFetchLoading,
      data: proposalData,
    },
  ] = useLazyGetProposalByIdQuery();

  const [
    cloneProposalById,
    {
      isLoading: isProposalCloneLoading,
      isSuccess: isProposalCloneSuccess,
      data: proposalCloneData,
    },
  ] = useCloneProposalByIdMutation();

  useEffect(() => {
    form?.resetFields();
    setDescription('');
    setAlertVisible(false);
    setEffectiveDates('');
    setAlertMessage('');

    if (employerId) {
      getUpcomingPlanYears({ employerId: String(employerId) });
    }
    setIsClose(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  useEffect(() => {
    setCloneMode(cloneProposal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloneProposal]);

  useEffect(() => {
    if (proposalId || proposalDetails?.payload) {
      getProposalById({
        proposalId: proposalId ? proposalId : proposalDetails?.payload.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalId]);

  const setInitialProposalData = (proposalData: any) => {
    if (!isEmpty(proposalData)) {
      form.setFieldsValue({
        proposalName:
          cloneProposal && !isNext
            ? `Clone - ${proposalData?.name}`
            : proposalData?.name,
        description: proposalData?.description,
        effectiveDates: proposalData?.effectiveDateId,
      });
      setDescription(proposalData?.description);
      setEffectiveDates(proposalData?.effectiveDateId);
      dispatch(attachProposal(proposalData));
    }
  };

  useEffect(() => {
    if (isEmpty(proposalId)) {
      setInitialProposalData({});
    } else if (proposalCloneData && cloneProposal) {
      setInitialProposalData(proposalCloneData);
    } else if (updatedProposal) {
      setInitialProposalData(updatedProposal);
    } else {
      setInitialProposalData(proposalData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isProposalUpdateSuccess,
    isProposalCloneSuccess,
    isProposalFetchSuccess,
    proposalData,
    proposalCloneData,
    updatedProposal,
    cloneProposal,
    isModalOpen,
    proposalId,
  ]);

  const upcomingDataSet: any[] = isUpcomingFetchSuccess
    ? upcomingData?.upcomingPlanYears
        ?.filter((obj: any) => null === obj.planYearId)
        .map((obj) => {
          return {
            label: dayjs(obj.effectiveStartDate)
              .format('ll')
              .concat(' - ', dayjs(obj.effectiveEndDate).format('ll')),
            value: obj.id,
          };
        }) || []
    : [];

  const [
    createProposal,
    {
      isLoading: isProposalCreationLoading,
      isSuccess: isProposalCreationSuccess,
      data: proposal,
    },
  ] = useCreateProposalMutation();

  const [saveCredits, { isLoading: isSaveCreditsLoading }] =
    useSaveOfferDiscountMutation();
  const [saveDiscounts, { isLoading: isSaveDiscountsLoading }] =
    useSaveOfferDiscountMutation();

  useEffect(() => {
    if (
      isUpcomingFetchSuccess &&
      !isUpcomingFetchLoading &&
      upcomingDataSet?.length === 1 &&
      !props.isEditMode
    ) {
      form.setFieldsValue({
        effectiveDates: upcomingDataSet[0]?.value,
      });
    }
    // eslint-disable-next-line
  }, [isUpcomingFetchSuccess, isUpcomingFetchLoading]);

  useEffect(() => {
    if (upcomingDataSet?.length === 1) {
      form.setFieldsValue({
        effectiveDates: upcomingDataSet[0]?.value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcomingDataSet]);

  useEffect(() => {
    if (isProposalCreationSuccess && !isClose) {
      handleProposalId && handleProposalId(proposal?.id);
      setAlertVisible(false);
      dispatch(attachProposal(proposal));
      props.nextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProposalCreationSuccess]);

  useEffect(() => {
    if (isProposalUpdateSuccess && !isClose) {
      handleProposalId && handleProposalId(updatedProposal.id);
      setAlertVisible(false);
      dispatch(attachProposal(updatedProposal));
      props.nextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProposalUpdateSuccess]);

  useEffect(() => {
    if (isProposalCloneSuccess && !isClose) {
      handleProposalId && handleProposalId(proposalCloneData.id);
      setAlertVisible(false);
      dispatch(attachProposal(proposalCloneData));
      setCloneMode(false);
      props.nextStep();
      setIsNextStep && setIsNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProposalCloneSuccess]);

  const validateFormData = async () => {
    const formData = await form.getFieldsValue();
    return (
      !isEmpty(formData?.proposalName) && !isEmpty(formData?.effectiveDates)
    );
  };

  const handleSubmit = async () => {
    let updatedProposalId: string | null = null;

    await form
      .validateFields()
      .then(async (data) => {
        // eslint-disable-next-line
        const { effectiveDates, proposalName } = data;

        const proposalDto: ProposalDto = {
          employerId: String(employerId),
          organizationId: String(brokerId),
          name: proposalName,
          description: description,
          effectiveDateId: effectiveDates,
          id: proposalId,
        };
        if (proposalId || proposalDetails?.payload?.id) {
          const proposalUpdated = cloneDeep(proposalDetails.payload);

          if (proposalUpdated?.effectiveDateId !== effectiveDates) {
            proposalUpdated.name = proposalName;
            proposalUpdated.description = description;
            proposalUpdated.effectiveDateId = effectiveDates;
            proposalUpdated.lifeAddOffers = [];
            proposalUpdated.lifeSTDOffers = [];
            proposalUpdated.lifeLTDOffers = [];
            proposalUpdated.volLifeOffers = [];
            proposalUpdated.supplementaryMedicalOffers = [];
            proposalUpdated.primaryMedicalOffer = null;
            proposalUpdated.supplementaryDentalOffers = [];
            proposalUpdated.primaryDentalOffer = null;
            proposalUpdated.supplementaryVisionOffers = [];
            proposalUpdated.primaryVisionOffer = null;
            await saveDiscounts({
              planId: proposalDetails?.payload?.id,
              proposalDiscount: {
                id: proposalDetails?.payload?.id,
                discountCreditType: 'NONE',
                discounts: [],
              },
              type: 'discounts',
            });
            await saveCredits({
              planId: proposalDetails?.payload?.id,
              proposalDiscount: {
                id: proposalDetails?.payload?.id,
                discountCreditType: 'CREDIT',
                credits: [],
              },
              type: 'credits',
            });
          } else {
            proposalUpdated.name = proposalName;
            proposalUpdated.description = description;
            proposalUpdated.effectiveDateId = effectiveDates;
          }
          if (isCloneMode && !isNext) {
            const { id } = await cloneProposalById({
              proposalId: proposalId || proposalDetails?.payload?.id,
              proposal: proposalDto,
            }).unwrap();
            handleProposalId && handleProposalId(id ?? '');
            updatedProposalId = id;
          } else {
            await updateProposal({
              proposalId: proposalId || proposalDetails?.payload?.id,
              proposal: proposalUpdated,
            });
          }
        } else {
          const { id } = await createProposal({
            proposal: proposalDto,
          }).unwrap();
          updatedProposalId = id;
        }
      })
      .catch((error) => {
        if (error?.errorFields?.length === 1) {
          setAlertVisible(true);
          setAlertMessage(
            error?.errorFields?.[0]?.name?.includes('proposalName')
              ? 'Please enter a proposal name'
              : 'Please select effective dates'
          );
        } else {
          setAlertVisible(true);
          setAlertMessage(formVerificationMsg);
        }
      });

    return updatedProposalId;
  };

  useImperativeHandle(ref, () => ({
    validateFormData: async () => {
      const isValid = await validateFormData();
      return isValid;
    },
    handleSubmit: async () => {
      setIsClose(true);
      const id = await handleSubmit();
      return id;
    },
  }));

  if (isLoading || isProposalFetchLoading) {
    return <Spin />;
  }

  return (
    <div className={styles.container}>
      <InputForm form={form}>
        {alertVisible && (
          <AlertMessage
            type="error"
            message={alertMessage}
            className={styles.errorAlert}
            closeAlert={() => setAlertVisible(false)}
          />
        )}
        <Row>
          <Col span={24}>
            <Form.Item
              name="proposalName"
              label="Proposal Name"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              labelAlign="left"
              label="Effective Dates"
              name="effectiveDates"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                },
              ]}
            >
              <Select
                listHeight={128}
                loading={isUpcomingFetchLoading}
                onChange={(value) => setEffectiveDates(value)}
                value={effectiveDates}
              >
                {upcomingDataSet.map((data, index) => {
                  return (
                    <Select.Option value={data.value} key={index}>
                      {data.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ xs: 24 }}
              label="Proposal Description"
              name="description"
            >
              <RichTextEditor
                maxHeight={280}
                onChange={(content: any) => setDescription(content)}
                defaultText={description}
                initialValue={description}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <SubmitButton
              dataCy="data-erBasicInfoEdit"
              type="primary"
              htmlType="submit"
              className={styles.submitBtn}
              loading={
                isProposalCreationLoading ||
                isProposalUpdateLoading ||
                isSaveCreditsLoading ||
                isSaveDiscountsLoading ||
                isProposalCloneLoading
              }
              onClick={handleSubmit}
            >
              Next
            </SubmitButton>
          </Col>
          <Col span={24} className={styles.cancelBtnWrapper}>
            <TextButton
              label="Cancel"
              onClick={() => {
                closeModal(false);
              }}
              type="primary"
            />
          </Col>
        </Row>
      </InputForm>
    </div>
  );
};

export default forwardRef(ProposalBasicInfo);
