import { FC, useEffect } from 'react';
import { Form } from 'antd';

import AddFourOOneKPlan from 'modules/plans/retirement/components/AddFourOOneKPlan/AddFourOOneKPlan';
import EditPlanModal from 'modules/plans/components/EditPlanModal/EditPlanModal';
import { clearDocuments } from 'modules/plans/slices/retirementPlanSlice';
import { useAppDispatch } from 'hooks/redux';

type EditFourOOneKPlanProps = {
  onClose: () => void;
  onSave: () => void;
  visible: boolean;
  plan: any;
  isDBGPlan?: boolean;
  isSaveDisabled?: boolean;
};

const EditFourOOneKPlan: FC<EditFourOOneKPlanProps> = (
  props: EditFourOOneKPlanProps
) => {
  const { visible, onClose, onSave, plan, isDBGPlan, isSaveDisabled } = props;

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!visible) {
      dispatch(clearDocuments());
    }
  }, [visible, dispatch]);

  return (
    <EditPlanModal
      visible={visible}
      onCancel={onClose}
      title="Edit 401(k) Plan"
      isSaveDisabled={isSaveDisabled}
    >
      <AddFourOOneKPlan
        onCancel={onClose}
        isCloseConfirmed={!visible}
        onSave={onSave}
        isPlanUpdateMode={true}
        plan={plan}
        form={form}
        isDBGPlan={isDBGPlan}
        isSaveDisabled={isSaveDisabled}
        isModalVisible={visible}
      />
    </EditPlanModal>
  );
};

export default EditFourOOneKPlan;
