import { Input } from 'antd';
import styles from './ieInput.module.less';

type IEInputProps = {
  isTitle?: boolean;
  ref?: any;
  className?: string;
  [x: string]: any;
};
const IEInput = (props: IEInputProps) => {
  return (
    <Input
      className={`${styles.ieInputWrapper} ${props.isTitle && styles.title} ${
        props.className
      }`}
      {...props}
    />
  );
};

export default IEInput;
