import axios from 'axios';
import { getCurrentUnixTime } from 'util/dateUtil';
import { baseApi } from 'util/apiUtil';
import {
  EXPIRE_TIME,
  LOGGED_IN_USER_EMAIL,
  UNIFIED_PORTAL,
} from 'modules/auth/constants/authConstants';
import { sessionActionTypes } from 'constants/authConstants';

const COOKIELESS_CLIENT_HEADER = {
  'X-Web-Auth-Cookieless-Client': 'UP',
};

export const getOAuthToken = (email: string, password: string) => {
  const formData = new FormData();
  formData.set('username', email);
  formData.set('password', password);
  formData.set('grant_type', 'password');
  formData.set('scope', 'ADMIN');
  logUserSessionAction(sessionActionTypes.userActive, email);
  return axios.post(`${baseApi}/oauth/token`, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Web-Auth-Client': 'UP_ADMIN',
    },
  });
};

export const storeAuthDetails = (expiresIn: number, loggedUser: any) => {
  localStorage.setItem(
    EXPIRE_TIME,
    (getCurrentUnixTime() + expiresIn).toString()
  );
  localStorage.setItem(LOGGED_IN_USER_EMAIL, loggedUser);
};

export const whoami = () => {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  return axios.get(`${baseApi}/v1/individuals/whoami`, config);
};

export const signOut = () => {
  return axios.delete(`${baseApi}/oauth/token`).then(() => {
    clearAuthDetails();
  });
};

export const clearAuthDetails = () => {
  localStorage.clear();
};

export const validateAdminRegistrationToken = (token: string) => {
  return axios.get(`${baseApi}/v1/signup/validate/admin`, {
    params: { token, application: UNIFIED_PORTAL },
    headers: COOKIELESS_CLIENT_HEADER,
    withCredentials: false,
  });
};

export const adminSignup = (token: string, password: string) => {
  return axios.post(
    `${baseApi}/v1/signup/admin`,
    {
      token,
      password,
    },
    {
      params: {
        application: UNIFIED_PORTAL,
      },
      headers: COOKIELESS_CLIENT_HEADER,
      withCredentials: false,
    }
  );
};

export const initiateResetPassword = (username: string) => {
  return axios.post(`${baseApi}/v1/resetPassword/request/up`, { username });
};

export const validateResetPwdToken = (token: string) => {
  return axios.get(`${baseApi}/v1/resetPassword/validate`, {
    params: { token },
  });
};

export const resetPassword = (token: string, newPassword: string) => {
  return axios.post(`${baseApi}/v1/resetPassword`, { token, newPassword });
};

export const validateEmailAndOtp = async (otp: string, email: string) => {
  try {
    const response = await axios.post(
      `${baseApi}/v1/signup/otp-and-email/validate`,
      {
        otp: otp,
        email: email,
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
// API call to retrieve the refresh token when the session times out
export const checkRefreshToken = () => {
  return axios.post(`${baseApi}/v2/logins/token/refresh/validate`);
};

// API call to update the user session when the user is active
export const logUserSessionAction = (action: string, userName: string = '') => {
  const formData = new FormData();
  formData.append('action', action);
  formData.append(
    'user-id',
    localStorage.getItem(LOGGED_IN_USER_EMAIL) || userName || ''
  );
  formData.append(
    'user-name',
    localStorage.getItem(LOGGED_IN_USER_EMAIL) || userName || ''
  );
  // return axios.post(`${baseApi}/v2/logins/token/log/user-session`, formData);
  return;
};

export const refreshToken = (refreshToken: string) => {
  const formData = new FormData();
  formData.set('refresh_token', refreshToken);
  formData.set('grant_type', 'refresh_token');
  formData.set('scope', 'ADMIN');
  return axios.post(`${baseApi}/oauth/token`, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Web-Auth-Client': 'UP_ADMIN',
    },
  });
};
