import { useEffect } from 'react';
import { AnyAction, Dispatch } from 'redux';
import { SBC_PARAMS as DOCUMENT_EXTRACTION_PARAMS } from 'constants/commonConstants';
import { getParamsFromUrlParams } from 'modules/plans/utils';
import { setOPSView as setOPSViewForPlan } from 'modules/plans/slices/aiSbcUploaderSlice';
import UploaderType from 'modules/plans/enums/UploaderType';
import { getStatusAiSBC } from 'modules/plans/services/AiSBCUploaderService';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import { ReviewType } from 'modules/plans/enums/ReviewType';

/**
 * Custom hook for handling SBC and Quote (Summary of Benefits and Coverage) plan review modal.
 * This hook abstracts the logic for opening a modal based on URL parameters.
 *
 * @param {boolean} isOpsAdmin - A boolean indicating whether the current user is an operations administrator.
 * @param {Dispatch} dispatch - The Redux dispatch function used to trigger state changes.
 * @param {Function} handleOpenModal - A function that opens the modal and sets the job ID for the SBC review.
 * @param {boolean} [isQuoteProcessingEnabled=false] - Pass if quote processing is enabled.
 * @param {boolean} [isNavigate] - Pass if navigate is enabled.
 * @param {Function} [navigateToView] - A function that navigates to the view.
 * @param {Function }[showPlanMessage] - A function that shows a plan message.
 */
const useDocumentExtractionReviewModal = (
  isOpsAdmin: boolean,
  dispatch: Dispatch<AnyAction>,
  handleOpenModal: (jobId: string) => void,
  isQuoteProcessingEnabled: boolean = false,
  isNavigate?: boolean,
  navigateToView?: () => void,
  showPlanMessage?: () => void
) => {
  useEffect(() => {
    // If navigation is enabled, navigate to the view and return early.
    if (isNavigate) {
      navigateToView?.();
      return;
    }

    // Extract parameters from the URL, specifically checking for review status and job ID.
    const urlReviewParams =
      getParamsFromUrlParams([
        DOCUMENT_EXTRACTION_PARAMS.isReview,
        DOCUMENT_EXTRACTION_PARAMS.jobId,
      ]) ?? {};

    // If the jobId is not present in the URL parameters, return early.
    const jobId = urlReviewParams?.[DOCUMENT_EXTRACTION_PARAMS.jobId];
    if (!jobId) return;

    // Function to fetch SBC status and handle modal opening logic.
    const fetchSBCStatus = async () => {
      const response = await getStatusAiSBC(jobId);
      const data = response?.data;

      // If the status is SUCCESS and type is AUTOMATIC, show the plan message.
      if (
        !isOpsAdmin &&
        data?.status === ProcessStatus.SUCCESS &&
        data?.type === ReviewType.AUTOMATIC
      ) {
        showPlanMessage?.();
      } else {
        // Otherwise, set the OPS view and open the modal.
        dispatch(
          setOPSViewForPlan({
            jobId,
            planUploderType: isOpsAdmin
              ? UploaderType.OPS_VIEW
              : UploaderType.ADMIN_VIEW,
          })
        );
        handleOpenModal(jobId);
      }
    };

    // If quote processing is not enabled, fetch the SBC status.
    if (!isQuoteProcessingEnabled) {
      fetchSBCStatus();
    } else {
      // Otherwise, directly open the modal.
      handleOpenModal(jobId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpsAdmin, isNavigate, dispatch]);
};

export default useDocumentExtractionReviewModal;
