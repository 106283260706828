import { FC } from 'react';

import { Button } from 'antd';
import { NativeButtonProps } from 'antd/lib/button/button';

import styles from './linkButton.module.less';

interface LinkButtonProps extends NativeButtonProps {
  className?: string;
  href?: string;
  customClass?: string;
  enableDisableStyle?: true;
  containerClassName?: string;
}

const LinkButton: FC<LinkButtonProps> = (props) => {
  const {
    href,
    enableDisableStyle = false,
    disabled,
    customClass = '',
    containerClassName = '',
    className = disabled && enableDisableStyle
      ? styles.linkBtnDisabled
      : styles.linkBtn,
    ...rest
  } = props;
  return (
    <div
      className={`${styles.linkBtnWrapper} linkBtnWrapper ${containerClassName}`}
    >
      <Button
        type="link"
        className={`${className} ${customClass}`}
        disabled={disabled}
        href={href}
        {...rest}
      />
    </div>
  );
};

export default LinkButton;
