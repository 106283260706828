import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import iconWarning from 'assets/images/icon-warning.svg';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import { FORMAT_VALIDATE, SIZE_VALIDATE } from 'util/commonUtil';
import styles from './documentUploader.module.less';

type DocumentUploaderProps = {
  onChange: Function;
  onRemove: Function;
  allowedFileTypes?: string | string[];
  onFileNameClick: Function;
  maxFileSizeMB: number;
  isLoading?: boolean;
  isRemoved?: boolean;
  defaultFile?: File | null;
  hideNoFileMessage: boolean;
  onValidateFails?: (validateSetting: string) => void;
  disabled?: boolean;
  selectFileName?: string;
  showCancelConfirmationBody?: boolean;
  overflowPopoverMaxWidth?: string;
  isFileNameVisible?: boolean;
  wrapperClassName?: string;
  noFileUploadedMessage?: string;
  showCancelConfirmation?: boolean;
  removeConfirmationPopUpZIndex?: number | undefined;
  fileNamePopoverZIndex?: number | undefined;
  isAdditionalFileUploader?: boolean;
};
const emptyFile = new File([''], '');

const DocumentUploader = forwardRef((props: DocumentUploaderProps, ref) => {
  const {
    onChange,
    onRemove,
    allowedFileTypes,
    maxFileSizeMB,
    isLoading = false,
    onFileNameClick,
    isRemoved = false,
    defaultFile,
    hideNoFileMessage,
    onValidateFails,
    disabled = false,
    selectFileName = false,
    showCancelConfirmationBody = true,
    overflowPopoverMaxWidth = '',
    isFileNameVisible = true,
    wrapperClassName,
    noFileUploadedMessage,
    showCancelConfirmation = true,
    removeConfirmationPopUpZIndex = undefined,
    fileNamePopoverZIndex = undefined,
    isAdditionalFileUploader = false,
  } = props;

  const [file, setFile] = useState<File>(emptyFile);

  const fileInput = useRef<HTMLInputElement>(null);

  const showNoFileSelectedMessage = hideNoFileMessage
    ? !hideNoFileMessage
    : true;

  useEffect(() => {
    if (isRemoved) {
      setFile(emptyFile);
    }
  }, [isRemoved]);

  useEffect(() => {
    if (defaultFile && !file.name) {
      setFile(defaultFile);
    }
  }, [defaultFile, file.name]);

  const uploadFile = (): void => {
    if (fileInput.current !== null) {
      fileInput.current.click();
    }
  };

  const onSelectDocument = (event: any): void => {
    const file = event.target.files[0];
    if (allowedFileTypes !== undefined) {
      if (typeof allowedFileTypes === 'string') {
        const isallowedFileTypes = file.type === allowedFileTypes;
        if (!isallowedFileTypes) {
          if (onValidateFails) {
            onValidateFails(FORMAT_VALIDATE);
          }
          return;
        }
      } else {
        if (!allowedFileTypes?.includes(file.type)) {
          if (onValidateFails) {
            onValidateFails(FORMAT_VALIDATE);
          }
          return;
        }
      }
    }

    const isallowedFileSize = file.size / 1024 / 1024 < maxFileSizeMB;
    if (!isallowedFileSize) {
      if (onValidateFails) {
        onValidateFails(SIZE_VALIDATE);
      }
      return;
    }
    if (!file) {
      return;
    } else {
      setFile(file);
      onChange(file);
    }
  };

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  useImperativeHandle(ref, () => ({
    reset() {
      setFile(emptyFile);
    },
  }));

  const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

  return (
    <div className={`${styles.documentUploaderWrapper} ${wrapperClassName}`}>
      <PageActionButton
        onClick={uploadFile}
        disabled={disabled}
        isAdditionalFileUploader={isAdditionalFileUploader}
      >
        {selectFileName ? selectFileName : '+ Choose File'}
      </PageActionButton>
      <div
        className={
          isAdditionalFileUploader
            ? styles.additionalDocFileLabel
            : `${styles.fileLabel} ${disabled ? styles.disabled : ''}`
        }
      >
        {isLoading && (
          <>
            <Spin indicator={antIcon} /> &nbsp;
          </>
        )}
        {isFileNameVisible && file.name && (
          <div
            className={`${styles.fileNameArea} uploadedFileName`}
            onClick={() => onFileNameClick(file)}
          >
            {!isEmpty(overflowPopoverMaxWidth) ? (
              <OverflowPopover
                maxWidth={overflowPopoverMaxWidth}
                popoverZIndex={fileNamePopoverZIndex}
              >
                {file.name}
              </OverflowPopover>
            ) : (
              file.name
            )}
          </div>
        )}
        {showCancelConfirmation && (
          <div className={styles.removeFile}>
            {isEmpty(file.name) ? (
              showNoFileSelectedMessage && (
                <span className="text label-disabled">
                  {noFileUploadedMessage
                    ? noFileUploadedMessage
                    : 'No File Selected'}
                </span>
              )
            ) : (
              <ConfirmationModal
                title={
                  <div className={styles.cancelUploadBody}>
                    Are you sure you want to remove this file?
                    {showCancelConfirmationBody ? (
                      <p>
                        Removing the file will not affect any plan information,
                        but it will no longer be available to download from a
                        Digital Benefits Guide.
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                }
                icon={
                  <img
                    src={iconWarning}
                    alt="warning-icon"
                    className={styles.iconWarning}
                  />
                }
                confirmModalTrigger="(remove)"
                onConfirm={() => {
                  setFile(emptyFile);
                  onRemove();
                }}
                okText="Yes, remove file"
                zIndex={removeConfirmationPopUpZIndex}
                placement="topLeft"
              />
            )}
          </div>
        )}
        {!showCancelConfirmation && (
          <div className={styles.removeFile}>
            {isEmpty(file.name) ? (
              showNoFileSelectedMessage && (
                <span className="text label-disabled">
                  {noFileUploadedMessage
                    ? noFileUploadedMessage
                    : 'No File Selected'}
                </span>
              )
            ) : (
              <span
                onClick={() => {
                  onRemove();
                  setFile(emptyFile);
                }}
              >
                (remove)
              </span>
            )}
          </div>
        )}
      </div>
      <input
        type="file"
        name="file"
        onClick={onInputClick}
        onChange={onSelectDocument}
        ref={fileInput}
        accept={typeof allowedFileTypes === 'string' ? allowedFileTypes : ''}
        hidden
      />
    </div>
  );
});

DocumentUploader.displayName = 'DocumentUploader';

export default DocumentUploader;
