import { useState } from 'react';
import { Spin } from 'antd';
import { ReactComponent as Android } from 'assets/images/android.svg';
import { ReactComponent as IOS } from 'assets/images/ios.svg';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import {
  MOBILE_DEMO_ANDROID,
  MOBILE_DEMO_ANDROID_KEY,
  MOBILE_DEMO_IOS,
  MOBILE_DEMO_IOS_KEY,
} from 'constants/commonConstants';
import styles from './mobileDemo.module.less';

const MobileDemo = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [deviceType, setDeviceType] = useState<String | null>(null);

  const handleOnIconClick = (type: string) => {
    setDeviceType(type);
    setIsVisible(true);
  };

  const onModalClose = () => {
    setDeviceType(null);
    setIsVisible(false);
  };

  const getDemoUrl = () => {
    if (deviceType === MOBILE_DEMO_ANDROID) {
      return `https://appetize.io/embed/${MOBILE_DEMO_ANDROID_KEY}?device=pixel6&scale=85&autoplay=false&orientation=portrait&deviceColor=black&osVersion=12.0`;
    } else {
      return `https://appetize.io/embed/${MOBILE_DEMO_IOS_KEY}?device=iphone12&scale=85&autoplay=false&orientation=portrait&deviceColor=black`;
    }
  };

  return (
    <>
      <div className={styles.mobileDemoInnerContent}>
        Mobile
        <a onClick={() => handleOnIconClick(MOBILE_DEMO_IOS)}>
          <IOS className={styles.ios} />
        </a>{' '}
        <a onClick={() => handleOnIconClick(MOBILE_DEMO_ANDROID)}>
          <Android className={styles.andoid} />
        </a>{' '}
      </div>

      <FullScreenModal
        visible={isVisible}
        onCancel={onModalClose}
        footer={false}
        confirmTitle={false}
        destroyOnClose={true}
      >
        <div className={styles.iframeWrapper}>
          {deviceType === null ? (
            <Spin />
          ) : (
            <iframe
              src={getDemoUrl()}
              width="400px"
              className={styles.emulator}
              title="mobile-demo-simulator"
              frameBorder="0"
              scrolling="no"
            ></iframe>
          )}
        </div>
      </FullScreenModal>
    </>
  );
};

export default MobileDemo;
