import { FC, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { DentalPlan } from 'model/plans/DentalPlan';
import {
  resetStateCompleted,
  updateDentalPlan,
} from 'modules/plans/slices/dentalPlanSlice';
import DeductiblesOOPMax from 'modules/plans/dental/components/DeductiblesOOPMax/DeductiblesOOPMax';

type DeductiblesOOPMaxDBGWrapperProps = {
  dentalPlan: DentalPlan;
};

const DeductiblesOOPMaxDBGWrapper: FC<DeductiblesOOPMaxDBGWrapperProps> = (
  props: DeductiblesOOPMaxDBGWrapperProps
) => {
  const { dentalPlan } = props;

  const dispatch = useAppDispatch();
  const editingPlan = useAppSelector(
    (state) => state.plan.dentalPlan.dentalPlan
  );

  useEffect(() => {
    dispatch(resetStateCompleted());
    dispatch(updateDentalPlan(dentalPlan));
  }, [dispatch, dentalPlan]);

  const onChange = (dentalPlan: DentalPlan) => {
    dispatch(updateDentalPlan(dentalPlan));
  };

  return (
    <DeductiblesOOPMax
      dentalPlan={editingPlan || ({} as DentalPlan)}
      onChange={onChange}
      isTouched={false}
      isEdit
    />
  );
};

export default DeductiblesOOPMaxDBGWrapper;
