import * as React from 'react';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Col, Row, Spin } from 'antd';
import { Dayjs } from 'dayjs';

import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import { ReactComponent as CalendarIcon } from 'assets/images/icon-calendar.svg';
import styles from './claimsHeader.module.less';

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const { MonthPicker } = DatePicker;

type Props = {
  validDateRange: number;
  SummaryHeaderList: any[];
  headerTitle: string;
  loading: boolean;
  onYearChange?: Function;
  dateRange: any[];
  disabledDate: Function;
  onDateRangeChange: Function;
  displayDummyData: boolean;
  claimManageOpen?: boolean;
};

const ClaimsHeader = (props: Props) => {
  const {
    validDateRange,
    onDateRangeChange,
    disabledDate,
    SummaryHeaderList,
    headerTitle,
    loading,
    dateRange,
    displayDummyData,
    claimManageOpen = false,
  } = props;

  const [endOpen, setEndOpen] = useState<boolean>(false);

  useEffect(() => {
    setEndOpen(false);
  }, [claimManageOpen]);

  const onStartChange = (value: any) => {
    onDateRangeChange(value, 'fromDate');
  };

  const onEndChange = (value: any) => {
    onDateRangeChange(value, 'toDate');
  };

  const handleStartOpenChange = (open: any) => {
    setEndOpen(!open);
  };

  const handleEndOpenChange = (open: React.SetStateAction<boolean>) => {
    setEndOpen(open);
  };
  const validateStart = (current: { isAfter: (arg0: any) => any }) => {
    if (current && current.isAfter(dateRange[1])) {
      return true;
    } else {
      return disabledDate(current);
    }
  };
  const validateEnd = (current: { isBefore: (arg0: any) => any }) => {
    if (
      (current && current.isBefore(dateRange[0])) ||
      Math.abs(dateRange[0].diff(current, 'months')) > validDateRange - 1
    ) {
      return true;
    } else {
      return disabledDate(current);
    }
  };

  return (
    <div className={styles.claimsHeader}>
      <Row>
        <Col span={6} xs={6} sm={6} md={6}>
          <span className={styles.chartHeaderLabel}>{headerTitle}</span>
        </Col>
        <Col xs={18} sm={18} lg={18} md={18}>
          {!displayDummyData && (
            <div className={styles.actionContainer}>
              <div className={`${styles.actions} ${styles.titleRight}`}>
                <span className={styles.actionsTitle}>Viewing:</span>
                <div className="insight-calendar-picker-container">
                  <MonthPicker
                    disabledDate={validateStart}
                    dropdownClassName="insights-range-picker"
                    className={styles.range}
                    format="MMM YYYY"
                    value={dateRange[0]}
                    placeholder="Start"
                    onChange={onStartChange}
                    onOpenChange={handleStartOpenChange}
                    allowClear={false}
                    suffixIcon={
                      <CalendarIcon className={styles.calenderIcon} />
                    }
                  />
                </div>
                <div className={styles.hypen}>&ndash;</div>
                <div className="insight-calendar-picker-container">
                  <MonthPicker
                    disabledDate={validateEnd}
                    dropdownClassName="insights-range-picker"
                    format="MMM YYYY"
                    className={styles.range}
                    value={dateRange[1]}
                    placeholder="End"
                    onChange={onEndChange}
                    open={endOpen}
                    onOpenChange={handleEndOpenChange}
                    allowClear={false}
                    suffixIcon={
                      <CalendarIcon className={styles.calenderIcon} />
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {!_.isEmpty(SummaryHeaderList) && (
        <Row gutter={70}>
          {loading ? (
            <Spin className={styles.summaryHeaderLoader} />
          ) : (
            <Row className={styles.infoContainer}>{SummaryHeaderList}</Row>
          )}
        </Row>
      )}
    </div>
  );
};

export default ClaimsHeader;
