import CostSharing from './CostSharing';

class NetworkBasedCostSharing {
  inNetwork: CostSharing;
  outOfNetwork: CostSharing;

  constructor() {
    this.inNetwork = {} as CostSharing;
    this.outOfNetwork = {} as CostSharing;
  }
}
export default NetworkBasedCostSharing;
