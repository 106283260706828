import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Col, Row } from 'antd';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import { ReactComponent as RfpIconSelected } from 'assets/images/Rfp-ai-selected.svg';

import ContentContainer from 'containers/ContentContainer/ContentContainer';
import { ReactComponent as IconMedical } from 'assets/images/icon-medical.svg';
import PlansList from 'modules/plans/components/PlansList/PlansList';
import EmptyAddPlan from 'modules/plans/components/EmptyAddPlan/EmptyAddPlan';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { AlertInfo } from 'components/Alert/AlertMessage';
import AlertMessageWrapper from 'modules/plans/components/AlertMessageWrapper/AlertMessageWrapper';
import { DocumentExtractionSource } from 'modules/plans/enums/DocumentExtractionSource';
import AddPlanModal, {
  SelectedPlanProps,
  TDocumentExtractionSource,
} from 'modules/plans/components/AddPlanModal/AddPlanModal';
import SearchBar from 'components/SearchBar/SearchBar';
import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
  resetPaginationConfig,
} from 'util/commonUtil';
import TablePagination from 'model/TablePagination';
import PlanYear from 'model/PlanYear';
import MedicalPlan from 'model/plans/MedicalPlan';
import {
  getMedicalPlansList,
  resetPlanReduxStore,
  updateMedicalPlan,
  updateMedicalPlanCompleted,
} from 'modules/plans/slices/medicalPlanSlice';
import {
  getParamsFromUrlParams,
  removeSearchParams,
} from 'modules/plans/utils';
import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import PlanListPlanYearDropDown from 'components/PlanListPlanYearDropDown/PlanListPlanYearDropDown';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import {
  BenefitCategory,
  EMPLOYER_MEMBER_RESTRICTED,
  planCreateSuccess,
  planOfferFetchError,
  SBC_PARAMS,
  sbcfetchError,
  sbcReviewStarted,
} from 'constants/commonConstants';
import { usePrevious } from 'hooks/usePrevious';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import AiPlanUploadHistoryModal from 'modules/plans/components/AiPlanUploadHistoryModal/AiPlanUploadHistoryModal';
import { clear, setOPSView } from 'modules/plans/slices/aiSbcUploaderSlice';
import UploaderType from 'modules/plans/enums/UploaderType';
import { trackEvents } from 'api/initGA4';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import SendSelfToOPSSuccessComponent from 'modules/plans/components/SendSelfToOPSSuccessComponent/SendSelfToOPSSuccessComponent';
import { getStatusAiSBC } from 'modules/plans/services/AiSBCUploaderService';
import { ReviewType } from 'modules/plans/enums/ReviewType';

import styles from './planIntro.module.less';

const PlanIntro: FC = () => {
  const dispatch = useAppDispatch();
  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );
  const { status } = useAppSelector((state) => state.plan.aiSbc);
  const { brokerId, employerId, employer } = useNavContext();
  const requestType = useAppSelector((state) => state.plan.plans.requestType);
  const newPlan = useAppSelector((state) => state.plan.plans.newPlan);
  const { medicalPlansList } = useAppSelector((state) => state.plan.plans);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [planYear, setPlanYear] = useState<PlanYear | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [isAiPlanModalOpen, setIsAiPlanModalOpen] = useState<boolean>(false);
  const [addPlanPropsToState, setAddPlansPropsToState] = useState<
    SelectedPlanProps | undefined
  >(undefined);
  const [
    getUpcomingPlanYears,
    { data: upcomingPlanYearData, isLoading: isGetUpcomingPlanYearsLoading },
  ] = useLazyGetUpcomingPlanYearsByEmployerQuery();
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: 'success',
    message: '',
  });
  const [searchText, setSearchText] = useState<string>('');
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText,
    },
    filters: {},
  });

  const isRenewalStarted =
    upcomingPlanYearData?.upcomingPlanYears?.some(
      (obj: any) => obj?.planYearId === null
    ) && planYear?.current;

  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );

  const navigate = useNavigate();

  const { inProgress, data: medicalPLans } = medicalPlansList;
  const { metadata } = medicalPLans || {};
  const total = metadata ? metadata.total : 0;
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  useEffect(() => {
    if (employerId) {
      dispatch(getPlanYears(employerId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, employerId]);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
    dispatch(resetPlanReduxStore(BenefitCategory.MEDICAL.value, employerId));
  }, [dispatch, employerId]);

  const openSBCplanModal = useCallback(
    (jobId: string) => {
      dispatch(
        setOPSView({
          jobId: jobId,
          planUploderType: isOpsAdmin
            ? UploaderType.OPS_VIEW
            : UploaderType.ADMIN_VIEW,
        })
      );
      handleOpenModal();
    },
    [dispatch, handleOpenModal, isOpsAdmin]
  );

  // Runs when the component mounts or when the window location changes
  // Checks if the URL parameters contain review and jobId for SBC review
  // If the parameters exist, sets the jobId and call status api according to
  // the response and logic opens the modal
  // Removes the URL parameters after the effect is cleaned up
  useEffect(() => {
    const sbcReviewParams =
      getParamsFromUrlParams([SBC_PARAMS.isReview, SBC_PARAMS.jobId]) ?? {};
    const jobId = sbcReviewParams?.[SBC_PARAMS.jobId];

    const fetchSBCStatus = async (jobId: string) => {
      const { data } = await getStatusAiSBC(jobId);
      const showNotPlanModal =
        !isOpsAdmin &&
        data?.status === ProcessStatus.SUCCESS &&
        data?.type === ReviewType.AUTOMATIC;

      if (showNotPlanModal) {
        setAlertMessage({
          type: 'success',
          message: (
            <SendSelfToOPSSuccessComponent
              onClick={() => setIsAiPlanModalOpen(true)}
            />
          ),
        });
        setVisible(true);
      } else {
        openSBCplanModal(jobId);
      }
    };

    if (jobId) {
      fetchSBCStatus(jobId);
    }

    return () => {
      removeSearchParams([SBC_PARAMS.jobId, SBC_PARAMS.isReview]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location, isOpsAdmin, openSBCplanModal]);

  useEffect(() => {
    if (
      !inProgress &&
      requestType === updateMedicalPlanCompleted.type &&
      newPlan &&
      alertMessage.type !== undefined
    ) {
      setVisible(true);
      setAlertMessage({
        type: 'success',
        message: planCreateSuccess,
      });
    }
  }, [inProgress, requestType, newPlan, alertMessage.type]);

  const previousPlanYear = usePrevious(planYear?.id);

  const fetchPlanList = useCallback(() => {
    if (employerId && planYear?.id) {
      if (previousPlanYear !== planYear?.id) {
        setPaginationConfig(resetPaginationConfig(paginationConfig));
      }
      dispatch(
        getMedicalPlansList(
          convertPaginationConfig(paginationConfig),
          employerId,
          planYear.id
        )
      );

      if (employerId) {
        getUpcomingPlanYears({ employerId });
      }
    }
    // eslint-disable-next-line
  }, [employerId, planYear?.id, dispatch, paginationConfig]);

  useEffect(() => {
    if (employerId && planYear?.id) {
      fetchPlanList();
    }
    // eslint-disable-next-line
  }, [dispatch, employerId, planYear?.id, paginationConfig, fetchPlanList]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setSearchText(_searchText);
    const config = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText: _searchText },
    };
    debounceLoadData(config);
  };

  const getData = (filters: TablePagination) => {
    const paginationConfigData = {
      sorterInfo: {
        columnKey: 'name',
        field: 'name',
        order: 'ascend',
      },
      paginationIndex: filters.paginationIndex,
      filterInfo: {
        limit: filters.filterInfo.limit,
        offset: filters.filterInfo.offset,
        searchText: searchText,
      },
      filters: filters.filters,
    } as TablePagination;
    setPaginationConfig(paginationConfigData);
  };

  const onMedicalPlanAdd = () => {
    dispatch(updateMedicalPlan(new MedicalPlan()));
    setIsModalOpen(true);
  };

  const goToOverviewPage = (plan: MedicalPlan) => {
    navigate(
      `/brokers/${brokerId}/employers/${employerId}/medical/${plan.id}/overview`,
      { state: { plan } }
    );
  };

  const closeAlert = () => {
    setVisible(false);
    setAlertMessage({
      type: undefined,
      message: '',
    });
  };

  const setSelectedPlanYear = (planYear: PlanYear | null | undefined) => {
    if (employerId && planYear) {
      setPlanYear(planYear);
    }
  };

  const onClose = () => {
    if (employerId && planYear) {
      fetchPlanList();
    }
  };

  const onPlanSave = (
    isSuccess: boolean,
    successMessage?: string | undefined | null
  ) => {
    setIsModalOpen(false);
    setAddPlansPropsToState(undefined);
    if (isSuccess) {
      setAlertMessage({
        type: 'success',
        message: successMessage ?? planCreateSuccess,
      });
      setVisible(true);
    }
    dispatch(resetPlanReduxStore(BenefitCategory.MEDICAL.value, employerId));
    fetchPlanList();
    trackEvents({
      category: 'Medical Plan',
      action: 'Medical Plan Created',
      label: 'Medical Plan Created',
    });
  };

  const handleModalClose = (
    extractStatus: ProcessStatus,
    sendToOpsFailed?: boolean,
    docExtractionSource?: TDocumentExtractionSource
  ) => {
    const currentStatus = extractStatus ?? status;

    const constructFailedMessage = (
      source?: DocumentExtractionSource | undefined
    ) => {
      if (source === DocumentExtractionSource.UPDATE_QUOTE_PLAN) {
        return planOfferFetchError;
      }
      return sbcfetchError;
    };

    if (
      [
        ProcessStatus.PROCESSING,
        ProcessStatus.SUCCESS,
        ProcessStatus.REVIEWED,
        ProcessStatus.VALIDATED,
        ProcessStatus.FAILED,
        ProcessStatus.CANCELLED,
      ].includes(currentStatus)
    ) {
      if (extractStatus === ProcessStatus.PROCESSING) {
        if (sendToOpsFailed) {
          setAlertMessage({
            type: 'success',
            message: (
              <SendSelfToOPSSuccessComponent
                isSendOpsAdmin={true}
                onClick={() => setIsAiPlanModalOpen(true)}
              />
            ),
          });
          setVisible(true);
        } else {
          setAlertMessage({
            type: 'success',
            message: sbcReviewStarted,
          });
          setVisible(true);
        }
      } else if (
        [
          ProcessStatus.SUCCESS,
          ProcessStatus.REVIEWED,
          !isOpsAdmin && ProcessStatus.PROCESSING,
        ].includes(currentStatus)
      ) {
        removeSearchParams([SBC_PARAMS.isReview, SBC_PARAMS.jobId]);
      } else if (extractStatus === ProcessStatus.FAILED) {
        setAlertMessage({
          type: 'error',
          message: constructFailedMessage(docExtractionSource?.source),
        });
        setVisible(true);
        removeSearchParams([SBC_PARAMS.isReview, SBC_PARAMS.jobId]);
      }
      dispatch(clear());
    }
    setIsModalOpen(false);
    setAddPlansPropsToState(undefined);
    fetchPlanList();
  };

  const addMedicalPlanButton = (
    <div className={styles.allTypesPlanWrapper}>
      <div className={styles.addPlanButtonWrapper}>
        <PageActionButton
          type="primary"
          onClick={() => {
            handleOpenModal();
            trackEvents({
              category: 'Medical Plan',
              action: 'Add Medical Plan',
              label: 'Add Medical Plan',
            });
          }}
          className={styles.actionButton}
        >
          Add Medical Plan
        </PageActionButton>
      </div>
      <LinkButton
        onClick={() => {
          setIsAiPlanModalOpen(true);
        }}
      >
        <RfpIconSelected /> Plan Uploads
      </LinkButton>
    </div>
  );

  const permittedAddMedicalPlanButton = usePermitByUserType(
    addMedicalPlanButton,
    EMPLOYER_MEMBER_RESTRICTED
  );

  return (
    <ContentContainer>
      <AlertMessageWrapper
        type={alertMessage.type}
        message={alertMessage.message}
        closeAlert={closeAlert}
        visible={visible && !inProgress}
      />
      <Row>
        <Col span={18}>
          <div className={styles.employerName}>
            <OverflowPopover
              popoverContent={`${employer?.name}`}
              maxWidth="50%"
            >
              {`${employer?.name} `}
            </OverflowPopover>
            Medical Plans
          </div>
        </Col>
        <Col span={6}>
          {((!inProgress && total !== 0) ||
            (!inProgress && total == 0 && !isEmpty(searchText))) &&
            permittedAddMedicalPlanButton}
        </Col>
      </Row>
      <Row>
        <Col>
          <PlanListPlanYearDropDown onPlanYearSelect={setSelectedPlanYear} />
        </Col>
      </Row>
      {!inProgress && total === 0 && isEmpty(searchText) ? (
        <>
          <EmptyAddPlan
            title="You have no Medical plans set up!"
            description="Get started by adding your first plan"
            icon={<IconMedical />}
            actionTitle="+ Add Medical Plan"
            onActionClick={onMedicalPlanAdd}
          >
            <LinkButton onClick={() => setIsAiPlanModalOpen(true)}>
              <RfpIconSelected /> Plan Uploads
            </LinkButton>
          </EmptyAddPlan>
        </>
      ) : (
        <>
          <div className={styles.searchBar}>
            <SearchBar placeholder="Search Plans" onChange={handleSearch} />
          </div>
          <PlansList
            planYear={planYear}
            planList={medicalPLans}
            inProgress={inProgress || isGetUpcomingPlanYearsLoading}
            limit={limit}
            paginationConfig={paginationConfig}
            getData={getData}
            goToOverviewPage={goToOverviewPage}
            benefitCategory={BenefitCategory.MEDICAL.value}
            onSaveClose={onClose}
            isRenewalProcessStarted={!!isRenewalStarted}
            setAddPlansPropsToState={setAddPlansPropsToState}
          />
        </>
      )}
      <AddPlanModal
        {...addPlanPropsToState}
        benefit="MEDICAL"
        onClose={handleModalClose}
        isOpen={addPlanPropsToState?.isOpen ?? isModalOpen}
        isRenewalProcessStarted={isRenewalStarted}
        fullScreenModalClassName={styles.planIntroWrapper}
        onSave={onPlanSave}
      />
      <AiPlanUploadHistoryModal
        benefit={'MEDICAL'}
        isOpen={isAiPlanModalOpen}
        onClose={() => setIsAiPlanModalOpen(false)}
        planYearName={planYear?.name}
        openSBCplanModal={openSBCplanModal}
      />
    </ContentContainer>
  );
};

export default PlanIntro;
