import { Col } from 'antd';
import { useParams } from 'react-router-dom';
import {
  ISSUES_LOG_FILTER_TYPES,
  ISSUE_EMPLOYER_STATUS_DEFAULT_MAP,
  ISSUE_EMPLOYER_STATUS_MAP,
  ISSUE_SUBTYPE_DEFAULT_MAP,
  ISSUE_SUBTYPE_MAP,
  PRIORITY_DEFAULT_MAP,
  PRIORITY_MAP,
} from 'modules/issueslog/constants/IssueLogFilterConstants';
import {
  addOrReplace,
  convertToFlatNumberArray,
  createUniqueId,
} from 'modules/issueslog/utils/issueLogUtils';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { saveFilter } from 'modules/issueslog/slices/issuesLogFilterSlice';
import FilterDropDown from 'components/FilterDropDown/FilterDropDown';

type ErAdminFiltersProps = {
  filterRefs: any;
  showMoreFilters?: boolean;
};

const ErAdminFilters = ({ filterRefs }: ErAdminFiltersProps) => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const appBootInfo: any = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );

  const isEmployerView = !!params?.employerId;

  const saveFiltersID = createUniqueId(
    params,
    isEmployerView,
    appBootInfo?.type
  );

  const savedFilterState = useAppSelector(
    (state) => state?.filters[saveFiltersID]
  );

  const getFilterDefaultFromState = (type: string) => {
    const optionArray: any[] =
      savedFilterState?.filters?.filters?.find(
        (item: any) => item?.name === type
      )?.values || [];

    return optionArray;
  };

  const getFilterDefaultStatusFromState = (type: string) => {
    const optionArray: any[] =
      savedFilterState?.filters?.filters?.find(
        (item: any) => item?.name === type
      )?.values || [];

    // this done because of special scenario in emp admin In progress has to values IN_PROGRESS,BLOCKED statues
    const copiedOptionArray = [...optionArray];
    const joinedValue = [];
    const indexOfTwo = copiedOptionArray.indexOf(2);
    if (indexOfTwo !== -1) {
      joinedValue.push(copiedOptionArray.splice(indexOfTwo, 1)[0]);
    }
    const indexOfSix = copiedOptionArray.indexOf(6);
    if (indexOfSix !== -1) {
      joinedValue.push(copiedOptionArray.splice(indexOfSix, 1)[0]);
    }
    if (joinedValue?.length !== 0) {
      return [...copiedOptionArray, joinedValue.join(',')];
    }

    return copiedOptionArray;
  };

  const handelOkayOrReset = ({
    filterType,
    values,
  }: {
    filterType: string;
    values: any[];
  }) => {
    const addReplaceFilterArray = addOrReplace(
      savedFilterState?.filters?.filters || [],
      { name: filterType, values: values }
    );
    dispatch(
      saveFilter({
        clickedSaved: true,
        filters: {
          searchKey: savedFilterState?.filters?.searchKey || '',
          filters: addReplaceFilterArray,
        },
        filterSaveType: saveFiltersID,
      })
    );
  };
  return (
    <>
      <Col span={6}>
        <FilterDropDown
          ref={filterRefs?.statusRef}
          filterName={ISSUES_LOG_FILTER_TYPES.STATUS}
          placeholder="Status"
          showSearch={false}
          optionSort={false}
          showCustomButton={false}
          options={ISSUE_EMPLOYER_STATUS_MAP}
          defaultOptions={getFilterDefaultStatusFromState(
            ISSUES_LOG_FILTER_TYPES.STATUS
          )}
          resetOptions={ISSUE_EMPLOYER_STATUS_DEFAULT_MAP}
          handleOkayCallback={(values, filterName) => {
            handelOkayOrReset({
              filterType: filterName,
              values: convertToFlatNumberArray(values),
            });
          }}
          handleResetCallback={(values, filterName) => {
            handelOkayOrReset({
              filterType: filterName,
              values: convertToFlatNumberArray(values),
            });
          }}
        />
      </Col>
      <Col span={6}>
        <FilterDropDown
          ref={filterRefs?.priorityRef}
          filterName={ISSUES_LOG_FILTER_TYPES.PRIORITY}
          placeholder="Priority"
          showSearch={false}
          showCustomButton={false}
          optionSort={false}
          options={PRIORITY_MAP}
          defaultOptions={getFilterDefaultFromState(
            ISSUES_LOG_FILTER_TYPES.PRIORITY
          )}
          resetOptions={PRIORITY_DEFAULT_MAP}
          handleOkayCallback={(values, filterName) => {
            handelOkayOrReset({
              filterType: filterName,
              values: values,
            });
          }}
          handleResetCallback={(values, filterName) => {
            handelOkayOrReset({
              filterType: filterName,
              values: values,
            });
          }}
        />
      </Col>
      <Col span={7}>
        <FilterDropDown
          ref={filterRefs?.typeRef}
          filterName={ISSUES_LOG_FILTER_TYPES.SUB_TYPE}
          placeholder="Type"
          showSearch={true}
          searchPlaceholder="Search Type"
          showCustomButton={false}
          optionSort={false}
          options={ISSUE_SUBTYPE_MAP}
          defaultOptions={getFilterDefaultFromState(
            ISSUES_LOG_FILTER_TYPES.SUB_TYPE
          )}
          resetOptions={ISSUE_SUBTYPE_DEFAULT_MAP}
          handleOkayCallback={(values, filterName) => {
            handelOkayOrReset({
              filterType: filterName,
              values: values,
            });
          }}
          handleResetCallback={(values, filterName) => {
            handelOkayOrReset({
              filterType: filterName,
              values: values,
            });
          }}
        />
      </Col>
    </>
  );
};

export default ErAdminFilters;
