import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getPDFViewURL } from 'modules/plans/services/AiSBCUploaderService';
import { convertEnumToOptions } from 'util/commonUtil';
import PlanDocumentUploader from 'modules/plans/components/SBCDocumentUploader/PlanDocumentUploader';
import { DentalPlan } from 'model/plans/DentalPlan';
import { VisionPlan } from 'model/plans/VisionPlan';
import { LifePlan } from 'model/plans/LifePlan';
import MedicalPlan from 'model/plans/MedicalPlan';
import { FourOOneKPlan } from 'model/plans/FourOOneKPlan';
import { TaxAdvantagedAccountPlan } from 'model/plans/TaxAdvantagedAccountPlan';
import { MedicalPlanDocumentType, AI_FILE_TYPE } from 'modules/plans/constants';
import { openPlanDocument } from 'util/fileUtil';
import {
  deleteAdditionalDocument,
  uploadTemporaryAdditionalDocument,
} from 'modules/renewals/slices/addProposalToPlanYearSlice';

import AdditionalPlanResources from 'modules/plans/components/AdditionalPlanResources/AdditionalPlanResources';
import FileType from 'model/plans/FileType';
import WebLinkType from 'model/plans/WebLinkType';
import styles from './planDocuments.module.less';

type PlanDocumentsProps = {
  onPlanChange: Function;
  onFileChange: Function;
  plan:
    | DentalPlan
    | VisionPlan
    | MedicalPlan
    | LifePlan
    | FourOOneKPlan
    | TaxAdvantagedAccountPlan;
  planDocumentTypes: any;
  isSBC?: boolean;
  onValidateFails?: (validateSetting: string) => void;
  benefitKind?: string;
  isDisable?: boolean;
  isAFPReview?: boolean;
  isOnlyAfpVisible?: boolean;
};

const PlanDocuments = forwardRef((props: PlanDocumentsProps, ref) => {
  const {
    onPlanChange,
    plan,
    planDocumentTypes,
    onFileChange,
    isSBC,
    onValidateFails,
    benefitKind,
    isDisable = false,
    isAFPReview = false,
    isOnlyAfpVisible = false,
  } = props;
  const { documentReferences, documents } = plan;
  const { jobId } = useAppSelector((state) => state.plan.aiSbc);

  const isSBCFlow = (docType: any) => {
    return Boolean(
      [
        AI_FILE_TYPE.SBC,
        AI_FILE_TYPE.PLAN_SUMMARY,
        AI_FILE_TYPE.BENEFIT_SUMMARY,
      ].includes(docType) && jobId
    );
  };

  const [selectedFileList, setSelectedFileList] = useState<FileType[]>([]);
  const [selectedWeblink, setSelectedWeblink] = useState<WebLinkType[]>([]);
  const [, setIsDocRemoved] = useState<{
    [key: string]: boolean;
  }>({});

  const [resetInput, setResetInput] = useState<{ [key: string]: boolean }>({});
  const dispatch = useAppDispatch();

  useImperativeHandle(ref, () => ({
    resetAll() {
      documentTypes.forEach((type) => onRemove(type.value));
    },
    validate: async () => ({
      isValid: true,
      isComplete: getIsComplete(),
    }),
  }));

  const getIsComplete = (): boolean => {
    return documentTypes.every(
      ({ value }) => !isEmpty((documentReferences ?? {})[value])
    );
  };

  const onRemove = (docType: string) => {
    if (isSBCFlow(docType)) return;
    const clonedDentalPlan = cloneDeep(plan);
    if (
      !isEmpty(clonedDentalPlan.documentReferences) &&
      !isEmpty(clonedDentalPlan.documentReferences[docType])
    ) {
      delete clonedDentalPlan.documentReferences[docType];
    }
    if (
      !isEmpty(clonedDentalPlan.documents) &&
      !isEmpty(clonedDentalPlan.documents[docType])
    ) {
      delete clonedDentalPlan.documents[docType];
    }
    onPlanChange(clonedDentalPlan, docType);
    setResetInput((prevState) => ({ ...prevState, [docType]: true }));
  };

  useEffect(() => {
    if (
      plan?.documentReferences &&
      !plan.documentReferences[MedicalPlanDocumentType.SBC.value]
    ) {
      setResetInput((prevState) => ({
        ...prevState,
        [MedicalPlanDocumentType.SBC.value]: true,
      }));
    }
  }, [setResetInput, plan.documentReferences]);

  useEffect(() => {
    if (isEmpty(resetInput)) {
      return;
    }
    const keys = Object.keys(resetInput);
    keys.forEach((key) => {
      if (resetInput[key]) {
        resetInput[key] = false;
        setResetInput((prevState) => ({ ...prevState, [key]: false }));
      }
    });
  }, [resetInput]);

  const documentTypes = convertEnumToOptions(planDocumentTypes);

  const downloadFileObj = (data: File, docType: string, blobUrl: string) => {
    let url = blobUrl && isSBC ? blobUrl : window.URL.createObjectURL(data);

    if (isSBCFlow(docType)) {
      url = getPDFViewURL(jobId!);
    }

    if (documents && documents[docType] && plan.id && benefitKind) {
      openPlanDocument(plan.id, docType, benefitKind);
      return;
    }

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';

    a.href = url;
    a.download = data.name;
    a.click();
    window.URL.revokeObjectURL(url);
    return;
  };

  /**
   * Triggers the appropriate action based on the specified type.
   *
   *  @param {string} action - The type of action to perform ('ADD', 'DELETE', or 'EDIT').
   *  @param {boolean} isFile - A boolean indicating if the document is a file.
   *  @param {string} docName - The name of the document to be added, deleted, or edited.
   *  @param {any} file - The file object to be uploaded (used for 'ADD' and 'EDIT' actions).
   *  @param {string} webLink - The web link associated with the document (used for 'ADD' action).
   * @param {string} existingPlan - The name of the existing plan document to be replaced (used for 'EDIT' action).
   *
   * @throws Will throw an error if an unknown action is provided.
   */
  const actionTriggered = async (
    action: 'ADD' | 'DELETE' | 'EDIT',
    isFile: boolean,
    docName: string,
    file: any,
    webLink: string,
    existingPlan: string
  ) => {
    switch (action) {
      case 'ADD':
      case 'EDIT':
        if (action === 'EDIT') {
          dispatch(deleteAdditionalDocument(existingPlan, isFile));
        }
        await dispatch(
          uploadTemporaryAdditionalDocument(
            file,
            docName,
            false,
            isFile,
            webLink
          )
        );
        break;
      case 'DELETE':
        dispatch(deleteAdditionalDocument(docName, isFile));
        break;
      default:
        throw new Error(`Unknown action: ${action}`);
    }
  };

  const disableFileSelection = () => {
    let isDisable = false;
    if (!isEmpty(plan?.documentReferences)) {
      Object.keys(plan.documentReferences).forEach((doc) => {
        if (plan.documentReferences[doc].uploading) {
          isDisable = true;
        }
      });
    }
    return isDisable;
  };

  return (
    <div className={styles.planDocumentsWrapper}>
      {isSBC && !isAFPReview && (
        <>
          <div className={styles.docType}> Additional Plan Docs</div>
          <Divider />
        </>
      )}
      {documentTypes.map((docType, index) => {
        const blobUrl = get(
          documentReferences,
          [docType.value, 'blobUrl'],
          undefined
        );
        const fileName = get(
          documentReferences,
          [docType.value, 'fileName'],
          undefined
        );
        let file;
        const savedFileName = get(documents, docType.value);
        if (savedFileName) {
          file = new File([''], savedFileName);
        }
        if (blobUrl) {
          file = new File([blobUrl], fileName);
        }

        return (
          <Row key={index} className={styles.docUploadRow}>
            <Col span={6}>
              <div className={styles.docType}> {docType.label}</div>
            </Col>
            <Col span={18}>
              <PlanDocumentUploader
                onChange={(e: File) => onFileChange(e, docType.value)}
                onRemove={() => {
                  onRemove(docType.value);
                }}
                onFileNameClick={(file: File) =>
                  downloadFileObj(file, docType.value, blobUrl)
                }
                isLoading={get(
                  documentReferences,
                  [docType.value, 'uploading'],
                  false
                )}
                allowedFileTypes="application/pdf"
                maxFileSizeMB={100}
                isRemoved={resetInput[docType.value]}
                file={file}
                docType={docType.value}
                onValidateFails={onValidateFails}
                isDisable={isDisable || isSBCFlow(docType.value)}
                isSBC={isSBC}
                isDisableFileSelection={
                  disableFileSelection() || isSBCFlow(docType.value)
                }
                isAFPReview={isAFPReview}
              />
            </Col>
          </Row>
        );
      })}
      <Col span={24}>
        {isOnlyAfpVisible && (
          <AdditionalPlanResources
            ref={ref}
            plan={plan}
            benefitKind={'MEDICAL'}
            isCloseConfirmed={false}
            selectedFileList={selectedFileList}
            setSelectedFileList={setSelectedFileList}
            selectedWebLinkList={selectedWeblink}
            setSelectedWebLinkList={setSelectedWeblink}
            setIsDocRemoved={setIsDocRemoved}
            actionTriggered={actionTriggered}
          />
        )}
      </Col>
    </div>
  );
});

PlanDocuments.displayName = 'PlanDocuments';

export default PlanDocuments;
