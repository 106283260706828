import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Form, notification } from 'antd';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import CommuterPlanInformation from 'modules/plans/taxAdvantaged/components/CommuterPlanInformation/CommuterPlanInformation';
import DependentCareFSAPlanInformation from 'modules/plans/taxAdvantaged/components/DependentCareFSAPlanInfo/DependentCareFSAPlanInfo';
import HSAPlanInformation from 'modules/plans/taxAdvantaged/components/HSAPlanInformation/HSAPlanInformation';
import HRAHealthCareFSALimitedFSAPlanInfo from 'modules/plans/taxAdvantaged/components/HRAHealthCareFSALimitedFSAPlanInfo/HRAHealthCareFSALimitedFSAPlanInfo';
import EditPlanDocuments from 'modules/plans/components/EditPlanDocuments/EditPlanDocuments';
import PlanBasicForm from 'modules/plans/components/PlanBasicForm/PlanBasicForm';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';

import {
  EmployerContributionFrequency,
  PLAN_ADDITIONAL_DOCUMENT,
  RateType,
  TaxAdvantageCarrierTypes,
  TaxAdvantagedAccountPlanType,
  TaxAdvantagedMaxValues,
  TaxAdvantagedPlanDocumentType,
  TaxAdvantageFields,
  VALIDATION_NAME_DUPLICATED,
} from 'modules/plans/constants';
import {
  BenefitCategory,
  benefitGroupsChangedMsg,
  BenefitKind,
  EMPTY_MESSAGE,
  formVerificationMsg,
  planAlreadyExistsError,
  planYearChangedMsg,
} from 'constants/commonConstants';
import {
  getHraPlanList,
  setHraPlanList,
} from 'modules/plans/slices/planSllice';
import { getCarriersByBenefitKind } from 'modules/plans/slices/basicPlanInfoSlice';
import { getHsaMedicalPlansList } from 'modules/plans/slices/medicalPlanSlice';
import { TaxAdvantagedAccountPlan } from 'model/plans/TaxAdvantagedAccountPlan';
import BenefitCarrier from 'model/BenefitCarrier';
import MedicalPlan from 'model/plans/MedicalPlan';
import {
  findPlanById,
  getPlanByIdCompleted,
  removeDocumentReference,
  saveTaxAdvantagedAccountPlan,
  unsetApiError,
  updateTaxAdvantagedAccountPlanInProgress,
  uploadTemporaryDocument,
} from 'modules/plans/slices/taxAdvantagedAccountPlanSlice';
import { getValidationMessage } from 'util/commonUtil';
import {
  ERROR_MESSAGE_PLAN_EDITING,
  PLAN_NOTIFICATION_KEY,
} from 'constants/benguideCollaborationConstants';

import FileType from 'model/plans/FileType';
import WebLinkType from 'model/plans/WebLinkType';
import AdditionalPlanResources from 'modules/plans/components/AdditionalPlanResources/AdditionalPlanResources';
import {
  removePlanDocuments,
  updateWeblinks,
  uploadPlanDocument,
  uploadRemovePlanDocument,
} from 'modules/plans/slices/planDocumentsSlice';
import styles from './addTaxAdvantagedPlan.module.less';

export const TaxAdvantagedBasicInfoFormFields = {
  requiredFields: ['planName', 'planYear', 'benefitClass', 'benefitCarrier'],
  formFields: [
    'planName',
    'planYear',
    'benefitCarrier',
    'benefitClass',
    'selectedMedicalPlans',
    'groupId',
  ],
};

type AddTaxAdvantagedPlanProps = {
  onReset: () => void;
  closePlanAddModal: () => void;
  isCancelButtonClickConfirm: boolean;
  planType: string;
  onSaveClose: Function;
  isPlanUpdateMode?: boolean;
  plan?: TaxAdvantagedAccountPlan;
  defaultPlanYear?: string;
  dbgPlanYear?: string;
  isDBGPlan?: boolean;
  isModalVisible?: boolean;
  isSaveDisabled?: boolean;
  isReview?: boolean;
  onPlanSave?: Function;
  onFileChangeAction?: Function;
  onFileRemoveAction?: Function;
  isUploading?: boolean;
  isReviewHighlight?: boolean;
  reviewNewPlanYear?: string;
  reviewBenefitClasses?: string[];
  isHightlightCarrier?: boolean;
};

type MultiSelect = {
  groups: string[];
};

type AlertVisibleInfo = {
  state: boolean;
  maxFieldError: boolean;
};
export type TaxAdvantagedAccountFormData = {
  planName: string;
  planYear: string;
  benefitCarrier: string | null;
  benefitClass: string[];
  benefitKind: string;
  maxYearlyContribution?: number;
  maxIndividualContributionForMarriedButFilledAsSeparately?: number;
  maxMarriedContributionForFillingJointlyOrSingleParentAsHead?: number;
  endOfYearPolicy?: string;
  maxYearlyContributionIndividual?: number;
  maxYearlyContributionFamily?: number;
  frequency?: string;
  otherContribution?: string;
  employerEEContribution?: number;
  employerESContribution?: number;
  employerECContribution?: number;
  employerEFContribution?: number;
  maxMonthlyContribution?: number;
  employerEE1Contribution?: number;
  employerEE2Contribution?: number;
  employerEE3Contribution?: number;
  employerEE4Contribution?: number;
  employerEE5Contribution?: number;
  employerEE6Contribution?: number;
  empContribution?: number;
  employerMonthlyContribution?: number;
  documentReferences?: any;
  documents?: { [key: string]: string };
  selectedMedicalPlans?: string[];
  selectedDentalPlans?: string[];
  selectedVisionPlans?: string[];
  groupId?: string;
  hraUtilization?: number;
};

const taxAdvantagedAccountPlanDefaultValues: TaxAdvantagedAccountFormData = {
  planName: '',
  benefitCarrier: '',
  planYear: '',
  benefitClass: [],
  benefitKind: '',
  groupId: '',
};

const otherEmployerContributionsTooltipContent = (planType: string) => (
  <div className={styles.popoverContent}>
    <p>
      <div className={styles.tooltipHeader}>
        Other Employer{' '}
        {planType === TaxAdvantagedAccountPlanType.HSA.value
          ? 'Contributions'
          : 'Allowances'}
      </div>
      <br />
      You can add details here for any other{' '}
      {planType === TaxAdvantagedAccountPlanType.HSA.value
        ? 'contributions'
        : 'allowances'}
      <br />
      structure that doesn&apos;t follow the ones mentioned above.
    </p>
  </div>
);

const EndOfYearPolicyTooltipContent = () => (
  <div className={styles.popoverContent}>
    <p>
      <div className={styles.tooltipHeader}>End of Year Policy</div>
      <br />
      This policy explains what will happen to employee
      <br />
      funds at the end of the plan year. Examples
      <br />
      include &quot;Use it or Lose it&quot;, &quot;X Month Grace Period&quot;
      <br />
      or &quot;Up to $X Rollover&quot;.
    </p>
  </div>
);

const associatedMedicalPlansTooltipContent = (planType: string) => (
  <div className={styles.popoverContent}>
    <p>
      <div className={styles.tooltipHeader}>
        {planType === TaxAdvantagedAccountPlanType.HSA.value
          ? 'Associated Medical Plans'
          : 'Associated Plans'}
      </div>
      <br />
      Please select the {planType} enabled
      {planType === TaxAdvantagedAccountPlanType.HSA.value ? ' medical ' : ' '}
      plans that
      <br />
      will use this plan. Only{' '}
      {planType === TaxAdvantagedAccountPlanType.HSA.value ? ' medical ' : ' '}
      plans that have
      <br />
      {planType} enabled will be shown here.
    </p>
  </div>
);

const ALL_STATES = 'All';

const AddTaxAdvantagedPlan = forwardRef(
  (props: AddTaxAdvantagedPlanProps, ref) => {
    const {
      isCancelButtonClickConfirm,
      onReset,
      closePlanAddModal,
      planType,
      onSaveClose,
      isPlanUpdateMode,
      plan,
      defaultPlanYear,
      dbgPlanYear,
      isDBGPlan,
      isModalVisible,
      isSaveDisabled = false,
      isReview = false,
      onPlanSave,
      onFileRemoveAction,
      isReviewHighlight,
      reviewNewPlanYear,
      reviewBenefitClasses,
      onFileChangeAction,
      isHightlightCarrier = false,
    } = props;

    const [alertVisible, setAlertVisible] = useState<AlertVisibleInfo>({
      state: false,
      maxFieldError: false,
    });

    const [selectedCarrier, setSelectedCarrier] = useState<
      BenefitCarrier | undefined
    >();
    const [alertMessage, setAlertMessage] = useState<AlertInfo>({
      type: undefined,
      message: '',
    });

    const [formData, setFormData] = useState<TaxAdvantagedAccountFormData>({
      ...taxAdvantagedAccountPlanDefaultValues,
    });

    const selectedFormDataPlans = [
      ...(formData?.selectedMedicalPlans || []),
      ...(formData?.selectedDentalPlans || []),
      ...(formData?.selectedVisionPlans || []),
    ];
    const [hsaMedicalPlansMultiSelect, setHsaMedicalPlansMultiSelect] =
      useState<MultiSelect>({
        groups: [],
      });
    const [medicalPlansWithAll, setMedicalPlansWithAll] = useState<string[]>(
      []
    );
    const [contributionsWarning, setContributionsWarning] = useState<{
      state: boolean;
      fieldNames: string[];
    }>({ state: false, fieldNames: [] });
    const [attachedMedicalPlans, setAttachedMedicalPlans] = useState<string[]>(
      []
    );
    const [selectedPlanYear, setSelectedPlanYear] = useState<string>();
    const [filteredHsaPlansList, setFilteredHsaPlansList] = useState<
      MedicalPlan[]
    >([]);
    const [filteredHRAPlansList, setFilteredHRAPlansList] = useState({
      medical: [],
      dental: [],
      vision: [],
    });
    const [tierCount, setTierCount] = useState<number>(0);

    const [selectedFileList, setSelectedFileList] = useState<FileType[]>([]);
    const [isDocRemoved, setIsDocRemoved] = useState<{
      [key: string]: boolean;
    }>({});
    const [selectedWeblink, setSelectedWeblink] = useState<WebLinkType[]>([]);

    const dispatch = useAppDispatch();
    const { employerId, brokerId } = useNavContext();
    const { planYearsList } = useAppSelector(
      (state) => state.employer.employer
    );
    const { inProgress: documentUploadInProgress } = useAppSelector(
      (state) => state.plan.planDocuments
    );
    const basicPlanInfo = useAppSelector((state) => state.plan.planBasicInfo);
    const { inProgress, requestType, documentReferences } = useAppSelector(
      (state) => state.plan.taxAdvantagedAccountPlan
    );
    const { hsaMedicalPlansList } = useAppSelector((state) => state.plan.plans);
    const error = useAppSelector(
      (state) => state.plan.taxAdvantagedAccountPlan.error
    );
    const [showPlanYearWarning, setShowPlanYearWarning] =
      useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const { data: hraPlanList } = useAppSelector(
      (state) => state.plan.dbg.hraPlans
    );

    const { carriersList } = basicPlanInfo;

    const basicPlanInfoRef = useRef<any>();
    const dependentCareFsaInfoRef = useRef<any>();
    const hsaInfoRef = useRef<any>();
    const hraHealthCareFsaLimitedFsaInfoRef = useRef<any>();
    const commuterInformationRef = useRef<any>();
    const documentsRef = useRef<any>();
    const [notificationKey] = useState(PLAN_NOTIFICATION_KEY);
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [contributionForm] = Form.useForm();

    useImperativeHandle(ref, () => ({
      reset() {
        setSelectedFileList([]);
        setSelectedWeblink([]);
        setAlertVisible({ state: false, maxFieldError: false });
        setHsaMedicalPlansMultiSelect({ groups: [] });
        setAttachedMedicalPlans([]);
        setShowWarning(false);
        setShowPlanYearWarning(false);
        setFormData({ ...taxAdvantagedAccountPlanDefaultValues });
        basicPlanInfoRef.current?.resetForm();
        documentsRef.current?.resetAll();
        setContributionsWarning({ state: false, fieldNames: [] });
        switch (planType) {
          case TaxAdvantagedAccountPlanType.HRA.value:
          case TaxAdvantagedAccountPlanType.HEALTHCARE_FSA.value:
          case TaxAdvantagedAccountPlanType.LIMITED_PURPOSE_FSA.value:
            return hraHealthCareFsaLimitedFsaInfoRef.current?.resetForm();
          case TaxAdvantagedAccountPlanType.DEPENDENT_CARE_FSA.value:
            return dependentCareFsaInfoRef.current?.resetForm();
          case TaxAdvantagedAccountPlanType.HSA.value:
            return hsaInfoRef.current?.resetForm();
          case TaxAdvantagedAccountPlanType.COMMUTER.value:
            return commuterInformationRef.current?.resetForm();
        }
      },
      isValidForm: async () => {
        await onClickSave();
      },
    }));

    useEffect(() => {
      if (brokerId && employerId && isModalVisible) {
        dispatch(
          getCarriersByBenefitKind(
            TaxAdvantageCarrierTypes.SAVING,
            brokerId,
            employerId
          )
        );
      }
    }, [dispatch, brokerId, planType, employerId, isModalVisible]);

    useEffect(() => {
      if (formData.benefitCarrier) {
        setSelectedCarrier(
          carriersList.find((t) => t.id === formData.benefitCarrier)
        );
      }
    }, [carriersList, formData.benefitCarrier]);

    useEffect(() => {
      const year = formData.planYear ? formData.planYear : defaultPlanYear;
      setSelectedPlanYear(year);
    }, [formData.planYear, defaultPlanYear]);

    useEffect(() => {
      if (employerId && selectedPlanYear) {
        if (TaxAdvantagedAccountPlanType.HSA.value === planType) {
          dispatch(
            getHsaMedicalPlansList(employerId, selectedPlanYear, planType)
          );
        } else {
          if (isReview) {
            const planType = plan?.category?.toLowerCase();
            if (planType === 'medical') {
              setHraPlansAFP('medical', 'selectedMedicalPlans');
            } else if (planType === 'dental') {
              setHraPlansAFP('dental', 'selectedDentalPlans');
            } else {
              setHraPlansAFP('vision', 'selectedVisionPlans');
            }
          } else {
            dispatch(getHraPlanList(employerId, selectedPlanYear));
          }
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      employerId,
      planType,
      selectedPlanYear,
      isReview,
      isModalVisible,
      plan?.category,
    ]);

    const setHraPlansAFP = (
      categoryVariable: string,
      selectedVariable: string
    ) => {
      dispatch(
        setHraPlanList({
          [categoryVariable]: [
            {
              ...plan?.parentPlan,
              name: plan?.parentPlan?.planName,
              groups: formData?.benefitClass,
              rates: {
                'All Full-Time Employees': { type: RateType.N_TIER.value },
              },
            },
          ],
        })
      );
      setFormData((prevData) => ({
        ...prevData,
        selectedMedicalPlans: [],
        selectedDentalPlans: [],
        selectedVisionPlans: [],
        [selectedVariable]: [plan?.parentPlan?.id],
      }));
    };

    const getTierCount = (hsaList: any[]) => {
      const keys = Object.keys(hsaList?.[0]?.rates);
      if (hsaList?.[0]?.rates?.[keys?.[0]]?.type === 'N_TIER') {
        return (
          hsaList?.[0]?.rates?.[keys?.[0]]?.ntierContributions?.contributions
            ?.length ?? 0
        );
      } else {
        return 0;
      }
    };

    const resetContributionsOnTierChange = (selectedIds: string[]) => {
      let previousSelected: string[] = [];
      if (!isReview) {
        previousSelected = [
          ...(plan?.selectedMedicalPlans || []),
          ...(plan?.selectedDentalPlans || []),
          ...(plan?.selectedVisionPlans || []),
        ];
      } else {
        previousSelected = [...selectedFormDataPlans];
      }

      if (!isEmpty(previousSelected) && isPlanUpdateMode) {
        if (!selectedIds.includes(previousSelected[0])) {
          hraHealthCareFsaLimitedFsaInfoRef.current?.resetOnTierChange();
        } else {
          contributionForm?.setFieldsValue({
            otherContribution: !isEmpty(formData?.otherContribution)
              ? formData?.otherContribution
              : plan?.employerContribution.otherContribution,
            frequency: !isEmpty(formData?.frequency)
              ? formData?.frequency
              : plan?.employerContribution.frequency,
            employerEEContribution: !isEmpty(formData?.employerEEContribution)
              ? formData?.employerEEContribution
              : plan?.employerContribution?.employerEEContribution,
            employerESContribution: !isEmpty(formData?.employerESContribution)
              ? formData?.employerESContribution
              : plan?.employerContribution?.employerESContribution,
            employerEFContribution: !isEmpty(formData?.employerEFContribution)
              ? formData?.employerEFContribution
              : plan?.employerContribution?.employerEFContribution,
            employerECContribution: !isEmpty(formData.employerECContribution)
              ? formData?.employerECContribution
              : plan?.employerContribution?.employerECContribution,
            employerEE1Contribution: !isEmpty(formData?.employerEE1Contribution)
              ? formData?.employerEE1Contribution
              : plan?.employerContribution?.employerEE1Contribution,
            employerEE2Contribution: !isEmpty(formData?.employerEE2Contribution)
              ? formData?.employerEE2Contribution
              : plan?.employerContribution?.employerEE2Contribution,
            employerEE3Contribution: !isEmpty(formData?.employerEE3Contribution)
              ? formData?.employerEE3Contribution
              : plan?.employerContribution?.employerEE3Contribution,
            employerEE4Contribution: !isEmpty(formData?.employerEE4Contribution)
              ? formData?.employerEE4Contribution
              : plan?.employerContribution?.employerEE4Contribution,
            employerEE5Contribution: !isEmpty(formData?.employerEE5Contribution)
              ? formData?.employerEE5Contribution
              : plan?.employerContribution?.employerEE5Contribution,
            employerEE6Contribution: !isEmpty(formData?.employerEE6Contribution)
              ? formData?.employerEE6Contribution
              : plan?.employerContribution?.employerEE6Contribution,
          });
        }
      }
    };

    const filterMDVByIds = (
      ids: string[],
      categories: Record<string, any>
    ): Record<string, string[]> => {
      const filteredCategories: Record<string, string[]> = {
        MEDICAL: [],
        DENTAL: [],
        VISION: [],
      };

      Object.keys(categories).forEach((category) => {
        const MDVCategory = category.toUpperCase();
        filteredCategories[MDVCategory] = categories[category]
          ?.filter((item: any) => ids?.includes(item?.id))
          ?.map((item: any) => item?.id);
      });

      return filteredCategories;
    };

    const getTierCountForSelectedHRAPlans = (hraPlanList: any[] = []) => {
      let maxNumberOfTiers = 0;
      if (isReview) {
        maxNumberOfTiers = plan?.tierCount || 0;
      } else {
        const filteredSelectedHRAPlans = Object.values(hraPlanList)
          .flat()
          .filter((obj) => selectedFormDataPlans?.includes(obj?.id));

        filteredSelectedHRAPlans.forEach((item) => {
          const rate = item?.rates?.[Object.keys(item?.rates)[0]];

          if (rate?.type === RateType.N_TIER.value) {
            const numberOfTiers =
              rate?.ntierContributions?.contributions?.length ?? 0;
            maxNumberOfTiers = Math.max(maxNumberOfTiers, numberOfTiers);
          }
        });
      }
      return maxNumberOfTiers;
    };

    const filterHRAPlans = (plans: MedicalPlan[]): MedicalPlan[] =>
      plans?.filter((plan: MedicalPlan) =>
        plan?.groups?.some((group) => formData?.benefitClass.includes(group))
      );

    const isFormDataEmpty = () => {
      if (
        formData.planName === taxAdvantagedAccountPlanDefaultValues.planName &&
        formData.benefitCarrier ===
          taxAdvantagedAccountPlanDefaultValues.benefitCarrier &&
        formData.benefitClass ===
          taxAdvantagedAccountPlanDefaultValues.benefitClass &&
        formData.benefitKind ===
          taxAdvantagedAccountPlanDefaultValues.benefitKind &&
        formData.groupId === taxAdvantagedAccountPlanDefaultValues.groupId &&
        formData.planYear === taxAdvantagedAccountPlanDefaultValues.planYear
      ) {
        return true;
      }
      return false;
    };

    useEffect(() => {
      if (planType === TaxAdvantagedAccountPlanType.HRA.value) {
        setFilteredHRAPlansList({
          medical: [],
          dental: [],
          vision: [],
        });

        if (!isEmpty(formData?.benefitClass)) {
          const cloneHraList = cloneDeep(hraPlanList);
          cloneHraList.medical = filterHRAPlans(hraPlanList?.medical);
          cloneHraList.dental = filterHRAPlans(hraPlanList?.dental);
          cloneHraList.vision = filterHRAPlans(hraPlanList?.vision);
          setFilteredHRAPlansList(cloneHraList);
          setTierCount(getTierCountForSelectedHRAPlans(cloneHraList));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      hraPlanList,
      formData?.selectedDentalPlans,
      formData?.selectedMedicalPlans,
      formData?.selectedVisionPlans,
      planType,
      formData.benefitClass,
    ]);

    useEffect(() => {
      if (!isEmpty(hsaMedicalPlansList)) {
        setMedicalPlansWithAll([]);
        const plans: MedicalPlan[] = [];
        hsaMedicalPlansList.forEach((plan: MedicalPlan) => {
          plan.groups?.forEach((group: string) => {
            if (
              formData.benefitClass?.includes(group) &&
              !plans.includes(plan)
            ) {
              plans.push(plan);
            }
          });
        });
        setTierCount(getTierCount(hsaMedicalPlansList));
        if (!isEmpty(plans)) {
          setFilteredHsaPlansList(plans);
          setMedicalPlansWithAll(
            [ALL_STATES].concat(
              plans.map((plan: MedicalPlan) => {
                return plan.name;
              })
            )
          );
        }
      } else {
        setFilteredHsaPlansList([]);
        setMedicalPlansWithAll([]);
      }
    }, [formData.benefitClass, hsaMedicalPlansList]);

    useEffect(() => {
      let selectedPlans: string[] = attachedMedicalPlans;
      if (!isEmpty(selectedPlans)) {
        attachedMedicalPlans.forEach((item: string) => {
          const plan: any = hsaMedicalPlansList.find(
            (medicalPlan: MedicalPlan) => medicalPlan.name === item
          );
          if (plan) {
            const contains = plan.groups.some((element: string) => {
              return formData.benefitClass.includes(element);
            });
            if (!contains) {
              selectedPlans = selectedPlans?.filter((selectedPlan: string) => {
                if (item === selectedPlan) {
                  const event = {
                    target: {
                      name: 'group',
                      value: selectedPlan,
                      checked: false,
                    },
                  };
                  onMedicalPlanCheckboxSelection(event);
                }
                return item !== selectedPlan;
              });
            }
          }
        });
        setAttachedMedicalPlans(selectedPlans);
        form.setFieldsValue({ selectedMedicalPlans: selectedPlans });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      form,
      formData.benefitClass,
      hsaMedicalPlansList,
      attachedMedicalPlans,
    ]);

    useEffect(() => {
      if (isEmpty(formData?.benefitClass) && hsaMedicalPlansList) {
        setAttachedMedicalPlans([]);
        form.setFieldsValue({ selectedMedicalPlans: null });
        setHsaMedicalPlansMultiSelect({ groups: [] });
      }
    }, [form, formData?.benefitClass, hsaMedicalPlansList]);

    useEffect(() => {
      if (error?.data?.code === VALIDATION_NAME_DUPLICATED) {
        form.setFields([{ name: 'planName', errors: [EMPTY_MESSAGE] }]);
        setAlertMessage({
          type: 'error',
          message: planAlreadyExistsError,
        });
        setAlertVisible({ state: true, maxFieldError: false });
        dispatch(unsetApiError());
        setLoading(false);
      }
    }, [dispatch, error, form]);

    const onFileChange = (document: File, docType: string) => {
      if (isReview) {
        onFileChangeAction?.(document, docType, getData());
      } else {
        dispatch(uploadTemporaryDocument(document, docType));
      }
    };

    useEffect(() => {
      if (plan) {
        const {
          name,
          groups,
          planYearId,
          benefitCarrier,
          maxYearlyContribution,
          maxIndividualContributionForMarriedButFilledAsSeparately,
          maxMarriedContributionForFillingJointlyOrSingleParentAsHead,
          endOfYearPolicy,
          maxYearlyContributionIndividual,
          maxYearlyContributionFamily,
          employerContribution,
          maxMonthlyContribution,
          employerMonthlyContribution,
          documents,
          benefitKind,
          selectedMedicalPlans = [],
          selectedDentalPlans = [],
          selectedVisionPlans = [],
          groupId,
          hraUtilization,
        } = plan;
        const {
          frequency,
          otherContribution,
          employerEEContribution,
          employerESContribution,
          employerECContribution,
          employerEFContribution,
          employerEE1Contribution,
          employerEE2Contribution,
          employerEE3Contribution,
          employerEE4Contribution,
          employerEE5Contribution,
          employerEE6Contribution,
          empContribution,
        } = employerContribution || {};

        const { id } = benefitCarrier || {};
        const formData: TaxAdvantagedAccountFormData = {
          planName: name,
          benefitClass: groups,
          planYear: planYearId,
          benefitCarrier: id ? id : null,
          maxYearlyContribution: maxYearlyContribution,
          maxIndividualContributionForMarriedButFilledAsSeparately:
            maxIndividualContributionForMarriedButFilledAsSeparately,
          maxMarriedContributionForFillingJointlyOrSingleParentAsHead:
            maxMarriedContributionForFillingJointlyOrSingleParentAsHead,
          endOfYearPolicy: endOfYearPolicy,
          maxYearlyContributionIndividual: maxYearlyContributionIndividual,
          maxYearlyContributionFamily: maxYearlyContributionFamily,
          frequency: frequency,
          otherContribution: otherContribution,
          employerEEContribution: employerEEContribution,
          employerESContribution: employerESContribution,
          employerECContribution: employerECContribution,
          employerEFContribution: employerEFContribution,
          employerEE1Contribution: employerEE1Contribution,
          employerEE2Contribution: employerEE2Contribution,
          employerEE3Contribution: employerEE3Contribution,
          employerEE4Contribution: employerEE4Contribution,
          employerEE5Contribution: employerEE5Contribution,
          employerEE6Contribution: employerEE6Contribution,
          empContribution: empContribution,
          maxMonthlyContribution: maxMonthlyContribution,
          employerMonthlyContribution: employerMonthlyContribution,
          benefitKind: benefitKind,
          documents: documents,
          selectedMedicalPlans: selectedMedicalPlans,
          selectedDentalPlans: selectedDentalPlans,
          selectedVisionPlans: selectedVisionPlans,
          groupId: groupId,
          hraUtilization: hraUtilization,
        };

        form.setFieldsValue(formData);
        setFormData(formData);

        setAttachedMedicalPlans(selectedMedicalPlans);
        const selectedMedicalPlans2 = plan.selectedMedicalPlanVOs?.map(
          (plan) => plan.name
        );
        setHsaMedicalPlansMultiSelect({ groups: selectedMedicalPlans2 });
        dispatch(getPlanByIdCompleted(plan));
      }
    }, [dispatch, form, plan]);

    useEffect(() => {
      setShowWarning(false);
      setShowPlanYearWarning(false);
      if (
        !isEmpty(plan) &&
        !isFormDataEmpty() &&
        isPlanUpdateMode &&
        !isReview &&
        formData.benefitClass &&
        !isEqual(plan?.groups, formData.benefitClass)
      ) {
        setShowPlanYearWarning(false);
        setShowWarning(true);
        setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
        setAlertVisible({ state: true, maxFieldError: false });
      }
      if (
        !isEmpty(plan) &&
        isPlanUpdateMode &&
        formData.planYear &&
        !isEqual(plan?.planYearId, formData.planYear?.trim())
      ) {
        setShowPlanYearWarning(true);
        setAlertMessage({ message: planYearChangedMsg, type: 'warning' });
        setAlertVisible({ state: true, maxFieldError: false });
      }
      if (
        !isEmpty(plan) &&
        !isFormDataEmpty() &&
        isPlanUpdateMode &&
        !isReview &&
        !isEqual(plan?.planYearId, formData.planYear?.trim()) &&
        formData.benefitClass &&
        !isEqual(plan?.groups, formData.benefitClass)
      ) {
        setShowPlanYearWarning(true);
        setShowWarning(true);
        setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
        setAlertVisible({ state: true, maxFieldError: false });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlanUpdateMode, plan, formData.benefitClass, formData.planYear]);

    const getDefaultStates = () => {
      let selectedItemValues: string | undefined;
      let showAll = false;
      if (hsaMedicalPlansMultiSelect.groups?.includes(ALL_STATES)) {
        selectedItemValues = medicalPlansWithAll.join(', ');
        showAll = true;
      } else {
        selectedItemValues = hsaMedicalPlansMultiSelect.groups?.join(', ');
        showAll =
          hsaMedicalPlansMultiSelect.groups?.length >
          medicalPlansWithAll.length;
      }
      if (
        hsaMedicalPlansMultiSelect?.groups?.length ===
        medicalPlansWithAll?.filter((plan) => plan !== ALL_STATES)?.length
      ) {
        selectedItemValues = [ALL_STATES].concat(selectedItemValues).join(', ');
        showAll = true;
      }
      return { selectedItemValues, showAll };
    };
    const { selectedItemValues, showAll } = getDefaultStates();

    const validateBasicInfo = async () => {
      try {
        await form.validateFields(
          TaxAdvantagedBasicInfoFormFields.requiredFields
        );
        return true;
      } catch (errorInfo: any) {
        return errorInfo.errorFields.length === 0;
      }
    };

    const getValidationResult = async () => {
      const isFormValid = await validateBasicInfo();
      if (isFormValid) {
        return true;
      } else {
        setAlertVisible({ state: true, maxFieldError: false });
        return false;
      }
    };

    const onSave = async (id: string) => {
      await uploadDocument(id);
    };

    const getData = () => {
      const benefitCategory = planType;
      const selectedPlanYear = planYearsList.find(
        (t) => t.id === formData.planYear
      );
      const carrierId = formData.benefitCarrier;
      const newPlan = {
        benefitKind: planType,
        benefitCarrier: {
          name: carrierId ? selectedCarrier?.name : null,
          benefitCategory: benefitCategory,
          id: carrierId ? selectedCarrier?.id : null,
        },
        employerId: employerId,
        planYearId: formData.planYear?.trim(),
        name: formData.planName.trim(),
        startDate: selectedPlanYear?.startDate,
        endDate: selectedPlanYear?.endDate,
        groups: formData.benefitClass,
        maxYearlyContribution: formData.maxYearlyContribution,
        maxIndividualContributionForMarriedButFilledAsSeparately:
          formData.maxIndividualContributionForMarriedButFilledAsSeparately,
        maxMarriedContributionForFillingJointlyOrSingleParentAsHead:
          formData.maxMarriedContributionForFillingJointlyOrSingleParentAsHead,
        endOfYearPolicy: formData.endOfYearPolicy,
        maxYearlyContributionIndividual:
          formData.maxYearlyContributionIndividual,
        maxYearlyContributionFamily: formData.maxYearlyContributionFamily,
        employerContribution: {
          frequency: formData.frequency,
          otherContribution:
            !isEmpty(formData.otherContribution) && formData.otherContribution
              ? formData.otherContribution.trim()
              : '',
          employerEEContribution: formData.employerEEContribution,
          employerESContribution: formData.employerESContribution,
          employerECContribution: formData.employerECContribution,
          employerEFContribution: formData.employerEFContribution,
          employerEE1Contribution: formData.employerEE1Contribution,
          employerEE2Contribution: formData.employerEE2Contribution,
          employerEE3Contribution: formData.employerEE3Contribution,
          employerEE4Contribution: formData.employerEE4Contribution,
          employerEE5Contribution: formData.employerEE5Contribution,
          employerEE6Contribution: formData.employerEE6Contribution,
          empContribution: formData.empContribution,
        },
        maxMonthlyContribution: formData.maxMonthlyContribution,
        employerMonthlyContribution: formData.employerMonthlyContribution,
        selectedMedicalPlans: formData.selectedMedicalPlans
          ? formData.selectedMedicalPlans
          : attachedMedicalPlans,
        selectedDentalPlans: formData.selectedDentalPlans,
        selectedVisionPlans: formData.selectedVisionPlans,
        benefitKinds: [planType],
        documentReferences: documentReferences,
        documents: formData.documents,
        groupId: formData.groupId?.trim(),
      } as TaxAdvantagedAccountPlan;

      if (isPlanUpdateMode && plan) {
        newPlan.id = plan.id;
      }

      return newPlan;
    };

    const onClickSave = async () => {
      setAlertVisible({ state: false, maxFieldError: false });
      setShowWarning(false);
      setShowPlanYearWarning(false);
      const isFormValid = await getValidationResult();

      const benefitCategory = planType;
      const selectedPlanYear = planYearsList.find(
        (t) => t.id === formData.planYear
      );
      const carrierId = formData.benefitCarrier;
      const newPlan = {
        benefitKind: planType,
        benefitCarrier: {
          name: carrierId ? selectedCarrier?.name : null,
          benefitCategory: benefitCategory,
          id: carrierId ? selectedCarrier?.id : null,
        },
        employerId: employerId,
        planYearId: formData.planYear?.trim(),
        name: formData.planName.trim(),
        startDate: selectedPlanYear?.startDate,
        endDate: selectedPlanYear?.endDate,
        groups: formData.benefitClass,
        maxYearlyContribution: formData.maxYearlyContribution,
        maxIndividualContributionForMarriedButFilledAsSeparately:
          formData.maxIndividualContributionForMarriedButFilledAsSeparately,
        maxMarriedContributionForFillingJointlyOrSingleParentAsHead:
          formData.maxMarriedContributionForFillingJointlyOrSingleParentAsHead,
        endOfYearPolicy: formData.endOfYearPolicy,
        maxYearlyContributionIndividual:
          formData.maxYearlyContributionIndividual,
        maxYearlyContributionFamily: formData.maxYearlyContributionFamily,
        employerContribution: {
          frequency: formData.frequency,
          otherContribution:
            !isEmpty(formData.otherContribution) && formData.otherContribution
              ? formData.otherContribution.trim()
              : '',
          employerEEContribution: formData.employerEEContribution,
          employerESContribution: formData.employerESContribution,
          employerECContribution: formData.employerECContribution,
          employerEFContribution: formData.employerEFContribution,
          employerEE1Contribution: formData.employerEE1Contribution,
          employerEE2Contribution: formData.employerEE2Contribution,
          employerEE3Contribution: formData.employerEE3Contribution,
          employerEE4Contribution: formData.employerEE4Contribution,
          employerEE5Contribution: formData.employerEE5Contribution,
          employerEE6Contribution: formData.employerEE6Contribution,
          empContribution: formData.empContribution,
        },
        maxMonthlyContribution: formData.maxMonthlyContribution,
        employerMonthlyContribution: formData.employerMonthlyContribution,
        // HSA we take attachedMedicalPlans else HRA medical formData.selectedMedicalPlans we take formData
        selectedMedicalPlans:
          planType === TaxAdvantagedAccountPlanType.HRA.value
            ? formData?.selectedMedicalPlans
            : attachedMedicalPlans,
        selectedDentalPlans: formData.selectedDentalPlans,
        selectedVisionPlans: formData.selectedVisionPlans,
        benefitKinds: [planType],
        documentReferences: documentReferences,
        documents: formData.documents,
        groupId: formData.groupId?.trim(),
        hraUtilization: formData.hraUtilization,
      } as TaxAdvantagedAccountPlan;

      if (isPlanUpdateMode && plan) {
        newPlan.id = plan.id;
      }

      if (isFormValid) {
        if (isReview) {
          return onPlanSave?.(newPlan);
        }
        if (isPlanUpdateMode && isDBGPlan) {
          setLoading(true);
        }
        await dispatch(
          saveTaxAdvantagedAccountPlan(newPlan, (id: string) => onSave(id))
        );
        notification.close(notificationKey);
      } else {
        setAlertVisible({ state: true, maxFieldError: false });
        setAlertMessage({
          type: 'error',
          message: formVerificationMsg,
        });
        if (isReview) {
          throw Error;
        }
      }
    };

    const onCancel = () => {
      setShowWarning(false);
      setShowPlanYearWarning(false);
      onReset();
    };

    const handleFileRemove = (docType: string) => {
      if (
        isPlanUpdateMode &&
        Object.keys(formData.documents ? formData.documents : {}).includes(
          docType
        )
      ) {
        const clonedDocuments = { ...formData.documents };
        delete clonedDocuments[docType];
        setFormData((prevData) => ({
          ...prevData,
          documents: clonedDocuments,
        }));
      }
      dispatch(removeDocumentReference(docType));
    };

    const getMedicalPlanCheckboxDataForGroups = (
      checkboxValues: string[],
      value: string,
      checked: boolean
    ): string[] => {
      let newCheckboxValues = cloneDeep(checkboxValues);

      if (checked) {
        newCheckboxValues = [...checkboxValues, value];
        if (checkboxValues.length === filteredHsaPlansList.length - 1) {
          newCheckboxValues.unshift(ALL_STATES);
        }
        if (value === ALL_STATES) {
          newCheckboxValues = medicalPlansWithAll;
        }
      } else {
        const index = checkboxValues.findIndex((element) => element === value);

        if (checkboxValues.includes(ALL_STATES)) {
          const indexOfAll = checkboxValues.indexOf(ALL_STATES);
          newCheckboxValues.splice(indexOfAll, 1);
          newCheckboxValues.splice(index - 1, 1);
        } else {
          newCheckboxValues.splice(index, 1);
        }

        if (value === ALL_STATES) {
          newCheckboxValues = [];
        }
      }
      return newCheckboxValues;
    };

    const onMedicalPlanCheckboxSelection = (event: any) => {
      const { name, value, checked } = event.target;
      let newCheckboxValues = getMedicalPlanCheckboxDataForGroups(
        hsaMedicalPlansMultiSelect.groups,
        value,
        checked
      );
      newCheckboxValues = newCheckboxValues
        .filter((value) => value === ALL_STATES)
        .concat(
          newCheckboxValues.filter((value) => value !== ALL_STATES).sort()
        );

      if (value === true) {
        setHsaMedicalPlansMultiSelect((prevState) => ({
          ...prevState,
          [name]: newCheckboxValues,
        }));
      } else {
        setHsaMedicalPlansMultiSelect({ groups: newCheckboxValues });
      }
      let actualCheckboxValues = newCheckboxValues;
      if (newCheckboxValues.includes(ALL_STATES)) {
        actualCheckboxValues = hsaMedicalPlansList;
        const plansWithId = hsaMedicalPlansList
          .filter(
            (item: MedicalPlan) =>
              Boolean(item.id) && newCheckboxValues.includes(item.name)
          )
          .map((filtered: MedicalPlan) => filtered.id as string);
        setAttachedMedicalPlans(plansWithId);
      } else {
        const plansWithId = hsaMedicalPlansList
          .filter(
            (item: MedicalPlan) =>
              Boolean(item.id) && actualCheckboxValues.includes(item.name)
          )
          .map((filtered: MedicalPlan) => filtered.id as string);
        setAttachedMedicalPlans(plansWithId);
      }
      form.setFieldsValue({ selectedMedicalPlans: actualCheckboxValues });
    };

    const getContributionForm = () => {
      switch (planType) {
        case TaxAdvantagedAccountPlanType.HRA.value:
        case TaxAdvantagedAccountPlanType.HEALTHCARE_FSA.value:
        case TaxAdvantagedAccountPlanType.LIMITED_PURPOSE_FSA.value:
          return (
            <HRAHealthCareFSALimitedFSAPlanInfo
              isCancelButtonClickConfirm={isCancelButtonClickConfirm}
              setFormData={setFormData}
              form={contributionForm}
              formData={formData}
              savingsPlanType={planType}
              otherEmployerContributionsTooltipContent={otherEmployerContributionsTooltipContent(
                planType
              )}
              endOfYearPolicyTooltipContent={EndOfYearPolicyTooltipContent}
              ref={hraHealthCareFsaLimitedFsaInfoRef}
              onNumberInputChange={onNumberInputChange}
              changeInputStyle={changeInputStyle}
              isFrequencyError={isFrequencyError()}
              isEditMode={isPlanUpdateMode || isReview}
              tierCount={tierCount}
              plan={plan}
              isReviewHighlight={isReviewHighlight}
              isReview={isReview}
            />
          );
        case TaxAdvantagedAccountPlanType.DEPENDENT_CARE_FSA.value:
          return (
            <DependentCareFSAPlanInformation
              isCancelButtonClickConfirm={isCancelButtonClickConfirm}
              setFormData={setFormData}
              formData={formData}
              otherEmployerContributionsTooltipContent={otherEmployerContributionsTooltipContent(
                planType
              )}
              endOfYearPolicyTooltipContent={EndOfYearPolicyTooltipContent}
              ref={dependentCareFsaInfoRef}
              onNumberInputChange={onNumberInputChange}
              changeInputStyle={changeInputStyle}
              isFrequencyError={isFrequencyError()}
              isEditMode={isPlanUpdateMode}
            />
          );
        case TaxAdvantagedAccountPlanType.HSA.value:
          return (
            <HSAPlanInformation
              isCancelButtonClickConfirm={isCancelButtonClickConfirm}
              setFormData={setFormData}
              formData={formData}
              otherEmployerContributionsTooltipContent={otherEmployerContributionsTooltipContent(
                planType
              )}
              ref={commuterInformationRef}
              onNumberInputChange={onNumberInputChange}
              changeInputStyle={changeInputStyle}
              isFrequencyError={isFrequencyError()}
              isEditMode={isPlanUpdateMode}
              tierCount={tierCount}
            />
          );
        case TaxAdvantagedAccountPlanType.COMMUTER.value:
          return (
            <CommuterPlanInformation
              isCancelButtonClickConfirm={isCancelButtonClickConfirm}
              setFormData={setFormData}
              formData={formData}
              otherEmployerContributionsTooltipContent={otherEmployerContributionsTooltipContent(
                planType
              )}
              ref={commuterInformationRef}
              onNumberInputChange={onNumberInputChange}
              changeInputStyle={changeInputStyle}
              isEditMode={isPlanUpdateMode}
            />
          );
      }
    };

    const getMaxValues = (fieldName: string) => {
      switch (planType) {
        case TaxAdvantagedAccountPlanType.HRA.value:
          if (fieldName === TaxAdvantageFields.EMPLOYER_EE_CONTRIBUTION) {
            return TaxAdvantagedMaxValues.HRA.maxEEValue;
          } else {
            return TaxAdvantagedMaxValues.HRA.maxOtherValue;
          }
        case TaxAdvantagedAccountPlanType.HEALTHCARE_FSA.value:
          return TaxAdvantagedMaxValues.HEALTHCARE_FSA.maxValue;
        case TaxAdvantagedAccountPlanType.LIMITED_PURPOSE_FSA.value:
          return TaxAdvantagedMaxValues.LIMITED_PURPOSE_FSA.maxValue;
        case TaxAdvantagedAccountPlanType.DEPENDENT_CARE_FSA.value:
          return TaxAdvantagedMaxValues.DEPENDENT_CARE_FSA.maxValue;
        case TaxAdvantagedAccountPlanType.HSA.value:
          if (fieldName === TaxAdvantageFields.EMPLOYER_EE_CONTRIBUTION) {
            return TaxAdvantagedMaxValues.HSA.maxEEValue;
          } else {
            return TaxAdvantagedMaxValues.HSA.maxOtherValue;
          }
        case TaxAdvantagedAccountPlanType.COMMUTER.value:
          return TaxAdvantagedMaxValues.COMMUTER.maxValue;
        default:
          return 0;
      }
    };

    const onNumberInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const _value = parseFloat(value);

      if (value === '') {
        setContributionsWarning({ state: false, fieldNames: [] });
      }

      if (
        planType !== TaxAdvantagedAccountPlanType.HRA.value &&
        name &&
        _value > getMaxValues(name) &&
        !contributionsWarning.fieldNames.includes(name) &&
        getMaxValues(name) !== 0
      ) {
        setContributionsWarning({
          state: true,
          fieldNames: [...contributionsWarning.fieldNames, name],
        });
        setAlertMessage({
          type: 'warning',
          message:
            'Warning! Contributions are near or over the maximum. Please verify the amounts.',
        });
        setAlertVisible({ state: true, maxFieldError: true });
      } else if (_value <= getMaxValues(name)) {
        const errorFieldsNames = contributionsWarning.fieldNames.filter(
          (item) => item !== name
        );
        if (!isEmpty(errorFieldsNames)) {
          setContributionsWarning({
            state: true,
            fieldNames: errorFieldsNames,
          });
        } else {
          setContributionsWarning({ state: false, fieldNames: [] });
          setAlertVisible({ state: false, maxFieldError: true });
        }
      }
    };

    const onValidateFails = (validateSetting: string) => {
      setAlertMessage({
        message: getValidationMessage(validateSetting),
        type: 'error',
      });
      setAlertVisible({ state: true, maxFieldError: false });
    };

    const changeInputStyle = (fieldName: string) => {
      if (contributionsWarning.fieldNames.includes(fieldName)) {
        return true;
      }
    };

    const isFrequencyError = () => {
      if (!isEmpty(contributionsWarning.fieldNames)) {
        return true;
      }
      return false;
    };

    const handleResetMedicalPlanSelection = () => {
      setAttachedMedicalPlans([]);
      form.setFieldsValue({ selectedMedicalPlans: null });
      setHsaMedicalPlansMultiSelect({ groups: [] });
    };

    const getPlanDefaultName = (planType: string) => {
      switch (planType) {
        case BenefitKind.HRA.value:
          return BenefitKind.HRA.label;
        case BenefitKind.FSA.value:
          return BenefitKind.FSA.label;
        case BenefitKind.DCAP_FSA.value:
          return BenefitKind.DCAP_FSA.label;
        case BenefitKind.LIMITED_FSA.value:
          return BenefitKind.LIMITED_FSA.label;
        case BenefitKind.HSA.value:
          return BenefitKind.HSA.label;
        case BenefitKind.COMMUTER_GENERIC.value:
          return BenefitKind.COMMUTER_GENERIC.label;
        default:
          return '';
      }
    };

    const uploadDocument = async (id: string) => {
      const uploadDocumentSuccess = () => {
        closePlanAddModal();
        setShowWarning(false);
        setShowPlanYearWarning(false);
        onSaveClose();
        if (plan && plan.id) {
          dispatch(findPlanById(plan.id));
        }
      };

      const planId = id ?? plan?.id;
      const removeDocs = Object.keys(isDocRemoved)
        .filter((documentType) => isDocRemoved[documentType])
        .map((documentType) => ({
          docType: PLAN_ADDITIONAL_DOCUMENT,
          planDocumentName: documentType,
        }));
      if (!isEmpty(selectedFileList) && isEmpty(removeDocs)) {
        await dispatch(uploadPlanDocument(selectedFileList, planId, planType));
      } else if (!isEmpty(removeDocs) && isEmpty(selectedFileList)) {
        await dispatch(removePlanDocuments(planId, planType, removeDocs));
      } else if (!isEmpty(removeDocs) && !isEmpty(selectedFileList)) {
        await dispatch(
          uploadRemovePlanDocument(
            selectedFileList,
            planId,
            planType,
            removeDocs
          )
        );
      }
      if (selectedWeblink.length > 0) {
        await dispatch(updateWeblinks(selectedWeblink, planId, planType));
      }
      uploadDocumentSuccess();
    };

    return (
      <div
        className={`${
          isPlanUpdateMode
            ? styles.planEditModeWrapper
            : isReview
            ? styles.planReviewWrapperForm
            : styles.wrapperForm
        }`}
      >
        {alertVisible.state && !alertVisible.maxFieldError && (
          <AlertMessage
            className={
              isPlanUpdateMode
                ? styles.alertWrapper
                : styles.alertMessageWrapper
            }
            type={alertMessage.type}
            message={alertMessage.message}
            closeAlert={() => {
              setAlertVisible({ state: false, maxFieldError: false });
            }}
          />
        )}
        {isSaveDisabled && !plan && (
          <FixedAlertMessage
            type={'error'}
            message={ERROR_MESSAGE_PLAN_EDITING}
          />
        )}
        <PlanBasicForm
          benefitCategory={BenefitCategory.SAVING.value}
          ref={basicPlanInfoRef}
          carriersList={carriersList}
          setRequiredFieldError={setAlertVisible}
          setFormData={setFormData}
          formData={formData}
          form={form}
          isSavingsPlan={
            planType === TaxAdvantagedAccountPlanType.HSA.value ||
            planType === TaxAdvantagedAccountPlanType.HRA.value
          }
          associateMedicalPlansElements={{
            onMedicalPlanCheckboxSelection: onMedicalPlanCheckboxSelection,
            selectedItemValues: selectedItemValues,
            showAll: showAll,
            options: medicalPlansWithAll,
            tooltipContent: associatedMedicalPlansTooltipContent(planType),
            onResetSelection: handleResetMedicalPlanSelection,
          }}
          isPlanUpdateMode={isPlanUpdateMode}
          dbgPlanYear={dbgPlanYear}
          isDBGPlan={isDBGPlan}
          isModalVisible={isModalVisible}
          showWarning={showWarning}
          showPlanYearWarning={showPlanYearWarning}
          defaultPlanName={isReview ? plan?.name : getPlanDefaultName(planType)}
          planType={planType}
          currentPlanId={plan?.id}
          hraPlanList={filteredHRAPlansList}
          onChangeReferencePlans={(valuesArr) => {
            resetContributionsOnTierChange(
              isReview ? selectedFormDataPlans : valuesArr
            );
            const selectedMDVplanList = filterMDVByIds(
              valuesArr,
              filteredHRAPlansList
            );
            setFormData((prevData) => ({
              ...prevData,
              frequency: EmployerContributionFrequency[2].value,
              selectedMedicalPlans: selectedMDVplanList.MEDICAL,
              selectedDentalPlans: selectedMDVplanList.DENTAL,
              selectedVisionPlans: selectedMDVplanList.VISION,
            }));
          }}
          isReviewHighlight={isReviewHighlight}
          reviewNewPlanYear={reviewNewPlanYear}
          reviewBenefitClasses={reviewBenefitClasses}
          isHightlightCarrier={isHightlightCarrier}
          isReview={isReview}
        />
        <div className={styles.header}>Plan Information</div>
        {alertVisible.state && alertVisible.maxFieldError && (
          <AlertMessage
            type={alertMessage.type}
            className={`${
              isPlanUpdateMode
                ? styles.editWarningAlertMessageWrapper
                : styles.warningAlertMessageWrapper
            }  `}
            message={alertMessage.message}
            closeAlert={() => {
              setAlertVisible({ state: false, maxFieldError: false });
            }}
          />
        )}
        {getContributionForm()}
        <div className={styles.header}>Plan Resources</div>
        <EditPlanDocuments
          onUpload={onFileChange}
          onFileRemove={(docType: any) => {
            if (isReview && onFileRemoveAction) {
              onFileRemoveAction(docType);
            } else {
              handleFileRemove(docType);
            }
          }}
          documents={formData.documents ? formData.documents : {}}
          benefitKind={planType}
          planId={plan?.id}
          documentReferences={documentReferences}
          planDocumentTypes={TaxAdvantagedPlanDocumentType}
          ref={documentsRef}
          onValidateFails={onValidateFails}
          isSaveDisabled={isSaveDisabled}
          onFileRemoveAction={onFileRemoveAction}
          isReview={isReview}
        />
        <AdditionalPlanResources
          ref={ref}
          plan={plan}
          benefitKind={planType}
          isCloseConfirmed={isCancelButtonClickConfirm}
          selectedFileList={selectedFileList}
          setSelectedFileList={setSelectedFileList}
          selectedWebLinkList={selectedWeblink}
          setSelectedWebLinkList={setSelectedWeblink}
          setIsDocRemoved={setIsDocRemoved}
        />
        {isReview ? (
          ''
        ) : (
          <div className={styles.btnWrapper}>
            <SubmitButton
              disabled={inProgress || isSaveDisabled}
              loading={
                (inProgress &&
                  requestType ===
                    updateTaxAdvantagedAccountPlanInProgress.type) ||
                loading ||
                documentUploadInProgress
              }
              type="primary"
              className={styles.saveButtonWrapper}
              onClick={onClickSave}
            >
              {isPlanUpdateMode ? 'Done' : 'Save'}
            </SubmitButton>
            <CancelButton
              className={styles.cancelButtonWrapper}
              onClick={onCancel}
              withBorder={isPlanUpdateMode}
            >
              Cancel
            </CancelButton>
          </div>
        )}
      </div>
    );
  }
);
AddTaxAdvantagedPlan.displayName = 'AddTaxAdvantagedPlan';
export default AddTaxAdvantagedPlan;
