import {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {
  Select,
  Button,
  Pagination,
  Table,
  TablePaginationConfig,
  TableProps,
} from 'antd';

import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import prevIcon from 'assets/images/icon-prev.svg';
import nextIcon from 'assets/images/icon-next.svg';
import selectIcon from 'assets/images/icon-caret-down.svg';
import { useStateCallback } from 'hooks/updateState';
import TablePagination from 'model/TablePagination';
import { PaginationElementType } from 'constants/commonConstants';
import { usePrevious } from 'hooks/usePrevious';

import styles from './datatable.module.less';

export interface DataTableProps extends Omit<TableProps<any>, 'pagination'> {
  columns: any[];
  data: any[];
  pagination?: boolean;
  loading?: boolean;
  currentIndex?: number;
  total?: string;
  pageSize?: number;
  getData?: (config: TablePagination) => void;
  clientSidePagination?: boolean;
  hasOutsideFilter?: boolean;
  sorterInfo?: SorterResult<any> | SorterResult<any>[];
  paginationRowText?: string;
  tableRef?: any;
  pageSizeOptions?: number[];
  showPageSize?: boolean;
  showTotalCount?: boolean;
  rfpContextPadding?: boolean;
}

const DataTable = forwardRef((props: DataTableProps, ref) => {
  const {
    data,
    columns,
    pagination,
    total,
    pageSize,
    currentIndex,
    getData,
    clientSidePagination = false,
    hasOutsideFilter = false,
    sorterInfo,
    paginationRowText,
    pageSizeOptions = [10, 20, 50],
    showPageSize = true,
    showTotalCount = true,
    rfpContextPadding = false,
    ...rest
  } = props;

  const [current, setCurrent] = useState<number>(currentIndex || 1);
  const [pageSizePerRow, setPageSizePerRow] = useState<number>(pageSize || 10);
  const [tableConfig, setTableConfig] = useStateCallback({
    sorterInfo: sorterInfo,
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: '',
    },
    filters: {},
  });
  const [tableData, setTableData] = useState<any[]>(data);
  const [spliceIndex, setSpliceIndex] = useState<number>(0);

  const previousData = usePrevious(data);

  useEffect(() => {
    if (!isEqual(data, previousData) && hasOutsideFilter) {
      setSpliceIndex(0);
      setCurrent(1);
    }
  }, [data, hasOutsideFilter, previousData]);

  useEffect(() => {
    if (clientSidePagination) {
      const originalTableData = [...data];
      const newTableData = originalTableData.splice(spliceIndex, pageSize);
      if (!isEmpty(newTableData)) {
        setTableData(newTableData);
      } else {
        setTableData(originalTableData.splice(0, pageSize));
      }
    }
  }, [clientSidePagination, currentIndex, data, pageSize, spliceIndex]);

  useEffect(() => {
    if (currentIndex && current !== currentIndex) {
      setCurrent(currentIndex);
    }
  }, [current, currentIndex]);

  const handleChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    const paginationIndex = current;
    const { filterInfo } = tableConfig;
    filterInfo.offset = (paginationIndex - 1) * pageSizePerRow;
    const dataTableConfig = {
      filterInfo: { ...filterInfo, limit: pageSizePerRow },
      sorterInfo: sorter,
      paginationIndex,
      filters,
    };
    if (!isEmpty(sorter)) {
      setCurrent(1);
      dataTableConfig.paginationIndex = 1;
    }
    setTableConfig(dataTableConfig);
    getData && getData(dataTableConfig);
  };

  useImperativeHandle(ref, () => ({
    reload() {
      getData && getData(tableConfig);
    },
  }));

  const itemRender = (
    page: number,
    type: string,
    originalElement: ReactNode
  ) => {
    if (type === PaginationElementType.prev) {
      return (
        <Button className={styles.prevButton}>
          <span>
            <img src={prevIcon} alt="prev-icon" />
          </span>
        </Button>
      );
    } else if (type === PaginationElementType.next) {
      return (
        <Button className={styles.prevButton}>
          <span>
            <img src={nextIcon} alt="next-icon" />
          </span>
        </Button>
      );
    }
    return originalElement;
  };

  const handlePageChange = (page: number, pageSize: number) => {
    if (clientSidePagination) {
      if (page > current) {
        setSpliceIndex(spliceIndex + (page - current) * pageSize);
        setCurrent(page);
      } else {
        if (page !== current) {
          setSpliceIndex(spliceIndex - (current - page) * pageSize);
          setCurrent(page);
        }
      }
      if (pageSize !== pageSizePerRow) {
        setCurrent(1);
        setSpliceIndex(0);
      }
    } else {
      setCurrent(page);
    }

    setPageSizePerRow(pageSize);
    setTableConfig(
      {
        ...tableConfig,
        paginationIndex: page,
        filterInfo: { limit: pageSize, offset: 0, searchText: '' },
      },
      () => {
        getData &&
          getData({
            ...tableConfig,
            paginationIndex: page,
            filterInfo: { limit: pageSize, offset: 0, searchText: '' },
          });
      }
    );
  };

  useEffect(() => {
    setPageSizePerRow(pageSize || 10);
  }, [pageSize]);

  return (
    <>
      <Table
        {...rest}
        dataSource={clientSidePagination ? tableData : data}
        columns={columns}
        className={styles.tableWrapper}
        onChange={handleChange}
        pagination={false}
        showSorterTooltip={false}
      />
      {pagination && (
        <>
          {showPageSize ? (
            <Select
              value={pageSizePerRow}
              onChange={(e) => {
                handlePageChange(1, e);
              }}
              bordered={false}
              className={styles.rowDropdown}
              suffixIcon={<img src={selectIcon} alt="select-icon" />}
            >
              {pageSizeOptions.map((pageSize) => (
                <Select.Option key={pageSize} value={pageSize}>
                  View: {`${pageSize} `}
                  {paginationRowText ? paginationRowText : 'Rows'}
                </Select.Option>
              ))}
            </Select>
          ) : (
            ''
          )}
          <Pagination
            showSizeChanger
            defaultCurrent={1}
            current={current}
            pageSize={pageSizePerRow}
            total={parseInt(total || '0')}
            onChange={handlePageChange}
            itemRender={itemRender}
            className={`${styles.paginationWrapper} ${
              showPageSize
                ? styles.showPageSizePadding
                : styles.hidePageSizePadding
            } ${rfpContextPadding && styles.rfpContextPadding}`}
            pageSizeOptions={pageSizeOptions}
            showTotal={
              showTotalCount
                ? (total, range) => (
                    <div className={styles.totalCount}>
                      {range[0]}-{range[1]} of {total}
                    </div>
                  )
                : undefined
            }
          />
        </>
      )}
    </>
  );
});

DataTable.displayName = 'DataTable';

export default DataTable;
