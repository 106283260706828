import React, { useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import * as _ from 'lodash';
import { Empty } from 'antd';

import {
  LegendProps,
  xAxisProps,
  yAxisProps,
} from 'modules/claims/constants/types';

import styles from './barChart.module.less';

type Props = {
  data: any[];
  dataKey: string;
  gridStroke?: string;
  chartDimensions: { height: number };
  chartMargins?: {
    top?: number;
    right?: number;
    left?: number;
    bottom?: number;
  };
  barList: any[];
  gridAlign?: { vertical: boolean; horizontal: boolean };
  legendProp: LegendProps;
  xAxisProps: xAxisProps;
  yAxisProps: yAxisProps;
  headerComponent?: any;
  stroke?: string;
  legendContent?: any;
  onBarClick?: any;
  payload?: [{ [dataKey: string]: {} }];
  stack?: string;
  tooltipContent: any;
  selectedYear?: string;
  active?: boolean;
  breakdownList?: [];
  colors?: string[];
  tooltipProps?: any;
  cellStroke?: { cellStrokeFill: string; cellStrokeWidth: number };
  maxBarSize?: number;
  hideChartLegend?: boolean;
};

const BarChartTooltip = (props: Props) => {
  const {
    payload,
    stack,
    tooltipContent,
    selectedYear,
    active,
    breakdownList,
  } = props;
  if (payload && active) {
    for (const bar of payload) {
      if (bar.dataKey === stack) {
        if (breakdownList) {
          return tooltipContent(bar, breakdownList);
        } else {
          return tooltipContent(bar, selectedYear);
        }
      }
    }
  }
  return null;
};
const BarGraphChart = (props: Props) => {
  const [stack, setStack] = useState(null);
  const {
    data,
    dataKey,
    gridStroke = '3 0',
    chartDimensions,
    chartMargins = {
      top: 10,
      right: 30,
      left: 10,
      bottom: 5,
    },
    barList,
    gridAlign = { vertical: false, horizontal: true },
    legendProp = {
      align: 'right',
      verticalAlign: 'top',
      width: 350,
      height: 50,
      margin: {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
      },
    },
    xAxisProps = {
      hide: false,
      tickLine: true,
      axisLine: false,
      tickGap: 0,
      tickMargin: 10,
      padding: { left: 0, right: 0 },
      dataKey: '',
    },
    yAxisProps = {
      hide: false,
      tickLine: false,
      axisLine: false,
      tickGap: 0,
      tickMargin: 10,
      padding: { top: 0, bottom: 0 },
      tickFormatter: '',
      dataKey: '',
    },
    headerComponent = '',
    colors,
    tooltipContent,
    tooltipProps,
    stroke = '#E9E9E9',
    legendContent,
    cellStroke,
    maxBarSize = 50,
    onBarClick,
    hideChartLegend = false,
  } = props;
  const { cellStrokeFill, cellStrokeWidth } = cellStroke || {};

  return (
    <div className={styles.barChart}>
      {/* `headerComponent` is a custom component or HTML heading. It's Optional. */}
      {headerComponent}
      {!_.isEmpty(data) && data.length > 0 ? (
        <ResponsiveContainer height={chartDimensions.height}>
          <BarChart data={data} barGap={1} margin={{ ...chartMargins }}>
            <CartesianGrid
              strokeDasharray={gridStroke}
              vertical={gridAlign.vertical}
              horizontal={gridAlign.horizontal}
              stroke={stroke}
            />
            <XAxis {...xAxisProps} dataKey={xAxisProps.dataKey || dataKey} />
            <YAxis {...yAxisProps} tickFormatter={yAxisProps.tickFormatter} />
            <Tooltip
              cursor={{ fill: 'transparent' }}
              content={
                <BarChartTooltip
                  stack={stack}
                  tooltipContent={tooltipContent}
                  {...tooltipProps}
                />
              }
            />
            {!hideChartLegend && (
              <Legend content={legendContent} {...legendProp} />
            )}
            {barList.map(
              ({ dataKey, stackId, fill, fillOpacity, name }, index) => {
                return (
                  <Bar
                    key={`${dataKey}_${index}`}
                    dataKey={dataKey}
                    stackId={stackId}
                    fill={fill}
                    fillOpacity={fillOpacity || 1}
                    onMouseOver={() => setStack(dataKey)}
                    maxBarSize={maxBarSize}
                    onClick={onBarClick}
                    name={name}
                  >
                    {data &&
                      data.map((entry, i) => {
                        const color = colors ? colors[i] : fill;
                        return (
                          <Cell
                            key={`cell_${i}`}
                            fill={color}
                            stroke={cellStrokeFill}
                            strokeWidth={cellStrokeWidth}
                          />
                        );
                      })}
                  </Bar>
                );
              }
            )}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div className={styles.placeHolder}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </div>
  );
};

export default BarGraphChart;
