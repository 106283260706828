import { FC, useEffect, useState, useCallback } from 'react';
import { StompSessionProvider } from 'react-stomp-hooks';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, PageHeader, Row } from 'antd';

import { useNavContext } from 'hooks/useNavContext';
import { usePermitIf } from 'hooks/usePermitIf';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { findPlanById } from 'modules/plans/slices/retirementPlanSlice';
import BasicPlanInfoOverview from 'modules/plans/components/OverviewPage/BasicPlanInfoOverview/BasicPlanInfoOverview';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import FourOOneKPlanOverviewDetails from 'modules/plans/retirement/pages/FourOOneKPlanOverviewDetails/FourOOneKPlanOverviewDetails';
import { allowedCommonButtonPermittedTypes } from 'constants/permittedConstants';

import { wsBaseApi } from 'util/apiUtil';
import PlanCollaborationNotification from 'components/PlanCollaborationNotification/PlanCollaborationNotification';

import styles from './fourOOneKPlanOverview.module.less';

const FourOOneKPlanOverview: FC = () => {
  const params = useParams();
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();

  const { retirementPlan } = useAppSelector(
    (state) => state.plan.retirementPlan
  );

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const navigate = useNavigate();

  const [viewPlanModal, setViewPlanModal] = useState(false);
  const [disableEditButton, setDisableEditButton] = useState(false);
  const [fetchPlanWhenUpdated, setFetchPlanWenUpdated] =
    useState<boolean>(false);

  const refreshPlan = useCallback(() => {
    if (employerId && brokerId && params.planId) {
      dispatch(findPlanById(params.planId));
    }
    setFetchPlanWenUpdated(false);
  }, [dispatch, employerId, brokerId, params.planId]);

  useEffect(() => {
    if (employerId && brokerId && params.planId && !retirementPlan) {
      dispatch(findPlanById(params.planId));
    }
    setFetchPlanWenUpdated(false);
  }, [dispatch, employerId, brokerId, params.planId, retirementPlan]);

  const navigation: { name: string; link: string }[] = [
    {
      name: 'All 401(k) Plans',
      link: `/brokers/${brokerId}/employers/${employerId}/401k`,
    },
  ];

  const securedBreadcrumb = usePermitIf(
    <BreadcrumbNavigation breadCrumbData={navigation} />,
    allowedCommonButtonPermittedTypes,
    []
  );

  const exitPlan = () => {
    navigate(`/brokers/${brokerId}/employers/${employerId}/401k`);
  };

  const updateViewPlanModal = (value: boolean) => {
    setViewPlanModal(value);
  };

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col span={24}>
          <Row>{securedBreadcrumb}</Row>
          <Col span={18}>
            <PageHeader
              className={styles.pageTitle}
              title={retirementPlan.name}
            />
          </Col>
        </Col>
      </Row>
      <StompSessionProvider
        url={`${wsBaseApi}/websocket-plan-update`}
        onWebSocketError={(e) => console.error(e)}
        reconnectDelay={500}
      >
        <PlanCollaborationNotification
          loggedInUserIndividualId={appBootInfo?.individualId}
          exitPlan={() => exitPlan()}
          refreshPlan={() => refreshPlan()}
          planId={retirementPlan.name}
          isInEdit={viewPlanModal}
          disableEdit={() => setDisableEditButton(true)}
          fetchPlan={() => setFetchPlanWenUpdated(true)}
        />
      </StompSessionProvider>
      <div className={styles.basicInfoWrapper}>
        <BasicPlanInfoOverview
          heading={'Plan Details'}
          planName={retirementPlan.name}
          planYearName={retirementPlan.planYear?.name}
          benefitClasses={retirementPlan.groups}
          carrierName={retirementPlan.benefitCarrier?.name}
          type={'FOUR_O_ONE_K'}
          viewPlanModal={viewPlanModal}
          setViewPlanModal={updateViewPlanModal}
          disableEditButton={disableEditButton}
          fetchPlanWhenUpdated={fetchPlanWhenUpdated}
          exitPlan={() => exitPlan()}
          groupId={retirementPlan.groupId}
        />
      </div>
      <FourOOneKPlanOverviewDetails
        plan={retirementPlan}
        heading="Plan Details"
      />
    </div>
  );
};

export default FourOOneKPlanOverview;
