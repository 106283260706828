import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { currencyFormatter } from 'util/commonUtil';

import styles from './viewCreditDescription.module.less';

type Props = {
  carrier: string;
  credit?: number;
  creditDescription?: string;
  isModalVisible: boolean;
  handleClose: (flag: boolean) => void;
};

const ViewCreditDescription = (props: Props) => {
  const { carrier, credit, creditDescription, isModalVisible, handleClose } =
    props;

  return (
    <ConfirmationDialog
      title="Credits"
      recordName={carrier}
      isRecordApplicable={true}
      onConfirm={() => handleClose(!isModalVisible)}
      visible={isModalVisible}
      isCloseOnly={false}
      confirmText={'Close'}
      centered={true}
      width={600}
      confirmBtnFullWidth={true}
      isCancelApplicable={false}
    >
      <div className={styles.creditDescription}>
        {credit && (
          <span className={styles.creditAmount}>
            {currencyFormatter(Math.round(credit), undefined, undefined, 0)}
            <br />
          </span>
        )}
        {creditDescription && <span>{creditDescription}</span>}
      </div>
    </ConfirmationDialog>
  );
};

export default ViewCreditDescription;
