import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Col, Row, Table, TablePaginationConfig } from 'antd';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { ReactComponent as DownloadIcon } from 'assets/images/icon-download-black.svg';
import { ReactComponent as BriefcaseIcon } from 'assets/images/briefcase.svg';
import { ReactComponent as UsersIcon } from 'assets/images/users.svg';
import { ER_ANALYTICS } from 'modules/issueslog/constants/IssuesLogConstants';
import { DataColumn } from 'components/DataTable/DataColumn';
import SearchBar from 'components/SearchBar/SearchBar';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import { getDuration } from 'modules/issueslog/utils/issueLogUtils';
import {
  exportIssuesLogAnalyticsAdmin,
  exportIssuesLogAnalyticsEmployer,
} from 'modules/issueslog/slices/issuesLogSlice';
import tableStyles from 'components/DataTable/datatable.module.less';
import PaginationNav from 'components/TableCustomPagination/TableCustomPagination';

import styles from './analyticsTable.module.less';

type Props = {
  type: String;
  filterData: any[];
  tab: string;
  tableData: any[];
  isLoading: boolean;
  setFilterValue: Function;
  selectedSupport?: string | null;
};

const AnalyticsTable = (props: Props) => {
  const { type, filterData, tab, tableData, isLoading, setFilterValue } = props;

  const params = useParams();
  const [searchText, setSearchText] = useState<string>('');
  const [filterSelectedValue, setFilterSelectedValue] = useState<string>('');

  const singleSelectRef = useRef<any>();

  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const [sorterValue, setSorterValue] = useState<any>({
    column: {
      dataIndex: 'openIssues',
      key: 'openIssues',
    },
    columnKey: 'openIssues',
    field: 'openIssues',
    order: 'descend',
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: currentIndex,
    itemRender: PaginationNav,
    showTotal: (total, range) => (
      <div className={styles.totalCount}>
        {range[0]}-{range[1]} of {total}
      </div>
    ),
  });

  const getData = (data: any[]) => {
    const dataArray = data?.filter(
      (label) =>
        label?.name
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase()?.trim() ?? '') ?? false
    );
    return dataArray;
  };

  const analyticsTableColumns: DataColumn[] = [
    {
      title: `${type === ER_ANALYTICS ? 'EMPLOYER' : 'NAME'}`,
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      sortOrder: sorterValue.columnKey === 'name' ? sorterValue.order : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (colData) => {
        return (
          <div className={styles.nameColumn}>
            <OverflowPopover>{colData}</OverflowPopover>
          </div>
        );
      },
    },
    {
      title: 'OPEN ITEMS',
      dataIndex: 'openIssues',
      key: 'openIssues',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      showSorterTooltip: false,
      sortOrder:
        sorterValue.columnKey === 'openIssues' ? sorterValue.order : null,
      sorter: (a: any, b: any) =>
        (a?.openIssues ?? 0) < (b?.openIssues ?? 0) ? -1 : 1,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (colData) => {
        return (
          <div className={styles.subColumn}>
            {colData === 0 ? <div>&ndash;</div> : colData}
          </div>
        );
      },
    },
    {
      title: 'TOTAL ITEMS',
      dataIndex: 'totalIssues',
      key: 'totalIssues',
      width: '20%',
      showSorterTooltip: false,
      sortOrder:
        sorterValue.columnKey === 'totalIssues' ? sorterValue.order : null,
      sorter: (a: any, b: any) =>
        (a?.totalIssues ?? 0) < (b?.totalIssues ?? 0) ? -1 : 1,
      sortDirections: ['ascend', 'descend', 'ascend'],
      ellipsis: {
        showTitle: false,
      },
      render: (colData) => {
        return (
          <div className={styles.subColumn}>
            {colData === 0 ? <div>&ndash;</div> : colData}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          AVG. FIRST <br />
          RESPONSE TIME
        </div>
      ),
      dataIndex: 'avgFirstResponseTime',
      key: 'avgFirstResponseTime',
      showSorterTooltip: false,
      sortOrder:
        sorterValue.columnKey === 'avgFirstResponseTime'
          ? sorterValue.order
          : null,
      sorter: (a: any, b: any) =>
        (a?.avgFirstResponseTime ?? 0) < (b?.avgFirstResponseTime ?? 0)
          ? -1
          : 1,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (colData) => {
        const duration = getDuration(colData);
        return (
          <div className={styles.subColumn}>
            {duration === null ? <div>&ndash;</div> : duration}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          AVG.
          <br />
          RESOLUTION TIME
        </div>
      ),
      dataIndex: 'avgResolutionTime',
      key: 'avgResolutionTime',
      sortOrder:
        sorterValue.columnKey === 'avgResolutionTime'
          ? sorterValue.order
          : null,
      showSorterTooltip: false,
      sorter: (a: any, b: any) =>
        (a?.avgResolutionTime ?? 0) < (b?.avgResolutionTime ?? 0) ? -1 : 1,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (colData) => {
        const duration = getDuration(colData);
        return (
          <div className={styles.subColumn}>
            {duration === null ? <div>&ndash;</div> : duration}
          </div>
        );
      },
    },
    {
      title: 'DATE OF LAST EMAIL',
      dataIndex: 'lastEmailTs',
      key: 'lastEmailTs',
      sortOrder:
        sorterValue.columnKey === 'lastEmailTs' ? sorterValue.order : null,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (isEmpty(a?.lastEmailTs ?? null)) {
          return -1;
        } else if (isEmpty(b?.lastEmailTs ?? null)) {
          return 1;
        } else {
          return a.lastEmailTs.localeCompare(b.lastEmailTs);
        }
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (colData) => {
        const date = moment(colData);
        return (
          <div className={styles.subColumn}>
            <OverflowPopover>
              {date.isValid() ? (
                date.format('MMMM DD, YYYY')
              ) : (
                <div>&ndash;</div>
              )}
            </OverflowPopover>
          </div>
        );
      },
    },
  ];

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value;
    setSearchText(_searchText);
  };

  useEffect(() => {
    setPagination({
      ...pagination,
      pageSize: 10,
      current: currentIndex,
    });
    // eslint-disable-next-line
  }, [currentIndex]);

  useEffect(() => {
    singleSelectRef.current.reset();
    setSearchText('');
    setSorterValue({
      column: {
        dataIndex: 'openIssues',
        key: 'openIssues',
      },
      columnKey: 'openIssues',
      field: 'openIssues',
      order: 'descend',
    });
  }, [params.type, tab]);

  useEffect(() => {
    setFilterValue(filterSelectedValue);
  }, [filterSelectedValue, setFilterValue]);

  return (
    <div className={styles.analyticsTableWrapper}>
      <Row justify={'space-between'} align={'bottom'}>
        <Col>
          <Row justify={'start'} align={'bottom'}>
            <Col>
              {type === ER_ANALYTICS ? (
                <BriefcaseIcon className={styles.titleIcon} />
              ) : (
                <UsersIcon className={styles.titleIcon} />
              )}
            </Col>
            <Col className={styles.tableTitle}>
              {type === ER_ANALYTICS ? 'Employer Analytics' : 'Admin Analytics'}
            </Col>
          </Row>
        </Col>
        <Col>
          <LinkButton
            onClick={() => {
              type === ER_ANALYTICS
                ? exportIssuesLogAnalyticsEmployer(
                    params.brokerId,
                    params.type?.toUpperCase(),
                    filterSelectedValue ? filterSelectedValue : '',
                    props.selectedSupport ? props.selectedSupport : '',
                    searchText,
                    sorterValue.field,
                    sorterValue.order == 'ascend' ? 'ASC' : 'DESC'
                  )
                : exportIssuesLogAnalyticsAdmin(
                    params.brokerId,
                    params.type?.toUpperCase(),
                    filterSelectedValue ? filterSelectedValue : '',
                    props.selectedSupport ? props.selectedSupport : '',
                    searchText,
                    sorterValue.field,
                    sorterValue.order == 'ascend' ? 'ASC' : 'DESC'
                  );
            }}
          >
            Export Excel file
            <DownloadIcon className={styles.downloadIcon} />
          </LinkButton>
        </Col>
      </Row>
      <Row className={styles.searchBarWrapper}>
        <Col>
          <SearchBar
            placeholder={`Search ${
              type === ER_ANALYTICS ? 'Employers' : 'Admins'
            }`}
            value={searchText}
            onChange={handleSearch}
          />
        </Col>
        <Col className={styles.singleSelectFilter}>
          <SingleSelectFilter
            ref={singleSelectRef}
            showSearch={true}
            searchPlaceholder={`Search ${
              type === ER_ANALYTICS ? 'Admins' : 'Employers'
            }`}
            placeholder={`All ${
              type === ER_ANALYTICS ? 'Admins' : 'Employers'
            }`}
            data={filterData}
            setFilterSelectedValue={setFilterSelectedValue}
            selectedValue={filterSelectedValue}
            defaultValue={null}
            addAllValue={true}
            allLabel={`All ${type === ER_ANALYTICS ? 'Admins' : 'Employers'}`}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} className={styles.documentTable}>
          <Table
            className={`${tableStyles.tableWrapper} ${styles.tableWrapper}`}
            dataSource={getData(tableData)}
            columns={analyticsTableColumns}
            pagination={pagination}
            loading={isLoading}
            tableLayout="fixed"
            onChange={(pagination, filter, sorter: any) => {
              const { current } = pagination;
              setCurrentIndex(current ?? 0);
              if (
                (sorterValue?.columnKey ?? '') !== (sorter?.columnKey ?? '') ||
                (sorterValue?.order ?? '') !== (sorter?.order ?? '')
              ) {
                setCurrentIndex(1);
                setSorterValue(sorter);
              }
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AnalyticsTable;
