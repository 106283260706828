import React from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { Empty } from 'antd';
import isEmpty from 'lodash/isEmpty';

import styles from './areaChart.module.less';

type Props = {
  data: [];
  dataKey: string;
  gridStroke: string;
  chartDimensions: any;
  chartMargins: any;
  areaList: {
    type: string;
    dataKey: string;
    stroke: string;
    fill: string;
    fillOpacity: number;
    dot: { fill: string };
    strokeWidth: number;
    legendType: string;
  }[];
  gridAlign: any;
  legendProp?: any;
  xAxisProps: any;
  yAxisProps: any;
  label?: any;
  tooltipContent: any;
  headerComponent?: any;
  tickFormatter?: Function;
  lineList: any[];
};

const generateChartArea = (areaList: any[]) => {
  return areaList.map((area, index) => (
    <Area key={`${area.type}_${index}`} {...area} />
  ));
};

const generateChartLine = (lineList: any[]) => {
  return lineList.map((line, index) => {
    const randomKeyValue = parseInt(String(Math.random() * 100));
    return <Line key={`${line.type}_${randomKeyValue}-line`} {...line} />;
  });
};

const AreaGraphChart = (props: Props) => {
  const {
    data = [],
    dataKey,
    gridStroke = '3 0',
    chartDimensions,
    chartMargins = {
      top: -18,
      right: 30,
      left: 10,
      bottom: 5,
    },
    areaList = [],
    gridAlign = { vertical: false, horizontal: true },
    legendProp = {
      align: 'right',
      verticalAlign: 'top',
      height: 50,
      margin: {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
      },
      iconType: 'square',
    },
    xAxisProps = {
      hide: false,
      tickLine: true,
      axisLine: false,
      minTickGap: 0,
      tickMargin: 10,
      padding: { left: 0, right: 0 },
      label: '',
      tickFormatter: '',
      unit: '',
    },
    yAxisProps = {
      hide: false,
      tickLine: false,
      axisLine: false,
      minTickGap: 0,
      tickMargin: 10,
      padding: { top: 0, bottom: 0 },
      label: '',
      tickFormatter: '',
      unit: '',
    },
    tooltipContent,
    headerComponent,
    lineList = [],
  } = props;
  return (
    <div className={styles.areaChart}>
      {headerComponent}
      {!isEmpty(data) && data.length > 0 ? (
        <ResponsiveContainer height={chartDimensions.height}>
          <ComposedChart data={data} margin={{ ...chartMargins }}>
            <CartesianGrid
              strokeDasharray={gridStroke}
              vertical={gridAlign.vertical}
              horizontal={gridAlign.horizontal}
            />
            <XAxis
              dataKey={xAxisProps.dataKey || dataKey}
              {...xAxisProps}
              interval={0}
            />
            <YAxis {...yAxisProps} />
            <Tooltip content={tooltipContent ? tooltipContent : null} />
            <Legend {...legendProp} />
            {generateChartArea(areaList)}
            {generateChartLine(lineList)}
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <div className="place-holder">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </div>
  );
};

export default AreaGraphChart;
