import billingIcon from 'assets/images/navigation/Billing.svg';
import renewalsIcon from 'assets/images/navigation/Renewals.svg';
import claimsIcon from 'assets/images/navigation/Claims.svg';
import planRatesIcon from 'assets/images/navigation/PlanRates.svg';
import issueLogIcon from 'assets/images/navigation/IssuesLog.svg';
import idCardIcon from 'assets/images/navigation/IdCard.svg';
import ssoIcon from 'assets/images/navigation/Sso.svg';
import rfpIcon from 'assets/images/navigation/RFP.svg';
import benefitGuideIcon from 'assets/images/navigation/DigitalBenefitsGuide.svg';
import benefitConsultationIcon from 'assets/images/navigation/Consultation.svg';
import selfReviewIcon from 'assets/images/navigation/SelfReview.svg';
import advancedProfileIcon from 'assets/images/navigation/AdvancedProfileIcon.svg';

export type configurationValueType =
  typeof locationBasedConfigurations[keyof typeof locationBasedConfigurations];

export type configurationsType = keyof typeof locationBasedConfigurations;

export const locationBasedConfigurations = {
  CLAIMS: 'Claims',
  BILLING: 'Billing',
  PLAN_RATES: 'Plan Rates',
  ISSUES_LOG: 'Issues Log',
  ID_CARDS: 'ID Cards',
  RENEWAL: 'Renewals',
  SSO: 'SSO',
  RFP: 'RFP',
  BENEFIT_GUIDE: 'Benefit Guide',
  BENEFIT_CONSULTATION: 'Benefit Consultation',
  SELF_REVIEW: 'Self-Review',
  ADVANCED_PROFILE: 'Advanced Profile',
} as const;

export const getConfigurationTypeIcon = (
  type: configurationValueType | string
): { icon?: string; name?: string } => {
  switch (type) {
    case locationBasedConfigurations.CLAIMS:
      return {
        icon: claimsIcon,
        name: 'Claims',
      };
    case locationBasedConfigurations.BILLING:
      return {
        icon: billingIcon,
        name: 'Billing',
      };
    case locationBasedConfigurations.PLAN_RATES:
      return {
        icon: planRatesIcon,
        name: 'Plan Rates',
      };
    case locationBasedConfigurations.ISSUES_LOG:
      return {
        icon: issueLogIcon,
        name: 'Issues Log',
      };
    case locationBasedConfigurations.ID_CARDS:
      return {
        icon: idCardIcon,
        name: 'ID Cards',
      };
    case locationBasedConfigurations.RENEWAL:
      return {
        icon: renewalsIcon,
        name: 'Renewals',
      };
    case locationBasedConfigurations.SSO:
      return {
        icon: ssoIcon,
        name: 'SSO',
      };
    case locationBasedConfigurations.RFP:
      return {
        icon: rfpIcon,
        name: 'RFP',
      };
    case locationBasedConfigurations.BENEFIT_GUIDE:
      return {
        icon: benefitGuideIcon,
        name: 'Benefit Guide',
      };
    case locationBasedConfigurations.BENEFIT_CONSULTATION:
      return {
        icon: benefitConsultationIcon,
        name: 'Benefit Consultation',
      };
    case locationBasedConfigurations.SELF_REVIEW:
      return {
        icon: selfReviewIcon,
        name: 'Self-Review',
      };
    case locationBasedConfigurations.ADVANCED_PROFILE:
      return {
        icon: advancedProfileIcon,
        name: 'Advanced Profile',
      };
    default:
      return {};
  }
};
