import { createSlice, ThunkAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

import { resetUserInAnalytics } from 'api/initGA4';
import { handleAPIError } from 'util/apiUtil';
import { RootState } from 'app/store';

import { clearBroker, clearEmployer } from 'layout/slices/layoutSlice';
import AppBootupInfo from 'model/AppBootupInfo';
import * as AuthService from 'modules/auth/services/AuthService';
import * as IndividualService from 'modules/employers/services/IndividualService';

type BasicThunkAction = ThunkAction<Promise<void>, RootState, unknown, any>;

interface AuthState {
  inProgress: boolean;
  organizationId: string;
  pingInprogress: boolean;
  appBootupInfo: AppBootupInfo | null;
  error: any;
  pingError: any;
  sessionExpired: boolean;
}

const initialState = {
  inProgress: false,
  pingInprogress: false,
  appBootupInfo: null,
  sessionExpired: false,
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStarted: (state) => {
      state.inProgress = true;
    },
    loginSucceeded: (state) => {
      state.inProgress = false;
    },
    loginFailed: (state, action) => {
      state.inProgress = false;
      state.error = action.payload;
    },
    resetLoginState: (state) => {
      state.inProgress = false;
      state.appBootupInfo = null;
      state.error = null;
      state.pingError = null;
    },
    logoutCompleted: (state) => {
      state.appBootupInfo = null;
      state.inProgress = false;
      state.error = null;
    },
    pingStarted: (state) => {
      state.appBootupInfo = null;
      state.pingInprogress = true;
      state.error = null;
    },
    pingCompleted: (state, action) => {
      state.appBootupInfo = action.payload;
      state.pingInprogress = false;
      state.error = null;
    },
    pingFailed: (state, action) => {
      state.appBootupInfo = null;
      state.pingInprogress = false;
      state.pingError = action.payload;
    },
    sessionExpired: (state, action) => {
      state.sessionExpired = action.payload;
    },
  },
});

export const {
  loginStarted,
  loginSucceeded,
  loginFailed,
  resetLoginState,
  logoutCompleted,
  pingCompleted,
  pingStarted,
  pingFailed,
  sessionExpired,
} = authSlice.actions;
export default authSlice.reducer;

export const login =
  (username: string, password: string, callbackFunc: Function) =>
  async (dispatch: any) => {
    dispatch(loginStarted());
    try {
      const response = await AuthService.getOAuthToken(
        username.trim(),
        password
      );
      AuthService.storeAuthDetails(response.data.expires_in, username.trim());
      dispatch(loginSucceeded());
      dispatch(ping());
      callbackFunc();
    } catch (error) {
      dispatch(
        loginFailed(JSON.parse(JSON.stringify(handleAPIError(error).data)))
      );
    }
  };

export const logout = (callbackFunc: VoidFunction) => {
  return async (dispatch: any) => {
    await AuthService.signOut();
    callbackFunc();
    dispatch(logoutCompleted());
    dispatch(clearBroker());
    dispatch(clearEmployer());
    resetUserInAnalytics();
  };
};

export const ping = () => {
  return async (dispatch: Dispatch) => {
    dispatch(pingStarted());
    await AuthService.whoami()
      .then((response) => {
        const { data } = response;
        dispatch(pingCompleted({ ...data }));
      })
      .catch((error) => {
        dispatch(
          pingFailed(
            error.response && error.response.data ? error.response.data : {}
          )
        );
      });
  };
};

/**
 * Create a redux thunk action that is used to update the signed in user
 * without setting the in progress state.
 * @return {BasicThunkAction} The thunk action to update the signed in user.
 */
export const updateUserSilently = (): BasicThunkAction => {
  return async (dispatch) => {
    await AuthService.whoami()
      .then((response) => {
        const { data } = response;
        dispatch(pingCompleted({ ...data }));
      })
      .catch((error) => console.error);
  };
};

export const updateTermsForIndividual = (individualId: string) => {
  return async (dispatch: Dispatch) => {
    await IndividualService.updateTermsForIndividual(individualId).then(() => {
      AuthService.whoami()
        .then((response) => {
          const { data } = response;
          dispatch(pingCompleted({ ...data }));
        })
        .catch((error) => console.error);
    });
  };
};
