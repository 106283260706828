import { FC, ReactNode } from 'react';

import { Row, Steps } from 'antd';

import { ReactComponent as CurrentStepIcon } from 'assets/images/current-step.svg';

import rfpIcon from 'assets/images/Rfp-ai-not-selected.svg';
import rfpIconSelected from 'assets/images/Rfp-ai-selected.svg';
import styles from './stepper.module.less';

const { Step } = Steps;

type props = {
  headers: string[];
  activeStep: number;
  renderStepContent: () => ReactNode;
  changeCurrentStep: (arg1: number) => any;
  isEditMode?: boolean;
  isLastStepActive?: boolean;
  isDisabledStep?: (index: number) => boolean;
  stepperRightMargin?: boolean;
};

const customDot = (
  dot: any,
  { status, index }: { status: string; index: number }
): any => (status === 'process' ? processDotIcon() : otherDotIcon(status));

const processDotIcon = () => <CurrentStepIcon />;

const otherDotIcon = (status: string) => (
  <div className={styles.round}>
    <span
      className={
        status === 'wait'
          ? `${styles.waitIcon} ${styles.otherBullet}`
          : styles.otherBullet
      }
    >
      &bull;
    </span>
  </div>
);

const RfpStepper: FC<props> = ({
  headers,
  renderStepContent,
  activeStep,
  changeCurrentStep,
  isEditMode = false,
  isLastStepActive = false,
  isDisabledStep: _isDisabledStep,
  stepperRightMargin = true,
}) => {
  const isDisabledStep = (index: number) => {
    if (isEditMode) {
      return activeStep === headers.length - 1 ? false : index < activeStep;
    } else {
      if (index === 0) {
        return activeStep > 0;
      } else if (index === 1) {
        return activeStep === 0;
      } else {
        return activeStep < 2;
      }
    }
  };
  return (
    <div>
      <div
        className={`${styles.progressIndicator} ${
          stepperRightMargin ? styles.finalStepperWrapper : ''
        }`}
      >
        <div className={styles.stepperIconWrapper}>
          {activeStep === 2 ? (
            <img src={rfpIconSelected} className={styles.stepperIcon} />
          ) : (
            <img src={rfpIcon} className={styles.stepperIcon} />
          )}

          <Steps
            current={activeStep}
            onChange={changeCurrentStep}
            progressDot={customDot}
          >
            {headers.map((header, index) => (
              <Step
                title={header}
                key={index}
                disabled={
                  _isDisabledStep
                    ? _isDisabledStep(index)
                    : isDisabledStep(index)
                }
              />
            ))}
          </Steps>
        </div>

        <Row>{renderStepContent()}</Row>
      </div>
    </div>
  );
};

export default RfpStepper;
