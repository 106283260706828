import { FC, useEffect, useRef, useState } from 'react';
import { Form, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import CloseCircleOutlined from '@ant-design/icons/lib/icons/CloseCircleOutlined';

import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';

import ViewBasicInfo from 'modules/benefitsConsultation/components/ViewBasicInfo/ViewBasicInfo';
import ViewAvailability from 'modules/benefitsConsultation/components/ViewAvailability/ViewAvailability';
import EditBasicInfo from 'modules/benefitsConsultation/components/EditBasicInfo/EditBasicInfo';
import EditAvailability from 'modules/benefitsConsultation/components/EditAvailability/EditAvailability';

import BenefitsConsultant from 'model/benefitsConsultation/BenefitsConsultant';
import WeekTimeConfig from 'model/benefitsConsultation/WeekTimeConfig';
import ConsultantStatus from 'model/benefitsConsultation/ConsultantStatus';

import {
  editConsultantDetails,
  editConsultantDetailsSuccess,
} from 'modules/benefitsConsultation/slices/commonSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  DaysOfTheWeek,
  TIME_OPTIONS,
} from 'modules/benefitsConsultation/constants/benefitsConsultation';
import {
  EMPLOYER_MEMBER_RESTRICTED,
  formVerificationMsg,
} from 'constants/commonConstants';
import { mapValueToLabel } from 'util/commonUtil';
import { resetStartEndTimeForUnavailable } from 'modules/benefitsConsultation/utils/commonConsultLvlsUtils';
import { usePermitByUserType } from 'hooks/usePermitByUserType';

import styles from './employerConsultantDetails.module.less';

type BrokerConsultantDetailsProps = {
  visible: boolean;
  setVisible: Function;
  consultant: BenefitsConsultant;
  setAlertVisibleParent: Function;
  setAlertMessageParent: Function;
  showEditMode: boolean;
  updatedStatus?: ConsultantStatus;
  updateTableData: Function;
};

const EmployerConsultantDetails: FC<BrokerConsultantDetailsProps> = (
  props: BrokerConsultantDetailsProps
) => {
  const [form] = Form.useForm();
  const editBasicInfoRef = useRef<any>(null);

  const {
    visible,
    setVisible,
    consultant,
    setAlertVisibleParent,
    setAlertMessageParent,
    showEditMode,
    updatedStatus,
    updateTableData,
  } = props;
  const dispatch = useAppDispatch();

  const requestType = useAppSelector(
    (state) => state.benefitsConsultation.common.requestType
  );
  const inProgress = useAppSelector(
    (state) => state.benefitsConsultation.common.inProgress
  );

  const [editMode, setEditMode] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>();
  const [showAvailabilityInvalid, setShowAvailabilityInvalid] =
    useState<boolean>(false);
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });

  const editAvailabilityRef = useRef<any>(null);

  useEffect(() => {
    setEditMode(showEditMode);
  }, [showEditMode]);

  useEffect(() => {
    if (requestType === editConsultantDetailsSuccess.type) {
      setVisible(false);
      setEditMode(false);
    }
  }, [requestType, setVisible]);

  useEffect(() => {
    const newFormData = pick(consultant, [
      'email',
      'fullName',
      'timezone',
      'status',
      'conferenceDetails',
    ]);
    setFormData(newFormData);
    form.setFieldsValue(newFormData);
  }, [consultant, form]);

  useEffect(() => {
    if (editMode) {
      editBasicInfoRef?.current?.initialFocus();
    }
  }, [editMode]);

  const validateFields = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errorInfo: any) {
      return isEmpty(errorInfo.errorFields);
    }
  };

  const onSubmit = async () => {
    if (editMode) {
      const isValid = await validateFields();
      const validations = await editAvailabilityRef.current.validateData();

      resetStartEndTimeForUnavailable(validations.dayConfig);

      if (!isValid || validations.isAvailabilityEmpty) {
        setAlertVisible(true);
        setAlertMessage({
          type: 'error',
          message: formVerificationMsg,
        });
      } else if (validations.isDurationInvalid) {
        setAlertVisible(true);
        setAlertMessage({
          type: 'error',
          message: 'The end time should be greater than the start time.',
        });
      } else {
        const currentConsultant = {
          id: consultant.id,
          organizationId: consultant.organizationId,
          individualId: consultant.individualId,
          avatarUrl: consultant.avatarUrl,
          type: consultant.type,
          status: consultant.status,
          availability: validations.dayConfig,
          employerId: consultant.employerId,
          mappedEmployers: consultant.mappedEmployers,
        };

        const updatedConsultant = {
          ...currentConsultant,
          ...form.getFieldsValue(),
        };

        dispatch(editConsultantDetails(consultant.id, updatedConsultant));
      }
    } else {
      setEditMode(true);
    }
  };

  const availabilityDetails =
    consultant.availability &&
    Object.entries(consultant.availability).map((availability) => {
      const startTimeLabel =
        TIME_OPTIONS.find(
          (time) => time.value === availability[1].startTime.toString()
        )?.label || '';
      const endTimeLabel =
        TIME_OPTIONS.find(
          (time) => time.value === availability[1].endTime.toString()
        )?.label || '';

      return {
        day: `${mapValueToLabel(availability[0], DaysOfTheWeek)}`,
        isOpen: availability[1].isOpen,
        duration: `${startTimeLabel} - ${endTimeLabel}`,
      };
    });

  let defaultAvailabilityObj = {} as WeekTimeConfig;
  Object.values(DaysOfTheWeek).forEach((day) => {
    defaultAvailabilityObj = {
      ...defaultAvailabilityObj,
      ...{
        [day.value]: {
          startTime: 900,
          endTime: 1700,
          is24Hours: false,
          isOpen: null,
        },
      },
    };
  });

  const securedSubmitButton = (
    <SubmitButton
      onClick={onSubmit}
      type="primary"
      disabled={inProgress}
      loading={inProgress}
    >
      {editMode ? 'Save Consultant' : 'Edit Consultant Details'}
    </SubmitButton>
  );

  return (
    <FullScreenModal
      title={`${editMode ? 'Edit' : ''} Consultant Details`}
      visible={visible}
      className={styles.detailsWrapper}
      onCancel={() => {
        setEditMode(false);
        setVisible(false);
        form.resetFields();
      }}
      footer={false}
      closeIcon={<CloseCircleOutlined />}
      afterClose={() => {
        if (requestType === editConsultantDetailsSuccess.type) {
          updateTableData();
          setAlertVisibleParent(true);
          setAlertMessageParent({
            type: 'success',
            message: 'Employer Consultant has been successfully updated.',
          });
        }
      }}
    >
      <div className={styles.alertWrapper}>
        {alertVisible && (
          <AlertMessage
            type={alertMessage.type}
            message={alertMessage.message}
            closeAlert={() => setAlertVisible(false)}
          />
        )}
      </div>

      {!editMode && (
        <div className={styles.overallContent}>
          <div className={styles.sectionHeading}>Basic Information</div>
          <ViewBasicInfo consultant={consultant} />

          <div className={styles.sectionHeading}>Availability</div>
          <ViewAvailability availabilityList={availabilityDetails} />
        </div>
      )}

      {editMode && (
        <div className={styles.overallEditContent}>
          <div tabIndex={1} className={styles.sectionHeadingEdit}>
            Basic Information
          </div>
          <EditBasicInfo
            ref={editBasicInfoRef}
            form={form}
            formData={formData}
            consultant={consultant}
            setAlertMessage={setAlertMessage}
            setAlertVisible={setAlertVisible}
            updatedStatus={updatedStatus}
          />

          <div
            className={`${styles.sectionHeadingEdit} ${
              showAvailabilityInvalid ? styles.invalid : ''
            }`}
          >
            Availability *
          </div>
          <EditAvailability
            availabilityObj={
              consultant.availability
                ? consultant.availability
                : defaultAvailabilityObj
            }
            ref={editAvailabilityRef}
            setShowAvailabilityInvalid={setShowAvailabilityInvalid}
          />
        </div>
      )}

      <Row justify="center">
        {usePermitByUserType(securedSubmitButton, EMPLOYER_MEMBER_RESTRICTED)}
      </Row>
      <Row justify="center">
        <CancelButton disabled={inProgress}>
          <span
            onClick={() => {
              setVisible(false);
              setEditMode(false);
              form.resetFields();
            }}
            className={styles.cancelButton}
          >
            Cancel
          </span>
        </CancelButton>
      </Row>
    </FullScreenModal>
  );
};

export default EmployerConsultantDetails;
