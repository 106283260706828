import { FC, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Checkbox, Input, Dropdown, Menu, Popover } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import styles from './benefitClassMultiSelect.module.less';

type BenefitClassMultiSelectProps = {
  options: Array<string>;
  increased?: boolean;
  onChange: Function;
  name?: string;
  selectedItemValues: string[];
  disabled?: boolean;
  showAll?: boolean;
  showTooltip?: boolean;
  disableInput?: boolean;
  ref?: React.Ref<any>;
};

const BenefitClassMultiSelect: FC<BenefitClassMultiSelectProps> = (
  props: BenefitClassMultiSelectProps
) => {
  const {
    options,
    increased = false,
    onChange,
    name,
    selectedItemValues,
    disabled = false,
    showAll = false,
    showTooltip = false,
    disableInput,
    ref,
  } = props;
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

  const handleVisibleChange = (flag: boolean): void => {
    setIsDropdownVisible(flag);
  };

  return (
    <div className={styles.multiSelectPlanWrapper}>
      <Dropdown
        getPopupContainer={(triggerNode) => triggerNode}
        disabled={disabled}
        trigger={['click']}
        onVisibleChange={handleVisibleChange}
        visible={isDropdownVisible}
        overlayClassName={
          increased ? styles.horizontalDropdown : styles.normalDropdown
        }
        overlayStyle={increased ? { minWidth: '0px' } : {}}
        overlay={
          <Menu>
            {options.map((value, index) => (
              <Menu.Item key={index}>
                <Checkbox
                  onChange={(e) => onChange(e)}
                  value={value}
                  name={name}
                  checked={selectedItemValues.includes(value)}
                >
                  {value}
                </Checkbox>
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Popover
          content={selectedItemValues.join(', ')}
          placement="top"
          overlayClassName={
            selectedItemValues && !isEmpty(selectedItemValues)
              ? styles.basicPlanPopoverInner
              : styles.noBasicPlanPopoverInner
          }
        >
          <Input
            className={styles.dropdownInput}
            suffix={<DownOutlined />}
            value={showAll ? 'All' : selectedItemValues}
            title={showTooltip ? selectedItemValues.join(', ') : undefined}
            ref={ref}
            disabled={disableInput}
          />
        </Popover>
      </Dropdown>
    </div>
  );
};

export default BenefitClassMultiSelect;
