import { Select, SelectProps } from 'antd';
import { usStates } from 'constants/commonConstants';

import styles from './stateSelect.module.less';
interface StateSelectProps extends SelectProps {
  dataCy?: string;
}

const StateSelect = (props: StateSelectProps) => {
  return (
    <Select
      size="large"
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      data-cy={props.dataCy}
      {...props}
      className={styles.stateSelect}
    >
      {usStates.map((state, index) => (
        <Select.Option key={index} value={state.value} title="">
          {state.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default StateSelect;
