import React, { ReactElement, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Form, Popover, Row } from 'antd';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { ReactComponent as RowRemoveIcon } from 'assets/images/row-remove-icon.svg';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import { useLazyGetPlanYearsQuery } from 'modules/renewals/slices/renewalsSlice';
import { PLAN_YEARS_TAB } from 'modules/employers/constants/employerConstants';
import styles from './dynamicForm.module.less';

interface IDynamicFormProps {
  formItems: any[];
  submitBtnLabel?: string;
  addBtnContent?: string;
  removeSetIcon?: any;
  addFormItems: () => void;
  form: any;
  isAddBtnDisabled: boolean;
  addItemTxt?: string;
  mainButtonStyling?: string;
  handleCancelClick: () => void;
  description?: string;
  subHeader?: string;
  handleSubmitClick: (val: any) => void;
  isSubmitBtnLoading?: boolean;
  addItemIcon?: any;
  popOverTxt?: string | ReactElement;
  handleDelete: (val: any) => void;
  nextBtnDisabled?: boolean;
}

interface PlanYear {
  id: string;
  employerId: string;
  name: string;
  startDate: string;
  endDate: string;
  benefitGroups: string[];
  benefitClasses: any;
  planYearSource: string | null;
  next: boolean;
  previous: boolean;
  current: boolean;
  cloned: boolean;
}

const DynamicForm: React.FC<IDynamicFormProps> = ({
  formItems,
  addFormItems,
  form,
  isAddBtnDisabled,
  addItemTxt,
  mainButtonStyling,
  handleCancelClick,
  description,
  subHeader,
  handleSubmitClick,
  isSubmitBtnLoading = false,
  addItemIcon,
  popOverTxt,
  handleDelete,
  nextBtnDisabled,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const employerId = params?.employerId;
  const onHandleSubmit = (values: any) => {
    handleSubmitClick(values);
  };

  const isInitialRow = (index: number): boolean => {
    return 0 !== index;
  };

  const navigateToCurrentPlan = () => {
    return navigate(
      `/brokers/${params?.brokerId}/employers/${employerId}/medical`
    );
  };

  const navigateToPlanYearList = () => {
    return navigate(
      `/brokers/${params?.brokerId}/employers/${employerId}/info`,
      { state: { tab: PLAN_YEARS_TAB } }
    );
  };

  const [fetchPlanYears, { data: planYears }] = useLazyGetPlanYearsQuery();
  const [latestPlanYear, setLatestPlanYear] = useState<PlanYear | null>();

  useEffect(() => {
    if (employerId) {
      fetchPlanYears({ employerId });
    }
  }, [employerId, fetchPlanYears]);

  useEffect(() => {
    if (planYears) {
      setLatestPlanYear(findLatestPlanYear(planYears));
    }
  }, [planYears]);

  const findLatestPlanYear = (plans: PlanYear[]): PlanYear | null => {
    if (plans.length === 0) return null;

    return plans.reduce((latest, current) => {
      return new Date(latest.startDate) > new Date(current.startDate)
        ? latest
        : current;
    });
  };

  const WARNING_MESSAGE = (
    <>
      Premiums are not given for certain plans in the{' '}
      <span
        className={styles.warningNavigation}
        onClick={navigateToCurrentPlan}
      >
        current plan year
      </span>
      .
    </>
  );

  const PLAN_YEAR_NOT_LATEST_WARNING_TEXT = (
    <p>
      You have an ongoing plan year{' '}
      <span className={styles.planYear}>{latestPlanYear?.name}</span> which is
      not set as the current plan year. To proceed with starting a new renewal,
      please update the current plan year to{' '}
      <span className={styles.planYear}>{latestPlanYear?.name}</span> by{' '}
      <span className={styles.planYearWarning} onClick={navigateToPlanYearList}>
        clicking here
      </span>
      .
    </p>
  );

  return (
    <Form form={form} layout={'vertical'} className={styles.dynamicForm}>
      {description && (
        <Row justify={'center'} className={styles.description}>
          <div className={styles.descriptionText}>{description}</div>
        </Row>
      )}
      {latestPlanYear && !latestPlanYear?.current && (
        <Row>
          <div className={styles.planYearWarningMsgStyling}>
            <FixedAlertMessage
              className={styles.textMessage}
              message={PLAN_YEAR_NOT_LATEST_WARNING_TEXT}
              type="error"
            />
          </div>
        </Row>
      )}
      <Row justify="center">
        <Row className={styles.mainSection} justify="center">
          {formItems.map((formItem: any, index: number) => {
            return (
              <Row key={formItem.key}>
                {isInitialRow(index) && (
                  <Col lg={2} xl={2} className={styles.deleteRow}>
                    <span
                      className={styles.removeIcon}
                      onClick={() => {
                        handleDelete(formItem.key);
                      }}
                    >
                      <RowRemoveIcon />
                    </span>
                  </Col>
                )}
                <Col
                  lg={isInitialRow(index) ? 24 : 22}
                  xl={isInitialRow(index) ? 24 : 22}
                  className={isInitialRow(index) ? styles.dateCol : ''}
                >
                  {formItem}
                </Col>
              </Row>
            );
          })}
        </Row>
      </Row>

      <Row justify="center" className={styles.addItemRow}>
        {addItemTxt && !isAddBtnDisabled && (
          <>
            <span onClick={addFormItems} className={styles.addItemText}>
              {addItemTxt}
            </span>
            {addItemIcon && (
              <Popover
                content={popOverTxt}
                placement="right"
                overlayClassName="popoverInner"
                className={styles.popoverIcon}
              >
                {addItemIcon}
              </Popover>
            )}
          </>
        )}

        {addItemTxt && isAddBtnDisabled && (
          <span className={styles.addItemTextDisabled}>{addItemTxt}</span>
        )}
      </Row>
      {nextBtnDisabled && (
        <Row>
          <div className={styles.warningMsgStyling}>
            <FixedAlertMessage type="warning" message={WARNING_MESSAGE} />
          </div>
        </Row>
      )}

      <Form.Item>
        <PageActionButton
          type="primary"
          className={mainButtonStyling}
          loading={isSubmitBtnLoading}
          onClick={onHandleSubmit}
          disabled={nextBtnDisabled || !latestPlanYear?.current}
        >
          Next
        </PageActionButton>
      </Form.Item>

      <Row justify="center">
        <span className={styles.addItemText} onClick={handleCancelClick}>
          Cancel
        </span>
      </Row>
    </Form>
  );
};

export default DynamicForm;
