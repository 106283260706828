// type props = {}

import { FC } from 'react';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import EditCarrierContacts from 'modules/employers/components/EditCarrierContacts/EditCarrierContacts';
import styles from './EditCarrierContactsModal.module.less';
type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  employerName: string;
  isCarrierContactsVisible?: boolean;
  refetchCarrierContacts?: () => void;
  selectedCarrierContacts?: any[];
};

const EditCarrierContactsModal: FC<Props> = (props: Props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    employerName,
    isCarrierContactsVisible,
    refetchCarrierContacts,
    selectedCarrierContacts,
  } = props;

  const handleCloseModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <FullScreenModal
        visible={isModalOpen}
        onCancel={handleCloseModal}
        footer={false}
        title={
          <div className={styles.titleWrapper}>
            <div>Edit Carrier Team</div>
            <div className={styles.textSecondary}>{employerName}</div>
          </div>
        }
        destroyOnClose
      >
        <EditCarrierContacts
          closeModal={closeModal}
          isCheckedByDefault={isCarrierContactsVisible}
          refetchCarrierContacts={refetchCarrierContacts}
          selectedSortedCarrierContacts={selectedCarrierContacts}
        ></EditCarrierContacts>
      </FullScreenModal>
    </>
  );
};

export default EditCarrierContactsModal;
