import { FC, useEffect } from 'react';

import { Col, notification, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';
import OverviewHeader from 'modules/plans/components/OverviewPage/OverviewHeader/OverviewHeader';
import { BenefitCategory } from 'constants/commonConstants';
import { PLAN_NOTIFICATION_KEY } from 'constants/benguideCollaborationConstants';
import { DentalPlan } from 'model/plans/DentalPlan';
import MedicalPlan from 'model/plans/MedicalPlan';
import PlanInfoHeaderLabel from 'modules/employers/components/PlanInfoHeaderLabel/PlanInfoHeaderLabel';
import EditMedicalPlanModal from 'modules/plans/medical/components/EditMedicalPlanModal/EditMedicalPlanModal';
import EditDentalPlanModal from 'modules/plans/dental/components/EditDentalPlanModal/EditDentalPlanModal';
import { EditMdvTypes } from 'modules/plans/constants';
import { findMedicalPlanById } from 'modules/plans/slices/medicalPlanSlice';
import { findDentalPlanById } from 'modules/plans/slices/dentalPlanSlice';
import { isNullOrUndefined } from 'modules/plans/utils';

import styles from './deductiblesOverview.module.less';

type DeductiblesOverviewProps = {
  heading: string;
  plan?: DentalPlan | MedicalPlan;
  benefitKind: string;
  fetchPlanWhenUpdated?: any;
  disableEditButton?: boolean;
  viewPlanDeductibleModal?: boolean;
  setViewPlanDeductibleModal?: (value: boolean) => void;
};

const DeductiblesOverview: FC<DeductiblesOverviewProps> = (props) => {
  const {
    heading,
    plan,
    benefitKind,
    fetchPlanWhenUpdated,
    disableEditButton,
    viewPlanDeductibleModal,
    setViewPlanDeductibleModal,
  } = props;

  const params = useParams();
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();

  const { medicalPlan } = useAppSelector((state) => state.plan.plans);

  const { dentalPlan } = useAppSelector((state) => state.plan.dentalPlan);

  useEffect(() => {
    if (employerId && brokerId && params.planId && !medicalPlan) {
      if (benefitKind === BenefitCategory.MEDICAL.value) {
        dispatch(findMedicalPlanById(params.planId));
      }
      if (benefitKind === BenefitCategory.DENTAL.value) {
        dispatch(findDentalPlanById(params.planId));
      }
    }
  }, [dispatch, employerId, brokerId, params.planId, benefitKind, medicalPlan]);

  const fetchPlanOnNotificationReceived = () => {
    if (benefitKind === BenefitCategory.MEDICAL.value && params.planId) {
      dispatch(findMedicalPlanById(params.planId));
    } else if (benefitKind === BenefitCategory.DENTAL.value && params.planId) {
      dispatch(findDentalPlanById(params.planId));
    }
  };

  const editPlan = () => {
    setViewPlanDeductibleModal && setViewPlanDeductibleModal(true);
    if (fetchPlanWhenUpdated) {
      fetchPlanOnNotificationReceived();
      notification.close(PLAN_NOTIFICATION_KEY);
    }
  };

  const onClose = () => {
    if (
      benefitKind === BenefitCategory.MEDICAL.value &&
      setViewPlanDeductibleModal
    ) {
      setViewPlanDeductibleModal(false);
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(findMedicalPlanById(params.planId));
      }
    } else if (
      benefitKind === BenefitCategory.DENTAL.value &&
      setViewPlanDeductibleModal
    ) {
      setViewPlanDeductibleModal(false);
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(findDentalPlanById(params.planId));
      }
    }
  };

  return (
    <div>
      <Row>
        <OverviewHeader
          title={heading}
          onEditClick={() => {
            editPlan();
          }}
          disabled={disableEditButton}
        />
      </Row>
      <div className={styles.tableWrapper}>
        <Row>
          <Col span={18} className={styles.mainText}>
            <PlanInfoHeaderLabel
              labelHeaderText={['In Network', 'Out Of Network']}
            />
          </Col>
        </Row>
        <InfoLabelSection
          labelText="Individual Deductible"
          value={
            !isNullOrUndefined(plan?.deductibles?.individualInNetworkCost)
              ? `${plan?.deductibles?.individualInNetworkCost}`
              : '-'
          }
          extraColValue1={
            !isNullOrUndefined(plan?.deductibles?.individualOutOfNetworkCost)
              ? plan?.deductibles?.individualOutOfNetworkCost
              : '-'
          }
        />
        <InfoLabelSection
          labelText="Family Deductible"
          value={
            !isNullOrUndefined(plan?.deductibles?.familyInNetworkCost)
              ? `${plan?.deductibles?.familyInNetworkCost}`
              : '-'
          }
          extraColValue1={
            !isNullOrUndefined(plan?.deductibles?.familyOutOfNetworkCost)
              ? plan?.deductibles?.familyOutOfNetworkCost
              : '-'
          }
        />
        {benefitKind === 'DENTAL' && (
          <>
            <InfoLabelSection
              labelText="Plan Year Max"
              value={
                (plan as DentalPlan).calendarYearMax?.inNetworkCost
                  ? (plan as DentalPlan).calendarYearMax?.inNetworkCost
                  : '-'
              }
              extraColValue1={
                (plan as DentalPlan).calendarYearMax?.outOfNetworkCost
                  ? (plan as DentalPlan).calendarYearMax?.outOfNetworkCost
                  : '-'
              }
            />
            <InfoLabelSection
              labelText="Orthodontia Max"
              value={
                !isNullOrUndefined(
                  (plan as DentalPlan).orthodontiaLifetimeMax?.inNetworkCost
                )
                  ? (plan as DentalPlan).orthodontiaLifetimeMax?.inNetworkCost
                  : '-'
              }
              extraColValue1={
                !isNullOrUndefined(
                  (plan as DentalPlan).orthodontiaLifetimeMax?.outOfNetworkCost
                )
                  ? (plan as DentalPlan).orthodontiaLifetimeMax
                      ?.outOfNetworkCost
                  : '-'
              }
            />
          </>
        )}
        {benefitKind === 'MEDICAL' && (
          <>
            {
              <>
                <InfoLabelSection
                  labelText="Ind. within Family Deductible"
                  value={
                    !isNullOrUndefined(
                      (plan as MedicalPlan).deductibles
                        ?.individualWithinFamilyInNetworkCost
                    )
                      ? `${
                          (plan as MedicalPlan).deductibles
                            ?.individualWithinFamilyInNetworkCost
                        }`
                      : '-'
                  }
                  extraColValue1={
                    !isNullOrUndefined(
                      (plan as MedicalPlan).deductibles
                        ?.individualWithinFamilyOutOfNetworkCost
                    )
                      ? (plan as MedicalPlan).deductibles
                          ?.individualWithinFamilyOutOfNetworkCost
                      : '-'
                  }
                />
              </>
            }
            <InfoLabelSection
              labelText="Individual OOP Max"
              value={
                !isNullOrUndefined(
                  (plan as MedicalPlan).outOfPocket?.individualInNetworkCost
                )
                  ? `${
                      (plan as MedicalPlan).outOfPocket?.individualInNetworkCost
                    }`
                  : '-'
              }
              extraColValue1={
                !isNullOrUndefined(
                  (plan as MedicalPlan).outOfPocket?.individualOutOfNetworkCost
                )
                  ? (plan as MedicalPlan).outOfPocket
                      ?.individualOutOfNetworkCost
                  : '-'
              }
            />
            <InfoLabelSection
              labelText="Family OOP Max"
              value={
                !isNullOrUndefined(
                  (plan as MedicalPlan).outOfPocket?.familyInNetworkCost
                )
                  ? `${(plan as MedicalPlan).outOfPocket?.familyInNetworkCost}`
                  : '-'
              }
              extraColValue1={
                !isNullOrUndefined(
                  (plan as MedicalPlan).outOfPocket?.familyOutOfNetworkCost
                )
                  ? (plan as MedicalPlan).outOfPocket?.familyOutOfNetworkCost
                  : '-'
              }
            />
            {
              <>
                <InfoLabelSection
                  labelText="Ind. within Family OOP Max"
                  value={
                    !isNullOrUndefined(
                      (plan as MedicalPlan).outOfPocket
                        ?.individualWithinFamilyInNetworkCost
                    )
                      ? `${
                          (plan as MedicalPlan).outOfPocket
                            ?.individualWithinFamilyInNetworkCost
                        }`
                      : '-'
                  }
                  extraColValue1={
                    !isNullOrUndefined(
                      (plan as MedicalPlan).outOfPocket
                        ?.individualWithinFamilyOutOfNetworkCost
                    )
                      ? (plan as MedicalPlan).outOfPocket
                          ?.individualWithinFamilyOutOfNetworkCost
                      : '-'
                  }
                />
              </>
            }
          </>
        )}
        {benefitKind === BenefitCategory.MEDICAL.value &&
          viewPlanDeductibleModal && (
            <EditMedicalPlanModal
              plan={medicalPlan}
              onClose={onClose}
              isModalVisible={viewPlanDeductibleModal}
              editType={EditMdvTypes.DEDUCTIBLES_OOP_MAX}
              setViewModal={() => setViewPlanDeductibleModal}
            />
          )}
        {benefitKind === BenefitCategory.DENTAL.value &&
          viewPlanDeductibleModal && (
            <EditDentalPlanModal
              plan={dentalPlan}
              onClose={onClose}
              isModalVisible={viewPlanDeductibleModal}
              editType={EditMdvTypes.DEDUCTIBLES_AND_MAXIMUMS}
              setViewModal={() => setViewPlanDeductibleModal}
            />
          )}
      </div>
    </div>
  );
};

export default DeductiblesOverview;
