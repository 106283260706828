import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Select } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import SearchBar from 'components/SearchBar/SearchBar';
import { ReactComponent as FilterIcon } from 'assets/images/icon-funnel.svg';
import { ReactComponent as DropDownSelector } from 'assets/images/dashboardUtils/dropdown-selector.svg';
import styles from 'components/SingleSelectFilter/singleSelectFilter.module.less';

const { Option } = Select;

type Props = {
  data: any[];
  placeholder: string;
  showSearch: boolean;
  searchPlaceholder?: string;
  setFilterSelectedValue: Function;
  onMouseEnter?: Function;
  defaultValue: string | null;
  inlineDropdown?: boolean;
  addAllValue?: boolean;
  allLabel?: string;
  selectedValue: string | null;
  applicableIcon?: any;
  archivedList?: any[];
  archivedText?: string;
  isDisabled?: boolean;
  isWiderWrapper?: boolean;
  isNoWrapWrapper?: boolean;
  className?: string;
  isOverflowHide?: boolean;
  allowClear?: boolean;
  // Use this prop to make the dropdowns selected tick to show or hide and to make selected item in blue color or default
  isSelectionHighlightAndTickApplicable?: boolean;
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
  handleOnSelect?: (val: string) => void;
  width?: string;
  planError?: boolean;
  listHeight?: number;
};

const SingleSelectFilter = forwardRef((props: Props, ref) => {
  const {
    data,
    placeholder,
    showSearch,
    searchPlaceholder = '',
    setFilterSelectedValue,
    defaultValue,
    inlineDropdown = false,
    addAllValue = false,
    allLabel = '',
    selectedValue,
    archivedList,
    archivedText,
    isDisabled = false,
    isWiderWrapper = false,
    isNoWrapWrapper = false,
    className,
    isOverflowHide = false,
    isSelectionHighlightAndTickApplicable = true,
    handleOnSelect = undefined,
    placement,
    width,
    planError = false,
    allowClear = false,
    onMouseEnter,
    listHeight = 200,
  } = props;

  const [searchText, setSearchText] = useState('');

  useImperativeHandle(ref, () => ({
    reset() {
      setFilterSelectedValue(defaultValue);
      setSearchText('');
    },
  }));

  const suffixIcon = () => {
    if (props.applicableIcon) {
      return props.applicableIcon;
    }
    if (inlineDropdown) {
      return <DropDownSelector />;
    } else {
      return <FilterIcon />;
    }
  };

  const getFilteredOption = (options: any[]) => {
    if (addAllValue) {
      options = [{ label: allLabel, value: null }, ...options];
    }

    const optionsArray: any[] = options?.filter(({ label }) =>
      label?.toLowerCase()?.includes(searchText?.toLowerCase().trim())
    );
    return optionsArray;
  };

  const dropdownRenderComp = (menu: React.ReactNode) => {
    return (
      <>
        {showSearch ? (
          <div className={styles.searchFilter}>
            <SearchBar
              placeholder={searchPlaceholder}
              isLarge={false}
              value={searchText}
              onChange={(e) => setSearchText(e?.target?.value ?? '')}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </div>
        ) : null}
        {menu}
      </>
    );
  };

  const handleOnClick = (value: any) => {
    setSearchText('');
    setFilterSelectedValue(value);
  };

  // Will be triggered when a user click on the option, IE - Even if the user click on the same option this will get triggered
  const onSelect = (val: string) => {
    if (handleOnSelect) {
      handleOnSelect(val);
    }
  };

  const getDropdownClassName = (): string => {
    if (isNoWrapWrapper) {
      return styles.dropdownNoWrapWrapper;
    } else if (isWiderWrapper) {
      return styles.dropdownWiderWrapper;
    } else {
      return styles.dropdownWrapper;
    }
  };

  return (
    <div
      className={`${
        inlineDropdown ? styles.dropDownFilter : styles.filterWrapper
      } ${className} ${planError ? styles.errorFilter : ''}`}
      onMouseEnter={() => onMouseEnter?.()}
    >
      <Select
        placeholder={placeholder}
        className={
          inlineDropdown
            ? isOverflowHide
              ? styles.overflowHideLabel
              : styles.selectedLabel
            : styles.filter
        }
        style={{ width: width !== undefined ? width : '' }}
        suffixIcon={suffixIcon}
        dropdownRender={dropdownRenderComp}
        dropdownClassName={`${
          styles.dropdownDefaultWrapper
        } ${getDropdownClassName()}`}
        listHeight={listHeight}
        onChange={handleOnClick}
        value={selectedValue}
        defaultValue={defaultValue}
        onDropdownVisibleChange={() => setSearchText('')}
        disabled={isDisabled}
        placement={placement}
        getPopupContainer={(trigger) => trigger.parentNode}
        onSelect={onSelect}
        allowClear={allowClear}
      >
        {getFilteredOption(data)?.map((option: any, idx: number) => {
          return (
            <Option
              key={option?.value === null ? idx : option?.value}
              value={option?.value}
              label={option?.label}
              disabled={option?.disable}
              className={
                isSelectionHighlightAndTickApplicable
                  ? selectedValue === option?.value
                    ? styles.selectedOption
                    : styles.unselectedOption
                  : styles.unselectedOption
              }
            >
              {(isSelectionHighlightAndTickApplicable &&
                selectedValue === option?.value) ??
              '' ? (
                <CheckOutlined className={styles.selectedCheck} />
              ) : (
                ''
              )}
              {option?.label ?? ''}
            </Option>
          );
        })}

        {archivedList?.length ? (
          <Option className={styles.archivedLabel} key={'ARCHIVED_LABEL'}>
            <span className={styles.archivedText}>{archivedText}</span>
          </Option>
        ) : (
          <></>
        )}
        {archivedList?.length &&
          archivedList?.map((option, idx) => {
            return (
              <Option
                key={option?.value === null ? idx : option?.value}
                value={option?.value}
                label={option?.label}
                className={
                  selectedValue === option?.value
                    ? styles.selectedOption
                    : styles.unselectedOption
                }
              >
                {selectedValue === option?.value ?? '' ? (
                  <CheckOutlined className={styles.selectedCheck} />
                ) : (
                  ''
                )}
                {option?.label ?? ''}
              </Option>
            );
          })}
      </Select>
    </div>
  );
});
SingleSelectFilter.displayName = 'SingleSelectFilter';
export default SingleSelectFilter;
