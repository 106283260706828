import { FC, useState } from 'react';

import { Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { cloneDeep } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import NextButton from 'components/buttons/NextButton/NextButton';
import TextButton from 'components/buttons/TextButton/TextButton';
import SummeryCommonTable from 'modules/renewals/components/helperComponents/SummeryCommonTable/SummeryCommonTable';
import PlanDetailModal from 'modules/employers/components/AFPCloneDBGAndPlans/PlanDetailModal/PlanDetailModal';
import {
  IBenGuide,
  IResultItem,
  ITransformedResult,
} from 'modules/employers/types/planBGCloneTypes';
import { updateBenGuideList } from 'modules/employers/slices/planBGCloneSlice';

import { ReactComponent as RemoveIcon } from 'assets/images/icon-remove-red.svg';

import styles from './benefitGuideDetails.module.less';

type BenefitGuideDetailsProps = {
  setCurrentStep: (step: number) => void;
};

const BenefitGuideDetails: FC<BenefitGuideDetailsProps> = (
  props: BenefitGuideDetailsProps
) => {
  const { setCurrentStep } = props;
  const dispatch = useAppDispatch();
  const cloneBenefitGuideList = useAppSelector(
    (state) => state.employer.planBGClone.clonePlanBGList.benGuides
  );
  const clonePlanList = useAppSelector(
    (state) => state.employer.planBGClone.clonePlanBGList.plans
  );

  const [showPlanDetails, setViewPlanDetails] = useState<{
    show: boolean;
    benGuideData: Partial<IBenGuide>;
    index: number | null;
  }>({
    show: false,
    benGuideData: {},
    index: null,
  });

  const isDisabledPlanStep = (
    clonePlan: Partial<ITransformedResult>,
    benefitList: IBenGuide[]
  ): boolean => {
    if (Object.keys(clonePlan)?.length === 0 && benefitList?.length === 0) {
      return true;
    }

    const hasCheckedPlans = Object.values(clonePlan)?.filter(
      (category?: IResultItem) =>
        category?.isChecked && category?.plan?.length > 0
    );

    const isClonePlanFound = hasCheckedPlans
      ?.map((item) => item?.plan)
      ?.flat()
      ?.every((item) => item?.isRemoved);

    const isBenGuideFound = benefitList?.every((benefit) => benefit.isRemoved);

    return isClonePlanFound && isBenGuideFound;
  };

  const handleToggleBenGuideRemovedState = (index: number, remove: boolean) => {
    const copiedBGList = cloneDeep(cloneBenefitGuideList);
    copiedBGList[index].isRemoved = remove;
    dispatch(updateBenGuideList(copiedBGList));
  };

  const handleRemovePlan = (index: number) => {
    handleToggleBenGuideRemovedState(index, true);
  };

  const handleViewDetailAndUndo = (record: IBenGuide, index: number) => {
    if (record.isRemoved) {
      handleToggleBenGuideRemovedState(index, false);
    } else {
      setViewPlanDetails({ show: true, benGuideData: record, index });
    }
  };

  const benefitGuideTableColumns: ColumnsType<IBenGuide> = [
    {
      title: <p className={styles.planTitle}>Benefits Guide Name</p>,
      key: 'name',
      dataIndex: 'name',
      render: (data, record, index) => (
        <div className={styles.headerContainer}>
          {!record.isRemoved && (
            <RemoveIcon
              className={styles.removedIcon}
              onClick={() => handleRemovePlan(index)}
            />
          )}
          <p
            className={
              record.isRemoved ? styles.removedStateStyle : styles.planText
            }
          >
            {data}
          </p>
        </div>
      ),
    },
    {
      title: '',
      key: '',
      dataIndex: '',
      align: 'right',
      render: (_, record, index) => {
        return (
          <div className={styles.actionContainer}>
            {record?.isRemoved && (
              <p className={styles.removedTextStyle}>Guide Removed</p>
            )}

            <TextButton
              type="primary"
              label={record?.isRemoved ? '(Undo)' : 'Guide Details'}
              onClick={() => handleViewDetailAndUndo(record, index)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.benefitContainer}>
      <Row align="middle">
        <span className={styles.headerText}>Benefit Guides</span>
      </Row>

      <SummeryCommonTable
        data={cloneBenefitGuideList}
        columns={benefitGuideTableColumns}
        emptyMessage={
          <span className={styles.tableEmptyText}>
            You have no current benefit guides.
          </span>
        }
      />

      <PlanDetailModal
        visible={showPlanDetails.show}
        data={showPlanDetails.benGuideData}
        changedIndex={showPlanDetails.index}
        handleClose={() => {
          setViewPlanDetails({ show: false, benGuideData: {}, index: null });
        }}
        type="BEN_GUIDE"
      />
      <NextButton
        disabled={isDisabledPlanStep(clonePlanList, cloneBenefitGuideList)}
        className={styles.nextButtonStyles}
        nextStep={() => {
          setCurrentStep(3);
        }}
      />
    </div>
  );
};

export default BenefitGuideDetails;
