import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Menu } from 'antd';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import {
  createRenderColumns,
  DataColumn,
} from 'components/DataTable/DataColumn';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import InfoBanner from 'components/InfoBanner/InfoBanner';
import SearchBar from 'components/SearchBar/SearchBar';
import DataTable from 'components/DataTable/DataTable';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';

import TablePagination from 'model/TablePagination';
import BenefitsConsultant from 'model/benefitsConsultation/BenefitsConsultant';
import AddBrokerConsultants from 'modules/benefitsConsultation/pages/AddBrokerConsultants/AddBrokerConsultants';
import IncompleteDetailsPopover from 'modules/benefitsConsultation/components/IncompleteDetailsPopover/IncompleteDetailsPopover';
import SendInviteModal from 'modules/benefitsConsultation/components/SendInviteModal/SendInviteModal';
import BrokerConsultantDetails from 'modules/benefitsConsultation/pages/BrokerConsultantDetails/BrokerConsultantDetails';

import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  addRemoveConsultantsSuccess,
  getBrokerConsultantsList,
} from 'modules/benefitsConsultation/slices/brokerLevelSlice';
import {
  deleteConsultant,
  deleteConsultantInProgress,
  deleteConsultantSuccess,
  getConsultantDetails,
  inviteConsultantsSuccess,
} from 'modules/benefitsConsultation/slices/commonSlice';
import {
  ConsultationTypes,
  SEND_INVITE_NOW,
} from 'modules/benefitsConsultation/constants/benefitsConsultation';

import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
  mapValueToLabel,
} from 'util/commonUtil';
import { buildInitials } from 'util/stringUtil';
import consultationIcon from 'assets/images/consultation-large.svg';

import { CONSULTANT_EMAIL_WARNING_MSG_IF_ASSIGN_TO_EMP } from 'modules/admins/constants/adminConstants';
import ListComponent from 'components/Helpers/ListComponent/ListComponent';
import { getAdminConsultantValidation } from 'modules/admins/slices/adminConsultantValidateSlice';
import { IndividualType } from 'constants/commonConstants';
import styles from './benefitsConsultation.module.less';

const convertBrokerConsultantsForTableRender = (
  brokerConsultants: BenefitsConsultant[]
): any[] => {
  if (brokerConsultants != null) {
    return brokerConsultants.map((element) => ({
      key: element.id,
      consultant: element,
      email: element.email,
      type: element.type,
      status: element,
      actions: element,
    }));
  }
  return [];
};

const BenefitsConsultation: FC = () => {
  const dispatch = useAppDispatch();
  const { brokerId } = useNavContext();

  const brokerConsultants = useAppSelector(
    (state) => state.benefitsConsultation.brokerLevel.brokerConsultants
  );

  const requestType = useAppSelector(
    (state) => state.benefitsConsultation.brokerLevel.requestType
  );
  const requestTypeInvite = useAppSelector(
    (state) => state.benefitsConsultation.common.requestTypeInvite
  );

  const inProgress = useAppSelector(
    (state) => state.benefitsConsultation.brokerLevel.inProgress
  );

  const pendingInviteList = useAppSelector(
    (state) =>
      state.benefitsConsultation.brokerLevel.pendingInviteConsultantsList
  );

  const requestTypeCommon = useAppSelector(
    (state) => state.benefitsConsultation.common.requestType
  );

  const consultant = useAppSelector(
    (state) => state.benefitsConsultation.common.consultant
  );

  const adminConsultantValidateState = useAppSelector(
    (state) => state.admins.adminConsultantValidate
  );

  const [addConsultantsModalVisible, setAddConsultantsModalVisible] =
    useState<boolean>(false);
  const [sendInvitesVisible, setSendInvitesVisible] = useState<boolean>(false);
  const [consultantDetailsModalVisible, setConsultantDetailsModalVisible] =
    useState<boolean>(false);
  const [visibleConfirmationDelete, setVisibleConfirmationDelete] =
    useState<boolean>(false);
  const [showEditMode, setShowEditMode] = useState<boolean>(false);
  const [consultantId, setConsultantId] = useState<string>('');
  const [consultantName, setCounsultatntName] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });

  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'fullName',
      field: 'fullName',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  const { content, metadata } = brokerConsultants || {};
  const { total } = metadata;
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  const updateTableData = useCallback(() => {
    if (brokerId) {
      dispatch(
        getBrokerConsultantsList(
          convertPaginationConfig(paginationConfig),
          brokerId
        )
      );
    }
  }, [brokerId, dispatch, paginationConfig]);

  useEffect(() => {
    updateTableData();
  }, [updateTableData]);

  useEffect(() => {
    if (
      !addConsultantsModalVisible &&
      requestType === addRemoveConsultantsSuccess.type
    ) {
      updateTableData();
      setSendInvitesVisible(true);
    }
  }, [addConsultantsModalVisible, requestType, updateTableData]);

  useEffect(() => {
    if (requestTypeCommon === deleteConsultantSuccess.type) {
      setVisibleConfirmationDelete(false);
      updateTableData();
    }
  }, [requestTypeCommon, updateTableData]);

  useEffect(() => {
    if (requestTypeInvite === inviteConsultantsSuccess.type) {
      updateTableData();
    }
  }, [requestTypeInvite, updateTableData]);

  const getData = (filters: TablePagination) => {
    if (filters.sorterInfo) {
      const paginationConfigData = {
        sorterInfo: filters.sorterInfo,
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      };
      setPaginationConfig(paginationConfigData);
    } else {
      const paginationConfigData = {
        sorterInfo: {
          columnKey: 'fullName',
          field: 'fullName',
          order: 'ascend',
        },
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      } as TablePagination;
      setPaginationConfig(paginationConfigData);
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setSearchText(_searchText);
    const pageInfo = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText: _searchText },
    };
    debounceLoadData(pageInfo);
  };

  const confirmDelete = () => {
    if (!isEmpty(consultantId)) {
      dispatch(deleteConsultant(consultantId));
    }
  };

  const toggleConfirmationDeletePopup = () => {
    setVisibleConfirmationDelete(!visibleConfirmationDelete);
  };

  const handleDelete = (consultant: BenefitsConsultant) => {
    setCounsultatntName(consultant.fullName);
    setConsultantId(consultant.id);
    dispatch(
      getAdminConsultantValidation({
        adminType: IndividualType.ORGANIZATION_ADMIN,
        orgId: brokerId,
        individualId: null,
        employerId: null,
        consultantId: consultant.id,
      })
    );
    toggleConfirmationDeletePopup();
  };

  const brokerConsultantsTableColumns: DataColumn[] = [
    {
      title: 'CONSULTANT',
      dataIndex: 'consultant',
      key: 'fullName',
      sorter: (a: any, b: any) => 0, // this sort is done by backend.
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: 'ascend',
      render: (consultant) => {
        const firstName = consultant.fullName.split(' ')[0];
        const lastName = consultant.fullName.split(' ')[1];

        return (
          <div className={styles.name}>
            <div className={styles.avatar}>
              <ProfileAvatar
                content={buildInitials(firstName || '', lastName || '')}
                src={consultant.avatarUrl}
              />
            </div>

            <span
              className={styles.nameColStyle}
              onClick={() => {
                dispatch(getConsultantDetails(consultant.id));
                setConsultantDetailsModalVisible(true);
              }}
            >
              <OverflowPopover
                popoverContent={consultant.fullName}
                maxWidth="200px"
              >
                {consultant.fullName}
              </OverflowPopover>
            </span>
          </div>
        );
      },
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      ellipsis: {
        showTitle: false,
      },
      render: (email) => (
        <OverflowPopover popoverContent={email}>{email}</OverflowPopover>
      ),
    },
    {
      title: 'CONSULTANT TYPE',
      dataIndex: 'type',
      key: 'type',
      render: (type) => <>{mapValueToLabel(type, ConsultationTypes)}</>,
    },

    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: any, b: any) => a.status.status - b.status.status,

      render: (data) => {
        return (
          <b>
            {!data.status.status ? (
              <div>
                <IncompleteDetailsPopover
                  setAlertMessage={setAlertMessage}
                  setAlertVisible={setAlertVisible}
                  benefitsConsultant={data}
                  setShowEditMode={setShowEditMode}
                  setConsultantDetailsModalVisible={
                    setConsultantDetailsModalVisible
                  }
                >
                  <span className={styles.incompleteText}>Incomplete</span>
                </IncompleteDetailsPopover>
              </div>
            ) : (
              <>Complete</>
            )}
          </b>
        );
      },
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '12%',
      render: (consultant: BenefitsConsultant) => {
        return (
          <div className={styles.dropdownWrapper}>
            <SelectDropdown overlay={brokerActions(consultant)} />
          </div>
        );
      },
      align: 'left',
    },
  ];

  const brokerActions = (consultant: BenefitsConsultant) => {
    return (
      <Menu>
        <Menu.Item
          key="consultantInfo"
          onClick={() => {
            dispatch(getConsultantDetails(consultant.id));
            setShowEditMode(false);
            setConsultantDetailsModalVisible(true);
          }}
        >
          Consultant Details
        </Menu.Item>
        <Menu.Item
          key="remove"
          onClick={() => {
            handleDelete(consultant);
          }}
        >
          Remove Consultant
        </Menu.Item>
      </Menu>
    );
  };

  const handleEmployerClick = (val: any) => {
    window.open(
      `/brokers/${brokerId}/employers/${val.value}/broker-consultation`,
      '_blank'
    );
  };

  return (
    <div className={styles.benefitsConsultationWrapper}>
      <div className={styles.alertWrapper}>
        {alertVisible && (
          <AlertMessage
            type={alertMessage.type}
            message={alertMessage.message}
            closeAlert={() => setAlertVisible(false)}
          />
        )}
      </div>
      <div className={styles.headerSection}>
        <div className={styles.heading}>
          <h1>Broker Consultants</h1>
        </div>
        <PageActionButton
          type="primary"
          onClick={() => {
            setAddConsultantsModalVisible(true);
          }}
          className={`${styles.actionButton}`}
        >
          Add Broker Consultant
        </PageActionButton>
      </div>
      {!inProgress && isEmpty(content) && isEmpty(searchText) ? (
        <InfoBanner
          title="You have no Broker Consultants added!"
          description="Get started by adding your first Consultant."
          logo={<img src={consultationIcon} alt="next-icon" />}
          className={styles.infoBanner}
        />
      ) : (
        <>
          <div className={styles.searchBar}>
            <SearchBar
              placeholder="Search Broker Consultants"
              onChange={handleSearch}
            />
          </div>
          <div className={styles.summaryTable}>
            <DataTable
              currentIndex={paginationConfig.paginationIndex}
              data={convertBrokerConsultantsForTableRender(content)}
              columns={createRenderColumns(brokerConsultantsTableColumns)}
              pagination
              getData={getData}
              total={total}
              pageSize={limit}
              loading={inProgress}
            />
          </div>
        </>
      )}
      <AddBrokerConsultants
        visible={addConsultantsModalVisible}
        setVisible={setAddConsultantsModalVisible}
      />
      <SendInviteModal
        pendingInviteList={pendingInviteList}
        visible={!isEmpty(pendingInviteList) && sendInvitesVisible}
        onCancel={(event, type) => {
          setSendInvitesVisible(false);
          setAlertVisible(true);
          setAlertMessage({
            type: 'success',
            message: `${
              type === SEND_INVITE_NOW
                ? 'Broker Consultant(s) have been successfully added and invites have been sent to sync their calendar.'
                : 'Broker Consultant(s) have been successfully added.'
            }`,
          });
        }}
      />
      {!isEmpty(consultant) && (
        <BrokerConsultantDetails
          visible={consultantDetailsModalVisible}
          setVisible={setConsultantDetailsModalVisible}
          consultant={consultant}
          setAlertVisibleParent={setAlertVisible}
          setAlertMessageParent={setAlertMessage}
          showEditMode={showEditMode}
          updatedStatus={
            content?.find((cons) => cons.id === consultant.id)?.status
          }
          updateTableData={updateTableData}
        />
      )}

      <ConfirmationDialog
        title="Remove Broker Consultant"
        confirmText="Yes - Remove Consultant"
        cancelText="Cancel"
        closeModal={toggleConfirmationDeletePopup}
        onConfirm={confirmDelete}
        visible={
          visibleConfirmationDelete && !adminConsultantValidateState?.inProgress
        }
        isRecordApplicable={true}
        recordName={consultantName}
        confirmLoading={requestTypeCommon === deleteConsultantInProgress.type}
      >
        <div className={styles.warningConfirmation}>
          Are you sure you want to remove this consultant? Any already scheduled
          meetings with this consultant will remain and should be manually
          updated from this user&apos;s calendar if needed.
          {Array.isArray(adminConsultantValidateState?.consultantValidateObj) &&
          adminConsultantValidateState?.consultantValidateObj?.length ? (
            <div className={styles.additionalWarningSectionList}>
              {CONSULTANT_EMAIL_WARNING_MSG_IF_ASSIGN_TO_EMP}
              <ListComponent
                className={styles.employerList}
                dataSource={adminConsultantValidateState?.consultantValidateObj?.map(
                  (item: any) => ({ label: item?.name, value: item?.id })
                )}
                onClickItem={handleEmployerClick}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </ConfirmationDialog>
    </div>
  );
};

export default BenefitsConsultation;
