import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { DentalPlan } from 'model/plans/DentalPlan';
import { updateDentalPlan } from 'modules/plans/slices/dentalPlanSlice';
import PlanBasicInfoForm from 'modules/plans/components/DVPlanBasicInfoForm/PlanBasicInfoForm';

type DentalBasicPlanInfoDBGWrapperProps = {
  dentalPlan: DentalPlan;
  error: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

const DentalBasicPlanInfoDBGWrapper = forwardRef(
  (props: DentalBasicPlanInfoDBGWrapperProps, ref) => {
    const dispatch = useAppDispatch();
    const { dentalPlan } = props;
    const editedDentalPlan = useAppSelector(
      (state) => state.plan.dentalPlan.editedDentalPlan
    );
    const basicPlanInfoRef = useRef<any>();
    const [requiredFieldError, setRequiredFieldError] =
      useState<boolean>(false);

    const changeValues = (changedPlan: DentalPlan) => {
      dispatch(updateDentalPlan(changedPlan));
    };

    useImperativeHandle(ref, () => ({
      reset() {
        basicPlanInfoRef.current?.resetForm();
      },
      isValidForm() {
        return basicPlanInfoRef.current?.isValidForm();
      },
    }));

    useEffect(() => {
      dispatch(updateDentalPlan(dentalPlan));
    }, [dispatch, dentalPlan]);

    return (
      <PlanBasicInfoForm
        onChange={changeValues}
        plan={dentalPlan}
        isCloseConfirmed={false}
        isDBGPlan={true}
        error={[requiredFieldError, setRequiredFieldError]}
        ref={basicPlanInfoRef}
        hasSameContributions={editedDentalPlan?.hasSameContributions}
        benefitCategory="DENTAL"
        isSingleColumn
      />
    );
  }
);

DentalBasicPlanInfoDBGWrapper.displayName = 'DentalBasicPlanInfoDBGWrapper';
export default DentalBasicPlanInfoDBGWrapper;
