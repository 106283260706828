import { WidthRef } from 'modules/renewals/types/planTableTypes';
import {
  ADDITIONAL_SERVICES_FIELD_PROPERTY,
  ADMIN_FEE,
  AGE_REDUCTION_FIELD_PROPERTY,
  AGGREGATE_STOP_LOSS_FEE_FIELD,
  AGGREGATE_STOP_LOSS_FIELD,
  ANNUAL_ESTIMATED_CLAIMS,
  ANNUAL_PREMIUM_PROPERTY,
  BASIC_ADD,
  BASIC_ADD_RATE_FIELD_PROPERTY,
  BASIC_LIFE,
  BASIC_LIFE_AND_ADD,
  BENEFIT_DURATION_FIELD_PROPERTY,
  BENEFIT_FIELD_PROPERTY,
  DEDUCTIBLE_FAM_OUT_NETWORK_PROPERTY,
  DEDUCTIBLES_FIELD_PROPERTY,
  DEDUCTIBLES_IND_WITHIN_FAMILY_FIELD_PROPERTY,
  DEFINITION_OF_DISABILITY_FIELD_PROPERTY,
  ENROLLMENT_FIELD_PROPERTY,
  FIELDS_SUB_TYPES,
  FLAT_AMOUNT_FIELD_PROPERTY,
  FROM_CURRENT_PROPERTY,
  FUNDING_TYPE,
  GUARANTEED_ISSUE_FIELD_PROPERTY,
  IN_NETWORK_VALUE,
  INDIVIDUAL_STOP_LOSS_FEE_FIELD,
  INDIVIDUAL_STOP_LOSS_FIELD,
  LIFE_ADD_BENEFIT_FIELD_PROPERTY,
  LIFE_AND_ADD_MAXIMUM_FIELD_PROPERTY,
  LIFE_RATE_FIELD_PROPERTY,
  LTD,
  MAX_MONTHLY_BENEFIT_FIELD_PROPERTY,
  MAX_WEEKLY_BENEFIT_FIELD_PROPERTY,
  MAXIMUM_BENEFITS_FIELD_PROPERTY,
  MONTHLY_PREMIUM_PROPERTY,
  MULTIPLIER_FIELD_PROPERTY,
  NON_MDV_REQUIRED_FIELDS,
  OOP_FAM_OUT_NETWORK_PROPERTY,
  OOP_FIELD_PROPERTY,
  OOP_IND_WITHIN_FAMILY_FIELD_PROPERTY,
  ORTHODONTIA_MAX_FIELD_PROPERTY,
  OTHER_FEES_FIELD,
  OUT_NETWORK_DEDUCTIBLE_FIELD_PROPERTY,
  OUT_NETWORK_MAXIMUM_BENEFITS_FIELD_PROPERTY,
  OUT_NETWORK_OOP_FIELD_PROPERTY,
  OUT_NETWORK_ORTHODONTIA_MAX_FIELD_PROPERTY,
  OUT_NETWORK_RX_DEDUCTIBLE_PROPERTY,
  OUT_NETWORK_RX_OOP_PROPERTY,
  OUT_NETWORK_VALUE,
  OWN_OCCUPATION_PERIOD_FIELD_PROPERTY,
  PLAN_DOCUMENTS_FIELD_PROPERTY,
  PLAN_NETWORK_FIELD_PROPERTY,
  PLAN_TABLE_INITIAL_TITLES,
  PLAN_TYPE_FIELD_PROPERTY,
  PRE_EXISTING_CONDITIONS_PROPERTY,
  PREMIUMS_FIELD_PROPERTY,
  RX_DEDUCTIBLE_FIELD_PROPERTY,
  RX_OOP_FIELD_PROPERTY,
  RX_TIERS_FIELD_PROPERTY,
  SERVICES_FIELD_PROPERTY,
  STATE_DISABILITY_INTEGRATION_PROPERTY,
  STD,
  STD_LTD_PLANS,
  THIRD_PARTY_ADMIN_FEE_FIELD,
  TOTAL_ELIGIBLE_EMPLOYEES,
  VISION_FREQUENCY_OPTIONS,
  VOLUME_FIELD_PROPERTY,
  WAITING_PERIOD_FIELD_PROPERTY,
} from 'modules/renewals/constants/renewalsConstants';
import FundingType from 'modules/plans/enums/FundingType';
import { BenefitType, NetworkType } from 'modules/renewals/models/PlanTable';
import {
  currencyFormatterWithoutZeros,
  removeCurrencySymbols,
} from 'util/commonUtil';
import {
  ArrayItemTemplate,
  Plan,
  ServiceArrayItemTemplate,
} from 'modules/renewals/types/planTypes';
import { isNullOrUndefined } from 'modules/plans/utils';
import { BenefitCategory } from 'constants/commonConstants';

/**
 * Checks if a property value is present in any of the plans based on the given conditions.
 * @param {Object} options - The options object.
 * @param {Plan[]} options.plans - The array of plans to check.
 * @param {string} options.property - The property to check in each plan.
 * @param {NetworkType} options.networkType - The network type to match.
 * @param {string} [options.fieldSubType] - The field sub type to match (optional).
 * @return {boolean} - Returns true if the property value is present in any of the plans, false otherwise.
 */
const showPropertyAccordingToIFValuePresent = ({
  plans,
  property,
  networkType,
  fieldSubType,
}: {
  plans: Plan[];
  property: string;
  networkType: NetworkType;
  fieldSubType?: string;
}): boolean => {
  return (
    plans?.some((plan: any) => {
      const valueArray: Partial<ArrayItemTemplate>[] = plan?.[property];
      const value = valueArray?.find(
        (item: Partial<ArrayItemTemplate>) =>
          item?.fieldType === networkType &&
          (!fieldSubType || item.fieldSubType === fieldSubType) &&
          !isNullOrUndefined(item?.value) &&
          !['', 'null'].includes(String(item?.value))
      )?.value;
      return Boolean(value);
    }) || false
  );
};

/**
 * Retrieves the self funding fields to be displayed in the plan table based on the self-funding status.
 *
 * @param {Plan[]} plans
 * @param {boolean} isSelfFunded
 * @param {boolean | undefined} isComparePlans
 * @return {string[]} - An array of fields to be displayed in the plan table.
 */
const getMedicalSelfFundingFields = (
  plans: Plan[],
  isSelfFunded: boolean,
  isComparePlans: boolean | undefined
): string[] => {
  let fields: string[] = [];
  if (isSelfFunded) {
    fields = [
      INDIVIDUAL_STOP_LOSS_FIELD,
      INDIVIDUAL_STOP_LOSS_FEE_FIELD,
      AGGREGATE_STOP_LOSS_FIELD,
      AGGREGATE_STOP_LOSS_FEE_FIELD,
      THIRD_PARTY_ADMIN_FEE_FIELD,
      OTHER_FEES_FIELD,
    ];
  } else {
    checkIfSelfFundingFieldExists(
      plans,
      isSelfFunded,
      isComparePlans,
      INDIVIDUAL_STOP_LOSS_FIELD
    ) && fields.push(INDIVIDUAL_STOP_LOSS_FIELD);
    checkIfSelfFundingFieldExists(
      plans,
      isSelfFunded,
      isComparePlans,
      INDIVIDUAL_STOP_LOSS_FEE_FIELD
    ) && fields.push(INDIVIDUAL_STOP_LOSS_FEE_FIELD);
    checkIfSelfFundingFieldExists(
      plans,
      isSelfFunded,
      isComparePlans,
      AGGREGATE_STOP_LOSS_FIELD
    ) && fields.push(AGGREGATE_STOP_LOSS_FIELD);
    checkIfSelfFundingFieldExists(
      plans,
      isSelfFunded,
      isComparePlans,
      AGGREGATE_STOP_LOSS_FEE_FIELD
    ) && fields.push(AGGREGATE_STOP_LOSS_FEE_FIELD);
    checkIfSelfFundingFieldExists(
      plans,
      isSelfFunded,
      isComparePlans,
      THIRD_PARTY_ADMIN_FEE_FIELD
    ) && fields.push(THIRD_PARTY_ADMIN_FEE_FIELD);
    checkIfSelfFundingFieldExists(
      plans,
      isSelfFunded,
      isComparePlans,
      OTHER_FEES_FIELD
    ) && fields.push(OTHER_FEES_FIELD);
  }
  return fields;
};

const checkIfSelfFundingFieldExists = (
  plans: Plan[],
  isSelfFunded: boolean,
  isComparePlans: boolean | undefined,
  fieldName: string
) => {
  return (
    plans?.some((plan: Plan) => {
      let value: string | null | undefined;

      switch (fieldName) {
        case INDIVIDUAL_STOP_LOSS_FIELD:
          value =
            plan?.individualStopLoss?.formattedValue ||
            plan?.individualStopLoss?.value;
          break;
        case INDIVIDUAL_STOP_LOSS_FEE_FIELD:
          value =
            plan?.individualStopLossFee?.formattedValue ||
            plan?.individualStopLossFee?.value;
          break;
        case AGGREGATE_STOP_LOSS_FIELD:
          value =
            plan?.aggregateStopLoss?.formattedValue ||
            plan?.aggregateStopLoss?.value;
          break;
        case AGGREGATE_STOP_LOSS_FEE_FIELD:
          value =
            plan?.aggregateStopLossFee?.formattedValue ||
            plan?.aggregateStopLossFee?.value;
          break;
        case THIRD_PARTY_ADMIN_FEE_FIELD:
          value =
            plan?.thirdPartyAdministrationFee?.formattedValue ||
            plan?.thirdPartyAdministrationFee?.value;
          break;
        case OTHER_FEES_FIELD:
          value = plan?.otherFees?.formattedValue || plan?.otherFees?.value;
          break;
        default:
          value = '';
          break;
      }

      const showAdminFeeAccordingToFundingType = isComparePlans
        ? plan?.fundingType === FundingType.SELF_FUNDED
        : isSelfFunded;
      return (
        value &&
        !['', 'null', null, undefined].includes(value) &&
        showAdminFeeAccordingToFundingType
      );
    }) || false
  );
};

const getAdminFeeValueExistInAtLeastOnePlan = (
  plans: Plan[],
  isSelfFunded: boolean,
  isComparePlans: boolean | undefined
) => {
  return (
    plans?.some((plan: Plan) => {
      const value =
        plan?.administrationFee?.formattedValue ||
        plan?.administrationFee?.value;

      const showAdminFeeAccordingToFundingType = isComparePlans
        ? plan?.fundingType === FundingType.SELF_FUNDED
        : isSelfFunded;
      return (
        value &&
        !['', 'null', null, undefined].includes(value) &&
        showAdminFeeAccordingToFundingType
      );
    }) || false
  );
};

/**
 * Retrieves properties from an array of plans based on the network type.
 * @param {Plan[]} plans - An array of plans.
 * @param {NetworkType} networkType - The network type.
 * @return {boolean[]} An array of properties extracted from the plans.
 */
const getProperties = (plans: Plan[], networkType: NetworkType) =>
  [
    { property: RX_DEDUCTIBLE_FIELD_PROPERTY },
    { property: RX_OOP_FIELD_PROPERTY },
    {
      property: DEDUCTIBLES_FIELD_PROPERTY,
      fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
    },
    {
      property: OOP_FIELD_PROPERTY,
      fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
    },
    {
      property:
        networkType === IN_NETWORK_VALUE
          ? DEDUCTIBLES_IND_WITHIN_FAMILY_FIELD_PROPERTY
          : DEDUCTIBLE_FAM_OUT_NETWORK_PROPERTY,
      fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
    },
    {
      property:
        networkType === IN_NETWORK_VALUE
          ? DEDUCTIBLES_IND_WITHIN_FAMILY_FIELD_PROPERTY
          : OOP_FAM_OUT_NETWORK_PROPERTY,
      fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
    },
  ].map(({ property, fieldSubType }) =>
    showPropertyAccordingToIFValuePresent({
      plans,
      property,
      networkType,
      fieldSubType,
    })
  );

/**
 * Returns an array of string values representing the order of properties in a plan table.
 *
 * @param {Object} options - The options object.
 * @param {Plan[]} options.plans - The array of plans.
 * @param {boolean} options.isComparePlans - Indicates whether the plans are being compared.
 * @param {boolean} options.isPlanDetails - Indicates whether the plan details are being shown.
 * @param {boolean} options.isPlanDetailSidebar - Indicates whether the plan detail sidebar is being shown.
 * @return {string[]} - The array of property names in the desired order.
 */
const planTableOrderMedical = ({
  plans,
  fundingType,
  isComparePlans,
  isPlanDetails,
  isPlanDetailSidebar,
  isPreview,
}: {
  plans: Plan[];
  fundingType: FundingType;
  isComparePlans: boolean | undefined;
  isPlanDetails: boolean | undefined;
  isPlanDetailSidebar: boolean | undefined;
  isPreview: boolean | undefined;
}): string[] => {
  const inNetworkProperties = getProperties(plans, IN_NETWORK_VALUE);
  const outNetworkProperties = getProperties(plans, OUT_NETWORK_VALUE);
  const isOnlyInComparePlans: boolean =
    !isComparePlans && !isPlanDetails && !isPlanDetailSidebar;

  const isComparePlansOrPlanDetails = isComparePlans || isPlanDetails;
  const isSelfFunded = fundingType === FundingType.SELF_FUNDED;
  const selfFundingFields: string[] = getMedicalSelfFundingFields(
    plans,
    isSelfFunded,
    isComparePlans
  );

  return [
    PLAN_TYPE_FIELD_PROPERTY,
    PLAN_NETWORK_FIELD_PROPERTY,
    ...(isComparePlansOrPlanDetails || isPreview ? [FUNDING_TYPE] : []),
    ...selfFundingFields,
    DEDUCTIBLES_FIELD_PROPERTY,
    ...(isOnlyInComparePlans || inNetworkProperties[2] || inNetworkProperties[4]
      ? [DEDUCTIBLES_IND_WITHIN_FAMILY_FIELD_PROPERTY]
      : []),
    OOP_FIELD_PROPERTY,
    ...(isOnlyInComparePlans || inNetworkProperties[3] || inNetworkProperties[5]
      ? [OOP_IND_WITHIN_FAMILY_FIELD_PROPERTY]
      : []),
    SERVICES_FIELD_PROPERTY,
    ...(inNetworkProperties[0] || isOnlyInComparePlans
      ? [RX_DEDUCTIBLE_FIELD_PROPERTY]
      : []),
    ...(inNetworkProperties[1] || isOnlyInComparePlans
      ? [RX_OOP_FIELD_PROPERTY]
      : []),
    RX_TIERS_FIELD_PROPERTY,
    OUT_NETWORK_DEDUCTIBLE_FIELD_PROPERTY,
    ...(isOnlyInComparePlans ||
    outNetworkProperties[2] ||
    outNetworkProperties[4]
      ? [DEDUCTIBLE_FAM_OUT_NETWORK_PROPERTY]
      : []),
    OUT_NETWORK_OOP_FIELD_PROPERTY,
    ...(isOnlyInComparePlans ||
    outNetworkProperties[3] ||
    outNetworkProperties[5]
      ? [OOP_FAM_OUT_NETWORK_PROPERTY]
      : []),
    ...(outNetworkProperties[0] || isOnlyInComparePlans
      ? [OUT_NETWORK_RX_DEDUCTIBLE_PROPERTY]
      : []),
    ...(outNetworkProperties[1] || isOnlyInComparePlans
      ? [OUT_NETWORK_RX_OOP_PROPERTY]
      : []),
    PREMIUMS_FIELD_PROPERTY,
    ...(isComparePlansOrPlanDetails && !isPlanDetailSidebar
      ? [
          MONTHLY_PREMIUM_PROPERTY,
          ANNUAL_PREMIUM_PROPERTY,
          FROM_CURRENT_PROPERTY,
        ]
      : []),
    ...(!isComparePlans ? [PLAN_DOCUMENTS_FIELD_PROPERTY] : []),
  ];
};

/**
 * Retrieves the order of table fields for dental plans.
 *
 * @param {Object} options - The options object.
 * @param {Plan[]} options.plans - The array of plans.
 * @param {FundingType} options.fundingType - The funding type.
 * @param {boolean} options.isComparePlans - Indicates if it's a compare plans scenario.
 * @param {boolean} options.isPlanDetails - Indicates if it's a plan details scenario.
 * @param {boolean} options.isPreview - Indicates if it's a preview scenario.
 * @param {boolean} options.isPlanDetailSidebar - Indicates if it's a plan detail sidebar scenario.
 * @return {string[]} The order of table fields for dental plans.
 */
const getPlanTableOrderDental = ({
  plans,
  fundingType,
  isComparePlans,
  isPlanDetails,
  isPreview,
  isPlanDetailSidebar,
}: {
  plans: Plan[];
  fundingType: FundingType;
  isComparePlans: boolean | undefined;
  isPlanDetails: boolean | undefined;
  isPreview: boolean | undefined;
  isPlanDetailSidebar: boolean | undefined;
}): string[] => {
  const isComparePlansOrPlanDetails = isComparePlans || isPlanDetails;
  const isSelfFunded = fundingType === FundingType.SELF_FUNDED;
  const isAdminFeeValueExist = getAdminFeeValueExistInAtLeastOnePlan(
    plans,
    isSelfFunded,
    isComparePlans
  );
  return [
    PLAN_TYPE_FIELD_PROPERTY,
    PLAN_NETWORK_FIELD_PROPERTY,
    ...(isComparePlansOrPlanDetails || isPreview ? [FUNDING_TYPE] : []),
    ...(isSelfFunded || isAdminFeeValueExist ? [ADMIN_FEE] : []),
    DEDUCTIBLES_FIELD_PROPERTY,
    MAXIMUM_BENEFITS_FIELD_PROPERTY,
    ORTHODONTIA_MAX_FIELD_PROPERTY,
    SERVICES_FIELD_PROPERTY,
    OUT_NETWORK_DEDUCTIBLE_FIELD_PROPERTY,
    OUT_NETWORK_MAXIMUM_BENEFITS_FIELD_PROPERTY,
    OUT_NETWORK_ORTHODONTIA_MAX_FIELD_PROPERTY,
    PREMIUMS_FIELD_PROPERTY,
    ...(isComparePlansOrPlanDetails && !isPlanDetailSidebar
      ? [
          MONTHLY_PREMIUM_PROPERTY,
          ANNUAL_PREMIUM_PROPERTY,
          FROM_CURRENT_PROPERTY,
        ]
      : []),
    PLAN_DOCUMENTS_FIELD_PROPERTY,
  ];
};

/**
 * Retrieves the order of table columns for the plan table in the vision.
 *
 * @param {Object} options - The options object.
 * @param {Plan[]} options.plans - The array of plans.
 * @param {FundingType} options.fundingType - The funding type.
 * @param {boolean} options.isComparePlans - Indicates whether it is a compare plans view.
 * @param {boolean} options.isPlanDetails - Indicates whether it is a plan details view.
 * @param {boolean} options.isPreview - Indicates whether it is a preview view.
 * @param {boolean} options.isPlanDetailSidebar - Indicates whether it is a plan detail sidebar view.
 * @return {string[]} - The array of column names in the desired order.
 */
const getPlanTableOrderVision = ({
  plans,
  fundingType,
  isComparePlans,
  isPlanDetails,
  isPreview,
  isPlanDetailSidebar,
}: {
  plans: Plan[];
  fundingType: FundingType;
  isComparePlans: boolean | undefined;
  isPlanDetails: boolean | undefined;
  isPreview: boolean | undefined;
  isPlanDetailSidebar: boolean | undefined;
}): string[] => {
  const isComparePlansOrPlanDetails = isComparePlans || isPlanDetails;
  const isSelfFunded = fundingType === FundingType.SELF_FUNDED;
  const isAdminFeeValueExist = getAdminFeeValueExistInAtLeastOnePlan(
    plans,
    isSelfFunded,
    isComparePlans
  );
  const isAdditionalServiceValueExists: boolean =
    getInNetworkAdditionalServiceValueExistInAtLeastOnePlan(
      plans,
      VISION_FREQUENCY_OPTIONS.map((option) => option.value)
    );

  return [
    PLAN_TYPE_FIELD_PROPERTY,
    PLAN_NETWORK_FIELD_PROPERTY,
    ...(isComparePlansOrPlanDetails || isPreview ? [FUNDING_TYPE] : []),
    ...(isSelfFunded || isAdminFeeValueExist ? [ADMIN_FEE] : []),
    ...(isAdditionalServiceValueExists
      ? [ADDITIONAL_SERVICES_FIELD_PROPERTY]
      : []),
    SERVICES_FIELD_PROPERTY,
    PREMIUMS_FIELD_PROPERTY,
    ...(isComparePlansOrPlanDetails && !isPlanDetailSidebar
      ? [
          MONTHLY_PREMIUM_PROPERTY,
          ANNUAL_PREMIUM_PROPERTY,
          FROM_CURRENT_PROPERTY,
        ]
      : []),
    PLAN_DOCUMENTS_FIELD_PROPERTY,
  ];
};

/**
 * Retrieves the order of fields for the plan table in the life category.
 * @return {string[]} An array of strings representing the order of fields.
 */
const getPlanTableOrderLife = (): string[] => {
  return [
    MULTIPLIER_FIELD_PROPERTY,
    FLAT_AMOUNT_FIELD_PROPERTY,
    LIFE_ADD_BENEFIT_FIELD_PROPERTY,
    LIFE_AND_ADD_MAXIMUM_FIELD_PROPERTY,
    GUARANTEED_ISSUE_FIELD_PROPERTY,
    AGE_REDUCTION_FIELD_PROPERTY,
    VOLUME_FIELD_PROPERTY,
    LIFE_RATE_FIELD_PROPERTY,
    BASIC_ADD_RATE_FIELD_PROPERTY,
    PLAN_DOCUMENTS_FIELD_PROPERTY,
  ];
};

/**
 * Retrieves the order of fields for the plan table based on the funding type.
 *
 * @param {Object} options - The options object.
 * @param {Plan[]} options.plans - The array of plans (optional).
 * @param {FundingType} options.fundingType - The funding type.
 * @return {string[]} The order of fields for the plan table.
 */
const getPlanTableOrderStd = ({
  fundingType,
}: {
  plans?: Plan[];
  fundingType: FundingType;
}): string[] => {
  const fieldAccordingToFundingType =
    fundingType === FundingType.SELF_FUNDED
      ? [ADMIN_FEE, ANNUAL_ESTIMATED_CLAIMS, TOTAL_ELIGIBLE_EMPLOYEES]
      : [VOLUME_FIELD_PROPERTY, LIFE_RATE_FIELD_PROPERTY];

  return [
    BENEFIT_FIELD_PROPERTY,
    MAX_WEEKLY_BENEFIT_FIELD_PROPERTY,
    WAITING_PERIOD_FIELD_PROPERTY,
    BENEFIT_DURATION_FIELD_PROPERTY,
    DEFINITION_OF_DISABILITY_FIELD_PROPERTY,
    PRE_EXISTING_CONDITIONS_PROPERTY,
    STATE_DISABILITY_INTEGRATION_PROPERTY,
    ...fieldAccordingToFundingType,
    PLAN_DOCUMENTS_FIELD_PROPERTY,
  ];
};

/**
 * Retrieves the order of properties for the plan table.
 * @return {string[]} An array of strings representing the order of properties.
 */
const getPlanTableOrderLtd = (): string[] => {
  return [
    BENEFIT_FIELD_PROPERTY,
    MAX_MONTHLY_BENEFIT_FIELD_PROPERTY,
    WAITING_PERIOD_FIELD_PROPERTY,
    BENEFIT_DURATION_FIELD_PROPERTY,
    DEFINITION_OF_DISABILITY_FIELD_PROPERTY,
    OWN_OCCUPATION_PERIOD_FIELD_PROPERTY,
    VOLUME_FIELD_PROPERTY,
    LIFE_RATE_FIELD_PROPERTY,
    PLAN_DOCUMENTS_FIELD_PROPERTY,
  ];
};

/**
 * Retrieves the order of the plan table for voluntary benefits.
 * @return {string[]} An array of strings representing the order of the plan table.
 */
const getPlanTableOrderVoluntaryBenefit = (): string[] => {
  return [PLAN_DOCUMENTS_FIELD_PROPERTY];
};

/**
 * Retrieves the order of plan properties based on the given parameters.
 * @param {Object} options - The options object.
 * @param {Array} options.plans - The array of plans.
 * @param {BenefitType} options.benefitType - The benefit type.
 * @param {FundingType} options.fundingType - The funding type.
 * @param {boolean} options.isComparePlans - Indicates whether it is a compare plans scenario.
 * @param {boolean} options.isPlanDetails - Indicates whether it is a plan details scenario.
 * @param {boolean} options.isPreview - Indicates whether it is a preview scenario.
 * @param {boolean} options.isPlanDetailSidebar - Indicates whether it is a plan detail sidebar scenario.
 * @return {string[]} The order of plan properties.
 */
export const getPlanPropertyOrder = ({
  plans,
  benefitType,
  fundingType,
  isComparePlans,
  isPlanDetails,
  isPreview,
  isPlanDetailSidebar,
}: {
  plans: any[];
  benefitType: BenefitType;
  fundingType: FundingType;
  isComparePlans: boolean | undefined;
  isPlanDetails: boolean | undefined;
  isPreview: boolean | undefined;
  isPlanDetailSidebar: boolean | undefined;
}): string[] => {
  const order = {
    MEDICAL: planTableOrderMedical({
      plans,
      fundingType,
      isComparePlans,
      isPlanDetails,
      isPlanDetailSidebar,
      isPreview,
    }),
    DENTAL: getPlanTableOrderDental({
      plans,
      fundingType,
      isComparePlans,
      isPlanDetails,
      isPreview,
      isPlanDetailSidebar,
    }),
    VISION: getPlanTableOrderVision({
      plans,
      fundingType,
      isComparePlans,
      isPlanDetails,
      isPreview,
      isPlanDetailSidebar,
    }),
    LIFE: getPlanTableOrderLife(),
    STD: getPlanTableOrderStd({ plans, fundingType }),
    LTD: getPlanTableOrderLtd(),
    VOLUNTARY_BENEFIT: getPlanTableOrderVoluntaryBenefit(),
  };
  return order[benefitType] || [];
};

/**
 * Checks if a given property is a title property based on the benefit type.
 * @param {string} property - The property to check.
 * @param {BenefitType} benefitType - The benefit type.
 * @return {boolean} True if the property is a title property, false otherwise.
 */
export const isTitleProperty = (
  property: string,
  benefitType: BenefitType
): boolean => {
  const titlePropertiesSet = new Set([
    RX_DEDUCTIBLE_FIELD_PROPERTY,
    RX_OOP_FIELD_PROPERTY,
    RX_TIERS_FIELD_PROPERTY,
    DEDUCTIBLES_FIELD_PROPERTY,
    OUT_NETWORK_DEDUCTIBLE_FIELD_PROPERTY,
    PLAN_DOCUMENTS_FIELD_PROPERTY,
    PREMIUMS_FIELD_PROPERTY,
    SERVICES_FIELD_PROPERTY,
    MONTHLY_PREMIUM_PROPERTY,
    ADDITIONAL_SERVICES_FIELD_PROPERTY,
  ]);

  if (STD_LTD_PLANS.includes(benefitType)) {
    titlePropertiesSet.add(VOLUME_FIELD_PROPERTY);
    titlePropertiesSet.add(ADMIN_FEE);
  }

  if (BenefitCategory.VISION.value === benefitType) {
    titlePropertiesSet.add(ADDITIONAL_SERVICES_FIELD_PROPERTY);
  }

  return titlePropertiesSet.has(property);
};

/**
 * Checks if a given property is a single column property.
 * @param {string} property - The property to check.
 * @return {boolean} `true` if the property is a single column property, `false` otherwise.
 */
export const isSingleColumnProperty = (property: string): boolean => {
  const singleColumnProperties = new Set([
    DEDUCTIBLES_FIELD_PROPERTY,
    OOP_FIELD_PROPERTY,
    OUT_NETWORK_OOP_FIELD_PROPERTY,
    OUT_NETWORK_DEDUCTIBLE_FIELD_PROPERTY,
    RX_DEDUCTIBLE_FIELD_PROPERTY,
    RX_OOP_FIELD_PROPERTY,
    OUT_NETWORK_RX_DEDUCTIBLE_PROPERTY,
    OUT_NETWORK_RX_OOP_PROPERTY,
  ]);
  return singleColumnProperties.has(property);
};

/**
 * Checks if a given property belongs to a set of predefined object properties.
 * @param {string} property The property to check.
 *
 * @return {boolean} True if the property belongs to the predefined object properties, false otherwise.
 */
export const isObjectProperties = (property: string): boolean => {
  const objectProperties = new Set([
    ADMIN_FEE,
    INDIVIDUAL_STOP_LOSS_FEE_FIELD,
    INDIVIDUAL_STOP_LOSS_FIELD,
    AGGREGATE_STOP_LOSS_FEE_FIELD,
    AGGREGATE_STOP_LOSS_FIELD,
    THIRD_PARTY_ADMIN_FEE_FIELD,
    OTHER_FEES_FIELD,
    ANNUAL_ESTIMATED_CLAIMS,
    TOTAL_ELIGIBLE_EMPLOYEES,
    VOLUME_FIELD_PROPERTY,
    LIFE_ADD_BENEFIT_FIELD_PROPERTY,
    AGE_REDUCTION_FIELD_PROPERTY,
    DEFINITION_OF_DISABILITY_FIELD_PROPERTY,
    OWN_OCCUPATION_PERIOD_FIELD_PROPERTY,
    MAX_MONTHLY_BENEFIT_FIELD_PROPERTY,
    MULTIPLIER_FIELD_PROPERTY,
    FLAT_AMOUNT_FIELD_PROPERTY,
    BENEFIT_FIELD_PROPERTY,
  ]);
  return objectProperties.has(property);
};

/**
 * Formats the label for a property with tier names and Rx tier information.
 * @param {string} property - The property to format the label for.
 * @param {string[]} tierNames - An array of tier names.
 * @param {boolean} isRxTier - A boolean indicating if it is an Rx tier.
 * @return {string} The formatted label.
 */
export const formatLabel = (
  property: string,
  tierNames: string[],
  isRxTier: boolean
) => {
  const tierLabel =
    tierNames.length && isRxTier
      ? `Tier ${tierNames.join('/')}`
      : tierNames.join('/');
  const label = `${PLAN_TABLE_INITIAL_TITLES[property] || property} ${
    tierLabel ? `(${tierLabel})` : ''
  }`;

  return label.replaceAll(',', '/');
};

/**
 * Formats the value for the plan type based on the payload and property.
 * @param {any} payload - The payload object containing the plan type value. because hard to determine the type of payload
 * @param {string} property - The property name for the plan type value in the payload.
 * @return {string} The formatted plan type value as a string.
 */
export const formatValueForPlanType = (
  payload: any,
  property: string
): string => {
  const EXCLUDE_CAPITALZATION = ['OTHER'];
  const capitalized = payload[property]?.toUpperCase();
  const planType =
    formatEmptyValues(payload[property]) === undefined
      ? ''
      : EXCLUDE_CAPITALZATION.includes(capitalized)
      ? payload[property]
      : capitalized;
  const isHSA = payload?.hsaCompatible;
  const isHRA = payload?.hraCompatible;
  const isHSAAndHRA = isHSA && isHRA;

  if (isHSAAndHRA) return `${planType} HSA & HRA`;
  if (isHSA) return `${planType} HSA`;
  if (isHRA) return `${planType} HRA`;
  return `${planType}`;
};

/**
 * Formats empty values to undefined.
 * @param {any} value - The value to be formatted. any because it can be any type
 * @return {string} The formatted value. If the value is null, undefined, an empty string, or the string 'null', it will be converted to undefined.
 */
export const formatEmptyValues = (value: any) =>
  [null, undefined, '', 'null'].includes(String(value)) ? undefined : value;

/**
 * Formats the funding type value.
 *
 * @param {FundingType} value - The funding type value.
 * @return {string} The formatted funding type value.
 */
const formatFundingTypeValue = (value: FundingType): string => {
  switch (value) {
    case FundingType.SELF_FUNDED:
      return 'Self Funded';
    case FundingType.LEVEL_FUNDED:
      return 'Level Funded';
    case FundingType.FULLY_INSURED:
      return 'Fully Insured';
    default:
      return 'Fully Insured';
  }
};

/**
 * Formats the given value to currency or free text based on the property.
 * @param {string} property - The property name.
 * @param {string} value - The value to be formatted.
 * @return {string} The formatted value as a string.
 */
export const formatValuesToCurrencyOrFreeText = (
  property: string,
  value: string | null | undefined
): string => {
  const defaultValue = '-';
  switch (property) {
    case PREMIUMS_FIELD_PROPERTY:
    case ADMIN_FEE:
    case INDIVIDUAL_STOP_LOSS_FIELD:
    case INDIVIDUAL_STOP_LOSS_FEE_FIELD:
    case AGGREGATE_STOP_LOSS_FIELD:
    case AGGREGATE_STOP_LOSS_FEE_FIELD:
    case THIRD_PARTY_ADMIN_FEE_FIELD:
    case OTHER_FEES_FIELD:
    case VOLUME_FIELD_PROPERTY:
    case ANNUAL_ESTIMATED_CLAIMS:
    case MONTHLY_PREMIUM_PROPERTY:
    case ANNUAL_PREMIUM_PROPERTY:
    case FROM_CURRENT_PROPERTY:
      return formatEmptyValues(value)
        ? currencyFormatterWithoutZeros(
            removeCurrencySymbols(formatEmptyValues(value!))
          )
        : defaultValue;
    case BASIC_ADD_RATE_FIELD_PROPERTY:
      return formatEmptyValues(value)
        ? `${removeCurrencySymbols(formatEmptyValues(value!))}`
        : defaultValue;
    case FUNDING_TYPE:
      return formatFundingTypeValue(value as FundingType);
    default:
      return formatEmptyValues(value) || defaultValue;
  }
};

/**
 * Validates the fields of the plan table.
 * @param {string} property - The property to validate.
 * @param {string} value - The value to validate.
 * @return {boolean} - Returns true if the fields are valid, false otherwise.
 */
export const validatePlanTableFields = (
  property: string,
  value: string
): boolean => {
  const isValueDefault = value === '-';

  if (
    [
      PLAN_TYPE_FIELD_PROPERTY,
      PREMIUMS_FIELD_PROPERTY,
      ENROLLMENT_FIELD_PROPERTY,
      ADMIN_FEE,
      ...NON_MDV_REQUIRED_FIELDS,
    ].includes(property)
  ) {
    if (isValueDefault) {
      return true;
    }

    if (
      [
        PREMIUMS_FIELD_PROPERTY,
        ENROLLMENT_FIELD_PROPERTY,
        ADMIN_FEE,
        ...NON_MDV_REQUIRED_FIELDS,
      ].includes(property)
    ) {
      value = value?.replace('$', '').replaceAll('.', '').replaceAll(',', '');
      return !/^\d+$/.test(value);
    }
  }

  return false;
};

/**
 * Validates the life rate error based on the given property, value, and insurance type.
 * @param {string} property - The property to validate.
 * @param {string} value - The value to validate.
 * @param {string} insuranceType - The insurance type to validate.
 * @return {boolean} A boolean indicating whether the life rate error is valid or not.
 */
export const validateLifeRateError = (
  property: string,
  value: string,
  insuranceType: string
) => {
  return (
    [
      LIFE_RATE_FIELD_PROPERTY,
      BASIC_ADD_RATE_FIELD_PROPERTY,
      VOLUME_FIELD_PROPERTY,
    ].includes(property) &&
    value === '-' &&
    ((property === LIFE_RATE_FIELD_PROPERTY &&
      [BASIC_LIFE, BASIC_LIFE_AND_ADD, null, STD, LTD].includes(
        insuranceType
      )) ||
      (property === BASIC_ADD_RATE_FIELD_PROPERTY &&
        [BASIC_ADD, BASIC_LIFE_AND_ADD, null].includes(insuranceType)))
  );
};

/**
 * Checks if a given string represents a numeric value.
 * @param {string} value - The string value to check.
 * @return {boolean} - Returns true if the value is numeric, false otherwise.
 */
export const isNumeric = (value: string): boolean => {
  return value !== '' && !isNaN(Number(value));
};

/**
 * Adds dollar signs to each element in an array if the element represents a numeric value.
 * If the element is not numeric, it adds a dollar sign before the first occurrence of '/'.
 * @param {string[]} array - The array of strings to process.
 * @return {string[]} - Returns an array of strings with dollar signs added.
 */
export const addDollarSignsForArray = (array: string[]): string[] => {
  return array.map((item) => {
    if (!isNaN(Number(item))) {
      return `$${item.trimStart()}`;
    } else {
      return item.replace('/ ', '/ $');
    }
  });
};

/**
 * Retrieves the longest title from an array of objects based on a specified title property.
 * @param {Object[]} initialData An array of objects.
 * @param {string} titleProperty The property name containing the title within each object.
 * @return {string} The longest title found in the array of objects.
 */
export const getLongestTitle = (
  initialData: any,
  titleProperty: string
): string => {
  let length = 0;
  let value = '';
  initialData?.map((item: any) => {
    if (item?.[titleProperty]?.length > length) {
      length = item?.[titleProperty]?.length;
      value = item?.[titleProperty];
    }
  });
  return value;
};

/**
 * Scrolls to the rightmost position within a scrollable element.
 * @param {WidthRef} widthRef Reference to the scrollable element.
 */
export const scrollToRight = (widthRef: WidthRef): void => {
  if (widthRef?.current) {
    const divElement = widthRef?.current;
    const scrollWidth = divElement?.scrollWidth;
    divElement.scrollLeft = scrollWidth;
  }
};

/**
 * Checks if at least one plan contains an "in network" additional service
 * with a value for any of the specified additional service names.
 *
 * @param {Plan[]} plans - An array of Plan objects to be checked.
 * @param {string[]} additionalServiceNames - An array of additional service names to look for in the plans.
 * @return {boolean} - Returns true if at least one plan contains a specified "in network" additional service with a value. Otherwise, returns false.
 */

const getInNetworkAdditionalServiceValueExistInAtLeastOnePlan = (
  plans: Plan[],
  additionalServiceNames: string[]
) => {
  return additionalServiceNames.some((additionalServiceName) =>
    plans?.some((plan: Plan) => {
      const service = plan?.additionalServices?.find(
        (service: ServiceArrayItemTemplate) =>
          service?.name === additionalServiceName &&
          service?.fieldType === IN_NETWORK_VALUE
      );

      const value = service?.value;

      return value && !['', 'null', null, undefined].includes(value);
    })
  );
};
