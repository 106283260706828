import { DeepPartial } from '@reduxjs/toolkit';
import { cloneDeep, isEmpty, size } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  PLAN_TYPE_LABEL_MAP,
  STANDARD_TIER_KEYS,
} from 'modules/renewals/constants/addToPlanYearConstants';
import { TIER_TYPES } from 'modules/renewals/constants/renewalsConstants';

import ProposalSummaryVO, {
  PlanContributionVO,
  ProposalContributionVO,
  VolLifeSummaryVO,
} from 'model/ProposalSummaryVO';
import {
  IAddProposalToPlanYearState,
  TransformedPlan,
} from 'modules/renewals/types/renewalsTypes';
import { RenewalPlanDto } from 'modules/renewals/models/RenewalPlanDto';
import { PlanYearDto } from 'modules/renewals/models/PanYearDto';
import PremiumType from 'modules/renewals/enums/PremiumType';

const transformPlans = (
  planContributions: PlanContributionVO[],
  benefit: 'MEDICAL' | 'VISION' | 'DENTAL' | 'LIFE_AND_DISABILITY'
): DeepPartial<TransformedPlan>[] => {
  const result: DeepPartial<TransformedPlan>[] = [];
  planContributions.forEach((plan) => {
    const isStandardTier = Object?.keys(
      plan?.tierContributionsDefault ?? []
    )?.some((r) => STANDARD_TIER_KEYS?.includes(r));
    result.push({
      id: plan?.planId ?? '',
      benefit: benefit,
      isReviewed: false,
      planName: plan?.planName ?? '',
      mergablePlans: [],
      isMerged: false,
      planType:
        benefit !== 'LIFE_AND_DISABILITY'
          ? (plan?.planType as keyof typeof PLAN_TYPE_LABEL_MAP)
          : (plan?.insuranceType as keyof typeof PLAN_TYPE_LABEL_MAP),
      premiumType:
        benefit !== 'LIFE_AND_DISABILITY'
          ? isStandardTier
            ? PremiumType.STANDARD_TIER
            : PremiumType.N_TIER
          : null,
      tierCount:
        benefit !== 'LIFE_AND_DISABILITY'
          ? isStandardTier
            ? 4
            : Object?.keys(plan?.tierContributionsDefault ?? [])?.length ?? 0
          : 0,
      initialPlanId: plan?.planId,
      hraPlanId: plan?.hraCompatible ? uuidv4() : undefined,
      volume: plan?.tierContributionsDefault?.EE?.volume,
      enrollment:
        benefit === 'LIFE_AND_DISABILITY'
          ? plan?.tierContributionsDefault?.EE?.totalEligibleEmployees
          : plan?.tierContributionsDefault,
      planContributionId: plan?.id,
    });
  });
  return result;
};

const transformVolLifePlans = (
  planContributions: VolLifeSummaryVO[]
): DeepPartial<TransformedPlan>[] => {
  const result: DeepPartial<TransformedPlan>[] = [];
  planContributions.forEach((plan) => {
    result.push({
      id: plan?.id ?? '',
      benefit: 'VOLUNTARY_BENEFIT',
      isReviewed: false,
      planName: plan?.name ?? '',
      mergablePlans: [],
      isMerged: false,
      planType: plan?.insuranceType as keyof typeof PLAN_TYPE_LABEL_MAP,
    });
  });
  return result;
};

export const transformProposalSummary = (
  proposal: ProposalSummaryVO
): IAddProposalToPlanYearState['plans'] => {
  const result: IAddProposalToPlanYearState['plans'] = {
    MEDICAL: [],
    DENTAL: [],
    VISION: [],
    LIFE_AND_DISABILITY: [],
    VOLUNTARY_BENEFIT: [],
    TAX_ADVANTAGE_ACCOUNTS: [],
  };

  const map = {
    MEDICAL: ['MEDICAL'],
    DENTAL: ['DENTAL'],
    VISION: ['VISION'],
    LIFE_AND_DISABILITY: ['LIFE', 'LTD', 'STD'],
    VOLUNTARY_BENEFIT: ['VOLUNTARY_BENEFIT'],
  };

  Object.entries(map).forEach(([benefit, categories]) => {
    proposal.bundleContributions
      ?.filter((contribution) =>
        categories.includes(contribution?.category || '')
      )
      ?.forEach((contribution) => {
        const category = benefit as keyof typeof result;
        if (category === 'VOLUNTARY_BENEFIT') {
          result[category].push(
            ...transformVolLifePlans(contribution?.volLifePlans ?? [])
          );
        } else if (category !== 'TAX_ADVANTAGE_ACCOUNTS') {
          result[category].push(
            ...transformPlans(contribution?.planContributions ?? [], category)
          );
        }
      });
  });

  proposal.bundleContributions?.forEach(
    (proposalContributions: ProposalContributionVO) => {
      proposalContributions.planContributions?.forEach(
        (plan: PlanContributionVO) => {
          if (plan?.hraId && plan?.hraCompatible) {
            let tierCountTaxAdv;
            const tierContributions = plan?.tierContributionsDefault || {};
            if (tierContributions.hasOwnProperty('EC')) {
              tierCountTaxAdv = 0;
            } else {
              tierCountTaxAdv = size(tierContributions);
            }
            const taxObj: DeepPartial<TransformedPlan> = {
              benefit: 'TAX_ADVANTAGE_ACCOUNTS',
              isMerged: false,
              isReviewed: false,
              planName: plan?.planName + ' HRA' ?? '',
              mergablePlans: [],
              planType: 'HRA',
              taxAdvId: plan?.hraId ?? '',
              parentId: plan.planId,
              employerContribution: {
                employerEEContribution:
                  plan.tierContributionsDefault?.EE?.hraFaceValue,
                employerESContribution:
                  plan.tierContributionsDefault?.ES?.hraFaceValue,
                employerECContribution:
                  plan.tierContributionsDefault?.EC?.hraFaceValue,
                employerEFContribution:
                  plan.tierContributionsDefault?.EF?.hraFaceValue,
                employerEE1Contribution:
                  plan.tierContributionsDefault?.EE1?.hraFaceValue,
                employerEE2Contribution:
                  plan.tierContributionsDefault?.EE2?.hraFaceValue,
                employerEE3Contribution:
                  plan.tierContributionsDefault?.EE3?.hraFaceValue,
                employerEE4Contribution:
                  plan.tierContributionsDefault?.EE4?.hraFaceValue,
                employerEE5Contribution:
                  plan.tierContributionsDefault?.EE5?.hraFaceValue,
                employerEE6Contribution:
                  plan.tierContributionsDefault?.EE6?.hraFaceValue,
                frequency: 'ANNUALLY',
              },
              tempId: uuidv4(),
              category: proposalContributions?.category,
              parentPlan: plan,
              tierCount: tierCountTaxAdv,
              initialPlanId: plan?.planId,
              hraUtilization: plan?.hraUtilization,
            };
            result['TAX_ADVANTAGE_ACCOUNTS'].push(taxObj);
          }
          if (
            proposalContributions.category === 'MEDICAL' &&
            plan.hsaId &&
            plan?.hsaCompatible
          ) {
            let tierCountTaxAdv;
            const tierContributions = plan?.tierContributionsDefault || {};
            if (tierContributions.hasOwnProperty('EC')) {
              tierCountTaxAdv = 0;
            } else {
              tierCountTaxAdv = size(tierContributions);
            }
            const taxObj: DeepPartial<TransformedPlan> = {
              benefit: 'TAX_ADVANTAGE_ACCOUNTS',
              isMerged: false,
              isReviewed: false,
              planName: plan?.planName + ' HSA' ?? '',
              mergablePlans: [],
              planType: 'HSA',
              taxAdvId: plan?.hsaId ?? '',
              parentId: plan.planId,
              employerContribution: {
                employerEEContribution: Number(
                  plan.tierContributionsDefault?.EE?.hsaContribution
                ),
                employerESContribution: Number(
                  plan.tierContributionsDefault?.ES?.hsaContribution
                ),
                employerECContribution: Number(
                  plan.tierContributionsDefault?.EC?.hsaContribution
                ),
                employerEFContribution: Number(
                  plan.tierContributionsDefault?.EF?.hsaContribution
                ),
                employerEE1Contribution: Number(
                  plan.tierContributionsDefault?.EE1?.hsaContribution
                ),
                employerEE2Contribution: Number(
                  plan.tierContributionsDefault?.EE2?.hsaContribution
                ),
                employerEE3Contribution: Number(
                  plan.tierContributionsDefault?.EE3?.hsaContribution
                ),
                employerEE4Contribution: Number(
                  plan.tierContributionsDefault?.EE4?.hsaContribution
                ),
                employerEE5Contribution: Number(
                  plan.tierContributionsDefault?.EE5?.hsaContribution
                ),
                employerEE6Contribution: Number(
                  plan.tierContributionsDefault?.EE6?.hsaContribution
                ),
                frequency: 'ANNUALLY',
              },
              tempId: uuidv4(),
              category: proposalContributions?.category,
              parentPlan: plan,
              tierCount: tierCountTaxAdv,
              initialPlanId: plan?.planId,
            };
            result['TAX_ADVANTAGE_ACCOUNTS'].push(taxObj);
          }
        }
      );
    }
  );
  return result;
};

export const buildRatesForReview = (
  benefitClass: string[],
  plan: TransformedPlan
) => {
  const newObj: any = {};
  const isStandardTier = plan?.premiumType === PremiumType.STANDARD_TIER;
  benefitClass?.map((item: string) => {
    newObj[item] = {};
    newObj[item].frequency = 'MONTHLY';
    newObj[item].type = isStandardTier ? 'FOUR_TIER' : 'N_TIER';
    newObj[item].contributionType = 'FIXED';
    if (isStandardTier) {
      newObj[item].fourTierContributions = {};
      newObj[item].fourTierContributions.contributions = [
        {
          tierName: 'EE',
        },
        {
          tierName: 'EC',
        },
        {
          tierName: 'ES',
        },
        {
          tierName: 'EF',
        },
      ];
    } else {
      newObj[item].ntierContributions = {};
      let nTierList = [] as any[];
      for (let index = 0; index < (plan?.tierCount ?? 0); index++) {
        nTierList = [
          ...nTierList,
          {
            tierName: index === 0 ? 'EE' : `EE_PLUS_${index}`,
          },
        ];
      }
      newObj[item].ntierContributions.contributions = nTierList;
    }
  });
  return newObj;
};

// Get applicable benefit classes
const retrieveBenefitClasses = (benefitClasses: any): any[] => {
  return benefitClasses.data.map((data: any) => ({
    id: data.value,
    name: data.label,
  }));
};

const transformDocumentReferences = (documentReferences: any): any => {
  const transformedReferences: any = {};

  if (documentReferences !== null && documentReferences !== undefined) {
    Object.keys(documentReferences).forEach((obj: any) => {
      const ref = documentReferences[obj];
      transformedReferences[obj] = {
        reference: ref.reference,
        // TODO - confirm use of this
        documentUploadStatus: 'ADDED',
        fileName: ref.fileName,
      };
    });
  }

  return transformedReferences;
};

const notCoveredFixedInServices = (dataArray: any[]) => {
  const newArray = dataArray?.map((item) => {
    return {
      ...item,
      serviceValue: {
        inNetwork: !isEmpty(item.serviceValue.inNetwork)
          ? {
              ...item.serviceValue.inNetwork,
              ...(item.serviceValue.inNetwork.costSharingPolicy
                ? {
                    benefitCovered:
                      item.serviceValue.inNetwork.costSharingPolicy ===
                      'NOT_COVERED'
                        ? 'NO'
                        : item.serviceValue.inNetwork?.benefitCovered,
                  }
                : {}),
            }
          : {},
        outOfNetwork: !isEmpty(item.serviceValue.outOfNetwork)
          ? {
              ...item.serviceValue.outOfNetwork,
              ...{
                benefitCovered:
                  item.serviceValue.outOfNetwork.costSharingPolicy ===
                  'NOT_COVERED'
                    ? 'NO'
                    : item.serviceValue.outOfNetwork?.benefitCovered,
              },
            }
          : {},
      },
    };
  });
  return newArray;
};

const notCoveredFixedInRxAndMailOrder = (dataObj: any) => {
  for (const tier in dataObj?.inNetwork) {
    if (dataObj?.inNetwork[tier]?.costSharingPolicy === 'NOT_COVERED') {
      dataObj.inNetwork[tier].benefitCovered = 'NO';
    }
  }
  for (const tier in dataObj?.outOfNetwork) {
    if (dataObj?.outOfNetwork[tier]?.costSharingPolicy === 'NOT_COVERED') {
      dataObj.outOfNetwork[tier].benefitCovered = 'NO';
    }
  }
  return dataObj;
};

// transform reviewed plans separately
const transformReviewedPlansBasicCategory = (
  benefitPlanList: {
    id?: string;
    plan?: any;
    planType?: string;
    benefit?: string;
  }[]
): any[] => {
  return benefitPlanList
    .filter((obj) => obj.hasOwnProperty('plan'))
    .map(({ id, plan, planType, benefit }) => {
      let copyPlans;
      if (['MEDICAL'].includes(benefit || '')) {
        copyPlans = cloneDeep(plan);
        copyPlans.customServices = notCoveredFixedInServices(
          copyPlans.customServices
        );
        copyPlans.rxCosts = notCoveredFixedInRxAndMailOrder(copyPlans.rxCosts);
        copyPlans.mailOrderRxCosts = notCoveredFixedInRxAndMailOrder(
          copyPlans.mailOrderRxCosts
        );
      } else if (['DENTAL', 'VISION'].includes(benefit || '')) {
        copyPlans = cloneDeep(plan);
        copyPlans.customServices = notCoveredFixedInServices(
          copyPlans.customServices
        );
      } else {
        copyPlans = { ...plan, id: id };
      }

      return {
        ...copyPlans,
        documentReferences: transformDocumentReferences(
          plan?.documentReferences ?? []
        ),
        benefitKind: planType,
      };
    });
};

const transformReviewedPlansTaxadv = (
  benefitPlanList: { plan?: any; planType?: string; benefit?: string }[]
): any[] => {
  return benefitPlanList
    .filter((obj) => obj.hasOwnProperty('plan'))
    .map(({ plan }) => {
      const copyPlans = plan;
      return {
        ...copyPlans,
        documentReferences: transformDocumentReferences(
          plan?.documentReferences ?? []
        ),
        benefitKind: copyPlans.benefitKind,
      };
    });
};

// transform not reviewed plans separately
const transformNotReviewedPlansBasicCategory = (
  benefitPlanList: DeepPartial<TransformedPlan>[]
): any[] => {
  return benefitPlanList
    .filter((obj) => !obj.hasOwnProperty('plan'))
    .map((item) => ({
      offerPlanId: item?.id,
      mergePlanId: item?.mergedPlanId || null,
      benefitKind: item?.planType,
      initialPlanId: item?.id,
      planContributionId: item?.planContributionId,
    }));
};

const transformNotReviewedPlansBasicCategoryTaxAdv = (
  benefitPlanList: DeepPartial<TransformedPlan>[]
): any[] => {
  return benefitPlanList
    .filter((obj) => !obj.hasOwnProperty('plan'))
    .map((item) => ({
      offerPlanId: item?.taxAdvId,
      mergePlanId: item?.mergedPlanId || null,
      benefitKind: item?.benefit,
      initialPlanId: item?.initialPlanId,
      planName: item?.planName,
      hraUtilization: item?.hraUtilization,
    }));
};

export const transformToFinalizedRenewalList = (
  basicInfo: IAddProposalToPlanYearState['basicDetails'],
  proposalSummary: ProposalSummaryVO & { employerId?: string },
  plans: IAddProposalToPlanYearState['plans']
): RenewalPlanDto => {
  // create the plan  year dto
  const { effectiveStartDate, effectiveEndDate, employerId } = proposalSummary;
  const { planYearName, benefitClasses } = basicInfo;

  const planYearVo: PlanYearDto = {
    name: planYearName ?? '',
    startDate: effectiveStartDate,
    endDate: effectiveEndDate,
    employerId: employerId ?? '',
    benefitGroups: benefitClasses?.data?.map((item: any) => item?.label) ?? [],
    benefitClasses: retrieveBenefitClasses(benefitClasses),
  };

  // Need to put vol-life plans in life plans list for saving to work
  const lifePlans: DeepPartial<TransformedPlan>[] = [];
  const voluntaryPlans: DeepPartial<TransformedPlan>[] = [];
  plans.VOLUNTARY_BENEFIT.forEach((plan) => {
    if (isVolLifePlan(plan.planType)) {
      lifePlans.push(plan);
    } else {
      voluntaryPlans.push(plan);
    }
  });
  lifePlans.push(...plans.LIFE_AND_DISABILITY);

  // create mdv
  const finalizedRenewalPlanList: RenewalPlanDto = {
    planYearVO: planYearVo,
    medicalPlans: transformReviewedPlansBasicCategory(plans.MEDICAL),
    dentalPlans: transformReviewedPlansBasicCategory(plans.DENTAL),
    visionPlans: transformReviewedPlansBasicCategory(plans.VISION),
    lifePlans: transformReviewedPlansBasicCategory(lifePlans),
    voluntaryBenefitPlans: transformReviewedPlansBasicCategory(voluntaryPlans),
    taxAdvantagePlans: transformReviewedPlansTaxadv(
      plans.TAX_ADVANTAGE_ACCOUNTS
    ),
    medicalPlansToReview: transformNotReviewedPlansBasicCategory(plans.MEDICAL),
    dentalPlansToReview: transformNotReviewedPlansBasicCategory(plans.DENTAL),
    visionPlansToReview: transformNotReviewedPlansBasicCategory(plans.VISION),
    lifePlansToReview: transformNotReviewedPlansBasicCategory(lifePlans),
    voluntaryBenefitPlansToReview:
      transformNotReviewedPlansBasicCategory(voluntaryPlans),
    medicalPlanPremiumType:
      plans?.MEDICAL?.[0]?.premiumType ?? TIER_TYPES.STANDARD_TIER,
    dentalPlanPremiumType:
      plans?.DENTAL?.[0]?.premiumType ?? TIER_TYPES.STANDARD_TIER,
    visionPlanPremiumType:
      plans?.VISION?.[0]?.premiumType ?? TIER_TYPES.STANDARD_TIER,
    taxAdvantagePlansToReview: transformNotReviewedPlansBasicCategoryTaxAdv(
      plans.TAX_ADVANTAGE_ACCOUNTS
    ),
    selectedProposalId: proposalSummary.proposalId ?? '',
  };

  return finalizedRenewalPlanList;
};

export const isVolLifePlan = (benefitKind?: string) =>
  [
    'VOLUNTARY_ADD',
    'VOLUNTARY_LIFE',
    'VOLUNTARY_LIFE_ADD',
    'VOLUNTARY_STD',
    'VOLUNTARY_LTD',
  ].includes(benefitKind ?? '');
