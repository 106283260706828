import { Col, Row } from 'antd';
import { ReactComponent as DashboardNoData } from 'assets/images/dashboardUtils/no-data-issue-icon.svg';
import styles from './NoIssueVelocity.module.less';
const NoApplicableAnalytics = () => {
  return (
    <Row className={styles.emptyIssue}>
      <Col xl={14}>
        <DashboardNoData />
        <p className={styles.emptyIssueText}>
          There are no account analytics yet
        </p>
        <p className={styles.emptyIssueParaText}>
          Be sure to check back after items are logged into the system.
        </p>
      </Col>
    </Row>
  );
};
export default NoApplicableAnalytics;
