import { FC, useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Popconfirm, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import iconWarning from 'assets/images/icon-warning.svg';
import { uploadSBC } from 'modules/plans/slices/medicalPlanSlice';
import { MedicalPlanDocumentType } from 'modules/plans/constants';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { FORMAT_VALIDATE, SIZE_VALIDATE } from 'util/commonUtil';
import styles from './planDocumentUploader.module.less';

type PlanDocumentUploaderProps = {
  onChange: Function;
  onRemove: Function;
  onFileNameClick: Function;
  allowedFileTypes?: string;
  maxFileSizeMB: number;
  isLoading?: boolean;
  isRemoved?: boolean;
  file?: File;
  docType: string;
  onValidateFails?: (validateSetting: string) => void;
  isDisable?: boolean;
  isSBC?: boolean;
  isDisableFileSelection?: boolean;
  isAFPReview?: boolean;
};
const emptyFile = new File([''], '');

const OVERWRIRE = 'OVERWRIRE';

const PlanDocumentUploader: FC<PlanDocumentUploaderProps> = (
  props: PlanDocumentUploaderProps
) => {
  const plan = useSelector((state: any) => state.plan.plans);
  const { cancelDocumentUpload } = plan;
  const [file, setFile] = useState<File>(emptyFile);

  const fileInput = useRef<HTMLInputElement>(null);

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  const [lastSelectOption, setLastSelectOption] = useState<string>('');

  const dispatch = useDispatch();

  const {
    onChange,
    onRemove,
    onFileNameClick,
    allowedFileTypes,
    maxFileSizeMB,
    isLoading = false,
    isRemoved = false,
    docType,
    onValidateFails,
    isDisable = false,
    isSBC = false,
    isDisableFileSelection = false,
    isAFPReview = false,
  } = props;

  useEffect(() => {
    if (isRemoved || cancelDocumentUpload) {
      setFile(emptyFile);
    }
  }, [file.name, isSBC, props.file, isRemoved, cancelDocumentUpload]);

  useEffect(() => {
    if (props.file && !file.name && !cancelDocumentUpload) {
      setFile(props.file);
    }
  }, [file.name, props.file, cancelDocumentUpload]);

  useEffect(() => {
    if (isSBC && props.file && !cancelDocumentUpload) {
      props.file.name !== file.name && setFile(props.file);
    }
  }, [file.name, isSBC, props.file, cancelDocumentUpload]);

  const uploadFile = (): void => {
    if (fileInput.current !== null) {
      fileInput.current.click();
    }
  };

  const onSelectDocument = (event: any): void => {
    const file = event.target.files[0];
    const isallowedFileTypes = file.type === allowedFileTypes;
    if (!isallowedFileTypes) {
      if (onValidateFails) {
        onValidateFails(FORMAT_VALIDATE);
      }
      return;
    }
    const isallowedFileSize = file.size / 1024 / 1024 < maxFileSizeMB;
    if (!isallowedFileSize) {
      if (onValidateFails) {
        onValidateFails(SIZE_VALIDATE);
      }
      return;
    }
    if (!file) {
      return;
    } else {
      setFile(file);
      onChange(file);
      if (OVERWRIRE === lastSelectOption) {
        const data = new FormData();
        data.append('file', file);
        dispatch(uploadSBC(data));
        // TODO: Change this
      }
      setLastSelectOption('');
    }
  };

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

  return (
    <div className={styles.documentUploaderWrapper}>
      {MedicalPlanDocumentType.SBC.value === docType && !isAFPReview ? (
        <Popconfirm
          icon={
            <img
              src={iconWarning}
              alt="warning-icon"
              className={styles.iconWarning}
            />
          }
          onConfirm={() => {
            setLastSelectOption('');
            uploadFile();
          }}
          onCancel={() => {
            setLastSelectOption(OVERWRIRE);
            uploadFile();
          }}
          placement="bottomRight"
          okText="Only upload new SBC file (do not overwrite data)"
          cancelText="Upload new SBC file and overwrite any existing data"
          overlayClassName={'planDocumentUploaderWrapper'}
          title={
            <div>
              <div className="title">
                Do you want to upload a new SBC file and import new data?
              </div>
              <div className="seconday-text">
                Importing new plan data will overwrite any existing information
                in:
              </div>
              <ul className="list">
                <li>Deductibles & OOP Max</li>
                <li>Medical Services</li>
                <li>RX</li>
              </ul>
            </div>
          }
        >
          <PageActionButton
            className={styles.uploadButton}
            onClick={() => {
              setConfirmModalOpen(!confirmModalOpen);
            }}
            disabled={isDisable || isDisableFileSelection}
          >
            + Choose File
          </PageActionButton>
        </Popconfirm>
      ) : (
        <PageActionButton
          className={styles.uploadButton}
          onClick={uploadFile}
          disabled={isDisable || isDisableFileSelection}
        >
          + Choose File
        </PageActionButton>
      )}

      <div className={styles.fileLabel}>
        {isLoading && (
          <>
            <Spin indicator={antIcon} /> &nbsp;
          </>
        )}
        {file.name && !isLoading && (
          <div
            onClick={() => onFileNameClick(file)}
            className={`${styles.fileNameArea} uploadedFileName`}
          >
            {file.name}
          </div>
        )}
      </div>

      <div className={styles.removeFile}>
        {isEmpty(file.name) ? (
          <span className="text label-disabled">No File Selected</span>
        ) : (
          !isLoading &&
          !isDisable &&
          !isDisableFileSelection && (
            <ConfirmationModal
              title={
                <div className={styles.cancelUploadBody}>
                  Are you sure you want to remove this file?
                  <p>
                    Removing the file will not affect any plan information, but
                    it will no longer be available to download from a Digital
                    Benefits Guide.
                  </p>
                </div>
              }
              icon={
                <img
                  src={iconWarning}
                  alt="warning-icon"
                  className={styles.iconWarning}
                />
              }
              confirmModalTrigger="(remove)"
              onConfirm={() => onRemove()}
              okText="Yes, remove file"
              placement="topLeft"
            />
          )
        )}
      </div>
      <input
        type="file"
        name="file"
        onClick={onInputClick}
        onChange={onSelectDocument}
        ref={fileInput}
        accept={allowedFileTypes}
        hidden
      />
    </div>
  );
};

export default PlanDocumentUploader;
