import { Popover } from 'antd';
import { ReactComponent as Recommended } from 'assets/images/recommended.svg';
import { RECOMMENDED_OFFER } from 'modules/renewals/constants/renewalsConstants';
import styles from './recommendedTooltip.module.less';

const RecommendedTooltip = () => {
  return (
    <span className={styles.recommenderWrapper}>
      <Popover
        content={RECOMMENDED_OFFER}
        overlayClassName={'popoverInner recommendedtooltip'}
      >
        <Recommended />
      </Popover>
    </span>
  );
};

export default RecommendedTooltip;
