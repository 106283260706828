import React from 'react';
import styles from './bouncingDots.module.less';
type Props = {};

const BouncingDots = (props: Props) => {
  return (
    <div className={styles.bouncingLoaderWrapper}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default BouncingDots;
