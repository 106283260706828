import { FC, useState } from 'react';
import { Col, Divider, Form, Input, Row, Select } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import isEmpty from 'lodash/isEmpty';

import { useAppDispatch } from 'hooks/redux';
import {
  useLazyGetBrokerLevelDisabledLocationsQuery,
  useLazyGetUserCountForReassignLocationQuery,
} from 'api/featureControl';

import {
  deleteLocationAndReassign,
  clearBrokerLocationDeletion,
} from 'modules/brokers/slices/brokerLocationSlice';

import InputForm from 'components/InputForm/InputForm';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';

import {
  EMPTY_MESSAGE,
  deleteBrokerLocationFields,
} from 'modules/brokers/constants/brokerConstants';
import EmployerLocationChangeModal from 'modules/tools/components/EmployerLocationChangeModal/EmployerLocationChangeModal';

import LocationReassign from 'model/LocationReassign';
import EmployerLocation from 'model/EmployerLocation';

import styles from './removeBrokerLocation.module.less';

const { Option } = Select;

type RemoveBrokerLocationProps = {
  filterdLocations: Array<EmployerLocation>;
  closeModal: () => void;
  removableLocationId: string;
  brokerId: string | null;
  removableLocationName: string;
  isRfpEnabled?: boolean;
  isTechAdmin?: boolean;
};

const RemoveBrokerLocation: FC<RemoveBrokerLocationProps> = (
  props: RemoveBrokerLocationProps
) => {
  const {
    filterdLocations,
    closeModal,
    removableLocationId,
    brokerId,
    removableLocationName,
    isRfpEnabled,
    isTechAdmin,
  } = props;
  const [isLocationSaveModal, setIsLocationSaveModal] =
    useState<boolean>(false);
  const [confirmationText, setConfirmationText] = useState<string>('');
  const [additionalUserCount, setAdditionalUserCount] = useState<number>(0);
  const [featureKeys, setFeatureKeys] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState({
    employerAndAdminLocationId: '',
    isRfpDelete: false,
  });
  const [form] = Form.useForm();
  const [transferLocation, setTransferLocation] = useState<string>('');
  const [isNewLocationOverCapacity, setIsNewLocationOverCapacity] =
    useState<boolean>(false);
  const isConfirmationTextValid = confirmationText === 'REMOVE';
  const selectedLocationName = filterdLocations.find(
    (loc) => loc.locationId === formData.employerAndAdminLocationId
  )?.locationName;

  const onInputChange = (allValues: any) => {
    form.setFieldsValue(allValues);
    setFormData({ ...formData, ...allValues });
  };

  const [getRemovedConfigs, { isLoading }] =
    useLazyGetBrokerLevelDisabledLocationsQuery();

  const [getUserCountForReAssigned, { isLoading: isRfpCountLoading }] =
    useLazyGetUserCountForReassignLocationQuery();

  const handleClickSave = async () => {
    return await onClickSave();
  };

  const handleRemoveLocation = async () => {
    const { employerAndAdminLocationId } = formData;
    const data = (await getUserCountForReAssigned({
      newLocationId: employerAndAdminLocationId,
      prevLocationId: removableLocationId,
      organizationId: brokerId ?? '',
    }).unwrap()) as any;
    if (!isRfpCountLoading && data.exceeded) {
      setIsNewLocationOverCapacity(true);
      setAdditionalUserCount(data.additionalUserCount);
    } else {
      await handleClickSave();
    }
  };

  const reAssignLocation = () => {
    const { employerAndAdminLocationId, isRfpDelete } = formData;
    const locationReassign = {
      organizationId: brokerId,
      reassignLocationIdForOrgAdmins: employerAndAdminLocationId,
      reassignLocationIdForERAdmins: employerAndAdminLocationId,
      isRfpDelete,
      isRfpExceed: isNewLocationOverCapacity,
      exceedCount: additionalUserCount,
    } as LocationReassign;

    dispatch(deleteLocationAndReassign(removableLocationId, locationReassign));
    form.resetFields(deleteBrokerLocationFields.formFields);
    closeModal();
  };

  const onClickSave = async () => {
    let isFormValid: boolean = true;
    try {
      await form.validateFields(deleteBrokerLocationFields.requiredFields);
    } catch (errorInfo) {
      isFormValid = false;
    }
    if (isFormValid) {
      const { employerAndAdminLocationId, isRfpDelete } = formData;
      const locationReassign = {
        organizationId: brokerId,
        reassignLocationIdForOrgAdmins: employerAndAdminLocationId,
        reassignLocationIdForERAdmins: employerAndAdminLocationId,
        isRfpDelete,
      } as LocationReassign;

      filterdLocations.find((location) => {
        if (location.locationId === employerAndAdminLocationId) {
          setTransferLocation(location.locationName);
        }
      });

      const data = (await getRemovedConfigs({
        newLocation: locationReassign.reassignLocationIdForERAdmins,
        oldLocation: removableLocationId,
        organizationId: locationReassign.organizationId,
      }).unwrap()) as any;
      if (!isLoading && data?.featureKeys?.length > 0) {
        setFeatureKeys(data?.featureKeys);
        setIsLocationSaveModal(true);
      } else {
        reAssignLocation();
      }
    }
    setIsNewLocationOverCapacity(false);
  };

  const onReset = () => {
    dispatch(clearBrokerLocationDeletion());
    form.resetFields(deleteBrokerLocationFields.formFields);
    closeModal();
  };

  return (
    <>
      <div className={styles.container}>
        <Row justify="center" className={styles.text}>
          <Col>
            {isRfpEnabled ? (
              <p>
                Before you can remove this location you must re-assign any
                employers, current admins, RFPs and RFP training files to
                another location.
              </p>
            ) : (
              <p>
                Before you can remove this location you must re-assign any
                Employers and Admins to another location.
              </p>
            )}
          </Col>
        </Row>
        <InputForm
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
          onValuesChange={onInputChange}
        >
          <Row justify="center">
            <Col>
              <Row>
                <Col className={styles.locationList}>
                  <div className={styles.disableWarningText}>
                    Choose a destination location
                  </div>
                  <Form.Item
                    name="employerAndAdminLocationId"
                    rules={[
                      {
                        required: true,
                        message: EMPTY_MESSAGE,
                      },
                    ]}
                  >
                    <Select size="large" placeholder="Select">
                      {filterdLocations.map((location) => (
                        <Option
                          value={location.locationId}
                          key={location.locationId}
                        >
                          <Row
                            align="middle"
                            className={styles.locationOptionRow}
                          >
                            <div>{location.locationName}</div>
                            {!location.isRfpEnabled && isRfpEnabled && (
                              <PlanyearPopover
                                placement="right"
                                content="RFP not enabled at this location."
                                zIndex={1050}
                              >
                                <QuestionCircleOutlined />
                              </PlanyearPopover>
                            )}
                          </Row>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {isRfpEnabled && (
                    <Form.Item name="isRfpDelete" valuePropName="checked">
                      <Checkbox>Delete RFPs instead of re-assigning</Checkbox>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="center">
            <Divider />
            <div className={styles.warningConfirmation}>
              <div className={styles.disableWarningText}>
                Type &quot;REMOVE&quot; in the field below to proceed
              </div>
              <Input
                value={confirmationText}
                className={`${
                  confirmationText && !isConfirmationTextValid
                    ? styles.disableTextInputError
                    : styles.disableTextInput
                } disableInputField`}
                type="text"
                onChange={(e) => setConfirmationText(e.target.value)}
                bordered={true}
              />
              {confirmationText && !isConfirmationTextValid ? (
                <div className={styles.invalidInput}>Invalid Input</div>
              ) : (
                ''
              )}
            </div>
          </Row>
          <PageActionButton
            type="primary"
            className={styles.confirmButton}
            onClick={handleRemoveLocation}
            disabled={
              isEmpty(formData?.employerAndAdminLocationId) ||
              !isConfirmationTextValid ||
              !confirmationText
            }
          >
            Save Changes and Remove Location
          </PageActionButton>
          <Row justify="center">
            <Col>
              <CancelButton htmlType="button" onClick={onReset} block>
                Cancel
              </CancelButton>
            </Col>
          </Row>
        </InputForm>
      </div>
      <ConfirmationDialog
        isCancelApplicable
        isCancelLink
        cancelText="Cancel"
        title="Confirm Location"
        confirmText="Continue"
        onConfirm={handleClickSave}
        closeModal={() => setIsNewLocationOverCapacity(false)}
        visible={isNewLocationOverCapacity}
      >
        <p>
          Transferring all the broker admins will exceed the current user limit
          set for RFP in {selectedLocationName} and the account will be charged
          for {additionalUserCount} additional user(s).
        </p>
        {isTechAdmin ? (
          <p>
            Please ensure that the user limit is updated in the Platform once it
            has been finalized.
          </p>
        ) : (
          <p>
            Please note that our sales team will be notified upon confirmation
            and a team member will contact you shortly.
          </p>
        )}
      </ConfirmationDialog>
      {isLocationSaveModal && (
        <EmployerLocationChangeModal
          currentLocation={removableLocationName}
          transferLocation={transferLocation}
          isModalOpen={isLocationSaveModal}
          title={'Re-Assign Broker Location'}
          setIsLocationSaveModal={setIsLocationSaveModal}
          isEmployerEdit={false}
          featureKeys={featureKeys}
          saveData={() => {}}
          saveReassignLocation={reAssignLocation}
        />
      )}
    </>
  );
};

export default RemoveBrokerLocation;
