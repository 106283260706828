import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Col, Form, Modal, Row } from 'antd';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import IconInfo from 'assets/images/icon-info.svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import MDVEditDocumentUploader from 'modules/plans/components/MDVEditDocumentUploader/MDVEditDocumentUploader';
import Option from 'model/Option';
import FileType from 'model/plans/FileType';
import WebLinkType from 'model/plans/WebLinkType';

import { convertEnumToOptions } from 'util/commonUtil';
import { openAndDownloadPlanDocument } from 'util/fileUtil';

import {
  removePlanDocuments,
  updateWeblinks,
  uploadPlanDocument,
  uploadRemovePlanDocument,
} from 'modules/plans/slices/planDocumentsSlice';
import { Plan } from 'model/plans/Plan';
import { PLAN_ADDITIONAL_DOCUMENT } from 'modules/plans/constants';
import { BenefitKind } from 'constants/commonConstants';
import AdditionalPlanResources from 'modules/plans/components/AdditionalPlanResources/AdditionalPlanResources';
import styles from './editDocumentUploadModal.module.less';

type EditDocumentUploadModalProps = {
  visible: boolean;
  onClose: Function;
  benefitKind: string;
  plan: Plan;
  documentTypes: { [key: string]: Option };
  documentReferences: any;
};

export type InvalidField = 'documentInvalid' | 'webLinkInvalid' | '';
const EditDocumentUploadModal = forwardRef(
  (props: EditDocumentUploadModalProps, ref) => {
    const { visible, onClose, plan, benefitKind, documentTypes } = props;
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const [submitDisable, setSubmitDisable] = useState<boolean>(false);
    const planResourcesRef = useRef<any>();
    const [temporaryWeblink, setTemporaryWeblink] = useState<WebLinkType[]>([]);

    const { inProgress: documentUploadInProgress } = useAppSelector(
      (state) => state.plan.planDocuments
    );
    const planDocumentTypes = convertEnumToOptions(documentTypes);

    const [selectedFileList, setSelectedFileList] = useState<FileType[]>([]);
    const [isRemoved, setIsRemoved] = useState<{
      [key: string]: boolean;
    }>({});
    const [docs, setDocs] = useState<{
      [key: string]: string;
    }>({
      ...plan.documents,
      ...plan.additionalDocuments,
    });

    useImperativeHandle(ref, () => ({
      resetAll() {
        setDocs({});
        setIsRemoved({});
        setSelectedFileList([]);
        setTemporaryWeblink([]);
      },
    }));

    const uploadDocument = async () => {
      const removeDocs = Object.keys(isRemoved)
        .filter((documentType) => isRemoved[documentType])
        .map((documentType) => ({
          docType: PLAN_ADDITIONAL_DOCUMENT,
          planDocumentName: documentType,
        }));
      if (!isEmpty(selectedFileList) && isEmpty(removeDocs)) {
        await dispatch(
          uploadPlanDocument(selectedFileList, plan.id as string, benefitKind)
        );
      } else if (!isEmpty(removeDocs) && isEmpty(selectedFileList)) {
        await dispatch(
          removePlanDocuments(plan.id as string, benefitKind, removeDocs)
        );
      } else if (!isEmpty(removeDocs) && !isEmpty(selectedFileList)) {
        await dispatch(
          uploadRemovePlanDocument(
            selectedFileList,
            plan.id as string,
            benefitKind,
            removeDocs
          )
        );
      }
      if (temporaryWeblink.length > 0) {
        await dispatch(
          updateWeblinks(temporaryWeblink, plan.id as string, benefitKind)
        );
      }
      onCancel();
    };

    const downloadFileObj = (data: File, docType: string) => {
      if (plan.id && benefitKind) {
        openAndDownloadPlanDocument(plan.id, docType, benefitKind, data);
      }
    };

    const onRemove = (docType: string) => {
      setIsRemoved((prevState) => ({
        ...prevState,
        [docType]: true,
      }));

      if (!isEmpty(docs) && !isEmpty(docs[docType])) {
        const copy = cloneDeep(docs);
        delete copy[docType];
        setDocs(copy);
      }
    };

    const onCancel = () => {
      setSubmitDisable(false);
      form.resetFields();
      onClose();
    };

    function getBenefitKindLabel(value: string): string {
      const label = BenefitKind[value as keyof typeof BenefitKind]?.label;
      return label ? label : value;
    }

    return (
      <Modal
        visible={visible}
        footer={null}
        centered
        className={styles.documentUploadModalWrapper}
        closable={false}
        width={565}
      >
        <div className={styles.title}>
          Edit {getBenefitKindLabel(benefitKind)} Plan Resources
        </div>
        <div className={styles.toggleWrapper}>
          <div>
            <img src={IconInfo} alt="Icon Info" />
          </div>
          <div className={styles.defaultText}>
            Changing plan information here will change the plan information
            everywhere this plan is shown
          </div>
        </div>
        {planDocumentTypes.map((docType: Option, index: number) => {
          const blobUrl = get(docs, [docType.value, 'blobUrl'], undefined);
          const fileName = get(docs, [docType.value, 'fileName'], undefined);
          let file;
          const savedFileName = get(docs, docType.value);
          if (savedFileName) {
            file = new File([''], savedFileName);
          }
          if (blobUrl) {
            file = new File([blobUrl], fileName);
          }
          return (
            <div className={styles.uploadSection} key={index}>
              <Row>
                <Col span={7}>
                  <div className={styles.documentText}>{docType.label}</div>
                </Col>
                <Col span={17}>
                  <MDVEditDocumentUploader
                    onChange={(e: File) => {
                      setIsRemoved((prevState) => ({
                        ...prevState,
                        [docType.value]: false,
                      }));
                      const docTypeValues = new Set(
                        planDocumentTypes.map((docType) => docType.value)
                      );
                      setSelectedFileList([
                        ...selectedFileList,
                        {
                          file: e,
                          docType: docTypeValues.has(docType.value)
                            ? docType.value
                            : PLAN_ADDITIONAL_DOCUMENT,
                          planDocumentName: docType.value,
                        },
                      ]);
                      setDocs({ ...docs, [docType.value]: e.name });
                    }}
                    onRemove={() => {
                      onRemove(docType.value);
                    }}
                    onFileNameClick={(file: File) =>
                      downloadFileObj(file, docType.value)
                    }
                    allowedFileTypes="application/pdf"
                    maxFileSizeMB={100}
                    isRemoved={isRemoved[docType.value]}
                    file={file}
                  />
                </Col>
              </Row>
            </div>
          );
        })}
        <AdditionalPlanResources
          ref={planResourcesRef}
          plan={plan}
          benefitKind={benefitKind}
          isCloseConfirmed={false}
          selectedFileList={selectedFileList}
          setSelectedFileList={setSelectedFileList}
          selectedWebLinkList={temporaryWeblink}
          setSelectedWebLinkList={setTemporaryWeblink}
          setIsDocRemoved={setIsRemoved}
        />
        <div className={styles.btnWrapper}>
          <SubmitButton
            className={styles.saveButtonWrapper}
            onClick={() => {
              uploadDocument();
            }}
            type="primary"
            loading={documentUploadInProgress}
            disabled={submitDisable}
          >
            Done
          </SubmitButton>
          <CancelButton
            className={styles.cancelButtonWrapper}
            onClick={() => {
              onClose();
            }}
            withBorder={true}
          >
            Cancel
          </CancelButton>
        </div>
      </Modal>
    );
  }
);

EditDocumentUploadModal.displayName = 'EditDocumentUploadModal';

export default EditDocumentUploadModal;
