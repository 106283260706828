import {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Col, Row, Select, Spin } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, isEmpty, isNull } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import {
  useApproveExtractedQuoteOfferMutation,
  useGetBenefitCarrierByIdQuery,
  useLazyAddMedicalSystemPlanQuery,
  useLazyAllPlansQuery,
} from 'modules/renewals/slices/offersSlice';
import {
  ADD_PLAN_OPTIONS,
  ALL_PLAN_MAPPUING_INFO,
  BENEFIT_CARRIER_DROPDOWN_BENEFIT_TYPE,
  BROKER_ADMIN_EXTRACTION_SUCCESS,
  DUPLICATE_PLANS_DETECTED,
  FIXED_ALERT_MESSAGE_IF_FINAL_APPROVED,
  FOUR_TIER,
  LIFE_PLANS,
  LLM_EXTRACTION_POPOVER_TEXT_INFO,
  LLM_WARNING_TITLE_TEXT_QUOTE_READING,
  MDV_PLANS,
  N_TIER,
  NAME_FIELD_PROPERTY,
  NONE,
  OFFER_BENEFIT_TYPE_NAVIGATION_LABELS,
  OFFER_STATUS,
  OFFER_STATUSES,
  OFFERED_PLAN_PROCESSING_WARNING,
  OPS_ADMIN_PLAN_PARAMETER,
  PLAN_DROPDOWN_TYPES,
  QUOTE_ADDING_PROPERTY,
  QUOTE_PROCESSING_COMPLETED,
  reviewModalContent,
  STANDARD_TIER,
  URL_PARAM_TO_BENEFIT_TYPE,
  VOL_LIFE_BENEFIT_TYPES,
} from 'modules/renewals/constants/renewalsConstants';
import QuoteProcessStatus from 'modules/renewals/enums/QuoteUploadStatus';
import { QUOTE } from 'modules/renewals/constants/planTableConstants';
import PlanTable from 'modules/renewals/components/PlanTable/PlanTable';
import AlertMessage from 'components/Alert/AlertMessage';
import { isNullOrUndefined } from 'modules/plans/utils';
import PlanDropDown from 'modules/renewals/components/PlanDropDown/PlanDropDown';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import {
  compareMappedPlansAndGetUnMappedPlanList,
  compareMappedPlansForQuotes,
  getCurrentPlansForSubmit,
  getEmptyPlan,
  initialQuoteMappingData,
  isDtqOrPending,
  isVoluntaryBenefit,
  removeKeyRecursively,
  validateNonMDVObj,
} from 'modules/renewals/utils/renewalsUtils';
import { ReviewType } from 'modules/plans/enums/ReviewType';
import { QuoteMappingType } from 'modules/renewals/types/QuoteMappingType';
import { validateObj } from 'modules/renewals/utils/addPlansUtil';
import NextButton from 'components/buttons/NextButton/NextButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import TextButton from 'components/buttons/TextButton/TextButton';
import TransferEnrollmentsModal from 'modules/renewals/components/AddOfferComponents/AddPlans/TransferEnrollmentsModal/TransferEnrollmentsModal';
import PremiumType from 'modules/renewals/enums/PremiumType';
import { checkMDV } from 'modules/renewals/utils/addOfferUtil';
import { isEmptyOrUndefined } from 'util/stringUtil';
import { Offer } from 'modules/renewals/models/Offer';
import {
  ActionStatus,
  ReviewModalState,
} from 'modules/renewals/types/renewalsTypes';
import {
  cancel,
  redirectToOps,
} from 'modules/renewals/slices/carrierQuoteAiSlice';
import { getPlanTableColumnLabels } from 'modules/renewals/utils/planTableLabelUtil';
import QuoteDocumentUploader from 'modules/renewals/components/QuoteProcessing/QuoteDocumentUploader/QuoteDocumentUploader';
import PlanParametersModal from 'modules/renewals/components/QuoteProcessing/PlanParametersModal/PlanParametersModal';
import QuoteReviewModal from 'modules/renewals/components/QuoteProcessing/QuoteReviewFinalizeModal/QuoteReviewFinalizeModal';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import RejectFileModal from 'modules/plans/components/RejectFileModal/RejectFileModal';
import { AIRejectType } from 'modules/plans/enums/CommonTypes';
import { ReactComponent as AIFeedbackRedFlag } from 'assets/images/ai-red-flag.svg';
import QuoteProcessingThresholdAlert from './QuoteProcessingThresholdAlert/QuoteProcessingThresholdAlert';

import styles from './addPlans.module.less';

type AddPlansProps = {
  offer: any;
  updateOffer: (offer: any) => void;
  nextStep?: () => void;
  selectedAddingType: string;
  updateAddingType: Function;
  selectedFile: File | null;
  changeSelectedFile: Function;
  updatedData: any;
  changeUpdatedData: Function;
  changeDocumentDetails: Function;
  carrierImage: string;
  setMappedPlans: Function;
  mappedPlans: string[];
  documentDetails: any;
  tempData: any;
  setTempData: Function;
  dropdownPlansSelected: string[];
  changeDropdownPlansSelected: Function;
  isEdit: boolean;
  offerAttachedProposal?: any;
  saveAsDraft: (
    discontinuedPlanMap?: any,
    shouldInitializeQuote?: boolean,
    saveProgressAsOps?: boolean
  ) => void;
  saveInProgress: () => void;
  carrierName: String;
  isOfferCreateLoading: boolean;
  editData: any;
  currentStep: number;
  changeCurrentStep: (step: number) => any;
  premiumType?: PremiumType;
  nTierCount?: number;
  currentPlans: any;
  onExternalPublish?: Function;
  onPublishOrDraftOfferExternal?: Function;
  isMultipleBenClass?: boolean;
  quoteMapping: QuoteMappingType[];
  changeQuoteMapping: Function;
  showMappedPlansError: boolean;
  setShowMappedPlansError: Function;
  checkExistsPublishedOffer: boolean;
  checkExistsDraftOffer: boolean;
  setShowOverwritePopup: any;
  isNoCurrentPlans: boolean;
  closeModalWithoutPopup: ({
    isRedirectToOps,
    isNavigateToCurrentTab,
  }: {
    isRedirectToOps?: boolean;
    isNavigateToCurrentTab: boolean;
  }) => void;
  isClonedOffer?: boolean;
};

const AddPlans = (props: AddPlansProps, ref: Ref<any>) => {
  const DEMO_ACCOUNT_PLAN_OPTIONS = {
    // Hide option until release the feature
    MANUAL_PLANS: {
      key: 'MANUAL_PLANS',
      label: 'Add plans manually',
    },
    EXISTING_PLANS: {
      key: 'FROM_EXISTING_PLANS',
      label: 'Import from existing plans',
    },
  };

  const {
    offer,
    updateOffer,
    nextStep,
    selectedAddingType,
    updateAddingType,
    selectedFile,
    changeSelectedFile,
    updatedData,
    changeUpdatedData,
    setMappedPlans,
    mappedPlans,
    documentDetails,
    tempData,
    dropdownPlansSelected,
    changeDropdownPlansSelected,
    isEdit,
    offerAttachedProposal,
    saveAsDraft,
    saveInProgress,
    isOfferCreateLoading,
    editData,
    currentStep,
    changeCurrentStep,
    premiumType = PremiumType.STANDARD_TIER,
    nTierCount = 0,
    currentPlans,
    onExternalPublish,
    onPublishOrDraftOfferExternal,
    isMultipleBenClass,
    quoteMapping,
    changeQuoteMapping,
    showMappedPlansError,
    setShowMappedPlansError,
    checkExistsPublishedOffer,
    setShowOverwritePopup,
    checkExistsDraftOffer,
    isNoCurrentPlans,
    closeModalWithoutPopup,
  } = props;
  const { employerId, brokerId, benefitKind } = useParams();
  const navigate = useNavigate();
  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );
  const {
    status,
    reviewType,
    jobId,
    offerId: quoteOfferID,
    isPlanNamesValid,
    isDuelLLMEnable,
  } = useAppSelector((state) => state.renewals.quoteReaderSlice);
  const dispatch = useAppDispatch();
  const [currentPlansDropdownData, setCurrentPlansDropdownData] =
    useState<any>(null);
  const [resetModalVisible, setResetModalVisible] = useState<boolean>(false);
  const [selectClickedValue, setSelectedClickedValue] = useState<string>('');
  const [navigateDefault, setNavigateDefault] = useState<boolean>(false);
  const [addPlanIndex, setAddPlanIndex] = useState<number[]>([]);
  const [selectedPopupType, setSelectedPopupType] = useState<string>();
  const [tempSelectType, setTempSelectType] = useState<string>();
  const [existingPlanIsLoading, setExistingPlansIsLoading] =
    useState<boolean>(false);
  const [reviewModalState, setReviewModalState] = useState<ReviewModalState>({
    isOpen: false,
    action: ActionStatus.APPROVE,
  });
  const [showMappedPlansHighlight, setShowMappedPlansHighlight] =
    useState<boolean>(false);

  const [isShowRejectFileModal, setIsShowRejectFileModal] =
    useState<boolean>(false);

  const [approveQuotePlan, { isLoading: isApprovalLoading }] =
    useApproveExtractedQuoteOfferMutation();

  const [showTransferEnrollmentsModal, setShowTransferEnrollmentsModal] =
    useState<{
      show: boolean;
      isBack?: boolean;
      status: 'NEXT' | 'DRAFT' | 'PUBLISH' | 'QUOTE' | null;
      incomingStep?: number;
    }>({
      show: false,
      status: null,
      isBack: false,
    });
  const [showQuoteMappingError, setShowQuoteMappingError] =
    useState<boolean>(false);
  const [showQuoteMappingHighlight, setShowQuoteMappingHighlight] =
    useState<boolean>(false);
  const [isPlanParametersModalOpen, setIsPlanParametersModalOpen] =
    useState<boolean>(false);
  const [showPlanIdentifierError, setShowPlanIdentifierError] =
    useState<boolean>(false);

  const isProcessing = [QuoteProcessStatus.PROCESSING].includes(status);
  const isFailed = [QuoteProcessStatus.FAILED].includes(status);
  const isSavedQuoteOffer = [QuoteProcessStatus.SAVED].includes(status);

  const isQuoteProcessingEnabled =
    ADD_PLAN_OPTIONS.CARRIER_QUOTE.key === selectedAddingType;

  const showSaveAsDraftForQuotes =
    isQuoteProcessingEnabled && isSavedQuoteOffer;

  const resetMappingErrors = () => {
    if (
      showQuoteMappingError ||
      showQuoteMappingHighlight ||
      showPlanIdentifierError
    ) {
      setShowQuoteMappingError(false);
      setShowQuoteMappingHighlight(false);
      setShowPlanIdentifierError(false);
    }
  };

  const showQuoteDocumentUploader = () => {
    const isIdEmpty = isEmpty(offer?.extractionDetails?.id);
    const isQuoteProcessing = isQuoteProcessingEnabled;
    const isFailedAutomaticReview =
      reviewType === ReviewType.AUTOMATIC &&
      status === QuoteProcessStatus.FAILED;

    if (
      offer?.quoteProcessingStatus === QUOTE_PROCESSING_COMPLETED &&
      showPlanTable()
    ) {
      return false;
    }

    return (
      (isIdEmpty && isQuoteProcessing && !isOpsAdmin) ||
      isProcessing ||
      isFailedAutomaticReview
    );
  };

  const disableOnOpsAdmin =
    isOpsAdmin && isQuoteProcessingEnabled && !showQuoteDocumentUploader();

  const disableOnSelfReview =
    [QuoteProcessStatus.REVIEWED].includes(status) &&
    reviewType === ReviewType.AUTOMATIC;

  const disableQuoteProcessing = [
    QuoteProcessStatus.UPLOADED,
    QuoteProcessStatus.UPLOADING,
    QuoteProcessStatus.VALIDATING,
    QuoteProcessStatus.MANUAL,
  ].includes(status);

  const isOfferCreationLoading =
    isOfferCreateLoading &&
    !(reviewType === ReviewType.AUTOMATIC && isPlanNamesValid);

  const resetQuoteURL = () => {
    navigate(
      `/brokers/${brokerId}/employers/${employerId}/renewals/carrier/${benefitKind}`
    );
  };

  const employerDetails: any = useAppSelector((state) => state.layout.employer);

  const [getSystemPlans, { data: allPlans }] = useLazyAllPlansQuery();

  const handleSaveAsDraft = () => {
    if (!isPlansMapped() || isNullOrUndefined(updatedData)) {
      if (
        compareMappedPlansAndGetUnMappedPlanList(
          currentPlansDropdownData,
          mappedPlans
        ).length !== 0 &&
        MDV_PLANS.includes(offer?.benefitCategory) &&
        !isQuoteProcessingEnabled
      ) {
        setShowTransferEnrollmentsModal({
          show: true,
          status: 'DRAFT',
        });
      } else {
        saveAsDraft({});
      }
    }
  };

  const handleSaveAsPublished = () => {
    if (!isPlansMapped() || isNullOrUndefined(updatedData)) {
      if (
        compareMappedPlansAndGetUnMappedPlanList(
          currentPlansDropdownData,
          mappedPlans
        ).length !== 0 &&
        MDV_PLANS.includes(offer?.benefitCategory) &&
        !isQuoteProcessingEnabled
      ) {
        setShowTransferEnrollmentsModal({
          show: true,
          status: 'PUBLISH',
        });
      } else if (offerAttachedProposal?.list?.length !== 0) {
        onPublishOrDraftOfferExternal?.(OFFER_STATUS.PUBLISHED);
        publishOfferWhenExistsInProposal();
      } else {
        publishOffer();
      }
    }
  };

  useEffect(() => {
    if (currentPlans && employerId && benefitKind) {
      getSystemPlans({
        employerId: employerId ?? '',
        type: PLAN_DROPDOWN_TYPES?.[benefitKind?.toUpperCase() ?? ''],
        benefitType: URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? ''],
        tierType: currentPlans?.planTierType !== N_TIER ? FOUR_TIER : N_TIER,
        tierCount: currentPlans?.planTierCount,
      });
    }
  }, [employerId, benefitKind, currentPlans, getSystemPlans]);

  const { data: benefitCarrier, isFetching: benefitCarrierIsFetching } =
    useGetBenefitCarrierByIdQuery(
      {
        id: offer?.carrierId,
      },
      { refetchOnMountOrArgChange: true }
    );

  const [getMedicalSystemPlan] = useLazyAddMedicalSystemPlanQuery();

  const handleBack = () => {
    handlePlanSelection({ isBack: true });
  };

  const handleReviewModalState = (isOpen: boolean, action?: ActionStatus) => {
    setReviewModalState({ isOpen, action });
  };

  useEffect(() => {
    if (currentPlans !== undefined) {
      const plans = Object.entries(currentPlans?.currentPlans).map(
        (item: any) => {
          return {
            value: item[0],
            label: item[1],
          };
        }
      );
      setCurrentPlansDropdownData(plans);
    }
    // eslint-disable-next-line
  }, [currentPlans]);

  useEffect(() => {
    let containsNonMappedPlan = false;
    mappedPlans.forEach((plan) => {
      if (plan === undefined) {
        containsNonMappedPlan = true;
      }
    });

    if (
      mappedPlans.includes('') ||
      mappedPlans?.length !== updatedData?.length ||
      containsNonMappedPlan
    ) {
      setShowMappedPlansError(true);
      changeUpdatedData(updatedData);
    } else {
      setShowMappedPlansError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedPlans, updatedData]);

  const parsePlanString = (planString: string): PlanInfo | null => {
    const firstUnderscoreIndex = planString.indexOf('_');

    // Ensure that there is at least one underscore in the string
    if (firstUnderscoreIndex !== -1) {
      const planId = planString.slice(0, firstUnderscoreIndex);
      const planName = planString.slice(firstUnderscoreIndex + 1);

      // Ensure that neither planId nor planName is an empty string
      if (!isEmpty(planId) && !isEmpty(planName)) {
        return { planId, planName };
      }
    }
    return null;
  };

  const arrangeColData = useMemo(() => {
    const isNTier =
      premiumType !== null &&
      premiumType !== undefined &&
      premiumType?.toString() !== STANDARD_TIER;
    const plans = tempData ??
      updatedData ?? [
        {
          ...getEmptyPlan(
            premiumType,
            URL_PARAM_TO_BENEFIT_TYPE[benefitKind ?? ''],
            nTierCount
          ),
          name: 'Plan #1',
        },
      ];

    return getPlanTableColumnLabels({
      benefitType: URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? ''],
      plans: plans,
      fundingType: offer.fundingType!,
      isNTier: isNTier,
      isComparePlans: false,
      isPlanDetails: false,
      isPreview: false,
      addFooterRow: true,
      addEnrollmentsForPremiums: false,
      showErrorHighlight: !isDtqOrPending(offer?.offerStatus),
      nTierCount,
      isNoCurrentPlans,
      isAddEditPlan: true,
    });
    // eslint-disable-next-line
  }, [
    updatedData,
    tempData,
    addPlanIndex,
    benefitCarrier,
    offer,
    premiumType,
    nTierCount,
    isNoCurrentPlans,
    benefitKind,
  ]);

  const redirectToOpsOnFailedStatus = async () => {
    closeModalWithoutPopup({
      isRedirectToOps: true,
      isNavigateToCurrentTab: true,
    });
    if (jobId && quoteOfferID) {
      await dispatch(redirectToOps(jobId, quoteOfferID));
    }
    return;
  };

  const isDisabledNextButton = () => {
    const quoteStatusesToCheck = [
      QuoteProcessStatus.REVIEWED,
      QuoteProcessStatus.SAVED,
      QuoteProcessStatus.REJECTED,
    ];

    const loadingQuoteProcess = [
      QuoteProcessStatus.UPLOADING,
      QuoteProcessStatus.VALIDATING,
    ].includes(status);

    const planNamesNotValid =
      [QuoteProcessStatus.VALIDATED].includes(status) && !isPlanNamesValid;

    const allowedStatuses = [
      QuoteProcessStatus.SUCCESS,
      isOpsAdmin && QuoteProcessStatus.FAILED,
      QuoteProcessStatus.REVIEWED,
      QuoteProcessStatus.SAVED,
    ].includes(status);

    const isAdminCondition =
      isOpsAdmin &&
      (showMappedPlansError || quoteStatusesToCheck.includes(status));
    const isLoadingCondition = isOfferCreateLoading || loadingQuoteProcess;

    const isDisabledForQuotes =
      isLoadingCondition ||
      isAdminCondition ||
      planNamesNotValid ||
      (allowedStatuses && disablePlanNext()) ||
      (!disableOnOpsAdmin && disableQuoteProcessing);

    const isDisabledForManualPlans =
      disablePlanNext() || showMappedPlansError || isLoadingCondition;

    return isQuoteProcessingEnabled
      ? isDisabledForQuotes
      : isDisabledForManualPlans;
  };

  const validateUpdatedData = useCallback(() => {
    const data =
      updatedData !== null || updatedData !== undefined
        ? updatedData
        : tempData;
    const names = (data ?? []).map((item: any) => item?.name?.toLowerCase());

    if (new Set(names ?? []).size !== (updatedData?.length ?? 0)) {
      return true;
    } else {
      return false;
    }
  }, [tempData, updatedData]);

  const validateFormatting = useCallback(() => {
    const data =
      updatedData !== null || updatedData !== undefined
        ? updatedData
        : tempData;

    let isValid = true;
    (data ?? []).map((item: any) => {
      if (
        isValid &&
        MDV_PLANS?.includes(URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? ''])
      ) {
        isValid = !validateObj(
          item,
          isNoCurrentPlans,
          offer?.fundingType,
          benefitKind
        );
      } else if (
        isValid &&
        LIFE_PLANS?.includes(URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? ''])
      ) {
        isValid = !validateNonMDVObj(
          item,
          URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? ''],
          offer?.fundingType
        );
      }
    });

    return !isValid && !isDtqOrPending(offer?.offerStatus);
  }, [
    isNoCurrentPlans,
    benefitKind,
    offer?.offerStatus,
    offer?.fundingType,
    tempData,
    updatedData,
  ]);

  const publishOffer = async () => {
    await (onExternalPublish && onExternalPublish());
  };

  const publishOfferWhenExistsInProposal = async () => {
    await (onPublishOrDraftOfferExternal && onPublishOrDraftOfferExternal());
  };

  const getBenefitExclusivePlansList = (
    plan: any,
    isMultipleBenClass: boolean
  ) => {
    if (isMultipleBenClass) {
      return plan?.groups?.map((groupName: any, index: number) => {
        return {
          label: `${plan.planName} - ${groupName} `,
          planId: plan.planId,
          value: `${plan.planId}_${groupName}`,
          fundingType: plan?.fundingType,
        };
      });
    } else {
      return {
        label: plan?.planName,
        planId: plan?.planId,
        value: plan?.planId,
        fundingType: plan?.fundingType,
      };
    }
  };

  const mapAllPlansObject = (allPlans: any[], isMultipleBenClass: boolean) => {
    let dropdownOptions: any[] = [];
    allPlans?.forEach((plan: any) => {
      const benefitExclusivePlans = getBenefitExclusivePlansList(
        plan,
        isMultipleBenClass && checkMDV(benefitKind)
      );
      dropdownOptions = dropdownOptions.concat(benefitExclusivePlans);
    });
    return dropdownOptions;
  };

  const onSystemPlanSave = async (selectedValues: string[]) => {
    setMappedPlans([]);
    setAddPlanIndex([]);
    let existingPlanArr = [] as any[];
    setExistingPlansIsLoading(true);

    const benefitCategory =
      BENEFIT_CARRIER_DROPDOWN_BENEFIT_TYPE?.[benefitKind?.toUpperCase() ?? ''];
    let type = benefitCategory?.toLowerCase();

    await Promise.all(
      (selectedValues ?? [])
        .filter((item) => item !== null)
        .map(async (item) => {
          const dropdownObj = parsePlanString(item);
          const newPlanId =
            isMultipleBenClass && checkMDV(benefitKind)
              ? dropdownObj?.planId ?? ''
              : item;
          const newBenefitClass =
            isMultipleBenClass && checkMDV(benefitKind)
              ? dropdownObj?.planName ?? ''
              : '';

          if (!dropdownPlansSelected?.includes(item)) {
            // Specifically in the case of voluntary-benefits. We need to check and specify
            // the type as life if its a vol-life plan. Otherwise API breaks
            if (
              benefitCategory ===
              BENEFIT_CARRIER_DROPDOWN_BENEFIT_TYPE?.['VOL-LIFE']
            ) {
              const volLifeBenefitKind = allPlans.find(
                (plan: any) => plan?.planId === item
              )?.benefitKind;
              if (
                VOL_LIFE_BENEFIT_TYPES.some(
                  (type) => type.value === volLifeBenefitKind
                )
              ) {
                type =
                  BENEFIT_CARRIER_DROPDOWN_BENEFIT_TYPE?.[
                    'LIFE-ADD'
                  ]?.toLowerCase();
              }
            }

            await getMedicalSystemPlan({ id: newPlanId, type })
              .unwrap()
              .then((response) => {
                const modifiedPlanObject = modifyPlanObject(
                  response,
                  newBenefitClass,
                  (isMultipleBenClass ?? false) && checkMDV(benefitKind)
                );

                existingPlanArr = [...existingPlanArr, modifiedPlanObject];
              });
          } else {
            const existingPlan = (updatedData ?? []).find(
              (updatedDataItem: any) =>
                updatedDataItem?.referenceId === newPlanId
            );

            if (existingPlan !== null) {
              existingPlanArr = [...existingPlanArr, existingPlan];
            }
          }
        })
    );

    (updatedData ?? []).map((item: any) => {
      if (item?.referenceId === null) {
        existingPlanArr = [...existingPlanArr, item];
      }
    });

    if ((existingPlanArr?.length ?? 0) === 0) {
      changeUpdatedData(null);
    } else {
      // Set HRA AND HSA false if both are enabled
      const reMapData = existingPlanArr?.map((plan) => {
        if (!!plan?.hraCompatible && !!plan?.hsaCompatible) {
          return {
            ...plan,
            hraCompatible: false,
            hsaCompatible: false,
          };
        }
        return plan;
      });

      changeUpdatedData(reMapData);
    }
    setExistingPlansIsLoading(false);
  };

  interface PlanInfo {
    planId: string;
    planName: string;
  }

  const modifyPlanObject = (
    planObj: any,
    benefitClassName: string,
    isMultipleBenClass: boolean
  ) => {
    if (isMultipleBenClass) {
      return {
        ...planObj,
        name: `${planObj.name} - ${benefitClassName}`,
        benefitClassName: benefitClassName,
        keyRefId: uuidv4() + 'existingOfferPlan',
      };
    } else {
      return planObj;
    }
  };

  /**
   * Determines whether to show the plan table based on various conditions.
   *
   * @return {boolean} True if the plan table should be shown, false otherwise.
   */
  const showPlanTable = (): boolean => {
    // Check if the existing plan data is not loading
    if (!existingPlanIsLoading) {
      // Check if updated data is available
      if (updatedData !== null && updatedData !== undefined) {
        return true;
      }
      // Check if manual plans are selected and there are selected plans
      if (
        ADD_PLAN_OPTIONS.MANUAL_PLANS.key === selectedAddingType &&
        (addPlanIndex?.length ?? 0) !== 0
      ) {
        return true;
      }
      // Check if existing plans are selected and there are selected plans
      if (
        ADD_PLAN_OPTIONS.EXISTING_PLANS.key === selectedAddingType &&
        (addPlanIndex?.length ?? 0) !== 0
      ) {
        return true;
      }
      // Check if manual plans are selected
      if (ADD_PLAN_OPTIONS.MANUAL_PLANS.key === selectedAddingType) {
        return true;
      }
      // If none of the conditions are met, do not show the plan table
      return false;
    }
    // If existing plan data is loading, do not show the plan table
    return false;
  };

  const isPlansMapped = (): boolean => {
    if (
      OFFER_BENEFIT_TYPE_NAVIGATION_LABELS.VOLUNTARY_BENEFIT === benefitKind
    ) {
      return (
        updatedData?.length !== mappedPlans?.length ||
        mappedPlans.includes('') ||
        mappedPlans.includes('-')
      );
    }
    return false;
  };

  const validatePlanIdentifiers = () => {
    const planIdentifierSet = new Set();

    const emptyRows = quoteMapping?.filter(
      (item) =>
        isEmptyOrUndefined(item?.planIdentifier) &&
        isEmptyOrUndefined(item?.currentPlan)
    );
    const emptyFields = quoteMapping?.filter(
      (item) =>
        (!isEmptyOrUndefined(item?.planIdentifier) &&
          isEmptyOrUndefined(item?.currentPlan)) ||
        (isEmptyOrUndefined(item?.planIdentifier) &&
          !isEmptyOrUndefined(item?.currentPlan))
    );

    const hasDuplicatePlanIdentifier = quoteMapping?.some((item) => {
      if (isEmptyOrUndefined(item?.planIdentifier)) return false;
      if (planIdentifierSet.has(item.planIdentifier.trim())) {
        return true;
      }
      planIdentifierSet.add(item.planIdentifier);
      return false;
    });

    if ((emptyRows?.length ?? 0) === (quoteMapping?.length ?? 0)) {
      setShowQuoteMappingError(true);
      return true;
    } else if ((emptyFields?.length ?? 0) !== 0) {
      setShowQuoteMappingError(false);
      setShowQuoteMappingHighlight(true);
      return true;
    } else if (hasDuplicatePlanIdentifier) {
      setShowPlanIdentifierError(true);
      return true;
    } else if (!hasDuplicatePlanIdentifier) {
      setShowPlanIdentifierError(false);
      return false;
    }
  };

  const onSaveDraft = (discontinuedPlanMap = {}, shouldInitialize = false) => {
    if (isQuoteProcessingEnabled) {
      const isReviewed = status === QuoteProcessStatus.REVIEWED;
      const hasDifferentPlans = !compareMappedPlansForQuotes(
        mappedPlans,
        currentPlans?.currentPlans,
        discontinuedPlanMap
      );
      const isMDVPlan = MDV_PLANS.includes(offer?.benefitCategory);

      if (isReviewed && hasDifferentPlans && isMDVPlan) {
        setShowTransferEnrollmentsModal({
          show: true,
          status: 'QUOTE',
        });
        return;
      }
    }

    if (!isPlansMapped() || isNullOrUndefined(updatedData)) {
      const unmappedPlansExist =
        compareMappedPlansAndGetUnMappedPlanList(
          currentPlansDropdownData,
          mappedPlans
        ).length !== 0;

      if (
        unmappedPlansExist &&
        MDV_PLANS.includes(offer?.benefitCategory) &&
        !isQuoteProcessingEnabled
      ) {
        setShowTransferEnrollmentsModal({
          show: true,
          status: 'DRAFT',
        });
      } else {
        saveAsDraft(discontinuedPlanMap, shouldInitialize);
      }
    } else {
      setShowMappedPlansError(true);
      setShowMappedPlansHighlight(true);
    }
  };

  const getPlanNameList = useCallback(() => {
    const data = tempData === null ? updatedData : tempData;
    return data?.map((item: any) => ({
      label: item?.name,
      value: item?.name,
    }));
  }, [tempData, updatedData]);

  const getPlanNameListForQuotes = useCallback(() => {
    const quoteData = (quoteMapping ?? [])
      ?.filter((item) => item?.planIdentifier)
      ?.map((item) => ({
        label: item?.planIdentifier,
        value: item?.planIdentifier,
      }));

    const data = tempData ?? updatedData;
    const planData = (data ?? []).map((item: any) => ({
      label: item.name,
      value: item.name,
    }));

    const uniqueData = [...quoteData, ...planData]?.reduce((acc, item) => {
      if (
        !acc?.some((existingItem: any) => existingItem?.value === item?.value)
      ) {
        acc.push(item);
      }
      return acc;
    }, []);

    return uniqueData;
  }, [quoteMapping, tempData, updatedData]);

  const getDiscontinuedPlanMapValueUsingKeyRefId = useCallback(() => {
    if (offer?.discontinuedPlanMap) {
      if (isQuoteProcessingEnabled) {
        return offer?.discontinuedPlanMap;
      } else {
        const newObj: any = {};
        Object.entries(offer?.discontinuedPlanMap)?.map(([key, value]) => {
          const findOldSavedPlanId = offer?.[
            QUOTE_ADDING_PROPERTY?.[benefitKind?.toUpperCase() ?? '']
          ]?.find((item: any) => item?.name === value)?.keyRefId;

          const findUpdatedPlan = updatedData?.find(
            (item: any) => item?.keyRefId === findOldSavedPlanId
          );

          newObj[key] = findUpdatedPlan?.name;
        });
        return newObj;
      }
    }
    // eslint-disable-next-line
  }, [updatedData, offer]);

  const defaultUpdateOffer = async (
    value: any = {},
    isBack: boolean = false
  ) => {
    const nullPlanId = (updatedData ?? []).map((item: any) => ({
      ...item,
      id: null,
    }));

    const shouldApproveOffer =
      !isBack &&
      !offer?.quoteOfferApproved &&
      ![QUOTE_PROCESSING_COMPLETED].includes(offer?.quoteProcessingStatus);

    updateOffer({
      ...offer,
      currentPlans: getCurrentPlansForSubmit(mappedPlans, updatedData),
      [QUOTE_ADDING_PROPERTY?.[benefitKind?.toUpperCase() ?? '']]: nullPlanId,
      s3DocumentDetails: documentDetails,
      planCreationType: selectedAddingType === '' ? NONE : selectedAddingType,
      discontinuedPlanMap: value,
      ...(shouldApproveOffer && { quoteOfferApproved: true }),
    });

    if (shouldApproveOffer) {
      const newUpdatedData = updatedData ?? tempData;
      // This method removes LLM extraction info from the updated data, when the offer is approved
      removeKeyRecursively(newUpdatedData, 'llmExtractionInfo');
      changeUpdatedData(newUpdatedData);

      if (offer?.offerId) {
        await approveQuotePlan({
          offerId: offer?.offerId ?? '',
        });
      }
    }
  };

  const handleNext = () => {
    triggerNextStep([QuoteProcessStatus.PROCESSING].includes(status));
  };

  const triggerNextStep = (
    isProcessing: boolean,
    shouldInitialize?: boolean
  ) => {
    const isQuoteActive = isQuoteProcessingEnabled;
    // Check if quote processing should be initialized
    const initializeQuoteProcessing =
      isQuoteActive &&
      ([QuoteProcessStatus.VALIDATED].includes(status) || shouldInitialize);

    // Determine if the current status is processing
    const isProcessingStatus = isQuoteActive && isProcessing;

    // Determine if the automatic review has failed
    const isSelfReviewFailed =
      [QuoteProcessStatus.FAILED].includes(status) &&
      reviewType === ReviewType.AUTOMATIC;

    const promptQuoteApproveModal =
      isQuoteActive &&
      !offer?.quoteOfferApproved &&
      offer?.quoteProcessingStatus !== QUOTE_PROCESSING_COMPLETED;

    // Determine if the current status is a success state
    const isStatusSuccess =
      isQuoteActive &&
      ![QuoteProcessStatus.REVIEWED, QuoteProcessStatus.SAVED].includes(status);

    // Handle processing status or failed self-review
    if (isProcessingStatus || isSelfReviewFailed) {
      if (isSelfReviewFailed) {
        redirectToOpsOnFailedStatus();
      }
      return closeModalWithoutPopup({ isNavigateToCurrentTab: true });
    }

    // Handle success status
    if (isStatusSuccess || promptQuoteApproveModal) {
      const shouldReview = !isPlansMapped() || isNullOrUndefined(updatedData);

      if (shouldReview && !showQuoteDocumentUploader()) {
        handleReviewModalState(true, ActionStatus.APPROVE);
      } else {
        onSaveDraft({}, initializeQuoteProcessing);
      }
    } else {
      // Handle plan selection and move to the next step
      const planSelected = handlePlanSelection({ isBack: false });
      if (planSelected) {
        nextStep?.();
      }
    }
  };

  const disablePlanNext = useCallback(() => {
    return (
      existingPlanIsLoading ||
      validateUpdatedData() ||
      (validateFormatting() && !isDtqOrPending(offer?.offerStatus)) ||
      ((updatedData === null || updatedData === undefined) &&
        !isDtqOrPending(offer?.offerStatus))
    );
  }, [
    existingPlanIsLoading,
    offer?.offerStatus,
    validateFormatting,
    validateUpdatedData,
    updatedData,
  ]);

  const handleConfirm = () => {
    setShowMappedPlansHighlight(false);
    if (selectedPopupType !== 'UPLOAD') {
      updateAddingType(selectClickedValue);
    }
    setResetModalVisible(false);
    changeUpdatedData(null);
    changeSelectedFile(null);
    setMappedPlans([]);
    setAddPlanIndex(
      selectClickedValue === ADD_PLAN_OPTIONS.MANUAL_PLANS.key ? [0] : []
    );
    changeDropdownPlansSelected([]);
    changeQuoteMapping(initialQuoteMappingData);
    if (offer?.hasOwnProperty('extractionDetails')) {
      const clonedOffer = cloneDeep(offer);
      delete clonedOffer.extractionDetails;
      updateOffer({ ...clonedOffer });
    }
    dispatch(cancel());
  };

  const handlePlanSelection = useCallback(
    ({
      isBack,
      incomingStep,
    }: { isBack?: boolean; incomingStep?: number } = {}) => {
      setNavigateDefault(isBack ?? false);

      const isUnmappedPlans = !isPlansMapped();
      const isPendingOffer =
        offer.offerStatus === OFFER_STATUSES.PENDING.value &&
        (updatedData?.length ?? 0) === 0;

      const unmappedPlanList = compareMappedPlansAndGetUnMappedPlanList(
        currentPlansDropdownData,
        mappedPlans
      );

      const updatedDataExists = !isNullOrUndefined(updatedData);

      const isUnmappedMDVPlans =
        !isEmpty(unmappedPlanList) &&
        MDV_PLANS.includes(offer?.benefitCategory);

      if (!isEmpty(unmappedPlanList) && !updatedDataExists) {
        defaultUpdateOffer({}, isBack);
        return true;
      } else if (
        isOfferCreateLoading ||
        (!isNullOrUndefined(updatedData) &&
          (disablePlanNext() || showMappedPlansError))
      ) {
        // Return Error
        return false;
      } else {
        if (isUnmappedPlans || isPendingOffer) {
          if (isUnmappedMDVPlans) {
            setShowTransferEnrollmentsModal({
              show: true,
              isBack: isBack,
              status: 'NEXT',
              incomingStep: incomingStep,
            });
            return false;
          } else {
            defaultUpdateOffer({}, isBack);
            return true;
          }
        } else {
          setShowMappedPlansError(true);
          setShowMappedPlansHighlight(true);
          return false;
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      offer,
      updatedData,
      currentPlansDropdownData,
      mappedPlans,
      disablePlanNext,
      showMappedPlansError,
      isOfferCreateLoading,
    ]
  );

  useImperativeHandle(ref, () => ({
    onSubmitNext: (data: any) => handlePlanSelection(data),
    handleSaveAsDraft,
    handleSaveAsPublished,
  }));

  useEffect(() => {
    if (!!navigateDefault) {
      changeCurrentStep(currentStep - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateDefault]);

  // to add N/A as an option
  const updatedDropDownValues = [
    { value: null, label: 'N/A' },
    ...(currentPlansDropdownData ? currentPlansDropdownData : []),
  ];

  const isProposalsAttached = () => {
    return (
      (offerAttachedProposal?.list ?? null) !== null &&
      offerAttachedProposal?.list?.length !== 0
    );
  };

  const quotePlanMappingError = () => {
    return (
      <Col span={24}>
        <Row justify={'center'}>
          <AlertMessage
            type="error"
            message={
              'Please provide the Plan Name and the Current Plan Mapping for at least one plan that you wish to include in this offer.'
            }
            wrapperClassName={styles.quoteMappingError}
          />
        </Row>
      </Col>
    );
  };

  const renderQuoteProcessingWarnings = () => {
    if (offer.planCreationType === ADD_PLAN_OPTIONS.CARRIER_QUOTE.key) {
      if (isOpsAdmin) {
        return (
          <FixedAlertMessage
            type="warning"
            message={
              <>
                {OPS_ADMIN_PLAN_PARAMETER}
                <span
                  className={styles.warningLink}
                  onClick={() => {
                    setIsPlanParametersModalOpen(true);
                  }}
                >
                  plan parameters
                </span>{' '}
                that were entered by the broker admin.
              </>
            }
          />
        );
      } else if (
        [QuoteProcessStatus.REVIEWED, QuoteProcessStatus.SUCCESS].includes(
          status
        )
      ) {
        return (
          <>
            {(offer as Offer)?.extractionDetails?.errorThresholdExceeded &&
            !offer?.quoteOfferApproved ? (
              <QuoteProcessingThresholdAlert
                exceeded={offer?.extractionDetails?.errorThresholdExceeded}
                onSubmit={redirectToOpsOnFailedStatus}
              />
            ) : (
              <FixedAlertMessage
                type="warning"
                message={BROKER_ADMIN_EXTRACTION_SUCCESS}
                closeAlert={() => {}}
              />
            )}
          </>
        );
      }
    }
  };

  /**
   * Handles the continue action based on the status of the transfer enrollments modal.
   * @param { Object } value An object containing data to be processed.
   */
  const onContinue = (value: { [key: string]: any }): void => {
    // If the modal status is 'QUOTE' and quote processing is enabled, save as draft.
    if (
      showTransferEnrollmentsModal.status === QUOTE &&
      isQuoteProcessingEnabled
    ) {
      onSaveDraft(value);
      return;
    }
    // If the modal status is 'DRAFT', determine whether to overwrite or save as draft.
    if (showTransferEnrollmentsModal.status === OFFER_STATUS.DRAFT) {
      if (checkExistsPublishedOffer && checkExistsDraftOffer) {
        setShowOverwritePopup(true);
        onPublishOrDraftOfferExternal?.(OFFER_STATUS.DRAFT);
      } else {
        saveAsDraft(value);
      }
      return;
    }
    // If the modal status is 'PUBLISH', determine whether to publish or update offer.
    if (showTransferEnrollmentsModal.status === OFFER_STATUS.PUBLISH) {
      if (checkExistsPublishedOffer && checkExistsDraftOffer) {
        onPublishOrDraftOfferExternal?.(OFFER_STATUS.PUBLISHED);
      } else {
        publishOffer();
        onPublishOrDraftOfferExternal?.(OFFER_STATUS.PUBLISHED);
        defaultUpdateOffer(value, navigateDefault);
      }
      return;
    }
    // Update offer by default.
    defaultUpdateOffer(value, navigateDefault);
    // If 'isBack' flag is set in the modal, navigate to the previous step.
    if (!!showTransferEnrollmentsModal?.isBack) {
      changeCurrentStep(currentStep - 1);
      return;
    }
    // If 'incomingStep' is specified in the modal, navigate to that step.
    if (showTransferEnrollmentsModal?.incomingStep) {
      changeCurrentStep(showTransferEnrollmentsModal?.incomingStep);
      return;
    }
    // Move to the next step by default.
    nextStep && nextStep();
  };

  const handleApproveQuoteOffer = async () => {
    if (offer?.offerId) {
      if (isOpsAdmin) {
        await approveQuotePlan({
          offerId: offer?.offerId ?? '',
        });
        saveAsDraft();
      } else {
        const planSelected = handlePlanSelection({ isBack: false });
        if (planSelected) {
          nextStep?.();
        }
      }
      handleReviewModalState(false);
    }
  };

  const handleSaveProgressAction = () => {
    // Setting as false to save the progress
    saveInProgress();
    handleReviewModalState(false);
    resetQuoteURL();
  };

  const reviewModalSubmitActions = {
    [ActionStatus.APPROVE]: handleApproveQuoteOffer,
    [ActionStatus.SAVE]: handleSaveProgressAction,
  };

  const isDisabledBackButton =
    (!isNullOrUndefined(updatedData) &&
      (disablePlanNext() || showMappedPlansError)) ||
    isOfferCreateLoading ||
    disableOnOpsAdmin ||
    status === QuoteProcessStatus.PROCESSING ||
    isFailed;

  const handlePlanSelectionTypeSwitch = (value: string, option: any) => {
    setSelectedPopupType('CARRIER');
    setSelectedClickedValue(value);
    if (
      isNullOrUndefined(updatedData) &&
      isNull(tempData) &&
      isNull(selectedFile)
    ) {
      updateAddingType(value);
      setAddPlanIndex(value === ADD_PLAN_OPTIONS.MANUAL_PLANS.key ? [0] : []);
      changeDropdownPlansSelected([]);
      setShowMappedPlansHighlight(false);
      changeUpdatedData(null);
      changeQuoteMapping(initialQuoteMappingData);
      resetMappingErrors();
      dispatch(cancel());
    } else {
      setTempSelectType(option?.children ?? option?.label);
      setResetModalVisible(true);
    }
  };

  const rejectFileModalClick = () => {
    setIsShowRejectFileModal(true);
  };

  const shouldDisplayWarning =
    isQuoteProcessingEnabled &&
    offer?.quoteProcessingStatus !== QUOTE_PROCESSING_COMPLETED &&
    !offer?.quoteOfferApproved &&
    isDuelLLMEnable;

  const getNextButtonLabel = () => {
    if (status === QuoteProcessStatus.FAILED) {
      return 'Opt for Additional Processing';
    }
    if (
      offer?.extractionDetails?.status &&
      [
        isOpsAdmin && QuoteProcessStatus.FAILED,
        QuoteProcessStatus.SUCCESS,
        QuoteProcessStatus.REVIEWED,
      ].includes(offer?.extractionDetails?.status)
    ) {
      return offer?.quoteOfferApproved ? 'Next' : 'Approve';
    }
    return 'Next';
  };

  if (benefitCarrierIsFetching) {
    return <Spin />;
  }

  return (
    <>
      {offerAttachedProposal?.isFinalApproved && (
        <div className={styles.generalErrorWrapper}>
          <FixedAlertMessage
            type="info"
            message={FIXED_ALERT_MESSAGE_IF_FINAL_APPROVED}
          />
        </div>
      )}
      <div className={styles.addPlansWrapper}>
        <Row justify="center">
          {showPlanTable() && (
            <>
              {validateUpdatedData() && (
                <FixedAlertMessage
                  type="error"
                  message={DUPLICATE_PLANS_DETECTED}
                />
              )}
              {showMappedPlansError && (
                <FixedAlertMessage
                  type="info"
                  message={ALL_PLAN_MAPPUING_INFO}
                />
              )}
              {offer?.isOfferedPlanProcessing && (
                <FixedAlertMessage
                  message={OFFERED_PLAN_PROCESSING_WARNING}
                  type="warning"
                />
              )}
            </>
          )}
        </Row>
        <Row justify="center">
          <Col span={24}>
            <label className={styles.chooseFileLabel}>
              How would you like to add plans for this carrier?
            </label>
            <Select
              size="large"
              disabled={
                existingPlanIsLoading ||
                disableOnOpsAdmin ||
                isProcessing ||
                disableOnSelfReview ||
                isFailed
              }
              onChange={handlePlanSelectionTypeSwitch}
              value={selectedAddingType}
              className={
                selectedAddingType === ''
                  ? styles.addingOptionDropdownEmpty
                  : styles.addingOptionDropdown
              }
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {!employerDetails?.isDemoAccount &&
              !isVoluntaryBenefit(benefitKind ?? '')
                ? Object.values(ADD_PLAN_OPTIONS).map(
                    (option: { key: string; label: string }) => (
                      <Select.Option value={option.key} key={option.key}>
                        {option.label}
                      </Select.Option>
                    )
                  )
                : Object.values(DEMO_ACCOUNT_PLAN_OPTIONS).map(
                    (option: { key: string; label: string }) => (
                      <Select.Option value={option.key} key={option.key}>
                        {option.label}
                      </Select.Option>
                    )
                  )}
            </Select>
            {ADD_PLAN_OPTIONS.EXISTING_PLANS.key === selectedAddingType && (
              <div className={styles.planDropdownWrapper}>
                <label className={styles.chooseFileLabel}>Select Plans</label>
                <div className={styles.planDropdownComponentWrapper}>
                  <PlanDropDown
                    selectOptions={mapAllPlansObject(
                      allPlans,
                      (isMultipleBenClass ?? false) && checkMDV(benefitKind)
                    )}
                    dropdownPlansSelected={dropdownPlansSelected}
                    changeDropdownPlansSelected={changeDropdownPlansSelected}
                    onSystemPlanSave={onSystemPlanSave}
                    isLoading={existingPlanIsLoading}
                    isFundingTypeApplicable={checkMDV(benefitKind ?? '')}
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
      {existingPlanIsLoading && (
        <div className={styles.spinnerWrapper}>
          <Spin />
          <p className={styles.loadingText}>
            Loading Plans - This may take a few moments...
          </p>
        </div>
      )}
      {renderQuoteProcessingWarnings()}

      <div className={styles.planWrapper}>
        {showPlanTable() && (
          <>
            {shouldDisplayWarning && (
              <Row className={styles.createPlanHeader}>
                <AIFeedbackRedFlag />
                {LLM_WARNING_TITLE_TEXT_QUOTE_READING}
                <PlanyearPopover
                  content={LLM_EXTRACTION_POPOVER_TEXT_INFO}
                  placement="left"
                >
                  <QuestionCircleOutlined />
                </PlanyearPopover>
              </Row>
            )}
            <div className={`${styles.planTable}`}>
              <PlanTable
                data={tempData === null ? updatedData : tempData}
                labelColumnData={arrangeColData}
                titleProperty={NAME_FIELD_PROPERTY}
                logoProperty={benefitCarrier?.logoUrl}
                benefitType={URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? '']}
                editTable={true}
                removePlans={true}
                updateData={tempData === null ? changeUpdatedData : updatedData}
                changeUpdatedData={changeUpdatedData}
                mapPlanDroDownData={updatedDropDownValues}
                mapDropDownDataOnChange={setMappedPlans}
                mapDropdownSelectedValues={mappedPlans}
                addPlan={true}
                mapToPlan={true}
                setAddPlanIndex={setAddPlanIndex}
                addPlanIndex={addPlanIndex}
                changeManualPlanDropdownPlansSelected={
                  changeDropdownPlansSelected
                }
                manualSelectDropDownValues={dropdownPlansSelected}
                showValidationsOnLoad={
                  selectedAddingType === ADD_PLAN_OPTIONS.CARRIER_QUOTE.key
                }
                triggerAllValidations={isDtqOrPending(offer?.offerStatus)}
                showMappedPlansHighlight={showMappedPlansHighlight}
                showRequired={!isDtqOrPending(offer?.offerStatus)}
                isEditOffer={isEdit}
                premiumType={premiumType}
                nTierCount={nTierCount}
                isImportPlans={
                  selectedAddingType === ADD_PLAN_OPTIONS.EXISTING_PLANS.key
                }
                isNoCurrentPlans={isNoCurrentPlans}
                isProposalsAttached={isProposalsAttached()}
                fundingType={offer.fundingType}
                validateFormatting={validateFormatting()}
                isQuoteProcessingEnabled={isQuoteProcessingEnabled}
                extractedDocument={offer?.extractionDetails}
                isQuotePlansApproved={offer?.quoteOfferApproved}
                showFlagItemWarning={shouldDisplayWarning}
              />
            </div>
          </>
        )}

        {/* // SECTION - QUOTE UPLOADER */}
        {showQuoteDocumentUploader() && (
          <Row justify="center">
            <QuoteDocumentUploader
              changeUpdatedData={changeUpdatedData}
              changeSelectedFile={changeSelectedFile}
              setMappedPlans={setMappedPlans}
              selectedFile={selectedFile}
              isEdit={isEdit}
              editData={editData}
              documentDetails={documentDetails}
              benefitKind={benefitKind}
              changeQuoteMapping={changeQuoteMapping}
              showQuoteMappingHighlight={showQuoteMappingHighlight}
              mappedPlans={mappedPlans}
              currentPlans={currentPlans}
              quoteMapping={quoteMapping}
              employerId={employerId}
              brokerId={brokerId}
              setAddPlanIndex={setAddPlanIndex}
              showPlanIdentifierError={showPlanIdentifierError}
              benefit={benefitKind?.toUpperCase()}
              validatePlanIdentifiers={validatePlanIdentifiers}
              handlePlanSelectionTypeSwitch={handlePlanSelectionTypeSwitch}
              resetMappingErrors={resetMappingErrors}
              handleNext={(shouldInitialize) =>
                triggerNextStep(false, shouldInitialize)
              }
              isOfferCreateLoading={isOfferCreateLoading}
              isAllDisabled={isFailed}
            />
          </Row>
        )}

        {/*  Next and Back section */}
        <Row className={styles.btnRow} gutter={16}>
          {showQuoteMappingError && quotePlanMappingError()}
          <Col className={styles.backBtnWrapper} span={8}>
            <SubmitButton
              loading={isOfferCreationLoading}
              disabled={isDisabledBackButton}
              className={styles.cancelButton}
              onClick={handleBack}
            >
              Back
            </SubmitButton>
          </Col>
          <Col span={8}>
            <NextButton
              nextStep={handleNext}
              loading={isOfferCreationLoading}
              className={styles.nextBtn}
              disabled={isDisabledNextButton()}
              buttonText={getNextButtonLabel()}
            />
          </Col>
          {(!isQuoteProcessingEnabled || showSaveAsDraftForQuotes) && (
            <Col span={24} className={styles.saveDraftBtn}>
              <TextButton
                label="Save Draft & Close"
                disabled={
                  (!isNullOrUndefined(updatedData) &&
                    (disablePlanNext() || showMappedPlansError)) ||
                  isOfferCreateLoading
                }
                onClick={() => onSaveDraft()}
                type="primary"
              />
            </Col>
          )}
        </Row>
        {isQuoteProcessingEnabled &&
          offer?.extractionDetails?.status &&
          ![QuoteProcessStatus.PROCESSING, QuoteProcessStatus.SAVED].includes(
            offer?.extractionDetails?.status
          ) && (
            <Row className={styles.rejectFileRow}>
              <LinkButton
                loading={isOfferCreateLoading}
                onClick={() => handleReviewModalState(true, ActionStatus.SAVE)}
                disabled={[QuoteProcessStatus.REJECTED].includes(status)}
              >
                Save Progress & Close
              </LinkButton>
            </Row>
          )}
        {isOpsAdmin && (
          <Row>
            <Col span={24}>
              <Row className={styles.rejectFileRow}>
                <LinkButton
                  loading={isOfferCreateLoading}
                  onClick={rejectFileModalClick}
                  disabled={[
                    QuoteProcessStatus.REVIEWED,
                    QuoteProcessStatus.SAVED,
                    QuoteProcessStatus.REJECTED,
                  ].includes(status)}
                >
                  Reject File
                </LinkButton>
              </Row>
            </Col>
          </Row>
        )}
      </div>
      <ConfirmationDialog
        confirmText={'Yes - Reset Plans'}
        visible={resetModalVisible}
        title={
          selectedPopupType === 'UPLOAD'
            ? 'Re-Upload Plans'
            : `Switch to ${tempSelectType}`
        }
        isCancelLink={true}
        cancelText="Cancel"
        onConfirm={handleConfirm}
        closeModal={() => setResetModalVisible(false)}
        buttonsExpanded={true}
      >
        Performing this action will reset all plans. Are you sure you want to
        continue?
      </ConfirmationDialog>
      <TransferEnrollmentsModal
        isEdit={isEdit}
        visible={showTransferEnrollmentsModal.show}
        onContinue={onContinue}
        onClose={() => {
          setShowTransferEnrollmentsModal({
            show: false,
            status: null,
            isBack: false,
          });
        }}
        mappedPlans={mappedPlans}
        currentPlanList={currentPlansDropdownData}
        newPlanList={
          isQuoteProcessingEnabled
            ? getPlanNameListForQuotes()
            : getPlanNameList()
        }
        alreadyMappedData={getDiscontinuedPlanMapValueUsingKeyRefId()}
      />
      {isPlanParametersModalOpen && disableOnOpsAdmin && (
        <PlanParametersModal
          isOpen={isPlanParametersModalOpen}
          onClose={() => setIsPlanParametersModalOpen(false)}
          quoteMapping={quoteMapping ?? []}
          benefitKind={benefitKind}
          offer={offer}
        />
      )}
      <QuoteReviewModal
        onClose={() => handleReviewModalState(false)}
        onConfirm={reviewModalSubmitActions[reviewModalState.action!]}
        isLoading={isOfferCreateLoading || isApprovalLoading}
        reviewModalState={reviewModalState}
        {...(reviewModalState.action === ActionStatus.APPROVE && {
          confirmText: 'Approve Plans',
        })}
        content={reviewModalContent[reviewModalState.action!]}
      />
      <RejectFileModal
        jobId={offer?.extractionDetails?.id}
        isModalShow={isShowRejectFileModal}
        setIsModalShow={setIsShowRejectFileModal}
        type={AIRejectType.QUOTE_REJECT}
      />
    </>
  );
};

export default forwardRef(AddPlans);
