import { navContexts } from 'constants/authConstants';
import { useNavContext } from 'hooks/useNavContext';
import BrokerEmployerCarrierTabView from 'modules/carriers/components/BrokerEmployerCarrierTabView/BrokerEmployerCarrierTabView';
import CarrierList from 'modules/carriers/components/CarrierList/CarriersList';

const BrokerEmployerCarrierBoard = () => {
  const { context } = useNavContext();
  const { broker } = navContexts;

  return (
    <div>
      {context !== broker && <CarrierList />}
      {context === broker && <BrokerEmployerCarrierTabView />}
    </div>
  );
};

export default BrokerEmployerCarrierBoard;
