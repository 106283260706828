import { forwardRef, useState } from 'react';

import { Form, Input } from 'antd';
import { useAppSelector } from 'hooks/redux';
import InputForm from 'components/InputForm/InputForm';
import MedicalPlan from 'model/plans/MedicalPlan';

import { RxCardInfoFields } from 'modules/plans/constants';

import styles from './editRxCardInfo.module.less';

type RxCardInfoProps = {
  onChange: Function;
  isDBGPlan?: boolean;
  medicalPlan?: MedicalPlan;
  isUPEdit?: boolean;
};

type FormDataType = {
  rxBinNumber?: string;
  rxPcnNumber?: string;
  rxGroupNumber?: string;
};

const EditRxCardInfo = forwardRef((props: RxCardInfoProps, ref) => {
  const plan = useAppSelector((state) => state.plan.plans);
  const medicalPlan = props.medicalPlan ? props.medicalPlan : plan.medicalPlan;
  const [form] = Form.useForm();

  const [formData] = useState<FormDataType>({
    rxBinNumber: medicalPlan.rxBinNumber,
    rxPcnNumber: medicalPlan.rxPcnNumber,
    rxGroupNumber: medicalPlan.rxGroupNumber,
  });

  const { onChange: _onChange } = props;

  const onInputChange = async (currentChange: any, allValues: any) => {
    const newValues: { [key: string]: any } = {};
    if (allValues.rxBinNumber || allValues.rxBinNumber === '') {
      newValues.rxBinNumber = allValues.rxBinNumber;
    }
    if (allValues.rxPcnNumber || allValues.rxPcnNumber === '') {
      newValues.rxPcnNumber = allValues.rxPcnNumber;
    }
    if (allValues.rxGroupNumber || allValues.rxGroupNumber === '') {
      newValues.rxGroupNumber = allValues.rxGroupNumber;
    }

    const updatedObject = {
      ...medicalPlan,
      ...formData,
      ...newValues,
    };

    _onChange({ ...updatedObject });
  };

  return (
    <div className={styles.editRxCardInfo}>
      <InputForm form={form} onValuesChange={onInputChange}>
        <Form.Item
          name={RxCardInfoFields.RX_BIN_NUMBER}
          label="Rx Bin #"
          labelCol={{ span: 24 }}
        >
          <Input defaultValue={formData.rxBinNumber} />
        </Form.Item>
        <Form.Item
          name={RxCardInfoFields.RX_PCN_NUMBER}
          label="Rx PCN #"
          labelCol={{ span: 24 }}
        >
          <Input defaultValue={formData.rxPcnNumber} />
        </Form.Item>
        <Form.Item
          name={RxCardInfoFields.RX_GROUP_NUMBER}
          label="Rx Group #"
          labelCol={{ span: 24 }}
        >
          <Input defaultValue={formData.rxGroupNumber} />
        </Form.Item>
      </InputForm>
    </div>
  );
});

EditRxCardInfo.displayName = 'EditRxCardInfo';

export default EditRxCardInfo;
