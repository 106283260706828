import {
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import { Collapse, Form, notification, Spin } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import {
  removePlanDocuments,
  updateWeblinks,
  uploadPlanDocument,
  uploadRemovePlanDocument,
} from 'modules/plans/slices/planDocumentsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import BasicPlanInformation from 'modules/plans/life/components/BasicPlanInformation/BasicPlanInformation';
import ResourceInformation from 'modules/plans/components/ResourceInformation/ResourceInformation';
import PlanBasicForm from 'modules/plans/components/PlanBasicForm/PlanBasicForm';
import DisabilityPlanInformation from 'modules/plans/life/components/DisabilityPlanInformation/DisabilityPlanInformation';
import VoluntaryPlanInformation from 'modules/plans/life/components/VoluntaryPlanInformation/VoluntaryPlanInformation';

import {
  BenefitCategory,
  EMPTY_MESSAGE,
  FEATURE_KEYS,
  formVerificationMsg,
  planAlreadyExistsError,
} from 'constants/commonConstants';
import {
  BasicPlans,
  PANEL_HEADINGS_LONG,
  PlanApproach,
  VALIDATION_NAME_DUPLICATED,
  VoluntaryPlans,
  TooltipHeadings,
  Tooltips,
  LIFE_FEEDBACK_PANEL_TYPES,
  PLAN_ADDITIONAL_DOCUMENT,
} from 'modules/plans/constants';
import { LifePlan } from 'model/plans/LifePlan';
import { getCarriersByBenefitKind } from 'modules/plans/slices/basicPlanInfoSlice';
import {
  clearLifePlanApiErrors,
  resetPlanReduxStore,
  saveLifePlan,
  updateLifePlanCompleted,
  removeUserAiFeedback,
  setUserAiFeedback,
} from 'modules/plans/slices/lifePlanSlice';
import { getValidationMessage } from 'util/commonUtil';
import AiFeedbackReceiver from 'modules/plans/components/AiFeedbackReceiver/aiFeedbackReceiver';
import { AIUploaderFeedback } from 'model/plans/UserFeedback';
import { PLAN_NOTIFICATION_KEY } from 'constants/benguideCollaborationConstants';
import BenefitCarrier from 'model/BenefitCarrier';
import { AddPlanFormProps, LifePlanFormData } from 'modules/plans/types/types';
import FundingType from 'modules/plans/enums/FundingType';
import PanelHeaderExtra from 'modules/plans/components/PanelHeaderExtra/PanelHeaderExtra';
import PanelSection from 'modules/plans/enums/PanelSection';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import UploaderType from 'modules/plans/enums/UploaderType';
import NextButton from 'components/buttons/NextButton/NextButton';
import PanelFooter from 'modules/plans/components/PanelFooter/PanelFooter';
import { DocumentExtractionSource } from 'modules/plans/enums/DocumentExtractionSource';
import {
  handleScrollToNextPanel,
  transformToFormData,
  createMockPlanObject,
} from 'modules/plans/utils';
import useGetUserFeatureStatus from 'hooks/useGetUserFeatureStatus';
import PlanUpdateWarningModal, {
  UpdateDocumentExtractionSource,
} from 'modules/plans/components/PlanUpldateWarningModal/PlanUpdateWarningModal';

import AdditionalPlanResources from 'modules/plans/components/AdditionalPlanResources/AdditionalPlanResources';
import FileType from 'model/plans/FileType';
import WebLinkType from 'model/plans/WebLinkType';

import styles from './addLifePlanPanelForm.module.less';

const FORM_DEFAULT_VALUES = {
  planName: '',
  planYear: '',
  benefitCarrier: '',
  benefitClass: [],
  lifeBenefit: '',
  waitingPeriod: '',
  employeeBenefit: '',
  spouseBenefit: '',
  childBenefit: '',
  documents: {},
  fundingType: '',
  administrationFee: '',
  volume: undefined,
  enrollment: undefined,
};

const DEFAULT_VISITED_STATUS = {
  [PanelSection.BASIC_PLAN_INFO]: false,
  [PanelSection.PLAN_INFORMATION]: false,
  [PanelSection.ADDITIONAL_RESOURCES]: false,
};

const PANELS_IN_ORDER = [
  PanelSection.BASIC_PLAN_INFO,
  PanelSection.PLAN_INFORMATION,
  PanelSection.ADDITIONAL_RESOURCES,
];

const FORM_FIELDS = {
  REQUIRED: [
    'planName',
    'planYear',
    'benefitClass',
    'benefitCarrier',
    'fundingType',
  ],
  ALL: [
    'planName',
    'planYear',
    'benefitCarrier',
    'benefitClass',
    'fundingType',
    'administrationFee',
    'groupId',
    'enrollment',
  ],
};

type TrackPanelChanges = {
  planInformation: boolean;
};

const getSBCDefaultValues = (): TrackPanelChanges => {
  return {
    planInformation: true,
  };
};

const getDefaultNonSBCValues = (): TrackPanelChanges => {
  return {
    planInformation: false,
  };
};

const Panel = Collapse.Panel;

const AddLifePlanPanelForm = forwardRef((props: AddPlanFormProps, ref) => {
  const {
    isOpen,
    type,
    dbgPlanYear,
    onError,
    isDisable,
    isLoading,
    planApproach,
    planUploderType,
    status = ProcessStatus.CANCELLED,
    jobId,
    onSave,
    isReview,
    skipFailedDocument,
    formRightWrapperRef,
    docExtractionSource,
  } = props;

  const lifePlan = useAppSelector((state) => state.plan.lifePlan.lifePlan);
  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );
  const [activePanel, setActivePanel] = useState<string>('');
  const [isVisitedByPanel, setIsVisitedByPanel] = useState(
    DEFAULT_VISITED_STATUS
  );
  const [showUpdatePlanWarning, setShowUpdatePlanWarning] =
    useState<boolean>(false);
  const [isCompleteByPanel, setIsCompleteByPanel] = useState(
    DEFAULT_VISITED_STATUS
  );
  const [isSBCPanelVsited, setSBCPanelVisited] = useState<TrackPanelChanges>(
    getDefaultNonSBCValues()
  );
  const [requiredFieldError, setRequiredFieldError] = useState<boolean>(false);
  const [selectedCarrier, setSelectedCarrier] = useState<
    BenefitCarrier | undefined
  >();
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [formData, setFormData] = useState<LifePlanFormData>({
    ...FORM_DEFAULT_VALUES,
  });
  const basicPlanInfo = useAppSelector((state) => state.plan.planBasicInfo);
  const { planYearsList } = useAppSelector((state) => state.employer.employer);
  const { lifePlanServiceList } = useAppSelector(
    (state) => state.plan.lifePlan
  );
  const {
    inProgress,
    error,
    documentReferences: lifeDocumentReferences,
    planFeedback,
  } = useAppSelector((state) => state.plan.lifePlan);
  const plan = useSelector((state: any) => state.plan.plans);
  const { inProgress: documentUploadInProgress } = useAppSelector(
    (state) => state.plan.planDocuments
  );

  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const { carriersList } = basicPlanInfo;
  const benefitKind = type ? type : '';

  const basicPlanInfoRef = useRef<any>();
  const planInformationRef = useRef<any>();
  const documentsRef = useRef<any>();
  const [notificationKey] = useState(PLAN_NOTIFICATION_KEY);
  const [showPlanYearWarning, setShowPlanYearWarning] =
    useState<boolean>(false);

  const [selectedFileList, setSelectedFileList] = useState<FileType[]>([]);
  const [isDocRemoved, setIsDocRemoved] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedWeblink, setSelectedWeblink] = useState<WebLinkType[]>([]);

  const disableOnUpdatePlan =
    !isEmpty(docExtractionSource) &&
    !isOpsAdmin &&
    [ProcessStatus.REVIEWED].includes(status);

  const isFromUpdatePan = !isEmpty(docExtractionSource);
  const isAnUpdateOfferedPlan =
    isFromUpdatePan &&
    docExtractionSource?.source === DocumentExtractionSource.UPDATE_QUOTE_PLAN;

  const getPanelClassName = (panel: PanelSection): string => {
    const defaultValues = getSBCDefaultValues();
    const isPanelInDefaultValues = Object.keys(defaultValues).includes(panel);
    const showUpdatePlanPanels = isFromUpdatePan && !isPanelInDefaultValues;

    return showUpdatePlanPanels
      ? styles.disabledPanelColor
      : isVisitedByPanel[panel as keyof typeof isVisitedByPanel]
      ? styles.visitPanel
      : styles.notVisitPanel;
  };

  const isSaveDisabled =
    (isReview && !disableOnUpdatePlan) ||
    (!disableOnUpdatePlan && !isVisitedByPanel.basicPlanInfo) ||
    inProgress ||
    isDisable ||
    isLoading ||
    documentUploadInProgress ||
    planApproach === PlanApproach.NOT_SELECTED;

  useImperativeHandle(ref, () => ({
    setActivePanel,
    getPlan: () => getPlanData(),
    reset: () => {
      setIsCompleteByPanel(DEFAULT_VISITED_STATUS);
      setIsVisitedByPanel(DEFAULT_VISITED_STATUS);
    },
  }));

  useEffect(() => {
    if (lifePlan) {
      setFormData(transformToFormData(lifePlan));
      if (!isEmpty(lifePlan.lifeBenefit)) {
        setSelectedOption(lifePlan.lifeBenefit ?? '');
      }
    }
  }, [lifePlan]);

  // Below triggers to set the values as AI when job id is available
  useEffect(() => {
    if (
      [
        ProcessStatus.PROCESSING,
        ProcessStatus.REVIEWED,
        isReview && (ProcessStatus.SUCCESS || ProcessStatus.SAVED),
      ].includes(status)
    ) {
      setSBCPanelVisited(getSBCDefaultValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, jobId]);

  useEffect(() => {
    if (brokerId && employerId && isOpen) {
      setShowPlanYearWarning(false);
      dispatch(resetPlanReduxStore());
      dispatch(
        getCarriersByBenefitKind(
          BenefitCategory.LIFE.value,
          brokerId,
          employerId
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, brokerId, employerId, isOpen]);

  useEffect(() => {
    if (formData.benefitCarrier) {
      setSelectedCarrier(
        carriersList.find(
          (carrier: any) => carrier.id === formData.benefitCarrier
        )
      );
    }
  }, [carriersList, formData.benefitCarrier]);

  /** For error handling */
  useEffect(() => {
    if (requiredFieldError) {
      onError?.('Please fill in all required fields');
    }
  }, [requiredFieldError, onError]);

  useEffect(() => {
    if (error?.data?.code === VALIDATION_NAME_DUPLICATED) {
      setActivePanel(PanelSection.BASIC_PLAN_INFO);
      form.setFields([{ name: 'planName', errors: [EMPTY_MESSAGE] }]);
      onError?.(planAlreadyExistsError);
      dispatch(clearLifePlanApiErrors());
      setShowUpdatePlanWarning(false);
    }

    return () => {
      dispatch(clearLifePlanApiErrors());
    };
  }, [dispatch, error, form, onError]);

  const onPlanChange = useCallback(
    (plan: LifePlan) => {
      dispatch(updateLifePlanCompleted(plan));
    },
    [dispatch]
  );

  const validateBasicInfo = async () => {
    try {
      await form.validateFields(FORM_FIELDS.REQUIRED);
      return true;
    } catch (errorInfo: any) {
      return errorInfo.errorFields.length === 0;
    }
  };

  const getValidationResult = async () => {
    const isFormValid = await validateBasicInfo();
    if (isFormValid) {
      return true;
    } else {
      return false;
    }
  };

  const showFeedbackPanel =
    planApproach === PlanApproach.SBC &&
    !isReview &&
    docExtractionSource?.source !== DocumentExtractionSource.UPDATE_QUOTE_PLAN;

  const getPlanData = () => {
    const benefitCategory = BenefitCategory.LIFE.value;
    const selectedPlanYear = planYearsList.find(
      (t) => t.id === formData.planYear
    );
    const carrierId = formData.benefitCarrier;
    const planData = {
      benefitKind: type,
      benefitCarrier: {
        name: carrierId ? selectedCarrier?.name : null,
        benefitCategory: benefitCategory,
        id: carrierId ? selectedCarrier?.id : null,
      },
      employerId: employerId,
      endDate: selectedPlanYear?.endDate,
      name: formData.planName,
      planYearId: selectedPlanYear?.id,
      startDate: selectedPlanYear?.startDate,
      groups: formData.benefitClass,
      multiplier: formData.multiplier,
      flatAmount: formData.flatAmount,
      benefitMaximum: formData.benefitMaximum,
      guaranteedIssue: formData.guaranteedIssue,
      lifeRate: formData.lifeRate,
      lifeBenefit: formData.lifeBenefit,
      addRate: formData.addRate,
      benefitPercentage: formData.benefitPercentage,
      weeklyBenefitMax: formData.weeklyBenefitMax,
      monthlyBenefitMax: formData.monthlyBenefitMax,
      waitingPeriod: formData.waitingPeriod,
      benefitDurationSTD: formData.benefitDurationSTD,
      benefitDurationLTD: formData.benefitDurationLTD,
      rate: formData.rate,
      employeeBenefit: formData.employeeBenefit,
      employeeGuaranteedIssue: formData.employeeGuaranteedIssue,
      spouseBenefit: formData.spouseBenefit,
      spouseGuaranteedIssue: formData.spouseGuaranteedIssue,
      childBenefit: formData.childBenefit,
      childGuaranteedIssue: formData.childGuaranteedIssue,
      documentReferences: lifeDocumentReferences,
      documents: formData.documents,
      latest: true,
      fundingType: formData.fundingType,
      groupId: formData.groupId?.trim(),
      volume: formData.volume,
      enrollment: formData.enrollment,
      administrationFee: formData.administrationFee,
      annualEstimatedClaims: formData.annualEstimatedClaims,
      textractJobId: lifePlan?.textractJobId,
      planFeedback: planFeedback,
      ageReduction: formData?.ageReduction,
      waiverOfPremium: formData?.waiverOfPremium,
      acceleratedLifeBenefit: formData?.acceleratedLifeBenefit,
      portability: formData?.portability,
      definitionOfEarnings: formData?.definitionOfEarnings,
      lifeServices: lifePlanServiceList,
      additionalServices: formData?.additionalServices,
      stdServices: lifePlanServiceList,
      ltdServices: lifePlanServiceList,
      definitionOfDisability: formData?.definitionOfDisability,
      preExistingConditions: formData?.preExistingConditions,
      stateDisabilityIntegration: formData?.stateDisabilityIntegration,
      ownOccupationPeriod: formData?.ownOccupationPeriod,
      w2Preparation: formData?.w2Preparation,
      llmExtractionInfo: lifePlan?.llmExtractionInfo,
    } as LifePlan;
    if (formData.fundingType === FundingType.SELF_FUNDED) {
      planData.sfcPlanConfigVo = {
        employerId: planData.employerId,
        planYearId: planData.planYearId,
        carrierId: planData.benefitCarrier?.id,
        benefitKind: planData.benefitKind,
        administrationFee: formData.administrationFee,
      };
      planData.sfcPlanDataVO = {
        selfFundedClaimsStatus: true,
      };
    } else {
      planData.sfcPlanDataVO = {
        selfFundedClaimsStatus: false,
      };
    }

    if (formData.fundingType === FundingType.SELF_FUNDED) {
      planData.sfcPlanConfigVo = {
        employerId: planData.employerId,
        planYearId: planData.planYearId,
        carrierId: planData.benefitCarrier?.id,
        benefitKind: planData.benefitKind,
        administrationFee: formData.administrationFee,
      };
      planData.sfcPlanDataVO = {
        selfFundedClaimsStatus: true,
      };
    } else {
      planData.sfcPlanDataVO = {
        selfFundedClaimsStatus: false,
      };
    }

    return planData;
  };

  const savePlan = async (skipForUpdate?: boolean) => {
    setShowUpdatePlanWarning(false);
    const planData = getPlanData();
    let updatedPlan = cloneDeep(planData);
    if (skipForUpdate) {
      updatedPlan = { ...updatedPlan, ...createMockPlanObject() };
    }
    await dispatch(
      saveLifePlan(updatedPlan, (isSuccess?: boolean, id?: string) =>
        uploadDocument(id, isSuccess)
      )
    );
    notification.close(notificationKey);
  };

  const handleSubmit = async () => {
    setShowUpdatePlanWarning(false);
    const isFormValid = await getValidationResult();
    onError?.('');
    setShowPlanYearWarning(false);

    if (!isEmpty(docExtractionSource)) {
      return setShowUpdatePlanWarning(true);
    } else if (isFormValid) {
      savePlan();
    } else {
      onError?.(formVerificationMsg);
    }
  };

  const onValidateFails = (validateSetting: string) => {
    onError?.(getValidationMessage(validateSetting));
  };

  const handleRemoveDocument = (docType: string) => {
    const clonedDocuments = { ...formData.documents };
    delete clonedDocuments[docType];
    setFormData((prevData: any) => ({
      ...prevData,
      documents: clonedDocuments,
    }));
  };

  const planInformationSection = () => {
    switch (type) {
      case BasicPlans.BASIC_ADD.value:
      case BasicPlans.BASIC_LIFE.value:
      case BasicPlans.BASIC_LIFE_AND_ADD.value:
        return (
          <BasicPlanInformation
            isEditMode
            isTitleVisible={false}
            isCancelButtonClickConfirm={!isOpen}
            setFormData={setFormData}
            formData={formData}
            lifePlanType={type}
            ref={planInformationRef}
            setAlertMessage={({ message }: Record<string, string>) => {
              onError?.(message);
            }}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            isAdvancedProfile={isAdvancedProfile}
            docExtractionSource={docExtractionSource}
          />
        );
      case BasicPlans.STD.value:
      case BasicPlans.LTD.value:
      case VoluntaryPlans.VOLUNTARY_STD.value:
      case VoluntaryPlans.VOLUNTARY_LTD.value:
        return (
          <DisabilityPlanInformation
            isEditMode
            isTitleVisible={false}
            isCancelButtonClickConfirm={!isOpen}
            setFormData={setFormData}
            formData={formData}
            lifePlanType={type}
            ref={planInformationRef}
            cancelButtonClicked={!isOpen}
            fundingType={formData.fundingType}
            isAdvancedProfile={isAdvancedProfile}
            docExtractionSource={docExtractionSource}
          />
        );
      case VoluntaryPlans.VOLUNTARY_LIFE.value:
      case VoluntaryPlans.VOLUNTARY_ADD.value:
      case VoluntaryPlans.VOLUNTARY_LIFE_ADD.value:
        return (
          <VoluntaryPlanInformation
            isEditMode
            isTitleVisible={false}
            isCancelButtonClickConfirm={!isOpen}
            setFormData={setFormData}
            formData={formData}
            lifePlanType={type}
            ref={planInformationRef}
          />
        );
    }
  };

  const getPlanDefaultName = (lifePlanType: string) => {
    return Object.values(BasicPlans)
      .filter(({ value }) => value === lifePlanType)
      .map(({ label }) => label)[0];
  };

  /** Function that is run in the case a valid section's header is clicked.
   * @param {any} nextKey The next key to be set as the active panel
   */
  const handleCollapseChange = (nextKey: any) => {
    setActivePanel(nextKey);
    setIsVisitedByPanel((prev) => ({
      ...prev,
      [nextKey]: true,
    }));
  };

  const getPanelHeader = (panel: PanelSection) => {
    const panelNumber = PANELS_IN_ORDER.indexOf(panel) + 1;
    const panelTitle = PANEL_HEADINGS_LONG[panel];
    return (
      <span
        className={getPanelClassName(panel)}
      >{`${panelNumber}. ${panelTitle}`}</span>
    );
  };

  const getPanelHeaderExtra = (
    panel: PanelSection,
    isAiCheck?: boolean,
    isSBC?: boolean
  ) => {
    const panelKey = Object.keys(PanelSection)[
      Object.values(PanelSection).indexOf(panel)
    ] as keyof typeof Tooltips;

    return (
      planApproach !== PlanApproach.NOT_SELECTED && (
        <PanelHeaderExtra
          isSBC={isSBC}
          isAi={isAiCheck}
          isOpsAdmin={isReview}
          isActive={activePanel === panel}
          isFromUpdatePan={isFromUpdatePan}
          isComplete={
            isCompleteByPanel[panel as keyof typeof isCompleteByPanel]
          }
          popoverContent={
            Tooltips[panelKey] && (
              <div>
                <QuestionCircleOutlined />
                <span className="text popover-title">
                  {TooltipHeadings[panelKey]}
                </span>
                <p> {Tooltips[panelKey]}</p>
              </div>
            )
          }
          isUploading={[ProcessStatus.PROCESSING].includes(status!)}
        />
      )
    );
  };

  const getCollapsible = (enabled: boolean): 'disabled' | undefined => {
    const disablCollapsible = [
      ProcessStatus.VALIDATING,
      ProcessStatus.VALIDATED,
      ProcessStatus.PROCESSING,
      !isReview && ProcessStatus.SUCCESS,
    ].includes(status!);
    if (!(planApproach !== PlanApproach.NOT_SELECTED && enabled))
      return 'disabled';
    else if (disablCollapsible) return 'disabled';
  };

  /**
   * We pass this prop object rather than making a new Panel componnent that consolidates this logic
   * as when we do that, the collapsible does not work properly. It does not expand or collapse when clicked.
   * @param {PanelSection} section Which panel section
   * @return {Object} Props object
   */
  const getPanelProps = (section: PanelSection) => {
    let isEnabled: boolean =
      isVisitedByPanel[section as keyof typeof isVisitedByPanel];
    if (planUploderType === UploaderType.OPS_VIEW) {
      isEnabled = section === PanelSection.PLAN_INFORMATION;
    }

    return {
      header: getPanelHeader(section),
      key: section,
      showArrow: false,
      collapsible: getCollapsible(isEnabled),
      extra: getPanelHeaderExtra(
        section,
        isSBCPanelVsited[section as keyof typeof isSBCPanelVsited],
        section === PanelSection.PLAN_INFORMATION ? !isEmpty(jobId) : false
      ),
    } as const;
  };

  /**
   * This function is used to create the next step handler for each panel section. It is used to validate the passed in section
   * and then move to the next panel if the validation passes while recording the touched and completion status of the panel.
   * @param {MutableRefObject<any>} ref The ref of the panel. Either the ref of the form itself or the wrapper
   * @param {PanelSection} section The current panel section
   * @return {Function} The next step handler
   */
  const createNextStepHandler =
    (ref: MutableRefObject<any>, section: PanelSection) => async () => {
      const { isComplete = true, isValid = true } =
        (await ref.current?.validate?.()) ?? {};
      if (isValid) {
        onError?.('');
        dispatch(updateLifePlanCompleted(getPlanData()));
        const crntPanelIdx = PANELS_IN_ORDER.indexOf(section);
        const nextSection =
          crntPanelIdx !== -1
            ? PANELS_IN_ORDER[PANELS_IN_ORDER.indexOf(section) + 1]
            : undefined;
        setActivePanel(nextSection ?? ('' as PanelSection));
        setIsVisitedByPanel((prev) => ({ ...prev, [section]: true }));
        setIsCompleteByPanel((prev) => ({
          ...prev,
          [section]: isComplete,
        }));
        handleScrollToNextPanel(section, formRightWrapperRef);

        if (section === PanelSection.PLAN_INFORMATION) {
          setSBCPanelVisited({
            ...isSBCPanelVsited,
            [PanelSection.PLAN_INFORMATION]: false,
          });
        }
      }
    };

  const addAiFeedback = (planFeedback: AIUploaderFeedback) => {
    dispatch(setUserAiFeedback(planFeedback));
  };

  const removeAiFeedback = (feedbackPanel: string) => {
    dispatch(removeUserAiFeedback(feedbackPanel));
  };
  const isAdvancedProfile = useGetUserFeatureStatus(
    brokerId,
    employerId,
    FEATURE_KEYS.ADVANCED_PROFILE,
    false
  );

  const uploadDocument = async (id?: string, isSuccess?: boolean) => {
    const documentUpdloadSuccess = () => {
      setSelectedFileList([]);
      setSelectedWeblink([]);
      onSave(isSuccess);
    };
    const planId = id ?? (plan.id as string);
    const removeDocs = Object.keys(isDocRemoved)
      .filter((documentType) => isDocRemoved[documentType])
      .map((documentType) => ({
        docType: PLAN_ADDITIONAL_DOCUMENT,
        planDocumentName: documentType,
      }));
    if (!isEmpty(selectedFileList) && isEmpty(removeDocs)) {
      await dispatch(uploadPlanDocument(selectedFileList, planId, benefitKind));
    } else if (!isEmpty(removeDocs) && isEmpty(selectedFileList)) {
      await dispatch(removePlanDocuments(planId, benefitKind, removeDocs));
    } else if (!isEmpty(removeDocs) && !isEmpty(selectedFileList)) {
      await dispatch(
        uploadRemovePlanDocument(
          selectedFileList,
          planId,
          benefitKind,
          removeDocs
        )
      );
    }
    if (selectedWeblink.length > 0) {
      await dispatch(updateWeblinks(selectedWeblink, planId, benefitKind));
    }
    documentUpdloadSuccess();
  };

  return (
    <>
      <div className={styles.addPlanWrapper}>
        <Collapse
          accordion
          activeKey={activePanel}
          bordered={false}
          defaultActiveKey={PanelSection.BASIC_PLAN_INFO}
          onChange={handleCollapseChange}
        >
          <Panel {...getPanelProps(PanelSection.BASIC_PLAN_INFO)}>
            <div className={styles.infoText}>
              Please add details to finalize plan.
            </div>
            <PlanBasicForm
              isSingleColumn
              benefitCategory={BenefitCategory.LIFE.value}
              carriersList={carriersList}
              setRequiredFieldError={setRequiredFieldError}
              setFormData={setFormData}
              formData={formData}
              form={form}
              enableFundingType={type === BasicPlans.STD.value}
              ref={basicPlanInfoRef}
              dbgPlanYear={dbgPlanYear}
              isModalVisible={isOpen}
              showPlanYearWarning={showPlanYearWarning}
              defaultPlanName={getPlanDefaultName(type ?? '')}
              isTitleVisible={false}
            />
            <PanelFooter
              nextStep={createNextStepHandler(
                basicPlanInfoRef,
                PanelSection.BASIC_PLAN_INFO
              )}
              isDisable={isDisable}
            />
          </Panel>
          <Panel {...getPanelProps(PanelSection.PLAN_INFORMATION)}>
            {planInformationSection()}
            <PanelFooter
              nextStep={createNextStepHandler(
                planInformationRef,
                PanelSection.PLAN_INFORMATION
              )}
              isDisable={isDisable || isReview || disableOnUpdatePlan}
              buttonText={
                planApproach === PlanApproach.SBC && !skipFailedDocument
                  ? 'Approve'
                  : undefined
              }
            />
            {showFeedbackPanel && (
              <AiFeedbackReceiver
                planSection={
                  LIFE_FEEDBACK_PANEL_TYPES[PanelSection.PLAN_INFORMATION]
                }
                setFeedback={addAiFeedback}
                removeFeedback={removeAiFeedback}
              />
            )}
          </Panel>
          <Panel {...getPanelProps(PanelSection.ADDITIONAL_RESOURCES)}>
            <ResourceInformation
              isTitleVisible={false}
              planType={type}
              onPlanChange={onPlanChange}
              lifePlanDocsRef={documentsRef}
              removeUploadedDocument={handleRemoveDocument}
              documents={formData.documents}
              onValidateFails={onValidateFails}
              documentReferences={lifeDocumentReferences}
            />
            <AdditionalPlanResources
              ref={ref}
              plan={plan}
              benefitKind={benefitKind}
              isCloseConfirmed={false}
              selectedFileList={selectedFileList}
              setSelectedFileList={setSelectedFileList}
              selectedWebLinkList={selectedWeblink}
              setSelectedWebLinkList={setSelectedWeblink}
              setIsDocRemoved={setIsDocRemoved}
            />
            <PanelFooter
              nextStep={createNextStepHandler(
                documentsRef,
                PanelSection.ADDITIONAL_RESOURCES
              )}
              isDisable={isDisable}
            />
          </Panel>
        </Collapse>
      </div>
      <NextButton
        type="primary"
        buttonText={
          <>
            {inProgress || (documentUploadInProgress && <Spin />)}
            Complete and Save Plan {isAnUpdateOfferedPlan ? 'Parameters' : ''}
          </>
        }
        className={styles.completeButton}
        disabled={isSaveDisabled}
        nextStep={handleSubmit}
      />
      {!isEmpty(docExtractionSource) && (
        <PlanUpdateWarningModal
          type={docExtractionSource?.source as UpdateDocumentExtractionSource}
          isLoading={false}
          isOpen={showUpdatePlanWarning}
          onClose={() => setShowUpdatePlanWarning(false)}
          onConfirm={savePlan}
          confirmText="Continue"
        />
      )}
    </>
  );
});

AddLifePlanPanelForm.displayName = 'AddLifePlanPanelForm';

export default AddLifePlanPanelForm;
