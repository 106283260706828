import { FC, useEffect, useState, useRef } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { Input, Row, Col, Form, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ERAdmin } from 'model/DraftEmployer';

import InputForm from 'components/InputForm/InputForm';
import CheckboxSelect from 'components/CheckboxSelect/CheckboxSelect';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import AlertMessage from 'components/Alert/AlertMessage';
import { addErAdmin } from 'modules/employers/slices/employerCreateStepperSlice';
import { validateAdminEmail } from 'modules/admins/services/AdminService';
import {
  EMPTY_MESSAGE,
  addERAdminFormFields,
} from 'modules/employers/constants/employerConstants';
import { EXISTING_LOGIN_FOUND } from 'modules/admins/constants/adminErrorConstants';
import { isValidEmailFormat, isValidPhoneNumber } from 'util/commonUtil';
import InputPhoneNumber from 'components/InputPhoneNumber/InputPhoneNumber';
import {
  EMPLOYER_USER_TYPES_DROPDOWN,
  formVerificationMsg,
} from 'constants/commonConstants';
import CustomEmployerUserDropDown from 'modules/benefitGuide/components/CustomEmployerUserDropDown/CustomEmployerUserDropDown';
import styles from './addERAdmins.module.less';

const { Item } = Form;
const { Text } = Typography;

type AddERAdminFormProps = {
  onClose: (...args: any[]) => any;
  expanded: boolean;
  editBrokerId: string | null;
};

const AddERAdminForm: FC<AddERAdminFormProps> = (
  props: AddERAdminFormProps
) => {
  const { onClose, editBrokerId, expanded } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [alertMessage, setAlertMessage] = useState<{
    visible: boolean;
    message: string;
  }>({
    visible: false,
    message: '',
  });
  const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [isSavingProgressSuccess, setIsSavingProgressSuccess] =
    useState<boolean>(false);
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [isDefault, setIsDefault] = useState<boolean>(false);

  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const erAdmins = useAppSelector(
    (state) => state.employer.employerCreateStepper.employer.erAdmins
  );

  const checkedAndDisabled = erAdmins ? erAdmins.length === 0 : true;

  const emailInputRef = useRef<Input>(null);
  const firstRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expanded) {
      const emailElement = emailInputRef.current;
      const firstRowElement = firstRowRef.current;
      if (emailElement !== null && firstRowElement !== null) {
        setHasFocus(true);
      }
    }
  }, [expanded]);

  useEffect(() => {
    if (hasFocus && expanded) {
      const emailElement = emailInputRef.current;
      const firstRowElement = firstRowRef.current;
      if (emailElement !== null) {
        emailElement.focus();
      }
      if (firstRowElement !== null) {
        firstRowElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hasFocus, expanded]);

  useEffect(() => {
    if (editBrokerId) {
      const adminToEdit = erAdmins?.find((admin) => admin.id == editBrokerId);
      if (adminToEdit) {
        setIsEdit(true);
        const {
          email,
          firstName,
          lastName,
          title,
          primary,
          phone,
          individualSubType,
        } = adminToEdit;
        form.setFieldsValue({
          email,
          firstName,
          lastName,
          title,
          phone,
          isPrimaryContact: primary,
          individualSubType: individualSubType,
        });
        adminToEdit.primary ? setIsDefault(true) : setIsDefault(false);
      }
    } else {
      form.resetFields();
    }
  }, [editBrokerId, form, erAdmins]);

  useEffect(() => {
    if (checkedAndDisabled) {
      form.setFieldsValue({ isPrimaryContact: true });
    }
  }, [form, checkedAndDisabled, expanded]);

  const onReset = () => {
    form.resetFields();
    onClose();
    setAlertMessage({ ...alertMessage, visible: false });
    setValidPhoneNumber(false);
    setIsSaveClicked(false);
    setIsSavingProgressSuccess(false);
  };

  const closeAlert = () => {
    setAlertMessage({ ...alertMessage, visible: false });
    setValidPhoneNumber(false);
  };

  const isEmailAlreadyAdded = (email: string, addedAdmins: ERAdmin[]) => {
    if (
      addedAdmins.find(
        (admin) =>
          admin.email &&
          admin.email.trim().toLowerCase() === email.trim().toLowerCase()
      )
    ) {
      return true;
    }
    return false;
  };

  const validateAdminInfo = async () => {
    try {
      await form.validateFields(addERAdminFormFields.requiredFields);
      return true;
    } catch (errorInfo: any) {
      return errorInfo.errorFields.length === 0;
    }
  };

  const onInputChange = async (changedValues: any, allValues: any) => {
    setIsSavingProgressSuccess(false);
    form.setFieldsValue(allValues);
    if (isSaveClicked) {
      const valid = await validateAdminInfo();
      if (valid) {
        setValidPhoneNumber(false);
        setAlertMessage({ ...alertMessage, visible: false });
      }
    }
  };

  const handleSave = async () => {
    setIsSaveClicked(true);
    setIsSavingProgressSuccess(true);
    const isFormValid = await validateAdminInfo();
    if (isFormValid) {
      const values = form.getFieldsValue();
      const { email, firstName, lastName, phone, title, isPrimaryContact } =
        values || {};
      const trimmedEmail = email.trim();
      if (!isValidEmailFormat(trimmedEmail)) {
        form.setFields([{ name: 'email', errors: [EMPTY_MESSAGE] }]);
        setAlertMessage({
          message: formVerificationMsg,
          visible: true,
        });
      } else if (!isEmpty(phone) && !isValidPhoneNumber(phone)) {
        setValidPhoneNumber(true);
        setAlertMessage({
          message: formVerificationMsg,
          visible: true,
        });
      } else {
        try {
          const addedAdmins = isEdit
            ? erAdmins?.filter((admin) => admin.id !== editBrokerId)
            : erAdmins;
          const response = await validateAdminEmail(trimmedEmail);
          if (
            (response &&
              response.data &&
              response.data.code &&
              response.data.code === EXISTING_LOGIN_FOUND) ||
            (addedAdmins && isEmailAlreadyAdded(email, addedAdmins))
          ) {
            form.setFields([{ name: 'email', errors: [EMPTY_MESSAGE] }]);
            setAlertMessage({
              message: 'This email is already in use by another employer user.',
              visible: true,
            });
          } else {
            const id = isEdit && editBrokerId ? editBrokerId : uuidv4();
            const erAdmin = {
              id: id,
              firstName: firstName.trim(),
              lastName: lastName.trim(),
              phone,
              email: trimmedEmail,
              title: title?.trim(),
              primary: isPrimaryContact,
              individualSubType: values.individualSubType,
            } as ERAdmin;
            dispatch(addErAdmin(erAdmin));
            setIsEdit(false);
            onReset();
          }
        } catch (error) {
          // TODO: add proper error message for unexpected errors
          setAlertMessage({
            message: 'Failed to validate user email.',
            visible: true,
          });
          return;
        }
      }
    } else {
      setAlertMessage({
        message: formVerificationMsg,
        visible: true,
      });
    }
  };

  return (
    <>
      {alertMessage.visible && (
        <AlertMessage
          type="error"
          message={alertMessage.message}
          closeAlert={closeAlert}
        />
      )}
      <InputForm form={form} onValuesChange={onInputChange}>
        <Row ref={firstRowRef} gutter={48}>
          <Col span={24}>
            <Item
              label="Email"
              name="email"
              rules={[{ required: true, whitespace: true }]}
              labelCol={{ span: 24 }}
            >
              <Input
                id="email"
                size="large"
                ref={emailInputRef}
                onFocus={() => setHasFocus(true)}
                onBlur={() => setHasFocus(false)}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, whitespace: true }]}
              labelCol={{ span: 24 }}
            >
              <Input id="firstName" size="large" />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, whitespace: true }]}
              labelCol={{ span: 24 }}
            >
              <Input id="lastName" size="large" />
            </Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Item
              label="Phone"
              name="phone"
              rules={[{ required: false }]}
              labelCol={{ span: 24 }}
              className={validPhoneNumber ? styles.labelError : ''}
            >
              <InputPhoneNumber />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Title"
              name="title"
              rules={[{ required: false }]}
              labelCol={{ span: 24 }}
            >
              <Input id="title" size="large" />
            </Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <CustomEmployerUserDropDown
              isEmptyField={() => {}}
              options={EMPLOYER_USER_TYPES_DROPDOWN}
            />
          </Col>
        </Row>
        <Row gutter={48}>
          <Col>
            <Item name="isPrimaryContact" valuePropName="checked">
              <CheckboxSelect
                disabled={
                  checkedAndDisabled ||
                  (erAdmins !== null && isEdit && isDefault)
                }
              >
                <Text className="text checkbox">Make Primary Contact</Text>
              </CheckboxSelect>
            </Item>
          </Col>
        </Row>
        <Row justify="center" className={styles.buttonStyle}>
          <Col>
            <SubmitButton
              onClick={handleSave}
              disabled={isSavingProgressSuccess}
            >
              Save Employer User
            </SubmitButton>
          </Col>
        </Row>
        <Row justify="center" className={styles.buttonStyle}>
          <Col>
            <CancelButton
              disabled={false}
              onClick={onReset}
              className={styles.cancelBtnStyle}
            >
              Cancel
            </CancelButton>
          </Col>
        </Row>
      </InputForm>
    </>
  );
};

export default AddERAdminForm;
