import React, { FC, ReactElement } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import { EMPLOYER_MEMBER_RESTRICTED } from 'constants/commonConstants';
import styles from './emptyAddPlan.module.less';

type EmptyAddPlanProps = {
  title: string;
  description: string;
  icon?: ReactElement;
  onActionClick: () => void;
  actionTitle?: string;
  selectButton?: ReactElement;
  children?: React.ReactNode; // For ai medical plan list
};

const EmptyAddPlan: FC<EmptyAddPlanProps> = (props: EmptyAddPlanProps) => {
  const {
    title,
    description,
    icon,
    onActionClick,
    actionTitle,
    selectButton,
    children,
  } = props;
  const { Text } = Typography;

  const emptyAddPlanButton = (
    <div className={styles.allTypesPlanWrapper}>
      {selectButton ? (
        selectButton
      ) : (
        <>
          <PageActionButton
            type="primary"
            onClick={onActionClick}
            className={styles.actionButton}
          >
            {actionTitle}
          </PageActionButton>
          {children}
        </>
      )}
    </div>
  );

  const permittedAddPlanButton = usePermitByUserType(
    emptyAddPlanButton,
    EMPLOYER_MEMBER_RESTRICTED
  );

  const emptyPageDescription = (
    <div className={styles.descriptionWrapper}>
      <Text>{description}</Text>
    </div>
  );

  const securedEmptyPageDescription = usePermitByUserType(
    emptyPageDescription,
    EMPLOYER_MEMBER_RESTRICTED
  );

  return (
    <div className={styles.card}>
      <Card>
        <div className={styles.rowWrapper}>
          <Row>
            {icon && (
              <Col span={2}>
                <div className={styles.iconWrapper}>{icon}</div>
              </Col>
            )}
            <Col span={16} className={styles.textCol}>
              <div className={styles.textWrapper}>
                <h2>
                  <Text>{title}</Text>
                </h2>
              </div>
              {securedEmptyPageDescription}
            </Col>
            <Col span={5} className={styles.actionWrapper}>
              {permittedAddPlanButton}
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default EmptyAddPlan;
