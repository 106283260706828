import axios from 'axios';

import ConsultationLevel from 'modules/benefitsConsultation/enums/ConsultationLevel';
import Consultant from 'model/benefitsConsultation/Consultant';

import { baseApi, getPagingQueryString } from 'util/apiUtil';
import BenefitsConsultant from 'model/benefitsConsultation/BenefitsConsultant';

const baseUrl = `${baseApi}/v2/benefit-consultants`;

export const getConsultantsSummary = (
  consultationLevel: ConsultationLevel,
  organizationId: string,
  employerId?: string
) => {
  return axios.get(
    `${baseUrl}/benefit-summary?consultation-level=${consultationLevel}&organization-id=${organizationId}&employer-id=${employerId}`
  );
};

export const addRemoveConsultants = (
  consultationLevel: ConsultationLevel,
  consultantList: Consultant[]
) => {
  return axios.post(
    `${baseUrl}?consultation-level=${consultationLevel}`,
    consultantList
  );
};

export const assignUnassignConsultants = (
  consultationLevel: ConsultationLevel,
  consultantList: Consultant[],
  employerId: string
) => {
  return axios.post(
    `${baseUrl}?consultation-level=${consultationLevel}&employer-id=${employerId}`,
    consultantList
  );
};

export const getConsultantsList = async (
  page: number,
  size: number,
  sort: string,
  query: string,
  organizationId: string,
  consultationLevel: ConsultationLevel,
  employerId?: string
) => {
  try {
    return await axios.get(
      `${baseUrl}?${getPagingQueryString(page, size, sort, query, 'search')}`,
      {
        params: {
          'consultation-level': consultationLevel,
          'organization-id': organizationId,
          'employer-id': employerId,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const removeConsultant = (consultantId: string) => {
  return axios.delete(`${baseUrl}/${consultantId}`);
};

export const getConsultantDetails = (consultantId: string) => {
  return axios.get(`${baseUrl}/${consultantId}`);
};

export const getConsultantType = (consultantId: string) => {
  return axios.get(`${baseUrl}/type/${consultantId}`);
};

export const getConsultantCalendarSyncStatus = (consultantId: string) => {
  return axios.get(`${baseUrl}/status/${consultantId}`);
};

export const editConsultantDetails = (
  consultantId: string,
  consultant: BenefitsConsultant
) => {
  return axios.put(`${baseUrl}/${consultantId}`, consultant);
};

export const inviteConsultants = (consultants: BenefitsConsultant[]) => {
  return axios.post(`${baseUrl}/invite`, consultants);
};

export const confirmInvite = (
  eventTrigger: string,
  consultantId?: string,
  calendarType?: string,
  appointmentId?: string
) => {
  const payload: any = {
    eventTrigger: eventTrigger,
    benefitConsultationId: consultantId,
  };
  if (calendarType) {
    payload.calendarType = calendarType;
  }
  if (appointmentId) {
    payload.appointment = { id: appointmentId };
  }

  return axios.post(`${baseUrl}/webhooks`, payload);
};
