import { FC } from 'react';
import { Col, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as IconHidden } from 'assets/images/icon-hidden.svg';
import { FEATURE_KEYS } from 'constants/commonConstants';

import styles from './employerFeatureDisabled.module.less';

type EmployerFeatureDisabledProps = {
  feature: string;
  featureName: string;
  isInvisible?: boolean;
};

const EmployerFeatureDisabled: FC<EmployerFeatureDisabledProps> = (props) => {
  const { feature, isInvisible, featureName } = props;

  const navigate = useNavigate();
  const { brokerId } = useParams();

  const navigateToFeatureControl = (feature: string) => {
    switch (feature) {
      case FEATURE_KEYS.CLAIMS:
        navigate(`/brokers/${brokerId}/configuration/claims`, {
          state: { bypassInvalidRoute: true },
        });
        break;
      case FEATURE_KEYS.BILLING:
        navigate(`/brokers/${brokerId}/configuration/billing`, {
          state: { bypassInvalidRoute: true },
        });
        break;
      case FEATURE_KEYS.ISSUES_LOG:
        navigate(
          `/brokers/${brokerId}/configuration/issues-log-provisioning/account`,
          {
            state: { bypassInvalidRoute: true },
          }
        );
        break;
      case FEATURE_KEYS.PLAN_RATES:
        navigate(`/brokers/${brokerId}/configuration/plan-rates`, {
          state: { bypassInvalidRoute: true },
        });
        break;
      case FEATURE_KEYS.RENEWAL:
        navigate(`/brokers/${brokerId}/configuration/renewals`, {
          state: { bypassInvalidRoute: true },
        });
        break;
      case 'ID_CARDS':
        navigate(`/brokers/${brokerId}/configuration/id-cards`, {
          state: { bypassInvalidRoute: true },
        });
        break;
      case FEATURE_KEYS.BENEFIT_CONSULTATION:
        navigate(`/brokers/${brokerId}/configuration/benefits-consultation`, {
          state: { bypassInvalidRoute: true },
        });
        break;
      case FEATURE_KEYS.BENEFIT_GUIDE:
        navigate(`/brokers/${brokerId}/configuration/benefit-guide`, {
          state: { bypassInvalidRoute: true },
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.cardWrapper}>
      <Row className={styles.gridStyle}>
        <Col>
          <IconHidden className={styles.hiddenIcon} />
        </Col>
        <Col className={styles.displayText}>
          <h2>This page is hidden to the Employer</h2>
          <div>
            <span>
              You can still make updates to the {featureName} tool, but it will
              not be accessible by the Employer until{' '}
              {isInvisible ? 'visibility is turned on' : 'enabled'}.
            </span>
            <span
              className={styles.manageLink}
              onClick={() => navigateToFeatureControl(feature)}
            >
              {' '}
              Manage
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EmployerFeatureDisabled;
