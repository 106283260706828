import React, { FC, ReactNode } from 'react';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import styles from './labelWithTooltip.module.less';

type LabelWithTooltipProps = {
  content: ReactNode;
  mandatoryField: boolean;
  fieldText: string;
  zIndex?: number;
};

const LabelWithTooltip: FC<LabelWithTooltipProps> = (
  props: LabelWithTooltipProps
) => {
  const { content, mandatoryField, fieldText, zIndex } = props;
  return (
    <div className={mandatoryField ? '' : styles.tooltipLabel}>
      {fieldText}
      <Popover
        content={content}
        placement="right"
        overlayClassName="popoverInner"
        zIndex={zIndex}
      >
        <span>
          <QuestionCircleOutlined className={styles.questionIcon} />
        </span>
      </Popover>
    </div>
  );
};

export default LabelWithTooltip;
