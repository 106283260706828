import { Col, Divider, Row, Spin, Tabs } from 'antd';
import { useParams } from 'react-router-dom';

import { useReactToPrint } from 'react-to-print';
import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { usePermitIf } from 'hooks/usePermitIf';
import {
  useLazyGetEmployeeDependentListQuery,
  useLazyGetEmployeeElectionDetailsQuery,
  useLazyGetEmployerPlanYearsListQuery,
  useLazyGetIdCardPlanDetailsQuery,
} from 'modules/idCards/slices/idCardApiSlice';
import { setSelectedPlanYearId } from 'modules/idCards/slices/idCardSlice';
import OfferStatusTag from 'components/OfferStatusTag/OfferStatusTag';

import { isBlank } from 'util/commonUtil';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import {
  BASIC_INFO_TAB,
  DEPENDENTS_TAB,
  ID_CARD_TAB,
} from 'modules/idCards/constants/IdCardConstants';

import { ReactComponent as DarkContactIcon } from 'assets/images/dark-contact.svg';
import { toStringToProperCase } from 'util/stringUtil';
import IDCardRear from 'modules/idCards/components/IDCardRear/IDCardRear';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import IdCard from 'modules/idCards/components/IDCard/IdCard';
import PrintableIdCard from 'modules/idCards/components/PrintableIDCard/PrintableIdCard';
import { MDV_PLANS } from 'modules/renewals/constants/renewalsConstants';
import NoIDCardView from 'modules/employers/components/NoIDCardsView/NoIDCardView';
import { allowedCommonButtonPermittedTypes } from 'constants/permittedConstants';
import styles from './employeeDetails.module.less';

const { TabPane } = Tabs;

const EmployeeDetails = () => {
  const { brokerId = '', employerId = '', employeeId = '' } = useParams();

  const dispatch = useAppDispatch();
  const selectedPlanYearId = useAppSelector(
    (state) => state.idCards.selectedPlanYearId
  );

  const [
    getEmployeeElectionDetails,
    {
      data: rawEmployeeDetails,
      isLoading: isGetEmployeeDetailsLoading,
      isUninitialized: isGetEmployeeDetailsUnitialized,
      isSuccess: isGetEmployeeDetailsSuccess,
    },
  ] = useLazyGetEmployeeElectionDetailsQuery();

  const [
    getEmployeeDependentList,
    { data: rawDependentList, isLoading: isGetDependentDetailsLoading },
  ] = useLazyGetEmployeeDependentListQuery();
  const [
    getPlanYearsList,
    { data: planYearsList, isLoading: isGetPlanYearsListLoading },
  ] = useLazyGetEmployerPlanYearsListQuery();

  /** In the scenario a selectedPlanYearId does not exist i.e a refresh.
   * get the plan-years list, find the current and set the id.
   */
  useEffect(() => {
    if (!selectedPlanYearId) {
      getPlanYearsList({ employerId }).then(({ data }) => {
        const currentPlanYear = planYearsList?.find(
          (planYear) => planYear.current
        );
        dispatch(setSelectedPlanYearId({ planYearId: currentPlanYear?.id }));
      });
    } else {
      // Manual call to avoid double requests if user refreshes on this page.
      // This means that these requests will go through once plan-years has been fetched
      // and the current plan year has been found
      getEmployeeDependentList({ employeeId, planYearId: selectedPlanYearId });
      getEmployeeElectionDetails({
        employerId,
        employeeId,
        planYearId: selectedPlanYearId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetPlanYearsListLoading, selectedPlanYearId, dispatch]);

  const [
    getPlnIdCardDetail,
    {
      data: idCardDetails,
      isLoading: isCardDetailsLoading,
      isSuccess: isCardDetailsSuccess,
    },
  ] = useLazyGetIdCardPlanDetailsQuery();

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `ID card list.pdf`,
  });

  const [selectedIndividual, setSelectedIndividual] = useState<string>('');

  const navigation: { name: string; link: string }[] = [
    {
      name: 'All Employees',
      link: `/brokers/${brokerId}/employers/${employerId}/id-cards `,
    },
  ];

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);

  const [isPrintBtnEnabled, setIsPrintBtnEnabled] = useState<boolean>(false);

  const getBreadCrumbData = () => {
    return navigation;
  };

  const getIDSortedData = () => {
    if (isCardDetailsSuccess) {
      const priorityOrder = MDV_PLANS;

      const clonedArray = [...idCardDetails];

      return clonedArray.sort((a: any, b: any) => {
        const priorityA = priorityOrder.indexOf(a.benefitKind);
        const priorityB = priorityOrder.indexOf(b.benefitKind);

        if (priorityA < priorityB) {
          return -1;
        } else if (priorityA > priorityB) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return [];
  };

  useEffect(() => {
    if (isGetEmployeeDetailsSuccess) {
      setSelectedIndividual(rawEmployeeDetails?.id ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetEmployeeDetailsSuccess]);

  const getPlanIdCardData = () => {
    getPlnIdCardDetail({
      employeeId,
      employerId,
      individualId: selectedIndividual,
      planYearId: String(selectedPlanYearId),
    });
  };

  useEffect(() => {
    if (selectedIndividual) {
      getPlanIdCardData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndividual]);

  const getIndividualData = () => {
    const individualList: any[] =
      rawDependentList?.map((obj) => {
        return {
          value: obj?.id,
          label: `${obj.firstName} ${obj.lastName}`,
        };
      }) ?? [];

    individualList.unshift({
      value: rawEmployeeDetails?.id,
      label: `${rawEmployeeDetails?.name.firstName} ${rawEmployeeDetails?.name.lastName}`,
    });
    return individualList;
  };

  const securedBreadcrumb = usePermitIf(
    <BreadcrumbNavigation breadCrumbData={getBreadCrumbData()} />,
    allowedCommonButtonPermittedTypes,
    []
  );

  const isUserAmended = (label: string) => {
    const benefit = label.split(' ')?.[0]?.toUpperCase();
    switch (benefit) {
      case 'MEDICAL':
        return !!rawEmployeeDetails?.medicalUserAmended;
      case 'DENTAL':
        return !!rawEmployeeDetails?.dentalUserAmended;
      case 'VISION':
        return !!rawEmployeeDetails?.visionUserAmended;
    }
    return false;
  };

  const basicInfoDetails = () => {
    return [
      {
        label: 'Employee Name',
        value: `${rawEmployeeDetails?.name?.firstName} ${rawEmployeeDetails?.name?.lastName}`,
      },
      {
        label: 'Email Address',
        value: rawEmployeeDetails?.email,
      },
      {
        label: 'Medical Member ID#',
        value: rawEmployeeDetails?.medicalMemberId,
      },
      {
        label: 'Dental Member ID#',
        value: rawEmployeeDetails?.dentalMemberId,
      },
      {
        label: 'Vision Member ID#',
        value: rawEmployeeDetails?.visionMemberId,
      },
    ];
  };

  const handleTabClick = (key: string) => {
    setIsPrintBtnEnabled(ID_CARD_TAB === key);
  };

  const isNoDataExist = (): boolean => {
    return !getIDSortedData()?.length;
  };

  const dependentDetails = () => {
    return (
      rawDependentList?.map((data, i) => ({
        label: `Dependent #${i + 1}`,
        values: [
          {
            label: 'Dependent Name',
            value: `${data.firstName} ${data.lastName}`,
          },
          {
            label: 'Email Address',
            value: data.email,
          },
          {
            label: 'Medical Member ID#',
            value: data?.medicalMemberId,
            isUserAmended: data?.medicalUserAmended,
          },
          {
            label: 'Dental Member ID#',
            value: data?.dentalMemberId,
            isUserAmended: data?.dentalUserAmended,
          },
          {
            label: 'Vision Member ID#',
            value: data?.visionMemberId,
            isUserAmended: data?.visionUserAmended,
          },
        ],
      })) || []
    );
  };

  if (
    isGetEmployeeDetailsUnitialized ||
    isGetDependentDetailsLoading ||
    isGetEmployeeDetailsLoading ||
    isGetPlanYearsListLoading
  ) {
    return <Spin />;
  }

  const getIndividual = () => {
    const data = getIndividualData().find(
      (obj) => obj.value === selectedIndividual
    );
    if (data) {
      return data.label.concat(':');
    }
    return '';
  };

  const isOpsAdmin = () => {
    return appBootInfo?.isOpsAdmin;
  };

  const getEncryptedText = () => {
    return <span className={styles.encryptedText}>encrypted</span>;
  };

  const isMemberIdFields = (label: string) => {
    return (
      label === 'Medical Member ID#' ||
      label === 'Dental Member ID#' ||
      label === 'Vision Member ID#'
    );
  };

  return (
    <div className={styles.employeeDetailsWrapper}>
      <Row>{securedBreadcrumb}</Row>
      <Row
        className={styles.employeeName}
        align={'middle'}
        justify={'space-between'}
      >
        <Col>
          {basicInfoDetails()[0]?.value}{' '}
          {rawEmployeeDetails?.selfDeclared && (
            <OfferStatusTag offerStatus={'SELF_DECLARED'} />
          )}
        </Col>
        <Col>
          {isPrintBtnEnabled && !isNoDataExist() && !isOpsAdmin() && (
            <PageActionButton
              type="primary"
              className={styles.pdfDownloadBtn}
              onClick={() => handlePrint()}
              icon={<DarkContactIcon className={styles.darkConactIcon} />}
            >
              Export ID Cards
            </PageActionButton>
          )}
        </Col>
      </Row>
      <div className={styles.tabWrapper}>
        <Tabs
          type="card"
          defaultActiveKey={BASIC_INFO_TAB}
          size="large"
          className={styles.employeeTabs}
          onChange={handleTabClick}
        >
          <TabPane tab="Basic Info" key={BASIC_INFO_TAB}>
            <div className={styles.basicInfoList}>
              {basicInfoDetails().map((item, index) => {
                return (
                  <Row className={styles.singleInfo} key={index}>
                    <div className={styles.basicInfoSubTitle}>{item.label}</div>
                    <div className={styles.basicInfoSubValue}>
                      {isBlank(item.value)
                        ? '-'
                        : isOpsAdmin() && isMemberIdFields(item.label)
                        ? getEncryptedText()
                        : item.value}
                      {!rawEmployeeDetails?.selfDeclared &&
                        isUserAmended(item.label) && (
                          <OfferStatusTag offerStatus={'USER_AMENDED'} />
                        )}
                    </div>
                  </Row>
                );
              })}
            </div>
          </TabPane>
          <TabPane
            tab={`Dependents (${dependentDetails().length})`}
            key={DEPENDENTS_TAB}
          >
            {dependentDetails().map((dependent, index) => {
              return (
                <div key={index} className={styles.dependentListWrapper}>
                  <div className={styles.dependentName}>{dependent.label}</div>
                  <Divider />
                  <div className={styles.dependentList}>
                    {dependent.values.map((item, index) => {
                      return (
                        <Row className={styles.singleInfo} key={index}>
                          <div className={styles.basicInfoSubTitle}>
                            {item.label}
                          </div>
                          <div className={styles.basicInfoSubValue}>
                            {isBlank(item.value)
                              ? '-'
                              : isOpsAdmin() && isMemberIdFields(item.label)
                              ? getEncryptedText()
                              : item.value}
                            {item?.isUserAmended && (
                              <OfferStatusTag offerStatus={'USER_AMENDED'} />
                            )}
                          </div>
                        </Row>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </TabPane>
          <TabPane tab={`ID Cards`} key={ID_CARD_TAB}>
            <Row justify={'end'} align={'middle'}>
              <Col>Viewing ID Cards for:</Col>
              <Col className={styles.individualSelector}>
                <SingleSelectFilter
                  data={getIndividualData()}
                  placeholder={'-'}
                  showSearch={false}
                  setFilterSelectedValue={setSelectedIndividual}
                  defaultValue={selectedIndividual}
                  selectedValue={selectedIndividual}
                  inlineDropdown={true}
                />
              </Col>
            </Row>

            {isCardDetailsLoading ? (
              <Spin />
            ) : (
              <>
                {isNoDataExist() ? (
                  <NoIDCardView />
                ) : (
                  getIDSortedData()?.map((data: any, index: any) => {
                    return (
                      <div key={index} className={styles.dependentListWrapper}>
                        <Row gutter={40}>
                          <Col className={styles.benefitCategory} lg={2}>
                            {toStringToProperCase(data?.benefitKind)}
                          </Col>
                          <Col ref={componentRef}>
                            <IdCard
                              idData={data}
                              employeeDetails={rawEmployeeDetails}
                              benefitKind={data?.benefitKind}
                              planYearId={selectedPlanYearId}
                              triggerRefresh={getPlanIdCardData}
                              isEmployeeSelected={
                                rawEmployeeDetails?.id === selectedIndividual
                              }
                              isOpsAdmin={isOpsAdmin()}
                            />
                          </Col>
                          <Col>
                            <IDCardRear idData={data} />
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                )}
                <div className={styles.printableSection}>
                  <div ref={componentRef} className={styles.printableWrapper}>
                    <p className={styles.benefitCategoryPrintable}>
                      {getIndividual()}
                    </p>
                    {getIDSortedData()?.map((data: any, index: any) => {
                      return (
                        <Row key={index} gutter={40}>
                          <Col span={2}>
                            <span className={styles.benefitCategoryPrintable}>
                              {toStringToProperCase(data?.benefitKind)}
                            </span>{' '}
                            &nbsp; &nbsp;
                          </Col>
                          <Col className={styles.printableCol}>
                            <PrintableIdCard
                              idData={data}
                              coverageDetails={data?.coverageDetails}
                              employeeDetails={rawEmployeeDetails}
                              benefitKind={data?.benefitKind}
                              triggerRefresh={getPlanIdCardData}
                              isEmployeeSelected={false}
                            />
                            <br />
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default EmployeeDetails;
