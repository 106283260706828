import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useAppSelector } from 'hooks/redux';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import AlertMessage from 'components/Alert/AlertMessage';

import SummeryCommonTable from 'modules/renewals/components/helperComponents/SummeryCommonTable/SummeryCommonTable';

import { useClonePlanBGMutation } from 'modules/employers/slices/planBGCloneRTKSlice';
import {
  PLAN_CLONE_TYPE_TEXT,
  getClonePlanIcon,
  transformDataForSubmit,
} from 'modules/employers/utils/clonePlanUtils';
import {
  DUPLICATE_ERROR_MESSAGE,
  ERROR_CODE_DUPLICATE,
  PLAN_CLONE_BENEFIT_CATEGORIES,
} from 'modules/employers/constants/afpClonePlansConstants';
import PlanYear from 'model/PlanYear';
import { IBenGuide, IPlan } from 'modules/employers/types/planBGCloneTypes';

import styles from './reviewPlan.module.less';

type PlanDetailsProps = {
  setCurrentStep: (step: number) => void;
  closeModal: () => void;
};

const ReviewPlanDetails: FC<PlanDetailsProps> = (props: PlanDetailsProps) => {
  const { closeModal } = props;
  const { employerId } = useParams();
  const selectedPlanYear: Partial<PlanYear> = useAppSelector(
    (state) => state.employer.planBGClone.selectedPlanYear
  );
  const clonePlanBGList = useAppSelector(
    (state) => state.employer.planBGClone.clonePlanBGList
  );
  const [showAlertError, setShowAlertError] = useState({
    show: false,
    message: '',
  });
  const [
    clonePlansBG,
    {
      isLoading: isClonePlansBGLoading,
      isSuccess: isClonePlansBGSuccess,
      isError: isClonePlansBGError,
      error: clonePlansBGError,
      reset,
    },
  ] = useClonePlanBGMutation();

  useEffect(() => {
    if (isClonePlansBGError) {
      const isDuplicate = ERROR_CODE_DUPLICATE.includes(
        // @ts-ignore
        clonePlansBGError?.data?.code
      );
      setShowAlertError({
        show: isClonePlansBGError && isDuplicate,
        message: isDuplicate ? DUPLICATE_ERROR_MESSAGE : '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClonePlansBGError]);

  useEffect(() => {
    if (isClonePlansBGSuccess) {
      closeModal();
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClonePlansBGSuccess]);

  const planTableColumns: ColumnsType<IPlan> = [
    {
      title: <p className={styles.planTitle}>Plan Name</p>,
      key: 'name',
      dataIndex: 'name',
      width: '40%',
      render: (data) => (
        <div className={styles.text}>
          <OverflowPopover showPopover maxWidth={245}>
            {data}
          </OverflowPopover>
        </div>
      ),
    },
    {
      title: <p className={styles.planTitle}>Carrier</p>,
      key: 'carrierLogo',
      dataIndex: 'carrierLogo',
      width: '15%',
      render: (data, record) => {
        return record?.carrierLogo ? (
          <img
            className={styles.carrierLogo}
            src={data}
            alt={record.carrierName}
          />
        ) : (
          <span>-</span>
        );
      },
    },
    {
      title: <p className={styles.planTitle}>Benefit Classes</p>,
      key: 'benefitClasses',
      dataIndex: 'benefitClasses',
      width: '35%',
      render: (data) => {
        return (
          <div>
            <OverflowPopover showPopover maxWidth={210}>
              {data.join(', ')}
            </OverflowPopover>
          </div>
        );
      },
    },
  ];

  const benefitGuideTableColumns: ColumnsType<IBenGuide> = [
    {
      title: <p className={styles.planTitle}>Benefits Guide Name</p>,
      key: 'name',
      dataIndex: 'name',
      width: '62%',
      render: (data) => (
        <div className={styles.text}>
          <OverflowPopover showPopover maxWidth={380}>
            {data}
          </OverflowPopover>
        </div>
      ),
    },
    {
      title: <p className={styles.planTitle}>Benefit Classes</p>,
      key: 'benefitClasses',
      dataIndex: 'benefitClasses',
      width: '38%',
      render: (data) => {
        return (
          <div>
            <OverflowPopover showPopover maxWidth={210}>
              {data?.join(', ')}
            </OverflowPopover>
          </div>
        );
      },
    },
  ];

  const handleSubmit = () => {
    const newObj = transformDataForSubmit(
      clonePlanBGList.plans,
      clonePlanBGList.benGuides
    );

    clonePlansBG({
      employerId: employerId || '',
      newPlanYearId: selectedPlanYear?.id || '',
      data: newObj,
    });
  };

  return (
    <>
      <div className={styles.planContainer}>
        {showAlertError?.show && (
          <AlertMessage
            className={styles.errorAlert}
            message={showAlertError.message}
            type="error"
            closeAlert={() => {
              setShowAlertError({ show: false, message: '' });
            }}
          />
        )}
        <Col className={styles.headingWrapper}>
          <h3 className={styles.reviewPlanHeader}>
            Review Plans & Benefit Guides
          </h3>

          <p>
            Plans and benefit guides are ready to be added to the new Plan Year.
            You can review each plans details below.
          </p>
        </Col>

        {Object.entries(clonePlanBGList.plans).map(([benefit, values]) => {
          if (
            values?.isChecked &&
            values?.plan?.length !== 0 &&
            !values?.plan?.every((item) => item?.isRemoved)
          ) {
            return (
              <div className={styles.tableComponentWrapper}>
                <Row align="middle">
                  <img
                    className={styles.headerIcon}
                    src={getClonePlanIcon(
                      benefit as keyof typeof PLAN_CLONE_BENEFIT_CATEGORIES
                    )}
                  />
                  <span className={styles.headerText}>
                    {
                      PLAN_CLONE_TYPE_TEXT[
                        benefit as keyof typeof PLAN_CLONE_BENEFIT_CATEGORIES
                      ]
                    }
                  </span>
                </Row>

                <SummeryCommonTable
                  data={values?.plan?.filter((item) => !item.isRemoved) || []}
                  columns={planTableColumns}
                  emptyMessage={
                    <span className={styles.tableEmptyText}>
                      There are no selected plans for this benefit type.
                    </span>
                  }
                />
              </div>
            );
          }
        })}

        {clonePlanBGList.benGuides?.length !== 0 && (
          <Card className={styles.benGuideCard}>
            <Row align="middle">
              <span className={styles.headerText}>Benefit Guides</span>
            </Row>

            <SummeryCommonTable
              data={clonePlanBGList.benGuides?.filter(
                (item: IBenGuide) => !item.isRemoved
              )}
              columns={benefitGuideTableColumns}
              emptyMessage={
                <span className={styles.tableEmptyText}>
                  There are no selected benefit guides
                </span>
              }
            />
          </Card>
        )}

        <PageActionButton
          type="primary"
          className={styles.saveBtn}
          onClick={handleSubmit}
          loading={isClonePlansBGLoading}
        >
          Clone Additional Plans & Benefits Guides
        </PageActionButton>
      </div>
    </>
  );
};

export default ReviewPlanDetails;
