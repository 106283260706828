import {
  createApi,
  BaseQueryFn,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { baseApi } from 'util/apiUtil';

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string;
      absoluteUrl?: boolean;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    FetchBaseQueryError
  > =>
  async ({ url, absoluteUrl, method, data, params }) => {
    try {
      const result = await axios({
        url: absoluteUrl ? url : baseUrl + url,
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status || 500,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const lumityApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: `${baseApi}/` }),
  tagTypes: ['Broker', 'Admin'],
  endpoints: () => ({}),
});
