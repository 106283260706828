import { isFinite } from 'lodash';

export const currencyRoundedFormatterClaims = (
  value: number,
  format: string = 'en-US',
  currency: string = 'USD'
) => {
  const formatter = new Intl.NumberFormat(format, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const fractionFormatter = new Intl.NumberFormat(format, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
  });

  if (!isFinite(value)) {
    return '';
  }

  return value % 1 === 0
    ? formatter.format(value)
    : fractionFormatter.format(value);
};

export const claimsBreakdownDummyData = [
  {
    displayLabel: "'21 Mar",
    month: '2021-03',
    totalMedicalClaims: 704096.04,
    totalRxClaims: 180318.55,
    totalCapitation: 172.92,
    totalCost: 884587.51,
  },
  {
    displayLabel: "'21 Apr",
    month: '2021-04',
    totalMedicalClaims: 789594.91,
    totalRxClaims: 175512.94,
    totalCapitation: 201.52,
    totalCost: 965309.37,
  },
  {
    displayLabel: "'21 May",
    month: '2021-05',
    totalMedicalClaims: 861145.91,
    totalRxClaims: 167871.74,
    totalCapitation: 178.26,
    totalCost: 1029195.91,
  },
  {
    displayLabel: "'21 Jun",
    month: '2021-06',
    totalMedicalClaims: 820573.44,
    totalRxClaims: 142902.71,
    totalCapitation: 198.09,
    totalCost: 963674.24,
  },
  {
    displayLabel: "'21 Jul",
    month: '2021-07',
    totalMedicalClaims: 866548.63,
    totalRxClaims: 202477.91,
    totalCapitation: 178.06,
    totalCost: 1069204.6,
  },
  {
    displayLabel: "'21 Aug",
    month: '2021-08',
    totalMedicalClaims: 727742.04,
    totalRxClaims: 175022.36,
    totalCapitation: 219.01,
    totalCost: 902983.41,
  },
  {
    displayLabel: "'21 Sep",
    month: '2021-09',
    totalMedicalClaims: 595735.21,
    totalRxClaims: 225880.85,
    totalCapitation: 180.94,
    totalCost: 821797.0,
  },
  {
    displayLabel: "'21 Oct",
    month: '2021-10',
    totalMedicalClaims: 1107019.55,
    totalRxClaims: 235400.02,
    totalCapitation: 171.93,
    totalCost: 1342591.5,
  },
  {
    displayLabel: "'21 Nov",
    month: '2021-11',
    totalMedicalClaims: 761994.91,
    totalRxClaims: 178570.48,
    totalCapitation: 196.97,
    totalCost: 940762.36,
  },
  {
    displayLabel: "'21 Dec",
    month: '2021-12',
    totalMedicalClaims: 751838.31,
    totalRxClaims: 226895.63,
    totalCapitation: 191.19,
    totalCost: 978925.13,
  },
  {
    displayLabel: "'22 Jan",
    month: '2022-01',
    totalMedicalClaims: 767015.19,
    totalRxClaims: 181545.89,
    totalCapitation: 225.04,
    totalCost: 948786.12,
  },
  {
    displayLabel: "'22 Feb",
    month: '2022-02',
    totalMedicalClaims: 660875.22,
    totalRxClaims: 169038.09,
    totalCapitation: 164.34,
    totalCost: 830077.65,
  },
];
export const lossRatioDummyData = [
  {
    displayLabel: "Sep '21",
    year: '2021',
    month: 'September',
    averageLossRatio: 80.5,
    totalPremium: 99423.18,
    totalCost: 80038.26,
  },
  {
    displayLabel: "Mar '22",
    year: '2022',
    month: 'March',
    averageLossRatio: 38.0,
    totalPremium: 159836.61,
    totalCost: 60735.25,
  },
  {
    displayLabel: "Nov '21",
    year: '2021',
    month: 'November',
    averageLossRatio: 131.24,
    totalPremium: 119017.28,
    totalCost: 156195.36,
  },
  {
    displayLabel: "Oct '21",
    year: '2021',
    month: 'October',
    averageLossRatio: 95.14,
    totalPremium: 110615.17,
    totalCost: 105239.19,
  },
  {
    displayLabel: "Jan '22",
    year: '2022',
    month: 'January',
    averageLossRatio: 41.86,
    totalPremium: 148414.67,
    totalCost: 62119.01,
  },
  {
    displayLabel: "Jul '21",
    year: '2021',
    month: 'July',
    averageLossRatio: 50.28,
    totalPremium: 97298.0,
    totalCost: 48923.94,
  },
  {
    displayLabel: "Aug '21",
    year: '2021',
    month: 'August',
    averageLossRatio: 112.72,
    totalPremium: 92590.54,
    totalCost: 104369.53,
  },
  {
    displayLabel: "Apr '21",
    year: '2021',
    month: 'April',
    averageLossRatio: 268.18,
    totalPremium: 102542.9,
    totalCost: 274994.85,
  },
  {
    displayLabel: "Feb '22",
    year: '2022',
    month: 'February',
    averageLossRatio: 53.26,
    totalPremium: 159047.55,
    totalCost: 84706.99,
  },
  {
    displayLabel: "May '21",
    year: '2021',
    month: 'May',
    averageLossRatio: 87.81,
    totalPremium: 91428.35,
    totalCost: 80287.52,
  },
  {
    displayLabel: "Dec '21",
    year: '2021',
    month: 'December',
    averageLossRatio: 48.11,
    totalPremium: 119797.33,
    totalCost: 57629.07,
  },
  {
    displayLabel: "Jun '21",
    year: '2021',
    month: 'June',
    averageLossRatio: 142.08,
    totalPremium: 98918.01,
    totalCost: 140546.97,
  },
];

export const claimsDashboardDummyData = [
  {
    displayLabel: "'21 Mar",
    month: '2021-03',
    totalMedicalClaims: 704096.04,
    totalRxClaims: 380318.55,
    totalCapitation: 172.92,
    totalCost: 1084587.51,
  },
  {
    displayLabel: "'21 Apr",
    month: '2021-04',
    totalMedicalClaims: 789594.91,
    totalRxClaims: 175512.94,
    totalCapitation: 201.52,
    totalCost: 965309.37,
  },
  {
    displayLabel: "'21 May",
    month: '2021-05',
    totalMedicalClaims: 861145.91,
    totalRxClaims: 167871.74,
    totalCapitation: 178.26,
    totalCost: 1029195.91,
  },
  {
    displayLabel: "'21 Jun",
    month: '2021-06',
    totalMedicalClaims: 820573.44,
    totalRxClaims: 142902.71,
    totalCapitation: 198.09,
    totalCost: 963674.24,
  },
  {
    displayLabel: "'21 Jul",
    month: '2021-07',
    totalMedicalClaims: 866548.63,
    totalRxClaims: 202477.91,
    totalCapitation: 178.06,
    totalCost: 1069204.6,
  },
];

export const claimsSummaryDummyData = {
  totalYtdPremium: 13616562,
  totalYtdCost: 11677895,
  avgPremiumPerEmployee: 1444.88,
  avgCostPerEmployee: 1239.16,
  lossRatio: 85.76,
  adjustedLossRatio: null,
  claimsAboveStopLoss: null,
};
