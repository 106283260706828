import { ChangeEvent, FC, useEffect, useState, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { Menu, Col, Row } from 'antd';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import DataTable from 'components/DataTable/DataTable';
import { DataColumn } from 'components/DataTable/DataColumn';
import SearchBar from 'components/SearchBar/SearchBar';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import { buildFullName, buildInitials } from 'util/stringUtil';
import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
} from 'util/commonUtil';

import LumityAdmin from 'model/LumityAdmin';
import Admin from 'model/admin/Admin';
import { getLumityAdmins } from 'modules/admins/slices/lumityAdminSlice';
import {
  findAdminByAdminId,
  clearAdminFind,
  deleteAdmin,
  clearAdminDeletion,
  inviteAdmin,
  clearinviteAdmin,
  deactivateAdminInvite,
  clearDeactivateAdminInvite,
} from 'modules/admins/slices/adminSlice';
import { AccountStatus } from 'modules/admins/constants/adminConstants';
import TablePagination from 'model/TablePagination';

import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import CreateAdmin from 'modules/admins/pages/CreateAdmin/CreateAdmin';
import SendInviteTemplate from 'modules/admins/pages/SendInviteTemplate/SendInviteTemplate';
import { IndividualType } from 'constants/commonConstants';
import AlertDialog from 'components/AlertDialog/AlertDialog';
import { getInvitedTime } from 'util/dateUtil';

import styles from './lumityAdminList.module.less';

const convertLumityAdminsForTableRender = (lumityAdmins: LumityAdmin[]) => {
  if (lumityAdmins != null) {
    return lumityAdmins.map((admin) => ({
      key: admin.id,
      admin: admin,
      email: admin.email,
      accountStatus: admin,
    }));
  }
  return [];
};

const LumityAdminList: FC = () => {
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const [searchText, setSearchText] = useState<string>('');
  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);
  const [visibleConfirmationDelete, setVisibleConfirmationDelete] =
    useState<boolean>(false);
  const [admin, setAdmin] = useState<Admin | null>(null);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [visible, setVisible] = useState<boolean>(false);
  const [invitedEmail, setInvitedEmail] = useState<string>('');
  const [displayTemplate, setDisplayTemplate] = useState<boolean>(false);
  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'admin',
      field: 'admin',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText,
    },
    filters: {},
  });
  const dispatch = useAppDispatch();
  const lumityAdmins = useAppSelector(
    (state) => state.admins.lumityAdmins.lumityAdmins
  );
  const inProgress = useAppSelector(
    (state) => state.admins.lumityAdmins.inProgress
  );
  const adminStatus = useAppSelector((state) => state.admins.admins);
  const { content, metadata } = lumityAdmins || {};
  const { total } = metadata;
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getLumityAdmins(convertPaginationConfig(paginationConfig)));
  }, [dispatch, paginationConfig]);

  useEffect(() => {
    if (
      !adminStatus.adminFindInProgress &&
      !isEmpty(adminStatus.adminObj) &&
      adminStatus.error === null
    ) {
      setAdmin(adminStatus.adminObj);
      dispatch(clearAdminFind());
      if (!isModalOpen && !visibleConfirmationDelete) {
        setIsModalOpen(true);
        setVisibleConfirmation(false);
      }
    }
  }, [
    dispatch,
    adminStatus?.adminFindInProgress,
    adminStatus?.adminObj,
    adminStatus?.error,
    isModalOpen,
    visibleConfirmationDelete,
  ]);

  useEffect(() => {
    if (!adminStatus.deactivateInProgress && adminStatus.deactivateCompleted) {
      setVisible(true);
      setAdmin(null);
      setIsModalOpen(false);
      dispatch(clearDeactivateAdminInvite());
      dispatch(getLumityAdmins(convertPaginationConfig(paginationConfig)));
      if (adminStatus.error === null) {
        setAlertMessage({
          type: 'success',
          message: `Invite has been deactivated for ${invitedEmail}.`,
        });
      } else {
        setAlertMessage({
          type: 'error',
          message: 'Invite deactivation failed.',
        });
      }
    }
  }, [
    dispatch,
    adminStatus.deactivateInProgress,
    adminStatus.deactivateCompleted,
    adminStatus.error,
    paginationConfig,
    invitedEmail,
  ]);

  useEffect(() => {
    if (!adminStatus.deleteInProgress && adminStatus.deleteCompleted) {
      setVisibleConfirmationDelete(false);
      setVisible(true);
      setAdmin(null);
      dispatch(clearAdminDeletion());
      dispatch(getLumityAdmins(convertPaginationConfig(paginationConfig)));
      if (adminStatus.error === null) {
        setAlertMessage({
          type: 'success',
          message: 'Tech Admin has been removed.',
        });
      } else {
        setAlertMessage({
          type: 'error',
          message: 'Tech Admin delete failed.',
        });
      }
    }
  }, [
    dispatch,
    adminStatus.deleteInProgress,
    adminStatus.deleteCompleted,
    adminStatus.error,
    paginationConfig,
  ]);

  useEffect(() => {
    if (!adminStatus.inviteInProgress && adminStatus.inviteCompleted) {
      setVisible(true);
      setAdmin(null);
      setIsModalOpen(false);
      dispatch(clearinviteAdmin());
      setDisplayTemplate(false);
      dispatch(getLumityAdmins(convertPaginationConfig(paginationConfig)));
      if (adminStatus.error === null) {
        setAlertMessage({
          type: 'success',
          message: `Invite has been sent to ${invitedEmail}.`,
        });
      } else {
        setAlertMessage({
          type: 'error',
          message: 'Invite sent failed.',
        });
      }
    }
  }, [
    dispatch,
    adminStatus.inviteInProgress,
    adminStatus.inviteCompleted,
    adminStatus.error,
    paginationConfig,
    invitedEmail,
  ]);

  const getData = (filters: TablePagination) => {
    if (filters.sorterInfo) {
      const paginationConfigData = {
        sorterInfo: filters.sorterInfo,
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      };
      setPaginationConfig(paginationConfigData);
    } else {
      const paginationConfigData = {
        sorterInfo: {
          columnKey: 'admin',
          field: 'admin',
          order: 'ascend',
        },
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      } as TablePagination;
      setPaginationConfig(paginationConfigData);
    }
  };

  const closeBrokerModal = () => {
    if (displayTemplate) {
      setIsModalOpen(false);
      setAdmin(null);
      dispatch(getLumityAdmins(convertPaginationConfig(paginationConfig)));
    } else {
      toggleConfirmationPopup();
    }
    setDisplayTemplate(false);
  };

  const addAdmin = () => {
    closeAlert();
    setIsModalOpen(true);
    setVisibleConfirmation(false);
    setAdmin(null);
  };

  const confirmCloseModal = async (closeModal: boolean = true) => {
    visibleConfirmation && (await toggleConfirmationPopup());
    setAdmin(null);
    dispatch(getLumityAdmins(convertPaginationConfig(paginationConfig)));
    closeModal && setIsModalOpen(false);
  };

  const toggleConfirmationPopup = async () => {
    setVisibleConfirmation(!visibleConfirmation);
  };

  const toggleAdminCreatedInviteSent = () => {
    setAlertMessage({
      type: 'success',
      message: 'Admin has been successfully added and an invite has been sent',
    });
    setVisible(true);
  };

  const toggleAdminCreatedInviteNotSent = () => {
    setAlertMessage({
      type: 'success',
      message: 'Admin has been successfully added',
    });
    setVisible(true);
  };

  const toggleAdminUpdated = () => {
    setAlertMessage({
      type: 'success',
      message: 'Admin has been successfully updated',
    });
    setVisible(true);
  };

  const toggleConfirmationDeletePopup = () => {
    setVisibleConfirmationDelete(!visibleConfirmationDelete);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setSearchText(_searchText);
    const config = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText: _searchText },
    };
    debounceLoadData(config);
  };

  const sendInvite = () => {
    if (admin) {
      setInvitedEmail(admin.email);
      dispatch(inviteAdmin(IndividualType.LUMITY_ADMIN, admin.id));
    }
  };

  const getLumityAdminAccountStatus = (admin: LumityAdmin): any => {
    if (admin.accountStatus === AccountStatus.SEND_INVITE) {
      return 'Not Invited';
    } else if (admin.accountStatus === AccountStatus.INVITED) {
      return (
        <div>
          Invited
          <span className={styles.sendInviteDate}>
            {getInvitedTime(admin.invitedTime)}
          </span>
          <div className={styles.resendInvite}>
            <LinkButton onClick={() => handleSendInvite(admin)}>
              Resend Invite
            </LinkButton>
          </div>
        </div>
      );
    } else if (AccountStatus.ACCOUNT_ACTIVATED) {
      return 'Account Active';
    } else {
      return;
    }
  };

  const adminActionsMenu = (admin: LumityAdmin) => {
    return (
      <Menu>
        <Menu.Item key="edit" onClick={() => handleEdit(admin)}>
          Edit Admin
        </Menu.Item>
        {admin.accountStatus === AccountStatus.SEND_INVITE && (
          <Menu.Item key="send" onClick={() => handleSendInvite(admin)}>
            Send Invite
          </Menu.Item>
        )}
        {admin.accountStatus === AccountStatus.INVITED && (
          <Menu.Item key="send" onClick={() => handleDeactivateInvite(admin)}>
            Deactivate Invite
          </Menu.Item>
        )}
        <Menu.Item key="delete" onClick={() => handleDelete(admin)}>
          Remove Admin
        </Menu.Item>
      </Menu>
    );
  };

  const handleEdit = (admin: LumityAdmin) => {
    closeAlert();
    dispatch(findAdminByAdminId(admin.id));
  };

  const handleDelete = (admin: LumityAdmin) => {
    closeAlert();
    dispatch(findAdminByAdminId(admin.id));
    toggleConfirmationDeletePopup();
  };

  const confirmDelete = () => {
    if (admin) {
      dispatch(
        deleteAdmin(
          admin.organizationId ? admin.organizationId : null,
          admin.id
        )
      );
    }
  };

  const handleSendInvite = (admin: LumityAdmin) => {
    closeAlert();
    dispatch(findAdminByAdminId(admin.id));
    setDisplayTemplate(true);
  };

  const handleDeactivateInvite = (admin: LumityAdmin) => {
    closeAlert();
    if (admin) {
      setInvitedEmail(admin.email);
      dispatch(deactivateAdminInvite(admin.id));
    }
  };

  const closeAlert = () => {
    dispatch(clearAdminDeletion());
    dispatch(clearinviteAdmin());
    dispatch(clearDeactivateAdminInvite());
    setVisible(false);
    setAlertMessage({
      type: undefined,
      message: '',
    });
    setInvitedEmail('');
  };

  const adminTableColumns: DataColumn[] = [
    {
      title: 'ADMIN NAME',
      dataIndex: 'admin',
      key: 'admin',
      width: '30%',
      ellipsis: {
        showTitle: false,
      },
      sorter: (a: any, b: any) => 0, // this sort is done by backend.
      defaultSortOrder: 'ascend',
      render: (admin) => {
        return (
          <div className={styles.name}>
            <ProfileAvatar
              content={buildInitials(admin.firstName, admin.lastName)}
              src={admin.avatarUrl}
            />
            <OverflowPopover
              popoverContent={buildFullName(admin.firstName, admin.lastName)}
            >
              <span
                className={styles.nameColStyle}
                onClick={() => handleEdit(admin)}
              >
                {buildFullName(admin.firstName, admin.lastName)}
              </span>
            </OverflowPopover>
          </div>
        );
      },
      align: 'left',
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      ellipsis: {
        showTitle: false,
      },
      render: (email) => <OverflowPopover>{email}</OverflowPopover>,
    },
    {
      title: 'ACCOUNT STATUS',
      dataIndex: 'accountStatus',
      key: 'accountStatus',
      width: '15%',
      render: (colData) => getLumityAdminAccountStatus(colData),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'admin',
      key: 'admin',
      render: (admin) => <SelectDropdown overlay={adminActionsMenu(admin)} />,
      align: 'center',
    },
  ];

  return (
    <div className={styles.membersTableWrapper}>
      {visible && (
        <div className={styles.alertWrapper}>
          <AlertMessage
            type={alertMessage.type}
            message={alertMessage.message}
            closeAlert={closeAlert}
          />
        </div>
      )}
      <Row>
        <Col span={12}>
          <h1>Tech Admins</h1>
        </Col>
        <Col span={12}>
          <PageActionButton
            type="primary"
            className={styles.actionButton}
            onClick={() => addAdmin()}
          >
            Add Tech Admin
          </PageActionButton>
        </Col>
      </Row>
      <Row className={styles.searchBar}>
        <SearchBar placeholder="Search Admins" onChange={handleSearch} />
      </Row>
      <DataTable
        currentIndex={paginationConfig.paginationIndex}
        data={convertLumityAdminsForTableRender(content)}
        columns={adminTableColumns}
        pagination={true}
        getData={getData}
        total={total}
        loading={inProgress}
        pageSize={limit}
      />
      {!adminStatus.adminFindInProgress && (
        <>
          {admin?.id !== appBootInfo?.individualId &&
            visibleConfirmationDelete && (
              <ConfirmationDialog
                title="Remove Tech Admin"
                confirmText="Yes - Remove Tech Admin"
                cancelText="Cancel"
                closeModal={toggleConfirmationDeletePopup}
                onConfirm={confirmDelete}
                visible={visibleConfirmationDelete}
              >
                <p className={styles.warningConfirmation}>
                  <div>
                    Are you sure you want to remove this tech admin? They will
                    <br />
                    no longer be able to access the system; this action cannot
                    be <br />
                    un-done.
                  </div>
                </p>
              </ConfirmationDialog>
            )}
          {admin?.id === appBootInfo?.individualId &&
            visibleConfirmationDelete && (
              <AlertDialog
                title="Unable to Remove"
                onCancel={toggleConfirmationDeletePopup}
                closeModal={toggleConfirmationDeletePopup}
                visible={visibleConfirmationDelete}
              >
                <p className={styles.warningConfirmation}>
                  You are not able to remove yourself from the system.
                </p>
              </AlertDialog>
            )}
        </>
      )}
      <FullScreenModal
        visible={isModalOpen}
        onCancel={closeBrokerModal}
        footer={false}
        title={!displayTemplate ? `${admin ? 'Edit' : 'Add'} Tech Admin` : ''}
        className={styles.mediaScreen}
      >
        {!displayTemplate && (
          <CreateAdmin
            type={IndividualType.LUMITY_ADMIN}
            admin={admin}
            confirmCloseModal={(closeModal: boolean) =>
              confirmCloseModal(closeModal)
            }
            visibleConfirmation={visibleConfirmation}
            toggleConfirmationPopup={toggleConfirmationPopup}
            adminCreatedInviteSent={toggleAdminCreatedInviteSent}
            adminCreatedInviteNotSent={toggleAdminCreatedInviteNotSent}
            adminUpdated={toggleAdminUpdated}
          />
        )}
        {displayTemplate && admin && (
          <SendInviteTemplate
            admin={admin}
            closeBrokerModal={closeBrokerModal}
            sendInvite={sendInvite}
          />
        )}
      </FullScreenModal>
    </div>
  );
};

export default LumityAdminList;
