import { Empty, List } from 'antd';

import { ReactComponent as IconEdit } from 'assets/images/icon-edit-pen.svg';

import styles from './listComponent.module.less';

type OptionProps = {
  label: any;
  value?: string;
  type?: string;
};
type ListComponentProps = {
  className?: string;
  listItemClassName?: string;
  dataSource: OptionProps[];
  onClickItem?: Function;
  bordered?: boolean;
  showEditIcon?: boolean;
  onEdit?: (value: any) => void;
  editClassName?: string;
  subHeading?: string;
  subHeadingClassName?: string;
  header?: React.ReactFragment;
};
const ListComponent = ({
  className = '',
  listItemClassName = '',
  dataSource = [],
  onClickItem,
  bordered = true,
  showEditIcon,
  onEdit,
  editClassName = '',
  subHeading = '',
  subHeadingClassName = '',
  header,
}: ListComponentProps) => {
  return (
    <>
      <div className={`${subHeadingClassName}`}>{subHeading}</div>
      <List
        header={header}
        className={`${styles.listComponentMain} ${className} `}
        bordered={bordered}
        dataSource={dataSource}
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
        renderItem={(item, idx) => (
          <List.Item
            key={idx}
            className={`${styles.listItem} ${listItemClassName}`}
          >
            <span
              className={`${onClickItem ? styles.onEmployerHover : ''}`}
              onClick={() => onClickItem && onClickItem(item)}
            >
              {item.label}
            </span>
            {showEditIcon && (
              <div className={`${editClassName}`}>
                <IconEdit onClick={() => onEdit!(item)} />
              </div>
            )}
          </List.Item>
        )}
      />
    </>
  );
};

export default ListComponent;
