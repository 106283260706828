import {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';

import isEmpty from 'lodash/isEmpty';
import { Form, notification } from 'antd';
import { isEqual } from 'lodash';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import PlanBasicForm from 'modules/plans/components/PlanBasicForm/PlanBasicForm';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { AdditionalPlan } from 'model/plans/AdditionalPlan';
import { useNavContext } from 'hooks/useNavContext';
import {
  PLAN_ADDITIONAL_DOCUMENT,
  VALIDATION_NAME_DUPLICATED,
} from 'modules/plans/constants';
import {
  BenefitCategory,
  benefitGroupsChangedMsg,
  EMPTY_MESSAGE,
  formVerificationMsg,
  planAlreadyExistsError,
  planYearChangedMsg,
} from 'constants/commonConstants';
import {
  clearAdditionalPerksApiErrors,
  findAdditionalPerkPlanById,
  saveAdditionalPerkPlan,
} from 'modules/plans/slices/additionalPerkPlanSlice';
import { getCarriersByBenefitKind } from 'modules/plans/slices/basicPlanInfoSlice';
import {
  ERROR_MESSAGE_PLAN_EDITING,
  PLAN_NOTIFICATION_KEY,
} from 'constants/benguideCollaborationConstants';

import AdditionalPlanResources from 'modules/plans/components/AdditionalPlanResources/AdditionalPlanResources';
import FileType from 'model/plans/FileType';
import WebLinkType from 'model/plans/WebLinkType';
import {
  removePlanDocuments,
  updateWeblinks,
  uploadPlanDocument,
  uploadRemovePlanDocument,
} from 'modules/plans/slices/planDocumentsSlice';
import styles from './addAdditionalPerkPlan.module.less';

export const AdditionalPerkPlanBasicInfoFormFields = {
  requiredFields: ['planName', 'planYear', 'benefitClass'],
  formFields: [
    'planName',
    'planYear',
    'benefitClass',
    'benefitCarrier',
    'groupId',
  ],
};
type AddAdditionalPerkPlanProps = {
  onClose: () => void;
  closePlanAddModal: () => void;
  isCloseConfirmed: boolean;
  isPlanUpdateMode?: boolean;
  plan?: AdditionalPlan;
  isSaveDisabled?: boolean;
  dbgPlanYear?: string;
  isDBGPlan?: boolean;
  isModalVisible?: boolean;
};

const AddAdditionalPerkPlan = forwardRef(
  (props: AddAdditionalPerkPlanProps, ref) => {
    const {
      isCloseConfirmed,
      onClose,
      closePlanAddModal,
      isPlanUpdateMode,
      plan,
      isSaveDisabled = false,
      dbgPlanYear,
      isDBGPlan,
      isModalVisible,
    } = props;
    type formDataType = {
      planName: '';
      planYear: '';
      benefitClass: string[];
      weblink: '';
      benefitCarrier: '';
      fileName: '';
      groupId: '';
    };

    const dispatch = useAppDispatch();
    const { employerId, brokerId } = useNavContext();
    const { planYearsList } = useAppSelector(
      (state) => state.employer.employer
    );
    const basicPlanInfo = useAppSelector((state) => state.plan.planBasicInfo);
    const error = useAppSelector((state) => state.plan.additionalPerk.error);
    const { inProgress } = useAppSelector((state) => state.plan.additionalPerk);
    const { inProgress: documentUploadInProgress } = useAppSelector(
      (state) => state.plan.planDocuments
    );

    const [form] = Form.useForm();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<AlertInfo>({
      type: undefined,
      message: '',
    });

    const [selectedFileList, setSelectedFileList] = useState<FileType[]>([]);
    const [isDocRemoved, setIsDocRemoved] = useState<{
      [key: string]: boolean;
    }>({});
    const [selectedWeblink, setSelectedWeblink] = useState<WebLinkType[]>([]);

    const [formData, setFormData] = useState<formDataType>({
      planName: '',
      planYear: '',
      benefitClass: [],
      weblink: '',
      benefitCarrier: '',
      fileName: '',
      groupId: '',
    });
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const additionalResourcesRef = useRef<any>();
    const basicPlanInfoRef = useRef<any>();
    const { carriersList } = basicPlanInfo;
    const [notificationKey] = useState(PLAN_NOTIFICATION_KEY);
    const [loading, setLoading] = useState<boolean>(false);
    const [showPlanYearWarning, setShowPlanYearWarning] =
      useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      reset() {
        setSelectedFileList([]);
        setSelectedWeblink([]);
        setShowAlert(false);
        setShowWarning(false);
        setShowPlanYearWarning(false);
        form.resetFields();
      },
    }));

    useEffect(() => {
      if (isCloseConfirmed) {
        setFormData({
          planName: '',
          planYear: '',
          benefitClass: [],
          weblink: '',
          benefitCarrier: '',
          fileName: '',
          groupId: '',
        });
        basicPlanInfoRef.current?.resetForm();
        setShowAlert(false);
      }
    }, [form, isCloseConfirmed]);

    useEffect(() => {
      if (error?.data?.code === VALIDATION_NAME_DUPLICATED) {
        form.setFields([{ name: 'planName', errors: [EMPTY_MESSAGE] }]);
        setAlertMessage({ message: planAlreadyExistsError, type: 'error' });
        setShowAlert(true);
        dispatch(clearAdditionalPerksApiErrors());
        setLoading(false);
      }
    }, [dispatch, error, form]);

    useEffect(() => {
      if (brokerId && employerId && isModalVisible) {
        dispatch(
          getCarriersByBenefitKind(
            BenefitCategory.ADDITIONAL_PERK.value,
            brokerId,
            employerId
          )
        );
      }
    }, [dispatch, brokerId, employerId, isModalVisible]);

    useEffect(() => {
      setShowAlert(false);
      setShowWarning(false);
      setShowPlanYearWarning(false);
      if (isPlanUpdateMode && !isEqual(plan?.groups, formData.benefitClass)) {
        setShowPlanYearWarning(false);
        setShowWarning(true);
        setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
        setShowAlert(true);
      }
      if (
        isPlanUpdateMode &&
        !isEqual(plan?.planYearId, formData.planYear?.trim())
      ) {
        setShowPlanYearWarning(true);
        setAlertMessage({ message: planYearChangedMsg, type: 'warning' });
        setShowAlert(true);
      }
      if (
        isPlanUpdateMode &&
        !isEqual(plan?.planYearId, formData.planYear?.trim()) &&
        !isEqual(plan?.groups, formData.benefitClass)
      ) {
        setShowPlanYearWarning(true);
        setShowWarning(true);
        setShowAlert(true);
        setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
      }
    }, [isPlanUpdateMode, plan, formData]);

    useEffect(() => {
      if (plan) {
        const { name, groups, planYearId, benefitCarrier, groupId } = plan;

        const { id } = benefitCarrier || {};
        const formData = {
          planName: name,
          benefitClass: groups,
          planYear: planYearId,
          benefitCarrier: id ? id : null,
          groupId: groupId,
        };

        form.setFieldsValue(formData);
        setFormData(formData as formDataType);
      }
    }, [setFormData, form, plan]);

    const closeOnSave = async (id: string) => {
      await uploadDocument(id);
    };

    const onCancel = () => {
      closePlanAddModal();
    };

    const getValidationResult = async () => {
      try {
        await form.validateFields(
          AdditionalPerkPlanBasicInfoFormFields.requiredFields
        );
        return true;
      } catch (errorInfo: any) {
        setShowAlert(true);
        return false;
      }
    };

    const onClickSave = async () => {
      setShowAlert(false);
      setShowWarning(false);
      setShowPlanYearWarning(false);

      const isFormValid = await getValidationResult();
      const selectedPlanYear = planYearsList.find(
        (t) => t.id === formData.planYear
      );

      const formValues = form.getFieldsValue();
      const { planName, benefitCarrier } = formValues;

      const newPlan = {
        benefitKind: BenefitCategory.ADDITIONAL_PERK.value,
        employerId: employerId,
        planYearId: formData.planYear?.trim(),
        name: planName,
        startDate: selectedPlanYear?.startDate,
        endDate: selectedPlanYear?.endDate,
        groups: formData.benefitClass,
        benefitCarrierId: benefitCarrier ? benefitCarrier : null,
        groupId: formData.groupId ? formData.groupId : '',
      } as AdditionalPlan;

      if (newPlan?.groupId) {
        newPlan.groupId = newPlan.groupId?.trim();
      }

      delete newPlan.additionalDocumentReferences;
      delete newPlan.additionalWeblinks;

      if (isEmpty(newPlan?.documentReferences)) {
        delete newPlan.documentReferences;
      }

      if (isPlanUpdateMode) {
        newPlan.id = plan?.id;
      }

      if (isFormValid) {
        if (isPlanUpdateMode && isDBGPlan) {
          setLoading(true);
        }
        await dispatch(
          saveAdditionalPerkPlan(newPlan, (id: string) => {
            closeOnSave(id);
          })
        );
        notification.close(notificationKey);
      } else {
        setAlertMessage({ message: formVerificationMsg, type: 'error' });
        setShowAlert(true);
      }
    };

    const uploadDocument = async (id: string) => {
      const uploadDocuementSuccess = () => {
        setSelectedFileList([]);
        setSelectedWeblink([]);
        setShowAlert(false);
        onClose();
        if (plan && plan.id) {
          dispatch(findAdditionalPerkPlanById(plan.id));
        }
      };

      const planId = id ?? plan?.id;
      const removeDocs = Object.keys(isDocRemoved)
        .filter((documentType) => isDocRemoved[documentType])
        .map((documentType) => ({
          docType: PLAN_ADDITIONAL_DOCUMENT,
          planDocumentName: documentType,
        }));
      if (!isEmpty(selectedFileList) && isEmpty(removeDocs)) {
        await dispatch(
          uploadPlanDocument(
            selectedFileList,
            planId,
            BenefitCategory.ADDITIONAL_PERK.value
          )
        );
      } else if (!isEmpty(removeDocs) && isEmpty(selectedFileList)) {
        await dispatch(
          removePlanDocuments(
            planId,
            BenefitCategory.ADDITIONAL_PERK.value,
            removeDocs
          )
        );
      } else if (!isEmpty(removeDocs) && !isEmpty(selectedFileList)) {
        await dispatch(
          uploadRemovePlanDocument(
            selectedFileList,
            planId,
            BenefitCategory.ADDITIONAL_PERK.value,
            removeDocs
          )
        );
      }
      if (selectedWeblink.length > 0) {
        await dispatch(
          updateWeblinks(
            selectedWeblink,
            planId,
            BenefitCategory.ADDITIONAL_PERK.value
          )
        );
      }
      uploadDocuementSuccess();
    };

    return (
      <div
        className={`${
          isPlanUpdateMode ? styles.planEditModeWrapper : styles.wrapperForm
        }  `}
      >
        {showAlert && (
          <AlertMessage
            type={alertMessage.type}
            message={alertMessage.message}
            closeAlert={() => {
              setShowAlert(false);
            }}
            className={
              isPlanUpdateMode
                ? styles.alertWrapper
                : styles.alertMessageWrapper
            }
          />
        )}
        {isSaveDisabled && !plan && (
          <FixedAlertMessage
            type={'error'}
            message={ERROR_MESSAGE_PLAN_EDITING}
          />
        )}
        <PlanBasicForm
          benefitCategory={BenefitCategory.ADDITIONAL_PERK.value}
          setRequiredFieldError={setShowAlert}
          setFormData={setFormData}
          formData={formData}
          form={form}
          ref={basicPlanInfoRef}
          carriersList={carriersList}
          isPlanUpdateMode={isPlanUpdateMode}
          showWarning={showWarning}
          dbgPlanYear={dbgPlanYear}
          isDBGPlan={isDBGPlan}
          isModalVisible={isModalVisible}
          isCarrierRequired={false}
          showPlanYearWarning={showPlanYearWarning}
        />
        <div className={styles.header}>Plan Resources</div>
        <AdditionalPlanResources
          ref={additionalResourcesRef}
          plan={plan}
          benefitKind={BenefitCategory.ADDITIONAL_PERK.value}
          isCloseConfirmed={isCloseConfirmed}
          selectedFileList={selectedFileList}
          setSelectedFileList={setSelectedFileList}
          selectedWebLinkList={selectedWeblink}
          setSelectedWebLinkList={setSelectedWeblink}
          setIsDocRemoved={setIsDocRemoved}
        />
        <div className={styles.btnWrapper}>
          <SubmitButton
            disabled={isSaveDisabled}
            type="primary"
            className={
              isPlanUpdateMode ? styles.saveButtonWrapper : styles.saveButton
            }
            onClick={() => onClickSave()}
            loading={inProgress || loading || documentUploadInProgress}
          >
            {isPlanUpdateMode ? 'Done' : 'Save'}
          </SubmitButton>
          {isPlanUpdateMode ? (
            <CancelButton
              onClick={onCancel}
              className={styles.cancelButtonWrapper}
              withBorder={true}
            >
              Cancel
            </CancelButton>
          ) : (
            <CancelButton>
              <span onClick={onCancel} className={styles.cancelButton}>
                Cancel
              </span>
            </CancelButton>
          )}
        </div>
      </div>
    );
  }
);
AddAdditionalPerkPlan.displayName = 'AddAdditionalPerkPlan';
export default AddAdditionalPerkPlan;
