import { FC, useEffect, useState, useCallback } from 'react';
import { useSubscription } from 'react-stomp-hooks';
import { Button, notification } from 'antd';
import { InfoCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'hooks/redux';

import useDBGClient from 'modules/clients/DBGClient/useDBGClient';
import { enableEditModeForPublish } from 'modules/benefitGuide/slices/benguideSlice';
import {
  BENGUIDE_NOTIFICATION_RECEIVED,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
  BENGUIDE_NOTIFICATIONS,
  EXIT_BENGUIDE,
  REFRESH_BENGUIDE,
} from 'modules/clients/DBGClient/DBGClientConts';
import {
  ARCHIVED,
  DELETE,
  GENERAL_UPDATE,
  STATUS_CHANGE,
} from 'constants/notificationState';
import {
  GENERAL_UPDATE_TITLE,
  GENERAL_UPDATE_MESSAGE,
  DELETE_TITLE,
  DELETE_MESSAGE,
  PUBLISHED,
  IN_PROGRESS,
  STATUS_CHANGE_PUBLISHED_MESSAGE,
  STATUS_CHANGE_IN_PROGRESS_MESSAGE,
  DBG_NOTIFICATION_KEY,
  ARCHIVED_TITLE,
  ARCHIVED_MESSAGE,
} from 'constants/benguideCollaborationConstants';

import DBGClient from 'modules/clients/DBGClient/DBGClient';
import styles from './collaborationNotification.module.less';

interface CollaborationNotificationProps {
  benGuideId?: string | null;
  selectedBenGuides?: string[];
  isBenguideModalOpen?: boolean;
  loggedInUserIndividualId?: string;
  exitBenguide: () => void;
  inPublishMode?: boolean;
  exitPublishModal: () => void;
}

const CollaborationNotification: FC<CollaborationNotificationProps> = (
  props: CollaborationNotificationProps
) => {
  const { inPublishMode = false } = props;
  const [lastMessage, setLastMessage] = useState<any>({});
  const [notificationKey] = useState(DBG_NOTIFICATION_KEY);
  const [showOverlay, setShowOverlay] = useState(false);
  const [isInPlanEditMode, setIsInPlanEditMode] = useState(false);
  const dbgClient = useDBGClient();
  const dispatch = useAppDispatch();
  useSubscription(`/topic/benguide/${props.benGuideId}`, (message) =>
    setLastMessage(JSON.parse(message.body))
  );

  const refreshBenguide = useCallback(() => {
    notification.close(notificationKey);
    setShowOverlay(false);
    setIsInPlanEditMode(false);
    if (props.benGuideId) {
      dispatch(
        enableEditModeForPublish(props.benGuideId, (response: any) => {
          if (response && response.revision) {
            const revision = response.revision;
            dbgClient.postMessage({
              channel: BENGUIDE_NOTIFICATIONS,
              event: REFRESH_BENGUIDE,
              data: { revision },
            });
          }
        })
      );
    }
    if (inPublishMode) {
      props.exitPublishModal();
    }
  }, [
    notificationKey,
    dbgClient,
    setShowOverlay,
    inPublishMode,
    props,
    dispatch,
  ]);

  const exitBenguide = useCallback(() => {
    notification.close(notificationKey);
    if (
      lastMessage.state === DELETE ||
      (lastMessage.newStatus && lastMessage.newStatus === ARCHIVED)
    ) {
      dbgClient.postMessage({
        channel: BENGUIDE_NOTIFICATIONS,
        event: EXIT_BENGUIDE,
        data: {},
      });
      setShowOverlay(false);
    } else {
      props.exitBenguide();
      setShowOverlay(false);
    }
    if (inPublishMode) {
      props.exitPublishModal();
    }
    setIsInPlanEditMode(false);
  }, [
    notificationKey,
    setShowOverlay,
    props,
    dbgClient,
    lastMessage,
    inPublishMode,
  ]);

  const refreshButton = useCallback(() => {
    return (
      <Button type="link" size="small" onClick={() => refreshBenguide()}>
        Refresh to update
      </Button>
    );
  }, [refreshBenguide]);

  const exitButton = useCallback(() => {
    return (
      <Button type="link" size="small" onClick={() => exitBenguide()}>
        Exit Guide
      </Button>
    );
  }, [exitBenguide]);

  const openNotification = useCallback(
    (
      updateMessage: any,
      title: string,
      message: string,
      event: string,
      newState?: string
    ) => {
      notification.open({
        message: title,
        description: `${updateMessage.firstName} ${updateMessage.lastName} 
                         (${updateMessage.email}) ${message}`,
        btn:
          event === DELETE || (newState && newState === ARCHIVED)
            ? exitButton()
            : refreshButton(),
        placement: 'bottomLeft',
        duration: null,
        className: styles.notification,
        closeIcon: <></>,
        key: notificationKey,
        icon:
          event === DELETE || (newState && newState === ARCHIVED) ? (
            <CloseOutlined className={styles.exitIcon} size={20} />
          ) : (
            <InfoCircleOutlined className={styles.refreshIcon} size={20} />
          ),
      });
    },
    [notificationKey, refreshButton, exitButton]
  );

  const determineStatusChangeMessage = useCallback((updateMessage: any) => {
    if (
      updateMessage.previousStatus === IN_PROGRESS &&
      updateMessage.newStatus === PUBLISHED
    ) {
      return STATUS_CHANGE_PUBLISHED_MESSAGE;
    }
    if (
      updateMessage.previousStatus === PUBLISHED &&
      updateMessage.newStatus === IN_PROGRESS
    ) {
      return STATUS_CHANGE_IN_PROGRESS_MESSAGE;
    }
    return GENERAL_UPDATE_MESSAGE;
  }, []);

  useEffect(() => {
    if (
      lastMessage &&
      lastMessage.benefitGuideId === props.benGuideId &&
      props.isBenguideModalOpen
    ) {
      if (props.loggedInUserIndividualId !== lastMessage.individualId) {
        switch (lastMessage.state) {
          case GENERAL_UPDATE:
            openNotification(
              lastMessage,
              GENERAL_UPDATE_TITLE,
              GENERAL_UPDATE_MESSAGE,
              GENERAL_UPDATE
            );
            break;
          case STATUS_CHANGE:
            if (lastMessage.newStatus && lastMessage.newStatus === ARCHIVED) {
              openNotification(
                lastMessage,
                ARCHIVED_TITLE,
                ARCHIVED_MESSAGE,
                ARCHIVED,
                lastMessage.newStatus
              );
            } else {
              openNotification(
                lastMessage,
                GENERAL_UPDATE_TITLE,
                determineStatusChangeMessage(lastMessage),
                STATUS_CHANGE
              );
            }
            break;
          case DELETE:
            openNotification(lastMessage, DELETE_TITLE, DELETE_MESSAGE, DELETE);
            break;
        }
        dbgClient.postMessage({
          channel: BENGUIDE_NOTIFICATIONS,
          event: BENGUIDE_NOTIFICATION_RECEIVED,
          data: lastMessage,
        });
        setShowOverlay(true);
        setLastMessage('');
      }
    }
  }, [
    props.isBenguideModalOpen,
    props.selectedBenGuides,
    props.loggedInUserIndividualId,
    lastMessage,
    props.benGuideId,
    refreshButton,
    notificationKey,
    openNotification,
    determineStatusChangeMessage,
    dbgClient,
    isInPlanEditMode,
  ]);

  return (
    <>
      {showOverlay && <div className={styles.notificationOverlay} />}
      <DBGClient
        channel={BENGUIDE_NOTIFICATIONS}
        subscribe={(event: string, data: any) => {
          if (event === BENGUIDE_NOTIFICATION_HIDE_OVERLAY) {
            setShowOverlay(false);
          } else if (event === BENGUIDE_NOTIFICATION_SHOW_OVERLAY) {
            setShowOverlay(true);
          }
        }}
      />
    </>
  );
};

export default CollaborationNotification;
