import { lumityApi } from 'api/apiSlice';

const dashboardBrokerApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardBroker: builder.query<
      any,
      {
        brokerId: string | undefined;
      }
    >({
      query: (args) => {
        const { brokerId } = args;

        return {
          url: `v2/organizations/${brokerId}/dashboard`,
          method: 'get',
        };
      },
    }),
    // Fetch Broker related issues

    getBrokerDashboardIssues: builder.query({
      query: (args) => {
        const { brokerId } = args;

        return {
          url: `v1/issues-log/dashboard/`,
          method: 'get',
          params: {
            'organization-id': brokerId,
          },
        };
      },
    }),

    updateAllowedCount: builder.mutation<any, { orgId: string; data: any }>({
      query: ({ orgId, data }) => {
        return {
          url: `v2/organizations/${orgId}/subscription-update`,
          method: 'PUT',
          data: data,
        };
      },
    }),
  }),
});

export const {
  useGetDashboardBrokerQuery,
  useGetBrokerDashboardIssuesQuery,
  useUpdateAllowedCountMutation,
} = dashboardBrokerApi;
