import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Spin } from 'antd';
import isNull from 'lodash/isNull';
import dayjs, { Dayjs } from 'dayjs';
import Icon, { DownloadOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';

import LossRatioAreaChart from 'modules/claims/components/LossRatioAreaChart/LossRatioAreaChart';
import {
  claimsBreakdownDummyData,
  claimsSummaryDummyData,
  currencyRoundedFormatterClaims,
  lossRatioDummyData,
} from 'modules/claims/constants/util';
import ClaimsBreakdown from 'modules/claims/components/ClaimsBreakdown/ClaimsBreakdown';
import ClaimsHeader from 'modules/claims/components/ClaimsHeader/ClaimsHeader';
import {
  useGetClaimsLastUpdatedStatusQuery,
  useLazyGetClaimsBreakdownChartDataQuery,
  useLazyGetClaimsSummaryQuery,
  useLazyGetLargeClaimsChartDataQuery,
  useLazyGetLossRatioChartDataQuery,
  useLazyGetLossRatioDateRangeQuery,
  useLazyGetSFCChartDataQuery,
  useLazyGetSFCChartSummaryQuery,
  useLazyGetSFCDateRangeQuery,
  useLazyGetYearsQuery,
} from 'modules/claims/slices/claimsSlice';
import SummaryInfo from 'modules/claims/components/SummaryInfo/SummaryInfo';
import LargeClaims from 'modules/claims/components/LargeClaims/LargeClaims';
import YearSelector from 'modules/claims/components/YearSelector/YearSelector';
import SelfFundedCostChart from 'modules/claims/components/SelfFundedCostChart/SelfFundedCostChart';

import { useNavContext } from 'hooks/useNavContext';
import { currencyFormatter } from 'util/commonUtil';
import {
  BENEFIT_KIND_LABEL_MAP,
  BENEFIT_KIND_MEDICAL,
  FUNDING_TYPE,
  SELF_FUNDED_COST_CATEGORY,
  UP_CONSOLIDATE_CLAIMS,
  LARGE_CLAIMS,
} from 'modules/claims/constants/constants';
import { baseApi } from 'util/apiUtil';
import { isNullOrUndefined } from 'modules/plans/utils';

import styles from './claimTabDetails.module.less';

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

type ClaimTabDetailsProps = {
  benefitKind: string;
  preview: boolean;
  displayDummyData: boolean;
  claimManageOpen?: boolean;
  fundingType: string | undefined;
  claimsFileDeleted: boolean;
};
export const API_DATE_FORMAT = 'MM-YYYY';
export const LAST_SUCCESS_FILE = 'LAST_SUCCESS_FILE';
const ClaimTabDetails = (props: ClaimTabDetailsProps) => {
  const {
    benefitKind,
    preview,
    displayDummyData,
    claimManageOpen = false,
    fundingType,
    claimsFileDeleted,
  } = props;

  const [dateRange, setDateRange] = useState([
    dayjs().subtract(11, 'months'),
    dayjs(),
  ]);
  const [yearListAr, setYearListAr] = useState<any>([]);

  const [getYears, { data: yearList_ = [], isSuccess }] =
    useLazyGetYearsQuery();

  const [sfcChartViewType, setSFCChartViewType] = useState<string>(
    SELF_FUNDED_COST_CATEGORY.TOTAL_COST_PER_MONTH.value
  );

  const params = useParams();
  const { employerId } = useNavContext();

  const [
    getLargeClaimsChartData,
    { data: largeClaimChartData, isLoading: largeClaimsDataLoading },
  ] = useLazyGetLargeClaimsChartDataQuery();

  const [
    getClaimsBreakdownChartData,
    { data: claimsBreakdownData, isLoading: claimsBreakdownLoading },
  ] = useLazyGetClaimsBreakdownChartDataQuery();

  const [
    getLossRatioChartData,
    { data: lossRatioData, isLoading: lossRatioDataLoading },
  ] = useLazyGetLossRatioChartDataQuery();

  const [
    getLossRatioDateRange,
    { data: lossRatioDateRange, isLoading: dateRangeLoading },
  ] = useLazyGetLossRatioDateRangeQuery();

  const [
    getSFCDateRange,
    { data: sfcDateRange, isLoading: sfcDateRangeLoading },
  ] = useLazyGetSFCDateRangeQuery();

  const [
    getClaimsSummary,
    { data: claimsSummary, isLoading: claimsSummaryLoading },
  ] = useLazyGetClaimsSummaryQuery();

  const [
    getSFCClaimsSummary,
    { data: sfcClaimsSummary, isLoading: sfcClaimsSummaryLoading },
  ] = useLazyGetSFCChartSummaryQuery();

  const [getSFCChartData, { data: sfcData, isLoading: sfcDataLoading }] =
    useLazyGetSFCChartDataQuery();

  const { data: lastUpdatedData, refetch: refetchLastUpdatedData } =
    useGetClaimsLastUpdatedStatusQuery({
      employerId: params.employerId,
    });

  const [rangeChecked, setRangeChecked] = useState<boolean>(false);

  // standard time is 2 years(24 months) which calculates into 23 months with the starting month.
  const validDateRange = 23;

  useEffect(() => {
    getLossRatioDateRange({
      employerId: params.employerId,
      benefitKind: benefitKind,
      preview: preview,
    }).then(() => {
      setRangeChecked(true);
    });
  }, [
    benefitKind,
    dateRange,
    getLargeClaimsChartData,
    getLossRatioDateRange,
    params.employerId,
    preview,
    fundingType,
    claimsFileDeleted,
  ]);

  useEffect(() => {
    getSFCDateRange({
      employerId: params.employerId,
      benefitKind: benefitKind,
      preview: preview,
    }).then(() => {
      setRangeChecked(true);
    });
  }, [
    benefitKind,
    getSFCDateRange,
    params.employerId,
    preview,
    fundingType,
    claimsFileDeleted,
  ]);

  useEffect(() => {
    if (preview) {
      if (
        lossRatioDateRange &&
        !isEmpty(lossRatioDateRange.dataAvailable) &&
        fundingType === FUNDING_TYPE.FULLY_INSURED
      ) {
        const { firstDataMonth, firstDataYear, lastDataMonth, lastDataYear } =
          lossRatioDateRange;
        const firstDataDate = dayjs()
          .month(firstDataMonth - 1)
          .year(firstDataYear);
        const lastDataDate = dayjs()
          .month(lastDataMonth - 1)
          .year(lastDataYear);
        let selectedFromDate = dayjs(lastDataDate).subtract(11, 'months');
        if (
          selectedFromDate.isSame(firstDataDate) ||
          selectedFromDate.isBefore(firstDataDate)
        ) {
          selectedFromDate = firstDataDate;
        }
        setDateRange([selectedFromDate, lastDataDate]);
        const from = selectedFromDate.format(API_DATE_FORMAT);
        const to = lastDataDate.format(API_DATE_FORMAT);
        getClaimsBreakdownChartData({
          employerId: params.employerId,
          benefitKind: benefitKind,
          from: from,
          to: to,
          preview: preview,
        });
        getLossRatioChartData({
          employerId: params.employerId,
          benefitKind: benefitKind,
          from: from,
          to: to,
          preview: preview,
        });
        getClaimsSummary({
          employerId: params.employerId,
          benefitKind: benefitKind,
          from: from,
          to: to,
          preview: preview,
        });
        refetchLastUpdatedData();
      } else if (
        fundingType === FUNDING_TYPE.SELF_FUNDED &&
        sfcDateRange &&
        !isEmpty(sfcDateRange.dataAvailable)
      ) {
        const { firstDataMonth, firstDataYear, lastDataMonth, lastDataYear } =
          sfcDateRange;
        const firstDataDate = dayjs()
          .month(firstDataMonth - 1)
          .year(firstDataYear);
        const lastDataDate = dayjs()
          .month(lastDataMonth - 1)
          .year(lastDataYear);
        let selectedFromDate = dayjs(lastDataDate).subtract(11, 'months');
        if (
          selectedFromDate.isSame(firstDataDate) ||
          selectedFromDate.isBefore(firstDataDate)
        ) {
          selectedFromDate = firstDataDate;
        }
        setDateRange([selectedFromDate, lastDataDate]);
        const from = selectedFromDate.format(API_DATE_FORMAT);
        const to = lastDataDate.format(API_DATE_FORMAT);
        getSFCChartData({
          employerId: params.employerId,
          preview: preview,
          from: from,
          to: to,
          benefitKind: benefitKind,
        });
        getSFCClaimsSummary({
          employerId: params.employerId,
          preview: preview,
          from: from,
          to: to,
          benefitKind: benefitKind,
        });
      }
    } else if (rangeChecked) {
      if (
        lossRatioDateRange &&
        !isEmpty(lossRatioDateRange.dataAvailable) &&
        fundingType === FUNDING_TYPE.FULLY_INSURED
      ) {
        const { firstDataMonth, firstDataYear, lastDataMonth, lastDataYear } =
          lossRatioDateRange;
        const firstDataDate = dayjs()
          .month(firstDataMonth - 1)
          .year(firstDataYear);
        const lastDataDate = dayjs()
          .month(lastDataMonth - 1)
          .year(lastDataYear);
        let selectedFromDate = dayjs(lastDataDate).subtract(11, 'months');
        if (
          selectedFromDate.isSame(firstDataDate) ||
          selectedFromDate.isBefore(firstDataDate)
        ) {
          selectedFromDate = firstDataDate;
        }
        setDateRange([selectedFromDate, lastDataDate]);
        const from = selectedFromDate.format(API_DATE_FORMAT);
        const to = lastDataDate.format(API_DATE_FORMAT);
        getClaimsBreakdownChartData({
          employerId: params.employerId,
          benefitKind: benefitKind,
          from: from,
          to: to,
          preview: preview,
        });
        getLossRatioChartData({
          employerId: params.employerId,
          benefitKind: benefitKind,
          from: from,
          to: to,
          preview: preview,
        });
        getClaimsSummary({
          employerId: params.employerId,
          benefitKind: benefitKind,
          from: from,
          to: to,
          preview: preview,
        });
      } else if (
        fundingType === FUNDING_TYPE.SELF_FUNDED &&
        sfcDateRange &&
        !isEmpty(sfcDateRange.dataAvailable)
      ) {
        const { firstDataMonth, firstDataYear, lastDataMonth, lastDataYear } =
          sfcDateRange;
        const firstDataDate = dayjs()
          .month(firstDataMonth - 1)
          .year(firstDataYear);
        const lastDataDate = dayjs()
          .month(lastDataMonth - 1)
          .year(lastDataYear);
        let selectedFromDate = dayjs(lastDataDate).subtract(11, 'months');
        if (
          selectedFromDate.isSame(firstDataDate) ||
          selectedFromDate.isBefore(firstDataDate)
        ) {
          selectedFromDate = firstDataDate;
        }
        setDateRange([selectedFromDate, lastDataDate]);
        const from = selectedFromDate.format(API_DATE_FORMAT);
        const to = lastDataDate.format(API_DATE_FORMAT);
        getSFCChartData({
          employerId: params.employerId,
          preview: preview,
          from: from,
          to: to,
          benefitKind: benefitKind,
        });
        getSFCClaimsSummary({
          employerId: params.employerId,
          preview: preview,
          from: from,
          to: to,
          benefitKind: benefitKind,
        });
      }

      refetchLastUpdatedData();
    }
  }, [
    benefitKind,
    getClaimsBreakdownChartData,
    getClaimsSummary,
    getLossRatioChartData,
    getSFCChartData,
    lossRatioDateRange,
    params.employerId,
    preview,
    rangeChecked,
    refetchLastUpdatedData,
    fundingType,
    sfcDateRange,
    getSFCClaimsSummary,
    claimsFileDeleted,
  ]);

  const isDisabledDate = (current: any) => {
    const { dataAvailable } =
      fundingType === FUNDING_TYPE.FULLY_INSURED
        ? lossRatioDateRange
        : sfcDateRange;
    let disabledDate = true;
    if (!isEmpty(dataAvailable)) {
      dataAvailable.forEach(
        (range: {
          benefitKind: string;
          lastDataYear: any;
          lastDataMonth: number;
          firstDataYear: any;
          firstDataMonth: number;
        }) => {
          const isMatchActiveTabBenefitType =
            range.benefitKind.toLowerCase() === benefitKind.toLowerCase();
          const latestDate = dayjs()
            .set('year', range.lastDataYear)
            .set('month', range.lastDataMonth - 1);
          const firstDate = dayjs()
            .set('year', range.firstDataYear)
            .set('month', range.firstDataMonth - 1);
          if (
            current &&
            current.isSame(firstDate, 'month') &&
            isMatchActiveTabBenefitType
          ) {
            disabledDate = false;
          } else if (
            current &&
            current.isSame(latestDate, 'month') &&
            isMatchActiveTabBenefitType
          ) {
            disabledDate = false;
          }
          if (
            current &&
            current.isBetween(firstDate, latestDate, 'month') &&
            isMatchActiveTabBenefitType
          ) {
            disabledDate = false;
          }
        }
      );
    }
    return disabledDate;
  };

  const downloadClaimsFile: () => string = () => {
    return `${baseApi}/v1/analytics/employers/${employerId}/download?downloadType=${
      preview ? 'PREVIEW_FILE' : `LAST_SUCCESS_FILE`
    }&documentType=${UP_CONSOLIDATE_CLAIMS}`;
  };

  const onDateRangeChange = (value: any, type: string) => {
    let from;
    let to;
    if (type === 'fromDate') {
      let toDate = dateRange[1];
      if (Math.abs(value.diff(dateRange[1], 'months')) > validDateRange) {
        toDate = dayjs(value).add(validDateRange, 'months');
      }
      setDateRange([value, toDate]);
      from = value;
      to = toDate;
    } else {
      setDateRange([dateRange[0], value]);
      from = dateRange[0];
      to = value;
    }
    from = from.format(API_DATE_FORMAT);
    to = to.format(API_DATE_FORMAT);
    if (fundingType === FUNDING_TYPE.FULLY_INSURED) {
      getClaimsBreakdownChartData({
        employerId: params.employerId,
        benefitKind: benefitKind,
        from: from,
        to: to,
        preview: preview,
      });
      getLossRatioChartData({
        employerId: params.employerId,
        benefitKind: benefitKind,
        from: from,
        to: to,
        preview: preview,
      });
      getClaimsSummary({
        employerId: params.employerId,
        benefitKind: benefitKind,
        from: from,
        to: to,
        preview: preview,
      });
    } else {
      getSFCChartData({
        employerId: params.employerId,
        preview: preview,
        from: from,
        to: to,
        benefitKind: benefitKind,
      });
      getSFCClaimsSummary({
        employerId: params.employerId,
        preview: preview,
        from: from,
        to: to,
        benefitKind: benefitKind,
      });
    }
  };

  const summaryHeaderList = () => {
    const returnObjArr = [];
    if (
      (claimsSummary &&
        !isNull(claimsSummary.totalYtdPremium) &&
        !isNull(claimsSummary.totalYtdCost)) ||
      displayDummyData
    ) {
      const {
        avgPremiumPerEmployee,
        totalYtdPremium,
        avgCostPerEmployee,
        lossRatio,
        adjustedLossRatio,
        totalYtdCost,
        claimsAboveStopLoss,
      } = displayDummyData ? claimsSummaryDummyData : claimsSummary || {};

      const summaryData = [
        {
          label: 'Total Premium',
          amount: Number.isFinite(totalYtdPremium)
            ? currencyRoundedFormatterClaims(totalYtdPremium)
            : '',
          footer: avgPremiumPerEmployee && (
            <span className={styles.claimSummaryFooter}>
              {`Avg. Premium Per Employee ${currencyFormatter(
                avgPremiumPerEmployee
              )}`}
            </span>
          ),
          noFooter: !avgPremiumPerEmployee,
        },
        {
          label: 'Total Claims',
          amount: Number.isFinite(totalYtdCost)
            ? currencyRoundedFormatterClaims(totalYtdCost)
            : '',
          footer: avgCostPerEmployee && (
            <span className={styles.claimSummaryFooter}>
              {`Avg. Claims Per Employee ${currencyFormatter(
                avgCostPerEmployee
              )}`}
            </span>
          ),
          noFooter: !avgCostPerEmployee,
        },
        {
          label: `Loss Ratio`,
          amount: lossRatio ? `${lossRatio.toFixed(2)} %` : 'N/A',
          noFooter: true,
          toolTip:
            'This is the ratio of total expenses the carrier incurred (claims & admin fees) divided by the total premiums paid to the carrier',
          toolTipPosition: 'top',
        },
        {
          label: 'Adjusted Loss Ratio',
          amount: adjustedLossRatio ? `${adjustedLossRatio.toFixed(2)} %` : '',
          noFooter: isNull(adjustedLossRatio),
          toolTip:
            'The adjusted loss ratio backs out the claim expenses that were above the carrier’s stop-loss policy, and therefore not paid by the carrier',
          toolTipPosition: 'top',
          hide: isNull(adjustedLossRatio),
          footer: avgCostPerEmployee && (
            <span className={styles.claimSummaryFooter}>
              {`Claims Above Stop Loss ${currencyFormatter(
                claimsAboveStopLoss
              )}`}
            </span>
          ),
        },
      ];
      returnObjArr.push(
        summaryData
          .filter((value) => !value.hide)
          .map(
            (
              { label, amount, footer, toolTip, toolTipPosition, noFooter },
              index
            ) => (
              <Col
                key={`header-${index}`}
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                lg={{ span: 6 }}
                md={{ span: 6 }}
              >
                <SummaryInfo
                  topLabel={label}
                  amount={amount}
                  toolTipTitle={toolTip}
                  toolTipPosition={'top'}
                  noFooter={noFooter}
                  footer={footer}
                />
              </Col>
            )
          )
      );
    }
    return returnObjArr;
  };

  const sfcSummaryHeaderList = () => {
    const returnObjArr = [];
    if (
      (sfcClaimsSummary &&
        !isNull(sfcClaimsSummary.totalYtdPremium) &&
        !isNull(sfcClaimsSummary.totalYtdCost)) ||
      displayDummyData
    ) {
      const {
        totalAdjustedClaims,
        totalClaims,
        totalEmployerCost,
        totalFixedCost,
        totalStopLoss,
        selfFundedOverviewAverageAmounts = {},
      } = sfcClaimsSummary || {};

      const { averageClaimsCost, averageFixedCost, averageEmployerCost } =
        selfFundedOverviewAverageAmounts[sfcChartViewType] || {};
      const summaryData = [
        {
          label: 'Total Claims',
          amount: Number.isFinite(totalClaims)
            ? currencyRoundedFormatterClaims(Math.round(totalClaims))
            : '',
          footer: averageClaimsCost && (
            <span className={styles.claimSummaryFooter}>
              {`Avg. Claims Per ${getViewingTypeSummaryFooterLabel()} ${currencyRoundedFormatterClaims(
                Math.round(averageClaimsCost)
              )}`}
            </span>
          ),
          noFooter: !averageClaimsCost,
          hide: false,
        },
        {
          label: 'Total Adjusted Claims',
          amount: Number.isFinite(totalAdjustedClaims)
            ? currencyRoundedFormatterClaims(Math.round(totalAdjustedClaims))
            : '',
          footer: totalStopLoss && (
            <span className={styles.claimSummaryFooter}>
              {`Claims Above Stop-Loss ${currencyRoundedFormatterClaims(
                Math.round(totalStopLoss)
              )}`}
            </span>
          ),
          noFooter: !totalStopLoss,
          hide: benefitKind !== BENEFIT_KIND_MEDICAL || !totalStopLoss,
        },
        {
          label: 'Total Fixed Cost',
          amount: Number.isFinite(totalFixedCost)
            ? currencyRoundedFormatterClaims(Math.round(totalFixedCost))
            : 'N/A',
          footer: Number.isFinite(totalFixedCost) ? (
            <span className={styles.claimSummaryFooter}>
              {`Avg. Fixed Cost Per ${getViewingTypeSummaryFooterLabel()} ${currencyRoundedFormatterClaims(
                Math.round(averageFixedCost)
              )}`}
            </span>
          ) : (
            <span className={styles.claimSummaryFooter}>
              {`Avg.Fixed Cost Per ${getViewingTypeSummaryFooterLabel()} N/A`}
            </span>
          ),
          noFooter: isNullOrUndefined(!averageFixedCost),
          hide: false,
        },
        {
          label: 'Total Employer Cost',
          amount: Number.isFinite(totalEmployerCost)
            ? currencyRoundedFormatterClaims(Math.round(totalEmployerCost))
            : '',
          footer: Number.isFinite(averageEmployerCost) && (
            <span className={styles.claimSummaryFooter}>
              {`Avg. Employer Cost Per ${getViewingTypeSummaryFooterLabel()} ${currencyRoundedFormatterClaims(
                Math.round(averageEmployerCost)
              )}`}
            </span>
          ),
          noFooter: !Number.isFinite(averageEmployerCost),
          hide: false,
        },
      ];
      returnObjArr.push(
        summaryData
          .filter((value) => !value.hide)
          .map(({ label, amount, footer, noFooter }, index) => (
            <Col
              key={`header-${index}`}
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              lg={{ span: 6 }}
              md={{ span: 6 }}
            >
              <SummaryInfo
                topLabel={label}
                amount={amount}
                toolTipPosition={'bottom'}
                noFooter={noFooter}
                footer={footer}
              />
            </Col>
          ))
      );
    }
    return returnObjArr;
  };

  const getViewingTypeSummaryFooterLabel = () => {
    if (
      SELF_FUNDED_COST_CATEGORY.PER_EMPLOYEE_PER_MONTH.value ===
      sfcChartViewType
    ) {
      return 'Employee';
    } else if (
      SELF_FUNDED_COST_CATEGORY.PER_EMPLOYEE_PER_MONTH.value ===
      sfcChartViewType
    ) {
      return 'Member';
    } else {
      return 'Month';
    }
  };

  const largeClaimYearChange = (year: string) => {
    if (!isEmpty(year)) {
      getLargeClaimsChartData({
        employerId: params.employerId,
        from: year?.substring(0, 7),
        to: year?.substring(10, 17),
        preview: preview,
      });
    }
  };

  const getClaimsLastUpdatedData = () => {
    if (preview) {
      return dayjs().format('MMM DD, YYYY');
    }
    if (lastUpdatedData?.lastUpdated[UP_CONSOLIDATE_CLAIMS]) {
      return dayjs(lastUpdatedData?.lastUpdated[UP_CONSOLIDATE_CLAIMS]).format(
        'MMM DD, YYYY'
      );
    }
    return '';
  };

  useEffect(() => {
    getYears({
      benefitKind: benefitKind,
      preview: preview,
      employerId: params.employerId,
      documentType: LARGE_CLAIMS,
      currentTs: new Date().getTime(),
    });
  }, [benefitKind, params.employerId, preview, getYears]);

  useEffect(() => {
    if (isSuccess && yearList_ && yearList_.length) {
      setYearListAr(yearList_);
    }
  }, [isSuccess, yearList_, dateRange]);

  useEffect(() => {
    if (yearListAr && yearListAr.length) {
      getLargeClaimsChartData({
        employerId: params.employerId,
        from: yearListAr[0]?.substring(0, 7),
        to: yearListAr[0]?.substring(10, 17),
        preview: preview,
      });
    }
  }, [yearListAr, getLargeClaimsChartData, preview, params.employerId]);

  return (
    <div>
      {(displayDummyData ||
        (lossRatioData && !isEmpty(lossRatioData)) ||
        !isEmpty(sfcData)) && (
        <ClaimsHeader
          validDateRange={validDateRange}
          SummaryHeaderList={
            fundingType === FUNDING_TYPE.FULLY_INSURED
              ? summaryHeaderList()
              : sfcSummaryHeaderList()
          }
          dateRange={dateRange}
          disabledDate={isDisabledDate}
          loading={
            fundingType === FUNDING_TYPE.FULLY_INSURED
              ? claimsSummaryLoading
              : sfcClaimsSummaryLoading
          }
          onDateRangeChange={onDateRangeChange}
          headerTitle={`${BENEFIT_KIND_LABEL_MAP[benefitKind]} Claims`}
          displayDummyData={displayDummyData}
          claimManageOpen={claimManageOpen}
        />
      )}
      {fundingType === FUNDING_TYPE.FULLY_INSURED ? (
        <div className={styles.claimsChartWrapper}>
          {(displayDummyData || (lossRatioData && !isEmpty(lossRatioData))) && (
            <ChartTitle
              chartTitle="Loss Ratio | Total Premium vs. Total Claims"
              downloadUploadedFile={downloadClaimsFile}
              lastUpdatedDate={getClaimsLastUpdatedData()}
              yearListAr={yearListAr}
            />
          )}
          {lossRatioDataLoading || dateRangeLoading ? (
            <ClaimsDataLoader />
          ) : lossRatioData && !isEmpty(lossRatioData) ? (
            <LossRatioAreaChart
              lossRatioVTot={lossRatioData}
              isOnlyOneMonthAvailable={false}
              displayDummyData={displayDummyData}
            />
          ) : displayDummyData ? (
            <LossRatioAreaChart
              lossRatioVTot={lossRatioDummyData}
              isOnlyOneMonthAvailable={false}
              displayDummyData={displayDummyData}
            />
          ) : (
            <></>
          )}

          {benefitKind === BENEFIT_KIND_MEDICAL && (
            <>
              {(claimsBreakdownData || displayDummyData) &&
                lossRatioData &&
                !isEmpty(lossRatioData) && (
                  <ChartTitle
                    chartTitle="Claims Breakdown"
                    downloadUploadedFile={downloadClaimsFile}
                    lastUpdatedDate={getClaimsLastUpdatedData()}
                    yearListAr={yearListAr}
                  />
                )}
              {claimsBreakdownLoading || dateRangeLoading ? (
                <ClaimsDataLoader />
              ) : claimsBreakdownData &&
                lossRatioData &&
                !isEmpty(lossRatioData) ? (
                <ClaimsBreakdown
                  isSelfFunded={false}
                  claimsBreakdown={claimsBreakdownData}
                />
              ) : displayDummyData ? (
                <ClaimsBreakdown
                  isSelfFunded={false}
                  claimsBreakdown={claimsBreakdownDummyData}
                />
              ) : (
                <></>
              )}
            </>
          )}

          {largeClaimChartData &&
            benefitKind === BENEFIT_KIND_MEDICAL &&
            !isEmpty(largeClaimChartData.chartData) && (
              <div>
                <ChartTitle
                  chartTitle="Large Claims"
                  downloadUploadedFile={downloadClaimsFile}
                  lastUpdatedDate={getClaimsLastUpdatedData()}
                  benefitKind={benefitKind}
                  preview={preview}
                  yearSelector={true}
                  dateRange={dateRange}
                  yearListAr={yearListAr}
                  onYearChange={largeClaimYearChange}
                />
                {largeClaimsDataLoading ? (
                  <ClaimsDataLoader />
                ) : (
                  <LargeClaims
                    benefitKind={benefitKind}
                    fundingType={FUNDING_TYPE.FULLY_INSURED}
                    largeClaims={
                      largeClaimChartData ? largeClaimChartData.chartData : {}
                    }
                  />
                )}
              </div>
            )}
        </div>
      ) : (
        <div className={styles.claimsChartWrapper}>
          {(displayDummyData || sfcData) && (
            <ChartTitle
              chartTitle="Self-Funded Overview"
              downloadUploadedFile={downloadClaimsFile}
              lastUpdatedDate={getClaimsLastUpdatedData()}
              yearListAr={yearListAr}
            />
          )}
          {sfcDataLoading || sfcDateRangeLoading ? (
            <ClaimsDataLoader />
          ) : !isEmpty(sfcData) ? (
            <SelfFundedCostChart
              data={sfcData}
              aggregateStopLoss={1000}
              benefitKind={benefitKind}
              individualStopLoss={2000}
              onTabChange={setSFCChartViewType}
              selectedTab={sfcChartViewType}
            />
          ) : (
            <></>
          )}
          {(displayDummyData ||
            (largeClaimChartData &&
              !isEmpty(largeClaimChartData.chartData) &&
              benefitKind === BENEFIT_KIND_MEDICAL)) && (
            <div>
              <ChartTitle
                chartTitle="Large Claims"
                downloadUploadedFile={downloadClaimsFile}
                lastUpdatedDate={getClaimsLastUpdatedData()}
                benefitKind={benefitKind}
                preview={preview}
                yearSelector={true}
                dateRange={dateRange}
                yearListAr={yearListAr}
                onYearChange={largeClaimYearChange}
              />
              {largeClaimsDataLoading ? (
                <ClaimsDataLoader />
              ) : (
                <LargeClaims
                  benefitKind={benefitKind}
                  fundingType={FUNDING_TYPE.FULLY_INSURED}
                  largeClaims={
                    largeClaimChartData ? largeClaimChartData.chartData : {}
                  }
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
const ChartTitle = (props: {
  lastUpdatedDate: string;
  downloadUploadedFile: () => string;
  chartTitle: string;
  preview?: boolean;
  benefitKind?: string;
  yearSelector?: boolean;
  onYearChange?: (year: string) => void;
  dateRange?: Dayjs[];
  yearListAr: [];
}) => {
  const {
    lastUpdatedDate,
    downloadUploadedFile,
    chartTitle,
    preview,
    benefitKind,
    yearSelector,
    onYearChange,
    dateRange,
    yearListAr,
  } = props;

  const fileDownloadUrl = downloadUploadedFile();
  return (
    <>
      <Row>
        <label className={styles.chartTitle}>{chartTitle}</label>
      </Row>
      {yearSelector && (
        <Row>
          <div className={styles.yearSelector}>
            <YearSelector
              preview={preview}
              benefitKind={benefitKind}
              onSelectYear={onYearChange}
              dateRange={dateRange}
              yearListAr={yearListAr}
            />
          </div>
        </Row>
      )}
      <Row>
        <Col>
          <a className={styles.claimsDownloadFileLink} href={fileDownloadUrl}>
            Download Claims file
            <DownloadOutlined className={styles.claimsDownloadFileIcon} />
            <Icon type="file-text" onClick={downloadUploadedFile} />
          </a>
          <span className={styles.chartSectionUpdatedDate}>
            {`data as of ${lastUpdatedDate}`}
          </span>
        </Col>
      </Row>
    </>
  );
};

const ClaimsDataLoader = () => {
  return (
    <div className={styles.claimsDataLoaderWrapper}>
      <Spin />
    </div>
  );
};

export default ClaimTabDetails;
