import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISSUE_LOG_SAVE_FILTER_ID_TYPES } from 'modules/issueslog/constants/IssueLogFilterConstants';

type saveFilterTypes = {
  clickedSaved: boolean;
  filters: { searchKey: string; filters: { name: string; values: any[] }[] };
  filterSaveType: string;
};
interface issueLogFilterState {
  showMoreFilters: boolean;
  loadingFilters: boolean;
  page: number;
  [key: string]: any;
}

const initialState: issueLogFilterState = {
  showMoreFilters: false,
  loadingFilters: false,
  page: 1,
  [ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER]: {
    clickedSaved: false,
    filters: {},
  },
  [ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_ACCOUNT]: {
    clickedSaved: false,
    filters: {},
  },
  [ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_SUPPORT]: {
    clickedSaved: false,
    filters: {},
  },
  [ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_ACCOUNT]: {
    clickedSaved: false,
    filters: {},
  },
  [ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_SUPPORT]: {
    clickedSaved: false,
    filters: {},
  },
  [ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_ACCOUNT]: {
    clickedSaved: false,
    filters: {},
  },
  [ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_SUPPORT]: {
    clickedSaved: false,
    filters: {},
  },
};

export const issueLogFilterSlice = createSlice({
  name: 'issueLogFilter',
  initialState: initialState,
  reducers: {
    clickShowMore: (state, action: PayloadAction<boolean>) => {
      state.showMoreFilters = action.payload;
    },
    saveFilter: (state, action: PayloadAction<saveFilterTypes>) => {
      state.page = 1;
      switch (action.payload.filterSaveType) {
        case ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER: {
          state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER] = action.payload;
          break;
        }
        case ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_ACCOUNT: {
          state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_ACCOUNT] = action.payload;
          break;
        }
        case ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_SUPPORT: {
          state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_SUPPORT] = action.payload;
          break;
        }
        case ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_ACCOUNT: {
          state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_ACCOUNT] =
            action.payload;
          break;
        }
        case ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_SUPPORT: {
          state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_SUPPORT] =
            action.payload;
          break;
        }
        case ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_ACCOUNT: {
          state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_ACCOUNT] = action.payload;
          break;
        }
        case ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_SUPPORT: {
          state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_SUPPORT] = action.payload;
          break;
        }
      }
    },
    resetFilter: (state, action: PayloadAction<Partial<saveFilterTypes>>) => {
      state.page = 1;
      switch (action.payload.filterSaveType) {
        case ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER: {
          state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER].clickedSaved = false;
          break;
        }
        case ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_ACCOUNT: {
          state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_ACCOUNT].clickedSaved = false;
          break;
        }
        case ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_SUPPORT: {
          state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_SUPPORT].clickedSaved = false;
          break;
        }
        case ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_ACCOUNT: {
          state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_ACCOUNT].clickedSaved =
            false;
          break;
        }
        case ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_SUPPORT: {
          state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_SUPPORT].clickedSaved =
            false;
          break;
        }
        case ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_ACCOUNT: {
          state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_ACCOUNT].clickedSaved =
            false;
          break;
        }
        case ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_SUPPORT: {
          state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_SUPPORT].clickedSaved =
            false;
          break;
        }
      }
    },
    clearFilter: (state) => {
      state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER].clickedSaved = false;
      state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER].filters = {};
      state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_ACCOUNT].clickedSaved = false;
      state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_ACCOUNT].filters = {};
      state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_SUPPORT].clickedSaved = false;
      state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_SUPPORT].filters = {};
      state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_ACCOUNT].clickedSaved =
        false;
      state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_ACCOUNT].filters = {};
      state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_SUPPORT].clickedSaved =
        false;
      state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_SUPPORT].filters = {};
      state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_ACCOUNT].clickedSaved = false;
      state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_ACCOUNT].filters = {};
      state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_SUPPORT].clickedSaved = false;
      state[ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_SUPPORT].filters = {};
      state.page = 1;
    },
    setLoadingFilter: (state, action: PayloadAction<boolean>) => {
      state.loadingFilters = action.payload;
    },
    setChangePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const {
  clickShowMore,
  saveFilter,
  clearFilter,
  resetFilter,
  setLoadingFilter,
  setChangePage,
} = issueLogFilterSlice.actions;
export default issueLogFilterSlice.reducer;
