import React, { useEffect, useState } from 'react';
import { Divider, Popover, Row } from 'antd';

import BarChart from 'components/Charts/BarChart/BarChart';
import LegendLabel from 'components/Charts/LegendLabel/LegendLabel';
import { ReactComponent as Checkmark } from 'assets/images/checkmark.svg';

import { currencyFormatter, shortenCurrencyWithSuffix } from 'util/commonUtil';
import {
  BENEFIT_KIND_LABEL_MAP,
  BENEFIT_KIND_MEDICAL,
  COLOR_LEGEND_LABEL_SHADE,
  COLOR_LIGHT_GREY,
  SELF_FUNDED_COST_CATEGORY,
  SELF_FUNDED_COST_COLORS,
  SFC_CHART_LEGENDS,
  SFC_MEDICAL_CHART_LEGENDS,
} from 'modules/claims/constants/constants';

import { LegendProps } from 'modules/claims/constants/types';
import styles from './selfFundedCostChart.module.less';

type Props = {
  data: any;
  selectedTab: string;
  onTabChange: (key: string) => void;
  individualStopLoss: number;
  aggregateStopLoss: number;
  benefitKind: string;
};

const yAxisProps = {
  hide: false,
  tickLine: false,
  axisLine: false,
  tickGap: 0,
  tickMargin: 10,
  padding: { top: 0, bottom: 0 },
  tickFormatter: (value: number) => {
    return `${shortenCurrencyWithSuffix(value, 2)}`;
  },
  width: 80,
};

const legendProp: LegendProps = {
  align: 'right',
  verticalAlign: 'top',
  width: 500,
  height: 50,
  margin: {
    top: 10,
    left: 10,
    right: 10,
    bottom: 10,
  },
  formatter: (value: React.ReactNode) => {
    return <span className="legendLabel">{value}</span>;
  },
  iconType: 'circle',
  iconSize: 10,
};

const xAxisProps = {
  hide: false,
  tickLine: { height: 40, stroke: COLOR_LIGHT_GREY },
  axisLine: false,
  tickGap: 0,
  tickMargin: 10,
  height: 50,
  padding: { left: 0, right: 0 },
  tick: ({ payload, x, y }: any) => {
    const { value } = payload || {};
    return xAxisTick(value, x, y);
  },
};

const xAxisTick = (value: string, x: number, y: number) => {
  const labelArray = value.split(' ');
  return (
    value && (
      <>
        <text
          x={x - 12}
          y={y + 15}
          fontWeight={400}
          fill={COLOR_LEGEND_LABEL_SHADE}
        >
          {labelArray[0]}
        </text>
        <text
          x={x - 12}
          y={y + 35}
          fill={COLOR_LEGEND_LABEL_SHADE}
          fontWeight={400}
        >
          {`'${
            labelArray[1].length > 2
              ? labelArray[1].substring(labelArray[1].length - 2)
              : labelArray[1]
          }`}
        </text>
      </>
    )
  );
};

const SelfFundedCostChart = (props: Props) => {
  const {
    benefitKind = BENEFIT_KIND_LABEL_MAP[BENEFIT_KIND_MEDICAL],
    data,
    onTabChange,
  } = props;
  const { mbrspresent, emptyMBRSMonthsPresent } = data || {};

  const [viewingType, setViewingType] = useState<string>(
    SELF_FUNDED_COST_CATEGORY.TOTAL_COST_PER_MONTH.value
  );

  useEffect(() => {
    if (
      emptyMBRSMonthsPresent &&
      viewingType === SELF_FUNDED_COST_CATEGORY.PER_MEMBER_PER_MONTH.value
    ) {
      setViewingType(SELF_FUNDED_COST_CATEGORY.TOTAL_COST_PER_MONTH.value);
    }
    // eslint-disable-next-line
  }, [data, emptyMBRSMonthsPresent]);

  const getBarList = () => {
    const nonMedicalChartBars = [
      {
        dataKey: 'fixedFeesAmt',
        stackId: '1',
        fill: SELF_FUNDED_COST_COLORS.ORANGE,
        name: 'Fixed Fees',
      },
      {
        dataKey: 'claimsAmt',
        stackId: '1',
        fill: SELF_FUNDED_COST_COLORS.GREEN,
        name: 'Claims',
      },
    ];
    if (benefitKind !== BENEFIT_KIND_MEDICAL) {
      return nonMedicalChartBars;
    } else {
      return [
        ...nonMedicalChartBars,
        {
          dataKey: 'stopLossAmt',
          stackId: '1',
          fill: SELF_FUNDED_COST_COLORS.YELLOW,
          name: 'Stop Loss Paid',
        },
      ];
    }
  };

  const tooltipContent = (data: any) => {
    const {
      fixedFeesAmt,
      employerCost,
      stopLossAmt,
      adjustedClaimsAmt,
      totalClaims,
      claimsAmt,
    } = data.payload;

    if (benefitKind !== BENEFIT_KIND_MEDICAL) {
      return (
        <div className={styles.barChartTooltip}>
          <TooltipRow description="Claims" value={claimsAmt} />
          <TooltipRow
            description="Fixed Fees"
            value={fixedFeesAmt}
            preValueText="+"
          />
          <Divider className={styles.tooltipDivider} />
          <TooltipRow
            description="Employer Cost:"
            value={employerCost}
            isBold
          />
        </div>
      );
    }
    return (
      <div className={styles.barChartTooltip}>
        <TooltipRow description="Claims" value={totalClaims} />
        <TooltipRow
          description="Stop Loss Paid"
          value={stopLossAmt}
          preValueText="-"
        />
        <Divider className={styles.tooltipDivider} />
        <TooltipRow
          isBold={true}
          description="Adjusted Claims"
          value={adjustedClaimsAmt}
        />
        <TooltipRow
          description="Adjusted Claims"
          value={adjustedClaimsAmt}
          className="sectionDivider"
        />
        <TooltipRow
          description="Fixed Fees"
          value={fixedFeesAmt}
          preValueText="+"
        />
        <Divider className={styles.tooltipDivider} />
        <TooltipRow
          description="Employer Cost:"
          value={employerCost}
          isBold={true}
        />
      </div>
    );
  };

  const formatSFCChartData = (data = []) => {
    return data.map(
      ({
        stopLossAmt,
        month,
        employerCost,
        claimsAmt,
        fixedFeesAmt,
        adjustedClaimsAmt,
        year,
        totalClaims,
      }) => ({
        stopLossAmt: Math.abs(stopLossAmt),
        month,
        employerCost,
        claimsAmt,
        fixedFeesAmt,
        adjustedClaimsAmt,
        year,
        displayLabel: month + ' ' + year,
        totalClaims,
      })
    );
  };

  const getChartData = () => {
    const { chartContent } = data || {};
    const { total, pmpm, pepm } = chartContent || {};

    let chartData: any[] = [];

    switch (viewingType) {
      case SELF_FUNDED_COST_CATEGORY.TOTAL_COST_PER_MONTH.value:
        chartData = formatSFCChartData(total);
        break;
      case SELF_FUNDED_COST_CATEGORY.PER_MEMBER_PER_MONTH.value:
        chartData = formatSFCChartData(pmpm);
        break;
      case SELF_FUNDED_COST_CATEGORY.PER_EMPLOYEE_PER_MONTH.value:
        chartData = formatSFCChartData(pepm);
        break;
      default:
        chartData = [];
    }
    if (chartData && chartData.length > 0) {
      chartData = chartData.sort((a, b) => {
        return (
          new Date(`${a.year}-${a.month}-1`).getTime() -
          new Date(`${b.year}-${b.month}-1`).getTime()
        );
      });
    }

    return chartData;
  };

  const getBarChart = () => {
    return (
      <div className={styles.selfFundedCostChartWrapper}>
        <BarChart
          dataKey="displayLabel"
          data={getChartData()}
          barList={getBarList()}
          chartDimensions={{ height: 400 }}
          tooltipContent={(data: any) => tooltipContent(data)}
          legendProp={legendProp}
          yAxisProps={yAxisProps}
          xAxisProps={xAxisProps}
          hideChartLegend={true}
        />
      </div>
    );
  };

  const getViewingTypeSelector = (
    type: { value: string; label: string },
    disabled?: boolean
  ) => {
    return (
      <div
        className={`${styles.viewingType} ${
          type.value === SELF_FUNDED_COST_CATEGORY.TOTAL_COST_PER_MONTH.value
            ? styles.totalCost
            : ''
        } ${viewingType === type.value ? styles.active : ''}`}
        style={disabled ? { opacity: 0.5 } : { opacity: 1 }}
        onClick={() => {
          if (!disabled) {
            setViewingType(type.value);
            onTabChange(type.value);
          }
        }}
      >
        {disabled ? (
          <Popover
            overlayClassName="popoverInner summaryInfotooltip"
            placement="top"
            content="Some months in the selected date range are missing this value. Please update the imported data or select a different date range."
          >
            {type.label}
          </Popover>
        ) : (
          type.label
        )}
        {viewingType === type.value && (
          <Checkmark className={styles.checkmark} />
        )}
      </div>
    );
  };

  const getChartLegend = () => {
    const chartLegendLabels =
      benefitKind === BENEFIT_KIND_MEDICAL
        ? SFC_MEDICAL_CHART_LEGENDS
        : SFC_CHART_LEGENDS;
    return Object.values(chartLegendLabels).map((entry: any, index) => {
      return (
        <>
          <LegendLabel
            title={entry.label}
            prefixShape={{
              color: entry.color,
              width: '10px',
              height: '10px',
              circle: true,
            }}
            className={{
              wrapperClass: 'chart-legend-label',
              headerClass: 'chart-legend-header',
            }}
          />
        </>
      );
    });
  };

  return (
    <div className={styles.selfFundedCostChartWrapper}>
      <Row className={styles.chartHeaderContainer}>
        <div className={styles.viewingTypeWrapper}>
          Viewing:
          {getViewingTypeSelector(
            SELF_FUNDED_COST_CATEGORY.TOTAL_COST_PER_MONTH
          )}
          <span>|</span>
          {getViewingTypeSelector(
            SELF_FUNDED_COST_CATEGORY.PER_EMPLOYEE_PER_MONTH
          )}
          {mbrspresent && (
            <>
              <span>|</span>
              {getViewingTypeSelector(
                SELF_FUNDED_COST_CATEGORY.PER_MEMBER_PER_MONTH,
                emptyMBRSMonthsPresent
              )}
            </>
          )}
        </div>
        <div className={styles.chartLegendWrapper}>{getChartLegend()}</div>
      </Row>
      {getBarChart()}
    </div>
  );
};

export default SelfFundedCostChart;

const TooltipRow = (props: {
  isBold?: boolean;
  description?: string;
  value: number;
  preValueText?: string;
  className?: string;
}) => {
  const { isBold, description, value, preValueText, className = '' } = props;

  return (
    <div className={`${styles.tooltipRow} ${className}`}>
      <div className={`cost-desc ${isBold && 'bold'}`}>{description}</div>
      <div className="text bold">
        {preValueText} {currencyFormatter(Math.round(value))}
      </div>
    </div>
  );
};
