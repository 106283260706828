import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Col, Row } from 'antd';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import ContentContainer from 'containers/ContentContainer/ContentContainer';
import { ReactComponent as IconVision } from 'assets/images/benefitCategories/icon-vision.svg';
import { useNavContext } from 'hooks/useNavContext';
import EmptyAddPlan from 'modules/plans/components/EmptyAddPlan/EmptyAddPlan';
import PlansList from 'modules/plans/components/PlansList/PlansList';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { AlertInfo } from 'components/Alert/AlertMessage';
import AlertMessageWrapper from 'modules/plans/components/AlertMessageWrapper/AlertMessageWrapper';
import SearchBar from 'components/SearchBar/SearchBar';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import AddPlanModal, {
  SelectedPlanProps,
  TDocumentExtractionSource,
} from 'modules/plans/components/AddPlanModal/AddPlanModal';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import {
  getVisionPlansList,
  resetVisionPlanReduxStore,
} from 'modules/plans/slices/visionPlanSlice';
import TablePagination from 'model/TablePagination';
import PlanYear from 'model/PlanYear';
import { VisionPlan } from 'model/plans/VisionPlan';
import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
  resetPaginationConfig,
} from 'util/commonUtil';
import useDocumentExtractionReviewModal from 'hooks/useDocumentExtractionReviewModal';
import PlanListPlanYearDropDown from 'components/PlanListPlanYearDropDown/PlanListPlanYearDropDown';
import {
  BenefitCategory,
  planCreateSuccess,
  sbcReviewStarted,
  SBC_PARAMS,
  sbcfetchError,
  EMPLOYER_MEMBER_RESTRICTED,
  planOfferFetchError,
} from 'constants/commonConstants';
import { usePrevious } from 'hooks/usePrevious';
import AiPlanUploadHistoryModal from 'modules/plans/components/AiPlanUploadHistoryModal/AiPlanUploadHistoryModal';
import { ReactComponent as RfpIconSelected } from 'assets/images/Rfp-ai-selected.svg';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { DocumentExtractionSource } from 'modules/plans/enums/DocumentExtractionSource';
import {
  createModalCloseHandler,
  removeSearchParams,
} from 'modules/plans/utils';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import { clear, setOPSView } from 'modules/plans/slices/aiSbcUploaderSlice';
import UploaderType from 'modules/plans/enums/UploaderType';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import SendSelfToOPSSuccessComponent from 'modules/plans/components/SendSelfToOPSSuccessComponent/SendSelfToOPSSuccessComponent';

import styles from './visionPlansList.module.less';

const VisionPlansList: FC = () => {
  const [fullScreenModalView, setFullScreenModalView] =
    useState<boolean>(false);
  const { brokerId, employerId, employer } = useNavContext();
  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );
  const { status } = useAppSelector((state) => state.plan.aiSbc);
  const [planYear, setPlanYear] = useState<PlanYear | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [isAiPlanModalOpen, setIsAiPlanModalOpen] = useState<boolean>(false);
  const [addPlanPropsToState, setAddPlansPropsToState] = useState<
    SelectedPlanProps | undefined
  >(undefined);
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText,
    },
    filters: {},
  });
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });

  const [
    getUpcomingPlanYears,
    { data: upcomingPlanYearData, isLoading: isGetUpcomingPlanYearsLoading },
  ] = useLazyGetUpcomingPlanYearsByEmployerQuery();

  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );

  const isRenewalStarted =
    upcomingPlanYearData?.upcomingPlanYears?.some(
      (obj: any) => obj?.planYearId === null
    ) && planYear?.current;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { visionPlanList, inProgress, error } = useAppSelector(
    (state) => state.plan.visionPlan
  );

  const { metadata } = visionPlanList || {};
  const total = metadata ? metadata.total : 0;
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  const previousPlanYear = usePrevious(planYear?.id);

  const fetchPlanList = useCallback(() => {
    if (employerId && planYear?.id) {
      dispatch(
        getVisionPlansList(
          convertPaginationConfig(paginationConfig),
          employerId,
          planYear.id
        )
      );
    }
  }, [employerId, planYear?.id, dispatch, paginationConfig]);

  useEffect(() => {
    if (employerId && planYear) {
      if (previousPlanYear !== planYear?.id) {
        setPaginationConfig(resetPaginationConfig(paginationConfig));
      }
      fetchPlanList();
    }
    // eslint-disable-next-line
  }, [dispatch, employerId, planYear, paginationConfig, fetchPlanList]);

  useEffect(() => {
    if (employerId) {
      dispatch(getPlanYears(employerId));
      getUpcomingPlanYears({ employerId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, employerId]);

  const handleOpenModal = () => {
    setFullScreenModalView(true);
    dispatch(resetVisionPlanReduxStore());
  };

  /**
   * Custom hook for handling SBC (Summary of Benefits and Coverage) plan review modal.
   * This hook abstracts the logic for opening a modal based on URL parameters,
   */
  useDocumentExtractionReviewModal(
    isOpsAdmin,
    dispatch,
    handleOpenModal,
    false,
    false,
    () => {},
    () => {
      setAlertMessage({
        type: 'success',
        message: (
          <SendSelfToOPSSuccessComponent
            onClick={() => setIsAiPlanModalOpen(true)}
          />
        ),
      });
      setVisible(true);
    }
  );
  const openSBCplanModal = (jobId: string) => {
    dispatch(
      setOPSView({
        jobId: jobId,
        planUploderType: isOpsAdmin
          ? UploaderType.OPS_VIEW
          : UploaderType.ADMIN_VIEW,
      })
    );
    handleOpenModal();
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setSearchText(_searchText);
    const pageInfo = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText: _searchText },
    };
    debounceLoadData(pageInfo);
  };

  const getData = (filters: TablePagination) => {
    const paginationConfigData = {
      sorterInfo: {
        columnKey: 'name',
        field: 'name',
        order: 'ascend',
      },
      paginationIndex: filters.paginationIndex,
      filterInfo: {
        limit: filters.filterInfo.limit,
        offset: filters.filterInfo.offset,
        searchText: searchText,
      },
      filters: filters.filters,
    } as TablePagination;
    setPaginationConfig(paginationConfigData);
  };

  const goToOverviewPage = (plan: VisionPlan) => {
    navigate(
      `/brokers/${brokerId}/employers/${employerId}/vision/${plan.id}/overview`,
      { state: { plan } }
    );
  };

  const closeAlert = () => setVisible(false);

  const onClose = () => {
    if (employerId && planYear) {
      if (!error) {
        setAlertMessage({
          type: 'success',
          message: planCreateSuccess,
        });
        setVisible(true);
      }
      fetchPlanList();
    }
  };

  const onSavePlan = (
    isSuccess: boolean,
    successMessage?: string | undefined | null
  ) => {
    if (isSuccess) {
      setAlertMessage({
        type: 'success',
        message: successMessage ?? planCreateSuccess,
      });
      setVisible(true);
    }
    setFullScreenModalView(false);
    setAddPlansPropsToState(undefined);
    fetchPlanList();
    dispatch(resetVisionPlanReduxStore());
    dispatch(clear());
  };

  const setSelectedPlanYear = (planYear: PlanYear | null | undefined) => {
    if (employerId && planYear) {
      setPlanYear(planYear);
    }
  };

  const onModalClose = () => {
    setFullScreenModalView(false);
    dispatch(resetVisionPlanReduxStore());
    setAddPlansPropsToState(undefined);
    fetchPlanList();
  };

  const handleModalClose = (
    extractStatus: ProcessStatus,
    sendToOpsFailed?: boolean,
    docExtractionSource?: TDocumentExtractionSource
  ) => {
    const sendSelfToOpsSuccessMessage = sendToOpsFailed ? (
      <SendSelfToOPSSuccessComponent
        isSendOpsAdmin={true}
        onClick={() => setIsAiPlanModalOpen(true)}
      />
    ) : (
      sbcReviewStarted
    );

    const constructFailedMessage = (
      source?: DocumentExtractionSource | undefined
    ) => {
      if (source === DocumentExtractionSource.UPDATE_QUOTE_PLAN) {
        return planOfferFetchError;
      }
      return sbcfetchError;
    };

    return createModalCloseHandler({
      clearAction: () => dispatch(clear()),
      removeParamsAction: () =>
        removeSearchParams([SBC_PARAMS.isReview, SBC_PARAMS.jobId]),
      handleCloseAction: onModalClose,
      setAlertMessageAction: setAlertMessage,
      setVisibleAction: setVisible,
      status: status,
      messages: {
        [ProcessStatus.PROCESSING]: sendSelfToOpsSuccessMessage,
        [ProcessStatus.FAILED]: constructFailedMessage(
          docExtractionSource?.source
        ),
      },
    })(extractStatus);
  };

  const addVisionPlanButton = (
    <div className={styles.allTypesPlanWrapper}>
      <div className={styles.addPlanButtonWrapper}>
        <PageActionButton
          type="primary"
          onClick={() => setFullScreenModalView(true)}
          className={styles.actionButton}
        >
          Add Vision Plan
        </PageActionButton>
      </div>
      <LinkButton onClick={() => setIsAiPlanModalOpen(true)}>
        <RfpIconSelected /> Plan Uploads
      </LinkButton>
    </div>
  );

  const permittedVisionPlanButton = usePermitByUserType(
    addVisionPlanButton,
    EMPLOYER_MEMBER_RESTRICTED
  );

  return (
    <ContentContainer>
      <AlertMessageWrapper
        type={alertMessage.type}
        message={alertMessage.message}
        closeAlert={closeAlert}
        visible={visible}
      />
      <Row>
        <Col span={18}>
          <div className={styles.employerName}>
            <OverflowPopover
              popoverContent={`${employer?.name}`}
              maxWidth="50%"
            >
              {`${employer?.name} `}
            </OverflowPopover>
            Vision Plans
          </div>
        </Col>
        <Col span={6}>
          {((!inProgress && total !== 0) ||
            (!inProgress && total == 0 && !isEmpty(searchText))) &&
            permittedVisionPlanButton}
        </Col>
      </Row>

      <PlanListPlanYearDropDown onPlanYearSelect={setSelectedPlanYear} />
      {!inProgress && total === 0 && isEmpty(searchText) ? (
        <EmptyAddPlan
          title="You have no Vision plans set up!"
          description="Get started by adding your first plan"
          icon={<IconVision />}
          actionTitle="+ Add Vision Plan"
          onActionClick={() => setFullScreenModalView(true)}
        >
          <div className={styles.aiLinkWrapper}>
            <LinkButton onClick={() => setIsAiPlanModalOpen(true)}>
              <RfpIconSelected /> Plan Uploads
            </LinkButton>
          </div>
        </EmptyAddPlan>
      ) : (
        <>
          <div className={styles.searchBar}>
            <SearchBar placeholder="Search Plans" onChange={handleSearch} />
          </div>
          <PlansList
            planYear={planYear}
            planList={visionPlanList}
            inProgress={inProgress || isGetUpcomingPlanYearsLoading}
            limit={limit}
            paginationConfig={paginationConfig}
            getData={getData}
            goToOverviewPage={goToOverviewPage}
            benefitCategory={BenefitCategory.VISION.value}
            onSaveClose={onClose}
            isRenewalProcessStarted={!!isRenewalStarted}
            setAddPlansPropsToState={setAddPlansPropsToState}
          />
        </>
      )}
      <AddPlanModal
        {...addPlanPropsToState}
        benefit="VISION"
        onClose={handleModalClose}
        isOpen={addPlanPropsToState?.isOpen ?? fullScreenModalView}
        isRenewalProcessStarted={!!isRenewalStarted}
        onSave={onSavePlan}
      />
      <AiPlanUploadHistoryModal
        benefit="VISION"
        isOpen={isAiPlanModalOpen}
        onClose={() => setIsAiPlanModalOpen(false)}
        planYearName={planYear?.name}
        openSBCplanModal={openSBCplanModal}
      />
    </ContentContainer>
  );
};

export default VisionPlansList;
