import Login from 'modules/auth/pages/Login/Login';
import Register from 'modules/auth/pages/Register/Register';
import ResetPassword from 'modules/auth/pages/ResetPassword/ResetPassword';
import CreatePassword from 'modules/auth/pages/CreatePassword/CreatePassword';
import CarrierInfo from 'modules/carriers/components/CarrierInfo/CarrierInfo';
import PublicBenefitConsultation from 'modules/benefitsConsultation/pages/PublicBenefitConsultation/PublicBenefitConsultation';
import NotFound from 'components/NotFound/NotFound';
import RegisterWithCodAndEmail from 'modules/auth/pages/RegisterWithCodeAndEmail/RegisterWithCodAndEmail';

export const LOGIN_PATH = '/login';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/reset-password';
export const REGISTER_ACCOUNT_PATH = '/register';
export const REGISTER_WITH_CODE_PATH = '/register-with-code';
export const BENEFIT_CONSULTATION_INVITE_PATH = '/benefit-consultation/invite';
export const BENEFIT_CONSULTATION_INVITE_CONFIRM_PATH =
  '/benefit-consultation/invite-confirm';
export const BENEFIT_CONSULTATION_APPOINTMENT_ACTION_PATH =
  '/benefit-consultation/appointment-action';
export const BENEFIT_CONSULTATION_CANCEL_APPOINTMENT_PATH =
  '/benefit-consultation/cancel-appointment';
export const NOT_FOUND = '/404';

const authRoutes = [
  {
    path: LOGIN_PATH,
    element: <Login />,
  },
  {
    path: FORGOT_PASSWORD_PATH,
    element: <ResetPassword />,
  },
  {
    path: RESET_PASSWORD_PATH,
    element: <CreatePassword />,
  },
  {
    path: REGISTER_ACCOUNT_PATH,
    element: <Register />,
  },
  {
    path: REGISTER_WITH_CODE_PATH,
    element: <RegisterWithCodAndEmail />,
  },
  {
    path: 'carrierinfo',
    element: <CarrierInfo />,
  },
  {
    path: BENEFIT_CONSULTATION_INVITE_PATH,
    element: <PublicBenefitConsultation type="invite" />,
  },
  {
    path: BENEFIT_CONSULTATION_INVITE_CONFIRM_PATH,
    element: <PublicBenefitConsultation type="invite-confirm" />,
  },
  {
    path: BENEFIT_CONSULTATION_CANCEL_APPOINTMENT_PATH,
    element: <PublicBenefitConsultation type="cancel-appointment" />,
  },
  {
    path: NOT_FOUND,
    element: <NotFound />,
  },
];

export default authRoutes;
