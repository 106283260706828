import { FC, useState } from 'react';
import { Col, Form, Row, Select } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';

import { useStateCallback } from 'hooks/updateState';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import InputForm from 'components/InputForm/InputForm';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { exitWithoutSavingMsg } from 'constants/commonConstants';
import { changedEmployerIdState } from 'modules/benefitGuide/slices/benguideSlice';
import styles from './addBenguideModal.module.less';

const { Option } = Select;

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  brokerId?: string;
};
export const basicRequiredFields = ['employerId'];
const AddEmployerModal: FC<Props> = (props: Props) => {
  const { isModalOpen, setIsModalOpen, brokerId } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const employerList = useAppSelector(
    (state) => state.brokers.brokerDashboard.employerList
  );
  const [formData, setFormData] = useState({
    employerId: '',
  });
  const [form] = Form.useForm();
  const [isConfirmOpen, setIsConfirmOpen] = useStateCallback(false);

  const handleCloseModal = () => {
    setIsConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setIsConfirmOpen(false, () => {
      form.resetFields(basicRequiredFields);
      setIsModalOpen(false);
    });
  };

  const onInputChange = (allValues: any) => {
    form.setFieldsValue(allValues);
    setFormData({ ...formData, ...allValues });
  };

  const createBenguide = async () => {
    let isFormValid: boolean = true;
    try {
      await form.validateFields(basicRequiredFields);
    } catch (errorInfo) {
      isFormValid = false;
    }
    if (isFormValid) {
      const { employerId } = formData;
      dispatch(changedEmployerIdState(employerId));
      navigate(`/brokers/${brokerId}/employers/${employerId}`);
      form.resetFields(basicRequiredFields);
      setIsModalOpen(false);
    }
  };

  return (
    <FullScreenModal
      visible={isModalOpen}
      onCancel={handleCloseModal}
      footer={false}
      title="Create Benefits Guide"
      forceRender
    >
      <InputForm
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        autoComplete="off"
        onValuesChange={onInputChange}
      >
        <Row justify="center">
          <Col>
            <Row>
              <Col className={styles.locationList}>
                {!isEmpty(employerList) && (
                  <Form.Item
                    name="employerId"
                    label="Choose Employer"
                    rules={[
                      {
                        required: true,
                        message: 'Please select an employer',
                        validateTrigger: ['onSubmit'],
                      },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <Select
                      size="large"
                      showSearch
                      placeholder="Select"
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {employerList.map((employer) => (
                        <Option value={employer.id} key={employer.id}>
                          {employer.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <PageActionButton
              type="primary"
              className={styles.confirmButton}
              onClick={createBenguide}
              htmlType="submit"
            >
              Continue
            </PageActionButton>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <CancelButton htmlType="button" onClick={handleCloseModal} block>
              Cancel
            </CancelButton>
          </Col>
        </Row>
      </InputForm>

      {isConfirmOpen && (
        <ConfirmationDialog
          title="Are you sure you want to close?"
          confirmText="Yes - close and do not save"
          cancelText="Cancel"
          closeModal={() => setIsConfirmOpen(false)}
          onConfirm={handleCloseConfirm}
          visible={isConfirmOpen}
        >
          <p className={styles.warningConfirmation}>{exitWithoutSavingMsg}</p>
        </ConfirmationDialog>
      )}
    </FullScreenModal>
  );
};

export default AddEmployerModal;
