import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse, AxiosError } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';

import { DentalPlan } from 'model/plans/DentalPlan';
import * as PlanService from 'modules/plans/services/PlanService';
import PaginationConfig from 'model/PaginationConfig';
import MetaData from 'model/MetaData';
import CustomPlan from 'model/plans/CustomPlan';
import ErrorDetails from 'model/ErrorDetails';
import ClonePlanDocument from 'model/plans/ClonePlanDocument';
import {
  BenefitCovered,
  ContributionType,
  CostType,
  CUSTOM_SERVICE_CODE_RANGE,
  DEFAULT_SERVICE_CODES,
} from 'modules/plans/constants';
import { BenefitCategory } from 'constants/commonConstants';
import PlanServiceObject from 'model/PlanService';
import { getOrderedDisplayServices } from 'modules/plans/slices/medicalPlanSlice';
import {
  removeBenefitDocumentsFromPlan,
  transformDeductibleForSave,
  transformOrthodontiaMaxForSave,
} from 'modules/plans/utils';
import FundingType from 'modules/plans/enums/FundingType';
import { AIUploaderFeedback } from 'model/plans/UserFeedback';
import { clonePlanDocuments } from './planDocumentsSlice';

export interface DentalPlanServiceState {
  inProgress: boolean;
  error: any;
  dentalPlan: DentalPlan;
  requestType: string;
  dentalPlanList: { content: Array<DentalPlan>; metadata: MetaData };
  dentalPlanServiceList: {
    inProgress: boolean;
    error: any;
    defaults: PlanServiceObject[];
    options: PlanServiceObject[];
    benefitKind: string;
  };
  displayedDentalServices: PlanServiceObject[];
  dentalRates: any;
  editedDentalPlan: DentalPlan;
  dentalDocumentReferences: {};
}

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  dentalPlan: {
    rates: {},
    groups: [] as string[],
    planFeedback: [] as AIUploaderFeedback[],
  } as DentalPlan,
  dentalPlanList: { content: [], metadata: {} },
  dentalPlanServiceList: {
    defaults: [] as PlanServiceObject[],
    options: [],
    inProgress: false,
    benefitKind: '',
    error: null,
  },
  displayedDentalServices: [] as PlanServiceObject[],
  dentalRates: {},
  editedDentalPlan: { rates: {}, groups: [] as string[] } as DentalPlan,
  dentalDocumentReferences: {},
} as DentalPlanServiceState;

const dentalPlanSlice = createSlice({
  name: 'dentalPlan',
  initialState,
  reducers: {
    injectDentalPlanDetails: (
      state,
      { payload }: PayloadAction<Partial<DentalPlan>>
    ) => {
      state.inProgress = false;
      state.dentalPlan = {
        ...state.dentalPlan,
        ...payload,
      };
    },
    createDentalPlan: (state, action: PayloadAction<DentalPlan>) => {
      state.dentalPlan = action.payload;
      state.inProgress = false;
      state.error = null;
      state.requestType = action.type;
    },
    documentUploadStarted: (state, { payload }: PayloadAction<any>) => {
      state.dentalPlan.documentReferences = {
        ...state.dentalPlan.documentReferences,
        [payload.documentType]: {
          uploading: true,
        },
      };
    },
    documentUploadCompleted: (state, { payload }: PayloadAction<any>) => {
      state.dentalPlan.documentReferences = {
        ...state.dentalPlan.documentReferences,
        [payload.documentType]: {
          uploading: false,
          reference: payload.reference,
          blobUrl: payload.blobUrl,
          fileName: payload.fileName,
        },
      };
    },
    documentUploadFailed: (state, { payload }: PayloadAction<any>) => {
      state.dentalPlan.documentReferences = {
        ...state.dentalPlan.documentReferences,
        [payload.documentType]: {
          uploading: false,
          error: JSON.parse(JSON.stringify(payload.error)),
        },
      };
    },
    removeBenefitDocuments: (state) => {
      removeBenefitDocumentsFromPlan(
        state.dentalPlan,
        state.dentalDocumentReferences,
        state.editedDentalPlan
      );
    },
    updateDentalPlanInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    updateDentalPlanCompleted: (state, action: PayloadAction<DentalPlan>) => {
      state.inProgress = false;
      state.dentalPlan = { ...state.dentalPlan, ...action.payload };
      state.editedDentalPlan = { ...state.editedDentalPlan, ...action.payload };
      state.error = null;
      state.requestType = action.type;
    },
    updateDentalPlanByPathCompleted: (
      state,
      { payload, type }: PayloadAction<any>
    ) => {
      set(state.editedDentalPlan, payload.path, payload.value);
      if (payload.isDBGView) {
        set(state.dentalPlan, payload.path, payload.value);
      }
      state.inProgress = false;
      state.error = null;
      state.requestType = type;
    },
    updateDentalPlanFailed: (state, action) => {
      state.inProgress = false;
      state.error = JSON.parse(JSON.stringify(action.payload));
      state.requestType = action.type;
    },
    getDentalPlansListInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
    },
    getDentalPlansListCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.inProgress = false;
      state.error = null;
      state.dentalPlanList = payload;
    },
    getDentalPlansListFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = action.payload;
    },
    updateDentalPlanServiceCompleted: (
      state,
      action: PayloadAction<CustomPlan>
    ) => {
      const { payload } = action;
      const { customServices = [] } = state.dentalPlan;
      const existingServiceIndex = customServices.findIndex(
        (s) =>
          (payload.benefitCode.code &&
            payload.benefitCode.code === s.benefitCode.code) ||
          payload.benefitCode.shortName === s.benefitCode.shortName
      );
      if (existingServiceIndex !== -1) {
        customServices[existingServiceIndex] = payload;
      } else {
        customServices.push(payload);
      }
      state.dentalPlan = {
        ...state.dentalPlan,
        customServices: [...customServices],
      };
      state.editedDentalPlan = {
        ...state.editedDentalPlan,
        customServices: [...customServices],
      };
    },
    resetStateCompleted: (state) => {
      state.dentalPlan = { rates: {}, groups: [] as string[] } as DentalPlan;
      state.editedDentalPlan = {
        rates: {},
        groups: [] as string[],
      } as DentalPlan;
      state.dentalPlanServiceList.defaults = [];
      state.dentalPlanServiceList.options = [];
    },
    getPlanContributionInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
    },
    getPlanContributionCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.dentalPlan = payload;
      state.editedDentalPlan = payload;
      state.dentalRates = payload.rates;
      state.inProgress = false;
      state.requestType = action.type;
    },
    getLatestPlanContributionCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.editedDentalPlan = payload;
      state.dentalRates = payload.rates;
      state.inProgress = false;
      state.requestType = action.type;
    },
    getPlanContributionFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = action.payload;
      state.requestType = action.type;
    },
    getPlanByIdStarted: (state) => {
      state.inProgress = true;
      state.dentalPlan = {} as DentalPlan;
      state.editedDentalPlan = {} as DentalPlan;
      state.error = null;
    },
    getPlanByIdCompleted: (state, action: PayloadAction<DentalPlan>) => {
      state.inProgress = false;
      state.dentalPlan = action.payload;
      state.editedDentalPlan = action.payload;
      state.dentalRates = action.payload.rates;
      state.error = null;
      state.dentalDocumentReferences = action.payload?.documents;
    },
    getPlanByIdFailed: (state, action) => {
      state.inProgress = false;
      state.dentalPlan = {} as DentalPlan;
      state.error = action.payload;
    },
    getCheckRateInGroupsStarted: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.error = null;
    },
    getCheckRateInGroupsCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.dentalPlan = payload;
      state.editedDentalPlan = payload;
      state.error = null;
    },
    getCheckRateInGroupsFailed: (state, action: PayloadAction<any>) => {
      state.inProgress = false;
      state.error = action.payload;
    },
    setDisplayedDentalServicesCompleted: (
      state,
      action: PayloadAction<PlanServiceObject[]>
    ) => {
      const { payload } = action;
      state.displayedDentalServices = getOrderedDisplayServices(payload);
    },
    dentalPlanServiceListInProgress: (state, { payload }) => {
      state.dentalPlanServiceList.inProgress = true;
      state.dentalPlanServiceList.benefitKind = payload;
    },
    dentalPlanServiceListCompleted: (state, { payload }) => {
      state.dentalPlanServiceList.inProgress = false;
      state.dentalPlanServiceList = payload;
    },
    dentalPlanServiceListFailed: (state, { payload }) => {
      state.dentalPlanServiceList.inProgress = false;
      state.dentalPlanServiceList.defaults = [];
      state.dentalPlanServiceList.options = [];
      state.dentalPlanServiceList.error = payload;
    },
    clearDentalPlanApiErrors: (state) => {
      state.error = null;
    },
    setUserAiFeedback: (
      state,
      { payload }: PayloadAction<AIUploaderFeedback>
    ) => {
      if (isEmpty(state.dentalPlan.planFeedback)) {
        state.dentalPlan.planFeedback = [];
      }
      const planFeedback = state.dentalPlan.planFeedback;
      const existingFeedbackIndex =
        planFeedback?.findIndex(
          (feedback) => feedback.section === payload.section
        ) ?? 0;

      if (existingFeedbackIndex !== -1) {
        planFeedback?.splice(existingFeedbackIndex!, 1, payload);
      } else {
        planFeedback?.push(payload);
      }
    },
    removeUserAiFeedback: (state, { payload }: PayloadAction<string>) => {
      if (isEmpty(state.dentalPlan.planFeedback)) {
        state.dentalPlan.planFeedback = [];
      }
      const sectionToRemove = payload;
      state.dentalPlan.planFeedback = state.dentalPlan?.planFeedback?.filter(
        (feedback) => feedback?.section !== sectionToRemove
      );
    },
  },
});

export const {
  injectDentalPlanDetails,
  createDentalPlan,
  documentUploadStarted,
  documentUploadCompleted,
  documentUploadFailed,
  updateDentalPlanInProgress,
  updateDentalPlanCompleted,
  updateDentalPlanByPathCompleted,
  updateDentalPlanFailed,
  getDentalPlansListInProgress,
  getDentalPlansListCompleted,
  getDentalPlansListFailed,
  updateDentalPlanServiceCompleted,
  resetStateCompleted,
  getPlanContributionInProgress,
  getPlanContributionCompleted,
  getLatestPlanContributionCompleted,
  getPlanContributionFailed,
  getPlanByIdStarted,
  getPlanByIdCompleted,
  getPlanByIdFailed,
  getCheckRateInGroupsStarted,
  getCheckRateInGroupsCompleted,
  getCheckRateInGroupsFailed,
  setDisplayedDentalServicesCompleted,
  dentalPlanServiceListInProgress,
  dentalPlanServiceListCompleted,
  dentalPlanServiceListFailed,
  clearDentalPlanApiErrors,
  removeUserAiFeedback,
  setUserAiFeedback,
  removeBenefitDocuments,
} = dentalPlanSlice.actions;

export default dentalPlanSlice.reducer;

export const createDentalPlanBasicInfo =
  (data: DentalPlan) => (dispatch: Dispatch) => {
    dispatch(createDentalPlan(data));
  };
export const uploadDentalTemporaryDocument = (
  file: File,
  documentType: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(documentUploadStarted({ documentType }));
    PlanService.uploadPlanDocuments(file.name, file)
      .then(({ data }: AxiosResponse) => {
        dispatch(
          documentUploadCompleted({
            documentType,
            reference: data,
            fileName: file.name,
            blobUrl: URL.createObjectURL(file),
          })
        );
      })
      .catch((error: AxiosError) => {
        dispatch(documentUploadFailed({ documentType, error: error }));
      });
  };
};

export const saveDentalPlan = (
  _plan: DentalPlan,
  onSave: (isSucess?: boolean, id?: string) => void,
  cloneDocuments?: boolean,
  sourcePlanId?: string
) => {
  return async (dispatch: Dispatch) => {
    const plan = fixedUpDentalPlanObj(_plan);
    try {
      dispatch(updateDentalPlanInProgress());
      let response = {} as any;
      if (plan.id) {
        response = await PlanService.updateDentalPlan(plan);
      } else {
        response = await PlanService.createDentalPlan(plan);
      }
      const data = response.data;
      dispatch(updateDentalPlanCompleted(JSON.parse(JSON.stringify(data))));
      if (data && cloneDocuments && plan.documents && sourcePlanId) {
        const clonePlanDoc: ClonePlanDocument = {
          employerId: data.employerId,
          sourcePlanId: sourcePlanId,
          targetPlanId: data.id,
          benefitCategory: BenefitCategory.DENTAL.value,
          benefitKind: 'DENTAL',
        };
        dispatch(clonePlanDocuments(clonePlanDoc));
      }
      onSave(response?.status === 200, data.id);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            updateDentalPlanFailed({
              data: error.response.data,
            } as ErrorDetails)
          );
        }
      }
    }
  };
};

export const setDisplayedDentalServices = (data: PlanServiceObject[]) => {
  return (dispatch: Dispatch) => {
    dispatch(setDisplayedDentalServicesCompleted(data));
  };
};

export const getDentalPlanServicesList =
  (benefitKind: string, employerId: string): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(dentalPlanServiceListInProgress(benefitKind));
      const response = await PlanService.getPlanServicesList(
        benefitKind,
        employerId
      );
      sortAndStoreBenefitCodes(benefitKind, response.data, dispatch);
    } catch (error) {
      dispatch(dentalPlanServiceListFailed(error));
    }
  };

const sortAndStoreBenefitCodes = (
  benefitKind: string,
  allServiceTypes: PlanServiceObject[],
  dispatch: Dispatch
) => {
  const serviceTypes = DEFAULT_SERVICE_CODES[benefitKind]
    .map((serviceCode) => {
      return (
        allServiceTypes.find(
          (serviceType: PlanServiceObject) => serviceType.code === serviceCode
        ) || ({} as PlanServiceObject)
      );
    })
    .filter((s) => s.shortName);

  const displayedList: PlanServiceObject[] = serviceTypes.filter(
    (s) => s?.isDefault
  );
  dispatch(setDisplayedDentalServicesCompleted([...displayedList]));

  // custom services
  serviceTypes.push(
    ...allServiceTypes.filter(
      (serviceType: PlanServiceObject) =>
        serviceType.code && serviceType.code >= CUSTOM_SERVICE_CODE_RANGE
    )
  );

  const payload = {
    options: allServiceTypes,
    defaults: serviceTypes,
    inProgress: false,
    benefitKind,
    error: null,
  };

  dispatch(dentalPlanServiceListCompleted(payload));
};

export const getDentalPlansList = (
  { page, size, sort, query }: PaginationConfig,
  employerId: string,
  planYearId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getDentalPlansListInProgress());
    try {
      const response = await PlanService.getDentalPlansList(
        page,
        size,
        sort,
        query,
        employerId,
        planYearId
      );
      dispatch(getDentalPlansListCompleted(response?.data));
    } catch (error) {
      dispatch(getDentalPlansListFailed(error));
    }
  };
};
export const updateDentalPlanService = (data: CustomPlan) => {
  return (dispatch: Dispatch) => {
    dispatch(
      updateDentalPlanServiceCompleted(JSON.parse(JSON.stringify(data)))
    );
  };
};
export const resetDentalPlanReduxStore = () => {
  return (dispatch: Dispatch) => {
    dispatch(resetStateCompleted());
  };
};
let abortController: AbortController;
let changedTier: string;
export const getPlanContributionByFrequency = (
  contributions: any,
  frequency: string,
  benefitGroup: string,
  rateTier: string,
  currentTier: string,
  isEdit?: boolean
) => {
  return async (dispatch: Dispatch) => {
    if (abortController && changedTier === rateTier) {
      abortController.abort(); // Tell the browser to abort request
    }
    if (typeof 'AbortController' !== 'undefined') {
      abortController = new AbortController();
    }
    changedTier = rateTier;
    try {
      dispatch(getPlanContributionInProgress());
      const response = await PlanService.calculateDentalPlanContributions(
        frequency,
        contributions,
        encodeURIComponent(benefitGroup),
        rateTier,
        currentTier,
        abortController.signal
      );
      const data = response.data;
      if (isEdit) {
        dispatch(
          getLatestPlanContributionCompleted(JSON.parse(JSON.stringify(data)))
        );
      } else {
        dispatch(
          getPlanContributionCompleted(JSON.parse(JSON.stringify(data)))
        );
      }
    } catch (error: any) {
      if (error.name === 'AbortError') {
        return; // Aborting request will return an error we don't want to handle in redux
      }
      dispatch(getPlanContributionFailed(error));
    }
  };
};

export const updateDentalPlan = (plan: DentalPlan) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateDentalPlanCompleted(JSON.parse(JSON.stringify(plan))));
  };
};

export const updateDentalPlanByPath = (
  path: string[],
  value: any,
  isDBGView: boolean = false
) => {
  return async (dispatch: Dispatch) => {
    dispatch(
      updateDentalPlanByPathCompleted(
        JSON.parse(JSON.stringify({ path, value, isDBGView }))
      )
    );
  };
};

export const findDentalPlanById = (planId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPlanByIdStarted());
    try {
      const { data } = await PlanService.getDentalPlanById(planId);

      // Set SFC data
      data.administrationFee = data?.sfcPlanConfigVo?.administrationFee;

      dispatch(getPlanByIdCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(getPlanByIdFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
};

export const findCheckRateInGroups = (
  planId: string,
  revision: number,
  groups: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getCheckRateInGroupsStarted());
    try {
      const response = await PlanService.getDentalCheckRateInGroups(
        planId,
        revision,
        groups
      );
      dispatch(getCheckRateInGroupsCompleted(response.data));
    } catch (error) {
      dispatch(getCheckRateInGroupsFailed(error));
    }
  };
};

export const fixedUpDentalPlanObj = (plan: DentalPlan) => {
  const copyObject: DentalPlan = cloneDeep(plan);
  copyObject.name = plan.name?.trim();
  copyObject.groupId = plan.groupId?.trim();

  if (copyObject.rates) {
    Object.values(copyObject.rates)?.forEach((rate: any) => {
      if (!rate.contributionType) {
        rate.contributionType = ContributionType.FIXED.value;
      } else {
        return;
      }
    });
  }

  copyObject.customServices = copyObject.customServices?.map((service) => {
    if (get(service, 'serviceValue.inNetwork')) {
      if (
        get(service, 'serviceValue.inNetwork.costSharingPolicy') ===
        BenefitCovered.CLEAR
      ) {
        set(
          service,
          'serviceValue.inNetwork.benefitCovered',
          BenefitCovered.CLEAR
        );
      }

      switch (get(service, 'serviceValue.inNetwork.costSharingPolicy')) {
        case CostType.OTHER:
          set(
            service,
            'serviceValue.inNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(get(service, 'serviceValue.inNetwork.info')) &&
            set(service, 'serviceValue.inNetwork', {});
          break;

        case CostType.COPAY: {
          set(
            service,
            'serviceValue.inNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(get(service, 'serviceValue.inNetwork.copay')?.toString()) &&
            set(service, 'serviceValue.inNetwork', {});
          break;
        }

        case CostType.COINSURANCE: {
          set(
            service,
            'serviceValue.inNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(
            get(service, 'serviceValue.inNetwork.coinsurance')?.toString()
          ) && set(service, 'serviceValue.inNetwork', {});
          break;
        }

        case CostType.NOT_COVERED: {
          set(
            service,
            'serviceValue.inNetwork.benefitCovered',
            BenefitCovered.NO
          );
          set(service, 'serviceValue.inNetwork.costSharingPolicy', null);
          break;
        }
      }
    }

    if (get(service, 'serviceValue.outOfNetwork')) {
      if (
        get(service, 'serviceValue.outOfNetwork.costSharingPolicy') ===
        BenefitCovered.CLEAR
      ) {
        set(
          service,
          'serviceValue.outOfNetwork.benefitCovered',
          BenefitCovered.CLEAR
        );
      }

      switch (get(service, 'serviceValue.outOfNetwork.costSharingPolicy')) {
        case CostType.OTHER:
          set(
            service,
            'serviceValue.outOfNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(get(service, 'serviceValue.outOfNetwork.info')) &&
            set(service, 'serviceValue.outOfNetwork', {});
          break;

        case CostType.COPAY:
          set(
            service,
            'serviceValue.outOfNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(
            get(service, 'serviceValue.outOfNetwork.copay')?.toString()
          ) && set(service, 'serviceValue.outOfNetwork', {});
          break;

        case CostType.COINSURANCE:
          set(
            service,
            'serviceValue.outOfNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(
            get(service, 'serviceValue.outOfNetwork.coinsurance')?.toString()
          ) && set(service, 'serviceValue.outOfNetwork', {});
          break;

        case CostType.NOT_COVERED: {
          set(
            service,
            'serviceValue.outOfNetwork.benefitCovered',
            BenefitCovered.NO
          );
          set(service, 'serviceValue.outOfNetwork.costSharingPolicy', null);
          break;
        }
      }
    }
    return service;
  });

  transformDeductibleForSave(copyObject);
  transformOrthodontiaMaxForSave(copyObject);

  if (copyObject.fundingType === FundingType.SELF_FUNDED) {
    copyObject.sfcPlanConfigVo = {
      employerId: plan.employerId,
      planYearId: plan.planYearId,
      carrierId: plan.benefitCarrier?.id,
      benefitKind: BenefitCategory.DENTAL.value,
      administrationFee:
        copyObject?.administrationFee === '' ||
        copyObject?.administrationFee === undefined ||
        copyObject?.administrationFee === null
          ? plan?.sfcPlanConfigVo?.administrationFee === ''
            ? null
            : plan?.sfcPlanConfigVo?.administrationFee
          : copyObject?.administrationFee,
    };
    copyObject.sfcPlanDataVO = {
      selfFundedClaimsStatus: true,
    };
  } else {
    copyObject.sfcPlanDataVO = {
      selfFundedClaimsStatus: false,
    };
  }
  return copyObject;
};

export const validateDentalPlanName = (paylaod: any) => {
  return async (dispatch: Dispatch) => {
    try {
      await PlanService.validatePlanName(paylaod, 'dentals');
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            updateDentalPlanFailed({
              data: error.response.data,
            } as ErrorDetails)
          );
          return false;
        }
      }
    }
  };
};
