import React, { FC, useEffect } from 'react';
import {
  useGetBrokerFeatureStatusQuery,
  useGetEmployerFeatureStatusQuery,
} from 'api/featureControl';
import EmployerFeatureDisabled from './EmployerFeatureDisabled';

type EmployerFeatureDisabledBannerProps = {
  feature: string;
  featureName: string;
  employerId?: string;
  brokerId?: string;
};

const EmployerFeatureDisabledBanner: FC<EmployerFeatureDisabledBannerProps> = (
  props
) => {
  const { feature, featureName, employerId, brokerId } = props;

  const { data: employerFeatureStatus, refetch: refetchEmployerFeatureStatus } =
    useGetEmployerFeatureStatusQuery({
      employerId: employerId,
      key: feature,
    });

  const { data: brokerFeatureStatus, refetch: refetchBrokerFeatureStatus } =
    useGetBrokerFeatureStatusQuery(
      {
        organizationId: brokerId,
        key: feature,
      },
      { skip: !brokerId }
    );

  useEffect(() => {
    refetchEmployerFeatureStatus();
  }, [refetchEmployerFeatureStatus]);

  useEffect(() => {
    refetchBrokerFeatureStatus();
  }, [refetchBrokerFeatureStatus]);

  const { enabled, employerVisibilityEnabled } = employerFeatureStatus || {};

  const isDisabled = employerFeatureStatus && !enabled;
  const isInvisible =
    employerFeatureStatus && enabled && !employerVisibilityEnabled;

  const isDisabledToBroker =
    brokerFeatureStatus && !brokerFeatureStatus.enabled;

  return (
    <>
      {(isDisabled || isInvisible || isDisabledToBroker) && (
        <EmployerFeatureDisabled
          feature={feature}
          featureName={featureName}
          isInvisible={isInvisible}
        />
      )}
    </>
  );
};

export default EmployerFeatureDisabledBanner;
