import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import * as BenguideService from 'modules/employers/services/BenguideService';
import ErrorDetails from 'model/ErrorDetails';
import BenguideBasicData from 'model/BenguideBasicData';
import PlanYearBenefitGuideInfo from 'model/PlanYearBenefitGuideInfo';
import BenefitGuide from 'model/BenefitGuide';
import PasswordAssociation from 'model/PasswordAssociation';
import { changedBenguideModalState } from 'modules/benefitGuide/slices/benguideSlice';
import EmployerName from 'model/EmployerName';

export interface BenguideState {
  inProgress: boolean;
  error: any;
  requestType: string;
  benguideAvailable: boolean;
  benguideAvailableInProgress: boolean;
  benguideAvailableFoundError: ErrorDetails | null;
  benguideAvailableFoundSuccess: boolean;
  benguideList: Array<BenguideBasicData>;
  benguideListInProgress: boolean;
  benguideListSuccess: boolean;
  benguideListError: ErrorDetails | null;
  planYearBenefitGuideInfoList: Array<PlanYearBenefitGuideInfo>;
  benefitGuidePlanList: BenefitGuide;
  benefitGuideObj: BenguideBasicData;
  benguidePublishInprogress: boolean;
  benguidePublishSuccess: boolean;
  benguidePublishError: ErrorDetails | null;
  benguideUpdatedStarted: boolean;
  benguideUpdatedError: ErrorDetails | null;
  benguideArchivedInprogress: boolean;
  benguideArchivedError: ErrorDetails | null;
  benguideArchived: boolean;
}

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  benguideAvailable: true,
  benguideAvailableInProgress: false,
  benguideAvailableFoundError: null,
  benguideAvailableFoundSuccess: true,
  benguideList: [],
  benguideListInProgress: false,
  benguideListSuccess: false,
  benguideListError: null,
  planYearBenefitGuideInfoList: [] as any,
  benefitGuidePlanList: {} as BenefitGuide,
  benefitGuideObj: {} as BenguideBasicData,
  benguidePublishInprogress: false,
  benguidePublishError: null,
  benguidePublishSuccess: false,
  benguideUpdatedStarted: false,
  benguideUpdatedError: null,
  benguideArchivedInprogress: false,
  benguideArchivedError: null,
  benguideArchived: false,
} as BenguideState;

const benguideSlice = createSlice({
  name: 'benguideSlice',
  initialState,
  reducers: {
    checkBenguideAvailabilityStarted: (state: {
      benguideAvailableInProgress: boolean;
    }) => {
      state.benguideAvailableInProgress = true;
    },
    checkBenguideAvailabilitySuccess: (
      state: {
        benguideAvailableInProgress: boolean;
        benguideAvailable: boolean;
      },
      { payload }: any
    ) => {
      state.benguideAvailableInProgress = false;
      state.benguideAvailable = payload;
    },
    checkBenguideAvailabilityFailed: (state, { payload }: any) => {
      state.benguideAvailableInProgress = false;
      state.benguideAvailableFoundError = payload;
    },
    findBenguideListStarted: (state, action: PayloadAction) => {
      state.benguideListInProgress = true;
      state.benguideList = [];
      state.benguideListSuccess = false;
    },
    findBenguideListSuccess: (
      state,
      action: PayloadAction<Array<BenguideBasicData>>
    ) => {
      state.benguideListInProgress = false;
      state.benguideList = action.payload;
      state.benguideListSuccess = true;
      state.benguidePublishSuccess = false;
    },
    resetBenguideList: (state) => {
      state.benguideListInProgress = false;
      state.benguideList = [];
      state.benguideListSuccess = false;
      state.benguidePublishSuccess = false;
    },
    findBenguideListError: (state, { payload }) => {
      state.benguideListInProgress = false;
      state.benguideListError = payload;
      state.benguideListSuccess = false;
    },
    getPlanYearBenefitGuideInfoInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.planYearBenefitGuideInfoList = [];
      state.error = null;
      state.requestType = action.type;
    },
    getPlanYearBenefitGuideInfoCompleted: (
      state,
      action: PayloadAction<Array<PlanYearBenefitGuideInfo>>
    ) => {
      state.inProgress = false;
      state.planYearBenefitGuideInfoList = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getPlanYearBenefitGuideInfoFailed: (state, action) => {
      state.inProgress = false;
      state.planYearBenefitGuideInfoList = [];
      state.error = { response: action.payload };
      state.requestType = action.type;
    },
    getBenefitGuidePlanListInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.benefitGuidePlanList = {} as BenefitGuide;
      state.error = null;
      state.requestType = action.type;
    },
    getBenefitGuidePlanListCompleted: (
      state,
      action: PayloadAction<BenefitGuide>
    ) => {
      state.inProgress = false;
      state.benefitGuidePlanList = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getBenefitGuidePlanListFailed: (state, action) => {
      state.inProgress = false;
      state.benefitGuidePlanList = {} as BenefitGuide;
      state.error = { response: action.payload };
      state.requestType = action.type;
    },
    resetBenefitGuidePlanList: (state) => {
      state.benefitGuidePlanList = {} as BenefitGuide;
      state.error = null;
    },
    setBenefitGuideObject: (state, action) => {
      state.benefitGuideObj = action.payload;
    },
    publishBenguideStarted: (state) => {
      state.benguidePublishInprogress = true;
      state.benguidePublishSuccess = false;
      state.benguidePublishError = null;
    },
    publishBenguideSuccess: (state) => {
      state.benguidePublishInprogress = false;
      state.benguidePublishSuccess = true;
      state.benguidePublishError = null;
    },
    publishBenguideError: (state, action) => {
      state.benguidePublishInprogress = false;
      state.benguidePublishSuccess = false;
      state.benguidePublishError = action.payload;
    },
    benguideUpdatedStarted: (state) => {
      state.benguideUpdatedStarted = true;
      state.benguideUpdatedError = null;
    },
    benguideUpdatedSuccess: (state) => {
      state.benguideUpdatedStarted = false;
      state.benguideUpdatedError = null;
    },
    benguideUpdatedError: (state, action) => {
      state.benguideUpdatedStarted = false;
      state.benguideUpdatedError = action.payload;
    },
    benguideArchivedStarted: (state) => {
      state.benguideArchivedInprogress = true;
      state.benguideArchived = false;
      state.benguideArchivedError = null;
    },
    benguideArchivedSuccess: (state, action) => {
      state.benguideArchivedInprogress = false;
      state.benguideArchived = action.payload;
      state.benguideArchivedError = null;
    },
    benguideArchivedError: (state, action) => {
      state.benguideArchivedInprogress = false;
      state.benguideArchived = false;
      state.benguideArchivedError = action.payload;
    },
    clearPublishBenguideApiErrors: (state) => {
      state.benguidePublishError = null;
    },
  },
});

export const {
  checkBenguideAvailabilityStarted,
  checkBenguideAvailabilitySuccess,
  checkBenguideAvailabilityFailed,
  findBenguideListStarted,
  findBenguideListSuccess,
  findBenguideListError,
  getPlanYearBenefitGuideInfoInProgress,
  getPlanYearBenefitGuideInfoCompleted,
  getPlanYearBenefitGuideInfoFailed,
  getBenefitGuidePlanListInProgress,
  getBenefitGuidePlanListCompleted,
  getBenefitGuidePlanListFailed,
  resetBenefitGuidePlanList,
  publishBenguideStarted,
  publishBenguideSuccess,
  publishBenguideError,
  benguideUpdatedStarted,
  benguideUpdatedSuccess,
  benguideUpdatedError,
  setBenefitGuideObject,
  benguideArchivedStarted,
  benguideArchivedSuccess,
  benguideArchivedError,
  resetBenguideList,
  clearPublishBenguideApiErrors,
} = benguideSlice.actions;

export default benguideSlice.reducer;

export const isBenguideAssignedForPlanYear =
  (planYearId: string, employerId: string) => async (dispatch: Dispatch) => {
    dispatch(checkBenguideAvailabilityStarted);
    try {
      const response = await BenguideService.isBenguideAssignedForPlanYear(
        planYearId,
        employerId
      );
      dispatch(checkBenguideAvailabilitySuccess(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          checkBenguideAvailabilityFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };

export const getBenguidesByEmployerIdAndPlanYearId =
  (planYearId: string, employerId: string): any =>
  async (dispatch: Dispatch) => {
    dispatch(findBenguideListStarted());
    try {
      const response =
        await BenguideService.findAllBenguidesByEmployerIdAndPlanYear(
          planYearId,
          employerId
        );
      dispatch(findBenguideListSuccess(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          findBenguideListError(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };

export const getPlanYearBenefitGuideInfo = (employerId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPlanYearBenefitGuideInfoInProgress());
    try {
      const response = await BenguideService.getPlanYearBenefitGuideInfo(
        employerId
      );
      const data = response.data;
      dispatch(getPlanYearBenefitGuideInfoCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getPlanYearBenefitGuideInfoFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const getBenefitGuidePlanList = (benefitGuideId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getBenefitGuidePlanListInProgress());
    try {
      const response = await BenguideService.getBenefitGuidePlanList(
        benefitGuideId
      );
      const data = response.data;
      dispatch(getBenefitGuidePlanListCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getBenefitGuidePlanListFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const getBenefitGuideByHashAndRevision = (
  hash: string,
  revision: number
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getBenefitGuidePlanListInProgress());
    try {
      const response = await BenguideService.getBenefitGuideByHashAndRevision(
        hash,
        revision
      );
      const data = response.data;
      dispatch(getBenefitGuidePlanListCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getBenefitGuidePlanListFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const clearBenguidePlanList = () => (dispatch: Dispatch) => {
  dispatch(resetBenefitGuidePlanList());
};

export const setDBGObject = (dbg: BenguideBasicData) => {
  return (dispatch: Dispatch) => {
    dispatch(setBenefitGuideObject(dbg));
  };
};
export const clearDBGObject = () => {
  return (dispatch: Dispatch) => {
    dispatch(setBenefitGuideObject({}));
  };
};

export const publishBenguide = (
  masterId: string,
  password: PasswordAssociation,
  onCancel: Function,
  isPublishedBenguide: boolean | undefined,
  publishRevision: number | undefined,
  employerName: EmployerName
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(benguideUpdatedStarted());
      await BenguideService.savePassword(masterId, password);
      await BenguideService.updateEmployerName(masterId, employerName);
      dispatch(benguideUpdatedSuccess());
      try {
        dispatch(publishBenguideStarted());
        await BenguideService.publish(masterId);

        dispatch(publishBenguideSuccess());
        onCancel();
        dispatch(changedBenguideModalState(false));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            dispatch(
              publishBenguideError({
                data: error.response.data,
              } as ErrorDetails)
            );
          }
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            benguideUpdatedError({
              data: error.response.data,
            } as ErrorDetails)
          );
        }
      }
    }
  };
};

export const archiveBenguide = (
  benguideId: string,
  planYearId: string,
  employerId: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(benguideArchivedStarted());

      await BenguideService.archive(benguideId);
      dispatch(benguideArchivedSuccess(true));
      dispatch(getBenguidesByEmployerIdAndPlanYearId(planYearId, employerId));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          benguideArchivedError(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};
