import { FC } from 'react';
import { Modal } from 'antd';
import get from 'lodash/get';

import EditVoluntaryBenefitsPlan from 'modules/plans/voluntary/components/EditVoluntaryBenefitsPlan/EditVoluntaryBenefitsPlan';

import { BenefitKind } from 'constants/commonConstants';

import IconInfo from 'assets/images/icon-info.svg';

import styles from './editVoluntaryBenefitPlanUPWrapper.module.less';

type EditVoluntaryBenefitPlanUPWrapperProps = {
  plan: any;
  onClose: any;
  isModalVisible: boolean;
};
const EditVoluntaryBenefitPlanUPWrapper: FC<
  EditVoluntaryBenefitPlanUPWrapperProps
> = ({
  plan,
  onClose,
  isModalVisible,
}: EditVoluntaryBenefitPlanUPWrapperProps) => {
  return (
    <Modal
      width={600}
      visible={isModalVisible}
      okText="Done"
      footer={false}
      className={styles.editVoluntaryBenefitPlanChannelModal}
      closable={false}
    >
      <div className={styles.editVoluntaryBenefitPlanChannelWrapper}>
        <div className={styles.title}>{`Edit ${
          get(BenefitKind, plan?.benefitKind || '', '').label
        } Plan`}</div>
        <div className={styles.leftBorder}></div>
        <div className={styles.editVoluntaryBenefitPlanChannel}>
          <div>
            <img src={IconInfo} alt="Icon Info" />
          </div>
          <div className={styles.defaultText}>
            Changing plan information here will change the plan information
            everywhere this plan is shown
          </div>
        </div>
        <EditVoluntaryBenefitsPlan
          plan={plan}
          onClose={onClose}
          isModalVisible={isModalVisible}
        />
      </div>
    </Modal>
  );
};

export default EditVoluntaryBenefitPlanUPWrapper;
