import { FC, MutableRefObject, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import MedicalPlan from 'model/plans/MedicalPlan';
import Services from 'modules/plans/components/Services/Services';
import { getPlanServicesList } from 'modules/plans/slices/medicalPlanSlice';
import { BenefitCategory } from 'constants/commonConstants';
import { useNavContext } from 'hooks/useNavContext';

import AddCustomServiceModal from 'modules/plans/components/AddCustomServiceModal/AddCustomServiceModal';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import PanelSection from 'modules/plans/enums/PanelSection';
import iconAdd from 'assets/images/icon-add.svg';
import PlanServiceObject from 'model/PlanService';
import CustomPlan from 'model/plans/CustomPlan';

import styles from './services.module.less';

type ServiceUPWrapperProps = {
  type: string;
  wrappedRef?: MutableRefObject<any>;
  medicalPlan: MedicalPlan;
  setMedicalPlan: Function;
  isReviewProp?: boolean;
};

const ServiceUPWrapper: FC<ServiceUPWrapperProps> = (
  props: ServiceUPWrapperProps
) => {
  const { type, medicalPlan, setMedicalPlan, isReviewProp } = props;
  const { employerId } = useNavContext();
  const dispatch = useAppDispatch();
  const [isAddCustomServiceShow, setAddCustomServiceShow] =
    useState<boolean>(false);

  const plan = useAppSelector((state) => state.plan.plans);
  const { displayedServices: _displayedServices } = plan;

  const displayedServices = _displayedServices as PlanServiceObject[];

  useEffect(() => {
    dispatch(
      getPlanServicesList(BenefitCategory.MEDICAL.value, employerId || '')
    );
  }, [dispatch, employerId]);

  const addCustomService = (customServiceCopy: CustomPlan) => {
    let { customServices = [] } = medicalPlan;
    const existingServiceIndex = customServices.findIndex(
      (s) =>
        (customServiceCopy.benefitCode.code &&
          customServiceCopy.benefitCode.code === s.benefitCode.code) ||
        customServiceCopy.benefitCode.shortName === s.benefitCode.shortName
    );
    if (existingServiceIndex !== -1) {
      customServices[existingServiceIndex] = customServiceCopy;
    } else {
      customServices = [...customServices, customServiceCopy];
    }
    const copy = [...customServices];
    setMedicalPlan({ ...medicalPlan, customServices: copy });
  };

  return (
    <>
      <div className={styles.serviceDBGWrapper}>
        <Services
          formType={type}
          onChange={setMedicalPlan}
          isDBGView={true}
          isTouched={false}
          currentPlan={medicalPlan}
          isReviewProp={isReviewProp}
        />
        <AddCustomServiceModal
          visible={isAddCustomServiceShow}
          displayedServices={displayedServices}
          onClose={() => setAddCustomServiceShow(false)}
          benefitKind={BenefitCategory.MEDICAL.value}
          planTypeName={BenefitCategory.MEDICAL.label}
          hsaCompatible={medicalPlan?.hsaCompatible}
          onPlanChange={addCustomService}
          addedCustomServices={medicalPlan?.customServices}
        />
      </div>
      {PanelSection.SERVICES === type && (
        <div className={styles.addNewServiceBtn}>
          <LinkButton
            onClick={() => {
              setAddCustomServiceShow(true);
            }}
          >
            <img src={iconAdd} alt="add-icon" className={styles.iconStyle} />
            Add Service
          </LinkButton>
        </div>
      )}
    </>
  );
};

export default ServiceUPWrapper;
