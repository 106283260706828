import { FC } from 'react';
import { Col, Row } from 'antd';

import styles from './planInfoHeaderLabel.module.less';

type PlanInfoHeaderLabelPropTypes = {
  labelHeaderText: string[];
  mainLabelHeaderText?: string;
};

const PlanInfoHeaderLabel: FC<PlanInfoHeaderLabelPropTypes> = (
  props: PlanInfoHeaderLabelPropTypes
) => {
  const { labelHeaderText, mainLabelHeaderText } = props;

  return (
    <Row className={styles.infoLabelText} gutter={48}>
      <Col span={8} className={`header-label-text`}>
        <div>{mainLabelHeaderText}</div>
      </Col>
      {labelHeaderText.map((text, key) => {
        return (
          <Col span={6} key={key} className={`header-label-col-text`}>
            <div>{text}</div>
          </Col>
        );
      })}
    </Row>
  );
};

export default PlanInfoHeaderLabel;
