import { FC } from 'react';
import { Col, Row } from 'antd';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import styles from './CarrierContact.module.less';

type CarrierContactProps = {
  contactName: string;
  avatarUrl?: string;
  carrier?: string;
  email: string;
  title?: string;
  contactNumber?: string;
};

const CarrierContact: FC<CarrierContactProps> = (props) => {
  const { contactName, avatarUrl, email, title, carrier, contactNumber } =
    props;
  const emailApplication = `mailto:${email}`;

  return (
    <div className={styles.contact}>
      <Row className={styles.rowWrapper}>
        <Col xs="2">
          <ProfileAvatar content={contactName} src={avatarUrl} />
        </Col>
        <Col xs="6" className={styles.nameColStyle}>
          <Row className={styles.nameWrapper}>
            <OverflowPopover maxWidth="200px">{contactName}</OverflowPopover>
          </Row>
          <Row className={styles.titleWrapper}>
            <OverflowPopover maxWidth="200px">{carrier}</OverflowPopover>
          </Row>
          <Row className={styles.titleWrapper}>
            <OverflowPopover maxWidth="200px">{title}</OverflowPopover>
          </Row>
          <Row>
            <a
              className={styles.emailWrapper}
              title={email}
              href={emailApplication}
            >
              {email.toLocaleLowerCase()}
            </a>
          </Row>
          <Row>
            <a className={styles.contactNumberWrapper}>{contactNumber}</a>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CarrierContact;
