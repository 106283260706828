import React, { forwardRef, useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { get, isEmpty } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import {
  convertToOptions,
  convertToWebLinkType,
  getValidationMessage,
  INVALID_WEBLINK,
  isValidWeblink,
} from 'util/commonUtil';
import PlanResources from 'modules/plans/components/PlanResources/PlanResources';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { ReactComponent as IconAddDocument } from 'assets/images/icon-add.svg';
import { ReactComponent as IconDisabledDocument } from 'assets/images/icon-add-disabled.svg';
import { ReactComponent as IconRemoveDocument } from 'assets/images/icon-remove-red.svg';
import { Plan } from 'model/plans/Plan';
import Option from 'model/Option';
import FileType from 'model/plans/FileType';
import WebLinkType from 'model/plans/WebLinkType';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import {
  FILE,
  NO_NAME,
  PLAN_ADDITIONAL_DOCUMENT,
  WEBLINK,
} from 'modules/plans/constants';
import {
  getDocumentNameWithHighestNumber,
  incrementNumberInFileName,
} from 'util/fileUtil';
import styles from './AdditionalPlanResource.module.less';

type AdditionalPlanResourcesProps = {
  plan?: Plan;
  benefitKind?: string;
  isCloseConfirmed: boolean;
  selectedFileList: FileType[];
  setSelectedFileList: Function;
  selectedWebLinkList: WebLinkType[];
  setSelectedWebLinkList: Function;
  setIsDocRemoved: Function;
  actionTriggered?: Function;
};

const formInitialValues = {
  planDocumentName: '',
  benefitCarrier: '',
  planYear: '',
  benefitClass: [],
  weblink: '',
  fileName: '',
  groupId: '',
};

export type InvalidField = 'documentInvalid' | 'webLinkInvalid' | '';

const AdditionalPlanResources = forwardRef(
  (props: AdditionalPlanResourcesProps, ref) => {
    const {
      plan,
      benefitKind,
      isCloseConfirmed,
      selectedFileList,
      setSelectedFileList,
      selectedWebLinkList,
      setSelectedWebLinkList,
      setIsDocRemoved,
      actionTriggered,
    } = props;

    const [isAddNewDocumentFormOpened, setIsAddNewDocumentOpened] =
      useState<boolean>(false);
    const [
      temporaryAdditionalPlanDocuments,
      setTemporaryAdditionalPlanDocuments,
    ] = useState<Option[]>([]);
    const [additionalPlanDocumentTypes, setAdditionalPlanDocumentTypes] =
      useState<Option[]>([]);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [file, setFile] = useState<any | null>(null);
    const [docs, setDocs] = useState<{
      [key: string]: string;
    }>({});
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(formInitialValues);
    const [additionalResourceType, setAdditionalResourceType] =
      useState<string>('');
    const [alertMessage, setAlertMessage] = useState<AlertInfo>({
      type: undefined,
      message: '',
    });
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [invalidField, setInvalidField] = useState<InvalidField>('');
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [onEdit, setOnEdit] = useState<string>();
    const [editType, setEditType] = useState<string>();
    const [planDocumentNameList, setPlanDocumentNameList] = useState<string[]>(
      []
    );
    const [weblinkNameList, setWeblinkNameList] = useState<string[]>([]);
    const [saveDisable, setSaveDisable] = useState<boolean>(false);

    useEffect(() => {
      if (isEmpty(selectedFileList)) {
        setTemporaryAdditionalPlanDocuments([]);
      }
      if (plan) {
        setTemporaryAdditionalPlanDocuments([]);
        setDocs({
          ...plan.documents,
          ...plan.additionalDocuments,
        });
        if (!isEmpty(plan.additionalDocuments)) {
          const convertedList: Option[] = convertToOptions(
            plan.additionalDocuments
          );
          setAdditionalPlanDocumentTypes(convertedList);
          setPlanDocumentNameList(
            convertedList.map((document) => document.value)
          );
        }
        if (actionTriggered && plan.additionalDocumentReferences)
          setAdditionalPlanDocumentTypes(
            convertToOptions(plan.additionalDocumentReferences)
          );
        if (plan.additionalWeblinks) {
          const convertedList = convertToWebLinkType(plan.additionalWeblinks);
          setSelectedWebLinkList(convertedList);
          setWeblinkNameList(
            convertedList.map((weblink) => weblink.planDocumentName)
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plan, selectedFileList]);
    const onFileRemove = () => {
      setFile(null);
      setIsRemoved(true);
      setFormData({ ...formData, fileName: '' });
    };

    const onFileChange = (value: File) => {
      setIsRemoved(false);
      if (value) {
        const planDocumentName = form.getFieldValue('planDocumentName');
        setFile(value);
        setDocs({ ...docs, [planDocumentName]: value.name });
      }
    };

    const onValidateFails = (validateSetting: string) => {
      setAlertMessage({
        type: 'error',
        message: getValidationMessage(validateSetting),
      });
      setShowAlert(true);
    };

    const onCancel = () => {
      setIsAddNewDocumentOpened(false);
      form.resetFields();
      onFileRemove();
      setIsEdit(false);
      setOnEdit(undefined);
    };

    const updateResourceName = (
      nameList: string[],
      planDocumentName: string
    ): string => {
      const selectedListExist = nameList.filter((file) =>
        file.startsWith(planDocumentName)
      );
      if (!selectedListExist.includes(planDocumentName)) {
        return planDocumentName;
      } else if (selectedListExist.length > 0) {
        const highest = getDocumentNameWithHighestNumber(
          selectedListExist,
          planDocumentName
        );
        return incrementNumberInFileName(highest);
      }
      return planDocumentName;
    };

    const removeDocFromList = (editedResource: string) => {
      const existingFile = selectedFileList.find(
        (file) => file.planDocumentName === editedResource
      );
      if (existingFile) {
        setSelectedFileList((prevSelectedFileList: any) => {
          const updatedFileList = prevSelectedFileList.filter(
            (file: any) => file.planDocumentName !== editedResource
          );

          return updatedFileList;
        });

        setTemporaryAdditionalPlanDocuments((prevTempDocs) => {
          return prevTempDocs.filter(
            (additionalDoc) => additionalDoc.value !== editedResource
          );
        });
      } else {
        setAdditionalPlanDocumentTypes(
          additionalPlanDocumentTypes.filter(
            (additionalDoc) => additionalDoc.value !== editedResource
          )
        );
        onRemove(editedResource);
      }
    };

    const onSave = async () => {
      let planDocumentName = form.getFieldValue('planDocumentName')
        ? form.getFieldValue('planDocumentName')
        : NO_NAME;
      const weblink = form.getFieldValue('weblink');
      let weblinkList = cloneDeep(selectedWebLinkList);
      let updatedFileList: any[] = selectedFileList;
      if (isEdit && onEdit) {
        if (editType === FILE && weblink) {
          if (actionTriggered) {
            await actionTriggered('DELETE', true, onEdit, null, null);
          }
          removeDocFromList(onEdit);
        } else if (editType === WEBLINK && file) {
          if (actionTriggered) {
            await actionTriggered('DELETE', false, onEdit, null, null);
          }
          setSelectedWebLinkList(
            selectedWebLinkList.filter(
              (weblink) => weblink.planDocumentName !== onEdit
            )
          );
        }
        if (file) {
          const existingFile = selectedFileList.find(
            (file) => file.planDocumentName === onEdit
          );
          if (existingFile) {
            updatedFileList = selectedFileList.filter(
              (file: any) => file.planDocumentName !== onEdit
            );
            if (actionTriggered) {
              // is AFP Flow
              await actionTriggered('DELETE', true, onEdit, null, null);
            } else {
              const filteredList = temporaryAdditionalPlanDocuments.filter(
                (additionalDoc) => additionalDoc.value !== onEdit
              );
              setTemporaryAdditionalPlanDocuments(filteredList);
            }
          } else {
            const filteredList = additionalPlanDocumentTypes.filter(
              (additionalDoc) => additionalDoc.value !== onEdit
            );
            setAdditionalPlanDocumentTypes(filteredList);
            onRemove(onEdit);
          }
        } else if (weblink) {
          setSelectedWebLinkList(
            selectedWebLinkList.filter(
              (weblink) => weblink.planDocumentName !== onEdit
            )
          );
          if (actionTriggered) {
            actionTriggered('DELETE', false, onEdit, null, null);
          }
          weblinkList = selectedWebLinkList.filter(
            (weblink) => weblink.planDocumentName !== onEdit
          );
        }
        const filteredList = planDocumentNameList.filter(
          (name) => name !== onEdit
        );
        setPlanDocumentNameList(filteredList);
        planDocumentName = updateResourceName(filteredList, planDocumentName);
        setIsEdit(false);
        setOnEdit(undefined);
        setEditType(undefined);
      } else {
        planDocumentName = updateResourceName(
          planDocumentNameList,
          planDocumentName
        );
      }

      if (file) {
        updatedFileList.push({
          file: file,
          docType: PLAN_ADDITIONAL_DOCUMENT,
          planDocumentName: planDocumentName,
        });
        setSelectedFileList(updatedFileList);

        setPlanDocumentNameList([...planDocumentNameList, planDocumentName]);
        setDocs({ ...docs, [planDocumentName]: file.name });

        const tempDocListIndex = temporaryAdditionalPlanDocuments.findIndex(
          (item) => item.value?.toString() === planDocumentName?.toString()
        );
        const planDocListIndex = additionalPlanDocumentTypes.findIndex(
          (item) => item.value?.toString() === planDocumentName?.toString()
        );
        if (tempDocListIndex !== -1) {
          const docList = cloneDeep(temporaryAdditionalPlanDocuments);
          docList[tempDocListIndex].value = planDocumentName;
          setTemporaryAdditionalPlanDocuments(docList);
        } else if (planDocListIndex !== -1) {
          const docList = cloneDeep(additionalPlanDocumentTypes);
          docList[planDocListIndex].value = planDocumentName;
          setAdditionalPlanDocumentTypes(docList);
        } else {
          setTemporaryAdditionalPlanDocuments((additionalTypes: any) => [
            ...additionalTypes,
            {
              value: !planDocumentName.startsWith(NO_NAME)
                ? planDocumentName
                : file.name,
              label: file.name,
            },
          ]);
        }
        setSelectedWebLinkList(
          selectedWebLinkList?.filter(
            (item) =>
              item.planDocumentName?.toString() !== planDocumentName?.toString()
          )
        );

        if (actionTriggered) {
          setSaveDisable(true);
          await actionTriggered('ADD', true, planDocumentName, file, null);
        }
        setSaveDisable(false);
        setIsAddNewDocumentOpened(false);
        if (actionTriggered) {
          await actionTriggered('ADD', true, planDocumentName, file, null);
        }
      } else if (weblink) {
        if (isValidWeblink(weblink)) {
          const webLinks = cloneDeep(weblinkList);
          const docs = cloneDeep(temporaryAdditionalPlanDocuments);
          const savedPlanDocs = cloneDeep(additionalPlanDocumentTypes);
          setTemporaryAdditionalPlanDocuments(
            docs?.filter(
              (item) => item.value?.toString() !== planDocumentName?.toString()
            )
          );
          setAdditionalPlanDocumentTypes(
            savedPlanDocs?.filter(
              (item) => item.value?.toString() !== planDocumentName?.toString()
            )
          );
          const existingWebLinkIndex = webLinks.findIndex(
            (link) => link.planDocumentName === planDocumentName
          );
          if (existingWebLinkIndex !== -1) {
            webLinks[existingWebLinkIndex].weblink = weblink;
            setSelectedWebLinkList(webLinks);
          } else {
            weblinkList.push({
              weblink: weblink,
              planDocumentName: planDocumentName,
            });

            setSelectedWebLinkList(weblinkList);
          }
          setWeblinkNameList([...weblinkNameList, planDocumentName]);
          setIsAddNewDocumentOpened(false);
          if (actionTriggered) {
            actionTriggered(
              'ADD',
              false,
              planDocumentName,
              null,
              weblink,
              null
            );
          }
        } else
          setAlertMessage({
            type: 'error',
            message: getValidationMessage(INVALID_WEBLINK),
          });
      } else {
        setAlertMessage({
          type: 'error',
          message: 'Please select a file to upload',
        });
        setShowAlert(true);
      }
      form.resetFields();
      onFileRemove();
      setAdditionalResourceType('');
    };

    const onRemove = (docType: string) => {
      setIsDocRemoved((prevState: any) => ({
        ...prevState,
        [docType]: true,
      }));

      if (actionTriggered) {
        actionTriggered('DELETE', true, docType, null, null);
      }

      if (!isEmpty(docs) && !isEmpty(docs[docType])) {
        const copy = cloneDeep(docs);
        delete copy[docType];
        setDocs(copy);
      }
    };

    const onRemoveWebLinkFromList = (webLink: WebLinkType) => {
      const webLinkIndex = selectedWebLinkList.indexOf(webLink);
      setSelectedWebLinkList((prevItems: any) =>
        prevItems.filter((_: any, i: any) => i !== webLinkIndex)
      );
      if (actionTriggered) {
        actionTriggered('DELETE', false, webLink.planDocumentName, null, null);
      }
    };

    const renderList = (docType: Option, index: number) => {
      let afpAdditionalDocFileName: string = '';
      if (actionTriggered) {
        const tempfile = docType.label as any;
        afpAdditionalDocFileName = (tempfile as { fileName: string }).fileName;
      }
      return (
        <div key={index}>
          <Row>
            <Col>
              <LinkButton
                onClick={() => {
                  const docIndex = additionalPlanDocumentTypes.indexOf(docType);
                  setAdditionalPlanDocumentTypes((prevItems: Option[]) =>
                    prevItems.filter((_: Option, i: number) => i !== docIndex)
                  );
                  const tempDocIndex =
                    temporaryAdditionalPlanDocuments.indexOf(docType);
                  setTemporaryAdditionalPlanDocuments((prevItems: Option[]) =>
                    prevItems.filter(
                      (_: Option, i: number) => i !== tempDocIndex
                    )
                  );
                  const newAddedFile = selectedFileList.find(
                    (file) => file.planDocumentName === docType.value
                  );
                  if (newAddedFile) {
                    setSelectedFileList((prevItems: Option[]) =>
                      prevItems.filter(
                        (_: Option, i: number) => i !== tempDocIndex
                      )
                    );
                  } else {
                    onRemove(docType.value);
                  }
                }}
                customClass={styles.removeIcon}
                icon={<IconRemoveDocument width={24} />}
              ></LinkButton>
            </Col>
            <Col>
              {actionTriggered && (
                <div className={styles.planDocumentText}>
                  {docType.value.startsWith(NO_NAME)
                    ? afpAdditionalDocFileName
                    : docType.value}
                </div>
              )}
              {!actionTriggered && (
                <div className={styles.planDocumentText}>
                  {docType.value.startsWith(NO_NAME)
                    ? docType.label
                    : docType.value}
                </div>
              )}
            </Col>
            <Col>
              {' '}
              <LinkButton
                onClick={() => {
                  // Set initial form values first
                  form.setFieldsValue({ weblink: undefined });

                  // Use a promise to enforce order for setting form fields and file
                  Promise.resolve().then(() => {
                    form.setFieldsValue({
                      planDocumentName: !docType.value.startsWith(NO_NAME)
                        ? docType.value
                        : undefined,
                    });

                    const blobUrl = get(
                      docs,
                      [docType.value, 'blobUrl'],
                      undefined
                    );
                    const fileName = get(
                      docs,
                      [docType.value, 'fileName'],
                      undefined
                    );
                    let file;
                    const savedFileName = !actionTriggered
                      ? get(docs, docType.value)
                      : afpAdditionalDocFileName;

                    if (savedFileName) {
                      file = new File([''], savedFileName);
                    }
                    if (blobUrl) {
                      file = new File([blobUrl], fileName);
                    }

                    setFile(file);
                    setOnEdit(docType.value);
                    setEditType(FILE);
                  });

                  // Perform the rest of the state updates
                  setIsEdit(true);
                  setIsAddNewDocumentOpened(true);
                  setAdditionalResourceType('file');
                }}
              >
                Edit
              </LinkButton>
            </Col>
          </Row>
        </div>
      );
    };
    const renderWebList = (webLink: WebLinkType, index: number) => {
      return (
        <div key={index}>
          <Row>
            <Col>
              <LinkButton
                onClick={() => onRemoveWebLinkFromList(webLink)}
                icon={<IconRemoveDocument width={24} />}
              />
            </Col>
            <Col>
              <div className={styles.planDocumentText}>
                {webLink.planDocumentName == null ||
                webLink.planDocumentName === 'undefined' ||
                webLink.planDocumentName.startsWith(NO_NAME)
                  ? webLink.weblink
                  : webLink.planDocumentName}
              </div>
            </Col>
            <Col>
              {' '}
              <LinkButton
                onClick={() => {
                  setFile(null);
                  Promise.resolve().then(() => {
                    form.setFieldsValue({
                      planDocumentName: webLink.planDocumentName.startsWith(
                        NO_NAME
                      )
                        ? webLink.weblink
                        : webLink.planDocumentName,
                    });
                  });

                  Promise.resolve().then(() => {
                    form.setFieldsValue({
                      weblink: webLink.weblink,
                    });
                  });
                  form.setFieldsValue({ webLink: webLink.weblink });
                  setIsAddNewDocumentOpened(true);
                  setAdditionalResourceType('webLink');
                  setIsEdit(true);
                  setEditType(WEBLINK);
                  setOnEdit(webLink.planDocumentName);
                }}
              >
                Edit
              </LinkButton>
            </Col>
          </Row>
        </div>
      );
    };
    return (
      <>
        {showAlert && (
          <div className={styles.alertWrapper}>
            <AlertMessage
              type={alertMessage.type}
              message={alertMessage.message}
              closeAlert={() => setShowAlert(false)}
            />
          </div>
        )}
        <LinkButton
          icon={
            isAddNewDocumentFormOpened ? (
              <IconDisabledDocument width={24} className={styles.addIcon} />
            ) : (
              <IconAddDocument width={24} className={styles.addIcon} />
            )
          }
          enableDisableStyle={true}
          disabled={isAddNewDocumentFormOpened}
          onClick={() => {
            setIsAddNewDocumentOpened(true);
          }}
        >
          Add Additional Resources
        </LinkButton>
        {!actionTriggered && temporaryAdditionalPlanDocuments.map(renderList)}
        {additionalPlanDocumentTypes.map(renderList)}
        {selectedWebLinkList.map(renderWebList)}
        {isAddNewDocumentFormOpened && (
          <PlanResources
            ref={ref}
            onFileChange={onFileChange}
            onFileRemove={onFileRemove}
            setFormData={setFormData}
            formData={formData}
            form={form}
            isRemoved={isRemoved}
            isCloseConfirmed={isCloseConfirmed}
            additionalResourceType={additionalResourceType}
            setAdditionalResourceType={setAdditionalResourceType}
            onValidateFails={onValidateFails}
            invalidField={invalidField}
            setIsAdditionalInfoValid={setInvalidField}
            planId={plan?.id}
            benefitKind={benefitKind}
            onSave={onSave}
            onCancel={onCancel}
            file={file}
            saveDisable={saveDisable}
          />
        )}
      </>
    );
  }
);
AdditionalPlanResources.displayName = 'AdditionalPlanResources';
export default AdditionalPlanResources;
