import axios from 'axios';

import CarrierType from 'modules/carriers/enums/CarrierType';
import { baseApi, getPagingQueryString } from 'util/apiUtil';

import { MASTER } from 'constants/commonConstants';
import { navContexts } from 'constants/authConstants';

const baseUrl = `${baseApi}/v2/plans/carriers`;

export const getCarrierList = (
  page: number,
  size: number,
  sort: string,
  query: string,
  organizationId: string | null | undefined,
  employerId: string | null | undefined,
  type: string,
  benefitCategories: string
) => {
  if (MASTER === type) {
    return axios.get(
      `${baseUrl}?${getPagingQueryString(
        page,
        size,
        sort,
        query,
        'search-key'
      )}&carrier-library-type=${type}&benefit-categories=${benefitCategories}`
    );
  } else if (navContexts.broker === type) {
    return axios.get(
      `${baseUrl}?${getPagingQueryString(
        page,
        size,
        sort,
        query,
        'search-key'
      )}&carrier-library-type=${type}&organization-id=${organizationId}&benefit-categories=${benefitCategories}`
    );
  } else {
    return axios.get(
      `${baseUrl}?${getPagingQueryString(
        page,
        size,
        sort,
        query,
        'search-key'
      )}&carrier-library-type=${type}&organization-id=${organizationId}&employer-id=${employerId}&benefit-categories=${benefitCategories}`
    );
  }
};

export const getCarrierById = (
  organizationId: string | null | undefined,
  employerId: string | null | undefined,
  type: string,
  carrierId: string | null
) => {
  if (MASTER === type) {
    const params = {
      'carrier-type': CarrierType.MASTER,
    };
    return axios.get(`${baseUrl}/${carrierId}/carrier`, { params });
  } else if (navContexts.broker === type) {
    const params = {
      'carrier-type': CarrierType.BROKER,
      'organization-id': organizationId,
    };
    return axios.get(`${baseUrl}/${carrierId}/carrier`, { params });
  } else {
    const params = {
      'carrier-type': CarrierType.EMPLOYER,
      'organization-id': organizationId,
      'employer-id': employerId,
    };
    return axios.get(`${baseUrl}/${carrierId}/carrier`, { params });
  }
};

export const getBenefitCarrierById = (
  benefitCarrierId: string | null | undefined
) => {
  return axios.get(`${baseUrl}/${benefitCarrierId}/benefit-carrier`);
};

export const createCarrier = async (data: any) => {
  return await axios.post(`${baseUrl}`, data);
};

export const uploadCarrierLogo = async (logo: Blob) => {
  const formData = new FormData();
  formData.append('file', logo);
  return axios.post(`${baseUrl}/logo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateCarrier = async (data: any, editedCarrierType: string) => {
  const params = {
    'carrier-library-type': editedCarrierType,
  };
  return await axios.put(`${baseUrl}`, data, { params });
};

export const createBenefitCarrier = async (data: any) => {
  return await axios.post(`${baseUrl}/benefit-carrier`, data);
};

export const updateBenefitCarrier = async (
  data: any,
  editedCarrierType: string
) => {
  const params = {
    'carrier-type': editedCarrierType,
  };
  return await axios.put(`${baseUrl}/benefit-carrier`, data, { params });
};

export const validateCarrierDelete = (
  carrierId: string | null | undefined,
  organizationId: string | undefined,
  employerId: string | undefined,
  benefitCarrier: boolean
) => {
  const params = {
    'organization-id': organizationId,
    'employer-id': employerId,
    'benefit-carrier': benefitCarrier,
  };
  return axios.get(`${baseUrl}/validate-delete/${carrierId}`, { params });
};

export const deleteCarrier = (
  carrierId: string | null | undefined,
  carrierType: string,
  employerId: string | undefined,
  organizationId: string | undefined
) => {
  const params = {
    'carrier-type': carrierType,
    'organization-id': organizationId,
    'employer-id': employerId,
  };
  return axios.delete(`${baseUrl}/carrier/${carrierId}`, { params });
};

export const deleteBenefitCarrier = (
  benefitCarrierId: string | null | undefined
) => {
  return axios.delete(`${baseUrl}/benefit-carrier/${benefitCarrierId}`);
};
