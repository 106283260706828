import { FC, useEffect, useRef, useState } from 'react';

import { Button, Modal, notification } from 'antd';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import IconInfo from 'assets/images/icon-info.svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import AddEnrollments from 'modules/plans/components/Enrollments/AddEnrollments/AddEnrollments';
import VisionBasicPlanInfoUPWrapper from 'modules/plans/vision/components/PlanBasicInfo/VisionBasicPlanInfoUPWrapper';
import RatesUPEditWrapper from 'modules/plans/vision/components/Rates/RatesUPEditWrapper';
import ServiceUPEditWrapper from 'modules/plans/vision/components/Services/ServicesUPEditWrapper';
import { EditMdvTypes } from 'modules/plans/constants';
import PanelSection from 'modules/plans/enums/PanelSection';
import { VisionPlan } from 'model/plans/VisionPlan';
import {
  findVisionPlanById,
  saveVisionPlan,
} from 'modules/plans/slices/visionPlanSlice';
import { buildRates } from 'modules/plans/utils';
import { getEditedPlanField } from 'util/commonUtil';
import { PLAN_NOTIFICATION_KEY } from 'constants/benguideCollaborationConstants';

import styles from './editVisionPlanModal.module.less';

type EditType = {
  value: string;
  title: string;
  width: number;
};

type EditVisionPlanModalProps = {
  plan: any;
  onClose: Function;
  isModalVisible: boolean;
  editType: EditType;
  setViewModal: Function;
};

const EditVisionPlanModal: FC<EditVisionPlanModalProps> = ({
  plan,
  onClose,
  editType,
  isModalVisible,
}: EditVisionPlanModalProps) => {
  const [currentPlan, setCurrentPlan] = useState<VisionPlan>(plan);
  const [initialRates, setInitialRates] = useState<any>();
  const [isInitialRate, setIsInitialRate] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const visionPlanRef = useRef<any>();
  const enrollmentsRef = useRef<any>();
  const [notificationKey] = useState(PLAN_NOTIFICATION_KEY);
  const cancelModal = () => {
    onClose();
  };

  const { inProgress } = useAppSelector((state) => state.plan.visionPlan);

  useEffect(() => {
    setCurrentPlan(plan);
    if (isInitialRate) {
      setInitialRates(plan.rates);
      setIsInitialRate(false);
    }
    // eslint-disable-next-line
  }, [plan]);

  const checkVisionPlanChangedFields = (updatedVisionPlan: VisionPlan) => {
    return (
      (!isEqual(updatedVisionPlan, plan) &&
        editType.value === EditMdvTypes.SERVICES.value) ||
      editType.value === EditMdvTypes.ENROLLMENTS.value ||
      (!isEqual(updatedVisionPlan.rates, initialRates) &&
        editType.value === EditMdvTypes.RATES.value)
    );
  };

  const savePlan = async () => {
    if (
      (editType.value === EditMdvTypes.BASIC_INFO.value &&
        (await visionPlanRef?.current?.isValidForm())) ||
      editType.value !== EditMdvTypes.BASIC_INFO.value
    ) {
      let updatedVisionPlan = currentPlan;
      if (editType.value === EditMdvTypes.RATES.value) {
        const { invalidFormFields = [] } =
          await visionPlanRef?.current?.validate();
        if (!isEmpty(invalidFormFields)) {
          return;
        }
      }
      if (editType.value === EditMdvTypes.BASIC_INFO.value) {
        updatedVisionPlan = {
          ...currentPlan,
          rates: buildRates(currentPlan.groups, currentPlan),
        };
      }
      if (checkVisionPlanChangedFields(updatedVisionPlan)) {
        updatedVisionPlan = { ...updatedVisionPlan };

        updatedVisionPlan.planChangedFieldTypes = [
          getEditedPlanField(editType.value),
        ];
      }
      dispatch(
        saveVisionPlan(updatedVisionPlan, () => {
          onClose();
          visionPlanRef?.current?.reset();
          if (plan && plan.id) {
            dispatch(findVisionPlanById(plan.id));
          }
        })
      );
      notification.close(notificationKey);
    }
  };

  const getEditTypeComponent = () => {
    switch (editType.value) {
      case EditMdvTypes.BASIC_INFO.value:
        return (
          <VisionBasicPlanInfoUPWrapper
            visionPlan={currentPlan}
            setVisionPlan={setCurrentPlan}
            basicPlanInfoRef={visionPlanRef}
            originalPlanYear={plan?.planYearId}
            originalPlanBenGroups={plan?.groups}
          />
        );
      case EditMdvTypes.RATES.value:
        return (
          <RatesUPEditWrapper
            visionPlan={currentPlan}
            currentSection={PanelSection.RATES}
            wrappedRef={visionPlanRef}
            setVisionPlan={setCurrentPlan}
          />
        );
      case EditMdvTypes.SERVICES.value:
        return (
          <ServiceUPEditWrapper
            visionPlan={currentPlan}
            type={PanelSection.SERVICES}
            setVisionPlan={setCurrentPlan}
          />
        );
      case EditMdvTypes.ENROLLMENTS.value:
        return (
          <AddEnrollments
            plan={currentPlan}
            onChange={setCurrentPlan}
            isEdit={true}
            ref={enrollmentsRef}
          />
        );
    }
  };

  return (
    <Modal
      width={editType.width}
      title={<strong>{editType.title}</strong>}
      visible={isModalVisible}
      onCancel={cancelModal}
      okText="Done"
      footer={false}
      className={styles.visionPlanChannelModal}
      closable={false}
      maskClosable={false}
    >
      <div className={styles.visionPlanChannelWrapper}>
        <div className={styles.leftBorder}></div>
        <div className={styles.visionPlanChannel}>
          <div>
            <img src={IconInfo} alt="Icon Info" />
          </div>
          <div className={styles.defaultText}>
            Changing plan information here will change the plan information
            everywhere this plan is shown
          </div>
        </div>
        {getEditTypeComponent()}
        <div className={styles.buttons}>
          <Button onClick={cancelModal} className={styles.cancel}>
            Cancel
          </Button>
          <Button
            onClick={savePlan}
            className={styles.done}
            loading={inProgress}
          >
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditVisionPlanModal;
