import axios from 'axios';

import ErrorDetails from 'model/ErrorDetails';
import { TaxAdvantagedAccountPlan } from 'model/plans/TaxAdvantagedAccountPlan';
import { baseApi, getPagingQueryString } from 'util/apiUtil';

const taxAdvantageAccountsUrl = baseApi + '/v1/plans/tax-advantage-accounts';

export const createTaxAdvantagedAccountPlan = (
  payload: TaxAdvantagedAccountPlan
) => {
  try {
    return axios.post(`${taxAdvantageAccountsUrl}`, payload);
  } catch (error: any) {
    if (error.response) {
      return { data: error.response.data } as ErrorDetails;
    }
  }
};

export const updateTaxAdvantagedAccountPlan = (
  payload: TaxAdvantagedAccountPlan
) => {
  try {
    return axios.put(`${taxAdvantageAccountsUrl}/${payload.id}`, payload);
  } catch (error) {
    throw error;
  }
};

export const getTaxAdvantagedAccountPlansList = (
  page: number,
  size: number,
  sort: string,
  query: string,
  employerId: string,
  planYearId: string
) => {
  return axios.get(
    `${taxAdvantageAccountsUrl}/roster?${getPagingQueryString(
      page,
      size,
      sort,
      query
    )}`,
    {
      params: {
        'employer-id': employerId,
        'plan-year-id': planYearId,
      },
    }
  );
};

export const getTaxAdvantagedPlanById = (planId: string) => {
  return axios.get(`${taxAdvantageAccountsUrl}/${planId}`);
};
