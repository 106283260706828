import { FC, MutableRefObject } from 'react';
import { useAppSelector } from 'hooks/redux';
import { DentalPlan } from 'model/plans/DentalPlan';
import Services from 'modules/plans/components/DVServices/Services';
import { BenefitCategory } from 'constants/commonConstants';

type ServicesUPWrapperProps = {
  onChange: Function;
  wrappedRef: MutableRefObject<any>;
  isTouched: boolean;
};
const ServicesUPWrapper: FC<ServicesUPWrapperProps> = (props) => {
  const { onChange, wrappedRef, isTouched } = props;
  const { dentalPlan } = useAppSelector((state) => state.plan.dentalPlan);

  return (
    <Services
      isSmall
      plan={dentalPlan || ({} as DentalPlan)}
      ref={wrappedRef}
      onChange={onChange}
      planType={BenefitCategory.DENTAL.value}
      isTouched={isTouched}
    />
  );
};

export default ServicesUPWrapper;
