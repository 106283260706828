import React, { useState, useEffect, useMemo, forwardRef } from 'react';

import { isEmpty } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useAppSelector } from 'hooks/redux';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import NextButton from 'components/buttons/NextButton/NextButton';
import Employer from 'model/Employer';
import {
  DRAFTS_TAB,
  OFFER_CATEGORY,
  PROPOSAL_MODELING_SECTION,
} from 'modules/renewals/constants/renewalsConstants';
import RenewalInnerStepper from 'modules/renewals/components/renewalInnerStepper/RenewalInnerStepper';
import RenewalInnerStepperDto from 'model/RenewalInnerStepperDto';
import RenewalInnerStepperInfoDto from 'model/RenewalInnerStepperInfoDto';
import RenewalCommonSubHeader from 'modules/renewals/components/helperComponents/RenewalCommonSubHeader/RenewalCommonSubHeader';
import ModelingContainerWrapperNew from 'modules/renewals/components/addProposalsComponents/ModelingContainerWrapperNew/ModelingContainerWrapperNew';
import TextButton from 'components/buttons/TextButton/TextButton';
import { ReactComponent as MedicalOfferIcon } from 'assets/images/medical-offer-icon.svg';
import { ReactComponent as DentalOfferIcon } from 'assets/images/dental-offer-icon.svg';
import { ReactComponent as DentalOfferDisabledIcon } from 'assets/images/dental-disabled-offer-icon.svg';
import { ReactComponent as VisionOfferIcon } from 'assets/images/vision-offer-icon.svg';
import { ReactComponent as VisionOfferDisabledIcon } from 'assets/images/vision-disabled-offer-icon.svg';

import styles from 'modules/renewals/components/addProposalsComponents/Modeling/modeling.module.less';
import { loginTypes } from 'constants/authConstants';

type BasicInfoProps = {
  nextStep: () => void;
  onPreviousStep: () => void;
  closeModal: (notShowConfirmMessage: any) => void;
  isModalOpen?: boolean;
  isEditMode?: boolean;
  employerEditData?: Employer | null;
  proposalId: string | undefined;
  isProposalDetailedView?: boolean;
};

const Modeling = (props: BasicInfoProps, ref: any) => {
  const proposalObj: any = useAppSelector(
    (state: any) => state.proposalOnBoarding.proposal
  );
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;

  const [mainStepperData, setMainStepperData] = useState<
    RenewalInnerStepperDto[]
  >([]);
  const [completedSteps] = useState<number[]>([]);
  const [currentStep, setCurrentStep] = useState<number | null>(null);
  const [currentStepInfo, setCurrentStepInfo] =
    useState<RenewalInnerStepperInfoDto | null>(null);

  const params = useParams();
  const navigate = useNavigate();

  const handleNavigation = (): void => {
    navigate(
      `/brokers/${params.brokerId}/employers/${params.employerId}/renewals/proposals`,
      { state: { proposalStatus: DRAFTS_TAB } }
    );
  };

  useEffect(() => {
    if (proposalObj?.payload) {
      const {
        supplementaryMedicalOffers,
        supplementaryDentalOffers,
        supplementaryVisionOffers,
      } = proposalObj?.payload;
      const innerStepperData: RenewalInnerStepperDto[] = [];
      let step = 0;
      if (
        proposalObj?.payload?.primaryMedicalOffer ||
        supplementaryMedicalOffers?.length !== 0
      ) {
        innerStepperData.push({
          id: step,
          mainTxt: 'Medical Modeling',
          mainEnabledIcon: <MedicalOfferIcon className={styles.mainIcon} />,
          mainDisabledIcon: <MedicalOfferIcon className={styles.mainIcon} />,
          isCompleted: false,
          isActive: false,
          isEnabled: false,
          benefitKind: OFFER_CATEGORY.MEDICAL,
          order: 0,
        });
        step = step + 1;
      }
      if (
        proposalObj?.payload?.primaryDentalOffer ||
        !isEmpty(supplementaryDentalOffers)
      ) {
        innerStepperData.push({
          id: step,
          mainTxt: 'Dental Modeling',
          mainEnabledIcon: <DentalOfferIcon className={styles.mainIcon} />,
          mainDisabledIcon: (
            <DentalOfferDisabledIcon className={styles.mainIcon} />
          ),
          isCompleted: false,
          isActive: false,
          isEnabled: false,
          benefitKind: OFFER_CATEGORY.DENTAL,
          order: 1,
        });
        step = step + 1;
      }
      if (
        proposalObj?.payload?.primaryVisionOffer ||
        !isEmpty(supplementaryVisionOffers)
      ) {
        innerStepperData.push({
          id: step,
          mainTxt: 'Vision Modeling',
          mainEnabledIcon: <VisionOfferIcon className={styles.mainIcon} />,
          mainDisabledIcon: (
            <VisionOfferDisabledIcon className={styles.mainIcon} />
          ),
          isCompleted: false,
          isActive: false,
          isEnabled: false,
          benefitKind: OFFER_CATEGORY.VISION,
          order: 2,
        });
      }

      if (innerStepperData.length !== 0) {
        innerStepperData[0].isActive = true;
        innerStepperData[0].isEnabled = true;

        setCurrentStep(innerStepperData[0].id);
      }
      setMainStepperData(innerStepperData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMainStepperComplete = (
    key: number,
    isFullyCompleted: boolean,
    isNextClick: boolean
  ) => {
    const stepperInfo: RenewalInnerStepperInfoDto = {
      isFullyCompleted,
      key,
      isNextClick,
    };
    setCurrentStepInfo(stepperInfo);

    if (isNextClick) {
      if (mainStepperData.length === 1) {
        props.nextStep();
      } else {
        if (key === mainStepperData[mainStepperData.length - 1].id) {
          props.nextStep();
        }
      }
    } else {
      if (mainStepperData.length === 1) {
        props.onPreviousStep();
      } else {
        if (key === mainStepperData[0].id) {
          props.onPreviousStep();
        }
      }
    }
  };

  const generateViewBasingStepper = () => {
    if (currentStep !== null) {
      const data = mainStepperData[currentStep];
      return (
        <ModelingContainerWrapperNew
          ref={ref}
          type={data?.benefitKind}
          handleStepperComplete={handleMainStepperComplete}
          proposalId={props.proposalId!}
          closeModal={props.closeModal}
          mainsStepperData={mainStepperData}
          handleNavigation={handleNavigation}
          isProposalDetailedView={props.isProposalDetailedView}
        />
      );
    }
  };

  const generateIndividualStylingBasedOnLength = (
    innerStepperData: RenewalInnerStepperDto[]
  ): any => {
    switch (innerStepperData.length) {
      case 1:
        return styles.fullyWidthStepperItem;
      case 2:
        return styles.twoItemsItemStyling;
      case 3:
        return styles.stepperItemStyling;
      default:
        return styles.stepperItemStyling;
    }
  };

  const isNotSelectAny = useMemo(() => {
    const status = [
      proposalObj?.payload?.primaryMedicalOffer === null,
      proposalObj?.payload?.supplementaryMedicalOffers?.length === 0,
      proposalObj?.payload?.primaryDentalOffer === null,
      proposalObj?.payload?.supplementaryDentalOffers?.length === 0,
      proposalObj?.payload?.primaryVisionOffer === null,
      proposalObj?.payload?.supplementaryVisionOffers?.length === 0,
      proposalObj?.payload?.lifeAddOffers?.length === 0,
      proposalObj?.payload?.lifeLTDOffers?.length === 0,
      proposalObj?.payload?.lifeSTDOffers?.length === 0,
      proposalObj?.payload?.volLifeOffers?.length === 0,
    ].every((item) => item === true);

    return status;
  }, [proposalObj?.payload]);

  return (
    <div className={styles.container}>
      {mainStepperData.length === 0 ? (
        <>
          <div className={styles.noSelectedStyles}>
            <RenewalCommonSubHeader subText="You haven’t selected any offers for modeling" />
            <p className={styles.selectOfferDescription}>
              To begin modeling, you must{' '}
              <span
                className={styles.selectOfferLink}
                onClick={() => props.onPreviousStep()}
              >
                select offers
              </span>{' '}
              for this proposal first
            </p>
          </div>
          {/*  Next and Back section */}
          <Row className={styles.btnRow} gutter={[16, 16]}>
            <Col className={styles.backBtnWrapper} span={8}>
              <SubmitButton
                className={styles.cancelButton}
                onClick={() => props.onPreviousStep()}
                disabled={false}
              >
                Back
              </SubmitButton>
            </Col>
            <Col span={8}>
              <NextButton
                nextStep={() => props.nextStep()}
                className={styles.nextBtn}
                disabled={isNotSelectAny}
              />
            </Col>
            {!isErAdmin && (
              <Col span={24} className={styles.saveDraftBtn}>
                <TextButton
                  label="Save Draft & Close"
                  onClick={() => props.closeModal(true)}
                  type="primary"
                />
              </Col>
            )}
          </Row>
        </>
      ) : (
        <>
          <RenewalInnerStepper
            ref={ref}
            data={mainStepperData}
            setCurrentStep={setCurrentStep}
            setInnerStepperData={setMainStepperData}
            completedSteps={completedSteps}
            individualItemStyling={generateIndividualStylingBasedOnLength(
              mainStepperData
            )}
            isSubStepperExist={false}
            completedStepInfo={currentStepInfo}
            mainSection={PROPOSAL_MODELING_SECTION}
            proposalId={props.proposalId}
          />

          {/* Dynamic Section */}
          {generateViewBasingStepper()}
        </>
      )}
    </div>
  );
};

export default forwardRef(Modeling);
