import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { List, Popover, Row } from 'antd';
import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { ColumnData } from 'modules/renewals/models/ColumnData';
import { useAppSelector } from 'hooks/redux';
import {
  OFFER_TYPE_NAMES,
  STANDARD_TIER,
  VOLUNTARY_BENEFIT,
  OFFERED_PLAN,
  RENEWAL_OFFER,
  OfferType,
  TOOLTIP_INOUT_CONST,
} from 'modules/renewals/constants/renewalsConstants';
import StandardErrorBoundary from 'modules/tools/components/ErrorBoundary/ErrorBoundry';
import AddPlanDetailsModal from 'modules/renewals/components/AddPlanDetailsModal/AddPlanDetailsModal';
import { AddPlanConfirmModal } from 'modules/renewals/components/AddOfferComponents/AddPlanConfirmModal/AddPlanConfirmModal';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { isNullOrUndefined } from 'modules/plans/utils';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { BenefitCategory } from 'constants/commonConstants';
import ComparePlans from 'modules/renewals/pages/offers/ComparePlans/ComparePlans';
import { Offer } from 'modules/renewals/models/Offer';
import ColHeader from 'modules/renewals/components/ColHeader/ColHeader';
import AddPlanButton from 'modules/renewals/components/AddPlanButton/AddPlanButton';
import { ReactComponent as OfferDetailIcon } from 'assets/images/square-arrow-icon.svg';
import PremiumType from 'modules/renewals/enums/PremiumType';
import { getEmptyPlan } from 'modules/renewals/utils/renewalsUtils';
import {
  getPlanTableColumnLabels,
  transformPlansToTablePlans,
} from 'modules/renewals/utils/planTableLabelUtil';
import {
  transformMDVPlanDataToTablePlan,
  transformTablePlanDataToEditData,
} from 'modules/renewals/utils/planTableDataTransformerUtil';
import {
  addDollarSignsForArray,
  getLongestTitle,
  isNumeric,
  scrollToRight,
} from 'modules/renewals/utils/planTableOrderAndOtherUtils';
import { BenefitType } from 'modules/renewals/models/PlanTable';
import FundingType from 'modules/plans/enums/FundingType';
import { Plan } from 'modules/renewals/types/planTypes';
import QuoteProcessStatus from 'modules/renewals/enums/QuoteUploadStatus';
import { ReviewType } from 'modules/renewals/enums/ReviewType';
import { ReactComponent as AIFeedbackRedFlag } from 'assets/images/ai-red-flag.svg';
import styles from './planTable.module.less';

type PlanTableProps = {
  data: Plan[];
  labelColumnData: ColumnData[];
  titleProperty: string;
  logoProperty: string;
  isLogoProperty?: boolean;
  offer?: Offer;
  benefitType?: BenefitType;
  fixedWidth?: boolean;
  showEndLine?: boolean;
  editTable?: boolean;
  removePlans?: boolean;
  updateData?: Function;
  mapPlanDroDownData?: any;
  mapDropDownDataOnChange?: Function;
  mapDropdownSelectedValues?: any[];
  addPlan?: boolean;
  isPlanEmpty?: boolean;
  shrinkedHeader?: boolean;
  setAddPlanIndex?: Function;
  addPlanIndex?: number[];
  isComparePlans?: boolean;
  offerStatus?: string;
  // In order to toggle the display of Compare Similar plans in the table
  isCompareSimilarPlansApplicable?: boolean;
  handleOfferDetailClick?: (bundleId: any) => void;
  mapToPlan?: boolean;
  changeManualPlanDropdownPlansSelected?: Function;
  manualSelectDropDownValues?: string[];
  showValidationsOnLoad?: boolean;
  triggerAllValidations?: boolean;
  showMappedPlansHighlight?: boolean;
  showRequired?: boolean;
  isEditOffer?: boolean;
  premiumType?: PremiumType;
  nTierCount?: number;
  isImportPlans?: boolean;
  isNoCurrentPlans?: boolean;
  isProposalsAttached?: boolean;
  changeUpdatedData?: Function;
  fundingType?: FundingType;
  isPreviewSection?: boolean;
  validateFormatting?: boolean;
  isQuoteProcessingEnabled?: boolean;
  extractedDocument?: {
    id: string;
    status: QuoteProcessStatus;
    reviewType: ReviewType;
  };
  isQuotePlansApproved?: boolean;
  isPlanDetails?: boolean;
  isPlanDetailSidebar?: boolean;
  showFlagItemWarning?: boolean;
};

const PlanTable: FC<PlanTableProps> = ({
  data,
  labelColumnData,
  titleProperty,
  logoProperty,
  isLogoProperty = false,
  offer,
  benefitType,
  fixedWidth = false,
  showEndLine = false,
  editTable = false,
  removePlans = false,
  updateData,
  mapPlanDroDownData,
  mapDropDownDataOnChange,
  mapDropdownSelectedValues,
  addPlan = false,
  shrinkedHeader = false,
  setAddPlanIndex,
  addPlanIndex = [],
  isComparePlans = false,
  isCompareSimilarPlansApplicable = false,
  offerStatus,
  handleOfferDetailClick,
  mapToPlan = false,
  changeManualPlanDropdownPlansSelected,
  manualSelectDropDownValues,
  showValidationsOnLoad = false,
  triggerAllValidations,
  showMappedPlansHighlight = false,
  showRequired,
  isEditOffer,
  premiumType = PremiumType.STANDARD_TIER,
  nTierCount = 0,
  isImportPlans,
  isNoCurrentPlans = false,
  isProposalsAttached = false,
  changeUpdatedData,
  fundingType,
  isPreviewSection,
  validateFormatting = false,
  isQuoteProcessingEnabled = false,
  extractedDocument,
  isQuotePlansApproved,
  isPlanDetails,
  isPlanDetailSidebar,
  showFlagItemWarning,
}) => {
  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );
  const addButtonRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);
  const [flexWrapWidth, setFlexWrapWidth] = useState<number>(0);
  const [listWrapHeight, setListWrapHeight] = useState<number>(0);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [addCloneOfferPlanVisible, setAddCloneOfferPlanVisible] =
    useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [updatedData, setUpdatedData] = useState<any>(undefined);
  const [selectedDataIndex, setSelectedIndex] = useState<number>(0);
  const [initialData, setInitialData] = useState<any>(null);
  const [columnData, setColumnData] = useState<ColumnData[]>(labelColumnData);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState<number>(-1);
  const [selectedPlanId, setSelectedPlanId] = useState<any>(null);
  const [comparePlansVisible, setComparePlansVisible] =
    useState<boolean>(false);
  const ref: any = useRef(null);
  const widthRef: any = useRef(null);
  const listHeightRef: any = useRef(null);

  const filterMappedPlans = (planList: any[], currentSelected: string) => {
    const filterExceptCurrent = mapDropdownSelectedValues?.filter(
      (planId: string) =>
        planId !== currentSelected && !isNullOrUndefined(planId)
    );
    return planList?.filter(
      (plan: any) => !filterExceptCurrent?.includes(plan?.value)
    );
  };

  const isNewTagApplicable = useCallback(
    (index: number, titleData: any): boolean => {
      if (isEditOffer) {
        if (isEmpty(mapPlanDroDownData)) return true;

        const assignedCurrentPlan = mapDropdownSelectedValues?.[index];

        const checkMapPlanDroDownData = ['', undefined].includes(
          assignedCurrentPlan
        );

        if (checkMapPlanDroDownData) return false;

        if (assignedCurrentPlan === null) return true;

        // Check if assignedCurrentPlan is in the currentPlans object
        return !mapPlanDroDownData?.find(
          (obj: any) => obj.value === assignedCurrentPlan
        );
      }
      return titleData?.newPlan;
    },
    [isEditOffer, mapDropdownSelectedValues, mapPlanDroDownData]
  );
  useEffect(() => {
    setHeight(ref?.current?.clientHeight);
    setFlexWrapWidth(widthRef?.current?.clientWidth);
    if (listHeightRef?.current) {
      setListWrapHeight(listHeightRef?.current?.clientHeight);
    } else if (document.getElementsByClassName('listHeightRefDiv')[0]) {
      setListWrapHeight(
        document.getElementsByClassName('listHeightRefDiv')[0]?.clientHeight
      );
    }
  }, [initialData, flexWrapWidth, height, columnData]);

  useEffect(() => {
    setColumnData(labelColumnData);
  }, [labelColumnData]);

  const emptyPlan = transformMDVPlanDataToTablePlan({
    plan: getEmptyPlan(premiumType, benefitType ?? '', nTierCount),
    benefitType: benefitType!,
    isCurrentPlan: false,
  });

  useEffect(() => {
    const isNTier =
      premiumType !== null &&
      premiumType !== undefined &&
      premiumType?.toString() !== STANDARD_TIER;
    if (addPlan && !data) {
      setAddPlanIndex?.([0]);
      const dummyArr = [
        {
          ...emptyPlan,
          name: 'Plan #1',
        },
      ];
      const transformedDummyArr = transformPlansToTablePlans({
        plans: dummyArr,
        benefitType: benefitType!,
        isNTier,
        nTierCount,
        isComparePlans,
        isPreview: isPreviewSection,
        isPlanDetails,
        isPlanDetailSidebar,
        isNoCurrentPlans,
      });
      setInitialData(transformedDummyArr);
    } else {
      const dummyArr =
        transformPlansToTablePlans({
          plans: data,
          benefitType: benefitType!,
          isNTier,
          nTierCount,
          isComparePlans,
          isPreview: isPreviewSection,
          isPlanDetails,
          isPlanDetailSidebar,
          isNoCurrentPlans,
        }) || [];
      const additionalPlans =
        addPlanIndex?.map((_, index) => ({
          ...emptyPlan,
          name: `Plan #${(dummyArr?.length ?? 0) + index + 1}`,
        })) ?? [];

      setInitialData([...dummyArr, ...additionalPlans]);
    }

    // eslint-disable-next-line
  }, [
    data,
    premiumType,
    isNoCurrentPlans,
    isPreviewSection,
    isComparePlans,
    nTierCount,
    isPlanDetailSidebar,
    isPlanDetails,
  ]);

  useEffect(() => {
    if (updatedData !== undefined) {
      let updated = [] as Plan[];
      if (addPlanIndex.includes(selectedDataIndex)) {
        updated =
          data !== null && data !== undefined
            ? [...data, updatedData]
            : [updatedData];
        setAddPlanIndex &&
          setAddPlanIndex(
            addPlanIndex.filter((item: number, index) => index !== 0)
          );
      } else {
        updated = data?.map((item: any, index: number) => {
          if (index === selectedDataIndex) {
            return updatedData;
          } else {
            return item;
          }
        });
      }

      updateData && updateData(updated);
    }

    // eslint-disable-next-line
  }, [updatedData]);

  const handleCurrentPlanSelectToEachPlan = (value: string): void => {
    if (value !== '') {
      const mappedCurrentPlanArray = data?.map((_: any, index: number) => {
        if (selectedDataIndex === index) {
          // This dropdown needs to allow clearing. If we give an undefined value here, API breaks.
          // Therefore we are converting to an empty string in that case
          return value === null ? null : value ?? '';
        }
        const mappedValue = mapDropdownSelectedValues?.[index];

        if (mappedValue === null || mappedValue === '-') {
          return null;
        }

        return mappedValue || '';
      });

      mapDropDownDataOnChange?.(mappedCurrentPlanArray);
    }
  };

  const handlePlanDelete = () => {
    // Initialize updatedArr and dropdownList arrays
    let updatedArr: any[] = [];
    let dropdownList: string[] = [];

    // Check if changeManualPlanDropdownPlansSelected function is available
    if (changeManualPlanDropdownPlansSelected) {
      // Get the selectedRecord's referenceId or set it to null
      const selectedRecord =
        initialData?.[selectedDataIndex]?.referenceId ?? null;

      // Call mapDropDownDataOnChange with updated mapDropdownSelectedValues array
      mapDropDownDataOnChange &&
        mapDropDownDataOnChange(
          (mapDropdownSelectedValues ?? []).filter(
            (item: any, index: number) => index !== selectedDataIndex
          ) ?? []
        );

      // Update manualSelectDropDownValues if selectedRecord is not null
      if (selectedRecord !== null) {
        let updatedRecord: string[] = [];

        (manualSelectDropDownValues ?? []).map((item: any) => {
          if (item !== selectedRecord) {
            updatedRecord = [...updatedRecord, item];
          }
        });

        changeManualPlanDropdownPlansSelected(updatedRecord);
      }
    }

    // Update initialData, updatedArr, and dropdownList arrays
    initialData.forEach((item: any, index: number) => {
      if (selectedDataIndex !== index && !addPlanIndex.includes(index)) {
        (data ?? []).map((dataItem: any) => {
          if (dataItem.name === item.name) {
            item.services = dataItem.services;
          }
        });
        updatedArr = [...updatedArr, item];
        dropdownList = [
          ...dropdownList,
          mapDropdownSelectedValues?.[index] ?? '-',
        ];
      }
    });

    // Update updatedAddPlanIndex array
    let updatedAddPlanIndex: number[] = [];
    addPlanIndex.map((item) => {
      if (item !== selectedDataIndex) {
        if (item >= selectedDataIndex) {
          updatedAddPlanIndex = [...updatedAddPlanIndex, item - 1];
        } else {
          updatedAddPlanIndex = [...updatedAddPlanIndex, item];
        }
      }
    });

    // Remove duplicates from updatedAddPlanIndex array
    const set = new Set(updatedAddPlanIndex);
    updatedAddPlanIndex = Array.from(set);

    // Update addPlanIndex state
    setAddPlanIndex &&
      setAddPlanIndex(
        (updatedArr?.length ?? 0) === 0 &&
          (updatedAddPlanIndex?.length ?? 0) === 0 &&
          addPlan
          ? [0]
          : updatedAddPlanIndex
      );

    // Update initialData state
    if (
      (updatedArr?.length ?? 0) === 0 &&
      (updatedAddPlanIndex?.length ?? 0) !== 0
    ) {
      let addPlanArray: any[] = [];
      updatedAddPlanIndex.map((item, index) => {
        addPlanArray = [
          ...addPlanArray,
          {
            ...emptyPlan,
            name: `Plan #${index + 1}`,
          },
        ];
      });
      setInitialData(addPlanArray);
    } else {
      setInitialData(
        (updatedArr?.length ?? 0) === 0 &&
          (updatedAddPlanIndex?.length ?? 0) === 0 &&
          addPlan
          ? [{ ...emptyPlan, name: `Plan #1` }]
          : updatedArr
      );
    }

    // Call mapDropDownDataOnChange with dropdownList array
    mapDropDownDataOnChange && mapDropDownDataOnChange(dropdownList);

    // Update updateData state
    updateData &&
      updateData((updatedArr?.length ?? 0) === 0 ? null : updatedArr);

    // Close the delete modal
    setDeleteModalVisible(false);
  };

  const isSelectedPlanForComparison = (
    index: number,
    targetIndex: number
  ): boolean => {
    return index == targetIndex;
  };

  /**
   * Handles the click event when the remove service icon is clicked.
   *
   * @param {number} dataIndex The index of the data item to be selected for removal.
   */
  const handleRemoveServiceIconClicked = useCallback(
    (dataIndex: number): void => {
      setDeleteModalVisible(true);
      setSelectedData(initialData?.[dataIndex]);
      setSelectedIndex(dataIndex);
    },
    [initialData]
  );

  const arrangeColData = (data: Plan[]) => {
    const isNTier =
      premiumType !== null &&
      premiumType !== undefined &&
      premiumType?.toString() !== STANDARD_TIER;

    return getPlanTableColumnLabels({
      benefitType: benefitType!,
      plans: data,
      fundingType: fundingType!,
      addFooterRow: true,
      addEnrollmentsForPremiums: true,
      isNTier: isNTier,
      isPreview: isPreviewSection,
      nTierCount,
      isNoCurrentPlans,
    });
  };

  const handleManualPlanAdd = () => {
    if (isOpsAdmin) {
      return;
    }
    // Compute the new plan data
    const newPlan = {
      ...emptyPlan,
      name: `Plan #${initialData?.length ? initialData.length + 1 : 1}`,
    };

    // New initial data with the new plan added
    const newInitialData = initialData ? [...initialData, newPlan] : [newPlan];

    // Update initial data state
    setInitialData(newInitialData);

    // Update column data state
    const newColumnData = arrangeColData(newInitialData);
    setColumnData(newColumnData);

    setAddPlanIndex &&
      setAddPlanIndex((prevAddPlanIndex: number[]) => [
        ...prevAddPlanIndex,
        newInitialData.length - 1 ?? 0,
      ]);
  };

  const handleAddPlan = () => {
    if (isOpsAdmin || validateFormatting) {
      return;
    }
    if (!isEmpty(data)) {
      setAddCloneOfferPlanVisible(true);
    } else {
      handleManualPlanAdd();
    }
  };

  const orderData: any = useMemo(() => {
    const allData: any = [];

    initialData?.map((item: any, index: number) => {
      const singleData: any[] = [];
      const header = (
        <ColHeader
          titleData={item}
          dataIndex={index}
          removePlans={removePlans && !isOpsAdmin}
          addPlanIndex={addPlanIndex}
          updatedData={updatedData}
          updateData={updateData}
          titleProperty={titleProperty}
          shrinkedHeader={shrinkedHeader}
          isComparePlans={isComparePlans}
          logoProperty={logoProperty}
          isNewTagApplicable={isNewTagApplicable}
          removeServiceIconClicked={handleRemoveServiceIconClicked}
        />
      );
      columnData?.map((colData) => {
        if (!colData?.isFooterValue) {
          singleData.push({
            isWhiteBG: colData?.isWhiteBG,
            isTableBreak: colData?.isTableBreak,
            isEditPlanRow: colData?.isEditPlanRow,
            value: colData?.render(item),
          });
        }
        return singleData;
      });

      const dataObj = {
        header: header,
        body: singleData,
        footer: [
          {
            isWhiteBG: true,
            isTableBreak: true,
            isEditPlanRow: false,
            value: '-',
          },
        ],
      };
      allData?.push(dataObj);
      return allData;
    });

    if (addPlan && (addPlanIndex?.length ?? 0) !== 0) {
      scrollToRight(widthRef);
    }

    return allData;
    // eslint-disable-next-line
  }, [
    initialData,
    columnData,
    addPlanIndex,
    updatedData,
    addPlan,
    isComparePlans,
    removePlans,
    titleProperty,
    shrinkedHeader,
    logoProperty,
    isOpsAdmin,
    updateData,
    isNewTagApplicable,
    handleRemoveServiceIconClicked,
    mapDropdownSelectedValues,
    mapPlanDroDownData,
  ]);

  const isMappedPlanError = (index: number): boolean => {
    return (
      (((mapDropdownSelectedValues?.[index] ?? '-') === '-' ||
        (mapDropdownSelectedValues?.[index] ?? '-') === '') &&
        showMappedPlansHighlight &&
        index < (data?.length ?? 0)) ||
      mapDropdownSelectedValues?.[index] === ''
    );
  };

  const setSelectedCurrentPlan = (index: number) => {
    const currentPlanIds = mapPlanDroDownData?.map(
      (currPlan: { value: string }) => currPlan.value
    );

    return mapDropdownSelectedValues?.[index] !== null
      ? mapDropdownSelectedValues?.[index]
        ? currentPlanIds?.includes(mapDropdownSelectedValues?.[index])
          ? mapDropdownSelectedValues?.[index]
          : null
        : mapDefaultPlan(index, mapPlanDroDownData)
      : mapDropdownSelectedValues?.[index];
  };

  const mapDefaultPlan = (index: number, arr: any[]) => {
    if (isImportPlans) {
      const dropDownObj = arr?.find((x) => x.label === data?.[index]?.name);
      const dropDownSelectedValuesArray = [
        ...(mapDropdownSelectedValues ?? []),
      ];
      dropDownSelectedValuesArray[index] = dropDownObj?.value;
      if (mapDropDownDataOnChange && dropDownObj) {
        mapDropDownDataOnChange(dropDownSelectedValuesArray);
        return dropDownObj.value;
      }
    }
    return '-';
  };

  const customWidth = fixedWidth ? 194 : flexWrapWidth / 5;
  const customHeightOne = listWrapHeight - (height + 65);
  const customHeightTwo = listWrapHeight - (height + 30);

  return (
    <StandardErrorBoundary>
      <div
        ref={ref}
        className={styles.titleHeightBlock}
        style={{
          width: `${customWidth}px`,
          maxWidth: `${customWidth}px`,
          minWidth: `${customWidth <= 198 ? 198 : flexWrapWidth / 5}px`,
        }}
      >
        {getLongestTitle(initialData, titleProperty)}
      </div>
      <div
        className={`${styles.planTableWrapper} ${
          benefitType === OFFER_TYPE_NAMES.VOLUNTARY
            ? styles.voluntaryPlanTable
            : ''
        }`}
        style={{ marginTop: `-${height}px` }}
      >
        <div className={styles.headerListWrapper}>
          <List
            bordered
            header={
              <div
                style={{
                  height: `${
                    height +
                    (shrinkedHeader &&
                    initialData?.some((initData: any, index: number) =>
                      isNewTagApplicable(index, initData)
                    )
                      ? 30
                      : shrinkedHeader
                      ? 0
                      : 80)
                  }px`,
                }}
              ></div>
            }
            dataSource={columnData}
            renderItem={(item) => {
              let tooltipContent;
              if (typeof item?.label === 'string') {
                tooltipContent = TOOLTIP_INOUT_CONST[item?.label];
              }
              return (
                <span
                  className={`${item?.isTableBreak ? styles.tableBreak : ''} ${
                    item?.isEditPlanRow
                      ? styles.editPlanRow
                      : styles.nonEditPlanRow
                  }`}
                >
                  <List.Item>
                    <OverflowPopover>
                      {item?.label ?? '-'}
                      {tooltipContent && (
                        <Popover
                          content={<div>{tooltipContent.subtitle}</div>}
                          placement="bottomLeft"
                          overlayClassName="popoverInnerPlanTooltip"
                        >
                          <QuestionCircleOutlined
                            className={styles.serviceQuestionIconGeneral}
                          />
                        </Popover>
                      )}
                    </OverflowPopover>
                  </List.Item>
                </span>
              );
            }}
          />
        </div>
        <div ref={widthRef} className={styles.flexListWrapper}>
          {orderData?.map((item: any, index: number) => {
            let rowCount = 1;
            return (
              <div
                className={`${
                  showEndLine ? styles.singleListEndLine : styles.singleList
                } ${
                  editTable && !addPlanIndex?.includes(index)
                    ? styles.hoverEffect
                    : ''
                } ${
                  addPlanIndex?.includes(index) ? styles.addPlanEffect : ''
                } ${
                  isComparePlans &&
                  (index == 0
                    ? styles.stickyColumnCompareCurrent
                    : index == 1
                    ? styles.stickyColumnCompareSelected
                    : '')
                }`}
                key={index}
                style={{
                  width: `${customWidth}px`,
                  maxWidth: `${customWidth}px`,
                  minWidth: `${customWidth <= 198 ? 198 : flexWrapWidth / 5}px`,
                }}
              >
                {editTable || addPlanIndex?.includes(index) ? (
                  <div
                    className={styles.editPlanLink}
                    onClick={() => {
                      if (editTable) {
                        setEditModalVisible(true);
                        setSelectedData(initialData?.[index]);
                        setSelectedIndex(index);
                      }
                    }}
                    style={{
                      width: `${customWidth}px`,
                      maxWidth: `${customWidth}px`,
                      minWidth: `${
                        customWidth <= 198 ? 198 : flexWrapWidth / 5
                      }px`,
                      height: `${customHeightOne}px`,
                      maxHeight: `${customHeightOne}px`,
                      minHeight: `${
                        benefitType === VOLUNTARY_BENEFIT
                          ? customHeightTwo
                          : customHeightOne
                      }px`,
                      marginTop: `${
                        benefitType === VOLUNTARY_BENEFIT && shrinkedHeader
                          ? height + 40
                          : height + 65
                      }px`,
                      paddingTop: `${
                        benefitType === VOLUNTARY_BENEFIT
                          ? addPlanIndex?.includes(index)
                            ? listWrapHeight / 2.5
                            : listWrapHeight / (shrinkedHeader ? 22 : 6)
                          : addPlanIndex?.includes(index)
                          ? listWrapHeight / 2
                          : listWrapHeight / 2.8
                      }px`,
                      overflow: 'auto',
                    }}
                  >
                    <div
                      className={
                        addPlanIndex?.includes(index)
                          ? styles.enterPlanText
                          : styles.enterPlanTextHidden
                      }
                    >
                      Enter Plan Details
                    </div>
                    <div className={styles.editPlanText}>
                      {addPlanIndex?.includes(index) ? '' : 'Edit Plan Details'}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div
                  ref={listHeightRef}
                  className={`${styles.listInner} ${
                    isComparePlans
                      ? isSelectedPlanForComparison(index, 1)
                        ? styles.selectedPlanHeader
                        : ''
                      : ''
                  }
                  ${
                    isComparePlans &&
                    isSelectedPlanForComparison(index, 0) &&
                    styles.initialHeader
                  }
                  ${'listHeightRefDiv'}
                  `}
                >
                  <List
                    bordered
                    dataSource={item?.body}
                    header={
                      <div
                        style={{
                          height: `${
                            height +
                            (shrinkedHeader &&
                            initialData?.some(
                              (initData: any, valueIndex: number) =>
                                isNewTagApplicable(valueIndex, initData)
                            )
                              ? 30
                              : shrinkedHeader
                              ? 0
                              : 80)
                          }px`,
                        }}
                        className={styles.headerWrapper}
                      >
                        {isComparePlans ? (
                          isSelectedPlanForComparison(index, 0) ? (
                            <div className={styles.comparisonBadgeCurrent}>
                              <p className={styles.badgeTxt}>CURRENT PLAN</p>
                            </div>
                          ) : (
                            <div className={styles.comparisonBadgeAlternative}>
                              <p className={styles.badgeTxt}>
                                {data[index]?.offerPlanType ===
                                OfferType.RENEWAL
                                  ? RENEWAL_OFFER
                                  : OFFERED_PLAN}
                              </p>
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                        {item.header}
                      </div>
                    }
                    renderItem={(item: any, indexRow: number) => {
                      rowCount =
                        item?.isTableBreak || item?.isWhiteBG
                          ? 0
                          : rowCount + 1;
                      const isNotValidated =
                        item?.value?.props?.className?.includes('errorCell');

                      const isLLMDifferent =
                        !isQuotePlansApproved &&
                        item?.value?.props?.className?.includes('llmDifferent');

                      return (
                        <div
                          className={`${
                            rowCount % 2 !== 0 ? styles.oddRow : styles.evenRow
                          } ${
                            item?.isTableBreak ? styles.tableBreakBody : ''
                          } ${
                            item?.isEditPlanRow
                              ? styles.editPlanRow
                              : styles.nonEditPlanRowBody
                          }
                          ${
                            isComparePlans &&
                            index === 1 &&
                            styles.selectedPlanColumn
                          } 
                              ${
                                !addPlanIndex?.includes(index) && isLLMDifferent
                                  ? styles.llmDifferentHighlight
                                  : ''
                              }

                          ${
                            !addPlanIndex?.includes(index) && isNotValidated
                              ? styles.validateArea
                              : ''
                          }
                      
                          `}
                        >
                          <List.Item>
                            <OverflowPopover>
                              {item?.value ?? '-'}
                            </OverflowPopover>
                          </List.Item>

                          {isLLMDifferent && (
                            <AIFeedbackRedFlag
                              className={styles.aiFeedbackLabel}
                            />
                          )}
                        </div>
                      );
                    }}
                  />
                  {isCompareSimilarPlansApplicable &&
                    !isComparePlans &&
                    benefitType !== BenefitCategory.VOLUNTARY_BENEFIT.value &&
                    !editTable &&
                    !data[index]?.newPlan && (
                      <PageActionButton
                        className={styles.offerDetailsButton}
                        onClick={() => {
                          setSelectedPlanIndex(index);
                          setSelectedPlanId(data[index]?.id);
                          setComparePlansVisible(true);
                        }}
                      >
                        Compare Similar Plans
                      </PageActionButton>
                    )}
                </div>
                <div
                  className={
                    addPlanIndex?.includes(index)
                      ? styles.addPlanFooterEffect
                      : ''
                  }
                >
                  {isComparePlans ? (
                    index != 0 ? (
                      <Row className={styles.offerDetailContainer}>
                        <p
                          className={styles.offerDetailText}
                          onClick={() => {
                            if (handleOfferDetailClick) {
                              const bundleId: any = data[index].bundleId;
                              handleOfferDetailClick(bundleId);
                            }
                          }}
                        >
                          Offer Details
                          <span className={styles.offerDetailIcon}>
                            <OfferDetailIcon />
                          </span>
                        </p>
                      </Row>
                    ) : index == 0 ? (
                      <Row className={styles.offerDetailContainerInitial}></Row>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                  {addPlan && addPlanIndex?.includes(index) ? (
                    <div
                      className={styles.addPlanFooter}
                      onClick={() => {
                        if (editTable) {
                          setEditModalVisible(true);
                          setSelectedData(initialData?.[index]);
                          setSelectedIndex(index);
                        }
                      }}
                      style={{
                        width: `${customWidth}px`,
                        maxWidth: `${customWidth}px`,
                        minWidth: `${
                          customWidth <= 198 ? 198 : flexWrapWidth / 5
                        }px`,
                        height: `${100}px`,
                        maxHeight: `${100}px`,
                        minHeight: `${100}px`,
                        overflow: 'auto',
                      }}
                    ></div>
                  ) : (
                    ''
                  )}
                  {editTable && mapToPlan ? (
                    <div className={styles.footerPlans}>
                      <List
                        bordered
                        dataSource={item?.footer}
                        renderItem={(item: any, indexRow: number) => {
                          rowCount =
                            item?.isTableBreak || item?.isWhiteBG
                              ? 0
                              : rowCount + 1;
                          return (
                            <span
                              className={`${
                                rowCount % 2 !== 0
                                  ? styles.oddRow
                                  : styles.evenRow
                              } ${
                                item?.isTableBreak ? styles.tableBreakBody : ''
                              } ${
                                item?.isEditPlanRow
                                  ? styles.editPlanRow
                                  : styles.nonEditPlanRowBody
                              }${
                                addPlanIndex?.includes(index)
                                  ? ''
                                  : styles.validateArea
                              }`}
                            >
                              <List.Item>
                                <OverflowPopover>
                                  {item?.value
                                    ? isNumeric(item?.value)
                                      ? `$${item?.value}`
                                      : Array.isArray(item?.value)
                                      ? addDollarSignsForArray(item?.value)
                                      : item?.value
                                    : '-'}
                                </OverflowPopover>
                              </List.Item>
                            </span>
                          );
                        }}
                      />
                      <div
                        style={{
                          width: `${customWidth}px`,
                          maxWidth: `${customWidth}px`,
                          minWidth: `${
                            customWidth <= 198 ? 197 : flexWrapWidth / 5
                          }px`,
                        }}
                        className={`${styles.filterCell} ${
                          styles.filterCellBorder
                        } ${
                          isMappedPlanError(index) ? styles.mappedPlanError : ''
                        }`}
                      >
                        <Row
                          justify="center"
                          onClick={() => {
                            setSelectedIndex(index);
                            setSelectedData(initialData?.[index]);
                          }}
                        >
                          <SingleSelectFilter
                            data={filterMappedPlans(
                              mapPlanDroDownData,
                              setSelectedCurrentPlan(index)
                            )}
                            placeholder={'-'}
                            showSearch={true}
                            setFilterSelectedValue={
                              handleCurrentPlanSelectToEachPlan
                            }
                            isDisabled={isOpsAdmin}
                            defaultValue={''}
                            selectedValue={setSelectedCurrentPlan(index)}
                            onMouseEnter={() => setSelectedIndex(index)}
                            inlineDropdown={true}
                            searchPlaceholder={'Search Plans'}
                            isWiderWrapper={true}
                            applicableIcon={<DownOutlined />}
                            width={'180px'}
                            allowClear
                            planError={isMappedPlanError(index)}
                          />
                        </Row>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            );
          })}
          {orderData?.length < 5 && addPlan && (
            <AddPlanButton
              fixedWidth={fixedWidth}
              flexWrapWidth={flexWrapWidth}
              listWrapHeight={listWrapHeight}
              height={height}
              benefitType={benefitType}
              addButtonRef={addButtonRef}
              handleAddPlan={handleAddPlan}
              handleManualPlanAdd={handleManualPlanAdd}
              disabled={isOpsAdmin || validateFormatting}
            />
          )}
        </div>
        {orderData?.length >= 5 && addPlan && (
          <AddPlanButton
            fixedWidth={fixedWidth}
            flexWrapWidth={flexWrapWidth}
            listWrapHeight={listWrapHeight}
            height={height}
            benefitType={benefitType}
            addButtonRef={addButtonRef}
            handleAddPlan={handleAddPlan}
            handleManualPlanAdd={handleManualPlanAdd}
            disabled={isOpsAdmin || validateFormatting}
          />
        )}
      </div>
      <AddPlanConfirmModal
        setVisible={setAddCloneOfferPlanVisible}
        visible={addCloneOfferPlanVisible}
        addedPlans={data ?? []}
        setUpdateData={changeUpdatedData}
        addPlanButtonRef={addButtonRef}
        mapDropdownSelectedValues={mapDropdownSelectedValues}
        mapDropDownDataOnChange={mapDropDownDataOnChange}
      />
      <AddPlanDetailsModal
        visible={editModalVisible}
        onClose={setEditModalVisible}
        data={transformTablePlanDataToEditData({
          plan: initialData?.[selectedDataIndex],
          benefitType: benefitType!,
        })}
        setUpdateData={setUpdatedData}
        dataIndex={1}
        benefitType={benefitType ?? ''}
        addIndexList={setAddPlanIndex && setAddPlanIndex}
        indexList={addPlanIndex}
        showValidationsOnLoad={
          showValidationsOnLoad &&
          !(addPlanIndex ?? []).includes(selectedDataIndex)
        }
        updatedData={data}
        triggerAllValidations={triggerAllValidations}
        showRequired={showRequired}
        premiumType={premiumType}
        nTierCount={nTierCount}
        isImportPlans={isImportPlans}
        isNoCurrentPlans={isNoCurrentPlans}
        isProposalsAttached={isProposalsAttached}
        fundingType={fundingType}
        isQuoteProcessingEnabled={isQuoteProcessingEnabled}
        extractedDocument={extractedDocument}
        isQuotePlansApproved={isQuotePlansApproved}
        showFlagItemWarning={showFlagItemWarning}
      />
      <ConfirmationDialog
        visible={deleteModalVisible}
        confirmText={'Delete Plan'}
        cancelText="Cancel"
        isCancelLink
        title={'Delete Plan'}
        buttonsExpanded={true}
        destroyOnClose={true}
        closeModal={() => setDeleteModalVisible(false)}
        onCancel={() => setDeleteModalVisible(false)}
        onConfirm={() => handlePlanDelete()}
        maskClosable={false}
      >
        Are you sure you want to delete {selectedData?.name} ?
      </ConfirmationDialog>
      {!isComparePlans && comparePlansVisible && !editTable && (
        <ComparePlans
          visible={comparePlansVisible}
          onClose={setComparePlansVisible}
          initialSelectedPlanIndex={selectedPlanIndex}
          offer={offer ? offer : {}}
          plansData={data}
          selectedPlanId={selectedPlanId}
          benefitType={benefitType}
          offerStatus={offerStatus}
          fundingType={offer?.fundingType || FundingType.FULLY_INSURED}
        />
      )}
    </StandardErrorBoundary>
  );
};

export default PlanTable;
