import BenefitCode from './BenefitCode';
import NetworkBasedCostSharing from './NetworkBasedCostSharing';

/**
 * keeping as a class instead of type, because we need to instantiate
 * new CustomPlan() Objects (with all the nested objects) when binding data to the UI
 */
class CustomPlan {
  id: string;
  planId: string;
  benefitCode: BenefitCode;
  serviceValue: NetworkBasedCostSharing;
  source: string;
  createdTs: Number;
  lastUpdatedTs: Number;
  isDefault?: boolean;
  added?: boolean;
  manualAdded?: boolean;

  constructor() {
    this.id = '';
    this.planId = '';
    this.benefitCode = new BenefitCode();
    this.serviceValue = new NetworkBasedCostSharing();
    this.source = '';
    this.createdTs = 0;
    this.lastUpdatedTs = 0;
    this.added = false;
    this.manualAdded = false;
  }
}

export default CustomPlan;
