import { FC, useEffect, useState, useCallback } from 'react';
import { StompSessionProvider } from 'react-stomp-hooks';
import { useParams, useNavigate } from 'react-router-dom';

import { Col, Row } from 'antd';

import { useNavContext } from 'hooks/useNavContext';
import { usePermitIf } from 'hooks/usePermitIf';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import EnrollmentOverview from 'modules/plans/components/Enrollments/EnrollmentOverview/EnrollmentOverview';
import BasicPlanInfoOverview from 'modules/plans/components/OverviewPage/BasicPlanInfoOverview/BasicPlanInfoOverview';
import PremiumsOverview from 'modules/plans/components/OverviewPage/PremiumsOverview/PremiumsOverview';
import ServicesOverview from 'modules/plans/components/OverviewPage/ServicesOverview/ServicesOverview';
import PlanDocumentsOverview from 'modules/plans/components/OverviewPage/PlanDocumentsOverview/PlanDocumentOverview';
import PlanCollaborationNotification from 'components/PlanCollaborationNotification/PlanCollaborationNotification';
import HeaderNavigation from 'modules/plans/components/HeaderNavigation/HeaderNavigation';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import { allowedCommonButtonPermittedTypes } from 'constants/permittedConstants';
import { BenefitCategory } from 'constants/commonConstants';
import { wsBaseApi } from 'util/apiUtil';
import {
  findVisionPlanById,
  getVisionPlanServicesList,
} from 'modules/plans/slices/visionPlanSlice';

import styles from './visionOverviewPage.module.less';

const VisionOverviewPage: FC = () => {
  const params = useParams();

  const { employerId, brokerId } = useNavContext();

  const dispatch = useAppDispatch();

  const { visionPlan, editedVisionPlan, visionPlanServiceList, inProgress } =
    useAppSelector((state) => state.plan.visionPlan);

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const navigate = useNavigate();

  const [viewPlanModal, setViewPlanModal] = useState(false);
  const [viewPlanEnrollmentModal, setViewEnrollmentModal] = useState(false);
  const [viewPlanPremiumModal, setViewPlanPremiumModal] = useState(false);
  const [viewPlanServicesModal, setViewPlanServicesModal] = useState(false);
  const [viewPlanDocumentsModal, setViewPlanDocumentsModal] = useState(false);

  const [disableEditButton, setDisableEditButton] = useState(false);
  const [fetchPlanWhenUpdated, setFetchPlanWenUpdated] =
    useState<boolean>(false);

  const refreshPlan = useCallback(() => {
    if (employerId && brokerId && params.planId) {
      dispatch(findVisionPlanById(params.planId));
    }
    setFetchPlanWenUpdated(false);
  }, [dispatch, employerId, brokerId, params.planId]);

  useEffect(() => {
    refreshPlan();
  }, [refreshPlan]);

  useEffect(() => {
    dispatch(
      getVisionPlanServicesList(BenefitCategory.VISION.value, employerId || '')
    );
  }, [dispatch, employerId]);

  const getDefaultVisionServices = () => {
    return visionPlanServiceList.defaults
      .filter((service) => service.isDefault && service.isSaasSupported)
      .map((service) => ({ shortName: service.shortName, code: service.code }));
  };

  const exitPlan = () => {
    navigate(`/brokers/${brokerId}/employers/${employerId}/vision`);
  };

  const updateViewPlanModal = (value: boolean) => {
    setViewPlanModal(value);
  };

  const updateViewPlanPremiumModal = (value: boolean) => {
    setViewPlanPremiumModal(value);
  };

  const updateViewPlanServicesModal = (value: boolean) => {
    setViewPlanServicesModal(value);
  };

  const updateViewPlanEnrollment = (value: boolean) => {
    setViewEnrollmentModal(value);
  };

  const updateViewPlanDocumentsModal = (value: boolean) => {
    setViewPlanDocumentsModal(value);
  };

  const navigation = {
    name: 'All Vision Plans',
    link: `/brokers/${brokerId}/employers/${employerId}/vision`,
  };

  const securedBreadcrumb = usePermitIf(
    <BreadcrumbNavigation breadCrumbData={[navigation]} />,
    allowedCommonButtonPermittedTypes,
    []
  );

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col span={24}>
          <Row>{securedBreadcrumb}</Row>
          <Row>
            <Col span={18}>
              <h1 className={styles.pageTitle}>
                {visionPlan?.name && (
                  <OverflowPopover>{`${visionPlan.name}`}</OverflowPopover>
                )}
              </h1>
            </Col>
          </Row>
          <Row>
            <HeaderNavigation
              headerItems={[
                {
                  headerName: 'Basic Plan Info',
                  headerNavigation: '#Plan-Info',
                },
                {
                  headerName: 'Premiums & Contributions',
                  headerNavigation: '#Premium',
                },
                { headerName: 'Services', headerNavigation: '#Service' },
                {
                  headerName: 'Enrollments',
                  headerNavigation: '#Enrollments',
                },
                { headerName: 'Plan Resources', headerNavigation: '#Plan_Doc' },
              ]}
            />
          </Row>
          <div className={styles.overview} id={'Plan-Info'}>
            <StompSessionProvider
              url={`${wsBaseApi}/websocket-plan-update`}
              onWebSocketError={(e) => console.error(e)}
              reconnectDelay={500}
            >
              <PlanCollaborationNotification
                loggedInUserIndividualId={appBootInfo?.individualId}
                exitPlan={() => exitPlan()}
                refreshPlan={() => refreshPlan()}
                planId={visionPlan.name}
                isInEdit={
                  viewPlanModal ||
                  viewPlanPremiumModal ||
                  viewPlanServicesModal ||
                  viewPlanEnrollmentModal ||
                  viewPlanDocumentsModal
                }
                disableEdit={() => setDisableEditButton(true)}
                fetchPlan={() => setFetchPlanWenUpdated(true)}
              />
            </StompSessionProvider>
            <BasicPlanInfoOverview
              heading={'Basic Plan Info'}
              planName={visionPlan.name}
              planYearName={visionPlan.planYear?.name}
              benefitClasses={visionPlan.groups}
              carrierName={
                visionPlan.benefitCarrier ? visionPlan.benefitCarrier?.name : ''
              }
              planNetwork={visionPlan.planNetworkName}
              type={'VISION'}
              viewPlanModal={viewPlanModal}
              setViewPlanModal={updateViewPlanModal}
              disableEditButton={disableEditButton}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              exitPlan={() => exitPlan()}
              fundingType={visionPlan.fundingType}
              administrationFee={visionPlan.administrationFee}
              groupId={visionPlan.groupId}
              hraEligibile={visionPlan.hraCompatible}
              planType={visionPlan.type}
            />
          </div>
          <div className={styles.overview} id={'Premium'}>
            <PremiumsOverview
              heading={'Premiums & Contributions'}
              plan={visionPlan}
              benefitKind={BenefitCategory.VISION.value}
              disableEditButton={disableEditButton}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              editedPlan={editedVisionPlan}
              viewPlanPremiumModal={viewPlanPremiumModal}
              setViewPlanPremiumModal={(value) =>
                updateViewPlanPremiumModal(value)
              }
            />
          </div>
          <div className={styles.overview} id={'Service'}>
            <ServicesOverview
              heading={'Services'}
              plan={visionPlan}
              benefitKind={BenefitCategory.VISION.value}
              disableEditButton={disableEditButton}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              defaultServices={getDefaultVisionServices()}
              isLoading={inProgress}
              viewPlanServicesModal={viewPlanServicesModal}
              setViewPlanServicesModal={(value) =>
                updateViewPlanServicesModal(value)
              }
            />
          </div>{' '}
          <div className={styles.overview} id={'Enrollments'}>
            <EnrollmentOverview
              heading={'Enrollments'}
              plan={visionPlan}
              editedPlan={editedVisionPlan}
              benefitKind={BenefitCategory.VISION.value}
              disableEditButton={disableEditButton}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              viewPlanEnrollmentModal={viewPlanEnrollmentModal}
              setViewPlanEnrollmentModal={(value) =>
                updateViewPlanEnrollment(value)
              }
            />
          </div>
          <div className={styles.overview} id={'Plan_Doc'}>
            <PlanDocumentsOverview
              heading={'Plan Resources'}
              plan={visionPlan}
              documents={visionPlan.documents}
              benefitKind={BenefitCategory.VISION.value}
              disableEditButton={disableEditButton}
              viewPlanDocumentsModal={viewPlanDocumentsModal}
              setViewPlanDocumentsModal={(value) =>
                updateViewPlanDocumentsModal(value)
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VisionOverviewPage;
