import axios from 'axios';
import { baseApi, getPagingQueryString } from 'util/apiUtil';
import ErrorDetails from 'model/ErrorDetails';

export const getBrokerList = async (
  page: number,
  size: number,
  sort: string,
  query: string
) => {
  try {
    return axios.get(
      `${baseApi}/v2/organizations?${getPagingQueryString(
        page,
        size,
        sort,
        query,
        'query'
      )}`
    );
  } catch (error) {
    throw error;
  }
};

export const createBroker = (data: any) => {
  return axios.post(`${baseApi}/v2/organizations`, data);
};

export const uploadBrokerLogo = (image: any, original?: boolean) => {
  const formData = new FormData();
  formData.append('file', image);
  if (original) {
    formData.append('original', 'true');
  }
  return axios.post(`${baseApi}/v2/organizations/logo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const validateBroker = async (organizationName: string) => {
  if (organizationName) {
    try {
      await axios.post(
        `${baseApi}/v2/organizations/validate?organizationName=${organizationName}`
      );
    } catch (error: any) {
      if (error.response) {
        return { data: error.response.data } as ErrorDetails;
      } else {
        // TODO: need proper error handling
        console.log('Exception thrown ' + JSON.stringify(error));
      }
    }
  }
  return null;
};

export const validateEmailDomain = async (
  emailDomains: string,
  organizationId: string
) => {
  if (emailDomains) {
    try {
      await axios.post(
        `${baseApi}/v2/organizations/email-domain/validate?email-domains=${emailDomains}&organizationId=${organizationId}`
      );
    } catch (error: any) {
      if (error.response) {
        return { data: error.response.data } as ErrorDetails;
      } else {
        // TODO: need proper error handling
        console.error('Exception thrown ' + JSON.stringify(error));
      }
    }
  }
  return null;
};

export const getBrokerListWithoutPagination = async () => {
  return axios.get(`${baseApi}/v1/organizations/list`);
};

export const findBroker = (brokerId: string) => {
  return axios.get(`${baseApi}/v2/organizations/${brokerId}`);
};

export const updateBroker = (brokerId: string, data: any) => {
  return axios.put(`${baseApi}/v2/organizations/${brokerId}`, data);
};

export const getBrokerDetails = (organizationId: string) => {
  return axios.get(`${baseApi}/v2/organizations/${organizationId}/summary`);
};

export const getBrokerCount = () => {
  return axios.get(`${baseApi}/v2/organizations/count`);
};

export const getEmployersByOrganizationId = (organizationId: string) => {
  return axios.get(`${baseApi}/v2/organizations/${organizationId}/employers`);
};
