import { FC, useState } from 'react';
import { Row } from 'antd';
import moment from 'moment';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { ReactComponent as Bill } from 'assets/images/bill.svg';
import { useNavContext } from 'hooks/useNavContext';
import { IndividualSubTypes } from 'constants/commonConstants';
import { useAppSelector } from 'hooks/redux';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import BillingView from './BillingView';
import styles from './noBilling.module.less';

type NoBillingViewProps = {
  isUploaderOpen: Function;
  isBillingManageOpen: Function;
  isDraftsAvaialble: Boolean;
};

const NoBillingView: FC<NoBillingViewProps> = (props) => {
  const { isUploaderOpen, isDraftsAvaialble, isBillingManageOpen } = props;
  const { employer } = useNavContext();
  const [invoiceMonth, setInvoiceMonth] = useState<any>(moment());
  const [monthlyTrendFrom, setmonthlyTrendFrom] = useState<any>(
    moment('01/01' + '/' + moment().year())
  );
  const [monthlyTrendTo, setmonthlyTrendTo] = useState<any>(
    moment('12/01' + '/' + moment().year())
  );

  const individualSubType = useAppSelector(
    (state) => state?.auth?.auth?.appBootupInfo?.individualSubType
  );

  return (
    <>
      <div className={styles.billingEmployerName}>
        <h1>{employer?.name} Billing</h1>
      </div>
      <div className={styles.noBillingPreviewContent}>
        <BillingView
          invoiceDate={invoiceMonth}
          setInvoiceDate={setInvoiceMonth}
          isNoBillinView={true}
          monthlyTrendFrom={monthlyTrendFrom}
          setMonthlyTrendFrom={setmonthlyTrendFrom}
          monthlyTrendTo={monthlyTrendTo}
          setmonthlyTrendTo={setmonthlyTrendTo}
          avaiableMonthsData={[]}
          chartData={false}
        />
      </div>
      <div className={styles.noUploadContent}>
        <div className={styles.noBillingView}>
          <div className={styles.noBillingViewWrapper}>
            <Row className={styles.description}>
              <Bill />
            </Row>
            <Row className={styles.description}>
              <h2>
                {isDraftsAvaialble
                  ? 'You don’t have any published billing data'
                  : 'You don’t have any Billing Data'}
              </h2>
            </Row>
            <Row className={styles.description}>
              <p>
                {isDraftsAvaialble
                  ? 'Start by managing billing data below:'
                  : 'Start by importing billing data below:'}
              </p>
            </Row>
            <Row className={styles.description}>
              {[
                IndividualSubTypes.EMPLOYER_MEMBER,
                IndividualSubTypes.EMPLOYER_ADMIN,
              ].includes(individualSubType!) ? (
                <PlanyearPopover content="Only admins may import billing data">
                  <PageActionButton
                    type="primary"
                    className={styles.uploadBillingButtonDisabled}
                  >
                    Import Billing Files
                  </PageActionButton>
                </PlanyearPopover>
              ) : (
                <PageActionButton
                  type="primary"
                  onClick={() => {
                    isDraftsAvaialble
                      ? isBillingManageOpen(true)
                      : isUploaderOpen(true);
                  }}
                  className={styles.uploadBillingButton}
                >
                  {isDraftsAvaialble
                    ? 'Manage Billing Data'
                    : 'Import Billing Files'}
                </PageActionButton>
              )}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoBillingView;
