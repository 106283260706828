import axios from 'axios';
import moment from 'moment';
import { lumityApi } from 'api/apiSlice';
import { baseApi, CONTENT_TYPE_MULTIPART_FORM } from 'util/apiUtil';
import { OpenOffersQueryParams } from 'modules/renewals/types/renewalsTypes';
import { OfferNoteDto } from 'modules/renewals/models/OfferNoteDto';
import { ProposalNoteDto } from 'modules/renewals/models/ProposalNoteDto';

const RENEWAL_OFFERS_API_PREFIX = 'v1/renewals/offer-proposals';
const OFFERS_API_PREFIX = 'v1/renewals/offers';
const QUOTES_PARSING_API_PREFIX = 'v1/renewals/quotes';
const INDIVIDUAL_PREFERENCE_PARSING_API_PREFIX = 'v2/individuals/preferences';

const offersAPI = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadImportOffersFile: builder.mutation<
      any,
      { employerId: string; file: File | null }
    >({
      query: (args) => {
        const { employerId, file } = args;
        const formData = new FormData();
        formData.append('file', file ?? '');
        return {
          url: `${RENEWAL_OFFERS_API_PREFIX}/parse`,
          method: 'POST',
          headers: {
            'content-type': CONTENT_TYPE_MULTIPART_FORM,
          },
          data: formData,
          params: {
            employerId,
          },
        };
      },
    }),
    importOffersPreview: builder.query<
      any,
      { offerDocumentId: string; employerId: string; status: string }
    >({
      query: (args) => {
        const { offerDocumentId, employerId, status } = args;
        return {
          url: `${OFFERS_API_PREFIX}/offer-documents/${offerDocumentId}`,
          method: 'GET',
          params: {
            'employer-id': employerId,
            status: status,
          },
        };
      },
    }),
    publishOrDraftOffer: builder.mutation<
      any,
      {
        offerDocumentId: string;
        saveStatus: string;
        benefitCategory?: string;
        overWriteOffers?: boolean;
        editOfferId?: boolean;
        isNotificationActive?: boolean;
      }
    >({
      query: ({
        offerDocumentId,
        saveStatus,
        benefitCategory,
        overWriteOffers,
        editOfferId,
        isNotificationActive,
      }) => {
        return {
          url: `v1/renewals/offers/document/${offerDocumentId}/status/${saveStatus}`,
          method: 'PUT',
          params: {
            benefitCategory: benefitCategory,
            overWriteOffers: overWriteOffers,
            'edit-offer-id': editOfferId,
            'is-notification-active': isNotificationActive,
          },
        };
      },
    }),
    getDocumentExtractionById: builder.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `v1/document-extraction/${id}`,
        method: 'GET',
      }),
    }),
    getDetailedDocumentExtractionById: builder.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `v1/document-extraction/carrier-quote/detailed/${id}`,
        method: 'GET',
      }),
    }),
    getOfferedPlanProcessingStatusByOfferId: builder.query<
      any,
      { offerId: string; timestamp?: any }
    >({
      query: ({ offerId, timestamp }) => ({
        url: `v1/document-extraction/carrier-quote/offers/${offerId}/status?ts=${
          timestamp ?? new Date().getTime()
        }`,
        method: 'GET',
      }),
    }),
    getPlanDetails: builder.query<any, { offerId: string }>({
      query: ({ offerId }) => {
        return {
          url: `v1/renewals/offers/${offerId}/details?ts=${new Date().getTime()}`,
          method: 'GET',
        };
      },
    }),
    getOffersByEffectiveDate: builder.query<
      any,
      {
        employerId: string;
        category: string;
        upcomingPlanYearId: string;
        basic?: boolean;
      }
    >({
      query: (args) => {
        const {
          employerId,
          category,
          upcomingPlanYearId,
          basic = false,
        } = args;
        return {
          url: `v1/renewals/offers/find-by-effective-date?employerId=${employerId}&category=${category}&upcomingPlanYearId=${upcomingPlanYearId}&basic=${basic}`,
          method: 'GET',
        };
      },
    }),
    getOfferExists: builder.query<
      { existsPublishedOffer: boolean; existsDraftOffer: boolean },
      {
        status?: string;
        employerId: string;
        name?: string;
        category?: string;
        offerId?: string;
      }
    >({
      query: ({ status, employerId, name, category, offerId }) => {
        return {
          url: `v1/renewals/offers/exists`,
          method: 'GET',
          params: {
            'employer-id': employerId,
            status: status,
            name: name,
            category: category,
            'offer-id': offerId,
          },
        };
      },
    }),
    getOpenOffers: builder.query<any, OpenOffersQueryParams>({
      query: (args) => {
        const {
          statuses,
          employernames,
          types,
          categories,
          searchKeyValue,
          carriers,
          page,
          brokers,
          size,
          sort,
          lastmodified,
          renewalDates,
          bidstatus,
          isAllEmployerSelected,
        } = args;

        return {
          url: `v1/renewals/offers/open-offers/summary`,
          method: 'POST',
          params: {
            _page: page,
            _size: size,
            _sort: sort,
          },
          data: {
            organizations: brokers,
            statuses: statuses,
            employers: employernames,
            types: types,
            categories: categories,
            carriers: carriers,
            query: searchKeyValue,
            lastModify: lastmodified,
            offerStatus: bidstatus,
            renewalDates: renewalDates,
            isAllEmployerSelected: isAllEmployerSelected,
          },
        };
      },
    }),
    getContributionsByOffer: builder.query<
      any,
      { category: string; proposalId: string; employerId: string | undefined }
    >({
      query: ({ category, employerId, proposalId }) => {
        return {
          url: `v1/renewals/offer-proposals/${proposalId}/contributions`,
          method: 'GET',
          params: {
            category,
            employerId,
          },
        };
      },
    }),
    calculateModelingContribution: builder.mutation<
      any,
      { baseModelingDto: any; proposalId: string; isRoundOff?: boolean }
    >({
      query: (data) => {
        const { proposalId, baseModelingDto, isRoundOff } = data;
        return {
          url: `v1/renewals/offer-proposals/${proposalId}/calculations`,
          method: 'POST',
          data: baseModelingDto,
          params: {
            'is-round-off': isRoundOff,
          },
        };
      },
    }),
    updateBundleContribution: builder.mutation<
      any,
      {
        baseModelingDto: any;
        proposalId: string;
        employerId: any;
        isVersion?: boolean;
      }
    >({
      query: (data) => {
        const {
          proposalId,
          baseModelingDto,
          employerId,
          isVersion = false,
        } = data;
        return {
          url: `v1/renewals/offer-proposals/${proposalId}/contributions`,
          method: 'PUT',
          data: baseModelingDto,
          params: {
            employerId,
            'version-record': isVersion,
          },
        };
      },
    }),
    updateOfferNoteAsViewed: builder.mutation<
      any,
      { offerId: string; individualId: string }
    >({
      query: (data) => {
        const { offerId, individualId } = data;
        return {
          url: `v1/renewals/offers/${offerId}/offer-notes/${individualId}`,
          method: 'PUT',
        };
      },
    }),
    updateProposalNoteAsViewed: builder.mutation<
      any,
      { proposalId: string; individualId: string }
    >({
      query: (data) => {
        const { proposalId, individualId } = data;
        return {
          url: `v1/renewals/offer-proposals/${proposalId}/proposal-notes/${individualId}`,
          method: 'PUT',
        };
      },
    }),
    updateOfferStatusField: builder.mutation<
      any,
      { offerId: string; offerStatusField: string }
    >({
      query: ({ offerId, offerStatusField }) => {
        return {
          url: `v1/renewals/offers/${offerId}/status-field`,
          method: 'PUT',
          params: { 'status-field': offerStatusField },
        };
      },
    }),
    updateOfferStatusAsRecommended: builder.mutation<
      any,
      { offerId: string; isRecommended: boolean }
    >({
      query: ({ offerId, isRecommended }) => {
        return {
          url: `v1/renewals/offers/${offerId}/status-update?recommended=${isRecommended}`,
          method: 'PUT',
        };
      },
    }),
    getPrimaryCarrierRecommendedStatus: builder.query<any, string>({
      query: (offerId) => {
        return {
          url: `v1/renewals/offers/${offerId}/recommended/primary-carrier-status?ts=${new Date().getTime()}`,
          method: 'GET',
        };
      },
    }),
    getOfferImportHistory: builder.query<
      any,
      { employerId: string; search: string; page: number; size: number }
    >({
      query: ({ employerId, search, page, size }) => {
        return {
          url: `v1/renewals/offer-files/history-logs`,
          method: 'GET',
          params: {
            'employer-id': employerId,
            search: search,
            _page: page,
            _size: size,
          },
        };
      },
    }),
    uploadAndParseCarrierQuote: builder.mutation<
      any,
      {
        employerId: string;
        file: File | null;
        carrierId: string;
        category: string;
        origanizationId: string;
      }
    >({
      query: (args) => {
        const { employerId, file, carrierId, category, origanizationId } = args;
        const formData = new FormData();
        formData.append('file', file ?? '');
        return {
          url: `${QUOTES_PARSING_API_PREFIX}/parse`,
          method: 'POST',
          headers: {
            'content-type': CONTENT_TYPE_MULTIPART_FORM,
          },
          data: formData,
          params: {
            employerId,
            carrierId,
            category,
            origanizationId,
          },
        };
      },
    }),
    createOffer: builder.mutation<
      any,
      {
        offer: any;
        draft?: boolean;
        editOfferId?: string | null;
        saveStatus?: string;
        jobId?: string | undefined | null;
        isOpsAdmin?: boolean;
        shouldInitializeQuote?: boolean;
        preventDocExtractionDetailsUpdate?: boolean;
      }
    >({
      query: (args) => {
        const {
          offer,
          draft = false,
          editOfferId,
          saveStatus = 'DRAFT',
          jobId = null,
          isOpsAdmin = false,
          shouldInitializeQuote = false,
          preventDocExtractionDetailsUpdate = false,
        } = args;
        return {
          url: `${OFFERS_API_PREFIX}/with-plan-details`,
          method: 'POST',
          data: offer,
          params: {
            'save-partial-plan': draft,
            'edit-offer-id': editOfferId,
            'save-status': saveStatus,
            'document-extraction-id': jobId,
            'finalize-quote': isOpsAdmin ?? false,
            'should-initialize': shouldInitializeQuote,
            'prevent-doc-extraction-updates': preventDocExtractionDetailsUpdate,
          },
        };
      },
    }),
    getCurrentPlans: builder.query<
      any,
      {
        employerId: string;
        benefitType: string;
        offerStatus: string;
      }
    >({
      query: (args) => {
        const { employerId, benefitType, offerStatus } = args;
        return {
          url: `v1/renewals/offers/current-plans`,
          method: 'GET',
          params: {
            'employer-id': employerId,
            'benefit-category': benefitType,
            status: offerStatus,
          },
        };
      },
    }),
    updateOpenOffersFilter: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: INDIVIDUAL_PREFERENCE_PARSING_API_PREFIX,
          method: 'PUT',
          data: data,
        };
      },
    }),
    getOpenOffersFilter: builder.query<any, string>({
      query: (id) => {
        return {
          url: `${INDIVIDUAL_PREFERENCE_PARSING_API_PREFIX}/${id}`,
          method: 'GET',
        };
      },
    }),
    getQuoteParsingStatus: builder.query<
      any,
      {
        jobId: string;
      }
    >({
      query: (args) => {
        const { jobId } = args;
        return {
          url: `v1/renewals/quotes/parse/${jobId}/status`,
          method: 'GET',
        };
      },
    }),
    getParsedQuote: builder.query<
      any,
      {
        jobId: string;
      }
    >({
      query: (args) => {
        const { jobId } = args;
        return {
          url: `v1/renewals/quotes/parse/${jobId}`,
          method: 'GET',
        };
      },
    }),
    deleteOffer: builder.query<any, { offerId: string }>({
      query: ({ offerId }) => {
        return {
          url: `${OFFERS_API_PREFIX}/${offerId}/delete`,
          method: 'DELETE',
        };
      },
    }),
    existProposalByOffer: builder.query<
      any,
      { offerId: string; currentTs: number }
    >({
      query: (args) => {
        const { offerId, currentTs } = args;
        return {
          url: `${OFFERS_API_PREFIX}/${offerId}/exists`,
          method: 'GET',
          params: {
            currentTs: currentTs,
          },
        };
      },
    }),
    uploadCarrierDocument: builder.mutation<
      any,
      {
        employerId: string;
        offerId: string;
        carrierDocumentType: string;
        file: File | null;
      }
    >({
      query: (args) => {
        const { employerId, offerId, carrierDocumentType, file } = args;
        const formData = new FormData();
        formData.append('file', file ?? '');
        return {
          url: `v1/renewals/offer-files/carrier-documents`,
          method: 'POST',
          headers: {
            'content-type': CONTENT_TYPE_MULTIPART_FORM,
          },
          data: formData,
          params: {
            'employer-id': employerId,
            'offer-id': offerId,
            'carrier-document': carrierDocumentType,
          },
        };
      },
    }),
    downloadCarrierDocument: builder.query<
      any,
      {
        offerId: string;
        carrierDocumentType: string;
      }
    >({
      query: (args) => {
        const { offerId, carrierDocumentType } = args;
        return {
          url: `v1/renewals/offer-files/offer/${offerId}/carrier-documents/${carrierDocumentType}`,
          method: 'GET',
        };
      },
    }),
    deleteCarrierDocument: builder.mutation<
      any,
      {
        offerId: string;
        carrierDocumentType: string;
      }
    >({
      query: (args) => {
        const { offerId, carrierDocumentType } = args;
        return {
          url: `v1/renewals/offer-files/offer/${offerId}/carrier-documents/${carrierDocumentType}`,
          method: 'DELETE',
        };
      },
    }),
    comparePlans: builder.query<
      any,
      {
        category: string;
        planId: string;
        employerId: any;
        planYearId: string;
        offerStatus: string;
      }
    >({
      query: (args) => {
        const { employerId, planYearId, planId, category, offerStatus } = args;
        return {
          url: `${OFFERS_API_PREFIX}/plan-compare/${planId}/benefit-category/${category}`,
          method: 'GET',
          params: {
            'employer-id': employerId,
            'plan-year-id': planYearId,
            status: offerStatus,
          },
        };
      },
    }),
    downloadTemplateFile: builder.query<any, { employerId: string }>({
      query: ({ employerId }) => {
        return {
          url: `${OFFERS_API_PREFIX}/document/template`,
          method: 'GET',
          params: { employerId },
        };
      },
    }),
    allPlans: builder.query<
      any,
      {
        employerId: string;
        type: string;
        benefitType: string;
        tierType: string;
        tierCount: number;
      }
    >({
      query: (args) => {
        const { employerId, type, benefitType, tierType, tierCount } = args;
        return {
          url: `v1/plans/${type}/current-plans`,
          method: 'GET',
          params: {
            'employer-id': employerId,
            'benefit-category': benefitType,
            'rate-type': tierType,
            'tier-count': tierCount,
          },
        };
      },
    }),
    getInitialContributionsByOffer: builder.query<
      any,
      { category: string; proposalId: string; employerId: string | undefined }
    >({
      query: ({ category, employerId, proposalId }) => {
        return {
          url: `v1/renewals/offer-proposals/${proposalId}/initial-contribution`,
          method: 'GET',
          params: {
            category,
            employerId,
          },
        };
      },
    }),
    addMedicalSystemPlan: builder.query<
      any,
      {
        id: string;
        type: string;
      }
    >({
      query: (args) => {
        const { id, type } = args;
        return {
          url: `v1/renewals/offers/system-plans/${type}/${id}`,
          method: 'GET',
        };
      },
    }),
    getBenefitCarrierById: builder.query<
      any,
      {
        id: string;
      }
    >({
      query: (args) => {
        const { id } = args;
        return {
          url: `v2/plans/carriers/${id}/benefit-carrier`,
          method: 'GET',
        };
      },
    }),
    getOfferExistInProposalBulkImport: builder.query<
      any,
      {
        offerDocumentId: string;
      }
    >({
      query: ({ offerDocumentId }) => {
        return {
          url: `v1/renewals/offers/proposals-exists`,
          method: 'GET',
          params: {
            'document-id': offerDocumentId,
          },
        };
      },
    }),
    getOffersExistInProposalOfferListBulkImport: builder.query<
      { dtqPendingEffectiveDateList: any[]; finalList: any[] },
      {
        offerDocumentId: string;
      }
    >({
      query: ({ offerDocumentId }) => {
        return {
          url: `v1/renewals/offers/offer-comparison/document/${offerDocumentId}`,
          method: 'GET',
        };
      },
    }),
    getIndividualOfferNotesForId: builder.query<
      any,
      {
        offerId: string;
      }
    >({
      query: (args) => {
        const { offerId } = args;
        return {
          url: `v1/renewals/offers/${offerId}/offer-notes`,
          method: 'GET',
        };
      },
    }),

    getIndividualProposalNotesForId: builder.query<
      any,
      {
        proposalId: string;
      }
    >({
      query: (args) => {
        const { proposalId } = args;
        return {
          url: `v1/renewals/offer-proposals/${proposalId}/proposal-notes`,
          method: 'GET',
        };
      },
    }),
    createOfferNote: builder.mutation<any, { offerNote: OfferNoteDto }>({
      query: (data) => {
        const { offerNote } = data;
        const formData = new FormData();
        offerNote.files.forEach((file: any) => {
          formData.append('files', file);
        });
        Object.entries(offerNote).map(
          ([key, value]: [key: string, value: any]) => {
            if (key !== 'files') {
              formData.append(key, value);
            }
          }
        );
        return {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          url: `v1/renewals/offers/${offerNote.offerId}/offer-note`,
          method: 'POST',
          data: formData,
        };
      },
    }),
    createProposalNote: builder.mutation<
      any,
      { proposalNote: ProposalNoteDto }
    >({
      query: (data) => {
        const { proposalNote } = data;
        const formData = new FormData();
        proposalNote.files.forEach((file: any) => {
          formData.append('files', file);
        });
        Object.entries(proposalNote).map(
          ([key, value]: [key: string, value: any]) => {
            if (key !== 'files') {
              formData.append(key, value);
            }
          }
        );
        return {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          url: `v1/renewals/offer-proposals/${proposalNote.proposalId}/proposal-note`,
          method: 'POST',
          data: formData,
        };
      },
    }),
    publishOrDraftSingleOffer: builder.mutation<
      any,
      {
        offerId: string;
        saveStatus: string;
      }
    >({
      query: ({ offerId, saveStatus }) => {
        return {
          url: `v1/renewals/offers/${offerId}/status/${saveStatus}`,
          method: 'PUT',
        };
      },
    }),
    finalizeQUoteProcess: builder.mutation<
      any,
      {
        offerId: string;
      }
    >({
      query: ({ offerId }) => {
        return {
          url: `v1/renewals/offers/finalize-quote-processing/${offerId}`,
          method: 'PUT',
        };
      },
    }),
    editRateDetails: builder.mutation<
      any,
      {
        offerId: string;
        rate: string;
      }
    >({
      query: ({ offerId, rate }) => {
        return {
          url: `v1/renewals/offers/${offerId}/edit-rate-details`,
          method: 'PUT',
          params: {
            rate: rate,
          },
        };
      },
    }),
    approveExtractedQuoteOffer: builder.mutation<
      any,
      {
        offerId: string;
      }
    >({
      query: ({ offerId }) => {
        return {
          url: `v1/renewals/offers/${offerId}/approve`,
          method: 'PUT',
        };
      },
    }),
    getOfferCarrierNames: builder.query<any, { organizationId: string | null }>(
      {
        query: ({ organizationId }) => {
          return {
            url: `v1/renewals/offers/offer-carriers`,
            method: 'GET',
            params: {
              'organization-id': organizationId,
            },
          };
        },
      }
    ),
    getLatestNonVersionedOffer: builder.query<
      any,
      { offerId: string; status: string }
    >({
      query: (args) => {
        const { offerId, status } = args;
        return {
          url: `v1/renewals/offers/latest/${offerId}/status/${status}`,
          method: 'GET',
        };
      },
    }),
    updateOfferNotificationStatus: builder.mutation<
      any,
      { offerId: string; isActive: boolean }
    >({
      query: (args) => {
        const { offerId, isActive } = args;
        return {
          url: `v1/renewals/offers/${offerId}/published-notification`,
          method: 'PUT',
          params: {
            isActive: isActive,
          },
        };
      },
    }),
  }),
});

export const exportOfferAuditLog = async (offerId: string | undefined) => {
  const response = await axios.post(
    `${baseApi}/v1/renewals/audit/offer/${offerId}/export`,
    {},
    {
      responseType: 'blob',
    }
  );
  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `Offer_ActivityLog_Export_${moment().format('MM-DD-YYYY')}_${offerId}.xlsx`
  );
  document.body && document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const exportErrorFile = async (file: File | null, errors: any) => {
  const errorData = {
    errorsList: errors,
  };
  const formData = new FormData();
  formData.append('file', file ?? '');
  formData.append(
    'errors',
    new Blob([JSON.stringify(errorData)], { type: 'application/json' })
  );
  const response = await axios.post(
    `${baseApi}/v1/renewals/offer-proposals/error-file`,
    formData,
    {
      responseType: 'blob',
    }
  );
  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `Error File - ${file?.name}`);
  document.body && document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const {
  useUploadImportOffersFileMutation,
  useLazyImportOffersPreviewQuery,
  usePublishOrDraftOfferMutation,
  useGetDocumentExtractionByIdQuery,
  useGetDetailedDocumentExtractionByIdQuery,
  useGetOfferedPlanProcessingStatusByOfferIdQuery,
  useLazyGetOfferedPlanProcessingStatusByOfferIdQuery,
  useGetPlanDetailsQuery,
  useLazyGetPlanDetailsQuery,
  useLazyGetOffersByEffectiveDateQuery,
  useLazyGetOfferExistsQuery,
  useLazyGetContributionsByOfferQuery,
  useCalculateModelingContributionMutation,
  useCreateOfferNoteMutation,
  useCreateProposalNoteMutation,
  useUpdateBundleContributionMutation,
  useUpdateOfferNoteAsViewedMutation,
  useUpdateProposalNoteAsViewedMutation,
  useLazyGetOpenOffersQuery,
  useLazyGetIndividualOfferNotesForIdQuery,
  useLazyGetLatestNonVersionedOfferQuery,
  useLazyGetIndividualProposalNotesForIdQuery,
  useUpdateOfferStatusFieldMutation,
  useUpdateOfferStatusAsRecommendedMutation,
  useGetPrimaryCarrierRecommendedStatusQuery,
  useLazyGetPrimaryCarrierRecommendedStatusQuery,
  useLazyGetOfferImportHistoryQuery,
  useGetOffersByEffectiveDateQuery,
  useLazyGetInitialContributionsByOfferQuery,
  useUploadAndParseCarrierQuoteMutation,
  useCreateOfferMutation,
  useUpdateOpenOffersFilterMutation,
  useLazyGetOpenOffersFilterQuery,
  useLazyGetQuoteParsingStatusQuery,
  useLazyGetParsedQuoteQuery,
  useUploadCarrierDocumentMutation,
  useLazyDownloadCarrierDocumentQuery,
  useDeleteCarrierDocumentMutation,
  useLazyComparePlansQuery,
  useGetCurrentPlansQuery,
  useLazyDeleteOfferQuery,
  useLazyExistProposalByOfferQuery,
  useLazyDownloadTemplateFileQuery,
  useAllPlansQuery,
  useLazyAllPlansQuery,
  useLazyAddMedicalSystemPlanQuery,
  useGetBenefitCarrierByIdQuery,
  useLazyGetOfferExistInProposalBulkImportQuery,
  usePublishOrDraftSingleOfferMutation,
  useLazyGetOffersExistInProposalOfferListBulkImportQuery,
  useFinalizeQUoteProcessMutation,
  useEditRateDetailsMutation,
  useGetOfferCarrierNamesQuery,
  useUpdateOfferNotificationStatusMutation,
  useApproveExtractedQuoteOfferMutation,
} = offersAPI;
