import { FC, useRef, useState } from 'react';
import { Row } from 'antd';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { ReactComponent as NoteBook } from 'assets/images/notebook.svg';
import { ReactComponent as NoMedicalCalimsNoteBook } from 'assets/images/dashboardUtils/no-claims-uploaded-other.svg';
import { claimsDashboardDummyData } from 'modules/claims/constants/util';
import ClaimsChartDashboard from 'modules/claims/components/ClaimsChartNoDataView/ClaimsChartDashboard';
import ClaimUpload from 'modules/claims/pages/ClaimUpload';
import { useAppSelector } from 'hooks/redux';
import {
  EMP_MEMBER_CLAIMS_RESTRICTED,
  IndividualSubTypes,
} from 'constants/commonConstants';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import styles from './NoClaimsViewDashboard.module.less';

type NoClaimsViewDashboardProps = {
  className: string;
  primaryButtonAction: () => void;

  isMedicalClaimsUploaded: boolean;
};

const NoClaimsViewDashboard: FC<NoClaimsViewDashboardProps> = ({
  isMedicalClaimsUploaded,
}) => {
  const claimUploadRef = useRef();

  const [isClaimManageOpen, setIsClaimManageOpen] = useState<boolean>(false);
  const handleImportClaimClick = () => {
    setIsClaimManageOpen(true);
  };

  const individualSubType = useAppSelector(
    (state) => state?.auth?.auth?.appBootupInfo?.individualSubType
  );

  return (
    <>
      <div className={styles.noClaimsPreviewContent}>
        {/* Add claims chart */}
        <ClaimsChartDashboard
          isSelfFunded={false}
          claimsBreakdown={claimsDashboardDummyData}
        />
      </div>
      <div className={styles.noUploadContent}>
        <div className={styles.noClaimsView}>
          <div
            className={
              isMedicalClaimsUploaded
                ? styles.noClaimsMedicalViewWrapper
                : styles.noClaimsViewWrapper
            }
          >
            <Row className={styles.description}>
              {isMedicalClaimsUploaded ? (
                <NoMedicalCalimsNoteBook />
              ) : (
                <NoteBook />
              )}
            </Row>
            <Row className={styles.description}>
              {isMedicalClaimsUploaded ? (
                <h2 className={styles.medicalClaimTxt}>
                  You don’t have any Medical Claims Data
                </h2>
              ) : (
                <h2>You don’t have any Claims Data</h2>
              )}
            </Row>
            {!isMedicalClaimsUploaded ? (
              <>
                <Row className={styles.description}>
                  <p>Start by importing claims data below;</p>
                </Row>
                <Row className={styles.buttonClaims}>
                  {individualSubType === IndividualSubTypes.EMPLOYER_MEMBER ? (
                    <PlanyearPopover content={EMP_MEMBER_CLAIMS_RESTRICTED}>
                      <PageActionButton
                        type="primary"
                        className={styles.disabledClaimsButton}
                      >
                        Import Claims
                      </PageActionButton>
                    </PlanyearPopover>
                  ) : (
                    <PageActionButton
                      type="primary"
                      onClick={handleImportClaimClick}
                      className={styles.uploadClaimsButton}
                    >
                      Import Claims
                    </PageActionButton>
                  )}
                </Row>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <ClaimUpload
        ref={claimUploadRef}
        isUploaderOpen={isClaimManageOpen}
        setIsUploaderOpen={setIsClaimManageOpen}
        openManageClaimsModel={setIsClaimManageOpen}
        isEmptyFileHistory={true}
        isNoClaimApplicable={false}
      />
    </>
  );
};

export default NoClaimsViewDashboard;
