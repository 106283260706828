import axios from 'axios';
import { IndividualType } from 'constants/commonConstants';
import { baseApi } from 'util/apiUtil';

const baseUrl = `${baseApi}/v2/individuals`;

export const getBrokerAdminByLocation = (
  organizationId: string,
  locationList: string[]
) => {
  const params = {
    type: IndividualType.ORGANIZATION_ADMIN,
    organizationId,
    locations: locationList.join(','),
  };
  return axios.get(`${baseUrl}/admins/list`, { params });
};

export const getTeamMembers = (
  organizationId: string,
  employerId: string | null | undefined
) => {
  const params = {
    'organization-id': organizationId,
    employerId,
  };
  return axios.get(`${baseUrl}/team-members`, { params });
};

export const updateTermsForIndividual = (individualId: string) => {
  return axios.put(`${baseUrl}/update-terms?individual-id=${individualId}`);
};
