import { FC, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import Rates from 'modules/plans/components/Rates/Rates';
import {
  getPlanContributionByFrequency,
  resetPlanReduxStore,
  updateMedicalPlan,
  updateMedicalPlanByPath,
  getPlanContributionInProgress,
} from 'modules/plans/slices/medicalPlanSlice';
import { getBenefitGuidesByFrequency } from 'modules/plans/slices/dbgInfoSlice';
import MedicalPlan from 'model/plans/MedicalPlan';
import { BenefitCategory } from 'constants/commonConstants';
import PanelSection from 'modules/plans/enums/PanelSection';

type RatesDBGWrapperProps = {
  currentSection: string;
  medicalPlan: MedicalPlan;
  dbgMetaData: any;
};

const RatesDBGWrapper: FC<RatesDBGWrapperProps> = forwardRef(
  (props: RatesDBGWrapperProps, refs) => {
    const { editedMedicalPlan: medicalPlanState } = useAppSelector(
      (state) => state.plan.plans
    );
    const requestTypePlans = useAppSelector(
      (state) => state.plan.plans.requestType
    );

    const { dbgData, requestType } = useAppSelector(
      (state) => state.plan.dgbInfo
    );
    const { medicalPlan, dbgMetaData } = props;
    const [ratesForm] = Form.useForm();
    const dispatch = useAppDispatch();
    const { employerId } = useNavContext();
    const { dbgByFrequency, rateValidations } = dbgData;
    const ratesRef = useRef<any>();

    useImperativeHandle(refs, () => ({
      reset() {
        dispatch(
          resetPlanReduxStore(BenefitCategory.MEDICAL.value, employerId)
        );
        ratesRef?.current?.resetAll();
      },
    }));

    useEffect(() => {
      employerId &&
        medicalPlan.id &&
        dispatch(
          getBenefitGuidesByFrequency(
            employerId,
            medicalPlan.planYearId,
            BenefitCategory.MEDICAL.value,
            medicalPlan.id,
            dbgMetaData?.masterId,
            dbgMetaData?.latestRevision
          )
        );

      dispatch(updateMedicalPlan(medicalPlan));
    }, [
      dispatch,
      employerId,
      medicalPlan,
      dbgMetaData?.latestRevision,
      dbgMetaData?.masterId,
    ]);

    if (isEmpty(medicalPlanState)) {
      return <div></div>;
    }

    return (
      <Rates
        benefitCategory={BenefitCategory.MEDICAL.value}
        updatePlanByPathAction={updateMedicalPlanByPath}
        getContributionAction={getPlanContributionByFrequency}
        benefitGuideObj={dbgMetaData}
        plan={medicalPlanState}
        form={ratesForm}
        currentSection={PanelSection.RATES}
        dbgByFrequency={dbgByFrequency}
        isDBGView={true}
        ref={ratesRef}
        requestType={requestType}
        rateValidations={rateValidations}
        getContributionInProgress={
          requestTypePlans === getPlanContributionInProgress.type
        }
      />
    );
  }
);
RatesDBGWrapper.displayName = 'RatesDBGWrapper';
export default RatesDBGWrapper;
