import { isEmpty } from 'lodash';
import {
  NON_MDV_REQUIRED_FIELDS,
  SORT_MODELING_TOTAL_ENROLLMENTS,
  LIFE,
  VOLUNTARY_BENEFIT,
  OFFER_CATEGORY,
  PREMIUMS_FIELD_PROPERTY,
  EE,
} from 'modules/renewals/constants/renewalsConstants';
import { STANDARD_TIER_COUNT } from 'modules/plans/constants';
import {
  NULL_STRING,
  CAP_PREMIUM,
  ENROLLMENTS,
  EE_ONLY,
} from 'modules/renewals/constants/planTableConstants';
import { Premium } from 'modules/renewals/types/renewalsTypes';
import FundingType from 'modules/plans/enums/FundingType';
import PremiumType from 'modules/renewals/enums/PremiumType';
import { isNullOrUndefined } from 'modules/plans/utils';

/**
 * Validates a value based on its type.
 * @param {string} type - The type of the value.
 * @param {string} value - The value to be validated.
 *
 * @return {boolean} - Returns true if the value is considered valid, false otherwise.
 */
export const validate = (type: string, value: string): boolean => {
  // Check if value is empty or 'null' or null
  if (value === '' || value === null || value === NULL_STRING) {
    return true;
  }
  // Check type-specific validations
  if (type === CAP_PREMIUM || NON_MDV_REQUIRED_FIELDS.includes(type)) {
    // Remove special characters and check if it's not a number
    value = value?.replace('$', '');
    value = value?.replace('.', '');
    value = value?.replaceAll(',', '');
    return !/^\d+$/.test(value);
  }
  if (type === SORT_MODELING_TOTAL_ENROLLMENTS) {
    // Check if value contains only digits or digits with commas
    return /^\d+$/.test(value) ? false : !/^[\d,]+$/.test(value);
  }
  // Default to false if type is not recognized
  return false;
};

/**
 * Validates the length of a plan name.
 * @param {string} value The plan name to validate.
 *
 * @return {boolean} A boolean indicating whether the plan name length is greater than 50 characters.
 */
export const validatePlanNameLength = (value: string): boolean => {
  // Check if the length of the plan name is greater than 50 characters
  return (value?.length || 0) > 50;
};

/**
 * Checks if a name exists within updatedData, excluding the current item's name.
 * @param {Object[]} updatedData An array of updated data items.
 * @param {Object} selectedData The selected data item to compare against.
 * @param {Object} data The original data item for exclusion.
 *
 * @return {boolean} A boolean indicating whether the name exists in updatedData.
 */
export const nameExists = (
  updatedData: any[] | undefined,
  selectedData: any,
  data: any
): boolean => {
  // Find an item in updatedData whose name matches the selectedData's name,
  // ignoring case and leading/trailing whitespace
  const nameExists = updatedData?.find(
    (item) =>
      item?.name?.toLowerCase()?.trim() ===
      selectedData?.name?.toLowerCase()?.trim()
  );

  // Return true if nameExists is not null, not undefined, and is not an empty object,
  // and if the selectedData's name is different from the original data's name
  return (
    nameExists?.length !== 0 &&
    nameExists !== undefined &&
    selectedData?.name?.toLowerCase()?.trim() !==
      data?.name?.toLowerCase()?.trim()
  );
};

/**
 * Validates the plan name and insurance type based on the selected data and benefit type.
 * @param {Object} selectedData The selected data containing the plan name and insurance type.
 * @param {string} benefitType The type of benefit (e.g., LIFE, VOLUNTARY_BENEFIT).
 *
 * @return {boolean} A boolean indicating whether the plan name and insurance type are valid.
 */
export const validatePlanName = (
  selectedData: any,
  benefitType: string
): boolean => {
  // Check if the plan name is empty or null
  if (selectedData?.name?.trim() === '' || selectedData?.name === null) {
    return true;
  }
  if (
    // Check if the insurance type is empty or null and check if the benefit type is LIFE or VOLUNTARY_BENEFIT
    (isEmpty(selectedData?.insuranceType?.trim()) ||
      selectedData?.insuranceType === null) &&
    (benefitType === LIFE || benefitType === VOLUNTARY_BENEFIT)
  ) {
    return true;
  }
  return false;
};

/**
 * Checks if the administration fee is invalid for a given funding type and selected data.
 * @param {string | undefined} fundingType The type of funding (e.g., SELF_FUNDED).
 * @param {Object} selectedData The selected data containing administration fee information.
 *
 * @return {boolean} True if the administration fee is invalid, otherwise false.
 */
const isInvalidAdminFee = (
  fundingType: string | undefined,
  selectedData: any
): boolean => {
  // Check if the funding type is SELF_FUNDED
  if (fundingType === FundingType.SELF_FUNDED) {
    // Check if the administrationFee field is null or undefined
    if (isNullOrUndefined(selectedData?.administrationFee)) {
      return true;
    }
    // Check if the administrationFee value is empty or NULL_STRING
    if (
      isEmpty(selectedData?.administrationFee?.value) ||
      selectedData?.administrationFee?.value === NULL_STRING
    ) {
      return true;
    }
  }
  return false;
};

/**
 * Validates the given object based on various criterias.
 * @param {Object} selectedData The data object to validate.
 * @param {string | undefined} fundingType The type of funding.
 * @param {string} benefitType The type of benefit.
 * @param {PremiumType} premiumType The premium type.
 * @param {boolean} isNoCurrentPlans Indicates if there are no current plans.
 * @param {number} nTierCount The number of tiers.
 *
 * @return {boolean} True if the object is invalid based on the criteria, false otherwise.
 */
export const validateObj = (
  selectedData: any,
  fundingType: string | undefined,
  benefitType: string,
  premiumType: PremiumType,
  isNoCurrentPlans: boolean,
  nTierCount: number
): boolean => {
  // Check if the name is empty or null
  if (selectedData?.name?.trim() === '' || selectedData?.name === null) {
    return true;
  }
  if (
    [
      OFFER_CATEGORY.DENTAL,
      OFFER_CATEGORY.VISION,
      OFFER_CATEGORY.SHORT_TERM_DISABILITY,
    ].includes(benefitType?.toUpperCase()) &&
    isInvalidAdminFee(fundingType, selectedData)
  ) {
    // Check if the admin fee is invalid
    return true;
  }
  if (
    ['', null, NULL_STRING].includes(selectedData?.planType) &&
    benefitType?.toUpperCase() === OFFER_CATEGORY.MEDICAL
  ) {
    // Check if the plan type is invalid for medical benefits
    return true;
  }
  if (
    selectedData?.monthlyPremiums?.length !==
      (premiumType === PremiumType.STANDARD_TIER
        ? STANDARD_TIER_COUNT
        : nTierCount + 1) ||
    selectedData?.monthlyPremiums?.filter((item: any) =>
      validate(CAP_PREMIUM, item?.value)
    )?.length !== 0
  ) {
    // Check if the monthly premiums are invalid
    return true;
  }
  if (
    (selectedData?.enrolments?.filter((item: any) =>
      validate(
        SORT_MODELING_TOTAL_ENROLLMENTS,
        item?.value?.replaceAll(',', '')
      )
    )?.length !== 0 ||
      selectedData?.enrolments?.length === 0) &&
    isNoCurrentPlans
  ) {
    // Check if the enrolments are invalid when no current plans exist
    return true;
  }
  return false;
};

/**
 * Builds an array of tiered monthly premiums based on the number of tiers and a flag indicating if there are no current plans.
 * @param {number} nTierCount The number of tiers.
 * @param {boolean} isNoCurrentPlans A boolean flag indicating if there are no current plans.
 *
 * @return {Premium[]} An array of tiered monthly premiums.
 */
export const buildNTierMonthlyPremiums = (
  nTierCount: number,
  isNoCurrentPlans: boolean
): Premium[] => {
  // Initialize an empty array to store the premium data
  let premiumList = [] as Premium[];

  // Loop through each tier, including EE Only as the first tier
  for (let index = 0; index < nTierCount + 1; index++) {
    // Create a premium object for each tier
    let premium = {
      tier: {
        property: PREMIUMS_FIELD_PROPERTY,
        name: index === 0 ? EE : `EE + ${index}`,
        fieldType: null,
        fieldSubType: `${index === 0 ? 1 : index}/${index === 0 ? 4 : 6}`,
      },
    } as Premium;

    // Check if there are no current plans
    if (isNoCurrentPlans) {
      // Update the premium object with enrollment data
      premium = {
        ...premium,
        enrollment: {
          property: ENROLLMENTS,
          name:
            index === 0 ? 'ENROLLMENT_TIER_1' : `ENROLLMENT_TIER_${index + 4}`,
          fieldType: null,
          fieldSubType: `${index === 0 ? 1 : index}/${index === 0 ? 4 : 6}`,
        },
      };
    }

    // Add the premium object to the premiumList array
    premiumList = {
      ...premiumList,
      [index === 0 ? EE_ONLY : `EE + ${index}*`]: premium,
    };
  }

  // Return the array of tiered monthly premiums
  return premiumList;
};
