export const DLP_VERIFY_TOPIC = 'Data Protection';
export const DLP_VERIFY_DESCRIPTION_1 =
  'This message contains sensitive information in the\ncategories listed below.';
export const DLP_VERIFY_DESCRIPTION_2 = 'Please edit/remove the ';
export const DLP_VERIFY_DESCRIPTION_SPAN = 'highlighted items';
export const DLP_VERIFY_DESCRIPTION_3 =
  ' from your\nmessage before sending or send them from your work email\nusing your companies own encryption tools.';

export const DLP_FEATURE_ENABLE_TOPIC = 'Enable Data Loss Prevention (DLP)';
export const DLP_FEATURE_ENABLE_SUB_TOPIC = 'Account Log - ';
export const DLP_FEATURE_ENABLE_DESCRIPTION =
  'Data loss prevention is a security solution that identifies and helps\nprevent unsafe or inappropriate sharing, transfer, or use of sensitive\ndata.';
