export const UPLOAD = 'UPLOAD';
export const PUBLISH = 'PUBLISH';
export const MDVPLANS = ['Medical', 'Dental', 'Vision'];

export const ALLMONTHS = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];
export const caretColor = '#A5ADAF';
export const months = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
};

export const monthsShort = {
  JANUARY: 'Jan',
  FEBRUARY: 'Feb',
  MARCH: 'Mar',
  APRIL: 'Apr',
  MAY: 'May',
  JUNE: 'Jun',
  JULY: 'Jul',
  AUGUST: 'Aug',
  SEPTEMBER: 'Sep',
  OCTOBER: 'Oct',
  NOVEMBER: 'Nov',
  DECEMBER: 'Dec',
} as any;

export const monthsShortNumbers = {
  JANUARY: '01',
  FEBRUARY: '02',
  MARCH: '03',
  APRIL: '04',
  MAY: '05',
  JUNE: '06',
  JULY: '07',
  AUGUST: '08',
  SEPTEMBER: '09',
  OCTOBER: '10',
  NOVEMBER: '11',
  DECEMBER: '12',
} as any;

export const carrierFileEditingAdmins = ['PLATFORM_ADMIN', 'INTERNAL_ADMIN'];

export const fileTypes = {
  SAAS_DETAIL: 'Detail',
  SAAS_SUMMARY: 'Summary',
  SUMMARY_AND_DETAIL: 'Summary and Detail',
} as any;

export const billingUploadFileTypes = {
  SAAS_DETAIL: 'SAAS_DETAIL',
  SAAS_SUMMARY: 'SAAS_SUMMARY',
};

export const billingStatus = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
} as any;
