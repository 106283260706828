import { FC, useMemo, useEffect } from 'react';

import { Badge, Col, notification, Popover, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch } from 'hooks/redux';
import OverviewHeader from 'modules/plans/components/OverviewPage/OverviewHeader/OverviewHeader';
import EditMedicalPlanModal from 'modules/plans/medical/components/EditMedicalPlanModal/EditMedicalPlanModal';
import EditDentalPlanModal from 'modules/plans/dental/components/EditDentalPlanModal/EditDentalPlanModal';
import EditVisionPlanModal from 'modules/plans/vision/components/EditVisionPlanModal/EditVisionPlanModal';
import { DentalPlan } from 'model/plans/DentalPlan';
import MedicalPlan from 'model/plans/MedicalPlan';
import PlanInfoHeaderLabel from 'modules/employers/components/PlanInfoHeaderLabel/PlanInfoHeaderLabel';
import {
  serviceFrequencies,
  EditMdvTypes,
  BenefitCovered,
  DENTAL_ADVANCED_PROFILE_PARAMETERS,
  VISION_ADVANCED_PROFILE_PARAMETERS,
  TOOLTIP_CONST,
} from 'modules/plans/constants';
import NetworkBasedCostSharing from 'model/plans/NetworkBasedCostSharing';
import { BenefitCategory, FEATURE_KEYS } from 'constants/commonConstants';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';
import { PLAN_NOTIFICATION_KEY } from 'constants/benguideCollaborationConstants';
import { VisionPlan } from 'model/plans/VisionPlan';
import { findMedicalPlanById } from 'modules/plans/slices/medicalPlanSlice';
import { findDentalPlanById } from 'modules/plans/slices/dentalPlanSlice';
import { findVisionPlanById } from 'modules/plans/slices/visionPlanSlice';
import {
  displayNumberWithDecimals,
  isNullOrUndefined,
} from 'modules/plans/utils';
import useGetUserFeatureStatus from 'hooks/useGetUserFeatureStatus';

import styles from './servicesOverview.module.less';

type ServicesOverviewProps = {
  heading: string;
  plan: DentalPlan | MedicalPlan | VisionPlan;
  benefitKind?: string;
  fetchPlanWhenUpdated?: any;
  disableEditButton?: boolean;
  defaultServices: { shortName: string; code: number | null }[];
  isLoading?: boolean;
  viewPlanServicesModal?: boolean;
  setViewPlanServicesModal: (value: boolean) => void;
};

const ServicesOverview: FC<ServicesOverviewProps> = (props) => {
  const {
    heading,
    plan,
    benefitKind,
    fetchPlanWhenUpdated,
    disableEditButton,
    defaultServices,
    isLoading,
    viewPlanServicesModal,
    setViewPlanServicesModal,
  } = props;
  const params = useParams();
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (employerId && brokerId && params.planId && !plan) {
      if (benefitKind === BenefitCategory.MEDICAL.value) {
        dispatch(findMedicalPlanById(params.planId));
      }
      if (benefitKind === BenefitCategory.DENTAL.value) {
        dispatch(findDentalPlanById(params.planId));
      }
      if (benefitKind === BenefitCategory.VISION.value) {
        dispatch(findVisionPlanById(params.planId));
      }
    }
  }, [dispatch, employerId, brokerId, params.planId, benefitKind, plan]);

  const isAdvancedProfile = useGetUserFeatureStatus(
    brokerId,
    employerId,
    FEATURE_KEYS.ADVANCED_PROFILE,
    false
  );

  const editPlan = () => {
    setViewPlanServicesModal && setViewPlanServicesModal(true);
    if (fetchPlanWhenUpdated) {
      fetchPlanOnNotificationReceived();
      notification.close(PLAN_NOTIFICATION_KEY);
    }
  };

  const onClose = () => {
    if (
      benefitKind === BenefitCategory.MEDICAL.value &&
      viewPlanServicesModal
    ) {
      setViewPlanServicesModal(false);
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(findMedicalPlanById(params.planId));
      }
    } else if (
      benefitKind === BenefitCategory.DENTAL.value &&
      setViewPlanServicesModal
    ) {
      setViewPlanServicesModal(false);
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(findDentalPlanById(params.planId));
      }
    } else if (
      benefitKind === BenefitCategory.VISION.value &&
      setViewPlanServicesModal
    ) {
      setViewPlanServicesModal(false);
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(findVisionPlanById(params.planId));
      }
    }
  };

  const fetchPlanOnNotificationReceived = () => {
    if (benefitKind === BenefitCategory.MEDICAL.value && params.planId) {
      dispatch(findMedicalPlanById(params.planId));
    } else if (benefitKind === BenefitCategory.DENTAL.value && params.planId) {
      dispatch(findDentalPlanById(params.planId));
    } else if (benefitKind === BenefitCategory.VISION.value && params.planId) {
      dispatch(findVisionPlanById(params.planId));
    }
  };

  const defaultFilledServicesArray = useMemo(() => {
    return plan?.customServices
      ?.filter((service) =>
        defaultServices.some(
          (defaultService) =>
            defaultService.shortName === service.benefitCode.shortName
        )
      )
      .map((service) => service.benefitCode.shortName);
  }, [defaultServices, plan?.customServices]);

  const customFilledServicesArray = useMemo(() => {
    return plan?.customServices
      ?.filter(
        (service) =>
          !defaultServices.some(
            (defaultService) =>
              defaultService.shortName === service.benefitCode.shortName
          )
      )
      .filter((service) => {
        if (
          benefitKind === BenefitCategory.DENTAL.value &&
          !isAdvancedProfile
        ) {
          return !DENTAL_ADVANCED_PROFILE_PARAMETERS.includes(
            service.benefitCode.shortName
          );
        }
        if (
          benefitKind === BenefitCategory.VISION.value &&
          !isAdvancedProfile
        ) {
          return !VISION_ADVANCED_PROFILE_PARAMETERS.includes(
            service.benefitCode.shortName
          );
        }
        return true;
      })
      .map((service) => service);
  }, [defaultServices, plan?.customServices, benefitKind, isAdvancedProfile]);

  const getInNetworkValueDefaults = (shortName: string) => {
    let inNetworkValue: string = '-';

    if (plan && plan.customServices) {
      const service = plan?.customServices?.find(
        (s) => s.benefitCode.shortName === shortName
      );
      if (service) {
        if (
          service.serviceValue.inNetwork.costSharingPolicy === 'NOT_APPLICABLE'
        ) {
          return 'Not Applicable';
        }

        inNetworkValue = !isNullOrUndefined(
          service.serviceValue.inNetwork.copay
        )
          ? `$${displayNumberWithDecimals(
              service.serviceValue.inNetwork.copay
            )} ` +
            formatFrequencyText(service.serviceValue.inNetwork.copayFrequency)
          : !isNullOrUndefined(service.serviceValue.inNetwork.coinsurance)
          ? service.serviceValue.inNetwork.coinsurance + '% '
          : service.serviceValue.inNetwork.info
          ? service.serviceValue.inNetwork.info
          : service.serviceValue.inNetwork.benefitCovered === BenefitCovered.NO
          ? 'Not Covered'
          : '-';
      }
    }
    return inNetworkValue;
  };

  const getInNetworkValue = (serviceValue: NetworkBasedCostSharing) => {
    let inNetworkValue: string = '';

    if (serviceValue.inNetwork.costSharingPolicy === 'NOT_APPLICABLE') {
      return 'Not Applicable';
    }

    inNetworkValue = !isNullOrUndefined(serviceValue.inNetwork.copay)
      ? `$${displayNumberWithDecimals(serviceValue.inNetwork.copay)} ` +
        formatFrequencyText(serviceValue.inNetwork.copayFrequency)
      : !isNullOrUndefined(serviceValue.inNetwork.coinsurance)
      ? serviceValue.inNetwork.coinsurance + '% '
      : serviceValue.inNetwork.info
      ? serviceValue.inNetwork.info
      : serviceValue.inNetwork.benefitCovered === BenefitCovered.NO
      ? 'Not Covered'
      : '-';
    return inNetworkValue;
  };

  const getOutOfNetworkValueForDefaults = (shortName: string) => {
    let outNetworkValue: string = '-';

    if (plan && plan.customServices) {
      const service = plan?.customServices?.find(
        (s) => s.benefitCode.shortName === shortName
      );
      if (service) {
        outNetworkValue = !isNullOrUndefined(
          service.serviceValue.outOfNetwork.copay
        )
          ? `$${displayNumberWithDecimals(
              service.serviceValue.outOfNetwork.copay
            )} ` +
            formatFrequencyText(
              service.serviceValue.outOfNetwork.copayFrequency
            )
          : !isNullOrUndefined(service.serviceValue.outOfNetwork.coinsurance)
          ? service.serviceValue.outOfNetwork.coinsurance + '% '
          : service.serviceValue.outOfNetwork.info
          ? service.serviceValue.outOfNetwork.info
          : service.serviceValue.outOfNetwork.benefitCovered ===
            BenefitCovered.NO
          ? 'Not Covered'
          : '-';
      }
    }
    return outNetworkValue;
  };

  const getOutOfNetworkValue = (serviceValue: NetworkBasedCostSharing) => {
    let outNetworkValue: string = '';

    outNetworkValue = !isNullOrUndefined(serviceValue.outOfNetwork.copay)
      ? `$${displayNumberWithDecimals(serviceValue.outOfNetwork.copay)} ` +
        formatFrequencyText(serviceValue.outOfNetwork.copayFrequency)
      : !isNullOrUndefined(serviceValue.outOfNetwork.coinsurance)
      ? serviceValue.outOfNetwork.coinsurance + '% '
      : serviceValue.outOfNetwork.info
      ? serviceValue.outOfNetwork.info
      : serviceValue.outOfNetwork.benefitCovered === BenefitCovered.NO
      ? 'Not Covered'
      : '-';
    return outNetworkValue;
  };

  // TODO: Here considered only copayPriorToDeductible. check if others will have that feature in future
  const checkInNetworkServicePriorToDeductible = (
    serviceValue: NetworkBasedCostSharing
  ) => {
    let isInNetworkPriorToDeductible: boolean = false;

    if (
      !isEmpty(serviceValue.inNetwork.copayPriorToDeductible) &&
      serviceValue.inNetwork.benefitCovered === 'YES'
    ) {
      if (serviceValue.inNetwork.copayPriorToDeductible === 'YES') {
        isInNetworkPriorToDeductible = true;
      } else {
        isInNetworkPriorToDeductible = false;
      }
    } else if (
      isNullOrUndefined(serviceValue.inNetwork.copay) &&
      isNullOrUndefined(serviceValue.inNetwork.coinsurance) &&
      isEmpty(serviceValue.inNetwork.info)
    ) {
      isInNetworkPriorToDeductible = true;
    } else {
      isInNetworkPriorToDeductible = false;
    }

    return isInNetworkPriorToDeductible;
  };

  const checkInNetworkServicePriorToDeductibleForDefaults = (
    shortName: string
  ) => {
    let isInNetworkPriorToDeductible: boolean = false;

    if (plan && plan.customServices) {
      const tempService = plan?.customServices?.find(
        (service) => service.benefitCode.shortName === shortName
      );
      if (
        tempService &&
        !isEmpty(tempService.serviceValue.inNetwork.copayPriorToDeductible) &&
        tempService.serviceValue.inNetwork.benefitCovered === 'YES'
      ) {
        if (
          tempService.serviceValue.inNetwork.copayPriorToDeductible === 'YES'
        ) {
          isInNetworkPriorToDeductible = true;
        } else {
          isInNetworkPriorToDeductible = false;
        }
      } else if (
        isNullOrUndefined(tempService?.serviceValue.inNetwork.copay) &&
        isNullOrUndefined(tempService?.serviceValue.inNetwork.coinsurance) &&
        isEmpty(tempService?.serviceValue.inNetwork.info)
      ) {
        isInNetworkPriorToDeductible = true;
      } else {
        isInNetworkPriorToDeductible = false;
      }
    }

    return isInNetworkPriorToDeductible;
  };

  // TODO: Here considered only copayPriorToDeductible. check if others will have that feature in future
  const checkOutOfNetworkServicePriorToDeductible = (
    serviceValue: NetworkBasedCostSharing
  ) => {
    let isOutNetworkPriorToDeductible: boolean = false;

    if (
      !isEmpty(serviceValue.outOfNetwork.copayPriorToDeductible) &&
      serviceValue.outOfNetwork.benefitCovered === 'YES'
    ) {
      if (serviceValue.outOfNetwork.copayPriorToDeductible === 'YES') {
        isOutNetworkPriorToDeductible = true;
      } else {
        isOutNetworkPriorToDeductible = false;
      }
    } else if (
      isNullOrUndefined(serviceValue.outOfNetwork.copay) &&
      isNullOrUndefined(serviceValue.outOfNetwork.coinsurance) &&
      isEmpty(serviceValue.outOfNetwork.info)
    ) {
      isOutNetworkPriorToDeductible = true;
    } else {
      isOutNetworkPriorToDeductible = false;
    }
    return isOutNetworkPriorToDeductible;
  };

  const checkOutOfNetworkServicePriorToDeductibleForDefaults = (
    shortName: string
  ) => {
    let isOutNetworkPriorToDeductible: boolean = false;

    if (plan && plan.customServices) {
      const tempService = plan?.customServices?.find(
        (service) => service.benefitCode.shortName === shortName
      );
      if (
        tempService &&
        !isEmpty(
          tempService.serviceValue.outOfNetwork.copayPriorToDeductible
        ) &&
        tempService.serviceValue.outOfNetwork.benefitCovered === 'YES'
      ) {
        if (
          tempService.serviceValue.outOfNetwork.copayPriorToDeductible === 'YES'
        ) {
          isOutNetworkPriorToDeductible = true;
        } else {
          isOutNetworkPriorToDeductible = false;
        }
      } else if (
        isNullOrUndefined(tempService?.serviceValue.outOfNetwork.copay) &&
        isNullOrUndefined(tempService?.serviceValue.outOfNetwork.coinsurance) &&
        isEmpty(tempService?.serviceValue.outOfNetwork.info)
      ) {
        isOutNetworkPriorToDeductible = true;
      } else {
        isOutNetworkPriorToDeductible = false;
      }
    }

    return isOutNetworkPriorToDeductible;
  };

  const formatFrequencyText = (copayFrequency: string) => {
    const frequency = serviceFrequencies.find(
      (item) => item.value === copayFrequency
    );
    return frequency ? frequency.label : '';
  };

  return (
    <div>
      <Row>
        <OverviewHeader
          title={heading}
          onEditClick={() => {
            editPlan();
          }}
          disabled={disableEditButton}
        />
      </Row>
      <div className={styles.tableWrapper}>
        <Row>
          <Col span={18} className={styles.mainText}>
            <PlanInfoHeaderLabel
              labelHeaderText={['In Network', 'Out Of Network']}
            />
          </Col>
        </Row>

        {defaultServices.map((serviceName, index) => {
          const code = serviceName.code;
          const tooltipContent = code !== null && TOOLTIP_CONST[code];
          if (
            defaultFilledServicesArray &&
            !defaultFilledServicesArray.includes(serviceName.shortName)
          ) {
            return (
              <InfoLabelSection
                key={index}
                labelText={
                  <div className={styles.planTableServicesWrapper}>
                    {serviceName.shortName}
                    {tooltipContent && (
                      <Popover
                        content={<div>{tooltipContent.subtitle}</div>}
                        placement="bottomLeft"
                        overlayClassName="popoverInnerPlanTooltip"
                      >
                        <QuestionCircleOutlined
                          className={styles.serviceQuestionIconGeneral}
                        />
                      </Popover>
                    )}
                  </div>
                }
                value={'-'}
                extraColValue1={'-'}
              />
            );
          } else {
            return (
              <InfoLabelSection
                key={index}
                labelText={
                  <div className={styles.planTableServicesWrapper}>
                    {serviceName.shortName}
                    {tooltipContent && (
                      <Popover
                        content={<div>{tooltipContent.subtitle}</div>}
                        placement="bottomLeft"
                        overlayClassName="popoverInnerPlanTooltip"
                      >
                        <QuestionCircleOutlined
                          className={styles.serviceQuestionIconGeneral}
                        />
                      </Popover>
                    )}
                  </div>
                }
                value={
                  checkInNetworkServicePriorToDeductibleForDefaults(
                    serviceName.shortName
                  ) || benefitKind === BenefitCategory.VISION.value ? (
                    getInNetworkValueDefaults(serviceName.shortName)
                  ) : !isLoading ? (
                    <Badge count={1} size={'small'} offset={[7, 0]}>
                      {getInNetworkValueDefaults(serviceName.shortName)}
                    </Badge>
                  ) : (
                    '-'
                  )
                }
                extraColValue1={
                  checkOutOfNetworkServicePriorToDeductibleForDefaults(
                    serviceName.shortName
                  ) || benefitKind === BenefitCategory.VISION.value ? (
                    getOutOfNetworkValueForDefaults(serviceName.shortName)
                  ) : !isLoading ? (
                    <Badge count={1} size={'small'} offset={[7, 0]}>
                      {getOutOfNetworkValueForDefaults(serviceName.shortName)}
                    </Badge>
                  ) : (
                    '-'
                  )
                }
              />
            );
          }
        })}

        {customFilledServicesArray &&
          customFilledServicesArray.map((service, index) => {
            return (
              <InfoLabelSection
                key={index}
                labelText={service.benefitCode.shortName}
                value={
                  checkInNetworkServicePriorToDeductible(
                    service.serviceValue
                  ) || benefitKind === BenefitCategory.VISION.value ? (
                    getInNetworkValue(service.serviceValue)
                  ) : (
                    <Badge count={1} size={'small'} offset={[7, 0]}>
                      {getInNetworkValue(service.serviceValue)}
                    </Badge>
                  )
                }
                extraColValue1={
                  checkOutOfNetworkServicePriorToDeductible(
                    service.serviceValue
                  ) || benefitKind === BenefitCategory.VISION.value ? (
                    getOutOfNetworkValue(service.serviceValue)
                  ) : (
                    <Badge count={1} size={'small'} offset={[7, 0]}>
                      {getOutOfNetworkValue(service.serviceValue)}
                    </Badge>
                  )
                }
              />
            );
          })}

        {benefitKind !== BenefitCategory.VISION.value ? (
          <div className={styles.textWrapper}>
            <sup className={styles.superText}>1</sup> After Deductible is met
          </div>
        ) : (
          ''
        )}
        {benefitKind === BenefitCategory.MEDICAL.value &&
          viewPlanServicesModal && (
            <EditMedicalPlanModal
              plan={plan}
              onClose={onClose}
              isModalVisible={viewPlanServicesModal}
              editType={EditMdvTypes.SERVICES}
              setViewModal={setViewPlanServicesModal}
            />
          )}
        {benefitKind === BenefitCategory.DENTAL.value &&
          viewPlanServicesModal && (
            <EditDentalPlanModal
              plan={plan}
              onClose={onClose}
              isModalVisible={viewPlanServicesModal}
              editType={EditMdvTypes.SERVICES}
              setViewModal={setViewPlanServicesModal}
            />
          )}
        {benefitKind === BenefitCategory.VISION.value &&
          viewPlanServicesModal && (
            <EditVisionPlanModal
              plan={plan}
              onClose={onClose}
              isModalVisible={viewPlanServicesModal}
              editType={EditMdvTypes.SERVICES}
              setViewModal={setViewPlanServicesModal}
            />
          )}
      </div>
    </div>
  );
};

export default ServicesOverview;
