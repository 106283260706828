import { Menu, Popover, Row } from 'antd';
import { ChangeEvent, FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SorterResult } from 'antd/lib/table/interface';

import { CheckOutlined } from '@ant-design/icons';
import { useAppSelector } from 'hooks/redux';
import DataTable from 'components/DataTable/DataTable';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import {
  DataColumn,
  createRenderColumns,
} from 'components/DataTable/DataColumn';
import TablePagination from 'model/TablePagination';
import SearchBar from 'components/SearchBar/SearchBar';
import OfferStatusTag from 'components/OfferStatusTag/OfferStatusTag';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import {
  MOBILE_APP_ANDROID_URL,
  MOBILE_APP_IOS_URL,
} from 'constants/commonConstants';
import { isBlank } from 'util/commonUtil';

import styles from './idCardTable.module.less';

type IdCardTableProps = {
  isPreview?: boolean;
  onDeleteEmployee?: (employeeId: string) => void;
  paginationConfig: TablePagination;
  setPaginationConfig: Function;
  data: any[];
  totalData?: number;
  loading?: boolean;
  setSearchText?: Function;
  searchText?: string;
};

const IdCardTable: FC<IdCardTableProps> = ({
  isPreview = false,
  paginationConfig,
  setPaginationConfig,
  data,
  totalData = 0,
  loading,
  setSearchText,
  searchText = '',
  onDeleteEmployee,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const [isEmployeeDeleteModalOpen, setIsEmployeeDeleteModalOpen] =
    useState<boolean>(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>();

  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);

  const handleRedirectToEmployeeDetails = (employeeId: string) => {
    if (!isPreview) {
      navigate(
        `/brokers/${params.brokerId}/employers/${params.employerId}/id-cards/${employeeId}`
      );
    }
  };

  const handleDeleteEmployee = (employeeId: string) => {
    setSelectedEmployeeId(employeeId);
    setIsEmployeeDeleteModalOpen(true);
  };

  const handleDeleteEmployeeCancel = () => {
    setIsEmployeeDeleteModalOpen(false);
    setSelectedEmployeeId(undefined);
  };

  const handleDeleteEmployeeConfirm = () => {
    if (selectedEmployeeId) {
      onDeleteEmployee?.(selectedEmployeeId);
    }
    setIsEmployeeDeleteModalOpen(false);
  };

  const selectOptions = (employeeId: string, isDeleteEnabled?: boolean) => {
    return (
      <Menu>
        <Menu.Item
          key="details"
          onClick={() => handleRedirectToEmployeeDetails(employeeId)}
        >
          Employee Details
        </Menu.Item>
        {isDeleteEnabled && (
          <Menu.Item
            key="delete"
            onClick={() => handleDeleteEmployee(employeeId)}
          >
            Delete User
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const isOpsAdmin = () => {
    return appBootInfo?.isOpsAdmin;
  };

  const getEncryptedText = () => {
    return <span className={styles.encryptedText}>encrypted</span>;
  };

  const getMemberIdCell = (memberId: string) => {
    return (
      <div className={styles.memberIdCell}>
        {!isBlank(memberId) ? (
          <Popover
            content={
              isOpsAdmin() ? getEncryptedText() : `Member ID: ${memberId}`
            }
            overlayClassName={'popoverInner recommendedtooltip'}
          >
            <CheckOutlined />
          </Popover>
        ) : (
          '-'
        )}
      </div>
    );
  };

  const getSorter = () => (a: any, b: any) => 0;

  const tableColumns: DataColumn[] = [
    {
      title: 'EMPLOYEE NAME',
      dataIndex: 'individualName',
      key: 'individualName',
      width: '20%',
      sorter: getSorter(),
      defaultSortOrder: 'ascend',
      render: (data, record) => {
        return (
          <div
            className={`${styles.employeeNameCell} ${
              isPreview ? '' : styles.isClickable
            }`}
            onClick={() => handleRedirectToEmployeeDetails(record.employeeId)}
          >
            <div className={styles.employeeNameText}>
              {`${record?.firstName} ${record?.lastName}`}
            </div>
            {!isPreview && (
              <>
                {!record?.selfDeclared && record?.userAmended && (
                  <OfferStatusTag offerStatus={'USER_AMENDED'} />
                )}
                {record?.selfDeclared && (
                  <OfferStatusTag offerStatus={'SELF_DECLARED'} />
                )}
              </>
            )}
          </div>
        );
      },
    },
    {
      title: 'WORK EMAIL',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
    },
    {
      title: 'MEDICAL',
      dataIndex: 'medicalMemberId',
      key: 'medicalMemberId',
      width: '10%',
      align: 'center',
      render: (data) => getMemberIdCell(data),
    },
    {
      title: 'DENTAL',
      dataIndex: 'dentalMemberId',
      key: 'dentalMemberId',
      width: '10%',
      align: 'center',
      render: (data) => getMemberIdCell(data),
    },
    {
      title: 'VISION',
      dataIndex: 'visionMemberId',
      key: 'visionMemberId',
      width: '10%',
      align: 'center',
      render: (data) => getMemberIdCell(data),
    },
    {
      title: 'DEPENDENTS',
      dataIndex: 'dependantCount',
      key: 'dependantCount',
      width: '10%',
      render: (data) => <div className={styles.dependantsCell}>{data}</div>,
    },
  ];

  const tableColumnActions: DataColumn = {
    title: 'ACTIONS',
    dataIndex: 'actions',
    key: 'actions',
    width: '5%',
    render: (_data, record) => {
      return (
        <div className={styles.dropdownWrapper}>
          <SelectDropdown
            overlay={selectOptions(
              record.employeeId,
              record?.selfDeclared || record?.userAmended
            )}
          />
        </div>
      );
    },
  };

  const getData = (dataFilters: TablePagination) => {
    const { sorterInfo, paginationIndex, filterInfo, filters } = dataFilters;
    if (sorterInfo) {
      const paginationConfigData = {
        sorterInfo,
        paginationIndex,
        filterInfo: {
          limit: filterInfo.limit,
          offset: filterInfo.offset,
          searchText,
        },
        filters,
      };
      setPaginationConfig(paginationConfigData);
    } else {
      const paginationConfigData = {
        sorterInfo: {
          columnKey: 'individualName',
          field: 'individualName',
          order: 'ascend',
        } as SorterResult<any>,
        paginationIndex,
        filterInfo: {
          limit: filterInfo.limit,
          offset: filterInfo.offset,
          searchText,
        },
        filters,
      } as TablePagination;
      setPaginationConfig(paginationConfigData);
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearchText && setSearchText(searchText);
    const config = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText },
    };
    setPaginationConfig(config);
  };

  return (
    <div className={styles.idCardTableWrapper}>
      {!isPreview && (
        <Row
          justify={'space-between'}
          align={'middle'}
          className={styles.searchRowWrapper}
        >
          <SearchBar
            value={searchText}
            placeholder="Search Employees"
            isIssueSearch={true}
            onChange={handleSearch}
            isIconEndAligned
          />
          <Row
            justify={'end'}
            align={'middle'}
            className={styles.downloadAppWrapper}
          >
            PlanYear App Download:{' '}
            <span
              className={styles.downloadButton}
              onClick={() => (window.location.href = MOBILE_APP_IOS_URL)}
            >
              &nbsp;iOS&nbsp;
            </span>
            |
            <span
              className={styles.downloadButton}
              onClick={() => (window.location.href = MOBILE_APP_ANDROID_URL)}
            >
              &nbsp;Android
            </span>
          </Row>
        </Row>
      )}
      <DataTable
        columns={createRenderColumns(
          isPreview ? tableColumns : [...tableColumns, tableColumnActions]
        )}
        currentIndex={paginationConfig.paginationIndex}
        data={data}
        pagination={true}
        getData={getData}
        total={totalData?.toString()}
        pageSize={limit}
        loading={loading}
      />
      <ConfirmationDialog
        visible={isEmployeeDeleteModalOpen}
        confirmText={'Yes - Delete User'}
        title={'Delete User'}
        onConfirm={handleDeleteEmployeeConfirm}
        confirmBtnFullWidth
        isCancelLink
        closeModal={handleDeleteEmployeeCancel}
        cancelText="Cancel"
        width={464}
      >
        Are you sure you want to delete this user?
      </ConfirmationDialog>
    </div>
  );
};

export default IdCardTable;
