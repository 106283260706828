import { FC, useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import FullScreenModal from 'components/FullScreenModal/FullScreenModal';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import {
  clearManagingTeamMember,
  getBrokerAdminDetails,
} from 'modules/admins/slices/brokerAdminSlice';
import { getConsultantsSummary } from 'modules/benefitsConsultation/slices/brokerLevelSlice';
import ManageBrokerAdmin from 'modules/admins/pages/ManageBrokerAdmin/ManageBrokerAdmin';
import CreateAdmin from 'modules/admins/pages/CreateAdmin/CreateAdmin';
import { IndividualType } from 'constants/commonConstants';
import Admin from 'model/admin/Admin';
import EmployerLocation from 'model/EmployerLocation';
import {
  clearAdminFind,
  findAdminByAdminId,
} from 'modules/admins/slices/adminSlice';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';

import styles from './brokerAdminDetails.module.less';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  brokerAdminId: string;
  confirmCloseModal: Function;
  brokerLocations?: {
    selectAll: boolean;
    employerLocations: EmployerLocation[];
  };
};

const BrokerAdminDetails: FC<Props> = (props: Props) => {
  const {
    isModalOpen,
    brokerAdminId,
    setIsModalOpen,
    confirmCloseModal,
    brokerLocations,
  } = props;
  const [admin, setAdmin] = useState<Admin | null>(null);
  const [isEditAdminModalOpen, setIsEditAdminModalOpen] =
    useState<boolean>(false);
  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [visible, setVisible] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { brokerId } = useNavContext();

  const brokerAdminDetails = useAppSelector(
    (state) => state.admins.brokerAdmins.brokerAdminDetails
  );
  const adminStatus = useAppSelector((state) => state.admins.admins);

  useEffect(() => {
    if (brokerAdminId || !isModalOpen) {
      dispatch(getBrokerAdminDetails(brokerAdminId));
    }
  }, [dispatch, brokerAdminId, isModalOpen]);

  useEffect(() => {
    if (
      !adminStatus.adminFindInProgress &&
      !isEmpty(adminStatus.adminObj) &&
      adminStatus.error === null
    ) {
      setAdmin(adminStatus.adminObj);
      dispatch(clearAdminFind());
      if (!isEditAdminModalOpen) {
        setIsEditAdminModalOpen(true);
        setVisibleConfirmation(false);
      }
    }
  }, [
    dispatch,
    adminStatus?.adminFindInProgress,
    adminStatus?.adminObj,
    adminStatus?.error,
    isEditAdminModalOpen,
  ]);

  const handleCloseModal = () => {
    dispatch(clearManagingTeamMember());
    setIsModalOpen(false);
  };

  const toggleConfirmationPopup = async () => {
    setVisibleConfirmation(!visibleConfirmation);
  };

  const handleEditAdmin = () => {
    dispatch(findAdminByAdminId(brokerAdminId));
    brokerId && dispatch(getConsultantsSummary(brokerId));
  };

  const closeBrokerModal = () => {
    toggleConfirmationPopup();
  };

  const confirmCloseAdminDetailsModal = async (isClose: boolean = true) => {
    await toggleConfirmationPopup();
    if (isClose) {
      setIsModalOpen(false);
      setIsEditAdminModalOpen(false);
      confirmCloseModal(true, true);
    }
  };

  const toggleAdminUpdated = () => {
    setAlertMessage({
      type: 'success',
      message: 'Admin has been successfully updated',
    });
    setVisible(true);
  };

  const closeAlert = () => {
    setVisible(false);
    setAlertMessage({
      type: undefined,
      message: '',
    });
  };

  return (
    <>
      <div className={styles.alertWrapper}>
        {visible && (
          <AlertMessage
            type={alertMessage.type}
            message={alertMessage.message}
            closeAlert={closeAlert}
          />
        )}
      </div>
      <FullScreenModal
        visible={isModalOpen}
        onCancel={handleCloseModal}
        footer={false}
        title="Broker Admin Details"
        destroyOnClose
      >
        <ManageBrokerAdmin
          brokerAdminId={brokerAdminId}
          brokerAdminDetails={brokerAdminDetails}
          handleEditAdmin={handleEditAdmin}
        />
      </FullScreenModal>

      {isEditAdminModalOpen && (
        <FullScreenModal
          visible={isEditAdminModalOpen}
          onCancel={closeBrokerModal}
          footer={false}
          title="Edit Broker Admin"
          className={styles.mediaScreen}
          destroyOnClose
        >
          <CreateAdmin
            type={IndividualType.ORGANIZATION_ADMIN}
            admin={admin}
            confirmCloseModal={confirmCloseAdminDetailsModal}
            visibleConfirmation={visibleConfirmation}
            toggleConfirmationPopup={toggleConfirmationPopup}
            adminUpdated={toggleAdminUpdated}
            brokerLocations={brokerLocations}
          />
        </FullScreenModal>
      )}
    </>
  );
};

export default BrokerAdminDetails;
