import axios from 'axios';
import { baseApi } from 'util/apiUtil';

export const fetchCurrentPlansBG = async ({
  currentPlanYearId,
  employerId,
}: {
  currentPlanYearId: string;
  employerId: string;
}) => {
  return await axios.get(
    `${baseApi}/v1/plans/renewals/${employerId}/plan-years/${currentPlanYearId}`,
    {
      params: {
        afp: true,
      },
    }
  );
};
