import { FC, useRef, useState, useEffect } from 'react';
import { Button, Col, Row, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import FullScreenModal, {
  FullScreenModalProps,
} from 'components/FullScreenModal/FullScreenModal';
import NextButton from 'components/buttons/NextButton/NextButton';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import useDBGClient from 'modules/clients/DBGClient/useDBGClient';
import { ReactComponent as AIFeedbackRedFlag } from 'assets/images/ai-red-flag.svg';
import {
  injectLifePlanDetails,
  uploadTemporaryDocument as uploadTemporaryLifeDocument,
  removeBenefitDocuments as removeBenefitLifeDocuments,
  setLifeServices,
} from 'modules/plans/slices/lifePlanSlice';
import {
  injectDentalPlanDetails,
  removeBenefitDocuments as removeBenefitDentalDocuments,
  uploadDentalTemporaryDocument,
} from 'modules/plans/slices/dentalPlanSlice';
import {
  injectVisionPlanDetails,
  removeBenefitDocuments as removeBenefitVisionDocuments,
  uploadVisionTemporaryDocument,
} from 'modules/plans/slices/visionPlanSlice';
import {
  injectMedicalPlanDetails,
  uploadTemporaryDocument,
  removeBenefitDocuments as removeBenefitMedicalDocuments,
} from 'modules/plans/slices/medicalPlanSlice';
import AiSBCUploader, {
  AiSBCUploaderProps,
} from 'modules/plans/components/AiSBCUploader/AiSBCUploader';
import AddPlanReviewFinalizeModal from 'modules/plans/components/AddPlanReviewFinalizeModal/AddPlanReviewFinalizeModal';
import AddDentalPlanDialog from 'modules/plans/dental/components/AddDentalPlanDialog/AddDentalPlanDialog';
import AddVisionPlanDialog from 'modules/plans/vision/components/AddVisionPlanDialog/AddVisionPlanDialog';
import AddMedicalPlanDialog from 'modules/plans/medical/components/AddPlan/AddPlan';
import AddLifePlanDialog from 'modules/plans/life/components/AddLifePlanPanelForm/AddLifePlanPanelForm';
import { ReactComponent as AIFlashIcon } from 'assets/images/ai-flash-white.svg';

import {
  DENTAL_PLAN_CHANNEL,
  LIFE_AND_DISABILITY_CHANNEL,
  MEDICAL_PLAN_CHANNEL,
  RELOAD_DENTAL_PLAN_LIST,
  RELOAD_LIFE_AND_DISABILITY_PLAN_LIST,
  RELOAD_MEDICAL_PLAN_LIST,
  RELOAD_VISION_PLAN_LIST,
  VISION_PLAN_CHANNEL,
} from 'modules/clients/DBGClient/DBGClientConts';

import {
  setBenefitActivePanel,
  transformProcessedDentalPlanForStateInjection,
  transformProcessedLifePlanForStateInjection,
  transformProcessedMedicalPlanForStateInjection,
  transformProcessedVisionPlanForStateInjection,
} from 'modules/plans/utils';
import {
  clear,
  fetchJobStatus,
  finalizeSBCReview,
  getDuelLLMEnable,
  redirectToOps,
  setStatus,
} from 'modules/plans/slices/aiSbcUploaderSlice';
import { NavigationState } from 'components/Alert/AlertMessage';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import { toStringToProperCase } from 'util/stringUtil';
import UploaderType from 'modules/plans/enums/UploaderType';
import { ReviewType } from 'modules/plans/enums/ReviewType';
import {
  LIFE,
  LLM_EXTRACTION_POPOVER_TEXT_INFO,
  LLM_WARNING_TITLE_TEXT,
} from 'modules/renewals/constants/renewalsConstants';
import { DEBOUNCE_WAIT_TIME_DOCUMENT_READING } from 'util/commonUtil';
import {
  PlanApproach,
  MedicalPlanDocumentType,
  SBC_OPS_REVIEW_MODAL_TITLE,
  BasicPlans,
  EXTRACTION_SUCCESS_MESSAGE,
  getUpdatePlanParametersWarning,
} from 'modules/plans/constants';
import {
  exitWithoutSavingMsgSBC,
  exitWithoutSavingMsgWhileProcessingSBC,
  LLM_PROMPT_TYPE,
  planOfferFetchError,
  sbcfetchError,
} from 'constants/commonConstants';
import { ERROR_MESSAGE_PLAN_EDITING } from 'constants/benguideCollaborationConstants';
import PanelSection from 'modules/plans/enums/PanelSection';
import { AddPlanFormProps, BasicPlanType } from 'modules/plans/types/types';
import { showNotification } from 'modules/idCards/components/Notification/Notification';
import { DocumentExtractionSource } from 'modules/plans/enums/DocumentExtractionSource';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import RejectFileModal from 'modules/plans/components/RejectFileModal/RejectFileModal';
import { AIRejectType } from 'modules/plans/enums/CommonTypes';
import PlanReviewSwitchMessage from 'modules/plans/components/PlanReviewSwitchModal/PlanReviewSwitchMessage';
import { getCategoryForUrlNavigation } from 'modules/renewals/utils/renewalsUtils';
import styles from './addPlanModal.module.less';

export type AddPlanModalProps = {
  isOpen: boolean;
  isDisable?: boolean;
  benguideId?: string;
  dbgPlanYear?: string;
  fullScreenModalClassName?: string;
  jobId?: string;
  onSave?: (
    isSuccess: boolean,
    successMessage: string | undefined | null
  ) => void;
  benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE';
  subType?: string;
  title?: string;
  isRenewalProcessStarted?: boolean;
  setAiCount?: Function;
  getAiPlans?: Function;
  onClose: (
    extractStatus: ProcessStatus,
    sendToOpsFailed?: boolean,
    docExtractionSource?: TDocumentExtractionSource
  ) => void;
  docExtractionSource?: TDocumentExtractionSource;
};

export type SelectedPlanProps = Pick<
  AddPlanModalProps,
  'isOpen' | 'jobId' | 'subType' | 'docExtractionSource'
>;

export type TDocumentExtractionSource = {
  sourceOfferId?: string;
  sourceOfferName?: string;
  planId?: string;
  planName?: string;
  source: DocumentExtractionSource;
  updatedPlan: any;
};

const INJECTION_ACTION_CREATOR = {
  MEDICAL: injectMedicalPlanDetails,
  DENTAL: injectDentalPlanDetails,
  VISION: injectVisionPlanDetails,
  LIFE: injectLifePlanDetails,
} as const;

const UPLOAD_ACTION_CREATOR = {
  MEDICAL: uploadTemporaryDocument,
  DENTAL: uploadDentalTemporaryDocument,
  VISION: uploadVisionTemporaryDocument,
  LIFE: uploadTemporaryLifeDocument,
} as const;

const REMOVE_DOCUMENT_FROM_PLAN_CREATOR = {
  MEDICAL: removeBenefitMedicalDocuments,
  DENTAL: removeBenefitDentalDocuments,
  VISION: removeBenefitVisionDocuments,
  LIFE: removeBenefitLifeDocuments,
};

const TRANSFORMER_FUNCTION = {
  MEDICAL: transformProcessedMedicalPlanForStateInjection,
  DENTAL: transformProcessedDentalPlanForStateInjection,
  VISION: transformProcessedVisionPlanForStateInjection,
  LIFE: transformProcessedLifePlanForStateInjection,
} as const;

const CHANNEL = {
  MEDICAL: MEDICAL_PLAN_CHANNEL,
  DENTAL: DENTAL_PLAN_CHANNEL,
  VISION: VISION_PLAN_CHANNEL,
  LIFE: LIFE_AND_DISABILITY_CHANNEL,
} as const;

const RELOAD_EVENT = {
  MEDICAL: RELOAD_MEDICAL_PLAN_LIST,
  DENTAL: RELOAD_DENTAL_PLAN_LIST,
  VISION: RELOAD_VISION_PLAN_LIST,
  LIFE: RELOAD_LIFE_AND_DISABILITY_PLAN_LIST,
} as const;

const POPULATED_SECTION_KEYS = {
  MEDICAL: ['DEDUCTIBLES_OOP_MAX', 'SERVICES', 'RX'],
  DENTAL: ['DEDUCTIBLES_OOP_MAX', 'SERVICES'],
  VISION: ['SERVICES'],
  LIFE: ['PLAN_INFORMATION'],
} as const;

const AddPlanModal: FC<AddPlanModalProps> = ({
  isOpen,
  onClose,
  benguideId,
  dbgPlanYear,
  isDisable = false,
  benefit,
  subType,
  title: modalTitle,
  isRenewalProcessStarted = false,
  fullScreenModalClassName,
  onSave,
  docExtractionSource: documentExtractionSourceProps,
}: AddPlanModalProps) => {
  const { employer, employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();
  const {
    isLoading,
    planUploderType,
    status,
    jobId,
    isPlanLoading,
    reviewType,
    skipFailedDocument,
    benefitKind,
    isDuelLLMEnable,
  } = useAppSelector((state) => state.plan.aiSbc);
  const params = useParams();
  const navigate = useNavigate();
  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );
  const [docExtractionSource, setDocumentExtractionSource] = useState<
    TDocumentExtractionSource | undefined
  >(documentExtractionSourceProps);

  const [uploadedFileName, setUploadedFileName] = useState<string>();
  const [errorAlertMessage, setErrorAlertMessage] = useState<string>();
  const [successAlertMessage, setSuccessAlertMessage] = useState<string>();
  const [isInitialized, setIsIntialized] = useState<boolean>(false);
  const [isFinalizing, setIsFinalizing] = useState<boolean>(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);
  const [pollingCount, setPolingCount] = useState(0);
  const [planApproach, setPlanApproach] = useState<string>(
    PlanApproach.NOT_SELECTED
  );
  const [lifePlanType, setLifePlanType] = useState<BasicPlanType | null>('');
  const [hasExtractionDifference, setHasExtractionDifference] =
    useState<boolean>(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false);
  const [thresholdLimitReachedWarning, setThresholdLimitReachedWarning] =
    useState<boolean>(false);
  const [isOpsApproved, setIsOpsApproved] = useState<boolean>(false);

  const formRightWrapperRef = useRef<HTMLDivElement>(null);
  const isProcessing = [ProcessStatus.PROCESSING].includes(status);
  const enablePanelsForReview = [ProcessStatus.REVIEWED].includes(status);

  // get the title dynamcaly for life plans in other plans gets from the props
  const title = BasicPlans?.[lifePlanType! ?? subType!]?.label ?? modalTitle;

  const addPlanRef = useRef<any>(null);
  const dbgClient = useDBGClient();

  /** This runs a single time when modal is opened */
  useEffect(() => {
    if (!isInitialized && addPlanRef?.current?.setActivePanel) {
      addPlanRef?.current?.setActivePanel?.('');
      setIsIntialized(true);
    }
    // eslint-disable-next-line
  }, [addPlanRef?.current, isInitialized]);

  const handleModalClose = ({
    status,
    sendFailedToOps,
    extractionSource,
    benefitKind,
  }: {
    status?: ProcessStatus;
    sendFailedToOps?: boolean;
    extractionSource?: TDocumentExtractionSource;
    benefitKind?: string;
  }) => {
    dispatch(clear());
    setPlanApproach(PlanApproach.NOT_SELECTED);
    setErrorAlertMessage(undefined);
    setPolingCount(0);
    setHasExtractionDifference(false);
    setIsOpsApproved(false);
    if (extractionSource?.source || docExtractionSource?.source) {
      redirectModalOnOfferedPlanType(
        extractionSource ?? docExtractionSource!,
        false,
        status!,
        benefitKind
      );
    }
    onClose(status!, sendFailedToOps, extractionSource);
  };

  const navigateBackToOfferList = (
    isSuccess?: boolean,
    status?: ProcessStatus,
    docExtractionSource?: TDocumentExtractionSource,
    benefitKind?: string
  ) => {
    const category =
      benefit === 'LIFE'
        ? benefitKind || lifePlanType || subType || benefit
        : benefit;

    const customSuccessMessage = docExtractionSource?.source
      ? `${docExtractionSource?.planName} plan has been updated.`
      : null;

    const isFailed = status === ProcessStatus.FAILED;

    const getWarningMessage = (): {
      message: string;
      type: 'success' | 'error';
    } | null => {
      if (isFailed) {
        const failedMessage =
          docExtractionSource?.source ===
          DocumentExtractionSource.UPDATE_QUOTE_PLAN
            ? planOfferFetchError
            : sbcfetchError;
        return { message: failedMessage, type: 'error' };
      }

      if (isSuccess && customSuccessMessage) {
        return { message: customSuccessMessage, type: 'success' };
      }

      return null;
    };

    const state: NavigationState = {
      warning: getWarningMessage(),
    };

    const targetUrl = `/brokers/${params?.brokerId}/employers/${
      params?.employerId
    }/renewals/carrier/${getCategoryForUrlNavigation(category)}`;

    navigate(targetUrl, { state });
  };

  const redirectModalOnOfferedPlanType = (
    docExtractionSource: TDocumentExtractionSource,
    isSuccess?: boolean,
    status?: ProcessStatus,
    benefitKind?: string
  ) => {
    if (
      [DocumentExtractionSource.UPDATE_QUOTE_PLAN].includes(
        docExtractionSource?.source
      )
    ) {
      return navigateBackToOfferList(
        isSuccess,
        status,
        docExtractionSource,
        benefitKind
      );
    }
  };

  const handleUploadStart: NonNullable<AiSBCUploaderProps['onUploadStart']> = (
    file
  ) => {
    addPlanRef?.current?.setActivePanel?.('');
    addPlanRef?.current?.reset?.();
    dispatch(
      UPLOAD_ACTION_CREATOR[benefit]?.(
        file,
        benefit === 'MEDICAL'
          ? MedicalPlanDocumentType.SBC.value
          : MedicalPlanDocumentType.PLAN_SUMMARY.value
      )
    );
  };

  const handleUploadSuccess: NonNullable<
    AiSBCUploaderProps['onUploadSuccess']
  > = (plan, benefitCodes, docExtractionSourceData) => {
    dispatch(
      INJECTION_ACTION_CREATOR[benefit]?.(
        TRANSFORMER_FUNCTION[benefit]?.(
          plan,
          benefitCodes,
          plan?.isHsaCompatible
        )
      )
    );

    setThresholdLimitReachedWarning(
      plan?.thresholdReached && !plan?.extractionFinalized
    );
    setIsOpsApproved(plan?.extractionFinalized ?? false);
    handleLifePlanType(plan);
    setHasExtractionDifference(plan?.llmExtractionInfo ?? false);
    setPlanApproach(PlanApproach.SBC);
    setDocumentExtractionSource(docExtractionSourceData);

    const showUpdatePlanPanelsForBrAdmin =
      !isOpsAdmin &&
      (!isEmpty(docExtractionSource) || !isEmpty(docExtractionSourceData));

    if (
      planUploderType === UploaderType.OPS_VIEW ||
      showUpdatePlanPanelsForBrAdmin
    ) {
      setBenefitActivePanel(benefit, addPlanRef!);
    } else {
      addPlanRef?.current?.setActivePanel?.(PanelSection.BASIC_PLAN_INFO);
    }
  };

  const handleLifePlanType = (plan: any) => {
    if (benefit === LIFE) {
      dispatch(setLifeServices(plan?.lifeServices));
      if (plan?.benefitKind) {
        setLifePlanType(plan?.benefitKind);
      } else if (benefitKind) {
        setLifePlanType(benefitKind);
      }
    } else {
      setLifePlanType(null);
    }
  };

  const handleUploadCancel: NonNullable<
    AiSBCUploaderProps['onCancel']
  > = () => {
    dispatch(setStatus(ProcessStatus.CANCELLED));
    dispatch(REMOVE_DOCUMENT_FROM_PLAN_CREATOR[benefit]?.());
    setPlanApproach(PlanApproach.MANUAL);
    addPlanRef?.current?.setActivePanel?.(PanelSection.BASIC_PLAN_INFO);
    addPlanRef?.current?.reset?.();
  };

  const renderPlanHeader = () => {
    const isAnOfferedPlan =
      !isEmpty(docExtractionSource) &&
      docExtractionSource?.source ===
        DocumentExtractionSource.UPDATE_QUOTE_PLAN;
    return isPlanLoading ? (
      <></>
    ) : (
      <h1>{`${employer?.name} - ${
        isEmpty(docExtractionSource) ? 'Add' : 'Update'
      } ${title || toStringToProperCase(benefit)} Plan ${
        isAnOfferedPlan ? 'Offer' : ''
      }`}</h1>
    );
  };

  const getCancelConfirmationProps = (): Pick<
    FullScreenModalProps,
    'confirmTitle' | 'okText' | 'cancelText' | 'onCancelCancel'
  > => {
    return {
      okText: (
        <Button className={styles.closeWithoutSavingBtn}>
          {isProcessing ? 'Close' : 'Close without Saving'}
        </Button>
      ),
      cancelText: (
        <div className={styles.cancelLinkButton}>
          <LinkButton>Cancel</LinkButton>
        </div>
      ),
      confirmTitle: (
        <>
          Are you sure you want to close?
          <p>
            {isProcessing
              ? exitWithoutSavingMsgWhileProcessingSBC
              : exitWithoutSavingMsgSBC}
          </p>
        </>
      ),
    };
  };

  const renderDocExtractionSourceWarnings = () => {
    if (isEmpty(docExtractionSource)) return null;

    return (
      <>
        <div className={styles.modalTitleContainer}>
          {docExtractionSource?.sourceOfferName ? (
            <div className={styles.gridContainer}>
              <div className={styles.leftColumn}>
                <span>{docExtractionSource?.sourceOfferName}</span>
              </div>
              <div className={styles.separator}>|</div>
              <div className={styles.rightColumn}>
                <span>{docExtractionSource?.planName}</span>
              </div>
            </div>
          ) : (
            <span>{docExtractionSource?.planName}</span>
          )}
        </div>
        <div className={styles.warningContainer}>
          {showUpdatePlanWarning && (
            <FixedAlertMessage
              type="warning"
              wrapperClassName={styles.alertMessage}
              message={getUpdatePlanParametersWarning(
                benefit?.toLowerCase(),
                docExtractionSource?.source
              )}
            />
          )}
        </div>
      </>
    );
  };

  const showUpdatePlanWarning =
    !isOpsAdmin &&
    !isEmpty(docExtractionSource) &&
    [ProcessStatus.CANCELLED, ProcessStatus.MANUAL].includes(status);

  const handleSave = (isSuccess?: boolean) => {
    // Determine custom success message if applicable
    const customSuccessMessage = docExtractionSource?.source
      ? `${docExtractionSource.planName} plan has been updated.`
      : null;

    // Post message to the appropriate channel
    dbgClient.postMessage({
      channel: CHANNEL[benefit],
      event: RELOAD_EVENT[benefit],
      data: {},
    });

    // Trigger onSave callback with the appropriate parameters
    onSave?.(isSuccess ?? false, customSuccessMessage);

    // Reset states and clear data
    setPlanApproach(PlanApproach.NOT_SELECTED);
    dispatch(clear());
    setHasExtractionDifference(false);
    setIsOpsApproved(false);
    setDocumentExtractionSource(undefined);

    // Redirect if a source exists
    docExtractionSource?.source &&
      redirectModalOnOfferedPlanType(docExtractionSource, isSuccess);
  };

  const getForm = (): React.ReactNode => {
    const isOpsAdminReview =
      planUploderType === UploaderType.OPS_VIEW && isOpsAdmin;

    const isDocumentExtractionReviewForUpdate =
      !isEmpty(docExtractionSource) && enablePanelsForReview;

    const isReview = isOpsAdminReview || isDocumentExtractionReviewForUpdate;

    const commonProps: AddPlanFormProps = {
      ref: addPlanRef,
      onClose: handleSave,
      isOpen,
      planApproach,
      isRenewalProcessStarted,
      onError: setErrorAlertMessage,
      benguideId,
      dbgPlanYear,
      isDisable,
      isLoading: isFinalizing || isLoading,
      planUploderType,
      status,
      type: lifePlanType ?? subType,
      jobId,
      onSave: handleSave,
      isReview,
      skipFailedDocument,
      formRightWrapperRef,
      docExtractionSource,
    };

    const formComponents: Record<string, React.ReactNode> = {
      MEDICAL: <AddMedicalPlanDialog {...commonProps} />,
      DENTAL: <AddDentalPlanDialog {...commonProps} />,
      VISION: <AddVisionPlanDialog {...commonProps} />,
      LIFE: <AddLifePlanDialog {...commonProps} />,
    };

    return formComponents[benefit] ?? null;
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getDuelLLMEnable({
          promptType:
            benefit === 'MEDICAL'
              ? LLM_PROMPT_TYPE.SBC_READER
              : LLM_PROMPT_TYPE.BENEFIT_SUMMARY,
          benefit: benefit === 'LIFE' ? 'BASIC_LIFE' : benefit,
        })
      );
    }
    return () => {
      setThresholdLimitReachedWarning(false);
    };
  }, [isOpen, benefit, dispatch]);

  useEffect(() => {
    if (documentExtractionSourceProps && isOpen) {
      setDocumentExtractionSource(documentExtractionSourceProps);
    } else {
      setDocumentExtractionSource(undefined);
    }

    return () => {
      setDocumentExtractionSource(undefined);
    };
  }, [documentExtractionSourceProps, isOpen]);

  useEffect(() => {
    const enablePanelsForReview = [
      ProcessStatus.SUCCESS,
      ProcessStatus.REVIEWED,
      ProcessStatus.FAILED,
      ProcessStatus.SAVED,
    ].includes(status);
    const showOpsAdminPanels = isOpsAdmin && enablePanelsForReview;

    const showUpdatePlanPanelsForBrAdmin =
      !isOpsAdmin &&
      [ProcessStatus.REVIEWED].includes(status) &&
      !isEmpty(docExtractionSource);

    if (showOpsAdminPanels || showUpdatePlanPanelsForBrAdmin) {
      setBenefitActivePanel(benefit, addPlanRef!);
    } else if (!isOpsAdmin && status === ProcessStatus.CANCELLED) {
      addPlanRef?.current?.setActivePanel?.(PanelSection.BASIC_PLAN_INFO);
    } else if (!isOpsAdmin && status !== ProcessStatus.REVIEWED) {
      addPlanRef?.current?.setActivePanel?.('');
    }

    if (!isOpsAdmin && status === ProcessStatus.REVIEWED) {
      setSuccessAlertMessage(EXTRACTION_SUCCESS_MESSAGE);
    } else {
      setSuccessAlertMessage(undefined);
    }

    if (benefitKind && benefit === LIFE) {
      setLifePlanType(benefitKind);
    }

    return () => {
      setLifePlanType(null);
      setSuccessAlertMessage(undefined);
      setIsOpsApproved(false);
    };
  }, [
    status,
    isOpsAdmin,
    benefit,
    benefitKind,
    docExtractionSource,
    enablePanelsForReview,
  ]);

  useEffect(() => {
    if (isOpen) {
      dispatch(
        fetchJobStatus(
          employerId || '',
          benefit,
          isOpsAdmin,
          handleUploadSuccess ?? (() => {}),
          handleModalClose ?? (() => {}),
          uploadedFileName,
          setDocumentExtractionSource
        )
      );
    }
    // eslint-disable-next-line
  }, [employerId, jobId, benefit, isOpsAdmin, isOpen, pollingCount]);

  useEffect(() => {
    // This function is to poll the status of the job until it is processed
    // on automated flow
    const intervalId = setInterval(() => {
      if (isProcessing && reviewType === ReviewType.AUTOMATIC) {
        setPolingCount((prevCount) => prevCount + 1);
      }
    }, DEBOUNCE_WAIT_TIME_DOCUMENT_READING);

    return () => clearInterval(intervalId);
  }, [reviewType, isProcessing]);

  const handleCallback = () => {
    handleModalClose({});
    setThresholdLimitReachedWarning(false);
  };

  const redirectToOpsOnFailedStatus = async () => {
    if (jobId) {
      await dispatch(
        redirectToOps(jobId, benefit, handleCallback, handleCallback)
      );
    }
  };

  return (
    <>
      <FullScreenModal
        key={isOpen ? 1 : 2}
        visible={isOpen}
        footer={false}
        title={renderPlanHeader()}
        className={`${fullScreenModalClassName} add-${
          title?.toLowerCase() || benefit.toLowerCase()
        }-plan-modal`}
        destroyOnClose={true}
        showAiConfirm={
          planApproach !== PlanApproach.NOT_SELECTED ||
          !isEmpty(jobId) ||
          status === ProcessStatus.VALIDATING
        }
        {...getCancelConfirmationProps()}
        onCancelConfirm={() => handleModalClose({})}
      >
        <div className={styles.modalBodyContainer}>
          {/* Added an overlay because the HTML nodes in the "Add Plan" modal need to be loaded; 
              otherwise, the 'addPlan' ref will be null when the upload success event is triggered, 
              therefore preventing the forms from being loaded. This ensures that the modal 
              and its contents are fully loaded before any interactions occur. 
          */}
          {renderDocExtractionSourceWarnings()}

          {isPlanLoading && (
            <div className={styles.loadingContainerOverLay}>
              <Spin />
            </div>
          )}

          <Row justify="center">
            {successAlertMessage && !skipFailedDocument && (
              <FixedAlertMessage
                type="success"
                message={successAlertMessage}
                wrapperClassName={styles.alertMessage}
                closeAlert={() => setSuccessAlertMessage(undefined)}
                closable
              />
            )}
            {errorAlertMessage && (
              <FixedAlertMessage
                type="error"
                message={errorAlertMessage}
                wrapperClassName={styles.alertMessage}
                closeAlert={() => setErrorAlertMessage(undefined)}
                closable
              />
            )}
            {isDisable && (
              <FixedAlertMessage
                type={'error'}
                message={ERROR_MESSAGE_PLAN_EDITING}
              />
            )}
            {thresholdLimitReachedWarning && !isOpsAdmin && (
              <PlanReviewSwitchMessage
                onConfirm={() => {
                  redirectToOpsOnFailedStatus();
                }}
                visible={thresholdLimitReachedWarning}
              />
            )}
          </Row>
          <Row gutter={20} className={styles.createPlanContainer}>
            {hasExtractionDifference && isDuelLLMEnable && !isOpsApproved && (
              <Col span={24}>
                <Row className={styles.createPlanHeader}>
                  <AIFeedbackRedFlag />
                  {LLM_WARNING_TITLE_TEXT}
                  <PlanyearPopover
                    content={LLM_EXTRACTION_POPOVER_TEXT_INFO}
                    placement="left"
                  >
                    <QuestionCircleOutlined />
                  </PlanyearPopover>
                </Row>
              </Col>
            )}
            <Col span={14} className={styles.leftWrapper}>
              <AiSBCUploader
                benefit={benefit}
                onUploadStart={handleUploadStart}
                onUploadSuccess={handleUploadSuccess}
                onCancel={handleUploadCancel}
                employerId={employerId ?? ''}
                brokerId={brokerId ?? ''}
                populatedSections={POPULATED_SECTION_KEYS[benefit]}
                setIsIntialized={setIsIntialized}
                setPlanApproach={setPlanApproach}
                subType={subType}
                addPlanRef={addPlanRef}
                setUploadedFileName={setUploadedFileName}
                handleModalClose={handleModalClose}
                docExtractionSource={docExtractionSource}
              />
            </Col>
            <Col
              ref={formRightWrapperRef}
              className={styles.rightWrapper}
              span={10}
            >
              {getForm()}
              {planUploderType === UploaderType.OPS_VIEW && (
                <>
                  <NextButton
                    type="primary"
                    buttonText={
                      <>
                        {isFinalizing ? <Spin /> : <AIFlashIcon />}
                        {SBC_OPS_REVIEW_MODAL_TITLE}
                      </>
                    }
                    className={styles.sbcOpsReviewButton}
                    nextStep={() => setIsReviewModalOpen(true)}
                    disabled={[
                      ProcessStatus.REVIEWED,
                      ProcessStatus.SAVED,
                      ProcessStatus.REJECTED,
                    ].includes(status)}
                  />
                  <Row justify="center">
                    <LinkButton
                      disabled={[
                        ProcessStatus.REVIEWED,
                        ProcessStatus.SAVED,
                        ProcessStatus.REJECTED,
                      ].includes(status)}
                      onClick={() => {
                        setIsRejectModalOpen(true);
                      }}
                    >
                      Reject File
                    </LinkButton>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>
      </FullScreenModal>
      <AddPlanReviewFinalizeModal
        onClose={() => setIsReviewModalOpen(false)}
        onConfirm={async () => {
          setIsFinalizing(true);
          await finalizeSBCReview(
            addPlanRef?.current?.getPlan?.(),
            jobId!,
            benefit
          ).catch(() => {
            showNotification({
              type: 'error',
              message: 'Failed',
              description: 'Failed to Finalize AI Document Upload Review',
            });
          });
          handleSave();
          setIsFinalizing(false);
          setIsReviewModalOpen(false);
        }}
        isLoading={isFinalizing}
        isVisible={isReviewModalOpen}
      />

      <RejectFileModal
        jobId={jobId!}
        isModalShow={isRejectModalOpen}
        setIsModalShow={setIsRejectModalOpen}
        type={AIRejectType.PLANS_REJECT}
      />
    </>
  );
};

export default AddPlanModal;
