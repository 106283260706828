import {
  forwardRef,
  MutableRefObject,
  useImperativeHandle,
  useState,
} from 'react';

import { DentalPlan } from 'model/plans/DentalPlan';
import PlanBasicInfoForm from 'modules/plans/components/DVPlanBasicInfoForm/PlanBasicInfoForm';
import { useAppSelector } from 'hooks/redux';

type DentalBasicPlanInfoUPWrapperProps = {
  dentalPlan: DentalPlan;
  setDentalPlan: Function;
  basicPlanInfoRef: MutableRefObject<any>;
  originalPlanYear?: string;
  originalPlanBenGroups?: string[];
  isReviewProp?: boolean;
  reviewNewPlanYear?: string;
  reviewBenefitClasses?: string[];
  isReviewHighlight?: boolean;
};

const DentalBasicPlanInfoUPWrapper = forwardRef(
  (props: DentalBasicPlanInfoUPWrapperProps, ref) => {
    const {
      dentalPlan,
      setDentalPlan,
      basicPlanInfoRef,
      originalPlanYear,
      originalPlanBenGroups,
      isReviewProp,
      reviewNewPlanYear,
      reviewBenefitClasses,
      isReviewHighlight = false,
    } = props;

    const editedDentalPlan = useAppSelector(
      (state) => state.plan.dentalPlan.editedDentalPlan
    );
    const [requiredFieldError, setRequiredFieldError] =
      useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      reset() {
        basicPlanInfoRef.current?.resetForm();
      },
      isValidForm() {
        return basicPlanInfoRef.current?.isValidForm();
      },
      setNameDuplicationError() {
        return basicPlanInfoRef?.current?.setNameDuplicationError();
      },
    }));

    return (
      <PlanBasicInfoForm
        onChange={setDentalPlan}
        plan={dentalPlan}
        isCloseConfirmed={false}
        isDBGPlan={true}
        error={[requiredFieldError, setRequiredFieldError]}
        ref={basicPlanInfoRef}
        isUPEdit={true}
        originalPlanYear={originalPlanYear}
        originalPlanBenGroups={originalPlanBenGroups}
        hasSameContributions={editedDentalPlan?.hasSameContributions}
        benefitCategory="DENTAL"
        isReviewProp={isReviewProp}
        isReviewHighlight={isReviewHighlight}
        reviewNewPlanYear={reviewNewPlanYear}
        reviewBenefitClasses={reviewBenefitClasses}
        isSingleColumn
      />
    );
  }
);

DentalBasicPlanInfoUPWrapper.displayName = 'DentalBasicPlanInfoUPWrapper';
export default DentalBasicPlanInfoUPWrapper;
