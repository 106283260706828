import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Col, Form, Row } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { FormInstance } from 'antd/es/form/Form';

import DatePickerInput from 'components/DatePickerInput/DatePickerInput';
import DynamicForm from 'components/DynamicForm/DynamicForm';

import {
  ADDITIONAL_DATE_RANGE_POPOVER_TXT_LINE_1,
  ADDITIONAL_DATE_RANGE_POPOVER_TXT_LINE_2,
  DYNAMIC_FORM_ITEM_NAME_SEPARATOR,
  EFFECTIVE_END_DATE_FIELD_NAME,
  EFFECTIVE_START_DATE_FIELD_NAME,
  START_RENEWAL_DESCRIPTION,
  START_RENEWAL_DESCRIPTION_WITH_NO_CURRENT_PLANS,
  START_RENEWAL_SUBHEADER,
  UNIQUE_RENEWAL_DATE_RANGE_ERROR_MSG,
} from 'modules/renewals/constants/renewalsConstants';
import { dateFormat } from 'constants/dateFormat';

import UpcomingPlanYearDto from 'model/UpcomingPlanYearDto';
import UpcomingPlanYearBasicDto from 'model/UpcomingPlanYearBasicDto';
import { PlansState } from 'modules/renewals/components/StartNewRenewalModal/StartNewRenewalModal';

import { ReactComponent as CalendarIcon } from 'assets/images/icon-calendar.svg';
import styles from 'modules/renewals/components/StartNewRenewalModal/components/EffectiveDates/effectiveDates.module.less';

type Props = {
  plans?: PlansState;
  form: FormInstance<any>;
  /** Used to inject data into the form and the UI easily */
  initialData?: UpcomingPlanYearBasicDto[];
  isLoading: boolean;
  employerId: string;
  renewalDate: Dayjs;
  setUpcomingPlanYear: Function;
  setEffectiveDatesHistory: Function;
  onNextStep: (options?: object) => any;
  onCancel: () => any;
  nextButtonDisabled?: boolean;
};

// Builds the field key for use in the component. If the 'id' is one the
// Seperator is excluded for both the start and end fields.
const buildKey = (type: 'Start' | 'End', id?: string) => {
  if (type === 'Start') {
    return id === '1'
      ? EFFECTIVE_START_DATE_FIELD_NAME.concat(id)
      : EFFECTIVE_START_DATE_FIELD_NAME.concat(
          `${DYNAMIC_FORM_ITEM_NAME_SEPARATOR}${id}`
        );
  }

  return id === '1'
    ? EFFECTIVE_END_DATE_FIELD_NAME.concat(id)
    : EFFECTIVE_END_DATE_FIELD_NAME.concat(
        `${DYNAMIC_FORM_ITEM_NAME_SEPARATOR}${id}`
      );
};

const EffectiveDates = forwardRef(
  (
    {
      plans,
      form,
      onNextStep,
      onCancel,
      employerId,
      isLoading,
      setUpcomingPlanYear,
      setEffectiveDatesHistory,
      initialData,
      renewalDate,
      nextButtonDisabled,
    }: Props,
    ref
  ) => {
    const generateEffectiveEndDate = (): Dayjs => {
      return renewalDate?.add(1, 'y').subtract(1, 'd');
    };

    // function to validate start date
    const disableEffectiveDateWithRules = (
      current: Dayjs | null,
      counter: any
    ) => {
      return (
        disabledDate(current) || disableStartDateBeforeEndDate(current, counter)
      );
    };

    // Function to allow user to select a date before the existing renewal date
    const disabledDate = (current: Dayjs | null): boolean => {
      // Disable dates before today's date (inclusive)
      return current ? current.isBefore(dayjs(), 'day') : false;
    };

    // Function to not allow user to select start date before the end date
    const disableStartDateBeforeEndDate = (
      current: Dayjs | null,
      currentCounter: number
    ): boolean => {
      // get the selected end date
      const selectedEndDate: Dayjs = form.getFieldValue(
        EFFECTIVE_END_DATE_FIELD_NAME.concat(currentCounter.toString())
      );

      // if no end date is selected no need to validate
      if (!selectedEndDate) return false;
      return current
        ? current.isAfter(dayjs(selectedEndDate).startOf('day')) ||
            current.isSame(dayjs(selectedEndDate).startOf('day'))
        : false;
    };

    const disabledDateForEffectiveDate = (
      current: Dayjs | null,
      fromName: string
    ): boolean => {
      let effectiveDate: Dayjs = form.getFieldValue(fromName);
      // find the initial form item
      if (fromName.includes(DYNAMIC_FORM_ITEM_NAME_SEPARATOR)) {
        effectiveDate = effectiveDate?.add(1, 'day');
      }
      // Disable dates before today's date (inclusive)
      return current
        ? current.isBefore(dayjs(effectiveDate).startOf('day')) ||
            current.isSame(dayjs(effectiveDate).startOf('day'))
        : false;
    };

    // Build an configure a date input field based on the parameters */
    const buildDateInputField = (
      type: 'Start' | 'End',
      id: string,
      isInitial?: boolean,
      defaultValue?: Dayjs
    ) => {
      const keyName = buildKey(type, id);
      const disabledDates =
        type == 'Start'
          ? (current: Dayjs) => disableEffectiveDateWithRules(current, 1)
          : (current: Dayjs) =>
              disabledDateForEffectiveDate(
                current,
                EFFECTIVE_START_DATE_FIELD_NAME.concat(
                  `${DYNAMIC_FORM_ITEM_NAME_SEPARATOR}${id}`
                )
              );
      const value =
        defaultValue ??
        (type === 'Start'
          ? renewalDate
          : isInitial
          ? generateEffectiveEndDate()
          : undefined);
      form.setFields([{ name: `effective${type}Date~${id}`, value }]);

      return (
        <Col lg={12} xl={12}>
          <Form.Item
            key={keyName}
            name={keyName}
            rules={[
              {
                required: !isInitial,
                message: 'Date Required',
              },
            ]}
            label={
              isInitial && (
                <span className={styles.dynamicLabel}>
                  Effective {type} Date *
                </span>
              )
            }
          >
            <DatePickerInput
              placeholder={dateFormat}
              suffixIcon={<CalendarIcon />}
              disabled={false}
              defaultValue={value}
              disabledDate={disabledDates}
            />
          </Form.Item>
        </Col>
      );
    };

    // Build an configure an entire form row based on parameters
    const buildFormRow = (
      rowId: string,
      isInitial?: boolean,
      startDate?: Dayjs,
      endDate?: Dayjs
    ) => (
      <Row
        key={rowId}
        className={styles.datePickerRow}
        gutter={28}
        justify={'center'}
      >
        {buildDateInputField('Start', rowId, isInitial, startDate)}
        <span
          className={
            isInitial ? styles.seperatorDash : styles.seperatorDashAdditional
          }
        >
          -
        </span>
        {buildDateInputField('End', rowId, isInitial, endDate)}
      </Row>
    );

    const [formItems, setFormItems] = useState<any[]>([
      buildFormRow('initial', true),
    ]);

    /**
     * Supposed to only run on initial load.
     * Used to set the form and UI in case initial data is supplied
     * */
    useEffect(() => {
      if (initialData !== undefined) {
        const formItems = initialData.map((planYear, idx) =>
          buildFormRow(
            planYear?.id ?? '',
            idx === 0,
            dayjs(planYear.effectiveStartDate),
            dayjs(planYear.effectiveEndDate)
          )
        );
        const formData: Record<string, Dayjs> = {};
        initialData.forEach(({ effectiveEndDate, effectiveStartDate, id }) => {
          formData[`effectiveStartDate~${id}`] = dayjs(effectiveStartDate);
          formData[`effectiveEndDate~${id}`] = dayjs(effectiveEndDate);
        });
        form.resetFields(); // Otherwise data from first load persists.
        form.setFieldsValue(formData);
        setFormItems(formItems);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData]);

    const handleDeleteIconOnClick = (removingRowKey: any) => {
      const filteredList = formItems.filter((obj) => obj.key != removingRowKey);
      const startDateKey = buildKey('Start', removingRowKey);
      const endDateKey = buildKey('End', removingRowKey);
      form.resetFields([startDateKey, endDateKey]);
      setFormItems(filteredList);
    };

    const handleAddItem = () => {
      const newId = uuidv4();
      const startDateKey = buildKey('Start', newId);
      const endDateKey = buildKey('End', newId);
      form.setFields([
        { name: startDateKey, value: renewalDate },
        { name: endDateKey, value: undefined },
      ]);
      setFormItems((prev) => [...prev, buildFormRow(newId, false)]);
    };

    // Generate array of form item names
    const generateFormItemNameList = (): any[] => {
      const dateValues = form.getFieldsValue();
      const keySet = new Set();
      Object.keys(dateValues).forEach((key) => {
        // not initial one
        if (key.includes(DYNAMIC_FORM_ITEM_NAME_SEPARATOR)) {
          const randomId = key.split(DYNAMIC_FORM_ITEM_NAME_SEPARATOR)[1];
          keySet.add(DYNAMIC_FORM_ITEM_NAME_SEPARATOR.concat(randomId));
        } else {
          keySet.add('1');
        }
      });
      return Array.from(keySet);
    };

    const validateDateRanges = () => {
      const dateValues = form.getFieldsValue();
      const sameDaySet = new Set();
      const formItemNames: any[] = generateFormItemNameList();

      for (let i = 0; i < formItemNames.length; i++) {
        const itemName: string = formItemNames[i];
        const startDate =
          dateValues[EFFECTIVE_START_DATE_FIELD_NAME + itemName];
        const endDate = dateValues[EFFECTIVE_END_DATE_FIELD_NAME + itemName];

        if (startDate && endDate) {
          for (let j = 0; j < formItemNames.length; j++) {
            if (j === i) continue;
            const itemNameSub: string = formItemNames[j];
            const subStart =
              dateValues[EFFECTIVE_START_DATE_FIELD_NAME + itemNameSub];
            const subEnd =
              dateValues[EFFECTIVE_END_DATE_FIELD_NAME + itemNameSub];

            if (
              subStart &&
              subEnd &&
              startDate.isSame(subStart, 'day') &&
              endDate.isSame(subEnd, 'day')
            ) {
              sameDaySet.add(itemNameSub);
            }
          }
        }
      }

      if (sameDaySet.size > 0) {
        const errorSet = Array.from(sameDaySet).flatMap((obj) => [
          {
            name: EFFECTIVE_END_DATE_FIELD_NAME + obj,
            errors: [''],
          },
          {
            name: EFFECTIVE_START_DATE_FIELD_NAME + obj,
            errors: [UNIQUE_RENEWAL_DATE_RANGE_ERROR_MSG],
          },
        ]);

        resetErrorFields();
        form.setFields(errorSet);
        return;
      }
      resetErrorFields();
      return;
    };

    const resetErrorFields = () => {
      const dateValues = form.getFieldsValue();
      form.setFields(
        Object.keys(dateValues).map((key) => ({ name: key, errors: [] }))
      );
    };

    const handleSubmitClickOnDynamicForm = async (_: any) => {
      try {
        form.validateFields().then(() => {
          // setting errors
          validateDateRanges();

          const hasFieldErrors = form
            .getFieldsError()
            .some((obj) => obj.errors.length);

          // duplicated date range exist
          if (hasFieldErrors) {
            return;
          }

          const formItemNames: any[] = generateFormItemNameList();

          const {
            upcomingPlanYearBasicDTOs,
            startGreaterThanEndErrorSet,
            effectiveDatesWithId,
          } = formItemNames.reduce(
            (acc, itemName, idx) => {
              const startDate: Dayjs = form.getFieldValue(
                EFFECTIVE_START_DATE_FIELD_NAME.concat(itemName)
              );
              const endDate: Dayjs = form.getFieldValue(
                EFFECTIVE_END_DATE_FIELD_NAME.concat(itemName)
              );

              if (startDate >= endDate) {
                acc.startGreaterThanEndErrorSet.push({
                  name: EFFECTIVE_START_DATE_FIELD_NAME.concat(itemName),
                  errors: ['Start date is greater than end date.'],
                });
              }

              // add all the selected items of the dynamic list to the basic dto list
              acc.upcomingPlanYearBasicDTOs.push({
                effectiveEndDate: endDate?.format(dateFormat),
                effectiveStartDate: startDate?.format(dateFormat),
              });

              acc.effectiveDatesWithId.push({
                effectiveEndDate: endDate?.format(dateFormat),
                effectiveStartDate: startDate?.format(dateFormat),
                id: idx,
              });
              return acc;
            },
            {
              upcomingPlanYearBasicDTOs: [],
              startGreaterThanEndErrorSet: [],
              effectiveDatesWithId: [],
            }
          );

          if (startGreaterThanEndErrorSet.length > 0) {
            form.setFields(startGreaterThanEndErrorSet);
            return;
          }

          const upcomingPlanYearDto: UpcomingPlanYearDto = {
            upcomingPlanYears: upcomingPlanYearBasicDTOs,
            employerId: String(employerId),
          };

          setUpcomingPlanYear(upcomingPlanYearDto);
          setEffectiveDatesHistory(effectiveDatesWithId);
          onNextStep();
          return;
        });
      } catch (error) {}
    };

    useImperativeHandle(
      ref,
      () => ({
        handleSubmitEffectiveDates: handleSubmitClickOnDynamicForm,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const isPlansEmpty = Object.values(plans ?? []).every(
      (planArr) => planArr.length === 0
    );

    return (
      <DynamicForm
        formItems={formItems}
        addFormItems={handleAddItem}
        addItemTxt={`+ Add Another Effective Date Range`}
        addItemIcon={<QuestionCircleOutlined className={styles.popoverIcon} />}
        form={form}
        isAddBtnDisabled={formItems.length >= 3}
        mainButtonStyling={styles.modalEditMainBtnStyling}
        handleCancelClick={onCancel}
        description={
          isPlansEmpty
            ? START_RENEWAL_DESCRIPTION_WITH_NO_CURRENT_PLANS
            : START_RENEWAL_DESCRIPTION
        }
        subHeader={START_RENEWAL_SUBHEADER}
        handleSubmitClick={handleSubmitClickOnDynamicForm}
        isSubmitBtnLoading={isLoading}
        popOverTxt={
          <div>
            {ADDITIONAL_DATE_RANGE_POPOVER_TXT_LINE_1}
            <br />
            {ADDITIONAL_DATE_RANGE_POPOVER_TXT_LINE_2}
          </div>
        }
        handleDelete={handleDeleteIconOnClick}
        nextBtnDisabled={nextButtonDisabled}
      />
    );
  }
);

EffectiveDates.displayName = 'EffectiveDates';

export default EffectiveDates;
