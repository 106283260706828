import { useRef, useState } from 'react';
import { notification } from 'antd';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import DocumentUploader from 'components/DocumentUploader/DocumentUploader';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import ProcessStatus from 'modules/renewals/enums/QuoteUploadStatus';
import {
  initialQuoteMappingData,
  requiredFileQuoteTypes,
} from 'modules/renewals/utils/renewalsUtils';
import { isNullOrUndefined } from 'modules/plans/utils';
import { QuoteMappingType } from 'modules/renewals/types/QuoteMappingType';
import {
  clear,
  uploadCarrierQuoteFile,
  validateQuotePlanNames,
} from 'modules/renewals/slices/carrierQuoteAiSlice';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import {
  QUOTES_MANUAL_ALTERNATE_WARNING,
  QUOTES_MANUAL_PROCESSING_WARNING,
  ADD_PLAN_OPTIONS,
  QUOTES_MANUAL_GENERAL_ERROR,
  QUOTES_SELF_REVIEW_PROCESSING_WARNING,
  QUOTES_SELF_REVIEW_PROCESSING_WARNING_BOLD,
  QUOTES_SELF_REVIEW_ALTERNATE_WARNING,
  QUOTE_UPLOAD_FILE_WARNING_PROMPT,
  QUOTE_UPLOAD_FILE_PROMPT_TWO,
} from 'modules/renewals/constants/renewalsConstants';
import { ReviewType } from 'modules/renewals/enums/ReviewType';
import {
  QUOTE_UPLOAD_FILE_SIZE_WARNING,
  QUOTE_UPLOAD__FORMAT_ERROR_WARNING,
} from 'modules/plans/constants';
import IconError from 'assets/images/alert-failure.svg';
import QuoteMapToPlan from 'modules/renewals/components/QuoteProcessing/QuoteMapToPlan/quoteMapToPlan';
import RecentFilesModal from 'modules/renewals/components/QuoteProcessing/RecentFiles/RecentFilesModal';
import { ReactComponent as RecentFilesIcon } from 'assets/images/quote-upload-recent-files-icon.svg';
import { ReactComponent as RecentFilesDisableIcon } from 'assets/images/quote-upload-recent-files-gray-icon.svg';
import { downloadUploadedFileObj } from 'modules/renewals/utils/addPlansUtil';

import styles from './quoteDocumentUploader.module.less';

type AddPlanOptionKeys =
  typeof ADD_PLAN_OPTIONS[keyof typeof ADD_PLAN_OPTIONS]['key'];

type QuoteDocumentUploaderProps = {
  changeSelectedFile: Function;
  changeUpdatedData: Function;
  setMappedPlans: Function;
  selectedFile: File | null;
  isEdit: boolean;
  editData: any;
  documentDetails: any;
  benefitKind: any;
  changeQuoteMapping: Function;
  showQuoteMappingHighlight: any;
  mappedPlans: string[];
  currentPlans: any;
  quoteMapping: QuoteMappingType[];
  employerId: string | undefined;
  brokerId: string | undefined;
  setAddPlanIndex: any;
  showPlanIdentifierError: boolean;
  benefit?: string;
  validatePlanIdentifiers: Function;
  handlePlanSelectionTypeSwitch: Function;
  resetMappingErrors: Function;
  handleNext: (shouldInitializeQuote?: boolean) => void;
  isOfferCreateLoading?: boolean;
  isAllDisabled?: boolean;
};

const QuoteDocumentUploader = (props: QuoteDocumentUploaderProps) => {
  const {
    setMappedPlans,
    isEdit,
    editData,
    documentDetails,
    benefitKind,
    changeQuoteMapping,
    showQuoteMappingHighlight: showQuoteMappingHighlight,
    mappedPlans,
    currentPlans,
    quoteMapping,
    employerId,
    brokerId,
    setAddPlanIndex,
    showPlanIdentifierError,
    selectedFile,
    changeSelectedFile,
    benefit = 'MEDICAL',
    validatePlanIdentifiers,
    handlePlanSelectionTypeSwitch,
    resetMappingErrors,
    handleNext,
    isOfferCreateLoading,
    isAllDisabled,
  } = props;
  const {
    jobId,
    status,
    isLoading: isQuoteLoading,
    reviewType,
    isPlanNamesValid,
  } = useAppSelector((state) => state.renewals.quoteReaderSlice);

  const isLoading = isOfferCreateLoading || isQuoteLoading;
  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );
  const documentUploaderRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const [isRecentFilesModalOpen, setIsRecentFilesModalOpen] =
    useState<boolean>(false);
  const [selectedRecentFileId, setSelectedRecentFileId] = useState<string>('');

  const disableDocumentUpload =
    [ProcessStatus.VALIDATING, ProcessStatus.PROCESSING].includes(status) ||
    isOpsAdmin ||
    isLoading;

  const handleRemoveDocument = () => {
    if (!isLoading) {
      changeSelectedFile(null);
      setMappedPlans([]);
      setSelectedRecentFileId('');
      dispatch(clear());
    }
  };

  const showMapToPlan =
    !isNullOrUndefined(selectedFile) &&
    ![ProcessStatus.UPLOADING].includes(status);

  const handleClientSideValidationFail = (failReason: string) => {
    let errorMessage = '';
    if (failReason === 'FORMAT') {
      errorMessage = QUOTE_UPLOAD__FORMAT_ERROR_WARNING;
    } else if (failReason === 'SIZE') {
      errorMessage = QUOTE_UPLOAD_FILE_SIZE_WARNING;
    }
    if (errorMessage !== '') {
      notification.open({
        message: 'File Upload Failed',
        description: errorMessage,
        placement: 'topRight',
        duration: 4,
        className: styles.notification,
        closeIcon: <></>,
        icon: (
          <img className={styles.notificationIcon} src={IconError} alt="" />
        ),
      });
    }
  };

  const handleQuoteProcessing = () => {
    if (validatePlanIdentifiers()) return;
    const planNames = quoteMapping?.map((item) => item?.planIdentifier) ?? [];
    if (
      isNullOrUndefined(selectedFile) ||
      showPlanIdentifierError ||
      isEmpty(jobId) ||
      isEmpty(planNames)
    ) {
      return;
    }
    dispatch(
      validateQuotePlanNames(
        jobId!,
        planNames,
        quoteMapping,
        changeQuoteMapping,
        handleNext
      )
    );
  };

  const handleFileChange = (file: File) => {
    if (isEmpty(file.name)) {
      changeSelectedFile(null);
    }
    setSelectedRecentFileId('');
    changeSelectedFile(file);
    setAddPlanIndex([]);
    setMappedPlans([]);
    changeQuoteMapping(initialQuoteMappingData);
    resetMappingErrors();
    dispatch(uploadCarrierQuoteFile(file, employerId!, brokerId!, benefit));
  };

  const handleUploadFromRecentFiles = (file: File, selectedFileId: string) => {
    documentUploaderRef?.current?.reset();
    setAddPlanIndex([]);
    setMappedPlans([]);
    changeQuoteMapping(initialQuoteMappingData);
    resetMappingErrors();
    if (isEmpty(file.name)) {
      return changeSelectedFile(null);
    }
    setSelectedRecentFileId(selectedFileId);
    changeSelectedFile(file);
  };

  const changeOfferSelectionType = (value: AddPlanOptionKeys, option: any) => {
    handlePlanSelectionTypeSwitch(value, option);
  };

  const validationSuccessOrError = () => {
    if (isPlanNamesValid && reviewType === ReviewType.MANUAL) {
      return (
        <FixedAlertMessage
          message={
            <>
              <span className={styles.processingOptions}>
                {'PROCESSING OPTIONS'}
              </span>
              <br />
              <br />
              <div className={styles.opsWarningContainer}>
                <p>
                  {QUOTES_MANUAL_PROCESSING_WARNING} <br />
                  <br />
                  {QUOTES_MANUAL_ALTERNATE_WARNING}
                  <span
                    className={styles.linkText}
                    onClick={() =>
                      changeOfferSelectionType(
                        ADD_PLAN_OPTIONS.MANUAL_PLANS.key,
                        ADD_PLAN_OPTIONS.MANUAL_PLANS
                      )
                    }
                  >
                    manually input the values{' '}
                  </span>
                  or{' '}
                  <span
                    className={styles.linkText}
                    onClick={() =>
                      changeOfferSelectionType(
                        ADD_PLAN_OPTIONS.EXISTING_PLANS.key,
                        ADD_PLAN_OPTIONS.EXISTING_PLANS
                      )
                    }
                  >
                    import them from existing plans.
                  </span>
                </p>
              </div>
            </>
          }
          type="info"
        />
      );
    }
    // no change necessary since this condition is only visible when the review flow is auto
    if ([ProcessStatus.FAILED].includes(status)) {
      if (reviewType === ReviewType.AUTOMATIC) {
        return (
          <FixedAlertMessage
            message={
              <>
                <span className={styles.processingOptions}>
                  {'PROCESSING OPTIONS'}
                </span>
                <br />
                <br />
                <div className={styles.opsWarningContainer}>
                  <p>
                    {QUOTES_SELF_REVIEW_PROCESSING_WARNING}{' '}
                    <span className={styles.boldText}>
                      {QUOTES_SELF_REVIEW_PROCESSING_WARNING_BOLD}
                    </span>
                    <br />
                    <br />
                    {QUOTES_SELF_REVIEW_ALTERNATE_WARNING}
                    <span
                      className={styles.linkText}
                      onClick={() =>
                        changeOfferSelectionType(
                          ADD_PLAN_OPTIONS.MANUAL_PLANS.key,
                          ADD_PLAN_OPTIONS.MANUAL_PLANS
                        )
                      }
                    >
                      input these values manually{' '}
                    </span>
                    or{' '}
                    <span
                      className={styles.linkText}
                      onClick={() =>
                        changeOfferSelectionType(
                          ADD_PLAN_OPTIONS.EXISTING_PLANS.key,
                          ADD_PLAN_OPTIONS.EXISTING_PLANS
                        )
                      }
                    >
                      import values from existing plans.
                    </span>
                  </p>
                </div>
              </>
            }
            type="info"
            processingOptions="PROCESSING OPTIONS"
          />
        );
      } else if (reviewType === ReviewType.MANUAL) {
        return (
          <FixedAlertMessage
            message={
              <div className={styles.opsWarningContainer}>
                <p>
                  {QUOTES_MANUAL_GENERAL_ERROR}
                  <span
                    className={styles.linkText}
                    onClick={() =>
                      changeOfferSelectionType(
                        ADD_PLAN_OPTIONS.MANUAL_PLANS.key,
                        ADD_PLAN_OPTIONS.MANUAL_PLANS
                      )
                    }
                  >
                    input these values manually
                  </span>{' '}
                  or{' '}
                  <span
                    className={styles.linkText}
                    onClick={() =>
                      changeOfferSelectionType(
                        ADD_PLAN_OPTIONS.EXISTING_PLANS.key,
                        ADD_PLAN_OPTIONS.EXISTING_PLANS
                      )
                    }
                  >
                    import values from existing plans.
                  </span>
                </p>
              </div>
            }
            type="error"
          />
        );
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.uploaderControls}>
        <div className={styles.uploadButton}>
          <div className={styles.quoteFileWarning}>
            {QUOTE_UPLOAD_FILE_WARNING_PROMPT}
            <br />
            {QUOTE_UPLOAD_FILE_PROMPT_TWO}
          </div>
          <div className={styles.chooseFileLabel}>Choose File</div>
          <div>
            <DocumentUploader
              ref={documentUploaderRef}
              onChange={handleFileChange}
              isLoading={status === ProcessStatus.UPLOADING}
              disabled={disableDocumentUpload || isAllDisabled}
              onRemove={handleRemoveDocument}
              isRemoved={selectedFile === null}
              maxFileSizeMB={100}
              defaultFile={selectedFile}
              hideNoFileMessage={true}
              onFileNameClick={(file: File) => {
                downloadUploadedFileObj(
                  file,
                  isEdit && !changeSelectedFile,
                  editData,
                  documentDetails,
                  selectedRecentFileId
                );
              }}
              allowedFileTypes={requiredFileQuoteTypes}
              selectFileName="+ File"
              overflowPopoverMaxWidth={'380px'}
              showCancelConfirmationBody={false}
              onValidateFails={handleClientSideValidationFail}
            />
          </div>
        </div>
        <div className={styles.recentFilesButton}>
          <LinkButton
            onClick={() => {
              setIsRecentFilesModalOpen(true);
            }}
            disabled={isLoading || isAllDisabled}
            enableDisableStyle
          >
            <div className={styles.recentFilesButtonFlexContainer}>
              {isLoading || isAllDisabled ? (
                <RecentFilesDisableIcon />
              ) : (
                <RecentFilesIcon />
              )}{' '}
              Recent Files
            </div>
          </LinkButton>
        </div>
      </div>
      {showMapToPlan && (
        <QuoteMapToPlan
          quoteMapping={quoteMapping ?? []}
          changeQuoteMapping={changeQuoteMapping}
          currentPlans={currentPlans?.currentPlans}
          showErrorHighlight={showQuoteMappingHighlight}
          mappedPlans={mappedPlans}
          setMappedPlans={setMappedPlans}
          benefitKind={benefitKind}
          showPlanIdentifierError={showPlanIdentifierError}
          handleQuoteProcessing={handleQuoteProcessing}
          validationSuccessOrError={validationSuccessOrError}
          resetMappingErrors={resetMappingErrors}
          isOfferCreateLoading={isOfferCreateLoading}
          isAllDisabled={isAllDisabled}
        />
      )}
      <RecentFilesModal
        isOpen={isRecentFilesModalOpen}
        onClose={() => setIsRecentFilesModalOpen(false)}
        benefit={benefit}
        changeSelectedFile={handleUploadFromRecentFiles}
      />
    </div>
  );
};

export default QuoteDocumentUploader;
