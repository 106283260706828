import { FC, useState } from 'react';
import { Menu } from 'antd';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { CheckOutlined } from '@ant-design/icons';

import iconInfo from 'assets/images/icon-info.svg';
import DataTable from 'components/DataTable/DataTable';
import { DataColumn } from 'components/DataTable/DataColumn';
import Expandable from 'components/Expandable/Expandable';
import NextButton from 'components/buttons/NextButton/NextButton';
import InfoBanner from 'components/InfoBanner/InfoBanner';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import FooterText from 'components/FooterText/FooterText';
import BrokerLocationForm from 'modules/brokers/components/BrokerForm/BrokerLocationForm';
import BrokerLocation from 'model/BrokerLocation';
import { brokerFooterText } from 'constants/commonConstants';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { addBroker } from 'modules/brokers/slices/brokerBasicInfoSlice';
import styles from './brokerLocationList.module.less';

const EDIT_ACTION_KEY: string = 'edit';
const DELETE_ACTION_KEY: string = 'delete';
const MAKE_PRIMARY_LOCATION_ACTION_KEY: string = 'makePrimaryLocation';

type BrokerLocationListProps = {
  nextStep: () => void;
};

const convertBrokerLocationsForTableRender = (
  locations: BrokerLocation[]
): any[] => {
  if (locations != null) {
    return locations.map((item) => ({
      key: item.inPlaceId,
      location: item.name,
      primary: item.primary,
      actions: item,
    }));
  }
  return [];
};

const BrokerLocationList: FC<BrokerLocationListProps> = (
  props: BrokerLocationListProps
) => {
  const { nextStep, ...rest } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [editLocationId, setEditLocationId] = useState<string | null>(null);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const dispatch = useAppDispatch();
  const broker = useAppSelector(
    (state) => state.brokers.brokerBasicInfo.brokerObj
  );

  const onLocationRemove = (location: BrokerLocation) => {
    if (location.primary) {
      setVisible(true);
      setAlertMessage({
        type: 'error',
        message:
          'There must be a primary location. Please create or assign another primary location before removing this one.',
      });
    } else {
      const adminsWithLocations = broker.admins.filter((t) =>
        t.locations.includes(location.inPlaceId)
      );
      if (adminsWithLocations && !isEmpty(adminsWithLocations)) {
        setVisible(true);
        setAlertMessage({
          type: 'error',
          message: 'This location is already assigned to a broker admin.',
        });
        return;
      }
      const allOthers = broker.locations.filter(
        (t) => t.name !== location.name
      );
      const updatedBroker = { ...broker, locations: [...allOthers] };
      dispatch(addBroker(JSON.parse(JSON.stringify(updatedBroker))));
      setVisible(true);
      setAlertMessage({
        type: 'success',
        message: 'Location has been removed.',
      });
    }
  };

  const handleEdit = (location: BrokerLocation) => {
    setExpanded(true);
    setEditLocationId(location.inPlaceId);
  };
  const handlePrimaryLocation = (location: BrokerLocation) => {
    const editLocationFilter = broker.locations.filter(
      (brokerLocation: BrokerLocation) =>
        brokerLocation.inPlaceId === location.inPlaceId
    );
    if (editLocationFilter) {
      const editPrimaryLocation = editLocationFilter[0];
      if (editPrimaryLocation) {
        const updatedLocations = broker.locations.map((brokerLocation) => {
          const locationClone = cloneDeep(brokerLocation);
          const isPrimary = locationClone.inPlaceId === location.inPlaceId;
          locationClone.primary = isPrimary;
          return locationClone;
        });
        const updatedBroker = {
          ...broker,
          locations: [...updatedLocations],
        };
        dispatch(addBroker(JSON.parse(JSON.stringify(updatedBroker))));
        setVisible(true);
        setAlertMessage({
          type: 'success',
          message: `${location.name} is now the primary location.`,
        });
      }
    }
  };

  const onClickNext = () => {
    const primaryLocation = broker.locations.filter(
      (location: BrokerLocation) => location.primary
    );
    if (isEmpty(primaryLocation)) {
      setVisible(true);
      setAlertMessage({
        type: 'error',
        message: 'There must be at least one primary location.',
      });
    } else {
      nextStep();
    }
  };

  const addBrokerLocationsActions = (data: BrokerLocation) => {
    return (
      <Menu>
        <Menu.Item key={EDIT_ACTION_KEY} onClick={() => handleEdit(data)}>
          <span data-cy="actionsDropDownOption">Edit</span>
        </Menu.Item>
        {!data.primary && (
          <Menu.Item
            key={MAKE_PRIMARY_LOCATION_ACTION_KEY}
            onClick={() => handlePrimaryLocation(data)}
          >
            <span data-cy="actionsDropDownOption">Make Primary Location</span>
          </Menu.Item>
        )}
        <Menu.Item
          key={DELETE_ACTION_KEY}
          onClick={() => onLocationRemove(data)}
        >
          <span data-cy="actionsDropDownOption">Remove</span>
        </Menu.Item>
      </Menu>
    );
  };

  const closeAlert = () => setVisible(false);

  const brokerListTableColumns: DataColumn[] = [
    {
      title: 'LOCATION',
      dataIndex: 'location',
      key: 'location',
      width: '60%',
      sorter: (a, b) => {
        a = a.location || '';
        b = b.location || '';
        return a.localeCompare(b);
      },
      defaultSortOrder: 'descend',
      ellipsis: {
        showTitle: false,
      },
      render: (location) => <OverflowPopover>{location}</OverflowPopover>,
    },
    {
      title: 'PRIMARY LOCATION',
      dataIndex: 'primary',
      key: 'primary',
      width: '20%',
      render: (colData) =>
        colData ? (
          <CheckOutlined className={styles.primaryLocationCol} />
        ) : (
          <></>
        ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      render: (colData) => {
        return (
          <SelectDropdown
            overlay={addBrokerLocationsActions(colData)}
            dataCy="actionsDropDown"
          />
        );
      },
      align: 'center',
    },
  ];

  return (
    <div>
      <InfoBanner
        title="Broker Locations & Access"
        description="Broker Locations can be used to assign broker team members to clients served by that location. If the broker only has one location then all their admins will have access to all clients. If a broker has multiple locations then their admins can be assigned to one or more locations and will see clients served by that location. "
        logo={<img src={iconInfo} alt="info icon" />}
      />
      {visible && (
        <AlertMessage
          type={alertMessage.type}
          message={alertMessage.message}
          closeAlert={closeAlert}
        />
      )}
      <DataTable
        data={convertBrokerLocationsForTableRender(broker.locations)}
        columns={brokerListTableColumns}
        {...rest}
      />
      <Expandable
        header={
          !isEmpty(broker.locations) && broker.locations.length === 1
            ? '+ Add Location'
            : '+ Add Another Location'
        }
        isExpanded={expanded}
        onChange={(expanded) => setExpanded(true)}
        dataCy="addLocationBtn"
      >
        <BrokerLocationForm
          expanded={expanded}
          preLoadInPlaceId={editLocationId}
          onClose={() => {
            setExpanded(false);
            setEditLocationId(null);
          }}
          locationCount={
            broker && broker.locations ? broker.locations.length : null
          }
          isEdit={editLocationId !== null}
        />
      </Expandable>
      {!expanded && (
        <div>
          <NextButton
            nextStep={onClickNext}
            disabled={
              broker.locations === undefined || broker.locations.length === 0
            }
          />
          <FooterText messageText={brokerFooterText} />
        </div>
      )}
    </div>
  );
};

export default BrokerLocationList;
