class DeductiblesForIndividualWithFamily {
  individualInNetworkCost: string;
  individualOutOfNetworkCost: string;
  familyInNetworkCost: string;
  familyOutOfNetworkCost: string;
  individualWithinFamilyInNetworkCost: string;
  individualWithinFamilyOutOfNetworkCost: string;
  individualInNetworkApplicable: string;
  individualOutOfNetworkApplicable: string;
  familyInNetworkApplicable: string;
  familyOutOfNetworkApplicable: string;
  individualWithinFamilyInNetworkApplicable: string;
  individualWithinFamilyOutOfNetworkApplicable: string;

  constructor() {
    this.individualInNetworkCost = '';
    this.individualOutOfNetworkCost = '';
    this.familyInNetworkCost = '';
    this.familyOutOfNetworkCost = '';
    this.individualWithinFamilyInNetworkCost = '';
    this.individualWithinFamilyOutOfNetworkCost = '';
    this.individualInNetworkApplicable = '';
    this.individualOutOfNetworkApplicable = '';
    this.familyInNetworkApplicable = '';
    this.familyOutOfNetworkApplicable = '';
    this.individualWithinFamilyInNetworkApplicable = '';
    this.individualWithinFamilyOutOfNetworkApplicable = '';
  }
}
export default DeductiblesForIndividualWithFamily;
