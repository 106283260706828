import React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  getConfigurationTypeIcon,
  locationBasedConfigurations,
  configurationsType,
} from 'modules/tools/types/configurationTypes';
import DraftEmployer from 'model/DraftEmployer';
import styles from './employerLocationChange.module.less';

type Props = {
  currentLocation?: string;
  transferLocation?: string;
  isModalOpen: boolean;
  title: string;
  setIsLocationSaveModal: Function;
  isEmployerEdit: boolean;
  featureKeys: string[];
  saveData: Function;
  updatedEmployer?: DraftEmployer;
  saveReassignLocation?: Function;
};

const EmployerLocationChangeModal = (props: Props) => {
  const {
    currentLocation,
    transferLocation,
    isModalOpen,
    title,
    setIsLocationSaveModal,
    isEmployerEdit,
    featureKeys,
    saveData,
    updatedEmployer,
    saveReassignLocation,
  } = props;

  const ALERT_MESSAGE_FOR_EMPLOYER = (
    <span>
      By changing this employer&apos;s location, certain tools will become
      unavailable. Exercise caution when changing an employer&apos;s location.
    </span>
  );

  const ALERT_MESSAGE_FOR_BROKER = (
    <span>
      By re-assigning this location, certain tools will become unavailable.
      Exercise caution when re-assigning a broker location.
    </span>
  );

  const saveChanges = () => {
    if (isEmployerEdit) {
      setIsLocationSaveModal(false);
      saveData(updatedEmployer);
    } else if (saveReassignLocation) {
      saveReassignLocation();
      setIsLocationSaveModal(false);
    }
  };

  return (
    <ConfirmationDialog
      width={600}
      className={styles.confirmationDialogLocationReassign}
      confirmText="Continue"
      title={
        <>
          {title}
          {!isEmployerEdit && (
            <div className={styles.locationHeaderArea}>
              {currentLocation} <ArrowRightOutlined /> {transferLocation}
            </div>
          )}
        </>
      }
      isCancelLink
      visible={isModalOpen}
      cancelText="Cancel"
      confirmBtnFullWidth
      onConfirm={saveChanges}
      closeModal={() => {
        setIsLocationSaveModal(false);
      }}
    >
      <FixedAlertMessage
        type="error"
        message={
          isEmployerEdit ? ALERT_MESSAGE_FOR_EMPLOYER : ALERT_MESSAGE_FOR_BROKER
        }
      />
      <h4>
        <b>
          {isEmployerEdit
            ? 'Tools not available in new location'
            : 'Tools not available in re-assigned location'}
        </b>
      </h4>
      <p>
        {isEmployerEdit
          ? "These tools aren't enabled in the locations you've designated for the employer and will no longer be accessible for them. Are you sure you want to proceed in the changing this employer location?"
          : "The following tools aren't enabled in the re-assigned location you've designated. Are you sure you want to proceed?"}
      </p>
      {featureKeys.length > 0 &&
        Object?.entries(featureKeys)?.map(([key, value]) => {
          return (
            <Row align="middle" key={key} className={styles.configurations}>
              <img
                className={styles.headerIcon}
                src={
                  getConfigurationTypeIcon(
                    locationBasedConfigurations[value as configurationsType]
                  ).icon
                }
              />
              <span className={styles.headerText}>
                {
                  getConfigurationTypeIcon(
                    locationBasedConfigurations[value as configurationsType]
                  ).name
                }
              </span>
            </Row>
          );
        })}
      <div className={styles.supportLinkFooter}>
        If you have any additional questions, Please contact{' '}
        <a href="mailto:support@planyear.com">
          <span className={styles.emailLinkClickableArea}>
            PlanYear Support
          </span>
          .
        </a>
      </div>
    </ConfirmationDialog>
  );
};

export default EmployerLocationChangeModal;
