import { useEffect, useRef } from 'react';

import RatesUPWrapper from 'modules/plans/components/Rates/RatesUPWrapper';
import PanelSection from 'modules/plans/enums/PanelSection';
import { useAppSelector } from 'hooks/redux';

type Props = { setState: Function; state: any; formRef: any };

const PremiumsAndContributions = ({ setState, state, formRef }: Props) => {
  const ratesRef = useRef();
  const planCreateType = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.selectPlanCreateType
  );
  const isPlanReviewed = useAppSelector(
    (state) =>
      state.renewals.addProposalToPlanYear.isCurrentSelectedPlanReviewed
  );
  const isPlanMerged = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.isCurrentSelectedPlanMerged
  );

  const isMerging =
    planCreateType === 'MERGE' && !(isPlanReviewed === isPlanMerged);

  useEffect(() => {
    (ratesRef?.current as any).prepareForReview();
  }, [ratesRef]);

  return (
    <div>
      <RatesUPWrapper
        medicalPlan={state}
        currentSection={PanelSection.RATES}
        dbgMetaData={''}
        setMedicalPlan={setState}
        isReviewProp={true}
        isReviewHighlight={isMerging}
        ratesRef={ratesRef}
        ref={formRef}
      />
    </div>
  );
};

export default PremiumsAndContributions;
