import axios from 'axios';
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { AdditionalPlan } from 'model/plans/AdditionalPlan';

import MetaData from 'model/MetaData';
import PaginationConfig from 'model/PaginationConfig';
import * as VoluntaryBenefitPlanService from 'modules/plans/services/VoluntaryBenefitPlanService';
import ErrorDetails from 'model/ErrorDetails';
import ClonePlanDocument from 'model/plans/ClonePlanDocument';
import { clonePlanDocuments } from './planDocumentsSlice';

export type VoluntaryBenefitPlanServiceState = {
  inProgress: boolean;
  error: any;
  requestType: string;
  voluntaryBenefitPlan: AdditionalPlan;
  voluntaryBenefitPlanList: {
    content: Array<AdditionalPlan>;
    metadata: MetaData;
  };
};

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  voluntaryBenefitPlan: {} as AdditionalPlan,
  voluntaryBenefitPlanList: { content: [], metadata: {} },
} as VoluntaryBenefitPlanServiceState;

const voluntaryBenefitPlanSlice = createSlice({
  name: 'voluntaryBenefitPlan',
  initialState,
  reducers: {
    createVoluntaryBenefitPlan: (
      state,
      action: PayloadAction<AdditionalPlan>
    ) => {
      state.voluntaryBenefitPlan = action.payload;
      state.inProgress = false;
      state.error = null;
      state.requestType = action.type;
    },
    updateVoluntaryBenefitPlanInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    updateVoluntaryBenefitPlanCompleted: (
      state,
      action: PayloadAction<AdditionalPlan>
    ) => {
      state.inProgress = false;
      state.voluntaryBenefitPlan = {
        ...state.voluntaryBenefitPlan,
        ...action.payload,
      };
      state.error = null;
      state.requestType = action.type;
    },
    updateVoluntaryBenefitPlanFailed: (state, action) => {
      state.inProgress = false;
      state.error = JSON.parse(JSON.stringify(action.payload));
      state.requestType = action.type;
    },
    getVoluntaryBenefitPlanListInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
    },
    getVoluntaryBenefitPlanListCompleted: (
      state,
      action: PayloadAction<any>
    ) => {
      const { payload } = action;
      state.inProgress = false;
      state.error = null;
      state.voluntaryBenefitPlanList = payload;
    },
    getVoluntaryBenefitPlanListFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = { response: action.payload };
    },
    getPlanByIdStarted: (state) => {
      state.inProgress = true;
      state.voluntaryBenefitPlan = {} as AdditionalPlan;
      state.error = null;
    },
    getPlanByIdCompleted: (state, action: PayloadAction<AdditionalPlan>) => {
      state.inProgress = false;
      state.voluntaryBenefitPlan = action.payload;
      state.error = null;
    },
    getPlanByIdFailed: (state, action) => {
      state.inProgress = false;
      state.voluntaryBenefitPlan = {} as AdditionalPlan;
      state.error = { response: action.payload };
    },
    clearVolBenefitPlanApiErrors: (state) => {
      state.error = null;
    },
  },
});

export const {
  updateVoluntaryBenefitPlanInProgress,
  createVoluntaryBenefitPlan,
  updateVoluntaryBenefitPlanCompleted,
  updateVoluntaryBenefitPlanFailed,
  getVoluntaryBenefitPlanListInProgress,
  getVoluntaryBenefitPlanListCompleted,
  getVoluntaryBenefitPlanListFailed,
  getPlanByIdStarted,
  getPlanByIdCompleted,
  getPlanByIdFailed,
  clearVolBenefitPlanApiErrors,
} = voluntaryBenefitPlanSlice.actions;

export default voluntaryBenefitPlanSlice.reducer;

export const saveVoluntaryBenefitPlan = (
  plan: AdditionalPlan,
  onSave: Function,
  cloneDocuments?: boolean,
  sourcePlanId?: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateVoluntaryBenefitPlanInProgress());
      let response = {} as any;
      if (plan.id) {
        response = await VoluntaryBenefitPlanService.saveVoluntaryBenefitPlan(
          plan,
          true
        );
      } else {
        response = await VoluntaryBenefitPlanService.saveVoluntaryBenefitPlan(
          plan
        );
      }
      const data = response.data;
      dispatch(
        updateVoluntaryBenefitPlanCompleted(JSON.parse(JSON.stringify(data)))
      );
      if (data && cloneDocuments && plan.documents && sourcePlanId) {
        const clonePlanDoc: ClonePlanDocument = {
          employerId: data.employerId,
          sourcePlanId: sourcePlanId,
          targetPlanId: data.id,
          benefitCategory: 'VOLUNTARY_BENEFIT',
          benefitKind: plan.benefitKind,
        };
        dispatch(clonePlanDocuments(clonePlanDoc));
      }
      onSave(data.id);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            updateVoluntaryBenefitPlanFailed({
              data: error.response.data,
            } as ErrorDetails)
          );
        }
      }
    }
  };
};

export const getVoluntaryBenefitPlanList = (
  { page, size, sort, query }: PaginationConfig,
  employerId: string,
  planYearId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getVoluntaryBenefitPlanListInProgress());
    try {
      const response =
        await VoluntaryBenefitPlanService.getVoluntaryBenefitPlanList(
          page,
          size,
          sort,
          query,
          employerId,
          planYearId
        );
      dispatch(getVoluntaryBenefitPlanListCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getVoluntaryBenefitPlanListFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const findPlanById = (planId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPlanByIdStarted());
    try {
      const response = await VoluntaryBenefitPlanService.getVoluntaryPlanById(
        planId
      );
      dispatch(getPlanByIdCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(getPlanByIdFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
};
