import { ChangeEvent } from 'react';
import { Col, Input, Row } from 'antd';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseCircleFilled,
  SearchOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin, RenderCurrentScaleProps } from '@react-pdf-viewer/zoom';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { searchPlugin } from '@react-pdf-viewer/search';

import { PDF_DIST_URL } from 'util/apiUtil';
import LinkButton from 'components/buttons/LinkButton/LinkButton';

import styles from './pdfViewer.module.less';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';

type Props = {
  file: string | Uint8Array;
  fileType: string;
};

const PdfViewer = (props: Props) => {
  const { file, fileType } = props;

  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const searchPluginInstance = searchPlugin();
  const { ZoomIn, ZoomOut, CurrentScale } = zoomPluginInstance;
  const { GoToNextPage, GoToPreviousPage, CurrentPageLabel } =
    pageNavigationPluginInstance;
  const { Search } = searchPluginInstance;

  return (
    <>
      <Row justify="space-between" className={styles.controllerWrapper}>
        <Col>
          <div className={styles.titleWrapper}>{fileType}</div>
        </Col>
        <Col>
          <div className={styles.navigationButtonWrapper}>
            <GoToPreviousPage>
              {({ onClick, isDisabled }) => (
                <LinkButton
                  className={styles.navPageButton}
                  onClick={onClick}
                  disabled={isDisabled}
                >
                  <CaretLeftOutlined /> Previous
                </LinkButton>
              )}
            </GoToPreviousPage>
            <CurrentPageLabel />
            <GoToNextPage>
              {({ onClick, isDisabled }) => (
                <LinkButton
                  className={styles.navPageButton}
                  onClick={onClick}
                  disabled={isDisabled}
                >
                  Next
                  <CaretRightOutlined />
                </LinkButton>
              )}
            </GoToNextPage>
          </div>
        </Col>
        <Col className={styles.searchZoomContainer}>
          <div>
            <Search>
              {({ keyword, setKeyword, search, clearKeyword }) => (
                <div className={styles.searchWrapper}>
                  <Input
                    prefix={
                      <SearchOutlined
                        className={styles.searchIcon}
                        onClick={search}
                      />
                    }
                    className={styles.searchInput}
                    value={keyword}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setKeyword(e.target.value)
                    }
                    onPressEnter={search}
                    suffix={
                      keyword && (
                        <CloseCircleFilled
                          className={styles.clearIcon}
                          onClick={clearKeyword}
                        />
                      )
                    }
                  />
                </div>
              )}
            </Search>
          </div>

          <div className={styles.zoomButtonWrapper}>
            <ZoomOut>
              {({ onClick }) => (
                <ZoomOutOutlined className={styles.zoomOut} onClick={onClick} />
              )}
            </ZoomOut>
            <CurrentScale>
              {(props: RenderCurrentScaleProps) => (
                <>{`${Math.round(props.scale * 100)}%`}</>
              )}
            </CurrentScale>

            <ZoomIn>
              {({ onClick }) => (
                <ZoomInOutlined onClick={onClick} className={styles.zoomIn} />
              )}
            </ZoomIn>
          </div>
        </Col>
      </Row>

      <Worker workerUrl={PDF_DIST_URL}>
        <div className={styles.pdfViewerWrapper}>
          <Viewer
            fileUrl={file}
            withCredentials={true}
            plugins={[
              zoomPluginInstance,
              pageNavigationPluginInstance,
              searchPluginInstance,
            ]}
          />
        </div>
      </Worker>
    </>
  );
};

export default PdfViewer;
