import { Feature } from 'api/navigationSlice';
export const featuresKeys = (
  features: Feature[],
  isErAdmin: boolean
): string[] => {
  return features
    .filter((feature: Feature) => {
      if (isErAdmin && feature.enabled && feature.employerVisibilityApplicable)
        return feature.employerVisibilityEnabled;
      return feature.enabled;
    })
    .map((feature: Feature) => feature.feature);
};
