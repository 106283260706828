import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import axios, { AxiosResponse } from 'axios';
import MetaData from 'model/MetaData';
import { TaxAdvantagedAccountPlan } from 'model/plans/TaxAdvantagedAccountPlan';

import * as PlanService from 'modules/plans/services/PlanService';
import * as TaxAdvantagedAccountPlanService from 'modules/plans/services/TaxAdvantagedAccountPlanService';
import PaginationConfig from 'model/PaginationConfig';
import ErrorDetails from 'model/ErrorDetails';
import DocumentUploadStatus from 'model/DocumentUploadStatus';
import ClonePlanDocument from 'model/plans/ClonePlanDocument';
import { clonePlanDocuments } from './planDocumentsSlice';

export type TaxAdvantagedAccountPlanState = {
  inProgress: boolean;
  error: any;
  requestType: string;
  taxAdvantagedAccountPlan: TaxAdvantagedAccountPlan;
  taxAdvantagedAccountPlanList: {
    content: Array<TaxAdvantagedAccountPlan>;
    metadata: MetaData;
  };
  documentReferences: {
    [key: string]: {
      uploading: boolean;
      reference: string;
      blobUrl: string;
      fileName: string;
      documentUploadStatus: DocumentUploadStatus;
    };
  };
  cancelDocumentUpload: boolean;
};

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  taxAdvantagedAccountPlan: {} as TaxAdvantagedAccountPlan,
  taxAdvantagedAccountPlanList: { content: [], metadata: {} },
  documentReferences: {},
  cancelDocumentUpload: false,
} as TaxAdvantagedAccountPlanState;

const taxAdvantagedAccountPlanSlice = createSlice({
  name: 'taxAdvantagedAccountPlan',
  initialState,
  reducers: {
    createTaxAdvantagedAccountPlan: (
      state,
      action: PayloadAction<TaxAdvantagedAccountPlan>
    ) => {
      state.taxAdvantagedAccountPlan = action.payload;
      state.inProgress = false;
      state.error = null;
      state.requestType = action.type;
    },
    updateTaxAdvantagedAccountPlanInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    updateTaxAdvantagedAccountPlanCompleted: (
      state,
      action: PayloadAction<TaxAdvantagedAccountPlan>
    ) => {
      state.inProgress = false;
      state.taxAdvantagedAccountPlan = {
        ...state.taxAdvantagedAccountPlan,
        ...action.payload,
      };
      state.error = null;
      state.requestType = action.type;
    },
    updateTaxAdvantagedAccountPlanFailed: (state, action) => {
      state.inProgress = false;
      state.error = JSON.parse(JSON.stringify(action.payload));
      state.requestType = action.type;
    },
    documentUploadStarted: (state, { payload }: PayloadAction<any>) => {
      state.cancelDocumentUpload = false;
      state.inProgress = true;
      state.documentReferences = {
        ...state.documentReferences,
        [payload.documentType]: {
          uploading: true,
        },
      };
    },
    documentUploadCompleted: (state, { payload }: PayloadAction<any>) => {
      state.inProgress = false;
      if (!state.cancelDocumentUpload) {
        state.documentReferences = {
          ...state.documentReferences,
          [payload.documentType]: {
            uploading: false,
            reference: payload.reference,
            blobUrl: payload.blobUrl,
            fileName: payload.fileName,
            documentUploadStatus: 'ADDED',
          },
        };
      }
    },
    documentUploadFailed: (state, { payload }: PayloadAction<any>) => {
      state.inProgress = false;
      if (!state.cancelDocumentUpload) {
        state.documentReferences = {
          ...state.documentReferences,
          [payload.documentType]: {
            uploading: false,
            error: { response: payload.error },
          },
        };
      }
    },
    getTaxAdvantagedAccountPlansListInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
    },
    getTaxAdvantagedAccountPlansListCompleted: (
      state,
      action: PayloadAction<any>
    ) => {
      const { payload } = action;
      state.inProgress = false;
      state.error = null;
      state.taxAdvantagedAccountPlanList = payload;
      state.requestType = action.type;
    },
    getTaxAdvantagedAccountPlansListFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = { response: action.payload };
      state.requestType = action.type;
    },
    clearDocuments: (state) => {
      state.documentReferences = {};
      state.cancelDocumentUpload = true;
      state.inProgress = false;
    },
    unsetApiError: (state) => {
      state.error = null;
    },
    getPlanByIdStarted: (state) => {
      state.inProgress = true;
      state.taxAdvantagedAccountPlan = {} as TaxAdvantagedAccountPlan;
      state.error = null;
    },
    getPlanByIdCompleted: (
      state,
      action: PayloadAction<TaxAdvantagedAccountPlan>
    ) => {
      state.inProgress = false;
      state.taxAdvantagedAccountPlan = action.payload;
      state.error = null;
    },
    getPlanByIdFailed: (state, action) => {
      state.inProgress = false;
      state.taxAdvantagedAccountPlan = {} as TaxAdvantagedAccountPlan;
      state.error = { response: action.payload };
    },
    updateDocumentStatus: (
      state,
      { payload }: PayloadAction<{ documentType: string; status: string }>
    ) => {
      state.taxAdvantagedAccountPlan.documentReferences = {
        ...state.taxAdvantagedAccountPlan.documentReferences,
        [payload.documentType]: {
          documentUploadStatus: payload.status,
        },
      };
    },
    removeDocumentReference: (state, { payload }: PayloadAction<any>) => {
      state.documentReferences = {
        ...state.documentReferences,
        [payload]: {
          documentUploadStatus: 'REMOVED',
        },
      };
    },
  },
});

export const {
  createTaxAdvantagedAccountPlan,
  updateTaxAdvantagedAccountPlanInProgress,
  updateTaxAdvantagedAccountPlanCompleted,
  updateTaxAdvantagedAccountPlanFailed,
  documentUploadStarted,
  documentUploadCompleted,
  documentUploadFailed,
  getTaxAdvantagedAccountPlansListInProgress,
  getTaxAdvantagedAccountPlansListCompleted,
  getTaxAdvantagedAccountPlansListFailed,
  clearDocuments,
  unsetApiError,
  getPlanByIdStarted,
  getPlanByIdCompleted,
  getPlanByIdFailed,
  updateDocumentStatus,
  removeDocumentReference,
} = taxAdvantagedAccountPlanSlice.actions;

export default taxAdvantagedAccountPlanSlice.reducer;

export const uploadTemporaryDocument = (file: File, documentType: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(documentUploadStarted({ documentType }));
    PlanService.uploadPlanDocuments(file.name, file)
      .then(({ data }: AxiosResponse) => {
        dispatch(
          documentUploadCompleted({
            documentType,
            reference: data,
            fileName: file.name,
            blobUrl: URL.createObjectURL(file),
          })
        );
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          dispatch(
            documentUploadFailed({
              documentType,
              error: JSON.parse(JSON.stringify(error.response)),
            })
          );
        } else {
          console.error(error);
        }
      });
  };
};

export const saveTaxAdvantagedAccountPlan = (
  plan: TaxAdvantagedAccountPlan,
  onSave: Function,
  cloneDocuments?: boolean,
  sourcePlanId?: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateTaxAdvantagedAccountPlanInProgress());
      let response = {} as any;
      if (plan.id) {
        response =
          await TaxAdvantagedAccountPlanService.updateTaxAdvantagedAccountPlan(
            plan
          );
      } else {
        response =
          await TaxAdvantagedAccountPlanService.createTaxAdvantagedAccountPlan(
            plan
          );
      }
      const data = response?.data;
      dispatch(updateTaxAdvantagedAccountPlanCompleted(data));
      if (data && cloneDocuments && plan.documents && sourcePlanId) {
        const clonePlanDoc: ClonePlanDocument = {
          employerId: data.employerId,
          sourcePlanId: sourcePlanId,
          targetPlanId: data.id,
          benefitCategory: 'SAVING',
          benefitKind: plan.benefitKind,
        };
        dispatch(clonePlanDocuments(clonePlanDoc));
      }
      onSave(data.id);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        dispatch(
          updateTaxAdvantagedAccountPlanFailed({
            data: error.response.data,
          } as ErrorDetails)
        );
      }
    }
  };
};

export const getTaxAdvantagedAccountPlansList = (
  { page, size, sort, query }: PaginationConfig,
  employerId: string,
  planYearId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getTaxAdvantagedAccountPlansListInProgress());
    try {
      const response =
        await TaxAdvantagedAccountPlanService.getTaxAdvantagedAccountPlansList(
          page,
          size,
          sort,
          query,
          employerId,
          planYearId
        );

      dispatch(getTaxAdvantagedAccountPlansListCompleted(response?.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getTaxAdvantagedAccountPlansListFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const clearDocumentsAttached = () => (dispatch: Dispatch) => {
  dispatch(clearDocuments());
};

export const clearApiError = () => (dispatch: Dispatch) => {
  dispatch(unsetApiError());
};

export const findPlanById = (planId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPlanByIdStarted());
    try {
      const response =
        await TaxAdvantagedAccountPlanService.getTaxAdvantagedPlanById(planId);
      dispatch(getPlanByIdCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(getPlanByIdFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
};
