import { FC, useState } from 'react';

import { Col, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';
import {
  BasicPlans,
  NON_MDV_ADDITIONAL_SERVICES,
  VoluntaryPlans,
} from 'modules/plans/constants';
import PlanDocumentsOverview from 'modules/plans/components/OverviewPage/PlanDocumentsOverview/PlanDocumentOverview';
import { LifePlan } from 'model/plans/LifePlan';

import {
  displayNumberWithDecimals,
  isNullOrUndefined,
} from 'modules/plans/utils';
import FundingType from 'modules/plans/enums/FundingType';
import styles from './disabilityPlanInformationOverview.module.less';

type DisabilityPlanInformationOverviewProps = {
  plan: LifePlan;
  heading?: string;
  isAdvancedProfile: boolean;
};

const DisabilityPlanInformationOverview: FC<
  DisabilityPlanInformationOverviewProps
> = (props) => {
  const { plan, isAdvancedProfile } = props;

  const [viewPlanDocumentsModal, setViewPlanDocumentsModal] = useState(false);

  const fundingType = plan.fundingType;

  const getNonMdvDescription = (STD: string[], value: string): string => {
    const nonMDVServiceType =
      plan?.stdServices?.find((obj) => obj.service == value) ||
      plan?.ltdServices?.find((obj) => obj.service == value);
    if (nonMDVServiceType) {
      if (nonMDVServiceType.description?.trim() === '') {
        return '-';
      } else {
        return nonMDVServiceType.description;
      }
    }
    return '-';
  };

  const isNonMdvSkip = (STD: string[], value: string): boolean => {
    return (
      !(
        plan?.stdServices?.some((obj) => obj.service === value) ||
        plan?.ltdServices?.some((obj) => obj.service === value)
      ) || !isAdvancedProfile
    );
  };

  const contributionOverview = [
    {
      label: 'Enrollments',
      value: plan?.hasOwnProperty('enrollment') ? `${plan.enrollment}` : '-',
      types: [
        VoluntaryPlans.VOLUNTARY_STD.value,
        VoluntaryPlans.VOLUNTARY_LTD.value,
      ],
    },
    {
      label: 'STD Benefit',
      value:
        plan?.benefitPercentage?.trim() === '' ||
        isEmpty(plan?.benefitPercentage)
          ? '-'
          : plan.benefitPercentage,
      types: [BasicPlans.STD.value, VoluntaryPlans.VOLUNTARY_STD.value],
    },
    {
      label: 'LTD Benefit',
      value:
        plan?.benefitPercentage?.trim() === '' ||
        isEmpty(plan?.benefitPercentage)
          ? '-'
          : plan.benefitPercentage,
      types: [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value],
    },
    {
      label: 'Max Weekly Benefit',
      value:
        plan?.weeklyBenefitMax?.trim() === '' || isEmpty(plan?.weeklyBenefitMax)
          ? '-'
          : plan.weeklyBenefitMax,
      types: [BasicPlans.STD.value, VoluntaryPlans.VOLUNTARY_STD.value],
    },
    {
      label: 'Max Monthly Benefit',
      value:
        plan?.monthlyBenefitMax?.trim() === '' ||
        isEmpty(plan?.monthlyBenefitMax)
          ? '-'
          : plan.monthlyBenefitMax,
      types: [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value],
    },
    {
      label: 'Waiting Period',
      value: plan.waitingPeriod ? `${plan.waitingPeriod}` : '-',
      types: [
        BasicPlans.STD.value,
        BasicPlans.LTD.value,
        VoluntaryPlans.VOLUNTARY_STD.value,
        VoluntaryPlans.VOLUNTARY_LTD.value,
      ],
    },
    {
      label: 'Max Benefit Duration',
      value:
        plan?.benefitDurationSTD?.trim() === '' ||
        isEmpty(plan?.benefitDurationSTD)
          ? '-'
          : plan.benefitDurationSTD,
      types: [BasicPlans.STD.value, VoluntaryPlans.VOLUNTARY_STD.value],
    },
    {
      label: 'Max Benefit Duration',
      value:
        plan?.benefitDurationLTD?.trim() === '' ||
        isEmpty(plan?.benefitDurationLTD)
          ? '-'
          : plan.benefitDurationLTD,
      types: [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value],
    },
    {
      label: 'Definition of Disability',
      value:
        plan?.definitionOfDisability?.trim() === '' ||
        isEmpty(plan?.definitionOfDisability)
          ? '-'
          : plan.definitionOfDisability,
      types: [BasicPlans.STD.value],
    },
    {
      label: 'Definition of Disability',
      value:
        plan?.definitionOfDisability?.trim() === '' ||
        isEmpty(plan?.definitionOfDisability)
          ? '-'
          : plan.definitionOfDisability,
      types: [BasicPlans.LTD.value],
    },
    {
      label: 'Pre-Existing Conditions',
      value:
        plan?.preExistingConditions?.trim() === '' ||
        isEmpty(plan?.preExistingConditions)
          ? '-'
          : plan.preExistingConditions,
      types: [BasicPlans.STD.value],
    },
    {
      label: 'State Disability Integration',
      value:
        plan?.stateDisabilityIntegration?.trim() === '' ||
        isEmpty(plan?.stateDisabilityIntegration)
          ? '-'
          : plan.stateDisabilityIntegration,
      types: [BasicPlans.STD.value],
    },
    {
      label: 'Own Occupation Period',
      value:
        plan?.ownOccupationPeriod?.trim() === '' ||
        isEmpty(plan?.ownOccupationPeriod)
          ? '-'
          : plan.ownOccupationPeriod,
      types: [BasicPlans.LTD.value],
    },
    fundingType === FundingType.FULLY_INSURED
      ? {
          label: 'STD Rate',
          value: !isNullOrUndefined(plan.rate)
            ? `${displayNumberWithDecimals(plan.rate, 3)}`
            : '-',
          types: [BasicPlans.STD.value],
        }
      : undefined,
    {
      label: 'LTD Rate',
      value: !isNullOrUndefined(plan.rate)
        ? `${displayNumberWithDecimals(plan.rate, 3)}`
        : '-',
      types: [BasicPlans.LTD.value],
    },
    fundingType !== FundingType.SELF_FUNDED
      ? {
          label: 'Volume',
          value: !isNullOrUndefined(plan?.volume)
            ? `$${displayNumberWithDecimals(plan?.volume)}`
            : '-',
          types: [
            BasicPlans.STD.value,
            BasicPlans.LTD.value,
            VoluntaryPlans.VOLUNTARY_STD.value,
            VoluntaryPlans.VOLUNTARY_LTD.value,
          ],
        }
      : undefined,
    fundingType === FundingType.SELF_FUNDED
      ? {
          label: 'Annual Est. Claims',
          value: !isNullOrUndefined(plan.annualEstimatedClaims)
            ? `$${displayNumberWithDecimals(plan.annualEstimatedClaims)}`
            : '-',
          types: [BasicPlans.STD.value],
        }
      : undefined,
    {
      label: 'Definition of Earnings',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.DEFINITION_OF_EARNINGS,
        'DEFINITION_OF_EARNINGS'
      ),
      types: [
        BasicPlans.STD.value,
        BasicPlans.LTD.value,
        VoluntaryPlans.VOLUNTARY_STD.value,
        VoluntaryPlans.VOLUNTARY_LTD.value,
      ],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.DEFINITION_OF_EARNINGS,
        'DEFINITION_OF_EARNINGS'
      ),
    },
    {
      label: 'Waiting Period Injury',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.WAITING_PERIOD_INJURY,
        'WAITING_PERIOD_INJURY'
      ),
      types: [BasicPlans.STD.value, VoluntaryPlans.VOLUNTARY_STD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.WAITING_PERIOD_INJURY,
        'WAITING_PERIOD_INJURY'
      ),
    },
    {
      label: 'Waiting Period Illness',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.WAITING_PERIOD_ILLNESS,
        'WAITING_PERIOD_ILLNESS'
      ),
      types: [BasicPlans.STD.value, VoluntaryPlans.VOLUNTARY_STD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.WAITING_PERIOD_ILLNESS,
        'WAITING_PERIOD_ILLNESS'
      ),
    },
    {
      label: 'Social Security Integration',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.SOCIAL_SECURITY_INTEGRATION,
        'SOCIAL_SECURITY_INTEGRATION'
      ),
      types: [
        BasicPlans.STD.value,
        BasicPlans.LTD.value,
        VoluntaryPlans.VOLUNTARY_STD.value,
        VoluntaryPlans.VOLUNTARY_LTD.value,
      ],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.SOCIAL_SECURITY_INTEGRATION,
        'SOCIAL_SECURITY_INTEGRATION'
      ),
    },
    {
      label: 'W-2 Preparation',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.W2_PREPARATION,
        'W2_PREPARATION'
      ),
      types: [BasicPlans.STD.value, VoluntaryPlans.VOLUNTARY_STD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.W2_PREPARATION,
        'W2_PREPARATION'
      ),
    },
    {
      label: 'FICA Match',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.FICA_MATCH,
        'FICA_MATCH'
      ),
      types: [
        BasicPlans.STD.value,
        BasicPlans.LTD.value,
        VoluntaryPlans.VOLUNTARY_STD.value,
        VoluntaryPlans.VOLUNTARY_LTD.value,
      ],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.FICA_MATCH,
        'FICA_MATCH'
      ),
    },
    {
      label: 'Portability',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.PORTABILITY,
        'PORTABILITY'
      ),
      types: [BasicPlans.STD.value, VoluntaryPlans.VOLUNTARY_STD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.PORTABILITY,
        'PORTABILITY'
      ),
    },
    {
      label: 'Recurrent Disability Provision',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.RECURRENT_DISABILITY_PROVISION,
        'RECURRENT_DISABILITY_PROVISION'
      ),
      types: [
        BasicPlans.STD.value,
        BasicPlans.LTD.value,
        VoluntaryPlans.VOLUNTARY_STD.value,
        VoluntaryPlans.VOLUNTARY_LTD.value,
      ],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.RECURRENT_DISABILITY_PROVISION,
        'RECURRENT_DISABILITY_PROVISION'
      ),
    },
    {
      label: 'Continuity of Coverage',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.CONTINUITY_OF_COVERAGE,
        'CONTINUITY_OF_COVERAGE'
      ),
      types: [
        BasicPlans.STD.value,
        BasicPlans.LTD.value,
        VoluntaryPlans.VOLUNTARY_STD.value,
        VoluntaryPlans.VOLUNTARY_LTD.value,
      ],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.CONTINUITY_OF_COVERAGE,
        'CONTINUITY_OF_COVERAGE'
      ),
    },
    {
      label: 'Convertibility',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.CONVERTIBILITY,
        'CONVERTIBILITY'
      ),
      types: [BasicPlans.STD.value, VoluntaryPlans.VOLUNTARY_STD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.CONVERTIBILITY,
        'CONVERTIBILITY'
      ),
    },
    {
      label: 'Min Monthly Benefit',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.MIN_MONTHLY_BENEFIT,
        'MIN_MONTHLY_BENEFIT'
      ),
      types: [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.MIN_MONTHLY_BENEFIT,
        'MIN_MONTHLY_BENEFIT'
      ),
    },
    {
      label: 'Pre Existing Conditions',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.PRE_EXISTING_CONDITIONS,
        'PRE_EXISTING_CONDITIONS'
      ),
      types: [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.PRE_EXISTING_CONDITIONS,
        'PRE_EXISTING_CONDITIONS'
      ),
    },
    {
      label: 'State Integration',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.STATE_INTEGRATION,
        'STATE_INTEGRATION'
      ),
      types: [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.STATE_INTEGRATION,
        'STATE_INTEGRATION'
      ),
    },
    {
      label: 'Rehabilitation Benefit',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.REHABILITATION_BENEFIT,
        'REHABILITATION_BENEFIT'
      ),
      types: [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.REHABILITATION_BENEFIT,
        'REHABILITATION_BENEFIT'
      ),
    },
    {
      label: 'Worksite Modification Benefit',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.WORKSITE_MODIFICATION_BENEFIT,
        'WORKSITE_MODIFICATION_BENEFIT'
      ),
      types: [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.WORKSITE_MODIFICATION_BENEFIT,
        'WORKSITE_MODIFICATION_BENEFIT'
      ),
    },
    {
      label: 'Return to Work',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.RETURN_TO_WORK,
        'RETURN_TO_WORK'
      ),
      types: [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.RETURN_TO_WORK,
        'RETURN_TO_WORK'
      ),
    },
    {
      label: 'Waiver of Premium',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.WAIVER_OF_PREMIUM,
        'WAIVER_OF_PREMIUM'
      ),
      types: [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.WAIVER_OF_PREMIUM,
        'WAIVER_OF_PREMIUM'
      ),
    },
    {
      label: 'Earnings Test',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.EARNINGS_TEST,
        'EARNINGS_TEST'
      ),
      types: [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.EARNINGS_TEST,
        'EARNINGS_TEST'
      ),
    },
    {
      label: 'Disability Limitations Mental Health',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.DISABILITY_LIMITATIONS_MENTAL_HEALTH,
        'DISABILITY_LIMITATIONS_MENTAL_HEALTH'
      ),
      types: [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.DISABILITY_LIMITATIONS_MENTAL_HEALTH,
        'DISABILITY_LIMITATIONS_MENTAL_HEALTH'
      ),
    },
    {
      label: 'Disability Limitations Substance Abuse',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.DISABILITY_LIMITATIONS_SUBSTANCE_ABUSE,
        'DISABILITY_LIMITATIONS_SUBSTANCE_ABUSE'
      ),
      types: [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.DISABILITY_LIMITATIONS_SUBSTANCE_ABUSE,
        'DISABILITY_LIMITATIONS_SUBSTANCE_ABUSE'
      ),
    },
    {
      label: 'Disability Limitations Self Reported',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.DISABILITY_LIMITATIONS_SELF_REPORTED,
        'DISABILITY_LIMITATIONS_SELF_REPORTED'
      ),
      types: [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.DISABILITY_LIMITATIONS_SELF_REPORTED,
        'DISABILITY_LIMITATIONS_SELF_REPORTED'
      ),
    },
    {
      label: 'EAP Offered',
      value: getNonMdvDescription(
        NON_MDV_ADDITIONAL_SERVICES.EAP_OFFERED,
        'EAP_OFFERED'
      ),
      types: [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value],
      isSkip: isNonMdvSkip(
        NON_MDV_ADDITIONAL_SERVICES.EAP_OFFERED,
        'EAP_OFFERED'
      ),
    },
  ];

  const updateViewPlanDocumentsModal = (value: boolean) => {
    setViewPlanDocumentsModal(value);
  };

  return (
    <div className={styles.wrapper}>
      {contributionOverview.map((item, Index) => {
        if (
          item?.types &&
          item.types.includes(plan?.benefitKind) &&
          !item.isSkip
        ) {
          return (
            <Row key={Index}>
              <Col span={24}>
                <InfoLabelSection labelText={item.label} value={item.value} />
              </Col>
            </Row>
          );
        }
      })}
      <div className={styles.overview} id={'Plan_Doc'}>
        <PlanDocumentsOverview
          heading={'Plan Resources'}
          plan={plan}
          documents={plan?.documents}
          benefitKind={plan.benefitKind}
          viewPlanDocumentsModal={viewPlanDocumentsModal}
          setViewPlanDocumentsModal={(value) =>
            updateViewPlanDocumentsModal(value)
          }
        />
      </div>
    </div>
  );
};

export default DisabilityPlanInformationOverview;
