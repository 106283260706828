import { Row } from 'antd';
import { ReactComponent as AlertInfo } from 'assets/images/icon-info-blue.svg';

import styles from 'modules/employers/components/NoIDCardsView/NoIDCardView.module.less';

const NoIDCardView = () => {
  return (
    <div className={styles.noERAdminView}>
      <div className={styles.noERAdminViewWrapper}>
        <Row className={styles.description}>
          <AlertInfo className={styles.alertInfoIcon} />
        </Row>
        <Row className={styles.description}>
          <h2>There are no ID cards available</h2>
        </Row>
        <Row className={styles.description}>
          <p>There are no ID cards available for this individual</p>
        </Row>
      </div>
    </div>
  );
};

export default NoIDCardView;
