import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import BenguidePublish from 'modules/benefitGuide/components/BenguidePublish/BenguidePublish';
import {
  changedBenguideModalState,
  changedEmployerIdState,
  previewBenguide,
} from 'modules/benefitGuide/slices/benguideSlice';
import DBGClient from 'modules/clients/DBGClient/DBGClient';
import {
  BENGUIDE_COMMON_CHANNEL,
  BENGUIDE_LOADING_COMPLETED,
  BENGUIDE_SAVE_COMPLETED,
  CLOSE_DBG_MODAL,
  ENABLE_EDIT_MODE,
  EXIT_BENGUIDE,
  PREVIEW_BENGUIDE,
  PUBLISH_BENGUIDE,
  UP_COMMON_CHANNEL,
} from 'modules/clients/DBGClient/DBGClientConts';
import useDBGClient from 'modules/clients/DBGClient/useDBGClient';
import { getBenefitGuideByHashAndRevision } from 'modules/employers/slices/benguideSlice';
import { useNavContext } from 'hooks/useNavContext';

const BenguideCommonChannel = () => {
  const navigate = useNavigate();
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [eventData, setEventData] = useState<any>(null);
  const [publishedBenguide, setPublishedBenguide] = useState<boolean>(false);
  const [publishRevision, setPublishRevision] = useState<number | undefined>();

  const upChannel = useDBGClient();
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const userType = appBootInfo?.type;

  const handleActions = (event: string, data: any) => {
    if (BENGUIDE_LOADING_COMPLETED === event) {
      const newData = { ...data, userType };
      upChannel.postMessage({
        channel: UP_COMMON_CHANNEL,
        event: ENABLE_EDIT_MODE,
        data: newData,
      });
    }
    if (CLOSE_DBG_MODAL === event) {
      dispatch(changedBenguideModalState(false));
      if (data && data?.planYearId && data?.status && employerId && brokerId) {
        navigate(`brokers/${brokerId}/employers/${employerId}/benefit-guides`, {
          state: { dbgStatus: data.status, planYearId: data.planYearId },
        });
      }
      dispatch(changedEmployerIdState(''));
    }
    if (PUBLISH_BENGUIDE === event) {
      dispatch(getBenefitGuideByHashAndRevision(data.urlHash, data.revision));
      setEventData(data.planYearName);
      setPublishedBenguide(data.isPublished);
      setPublishRevision(data.revision);
      setIsPublishModalOpen(true);
    }
    if (PREVIEW_BENGUIDE === event) {
      dispatch(previewBenguide(data.id, data.revision));
    }
    if (BENGUIDE_SAVE_COMPLETED === event) {
      setIsPublishModalOpen(false);
    }
    if (EXIT_BENGUIDE === event) {
      setIsPublishModalOpen(false);
    }
  };

  return (
    <>
      <DBGClient channel={BENGUIDE_COMMON_CHANNEL} subscribe={handleActions} />
      <FullScreenModal
        key={2}
        visible={isPublishModalOpen}
        showConfirm={false}
        onCancel={() => setIsPublishModalOpen(false)}
        destroyOnClose
        footer={false}
        zIndex={1004}
      >
        <BenguidePublish
          planYear={eventData}
          onCancel={() => setIsPublishModalOpen(false)}
          isPublishedBenguide={publishedBenguide}
          publishRevision={publishRevision}
        />
      </FullScreenModal>
    </>
  );
};

export default BenguideCommonChannel;
