import React from 'react';
import { Divider, Select } from 'antd';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { ReactComponent as DropDownSelector } from 'assets/images/dashboardUtils/dropdown-selector.svg';
import { ReactComponent as XIcon } from 'assets/images/x-icon-remove.svg';
import { ReactComponent as SettingCogIcon } from 'assets/images/setting-cog.svg';
import styles from './savedFilterDropdown.module.less';

type Props = {
  placeholder?: string;
  options: any[];
  selectedValue: string;
  setSelectedValue: (value: any) => void;
  handleClickRemove?: (value: any) => void;
  actionLinkButtonLabel?: string;
  handleClickLinkButton?: () => void;
  handleClickSettingButton?: () => void;
  isLoading: boolean;
};

const SavedFilterDropdown = (props: Props) => {
  const {
    placeholder,
    options,
    selectedValue,
    setSelectedValue,
    handleClickRemove,
    actionLinkButtonLabel,
    handleClickLinkButton,
    handleClickSettingButton,
    isLoading,
  } = props;

  const dropdownRenderComp = (menu: React.ReactNode) => {
    return (
      <>
        {handleClickLinkButton && (
          <>
            <div className={styles.dropdownActionWrapper}>
              <LinkButton onClick={handleClickLinkButton}>
                {actionLinkButtonLabel}
              </LinkButton>
              {handleClickSettingButton && (
                <SettingCogIcon
                  className={styles.settingIcon}
                  onClick={() => handleClickSettingButton()}
                />
              )}
            </div>
            <Divider />
          </>
        )}
        {menu}
      </>
    );
  };
  return (
    <div className={`${styles.dropDownFilter}`}>
      <Select
        placeholder={placeholder}
        className={styles.selectedLabel}
        dropdownClassName={styles.dropdownWrapper}
        suffixIcon={<DropDownSelector />}
        value={selectedValue}
        onChange={(value) => {
          setSelectedValue(value);
        }}
        listHeight={200}
        dropdownRender={dropdownRenderComp}
        defaultValue={selectedValue}
        placement="bottomRight"
        optionLabelProp="label"
        loading={isLoading}
      >
        {options?.map((item) => {
          return (
            <Select.Option
              key={item?.value}
              className={styles.unselectedOption}
              value={item?.value}
              label={item?.label}
            >
              {item?.label}
              {handleClickRemove && (
                <XIcon
                  className={styles.xIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickRemove(item?.value);
                  }}
                />
              )}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SavedFilterDropdown;
