import { useState } from 'react';

import { Button, Drawer, Menu, Dropdown } from 'antd';
import { ReactComponent as ChatIcon } from 'assets/images/icon-black-question.svg';
import { ReactComponent as ContactSupport } from 'assets/images/contact-support.svg';
import { ReactComponent as KnowlageBase } from 'assets/images/knowledge-base.svg';
import { ReactComponent as AISupportChatIcon } from 'assets/images/icon-ai-support.svg';
import SupportChatLayout from 'modules/chatbot/layouts/SupportChatLayout/SupportChatLayout';
import { useAppSelector } from 'hooks/redux';
import { loginTypes } from 'constants/authConstants';
import ChatBubble from 'modules/chatbot/layouts/ChatBubbleLayout/ChatBubbleLayout';
import { brokerChatBotEnabled } from 'util/apiUtil';
import { KNOWLEDGE_BASE_URL, SUPPORT_EMAIL } from 'constants/commonConstants';
import { mailToSupport, navigateToKnowledgeBase } from 'util/commonUtil';

import styles from './floatingChatBot.module.less';

const RedDot = ({
  className,
  count,
}: {
  className?: string;
  count: number;
}) => {
  const lengthString = count > 9 ? '9+' : count;
  return <div className={`${styles.redDot} ${className}`}>{lengthString}</div>;
};

const FloatingChatBot = () => {
  const { auth } = useAppSelector((state) => state.auth);
  const [visible, setVisible] = useState(false);
  const appBootupInfo = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );
  const { conversationId, messages } = useAppSelector((state) => state.chatBot);

  const isBrokerAdmin = appBootupInfo?.type == loginTypes.bokerAdmin;

  const onClose = () => {
    setVisible(false);
  };

  const handleMenuClick = (event: any) => {
    const key = event.key;
    switch (key) {
      case 'contact':
        mailToSupport(SUPPORT_EMAIL);
        break;
      case 'knowledge':
        navigateToKnowledgeBase(KNOWLEDGE_BASE_URL);
        break;
      case 'chatbot':
        setVisible(true);
        break;
      default:
        break;
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick} className={styles.menu}>
      <Menu.Item
        key="contact"
        className={styles.menuItem}
        icon={<ContactSupport width={30} height={30} />}
      >
        Contact Support
      </Menu.Item>
      <Menu.Item
        key="knowledge"
        className={styles.menuItem}
        icon={<KnowlageBase width={30} height={30} />}
      >
        View Knowledge Base
      </Menu.Item>
      <Menu.Item
        key="chatbot"
        icon={<AISupportChatIcon width={30} height={30} />}
      >
        AI Support Chatbot
        {conversationId && (
          <RedDot className={styles.redBotMargin} count={messages.length} />
        )}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {isBrokerAdmin && brokerChatBotEnabled !== 'false' ? (
        <div>
          <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="topLeft"
            className={styles.dropdownContainer}
          >
            <Button
              icon={<ChatIcon />}
              size="large"
              className={styles.floatingSupportButton}
            >
              {conversationId && (
                <RedDot
                  className={styles.redDotAbsolute}
                  count={messages.length}
                />
              )}
            </Button>
          </Dropdown>
          <Drawer
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible && !auth.sessionExpired}
            width={410}
            drawerStyle={{ overflow: 'auto', height: '100%' }}
            zIndex={99999999}
            mask={false}
          >
            <SupportChatLayout handleModalClose={onClose} visible={visible}>
              {messages.map((message, index) => (
                <ChatBubble
                  key={index}
                  content={message?.content}
                  reference={message?.reference}
                  sender={message?.sender}
                />
              ))}
            </SupportChatLayout>
          </Drawer>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default FloatingChatBot;
