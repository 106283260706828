import { useCallback, useEffect, useState } from 'react';
import { Popover } from 'antd';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  EMPLOYER_VISIBILITY_OFF_MESSAGE,
  EMPLOYER_VISIBILITY_ON_MESSAGE,
  EMPLOYER_VISIBILITY_TOOL_TIP,
} from 'constants/commonConstants';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { ReactComponent as InvisibleIcon } from 'assets/images/icon-invisible.svg';
import { ReactComponent as VisibleIcon } from 'assets/images/icon-visible.svg';
import styles from './employerVisibilityOption.module.less';
type EmployerVisibilityOptionProps = {
  onConfirmToggle: any;
  employerName: string;
  toolName: string;
  enabled: boolean;
  employerVisibilityEnabled?: boolean;
  className?: string;
};
const EmployerVisibilityOption = (props: EmployerVisibilityOptionProps) => {
  const {
    onConfirmToggle,
    employerName,
    toolName,
    enabled,
    employerVisibilityEnabled,
    className,
  } = props;
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const setDisableVisibility = useCallback((value: boolean) => {}, []);
  const confirmToggle = () => {
    onConfirmToggle();
    setShowConfirm(false);
  };
  useEffect(() => {
    setDisableVisibility(!enabled);
  }, [enabled, setDisableVisibility]);
  const getConfirmText = () => {
    if (enabled && employerVisibilityEnabled) {
      return `Yes - Turn Visibility Off for this Employer`;
    } else return `Yes - Turn Visibility On for this Employer`;
  };
  return (
    <div className={styles.visibilityWrapper}>
      {!enabled ? (
        <Popover
          trigger="hover"
          content={EMPLOYER_VISIBILITY_TOOL_TIP}
          placement="left"
          overlayClassName="provisionStatusPopover"
        >
          <div className={styles.disableWrapper}>
            <InvisibleIcon />
            <div className={styles.disabled}>Off</div>
          </div>
        </Popover>
      ) : (
        <LinkButton
          enableDisableStyle={true}
          onClick={() => {
            setShowConfirm(true);
          }}
        >
          {enabled && employerVisibilityEnabled ? (
            <div className={styles.visibleWrapper}>
              <VisibleIcon />
              <div className={styles.visible}>Visible</div>
            </div>
          ) : (
            <>
              <div className={styles.inVisibleWrapper}>
                <InvisibleIcon />
                <div className={styles.inVisible}>Off</div>
              </div>
            </>
          )}
        </LinkButton>
      )}
      {showConfirm && enabled && (
        <ConfirmationDialog
          title={
            employerVisibilityEnabled
              ? 'Turn Employer Visibility Off'
              : 'Turn Employer Visibility On'
          }
          confirmText={getConfirmText()}
          cancelText="Cancel"
          closeModal={() => {
            setShowConfirm(false);
          }}
          onConfirm={confirmToggle}
          visible={showConfirm}
          isCancelLink
          modalClassName={className}
        >
          <div className={styles.subTitle}>
            {`${toolName} - ${employerName}`}
          </div>
          <p className={styles.message}>
            {employerVisibilityEnabled
              ? EMPLOYER_VISIBILITY_OFF_MESSAGE
              : EMPLOYER_VISIBILITY_ON_MESSAGE}
          </p>
        </ConfirmationDialog>
      )}
    </div>
  );
};
export default EmployerVisibilityOption;
