import { FC, useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import PlanYear from 'model/PlanYear';
import { useNavContext } from 'hooks/useNavContext';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import SelectOptions from 'components/SelectOptions/SelectOptions';
import styles from './planYearDropdown.module.less';

const { Option } = Select;

type PlanYearDropdownProps = {
  onChange: (value: PlanYear) => void;
  className: string;
  isDeleted?: boolean;
  setIsPlanYearDeleted?: (deleted: boolean) => void;
  defaultLatest?: boolean;
  newPlanYear?: PlanYear;
  planYearOptionsStyle?: string;
};

const PlanYearDropdown: FC<PlanYearDropdownProps> = (props) => {
  const {
    onChange,
    className,
    isDeleted,
    setIsPlanYearDeleted,
    defaultLatest,
    newPlanYear,
    planYearOptionsStyle = styles.planYearOptions,
  } = props;
  const { planYearsList } = useAppSelector(
    (state) => state.benguide.employerSlice
  );

  const dispatch = useAppDispatch();
  const { employerId } = useNavContext();

  const [selectedPlanYear, setSelectedPlanYear] = useState<PlanYear>({
    id: '',
    benefitGroups: [],
    employerId: employerId || '',
    startDate: '',
    endDate: '',
    current: false,
    name: '',
    next: false,
    previous: false,
  });

  useEffect(() => {
    if (employerId) {
      dispatch(getPlanYears(employerId));
    }
  }, [dispatch, employerId]);

  useEffect(() => {
    if (
      newPlanYear &&
      selectedPlanYear &&
      newPlanYear.id !== selectedPlanYear.id
    ) {
      setSelectedPlanYear(newPlanYear);
    }
  }, [selectedPlanYear, newPlanYear]);

  useEffect(() => {
    const currentPlanYear = find(planYearsList, { current: true });
    if (isDeleted && currentPlanYear) {
      setSelectedPlanYear(currentPlanYear);
      onChange(currentPlanYear);
    }
    if (currentPlanYear?.id === selectedPlanYear?.id) {
      setIsPlanYearDeleted && setIsPlanYearDeleted(false);
    }
  }, [
    isDeleted,
    onChange,
    planYearsList,
    selectedPlanYear?.id,
    setIsPlanYearDeleted,
  ]);

  useEffect(() => {
    if (!isEmpty(planYearsList)) {
      const currentPlanYear = find(planYearsList, { current: true });
      const updatedPlanYear = find(planYearsList, { id: selectedPlanYear.id });

      if (
        currentPlanYear &&
        (isEmpty(selectedPlanYear.id) ||
          selectedPlanYear.employerId !== employerId)
      ) {
        setSelectedPlanYear(currentPlanYear);
        onChange(currentPlanYear);
      }

      if (updatedPlanYear && !isEmpty(selectedPlanYear.id)) {
        setSelectedPlanYear(updatedPlanYear);
      }
    }
  }, [onChange, planYearsList, selectedPlanYear, employerId]);

  const onPlanYearChange = useCallback(
    (value: string) => {
      planYearsList.forEach((planYear) => {
        if (planYear.id === value) {
          setSelectedPlanYear(planYear);
          onChange(planYear);
        }
      });
    },
    [onChange, planYearsList]
  );

  useEffect(() => {
    if (defaultLatest) {
      const latestPlanYear = find(planYearsList, { next: true });
      latestPlanYear && onPlanYearChange(latestPlanYear?.id);
    }
    // No need to add onPlanYearChange to dependency array
    // eslint-disable-next-line
  }, [planYearsList, defaultLatest]);

  return (
    <>
      <div className={className}>
        <span className={styles.planYearLabel}>Plan Year:</span>
      </div>
      <SelectOptions
        onChange={onPlanYearChange}
        value={selectedPlanYear.name}
        dropdownMatchSelectWidth={false}
        className={styles.selectedLabel}
      >
        {planYearsList.map((value, index) => (
          <Option value={value.id} key={index} className={planYearOptionsStyle}>
            {value.name}
          </Option>
        ))}
      </SelectOptions>
    </>
  );
};

export default PlanYearDropdown;
