export const PRIORITY_HIGH = 'HIGH';
export const PRIORITY_MEDIUM = 'MEDIUM';
export const PRIORITY_LOW = 'LOW';
export const PRIORITY_UNKNOWN = 'UNKNOWN';

export const PRIORIRY_MAP = [
  { value: PRIORITY_LOW, label: 'Low' },
  { value: PRIORITY_MEDIUM, label: 'Medium' },
  { value: PRIORITY_HIGH, label: 'High' },
];

export const SUBTYPE_BENEFITS_QUESTION = 'BENEFITS_QUESTION';
export const SUBTYPE_BILLING = 'BILLING';
export const SUBTYPE_CARRIER = 'CARRIER';
export const SUBTYPE_COBRA = 'COBRA';
export const SUBTYPE_COMPLIANCE = 'COMPLIANCE';
export const SUBTYPE_DEMOGRAPHIC_DATA = 'DEMOGRAPHIC_DATA';
export const SUBTYPE_EDI = 'EDI';
export const SUBTYPE_ELIGIBILITY = 'ELIGIBILITY';
export const SUBTYPE_EOI = 'EOI';
export const SUBTYPE_GENERAL = 'GENERAL';
export const SUBTYPE_ID_CARDS = 'ID_CARDS';
export const SUBTYPE_INTEGRATIONS = 'INTEGRATIONS';
export const SUBTYPE_LOGIN_ISSUE = 'LOGIN_ISSUE';
export const SUBTYPE_PAYROLL = 'PAYROLL';
export const SUBTYPE_PROVIDER_SEARCH = 'PROVIDER_SEARCH';
export const SUBTYPE_QLE = 'QLE';
export const SUBTYPE_RENEWAL = 'RENEWAL';
export const SUBTYPE_CLAIMS_ISSUE = 'CLAIMS_ISSUE';
export const SUBTYPE_SPENDING_ACCOUNTS = 'SPENDING_ACCOUNTS';
export const SUBTYPE_URGENT_ENROLLMENTS = 'URGENT_ENROLLMENTS';
export const SUBTYPE_SPAM = 'SPAM';

export const ISSUE_VELOCITY_CHART_PRIMARY_COLOR = '#317AD0';
export const ISSUE_VELOCITY_CHART_SECONDARY_COLOR = '#A5B6C8';

export const COLOR_ISSUE_VELOCITY_CHART_NON_HOVER = '#3EA9A733';
export const COLOR_ISSUE_VELOCITY_CHAT_STOKE = '#E9E9E9';
export const COLOR_LIGHT_GREY = '#EBECED';
export const COLOR_LEGEND_LABEL_SHADE = '#758184';
export const ISSUE_SUBTYPE_MAP = [
  { value: SUBTYPE_BENEFITS_QUESTION, label: 'Benefits Question' },
  { value: SUBTYPE_BILLING, label: 'Billing' },
  { value: SUBTYPE_CARRIER, label: 'Carrier' },
  { value: SUBTYPE_CLAIMS_ISSUE, label: 'Claims Issue' },
  { value: SUBTYPE_COBRA, label: 'Cobra' },
  { value: SUBTYPE_COMPLIANCE, label: 'Compliance' },
  { value: SUBTYPE_DEMOGRAPHIC_DATA, label: 'Demographic Data' },
  { value: SUBTYPE_EDI, label: 'EDI' },
  { value: SUBTYPE_ELIGIBILITY, label: 'Eligibility' },
  { value: SUBTYPE_EOI, label: 'EOI' },
  { value: SUBTYPE_GENERAL, label: 'General' },
  { value: SUBTYPE_ID_CARDS, label: 'ID Cards' },
  { value: SUBTYPE_INTEGRATIONS, label: 'Integrations' },
  { value: SUBTYPE_LOGIN_ISSUE, label: 'Login Issue' },
  { value: SUBTYPE_PAYROLL, label: 'Payroll' },
  { value: SUBTYPE_PROVIDER_SEARCH, label: 'Provider Search' },
  { value: SUBTYPE_QLE, label: 'QLE' },
  { value: SUBTYPE_RENEWAL, label: 'Renewal' },
  { value: SUBTYPE_SPENDING_ACCOUNTS, label: 'Spending Accounts' },
  { value: SUBTYPE_URGENT_ENROLLMENTS, label: 'Urgent Enrollments' },
];

export const ISSUE_STATUS_NEW = 'NEW';

export const ISSUE_TYPE_ACCOUNT = 'ACCOUNT';
export const ISSUE_TYPE_SUPPORT = 'SUPPORT';

export const DELETE_SUPPORT_TEAM = 'Delete Support Team';
export const DELETE_EMAIL_ADDRESS = 'Delete Email Address';

export const STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const STATUS_RESOLVED = 'RESOLVED';
export const STATUS_BLOCKED = 'BLOCKED';
export const STATUS_RE_OPENED = 'RE_OPENED';
export const STATUS_NOT_TRACKED = 'SPAM';

export const ISSUE_STATUS_MAP = [
  { value: STATUS_IN_PROGRESS, label: 'In Progress' },
  { value: STATUS_BLOCKED, label: 'Blocked' },
  { value: STATUS_RESOLVED, label: 'Resolved' },
];

export const ISSUES_LOG_PROVISION_STATUS_COMPLETED = 'COMPLETED';
export const ISSUES_LOG_PROVISION_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const ISSUES_LOG_PROVISION_STATUS_NA = 'NA';
export const ISSUES_LOG_PROVISION_STATUS_ABORTED = 'ABORTED';

export const ISSUE_COMMENT_TYPE_INTERNAL = 'INTERNAL';
export const ISSUE_COMMENT_TYPE_EMAIL = 'EMAIL';
export const SUPPORT_TEAM_KEY = 'supportTeam';
export const ORGANIZATION_ID_KEY = 'organizationId';

export const ITEMS_TAB = 'ITEMS_TAB';
export const ANALYTICS_TAB = 'ANALYTICS_TAB';

// issue filter comp constant
export const ALL_BROKERS = 'All Brokers';

export const ISSUE_EDIT_TYPES = {
  SUPPORT_TEAM: 'SUPPORT_TEAM',
  EMPLOYER: 'EMPLOYER',
  VISIBLE_TO_EMPLOYER: 'VISIBLE_TO_EMPLOYER',
  ISSUE_TYPE_SPAM: 'ISSUE_TYPE_SPAM',
  PRIORITY: 'PRIORITY',
  ASSIGN_TO: 'ASSIGN_TO',
  WATCHERS: 'WATCHERS',
  RESOLVED: 'RESOLVED',
  RE_OPENED: 'RE_OPENED',
};

export const ER_ANALYTICS = 'ER_ANALYTICS';
export const BR_ADMIN_ANALYTICS = 'BR_ADMIN_ANALYTICS';
export const NO_SUPPORT_TEAMS_FOR_BROKER =
  'Currently no support teams are setup for this broker.';
export const CREATE_NEW_ISSUE = 'Create New Item';

export const DURATION_ELEMENTS = [
  'years',
  'months',
  'days',
  'hours',
  'minutes',
];

export const ISSUE_COMMENT_COLLAPSE_STATE = {
  COLLAPSED: 'COLLAPSED',
  EXPANDED: 'EXPANDED',
};
