import { FUNDING_TYPE } from 'modules/claims/constants/constants';
import { ReactComponent as Checkmark } from 'assets/images/checkmark.svg';

import styles from './fundingTypeSelector.module.less';

type Props = {
  fundingMode: string | undefined;
  setFundingMode: Function;
  availableFundingTypes: string[] | undefined;
};
const FundingTypeSelector = (props: Props) => {
  const { fundingMode, setFundingMode, availableFundingTypes } = props;
  return (
    <div className={styles.claimsTypeWrapper}>
      Claims Type:
      {availableFundingTypes?.includes(FUNDING_TYPE.FULLY_INSURED) && (
        <div
          className={`${styles.claimsType} ${
            fundingMode === FUNDING_TYPE.FULLY_INSURED ? styles.active : ''
          }`}
          onClick={() => {
            setFundingMode(FUNDING_TYPE.FULLY_INSURED);
          }}
        >
          Fully-Insured
          {fundingMode === FUNDING_TYPE.FULLY_INSURED && (
            <Checkmark className={styles.checkmark} />
          )}
        </div>
      )}
      {availableFundingTypes?.length === 2 && <span>|</span>}
      {availableFundingTypes?.includes(FUNDING_TYPE.SELF_FUNDED) && (
        <div
          className={`${styles.claimsType} ${
            fundingMode === FUNDING_TYPE.SELF_FUNDED ? styles.active : ''
          }`}
          onClick={() => {
            setFundingMode(FUNDING_TYPE.SELF_FUNDED);
          }}
        >
          Self-Funded
          {fundingMode === FUNDING_TYPE.SELF_FUNDED && (
            <Checkmark className={styles.checkmark} />
          )}
        </div>
      )}
    </div>
  );
};
export default FundingTypeSelector;
