import React, { useState } from 'react';
import { Row, Select } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import ConfirmationDialog, {
  ConfirmationDialogProps,
} from 'components/ConfirmationDialog/ConfirmationDialog';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';

import SelectOption from 'model/SelectOption';

import styles from './chooseLocationModal.module.less';

type Props = Omit<ConfirmationDialogProps, 'onConfirm' | 'confirmText'> & {
  locations: SelectOption[];
  onConfirm: (selectedOption: SelectOption) => void;
};

const ChooseLocationModal = ({
  onConfirm,
  locations,
  closeModal,
  ...rest
}: Props) => {
  const [selectedLocationValue, setSelectedLocationValue] = useState<string>();
  const selectedOption = locations.find(
    (loc) => loc.value === selectedLocationValue
  );

  const handleCancel = () => {
    closeModal?.();
    setSelectedLocationValue(undefined);
  };

  const handleConfirm = () => {
    if (selectedOption) {
      onConfirm(selectedOption);
      setSelectedLocationValue(undefined);
    }
  };

  return (
    <ConfirmationDialog
      {...rest}
      isCancelLink
      cancelText={'Cancel'}
      confirmText={'Continue'}
      title="Choose Location"
      disableConfirmButton={!selectedOption}
      closeModal={handleCancel}
      onConfirm={handleConfirm}
      buttonsExpanded={true}
    >
      <p>
        Our AI model has been trained using documents specific to various
        locations. Please select the location for which you wish to generate a
        new RFP response.
      </p>
      <Row>
        <b>Location</b>
      </Row>
      <Row>
        <Select
          size="large"
          value={selectedLocationValue}
          className={styles.locationSelector}
          onChange={setSelectedLocationValue}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          {locations.map((loc) => (
            <Select.Option disabled={loc.isDisabled} key={loc.value}>
              {loc.label}
              {loc.isDisabled && (
                <PlanyearPopover
                  zIndex={1050}
                  placement="right"
                  content="No training data available."
                >
                  <QuestionCircleOutlined className={styles.infoIcon} />
                </PlanyearPopover>
              )}
            </Select.Option>
          ))}
        </Select>
      </Row>
    </ConfirmationDialog>
  );
};

export default ChooseLocationModal;
