import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Col, Menu, Row } from 'antd';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import { usePrevious } from 'hooks/usePrevious';

import { ReactComponent as IconLife } from 'assets/images/benefitCategories/icon-life-and-disability.svg';
import EmptyAddPlan from 'modules/plans/components/EmptyAddPlan/EmptyAddPlan';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import PlansList from 'modules/plans/components/PlansList/PlansList';
import { BasicPlans, VoluntaryPlans } from 'modules/plans/constants';
import PlanYear from 'model/PlanYear';
import { LifePlan } from 'model/plans/LifePlan';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import PlanSelectButton from 'modules/plans/components/PlanSelectButton/PlanSelectButton';
import SearchBar from 'components/SearchBar/SearchBar';
import TablePagination from 'model/TablePagination';
import {
  getLifePlansList,
  resetPlanReduxStore,
} from 'modules/plans/slices/lifePlanSlice';
import AiPlanUploadHistoryModal from 'modules/plans/components/AiPlanUploadHistoryModal/AiPlanUploadHistoryModal';
import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
  resetPaginationConfig,
} from 'util/commonUtil';
import { ReactComponent as RfpIconSelected } from 'assets/images/Rfp-ai-selected.svg';
import PlanListPlanYearDropDown from 'components/PlanListPlanYearDropDown/PlanListPlanYearDropDown';
import AddLifePlanModal from 'modules/plans/life/components/AddLifePlanModal/AddLifePlanModal';
import AlertMessageWrapper from 'modules/plans/components/AlertMessageWrapper/AlertMessageWrapper';
import {
  BenefitCategory,
  EMPLOYER_MEMBER_RESTRICTED,
  SBC_PARAMS,
  planCreateSuccess,
  planOfferFetchError,
  sbcReviewStarted,
  sbcfetchError,
} from 'constants/commonConstants';
import { DocumentExtractionSource } from 'modules/plans/enums/DocumentExtractionSource';
import { AlertInfo } from 'components/Alert/AlertMessage';
import UploaderType from 'modules/plans/enums/UploaderType';
import useDocumentExtractionReviewModal from 'hooks/useDocumentExtractionReviewModal';
import { clear, setOPSView } from 'modules/plans/slices/aiSbcUploaderSlice';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import AddPlanModal, {
  SelectedPlanProps,
  TDocumentExtractionSource,
} from 'modules/plans/components/AddPlanModal/AddPlanModal';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import {
  createModalCloseHandler,
  removeSearchParams,
} from 'modules/plans/utils';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import SendSelfToOPSSuccessComponent from 'modules/plans/components/SendSelfToOPSSuccessComponent/SendSelfToOPSSuccessComponent';

import styles from './lifePlansList.module.less';

type lifePlan = {
  value: string;
  label: string;
};

const LifePlansList: FC = () => {
  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );
  const { status } = useAppSelector((state) => state.plan.aiSbc);
  const [fullScreenModalView, setFullScreenModalView] =
    useState<boolean>(false);
  const [isNewAddPlanModal, setIsNewAddPlanModal] = useState<boolean>(false);
  const [planYear, setPlanYear] = useState<PlanYear | null>(null);
  const [addPlanPropsToState, setAddPlansPropsToState] = useState<
    SelectedPlanProps | undefined
  >(undefined);
  const [lifePlanType, setLifePlanType] = useState<lifePlan>({
    value: '',
    label: '',
  });
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: '',
    },
    filters: {},
  });
  const [
    getUpcomingPlanYears,
    { data: upcomingPlanYearData, isLoading: isGetUpcomingPlanYearsLoading },
  ] = useLazyGetUpcomingPlanYearsByEmployerQuery();
  const [searchText, setSearchText] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [isAiPlanModalOpen, setIsAiPlanModalOpen] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const isRenewalStarted =
    upcomingPlanYearData?.upcomingPlanYears?.some(
      (obj: any) => obj?.planYearId === null
    ) && planYear?.current;

  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { employerId, employer, brokerId } = useNavContext();

  const { lifePlanList, inProgress, error } = useAppSelector(
    (state) => state.plan.lifePlan
  );

  const { content, metadata } = lifePlanList || {};
  const total = metadata ? metadata.total : 0;
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  useEffect(() => {
    if (employerId) {
      dispatch(getPlanYears(employerId));
      getUpcomingPlanYears({ employerId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, employerId]);

  const previousPlanYear = usePrevious(planYear?.id);

  useEffect(() => {
    if (employerId && planYear?.id) {
      if (previousPlanYear !== planYear?.id) {
        setPaginationConfig(resetPaginationConfig(paginationConfig));
      }
      dispatch(
        getLifePlansList(
          convertPaginationConfig(paginationConfig),
          employerId,
          planYear.id
        )
      );
    }
    // eslint-disable-next-line
  }, [dispatch, employerId, planYear?.id, paginationConfig]);

  const openSBCplanModal = (jobId: string) => {
    dispatch(
      setOPSView({
        jobId: jobId,
        planUploderType: isOpsAdmin
          ? UploaderType.OPS_VIEW
          : UploaderType.ADMIN_VIEW,
      })
    );
    openPlanCreateModal(Object.values(BasicPlans)?.[0]);
  };

  /**
   * Custom hook for handling SBC (Summary of Benefits and Coverage) plan review modal.
   * This hook abstracts the logic for opening a modal based on URL parameters,
   */
  useDocumentExtractionReviewModal(
    isOpsAdmin,
    dispatch,
    openSBCplanModal,
    false,
    false,
    () => {},
    () => {
      setAlertMessage({
        type: 'success',
        message: (
          <SendSelfToOPSSuccessComponent
            onClick={() => setIsAiPlanModalOpen(true)}
          />
        ),
      });
      setVisible(true);
    }
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.trim();
    setSearchText(searchText);
    const config = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText },
    };
    debounceLoadData(config);
  };

  const handleClose = () => {
    setFullScreenModalView(false);
    if (employerId && planYear?.id) {
      dispatch(resetPlanReduxStore());
      setAddPlansPropsToState(undefined);
      dispatch(
        getLifePlansList(
          convertPaginationConfig(paginationConfig),
          employerId,
          planYear.id
        )
      );
    }
  };

  const handleModalClose = (
    extractStatus: ProcessStatus,
    sendToOpsFailed?: boolean,
    docExtractionSource?: TDocumentExtractionSource
  ) => {
    const sendSelfToOpsSuccessMessage = sendToOpsFailed ? (
      <SendSelfToOPSSuccessComponent
        isSendOpsAdmin={true}
        onClick={() => setIsAiPlanModalOpen(true)}
      />
    ) : (
      sbcReviewStarted
    );

    const constructFailedMessage = (
      source?: DocumentExtractionSource | undefined
    ) => {
      if (source === DocumentExtractionSource.UPDATE_QUOTE_PLAN) {
        return planOfferFetchError;
      }
      return sbcfetchError;
    };

    return createModalCloseHandler({
      clearAction: () => dispatch(clear()),
      removeParamsAction: () =>
        removeSearchParams([SBC_PARAMS.isReview, SBC_PARAMS.jobId]),
      handleCloseAction: handleClose,
      setAlertMessageAction: setAlertMessage,
      setVisibleAction: setVisible,
      status: status,
      messages: {
        [ProcessStatus.PROCESSING]: sendSelfToOpsSuccessMessage,
        [ProcessStatus.FAILED]: constructFailedMessage(
          docExtractionSource?.source
        ),
      },
    })(extractStatus);
  };

  const onSaveClose = () => {
    setFullScreenModalView(false);
    if (employerId && planYear?.id) {
      if (!error) {
        setAlertMessage({
          type: 'success',
          message: planCreateSuccess,
        });
        setVisible(true);
      }
      dispatch(
        getLifePlansList(
          convertPaginationConfig(paginationConfig),
          employerId,
          planYear.id
        )
      );
    }
  };

  const onSavePlan = (
    isSuccess: boolean,
    successMessage?: string | undefined | null
  ) => {
    setFullScreenModalView(false);
    setAddPlansPropsToState(undefined);
    if (employerId && planYear?.id) {
      if (isSuccess) {
        setAlertMessage({
          type: 'success',
          message: successMessage ?? planCreateSuccess,
        });
        setVisible(true);
      }
      dispatch(
        getLifePlansList(
          convertPaginationConfig(paginationConfig),
          employerId,
          planYear.id
        )
      );
    }
    dispatch(clear());
  };

  const openPlanCreateModal = (planType: lifePlan) => {
    dispatch(resetPlanReduxStore());
    setIsNewAddPlanModal(Object.keys(BasicPlans).includes(planType.value));
    setFullScreenModalView(true);
    setLifePlanType(planType);
    setVisible(false);
  };

  const goToOverviewPage = (lifePlan: LifePlan) => {
    navigate(
      `/brokers/${brokerId}/employers/${employerId}/life/${lifePlan.id}/overview`
    );
  };

  const lifeAndDisabilityPlanTypes = () => {
    return (
      <Menu>
        <Menu.Item key="basicPlans" className={styles.menuItemHeader}>
          BASIC PLANS
        </Menu.Item>
        {Object.values(BasicPlans).map((item) => (
          <Menu.Item key={item.value} onClick={() => openPlanCreateModal(item)}>
            {item.label}
          </Menu.Item>
        ))}
        <Menu.Item key="voluntaryPlans" className={styles.menuItemHeader}>
          VOLUNTARY PLANS
        </Menu.Item>
        {Object.values(VoluntaryPlans).map((item) => (
          <Menu.Item key={item.value} onClick={() => openPlanCreateModal(item)}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const getData = (filters: TablePagination) => {
    const paginationConfigData = {
      sorterInfo: {
        columnKey: 'name',
        field: 'name',
        order: 'ascend',
      },
      paginationIndex: filters.paginationIndex,
      filterInfo: {
        limit: filters.filterInfo.limit,
        offset: filters.filterInfo.offset,
        searchText: searchText,
      },
      filters: filters.filters,
    } as TablePagination;
    setPaginationConfig(paginationConfigData);
  };

  const setSelectedPlanYear = (planYear: PlanYear | null | undefined) => {
    if (employerId && planYear) {
      setPlanYear(planYear);
    }
  };

  const closeAlert = () => setVisible(false);

  const addLifePlansButton = (
    <div className={styles.allTypesPlanWrapper}>
      <div className={styles.addPlanButtonWrapper}>
        <div className={styles.selectButtonWrapper}>
          <PlanSelectButton
            overlay={lifeAndDisabilityPlanTypes()}
            selectLabel="Add Life & Disability Plan"
            className={styles.dropdownButton}
          />
        </div>
        <LinkButton
          onClick={() => {
            setIsAiPlanModalOpen(true);
          }}
        >
          <RfpIconSelected /> Plan Uploads
        </LinkButton>
      </div>
    </div>
  );

  const permittedAddPlansButton = usePermitByUserType(
    addLifePlansButton,
    EMPLOYER_MEMBER_RESTRICTED
  );
  return (
    <ContentContainer>
      <AlertMessageWrapper
        type={alertMessage.type}
        message={alertMessage.message}
        closeAlert={closeAlert}
        visible={visible}
      />
      <Row>
        <Col span={18}>
          <div className={styles.employerName}>
            <OverflowPopover
              popoverContent={`${employer?.name}`}
              maxWidth="50%"
            >
              {`${employer?.name} `}
            </OverflowPopover>
            Life & Disability Plans
          </div>
        </Col>
        <Col span={6}>
          {((!inProgress && content.length !== 0) ||
            (!inProgress && content.length == 0 && !isEmpty(searchText))) &&
            permittedAddPlansButton}
        </Col>
      </Row>
      <PlanListPlanYearDropDown onPlanYearSelect={setSelectedPlanYear} />
      {!inProgress && total === 0 && isEmpty(searchText) ? (
        <EmptyAddPlan
          title="You have no Life & Disability plans set up!"
          description="Get started by adding your first plan"
          icon={<IconLife />}
          onActionClick={() => {
            setFullScreenModalView(true);
            setVisible(false);
          }}
          selectButton={
            <div className={styles.emptySelectButtonWrapper}>
              <PlanSelectButton
                overlay={lifeAndDisabilityPlanTypes()}
                selectLabel="+ Add Life & Disability Plan"
                className={styles.emptyDropdownButton}
              />{' '}
              <LinkButton onClick={() => setIsAiPlanModalOpen(true)}>
                <RfpIconSelected /> Plan Uploads
              </LinkButton>
            </div>
          }
        />
      ) : (
        <>
          <div className={styles.searchBar}>
            <SearchBar placeholder="Search Plans" onChange={handleSearch} />
            <br />
          </div>
          <PlansList
            planYear={planYear}
            planList={lifePlanList}
            inProgress={inProgress || isGetUpcomingPlanYearsLoading}
            limit={limit}
            paginationConfig={paginationConfig}
            getData={getData}
            goToOverviewPage={goToOverviewPage}
            benefitCategory={BenefitCategory.LIFE.value}
            onSaveClose={onSaveClose}
            isRenewalProcessStarted={!!isRenewalStarted}
            setAddPlansPropsToState={setAddPlansPropsToState}
          />
        </>
      )}
      <AddLifePlanModal
        lifePlanType={lifePlanType}
        employerName={employer?.name}
        visible={fullScreenModalView && !isNewAddPlanModal}
        onClose={handleClose}
        onSaveClose={onSaveClose}
        isRenewalProcessStarted={!!isRenewalStarted}
      />
      <AddPlanModal
        {...addPlanPropsToState}
        benefit={'LIFE'}
        subType={addPlanPropsToState?.subType ?? lifePlanType?.value}
        title={lifePlanType?.label}
        isOpen={
          addPlanPropsToState?.isOpen ??
          (fullScreenModalView && isNewAddPlanModal)
        }
        onClose={handleModalClose}
        onSave={onSavePlan}
        isRenewalProcessStarted={!!isRenewalStarted}
      />
      <AiPlanUploadHistoryModal
        isOpen={isAiPlanModalOpen}
        onClose={() => setIsAiPlanModalOpen(false)}
        planYearName={planYear?.name}
        openSBCplanModal={openSBCplanModal}
        benefit={'LIFE'}
      />
    </ContentContainer>
  );
};

export default LifePlansList;
