import React from 'react';
import { Table, Row, Col } from 'antd';
import { cloneDeep } from 'lodash';

import DoughnutChart from 'components/Charts/DoughnutChart/DoughnutChart';
import LegendLabel from 'modules/claims/components/LegendLabel/LegendLabel';
import { currencyFormatter, shortenCurrencyWithSuffix } from 'util/commonUtil';

import {
  COLOR_GOLDEN_ORANGE,
  COLOR_GOLDEN_YELLOW,
  COLOR_GOLDEN_OLIVE,
  COLOR_LIGHT_OLIVE,
  COLOR_LIGHT_FOREST_GREEN,
  COLOR_FOREST_SEA_GREEN,
  COLOR_DARK_AQUA_MARINE,
  COLOR_DARK_SEA_GREEN,
  COLOR_DARK_DODGER_BLUE,
  COLOR_VAVY_BLUE,
  BENEFIT_KIND_MEDICAL,
  FUNDING_TYPE,
  BENEFIT_KIND_RX,
} from 'modules/claims/constants/constants';

import styles from './largeClaims.module.less';

type Props = {
  largeClaims: { [key: string]: any };
  benefitKind: string;
  fundingType: string;
  largeClaimsISLValues?: any;
};

const COLORS = [
  COLOR_GOLDEN_ORANGE,
  COLOR_GOLDEN_YELLOW,
  COLOR_GOLDEN_OLIVE,
  COLOR_LIGHT_OLIVE,
  COLOR_LIGHT_FOREST_GREEN,
  COLOR_FOREST_SEA_GREEN,
  COLOR_DARK_AQUA_MARINE,
  COLOR_DARK_SEA_GREEN,
  COLOR_DARK_DODGER_BLUE,
  COLOR_VAVY_BLUE,
];

const generateObj = (dataList: { primaryDiagnosis: any; totalCost: any }[]) => {
  if (dataList) {
    const sortedDataList = cloneDeep(dataList);
    sortedDataList.sort((a, b) => {
      return b.totalCost - a.totalCost;
    });
    return sortedDataList.map(({ primaryDiagnosis, totalCost }, index) => {
      const label = primaryDiagnosis || 'N/A';
      return {
        id: index + 1,
        name: (
          <LegendLabel
            title={label}
            prefixShape={{
              color: COLORS[COLORS.length - (index + 1)],
              width: '8px',
              height: '8px',
              circle: true,
            }}
            className={{
              wrapperClass: 'large-claims-label',
              headerClass: 'large-claims-header',
            }}
            showTitleTooltip={true}
          />
        ),
        value: Math.round(totalCost),
        label: label,
      };
    });
  }
  return [];
};
const generateDataList = (
  dataList: { totalCost: number; primaryDiagnosis: any }[]
) => {
  if (dataList) {
    const sortedDataList = cloneDeep(dataList);
    sortedDataList.sort((a, b) => {
      return b.totalCost - a.totalCost;
    });
    return sortedDataList.map(({ totalCost, primaryDiagnosis }, index) => ({
      id: index + 1,
      value: Math.round(totalCost),
      name: primaryDiagnosis || 'N/A',
    }));
  }
  return [];
};
const generateLowerThanISLDataList = (dataList: any[], islValue: number) =>
  dataList &&
  dataList
    .filter(({ totalCost }) => islValue > totalCost)
    .map(({ primaryDiagnosis, totalCost, rank }, index) => {
      const label = primaryDiagnosis || 'N/A';
      const sfcCount = geValuesGraterThanISLCount(dataList, islValue);
      return {
        id: rank,
        name: (
          <LegendLabel
            title={label}
            prefixShape={{
              color: COLORS[COLORS.length - (sfcCount + index + 1)],
              width: '8px',
              height: '8px',
              circle: true,
            }}
            className={{
              wrapperClass: 'large-claims--label',
              headerClass: 'large-claims--header',
            }}
            showTitleTooltip={true}
          />
        ),
        value: totalCost,
        label: label,
      };
    });
const geValuesGraterThanISLCount = (dataList: any[], islValue: number) => {
  if (dataList) {
    return dataList.filter(({ totalCost }) => islValue <= totalCost).length;
  }
  return 0;
};
const generateGreaterThanISLDataList = (dataList: any[], islValue: number) =>
  dataList &&
  dataList
    .filter(({ totalCost }) => islValue <= totalCost)
    .map(({ primaryDiagnosis, totalCost, rank }, index) => {
      const label = primaryDiagnosis || 'N/A';
      return {
        id: rank,
        name: (
          <LegendLabel
            title={label}
            prefixShape={{
              color: COLORS[COLORS.length - (index + 1)],
              width: '8px',
              height: '8px',
              circle: true,
            }}
            className={{
              wrapperClass: 'large-claims--label',
              headerClass: 'large-claims--header',
            }}
            showTitleTooltip={true}
          />
        ),
        value: totalCost,
        label: label,
      };
    });
const getChartColumns = () => {
  return [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: '5px',
      render: (id: number) => <span>{id}.</span>,
    },
    {
      title: 'Primary Diagnosis',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Total Claims',
      dataIndex: 'value',
      key: 'value',
      width: '120px',
      render: (amount: number) => (
        <span className={styles.totalclaims}>
          {currencyFormatter(Math.round(amount), undefined, undefined, 0)}
        </span>
      ),
    },
  ];
};
const tooltipContent = (bar: { payload: any; active: any }) => {
  const { payload, active } = bar;
  const { value, name } = payload[0] || {};
  if (active) {
    return (
      <div className="doughnut-chart-tooltip">
        <span className="currency-bold-large">
          {`${currencyFormatter(value)}` || ''}
        </span>
        <span
          className={`${styles.claimDiagnosis} doughnut-chart-tooltip-text`}
        >{`${name || ''}`}</span>
      </div>
    );
  } else {
    return null;
  }
};
const LargeClaims = (props: Props) => {
  const {
    largeClaims,
    benefitKind,
    fundingType,
    largeClaimsISLValues = {},
  } = props;
  const largeClaimsList = largeClaims[benefitKind.toUpperCase()];
  const dataList = generateDataList(largeClaimsList);
  const tableData = generateObj(largeClaimsList);
  const islValue =
    largeClaimsISLValues[
      benefitKind.toUpperCase() === BENEFIT_KIND_RX.toUpperCase()
        ? BENEFIT_KIND_MEDICAL.toUpperCase()
        : benefitKind.toUpperCase()
    ];
  const isSelfFundedMode = fundingType === FUNDING_TYPE.SELF_FUNDED;

  return (
    <div
      className={`${styles.largeClaims} ${
        isSelfFundedMode ? styles.sfcLargeClaims : ''
      }`}
    >
      <Row>
        <Col span={14}>
          {isSelfFundedMode && !isNaN(islValue) ? (
            <>
              <span className={styles.largeClaimsSfcLabel}>
                Large Claims Above Stop Loss{' '}
                {shortenCurrencyWithSuffix(islValue)}
              </span>
              <Table
                className={styles.largeClaimsTable}
                rowClassName={styles.largeClaimsRow}
                rowKey={(record) => record.id}
                columns={getChartColumns()}
                dataSource={generateGreaterThanISLDataList(
                  largeClaimsList,
                  islValue
                )}
                pagination={false}
                size="small"
              />
              <span className={styles.largeClaimsSfcLabel}>
                Large Claims Below Stop Loss{' '}
                {shortenCurrencyWithSuffix(islValue)}
              </span>
              <Table
                className={styles.largeClaimsTable}
                rowClassName={styles.largeClaimsRow}
                rowKey={(record) => record.id}
                columns={getChartColumns()}
                dataSource={generateLowerThanISLDataList(
                  largeClaimsList,
                  islValue
                )}
                pagination={false}
                size="small"
              />
            </>
          ) : (
            <Table
              className={styles.largeClaimsTable}
              rowClassName={styles.largeClaimsRow}
              rowKey={(record) => record.id}
              columns={getChartColumns()}
              dataSource={tableData}
              pagination={false}
              size="small"
            />
          )}
        </Col>
        <Col span={10}>
          <div className={styles.largeClaimChart}>
            <DoughnutChart
              dataKey="value"
              innerRadius={100}
              outerRadius={180}
              chartHeight={420}
              data={dataList}
              colors={COLORS}
              startAngle={450}
              endAngle={90}
              chartDimensions={{
                marginTop: -18,
                marginRight: 30,
                marginLeft: 10,
                marginBottom: 5,
              }}
              tooltip={tooltipContent}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LargeClaims;
