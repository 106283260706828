import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BenefitsConsultant from 'model/benefitsConsultation/BenefitsConsultant';

import * as BenefitConsultationService from 'modules/benefitsConsultation/services/BenefitConsultationService';
import { CALENDAR_SYNC_DENIED } from 'modules/benefitsConsultation/constants/benefitsConsultation';
export interface CommonBCState {
  inProgress: boolean;
  error: any;
  requestType: string;
  requestTypeInvite: string;
  consultant: BenefitsConsultant;
  cancelStatus: any;
  consultantType: string;
  consultantCalendarSyncStatus: boolean;
}

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  requestTypeInvite: '',
  consultant: {} as BenefitsConsultant,
  cancelStatus: null,
  consultantType: '',
  consultantCalendarSyncStatus: false,
} as CommonBCState;

const commonStarted = (state: CommonBCState, action: PayloadAction) => {
  state.inProgress = true;
  state.requestType = action.type;
  state.error = null;
};

const consultantCompleted = (
  state: CommonBCState,
  action: PayloadAction<BenefitsConsultant>
) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.consultant = action.payload;
};

const consultantFailed = (state: CommonBCState, action: PayloadAction<any>) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.error = action.payload;
  state.consultant = {} as BenefitsConsultant;
};

const commonDeleteSuccess = (state: CommonBCState, action: PayloadAction) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.error = null;
};

const commonDeleteFailed = (state: CommonBCState, action: PayloadAction) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.error = action.payload;
};

const commonSlice = createSlice({
  name: 'commonBC',
  initialState,
  reducers: {
    deleteConsultantInProgress: (state, action: PayloadAction) => {
      commonStarted(state, action);
    },
    deleteConsultantSuccess: (state, action: PayloadAction) => {
      commonDeleteSuccess(state, action);
    },
    deleteConsultantFailed: (state, action) => {
      commonDeleteFailed(state, action);
    },
    getConsultantStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
      state.error = null;
      state.consultant = {} as BenefitsConsultant;
    },
    getConsultantSuccess(state, action: PayloadAction<BenefitsConsultant>) {
      consultantCompleted(state, action);
    },
    getConsultantFailed(state, action: PayloadAction<any>) {
      consultantFailed(state, action);
    },
    editConsultantDetailsStarted(state, action: PayloadAction) {
      commonStarted(state, action);
    },
    editConsultantDetailsSuccess(
      state,
      action: PayloadAction<BenefitsConsultant>
    ) {
      consultantCompleted(state, action);
    },
    editConsultantDetailsFailed(state, action: PayloadAction<any>) {
      consultantFailed(state, action);
    },
    inviteConsultantsInProgress(state, action: PayloadAction) {
      state.requestTypeInvite = action.type;
      commonStarted(state, action);
    },
    inviteConsultantsSuccess(state, action: PayloadAction<BenefitsConsultant>) {
      state.requestTypeInvite = action.type;
      state.inProgress = false;
      state.requestType = action.type;
    },
    inviteConsultantsFailed(state, action: PayloadAction) {
      state.requestTypeInvite = action.type;
      state.error = action.payload;
      state.inProgress = false;
      state.requestType = action.type;
    },
    confirmInviteInProgress(state, action: PayloadAction) {
      commonStarted(state, action);
    },
    confirmInviteSuccess(state, action: PayloadAction<BenefitsConsultant>) {
      consultantCompleted(state, action);
    },
    confirmInviteFailed(state, action: PayloadAction) {
      commonStarted(state, action);
    },
    cancelAppointmentInProgress: (state, action: PayloadAction) => {
      commonStarted(state, action);
    },
    cancelAppointmentSuccess: (state, action: PayloadAction) => {
      commonDeleteSuccess(state, action);
    },
    cancelAppointmentFailed: (state, action) => {
      commonDeleteFailed(state, action);
    },
    getCancelStatusInProgress: (state, action: PayloadAction) => {
      commonStarted(state, action);
    },
    getCancelStatusSuccess: (state, action: PayloadAction<any>) => {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = null;
      state.cancelStatus = action.payload;
    },
    getCancelStatusFailed: (state, action) => {
      commonDeleteFailed(state, action);
      state.cancelStatus = null;
    },
    getConsultantTypeStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
      state.error = null;
      state.consultantType = '';
    },
    getConsultantTypeSuccess(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.consultantType = action.payload;
    },
    getConsultantTypeFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = action.payload;
      state.consultantType = '';
    },
    getConsultantCalendarSyncStatusStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
      state.error = null;
      state.consultantCalendarSyncStatus = false;
    },
    getConsultantCalendarSyncStatusSuccess(
      state,
      action: PayloadAction<boolean>
    ) {
      state.inProgress = false;
      state.requestType = action.type;
      state.consultantCalendarSyncStatus = action.payload;
    },
    getConsultantCalendarSyncStatusFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = action.payload;
      state.consultantCalendarSyncStatus = false;
    },
    calendarSyncFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = action.payload;
    },
  },
});

export const {
  deleteConsultantInProgress,
  deleteConsultantSuccess,
  deleteConsultantFailed,
  getConsultantStarted,
  getConsultantSuccess,
  getConsultantFailed,
  editConsultantDetailsStarted,
  editConsultantDetailsSuccess,
  editConsultantDetailsFailed,
  inviteConsultantsInProgress,
  inviteConsultantsSuccess,
  inviteConsultantsFailed,
  confirmInviteInProgress,
  confirmInviteSuccess,
  confirmInviteFailed,
  cancelAppointmentInProgress,
  cancelAppointmentSuccess,
  cancelAppointmentFailed,
  getCancelStatusInProgress,
  getCancelStatusSuccess,
  getCancelStatusFailed,
  getConsultantTypeStarted,
  getConsultantTypeSuccess,
  getConsultantTypeFailed,
  getConsultantCalendarSyncStatusStarted,
  getConsultantCalendarSyncStatusSuccess,
  getConsultantCalendarSyncStatusFailed,
  calendarSyncFailed,
} = commonSlice.actions;

export default commonSlice.reducer;

export const deleteConsultant = (consultantId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteConsultantInProgress());
    try {
      await BenefitConsultationService.removeConsultant(consultantId);
      dispatch(deleteConsultantSuccess());
    } catch (error) {
      dispatch(deleteConsultantFailed(JSON.parse(JSON.stringify(error))));
    }
  };
};

export const getConsultantDetails = (consultantId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getConsultantStarted());
    try {
      const response = await BenefitConsultationService.getConsultantDetails(
        consultantId
      );
      dispatch(getConsultantSuccess(response.data));
    } catch (error) {
      dispatch(getConsultantFailed(JSON.parse(JSON.stringify(error))));
    }
  };
};

export const getConsultantType = (consultantId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getConsultantTypeStarted());
    try {
      const response = await BenefitConsultationService.getConsultantType(
        consultantId
      );
      dispatch(getConsultantTypeSuccess(response.data));
    } catch (error) {
      dispatch(getConsultantTypeFailed(JSON.parse(JSON.stringify(error))));
    }
  };
};

export const getConsultantCalendarSyncStatus = (consultantId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getConsultantCalendarSyncStatusStarted());
    try {
      const response =
        await BenefitConsultationService.getConsultantCalendarSyncStatus(
          consultantId
        );
      dispatch(getConsultantCalendarSyncStatusSuccess(response.data));
    } catch (error) {
      dispatch(
        getConsultantCalendarSyncStatusFailed(JSON.parse(JSON.stringify(error)))
      );
    }
  };
};

export const inviteConsultants = (consultants: BenefitsConsultant[]) => {
  return async (dispatch: Dispatch) => {
    dispatch(inviteConsultantsInProgress());
    try {
      const response = await BenefitConsultationService.inviteConsultants(
        consultants
      );
      dispatch(inviteConsultantsSuccess(response.data));
    } catch (error) {
      dispatch(inviteConsultantsFailed());
    }
  };
};

export const confirmInvite = (
  consultantId: string,
  eventTrigger: string,
  type?: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(confirmInviteInProgress());
    try {
      const response = await BenefitConsultationService.confirmInvite(
        eventTrigger,
        consultantId,
        type
      );
      //  Redirect to Calendar Sync
      if (type) window.location = response.data.redirectUrl;
      dispatch(confirmInviteSuccess(response.data));
    } catch (error) {
      if (error?.response?.data?.code === CALENDAR_SYNC_DENIED) {
        dispatch(calendarSyncFailed(error));
      } else {
        dispatch(confirmInviteFailed());
      }
    }
  };
};

export const cancelAppointment = (
  eventTrigger: string,
  appointmentId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(cancelAppointmentInProgress());
    try {
      await BenefitConsultationService.confirmInvite(
        eventTrigger,
        undefined,
        undefined,
        appointmentId
      );

      dispatch(cancelAppointmentSuccess());
    } catch (error) {
      dispatch(cancelAppointmentFailed(JSON.parse(JSON.stringify(error))));
    }
  };
};

export const getCancelStatus = (
  eventTrigger: string,
  appointmentId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getCancelStatusInProgress());
    try {
      const response = await BenefitConsultationService.confirmInvite(
        eventTrigger,
        undefined,
        undefined,
        appointmentId
      );

      dispatch(getCancelStatusSuccess(response.data));
    } catch (error) {
      dispatch(getCancelStatusFailed(JSON.parse(JSON.stringify(error))));
    }
  };
};

export const editConsultantDetails = (
  consultantId: string,
  consultant: BenefitsConsultant
) => {
  return async (dispatch: Dispatch) => {
    dispatch(editConsultantDetailsStarted());
    try {
      await BenefitConsultationService.editConsultantDetails(
        consultantId,
        consultant
      );
      dispatch(editConsultantDetailsSuccess(consultant));
    } catch (error) {
      dispatch(editConsultantDetailsFailed(JSON.parse(JSON.stringify(error))));
    }
  };
};
