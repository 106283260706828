import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import AddTaxAdvantagedPlan from 'modules/plans/taxAdvantaged/components/AddTaxAdvantagedPlan/AddTaxAdvantagedPlan';
import { ReviewModalFragment } from 'modules/renewals/types/renewalsTypes';
import { TaxAdvantagedAccountPlan } from 'model/plans/TaxAdvantagedAccountPlan';
import { Plan } from 'model/plans/Plan';
import {
  uploadTemporaryDocument,
  updateCurrentSelectedPlan,
  removeDocumentReference,
} from 'modules/renewals/slices/addProposalToPlanYearSlice';

type Props = ReviewModalFragment;

const TaxAdvantageAccountsReview = ({
  state,
  formRef,
  setState,
  isModalVisible,
  benefitClasses,
}: Props) => {
  const dispatch = useAppDispatch();
  const planYearName = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.basicDetails.planYearName
  );
  const planCreateType = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.selectPlanCreateType
  );
  const isPlanReviewed = useAppSelector(
    (state) =>
      state.renewals.addProposalToPlanYear.isCurrentSelectedPlanReviewed
  );
  const isPlanMerged = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.isCurrentSelectedPlanMerged
  );

  const isMerging =
    planCreateType === 'MERGE' && !isPlanReviewed && isPlanMerged;

  useEffect(() => {
    if (
      !state.documentReferences['PLAN_SUMMARY']?.uploading &&
      state.documentReferences['PLAN_SUMMARY']?.fileName !== undefined
    )
      dispatch(
        updateCurrentSelectedPlan({
          ...state,
          file: {},
          fileName: state.documents?.['PLAN_SUMMARY'] ?? '',
          documentAvailable: true,
        })
      );
    // eslint-disable-next-line
  }, [state.documentReferences]);
  const handleFileRemove = (docType: any) => {
    dispatch(removeDocumentReference(docType));
  };

  const handleTaxAdvFileUpload = (
    document: File,
    docType: string,
    newPlan?: Plan
  ) => {
    dispatch(
      uploadTemporaryDocument(document, docType, {
        ...newPlan,
        documentReferences: state?.documentReferences,
      })
    );
  };

  return (
    <AddTaxAdvantagedPlan
      onReset={() => {}}
      isCancelButtonClickConfirm={false}
      closePlanAddModal={() => {}}
      planType={state.benefitKind || ''}
      onSaveClose={() => {}}
      plan={state as TaxAdvantagedAccountPlan}
      ref={formRef}
      isModalVisible={isModalVisible}
      isReview={true}
      onPlanSave={setState}
      onFileChangeAction={handleTaxAdvFileUpload}
      onFileRemoveAction={handleFileRemove}
      isReviewHighlight={isMerging}
      isPlanUpdateMode={true}
      reviewNewPlanYear={planYearName}
      reviewBenefitClasses={benefitClasses}
      isHightlightCarrier={false}
    />
  );
};

export default TaxAdvantageAccountsReview;
