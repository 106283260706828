import { FC, MutableRefObject } from 'react';
import BasicPlanInfo from 'modules/plans/components/BasicPlanInfo/BasicPlanInfo';

type BasicPlanInfoSBCWrapperProps = {
  onChange: Function;
  wrappedRef: MutableRefObject<any>;
  dbgPlanYear?: string;
};

const BasicPlanInfoSBCWrapper: FC<BasicPlanInfoSBCWrapperProps> = (
  props: BasicPlanInfoSBCWrapperProps
) => {
  const { onChange, wrappedRef, dbgPlanYear } = props;
  return (
    <BasicPlanInfo
      ref={wrappedRef}
      onChange={onChange}
      dbgPlanYear={dbgPlanYear}
    />
  );
};

export default BasicPlanInfoSBCWrapper;
