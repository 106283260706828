import { FC } from 'react';

import { AdditionalPlan } from 'model/plans/AdditionalPlan';
import AddVoluntaryBenefitPlan from 'modules/plans/voluntary/components/AddVoluntaryBenefitPlan/AddVoluntaryBenefitPlan';

type EditVoluntaryBenefitsPlanProps = {
  onClose: () => {};
  plan: AdditionalPlan;
  isSaveDisabled?: boolean;
  isDBGPlan?: boolean;
  isReview?: boolean;
  benefitType?: string;
  isModalVisible?: boolean;
};

const EditVoluntaryBenefitsPlan: FC<EditVoluntaryBenefitsPlanProps> = ({
  onClose,
  plan,
  isSaveDisabled,
  isDBGPlan,
  isReview,
  benefitType,
  isModalVisible,
}: EditVoluntaryBenefitsPlanProps) => {
  const { benefitKind } = plan;

  return (
    <div>
      <AddVoluntaryBenefitPlan
        benefitCategory={isReview ? benefitType : { value: benefitKind }}
        closePlanAddModal={onClose}
        isCloseConfirmed={true}
        onClose={onClose}
        isEdit={true}
        plan={plan}
        isSaveDisabled={isSaveDisabled}
        isDBGPlan={isDBGPlan}
        isReview={isReview}
        isModalVisible={isModalVisible}
      />
    </div>
  );
};

export default EditVoluntaryBenefitsPlan;
