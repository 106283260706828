import { FC, useEffect, useState } from 'react';

import { Alert, Form, Input, Modal, Spin } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import alertError from 'assets/images/alert-error.svg';
import AlertMessage from 'components/Alert/AlertMessage';
import AuthContainer from 'containers/AuthContainer/AuthContainer';
import CreatePasswordForm from 'modules/auth/components/CreatePasswordForm/CreatePasswordForm';
import InputForm from 'components/InputForm/InputForm';
import Terms from 'modules/auth/components/Terms/Terms';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import AuthHeading from 'modules/auth/components/AuthHeading/AuthHeading';
import {
  PASSWORD_VALIDATION_ERROR_MSG,
  REGISTER_FAILED_ERROR_MSG,
  SET_PASSWORD_ERROR_MESSAGES,
} from 'modules/auth/constants/authConstants';
import {
  adminSignUpSuccess,
  processSignUp,
  processRegistrationToken,
  validateTokenFailed,
  validateTokenStarted,
} from 'modules/auth/slices/registerSlice';
import { LOGIN_PATH } from 'modules/auth/routes';
import { validatePasswordComplexity } from 'util/passwordUtil';

import styles from './register.module.less';

const { Item } = Form;

const Register: FC = () => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [isTermsModalVisible, setIsTermsModalVisible] =
    useState<boolean>(false);
  const [proceedRegister, setProceedRegister] = useState<boolean>(false);

  const [params] = useSearchParams();

  const [form] = Form.useForm();

  const registerState = useAppSelector((state) => state.auth.register);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const token = params.get('token');

  useEffect(() => {
    if (!token) {
      navigate('/');
      return;
    }
    dispatch(processRegistrationToken(token));
  }, [token, dispatch, navigate]);

  // handle token validation result
  useEffect(() => {
    if (!registerState.inProgress) {
      if (!isEmpty(registerState.username)) {
        setUserEmail(registerState.username);
      }
    }
  }, [registerState.inProgress, registerState.username]);

  // sets invalid token state
  useEffect(() => {
    if (
      !registerState.inProgress &&
      registerState.error &&
      registerState.requestType === validateTokenFailed.type
    ) {
      setIsTokenValid(false);
    }
  }, [
    registerState.inProgress,
    registerState.error,
    registerState.requestType,
  ]);

  // navigate to login after successful sign up
  useEffect(() => {
    if (
      !registerState.inProgress &&
      registerState.requestType === adminSignUpSuccess.type
    ) {
      navigate(LOGIN_PATH, {
        state: {
          isRegister: true,
        },
        replace: true,
      });
    }
  }, [registerState.inProgress, registerState.requestType, navigate]);

  // handle api errors
  useEffect(() => {
    if (!registerState.inProgress && registerState.error) {
      if (
        Object.keys(SET_PASSWORD_ERROR_MESSAGES).includes(
          registerState.error.data.code
        )
      ) {
        setIsAlertVisible(true);
        setErrorMessage(
          SET_PASSWORD_ERROR_MESSAGES[registerState.error.data.code]
        );
      }
    }
  }, [registerState.inProgress, registerState.error]);

  const handleRegister = async () => {
    setIsSubmitClicked(true);
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }
    const password = form.getFieldValue('password');
    const passwordValid = validatePasswordComplexity(password);

    if (!passwordValid) {
      setIsAlertVisible(true);
      setErrorMessage(PASSWORD_VALIDATION_ERROR_MSG);
      return;
    }

    setIsTermsModalVisible(!proceedRegister);

    if (token && proceedRegister) {
      dispatch(processSignUp(token, password));
      setProceedRegister(false);
    }
  };

  useEffect(() => {
    if (proceedRegister) {
      handleRegister();
    }
    // eslint-disable-next-line
  }, [proceedRegister]);

  const handleFinishFailed = () => {
    setIsAlertVisible(true);
    setErrorMessage(REGISTER_FAILED_ERROR_MSG);
    setIsSubmitClicked(true);
  };

  const handleValuesChange = async (changedValues: any, allValues: any) => {
    form.setFieldsValue({ ...allValues });
    if (isSubmitClicked) {
      setIsSubmitClicked(false);
      setIsAlertVisible(false);
    }
  };

  if (
    registerState.inProgress &&
    registerState.requestType === validateTokenStarted.type
  ) {
    return (
      <AuthContainer>
        <Spin />
      </AuthContainer>
    );
  }

  return (
    <AuthContainer>
      <div className={styles.container}>
        {isAlertVisible && isTokenValid && (
          <AlertMessage
            wrapperClassName="authAlertWrapper"
            type="error"
            message={errorMessage}
            closeAlert={() => setIsAlertVisible(false)}
          />
        )}
        <AuthHeading header="Register" />
        {!isTokenValid ? (
          <div className={styles.tokenError}>
            {/* TODO: change once design is updated */}
            <Alert
              className={styles.errorAlert}
              type="error"
              icon={<img src={alertError} alt="error icon" />}
              showIcon
              message="Invalid Link"
              description="The Link is either invalid or expired. Please contact support."
            />
          </div>
        ) : (
          <>
            <InputForm layout="vertical">
              <Item label="Email">
                <Input disabled value={userEmail} />
              </Item>
            </InputForm>
            <CreatePasswordForm
              form={form}
              onSubmit={handleRegister}
              onSubmitFailed={handleFinishFailed}
              onValuesChange={handleValuesChange}
              submitButtonText="Register"
              loading={registerState.inProgress}
            />
          </>
        )}
      </div>
      <Modal
        width={600}
        maskClosable={false}
        closable={false}
        wrapClassName={styles.modalMain}
        visible={isTermsModalVisible}
        onCancel={() => {}}
        footer={null}
        bodyStyle={{ border: '2px', borderRadius: '4px' }}
      >
        <Terms
          setIsTermsModalOpen={setIsTermsModalVisible}
          setProceedRegister={setProceedRegister}
          isRegister={true}
        />
      </Modal>
    </AuthContainer>
  );
};

export default Register;
