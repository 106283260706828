import { FC, useCallback, useEffect, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { usePrevious } from 'hooks/usePrevious';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import AlertMessage from 'components/Alert/AlertMessage';
import BenefitClassMultiSelect from 'components/BenefitClassMultiSelect/BenefitClassMultiSelect';
import { BenefitGuideCloneRequest } from 'model/BenefitGuideCloneRequest';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import PlanYear from 'model/PlanYear';
import { removeDuplicates } from 'util/arrayUtil';
import {
  cloneBenguide,
  populatePlans,
} from 'modules/benefitGuide/slices/benguideSlice';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';

import {
  BENEFIT_GUIDE_ALREADY_EXISTS,
  BENEFIT_GUIDE_CLONING_INFO_MESSAGE,
  BENEFIT_GUIDE_CLONING_WARNING_MESSAGE,
} from 'modules/benefitGuide/constants/benefitGuideConstants';

import styles from './cloneBenefitGuideModal.module.less';

type CloneBenefitGuideModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  guideName: string;
  guidePlanYear: PlanYear;
  guideId: string;
  employerId: string;
  onCloned: Function;
};

const CloneBenefitGuideModal: FC<CloneBenefitGuideModalProps> = ({
  visible,
  setVisible,
  guideName,
  guidePlanYear,
  guideId,
  employerId,
  onCloned,
}: CloneBenefitGuideModalProps) => {
  const dispatch = useAppDispatch();
  const benguideSlice = useAppSelector((state) => state.benguide.benguideSlice);

  const [form] = Form.useForm();
  const { planYearsList } = useAppSelector((state) => state.employer.employer);
  const [benefitClassesList, setBenefitClassesList] = useState<string[]>([]);
  const [selectedBenefitClasses, setSelectedBenefitClasses] = useState<
    string[]
  >([]);
  const [isPlanPopulateModalOpen, setPlanPopulateModalOpen] =
    useState<boolean>(false);
  const [isShowCloneSuceessNotification, setShowCloneSuceessNotification] =
    useState<boolean>(false);
  const [
    isShowPopulateSuceessNotification,
    setShowPopulateSuceessNotification,
  ] = useState<boolean>(false);
  const [isEqualPlanYear, setIsEqualPlanYear] = useState<boolean>(true);

  const { cloneBenguide: cloneState, populateBenguidePlans } = benguideSlice;
  const {
    inprogress: cloning,
    error: cloningFailed,
    createdPlanId,
    isPlanIncluded,
  } = cloneState;
  const { inprogress: populating } = populateBenguidePlans;
  const previousCloning = usePrevious(cloning);
  const previousPopulating = usePrevious(populating);
  const { planYear } = form.getFieldsValue();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({ name: `Clone - ${guideName}` });
      setShowCloneSuceessNotification(false);
    } else {
      form.resetFields();
      setSelectedBenefitClasses([]);
      setIsEqualPlanYear(true);
    }
  }, [visible, form, guideName]);

  useEffect(() => {
    form.setFieldsValue({ benefitClasses: selectedBenefitClasses.join(', ') });
  }, [form, selectedBenefitClasses]);

  useEffect(() => {
    if (previousCloning && !cloning && !cloningFailed) {
      setVisible(false);
      if (isPlanIncluded) {
        setPlanPopulateModalOpen(true);
      }
      setShowCloneSuceessNotification(true);
      onCloned(form.getFieldValue('planYear'));
    }
  }, [
    cloning,
    previousCloning,
    setVisible,
    cloningFailed,
    onCloned,
    isPlanIncluded,
    form,
  ]);

  useEffect(() => {
    if (previousPopulating && !populating) {
      setPlanPopulateModalOpen(false);
      setShowPopulateSuceessNotification(true);
    }
  }, [previousPopulating, populating]);

  const onPlanYearSelect = useCallback(
    (value: string) => {
      setIsEqualPlanYear(false);
      const planYear = planYearsList.find((t) => t.id === value);
      const filteredGroups =
        planYear?.benefitGroups.filter(function (entry1) {
          return guidePlanYear?.benefitGroups.some(function (entry2) {
            return entry1 === entry2;
          });
        }) || ([] as any);
      if (planYear) {
        setBenefitClassesList(planYear.benefitGroups);
        form.setFieldsValue({
          planYear: planYear.id,
        });

        if (guidePlanYear.id === planYear.id) {
          setIsEqualPlanYear(true);
        }

        const { benefitGroups } = planYear;
        if (!isEmpty(benefitGroups) && benefitGroups.length === 1) {
          setSelectedBenefitClasses(
            removeDuplicates([...selectedBenefitClasses, ...benefitGroups])
          );
        } else if (guidePlanYear.id === planYear.id) {
          setSelectedBenefitClasses([]);
        } else if (
          isEqual(
            sortBy(guidePlanYear.benefitGroups),
            sortBy(planYear.benefitGroups)
          )
        ) {
          setSelectedBenefitClasses(guidePlanYear.benefitGroups);
        } else if (filteredGroups) {
          setSelectedBenefitClasses(filteredGroups);
        } else {
          setSelectedBenefitClasses([]);
        }
      }
    },
    [
      planYearsList,
      form,
      guidePlanYear.benefitGroups,
      guidePlanYear.id,
      selectedBenefitClasses,
    ]
  );

  const changeBenefitClass = (event: CheckboxChangeEvent) => {
    const { target } = event;
    const { value } = target;
    const isInclude = selectedBenefitClasses.includes(value);
    if (isInclude) {
      setSelectedBenefitClasses(
        selectedBenefitClasses.filter((element) => element != value)
      );
    } else {
      setSelectedBenefitClasses([...selectedBenefitClasses, value]);
    }
  };

  const submit = () => {
    form.validateFields().then((res: BenefitGuideCloneRequest) => {
      res.benefitClasses = selectedBenefitClasses;
      dispatch(
        cloneBenguide(guideId, form.getFieldValue('planYear'), employerId, res)
      );
    });
  };

  const checkDuplicateBenGuide = () => {
    if (
      benguideSlice.cloneBenguide &&
      !benguideSlice.cloneBenguide.inprogress &&
      benguideSlice.cloneBenguide.error !== null &&
      benguideSlice.cloneBenguide.error.response &&
      benguideSlice.cloneBenguide.error.response.data &&
      benguideSlice.cloneBenguide.error.response.data.code ===
        BENEFIT_GUIDE_ALREADY_EXISTS
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (checkDuplicateBenGuide()) {
      form.setFields([
        {
          name: 'name',
          errors: ['Name already exists'],
        },
      ]);
    }
    // omitted form because the change only needs to occur when call receives again from the backend
    // clarifying if the dbg is a duplicate or not
    // eslint-disable-next-line
  }, [benguideSlice.cloneBenguide.error]);

  const populatePlanSubmit = () => {
    if (createdPlanId) {
      dispatch(
        populatePlans(createdPlanId, guidePlanYear.id, employerId, guideId)
      );
    }
  };

  return (
    <>
      {isShowCloneSuceessNotification && (
        <AlertMessage
          type={'success'}
          message="Guide has been cloned successfully"
          closeAlert={() => {
            setShowCloneSuceessNotification(false);
          }}
          wrapperClassName={styles.dbgActionMessage}
        />
      )}
      {isShowPopulateSuceessNotification && (
        <AlertMessage
          type={'success'}
          message="Guide has been cloned successfully and all eligible plans have been auto-populated"
          closeAlert={() => {
            setShowPopulateSuceessNotification(false);
          }}
          wrapperClassName={styles.dbgActionMessage}
        />
      )}
      <ConfirmationDialog
        title="Clone Benefits Guide"
        confirmText="Clone Benefits Guide"
        cancelText="Cancel"
        closeModal={() => {
          setVisible(!visible);
          setIsEqualPlanYear(false);
        }}
        onConfirm={submit}
        visible={visible}
        isCloseOnly={false}
        modalClassName={styles.cloneModalWrapper}
        confirmLoading={cloning}
        isCancelLink={true}
      >
        {isEqualPlanYear ? (
          <FixedAlertMessage
            wrapperClassName={styles.cloneMessageBox}
            className={styles.textMessage}
            message={<p>{BENEFIT_GUIDE_CLONING_INFO_MESSAGE}</p>}
            type="info"
          />
        ) : (
          <FixedAlertMessage
            wrapperClassName={styles.cloneMessageBox}
            className={styles.textMessage}
            message={<p>{BENEFIT_GUIDE_CLONING_WARNING_MESSAGE}</p>}
            type="warning"
          />
        )}
        <div className={styles.cloneBenefitGuideWrapper}>
          <Form form={form} layout="vertical">
            <Form.Item
              name="name"
              label="Benefits Guide Name"
              rules={[
                {
                  required: true,
                  message: 'Benefit Guide name required',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Plan Year"
              name="planYear"
              rules={[
                {
                  required: true,
                  message: 'Plan Year required',
                  whitespace: true,
                },
              ]}
            >
              <Select
                className={styles.planYearSelector}
                dropdownClassName={styles.planYearSelectorOptions}
                onSelect={onPlanYearSelect}
              >
                {planYearsList &&
                  planYearsList
                    .filter((planYear) => !planYear.previous)
                    .map((planYear: PlanYear) => (
                      <Select.Option value={planYear.id} key={planYear.id}>
                        {planYear.name}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="benefitClasses"
              label="Benefit Classes"
              rules={[
                {
                  required: true,
                  message: 'Benefit Classes required',
                  whitespace: true,
                },
              ]}
            >
              <Input className={styles.hide} />
              <div
                className={`${styles.benefitClasses} ${
                  !planYear ? styles.disabled : ''
                }`}
              >
                <BenefitClassMultiSelect
                  options={benefitClassesList}
                  onChange={changeBenefitClass}
                  disabled={!planYear}
                  name="groups"
                  selectedItemValues={selectedBenefitClasses}
                />
              </div>
            </Form.Item>
          </Form>
        </div>
      </ConfirmationDialog>
      <ConfirmationDialog
        title="Auto-Populate Plans? "
        confirmText="Yes"
        cancelText="No"
        closeModal={() => setPlanPopulateModalOpen(!isPlanPopulateModalOpen)}
        onConfirm={populatePlanSubmit}
        visible={isPlanPopulateModalOpen}
        isCloseOnly={false}
        modalClassName={styles.planPopulateModalWrapper}
        confirmLoading={populating}
      >
        <p className={styles.archiveText}>
          Only eligible plans will be cloned into the new Benefits Guide. Plans
          that are not eligible (e.g., plans from a benefit class with no
          associated plans) will not be auto-populated.
        </p>
        <p className={styles.archiveText}>
          Do you want to proceed with cloning the Benefits Guide?
        </p>
      </ConfirmationDialog>
    </>
  );
};
export default CloneBenefitGuideModal;
