import { FC, useEffect, useMemo, useState } from 'react';
import { StompSessionProvider } from 'react-stomp-hooks';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Col, PageHeader, Row } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { useNavContext } from 'hooks/useNavContext';
import BasicPlanInfoOverview from 'modules/plans/components/OverviewPage/BasicPlanInfoOverview/BasicPlanInfoOverview';
import PlanDocumentsOverview from 'modules/plans/components/OverviewPage/PlanDocumentsOverview/PlanDocumentOverview';
import { findPlanById } from 'modules/plans/slices/workLifePlanSlice';
import { findWellbeingPlanById } from 'modules/plans/slices/wellbeingPlanSlice';

import { findAdditionalPerkPlanById } from 'modules/plans/slices/additionalPerkPlanSlice';
import { BenefitCategory } from 'constants/commonConstants';
import { wsBaseApi } from 'util/apiUtil';

import PlanCollaborationNotification from 'components/PlanCollaborationNotification/PlanCollaborationNotification';
import styles from 'modules/plans/components/WorkLifePlanOverview/workLifePlanOverview.module.less';
import { usePermitIf } from 'hooks/usePermitIf';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import { allowedCommonButtonPermittedTypes } from 'constants/permittedConstants';

type WorkLifePlanOverviewProps = {
  type: 'WELLBEING' | 'FAMILY_HOLIDAY_TIME_OFF' | 'ADDITIONAL_PERKS';
};

const WorkLifePlanOverview: FC<WorkLifePlanOverviewProps> = (props) => {
  const { type } = props;

  const params = useParams();
  const { employerId, brokerId } = useNavContext();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { workLifePlan, error } = useAppSelector(
    (state) => state.plan.holidaysTimeOffPlan
  );
  const { wellbeingPlan } = useAppSelector((state) => state.plan.wellbeing);

  const { additionalPerkPlan } = useAppSelector(
    (state) => state.plan.additionalPerk
  );
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const navigate = useNavigate();

  const [viewPlanModal, setViewPlanModal] = useState(false);
  const [disableEditButton, setDisableEditButton] = useState(false);
  const [viewPlanDocumentsModal, setViewPlanDocumentsModal] = useState(false);
  const [fetchPlanWhenUpdated, setFetchPlanWenUpdated] =
    useState<boolean>(false);
  useEffect(() => {
    if (employerId && brokerId && params.planId && !wellbeingPlan) {
      switch (type) {
        case 'FAMILY_HOLIDAY_TIME_OFF':
          dispatch(findPlanById(params.planId));
          break;
        case 'WELLBEING':
          dispatch(findWellbeingPlanById(params.planId));
          break;
        case 'ADDITIONAL_PERKS':
          dispatch(findAdditionalPerkPlanById(params.planId));
          break;
      }
    }
  }, [dispatch, employerId, brokerId, params.planId, type, wellbeingPlan]);

  const plan = useMemo(() => {
    switch (type) {
      case 'FAMILY_HOLIDAY_TIME_OFF':
        return workLifePlan;
      case 'WELLBEING':
        return wellbeingPlan;
      case 'ADDITIONAL_PERKS':
        return additionalPerkPlan;
    }
  }, [type, workLifePlan, wellbeingPlan, additionalPerkPlan]);

  const navigateToFamilyAndLeave = () => {
    navigate(`/brokers/${brokerId}/employers/${employerId}/family-and-leave`);
  };

  const navigateToHolidayAndTimeOff = () => {
    navigate(`/brokers/${brokerId}/employers/${employerId}/holiday-time-off`);
  };

  const exitPlan = () => {
    if (type === BenefitCategory.WELLBEING.value) {
      navigate(`/brokers/${brokerId}/employers/${employerId}/wellbeing`);
    } else if (type === 'FAMILY_HOLIDAY_TIME_OFF') {
      if (workLifePlan && workLifePlan.workLifeSubCategory === 'FAMILY') {
        navigateToFamilyAndLeave();
      } else if (
        workLifePlan &&
        workLifePlan.workLifeSubCategory === 'TIME_OFF'
      ) {
        navigateToHolidayAndTimeOff();
      } else if (error && location.pathname.includes('holiday-time-off')) {
        navigateToHolidayAndTimeOff();
      } else if (error && location.pathname.includes('family-and-leave')) {
        navigateToFamilyAndLeave();
      }
    } else if (type === 'ADDITIONAL_PERKS') {
      navigate(`/brokers/${brokerId}/employers/${employerId}/additional-perks`);
    }
  };

  const refreshPlan = () => {
    if (type === BenefitCategory.WELLBEING.value) {
      if (employerId && brokerId && params.planId) {
        dispatch(findWellbeingPlanById(params.planId));
      }
    } else if (type === 'FAMILY_HOLIDAY_TIME_OFF') {
      if (employerId && brokerId && params.planId) {
        dispatch(findPlanById(params.planId));
      }
    } else if (type === 'ADDITIONAL_PERKS') {
      if (employerId && brokerId && params.planId) {
        dispatch(findAdditionalPerkPlanById(params.planId));
      }
    }
    setFetchPlanWenUpdated(false);
  };

  const updateViewPlanModal = (value: boolean) => {
    setViewPlanModal(value);
  };

  const updateViewPlanDocumentsModal = (value: boolean) => {
    setViewPlanDocumentsModal(value);
  };

  const wellbeingNavigation: { name: string; link: string }[] = [
    {
      name: 'All Wellbeing Plans',
      link: `/brokers/${brokerId}/employers/${employerId}/wellbeing`,
    },
  ];
  const familyLeaveNavigation: { name: string; link: string }[] = [
    {
      name: 'All Family & Leave Plans',
      link: `/brokers/${brokerId}/employers/${employerId}/family-and-leave`,
    },
  ];
  const holidayNavigation: { name: string; link: string }[] = [
    {
      name: 'All Holiday & Time Off Plans',
      link: `/brokers/${brokerId}/employers/${employerId}/holiday-time-off`,
    },
  ];
  const additionalNavigation: { name: string; link: string }[] = [
    {
      name: 'All Additional Perks Plans',
      link: `/brokers/${brokerId}/employers/${employerId}/additional-perks`,
    },
  ];

  const getBreadCrumbData = () => {
    switch (type) {
      case BenefitCategory.WELLBEING.value:
        return wellbeingNavigation;
        break;
      case 'FAMILY_HOLIDAY_TIME_OFF':
        if (workLifePlan && workLifePlan.workLifeSubCategory === 'FAMILY') {
          return familyLeaveNavigation;
        } else if (
          workLifePlan &&
          workLifePlan.workLifeSubCategory === 'TIME_OFF'
        ) {
          return holidayNavigation;
        }
        break;
      case 'ADDITIONAL_PERKS':
        return additionalNavigation;
        break;
    }
  };

  const securedBreadcrumb = usePermitIf(
    <BreadcrumbNavigation breadCrumbData={getBreadCrumbData()} />,
    allowedCommonButtonPermittedTypes,
    []
  );

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col span={24}>
          <Row>{securedBreadcrumb}</Row>
          <Col span={18}>
            <PageHeader className={styles.pageTitle} title={plan.name} />
          </Col>
        </Col>
      </Row>
      <div className={styles.overview}>
        <StompSessionProvider
          url={`${wsBaseApi}/websocket-plan-update`}
          onWebSocketError={(e) => console.error(e)}
          reconnectDelay={500}
        >
          <PlanCollaborationNotification
            loggedInUserIndividualId={appBootInfo?.individualId}
            exitPlan={() => exitPlan()}
            refreshPlan={() => refreshPlan()}
            planId={plan.name}
            isInEdit={viewPlanModal}
            disableEdit={() => setDisableEditButton(true)}
            fetchPlan={() => setFetchPlanWenUpdated(true)}
          />
        </StompSessionProvider>
        <div className={styles.basicInfoWrapper}>
          <BasicPlanInfoOverview
            heading={'Plan Details'}
            planName={plan.name}
            planYearName={plan.planYear?.name}
            benefitClasses={plan.groups}
            carrierName={plan.benefitCarrier?.name}
            type={type}
            viewPlanModal={viewPlanModal}
            setViewPlanModal={updateViewPlanModal}
            disableEditButton={disableEditButton}
            fetchPlanWhenUpdated={fetchPlanWhenUpdated}
            exitPlan={() => exitPlan()}
            groupId={plan.groupId}
          />
        </div>

        <div className={styles.overview} id={'Plan_Doc'}>
          <PlanDocumentsOverview
            heading={'Plan Resources'}
            plan={plan}
            documents={plan.documents}
            benefitKind={plan.benefitKind}
            viewPlanDocumentsModal={viewPlanDocumentsModal}
            setViewPlanDocumentsModal={(value) =>
              updateViewPlanDocumentsModal(value)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default WorkLifePlanOverview;
