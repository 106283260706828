import { BenefitCategory } from 'constants/commonConstants';
import PanelSection from './enums/PanelSection';
import { DocumentExtractionSource } from './enums/DocumentExtractionSource';

/** Predefined services codes **/

export const DEFAULT_SERVICE_CODES = {
  [BenefitCategory.MEDICAL.value]: [
    95, //  Preventive Care
    1, // Primary Care Visit
    2, // Specialist Visit
    106, // Coinsurance
    13, // Urgent Care
    16, // Ambulance
    15, // Emergency Room
    77, // Lab & X-Ray
    87, // Inpatient
    88, // Outpatient
    89, // Mental Health (Outpatient)
    34, // Chiropractic Care
    40, // Acupuncture
    90, // Childbirth/Delivery Services
    35, // Durable Medical Equipment
    92, // Habilitation Services
    14, // ClaimsHomePage Health Care
    91, // Hospice Services
    37, // Imaging (CT/PET/MRI)
    9, // Infertility Treatment
    25, // Mental Health (Inpatient)
    93, // Rehabilitation Services
    94, // Skilled Nursing Care
  ],
  [BenefitCategory.DENTAL.value]: [
    38, //  Preventive Care
    53, //  Basic Dental Care
    55, //  Major Dental Care
    54, //  Orthodontia
    96, //  Orthodontia Coinsurance
    97, //  OON Reimbursement
    98, // Waiting Period
    99, // Endodontic Care
    100, // Periodontic Care
    101, // Implants
  ],
  [BenefitCategory.VISION.value]: [
    12, //  Routine Eye Exam
    70, //  Materials
    71, //  Frames
    72, //  Lenses (Single)
    73, //  Lenses (Bi)
    74, //  Lenses (Tri)
    42, // Routine Eye Exam for Children
    43, // Eye Glasses for Children
    76, // Medical Nec. Contacts
    75, //  Elective Contacts
    86, // Laser Correction Surgery
    102, // Routine Eye Exam Frequency
    103, // Lenses Frequency
    104, // Frames Frequency
    105, // Contact Lenses Frequency
  ],
};

export const CUSTOM_SERVICE_CODE_RANGE = 9000;

export const IN_NETWORK = 'In Network';
export const OUT_OF_NETWORK = 'Out of Network';

export const N_TIER_PROPERTY = 'ntierContributions';
export const FOUR_TIER_PROPERTY = 'fourTierContributions';

export const CostType = {
  COPAY: 'COPAY',
  COINSURANCE: 'COINSURANCE',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
  NOT_COVERED: 'NOT_COVERED',
  OTHER: 'OTHER',
};

export const costTypes = [
  { label: 'Copay', value: 'COPAY' },
  { label: 'Coinsurance', value: 'COINSURANCE' },
  { label: 'Not Covered', value: 'NOT_COVERED' },
  { label: 'Other', value: 'OTHER' },
];

export const inNetworkMedicalCostTypes = [
  { label: 'Copay', value: 'COPAY' },
  { label: 'Coinsurance', value: 'COINSURANCE' },
  { label: 'Not Applicable', value: 'NOT_APPLICABLE' },
  { label: 'Not Covered', value: 'NOT_COVERED' },
  { label: 'Other', value: 'OTHER' },
];

export const serviceFrequencies = [
  {
    label: 'per admission',
    value: 'PER_ADMISSION',
    overviewLabel: 'per admission',
  },
  {
    label: 'per procedure',
    value: 'PER_PROCEDURE',
    overviewLabel: 'per procedure',
  },
  { label: 'per trip', value: 'PER_TRIP', overviewLabel: 'per trip' },
  { label: 'per day', value: 'PER_DAY', overviewLabel: 'per day' },
  { label: 'per week', value: 'PER_WEEK', overviewLabel: 'per week' },
  { label: 'per month', value: 'PER_MONTH', overviewLabel: 'per month' },
  { label: 'per year', value: 'PER_YEAR', overviewLabel: 'per year' },
  { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
  {
    label: 'every other year',
    value: 'EVERY_OTHER_YEAR',
    overviewLabel: 'every other year',
  },
  { label: 'per service', value: 'PER_SERVICE', overviewLabel: 'per service' },
  { label: 'per script', value: 'PER_SCRIPT', overviewLabel: 'per script' },
  { label: 'per item', value: 'PER_ITEM', overviewLabel: 'per item' },
];

export const dentalServiceFrequencies = [
  { label: 'per service', value: 'PER_SERVICE', overviewLabel: 'per service' },
  { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
];

export const visionServiceFrequencies = [
  { label: 'per year', value: 'PER_YEAR', overviewLabel: 'per year' },
  {
    label: 'every other year',
    value: 'EVERY_OTHER_YEAR',
    overviewLabel: 'every other year',
  },
];

export const medicalServiceFrequencies = [
  {
    name: 'Preventive Care',
    options: [
      { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
    ],
  },
  {
    name: 'Primary Care Visit',
    options: [
      { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
    ],
  },
  {
    name: 'Specialist Visit',
    options: [
      { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
    ],
  },
  {
    name: 'Coinsurance',
    options: [
      { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
    ],
  },
  {
    name: 'Urgent Care',
    options: [
      { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
    ],
  },
  {
    name: 'Ambulance',
    options: [
      { label: 'per trip', value: 'PER_TRIP', overviewLabel: 'per trip' },
    ],
  },
  {
    name: 'Emergency Room Care',
    options: [
      { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
    ],
  },
  {
    name: 'Emergency Room',
    options: [
      { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
    ],
  },
  {
    name: 'Lab & X-Ray',
    options: [
      {
        label: 'per procedure',
        value: 'PER_PROCEDURE',
        overviewLabel: 'per procedure',
      },
    ],
  },
  {
    name: 'Imaging (CT/PET/MRI)',
    options: [
      {
        label: 'per procedure',
        value: 'PER_PROCEDURE',
        overviewLabel: 'per procedure',
      },
    ],
  },
  {
    name: 'Inpatient',
    options: [
      {
        label: 'per admission',
        value: 'PER_ADMISSION',
        overviewLabel: 'per admission',
      },
    ],
  },
  {
    name: 'Outpatient',
    options: [
      {
        label: 'per procedure',
        value: 'PER_PROCEDURE',
        overviewLabel: 'per procedure',
      },
    ],
  },
  {
    name: 'Childbirth/Delivery Services',
    options: [
      {
        label: 'per procedure',
        value: 'PER_PROCEDURE',
        overviewLabel: 'per procedure',
      },
    ],
  },
  {
    name: 'Mental Health (Outpatient)',
    options: [
      { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
    ],
  },
  {
    name: 'Chiropractic Care',
    options: [
      { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
    ],
  },
  {
    name: 'Acupuncture',
    options: [
      { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
    ],
  },
  {
    name: 'Durable Medical Equipment',
    options: [
      { label: 'per item', value: 'PER_ITEM', overviewLabel: 'per item' },
    ],
  },
  {
    name: 'Habilitation Services',
    options: [
      { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
    ],
  },
  {
    name: 'Home Health Care',
    options: [
      { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
    ],
  },
  {
    name: 'Hospice Services',
    options: [{ label: 'per day', value: 'PER_DAY', overviewLabel: 'per day' }],
  },
  {
    name: 'Infertility Treatment',
    options: [
      { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
      {
        label: 'per procedure',
        value: 'PER_PROCEDURE',
        overviewLabel: 'per procedure',
      },
      { label: 'per script', value: 'PER_SCRIPT', overviewLabel: 'per script' },
    ],
  },
  {
    name: 'Mental Health (Inpatient)',
    options: [
      {
        label: 'per admission',
        value: 'PER_ADMISSION',
        overviewLabel: 'per admission',
      },
    ],
  },
  {
    name: 'Rehabilitation Services',
    options: [
      { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
    ],
  },
  {
    name: 'Skilled Nursing Care',
    options: [{ label: 'per day', value: 'PER_DAY', overviewLabel: 'per day' }],
  },
  {
    name: 'Custom Services',
    options: [
      { label: 'per visit', value: 'PER_VISIT', overviewLabel: 'per visit' },
      {
        label: 'per procedure',
        value: 'PER_PROCEDURE',
        overviewLabel: 'per procedure',
      },
      {
        label: 'per admission',
        value: 'PER_ADMISSION',
        overviewLabel: 'per admission',
      },
      { label: 'per trip', value: 'PER_TRIP', overviewLabel: 'per trip' },
      { label: 'per day', value: 'PER_DAY', overviewLabel: 'per day' },
      { label: 'per script', value: 'PER_SCRIPT', overviewLabel: 'per script' },
      { label: 'per item', value: 'PER_ITEM', overviewLabel: 'per item' },
    ],
  },
];

export const rxFrequencies = [
  { label: 'Per Script', value: 'PER_SCRIPT' },
  { label: 'No Info', value: 'NO_INFO' },
];

export const choices = ['Yes', 'No'];

export const BenefitCovered = {
  YES: 'YES',
  NO: 'NO',
  CLEAR: '',
};

export const PriorToDeductible = {
  YES: 'YES',
  NO: 'NO',
};

export const priorToDeductibleOptions = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
];

export const RxTier: {
  [key: string]: { value: string; label: string };
} = {
  TIER_1: { value: 'TIER_1', label: 'Tier 1 Retail Rx' },
  TIER_2: { value: 'TIER_2', label: 'Tier 2 Retail Rx' },
  TIER_3: { value: 'TIER_3', label: 'Tier 3 Retail Rx' },
  TIER_4: { value: 'TIER_4', label: 'Tier 4 Retail Rx' },
  TIER_5: { value: 'TIER_5', label: 'Tier 5 Retail Rx' },
  TIER_6: { value: 'TIER_6', label: 'Tier 6 Retail Rx' },
  TIER_7: { value: 'TIER_7', label: 'Tier 7 Retail Rx' },
  TIER_8: { value: 'TIER_8', label: 'Tier 8 Retail Rx' },
};

export const RxTierMailOrders: {
  [key: string]: { value: string; label: string };
} = {
  TIER_1: { value: 'TIER_1', label: 'Tier 1 Mail Order' },
  TIER_2: { value: 'TIER_2', label: 'Tier 2 Mail Order' },
  TIER_3: { value: 'TIER_3', label: 'Tier 3 Mail Order' },
  TIER_4: { value: 'TIER_4', label: 'Tier 4 Mail Order' },
  TIER_5: { value: 'TIER_5', label: 'Tier 5 Mail Order' },
  TIER_6: { value: 'TIER_6', label: 'Tier 6 Mail Order' },
  TIER_7: { value: 'TIER_7', label: 'Tier 7 Mail Order' },
  TIER_8: { value: 'TIER_8', label: 'Tier 8 Mail Order' },
};

export const RxTiers = {
  TIER_1: 'TIER_1',
  TIER_2: 'TIER_2',
  TIER_3: 'TIER_3',
  TIER_4: 'TIER_4',
  TIER_5: 'TIER_5',
  TIER_6: 'TIER_6',
  TIER_7: 'TIER_7',
  TIER_8: 'TIER_8',
};

export const RxTiersDefault = {
  TIER_1: 'TIER_1',
  TIER_2: 'TIER_2',
  TIER_3: 'TIER_3',
  TIER_4: 'TIER_4',
};

export const Tooltips = {
  BASIC_PLAN_INFO:
    'This info is not always available from the SBC and must be entered manually. Plan Name, Plan Year, and Benefit Groups are required to save the plan and continue.',
  RATES:
    'Rate info is not available from the SBC. Please fill it in manually. You can fill in rate info while the SBC is being uploaded and scanned.',
  DEDUCTIBLES_OOP_MAX:
    'When you upload the SBC for this plan we will do our best to scan for the correct deductible and Out of Pocket Maximums. If you see something that isn’t right you can correct it or upload a new SBC.',
  SERVICES:
    'We include standard services as part of the plan info. If you need to add a service not shown in the list click the link to “Add Service” at the bottom of the list.',
  RX: 'We list the RX as “Tiers” that you can use to correspond to the terms used by the carrier in your specific SBC. For example “Tier 1” can be used for “Generic” etc.',
  ID_CARD_INFO:
    'This information will populate the digital ID card for this plan.',
  ENROLLMENTS:
    'This information will populate the digital ID card for this plan.',
  PLAN_DOCUMENTS: 'You can upload related plan documents here.',
};

export const TooltipHeadings = {
  BASIC_PLAN_INFO: 'Basic Plan Info',
  RATES: 'Premiums & Contributions',
  DEDUCTIBLES_OOP_MAX: 'Deductibles & OOP Max',
  SERVICES: 'Services',
  RX: 'RX',
  ID_CARD_INFO: 'ID Card Information',
  PLAN_DOCUMENTS: 'Plan Documents',
  ENROLLMENTS: 'Enrollments',
  PLAN_INFORMATION: 'Plan Information',
} as const;

export const RateType = {
  FOUR_TIER: {
    value: 'FOUR_TIER',
    label: 'Standard 4 Tier',
    fieldName: 'fourTierContributions',
  },
  N_TIER: { value: 'N_TIER', label: 'N Tier', fieldName: 'ntierContributions' },
  AGE_BAND: { value: 'AGE_BAND', label: 'Age Band', fieldName: '' },
};

export const RateFrequency: {
  [key: string]: { value: string; label: string };
} = {
  MONTHLY: { value: 'MONTHLY', label: 'Monthly' },
  SEMI_MONTHLY: { value: 'SEMI_MONTHLY', label: 'Semi-Monthly' },
  BI_WEEKLY: { value: 'BI_WEEKLY', label: 'Bi-Weekly' },
  PER_PAY_PERIOD: { value: 'PER_PAY_PERIOD', label: 'Per Pay Period' },
};

export const ContributionType = {
  FIXED: { value: 'FIXED', label: 'Fixed Dollar' },
  PERCENTAGE: { value: 'PERCENTAGE', label: 'Percentage' },
  ACA_RATES: { value: 'ACA_RATES', label: 'ACA Rates' },
};

export const NTier: {
  [key: string]: { value: string; label: string; id?: string };
} = {
  EE: { value: 'EE', label: 'EE', id: 'eNtier' },
  EE_PLUS_1: { value: 'EE_PLUS_1', label: 'EE+1', id: 'e1Ntier' },
  EE_PLUS_2: { value: 'EE_PLUS_2', label: 'EE+2', id: 'e2Ntier' },
  EE_PLUS_3: { value: 'EE_PLUS_3', label: 'EE+3', id: 'e3Ntier' },
  EE_PLUS_4: { value: 'EE_PLUS_4', label: 'EE+4', id: 'e4Ntier' },
  EE_PLUS_5: { value: 'EE_PLUS_5', label: 'EE+5', id: 'e5Ntier' },
  EE_PLUS_6: { value: 'EE_PLUS_6', label: 'EE+6', id: 'e6Ntier' },
};

export const N_TIER_DEFAULTS = {
  EE: { value: 'EE', label: 'EE' },
  EE_PLUS_1: { value: 'EE_PLUS_1', label: 'EE+1' },
};

export const NTiers = ['EE', 'EE+1', 'EE+2', 'EE+3', 'EE+4', 'EE+5', 'EE+6'];

export const FourTier: {
  [key: string]: { value: string; label: string; order: number; id?: string };
} = {
  EE: { value: 'EE', label: 'EE', order: 1, id: 'eeFTier' },
  ES: { value: 'ES', label: 'ES', order: 2, id: 'esFTier' },
  EC: { value: 'EC', label: 'EC', order: 3, id: 'ecFTier' },
  EF: { value: 'EF', label: 'EF', order: 4, id: 'efFTier' },
};

export const RateTier = {
  ...NTier,
  ...FourTier,
};

export const FourTierOrder: { [key: string]: number } = {
  EE: 1,
  ES: 2,
  EC: 3,
  EF: 4,
};

export const NTierOrder: { [key: string]: number } = {
  EE: 1,
  EE_PLUS_1: 2,
  EE_PLUS_2: 3,
  EE_PLUS_3: 4,
  EE_PLUS_4: 5,
  EE_PLUS_5: 6,
  EE_PLUS_6: 7,
};

export const MedicalPlanDocumentType = {
  SBC: { value: 'SBC', label: 'SBC' },
  COC: { value: 'COC', label: 'COC' },
  PLAN_SUMMARY: { value: 'PLAN_SUMMARY', label: 'Plan Summary' },
};

export const DentalPlanDocumentType = {
  PLAN_SUMMARY: { value: 'PLAN_SUMMARY', label: 'Plan Summary' },
  COC: { value: 'COC', label: 'COC File' },
};
export const VisionPlanDocumentType = {
  PLAN_SUMMARY: { value: 'PLAN_SUMMARY', label: 'Plan Summary' },
  COC: { value: 'COC', label: 'COC File' },
};
export const TaxAdvantagedPlanDocumentType = {
  PLAN_SUMMARY: { value: 'PLAN_SUMMARY', label: 'Plan Summary' },
  PLAN_DOCUMENT: { value: 'PLAN_DOCUMENT', label: 'Plan Document' },
};

export const NetworkRowFields = {
  COST_TYPE: 'costType',
  FREQUENCY: 'frequency',
  AMOUNT: 'amount',
  DEDUCTIBLE_PRIOR: 'deductiblePrior',
  COVERAGE: 'coverage',
  COVERED_AMOUNT: 'coveredAmount',
  INFO: 'info',
};

export const BasicInfoFields = {
  BENEFIT_CARRIER_ID: 'benefitCarrierId',
  BENEFIT_CARRIER: 'benefitCarrier',
  PLAN_YEAR: 'planYearId',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  BENEFIT_GROUPS: 'groups',
  PLAN_NAME: 'name',
  HSA_COMPATIBLE: 'hsaCompatible',
  HRA_COMPATIBLE: 'hraCompatible',
  GROUP_ID: 'groupId',
  ENROLLMENT: 'enrollment',
  PLAN_TYPE: 'type',
  PLAN_NETWORK_NAME: 'planNetworkName',
  STATES_TERRITORIES: 'states',
  FUNDING_TYPE: 'fundingType',

  INDIVIDUAL_STOP_LOSS: 'individualStopLoss',
  INDIVIDUAL_ADMINISTRATION_FEE: 'individualAdministrationFee',
  AGGREGATE_STOP_LOSS: 'aggregatedStopLoss',
  AGGREGATED_ADMINISTRATION_FEE: 'aggregatedAdministrationFee',
  THIRD_PARTY_ADMINISTRATION_FEE: 'thirdPartyAdministrationFee',
  OTHER_FEES: 'otherFees',
};

export const RxCardInfoFields = {
  RX_BIN_NUMBER: 'rxBinNumber',
  RX_PCN_NUMBER: 'rxPcnNumber',
  RX_GROUP_NUMBER: 'rxGroupNumber',
};

export const DeductibleOOPMaxLabels = [
  'Individual Deductible',
  'Family Deductible',
  'Individual OOP Max',
  'Family OOP Max',
];

export const MEDICAL_PLAN_TYPE_OPTIONS = [
  {
    value: 'HMO',
    label: 'HMO',
  },
  {
    value: 'PPO',
    label: 'PPO',
  },
  {
    value: 'EPO',
    label: 'EPO',
  },
  {
    value: 'POS',
    label: 'POS',
  },
];

export const DENTAL_PLAN_TYPE_OPTIONS = [
  {
    value: 'PPO',
    label: 'PPO',
  },
  {
    value: 'HMO',
    label: 'HMO',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

// TODO: remove after organizationId is available in the URL/Context
export const sampleOrgId = 'edb4be90-96dc-43a9-bb48-43b67869ab8f';
export const piedPiperId = '367bdb30-e42b-4269-95e3-3c95e236c3da';

export const coverageOption = {
  COVERED: 'COVERED',
  NOT_COVERED: 'NOT_COVERED',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};

export const coverageOptions = [
  { label: 'Covered', value: coverageOption.COVERED },
  { label: 'Not Covered', value: coverageOption.NOT_COVERED },
];

export const coverageOptionsCoveredOnly = [
  { label: 'Covered', value: coverageOption.COVERED },
];

export const defaultBenefitTypes = [
  {
    benefitKind: 'MEDICAL',
    code: 38,
    // description: 'Primary Care Visit to Treat and Injury or Illness',
    shortName: 'Preventive Care',
  },
  {
    benefitKind: 'MEDICAL',
    code: 1,
    description: 'Primary Care Visit to Treat and Injury or Illness',
    shortName: 'Primary Care Visit',
  },
  {
    benefitKind: 'MEDICAL',
    code: 2,
    // description: 'Primary Care Visit to Treat and Injury or Illness',
    shortName: 'Specialist Visit',
  },
  {
    benefitKind: 'MEDICAL',
    code: 106,
    shortName: 'Coinsurance',
  },
  {
    benefitKind: 'MEDICAL',
    code: 13,
    // description: 'Primary Care Visit to Treat and Injury or Illness',
    shortName: 'Urgent Care',
  },
  {
    benefitKind: 'MEDICAL',
    code: 16,
    // description: 'Primary Care Visit to Treat and Injury or Illness',
    shortName: 'Ambulance',
  },
  {
    benefitKind: 'MEDICAL',
    code: 15,
    // description: 'Primary Care Visit to Treat and Injury or Illness',
    shortName: 'Emergency Room',
  },
  {
    benefitKind: 'MEDICAL',
    code: 77,
    // description: 'Primary Care Visit to Treat and Injury or Illness',
    shortName: 'Lab & X-Ray',
  },
  {
    benefitKind: 'MEDICAL',
    code: 87,
    // description: 'Primary Care Visit to Treat and Injury or Illness',
    shortName: 'Inpatient',
  },
  {
    benefitKind: 'MEDICAL',
    code: 88,
    // description: 'Primary Care Visit to Treat and Injury or Illness',
    shortName: 'Outpatient',
  },
];

export const PlanApproach = {
  MANUAL: 'manual',
  SBC: 'sbc',
  NOT_SELECTED: 'none',
};

export const ServiceType = {
  BENEFIT: 'BENEFIT',
  RX: 'RX',
  MAILORDER: 'MAILORDER',
};

export const planRecommenderStatusErrorMessages = [
  {
    title: 'CONFLICTING_CONTRIBUTIONS',
    code: 1,
    message:
      'Plan Recommender cannot be enabled because the plan contains conflicting contributions among Benefit Groups.',
  },
  {
    title: 'ANY_MEDICAL_PLAN_WITH_N_TIER_OR_AGE_BAND',
    code: 2,
    message:
      'Plan Recommender cannot be enabled for a guide when there is a plan that has N-Tier or Age-Banded Rates.',
  },
  {
    title: 'MEDICAL_RATE_OR_CONTRIBUTIONS_DISABLED',
    code: 3,
    message:
      'Plan recommender cannot be enabled because contributions are disabled for this guide.',
  },
  {
    title: 'MISSING_MEDICAL_PLAN_INFORMATION',
    code: 4,
    message:
      'Plan Recommender cannot be enabled for a guide when there is missing plan information used in the calculations.',
  },
];

export const DBGStatus = [
  {
    title: 'PUBLISHED',
    code: 1,
    message: 'Published',
  },
  {
    title: 'DRAFT',
    code: 2,
    message: 'In Progress',
  },
  {
    title: 'ARCHIVED',
    code: 3,
    message: 'Archived',
  },
];

export const COST_TYPE = 'costType';
export const COST_SHARING_POLICY = 'costSharingPolicy';
export const COPAY = 'copay';
export const COPAY_FREQUENCY = 'copayFrequency';
export const COPAY_PRIOR_TO_DEDUCTIBLE = 'copayPriorToDeductible';
export const COINSURANCE = 'coinsurance';
export const COINSURANCE_AFTER_DEDUCTIBLE = 'coinsuranceAfterDeductible';
export const INFO = 'info';
export const MONTHLY_PREMIUM = 'MONTHLY_PREMIUM';
export const PLAN_ADDITIONAL_DOCUMENT = 'PLAN_ADDITIONAL_DOCUMENT';
export const SBC = 'SBC';
export const COC = 'COC';
export const PLAN_SUMMARY = 'PLAN_SUMMARY';
export const PLAN_DOCUMENT = 'PLAN_DOCUMENT';

export const RatesColumns = {
  TOTAL_EE_CONTRIBUTION: 'employeeCost',
  TOTAL_ER_CONTRIBUTION: 'employerCost',
  TOTAL_MONTHLY_COST: 'totalCost',
  BI_WEEKLY__COST: 'employeeBiWeeklyCost',
  SEMI_MONTHLY__COST: 'employeeSemiMonthlyCost',
};

export const AdditionalResourcesRadioTypes = {
  FILE: 'file',
  WEBLINK: 'webLink',
};

export const VALIDATION_NAME_DUPLICATED = 'validation.duplicate.exists';

export const AddLifePlanDocumentType = {
  PLAN_SUMMARY: { value: 'PLAN_SUMMARY', label: 'Plan Summary' },
  PORTABILITY_CONVERSION_FORM: {
    value: 'PORTABILITY_AND_CONVERSION',
    label: 'Portability & Conversion Form',
  },
  COC: { value: 'COC', label: 'COC File' },
};

export const ViewLifePlanDocumentType = {
  PLAN_SUMMARY: { value: 'PLAN_SUMMARY', label: 'Plan Summary' },
  PORTABILITY_AND_CONVERSION: {
    value: 'PORTABILITY_AND_CONVERSION',
    label: 'Portability & Conversion Form',
  },
  COC: { value: 'COC', label: 'COC File' },
};

export const RetirementPlanDocumentType = {
  PLAN_SUMMARY: { value: 'PLAN_DOCUMENT', label: 'Plan Document' },
  FOUR_O_ONE_K_ROLLOVER_FORM: {
    value: 'FOUR_O_ONE_K_ROLLOVER_FORM',
    label: '401(k) Rollover Form',
  },
};

export const BasicPlans: { [key: string]: { label: string; value: string } } = {
  BASIC_LIFE: { value: 'BASIC_LIFE', label: 'Basic Life' },
  BASIC_ADD: { value: 'BASIC_ADD', label: 'Basic AD&D' },
  BASIC_LIFE_AND_ADD: {
    value: 'BASIC_LIFE_AND_ADD',
    label: 'Basic Life w/AD&D',
  },
  STD: { value: 'STD', label: 'Basic Short-Term Disability' },
  LTD: { value: 'LTD', label: 'Basic Long-Term Disability' },
};

export const VoluntaryPlans: {
  [key: string]: { label: string; value: string };
} = {
  VOLUNTARY_LIFE: { value: 'VOLUNTARY_LIFE', label: 'Voluntary Life' },
  VOLUNTARY_ADD: { value: 'VOLUNTARY_ADD', label: 'Voluntary AD&D' },
  VOLUNTARY_LIFE_ADD: {
    value: 'VOLUNTARY_LIFE_ADD',
    label: 'Voluntary Life w/AD&D',
  },
  VOLUNTARY_STD: {
    value: 'VOLUNTARY_STD',
    label: 'Voluntary Short-Term Disability',
  },
  VOLUNTARY_LTD: {
    value: 'VOLUNTARY_LTD',
    label: 'Voluntary Long-Term Disability',
  },
};

export type TaxAdvantagedAccountBenefitKind =
  | 'HRA'
  | 'HEALTHCARE_FSA'
  | 'DEPENDENT_CARE_FSA'
  | 'LIMITED_PURPOSE_FSA'
  | 'HSA'
  | 'COMMUTER';

export const TaxAdvantagedAccountPlanType: {
  [key: string]: { value: string; label: string };
} = {
  HRA: { value: 'HRA', label: 'HRA' },
  HEALTHCARE_FSA: { value: 'FSA', label: 'Healthcare FSA' },
  DEPENDENT_CARE_FSA: {
    value: 'DCAP_FSA',
    label: 'Dependent Care FSA',
  },
  LIMITED_PURPOSE_FSA: {
    value: 'LIMITED_FSA',
    label: 'Limited Purpose FSA',
  },
  HSA: { value: 'HSA', label: 'HSA' },
  COMMUTER: { value: 'COMMUTER_GENERIC', label: 'Commuter' },
};

export const TaxAdvantagedAccountPlans = [
  TaxAdvantagedAccountPlanType.HRA,
  TaxAdvantagedAccountPlanType.HEALTHCARE_FSA,
  TaxAdvantagedAccountPlanType.DEPENDENT_CARE_FSA,
  TaxAdvantagedAccountPlanType.LIMITED_PURPOSE_FSA,
  TaxAdvantagedAccountPlanType.HSA,
  TaxAdvantagedAccountPlanType.COMMUTER,
];

export const benefitCode = {
  MEDICAL_PREVENTIVE_CARE: {
    code: 95,
    shortName: 'Preventive Care',
    description: 'Preventive Care',
    benefitKind: BenefitCategory.MEDICAL.value,
  },
  DENTAL_PREVENTIVE_CARE: {
    code: 38,
    shortName: 'Preventive Dental Care',
    description: 'Preventive Care/Screening/Immunization',
    benefitKind: BenefitCategory.DENTAL.value,
  },
};

type TooltipContent = {
  title: string;
  subtitle: string;
};

export const TOOLTIP_CONST: { [key: number]: TooltipContent } = {
  38: {
    title: 'Preventive Dental Care',
    subtitle: 'Includes services such as exams, X-rays, and cleanings.',
  },
  53: {
    title: 'Basic Dental Care',
    subtitle:
      'Includes services such as fillings, extractions, and root canals.',
  },
  55: {
    title: 'Major Dental Care',
    subtitle:
      'Includes extensive procedures such as crowns, bridges, and dentures.',
  },
};

export const BenefitTypesDropdownOptions = {
  FLAT_AMOUNT: { value: 'FLAT_AMOUNT', label: 'Flat Amount' },
  MULTIPLIER_OF_SALARY: {
    value: 'MULTIPLIER_OF_SALARY',
    label: 'Multiplier Salary',
  },
  MULTIPLIER_OF_SALARY_AND_FLAT_AMOUNT: {
    value: 'MULTIPLIER_OF_SALARY_AND_FLAT_AMOUNT',
    label: 'Multiplier Salary + Flat Amount',
  },
};

export const VoluntaryPlanConstants = {
  VOLUNTARY_PLANS: 'VOLUNTARY',
  VOLUNTARY_LIFE: 'VOLUNTARY_LIFE',
  VOLUNTARY_ADD: 'VOLUNTARY_ADD',
  VOLUNTARY_LIFE_AND_ADD: 'VOLUNTARY_LIFE_AND_ADD',
  VOLUNTARY_STD: 'VOLUNTARY_STD',
  VOLUNTARY_LTD: 'VOLUNTARY_LTD',
};

export const STDPlanDocumentType = {
  CLAIM_FORM_STD: { value: 'CLAIM_FORM_STD', label: 'STD Claim Form' },
  CLAIM_FORM_STD_LTD: {
    value: 'CLAIM_FORM_STD_LTD',
    label: 'STD to LTD Claim Form',
  },
};

export const LTDPlanDocumentType = {
  CLAIM_FORM_LTD: { value: 'CLAIM_FORM_LTD', label: 'LTD Claim Form' },
};

export const VoluntaryPlanDocumentType = {
  EOI: { value: 'EOI', label: 'EOI Form' },
};

export const EmployerContributionFrequency = [
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'QUARTERLY', label: 'Quarterly' },
  { value: 'ANNUALLY', label: 'Annual' },
];

export const EmployerContributionHSAFrequency = [
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'SEMI_MONTHLY', label: 'Semi-Monthly' },
  { value: 'BI_WEEKLY', label: 'Bi-Weekly' },
  { value: 'QUARTERLY', label: 'Quarterly' },
  { value: 'ANNUALLY', label: 'Annual' },
];

export const TaxAdvantagedMaxValues = {
  HRA: { maxEEValue: 5250, maxOtherValue: 10500 },
  HEALTHCARE_FSA: { maxValue: 500 },
  DEPENDENT_CARE_FSA: {
    maxValue: 4800,
  },
  LIMITED_PURPOSE_FSA: {
    maxValue: 500,
  },
  HSA: { maxEEValue: 3500, maxOtherValue: 7000 },
  COMMUTER: { maxValue: 260 },
};

export const TaxAdvantageFields = {
  EMPLOYER_EE_CONTRIBUTION: 'employerEEContribution',
  EMPLOYER_ES_CONTRIBUTION: 'employerESContribution',
  EMPLOYER_EC_CONTRIBUTION: 'employerECContribution',
  EMPLOYER_EF_CONTRIBUTION: 'employerEFContribution',
  EMPLOYER_CONTRIBUTION: 'employerContribution',
  EMPLOYER_MONTHLY_CONTRIBUTION: 'employerMonthlyContribution',
};

export const TaxAdvantageCarrierTypes = {
  COMMUTER: 'COMMUTER',
  SAVING: 'SAVING',
};

export const BenefitGuideStatus = [
  { value: 'DRAFT', label: 'In Progress' },
  { value: 'PUBLISHED', label: 'Active' },
  { value: 'ARCHIVED', label: 'Archived' },
];

export const EditMdvTypes = {
  BASIC_INFO: { value: 'BASIC_INFO', title: 'Edit Basic Info', width: 565 },
  RATES: { value: 'RATES', title: 'Edit Premiums & Contributions', width: 565 },
  DEDUCTIBLES_OOP_MAX: {
    value: 'DEDUCTIBLES_OOP_MAX',
    title: 'Edit Deductibles & OOP Max',
    width: 565,
  },
  DEDUCTIBLES_AND_MAXIMUMS: {
    value: 'DEDUCTIBLES_OOP_MAX',
    title: 'Edit Deductibles & Maximums',
    width: 565,
  },
  SERVICES: { value: 'SERVICES', title: 'Edit Services', width: 565 },
  RX: { value: 'RX', title: 'Edit RX', width: 700 },
  RX_CARD_INFO: {
    value: 'RX_CARD_INFO',
    title: 'Edit ID Card Information',
    width: 550,
  },
  ENROLLMENTS: { value: 'ENROLLMENTS', title: 'Edit Enrollments', width: 552 },
};

export const MDV_EDIT_DOCUMENT_TYPE = {
  SBC: {
    value: 'SBC',
    label: 'SBC',
  },
  PLAN_SUMMARY: {
    value: 'PLAN_SUMMARY',
    label: 'Plan Summary',
  },
};

export const VALIDATION_FAILED = 'validation.failed';
export const BENGUIDE_PLAN_YEAR_MISMATCH =
  'benguide.plan.year.mismatch.plans.plan.year';
export const BENGUIDE_BENEFIT_CLASS_MISMATCH =
  'benguide.benefit.class.mismatch.plans.benefit.classes';

export const CARRIER_OPTIONAL_PLAN_TYPES = [
  'WELLBEING',
  'FAMILY_HOLIDAY_TIME_OFF',
  'ADDITIONAL_PERKS',
];

export const PLAN_DELETE_FAILED_HSA_HRA_DEPENDENCIES = 'hsa.hra.dependant.plan';

export const FUNDING_TYPES_LIST = [
  { value: 'FULLY_INSURED', label: 'Fully-Insured' },
  { value: 'SELF_FUNDED', label: 'Self-Funded' },
  { value: 'LEVEL_FUNDED', label: 'Level-Funded' },
];

export const STANDARD_TIER_COUNT = 4;

export const SBC_OPS_REVIEW_MODAL_TITLE = 'Finalize AI Document Upload Review';

export const SBC_OPS_REVIEW_MODAL_WARNING = `Finalizing this document review will send a notification to the
broker admin that this file is ready to review.`;

// AI feedback Constants
export const AI_FEEDBACK_MODAL_DESCRIPTION =
  'If you didn’t receive expected results from your AI document upload, you can leave us feedback to help improve future results.';
export const AI_FEEDBACK_MODAL_TITLE = 'Rate AI Data Output';
export const AI_FEEDBACK_RATE_AI_TEXT = 'Rate AI Data Output';
export const AI_FEEDBACK_THUMBS_UP_TEXT = 'Thanks for your feedback!';
export const AI_FEEDBACK_TEXT_FEEDBACK_TEXT =
  'Would you like to leave more feedback?';

export const POSITIVE_FEEDBACK = 'Positive Feedback';
export const NEGETIVE_FEEDBACK = 'Negative Feedback';

export const MDV_FEEDBACK_PANEL_TYPES = {
  deductiblesOopMax: 'Deductibles & OOP Max',
  rx: 'RX',
  services: 'Services',
};

export const LIFE_FEEDBACK_PANEL_TYPES = {
  planInformation: 'Plan Information',
};

export const PANEL_HEADINGS_LONG: Record<PanelSection, string> = {
  basicPlanInfo: 'Basic Plan Info *',
  deductiblesOopMax: 'Deductibles & OOP Max',
  enrollments: 'Enrollments',
  idCardInfo: 'ID Card Information',
  planDocuments: 'Plan Resources',
  rates: 'Premiums & Contributions',
  services: 'Services',
  rx: 'RX',
  planInformation: 'Plan Information',
  additionalResources: 'Plan Resources',
};

export const PANEL_HEADINGS_SHORT: Partial<
  Record<keyof typeof PanelSection, string>
> = {
  DEDUCTIBLES_OOP_MAX: 'Deductibles',
  SERVICES: 'Services',
  RX: 'RX',
  PLAN_INFORMATION: 'Plan Information',
} as const;

export const AI_SBC_ERROR_MESSAGES = {
  'invalid.sbc.document':
    'The document you uploaded does not appear to be a Medical SBC/Plan Summary. Please verify your document and upload the correct Medical SBC/Plan Summary.',
  'scanned.image.detected':
    "Error: We've noticed that you've uploaded a scanned image or a PDF containing an image. Unfortunately, our system is unable to process scanned documents. If you need, please let us know. We can update the plan within 24 hours.",
  'non.sbc.detected':
    'Error: It seems like you have uploaded a file in a format other than the required SBC format. Please ensure that you are using a SBC file and try uploading again.',
  'too.large':
    'Error: It seems like the file you have uploaded is too large. Please ensure that the file is less than 100 MB.',
  'incorrect.format':
    'The file you uploaded is not in PDF format. Currently, we only support PDF files. Please upload your document as a PDF.',
  'invalid.benefit.summary.document':
    'Error: It seems like you have uploaded a file in a format other than the required Benefit Summary format. Please ensure that you are using a Benefit Summary file and try uploading again.',
  'invalid.basic.life.summary.document':
    'The document you uploaded does not appear to be a Basic Life Benefit Summary. Please verify your document and upload the correct Basic Life Benefit Summary.',
  'invalid.basic.add.summary.document':
    'The document you uploaded does not appear to be a Basic AD&D Benefit Summary. Please verify your document and upload the correct Basic AD&D Benefit Summary.',
  'invalid.basic.life.add.summary.document':
    'The document you uploaded does not appear to be a Basic Life & AD&D Benefit Summary. Please verify your document and upload the correct Basic Life & AD&D Benefit Summary.',
  'invalid.std.summary.document':
    'The document you uploaded does not appear to be an STD Benefit Summary. Please verify your document and upload the correct STD Benefit Summary.',
  'invalid.ltd.summary.document':
    'The document you uploaded does not appear to be an LTD Benefit Summary. Please verify your document and upload the correct LTD Benefit Summary.',
  'invalid.dental.summary.document':
    'The document you uploaded does not appear to be a Dental Benefit Summary. Please verify your document and upload the correct Dental Benefit Summary.',
  'invalid.vision.summary.document':
    'The document you uploaded does not appear to be a Vision Benefit Summary. Please verify your document and upload the correct Vision Benefit Summary.',
  default:
    "Error: We're sorry, but it seems there was an error with the system while processing your file. Please try re-uploading your file.",
} as const;

export const AI_SBC_SUPPORT_APPLICABLE_ERROR_KEYS: readonly string[] = [
  'invalid.sbc.document',
  'invalid.benefit.summary.document',
  'invalid.basic.life.summary.document',
  'invalid.basic.add.summary.document',
  'invalid.basic.life.add.summary.document',
  'invalid.std.summary.document',
  'invalid.ltd.summary.document',
  'invalid.dental.summary.document',
  'invalid.vision.summary.document',
  'default',
] as const;

export const AI_FILE_TYPE = {
  SBC: 'SBC',
  BENEFIT_SUMMARY: 'BENEFIT_SUMMARY',
  PLAN_SUMMARY: 'PLAN_SUMMARY',
} as const;

export const AI_PLAN_HISTORY_DELETE_MESSAGE = ` If this file hasn't been reviewed or currently in progress, the
          associated plan will not be saved into the system. Are you sure you
          want to delete this file?`;

export const SBC_MEDICAL_CANCELED_MESSAGE = `An SBC/Plan Summary file has not been uploaded for this plan. 
Enter the plan information to the right manually or you may still `;

export const BENEFIT_SUMMARY_CANCELED_MESSAGE = `A Benefit Summary file has not been uploaded. 
Enter the plan information to the right manually or you may still `;

export const QUOTE_UPLOAD_FILE_SIZE_WARNING = `The file you attempted to upload exceeds the maximum allowed size of 100 MB. 
To resolve this issue, confirm that you are uploading the correct file or reduce the file size and try again.`;

export const QUOTE_UPLOAD__FORMAT_ERROR_WARNING = `This file type is not supported. Please upload a file in one of the following formats: PDF, DOC, DOCX, XLS, XLSM, or XLSX.`;

export const SBC_SUCCESFULLY_PROCESSED_MESSAGE =
  "SBC file processed successfully! Please review the 'Ready for Review' sections, make necessary edits, and approve the final plan.";

export const EXTRACTION_SUCCESS_MESSAGE = `File processed successfully! Please review the 'Ready for Review' sections, make the necessary edits, and approve the final plan.`;

export const SBC_PROCESSING_POP_UP = `This file is undergoing additional 
processing, please check back later`;

export const REJECT_FILE_MESSAGE =
  'Rejecting this file will send a rejection notification to the broker admin who originally uploaded the document. You can choose between a templated response below or write a custom message that will be sent to the broker.';

export const REJECT_INCORRECT_BENEFIT = 'Reject File: Incorrect Benefit Type';

export const REJECT_IMAGE_READABLE = 'Reject File: File is Not Readable';

export const REJECT_CUSTOM_MESSAGE = 'Reject File: Custom Message';

export const REJECT_INCORRECT_BENEFIT_MESSAGE =
  'The file you uploaded is not compatible with the selected benefit type. To ensure accurate processing, please verify that you are uploading the appropriate benefit file using the designated benefit uploader. Double-check that the file content aligns with the specific benefit type before attempting to upload again.';

export const REJECT_IMAGE_READABLE_MESSAGE =
  'The text in the file you uploaded is not readable. To ensure accurate processing, please verify that you are uploading a file where the text is clear and legible. Double-check that the text is properly formatted and free from distortions or obstructions before attempting to upload again.';

export const SBC_ADDITIONAL_PROCESSING_WARNING_MESSAGE = `This document requires additional processing, expected to take up to one business day to complete. We advise waiting for processing completion. You'll receive a notification when the processing is finished. Alternatively, you can continue without uploading the document and manually input the values.`;

export const QUOTE_SELF_FEATURE_KEY = 'QUOTE_SELF_REVIEW';

export const lifeServiceConstants = {
  ACCELERATED_LIFE_BENEFIT: 'ACCELERATED_LIFE_BENEFIT',
  PORTABILITY: 'PORTABILITY',
  WAIVER_OF_PREMIUM: 'WAIVER_OF_PREMIUM',
  DEFINITION_OF_EARNINGS: 'DEFINITION_OF_EARNINGS',
};

const lifeServices = [
  lifeServiceConstants.ACCELERATED_LIFE_BENEFIT,
  lifeServiceConstants.PORTABILITY,
  lifeServiceConstants.WAIVER_OF_PREMIUM,
  lifeServiceConstants.DEFINITION_OF_EARNINGS,
];
export const NON_MDV_ADDITIONAL_SERVICES: Record<string, string[]> = {
  BASIC_LIFE: lifeServices,
  BASIC_ADD: lifeServices,
  BASIC_LIFE_AND_ADD: lifeServices,
  STD: [
    'DEFINITION_OF_EARNINGS',
    'WAITING_PERIOD_INJURY',
    'WAITING_PERIOD_ILLNESS',
    'SOCIAL_SECURITY_INTEGRATION',
    'W2_PREPARATION',
    'FICA_MATCH',
    'PORTABILITY',
    'RECURRENT_DISABILITY_PROVISION',
    'CONTINUITY_OF_COVERAGE',
    'CONVERTIBILITY',
  ],
  LTD: [
    'MIN_MONTHLY_BENEFIT',
    'DEFINITION_OF_EARNINGS',
    'PRE_EXISTING_CONDITIONS',
    'SOCIAL_SECURITY_INTEGRATION',
    'STATE_INTEGRATION',
    'FICA_MATCH',
    'REHABILITATION_BENEFIT',
    'WORKSITE_MODIFICATION_BENEFIT',
    'RECURRENT_DISABILITY_PROVISION',
    'RETURN_TO_WORK',
    'WAIVER_OF_PREMIUM',
    'CONTINUITY_OF_COVERAGE',
    'EARNINGS_TEST',
    'DISABILITY_LIMITATIONS_MENTAL_HEALTH',
    'DISABILITY_LIMITATIONS_SUBSTANCE_ABUSE',
    'DISABILITY_LIMITATIONS_SELF_REPORTED',
    'EAP_OFFERED',
  ],
};

export const DENTAL_ADVANCED_PROFILE_PARAMETERS = [
  'Waiting Period',
  'Endodontic Care',
  'Periodontic Care',
  'Implants',
];

export const VISION_ADVANCED_PROFILE_PARAMETERS = ['Contact Lenses Frequency'];

export const getUpdatePlanParametersWarning = (
  planType: string,
  source?: DocumentExtractionSource | undefined
): string => {
  const isAnOfferedPlan = source === DocumentExtractionSource.UPDATE_QUOTE_PLAN;
  const isMedical = planType === BenefitCategory.MEDICAL.value.toLowerCase();
  return `You can update this ${planType} plan ${
    isAnOfferedPlan ? 'offer' : ''
  } by uploading a new ${
    isMedical ? 'SBC/Plan Summary' : 'Benefit Summary'
  }. This will overwrite any existing plan parameter data for this plan.`;
};

export const NO_NAME = 'NO_NAME';
export const FILE = 'FILE';
export const WEBLINK = 'WEBLINK';

export const ALL_DOCUMENT_TYPE = [
  'SBC',
  'COC',
  'PLAN_SUMMARY',
  'PLAN_DOCUMENT',
  'PORTABILITY_AND_CONVERSION',
  'CLAIM_FORM_STD',
  'CLAIM_FORM_STD_LTD',
  'CLAIM_FORM_LTD_STD',
  'CLAIM_FORM_LTD',
  'EOI',
  'BIKING_PLAN',
  'BIKING_SUMMARY_PLAN',
  'PARKING_PLAN',
  'PARKING_SUMMARY_PLAN',
  'TRANSIT_PLAN',
  'TRANSIT_SUMMARY_PLAN',
  'FOUR_O_ONE_K_ROLLOVER_FORM',
];
export const RETIREMENT_401K = 'RETIREMENT_401K';
