import { lumityApi } from 'api/apiSlice';
import SoldConfirmationTypes from 'model/SoldConfirmation';

const soldConfirmationRTKSlice = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getSoldConfirmationCarrierList: builder.query<any, { proposalId: string }>({
      query: ({ proposalId }) => {
        return {
          url: `v1/renewals/offer-proposals/sold-confirmations`,
          method: 'GET',
          params: {
            proposal_id: proposalId,
          },
        };
      },
    }),
    getSoldConfirmationDetail: builder.query<
      any,
      { soldConfirmationId: string }
    >({
      query: ({ soldConfirmationId }) => {
        return {
          url: `v1/renewals/offer-proposals/sold-confirmations/${soldConfirmationId}`,
          method: 'GET',
        };
      },
    }),
    getIsSoldConfirmationComplete: builder.query<any, { proposalId: string }>({
      query: ({ proposalId }) => {
        return {
          url: `v1/renewals/offer-proposals/sold-confirmations/completed`,
          method: 'GET',
          params: {
            proposal_id: proposalId,
          },
        };
      },
    }),
    sendSoldConfirmationMail: builder.mutation<any, SoldConfirmationTypes>({
      query: (args) => {
        const {
          soldConfirmationId,
          soldConfirmationSubject,
          soldConfirmationBody,
          soldConfirmationTo,
          soldConfirmationCC,
          files,
        } = args;
        const formData = new FormData();
        formData.append('soldConfirmationId', soldConfirmationId);
        formData.append('soldConfirmationSubject', soldConfirmationSubject);
        formData.append('soldConfirmationBody', soldConfirmationBody);
        formData.append('soldConfirmationTo', soldConfirmationTo);
        formData.append('soldConfirmationCC', soldConfirmationCC);
        files.forEach((file: any) => {
          formData.append('files', file);
        });
        return {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          url: `v1/renewals/offer-proposals/sold-confirmations/send-email`,
          method: 'POST',
          data: formData,
        };
      },
    }),
    getIndividualById: builder.query<any, { individualId: string }>({
      query: (args) => {
        const { individualId } = args;
        return {
          url: `v2/individuals/${individualId}`,
          method: 'GET',
        };
      },
    }),
    sendSoldConfirmationMailForEmployer: builder.mutation<
      any,
      SoldConfirmationTypes
    >({
      query: (args) => {
        const {
          soldConfirmationId,
          soldConfirmationSubject,
          soldConfirmationBody,
          soldConfirmationTo,
          soldConfirmationCC,
          files,
          proposalId,
        } = args;
        const formData = new FormData();
        formData.append('soldConfirmationId', soldConfirmationId);
        formData.append('soldConfirmationSubject', soldConfirmationSubject);
        formData.append('soldConfirmationBody', soldConfirmationBody);
        formData.append('soldConfirmationTo', soldConfirmationTo);
        formData.append('soldConfirmationCC', soldConfirmationCC);
        formData.append('proposalId', proposalId ?? '');
        files.forEach((file: any) => {
          formData.append('files', file);
        });
        return {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          url: `v1/renewals/offer-proposals/sold-confirmations/send-email/employer`,
          method: 'POST',
          data: formData,
        };
      },
    }),
  }),
});

export const {
  useLazyGetSoldConfirmationCarrierListQuery,
  useLazyGetSoldConfirmationDetailQuery,
  useLazyGetIsSoldConfirmationCompleteQuery,
  useLazyGetIndividualByIdQuery,
  useSendSoldConfirmationMailMutation,
  useSendSoldConfirmationMailForEmployerMutation,
} = soldConfirmationRTKSlice;
