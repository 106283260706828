import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FormInstance, notification } from 'antd';

import { isEmpty } from 'lodash';
import isEqual from 'lodash/isEqual';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import AlertMessage from 'components/Alert/AlertMessage';
import FixedAlertMessage, {
  AlertInfo,
} from 'components/Alert/FixedAlert/FixedAlertMessage';
import FourOOneKAdditionalResources from 'modules/plans/retirement/components/AdditionalResources/FourOOneKAdditionalResources';
import PlanBasicForm from 'modules/plans/components/PlanBasicForm/PlanBasicForm';
import PlanInformationForm from 'modules/plans/retirement/components/PlanInformation/PlanInformationForm';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import {
  BenefitCategory,
  benefitGroupsChangedMsg,
  BenefitKind,
  EMPTY_MESSAGE,
  formVerificationMsg,
  planAlreadyExistsError,
  planYearChangedMsg,
} from 'constants/commonConstants';
import { getCarriersByBenefitKind } from 'modules/plans/slices/basicPlanInfoSlice';
import {
  clearDocumentsAttached,
  clearRetirementPlanApiErrors,
  getPlanByIdCompleted,
  findPlanById,
  saveRetirementPlan,
} from 'modules/plans/slices/retirementPlanSlice';
import { FourOOneKPlan } from 'model/plans/FourOOneKPlan';

import {
  PLAN_ADDITIONAL_DOCUMENT,
  VALIDATION_NAME_DUPLICATED,
} from 'modules/plans/constants';
import { getValidationMessage } from 'util/commonUtil';
import {
  ERROR_MESSAGE_PLAN_EDITING,
  PLAN_NOTIFICATION_KEY,
} from 'constants/benguideCollaborationConstants';
import BenefitCarrier from 'model/BenefitCarrier';

import FileType from 'model/plans/FileType';
import WebLinkType from 'model/plans/WebLinkType';
import AdditionalPlanResources from 'modules/plans/components/AdditionalPlanResources/AdditionalPlanResources';
import {
  removePlanDocuments,
  updateWeblinks,
  uploadPlanDocument,
  uploadRemovePlanDocument,
} from 'modules/plans/slices/planDocumentsSlice';
import styles from './addFourOOneKPlan.module.less';

export const RetirementPlanBasicInfoFormFields = {
  requiredFields: ['planName', 'planYear', 'benefitClass', 'benefitCarrier'],
  formFields: [
    'planName',
    'planYear',
    'benefitCarrier',
    'benefitClass',
    'annualMaxContribution',
    'annualCatchupContribution',
    'details',
    'groupId',
  ],
};
type Add401kPlanProps = {
  onSave: () => void;
  onCancel: () => void;
  isCloseConfirmed: boolean;
  form: FormInstance;
  isPlanUpdateMode?: boolean;
  plan?: any;
  dbgPlanYear?: string;
  isDBGPlan?: boolean;
  isModalVisible?: boolean;
  isSaveDisabled?: boolean;
};

const Add401kPlan = forwardRef((props: Add401kPlanProps, ref) => {
  const {
    isCloseConfirmed,
    onSave,
    onCancel,
    form,
    isPlanUpdateMode,
    plan,
    dbgPlanYear,
    isDBGPlan,
    isModalVisible,
    isSaveDisabled = false,
  } = props;
  type formDataType = {
    planName: string;
    planYear: string;
    benefitCarrier: string | BenefitCarrier;
    benefitClass: string[];
    annualMaxContribution?: number;
    annualCatchupContribution?: number;
    details: string;
    documents: { [key: string]: string };
    groupId: string;
  };

  const add401kPlanDefaultValues: formDataType = {
    planName: '',
    benefitCarrier: '',
    planYear: '',
    benefitClass: [],
    details: '',
    documents: {},
    groupId: '',
  };
  const dispatch = useAppDispatch();
  const { employerId, brokerId } = useNavContext();
  const { planYearsList } = useAppSelector((state) => state.employer.employer);
  const basicPlanInfo = useAppSelector((state) => state.plan.planBasicInfo);
  const { error, documentReferences } = useAppSelector(
    (state) => state.plan.retirementPlan
  );
  const { carriersList } = basicPlanInfo;

  const [requiredFieldError, setRequiredFieldError] = useState<boolean>(false);

  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [selectedCarrier, setSelectedCarrier] = useState<
    BenefitCarrier | undefined
  >();
  const [formData, setFormData] = useState<formDataType>({
    ...add401kPlanDefaultValues,
  });
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const { inProgress } = useAppSelector((state) => state.plan.retirementPlan);
  const { inProgress: documentUploadInProgress } = useAppSelector(
    (state) => state.plan.planDocuments
  );
  const documentsRef = useRef<any>();
  const basicPlanInfoRef = useRef<any>();
  const [notificationKey] = useState(PLAN_NOTIFICATION_KEY);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPlanYearWarning, setShowPlanYearWarning] =
    useState<boolean>(false);

  const [selectedFileList, setSelectedFileList] = useState<FileType[]>([]);
  const [isDocRemoved, setIsDocRemoved] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedWeblink, setSelectedWeblink] = useState<WebLinkType[]>([]);

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedFileList([]);
      setSelectedWeblink([]);
      form.resetFields();
    },
  }));

  useEffect(() => {
    if (plan && isPlanUpdateMode) {
      const {
        name,
        groups,
        planYearId,
        benefitCarrier,
        annualMaxContribution,
        annualCatchupContribution,
        details,
        documents,
        groupId,
      } = plan;

      const { id } = benefitCarrier || {};

      const newFormData = {
        planName: name,
        benefitCarrier: id || '',
        planYear: planYearId,
        benefitClass: groups,
        annualMaxContribution: annualMaxContribution,
        annualCatchupContribution: annualCatchupContribution,
        details: details,
        documents: documents,
        groupId: groupId,
      };
      form.setFieldsValue({ ...newFormData });
      setFormData(newFormData);
      dispatch(getPlanByIdCompleted(plan));
    }
  }, [dispatch, isPlanUpdateMode, form, plan]);

  useEffect(() => {
    if (isPlanUpdateMode) {
      form.setFieldsValue({ ...formData });
    }
  }, [formData, isPlanUpdateMode, form]);

  useEffect(() => {
    if (brokerId && employerId && isModalVisible) {
      dispatch(
        getCarriersByBenefitKind(
          BenefitCategory.RETIREMENT.value,
          brokerId,
          employerId
        )
      );
    }
  }, [dispatch, brokerId, employerId, isModalVisible]);

  useEffect(() => {
    if (formData.benefitCarrier) {
      setSelectedCarrier(
        carriersList.find((t) => t.id === formData.benefitCarrier)
      );
    }
  }, [carriersList, formData.benefitCarrier]);

  useEffect(() => {
    if (isCloseConfirmed) {
      setRequiredFieldError(false);
      setShowWarning(false);
      setShowPlanYearWarning(false);
      basicPlanInfoRef.current?.resetForm();
      documentsRef.current?.resetAll();
    }
  }, [isCloseConfirmed, form, setRequiredFieldError]);

  useEffect(() => {
    if (error?.data?.code === VALIDATION_NAME_DUPLICATED) {
      form.setFields([{ name: 'planName', errors: [EMPTY_MESSAGE] }]);

      setAlertMessage({
        type: 'error',
        message: planAlreadyExistsError,
      });
      setRequiredFieldError(true);
      dispatch(clearRetirementPlanApiErrors());
      setLoading(false);
    }
  }, [dispatch, error, form, isPlanUpdateMode]);

  useEffect(() => {
    if (plan) {
      const {
        name,
        groups,
        planYearId,
        benefitCarrier,
        details,
        annualMaxContribution,
        annualCatchupContribution,
        documents,
        groupId,
      } = plan;
      const { id } = benefitCarrier || {};
      const formData = {
        planName: name,
        benefitClass: groups,
        planYear: planYearId,
        benefitCarrier: id ? id : null,
        details: details,
        annualMaxContribution: annualMaxContribution,
        annualCatchupContribution: annualCatchupContribution,
        documents: documents,
        groupId: groupId,
      };
      form.setFieldsValue(formData);
      setFormData(formData);
    }
  }, [setFormData, form, plan]);

  useEffect(() => {
    setRequiredFieldError(false);
    setShowWarning(false);
    setShowPlanYearWarning(false);
    if (isPlanUpdateMode && !isEqual(plan?.groups, formData.benefitClass)) {
      setShowPlanYearWarning(false);
      setShowWarning(true);
      setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
      setRequiredFieldError(true);
    }
    if (
      isPlanUpdateMode &&
      !isEqual(plan?.planYearId, formData.planYear?.trim())
    ) {
      setShowPlanYearWarning(true);
      setAlertMessage({ message: planYearChangedMsg, type: 'warning' });
      setRequiredFieldError(true);
    }
    if (
      isPlanUpdateMode &&
      !isEqual(plan?.planYearId, formData.planYear?.trim()) &&
      !isEqual(plan?.groups, formData.benefitClass)
    ) {
      setShowPlanYearWarning(true);
      setShowWarning(true);
      setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
      setRequiredFieldError(true);
    }
  }, [plan, formData.benefitClass, isPlanUpdateMode, formData.planYear]);

  const handleFileRemove = (docType: any) => {
    const clonedDocuments = { ...formData.documents };
    delete clonedDocuments[docType];
    setFormData((prevData) => ({ ...prevData, documents: clonedDocuments }));
  };

  const validateBasicInfo = async () => {
    try {
      await form.validateFields(
        RetirementPlanBasicInfoFormFields.requiredFields
      );
      return true;
    } catch (errorInfo: any) {
      return errorInfo.errorFields.length === 0;
    }
  };

  const getValidationResult = async () => {
    const isFormValid = await validateBasicInfo();
    if (isFormValid) {
      return true;
    } else {
      setRequiredFieldError(true);
      return false;
    }
  };

  const onClickSave = async () => {
    setRequiredFieldError(false);
    setShowWarning(false);
    setShowPlanYearWarning(false);
    const isFormValid = await getValidationResult();

    const benefitCategory = BenefitCategory.RETIREMENT.value;
    const benefitKind = BenefitKind.RETIREMENT_401K.value;
    const selectedPlanYear = planYearsList.find(
      (t) => t.id === formData.planYear
    );
    const carrierId = formData.benefitCarrier;
    const formValues = form.getFieldsValue();
    const {
      benefitClass,
      planName,
      planYear,
      annualMaxContribution,
      annualCatchupContribution,
      details,
      groupId,
    } = formValues;

    const newPlan = {
      benefitKind: benefitKind,
      employerId: employerId,
      planYearId: planYear,
      name: planName ? planName.trim() : planName,
      benefitCarrier: {
        id: carrierId ? selectedCarrier?.id : null,
        name: carrierId ? selectedCarrier?.name : null,
        benefitCategory: benefitCategory,
      },
      groups: benefitClass,
      startDate: selectedPlanYear?.startDate,
      endDate: selectedPlanYear?.endDate,
      annualMaxContribution: annualMaxContribution,
      annualCatchupContribution: annualCatchupContribution,
      details: details,
      documentReferences: documentReferences,
      documents: formData.documents,
      groupId: groupId,
    } as FourOOneKPlan;

    if (isEmpty(newPlan?.documentReferences)) {
      delete newPlan.documentReferences;
    }
    if (isPlanUpdateMode) {
      newPlan.id = plan.id;
    }

    if (isFormValid) {
      if (isPlanUpdateMode && isDBGPlan) {
        setLoading(true);
      }
      await dispatch(
        saveRetirementPlan(newPlan, (id: string) => {
          onSaveClose(id);
          dispatch(clearDocumentsAttached());
        })
      );
      notification.close(notificationKey);
    } else {
      setAlertMessage({
        type: 'error',
        message: formVerificationMsg,
      });
      setRequiredFieldError(true);
    }
  };

  const onValidateFails = (validateSetting: string) => {
    setAlertMessage({
      type: 'error',
      message: getValidationMessage(validateSetting),
    });
    setRequiredFieldError(true);
  };

  const onSaveClose = async (id: string) => {
    await uploadDocument(id);
  };

  const uploadDocument = async (id: string) => {
    setSelectedFileList([]);
    setSelectedWeblink([]);
    const uploadDocuementSuccess = () => {
      onSave();
      if (plan && plan.id) {
        dispatch(findPlanById(plan.id));
      }
    };

    const planId = id ?? plan.id;
    const removeDocs = Object.keys(isDocRemoved)
      .filter((documentType) => isDocRemoved[documentType])
      .map((documentType) => ({
        docType: PLAN_ADDITIONAL_DOCUMENT,
        planDocumentName: documentType,
      }));
    if (!isEmpty(selectedFileList) && isEmpty(removeDocs)) {
      await dispatch(
        uploadPlanDocument(
          selectedFileList,
          planId,
          BenefitKind.RETIREMENT_401K.value
        )
      );
    } else if (!isEmpty(removeDocs) && isEmpty(selectedFileList)) {
      await dispatch(
        removePlanDocuments(
          planId,
          BenefitKind.RETIREMENT_401K.value,
          removeDocs
        )
      );
    } else if (!isEmpty(removeDocs) && !isEmpty(selectedFileList)) {
      await dispatch(
        uploadRemovePlanDocument(
          selectedFileList,
          planId,
          BenefitKind.RETIREMENT_401K.value,
          removeDocs
        )
      );
    }
    if (selectedWeblink.length > 0) {
      await dispatch(
        updateWeblinks(
          selectedWeblink,
          planId,
          BenefitKind.RETIREMENT_401K.value
        )
      );
    }
    uploadDocuementSuccess();
  };

  return (
    <div
      className={`${
        isPlanUpdateMode ? styles.planEditModeWrapper : styles.wrapperForm
      }  `}
    >
      {requiredFieldError && (
        <AlertMessage
          type={alertMessage.type}
          message={alertMessage.message}
          closeAlert={() => {
            setRequiredFieldError(false);
          }}
          className={
            isPlanUpdateMode ? styles.alertWrapper : styles.alertMessageWrapper
          }
        />
      )}
      {isSaveDisabled && !plan && (
        <FixedAlertMessage
          type={'error'}
          message={ERROR_MESSAGE_PLAN_EDITING}
        />
      )}
      <PlanBasicForm
        benefitCategory={BenefitCategory.RETIREMENT.value}
        carriersList={carriersList}
        setRequiredFieldError={setRequiredFieldError}
        setFormData={setFormData}
        formData={formData}
        form={form}
        ref={basicPlanInfoRef}
        isPlanUpdateMode={isPlanUpdateMode}
        showWarning={showWarning}
        dbgPlanYear={dbgPlanYear}
        isDBGPlan={isDBGPlan}
        isModalVisible={isModalVisible}
        showPlanYearWarning={showPlanYearWarning}
        defaultPlanName="401(k)"
      />
      <PlanInformationForm
        isCloseConfirmed={isCloseConfirmed}
        setRequiredFieldError={setRequiredFieldError}
        setFormData={setFormData}
        formData={formData}
        form={form}
        isEditMode={isPlanUpdateMode}
      />

      <FourOOneKAdditionalResources
        documentsRef={documentsRef}
        isEditMode={isPlanUpdateMode}
        removeUploadedDocument={handleFileRemove}
        documents={formData.documents}
        planId={plan?.id}
        onValidateFails={onValidateFails}
        disableFileUpload={isSaveDisabled}
      />
      <AdditionalPlanResources
        ref={ref}
        plan={plan}
        benefitKind={BenefitKind.RETIREMENT_401K.value}
        isCloseConfirmed={isCloseConfirmed}
        selectedFileList={selectedFileList}
        setSelectedFileList={setSelectedFileList}
        selectedWebLinkList={selectedWeblink}
        setSelectedWebLinkList={setSelectedWeblink}
        setIsDocRemoved={setIsDocRemoved}
      />

      <div className={styles.btnWrapper}>
        <SubmitButton
          disabled={inProgress || isSaveDisabled}
          type="primary"
          className={
            isPlanUpdateMode ? styles.saveButtonWrapper : styles.saveButton
          }
          onClick={onClickSave}
          loading={loading || documentUploadInProgress}
        >
          {isPlanUpdateMode ? 'Done' : 'Save'}
        </SubmitButton>

        {isPlanUpdateMode ? (
          <CancelButton
            onClick={() => {
              setSelectedFileList([]);
              setSelectedWeblink([]);
              onCancel();
            }}
            className={styles.cancelButtonWrapper}
            withBorder={true}
          >
            Cancel
          </CancelButton>
        ) : (
          <CancelButton>
            <span onClick={onCancel} className={styles.cancelButton}>
              Cancel
            </span>
          </CancelButton>
        )}
      </div>
    </div>
  );
});
Add401kPlan.displayName = 'Add401kPlan';
export default Add401kPlan;
