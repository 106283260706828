import { useEffect, useState } from 'react';
import DBGClient from 'modules/clients/DBGClient/DBGClient';
import AddLifePlanModal from 'modules/plans/life/components/AddLifePlanModal/AddLifePlanModal';
import Option from 'model/Option';
import {
  LIFE_AND_DISABILITY_CHANNEL,
  OPEN_ADD_NEW_PLAN_MODAL,
  OPEN_LIFE_AND_DISABILITY_PLAN_EDIT_MODAL,
  RELOAD_LIFE_AND_DISABILITY_PLAN,
  RELOAD_LIFE_AND_DISABILITY_PLAN_LIST,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  EXIT_BENGUIDE,
  BENGUIDE_SAVE_COMPLETED,
  CLOSE_MODALS,
} from 'modules/clients/DBGClient/DBGClientConts';
import useDBGClient from 'modules/clients/DBGClient/useDBGClient';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import { LifePlan } from 'model/plans/LifePlan';
import EditLifePlan from 'modules/plans/life/components/EditLifePlan/EditLifePlan';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { changedBenguideModalState } from 'modules/benefitGuide/slices/benguideSlice';
import BenefitGuideNotificationsChannel from 'modules/clients/DBGClient/channels/BenefitGuideNotificationsChannel';
import { BenefitCategory } from 'constants/commonConstants';
import AddPlanModal from 'modules/plans/components/AddPlanModal/AddPlanModal';
import { BasicPlans } from 'modules/plans/constants';

const LifeAndDisabilityChannel = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [benefitType, setBenefitType] = useState<Option>({
    value: '',
    label: '',
  });
  const [plan, setPlan] = useState<LifePlan | null>(null);
  const [subscribedEvent, setSubscribedEvent] = useState('');
  const [disableSave, setDisableSave] = useState(false);
  const [planYearId, setPlanYearID] = useState<string>('');
  const [isRenewalStarted, setIsRenewalStarted] = useState(false);
  const { planYearsList } = useAppSelector((state) => state.employer.employer);
  const [getUpcomingPlanYears, { data: upcomingData }] =
    useLazyGetUpcomingPlanYearsByEmployerQuery();
  const dispatch = useAppDispatch();

  const cancelModalWhenRefresh = () => {
    setIsModalVisible(false);
  };

  const cancelModalWhenExit = () => {
    setIsModalVisible(false);
    dispatch(changedBenguideModalState(false));
  };

  const dbgClient = useDBGClient();
  const { employer } = useNavContext();

  const showOverlay = () => {
    if (disableSave) {
      dbgClient.postMessage({
        channel: LIFE_AND_DISABILITY_CHANNEL,
        event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
        data: {},
      });
    }
  };

  const handleClientEvent = (event: string, data: any, dbgMetaData: any) => {
    switch (event) {
      case OPEN_LIFE_AND_DISABILITY_PLAN_EDIT_MODAL:
        setPlan(data);
        setIsModalVisible(true);
        setSubscribedEvent(event);
        setDisableSave(false);
        break;
      case OPEN_ADD_NEW_PLAN_MODAL:
        setBenefitType(data);
        setIsModalVisible(true);
        setSubscribedEvent(event);
        setDisableSave(false);
        setPlanYearID(dbgMetaData?.planYearId);
        break;
      case EXIT_BENGUIDE:
        cancelModalWhenExit();
        break;
      case BENGUIDE_SAVE_COMPLETED:
        cancelModalWhenRefresh();
        break;
      default:
        break;
    }
  };

  /**
   * TODO: This change only exists because for PY15, new Plan modal and SBC flow were only
   * implemented for basic life plans. Not voluntary life plans. But this logic is
   * used to open the relevant modal for all voluntary life and basic life.
   *
   * This logic should be removed once the new plan modal and SBC flow are implemented for
   * voluntary life plans. It should be identical to MedicalPlansChannel.
   */
  const isNewAddPlanModal = Object.keys(BasicPlans).includes(benefitType.value);
  const addPlanComponent = isNewAddPlanModal ? AddPlanModal : AddLifePlanModal;
  const addPlanProps = isNewAddPlanModal
    ? ({
        benefit: 'LIFE',
        subType: benefitType?.value,
        title: benefitType?.label,
        isOpen: isModalVisible,
        onClose: () => {
          setIsModalVisible(false);
          showOverlay();
        },
        onSave: () => {
          setIsModalVisible(false);
          dbgClient.postMessage({
            channel: LIFE_AND_DISABILITY_CHANNEL,
            event: RELOAD_LIFE_AND_DISABILITY_PLAN_LIST,
            data: {},
          });
        },
        isRenewalProcessStarted: !!isRenewalStarted,
        dbgPlanYear: planYearId,
        isDisable: disableSave,
      } as typeof AddPlanModal.defaultProps)
    : ({
        lifePlanType: benefitType,
        employerName: employer?.name,
        visible: isModalVisible,
        onClose: () => {
          setIsModalVisible(false);
          showOverlay();
        },
        onSaveClose: () => {
          setIsModalVisible(false);
          dbgClient.postMessage({
            channel: LIFE_AND_DISABILITY_CHANNEL,
            event: RELOAD_LIFE_AND_DISABILITY_PLAN_LIST,
            data: {},
          });
        },
        isRenewalProcessStarted: !!isRenewalStarted,
        isSaveDisabled: disableSave,
        isDBGPlan: true,
        dbgPlanYear: planYearId,
      } as typeof AddLifePlanModal.defaultProps);

  const eventComponentMapper = {
    [OPEN_ADD_NEW_PLAN_MODAL]: {
      component: addPlanComponent,
      title: '',
      props: addPlanProps,
    },
    [OPEN_LIFE_AND_DISABILITY_PLAN_EDIT_MODAL]: {
      component: EditLifePlan,
      title: `Edit Life & Disability Plan`,
      props: {
        onCancel: () => {
          setIsModalVisible(false);
          showOverlay();
        },
        plan: plan,
        visible: isModalVisible,
        onSave: () => {
          dbgClient.postMessage({
            channel: LIFE_AND_DISABILITY_CHANNEL,
            event: RELOAD_LIFE_AND_DISABILITY_PLAN,
            data: {},
          });
        },
        isDBGPlan: true,
        isSaveDisabled: disableSave,
      },
      width: 600,
    },
  } as any;

  const {
    component: Component,
    title,
    props,
    width,
  } = eventComponentMapper[subscribedEvent] || {};

  useEffect(() => {
    if (employer) {
      dispatch(getPlanYears(employer?.id ?? ''));
      getUpcomingPlanYears({ employerId: employer?.id ?? '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employer?.id]);

  useEffect(() => {
    const isPlanYearInList = !!planYearsList?.find(
      (planYear: any) => planYear.id === planYearId
    )?.current;

    const hasUpcomingPlanYearWithNullId = upcomingData?.upcomingPlanYears?.some(
      (obj: any) => obj.planYearId === null
    );

    const isRenewalStarted = isPlanYearInList && hasUpcomingPlanYearWithNullId;

    setIsRenewalStarted(isRenewalStarted ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcomingData, planYearsList]);

  return (
    <>
      <DBGClient
        channel={LIFE_AND_DISABILITY_CHANNEL}
        subscribeCommon={(event: string) => {
          if (CLOSE_MODALS === event) {
            setIsModalVisible(false);
          }
        }}
        subscribe={(event: string, data: any, dbgMetaData: any) => {
          handleClientEvent(event, data, dbgMetaData);
        }}
      />
      <BenefitGuideNotificationsChannel
        setDisableSave={() => setDisableSave(true)}
        isPlanEditModalOpen={isModalVisible}
        benefitKind={BenefitCategory.LIFE.value}
      />
      {Component && <Component title={title} width={width} {...props} />}
    </>
  );
};

export default LifeAndDisabilityChannel;
