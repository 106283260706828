import { isEmpty } from 'lodash';
import moment from 'moment';
import { loginTypes } from 'constants/authConstants';
import {
  DURATION_ELEMENTS,
  ISSUE_EDIT_TYPES,
} from 'modules/issueslog/constants/IssuesLogConstants';

import { ISSUE_LOG_SAVE_FILTER_ID_TYPES } from 'modules/issueslog/constants/IssueLogFilterConstants';

export const issueType = {
  ACCOUNT: 'account',
  SUPPORT: 'support',
};

export const issueAnalyticsDateRange = {
  TWELVE_MONTHS: 'TWELVE_MONTHS',
  TWELVE_WEEKS: 'TWELVE_WEEKS',
  FOUR_WEEKS: 'FOUR_WEEKS',
  SEVEN_DAYS: 'SEVEN_DAYS',
};

export const analyticsDateRangeNameMap = [
  { name: issueAnalyticsDateRange.SEVEN_DAYS, value: 'Last 7 Days' },
  { name: issueAnalyticsDateRange.FOUR_WEEKS, value: 'Last 4 Weeks' },
  { name: issueAnalyticsDateRange.TWELVE_WEEKS, value: 'Last 12 Weeks' },
  { name: issueAnalyticsDateRange.TWELVE_MONTHS, value: 'Last 12 Months' },
];

export const getSubHeadingAccordingToLoginType = (
  loginType: string,
  paramType: string,
  triggerDashboard: boolean
) => {
  switch (loginType) {
    case loginTypes.erAdmin:
      return '';

    case loginTypes.bokerAdmin:
      if (triggerDashboard) return 'Account Team';
      if (paramType === issueType.ACCOUNT) return 'Account Team';
      if (paramType === issueType.SUPPORT) return 'Support Team';
      return '';
    default:
      return '';
  }
};

export const issueAllowedFileTypes = [
  'application/pdf',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/zip',
  'application/x-zip-compressed',
  'multipart/x-zip',
];

export const timeShortFormat = {
  years: 'yr',
  months: 'mnth',
  day: 'day',
  days: 'days',
  hours: 'hr',
  minutes: 'min',
  seconds: 'sec',
} as any;

export const checkIsEmptyRequired = (valueArray: any[]) => {
  const checkBoolArray = valueArray.map((item) => isEmpty(item)).includes(true);
  return !checkBoolArray;
};

export const getStatus = (type: string) => {
  switch (type) {
    case 'New':
      return 'NEW';
    case 'Assigned':
      return 'ASSIGNED';
    case 'In Progress':
      return 'IN_PROGRESS';
    case 'Blocked':
      return 'BLOCKED';
    case 'Reserved':
      return 'RESERVED';
    case 'Resolved':
      return 'RESOLVED';
    case 'Re-Opened':
      return 'RE_OPENED';
    default:
      return 'IN_PROGRESS';
  }
};

export const getConfirmModelContent = (type: string, value?: any) => {
  switch (type) {
    case ISSUE_EDIT_TYPES.SUPPORT_TEAM:
      return {
        title: 'Confirm Support Team',
        confirmText: 'Yes - Save Support Team',
        content: `Are you sure you want to assign this item to ${value?.label} ?`,
      };

    case ISSUE_EDIT_TYPES.EMPLOYER:
      return {
        title: 'Confirm Employer',
        confirmText: 'Yes - Save Employer',
        content: `Are you sure you want to assign this item to ${value?.label} ?`,
      };
    case ISSUE_EDIT_TYPES.VISIBLE_TO_EMPLOYER:
      return {
        title: 'Employer Visibility',
        confirmText: `Yes - ${
          value?.value === true ? 'Show' : 'Hide'
        } on Employer’s Account Log`,
        content: `Are you sure you want to ${
          value?.value ? 'show' : 'hide'
        } this item on the employer’s Account log ?`,
      };
    case ISSUE_EDIT_TYPES.ISSUE_TYPE_SPAM:
      return {
        title: 'Mark Item as Not Tracked',
        confirmText: 'Yes - Mark as Not Tracked',
        content: ` Are you sure you want to mark this item as Not Tracked? This action cannot be undone and will remove this item from any analytics. The item will not be deleted and can still be found by filtering the log by Type.`,
      };
    case ISSUE_EDIT_TYPES.ASSIGN_TO:
      return {
        title: 'Confirm Assignment',
        confirmText: 'Yes - Save Assignment',
        content: `Are you sure you want to assign this item to ${value?.label} ?`,
      };
    case ISSUE_EDIT_TYPES.RESOLVED:
      return {
        title: 'Resolve Item',
        confirmText: 'Yes - Resolve Item',
        content: `Are you sure you want to resolve this item ?`,
      };
    case ISSUE_EDIT_TYPES?.RE_OPENED:
      return {
        title: 'Re-Open Item',
        confirmText: 'Yes - Re-Open Item',
        content: `Are you sure you want to re-open this item ?`,
      };
  }
};

export const createUniqueId = (
  params: any,
  isEmployerView: boolean,
  loginType: string
) => {
  let concatString = '';

  if (loginType === loginTypes.erAdmin) {
    concatString = ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER;
  }

  if (
    loginType === loginTypes.bokerAdmin &&
    params?.type === issueType.ACCOUNT &&
    !isEmployerView
  ) {
    concatString = ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_ACCOUNT;
  }

  if (
    loginType === loginTypes.bokerAdmin &&
    params?.type === issueType.SUPPORT &&
    !isEmployerView
  ) {
    concatString = ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_SUPPORT;
  }

  if (
    (loginType === loginTypes.bokerAdmin ||
      loginType === loginTypes.platform) &&
    params?.type === issueType.ACCOUNT &&
    isEmployerView
  ) {
    concatString = ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_ACCOUNT;
  }
  if (
    (loginType === loginTypes.bokerAdmin ||
      loginType === loginTypes.platform) &&
    params?.type === issueType.SUPPORT &&
    isEmployerView
  ) {
    concatString = ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_SUPPORT;
  }

  if (
    loginType === loginTypes.platform &&
    params?.type === issueType.ACCOUNT &&
    !isEmployerView
  ) {
    concatString = ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_ACCOUNT;
  }
  if (
    loginType === loginTypes.platform &&
    params?.type === issueType.SUPPORT &&
    !isEmployerView
  ) {
    concatString = ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_SUPPORT;
  }
  return concatString;
};

export const convertToFlatNumberArray = (array: any[]) => {
  return array
    ?.map((item) =>
      String(item).includes(',') ? item?.split(',').map(Number) : item
    )
    .flat();
};

export const jsonParseStringArray = (valueArray: string[]) => {
  const parsedArray = valueArray?.map((item) => JSON.parse(item));
  return parsedArray;
};
export const jsonStringifyNestedArray = (valueArray: string[]) => {
  const parsedArray = valueArray?.map((item) => JSON.stringify(item));
  return parsedArray;
};
export const addOrReplace = (arr: any[], newObj: any) => {
  return [...arr.filter((obj) => obj.name !== newObj.name), { ...newObj }];
};
export const handleNullOrEmpty = (str: string): string => {
  return str === undefined || str === null ? '--' : str;
};

export const getDuration = (seconds: number) => {
  const currentDate = moment();
  const newTime = moment().add(seconds, 'seconds');
  const duration = moment.duration(newTime.diff(currentDate)) as any;
  const durationData = duration?._data ?? null;
  let formatLength = 0;
  let formattedString = null as any;

  if (durationData === null || seconds === 0) {
    return null;
  } else {
    DURATION_ELEMENTS.map((item) => {
      if (durationData[item] !== 0 && formatLength === 0) {
        formattedString =
          durationData[item] +
          ' ' +
          (durationData[item] === 1 && item === 'days'
            ? timeShortFormat.day
            : timeShortFormat[item]);
        formatLength++;
      } else if (durationData[item] !== 0 && formatLength === 1) {
        formattedString =
          formattedString +
          ', ' +
          durationData[item] +
          ' ' +
          timeShortFormat[item];
        formatLength++;
      }
    });
  }

  if (formattedString === null && (durationData?.seconds ?? 0) !== 0) {
    formattedString = durationData?.seconds + ' ' + timeShortFormat?.seconds;
  }

  return formattedString;
};
