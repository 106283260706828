import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks/redux';

import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import AddBroker from 'modules/brokers/pages/AddBroker/AddBroker';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  clearBrokerCreateErr,
  clearBrokerData,
} from 'modules/brokers/slices/brokerBasicInfoSlice';
import { exitWithoutSavingMsg } from 'constants/commonConstants';

import styles from './addBrokerModal.module.less';

type AddBrokerModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

const AddBrokerModal: FC<AddBrokerModalProps> = (props) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);

  const { isModalOpen, setIsModalOpen } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (deleteConfirmation) {
      setCurrentStep(0);
      dispatch(clearBrokerData());
      dispatch(clearBrokerCreateErr());
      setIsModalOpen(false);
      setDeleteConfirmation(false);
    }
  }, [deleteConfirmation, dispatch, setIsModalOpen]);

  const closeModal = () => {
    setDeleteConfirmation(false);
    setVisibleConfirmation(false);
  };

  const confirmDelete = () => {
    setDeleteConfirmation(true);
    setVisibleConfirmation(false);
  };

  const changeCurrentStep = (activeStep: number) => {
    setCurrentStep(activeStep);
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const closeBrokerModal = () => {
    if (currentStep === 4) {
      setCurrentStep(0);
      dispatch(clearBrokerData());
      setIsModalOpen(false);
    } else {
      setVisibleConfirmation(true);
    }
  };

  return (
    <FullScreenModal
      visible={isModalOpen}
      onCancel={closeBrokerModal}
      footer={false}
      title="Add Broker"
      destroyOnClose
    >
      <AddBroker
        closeModal={closeBrokerModal}
        changeCurrentStep={changeCurrentStep}
        nextStep={nextStep}
        currentStep={currentStep}
      />
      {visibleConfirmation && (
        <ConfirmationDialog
          title="Are you sure you want to close?"
          confirmText="Yes - close and do not save"
          cancelText="Cancel"
          closeModal={closeModal}
          onConfirm={confirmDelete}
          visible={visibleConfirmation}
        >
          <p className={styles.warningConfirmation}>{exitWithoutSavingMsg}</p>
        </ConfirmationDialog>
      )}
    </FullScreenModal>
  );
};

export default AddBrokerModal;
