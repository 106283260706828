import { FC } from 'react';

import { isEmpty } from 'lodash';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';

import briefCase from 'assets/images/icon-employer-large.svg';

import styles from './emptyContent.module.less';

interface Props {
  setIsModalOpen: (isOpen: boolean) => void;
  header: string;
  title: string;
  content: string;
  buttonText: string;
}

const EmptyContent: FC<Props> = (props) => {
  const { setIsModalOpen, header, title, content, buttonText } = props;

  return (
    <div className={styles.emptyContentContainer}>
      <h1>{header}</h1>
      <div className={styles.emptyContentWrapper}>
        <img src={briefCase} alt="next-icon" className={styles.image} />
        <div className={styles.text}>
          <p
            className={`${styles.title} ${
              isEmpty(content) && styles.restrictedAccessUserTitle
            }`}
          >
            {title}
          </p>
          {!isEmpty(content) && <p className={styles.content}>{content}</p>}
        </div>
        {isEmpty(buttonText) ? (
          <></>
        ) : (
          <div className={styles.buttonWrapper}>
            <PageActionButton
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className={styles.actionButton}
            >
              {buttonText}
            </PageActionButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmptyContent;
