import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useAppSelector } from 'hooks/redux';
import PlanBasicInfoForm from 'modules/plans/components/DVPlanBasicInfoForm/PlanBasicInfoForm';

type DentalBasicPlanInfoWrapperProps = {
  onChange: Function;
  isCloseConfirmed: boolean;
  error: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  dbgPlanYear?: string;
  planAlreadyExists?: boolean;
  setPlanAlreadyExists?: Function;
  setAlertMessage?: Function;
};

const DentalBasicPlanInfoWrapper = forwardRef(
  (props: DentalBasicPlanInfoWrapperProps, ref) => {
    const {
      onChange,
      isCloseConfirmed,
      error,
      dbgPlanYear,
      planAlreadyExists,
      setPlanAlreadyExists,
      setAlertMessage,
    } = props;
    const editedDentalPlan = useAppSelector(
      (state) => state.plan.dentalPlan.editedDentalPlan
    );
    const { dentalPlan } = useAppSelector((state) => state.plan.dentalPlan);
    const formRef = useRef<any>(null);

    useImperativeHandle(ref, () => ({
      validate: async () => ({
        isValid: await formRef.current.validate(),
        isComplete: formRef.current.getIsComplete(),
      }),
    }));

    return (
      <PlanBasicInfoForm
        isUPEdit
        isSingleColumn
        ref={formRef}
        onChange={onChange}
        plan={dentalPlan}
        isCloseConfirmed={isCloseConfirmed}
        error={error}
        dbgPlanYear={dbgPlanYear}
        hasSameContributions={editedDentalPlan?.hasSameContributions}
        benefitCategory="DENTAL"
        planAlreadyExists={planAlreadyExists}
        setPlanAlreadyExists={setPlanAlreadyExists}
        resetAlertMessage={setAlertMessage}
      />
    );
  }
);

DentalBasicPlanInfoWrapper.displayName = 'DentalBasicPlanInfoWrapper';

export default DentalBasicPlanInfoWrapper;
