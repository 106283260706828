import { FC } from 'react';
import { Row } from 'antd';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { ReactComponent as AlertInfo } from 'assets/images/icon-info-blue.svg';

import styles from './noERAdminView.module.less';

type NoERAdminViewProps = {
  setIsModalOpen: Function;
  isModalOpen: boolean;
};

const NoERAdminView: FC<NoERAdminViewProps> = (props) => {
  const { setIsModalOpen } = props;
  return (
    <div className={styles.noERAdminView}>
      <div className={styles.noERAdminViewWrapper}>
        <Row className={styles.description}>
          <AlertInfo className={styles.alertInfoIcon} />
        </Row>
        <Row className={styles.description}>
          <h2>There are no Users set up</h2>
        </Row>
        <Row className={styles.description}>
          <p>Start by adding an Employer User below:</p>
        </Row>
        <Row className={styles.description}>
          <PageActionButton
            type="primary"
            onClick={() => setIsModalOpen(true)}
            className={styles.addERButton}
          >
            Add Employer User
          </PageActionButton>
        </Row>
      </div>
    </div>
  );
};

export default NoERAdminView;
