import { FC, useEffect } from 'react';
import { UP_COMMON_CHANNEL } from 'modules/clients/DBGClient/DBGClientConts';
import { benguideUrl } from 'util/apiUtil';

const MESSAGE = 'message';

type DBGClientProps = {
  subscribe: Function;
  subscribeCommon?: Function;
  channel: string;
};

export type DBGEvent = {
  channel: string;
  event: string;
  data: any;
};

const DBGClient: FC<DBGClientProps> = (props: DBGClientProps) => {
  const { subscribe, channel, subscribeCommon } = props;

  useEffect(() => {
    const listenToDBG = (_event: any) => {
      if (_event.origin !== benguideUrl) return;
      const { channel: _channel, event, data, dbgMetaData } = _event.data;
      if (UP_COMMON_CHANNEL === _channel) {
        subscribeCommon && subscribeCommon(event, data);
      }
      if (channel === _channel) {
        subscribe(event, data, dbgMetaData);
      }
    };

    window.addEventListener(MESSAGE, listenToDBG, true);

    return () => {
      window.removeEventListener(MESSAGE, listenToDBG, true);
    };
  }, [channel, subscribe, subscribeCommon]);

  return <></>;
};

export default DBGClient;
