import React, { FC, useRef, useState, useEffect, useLayoutEffect } from 'react';

import { Dropdown, Input, Menu, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import RichTextEditor from 'components/RichTextEditor/RichTextEditor';

import { ReactComponent as ThumbsUpNotSelected } from 'assets/images/rfp-response-thumbs-up-not-selected.svg';
import { ReactComponent as ThumbsUpSelected } from 'assets/images/rfp-thumbs-up-active.svg';

import { ReactComponent as ThumbsDown } from 'assets/images/rfp-response-thumbs-down-not-selected.svg';
import { ReactComponent as ThumbsDownSelected } from 'assets/images/thumbs-down-active.svg';
import { ReactComponent as AiDropDown } from 'assets/images/rfp-ai-dropdown-icon.svg';
import { ReactComponent as ScrollUp } from 'assets/images/scroll-up-not-selected.svg';
import { ReactComponent as ScrollUpSelected } from 'assets/images/scroll-up-selected.svg';
import { ReactComponent as ScrollDown } from 'assets/images/scroll-down-not-selected.svg';
import { ReactComponent as ScrollDownSelected } from 'assets/images/scroll-down-selected.svg';

import LinkButton from 'components/buttons/LinkButton/LinkButton';
import NextButton from 'components/buttons/NextButton/NextButton';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { isNullOrUndefined } from 'modules/plans/utils';
import {
  addQuestion,
  dislikeThisAnswer,
  getAnswer,
  likeThisAnswer,
  optimizeRfpAnswer,
  setIncludedChunks,
  setSelectedRfpIndex,
  setUpdateQuestion,
} from 'modules/rfp/slices/rfpQuestionSlice';
import rfpPencil from 'assets/images/rfp-card-edit-pencil.svg';

import { sendFeedback } from 'modules/rfp/services/RfpService';
import {
  NO_ANSWER_DETECTED_MESSAGE,
  RFP_SHORT_OPTIMIZE,
  RFP_VOTE_DOWN,
  RFP_VOTE_UP,
  UPLOAD_WARN_MESSAGE,
} from 'modules/rfp/constants/RfpConstants';

import { onlyNumberInputUtil } from 'util/commonUtil';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import QuestionCard from './QuestionCard/QuestionCard';

import DraftNewResponse from './DraftNewResponse/DraftNewResponse';
import LoadingStrip from './LoadingStrip/LoadingStrip';
import styles from './reviewResponse.module.less';

type ReviewResponseProps = {
  nextStep: () => void;
  closeModal: () => void;
};

const ReviewResponses: FC<ReviewResponseProps> = (
  props: ReviewResponseProps
) => {
  const { closeModal, nextStep } = props;
  const { brokerId } = useParams();
  const draftResponseRef = useRef<any>();
  const dispatch = useAppDispatch();
  const questions = useAppSelector((state) => state.rfp.rfpQuestions);

  const selectedRfpId = useAppSelector((state) => state.rfp?.selectedRfpId);
  const selectedQuestion = useAppSelector((state) => state.rfp.selectedIndex);
  const isLiked = useAppSelector(
    (state) => state?.rfp?.rfpQuestions[selectedQuestion]?.isLiked
  );
  const isDisliked = useAppSelector(
    (state) => state?.rfp?.rfpQuestions[selectedQuestion]?.isUnliked
  );
  const isPendingAnswer = useAppSelector(
    (state) => state?.rfp?.rfpQuestions[selectedQuestion]?.isPending
  );
  const isError = useAppSelector(
    (state) => state.rfp?.rfpQuestions[selectedQuestion!]?.isError
  );
  const includedChunkIds = useAppSelector((state) => state.rfp?.includedChunks);

  const isCompletedAnswers = questions.every((item) => !isEmpty(item?.answer));

  const userEnteredContext = useAppSelector(
    (state) => state.rfp?.rfpQuestions[selectedQuestion]?.userEnteredContext
  );

  const selectedContextIds = useAppSelector(
    (state) => state.rfp?.selectedProvideContextFiles
  );

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(0);
  const [questionNumber, setQuestionNumber] = useState<number>(1);
  const [advancedDraft, setAdvancedDraft] = useState<boolean>(false);
  const [aiFeedback, setAiFeedback] = useState<string>('');
  const [richTextValue, setRichTextValue] = useState<string>('');
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [isDislikeAnswer, setIsDislikeAnswer] = useState<boolean>(false);

  const [scrolledUp, setScrolledUp] = useState<boolean>(true);

  const [advancedUuid, setAdvancedUuid] = useState<string>('');
  const [isAddQuestionClicked, setIsAddQuestionClicked] =
    useState<boolean>(false);
  const [addedQuestion, setAddedQuestion] = useState<string>('');

  const loadedAmount = questions
    .filter((item: any) => item.answer)
    .length.toString();
  const questionAmount = questions.length.toString();

  useLayoutEffect(() => {
    if (questions?.length < Number(questionNumber)) return;

    const childs: any = scrollableRef?.current?.childNodes;

    let totalHeight = 0;

    for (let i = 0; i < Number(questionNumber) - 1; i++) {
      totalHeight += childs[i]?.offsetHeight + 12;
    }

    scrollableRef?.current?.scrollTo({
      top: totalHeight,
      left: 0,
      behavior: 'smooth',
    });
  }, [questionNumber, questions?.length]);

  const handleSendFeedback = () => {
    setIsDislikeAnswer(false);
    sendFeedback(brokerId!, RFP_VOTE_DOWN, aiFeedback);
    setAiFeedback('');
  };

  const handleScroll = (height?: number) => {
    if (height === 0) {
      setScrolledUp(true);
    } else {
      setScrolledUp(false);
    }
    scrollableRef?.current?.scrollTo({
      top: height,
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleMakeShorter = () => {
    const selectedUUID = questions[selectedIndex!].uuid;
    dispatch(
      optimizeRfpAnswer(
        brokerId!,
        questions[selectedIndex!].answer,
        RFP_SHORT_OPTIMIZE,
        selectedUUID
      )
    );
  };

  const handleLiked = () => {
    sendFeedback(brokerId!, RFP_VOTE_UP, '');
    dispatch(likeThisAnswer(selectedIndex!));
  };

  const handleGenerateAnswer = () => {
    getAnswer(
      brokerId,
      questions[selectedIndex!].question,
      selectedIndex!,
      selectedRfpId,
      selectedContextIds,
      dispatch
    );
  };

  const handleAdvancedRefined = () => {
    const selectedUUID = questions[selectedIndex!].uuid;
    setAdvancedUuid(selectedUUID);
    setAdvancedDraft(true);
  };

  const menu = (
    <Menu className={styles.aiMenu}>
      <Menu.Item key="0" disabled>
        <AiDropDown className={styles.aiDropDownIcon} />
        <b className={styles.aiToolstextDropdown}>AI Tools</b>
        <hr />
      </Menu.Item>

      <Menu.Item
        key="1"
        onClick={() => {
          handleGenerateAnswer();
        }}
      >
        <b>Create New Response</b>
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          handleAdvancedRefined();
        }}
      >
        <b>Create New Response (advanced)</b>
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          handleMakeShorter();
        }}
      >
        <b>Condense Response</b>
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => {
          handleGenerateAnswer();
        }}
      >
        <b>Elaborate Response</b>
      </Menu.Item>
    </Menu>
  );

  const handleDisableConfirm = () => {
    if (includedChunkIds) {
      if (isEmpty(userEnteredContext?.trim()) && isEmpty(includedChunkIds)) {
        return true;
      }
      return false;
    } else {
      if (isEmpty(userEnteredContext?.trim())) {
        return false;
      }
      return true;
    }
  };

  useEffect(() => {
    setRichTextValue('');
    if (
      !isNullOrUndefined(selectedIndex) &&
      !isEmpty(questions[selectedIndex!]?.answer) &&
      !questions[selectedIndex!]?.isPending
    ) {
      const updatedRichTextValue = questions[selectedIndex!]?.answer.replace(
        /\n/g,
        '<br/>'
      );

      setRichTextValue(updatedRichTextValue);
    }
    // eslint-disable-next-line
  }, [selectedIndex, questions?.[selectedIndex!]?.isPending]);

  const displayAiMenu =
    !isPendingAnswer &&
    !isError &&
    !isEmpty(questions[selectedQuestion]?.answer);

  return (
    <div className={styles.reviewResponseWrapperAll}>
      <FixedAlertMessage
        className={styles.reviewWarningMessage}
        type="warning"
        message={UPLOAD_WARN_MESSAGE}
      />
      <FixedAlertMessage
        className={styles.reviewWarningMessageEdit}
        type="warning"
        message={
          <>
            <p>
              {` In some cases, AI may miss or incompletely extract questions. Use
            the 'Add Questions' button to include more and`}{' '}
              <img
                className={styles.pencilIconWarning}
                src={rfpPencil}
                alt="Pencil Icon"
              />{' '}
              {`icon to refine.`}
            </p>
          </>
        }
      />
      <div className={styles.reviewResponseWrapper}>
        <div className={styles.questionAreaWrapper}>
          <div className={styles.headerQuestionArea}>
            <div className={styles.questionAmountArea}>
              <b>Question</b>
              <Input
                min={1}
                defaultValue={1}
                onKeyPress={onlyNumberInputUtil}
                className={styles.inputForQuestionNumber}
                value={questionNumber}
                onChange={(e) => {
                  const val = parseInt(e.target.value);
                  if (isNaN(val)) {
                    setQuestionNumber(0);
                  } else if (
                    val === 0 ||
                    isNullOrUndefined(val) ||
                    val > questions?.length
                  ) {
                    return;
                  } else {
                    setQuestionNumber(val);
                    dispatch(setSelectedRfpIndex(val - 1));
                    setSelectedIndex(val - 1);
                  }
                }}
              />
              <b>of {questions!.length}</b>
            </div>
            <div
              className={`${styles.scrollAreas} ${
                !scrolledUp ? styles.scrollAreasSelected : ''
              }`}
              onClick={() => handleScroll(0)}
            >
              {scrolledUp ? <ScrollUp /> : <ScrollUpSelected />}
              Scroll
            </div>
          </div>
          <div className={styles.aiDropDownDivWrapper}>
            {displayAiMenu && (
              <Dropdown overlay={menu} className={styles.aiDropDown}>
                <button
                  className={styles.aiDropwonButton}
                  onClick={(e) => e.preventDefault()}
                >
                  <AiDropDown /> <b>AI</b>
                </button>
              </Dropdown>
            )}
          </div>
          <div className={styles.scrollableQuestionArea}>
            <div className={styles.scrollableQuestions} ref={scrollableRef}>
              {questions?.map((item: any, index: any) => (
                <QuestionCard
                  index={index}
                  key={index}
                  selected={selectedIndex}
                  setSelectedIndex={() => {
                    setSelectedIndex(index);
                    setQuestionNumber(index + 1);
                  }}
                  content={item?.question!}
                />
              ))}
              <LinkButton
                onClick={() => {
                  setIsAddQuestionClicked(true);
                }}
              >
                + Add Question
              </LinkButton>
            </div>

            <div className={styles.questionAreaFooter}>
              <div
                onClick={() =>
                  handleScroll(scrollableRef?.current?.scrollHeight)
                }
                className={`${styles.scrollAreas} ${
                  scrolledUp ? styles.scrollAreasSelected : ''
                }`}
              >
                {!scrolledUp ? <ScrollDown /> : <ScrollDownSelected />}
                Scroll
              </div>
            </div>
          </div>
        </div>
        <div className={styles.richTextEditorWrapper}>
          <div>
            <div className={styles.headerTextEditor}>
              <div>
                <b>Response</b>
              </div>
              <div className={styles.thumbsImages}>
                <div className={styles.trainingLabel}>
                  {loadedAmount}/{questionAmount} Answered
                  {!isCompletedAnswers ? <LoadingStrip /> : <></>}
                </div>

                <div className={styles.linkButtonsThumbs}>
                  <div className={styles.rateResponseArea}>
                    <p className={styles.rateResponseTag}>Rate this Response</p>
                  </div>
                  {!isLiked ? (
                    <div>
                      <ThumbsUpNotSelected
                        onClick={() => {
                          handleLiked();
                        }}
                      />
                    </div>
                  ) : (
                    <div className={styles.thumbsUpSection}>
                      <ThumbsUpSelected />
                      <p className={styles.greatToHearText}>Great to hear!</p>
                    </div>
                  )}

                  {!isDisliked ? (
                    <ThumbsDown
                      className={styles.thumbsDownArea}
                      onClick={() => {
                        setIsDislikeAnswer(true);
                        dispatch(dislikeThisAnswer(selectedIndex!));
                      }}
                    />
                  ) : (
                    <ThumbsDownSelected className={styles.thumbsDownArea} />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.richTextEditorArea}>
              {isPendingAnswer ? (
                <div>
                  <Spin className={styles.spinForLoading} />
                  <p className={styles.generatingResponseTag}>
                    <b>Generating Response ...</b>
                  </p>
                  <RichTextEditor />
                </div>
              ) : (
                <>
                  {isError ? (
                    <div className={styles.noAnswerDetectedArea}>
                      <FixedAlertMessage
                        type="warning"
                        message={NO_ANSWER_DETECTED_MESSAGE}
                      />
                    </div>
                  ) : isEmpty(questions[selectedQuestion]?.answer) ? (
                    <> </>
                  ) : (
                    <RichTextEditor
                      onChange={(value: string) => {
                        dispatch(
                          setUpdateQuestion({
                            index: selectedIndex,
                            value: value ? value : ' ',
                          })
                        );
                      }}
                      height={750}
                      maxHeight={750}
                      defaultText={richTextValue}
                      initialValue={richTextValue}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.reviewResponseFooter}>
        {isCompletedAnswers ? (
          <NextButton
            className={styles.nextButton}
            buttonText="Save & Continue"
            nextStep={() => {
              nextStep();
            }}
          />
        ) : (
          <NextButton
            className={styles.nextButtonDisabled}
            buttonText="Next"
            nextStep={() => {}}
          />
        )}
        <div className={styles.cancelLinkArea}>
          <LinkButton onClick={() => closeModal()}>Cancel</LinkButton>
        </div>

        {isDislikeAnswer && (
          <ConfirmationDialog
            title="AI Feedback"
            confirmText="Send"
            cancelText="Cancel"
            isCancelLink
            visible={isDislikeAnswer}
            onCancel={() => setIsDislikeAnswer(false)}
            onConfirm={handleSendFeedback}
            closeModal={() => setIsDislikeAnswer(false)}
          >
            <p>Let us know how we can do better</p>
            <TextArea
              value={aiFeedback}
              onChange={(e) => {
                setAiFeedback(e.target.value);
              }}
              rows={6}
            />
          </ConfirmationDialog>
        )}

        {advancedDraft && (
          <ConfirmationDialog
            visible={advancedDraft}
            title="Create New Response (advanced)"
            confirmText="Create New Response (advanced)"
            isCancelLink
            onCancel={() => {
              setAdvancedDraft(false);
              dispatch(setIncludedChunks([]));
            }}
            cancelText="Cancel"
            disableConfirmButton={handleDisableConfirm()}
            className={styles.advancedDraftModal}
            closeModal={() => setAdvancedDraft(false)}
            onConfirm={() => {
              draftResponseRef.current.submitDraftResponse();
              dispatch(setIncludedChunks([]));
              setAdvancedDraft(false);
            }}
            destroyOnClose={true}
          >
            <DraftNewResponse
              isShowAlert={handleDisableConfirm()}
              uuid={advancedUuid}
              index={selectedQuestion}
              ref={draftResponseRef}
            />
          </ConfirmationDialog>
        )}

        {isAddQuestionClicked && (
          <ConfirmationDialog
            width={800}
            visible={isAddQuestionClicked}
            title="Add Question"
            confirmText="Save Question"
            isCancelLink
            onCancel={() => setIsAddQuestionClicked(false)}
            cancelText="Cancel"
            disableConfirmButton={isEmpty(addedQuestion.trim())}
            closeModal={() => setIsAddQuestionClicked(false)}
            onConfirm={() => {
              dispatch(
                addQuestion(
                  brokerId!,
                  addedQuestion,
                  questions.length + 1,
                  selectedRfpId,
                  selectedContextIds
                )
              );
              setAddedQuestion('');
              setIsAddQuestionClicked(false);
            }}
            destroyOnClose={true}
          >
            <p>
              <b>Question: </b>
            </p>
            <TextArea
              rows={13}
              className={styles.addedQuestionTextArea}
              value={addedQuestion}
              onChange={(e) => {
                setAddedQuestion(e.target.value);
              }}
            />
          </ConfirmationDialog>
        )}
      </div>
    </div>
  );
};

export default ReviewResponses;
