import { FC, useState } from 'react';

import { Col, Row } from 'antd';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';
import { BasicPlans, lifeServiceConstants } from 'modules/plans/constants';
import { LifePlan } from 'model/plans/LifePlan';

import { isNullOrUndefined } from 'modules/plans/utils';
import PlanDocumentsOverview from 'modules/plans/components/OverviewPage/PlanDocumentsOverview/PlanDocumentOverview';
import styles from './basicPlanInformationOverview.module.less';

type BasicPlanInformationOverviewProps = {
  plan: LifePlan;
  heading?: string;
  isAdvancedProfile: boolean;
};

const BasicPlanInformationOverview: FC<BasicPlanInformationOverviewProps> = (
  props
) => {
  const { plan, isAdvancedProfile } = props;

  const [viewPlanDocumentsModal, setViewPlanDocumentsModal] = useState(false);

  const getNonMdvDescription = (value: string): string => {
    const nonMDVServiceType = plan?.lifeServices?.find(
      (obj) => obj.service == value
    );
    if (nonMDVServiceType) {
      if (nonMDVServiceType.description?.trim() === '') {
        return '-';
      } else {
        return nonMDVServiceType.description;
      }
    }
    return '-';
  };

  const isNonMdvSkip = (value: string): boolean => {
    return (
      !plan?.lifeServices?.some((obj) => obj.service === value) ||
      !isAdvancedProfile
    );
  };

  const contributionOverview = [
    {
      label: 'Benefit Maximum',
      value:
        plan?.benefitMaximum === undefined ||
        plan?.benefitMaximum?.trim() === ''
          ? '-'
          : plan.benefitMaximum,
      types: [
        BasicPlans.BASIC_LIFE.value,
        BasicPlans.BASIC_ADD.value,
        BasicPlans.BASIC_LIFE_AND_ADD.value,
      ],
    },
    {
      label: 'Guaranteed Issue',
      value:
        plan?.guaranteedIssue === undefined ||
        plan?.guaranteedIssue?.trim() === ''
          ? '-'
          : plan.guaranteedIssue,
      types: [
        BasicPlans.BASIC_LIFE.value,
        BasicPlans.BASIC_ADD.value,
        BasicPlans.BASIC_LIFE_AND_ADD.value,
      ],
    },
    {
      label: 'Age Reduction',
      value:
        plan?.ageReduction === undefined || plan?.ageReduction?.trim() === ''
          ? '-'
          : plan.ageReduction,
      types: [
        BasicPlans.BASIC_LIFE.value,
        BasicPlans.BASIC_ADD.value,
        BasicPlans.BASIC_LIFE_AND_ADD.value,
      ],
    },
    {
      label: 'Life Rate',
      value: plan.lifeRate ? `${plan.lifeRate}` : '-',
      types: [BasicPlans.BASIC_LIFE.value, BasicPlans.BASIC_LIFE_AND_ADD.value],
    },
    {
      label: 'AD&D Rate',
      value: plan.addRate ? `${plan.addRate.toFixed(3)}` : '-',
      types: [BasicPlans.BASIC_ADD.value, BasicPlans.BASIC_LIFE_AND_ADD.value],
    },
    {
      label: 'Volume',
      value: plan.volume ? `$${plan.volume.toFixed(2)}` : '-',
      types: [
        BasicPlans.BASIC_LIFE.value,
        BasicPlans.BASIC_ADD.value,
        BasicPlans.BASIC_LIFE_AND_ADD.value,
      ],
    },
    {
      label: 'Accelerated Life Benefit',
      value: getNonMdvDescription(
        lifeServiceConstants.ACCELERATED_LIFE_BENEFIT
      ),
      types: [
        BasicPlans.BASIC_LIFE.value,
        BasicPlans.BASIC_ADD.value,
        BasicPlans.BASIC_LIFE_AND_ADD.value,
      ],
      isSkip: isNonMdvSkip(lifeServiceConstants.ACCELERATED_LIFE_BENEFIT),
    },
    {
      label: 'Portability',
      value: getNonMdvDescription(lifeServiceConstants.PORTABILITY),
      types: [
        BasicPlans.BASIC_LIFE.value,
        BasicPlans.BASIC_ADD.value,
        BasicPlans.BASIC_LIFE_AND_ADD.value,
      ],
      isSkip: isNonMdvSkip(lifeServiceConstants.PORTABILITY),
    },
    {
      label: 'Waiver of Premium',
      value: getNonMdvDescription(lifeServiceConstants.WAIVER_OF_PREMIUM),
      types: [
        BasicPlans.BASIC_LIFE.value,
        BasicPlans.BASIC_ADD.value,
        BasicPlans.BASIC_LIFE_AND_ADD.value,
      ],
      isSkip: isNonMdvSkip(lifeServiceConstants.WAIVER_OF_PREMIUM),
    },
    {
      label: 'Definition of Earnings',
      value: getNonMdvDescription(lifeServiceConstants.DEFINITION_OF_EARNINGS),
      types: [
        BasicPlans.BASIC_LIFE.value,
        BasicPlans.BASIC_ADD.value,
        BasicPlans.BASIC_LIFE_AND_ADD.value,
      ],
      isSkip: isNonMdvSkip(lifeServiceConstants.DEFINITION_OF_EARNINGS),
    },
  ];

  const getbenefitAmount = (value: string | number | undefined) => {
    if (!isNullOrUndefined(value)) {
      if (value === '') {
        return '-';
      } else {
        return value;
      }
    } else {
      return '-';
    }
  };

  const benefitTypeOverview = [
    {
      label: 'Flat Amount',
      value: getbenefitAmount(plan.flatAmount),
    },
    {
      label: 'Multiplier',
      value: getbenefitAmount(plan.multiplier),
    },
  ];

  const updateViewPlanDocumentsModal = (value: boolean) => {
    setViewPlanDocumentsModal(value);
  };

  return (
    <div className={styles.wrapper}>
      {contributionOverview.map((item, index) => {
        if (item.types.includes(plan.benefitKind)) {
          if (index === 0) {
            return (
              <div>
                <Row key={index}>
                  <Col span={24}>
                    <InfoLabelSection
                      labelText={item.label}
                      value={item.value}
                    />
                  </Col>
                </Row>
                <Row key={index}>
                  <Col span={24}>
                    <InfoLabelSection
                      labelText={benefitTypeOverview[0].label}
                      value={benefitTypeOverview[0].value}
                    />
                  </Col>
                </Row>
                <Row key={index}>
                  <Col span={24}>
                    <InfoLabelSection
                      labelText={benefitTypeOverview[1].label}
                      value={benefitTypeOverview[1].value}
                    />
                  </Col>
                </Row>
              </div>
            );
          }
          if (!item.isSkip) {
            return (
              <Row key={index}>
                <Col span={24}>
                  <InfoLabelSection labelText={item.label} value={item.value} />
                </Col>
              </Row>
            );
          }
        }
      })}
      <div className={styles.overview} id={'Plan_Doc'}>
        <PlanDocumentsOverview
          heading={'Plan Resources'}
          plan={plan}
          documents={plan?.documents}
          benefitKind={plan?.benefitKind}
          viewPlanDocumentsModal={viewPlanDocumentsModal}
          setViewPlanDocumentsModal={(value) =>
            updateViewPlanDocumentsModal(value)
          }
        />
      </div>
    </div>
  );
};

export default BasicPlanInformationOverview;
