import { FC, useEffect, useRef, useState } from 'react';

import { Form, Menu, Row } from 'antd';
import { find, isEmpty } from 'lodash';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import DataTable from 'components/DataTable/DataTable';
import { DataColumn, getRowActions } from 'components/DataTable/DataColumn';
import TablePagination from 'model/TablePagination';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import AlertMessage from 'components/Alert/AlertMessage';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import {
  getPlanYears,
  getPlanYearsInProgress,
  createBenefitClass,
  createBenefitClassCompleted,
  createBenefitClassInProgress,
  deleteBenefitClassCompleted,
  deleteBenefitClassFailed,
  editBenefitClass,
  editBenefitClassCompleted,
  editBenefitClassFailed,
  deleteBenefitClass,
} from 'modules/employers/slices/employerSlice';
import PlanYear from 'model/PlanYear';
import PlanYearDropdown from 'components/PlanYearDropdown/PlanYearDropdown';
import {
  DUPLICATE_DATA,
  BENEFIT_CLASS_ALREADY_ATTACHED_TO_A_BENGUIDE,
  EMPLOYER_GROUP_ALREADY_ATTACHED_TO_A_PLAN,
} from 'modules/employers/constants/employerConstants';
import alertSuccess from 'assets/images/alert-success.svg';
import alertError from 'assets/images/alert-error.svg';
import { useStateCallback } from 'hooks/updateState';
import {
  IndividualSubTypes,
  exitWithoutSavingMsg,
} from 'constants/commonConstants';
import AddEditBenefitClass from './AddEditBenefitClass/AddEditBenefitClass';

import styles from './benefitClassList.module.less';

const ACTION_EDIT = 'edit';
const ACTION_REMOVE = 'remove';

type BenefitClassListProps = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  setIsEditMode: (edit: boolean) => void;
  isEditMode: boolean;
  isDeleted?: boolean;
  setIsPlanYearDeleted?: (deleted: boolean) => void;
};

/* eslint-disable no-unused-vars */
enum AlertType {
  SUCCESS = 'success',
  ERROR = 'error',
}

/* eslint-disable no-unused-vars */

const BenefitClassList: FC<BenefitClassListProps> = (
  props: BenefitClassListProps
) => {
  const dispatch = useAppDispatch();
  const { planYearsList, requestType, inProgress, error } = useAppSelector(
    (state) => state.employer.employer
  );
  const { brokerId, employerId } = useNavContext();
  const {
    isModalOpen,
    setIsModalOpen,
    setIsEditMode,
    isEditMode,
    isDeleted,
    setIsPlanYearDeleted,
  } = props;

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {},
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: '',
    },
    filters: {},
  });
  const [selectedPlanYear, setSelectedPlanYear] = useState<PlanYear>({
    id: '',
    benefitGroups: [],
    employerId: employerId || '',
    startDate: '',
    endDate: '',
    current: false,
    name: '',
    next: false,
    previous: false,
  });

  const individualSubType = useAppSelector(
    (state) => state?.auth?.auth?.appBootupInfo?.individualSubType
  );

  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
  const [
    showDeleteWarnBenGuideDependency,
    setShowDeleteWarnBenGuideDependency,
  ] = useState<boolean>(false);
  const [selectedBenefitClass, setSelectedBenefitClass] = useState<string>('');
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertType, setAlertType] = useState<AlertType | null>(null);
  const [isConfirmOpen, setIsConfirmOpen] = useStateCallback(false);
  const [visibleConfirmationDelete, setVisibleConfirmationDelete] =
    useState<boolean>(false);

  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  const [form] = Form.useForm();
  const addEditModalRef = useRef<any>(null);

  useEffect(() => {
    if (brokerId && employerId) {
      dispatch(getPlanYears(employerId));
    }
  }, [dispatch, brokerId, employerId]);

  useEffect(() => {
    if (!isEmpty(planYearsList)) {
      const currentPlanYear = find(planYearsList, { current: true });
      const updatedPlanYear = find(planYearsList, { id: selectedPlanYear.id });

      if (currentPlanYear && isEmpty(selectedPlanYear.id)) {
        setSelectedPlanYear(currentPlanYear);
      }

      if (updatedPlanYear && !isEmpty(selectedPlanYear.id)) {
        setSelectedPlanYear(updatedPlanYear);
      }
    }
  }, [planYearsList, selectedPlanYear]);

  useEffect(() => {
    if (
      (requestType === deleteBenefitClassCompleted.type ||
        requestType === createBenefitClassCompleted.type ||
        requestType === editBenefitClassCompleted.type) &&
      brokerId &&
      employerId
    ) {
      dispatch(getPlanYears(employerId));
    }
  }, [dispatch, brokerId, employerId, requestType]);

  useEffect(() => {
    if (
      requestType === deleteBenefitClassFailed.type &&
      error.data.code === EMPLOYER_GROUP_ALREADY_ATTACHED_TO_A_PLAN
    ) {
      setShowDeleteWarning(true);
    }
    if (
      requestType === deleteBenefitClassFailed.type &&
      error.data.code === BENEFIT_CLASS_ALREADY_ATTACHED_TO_A_BENGUIDE
    ) {
      setShowDeleteWarnBenGuideDependency(true);
    }
    if (
      requestType === editBenefitClassFailed.type &&
      error.data.code === DUPLICATE_DATA
    ) {
      setAlertMessage('A benefit class with this name already exists.');
      setAlertVisible(true);
      setAlertType(AlertType.ERROR);
    }
    if (requestType === createBenefitClassCompleted.type) {
      setAlertMessage('Benefit Class has been successfully added');
      setAlertVisible(true);
      setAlertType(AlertType.SUCCESS);
    }
    if (requestType === editBenefitClassCompleted.type) {
      setAlertMessage('Benefit Class has been successfully edited');
      setAlertVisible(true);
      setAlertType(AlertType.SUCCESS);
    }
    if (requestType === deleteBenefitClassCompleted.type) {
      setAlertMessage('Benefit Class has been successfully removed');
      setAlertVisible(true);
      setAlertType(AlertType.SUCCESS);
    }
  }, [error, requestType]);

  const generateBenefitClassData = (benefitClasses: string[]) => {
    return benefitClasses.map((benefitClass) => ({ name: benefitClass }));
  };

  const onActionMenuClick = (event: any, benefitClass: string) => {
    if (event.key === ACTION_REMOVE) {
      setSelectedBenefitClass(benefitClass);
      toggleConfirmationDeletePopup();
    }

    if (event.key === ACTION_EDIT) {
      setSelectedBenefitClass(benefitClass);
      setIsEditMode(true);
      setIsModalOpen(true);
    }
  };

  const onChangePlanYear = (value: PlanYear) => {
    setSelectedPlanYear(value);
  };

  const getActionMenu = (benefitClass: string) => {
    return (
      <Menu onClick={(event: any) => onActionMenuClick(event, benefitClass)}>
        <Menu.Item key={ACTION_EDIT}>Edit Benefit Class</Menu.Item>
        {selectedPlanYear.benefitGroups.length > 1 && (
          <Menu.Item key={ACTION_REMOVE}>Remove Benefit Class</Menu.Item>
        )}
      </Menu>
    );
  };

  const saveBenefitClass = (benefitClass: string): void => {
    if (isEditMode && !isEmpty(selectedPlanYear.id) && employerId) {
      dispatch(
        editBenefitClass(
          employerId,
          selectedPlanYear.id,
          selectedBenefitClass,
          benefitClass
        )
      );
    } else {
      const benefitClassArray = [benefitClass];
      if (employerId) {
        dispatch(
          createBenefitClass(employerId, benefitClassArray, selectedPlanYear.id)
        );
      }
    }
  };

  const getData = (filters: TablePagination) => {
    setPaginationConfig(filters);
  };

  const closeBenefitClassModal = (): void => {
    if (addEditModalRef.current) {
      addEditModalRef.current.clearAllAlerts();
    }

    form.resetFields();
    setIsModalOpen(false);
    if (isEditMode) {
      setIsEditMode(!isEditMode);
    }
  };

  const closeFullScreenBenefitClassModal = () => {
    if (addEditModalRef.current) {
      addEditModalRef.current.clearAllAlerts();
    }
    setIsConfirmOpen(true);
  };

  const confirmDelete = () => {
    if (employerId) {
      dispatch(
        deleteBenefitClass(
          employerId,
          selectedBenefitClass,
          selectedPlanYear.id
        )
      );
    }
    toggleConfirmationDeletePopup();
  };

  const dataColumns: DataColumn[] = [
    {
      title: 'BENEFIT CLASS NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: {
        showTitle: false,
      },
      width: '90%',
      render: (benefitClass) => (
        <OverflowPopover>{benefitClass}</OverflowPopover>
      ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'name',
      key: 'actions',
      align: 'center',
      render: (benefitClass) => getRowActions(getActionMenu(benefitClass)),
    },
  ];

  const dataColumnsEmployerMember: DataColumn[] = [
    {
      title: 'BENEFIT CLASS NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: {
        showTitle: false,
      },
      width: '90%',
      render: (benefitClass) => (
        <OverflowPopover>{benefitClass}</OverflowPopover>
      ),
    },
  ];

  const handleCloseConfirm = () => {
    setIsConfirmOpen(false, () => {
      setIsModalOpen(false);
      form.resetFields();
    });
  };

  const toggleConfirmationDeletePopup = () => {
    setVisibleConfirmationDelete(!visibleConfirmationDelete);
  };

  return (
    <div className={styles.benefitClassList}>
      <div className={styles.planYearFilterContainer}>
        <div className={styles.alertWrapper}>
          {alertVisible && (
            <AlertMessage
              type={
                alertType && alertType === AlertType.ERROR ? 'error' : 'success'
              }
              message={alertMessage}
              icon={
                <img
                  src={
                    alertType && alertType === AlertType.ERROR
                      ? alertError
                      : alertSuccess
                  }
                  alt={
                    alertType && alertType === AlertType.ERROR
                      ? 'alert error icon'
                      : 'alert success icon'
                  }
                />
              }
              closeAlert={() => setAlertVisible(false)}
            />
          )}
        </div>

        <Row>
          <PlanYearDropdown
            onChange={onChangePlanYear}
            className={styles.planYearLabel}
            isDeleted={isDeleted}
            setIsPlanYearDeleted={setIsPlanYearDeleted}
          />
        </Row>
      </div>
      <DataTable
        data={generateBenefitClassData(selectedPlanYear.benefitGroups)}
        columns={
          individualSubType === IndividualSubTypes.EMPLOYER_MEMBER
            ? dataColumnsEmployerMember
            : dataColumns
        }
        pagination={true}
        getData={getData}
        total={
          !isEmpty(selectedPlanYear.benefitGroups)
            ? String(selectedPlanYear.benefitGroups.length)
            : '0'
        }
        loading={inProgress && requestType === getPlanYearsInProgress.type}
        pageSize={limit}
        clientSidePagination
        hasOutsideFilter
      />
      <FullScreenModal
        visible={isModalOpen}
        onCancel={closeFullScreenBenefitClassModal}
        footer={false}
        title={`${isEditMode ? 'Edit' : 'Add'} Benefit Class`}
      >
        <AddEditBenefitClass
          planYearsList={planYearsList}
          selectedPlanYear={selectedPlanYear}
          selectedBenefitClass={isEditMode ? selectedBenefitClass : ''}
          employerId={employerId}
          onSave={(benefitClass: string) => {
            if (isEditMode && selectedBenefitClass === benefitClass.trim()) {
              closeBenefitClassModal();
            } else {
              saveBenefitClass(benefitClass);
              closeBenefitClassModal();
            }
          }}
          loading={
            inProgress && requestType === createBenefitClassInProgress.type
          }
          form={form}
          ref={addEditModalRef}
        />
      </FullScreenModal>
      {showDeleteWarning && (
        <ConfirmationDialog
          title="Remove Benefit Class"
          confirmText="Okay"
          onConfirm={() => setShowDeleteWarning(false)}
          visible={showDeleteWarning}
          isCloseOnly={true}
        >
          <p className={styles.warningDeleteBenefitClass}>
            This Benefit Class is currently in use by a plan. Please remove it
            from all connected plans to remove this benefit class.
          </p>
        </ConfirmationDialog>
      )}
      {showDeleteWarnBenGuideDependency && (
        <ConfirmationDialog
          title="Remove Benefit Class"
          confirmText="Okay"
          onConfirm={() => setShowDeleteWarnBenGuideDependency(false)}
          visible={showDeleteWarnBenGuideDependency}
          isCloseOnly={true}
        >
          <p className={styles.warningDeleteBenefitClass}>
            This Benefit Class is currently in use by a Benefit Guide. Please
            remove it from all connected Guides to remove this benefit class.
          </p>
        </ConfirmationDialog>
      )}

      {isConfirmOpen && (
        <ConfirmationDialog
          title="Are you sure you want to close?"
          confirmText="Yes - close and do not save"
          cancelText="Cancel"
          closeModal={() => setIsConfirmOpen(false)}
          onConfirm={handleCloseConfirm}
          visible={isConfirmOpen}
        >
          <p className={styles.warningConfirmation}>{exitWithoutSavingMsg}</p>
        </ConfirmationDialog>
      )}

      {visibleConfirmationDelete && (
        <ConfirmationDialog
          title="Remove Benefit Class"
          confirmText={`Yes - Remove ${selectedBenefitClass}`}
          cancelText="Cancel"
          closeModal={toggleConfirmationDeletePopup}
          onConfirm={confirmDelete}
          visible={visibleConfirmationDelete}
        >
          <p className={styles.warningConfirmation}>
            Are you sure you want to remove?
          </p>
        </ConfirmationDialog>
      )}
    </div>
  );
};

export default BenefitClassList;
