import {
  createSlice,
  Dispatch,
  PayloadAction,
  ThunkAction,
} from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { RootState } from 'app/store';
import ProcessStatus from 'modules/renewals/enums/QuoteUploadStatus';
import UploaderType from 'modules/plans/enums/UploaderType';
import { PostSBCReviewPayload } from 'modules/plans/enums/CommonTypes';
import { QuoteMappingType } from 'modules/renewals/types/QuoteMappingType';
import { ReviewType } from 'modules/renewals/enums/ReviewType';
import {
  fetchLLMType,
  getCarrerQuoteStatus,
  redirectFailedExtractionToOps,
  uploadCarrierQuote,
  uploadCarrierQuoteFileFromRecentFiles,
  validatePlanNames,
} from 'modules/renewals/services/QuoteServices';
import { LLM_PROMPT_TYPE } from 'constants/commonConstants';

type BasicThunkAction = ThunkAction<Promise<void>, RootState, unknown, any>;

interface StatusType {
  status: ProcessStatus;
  type: ReviewType;
}

interface ValidateSuccessPayload {
  status: ProcessStatus;
  jobId: string;
  reviewType: ReviewType;
}

type QuoteDocumentReaderStateType = {
  jobId?: string;
  offerId?: string;
  status: ProcessStatus;
  errorCode?: string;
  isLoading: boolean;
  planUploderType: UploaderType;
  reviewType: ReviewType;
  isPlanLoading: boolean;
  skipFailedDocument?: boolean | undefined;
  isPlanNamesValid?: boolean;
  requestInProgress: boolean;
  isDuelLLMEnable?: boolean;
};

const initialState: QuoteDocumentReaderStateType = {
  isLoading: false,
  isPlanLoading: false,
  jobId: '',
  offerId: '',
  errorCode: undefined,
  status: ProcessStatus.MANUAL,
  reviewType: ReviewType.MANUAL,
  planUploderType: UploaderType.ADMIN_VIEW,
  skipFailedDocument: undefined,
  isPlanNamesValid: false,
  requestInProgress: false,
  isDuelLLMEnable: false,
};

const quoteReaderSlice = createSlice({
  name: 'quoteReader',
  initialState,
  reducers: {
    clear: (state) => {
      state.isLoading = false;
      state.errorCode = undefined;
      state.jobId = undefined;
      state.isLoading = false;
      state.status = ProcessStatus.MANUAL;
      state.reviewType = ReviewType.MANUAL;
      state.planUploderType = UploaderType.ADMIN_VIEW;
      state.offerId = '';
      state.isPlanNamesValid = false;
      state.requestInProgress = false;
    },
    cancel: (state) => {
      // job id is not cleared to keep the status of the job
      state.isLoading = false;
      state.errorCode = undefined;
      state.isLoading = false;
      state.status = ProcessStatus.MANUAL;
      state.reviewType = ReviewType.MANUAL;
      state.planUploderType = UploaderType.ADMIN_VIEW;
      state.offerId = '';
      state.isPlanNamesValid = false;
      state.requestInProgress = false;
    },
    uploadInProgress: (state) => {
      state.status = ProcessStatus.UPLOADING;
      state.isLoading = true;
      state.isPlanNamesValid = false;
    },
    uploadSuccess: (state, { payload }: PayloadAction<string>) => {
      state.jobId = payload;
      state.status = ProcessStatus.UPLOADED;
      state.isLoading = false;
      state.isPlanNamesValid = false;
    },
    uploadFailed: (state, { payload }: PayloadAction<string>) => {
      state.status = ProcessStatus.FAILED;
      state.errorCode = payload;
      state.isLoading = false;
      state.isPlanNamesValid = false;
    },
    validateInProgress: (state) => {
      state.status = ProcessStatus.VALIDATING;
      state.isLoading = true;
      state.isPlanNamesValid = false;
    },
    validateSuccess: (
      state,
      { payload }: PayloadAction<ValidateSuccessPayload>
    ) => {
      state.status = payload.status;
      state.jobId = payload.jobId;
      state.reviewType = payload.reviewType;
      state.isPlanNamesValid = true;

      if (
        [ProcessStatus.PROCESSING, ProcessStatus.VALIDATING].includes(
          state.status
        )
      ) {
        state.isLoading = true;
      } else {
        state.isLoading = false;
      }
    },
    validateFailed: (state, { payload }: PayloadAction<string>) => {
      state.status = ProcessStatus.UPLOADED;
      state.errorCode = payload;
      state.isLoading = false;
      state.isPlanNamesValid = false;
    },

    changePlanUploaderType: (
      state,
      { payload }: PayloadAction<UploaderType>
    ) => {
      state.planUploderType = payload;
    },
    setOPSView: (state, { payload }: PayloadAction<PostSBCReviewPayload>) => {
      state.planUploderType = payload.planUploderType;
      state.jobId = payload.jobId;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setPlanLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isPlanLoading = payload;
    },
    setOfferId: (state, { payload }: PayloadAction<string>) => {
      state.offerId = payload;
    },
    setStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        status: ProcessStatus;
        jobId?: string;
        offerId?: string;
      }>
    ) => {
      const { status, jobId, offerId } = payload;

      state.status = status;
      if (jobId) {
        state.jobId = jobId;
      }
      if (offerId) {
        state.offerId = offerId;
      }

      switch (status) {
        case ProcessStatus.UPLOADING:
          state.isPlanNamesValid = false;
          break;
        case ProcessStatus.UPLOADED:
          state.isPlanNamesValid = false;
          state.isLoading = false;
          break;
        case ProcessStatus.PROCESSING:
          state.isPlanNamesValid = true;
          state.isLoading = true;
          break;
        case ProcessStatus.VALIDATING:
          state.isLoading = true;
          break;
        default:
          state.isLoading = false;
          break;
      }
    },
    setReviewType: (state, { payload }: PayloadAction<ReviewType>) => {
      state.reviewType = payload;
    },
    setFailedPlanForManual: (state) => {
      state.errorCode = undefined;
      state.reviewType = ReviewType.AUTOMATIC;
      state.status = ProcessStatus.REVIEWED;
      state.skipFailedDocument = true;
    },
    setRequestInProgress: (state, { payload }: PayloadAction<boolean>) => {
      state.requestInProgress = payload;
    },
    setIsDuelLLMEnable: (state, { payload }: PayloadAction<boolean>) => {
      state.isDuelLLMEnable = payload;
    },
  },
});

export const {
  clear,
  cancel,
  changePlanUploaderType,
  uploadInProgress,
  uploadSuccess,
  uploadFailed,
  validateInProgress,
  validateSuccess,
  validateFailed,
  setOPSView,
  setLoading,
  setStatus,
  setReviewType,
  setPlanLoading,
  setFailedPlanForManual,
  setOfferId,
  setRequestInProgress,
  setIsDuelLLMEnable,
} = quoteReaderSlice.actions;

/**
 * Validates the Quote file with the given parameters.
 * @param file - The Quote file to validate.
 * @param employerId - The ID of the employer.
 * @param brokerId - The ID of the broker.
 * @param benefitCategory - The benefit category.
 * @param benefitKind - Optional benefit kind in Life plans.
 * @param jobId - Optional job ID of the selected file in recent files.
 */

export const uploadCarrierQuoteFile =
  (
    file: File,
    employerId: string,
    brokerId: string,
    benefitCategory: string
  ): BasicThunkAction =>
  async (dispatch) => {
    // Dispatch the validateInProgress action to indicate validation is in progress
    dispatch(uploadInProgress());

    const modifiedBenefitCategory =
      benefitCategory === 'LIFE-ADD' ? 'BASIC_LIFE' : benefitCategory;

    try {
      // Call the validateQuotefile function with the provided parameters
      const { id, reviewType } = (await uploadCarrierQuote(
        file,
        employerId,
        brokerId,
        modifiedBenefitCategory
      )) ?? { id: '', reviewType: ReviewType.MANUAL };

      // Dispatch the validateSuccess action with the returned ID
      dispatch(uploadSuccess(id));
      dispatch(setReviewType(reviewType));
    } catch (error) {
      console.error(error);

      // Dispatch the validateFailed action with the error code from the response
      dispatch(uploadFailed((error as any)?.response?.data?.code));
    }
  };

/**
 * Validates the Quote file with the given parameters.
 * @param jobId - The Quote file to validate.
 * @param planNames - plan names array
 * @param currentQuotes - current quotes
 * @param setQuoteMapping - set quote mapping
 * @param handleNext - handle next in automatic review saves the offer with shouldInitializeQuote boolean
 */

export const validateQuotePlanNames =
  (
    jobId: string,
    planNames: string[],
    currentQuotes: QuoteMappingType[],
    setQuoteMapping: Function,
    handleNext: (shouldInitialize?: boolean) => void
  ): BasicThunkAction =>
  async (dispatch) => {
    let isAllPlanValid = false;
    // Dispatch the validateInProgress action to indicate validation is in progress
    dispatch(validateInProgress());
    try {
      // Call the validateQuotefile function with the provided parameters
      const { data, status } = await validatePlanNames(jobId, planNames);

      if (status === 200 && data) {
        const { planNames, id, status, reviewType } = data;

        const updateCurrentQuoteMap = currentQuotes?.map((item: any) => {
          const matchingPlan = planNames?.find(
            (plan: any) => plan?.planName === item.planIdentifier
          );

          if (matchingPlan) {
            return { ...item, isError: isEmpty(matchingPlan?.pages) };
          }
          return item;
        });

        isAllPlanValid = updateCurrentQuoteMap?.every(
          (item: any) => !item?.isError
        );

        if (isAllPlanValid) {
          dispatch(
            validateSuccess({
              status: status,
              jobId: id,
              reviewType: reviewType,
            })
          );

          if (reviewType === ReviewType.AUTOMATIC) {
            handleNext(
              [ProcessStatus.VALIDATED, ProcessStatus.PROCESSING].includes(
                status
              )
            );
          }
        } else {
          dispatch(validateFailed(''));
        }
        setQuoteMapping(updateCurrentQuoteMap);
      }
    } catch (error) {
      console.error(error);

      // Dispatch the validateFailed action with the error code from the response
      dispatch(validateFailed((error as any)?.response?.data?.code));
    }
  };

export const uploadCarrierQuoteFileFromRecent =
  (
    employerId: string,
    brokerId: string,
    benefitCategory: string,
    jobId: string
  ): BasicThunkAction =>
  async (dispatch) => {
    // Dispatch the validateInProgress action to indicate validation is in progress
    dispatch(uploadInProgress());

    const modifiedBenefitCategory =
      benefitCategory === 'LIFE-ADD' ? 'BASIC_LIFE' : benefitCategory;

    try {
      // Call the validateDocument function with the provided parameters
      const res = await uploadCarrierQuoteFileFromRecentFiles(
        employerId,
        brokerId,
        modifiedBenefitCategory,
        jobId
      );

      // Dispatch the validateSuccess action with the returned ID
      dispatch(uploadSuccess(res?.id));
    } catch (error) {
      console.error(error);

      // Dispatch the validateFailed action with the error code from the response
      dispatch(uploadFailed((error as any)?.response?.data?.code));
    }
  };

/**
 * Fetches the job status for AI Quote uploader.
 * @param isOpsAdmin - is ops admin check
 * @param jobId - document extraction id
 */

export const fetchJobStatus =
  (
    isOpsAdmin: boolean,
    jobId?: string,
    refetchPlanDetails?: () => void
  ): BasicThunkAction =>
  async (dispatch, getState) => {
    const { status: currentStatus, requestInProgress } =
      getState().renewals.quoteReaderSlice;

    // Prevent multiple simultaneous requests
    if (requestInProgress) {
      return;
    }

    const updateStatus = (
      status: ProcessStatus,
      isOpsAdmin: boolean,
      jobId: string
    ) => {
      if (isOpsAdmin && status === ProcessStatus.FAILED) {
        return setStatus({ status: ProcessStatus.SUCCESS, jobId });
      } else if (!isOpsAdmin && status === ProcessStatus.SUCCESS) {
        return setStatus({ status: ProcessStatus.PROCESSING, jobId });
      } else {
        return setStatus({ status, jobId });
      }
    };

    const shouldRefetchPlanDetails = (
      currentStatus: ProcessStatus,
      newStatus: ProcessStatus
    ) => {
      const successStatuses = [
        ProcessStatus.SUCCESS,
        ProcessStatus.REVIEWED,
        ProcessStatus.SAVED,
      ];

      return (
        !successStatuses.includes(currentStatus) &&
        successStatuses.includes(newStatus)
      );
    };

    try {
      dispatch(setPlanLoading(true));
      dispatch(setRequestInProgress(true)); // Set the flag to true

      if (jobId) {
        const responseStatusType: StatusType = (
          await getCarrerQuoteStatus(jobId)
        )?.data;

        if (responseStatusType.status) {
          dispatch(updateStatus(responseStatusType?.status, isOpsAdmin, jobId));
        }

        if (responseStatusType.type) {
          dispatch(setReviewType(responseStatusType?.type));
        }

        if (
          shouldRefetchPlanDetails(currentStatus, responseStatusType?.status)
        ) {
          refetchPlanDetails?.();
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(setStatus({ status: ProcessStatus.FAILED }));
    } finally {
      dispatch(setPlanLoading(false));
      dispatch(setRequestInProgress(false)); // Reset the flag
    }
  };

/**
 * Sends a request to redirect a failed extraction to OPS admin.
 * @param jobId - The job ID.
 * @param offerId - The offerId.
 */

export const redirectToOps =
  (jobId: string, offerId: string): BasicThunkAction =>
  async (dispatch) => {
    // Set the status to processing
    try {
      if (jobId) {
        dispatch(setStatus({ status: ProcessStatus.PROCESSING, jobId }));
        await redirectFailedExtractionToOps(jobId!, offerId);
      }
    } catch (error) {
      console.log(error);
      dispatch(setStatus({ status: ProcessStatus.FAILED }));
    } finally {
      dispatch(clear());
    }
  };

export const getDuelLLMEnable =
  ({
    promptType,
    benefit,
  }: {
    promptType: keyof typeof LLM_PROMPT_TYPE;
    benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'BASIC_LIFE' | 'LTD' | 'STD';
  }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await fetchLLMType({
        promptType: promptType,
        benefit: benefit,
      });

      dispatch(setIsDuelLLMEnable(response?.data));
    } catch (error) {
      console.error('Error fetching LLM type:', error);
    }
  };
export default quoteReaderSlice.reducer;
