import axios from 'axios';
import { baseApi } from 'util/apiUtil';
import { BasicPlanType, AiUploaderFileType } from 'modules/plans/types/types';
import { AI_FILE_TYPE } from 'modules/plans/constants';
import { TDocumentExtractionSource } from 'modules/plans/components/AddPlanModal/AddPlanModal';
import { LLM_PROMPT_TYPE } from 'constants/commonConstants';

const BASE_AI_URL = baseApi + '/v1/document-extraction';

export const getAiExtractionCount = async (
  brokerId: string,
  employerId: String
) => {
  const queryParams = {
    'organization-id': brokerId,
    'employer-id': employerId,
  };
  try {
    const res = await axios.get(`${BASE_AI_URL}` + '/count', {
      params: queryParams,
    });
    return res.data;
  } catch (e) {}
};

export const getAiExtractionHistory = async (
  brokerId: string,
  employerId: string,
  benefit: string
) => {
  const queryParams = {
    'employer-id': employerId,
    'organization-id': brokerId,
    'benefit-kind': benefit,
  };

  try {
    const res = await axios.get(`${BASE_AI_URL}` + '/extractions', {
      params: queryParams,
    });
    return res.data;
  } catch (error) {}
};

export const initiateAiUploader = async (
  file: File,
  employerId?: string,
  brokerId?: string
) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const data = new FormData();
  data.append('document', file);
  data.append('employerId', employerId!);
  data.append('organization-id', brokerId!);

  const res = await axios.post(`${BASE_AI_URL}` + '/sbc/extract', data, config);
  return res.data;
};
export const validateSBCfile = async (
  file: File,
  employerId?: string,
  brokerId?: string,
  benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE' = 'MEDICAL',
  fileType: AiUploaderFileType = AI_FILE_TYPE.SBC,
  benefitKind?: BasicPlanType | undefined,
  docExtractionSource?: TDocumentExtractionSource | undefined
) => {
  const fileSegment = fileType?.toLowerCase().replaceAll('_', '-');
  const benefitSegment = benefit?.toLowerCase();
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const data = new FormData();
  data.append('document', file);
  data.append('employer-id', employerId!);
  data.append('employerId', employerId!);
  data.append('organization-id', brokerId!);
  data.append('benefit-category', benefit!);

  if (docExtractionSource?.source) {
    data.append('source', docExtractionSource.source);
  }
  if (docExtractionSource?.sourceOfferId) {
    data.append('offer-id', docExtractionSource.sourceOfferId);
  }
  if (docExtractionSource?.planId) {
    data.append('plan-id', docExtractionSource.planId);
  }
  if (docExtractionSource?.planName) {
    data.append('plan-name', encodeURIComponent(docExtractionSource.planName));
  }
  if (docExtractionSource?.sourceOfferName) {
    data.append(
      'offer-name',
      encodeURIComponent(docExtractionSource.sourceOfferName)
    );
  }

  if (benefit === 'LIFE' && benefitKind) {
    data.append('benefit-kind', benefitKind!);
  }

  const res = await axios.post(
    `${BASE_AI_URL}/${benefitSegment}/${fileSegment}/validate`,
    data,
    config
  );
  return res.data;
};

export const extractSBCfile = async (
  jobId?: string,
  benefit?: string,
  fileType?: AiUploaderFileType
) => {
  const fileSegment = fileType?.toLowerCase().replaceAll('_', '-');
  const benefitSegment = benefit?.toLowerCase();
  const res = await axios.put(
    `${BASE_AI_URL}/${benefitSegment}/${jobId}/${fileSegment}/extract`
  );
  return res.data;
};

export const getStatusAiSBC = (jobId: string) => {
  try {
    return axios.get(`${BASE_AI_URL}` + `/${jobId}/` + 'status');
  } catch (error) {
    throw error;
  }
};

export const deleteFromPineCone = (documentId: string) => {
  try {
    return axios.delete(
      `${BASE_AI_URL}` + '/delete-document/' + `${documentId}`
    );
  } catch (error) {}
};

export const getPDFViewURL = (jobId: string) => {
  return `${BASE_AI_URL}/${jobId}/pdf`;
};

export const updateStatus = (jobId: string) => {
  return `${BASE_AI_URL}/${jobId}/status`;
};

export const getTheZipFile = async (jobId: string) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${BASE_AI_URL}/${jobId}/zip`,
      responseType: 'arraybuffer',
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${jobId}.zip`);
    document.body.appendChild(link);

    link.click();

    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
};

export const getExtractedPlanDetails = (
  jobId: string,
  benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE' = 'MEDICAL'
) => {
  const benefitSegment = benefit?.toLowerCase();
  return axios.get(`${BASE_AI_URL}/${benefitSegment}/${jobId}/plan`);
};

export const deleteSBC = (
  jobId: string,
  benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE' = 'MEDICAL',
  fileType: AiUploaderFileType = AI_FILE_TYPE.SBC
) => {
  return axios.delete(`${BASE_AI_URL}/${jobId}`);
};

export const fetchLLMType = ({
  promptType,
  benefit,
}: {
  promptType: keyof typeof LLM_PROMPT_TYPE;
  benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'BASIC_LIFE';
}) => {
  return axios.get(`${BASE_AI_URL}/dual-llm-enabled`, {
    params: {
      'prompt-type': promptType,
      'benefit-kind': benefit,
    },
  });
};
