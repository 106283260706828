import { useParams } from 'react-router-dom';
import { Divider, Row, Select, Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import SummeryCommonTable from 'modules/renewals/components/helperComponents/SummeryCommonTable/SummeryCommonTable';
import OfferStatusTag from 'components/OfferStatusTag/OfferStatusTag';
import styles from 'modules/renewals/components/AppProposalToPlanYearComponents/MergePriorPlans/MergePriorPlans.module.less';
import { setPlan } from 'modules/renewals/slices/addProposalToPlanYearSlice';

import { OFFER_BENEFIT_TYPES } from 'modules/renewals/constants/renewalsConstants';
import {
  MERGE_PRIOR_PLANS_AUTO_SELECTION_INFO,
  MERGE_PRIOR_PLANS_WARNING,
  PLAN_OVERVIEW_URL_MAP,
} from 'modules/renewals/constants/addToPlanYearConstants';
import { getTypeIcon } from 'modules/renewals/utils/renewalsUtils';

import {
  OfferBenefitType,
  TransformedPlan,
} from 'modules/renewals/types/renewalsTypes';

import { ReactComponent as PopoutIcon } from 'assets/images/popout-icon.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon-arrow-right.svg';
import { VoluntaryPlans } from 'modules/plans/constants';

type Props = {};

const MergePriorPlans = (props: Props) => {
  const { brokerId, employerId } = useParams();
  const dispatch = useAppDispatch();
  const planData = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.plans
  );
  const isLoading = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.isLoading
  );

  const hasNoMergablePlans = Object.values(planData).every((planSet) =>
    planSet.every((plan) => isEmpty(plan.mergablePlans))
  );

  const handleSelectMergePlan = (
    benefit: keyof typeof planData,
    consideredPlanId: string,
    mergedPlanId: string
  ) => {
    planData[benefit]
      .filter(
        (plan) =>
          (benefit === 'TAX_ADVANTAGE_ACCOUNTS' ? plan?.tempId : plan?.id) ===
          consideredPlanId
      )
      .forEach((plan) => {
        const editedPlan = { ...plan };
        if (mergedPlanId === undefined) {
          editedPlan.isMerged = false;
          editedPlan.mergedPlanId = undefined;
        } else {
          editedPlan.isMerged = true;
          editedPlan.mergedPlanId = mergedPlanId;
        }
        if (editedPlan.plan) {
          delete editedPlan.plan;
          editedPlan.isReviewed = false;
        }
        dispatch(setPlan(editedPlan));
      });
  };

  const handlePlanLinkClick = (
    id: string,
    benefit: string,
    planType: string
  ) => {
    if (id !== '' && id !== undefined) {
      if (
        [
          VoluntaryPlans.VOLUNTARY_STD.value,
          VoluntaryPlans.VOLUNTARY_LTD.value,
          VoluntaryPlans.VOLUNTARY_LIFE.value,
          VoluntaryPlans.VOLUNTARY_ADD.value,
          VoluntaryPlans.VOLUNTARY_LIFE_ADD.value,
        ].includes(planType)
      ) {
        window.open(
          `/brokers/${brokerId}/employers/${employerId}/${PLAN_OVERVIEW_URL_MAP.LIFE_AND_DISABILITY}/${id}/overview`,
          '_blank',
          'noreferrer'
        );
      } else {
        window.open(
          `/brokers/${brokerId}/employers/${employerId}/${PLAN_OVERVIEW_URL_MAP[benefit]}/${id}/overview`,
          '_blank',
          'noreferrer'
        );
      }
    }
  };

  const tableColumns: ColumnsType<TransformedPlan> = [
    {
      title: 'Proposal Plans',
      key: 'planName',
      dataIndex: 'planName',
      width: '60%',
      render: (_, record) => (
        <Row justify="space-between" align="middle">
          <Row align="middle">
            <b>{record.planName}</b>
            {/* TODO: Future development */}
            {false && <OfferStatusTag offerStatus="NEW_PLAN" />}
          </Row>
          <div>
            <ArrowRightIcon />
          </div>
        </Row>
      ),
    },
    {
      title: (
        <Row align="middle">
          Merge with existing plans
          <PlanyearPopover
            width={450}
            content={MERGE_PRIOR_PLANS_AUTO_SELECTION_INFO}
          >
            <QuestionCircleOutlined className={styles.popoverIcon} />
          </PlanyearPopover>
        </Row>
      ),
      key: 'mergablePlans',
      dataIndex: 'mergablePlans',
      width: '40%',
      render: (
        mergablePlans,
        { id, mergedPlanId, benefit, isMerged, planType, tempId, category }
      ) => (
        <div className={styles.mergeSelectWrapper}>
          <Select
            allowClear
            size="small"
            value={mergedPlanId}
            className={styles.fundingTypeSelect}
            onChange={(selectedId) =>
              handleSelectMergePlan(
                benefit,
                benefit === 'TAX_ADVANTAGE_ACCOUNTS' ? tempId ?? '' : id,
                selectedId
              )
            }
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {mergablePlans
              .filter((plan: any) => {
                return (
                  ((category === 'DENTAL' || category === 'VISION') &&
                    plan.benefitKind !== 'HSA') ||
                  (category === 'MEDICAL' && planType === plan.benefitKind) ||
                  benefit !== 'TAX_ADVANTAGE_ACCOUNTS'
                );
              })
              .map((plan: any) => {
                return (
                  <Select.Option key={plan.value} value={plan.value}>
                    {plan.label}
                  </Select.Option>
                );
              })}
          </Select>
          <PopoutIcon
            className={isMerged ? styles.linkIcon : styles.disabledLinkIcon}
            onClick={() =>
              handlePlanLinkClick(mergedPlanId, benefit, planType || '')
            }
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div>
        <div className={styles.headerTextTop}>Merge with Current Plans</div>
        <p>
          In this flow, mapped current plan information will be used to populate
          any details (E.g. Plan Services, Rx Information) that are not
          comprehensively given in the proposal plans.
        </p>
        <FixedAlertMessage type="warning" message={MERGE_PRIOR_PLANS_WARNING} />
        <Divider />
      </div>
      <div className={styles.tableSectionWrapper}>
        {isLoading && (
          <div className={styles.loadingOverlay}>
            <Spin />
          </div>
        )}
        {!isLoading && hasNoMergablePlans && (
          <div className={styles.loadingOverlay}>
            {`The employer doesn't have any plans in the current year to merge
            with proposal plans`}
          </div>
        )}
        {Object.entries(planData).map(([key, value]) => (
          <div key={key} className={styles.tableComponentWrapper}>
            <Row align="middle">
              <img
                className={styles.headerIcon}
                src={
                  getTypeIcon(OFFER_BENEFIT_TYPES[key as OfferBenefitType]).icon
                }
              />
              <span className={styles.headerText}>
                {OFFER_BENEFIT_TYPES[key as OfferBenefitType]}
              </span>
            </Row>
            <SummeryCommonTable
              data={value}
              columns={tableColumns}
              emptyMessage={
                <span className={styles.tableEmptyText}>
                  There are no plans for this benefit type
                </span>
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MergePriorPlans;
