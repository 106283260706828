import { ChangeEvent, FC, useEffect, useState } from 'react';

import { Col, Collapse, Menu, MenuProps, Row, Typography } from 'antd';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CheckboxSelectGroup from 'components/CheckboxSelectGroup/CheckboxSelectGroup';
import CheckboxSelect from 'components/CheckboxSelect/CheckboxSelect';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import SearchBar from 'components/SearchBar/SearchBar';
import Option from 'model/Option';
import { checkboxGroupMode, multiSelectTypes } from 'constants/commonConstants';
import { ReactComponent as FilterOption } from 'assets/images/icon-funnel.svg';
import styles from './multiSelectDropdown.module.less';

const { Panel } = Collapse;
const defaultKey = 'default';

interface MultiSelectDropdownProps extends MenuProps {
  type?: string;
  allOption?: string;
  deSelectOption?: string;
  showArrow?: boolean;
  description?: string;
  selected: string[];
  options: Option[];
  updateSelected: (
    selectedOptions: string[],
    allOptionsSelected: boolean
  ) => void;
  allOptionsSelected: boolean;
  resetSelected?: () => void;
  displayAllOption?: boolean;
  allSelectedPanelLabel?: string;
  disabledDropDown?: boolean;
  disableAllOption?: boolean;
}

const MultiSelectDropdown: FC<MultiSelectDropdownProps> = (props) => {
  const {
    type,
    mode,
    selected,
    options,
    allOption,
    deSelectOption,
    showArrow = true,
    description,
    updateSelected,
    allOptionsSelected,
    displayAllOption = true,
    allSelectedPanelLabel = 'All',
    disabledDropDown = false,
    disableAllOption = false,
    ...rest
  } = props;

  const [activePanel, setActivePanel] = useState<string>(defaultKey);
  const [allSelected, setAllSelected] = useState<boolean>(allOptionsSelected);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [searchBarSelected, setSearchBarSelected] = useState<boolean>(false);
  const [searchOptions, setSearchOptions] = useState<Option[]>([]);

  useEffect(() => {
    setSelectedOptions(selected);
    setAllSelected(allOptionsSelected);
  }, [selected, allOptionsSelected]);

  const onSelect = (checkedValue: any[]) => {
    if (type === multiSelectTypes.FILTER) {
      setAllSelected(checkedValue.length === options.length);
    }

    if (!searchBarSelected) {
      setSelectedOptions(checkedValue);
    } else {
      const temp = getValues(searchOptions);
      const filteredSelectedOption = selectedOptions.filter((selected) => {
        return !temp.includes(selected);
      });
      setSelectedOptions([...filteredSelectedOption, ...checkedValue]);
    }
  };

  const updateSelectedOptions = () => {
    setActivePanel(defaultKey);
    updateSelected(selectedOptions, allSelected);
  };

  const resetSelectedOptions = () => {
    setActivePanel(defaultKey);
    setSelectedOptions(selected);
    setAllSelected(selected.length === options.length && allOptionsSelected);
  };

  const showSelected = () => {
    const selectedLabels = getLabels(selected);

    if (
      selectedLabels.length > 0 &&
      (!allSelected ||
        (type === multiSelectTypes.FILTER &&
          selected.length !== options.length))
    ) {
      return selectedLabels.join(', ');
    } else if (allSelected) {
      return allSelectedPanelLabel;
    } else {
      if (type === multiSelectTypes.FILTER) {
        return allSelectedPanelLabel;
      }
      return;
    }
  };

  const getLabels = (selected: string[]) => {
    return options
      .filter((option) => selected.includes(option.value))
      .map((option) => option.label);
  };

  const getValues = (selected: Option[]) => {
    return selected.map((item) => item.value);
  };

  const selectAll = (e: any) => {
    if (e.target.checked) {
      setSelectedOptions(getValues(options));
    } else {
      setSelectedOptions([]);
    }
    setAllSelected(e.target.checked);
  };

  const collapseOnChange = (key: any) => {
    setActivePanel(key);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = e.target;
    const searchText = value.toLowerCase().trim();
    const filteredLocations = options.filter((locations) => {
      const location = locations.label.toLowerCase();
      return location.includes(searchText);
    });

    if (searchText !== '') {
      setSearchBarSelected(true);
    } else {
      setSearchBarSelected(false);
    }

    setSearchOptions(filteredLocations);
  };

  const customOverlay = () => {
    const { Text } = Typography;
    if (type === multiSelectTypes.FILTER) {
      return (
        <>
          <div className={styles.searchFilter}>
            <SearchBar
              placeholder={'Search Locations'}
              onChange={handleSearch}
              isLarge={false}
            />
          </div>
          <Menu mode={mode} {...rest}>
            {displayAllOption && !searchBarSelected && (
              <CheckboxSelect
                checked={allSelected}
                onChange={selectAll}
                className={styles.filterAllCheckbox}
              >
                {allSelected ? deSelectOption : allOption}
              </CheckboxSelect>
            )}
            <CheckboxSelectGroup
              options={!searchBarSelected ? options : searchOptions}
              onChange={onSelect}
              value={selectedOptions}
              className={styles.filterGroupWrapper}
              mode={mode}
            />
          </Menu>
          <SubmitButton
            type="primary"
            onClick={updateSelectedOptions}
            className={styles.filterConfirmButton}
          >
            Okay
          </SubmitButton>
          <PageActionButton
            className={styles.filterConfirmButton}
            onClick={resetSelectedOptions}
          >
            Cancel
          </PageActionButton>
        </>
      );
    }

    if (type === multiSelectTypes.ALL_SELECTABLE) {
      return (
        <>
          {displayAllOption && (
            <Menu mode={mode} className={styles.allSelectOption} {...rest}>
              <Menu.Item>
                <Row>
                  <CheckboxSelect
                    checked={allSelected}
                    onChange={selectAll}
                    disabled={disableAllOption}
                  >
                    {
                      <div
                        className={
                          mode === checkboxGroupMode.VERTICAL
                            ? styles.vertical
                            : styles.horizontal
                        }
                      >
                        <div>{allOption}</div>
                        <Text type="secondary">{description}</Text>
                      </div>
                    }
                  </CheckboxSelect>
                </Row>
              </Menu.Item>
            </Menu>
          )}
          <div
            className={
              mode === checkboxGroupMode.VERTICAL
                ? styles.verticalCheckboxWrapper
                : styles.horizontalCheckboxWrapper
            }
          >
            <CheckboxSelectGroup
              options={options}
              onChange={onSelect}
              value={selectedOptions}
              disabled={allSelected}
              mode={mode}
            />
          </div>
          <Row>
            <Col
              span={4}
              className={
                mode === checkboxGroupMode.VERTICAL
                  ? styles.verticalMenu
                  : styles.menuCol
              }
            >
              <PageActionButton
                className={styles.cancelButton}
                onClick={resetSelectedOptions}
              >
                Cancel
              </PageActionButton>
            </Col>
            <Col className={styles.menuCol}>
              <SubmitButton
                type="primary"
                onClick={updateSelectedOptions}
                className={styles.confirmButton}
              >
                Ok
              </SubmitButton>
            </Col>
          </Row>
        </>
      );
    }
  };

  return (
    <div
      className={
        type === multiSelectTypes.FILTER
          ? styles.filterWrapper
          : styles.multiSelectWrapper
      }
    >
      <Collapse
        activeKey={activePanel}
        bordered={false}
        defaultActiveKey={defaultKey}
        onChange={collapseOnChange}
        expandIconPosition="right"
        accordion
      >
        <Panel
          header={
            <>
              <OverflowPopover>{showSelected()}</OverflowPopover>
              <span className={styles.filterIcon}>
                {!showArrow && <FilterOption />}
              </span>
            </>
          }
          key="select"
          showArrow={showArrow}
          className={styles.panelWrapper}
          disabled={disabledDropDown}
        >
          {customOverlay()}
        </Panel>
      </Collapse>
    </div>
  );
};

export default MultiSelectDropdown;
