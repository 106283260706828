import {
  ReactNode,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Col, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty, last } from 'lodash';
import { useSubscription } from 'react-stomp-hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  setPromptType,
  setSelectionType,
  appendConversation,
  setConversationDetails,
} from 'modules/assistant/slices/conversationSlice';
import RenderSelectionType from 'modules/assistant/enums/RenderSelectionType';
import PromptType from 'modules/assistant/enums/PromptRenderType';
import TextButton from 'components/buttons/TextButton/TextButton';
import SelectionCard, {
  SelectionCardInterface as SelectionCardType,
} from 'modules/assistant/components/SelectionCard/SelectionCard';
import AssistantInput from 'modules/assistant/components/AssistantInput/AssistantInput';
import PlanAssistanceModal, {
  PlanAssistanceModalProps,
} from 'modules/assistant/components/PlanAssistanceModal/PlanAssistanceModal';

import { receivingURL } from 'modules/assistant/services/ConversationService';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import {
  ASSISTANT_INITIALIZE_WARNING,
  SELECTION_COMPLIANCE_ASSISTANT_TEXT,
  SELECTION_GENERAL_ASSISTANT_TEXT,
  SELECTION_PLAN_ASSISTANCE_TEXT,
  PLAN_HEADER_TEXT,
  COMPLIANCE_HEADER_TEXT,
  GENERAL_HEADER_TEXT,
  HELP_HEADER_TEXT,
  GENERAL_ASSISTANT_TEXT,
  COMPLIANCE_ASSISTANT_TEXT,
  PLAN_ASSISTANT_TEXT,
  ASSISTANT_FAILED_WARNING,
  ASSISTANT_FAILED_WARNING_LINK,
  ASSISTANT_FAILED_WARNING_SUB,
  DOCUMENT_NOT_FOUND_ERROR,
  ASSISTANT_COMMON_ERROR,
} from 'modules/assistant/constants/constants';
import { EMPLOYER_NOT_FOUND } from 'constants/commonConstants';
import { Message } from 'modules/assistant/models/Message';
import { createNewChat } from 'modules/assistant/utils/commonUtils';
import { ReactComponent as ComplianceIcon } from 'assets/images/icon-compliance.svg';
import { ReactComponent as PlansIcon } from 'assets/images/icon-plan-assistance.svg';
import { ReactComponent as GeneralIcon } from 'assets/images/icon-general-questions.svg';
import { ReactComponent as EditIcon } from 'assets/images/icon-edit-assistant.svg';

import styles from './conversationLayout.module.less';

interface ConversationLayoutProps {
  conversationId: string | undefined;
  children: ReactNode | null;
  selectionType: RenderSelectionType;
  type: PromptType;
  isLoading: boolean;
  error?: boolean;
}

const getHeaderAndIcon = (type: PromptType) => {
  switch (type) {
    case PromptType.PLANS:
      return { header: PLAN_ASSISTANT_TEXT, icon: <PlansIcon /> };
    case PromptType.COMPLIANCE:
      return { header: COMPLIANCE_ASSISTANT_TEXT, icon: <ComplianceIcon /> };
    case PromptType.GENERAL_BENEFITS:
      return { header: GENERAL_ASSISTANT_TEXT, icon: <GeneralIcon /> };
    default:
      return { header: '', icon: null };
  }
};

const renderWarning = (
  error: boolean,
  navigateToNewChat: () => void,
  errorCode: string | undefined | null
) => (
  <>
    {error && (
      <Row align="middle" justify="center">
        <Col xs={24} sm={18} md={14} lg={14} xl={14}>
          <Row>
            <FixedAlertMessage
              message={
                <p>
                  {DOCUMENT_NOT_FOUND_ERROR === errorCode ||
                  errorCode === EMPLOYER_NOT_FOUND ? (
                    <>
                      {ASSISTANT_FAILED_WARNING}
                      <text
                        className={styles.linkText}
                        onClick={navigateToNewChat}
                      >
                        {ASSISTANT_FAILED_WARNING_LINK}
                      </text>
                      {ASSISTANT_FAILED_WARNING_SUB}
                    </>
                  ) : (
                    ASSISTANT_COMMON_ERROR
                  )}
                </p>
              }
              type="error"
            />
          </Row>
        </Col>
      </Row>
    )}
  </>
);

const HeaderComponent = ({ type }: { type: PromptType }) => {
  const { header, icon } = useMemo(() => getHeaderAndIcon(type), [type]);
  return (
    <Row align="middle" justify="center">
      <Col>
        <Row align="middle" justify="center">
          {icon}
        </Row>
        <Row align="middle" justify="center">
          <h1 className={styles.headerText}>{header}</h1>
        </Row>
      </Col>
    </Row>
  );
};

const SelectionCards = ({ cards }: { cards: SelectionCardType[] }) => (
  <>
    {cards.map((item, index) => (
      <SelectionCard key={index} {...item} />
    ))}
  </>
);

const ConversationLayout = forwardRef<any, ConversationLayoutProps>(
  (
    {
      conversationId,
      children,
      selectionType,
      type: promptType,
      isLoading,
      error,
    },
    ref
  ) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { brokerId = '', chatId } = useParams();
    const { messages, error: errorCode } =
      useAppSelector((state) => state.assistant.current) ?? {};
    const lastMessage = last(messages ?? []) as Message;
    const scrollRef = useRef<HTMLDivElement>(null);
    const [prevScrollTop, setPrevScrollTop] = useState<number>(0);
    const [isPlanAssistanceModalOpen, setIsPlanAssistanceModalOpen] =
      useState(false);
    const [scrollingInterrupted, setScrollingInterrupted] =
      useState<boolean>(false);

    const handleSelectionCardClick = (type: PromptType) => {
      dispatch(setPromptType(type));
      dispatch(setSelectionType(RenderSelectionType.START));
    };

    const handleNewChatClick = useCallback(() => {
      dispatch(createNewChat(navigate, brokerId));
    }, [dispatch, navigate, brokerId]);

    const selectionCardArr: SelectionCardType[] = [
      {
        title: PLAN_HEADER_TEXT,
        description: SELECTION_PLAN_ASSISTANCE_TEXT,
        icon: <PlansIcon />,
        type: PromptType.PLANS,
        onClick: () => setIsPlanAssistanceModalOpen(true),
      },
      {
        title: GENERAL_HEADER_TEXT,
        description: SELECTION_GENERAL_ASSISTANT_TEXT,
        icon: <GeneralIcon />,
        type: PromptType.GENERAL_BENEFITS,
        onClick: () => handleSelectionCardClick(PromptType.GENERAL_BENEFITS),
      },
      {
        title: COMPLIANCE_HEADER_TEXT,
        description: SELECTION_COMPLIANCE_ASSISTANT_TEXT,
        icon: <ComplianceIcon />,
        type: PromptType.COMPLIANCE,
        onClick: () => handleSelectionCardClick(PromptType.COMPLIANCE),
      },
    ];

    useSubscription(receivingURL(conversationId!), (message) => {
      const content = JSON.parse(message.body);
      dispatch(
        appendConversation({
          answer: content?.message,
          id: content?.messageId,
          conversationId: content.conversationId,
          error: !isEmpty(content.error) || isEmpty(content.message),
          errorResponse: content.error,
        })
      );
    });

    const navigateToNewChat = () => {
      dispatch(createNewChat(navigate, brokerId!));
    };

    const handleScroll = useCallback(
      (e: React.UIEvent<HTMLElement>) => {
        const element = e.target as HTMLDivElement;
        const currentScrollTop = element.scrollTop;
        const maxScrollTop = element.scrollHeight - element.clientHeight;

        if (currentScrollTop >= maxScrollTop) {
          setScrollingInterrupted(false);
        } else if (Math.abs(currentScrollTop - prevScrollTop) >= 1) {
          if (currentScrollTop < prevScrollTop) {
            setScrollingInterrupted(true);
          }
        } else {
          setScrollingInterrupted(false);
        }
        setPrevScrollTop(currentScrollTop);
      },
      [prevScrollTop]
    );

    const renderContent = () => {
      switch (selectionType) {
        case RenderSelectionType.SELECTION:
          return (
            <div className={styles.flexCenterContainer}>
              <div className={styles.flexColumnContainer}>
                <h1 className={styles.headerText}>{HELP_HEADER_TEXT}</h1>
                <div className={styles.flexCenterWrapper}>
                  <SelectionCards cards={selectionCardArr} />
                </div>
              </div>
            </div>
          );
        case RenderSelectionType.START:
          return (
            <div className={styles.flexCenterContainer}>
              <Col>
                <HeaderComponent type={promptType} />
              </Col>
            </div>
          );
        case RenderSelectionType.COMPLETED:
          return children || null;
        default:
          return null;
      }
    };

    const renderInputFooter = useMemo(() => {
      switch (selectionType) {
        case RenderSelectionType.START:
        case RenderSelectionType.COMPLETED:
          return (
            <Col span={24}>
              <AssistantInput scrollRef={scrollRef} />
              <Row align="middle" justify="center" className={styles.footerRow}>
                <Col className={styles.warningWrapper} span={24}>
                  <p className={styles.warningText}>
                    {ASSISTANT_INITIALIZE_WARNING}
                  </p>
                </Col>
                <Col>
                  <TextButton
                    className={styles.newChatButton}
                    label="New Chat"
                    type="primary"
                    icon={<EditIcon />}
                    onClick={handleNewChatClick}
                  />
                </Col>
              </Row>
            </Col>
          );
        default:
          return null;
      }
    }, [handleNewChatClick, selectionType]);

    const scrollIntoView = useCallback(
      (arg?: any) => {
        if (scrollRef.current && !scrollingInterrupted) {
          scrollRef.current.scrollIntoView(arg);
        }
      },
      [scrollRef, scrollingInterrupted]
    );

    useImperativeHandle(
      ref,
      () => ({
        scrollToBottom: scrollIntoView,
      }),
      [scrollIntoView]
    );

    useLayoutEffect(() => {
      setScrollingInterrupted(false);
      scrollIntoView();

      // Disable exhaustive-deps rule as we need to trigger this effect when the lastMessage changes
      // Resting the scroll position is necessary when the last message is pending

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatId, conversationId, scrollRef, lastMessage?.isPending]);

    const handlePlanAssistanceModalConfirm: PlanAssistanceModalProps['onConfirm'] =
      ({ documentId, documentName, employerName }) => {
        dispatch(
          setConversationDetails({
            type: PromptType.PLANS,
            documentId: documentId!,
            documentName: documentName!,
            employerName: employerName!,
            conversationId: conversationId!,
            createdTs: '',
            employerId: '',
            messages: [],
          })
        );
        handleSelectionCardClick(PromptType.PLANS);
        setIsPlanAssistanceModalOpen(false);
      };

    return (
      <>
        <PlanAssistanceModal
          organizationId={brokerId}
          isOpen={isPlanAssistanceModalOpen}
          onCancel={() => setIsPlanAssistanceModalOpen(false)}
          onConfirm={handlePlanAssistanceModalConfirm}
        />
        <div className={styles.container}>
          <div onScroll={handleScroll} className={styles.scrollableContainer}>
            {renderContent()}
            {renderWarning(error ?? false, navigateToNewChat, errorCode)}
            <div className={styles.hiddenDiv} ref={scrollRef} />
          </div>
          <Row className={styles.chatFooterWrapper}>{renderInputFooter}</Row>
        </div>
      </>
    );
  }
);

ConversationLayout.displayName = 'ConversationLayout';

export default ConversationLayout;
