import React, { FC } from 'react';
import { InputNumber as AntDInputNumber, InputNumberProps } from 'antd';
import styles from './inputNumber.module.less';

interface IInputNumber extends InputNumberProps {
  className?: string;
}

const InputNumber: FC<IInputNumber> = (props) => {
  const { className = '', ...rest } = props;
  return (
    <AntDInputNumber
      className={`${styles.inputNumber} ${className}`}
      {...rest}
    />
  );
};

export default InputNumber;
