import { FC, useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { Link } from 'react-router-dom';

import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import InputForm from 'components/InputForm/InputForm';
import AuthContainer from 'containers/AuthContainer/AuthContainer';
import AuthHeading from 'modules/auth/components/AuthHeading/AuthHeading';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  requestNewPassword,
  requestPwdStarted,
  requestPwdSuccess,
  resetRequestPwd,
} from 'modules/auth/slices/resetPwdSlice';
import { RESET_ALERT_MESSAGE } from 'modules/auth/constants/authConstants';
import { LOGIN_PATH } from 'modules/auth/routes';
import { isValidEmailFormat } from 'util/commonUtil';

import styles from './resetPassword.module.less';

const ResetPassword: FC = () => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    type: 'info',
    message: '',
  });
  const [isRequestSent, setIsRequestSent] = useState(false);

  const dispatch = useAppDispatch();
  const resetPwdState = useAppSelector((state) => state.auth.resetPwd);

  useEffect(() => {
    if (
      resetPwdState.actionType === requestPwdSuccess.type &&
      !resetPwdState.inProgress
    ) {
      setIsRequestSent(true);
      setIsAlertVisible(true);
      setAlertInfo({
        type: 'info',
        message: RESET_ALERT_MESSAGE,
      });
    }
  }, [resetPwdState.actionType, resetPwdState.inProgress, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetRequestPwd());
    };
  }, [dispatch]);

  const handleRequest = (formValues: any) => {
    const { email = '' } = formValues;
    if (email.trim() === '') {
      setIsAlertVisible(true);
      setAlertInfo({
        type: 'error',
        message: 'Please enter an email address.',
      });
    } else if (email) {
      if (!isValidEmailFormat(email.trim())) {
        setIsAlertVisible(true);
        setAlertInfo({
          type: 'error',
          message:
            'Resetting password failed. Please correct all highlighted errors and try again.',
        });
      } else {
        setIsAlertVisible(false);
        dispatch(requestNewPassword(email.trim()));
      }
    }
  };

  return (
    <AuthContainer>
      <div className={styles.container}>
        {isAlertVisible && (
          <AlertMessage
            type={alertInfo.type}
            message={alertInfo.message}
            closeAlert={() => setIsAlertVisible(false)}
            wrapperClassName="authAlertWrapper"
          />
        )}
        <AuthHeading header="Forgot Password" />
        <InputForm layout="vertical" onFinish={handleRequest}>
          <Form.Item
            label="Enter your account Email Address"
            name="email"
            validateStatus={
              isAlertVisible && alertInfo.type === 'error'
                ? 'error'
                : 'validating'
            }
          >
            <Input disabled={isRequestSent} />
          </Form.Item>
          <SubmitButton
            type="primary"
            htmlType="submit"
            loading={
              resetPwdState.inProgress &&
              resetPwdState.actionType === requestPwdStarted.type
            }
            disabled={isRequestSent}
          >
            Request New Password
          </SubmitButton>
          <CancelButton>
            <div className={styles.cancelButton}>
              <Link
                to={LOGIN_PATH}
                className={styles.cancelButtonText}
                state={{ previousPath: location.pathname }}
              >
                <a> {isRequestSent ? 'Back to Log In' : 'Cancel'}</a>
              </Link>
            </div>
          </CancelButton>
        </InputForm>
      </div>
    </AuthContainer>
  );
};

export default ResetPassword;
