import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Col, Row, Spin } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import SearchBar from 'components/SearchBar/SearchBar';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import NextButton from 'components/buttons/NextButton/NextButton';
import {
  DRAFTS_TAB,
  LIFE,
  LTD,
  OFFER_BENEFIT_TYPE_LABELS,
  OFFER_CATEGORY,
  OFFER_STATUS,
  OFFER_TYPES,
  ONLY_CREDITS_INCLUSION,
  PROPOSAL_CARD_TAGS,
  PROPOSAL_OFFER_SELECT_SECTION,
  STD,
} from 'modules/renewals/constants/renewalsConstants';
import { removeSpecificItemFromStringArray } from 'util/arrayUtil';
import ProposalCreateSelectOfferCard from 'modules/renewals/components/ProposalCreateSelectOfferCard/ProposalCreateSelectOfferCard';
import CreditIncludedExcludedModal from 'modules/renewals/components/CreditIncludedExcludedModal/CreditIncludedExcludedModal';
import { useLazyGetOffersByEffectiveDateQuery } from 'modules/renewals/slices/offersSlice';
import {
  useProposalStatusUpdateMutation,
  useUpdateProposalMutation,
} from 'modules/renewals/slices/proposalSlice';

import {
  attachProposal,
  setCompletedProposalSteps,
} from 'modules/renewals/slices/proposalCreateStepperSlice';
import NoOffersScreen from 'modules/renewals/components/helperComponents/NoOffersScreen/NoOffersScreen';
import TextButton from 'components/buttons/TextButton/TextButton';

import {
  addProposalCompletedSteps,
  checkIfSelectedOfferNamesDuplicated,
  getSelectedOffersBasedOnTheType,
} from 'modules/renewals/utils/renewalsUtils';
import { setAntDModalScrollToTop } from 'util/domUtils';
import { loginTypes } from 'constants/authConstants';

import styles from './offersContainerWrapper.module.less';

interface IOffersContainerProps {
  type: any;
  handleStepperComplete: (
    key: number,
    isFullyComplete: boolean,
    isNextClick: boolean
  ) => void;
  nextStep?: () => void;
  onPreviousStep?: () => void;
  currentStep?: number;
  closeModal: (
    notShowConfirmMessage: any,
    proposalStatus?: string | undefined
  ) => void;
  handleNavigation: () => void;
  isProposalDetailedView?: boolean;
}

const OffersContainerWrapper = (
  {
    type,
    // callback function to trigger the stepper
    handleStepperComplete,
    nextStep,
    currentStep,
    onPreviousStep,
    closeModal,
    isProposalDetailedView,
  }: IOffersContainerProps,
  ref: any
) => {
  const changeStateToDraft = () => {
    navigate(
      `/brokers/${brokerId}/employers/${employerId}/renewals/proposals`,
      { state: { proposalStatus: DRAFTS_TAB } }
    );
  };
  const params = useParams();

  const [creditType, setCreditType] = useState<string>(
    ONLY_CREDITS_INCLUSION.EXCLUDED.value
  );
  const [isNextClicked, setIsNextClicked] = useState<boolean | null>(null);
  const [isSaveDraft, setIsSaveDraft] = useState(false);

  const [selectedOffers, setSelectedOffers] = useState<string[]>([]);
  const [selectedOffersMedicalPrimary, setSelectedOffersMedicalPrimary] =
    useState<string>();
  const [selectedOffersDentalPrimary, setSelectedOffersDentalPrimary] =
    useState<string>();
  const [selectedOffersVisionPrimary, setSelectedOffersVisionPrimary] =
    useState<string>();
  const [searchText, setSearchText] = useState<string>('');

  const [showCreditModal, setShowCreditModal] = useState({
    visible: false,
    type: '',
  });
  const { employerId, brokerId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const proposalDetails = useAppSelector(
    (state) => state.proposalOnBoarding.proposal
  );
  const completedProposalSteps = useAppSelector(
    (state) => state.proposalOnBoarding.completedProposalSteps
  );

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;

  const [
    draftProposal,
    { isLoading: changeStatusIsLoading, isSuccess: changeStatusIsSuccess },
  ] = useProposalStatusUpdateMutation();

  const [
    getOffersByEffectiveDate,
    {
      isSuccess: isGetOffersByEffectiveDateSuccess,
      isLoading: isGetOffersByEffectiveDateLoading,
      isFetching: isGetOffersByEffectiveDateFetching,
      data: offers,
    },
  ] = useLazyGetOffersByEffectiveDateQuery();

  const [
    updateProposal,
    { isLoading: isProposalUpdateLoading, isSuccess: isProposalUpdateSuccess },
  ] = useUpdateProposalMutation();

  useEffect(() => {
    setSearchText('');
    setIsNextClicked(null);
    getOffersByEffectiveDate({
      employerId: String(employerId),
      category: type,
      upcomingPlanYearId: proposalDetails?.payload?.effectiveDateId,
    });
    dispatch(
      setCompletedProposalSteps(
        addProposalCompletedSteps(
          completedProposalSteps,
          `${PROPOSAL_OFFER_SELECT_SECTION}_${type}`
        )
      )
    );
    if (proposalDetails) {
      setSelectedOffersMedicalPrimary(
        proposalDetails?.payload?.primaryMedicalOffer
      );
      setSelectedOffersDentalPrimary(
        proposalDetails?.payload?.primaryDentalOffer
      );
      setSelectedOffersVisionPrimary(
        proposalDetails?.payload?.primaryVisionOffer
      );
      setSelectedOffers(
        getSelectedOffersBasedOnTheType(proposalDetails?.payload, type)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, employerId]);

  useEffect(() => {
    if (isNextClicked !== null && isProposalUpdateSuccess) {
      const proposal = { ...proposalDetails?.payload };
      const key: number = getTheApplicableStepperKey();
      let selectedOffers = getSelectedOffersBasedOnTheType(
        proposalDetails?.payload,
        type
      );
      if (type === OFFER_CATEGORY.MEDICAL && proposal?.primaryMedicalOffer) {
        selectedOffers = [...selectedOffers, proposal?.primaryMedicalOffer];
      } else if (
        type === OFFER_CATEGORY.DENTAL &&
        proposal?.primaryDentalOffer
      ) {
        selectedOffers = [...selectedOffers, proposal?.primaryDentalOffer];
      } else if (
        type === OFFER_CATEGORY.VISION &&
        proposal?.primaryVisionOffer
      ) {
        selectedOffers = [...selectedOffers, proposal?.primaryVisionOffer];
      }
      handleStepperComplete(key, !isEmpty(selectedOffers), isNextClicked);
      dispatch(
        setCompletedProposalSteps(
          addProposalCompletedSteps(
            completedProposalSteps,
            `${PROPOSAL_OFFER_SELECT_SECTION}_${type}`
          )
        )
      );
      if (isNextClicked) {
        if (currentStep === 4 && nextStep) {
          nextStep();
        }
      } else {
        if (key === 0 && onPreviousStep) {
          onPreviousStep();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProposalUpdateSuccess]);

  useEffect(() => {
    if (isNextClicked !== null) {
      let proposal = { ...proposalDetails?.payload };
      proposal = setOfferIdBasedOnTheType(proposal);
      updateProposal({
        proposalId: proposalDetails?.payload?.id,
        proposal: proposal,
      });
      dispatch(attachProposal(proposal));
      setAntDModalScrollToTop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNextClicked]);

  useEffect(() => {
    if (changeStatusIsSuccess && isSaveDraft) {
      if ((params?.proposalId ?? null) !== null) {
        navigate(
          `/brokers/${brokerId}/employers/${employerId}/renewals/proposals/details/${proposalDetails?.payload?.id}`,
          { state: { proposalStatus: DRAFTS_TAB } }
        );
      }
      closeModal(true, OFFER_STATUS.DRAFT);
      setIsSaveDraft(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeStatusIsSuccess, isSaveDraft]);

  const filterOffers = useMemo(() => {
    return offers?.filter((offer: { name: string }) =>
      offer.name.toLowerCase().includes(searchText.toLowerCase().trim())
    );
  }, [offers, searchText]);

  const handelSelectedOffer = ({
    id,
    offerType,
  }: {
    id: string;
    offerType?: string | null;
  }) => {
    switch (type) {
      case OFFER_CATEGORY.MEDICAL: {
        if (offerType === OFFER_TYPES.PRIMARY) {
          if (selectedOffersMedicalPrimary === id) {
            setSelectedOffersMedicalPrimary(undefined);
          } else {
            setSelectedOffersMedicalPrimary(id);
          }
        } else {
          if (selectedOffers?.includes(id)) {
            setSelectedOffers(
              removeSpecificItemFromStringArray(selectedOffers, id)
            );
          } else {
            setSelectedOffers((pre) => [...pre, id]);
          }
        }

        return;
      }
      case OFFER_CATEGORY.DENTAL: {
        setOffers(id, offerType, OFFER_CATEGORY.DENTAL);
        return;
      }
      case OFFER_CATEGORY.VISION: {
        setOffers(id, offerType, OFFER_CATEGORY.VISION);
        return;
      }
      default: {
        if (selectedOffers.includes(id)) {
          setSelectedOffers(
            removeSpecificItemFromStringArray(selectedOffers, id)
          );
        } else {
          setSelectedOffers((pre) => [...pre, id]);
        }
        return;
      }
    }
  };

  const setOffers = (id: any, offerType: any, offerCategory: any) => {
    if (offerCategory === OFFER_CATEGORY.DENTAL) {
      if (offerType === OFFER_TYPES.PRIMARY) {
        if (selectedOffersDentalPrimary === id) {
          setSelectedOffersDentalPrimary(undefined);
        } else {
          setSelectedOffersDentalPrimary(id);
        }
      } else {
        if (selectedOffers?.includes(id)) {
          setSelectedOffers(
            removeSpecificItemFromStringArray(selectedOffers, id)
          );
        } else {
          setSelectedOffers((pre) => [...pre, id]);
        }
      }
    }
    if (offerCategory === OFFER_CATEGORY.VISION) {
      if (offerType === OFFER_TYPES.PRIMARY) {
        if (selectedOffersVisionPrimary === id) {
          setSelectedOffersVisionPrimary(undefined);
        } else {
          setSelectedOffersVisionPrimary(id);
        }
      } else {
        if (selectedOffers?.includes(id)) {
          setSelectedOffers(
            removeSpecificItemFromStringArray(selectedOffers, id)
          );
        } else {
          setSelectedOffers((pre) => [...pre, id]);
        }
      }
    }
  };

  const transformSelectOfferVB = (data: any): any[] => {
    const volPlans: any[] = [];

    data?.volLifePlans?.forEach((item: any) => {
      volPlans.push({
        name: item,
        attachedDocuments: data?.attachedDocuments[item],
      });
    });
    return volPlans;
  };

  const disableSelectOffer = (item: any): boolean => {
    if (item.carrierType === OFFER_TYPES.PRIMARY) {
      if (type === OFFER_CATEGORY.MEDICAL) {
        const alreadySelectedPrimary = selectedOffersMedicalPrimary === item.id;
        return alreadySelectedPrimary
          ? false
          : filteredOffersBasedOnStatus.some(
              (offer: any) => offer.id === selectedOffersMedicalPrimary
            );
      } else if (type === OFFER_CATEGORY.DENTAL) {
        const alreadySelectedPrimary = selectedOffersDentalPrimary === item.id;
        return alreadySelectedPrimary
          ? false
          : filteredOffersBasedOnStatus.some(
              (offer: any) => offer.id === selectedOffersDentalPrimary
            );
      } else if (type === OFFER_CATEGORY.VISION) {
        const alreadySelectedPrimary = selectedOffersVisionPrimary === item.id;
        return alreadySelectedPrimary
          ? false
          : filteredOffersBasedOnStatus.some(
              (offer: any) => offer.id === selectedOffersVisionPrimary
            );
      }
    }
    return false; // Non-primary offers should always be enabled
  };

  const handleNavigationButton = (isNext: boolean) => {
    // sets the proper offer id based on the type
    setSearchText('');
    setIsNextClicked(isNext);
  };

  const setOfferIdBasedOnTheType = (proposal: any): any => {
    switch (type) {
      case OFFER_CATEGORY.MEDICAL:
        proposal.primaryMedicalOffer = selectedOffersMedicalPrimary;
        proposal.supplementaryMedicalOffers = selectedOffers || [];
        return proposal;
      case OFFER_CATEGORY.DENTAL:
        proposal.primaryDentalOffer = selectedOffersDentalPrimary;
        proposal.supplementaryDentalOffers = selectedOffers || [];
        return proposal;
      case OFFER_CATEGORY.VISION:
        proposal.primaryVisionOffer = selectedOffersVisionPrimary;
        proposal.supplementaryVisionOffers = selectedOffers || [];
        return proposal;
      case OFFER_CATEGORY.LIFE_AD:
        proposal.lifeAddOffers = selectedOffers || [];
        return proposal;
      case OFFER_CATEGORY.LONG_TERM_DISABILITY:
        proposal.lifeLTDOffers = selectedOffers || [];
        return proposal;
      case OFFER_CATEGORY.SHORT_TERM_DISABILITY:
        proposal.lifeSTDOffers = selectedOffers || [];
        return proposal;
      case OFFER_CATEGORY.VOLUNTARY:
        proposal.volLifeOffers = selectedOffers || [];
        return proposal;
      default:
        return proposal;
    }
  };
  const getTheApplicableStepperKey = (): number => {
    switch (type) {
      case OFFER_CATEGORY.MEDICAL:
      case OFFER_CATEGORY.LIFE_AD:
        return 0;
      case OFFER_CATEGORY.DENTAL:
      case OFFER_CATEGORY.SHORT_TERM_DISABILITY:
        return 1;
      case OFFER_CATEGORY.VISION:
      case OFFER_CATEGORY.LONG_TERM_DISABILITY:
        return 2;
      case OFFER_CATEGORY.VOLUNTARY:
        return 4;
      default:
        return -1;
    }
  };

  const isOfferSelected = (item: any) => {
    if (type === OFFER_CATEGORY.MEDICAL) {
      if (item.carrierType === OFFER_TYPES.PRIMARY) {
        return selectedOffersMedicalPrimary === item.id;
      }
    } else if (type === OFFER_CATEGORY.DENTAL) {
      if (item.carrierType === OFFER_TYPES.PRIMARY) {
        return selectedOffersDentalPrimary === item.id;
      }
    } else if (type === OFFER_CATEGORY.VISION) {
      if (item.carrierType === OFFER_TYPES.PRIMARY) {
        return selectedOffersVisionPrimary === item.id;
      }
    }

    return selectedOffers?.includes(item.id);
  };

  const onConfirmCreditSave = () => {
    setCreditType(showCreditModal.type);
    setShowCreditModal({
      visible: false,
      type:
        creditType === ONLY_CREDITS_INCLUSION.INCLUDED.value
          ? ONLY_CREDITS_INCLUSION.EXCLUDED.value
          : ONLY_CREDITS_INCLUSION.INCLUDED.value,
    });
  };

  const filteredOffersBasedOnStatus = filterOffers
    ?.filter(
      (offer: any) =>
        offer.offerStatusField !== 'PENDING' && offer.offerStatusField !== 'DTQ'
    )
    ?.sort((a: any, b: any) => (a?.newPlan ? -1 : 1));

  const draftOffers = filteredOffersBasedOnStatus?.filter(
    (offer: any) => offer.status === OFFER_STATUS.DRAFT
  );

  const hasADraftWithSameName = (publishedOfferName: any, draftOffers: any) => {
    const existingDraftOffers = Array.isArray(draftOffers) ? draftOffers : [];
    return existingDraftOffers.some(
      (draftOffer) => draftOffer.name === publishedOfferName
    );
  };

  const handleClose = async () => {
    let proposal = { ...proposalDetails?.payload };
    const proposalId = proposalDetails?.payload?.id;
    proposal = setOfferIdBasedOnTheType(proposal);
    await updateProposal({
      proposalId: proposalId,
      proposal: proposal,
    }).unwrap();
    dispatch(attachProposal(proposal));
  };

  useImperativeHandle(ref, () => ({
    handleClose: async () => {
      await handleClose();
    },
  }));

  /**
   *
   * Get the list of selected offers category wise
   * @return {string[]} list of offers ids of the selected offers
   */
  const getSelectedOffers = (): string[] => {
    const selectedData: string[] = [...selectedOffers];
    if (type === OFFER_CATEGORY.MEDICAL) {
      selectedData.push(selectedOffersMedicalPrimary as string);
    } else if (type === OFFER_CATEGORY.DENTAL) {
      selectedData.push(selectedOffersDentalPrimary as string);
    } else if (type === OFFER_CATEGORY.VISION) {
      selectedData.push(selectedOffersVisionPrimary as string);
    }
    return selectedData;
  };

  return (
    <div className={styles.offerContainerWrapper}>
      <Row className={styles.searchRow}>
        <div>
          <span className={styles.offerExcludeTooltipSpan}>
            <InfoCircleOutlined />
            If no
            {` ${OFFER_BENEFIT_TYPE_LABELS?.[
              type as keyof typeof OFFER_BENEFIT_TYPE_LABELS
            ]?.toLowerCase()} `}
            offer is selected, this benefit will be excluded from the proposal.
          </span>
        </div>
        <SearchBar
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e?.target?.value?.trimStart())}
        />
      </Row>
      {isGetOffersByEffectiveDateFetching || isProposalUpdateLoading ? (
        <div className={styles.loadingWrapper}>
          <Spin />
        </div>
      ) : (
        isGetOffersByEffectiveDateSuccess &&
        (isEmpty(offers) ? (
          <NoOffersScreen screenText="No offers available" />
        ) : isEmpty(filterOffers) ? (
          <NoOffersScreen screenText="No offers match your search" />
        ) : filteredOffersBasedOnStatus.length === 0 ? (
          <NoOffersScreen screenText="No offers available" />
        ) : (
          filteredOffersBasedOnStatus.map((item: any) => {
            const hasADraftOfferWithSameName =
              item?.status === OFFER_STATUS.PUBLISHED &&
              hasADraftWithSameName(item?.name, draftOffers);
            return (
              <ProposalCreateSelectOfferCard
                key={item?.id}
                id={item?.id}
                itemDetails={item}
                planData={
                  type !== OFFER_CATEGORY.VOLUNTARY
                    ? item?.planContributions
                    : transformSelectOfferVB(item)
                }
                planType={type}
                offerType={item?.carrierType}
                logoUrl={item.carrierLogoUrl}
                tags={[
                  item.type.toUpperCase(),
                  item.status.toUpperCase(),
                  ...(item?.recommended
                    ? [PROPOSAL_CARD_TAGS.RECOMMENDED]
                    : []),
                ]}
                handleSelectOffer={handelSelectedOffer}
                creditSelectedValue={creditType}
                setCreditSelectedValue={(value) =>
                  setShowCreditModal({ visible: true, type: value })
                }
                checkSelected={isOfferSelected(item)}
                selectButtonDisable={{
                  isPrimaryValidation: disableSelectOffer(item),
                  isNameDuplicated: checkIfSelectedOfferNamesDuplicated(
                    item,
                    getSelectedOffers(),
                    offers
                  ),
                }}
                showSummerySection={type !== OFFER_CATEGORY.VOLUNTARY}
                isNonMdv={[LIFE, STD, LTD].includes(type)}
                hasADraftOfferWithSameName={hasADraftOfferWithSameName}
              />
            );
          })
        ))
      )}
      {/*  Next and Back section */}
      <Row className={styles.btnRow} gutter={[16, 16]}>
        <Col className={styles.backBtnWrapper} span={8}>
          <SubmitButton
            className={styles.cancelButton}
            onClick={() => {
              handleNavigationButton(false);
            }}
            disabled={
              isGetOffersByEffectiveDateLoading || isProposalUpdateLoading
            }
          >
            Back
          </SubmitButton>
        </Col>
        <Col span={8}>
          <NextButton
            nextStep={() => {
              handleNavigationButton(true);
            }}
            className={styles.nextBtn}
            disabled={
              isGetOffersByEffectiveDateLoading || isProposalUpdateLoading
            }
            loading={
              isGetOffersByEffectiveDateLoading ||
              isProposalUpdateLoading ||
              changeStatusIsLoading
            }
          />
        </Col>
        {!isErAdmin && (
          <Col span={24} className={styles.saveDraftBtn}>
            <TextButton
              disabled={
                isGetOffersByEffectiveDateLoading ||
                isProposalUpdateLoading ||
                changeStatusIsLoading
              }
              label="Save Draft & Close"
              onClick={async () => {
                let proposal = { ...proposalDetails?.payload };
                const proposalId = proposalDetails?.payload?.id;
                proposal = setOfferIdBasedOnTheType(proposal);
                await updateProposal({
                  proposalId: proposalId,
                  proposal: proposal,
                }).unwrap();
                await draftProposal({
                  proposalId: proposalId ?? '',
                  proposalStatus: OFFER_STATUS.DRAFT,
                  isNotificationActive: false,
                }).unwrap();
                setIsSaveDraft(true);
                dispatch(attachProposal(proposal));
                changeStateToDraft();
              }}
              type="primary"
            />
          </Col>
        )}
      </Row>

      <CreditIncludedExcludedModal
        type={showCreditModal.type}
        visible={showCreditModal.visible}
        onClose={() =>
          setShowCreditModal({
            visible: false,
            type:
              creditType === ONLY_CREDITS_INCLUSION.INCLUDED.value
                ? ONLY_CREDITS_INCLUSION.EXCLUDED.value
                : ONLY_CREDITS_INCLUSION.INCLUDED.value,
          })
        }
        onConfirm={onConfirmCreditSave}
      />
    </div>
  );
};

export default forwardRef(OffersContainerWrapper);
