import { lumityApi } from 'api/apiSlice';
import { CONTENT_TYPE_MULTIPART_FORM } from 'util/apiUtil';

const billingApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    billingUploadFile: builder.mutation<
      any,
      {
        employerId: String;
        detailFile: File | null;
        summaryFile: File | null;
        type: String;
        month: Number;
        year: Number;
        billingId: string | null;
        dummySummaryFile: boolean;
        dummyDetailFile: boolean;
      }
    >({
      query: (args) => {
        const {
          employerId,
          detailFile,
          summaryFile,
          type,
          month,
          year,
          billingId,
          dummySummaryFile,
          dummyDetailFile,
        } = args;
        const formData = new FormData();
        formData.append('detailFile', detailFile ?? '');
        formData.append('summaryFile', summaryFile ?? '');
        return {
          url: `v2/billing/files/employers/${employerId}/draft`,
          method: 'post',
          params: {
            type,
            month,
            year,
            billingId,
            dummySummaryFile,
            dummyDetailFile,
          },
          data: formData,
          headers: {
            'content-type': CONTENT_TYPE_MULTIPART_FORM,
          },
        };
      },
    }),
    billingSummary: builder.query<
      any,
      {
        employerId: string;
        month: number;
        year: number;
      }
    >({
      query: (args) => {
        const { employerId, month, year } = args;
        return {
          url: `v2/billing/employers/${employerId}/summary`,
          method: 'GET',
          params: {
            month,
            year,
          },
        };
      },
    }),
    publishUploadFile: builder.mutation<
      any,
      {
        employerId: String;
        billingId: String;
        isNotificationEnabled: Boolean;
      }
    >({
      query: (args) => {
        const { employerId, billingId, isNotificationEnabled } = args;
        return {
          url: `v2/billing/${billingId}/employers/${employerId}/publish`,
          method: 'post',
          params: { notification: isNotificationEnabled },
        };
      },
    }),
    avaiableMonths: builder.query<
      any,
      {
        employerId: string;
      }
    >({
      query: (args) => {
        const { employerId } = args;
        return {
          url: `v2/billing/employers/${employerId}/months`,
          method: 'GET',
        };
      },
    }),
    getBillingFileHistory: builder.query<
      any,
      {
        employerId: string | undefined;
      }
    >({
      query: (args) => {
        const { employerId } = args;

        return {
          url: `v2/billing/employers/${employerId}/records`,
          method: 'get',
        };
      },
    }),
    billingTrend: builder.query<
      any,
      {
        employerId: string;
        startYear: number;
        startMonth: number;
        endYear: number;
        endMonth: number;
      }
    >({
      query: (args) => {
        const { employerId, startYear, startMonth, endYear, endMonth } = args;
        return {
          url: `v2/billing/employers/${employerId}/trend`,
          method: 'GET',
          params: {
            [`start-year`]: startYear,
            [`start-month`]: startMonth,
            [`end-year`]: endYear,
            [`end-month`]: endMonth,
          },
        };
      },
    }),
    exportFile: builder.query<
      any,
      {
        billingId: string;
        type: string;
      }
    >({
      query: (args) => {
        const { billingId, type } = args;
        return {
          url: `v2/billing/${billingId}/files/export`,
          method: 'GET',
          params: {
            type,
          },
        };
      },
    }),
    billingUploadCarrierFile: builder.mutation<
      any,
      {
        billingId: string;
        file: File | null;
      }
    >({
      query: (args) => {
        const { billingId, file } = args;
        const formData = new FormData();
        formData.append('file', file ?? '');
        return {
          url: `v2/billing/${billingId}/files/carrier-file/upload`,
          method: 'post',
          data: formData,
          headers: {
            'content-type': CONTENT_TYPE_MULTIPART_FORM,
          },
        };
      },
    }),
    billingPreview: builder.mutation<
      any,
      {
        billingId: String | undefined;
        employeeId: string;
      }
    >({
      query: (args) => {
        const { billingId, employeeId } = args;
        return {
          url: `v2/billing/${billingId}/employer/${employeeId}/preview`,
          method: 'post',
        };
      },
    }),
    monthlyBillingHistory: builder.query<
      any,
      {
        employerId: string;
        month: number;
      }
    >({
      query: (args) => {
        const { employerId, month } = args;
        return {
          url: `v2/billing/history/employer/${employerId}/month/${month}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useBillingUploadFileMutation,
  useBillingSummaryQuery,
  usePublishUploadFileMutation,
  useAvaiableMonthsQuery,
  useGetBillingFileHistoryQuery,
  useBillingTrendQuery,
  useExportFileQuery,
  useBillingUploadCarrierFileMutation,
  useBillingPreviewMutation,
  useMonthlyBillingHistoryQuery,
} = billingApi;
