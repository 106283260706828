import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as PlanService from 'modules/plans/services/PlanService';
import * as AdapterService from 'modules/plans/services/AdapterService';
import * as PlanRates from 'modules/plans/planRates/pages/PlanRates/PlanRates';
import ErrorDetails from 'model/ErrorDetails';
import MDVPlanRates from 'model/plans/MDVPlanRates';
import LifeAndDisabilityPlanRates from 'model/plans/LifeAndDisabilityPlanRates';

export interface MDVPlanRatesState {
  medicalRates: { [key: string]: Array<MDVPlanRates> } | null;
  dentalRates: { [key: string]: Array<MDVPlanRates> } | null;
  visionRates: { [key: string]: Array<MDVPlanRates> } | null;
  lifeDisabilityRates: {
    [key: string]: Array<LifeAndDisabilityPlanRates>;
  } | null;
  exportPlanRatesInProgress: boolean;
  medicalPlanRatesGetError: ErrorDetails | null;
  dentalPlanRatesGetError: ErrorDetails | null;
  visionPlanRatesGetError: ErrorDetails | null;
  lifeDisabilityPlanRatesGetError: ErrorDetails | null;
}

const initialState: MDVPlanRatesState = {
  medicalRates: null,
  dentalRates: null,
  visionRates: null,
  lifeDisabilityRates: null,
  exportPlanRatesInProgress: false,
  medicalPlanRatesGetError: null,
  dentalPlanRatesGetError: null,
  visionPlanRatesGetError: null,
  lifeDisabilityPlanRatesGetError: null,
};

const planRatesSlice = createSlice({
  name: 'planRates',
  initialState,
  reducers: {
    medicalPlanRatesGetInProgress: (state) => {
      state.medicalRates = null;
      state.medicalPlanRatesGetError = null;
    },
    medicalPlanRatesGetSucceeded: (state, action: PayloadAction<any>) => {
      state.medicalRates = action.payload;
      state.medicalPlanRatesGetError = null;
    },
    medicalPlanRatesGetFailed: (state, action) => {
      state.medicalPlanRatesGetError = action.payload;
    },
    dentalPlanRatesGetInProgress: (state) => {
      state.dentalRates = null;
      state.dentalPlanRatesGetError = null;
    },
    dentalPlanRatesGetSucceeded: (state, action: PayloadAction<any>) => {
      state.dentalRates = action.payload;
      state.dentalPlanRatesGetError = null;
    },
    dentalPlanRatesGetFailed: (state, action) => {
      state.dentalPlanRatesGetError = action.payload;
    },
    visionPlanRatesGetInProgress: (state) => {
      state.visionRates = null;
      state.visionPlanRatesGetError = null;
    },
    visionPlanRatesGetSucceeded: (state, action: PayloadAction<any>) => {
      state.visionRates = action.payload;
      state.visionPlanRatesGetError = null;
    },
    visionPlanRatesGetFailed: (state, action) => {
      state.visionPlanRatesGetError = action.payload;
    },
    lifeDisabilityPlanRatesGetInProgress: (state) => {
      state.lifeDisabilityRates = null;
      state.lifeDisabilityPlanRatesGetError = null;
    },
    lifeDisabilityPlanRatesGetSucceeded: (
      state,
      action: PayloadAction<any>
    ) => {
      state.lifeDisabilityRates = action.payload;
      state.lifeDisabilityPlanRatesGetError = null;
    },
    lifeDisabilityPlanRatesGetFailed: (state, action) => {
      state.lifeDisabilityPlanRatesGetError = action.payload;
    },
    exportPlanRatesInProgress: (state) => {
      state.exportPlanRatesInProgress = true;
    },
    exportPlanRatesSucceeded: (state) => {
      state.exportPlanRatesInProgress = false;
    },
    exportPlanRatesFailed: (state) => {
      state.exportPlanRatesInProgress = false;
    },
  },
});

export const {
  medicalPlanRatesGetInProgress,
  medicalPlanRatesGetSucceeded,
  medicalPlanRatesGetFailed,
  dentalPlanRatesGetInProgress,
  dentalPlanRatesGetSucceeded,
  dentalPlanRatesGetFailed,
  visionPlanRatesGetInProgress,
  visionPlanRatesGetSucceeded,
  visionPlanRatesGetFailed,
  lifeDisabilityPlanRatesGetInProgress,
  lifeDisabilityPlanRatesGetSucceeded,
  lifeDisabilityPlanRatesGetFailed,
  exportPlanRatesInProgress,
  exportPlanRatesSucceeded,
  exportPlanRatesFailed,
} = planRatesSlice.actions;

export default planRatesSlice.reducer;

export const getMedicalPlanRates = (
  employerId: string,
  planYearId: string,
  benefitGroups: string[]
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(medicalPlanRatesGetInProgress());
      const response = await PlanService.getMDVPlanRates(
        employerId,
        planYearId,
        PlanRates.MEDICAL,
        benefitGroups
      );
      const data = response.data;
      dispatch(medicalPlanRatesGetSucceeded(data));
    } catch (error: any) {
      dispatch(medicalPlanRatesGetFailed(error.response));
    }
  };
};

export const getDentalPlanRates = (
  employerId: string,
  planYearId: string,
  benefitGroups: string[]
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(dentalPlanRatesGetInProgress());
      const response = await PlanService.getMDVPlanRates(
        employerId,
        planYearId,
        PlanRates.DENTAL,
        benefitGroups
      );
      const data = response.data;
      dispatch(dentalPlanRatesGetSucceeded(data));
    } catch (error: any) {
      dispatch(dentalPlanRatesGetFailed(error.response));
    }
  };
};

export const getVisionPlanRates = (
  employerId: string,
  planYearId: string,
  benefitGroups: string[]
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(visionPlanRatesGetInProgress());
      const response = await PlanService.getMDVPlanRates(
        employerId,
        planYearId,
        PlanRates.VISION,
        benefitGroups
      );
      const data = response.data;
      dispatch(visionPlanRatesGetSucceeded(data));
    } catch (error: any) {
      dispatch(visionPlanRatesGetFailed(error.response));
    }
  };
};

export const getLifeDisabilityPlanRates = (
  employerId: string,
  planYearId: string,
  benefitGroups: string[]
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(lifeDisabilityPlanRatesGetInProgress());
      const response = await PlanService.getLifeAndDisabilityPlanRates(
        employerId,
        planYearId,
        benefitGroups
      );
      const data = response.data;
      dispatch(lifeDisabilityPlanRatesGetSucceeded(data));
    } catch (error: any) {
      dispatch(lifeDisabilityPlanRatesGetFailed(error.response));
    }
  };
};

export const exportPlanRatesExcel = (
  employerId: string,
  planYearId: string,
  benefitGroups: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(exportPlanRatesInProgress());
    try {
      const url = AdapterService.getExportPlanRatesUrl(
        employerId,
        planYearId,
        benefitGroups
      );
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      a.href = url;
      a.click();
      window.URL.revokeObjectURL(url);
      dispatch(exportPlanRatesSucceeded());
    } catch (error) {
      dispatch(exportPlanRatesFailed());
    }
  };
};
