import { forwardRef, MutableRefObject, useImperativeHandle } from 'react';

import EditRxCardInfo from 'modules/plans/components/RxCardInfo/EditRxCardInfo/EditRxCardInfo';
import MedicalPlan from 'model/plans/MedicalPlan';

import styles from './EditRxCardInfo/editRxCardInfo.module.less';

type RxCardInfoDBGWrapperProps = {
  medicalPlan: MedicalPlan;
  setMedicalPlan: Function;
  medicalPlanRef?: MutableRefObject<any>;
};

const RxCardInfoUPWrapper = forwardRef(
  (props: RxCardInfoDBGWrapperProps, ref) => {
    const { medicalPlan, setMedicalPlan, medicalPlanRef } = props;

    useImperativeHandle(ref, () => ({
      reset() {
        medicalPlanRef?.current?.resetForm();
      },
      isValidForm() {
        return medicalPlanRef?.current?.isValidForm();
      },
    }));

    return (
      <div className={styles.rxCardInfoDBGWrapper}>
        <EditRxCardInfo
          onChange={setMedicalPlan}
          isDBGPlan={true}
          ref={medicalPlanRef}
          medicalPlan={medicalPlan}
          isUPEdit={true}
        />
      </div>
    );
  }
);

RxCardInfoUPWrapper.displayName = 'RxCardInfoUPWrapper';
export default RxCardInfoUPWrapper;
