import { useState } from 'react';
import CarrierType from 'modules/carriers/enums/CarrierType';

export const useAddNewCarrierState = () => {
  const [carrierCreateTypeConfirmation, setCarrierCreateTypeConfirmation] =
    useState<{
      show: boolean;
      carrierType: CarrierType.BROKER | CarrierType.EMPLOYER | '';
    }>({
      show: false,
      carrierType: '',
    });
  const [openCarrierModal, setOpenCarrierModal] = useState<boolean>(false);
  const [newCarrierId, setNewCarrierId] = useState<string>('');

  return {
    carrierCreateTypeConfirmation,
    setCarrierCreateTypeConfirmation,
    openCarrierModal,
    setOpenCarrierModal,
    newCarrierId,
    setNewCarrierId,
  };
};
