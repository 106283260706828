import React, { useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import iconRemove from 'assets/images/icon-remove-red.svg';
import iconWarning from 'assets/images/icon-warning.svg';
import { ServiceTypeDisplayNames } from 'model/plans/ServiceType';
import InfoIconComponent from 'modules/plans/components/LLMInfoIconComponent/InfoIconComponent';
import { getLifeServiceInfo } from 'modules/plans/utils';
import { LlmExtractionInfo } from 'model/plans/LLMExtraction';
import { ReactComponent as AIFeedbackRedFlag } from 'assets/images/ai-red-flag.svg';
import styles from './lifeServiceSystemRow.module.less';

type Props = {
  description: string;
  service: string;
  isDefault?: boolean;
  onConfirmClick: any;
  handleServiceDescriptionChange: any;
  llmExtractionInfo: LlmExtractionInfo | null | undefined;
  lifePlanType: string;
  isHighlighted?: boolean;
  isUploadedPlan?: boolean;
};

const LifeServiceSystemRow = (props: Props) => {
  const {
    isDefault,
    onConfirmClick,
    handleServiceDescriptionChange,
    service,
    description,
    llmExtractionInfo,
    lifePlanType,
    isHighlighted,
    isUploadedPlan,
  } = props;
  const [visible, setVisible] = useState(false);

  const isAddOrRemoveIconVisible = !isDefault;

  const llmServiceInfo = getLifeServiceInfo(
    service,
    llmExtractionInfo,
    lifePlanType
  );

  return (
    <Row className={styles.inlineRow}>
      <Col className={styles.removeIcon}>
        {isAddOrRemoveIconVisible && (
          <ConfirmationModal
            className={styles.confirmationModalPopup}
            onVisibleChange={() => {
              !visible && setVisible(true);
            }}
            title="Are you sure you want to remove this service?"
            okText="Yes, remove service"
            icon={
              <img
                src={iconWarning}
                alt="warning-icon"
                className={styles.iconWarning}
              />
            }
            confirmModalTrigger={
              <img
                src={iconRemove}
                alt="remove-service-icon"
                className={styles.iconRemove}
              />
            }
            visible={visible}
            onConfirm={onConfirmClick}
            onCancel={() => {
              setVisible(false);
            }}
            placement="topLeft"
          />
        )}
      </Col>
      <Col flex="auto">
        <Form.Item
          label={
            <span className={styles.labelWithIcon}>
              {ServiceTypeDisplayNames[service]}
              <span className={styles.aiInfoIcon}>
                <InfoIconComponent
                  showIcon={!!isUploadedPlan}
                  extractionInfo={llmServiceInfo}
                />
              </span>
            </span>
          }
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
        >
          <div className={styles.inputWrapper}>
            <Input
              value={description}
              onChange={(event) =>
                handleServiceDescriptionChange(event.target.value, service)
              }
              className={
                llmServiceInfo?.different && isHighlighted
                  ? styles.highlightedLLMTextField
                  : ''
              }
            />
            {llmServiceInfo?.different && isHighlighted && (
              <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
            )}
          </div>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default LifeServiceSystemRow;
