import { FC, useCallback, useEffect, useState } from 'react';

import { Spin } from 'antd';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import EditBrokerTeam from 'modules/employers/components/EditBrokerTeam/EditBrokerTeam';
import {
  getAccessibleTeamMembers,
  getEmployer,
  removeTeamOrder,
  setTeamMemberSortFlag,
} from 'modules/employers/slices/employerSlice';
import TeamMember from 'model/TeamMember';
import * as EmployerService from 'modules/employers/services/EmployerService';

import styles from './editBrokerTeamModal.module.less';

type Props = {
  brokerName: string;
  isModalOpen: boolean;
  selectedEmployer: string;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

const EditBrokerTeamModal: FC<Props> = (props: Props) => {
  const { isModalOpen, selectedEmployer, setIsModalOpen } = props;
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);

  const dispatch = useAppDispatch();
  const { brokerId } = useNavContext();

  const accessibleTeamMembers = useAppSelector(
    (state) =>
      state.employer.employer.accessibleTeamMembers?.accessibleTeamMembers
  );

  const teamOrder = useAppSelector(
    (state) => state.employer.employer.teamOrder
  );

  const teamMemberSortDone = useAppSelector(
    (state) => state.employer.employer.teamMemberSortDone
  );

  const employerObj = useAppSelector(
    (state) => state.employer.employer.employerObj
  );

  const { inProgress } = useAppSelector((state) => state.employer.employer);

  const getLatestTeamMembers = useCallback(() => {
    let orderedMembers = [];
    orderedMembers =
      (accessibleTeamMembers &&
        teamOrder.map((id) =>
          accessibleTeamMembers.find((member) => member.id === id)
        )) ||
      [];
    accessibleTeamMembers
      ?.filter((member) => !teamOrder.includes(member.id) && member.assigned)
      .map((member) => orderedMembers.push(member));
    return orderedMembers || [];
  }, [accessibleTeamMembers, teamOrder]);

  useEffect(() => {
    if (accessibleTeamMembers) {
      setTeamMembers(accessibleTeamMembers);
    }
  }, [accessibleTeamMembers]);

  useEffect(() => {
    if (selectedEmployer) {
      dispatch(getAccessibleTeamMembers(selectedEmployer));
    }
  }, [dispatch, selectedEmployer]);

  useEffect(() => {
    if (teamOrder) {
      setTeamMembers(getLatestTeamMembers() as TeamMember[]);
    }
  }, [dispatch, teamOrder, getLatestTeamMembers]);

  useEffect(() => {
    if (selectedEmployer) {
      dispatch(getEmployer(selectedEmployer));
    }
  }, [dispatch, selectedEmployer, brokerId]);

  const handleCloseModal = () => {
    dispatch(setTeamMemberSortFlag(false));
    dispatch(removeTeamOrder());
    if (selectedEmployer) {
      dispatch(getAccessibleTeamMembers(selectedEmployer));
    }
    setIsModalOpen(false);
  };

  const getUpdatedTeamMembers = () => {
    dispatch(getAccessibleTeamMembers(selectedEmployer));
  };

  const updateOrder = async () => {
    const organizationId = brokerId ? brokerId : employerObj?.organizationId;
    if (organizationId && teamMemberSortDone) {
      dispatch(setTeamMemberSortFlag(false));
      try {
        await EmployerService.updateTeam(
          organizationId,
          selectedEmployer,
          teamOrder
        );
      } catch (error) {
        console.log(error);
      }
    }
    handleCloseModal();
  };

  return (
    <FullScreenModal
      visible={isModalOpen}
      onCancel={handleCloseModal}
      footer={false}
      title={`Edit Team for ${employerObj ? employerObj.name : ''}`}
    >
      {inProgress && (
        <div className={styles.loadingIconStyle}>
          <Spin />
        </div>
      )}
      {!inProgress && (
        <EditBrokerTeam
          employerId={selectedEmployer}
          closeEditModal={handleCloseModal}
          getUpdatedTeamMembers={getUpdatedTeamMembers}
          accessibleTeamMembers={accessibleTeamMembers || []}
          teamMembers={teamMembers || []}
          updateOrder={updateOrder}
        />
      )}
    </FullScreenModal>
  );
};

export default EditBrokerTeamModal;
