import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  clearUploadedLocationId,
  setClearState,
  setInprogressId,
} from 'modules/rfp/slices/rfpQuestionSlice';
import {
  CONFIRM_WARN_MESSAGE,
  PLEASE_WAIT_MESSAGE,
} from 'modules/rfp/constants/RfpConstants';
import { deleteRfp } from 'modules/rfp/services/RfpService';
import AddRfp from './AddRfp';
import styles from './rfpModal.module.less';

const initialState = {
  type: undefined,
  data: null,
};

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  isEditMode?: boolean;
  rfpName?: string;
  refetch?: () => void | Promise<void>;
  setRfpSavedAlert?: (value: boolean) => void;
  setModalSwitchState?: Function;
};

const AddRfpmodal: FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();

  const {
    isModalOpen,
    setIsModalOpen,
    isEditMode,
    rfpName,
    refetch,
    setRfpSavedAlert,
    setModalSwitchState,
  } = props;
  const { brokerId } = useParams();
  const [currentStep, setCurrentStep] = useState<number>(isEditMode ? 2 : 0);
  const inProgressId = useAppSelector((state) => state.rfp?.inProgressId);
  const isLoading = useAppSelector((state) => state.rfp?.isLoading);

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isSecondConfirmOpen, setIsSecondConfirmOpen] =
    useState<boolean>(false);
  const [isPleaseWaitModalOpen, setIsPleaseWaitModalOpen] = useState(false);

  const handleCloseModal = async () => {
    if ([0, 1].includes(currentStep) && !isLoading) {
      if (currentStep === 1) {
        await deleteRFPOnCancel();
      }
      dispatch(clearUploadedLocationId());
      setIsModalOpen(false);
    } else if (currentStep === 2 || currentStep === 3) {
      setIsSecondConfirmOpen(true);
    } else if (currentStep === 0 && isLoading) {
      setIsPleaseWaitModalOpen(true);
    } else {
      setIsConfirmOpen(true);
    }
  };

  useEffect(() => {
    if (isModalOpen && isEditMode) {
      setCurrentStep(2);
    } else {
      setCurrentStep(0);
    }
  }, [isModalOpen, isEditMode]);

  const deleteRFPOnCancel = async () => {
    // Excecute if only in provide context tab
    if (currentStep === 1 && inProgressId && !isEditMode) {
      await deleteRfp(brokerId!, inProgressId);
      await refetch?.();
      setModalSwitchState && setModalSwitchState(initialState);
    }
  };

  return (
    <div className={styles.RfpModalForm}>
      <FullScreenModal
        visible={isModalOpen}
        onCancel={() => handleCloseModal()}
        footer={false}
        title="Create AI Response"
        forceRender
      >
        <AddRfp
          isEditMode={isEditMode}
          refetch={refetch}
          rfpName={rfpName}
          closeModal={() => handleCloseModal()}
          currentStep={currentStep}
          onNextStep={() => {
            setCurrentStep(currentStep + 1);
          }}
          changeCurrentStep={(step) => setCurrentStep(step)}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setRfpSavedAlert={setRfpSavedAlert}
        />
      </FullScreenModal>

      {isConfirmOpen && (
        <ConfirmationDialog
          confirmText="Close"
          isCancelLink
          cancelText="Cancel"
          closeModal={() => {
            setIsConfirmOpen(false);
          }}
          onConfirm={() => {
            dispatch(clearUploadedLocationId());
            setIsModalOpen(false);
            setIsConfirmOpen(false);
            dispatch(setInprogressId(''));
            dispatch(setClearState());
          }}
          visible={isConfirmOpen}
        >
          <div className={styles.confirmationModalWrapper}>
            <p className={styles.exitWithoutSaveWarnMsg}>
              {CONFIRM_WARN_MESSAGE}
            </p>
          </div>
        </ConfirmationDialog>
      )}

      {isSecondConfirmOpen && (
        <ConfirmationDialog
          title="Are you sure you want to close?"
          confirmText="Close without saving"
          isCancelLink
          cancelText="Cancel"
          closeModal={() => {
            setIsSecondConfirmOpen(false);
          }}
          onConfirm={() => {
            dispatch(clearUploadedLocationId());
            setIsModalOpen(false);
            setIsSecondConfirmOpen(false);
            dispatch(setInprogressId(''));
            dispatch(setClearState());
          }}
          visible={isSecondConfirmOpen}
        ></ConfirmationDialog>
      )}

      <ConfirmationDialog
        isConfirmApplicable={false}
        onConfirm={() => {}}
        confirmText=""
        isCancelLink
        cancelText="Cancel"
        closeModal={() => setIsPleaseWaitModalOpen(false)}
        visible={isPleaseWaitModalOpen}
      >
        <div className={styles.confirmationModalWrapper}>
          <p className={styles.exitWithoutSaveWarnMsg}>{PLEASE_WAIT_MESSAGE}</p>
        </div>
      </ConfirmationDialog>
    </div>
  );
};

export default AddRfpmodal;
