import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import trim from 'lodash/trim';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosResponse } from 'axios';

import PlanServiceObject from 'model/PlanService';

import * as PlanService from 'modules/plans/services/PlanService';
import {
  getCurrentJobToken,
  getTextractJobStatus,
  startTextractJob,
} from 'modules/plans/services/SBCUploadService';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import {
  benefitCode,
  BenefitCovered,
  ContributionType,
  CostType,
  DEFAULT_SERVICE_CODES,
  MedicalPlanDocumentType,
  PriorToDeductible,
} from 'modules/plans/constants';
import ClonePlanDocument from 'model/plans/ClonePlanDocument';
import { BenefitCategory } from 'constants/commonConstants';
import MedicalPlan from 'model/plans/MedicalPlan';
import BenefitCode from 'model/plans/BenefitCode';
import CustomPlan from 'model/plans/CustomPlan';
import CostSharing from 'model/plans/CostSharing';
import PaginationConfig from 'model/PaginationConfig';
import BenguidePlan from 'model/BenguidePlan';
import ErrorDetails from 'model/ErrorDetails';
import RxCost from 'model/plans/RxCost';
import DeductiblesForIndividualWithFamily from 'model/plans/DeductiblesForIndividualWithFamily';

import {
  removeBenefitDocumentsFromPlan,
  transformDeductibleForSave,
  transformDeductiblesAndOopMaxForSave,
  transformOOPMaxForSave,
} from 'modules/plans/utils';
import FundingType from 'modules/plans/enums/FundingType';
import {
  getExtractedPlanDetails,
  getStatusAiSBC,
  initiateAiUploader,
} from 'modules/plans/services/AiSBCUploaderService';
import { AIUploaderFeedback } from 'model/plans/UserFeedback';
import { clonePlanDocuments } from './planDocumentsSlice';

export interface PlanServiceState {
  planServiceList: {
    inProgress: boolean;
    error: any;
    requestType: string;
    defaults?: PlanServiceObject[];
    options?: PlanServiceObject[];
    medicalPlan: MedicalPlan;
    planExtractingError: any;
    newPlan: boolean;
  };
}

const initialState = {
  newPlan: false,
  inProgress: false,
  error: null as any,
  requestType: '',
  sbcUpload: {
    inProgress: false,
    jobId: null,
    status: ProcessStatus.INITIALIZING,
    error: null,
  },
  planServiceList: {
    defaults: [] as PlanServiceObject[],
    options: [],
    inProgress: false,
    benefitKind: '',
    error: null,
  },
  medicalPlan: {} as MedicalPlan,
  displayedServices: [] as PlanServiceObject[],
  medicalPlansList: {
    inProgress: false,
    data: { content: [], metadata: { total: 0 } },
    error: null,
    requestType: '',
  },
  planExtractingError: null,
  dbgData: {
    dbgByFrequency: {},
    rateValidations: { hasMismatchContributions: false },
  },
  hsaMedicalPlansList: [],
  medicalRates: {},
  clonedState: null as any,
  clonedDocumentReferences: {},
  cancelDocumentUpload: false,
  editedMedicalPlan: {} as MedicalPlan,
  medicalDocumentReferences: {},
  hraMedicalPlansList: [],
};

const medicalPlanSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    sbcUploadingInprogress: (state) => {
      state.sbcUpload.inProgress = true;
      state.sbcUpload.status = ProcessStatus.INITIALIZING;
      state.sbcUpload.jobId = null;
      state.cancelDocumentUpload = false;
    },
    setProcessedSBCData: (state, action) => {
      state.sbcUpload.inProgress = false;
      state.sbcUpload.status = ProcessStatus.SUCCESS;
      state.sbcUpload.jobId = action.payload;
      state.cancelDocumentUpload = false;
    },
    sbcUploadingCompleted: (state, action) => {
      state.sbcUpload.inProgress = false;
      state.sbcUpload.jobId = action.payload;
    },
    sbcUploadingFailed: (state, action) => {
      state.sbcUpload.inProgress = false;
      state.sbcUpload.error = action.payload;
    },
    sbcUploadingStatusUpdate: (state, { payload }) => {
      if (ProcessStatus.SUCCESS === payload) {
        if (state.medicalPlan.documentReferences) {
          state.clonedDocumentReferences = JSON.parse(
            JSON.stringify(state.medicalPlan.documentReferences)
          );
        }
        state.clonedState = {
          status: ProcessStatus.SUCCESS,
          jobId: state.sbcUpload.jobId,
        };
      }
      state.sbcUpload.status = payload;
    },
    cancelUploadingPdf: (state) => {
      getCurrentJobToken()?.cancel('Operation canceled due to new request.');
      PlanService.getDocumentUploadToken()?.cancel(
        'Operation canceled due to new request.'
      );
      const medicalPlan = state.medicalPlan;
      medicalPlan.customServices = [];
      medicalPlan.rxCosts = new RxCost();
      medicalPlan.deductibles = new DeductiblesForIndividualWithFamily();
      medicalPlan.outOfPocket = new DeductiblesForIndividualWithFamily();
      medicalPlan.documentReferences = state.clonedDocumentReferences;
      state.cancelDocumentUpload = true;
      state.sbcUpload = {
        ...state.sbcUpload,
        inProgress: false,
        error: null,
        ...state.clonedState,
      };
      state.medicalPlan = medicalPlan;
    },
    planServiceListInProgress: (state, { payload }) => {
      state.planServiceList.inProgress = true;
      state.planServiceList.benefitKind = payload;
    },
    planServiceListCompleted: (state, { payload }) => {
      state.planServiceList.inProgress = false;
      state.planServiceList = payload;
    },
    planServiceListFailed: (state, { payload }) => {
      state.planServiceList.inProgress = false;
      state.planServiceList.defaults = [];
      state.planServiceList.options = [];
      state.planServiceList.error = payload;
    },
    getExtractedPlanDetailsInProgress: (state) => {
      state.inProgress = true;
      state.planExtractingError = null;
    },
    getExtractedPlanDetailsCompleted: (state, { payload }) => {
      const { benefitCodes, medicalPlan } = payload;
      const {
        deductibles,
        outOfPocket,
        customServices,
        rxCosts,
        mailOrderRxCosts,
        textractJobId,
      } = medicalPlan;

      const defaultServiceCodes = benefitCodes
        .filter(
          (service: PlanServiceObject) =>
            service &&
            service.isSaasSupported &&
            service.isDefault &&
            service.benefitKind === BenefitCategory.MEDICAL.value
        )
        .map((service: PlanServiceObject) => service.code);

      const updatedCustomServices = cloneDeep(customServices);

      updatedCustomServices?.forEach((service: any) => {
        service.isDefault = defaultServiceCodes.includes(
          service.benefitCode.code
        );
      });

      const updatedRxCosts = cloneDeep(rxCosts);
      const updatedMailOrderRxCosts = cloneDeep(mailOrderRxCosts);

      updatedCustomServices?.forEach((service: any) => {
        if (
          service.benefitCode.code !== benefitCode.MEDICAL_PREVENTIVE_CARE.code
        ) {
          const inNetwork = service.serviceValue.inNetwork;
          const outOfNetwork = service.serviceValue.outOfNetwork;

          inNetwork.copayPriorToDeductible = state?.medicalPlan?.hsaCompatible
            ? PriorToDeductible.NO
            : '';
          outOfNetwork.copayPriorToDeductible = state?.medicalPlan
            ?.hsaCompatible
            ? PriorToDeductible.NO
            : '';

          service.serviceValue.inNetwork = inNetwork;
          service.serviceValue.outOfNetwork = outOfNetwork;
        }
        return service;
      });

      if (updatedRxCosts?.inNetwork) {
        Object.keys(updatedRxCosts?.inNetwork).forEach((key) => {
          (
            updatedRxCosts?.inNetwork[key] as CostSharing
          ).copayPriorToDeductible = state?.medicalPlan?.hsaCompatible
            ? PriorToDeductible.NO
            : '';
        });
      }

      if (updatedRxCosts?.outOfNetwork) {
        Object.keys(updatedRxCosts?.outOfNetwork).forEach((key) => {
          (
            updatedRxCosts?.outOfNetwork[key] as CostSharing
          ).copayPriorToDeductible = state?.medicalPlan?.hsaCompatible
            ? PriorToDeductible.NO
            : '';
        });
      }

      if (updatedMailOrderRxCosts?.inNetwork) {
        Object.keys(updatedMailOrderRxCosts?.inNetwork).forEach((key) => {
          (
            updatedMailOrderRxCosts?.inNetwork[key] as CostSharing
          ).copayPriorToDeductible = state?.medicalPlan?.hsaCompatible
            ? PriorToDeductible.NO
            : '';
        });
      }

      if (updatedMailOrderRxCosts?.outOfNetwork) {
        Object.keys(updatedMailOrderRxCosts?.outOfNetwork).forEach((key) => {
          (
            updatedMailOrderRxCosts?.outOfNetwork[key] as CostSharing
          ).copayPriorToDeductible = state?.medicalPlan?.hsaCompatible
            ? PriorToDeductible.NO
            : '';
        });
      }

      state.medicalPlan = {
        ...state.medicalPlan,
        deductibles,
        outOfPocket,
        customServices: updatedCustomServices,
        rxCosts: updatedRxCosts,
        mailOrderRxCosts: updatedMailOrderRxCosts,
        textractJobId,
      };
      state.inProgress = false;
      state.planExtractingError = null;
    },
    injectMedicalPlanDetails: (
      state,
      { payload }: PayloadAction<Partial<MedicalPlan>>
    ) => {
      const updatedMedicalPlan = {
        ...state.medicalPlan,
        ...payload,
      };

      // below updates the custom services with the new benefit codes
      const updatedBenefitCodes: PlanServiceObject[] =
        updatedMedicalPlan?.customServices
          ?.filter((service) => !service?.isDefault)
          ?.map((service, index) => ({
            id: service?.benefitCode.id,
            benefitKind: service?.benefitCode.benefitKind,
            code: service?.benefitCode.code,
            description: service?.benefitCode.description,
            shortName: service?.benefitCode.shortName,
            isSaasSupported: true,
            orderIndex: index,
            isDefault: false,
            added: true,
            manualAdded: true,
          })) || [];

      // Filter out benefit codes that already exist in state.displayedServices based on shortName.
      const newBenefitCodes = updatedBenefitCodes?.filter(
        (updatedBenefitCode) =>
          !state.displayedServices?.some(
            (displayedService) =>
              displayedService?.shortName === updatedBenefitCode?.shortName
          )
      );

      // Update the state only with new benefit codes
      if (!isEmpty(newBenefitCodes)) {
        state.displayedServices = getOrderedDisplayServices([
          ...state.displayedServices,
          ...newBenefitCodes,
        ]);
      }
      state.planExtractingError = null;
      state.inProgress = false;
      state.medicalPlan = updatedMedicalPlan;
    },
    getExtractedPlanDetailsFailed: (state, { payload, type }) => {
      state.inProgress = false;
      state.planExtractingError = payload;
      state.requestType = type;
    },
    updateMedicalPlanInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    updateMedicalPlanCompleted: (
      state,
      { payload, type }: PayloadAction<any>
    ) => {
      state.inProgress = false;
      state.medicalPlan = { ...state.medicalPlan, ...payload.data };
      state.editedMedicalPlan = { ...state.editedMedicalPlan, ...payload.data };
      state.error = null;
      state.requestType = type;
      state.newPlan = payload.newPlan;
    },
    updateMedicalPlanByPathCompleted: (
      state,
      { payload, type }: PayloadAction<any>
    ) => {
      set(state.editedMedicalPlan, payload.path, payload.value);
      if (payload.isDBGView) {
        set(state.medicalPlan, payload.path, payload.value);
      }
      state.inProgress = false;
      state.error = null;
      state.requestType = type;
    },
    updateMedicalPlanFailed: (state, action) => {
      state.inProgress = false;
      state.error = action.payload;
      state.requestType = action.type;
    },
    updateMedicalPlanServiceCompleted: (
      state,
      action: PayloadAction<CustomPlan>
    ) => {
      const { payload } = action;
      const { customServices = [] } = state.medicalPlan;
      const existingServiceIndex = customServices.findIndex(
        (s) =>
          (payload.benefitCode.code &&
            payload.benefitCode.code === s.benefitCode.code) ||
          payload.benefitCode.shortName === s.benefitCode.shortName
      );
      if (existingServiceIndex !== -1) {
        customServices[existingServiceIndex] = payload;
      } else {
        customServices.push(payload);
      }
      const copy = [...customServices];
      state.medicalPlan = {
        ...state.medicalPlan,
        customServices: copy,
      };
    },
    removeMedicalPlanServiceCompleted: (
      state,
      action: PayloadAction<BenefitCode>
    ) => {
      const { payload } = action;
      const { code, shortName } = payload;
      const { customServices = [] } = state.medicalPlan;

      const newCustomServices = customServices.filter(
        (service) =>
          (code && code !== service.benefitCode.code) ||
          shortName !== service.benefitCode.shortName
      );

      state.medicalPlan = {
        ...state.medicalPlan,
        customServices: newCustomServices,
      };
    },
    resetStateCompleted: (state) => {
      state.sbcUpload = initialState.sbcUpload;
      state.displayedServices = initialState.displayedServices;
      state.medicalPlan = JSON.parse(JSON.stringify(new MedicalPlan()));
      state.editedMedicalPlan = JSON.parse(JSON.stringify(new MedicalPlan()));
      state.clonedDocumentReferences = initialState.clonedDocumentReferences;
      state.clonedState = initialState.clonedState;
      state.error = null;
    },
    setDisplayedServicesCompleted: (
      state,
      action: PayloadAction<PlanServiceObject[]>
    ) => {
      const { payload } = action;
      state.displayedServices = getOrderedDisplayServices(payload);
    },
    setNonDefaultValuesCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const { benefitCodes, medicalPlan } = payload;
      const displayedList = benefitCodes.filter(
        (service: PlanServiceObject) =>
          service && service.isSaasSupported && service.isDefault
      );

      const nonDefaultIds =
        medicalPlan.customServices
          ?.map((s: CustomPlan) => s.benefitCode.id)
          ?.filter(
            (s: string) =>
              !displayedList.find((d: PlanServiceObject) => d.id === s)
          ) || [];

      const nonDefaults =
        nonDefaultIds
          .map(
            (id: string) =>
              benefitCodes.find((s: PlanServiceObject) => s.id === id) ||
              ({} as PlanServiceObject)
          )
          .filter((s: PlanServiceObject) => s.id) || [];
      state.displayedServices = getOrderedDisplayServices([
        ...displayedList,
        ...nonDefaults,
      ]);
    },
    documentUploadStarted: (state, { payload }: PayloadAction<any>) => {
      state.cancelDocumentUpload = false;
      state.medicalPlan.documentReferences = {
        ...state.medicalPlan.documentReferences,
        [payload.documentType]: {
          uploading: true,
        },
      };
    },
    documentUploadCompleted: (state, { payload }: PayloadAction<any>) => {
      if (!state.cancelDocumentUpload) {
        state.medicalPlan.documentReferences = {
          ...state.medicalPlan.documentReferences,
          [payload.documentType]: {
            uploading: false,
            reference: payload.reference,
            blobUrl: payload.blobUrl,
            fileName: payload.fileName,
          },
        };
      }
    },
    clearSBCDocument: (state) => {
      if (!state.cancelDocumentUpload) {
        delete state.medicalPlan.documentReferences[
          MedicalPlanDocumentType.SBC.value
        ];
      }
    },
    removeBenefitDocuments: (state) => {
      removeBenefitDocumentsFromPlan(
        state.medicalPlan,
        state.clonedDocumentReferences,
        state.editedMedicalPlan
      );
    },
    documentUploadFailed: (state, { payload }: PayloadAction<any>) => {
      if (!state.cancelDocumentUpload) {
        state.medicalPlan.documentReferences = {
          ...state.medicalPlan.documentReferences,
          [payload.documentType]: {
            uploading: false,
            error: JSON.parse(JSON.stringify(payload.error)),
          },
        };
      }
    },
    getMedicalPlansListInProgress(state, action: PayloadAction) {
      state.medicalPlansList.inProgress = true;
      state.medicalPlansList.error = null;
    },
    getMedicalPlansListCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.medicalPlansList.inProgress = false;
      state.medicalPlansList.error = null;
      state.medicalPlansList.data = payload;
      state.requestType = '';
    },
    getMedicalPlansListFailed(state, action: PayloadAction<any>) {
      state.medicalPlansList.inProgress = false;
      state.medicalPlansList.error = action.payload;
      state.requestType = '';
    },
    getPlanContributionInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
    },
    getPlanContributionCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.medicalPlan.rates = payload.rates;
      state.editedMedicalPlan = payload;
      state.medicalRates = payload.rates;
      state.inProgress = false;
      state.requestType = action.type;
    },
    getLatestPlanContributionCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.editedMedicalPlan.rates = payload.rates;
      state.medicalRates = payload.rates;
      state.inProgress = false;
      state.requestType = action.type;
    },
    getPlanContributionFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = { response: action.payload };
      state.requestType = action.type;
    },
    getPlanByIdStarted: (state) => {
      state.inProgress = true;
      state.medicalPlan = {} as MedicalPlan;
      state.editedMedicalPlan = {} as MedicalPlan;
      state.error = null;
      state.medicalDocumentReferences = {};
    },
    getPlanByIdCompleted: (state, action: PayloadAction<MedicalPlan>) => {
      state.inProgress = false;
      state.medicalPlan = action.payload;
      state.editedMedicalPlan = action.payload;
      state.medicalRates = action.payload.rates;
      state.error = null;
      state.medicalDocumentReferences = action.payload?.documents;
    },
    getPlanByIdFailed: (state, action) => {
      state.inProgress = false;
      state.medicalPlan = {} as MedicalPlan;
      state.editedMedicalPlan = {} as MedicalPlan;
      state.error = { response: action.payload };
    },
    getHsaMedicalPlansListInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.hsaMedicalPlansList = [];
    },
    getHsaMedicalPlansListCompleted(state, action: PayloadAction<any>) {
      const { payload } = action;
      state.inProgress = false;
      state.error = null;
      state.hsaMedicalPlansList = payload;
    },
    getHsaMedicalPlansListFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = { response: action.payload };
      state.hsaMedicalPlansList = [];
    },
    clearMedicalPlanApiErrors: (state) => {
      state.error = null;
    },
    setUserAiFeedback: (
      state,
      { payload }: PayloadAction<AIUploaderFeedback>
    ) => {
      const existingFeedbackIndex = state.medicalPlan.planFeedback?.findIndex(
        (feedback) => feedback.section === payload.section
      );

      if (existingFeedbackIndex !== -1) {
        state.medicalPlan.planFeedback?.splice(
          existingFeedbackIndex!,
          1,
          payload
        );
      } else {
        state.medicalPlan.planFeedback?.push(payload);
      }
    },

    removeUserAiFeedback: (state, { payload }: PayloadAction<string>) => {
      const sectionToRemove = payload;
      state.medicalPlan.planFeedback = state.medicalPlan?.planFeedback?.filter(
        (feedback) => feedback?.section !== sectionToRemove
      );
    },
  },
});

export const getOrderedDisplayServices = (
  displayedServices: PlanServiceObject[]
) => {
  const nonOrderedDisplayedServices = displayedServices.map((service) => {
    const serviceCopy = cloneDeep(service);

    return serviceCopy;
  });

  const orderMapping = DEFAULT_SERVICE_CODES.MEDICAL.map(
    (benefitCode, index) => {
      return {
        code: benefitCode,
        index: index,
      };
    }
  );

  const defaultDisplayedServices = nonOrderedDisplayedServices.filter(
    (service) => service.isDefault
  );
  const nonDefaultDisplayedServices = nonOrderedDisplayedServices.filter(
    (service) => !service.isDefault
  );

  const orderedDefaultDisplayedServices = orderBy(
    defaultDisplayedServices.map((service) => {
      service.orderIndex =
        orderMapping.find((mapping) => mapping?.code === service.code)?.index ??
        nonOrderedDisplayedServices.length;
      return service;
    }) || '',
    'orderIndex',
    'asc'
  );

  return orderedDefaultDisplayedServices.concat(nonDefaultDisplayedServices);
};

export const {
  injectMedicalPlanDetails,
  sbcUploadingInprogress,
  sbcUploadingCompleted,
  sbcUploadingFailed,
  sbcUploadingStatusUpdate,
  cancelUploadingPdf,
  planServiceListInProgress,
  planServiceListCompleted,
  planServiceListFailed,
  getExtractedPlanDetailsInProgress,
  getExtractedPlanDetailsCompleted,
  getExtractedPlanDetailsFailed,
  updateMedicalPlanInProgress,
  updateMedicalPlanCompleted,
  updateMedicalPlanFailed,
  updateMedicalPlanServiceCompleted,
  removeMedicalPlanServiceCompleted,
  updateMedicalPlanByPathCompleted,
  resetStateCompleted,
  setDisplayedServicesCompleted,
  setNonDefaultValuesCompleted,
  documentUploadStarted,
  documentUploadCompleted,
  documentUploadFailed,
  getMedicalPlansListInProgress,
  getMedicalPlansListCompleted,
  getMedicalPlansListFailed,
  getPlanContributionInProgress,
  getPlanContributionCompleted,
  getLatestPlanContributionCompleted,
  getPlanContributionFailed,
  getPlanByIdStarted,
  getPlanByIdCompleted,
  getPlanByIdFailed,
  getHsaMedicalPlansListInProgress,
  getHsaMedicalPlansListCompleted,
  getHsaMedicalPlansListFailed,
  clearMedicalPlanApiErrors,
  setProcessedSBCData,
  clearSBCDocument,
  setUserAiFeedback,
  removeUserAiFeedback,
  removeBenefitDocuments,
} = medicalPlanSlice.actions;

export default medicalPlanSlice.reducer;

export const uploadSBC = (file: FormData) => {
  return (dispatch: Dispatch) => {
    dispatch(sbcUploadingInprogress());
    startTextractJob(file)
      .then((res) => {
        const jobId = res.data;
        dispatch(sbcUploadingCompleted(jobId));
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          dispatch(
            sbcUploadingFailed(JSON.parse(JSON.stringify(error.response)))
          );
        } else {
          console.error(error);
        }
      });
  };
};

export const uploadAiSBC = (
  file: File,
  employerId?: string,
  brokerId?: string
) => {
  return (dispatch: Dispatch) => {
    dispatch(sbcUploadingInprogress());
    initiateAiUploader(file, employerId, brokerId)
      .then((res) => {
        const jobId = res?.id;
        dispatch(sbcUploadingCompleted(jobId));
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          dispatch(
            sbcUploadingFailed(JSON.parse(JSON.stringify(error.response)))
          );
        } else {
          console.error(error);
        }
      });
  };
};

export const updateSBCStatus = (jobId: string) => {
  return (dispatch: Dispatch) => {
    getTextractJobStatus(jobId).then((res) => {
      dispatch(sbcUploadingStatusUpdate(res.data));
    });
  };
};

export const updateAiSBCStatus = (jobId: string) => {
  return (dispatch: Dispatch) => {
    getStatusAiSBC(jobId).then((res) => {
      dispatch(sbcUploadingStatusUpdate(res.data));
    });
  };
};

export const fetchExtractedPlanDetails =
  (
    jobId: string,
    employerId: string,
    benefitKind: string,
    onComplete: Function
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(getExtractedPlanDetailsInProgress());
    const planPromise = getExtractedPlanDetails(jobId);
    const benefitCodePromise = PlanService.getPlanServicesList(
      benefitKind,
      employerId
    );
    await Promise.all([planPromise, benefitCodePromise])
      .then((res) => {
        const medicalPlan = JSON.parse(JSON.stringify(res[0].data));
        const benefitCodes = JSON.parse(JSON.stringify(res[1].data));
        dispatch(
          setNonDefaultValuesCompleted({
            medicalPlan,
            benefitCodes,
          })
        );
        dispatch(
          getExtractedPlanDetailsCompleted({
            medicalPlan,
            benefitCodes,
          })
        );
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          dispatch(
            getExtractedPlanDetailsFailed(
              JSON.parse(JSON.stringify(error.response))
            )
          );
        } else {
          console.error(error);
        }
      });

    onComplete();
  };

export const getPlanServicesList =
  (benefitKind: string, employerId: string): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(planServiceListInProgress(benefitKind));
      const response = await PlanService.getPlanServicesList(
        benefitKind,
        employerId
      );
      sortAndStoreBenefitCodes(benefitKind, response.data, dispatch);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          planServiceListFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };

const sortAndStoreBenefitCodes = (
  benefitKind: string,
  allServiceTypes: PlanServiceObject[],
  dispatch: Dispatch
) => {
  const serviceTypes = DEFAULT_SERVICE_CODES[benefitKind]
    .map((serviceCode) => {
      return (
        allServiceTypes.find(
          (serviceType: PlanServiceObject) => serviceType.code === serviceCode
        ) || ({} as PlanServiceObject)
      );
    })
    .filter((s) => s.shortName);

  const displayedList: PlanServiceObject[] = serviceTypes.filter(
    (s) => s?.isDefault
  );
  dispatch(setDisplayedServicesCompleted([...displayedList]));

  // custom services
  serviceTypes.push(
    ...allServiceTypes.filter(
      (serviceType: PlanServiceObject) =>
        serviceType.code && serviceType.code >= 9000
    )
  );

  const payload = {
    options: allServiceTypes,
    defaults: serviceTypes,
    inProgress: false,
    benefitKind,
    error: null,
  };

  dispatch(planServiceListCompleted(payload));
};

export const saveMedicalPlan = (
  _plan: MedicalPlan,
  onSave: Function,
  benguideId?: string,
  cloneDocuments?: boolean,
  sourcePlanId?: string
) => {
  return async (dispatch: Dispatch) => {
    const plan = fixedUpMedicalPlanObj(_plan);
    try {
      dispatch(updateMedicalPlanInProgress());
      let response = {} as any;
      if (plan.id) {
        response = await PlanService.updateMedicalPlan(plan);
      } else {
        response = await PlanService.createMedicalPlan(plan);
        if (benguideId && response && response.data) {
          const benguidePlan: BenguidePlan = {
            planId: response.data.id,
            planRevisionId: response.data.revision,
            section: 'MEDICAL',
          };
          await PlanService.addPlanToBenguide(benguideId, benguidePlan);
        }
      }
      const data = response.data;
      // TODO - Need to refactor class MedicalPlan to type MedicalPlan
      dispatch(
        updateMedicalPlanCompleted({
          data: JSON.parse(JSON.stringify(data)),
          newPlan: !plan.id,
        })
      );
      if (data && cloneDocuments && plan.documents && sourcePlanId) {
        const clonePlanDoc: ClonePlanDocument = {
          employerId: data.employerId,
          sourcePlanId: sourcePlanId,
          targetPlanId: data.id,
          benefitCategory: BenefitCategory.MEDICAL.value,
          benefitKind: 'MEDICAL',
        };
        dispatch(clonePlanDocuments(clonePlanDoc));
      }
      onSave(response?.status === 200, data.id);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            updateMedicalPlanFailed({
              data: error.response.data,
            } as ErrorDetails)
          );
        }
      }
    }
  };
};

let abortController: AbortController;
let changedTier: string;

export const getPlanContributionByFrequency = (
  contributions: any,
  frequency: string,
  benefitGroup: string,
  rateTier: string,
  currentTier: string,
  isEdit?: boolean
) => {
  if (abortController && changedTier === rateTier) {
    abortController.abort(); // Tell the browser to abort request
  }
  if (typeof 'AbortController' !== 'undefined') {
    abortController = new AbortController();
  }
  changedTier = rateTier;
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getPlanContributionInProgress());
      const response = await PlanService.calculateMedicalPlanContributions(
        frequency,
        contributions,
        encodeURIComponent(benefitGroup),
        rateTier,
        currentTier,
        abortController.signal
      );
      const data = response.data;
      if (isEdit) {
        dispatch(
          getLatestPlanContributionCompleted(JSON.parse(JSON.stringify(data)))
        );
      } else {
        dispatch(
          getPlanContributionCompleted(JSON.parse(JSON.stringify(data)))
        );
      }
    } catch (error: any) {
      if (error.name === 'AbortError') {
        return; // Aborting request will return an error we don't want to handle in redux
      }
      if (axios.isAxiosError(error)) {
        dispatch(
          getPlanContributionFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const fixedUpMedicalPlanObj = (plan: MedicalPlan) => {
  const copyObject: MedicalPlan = cloneDeep(plan);
  copyObject.name = trim(copyObject.name || '');
  copyObject.planNetworkName = trim(copyObject.planNetworkName);
  copyObject.groupId = trim(copyObject.groupId);
  copyObject.rxBinNumber = trim(copyObject.rxBinNumber);
  copyObject.rxPcnNumber = trim(copyObject.rxPcnNumber);
  copyObject.rxGroupNumber = trim(copyObject.rxGroupNumber);

  if (copyObject.rates) {
    Object.values(copyObject.rates)?.forEach((rate: any) => {
      if (!rate.contributionType) {
        rate.contributionType = ContributionType.FIXED.value;
      } else {
        return;
      }
    });
  }

  // Remove not added services that came from SBC only in plan create mode
  if (!plan.id) {
    copyObject.customServices = copyObject.customServices?.filter(
      (plan) => plan.isDefault || plan.added
    );
  }

  copyObject.customServices = copyObject.customServices?.map((service) => {
    if (get(service, 'serviceValue.inNetwork')) {
      set(
        service,
        'serviceValue.inNetwork.info',
        trim(get(service, 'serviceValue.inNetwork.info'))
      );

      if (
        get(service, 'serviceValue.inNetwork.costSharingPolicy') ===
        BenefitCovered.CLEAR
      ) {
        set(
          service,
          'serviceValue.inNetwork.benefitCovered',
          BenefitCovered.CLEAR
        );
      }

      switch (get(service, 'serviceValue.inNetwork.costSharingPolicy')) {
        case CostType.OTHER:
          set(
            service,
            'serviceValue.inNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(get(service, 'serviceValue.inNetwork.info')) &&
            set(service, 'serviceValue.inNetwork', {});
          break;

        case CostType.COPAY: {
          set(
            service,
            'serviceValue.inNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(get(service, 'serviceValue.inNetwork.copay')?.toString()) &&
            set(service, 'serviceValue.inNetwork', {});
          break;
        }

        case CostType.COINSURANCE: {
          set(
            service,
            'serviceValue.inNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(
            get(service, 'serviceValue.inNetwork.coinsurance')?.toString()
          ) && set(service, 'serviceValue.inNetwork', {});
          break;
        }

        case CostType.NOT_APPLICABLE:
          set(
            service,
            'serviceValue.inNetwork.benefitCovered',
            BenefitCovered.NO
          );
          break;
        case CostType.NOT_COVERED: {
          set(
            service,
            'serviceValue.inNetwork.benefitCovered',
            BenefitCovered.NO
          );
          set(service, 'serviceValue.inNetwork.costSharingPolicy', null);
          break;
        }
      }
    }

    if (get(service, 'serviceValue.outOfNetwork')) {
      set(
        service,
        'serviceValue.outOfNetwork.info',
        trim(get(service, 'serviceValue.outOfNetwork.info'))
      );

      if (
        get(service, 'serviceValue.outOfNetwork.costSharingPolicy') ===
        BenefitCovered.CLEAR
      ) {
        set(
          service,
          'serviceValue.outOfNetwork.benefitCovered',
          BenefitCovered.CLEAR
        );
      }

      switch (get(service, 'serviceValue.outOfNetwork.costSharingPolicy')) {
        case CostType.OTHER:
          set(
            service,
            'serviceValue.outOfNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(get(service, 'serviceValue.outOfNetwork.info')) &&
            set(service, 'serviceValue.outOfNetwork', {});
          break;

        case CostType.COPAY:
          set(
            service,
            'serviceValue.outOfNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(
            get(service, 'serviceValue.outOfNetwork.copay')?.toString()
          ) && set(service, 'serviceValue.outOfNetwork', {});
          break;

        case CostType.COINSURANCE:
          set(
            service,
            'serviceValue.outOfNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(
            get(service, 'serviceValue.outOfNetwork.coinsurance')?.toString()
          ) && set(service, 'serviceValue.outOfNetwork', {});
          break;

        case CostType.NOT_COVERED: {
          set(
            service,
            'serviceValue.outOfNetwork.benefitCovered',
            BenefitCovered.NO
          );
          set(service, 'serviceValue.outOfNetwork.costSharingPolicy', null);
          break;
        }
      }
    }

    return service;
  });

  if (copyObject.rxCosts?.inNetwork) {
    Object.keys(copyObject.rxCosts?.inNetwork).forEach((key) => {
      (copyObject.rxCosts?.inNetwork[key] as CostSharing).info = trim(
        copyObject.rxCosts?.inNetwork[key].info
      );

      switch (copyObject.rxCosts?.inNetwork[key].costSharingPolicy) {
        case CostType.OTHER:
          copyObject.rxCosts.inNetwork[key].benefitCovered = BenefitCovered.YES;
          break;

        case CostType.COPAY:
          copyObject.rxCosts.inNetwork[key].benefitCovered = BenefitCovered.YES;
          isEmpty(copyObject.rxCosts?.inNetwork[key].copay?.toString()) &&
            delete copyObject.rxCosts?.inNetwork[key];
          break;

        case CostType.COINSURANCE:
          copyObject.rxCosts.inNetwork[key].benefitCovered = BenefitCovered.YES;
          isEmpty(copyObject.rxCosts?.inNetwork[key].coinsurance?.toString()) &&
            delete copyObject.rxCosts?.inNetwork[key];
          break;

        case CostType.NOT_APPLICABLE:
          copyObject.rxCosts.inNetwork[key].benefitCovered = BenefitCovered.NO;
          break;
        case CostType.NOT_COVERED:
          copyObject.rxCosts.inNetwork[key].benefitCovered = BenefitCovered.NO;
          copyObject.rxCosts.inNetwork[key].costSharingPolicy = null;
          break;
      }
    });
  }
  if (copyObject.rxCosts?.outOfNetwork) {
    Object.keys(copyObject.rxCosts?.outOfNetwork).forEach((key) => {
      (copyObject.rxCosts?.outOfNetwork[key] as CostSharing).info = trim(
        copyObject.rxCosts?.outOfNetwork[key].info
      );

      switch (copyObject.rxCosts?.outOfNetwork[key].costSharingPolicy) {
        case CostType.OTHER:
          copyObject.rxCosts.outOfNetwork[key].benefitCovered =
            BenefitCovered.YES;
          break;

        case CostType.COPAY:
          copyObject.rxCosts.outOfNetwork[key].benefitCovered =
            BenefitCovered.YES;
          isEmpty(copyObject.rxCosts?.outOfNetwork[key].copay?.toString()) &&
            delete copyObject.rxCosts?.outOfNetwork[key];
          break;

        case CostType.COINSURANCE:
          copyObject.rxCosts.outOfNetwork[key].benefitCovered =
            BenefitCovered.YES;
          isEmpty(
            copyObject.rxCosts?.outOfNetwork[key].coinsurance?.toString()
          ) && delete copyObject.rxCosts?.outOfNetwork[key];
          break;

        case CostType.NOT_COVERED:
          copyObject.rxCosts.outOfNetwork[key].benefitCovered =
            BenefitCovered.NO;
          copyObject.rxCosts.outOfNetwork[key].costSharingPolicy = null;
          break;
      }
    });
  }
  if (copyObject.mailOrderRxCosts?.inNetwork) {
    Object.keys(copyObject.mailOrderRxCosts?.inNetwork).forEach((key) => {
      (copyObject.mailOrderRxCosts?.inNetwork[key] as CostSharing).info = trim(
        copyObject.mailOrderRxCosts?.inNetwork[key].info
      );

      switch (copyObject.mailOrderRxCosts?.inNetwork[key].costSharingPolicy) {
        case CostType.OTHER:
          copyObject.mailOrderRxCosts.inNetwork[key].benefitCovered =
            BenefitCovered.YES;
          isEmpty(copyObject.mailOrderRxCosts?.inNetwork[key].info) &&
            delete copyObject.mailOrderRxCosts?.inNetwork[key];
          break;

        case CostType.COPAY:
          copyObject.mailOrderRxCosts.inNetwork[key].benefitCovered =
            BenefitCovered.YES;
          isEmpty(
            copyObject.mailOrderRxCosts?.inNetwork[key].copay?.toString()
          ) && delete copyObject.mailOrderRxCosts?.inNetwork[key];
          break;

        case CostType.COINSURANCE:
          copyObject.mailOrderRxCosts.inNetwork[key].benefitCovered =
            BenefitCovered.YES;
          isEmpty(
            copyObject.mailOrderRxCosts?.inNetwork[key].coinsurance?.toString()
          ) && delete copyObject.mailOrderRxCosts?.inNetwork[key];
          break;

        case CostType.NOT_APPLICABLE:
          copyObject.mailOrderRxCosts.inNetwork[key].benefitCovered =
            BenefitCovered.NO;
          break;
        case CostType.NOT_COVERED:
          copyObject.mailOrderRxCosts.inNetwork[key].benefitCovered =
            BenefitCovered.NO;
          copyObject.mailOrderRxCosts.inNetwork[key].costSharingPolicy = null;
          break;
      }
    });
  }
  if (copyObject.mailOrderRxCosts?.outOfNetwork) {
    Object.keys(copyObject.mailOrderRxCosts?.outOfNetwork).forEach((key) => {
      (copyObject.mailOrderRxCosts?.outOfNetwork[key] as CostSharing).info =
        trim(copyObject.mailOrderRxCosts?.outOfNetwork[key].info);

      switch (
        copyObject.mailOrderRxCosts?.outOfNetwork[key].costSharingPolicy
      ) {
        case CostType.OTHER:
          copyObject.mailOrderRxCosts.outOfNetwork[key].benefitCovered =
            BenefitCovered.YES;
          isEmpty(copyObject.mailOrderRxCosts?.outOfNetwork[key].info) &&
            delete copyObject.mailOrderRxCosts?.outOfNetwork[key];
          break;

        case CostType.COPAY:
          copyObject.mailOrderRxCosts.outOfNetwork[key].benefitCovered =
            BenefitCovered.YES;
          isEmpty(
            copyObject.mailOrderRxCosts?.outOfNetwork[key].copay?.toString()
          ) && delete copyObject.mailOrderRxCosts?.outOfNetwork[key];
          break;

        case CostType.COINSURANCE:
          copyObject.mailOrderRxCosts.outOfNetwork[key].benefitCovered =
            BenefitCovered.YES;
          isEmpty(
            copyObject.mailOrderRxCosts?.outOfNetwork[
              key
            ].coinsurance?.toString()
          ) && delete copyObject.mailOrderRxCosts?.outOfNetwork[key];
          break;

        case CostType.NOT_COVERED:
          copyObject.mailOrderRxCosts.outOfNetwork[key].benefitCovered =
            BenefitCovered.NO;
          copyObject.mailOrderRxCosts.outOfNetwork[key].costSharingPolicy =
            null;
          break;
      }
    });
  }

  // set coverage option to NOT_APPLICABLE if not chosen for deductibles and outOfPocket
  transformDeductibleForSave(copyObject);
  transformOOPMaxForSave(copyObject);
  transformDeductiblesAndOopMaxForSave(copyObject);

  // we don't want to save the extracted plan info
  delete copyObject.llmExtractionInfo;
  return copyObject;
};

export const updateMedicalPlan = (plan: MedicalPlan) => {
  return async (dispatch: Dispatch) => {
    // TODO - Need to refactor class MedicalPlan to type MedicalPlan
    if (plan.fundingType === FundingType.SELF_FUNDED) {
      plan = {
        ...plan,
        sfcPlanConfigVo: {
          employerId: plan.employerId,
          planYearId: plan.planYearId,
          carrierId: plan.benefitCarrier?.id,
          individualStopLoss: plan.individualStopLoss,
          individualAdministrationFee: plan.individualAdministrationFee,
          aggregatedStopLoss: plan.aggregatedStopLoss,
          aggregatedAdministrationFee: plan.aggregatedAdministrationFee,
          thirdPartyAdministrationFee: plan.thirdPartyAdministrationFee,
          otherFees: plan.otherFees,
          benefitKind: BenefitCategory.MEDICAL.value,
        },
        sfcPlanDataVO: {
          selfFundedClaimsStatus: true,
        },
      };
    } else {
      plan = {
        ...plan,
        sfcPlanDataVO: {
          selfFundedClaimsStatus: false,
        },
      };
    }

    dispatch(
      updateMedicalPlanCompleted({
        data: JSON.parse(JSON.stringify(plan)),
        newPlan: false,
      })
    );
  };
};

export const updateMedicalPlanByPath = (
  path: string[],
  value: any,
  isDBGView: boolean = false
) => {
  return async (dispatch: Dispatch) => {
    // TODO - Need to refactor class MedicalPlan to type MedicalPlan
    dispatch(
      updateMedicalPlanByPathCompleted(
        JSON.parse(JSON.stringify({ path, value, isDBGView }))
      )
    );
  };
};

export const updateMedicalPlanService = (data: CustomPlan) => {
  return (dispatch: Dispatch) => {
    dispatch(
      // TODO - Need to refactor class MedicalPlan to type MedicalPlan
      updateMedicalPlanServiceCompleted(JSON.parse(JSON.stringify(data)))
    );
  };
};

export const removeMedicalPlanService = (data: BenefitCode) => {
  return (dispatch: Dispatch) => {
    dispatch(removeMedicalPlanServiceCompleted(data));
  };
};

export const resetPlanReduxStore = (
  benefitCategory: string,
  employerId: string | null
) => {
  return (dispatch: Dispatch) => {
    dispatch(resetStateCompleted());
    dispatch(getPlanServicesList(benefitCategory, employerId || ''));
  };
};
export const setDisplayedServices = (data: PlanServiceObject[]) => {
  return (dispatch: Dispatch) => {
    dispatch(setDisplayedServicesCompleted(data));
  };
};

export const uploadTemporaryDocument = (file: File, documentType: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(documentUploadStarted({ documentType }));
    PlanService.uploadPlanDocuments(file.name, file)
      .then(({ data }: AxiosResponse) => {
        dispatch(
          documentUploadCompleted({
            documentType,
            reference: data,
            fileName: file.name,
            blobUrl: URL.createObjectURL(file),
          })
        );
      })
      .catch((error: AxiosError) => {
        dispatch(documentUploadFailed({ documentType, error: error }));
      });
  };
};

export const getMedicalPlansList = (
  { page, size, sort, query }: PaginationConfig,
  employerId: string,
  planYearId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getMedicalPlansListInProgress());
    try {
      const response = await PlanService.getMedicalPlansList(
        page,
        size,
        sort,
        query,
        employerId,
        planYearId
      );
      dispatch(getMedicalPlansListCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getMedicalPlansListFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const findMedicalPlanById = (planId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPlanByIdStarted());
    try {
      const response = await PlanService.getMedicalPlanById(planId);
      const responseDataCopy = cloneDeep(response.data);
      responseDataCopy.customServices = response?.data?.customServices?.map(
        (service: CustomPlan) => {
          const clonedService = cloneDeep(service);
          clonedService.isDefault = true;
          return clonedService;
        }
      );
      responseDataCopy.individualStopLoss =
        responseDataCopy?.sfcPlanConfigVo?.individualStopLoss;
      responseDataCopy.individualAdministrationFee =
        responseDataCopy?.sfcPlanConfigVo?.individualAdministrationFee;
      responseDataCopy.aggregatedStopLoss =
        responseDataCopy?.sfcPlanConfigVo?.aggregatedStopLoss;
      responseDataCopy.aggregatedAdministrationFee =
        responseDataCopy?.sfcPlanConfigVo?.aggregatedAdministrationFee;
      responseDataCopy.thirdPartyAdministrationFee =
        responseDataCopy?.sfcPlanConfigVo?.thirdPartyAdministrationFee;
      responseDataCopy.otherFees = responseDataCopy?.sfcPlanConfigVo?.otherFees;

      dispatch(getPlanByIdCompleted(responseDataCopy));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(getPlanByIdFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
};

export const getHsaMedicalPlansList = (
  employerId: string,
  planYearIds: string[] | string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getHsaMedicalPlansListInProgress());
    try {
      const response = await PlanService.getMedicalHsaPlans(
        employerId,
        planYearIds,
        benefitKind
      );
      dispatch(getHsaMedicalPlansListCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getHsaMedicalPlansListFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const validateMedicalPlanName = (paylaod: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await PlanService.validatePlanName(paylaod, 'medicals');
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            updateMedicalPlanFailed({
              data: error.response.data,
            } as ErrorDetails)
          );
          return false;
        }
      }
    }
  };
};
