import { FC, ReactNode } from 'react';

import { Row, Steps } from 'antd';

import { ReactComponent as CurrentStepIcon } from 'assets/images/current-step.svg';

import styles from './stepper.module.less';

const { Step } = Steps;

type props = {
  headers: string[];
  activeStep: number;
  renderStepContent: () => ReactNode;
  changeCurrentStep: (arg1: number) => any;
  isEditMode?: boolean;
  isLastStepActive?: boolean;
  isDisabledStep?: (index: number) => boolean;
  stepperRightMargin?: boolean;
};

const customDot = (
  dot: any,
  { status, index }: { status: string; index: number }
): any => (status === 'process' ? processDotIcon() : otherDotIcon(status));

const processDotIcon = () => <CurrentStepIcon />;

const otherDotIcon = (status: string) => (
  <div className={styles.round}>
    <span
      className={
        status === 'wait'
          ? `${styles.waitIcon} ${styles.otherBullet}`
          : styles.otherBullet
      }
    >
      &bull;
    </span>
  </div>
);

const Stepper: FC<props> = ({
  headers,
  renderStepContent,
  activeStep,
  changeCurrentStep,
  isEditMode = false,
  isLastStepActive = false,
  isDisabledStep: _isDisabledStep,
  stepperRightMargin = true,
}) => {
  const isDisabledStep = (index: number) => {
    if (isLastStepActive) {
      if (isEditMode) {
        return false;
      }
      return activeStep === headers.length - 1 ? false : index > activeStep;
    } else {
      return activeStep === headers.length - 1
        ? index < activeStep
        : index > activeStep;
    }
  };

  return (
    <div>
      <div
        className={`${styles.progressIndicator} ${
          stepperRightMargin ? styles.finalStepperWrapper : ''
        }`}
      >
        <Steps
          current={activeStep}
          onChange={changeCurrentStep}
          progressDot={customDot}
        >
          {headers.map((header, index) => (
            <Step
              title={header}
              key={index}
              disabled={
                _isDisabledStep ? _isDisabledStep(index) : isDisabledStep(index)
              }
            />
          ))}
        </Steps>
        <Row>{renderStepContent()}</Row>
      </div>
    </div>
  );
};

export default Stepper;
