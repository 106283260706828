import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import ValidateTokenResponse from 'model/auth/ValidateTokenReponse';
import ErrorDetails from 'model/ErrorDetails';
import {
  adminSignup,
  validateAdminRegistrationToken,
} from 'modules/auth/services/AuthService';

interface RegisterUserState {
  inProgress: boolean;
  username: string;
  requestType: string;
  error: ErrorDetails | null;
}

const initialState = {
  inProgress: false,
  username: '',
  requestType: '',
  error: null,
} as RegisterUserState;

const registerUserSlice = createSlice({
  name: 'registerUser',
  initialState,
  reducers: {
    validateTokenStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
    },
    validateTokenSuccess(state, action: PayloadAction<ValidateTokenResponse>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.username = action.payload.username;
    },
    validateTokenFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = action.payload;
      state.username = '';
    },
    adminSignUpStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
      state.error = null;
    },
    adminSignUpSuccess(state, action: PayloadAction<string>) {
      state.inProgress = false;
      state.requestType = action.type;
    },
    adminSignUpFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = action.payload;
    },
  },
});

export const {
  validateTokenStarted,
  validateTokenSuccess,
  validateTokenFailed,
  adminSignUpStarted,
  adminSignUpSuccess,
  adminSignUpFailed,
} = registerUserSlice.actions;

export default registerUserSlice.reducer;

export const processRegistrationToken =
  (token: string) => async (dispatch: Dispatch) => {
    dispatch(validateTokenStarted());
    try {
      const response = await validateAdminRegistrationToken(token);
      dispatch(validateTokenSuccess(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorDetails = {
          data: error.response?.data,
        };
        dispatch(validateTokenFailed(errorDetails));
      } else {
        console.error(error);
      }
    }
  };

export const processSignUp =
  (token: string, password: string) => async (dispatch: Dispatch) => {
    dispatch(adminSignUpStarted());
    try {
      const response = await adminSignup(token, password);
      dispatch(adminSignUpSuccess(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorDetails = {
          data: error.response?.data,
        } as ErrorDetails;
        dispatch(adminSignUpFailed(errorDetails));
      } else {
        console.error(error);
      }
    }
  };
