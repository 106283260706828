import { FC } from 'react';

import Stepper from 'components/Stepper/Stepper';
import BasicInfo from 'modules/employers/components/BasicInfo/BasicInfo';
import AddERAdmins from 'modules/employers/components/ERAdmins/AddERAdmins';
import AssignTeamMembers from 'modules/employers/components/TeamMembers/AssignTeamMembers';
import AddBenefitClass from 'modules/employers/components/BenefitClass/AddBenefitClass';
import CompleteStep from 'modules/employers/components/CompleteStep/CompleteStep';

import styles from './addEmployer.module.less';

type Props = {
  closeModal: () => void;
  currentStep: number;
  onNextStep: () => void;
  changeCurrentStep: (step: number) => void;
  isModalOpen: boolean;
};

const AddEmployer: FC<Props> = (props) => {
  const {
    closeModal,
    currentStep,
    onNextStep,
    changeCurrentStep,
    isModalOpen,
  } = props;

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className={styles.step}>
            <BasicInfo nextStep={onNextStep} isModalOpen={isModalOpen} />
          </div>
        );
      case 1:
        return (
          <div className={styles.step}>
            <AddERAdmins nextStep={onNextStep} />
          </div>
        );
      case 2:
        return (
          <div className={styles.step}>
            <AssignTeamMembers nextStep={onNextStep} />
          </div>
        );
      case 3:
        return (
          <div className={styles.step}>
            <AddBenefitClass nextStep={onNextStep} />
          </div>
        );
      case 4:
        return (
          <div className={styles.step}>
            <CompleteStep closeModal={closeModal} />
          </div>
        );
      default:
        return;
    }
  };

  return (
    <div className={styles.stepWrapper}>
      <Stepper
        headers={[
          'Basic Info',
          'Add Employer Users',
          'Assign Team Members',
          'Plan Year & Benefit Classes',
          'Complete',
        ]}
        activeStep={currentStep}
        changeCurrentStep={changeCurrentStep}
        renderStepContent={renderStepContent}
      />
    </div>
  );
};

export default AddEmployer;
