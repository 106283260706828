import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { AdditionalPlan } from 'model/plans/AdditionalPlan';
import { LifePlan } from 'model/plans/LifePlan';
import AddLifePlan from 'modules/plans/life/components/AddLifePlan/AddLifePlan';
import AddVoluntaryBenefitPlan from 'modules/plans/voluntary/components/AddVoluntaryBenefitPlan/AddVoluntaryBenefitPlan';
import {
  deleteAdditionalDocument,
  removeAllCurrentSelectedPlanDocuments,
  removeDocumentReference,
  updateCurrentSelectedPlan,
  uploadTemporaryAdditionalDocument,
  uploadTemporaryDocument,
} from 'modules/renewals/slices/addProposalToPlanYearSlice';
import { ReviewModalFragment } from 'modules/renewals/types/renewalsTypes';
import { isVolLifePlan } from 'modules/renewals/utils/addToPlanYearUtil';

type Props = ReviewModalFragment;

const VoluntaryPlanReview = ({
  setState,
  type,
  state,
  benefitKind = '',
  formRef,
  benefitClasses,
  isModalVisible,
}: Props) => {
  const dispatch = useAppDispatch();
  const planYearName = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.basicDetails.planYearName
  );
  const planCreateType = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.selectPlanCreateType
  );
  const isPlanReviewed = useAppSelector(
    (state) =>
      state.renewals.addProposalToPlanYear.isCurrentSelectedPlanReviewed
  );
  const isPlanMerged = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.isCurrentSelectedPlanMerged
  );

  const isMerging =
    planCreateType === 'MERGE' && !isPlanReviewed && isPlanMerged;

  useEffect(() => {
    if (
      !state.documentReferences['PLAN_SUMMARY']?.uploading &&
      state.documentReferences['PLAN_SUMMARY']?.fileName !== undefined
    )
      dispatch(
        updateCurrentSelectedPlan({
          ...state,
          file: {},
          fileName: state.documents?.['PLAN_SUMMARY'] ?? '',
          documentAvailable: true,
        })
      );
    // eslint-disable-next-line
  }, [state.documentReferences]);

  const handleVolBenefitsFileUpload = (document: File) => {
    dispatch(uploadTemporaryDocument(document, 'PLAN_SUMMARY'));
  };

  const handleVolLifeFileUpload = (document: File, docType: string) => {
    dispatch(uploadTemporaryDocument(document, docType));
  };

  const handleFileRemove = () => {
    dispatch(removeAllCurrentSelectedPlanDocuments());
  };

  /**
   * Triggers the appropriate action based on the specified type.
   *
   *  @param {string} action - The type of action to perform ('ADD', 'DELETE', or 'EDIT').
   *  @param {boolean} isFile - A boolean indicating if the document is a file.
   *  @param {string} docName - The name of the document to be added, deleted, or edited.
   *  @param {any} file - The file object to be uploaded (used for 'ADD' and 'EDIT' actions).
   *  @param {string} webLink - The web link associated with the document (used for 'ADD' action).
   * @param {string} existingPlan - The name of the existing plan document to be replaced (used for 'EDIT' action).
   *
   * @throws Will throw an error if an unknown action is provided.
   */
  const actionTriggered = async (
    action: 'ADD' | 'DELETE' | 'EDIT',
    isFile: boolean,
    docName: string,
    file: any,
    webLink: string,
    existingPlan: string
  ) => {
    switch (action) {
      case 'ADD':
      case 'EDIT':
        if (action === 'EDIT') {
          dispatch(deleteAdditionalDocument(existingPlan, isFile));
        }
        await dispatch(
          uploadTemporaryAdditionalDocument(
            file,
            docName,
            false,
            isFile,
            webLink
          )
        );
        break;
      case 'DELETE':
        dispatch(deleteAdditionalDocument(docName, isFile));
        break;
      default:
        throw new Error(`Unknown action: ${action}`);
    }
  };

  if (isVolLifePlan(benefitKind)) {
    return (
      <AddLifePlan
        isEdit
        isReviewProp
        isCancelButtonClickConfirm
        disableInformationFieldHighlight
        ref={formRef}
        onPlanSave={setState}
        onReset={() => {}}
        onSaveClose={() => {}}
        plan={state as LifePlan}
        closePlanAddModal={() => {}}
        isReviewHighlight={isMerging}
        isReviewType={type}
        onFileUpload={handleVolLifeFileUpload}
        reviewNewPlanYear={planYearName}
        lifePlanType={benefitKind ?? ''}
        onFileRemoveAction={removeDocumentReference}
        documentReferences={state.documentReferences}
        reviewBenefitClasses={benefitClasses}
        isModalVisible={isModalVisible}
        actionTriggered={actionTriggered}
      />
    );
  }

  return (
    <AddVoluntaryBenefitPlan
      isEdit
      isReview
      isCloseConfirmed
      ref={formRef}
      isUploading={state.documentReferences['PLAN_SUMMARY']?.uploading}
      onPlanSave={setState}
      onClose={() => {}}
      closePlanAddModal={() => {}}
      benefitCategory={benefitKind}
      isReviewHighlight={isMerging}
      isReviewType={type}
      plan={state as AdditionalPlan}
      onFileChangeAction={handleVolBenefitsFileUpload}
      onFileRemoveAction={handleFileRemove}
      reviewNewPlanYear={planYearName}
      documentReferences={state.documentReferences}
      reviewBenefitClasses={benefitClasses}
      isModalVisible={isModalVisible}
      actionTriggered={actionTriggered}
    />
  );
};

export default VoluntaryPlanReview;
