import { Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Support } from 'assets/images/support.svg';

import styles from './noSupportTeamData.module.less';

const NoSupportTeamData = () => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div className={styles.noDataWrapper}>
      <div className={styles.icon}>
        <Support />
      </div>
      <div className={styles.title}>
        <h2>Support Team items are not set up</h2>
      </div>
      <div className={styles.subTitle}>
        <p>Start by setting it up below:</p>
      </div>

      <Button
        type="primary"
        className={styles.btn}
        onClick={() =>
          navigate(
            `/brokers/${params?.brokerId}/configuration/issues-log-provisioning/support`
          )
        }
      >
        Set up Support Team Account Log
      </Button>
    </div>
  );
};

export default NoSupportTeamData;
