import React, { useRef } from 'react';
import AddEnrollments from 'modules/plans/components/Enrollments/AddEnrollments/AddEnrollments';
import { useAppSelector } from 'hooks/redux';

type Props = {
  setState: Function;
  state: any;
  benefitKind: string;
};

const EnrollmentsInformation = ({ setState, state, benefitKind }: Props) => {
  const planCreateType = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.selectPlanCreateType
  );
  const isPlanReviewed = useAppSelector(
    (state) =>
      state.renewals.addProposalToPlanYear.isCurrentSelectedPlanReviewed
  );
  const isPlanMerged = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.isCurrentSelectedPlanMerged
  );

  const isMerging =
    planCreateType === 'MERGE' && !(isPlanReviewed === isPlanMerged);

  const enrollmentsRef = useRef<any>();
  return (
    <div>
      <AddEnrollments
        plan={state}
        benefitKind={benefitKind}
        onChange={setState}
        isEdit={true}
        ref={enrollmentsRef}
        isReviewHighlight={isMerging}
      />
    </div>
  );
};

export default EnrollmentsInformation;
