import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Row } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { SorterResult } from 'antd/es/table/interface';

import {
  useDeleteEmployeeMutation,
  useEmployeeListQuery,
  useGetEmployerPlanYearsListQuery,
} from 'modules/idCards/slices/idCardApiSlice';
import { setSelectedPlanYearId } from 'modules/idCards/slices/idCardSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import { showNotification } from 'modules/idCards/components/Notification/Notification';
import TablePagination from 'model/TablePagination';
import IdCardTable from 'modules/idCards/components/IdCardTable/IdCardTable';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import FileHistory from 'modules/idCards/pages/FileHistory/FileHistory';
import EmployerFeatureDisabledBanner from 'modules/tools/components/EmployerFeatureDisabledBanner/EmployerFeatureDisabledBanner';
import styles from './idCardHome.module.less';

const IdCardHome = () => {
  const { employerId = '' } = useParams();
  const dispatch = useAppDispatch();
  const employerName = useAppSelector((state) => state.layout.employer?.name);
  const selectedPlanYearId = useAppSelector(
    (state) => state.idCards.selectedPlanYearId
  );
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'individualName',
      field: 'individualName',
      order: 'ascend',
    } as SorterResult<any>,
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: '',
    },
    filters: {},
  });
  const [historyModalVisible, setHistoryModalVisible] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const { columnKey, order } = (paginationConfig.sorterInfo as any) || {};

  const {
    data: employeeList,
    isFetching: employeeListIsLoading,
    refetch: employeeListRefetch,
  } = useEmployeeListQuery(
    {
      planYearId: selectedPlanYearId ?? '',
      employerId: employerId ?? '',
      size: paginationConfig.filterInfo.limit,
      page: paginationConfig.paginationIndex,
      searchKey: paginationConfig.filterInfo.searchText,
      sort:
        order === undefined
          ? ''
          : order === 'descend'
          ? `-${columnKey}`
          : columnKey,
    },
    { refetchOnMountOrArgChange: true }
  );
  const {
    isLoading: isGetPlanYearsListLoading,
    isSuccess: isGetPlanYearListSuccess,
    data: planYearsList,
  } = useGetEmployerPlanYearsListQuery(
    {
      employerId,
    },
    { refetchOnMountOrArgChange: true }
  );
  const [deleteEmployee, { isLoading: isDeleteEmployeeLoading }] =
    useDeleteEmployeeMutation();

  const resetIdCardTable = () => {
    setSearchText('');
    setPaginationConfig({
      sorterInfo: {
        columnKey: 'individualName',
        field: 'individualName',
        order: 'ascend',
      } as SorterResult<any>,
      paginationIndex: 1,
      filterInfo: {
        limit: 10,
        offset: 0,
        searchText: '',
      },
      filters: {},
    });
  };

  /** Once the plan-years list is loaded. Set the default value for the filter.
   * Runs each time list is re-loaded i.e on page load */
  useEffect(() => {
    if (isGetPlanYearListSuccess && planYearsList) {
      const currentPlanYear = planYearsList?.find(
        (planYear) => planYear.current
      );

      if (!selectedPlanYearId) {
        dispatch(setSelectedPlanYearId({ planYearId: currentPlanYear?.id }));
      } else {
        /** If the current planYearId is one of the current employer's don't change it. */
        const isEmployersPlanYear = planYearsList?.find(
          (planYear) => planYear.id === selectedPlanYearId
        );
        if (!isEmployersPlanYear) {
          dispatch(setSelectedPlanYearId({ planYearId: currentPlanYear?.id }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planYearsList, dispatch]);

  const handleDeleteEmployee = async (employeeId: string) => {
    try {
      await deleteEmployee({ employeeId }).unwrap();
      showNotification({
        type: 'success',
        message: 'Employee Delete Successful',
        description: 'Employee was successfully deleted.',
      });
      employeeListRefetch();
    } catch {
      showNotification({
        type: 'error',
        message: 'Employee Delete Failed',
        description: 'The employee failed to delete. Please try again later.',
      });
    }
  };

  const handleChangeSelectedPlanYear = (planYearId: string) => {
    dispatch(setSelectedPlanYearId({ planYearId }));
    resetIdCardTable();
  };

  const handleCloseHistoryModal = () => {
    setHistoryModalVisible(false);
    employeeListRefetch();
    resetIdCardTable();
  };

  const getPlanYearSelectData = () => {
    if (!planYearsList) {
      return [];
    }

    return planYearsList.map((planYear) => ({
      value: planYear.id,
      label: planYear.name,
    }));
  };

  return (
    <>
      <EmployerFeatureDisabledBanner
        feature="ID_CARDS"
        featureName="ID Cards"
        employerId={employerId}
      />
      <div className={styles.idCardHomeWrapper}>
        <Row justify={'space-between'} align={'middle'}>
          <div className={styles.titleColumn}>
            <div className={styles.titleText}>{`${
              employerName || ''
            } Employee ID Cards`}</div>
            <div className={styles.planYearFilterRow}>
              <div>Plan Year: </div>
              <SingleSelectFilter
                data={getPlanYearSelectData()}
                inlineDropdown
                placeholder={''}
                showSearch={false}
                setFilterSelectedValue={handleChangeSelectedPlanYear}
                defaultValue={''}
                selectedValue={selectedPlanYearId ?? ''}
              />
            </div>
          </div>
          <Button
            className={styles.manageButton}
            onClick={() => setHistoryModalVisible(true)}
          >
            <SettingOutlined />
            Manage ID Card Imports
          </Button>
        </Row>

        <div className={styles.idCardTableWrapper}>
          <IdCardTable
            paginationConfig={paginationConfig}
            setPaginationConfig={setPaginationConfig}
            data={employeeList?.content}
            totalData={employeeList?.totalElements ?? 0}
            loading={
              employeeListIsLoading ||
              isGetPlanYearsListLoading ||
              isDeleteEmployeeLoading
            }
            setSearchText={setSearchText}
            searchText={searchText}
            onDeleteEmployee={handleDeleteEmployee}
          />
        </div>

        <FullScreenModal
          title={'Manage ID Card Imports'}
          visible={historyModalVisible}
          onCancel={handleCloseHistoryModal}
          footer={false}
          confirmTitle={false}
          destroyOnClose={true}
        >
          <FileHistory onClose={handleCloseHistoryModal} />
        </FullScreenModal>
      </div>
    </>
  );
};

export default IdCardHome;
