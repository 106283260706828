import { ChangeEvent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import { DataColumn } from 'components/DataTable/DataColumn';
import ToolList from 'components/ToolList/ToolList';
import {
  useGetBrokersQuery,
  useUpdateBrokerFeatureStatusMutation,
} from 'api/featureControl';
import TablePagination from 'model/TablePagination';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { FEATURE_KEYS } from 'constants/commonConstants';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import DefaultFeatureToggle from 'components/DefaultFeatureToggle/DefaultFeatureToggle';
import styles from './featureList.module.less';

const TechAIAssistantList = () => {
  const [updateStatus] = useUpdateBrokerFeatureStatusMutation({});
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>('');
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortOrder, setSortOrder] = useState<string>('name');
  const {
    data: featureWrappers = [],
    refetch: refetchBrokers,
    isFetching,
  } = useGetBrokersQuery({
    page: pageIndex,
    key: FEATURE_KEYS.AI_ASSISTANT,
    query: searchText,
    size: pageSize,
    sort: sortOrder,
  });

  useEffect(() => {
    refetchBrokers();
  }, [refetchBrokers, searchText]);

  const dataColumns: DataColumn[] = [
    {
      title: 'BROKER',
      dataIndex: 'brokerName',
      key: 'brokerName',
      width: '70%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.brokerName.localeCompare(b.brokerName),
      defaultSortOrder: 'ascend',
      render: (item) => {
        return (
          <OverflowPopover>
            <span className="text table-item-link">{item}</span>
          </OverflowPopover>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'LAST STATUS UPDATED',
      dataIndex: 'lastUpdatedTs',
      key: 'lastUpdatedTs',
      width: '50%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      render: (colData, record) => {
        const updatedAt = dayjs(colData).format('MMMM D, YYYY h:mm A');
        return isEmpty(colData) ? (
          <div>-</div>
        ) : (
          <div>
            {updatedAt}
            <br />
            <span className={styles.addedBy}>
              by {record.lastUpdatedByAdminName}
            </span>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'enabled',
      key: 'enabled',
      width: '17%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      render: (colData, record, index) => {
        return (
          <div>
            {colData && (
              <LinkButton
                onClick={() => {
                  navigate(
                    `/brokers/${record?.organizationId}/configuration/ai-assistant`
                  );
                }}
              >
                Manage Users
              </LinkButton>
            )}
          </div>
        );
      },
      align: 'left',
    },
    {
      title: 'ENABLED',
      dataIndex: 'enabled',
      key: 'enabled',
      width: '15%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      render: (colData, record, index) => {
        return (
          <DefaultFeatureToggle
            onConfirmToggle={() => {
              updateStatus({
                enabled: !colData,
                organizationId: record.organizationId,
                employerId: record.employerId,
                key: FEATURE_KEYS.AI_ASSISTANT,
              }).then(() => {
                refetchBrokers();
              });
            }}
            enabled={colData}
            recordName={record.brokerName}
            enableMessage={`Type "CONFIRM" in the box below to confirm`}
            title={'AI Assistant'}
            disableMessage={<></>}
          />
        );
      },
      align: 'left',
    },
  ];

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();

    setSearchText(_searchText);
  };

  useEffect(() => {
    setPaginationConfig(paginationConfig);
    setPageIndex(paginationConfig.paginationIndex);
    setPageSize(paginationConfig.filterInfo.limit);
    const { field, order } = (paginationConfig.sorterInfo as any) || {};
    if (isEmpty(order)) {
      setSortOrder('');
    } else if (field === 'brokerName' && order === 'descend') {
      setSortOrder('-name');
    } else if (field === 'brokerName' && order === 'ascend') {
      setSortOrder('name');
    }
  }, [paginationConfig, isFetching]);

  return (
    <div className={styles.featureTableWrapper}>
      <ToolList
        title="AI Assistant"
        columns={dataColumns}
        data={featureWrappers}
        hasBenefitsCategoryFilter={false}
        search={'Search Broker'}
        handleSearch={handleSearch}
        isLoading={isFetching}
      />
    </div>
  );
};

export default TechAIAssistantList;
