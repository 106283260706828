import { Col, Dropdown, Menu, Popover, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import React from 'react';
import FromCurrentDetailsFooter from 'modules/renewals/components/FromCurrentDetailsFooter/FromCurrentDetailsFooter';
import OfferCardOverview from 'modules/renewals/components/helperComponents/OfferCardOverview/OfferCardOverview';
import CreditsDiscountsDetailsFooter from 'modules/renewals/components/CreditsDiscountsDetailsFooter/CreditsDiscountsDetailsFooter';
import SummeryCommonTable from 'modules/renewals/components/helperComponents/SummeryCommonTable/SummeryCommonTable';
import { NewPlanTag } from 'modules/renewals/components/NewPlanTag/NewPlanTag';
import FromCurrentPercentage from 'modules/renewals/components/helperComponents/FromCurrentPercentage/FromCurrentPercentage';

import {
  IProposalBenefitsCardData,
  IProposalBenefitsTableRow,
} from 'modules/renewals/types/renewalsTypes';
import { getTypeIcon } from 'modules/renewals/utils/renewalsUtils';
import { OFFER_BENEFIT_TYPES } from 'modules/renewals/constants/renewalsConstants';
import {
  currencyFormatter,
  currencyFormatterNoDecimals,
  currencyFormatterWithoutZeros,
} from 'util/commonUtil';

import styles from 'modules/renewals/components/ProposalsBenefitsCard/ProposalsBenefitsCard.module.less';
import { getFileNameEllipsis } from 'util/stringUtil';
import { handleFileItemDownloadClickForPopulatedFileItem } from 'modules/renewals/services/RenewalService';
import { isNullOrUndefined } from 'modules/plans/utils';
import { FundingTypeTag } from 'components/StdFundingTypeTag/FundingTypeTag';

type Props = {
  headerElements?: React.ReactNode;
  onViewDescriptionClick?: () => void;
  hasCreditDiscountData?: boolean;
  data: IProposalBenefitsCardData;
};

const ProposalsBenefitsCard = ({
  headerElements,
  onViewDescriptionClick,
  hasCreditDiscountData = false,
  data,
}: Props) => {
  const {
    name,
    tableData,
    type,
    overviewData: {
      credits,
      eeCost,
      erCost,
      estimatedAnnualPremium,
      totalHraCost,
    },
    isPlaceHolder = false,
  } = data;

  const getFromCurrentAmount = (value: number): string => {
    const str = currencyFormatter(value);
    if (value > 0) {
      return `+${str}`;
    } else {
      return `${str}`;
    }
  };

  const getTableColumns = (
    image: string
  ): ColumnsType<IProposalBenefitsTableRow> => {
    switch (type) {
      case 'Voluntary Benefits':
        return [
          {
            title: <img className={styles.carrierLogo} src={image} />,
            key: 'name',
            dataIndex: 'name',
            width: '85%',
            render: (_, { name, newPlan }) => (
              <Row align="middle">
                {name}
                {newPlan && <NewPlanTag className={styles.newPlanTagWrapper} />}
              </Row>
            ),
          },
          {
            title: 'Plan Documents',
            key: 'documents',
            dataIndex: 'documents',
            width: '15%',
            render: (data, row) => {
              const documentsArray = row.planDocuments ? row.planDocuments : [];
              const adminActionsMenu = (attachments: any[]) => {
                return row.planDocuments?.length ? (
                  <Menu className={styles.scrollableMenu}>
                    {attachments.map((attachment) => {
                      return (
                        <Menu.Item
                          key={attachment.referenceId}
                          onClick={() => {
                            handleFileItemDownloadClickForPopulatedFileItem(
                              attachment.downloadUrl,
                              attachment.fileName
                            );
                          }}
                        >
                          <span className={styles.dropDownItem}>
                            {getFileNameEllipsis({
                              fileName: String(attachment?.fileName),
                              limit: 25,
                            })}
                          </span>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                ) : (
                  <></>
                );
              };
              return (
                <>
                  <Dropdown
                    trigger={['click']}
                    overlay={adminActionsMenu(documentsArray)}
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                        ? triggerNode.parentElement
                        : triggerNode
                    }
                  >
                    <p
                      className={styles.attachmentLabel}
                      onClick={(e) => e.preventDefault()}
                    >
                      {!row.planDocuments?.length ? (
                        <span>-</span>
                      ) : (
                        <span className={styles.attachmentText}>
                          {`${row?.planDocuments?.length} ${
                            row?.planDocuments?.length === 1
                              ? 'Document'
                              : 'Documents'
                          }`}
                        </span>
                      )}
                      {}
                    </p>
                  </Dropdown>
                </>
              );
            },
          },
        ];
      case 'Life & Disability':
        return [
          {
            title: <img className={styles.carrierLogo} src={image} />,
            key: 'name',
            dataIndex: 'name',
            width: '40%',
            render: (_, { name, newPlan }) => (
              <Row align="middle">
                {name}
                {newPlan && <NewPlanTag className={styles.newPlanTagWrapper} />}
              </Row>
            ),
          },
          {
            title: 'Enrollment',
            key: 'enrollment',
            dataIndex: 'enrollment',
            width: '30%',
            render: (_, { enrollment, newPlan = false }) =>
              newPlan ? '-' : enrollment ?? '-',
          },
          {
            title: 'Annual Premium',
            key: 'annualPremium',
            dataIndex: 'annualPremium',
            width: '15%',
            render: (data) => <div>{currencyFormatter(data)}</div> || '-',
          },
          {
            title: 'From Current',
            key: 'fromCurrent',
            dataIndex: 'fromCurrent',
            width: '15%',
            render: (data, obj) => {
              return (
                <div>
                  {data ? (
                    <>
                      {`${getFromCurrentAmount(data.value)} `}
                      {obj.isPlaceHolder || obj.newPlan ? (
                        <span className={styles.offset}>(N/A)</span>
                      ) : (
                        <FromCurrentPercentage
                          decimalPlaces={2}
                          singleLine={true}
                          showBrackets={true}
                          fontSize={14}
                          fontWeight={600}
                          percentage={data.percentage}
                        />
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              );
            },
          },
        ];
      default:
        return [
          {
            title: <img className={styles.carrierLogo} src={image} />,
            key: 'name',
            dataIndex: 'name',
            width: '40%',
            render: (_, { name, newPlan, fundingType }) => (
              <Row align="middle" className={styles.commonTablePlanName}>
                {name}
                {newPlan && <NewPlanTag className={styles.newPlanTagWrapper} />}
                {fundingType && <FundingTypeTag fundingType={fundingType} />}
              </Row>
            ),
          },
          {
            title: 'Enrollment',
            key: 'enrollment',
            dataIndex: 'enrollment',
            width: '15%',
            render: (_, { enrollment, newPlan = false }) =>
              newPlan && (enrollment === 0 || enrollment === null)
                ? '-'
                : enrollment ?? '-',
          },
          {
            title: 'ER/EE Splits',
            key: 'splits',
            dataIndex: 'splits',
            width: '15%',
            render: (data) => data || '-',
          },
          {
            title: 'Annual Premium',
            key: 'annualPremium',
            dataIndex: 'annualPremium',
            width: '15%',
            render: (data) => <div>{currencyFormatter(data)}</div> || '-',
          },
          {
            title: 'From Current',
            key: 'fromCurrent',
            dataIndex: 'fromCurrent',
            width: '15%',
            render: (data, { newPlan }) => (
              <div>
                {data && !newPlan ? (
                  <>
                    {`${getFromCurrentAmount(data.value)} `}
                    <FromCurrentPercentage
                      decimalPlaces={2}
                      singleLine={true}
                      showBrackets={true}
                      percentage={data.percentage}
                      fontSize={14}
                      fontWeight={600}
                    />
                  </>
                ) : (
                  '-'
                )}
              </div>
            ),
          },
        ];
    }
  };

  const isHra = (): boolean =>
    totalHraCost !== 0 && !isNullOrUndefined(totalHraCost);
  const isHsa = (): boolean => type === 'Medical' && erCost.erHSA !== 0;

  return (
    <>
      <div className={styles.wrapper}>
        <Row
          justify="space-between"
          align="middle"
          className={styles.headerSection}
        >
          <Col flex={'auto'}>
            <img src={getTypeIcon(type).icon} className={styles.headerIcon} />
            <span className={styles.headerText}>{type} Plans</span>
          </Col>
          <Col>{headerElements}</Col>
        </Row>
        <div className={styles.bodySection}>
          <Row className={styles.titleText}>{name}</Row>
          {type !== OFFER_BENEFIT_TYPES.VOLUNTARY_BENEFIT && (
            <Row
              justify="space-between"
              className={styles.overviewSection}
              wrap={false}
            >
              <OfferCardOverview
                className={styles.overviewItem}
                headerText="Total Benefits Cost"
                value={
                  estimatedAnnualPremium.value
                    ? currencyFormatterNoDecimals(estimatedAnnualPremium.value)
                    : '-'
                }
                footerElement={
                  <FromCurrentDetailsFooter
                    value={estimatedAnnualPremium.differenceValue}
                    percentage={estimatedAnnualPremium.differencePercentage}
                    isNaApplicable={isPlaceHolder}
                  />
                }
              />
              <OfferCardOverview
                className={styles.overviewItem}
                headerText={
                  <>
                    Employer Cost
                    {isHsa() && isHra() ? (
                      <Popover
                        content={
                          <>
                            <span>
                              ER HSA Funding :{' '}
                              {currencyFormatterWithoutZeros(erCost?.erHSA!)}
                            </span>
                            <br />
                            <span>
                              ER HRA Cost :{' '}
                              {currencyFormatterWithoutZeros(totalHraCost!)}
                            </span>
                          </>
                        }
                        overlayClassName={'popoverInner recommendedtooltip'}
                      >
                        <span className={styles.inclErHsaText}>
                          (Incl. ER HSA/HRA)
                        </span>
                      </Popover>
                    ) : // only hra exist
                    isHra() ? (
                      <Popover
                        content={`ER HRA Cost : ${currencyFormatterWithoutZeros(
                          totalHraCost!
                        )}`}
                        overlayClassName={'popoverInner recommendedtooltip'}
                      >
                        <span className={styles.inclErHsaText}>
                          (Incl. ER HRA)
                        </span>
                      </Popover>
                    ) : (
                      // only hsa
                      isHsa() && (
                        <Popover
                          content={`ER HSA Funding : ${currencyFormatterWithoutZeros(
                            erCost?.erHSA!
                          )}`}
                          overlayClassName={'popoverInner recommendedtooltip'}
                        >
                          <span className={styles.inclErHsaText}>
                            (Incl. ER HSA)
                          </span>
                        </Popover>
                      )
                    )}
                  </>
                }
                value={
                  erCost.value !== undefined
                    ? currencyFormatterNoDecimals(erCost.value)
                    : '-'
                }
                footerElement={
                  <FromCurrentDetailsFooter
                    value={erCost.differenceValue}
                    percentage={erCost.differencePercentage}
                    isNaApplicable={isPlaceHolder}
                  />
                }
              />
              {type !== 'Life & Disability' && (
                <OfferCardOverview
                  className={styles.overviewItem}
                  headerText="Employee Cost"
                  value={
                    eeCost.value !== undefined
                      ? currencyFormatterNoDecimals(eeCost.value)
                      : '-'
                  }
                  footerElement={
                    <FromCurrentDetailsFooter
                      value={eeCost.differenceValue}
                      percentage={eeCost.differencePercentage}
                    />
                  }
                />
              )}
              <OfferCardOverview
                className={styles.overviewItem}
                headerText="Credits & Discounts"
                value={
                  credits?.value
                    ? currencyFormatterNoDecimals(credits?.value)
                    : '-'
                }
                footerElement={
                  <CreditsDiscountsDetailsFooter
                    showViewDescription={hasCreditDiscountData}
                    onViewDescriptionClick={onViewDescriptionClick}
                  />
                }
              />
            </Row>
          )}
          <div className={styles.tableSection}>
            {tableData.map((data, idx) => (
              <SummeryCommonTable
                key={idx}
                data={data.rowData?.sort((a, b) => (a?.newPlan ? 1 : -1))}
                columns={getTableColumns(data.carrierLogo)}
                tableClassName={styles.table}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProposalsBenefitsCard;
