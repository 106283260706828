import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Col, Row, Tabs } from 'antd';

import ERAdminList from 'modules/admins/pages/ERAdminList/ERAdminList';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import PlanYearList from 'modules/employers/components/PlanYearList/PlanYearList';
import BenefitClassList from 'modules/employers/components/BenefitClass/BenefitClassList';
import EmployerBasicInfoView from 'modules/employers/components/BasicInfoView/BasicInfoView';

import {
  BASIC_INFO_TAB,
  BENEFIT_CLASSES_TAB,
  EMPLOYER_ADMINS_TAB,
  EMPLOYER_USERS,
  PLAN_YEARS_TAB,
} from 'modules/employers/constants/employerConstants';

import { useNavContext } from 'hooks/useNavContext';
import { employerBackButtonPermittedTypes } from 'constants/permittedConstants';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import { usePermitIf } from 'hooks/usePermitIf';
import { useAppSelector } from 'hooks/redux';
import { loginTypes } from 'constants/authConstants';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { EMPLOYER_MEMBER_RESTRICTED } from 'constants/commonConstants';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import styles from './employerInfo.module.less';

const { TabPane } = Tabs;

const getActionButtonTitle = (tabKey: string) => {
  switch (tabKey) {
    case EMPLOYER_ADMINS_TAB:
      return 'Add Employer Users';
    case PLAN_YEARS_TAB:
      return 'Add Plan Year';
    case BENEFIT_CLASSES_TAB:
      return 'Add Benefit Class';
    case BASIC_INFO_TAB:
      return 'Edit Basic Info';
    default:
      return '';
  }
};

const EmployerInfo: FC = () => {
  const { state }: any = useLocation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeTabKey, setActiveTabKey] = useState<string>(EMPLOYER_ADMINS_TAB);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isPlanYearDeleted, setIsPlanYearDeleted] = useState<boolean>(false);
  const { employer } = useNavContext();
  const onTabClick = (key: string) => {
    setActiveTabKey(key);
    setIsModalOpen(false);
  };

  const { brokerId } = useNavContext();
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);

  const navigationTechAdmin: { name: string; link: string }[] = [
    { name: 'All Employers', link: '/employers' },
  ];

  const navigationBrokerAdmin: { name: string; link: string }[] = [
    { name: 'All Employers', link: `/brokers/${brokerId}/employers ` },
  ];

  const getBreadCrumbData = () => {
    if (appBootInfo?.type === loginTypes.platform) {
      return navigationTechAdmin;
    }

    if (appBootInfo?.type === loginTypes.bokerAdmin) {
      return navigationBrokerAdmin;
    }
  };

  const securedBreadcrumb = usePermitIf(
    <BreadcrumbNavigation breadCrumbData={getBreadCrumbData()} />,
    employerBackButtonPermittedTypes,
    []
  );

  useEffect(() => {
    if (state && state.tab) {
      setActiveTabKey(state.tab);
    }
  }, [state]);

  const addEmployerUsersButton = (
    <PageActionButton
      type="primary"
      className={styles.actionButton}
      onClick={() => {
        setIsModalOpen(true);
        activeTabKey === BENEFIT_CLASSES_TAB && setIsEditMode(false);
      }}
    >
      {getActionButtonTitle(activeTabKey)}
    </PageActionButton>
  );

  const securedEmployerAddUsersButton = usePermitByUserType(
    addEmployerUsersButton,
    EMPLOYER_MEMBER_RESTRICTED
  );

  return (
    <div className={styles.employerInfo}>
      <Row>
        <Col span={24}>
          <Row>{securedBreadcrumb}</Row>
          <Row className={styles.headerRow}>
            <Col span={12}>
              <h1 className={styles.titleWrapper}>
                <OverflowPopover>
                  {employer ? employer.name : 'Employer'}
                </OverflowPopover>
                &nbsp;Details
              </h1>
            </Col>
            <Col span={12}>{securedEmployerAddUsersButton}</Col>
          </Row>
          <Tabs
            type="card"
            defaultActiveKey={(state && state.tab) || EMPLOYER_ADMINS_TAB}
            size="large"
            className={styles.employerTabs}
            onTabClick={onTabClick}
          >
            <TabPane tab={EMPLOYER_USERS} key={EMPLOYER_ADMINS_TAB}>
              <ERAdminList
                isModalOpen={
                  activeTabKey === EMPLOYER_ADMINS_TAB && isModalOpen
                }
                setIsModalOpen={setIsModalOpen}
              />
            </TabPane>
            <TabPane tab="Plan Years" key={PLAN_YEARS_TAB}>
              <PlanYearList
                setIsPlanYearDeleted={setIsPlanYearDeleted}
                isModalOpen={activeTabKey === PLAN_YEARS_TAB && isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </TabPane>
            <TabPane tab="Benefit Classes" key={BENEFIT_CLASSES_TAB}>
              <BenefitClassList
                isModalOpen={
                  activeTabKey === BENEFIT_CLASSES_TAB && isModalOpen
                }
                setIsModalOpen={setIsModalOpen}
                setIsEditMode={setIsEditMode}
                isEditMode={isEditMode}
                isDeleted={isPlanYearDeleted}
                setIsPlanYearDeleted={setIsPlanYearDeleted}
              />
            </TabPane>
            <TabPane tab="Basic Info" key={BASIC_INFO_TAB}>
              <EmployerBasicInfoView
                isModalOpen={activeTabKey === BASIC_INFO_TAB && isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default EmployerInfo;
