import { useAppDispatch, useAppSelector } from 'hooks/redux';
import AddLifePlan from 'modules/plans/life/components/AddLifePlan/AddLifePlan';

import { ReviewModalFragment } from 'modules/renewals/types/renewalsTypes';
import { LifePlan } from 'model/plans/LifePlan';
import {
  deleteAdditionalDocument,
  removeDocumentReference,
  uploadTemporaryAdditionalDocument,
  uploadTemporaryDocument,
} from 'modules/renewals/slices/addProposalToPlanYearSlice';

type Props = ReviewModalFragment;

const LifePlans = ({
  state,
  benefitKind,
  formRef,
  setState,
  benefitClasses,
  type,
  isModalVisible,
}: Props) => {
  const dispatch = useAppDispatch();
  const planYearName = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.basicDetails.planYearName
  );
  const planCreateType = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.selectPlanCreateType
  );
  const isPlanReviewed = useAppSelector(
    (state) =>
      state.renewals.addProposalToPlanYear.isCurrentSelectedPlanReviewed
  );
  const isPlanMerged = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.isCurrentSelectedPlanMerged
  );

  const isMerging =
    planCreateType === 'MERGE' && !isPlanReviewed && isPlanMerged;

  const handleFileUpload = (document: File, docType: string) => {
    dispatch(uploadTemporaryDocument(document, docType));
  };

  /**
   * Triggers the appropriate action based on the specified type.
   *
   *  @param {string} action - The type of action to perform ('ADD', 'DELETE', or 'EDIT').
   *  @param {boolean} isFile - A boolean indicating if the document is a file.
   *  @param {string} docName - The name of the document to be added, deleted, or edited.
   *  @param {any} file - The file object to be uploaded (used for 'ADD' and 'EDIT' actions).
   *  @param {string} webLink - The web link associated with the document (used for 'ADD' action).
   * @param {string} existingPlan - The name of the existing plan document to be replaced (used for 'EDIT' action).
   *
   * @throws Will throw an error if an unknown action is provided.
   */
  const actionTriggered = async (
    action: 'ADD' | 'DELETE' | 'EDIT',
    isFile: boolean,
    docName: string,
    file: any,
    webLink: string,
    existingPlan: string
  ) => {
    switch (action) {
      case 'ADD':
      case 'EDIT':
        if (action === 'EDIT') {
          dispatch(deleteAdditionalDocument(existingPlan, isFile));
        }
        await dispatch(
          uploadTemporaryAdditionalDocument(
            file,
            docName,
            false,
            isFile,
            webLink
          )
        );
        break;
      case 'DELETE':
        dispatch(deleteAdditionalDocument(docName, isFile));
        break;
      default:
        throw new Error(`Unknown action: ${action}`);
    }
  };

  return (
    <AddLifePlan
      isEdit={true}
      onReset={() => {}}
      reviewNewPlanYear={planYearName}
      isCancelButtonClickConfirm={false}
      closePlanAddModal={() => {}}
      lifePlanType={benefitKind ?? ''}
      onSaveClose={() => {}}
      plan={state as LifePlan}
      ref={formRef}
      isReviewType={type}
      isReviewHighlight={isMerging}
      isReviewProp={true}
      onFileUpload={handleFileUpload}
      onPlanSave={setState}
      onFileRemoveAction={removeDocumentReference}
      documentReferences={state.documentReferences}
      reviewBenefitClasses={benefitClasses}
      isModalVisible={isModalVisible}
      actionTriggered={actionTriggered}
    />
  );
};

export default LifePlans;
