import { FC, useEffect, useRef, useState } from 'react';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';

import Consultant from 'model/benefitsConsultation/Consultant';

import AddBenefitConsultant from 'modules/benefitsConsultation/components/AddBenefitConsultant/AddBenefitConsultant';

import {
  addRemoveConsultants,
  getConsultantsSummary,
  addRemoveConsultantsStarted,
  addRemoveConsultantsSuccess,
  getConsultantsSummaryStarted,
  getBrokerConsultantsListCompleted,
  addRemoveConsultantsFailed,
} from 'modules/benefitsConsultation/slices/brokerLevelSlice';
import ConsultantType from 'modules/benefitsConsultation/enums/ConsultantType';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';

type AddBrokerConsultantsProps = {
  visible: boolean;
  setVisible: Function;
};

const AddBrokerConsultants: FC<AddBrokerConsultantsProps> = (
  props: AddBrokerConsultantsProps
) => {
  const { visible, setVisible } = props;
  const [isFieldsEmpty, setIsFieldsEmpty] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { brokerId } = useNavContext();
  const activeBrokerAdminsList = useAppSelector(
    (state) => state.benefitsConsultation.brokerLevel.activeBrokerAdminsList
  );
  const externalBrokerConsultantsList = useAppSelector(
    (state) =>
      state.benefitsConsultation.brokerLevel.externalBrokerConsultantsList
  );
  const requestType = useAppSelector(
    (state) => state.benefitsConsultation.brokerLevel.requestType
  );
  const inProgress = useAppSelector(
    (state) => state.benefitsConsultation.brokerLevel.inProgress
  );

  const checkboxListSelectorRef = useRef<any>(null);
  const addExternalBCRef = useRef<any>(null);

  useEffect(() => {
    brokerId && dispatch(getConsultantsSummary(brokerId));
  }, [brokerId, dispatch]);

  useEffect(() => {
    if (requestType === getBrokerConsultantsListCompleted.type) {
      brokerId && dispatch(getConsultantsSummary(brokerId));
    }
  }, [brokerId, dispatch, requestType]);

  useEffect(() => {
    if (requestType === addRemoveConsultantsSuccess.type) setVisible(false);
  }, [requestType, setVisible]);

  const addBrokerConsultants = () => {
    const newConsultants =
      checkboxListSelectorRef?.current?.getConsultantsToBeAdded();
    let addedConsultantsList = newConsultants.filter(
      (consultant: Consultant) => consultant.isConsultant
    );
    addedConsultantsList = addedConsultantsList.map((consultant: Consultant) =>
      omit(consultant, ['previous'])
    );
    const consultantsList = activeBrokerAdminsList.map(
      (obj) =>
        addedConsultantsList.find((o: Consultant) => o.id === obj.id) || obj
    );

    let newExternalConsultants =
      addExternalBCRef?.current?.getExternalConsultantsToBeAdded();

    newExternalConsultants = newExternalConsultants.map((data: Consultant) => {
      return {
        ...data,
        type: ConsultantType.BROKER_EXTERNAL,
        organizationId: brokerId,
        isAssigned: true,
      };
    });

    if (!isEmpty(newExternalConsultants) || !isEmpty(addedConsultantsList)) {
      dispatch(
        addRemoveConsultants(
          addedConsultantsList,
          consultantsList,
          newExternalConsultants,
          externalBrokerConsultantsList.concat(newExternalConsultants)
        )
      ).then(() => {
        addExternalBCRef?.current?.resetData();
      });
    } else {
      if (isFieldsEmpty) {
        addExternalBCRef.current.resetData();
        setVisible(false);
      }
    }
  };

  return (
    <AddBenefitConsultant
      modalHeading="Add Broker Consultant"
      visible={visible}
      setVisible={setVisible}
      saveInProgress={
        inProgress && requestType === addRemoveConsultantsStarted.type
      }
      listIsLoading={
        inProgress && requestType === getConsultantsSummaryStarted.type
      }
      onSave={addBrokerConsultants}
      addExternalBCRef={addExternalBCRef}
      checkboxListSelectorRef={checkboxListSelectorRef}
      activeAdminsList={activeBrokerAdminsList}
      externalConsultantsList={externalBrokerConsultantsList}
      consultantTypeText="Broker Admins"
      addRemoveFailed={requestType === addRemoveConsultantsFailed.type}
      isFieldsEmpty={isFieldsEmpty}
      setIsFieldsEmpty={setIsFieldsEmpty}
    />
  );
};

export default AddBrokerConsultants;
