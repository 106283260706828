import React, { ReactNode } from 'react';
import styles from 'modules/renewals/components/AddPlanDetailsModal/layout/splitContainer.module.less';

type Props = {
  children: ReactNode[];
  hideLeft?: boolean;
  hideRight?: boolean;
  leftWeight: number;
  rightWeight: number;
};

const SplitContainer = ({
  children,
  leftWeight,
  rightWeight,
  hideLeft,
  hideRight,
}: Props) => {
  const [left, right] = children;
  return (
    <div className={styles.container}>
      {!hideLeft && <div style={{ flex: leftWeight }}>{left}</div>}
      {!hideRight && <div style={{ flex: rightWeight }}>{right}</div>}
    </div>
  );
};

export default SplitContainer;
