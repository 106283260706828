import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Title from 'antd/lib/typography/Title';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';

import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import SelectOptions from 'components/SelectOptions/SelectOptions';
import {
  CostType,
  PriorToDeductible,
  DENTAL_ADVANCED_PROFILE_PARAMETERS,
  VISION_ADVANCED_PROFILE_PARAMETERS,
} from 'modules/plans/constants';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  setDisplayedServices,
  updateMedicalPlanService,
} from 'modules/plans/slices/medicalPlanSlice';
import PlanServiceObject from 'model/PlanService';
import CustomPlan from 'model/plans/CustomPlan';
import CostSharing from 'model/plans/CostSharing';
import {
  setDisplayedDentalServices,
  updateDentalPlanService,
} from 'modules/plans/slices/dentalPlanSlice';
import {
  setDisplayedVisionServices,
  updateVisionPlanService,
} from 'modules/plans/slices/visionPlanSlice';
import useGetUserFeatureStatus from 'hooks/useGetUserFeatureStatus';
import { BenefitCategory, FEATURE_KEYS } from 'constants/commonConstants';
import { isNullOrUndefined } from 'modules/plans/utils';
import styles from './addCustomServiceModal.module.less';

const { Option } = Select;
const SERVICE_TYPE_CUSTOM = 'CUSTOM';

type RowValues = {
  costType: string;
  amount: string;
  frequency: string;
  priorToDeductible: string;
  info: string;
};
type AddCustomServiceModalProps = {
  displayedServices?: PlanServiceObject[];
  visible: boolean;
  onClose: Function;
  planTypeName: string;
  benefitKind: string;
  hsaCompatible: boolean;
  onPlanChange?: Function;
  addedCustomServices?: CustomPlan[];
  isAdvancedProfileEmployer?: boolean;
};
type DefaultValues = {
  costType: string;
  amount: string;
  frequency: string;
  priorToDeductible: string;
  info: string;
};
const AddCustomServiceModal: FC<AddCustomServiceModalProps> = (
  props: AddCustomServiceModalProps
) => {
  const {
    visible,
    onClose,
    planTypeName,
    benefitKind,
    displayedServices,
    hsaCompatible,
    onPlanChange,
    addedCustomServices,
    isAdvancedProfileEmployer = false,
  } = props;

  const defaultValues: DefaultValues = {
    costType: '',
    amount: '',
    frequency: '',
    priorToDeductible: hsaCompatible ? PriorToDeductible.NO : '',
    info: '',
  };

  const plan = useAppSelector((state) => state.plan.plans);
  const { planServiceList } = plan;
  const { dentalPlanServiceList } = useAppSelector(
    (state) => state.plan.dentalPlan
  );
  const { visionPlanServiceList } = useAppSelector(
    (state) => state.plan.visionPlan
  );

  const { displayedServices: medicalDisplayServices } = useAppSelector(
    (state) => state.plan.plans
  );

  const [serviceType, setServiceType] = useState<string | null>(null);
  const [serviceName, setServiceName] = useState<string>('');
  const [inNetwork, setInNetwork] = useState<RowValues>(defaultValues);
  const [outNetwork, setOutNetwork] = useState<RowValues>(defaultValues);
  const [nonDefaultServiceList, setNonDefaultServiceList] = useState<
    PlanServiceObject[]
  >([]);
  const [serviceTypes, setServiceTypes] = useState<PlanServiceObject[]>([]);

  const dispatch = useAppDispatch();
  const [checkboxSelectedOut, setCheckboxSelectedOut] =
    useState<boolean>(false);
  const [checkboxSelectedIn, setCheckboxSelectedIn] = useState<boolean>(false);

  const { defaults: medicalServiceTypes } = planServiceList;
  const { defaults: dentalServiceTypes } = dentalPlanServiceList;
  const { defaults: visionServiceTypes } = visionPlanServiceList;

  const { employerId, brokerId } = useParams();

  useEffect(() => {
    setInNetwork((prevState) => ({
      ...prevState,
      priorToDeductible: hsaCompatible ? PriorToDeductible.NO : '',
    }));

    setOutNetwork((prevState) => ({
      ...prevState,
      priorToDeductible: hsaCompatible ? PriorToDeductible.NO : '',
    }));
  }, [hsaCompatible]);

  const isAdvancedProfile = useGetUserFeatureStatus(
    brokerId,
    employerId,
    FEATURE_KEYS.ADVANCED_PROFILE,
    false
  );

  const isDVAdvancedProfile =
    isAdvancedProfileEmployer || (isAdvancedProfile && brokerId !== undefined);

  // filter non Default service list by already added non default services
  const getFilteredNonDefaultServiceList = () => {
    const nonDefaultServicesFiltered = nonDefaultServiceList.filter((s) => {
      return !addedCustomServices?.some(
        (service) => service?.benefitCode?.code === s?.code
      );
    });

    return nonDefaultServicesFiltered;
  };

  useEffect(() => {
    if (benefitKind === BenefitCategory.MEDICAL.value) {
      setServiceTypes(medicalServiceTypes);
    } else if (benefitKind === BenefitCategory.DENTAL.value) {
      setServiceTypes(dentalServiceTypes);
    } else if (benefitKind === BenefitCategory.VISION.value) {
      setServiceTypes(visionServiceTypes);
    }
  }, [
    benefitKind,
    medicalServiceTypes,
    visionServiceTypes,
    dentalServiceTypes,
  ]);

  useEffect(() => {
    const addedNonDefaultServices =
      medicalDisplayServices
        ?.filter((s) => {
          return isEmpty(plan.newPlan) && !s.isDefault && !s.added;
        })
        .map((s) => s.code) || [];

    const alreadyAddedServiceTypes =
      displayedServices
        ?.filter((s) => !addedNonDefaultServices.includes(s.code))
        ?.map((s) => s.shortName) || [];

    const nonDefaults = serviceTypes.filter((service: PlanServiceObject) => {
      return (
        service &&
        service.isSaasSupported &&
        !alreadyAddedServiceTypes.includes(service.shortName)
      );
    });
    setNonDefaultServiceList(nonDefaults);
  }, [
    displayedServices,
    serviceTypes,
    plan?.editedMedicalPlan?.customServices,
    plan.newPlan,
    medicalDisplayServices,
  ]);

  const resetFields = () => {
    form.resetFields();
    setServiceType(null);
    setServiceName('');
    setInNetwork(defaultValues);
    setOutNetwork(defaultValues);
    setCheckboxSelectedIn(false);
    setCheckboxSelectedOut(false);
  };

  const getNetworkObject = (inNetwork: RowValues) => {
    const {
      amount,
      priorToDeductible: copayPriorToDeductible,
      frequency: copayFrequency,
      costType: costSharingPolicy,
      info,
    } = inNetwork;

    const copay = costSharingPolicy === CostType.COPAY ? amount : null;
    const coinsurance =
      costSharingPolicy === CostType.COINSURANCE ? amount : null;

    return {
      copay,
      coinsurance,
      copayPriorToDeductible,
      info,
      benefitCovered: 'COVERED',
      copayFrequency,
      costSharingPolicy: CostType.OTHER,
    } as CostSharing;
  };

  const validateForm = async () => {
    try {
      await form.validateFields();
    } catch (errorInfo) {
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    const valid = await validateForm();

    if (!valid) {
      return;
    }

    const serviceTypeObject = {
      ...(nonDefaultServiceList.find((s) => s.id === serviceType) ||
        ({
          id: '',
          benefitKind: benefitKind,
          code: null,
          description: trim(serviceName),
          shortName: trim(serviceName),
          isSaasSupported: true,
          isDefault: false,
        } as PlanServiceObject)),
      added: true,
    };

    const customService = new CustomPlan();
    customService.benefitCode = JSON.parse(JSON.stringify(serviceTypeObject));
    customService.serviceValue.inNetwork = getNetworkObject(inNetwork);
    customService.serviceValue.outOfNetwork = getNetworkObject(outNetwork);
    const customServiceCopy: CustomPlan = JSON.parse(
      JSON.stringify(customService)
    );
    customServiceCopy.added = true;

    const existIndex =
      displayedServices?.findIndex(
        (service) =>
          !isEmpty(service.code) &&
          service.code === serviceTypeObject.code &&
          service.benefitKind === BenefitCategory.MEDICAL.value
      ) || (displayedServices || []).length;

    customServiceCopy.manualAdded = true;

    if (benefitKind === BenefitCategory.MEDICAL.value) {
      onPlanChange
        ? onPlanChange(customServiceCopy)
        : dispatch(updateMedicalPlanService(customServiceCopy));
    } else if (benefitKind === BenefitCategory.DENTAL.value) {
      onPlanChange
        ? onPlanChange(customServiceCopy)
        : dispatch(updateDentalPlanService(customServiceCopy));
    } else if (benefitKind === BenefitCategory.VISION.value) {
      onPlanChange
        ? onPlanChange(customServiceCopy)
        : dispatch(updateVisionPlanService(customServiceCopy));
    }

    const servicesCopy = [...(displayedServices || [])];

    const serviceOrderIndex = serviceTypes.findIndex(
      (s: PlanServiceObject) => s.code === serviceTypeObject.code
    );

    const insertIndex =
      serviceOrderIndex !== -1 && !serviceTypeObject.added
        ? serviceOrderIndex
        : servicesCopy.length;

    const indexToInsert = existIndex === -1 ? insertIndex : existIndex;
    const numItemsToRemove = existIndex === -1 ? 0 : 1;
    servicesCopy.splice(indexToInsert, numItemsToRemove, serviceTypeObject);

    if (benefitKind === BenefitCategory.MEDICAL.value) {
      dispatch(setDisplayedServices(servicesCopy));
    } else if (benefitKind === BenefitCategory.DENTAL.value) {
      dispatch(setDisplayedDentalServices(servicesCopy));
    } else if (benefitKind === BenefitCategory.VISION.value) {
      dispatch(setDisplayedVisionServices(servicesCopy));
    }

    resetFields();
    onClose();
  };

  const onCancel = () => {
    resetFields();
    onClose();
  };

  const duplicateValidator = (rule: any, value: string) => {
    const allServices = [
      ...nonDefaultServiceList,
      ...(displayedServices || []),
    ];
    const serviceNamesList = allServices.map((service) => service.shortName);
    const trimedValue = trim(value).toLowerCase();
    const exists = serviceNamesList.some(
      (s) => s.toLowerCase() === trimedValue
    );
    if (!exists) {
      return Promise.resolve();
    }
    return Promise.reject(new Error());
  };

  const modalTitle = (
    <div>
      <div>
        <Title level={3} className={styles.center}>
          Add {planTypeName} Service
        </Title>
      </div>
    </div>
  );

  const footer = (
    <div className={styles.footerWrapper}>
      <CancelButton
        onClick={onCancel}
        className={styles.cancelButton}
        withBorder={true}
      >
        Cancel
      </CancelButton>
      <SubmitButton
        key="submit"
        type="primary"
        onClick={onSubmit}
        className={styles.saveButton}
      >
        Add
      </SubmitButton>
    </div>
  );

  const [form] = Form.useForm();

  const getNetworkValueForForm = (network: CostSharing | undefined) => {
    const amount = isNullOrUndefined(network?.copay)
      ? network?.coinsurance
      : network?.copay;
    return {
      costType: network?.costSharingPolicy || '',
      amount: isNullOrUndefined(amount) ? '' : amount || '0',
      frequency: network?.copayFrequency || '',
      priorToDeductible: network?.copayPriorToDeductible || '',
      info: network?.info || '',
    };
  };

  return (
    <Modal
      wrapClassName={styles.modalWrapper}
      title={modalTitle}
      width={740}
      footer={footer}
      visible={visible}
      onOk={() => onSubmit()}
      onCancel={() => onCancel()}
      maskClosable={false}
      closable={false}
    >
      <Form form={form}>
        <Row>
          <Col span={5}>
            <div className={styles.fieldLabel}>
              <strong>Select Service</strong>
            </div>
          </Col>
          <Col span={19}>
            <Form.Item
              name="Service Type"
              className={styles.planName}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please select a service',
                },
              ]}
            >
              <SelectOptions
                value={serviceType}
                onChange={(value) => {
                  setServiceType(value);
                  const { code, benefitKind } =
                    nonDefaultServiceList.find((s) => s.id == value) || {};
                  const selectedService =
                    plan.editedMedicalPlan.customServices?.find(
                      (s) =>
                        s.benefitCode.code === code &&
                        s.benefitCode.benefitKind === benefitKind
                    );

                  setInNetwork(
                    getNetworkValueForForm(
                      selectedService?.serviceValue.inNetwork
                    )
                  );

                  setOutNetwork(
                    getNetworkValueForForm(
                      selectedService?.serviceValue.outOfNetwork
                    )
                  );
                  setCheckboxSelectedIn(false);
                  setCheckboxSelectedOut(false);
                  setServiceName('');
                  form.resetFields(['Service Name']);
                }}
              >
                {(addedCustomServices
                  ? getFilteredNonDefaultServiceList()
                  : nonDefaultServiceList
                )
                  .filter((service) => {
                    if (
                      !isDVAdvancedProfile &&
                      planTypeName === BenefitCategory.DENTAL.label
                    ) {
                      return !DENTAL_ADVANCED_PROFILE_PARAMETERS.includes(
                        service.shortName
                      );
                    }
                    if (
                      !isDVAdvancedProfile &&
                      planTypeName === BenefitCategory.VISION.label
                    ) {
                      return !VISION_ADVANCED_PROFILE_PARAMETERS.includes(
                        service.shortName
                      );
                    }
                    return true;
                  })
                  .map((service, i) => (
                    <Option value={service.id} key={i}>
                      {service.shortName}
                    </Option>
                  ))}
                <Option
                  value={SERVICE_TYPE_CUSTOM}
                  className={styles.customServiceOption}
                >
                  + Add Custom Service
                </Option>
              </SelectOptions>
            </Form.Item>
          </Col>
        </Row>
        {serviceType === SERVICE_TYPE_CUSTOM && (
          <Row>
            <Col span={5}>
              <div className={styles.fieldLabel}>
                <strong>Service Name</strong>
              </div>
            </Col>
            <Col span={19}>
              <Form.Item
                name="Service Name"
                className={styles.serviceNameField}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please specify a valid service name',
                  },
                  {
                    validator: duplicateValidator,
                    message: 'This service name already exists',
                  },
                ]}
              >
                <Input
                  value={serviceName}
                  onChange={(event) => setServiceName(event.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row className={styles.titleRow}>
          <Col span={5}></Col>
          <Col span={15}>Amount/Frequency </Col>
          {planTypeName !== BenefitCategory.VISION.label ? (
            <Col span={4}>Prior to Deductible</Col>
          ) : (
            ''
          )}
        </Row>
        <Row>
          <Col span={5} className={styles.fieldLabel}>
            In-Network
          </Col>
          <Col span={14}>
            <Input
              onChange={(event: any) =>
                setInNetwork({ ...inNetwork, info: event.target.value })
              }
              value={inNetwork.info}
            />
          </Col>

          {planTypeName !== BenefitCategory.VISION.label ? (
            <Col span={4}>
              <Checkbox
                className={styles.checkboxTable}
                disabled={inNetwork.costType === CostType.NOT_COVERED}
                onChange={(e) => {
                  setCheckboxSelectedIn(!checkboxSelectedIn);
                  setInNetwork({
                    ...inNetwork,
                    priorToDeductible: e.target.checked ? 'YES' : 'NO',
                  });
                }}
                checked={checkboxSelectedIn}
              ></Checkbox>
            </Col>
          ) : (
            <Col></Col>
          )}
        </Row>
        <Row className={styles.mt10}>
          <Col span={5} className={styles.fieldLabel}>
            Out-of-Network
          </Col>

          <Col span={14}>
            <Input
              onChange={(event: any) =>
                setOutNetwork({ ...outNetwork, info: event.target.value })
              }
              value={outNetwork.info}
            />
          </Col>

          {planTypeName !== BenefitCategory.VISION.label ? (
            <Col span={4}>
              <Checkbox
                className={styles.checkboxTable}
                disabled={outNetwork.costType === CostType.NOT_COVERED}
                onChange={(e) => {
                  setCheckboxSelectedOut(!checkboxSelectedOut);
                  setOutNetwork({
                    ...outNetwork,
                    priorToDeductible: e.target.checked ? 'YES' : 'NO',
                  });
                }}
                checked={checkboxSelectedOut}
              ></Checkbox>
            </Col>
          ) : (
            <Col></Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export default AddCustomServiceModal;
