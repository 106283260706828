import React from 'react';
import { Col } from 'antd';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import styles from './brokerLocationStatus.module.less';

type Props = {
  record: any;
  locationModalRef: any;
};

const BrokerLocationStatus = (props: Props) => {
  const { record, locationModalRef } = props;
  return (
    <Col>
      {record?.allLocationsCount === record?.enabledLocationsCount ? (
        <LinkButton
          onClick={() => {
            locationModalRef.current?.openLocationModal(
              record?.enabled,
              record
            );
          }}
        >
          All locations
        </LinkButton>
      ) : record?.enabledLocationsCount === 0 ? (
        <div className={styles.disabledTextToggle}>Disabled</div>
      ) : (
        <LinkButton
          onClick={() => {
            locationModalRef.current?.openLocationModal(
              record?.enabled,
              record
            );
          }}
        >
          {record?.enabledLocationsCount} of {record?.allLocationsCount}{' '}
          locations
        </LinkButton>
      )}
    </Col>
  );
};

export default BrokerLocationStatus;
