import { camelCase, find, isEmpty, startCase } from 'lodash';
import { usStates } from 'constants/commonConstants';
import Address from 'model/Address';

export const getAvatarName = (name: string) => {
  const avatarName = '';
  return name.split(' ').map((word) => {
    if (word[0] !== undefined) {
      return avatarName.concat(word[0]);
    }
    return '';
  });
};

export const buildFullName = (firstName: string, lastName: string): string => {
  return `${firstName} ${lastName}`;
};

const addNumberAtIndex = (
  originalNumber: any,
  numberToAdd: number,
  index: number
): Number => {
  const originalConverted = Number(originalNumber);
  if (typeof originalConverted !== 'number' || isNaN(originalConverted)) {
    return NaN; // Return NaN if the originalNumber is not a valid number
  }
  const numberString = originalNumber.toString();
  const newNumberString =
    numberString.slice(0, index) +
    numberToAdd.toString() +
    numberString.slice(index);

  return parseFloat(newNumberString);
};

export const isInputNumberValidation = (evt: any) => {
  const { selectionStart, value } = evt.target;
  const inputValueUpdated =
    value.slice(0, selectionStart) + evt.data + value.slice(selectionStart);
  const isValidNumber = /^\d*\.?\d{0,2}$/.test(inputValueUpdated.toString());
  if (!isValidNumber) evt.preventDefault();
};

export const isNumber = (evt: any) => {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  const inputVal = evt.target.value;
  const inputValUpdated = addNumberAtIndex(
    inputVal,
    evt.key,
    evt.target.selectionStart
  );
  if ((charCode > 31 && charCode < 48) || charCode > 57) {
    if (charCode === 46 && inputVal.indexOf('.') === -1) {
      return;
    }
    evt.preventDefault();
  } else if (inputValUpdated?.toString()?.split('.')?.[1]?.length > 2) {
    evt.preventDefault();
  }
};

export const buildInitials = (firstName: string, lastName: string): string => {
  return `${firstName.trim().charAt(0)} ${lastName.trim().charAt(0)}`;
};

export const buildAddress = (address: Address): string => {
  const selectedUsState = find(usStates, { value: address.state }) || {
    label: '',
  };

  if (address) {
    return `${address.addressLine1}, ${
      address.addressLine2 !== null ? `${address.addressLine2},` : ''
    } ${address.city} ${selectedUsState.label}, ${address.zipCode}`;
  }
  return '';
};

export const isContainsDataInArray = (
  searchWorkArray: string[],
  name: string
): boolean => {
  const data = searchWorkArray.find((value) =>
    name.toLowerCase().includes(value)
  );
  return !!(data && data.length > 0);
};

export const getFileNameEllipsis = ({
  fileName,
  limit = 25,
}: {
  fileName: string;
  limit?: number;
}) => {
  const splittedString: string[] = fileName.split('.');
  const [documentName, fileExtension] = splittedString;
  const truncatedString: string =
    documentName.length > limit
      ? `${documentName.slice(0, limit - 1)}...${documentName.substring(
          documentName.length - 5
        )}.${fileExtension}`
      : `${fileName}`;

  return truncatedString;
};

export const toStringToProperCase = (str: string) => {
  return startCase(camelCase(str));
};

export const getTextEllipsis = (str: string, limit: number) => {
  return str.length > limit ? str.slice(0, limit - 1) + '...' : str;
};

export const getStateAbbreviation = (state: string) => {
  const states = [
    ['Arizona', 'AZ'],
    ['Alabama', 'AL'],
    ['Alaska', 'AK'],
    ['Arkansas', 'AR'],
    ['California', 'CA'],
    ['Colorado', 'CO'],
    ['Connecticut', 'CT'],
    ['Delaware', 'DE'],
    ['Florida', 'FL'],
    ['Georgia', 'GA'],
    ['Hawaii', 'HI'],
    ['Idaho', 'ID'],
    ['Illinois', 'IL'],
    ['Indiana', 'IN'],
    ['Iowa', 'IA'],
    ['Kansas', 'KS'],
    ['Kentucky', 'KY'],
    ['Louisiana', 'LA'],
    ['Maine', 'ME'],
    ['Maryland', 'MD'],
    ['Massachusetts', 'MA'],
    ['Michigan', 'MI'],
    ['Minnesota', 'MN'],
    ['Mississippi', 'MS'],
    ['Missouri', 'MO'],
    ['Montana', 'MT'],
    ['Nebraska', 'NE'],
    ['Nevada', 'NV'],
    ['New Hampshire', 'NH'],
    ['New Jersey', 'NJ'],
    ['New Mexico', 'NM'],
    ['New York', 'NY'],
    ['North Carolina', 'NC'],
    ['North Dakota', 'ND'],
    ['Ohio', 'OH'],
    ['Oklahoma', 'OK'],
    ['Oregon', 'OR'],
    ['Pennsylvania', 'PA'],
    ['Rhode Island', 'RI'],
    ['South Carolina', 'SC'],
    ['South Dakota', 'SD'],
    ['Tennessee', 'TN'],
    ['Texas', 'TX'],
    ['Utah', 'UT'],
    ['Vermont', 'VT'],
    ['Virginia', 'VA'],
    ['Washington', 'WA'],
    ['West Virginia', 'WV'],
    ['Wisconsin', 'WI'],
    ['Wyoming', 'WY'],
  ];

  state = state.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
  for (let i = 0; i < states.length; i++) {
    if (states[i][0] == state) {
      return states[i][1];
    }
  }

  return state;
};

export const checkIfEmpty = (value: any) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'string' && value.trim() === '')
  );
};

export const buildCommaSeparatedString = (strings: string[]) => {
  strings = strings.filter((str, idx, arr) => arr.indexOf(str) === idx);
  if (strings.length === 0) {
    return undefined;
  } else if (strings.length === 1) {
    return strings[0];
  } else if (strings.length === 2) {
    return `${strings[0]} and ${strings[1]}`;
  } else {
    let result = '';
    strings.forEach((str, idx) => {
      if (idx === strings.length - 2) {
        result = result.concat(`${str} and `);
      } else if (idx === strings.length - 1) {
        result = result.concat(`${str}`);
      } else {
        result = result.concat(`${str}, `);
      }
    });
    return result;
  }
};

export const isEmptyOrUndefined = (value: any) => {
  return isEmpty(value) || value === undefined;
};

/**
 * Finds the position of the nth occurrence of a substring within a string.
 * If the substring is not found enough times, it returns the length of the string.
 *
 * @param {string} string - The main string to search within.
 * @param {string} subString - The substring to search for.
 * @param {number} index - The occurrence index to find.
 * @return {number} - The position of the nth occurrence of the substring or the length of the string.
 */
export const getStringPosition = (
  string: string,
  subString: string,
  index: number
): number => {
  let position = -1;
  while (index-- && position++ < string.length) {
    position = string.indexOf(subString, position);
    if (position === -1) break;
  }
  return position === -1 ? string.length : position;
};

/**
 * Calculates the node index and overflow position based on the accumulated length.
 *
 * @param {number[]} arr - The array of node lengths.
 * @param {number} pos - The target position within the accumulated lengths.
 * @return {[number, number]} - A tuple where the first element is the node index and the second element is the overflow position.
 */
export const getCurrentNodeOverflow = (
  arr: number[],
  pos: number
): [number, number] => {
  let acc = 0;
  let index = 0;

  for (let i = 0; i < arr.length; i++) {
    acc += arr[i];
    if (acc > pos) {
      return [index, pos - (acc - arr[i])];
    }
    index++;
  }

  return [index, pos - acc];
};
