import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import { ICreditsDiscountsDescriptionItem } from 'modules/renewals/types/renewalsTypes';
import { currencyFormatterWithoutZeros } from 'util/commonUtil';

import styles from 'modules/renewals/components/SingleCreditsDiscountsDescriptionModal/SingleCreditsDiscountsDescriptionModal.module.less';

type Props = {
  isOpen: boolean;
  setIsOpen: Function;
  data: ICreditsDiscountsDescriptionItem;
};

const SingleCreditsDiscountsDescriptionModal = ({
  isOpen,
  setIsOpen,
  data,
}: Props) => {
  const { description, type, value, offerName } = data;

  const handleCancelBtnClick = (): void => {
    setIsOpen(false);
  };

  return (
    <ConfirmationDialog
      visible={isOpen}
      title={type === 'CREDITS' ? 'Credits' : 'Discounts'}
      confirmText={''}
      onConfirm={() => {}}
      cancelText="Close"
      closeModal={handleCancelBtnClick}
      modalClassName={styles.modal}
      isConfirmApplicable={false}
    >
      <div className={styles.name}>{offerName}</div>
      <p>
        <text className={styles.value}>
          {`${currencyFormatterWithoutZeros(value)} - `}
        </text>
        {description}
      </p>
    </ConfirmationDialog>
  );
};

export default SingleCreditsDiscountsDescriptionModal;
