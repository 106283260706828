import { FC, useEffect, useRef, useState } from 'react';
import { Button, Col, Collapse, Popover, Row, Select, Switch } from 'antd';
import {
  CheckOutlined,
  DownOutlined,
  QuestionCircleOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { cloneDeep, isUndefined } from 'lodash';

import { useAppSelector } from 'hooks/redux';
import {
  exportErrorFile,
  exportTemplateFile,
  useGetEmployerPlanYearsListQuery,
  useImportFilePreviewMutation,
  useImportIdCardFileMutation,
} from 'modules/idCards/slices/idCardApiSlice';
import DocumentUploader from 'components/DocumentUploader/DocumentUploader';
import AlertMessage from 'components/Alert/AlertMessage';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { showNotification } from 'modules/idCards/components/Notification/Notification';
import IdCardTable from 'modules/idCards/components/IdCardTable/IdCardTable';

import {
  IMPORT_FILE_SIZE_ERROR_MSG,
  IMPORT_FILE_TYPE_ERROR_MSG,
  PREVIEW_PANEL_KEY,
  UPLOAD_PANEL_KEY,
} from 'modules/idCards/constants/IdCardConstants';
import { SIZE_VALIDATE } from 'util/commonUtil';
import { getDownloadFileObj, MIME_TYPES } from 'util/fileUtil';
import TablePagination from 'model/TablePagination';
import styles from './importFile.module.less';

type ImportFileProps = {
  selectedFile: File | null;
  setSelectedFile: Function;
  onClose: Function;
  onCancel: Function;
};

type InactivatedCounts = {
  dependents?: number;
  logins?: number;
  employees?: number;
};

const ImportFile: FC<ImportFileProps> = ({
  selectedFile,
  setSelectedFile,
  onClose,
  onCancel,
}) => {
  const { Panel } = Collapse;
  const { employerId = '' } = useParams();
  const documentUploaderRef = useRef(null);
  const employer = useAppSelector((state) => state.layout.employer?.name);
  const [selectedPlanYearId, setSelectedPlanYearId] = useState<string>();
  const [fatalErrorCode, setFatalErrorCode] = useState<string>();
  const [inactivatedCounts, setInactivatedCounts] =
    useState<InactivatedCounts>();
  const [errorRecordList, setErrorRecordList] = useState<any[]>();
  const [commonError, setCommonError] = useState<string>('');
  const [showCommonError, setShowCommonError] = useState<boolean>(false);
  const [activePanel, setActivePanel] = useState<string | string[]>(
    UPLOAD_PANEL_KEY
  );
  const [isNotificationActive, setIsNotificationActive] =
    useState<boolean>(true);
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: '',
    },
    filters: {},
  });
  const [pageEmployeeList, setPageEmployeeList] = useState<object[]>([]);
  const [alertBoxHeight, setAlertBoxHeight] = useState<number>(0);
  const alertBoxRef = useRef<HTMLDivElement>(null);

  const [importIdCard, { isLoading: importIdCardIsLoading }] =
    useImportIdCardFileMutation();
  const {
    isLoading: isGetPlanYearsListLoading,
    isSuccess: isGetPlanYearListSuccess,
    data: planYearsList,
  } = useGetEmployerPlanYearsListQuery({
    employerId,
  });
  const [
    importIdCardPreview,
    {
      data: importIdCardPreviewData,
      isLoading: importIdCardPreviewIsLoading,
      isError: importIdCardPreviewIsError,
      isSuccess: importIdCardPreviewIsSuccess,
      reset: importIdCardPreviewReset,
    },
  ] = useImportFilePreviewMutation();

  const isUploadSuccess =
    !importIdCardIsLoading &&
    importIdCardPreviewIsSuccess &&
    !importIdCardPreviewData?.containsCriticalError;

  const getSortByName = (dir: string) => (a: any, b: any) => {
    return (
      `${a.firstName} ${a.lastName}`?.localeCompare(
        `${b.firstName} ${b.lastName}`
      ) * (dir === 'ascend' ? 1 : -1)
    );
  };

  /**
   * Enables frontend pagination to be used when previewing id card import
   */
  useEffect(() => {
    if (importIdCardPreviewData !== undefined) {
      // Figure out pagination variables
      const pageNum = paginationConfig.paginationIndex;
      const pageSize = paginationConfig.filterInfo.limit;
      const start = (pageNum - 1) * pageSize;
      const end = pageNum * pageSize;
      const order = (paginationConfig.sorterInfo as any).order || 'ascend';

      // Sorting
      const sorter = getSortByName(order);
      const sortedData = [
        ...(importIdCardPreviewData.electionSummaries || []),
      ].sort(sorter);

      // Paging
      const pagedDataList = sortedData.slice(start, end);
      setPageEmployeeList(pagedDataList);

      // Setting error states
      if ((importIdCardPreviewData?.errorRecords?.length ?? 0) > 0) {
        setErrorRecordList(importIdCardPreviewData.errorRecords);
      }
    }
  }, [importIdCardPreviewData, paginationConfig, importIdCardIsLoading]);

  /** Once the plan-years list is loaded. Set the default value for the dropdown */
  useEffect(() => {
    if (planYearsList) {
      const currentPlanYear = planYearsList?.find(
        (planYear) => planYear.current
      );
      setSelectedPlanYearId(currentPlanYear?.id);
    }
  }, [planYearsList, isGetPlanYearListSuccess]);

  useEffect(() => {
    setAlertBoxHeight(alertBoxRef.current?.clientHeight ?? 0);

    // eslint-disable-next-line
  }, [fatalErrorCode]);

  const handleImport = async () => {
    try {
      setInactivatedCounts(undefined);
      setFatalErrorCode(undefined);
      const data = await importIdCardPreview({
        employerId: employerId,
        file: selectedFile,
        planYearId: selectedPlanYearId ?? '',
      }).unwrap();
      if (
        data.toBeDeactivatedLoginCount ||
        data.employeesToDeactivateCount ||
        data.dependantsToDeactivateCount
      ) {
        setInactivatedCounts({
          logins: data.toBeDeactivatedLoginCount,
          employees: data.employeesToDeactivateCount,
          dependents: data.dependantsToDeactivateCount,
        });
      }
      if (!data.containsCriticalError) {
        setActivePanel(PREVIEW_PANEL_KEY);
      }
    } catch (error) {
      const {
        data: { code },
      } = error as AxiosResponse<{ code: string }, any>;
      setFatalErrorCode(code);
    }
  };

  const handleNotificationToggle = (val: boolean) => {
    setIsNotificationActive(val);
  };

  const handlePublishFile = async (isCurrentPlanYear: boolean) => {
    try {
      await importIdCard({
        employerId: employerId,
        file: selectedFile,
        planYearId: selectedPlanYearId ?? '',
        isNotificationActive: isNotificationActive,
        isCurrentPlanYear: isCurrentPlanYear,
      }).unwrap();
      showNotification({
        type: 'success',
        message: 'Import Successful',
        description: 'Your ID Card file was successfully imported.',
      });
      onClose(false);
    } catch {
      showNotification({
        type: 'error',
        message: 'File Upload Failed',
        description:
          'The submitted file failed to upload. Please try again later.',
      });
    }
  };

  const handleDownloadTemplateFile = () => {
    exportTemplateFile();
  };

  const handleDownloadErrorFile = () => {
    exportErrorFile(selectedFile, errorRecordList);
  };

  const handleFileValidateFails = (error: any) => {
    if (error === SIZE_VALIDATE) {
      setCommonError(IMPORT_FILE_SIZE_ERROR_MSG);
    } else {
      setCommonError(IMPORT_FILE_TYPE_ERROR_MSG);
    }
    setShowCommonError(true);
  };

  const handleFileChange = (file: File | null) => {
    setSelectedFile(file);
    setFatalErrorCode(undefined);
    importIdCardPreviewReset();
    setCommonError('');
    setShowCommonError(false);
  };

  const getDropdownPlanYearsOptions = (): React.ReactNode[] => {
    const tempPlanYearsList = cloneDeep(planYearsList);
    return (
      tempPlanYearsList
        ?.filter((planYear) => planYear.current || planYear.next)
        ?.sort((a, b) => (a?.current ? -1 : a.next ? -1 : 1))
        ?.slice(0, 2)
        ?.map((planYear) => (
          <Select.Option key={planYear.id}>{planYear.name}</Select.Option>
        )) ?? []
    );
  };

  const getPreviewErrorAlertContent = () => {
    if (showCommonError) {
      return <p>{commonError}</p>;
    }

    if (importIdCardPreviewData?.containsCriticalError) {
      return (
        <>
          The submitted file contains errors, which are marked in this
          <span
            onClick={handleDownloadErrorFile}
            className={styles.errorFileLink}
          >
            {' error file '}
          </span>
          . Please fix any errors and try uploading again.
        </>
      );
    }

    return (
      <>
        <p>
          The submitted file can not be processed because an error was
          encountered
        </p>
        <ul className={styles.errorList}>
          <li>{fatalErrorCode}</li>
        </ul>
      </>
    );
  };

  const isUpcomingPlanYear = (planYearId: string) => {
    const planYear = planYearsList?.find(
      (planYear) => planYear.id === planYearId
    );
    if (!isUndefined(planYear)) {
      return planYear.next;
    } else {
      return false;
    }
  };

  const getErrorBoxStyle = () => {
    return fatalErrorCode
      ? alertBoxHeight > 180
        ? { marginTop: '135px' }
        : alertBoxHeight > 150
        ? { marginTop: '115px' }
        : alertBoxHeight > 130
        ? { marginTop: '85px' }
        : { marginTop: '65px' }
      : {};
  };

  return (
    <div className={styles.importFileWrapper}>
      <Row justify={'center'} className={styles.employerName}>
        {employer}
      </Row>
      <div>
        <div>
          <Collapse
            bordered={false}
            activeKey={activePanel}
            onChange={(key: string | string[]) => {
              setActivePanel(key);
            }}
            accordion
          >
            <Panel
              header={
                <Row
                  justify={'center'}
                  className={styles.collapseSectionHeader}
                >
                  {isUploadSuccess ? (
                    <CheckOutlined className={styles.collapseIcon} />
                  ) : (
                    ''
                  )}
                  <span className={styles.collapseTitle}>
                    Step 1: Select ID Card File
                  </span>
                </Row>
              }
              key={UPLOAD_PANEL_KEY}
              showArrow={false}
              extra={
                activePanel === UPLOAD_PANEL_KEY ? (
                  <DownOutlined />
                ) : (
                  <UpOutlined />
                )
              }
              className={styles.offerImporterPanel}
              collapsible={undefined}
            >
              <div className={styles.offerUploadWrapper}>
                <Row className={styles.planYearSelectRow}>
                  <span>Plan Year</span>
                  <Select
                    size="large"
                    value={selectedPlanYearId}
                    onChange={(id) => setSelectedPlanYearId(id)}
                    loading={isGetPlanYearsListLoading}
                    disabled={isGetPlanYearsListLoading}
                  >
                    {getDropdownPlanYearsOptions()}
                  </Select>
                </Row>
                <div className={styles.selectText}>
                  <span>Select ID Card File</span>
                  <Popover
                    content="You can populate and upload the template ID card file below to import ID card data."
                    placement="right"
                    overlayClassName="popoverInner"
                  >
                    <QuestionCircleOutlined className={styles.popoverIcon} />
                  </Popover>
                </div>
                <div className={styles.uploader}>
                  <Row>
                    <Col>
                      <DocumentUploader
                        ref={documentUploaderRef}
                        onChange={handleFileChange}
                        onRemove={() => handleFileChange(null)}
                        isRemoved={selectedFile === null}
                        allowedFileTypes={[MIME_TYPES.XLS, MIME_TYPES.XLSX]}
                        maxFileSizeMB={100}
                        defaultFile={null}
                        disabled={false}
                        hideNoFileMessage={true}
                        onFileNameClick={getDownloadFileObj}
                        onValidateFails={handleFileValidateFails}
                        selectFileName={'+ File'}
                        showCancelConfirmationBody={false}
                        overflowPopoverMaxWidth={'355px'}
                        isFileNameVisible={true}
                      />
                    </Col>
                    {(importIdCardPreviewIsError ||
                      showCommonError ||
                      importIdCardPreviewData?.containsCriticalError) && (
                      <Col
                        className={styles.fatalErrorNotification}
                        ref={alertBoxRef}
                      >
                        <AlertMessage
                          type={'error'}
                          message={getPreviewErrorAlertContent()}
                          closeText={''}
                          wrapperClassName={'offerUploadWrapper'}
                          className={styles.errorMsg}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
                <LinkButton
                  className={styles.templateFile}
                  onClick={handleDownloadTemplateFile}
                >
                  Template File
                </LinkButton>
                <Button
                  className={
                    !fatalErrorCode
                      ? styles.previewButton
                      : styles.previewButtonError
                  }
                  onClick={handleImport}
                  disabled={
                    selectedFile === null ||
                    importIdCardPreviewIsLoading ||
                    importIdCardPreviewIsError ||
                    importIdCardPreviewData?.containsCriticalError
                  }
                  loading={importIdCardPreviewIsLoading}
                  style={getErrorBoxStyle()}
                >
                  Import & Preview ID Card File
                </Button>
              </div>
            </Panel>
            <Panel
              header={
                <Row
                  justify={'center'}
                  className={styles.collapseSectionHeader}
                >
                  {isUploadSuccess ? (
                    <CheckOutlined className={styles.collapseIcon} />
                  ) : (
                    ''
                  )}
                  <span
                    className={
                      isUploadSuccess
                        ? styles.collapseTitle
                        : styles.collapseTitleDisabled
                    }
                  >
                    Step 2: Preview ID Card File
                  </span>
                </Row>
              }
              key={PREVIEW_PANEL_KEY}
              disabled={
                !importIdCardPreviewIsSuccess ||
                importIdCardPreviewData?.containsCriticalError
              }
              showArrow={false}
              extra={
                activePanel === PREVIEW_PANEL_KEY ? (
                  <DownOutlined />
                ) : (
                  <UpOutlined />
                )
              }
              className={styles.offerImporterPanel}
              collapsible={undefined}
            >
              <div className={styles.previewWrapper}>
                <IdCardTable
                  isPreview={true}
                  paginationConfig={paginationConfig}
                  setPaginationConfig={setPaginationConfig}
                  data={pageEmployeeList}
                  loading={
                    importIdCardPreviewIsLoading || importIdCardPreviewIsError
                  }
                  totalData={
                    importIdCardPreviewData?.electionSummaries?.length || 0
                  }
                />
                {(importIdCardPreviewData?.errorRecords?.length ?? 0) > 0 && (
                  <Row
                    justify="center"
                    align="middle"
                    className={styles.errorFileNotificationRow}
                  >
                    <Col className={styles.errorFileNotification}>
                      <AlertMessage
                        type={'warning'}
                        message={
                          <>
                            The submitted file contains errors, which are marked
                            in this
                            <span
                              onClick={handleDownloadErrorFile}
                              className={styles.errorFileLink}
                            >
                              {' error file '}
                            </span>
                            The import can be completed but will leave out
                            records with errors.
                          </>
                        }
                        closeText={''}
                        className={styles.errorMsg}
                      />
                    </Col>
                  </Row>
                )}
                {inactivatedCounts && (
                  <Row
                    justify="center"
                    align="middle"
                    className={styles.errorFileNotificationRow}
                  >
                    <Col className={styles.errorFileNotification}>
                      <AlertMessage
                        type={'info'}
                        message={
                          <>
                            The submitted file is missing some records that are
                            already present in the system. The import can be
                            completed but will lead to
                            {` ${inactivatedCounts.employees}`} employee(s) and
                            {` ${inactivatedCounts.dependents} `}
                            dependent(s) with {inactivatedCounts.logins}{' '}
                            login(s) being deactivated, losing access to the
                            mobile application
                          </>
                        }
                        closeText={''}
                        className={styles.errorMsg}
                      />
                    </Col>
                  </Row>
                )}
                <Row justify={'center'}>
                  <div className={styles.sendNotificationWrapper}>
                    <Row>
                      <div className={styles.sendNotificationText}>
                        Send Notification after Publish &nbsp;
                        <Popover
                          content={
                            'Notifications will be sent to employees with new \n' +
                            'member ID’s when this file is imported'
                          }
                          placement="top"
                          overlayClassName="popoverInner proposalPublishTooltip"
                        >
                          <QuestionCircleOutlined />
                        </Popover>
                      </div>

                      <Switch
                        className={styles.sendNotificationSwitch}
                        defaultChecked={true}
                        onChange={handleNotificationToggle}
                      />
                    </Row>
                  </div>
                </Row>
                <Row justify={'center'}>
                  <Button
                    className={styles.publishButton}
                    loading={importIdCardIsLoading}
                    onClick={() =>
                      isUpcomingPlanYear(selectedPlanYearId ?? '')
                        ? handlePublishFile(false)
                        : handlePublishFile(true)
                    }
                  >
                    Import ID Card File
                  </Button>
                </Row>
                <Row justify={'center'}>
                  <div
                    className={styles.cancelButton}
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </div>
                </Row>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default ImportFile;
