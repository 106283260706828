import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import ErrorDetails from 'model/ErrorDetails';
import {
  fetchExistEnrollments,
  fetchHsaHraDependencies,
} from 'modules/plans/services/PlanEnrollmentService';

export interface planEnrollmentPrams {
  planId: string;
}

interface hsaHraDepPrams {
  planId: string;
}
export interface IplanEnrollmentValidate {
  planEnrollmentObj: boolean;
  hasHraHsaDependencies: boolean;
  inProgress: boolean;
  error: ErrorDetails | null;
}
const initialState: IplanEnrollmentValidate = {
  planEnrollmentObj: false,
  hasHraHsaDependencies: false,
  inProgress: false,
  error: null,
};
const planEnrollmentValidateSlice = createSlice({
  name: 'planEnrollmentValidate',
  initialState: initialState,
  reducers: {
    planEnrollmentCheckStarted: (state) => {
      state.inProgress = true;
    },
    planEnrollmentCheckSuccess: (state, { payload }) => {
      state.inProgress = false;
      state.planEnrollmentObj = payload;
    },
    planEnrollmentCheckError: (
      state,
      { payload }: { payload: ErrorDetails | null }
    ) => {
      state.inProgress = false;
      state.error = payload;
    },
    planEnrollmentCheckClear: (state) => {
      state.planEnrollmentObj = false;
      state.error = null;
    },
    planHsaHraCheckLoading: (state) => {
      state.inProgress = true;
    },
    planHsaHraCheckSuccess: (state, { payload }) => {
      state.inProgress = false;
      if (payload?.hsaPlanId || payload?.hraPlanId) {
        state.hasHraHsaDependencies = true;
      } else {
        state.hasHraHsaDependencies = false;
      }
    },
    planHsaHraCheckError: (
      state,
      { payload }: { payload: ErrorDetails | null }
    ) => {
      state.inProgress = false;
      state.error = payload;
    },
    planHsaHraCheckClear: (state) => {
      state.hasHraHsaDependencies = false;
      state.error = null;
      state.inProgress = false;
    },
  },
});
export const {
  planEnrollmentCheckStarted,
  planEnrollmentCheckSuccess,
  planEnrollmentCheckError,
  planEnrollmentCheckClear,
  planHsaHraCheckLoading,
  planHsaHraCheckSuccess,
  planHsaHraCheckError,
  planHsaHraCheckClear,
} = planEnrollmentValidateSlice.actions;
export default planEnrollmentValidateSlice.reducer;

export const getPlanEnrollment =
  ({ planId }: planEnrollmentPrams) =>
  async (dispatch: Dispatch) => {
    dispatch(planEnrollmentCheckStarted());
    try {
      const response = await fetchExistEnrollments(planId);

      return dispatch(planEnrollmentCheckSuccess(response?.data));
    } catch (err: any) {
      dispatch(planEnrollmentCheckError(err.response));
    }
  };

export const fetchHRAHSAdeps =
  ({ planId }: hsaHraDepPrams) =>
  async (dispatch: Dispatch) => {
    dispatch(planHsaHraCheckLoading());

    try {
      const response = await fetchHsaHraDependencies(planId);
      return dispatch(planHsaHraCheckSuccess(response?.data));
    } catch (err: any) {
      dispatch(planHsaHraCheckError(err.response));
    }
  };
