import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { CaretDownFilled } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';

import styles from 'components/PageActionDropdown/PageActionDropdown.module.less';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';

type Props = {
  icon?: React.ReactNode;
  onChange?: Function;
  options?: {
    value: string;
    label: string;
    disable?: boolean;
    hover?: boolean;
  }[];
  className?: string;
  label?: string;
  buttonClassName?: string;
  dropdownClassName?: string;
  manuallyDisabledVales?: string[];
  popOverContents?: any;
};

const PageActionDropdown = ({
  options,
  icon = <CaretDownFilled />,
  label = 'Actions',
  onChange = () => {},
  className,
  buttonClassName = '',
  dropdownClassName = '',
  manuallyDisabledVales = [],
  popOverContents = undefined,
}: Props) => {
  const [showPopOver, setShowPopOver] = useState<boolean>(false);

  return (
    <div
      className={`${className} ${
        isEmpty(buttonClassName) ? styles.button : buttonClassName
      }`}
    >
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu
            onClick={(e) => {
              if (
                isEmpty(manuallyDisabledVales) ||
                !manuallyDisabledVales.includes(e.key)
              ) {
                onChange(e.key);
              }
            }}
            className={
              isEmpty(dropdownClassName) ? styles.dropdown : dropdownClassName
            }
          >
            {options?.map((option) =>
              option.hover ? (
                <Menu.Item
                  key={option.value}
                  onMouseEnter={() => {
                    setShowPopOver(true);
                  }}
                  onMouseLeave={() => setShowPopOver(false)}
                >
                  <PlanyearPopover
                    content={popOverContents}
                    zIndex={9999}
                    placement="right"
                    visible={showPopOver}
                  >
                    <span className={styles.disabledOption}>
                      {option.label}
                    </span>
                  </PlanyearPopover>
                </Menu.Item>
              ) : (
                <Menu.Item key={option.value} disabled={option.disable}>
                  {option.label}
                </Menu.Item>
              )
            )}
          </Menu>
        }
      >
        <Button>
          {label} {icon}
        </Button>
      </Dropdown>
    </div>
  );
};

export default PageActionDropdown;
