import { MouseEventHandler } from 'react';

import { CompressOutlined, ExpandOutlined } from '@ant-design/icons';

import LinkButton from 'components/buttons/LinkButton/LinkButton';

import {
  ISSUE_COMMENT_COLLAPSE_STATE,
  ISSUE_COMMENT_TYPE_EMAIL,
  ISSUE_COMMENT_TYPE_INTERNAL,
} from 'modules/issueslog/constants/IssuesLogConstants';
import { COLLAPSE_EXPAND_ALL_TEXT } from 'constants/commonConstants';

type Props = {
  selectedTab: string;
  setIsEmailCollapsedArr: Function;
  setIsNoteCollapsedArr: Function;
  isEmailCollapsedArr: boolean[];
  isNoteCollapsedArr: boolean[];
  lastAllNoteState: string | undefined;
  lastAllEmailState: string | undefined;
};

const ExpandCollapseButton = ({
  selectedTab,
  setIsEmailCollapsedArr,
  setIsNoteCollapsedArr,
  isEmailCollapsedArr,
  isNoteCollapsedArr,
  lastAllEmailState,
  lastAllNoteState,
}: Props) => {
  /**
   * Creates and inserts a new fully true/false
   * array to the appropriate isCollapsedArr based on button text
   */
  const onClickHandler: MouseEventHandler<HTMLElement> = (): void => {
    if (selectedTab === ISSUE_COMMENT_TYPE_EMAIL) {
      if (getText() === COLLAPSE_EXPAND_ALL_TEXT.EXPAND_ALL) {
        setIsEmailCollapsedArr(Array(isEmailCollapsedArr.length).fill(false));
      } else {
        setIsEmailCollapsedArr(Array(isEmailCollapsedArr.length).fill(true));
      }
    } else if (selectedTab === ISSUE_COMMENT_TYPE_INTERNAL) {
      if (getText() === COLLAPSE_EXPAND_ALL_TEXT.EXPAND_ALL) {
        setIsNoteCollapsedArr(Array(isNoteCollapsedArr.length).fill(false));
      } else {
        setIsNoteCollapsedArr(Array(isNoteCollapsedArr.length).fill(true));
      }
    }
  };

  const getText = (): string => {
    let result = COLLAPSE_EXPAND_ALL_TEXT.COLLAPSE_ALL;
    if (selectedTab === ISSUE_COMMENT_TYPE_EMAIL) {
      if (lastAllEmailState === ISSUE_COMMENT_COLLAPSE_STATE.EXPANDED) {
        result = COLLAPSE_EXPAND_ALL_TEXT.COLLAPSE_ALL;
      }
      if (lastAllEmailState === ISSUE_COMMENT_COLLAPSE_STATE.COLLAPSED) {
        result = COLLAPSE_EXPAND_ALL_TEXT.EXPAND_ALL;
      }
    } else if (selectedTab === ISSUE_COMMENT_TYPE_INTERNAL) {
      if (lastAllNoteState === ISSUE_COMMENT_COLLAPSE_STATE.EXPANDED) {
        result = COLLAPSE_EXPAND_ALL_TEXT.COLLAPSE_ALL;
      }
      if (lastAllNoteState === ISSUE_COMMENT_COLLAPSE_STATE.COLLAPSED) {
        result = COLLAPSE_EXPAND_ALL_TEXT.EXPAND_ALL;
      }
    }
    return result;
  };

  const getIcon = (): React.ReactNode => {
    if (selectedTab === ISSUE_COMMENT_TYPE_EMAIL) {
      if (lastAllEmailState === ISSUE_COMMENT_COLLAPSE_STATE.EXPANDED) {
        return <CompressOutlined />;
      }
      if (lastAllEmailState === ISSUE_COMMENT_COLLAPSE_STATE.COLLAPSED) {
        return <ExpandOutlined />;
      }
    }

    if (selectedTab === ISSUE_COMMENT_TYPE_INTERNAL) {
      if (lastAllNoteState === ISSUE_COMMENT_COLLAPSE_STATE.EXPANDED) {
        return <CompressOutlined />;
      }
      if (lastAllNoteState === ISSUE_COMMENT_COLLAPSE_STATE.COLLAPSED) {
        return <ExpandOutlined />;
      }
    }
    return <CompressOutlined />;
  };

  const isDisabled = (): boolean => {
    if (selectedTab === ISSUE_COMMENT_TYPE_EMAIL) {
      return isEmailCollapsedArr.length < 1;
    }

    if (selectedTab === ISSUE_COMMENT_TYPE_INTERNAL) {
      return isNoteCollapsedArr.length < 1;
    }
    return true;
  };

  return (
    <LinkButton
      icon={getIcon()}
      onClick={onClickHandler}
      disabled={isDisabled()}
      enableDisableStyle
    >
      {getText()}
    </LinkButton>
  );
};
export default ExpandCollapseButton;
