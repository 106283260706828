import styles from './fromCurrentPercentage.module.less';

type Props = {
  percentage: number;
  showBrackets?: boolean;
  showEmpty?: boolean;
  showHyphenOnZero?: boolean;
  singleLine?: boolean;
  decimalPlaces?: number;
  fontSize?: number;
  fontWeight?: number;
};

const FromCurrentPercentage = (props: Props) => {
  const {
    percentage,
    showBrackets = false,
    showHyphenOnZero = false,
    singleLine = false,
    decimalPlaces = 0,
    fontSize = 13,
    fontWeight = 600,
  } = props;

  const isPositiveValue = () => {
    if (percentage === 0) {
      return 0;
    }
    return percentage > 0;
  };

  const rearrangePercentageValue = (percentage: string | number) => {
    const value =
      percentage == 0
        ? `0${decimalPlaces === 0 ? '' : '.'}${'0'.repeat(decimalPlaces)}`
        : parseFloat(parseFloat(percentage?.toString()).toFixed(2));
    return `${value?.toLocaleString('en-US', {
      minimumFractionDigits: decimalPlaces,
    })}`;
  };

  if (showHyphenOnZero && percentage === 0) {
    return <div className={styles.fromCurrentWrapper}>(-)</div>;
  }

  return (
    <div
      className={`${styles.fromCurrentWrapper} ${
        singleLine ? styles.singleLine : ''
      }`}
    >
      {isPositiveValue() ? (
        <div
          style={{ fontSize: fontSize, fontWeight: fontWeight }}
          className={styles.positive}
        >{`${showBrackets ? '(' : ''}+${rearrangePercentageValue(percentage)}%${
          showBrackets ? ')' : ''
        }`}</div>
      ) : isPositiveValue() === 0 ? (
        <div
          style={{ fontSize: fontSize, fontWeight: fontWeight }}
          className={styles.offset}
        >{`${showBrackets ? '(' : ''}${'0.00'}%${
          showBrackets ? ')' : ''
        }`}</div>
      ) : (
        <div
          style={{ fontSize: fontSize, fontWeight: fontWeight }}
          className={styles.negative}
        >{`${showBrackets ? '(' : ''}${rearrangePercentageValue(percentage)}%${
          showBrackets ? ')' : ''
        }`}</div>
      )}
    </div>
  );
};

export default FromCurrentPercentage;
