import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { cloneDeep, get, isEmpty, last } from 'lodash';
import RenderSelectionType from 'modules/assistant/enums/RenderSelectionType';
import ConversationService, {
  ConversationInputPayload,
  ConversationServiceInterface,
  getChatHistoryConversationService,
  initializeConversationService,
} from 'modules/assistant/services/ConversationService';
import { NEW_CHAT_NAV_CONSTANT } from 'modules/assistant/constants/constants';
import { RootState } from 'app/store';
import {
  AssistantChatResponseVO,
  MessageVO,
} from 'modules/assistant/models/AssistantChatResponseVO';
import BasicThunkAction from 'model/common/BasicThunkAction';
import { IConversationState } from 'modules/assistant/models/ConversationState';
import { Message } from 'modules/assistant/models/Message';
import { transformMessages } from 'modules/assistant/utils/conversationUtil';

export const initialConversation: Message = {
  id: undefined,
  question: undefined,
  // Below variable is to animate the type writer animation please reset it if this set to true where effect is not needed
  animate: false,
  answer: undefined,
  isPending: false,
};

const initialState: IConversationState = {
  messages: [],
  inProgress: false,
  error: null,
  selectionType: RenderSelectionType.SELECTION,
  promptType: null,
  conversationId: NEW_CHAT_NAV_CONSTANT,

  // The below properties are only considered when promptType is PromptType.PLANS
  documentId: null,
  documentName: null,
  employerId: null,
  employerName: null,

  // For Api calls
  downloadDocument: {
    status: { isError: false, isLoading: false },
    data: null,
  },
};

interface ChatPayload {
  question: string;
  answer: string;
  id: string;
  conversationId: string;
}

const conversationSlice = createSlice({
  name: 'conversation',
  initialState: initialState,
  reducers: {
    setInitialMessage: (state, { payload }) => {
      const initialMessage = {
        question: payload.question,
        animate: true,
        isPending: true,
        failed: false,
      };
      state.selectionType = RenderSelectionType.COMPLETED;
      state.messages = [...(state.messages ?? []), initialMessage];
    },
    setMessage: (state, action: PayloadAction<ChatPayload>) => {
      const { question, answer, id, conversationId } = action.payload;

      if (state.messages) {
        const lastChatIndex = state.messages.length - 1;
        if (lastChatIndex !== -1) {
          const updatedChat: Message = {
            ...state.messages[lastChatIndex],
            id,
            answer,
            question,
            animate: true,
            isPending: isEmpty(answer),
            error: false,
          };
          const updatedChats = cloneDeep(state.messages);
          updatedChats[lastChatIndex] = updatedChat;
          state.conversationId = conversationId;
          state.messages = updatedChats;
        }
      }
    },
    setConversationDetails: (
      state,
      {
        payload: {
          conversationId,
          type,
          messages = [],
          documentId,
          documentName,
          employerId,
          employerName,
        },
      }: PayloadAction<AssistantChatResponseVO>
    ) => {
      state.conversationId = conversationId;
      state.promptType = type;
      state.documentId = documentId;
      state.documentName = documentName;
      state.employerId = employerId;
      state.employerName = employerName;
      state.messages = transformMessages(messages as MessageVO[]);
      state.error = String(get(last(messages), 'content.error'));
    },
    setResetAnimation: (state) => {
      if (state.messages && !isEmpty(state.messages)) {
        const lastChatIndex = state.messages.length - 1;
        const updatedChats = cloneDeep(state.messages);

        updatedChats[lastChatIndex] = {
          ...updatedChats[lastChatIndex],
          animate: false,
          error: false,
        };

        state.messages = updatedChats;
      }
    },
    setMessageFailed: (
      state,
      { payload: { error } }: PayloadAction<{ error: string }>
    ) => {
      if (state.messages && !isEmpty(state.messages)) {
        const lastChatIndex = state.messages.length - 1;

        if (lastChatIndex !== -1) {
          const updatedChat: Message = {
            ...state.messages[lastChatIndex],
            error: true,
            isPending: false,
            animate: false,
            answer: undefined,
          };
          const updatedChats = cloneDeep(state.messages);
          updatedChats[lastChatIndex] = updatedChat;
          state.messages = updatedChats;
          state.error = error;
        }
      }
    },
    setSelectionType: (state, { payload }) => {
      state.selectionType = payload;
    },
    setConversationId: (state, { payload }) => {
      state.conversationId = payload;
    },
    setPromptType: (state, { payload }) => {
      state.promptType = payload;
    },
    setChatHistoryConversationFetchStarted: (state) => {
      state.inProgress = true;
      state.error = null;
    },
    setChatHistoryConversationSucceeded: (state) => {
      state.inProgress = false;
      state.error = null;
    },
    setChatHistoryConversationFailed: (state, { payload }) => {
      state.inProgress = false;
      state.error = payload;
    },
    setDocumentId: (state, { payload }) => {
      state.documentId = payload;
    },
    resetConversationHistory: (state) => {
      state.messages = [];
      state.error = null;
      state.downloadDocument.status.isLoading = false;
      state.downloadDocument.status.isError = false;
    },
    clearChatHistoryConversation: (state) => {
      state.messages = [];
      state.inProgress = false;
      state.error = null;
      state.selectionType = RenderSelectionType.SELECTION;
      state.promptType = null;
      state.conversationId = NEW_CHAT_NAV_CONSTANT;
      state.downloadDocument.status.isLoading = false;
      state.downloadDocument.status.isError = false;
    },
    downloadDocumentStarted: (state) => {
      state.downloadDocument.status.isLoading = true;
      state.downloadDocument.status.isError = false;
    },
    downloadDocumentCompleted: (state) => {
      state.downloadDocument.status.isLoading = false;
      state.downloadDocument.status.isError = false;
    },
    donwloadDocumentFailed: (state) => {
      state.downloadDocument.status.isLoading = false;
      state.downloadDocument.status.isError = true;
    },
  },
});

export const {
  setConversationId,
  setInitialMessage,
  setSelectionType,
  setPromptType,
  setMessage,
  setMessageFailed,
  setResetAnimation,
  setChatHistoryConversationFetchStarted,
  setChatHistoryConversationSucceeded,
  setChatHistoryConversationFailed,
  clearChatHistoryConversation,
  setConversationDetails,
  setDocumentId,
  resetConversationHistory,
} = conversationSlice.actions;

// Internal actions. Please do not use these outside
export const {
  donwloadDocumentFailed,
  downloadDocumentCompleted,
  downloadDocumentStarted,
} = conversationSlice.actions;

export const initializeConversation =
  ({
    type,
    question,
    documentReference,
    refreshHistory,
    onFinish,
  }: ConversationServiceInterface) =>
  async (dispatch: Dispatch) => {
    dispatch(setInitialMessage({ question }));

    try {
      const response: AssistantChatResponseVO =
        await initializeConversationService({
          type,
          question,
          documentReference,
        });

      if (response) {
        const lastChat = last(response.messages) as MessageVO;
        if (
          isEmpty(lastChat?.content?.content) ||
          !isEmpty(lastChat?.content?.error)
        ) {
          dispatch(setMessageFailed({ error: lastChat?.content?.error }));
        } else {
          const { conversationId } = response;
          const {
            id,
            question,
            content: { content: answer },
          } = lastChat;
          dispatch(setMessage({ conversationId, id, question, answer }));
        }
        onFinish?.(response.conversationId);
      } else {
        dispatch(
          setMessageFailed({ error: 'Error initializing conversation:' })
        );
        onFinish?.(NEW_CHAT_NAV_CONSTANT);
      }
      return response;
    } catch (err) {
      console.error('Error initializing conversation:', err);
      dispatch(setMessageFailed({ error: 'Error initializing conversation:' }));
      onFinish?.(NEW_CHAT_NAV_CONSTANT);
    } finally {
      refreshHistory?.();
    }
  };

export const getChatHistoryConversation =
  (conversationId: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(setChatHistoryConversationFetchStarted());
      const response = await getChatHistoryConversationService(conversationId);
      if (response) {
        dispatch(setChatHistoryConversationSucceeded());
        dispatch(setSelectionType(RenderSelectionType.COMPLETED));
        dispatch(setConversationDetails(response));
      }
    } catch (error: any) {
      dispatch(setChatHistoryConversationFailed(error.response));
      dispatch(setMessageFailed(error.response));
    }
  };

export const continueConversation =
  ({ question }: ConversationInputPayload) =>
  async (dispatch: Dispatch) => {
    dispatch(setInitialMessage({ question }));
  };

export const appendConversation =
  ({
    answer = '',
    id,
    conversationId,
    error,
    errorResponse,
  }: ConversationInputPayload) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    if (!isEmpty(error) || isEmpty(answer) || !id || !conversationId) {
      dispatch(setMessageFailed({ error: String(errorResponse) }));
      return;
    }

    const lastChat = last(getState().assistant.current.messages);
    const question = get(lastChat, 'question');

    if (question) {
      dispatch(
        setMessage({
          question,
          answer,
          id,
          conversationId,
        })
      );
    } else {
      dispatch(setMessageFailed({ error: 'Error initializing conversation:' }));
    }
  };

export const downloadDocument: BasicThunkAction<{ documentId: string }> =
  ({ documentId }) =>
  async (dispatch) => {
    try {
      dispatch(downloadDocumentStarted());
      const isValid = await ConversationService.validateDocument(documentId);
      if (isValid) {
        const url = ConversationService.buildDownloadUrl(documentId);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = url;
        a.download = documentId;
        a.target = '_blank';
        a.click();
        window.URL.revokeObjectURL(url);
        dispatch(downloadDocumentCompleted());
      } else {
        dispatch(donwloadDocumentFailed());
      }
    } catch (error) {
      dispatch(donwloadDocumentFailed());
    }
  };

export default conversationSlice.reducer;
