import { FC, ReactNode, useState } from 'react';

import { Col, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { BenefitKind } from 'constants/commonConstants';
import { EmployerContributionFrequency } from 'modules/plans/constants';
import { TaxAdvantagedAccountPlan } from 'model/plans/TaxAdvantagedAccountPlan';
import { getPlanDocument } from 'modules/plans/services/PlanService';
import PlanDocumentsOverview from 'modules/plans/components/OverviewPage/PlanDocumentsOverview/PlanDocumentOverview';

import styles from './dependentCareFSAPlanOverview.module.less';

type DependentCareFSAPlanOverviewProps = {
  plan: TaxAdvantagedAccountPlan;
  heading?: string;
};

const DependentCareFSAPlanOverview: FC<DependentCareFSAPlanOverviewProps> = (
  props
) => {
  const { plan } = props;

  const [viewPlanDocumentsModal, setViewPlanDocumentsModal] = useState(false);

  const onClick = async (planId: string | undefined, document: string) => {
    if (planId) {
      const response = await getPlanDocument(
        planId,
        document,
        BenefitKind.DCAP_FSA.value
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    }
  };

  const renderDocuments = (plan: TaxAdvantagedAccountPlan | undefined) => {
    if (plan && plan.documents) {
      const documentsList: ReactNode[] = [];
      Object.keys(plan.documents).forEach(function (key) {
        documentsList.push(
          <div className={styles.linkBtnWrapper}>
            <LinkButton onClick={() => onClick(plan?.id, key)}>
              <div className={styles.linkHeading}>{'File:'}</div>
              <OverflowPopover
                maxWidth="100%"
                popoverContent={plan.documents[key]}
              >
                <span className={styles.fileName}>{plan.documents[key]}</span>
              </OverflowPopover>
            </LinkButton>
          </div>
        );
      });
      return documentsList;
    }
  };

  const getFrequencyValue = (frequency: string) => {
    const selectedFrequency = EmployerContributionFrequency.find(
      (item) => item.value === frequency
    );
    return selectedFrequency?.label;
  };

  const getFormattedDecimalValue = (value: number) => {
    return `$${Number(parseFloat(`${value}`).toFixed(2)).toLocaleString('en', {
      minimumFractionDigits: 2,
    })}`;
  };

  const contributionOverview = [
    {
      label: 'Employer Contribution Frequency',
      value: plan.employerContribution?.frequency
        ? getFrequencyValue(plan.employerContribution?.frequency)
        : '-',
      longLabel: false,
    },
    {
      label: 'Employer Contribution',
      value:
        plan.employerContribution?.empContribution ||
        plan.employerContribution?.empContribution === 0
          ? getFormattedDecimalValue(plan.employerContribution?.empContribution)
          : '-',
      longLabel: false,
    },
    {
      label: 'Other Employer Contributions',
      value: (
        <OverflowPopover>
          {plan.employerContribution?.otherContribution
            ? `${plan.employerContribution?.otherContribution}`
            : '-'}
        </OverflowPopover>
      ),
      longLabel: false,
    },
    {
      label: 'Max Yearly Contribution Married Filing Separately',
      value:
        plan.maxIndividualContributionForMarriedButFilledAsSeparately ||
        plan.maxIndividualContributionForMarriedButFilledAsSeparately === 0
          ? getFormattedDecimalValue(
              plan.maxIndividualContributionForMarriedButFilledAsSeparately
            )
          : '-',
      longLabel: true,
    },
    {
      label: 'Max Yearly Contribution Married Filing Jointly or Single Parent',
      value:
        plan.maxMarriedContributionForFillingJointlyOrSingleParentAsHead ||
        plan.maxMarriedContributionForFillingJointlyOrSingleParentAsHead === 0
          ? getFormattedDecimalValue(
              plan.maxMarriedContributionForFillingJointlyOrSingleParentAsHead
            )
          : '-',
      longLabel: true,
    },
    {
      label: 'End of Year Policy',
      value: plan.endOfYearPolicy ? `${plan.endOfYearPolicy}` : '-',
      longLabel: false,
    },
    {
      label: 'Additional Resources',
      value: !isEmpty(plan.documents) ? renderDocuments(plan) : '-',
      longLabel: false,
    },
  ];

  const updateViewPlanDocumentsModal = (value: boolean) => {
    setViewPlanDocumentsModal(value);
  };

  return (
    <div className={styles.wrapper}>
      {contributionOverview.map((item, Index) => {
        if (item.longLabel) {
          return (
            <Row>
              <Col>
                <div className={styles.infoLabelText}>{item.label}</div>
              </Col>
              <Col>
                <div className={styles.infoValueSection}>{item.value}</div>
              </Col>
            </Row>
          );
        } else {
          return (
            <Row key={Index}>
              <Col span={24}>
                <InfoLabelSection labelText={item.label} value={item.value} />
              </Col>
            </Row>
          );
        }
      })}
      <div className={styles.overview} id={'Plan_Doc'}>
        <PlanDocumentsOverview
          heading={'Plan Resources'}
          plan={plan}
          documents={plan?.documents}
          benefitKind={plan.benefitKind}
          viewPlanDocumentsModal={viewPlanDocumentsModal}
          setViewPlanDocumentsModal={(value) =>
            updateViewPlanDocumentsModal(value)
          }
        />
      </div>
    </div>
  );
};

export default DependentCareFSAPlanOverview;
