export const navContexts = {
  platform: 'PLATFORM_ADMIN',
  broker: 'BROKER',
  er: 'EMPLOYER',
  carrier: 'CARRIER',
  issuesLog: 'ISSUES_LOG',
  renewals: 'RENEWAL',
  rfps: 'RFPS',
  configuration: 'CONFIGURATION',
  assistant: 'AI_ASSISTANT',
  assistantChat: 'AI_ASSISTANT_CHAT',
};

export const loginTypes = {
  platform: 'PLATFORM_ADMIN',
  bokerAdmin: 'INTERNAL_ADMIN',
  erAdmin: 'ER_ADMIN',
};

export const topLevelMenus = {
  home: 'Home',
  brokers: 'Brokers',
  employers: 'Employers',
  carriers: 'Carriers',
  issuesLog: 'Account Log',
  renewals: 'Renewals',
  rfps: 'RFP',
  assistant: 'AI Assistant',
  configuration: 'Configuration',
};

/**
 * User login session expiration time statuses will be captured in below points
 * 1. User is logged-in and active
 * 2. User is inactive for some time (session not expired but refresh token will call in next api call)
 * 3. User is inactive for long time (session about to expired and popup will show)
 * 4. User is about to expire session (popup will show) and session extended by user (popup will close and session extended)
 * 5. User is about to expire session (popup will show) and session not extended by user (popup will close and user will be logged out)
 * 6. User is logged out after session expired
 */
export const sessionActionTypes = {
  userActive: 'USER-LOGGED-IN',
  refreshRequired: 'REFRESH-REQUIRED',
  expireSoon: 'EXPIRE-SOON-POPUP',
  extendSession: 'EXTEND-CLICK',
  logoutClick: 'LOGOUT-CLICK',
  logout: 'LOGGED-OUT',
};
