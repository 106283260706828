import React, { FC, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { ReactComponent as RFPLOADED } from 'assets/images/rfp-answer-generated-check.svg';
import rfpPencil from 'assets/images/rfp-card-edit-pencil.svg';
import rfpTrash from 'assets/images/rfp-card-trash.svg';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  CONFIRM_DELETE_QUESTION,
  EDIT_ANSWER_PROMPT,
} from 'modules/rfp/constants/RfpConstants';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  deleteRfpQuestion,
  editRfpQuestion,
  getAnswer,
  setSelectedRfpIndex,
} from 'modules/rfp/slices/rfpQuestionSlice';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import styles from './questionCard.module.less';

type QuestionCardProps = {
  content?: string;
  index: number;
  setSelectedIndex: (index: number | undefined) => void;
  selected?: number;
};

const QuestionCard: FC<QuestionCardProps> = ({
  index,
  content,
  selected,
  setSelectedIndex,
}) => {
  const dispatch = useAppDispatch();
  const { brokerId } = useParams();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editQuestionText, setEditQuestionText] = useState<string>(content!);

  const questions = useAppSelector((state) => state.rfp.rfpQuestions);
  const selectedRfpId = useAppSelector((state) => state.rfp?.selectedRfpId);
  const selectedContextIds = useAppSelector(
    (state) => state.rfp?.selectedProvideContextFiles
  );

  const isLoading = useAppSelector(
    (state) => state.rfp.rfpQuestions[index]?.isPending
  );

  const handleConfirmDelete = () => {
    setIsConfirmOpen(false);
    dispatch(deleteRfpQuestion(index));
  };
  const handleEditQuestion = () => {
    setIsEditOpen(false);
    dispatch(setSelectedRfpIndex(index));
    dispatch(editRfpQuestion(index, editQuestionText));
    getAnswer(
      brokerId,
      editQuestionText,
      index,
      selectedRfpId,
      selectedContextIds,
      dispatch
    );
  };

  const extractAnswer = () => {
    setSelectedIndex(index);
    dispatch(setSelectedRfpIndex(index));
    if (questions[index]?.isPending) {
      return;
    }
  };

  return (
    <div className={styles.questionWrapper}>
      <div className={styles.loadedCheckIcon}>
        {questions[index]?.isPending || isEmpty(questions[index]?.answer) ? (
          <Spin size="small" />
        ) : (
          <RFPLOADED />
        )}
      </div>
      <div onClick={() => extractAnswer()}>
        <div
          className={`${styles.animateCardHeight} ${
            selected === index ? styles.expanded : styles.questionCardWrapper
          }`}
        >
          <p>{content}</p>

          {selected === index ? (
            <div className={styles.actionbuttonArea}>
              <div>
                {!isLoading ? (
                  <LinkButton
                    containerClassName={styles.rfpButtonIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditOpen(true);
                      setEditQuestionText(content!);
                    }}
                  >
                    <img src={rfpPencil} alt="Pencil Icon" />
                  </LinkButton>
                ) : (
                  <LinkButton
                    containerClassName={styles.rfpButtonIcon}
                    disabled
                  >
                    <img src={rfpPencil} alt="Pencil Icon" />
                  </LinkButton>
                )}
              </div>
              <div>
                <LinkButton
                  containerClassName={styles.rfpButtonIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsConfirmOpen(true);
                  }}
                >
                  <img src={rfpTrash} alt="Trash Icon" />
                </LinkButton>
              </div>

              {isConfirmOpen && (
                <ConfirmationDialog
                  className={styles.deleteQuestionModal}
                  title="Delete Question"
                  centered
                  confirmText="Yes - Delete Question"
                  cancelText="Cancel"
                  isCancelLink
                  closeModal={() => setIsConfirmOpen(false)}
                  onConfirm={handleConfirmDelete}
                  visible={isConfirmOpen}
                >
                  <p>{CONFIRM_DELETE_QUESTION}</p>
                </ConfirmationDialog>
              )}

              {isEditOpen && (
                <ConfirmationDialog
                  onCancel={() => {
                    setIsEditOpen(false);
                  }}
                  className={styles.editQuestionModal}
                  title="Edit Question"
                  confirmText="Save Question"
                  cancelText="Cancel"
                  disableConfirmButton={isEmpty(editQuestionText.trim())}
                  isCancelLink
                  closeModal={() => setIsEditOpen(false)}
                  onConfirm={handleEditQuestion}
                  visible={isEditOpen}
                >
                  <FixedAlertMessage
                    className={styles.editModalAlert}
                    type="info"
                    message={EDIT_ANSWER_PROMPT}
                  />
                  <p>
                    <b>Question : {index + 1}</b>
                  </p>
                  <TextArea
                    rows={12}
                    value={editQuestionText}
                    onChange={(e) => {
                      setEditQuestionText(e.target.value);
                    }}
                  />
                </ConfirmationDialog>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;
