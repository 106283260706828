import { isNullOrUndefined } from 'modules/plans/utils';
import {
  N_TIERS,
  TIER_TYPES,
} from 'modules/renewals/constants/renewalsConstants';

interface PlanRate {
  rateType: string;
  [key: string]: any;
}

interface IPlanItem {
  plan: {
    masterId: string;
    name: string;
    rates: PlanRate[];
    [key: string]: any;
  };
}

interface IPlanItemMod {
  masterId: string;
  name: string;
  rates: PlanRate[];
  [key: string]: any;
}

const getTierCount = (ratesObj: any) => {
  const objectKeys = Object.keys(ratesObj);
  const commonElementsCount = objectKeys.filter((key) =>
    N_TIERS.includes(key)
  ).length;

  return commonElementsCount;
};

export const mapAndSort = (plans: any[], enrollments: any[]) => {
  const filteredPlans = plans.filter((item) =>
    item?.plan?.rates
      ? Object.values(item?.plan?.rates as PlanRate[])?.[0]?.rateType !==
        TIER_TYPES.AGE_BAND
      : true
  );
  const plansArray = filteredPlans
    ?.map((item: IPlanItem) => {
      const rateObj: any = item?.plan?.rates
        ? Object.values(item?.plan?.rates as PlanRate[])?.[0]
        : {};
      const rateType = item?.plan?.rates
        ? rateObj?.rateType
        : TIER_TYPES.STANDARD_TIER;
      const rateTierCount =
        rateType === TIER_TYPES.STANDARD_TIER ? 4 : getTierCount(rateObj);

      const enrollmentExists = enrollments?.some(
        (enrollment) => enrollment.masterId === item?.plan.masterId
      );

      const enrollmentObj = enrollments?.find(
        (enrollment) => enrollment.masterId === item?.plan.masterId
      );

      const nTierEnrollments = enrollmentExists
        ? enrollmentObj?.nTierEnrollments
        : Array(rateTierCount).fill('');

      const fourTier = enrollmentExists
        ? [
            enrollmentObj?.['EE TIER'],
            enrollmentObj?.['ES TIER'],
            enrollmentObj?.['EC TIER'],
            enrollmentObj?.['EF TIER'],
          ]
        : Array(4).fill('');

      return {
        masterId: item?.plan.masterId,
        name: item?.plan?.name,
        tierType: rateType,
        tierCount: rateTierCount,
        nTierEnrollments:
          rateType === TIER_TYPES.N_TIER ? nTierEnrollments : [],
        fourTier: rateType === TIER_TYPES.STANDARD_TIER ? fourTier : [],
      };
    })
    .sort((a: any, b: any) => a.name.localeCompare(b.name));
  return plansArray;
};

export const benefitSpecificMapAndSort = (
  plans: any[],
  isBenClassEnabled: boolean
) => {
  const filteredPlans = plans.filter((item) =>
    item?.rates
      ? Object.values(item?.rates as PlanRate[])?.[0]?.rateType !==
        TIER_TYPES.AGE_BAND
      : true
  );
  const plansArray = filteredPlans
    ?.map((item: IPlanItemMod) => {
      const rateObj: any = item?.rates
        ? item?.rates[item?.benefitClassName]
        : {};
      const rateType = item?.rates
        ? rateObj?.rateType
        : TIER_TYPES.STANDARD_TIER;
      const rateTierCount =
        rateType === TIER_TYPES.STANDARD_TIER ? 4 : getTierCount(rateObj);

      const premiums: any = [
        'employeeOnly',
        'employeePlus1',
        'employeePlus2',
        'employeePlus3',
        'employeePlus4',
        'employeePlus5',
        'employeePlus6',
        'employeeOnly',
        'employeeChildren',
        'employeeFamily',
        'employeeSpouse',
      ];
      const enrollmentsArray: any = [];

      Object.entries(rateObj).map(([key, item]: [any, any]) => {
        if (premiums.includes(key)) {
          enrollmentsArray.push(
            isNullOrUndefined(item?.enrollment) ? '' : item?.enrollment
          );
        }
      });

      return {
        masterId: item?.masterId,
        name: isBenClassEnabled
          ? item?.benefitClassExclusivePlanName
          : item?.planName,
        benefitClassId: item?.benefitClassId,
        benefitClassName: item?.benefitClassName,
        tierType: rateType,
        tierCount: rateTierCount,
        nTierEnrollments:
          rateType === TIER_TYPES.N_TIER ? enrollmentsArray : [],
        fourTier: rateType === TIER_TYPES.STANDARD_TIER ? enrollmentsArray : [],
        fundingType: item?.fundingType,
      };
    })
    .sort((a: any, b: any) => a.name.localeCompare(b.name));
  return plansArray;
};
