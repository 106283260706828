import { isNumber } from 'lodash';
import { useEffect, useRef } from 'react';

const useInterval = (callback: Function, delay: number | null) => {
  const intervalRef = useRef<number | null>(null);
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => savedCallback.current();

    if (isNumber(delay)) {
      intervalRef.current = window.setInterval(tick, delay);
      return () => {
        if (intervalRef.current !== null) {
          window.clearInterval(intervalRef.current);
        }
      };
    }
  }, [delay]);

  return intervalRef;
};

export default useInterval;
