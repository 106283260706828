import { lumityApi } from './apiSlice';

export type Feature = {
  enabled: boolean;
  feature: string;
  employerVisibilityApplicable: boolean;
  employerVisibilityEnabled: boolean;
};

export type FeatureData = {
  features: Feature[];
};

type GetNavigationsParams = {
  brokerId: string | null;
  employerId: string | null;
};

export const navigationApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getNavigations: builder.query<FeatureData, GetNavigationsParams>({
      query: (args) => {
        return {
          url: `v2/organizations/configurations/features`,
          method: 'GET',
          params: {
            'organization-id': args.brokerId,
            'employer-id': args.employerId,
          },
        };
      },
    }),
    // Duplicate of the above query seperately that refetches with components without triggering another re-render s the above query has other dependencies
    getConfigurationStatus: builder.query<FeatureData, GetNavigationsParams>({
      query: (args) => {
        return {
          url: `v2/organizations/configurations/features`,
          method: 'GET',
          params: {
            'organization-id': args.brokerId,
            'employer-id': args.employerId,
          },
        };
      },
    }),
  }),
});

export const {
  useGetNavigationsQuery,
  useLazyGetNavigationsQuery,
  useGetConfigurationStatusQuery,
  useLazyGetConfigurationStatusQuery,
} = navigationApi;
