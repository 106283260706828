import { cloneDeep } from 'lodash';
import {
  FIELDS_SUB_TYPES,
  IN_NETWORK_VALUE,
  INN_OON_SUFFIX,
  OFFER_BENEFIT_TYPES,
  OON_REIMBURSEMENT,
  OUT_NETWORK_VALUE,
  SERVICE_PRIORITY_ORDER_DEFAULT_SERVICES,
  PREMIUMS_SHORT_FORM,
  STANDARD_TIER_NAMES_LIST,
} from 'modules/renewals/constants/renewalsConstants';
import { BenefitType, NetworkType } from 'modules/renewals/models/PlanTable';
import {
  ArrayItemTemplate,
  PartialArrayItemTemplate,
  Plan,
  ServiceArrayItemTemplate,
} from 'modules/renewals/types/planTypes';
import {
  STANDARD_DEDUCTIBLES_DEFAULT,
  STANDARD_DENTAL_DEDUCTIBLES_DEFAULT,
  STANDARD_ENROLLMENTS_DEFAULT,
  STANDARD_MAX_BENEFITS_DEFAULT,
  STANDARD_OOP_DEFAULT,
  STANDARD_ORTHODONTIA_MAX_DEFAULT,
  STANDARD_RX_DEDUCTIBLES_DEFAULT,
  STANDARD_RX_OOP_DEFAULT,
  STANDARD_RX_TIERS_DEFAULT,
  STANDARD_TIER_DEFAULT,
} from 'modules/renewals/constants/planTableConstants';

/**
 * Filters an array of `ArrayItemTemplate` objects based on the provided criteria.
 * @param {Object} options - The filter options.
 * @param {PartialArrayItemTemplate[]} options.array - The array to filter.
 * @param {NetworkType} options.fieldType - The field type to match.
 * @param {string} [options.fieldSubType] - The field sub type to match.
 * @param {boolean} [options.isNot=false] - Whether to negate the matching condition.
 * @return {PartialArrayItemTemplate[]} - The filtered array.
 */
const filterDeductibleAndOopAndRxAndAdditionalServicesFromArray = ({
  array,
  fieldType,
  fieldSubType,
  isNot = false,
}: {
  array: PartialArrayItemTemplate[];
  fieldType: NetworkType;
  fieldSubType?: string;
  isNot?: boolean;
}): PartialArrayItemTemplate[] =>
  array?.filter(
    ({
      fieldSubType: itemSubType,
      fieldType: itemType,
    }: PartialArrayItemTemplate) => {
      const isFieldSubType = fieldSubType
        ? isNot
          ? itemSubType !== fieldSubType
          : itemSubType === fieldSubType
        : true;
      return itemType === fieldType && isFieldSubType;
    }
  );

/**
 * Compares two arrays of objects and removes the common elements from the first array.
 *
 * @param {PartialArrayItemTemplate[]} arrayOne - The first array to compare.
 * @param {PartialArrayItemTemplate[]} arrayTwo - The second array to compare.
 * @return {PartialArrayItemTemplate[]} An array containing elements from arrayOne that are not present in arrayTwo.
 */
const compareTwoArrayRemoveData = (
  arrayOne: PartialArrayItemTemplate[],
  arrayTwo: PartialArrayItemTemplate[]
): PartialArrayItemTemplate[] =>
  arrayOne?.filter(
    (item) => !arrayTwo.some((d) => JSON.stringify(d) === JSON.stringify(item))
  );

/**
 * Transforms an array of services into an array of plan table services.
 * concat the in-network and out-of-network values for each service and added
 * (INN/OON) to name.
 * @param {ArrayItemTemplate[]} services - The array of services to transform.
 * @param {boolean} isLLMExtraction - isLLMExtraction.
 * @return {ServiceArrayItemTemplate[]} The transformed array of plan table services.
 */
const transformServicesToPlanTableServices = (
  services: ArrayItemTemplate[],
  isLLMExtraction: boolean = false
): ServiceArrayItemTemplate[] => {
  const newServices: ServiceArrayItemTemplate[] = [];

  services?.forEach((item) => {
    if (item.fieldType === IN_NETWORK_VALUE) {
      const oonObj = services.find(
        (service) =>
          service.name === item.name && service.fieldType === OUT_NETWORK_VALUE
      );
      const inNetworkObject: ServiceArrayItemTemplate = {
        name: `${item.name} ${
          item?.name.includes(INN_OON_SUFFIX) ||
          item?.name === OON_REIMBURSEMENT
            ? ''
            : INN_OON_SUFFIX
        }`,
        inNetworkValue: item?.value || null,
        outOfNetworkValue: oonObj?.value || null,
        formattedValue: item?.formattedValue || null,
        value: item?.value || null,
        fieldType: IN_NETWORK_VALUE as NetworkType,
        fieldSubType: null,
      };

      if (isLLMExtraction) {
        inNetworkObject.inNetworkDifferent = item?.different || false;
        inNetworkObject.outOfNetworkDifferent = oonObj?.different || false;
      }
      newServices.push(inNetworkObject);
    } else {
      newServices.push({ ...item });
    }
  });

  return newServices;
};

/**
 * Transforms MDV plan data to table plan based on the benefit type.
 * @param {Object} options - The options object.
 * @param {any} options.plan - The plan data. added any services has two types of values in-network and out-of-network.
 * @param {BenefitType} options.benefitType - The benefit type.
 * @param {boolean} options.isCurrentPlan - Indicates if it's the current plan.
 * @return {Object} - The transformed plan data.
 */
export const transformMDVPlanDataToTablePlan = ({
  plan,
  benefitType,
  isCurrentPlan,
}: {
  plan: any;
  benefitType: BenefitType;
  isCurrentPlan?: boolean;
}): {} => {
  switch (benefitType) {
    case OFFER_BENEFIT_TYPES.MEDICAL.toUpperCase(): {
      const outNetworkDeductibles =
        filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
          array: plan?.deductibles,
          fieldType: OUT_NETWORK_VALUE,
          fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
          isNot: true,
        }) || [];

      const indWithinFamDeductibles =
        filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
          array: plan?.deductibles,
          fieldType: IN_NETWORK_VALUE,
          fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
        }) || [];

      const indWithinFamDeductiblesOutNetwork =
        filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
          array: plan?.deductibles,
          fieldType: OUT_NETWORK_VALUE,
          fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
        }) || [];

      const outNetworkOOPS =
        filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
          array: plan?.oops,
          fieldType: OUT_NETWORK_VALUE,
          fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
          isNot: true,
        }) || [];

      const indWithinFamOopMax =
        filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
          array: plan?.oops,
          fieldType: IN_NETWORK_VALUE,
          fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
        }) || [];

      const indWithinFamOopMaxOutNetwork =
        filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
          array: plan?.oops,
          fieldType: OUT_NETWORK_VALUE,
          fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
        }) || [];

      const rxDeductibleOutNetwork =
        filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
          array: plan?.rxDeductible,
          fieldType: OUT_NETWORK_VALUE,
        }) || [];

      const rxOopOutNetwork =
        filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
          array: plan?.rxOop,
          fieldType: OUT_NETWORK_VALUE,
        }) || [];

      const rxTiersOutNetwork =
        filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
          array: plan?.rxTiers,
          fieldType: OUT_NETWORK_VALUE,
        });

      const deductibles =
        compareTwoArrayRemoveData(plan?.deductibles, [
          ...indWithinFamDeductiblesOutNetwork,
          ...indWithinFamDeductibles,
          ...outNetworkDeductibles,
        ]) || [];

      const oops =
        compareTwoArrayRemoveData(plan?.oops, [
          ...indWithinFamOopMaxOutNetwork,
          ...indWithinFamOopMax,
          ...outNetworkOOPS,
        ]) || [];

      const rxDeductible =
        compareTwoArrayRemoveData(plan?.rxDeductible, rxDeductibleOutNetwork) ||
        [];

      const rxOop =
        compareTwoArrayRemoveData(plan?.rxOop, rxOopOutNetwork) || [];

      const rxTiers =
        compareTwoArrayRemoveData(plan?.rxTiers, rxTiersOutNetwork) || [];
      // creating displayServices for Display Service in the table in-network and out-of-network values
      const displayServices =
        transformServicesToPlanTableServices(plan?.services) || [];

      const llmExtractionInfo: Partial<Plan> = { ...plan?.llmExtractionInfo };
      if (plan?.hasOwnProperty('llmExtractionInfo')) {
        const outNetworkDeductibles =
          filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
            array: plan?.llmExtractionInfo?.deductibles,
            fieldType: OUT_NETWORK_VALUE,
            fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
            isNot: true,
          });

        const indWithinFamDeductibles =
          filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
            array: plan?.llmExtractionInfo?.deductibles,
            fieldType: IN_NETWORK_VALUE,
            fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
          }) || [];

        const indWithinFamDeductiblesOutNetwork =
          filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
            array: plan?.llmExtractionInfo?.deductibles,
            fieldType: OUT_NETWORK_VALUE,
            fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
          }) || [];

        const outNetworkOOPS =
          filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
            array: plan?.llmExtractionInfo?.oops,
            fieldType: OUT_NETWORK_VALUE,
            fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
            isNot: true,
          }) || [];

        const indWithinFamOopMax =
          filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
            array: plan?.llmExtractionInfo?.oops,
            fieldType: IN_NETWORK_VALUE,
            fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
          }) || [];

        const indWithinFamOopMaxOutNetwork =
          filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
            array: plan?.llmExtractionInfo?.oops,
            fieldType: OUT_NETWORK_VALUE,
            fieldSubType: FIELDS_SUB_TYPES.SINGLE_WITHIN_FAMILY,
          }) || [];

        const rxDeductibleOutNetwork =
          filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
            array: plan?.llmExtractionInfo?.rxDeductible,
            fieldType: OUT_NETWORK_VALUE,
          }) || [];

        const rxOopOutNetwork =
          filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
            array: plan?.llmExtractionInfo?.rxOop,
            fieldType: OUT_NETWORK_VALUE,
          }) || [];

        const rxTiersOutNetwork =
          filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
            array: plan?.llmExtractionInfo?.rxTiers,
            fieldType: OUT_NETWORK_VALUE,
          });

        const deductibles =
          compareTwoArrayRemoveData(plan?.llmExtractionInfo?.deductibles, [
            ...(indWithinFamDeductiblesOutNetwork ?? []),
            ...(indWithinFamDeductibles ?? []),
            ...(outNetworkDeductibles ?? []),
          ]) || [];

        const oops =
          compareTwoArrayRemoveData(plan?.llmExtractionInfo?.oops, [
            ...indWithinFamOopMaxOutNetwork,
            ...indWithinFamOopMax,
            ...outNetworkOOPS,
          ]) || [];

        const rxDeductible = compareTwoArrayRemoveData(
          plan?.llmExtractionInfo?.rxDeductible,
          rxDeductibleOutNetwork
        );

        const rxOop = compareTwoArrayRemoveData(
          plan?.llmExtractionInfo?.rxOop,
          rxOopOutNetwork
        );

        const rxTiers = compareTwoArrayRemoveData(
          plan?.llmExtractionInfo?.rxTiers,
          rxTiersOutNetwork
        );

        const displayServices = transformServicesToPlanTableServices(
          plan?.llmExtractionInfo?.services,
          true
        );

        llmExtractionInfo.deductibles = deductibles || [];
        llmExtractionInfo.outNetworkDeductibles = outNetworkDeductibles || [];
        llmExtractionInfo.indWithinFamDeductibles =
          indWithinFamDeductibles || [];
        llmExtractionInfo.indWithinFamDeductiblesOutNetwork =
          indWithinFamDeductiblesOutNetwork || [];
        llmExtractionInfo.oops = oops || [];
        llmExtractionInfo.outNetworkOOPS = outNetworkOOPS || [];
        llmExtractionInfo.indWithinFamOopMax = indWithinFamOopMax || [];
        llmExtractionInfo.indWithinFamOopMaxOutNetwork =
          indWithinFamOopMaxOutNetwork || [];
        llmExtractionInfo.rxDeductible = rxDeductible || [];
        llmExtractionInfo.rxDeductibleOutNetwork = rxDeductibleOutNetwork || [];
        llmExtractionInfo.rxOop = rxOop || [];
        llmExtractionInfo.rxOopOutNetwork = rxOopOutNetwork || [];
        llmExtractionInfo.rxTiers = rxTiers || [];
        llmExtractionInfo.rxTiersOutNetwork = rxTiersOutNetwork || [];
        llmExtractionInfo.displayServices = displayServices || [];
      }
      return {
        ...plan,
        deductibles,
        oops,
        indWithinFamDeductibles,
        outNetworkDeductibles,
        indWithinFamDeductiblesOutNetwork,
        outNetworkOOPS,
        indWithinFamOopMax,
        indWithinFamOopMaxOutNetwork,
        rxDeductible,
        rxDeductibleOutNetwork,
        rxOop,
        rxOopOutNetwork,
        rxTiers,
        rxTiersOutNetwork,
        displayServices,
        isCurrentPlan,
        llmExtractionInfo,
        isTransformed: true,
      };
    }
    case OFFER_BENEFIT_TYPES.DENTAL.toUpperCase(): {
      const outNetworkDeductibles =
        filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
          array: plan?.deductibles,
          fieldType: OUT_NETWORK_VALUE,
        }) || [];

      const outNetworkMaximumBenefits =
        filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
          array: plan?.maximumBenefits,
          fieldType: OUT_NETWORK_VALUE,
        }) || [];

      const outNetworkOrthodontiaMax =
        filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
          array: plan?.orthodontiaMax,
          fieldType: OUT_NETWORK_VALUE,
        }) || [];

      const deductibles =
        compareTwoArrayRemoveData(plan?.deductibles, outNetworkDeductibles) ||
        [];

      const maximumBenefits =
        compareTwoArrayRemoveData(
          plan?.maximumBenefits,
          outNetworkMaximumBenefits
        ) || [];

      const orthodontiaMax =
        compareTwoArrayRemoveData(
          plan?.orthodontiaMax,
          outNetworkOrthodontiaMax
        ) || [];

      // creating displayServices for Display Service in the table in-network and out-of-network values
      const displayServices =
        transformServicesToPlanTableServices(plan?.services) || [];

      const llmExtractionInfo: Partial<Plan> = { ...plan?.llmExtractionInfo };

      if (plan?.hasOwnProperty('llmExtractionInfo')) {
        const outNetworkDeductibles =
          filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
            array: plan?.llmExtractionInfo?.deductibles,
            fieldType: OUT_NETWORK_VALUE,
          }) || [];

        const outNetworkMaximumBenefits =
          filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
            array: plan?.llmExtractionInfo?.maximumBenefits,
            fieldType: OUT_NETWORK_VALUE,
          }) || [];

        const outNetworkOrthodontiaMax =
          filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
            array: plan?.llmExtractionInfo?.orthodontiaMax,
            fieldType: OUT_NETWORK_VALUE,
          }) || [];

        const deductibles =
          compareTwoArrayRemoveData(
            plan?.llmExtractionInfo?.deductibles,
            outNetworkDeductibles
          ) || [];

        const maximumBenefits =
          compareTwoArrayRemoveData(
            plan?.llmExtractionInfo?.maximumBenefits,
            outNetworkMaximumBenefits
          ) || [];

        const orthodontiaMax =
          compareTwoArrayRemoveData(
            plan?.llmExtractionInfo?.orthodontiaMax,
            outNetworkOrthodontiaMax
          ) || [];

        const displayServices =
          transformServicesToPlanTableServices(
            plan?.llmExtractionInfo?.services,
            true
          ) || [];

        llmExtractionInfo.deductibles = deductibles || [];
        llmExtractionInfo.outNetworkDeductibles = outNetworkDeductibles || [];
        llmExtractionInfo.maximumBenefits = maximumBenefits || [];
        llmExtractionInfo.outNetworkMaximumBenefits =
          outNetworkMaximumBenefits || [];
        llmExtractionInfo.orthodontiaMax = orthodontiaMax || [];
        llmExtractionInfo.outNetworkOrthodontiaMax =
          outNetworkOrthodontiaMax || [];
        llmExtractionInfo.displayServices = displayServices || [];
      }

      return {
        ...plan,
        deductibles,
        maximumBenefits,
        orthodontiaMax,
        outNetworkDeductibles,
        outNetworkMaximumBenefits,
        outNetworkOrthodontiaMax,
        displayServices,
        llmExtractionInfo,
        isCurrentPlan,
        isTransformed: true,
      };
    }
    case OFFER_BENEFIT_TYPES.VISION.toUpperCase(): {
      const displayServices =
        transformServicesToPlanTableServices(plan?.services) || [];

      // filter out, out of network additional services
      const frequenciesOutNetwork =
        filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
          array: plan?.additionalServices,
          fieldType: OUT_NETWORK_VALUE,
        });

      const frequencies =
        compareTwoArrayRemoveData(
          plan?.additionalServices,
          frequenciesOutNetwork
        ) || [];

      const llmExtractionInfo: Partial<Plan> = { ...plan?.llmExtractionInfo };
      if (plan?.hasOwnProperty('llmExtractionInfo')) {
        const displayServices =
          transformServicesToPlanTableServices(
            plan?.llmExtractionInfo?.services,
            true
          ) || [];
        const frequenciesOutNetwork =
          filterDeductibleAndOopAndRxAndAdditionalServicesFromArray({
            array: plan?.llmExtractionInfo?.additionalServices,
            fieldType: OUT_NETWORK_VALUE,
          });
        const frequencies =
          compareTwoArrayRemoveData(
            plan?.llmExtractionInfo?.additionalServices,
            frequenciesOutNetwork
          ) || [];

        llmExtractionInfo.displayServices = displayServices || [];
        llmExtractionInfo.frequencies = frequencies || [];
        llmExtractionInfo.frequenciesOutNetwork = frequenciesOutNetwork || [];
      }

      return {
        ...plan,
        displayServices,
        frequenciesOutNetwork,
        frequencies,
        llmExtractionInfo,
        isCurrentPlan,
        isTransformed: true,
      };
    }
    default:
      return { ...plan, isCurrentPlan };
  }
};

/**
 * Transforms table plan data to edit data based on the benefit type.
 * @param {Object} options - The options object.
 * @param {Partial<Plan>} options.plan - The plan object.
 * @param {BenefitType} options.benefitType - The benefit type.
 * @return {Partial<Plan>} The transformed plan object.
 */
export const transformTablePlanDataToEditData = ({
  plan,
  benefitType,
}: {
  plan: Partial<Plan>;
  benefitType: BenefitType;
}): Partial<Plan> => {
  switch (benefitType) {
    case OFFER_BENEFIT_TYPES.MEDICAL.toUpperCase(): {
      const deductibles = [
        ...(plan?.deductibles || []),
        ...(plan?.indWithinFamDeductibles || []),
        ...(plan?.indWithinFamDeductiblesOutNetwork || []),
        ...(plan?.outNetworkDeductibles || []),
      ];
      const oops = [
        ...(plan?.oops || []),
        ...(plan?.indWithinFamOopMax || []),
        ...(plan?.indWithinFamOopMaxOutNetwork || []),
        ...(plan?.outNetworkOOPS || []),
      ];
      const rxDeductible = [
        ...(plan?.rxDeductible || []),
        ...(plan?.rxDeductibleOutNetwork || []),
      ];
      const rxOop = [...(plan?.rxOop || []), ...(plan?.rxOopOutNetwork || [])];

      const rxTiers = [
        ...(plan?.rxTiers || []),
        ...(plan?.rxTiersOutNetwork || []),
      ];

      const llmExtractionInfo: Partial<Plan> = {
        ...(plan?.llmExtractionInfo || {}),
        deductibles: [
          ...(plan?.llmExtractionInfo?.deductibles || []),
          ...(plan?.llmExtractionInfo?.indWithinFamDeductibles || []),
          ...(plan?.llmExtractionInfo?.indWithinFamDeductiblesOutNetwork || []),
          ...(plan?.llmExtractionInfo?.outNetworkDeductibles || []),
        ],
        oops: [
          ...(plan?.llmExtractionInfo?.oops || []),
          ...(plan?.llmExtractionInfo?.indWithinFamOopMax || []),
          ...(plan?.llmExtractionInfo?.indWithinFamOopMaxOutNetwork || []),
          ...(plan?.llmExtractionInfo?.outNetworkOOPS || []),
        ],
        rxDeductible: [
          ...(plan?.llmExtractionInfo?.rxDeductible || []),
          ...(plan?.llmExtractionInfo?.rxDeductibleOutNetwork || []),
        ],
        rxOop: [
          ...(plan?.llmExtractionInfo?.rxOop || []),
          ...(plan?.llmExtractionInfo?.rxOopOutNetwork || []),
        ],
        rxTiers: [
          ...(plan?.llmExtractionInfo?.rxTiers || []),
          ...(plan?.llmExtractionInfo?.rxTiersOutNetwork || []),
        ],
      };

      delete llmExtractionInfo?.indWithinFamDeductibles;
      delete llmExtractionInfo?.indWithinFamDeductiblesOutNetwork;
      delete llmExtractionInfo?.outNetworkDeductibles;
      delete llmExtractionInfo?.indWithinFamOopMax;
      delete llmExtractionInfo?.indWithinFamOopMaxOutNetwork;
      delete llmExtractionInfo?.outNetworkOOPS;
      delete llmExtractionInfo?.rxDeductibleOutNetwork;
      delete llmExtractionInfo?.rxOopOutNetwork;
      delete llmExtractionInfo?.rxTiersOutNetwork;
      delete llmExtractionInfo?.displayServices;

      return {
        ...plan,
        deductibles,
        oops,
        rxDeductible,
        rxOop,
        rxTiers,
        isTransformed: false,
        llmExtractionInfo,
      };
    }
    case OFFER_BENEFIT_TYPES.DENTAL.toUpperCase(): {
      const deductibles = [
        ...(plan?.deductibles || []),
        ...(plan?.outNetworkDeductibles || []),
      ];
      const maximumBenefits = [
        ...(plan?.maximumBenefits || []),
        ...(plan?.outNetworkMaximumBenefits || []),
      ];
      const orthodontiaMax = [
        ...(plan?.orthodontiaMax || []),
        ...(plan?.outNetworkOrthodontiaMax || []),
      ];

      const llmExtractionInfo: Partial<Plan> = {
        ...(plan?.llmExtractionInfo || {}),
        deductibles: [
          ...(plan?.llmExtractionInfo?.deductibles || []),
          ...(plan?.llmExtractionInfo?.outNetworkDeductibles || []),
        ],
        maximumBenefits: [
          ...(plan?.llmExtractionInfo?.maximumBenefits || []),
          ...(plan?.llmExtractionInfo?.outNetworkMaximumBenefits || []),
        ],
        orthodontiaMax: [
          ...(plan?.llmExtractionInfo?.orthodontiaMax || []),
          ...(plan?.llmExtractionInfo?.outNetworkOrthodontiaMax || []),
        ],
      };
      delete llmExtractionInfo?.outNetworkDeductibles;
      delete llmExtractionInfo?.outNetworkMaximumBenefits;
      delete llmExtractionInfo?.outNetworkOrthodontiaMax;
      delete llmExtractionInfo?.displayServices;

      return {
        ...plan,
        deductibles,
        maximumBenefits,
        orthodontiaMax,
        llmExtractionInfo,
        isTransformed: false,
      };
    }
    case OFFER_BENEFIT_TYPES.VISION.toUpperCase(): {
      const llmExtractionInfo: Partial<Plan> = {
        ...(plan?.llmExtractionInfo || {}),
        frequencies: [
          ...(plan?.llmExtractionInfo?.frequencies || []),
          ...(plan?.llmExtractionInfo?.frequenciesOutNetwork || []),
        ],
      };
      delete llmExtractionInfo?.frequenciesOutNetwork;
      delete llmExtractionInfo?.displayServices;

      return {
        ...plan,
        llmExtractionInfo,
        isTransformed: false,
      };
    }
    default:
      return { ...plan };
  }
};

/**
 * Builds the monthly premiums based on the provided parameters.
 * @param {boolean} isNTier Optional boolean flag indicating whether it is an N-tier plan.
 * @param {number} nTierCount Optional number indicating the count of N-tiers. Defaults to 4.
 * @param {PartialArrayItemTemplate[]} planPremiums Optional array of partial plan premium templates.
 * @return  {PartialArrayItemTemplate[]} An array of monthly premiums.
 */
const buildMonthlyPremiums = (
  isNTier?: boolean,
  nTierCount: number = 4,
  planPremiums?: PartialArrayItemTemplate[]
) => {
  if (isNTier) {
    // If it's an N-tier plan
    const premiumList = [] as any[];

    // Create the premium list based on nTierCount
    for (let index = 0; index < nTierCount + 1; index++) {
      premiumList.push({
        name: index === 0 ? 'EE Only' : `EE + ${index}`, // Define the name based on the index
        fieldType: null,
        fieldSubType: `${index === 0 ? 1 : index}/${index === 0 ? 4 : 6}`, // Define the fieldSubType
        value: null,
      });
    }

    // Map through the premium list to find and update the value from planPremiums
    const newPremiumList = premiumList?.map((premium) => {
      const planPremium = planPremiums?.find(
        (planPremium) =>
          (PREMIUMS_SHORT_FORM[planPremium?.name!] === premium?.name ||
            planPremium?.name === premium?.name) && // Match by name
          planPremium?.fieldSubType === premium?.fieldSubType // Match by fieldSubType
      );
      // If a matching planPremium is found, spread its value into the premium, otherwise return the original premium
      return planPremium ? { ...premium, value: planPremium?.value } : premium;
    });

    return newPremiumList; // Return the updated premium list
  } else {
    // If it's not an N-tier plan
    // Map through the standard tier defaults to find and update the value from planPremiums
    const valueFilledPremiums = STANDARD_TIER_DEFAULT?.map((premium) => {
      const planPremium = planPremiums?.find(
        (planPremium) =>
          (PREMIUMS_SHORT_FORM[planPremium?.name!] === premium?.name ||
            planPremium?.name === premium?.name) && // Match by name
          planPremium?.fieldSubType === premium?.fieldSubType // Match by fieldSubType
      );
      // If a matching planPremium is found, spread its value into the premium, otherwise return the original premium
      return planPremium ? { ...premium, value: planPremium?.value } : premium;
    });

    return valueFilledPremiums; // Return the updated standard tier premiums
  }
};

/**
 * Builds the enrollments based on the provided parameters.
 * @param {boolean} isNTier Optional boolean flag indicating whether it is an N-tier plan.
 * @param {number} nTierCount Optional number indicating the count of N-tiers. Defaults to 4.
 * @param {PartialArrayItemTemplate[]} planEnrollments Optional array of partial plan enrollment templates.
 * @return {PartialArrayItemTemplate[]} An array of enrollments.
 */
const buildEnrollments = (
  isNTier?: boolean,
  nTierCount: number = 4,
  planEnrollments?: PartialArrayItemTemplate[]
): PartialArrayItemTemplate[] => {
  if (isNTier) {
    // If it's an N-tier plan
    const enrollmentList: PartialArrayItemTemplate[] = [];

    // Create the enrollment list based on nTierCount
    for (let index = 0; index < nTierCount + 1; index++) {
      enrollmentList.push({
        name:
          index === 0 ? 'ENROLLMENT_TIER_1' : `ENROLLMENT_TIER_${index + 4}`, // Define the name based on the index
        fieldType: null,
        fieldSubType: `${index === 0 ? 1 : index}/${index === 0 ? 4 : 6}`, // Define the fieldSubType
        value: null,
      });
    }

    // Map through the enrollment list to find and update the value from planEnrollments
    const newEnrollmentList = enrollmentList?.map((enrollment) => {
      const planEnrollment = planEnrollments?.find(
        (planEnrollment) =>
          planEnrollment?.name === enrollment?.name && // Match by name
          planEnrollment?.fieldSubType === enrollment?.fieldSubType // Match by fieldSubType
      );
      // If a matching planEnrollment is found, spread its value into the enrollment, otherwise return the original enrollment
      return planEnrollment
        ? { ...enrollment, value: planEnrollment?.value }
        : enrollment;
    });

    return newEnrollmentList; // Return the updated enrollment list
  } else {
    // If it's not an N-tier plan
    // Map through the standard enrollments defaults to find and update the value from planEnrollments
    const valueFilledEnrollment = STANDARD_ENROLLMENTS_DEFAULT?.map(
      (enrollment) => {
        const planEnrollment = planEnrollments?.find(
          (planEnrollment) =>
            (PREMIUMS_SHORT_FORM[planEnrollment?.name!] === enrollment?.name ||
              planEnrollment?.name === enrollment?.name) && // Match by name
            planEnrollment?.fieldSubType === enrollment?.fieldSubType // Match by fieldSubType
        );
        // If a matching planEnrollment is found, spread its value into the enrollment, otherwise return the original enrollment
        return planEnrollment
          ? { ...enrollment, value: planEnrollment?.value }
          : enrollment;
      }
    );

    return valueFilledEnrollment; // Return the updated standard enrollments
  }
};

/**
 * Builds the default data by filling values from planPropertyData into defaultData and adding any missing items.
 * @param {PartialArrayItemTemplate[]} defaultData The default data array to be filled.
 * @param {PartialArrayItemTemplate[]} planPropertyData The array of plan property data to fill values from.
 * @return {PartialArrayItemTemplate[]} An array of default data with values filled from planPropertyData.
 */
const buildDefaultData = (
  defaultData: PartialArrayItemTemplate[],
  planPropertyData: PartialArrayItemTemplate[]
): PartialArrayItemTemplate[] => {
  // Fill values in defaultData from planPropertyData where names and types match
  const valueFilledDeductibles: PartialArrayItemTemplate[] = defaultData.map(
    (item) => {
      // Find matching item in planPropertyData
      const planData = planPropertyData?.find(
        (planDeductible: PartialArrayItemTemplate) =>
          planDeductible?.name === item?.name &&
          planDeductible?.fieldType === item?.fieldType &&
          planDeductible?.fieldSubType === item?.fieldSubType
      );
      // If a matching item is found, spread its value into the default item, otherwise return the original item
      return planData ? { ...item, value: planData?.value } : item;
    }
  );

  // Add missing items from planPropertyData to valueFilledDeductibles
  planPropertyData.forEach((item: PartialArrayItemTemplate) => {
    const existsInStandard = valueFilledDeductibles.some(
      (deductible) =>
        deductible?.name === item?.name &&
        deductible?.fieldType === item?.fieldType &&
        deductible?.fieldSubType === item?.fieldSubType
    );
    // If the item does not exist in valueFilledDeductibles, add it
    if (!existsInStandard) {
      valueFilledDeductibles.push(item);
    }
  });

  return valueFilledDeductibles; // Return the combined list with filled values and missing items added
};

/**
 * Retrieves the service priority order list based on the specified benefit type.
 *
 * @param {BenefitType} benefitType - The type of benefit for which to retrieve the service priority order list.
 * @return {ArrayItemTemplate[]} - The service priority order list for the specified benefit type.
 */
const buildServiceDefaultList = (benefitType: BenefitType) => {
  const serviceOrder = SERVICE_PRIORITY_ORDER_DEFAULT_SERVICES[benefitType];
  const result: ArrayItemTemplate[] = [];

  if (serviceOrder) {
    serviceOrder.forEach((service: string) => {
      result.push(
        {
          name: service,
          fieldType: 'In Network',
          fieldSubType: null,
          value: '',
        },
        {
          name: service,
          fieldType: 'Out of Network',
          fieldSubType: null,
          value: '',
        }
      );
    });
  }
  return result;
};

/**
 * Updates the default service list with the provided plan services.
 *
 * @param {PartialArrayItemTemplate[]} defaultServiceList - The default service list to be updated.
 * @param {PartialArrayItemTemplate[]} planServices - The plan services to update the default list with.
 * @return {PartialArrayItemTemplate[]} - The updated service list.
 */
const updateServiceListWithPlan = (
  defaultServiceList: PartialArrayItemTemplate[],
  planServices: PartialArrayItemTemplate[]
) => {
  const updatedServiceList = cloneDeep(defaultServiceList);

  const defaultServiceMap = new Map(
    updatedServiceList.map((service) => [
      `${service.name}-${service.fieldType}`,
      service,
    ])
  );

  // Iterate through the plan's services
  planServices.forEach((service) => {
    const defaultServiceKey = `${service.name}-${service.fieldType}`;
    const defaultService = defaultServiceMap.get(defaultServiceKey);

    if (defaultService) {
      // Replace default service with existing values
      defaultService.fieldType = service.fieldType;
      defaultService.fieldSubType = service.fieldSubType;
      defaultService.value = service.value;
    } else {
      // Append additional services not in the default list
      updatedServiceList.push(service);
    }
  });

  return updatedServiceList;
};

/**
 * Transforms plans by filling in missing data based on the benefit type and other parameters.
 * @param {Object} params - The parameters for the transformation.
 * @param {any[]} params.plans - The list of plans to be transformed.
 * @param {boolean} [params.isNTier] - Optional boolean flag indicating whether it is an N-tier plan.
 * @param {number} [params.nTierCount] - Optional number indicating the count of N-tiers.
 * @param {BenefitType} params.benefitType - The type of benefit for the plans.
 * @param {boolean} [params.isNoCurrentPlans] - Optional boolean flag indicating whether there are no current plans.
 * @return {any[]} An array of transformed plans.
 */
export const transformMissingFilledPlans = ({
  plans,
  isNTier,
  nTierCount,
  benefitType,
  isNoCurrentPlans,
}: {
  plans: any[];
  isNTier?: boolean;
  nTierCount?: number;
  benefitType: BenefitType;
  isNoCurrentPlans?: boolean;
}) => {
  // Get the default service priority order list based on the benefit type
  const defaultServiceList: ArrayItemTemplate[] =
    buildServiceDefaultList(benefitType);

  // Map through each plan to transform it based on the benefit type
  const newPlans = plans?.map((plan: Partial<Plan>) => {
    if (plan?.isTransformed) {
      return plan;
    }
    switch (benefitType) {
      case OFFER_BENEFIT_TYPES.MEDICAL.toUpperCase(): {
        // Build monthly premiums, enrolments, deductibles, and other medical-specific data
        const monthlyPremiums = buildMonthlyPremiums(
          isNTier,
          nTierCount!,
          plan?.monthlyPremiums
        );
        const enrolments = isNoCurrentPlans
          ? buildEnrollments(isNTier, nTierCount!, plan?.enrolments!)
          : plan?.enrolments;
        const deductibles = buildDefaultData(
          STANDARD_DEDUCTIBLES_DEFAULT,
          plan?.deductibles!
        );
        const oops = buildDefaultData(STANDARD_OOP_DEFAULT, plan?.oops!);
        const rxDeductible = buildDefaultData(
          STANDARD_RX_DEDUCTIBLES_DEFAULT,
          plan?.rxDeductible!
        );
        const rxOop = buildDefaultData(STANDARD_RX_OOP_DEFAULT, plan?.rxOop!);
        const rxTiers = buildDefaultData(
          STANDARD_RX_TIERS_DEFAULT,
          plan?.rxTiers!
        );
        const services = updateServiceListWithPlan(
          defaultServiceList,
          plan?.services!
        );

        // Return the transformed medical plan
        return {
          ...plan,
          deductibles: deductibles,
          monthlyPremiums: monthlyPremiums,
          services: services,
          oops: oops,
          rxDeductible: rxDeductible,
          rxOop: rxOop,
          rxTiers: rxTiers,
          enrolments: enrolments,
        };
      }
      case OFFER_BENEFIT_TYPES.DENTAL.toUpperCase(): {
        // Build monthly premiums, enrolments, deductibles, and other dental-specific data
        const monthlyPremiums = buildMonthlyPremiums(
          isNTier,
          nTierCount!,
          plan?.monthlyPremiums
        );
        const enrolments = isNoCurrentPlans
          ? buildEnrollments(isNTier, nTierCount!, plan?.enrolments!)
          : plan?.enrolments;
        const deductibles = buildDefaultData(
          STANDARD_DENTAL_DEDUCTIBLES_DEFAULT,
          plan?.deductibles!
        );
        const maximumBenefits = buildDefaultData(
          STANDARD_MAX_BENEFITS_DEFAULT,
          plan?.maximumBenefits!
        );
        const orthodontiaMax = buildDefaultData(
          STANDARD_ORTHODONTIA_MAX_DEFAULT,
          plan?.orthodontiaMax!
        );
        const services = updateServiceListWithPlan(
          defaultServiceList,
          plan?.services!
        );

        // Return the transformed dental plan
        return {
          ...plan,
          monthlyPremiums: monthlyPremiums,
          deductibles: deductibles,
          maximumBenefits: maximumBenefits,
          orthodontiaMax: orthodontiaMax,
          services: services,
          enrolments: enrolments,
        };
      }
      case OFFER_BENEFIT_TYPES.VISION.toUpperCase(): {
        // Build monthly premiums, enrolments, and services for vision plans
        const monthlyPremiums = buildMonthlyPremiums(
          isNTier,
          nTierCount!,
          plan?.monthlyPremiums
        );
        const enrolments = isNoCurrentPlans
          ? buildEnrollments(isNTier, nTierCount!, plan?.enrolments!)
          : plan?.enrolments;
        const services = updateServiceListWithPlan(
          defaultServiceList,
          plan?.services!
        );

        // Return the transformed vision plan
        return {
          ...plan,
          monthlyPremiums: monthlyPremiums,
          services: services,
          enrolments: enrolments,
        };
      }
      default: {
        // Return the plan as is if the benefit type doesn't match known types
        return { ...plan };
      }
    }
  });

  // Return the array of transformed plans
  return newPlans;
};

export const buildPremiumShortForm = (isNTier: boolean, nTierCount: number) => {
  const shortPremiumNames: Record<string, string> = {};
  const longPremiumNames: Record<string, string> = {};

  if (isNTier) {
    for (let index = 0; index <= nTierCount; index++) {
      const key = `Total Monthly Rate Tier ${index}*`;
      const value = index === 0 ? 'EE Only' : `EE + ${index}`;
      shortPremiumNames[key] = value;
      longPremiumNames[value] = key;
    }
  } else {
    STANDARD_TIER_NAMES_LIST.forEach((label, index) => {
      const key = `Total Monthly Rate Tier ${index + 1}*`;
      shortPremiumNames[key] = label;
      longPremiumNames[label] = key;
    });
  }

  return {
    shortPremiumNames: shortPremiumNames || null,
    longPremiumNames: longPremiumNames || null,
  };
};
