import { combineReducers } from '@reduxjs/toolkit';

import PlanReducer from 'modules/plans/slices/medicalPlanSlice';
import PlanBasicInfoReducer from 'modules/plans/slices/basicPlanInfoSlice';
import PlanDocumentsReducer from 'modules/plans/slices/planDocumentsSlice';
import VisionPlanReducer from 'modules/plans/slices/visionPlanSlice';
import telehealthRxPlanSlice from 'modules/plans/slices/telehealthRxPlanSlice';
import wellbeingPlanSlice from 'modules/plans/slices/wellbeingPlanSlice';
import additionalPerkPlanSlice from 'modules/plans/slices/additionalPerkPlanSlice';
import dentalPlanSlice from 'modules/plans/slices/dentalPlanSlice';
import retirementPlanSlice from 'modules/plans/slices/retirementPlanSlice';
import DbgInfoSlice from 'modules/plans/slices/dbgInfoSlice';
import lifePlanSlice from 'modules/plans/slices/lifePlanSlice';
import holidaysTimeOffPlanSlice from 'modules/plans/slices/workLifePlanSlice';
import voluntaryBenefitPlanSlice from 'modules/plans/slices/voluntaryBenefitPlanSlice';
import taxAdvantagedAccountPlanSlice from './taxAdvantagedAccountPlanSlice';
import planRatesSlice from './planRatesSlice';
import dbgSlice from './planSllice';
import planEnrollmentSlice from './planEnrollmentSlice';
import aiSbcUploaderSlice from './aiSbcUploaderSlice';

const planRootReducers = combineReducers({
  plans: PlanReducer,
  planBasicInfo: PlanBasicInfoReducer,
  planDocuments: PlanDocumentsReducer,
  dentalPlan: dentalPlanSlice,
  visionPlan: VisionPlanReducer,
  retirementPlan: retirementPlanSlice,
  telehealthRxPlan: telehealthRxPlanSlice,
  wellbeing: wellbeingPlanSlice,
  additionalPerk: additionalPerkPlanSlice,
  lifePlan: lifePlanSlice,
  dgbInfo: DbgInfoSlice,
  holidaysTimeOffPlan: holidaysTimeOffPlanSlice,
  voluntaryBenefitPlan: voluntaryBenefitPlanSlice,
  taxAdvantagedAccountPlan: taxAdvantagedAccountPlanSlice,
  planRates: planRatesSlice,
  dbg: dbgSlice,
  planEnrollment: planEnrollmentSlice,
  aiSbc: aiSbcUploaderSlice,
});

export default planRootReducers;
