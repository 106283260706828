import { FC, useEffect, useRef, useState } from 'react';
import { Button, Modal, notification } from 'antd';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import IconInfo from 'assets/images/icon-info.svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import DentalBasicPlanInfoUPWrapper from 'modules/plans/dental/components/PlanBasicInfo/DentalBasicPlanInfoUPWrapper';
import DeductiblesOOPMaxUPEditWrapper from 'modules/plans/dental/components/DeductiblesOOPMax/DeductiblesOOPMaxUPEditWrapper';
import ServiceUPEditWrapper from 'modules/plans/dental/components/Services/ServicesUPEditWrapper';
import RatesUPEditWrapper from 'modules/plans/dental/components/Rates/RatesUPEditWrapper';
import { EditMdvTypes } from 'modules/plans/constants';
import PanelSection from 'modules/plans/enums/PanelSection';
import {
  findDentalPlanById,
  saveDentalPlan,
} from 'modules/plans/slices/dentalPlanSlice';
import AddEnrollments from 'modules/plans/components/Enrollments/AddEnrollments/AddEnrollments';
import { DentalPlan } from 'model/plans/DentalPlan';
import { buildRates } from 'modules/plans/utils';
import { getEditedPlanField } from 'util/commonUtil';
import { PLAN_NOTIFICATION_KEY } from 'constants/benguideCollaborationConstants';

import styles from './editDentalPlanModal.module.less';

type EditType = {
  value: string;
  title: string;
  width: number;
};

type EditDentalPlanModalProps = {
  plan: any;
  onClose: Function;
  isModalVisible: boolean;
  editType: EditType;
  setViewModal: Function;
};

const EditDentalPlanModal: FC<EditDentalPlanModalProps> = ({
  plan,
  onClose,
  editType,
  isModalVisible,
}: EditDentalPlanModalProps) => {
  const [currentPlan, setCurrentPlan] = useState<DentalPlan>(plan);
  const [initialRates, setInitialRates] = useState<any>();
  const [isInitialRate, setIsInitialRate] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const dentalPlanRef = useRef<any>();
  const enrollmentsRef = useRef<any>();
  const [notificationKey] = useState(PLAN_NOTIFICATION_KEY);

  const { inProgress } = useAppSelector((state) => state.plan.dentalPlan);

  useEffect(() => {
    setCurrentPlan(plan);
    if (isInitialRate) {
      setInitialRates(plan.rates);
      setIsInitialRate(false);
    }
    // eslint-disable-next-line
  }, [plan]);

  const cancelModal = () => {
    onClose();
  };

  const checkDentalPlanChangedFields = (updatedDentalPlan: DentalPlan) => {
    return (
      (!isEqual(updatedDentalPlan, plan) &&
        (editType.value === EditMdvTypes.SERVICES.value ||
          editType.value === EditMdvTypes.DEDUCTIBLES_OOP_MAX.value ||
          editType.value === EditMdvTypes.ENROLLMENTS.value)) ||
      (!isEqual(updatedDentalPlan.rates, initialRates) &&
        editType.value === EditMdvTypes.RATES.value)
    );
  };

  const savePlan = async () => {
    if (
      (editType.value === EditMdvTypes.BASIC_INFO.value &&
        (await dentalPlanRef?.current?.isValidForm())) ||
      editType.value !== EditMdvTypes.BASIC_INFO.value
    ) {
      let updatedDentalPlan = currentPlan;
      if (editType.value === EditMdvTypes.RATES.value) {
        const { invalidFormFields = [] } =
          await dentalPlanRef?.current?.validate();
        if (!isEmpty(invalidFormFields)) {
          return;
        }
      }
      if (editType.value === EditMdvTypes.BASIC_INFO.value) {
        updatedDentalPlan = {
          ...currentPlan,
          rates: buildRates(currentPlan.groups, currentPlan),
        };
      }
      if (checkDentalPlanChangedFields(updatedDentalPlan)) {
        updatedDentalPlan = { ...updatedDentalPlan };

        updatedDentalPlan.planChangedFieldTypes = [
          getEditedPlanField(editType.value),
        ];
      }
      dispatch(
        saveDentalPlan(updatedDentalPlan, () => {
          onClose();
          dentalPlanRef?.current?.reset();
          if (plan && plan.id) {
            dispatch(findDentalPlanById(plan.id));
          }
        })
      );
      notification.close(notificationKey);
    }
  };

  const getEditTypeComponent = () => {
    switch (editType.value) {
      case EditMdvTypes.BASIC_INFO.value:
        return (
          <DentalBasicPlanInfoUPWrapper
            dentalPlan={currentPlan}
            setDentalPlan={setCurrentPlan}
            basicPlanInfoRef={dentalPlanRef}
            originalPlanYear={plan?.planYearId}
            originalPlanBenGroups={plan?.groups}
          />
        );
      case EditMdvTypes.DEDUCTIBLES_AND_MAXIMUMS.value:
        return (
          <DeductiblesOOPMaxUPEditWrapper
            dentalPlan={currentPlan}
            setDentalPlan={setCurrentPlan}
          />
        );
      case EditMdvTypes.RATES.value:
        return (
          <RatesUPEditWrapper
            dentalPlan={currentPlan}
            currentSection={PanelSection.RATES}
            wrappedRef={dentalPlanRef}
            setDentalPlan={setCurrentPlan}
          />
        );
      case EditMdvTypes.SERVICES.value:
        return (
          <ServiceUPEditWrapper
            dentalPlan={currentPlan}
            type={PanelSection.SERVICES}
            setDentalPlan={setCurrentPlan}
          />
        );
      case EditMdvTypes.ENROLLMENTS.value:
        return (
          <AddEnrollments
            plan={currentPlan}
            onChange={setCurrentPlan}
            isEdit={true}
            ref={enrollmentsRef}
          />
        );
    }
  };

  return (
    <Modal
      width={editType.width}
      title={<strong>{editType.title}</strong>}
      visible={isModalVisible}
      onCancel={cancelModal}
      okText="Done"
      footer={false}
      className={styles.dentalPlanChannelModal}
      closable={false}
      maskClosable={false}
    >
      <div className={styles.dentalPlanChannelWrapper}>
        <div className={styles.leftBorder}></div>
        <div className={styles.dentalPlanChannel}>
          <div>
            <img src={IconInfo} alt="Icon Info" />
          </div>
          <div className={styles.defaultText}>
            Changing plan information here will change the plan information
            everywhere this plan is shown
          </div>
        </div>
        {getEditTypeComponent()}
        <div className={styles.buttons}>
          <Button onClick={cancelModal} className={styles.cancel}>
            Cancel
          </Button>
          <Button
            onClick={savePlan}
            className={styles.done}
            loading={inProgress}
          >
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditDentalPlanModal;
