import { cloneDeep } from 'lodash';

export const usStates = [
  { value: 'ALABAMA', label: 'AL' },
  { value: 'ALASKA', label: 'AK' },
  { value: 'AMERICAN SAMOA', label: 'AS' },
  { value: 'ARIZONA', label: 'AZ' },
  { value: 'ARKANSAS', label: 'AR' },
  { value: 'CALIFORNIA', label: 'CA' },
  { value: 'COLORADO', label: 'CO' },
  { value: 'CONNECTICUT', label: 'CT' },
  { value: 'DELAWARE', label: 'DE' },
  { value: 'DISTRICT OF COLUMBIA', label: 'DC' },
  { value: 'FEDERATED STATES OF MICRONESIA', label: 'FM' },
  { value: 'FLORIDA', label: 'FL' },
  { value: 'GEORGIA', label: 'GA' },
  { value: 'GUAM', label: 'GU' },
  { value: 'HAWAII', label: 'HI' },
  { value: 'IDAHO', label: 'ID' },
  { value: 'ILLINOIS', label: 'IL' },
  { value: 'INDIANA', label: 'IN' },
  { value: 'IOWA', label: 'IA' },
  { value: 'KANSAS', label: 'KS' },
  { value: 'KENTUCKY', label: 'KY' },
  { value: 'LOUISIANA', label: 'LA' },
  { value: 'MAINE', label: 'ME' },
  { value: 'MARSHALL ISLANDS', label: 'MH' },
  { value: 'MARYLAND', label: 'MD' },
  { value: 'MASSACHUSETTS', label: 'MA' },
  { value: 'MICHIGAN', label: 'MI' },
  { value: 'MINNESOTA', label: 'MN' },
  { value: 'MISSISSIPPI', label: 'MS' },
  { value: 'MISSOURI', label: 'MO' },
  { value: 'MONTANA', label: 'MT' },
  { value: 'NEBRASKA', label: 'NE' },
  { value: 'NEVADA', label: 'NV' },
  { value: 'NEW HAMPSHIRE', label: 'NH' },
  { value: 'NEW JERSEY', label: 'NJ' },
  { value: 'NEW MEXICO', label: 'NM' },
  { value: 'NEW YORK', label: 'NY' },
  { value: 'NORTH CAROLINA', label: 'NC' },
  { value: 'NORTH DAKOTA', label: 'ND' },
  { value: 'NORTHERN MARIANA ISLANDS', label: 'MP' },
  { value: 'OHIO', label: 'OH' },
  { value: 'OKLAHOMA', label: 'OK' },
  { value: 'OREGON', label: 'OR' },
  { value: 'PALAU', label: 'PW' },
  { value: 'PENNSYLVANIA', label: 'PA' },
  { value: 'PUERTO RICO', label: 'PR' },
  { value: 'RHODE ISLAND', label: 'RI' },
  { value: 'SOUTH CAROLINA', label: 'SC' },
  { value: 'SOUTH DAKOTA', label: 'SD' },
  { value: 'TENNESSEE', label: 'TN' },
  { value: 'TEXAS', label: 'TX' },
  { value: 'UTAH', label: 'UT' },
  { value: 'VERMONT', label: 'VT' },
  { value: 'VIRGIN ISLANDS', label: 'VI' },
  { value: 'VIRGINIA', label: 'VA' },
  { value: 'WASHINGTON', label: 'WA' },
  { value: 'WEST VIRGINIA', label: 'WV' },
  { value: 'WISCONSIN', label: 'WI' },
  { value: 'WYOMING', label: 'WY' },
];

// These should match platform's BenefitCategory.java
export const BenefitCategory = {
  LIFE: {
    value: 'LIFE',
    label: 'Life & Disability',
  },
  MEDICAL: { value: 'MEDICAL', label: 'Medical' },
  DENTAL: { value: 'DENTAL', label: 'Dental' },
  VISION: { value: 'VISION', label: 'Vision' },
  SAVING: { value: 'SAVING', label: 'Spending' },
  COMMUTER: { value: 'COMMUTER', label: 'Commuter' },
  RETIREMENT: { value: 'RETIREMENT', label: 'Retirement' },
  WELLBEING: { value: 'WELLBEING', label: 'Wellbeing' },
  MEDITATION: { value: 'MEDITATION', label: 'Meditation' },
  MENTAL_HEALTH: { value: 'MENTAL_HEALTH', label: 'Mental Health' },
  WELLNESS_REIMBURSEMENT: {
    value: 'WELLNESS_REIMBURSEMENT',
    label: 'Wellness Reimbursement',
  },
  WORK_LIFE: { value: 'WORK_LIFE', label: 'Worklife' },
  ADDITIONAL_PERK: { value: 'ADDITIONAL_PERK', label: 'Additional Perk' },
  TELEHEALTH: { value: 'TELEHEALTH', label: 'Telehealth' },
  RX_DELIVERY: { value: 'RX_DELIVERY', label: 'Rx Delivery' },
  RX_COUPONS: { value: 'RX_COUPONS', label: 'Rx Coupons' },
  FAMILY_AND_LEAVE: { value: 'FAMILY_AND_LEAVE', label: 'Family and Leave' },
  HOLIDAYS_AND_TIME_OFF: {
    value: 'HOLIDAYS_AND_TIME_OFF',
    label: 'Holidays and Time Off',
  },
  VOL_BENEFITS: {
    label: 'Voluntary benefit',
    value: 'VOL_BENEFITS',
  },
  VOLUNTARY_BENEFIT: {
    label: 'Voluntary benefit',
    value: 'VOLUNTARY_BENEFIT',
  },
  TAX_ADVANTAGE: {
    label: 'Tax Advantage',
    value: 'TAX_ADVANTAGE',
  },
  STD: {
    label: 'Short Term Disability',
    value: 'STD',
  },
  LTD: {
    label: 'Long Term Disability',
    value: 'LTD',
  },
  BASIC_LIFE: {
    label: 'Basic Life',
    value: 'BASIC_LIFE',
  },
  BASIC_ADD: {
    label: 'Basic Add',
    value: 'BASIC_ADD',
  },
  BASIC_LIFE_AND_ADD: {
    label: 'Basic Life And ADD',
    value: 'BASIC_LIFE_AND_ADD',
  },
};
export const RX_DELIVERY_RX_COUPONS = 'RX_DELIVERY_RX_COUPONS';

export const BenefitKind = {
  RETIREMENT_401K: { value: 'RETIREMENT_401K', label: '401(K)' },
  WELLBEING_GENERIC: { value: 'WELLBEING_GENERIC', label: 'Wellbeing' },
  PAID_TIME_OFF: { value: 'PAID_TIME_OFF', label: 'Paid Time Off' },
  SICK: { value: 'SICK', label: 'Sick' },
  HOLIDAY: { value: 'HOLIDAY', label: 'Holiday' },
  FLEXIBLE_WORKING_HOURS: {
    value: 'FLEXIBLE_WORKING_HOURS',
    label: 'Flexible Working Hours',
  },
  OTHER_TIME_OFF: { value: 'OTHER_TIME_OFF', label: 'Other Time Off' },
  PARENTAL_LEAVE: { value: 'PARENTAL_LEAVE', label: 'Parental Leave' },
  BACKUP_CHILDCARE: { value: 'BACKUP_CHILDCARE', label: 'Backup Childcare' },
  FERTILITY: { value: 'FERTILITY', label: 'Fertility' },
  BEREAVEMENT: { value: 'BEREAVEMENT', label: 'Bereavement' },
  FAMILY_AND_LEAVE_OTHER: { value: 'FAMILY_AND_LEAVE_OTHER', label: 'Other' },
  ACCIDENT: { value: 'ACCIDENT', label: 'Accident' },
  CRITICAL_ILLNESS: { value: 'CRITICAL_ILLNESS', label: 'Critical Illness' },
  HOSPITAL: { value: 'HOSPITAL', label: 'Hospital' },
  CUSTOM_PLAN: { value: 'CUSTOM_VOLUNTARY_BENEFIT', label: 'Custom Plan' },
  HRA: { value: 'HRA', label: 'Health Reimbursement Account (HRA)' },
  FSA: { value: 'FSA', label: 'Healthcare Flexible Spending Account (FSA)' },
  DCAP_FSA: {
    value: 'DCAP_FSA',
    label: 'Dependent Care Flexible Spending Account (DCFSA)',
  },
  LIMITED_FSA: {
    value: 'LIMITED_FSA',
    label: 'Limited Purpose Flexible Spending Account (LPFSA)',
  },
  HSA: { value: 'HSA', label: 'Health Savings Account (HSA)' },
  COMMUTER_GENERIC: { value: 'COMMUTER_GENERIC', label: 'Commuter' },
  CUSTOM_VOLUNTARY_BENEFIT: {
    value: 'CUSTOM_VOLUNTARY_BENEFIT',
    label: 'Other Voluntary Benefit',
  },
  RX_DELIVERY: { value: 'RX_DELIVERY', label: 'Rx Delivery' },
  MEDICAL: { value: 'MEDICAL', label: 'Medical' },
  VISION: { value: 'VISION', label: 'Vision' },
  DENTAL: { value: 'DENTAL', label: 'Dental' },
  SAVING: { value: 'SAVING', label: 'Spending' },
  COMMUTER: { value: 'COMMUTER', label: 'Commuter' },
  RETIREMENT: { value: 'RETIREMENT', label: 'Retirement' },
  WELLBEING: { value: 'WELLBEING', label: 'Wellbeing' },
  MEDITATION: { value: 'MEDITATION', label: 'Meditation' },
  MENTAL_HEALTH: { value: 'MENTAL_HEALTH', label: 'Mental Health' },
  WELLNESS_REIMBURSEMENT: {
    value: 'WELLNESS_REIMBURSEMENT',
    label: 'Wellness Reimbursement',
  },
  WORK_LIFE: { value: 'WORK_LIFE', label: 'Worklife' },
  ADDITIONAL_PERK: { value: 'ADDITIONAL_PERK', label: 'Additional Perk' },
  TELEHEALTH: { value: 'TELEHEALTH', label: 'Telehealth' },
  RX_COUPONS: { value: 'RX_COUPONS', label: 'Rx Coupons' },
  FAMILY_AND_LEAVE: { value: 'FAMILY_AND_LEAVE', label: 'Family and Leave' },
  HOLIDAYS_AND_TIME_OFF: {
    value: 'HOLIDAYS_AND_TIME_OFF',
    label: 'Holidays and Time Off',
  },
  VOL_BENEFITS: {
    label: 'Voluntary benefit',
    value: 'VOL_BENEFITS',
  },
  VOLUNTARY_BENEFIT: {
    label: 'Voluntary benefit',
    value: 'VOLUNTARY_BENEFIT',
  },
  TAX_ADVANTAGE: {
    label: 'Tax Advantage',
    value: 'TAX_ADVANTAGE',
  },
  STD: {
    label: 'Short Term Disability',
    value: 'STD',
  },
  LTD: {
    label: 'Long Term Disability',
    value: 'LTD',
  },
  BASIC_LIFE: {
    label: 'Basic Life',
    value: 'BASIC_LIFE',
  },
  BASIC_ADD: {
    label: 'Basic Add',
    value: 'BASIC_ADD',
  },
  BASIC_LIFE_AND_ADD: {
    label: 'Basic Life And ADD',
    value: 'BASIC_LIFE_AND_ADD',
  },
  VOLUNTARY_LIFE: { value: 'VOLUNTARY_LIFE', label: 'Voluntary Life' },
  VOLUNTARY_ADD: { value: 'VOLUNTARY_ADD', label: 'Voluntary AD&D' },
  VOLUNTARY_LIFE_ADD: {
    value: 'VOLUNTARY_LIFE_ADD',
    label: 'Voluntary Life w/AD&D',
  },
  VOLUNTARY_STD: {
    value: 'VOLUNTARY_STD',
    label: 'Voluntary Short-Term Disability',
  },
  VOLUNTARY_LTD: {
    value: 'VOLUNTARY_LTD',
    label: 'Voluntary Long-Term Disability',
  },
};

export const brokerFooterText =
  'NOTE: You must complete all the steps for broker information to be saved.';

export const multiSelectTypes = {
  FILTER: 'FILTER',
  ALL_SELECTABLE: 'ALL_SELECTABLE',
};

export const checkboxGroupMode = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
};

export const IndividualType = {
  ORGANIZATION_ADMIN: 'ORGANIZATION_ADMIN',
  ER_ADMIN: 'ER_ADMIN',
  LUMITY_ADMIN: 'LUMITY_ADMIN',
  PLATFORM_ADMIN: 'PLATFORM_ADMIN',
  INTERNAL_ADMIN: 'INTERNAL_ADMIN',
  OPS_ADMIN: 'OPS_ADMIN',
  IMPLEMENTATION_ADMIN: 'IMPLEMENTATION_ADMIN',
  EMPLOYER_USER: 'EMPLOYER_USER',
};

export const IndividualSubTypes = {
  BROKER_ADMIN: 'BROKER_ADMIN',
  SUPER_BROKER_ADMIN: 'BROKER_SUPER_ADMIN',
  BROKER_USER: 'BROKER_USER',
  OPS_ADMIN: 'OPS_ADMIN',
  IMPLEMENTATION_ADMIN: 'IMPLEMENTATION_ADMIN',
  EMPLOYER_ADMIN: 'EMPLOYER_ADMIN',
  EMPLOYER_MEMBER: 'EMPLOYER_MEMBER',
};

export const PaginationElementType = {
  page: 'page',
  next: 'next',
  prev: 'prev',
};

export const allOption = 'all';
export const singleSelectAllOptions = 'ALL';
export const singleSelectAllLabel = 'All';
export const allOptionsEmployers = 'All Employers';

export const STATUS_204 = 204;
export const STATUS_200 = 200;
export const STATUS_202 = 202;

export const EMPTY_MESSAGE = '';

export const logoUploadHelperText =
  'Logos should be transparent or have a white background in PNG or JPG format, with a max file size 2MB.';

export const formVerificationMsg =
  'Please correct all items highlighted in red.';

export const emailDomainVerificationMsg =
  'Please use a valid format for email domains (example: companyname.com). If you are adding multiple domains, use a comma to delimit them.';

export const uploadImageSizeValidationMsg =
  'Photos should be in PNG or JPG format, with a max file size 2MB.';

export const benefitGroupsChangedMsg =
  'Changing the benefit classes may change or remove this plan from benefit guides associated with that class.';

export const planYearChangedMsg =
  'Changing the plan year will remove this plan from any benefit guides it is currently attached to.';

export const VolBenefitBenefitKinds = {
  ACCIDENTAL: { value: 'ACCIDENT', label: 'Accident' },
  CRITICAL_ILLNESS: { value: 'CRITICAL_ILLNESS', label: 'Critical Illness' },
  HOSPITAL: { value: 'HOSPITAL', label: 'Hospital' },
  CUSTOM_PLAN: { value: 'CUSTOM_VOLUNTARY_BENEFIT', label: 'Custom Plan' },
};

export const planAlreadyExistsError = 'A plan with this name already exists.';
export const planCreateSuccess = 'Successfully created new plan.';
export const sbcReviewStarted = `You’ll receive a notification when document processing is complete.`;
export const sbcfetchError = `Sorry, we encountered an error.`;
export const planOfferFetchError = `This plan offer no longer exists.`;

export const planCreateError = 'Plan creation has failed.';

export const exitWithoutSavingMsg =
  'If you leave now, you will lose any unsaved data. Do you wish to continue?';

export const exitWithoutSavingMsgSBC = `Unsaved changes will be lost. To save your edits, click the
bottom 'Complete and Save Plan' before closing this window.`;

export const exitWithoutSavingMsgWhileProcessingSBC = `Once processing is started, it cannot be cancelled. If you do not want
the Al response, please delete it when the processing is completed.`;

export const successfullyClonedMessage = `Cloned additional plans and benefit guides have been added
  to the new Plan Year. To manage these plans, please view
  them in the plans section.`;

export const maxPlanNameSizeMDV: number = 50;
export const maxPlanNameSize: number = 50;

export const maxDentalDeductibleValue: number = 1000000;
export const maxDentalServiceValue: number = 1000000;
export const maxMedicalDeductibleValue: number = 1000000;
export const maxMedicalServiceValue: number = 1000000;
export const maxMedicalRXValue: number = 1000000;
export const maxVisionServiceValue: number = 1000000;

export const MASTER = 'MASTER';

// plan documents
export const PLAN_DOCUMENTS_DOWNLOAD = 'planDocumentsDownloadNotification';
export const DOWNLOAD_READY_MESSAGE_TITLE = 'Download Ready';
export const DOWNLOAD_READY_MESSAGE_BODY =
  'Your requested file is ready for download.';

// plan error codes
export const PLAN_NOT_FOUND = 'plan.not.found';

export const IN_APP_NOTIFICATIONS = {
  CLAIMS: 'CLAIMS',
  DBG: 'DBG',
  BILLING: 'BILLING',
  PROPOSALS: 'PROPOSALS',
  OFFERS: 'OFFERS',
};

export const IN_APP_NOTIFICATION_ACTIONS = {
  OFFER_QUOTE_FINALIZED: 'OFFER_QUOTE_FINALIZED',
  OFFER_EXISTING_PUBLISHED: 'OFFER_EXISTING_PUBLISHED',
  OFFER_CREATE_PUBLISHED: 'OFFER_CREATE_PUBLISHED',
  OFFER_BULK_PUBLISHED: 'OFFER_BULK_PUBLISHED',
};

export const FEATURE_KEYS = {
  CLAIMS: 'CLAIMS',
  ISSUES_LOG: 'ISSUES_LOG',
  BILLING: 'BILLING',
  PLAN_RATES: 'PLAN_RATES',
  BENEFIT_GUIDE: 'BENEFIT_GUIDE',
  BENEFIT_CONSULTATION: 'BENEFIT_CONSULTATION',
  RENEWAL: 'RENEWAL',
  SELF_REVIEW: 'SELF_REVIEW',
  QUOTE_SELF_REVIEW: 'QUOTE_SELF_REVIEW',
  AI_ASSISTANT: 'AI_ASSISTANT',
  ADVANCED_PROFILE: 'ADVANCED_PROFILE',
  BROKER_AI_ASSISTANT: 'AI_ASSISTANT_CHAT',
};

export const TOOLS_CONFIGURATION = {
  ID_CARDS: 'ID_CARDS',
};

export const RENEWAL_PLANS = [
  'MEDICAL',
  'DENTAL',
  'VISION',
  'LIFE',
  'CUSTOM_VOLUNTARY_BENEFIT',
];

export const EMDASH_LITERAL = '—';

export const BILLING_NOTIFICATION_TYPES = {
  NEW: 'new_bill',
  UPDATED: 'updated_bill',
};

export const COLLAPSE_EXPAND_ALL_TEXT = {
  COLLAPSE_ALL: 'Collapse All',
  EXPAND_ALL: 'Expand All',
};

export const MOBILE_DEMO_ANDROID = 'Android';
export const MOBILE_DEMO_IOS = 'IOS';

export const MOBILE_DEMO_ANDROID_KEY = 'dpitah6fhvnnr3bim4le75an3e';
export const MOBILE_DEMO_IOS_KEY = 'lpr5ekn4w5ewztfso4j6rmkp5q';

// Brokers: Allege Benefits Group, Lumity Benefits
export const MOBILE_DEMO_VISIBLE_BROKERS = [
  '6f48c58f-d71c-471d-a486-436973a3ef61',
  '04855d4f-21c6-4557-bc38-ad960ce6f7cd',
  'd1fcce07-342e-43b6-9b9c-dd2356d08c83',
];

export const MOBILE_DEMO_RESTRICTED_BROKER_SUBTYPES = [
  'OPS_ADMIN',
  'IMPLEMENTATION_ADMIN',
];

export const BENEFIT_CONSULTATION_BROKER_ENABLE_MESSAGE =
  'Are you sure you want to enable Benefits Consultation? This broker and their employers will be able to access their benefits consultation data.';
export const BENEFIT_CONSULTATION_BROKER_DISABLE_MESSAGE = `Are you sure you want to disable Benefits consultation? This broker and all associated locations will lose access to this tool immediately.`;
export const BENEFIT_CONSULTATION_EMPLOYER_ENABLE_MESSAGE =
  'Are you sure you want to enable Benefits Consultation? This employer will be able to access their benefits consultation data.';
export const BENEFIT_CONSULTATION_EMPLOYER_DISABLE_MESSAGE = `Are you sure you want to disable Benefits consultation? This tool will be immediately hidden from the employer’s view. Any already scheduled meetings with the broker consultants will remain and should be manually updated from this user's calendar if needed.`;

export const BENGUIDE_EMPLOYER_ENABLE_MESSAGE =
  'Are you sure you want to enable Benefits Guide? This employer will be able to access their benefits guide data.';
export const BENGUIDE_EMPLOYER_DISABLE_MESSAGE =
  'Are you sure you want to disable Benefits Guides? This tool will be immediately hidden from the employer’s view.';
export const BENGUIDE_BROKER_ENABLE_MESSAGE =
  'Are you sure you want to enable Benefits Guide? This broker and their employers will be able to access their benefits guide data.';
export const BENGUIDE_BROKER_DISABLE_MESSAGE =
  'Are you sure you want to disable Benefits Guides? This broker and all associated locations will lose access to this tool immediately.';

export const RENEWAL_BROKER_ENABLE_MESSAGE =
  'Are you sure you want to enable Renewals? This broker and their employers will be able to access their claims Renewals.';
export const RENEWAL_BROKER_DISABLE_MESSAGE =
  'Are you sure you want to disable Renewals? This broker and all associated locations will lose access to this tool immediately.';
export const RENEWAL_EMPLOYER_ENABLE_MESSAGE =
  'Are you sure you want to enable Renewals? This employer will be able to access their Renewals data.';
export const RENEWAL_EMPLOYER_DISABLE_MESSAGE =
  "Are you sure you want to disable Renewals? This tool will be immediately hidden from the employer's view.";

export const EMP_LIMIT_REACH_POPOVER_MSG =
  'Employer capacity reached. Please contact PlanYear support for more information.';

export const AI_ASSISTANT_BROKER_ENABLE_MESSAGE =
  'Are you sure you want to enable AI Assistant? This broker and their employers will be able to access to this tool immediately.';

export const CLOSE_POPUP_CONFIRM_TEXT = 'Yes - close and do not save';
export const CLOSE_POPUP_CANCEL_TEXT = 'Cancel';
export const CLOSE_POPUP_TITLE_TEXT = 'Are you sure you want to close?';

export const MOBILE_APP_ANDROID_URL =
  'https://play.google.com/store/apps/details?id=com.planyear.mobile';
export const MOBILE_APP_IOS_URL =
  'https://apps.apple.com/us/app/planyear-employee-benefits/id1668942636';

export const QUOTE_ENABLED_ENV = ['local', 'demo', 'test'];

export const VALIDATION_ERROR_NOTICE = 'Validation Failed';
export const VALIDATION_ERROR_DESCRIPTION = 'Enrollment details are mandatory';
export const VALIDATION_ERROR_STD_FIELDS_DESCRIPTION = 'Details are mandatory';

export const RENEWALS_STARTED_WARNING = `This change will not be applied to the ongoing renewal process.
If updates are required for renewals, the renewal process must
be reset.`;

export const mobilePlanEnrollmentWarning = (
  planName: string
) => `${planName} cannot be deleted as at least one user has selected
this plan in the mobile app. These elections must be cleared in
order to delete the plan. Please reach out to the PlanYear support
team for assistance.`;

export const ENROLMENTS_INFO_WHEN_AGE_BAND_SELECTED = `The selected premium type is "Age Band”.`;

export const COVERAGE = {
  COVERED: 'COVERED',
  NOT_COVERED: 'NOT_COVERED',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};

export const EMPLOYER_NOT_FOUND = 'employer.not.found';

export const SBC_PARAMS = {
  jobId: 'job-id',
  isReview: 'review',
};

export const TOP_NAVIGATION = {
  HOME: 'Brokers',
};

export const ADD_NEW_CARRIER_MODAL_CONSTANTS = {
  title: 'Add New Carrier',
  firstButtonLabel: 'Add New Carrier for All Employers',
  secondButtonLabel: 'Add New Carrier for This Employer Only',
};

export const addNewCarrierModalDescription = (empName: string) =>
  `Do you want to add a new carrier for all employers across your brokerage
  or specific to ${empName || ''}?`;

export const HOLIDAYS_AND_TIME_OFF_BENEFIT_KINDS = [
  'SICK',
  'PAID_TIME_OFF',
  'HOLIDAY',
  'FLEXIBLE_WORKNG_HOURS',
  'OTHER_TIME_OFF',
];
export const FAMILY_AND_LEAVE_BENEFIT_KINDS = [
  'PARENTAL_LEAVE',
  'BACKUP_CHILDCARE',
  'FERTILITY',
  'BEREAVEMENT',
  'FAMILY_AND_LEAVE_OTHER',
];
export const EMPLOYER_ACCESS_LOCKED =
  'This employer does not have access to this tool.';

export const CONFIRM_TOGGLE_TEXT = 'CONFIRM';
export const CLOSE_CONFIRM_TEXT = 'CLOSE';
export const CLOSE_RENEWAL_WARNING_TEXT =
  'In order to start a new renewal, the existing pending renewal (including' +
  ' its open offers and proposals) will need to be closed. This action cannot be undone. The overlapping plan year is ';

export const BROKER_ADMIN_TEXT =
  'You have reached the user limit for RFP in the following locations assigned to this admin:';

export const BROKER_ADMIN_CONFIRM_TEXT = `Do you wish to continue? Your contract will be automatically updated to reflect the additional users and associated costs. 
  A member of the sales team will reach out to you with this information.`;

export const TECH_ADMIN_TEXT =
  'This account has reached the user limit for RFP in the following locations assigned to this admin:';

export const TEECH_ADMIN_CONFIRM_TEXT = `Do you wish to continue? Adding additional broker admins to these locations will incur additional charges per the terms of the contract.
   Please ensure that the user limit is updated in the Platform once it has been finalized.`;

export const SELF_REVIEW_MESSAGE =
  'Please note that this configuration will not be applied to existing files that are still in progress or under review.';

export const BROKER_NAME = 'brokerName';

export const DESCENDING_ORDER = 'descend';

export const ASCENDING_ORDER = 'ascend';

export const weblinkValidationMsg =
  'The weblink cannot contain the following characters: < > ^ { } [ ]';

export const KNOWLEDGE_BASE_URL = 'https://knowledgebase.planyear.com/';

export const SUPPORT_EMAIL = 'support@planyear.com';

export const BROKER_ADMIN_TYPE_LABELS = {
  [IndividualSubTypes.SUPER_BROKER_ADMIN]: 'Super Broker Admin',
  [IndividualSubTypes.BROKER_ADMIN]: 'Broker Admin',
  [IndividualSubTypes.BROKER_USER]: 'Broker User',
  [IndividualSubTypes.OPS_ADMIN]: 'OPS Admin',
  [IndividualSubTypes.IMPLEMENTATION_ADMIN]: 'Implementation Admin',
};

export const BROKER_ADMIN_TYPES = [
  {
    label: BROKER_ADMIN_TYPE_LABELS[IndividualSubTypes.SUPER_BROKER_ADMIN],
    value: IndividualSubTypes.SUPER_BROKER_ADMIN,
  },
  {
    label: BROKER_ADMIN_TYPE_LABELS[IndividualSubTypes.BROKER_ADMIN],
    value: IndividualSubTypes.BROKER_ADMIN,
  },
  {
    label: BROKER_ADMIN_TYPE_LABELS[IndividualSubTypes.BROKER_USER],
    value: IndividualSubTypes.BROKER_USER,
  },
];

export const getBrokerAdminType = (
  loginType: string | undefined,
  loginSubType: string | undefined,
  isBrokerOnboardingFlow: boolean
) => {
  const adminTypes = cloneDeep(BROKER_ADMIN_TYPES);
  if (loginType === IndividualType.PLATFORM_ADMIN) {
    if (!isBrokerOnboardingFlow) {
      adminTypes.push(
        {
          label: BROKER_ADMIN_TYPE_LABELS[IndividualType.OPS_ADMIN],
          value: IndividualType.OPS_ADMIN,
        },
        {
          label: BROKER_ADMIN_TYPE_LABELS[IndividualType.IMPLEMENTATION_ADMIN],
          value: IndividualType.IMPLEMENTATION_ADMIN,
        }
      );
    }

    return adminTypes;
  } else if (loginSubType === IndividualSubTypes.SUPER_BROKER_ADMIN) {
    return BROKER_ADMIN_TYPES;
  } else if (
    loginSubType === IndividualSubTypes.BROKER_ADMIN ||
    loginSubType === IndividualSubTypes.OPS_ADMIN ||
    loginSubType === IndividualSubTypes.IMPLEMENTATION_ADMIN ||
    loginType === IndividualType.OPS_ADMIN ||
    loginType === IndividualType.IMPLEMENTATION_ADMIN
  ) {
    return [
      {
        label: BROKER_ADMIN_TYPE_LABELS[IndividualSubTypes.SUPER_BROKER_ADMIN],
        value: IndividualSubTypes.SUPER_BROKER_ADMIN,
        hidden: true,
      },
      {
        label: BROKER_ADMIN_TYPE_LABELS[IndividualSubTypes.BROKER_ADMIN],
        value: IndividualSubTypes.BROKER_ADMIN,
      },
      {
        label: BROKER_ADMIN_TYPE_LABELS[IndividualSubTypes.BROKER_USER],
        value: IndividualSubTypes.BROKER_USER,
      },
    ];
  }
  return [];
};

export const ALLOWED_INDIVIDUAL_SUB_TYPES = [
  IndividualSubTypes.SUPER_BROKER_ADMIN,
  IndividualSubTypes.BROKER_ADMIN,
  IndividualSubTypes.OPS_ADMIN,
  IndividualSubTypes.IMPLEMENTATION_ADMIN,
];

export const EMPLOYER_MEMBER_RESTRICTED = [
  ...ALLOWED_INDIVIDUAL_SUB_TYPES,
  IndividualSubTypes.BROKER_USER,
  IndividualSubTypes.EMPLOYER_ADMIN,
];

export const EMPLOYER_USER_TYPES_DROPDOWN = [
  {
    value: 'EMPLOYER_ADMIN',
    label: 'Employer Admin',
  },
  {
    value: 'EMPLOYER_MEMBER',
    label: 'Employer Member',
  },
];

export const ALL_FEATURE_KEYS = {
  ADVANCED_PROFILE: { value: 'ADVANCED_PROFILE', label: 'Advanced Profile' },
  BENEFIT_GUIDE: { value: 'BENEFIT_GUIDE', label: 'Benefit Guides' },
  BENEFIT_CONSULTATION: {
    value: 'BENEFIT_CONSULTATION',
    label: 'Benefit Consultation',
  },
  ISSUES_LOG: { value: 'ISSUES_LOG', label: 'Account Log' },
  BILLING: { value: 'BILLING', label: 'Billing' },
  CLAIMS: { value: 'CLAIMS', label: 'Claims' },
  RENEWAL: { value: 'RENEWAL', label: 'Renewals' },
  ID_CARDS: { value: 'ID_CARDS', label: 'ID Cards' },
  SSO: { value: 'SSO', label: 'Single Sign-ON (SSO)' },
  RFP: { value: 'RFP', label: 'RFP' },
  SELF_REVIEW: { value: 'SELF_REVIEW', label: 'Self-Review' },
  AI_ASSISTANT: { value: 'AI_ASSISTANT', label: 'AI Assistant' },
};

export const EMPLOYER_VISIBILITY_OFF_MESSAGE =
  'The employer will not be able to view this tool once visibility is turned off, however broker users will still have access. Do you wish to continue?';
export const EMPLOYER_VISIBILITY_ON_MESSAGE =
  'Are you sure you want to turn on visibility for this tool for this employer?';
export const EMPLOYER_VISIBILITY_TOOL_TIP =
  'Employer visibility cannot be turned on for this employer until the tool is enabled';

export const EMP_MEMBER_BILLING_RESTRICED =
  'Only admins may import billing data';

export const EMP_MEMBER_CLAIMS_RESTRICTED =
  'Only admins may import claims data';
export const EMPLOYER_ADMIN_TOOLTIP =
  'Employer Admins have access to edit certain areas within PlanYear when visibility to a tool has been turned on.';

export const EMPLOYER_MEMBER_TOOLTIP =
  'Employer Members primarily have view-only access to tools that have been turned on for them.';

export const EMP_MEMBER_DBG_RESTRICTED =
  'Only admins may import benefit guides';
export const PLAN_REVIEW_CHANGE_MESSAGE = `Our AI has processed your file and generated the output below. We recommend you carefully review all information for accuracy. If the information looks correct, proceed to the next step to continue with your task. If you're unsure or notice discrepancies, you may request a manual review by our team. This may take up to 1 business day, and we'll notify you once it's complete.`;

export const OFFER_NOT_FOUND_MESSAGE =
  'The file you are looking to access has been deleted. Please contact support if you need further assistance.';

export const LLM_PROMPT_TYPE = {
  SBC_READER: 'SBC_READER',
  CARRIER_QUOTES: 'CARRIER_QUOTES',
  BENEFIT_SUMMARY: 'BENEFIT_SUMMARY',
} as const;
