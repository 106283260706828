import axios, { AxiosPromise } from 'axios';

import MedicalPlan from 'model/plans/MedicalPlan';

import {
  baseApi,
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_MULTIPART_FORM,
  getPagingQueryString,
} from 'util/apiUtil';
import { DentalPlan } from 'model/plans/DentalPlan';
import { VisionPlan } from 'model/plans/VisionPlan';
import BenguidePlan from 'model/BenguidePlan';
import ClonePlanDocument from 'model/plans/ClonePlanDocument';
import { LifePlan } from 'model/plans/LifePlan';
import { AiUploaderFileType } from 'modules/plans/types/types';
import { AI_FILE_TYPE, ALL_DOCUMENT_TYPE } from 'modules/plans/constants';
import WebLinkType from 'model/plans/WebLinkType';

const planUrl = baseApi + '/v1/plans';
const benefitGuideUrl = baseApi + '/v2/benefit-guides';
const planUrlV2 = baseApi + '/v2/plans';
const BASE_AI_URL = baseApi + '/v1/document-extraction';

export const getPlanServicesList = async (
  benefitKind: string,
  employerId: string
) => {
  try {
    return await axios.get(
      `${planUrl}/benefit-code?benefit-kind=${benefitKind}&employerId=${employerId}`
    );
  } catch (error) {
    throw error;
  }
};

export const getStatesAndTerritories = async () => {
  try {
    return await axios.get(`${planUrl}/states`);
  } catch (error) {
    throw error;
  }
};

export const getCarriersByBenefitKind = async (
  benefitCategory: string,
  organizationId: string,
  employerId: string
) => {
  try {
    return await axios.get(
      `${planUrlV2}/carriers/${benefitCategory}/benefit-carriers`,
      {
        params: {
          'employer-id': employerId,
          'organization-id': organizationId,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const createMedicalPlan = async (payload: MedicalPlan) => {
  try {
    return await axios.post(`${planUrl}/medicals`, payload);
  } catch (error) {
    throw error;
  }
};

export const validatePlanName = async (
  payload: any,
  benefitCategory: string
) => {
  try {
    return await axios.post(`${planUrl}/${benefitCategory}/validate`, payload);
  } catch (error) {
    throw error;
  }
};

export const updateMedicalPlan = async (payload: MedicalPlan) => {
  try {
    return await axios.put(`${planUrl}/medicals/${payload.id}`, payload);
  } catch (error) {
    throw error;
  }
};

export const checkPlanRecommenderStatus = async (payload: MedicalPlan) => {
  try {
    return await axios.post(
      `${planUrlV2}/medical/${payload.id}/plan-recommender/verify`,
      payload
    );
  } catch (error) {
    throw error;
  }
};

export const planRecommenderToggle = async (
  benguideId: string,
  section: string,
  data: any
) => {
  return axios.put(
    `${benefitGuideUrl}/${benguideId}/section/${section}/enabled`,
    data
  );
};

const CancelToken = axios.CancelToken;
let cancelToken: any = undefined;
/**
 * upload plan documents.
 *
 * @param {string} fileName File name.
 * @param {File} fileBlob File.
 * @return {AxiosPromise<any>}
 */
export const uploadPlanDocuments = (
  fileName: string,
  fileBlob: File
): AxiosPromise<any> => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }
  cancelToken = CancelToken.source();

  const formData = new FormData();
  formData.append('file', fileBlob, fileName);
  const config = {
    headers: {
      [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM,
    },
    cancelToken: cancelToken.token,
  };

  return axios.post(`${planUrlV2}/temp-document`, formData, config);
};

export const getDocumentUploadToken = () => {
  return cancelToken;
};

/**
 * update plan documents in edit mode.
 *
 * @param {string} fileName File name.
 * @param {File} fileBlob File.
 * @param {string} id Plan ID.
 * @param {string} benefitKind Benefitkind.
 * @param {string} documentType Type -SBC/PlanSummary/COC.
 * @return {AxiosPromise<any>}
 */
export const uploadPlanDocumentsEdit = (
  fileName: string,
  fileBlob: File,
  id: string,
  benefitKind: string,
  documentType: string
): AxiosPromise<any> => {
  const formData = new FormData();
  formData.append('file', fileBlob, fileName);
  const config = {
    headers: {
      [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM,
    },
  };

  return axios.post(
    `${planUrl}/${id}/documents/upload?benefitKind=${benefitKind}&documentType=${documentType}`,
    formData,
    config
  );
};

/**
 * update plan documents.
 *
 * @param {string} id Plan ID.
 * @param {string} benefitKind Benefitkind.
 * @param {string} documentType Type -SBC/PlanSummary/COC.
 * @param {string} planDocumentName Plan Document Name.
 */
export const updatePlanDocuments = async (
  id: string,
  benefitKind: string,
  documentType: string,
  planDocumentName: string
) => {
  return axios.put(
    `${planUrl}/${id}/documents?benefitKind=${benefitKind}&documentType=${documentType}&planDocumentName=${planDocumentName}`
  );
};

export const getMedicalPlansList = async (
  page: number,
  size: number,
  sort: string,
  query: string,
  employerId: string,
  planYearId: string
) => {
  return axios.get(
    `${planUrl}/medicals?${getPagingQueryString(page, size, sort, query)}`,
    {
      params: {
        'employer-id': employerId,
        'plan-year-id': planYearId,
      },
    }
  );
};

export const createDentalPlan = (payload: DentalPlan) => {
  return axios.post(`${planUrl}/dentals`, payload);
};
export const updateDentalPlan = async (payload: DentalPlan) => {
  try {
    return await axios.put(`${planUrl}/dentals/${payload.id}`, payload);
  } catch (error) {
    throw error;
  }
};

export const getDentalPlansList = async (
  page: number,
  size: number,
  sort: string,
  query: string,
  employerId: string,
  planYearId: string
) => {
  return axios.get(
    `${planUrl}/dentals?${getPagingQueryString(page, size, sort, query)}`,
    {
      params: {
        'employer-id': employerId,
        'plan-year-id': planYearId,
      },
    }
  );
};

export const getVisionPlansList = async (
  page: number,
  size: number,
  sort: string,
  query: string,
  employerId: string,
  planYearId: string
) => {
  return axios.get(
    `${planUrl}/visions?${getPagingQueryString(page, size, sort, query)}`,
    {
      params: {
        'employer-id': employerId,
        'plan-year-id': planYearId,
      },
    }
  );
};

export const calculateMedicalPlanContributions = (
  frequency: string,
  plan: MedicalPlan,
  benefitGroup: string,
  changedTier: string,
  rateType: string,
  signal: AbortSignal
) => {
  return axios.post(
    `${planUrl}/medicals/contributions/${frequency}?benefit-group=${benefitGroup}&changed-tier=${changedTier}&rate-type=${rateType}`,
    plan,
    { signal: signal }
  );
};

export const calculateDentalPlanContributions = (
  frequency: string,
  plan: MedicalPlan,
  benefitGroup: string,
  changedTier: string,
  rateType: string,
  signal: AbortSignal
) => {
  return axios.post(
    `${planUrl}/dentals/contributions/${frequency}?benefit-group=${benefitGroup}&changed-tier=${changedTier}&rate-type=${rateType}`,
    plan,
    { signal: signal }
  );
};

export const calculateVisionPlanContributions = (
  frequency: string,
  plan: MedicalPlan,
  benefitGroup: string,
  changedTier: string,
  rateType: string,
  signal: AbortSignal
) => {
  return axios.post(
    `${planUrl}/visions/contributions/${frequency}?benefit-group=${benefitGroup}&changed-tier=${changedTier}&rate-type=${rateType}`,
    plan,
    { signal: signal }
  );
};

export const getBenefitGuidesGroupByFrequency = (
  employerId: string,
  planYearId: string,
  benefitKind: string,
  planId: string,
  benefitGuideId?: string,
  revision?: number
) => {
  return axios.get(
    `${benefitGuideUrl}/${employerId}/get-by-frequency?plan-year-id=${planYearId}&benefit-kind=${benefitKind}&plan-id=${planId}`,
    {
      params: {
        revision: revision,
        'updating-guide-id': benefitGuideId,
      },
    }
  );
};
export const checkDBGRates = (
  benefitGuideMasterId: string,
  benefitKind: string,
  revision: number
) => {
  return axios.get(
    `${benefitGuideUrl}/validate-rates?benefitGuideMasterId=${benefitGuideMasterId}&benefitKind=${benefitKind}&revision=${revision}`
  );
};
export const createVisionPlan = (payload: VisionPlan) => {
  return axios.post(`${planUrl}/visions`, payload);
};
export const updateVisionPlan = async (payload: VisionPlan) => {
  return await axios.put(`${planUrl}/visions/${payload.id}`, payload);
};

export const addPlanToBenguide = (
  benguideId: string,
  benguidePlan: BenguidePlan
) => {
  return axios.put(`${benefitGuideUrl}/${benguideId}/plan`, benguidePlan);
};

export const getPlanDocument = async (
  planId: string,
  documentType: string,
  benefitKind: string
) => {
  const docType = ALL_DOCUMENT_TYPE.includes(documentType)
    ? documentType
    : 'PLAN_ADDITIONAL_DOCUMENT';
  return await axios.get(
    `${planUrl}/${planId}/documents?documentType=${docType}&benefitKind=${benefitKind}&isOpen=true&planDocumentName=${documentType}`,
    { responseType: 'blob' }
  );
};

export const getDentalPlanById = (planId: string) => {
  return axios.get(`${planUrl}/dentals/${planId}`);
};

export const getMedicalPlanById = (planId: string) => {
  return axios.get(`${planUrl}/medicals/${planId}`);
};

export const getVisionPlanById = (planId: string) => {
  return axios.get(`${planUrl}/visions/${planId}`);
};

export const getDentalCheckRateInGroups = (
  planId: string,
  revision: number,
  groups: string[]
) => {
  return axios.get(`${planUrl}/dentals/checkrate/${planId}/${revision}`, {
    params: {
      groups: groups.join(','),
    },
  });
};

export const getMedicalCheckRateInGroups = (
  planId: string,
  revision: number,
  groups: string[]
) => {
  return axios.get(`${planUrl}/medicals/checkrate/${planId}/${revision}`, {
    params: {
      groups: groups.join(','),
    },
  });
};

export const getVisionCheckRateInGroups = (
  planId: string,
  revision: number,
  groups: string[]
) => {
  return axios.get(`${planUrl}/visions/checkrate/${planId}/${revision}`, {
    params: {
      groups: groups.join(','),
    },
  });
};

export const getMedicalHsaPlans = (
  employerId: string,
  planYearIds: string[] | string,
  benefitKind: string
) => {
  return axios.get(
    `${planUrl}/medicals/hsa/?employer-id=${employerId}&plan-year-id=${planYearIds}&benefitKind=${benefitKind}`
  );
};

export const getMedicalHraPlans = (
  employerId: string,
  planYearIds: string[] | string
) => {
  return axios.get(
    `${planUrl}/medicals/hra/?employer-id=${employerId}&plan-year-id=${planYearIds}`
  );
};

export const getDentalHraPlans = (
  employerId: string,
  planYearIds: string[] | string
) => {
  return axios.get(
    `${planUrl}/dentals/hra/?employer-id=${employerId}&plan-year-id=${planYearIds}`
  );
};

export const getVisionHraPlans = (
  employerId: string,
  planYearIds: string[] | string
) => {
  return axios.get(
    `${planUrl}/visions/hra/?employer-id=${employerId}&plan-year-id=${planYearIds}`
  );
};

export const getBenefitGuideBasicInfo = (benefitGuideMasterList: any[]) => {
  return axios.post(`${benefitGuideUrl}/basic`, benefitGuideMasterList);
};

export const editWeblinks = (
  id: string,
  benefitKind: string,
  existingDocumentPlanName: string,
  webLink: WebLinkType
) => {
  return axios.put(
    `${planUrl}/${id}/weblink/edit?benefitKind=${benefitKind}&documentPlanName=${encodeURIComponent(
      webLink.planDocumentName
    )}&existingDocumentPlanName=${encodeURIComponent(
      existingDocumentPlanName
    )}&weblink=${encodeURIComponent(webLink.weblink)}`
  );
};

export const updateWeblinks = (
  id: string,
  benefitKind: string,
  webLinks: WebLinkType[]
) => {
  const webLinksMap = webLinks.reduce((acc, item) => {
    acc[item.planDocumentName] = item.weblink;
    return acc;
  }, {} as { [key: string]: string });
  return axios.put(`${planUrl}/${id}/weblink/upload`, {
    webLinks: webLinksMap,
    benefitKind: benefitKind,
  });
};
export const editPlanDocuments = async (
  fileBlob: File,
  fileName: string,
  planId: string,
  benefitKind: string,
  planDocumentName: string,
  existingPlanDocumentName: string
) => {
  const formData = new FormData();
  formData.append('file', fileBlob, fileName);
  const config = {
    headers: {
      [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM,
    },
  };

  return axios.put(
    `${planUrl}/${planId}/documents/upload?benefitKind=${benefitKind}&planDocumentName=${encodeURIComponent(
      planDocumentName
    )}&existingPlanDocumentName=${encodeURIComponent(
      existingPlanDocumentName
    )}`,
    formData,
    config
  );
};
export const editUploadPlanDocuments = async (
  fileBlob: File,
  fileName: string,
  planId: string,
  benefitKind: string,
  docType: string,
  planDocumentName: string
) => {
  const formData = new FormData();
  formData.append('file', fileBlob, fileName);
  const config = {
    headers: {
      [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM,
    },
  };

  return axios.post(
    `${planUrl}/${planId}/documents/upload?benefitKind=${benefitKind}&documentType=${encodeURIComponent(
      docType
    )}&planDocumentName=${encodeURIComponent(planDocumentName)}`,
    formData,
    config
  );
};

export const clonePlanDocuments = (payload: ClonePlanDocument) => {
  return axios.post(`${planUrlV2}/clone-documents`, payload);
};

export const getMDVPlanRates = (
  employerId: string,
  planYearId: string,
  benefitKind: string,
  benefitGroups: string[]
) => {
  // Encode each parameter value before appending to the URL
  const encodedBenefitGroups = benefitGroups.map((group) =>
    encodeURIComponent(group)
  );
  // Construct the URL with properly encoded parameters
  const url =
    `${planUrlV2}/employers/${employerId}/plan-rates-mdv?employer-id=${employerId}` +
    `&plan-year-id=${planYearId}&benefit-kind=${benefitKind}&benefit-groups=${encodedBenefitGroups.join(
      ','
    )}`;
  return axios.get(url);
};

export const getLifeAndDisabilityPlanRates = (
  employerId: string,
  planYearId: string,
  benefitGroups: string[]
) => {
  // Encode each parameter value before appending to the URL
  const encodedBenefitGroups = benefitGroups.map((group) =>
    encodeURIComponent(group)
  );
  // Construct the URL with properly encoded parameters
  const url =
    `${planUrlV2}/employers/${employerId}/plan-rates-life-disability?employer-id=${employerId}` +
    `&plan-year-id=${planYearId}&benefit-groups=${encodedBenefitGroups.join(
      ','
    )}`;
  return axios.get(url);
};

export const deletePlan = (
  employerId: string,
  planMasterId: string,
  benefitKind: string,
  isIgnoreHRAHSADependencies: boolean
) => {
  return axios.delete(
    `${planUrlV2}/employers/${employerId}/plan?planMasterId=${planMasterId}&benefitKind=${benefitKind}` +
      `&isIgnoreHRAHSADependencies=${isIgnoreHRAHSADependencies}`
  );
};

// the ones in focus are MDV, Life, STD, LTD,VOL
export const getPlansForEmployerByPlanYear = (employerId: string) => {
  return axios.get(`${planUrl}/current-plan-year?employer-id=${employerId}`);
};

// OPS Review save endpoint for all benefit types
export const saveOpsFinalizeReview = async (
  payload: MedicalPlan | DentalPlan | VisionPlan | LifePlan,
  jobId: string,
  benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE' = 'MEDICAL',
  fileType: AiUploaderFileType = AI_FILE_TYPE.SBC
) => {
  payload.llmExtractionInfo = null;
  const fileSegment = fileType?.toLowerCase().replaceAll('_', '-');
  const benefitSegment = benefit?.toLowerCase();
  try {
    return await axios.put(
      `${BASE_AI_URL}/${benefitSegment}/${jobId}/${fileSegment}/finalize`,
      payload
    );
  } catch (error) {
    throw error;
  }
};

// redirect to OPS is failed endpoint for all benefit types
export const redirectFailedExtractionToOps = async (
  jobId: string,
  benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE' = 'MEDICAL',
  fileType: AiUploaderFileType = AI_FILE_TYPE.SBC
) => {
  const fileSegment = fileType?.toLowerCase().replaceAll('_', '-');
  const benefitSegment = benefit?.toLowerCase();
  try {
    return await axios.put(
      `${BASE_AI_URL}/${benefitSegment}/${jobId}/${fileSegment}/redirect-to-ops`
    );
  } catch (error) {
    throw error;
  }
};

// Reject the uploaded sbc file by OPS
export const rejectSelectedFile = async (jobId: string, reason: string) => {
  try {
    return await axios.post(`${BASE_AI_URL}/${jobId}/reject`, { reason });
  } catch (error) {
    throw error;
  }
};
