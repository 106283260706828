import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useAppSelector } from 'hooks/redux';
import PlanBasicInfoForm from 'modules/plans/components/DVPlanBasicInfoForm/PlanBasicInfoForm';

type VisionBasicPlanInfoWrapperProps = {
  onChange: Function;
  isCloseConfirmed: boolean;
  error: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  dbgPlanYear?: string;
  planAlreadyExists?: boolean;
  setPlanAlreadyExists?: Function;
  setAlertMessage?: Function;
};

const VisionBasicPlanInfoWrapper = forwardRef(
  (props: VisionBasicPlanInfoWrapperProps, ref) => {
    const {
      onChange,
      isCloseConfirmed,
      error,
      dbgPlanYear,
      planAlreadyExists,
      setPlanAlreadyExists,
      setAlertMessage,
    } = props;
    const editedVisionPlan = useAppSelector(
      (state) => state.plan.visionPlan.editedVisionPlan
    );
    const { visionPlan } = useAppSelector((state) => state.plan.visionPlan);
    const formRef = useRef<any>(null);

    useImperativeHandle(ref, () => ({
      validate: async () => ({
        isValid: await formRef.current.validate(),
        isComplete: formRef.current.getIsComplete(),
      }),
    }));

    return (
      <PlanBasicInfoForm
        isSingleColumn
        isUPEdit
        ref={formRef}
        onChange={onChange}
        plan={visionPlan}
        isCloseConfirmed={isCloseConfirmed}
        error={error}
        dbgPlanYear={dbgPlanYear}
        benefitCategory="VISION"
        hasSameContributions={editedVisionPlan?.hasSameContributions}
        planAlreadyExists={planAlreadyExists}
        setPlanAlreadyExists={setPlanAlreadyExists}
        resetAlertMessage={setAlertMessage}
      />
    );
  }
);

VisionBasicPlanInfoWrapper.displayName = 'VisionBasicPlanInfoWrapper';

export default VisionBasicPlanInfoWrapper;
