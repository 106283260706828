import { FC } from 'react';
import AddWellbeingPlan from 'modules/plans/wellbeing/components/AddWellbeingPlan/AddWellbeingPlan';

type EditWellbeingPlanProps = {
  onClose: () => {};
  plan: any;
  isSaveDisabled?: boolean;
  isDBGPlan?: boolean;
  isModalVisible?: boolean;
};

const EditWellbeingPlan: FC<EditWellbeingPlanProps> = ({
  onClose,
  plan,
  isSaveDisabled,
  isDBGPlan,
  isModalVisible,
}: EditWellbeingPlanProps) => {
  return (
    <div>
      <AddWellbeingPlan
        closePlanAddModal={onClose}
        isCloseConfirmed={true}
        onClose={onClose}
        isPlanUpdateMode={true}
        plan={plan}
        isSaveDisabled={isSaveDisabled}
        isDBGPlan={isDBGPlan}
        isModalVisible={isModalVisible}
      />
    </div>
  );
};

export default EditWellbeingPlan;
