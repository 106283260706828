import { Layout } from 'antd';
import { StompSessionProvider } from 'react-stomp-hooks';
import { WEB_SOCKET_URL } from 'modules/assistant/services/ConversationService';
import AssistantPage from 'modules/assistant/pages/AssistantPage';
import styles from './assistantLayout.module.less';

const { Content } = Layout;

const AssistantLayout = () => {
  return (
    <StompSessionProvider
      url={WEB_SOCKET_URL}
      onWebSocketError={(e) => console.error(e)}
      reconnectDelay={500}
    >
      <Layout>
        <Content className={styles.container}>
          <AssistantPage />
        </Content>
      </Layout>{' '}
    </StompSessionProvider>
  );
};

export default AssistantLayout;
