import { lumityApi } from 'api/apiSlice';
import { CarrierContactCreateTypes } from 'model/CarrierContact';

const baseUrl = `v2/plans/carrier`;

const carrierContactsApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getCarrierContactsByBrokerId: builder.query<
      any,
      { brokerId: string; carrierContactId: string; isEditMode: boolean }
    >({
      query: (args) => {
        const { brokerId, carrierContactId, isEditMode = false } = args;
        return {
          url: `${baseUrl}/carrier-contact/${carrierContactId}`,
          method: 'GET',
          params: {
            'organization-id': brokerId,
            'is-edit-mode': isEditMode,
          },
        };
      },
    }),

    getCarrierContactsListByBrokerId: builder.query<
      any,
      {
        brokerId: string;
        page: number;
        size: number;
        sort: string;
        searchKey: string;
      }
    >({
      query: (args) => {
        const { brokerId, page, size, sort, searchKey } = args;
        return {
          url: `${baseUrl}/carrier-contact`,
          method: 'GET',
          params: {
            'organization-id': brokerId,
            _page: page,
            _size: size,
            _sort: sort,
            'search-key': searchKey,
          },
        };
      },
    }),

    deleteCarrierContactByCarrierContactId: builder.mutation<
      any,
      { carrierContactId: string }
    >({
      query: ({ carrierContactId }) => {
        return {
          url: `${baseUrl}/carrier-contact/${carrierContactId}`,
          method: 'DELETE',
        };
      },
    }),

    getEmployersByBrokerId: builder.query<
      any,
      { brokerId: string; individualId: string | null }
    >({
      query: (args) => {
        const { brokerId, individualId } = args;
        return {
          url: `v2/employers/list`,
          method: 'GET',
          params: {
            'organization-id': brokerId,
            'individual-id': individualId,
          },
        };
      },
    }),
    getCarriersByBrokerId: builder.query<
      any,
      { organizationId: string | null }
    >({
      query: ({ organizationId }) => {
        return {
          url: `v2/plans/carriers/carrier-by-orgId`,
          method: 'GET',
          params: {
            'organization-id': organizationId,
          },
        };
      },
    }),
    createCarrierContact: builder.mutation<any, CarrierContactCreateTypes>({
      query: (payload) => {
        return {
          url: `${baseUrl}/carrier-contact`,
          method: 'POST',
          data: payload,
        };
      },
    }),
    updateCarrierContact: builder.mutation<any, CarrierContactCreateTypes>({
      query: (payload) => {
        return {
          url: `${baseUrl}/carrier-contact/${payload.id}`,
          method: 'PUT',
          data: payload,
          params: {
            'organization-id': payload.organizationId,
          },
        };
      },
    }),
  }),
});

export const {
  useLazyGetCarrierContactsByBrokerIdQuery,
  useLazyGetCarrierContactsListByBrokerIdQuery,
  useDeleteCarrierContactByCarrierContactIdMutation,
  useLazyGetEmployersByBrokerIdQuery,
  useLazyGetCarriersByBrokerIdQuery,
  useCreateCarrierContactMutation,
  useUpdateCarrierContactMutation,
} = carrierContactsApi;
