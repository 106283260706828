import styles from './IssueStatusBadge.module.less';

type Props = {
  issueStatus: string;
};

const IssueStatus: any = {
  NEW: { value: 'NEW', label: 'New', color: 'blue', default: true },
  IN_PROGRESS: {
    value: 'IN_PROGRESS',
    label: 'In Progress',
    color: 'yellow',
    default: true,
  },
  RESOLVED: { value: 'RESOLVED', label: 'Resolved', color: 'green' },
  BLOCKED: {
    value: 'BLOCKED',
    label: 'Blocked',
    color: 'red',
    default: true,
  },
  RE_OPENED: {
    value: 'RE_OPENED',
    label: 'RE-Opened',
    color: 'lightBlue',
  },
  UNASSIGNED: {
    value: 'UNASSIGNED',
    label: 'Unassigned',
    color: 'lightBlue',
  },
  ASSIGNED: {
    value: 'ASSIGNED',
    label: 'assigned',
    color: 'green',
  },
  RESERVED: {
    value: 'RESERVED',
    label: 'reserved',
    color: 'orange',
  },
};

const IssueStatusBadge = (props: Props) => {
  const { issueStatus } = props;
  const issueStatusConfig = IssueStatus[issueStatus];

  if (!issueStatusConfig) {
    return <>-</>;
  }

  const { color, label } = issueStatusConfig;

  return (
    <div className={styles.issueStatusBadge}>
      <span className={color}>{label.toUpperCase()}</span>
    </div>
  );
};

export default IssueStatusBadge;
