import { FC, ReactNode } from 'react';

import styles from './authContainer.module.less';

type Props = {
  children: ReactNode;
};

const AuthContainer: FC<Props> = (props) => {
  const { children } = props;
  return <div className={styles.container}>{children}</div>;
};

export default AuthContainer;
