import axios from 'axios';
import { baseApi } from 'util/apiUtil';

const ENDPOINT = `${baseApi}/v1/text-extractor`;

const CancelToken = axios.CancelToken;

let cancelToken: any = undefined;
export const startTextractJob = (file: FormData) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }
  cancelToken = CancelToken.source();
  return axios.post(`${ENDPOINT}/start`, file, {
    cancelToken: cancelToken.token,
  });
};

export const getCurrentJobToken = () => {
  return cancelToken;
};

export const getTextractJobStatus = (jobId: string) => {
  return axios.get(`${ENDPOINT}/${jobId}/status`);
};

export const getPdfById = (jobId: string) => {
  return axios.get(`${ENDPOINT}/${jobId}/pdf`);
};

export const getPDFViewURL = (jobId: string) => {
  return `${ENDPOINT}/${jobId}/pdf`;
};

export const getExtractedPlanDetails = (jobId: string) => {
  return axios.get(`${ENDPOINT}/${jobId}/sbc/plan`);
};
