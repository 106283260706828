import visionIcon from 'assets/images/benefitCategories/icon-vision.svg';
import visionDisableIcon from 'assets/images/benefitCategoriesDisabled/icon-vision.svg';
import dentalIcon from 'assets/images/benefitCategories/icon-dental.svg';
import dentalDisableIcon from 'assets/images/benefitCategoriesDisabled/icon-dental.svg';
import medicalIcon from 'assets/images/benefitCategories/icon-medical.svg';
import medicalDisableIcon from 'assets/images/benefitCategoriesDisabled/icon-medical.svg';
import lifeIcon from 'assets/images/benefitCategories/icon-life-and-disability.svg';
import lifeDisableIcon from 'assets/images/benefitCategoriesDisabled/icon-life-and-disability.svg';
import voluntaryIcon from 'assets/images/benefitCategories/icon-voluntary-benefits.svg';
import voluntaryDisableIcon from 'assets/images/benefitCategoriesDisabled/icon-voluntary-benefits.svg';
import telehealthIcon from 'assets/images/benefitCategories/icon-telemedicine.svg';
import telehealthDisableIcon from 'assets/images/benefitCategoriesDisabled/icon-telemedicine.svg';
import wellBeingIcon from 'assets/images/benefitCategories/icon-wellbeing.svg';
import wellBeingDisableIcon from 'assets/images/benefitCategoriesDisabled/icon-wellbeing.svg';
import fourOOneKIcon from 'assets/images/benefitCategories/icon-retirement.svg';
import fourOOneKDisableIcon from 'assets/images/benefitCategoriesDisabled/icon-retirement.svg';
import taxAdvantagedAccountsIcon from 'assets/images/benefitCategories/icon-tax-adv.svg';
import taxAdvantagedAccountsDisableIcon from 'assets/images/benefitCategoriesDisabled/icon-tax-adv.svg';
import holidaysAndTimeOffIcon from 'assets/images/benefitCategories/icon-time-off.svg';
import holidaysAndTimeOffDisableIcon from 'assets/images/benefitCategoriesDisabled/icon-time-off.svg';
import familyAndLeaveIcon from 'assets/images/benefitCategories/icon-family.svg';
import familyAndLeaveDisableIcon from 'assets/images/benefitCategoriesDisabled/icon-family.svg';
import additionalPerksIcon from 'assets/images/benefitCategories/icon-additional-perks.svg';
import additionalPerksDisableIcon from 'assets/images/benefitCategoriesDisabled/icon-additional-perks.svg';

export const getBenefitNameAndIcon = (benefitName: string) => {
  const benefitMap: {
    [benefitName: string]: {
      benefitName: string;
      icon: string;
      disableIcon: string;
    };
  } = {
    medical: {
      benefitName: 'Medical',
      icon: medicalIcon,
      disableIcon: medicalDisableIcon,
    },
    dental: {
      benefitName: 'Dental',
      icon: dentalIcon,
      disableIcon: dentalDisableIcon,
    },
    vision: {
      benefitName: 'Vision',
      icon: visionIcon,
      disableIcon: visionDisableIcon,
    },
    telehealth: {
      benefitName: 'Telehealth/Rx Delivery',
      icon: telehealthIcon,
      disableIcon: telehealthDisableIcon,
    },
    voluntaryBenefit: {
      benefitName: 'Voluntary Benefit',
      icon: voluntaryIcon,
      disableIcon: voluntaryDisableIcon,
    },
    lifeAndDisability: {
      benefitName: 'Life and Disability',
      icon: lifeIcon,
      disableIcon: lifeDisableIcon,
    },
    wellBeing: {
      benefitName: 'Wellbeing',
      icon: wellBeingIcon,
      disableIcon: wellBeingDisableIcon,
    },
    fourOOneK: {
      benefitName: '401(k)',
      icon: fourOOneKIcon,
      disableIcon: fourOOneKDisableIcon,
    },
    taxAdvantagedAccounts: {
      benefitName: 'Tax Advantaged Accounts',
      icon: taxAdvantagedAccountsIcon,
      disableIcon: taxAdvantagedAccountsDisableIcon,
    },
    holidaysAndTimeOff: {
      benefitName: 'Holidays and Time Off',
      icon: holidaysAndTimeOffIcon,
      disableIcon: holidaysAndTimeOffDisableIcon,
    },
    familyAndLeave: {
      benefitName: 'Family and Leave',
      icon: familyAndLeaveIcon,
      disableIcon: familyAndLeaveDisableIcon,
    },
    additionalPerks: {
      benefitName: 'Additional Perks',
      icon: additionalPerksIcon,
      disableIcon: additionalPerksDisableIcon,
    },
  };
  return (
    benefitMap[benefitName] || { benefitName: '', icon: '', disableIcon: '' }
  );
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // Remove non-digit characters
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Extract area code, exchange, and subscriber number

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`; // Format as (xxx) xxx-xxxx
  }

  return phoneNumber;
};

export const mapToSelectedCarrierContact = (carrierVo: any) => {
  return {
    phoneNumber: carrierVo.phone,
    name: carrierVo.fullName,
    insuranceCarrierName: carrierVo.associatedCarrierName,
    title: carrierVo.title,
    email: carrierVo.email,
    id: carrierVo.id,
  };
};
