import uniqWith from 'lodash/uniqWith';
import isEmpty from 'lodash/isEmpty';

export const removeDuplicates = (arr: any) => {
  return arr.reduce((acc: any, curr: any) => {
    if (!acc.includes(curr)) acc.push(curr);
    return acc;
  }, []);
};
export const removeDuplicateEmails = (arr: any) => {
  if (isEmpty(arr)) {
    return [];
  }
  return (
    uniqWith(arr, (a: string, b: string) => {
      return a?.toLowerCase() === b?.toLowerCase();
    }) || []
  );
};

export const removeSpecificItemFromStringArray = (
  arr: string[],
  value: string
): string[] => {
  const copyArray = [...arr];
  const removingIndex = copyArray.indexOf(value);
  if (removingIndex > -1) {
    copyArray?.splice(removingIndex, 1);
  }
  return copyArray;
};

export const getFilteredOptionArray = (
  options: any[],
  filterKey: string,
  pattern: string
) => {
  const firstSecondFiltered = options?.filter((item) => {
    const [first, second]: string[] = item[filterKey].split(' ');
    if (
      first.charAt(0).toLowerCase() === pattern.charAt(0).toLowerCase() ||
      second.charAt(0).toLowerCase() === pattern.charAt(0).toLowerCase()
    ) {
      return item;
    }
  });
  const optionsArray = firstSecondFiltered?.filter((item) =>
    item[filterKey]?.toLowerCase()?.includes(pattern?.toLowerCase().trim())
  );
  return optionsArray;
};

export const getSortOptionsCaseInsensitive = (
  options: any[],
  sortKey: string
) => {
  return options.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
};
