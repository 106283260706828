import { FC } from 'react';
import { CheckOutlined } from '@ant-design/icons';

import styles from './outlinedText.module.less';

interface Props {
  text: string;
  type?: 'primary' | 'disabled';
  showCheck?: boolean;
}

const OutlinedText: FC<Props> = (props) => {
  const { text, type = 'primary', showCheck = true } = props;
  const colors: any = {
    primary: styles.outLinedTextWrapper,
    disabled: styles.outLinedTextWrapperDisabled,
  };
  return (
    <div className={colors[type]}>
      {showCheck && <CheckOutlined className={styles.check} />}
      {text}
    </div>
  );
};

export default OutlinedText;
