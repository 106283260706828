import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Image, Menu, Popover, Row, Skeleton } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { debounce, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import DataTable from 'components/DataTable/DataTable';
import Filter from 'components/Filter/Filter';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import SearchBar from 'components/SearchBar/SearchBar';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import EmptyContent from 'components/EmptyContent/EmptyContent';

import Employer from 'model/Employer';
import EmployerData from 'model/EmployerData';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import { DEBOUNCE_WAIT_TIME_SEARCH } from 'util/commonUtil';
import {
  getEmployers,
  getTeamMembers,
  getBrokerEmployers,
  getEmployerCount,
  getEmployer,
} from 'modules/employers/slices/employerSlice';
import { clearEmployer } from 'layout/slices/layoutSlice';
import { formatLocalized } from 'util/dateUtil';
import { buildFullName } from 'util/stringUtil';
import TablePagination from 'model/TablePagination';
import TeamMember from 'model/TeamMember';
import AddEmployerModal from 'modules/employers/components/AddEmployerModal/AddEmployerModal';
import PaginationConfig from 'model/PaginationConfig';

import {
  getBrokerList,
  processFindBroker,
} from 'modules/employers/slices/employerCreateStepperSlice';
import { getBrokerLocationList } from 'modules/brokers/slices/brokerLocationSlice';
import { BASIC_INFO_TAB } from 'modules/employers/constants/employerConstants';
import {
  ALL_FEATURE_KEYS,
  allOption,
  EMP_LIMIT_REACH_POPOVER_MSG,
  IndividualSubTypes,
} from 'constants/commonConstants';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import EditBrokerTeamModal from 'modules/employers/components/EditBrokerTeamModal/EditBrokerTeamModal';
import { getBrokerSummary } from 'modules/brokers/slices/brokerDashboardSlice';
import { useGetDashboardRenewalQuery } from 'modules/employers/slices/employerRenewalSlice';
import { useArchiveEmployerMutation } from 'modules/employers/slices/employerRTKSlice';
import styles from './employerList.module.less';

const ALL_LOCATIONS = 'All';

const EmployerList: FC = () => {
  const { state: stateValue } = useLocation();
  const {
    loggedInUserBroker,
    locationId,
    userId,
    featureTools,
    search = '',
  } = stateValue || ({} as any);
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: search,
    },
    filters: {},
  });
  const [searchText, setSearchText] = useState<string>(search);
  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isTeamModalOpen, setIsTeamModalOpen] = useState<boolean>(false);
  const [selectedEmployer, setSelectedEmployer] = useState<string>('');
  const [selectedRenewalDates, setSelectedRenewalDates] = useState<string>('');
  const [selectedFeatureTools, setSelectedFeatureTools] = useState<string>('');
  const [selectedBrokers, setSelectedBrokers] = useState<string>('');
  const [selectedMembers, setSelectedMembers] = useState<string>('');
  const [selectedLocations, setSelectedLocations] = useState<string>('');
  const [isOptionsCleared, setIsOptionsCleared] = useState<boolean>(false);
  const [isHideEmployerModal, setIsHideEmployerModal] = useState<{
    show: boolean;
    employerId: string;
    employerName: string;
  }>({ show: false, employerId: '', employerName: '' });

  const { setEmployerId } = useNavContext();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const employers = useAppSelector(
    (state) => state.employer.employer.employers
  );
  const inProgress = useAppSelector(
    (state) => state.employer.employer.inProgress
  );
  const employerObj = useAppSelector(
    (state) => state.employer.employer.employerObj
  );
  const brokers = useAppSelector((state) => state.employer.employer.brokerList);
  const layoutBrokerDetails = useAppSelector((state) => state.layout.broker);
  const brokerLocations = useAppSelector(
    (state) => state.brokers.brokerLocations.brokerLocations
  );
  const teamMembers = useAppSelector(
    (state) => state.employer.employer.teamMembers
  );
  const brokerDetails = useAppSelector(
    (state) => state.brokers.brokerDashboard.brokerObj
  );

  const { brokerId } = useNavContext();

  const employerLimitReached = brokerDetails?.employerLimitReached && brokerId;

  const employerCount = useAppSelector(
    (state) => state.employer.employer.employerCount
  );

  const { content, metadata } = employers || {};
  const total = metadata ? metadata.total : '0';
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  const [archiveEmployer, { isLoading: isArchiveEmployerLoading }] =
    useArchiveEmployerMutation();

  useEffect(() => {
    if (brokerId) {
      dispatch(getBrokerSummary(brokerId));
    }
  }, [dispatch, brokerId]);

  const filterData = useCallback(
    (dashBoardFilter: string, filterList: string) => {
      const filterArray = Array.from(filterList.split(','));
      if (dashBoardFilter) {
        if (isEmpty(filterList)) {
          if (isOptionsCleared) {
            return false;
          } else {
            return true;
          }
        } else if (
          (filterArray.length === 1 &&
            !filterArray.includes(dashBoardFilter)) ||
          filterArray.length > 1
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    [isOptionsCleared]
  );

  const params = useParams();
  const { data: renewalDates } = useGetDashboardRenewalQuery({
    organizationId: params.brokerId,
  });

  const location: any = useLocation();
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);

  const isCameFromRenewalDashboard: boolean =
    location.state?.isCameFromRenewalDashboard || false;

  const handleFilterSelect = (): any => {
    if (isCameFromRenewalDashboard) {
      return appBootInfo?.individualId;
    } else return [];
  };

  const fetchEmployers = useCallback(() => {
    if (brokerId) {
      dispatch(getEmployerCount(brokerId));
      dispatch(processFindBroker(brokerId));
      dispatch(
        getBrokerEmployers(
          convertToPaginationConfig(paginationConfig),
          loggedInUserBroker ? loggedInUserBroker : brokerId,
          filterData(locationId, selectedLocations)
            ? locationId
            : selectedLocations,
          filterData(
            isCameFromRenewalDashboard ? appBootInfo?.individualId : userId,
            selectedMembers
          )
            ? isCameFromRenewalDashboard
              ? appBootInfo?.individualId
              : userId
            : selectedMembers,
          filterData(renewalDates?.renewalDates, selectedRenewalDates)
            ? ''
            : selectedRenewalDates.toString(),
          filterData(featureTools, selectedFeatureTools)
            ? featureTools
            : selectedFeatureTools
        )
      );
    } else {
      dispatch(getEmployerCount(''));
      dispatch(
        getEmployers(
          convertToPaginationConfig(paginationConfig),
          filterData(loggedInUserBroker, selectedBrokers)
            ? loggedInUserBroker
            : selectedBrokers,
          filterData(loggedInUserBroker, selectedBrokers)
            ? locationId
            : selectedLocations,
          userId ? userId : selectedMembers,
          filterData(renewalDates?.renewalDates, selectedRenewalDates)
            ? ''
            : selectedRenewalDates.toString(),
          filterData(featureTools, selectedFeatureTools)
            ? featureTools
            : selectedFeatureTools,
          'false'
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    brokerId,
    paginationConfig,
    isModalOpen,
    selectedBrokers,
    selectedLocations,
    selectedMembers,
    renewalDates,
    selectedRenewalDates,
    loggedInUserBroker,
    featureTools,
    locationId,
    userId,
    isTeamModalOpen,
    filterData,
    isCameFromRenewalDashboard,
    appBootInfo?.individualId,
  ]);

  useEffect(() => {
    if (!isModalOpen && !isTeamModalOpen) {
      fetchEmployers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    brokerId,
    paginationConfig,
    isModalOpen,
    selectedBrokers,
    selectedLocations,
    selectedMembers,
    renewalDates,
    selectedRenewalDates,
    loggedInUserBroker,
    featureTools,
    locationId,
    userId,
    isTeamModalOpen,
    filterData,
    isCameFromRenewalDashboard,
    appBootInfo?.individualId,
  ]);

  useEffect(() => {
    if (!brokerId) {
      dispatch(getBrokerList());
    } else {
      dispatch(getBrokerLocationList(brokerId));
      dispatch(getTeamMembers(brokerId, null));
    }
  }, [dispatch, brokerId]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    const config = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText },
    };
    debounceLoadData(config);
  };

  const getData = (dataFilters: TablePagination) => {
    const { sorterInfo, paginationIndex, filterInfo, filters } = dataFilters;
    if (sorterInfo) {
      const paginationConfigData = {
        sorterInfo,
        paginationIndex,
        filterInfo: {
          limit: filterInfo.limit,
          offset: filterInfo.offset,
          searchText,
        },
        filters,
      };
      setPaginationConfig(paginationConfigData);
    } else {
      const paginationConfigData = {
        sorterInfo: {
          columnKey: 'name',
          field: 'name',
          order: 'ascend',
        },
        paginationIndex,
        filterInfo: {
          limit: filterInfo.limit,
          offset: filterInfo.offset,
          searchText,
        },
        filters,
      } as TablePagination;
      setPaginationConfig(paginationConfigData);
    }
  };

  const goToEmployer = (_employerId: string, _brokerId: string) => {
    dispatch(clearEmployer());
    navigate(`/brokers/${_brokerId}/employers/${_employerId}`, {
      state: {
        filters: {
          locations: selectedLocations,
          teamMembers: selectedMembers,
          brokers: selectedBrokers,
          search: searchText,
        },
      },
    });
  };

  const goToEmployerAdmins = (employerId: string, brokerId: string) => {
    setEmployerId(employerId, brokerId);
    navigate(`/brokers/${brokerId}/employers/${employerId}/info`);
  };

  const goToEmployerInfo = (employerId: string, brokerId: string) => {
    setEmployerId(employerId, brokerId);
    navigate(`/brokers/${brokerId}/employers/${employerId}/info`, {
      state: { tab: BASIC_INFO_TAB },
    });
  };

  const filterBrokerOptionsList = () => {
    const allOptions = { label: 'All Brokers', value: allOption };
    const options =
      brokers
        ?.map((item) => ({
          label: item.name,
          value: item.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [];
    return [allOptions].concat(options);
  };

  const filterLocationOptionsList = () => {
    const allOptions = { label: 'All Locations', value: allOption };
    const options =
      brokerLocations.employerLocations
        ?.map((item) => ({
          label: item.locationName,
          value: item.locationId,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [];
    return [allOptions].concat(options);
  };

  const filterTeamMemberOptionsList = () => {
    const allOptions = { label: 'All Team', value: allOption };
    const options =
      teamMembers
        ?.map((item) => ({
          label: buildFullName(item.firstName, item.lastName),
          value: item.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [];
    return [allOptions].concat(options);
  };

  const filterRenewalDates = () => {
    const allOptions = { label: 'All Renewal Dates', value: allOption };

    const options =
      renewalDates?.renewalDates
        ?.map((item: any) => ({
          label: dayjs(item).format('MMM D, YYYY'),
          value: item,
        }))
        .sort(
          (a: { value: number }, b: { value: number }) => a.value - b.value
        ) || [];
    return [allOptions].concat(options);
  };

  const filterToolsEnabled = () => {
    const allOptions = { label: 'All Tools Enabled', value: allOption };

    const options =
      Object.values(ALL_FEATURE_KEYS).map((feature) => ({
        label: feature.label,
        value: feature.value,
      })) || [];

    return [allOptions].concat(options);
  };

  const onSelectRenewalDates = (selectedItems: string[]) => {
    const filters = onSelectItems(selectedItems);

    setSelectedRenewalDates(filters);

    if (isEmpty(selectedItems)) {
      setIsOptionsCleared(true);
    }
  };

  const onSelectTools = (selectedItems: string[]) => {
    const filters = onSelectItems(selectedItems);

    setSelectedFeatureTools(filters);

    if (isEmpty(selectedItems)) {
      setIsOptionsCleared(true);
    }
  };

  const onSelectItems = (selectedItems: string[]) => {
    const filters = selectedItems.includes(allOption)
      ? ''
      : selectedItems.toString();
    setPaginationConfig({ ...paginationConfig, paginationIndex: 1 });
    return filters;
  };

  const onSelectBrokers = (selectedItems: string[]) => {
    const filters = onSelectItems(selectedItems);
    setSelectedBrokers(filters);
    if (isEmpty(selectedItems)) {
      setIsOptionsCleared(true);
    }
  };

  const onSelectTeamMembers = (selectedItems: string[]) => {
    const filters = onSelectItems(selectedItems);
    setSelectedMembers(filters);
    if (isEmpty(selectedItems)) {
      setIsOptionsCleared(true);
    }
  };

  const onSelectLocations = (selectedItems: string[]) => {
    const filters = onSelectItems(selectedItems);
    setSelectedLocations(filters);
    if (isEmpty(selectedItems)) {
      setIsOptionsCleared(true);
    }
  };

  const showTeamMemberNames = (teamMembers: TeamMember[]): string => {
    return teamMembers
      .map((teamMember) => {
        const { firstName, lastName } = teamMember;
        return buildFullName(firstName, lastName);
      })
      .sort()
      .join(', ');
  };

  const convertEmployersForTableRender = (
    empList: Employer[]
  ): EmployerData[] => {
    return (
      empList?.map((employer) => ({
        key: employer.id,
        employerName: employer.name,
        managingBroker: employer.organizationName,
        name: employer.logoUrl,
        renewalDate: employer.renewalDate,
        locations:
          employer.locations && !isEmpty(employer.locations)
            ? employer.locations.join(', ')
            : ALL_LOCATIONS,
        lastUpdated: formatLocalized(employer.lastUpdated),
        organizationId: employer.organizationId,
        teamMembers: showTeamMemberNames(
          employer.teamMembers ? employer.teamMembers : []
        ),
        actions: employer,
      })) || []
    );
  };

  const convertToPaginationConfig = (
    paginationConfig: TablePagination
  ): PaginationConfig => {
    const { paginationIndex, filterInfo, sorterInfo } = paginationConfig;
    const { limit, searchText } = filterInfo || {};
    const { columnKey, order } = (sorterInfo as any) || {};
    return {
      page: paginationIndex,
      size: limit,
      sort: sorterInfo
        ? order === 'descend'
          ? `-${columnKey}`
          : columnKey
        : '',
      query: searchText ? searchText.trim() : searchText,
    };
  };

  const logoSkelton = (
    <div>
      <Skeleton.Input className={styles.logoImg} />
    </div>
  );

  const employerTableColumns = () => {
    const columns = [
      {
        title: 'EMPLOYER',
        dataIndex: 'name',
        key: 'name',
        width: '10%',
        sorter: (a: any, b: any) =>
          a.employerName.localeCompare(b.employerName),
        defaultSortOrder: 'ascend',
        render: (logoUrl: string, record: EmployerData) => {
          const { key, organizationId, employerName } = record;
          return (
            <div className={styles.logoImg}>
              <a
                onClick={() => {
                  return goToEmployer(key, organizationId);
                }}
              >
                <Image
                  src={logoUrl}
                  preview={false}
                  alt={employerName}
                  className={styles.logoImg}
                  placeholder={logoSkelton}
                />
              </a>
            </div>
          );
        },
      },
      {
        title: '',
        dataIndex: 'employerName',
        key: 'employerName',
        width: '20%',
        ellipsis: {
          showTitle: false,
        },
        render: (text: string, record: EmployerData) => (
          <a
            onClick={() => {
              return goToEmployer(record.key, record.organizationId);
            }}
          >
            <OverflowPopover>
              <span className="text table-item-link">{text}</span>
            </OverflowPopover>
          </a>
        ),
      },
      {
        title: 'MANAGING BROKER',
        dataIndex: 'managingBroker',
        key: 'broker',
        width: '30%',
        ellipsis: {
          showTitle: false,
        },
        render: (broker: any) => (
          <OverflowPopover popoverContent={broker}>{broker}</OverflowPopover>
        ),
      },
      {
        title: 'RENEWAL DATE',
        dataIndex: 'renewalDate',
        key: 'renewalDate',
        width: '20%',
        ellipses: {
          showTitle: false,
        },
        sorter: (a: any, b: any) =>
          a?.renewalDates?.localeCompare(b?.renewalDates),

        render: (renewalDates: any, records: any) => {
          return (
            <OverflowPopover popoverContent={renewalDates}>
              {dayjs(renewalDates).format('MMM D, YYYY')}
            </OverflowPopover>
          );
        },
      },
      {
        title: 'LOCATIONS WITH ACCESS',
        dataIndex: 'locations',
        key: 'locations',
        width: '30%',
        ellipsis: {
          showTitle: false,
        },
        render: (locations: any) => (
          <OverflowPopover popoverContent={locations}>
            {locations}
          </OverflowPopover>
        ),
      },
      {
        title: 'TEAM',
        dataIndex: 'teamMembers',
        key: 'teamMembers',
        width: '30%',
        ellipsis: {
          showTitle: false,
        },
        render: (teamMembers: any) => (
          <OverflowPopover popoverContent={teamMembers}>
            {teamMembers}
          </OverflowPopover>
        ),
      },
      {
        title: 'ACTIONS',
        dataIndex: 'actions',
        key: 'actions',
        width: '15%',
        render: (colData: Employer) => {
          return (
            <div className={styles.dropdownWrapper}>
              <SelectDropdown
                overlay={employerActions(colData)}
                className="text-select-action"
              />
            </div>
          );
        },
        align: 'center',
      },
    ];
    return columns.filter((item) =>
      brokerId ? item.key !== 'broker' : item.key !== 'teamMembers'
    );
  };

  const hadleEditBrokerTeam = (employerId: string) => {
    setSelectedEmployer(employerId);
    setIsTeamModalOpen(true);
    getEmployer(employerId);
  };

  const handleConfirmHideEmployer = async () => {
    await archiveEmployer({ employerId: isHideEmployerModal.employerId });
    setIsHideEmployerModal({
      show: false,
      employerId: '',
      employerName: '',
    });
    fetchEmployers();
  };
  // TODO: Add archive employer and edit broker team options
  const employerActions = (data: Employer) => {
    const { id, organizationId, name } = data;
    return (
      <Menu>
        <Menu.Item
          key="viewDetails"
          onClick={() => goToEmployer(id, organizationId)}
        >
          Employer Dashboard
        </Menu.Item>

        <Menu.Item key="editBrokerTeam" onClick={() => hadleEditBrokerTeam(id)}>
          Edit Broker Team
        </Menu.Item>

        <Menu.Item
          key="editAdmins"
          onClick={() => goToEmployerAdmins(id, organizationId)}
        >
          Edit Employer Users
        </Menu.Item>
        <Menu.Item
          key="editInfo"
          onClick={() => goToEmployerInfo(id, organizationId)}
        >
          Edit Employer Info and Access
        </Menu.Item>
        {appBootInfo?.individualSubType !== IndividualSubTypes.BROKER_USER && (
          <Menu.Item
            key="hideEmployer"
            onClick={() =>
              setIsHideEmployerModal({
                show: true,
                employerId: id,
                employerName: name,
              })
            }
          >
            Hide Employer
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <>
      {!inProgress &&
        content &&
        content.length === 0 &&
        !searchText &&
        employerCount?.count === 0 && (
          <EmptyContent
            setIsModalOpen={setIsModalOpen}
            header="All Employers"
            title="You have no Employers set up!"
            content={
              appBootInfo?.individualSubType !== IndividualSubTypes.BROKER_USER
                ? 'Get started by adding your first Employer'
                : ''
            }
            buttonText={
              appBootInfo?.individualSubType !== IndividualSubTypes.BROKER_USER
                ? '+ Add Employer'
                : ''
            }
          />
        )}
      {employerCount?.count !== 0 && (
        <div className={styles.employerTableWrapper}>
          <Row>
            <Col span={12}>
              <h1>
                {brokerId
                  ? `${
                      layoutBrokerDetails ? layoutBrokerDetails.name : ''
                    } Employers`
                  : 'All Employers'}
              </h1>
            </Col>
            {appBootInfo?.individualSubType !==
              IndividualSubTypes.BROKER_USER && (
              <Col span={12}>
                <Popover
                  content={EMP_LIMIT_REACH_POPOVER_MSG}
                  overlayClassName="popoverInner employerListLimitPopover"
                  visible={employerLimitReached ? undefined : false}
                  placement="right"
                  align={{
                    offset: [-460, 25],
                  }}
                >
                  <div
                    className={`${
                      employerLimitReached ? styles.employerLimitReached : ''
                    }`}
                  >
                    <PageActionButton
                      type="primary"
                      onClick={() => setIsModalOpen(!employerLimitReached)}
                      className={styles.actionButton}
                    >
                      Add Employer
                    </PageActionButton>
                  </div>
                </Popover>
              </Col>
            )}
          </Row>
          <br />
          <Row className={styles.filterContainer}>
            <Col span={5}>
              <SearchBar
                value={searchText}
                placeholder="Search Employers"
                isIssueSearch={true}
                onChange={handleSearch}
              />
            </Col>
            {!brokerId ? (
              <>
                <Col span={6}>
                  <Filter
                    className={styles.EmployerFilter}
                    options={filterBrokerOptionsList()}
                    placeholder="All Brokers"
                    onSelectItems={onSelectBrokers}
                    filterValue={loggedInUserBroker}
                    showSearch={false}
                    searchName="Search Brokers"
                    selected={[]}
                    isFilterClose={isModalOpen}
                  />
                </Col>

                <Col span={6}>
                  <Filter
                    options={filterRenewalDates()}
                    placeholder="Renewal Date"
                    onSelectItems={onSelectRenewalDates}
                    filterValue={''}
                    searchName="Search Renewal Dates"
                    className={styles.EmployerFilter}
                    selected={[]}
                    isFilterClose={isModalOpen}
                  />
                </Col>

                <Col span={6}>
                  <Filter
                    options={filterToolsEnabled()}
                    placeholder="Tools Enabled"
                    onSelectItems={onSelectTools}
                    filterValue={featureTools}
                    searchName="Search Tools"
                    className={styles.EmployerFilter}
                    selected={[]}
                    isFilterClose={isModalOpen}
                  />
                </Col>
              </>
            ) : (
              <>
                <Col span={6}>
                  <Filter
                    options={filterRenewalDates()}
                    placeholder="Renewal Date"
                    onSelectItems={onSelectRenewalDates}
                    filterValue={''}
                    searchName="Search Renewal Dates"
                    className={styles.EmployerFilter}
                    selected={[]}
                    isFilterClose={isModalOpen}
                  />
                </Col>
                <Col span={6}>
                  <Filter
                    options={filterTeamMemberOptionsList()}
                    placeholder="Team Assigned To"
                    onSelectItems={onSelectTeamMembers}
                    filterValue={userId}
                    searchName="Search Team Members"
                    className={styles.EmployerFilter}
                    selected={handleFilterSelect()}
                    isFilterClose={isModalOpen}
                  />
                </Col>

                <Col span={7}>
                  <Filter
                    options={filterLocationOptionsList()}
                    placeholder="All Locations"
                    onSelectItems={onSelectLocations}
                    filterValue={locationId}
                    searchName="Search Locations"
                    className={styles.EmployerFilter}
                    selected={[]}
                    isFilterClose={isModalOpen}
                  />
                </Col>
              </>
            )}
          </Row>

          <br />

          {brokerId && (
            <Row className={styles.filterContainer_broker}>
              <Col span={5}>
                <Filter
                  options={filterToolsEnabled()}
                  placeholder="Tools Enabled"
                  onSelectItems={onSelectTools}
                  filterValue={featureTools}
                  searchName="Search Tools"
                  selected={[]}
                  isFilterClose={isModalOpen}
                />
              </Col>
            </Row>
          )}
          <DataTable
            currentIndex={paginationConfig.paginationIndex}
            data={convertEmployersForTableRender(content)}
            columns={employerTableColumns()}
            pagination={true}
            loading={inProgress}
            getData={getData}
            total={total}
            pageSize={limit}
          />
        </div>
      )}
      <AddEmployerModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <EditBrokerTeamModal
        isModalOpen={isTeamModalOpen}
        setIsModalOpen={setIsTeamModalOpen}
        brokerName={employerObj ? employerObj.name : ''}
        selectedEmployer={selectedEmployer}
      />
      <ConfirmationDialog
        visible={isHideEmployerModal.show}
        centered={true}
        title="Hide Employer"
        confirmText={'Hide Employer'}
        cancelText="Cancel"
        confirmBtnFullWidth={true}
        confirmLoading={isArchiveEmployerLoading}
        isCancelLink={true}
        closeModal={() =>
          setIsHideEmployerModal({
            show: false,
            employerId: '',
            employerName: '',
          })
        }
        onConfirm={handleConfirmHideEmployer}
        destroyOnClose={true}
      >
        <div>
          <p>
            Are you sure you want to hide {isHideEmployerModal.employerName} ?
          </p>
        </div>
      </ConfirmationDialog>
    </>
  );
};

export default EmployerList;
