import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Typography } from 'antd';

import arrowRightIcon from 'assets/images/arrow-arc-right.svg';
import benefitIcon from 'assets/images/icon-benefits-next.svg';
import completedIcon from 'assets/images/icon-completed.svg';

import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import AlertMessage from 'components/Alert/AlertMessage';
import { clearBrokerCreateErr } from 'modules/brokers/slices/brokerBasicInfoSlice';
import styles from './completeStep.module.less';

const { Text } = Typography;

type Props = {
  closeModal: () => void;
};

const CompleteStep: FC<Props> = (props) => {
  const { closeModal } = props;
  const dispatch = useAppDispatch();
  const brokerCreateError = useAppSelector(
    (state) => state.brokers.brokerBasicInfo.brokerCreateError
  );

  const brokerId = useAppSelector(
    (state) => state.brokers.brokerBasicInfo.savedBroker.id
  );

  const navigate = useNavigate();

  const closeBrokerCreation = () => {
    dispatch(clearBrokerCreateErr());
    closeModal();
  };

  const goToBrokerDashboard = () => {
    navigate(`/brokers/${brokerId}`);
    closeBrokerCreation();
  };

  const viewAllBrokers = () => {
    navigate('/brokers');
    closeBrokerCreation();
  };

  return (
    <>
      {brokerCreateError && (
        // TODO: proper error message needs here
        <AlertMessage
          type="error"
          message={`Broker Creation Failed : ${brokerCreateError.data.code}`}
          closeAlert={() => dispatch(clearBrokerCreateErr())}
        />
      )}
      <Row>
        <Col span={10}>
          <div className={styles.completeStepWrapper}>
            <img
              src={completedIcon}
              className={styles.checkIcon}
              alt="check mark"
            />
            <Row>
              <Col className={styles.successContainer}>
                <h2>
                  <Text className={styles.successHeader}>Broker Added!</Text>
                </h2>
                <p className="text success-message">
                  You’ve successfully added a broker.
                  <br />
                  If you sent any invitations to broker
                  <br />
                  admins those have been sent.
                  <br />
                </p>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={4} className={styles.arrow}>
          <img src={arrowRightIcon} alt="arrow-right-icon" />
        </Col>
        <Col span={10}>
          <div className={styles.nextStepWrapper}>
            <img
              src={benefitIcon}
              className={styles.checkIcon}
              alt="benefit-icon"
            />
            <Row>
              <Col className={styles.successContainer}>
                <h2>
                  <Text className={styles.successHeader}>What’s next?</Text>
                </h2>
              </Col>
            </Row>
            <Row>
              <Col span={24} className={styles.rightColumn}>
                <SubmitButton type="primary" onClick={goToBrokerDashboard}>
                  View Broker Dashboard
                </SubmitButton>
              </Col>
            </Row>
            <Row>
              <Col span={24} className={styles.rightColumn}>
                <SubmitButton type="primary" onClick={viewAllBrokers}>
                  Go to All Brokers Table
                </SubmitButton>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default CompleteStep;
