import IconCSV from 'assets/documents/icon_file_CSV.svg';
import IconDOC from 'assets/documents/icon_file_DOC.svg';
import IconImage from 'assets/documents/icon_file_Image.svg';
import IconPDF from 'assets/documents/icon_file_PDF.svg';
import IconPPT from 'assets/documents/icon_file_PPT.svg';
import IconVideo from 'assets/documents/icon_file_Video.svg';
import IconXLS from 'assets/documents/icon_file_XLS.svg';

export const getFileIconBtExtension = (extension: string) => {
  switch (extension.toLowerCase()) {
    case 'pdf':
      return IconPDF;
    case 'png':
    case 'jpg':
    case 'jpeg':
      return IconImage;
    case 'xls':
    case 'xlsx':
      return IconXLS;
    case 'csv':
      return IconCSV;
    case 'pptx':
    case 'ppt':
      return IconPPT;
    case 'docx':
    case 'doc':
      return IconDOC;
    case 'mp4':
      return IconVideo;
    default:
      break;
  }
};

export const getReadableFileSizeString = (size: number) => {
  if (size === 0) return '0 B';
  const logFileSize = Math.floor(Math.log(size) / Math.log(1024));
  return (
    ((size / Math.pow(1024, logFileSize)) as any).toFixed(2) * 1 +
    ' ' +
    ['B', 'kB', 'MB'][logFileSize]
  );
};
