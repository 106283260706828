import { ChangeEventHandler, FC } from 'react';
import { Row, Col, Form } from 'antd';

import NumberFormatInput from 'components/FormInput/NumberFormatInput';

import {
  TaxAdvantageFields,
  TaxAdvantagedAccountPlanType,
} from 'modules/plans/constants';

import styles from './employerContributionDetails.module.less';

type EmployerContributionDetailsProps = {
  isEditMode?: boolean;
  changeInputStyle: Function;
  onNumberInputChange: ChangeEventHandler<HTMLInputElement>;
  setContributionsDisable: any;
  tierCount?: number;
  planType: string;
  isReviewHighlight?: boolean;
  benefitKind?: string;
};

const EmployerContributionDetails: FC<EmployerContributionDetailsProps> = ({
  isEditMode,
  changeInputStyle,
  onNumberInputChange,
  setContributionsDisable,
  tierCount = 0,
  planType,
  isReviewHighlight = false,
  benefitKind,
}: EmployerContributionDetailsProps) => {
  const nTierFormField = (index: number) => {
    return (
      <Form.Item
        name={`employerEE${index}Contribution`}
        label={`Employer EE + ${index} ${
          planType === TaxAdvantagedAccountPlanType.HRA.value
            ? 'Allowance'
            : 'Contribution'
        }`}
        labelCol={{ span: 24 }}
      >
        <NumberFormatInput
          name={`employerEE${index}Contribution`}
          prefix="$"
          isBrokerScreen={true}
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          className={
            changeInputStyle(`employerEE${index}Contribution`)
              ? styles.warningInput
              : '' || isReviewHighlight
              ? styles.highlightField
              : ''
          }
          onChange={onNumberInputChange}
          disabled={setContributionsDisable}
          allowNegative={false}
        />
      </Form.Item>
    );
  };

  const buildHSAOrHRANTiers = () => {
    const rows = [];
    for (let index = 2; index < tierCount; index += 2) {
      rows.push(
        <Row gutter={48}>
          <Col span={isEditMode ? 24 : 12}>{nTierFormField(index)}</Col>
          {index + 1 < tierCount && (
            <Col span={isEditMode ? 24 : 12} offset={isEditMode ? 0 : 0}>
              {nTierFormField(index + 1)}
            </Col>
          )}
        </Row>
      );
    }
    return rows;
  };

  return tierCount === 0 ? (
    <>
      {isEditMode && (
        <Row gutter={48}>
          <Col offset={isEditMode ? 0 : 12}>
            <Form.Item
              name="employerESContribution"
              label={`Employer ES ${
                planType === TaxAdvantagedAccountPlanType.HRA.value
                  ? 'Allowance'
                  : 'Contribution'
              }`}
              labelCol={{ span: 24 }}
            >
              <NumberFormatInput
                name={TaxAdvantageFields.EMPLOYER_ES_CONTRIBUTION}
                prefix="$"
                isBrokerScreen={true}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                className={
                  changeInputStyle(TaxAdvantageFields.EMPLOYER_ES_CONTRIBUTION)
                    ? styles.warningInput
                    : '' || isReviewHighlight
                    ? styles.highlightField
                    : ''
                }
                onChange={onNumberInputChange}
                disabled={setContributionsDisable}
                allowNegative={false}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={48}>
        <Col span={isEditMode ? 24 : 12}>
          <Form.Item
            name="employerECContribution"
            label={`Employer EC ${
              planType === TaxAdvantagedAccountPlanType.HRA.value
                ? 'Allowance'
                : 'Contribution'
            }`}
            labelCol={{ span: 24 }}
          >
            <NumberFormatInput
              prefix="$"
              isBrokerScreen={true}
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              name={TaxAdvantageFields.EMPLOYER_EC_CONTRIBUTION}
              className={
                changeInputStyle(TaxAdvantageFields.EMPLOYER_EC_CONTRIBUTION)
                  ? styles.warningInput
                  : '' || isReviewHighlight
                  ? styles.highlightField
                  : ''
              }
              onChange={onNumberInputChange}
              disabled={setContributionsDisable}
              allowNegative={false}
            />
          </Form.Item>
        </Col>
        <Col span={isEditMode ? 24 : 12}>
          <Form.Item
            name="employerEFContribution"
            label={`Employer EF ${
              planType === TaxAdvantagedAccountPlanType.HRA.value
                ? 'Allowance'
                : 'Contribution'
            }`}
            labelCol={{ span: 24 }}
          >
            <NumberFormatInput
              prefix="$"
              isBrokerScreen={true}
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              name={TaxAdvantageFields.EMPLOYER_EF_CONTRIBUTION}
              className={
                changeInputStyle(TaxAdvantageFields.EMPLOYER_EF_CONTRIBUTION)
                  ? styles.warningInput
                  : '' || isReviewHighlight
                  ? styles.highlightField
                  : ''
              }
              onChange={onNumberInputChange}
              disabled={setContributionsDisable}
              allowNegative={false}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  ) : (
    <>
      {isEditMode && (
        <Row gutter={48}>
          <Col span={24}>{nTierFormField(1)}</Col>
        </Row>
      )}
      {buildHSAOrHRANTiers()}
    </>
  );
};

export default EmployerContributionDetails;
