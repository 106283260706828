import { FC } from 'react';
import { Modal } from 'antd';
import get from 'lodash/get';

import EditTelehealthPlan from 'modules/plans/telehealthRx/components/EditTelehealthPlan/EditTelehealthPlan';

import { BenefitCategory } from 'constants/commonConstants';

import IconInfo from 'assets/images/icon-info.svg';

import styles from './editTelehealthPlanUPWrapper.module.less';

type EditTelehealthPlanUPWrapperProps = {
  plan: any;
  onClose: any;
  isModalVisible: boolean;
};

const EditTelehealthPlanUPWrapper: FC<EditTelehealthPlanUPWrapperProps> = ({
  plan,
  onClose,
  isModalVisible,
}: EditTelehealthPlanUPWrapperProps) => {
  return (
    <Modal
      width={600}
      visible={isModalVisible}
      okText="Done"
      footer={false}
      className={styles.telehealthPlanChannelModal}
      closable={false}
    >
      <div className={styles.telehealthPlanChannelWrapper}>
        <div className={styles.title}>{`Edit ${
          get(BenefitCategory, plan?.benefitKind || '', '').label
        } Plan`}</div>
        <div className={styles.leftBorder}></div>
        <div className={styles.telehealthPlanChannel}>
          <div>
            <img src={IconInfo} alt="Icon Info" />
          </div>
          <div className={styles.defaultText}>
            Changing plan information here will change the plan information
            everywhere this plan is shown
          </div>
        </div>
        <EditTelehealthPlan
          plan={plan}
          onClose={onClose}
          isModalVisible={isModalVisible}
        />
      </div>
    </Modal>
  );
};

export default EditTelehealthPlanUPWrapper;
