import React, { FC } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { DELETE_RFP_CONFIRM_PROMPT } from 'modules/rfp/constants/RfpConstants';

type Props = {
  setDeleteConfirmRfp: (value: boolean) => void;
  deleteConfirmRfp: boolean;
  deleteRetrainConfirm: (value: string) => void;
  selectedRetrainId: string;
};

const ConfirmDeleteTrainFileModal: FC<Props> = (props: Props) => {
  const {
    setDeleteConfirmRfp,
    deleteConfirmRfp,
    deleteRetrainConfirm,
    selectedRetrainId,
  } = props;
  return (
    <div>
      <ConfirmationDialog
        centered
        destroyOnClose
        closeModal={() => {
          setDeleteConfirmRfp(false);
        }}
        visible={deleteConfirmRfp}
        title="Delete Training File"
        isCancelLink
        cancelText="Cancel"
        confirmText="Yes - Delete Training File"
        onConfirm={() => {
          setDeleteConfirmRfp(false);
          deleteRetrainConfirm(selectedRetrainId);
        }}
      >
        <p>{DELETE_RFP_CONFIRM_PROMPT}</p>
      </ConfirmationDialog>
    </div>
  );
};

export default ConfirmDeleteTrainFileModal;
