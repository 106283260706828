import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
} from 'react';

import { Form } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useAppSelector } from 'hooks/redux';
import Rates from 'modules/plans/components/Rates/Rates';
import {
  getPlanContributionByFrequency,
  updateMedicalPlanByPath,
  getPlanContributionInProgress,
} from 'modules/plans/slices/medicalPlanSlice';
import MedicalPlan from 'model/plans/MedicalPlan';
import { BenefitCategory } from 'constants/commonConstants';
import {
  getPlanContributionByFrequencyReview,
  updateMedicalPlanByPathReview,
} from 'modules/renewals/slices/addProposalToPlanYearSlice';

type RatesUPWrapperProps = {
  currentSection: string;
  medicalPlan: MedicalPlan;
  dbgMetaData: any;
  setMedicalPlan: Function;
  ratesRef?: MutableRefObject<any>;
  isReviewProp?: boolean;
  isReviewHighlight?: boolean;
};

const RatesUPWrapper = forwardRef((props: RatesUPWrapperProps, ref) => {
  const {
    medicalPlan,
    dbgMetaData,
    setMedicalPlan,
    ratesRef,
    currentSection,
    isReviewProp,
    isReviewHighlight,
  } = props;
  const { medicalRates, editedMedicalPlan } = useAppSelector(
    (state) => state.plan.plans
  );

  const requestType = useAppSelector((state) => state.plan.plans.requestType);
  const { dbgData } = useAppSelector((state) => state.plan.dgbInfo);

  const [ratesForm] = Form.useForm();
  const { dbgByFrequency } = dbgData;

  useImperativeHandle(ref, () => ({
    validate() {
      return ratesRef?.current?.validate();
    },
    isValidForm() {
      return ratesRef?.current?.validate();
    },
    showErrorMsg() {
      return ratesRef?.current?.showErrorMsg();
    },
  }));

  useEffect(() => {
    if (medicalRates && !isReviewProp) {
      setMedicalPlan({ ...medicalPlan, rates: medicalRates });
    }
    // eslint-disable-next-line
  }, [setMedicalPlan, medicalRates]);

  if (isEmpty(medicalPlan)) {
    return <div></div>;
  }

  const planData = isReviewProp ? medicalPlan : editedMedicalPlan;
  const getContributionAction = isReviewProp
    ? getPlanContributionByFrequencyReview
    : getPlanContributionByFrequency;

  const updatePlanByPathAction = isReviewProp
    ? updateMedicalPlanByPathReview
    : updateMedicalPlanByPath;
  return (
    <Rates
      benefitCategory={BenefitCategory.MEDICAL.value}
      updatePlanByPathAction={updatePlanByPathAction}
      getContributionAction={getContributionAction}
      benefitGuideObj={dbgMetaData}
      plan={planData}
      form={ratesForm}
      currentSection={currentSection}
      dbgByFrequency={dbgByFrequency}
      isDBGView={false}
      ref={ratesRef}
      getContributionInProgress={
        requestType === getPlanContributionInProgress.type
      }
      isUPEdit
      isReviewHighlight={isReviewHighlight}
      isReviewProp={isReviewProp}
    />
  );
});

RatesUPWrapper.displayName = 'RatesUPWrapper';
export default RatesUPWrapper;
