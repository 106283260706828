export const benGuideConstants = {
  published: 'PUBLISHED',
  draft: 'DRAFT',
  archived: 'ARCHIVED',
  inprogress: 'IN PROGRESS',
};

export const issueDashboardConstants = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
  UNKNOWN: 'UNKNOWN',
};

export const recentBillingDashboardConstants = {
  pastRenewal: 'PAST_RENEWAL',
  oneMonth: 'RENEWAL_WITHIN_1_MONTH',
  upComing: 'UPCOMING',
};
