import { FC, useState } from 'react';

import { Col, Row } from 'antd';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';
import { LifePlan } from 'model/plans/LifePlan';
import PlanDocumentsOverview from 'modules/plans/components/OverviewPage/PlanDocumentsOverview/PlanDocumentOverview';
import styles from './voluntaryPlanInformationOverview.module.less';

type VoluntaryPlanInformationOverviewProps = {
  plan: LifePlan;
  heading?: string;
};

const VoluntaryPlanInformationOverview: FC<
  VoluntaryPlanInformationOverviewProps
> = (props) => {
  const { plan } = props;

  const [viewPlanDocumentsModal, setViewPlanDocumentsModal] = useState(false);

  const contributionOverview = [
    {
      label: 'Enrollments',
      value: plan.enrollment ? `${plan.enrollment}` : '-',
    },
    {
      label: 'Employee Benefit',
      value: plan.employeeBenefit ? `${plan.employeeBenefit}` : '-',
    },
    {
      label: 'Employee Guaranteed Issue',
      value: plan.employeeGuaranteedIssue
        ? `${plan.employeeGuaranteedIssue}`
        : '-',
    },
    {
      label: 'Spouse Benefit',
      value: plan.spouseBenefit ? `${plan.spouseBenefit}` : '-',
    },
    {
      label: 'Spouse Guaranteed Issue',
      value: plan.spouseGuaranteedIssue ? `${plan.spouseGuaranteedIssue}` : '-',
    },
    {
      label: 'Child Benefit',
      value: plan.childBenefit ? `${plan.childBenefit}` : '-',
    },
    {
      label: 'Child Guaranteed Issue',
      value: plan.childGuaranteedIssue ? `${plan.childGuaranteedIssue}` : '-',
    },
    {
      label: 'Volume',
      value: plan.volume ? `$${plan.volume.toFixed(2)}` : '-',
    },
  ];

  const updateViewPlanDocumentsModal = (value: boolean) => {
    setViewPlanDocumentsModal(value);
  };

  return (
    <div className={styles.wrapper}>
      {contributionOverview.map((item, Index) => (
        <Row key={Index}>
          <Col span={24}>
            <InfoLabelSection labelText={item.label} value={item.value} />
          </Col>
        </Row>
      ))}
      <div className={styles.overview} id={'Plan_Doc'}>
        <PlanDocumentsOverview
          heading={'Plan Resources'}
          plan={plan}
          documents={plan?.documents}
          benefitKind={plan.benefitKind}
          viewPlanDocumentsModal={viewPlanDocumentsModal}
          setViewPlanDocumentsModal={(value) =>
            updateViewPlanDocumentsModal(value)
          }
        />
      </div>
    </div>
  );
};

export default VoluntaryPlanInformationOverview;
