import React from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import styles from './checkboxButton.module.less';

type Props = {
  label: string;
  value: any;
  checked: boolean;
  disabled?: boolean;
  showTooltip?: boolean;
  tooltipContent?: string;
  onHandleSelected: (value: string) => any;
};

const CheckboxButton = (props: Props) => {
  const {
    label,
    value,
    checked,
    disabled,
    showTooltip = false,
    tooltipContent = label,
    onHandleSelected,
  } = props;

  const renderLabel = () => (
    <>
      {checked && <CheckOutlined className={styles.selectedCheck} />}{' '}
      {checked ? 'Selected' : label}
    </>
  );

  return (
    <div className={styles.checkboxWrapper}>
      <input
        type="checkbox"
        id={`checkBox${value}`}
        value={value}
        checked={checked}
        onChange={(e) => {
          onHandleSelected(e.target.value);
        }}
        disabled={disabled}
      />

      {showTooltip ? (
        <Popover content={tooltipContent} placement="top">
          <label htmlFor={`checkBox${value}`}>{renderLabel()}</label>
        </Popover>
      ) : (
        <label htmlFor={`checkBox${value}`}>{renderLabel()}</label>
      )}
    </div>
  );
};

export default CheckboxButton;
