import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { DeepPartial } from '@reduxjs/toolkit';

import {
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_MULTIPART_FORM,
  baseApi,
} from 'util/apiUtil';

import { PlanYearVO } from 'model/PlanYearVO';
import ProposalSummaryVO from 'model/ProposalSummaryVO';
import { Plan } from 'model/plans/Plan';

const PROPOSALS_API_PREFIX = `${baseApi}/v1/renewals/offer-proposals`;
const EMPLOYER_API_PREFIX = `${baseApi}/v1/employers`;
const PLANS_V2_API_PREFIX = `${baseApi}/v2/plans`;
const PLANS_V1_API_PREFIX = `${baseApi}/v1/plans`;
const TAX_ADV_PLANS_V1_API_PREFIX = `${baseApi}/v1/plans/tax-advantage-accounts`;
const CancelToken = axios.CancelToken;
let cancelToken: any = undefined;

export const fetchProposalSummary = async ({
  proposalId,
  employerId,
}: {
  proposalId: string;
  employerId: string;
}) =>
  await axios.get<ProposalSummaryVO>(
    `${PROPOSALS_API_PREFIX}/${proposalId}/summary`,
    { params: { 'employer-id': employerId } }
  );

export const fetchPlanYears = async ({ employerId }: { employerId: string }) =>
  await axios.get<PlanYearVO[]>(
    `${EMPLOYER_API_PREFIX}/${employerId}/plan-years`
  );

/**
 * upload plan documents.
 *
 * @param {string} fileName File name.
 * @param {File} fileBlob File.
 * @return {AxiosPromise<any>}
 */
export const uploadPlanDocuments = (
  fileName: string,
  fileBlob: File
): AxiosPromise<any> => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }
  cancelToken = CancelToken.source();

  const formData = new FormData();
  formData.append('file', fileBlob, fileName);
  const config = {
    headers: {
      [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM,
    },
    cancelToken: cancelToken.token,
  };

  return axios.post(`${PLANS_V2_API_PREFIX}/temp-document`, formData, config);
};

export const fetchFinalApproveReviewPlanDetail = async ({
  planId,
  benefitCategory,
  rates,
  mergedPlanId,
  planContributionId,
}: {
  planId: string;
  benefitCategory: string;
  rates: any;
  mergedPlanId?: string;
  planContributionId?: string;
}): Promise<AxiosResponse<DeepPartial<Plan>, any>> => {
  return await axios.post(
    `${PLANS_V1_API_PREFIX}/${benefitCategory}/offer-plans`,
    rates,
    {
      params: {
        'plan-id': planId,
        'merge-plan-id': mergedPlanId,
        planContributionId: planContributionId,
      },
    }
  );
};

export const fetchTaxAdvantagePlans = async (
  planId: string
): Promise<AxiosResponse<DeepPartial<Plan>, any>> => {
  return await axios.get(
    `${PLANS_V1_API_PREFIX}/tax-advantage-accounts/${planId}`
  );
};

export const fetchMergablePlans = async ({
  benefit,
  planId,
  employerId,
}: {
  benefit: string;
  planId: string;
  employerId: string;
}): Promise<AxiosResponse<{ planId?: string; planName?: string }[], null>> =>
  await axios.get(`${PLANS_V1_API_PREFIX}/${benefit}/proposal-plan/${planId}`, {
    params: { 'employer-id': employerId },
  });

export const fetchTaxAdMergablePlans = async ({
  employerId,
  planYearId,
  benefitKind,
}: {
  employerId: string;
  planYearId: string;
  benefitKind: string;
}): Promise<AxiosResponse<DeepPartial<Plan>[], any>> =>
  await axios.get(`${TAX_ADV_PLANS_V1_API_PREFIX}`, {
    params: {
      'employer-id': employerId,
      'plan-year-id': planYearId,
      'benefit-kinds': benefitKind,
    },
  });
