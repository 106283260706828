import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Col,
  Form,
  Input,
  notification,
  Popover,
  Row,
  Select,
  Switch,
} from 'antd';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { DefaultOptionType } from 'antd/lib/select';
import { isEmpty } from 'lodash';
import { removeDuplicateEmails } from 'util/arrayUtil';
import { useNavContext } from 'hooks/useNavContext';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import InputForm from 'components/InputForm/InputForm';
import InputEmailChip from 'components/InputEmailChip/InputEmailChip';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import InputFileUpload from 'components/InputFileUpload/InputFileUpload';
import BrokerAdmin from 'model/BrokerAdmin';
import Employer from 'model/Employer';
import {
  ISSUE_STATUS_NEW,
  ISSUE_SUBTYPE_MAP,
  ISSUE_TYPE_ACCOUNT,
  PRIORIRY_MAP,
} from 'modules/issueslog/constants/IssuesLogConstants';
import {
  useCreateIssueMutation,
  useGetEmployersByBrokerIdQuery,
  useGetOrganizationByIdQuery,
  useLazyGetBrokerAdminsForEmployerQuery,
  useLazyGetIndividualByIdQuery,
  useLazyGetSupportTeamByIdQuery,
} from 'modules/issueslog/slices/issuesLogSlice';
import { isNullOrUndefined } from 'modules/plans/utils';
import { useAppSelector } from 'hooks/redux';
import {
  getSubHeadingAccordingToLoginType,
  issueAllowedFileTypes,
  issueType,
} from 'modules/issueslog/utils/issueLogUtils';
import { loginTypes } from 'constants/authConstants';
import {
  filterByLabel,
  FORMAT_VALIDATE,
  isRichTextEditorEmpty,
} from 'util/commonUtil';
import { getFileUploadErrorMessages } from 'util/fileUtil';
import { AccountStatus } from 'modules/admins/constants/adminConstants';
import styles from './createIssue.module.less';

const { Option } = Select;

type CreateIssueProps = {
  onSuccessfullySaved?: (id: string) => void;
  onClickedCancel: Function;
  triggerFromDashboard?: boolean;
};

type EmailInputStateProps = {
  defaultMailList: string[];
  emailList: string[];
  isValidList: boolean;
  isEmptyList: boolean;
  removableDefaultMailList: string[];
};

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const CreateIssue: FC<CreateIssueProps> = ({
  onSuccessfullySaved,
  onClickedCancel,
  triggerFromDashboard = false,
}) => {
  const { brokerId } = useNavContext();
  const params = useParams();
  const isEmployerView = !!params?.employerId;
  const brokerType = params?.type;

  const [form] = Form.useForm();
  const [formData, setFormData] = useState<any>({
    organizationId: brokerId,
    showInEmployer: false,
  });

  // Selectors
  const appBootInfo: any = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );

  // Constant
  const isNotErAdmin: boolean = appBootInfo?.type !== loginTypes.erAdmin;

  // if erAdmin  use these state, ref, effects
  const [getIndividualByIdQuery, { data: loggedUser }] =
    useLazyGetIndividualByIdQuery();

  const [
    getSupportTeam,
    { data: supportTeam, isFetching: supportTeamLoading },
  ] = useLazyGetSupportTeamByIdQuery();

  const employerObj: any = useAppSelector((state) => state?.layout?.employer);

  useEffect(() => {
    if (!isNotErAdmin) {
      if (params?.employerId && params?.brokerId)
        getSupportTeam({
          employerId: params?.employerId,
          organizationId: params?.brokerId,
        });
      getIndividualByIdQuery({ individualId: appBootInfo?.individualId });
    }
    // eslint-disable-next-line
  }, [isNotErAdmin, appBootInfo, params?.employerId]);

  useEffect(() => {
    if (!isNotErAdmin && employerObj) {
      const defaultMail = [];
      const removableDefaultMailList: any = [];
      if (employerObj?.issuesLogAccountEmail) {
        removableDefaultMailList.push(
          employerObj?.issuesLogAccountEmail?.email
        );
      } else {
        supportTeam?.map((item: any) => {
          removableDefaultMailList.push(item.email);
        });
      }
      defaultMail.push(loggedUser?.email);
      setCCEmailInputState({
        ...ccEmailInputState,
        defaultMailList: defaultMail,
      });
      setToEmailInputState({
        ...toEmailInputState,
        removableDefaultMailList: removableDefaultMailList,
      });

      form.setFieldsValue({ toList: removableDefaultMailList });
      form.setFieldsValue({ ccList: defaultMail });
    }
    // no need to rerender when ccEmailInputState change
    // eslint-disable-next-line
  }, [isNotErAdmin, loggedUser, supportTeam, employerObj]);

  const toEmailRef = useRef<any>();
  const ccEmailRef = useRef<any>();

  const [toEmailInputState, setToEmailInputState] =
    useState<EmailInputStateProps>({
      defaultMailList: [],
      emailList: [],
      isValidList: false,
      isEmptyList: false,
      removableDefaultMailList: [],
    });

  const [ccEmailInputState, setCCEmailInputState] =
    useState<EmailInputStateProps>({
      defaultMailList: [],
      emailList: [],
      isValidList: false,
      isEmptyList: false,
      removableDefaultMailList: [],
    });

  const [isEmailFieldsEmpty, setIsEmailFieldsEmpty] = useState(false);
  const [isEmailBodyEmpty, setIsEmailBodyEmpty] = useState(false);
  const [isBrokerTeamEmpty, setIsBrokerTeamEmpty] = useState(false);

  // if erAdmin end

  // if broker support
  const { data: supportTeamData } = useGetOrganizationByIdQuery({
    brokerId: params?.brokerId ?? '',
  });

  useEffect(() => {
    if (brokerType === issueType.SUPPORT) {
      if (supportTeamData?.issuesLogSupportEmails?.length <= 1) {
        form.setFieldsValue({
          supportTeam: supportTeamData?.issuesLogSupportEmails[0]?.name,
        });
      }
    }
    // eslint-disable-next-line
  }, [supportTeamData, brokerType]);

  const { employerId, subject = '' } = formData;

  const {
    data: employerData = [],
    refetch,
    isLoading: isLoadingEmpData,
  } = useGetEmployersByBrokerIdQuery({
    brokerId: brokerId || '',
    individualId: null,
  });

  const fetchData = async () => {
    try {
      await refetch();
    } catch (error) {
      console.error('Error fetching employer data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [brokerId]);

  const [getBrokerAdminsForEmployerQuery, { data: brokerAdminData = [] }] =
    useLazyGetBrokerAdminsForEmployerQuery();

  const [createIssue, { isSuccess, data, isLoading }] =
    useCreateIssueMutation();

  const employerList = employerData.map((employer: Employer) => {
    return { value: employer.id, label: employer.name };
  });

  const inputEmailPopoverDropdownData = supportTeam?.map((item: any) => ({
    label: `${item?.firstName} ${item?.lastName}`,
    value: item?.email,
  }));

  const supportTeamDropdownList = supportTeamData?.issuesLogSupportEmails?.map(
    (item: any) => ({
      label: item?.name,
      value: item?.name,
    })
  );

  const updateFormData = (data: any) => {
    setFormData({ ...formData, ...data });
  };

  useEffect(() => {
    form.setFieldsValue({ assigneeId: '' });
    if (brokerId && employerId) {
      getBrokerAdminsForEmployerQuery({
        brokerId: brokerId || '',
        employerId: employerId,
        isActiveAndNonActive: brokerType === issueType.SUPPORT,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerId, brokerId, form, getBrokerAdminsForEmployerQuery]);

  useEffect(() => {
    if (params?.employerId && !isLoadingEmpData) {
      form.setFieldsValue({ employerId: params?.employerId });
      updateFormData({ employerId: params?.employerId });
    }
    // No need add updateFormData since this function update formData
    // eslint-disable-next-line
  }, [params?.employerId, form, isLoadingEmpData]);

  useEffect(() => {
    if (isSuccess && data?.id && onSuccessfullySaved) {
      onSuccessfullySaved(data?.id);
    }
  }, [isSuccess, onSuccessfullySaved, data?.id]);

  // handle TO list in email chip
  const handleGetToEmailList = ({
    emailList = [],
    isValidList = false,
    isEmptyList = false,
    defaultMailList = [],
  }: Partial<EmailInputStateProps>) => {
    setIsEmailFieldsEmpty(false);
    setIsBrokerTeamEmpty(false);
    setToEmailInputState({
      defaultMailList: defaultMailList,
      emailList: emailList,
      isValidList: isValidList,
      isEmptyList: isEmptyList,
      removableDefaultMailList: [],
    });
    form.setFieldsValue({ toList: emailList });
  };

  // handle CC list in email chip
  const handleGetCCEmailList = ({
    emailList = [],
    isValidList = false,
    isEmptyList = false,
    defaultMailList = [],
  }: Partial<EmailInputStateProps>) => {
    setIsBrokerTeamEmpty(false);
    setCCEmailInputState({
      defaultMailList: defaultMailList,
      emailList,
      isValidList,
      isEmptyList,
      removableDefaultMailList: [],
    });
    form.setFieldsValue({ ccList: emailList });
  };

  // function for get show in employer according to below conditions
  const getShowInEmployer = () => {
    if (brokerType === issueType.SUPPORT) {
      return false;
    }
    if (!isNotErAdmin) {
      return true;
    }
    return formData.showInEmployer;
  };

  const saveIssue = () => {
    const checkbrokerTeam = checkForBrokerTeamMembers();

    form
      .validateFields()
      .then((data) => {
        checkbrokerTeam &&
          createIssue({
            ...formData,
            subject: data?.subject?.trim(),
            type: params?.type?.toUpperCase() || ISSUE_TYPE_ACCOUNT,
            status: ISSUE_STATUS_NEW,
            showInEmployer: getShowInEmployer(),
            toList:
              removeDuplicateEmails([
                ...toEmailInputState.emailList,
                ...toEmailInputState.defaultMailList,
                ...toEmailInputState.removableDefaultMailList,
              ]) || [],
            ccList:
              removeDuplicateEmails([
                ...ccEmailInputState.emailList,
                ...ccEmailInputState.defaultMailList,
              ]) || [],
            emailBody: data?.emailBody || '',
            files: data?.files || [],
            supportTeam: data?.supportTeam || '',
          });
      })
      .catch((error) => {
        if (!isNotErAdmin) {
          const emailBodyCheck = error.errorFields?.some((item: any) =>
            item.name?.includes('emailBody')
          );
          emailBodyCheck && setIsEmailBodyEmpty(true);

          [
            ...toEmailInputState.emailList,
            ...toEmailInputState.defaultMailList,
            ...ccEmailInputState.defaultMailList,
            ...ccEmailInputState.emailList,
          ].length === 0 && setIsEmailFieldsEmpty(true);
        }
      });
  };

  // check whether To or Cc contains atleast one broker team member
  const checkForBrokerTeamMembers = () => {
    if (!isNotErAdmin) {
      const checkAutomatedEmail =
        toEmailInputState.removableDefaultMailList?.includes(
          employerObj?.issuesLogAccountEmail?.email
        );
      const checkBrokerTeaminTo = supportTeam.some((item: any) =>
        toEmailInputState?.emailList.includes(item.email)
      );
      const checkBrokerTeaminCc = supportTeam.some((item: any) =>
        ccEmailInputState?.emailList.includes(item.email)
      );
      const checkBrokerTeaminRemovalbleTo = supportTeam.some((item: any) =>
        toEmailInputState?.removableDefaultMailList?.includes(item.email)
      );
      if (
        !checkAutomatedEmail &&
        !checkBrokerTeaminTo &&
        !checkBrokerTeaminCc &&
        !checkBrokerTeaminRemovalbleTo
      ) {
        setIsBrokerTeamEmpty(true);
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const filterSort = (
    optionA: DefaultOptionType,
    optionB: DefaultOptionType
  ) => {
    return (optionA.label?.toString().toLowerCase() || '').localeCompare(
      optionB.label?.toString().toLowerCase() || ''
    );
  };

  // Tooltip content
  const locationTooltipContent = () => (
    <p className={styles.popoverText}>
      You can create an item by emailing your benefits team <br /> using the
      section provided below. You can add additional <br /> email addresses for
      anyone else you wish to include in <br /> the email thread.
    </p>
  );

  // Notification Error Message
  const openNotificationWithIcon = (
    type: NotificationType,
    validateSetting: string
  ) => {
    let description: any = getFileUploadErrorMessages(validateSetting).message;
    if (FORMAT_VALIDATE === validateSetting) {
      description = (
        <Col>
          <span>
            Invalid file type uploaded. Submitted files must be in following
            formats.
          </span>
          <br />
          <span>
            pdf, csv, xlsx, xls, docx, doc, pptx, ppt, png, jpeg, jpg, zip
          </span>
        </Col>
      );
    }
    notification[type]({
      closeIcon: <></>,
      top: 40,
      message: getFileUploadErrorMessages(validateSetting).title,
      description: description,
      icon: <CloseOutlined className={styles.notificationErrorIcon} />,
    });
  };

  const findSupportTeam = useMemo(() => {
    return supportTeamData?.issuesLogSupportEmails?.find(
      (item: any) => item?.name === formData?.supportTeam
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportTeamData?.issuesLogSupportEmails, formData?.supportTeam, form]);

  useEffect(() => {
    if (brokerType === issueType.SUPPORT) {
      if (supportTeamData?.issuesLogSupportEmails?.length <= 1) {
        const onlySupTeam = supportTeamData?.issuesLogSupportEmails[0];
        updateFormData({
          supportTeam: onlySupTeam?.name,
          employerId: onlySupTeam?.taggedEmployer || null,
          assigneeId:
            onlySupTeam?.taggedEmployer &&
            onlySupTeam?.brokerAdmins?.length === 1
              ? onlySupTeam?.brokerAdmins[0]
              : null,
        });
        form.setFieldsValue({
          supportTeam: onlySupTeam?.name,
          employerId: onlySupTeam?.taggedEmployer,
          assigneeId:
            onlySupTeam?.taggedEmployer &&
            onlySupTeam?.brokerAdmins?.length === 1
              ? onlySupTeam?.brokerAdmins[0]
              : null,
        });
      }
    }
    // eslint-disable-next-line
  }, [supportTeamData, brokerType]);

  const isNvApplicable = (broker: any): boolean => {
    return AccountStatus.ACCOUNT_ACTIVATED !== broker.accountStatus;
  };

  const brokerAdminList = brokerAdminData
    .map((admin: BrokerAdmin) => ({
      value: admin.id,
      label: `${admin.firstName} ${admin.lastName}`,
      isTagged: findSupportTeam?.brokerAdmins?.includes(admin?.id) || false,
      isNvApplicable: isNvApplicable(admin),
    }))
    .sort((a: any, b: any) => (b?.isTagged ? 1 : 0) - (a?.isTagged ? 1 : 0));

  useEffect(() => {
    if (isNotErAdmin && brokerType === issueType.SUPPORT) {
      if (findSupportTeam?.taggedEmployer) {
        form.setFieldsValue({
          employerId: findSupportTeam?.taggedEmployer || null,
          assigneeId:
            findSupportTeam?.brokerAdmins?.length === 1 &&
            findSupportTeam?.isOnlyAdminVerified
              ? findSupportTeam?.brokerAdmins[0]
              : null,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.supportTeam, form]);

  return (
    <div className={styles.createIssueWrapper}>
      <div className={styles.title}>Create New Item</div>
      <div className={styles.subTitle}>
        {getSubHeadingAccordingToLoginType(
          String(appBootInfo?.type),
          String(brokerType),
          triggerFromDashboard
        )}
      </div>

      <InputForm form={form} onValuesChange={updateFormData}>
        {isNotErAdmin && brokerType === issueType.SUPPORT && (
          <Row>
            <Col xs={24}>
              <Form.Item
                labelAlign="left"
                labelCol={{ xs: 24 }}
                label="Support Team"
                name="supportTeam"
                rules={[
                  { required: true, message: 'Please select a support team' },
                ]}
              >
                <Select
                  showSearch
                  filterOption={filterByLabel}
                  disabled={
                    isEmpty(supportTeamDropdownList) ||
                    supportTeamDropdownList?.length === 1
                  }
                  onChange={(value) => {
                    const selectedSupTeam =
                      supportTeamData?.issuesLogSupportEmails?.find(
                        (item: any) => item?.name === value
                      );
                    updateFormData({
                      supportTeam: value,
                      employerId: selectedSupTeam?.taggedEmployer || null,
                      assigneeId:
                        selectedSupTeam?.taggedEmployer &&
                        selectedSupTeam?.brokerAdmins?.length === 1 &&
                        selectedSupTeam?.isOnlyAdminVerified
                          ? selectedSupTeam?.brokerAdmins[0]
                          : null,
                    });
                    if (!selectedSupTeam?.taggedEmployer) {
                      form.setFieldsValue({ employerId: null });
                    }
                  }}
                  allowClear
                  options={supportTeamDropdownList}
                  listHeight={128}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={24}>
            <Form.Item
              labelAlign="left"
              labelCol={{ xs: 24 }}
              label="Item Title"
              name="subject"
              rules={[
                {
                  required: true,
                  message: 'Please enter an item title',
                  whitespace: true,
                },
              ]}
            >
              <Input maxLength={255} />
            </Form.Item>
            <span
              className={`${styles.titleLimit} ${
                subject.length === 255 ? styles.limitReached : ''
              }`}
            >
              Character Limit: {subject.length}/255
            </span>
          </Col>
        </Row>
        {isNotErAdmin && (
          <Row>
            <Col xs={11}>
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.supportTeam !== curValues.supportTeam
                }
                labelAlign="left"
                labelCol={{ xs: 24 }}
                label="Employer"
                name="employerId"
                rules={[
                  {
                    required: true,
                    message: 'Please select an employer',
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={filterByLabel}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  options={employerList}
                  allowClear
                  listHeight={128}
                  disabled={isEmployerView || findSupportTeam?.taggedEmployer}
                  filterSort={filterSort}
                ></Select>
              </Form.Item>
            </Col>
            <Col xs={11} offset={2}>
              <Form.Item
                labelAlign="left"
                labelCol={{ xs: 24 }}
                label="Assign To"
                name="assigneeId"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.employerId !== curValues.employerId
                }
                rules={[
                  {
                    required: true,
                    message: 'Please select an assignee',
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={filterByLabel}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  allowClear
                  listHeight={128}
                  disabled={isNullOrUndefined(employerId)}
                  filterSort={filterSort}
                >
                  {brokerAdminList.map((obj: any) => {
                    return (
                      <Option
                        key={obj.value}
                        label={obj?.label}
                        disabled={
                          brokerType === issueType.SUPPORT && obj.isNvApplicable
                        }
                      >
                        <>{obj.label}</>
                        {obj.isNvApplicable &&
                          brokerType === issueType.SUPPORT && (
                            <span className={styles.notVerifiedLabel}>
                              (not verified)
                            </span>
                          )}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={11}>
            <Form.Item
              labelAlign="left"
              labelCol={{ xs: 24 }}
              label="Item Type"
              name="subType"
              rules={[
                {
                  required: true,
                  message: 'Please select an item type',
                  whitespace: true,
                },
              ]}
            >
              <Select
                showSearch
                filterOption={filterByLabel}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                options={ISSUE_SUBTYPE_MAP}
                allowClear
                listHeight={128}
              ></Select>
            </Form.Item>
          </Col>
          <Col xs={11} offset={2}>
            <Form.Item
              labelAlign="left"
              labelCol={{ xs: 24 }}
              label="Priority"
              name="priority"
              rules={[
                {
                  required: true,
                  message: 'Please select a priority',
                  whitespace: true,
                },
              ]}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                options={PRIORIRY_MAP}
                allowClear
                listHeight={128}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        {isNotErAdmin && (
          <Row>
            <Col xs={24}>
              <Form.Item
                labelAlign="left"
                labelCol={{ xs: 24 }}
                label="Description"
                name="description"
              >
                <RichTextEditor limit={31000} maxHeight={280} />
              </Form.Item>
            </Col>
          </Row>
        )}

        {!isNotErAdmin && (
          <>
            <Row gutter={8} className={styles.emailComponentHeader}>
              <Col className={styles.subTitleAlignLeft}>
                Communication with Benefits Team
              </Col>
              <Col>
                <Popover
                  content={locationTooltipContent}
                  placement="right"
                  overlayClassName="popoverInner"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </Col>
              {isEmailFieldsEmpty &&
                isEmpty(toEmailInputState.defaultMailList) && (
                  <Col xs={13} className={styles.errorText}>
                    At least 1 team member must be included
                  </Col>
                )}
              {isBrokerTeamEmpty && (
                <Row>
                  <Col xs={24} className={styles.errorText}>
                    At least one member from the broker team should be added to
                    this email thread.
                  </Col>
                </Row>
              )}
            </Row>

            <Form.Item
              name="toList"
              label={<span>To:</span>}
              labelAlign="left"
              labelCol={{ xs: 2 }}
              wrapperCol={{ xs: 22 }}
              rules={[
                { required: true, message: 'Please enter an email' },
                {
                  message: '',
                  validator: (_) => {
                    if (!toEmailInputState.isValidList) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error('Invalid email'));
                    }
                  },
                },
              ]}
              requiredMark="optional"
            >
              <InputEmailChip
                ref={toEmailRef}
                showAddMore={true}
                popoverOptions={inputEmailPopoverDropdownData || []}
                popDataLoading={supportTeamLoading}
                checkEmpty={true}
                getEmailList={handleGetToEmailList}
                removableDefaultMailList={
                  toEmailInputState.removableDefaultMailList
                }
              />
            </Form.Item>
            <Form.Item
              name="ccList"
              label={<span>Cc:</span>}
              labelAlign="left"
              labelCol={{ xs: 2 }}
              wrapperCol={{ xs: 22 }}
              rules={[
                {
                  message: '',
                  validator: (_) => {
                    if (!ccEmailInputState.isValidList) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error('Invalid email'));
                    }
                  },
                },
              ]}
            >
              <InputEmailChip
                ref={ccEmailRef}
                getEmailList={handleGetCCEmailList}
                loggedUser={{
                  email: loggedUser?.email,
                  popoverMessage: `Your email is cc'd to ensure you receive updates about this item`,
                }}
                defaultMailList={ccEmailInputState.defaultMailList}
              />
            </Form.Item>
            <>
              <Form.Item
                name="emailBody"
                rules={[
                  {
                    required: true,
                    message: 'Please enter body text.',
                  },
                  {
                    message: 'Please enter body text.',
                    validator: (_, value) => {
                      if (!isRichTextEditorEmpty(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error('Please enter body text.')
                        );
                      }
                    },
                  },
                ]}
                noStyle
              >
                <RichTextEditor
                  onChange={() => setIsEmailBodyEmpty(false)}
                  maxHeight={280}
                />
              </Form.Item>
              {isEmailBodyEmpty && (
                <div className={styles.bodyTextErrorText}>
                  Please enter body text.
                </div>
              )}
            </>
            <>
              <Form.Item name="files" noStyle>
                <InputFileUpload
                  allowedFileTypes={issueAllowedFileTypes}
                  uploadIconLabel="+ Include Attachment to Email"
                  maxFileSizeMB={11}
                  totalUploadSizeMB={20}
                  getFileList={(fileList) => {
                    form.setFieldsValue({ files: fileList });
                  }}
                  onValidateFails={(validateSetting, validateCondition) =>
                    openNotificationWithIcon('error', validateSetting)
                  }
                />
              </Form.Item>
            </>
          </>
        )}
        {isNotErAdmin &&
        (brokerType === issueType.ACCOUNT || triggerFromDashboard) ? (
          <Row>
            <Col xs={24} className={styles.enableForEmployer}>
              <span> Show this ticket on employer’s account log</span>
              <Form.Item name="showInEmployer">
                <Switch defaultChecked={false} disabled={false} />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        <Row>
          <Col xs={24}>
            <PageActionButton
              type="primary"
              className={styles.saveButton}
              loading={isLoading}
              onClick={saveIssue}
            >
              Save Item {!isNotErAdmin && `and Send Email`}
            </PageActionButton>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <div
              className={styles.cancelLink}
              onClick={() => onClickedCancel()}
            >
              Cancel
            </div>
          </Col>
        </Row>
      </InputForm>
    </div>
  );
};

export default CreateIssue;
