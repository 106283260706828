import {
  Checkbox,
  Col,
  Form,
  Input,
  Menu,
  Popover,
  Row,
  Select,
  Tag,
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toLower } from 'lodash';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import DataTable from 'components/DataTable/DataTable';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import SearchBar from 'components/SearchBar/SearchBar';
// import { ReactComponent as DownloadIcon } from 'assets/images/icon-download-black.svg';
import {
  useGetOfferCarrierNamesQuery,
  useLazyGetOpenOffersFilterQuery,
  useLazyGetOpenOffersQuery,
  useUpdateOpenOffersFilterMutation,
} from 'modules/renewals/slices/offersSlice';

import { ReactComponent as StarIcon } from 'assets/images/start-icon.svg';
import { loginTypes } from 'constants/authConstants';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import {
  BENEFIT_TYPE_OPTIONS,
  BENEFIT_TYPES,
  BID_STATUSES,
  LAST_MODIFIED_DATE_OPTIONS,
  OFFER_STATUS,
  OFFER_TAGS,
  OFFER_TYPES,
  OfferType,
  OPEN_OFFERS_SEARCH,
  RENEWAL_DATE_OPTIONS,
  RESET_BENEFIT_TYPE_OPTIONS,
  RESET_BENEFIT_TYPES,
  RESET_BID_STATUSES,
} from 'modules/renewals/constants/renewalsConstants';
import FilterDropDown from 'components/FilterDropDown/FilterDropDown';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { getBrokerList } from 'modules/employers/slices/employerCreateStepperSlice';
import SavedFilterDropdown from 'modules/renewals/components/helperComponents/SavedFilterDropdown/SavedFilterDropdown';
import {
  useGetAllEmployerAndSupportTeamQuery,
  useGetEmployersByBrokerIdQuery,
} from 'modules/issueslog/slices/issuesLogSlice';
import {
  replaceFilter,
  resetFilter,
  saveFilter,
  setDefaultFilters,
} from 'modules/renewals/slices/openOfferFiltersSlice';
import {
  IOpenOfferFilterDynamicObj,
  IPreferenceObj,
  ISelectOption,
} from 'modules/renewals/types/renewalsTypes';
import { getBenefitInfo } from 'modules/renewals/utils/renewalsUtils';
import AlertMessage from 'components/Alert/AlertMessage';
import styles from './openOffers.module.less';

const OpenOffers = () => {
  const dispatch = useAppDispatch();
  const [showSecondRow, setShowSecondRow] = useState(false);
  const brokers = useAppSelector((state) => state.employer.employer.brokerList);
  const filterValues = useAppSelector((state) => state?.openOfferFilters);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [rowData, setRowData] = useState<any>();
  const appBootInfo: any = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );
  const [savedFilters, setSavedFilters] = useState<any[]>([]);
  const [isDefaultChecked, setIsDefaultChecked] = useState<boolean>(false);
  const [isDuplicateFilterExist, setIsDuplicateFilterExist] =
    useState<boolean>(false);
  const [isDataTableLoading, setIsDataTableLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const offerViewHandler = (data: any) => {
    setRowData(data);
    if (data?.offers?.length > 1) {
      setConfirmOpen(true);
    }
  };
  const isPlatformAdmin: boolean = appBootInfo?.type === loginTypes.platform;
  const {
    data: allEmployers,
    isLoading: isAllPlatformAdminEmployersLoading,
    isSuccess: isAllPlatformAdminEmployersSuccess,
  } = useGetAllEmployerAndSupportTeamQuery({
    skip: !isPlatformAdmin,
  });

  const toggleConfirmationDeletePopup = () => {
    setConfirmOpen(false);
  };
  const defaultSortingConst: any = '';
  const params = useParams();
  const {
    data: brokerEmployers,
    isLoading: isBrokerEmployersLoading,
    isSuccess: brokerEmployersSuccess,
  } = useGetEmployersByBrokerIdQuery({
    brokerId: String(params.brokerId),
    individualId: null,
  });
  const isEmployerView = !!params?.employerId;
  const { data: brokerEmployersTeam, isLoading: isBrokerEmpTeamLoading } =
    useGetEmployersByBrokerIdQuery(
      {
        brokerId: String(params.brokerId),
        individualId: appBootInfo.individualId,
      },
      { skip: isEmployerView }
    );

  // fetch saved filteration
  const [
    getOpenOffersFilters,
    {
      isSuccess: isOpenOffersFiltersSuccess,
      data: openOffersFilters,
      isLoading: isOpenOfferFiltersLoading,
    },
  ] = useLazyGetOpenOffersFilterQuery();

  const getEmployerTeamOption = () => {
    const optionArray: any[] =
      brokerEmployersTeam?.map((EmpTeamMember: any) => ({
        value: EmpTeamMember?.id,
        label: EmpTeamMember?.name,
      })) || [];
    return optionArray;
  };

  const getEmployerOptions = () => {
    if (isPlatformAdmin) {
      const optionArray: any[] =
        allEmployers?.map(({ name, id }: { name: string; id: string }) => ({
          value: id,
          label: name,
        })) || [];
      return [...optionArray];
    } else {
      const optionArray: any[] =
        brokerEmployers?.map(({ name, id }: { name: string; id: string }) => ({
          value: id,
          label: name,
        })) || [];
      return [...optionArray];
    }
  };

  const [updateOffersFilters, { isSuccess: isUpdateOffersSuccess }] =
    useUpdateOpenOffersFilterMutation();

  useEffect(() => {
    if (isUpdateOffersSuccess) {
      getOpenOffersFilters(appBootInfo.individualId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateOffersSuccess]);

  const handleFormSave = () => {
    form.validateFields().then((res) => {
      const filterName: string = res.filterName;
      const foundObj = savedFilters.find(
        (obj: IOpenOfferFilterDynamicObj) => obj.name === filterName
      );
      if (foundObj) {
        setIsDuplicateFilterExist(true);
        return;
      }
      setIsDuplicateFilterExist(false);

      // if set as default, other default are removed
      let updatedFilters = savedFilters;
      if (isDefaultChecked) {
        updatedFilters = savedFilters.map((obj) => {
          return { ...obj, isDefault: false };
        });
      }
      const newPreferences: IOpenOfferFilterDynamicObj[] = [
        ...updatedFilters,
        {
          name: filterName,
          values: filterValues,
          isDefault: isDefaultChecked,
        },
      ];
      setSavedFilters(newPreferences);
      replacePreferenceObjWithDataSetApi(
        appBootInfo.individualId,
        newPreferences
      );
      form.resetFields();
      setShowSaveFilterModal(false);
      setIsDefaultChecked(false);
    });
  };

  const handlePaginationChange = (dataFilters: any) => {
    const sort =
      dataFilters?.sorterInfo?.column === undefined
        ? defaultSortingConst
        : dataFilters?.sorterInfo?.order === 'descend'
        ? `-${dataFilters?.sorterInfo?.columnKey}`
        : dataFilters?.sorterInfo?.columnKey;
    dispatch(
      saveFilter({
        searchKey: '',
        clickedSaved: true,
        filterType: 'sort',
        filterValues: sort,
        page: dataFilters?.paginationIndex,
        size: dataFilters?.filterInfo?.limit,
      })
    );
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const textSearch = e.target.value;
    dispatch(
      saveFilter({
        searchKey: textSearch,
        clickedSaved: true,
        filterType: '',
        filterValues: [],
        page: filterValues?.page,
        size: filterValues?.size,
      })
    );
  };

  const [selectedFilteredForDefault, setSelectedFilteredForDefault] = useState<
    string | null
  >(null);

  const setFilterAsDefaultFilter = () => {
    const updatedFilters = savedFilters.map((obj) => {
      if (obj.name === selectedFilteredForDefault) {
        return { ...obj, isDefault: true };
      }
      return { ...obj, isDefault: false };
    });

    setSavedFilters(updatedFilters);
    replacePreferenceObjWithDataSetApi(
      appBootInfo.individualId,
      updatedFilters
    );
    setSelectedFilteredForDefault(null);
    setShowSetDefaultFilterModal(false);
  };

  const handelOkayOrReset = ({
    filterType,
    values,
  }: {
    filterType: string;
    values: any[];
  }) => {
    dispatch(
      saveFilter({
        clickedSaved: true,
        searchKey: '',
        filterType: filterType?.toLocaleLowerCase(),
        filterValues: values,
        page: filterValues?.page,
        size: filterValues?.size,
      })
    );
  };
  const isBrokerAdmin: boolean = appBootInfo?.type === loginTypes.bokerAdmin;

  const [
    getOpenOffers,
    {
      data: openOfferTableData,
      isLoading: openOfferDataLoading,
      isFetching: openOfferDataFetching,
    },
  ] = useLazyGetOpenOffersQuery();

  const {
    data: carrierData,
    isLoading: isCarrierListLoading,
    isFetching: carrierIsFetching,
  } = useGetOfferCarrierNamesQuery(
    { organizationId: params?.brokerId ?? null },
    { refetchOnMountOrArgChange: true }
  );

  // set applicable values for the initial api call
  useEffect(() => {
    if (isBrokerAdmin && brokerEmployersSuccess) {
      getOpenOffersFilters(appBootInfo.individualId);
    }
    if (isPlatformAdmin && isAllPlatformAdminEmployersSuccess) {
      getOpenOffersFilters(appBootInfo.individualId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brokerEmployersSuccess, isAllPlatformAdminEmployersSuccess]);

  // When rendering check if any default filters exist, and if so set as the currnt filteration
  useEffect(() => {
    if (isOpenOffersFiltersSuccess) {
      setSavedFiltersInitially();
      setFiltersBasedOnDataSet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenOffersFiltersSuccess]);

  const setSavedFiltersInitially = () => {
    if (openOffersFilters) {
      setSavedFilters(openOffersFilters?.preferences[OPEN_OFFERS_SEARCH] || []);
    } else {
      setSavedFilters([]);
    }
  };

  const setFiltersBasedOnDataSet = () => {
    if (openOffersFilters) {
      const foundFilter = openOffersFilters?.preferences[
        OPEN_OFFERS_SEARCH
      ].find((obj: IOpenOfferFilterDynamicObj) => obj.isDefault);
      if (foundFilter) {
        setSavedFilterValue(foundFilter.name);
        dispatch(replaceFilter(foundFilter.values));
        return;
      }
      triggerInitialDefaultFilter();
    }
    triggerInitialDefaultFilter();
  };

  const handleFilterOptionSelect = (val: string) => {
    const foundFilter: IOpenOfferFilterDynamicObj =
      openOffersFilters?.preferences[OPEN_OFFERS_SEARCH].find(
        (obj: IOpenOfferFilterDynamicObj) => obj.name === val
      );

    dispatch(replaceFilter(foundFilter.values));
    setSavedFilterValue(val);
  };

  const triggerInitialDefaultFilter = () => {
    dispatch(
      setDefaultFilters({
        carriers: filterCarrierOptionsList().map((value) => value.value),
        employers: getEmployerOptions().map((value) => value.value),
        bidStatus: BID_STATUSES.map((record) => record.value),
        categories: BENEFIT_TYPE_OPTIONS.map((record) => record.value),
        offerType: [OFFER_TYPES.PRIMARY, OFFER_TYPES.SUPPLEMENTARY],
        statuses: Object.keys(OFFER_STATUS).map((item) => item),
        offerStatus: [
          OFFER_TAGS.DTQ,
          OFFER_TAGS.PENDING,
          OFFER_TAGS.RECOMMENDED,
        ],
        types: RESET_BENEFIT_TYPES,
        renewalDates: RENEWAL_DATE_OPTIONS.map((item) => item.value),
        lastModify: LAST_MODIFIED_DATE_OPTIONS.map((item) => item.value),
        brokers: isPlatformAdmin ? brokers.map((item) => item.id) : [],
      })
    );
  };

  const filterEmployerOptionsValueList = useMemo(() => {
    const employers = isPlatformAdmin ? allEmployers : brokerEmployers;
    const options = employers?.map((item: any) => item.id);
    return options;
  }, [allEmployers, brokerEmployers, isPlatformAdmin]);

  useEffect(() => {
    setIsDataTableLoading(true);
    let isAllEmployerSelected = false;
    if (
      filterValues !== undefined &&
      filterValues.employernames !== undefined &&
      filterEmployerOptionsValueList !== undefined &&
      filterValues.employernames.length == filterEmployerOptionsValueList.length
    ) {
      isAllEmployerSelected = true;
    }
    if (
      [
        filterValues?.statuses?.length,
        filterValues?.offerstatus?.length,
        filterValues?.employernames?.length,
        filterValues?.types?.length,
        filterValues?.categories?.length,
        filterValues?.carriers?.length,
        filterValues?.lastmodified?.length,
        filterValues?.bidstatus?.length,
        filterValues?.renewaldates?.length,
      ].includes(0)
    )
      return;

    getOpenOffers({
      brokers: isBrokerAdmin ? [params.brokerId] : filterValues?.brokers,
      statuses: filterValues?.statuses,
      offerStatus: filterValues?.offerstatus,
      employernames: filterValues?.employernames,
      types: filterValues?.types,
      categories: filterValues?.categories,
      carriers: filterValues?.carriers,
      page: filterValues?.page,
      size: filterValues?.size,
      sort: filterValues?.sort,
      searchKeyValue: filterValues?.searchKey,
      lastmodified: filterValues?.lastmodified,
      bidstatus: filterValues?.bidstatus,
      renewalDates: filterValues?.renewaldates,
      isAllEmployerSelected: isAllEmployerSelected,
    });
    setIsDataTableLoading(false);
  }, [
    getOpenOffers,
    filterValues?.brokers,
    filterValues?.statuses,
    filterValues?.employers,
    filterValues?.carriers,
    filterValues?.categories,
    filterValues?.types,
    filterValues?.page,
    filterValues?.searchKey,
    filterValues?.lastmodified,
    filterValues?.bidstatus,
    filterValues?.employernames,
    filterValues?.offerstatus,
    filterValues?.renewaldates,
    filterValues?.sort,
    filterValues?.size,
    params.brokerId,
    isBrokerAdmin,
    filterValues,
    filterEmployerOptionsValueList,
  ]);

  const isTableLoading =
    openOfferDataLoading ||
    openOfferDataFetching ||
    isCarrierListLoading ||
    isAllPlatformAdminEmployersLoading ||
    isBrokerEmployersLoading ||
    isBrokerEmpTeamLoading ||
    isDataTableLoading;

  const filterBrokerOptionsList = () => {
    const options =
      brokers
        ?.map((item) => ({
          label: item.name,
          value: item.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [];
    return options;
  };

  const generateTag = (offer: any) => {
    if (offer?.offerStatus === 'PENDING') {
      return <Tag className={styles.pending}>PENDING</Tag>;
    } else if (offer?.offerStatus === 'DTQ') {
      return <Tag className={styles.dtq}>DTQ</Tag>;
    } else {
      if (offer?.offerDiffPerc > 0) {
        return (
          <a className={styles.positiveValue}>
            {'+' + offer?.offerDiffPerc + '%'}
          </a>
        );
      } else if (offer?.offerDiffPerc < 0) {
        return (
          <a className={styles.negativeValue}>{offer?.offerDiffPerc + '%'}</a>
        );
      } else if (
        offer?.offerDiffPerc === null ||
        offer?.offerDiffPerc === '0.00'
      ) {
        return '0%';
      }
    }
  };

  const checkOfferConditions = (offers: any) => {
    return offers.map((offer: any) => {
      let tag = null;
      let draft = null;
      let published = null;
      if (offer.status === 'PUBLISHED') {
        published = generateTag(offer);
      } else if (offer.status === 'DRAFT') {
        draft =
          offers.length === 2 ? (
            <a>
              <a>/ Draft </a>
              {generateTag(offer)}
            </a>
          ) : (
            generateTag(offer)
          );
      }
      if (offers.length === 2) {
        if (offers.status === 'DRAFT') {
          tag = (
            <div className={styles.publishDraft}>
              <a>{published}</a>
              <a>{draft}</a>
            </div>
          );
        } else {
          tag = (
            <div className={styles.publishDraft}>
              <a>{published}</a>
              <a>{draft}</a>
            </div>
          );
        }
      } else {
        tag = <div>{published != null ? published : draft}</div>;
      }
      return tag;
    });
  };

  const filterCarrierOptionsList = useCallback(() => {
    const options: ISelectOption[] = [];

    if (carrierData) {
      carrierData?.forEach((item: any) => {
        options.push({
          label: item,
          value: item,
        });
      });
    }

    const sortedOptions = options.sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    return sortedOptions;
  }, [carrierData]);

  const filterBrokerOptionsValueList = useMemo(() => {
    const options = brokers?.map((item) => item.id);
    return options;
  }, [brokers]);

  const filterCarrierOptionsValueList = useMemo(() => {
    const options = filterCarrierOptionsList().map((item) => item.value);
    return options;
  }, [filterCarrierOptionsList]);

  useEffect(() => {
    dispatch(getBrokerList());
  }, [dispatch]);

  const getFileActions = (data: any) => {
    const { offers } = data;
    return (
      <Menu>
        <Menu.Item
          key="viewOffer"
          onClick={() =>
            offers.length === 2
              ? offerViewHandler(data)
              : redirectToOfferView(data)
          }
        >
          View Offer
        </Menu.Item>
      </Menu>
    );
  };

  // filter refs, had to lift up the ref
  const brokersRef = useRef<any>();
  const statusesRef = useRef<any>();
  const employernamesRef = useRef<any>();
  const typesRef = useRef<any>();
  const categoriesRef = useRef<any>();
  const carriersRef = useRef<any>();
  const typeRef = useRef<any>();
  const pageRef = useRef<any>();
  const sizeRef = useRef<any>();
  const sortRef = useRef<any>();
  const bidstatussRef = useRef<any>();
  const lastmodifiedRef = useRef<any>();

  // eslint-disable-next-line no-unused-vars
  const handelResetAll = () => {
    brokersRef?.current?.reset();
    typeRef?.current?.reset();
    statusesRef?.current?.reset();
    employernamesRef?.current?.reset();
    typesRef?.current?.reset();
    categoriesRef?.current?.reset();
    carriersRef?.current?.reset();
    pageRef?.current?.reset();
    sizeRef?.current?.reset();
    sortRef?.current?.reset();
    bidstatussRef?.current?.reset();
    lastmodifiedRef?.current?.reset();
    dispatch(resetFilter());
  };

  const columns = [
    {
      title: 'OFFER NAME/TYPE',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      render: (name: string, record: any) => {
        const { type, recommended, offers } = record;
        return (
          <div className={styles.offerNameWrapper}>
            <div className={styles.wrapNameAndRecommonded}>
              {recommended && (
                <Popover
                  overlayClassName={'popoverInner recommendedtooltip'}
                  placement="top"
                  content={'Recommended Offer'}
                >
                  <div className={styles.starIcon}>
                    <StarIcon className={styles.starIcon} />
                  </div>
                </Popover>
              )}
              {offers.length == 2 ? (
                <a
                  className={styles.offerName}
                  onClick={() => offerViewHandler(record)}
                >
                  <OverflowPopover maxWidth={170}>{name}</OverflowPopover>
                </a>
              ) : (
                <a
                  className={styles.offerName}
                  onClick={() => redirectToOfferView(record)}
                >
                  <OverflowPopover maxWidth={170}>{name}</OverflowPopover>
                </a>
              )}
            </div>
            {type === 'RENEWAL' && (
              <div className={styles.renewalsTag}>
                <Tag icon={<CheckOutlined />} className={styles.tagStyles}>
                  <span className={styles.renewalTag}>{OfferType.RENEWAL}</span>
                </Tag>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'PUBLISHED /DRAFT',
      dataIndex: 'offers',
      key: 'status',
      width: '7%',
      render: (offers: any) => {
        const draftOffers = offers.filter(
          (offer: any) => offer.status === 'DRAFT'
        );
        const publishedOffers = offers.filter(
          (offer: any) => offer.status === 'PUBLISHED'
        );

        const hasDraftOffers = draftOffers.length > 0;
        const hasPublishedOffers = publishedOffers.length > 0;

        if (hasDraftOffers && hasPublishedOffers) {
          return (
            <>
              <Popover
                placement="top"
                overlayClassName={'popoverInner recommendedtooltip'}
                content={'Published'}
              >
                <span className={`${styles.circle} ${styles.publishedCircle}`}>
                  P
                </span>
              </Popover>
              <Popover
                overlayClassName={'popoverInner recommendedtooltip'}
                placement="top"
                content={'Draft'}
              >
                <span className={`${styles.circle} ${styles.draftCircle}`}>
                  D
                </span>
              </Popover>
            </>
          );
        } else if (hasDraftOffers) {
          return (
            <Popover
              overlayClassName={'popoverInner recommendedtooltip'}
              placement="top"
              content={'Draft'}
            >
              <span className={`${styles.circle} ${styles.draftCircle}`}>
                D
              </span>
            </Popover>
          );
        } else if (hasPublishedOffers) {
          return (
            <Popover
              overlayClassName={'popoverInner recommendedtooltip'}
              placement="top"
              content={'Published'}
            >
              <span className={`${styles.circle} ${styles.publishedCircle}`}>
                P
              </span>
            </Popover>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: isPlatformAdmin ? 'EMPLOYER/ BROKER' : 'EMPLOYER/RENEWAL',
      dataIndex: 'employerName',
      key: 'employer',
      sorter: true,
      width: '15%',
      render: (_: any, data: any) => (
        <div>
          <div className={styles.employerName}>{data.employerName}</div>
          <div className={styles.brokerName}>
            {isPlatformAdmin
              ? data.organizationName
              : dayjs(data.renewal).format('ll')}
          </div>
        </div>
      ),
    },
    {
      title: 'CARRIER',
      dataIndex: 'carrier',
      key: 'carrier',
      sorter: true,
      width: '13%',
    },
    {
      title: 'BENEFIT TYPE',
      roataIndex: 'category',
      key: 'benefitType',
      sorter: true,
      width: '10%',
      render: (data: any) => (
        <div>
          <div className={styles.benefitType}>
            {getBenefitInfo(toLower(data.category)).name}
          </div>
        </div>
      ),
    },
    {
      title: 'BID STATUS',
      dataIndex: 'offers',
      key: 'offerStatus',
      width: '20%',
      render: (data: any) => {
        return (
          <div className={styles.bidStatus}>{checkOfferConditions(data)}</div>
        );
      },
    },
    {
      title: 'LAST MODIFIED',
      dataIndex: 'createdByLoginId',
      key: 'lastUpdatedTs',
      width: '10%',
      sorter: true,
      render: (_: any, data: any) => (
        <div>
          <div className={styles.benefitType}>
            {dayjs(data.lastUpdatedTs).format('MMMM D, YYYY h:mm A')}
          </div>
          <OverflowPopover popoverContent={data.lastUpdatedByLoginId}>
            <div className={styles.lastModifiedByName}>
              by {data.lastUpdatedByLoginId}
            </div>
          </OverflowPopover>
        </div>
      ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '5%',
      showSorterTooltip: false,
      render: (text: string, data: any) => {
        return (
          <SelectDropdown
            overlay={getFileActions(data)}
            className={styles.actionsButton}
          />
        );
      },
    },
  ];

  const redirectToOfferView = (content: any) => {
    const organizationId = content.organizationId;
    const employerId = content.employerId;
    let category = content.category;
    let offerId = content.offerId;

    // If content has an offers array with only one element, using that offer instead
    if (content.offers && content.offers.length === 1) {
      const offer = content.offers[0];
      offerId = offer.offerId;
      category = content.category;
    }
    const offerCategory = mapCategory(category);
    navigate(
      `/brokers/${organizationId}/employers/${employerId}/renewals/carrier/${offerCategory}/${offerId}/detail`
    );
  };

  const mapCategory = (category: string): string => {
    switch (category) {
      case 'MEDICAL':
        return 'medical';
      case 'DENTAL':
        return 'dental';
      case 'VISION':
        return 'vision';
      case 'LIFE':
        return 'life-add';
      case 'VOLUNTARY_BENEFIT':
        return 'vol-life';
      case 'STD':
        return 'std';
      case 'LTD':
        return 'ltd';
      default:
        return '';
    }
  };

  const columnsTest = [
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'OFFER NAME',
      dataIndex: 'name',
      key: 'name',
      render: (_: any, content: any) => {
        return (
          <div>
            <div
              className={styles.employer}
              onClick={() => redirectToOfferView(content)}
            >
              {content.name}
            </div>
          </div>
        );
      },
    },
    {
      title: 'BID STATUS',
      dataIndex: 'offerDiffPerc',
      key: 'offerDiffPerc',
      render: (_: any, content: any) => {
        return <div className={styles.bidStatus}>{generateTag(content)}</div>;
      },
    },
    {
      title: 'LAST MODIFIED',
      dataIndex: 'lastUpdatedTs',
      key: 'lastUpdatedTs',
    },
  ];

  const getListOfOpenFilters = (): any[] => {
    return isOpenOffersFiltersSuccess
      ? savedFilters?.map((val: any) => {
          return {
            value: val.name,
            label: val.name,
          };
        })
      : [];
  };

  const handleFilterItemDelete = () => {
    const selectedValue: string = showRemoveFilterOptionModal.id;
    const filteredPreferences: IOpenOfferFilterDynamicObj[] =
      savedFilters.filter(
        (obj: IOpenOfferFilterDynamicObj) => obj.name != selectedValue
      );

    setSavedFilters(filteredPreferences);
    replacePreferenceObjWithDataSetApi(
      appBootInfo.individualId,
      filteredPreferences
    );
    setSavedFilterValue('Custom Filter');
    setShowRemoveFilterOptionModal({ visible: false, id: '' });
    triggerInitialDefaultFilter();
  };

  const replacePreferenceObjWithDataSetApi = (
    individualId: string,
    preferences: IOpenOfferFilterDynamicObj[]
  ) => {
    const preferenceVo: IPreferenceObj = {
      individualId,
      preferenceType: OPEN_OFFERS_SEARCH,
      preferenceObject: preferences,
    };
    updateOffersFilters(preferenceVo);
  };

  const offerPopupData = (rowData: any) => {
    const offerDataArray = rowData?.offers?.map((offer: any) => ({
      name: rowData.name,
      lastUpdatedTs:
        dayjs(rowData.lastUpdatedTs).format('MMMM D, YYYY h:mm A') +
        '  ' +
        rowData.lastUpdatedByLoginId,
      status: offer.status,
      offerDiffPerc: offer.offerDiffPerc,
      offerId: offer.offerId,
      category: rowData.category,
      employerId: rowData.employerId,
      organizationId: rowData.organizationId,
    }));
    return offerDataArray;
  };

  const getRenewalDateDefaults = (): string[] => {
    return RENEWAL_DATE_OPTIONS.map((item) => item.value);
  };

  const getLastDateDefaults = (): string[] => {
    return LAST_MODIFIED_DATE_OPTIONS.map((item) => item.value);
  };

  const [savedFilterValue, setSavedFilterValue] = useState('Custom Filter');
  const [showSetDefaultFilterModal, setShowSetDefaultFilterModal] =
    useState(false);
  const [showRemoveFilterOptionModal, setShowRemoveFilterOptionModal] =
    useState({ visible: false, id: '' });
  const [showSaveFilterModal, setShowSaveFilterModal] = useState(false);
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.titleContainer}>
        <div className={styles.mainTopic}>
          <h1 className={styles.titleHeader}>Open Offers</h1>
        </div>
        <div className={styles.subTopics}>
          <LinkButton
            className={styles.tabExtraButtons}
            onClick={() => setShowSecondRow(!showSecondRow)}
          >
            {!showSecondRow ? (
              <span>Show More Filters</span>
            ) : (
              <span>Show Less Filters</span>
            )}
          </LinkButton>

          <SavedFilterDropdown
            options={getListOfOpenFilters()}
            selectedValue={savedFilterValue}
            setSelectedValue={(value) => handleFilterOptionSelect(value)}
            actionLinkButtonLabel="Reset All Filters"
            handleClickLinkButton={() => {
              setSavedFilterValue('Custom Filter');
              triggerInitialDefaultFilter();
            }}
            handleClickSettingButton={() => setShowSetDefaultFilterModal(true)}
            handleClickRemove={(value) =>
              setShowRemoveFilterOptionModal({ visible: true, id: value })
            }
            isLoading={isOpenOfferFiltersLoading}
          />
          <Tag
            className={styles.saveFilterBtn}
            onClick={() => setShowSaveFilterModal(true)}
          >
            Save Filter
          </Tag>
        </div>
      </div>
      <Row gutter={12} className={styles.filterCol}>
        <Col span={6}>
          <SearchBar
            value={filterValues?.searchKey}
            placeholder="Search"
            width={7}
            onChange={handleSearch}
            isRenewalSearch={true}
          />
        </Col>
        {isPlatformAdmin && (
          <Col span={6}>
            <FilterDropDown
              ref={brokersRef}
              placeholder="Broker"
              searchPlaceholder="Search Brokers"
              showSearch={true}
              showCustomButton={false}
              showSelectDeselect={true}
              optionSort={false}
              options={filterBrokerOptionsList()}
              defaultOptions={filterValues.brokers}
              resetOptions={filterBrokerOptionsValueList}
              filterName={'brokers'}
              handleOkayCallback={(values, filterName) => {
                handelOkayOrReset({
                  filterType: filterName,
                  values: values,
                });
              }}
              handleResetCallback={(values, filterName) => {
                handelOkayOrReset({
                  filterType: filterName,
                  values: values,
                });
              }}
            />
          </Col>
        )}
        <Col span={6}>
          <FilterDropDown
            ref={employernamesRef}
            placeholder="Employer"
            searchPlaceholder="Search Employers"
            showSearch={true}
            showSelectDeselect={true}
            optionSort={false}
            options={getEmployerOptions() || []}
            showCustomButton={!isPlatformAdmin}
            customButtonLabel={'My Employers'}
            customButtonOptions={getEmployerTeamOption()?.map(
              ({ value }) => value
            )}
            defaultOptions={filterValues?.employernames}
            resetOptions={filterEmployerOptionsValueList}
            filterName={'employernames'}
            handleOkayCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
            handleResetCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
          />
        </Col>
        {isBrokerAdmin && (
          <Col span={6}>
            <FilterDropDown
              ref={statusesRef}
              placeholder="Renewal Date"
              showSearch={false}
              showCustomButton={false}
              showSelectDeselect={true}
              optionSort={false}
              options={RENEWAL_DATE_OPTIONS}
              defaultOptions={filterValues.renewaldates}
              resetOptions={getRenewalDateDefaults()}
              filterName={'renewalDates'}
              handleOkayCallback={(values, filterName) => {
                handelOkayOrReset({
                  filterType: filterName,
                  values: values,
                });
              }}
              handleResetCallback={(values, filterName) => {
                handelOkayOrReset({
                  filterType: filterName,
                  values: values,
                });
              }}
            />
          </Col>
        )}
        <Col span={6}>
          <FilterDropDown
            ref={bidstatussRef}
            placeholder="Bid Status"
            showSearch={false}
            showCustomButton={false}
            showSelectDeselect={true}
            optionSort={false}
            options={BID_STATUSES}
            defaultOptions={filterValues.bidstatus}
            resetOptions={RESET_BID_STATUSES}
            filterName={'bidstatus'}
            handleOkayCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
            handleResetCallback={(values, filterName) => {
              handelOkayOrReset({
                filterType: filterName,
                values: values,
              });
            }}
          />
        </Col>
      </Row>
      {showSecondRow && (
        <Row gutter={12} className={styles.secondFilterRow}>
          {isBrokerAdmin && (
            <Col span={6}>
              <FilterDropDown
                ref={statusesRef}
                placeholder="Benefit Type"
                searchPlaceholder="Search Benefit Types"
                showSearch={true}
                showCustomButton={false}
                showSelectDeselect={true}
                optionSort={false}
                options={BENEFIT_TYPE_OPTIONS}
                defaultOptions={filterValues.categories}
                resetOptions={RESET_BENEFIT_TYPE_OPTIONS}
                filterName={'categories'}
                handleOkayCallback={(values, filterName) => {
                  handelOkayOrReset({
                    filterType: filterName,
                    values: values,
                  });
                }}
                handleResetCallback={(values, filterName) => {
                  handelOkayOrReset({
                    filterType: filterName,
                    values: values,
                  });
                }}
              />
            </Col>
          )}
          {isPlatformAdmin && (
            <Col span={6}>
              <FilterDropDown
                ref={categoriesRef}
                placeholder="Benefit Type"
                searchPlaceholder="Search Benefit Types"
                showSearch={true}
                showCustomButton={false}
                showSelectDeselect={true}
                optionSort={false}
                options={BENEFIT_TYPE_OPTIONS}
                defaultOptions={filterValues.categories}
                resetOptions={RESET_BENEFIT_TYPE_OPTIONS}
                filterName={'categories'}
                handleOkayCallback={(values, filterName) => {
                  handelOkayOrReset({
                    filterType: filterName,
                    values: values,
                  });
                }}
                handleResetCallback={(values, filterName) => {
                  handelOkayOrReset({
                    filterType: filterName,
                    values: values,
                  });
                }}
              />
            </Col>
          )}
          <Col span={6}>
            <FilterDropDown
              ref={typeRef}
              placeholder="Offer Type"
              showSearch={false}
              showCustomButton={false}
              showSelectDeselect={true}
              optionSort={false}
              options={BENEFIT_TYPES}
              defaultOptions={filterValues?.types}
              resetOptions={RESET_BENEFIT_TYPES}
              filterName={'types'}
              handleOkayCallback={(values, filterName) => {
                handelOkayOrReset({
                  filterType: filterName,
                  values: values,
                });
              }}
              handleResetCallback={(values, filterName) => {
                handelOkayOrReset({
                  filterType: filterName,
                  values: values,
                });
              }}
            />
          </Col>
          <Col span={6}>
            <FilterDropDown
              ref={carriersRef}
              placeholder="Carrier"
              searchPlaceholder="Search Carriers"
              showSearch={true}
              showCustomButton={false}
              showSelectDeselect={true}
              optionSort={false}
              options={filterCarrierOptionsList()}
              defaultOptions={filterValues?.carriers}
              resetOptions={filterCarrierOptionsValueList}
              filterName={'carriers'}
              handleOkayCallback={(values, filterName) => {
                handelOkayOrReset({
                  filterType: filterName,
                  values: values,
                });
              }}
              handleResetCallback={(values, filterName) => {
                handelOkayOrReset({
                  filterType: filterName,
                  values: values,
                });
              }}
              isLoading={carrierIsFetching}
            />
          </Col>
          <Col span={6}>
            <FilterDropDown
              ref={lastmodifiedRef}
              placeholder="Last Modified"
              showSearch={false}
              showCustomButton={false}
              showSelectDeselect={true}
              optionSort={false}
              options={LAST_MODIFIED_DATE_OPTIONS}
              defaultOptions={filterValues?.lastmodified}
              resetOptions={getLastDateDefaults()}
              handleOkayCallback={(values, filterName) => {
                handelOkayOrReset({
                  filterType: filterName,
                  values: values,
                });
              }}
              handleResetCallback={(values, filterName) => {
                handelOkayOrReset({
                  filterType: filterName,
                  values: values,
                });
              }}
              filterName={'lastmodified'}
            />
          </Col>
        </Row>
      )}

      <DataTable
        data={openOfferTableData?.content || []}
        columns={columns}
        pagination={true}
        pageSize={filterValues?.size}
        pageSizeOptions={[30, 50, 100]}
        getData={handlePaginationChange}
        total={openOfferTableData?.metadata.total}
        loading={isTableLoading}
      />
      <ConfirmationDialog
        confirmBtnFullWidth={true}
        title="Select Offer to View"
        confirmText="Close"
        width={710}
        onConfirm={toggleConfirmationDeletePopup}
        visible={ConfirmOpen}
      >
        <p>
          This offer has both a draft and published state. Select the offer to
          view below:
        </p>
        <DataTable data={offerPopupData(rowData) || []} columns={columnsTest} />
      </ConfirmationDialog>

      <ConfirmationDialog
        width={464}
        centered={true}
        title="Set Default Filter"
        confirmText="Save"
        cancelText="Cancel"
        isCancelLink={true}
        disableConfirmButton={selectedFilteredForDefault === null}
        closeModal={() => {
          setSelectedFilteredForDefault(null);
          setShowSetDefaultFilterModal(false);
        }}
        onConfirm={() => {
          setFilterAsDefaultFilter();
        }}
        visible={showSetDefaultFilterModal}
      >
        <div className={styles.defaultFilterModalContentWrapper}>
          <div className={styles.label}>Default Filter</div>

          <Select
            className={styles.setDefaultFilterSelectStyle}
            size="large"
            onSelect={(val: any) => {
              setSelectedFilteredForDefault(val);
            }}
          >
            {savedFilters?.map((obj) => (
              <Select.Option value={obj.name} key={obj.name}>
                {obj.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </ConfirmationDialog>
      <ConfirmationDialog
        width={464}
        centered={true}
        title="Remove Filter"
        confirmText="Yes - Remove Filter"
        cancelText="Cancel"
        isCancelLink={true}
        closeModal={() => {
          setShowRemoveFilterOptionModal({ visible: false, id: '' });
        }}
        onConfirm={() => {
          handleFilterItemDelete();
        }}
        visible={showRemoveFilterOptionModal.visible}
      >
        <div className={styles.modalContentWrapper}>
          Are you sure you want to remove this filter?{' '}
        </div>
      </ConfirmationDialog>
      <ConfirmationDialog
        width={464}
        centered={true}
        title="Save Filter"
        confirmText="Save"
        cancelText="Cancel"
        isCancelLink={true}
        closeModal={() => {
          form.resetFields();
          setShowSaveFilterModal(false);
        }}
        onConfirm={() => {
          handleFormSave();
        }}
        visible={showSaveFilterModal}
      >
        {isDuplicateFilterExist && (
          <AlertMessage
            type="error"
            message={'Filter name duplicated. Please try again'}
            closeAlert={() => {}}
            closeText={''}
            wrapperClassName={styles.duplicateErrorAlert}
          />
        )}
        <Form form={form} initialValues={{ remember: true }}>
          <div className={styles.modalContentWrapper}>
            Save this filter to access it at anytime from the filters dropdown
            menu.
            <div className={styles.label}>Filter Name</div>
            <Form.Item
              name={'filterName'}
              rules={[
                {
                  required: true,
                  message: 'Filter name required',
                  whitespace: true,
                },
              ]}
            >
              <Input className={styles.input} />
            </Form.Item>
            <Checkbox
              className={styles.checkBox}
              onChange={(record) => {
                setIsDefaultChecked(record.target.checked);
              }}
            >
              Set as my default filter
            </Checkbox>
          </div>
        </Form>
      </ConfirmationDialog>
    </div>
  );
};

export default OpenOffers;
