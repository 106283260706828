import { ChangeEvent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Popover, Switch } from 'antd';
import { DataColumn } from 'components/DataTable/DataColumn';
import ToolList from 'components/ToolList/ToolList';
import {
  useGetEmployersQuery,
  useUpdateEmployerFeatureStatusMutation,
} from 'api/featureControl';
import TablePagination from 'model/TablePagination';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import {
  BENEFIT_CONSULTATION_EMPLOYER_DISABLE_MESSAGE,
  BENEFIT_CONSULTATION_EMPLOYER_ENABLE_MESSAGE,
  EMPLOYER_ACCESS_LOCKED,
} from 'constants/commonConstants';
import EmployerFeatureToggle from 'components/EmployerFeatureToggle/EmployerFeatureToggle';
import EmployerVisibilityOption from 'components/EmployerVisibilityOption/EmployerVisibilityOption';

import styles from './featureList.module.less';

const EmployerBenConsultantList = () => {
  const { brokerId } = useParams();
  const [updateStatus] = useUpdateEmployerFeatureStatusMutation({});
  const [searchText, setSearchText] = useState<string>('');
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortOrder, setSortOrder] = useState<string>('name');
  const {
    data: featureWrappers = [],
    refetch: refetchEmployers,
    isFetching,
  } = useGetEmployersQuery({
    page: pageIndex,
    organizationId: brokerId as string,
    key: 'BENEFIT_CONSULTATION',
    query: searchText,
    size: pageSize,
    sort: sortOrder,
  });

  useEffect(() => {
    refetchEmployers();
  }, [refetchEmployers, searchText]);

  const dataColumns: DataColumn[] = [
    {
      title: 'EMPLOYER',
      dataIndex: 'employerName',
      key: 'employerName',
      width: '60%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.employerName.localeCompare(b.employerName),
      defaultSortOrder: 'ascend',
      render: (item, data, index) => {
        return (
          <OverflowPopover>
            <span className="text table-item-link">{item}</span>
          </OverflowPopover>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'lastUpdatedTs',
      key: 'lastUpdatedTs',
      width: '30%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (isEmpty(a.lastUpdatedTs)) {
          return 1;
        } else {
          return a.lastUpdatedTs.localeCompare(b.lastUpdatedTs);
        }
      },
      render: (colData, record) => {
        const updatedAt = dayjs(colData).format('MMMM D, YYYY h:mm A');
        return isEmpty(colData) ? (
          <div>-</div>
        ) : (
          <div>
            {updatedAt}
            <br />
            <span className={styles.addedBy}>
              by {record.lastUpdatedByAdminName}
            </span>
          </div>
        );
      },
    },
    {
      title: 'EMPLOYER VISIBILITY',
      dataIndex: 'employerVisibilityEnabled',
      key: 'employerVisibilityEnabled',
      width: '15%',
      render: (colData, record) => {
        return (
          <>
            <EmployerVisibilityOption
              onConfirmToggle={() => {
                updateStatus({
                  enabled: record.enabled,
                  employerVisibilityEnabled: !colData,
                  organizationId: record.organizationId,
                  employerId: record.employerId,
                  key: 'BENEFIT_CONSULTATION',
                }).then(() => {
                  refetchEmployers();
                });
              }}
              enabled={record.enabled}
              employerVisibilityEnabled={colData}
              employerName={record.employerName}
              toolName={'Benefits Consultation'}
            />
          </>
        );
      },
      align: 'left',
    },
    {
      title: 'ENABLED',
      dataIndex: 'enabled',
      key: 'enabled',
      width: '10%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (a.enabled) {
          return -1;
        } else {
          return 1;
        }
      },
      render: (colData, record) => {
        return (
          <>
            {record.locked ? (
              <Popover
                trigger="hover"
                content={EMPLOYER_ACCESS_LOCKED}
                placement="left"
                overlayClassName="provisionStatusPopover"
              >
                <Switch onChange={() => {}} checked={false} disabled={true} />
              </Popover>
            ) : (
              <EmployerFeatureToggle
                disabled={record.featureData}
                onConfirmToggle={() => {
                  updateStatus({
                    enabled: !colData,
                    employerVisibilityEnabled: record.employerVisibilityEnabled,
                    organizationId: record.organizationId,
                    employerId: record.employerId,
                    key: 'BENEFIT_CONSULTATION',
                  }).then(() => {
                    refetchEmployers();
                  });
                }}
                enabled={colData}
                recordName={record.employerName}
                enableMessage={BENEFIT_CONSULTATION_EMPLOYER_ENABLE_MESSAGE}
                disableMessage={BENEFIT_CONSULTATION_EMPLOYER_DISABLE_MESSAGE}
                title={'Benefits Consultation'}
              />
            )}
          </>
        );
      },
      align: 'left',
    },
  ];

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();

    setSearchText(_searchText);
  };

  useEffect(() => {
    setPaginationConfig(paginationConfig);
    setPageIndex(paginationConfig.paginationIndex);
    setPageSize(paginationConfig.filterInfo.limit);
    const { field, order } = (paginationConfig.sorterInfo as any) || {};
    if (isEmpty(order)) {
      setSortOrder('');
    } else if (field == 'employerName' && order == 'descend') {
      setSortOrder('-name');
    } else if (field == 'employerName' && order == 'ascend') {
      setSortOrder('name');
    }
  }, [paginationConfig, isFetching]);

  return (
    <div className={styles.featureTableWrapper}>
      <ToolList
        title="Benefits Consultation Configuration"
        columns={dataColumns}
        data={featureWrappers}
        hasBenefitsCategoryFilter={false}
        search={'Search Employers'}
        handleSearch={handleSearch}
        isLoading={isFetching}
      />
    </div>
  );
};

export default EmployerBenConsultantList;
