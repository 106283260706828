import { ReactNode } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import styles from './consultantWarnModal.module.less';

type ConsultantWarnModalProps = {
  title: any;
  visible: boolean;
  confirmLoading: boolean;
  warningMsg: ReactNode;
  onConfirm: () => void;
  closeModal: () => void;
};
const ConsultantWarnModal = ({
  title,
  onConfirm,
  closeModal,
  visible,
  warningMsg,
  confirmLoading,
}: ConsultantWarnModalProps) => {
  return (
    <ConfirmationDialog
      title={title}
      confirmText="Continue"
      cancelText="Cancel"
      closeModal={closeModal}
      onConfirm={onConfirm}
      visible={visible}
      modalClassName={styles.warningConfirmationModal}
      titleClassName={styles.warningTitle}
      confirmLoading={confirmLoading}
    >
      <div>
        {warningMsg}
        <p>Do you wish to continue?</p>
      </div>
    </ConfirmationDialog>
  );
};
export default ConsultantWarnModal;
