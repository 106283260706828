import { IndividualSubTypes } from 'constants/commonConstants';

export const AccountStatus = {
  SEND_INVITE: 'SEND_INVITE',
  INVITED: 'INVITED',
  ACCOUNT_ACTIVATED: 'ACCOUNT_ACTIVATED',
  INACTIVE: 'INACTIVE',
};

export const brokerAdminFormFields = {
  requiredFields: [
    'email',
    'firstName',
    'lastName',
    'location',
    'individualSubType',
  ],
  formFields: [
    'email',
    'firstName',
    'lastName',
    'phone',
    'title',
    'photoAvatar',
    'location',
    'individualSubType',
  ],
};

export const employerUserFormFields = {
  requiredFields: ['email', 'firstName', 'lastName', 'employerUserType'],
  formFields: [
    'email',
    'firstName',
    'lastName',
    'phone',
    'title',
    'photoAvatar',
    'employerUserType',
  ],
};

// Form validations
export const EMPTY_MESSAGE = '';

export const ACTIVE = 'Account Activated';

export const CONSULTANT_EMAIL_WARNING_MSG =
  'Editing the email address will reset the calendar sync and unassign the consultant from currently assigned employers.';

export const CONSULTANT_EMAIL_WARNING_MSG_IF_ASSIGN_TO_EMP = `Performing this action will turn off Broker Consultation for the following employers because they don’t have other consultants assigned to them:`;

export const CONSULTANT_LOCATION_WARNING_MSG =
  'If you are adding or removing locations, make sure that the correct employers are assigned to the consultant afterwards.';

export const CONSULTANT_EMAIL_WARNING_MSG_ER_ADMIN =
  'Performing this action will turn off Employer Consultation for this employer because no other consultants are assigned to it.';

export const CONSULTANT_EMPLOYER_LEVEL_ALL_CONFIRMATION =
  'Are you sure you want to unassign all consultants? Performing this action will turn off Broker Consultation for this employer.';

export const CONSULTANT_BROKER_LEVEL_EMPLOYER_CONFIRMATION =
  'Are you sure you want to unassign this consultant? Performing this action will turn off Broker Consultation for this employer because no other consultants are assigned to it.';

export const CONSULTANT_EMPLOYER_LEVEL_EMPLOYER_CONFIRMATION =
  'Are you sure you want to remove this consultant? Any already scheduled meetings with this consultant will remain and should be manually updated from this user’s calendar if needed. Performing this action will turn off Employer Consultation for this employer because no other employer consultants are assigned to it.';

export const CONSULTANT_EMPLOYER_LEVEL_EMPLOYER_NON_CONFIRMATION =
  'Are you sure you want to remove this consultant? Any already scheduled meetings with this consultant will remain and should be manually updated from this user’s calendar if needed.';

export const hasMutualLocations = (
  loginUserLocations: string[] | undefined,
  adminLocations: string[] | undefined,
  loginUserSubType: string | undefined,
  adminUserSubType: string | undefined
) => {
  if (
    IndividualSubTypes.OPS_ADMIN === loginUserSubType ||
    IndividualSubTypes.BROKER_ADMIN === loginUserSubType
  ) {
    if (adminUserSubType === IndividualSubTypes.SUPER_BROKER_ADMIN) {
      return true;
    }
    return adminLocations?.some((location) =>
      loginUserLocations?.includes(location)
    );
  }
  return true;
};
