import axios from 'axios';
import { baseApi, getPagingQueryString } from 'util/apiUtil';

export const getBrokerLocationList = async (organizationId: string) => {
  try {
    return await axios.get(`${baseApi}/v1/locations/list/${organizationId}`);
  } catch (error) {
    throw error;
  }
};

export const createBrokerLocation = async (data: any) => {
  return await axios.post(`${baseApi}/v1/locations`, data);
};

export const reassignBrokerLocation = async (locationId: string, data: any) => {
  return await axios.post(
    `${baseApi}/v2/locations/${locationId}/reassign`,
    data
  );
};

export const deleteBrokerLocation = async (locationId: string) => {
  return await axios.delete(`${baseApi}/v2/locations/${locationId}`);
};

export const updateBrokerLocationById = async (
  locationId: string,
  data: any
) => {
  return await axios.put(`${baseApi}/v1/locations/${locationId}`, data);
};

export const findBrokerLocationsByLoggedInUser = (organizationId: string) => {
  return axios.get(`${baseApi}/v2/locations/filter-by-organization`, {
    params: {
      organizationId,
    },
  });
};

export const getPaginatedBrokerLocationList = async (
  page: number,
  size: number,
  sort: string,
  query: string,
  organizationId: string
) => {
  return axios.get(
    `${baseApi}/v1/locations?${getPagingQueryString(
      page,
      size,
      sort,
      query,
      'query'
    )}`,
    {
      params: {
        organizationId: organizationId,
      },
    }
  );
};

export const findLocationById = async (id: string) => {
  return await axios.get(`${baseApi}/v1/locations/${id}`);
};

export const fetchBrokerLocationSummary = async (
  locationId: string,
  organizationId: string
) => {
  return await axios.get(
    `${baseApi}/v1/locations/${locationId}/organization/${organizationId}/summary`
  );
};
