import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import isEqual from 'lodash/isEqual';
import { AlertProps, Button, Col, Form, notification, Row } from 'antd';
import { isEmpty } from 'lodash';
import {
  removePlanDocuments,
  updateWeblinks,
  uploadPlanDocument,
  uploadRemovePlanDocument,
} from 'modules/plans/slices/planDocumentsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import BasicPlanInformation from 'modules/plans/life/components/BasicPlanInformation/BasicPlanInformation';
import ResourceInformation from 'modules/plans/components/ResourceInformation/ResourceInformation';
import AlertMessage from 'components/Alert/AlertMessage';
import PlanBasicForm from 'modules/plans/components/PlanBasicForm/PlanBasicForm';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import DisabilityPlanInformation from 'modules/plans/life/components/DisabilityPlanInformation/DisabilityPlanInformation';
import VoluntaryPlanInformation from 'modules/plans/life/components/VoluntaryPlanInformation/VoluntaryPlanInformation';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';

import {
  BenefitCategory,
  benefitGroupsChangedMsg,
  EMPTY_MESSAGE,
  FEATURE_KEYS,
  formVerificationMsg,
  planAlreadyExistsError,
  planYearChangedMsg,
  RENEWALS_STARTED_WARNING,
} from 'constants/commonConstants';
import {
  BasicPlans,
  PLAN_ADDITIONAL_DOCUMENT,
  VALIDATION_NAME_DUPLICATED,
  VoluntaryPlans,
} from 'modules/plans/constants';
import { LifePlan } from 'model/plans/LifePlan';
import { getCarriersByBenefitKind } from 'modules/plans/slices/basicPlanInfoSlice';
import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import {
  clearLifePlanApiErrors,
  findPlanById,
  getPlanByIdCompleted,
  saveLifePlan,
  updateLifePlanCompleted,
  updateLifePlanInProgress,
} from 'modules/plans/slices/lifePlanSlice';
import { getValidationMessage } from 'util/commonUtil';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  ERROR_MESSAGE_PLAN_EDITING,
  PLAN_NOTIFICATION_KEY,
  RENEWAL_COMMON_WARNING_MESSAGE_BASIC_INFO,
} from 'constants/benguideCollaborationConstants';
import BenefitCarrier from 'model/BenefitCarrier';
import FundingType from 'modules/plans/enums/FundingType';

import { Plan } from 'model/plans/Plan';
import { LifePlanFormData } from 'modules/plans/types/types';
import { IAddProposalToPlanYearState } from 'modules/renewals/types/renewalsTypes';
import useGetUserFeatureStatus from 'hooks/useGetUserFeatureStatus';
import FileType from 'model/plans/FileType';
import WebLinkType from 'model/plans/WebLinkType';
import AdditionalPlanResources from 'modules/plans/components/AdditionalPlanResources/AdditionalPlanResources';
import styles from './addLifePlan.module.less';

type AddLifePlanProps = {
  onReset: () => void;
  isCancelButtonClickConfirm: boolean;
  closePlanAddModal: () => void;
  lifePlanType: string;
  onSaveClose: Function;
  isEdit?: boolean;
  plan?: LifePlan;
  isSaveDisabled?: boolean;
  dbgPlanYear?: string;
  isDBGPlan?: boolean;
  isModalVisible?: boolean;
  isReviewProp?: boolean;
  isReviewType?: keyof IAddProposalToPlanYearState['plans'] | undefined;
  isReviewHighlight?: boolean;
  reviewNewPlanYear?: string;
  onFileUpload?: Function;
  onFileRemoveAction?: Function;
  documentReferences?: any;
  disableInformationFieldHighlight?: boolean;
  onPlanSave?: Function;
  reviewBenefitClasses?: string[];
  isRenewalProcessStarted?: boolean;
  actionTriggered?: Function;
};

const lifePlanDefaultValues = {
  planName: '',
  planYear: '',
  benefitCarrier: '',
  benefitClass: [],
  lifeBenefit: '',
  waitingPeriod: '',
  employeeBenefit: '',
  spouseBenefit: '',
  childBenefit: '',
  documents: {},
  fundingType: '',
  administrationFee: '',
  volume: undefined,
  enrollment: undefined,
};

export const planBasicLifeInfoFormFields = {
  requiredFields: [
    'planName',
    'planYear',
    'benefitClass',
    'benefitCarrier',
    'fundingType',
  ],
  formFields: [
    'planName',
    'planYear',
    'benefitCarrier',
    'benefitClass',
    'fundingType',
    'administrationFee',
    'groupId',
  ],
};

const AddLifePlan = forwardRef((props: AddLifePlanProps, ref) => {
  const {
    onReset,
    isCancelButtonClickConfirm,
    closePlanAddModal,
    lifePlanType,
    onSaveClose,
    isEdit = false,
    plan,
    isSaveDisabled = false,
    dbgPlanYear,
    isDBGPlan,
    isModalVisible,
    isReviewType,
    isReviewProp = false,
    isReviewHighlight = false,
    reviewNewPlanYear,
    onFileRemoveAction,
    onFileUpload,
    documentReferences,
    disableInformationFieldHighlight = false,
    onPlanSave,
    reviewBenefitClasses,
    isRenewalProcessStarted = false,
    actionTriggered,
  } = props;
  const [renwalWarningModalVisible, setRenewalWarningModalVisible] =
    useState<boolean>(false);
  const [requiredFieldError, setRequiredFieldError] = useState<boolean>(false);
  const [selectedCarrier, setSelectedCarrier] = useState<
    BenefitCarrier | undefined
  >();
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<AlertProps>({
    message: '',
    type: 'error',
  });
  const [formData, setFormData] = useState<LifePlanFormData>({
    ...lifePlanDefaultValues,
  });
  const [fieldValidationError, setFieldValidationError] =
    useState<boolean>(false);
  const [cancelButtonClicked, setCancelButtonClicked] =
    useState<boolean>(false);

  const [showWarning, setShowWarning] = useState<boolean>(false);

  const [selectedFileList, setSelectedFileList] = useState<FileType[]>([]);
  const [isDocRemoved, setIsDocRemoved] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedWeblink, setSelectedWeblink] = useState<WebLinkType[]>([]);

  const basicPlanInfo = useAppSelector((state) => state.plan.planBasicInfo);
  const { planYearsList } = useAppSelector((state) => state.employer.employer);
  const { lifePlanServiceList } = useAppSelector(
    (state) => state.plan.lifePlan
  );
  const plans = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.plans
  );
  const { inProgress: documentUploadInProgress } = useAppSelector(
    (state) => state.plan.planDocuments
  );
  const {
    inProgress,
    requestType,
    error,
    lifePlan,
    documentReferences: lifeDocumentReferences,
    planFeedback,
  } = useAppSelector((state) => state.plan.lifePlan);
  const [
    getUpcomingPlanYears,
    { isLoading: isUpcomingFetchLoading, data: upcomingData },
  ] = useLazyGetUpcomingPlanYearsByEmployerQuery();

  const isPlanYearInList = !!planYearsList?.find(
    (planYear) => planYear.id === plan?.planYearId || dbgPlanYear
  )?.current;

  const hasUpcomingPlanYearWithNullId = upcomingData?.upcomingPlanYears?.some(
    (obj: any) => obj.planYearId === null
  );

  const isRenewalStarted = !isEdit
    ? isRenewalProcessStarted
    : isPlanYearInList && hasUpcomingPlanYearWithNullId;

  const { employerId, brokerId } = useNavContext();
  const isAdvancedProfile = useGetUserFeatureStatus(
    brokerId,
    employerId,
    FEATURE_KEYS.ADVANCED_PROFILE,
    false
  );
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const { carriersList } = basicPlanInfo;

  const basicPlanInfoRef = useRef<any>();
  const basicInformationRef = useRef<any>();
  const disabilityInformationRef = useRef<any>();
  const voluntaryInformationRef = useRef<any>();
  const documentsRef = useRef<any>();
  const extraDocumentsRef = useRef<any>();
  const [notificationKey] = useState(PLAN_NOTIFICATION_KEY);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPlanYearWarning, setShowPlanYearWarning] =
    useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedFileList([]);
      setSelectedWeblink([]);
      setRequiredFieldError(false);
      setShowWarning(false);
      setShowPlanYearWarning(false);
      setFieldValidationError(false);
      setFormData({ ...lifePlanDefaultValues });
      basicPlanInfoRef.current?.resetForm();
      documentsRef.current?.resetAll();
      extraDocumentsRef.current?.resetAll();
      if (
        lifePlanType === BasicPlans.BASIC_ADD.value ||
        lifePlanType === BasicPlans.BASIC_LIFE.value ||
        lifePlanType === BasicPlans.BASIC_LIFE_AND_ADD.value
      ) {
        basicInformationRef.current.resetForm();
      } else if (
        lifePlanType === BasicPlans.STD.value ||
        lifePlanType === BasicPlans.LTD.value ||
        lifePlanType === VoluntaryPlans.VOLUNTARY_STD.value ||
        lifePlanType === VoluntaryPlans.VOLUNTARY_LTD.value
      ) {
        disabilityInformationRef.current.resetForm();
      } else if (
        lifePlanType === VoluntaryPlans.VOLUNTARY_LIFE.value ||
        lifePlanType === VoluntaryPlans.VOLUNTARY_ADD.value ||
        lifePlanType === VoluntaryPlans.VOLUNTARY_LIFE_ADD.value
      ) {
        voluntaryInformationRef.current.resetForm();
      }
    },
    isValidForm: async () => {
      await handleSubmit();
    },
  }));

  // Load plan data for edit mode
  useEffect(() => {
    if (isEdit && plan) {
      const {
        name,
        planYearId,
        benefitCarrier,
        groups,
        benefitMaximum,
        guaranteedIssue,
        multiplier,
        flatAmount,
        lifeRate,
        lifeBenefit,
        addRate,
        benefitPercentage,
        weeklyBenefitMax,
        waitingPeriod,
        benefitDurationSTD,
        benefitDurationLTD,
        rate,
        employeeBenefit,
        employeeGuaranteedIssue,
        spouseBenefit,
        spouseGuaranteedIssue,
        childBenefit,
        childGuaranteedIssue,
        monthlyBenefitMax,
        revision,
        documents,
        fundingType,
        administrationFee,
        sfcPlanConfigVo,
        groupId,
        volume,
        enrollment,
        annualEstimatedClaims,
        ageReduction,
        waiverOfPremium,
        acceleratedLifeBenefit,
        portability,
        definitionOfEarnings,
        additionalServices,
        definitionOfDisability,
        preExistingConditions,
        stateDisabilityIntegration,
        ownOccupationPeriod,
        w2Preparation,
        lifeServices,
        ltdServices,
        stdServices,
      } = plan;

      let sfcAdministrationFee;
      if (administrationFee === undefined) {
        sfcAdministrationFee = sfcPlanConfigVo?.administrationFee;
      }

      const editPlanData = {
        planName: name,
        planYear: planYearId,
        benefitCarrier: benefitCarrier ? benefitCarrier.id : '',
        benefitClass: groups,
        benefitMaximum,
        guaranteedIssue,
        multiplier,
        flatAmount,
        lifeRate,
        lifeBenefit,
        addRate,
        benefitPercentage,
        weeklyBenefitMax,
        waitingPeriod,
        benefitDurationSTD,
        benefitDurationLTD,
        rate,
        employeeBenefit,
        employeeGuaranteedIssue,
        spouseBenefit,
        spouseGuaranteedIssue,
        childBenefit,
        childGuaranteedIssue,
        monthlyBenefitMax,
        revision,
        documents,
        fundingType,
        administrationFee: administrationFee ?? sfcAdministrationFee,
        groupId: groupId,
        volume: volume,
        enrollment: enrollment,
        annualEstimatedClaims: annualEstimatedClaims,
        ageReduction,
        waiverOfPremium,
        acceleratedLifeBenefit,
        portability,
        definitionOfEarnings,
        additionalServices,
        definitionOfDisability,
        preExistingConditions,
        stateDisabilityIntegration,
        ownOccupationPeriod,
        w2Preparation,
        lifeServices: lifeServices,
        stdServices: stdServices,
        ltdServices: ltdServices,
      } as LifePlanFormData;

      setFormData(editPlanData);
      form.setFieldsValue({ ...editPlanData });
      setCancelButtonClicked(false);
      // For documents
      dispatch(getPlanByIdCompleted(plan));
    }
  }, [form, plan, isEdit, dispatch]);

  useEffect(() => {
    if (brokerId && employerId && isModalVisible) {
      dispatch(
        getCarriersByBenefitKind(
          BenefitCategory.LIFE.value,
          brokerId,
          employerId
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, brokerId, employerId, isModalVisible]);

  useEffect(() => {
    if (formData.benefitCarrier) {
      setSelectedCarrier(
        carriersList.find(
          (carrier: any) => carrier.id === formData.benefitCarrier
        )
      );
    }
  }, [carriersList, formData.benefitCarrier]);

  useEffect(() => {
    if (fieldValidationError) {
      setAlertMessage({ message: "Value can't be 0", type: 'error' });
    }
  }, [fieldValidationError]);

  useEffect(() => {
    if (error?.data?.code === VALIDATION_NAME_DUPLICATED) {
      form.setFields([{ name: 'planName', errors: [EMPTY_MESSAGE] }]);
      setAlertMessage({ message: planAlreadyExistsError, type: 'error' });
      setRequiredFieldError(true);
      dispatch(clearLifePlanApiErrors());
      setLoading(false);
    }
  }, [dispatch, error, form]);

  useEffect(() => {
    setRequiredFieldError(false);
    setShowWarning(false);
    setShowPlanYearWarning(false);
    if (
      isEdit &&
      !isReviewProp &&
      !isEqual(plan?.groups, formData.benefitClass)
    ) {
      setShowPlanYearWarning(false);
      setShowWarning(true);
      setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
      setRequiredFieldError(true);
    }
    if (isEdit && !isEqual(plan?.planYearId, formData.planYear?.trim())) {
      setShowPlanYearWarning(true);
      setAlertMessage({ message: planYearChangedMsg, type: 'warning' });
      setRequiredFieldError(true);
    }
    if (
      isEdit &&
      !isReviewProp &&
      !isEqual(plan?.planYearId, formData.planYear?.trim()) &&
      !isEqual(plan?.groups, formData.benefitClass)
    ) {
      setShowPlanYearWarning(true);
      setShowWarning(true);
      setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
      setRequiredFieldError(true);
    }
  }, [isEdit, plan, formData.benefitClass, formData.planYear, isReviewProp]);

  const onPlanChange = useCallback(
    (plan: LifePlan) => {
      dispatch(updateLifePlanCompleted(plan));
    },
    [dispatch]
  );

  const validateBasicInfo = async () => {
    try {
      let validateFields;
      if (isReviewProp) {
        validateFields = planBasicLifeInfoFormFields.requiredFields.filter(
          (item) => item !== 'planYear'
        );
      } else {
        validateFields = planBasicLifeInfoFormFields.requiredFields;
      }
      await form.validateFields(validateFields);
      return true;
    } catch (errorInfo: any) {
      return errorInfo.errorFields.length === 0;
    }
  };

  const getValidationResult = async () => {
    const isFormValid = await validateBasicInfo();
    if (isFormValid) {
      return true;
    } else {
      setRequiredFieldError(true);
      return false;
    }
  };

  const onSave = async (id?: string, isSuccess?: boolean) => {
    await uploadDocument(id, isSuccess);
  };

  const getPlanData = () => {
    const benefitCategory = BenefitCategory.LIFE.value;
    const selectedPlanYear = planYearsList.find(
      (t) => t.id === formData.planYear
    );
    const carrierId = formData.benefitCarrier;
    const planData = {
      id: isEdit && plan ? plan.id : null,
      benefitKind: lifePlanType,
      benefitCarrier: {
        name: carrierId ? selectedCarrier?.name : null,
        benefitCategory: benefitCategory,
        id: carrierId ? selectedCarrier?.id : null,
      },
      employerId: employerId,
      endDate: selectedPlanYear?.endDate,
      name: formData.planName,
      planYearId: selectedPlanYear?.id,
      startDate: selectedPlanYear?.startDate,
      groups: formData.benefitClass,
      multiplier: formData.multiplier?.toString().trim(),
      flatAmount: formData.flatAmount?.toString().trim(),
      benefitMaximum: formData.benefitMaximum,
      guaranteedIssue: formData.guaranteedIssue,
      lifeRate: formData.lifeRate,
      lifeBenefit: formData.lifeBenefit,
      addRate: formData.addRate,
      benefitPercentage: formData.benefitPercentage,
      weeklyBenefitMax: formData.weeklyBenefitMax,
      monthlyBenefitMax: formData.monthlyBenefitMax,
      waitingPeriod: formData.waitingPeriod,
      benefitDurationSTD: formData.benefitDurationSTD,
      benefitDurationLTD: formData.benefitDurationLTD,
      rate: formData.rate,
      employeeBenefit: formData.employeeBenefit,
      employeeGuaranteedIssue: formData.employeeGuaranteedIssue,
      spouseBenefit: formData.spouseBenefit,
      spouseGuaranteedIssue: formData.spouseGuaranteedIssue,
      childBenefit: formData.childBenefit,
      childGuaranteedIssue: formData.childGuaranteedIssue,
      documentReferences: lifeDocumentReferences,
      documents: formData.documents,
      latest: true,
      fundingType: formData.fundingType,
      groupId: formData.groupId?.trim(),
      volume: formData.volume,
      enrollment: formData.enrollment,
      administrationFee: formData.administrationFee,
      annualEstimatedClaims: formData.annualEstimatedClaims,
      textractJobId: lifePlan?.textractJobId,
      planFeedback: planFeedback,
      portability: formData.portability,
      acceleratedLifeBenefit: formData.acceleratedLifeBenefit,
      waiverOfPremium: formData.waiverOfPremium,
      definitionOfEarnings: formData.definitionOfEarnings,
      ageReduction: formData.ageReduction?.trim(),
      lifeServices: lifePlanServiceList,
      stdServices: lifePlanServiceList,
      ltdServices: lifePlanServiceList,
      additionalServices: formData.additionalServices,
      definitionOfDisability: formData.definitionOfDisability,
      preExistingConditions: formData.preExistingConditions,
      stateDisabilityIntegration: formData.stateDisabilityIntegration,
      ownOccupationPeriod: formData.ownOccupationPeriod,
      w2Preparation: formData.w2Preparation,
    } as LifePlan;
    if (formData.fundingType === FundingType.SELF_FUNDED) {
      planData.sfcPlanConfigVo = {
        employerId: planData.employerId,
        planYearId: planData.planYearId,
        carrierId: planData.benefitCarrier?.id,
        benefitKind: planData.benefitKind,
        administrationFee: formData.administrationFee,
      };
      planData.sfcPlanDataVO = {
        selfFundedClaimsStatus: true,
      };
    } else {
      planData.sfcPlanDataVO = {
        selfFundedClaimsStatus: false,
      };
    }

    if (formData.fundingType === FundingType.SELF_FUNDED) {
      planData.sfcPlanConfigVo = {
        employerId: planData.employerId,
        planYearId: planData.planYearId,
        carrierId: planData.benefitCarrier?.id,
        benefitKind: planData.benefitKind,
        administrationFee: formData.administrationFee,
      };
      planData.sfcPlanDataVO = {
        selfFundedClaimsStatus: true,
      };
    } else {
      planData.sfcPlanDataVO = {
        selfFundedClaimsStatus: false,
      };
    }

    return planData;
  };

  const isDuplicate = (updated: Plan) => {
    const prevArr = plans[isReviewType!];
    const previousPlan = plan;

    if (previousPlan?.name !== updated?.name) {
      if (
        prevArr
          .filter(
            (plan) =>
              plan.planType === previousPlan?.benefitKind &&
              plan.planName?.toLowerCase() != previousPlan?.name?.toLowerCase()
          )
          .some((plan: any) => plan.planName === updated?.name)
      ) {
        form.setFields([{ name: 'planName', errors: [EMPTY_MESSAGE] }]);
        return true;
      }
    }
    return false;
  };

  const handleSubmit = async () => {
    const isFormValid = await getValidationResult();

    setRequiredFieldError(false);
    setShowWarning(false);
    setShowPlanYearWarning(false);

    const planData = getPlanData();

    if (isFormValid && !fieldValidationError) {
      if (isReviewProp) {
        if (isDuplicate(planData)) {
          setRequiredFieldError(true);
          setAlertMessage({
            message: planAlreadyExistsError,
            type: 'error',
          });
          throw error;
        }
        return onPlanSave?.(planData);
      }

      if (isEdit && isDBGPlan) {
        setLoading(true);
      }
      if (isRenewalStarted && !isEdit) {
        setRenewalWarningModalVisible(true);
      } else {
        await dispatch(
          saveLifePlan(planData, (isSuccess?: boolean, id?: string) =>
            onSave(id, isSuccess)
          )
        );
        notification.close(notificationKey);
      }
    } else if (isFormValid && fieldValidationError) {
      setFieldValidationError(true);
      if (isReviewProp) {
        throw Error;
      }
    } else {
      setRequiredFieldError(true);
      setAlertMessage({ message: formVerificationMsg, type: 'error' });
      if (isReviewProp) {
        throw Error;
      }
    }
  };

  const handleCancelClick = () => {
    setCancelButtonClicked(true);
    onReset();
  };

  const onValidateFails = (validateSetting: string) => {
    setAlertMessage({
      message: getValidationMessage(validateSetting),
      type: 'error',
    });
    setRequiredFieldError(true);
  };

  useEffect(() => {
    if (employerId) {
      getUpcomingPlanYears({ employerId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerId, isEdit]);

  const handleRemoveDocument = (docType: string) => {
    const clonedDocuments = { ...formData.documents };
    delete clonedDocuments[docType];
    setFormData((prevData) => ({ ...prevData, documents: clonedDocuments }));
  };

  const planInformationSection = () => {
    switch (lifePlanType) {
      case BasicPlans.BASIC_ADD.value:
      case BasicPlans.BASIC_LIFE.value:
      case BasicPlans.BASIC_LIFE_AND_ADD.value:
        return (
          <BasicPlanInformation
            isCancelButtonClickConfirm={isCancelButtonClickConfirm}
            setFormData={setFormData}
            formData={formData}
            lifePlanType={lifePlanType}
            ref={basicInformationRef}
            isEditMode={isEdit}
            setAlertMessage={setAlertMessage}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            isReviewHighlight={
              isReviewHighlight && !disableInformationFieldHighlight
            }
            isAdvancedProfile={isAdvancedProfile ?? false}
          />
        );
      case BasicPlans.STD.value:
      case BasicPlans.LTD.value:
      case VoluntaryPlans.VOLUNTARY_STD.value:
      case VoluntaryPlans.VOLUNTARY_LTD.value:
        return (
          <DisabilityPlanInformation
            isCancelButtonClickConfirm={isCancelButtonClickConfirm}
            setFormData={setFormData}
            formData={formData}
            lifePlanType={lifePlanType}
            ref={disabilityInformationRef}
            isEditMode={isEdit}
            cancelButtonClicked={cancelButtonClicked}
            isReviewHighlight={
              isReviewHighlight && !disableInformationFieldHighlight
            }
            fundingType={formData.fundingType}
            isReview={isReviewProp}
            isAdvancedProfile={isAdvancedProfile ?? false}
          />
        );
      case VoluntaryPlans.VOLUNTARY_LIFE.value:
      case VoluntaryPlans.VOLUNTARY_ADD.value:
      case VoluntaryPlans.VOLUNTARY_LIFE_ADD.value:
        return (
          <VoluntaryPlanInformation
            isCancelButtonClickConfirm={isCancelButtonClickConfirm}
            setFormData={setFormData}
            formData={formData}
            lifePlanType={lifePlanType}
            ref={voluntaryInformationRef}
            isEditMode={isEdit}
          />
        );
    }
  };

  const getPlanDefaultName = (lifePlanType: string) => {
    switch (lifePlanType) {
      case BasicPlans.BASIC_LIFE.value:
        return BasicPlans.BASIC_LIFE.label;
      case BasicPlans.BASIC_ADD.value:
        return BasicPlans.BASIC_ADD.label;
      case BasicPlans.BASIC_LIFE_AND_ADD.value:
        return BasicPlans.BASIC_LIFE_AND_ADD.label;
      case BasicPlans.STD.value:
        return BasicPlans.STD.label;
      case BasicPlans.LTD.value:
        return BasicPlans.LTD.label;
      case VoluntaryPlans.VOLUNTARY_LIFE.value:
        return VoluntaryPlans.VOLUNTARY_LIFE.label;
      case VoluntaryPlans.VOLUNTARY_ADD.value:
        return VoluntaryPlans.VOLUNTARY_ADD.label;
      case VoluntaryPlans.VOLUNTARY_LIFE_ADD.value:
        return VoluntaryPlans.VOLUNTARY_LIFE_ADD.label;
      case VoluntaryPlans.VOLUNTARY_STD.value:
        return VoluntaryPlans.VOLUNTARY_STD.label;
      case VoluntaryPlans.VOLUNTARY_LTD.value:
        return VoluntaryPlans.VOLUNTARY_LTD.label;
      default:
        return '';
    }
  };

  const uploadDocument = async (id?: string, isSuccess?: boolean) => {
    const documentUploadSuccess = () => {
      setSelectedFileList([]);
      setSelectedWeblink([]);
      closePlanAddModal();
      onSaveClose(isSuccess);
      if (plan && plan.id) {
        dispatch(findPlanById(plan.id));
      }
    };

    const planId = id ?? (plan?.id as string);
    const removeDocs = Object.keys(isDocRemoved)
      .filter((documentType) => isDocRemoved[documentType])
      .map((documentType) => ({
        docType: PLAN_ADDITIONAL_DOCUMENT,
        planDocumentName: documentType,
      }));
    if (!isEmpty(selectedFileList) && isEmpty(removeDocs)) {
      await dispatch(
        uploadPlanDocument(selectedFileList, planId, lifePlanType)
      );
    } else if (!isEmpty(removeDocs) && isEmpty(selectedFileList)) {
      await dispatch(removePlanDocuments(planId, lifePlanType, removeDocs));
    } else if (!isEmpty(removeDocs) && !isEmpty(selectedFileList)) {
      await dispatch(
        uploadRemovePlanDocument(
          selectedFileList,
          planId,
          lifePlanType,
          removeDocs
        )
      );
    }
    if (selectedWeblink.length > 0) {
      await dispatch(updateWeblinks(selectedWeblink, planId, lifePlanType));
    }
    documentUploadSuccess();
  };

  return (
    <div className={isEdit ? styles.editWrapper : styles.wrapper}>
      {(requiredFieldError || fieldValidationError) && (
        <AlertMessage
          type={alertMessage.type}
          message={alertMessage.message}
          closeAlert={() => {
            setRequiredFieldError(false);
          }}
          wrapperClassName={styles.alertWrapper}
        />
      )}
      {isSaveDisabled && !plan && (
        <FixedAlertMessage
          type={'error'}
          message={ERROR_MESSAGE_PLAN_EDITING}
        />
      )}
      {isEdit && isRenewalStarted && (
        <FixedAlertMessage
          type={'warning'}
          message={RENEWAL_COMMON_WARNING_MESSAGE_BASIC_INFO}
        />
      )}
      <PlanBasicForm
        benefitCategory={BenefitCategory.LIFE.value}
        carriersList={carriersList}
        setRequiredFieldError={setRequiredFieldError}
        setFormData={setFormData}
        formData={formData}
        form={form}
        enableFundingType={lifePlanType === BasicPlans.STD.value}
        ref={basicPlanInfoRef}
        isPlanUpdateMode={isEdit}
        showWarning={showWarning && !isReviewProp}
        dbgPlanYear={dbgPlanYear}
        isDBGPlan={isDBGPlan}
        isModalVisible={isModalVisible}
        showPlanYearWarning={showPlanYearWarning}
        defaultPlanName={getPlanDefaultName(lifePlanType)}
        reviewNewPlanYear={reviewNewPlanYear}
        isReviewHighlight={isReviewHighlight}
        isHightlightCarrier={isReviewHighlight}
        reviewBenefitClasses={reviewBenefitClasses}
        isReview={isReviewProp}
        lifePlanType={lifePlanType}
      />
      {planInformationSection()}
      <ResourceInformation
        planType={lifePlanType}
        onPlanChange={onPlanChange}
        lifePlanDocsRef={documentsRef}
        isEditMode={isEdit}
        removeUploadedDocument={handleRemoveDocument}
        documents={formData.documents}
        planId={plan?.id}
        onValidateFails={onValidateFails}
        isSaveDisabled={isSaveDisabled}
        onFileRemoveAction={onFileRemoveAction}
        onFileUpload={
          onFileUpload
            ? (...args: any[]) => {
                onPlanSave?.(getPlanData());
                onFileUpload(...args);
              }
            : undefined
        }
        documentReferences={documentReferences}
        isTitleVisible
      />
      <AdditionalPlanResources
        ref={ref}
        plan={plan}
        benefitKind={lifePlanType}
        isCloseConfirmed={isCancelButtonClickConfirm}
        selectedFileList={selectedFileList}
        setSelectedFileList={setSelectedFileList}
        selectedWebLinkList={selectedWeblink}
        setSelectedWebLinkList={setSelectedWeblink}
        setIsDocRemoved={setIsDocRemoved}
        actionTriggered={actionTriggered}
      />
      {!isReviewProp ? (
        isEdit ? (
          <div className={styles.editButtons}>
            <Button onClick={handleCancelClick} className={styles.cancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              loading={
                (inProgress && requestType === updateLifePlanInProgress.type) ||
                loading
              }
              onClick={handleSubmit}
              disabled={inProgress || isSaveDisabled}
            >
              Done
            </Button>
          </div>
        ) : (
          <>
            <div className={styles.btnWrapper}>
              <SubmitButton
                disabled={
                  inProgress ||
                  isSaveDisabled ||
                  isUpcomingFetchLoading ||
                  documentUploadInProgress
                }
                loading={
                  (inProgress &&
                    requestType === updateLifePlanInProgress.type) ||
                  documentUploadInProgress
                }
                type="primary"
                onClick={handleSubmit}
              >
                Save
              </SubmitButton>
            </div>
            <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
          </>
        )
      ) : (
        ''
      )}
      {!isEdit && !isReviewProp && (
        <ConfirmationDialog
          modalClassName={styles.deleteConfirmationModal}
          title={
            <Col span={24}>
              <Row justify="center">Add Plan</Row>
              <Row justify="center">
                <span className={styles.subHeading}>
                  {formData?.planName ?? ''}
                </span>
              </Row>
            </Col>
          }
          confirmText={'Add Plan'}
          cancelText="Cancel"
          isCloseOnly={false}
          closeModal={() => {
            setRenewalWarningModalVisible(false);
          }}
          onConfirm={async () => {
            const planData = getPlanData();
            await dispatch(
              saveLifePlan(planData, (isSuccess?: boolean, id?: string) =>
                onSave(id, isSuccess)
              )
            );
            notification.close(notificationKey);
            setRenewalWarningModalVisible(false);
          }}
          visible={renwalWarningModalVisible}
          confirmLoading={
            inProgress || isSaveDisabled || isUpcomingFetchLoading
          }
          centered
          destroyOnClose
        >
          <p>{RENEWALS_STARTED_WARNING}</p>
        </ConfirmationDialog>
      )}
    </div>
  );
});

AddLifePlan.displayName = 'AddLifePlan';

export default AddLifePlan;
