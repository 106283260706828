import { ReactNode } from 'react';
import { Button, Col, Row } from 'antd';
import PromptType from 'modules/assistant/enums/PromptRenderType';
import styles from './selectionCard.module.less';

export type SelectionCardInterface = {
  title: string;
  description: string;
  icon: ReactNode;
  type: PromptType;
  onClick: () => void;
};
const SelectionCard = (props: SelectionCardInterface) => {
  const { title, description, icon, onClick } = props;

  return (
    <Button className={styles.card} onClick={onClick}>
      <Row gutter={[16, 16]} align="top" justify="start">
        <Col span={24}>
          <Row align="middle" gutter={[8, 8]}>
            <Col span={4} className={styles.cardIcon}>
              {icon}
            </Col>
            <Col span={20} className={styles.cardTitle}>
              {title}
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={4}></Col>
            <Col span={20} className={styles.cardText}>
              {description}
            </Col>
          </Row>
        </Col>
      </Row>
    </Button>
  );
};
export default SelectionCard;
