import { FC, useEffect, useMemo } from 'react';
import { LifePlan } from 'model/plans/LifePlan';
import EditPlanModal from 'modules/plans/components/EditPlanModal/EditPlanModal';
import AddLifePlan from 'modules/plans/life/components/AddLifePlan/AddLifePlan';
import { BasicPlans, VoluntaryPlans } from 'modules/plans/constants';
import { clearDocuments } from 'modules/plans/slices/lifePlanSlice';
import { useAppDispatch } from 'hooks/redux';

type Props = {
  visible: boolean;
  onCancel: () => void;
  plan: LifePlan;
  onSave: () => void;
  isSaveDisabled?: boolean;
  isDBGPlan?: boolean;
  fromReview?: boolean;
  benefitType?: any;
};

const EditLifePlan: FC<Props> = (props) => {
  const {
    visible,
    onCancel,
    plan,
    onSave,
    isSaveDisabled = false,
    isDBGPlan,
    benefitType,
    fromReview = false,
  } = props;

  const dispatch = useAppDispatch();

  const planTypeLabel = useMemo(() => {
    if (Object.keys(BasicPlans).includes(plan.benefitKind)) {
      return BasicPlans[plan.benefitKind].label;
    }
    if (Object.keys(VoluntaryPlans).includes(plan.benefitKind)) {
      return VoluntaryPlans[plan.benefitKind].label;
    }
    return '';
  }, [plan.benefitKind]);

  useEffect(() => {
    if (!visible) {
      dispatch(clearDocuments());
    }
  }, [visible, dispatch]);

  return (
    <EditPlanModal
      visible={visible}
      onCancel={onCancel}
      title={`Edit ${planTypeLabel} Plan`}
      width={700}
      isSaveDisabled={isSaveDisabled}
    >
      <AddLifePlan
        isEdit
        onReset={onCancel}
        isCancelButtonClickConfirm={true}
        closePlanAddModal={onCancel}
        lifePlanType={fromReview ? benefitType : plan.benefitKind}
        plan={plan}
        onSaveClose={onSave}
        isSaveDisabled={isSaveDisabled}
        isDBGPlan={isDBGPlan}
        isReviewProp={fromReview}
        isModalVisible={visible}
      />
    </EditPlanModal>
  );
};

export default EditLifePlan;
