export const benefitTypes: { [key: string]: { value: string; label: string } } =
  {
    MEDICAL: { value: 'MEDICAL', label: 'Medical' },
    TELEHEALTH: { value: 'TELEHEALTH', label: 'Telehealth' },
    RX_DELIVERY_RX_COUPONS: {
      value: 'RX_DELIVERY_RX_COUPONS',
      label: 'Rx Delivery/Coupons',
    },
    DENTAL: { value: 'DENTAL', label: 'Dental' },
    VISION: { value: 'VISION', label: 'Vision' },
    LIFE: { value: 'LIFE', label: 'Life & Disability' },
    VOLUNTARY_BENEFIT: {
      value: 'VOLUNTARY_BENEFIT',
      label: 'Voluntary Benefits',
    },
    WELLBEING: { value: 'WELLBEING', label: 'Wellbeing' },
    RETIREMENT: { value: 'RETIREMENT', label: '401(k)' },
    SAVING: { value: 'SAVING', label: 'Tax Advantaged Accounts' },
    HOLIDAYS_AND_TIME_OFF: {
      value: 'HOLIDAYS_AND_TIME_OFF',
      label: 'Holiday & Time Off',
    },
    FAMILY_AND_LEAVE: { value: 'FAMILY_AND_LEAVE', label: 'Family & Leave' },
    ADDITIONAL_PERK: { value: 'ADDITIONAL_PERK', label: 'Additional Perks' },
  };

export const DUPLICATE_CARRIER_ERROR_CODE = 'carrier name already exists';
export const DUPLICATE_BENEFIT_CARRIER_ERROR_CODE =
  'Benefit carrier name already exists';

export const CARRIERS = 'Carriers';
export const CARRIER_CONTACTS = 'Contacts';
export const CARRIER_BENEFIT_CATEGORY_TEXT =
  'If you know this carrier covers other benefit types, please select them below';
