import { useEffect, useRef, useState } from 'react';
import { Col, notification, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { SettingOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import ClaimUpload from 'modules/claims/pages/ClaimUpload';
import ClaimsView from 'modules/claims/pages/ClaimsView';
import {
  useDeleteClaimFIleMutation,
  useGetClaimsFileHistoryQuery,
} from 'modules/claims/slices/claimsSlice';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { useNavContext } from 'hooks/useNavContext';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import ClaimsFileHistory from 'modules/claims/components/ClaimsFileHistory/ClaimsFileHistory';
import EmployerFeatureDisabledBanner from 'modules/tools/components/EmployerFeatureDisabledBanner/EmployerFeatureDisabledBanner';
import { FEATURE_KEYS } from 'constants/commonConstants';
import IconCheck from 'assets/images/icon-check.svg';

import styles from './claimsHomePage.module.less';

const ClaimsHomePage = () => {
  const params = useParams();
  const { employer } = useNavContext();
  const {
    data: fileHistory,
    isFetching,
    refetch,
  } = useGetClaimsFileHistoryQuery({
    employerId: params.employerId,
  });

  const [
    deleteFile,
    {
      isLoading: claimsDeleteInProgress,
      isError: claimDeleteFailed,
      isSuccess: claimFileDeleted,
      isUninitialized: claimsDeletedStarted,
    },
  ] = useDeleteClaimFIleMutation();

  const claimUploadRef = useRef();
  const [isClaimManageOpen, setIsClaimManageOpen] = useState<boolean>(false);
  const [isClaimManageModalOpen, setIsClaimManageModalOpen] =
    useState<boolean>(false);
  const [isUploaderOpen, setIsUploaderOpen] = useState<boolean>(false);
  const [fileToDelete, setFileToDelete] = useState('');
  const [isOpenDeleteFileConfirmation, setOpenDeleteFileConfirmation] =
    useState<boolean>(false);
  const [isEmptyFileList, setEmptyFileList] = useState<boolean>(false);

  const { current = {} } = claimUploadRef as any;
  const {
    claimPublishPending,
    claimsPublished,
    claimsPublishFailed,
    claimPublishInProgress,
  } = current || {};

  useEffect(() => {
    if (!claimPublishPending && claimsPublished) {
      if (!claimsPublishFailed && !claimPublishInProgress) {
        refetch();
      }
    }
  }, [
    claimPublishInProgress,
    claimPublishPending,
    claimsPublishFailed,
    claimsPublished,
    refetch,
  ]);

  useEffect(() => {
    if (!claimsDeletedStarted && claimFileDeleted) {
      if (!claimDeleteFailed && !claimsDeleteInProgress) {
        notification.open({
          message: 'File Deleted',
          description: `The file has been successfully deleted.`,
          placement: 'topRight',
          duration: 3,
          className: styles.notification,
          closeIcon: <></>,
          key: 'claimFileDeleted',
          icon: (
            <img className={styles.folderDownloadIcon} src={IconCheck} alt="" />
          ),
        });
        cancelClaimDeleteConfirmation();
        if (fileHistory && fileHistory.length === 1) {
          setIsClaimManageOpen(false);
          setIsClaimManageModalOpen(false);
        }
        refetch();
      }
    }
    // eslint-disable-next-line
  }, [
    claimDeleteFailed,
    claimFileDeleted,
    claimsDeleteInProgress,
    claimsDeletedStarted,
    refetch,
  ]);

  useEffect(() => {
    fileHistory && fileHistory.length == 0
      ? setEmptyFileList(true)
      : setEmptyFileList(false);
  }, [fileHistory]);

  const onClickImportClaims = () => {
    setIsClaimManageOpen(false);
    setIsUploaderOpen(isClaimManageOpen);
  };

  const cancelClaimDeleteConfirmation = () => {
    setFileToDelete('');
    setOpenDeleteFileConfirmation(false);
  };

  const openDeleteClaimsFileModal = (fileId: string) => {
    setFileToDelete(fileId);
    setOpenDeleteFileConfirmation(true);
  };

  const getClaimDeleteConfirmation = () => {
    return (
      <ConfirmationDialog
        confirmText="Yes - Delete Claims File"
        onConfirm={() => {
          deleteFile({ employerId: params.employerId, fileId: fileToDelete });
        }}
        visible={isOpenDeleteFileConfirmation && Boolean(fileToDelete)}
        onCancel={cancelClaimDeleteConfirmation}
        closeModal={cancelClaimDeleteConfirmation}
        title="Delete Claims File"
        cancelText="Cancel"
        confirmLoading={claimsDeleteInProgress}
      >
        <p className={styles.claimDeleteMessage}>
          <div>
            Are you sure you want to delete this claims file? This action cannot
            be undone.
          </div>
        </p>
      </ConfirmationDialog>
    );
  };

  if (isFetching && !isClaimManageOpen) {
    return <></>;
  }

  return (
    <>
      <EmployerFeatureDisabledBanner
        feature={FEATURE_KEYS.CLAIMS}
        featureName="Claims"
        employerId={params.employerId}
        brokerId={params.brokerId}
      />
      <div className={styles.claimsHomePage}>
        <div className={styles.claimPageTitle}>
          <div className={styles.claimsEmployerName}>
            <h1>{employer?.name} Claims</h1>
          </div>
          <div className={styles.claimsManage}>
            {!isEmpty(fileHistory) && (
              <PageActionButton
                type="primary"
                onClick={() => {
                  setIsClaimManageOpen(true);
                }}
                className={styles.claimsManageActionButton}
              >
                <SettingOutlined /> Manage Claims
              </PageActionButton>
            )}
          </div>
        </div>
        <Row>
          <Col span={24} className={styles.claimsHomeContent}>
            {isEmpty(fileHistory) || isUploaderOpen ? (
              <ClaimUpload
                ref={claimUploadRef}
                isUploaderOpen={isUploaderOpen}
                setIsUploaderOpen={setIsUploaderOpen}
                openManageClaimsModel={setIsClaimManageOpen}
                isEmptyFileHistory={isEmptyFileList}
                isNoClaimApplicable={true}
              />
            ) : (
              <ClaimsView
                preview={false}
                displayDummyData={false}
                claimManageOpen={isClaimManageOpen}
                claimsFileDeleted={!claimsDeleteInProgress && claimFileDeleted}
              />
            )}
          </Col>
        </Row>
        <FullScreenModal
          visible={isClaimManageOpen}
          onCancel={() => {
            setIsClaimManageOpen(false);
            setIsClaimManageModalOpen(!isClaimManageModalOpen);
          }}
          footer={false}
          title="Manage Claims"
        >
          <ClaimsFileHistory
            openImportClaims={onClickImportClaims}
            closeManageClaimsModal={setIsClaimManageOpen}
            isClosed={isClaimManageModalOpen}
            openDeleteClaimsFileModal={openDeleteClaimsFileModal}
          />
          {getClaimDeleteConfirmation()}
        </FullScreenModal>
      </div>
    </>
  );
};
export default ClaimsHomePage;
