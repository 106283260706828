import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import iconWarning from 'assets/images/icon-warning.svg';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

import { FORMAT_VALIDATE, SIZE_VALIDATE } from 'util/commonUtil';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import styles from './documentUploader.module.less';

type DocumentUploaderProps = {
  onChange: Function;
  onRemove: Function;
  allowedFileTypes?: string;
  onFileNameClick: Function;
  maxFileSizeMB: number;
  isLoading?: boolean;
  isRemoved?: boolean;
  defaultFile?: File | null;
  hideNoFileMessage: boolean;
  onValidateFails?: (validateSetting: string) => void;
  disabled?: boolean;
  uploadError: any;
};
const emptyFile = new File([''], '');

const DocumentUploader = forwardRef((props: DocumentUploaderProps, ref) => {
  const {
    onChange,
    onRemove,
    allowedFileTypes,
    maxFileSizeMB,
    isLoading = false,
    onFileNameClick,
    isRemoved = false,
    defaultFile,
    hideNoFileMessage,
    onValidateFails,
    disabled = false,
    uploadError,
  } = props;

  const [file, setFile] = useState<File>(emptyFile);

  const fileInput = useRef<HTMLInputElement>(null);

  const showNoFileSelectedMessage = hideNoFileMessage
    ? !hideNoFileMessage
    : true;

  useEffect(() => {
    if (isRemoved) {
      setFile(emptyFile);
    }
  }, [isRemoved]);

  useEffect(() => {
    if (defaultFile && !file.name) {
      setFile(defaultFile);
    }
  }, [defaultFile, file.name]);

  const uploadFile = (): void => {
    if (fileInput.current !== null) {
      fileInput.current.click();
    }
  };

  const onSelectDocument = (event: any): void => {
    const file = event.target.files[0];
    const isallowedFileTypes = file.type === allowedFileTypes;
    if (!isallowedFileTypes) {
      if (onValidateFails) {
        onValidateFails(FORMAT_VALIDATE);
      }
      return;
    }
    const isallowedFileSize = file.size / 1024 / 1024 < maxFileSizeMB;
    if (!isallowedFileSize) {
      if (onValidateFails) {
        onValidateFails(SIZE_VALIDATE);
      }
      return;
    }
    if (!file) {
      return;
    } else {
      setFile(file);
      onChange(file);
    }
  };

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  useImperativeHandle(ref, () => ({
    reset() {
      setFile(emptyFile);
    },
  }));

  const onRemoveFile = () => {
    onRemove();
    setFile(emptyFile);
    onChange(emptyFile);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

  return (
    <div className={styles.documentUploaderWrapper}>
      <PageActionButton onClick={uploadFile} disabled={disabled}>
        + File
      </PageActionButton>
      <div className={`${styles.fileLabel} ${disabled ? styles.disabled : ''}`}>
        {isLoading && (
          <>
            <Spin indicator={antIcon} /> &nbsp;
          </>
        )}
        {file.name && !uploadError && (
          <OverflowPopover
            {...(file.name.length > 75 ? { maxWidth: 300 } : '')}
          >
            <span
              className={`${styles.fileNameArea} uploadedFileName`}
              onClick={() => onFileNameClick(file)}
            >
              {file.name}
            </span>
          </OverflowPopover>
        )}
        {!uploadError && (
          <div className={styles.removeFile}>
            {isEmpty(file.name) ? (
              showNoFileSelectedMessage && (
                <span className="text label-disabled">No File Selected</span>
              )
            ) : (
              <ConfirmationModal
                title={
                  <div className={styles.cancelUploadBody}>
                    Are you sure you want to remove this file?
                  </div>
                }
                icon={
                  <img
                    src={iconWarning}
                    alt="warning-icon"
                    className={styles.iconWarning}
                  />
                }
                confirmModalTrigger="(remove)"
                onConfirm={() => onRemoveFile()}
                okText="Yes, remove file"
                placement="topLeft"
              />
            )}
          </div>
        )}
      </div>
      <input
        type="file"
        name="file"
        onClick={onInputClick}
        onChange={onSelectDocument}
        ref={fileInput}
        accept={allowedFileTypes}
        hidden
      />
    </div>
  );
});

DocumentUploader.displayName = 'DocumentUploader';

export default DocumentUploader;
