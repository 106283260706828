import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import PlanYear from 'model/PlanYear';

import * as EmployerService from 'modules/employers/services/EmployerService';
import * as IndividualService from 'modules/employers/services/IndividualService';
import Employer from 'model/Employer';
import PaginationConfig from 'model/PaginationConfig';
import MetaData from 'model/MetaData';
import BrokerAdmin from 'model/BrokerAdmin';
import DraftEmployer from 'model/DraftEmployer';
import EmployerAccessibleTeam from 'model/EmployerAccessibleTeam';
import ErrorDetails from 'model/ErrorDetails';
import EmployerCount from 'model/EmployerCount';
import Broker from 'model/Broker';
import { getAllBrokersSuccess } from './employerCreateStepperSlice';

export interface EmployerState {
  inProgress: boolean;
  error: any;
  requestType: string;
  planYearsList: Array<PlanYear>;
  employers: { content: Array<Employer>; metadata: MetaData };
  teamMembers: Array<BrokerAdmin>;
  employerObj: Employer;
  employerEditInProgress: boolean;
  accessibleTeamMembers: EmployerAccessibleTeam | null;
  updateTeamInProgress: boolean;
  manageTeamOrderInProgress: boolean;
  teamOrder: string[];
  planYear: PlanYear;
  employerCount: EmployerCount;
  teamMemberSortDone: boolean;
  brokerList: Broker[];
}

const initialState = {
  planYearsList: [],
  inProgress: false,
  error: null,
  requestType: '',
  employers: { content: [], metadata: {} },
  teamMembers: [],
  employerObj: {} as Employer,
  employerEditInProgress: false,
  accessibleTeamMembers: null,
  updateTeamInProgress: false,
  manageTeamOrderInProgress: false,
  teamOrder: [],
  planYear: {} as PlanYear,
  employerCount: {} as EmployerCount,
  teamMemberSortDone: false,
  brokerList: [],
} as EmployerState;

const benefitClassUpdateInProgress = (
  state: EmployerState,
  action: PayloadAction
) => {
  state.inProgress = true;
  state.error = null;
  state.requestType = action.type;
};

const benefitClassUpdateCompleted = (
  state: EmployerState,
  action: PayloadAction
) => {
  state.inProgress = false;
  state.error = null;
  state.requestType = action.type;
};

const benefitClassUpdateFailed = (
  state: EmployerState,
  action: PayloadAction
) => {
  state.inProgress = false;
  state.error = action.payload;
  state.requestType = action.type;
};

const employerSlice = createSlice({
  name: 'employer',
  initialState,
  reducers: {
    getPlanYearsInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.planYearsList = [];
      state.error = null;
      state.requestType = action.type;
    },
    getPlanYearsCompleted: (state, action: PayloadAction<Array<PlanYear>>) => {
      state.inProgress = false;
      state.planYearsList = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getPlanYearsFailed: (state, action) => {
      state.inProgress = false;
      state.planYearsList = [];
      state.error = { response: action.payload };
      state.requestType = action.type;
    },
    getEmployersInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.employers = { content: [], metadata: {} };
      state.error = null;
      state.requestType = action.type;
    },
    getEmployersCompleted: (
      state,
      action: PayloadAction<{ content: Array<Employer>; metadata: {} }>
    ) => {
      state.inProgress = false;
      state.employers = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getEmployersFailed: (state, action) => {
      state.inProgress = false;
      state.employers = { content: [], metadata: {} };
      state.error = { response: action.payload };
      state.requestType = action.type;
    },
    getTeamMembersInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
      state.teamMembers = [];
    },
    getTeamMembersSuccess(state, action: PayloadAction<BrokerAdmin[]>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.teamMembers = action.payload;
    },
    getTeamMembersFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.teamMembers = [];
    },
    editEmployerCompleted: (state, action: PayloadAction<Employer>) => {
      state.employerEditInProgress = false;
      state.employerObj = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    editEmployerFailed: (state, action) => {
      state.employerEditInProgress = false;
      state.employerObj = {} as Employer;
      state.error = { response: action.payload };
      state.requestType = action.type;
    },
    editEmployerInProgress: (state, action: PayloadAction) => {
      state.employerEditInProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    getAccessibleTeamInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
      state.accessibleTeamMembers = null;
    },
    getAccessibleTeamSuccess(
      state,
      action: PayloadAction<EmployerAccessibleTeam>
    ) {
      state.inProgress = false;
      state.requestType = action.type;
      state.accessibleTeamMembers = action.payload;
    },
    getAccessibleTeamFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.accessibleTeamMembers = null;
    },
    updateTeamInProgress(state, action: PayloadAction) {
      state.updateTeamInProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    updateTeamSuccess(state, action: PayloadAction) {
      state.updateTeamInProgress = false;
      state.error = null;
      state.requestType = action.type;
    },
    updateTeamFailed(state, action: PayloadAction) {
      state.error = null;
      state.requestType = action.type;
      state.updateTeamInProgress = false;
    },
    manageTeamMemberOrder(state, action: PayloadAction<string[]>) {
      state.manageTeamOrderInProgress = false;
      state.error = null;
      state.requestType = action.type;
      state.teamOrder = action.payload;
    },
    createBenefitClassInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.planYear = {} as PlanYear;
      state.error = null;
      state.requestType = action.type;
    },
    createBenefitClassCompleted: (state, action: PayloadAction<PlanYear>) => {
      state.inProgress = false;
      state.planYear = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    createBenefitClassFailed: (state, action) => {
      state.inProgress = false;
      state.planYear = {} as PlanYear;
      state.error = { response: action.payload };
      state.requestType = action.type;
    },
    getEmployerInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.employerObj = {} as Employer;
      state.error = null;
    },
    getEmployerCompleted: (state, action: PayloadAction<Employer>) => {
      state.inProgress = false;
      state.employerObj = action.payload;
      state.error = null;
    },
    getEmployerFailed: (state, action) => {
      state.inProgress = false;
      state.employerObj = {} as Employer;
      state.error = { response: action.payload };
    },
    deleteBenefitClassInProgress: (state, action: PayloadAction) => {
      benefitClassUpdateInProgress(state, action);
    },
    deleteBenefitClassCompleted: (state, action: PayloadAction) => {
      benefitClassUpdateCompleted(state, action);
    },
    deleteBenefitClassFailed: (state, action) => {
      benefitClassUpdateFailed(state, action);
    },
    editBenefitClassInProgress: (state, action: PayloadAction) => {
      benefitClassUpdateInProgress(state, action);
    },
    editBenefitClassCompleted: (state, action: PayloadAction) => {
      benefitClassUpdateCompleted(state, action);
    },
    editBenefitClassFailed: (state, action) => {
      benefitClassUpdateFailed(state, action);
    },
    getEmployerListCountInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
      state.employerCount = {} as EmployerCount;
    },
    getEmployerListCountSuccess: (state, action) => {
      state.inProgress = false;
      state.employerCount = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getEmployerListCountFailed: (state, action) => {
      state.inProgress = false;
      state.error = { response: action.payload };
      state.employerCount = {} as EmployerCount;
    },
    setTeamMemberDetailsLocal: (
      state,
      action: PayloadAction<EmployerAccessibleTeam>
    ) => {
      state.accessibleTeamMembers = action.payload;
    },
    setTeamMemberSortDone: (state, action: PayloadAction<boolean>) => {
      state.teamMemberSortDone = action.payload;
    },
    clearTeamOrder(state) {
      state.teamOrder = [];
    },
    clearEmployerError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllBrokersSuccess,
      (state: { brokerList: Broker[] }, action: PayloadAction<Broker[]>) => {
        state.brokerList = action.payload;
      }
    );
  },
});

export const {
  getPlanYearsInProgress,
  getPlanYearsCompleted,
  getPlanYearsFailed,
  getEmployersInProgress,
  getEmployersCompleted,
  getEmployersFailed,
  getTeamMembersInProgress,
  getTeamMembersSuccess,
  getTeamMembersFailed,
  editEmployerInProgress,
  editEmployerCompleted,
  editEmployerFailed,
  getAccessibleTeamInProgress,
  getAccessibleTeamSuccess,
  getAccessibleTeamFailed,
  updateTeamInProgress,
  updateTeamSuccess,
  updateTeamFailed,
  manageTeamMemberOrder,
  setTeamMemberSortDone,
  createBenefitClassInProgress,
  createBenefitClassCompleted,
  createBenefitClassFailed,
  deleteBenefitClassInProgress,
  deleteBenefitClassCompleted,
  deleteBenefitClassFailed,
  editBenefitClassInProgress,
  editBenefitClassCompleted,
  editBenefitClassFailed,
  getEmployerInProgress,
  getEmployerCompleted,
  getEmployerFailed,
  getEmployerListCountInProgress,
  getEmployerListCountSuccess,
  getEmployerListCountFailed,
  setTeamMemberDetailsLocal,
  clearTeamOrder,
  clearEmployerError,
} = employerSlice.actions;

export default employerSlice.reducer;

export const getPlanYears = (organizationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPlanYearsInProgress());
    try {
      const response = await EmployerService.getPlanYears(organizationId);
      const data = response.data;
      dispatch(getPlanYearsCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getPlanYearsFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const getEmployers = (
  { page, size, sort, query }: PaginationConfig,
  organizations: string,
  locations: string,
  members: string,
  renewalDates: string,
  featureTools: string,
  archived: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getEmployersInProgress());
    try {
      const response = await EmployerService.getEmployerList(
        page,
        size,
        sort,
        query,
        organizations,
        locations,
        members,
        renewalDates,
        featureTools,
        archived
      );
      const data = response.data;
      dispatch(getEmployersCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getEmployersFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const getTeamMembers = (
  organizationId: string,
  employerId: string | null | undefined
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getTeamMembersInProgress());
    try {
      const response = await IndividualService.getTeamMembers(
        organizationId,
        employerId
      );
      dispatch(getTeamMembersSuccess(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getTeamMembersFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const getBrokerEmployers = (
  { page, size, sort, query }: PaginationConfig,
  organizationId: string,
  locations: string,
  members: string,
  renewalDate: string,
  featureTools: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getEmployersInProgress());
    try {
      const response = await EmployerService.getBrokerEmployers(
        page,
        size,
        sort,
        query,
        organizationId,
        locations,
        members,
        renewalDate,
        featureTools
      );
      const data = response.data;
      dispatch(getEmployersCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getEmployersFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const editEmployer = (employer: DraftEmployer, onFinish: () => void) => {
  return async (dispatch: Dispatch) => {
    dispatch(editEmployerInProgress());
    try {
      const response = await EmployerService.updateEmployer(employer);
      if (response.data) {
        dispatch(editEmployerCompleted(response.data));
        onFinish();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          editEmployerFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const getAccessibleTeamMembers = (employerId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getAccessibleTeamInProgress());
    try {
      const response = await EmployerService.getAccessibleTeamMembers(
        employerId
      );
      dispatch(getAccessibleTeamSuccess(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getAccessibleTeamFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const updateTeam = (
  organizationId: string,
  employerId: string,
  individuals: string[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(editEmployerInProgress());
    try {
      const response = await EmployerService.updateTeam(
        organizationId,
        employerId,
        individuals
      );
      dispatch(editEmployerCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          editEmployerFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const createBenefitClass = (
  employerId: string,
  benefitClass: string[],
  planYearId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(createBenefitClassInProgress());
    try {
      const response = await EmployerService.createBenefitClass(
        employerId,
        benefitClass,
        planYearId
      );

      const data = response.data;
      dispatch(createBenefitClassCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          createBenefitClassFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const deleteBenefitClass = (
  employerId: string,
  benefitClass: string,
  planYearId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteBenefitClassInProgress());
    try {
      await EmployerService.deleteBenefitClass(
        employerId,
        benefitClass,
        planYearId
      );
      dispatch(deleteBenefitClassCompleted());
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            deleteBenefitClassFailed({
              data: error.response.data,
            } as ErrorDetails)
          );
        }
      }
    }
  };
};

export const editBenefitClass = (
  employerId: string,
  planYearId: string,
  oldBenefitClass: string,
  newBenefitClass: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(editBenefitClassInProgress());
    try {
      await EmployerService.editBenefitClass(
        employerId,
        planYearId,
        oldBenefitClass,
        newBenefitClass
      );
      dispatch(editBenefitClassCompleted());
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            editBenefitClassFailed({
              data: error.response.data,
            } as ErrorDetails)
          );
        }
      }
    }
  };
};

export const getEmployer = (employerId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getEmployerInProgress());
    try {
      const response = await EmployerService.findEmployer(employerId);
      dispatch(getEmployerCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(getEmployerFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
};

export const getEmployerCount = (organizationId: string | null | undefined) => {
  return async (dispatch: Dispatch) => {
    dispatch(getEmployerListCountInProgress());
    try {
      const response = await EmployerService.getEmployerCount(organizationId);
      const data = response.data;
      dispatch(getEmployerListCountSuccess(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getEmployerListCountFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const updateTeamMembers =
  (data: EmployerAccessibleTeam) => (dispatch: Dispatch) => {
    dispatch(setTeamMemberDetailsLocal(data));
  };

export const setTeamMemberSortFlag =
  (flag: boolean) => (dispatch: Dispatch) => {
    dispatch(setTeamMemberSortDone(flag));
  };

export const removeTeamOrder = () => (dispatch: Dispatch) => {
  dispatch(clearTeamOrder());
};
