import { useAppSelector } from 'hooks/redux';

/**
 * Checks if the user's role is within the allowed roles.
 * @param {string[]} allowedRoles - The roles that are allowed.
 * @return {boolean} - Returns true if the user's role is within the allowed roles, false otherwise.
 */
export const useIsAuthorizedUserRole = (allowedRoles: string[]): boolean => {
  const userRole = useAppSelector(
    (state) => state.auth.auth.appBootupInfo?.type
  );
  return allowedRoles.includes(userRole!);
};
