import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Col, Menu, Row } from 'antd';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import { ReactComponent as IconTaxAdv } from 'assets/images/benefitCategories/icon-tax-adv.svg';

import EmptyAddPlan from 'modules/plans/components/EmptyAddPlan/EmptyAddPlan';
import SearchBar from 'components/SearchBar/SearchBar';
import PlanSelectButton from 'modules/plans/components/PlanSelectButton/PlanSelectButton';
import PlansList from 'modules/plans/components/PlansList/PlansList';
import { AlertInfo } from 'components/Alert/AlertMessage';
import {
  BenefitCategory,
  EMPLOYER_MEMBER_RESTRICTED,
  planCreateSuccess,
} from 'constants/commonConstants';
import AlertMessageWrapper from 'modules/plans/components/AlertMessageWrapper/AlertMessageWrapper';
import PlanListPlanYearDropDown from 'components/PlanListPlanYearDropDown/PlanListPlanYearDropDown';
import AddTaxAdvantagedPlanModal from 'modules/plans/taxAdvantaged/components/AddTaxAdvantagedModal/AddTaxAdvantagedModal';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import TablePagination from 'model/TablePagination';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import { getTaxAdvantagedAccountPlansList } from 'modules/plans/slices/taxAdvantagedAccountPlanSlice';
import { TaxAdvantagedAccountPlans } from 'modules/plans/constants';
import PlanYear from 'model/PlanYear';
import Option from 'model/Option';
import { TaxAdvantagedAccountPlan } from 'model/plans/TaxAdvantagedAccountPlan';
import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
  resetPaginationConfig,
} from 'util/commonUtil';
import { usePrevious } from 'hooks/usePrevious';
import { usePermitByUserType } from 'hooks/usePermitByUserType';

import styles from './taxAdvantagedPlanList.module.less';

const TaxAdvantagedPlanList: FC = () => {
  const [fullScreenModalView, setFullScreenModalView] =
    useState<boolean>(false);
  const [planYear, setPlanYear] = useState<PlanYear | null>(null);
  const [taxAdvantagedAccountPlanType, setTaxAdvantagedAccountPlanType] =
    useState<Option>({
      value: '',
      label: '',
    });
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: '',
    },
    filters: {},
  });
  const [searchText, setSearchText] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [alertVisible, setAlertVisible] = useState<boolean>(false);

  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );

  const dispatch = useAppDispatch();
  const { employerId, employer, brokerId } = useNavContext();
  const navigate = useNavigate();
  const { taxAdvantagedAccountPlanList, inProgress, error } = useAppSelector(
    (state: any) => state.plan.taxAdvantagedAccountPlan
  );

  const { content, metadata } = taxAdvantagedAccountPlanList || {};
  const total = metadata ? metadata.total : 0;
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  useEffect(() => {
    if (employerId) {
      dispatch(getPlanYears(employerId));
    }
  }, [dispatch, employerId]);

  const previousPlanYear = usePrevious(planYear?.id);

  useEffect(() => {
    if (employerId && planYear?.id) {
      if (previousPlanYear !== planYear?.id) {
        setPaginationConfig(resetPaginationConfig(paginationConfig));
      }
      dispatch(
        getTaxAdvantagedAccountPlansList(
          convertPaginationConfig(paginationConfig),
          employerId,
          planYear.id
        )
      );
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    employerId,
    paginationConfig,
    planYear?.id,
    fullScreenModalView,
  ]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.trim();
    setSearchText(searchText);
    const config = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText },
    };
    debounceLoadData(config);
  };

  const handleClose = () => {
    setFullScreenModalView(false);
    if (employerId && planYear?.id) {
      dispatch(
        getTaxAdvantagedAccountPlansList(
          convertPaginationConfig(paginationConfig),
          employerId,
          planYear.id
        )
      );
    }
  };

  const onSaveClose = () => {
    setFullScreenModalView(false);
    if (employerId && planYear?.id) {
      if (!error) {
        setAlertMessage({
          type: 'success',
          message: planCreateSuccess,
        });
        setAlertVisible(true);
      }
      dispatch(
        getTaxAdvantagedAccountPlansList(
          convertPaginationConfig(paginationConfig),
          employerId,
          planYear.id
        )
      );
    }
  };

  const openPlanCreateModal = (planType: any) => {
    setFullScreenModalView(true);
    setTaxAdvantagedAccountPlanType(planType);
    setAlertVisible(false);
  };

  const getData = (filters: TablePagination) => {
    const paginationConfigData = {
      sorterInfo: {
        columnKey: 'name',
        field: 'name',
        order: 'ascend',
      },
      paginationIndex: filters.paginationIndex,
      filterInfo: {
        limit: filters.filterInfo.limit,
        offset: filters.filterInfo.offset,
        searchText: searchText,
      },
      filters: filters.filters,
    } as TablePagination;
    setPaginationConfig(paginationConfigData);
  };

  const goToOverviewPage = (plan: TaxAdvantagedAccountPlan) => {
    navigate(
      `/brokers/${brokerId}/employers/${employerId}/tax-advantaged-accts/${plan.id}/overview`
    );
  };

  const setSelectedPlanYear = (planYear: PlanYear | null | undefined) => {
    if (employerId && planYear) {
      setPlanYear(planYear);
    }
  };

  const closeAlert = () => setAlertVisible(false);

  const taxAdvantagedAccountPlanTypes = () => {
    return (
      <Menu>
        {TaxAdvantagedAccountPlans.map((item) => (
          <Menu.Item key={item.value} onClick={() => openPlanCreateModal(item)}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const addPlanButton = (
    <div className={styles.selectButtonWrapper}>
      <PlanSelectButton
        overlay={taxAdvantagedAccountPlanTypes()}
        selectLabel="Add Tax Advantaged Accounts Plan"
        className={styles.dropdownButton}
      />
    </div>
  );

  const permittedAddPlanButton = usePermitByUserType(
    addPlanButton,
    EMPLOYER_MEMBER_RESTRICTED
  );

  return (
    <ContentContainer>
      <AlertMessageWrapper
        type={alertMessage.type}
        message={alertMessage.message}
        closeAlert={closeAlert}
        visible={alertVisible}
      />
      <Row>
        <Col span={18}>
          <div className={styles.employerName}>
            <OverflowPopover
              popoverContent={`${employer?.name}`}
              maxWidth="35%"
            >
              {`${employer?.name} `}
            </OverflowPopover>
            Tax Advantaged Account Plans
          </div>
        </Col>
        <Col span={6}>
          {((!inProgress && content.length !== 0) ||
            (!inProgress && content.length === 0 && !isEmpty(searchText))) &&
            permittedAddPlanButton}
        </Col>
      </Row>
      <PlanListPlanYearDropDown onPlanYearSelect={setSelectedPlanYear} />
      {!inProgress && total === 0 && isEmpty(searchText) ? (
        <EmptyAddPlan
          title="You have no Tax Advantaged Accounts Plan set up!"
          description="Get started by adding your first plan"
          icon={<IconTaxAdv className={styles.iconTaxAdv} />}
          onActionClick={() => {
            setFullScreenModalView(true);
            setAlertVisible(false);
          }}
          selectButton={
            <div className={styles.emptySelectButtonWrapper}>
              <PlanSelectButton
                overlay={taxAdvantagedAccountPlanTypes()}
                selectLabel="+ Add Tax Advantaged Accounts Plan"
                className={styles.emptyDropdownButton}
              />
            </div>
          }
        />
      ) : (
        <>
          <div className={styles.searchBar}>
            <SearchBar placeholder="Search Plans" onChange={handleSearch} />
          </div>
          <PlansList
            planYear={planYear}
            planList={taxAdvantagedAccountPlanList}
            inProgress={inProgress}
            limit={limit}
            paginationConfig={paginationConfig}
            getData={getData}
            goToOverviewPage={goToOverviewPage}
            benefitCategory={BenefitCategory.SAVING.value}
            onSaveClose={onSaveClose}
          />
        </>
      )}

      <AddTaxAdvantagedPlanModal
        employerName={employer?.name}
        visible={fullScreenModalView}
        onClose={handleClose}
        onSaveClose={onSaveClose}
        planType={taxAdvantagedAccountPlanType}
        defaultPlanYear={planYear?.id}
      />
    </ContentContainer>
  );
};

export default TaxAdvantagedPlanList;
