import hexToRgb from 'hex-to-rgba';

const fontList = [
  'Arial',
  'Arial Black',
  'Bahnschrift',
  'Calibri',
  'Cambria',
  'Cambria Math',
  'Candara',
  'Comic Sans MS',
  'Consolas',
  'Constantia',
  'Corbel',
  'Courier New',
  'Ebrima',
  'Franklin Gothic Medium',
  'Gabriola',
  'Gadugi',
  'Georgia',
  'HoloLens MDL2 Assets',
  'Impact',
  'Ink Free',
  'Javanese Text',
  'Leelawadee UI',
  'Lucida Console',
  'Lucida Sans Unicode',
  'Malgun Gothic',
  'Microsoft Himalaya',
  'Microsoft JhengHei',
  'Microsoft New Tai Lue',
  'Microsoft PhagsPa',
  'Microsoft Sans Serif',
  'Microsoft Tai Le',
  'Microsoft YaHei',
  'Microsoft Yi Baiti',
  'MingLiU-ExtB',
  'Mongolian Baiti',
  'MS Gothic',
  'MV Boli',
  'Myanmar Text',
  'Nirmala UI',
  'Palatino Linotype',
  'Segoe MDL2 Assets',
  'Segoe Print',
  'Segoe Script',
  'Segoe UI',
  'Segoe UI Emoji',
  'Segoe UI Historic',
  'Segoe UI Symbol',
  'SimSun',
  'Sitka',
  'Sylfaen',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
  'Yu Gothic',
].sort((a, b) => (a === 'Arial' ? -1 : a.localeCompare(b)));

export const fontObjects = fontList.map((fontName) => ({
  fontName: fontName,
  fontStyle: fontName,
}));

export const exportStylingColorConfigurationTypes = {
  TABLE_HEADER_COLOR: 'tableHeaderColor',
  TABLE_HEADER_FONT_COLOR: 'tableHeaderFontColor',
};

export type RGBAModel = {
  r: string | number;
  g: string | number;
  b: string | number;
  a: string | number;
};

export type Color = {
  rgb: RGBAModel;
  hex: string;
};

export const validateHex = (color: string) => {
  const regex = /^#?([0-9A-F]{3}|[0-9A-F]{6})$/i;
  return regex.test(color);
};

export const rgbaToHex = (rgba: any) => {
  rgba = rgba.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
  );
  return rgba && rgba.length === 4
    ? '#' +
        ('0' + parseInt(rgba[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgba[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgba[3], 10).toString(16)).slice(-2)
    : '';
};

export const convertToColorString = (rgba: RGBAModel) => {
  const { r, g, b, a } = rgba;
  return `rgba(${r},${g},${b},${a})`;
};

export const convertHexToRgba = (hex: string) => {
  const rgbaStr = hexToRgb(hex);
  const rgbArr = rgbaStr
    .substring(5, rgbaStr.length - 2)
    .replace(/ /g, '')
    .split(',');
  const rgba = {
    r: parseInt(rgbArr[0]),
    g: parseInt(rgbArr[1]),
    b: parseInt(rgbArr[2]),
    a: 1,
  };
  return rgba;
};
