import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import { BROKER_ADMIN_QUOTE_THRESHOLD_MESSAGE } from 'modules/renewals/constants/renewalsConstants';
import { ReactComponent as ThresholdIcon } from 'assets/images/ai-quote-threshold-icon.svg';
import Button from 'components/buttons/Button/Button';

import styles from './quoteProcessingThresholdAlert.module.less';

interface Props {
  exceeded: boolean;
  onSubmit: () => Promise<void>;
  isLoading?: boolean;
}

const QuoteProcessingThresholdAlert = (props: Props) => {
  const { exceeded, onSubmit, isLoading } = props;

  const onClick = async () => {
    if (!exceeded) return;
    await onSubmit();
  };

  const CustomAlertMessage = () => {
    return (
      <>
        {BROKER_ADMIN_QUOTE_THRESHOLD_MESSAGE}
        <Button
          onClick={onClick}
          label="Opt for Manual Review"
          className={styles.button}
          disabled={isLoading}
          loading={isLoading}
        >
          Opt for Manual Review
        </Button>
      </>
    );
  };

  if (!exceeded) return <></>;

  return (
    <FixedAlertMessage
      message={<CustomAlertMessage />}
      closeAlert={() => {}}
      icon={<ThresholdIcon />}
      className={styles.alert}
    />
  );
};

export default QuoteProcessingThresholdAlert;
