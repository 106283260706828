import React from 'react';
import { Col, Row } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { toLower } from 'lodash';

import DataTable from 'components/DataTable/DataTable';
import Button from 'components/buttons/Button/Button';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';

import { getBenefitInfo } from 'modules/renewals/utils/renewalsUtils';
import styles from './sendConfirmationStep.module.less';

type Props = {
  tableData: any[];
  employerName: string;
  tableLoading: boolean;
  onHandleSendSoldConfirm: (rowData: any) => void;
  showAlertMessage: {
    message: string;
    type: any;
    visible: boolean;
  };
};

const SendConfirmationStep = (props: Props) => {
  const {
    employerName,
    tableData,
    tableLoading,
    onHandleSendSoldConfirm,
    showAlertMessage,
  } = props;
  const columns = [
    {
      title: 'Carrier',
      dataIndex: 'carrierName',
      key: 'carrierName',
      width: '35%',
      sorter: (a: any, b: any) => a?.carrierName?.localeCompare(b?.carrierName),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (colData: any, record: any) => {
        return (
          <div className={styles.carrierTitleWrapper}>
            <img className={styles.logo} src={record?.carrierLogo} />
            <div className={styles.carrierTitle}>
              <OverflowPopover maxWidth={155}>{colData}</OverflowPopover>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Benefit',
      dataIndex: 'benefitCategories',
      key: 'benefitCategories',
      width: '40%',
      render: (colData: any) =>
        colData
          ?.map((item: string) => getBenefitInfo(toLower(item)).name)
          .join(', '),
    },
    {
      title: 'Action',
      width: '25%',
      render: (_: any, record: any) => {
        return record?.status !== 'NOT_SENT' ? (
          <Row>
            <Col xs={24}>
              <CheckOutlined className={styles.checkIcon} />{' '}
              <span className={styles.sendText}>Sent:</span>{' '}
              {dayjs(record?.lastUpdatedTs).format('MMM D, YYYY h:mm A')}
            </Col>
            <Col>
              <span className={styles.sendByText}>{`by ${record.sentBy}`}</span>
            </Col>
          </Row>
        ) : (
          <Button
            className={styles.sendSoldConfirmBtn}
            label="Send Sold Confirm"
            onClick={() => onHandleSendSoldConfirm(record)}
          />
        );
      },
    },
  ];

  return (
    <div className={styles.container}>
      <Row>
        Listed below are the carriers in the Final Approved proposal. Send each
        carrier a sold confirmation to notify them what plans {employerName} has
        selected.
      </Row>
      {showAlertMessage.visible && (
        <FixedAlertMessage
          wrapperClassName={styles.alertMessage}
          message={showAlertMessage.message}
          type={showAlertMessage.type || 'success'}
        />
      )}
      <div className={styles.tableWrapper}>
        <DataTable
          rowKey={'id'}
          data={tableData || []}
          loading={tableLoading}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default SendConfirmationStep;
