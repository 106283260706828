import { useEffect, useState } from 'react';

import { Col, Row, Table, Tooltip } from 'antd';
import Column from 'antd/lib/table/Column';
import { indexOf, sortBy } from 'lodash';

import { isNullOrUndefined } from 'modules/plans/utils';
import { OFFER_BENEFIT_TYPES } from 'modules/renewals/constants/renewalsConstants';
import { getTypeIcon } from 'modules/renewals/utils/renewalsUtils';
import { IProposalCardTableRow } from 'modules/renewals/types/renewalsTypes';
import { currencyFormatterNoDecimals } from 'util/commonUtil';

import infoIcon from 'assets/images/icon-info.svg';
import styles from 'modules/renewals/components/ProposalCard/ProposalCardTable/ProposalCardTable.module.less';

type ProposalCardTableProps = {
  tableRows?: IProposalCardTableRow[];
};

const ProposalCardTable = ({ tableRows }: ProposalCardTableProps) => {
  const [localTableRows, setLocalTableRows] = useState<IProposalCardTableRow[]>(
    []
  );

  /**
   * This piece of code take the tableRows[] passed in as props. Checks if it is undefined
   * or missing any specific benefit types, adds them, then orders them in given order for display.
   * The generated new array is stored in localTableRows, which is what is used for display.
   */
  useEffect(() => {
    let tempTableRows = tableRows ?? [];
    const order = [
      OFFER_BENEFIT_TYPES.MEDICAL,
      OFFER_BENEFIT_TYPES.DENTAL,
      OFFER_BENEFIT_TYPES.VISION,
      OFFER_BENEFIT_TYPES.LIFE,
      OFFER_BENEFIT_TYPES.STD,
      OFFER_BENEFIT_TYPES.LTD,
      OFFER_BENEFIT_TYPES.VOLUNTARY_BENEFIT,
    ];
    order.forEach((benefit) => {
      if (!tempTableRows.some((row) => row.benefit === benefit))
        tempTableRows.push({ benefit });
    });
    tempTableRows = sortBy(tempTableRows, (row) => indexOf(order, row.benefit));
    setLocalTableRows(tempTableRows);
  }, [tableRows]);

  const getTableCellText = (value?: string): string => {
    if (isNullOrUndefined(value) || value == '-') {
      return '-';
    } else {
      if ((value || '').match(/[A-Za-z]+/)?.[0]) return value || '';
      else {
        return currencyFormatterNoDecimals(Number(value || 0));
      }
    }
  };

  const getTableCellTextVolBenefits = (value?: string): string => {
    if (isNullOrUndefined(value) || value == '-') {
      return '-';
    } else {
      return `${value} Plans`;
    }
  };

  const isAllFieldsEmpty = (type: string): boolean => {
    if (!localTableRows) return false;
    const row = localTableRows.filter((row) => row.benefit === type).pop();
    return (
      isNullOrUndefined(row?.benefitsCost) &&
      isNullOrUndefined(row?.eeCost) &&
      isNullOrUndefined(row?.erCost)
    );
  };

  const getTableCellClasses = (value: string): string =>
    `${
      (isNullOrUndefined(value) || value.toLowerCase() == 'in progress') &&
      styles.disabledText
    }`;

  return (
    <Table<IProposalCardTableRow>
      dataSource={localTableRows}
      size="small"
      pagination={false}
      className={styles.proposalSummaryTable}
    >
      <Column
        title="Benefits"
        dataIndex="benefit"
        key="benefit"
        render={(value, record) => (
          <>
            <Row
              wrap={false}
              align="middle"
              className={`${styles.benefitText} ${
                isAllFieldsEmpty(value) && styles.disabledText
              }`}
            >
              <Col span={4}>
                <img src={getTypeIcon(value).icon} />
              </Col>
              <Col flex={'auto'}>
                {`${value} `}
                {(record as IProposalCardTableRow).important && (
                  <Tooltip placement="top" title={'Rate Change'}>
                    <img src={infoIcon} className={styles.infoIcon} />
                  </Tooltip>
                )}
              </Col>
            </Row>
          </>
        )}
      />
      <Column
        title="Benefits Cost"
        dataIndex="benefitsCost"
        key="benefitsCost"
        render={(value: string, record: IProposalCardTableRow) => (
          <div className={getTableCellClasses(value)}>
            {record.benefit === OFFER_BENEFIT_TYPES.VOLUNTARY_BENEFIT
              ? getTableCellTextVolBenefits(value)
              : getTableCellText(value)}
            {}
          </div>
        )}
      />
      <Column
        title="ER Cost"
        dataIndex="erCost"
        key="erCost"
        render={(value: string) => (
          <div className={getTableCellClasses(value)}>
            {getTableCellText(value)}
          </div>
        )}
      />
      <Column
        title="EE Cost"
        dataIndex="eeCost"
        key="eeCost"
        render={(value: string) => (
          <div className={getTableCellClasses(value)}>
            {getTableCellText(value)}
          </div>
        )}
      />
    </Table>
  );
};

export default ProposalCardTable;
