import axios from 'axios';
import { baseApi } from 'util/apiUtil';
import { IGetAdminConsultantValidationPrams } from 'modules/admins/slices/adminConsultantValidateSlice';
import CarrierType from 'modules/carriers/enums/CarrierType';

const v2BenefitConsultantsUrl = `${baseApi}/v2/benefit-consultants`;

export const fetchBrokerConsultantDeleteValidate = async ({
  individualId,
  orgId,
  consultantId,
}: Partial<IGetAdminConsultantValidationPrams>) => {
  try {
    const response = await axios.get(
      `${v2BenefitConsultantsUrl}/organization/${orgId}/validate-delete`,
      {
        params: {
          'individual-id': individualId,
          'consultation-level': CarrierType.BROKER,
          'consultant-id': consultantId,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchEmployerConsultantDeleteValidate = async ({
  orgId,
  individualId,
  employerId,
}: Partial<IGetAdminConsultantValidationPrams>) => {
  try {
    const response = await axios.get(
      `${v2BenefitConsultantsUrl}/organization/${orgId}/employer/${employerId}/validate-delete?individual-id=${individualId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
