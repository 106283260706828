import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Col, Menu, notification, Row, Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { CheckOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { DEBOUNCE_WAIT_TIME_SEARCH } from 'util/commonUtil';
import DataTable from 'components/DataTable/DataTable';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import PriorityBadge from 'modules/issueslog/components/PriorityBadge/PriorityBadge';
import { ReactComponent as XIcon } from 'assets/images/x.svg';
import IssueStatusBadge from 'modules/issueslog/components/IssueStatusBadge/IssueStatusBadge';
import { ISSUE_SUBTYPE_MAP } from 'modules/issueslog/constants/IssueLogFilterConstants';
import {
  ISSUE_EDIT_TYPES,
  PRIORITY_UNKNOWN,
  STATUS_NOT_TRACKED,
  STATUS_RE_OPENED,
  STATUS_RESOLVED,
  SUBTYPE_SPAM,
} from 'modules/issueslog/constants/IssuesLogConstants';
import { loginTypes } from 'constants/authConstants';
import {
  useLazyGetAllBrokerAdminsQuery,
  useLazyGetBrokerAdminsForEmployerQuery,
  useLazyGetIssuesListQuery,
  useUpdateIssueFromListMutation,
} from 'modules/issueslog/slices/issuesLogSlice';
import {
  createUniqueId,
  getConfirmModelContent,
  getStatus,
  issueType,
} from 'modules/issueslog/utils/issueLogUtils';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import FilterSection from 'modules/issueslog/components/FilterSection/FilterSection';
import {
  clickShowMore,
  saveFilter,
  setChangePage,
} from 'modules/issueslog/slices/issuesLogFilterSlice';
import { IndividualSubTypes } from 'constants/commonConstants';
import styles from './IssuesLogList.module.less';

type ConfirmStateProps = {
  visible: boolean;
  value: any;
  type: string;
};

type PaginationStateProps = {
  size: number;
  sort: string;
  sortedInfo: any;
  tableDefaultFilters: any;
  type: string;
};

type IssuesViewProps = {
  filterRefs?: any;
};

const IssuesView = forwardRef((props: IssuesViewProps, ref) => {
  const { filterRefs } = props;

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const appBootInfo: any = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );

  const isNotErAdmin: boolean = appBootInfo?.type !== loginTypes.erAdmin;
  const isEmployerView = !!params?.employerId;
  const isPlatformAdmin: boolean = appBootInfo?.type === loginTypes.platform;

  const isEmpMember: boolean =
    appBootInfo?.individualSubType === IndividualSubTypes.EMPLOYER_MEMBER;

  const defaultSortingConst: any = '';

  const filtersLoadingState = useAppSelector(
    (state) => state.filters.loadingFilters
  );
  const saveFiltersID = createUniqueId(
    params,
    isEmployerView,
    appBootInfo?.type
  );
  const savedPageState = useAppSelector((state) => state?.filters?.page);
  const savedFilterState = useAppSelector(
    (state) => state?.filters[saveFiltersID]
  );

  const defaultIssuesRef = useRef(true);

  const [pagination, setPagination] = useState<PaginationStateProps>({
    size: 30,
    sort: defaultSortingConst,
    sortedInfo: null,
    tableDefaultFilters: {},
    type: isEmployerView ? 'EMPLOYER' : 'ORGANIZATION',
  });

  const [searchValue, setSearchValue] = useState('');

  const [tableData, setTableData] = useState<any>([]);

  const [getIssuesListQuery, { currentData: issuesListData, isFetching }] =
    useLazyGetIssuesListQuery();

  useEffect(() => {
    if (pagination?.sortedInfo !== null) {
      setPagination({
        type: isEmployerView ? 'EMPLOYER' : 'ORGANIZATION',
        size: 30,
        sort: defaultSortingConst,
        tableDefaultFilters: {},
        sortedInfo: null,
      });
      dispatch(setChangePage(1));
    }
    setTableData([]);

    if (isNotErAdmin) {
      dispatch(clickShowMore(false));
    }
    setSearchValue(savedFilterState?.filters?.searchKey || '');
    // eslint-disable-next-line
  }, [params?.type]);

  const fetchIssuesList = () => {
    const employerIds: string[] = [];
    const organizationIds: string[] = [];

    params?.employerId && employerIds?.push(String(params?.employerId));
    params?.brokerId && organizationIds?.push(String(params?.brokerId));

    if (!isEmpty(savedFilterState?.filters)) {
      getIssuesListQuery({
        page: savedPageState,
        size: pagination.size,
        sort: pagination.sort,
        type: pagination.type,
        searchKey: savedFilterState?.filters?.searchKey || '',
        employerIds: employerIds,
        organizationIds: organizationIds,
        filters: savedFilterState?.filters?.filters,
        isDefault: defaultIssuesRef.current,
      });
      if (defaultIssuesRef.current) {
        defaultIssuesRef.current = false;
      }
    }
  };

  useEffect(() => {
    fetchIssuesList();
    // eslint-disable-next-line
  }, [
    params?.employerId,
    params?.brokerId,
    params?.type,
    savedPageState,
    savedFilterState.filters,
    isNotErAdmin,
    pagination,
  ]);

  useEffect(() => {
    setTableData(issuesListData?.content);
  }, [issuesListData, isFetching]);

  useImperativeHandle(ref, () => ({
    reset: () => {
      setSearchValue('');
    },
  }));

  const getData = (dataFilters: any) => {
    const sort =
      dataFilters?.sorterInfo?.column === undefined
        ? defaultSortingConst
        : dataFilters?.sorterInfo?.order === 'descend'
        ? `-${dataFilters?.sorterInfo?.columnKey}`
        : dataFilters?.sorterInfo?.columnKey;

    dispatch(setChangePage(dataFilters.paginationIndex));
    setPagination({
      ...pagination,
      sortedInfo: dataFilters?.sorterInfo,
      tableDefaultFilters: dataFilters.filters,
      size: dataFilters?.filterInfo?.limit,
      sort: sort,
    });
  };

  const debounceLoadData = useMemo(
    () =>
      debounce((config) => {
        dispatch(
          saveFilter({
            clickedSaved: true,
            filters: {
              searchKey: config.searchKey,
              filters: [...savedFilterState?.filters?.filters],
            },
            filterSaveType: saveFiltersID,
          })
        );
      }, DEBOUNCE_WAIT_TIME_SEARCH),
    [savedFilterState?.filters?.filters, saveFiltersID, dispatch]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    const config = {
      searchKey: searchText.trim(),
    };

    setSearchValue(searchText);
    debounceLoadData(config);
  };

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);
  const [modelConfirmState, setModelConfirmState] = useState<ConfirmStateProps>(
    {
      visible: false,
      value: null,
      type: '',
    }
  );
  const [rowData, setRowData] = useState<any>(null);
  const [assignValidate, setAssignValidate] = useState<any>({
    value: '',
    validation: false,
  });

  const [getBrokerAdmins, { currentData: brokerAdminEmpLocationData }] =
    useLazyGetBrokerAdminsForEmployerQuery();

  const [getAllBrokerAdmins, { data: allBrokerAdminsData }] =
    useLazyGetAllBrokerAdminsQuery();

  useEffect(() => {
    if (isNotErAdmin && visibleConfirmation && rowData) {
      if (rowData?.employerId) {
        getBrokerAdmins({
          brokerId: rowData?.organizationId || '',
          employerId: rowData?.employerId,
        });
      } else {
        getAllBrokerAdmins({
          organizationId: rowData?.organizationId,
          onlyActiveIndividuals: true,
        });
      }
    }
    // eslint-disable-next-line
  }, [rowData, visibleConfirmation, isNotErAdmin]);

  const getBrokerAdminsAccordingToEmp = () => {
    if (rowData?.employerId) {
      const brokerAdminOptions: any[] =
        brokerAdminEmpLocationData?.map((admin: any) => ({
          value: admin?.id,
          label: `${admin?.firstName} ${admin?.lastName}`,
        })) || [];

      return brokerAdminOptions;
    } else {
      const filteredAllLocation = allBrokerAdminsData?.filter(
        (admin: any) => admin?.allLocations === true
      );
      const allLocationBrokerAdminOptions = filteredAllLocation?.map(
        (admin: any) => ({
          value: admin?.id,
          label: `${admin?.firstName} ${admin?.lastName}`,
        })
      );
      return allLocationBrokerAdminOptions;
    }
  };

  const [updateIssueFromList] = useUpdateIssueFromListMutation();

  let notificationDesc = '';
  let description: any = '';
  let notificationMessage = '';

  const openSuccessNotification = (type: string) => {
    if (type === 'assign') {
      notificationDesc = 'Assignee has been successfully updated';
      notificationMessage = 'Successfully Assigned';
    } else if (type === 'resolve') {
      notificationDesc = 'Item has been successfully resolved';
      notificationMessage = 'Successfully Resolved';
    } else if (type === 'reopen') {
      notificationDesc = 'Item has been successfully re-opened';
      notificationMessage = 'Successfully Re-Opened';
    }

    description = (
      <Col>
        <span>{notificationDesc}</span>
      </Col>
    );

    notification['success']({
      message: (
        <div>
          <b>{notificationMessage}</b>
        </div>
      ),
      top: 40,
      description: description,
      icon: <CheckOutlined className={styles.successNotificationIcon} />,
    });
  };

  const handleUpdateFromListSubmit = ({
    issueId,
    type,
    value,
    modelClose,
  }: {
    issueId: string;
    type: string;
    value: any;
    modelClose: () => void;
  }) => {
    const data = {
      issueId: issueId,
      fieldType: type,
      value: value,
    };
    updateIssueFromList({ data: data, issueId: issueId }).then(() => {
      modelClose();
      fetchIssuesList();
    });
  };

  const issueActionsMenu = (record: any) => {
    function validateIssueFields(record: any) {
      if (
        !record.assigneeId ||
        !record.employerId ||
        record.priority.toUpperCase() === PRIORITY_UNKNOWN ||
        !record.subType
      ) {
        return setIsDialogOpen(true);
      } else {
        setModelConfirmState({
          visible: true,
          value: STATUS_RESOLVED,
          type: ISSUE_EDIT_TYPES.RESOLVED,
        });
      }
    }

    const checkResolve = record?.status?.toUpperCase() === STATUS_RESOLVED;
    const checkNotTracked =
      record?.subType?.toUpperCase() === STATUS_NOT_TRACKED;

    return (
      <Menu>
        <Menu.Item key="view" onClick={() => navigate(record.id)}>
          View
        </Menu.Item>
        {isEmpMember && record?.createdByUser !== appBootInfo?.workEmail ? (
          <></>
        ) : (
          <>
            {isNotErAdmin && !checkResolve && (
              <Menu.Item
                key="assign"
                onClick={() => {
                  setVisibleConfirmation(true);
                  setRowData(record);
                  setAssignValidate({
                    value: record?.assigneeId,
                    validation: false,
                  });
                }}
              >
                Assign
              </Menu.Item>
            )}
            {!checkResolve && !checkNotTracked && (
              <Menu.Item
                key="markAsSpam"
                onClick={() => {
                  setRowData(record);
                  setModelConfirmState({
                    visible: true,
                    value: SUBTYPE_SPAM,
                    type: ISSUE_EDIT_TYPES.ISSUE_TYPE_SPAM,
                  });
                }}
              >
                Mark as Not Tracked
              </Menu.Item>
            )}
            {!checkResolve && (
              <Menu.Item
                key="resolve"
                onClick={() => {
                  setRowData(record);
                  !isNotErAdmin
                    ? setModelConfirmState({
                        visible: true,
                        value: STATUS_RESOLVED,
                        type: ISSUE_EDIT_TYPES.RESOLVED,
                      })
                    : validateIssueFields(record);
                }}
              >
                Resolve
              </Menu.Item>
            )}
            {checkResolve && (
              <Menu.Item
                key="re_open"
                onClick={() => {
                  setRowData(record);
                  setModelConfirmState({
                    visible: true,
                    value: STATUS_RE_OPENED,
                    type: ISSUE_EDIT_TYPES.RE_OPENED,
                  });
                }}
              >
                Re-Open
              </Menu.Item>
            )}
          </>
        )}
      </Menu>
    );
  };

  const renderTableColumns = () => {
    // show assign column according to views
    const showAssignToInTable = () => {
      if (!isNotErAdmin) return false;
      if (isEmployerView && params.type === issueType.ACCOUNT) return false;
      return true;
    };

    // show created by column according to views
    const showCreatedByInTable = () => {
      if (!isNotErAdmin) return true;
      if (isEmployerView && params.type === issueType.ACCOUNT) return true;
      return false;
    };

    const setWidthForIssueName = () => {
      if (params?.type === issueType.SUPPORT && isEmployerView) return '30%';
      if (params?.type === issueType.SUPPORT && !isEmployerView) return '20%';
      return '25%';
    };
    const columns = [
      {
        title: <div className={styles.idColTitle}>ID</div>,
        dataIndex: 'generatedId',
        key: 'generatedIdValue',
        onCell: (colData: any) => {
          return {
            children: <span className={styles.issueHover}></span>,
            onClick: () => navigate(colData.id),
          };
        },
        width: '6%',
        ellipsis: {
          showTitle: false,
        },
        sorter: true,
        sortOrder:
          pagination?.sortedInfo?.columnKey === 'generatedIdValue'
            ? pagination?.sortedInfo?.order
            : null,
        render: (colData: any, record: any) => {
          return (
            <Row align="middle">
              {record?.priority?.toUpperCase() !== PRIORITY_UNKNOWN && (
                <PriorityBadge
                  priority={record?.priority?.toUpperCase()}
                  showLabel={false}
                />
              )}

              <div
                className={
                  record?.priority?.toUpperCase() === PRIORITY_UNKNOWN
                    ? styles.idColLabel
                    : ''
                }
              >
                <OverflowPopover>{colData}</OverflowPopover>
              </div>
            </Row>
          );
        },
      },
      {
        title: 'SUPPORT TEAM',
        dataIndex: 'supportTeam',
        key: 'supportTeam',
        onCell: (colData: any) => {
          return {
            children: <span className={styles.issueHover}></span>,
            onClick: () => navigate(colData.id),
          };
        },
        show: params?.type === issueType.SUPPORT && isNotErAdmin,
        sorter: true,
        sortOrder:
          pagination?.sortedInfo?.columnKey === 'supportTeam'
            ? pagination?.sortedInfo?.order
            : null,
        width: params?.type === issueType.SUPPORT ? '13%' : '',
        render: (colData: any) => (
          <OverflowPopover>
            {colData === 'unknown' || colData === 'Unknown'
              ? '--'
              : colData || '--'}
          </OverflowPopover>
        ),
      },
      {
        title: isPlatformAdmin ? 'EMPLOYER/\nBROKER' : 'EMPLOYER',
        dataIndex: 'employerName',
        key: 'employerName',
        onCell: (colData: any) => {
          return {
            children: <span className={styles.issueHover}></span>,
            onClick: () => navigate(colData.id),
          };
        },
        width:
          params?.type !== issueType.SUPPORT && !isEmployerView ? '11%' : '9%',
        show: !isEmployerView,
        sorter: true,
        sortOrder:
          pagination?.sortedInfo?.columnKey === 'employerName'
            ? pagination?.sortedInfo?.order
            : null,
        render: (colData: any, record: any) => (
          <>
            <OverflowPopover>{colData || '--'}</OverflowPopover>
            {isPlatformAdmin && (
              <OverflowPopover>
                <span className={styles.tableSubText}>
                  {record?.organizationName}
                </span>
              </OverflowPopover>
            )}
          </>
        ),
      },

      {
        title: 'ITEM NAME',
        dataIndex: 'subject',
        key: 'subject',
        onCell: (colData: any) => {
          return {
            children: <span className={styles.issueHover}></span>,
            onClick: () => navigate(colData.id),
          };
        },
        width: setWidthForIssueName(),
        sorter: true,
        sortOrder:
          pagination?.sortedInfo?.columnKey === 'subject'
            ? pagination?.sortedInfo?.order
            : null,
        ellipsis: {
          showTitle: false,
        },
        render: (colData: any, record: any) => {
          return (
            <div>
              <Col>
                <OverflowPopover>
                  <span
                    className={styles.issueName}
                    onClick={() => navigate(record.id)}
                  >
                    {colData}
                  </span>
                </OverflowPopover>
                <IssueStatusBadge
                  issueStatus={getStatus(
                    !isNotErAdmin && record?.status === 'Blocked'
                      ? 'In Progress'
                      : record?.status
                  )}
                />
              </Col>
            </div>
          );
        },
      },
      {
        title: 'TYPE',
        dataIndex: 'subType',
        key: 'subType',
        onCell: (colData: any) => {
          return {
            children: <span className={styles.issueHover}></span>,
            onClick: () => navigate(colData.id),
          };
        },
        sorter: true,
        sortOrder:
          pagination?.sortedInfo?.columnKey === 'subType'
            ? pagination?.sortedInfo?.order
            : null,
        width: '12%',
        ellipsis: {
          showTitle: false,
        },
        render: (colData: any) => (
          <OverflowPopover>
            {ISSUE_SUBTYPE_MAP.find((value) => value.value === colData)
              ?.label || '--'}
          </OverflowPopover>
        ),
      },
      {
        title: 'DAYS OPEN',
        dataIndex: 'daysOpen',
        key: 'daysOpen',
        onCell: (colData: any) => {
          return {
            children: <span className={styles.issueHover}></span>,
            onClick: () => navigate(colData.id),
          };
        },
        sorter: true,
        sortOrder:
          pagination?.sortedInfo?.columnKey === 'daysOpen'
            ? pagination?.sortedInfo?.order
            : null,
        width: '6%',
        show: !isEmployerView,
        render: (colData: any) => <OverflowPopover>{colData}</OverflowPopover>,
      },
      {
        title: 'ASSIGNED TO',
        dataIndex: 'assigneeName',
        key: 'assigneeName',
        onCell: (colData: any) => {
          return {
            children: <span className={styles.issueHover}></span>,
            onClick: () => navigate(colData.id),
          };
        },
        show: showAssignToInTable(),
        sorter: true,
        sortOrder:
          pagination?.sortedInfo?.columnKey === 'assigneeName'
            ? pagination?.sortedInfo?.order
            : null,
        width: params?.type === issueType.SUPPORT ? '11%' : '10%',
        render: (colData: any) => (
          <OverflowPopover>
            {colData === 'Unassigned' ? '--' : colData}
          </OverflowPopover>
        ),
      },
      {
        title: 'CREATED BY',
        dataIndex: 'createdByUser',
        key: 'createdByUser',
        onCell: (colData: any) => {
          return {
            children: <span className={styles.issueHover}></span>,
            onClick: () => navigate(colData.id),
          };
        },
        width: params?.type === issueType.SUPPORT ? '' : '12%',
        show: showCreatedByInTable(),
        sorter: true,
        sortOrder:
          pagination?.sortedInfo?.columnKey === 'createdByUser'
            ? pagination?.sortedInfo?.order
            : null,
        render: (colData: any) => (
          <OverflowPopover>{colData || '--'}</OverflowPopover>
        ),
      },
      {
        title: 'LAST MODIFIED',
        dataIndex: 'lastModifiedDate',
        key: 'lastIssueModifiedDate',
        onCell: (colData: any) => {
          return {
            children: <span className={styles.issueHover}></span>,
            onClick: () => navigate(colData.id),
          };
        },
        width: '15%',
        sorter: true,
        sortOrder:
          pagination?.sortedInfo?.columnKey === 'lastIssueModifiedDate'
            ? pagination?.sortedInfo?.order
            : null,
        render: (colData: any, record: any) => {
          return (
            <Row>
              <Col xs={24}>
                {colData
                  ? dayjs(record?.lastModifiedDate).format('MMM D, YYYY h:mm A')
                  : '--'}
              </Col>
              {isNotErAdmin && (
                <Col>
                  <span className={styles.tableSubText}>
                    {record?.lastModifiedUser ? (
                      <OverflowPopover
                        popoverContent={record?.lastModifiedUser}
                      >
                        by {record?.lastModifiedUser}
                      </OverflowPopover>
                    ) : (
                      ''
                    )}
                  </span>
                </Col>
              )}
            </Row>
          );
        },
      },
      {
        title: 'ACTIONS',
        dataIndex: 'actions',
        key: 'actions',
        width: params?.type === issueType.SUPPORT ? '' : '7%',
        render: (_: any, record: any) => (
          <SelectDropdown overlay={issueActionsMenu(record)} />
        ),
      },
    ];

    return columns.filter(
      (item) => item?.show === undefined || item?.show === true
    );
  };

  return (
    <>
      <div className={styles.issueTableWrapper}>
        <FilterSection
          filterRefs={filterRefs}
          handleSearch={handleSearch}
          searchValue={searchValue}
          defaultIssuesRef={defaultIssuesRef}
          setSearchValue={setSearchValue}
        />

        <div className={styles.summaryTable}>
          <DataTable
            rowKey={'id'}
            currentIndex={savedPageState}
            data={tableData || []}
            columns={renderTableColumns()}
            pagination={true}
            loading={isFetching || filtersLoadingState}
            getData={getData}
            total={
              issuesListData?.metadata
                ? String(issuesListData?.metadata?.total)
                : '0'
            }
            pageSize={pagination?.size}
            pageSizeOptions={[30, 50, 100]}
            showTotalCount={true}
          />
        </div>
      </div>
      {/* Assignee Model */}
      <ConfirmationDialog
        centered={true}
        title="Assign Item"
        confirmText="Save"
        cancelText="Cancel"
        isCancelLink={true}
        closeModal={() => {
          setAssignValidate({ value: '', validation: false });
          setVisibleConfirmation(false);
          setRowData(null);
        }}
        onConfirm={() => {
          if (isEmpty(assignValidate?.value)) {
            setAssignValidate({ value: '', validation: true });
            return;
          }
          handleUpdateFromListSubmit({
            issueId: rowData?.id,
            type: 'ASSIGNEE',
            value: assignValidate?.value,
            modelClose: () => {
              setAssignValidate({ value: '', validation: false });
              setVisibleConfirmation(false);
              setRowData(null);
              openSuccessNotification('assign');
            },
          });
        }}
        visible={visibleConfirmation}
      >
        <>
          <div className={styles.assignIssueSubtext}>
            <p className={styles.subtextSubject}>{rowData?.subject}</p>
          </div>
          <div className={styles.changeAssignee}>
            <p
              className={`${styles.changeAssigneeText} ${
                assignValidate?.validation && styles?.changeAssigneeTextError
              }`}
            >
              <b>Choose Admin *</b>
            </p>
            <Select
              showSearch
              optionFilterProp="children"
              size="large"
              className={styles.assigneeSelect}
              placeholder="Select"
              value={assignValidate?.value}
              onChange={(value) =>
                setAssignValidate({ value: value, validation: false })
              }
            >
              {getBrokerAdminsAccordingToEmp()?.map((admin: any) => {
                return (
                  <Select.Option key={admin?.value} value={admin?.value}>
                    {admin?.label}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        </>
      </ConfirmationDialog>

      {/* Common Confirm Model  */}
      <ConfirmationDialog
        visible={modelConfirmState.visible}
        centered={true}
        width={464}
        title={
          getConfirmModelContent(
            modelConfirmState.type,
            modelConfirmState?.value
          )?.title
        }
        confirmText={`${
          getConfirmModelContent(
            modelConfirmState.type,
            modelConfirmState?.value
          )?.confirmText
        }`}
        cancelText="Cancel"
        isCancelLink={true}
        closeModal={() => {
          setModelConfirmState({
            visible: false,
            value: null,
            type: '',
          });
          setRowData(null);
        }}
        onConfirm={() => {
          handleUpdateFromListSubmit({
            issueId: rowData?.id,
            type:
              modelConfirmState?.type === ISSUE_EDIT_TYPES?.ISSUE_TYPE_SPAM
                ? 'SUB_TYPE'
                : 'STATUS',
            value: modelConfirmState?.value,
            modelClose: () => {
              if (modelConfirmState.value === STATUS_RESOLVED) {
                openSuccessNotification('resolve');
              } else if (modelConfirmState.value === STATUS_RE_OPENED) {
                openSuccessNotification('reopen');
              }

              setModelConfirmState({
                visible: false,
                value: null,
                type: '',
              });
              setRowData(null);
            },
          });
        }}
      >
        <div className={styles.confirmModelContentWrapper}>
          {
            getConfirmModelContent(
              modelConfirmState.type,
              modelConfirmState?.value
            )?.content
          }
        </div>
      </ConfirmationDialog>

      <ConfirmationDialog
        visible={isDialogOpen}
        centered={true}
        title="Cannot Resolve Item"
        isCloseOnly={true}
        confirmText={'Close'}
        onConfirm={() => {
          setIsDialogOpen(false);
          setRowData(null);
        }}
      >
        <div className={styles?.resolveErrorModelContentWrapper}>
          <p>
            The following fields must be entered before resolving this item:
          </p>
          {isEmpty(rowData?.employerId) && (
            <div className={styles?.listItemWrapper}>
              <XIcon /> Employer
            </div>
          )}
          {isEmpty(rowData?.assigneeId) && (
            <div className={styles?.listItemWrapper}>
              <XIcon /> Assigned To
            </div>
          )}
          {rowData?.priority?.toUpperCase() === PRIORITY_UNKNOWN && (
            <div className={styles?.listItemWrapper}>
              <XIcon /> Priority
            </div>
          )}
          {isEmpty(rowData?.subType) && (
            <div className={styles?.listItemWrapper}>
              <XIcon /> Item Type
            </div>
          )}
        </div>
      </ConfirmationDialog>
    </>
  );
});

IssuesView.displayName = 'IssuesView';
export default IssuesView;
