import { MouseEventHandler } from 'react';

import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { Col, Row } from 'antd';
import { sanitizeRichText } from 'util/domUtils';

import IssueComment from 'model/IssueComment';
import { buildFullName, buildInitials } from 'util/stringUtil';
import { ISSUE_COMMENT_TYPE_EMAIL } from 'modules/issueslog/constants/IssuesLogConstants';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';

import iconattachment from 'assets/images/icon-attachment.svg';
import styles from './collapsedViewComment.module.less';

type Props = {
  issueComment: IssueComment;
  provisionedEmail: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const CollapsedViewComment = ({
  issueComment,
  onClick,
  provisionedEmail,
}: Props) => {
  const {
    createdByFirstName,
    createdByLastName,
    fromEmail,
    description,
    commentType,
    createdTime,
    creatorAvatarUrl,
    attachments,
  } = issueComment;

  const getFormatedEmail = (): string => {
    if (!fromEmail) {
      return `${buildFullName(
        createdByFirstName,
        createdByLastName
      )} <${provisionedEmail}>`;
    }

    if (isEmpty(createdByFirstName) || isEmpty(createdByLastName)) {
      return fromEmail;
    } else {
      return `${buildFullName(
        createdByFirstName || '',
        createdByLastName || ''
      )} <${fromEmail}>`;
    }
  };

  const getDateClasses = (): string => {
    return `${styles.commentDetailHeader} 
      ${
        commentType === ISSUE_COMMENT_TYPE_EMAIL
          ? styles.emailCommentCreatedTime
          : ''
      }`;
  };

  const getFormattedDate = (): string => {
    return `${dayjs(createdTime).format('MMMM D, YYYY')} at ${dayjs(
      createdTime
    ).format('h:mm A')}`;
  };

  return (
    <div className={styles.commentDetailContainer} onClick={onClick}>
      <Row wrap={false} justify="space-between">
        <Col flex="auto">
          {commentType === ISSUE_COMMENT_TYPE_EMAIL ? (
            <span className={`${styles.emailsList}`}>{getFormatedEmail()}</span>
          ) : (
            <Row>
              <ProfileAvatar
                content={buildInitials(createdByFirstName, createdByLastName)}
                src={creatorAvatarUrl}
              />
              <span className={styles.commentDetailHeader}>
                {buildFullName(createdByFirstName, createdByLastName)}
              </span>
            </Row>
          )}
        </Col>
        <Col span={7}>
          <Row justify="end" wrap={false}>
            {!isEmpty(attachments) && (
              <img
                src={iconattachment}
                alt="icon-attachment"
                className={styles.iconAttachment}
              />
            )}
            <span className={getDateClasses()}>{getFormattedDate()}</span>
          </Row>
        </Col>
      </Row>
      <Row className={styles.issueDescriptionContainer}>
        <div className={styles.textWrapper}>
          {sanitizeRichText(description)}
        </div>
      </Row>
    </div>
  );
};

export default CollapsedViewComment;
