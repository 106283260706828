import React, { FC } from 'react';
import styles from './RenewalCommonSubHeader.module.less';

type IProps = {
  subText: React.ReactNode;
};

const RenewalCommonSubHeader: FC<IProps> = ({ subText }) => {
  return <p className={styles.subHeader}>{subText}</p>;
};

export default RenewalCommonSubHeader;
