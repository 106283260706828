import { useState, useEffect } from 'react';
import { Input, Select } from 'antd';
import { isUndefined } from 'lodash';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import {
  useToggleEmployerSsoMutation,
  useUpdateEmployerSsoMutation,
  useValidateMetadataMutation,
  useEnableBrokerSsoMutation,
  useUpdateBrokerSsoConfigMutation,
} from 'modules/sso/slices/ssoSlice';

import { SSO_VENDOR_OPTIONS } from 'modules/sso/constants/ssoConstants';

import { ReactComponent as XIcon } from 'assets/images/x-icon-remove.svg';
import { ReactComponent as CheckIcon } from 'assets/images/icon-check.svg';
import styles from 'modules/sso/components/ConfigureSsoModal/ConfigureSsoModal.module.less';

type Props = {
  isOpen?: boolean;
  onCancel: () => void;
  onUpdate: Function;
  data?: {
    metadataUrl?: string;
    vendor?: string;
    employerId?: string;
    organizationId?: string;
    name?: string;
  };
};

const ConfigureSsoModal = ({ isOpen, onCancel, data, onUpdate }: Props) => {
  const [vendor, setVendor] = useState<string>();
  const [metadataUrl, setMetadataUrl] = useState<string>();
  const [
    validateMetadata,
    {
      isSuccess: isMetadataValid,
      isError: isMetadataInvalid,
      isLoading: isMetadataValidating,
      reset: resetValidateMetadata,
    },
  ] = useValidateMetadataMutation();
  const [toggleEmployerSso, { isLoading: isToggleEmployerSsoLoading }] =
    useToggleEmployerSsoMutation();
  const [updateEmployerSso, { isLoading: isUpdateEmployerSsoLoading }] =
    useUpdateEmployerSsoMutation();
  const [enableBrokerSso, { isLoading: isEnableBrokerSsoLoading }] =
    useEnableBrokerSsoMutation();
  const [updateBrokerSsoConfig, { isLoading: isUpdateBrokerSsoConfigLoading }] =
    useUpdateBrokerSsoConfigMutation();

  const isManaging =
    !isUndefined(data?.metadataUrl) && !isUndefined(data?.vendor);

  // Run on modal open / close
  useEffect(() => {
    if (isOpen && data?.metadataUrl && data?.vendor) {
      setVendor(data.vendor);
      setMetadataUrl(data.metadataUrl);
      validateMetadata({
        metadataUrl: data.metadataUrl,
        vendor: data.vendor,
      });
    }

    return () => {
      setVendor(undefined);
      setMetadataUrl(undefined);
      resetValidateMetadata();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleModalConfirm = async () => {
    if (metadataUrl && vendor) {
      await validateMetadata({
        metadataUrl: metadataUrl,
        vendor: vendor,
      }).unwrap();
    }
    if (data?.employerId) {
      if (isManaging) {
        await updateEmployerSso({
          employerId: data.employerId,
          metadataUrl: metadataUrl,
          vendor: vendor,
        }).unwrap();
      } else {
        await toggleEmployerSso({
          employerId: data.employerId,
          shouldEnable: true,
          metadataUrl: metadataUrl,
          vendor: vendor,
        }).unwrap();
      }
    }
    if (data?.organizationId) {
      if (isManaging) {
        await updateBrokerSsoConfig({
          organizationId: data.organizationId,
          metadataUrl: metadataUrl,
          vendor: vendor,
        }).unwrap();
      } else {
        await enableBrokerSso({
          organizationId: data.organizationId,
          metadataUrl: metadataUrl,
          vendor: vendor,
        }).unwrap();
      }
    }

    onUpdate();
    onCancel();
  };

  return (
    <ConfirmationDialog
      width={600}
      modalClassName={styles.modal}
      title={isManaging ? 'Manage SSO' : 'Enable SSO'}
      recordName={data?.name}
      visible={isOpen}
      confirmText={'Save'}
      onConfirm={() => handleModalConfirm()}
      onCancel={onCancel}
      closeModal={onCancel}
      cancelText="Cancel"
      centered
      isCancelLink
      destroyOnClose
      isRecordApplicable
      disableConfirmButton={isUndefined(vendor) || isUndefined(metadataUrl)}
      confirmLoading={
        isToggleEmployerSsoLoading ||
        isUpdateEmployerSsoLoading ||
        isEnableBrokerSsoLoading ||
        isMetadataValidating ||
        isUpdateBrokerSsoConfigLoading
      }
    >
      <FixedAlertMessage
        type={'warning'}
        message={
          'Once SSO is enabled, it cannot be disabled. Please contact support if you need assistance.'
        }
      />
      <div className={styles.field}>
        <span className={styles.label}>Vendor</span>
        <Select size="large" defaultValue={data?.vendor} onChange={setVendor}>
          {SSO_VENDOR_OPTIONS.map((option) => (
            <Select.Option key={option.value}>{option.label}</Select.Option>
          ))}
        </Select>
      </div>
      <div className={styles.field}>
        <span className={styles.label}>
          <div>Metadata URL</div>
          {isMetadataValid && (
            <div className={styles.validityLabel}>
              <CheckIcon width={25} height={25} />
              <div>Valid</div>
            </div>
          )}
          {isMetadataInvalid && (
            <div className={styles.validityLabel}>
              <XIcon className={styles.invalidIcon} width={25} height={25} />
              <div>Invalid</div>
            </div>
          )}
        </span>
        <div className={isMetadataInvalid ? styles.errorInputWrapper : ''}>
          <Input
            size="large"
            disabled={isUndefined(vendor)}
            defaultValue={data?.metadataUrl}
            onChange={(e) => setMetadataUrl(e.target.value)}
          />
        </div>
      </div>
    </ConfirmationDialog>
  );
};

export default ConfigureSsoModal;
