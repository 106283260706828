import React, { FC } from 'react';

import { Col, Row } from 'antd';
import styles from 'modules/idCards/components/IDCardRear/IDCardRear.module.less';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

const IDCardRear: FC<any> = ({ idData }) => {
  return (
    <div>
      <div className={styles.idCard}>
        <div className={styles.cardHeader}>
          <Row justify={'space-between'}>
            <Col>
              <img src={idData?.carrierLogoUrl} className={styles.logo1} />
            </Col>
            <Col>
              <img src={idData?.employerLogoUrl} className={styles.logo2} />
            </Col>
          </Row>
          <Row>
            <Col className={styles.membersAndProviderSection}>
              <span className={styles.heading}>Members and Providers</span>
              <p className={styles.description}>
                Network providers must call to request prior approval and
                confirm eligibility
              </p>
            </Col>
          </Row>
        </div>
        <div className={styles.cardBody}>
          <hr className={styles.line} />
          <Row
            justify={'space-between'}
            align={'middle'}
            className={styles.phoneRow}
          >
            <Col>
              <p className={styles.carrierTxt}>Carrier Phone #:</p>
            </Col>
            <Col>
              <p className={styles.carrierTxtPhone}>
                <OverflowPopover maxWidth={'130px'}>
                  {idData?.carrierPhone ?? ' '}
                </OverflowPopover>
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default IDCardRear;
