import axios from 'axios';
import { baseApi } from 'util/apiUtil';

const RFP_TAG_BASE_URL = `${baseApi}/v1/ai-tooling/rfp-tagging`;

export const createNewTag = async (brokerId: string, tagName: string) => {
  try {
    return await axios.post(
      `${RFP_TAG_BASE_URL}/create`,
      {},
      {
        params: {
          'organization-id': brokerId,
          'tag-name': tagName,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const getAllTags = async (brokerId: string) => {
  const response = await axios.get(`${RFP_TAG_BASE_URL}/all`, {
    params: {
      'organization-id': brokerId,
    },
  });
  return response?.data;
};

export const updateTag = async (tagId: string, updatedTag: string) => {
  return await axios.put(
    `${RFP_TAG_BASE_URL}/${tagId}/update`,
    {},
    {
      params: {
        'new-tag-name': updatedTag,
      },
    }
  );
};

export const deleteTag = async (brokerId: string, tagId: string) => {
  return await axios.delete(`${RFP_TAG_BASE_URL}/${tagId}/delete`, {
    params: {
      'organization-id': brokerId,
    },
  });
};
