import { FC } from 'react';

import { Col, Row } from 'antd';
import Modal, { ModalProps } from 'antd/lib/modal/Modal';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';

import styles from './confirmationDialog.module.less';

export interface ConfirmationDialogProps extends ModalProps {
  confirmText: string;
  cancelText?: string;
  closeModal?: () => void;
  onConfirm: () => void;
  onDelete?: () => void;
  deleteText?: string;
  resetZindex?: boolean;
  isCloseOnly?: boolean;
  disableConfirmButton?: boolean;
  modalClassName?: string;
  isCancelLink?: boolean;
  centered?: boolean;
  titleClassName?: string;
  titleClassNameSub?: string;
  isDanger?: boolean;
  recordName?: string;
  buttonsExpanded?: boolean;
  width?: number;
  className?: string;
  destroyOnClose?: boolean;
  isRecordApplicable?: boolean;
  confirmBtnFullWidth?: boolean;
  isConfirmApplicable?: boolean;
  closeBtnAdditionalStyling?: string;
  isCancelApplicable?: boolean;
  closeOnlyBtnFullWidth?: boolean;
  showPopover?: boolean;
  popOverContent?: string;
  icon?: React.ReactNode;
  showLongConfirmButton?: boolean;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
  const {
    title,
    children,
    visible,
    confirmText,
    cancelText,
    closeModal,
    onConfirm,
    isCloseOnly = false,
    disableConfirmButton,
    modalClassName,
    confirmLoading,
    isCancelLink = false,
    centered,
    titleClassName = styles.confirmationTitle,
    titleClassNameSub = styles.confirmationTitleSub,
    isDanger = false,
    recordName = '',
    buttonsExpanded = false,
    width,
    isRecordApplicable = false,
    destroyOnClose = false,
    confirmBtnFullWidth = false,
    isConfirmApplicable = true,
    closeBtnAdditionalStyling,
    isCancelApplicable = true,
    closeOnlyBtnFullWidth = false,
    showPopover = false,
    popOverContent = '',
    icon,
    onDelete = undefined,
    deleteText = undefined,
    resetZindex = false,
    showLongConfirmButton = false,
    ...rest
  } = props;

  const confirmationHeader = (
    <div>
      <div className={titleClassName}>
        {icon && <div className={styles.titleIcon}>{icon}</div>}
        {title}
      </div>
      {/* IsRecordApplicable prop is used, to show a sub text without making the button red color  */}
      {isDanger || isRecordApplicable ? (
        <div className={titleClassNameSub}>{recordName}</div>
      ) : (
        ''
      )}
    </div>
  );

  const getSelectedConfirmButton = () => {
    return !showLongConfirmButton
      ? isDanger
        ? styles.submitButtonDanger
        : ''
      : styles.submitButtonConfig;
  };

  const footerComponent = (
    <Row>
      {(confirmText !== '' || isConfirmApplicable) && (
        <Col span={24} className="sumbitButtonWrapper">
          {!showPopover ? (
            <PageActionButton
              className={`${
                confirmBtnFullWidth
                  ? styles.confirmButtonFullWidth
                  : buttonsExpanded
                  ? styles.confirmButtonExpanded
                  : styles.confirmButton
              } ${
                isCloseOnly
                  ? closeOnlyBtnFullWidth
                    ? styles.singleBtnFullWidth
                    : styles.singleBtn
                  : ''
              } ${getSelectedConfirmButton()}`}
              type="primary"
              onClick={onConfirm}
              disabled={disableConfirmButton}
              loading={confirmLoading}
            >
              {confirmText}
            </PageActionButton>
          ) : (
            <PlanyearPopover
              content={popOverContent}
              zIndex={9999}
              placement="bottom"
            >
              <PageActionButton
                className={`${
                  confirmBtnFullWidth
                    ? styles.confirmButtonFullWidthDisabled
                    : buttonsExpanded
                    ? styles.confirmButtonExpanded
                    : styles.confirmButton
                } ${
                  isCloseOnly
                    ? closeOnlyBtnFullWidth
                      ? styles.singleBtnFullWidth
                      : styles.singleBtn
                    : ''
                }  ${getSelectedConfirmButton()}`}
                type="primary"
                onClick={() => {}}
                loading={confirmLoading}
              >
                {confirmText}
              </PageActionButton>
            </PlanyearPopover>
          )}
        </Col>
      )}
      {!isCloseOnly && !isCancelLink && isCancelApplicable && (
        <Col span={24}>
          <SubmitButton
            className={`${styles.cancelButton} ${
              buttonsExpanded ? styles.cancelButtonExpanded : ''
            } ${
              closeBtnAdditionalStyling ? closeBtnAdditionalStyling : ''
            } cancelButton`}
            onClick={closeModal}
            loading={confirmLoading}
            disabled={confirmLoading}
          >
            {cancelText}
          </SubmitButton>
        </Col>
      )}
      {deleteText && onDelete && (
        <Col span={24}>
          <SubmitButton
            className={`${styles.deleteButton} ${
              buttonsExpanded ? styles.cancelButtonExpanded : ''
            }`}
            onClick={onDelete}
          >
            {deleteText}
          </SubmitButton>
        </Col>
      )}
      {isCancelLink && (
        <Col span={24}>
          <CancelButton
            htmlType="button"
            onClick={closeModal}
            disabled={confirmLoading}
            className={`${styles.cancelLink} cancelLink`}
          >
            {cancelText}
          </CancelButton>
        </Col>
      )}
    </Row>
  );

  return (
    <Modal
      title={confirmationHeader}
      visible={visible}
      wrapClassName={`${styles.confirmationContainer} ${
        resetZindex && styles.resetZindex
      }`}
      footer={footerComponent}
      className={modalClassName}
      centered={centered}
      width={width}
      destroyOnClose={destroyOnClose}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default ConfirmationDialog;
