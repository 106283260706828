import React, { useEffect, useRef, useState } from 'react';

import {
  Badge,
  Button,
  Divider,
  Form,
  notification,
  Popover,
  Row,
  Spin,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import reactHtmlParser from 'react-html-parser';
import dayjs from 'dayjs';

import { cloneDeep } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import LinkButton from 'components/buttons/LinkButton/LinkButton';
import OfferCardOverview from 'modules/renewals/components/helperComponents/OfferCardOverview/OfferCardOverview';
import RenewalCommonMainHeader from 'modules/renewals/components/helperComponents/RenewalCommonMainHeader/RenewalCommonMainHeader';
import RenewalCommonSubHeader from 'modules/renewals/components/helperComponents/RenewalCommonSubHeader/RenewalCommonSubHeader';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import CreditsDiscountsDetailsFooter from 'modules/renewals/components/CreditsDiscountsDetailsFooter/CreditsDiscountsDetailsFooter';
import ProposalsBenefitsCard from 'modules/renewals/components/ProposalsBenefitsCard/ProposalsBenefitsCard';
import FromCurrentDetailsFooter from 'modules/renewals/components/FromCurrentDetailsFooter/FromCurrentDetailsFooter';
import OfferStatusTag from 'components/OfferStatusTag/OfferStatusTag';
import PageActionDropdown from 'components/PageActionDropdown/PageActionDropdown';
import MultipleCreditsDiscountsDescriptionModal from 'modules/renewals/components/MultipleCreditsDiscountsDescriptionModal/MultipleCreditsDiscountsDescriptionModal';
import AddProposalModal from 'modules/renewals/components/addProposalsComponents/AddProposalModal/AddProposalModal';
import {
  exportProposalSummary,
  useLazyGetFinalApprovedProposalQuery,
  useLazyGetProposalCreditsQuery,
  useLazyGetProposalDiscountsQuery,
  useLazyGetProposalSummaryExportFilterOptionsQuery,
  useLazyGetProposalSummaryQuery,
  useProposalStatusUpdateMutation,
  useUpdateProposalStatusAsRecommendedMutation,
  useUpdateProposalMutation,
  useGetProposalByIdQuery,
  useLazyCheckDraftOfferExistForProposalQuery,
} from 'modules/renewals/slices/proposalSlice';
import {
  attachProposal,
  clearProposalObject,
} from 'modules/renewals/slices/proposalCreateStepperSlice';
import { ReactComponent as InternalNote } from 'assets/images/internal-note.svg';
import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import CreditIncludedExcludedModal from 'modules/renewals/components/CreditIncludedExcludedModal/CreditIncludedExcludedModal';
import EmployerSoldConfirmationModal from 'modules/renewals/components/soldConfirmation/EmployerSoldConfirmationModal/EmployerSoldConfirmationModal';

import {
  filterProposalOptionsForPlanYear,
  getERTooltipText,
  getMultipleCreditDiscountModalData,
  getProposalBenefitsOverviewData,
  hasCreditsDiscountsData,
  isFromCurrentExist,
  sortOfferNamesList,
  transformContributionsToCardDataList,
} from 'modules/renewals/utils/renewalsUtils';
import {
  currencyFormatterNoDecimals,
  currencyFormatterWithoutZeros,
  getKeyFromValue,
} from 'util/commonUtil';
import {
  CREDITS_INCLUSION,
  EXPORT_OFFERS_AND_PROPOSALS,
  EXPORT_PROPOSAL_BENEFIT_TYPE_DROPDOWN_OPTIONS,
  LIFE_DISABILITY_BENEFITS,
  MARK_AS_FINAL_PROPOSAL_MODAL_BROKER_BODY_TEXT,
  MARK_AS_FINAL_PROPOSAL_MODAL_CONFIRMATION_TEXT,
  MARK_AS_FINAL_PROPOSAL_MODAL_EMPLOYER_BODY_TEXT,
  OFFER_BENEFIT_TYPES,
  PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS,
  PROPOSAL_CARD_ACTION_OPTIONS,
  PROPOSAL_CARD_TAGS,
  PROPOSAL_DETAILED_RENEWAL_NAVIGATION_TYPES,
  MARK_AS_FINAL_APPROVED_MODAL_BROKER_BODY_TEXT_SECOND_PARA,
  DRAFT_OFFER_EXIST_ON_PROPOSAL_WARNING_MESSAGE,
  DRAFT_OFFER_EXIST_ON_PROPOSAL_BUTTON_TITLE,
  DRAFT_OFFER_EXIST_ON_PROPOSAL_WITH_SAME_NAME_WARNING_MESSAGE,
} from 'modules/renewals/constants/renewalsConstants';
import {
  CreditsInclusionType,
  ICreditsDiscountsDescriptionItem,
  IProposalActionsDropDownData,
  IProposalBenefitsCardData,
  IProposalBenefitsPageOverviewData,
  OfferBenefitValueType,
  OfferStatusKeyType,
  ProposalBenefitsPageActionType,
} from 'modules/renewals/types/renewalsTypes';
import { isNullOrUndefined } from 'modules/plans/utils';

import { ReactComponent as DownloadIcon } from 'assets/images/icon-download-black.svg';
import pencilIcon from 'assets/images/icon-pencil.svg';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import NotesCommonContent from 'modules/renewals/components/NotesCommonContent/NotesCommonContent';
import Drawer from 'components/Drawer/Drawer';
import { CommonNoteDto } from 'modules/renewals/models/CommonNoteDto';
import { ProposalNoteDto } from 'modules/renewals/models/ProposalNoteDto';
import {
  useCreateProposalNoteMutation,
  useLazyGetIndividualProposalNotesForIdQuery,
  useUpdateProposalNoteAsViewedMutation,
} from 'modules/renewals/slices/offersSlice';
import { baseApi } from 'util/apiUtil';
import { loginTypes } from 'constants/authConstants';
import InputForm from 'components/InputForm/InputForm';
import MultiSelectDropDown from 'modules/renewals/components/MultiSelectDropDown/MultiSelectDropDown';
import { setProposalsCreditMode } from 'modules/renewals/slices/offerReducerSlice';
import ComparePlans from 'modules/renewals/pages/offers/ComparePlans/ComparePlans';
import { MEDICAL } from 'modules/plans/planRates/pages/PlanRates/PlanRates';
import styles from 'modules/renewals/pages/proposals/ProposalsBenefits/ProposalsBenefits.module.less';
import { showSuccessNotification } from 'components/Notification/Notification';
import EditDescriptionModal from 'modules/renewals/components/EditDescriptionModal/EditDescriptionModal';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import {
  EMPLOYER_MEMBER_RESTRICTED,
  IndividualSubTypes,
} from 'constants/commonConstants';

const ProposalsBenefits = () => {
  const { brokerId, employerId, proposalId } = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const proposalDetails = useAppSelector(
    (state) => state.proposalOnBoarding.proposal
  );

  const [isActionsModalOpen, setIsActionsModalOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [effectiveDates, setEffectiveDates] = useState<string>('');
  const [tags, setTags] = useState<OfferStatusKeyType[]>([]);
  const [proposalsBenefitsDataList, setProposalsBenefitsDataList] = useState<
    IProposalBenefitsCardData[]
  >([]);
  const [overviewData, setOverviewData] =
    useState<IProposalBenefitsPageOverviewData>({});
  const [isCreditsDescriptionModalOpen, setIsCreditsDescriptionModalOpen] =
    useState<boolean>(false);
  const [creditsAndDiscountsData, setCreditsAndDiscountsData] = useState<{
    credits?: ICreditsDiscountsDescriptionItem[];
    discounts?: ICreditsDiscountsDescriptionItem[];
  }>({});
  const [isProposalCreateModalVisible, setIsProposalCreateModalVisible] =
    useState<boolean>(false);
  const [isCloneProposalOpen, setIsCloneProposalOpen] =
    useState<boolean>(false);
  const [proposalIdModal, setProposalIdModal] = useState<string>('');
  const [isDraftToPublished, setIsDraftToPublished] = useState<boolean>(false);
  const [isNotesVisible, setIsNotesVisible] = useState<boolean>(false);
  const [showProposalDescriptionEdit, setShowProposalDescriptionEdit] =
    useState<boolean>(false);

  const [showCreditModal, setShowCreditModal] = useState<{
    visible: boolean;
    type: CreditsInclusionType;
  }>({
    visible: false,
    type: CREDITS_INCLUSION.EXCLUDED.value,
  });
  const [actionsDropDownValues, setActionsDropDownValues] =
    useState<IProposalActionsDropDownData | null>(null);
  const [selectedBenefitType, setSelectedBenefitType] = useState<string[]>([]);
  const [displayExportPopup, setDisplayExportPopup] = useState(false);

  const [offerNameOptions, setOfferNameOptions] = useState<string[]>([]);
  const [allOfferOptions, setAllOfferOptions] = useState<string[]>([]);
  const [currentOfferOptions, setCurrentOfferOptions] = useState<string[]>([]);
  const [renewalOfferOptions, setRenewalOfferOptions] = useState<string[]>([]);
  const [selectedOfferNames, setSelectedOfferNames] = useState<string[]>([]);
  const [proposalOfferOptions, setProposalOfferOptions] = useState<string[]>(
    []
  );
  const selectedBenefitTypeRef = useRef<string[]>(selectedBenefitType);
  const selectedOfferNamesRef = useRef<string[]>(selectedOfferNames);
  const [planYearId, setPlanYearId] = useState<string | null>(null);
  const [
    isEmployerSoldConfirmationModalOpen,
    setIsEmployerSoldConfirmationModalOpen,
  ] = useState<boolean>(false);
  const [isFinalApprovedCompleted, setIsFinalApprovedCompleted] =
    useState<boolean>(false);
  const [effectiveDateRange, setEffectiveDateRange] = useState('');
  const [comparePlansVisible, setComparePlansVisible] =
    useState<boolean>(false);
  const [isProposalRecommended, setIsProposalRecommended] =
    useState<boolean>(false);

  const isPlanYearApplicable = (): boolean => {
    return null !== planYearId;
  };

  const [
    getProposalSummary,
    {
      isSuccess: isProposalSummaryFetchSuccess,
      data: proposalSummaryData,
      isLoading: isProposalSummaryLoading,
    },
  ] = useLazyGetProposalSummaryQuery();
  const [getProposalDiscount, { data: rawProposalDiscountData }] =
    useLazyGetProposalDiscountsQuery();
  const [getProposalCredit, { data: rawProposalCreditData }] =
    useLazyGetProposalCreditsQuery();

  const [updateOfferStatusAsRecommended, { isSuccess: markedAsRecommended }] =
    useUpdateProposalStatusAsRecommendedMutation();

  const creditsInclusion = useAppSelector(
    (state) => state.renewals.offers.selectedProposalsCreditMode
  );
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const employerObj: any = useAppSelector((state) => state?.layout?.employer);
  const isPlatformAdmin: boolean = appBootInfo?.type === loginTypes.platform;
  const isBrokerAdmin: boolean = appBootInfo?.type === loginTypes.bokerAdmin;
  const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;

  const [
    updateProposalStatus,
    {
      isSuccess: isUpdateProposalSuccess,
      reset,
      isLoading: isUpdateProposalLoading,
    },
  ] = useProposalStatusUpdateMutation();

  const [checkDraftExist] = useLazyCheckDraftOfferExistForProposalQuery();

  const [
    getUpcomingPlanYears,
    { isSuccess: isUpcomingFetchSuccess, data: upcomingPlanYearsData },
  ] = useLazyGetUpcomingPlanYearsByEmployerQuery();

  const [updateProposal] = useUpdateProposalMutation();

  const { data: proposalData } = useGetProposalByIdQuery({
    proposalId: proposalId ? proposalId : proposalDetails?.payload.id,
  });

  const [
    getProposalSummaryExportFilterOptions,
    {
      isLoading: isFetchingFilterOptions,
      isSuccess: isFilterOptionsFetchSuccess,
      isFetching: isFilterOptionsFetching,
      data: filterOptionsData,
    },
  ] = useLazyGetProposalSummaryExportFilterOptionsQuery();

  const [benefitTypes, setBenefitTypes] = useState<any[]>([]);

  const [
    getIndividualProposalNotesForOfferId,
    {
      data: proposalNotes,
      isLoading: proposalNoteDataIsLoading,
      isSuccess: proposalNoteDataIsSuccess,
    },
  ] = useLazyGetIndividualProposalNotesForIdQuery();

  const [
    publishProposal,
    {
      isSuccess: isPublishProposalSuccess,
      isLoading: isPublishProposalLoading,
    },
  ] = useProposalStatusUpdateMutation();

  const [
    updateProposalNoteAsViewed,
    {
      isLoading: isUpdateOfferNoteAsViewedLoading,
      isSuccess: isUpdateOfferNoteAsViewedSuccess,
    },
  ] = useUpdateProposalNoteAsViewedMutation();

  const [
    getFinalApprovedProposal,
    {
      data: finalApprovedProposal,
      isSuccess: isGetFinalApprovedProposalSuccess,
    },
  ] = useLazyGetFinalApprovedProposalQuery();

  const changeProposalStatus = (
    proposalId: string,
    updatedOfferType: string
  ) => {
    updateOfferStatusAsRecommended({
      proposalId: proposalId,
      isRecommended: PROPOSAL_CARD_TAGS.RECOMMENDED === updatedOfferType,
    });
    setIsProposalRecommended(
      PROPOSAL_CARD_TAGS.RECOMMENDED === updatedOfferType
    );
  };

  const handleMarkAsFinalApproved = (proposalId: string): void => {
    if (isErAdmin) {
      publishProposal({
        proposalId,
        proposalStatus: 'APPROVED',
        isNotificationActive: true,
      });
    } else {
      setIsFinalApprovedCompleted(false);
      setEffectiveDateRange(
        `${dayjs(proposalSummaryData?.effectiveStartDate).format(
          'll'
        )} - ${dayjs(proposalSummaryData?.effectiveEndDate).format('ll')}`
      );
      setIsEmployerSoldConfirmationModalOpen(true);
    }
  };

  const handleUnmarkAsFinalApproved = (proposalId: string) => {
    publishProposal({ proposalId, proposalStatus: 'PUBLISHED' });
  };

  useEffect(() => {
    getFinalApprovedProposal({ employerId: String(employerId) });
    // eslint-disable-next-line
  }, [isPublishProposalSuccess]);

  useEffect(() => {
    if (isFinalApprovedCompleted) {
      setIsEmployerSoldConfirmationModalOpen(false);
      loadDataSet();
    }
    // eslint-disable-next-line
  }, [isFinalApprovedCompleted]);

  const updateOfferAndCarrierFilters = (currSelectedBenefitTypes: any[]) => {
    const offerNames: Set<string> = new Set();
    const allOffers: Set<string> = new Set();
    const currentOffers: Set<string> = new Set();
    const renewalOffers: Set<string> = new Set();

    currSelectedBenefitTypes?.forEach((data) => {
      filterOptionsData?.offerNames?.[data?.value]?.forEach(
        (offerName: string) => {
          offerNames.add(offerName);
          allOffers.add(offerName);
        }
      );
      filterOptionsData?.currentOfferNames?.[data?.value]?.forEach(
        (offerName: string) => {
          allOffers.add(offerName);
        }
      );
      filterOptionsData?.renewalOfferNames?.[data?.value]?.forEach(
        (offerName: string) => {
          renewalOffers.add(offerName);
          allOffers.add(offerName);
        }
      );
    });

    setAllOfferOptions(Array.from(allOffers));
    setOfferNameOptions(Array.from(offerNames));
    setCurrentOfferOptions(Array.from(currentOffers));
    setRenewalOfferOptions(Array.from(renewalOffers));
    setProposalOfferOptions(Array.from(filterOptionsData?.offersInProposal));
    setSelectedOfferNames(
      Array.from(currentOffers).concat(
        Array.from(filterOptionsData?.offersInProposal)
      )
    );
  };

  useEffect(() => {
    if (!benefitTypes || !isFilterOptionsFetchSuccess) {
      return;
    }

    updateOfferAndCarrierFilters(
      benefitTypes?.filter((type) => {
        return selectedBenefitType?.includes(type?.value);
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBenefitType]);

  const handlePublish = async () => {
    const isOffersExist: boolean = checkIfOffersExist();
    const draftValidation = await checkDraftExist({
      id: proposalId ?? '',
    });
    const { draftExist = false, publishedExist = false } =
      draftValidation?.data ?? {};
    if (!isOffersExist) {
      setActionsDropDownValues({
        title: 'Publish Proposal',
        body: 'Are you sure you want to publish this proposal? This proposal currently does not have any offers selected',
        confirmTitle: 'Yes - Publish Proposal',
        onConfirm: () => {
          moveProposalToPublished();
        },
      });
    } else if (draftExist) {
      setActionsDropDownValues({
        title: 'Publish Proposal',
        body: (
          <div>
            {DRAFT_OFFER_EXIST_ON_PROPOSAL_WARNING_MESSAGE}
            {publishedExist && (
              <div className={styles.draftOfferPopUpContainer}>
                {DRAFT_OFFER_EXIST_ON_PROPOSAL_WITH_SAME_NAME_WARNING_MESSAGE}
              </div>
            )}
          </div>
        ),
        confirmTitle: DRAFT_OFFER_EXIST_ON_PROPOSAL_BUTTON_TITLE,
        onConfirm: () => {
          moveProposalToPublished();
        },
      });
      setIsActionsModalOpen(true);
    } else {
      moveProposalToPublished();
    }
  };

  useEffect(() => {
    if (isFilterOptionsFetchSuccess && benefitTypes) {
      setSelectedBenefitType(
        benefitTypes?.map((data) => {
          return data?.value;
        })
      );
    }
  }, [isFilterOptionsFetchSuccess, benefitTypes]);

  useEffect(() => {
    if (isFilterOptionsFetchSuccess) {
      const benefitTypes = EXPORT_PROPOSAL_BENEFIT_TYPE_DROPDOWN_OPTIONS.filter(
        (option) => {
          return filterOptionsData?.categories?.includes(option?.value);
        }
      );
      setBenefitTypes(benefitTypes);
      updateOfferAndCarrierFilters(benefitTypes);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterOptionsFetchSuccess, isFilterOptionsFetching]);

  useEffect(() => {
    selectedBenefitTypeRef.current = selectedBenefitType;
    selectedOfferNamesRef.current = selectedOfferNames;
  }, [selectedBenefitType, offerNameOptions, selectedOfferNames]);

  useEffect(() => {
    getUpcomingPlanYears({ employerId: String(employerId) });
    // eslint-disable-next-line
  }, [employerId]);

  const getEffectiveDateId = () => {
    const startDate = proposalSummaryData?.effectiveStartDate;
    const endDate = proposalSummaryData?.effectiveEndDate;

    if (isUpcomingFetchSuccess) {
      for (const planYear of upcomingPlanYearsData?.upcomingPlanYears!) {
        if (
          planYear.effectiveStartDate === startDate &&
          planYear.effectiveEndDate === endDate
        ) {
          return planYear.id;
        }
      }
    }
  };

  useEffect(() => {
    if (markedAsRecommended) {
      loadDataSet();
      showSuccessNotification({
        message: 'Successfully Updated',
        description: isProposalRecommended
          ? 'The Proposal has been successfully marked as Recommended'
          : 'The Proposal has been successfully removed from Recommended proposals',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markedAsRecommended]);

  useEffect(() => {
    loadDataSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    proposalId,
    employerId,
    getProposalSummary,
    getProposalDiscount,
    getProposalCredit,
    isGetFinalApprovedProposalSuccess,
  ]);

  const exportFile = () => {
    const currentBenefitTypes = selectedBenefitTypeRef.current;
    const currentSelectedOfferNames = selectedOfferNamesRef.current;
    const effectiveDateId = getEffectiveDateId();
    if (employerId && currentBenefitTypes && proposalId && effectiveDateId) {
      notification['info']({
        closeIcon: <></>,
        top: 40,
        message: 'Download Started',
        description:
          'Please wait while we export the summary excel. This process may take a few minutes',
        icon: <DownloadIcon className={styles.downloadIcon} />,
        duration: 4,
      });
      exportProposalSummary(
        employerId,
        effectiveDateId,
        proposalId,
        currentBenefitTypes,
        employerObj.name,
        currentSelectedOfferNames,
        false
      );
    }
  };

  const loadDataSet = () => {
    getProposalDiscount({ proposalId: String(proposalId) });
    getProposalCredit({ proposalId: String(proposalId) });
    getProposalSummary({
      proposalId: String(proposalId),
      employerId: String(employerId),
    });
  };

  useEffect(() => {
    if (isUpdateProposalSuccess || isPublishProposalSuccess) {
      setIsActionsModalOpen(false);
      loadDataSet();
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateProposalSuccess, isPublishProposalSuccess]);

  useEffect(() => {
    if (isProposalSummaryFetchSuccess && proposalSummaryData) {
      const overviewData: IProposalBenefitsPageOverviewData =
        getProposalBenefitsOverviewData(
          proposalSummaryData,
          creditsInclusion === CREDITS_INCLUSION.INCLUDED.value
        );

      const proposalsBenefitsDataList = transformContributionsToCardDataList(
        proposalSummaryData?.bundleContributions || [],
        proposalSummaryData.discountSummary,
        rawProposalCreditData?.offerCredits,
        creditsInclusion === CREDITS_INCLUSION.INCLUDED.value
      );

      const formattedTagData: OfferStatusKeyType[] = [];
      if (proposalSummaryData.proposalStatus === 'APPROVED') {
        formattedTagData.push('FINAL_APPROVED');
      }
      if (
        proposalSummaryData.proposalStatus === 'DRAFT' &&
        !isDraftToPublished
      ) {
        formattedTagData.push('DRAFT');
      }
      if (proposalSummaryData.recommended) {
        formattedTagData.push('RECOMMENDED');
      }
      if (proposalSummaryData.proposalStatus === 'PUBLISHED') {
        formattedTagData.push('PUBLISH');
      }

      const effectiveDateRange = `${dayjs(
        proposalSummaryData.effectiveStartDate
      ).format('ll')} - ${dayjs(proposalSummaryData.effectiveEndDate).format(
        'll'
      )}`;

      setOverviewData(overviewData);
      setTitle(proposalSummaryData.proposalName || '');
      setDescription(proposalSummaryData.proposalDescription || '');
      setProposalsBenefitsDataList(proposalsBenefitsDataList || []);
      setTags(formattedTagData);
      setEffectiveDates(effectiveDateRange || '');
      setPlanYearId(proposalSummaryData?.planYearId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    proposalSummaryData,
    rawProposalCreditData,
    rawProposalDiscountData,
    creditsInclusion,
    isDraftToPublished,
  ]);

  const resetSelectedValues = () => {
    setDisplayExportPopup(false);
    setSelectedBenefitType(
      benefitTypes.map((data) => {
        return data.value;
      })
    );
  };

  /**
   * Handles view description button click across page. Will not trigger modal display
   * if the modal will be empty
   * @param {string} type
   */
  const handleViewDescriptionClick = (type?: OfferBenefitValueType) => {
    const offerBenefitKeys =
      type !== undefined
        ? [getKeyFromValue(OFFER_BENEFIT_TYPES, type)]
        : undefined;
    if (offerBenefitKeys?.includes('LIFE_AND_DISABILITY')) {
      offerBenefitKeys.push(...LIFE_DISABILITY_BENEFITS);
    }
    const data = getMultipleCreditDiscountModalData(
      rawProposalCreditData,
      rawProposalDiscountData,
      offerBenefitKeys
    );
    if (data.credits.length !== 0 || data.discounts.length !== 0) {
      setCreditsAndDiscountsData(data);
      setIsCreditsDescriptionModalOpen(true);
    }
  };

  const handleCloneProposal = (id: string) => {
    setProposalIdModal(id || '');
    setIsCloneProposalOpen(true);
    setIsProposalCreateModalVisible(true);
  };

  const isEmpMemberCreatedProposal =
    appBootInfo?.workEmail === proposalSummaryData?.createdUserEmail &&
    appBootInfo?.individualSubType === IndividualSubTypes.EMPLOYER_MEMBER;

  const isEmpMember =
    appBootInfo?.individualSubType === IndividualSubTypes.EMPLOYER_MEMBER;

  const getDropdownOptions = () => {
    let options: any = [];
    if (isEmpMemberCreatedProposal) {
      options?.push(PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.COMPARE_PLANS);
      options?.push(PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.EDIT_PROPOSAL);
      options?.push(PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.CLONE_PROPOSAL);
      if (!tags?.includes('DRAFT')) {
        if (tags?.includes('RECOMMENDED')) {
          options.push(PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.REMOVE_RECOMMEND);
        } else {
          options.push(PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.RECOMMEND);
        }
      }
      return options;
    } else if (isEmpMember) {
      options?.push(PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.COMPARE_PLANS);
    } else {
      options?.push(PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.EDIT_PROPOSAL);
      options?.push(PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.CLONE_PROPOSAL);
      options?.push(PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.COMPARE_PLANS);
      if (!tags?.includes('DRAFT')) {
        if (tags?.includes('RECOMMENDED')) {
          options.push(PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.REMOVE_RECOMMEND);
        } else {
          options.push(PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.RECOMMEND);
        }
      }
      if (tags?.includes('DRAFT')) {
        options?.push(PROPOSAL_CARD_ACTION_OPTIONS.PUBLISH);
      }
      if (!tags?.includes('DRAFT')) {
        options?.push(PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.MOVE_TO_DRAFTS);
      }
      if (!tags?.includes('DRAFT')) {
        if (tags?.includes('FINAL_APPROVED')) {
          options.push(PROPOSAL_CARD_ACTION_OPTIONS.UNMARK_FINAL_APPROVED);
        } else {
          isGetFinalApprovedProposalSuccess &&
          finalApprovedProposal?.finalApproved
            ? options.push(PROPOSAL_CARD_ACTION_OPTIONS.FINAL_APPROVED_DISABLED)
            : options.push(PROPOSAL_CARD_ACTION_OPTIONS.FINAL_APPROVED);
        }
      }
    }

    if (isPlanYearApplicable()) {
      options = filterProposalOptionsForPlanYear(options);
    }

    return options;
  };

  const checkIfOffersExist = (): boolean => {
    return proposalSummaryData?.bundleContributions?.length !== 0 ?? false;
  };

  const handleProposalNotesClick = () => {
    setIsNotesVisible(true);
    getIndividualProposalNotesForOfferId({ proposalId: String(proposalId) });
  };

  const handleComparePlans = () => {
    setComparePlansVisible(true);
  };

  const extractMDVTypes = (proposalsBenefitsDataList: any[]) => {
    const MDVTypes = [
      OFFER_BENEFIT_TYPES.MEDICAL,
      OFFER_BENEFIT_TYPES.DENTAL,
      OFFER_BENEFIT_TYPES.VISION,
    ];

    const benefitTypes = proposalsBenefitsDataList
      ?.filter((item) => MDVTypes.includes(item?.type ?? ''))
      .map((offer) => offer?.type);

    // Use filter to create an array with unique strings
    const uniqueBen = benefitTypes?.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    return uniqueBen;
  };

  const saveProposalDescription = async (description: string) => {
    const proposalUpdated = cloneDeep(proposalDetails.payload);
    proposalUpdated.description = description;
    await updateProposal({
      proposalId: proposalId ?? '',
      proposal: proposalUpdated,
    });
    getProposalSummary({
      proposalId: String(proposalId),
      employerId: String(employerId),
    });
    setShowProposalDescriptionEdit(false);
  };

  const handleProposalsBenefitsPageActions = (
    action: ProposalBenefitsPageActionType
  ) => {
    // TODO: Implement handlers, modals.
    switch (action) {
      case 'EDIT_PROPOSAL':
        setProposalIdModal(String(proposalId));
        setIsDraftToPublished(false);
        setIsProposalCreateModalVisible(true);
        break;
      case 'CLONE_PROPOSAL':
        handleCloneProposal(String(proposalId) || '');
        break;
      case 'COMPARE_PLANS':
        handleComparePlans();
        break;
      case 'EXPORT_PROPOSAL':
        setDisplayExportPopup(true);
        getProposalSummaryExportFilterOptions({
          proposalId: proposalId ?? '',
          upcomingPlanYearId: undefined,
        });
        setActionsDropDownValues({
          title: EXPORT_OFFERS_AND_PROPOSALS,
          body: '',
          confirmTitle: 'Export',
          showFileLinks: false,
          excelFile: 'aaa',
          powerpointFile: 'bb',
          onConfirm: () => {
            exportFile();
            resetSelectedValues();
          },
        });
        break;
      case 'FINAL_APPROVED':
        setActionsDropDownValues({
          title: 'Mark as Final Approved',
          subTitle: isErAdmin
            ? `${title} - ${effectiveDates}`
            : `${employerObj.name} - ${title} - ${effectiveDates}`,
          body: !isErAdmin ? (
            <div>
              <p>{MARK_AS_FINAL_PROPOSAL_MODAL_BROKER_BODY_TEXT}</p>
              <p>{MARK_AS_FINAL_APPROVED_MODAL_BROKER_BODY_TEXT_SECOND_PARA}</p>
            </div>
          ) : (
            <div>
              <p>{MARK_AS_FINAL_PROPOSAL_MODAL_EMPLOYER_BODY_TEXT}</p>
            </div>
          ),
          confirmTitle: isErAdmin
            ? MARK_AS_FINAL_PROPOSAL_MODAL_CONFIRMATION_TEXT
            : 'Continue',
          onConfirm: () => {
            handleMarkAsFinalApproved(proposalId ?? '');
            setIsActionsModalOpen(false);
          },
        });
        setIsActionsModalOpen(true);
        break;
      case 'UNMARK_FINAL_APPROVED': {
        setActionsDropDownValues({
          title: 'Unmark as Final Approved',
          body: (
            <div>
              <p>
                Are you sure you want to remove Final Approved from this
                proposal?
              </p>
              <p>
                If you have already sent the Sold Confirmation or added this
                proposal to a new Plan Year, you may need to perform these
                actions again after re-finalizing a proposal.
              </p>
            </div>
          ),
          confirmTitle: 'Yes - Unmark as Final Approved',
          onConfirm: () => {
            handleUnmarkAsFinalApproved(proposalId || '');
          },
        });
        setIsActionsModalOpen(true);
        break;
      }
      case 'MOVE_TO_DRAFTS':
        if ('APPROVED' === proposalSummaryData?.proposalStatus) {
          setActionsDropDownValues({
            title: 'Move to Drafts',
            body: 'Confirming the action will move the proposal to the draft section and will remove the final approved',
            confirmTitle: 'Yes - Move Proposal to Drafts',
            onConfirm: () => {
              moveProposalToDraft();
            },
          });
          setIsActionsModalOpen(true);
        } else {
          setActionsDropDownValues({
            title: 'Move to Drafts',
            body: 'Are you sure you want to move this proposal to drafts? It will only be available in the draft tab of admin viewing.',
            confirmTitle: 'Yes - Move Proposal to Drafts',
            onConfirm: () => {
              moveProposalToDraft();
            },
          });
          setIsActionsModalOpen(true);
        }
        break;
      case 'PUBLISH':
        handlePublish();
        break;
      case 'RECOMMEND':
        changeProposalStatus(String(proposalId), 'RECOMMENDED');
        break;
      case 'REMOVE_RECOMMEND':
        changeProposalStatus(String(proposalId), 'REMOVE_RECOMMEND');
        break;
      default:
        break;
    }
  };

  const moveProposalToDraft = () => {
    setIsDraftToPublished(false);
    updateProposalStatus({
      proposalId: proposalId ?? '',
      proposalStatus: 'DRAFT',
    });
  };

  const moveProposalToPublished = () => {
    setIsDraftToPublished(true);
    updateProposalStatus({
      proposalId: proposalId ?? '',
      proposalStatus: 'PUBLISHED',
    });
  };

  const handleProposalsBenefitsCardViewDetails = (offerId: string) => {
    navigate(`offer-details/${offerId}`);
  };

  const onConfirmCreditSave = () => {
    dispatch(setProposalsCreditMode(showCreditModal.type));
    setShowCreditModal({
      visible: false,
      type:
        creditsInclusion === CREDITS_INCLUSION.INCLUDED.value
          ? CREDITS_INCLUSION.EXCLUDED.value
          : CREDITS_INCLUSION.INCLUDED.value,
    });
  };

  const setProposalModalOpen = (isOpen: boolean) => {
    setIsProposalCreateModalVisible(isOpen);
    if (!isOpen) {
      setProposalIdModal('');
      dispatch(clearProposalObject());
      if (employerId) {
        getProposalSummary({
          proposalId: String(proposalId),
          employerId: String(employerId),
        });
      }
    }
  };

  useEffect(() => {
    if (isUpdateOfferNoteAsViewedSuccess) {
      setIsNotesVisible(false);
      loadDataSet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateOfferNoteAsViewedLoading, isUpdateOfferNoteAsViewedSuccess]);

  const handleFileItemDownloadClickForInternalNote = (
    s3Key: string,
    item: string
  ) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const url = `${baseApi}/v1/renewals/offer-proposals/proposal-notes/download?s3-key=${s3Key}&file-name=${item}`;
    a.href = url;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const [
    createProposalInternalNote,
    { isLoading: isCreateProposalLoading, isSuccess: isCreateProposalSuccess },
  ] = useCreateProposalNoteMutation();

  useEffect(() => {
    if (isCreateProposalSuccess) {
      getIndividualProposalNotesForOfferId({ proposalId: String(proposalId) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateProposalLoading, isCreateProposalSuccess]);

  const handleCreateOfferNoteSubmit = (proposalNote: CommonNoteDto) => {
    const proposalNoteDto: ProposalNoteDto = {
      proposalId: String(proposalId),
      comment: proposalNote.commentTxt,
      files: proposalNote.fileList,
      organizationId: String(brokerId),
      individualId: String(appBootInfo?.individualId),
    };

    createProposalInternalNote({ proposalNote: proposalNoteDto });
  };

  const handleNotesDrawerClose = () => {
    updateProposalNoteAsViewed({
      proposalId: String(proposalId),
      individualId: String(appBootInfo?.individualId),
    });
  };

  const isActionInProgress = () => {
    if (
      actionsDropDownValues?.title ===
      PROPOSAL_CARD_ACTION_OPTIONS.UNMARK_FINAL_APPROVED.label
    ) {
      return isPublishProposalLoading;
    }
    return isUpdateProposalLoading;
  };

  const getHsaContribution = () => {
    return (
      proposalSummaryData?.bundleContributions?.find(
        (item: any) => item?.category === 'MEDICAL'
      )?.annualContributionDefault?.totalHsaContribution ?? 0
    );
  };

  const getHraContribution = (): number => {
    return proposalSummaryData?.totalHraCost ?? 0;
  };

  const proposalEditLinkButton = (
    <LinkButton
      onClick={async () => {
        dispatch(attachProposal(proposalData));
        setShowProposalDescriptionEdit(true);
      }}
      icon={<img src={pencilIcon} />}
    />
  );

  const permittedProposalLinkButton = usePermitByUserType(
    proposalEditLinkButton,
    EMPLOYER_MEMBER_RESTRICTED
  );

  const isHra = (): boolean => getHraContribution() !== 0;
  const isHsa = (): boolean => getHsaContribution() !== 0;
  const isAdditionalCost = (): boolean =>
    (rawProposalCreditData?.costExist ?? false) &&
    creditsInclusion === 'INCLUDED';

  const getErPopoverContent = (): JSX.Element | string | null => {
    const creditContent = renderCostsForToolTip(true);

    if (isHsa() && isHra()) {
      return (
        <>
          <span>
            ER HSA Funding :{' '}
            {currencyFormatterWithoutZeros(getHsaContribution())}
          </span>
          <br />
          <span>
            ER HRA Cost : {currencyFormatterWithoutZeros(getHraContribution())}
          </span>
          <br />
          {isAdditionalCost() && creditContent}
        </>
      );
    } else if (isHra()) {
      return (
        <>
          ER HRA Cost : {currencyFormatterWithoutZeros(getHraContribution())}
          <br />
          {isAdditionalCost() && creditContent}
        </>
      );
    } else if (isHsa()) {
      return (
        <>
          ER HSA Funding : {currencyFormatterWithoutZeros(getHsaContribution())}
          <br />
          {isAdditionalCost() && creditContent}
        </>
      );
    } else if (isAdditionalCost()) {
      return <>{creditContent}</>;
    }
    return null;
  };

  const getEETooltipText = (): string | null => {
    if (isAdditionalCost()) {
      return '(Incl. Additional costs)';
    }
    return null;
  };

  const renderCostsForToolTip = (isEr: boolean): JSX.Element[] => {
    return (
      rawProposalCreditData?.credits
        ?.filter((credit) => credit.type === 'COST')
        .map((credit) => (
          <span key={credit.description}>
            {credit.description}:{' '}
            {currencyFormatterWithoutZeros(
              isEr ? credit.erAmount ?? 0 : credit.eeAmount ?? 0
            )}
            <br />
          </span>
        )) ?? []
    );
  };

  const getEEPopoverContent = (): JSX.Element | string | null => {
    const creditContent = renderCostsForToolTip(false);

    if (isAdditionalCost()) {
      return <>{creditContent}</>;
    }

    return null;
  };

  return isProposalSummaryLoading || isUpdateOfferNoteAsViewedLoading ? (
    <Spin />
  ) : (
    <>
      <div className={styles.proposalsBenefits}>
        <Row justify="space-between">
          <div className={styles.titleSection}>
            <BreadcrumbNavigation
              breadCrumbData={[
                {
                  link: `/brokers/${brokerId}/employers/${employerId}/renewals/proposals`,
                  name: 'All Proposals',
                },
              ]}
            />
            <RenewalCommonMainHeader
              mainText={
                <div className={styles.title}>
                  <span>{title}</span>
                  <div className={styles.tagSection}>
                    {tags.map((item, idx) => (
                      <OfferStatusTag
                        offerStatus={item}
                        key={idx}
                        isApplicable={item === 'PUBLISH'}
                      />
                    ))}
                  </div>
                </div>
              }
            />
            <div className={styles.effectiveDates}>
              <span>Effective Dates:</span>
              <span>{effectiveDates}</span>
            </div>
          </div>
          <div>
            <PageActionDropdown
              className={styles.pageActionButton}
              options={Object.values(getDropdownOptions())}
              onChange={handleProposalsBenefitsPageActions}
            />
          </div>
        </Row>
        <Row
          justify="space-between"
          className={styles.overviewSection}
          wrap={false}
        >
          <OfferCardOverview
            className={styles.overviewItem}
            headerText="Total Benefits Cost"
            value={
              !isNullOrUndefined(overviewData?.totalBenefitsCost?.value)
                ? currencyFormatterNoDecimals(
                    overviewData?.totalBenefitsCost?.value || 0
                  )
                : '-'
            }
            footerElement={
              <FromCurrentDetailsFooter
                value={overviewData.totalBenefitsCost?.differenceValue}
                percentage={
                  overviewData.totalBenefitsCost?.differencePercentage
                }
                showCreditsDropdown
                creditsInclusion={creditsInclusion}
                onCreditsInclusionChange={(value: any) => {
                  setShowCreditModal({ visible: true, type: value });
                }}
                isNaApplicable={isFromCurrentExist(
                  overviewData.totalBenefitsCost?.differencePercentage
                )}
                isProposalBenefits={true}
              />
            }
          />
          <OfferCardOverview
            className={styles.overviewItem}
            headerText={
              <span>
                Employer Cost{' '}
                {getERTooltipText(isHsa(), isHra(), isAdditionalCost()) && (
                  <Popover
                    content={getErPopoverContent()}
                    overlayClassName={'popoverInner recommendedtooltip'}
                  >
                    <span className={styles.inclErHsaText}>
                      {getERTooltipText(isHsa(), isHra(), isAdditionalCost())}
                    </span>
                  </Popover>
                )}
              </span>
            }
            value={
              !isNullOrUndefined(overviewData?.erCost?.value)
                ? currencyFormatterNoDecimals(overviewData?.erCost?.value || 0)
                : '-'
            }
            footerElement={
              <FromCurrentDetailsFooter
                value={overviewData.erCost?.differenceValue}
                percentage={overviewData.erCost?.differencePercentage}
                isNaApplicable={isFromCurrentExist(
                  overviewData.erCost?.differencePercentage
                )}
              />
            }
          />
          <OfferCardOverview
            className={styles.overviewItem}
            headerText={
              <>
                Employee Cost
                {getEETooltipText() && (
                  <Popover
                    content={getEEPopoverContent()}
                    overlayClassName={'popoverInner recommendedtooltip'}
                  >
                    <span className={styles.inclErHsaText}>
                      {getEETooltipText()}
                    </span>
                  </Popover>
                )}
              </>
            }
            value={
              !isNullOrUndefined(overviewData?.eeCost?.value)
                ? currencyFormatterNoDecimals(overviewData?.eeCost?.value || 0)
                : '-'
            }
            footerElement={
              <FromCurrentDetailsFooter
                value={overviewData.eeCost?.differenceValue}
                percentage={overviewData.eeCost?.differencePercentage}
                isNaApplicable={isFromCurrentExist(
                  overviewData.eeCost?.differencePercentage
                )}
              />
            }
          />
          <OfferCardOverview
            className={styles.overviewItem}
            headerText="Credits & Discounts"
            value={
              overviewData?.creditsAndDiscounts
                ? currencyFormatterNoDecimals(
                    overviewData?.creditsAndDiscounts
                      .totalCreditsAndDiscounts || 0
                  )
                : '-'
            }
            footerElement={
              <CreditsDiscountsDetailsFooter
                credits={overviewData?.creditsAndDiscounts?.totalCredits}
                discounts={overviewData?.creditsAndDiscounts?.totalDiscounts}
                showViewDescription={hasCreditsDiscountsData(
                  rawProposalCreditData,
                  rawProposalDiscountData
                )}
                onViewDescriptionClick={() => handleViewDescriptionClick()}
              />
            }
          />
        </Row>
        <Row justify="space-between" align="top">
          <RenewalCommonSubHeader
            subText={
              <span className={styles.subHeader}>
                Proposal Description {permittedProposalLinkButton}
              </span>
            }
          />
          {(isBrokerAdmin || isPlatformAdmin) && (
            <p
              className={styles.brokerNotes}
              onClick={handleProposalNotesClick}
            >
              {proposalSummaryData?.isNewNotesExist && (
                <Badge color={'red'} className={styles.newComment} />
              )}
              <InternalNote className={styles.internalNoteIcon} />
              Broker Notes ({proposalSummaryData?.internalNoteCount})
            </p>
          )}
        </Row>
        <Row>
          <p>{reactHtmlParser(description)}</p>
        </Row>
        <Row>
          <RenewalCommonSubHeader subText={'Proposal Benefits'} />
        </Row>
        <Divider />
        <div className={styles.cardSection}>
          {proposalsBenefitsDataList &&
            proposalsBenefitsDataList.length > 0 &&
            proposalsBenefitsDataList.map((data, idx) => (
              <ProposalsBenefitsCard
                key={idx}
                data={data}
                onViewDescriptionClick={() =>
                  handleViewDescriptionClick(data.type)
                }
                hasCreditDiscountData={hasCreditsDiscountsData(
                  rawProposalCreditData,
                  rawProposalDiscountData,
                  data.type === 'Life & Disability'
                    ? LIFE_DISABILITY_BENEFITS
                    : [getKeyFromValue(OFFER_BENEFIT_TYPES, data.type)]
                )}
                headerElements={
                  <div className={styles.cardHeaderItems}>
                    {data.type !== 'Voluntary Benefits' && (
                      <Button
                        type="primary"
                        ghost
                        className={styles.viewDetailsButton}
                        onClick={() =>
                          handleProposalsBenefitsCardViewDetails(
                            PROPOSAL_DETAILED_RENEWAL_NAVIGATION_TYPES?.[
                              data.type.toLowerCase() || ''
                            ]
                          )
                        }
                      >
                        View Details
                      </Button>
                    )}
                  </div>
                }
              />
            ))}
        </div>
      </div>
      <MultipleCreditsDiscountsDescriptionModal
        isOpen={isCreditsDescriptionModalOpen}
        setIsOpen={setIsCreditsDescriptionModalOpen}
        data={creditsAndDiscountsData}
      />
      <AddProposalModal
        proposalId={proposalIdModal}
        isModalOpen={isProposalCreateModalVisible}
        setIsModalOpen={setProposalModalOpen}
        cloneProposal={isCloneProposalOpen}
        isProposalDetailedView={true}
      />
      <CreditIncludedExcludedModal
        type={showCreditModal.type}
        visible={showCreditModal.visible}
        onClose={() =>
          setShowCreditModal({
            visible: false,
            type:
              creditsInclusion === CREDITS_INCLUSION.INCLUDED.value
                ? CREDITS_INCLUSION.EXCLUDED.value
                : CREDITS_INCLUSION.INCLUDED.value,
          })
        }
        onConfirm={onConfirmCreditSave}
      />
      <ConfirmationDialog
        visible={isActionsModalOpen}
        isRecordApplicable={actionsDropDownValues?.subTitle !== undefined}
        recordName={actionsDropDownValues?.subTitle}
        onConfirm={() => actionsDropDownValues?.onConfirm()}
        confirmBtnFullWidth={true}
        cancelText={actionsDropDownValues?.showFileLinks ? '' : 'Cancel'}
        title={actionsDropDownValues?.title ?? ''}
        isCancelLink={!actionsDropDownValues?.showFileLinks}
        closeModal={() => setIsActionsModalOpen(false)}
        confirmText={actionsDropDownValues?.confirmTitle ?? ''}
        isCloseOnly={true}
        confirmLoading={isActionInProgress() ?? false}
        destroyOnClose={true}
      >
        <div className={styles.actionsDropdownBodyWrapper}>
          {actionsDropDownValues?.body}
        </div>
      </ConfirmationDialog>
      <ConfirmationDialog
        disableConfirmButton={
          selectedBenefitType?.length === 0 || selectedOfferNames?.length === 0
        }
        visible={displayExportPopup}
        isRecordApplicable={actionsDropDownValues?.subTitle !== undefined}
        recordName={actionsDropDownValues?.subTitle}
        onConfirm={() => actionsDropDownValues?.onConfirm()}
        confirmBtnFullWidth={true}
        cancelText={actionsDropDownValues?.showFileLinks ? '' : 'Cancel'}
        title={actionsDropDownValues?.title ?? ''}
        isCancelLink={!actionsDropDownValues?.showFileLinks}
        closeModal={() => {
          resetSelectedValues();
        }}
        confirmText={actionsDropDownValues?.confirmTitle ?? ''}
        isCloseOnly={true}
        destroyOnClose={true}
      >
        {isFetchingFilterOptions ? (
          <Spin />
        ) : (
          <div>
            <InputForm>
              <Form.Item
                label="Benefit Type"
                labelCol={{ span: 24 }}
                name="benefitType"
                rules={[
                  { required: false, message: 'Please select Benefit Type' },
                ]}
              >
                <MultiSelectDropDown
                  selectOptions={benefitTypes}
                  dropdownItemsSelected={selectedBenefitType}
                  changeDropdownItemsSelected={setSelectedBenefitType}
                  onSystemItemSave={() => {}}
                  displaySearch={true}
                  sortOptions={false}
                />
              </Form.Item>
              <Form.Item
                label="Offers"
                labelCol={{ span: 24 }}
                name="offerNames"
                rules={[{ required: false }]}
              >
                <MultiSelectDropDown
                  selectOptions={sortOfferNamesList(
                    allOfferOptions.map((name: any) => {
                      return {
                        label: name,
                        value: name,
                        renewal: renewalOfferOptions.includes(name),
                        current: currentOfferOptions.includes(name),
                        offersInProposal: proposalOfferOptions.includes(name),
                      };
                    })
                  )}
                  dropdownItemsSelected={selectedOfferNames ?? []}
                  changeDropdownItemsSelected={setSelectedOfferNames}
                  onSystemItemSave={() => {}}
                  displaySearch={true}
                  sortOptions={false}
                />
              </Form.Item>
            </InputForm>
          </div>
        )}
      </ConfirmationDialog>
      <Drawer
        visible={isNotesVisible}
        onClose={handleNotesDrawerClose}
        closable={false}
        width={550}
        bodyStyle={{ padding: '40px 34px' }}
      >
        <NotesCommonContent
          handleFileItemClick={handleFileItemDownloadClickForInternalNote}
          noteName={String(proposalSummaryData?.proposalName)}
          isCreateLoading={isCreateProposalLoading}
          handleSubmit={handleCreateOfferNoteSubmit}
          isFetchLoading={proposalNoteDataIsLoading}
          listOfComments={proposalNotes}
          individualId={String(appBootInfo?.individualId)}
          visibilityToggle={isNotesVisible}
          notesHeading={`Broker Notes (${
            !proposalNoteDataIsSuccess ? '' : proposalNotes.length
          })`}
        />
      </Drawer>
      <EmployerSoldConfirmationModal
        finalApprovalId={proposalId ?? ''}
        visible={isEmployerSoldConfirmationModalOpen}
        proposalName={proposalSummaryData?.proposalName ?? ''}
        effectiveDateRange={effectiveDateRange}
        onClose={() => {
          setIsEmployerSoldConfirmationModalOpen(false);
          loadDataSet();
        }}
        setIsFinalApprovedCompleted={setIsFinalApprovedCompleted}
      />
      <ComparePlans
        visible={comparePlansVisible}
        onClose={setComparePlansVisible}
        initialSelectedPlanIndex={0}
        benefitType={MEDICAL}
        offerStatus={'RENEWAL'}
        isProposal={true}
        proposalBenefitTypes={extractMDVTypes(proposalsBenefitsDataList) ?? []}
        proposalBenefitPlansData={proposalsBenefitsDataList ?? []}
      />

      <EditDescriptionModal
        visible={showProposalDescriptionEdit}
        onClose={() => setShowProposalDescriptionEdit(false)}
        confirmText="Save Description"
        label="Proposal Description"
        description={description}
        onConfirm={(description) => {
          saveProposalDescription(description);
        }}
      />
    </>
  );
};

export default ProposalsBenefits;
