import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Row, Spin } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import usePolling from 'hooks/usePolling';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import AddOffer from 'modules/renewals/pages/offers/AddOffer/AddOffer';
import {
  buildPremiums,
  getBenefitTitleFromUrlParam,
} from 'modules/renewals/utils/renewalsUtils';
import {
  useCreateOfferMutation,
  useGetPlanDetailsQuery,
  useLazyGetOfferExistsQuery,
} from 'modules/renewals/slices/offersSlice';
import {
  clear,
  fetchJobStatus,
  setStatus,
} from 'modules/renewals/slices/carrierQuoteAiSlice';
import CloseModalPopup from 'modules/renewals/components/CloseModalPopup/CloseModalPopup';
import CloseType from 'modules/renewals/enums/CloseType';
import QuoteProcessStatus from 'modules/renewals/enums/QuoteUploadStatus';
import RenewalType from 'modules/renewals/enums/RenewalType';
import { DEBOUNCE_WAIT_TIME_DOCUMENT_READING } from 'util/commonUtil';
import {
  ADD_PLAN_OPTIONS,
  BASIC_LIFE_AND_ADD,
  LIFE,
  LTD,
  MDV_PLANS,
  OFFER_CATEGORY,
  OFFER_STATUS,
  ORTHODONTIA_MAX,
  QUOTE_ADDING_PROPERTY,
  STD,
  URL_PARAM_TO_BENEFIT_TYPE,
} from 'modules/renewals/constants/renewalsConstants';
import { isNullOrUndefined } from 'modules/plans/utils';
import { ReviewType } from 'modules/plans/enums/ReviewType';

import styles from './addOfferModal.module.less';

type AddOfferModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  isEdit: boolean;
  onAddOffer: () => void;
  offerId?: string;
  isClonedOffer?: boolean;
  setSelectedTab?: Function;
  defaultStep?: number;
  onOpsRedirect?: () => void;
};

type DraftConfirmation = {
  visible: boolean;
  confirmationText: string;
  title: string;
  content: JSX.Element | string;
};

const AddOfferModal: FC<AddOfferModalProps> = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    isEdit,
    onAddOffer,
    offerId,
    isClonedOffer,
    setSelectedTab,
    defaultStep = 0,
    onOpsRedirect,
  } = props;
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState<number>(defaultStep);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [basicInfoValuesIsLoading, setBasicInfoValuesIsLoading] =
    useState<boolean>(false);
  const [offerData, setOfferData] = useState<any>(null);
  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);
  const [draftConfirmation, setDraftConfirmation] = useState<DraftConfirmation>(
    {
      visible: false,
      confirmationText: '',
      title: '',
      content: '',
    }
  );
  const {
    jobId,
    status,
    reviewType,
    offerId: quoteOfferID,
    isPlanNamesValid,
    requestInProgress,
  } = useAppSelector((state) => state.renewals.quoteReaderSlice);
  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );
  const disableOnQuoteProcessing =
    (status ?? false) || status != QuoteProcessStatus.SAVED || isOpsAdmin;

  const isProcessing =
    [QuoteProcessStatus.PROCESSING, QuoteProcessStatus.VALIDATED].includes(
      status
    ) && isPlanNamesValid;

  const ref = useRef<any>(null);
  const pollingCount = usePolling(
    isProcessing!,
    reviewType,
    DEBOUNCE_WAIT_TIME_DOCUMENT_READING
  );

  const {
    data: planDetailsData,
    isFetching: planDetailsIsFetching,

    refetch,
  } = useGetPlanDetailsQuery(
    { offerId: offerId ?? quoteOfferID ?? '' },
    {
      skip: isEmpty(offerId) && isEmpty(quoteOfferID),
    }
  );

  // should't show spinner in self review flow if processing is not started
  const shouldShowSpinner = !(
    reviewType === ReviewType.AUTOMATIC &&
    isPlanNamesValid &&
    [
      QuoteProcessStatus.UPLOADED,
      QuoteProcessStatus.UPLOADING,
      QuoteProcessStatus.PROCESSING,
      QuoteProcessStatus.VALIDATED,
      QuoteProcessStatus.VALIDATING,
    ].includes(status)
  );

  const [getOfferNameExists] = useLazyGetOfferExistsQuery();
  const [createOffer, { isLoading }] = useCreateOfferMutation();

  const getEditData = useCallback(() => {
    if (offerId || quoteOfferID) {
      return {
        ...planDetailsData,
        offerId: isClonedOffer ? undefined : planDetailsData?.offerId,
        offerDocumentId: isClonedOffer
          ? undefined
          : planDetailsData?.offerDocumentId,
      };
    }
  }, [offerId, quoteOfferID, isClonedOffer, planDetailsData]);

  const setPlanViewInQuotes = () => {
    const isCarrierQuote =
      planDetailsData?.planCreationType ===
        ADD_PLAN_OPTIONS.CARRIER_QUOTE.key && !isEmpty(jobId);
    const shouldLoadPlanView =
      isCarrierQuote &&
      [
        QuoteProcessStatus.SUCCESS,
        QuoteProcessStatus.FAILED,
        QuoteProcessStatus.REVIEWED,
        QuoteProcessStatus.REJECTED,
        QuoteProcessStatus.PROCESSING,
        isOpsAdmin && QuoteProcessStatus.SAVED,
      ].includes(status);
    return shouldLoadPlanView;
  };

  useEffect(() => {
    if (deleteConfirmation) {
      setCurrentStep(0);
      setIsModalOpen(false);
      setDeleteConfirmation(false);
    }
  }, [deleteConfirmation, setIsModalOpen]);

  /**
   * Fetches the quote status based on the plan details data.
   * If the plan creation type is "CARRIER_QUOTE", it retrieves the extraction details
   * and dispatches a fetchJobStatus action with the extracted id and status.
   */
  useEffect(() => {
    if (!isEmpty(jobId) && !requestInProgress && isModalOpen) {
      dispatch(fetchJobStatus(isOpsAdmin, jobId, refetch));
    }
    if (setPlanViewInQuotes()) {
      // should set to the second step if the quote is processed
      setCurrentStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, pollingCount, isModalOpen]);

  const changeCurrentStep = (activeStep: number) => {
    setCurrentStep(activeStep);
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const setExtractionDetails = () => {
    if (
      planDetailsData?.planCreationType ===
        ADD_PLAN_OPTIONS.CARRIER_QUOTE.key &&
      planDetailsData?.extractionDetails
    ) {
      dispatch(
        setStatus({
          status: planDetailsData?.extractionDetails?.status,
          jobId: planDetailsData?.extractionDetails?.id,
          offerId: planDetailsData?.offerId,
        })
      );
    }
  };

  const resetQuoteURL = () => {
    if (disableOnQuoteProcessing) {
      navigate(
        `/brokers/${params?.brokerId}/employers/${params.employerId}/renewals/carrier/${params.benefitKind}`
      );
    }
  };

  const closeOfferModal = async () => {
    setVisibleConfirmation(true);
    setBasicInfoValuesIsLoading(true);
    await ref?.current?.basicInfoValuesSave()?.then((data: any) => {
      setOfferData(data);
    });
    setBasicInfoValuesIsLoading(false);
  };

  const closeModalWithoutPopup = ({
    isRedirectToOps,
    isNavigateToCurrentTab,
  }: {
    isRedirectToOps?: boolean;
    isNavigateToCurrentTab: boolean;
  }) => {
    if (isRedirectToOps && onOpsRedirect) {
      onOpsRedirect();
    }
    if (isNavigateToCurrentTab) {
      setSelectedTab?.((currentTab: string) => currentTab);
    }
    setIsModalOpen(false);
    changeCurrentStep(0);
    resetQuoteURL();
  };

  const assignCloseType = () => {
    if (!offerData || !ref?.current?.isQuotePlanApproved()) {
      return CloseType.ERROR;
    } else if (isEdit && planDetailsData?.status === 'PUBLISHED') {
      return CloseType.EDIT_PUBLISHED;
    } else if (isEdit && planDetailsData?.status === 'DRAFT') {
      return CloseType.EDIT_DRAFT;
    } else {
      return CloseType.CREATE;
    }
  };

  const handleCloseWithoutSaving = () => {
    setIsModalOpen(false);
    changeCurrentStep(0);
    setVisibleConfirmation(false);
    resetQuoteURL();
  };

  const buildLifePlans = () => {
    const lifePlanList = (offerData?.lifePlans ?? []).map((item: any) => ({
      ...item,
      volume: item?.volume?.formattedValue ?? item?.volume?.value ?? null,
      weeklyBenefitMax:
        item?.weeklyBenefitMax?.formattedValue ??
        item?.weeklyBenefitMax?.value ??
        null,
      insuranceType:
        isEmpty(item?.insuranceType) || (item?.insuranceType ?? null) === null
          ? BASIC_LIFE_AND_ADD
          : item?.insuranceType,
      benefitLevel:
        item?.benefitLevel?.formattedValue ?? item?.benefitLevel?.value ?? null,
      ageReductionFromBaseField: item?.ageReductionFromBase,
      guaranteedIssueField: item?.guaranteedIssue,
      lifeAndAddBenefitField: item?.lifeAndAddBenefit,
      lifeAndAddMaximumField: item?.lifeAndAddMaximum,
      totalEligibleEnrollmentsField: item?.totalEligibleEnrollments,
      eliminationPeriodField: item?.eliminationPeriod,
      definitionOfDisabilityField: item?.definitionOfDisability,
      benefitDurationField: item?.benefitDuration,
      preExistingConditionsField: item?.preExistingConditions,
      stateDisabilityIntegrationField: item?.stateDisabilityIntegration,
      monthlyBenefitMaxField: item?.monthlyBenefitMax,
      ownOccupationPeriodField: item?.ownOccupationPeriod,
      w2PreparationField: item?.w2Preparation,
      ageReductionField: item?.ageReduction,
      waiverOfPremiumField: item?.waiverOfPremium,
      acceleratedLifeBenefitField: item?.acceleratedLifeBenefit,
      portabilityField: item?.portability,
      definitionOfEarningsField: item?.definitionOfEarnings,
      additionalServices: item?.additionalServices,
    }));
    const nullPlanIdList = (lifePlanList ?? []).map((item: any) => ({
      ...item,
      id: null,
    }));
    return {
      ...offerData,
      lifePlans: nullPlanIdList,
    };
  };

  const modifyOrthodontiaMax = (orthodontiaMax: any[]) => {
    return (
      orthodontiaMax?.map((item) => {
        return {
          ...item,
          name: ORTHODONTIA_MAX,
        };
      }) ?? []
    );
  };

  const buildPlans = () => {
    if (MDV_PLANS?.includes(offerData?.benefitCategory)) {
      const plans =
        offerData?.[
          QUOTE_ADDING_PROPERTY?.[offerData?.benefitCategory ?? '']
        ]?.map((item: any) => {
          let modifiedItem = {
            ...item,
            monthlyPremiums: buildPremiums(
              item?.monthlyPremiums,
              ref?.current?.getPremiumTypeAndCount()?.premiumType
            ),
          };
          if (
            offerData?.benefitCategory === OFFER_CATEGORY.DENTAL &&
            item?.orthodontiaMax
          ) {
            modifiedItem = {
              ...modifiedItem,
              orthodontiaMax: modifyOrthodontiaMax(item?.orthodontiaMax),
            };
          }
          return modifiedItem;
        }) ?? [];
      return {
        ...offerData,
        [QUOTE_ADDING_PROPERTY?.[offerData?.benefitCategory ?? ''] ?? 'plans']:
          plans,
      };
    } else {
      return offerData;
    }
  };

  const collapseOfferModal = () => {
    setVisibleConfirmation(false);
    setDraftConfirmation({
      visible: false,
      confirmationText: '',
      title: '',
      content: '',
    });
    setIsModalOpen(false);
    setCurrentStep(0);
    resetQuoteURL();
    dispatch(clear());
  };

  const handleSaveAsDraft = async () => {
    // if currently on add plans section deals with saving drafts using method in addPlans section
    if (currentStep === 1) {
      ref.current.handleSaveAsDraft();
      setVisibleConfirmation(false);
    } else if (currentStep === 3) {
      await ref?.current?.handlePublishOrDraftOffer?.(OFFER_STATUS.DRAFT);
      setVisibleConfirmation(false);
    } else {
      const { existsDraftOffer } = (await getOfferNameExists({
        employerId: offerData?.employerId ?? '',
        status: 'DRAFT',
        name: encodeURIComponent(offerData?.offerName?.trim()),
        category: URL_PARAM_TO_BENEFIT_TYPE?.[params?.benefitKind ?? ''],
        offerId: offerData?.offerId,
      }).unwrap()) as any;
      if (existsDraftOffer && getEditData()?.name !== offerData?.offerName) {
        setVisibleConfirmation(false);
        setDraftConfirmation({
          visible: true,
          confirmationText: 'Yes - Continue Saving Draft',
          title: 'Save Draft',
          content: (
            <>
              <div>
                Another offer draft with a similar name already exists. If you
                continue, this draft will overwrite the existing draft data.
                Alternatively, you can rename this draft and try saving again.
              </div>
              <div className={styles.textDivider}>Do you wish to continue?</div>
            </>
          ),
        });
      } else if (existsDraftOffer) {
        setVisibleConfirmation(false);
        setDraftConfirmation({
          visible: true,
          confirmationText: 'Yes - Save Draft Offer',
          title: 'Overwrite Existing Draft Offer?',
          content: (
            <div>
              A draft version of this offer already exists. Saving this offer as
              draft will overwrite that data. Do you wish to continue?
            </div>
          ),
        });
      } else {
        await createOffer({
          offer:
            offerData?.benefitCategory === LIFE ||
            offerData?.benefitCategory === STD ||
            offerData?.benefitCategory === LTD
              ? buildLifePlans()
              : buildPlans(),
          draft: true,
          editOfferId:
            isEdit && planDetailsData?.status === 'DRAFT'
              ? getEditData()?.offerId
              : null,
          saveStatus: 'DRAFT',
          jobId: jobId,
        }).unwrap();
        collapseOfferModal();
        onAddOffer();
        setSelectedTab && setSelectedTab('DRAFT');
      }
    }
  };

  const doCreateOffer = async () => {
    const data = await createOffer({
      offer:
        offerData?.benefitCategory === LIFE ||
        offerData?.benefitCategory === STD ||
        offerData?.benefitCategory === LTD
          ? buildLifePlans()
          : buildPlans(),
      draft: true,
      editOfferId:
        getEditData() === undefined ? undefined : getEditData()?.offerId,
      saveStatus: 'PUBLISHED',
      jobId: jobId,
    }).unwrap();
    collapseOfferModal();
    onAddOffer();
    setSelectedTab && setSelectedTab('PUBLISHED');
    navigateBack(data);
  };

  const navigateBack = useCallback(
    (offerData: any) => {
      const benefitType =
        URL_PARAM_TO_BENEFIT_TYPE?.[params?.benefitKind ?? ''].toLowerCase();

      if (params?.offerId) {
        let newOfferId;
        switch (benefitType) {
          case URL_PARAM_TO_BENEFIT_TYPE?.medical.toLowerCase():
            newOfferId = offerData?.medicalBundles?.[0]?.id;
            break;
          case URL_PARAM_TO_BENEFIT_TYPE?.dental.toLowerCase():
            newOfferId = offerData?.dentalBundles?.[0]?.id;
            break;
          case URL_PARAM_TO_BENEFIT_TYPE?.vision.toLowerCase():
            newOfferId = offerData?.visionBundles?.[0]?.id;
            break;
          case URL_PARAM_TO_BENEFIT_TYPE?.['life-add'].toLowerCase():
          case URL_PARAM_TO_BENEFIT_TYPE?.std.toLowerCase():
          case URL_PARAM_TO_BENEFIT_TYPE?.ltd.toLowerCase():
            newOfferId = offerData?.lifeBundles?.[0]?.id;
            break;
          case URL_PARAM_TO_BENEFIT_TYPE?.['vol-life'].toLowerCase():
            newOfferId = offerData?.voluntaryBundles?.[0]?.id;
            break;
        }

        if (!isNullOrUndefined(newOfferId)) {
          return navigate(
            `/brokers/${params?.brokerId}/employers/${params?.employerId}/renewals/carrier/${params.benefitKind}/${newOfferId}/detail`
          );
        }
      }
      // eslint-disable-next-line
    },
    [
      params.benefitKind,
      navigate,
      params?.brokerId,
      params?.employerId,
      params?.offerId,
    ]
  );

  const handleOnPublish = async () => {
    if (currentStep === 1) {
      ref.current.handleSaveAsPublished();
      setVisibleConfirmation(false);
    } else {
      const isPopupOpened = await ref?.current?.handlePublishOrDraftOffer?.(
        OFFER_STATUS.PUBLISHED
      );
      if (!isPopupOpened && currentStep < 3) {
        await doCreateOffer();
      }
      setVisibleConfirmation(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      setExtractionDetails();
    }
    // disabling because the functions only has an efffect on plan details data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, planDetailsData]);

  useEffect(() => {
    if (isModalOpen && !params.offerId) {
      dispatch(clear());
      changeCurrentStep(0);
      refetch();
    }
    // disabling because the functions only has an efffect on plan details data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  return (
    <FullScreenModal
      visible={isModalOpen}
      onCancel={closeOfferModal}
      footer={false}
      title={`${
        isClonedOffer ? 'Clone ' : isEdit ? 'Edit ' : 'Add '
      } ${getBenefitTitleFromUrlParam(params.benefitKind)} Offer`}
      destroyOnClose
    >
      {shouldShowSpinner && planDetailsIsFetching ? (
        <Row justify="center" align="middle">
          <Col span={24}>
            <Spin />
          </Col>
        </Row>
      ) : (
        <AddOffer
          ref={ref}
          closeModal={closeOfferModal}
          changeCurrentStep={changeCurrentStep}
          nextStep={nextStep}
          currentStep={currentStep}
          isEdit={isEdit}
          onAddOffer={onAddOffer}
          editData={getEditData()}
          isClonedOffer={isClonedOffer}
          closeModalWithoutPopup={closeModalWithoutPopup}
          setSelectedTab={props.setSelectedTab}
          onExternalPublish={doCreateOffer}
          refetchPlanDetails={refetch}
        />
      )}
      <CloseModalPopup
        visible={visibleConfirmation}
        type={RenewalType.OFFER}
        closeType={assignCloseType()}
        onPublish={handleOnPublish}
        onSaveAsDraft={handleSaveAsDraft}
        onCloseWithoutSaving={handleCloseWithoutSaving}
        onCancelClick={() => {
          setVisibleConfirmation(false);
        }}
        isDisabled={{
          PUBLISH:
            ref?.current?.getIsValidUpdate?.() ||
            ref?.current?.getIsShowUpdateOfferInProposalModal() ||
            ref?.current?.getIsEligibleEdit() ||
            ref?.current?.getIsFinalApprovedProposal(),
        }}
        overallLoader={basicInfoValuesIsLoading}
        isLoading={isLoading}
      />
      <ConfirmationDialog
        title={draftConfirmation.title}
        confirmText={draftConfirmation.confirmationText}
        cancelText="Go Back"
        closeModal={() => {
          setVisibleConfirmation(true);
          setDraftConfirmation({
            visible: false,
            confirmationText: '',
            title: '',
            content: '',
          });
        }}
        confirmLoading={isLoading}
        onConfirm={async () => {
          await createOffer({
            offer:
              offerData?.benefitCategory === LIFE ||
              offerData?.benefitCategory === STD ||
              offerData?.benefitCategory === LTD
                ? buildLifePlans()
                : buildPlans(),
            draft: true,
            editOfferId:
              isEdit && planDetailsData?.status === 'DRAFT'
                ? getEditData()?.offerId
                : null,
            saveStatus: 'DRAFT',
            jobId: jobId,
            isOpsAdmin,
          }).unwrap();
          collapseOfferModal();
          onAddOffer();
          setSelectedTab && setSelectedTab('DRAFT');
        }}
        visible={draftConfirmation.visible}
        buttonsExpanded={true}
      >
        {draftConfirmation.content}
      </ConfirmationDialog>
    </FullScreenModal>
  );
};

export default AddOfferModal;
