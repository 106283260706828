import { PLAN_CLONE_BENEFIT_CATEGORIES } from 'modules/employers/constants/afpClonePlansConstants';
import {
  IBenGuide,
  ITransformedResult,
} from 'modules/employers/types/planBGCloneTypes';
// icons
import teleHealthIcon from 'assets/images/icon-Telehealth.svg';
import familyLeaveIcon from 'assets/images/icon-leftnav-additional.svg';
import fourO1kIcon from 'assets/images/icon-Retirement.svg';
import additionalIcon from 'assets/images/icon-tick-benifits.svg';
import wellbeingIcon from 'assets/images/icon-health.svg';
import timeOffIcon from 'assets/images/icon-leftnav-benefits.svg';
import taxAdvantagedIcon from 'assets/images/icon-Tax-Advantaged-Act.svg';
import visionIcon from 'assets/images/benefitCategories/icon-vision.svg';
import dentalIcon from 'assets/images/benefitCategories/icon-dental.svg';
import medicalIcon from 'assets/images/benefitCategories/icon-medical.svg';
import lifeIcon from 'assets/images/benefitCategories/icon-life-and-disability.svg';
import voluntaryIcon from 'assets/images/benefitCategories/icon-voluntary-benefits.svg';

export const PLAN_CLONE_TYPE_TEXT = {
  [PLAN_CLONE_BENEFIT_CATEGORIES.MEDICAL]: 'Medical',
  [PLAN_CLONE_BENEFIT_CATEGORIES.TELEHEALTH]: 'Telehealth/Rx Delivery',
  [PLAN_CLONE_BENEFIT_CATEGORIES.DENTAL]: 'Dental',
  [PLAN_CLONE_BENEFIT_CATEGORIES.VISION]: 'Vision',
  [PLAN_CLONE_BENEFIT_CATEGORIES.LIFE]: 'Life & Disability',
  [PLAN_CLONE_BENEFIT_CATEGORIES.VOLUNTARY_BENEFIT]: 'Voluntary Benefits',
  [PLAN_CLONE_BENEFIT_CATEGORIES.WELLBEING]: 'Wellbeing',
  [PLAN_CLONE_BENEFIT_CATEGORIES.SAVING]: 'Tax Advantaged Accounts',
  [PLAN_CLONE_BENEFIT_CATEGORIES.RETIREMENT]: '401(k)',
  [PLAN_CLONE_BENEFIT_CATEGORIES.HOLIDAYS_AND_TIME_OFF]: 'Holidays & Time Off',
  [PLAN_CLONE_BENEFIT_CATEGORIES.FAMILY_AND_LEAVE]: 'Family & Leave',
  [PLAN_CLONE_BENEFIT_CATEGORIES.ADDITIONAL_PERK]: 'Additional Perks',
};

export const getClonePlanIcon = (planName: string) => {
  switch (planName) {
    case PLAN_CLONE_BENEFIT_CATEGORIES.TELEHEALTH:
      return teleHealthIcon;
    case PLAN_CLONE_BENEFIT_CATEGORIES.FAMILY_AND_LEAVE:
      return familyLeaveIcon;
    case PLAN_CLONE_BENEFIT_CATEGORIES.RETIREMENT:
      return fourO1kIcon;
    case PLAN_CLONE_BENEFIT_CATEGORIES.ADDITIONAL_PERK:
      return additionalIcon;
    case PLAN_CLONE_BENEFIT_CATEGORIES.WELLBEING:
      return wellbeingIcon;
    case PLAN_CLONE_BENEFIT_CATEGORIES.HOLIDAYS_AND_TIME_OFF:
      return timeOffIcon;
    case PLAN_CLONE_BENEFIT_CATEGORIES.SAVING:
      return taxAdvantagedIcon;
    case PLAN_CLONE_BENEFIT_CATEGORIES.VISION:
      return visionIcon;
    case PLAN_CLONE_BENEFIT_CATEGORIES.MEDICAL:
      return medicalIcon;
    case PLAN_CLONE_BENEFIT_CATEGORIES.DENTAL:
      return dentalIcon;
    case PLAN_CLONE_BENEFIT_CATEGORIES.LIFE:
      return lifeIcon;
    case PLAN_CLONE_BENEFIT_CATEGORIES.VOLUNTARY_BENEFIT:
      return voluntaryIcon;
    default:
      return '';
  }
};

const compareGroupsRemoveValueNotExistsNewGroups = (
  newBenefitClasses: string[],
  currentPlanBenefitClasses: string[]
) => {
  const comparedArray: string[] = newBenefitClasses.filter((element) =>
    currentPlanBenefitClasses.includes(element)
  );

  return comparedArray.length === 0 ? newBenefitClasses : comparedArray;
};

export const transformedPlanBGForModal = (
  currentPlans: any,
  newBenefitClasses: string[]
) => {
  const resultPlans: ITransformedResult = {
    MEDICAL: { isChecked: false, plan: [] },
    TELEHEALTH: { isChecked: false, plan: [] },
    DENTAL: { isChecked: false, plan: [] },
    VISION: { isChecked: false, plan: [] },
    LIFE: { isChecked: false, plan: [] },
    VOLUNTARY_BENEFIT: { isChecked: false, plan: [] },
    WELLBEING: { isChecked: false, plan: [] },
    SAVING: { isChecked: false, plan: [] },
    RETIREMENT: { isChecked: false, plan: [] },
    HOLIDAYS_AND_TIME_OFF: { isChecked: false, plan: [] },
    FAMILY_AND_LEAVE: { isChecked: false, plan: [] },
    ADDITIONAL_PERK: { isChecked: false, plan: [] },
  };

  Object.entries(currentPlans?.plans).forEach(
    ([benefit, value]: [benefit: any, value: any]) => {
      const category = benefit as keyof typeof resultPlans;
      const transformedPlans = value?.map((item: any) => {
        return {
          isRemoved: false,
          benefitClasses: compareGroupsRemoveValueNotExistsNewGroups(
            newBenefitClasses,
            item?.groups
          ),
          name: `${item?.name}`,
          planId: item?.id,
          carrierName: item?.benefitCarrier?.name,
          carrierLogo: item?.benefitCarrier?.logoUrl,
          benefitCategory: benefit,
          benefitKind: item?.benefitKind,
        };
      });

      resultPlans[category] = {
        isChecked: true,
        plan: transformedPlans,
      };
    }
  );

  const resultBG: IBenGuide[] = currentPlans?.benefitGuides?.map(
    (item: any) => {
      return {
        masterId: item?.masterId,
        name: `${item?.name}`,
        benefitClasses: compareGroupsRemoveValueNotExistsNewGroups(
          newBenefitClasses,
          item?.benefitClasses || []
        ),
        isRemoved: false,
      };
    }
  );
  return { plans: resultPlans, benGuides: resultBG };
};

export const transformDataForSubmit = (
  plans: Partial<ITransformedResult>,
  benGuides: IBenGuide[]
) => {
  const result: {
    plans: {
      [key: string]: { id: string; name: string; groups: string[] }[];
    };
    benefitGuides: {
      masterId: string;
      groups: string[];
      name: string;
    }[];
  } = {
    plans: {},
    benefitGuides: [],
  };

  Object.keys(plans).forEach((benefit) => {
    const values = plans[benefit as keyof typeof PLAN_CLONE_BENEFIT_CATEGORIES];
    if (values?.isChecked && values?.plan?.length !== 0) {
      result.plans[benefit] = values.plan
        .filter((item) => !item.isRemoved)
        .map((item) => ({
          name: item.name,
          id: item.planId,
          groups: item.benefitClasses,
        }));
    }
  });

  result.benefitGuides = benGuides
    .filter((item) => !item?.isRemoved)
    .map((item) => ({
      masterId: item.masterId,
      groups: item.benefitClasses,
      name: item.name,
    }));

  return result;
};
