import { FC, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Form } from 'antd';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';

import {
  addBrokerLocation,
  clearBrokerLocationCreation,
  updateBrokerLocation,
  clearUpdateBrokerLocationUpdation,
} from 'modules/brokers/slices/brokerLocationSlice';

import CreateBrokerLocationForm from 'modules/brokers/components/CreateBrokerLocationForm/CreateBrokerLocationForm';
import { updateUserSilently } from 'modules/auth/slices/authSlice';

import { Location } from 'model/broker/Location';
import Address from 'model/Address';

import {
  basicFormFields,
  EXISTING_LOCATION_FOUND,
} from 'modules/brokers/constants/brokerConstants';

import AlertMessage from 'components/Alert/AlertMessage';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  exitWithoutSavingMsg,
  formVerificationMsg,
} from 'constants/commonConstants';
import styles from './addBrokerLocation.module.less';

type AddBrokerLocationProps = {
  location: Location | null;
  visible: boolean;
  visibleConfirmationClosePopup: boolean;
  confirmCloseModal: () => void;
  toggleConfirmationPopup: () => void;
  locationUpdated?: () => void;
  locationCreated?: () => void;
};

const AddBrokerLocation: FC<AddBrokerLocationProps> = (
  props: AddBrokerLocationProps
) => {
  const {
    location,
    visible,
    visibleConfirmationClosePopup,
    confirmCloseModal,
    toggleConfirmationPopup,
    locationUpdated,
    locationCreated,
  } = props;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [duplicateLocationFound, setDuplicateLocationFound] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    primary: false,
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
  });
  const [confirmatinModalOpen, setConfirmatinModalOpen] =
    useState<boolean>(true);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const { brokerId } = useNavContext();

  const addBrokerLocationStatus = useAppSelector(
    (state) => state.brokers.brokerLocations
  );
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const onInputChange = async (allValues: any) => {
    form.setFieldsValue(allValues);
    setFormData({ ...formData, ...allValues });
    setDuplicateLocationFound(false);
    if (visibleAlert) {
      const valid = await validateAdminInfo();
      if (valid) {
        setVisibleAlert(false);
      }
    }
  };

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (!visible) {
      form.resetFields(basicFormFields.formFields);
    }
  }, [form, visible]);

  useEffect(() => {
    if (location) {
      closeAlert();
      dispatch(clearBrokerLocationCreation());
      dispatch(clearUpdateBrokerLocationUpdation());
      const data = {
        id: location.id,
        name: location.name,
        primary: location.primary,
        addressLine1: location.address.addressLine1,
        addressLine2: location.address.addressLine2,
        city: location.address.city,
        state: location.address.state,
        zipCode: location.address.zipCode,
      };
      setFormData(data);
      form.setFieldsValue(data);
      setIsChecked(location.primary);
      setEditMode(true);
    } else {
      setEditMode(false);
      setIsChecked(false);
      form.resetFields(basicFormFields.formFields);
      closeAlert();
      dispatch(clearBrokerLocationCreation());
      dispatch(clearUpdateBrokerLocationUpdation());
    }
  }, [dispatch, location, form]);

  useEffect(() => {
    if (!addBrokerLocationStatus.createInProgress) {
      if (!isEmpty(addBrokerLocationStatus.brokerLocationObj)) {
        if (
          locationCreated &&
          addBrokerLocationStatus.brokerLocationCreationSuccess
        ) {
          locationCreated();
        }
        dispatch(clearBrokerLocationCreation());
        if (brokerId) {
          form.resetFields(basicFormFields.formFields);
          setIsChecked(false);
          setVisibleAlert(false);
          confirmCloseModal();
        }
      } else if (
        addBrokerLocationStatus.brokerLocationCreateError &&
        addBrokerLocationStatus.brokerLocationCreateError.data &&
        addBrokerLocationStatus.brokerLocationCreateError.data.code
      ) {
        dispatch(clearBrokerLocationCreation());
        if (
          addBrokerLocationStatus.brokerLocationCreateError.data.code ===
          EXISTING_LOCATION_FOUND
        ) {
          setDuplicateLocationFound(true);
          setAlertMessage('Location Already Exists');
        }
      }
    }
    if (
      !addBrokerLocationStatus.updateInProgress &&
      addBrokerLocationStatus.updateCompleted
    ) {
      if (
        addBrokerLocationStatus.brokerLocationUpdateError &&
        addBrokerLocationStatus.brokerLocationUpdateError.data.code
      ) {
        dispatch(clearUpdateBrokerLocationUpdation());
        if (
          addBrokerLocationStatus.brokerLocationUpdateError.data.code ===
          EXISTING_LOCATION_FOUND
        ) {
          setAlertMessage('Location Already Exists');
          setDuplicateLocationFound(true);
        }
      } else {
        if (locationUpdated) {
          locationUpdated();
        }
        dispatch(clearUpdateBrokerLocationUpdation());
        if (brokerId) {
          form.resetFields(basicFormFields.formFields);
          setVisibleAlert(false);
          confirmCloseModal();
        }
      }
    }
  }, [
    dispatch,
    addBrokerLocationStatus?.createInProgress,
    addBrokerLocationStatus?.brokerLocationObj,
    addBrokerLocationStatus?.brokerLocationCreateError,
    addBrokerLocationStatus?.updateInProgress,
    addBrokerLocationStatus?.updateCompleted,
    addBrokerLocationStatus?.brokerLocationUpdateError,
    addBrokerLocationStatus?.brokerLocationCreationSuccess,
    confirmCloseModal,
    form,
    brokerId,
    locationUpdated,
    locationCreated,
    editMode,
  ]);

  const confirmClose = () => {
    setEditMode(false);
    setIsChecked(false);
    form.resetFields(basicFormFields.formFields);
    setVisibleAlert(false);
    closeAlert();
    dispatch(clearBrokerLocationCreation());
    dispatch(clearUpdateBrokerLocationUpdation());
    setConfirmatinModalOpen(false);
    confirmCloseModal();
  };

  const validateAdminInfo = async () => {
    try {
      await form.validateFields(basicFormFields.requiredFields);
      return true;
    } catch (errorInfo: any) {
      return errorInfo.errorFields.length === 0;
    }
  };

  useEffect(() => {
    if (visibleConfirmationClosePopup) {
      setConfirmatinModalOpen(true);
    }
  }, [visibleConfirmationClosePopup, setConfirmatinModalOpen]);

  const onClickSave = async () => {
    let isFormValid: boolean = true;
    setVisibleAlert(false);
    isFormValid = await validateAdminInfo();
    if (isFormValid) {
      const { name, addressLine1, addressLine2, city, state, zipCode } =
        formData;
      setDuplicateLocationFound(false);
      const address = {
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode: zipCode?.trim(),
      } as Address;
      if (editMode && location !== null) {
        const updatedLocation = {
          id: location.id,
          organizationId: brokerId ? brokerId : null,
          name: name.trim(),
          address,
          primary: isChecked,
        } as Location;
        dispatch(updateBrokerLocation(location.id, updatedLocation));
      } else {
        const newLocation = {
          organizationId: brokerId ? brokerId : null,
          name: name.trim(),
          address,
          primary: isChecked,
        } as Location;
        await dispatch(addBrokerLocation(newLocation));
        await dispatch(updateUserSilently());
      }
    } else {
      setVisibleAlert(true);
      setAlertMessage(formVerificationMsg);
    }
  };

  const closeAlert = () => {
    setDuplicateLocationFound(false);
    setVisibleAlert(false);
  };

  const onReset = () => {
    if (brokerId) {
      form.resetFields(basicFormFields.formFields);
      setVisibleAlert(false);
      confirmCloseModal();
    }
  };

  return (
    <>
      {(duplicateLocationFound || visibleAlert) && (
        <div className={styles.alertWrapper}>
          <AlertMessage
            type="error"
            message={alertMessage}
            closeAlert={closeAlert}
          />
        </div>
      )}
      <CreateBrokerLocationForm
        duplicateLocationFound={duplicateLocationFound}
        form={form}
        onInputChange={onInputChange}
        expanded={true}
        isChecked={isChecked}
        handleOnChange={handleOnChange}
        onClickSave={onClickSave}
        isDirect={true}
        isEdit={location !== null}
        onReset={onReset}
        isDefaultLocation={false}
        locationData={location}
      />
      {visibleConfirmationClosePopup && confirmatinModalOpen && (
        <ConfirmationDialog
          title="Are you sure you want to close?"
          confirmText="Yes - close and do not save"
          cancelText="Cancel"
          closeModal={toggleConfirmationPopup}
          onConfirm={confirmClose}
          visible={visibleConfirmationClosePopup}
        >
          <p className={styles.warningConfirmation}>{exitWithoutSavingMsg}</p>
        </ConfirmationDialog>
      )}
    </>
  );
};

export default AddBrokerLocation;
