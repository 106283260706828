import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { isUndefined } from 'lodash';
import { CREATE_NEW_PROPOSAL_TXT } from 'modules/renewals/constants/renewalsConstants';
import Stepper from 'components/Stepper/Stepper';
import SelectOffers from 'modules/renewals/components/addProposalsComponents/SelectOffers/SelectOffers';
import AddDiscounts from 'modules/renewals/components/addProposalsComponents/AddDiscount/AddDiscounts';
import Modeling from 'modules/renewals/components/addProposalsComponents/Modeling/Modeling';
import ProposalBasicInfo from 'modules/renewals/components/addProposalsComponents/BasicInfo/ProposalBasicInfo';
import Summary from 'modules/renewals/components/addProposalsComponents/Summary/Summary';
import styles from './addProposal.module.less';

type Props = {
  closeModal: (
    notShowConfirmMessage: any,
    proposalStatus?: string | undefined
  ) => void;
  currentStep: number;
  onNextStep: () => void;
  onPreviousStep: () => void;
  changeCurrentStep: (step: number) => void;
  isModalOpen: boolean;
  proposalId?: string;
  cloneProposal?: boolean;
  isProposalDetailedView?: boolean;
  isProposalCreateModalOpen?: boolean;
  isNext?: boolean;
  setIsNextStep?: () => void;
  changeProposalId?: Function;
  isLoading?: boolean;
  title: string;
  proposalType?: string;
  onChangedProposalId?: (proposalId: string) => void;
  setIsPublishedOrDraft: Function;
};

const AddProposal = (props: Props, ref: any) => {
  const {
    currentStep,
    onNextStep,
    changeCurrentStep,
    isModalOpen,
    proposalId,
    onPreviousStep,
    closeModal,
    cloneProposal,
    isProposalDetailedView,
    isProposalCreateModalOpen,
    isNext = false,
    setIsNextStep,
    changeProposalId,
    isLoading = false,
    title = null,
    proposalType,
    onChangedProposalId,
    setIsPublishedOrDraft,
  } = props;

  const addProposalRef = useRef<any>(null);
  const addOffersRef = useRef<any>(null);
  const modelingRef = useRef<any>(null);

  const [updatedProposalId, setUpdatedProposalId] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    updatedProposalId &&
      onChangedProposalId &&
      onChangedProposalId(updatedProposalId);
  }, [updatedProposalId, onChangedProposalId]);

  useEffect(() => {
    setUpdatedProposalId(proposalId);
    // eslint-disable-next-line
  }, [proposalId]);

  const handleProposalId = (proposalId: string) => {
    changeProposalId && changeProposalId(proposalId);
    setUpdatedProposalId(proposalId);
  };

  const handleCloseStep = async (step: number) => {
    switch (step) {
      case 0:
        return await addProposalRef?.current?.handleSubmit();
      case 1:
        await addOffersRef?.current?.handleClose();
        break;
      case 2:
        await modelingRef?.current?.handleClose();
        break;

      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    nextEventForCurrentTab: async () => {
      return await handleCloseStep(currentStep);
    },
    validateBasicInfoForm: async () => {
      const isValid = await addProposalRef?.current?.validateFormData();
      return isValid;
    },
  }));

  const validateAndSaveOnTopNavigate = async (incomingStep: number) => {
    let isValid = false;

    // Save the inner stepper plans if the user clicks on top nav
    if (modelingRef?.current) {
      await modelingRef.current.handleClose();
    }
    if (addOffersRef?.current) {
      await addOffersRef.current.handleClose();
    }

    switch (currentStep) {
      case 0:
        await addProposalRef?.current?.handleSubmit();
        isValid = await addProposalRef?.current?.validateFormData();
        break;
      default:
        isValid = true;
    }

    if (!!isValid || isUndefined(isValid)) {
      changeCurrentStep(incomingStep);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className={styles.step}>
            <ProposalBasicInfo
              ref={addProposalRef}
              nextStep={onNextStep}
              isModalOpen={isModalOpen}
              proposalId={updatedProposalId}
              closeModal={(notShowConfirmMessage, proposalStatus) =>
                closeModal(notShowConfirmMessage, proposalStatus)
              }
              cloneProposal={cloneProposal}
              handleProposalId={handleProposalId}
              isProposalCreateModalOpen={isProposalCreateModalOpen}
              isNext={isNext}
              setIsNextStep={setIsNextStep}
              isLoading={isLoading}
            />
          </div>
        );
      case 1:
        return (
          <div className={styles.step}>
            <SelectOffers
              ref={addOffersRef}
              nextStep={onNextStep}
              proposalId={updatedProposalId}
              onPreviousStep={onPreviousStep}
              closeModal={(notShowConfirmMessage, proposalStatus) =>
                closeModal(notShowConfirmMessage, proposalStatus)
              }
              isProposalDetailedView={isProposalDetailedView}
            />
          </div>
        );
      case 2:
        return (
          <div className={styles.step}>
            <Modeling
              ref={modelingRef}
              nextStep={onNextStep}
              onPreviousStep={onPreviousStep}
              closeModal={(notShowConfirmMessage) =>
                closeModal(notShowConfirmMessage)
              }
              proposalId={updatedProposalId}
              isProposalDetailedView={isProposalDetailedView}
            />
          </div>
        );

      case 3:
        return (
          <div className={styles.step}>
            <AddDiscounts
              nextStep={onNextStep}
              onPreviousStep={onPreviousStep}
              closeModal={(notShowConfirmMessage, proposalStatus) =>
                closeModal(notShowConfirmMessage, proposalStatus)
              }
              isProposalDetailedView={isProposalDetailedView}
              proposalId={updatedProposalId ?? ''}
            />
          </div>
        );
      case 4:
        return (
          <div className={styles.step}>
            <Summary
              proposalIdEdit={updatedProposalId}
              closeModal={(notShowConfirmMessage) =>
                closeModal(notShowConfirmMessage)
              }
              isProposalDetailedView={isProposalDetailedView}
              proposalType={proposalType}
              setIsPublishedOrDraft={setIsPublishedOrDraft}
            />
          </div>
        );
      default:
        return;
    }
  };

  return (
    <div className={styles.stepWrapper}>
      <Stepper
        headers={[
          'Basic Info',
          'Select Offers',
          'Modeling',
          'Costs/Credits',
          'Summary',
        ]}
        activeStep={currentStep}
        changeCurrentStep={validateAndSaveOnTopNavigate}
        renderStepContent={renderStepContent}
        isEditMode={title !== CREATE_NEW_PROPOSAL_TXT}
        isLastStepActive={true}
        isDisabledStep={(index: number) => {
          if (isLoading) {
            return true;
          } else if (title === CREATE_NEW_PROPOSAL_TXT) {
            return index > currentStep;
          } else {
            return false;
          }
        }}
      />
    </div>
  );
};

export default forwardRef(AddProposal);
