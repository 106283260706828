import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Select } from 'antd';
import { useAppDispatch } from 'hooks/redux';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import InputForm from 'components/InputForm/InputForm';
import InputNumber from 'components/InputNumber/InputNumber';
import {
  EMPLOYER_LIMIT_CONSTANTS,
  EMPLOYER_LIMIT_DROPDOWN,
  EMPLOYER_UNLIMITED_PASSING_VALUE,
  EMPTY_MESSAGE,
} from 'modules/brokers/constants/brokerConstants';
import Broker from 'model/Broker';
import TablePagination from 'model/TablePagination';
import { convertPaginationConfig, onlyNumberInputUtil } from 'util/commonUtil';
import { useUpdateAllowedCountMutation } from 'modules/brokers/slices/dashboardBrokerSlice';
import { getBrokerList } from 'modules/brokers/slices/brokerBasicInfoSlice';

import styles from './updateEmpCount.module.less';

type Props = {
  visible: boolean;
  setVisible: Function;
  brokerItemData: Broker;
  setBrokerItemData: Function;
  paginationConfig: TablePagination;
};

const UpdateEmpCount = ({
  visible,
  setVisible,
  brokerItemData,
  setBrokerItemData,
  paginationConfig,
}: Props) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [empLimitType, setEmpLimitType] = useState<string | null>(null);

  const [updateAllowedCount, { isSuccess, isLoading }] =
    useUpdateAllowedCountMutation();

  useEffect(() => {
    if (brokerItemData) {
      form.setFieldsValue({
        employerLimitCount:
          brokerItemData?.allowedEmployerCount ===
          EMPLOYER_UNLIMITED_PASSING_VALUE
            ? undefined
            : brokerItemData?.allowedEmployerCount,
        employerLimit:
          brokerItemData?.allowedEmployerCount ===
          EMPLOYER_UNLIMITED_PASSING_VALUE
            ? EMPLOYER_LIMIT_CONSTANTS.UNLIMITED
            : EMPLOYER_LIMIT_CONSTANTS.LIMITED,
      });
      setEmpLimitType(
        brokerItemData?.allowedEmployerCount ===
          EMPLOYER_UNLIMITED_PASSING_VALUE
          ? EMPLOYER_LIMIT_CONSTANTS.UNLIMITED
          : EMPLOYER_LIMIT_CONSTANTS.LIMITED
      );
    }
    // eslint-disable-next-line
  }, [brokerItemData]);

  useEffect(() => {
    if (isSuccess) {
      setEmpLimitType(null);
      setBrokerItemData(null);
      form.resetFields();
      dispatch(getBrokerList(convertPaginationConfig(paginationConfig)));
      setVisible(false);
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const handleConfirm = () => {
    form.validateFields().then((values) => {
      try {
        updateAllowedCount({
          orgId: brokerItemData?.id,
          data: {
            allowedEmployerCount:
              empLimitType === EMPLOYER_LIMIT_CONSTANTS.UNLIMITED
                ? EMPLOYER_UNLIMITED_PASSING_VALUE
                : values.employerLimitCount,
          },
        });
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <ConfirmationDialog
      visible={visible}
      centered={true}
      confirmBtnFullWidth={true}
      title="Update Employer Limit"
      confirmText="Save"
      confirmLoading={isLoading}
      cancelText="Cancel"
      isCancelLink={true}
      closeModal={() => {
        setEmpLimitType(null);
        setBrokerItemData(null);
        form.resetFields();
        setVisible(false);
      }}
      onConfirm={handleConfirm}
      destroyOnClose={true}
    >
      <div className={styles.updateModalBodyWrapper}>
        <div className={styles.updateSubtext}>
          <p>{brokerItemData?.name}</p>
        </div>
        <InputForm form={form} layout="vertical">
          <Row gutter={24}>
            <Col xs={16}>
              <Form.Item
                name="employerLimit"
                label="Employer Limit"
                rules={[
                  {
                    required: true,
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  size="large"
                  onChange={(value) => {
                    setEmpLimitType(value);
                    if (value === EMPLOYER_LIMIT_CONSTANTS.LIMITED)
                      return form.setFieldsValue({
                        employerLimitCount: brokerItemData?.allowedEmployerCount
                          ? brokerItemData?.allowedEmployerCount
                          : 1,
                      });
                    form.setFieldsValue({ employerLimitCount: '' });
                  }}
                >
                  {EMPLOYER_LIMIT_DROPDOWN.map((item) => {
                    return (
                      <Select.Option key={item?.value} value={item?.value}>
                        {item?.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                name="employerLimitCount"
                label="Count"
                rules={[
                  {
                    required: empLimitType === EMPLOYER_LIMIT_CONSTANTS.LIMITED,
                  },
                  {
                    validator: (_, value) => {
                      if (
                        value < 0 &&
                        empLimitType === EMPLOYER_LIMIT_CONSTANTS.LIMITED
                      )
                        return Promise.reject(
                          new Error('Count value cannot less than 0')
                        );
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  disabled={empLimitType === EMPLOYER_LIMIT_CONSTANTS.UNLIMITED}
                  onKeyDown={onlyNumberInputUtil}
                />
              </Form.Item>
            </Col>
          </Row>
        </InputForm>
      </div>
    </ConfirmationDialog>
  );
};

export default UpdateEmpCount;
