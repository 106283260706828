import { lumityApi } from 'api/apiSlice';

/**
 * API to get list of ben-guides basing employerID and planYearId for dashboard
 */

const SUB_BASE_API_ENDPOINT = 'v2/billing/employers';

const dashboardBillingApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getListOfBillings: builder.query<
      any,
      {
        employerId: string | undefined;
      }
    >({
      query: (args) => {
        const { employerId } = args;
        return {
          url: `${SUB_BASE_API_ENDPOINT}/${employerId}/dashboard`,
          method: 'get',
        };
      },
    }),
  }),
});

export const { useGetListOfBillingsQuery } = dashboardBillingApi;
