import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useAppDispatch } from 'hooks/redux';
import { setCreditMode } from 'modules/renewals/slices/offerReducerSlice';

type Props = {
  selectedCreditDropDown: any;
  isCreditsIncludedModalVisible: boolean;
  isCreditsExcludedModalVisible: boolean;
  setIsCreditsIncludedModalVisible: (flag: boolean) => void;
  setIsCreditsExcludedModalVisible: (flag: boolean) => void;
  setCreditDropDownDefault?: (flag: any) => void;
};

const CreditInclusionPopup = (props: Props) => {
  const {
    selectedCreditDropDown,
    isCreditsIncludedModalVisible,
    isCreditsExcludedModalVisible,
    setIsCreditsIncludedModalVisible,
    setIsCreditsExcludedModalVisible,
    setCreditDropDownDefault,
  } = props;
  const dispatch = useAppDispatch();

  return (
    <>
      <ConfirmationDialog
        title="Show With Credits Excluded"
        onConfirm={() => {
          setIsCreditsExcludedModalVisible(!isCreditsExcludedModalVisible);
          if (setCreditDropDownDefault !== undefined) {
            setCreditDropDownDefault!(selectedCreditDropDown);
          }
          dispatch(setCreditMode({ creditMode: selectedCreditDropDown }));
        }}
        visible={isCreditsExcludedModalVisible}
        isCloseOnly={false}
        confirmText={'Show With Credits Excluded'}
        centered={true}
        width={528}
        confirmBtnFullWidth={true}
        isCancelLink={true}
        cancelText={'Close'}
        closeModal={() =>
          setIsCreditsExcludedModalVisible(!isCreditsExcludedModalVisible)
        }
      >
        <div>
          By toggling this view, all premium amounts across Renewals will show
          values with Credits Excluded. You may change this back to Credits
          Included at anytime.
        </div>
      </ConfirmationDialog>
      <ConfirmationDialog
        title="Show With Credits Included"
        onConfirm={() => {
          setIsCreditsIncludedModalVisible(!isCreditsIncludedModalVisible);
          if (setCreditDropDownDefault !== undefined) {
            setCreditDropDownDefault!(selectedCreditDropDown);
          }
          dispatch(setCreditMode({ creditMode: selectedCreditDropDown }));
        }}
        visible={isCreditsIncludedModalVisible}
        isCloseOnly={false}
        confirmText={'Show With Credits Included'}
        centered={true}
        width={528}
        confirmBtnFullWidth={true}
        isCancelLink={true}
        cancelText={'Close'}
        closeModal={() =>
          setIsCreditsIncludedModalVisible(!isCreditsIncludedModalVisible)
        }
      >
        <div>
          By toggling this view, all premium amounts across Renewals will show
          values with Credits Included. You may change this back to Credits
          Excluded at anytime.
        </div>
      </ConfirmationDialog>
    </>
  );
};

export default CreditInclusionPopup;
