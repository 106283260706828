import { FC, MutableRefObject } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import PlanDocuments from 'modules/plans/components/PlanDocuments/PlanDocuments';
import { VisionPlanDocumentType } from 'modules/plans/constants';
import { uploadVisionTemporaryDocument } from 'modules/plans/slices/visionPlanSlice';

type PlanDocumentUPWrapperProps = {
  onChange: Function;
  wrappedRef: MutableRefObject<any>;
  plan?: any;
  onValidateFails?: (validateSetting: string) => void;
  isDisable?: boolean;
  isAFPReview?: boolean;
  isOnlyAfpVisible?: boolean;
  onFileChange?: Function;
};

const PlanDocumentUPWrapper: FC<PlanDocumentUPWrapperProps> = (props) => {
  const {
    onChange,
    plan,
    wrappedRef,
    onValidateFails,
    isDisable = false,
    isOnlyAfpVisible = false,
    onFileChange = (document: File, docType: string) => {
      dispatch(uploadVisionTemporaryDocument(document, docType));
    },
  } = props;
  const { visionPlan } = useAppSelector((state) => state.plan.visionPlan);
  const dispatch = useAppDispatch();

  return (
    <PlanDocuments
      ref={wrappedRef}
      planDocumentTypes={VisionPlanDocumentType}
      onFileChange={onFileChange}
      plan={plan ?? visionPlan}
      onPlanChange={onChange}
      onValidateFails={onValidateFails}
      isDisable={isDisable}
      isOnlyAfpVisible={isOnlyAfpVisible}
    />
  );
};

export default PlanDocumentUPWrapper;
