import { FC } from 'react';
import { Card, CardProps, Row } from 'antd';

import { ReactComponent as UserCirclePlus } from 'assets/images/user-circle-plus.svg';

import styles from './dashBoardCard.module.less';

interface DashBoardCardProps extends CardProps {
  className?: string;
  cardHeader?: string;
  onClick?: () => void;
  isBroker?: boolean;
}

const DashBoardCard: FC<DashBoardCardProps> = ({
  className,
  cardHeader,
  onClick,
  isBroker,
}) => {
  return (
    <Card bordered={true} onClick={onClick}>
      <Row>
        <UserCirclePlus
          className={`${styles.userCirclePlus} ${
            isBroker ? styles.userCircleBroker : styles.userCircleEmployer
          }`}
        />
      </Row>
      <Row className={className}>{cardHeader}</Row>
    </Card>
  );
};

export default DashBoardCard;
