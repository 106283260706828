import Contribution from 'model/plans/Contribution';

class NTierContributions {
  contributions: Contribution[];

  constructor() {
    this.contributions = [];
  }
}

export default NTierContributions;
