import { FC, useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import MedicalPlan from 'model/plans/MedicalPlan';
import DeductiblesOOPMax from 'modules/plans/components/DeductiblesOOPMax/DeductiblesOOPMax';
import { updateMedicalPlan } from 'modules/plans/slices/medicalPlanSlice';

type DeductiblesOOPMaxDBGWrapperProps = {
  medicalPlan: MedicalPlan;
};

const DeductiblesOOPMaxDBGWrapper: FC<DeductiblesOOPMaxDBGWrapperProps> = (
  props: DeductiblesOOPMaxDBGWrapperProps
) => {
  const dispatch = useAppDispatch();
  const { medicalPlan } = props;

  useEffect(() => {
    dispatch(updateMedicalPlan(medicalPlan));
  }, [dispatch, medicalPlan]);

  const onChange = (medicalPlan: MedicalPlan) => {
    dispatch(updateMedicalPlan(medicalPlan));
  };

  return <DeductiblesOOPMax onChange={onChange} isTouched={false} />;
};

export default DeductiblesOOPMaxDBGWrapper;
