import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Empty, Form, Input, Row, Select, Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { useAppSelector } from 'hooks/redux';
import { loginTypes } from 'constants/authConstants';
import Button from 'components/buttons/Button/Button';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import InputForm from 'components/InputForm/InputForm';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { showErrorNotification } from 'components/Notification/Notification';
import {
  formatPhoneNumber,
  getBenefitNameAndIcon,
} from 'modules/employers/utils/policyNumberAndCarrierContact';
import { ACTION_TYPES } from 'modules/employers/constants/policyNumbersCarrierContactConstants';
import {
  useLazyGetCarrierContactListByEmployerIdQuery,
  useUpdateCarrierContactPlansMutation,
  useUpdateGroupIdMutation,
} from 'modules/employers/slices/policyNumberCarrierContactSlice';
import pencilIcon from 'assets/images/icon-pencil.svg';
import { ReactComponent as PopoutIcon } from 'assets/images/popout-icon-blue.svg';
import styles from './policyNumberAndCarrierContactContent.module.less';

type Props = {
  planListData: any;
  isPlanListLoading: boolean;
  fetchTableData: () => void;
};

const EditPencilButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <img
      src={pencilIcon}
      alt="edit"
      className={styles.editPencil}
      onClick={onClick}
    />
  );
};

const TableHeadData = ({ title }: { title?: string }) => (
  <th className={styles.tableHeader}>{title || ''}</th>
);

const TableBodyData = ({
  children,
}: {
  children: JSX.Element | React.ReactNode;
}) => <td className={styles.tableBodyCell}>{children}</td>;

const PolicyNumberAndCarrierContactContent = (props: Props) => {
  const { planListData, isPlanListLoading, fetchTableData } = props;
  const { employerId, brokerId } = useParams();
  const [groupIdFrom] = Form.useForm();
  const [carrierContactsForm] = Form.useForm();

  const appBootInfo: any = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );
  const [isEdit, setIsEdit] = useState(false);
  const [isShowUpdateGroupIDModal, setIsShowUpdateGroupIDModal] = useState<{
    type: keyof typeof ACTION_TYPES;
    show: boolean;
    masterId: string;
    benefitType: string;
    value?: string;
  }>({
    type: 'ADD',
    show: false,
    masterId: '',
    benefitType: '',
  });

  const [isShowUpdateCarrierContactModal, setIsShowUpdateCarrierContactModal] =
    useState<{
      type: keyof typeof ACTION_TYPES;
      show: boolean;
      employerId: string;
      benefitType: string;
      carrierId: string;
      value?: string;
    }>({
      type: 'ADD',
      show: false,
      employerId: '',
      benefitType: '',
      carrierId: '',
    });

  const isErAdmin = appBootInfo?.type === loginTypes.erAdmin;

  const isTableHasData = Object.values(planListData)?.every(
    (item: any) => item?.length === 0
  );

  const [
    getCarrierPlanListByEmployerId,
    { data: carrierContactData, isFetching: isCarrierContactDataFetching },
  ] = useLazyGetCarrierContactListByEmployerIdQuery();

  const [updateGroupId, { isLoading: isUpdateGroupIdLoading }] =
    useUpdateGroupIdMutation();

  const [updateCarrierContact, { isLoading: isUpdateCarrierContactLoading }] =
    useUpdateCarrierContactPlansMutation();

  useEffect(() => {
    if (isShowUpdateGroupIDModal.show) {
      groupIdFrom.setFieldsValue({
        groupId: isShowUpdateGroupIDModal?.value || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowUpdateGroupIDModal.show]);

  useEffect(() => {
    if (isShowUpdateCarrierContactModal.show) {
      getCarrierPlanListByEmployerId({
        employerId: isShowUpdateCarrierContactModal?.employerId,
        carrierId: isShowUpdateCarrierContactModal?.carrierId,
      })
        .unwrap()
        .then(() => {
          carrierContactsForm.setFieldsValue({
            carrierContactId: isShowUpdateCarrierContactModal?.value || '',
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowUpdateCarrierContactModal?.show]);

  const resetCarrierContactState = () => {
    setIsShowUpdateCarrierContactModal({
      type: 'ADD',
      show: false,
      carrierId: '',
      benefitType: '',
      employerId: '',
    });
  };

  const handleUpdateGroupIDPolicyNumber = () => {
    groupIdFrom.validateFields().then(async (values) => {
      try {
        await updateGroupId({
          benefitCategory: isShowUpdateGroupIDModal.benefitType,
          masterId: isShowUpdateGroupIDModal.masterId,
          groupId: encodeURIComponent(values?.groupId.trim()),
        }).unwrap();
        setIsShowUpdateGroupIDModal({
          type: 'ADD',
          show: false,
          masterId: '',
          benefitType: '',
          value: '',
        });
        fetchTableData();
      } catch (error: any) {
        showErrorNotification({
          icon: <CloseOutlined className={styles.notificationErrorIcon} />,
          message: 'Something went wrong!',
          description:
            "We couldn't update the group id / policy #. Please try again.",
        });
      }
    });
  };

  const handleUpdateCarrierContact = () => {
    carrierContactsForm.validateFields().then(async (values) => {
      try {
        await updateCarrierContact({
          employerId: isShowUpdateCarrierContactModal.employerId,
          carrierId: isShowUpdateCarrierContactModal.carrierId,
          carrierContactId: values?.carrierContactId || '',
          benefitCategory: isShowUpdateCarrierContactModal.benefitType,
        }).unwrap();
        resetCarrierContactState();
        fetchTableData();
      } catch (error: any) {
        showErrorNotification({
          icon: <CloseOutlined className={styles.notificationErrorIcon} />,
          message: 'Something went wrong!',
          description:
            "We couldn't update the carrier contact. Please try again.",
        });
      }
    });
  };

  const handleManageCarrierContactsClick = () => {
    resetCarrierContactState();
    window.open(
      `/brokers/${brokerId}/carriers?carrier-contacts=true`,
      '_blank'
    );
  };

  const handleCarrierContactNavigateToEdit = (id: string) => {
    resetCarrierContactState();
    window.open(
      `/brokers/${brokerId}/carriers?carrier-contacts=true&edit-id=${id}`,
      '_blank'
    );
  };

  return (
    <>
      <div className={styles.container}>
        {!isErAdmin && (
          <Row justify="end">
            <Col className={styles.btnWrapper}>
              {isEdit ? (
                <Button
                  type="primary"
                  label="Done Editing"
                  className={styles.editBtn}
                  onClick={() => setIsEdit(false)}
                />
              ) : (
                <Button
                  className={styles.doneEditBtn}
                  label="Edit Page"
                  type="default"
                  onClick={() => setIsEdit(true)}
                />
              )}
            </Col>
          </Row>
        )}
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead className={styles.tableHead}>
              <tr className={styles.tableHeaderRow}>
                <TableHeadData />
                <TableHeadData title="Carrier" />
                <TableHeadData title="Plan Name" />
                <TableHeadData title="Group ID / Policy #" />
                <TableHeadData title="Carrier Contact" />
              </tr>
            </thead>

            {isPlanListLoading || isTableHasData ? (
              <tr className={styles.loaderTableRow}>
                {isPlanListLoading ? (
                  <div className={styles.loadingWrapper}>
                    <Spin />
                  </div>
                ) : (
                  isTableHasData && (
                    <div className={styles.noDataWrapper}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )
                )}
              </tr>
            ) : (
              !isTableHasData && (
                <tbody className={styles.tableBody}>
                  {Object.keys(planListData).map((benefitType: string) => {
                    const benefit = getBenefitNameAndIcon(benefitType);
                    const plans = planListData[benefitType];

                    return plans?.map((item: any, index: number) => {
                      const isLastItem =
                        planListData[benefitType]?.length - 1 === index;
                      return (
                        <tr
                          key={index}
                          className={`${styles.tableBodyRow}   ${
                            isLastItem ? styles.tableBodyRowBorderBottom : ''
                          }`}
                        >
                          <TableBodyData>
                            {index === 0 ? (
                              <div className={styles.benefitTypeWrapper}>
                                <img
                                  className={styles.benefitIcon}
                                  src={benefit?.icon}
                                  alt={benefit?.benefitName}
                                />
                                <p className={styles.benefitTypeText}>
                                  {benefit?.benefitName}
                                </p>
                              </div>
                            ) : (
                              ''
                            )}
                          </TableBodyData>
                          <TableBodyData>
                            <img
                              className={styles.carrierLogo}
                              src={item.carrierLogo}
                              alt={item.carrierName}
                            />
                          </TableBodyData>
                          <TableBodyData>
                            {item?.plans?.map((plan: any) => {
                              return (
                                <p key={plan.id} className={styles.planName}>
                                  <OverflowPopover key={plan.id} maxWidth={230}>
                                    {plan?.name}
                                  </OverflowPopover>
                                </p>
                              );
                            })}
                          </TableBodyData>
                          <TableBodyData>
                            {item?.plans?.map((plan: any) => {
                              return (
                                <div key={plan?.id}>
                                  {plan?.groupId ? (
                                    <div className={styles.groupIdWrapper}>
                                      <p className={styles.groupId}>
                                        <OverflowPopover maxWidth={145}>
                                          {plan?.groupId}
                                        </OverflowPopover>
                                      </p>
                                      {isEdit && (
                                        <EditPencilButton
                                          onClick={() => {
                                            setIsShowUpdateGroupIDModal({
                                              type: ACTION_TYPES.EDIT as keyof typeof ACTION_TYPES,
                                              show: true,
                                              masterId: plan?.masterId,
                                              value: plan?.groupId,
                                              benefitType:
                                                item?.benefitCategory,
                                            });
                                          }}
                                        />
                                      )}
                                    </div>
                                  ) : isEdit ? (
                                    <Button
                                      className={styles.addBtn}
                                      type="default"
                                      label="Add Group ID / Policy #"
                                      onClick={() => {
                                        setIsShowUpdateGroupIDModal({
                                          type: ACTION_TYPES.ADD as keyof typeof ACTION_TYPES,
                                          show: true,
                                          masterId: plan?.masterId,
                                          benefitType: item?.benefitCategory,
                                        });
                                      }}
                                    />
                                  ) : (
                                    <p className={styles.placeholder}>-</p>
                                  )}
                                </div>
                              );
                            })}
                          </TableBodyData>
                          <TableBodyData>
                            {item?.carrierContact?.email ? (
                              <div
                                className={`${styles.carrierContactWrapper} ${
                                  item?.carrierContact?.phone
                                    ? styles.carrierContactWrapperDirection
                                    : ''
                                }`}
                              >
                                <p className={styles.carrierContactsNameMail}>
                                  <OverflowPopover maxWidth={260}>
                                    <span className={styles.carrierContactName}>
                                      {item.carrierContact.fullName}{' '}
                                    </span>{' '}
                                    - {item?.carrierContact?.email}
                                  </OverflowPopover>
                                </p>
                                <div
                                  className={styles.carrierContactPhoneWrapper}
                                >
                                  {item?.carrierContact?.phone && (
                                    <p className={styles.carrierContactsPhone}>
                                      {formatPhoneNumber(
                                        item?.carrierContact?.phone
                                      )}
                                    </p>
                                  )}
                                  {isEdit && (
                                    <EditPencilButton
                                      onClick={() => {
                                        setIsShowUpdateCarrierContactModal({
                                          type: ACTION_TYPES.EDIT as keyof typeof ACTION_TYPES,
                                          show: true,
                                          employerId: employerId!,
                                          carrierId: item?.carrierId,
                                          benefitType: item?.benefitCategory,
                                          value: item?.carrierContact?.id,
                                        });
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            ) : isEdit ? (
                              <Button
                                className={styles.addBtn}
                                type="default"
                                label="Add Carrier Contact"
                                onClick={() => {
                                  setIsShowUpdateCarrierContactModal({
                                    type: ACTION_TYPES.ADD as keyof typeof ACTION_TYPES,
                                    show: true,
                                    employerId: employerId!,
                                    carrierId: item?.carrierId,
                                    benefitType: item?.benefitCategory,
                                  });
                                }}
                              />
                            ) : (
                              <p className={styles.placeholder}>-</p>
                            )}
                          </TableBodyData>
                        </tr>
                      );
                    });
                  })}
                </tbody>
              )
            )}
          </table>
        </div>
      </div>
      <ConfirmationDialog
        visible={isShowUpdateGroupIDModal.show}
        centered={true}
        confirmBtnFullWidth={true}
        title={`${
          isShowUpdateGroupIDModal.type === ACTION_TYPES.ADD ? 'Add' : 'Edit'
        } Group ID / Policy #`}
        confirmText="Save"
        confirmLoading={isUpdateGroupIdLoading}
        cancelText="Cancel"
        isCancelLink={true}
        closeModal={() => {
          setIsShowUpdateGroupIDModal({
            type: 'ADD',
            show: false,
            masterId: '',
            benefitType: '',
            value: '',
          });
        }}
        onConfirm={handleUpdateGroupIDPolicyNumber}
        destroyOnClose={true}
      >
        <div>
          <InputForm form={groupIdFrom} layout="vertical">
            <Form.Item name="groupId" label={`Group ID / Policy #`}>
              <Input />
            </Form.Item>
          </InputForm>
        </div>
      </ConfirmationDialog>

      <ConfirmationDialog
        visible={isShowUpdateCarrierContactModal.show}
        centered={true}
        confirmBtnFullWidth={true}
        title={`${
          isShowUpdateCarrierContactModal.type === ACTION_TYPES.ADD
            ? 'Add'
            : 'Edit'
        } Carrier Contact`}
        confirmText="Save"
        confirmLoading={
          isCarrierContactDataFetching || isUpdateCarrierContactLoading
        }
        cancelText="Cancel"
        isCancelLink={true}
        closeModal={() => {
          resetCarrierContactState();
        }}
        onConfirm={handleUpdateCarrierContact}
        destroyOnClose={true}
      >
        <div className={styles.carrierContactUpdateAddContainer}>
          <InputForm form={carrierContactsForm} layout="vertical">
            <Form.Item
              labelCol={{ xs: 24 }}
              name="carrierContactId"
              label={
                <Row className={styles.addCarrierContactWrapper}>
                  <Col>Carrier Contact</Col>
                  <Col
                    className={styles.manageCarrierContact}
                    onClick={() => {
                      handleManageCarrierContactsClick();
                    }}
                  >
                    Manage Carrier Contact
                  </Col>
                </Row>
              }
            >
              <Select
                allowClear
                listHeight={200}
                filterOption={(input, option: any) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                showSearch
                loading={isCarrierContactDataFetching}
                optionLabelProp="label"
              >
                {carrierContactData?.map((item: any) => {
                  return (
                    <Select.Option
                      key={item?.id}
                      value={item?.id}
                      label={item?.fullName}
                    >
                      <div className={styles.optionWrapper}>
                        <div>
                          {item?.fullName}{' '}
                          <span className={styles.optionalText}>
                            - {item?.carrierName}
                          </span>
                        </div>
                        <div className={styles.openIcon}>
                          <PopoutIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCarrierContactNavigateToEdit(item?.id);
                            }}
                          />
                        </div>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </InputForm>
        </div>
      </ConfirmationDialog>
    </>
  );
};

export default PolicyNumberAndCarrierContactContent;
