import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { cloneDeep, isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import NextButton from 'components/buttons/NextButton/NextButton';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import CustomerSpinner from 'components/Spinner/CustomSpinner';
import { showErrorNotification } from 'components/Notification/Notification';

import TextButton from 'components/buttons/TextButton/TextButton';
import ModelingStrategyCard from 'modules/renewals/components/addProposalsComponents/ModelingContainerWrapperNew/ModelingStrategyCard/ModelingStrategyCard';
import ModelingTable from 'modules/renewals/components/addProposalsComponents/ModelingContainerWrapperNew/ModelingTable/ModelingTable';
import { setAntDModalScrollToTop } from 'util/domUtils';
import {
  isUpdatedFieldNullCheck,
  isUpdatedFieldPresent,
  setBasePlanInitialUpdatedField,
  setUpdatedFieldToSkipInitialCalculation,
  transformModelingTableDataToPlanContribution,
  transformPlanContributionToModelingTableData,
} from 'modules/renewals/utils/modelingUtils';
import { addProposalCompletedSteps } from 'modules/renewals/utils/renewalsUtils';
import { ReactComponent as UndoIcon } from 'assets/images/undo-refresh.svg';
import {
  BASE_PLAN_BUY_UP,
  DRAFTS_TAB,
  MDV_PLANS,
  PER_TIER,
  PLAN_TYPES,
  SORT_PLANS_LIST,
  PROPOSAL_MODELING_SECTION,
  TIER_LIST_ENUMS,
  SORT_MODELING_CARRIER,
  SORT_MODELING_CUSTOM,
  OFFER_STATUS,
} from 'modules/renewals/constants/renewalsConstants';
import {
  useCalculateModelingContributionMutation,
  useLazyGetContributionsByOfferQuery,
  useLazyGetInitialContributionsByOfferQuery,
  useUpdateBundleContributionMutation,
} from 'modules/renewals/slices/offersSlice';
import { setCompletedProposalSteps } from 'modules/renewals/slices/proposalCreateStepperSlice';
import { useProposalStatusUpdateMutation } from 'modules/renewals/slices/proposalSlice';
import RenewalInnerStepperDto from 'model/RenewalInnerStepperDto';
import {
  ModelingBaseType,
  PlanContributionsEntity,
} from 'model/ModelingBaseType';
import { loginTypes } from 'constants/authConstants';
import SelectOptions from 'components/SelectOptions/SelectOptions';

import styles from './modelingContainerWrapperNew.module.less';

type Props = {
  type: any;
  handleStepperComplete: (
    key: number,
    isFullyComplete: boolean,
    isNextClick: boolean
  ) => void;
  proposalId: string;
  closeModal: (showConfirmMessage: any) => void;
  mainsStepperData: RenewalInnerStepperDto[];
  handleNavigation: () => void;
  isProposalDetailedView?: boolean;
};

export type StrategyContributionType = {
  strategy: string;
  contributionPlanId?: string;
  planContributionId?: string;
};

const ModelingContainerWrapperNew = (props: Props, ref: any) => {
  const {
    proposalId,
    type,
    mainsStepperData,
    isProposalDetailedView,
    handleStepperComplete,
    closeModal,
  } = props;
  const { employerId, brokerId } = useParams();
  const navigate = useNavigate();
  const { Option } = Select;
  const dispatch = useAppDispatch();
  const completedProposalSteps = useAppSelector(
    (state) => state.proposalOnBoarding.completedProposalSteps
  );

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;

  const [selectedStrategyAndContribution, setSelectedStrategyAndContribution] =
    useState<StrategyContributionType>({
      strategy: '',
      contributionPlanId: '',
      planContributionId: '',
    });
  const [initialData, setInitialData] = useState<ModelingBaseType | null>(null);
  const [modelingBaseData, setModelingBaseData] =
    useState<ModelingBaseType | null>(null);
  const [modelingTablesData, setModelingTableData] = useState<any[]>([]);
  const [isModelingChanged, setIsModelingChange] = useState(false);
  const [changedPlanContributions, setChangedPlanContributions] = useState<
    string[]
  >([]);
  const [showStrategyChangeConfirmation, setShowStrategyChangeConfirmation] =
    useState({ show: false, value: '' });
  const [sortCategory, setSortCategory] = useState<string>('');
  // state to manage table data before undo action
  const [modelingTablesDataPreRoundOff, setModelingTablesDataPreRoundOff] =
    useState<any[]>([]);
  const [isRoundOffUndoActive, setIsRoundOffUndoActive] = useState(false);

  const sortValue =
    sortCategory === SORT_MODELING_CUSTOM.label
      ? SORT_MODELING_CUSTOM.value
      : sortCategory;

  const [
    getContributionData,
    {
      data: contributionData,
      isLoading: isContributionDataLoading,
      isFetching: isContributionDataIsFetching,
      isSuccess: isContributionDataIsSuccess,
    },
  ] = useLazyGetContributionsByOfferQuery();

  const [
    getInitContributionData,
    {
      data: initContributionData,
      isFetching: isInitDataFetching,
      isSuccess: isInitDataSuccess,
    },
  ] = useLazyGetInitialContributionsByOfferQuery();

  const [
    calculatedModelingContributionData,
    {
      data: calculatedModelingData,
      isLoading: isLoadingCalculatedModelingData,
      isSuccess: isSuccessCalculatedModelingData,
      reset: resetCalculatedModelingData,
    },
  ] = useCalculateModelingContributionMutation();

  const [
    updateBundleContribution,
    { isLoading: isUpdateBundleLoading, reset: resetUpdateBundleContribution },
  ] = useUpdateBundleContributionMutation();

  const [draftProposal, { isLoading: changeStatusIsLoading }] =
    useProposalStatusUpdateMutation();

  useEffect(() => {
    // get initial modeling data set

    if (MDV_PLANS.includes(type)) {
      getInitContributionData({
        category: type,
        proposalId,
        employerId,
      });

      getContributionData({
        category: type,
        proposalId,
        employerId,
      });
    }

    dispatch(
      setCompletedProposalSteps(
        addProposalCompletedSteps(
          completedProposalSteps,
          `${PROPOSAL_MODELING_SECTION}_${type}`
        )
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (isInitDataSuccess) {
      setInitialData(initContributionData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitDataFetching]);

  useEffect(() => {
    if (isContributionDataIsSuccess) {
      setModelingBaseData(contributionData);
      setModelingTableData(
        transformPlanContributionToModelingTableData(
          contributionData?.planContributions
        )
      );
      setSortCategory(contributionData?.planSortType);
      const changedPlanList = contributionData?.planContributions
        ?.filter((item: any) => item?.planChanged)
        ?.map((item: any) => item?.id);

      setChangedPlanContributions(changedPlanList);
      setSelectedStrategyAndContribution({
        strategy: contributionData?.strategy
          ? contributionData?.strategy
          : PER_TIER,
        contributionPlanId: contributionData?.baseBuyUpPlanId || '',
        planContributionId: contributionData?.baseBuyUpPlanId
          ? contributionData?.planContributions?.find(
              (item: any) => item.planId === contributionData?.baseBuyUpPlanId
            )?.id
          : '',
      });
      setIsRoundOffUndoActive(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContributionDataIsFetching]);

  useEffect(() => {
    if (isSuccessCalculatedModelingData) {
      setModelingBaseData(calculatedModelingData);
      setModelingTableData(
        transformPlanContributionToModelingTableData(
          calculatedModelingData?.planContributions
        )
      );
      setSortCategory(calculatedModelingData?.planSortType);
      setSelectedStrategyAndContribution({
        strategy: calculatedModelingData?.strategy
          ? calculatedModelingData?.strategy
          : PER_TIER,
        contributionPlanId: calculatedModelingData?.baseBuyUpPlanId || '',
        planContributionId: calculatedModelingData?.baseBuyUpPlanId
          ? calculatedModelingData?.planContributions?.find(
              (item: any) =>
                item.planId === calculatedModelingData?.baseBuyUpPlanId
            )?.id
          : '',
      });
      resetCalculatedModelingData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCalculatedModelingData]);

  const isABasePlan = (planObj: PlanContributionsEntity) => {
    return (
      selectedStrategyAndContribution.strategy == BASE_PLAN_BUY_UP &&
      selectedStrategyAndContribution.contributionPlanId &&
      planObj.planId === selectedStrategyAndContribution.contributionPlanId
    );
  };

  // Check if any of the plans contains a hsa plan
  const checkIfHsaExist = (): boolean => {
    return !!modelingBaseData?.planContributions?.find(
      (obj) => obj.planType === PLAN_TYPES.HDHP || obj?.hsaCompatible
    );
  };

  // Check if any of the plans contains a hsa plan
  const checkIfHraExist = (): boolean => {
    return !!modelingBaseData?.planContributions?.find(
      (obj) => obj.hraCompatible
    );
  };

  const handleModelingOnChange = ({
    planId,
    mainDataIndex,
    tableRowIndex,
    modelingKey,
    updatedField,
    value,
  }: {
    planId: string;
    mainDataIndex: number;
    tableRowIndex: number;
    modelingKey: string;
    updatedField: string;
    value: any;
  }) => {
    const { strategy, planContributionId } = selectedStrategyAndContribution;

    if (strategy === BASE_PLAN_BUY_UP && planId === planContributionId) {
      setChangedPlanContributions([planId]);
    } else {
      setChangedPlanContributions((prevData) =>
        prevData.includes(planId) ? prevData : [...prevData, planId]
      );
    }
    setModelingTableData((prevData) => {
      const newData = [...prevData];
      const tierContributionsCopy = [
        ...newData[mainDataIndex]?.tierContributionsDefault,
      ];

      tierContributionsCopy[tableRowIndex][modelingKey] = value || 0;
      tierContributionsCopy[tableRowIndex].updatedField = updatedField;
      newData[mainDataIndex].tierContributionsDefault = tierContributionsCopy;

      return newData;
    });
    setIsModelingChange(true);
    setIsRoundOffUndoActive(false);
  };

  const handlePlanLevelAttributeChange = ({
    planId,
    modelingKey,
    value,
  }: {
    planId: string;
    modelingKey: string;
    value: any;
  }) => {
    setModelingTableData((prevData) => {
      const newData = [...prevData];
      // find the
      newData.forEach((obj) => {
        if (obj.id === planId) {
          obj[modelingKey] = value;
          obj.tierContributionsDefault[0].updatedField =
            TIER_LIST_ENUMS.EE_PREMIUM;
          return;
        }
      });
      return newData;
    });

    if (!isEmpty(planId)) {
      setChangedPlanContributions((prevData) =>
        prevData?.includes(planId) ? prevData : [...prevData, planId]
      );
    }
    setIsModelingChange(true);
    setIsRoundOffUndoActive(false);
  };

  const calculateModelingOnBlur = () => {
    const payload: any = {
      ...modelingBaseData,
      changedPlanContributionList: changedPlanContributions,
      planContributions:
        transformModelingTableDataToPlanContribution(modelingTablesData),
      strategy: selectedStrategyAndContribution.strategy,
      baseBuyUpPlanId:
        selectedStrategyAndContribution.strategy === BASE_PLAN_BUY_UP
          ? selectedStrategyAndContribution.contributionPlanId
          : null,
      planSortType: sortValue,
    };

    if (isUpdatedFieldNullCheck(payload.planContributions)) return;

    calculatedModelingContributionData({
      baseModelingDto: payload,
      proposalId: proposalId,
    });
  };

  const getIsNewPlanOrConsolidatedPlan = useCallback(() => {
    const isNewPlanOrConsolidatedPlan =
      modelingBaseData?.planContributions?.some(
        (item) => item?.newPlan || item?.consolidatedPlan
      );

    return isNewPlanOrConsolidatedPlan;
  }, [modelingBaseData?.planContributions]);

  const handleStrategyOnChange = (strategy: string) => {
    setChangedPlanContributions([]);
    setIsRoundOffUndoActive(false);
    let payload: any;
    if (strategy === BASE_PLAN_BUY_UP) {
      const random = Math.floor(
        Math.random() * (modelingBaseData?.planContributions?.length ?? 0)
      );
      const initialPlanObj: any = modelingBaseData?.planContributions?.[random];
      payload = {
        ...initialData,
        strategy: strategy,
        baseBuyUpPlanId: initialPlanObj?.planId,
        planSortType: SORT_MODELING_CARRIER,
      };
      if (!isUpdatedFieldPresent(payload)) {
        payload = setBasePlanInitialUpdatedField(payload);
      }
      setSelectedStrategyAndContribution({
        strategy: strategy,
        contributionPlanId: initialPlanObj?.planId,
        planContributionId: initialPlanObj?.id,
      });
    } else {
      payload = {
        ...initialData,
        strategy: strategy,
        baseBuyUpPlanId: null,
      };
      setSelectedStrategyAndContribution({
        strategy: strategy,
        contributionPlanId: '',
        planContributionId: '',
      });
    }

    calculatedModelingContributionData({
      baseModelingDto: payload,
      proposalId: proposalId,
    });
  };

  const handleContributionOnChange = (
    contribution: string,
    fullOption: any
  ) => {
    setChangedPlanContributions([]);
    let payload: any = {
      ...modelingBaseData,
      strategy: selectedStrategyAndContribution.strategy,
      baseBuyUpPlanId: contribution,
      planSortType: SORT_MODELING_CARRIER,
    };

    if (!isUpdatedFieldPresent(payload)) {
      payload = setBasePlanInitialUpdatedField(payload);
    }

    setSelectedStrategyAndContribution({
      ...selectedStrategyAndContribution,
      contributionPlanId: contribution,
      planContributionId: fullOption?.id,
    });

    calculatedModelingContributionData({
      baseModelingDto: payload,
      proposalId: proposalId,
    });
  };

  const getTheApplicableStepperKey = (): number => {
    const matchingStep = mainsStepperData?.find(
      (step) => step.benefitKind === type
    );
    return matchingStep ? matchingStep.id : -1;
  };

  const handleResetStates = () => {
    setChangedPlanContributions([]);
    setSelectedStrategyAndContribution({
      strategy: '',
      contributionPlanId: '',
      planContributionId: '',
    });
    setInitialData(null);
    setModelingBaseData(null);
    setModelingTableData([]);
    setIsModelingChange(false);
  };

  const handleErrorNotification = () => {
    showErrorNotification({
      message: 'Something went wrong !',
      description: 'Error while updating proposal',
      icon: <CloseOutlined className={styles.notificationErrorIcon} />,
    });
  };

  const handleBackNextClick = async ({ isNext }: { isNext: boolean }) => {
    try {
      await updateBundleContribution({
        baseModelingDto: {
          ...modelingBaseData,
          planContributions:
            transformModelingTableDataToPlanContribution(modelingTablesData),
          planSortType: sortValue,
        },
        proposalId,
        employerId,
      });
      const key: number = getTheApplicableStepperKey();
      handleStepperComplete(key, true, isNext);
      handleResetStates();
    } catch (error) {
      handleErrorNotification();
    } finally {
      resetUpdateBundleContribution();
      setAntDModalScrollToTop();
    }
  };

  // handle back, next, save draft
  const handleSubmit = async (type: 'BACK' | 'NEXT' | 'DRAFT') => {
    switch (type) {
      case 'BACK': {
        handleBackNextClick({ isNext: false });
        break;
      }
      case 'NEXT': {
        handleBackNextClick({ isNext: true });
        break;
      }
      case 'DRAFT': {
        try {
          await updateBundleContribution({
            baseModelingDto: {
              ...modelingBaseData,
              planContributions:
                transformModelingTableDataToPlanContribution(
                  modelingTablesData
                ),
              planSortType: sortValue,
              proposalStatus: 'DRAFT',
            },
            proposalId,
            employerId,
          });
          await draftProposal({
            proposalId: proposalId ?? '',
            proposalStatus: OFFER_STATUS.DRAFT,
            isNotificationActive: false,
          }).unwrap();
          handleResetStates();
          closeModal(true);
          if (isProposalDetailedView && proposalId) {
            navigate(
              `/brokers/${brokerId}/employers/${employerId}/renewals/proposals/details/${proposalId}`
            );
          } else {
            navigate(
              `/brokers/${brokerId}/employers/${employerId}/renewals/proposals`,
              { state: { proposalStatus: DRAFTS_TAB } }
            );
          }
        } catch (error) {
          handleErrorNotification();
        } finally {
          resetUpdateBundleContribution();
        }
        break;
      }
    }
  };

  const sortOptionsAccordingToCategory = (
    category: string
  ): { value: string; label: string }[] => {
    switch (category) {
      case 'MEDICAL':
        return SORT_PLANS_LIST.MEDICAL;
      default:
        return SORT_PLANS_LIST.OTHER;
    }
  };

  const handleClose = async () => {
    await updateBundleContribution({
      baseModelingDto: {
        ...modelingBaseData,
        planContributions:
          transformModelingTableDataToPlanContribution(modelingTablesData),
        proposalStatus: 'PREVIEW',
        planSortType: sortValue,
      },
      proposalId,
      employerId,
    });
    setIsModelingChange(false);
  };

  useImperativeHandle(ref, () => ({
    handleClose: async () => {
      await handleClose();
    },
  }));

  const generateLoadingTable = () => {
    return (
      <div className={styles.tableLoader}>
        <CustomerSpinner />
      </div>
    );
  };

  const handleRoundOff = () => {
    // keep the initial state before round off for the purpose of undoing
    setModelingTablesDataPreRoundOff(cloneDeep(modelingTablesData));
    triggerCalculationOnRoundOffAndUndo(modelingTablesData, true);
    setIsRoundOffUndoActive(!isRoundOffUndoActive);
  };

  // Function to trigger calculation of round off and in undo to trigger the calculation API
  const triggerCalculationOnRoundOffAndUndo = (
    data: any[],
    isRoundOff: boolean
  ) => {
    // set the modified table data backend original payload and pass it to calculation end point
    const payload: any = {
      ...modelingBaseData,
      changedPlanContributionList: changedPlanContributions,
      planContributions: transformModelingTableDataToPlanContribution(data),
      strategy: selectedStrategyAndContribution.strategy,
      baseBuyUpPlanId:
        selectedStrategyAndContribution.strategy === BASE_PLAN_BUY_UP
          ? selectedStrategyAndContribution.contributionPlanId
          : null,
      planSortType: sortValue,
    };

    calculatedModelingContributionData({
      baseModelingDto: setUpdatedFieldToSkipInitialCalculation(payload),
      proposalId: proposalId,
      isRoundOff: isRoundOff,
    });
  };

  const handleRoundOffUndo = () => {
    triggerCalculationOnRoundOffAndUndo(modelingTablesDataPreRoundOff, false);
    setIsRoundOffUndoActive(!isRoundOffUndoActive);
    // reset temporary state
    setModelingTablesDataPreRoundOff([]);
  };

  const onSortValueChange = (value: string) => {
    setSortCategory(value);
    const payload: any = {
      ...modelingBaseData,
      planSortType: value,
    };
    calculatedModelingContributionData({
      baseModelingDto: payload,
      proposalId: proposalId,
    });
  };

  const handleCustomSort = (move: 'UP' | 'DOWN', currentIndex: number) => {
    setSortCategory(SORT_MODELING_CUSTOM.label);
    const copyData = cloneDeep(modelingTablesData);
    let moveTo;
    if (move === 'UP') {
      moveTo = currentIndex - 1;
    } else {
      moveTo = currentIndex + 1;
    }
    copyData.splice(moveTo, 0, copyData.splice(currentIndex, 1)[0]);
    setModelingTableData(copyData);
  };

  return (
    <>
      {isUpdateBundleLoading ||
      isContributionDataIsFetching ||
      isContributionDataIsFetching ? (
        generateLoadingTable()
      ) : (
        <>
          <ModelingStrategyCard
            contributionData={modelingBaseData}
            selectedStrategyAndContribution={selectedStrategyAndContribution}
            setSelectedStrategy={(strategy) => {
              if (getIsNewPlanOrConsolidatedPlan() && isModelingChanged) {
                setShowStrategyChangeConfirmation({
                  show: true,
                  value: strategy,
                });
                return;
              }
              handleStrategyOnChange(strategy);
            }}
            setSelectedContribution={(contribution, fullOption) =>
              handleContributionOnChange(contribution, fullOption)
            }
            isCustomEdited={Boolean(modelingBaseData?.proposalChanged)}
            isHsaExist={checkIfHsaExist()}
            isHraExist={checkIfHraExist()}
            isDisabled={
              isLoadingCalculatedModelingData || isContributionDataLoading
            }
          />
          <div className={styles.actionContainer}>
            {!isRoundOffUndoActive ? (
              <Button
                className={styles.roundingButton}
                onClick={handleRoundOff}
              >
                Round ee contributions to nearest dollar
              </Button>
            ) : (
              <Button
                className={styles.roundingButton}
                onClick={handleRoundOffUndo}
              >
                Round ee contributions to nearest dollar
                <UndoIcon />
              </Button>
            )}
            <div className={styles.sortSelectionWrapper}>
              <div className={styles.sortLabel}>
                <span>Sort:</span>
              </div>
              <SelectOptions
                onChange={onSortValueChange}
                value={sortCategory}
                dropdownMatchSelectWidth={false}
              >
                {sortOptionsAccordingToCategory(type).map((item, index) => (
                  <Option value={item.value} key={index}>
                    {item.label}
                  </Option>
                ))}
              </SelectOptions>
            </div>
          </div>

          <div>
            {modelingTablesData?.map((item, idx) => {
              return (
                <ModelingTable
                  key={item?.id}
                  data={item}
                  isHsa={
                    item.planType === PLAN_TYPES.HDHP || item.hsaCompatible
                  }
                  isHra={item.hraCompatible}
                  isBasePlan={Boolean(isABasePlan(item))}
                  isCustomEdited={Boolean(item?.planChanged)}
                  isLoading={isLoadingCalculatedModelingData}
                  handleTableOnChange={({
                    tableRowIndex,
                    modelingKey,
                    updatedField,
                    value,
                  }: {
                    tableRowIndex: number;
                    modelingKey: string;
                    updatedField: string;
                    value: any;
                  }) => {
                    handleModelingOnChange({
                      planId: item?.id,
                      mainDataIndex: idx,
                      tableRowIndex,
                      modelingKey,
                      updatedField,
                      value,
                    });
                  }}
                  handlePlanLevelAttributeChange={({
                    planId,
                    modelingKey,
                    value,
                  }: {
                    planId: string;
                    modelingKey: string;
                    value: any;
                  }) => {
                    handlePlanLevelAttributeChange({
                      planId,
                      modelingKey,
                      value,
                    });
                  }}
                  handleOnBlur={calculateModelingOnBlur}
                  handleCustomSort={(move) => handleCustomSort(move, idx)}
                  currentIndex={idx}
                  totalPlans={modelingTablesData?.length}
                  strategy={selectedStrategyAndContribution.strategy}
                />
              );
            })}
          </div>

          <ConfirmationDialog
            visible={showStrategyChangeConfirmation.show}
            title="Change Contribution Strategy"
            confirmText="Yes - Change Strategy"
            onConfirm={() => {
              handleStrategyOnChange(showStrategyChangeConfirmation.value);
              setShowStrategyChangeConfirmation({ show: false, value: '' });
              setIsModelingChange(false);
            }}
            cancelText="Cancel"
            closeModal={() => {
              setShowStrategyChangeConfirmation({ show: false, value: '' });
            }}
            isCancelLink
            confirmBtnFullWidth={true}
          >
            <div className={styles.strategyChangeModalBodyWrapper}>
              Changing the contribution strategy will reset all the values you
              updated on this screen. Please confirm before proceeding.
            </div>
          </ConfirmationDialog>
        </>
      )}
      <Row className={styles.btnRow} gutter={[16, 16]}>
        <Col className={styles.backBtnWrapper} span={8}>
          <SubmitButton
            className={styles.cancelButton}
            onClick={() => {
              handleSubmit('BACK');
            }}
            disabled={false}
            loading={
              isInitDataFetching ||
              isContributionDataIsFetching ||
              isLoadingCalculatedModelingData ||
              isUpdateBundleLoading
            }
          >
            Back
          </SubmitButton>
        </Col>
        <Col span={8}>
          <NextButton
            nextStep={() => {
              handleSubmit('NEXT');
            }}
            className={styles.nextBtn}
            loading={
              isInitDataFetching ||
              isContributionDataIsFetching ||
              isLoadingCalculatedModelingData ||
              isUpdateBundleLoading ||
              changeStatusIsLoading
            }
          />
        </Col>
        {!isErAdmin && (
          <Col span={24} className={styles.saveDraftBtn}>
            <TextButton
              label="Save Draft & Close"
              onClick={() => {
                handleSubmit('DRAFT');
              }}
              disabled={changeStatusIsLoading}
              type="primary"
            />
          </Col>
        )}
      </Row>
    </>
  );
};

ModelingContainerWrapperNew.displayName = 'ModelingContainerWrapperNew';

export default forwardRef(ModelingContainerWrapperNew);
