import React from 'react';

import { Row } from 'antd';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

const IdCardEmployeeData = (props: any) => {
  return (
    <>
      <Row>
        <span>
          <OverflowPopover maxWidth={'160px'}>
            {props.data.name}
          </OverflowPopover>
        </span>
      </Row>
      <Row>
        <span>
          <OverflowPopover maxWidth={'160px'}>
            {props.data.primarySubscriber}
          </OverflowPopover>
        </span>
      </Row>
    </>
  );
};

export default IdCardEmployeeData;
