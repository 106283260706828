import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import PlanDocuments from 'modules/plans/components/PlanDocuments/PlanDocuments';
import { MedicalPlanDocumentType } from 'modules/plans/constants';
import {
  updateMedicalPlan,
  uploadTemporaryDocument,
} from 'modules/plans/slices/medicalPlanSlice';
import MedicalPlan from 'model/plans/MedicalPlan';

type PlanDocumentUPWrapperProps = {
  onValidateFails?: (validateSetting: string) => void;
  isDisable?: boolean;
  isAFPReview?: boolean;
  isOnlyAfpVisible?: boolean;
  plan?: any;
  onPlanChange?: Function;
  onFileChange?: Function;
};

const PlanDocumentUPWrapper: FC<PlanDocumentUPWrapperProps> = (props) => {
  const {
    plan,
    onValidateFails,
    isDisable = false,
    isAFPReview = false,
    isOnlyAfpVisible = false,
    onPlanChange = (updatedMedicalPlan: MedicalPlan) => {
      dispatch(updateMedicalPlan(updatedMedicalPlan));
    },
    onFileChange = (document: File, docType: string) => {
      dispatch(uploadTemporaryDocument(document, docType));
    },
  } = props;
  const { medicalPlan } = useAppSelector((state) => state.plan.plans);
  const dispatch = useAppDispatch();

  return (
    <PlanDocuments
      planDocumentTypes={MedicalPlanDocumentType}
      onFileChange={onFileChange}
      plan={plan ?? medicalPlan}
      onPlanChange={onPlanChange}
      isSBC
      onValidateFails={onValidateFails}
      isDisable={isDisable}
      isAFPReview={isAFPReview}
      isOnlyAfpVisible={isOnlyAfpVisible}
    />
  );
};

export default PlanDocumentUPWrapper;
