import { Dispatch } from 'redux';
import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdditionalPlan } from 'model/plans/AdditionalPlan';
import * as AdditionalPerkPlanService from 'modules/plans/services/AdditionalPerkPlanService';
import MetaData from 'model/MetaData';
import PaginationConfig from 'model/PaginationConfig';
import ErrorDetails from 'model/ErrorDetails';
import ClonePlanDocument from 'model/plans/ClonePlanDocument';
import { BenefitCategory } from 'constants/commonConstants';
import { clonePlanDocuments } from './planDocumentsSlice';

export type AdditionalPerkPlanServiceState = {
  inProgress: boolean;
  error: any;
  requestType: string;
  additionalPerkPlan: AdditionalPlan;
  additionalPerkPlanList: {
    content: Array<AdditionalPlan>;
    metadata: MetaData;
  };
};

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  additionalPerkPlan: {} as AdditionalPlan,
  additionalPerkPlanList: { content: [], metadata: {} },
} as AdditionalPerkPlanServiceState;

const additionalPerkPlanSlice = createSlice({
  name: 'additionalPerkPlan',
  initialState,
  reducers: {
    createAdditionalPerkPlan: (
      state,
      action: PayloadAction<AdditionalPlan>
    ) => {
      state.additionalPerkPlan = action.payload;
      state.inProgress = false;
      state.error = null;
      state.requestType = action.type;
    },
    updateAdditionalPerkPlanInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    updateAdditionalPerkPlanCompleted: (
      state,
      action: PayloadAction<AdditionalPlan>
    ) => {
      state.inProgress = false;
      state.additionalPerkPlan = {
        ...state.additionalPerkPlan,
        ...action.payload,
      };
      state.error = null;
      state.requestType = action.type;
    },
    updateAdditionalPerkPlanFailed: (state, action) => {
      state.inProgress = false;
      state.error = JSON.parse(JSON.stringify(action.payload));
      state.requestType = action.type;
    },
    getAdditionalPerkPlansListInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    getAdditionalPerkPlansListCompleted: (
      state,
      action: PayloadAction<any>
    ) => {
      const { payload } = action;
      state.inProgress = false;
      state.error = null;
      state.additionalPerkPlanList = payload;
    },
    getAdditionalPerkPlansListFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = { response: action.payload };
    },
    getPlanByIdStarted: (state) => {
      state.inProgress = true;
      state.additionalPerkPlan = {} as AdditionalPlan;
      state.error = null;
    },
    getPlanByIdCompleted: (state, action: PayloadAction<AdditionalPlan>) => {
      state.inProgress = false;
      state.additionalPerkPlan = action.payload;
      state.error = null;
    },
    getPlanByIdFailed: (state, action) => {
      state.inProgress = false;
      state.additionalPerkPlan = {} as AdditionalPlan;
      state.error = { response: action.payload };
    },
    clearAdditionalPerksApiErrors: (state) => {
      state.error = null;
    },
  },
});

export const {
  createAdditionalPerkPlan,
  updateAdditionalPerkPlanInProgress,
  updateAdditionalPerkPlanCompleted,
  updateAdditionalPerkPlanFailed,
  getAdditionalPerkPlansListInProgress,
  getAdditionalPerkPlansListCompleted,
  getAdditionalPerkPlansListFailed,
  getPlanByIdStarted,
  getPlanByIdCompleted,
  getPlanByIdFailed,
  clearAdditionalPerksApiErrors,
} = additionalPerkPlanSlice.actions;

export default additionalPerkPlanSlice.reducer;

export const saveAdditionalPerkPlan = (
  plan: AdditionalPlan,
  onSave: Function,
  cloneDocuments?: boolean,
  sourcePlanId?: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateAdditionalPerkPlanInProgress());
      let response = {} as any;
      if (plan.id) {
        response = await AdditionalPerkPlanService.saveAdditionalPerkPlan(
          plan,
          true
        );
      } else {
        response = await AdditionalPerkPlanService.saveAdditionalPerkPlan(plan);
      }
      const data = response.data;
      dispatch(updateAdditionalPerkPlanCompleted(data));
      if (data && cloneDocuments && plan.documents && sourcePlanId) {
        const clonePlanDoc: ClonePlanDocument = {
          employerId: data.employerId,
          sourcePlanId: sourcePlanId,
          targetPlanId: data.id,
          benefitCategory: BenefitCategory.ADDITIONAL_PERK.value,
          benefitKind: plan.benefitKind,
        };
        dispatch(clonePlanDocuments(clonePlanDoc));
      }
      onSave(data.id);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            updateAdditionalPerkPlanFailed({
              data: error.response.data,
            } as ErrorDetails)
          );
        }
      }
    }
  };
};

export const getAdditionalPerkPlanList = (
  { page, size, sort, query }: PaginationConfig,
  employerId: string,
  planYearId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getAdditionalPerkPlansListInProgress());
    try {
      const response =
        await AdditionalPerkPlanService.getAdditionalPerkPlanList(
          page,
          size,
          sort,
          query,
          employerId,
          planYearId
        );
      dispatch(getAdditionalPerkPlansListCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getAdditionalPerkPlansListFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const findAdditionalPerkPlanById = (planId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPlanByIdStarted());
    try {
      const response = await AdditionalPerkPlanService.getPlanById(planId);
      dispatch(getPlanByIdCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(getPlanByIdFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
};
