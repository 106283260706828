import React, { useState, useMemo } from 'react';
import { Col, Popover, Row } from 'antd';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import CustomerSpinner from 'components/Spinner/CustomSpinner';
import OfferCardOverview from 'modules/renewals/components/helperComponents/OfferCardOverview/OfferCardOverview';
import FromCurrentPercentage from 'modules/renewals/components/helperComponents/FromCurrentPercentage/FromCurrentPercentage';
import ModelingHelpPopup from 'modules/renewals/components/addProposalsComponents/ModelingContainerWrapperNew/ModelingHelpPopup/ModelingHelpPopup';
import {
  appendPlusOrMinusBasedOnValue,
  currencyFormatterNoDecimals,
  currencyFormatterWithoutZeros,
} from 'util/commonUtil';
import {
  BASE_PLAN_BUY_UP,
  MODELING_STRATEGY_LIST,
} from 'modules/renewals/constants/renewalsConstants';
import { StrategyContributionType } from 'modules/renewals/components/addProposalsComponents/ModelingContainerWrapperNew/ModelingContainerWrapperNew';

import { ReactComponent as DropDownBlueArrow } from 'assets/images/dropdown-down-arrow-blue.svg';
import { ReactComponent as PrimaryInfo } from 'assets/images/info-primary.svg';

import styles from './modelingStrategyCard.module.less';

type Props = {
  selectedStrategyAndContribution: StrategyContributionType;
  setSelectedStrategy: (strategy: string) => void;
  setSelectedContribution: (contribution: any, fullOption: any) => void;
  contributionData: any;
  isCustomEdited: boolean;
  isHsaExist: boolean;
  isHraExist: boolean;
  isDisabled: boolean;
};

const ModelingStrategyCard = (props: Props) => {
  const {
    selectedStrategyAndContribution: { strategy, contributionPlanId },
    setSelectedStrategy,
    setSelectedContribution,
    contributionData,
    isCustomEdited,
    isHsaExist,
    isDisabled,
    isHraExist,
  } = props;

  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  const getContributionNames = useMemo((): any[] => {
    return contributionData?.planContributions
      ? contributionData?.planContributions?.map((obj: any) => {
          return {
            value: obj?.planId,
            label: obj?.planName,
            id: obj?.id,
          };
        })
      : [];
  }, [contributionData?.planContributions]);

  return !contributionData ? (
    <CustomerSpinner />
  ) : (
    <>
      <div className={styles.mainCard}>
        <Row className={styles.filterRow}>
          <Col lg={10} xl={10}>
            <div className={styles.filterContainer}>
              <span className={styles.headerLabel}>
                Contribution Modeling Strategy:
              </span>
              <div className={styles.strategyDropDown}>
                <SingleSelectFilter
                  data={MODELING_STRATEGY_LIST}
                  placeholder={''}
                  showSearch={false}
                  isDisabled={isDisabled}
                  setFilterSelectedValue={setSelectedStrategy}
                  selectedValue={strategy}
                  defaultValue={null}
                  applicableIcon={<DropDownBlueArrow />}
                  inlineDropdown={true}
                  isSelectionHighlightAndTickApplicable={false}
                  className={styles.strategyFilter}
                />
              </div>
            </div>
          </Col>

          {strategy === BASE_PLAN_BUY_UP && (
            <Col lg={5} xl={5}>
              <div className={styles.filterContainerBasePlan}>
                <SingleSelectFilter
                  data={getContributionNames}
                  isDisabled={isDisabled}
                  placeholder={''}
                  showSearch={false}
                  setFilterSelectedValue={(value: string) => {
                    const fullOption = getContributionNames?.find(
                      (option) => option.value === value
                    );
                    setSelectedContribution(value, fullOption);
                  }}
                  selectedValue={contributionPlanId!}
                  defaultValue={null}
                  applicableIcon={<DropDownBlueArrow />}
                  inlineDropdown={true}
                  isSelectionHighlightAndTickApplicable={false}
                  isOverflowHide={true}
                />
              </div>
            </Col>
          )}

          <Col lg={6} xl={6}>
            <div
              className={styles.modelingHelpTxt}
              onClick={() => setModalVisibility(true)}
            >
              Modeling Help <PrimaryInfo className={styles.modelingHelpIcon} />
            </div>
          </Col>

          {isCustomEdited && <span className={styles.tag}>Custom Edited</span>}
        </Row>

        <Row className={styles.statHeaderRow}>
          <Col lg={7} xl={7}>
            <OfferCardOverview
              headerText={'Total Benefits Cost'}
              value={currencyFormatterNoDecimals(
                contributionData?.annualContributionDefault?.totalContribution
              )}
              className={styles.labelStyleSummary}
              footerElement={
                <>
                  <p className={styles.changeDescription}>
                    <span className={styles.changeInAmount}>
                      {appendPlusOrMinusBasedOnValue(
                        contributionData?.comparisons?.totalImpact
                      )}
                      {currencyFormatterNoDecimals(
                        Math.abs(contributionData?.comparisons?.totalImpact)
                      )}
                    </span>{' '}
                    <span>
                      <FromCurrentPercentage
                        percentage={
                          contributionData?.comparisons?.totalImpactPercent || 0
                        }
                        showBrackets={true}
                        singleLine={true}
                        decimalPlaces={2}
                        fontWeight={750}
                        fontSize={14}
                      />
                    </span>{' '}
                    from Current
                  </p>
                </>
              }
            />
          </Col>

          <Col lg={8} xl={8} className={styles.annualPremiumCol}>
            <OfferCardOverview
              headerText={
                <>
                  Employer Cost
                  {isHsaExist && isHraExist ? (
                    <Popover
                      content={
                        <>
                          <span>
                            ER HSA Funding :{' '}
                            {currencyFormatterNoDecimals(
                              contributionData?.annualContributionDefault
                                ?.totalHsaContribution
                            )}
                          </span>
                          <br />
                          <span>
                            ER HRA Cost :{' '}
                            {currencyFormatterWithoutZeros(
                              contributionData?.annualContributionDefault
                                ?.totalHraContribution
                            )}
                          </span>
                        </>
                      }
                      overlayClassName={'popoverInner recommendedtooltip'}
                    >
                      <span className={styles.inclErHsaText}>
                        (Incl. ER HSA/HRA)
                      </span>
                    </Popover>
                  ) : // only hra exist
                  isHraExist ? (
                    <Popover
                      content={`ER HRA Cost : ${currencyFormatterWithoutZeros(
                        contributionData?.annualContributionDefault
                          ?.totalHraContribution
                      )}`}
                      overlayClassName={'popoverInner recommendedtooltip'}
                    >
                      <span className={styles.inclErHsaText}>
                        (Incl. ER HRA Cost)
                      </span>
                    </Popover>
                  ) : (
                    // only hsa
                    isHsaExist && (
                      <Popover
                        content={`ER HSA Funding : ${currencyFormatterNoDecimals(
                          contributionData?.annualContributionDefault
                            ?.totalHsaContribution
                        )}`}
                        overlayClassName={'popoverInner recommendedtooltip'}
                      >
                        <span className={styles.inclErHsaText}>
                          (Incl. ER HSA)
                        </span>
                      </Popover>
                    )
                  )}
                </>
              }
              value={currencyFormatterNoDecimals(
                contributionData?.annualContributionDefault?.totalErContribution
              )}
              className={styles.labelStyleSummary}
              footerElement={
                <p className={styles.changeDescription}>
                  <span className={styles.changeInAmount}>
                    {appendPlusOrMinusBasedOnValue(
                      contributionData?.comparisons?.totalErImpact
                    )}
                    {currencyFormatterNoDecimals(
                      Math.abs(contributionData?.comparisons?.totalErImpact)
                    )}
                  </span>{' '}
                  <span>
                    <FromCurrentPercentage
                      percentage={
                        contributionData?.comparisons?.totalErImpactPercent || 0
                      }
                      showBrackets={true}
                      singleLine={true}
                      decimalPlaces={2}
                      fontWeight={750}
                      fontSize={14}
                    />
                  </span>{' '}
                  from Current
                </p>
              }
            />
          </Col>

          <Col lg={7} xl={7} className={styles.annualPremiumCol}>
            <OfferCardOverview
              headerText={'Employee Cost'}
              value={currencyFormatterNoDecimals(
                contributionData?.annualContributionDefault?.totalEePremium
              )}
              className={styles.labelStyleSummary}
              footerElement={
                <p className={styles.changeDescription}>
                  <span className={styles.changeInAmount}>
                    {appendPlusOrMinusBasedOnValue(
                      contributionData?.comparisons?.totalEeImpact
                    )}
                    {currencyFormatterNoDecimals(
                      Math.abs(contributionData?.comparisons?.totalEeImpact)
                    )}
                  </span>{' '}
                  <span>
                    <FromCurrentPercentage
                      percentage={
                        contributionData?.comparisons?.totalEeImpactPercent || 0
                      }
                      showBrackets={true}
                      singleLine={true}
                      decimalPlaces={2}
                      fontWeight={750}
                      fontSize={14}
                    />
                  </span>{' '}
                  from Current
                </p>
              }
            />
          </Col>
        </Row>
      </div>

      <ModelingHelpPopup
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
      />
    </>
  );
};

export default ModelingStrategyCard;
