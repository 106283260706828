import { cloneDeep, set } from 'lodash';
import { AxiosError, AxiosResponse } from 'axios';
import {
  createSlice,
  DeepPartial,
  Dispatch,
  PayloadAction,
  ThunkAction,
} from '@reduxjs/toolkit';

import {
  IAddProposalToPlanYearState,
  ISelectOption,
  TransformedPlan,
} from 'modules/renewals/types/renewalsTypes';
import * as ProposalService from 'modules/renewals/services/ProposalService';
import * as PlanService from 'modules/plans/services/PlanService';

import { BENEFIT_RESOURCE_PATH_MAP } from 'modules/renewals/constants/addToPlanYearConstants';

import { Plan } from 'model/plans/Plan';
import ProposalSummaryVO from 'model/ProposalSummaryVO';
import { RootState } from 'app/store';

const initialState: IAddProposalToPlanYearState = {
  basicDetails: {
    planYearName: '',
    benefitClasses: { data: [], status: { isLoading: false, isError: false } },
  },
  plans: {
    MEDICAL: [],
    DENTAL: [],
    VISION: [],
    LIFE_AND_DISABILITY: [],
    VOLUNTARY_BENEFIT: [],
    TAX_ADVANTAGE_ACCOUNTS: [],
  },
  proposalSummary: { status: { isLoading: false, isError: false } },
  selectPlanCreateType: 'NEW',
  currentSelectedPlan: null,
  currentSelectedPlanLoading: false,
  isCurrentSelectedPlanReviewed: false,
  isCurrentSelectedPlanMerged: false,
  isError: false,
  isLoading: false,
};

const addProposalToPlanYearSlice = createSlice({
  name: 'addProposalToPlanYear',
  initialState,
  reducers: {
    setBasicDetails: (
      state,
      { payload }: PayloadAction<IAddProposalToPlanYearState['basicDetails']>
    ) => {
      state.basicDetails = payload;
    },
    setPlans: (
      state,
      { payload }: PayloadAction<IAddProposalToPlanYearState['plans']>
    ) => {
      state.plans = payload;
    },
    setPlan: (
      state,
      { payload }: PayloadAction<DeepPartial<TransformedPlan>>
    ) => {
      const benefit = payload.benefit!;
      const idx = state.plans[benefit].findIndex((plan) =>
        benefit === 'TAX_ADVANTAGE_ACCOUNTS'
          ? plan.tempId === payload.tempId
          : plan.id === payload.id
      );
      state.plans[benefit][idx] = payload;
    },
    addBenefitClass: (state, { payload }: PayloadAction<string>) => {
      state.basicDetails.benefitClasses.data?.push({
        label: payload,
        value: '',
        hideSelect: false,
      });
    },
    removeBenefitClass: (state, { payload }: PayloadAction<number>) => {
      state.basicDetails.benefitClasses.data =
        state.basicDetails.benefitClasses.data?.filter(
          (_val, idx) => idx !== payload
        );
    },
    editBenefitClass: (
      state,
      { payload: { idx, name } }: PayloadAction<{ idx: number; name: string }>
    ) => {
      if (state.basicDetails.benefitClasses.data) {
        state.basicDetails.benefitClasses.data[idx].label = name;
      }
    },
    prepareForReview: (state) => {
      Object.values(state.plans).forEach((planSet) =>
        planSet.forEach((plan: any) => {
          const basicInfo: typeof plan['basicInfo'] = {
            ...plan.basicInfo,
            benefitClasses: [...(state.basicDetails.benefitClasses.data ?? [])],
          };
          plan.basicInfo = basicInfo;
        })
      );
    },
    clearData: () => initialState,
    clearCurrentSelectedPlan: (state) => {
      state.currentSelectedPlan = null;
    },
    removeDocumentReference: (state, { payload }: PayloadAction<any>) => {
      const documentReferences = {
        ...state.currentSelectedPlan?.documentReferences,
        [payload]: {
          documentUploadStatus: 'REMOVED',
        },
      };
      const documents = {
        ...state.currentSelectedPlan?.documents,
      };
      delete documents[payload];
      state.currentSelectedPlan = {
        ...state.currentSelectedPlan,
        documentReferences,
        documents,
      };
    },
    addCurrentSelectedToPlans: (
      state,
      {
        payload,
      }: PayloadAction<{
        plan: Plan;
        type: keyof IAddProposalToPlanYearState['plans'];
      }>
    ) => {
      state.plans[payload.type].forEach((el) => {
        if (el.id === payload.plan.id) {
          el.plan = payload.plan;
        }
      });
    },
    updateCurrentSelectedPlanByPath: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      if (state.currentSelectedPlan) {
        set(state.currentSelectedPlan, payload.path, payload.value);
      }
    },
    setSelectPlanCreateType: (
      state,
      {
        payload,
      }: PayloadAction<IAddProposalToPlanYearState['selectPlanCreateType']>
    ) => {
      state.selectPlanCreateType = payload;
    },
    updateCurrentSelectedPlanStarted: (state) => {
      state.currentSelectedPlanLoading = true;
    },
    updateCurrentSelectedPlan: (state, { payload }: PayloadAction<any>) => {
      const groups =
        state.basicDetails.benefitClasses.data?.map((item) => item.label) || [];

      const getHasSameContributions = () => {
        const payloadHasSameContributions = (payload as any)
          .hasSameContributions;
        if (payloadHasSameContributions !== undefined) {
          return payloadHasSameContributions as boolean;
        }

        const planHasSameContributions = (
          (state?.currentSelectedPlan ?? {}) as any
        ).hasSameContributions;
        if (planHasSameContributions !== undefined) {
          return planHasSameContributions as boolean;
        }

        return true;
      };

      state.currentSelectedPlan = {
        // To avoid breaking
        hasSameContributions: getHasSameContributions(),
        planYearId: '',
        states: payload.states ?? [],
        groups,
        ...state.currentSelectedPlan,
        ...payload,
      } as any;
      state.currentSelectedPlanLoading = false;
    },
    updateCurrentSelectedPlanFailed: (state) => {
      state.currentSelectedPlanLoading = false;
    },
    removeAllCurrentSelectedPlanDocuments: (state) => {
      state.currentSelectedPlan = {
        ...(state.currentSelectedPlan ?? {}),
        documents: {},
        documentReferences: {},
        file: undefined,
        fileName: '',
        documentAvailable: false,
      } as any;
    },
    saveCurrentPlan: (
      state,
      {
        payload,
      }: PayloadAction<{
        benefit: keyof IAddProposalToPlanYearState['plans'];
        id: string;
      }>
    ) => {
      state.plans[payload.benefit].forEach((item) => {
        if (
          payload.benefit === 'TAX_ADVANTAGE_ACCOUNTS' &&
          item.tempId === payload.id &&
          state.currentSelectedPlan
        ) {
          item.plan = cloneDeep(state.currentSelectedPlan);
          item.isReviewed = true;
          item.planName = state.currentSelectedPlan.name;
        } else {
          if (item.id === payload.id && state.currentSelectedPlan) {
            item.plan = cloneDeep(state.currentSelectedPlan);
            item.isReviewed = true;
            item.planName = state.currentSelectedPlan.name;
          }
        }
      });
    },
    setIsCurrentSelectedPlanReviewed: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCurrentSelectedPlanReviewed = payload;
    },
    setIsCurrentSelectedPlanMerged: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCurrentSelectedPlanMerged = payload;
    },
    setIsUsingMergedPlanData: (state, { payload }: PayloadAction<boolean>) => {
      state.isUsingMergePlanData = payload;
    },
    // For Async
    setProposalStarted: (state) => {
      state.proposalSummary.status.isLoading = true;
      state.proposalSummary.status.isError = false;
    },
    setProposalCompleted: (
      state,
      { payload }: PayloadAction<ProposalSummaryVO>
    ) => {
      state.proposalSummary.status.isError = false;
      state.proposalSummary.status.isLoading = false;
      state.proposalSummary.data = payload;
    },
    setProposalFailed: (state) => {
      state.proposalSummary.status.isLoading = false;
      state.proposalSummary.status.isError = true;
    },
    setBenefitClassesStarted: (state) => {
      state.basicDetails.benefitClasses.status.isLoading = true;
      state.basicDetails.benefitClasses.status.isError = false;
    },
    setBenefitClassesCompleted: (
      state,
      { payload }: PayloadAction<ISelectOption[]>
    ) => {
      state.basicDetails.benefitClasses.status.isError = false;
      state.basicDetails.benefitClasses.status.isLoading = false;
      state.basicDetails.benefitClasses.data = payload as any;
    },
    setBenefitClassesFailed: (state) => {
      state.basicDetails.benefitClasses.status.isLoading = false;
      state.basicDetails.benefitClasses.status.isError = true;
    },
    documentUploadStarted: (state, { payload }: PayloadAction<any>) => {
      if (state.currentSelectedPlan) {
        state.currentSelectedPlan.documentReferences = {
          ...state.currentSelectedPlan.documentReferences,
          [payload.documentType]: {
            uploading: true,
          },
        };
      }
    },
    documentUploadCompleted: (state, { payload }: PayloadAction<any>) => {
      if (state.currentSelectedPlan) {
        state.currentSelectedPlan.documentReferences = {
          ...state.currentSelectedPlan.documentReferences,
          [payload.documentType]: {
            uploading: false,
            reference: payload.reference,
            blobUrl: payload.blobUrl,
            fileName: payload.fileName,
          },
        };
        state.currentSelectedPlan.documents = {
          ...state.currentSelectedPlan.documents,
          [payload.documentType]: payload.fileName,
        };
      }
    },
    documentUploadFailed: (state, { payload }: PayloadAction<any>) => {
      if (state.currentSelectedPlan) {
        state.currentSelectedPlan.documentReferences = {
          ...state.currentSelectedPlan.documentReferences,
          [payload.documentType]: {
            uploading: false,
            error: cloneDeep(payload.error),
          },
        };
      }
    },
    additionalDocumentUploadStarted: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      if (state.currentSelectedPlan) {
        state.currentSelectedPlan.additionalDocumentReferences = {
          ...state.currentSelectedPlan.additionalDocumentReferences,
          [payload.documentType]: {
            uploading: true,
          },
        };
      }
    },
    additionalDocumentUploadCompleted: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      if (state.currentSelectedPlan) {
        state.currentSelectedPlan.additionalDocumentReferences = {
          ...state.currentSelectedPlan.additionalDocumentReferences,
          [payload.documentType]: {
            uploading: false,
            reference: payload.reference,
            blobUrl: payload.blobUrl,
            fileName: payload.fileName,
          },
        };
      }
    },
    additionalDocumentWebLinkUploadCompleted: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      if (state.currentSelectedPlan) {
        state.currentSelectedPlan.additionalWeblinks = {
          ...state.currentSelectedPlan.additionalWeblinks,
          [payload.documentType]: payload.webLink,
        };
      }
    },
    additionalDocumentDeleted: (state, { payload }: PayloadAction<any>) => {
      if (state.currentSelectedPlan) {
        const copy = cloneDeep(
          state.currentSelectedPlan.additionalDocumentReferences
        );
        delete copy[payload.documentType];

        state.currentSelectedPlan.additionalDocumentReferences = copy;
      }
    },
    additionalDocumentWebLinkDeleted: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      if (state.currentSelectedPlan) {
        const copy = cloneDeep(state.currentSelectedPlan.additionalWeblinks);
        delete copy[payload.documentType];

        state.currentSelectedPlan.additionalWeblinks = copy;
      }
    },
    getMergablePlansStarted: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    getMergablePlansCompleted: (
      state,
      {
        payload,
      }: PayloadAction<{
        [id: string]: {
          planId?: string | undefined;
          planName?: string | undefined;
          benefitKind?: string | undefined;
        }[];
      }>
    ) => {
      Object.values(state.plans).forEach((planSet) =>
        planSet.forEach((plan) => {
          plan.mergablePlans = (payload[plan?.id!] ?? []).map((item) => ({
            label: item.planName,
            value: item.planId,
            benefitKind: item.benefitKind,
          }));
          if (plan.planType === 'HRA' || plan.planType === 'HSA') {
            const mergableHraPlanId = plan.mergablePlans?.filter(
              (item: any) => item.benefitKind === 'HRA'
            )?.[0]?.value;

            const mergableHsaPlanId = plan.mergablePlans?.filter(
              (item: any) => item.benefitKind === 'HSA'
            )?.[0]?.value;
            plan.mergedPlanId =
              plan.planType === 'HRA' ? mergableHraPlanId : mergableHsaPlanId;
            plan.isMerged = true;
          } else if (plan.mergablePlans?.[0]) {
            plan.mergedPlanId = plan.mergablePlans?.[0]?.value;
            plan.isMerged = true;
          }
        })
      );
      state.isLoading = false;
      state.isError = false;
    },
    getMergablePlansFailed: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
  },
});

export const {
  clearData,
  addBenefitClass,
  setBasicDetails,
  editBenefitClass,
  setPlans,
  setPlan,
  prepareForReview,
  removeBenefitClass,
  setSelectPlanCreateType,
  updateCurrentSelectedPlan,
  updateCurrentSelectedPlanStarted,
  updateCurrentSelectedPlanFailed,
  clearCurrentSelectedPlan,
  addCurrentSelectedToPlans,
  updateCurrentSelectedPlanByPath,
  setIsCurrentSelectedPlanReviewed,
  setIsCurrentSelectedPlanMerged,
  setIsUsingMergedPlanData,
  saveCurrentPlan,
  removeDocumentReference,
  removeAllCurrentSelectedPlanDocuments,
} = addProposalToPlanYearSlice.actions;

export const fetchProposalSummary =
  (args: { proposalId: string; employerId: string }) =>
  async (dispatch: Dispatch) => {
    dispatch(addProposalToPlanYearSlice.actions.setProposalStarted());
    try {
      const { data } = await ProposalService.fetchProposalSummary(args);
      dispatch(addProposalToPlanYearSlice.actions.setProposalCompleted(data));
    } catch (error) {
      dispatch(addProposalToPlanYearSlice.actions.setProposalFailed());
    }
  };

export const fetchBenefitClasses =
  ({ employerId }: { employerId: string }) =>
  async (dispatch: Dispatch) => {
    dispatch(addProposalToPlanYearSlice.actions.setBenefitClassesStarted());
    try {
      const benefitClasses: ISelectOption[] = [];
      const { data } = await ProposalService.fetchPlanYears({ employerId });

      // Find the current plan year's benefit classes
      const currentPlanYearId = data?.find((planYear) => planYear.current)?.id;
      data
        .find((planYear) => planYear.current)
        ?.benefitClasses?.forEach(({ id, name }) => {
          benefitClasses.push({
            label: name ?? '',
            value: id ?? '',
            hideSelect: true,
            planYearId: currentPlanYearId,
          });
        });

      dispatch(
        addProposalToPlanYearSlice.actions.setBenefitClassesCompleted(
          benefitClasses
        )
      );
    } catch (error) {
      dispatch(addProposalToPlanYearSlice.actions.setBenefitClassesFailed());
    }
  };

export const getCurrentPlan =
  (
    benefitCategory: keyof IAddProposalToPlanYearState['plans'],
    planId: string,
    rates: any,
    mergedPlanId?: string,
    tempId?: string
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(updateCurrentSelectedPlanStarted());
    let savedPlan;
    try {
      if (benefitCategory === 'TAX_ADVANTAGE_ACCOUNTS') {
        savedPlan = getState().renewals.addProposalToPlanYear.plans[
          benefitCategory
        ].find((item) => item.tempId == tempId);
      } else {
        savedPlan = getState().renewals.addProposalToPlanYear.plans[
          benefitCategory
        ].find((item) => item.id == planId);
      }

      const getBenefitClasses =
        getState().renewals.addProposalToPlanYear.basicDetails.benefitClasses.data?.map(
          (item) => item.label
        );

      const planContributionId = savedPlan?.planContributionId;
      let plan = savedPlan?.plan;
      if (plan === undefined) {
        let response;
        if (benefitCategory === 'TAX_ADVANTAGE_ACCOUNTS') {
          response = await ProposalService.fetchTaxAdvantagePlans(
            mergedPlanId !== undefined ? mergedPlanId : planId
          );
        } else {
          response = await ProposalService.fetchFinalApproveReviewPlanDetail({
            benefitCategory: [
              'LIFE_AND_DISABILITY',
              'VOLUNTARY_BENEFIT',
            ].includes(benefitCategory)
              ? 'insurances'
              : benefitCategory.toLowerCase() + 's',
            planId,
            rates,
            mergedPlanId,
            planContributionId,
          });
        }

        plan = {
          ...response.data,
          name: savedPlan?.planName,
          groups: mergedPlanId ? response.data.groups : getBenefitClasses,
        };
        if (benefitCategory === 'MEDICAL') {
          plan.initialPlanId = savedPlan?.initialPlanId;
          plan.hraPlanId = savedPlan?.hraPlanId;
        }
        if (benefitCategory === 'MEDICAL' && !mergedPlanId) {
          const states = await PlanService.getStatesAndTerritories();
          plan.states = states.data;
          plan.initialPlanId = savedPlan?.initialPlanId;
        }
        if (benefitCategory === 'DENTAL' || benefitCategory === 'VISION') {
          plan.initialPlanId = savedPlan?.initialPlanId;
          plan.hraPlanId = savedPlan?.hraPlanId;
        }
        if (benefitCategory === 'TAX_ADVANTAGE_ACCOUNTS') {
          plan.parentId = savedPlan?.parentId;
          plan.category = savedPlan?.category;
          plan.parentPlan = savedPlan?.parentPlan;
          plan.tierCount = savedPlan?.tierCount;
          plan.hraUtilization = savedPlan?.hraUtilization;
          plan = setEmployerContributions(mergedPlanId, plan, savedPlan);
          plan.initialPlanId = savedPlan?.initialPlanId;
          plan.documents = {};
          if (
            response &&
            response?.data &&
            response?.data?.employerContribution
          ) {
            plan.employerContribution = response?.data?.employerContribution;
          }
        }
        if (benefitCategory === 'LIFE_AND_DISABILITY') {
          plan.volume = savedPlan?.volume;
        }
      }
      dispatch(setIsCurrentSelectedPlanReviewed(!!savedPlan?.isReviewed));
      dispatch(setIsCurrentSelectedPlanMerged(!!savedPlan?.isMerged));
      dispatch(updateCurrentSelectedPlan(plan as Plan));
    } catch (error) {
      console.error(error);
      dispatch(updateCurrentSelectedPlanFailed());
    }
  };

const setEmployerContributions = (
  mergedPlanId: any,
  plan: DeepPartial<Plan>,
  savedPlan: any
): DeepPartial<Plan> => {
  if (mergedPlanId && plan?.employerContribution) {
    plan.employerContribution.empContribution =
      savedPlan.employerContribution?.empContribution;
    plan.employerContribution.employerECContribution =
      savedPlan.employerContribution?.employerECContribution;
    plan.employerContribution.employerEEContribution =
      savedPlan.employerContribution?.employerEEContribution;
    plan.employerContribution.employerEFContribution =
      savedPlan.employerContribution?.employerEFContribution;
    plan.employerContribution.employerESContribution =
      savedPlan.employerContribution?.employerESContribution;
    plan.employerContribution.employerEE1Contribution =
      savedPlan.employerContribution?.employerEE1Contribution;
    plan.employerContribution.employerEE2Contribution =
      savedPlan.employerContribution?.employerEE2Contribution;
    plan.employerContribution.employerEE3Contribution =
      savedPlan.employerContribution?.employerEE3Contribution;
    plan.employerContribution.employerEE4Contribution =
      savedPlan.employerContribution?.employerEE4Contribution;
    plan.employerContribution.employerEE5Contribution =
      savedPlan.employerContribution?.employerEE5Contribution;
    plan.employerContribution.employerEE6Contribution =
      savedPlan.employerContribution?.employerEE6Contribution;
    plan.employerContribution.empContribution;
    return plan;
  } else {
    plan.employerContribution = savedPlan?.employerContribution;
    return plan;
  }
};

export const uploadTemporaryDocument = (
  file: File,
  documentType: string,
  newPlan?: any
) => {
  return async (dispatch: Dispatch) => {
    const {
      documentUploadCompleted,
      documentUploadFailed,
      documentUploadStarted,
    } = addProposalToPlanYearSlice.actions;

    // to save the latest formdata
    if (newPlan) {
      dispatch(updateCurrentSelectedPlan(newPlan));
    }

    dispatch(documentUploadStarted({ documentType }));
    ProposalService.uploadPlanDocuments(file.name, file)
      .then(({ data }: AxiosResponse) => {
        dispatch(
          documentUploadCompleted({
            documentType,
            reference: data,
            fileName: file.name,
            blobUrl: URL.createObjectURL(file),
          })
        );
      })
      .catch((error: AxiosError) => {
        dispatch(documentUploadFailed({ documentType, error: error }));
      });
  };
};

export const uploadTemporaryAdditionalDocument = (
  file: File,
  documentType: string,
  newPlan?: any,
  isFile?: boolean,
  webLink?: string
) => {
  return async (dispatch: Dispatch) => {
    const {
      additionalDocumentUploadStarted,
      additionalDocumentUploadCompleted,
      additionalDocumentWebLinkUploadCompleted,
    } = addProposalToPlanYearSlice.actions;

    // to save the latest formdata
    if (newPlan) {
      dispatch(updateCurrentSelectedPlan(newPlan));
    }

    if (!isFile) {
      dispatch(
        additionalDocumentWebLinkUploadCompleted({
          documentType,
          webLink: webLink ?? '',
        })
      );
    } else {
      dispatch(additionalDocumentUploadStarted({ documentType }));
      return await ProposalService.uploadPlanDocuments(file.name, file)
        .then(({ data }: AxiosResponse) => {
          dispatch(
            additionalDocumentUploadCompleted({
              documentType,
              reference: data,
              fileName: file.name,
              blobUrl: URL.createObjectURL(file),
            })
          );
        })
        .catch((error: AxiosError) => {
          console.error('Error while uploading the additional file', error);
        });
    }
  };
};

export const deleteAdditionalDocument = (
  documentType: string,
  isFile: boolean
) => {
  return async (dispatch: Dispatch) => {
    const { additionalDocumentDeleted, additionalDocumentWebLinkDeleted } =
      addProposalToPlanYearSlice.actions;

    if (!isFile) {
      dispatch(
        additionalDocumentWebLinkDeleted({
          documentType,
        })
      );
    } else {
      dispatch(
        additionalDocumentDeleted({
          documentType,
        })
      );
    }
  };
};

let abortController: AbortController;
let changedTier: string;
export const getPlanContributionByFrequencyReview = (
  contributions: any,
  frequency: string,
  benefitGroup: string,
  rateTier: string,
  currentTier: string
) => {
  if (abortController && changedTier === rateTier) {
    abortController.abort(); // Tell the browser to abort request
  }
  if (typeof 'AbortController' !== 'undefined') {
    abortController = new AbortController();
  }
  changedTier = rateTier;
  return async (dispatch: Dispatch) => {
    try {
      const response = await PlanService.calculateMedicalPlanContributions(
        frequency,
        contributions,
        benefitGroup,
        rateTier,
        currentTier,
        abortController.signal
      );
      const data = response.data;
      dispatch(updateCurrentSelectedPlan(data));
    } catch (error: any) {
      console.error(error);
    }
  };
};
export const updateMedicalPlanByPathReview = (
  path: string[],
  value: any,
  isDBGView: boolean = false
) => {
  return async (dispatch: Dispatch) => {
    // TODO: Need to refactor class MedicalPlan to type MedicalPlan
    dispatch(
      updateCurrentSelectedPlanByPath(cloneDeep({ path, value, isDBGView }))
    );
  };
};

export const prepareForMerge =
  ({
    employerId,
  }: {
    employerId: string;
  }): ThunkAction<void, RootState, any, any> =>
  async (dispatch, getState) => {
    const {
      getMergablePlansCompleted,
      getMergablePlansFailed,
      getMergablePlansStarted,
    } = addProposalToPlanYearSlice.actions;

    try {
      dispatch(getMergablePlansStarted());
      const plans = getState().renewals.addProposalToPlanYear.plans;
      const planYearIds: any =
        getState().renewals.addProposalToPlanYear.basicDetails.benefitClasses
          .data;
      const payload: {
        [id: string]: {
          planId?: string | undefined;
          planName?: string | undefined;
        }[];
      } = {};

      const planYearId = planYearIds?.[0]?.planYearId;
      const { data: responseHSA } =
        await ProposalService.fetchTaxAdMergablePlans({
          employerId,
          planYearId,
          benefitKind: 'HSA',
        });

      const { data: responseHRA } =
        await ProposalService.fetchTaxAdMergablePlans({
          employerId,
          planYearId,
          benefitKind: 'HRA',
        });
      const allRequests = Object.entries(plans)
        ?.map(([benefit, planSet]) =>
          planSet.map(async (plan) => {
            if (plan.benefit === 'TAX_ADVANTAGE_ACCOUNTS') {
              payload[plan.id!] = [...responseHRA, ...responseHSA]?.map(
                (plan) => ({
                  planId: plan?.id,
                  planName: plan?.name,
                  benefitKind: plan?.benefitKind,
                })
              );
            } else {
              const response = await ProposalService.fetchMergablePlans({
                benefit:
                  BENEFIT_RESOURCE_PATH_MAP[
                    benefit as keyof IAddProposalToPlanYearState['plans']
                  ]!,
                planId: plan?.id!,
                employerId,
              });
              payload[plan?.id!] = response.data;
            }
          })
        )
        .flat(1);
      await Promise.allSettled(allRequests);
      dispatch(getMergablePlansCompleted(payload));
    } catch (error) {
      dispatch(getMergablePlansFailed());
    }
  };

export default addProposalToPlanYearSlice.reducer;
