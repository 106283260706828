import { FC, useState, useMemo } from 'react';

import { Col, Row, Menu } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import iconInfo from 'assets/images/icon-info.svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ERAdmin } from 'model/DraftEmployer';
import { buildFullName } from 'util/stringUtil';

import InfoBanner from 'components/InfoBanner/InfoBanner';
import Expandable from 'components/Expandable/Expandable';
import DataTable from 'components/DataTable/DataTable';
import NextButton from 'components/buttons/NextButton/NextButton';
import { DataColumn } from 'components/DataTable/DataColumn';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import AddERAdminForm from 'modules/employers/components/ERAdmins/AddERAdminForm';
import {
  addErAdmin,
  removeErAdmin,
} from 'modules/employers/slices/employerCreateStepperSlice';
import { adminNameSorter, getLabelFromValue } from 'util/commonUtil';

import styles from './addERAdmins.module.less';

type AddERAdminProps = {
  nextStep: () => void;
};

const AddERAdmins: FC<AddERAdminProps> = (props: AddERAdminProps) => {
  const { nextStep } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);
  const [toBeDeleted, setToBeDeleted] = useState({ id: '', name: '' });
  const [editBrokerId, setEditBrokerId] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const onBoardingEmployer = useAppSelector(
    (state) => state.employer.employerCreateStepper.employer
  );

  const erAdminList = useMemo(() => {
    return onBoardingEmployer.erAdmins ? onBoardingEmployer.erAdmins : [];
  }, [onBoardingEmployer.erAdmins]);

  const setHeader = (listSize: number) => {
    if (listSize === 0) {
      return '+ Add Employer User';
    } else {
      return '+ Add Another Employer User';
    }
  };

  const convertERAdminsForTableRender = (admins: ERAdmin[]): any[] => {
    if (admins != null) {
      return admins.map((item) => ({
        key: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        title: item.title,
        email: item.email,
        isPrimaryContact: item.primary,
        actions: item,
        individualSubType: item.individualSubType,
      }));
    }
    return [];
  };

  const onClickDelete = (record: ERAdmin) => {
    setToBeDeleted({
      id: record.id,
      name: buildFullName(record.firstName, record.lastName),
    });
    setVisibleConfirmation(true);
  };

  const handleEditAdmin = (id: string) => {
    setEditBrokerId(id);
    setExpanded(true);
  };

  const handleMakePrimary = (admin: ERAdmin) => {
    dispatch(addErAdmin({ ...admin, primary: true }));
    setAlertMessage({
      message: `${buildFullName(
        admin.firstName,
        admin.lastName
      )} is now the primary contact`,
      type: 'success',
    });
    setVisibleAlert(true);
  };

  const adminActionMenu = (colData: ERAdmin) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => handleEditAdmin(colData.id)}>
        Edit Info
      </Menu.Item>
      <Menu.Item key="remove" onClick={() => onClickDelete(colData)}>
        Delete User
      </Menu.Item>
      {!colData.primary && (
        <Menu.Item
          key="make-primary"
          onClick={() => handleMakePrimary(colData)}
        >
          Make Primary Contact
        </Menu.Item>
      )}
    </Menu>
  );

  const closeAlert = () => setVisibleAlert(false);
  const closeModal = () => setVisibleConfirmation(false);
  const okDelete = () => {
    setVisibleConfirmation(false);
    dispatch(removeErAdmin(toBeDeleted.id));
    setVisibleAlert(true);
    setAlertMessage({
      type: 'success',
      message: 'Employer User has been removed.',
    });
  };

  const handleNextClick = () => {
    nextStep();
  };

  const columns: DataColumn[] = [
    {
      title: 'EMPLOYER USER',
      dataIndex: '',
      key: 'name',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => adminNameSorter(a, b),
      render: (admin) => renderERAdminCell(admin),
    },
    {
      title: 'USER TYPE',
      dataIndex: 'individualSubType',
      key: 'individualSubType',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      render: (individualSubType: any) => {
        return (
          <>
            {console.log(individualSubType)}
            {getLabelFromValue(individualSubType)}
          </>
        );
      },
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      render: (title) => <OverflowPopover>{title}</OverflowPopover>,
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (email: string) => (
        <OverflowPopover>{email?.toLowerCase()}</OverflowPopover>
      ),
    },
    {
      title: 'PRIMARY CONTACT',
      dataIndex: 'isPrimaryContact',
      key: 'primaryContact',
      width: '20%',
      className: styles.primaryContactCol,
      render: (colData) => (colData ? <CheckOutlined /> : <></>),
      align: 'center',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      render: (colData) => (
        <SelectDropdown overlay={adminActionMenu(colData)} />
      ),
      align: 'center',
    },
  ];

  return (
    <div className={styles.addAdminsContainer}>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <InfoBanner
            title="Adding Employer Users"
            description="Employer Users are your contacts at this company who may eventually have access to the system. Employer Users will not be able to access the PlanYear platform until you send them an invitation. You can send invitations after setting up this employer."
            logo={<img src={iconInfo} alt="info-icon" />}
          />
        </Col>
      </Row>
      {visibleAlert && (
        <AlertMessage
          type={alertMessage.type}
          message={alertMessage.message}
          closeAlert={closeAlert}
        />
      )}
      {visibleConfirmation && (
        <ConfirmationDialog
          title="Remove Employer User"
          confirmText={`Yes - Remove ${toBeDeleted.name}`}
          cancelText="Cancel"
          closeModal={closeModal}
          visible={visibleConfirmation}
          onConfirm={okDelete}
        >
          <p className="text-confirmation-content">
            Are you sure you want to remove this Employer User? They will no
            longer be able to access the system; this action cannot be un-done.
          </p>
        </ConfirmationDialog>
      )}
      <DataTable
        data={convertERAdminsForTableRender(erAdminList)}
        columns={columns}
      />
      <Expandable
        header={setHeader(erAdminList.length)}
        isExpanded={expanded}
        onChange={(expanded) => setExpanded(expanded)}
      >
        <AddERAdminForm
          expanded={expanded}
          onClose={() => {
            setEditBrokerId(null);
            setExpanded(false);
          }}
          editBrokerId={editBrokerId}
        />
      </Expandable>
      {!expanded && <NextButton nextStep={handleNextClick} />}
    </div>
  );
};

export default AddERAdmins;

const renderERAdminCell = (admin: ERAdmin) => {
  return (
    <OverflowPopover>
      {buildFullName(admin.firstName, admin.lastName)}
    </OverflowPopover>
  );
};
