import { notification } from 'antd';
import { ArgsProps, IconType } from 'antd/lib/notification';
import { CloseOutlined } from '@ant-design/icons';
import IconError from 'assets/images/alert-failure.svg';
import IconCheck from 'assets/images/checkmark.svg';
import styles from './notification.module.less';

export const showSuccessNotification = (args: ArgsProps) => {
  notification.open({
    ...{
      placement: 'topRight',
      duration: 3,
      closeIcon: <></>,
      icon: <img className={styles.checkmark} alt="" src={IconCheck} />,
    },
    ...args,
    className: `${styles.successNotification} ${args.className}`,
  });
};

export const showErrorNotification = (args: ArgsProps) => {
  notification.open({
    ...{
      placement: 'topRight',
      duration: 3,
      closeIcon: <></>,
      // icon: <img className={styles.checkmark} alt="" src={checkmark} />,
    },
    ...args,
    className: `${styles.successNotification} ${args.className}`,
  });
};

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const openNotificationWithIcon = (
  type: NotificationType,
  description: React.ReactFragment | string,
  message: string,
  top?: number,
  icon?: React.ReactNode
) => {
  notification[type]({
    closeIcon: <></>,
    top: top ?? 40,
    message: message,
    description: description,
    icon: icon ?? <CloseOutlined className={styles.notificationErrorIcon} />,
  });
};

const getIconSrc = (type: IconType) => {
  switch (type) {
    case 'error':
      return IconError;
    case 'success':
    default:
      return IconCheck;
  }
};

export const showNotification = ({ type, ...args }: ArgsProps) => {
  notification.open({
    placement: 'topRight',
    duration: 3,
    closeIcon: <></>,
    className: `${styles.notification} ${args.className}`,
    ...(type
      ? {
          icon: (
            <img
              className={styles.notificationIcon}
              alt=""
              src={getIconSrc(type)}
            />
          ),
        }
      : {}),
    ...args,
  });
};
