import { FC, MutableRefObject, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { VisionPlan } from 'model/plans/VisionPlan';
import Services from 'modules/plans/components/DVServices/Services';
import {
  getVisionPlanServicesList,
  updateVisionPlan,
} from 'modules/plans/slices/visionPlanSlice';
import { BenefitCategory, FEATURE_KEYS } from 'constants/commonConstants';
import { useNavContext } from 'hooks/useNavContext';
import AddCustomServiceModal from 'modules/plans/components/AddCustomServiceModal/AddCustomServiceModal';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import PanelSection from 'modules/plans/enums/PanelSection';
import iconAdd from 'assets/images/icon-add.svg';
import PlanServiceObject from 'model/PlanService';
import useGetUserFeatureStatus from 'hooks/useGetUserFeatureStatus';

import styles from './services.module.less';

type ServiceDBGWrapperProps = {
  type: string;
  wrappedRef?: MutableRefObject<any>;
  visionPlan: VisionPlan;
};

const ServiceDBGWrapper: FC<ServiceDBGWrapperProps> = (
  props: ServiceDBGWrapperProps
) => {
  const { type, visionPlan } = props;
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();
  const [isAddCustomServiceModalOpen, setIsAddCustomServiceModalOpen] =
    useState<boolean>(false);
  const plan = useAppSelector((state) => state.plan.visionPlan);
  const { displayedVisionServices: _displayedVisionServices } = plan;
  const displayedVisionServices =
    _displayedVisionServices as PlanServiceObject[];

  useEffect(() => {
    dispatch(updateVisionPlan(visionPlan));
    dispatch(
      getVisionPlanServicesList(BenefitCategory.VISION.value, employerId || '')
    );
  }, [dispatch, visionPlan, employerId]);

  const onChange = (visionPlan: VisionPlan) => {
    dispatch(updateVisionPlan(visionPlan));
  };

  const isAdvancedProfile = useGetUserFeatureStatus(
    brokerId,
    employerId,
    FEATURE_KEYS.ADVANCED_PROFILE,
    false
  );

  return (
    <>
      <div className={styles.visionServiceDBGWrapper}>
        <Services
          plan={visionPlan}
          onChange={onChange}
          planType={BenefitCategory.VISION.value}
          isDBGView={true}
          isTouched={false}
          isAdvancedEmployerProfile={isAdvancedProfile}
        />
        <AddCustomServiceModal
          planTypeName={BenefitCategory.VISION.label}
          benefitKind={BenefitCategory.VISION.value}
          visible={isAddCustomServiceModalOpen}
          displayedServices={displayedVisionServices}
          onClose={() => setIsAddCustomServiceModalOpen(false)}
          hsaCompatible={false}
          addedCustomServices={visionPlan.customServices}
          isAdvancedProfileEmployer={isAdvancedProfile}
        />
      </div>
      {PanelSection.SERVICES === type && (
        <div className={styles.addNewServiceBtn}>
          <LinkButton
            onClick={() => {
              setIsAddCustomServiceModalOpen(true);
            }}
          >
            <img src={iconAdd} alt="add-icon" className={styles.iconStyle} />
            Add Service
          </LinkButton>
        </div>
      )}
    </>
  );
};

export default ServiceDBGWrapper;
