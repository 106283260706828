import { useEffect, useState } from 'react';
import { Input, Form, Row } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import { isEmpty } from 'lodash';

import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import AuthContainer from 'containers/AuthContainer/AuthContainer';
import InputForm from 'components/InputForm/InputForm';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import AuthHeading from 'modules/auth/components/AuthHeading/AuthHeading';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  login,
  resetLoginState,
  sessionExpired,
} from 'modules/auth/slices/authSlice';
import {
  LOGIN_ERROR_MESSAGES,
  LOGIN_TYPE_PWD,
  LOGIN_TYPE_SSO,
  REGISTER_SUCCESS_MESSAGE,
  RESET_PWD_SUCCESS_MESSAGE,
} from 'modules/auth/constants/authConstants';
import { useGetLoginTypeMutation } from 'modules/auth/slices/loginSlice';
import { trackEvents } from 'api/initGA4';

import styles from './login.module.less';

const Login = () => {
  const dispatch = useAppDispatch();
  const { auth } = useAppSelector((state) => state.auth);
  const { error, inProgress } = auth;
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    type: 'error',
    message: '',
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const locationState: any = location.state;

  const [
    getLoginType,
    { isLoading: loginTypeIsLoading, error: loginTypeError },
  ] = useGetLoginTypeMutation({});

  // display alert if navigated after registration
  useEffect(() => {
    const timer = setTimeout(() => {
      if (locationState && locationState.isRegister) {
        setIsAlertVisible(false);
      }
    }, 15000);

    if (locationState) {
      if (locationState.isRegister) {
        setIsAlertVisible(true);
        setAlertInfo({
          type: 'success',
          message: REGISTER_SUCCESS_MESSAGE,
        });
      }
      if (locationState.isPasswordReset) {
        setIsAlertVisible(true);
        setAlertInfo({
          type: 'success',
          message: RESET_PWD_SUCCESS_MESSAGE,
        });
      }
    }
    return () => {
      clearTimeout(timer);
    };
  }, [locationState]);

  useEffect(() => {
    if (!inProgress && error) {
      const errorDescription = get(error, 'error_description');
      setAlertInfo({
        type: 'error',
        message: errorDescription
          ? LOGIN_ERROR_MESSAGES[errorDescription]
          : 'There was an error in the server, please try again later',
      });
      setIsAlertVisible(true);
    }
    return () => {
      dispatch(resetLoginState());
    };
  }, [inProgress, error, dispatch]);

  useEffect(() => {
    if (!loginTypeIsLoading && loginTypeError) {
      const errorDescription = get(loginTypeError, 'data.code');
      setAlertInfo({
        type: 'error',
        message: errorDescription
          ? LOGIN_ERROR_MESSAGES[errorDescription]
          : 'There was an error in the server, please try again later',
      });
      setIsAlertVisible(true);
    }
  }, [loginTypeError, dispatch, loginTypeIsLoading]);

  useEffect(() => {
    if (auth.sessionExpired) {
      setAlertInfo({
        type: 'info',
        message:
          'Your session timed out due to inactivity. Please log in again.',
      });
      setIsAlertVisible(true);
    }
  }, [auth.sessionExpired]);

  const loginHandler = () => {
    if (showPassword) {
      dispatch(
        login(credentials.email, credentials.password, () => {
          if (locationState?.from) {
            navigate('/', {
              state: {
                from: locationState.from,
              },
              replace: true,
            });
            return;
          }
          trackEvents({
            category: 'User',
            action: 'Login',
            label: 'login',
            value: 'email',
          });
          navigate('/');
        })
      );
    } else if (!isEmpty(credentials?.email) && credentials?.email !== null) {
      getLoginType({ username: credentials.email })
        .unwrap()
        .then((response) => {
          if (response?.type === LOGIN_TYPE_PWD) {
            setShowPassword(true);
          } else if (response?.type === LOGIN_TYPE_SSO) {
            if (response?.redirectUri !== null) {
              window.location.href = response?.redirectUri;
            }
          }
        });
    } else if (isEmpty(credentials?.email) || credentials?.email === null) {
      setAlertInfo({
        type: 'error',
        message: LOGIN_ERROR_MESSAGES.EMPTY_EMAIL,
      });
      setIsAlertVisible(true);
    }
  };

  const textChangeHandler = (event: any) => {
    const { value, name }: { [key: string]: string } = event.target;

    if (name === 'email') {
      setShowPassword(false);
    }

    setCredentials({ ...credentials, [name]: value.trim() });
    setIsAlertVisible(false);
    dispatch(sessionExpired(false));
  };
  return (
    <AuthContainer>
      <div className={styles.formContainer}>
        {isAlertVisible && (
          <AlertMessage
            wrapperClassName="authAlertWrapper"
            type={alertInfo.type}
            message={alertInfo.message}
            closeAlert={() => setIsAlertVisible(false)}
          />
        )}
        <div>
          <AuthHeading />
        </div>
        <div className={styles.adminOnlyMessage}>
          This login is for PlanYear administrators and brokers only.
        </div>
        <div className={styles.form}>
          <InputForm layout="vertical" onFinish={loginHandler}>
            <Form.Item
              name="email"
              label={<>Email</>}
              validateStatus={
                isAlertVisible && alertInfo.type === 'error'
                  ? 'error'
                  : 'validating'
              }
            >
              <Input name="email" onChange={textChangeHandler} autoFocus />
            </Form.Item>
            {showPassword && (
              <Form.Item
                name="password"
                label={<>Password</>}
                validateStatus={
                  isAlertVisible && alertInfo.type === 'error'
                    ? 'error'
                    : 'validating'
                }
              >
                <Input
                  name="password"
                  type="password"
                  autoComplete="off"
                  onChange={textChangeHandler}
                />
              </Form.Item>
            )}
            <SubmitButton
              type="primary"
              htmlType="submit"
              loading={inProgress || loginTypeIsLoading}
              className={styles.submitPwdButton}
            >
              {showPassword ? 'Log In' : 'Continue'}
            </SubmitButton>
            <Row justify="space-between">
              <div className={styles.registerWithCodeWrapper}>
                <LinkButton className={styles.registerWithCodeButton}>
                  <Link to="/register-with-code">
                    Register with Invite Code
                  </Link>
                </LinkButton>
              </div>
              {showPassword && (
                <div className={styles.resetPwdWrapper}>
                  <LinkButton className={styles.resetPwdButton}>
                    <Link to="/forgot-password">Reset Password</Link>
                  </LinkButton>
                </div>
              )}
            </Row>
          </InputForm>
        </div>
      </div>
    </AuthContainer>
  );
};

export default Login;
