import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { Table } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import { DataTableProps } from 'components/DataTable/DataTable';
import {
  manageTeamMemberOrder,
  setTeamMemberSortFlag,
} from 'modules/employers/slices/employerSlice';

import styles from './draggableDataTable.module.less';

interface DraggableDataTableProps extends DataTableProps {
  data: any[];
  columns: any[];
}

type SortEndProps = {
  oldIndex: number;
  newIndex: number;
};

const DraggableDataTable: FC<DraggableDataTableProps> = (
  props: DraggableDataTableProps
) => {
  const { data, columns } = props;
  const [dataSource, setDataSource] = useState<any[]>(data);
  const [isPreservedOrder, setIsPreservedOrder] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data && !isPreservedOrder) {
      setDataSource(data);
    }
  }, [data, isPreservedOrder]);

  useEffect(() => {
    if (data !== dataSource) {
      setIsPreservedOrder(false);
    }
  }, [data, dataSource]);

  // Need to start from capital since this is used as a component
  // eslint-disable-next-line
  const SortableItem = SortableElement((props: any) => <tr {...props} />);
  // Need to start from capital since this is used as a component
  // eslint-disable-next-line
  const SortableBody = SortableContainer((props: any) => <tbody {...props} />);
  const onSortEnd = ({ oldIndex, newIndex }: SortEndProps) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        ([] as any[]).concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el: any) => !!el);
      setDataSource(newData);
      setIsPreservedOrder(true);
      dispatch(manageTeamMemberOrder(newData.map((item) => item.key)));
      dispatch(setTeamMemberSortFlag(true));
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="rowDragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <div className={styles.draggableTableContainer}>
      <Table
        showHeader={false}
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </div>
  );
};

export default DraggableDataTable;
