import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Form, Input, notification } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FORMAT_VALIDATE, isRichTextEditorEmpty } from 'util/commonUtil';
import { getFileUploadErrorMessages } from 'util/fileUtil';
import { useNavContext } from 'hooks/useNavContext';
import { getErAdminsByEmployerId } from 'modules/admins/slices/erAdminSlice';
import { useGetDashboardBrokerQuery } from 'modules/brokers/slices/dashboardBrokerSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import InputEmailChip from 'components/InputEmailChip/InputEmailChip';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import InputFileUpload from 'components/InputFileUpload/InputFileUpload';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';

import { issueAllowedFileTypes } from 'modules/renewals/utils/soldConfirmationUtil';
import { EmailInputStateProps } from 'modules/renewals/components/soldConfirmation/SoldConfirmationModal/SoldConfirmationModal';
import styles from './employerSoldConfirmationMailStep.module.less';

type Props = {
  emailBodyValue: any;
  toEmailInputState: any;
  ccEmailInputState: any;
  handleGetToEmailList: ({
    emailList,
    isValidList,
    isEmptyList,
    defaultMailList,
  }: Partial<EmailInputStateProps>) => void;
  handleGetCCEmailList: ({
    emailList,
    isValidList,
    isEmptyList,
    defaultMailList,
  }: Partial<EmailInputStateProps>) => void;
  isEmailBodyEmpty: boolean;
  setIsEmailBodyEmpty: (value: boolean) => void;
  loggedUser: string;
  handleFileUpload: (fileList: any) => void;
  issueLogEmail: string;
  defaultFileList?: File[];
  form: any;
};
type NotificationType = 'success' | 'info' | 'warning' | 'error';
const EmployerSoldConfirmationMailStep = (props: Props) => {
  const {
    emailBodyValue,
    toEmailInputState,
    ccEmailInputState,
    handleGetToEmailList,
    handleGetCCEmailList,
    isEmailBodyEmpty,
    setIsEmailBodyEmpty,
    loggedUser,
    handleFileUpload,
    issueLogEmail,
    defaultFileList = [],
    form,
  } = props;

  const { employerId } = useNavContext();

  const { brokerId } = useParams();

  const dispatch = useAppDispatch();
  const erAdmins = useAppSelector(
    (state) => state.admins.erAdmins.erAdminsForEmployer
  );
  const isErAdminsLoading = useAppSelector(
    (state) => state.admins.erAdmins.inProgress
  );
  const [showAlertMessage, setShowAlertMessage] = useState<boolean>(false);

  const { data: brokerDashboard } = useGetDashboardBrokerQuery({
    brokerId: brokerId ?? '',
  });

  useEffect(() => {
    if (employerId) {
      dispatch(getErAdminsByEmployerId(employerId));
    }
  }, [dispatch, employerId]);

  const toEmailData = erAdmins?.map((item: any) => ({
    label: `${item?.firstName} ${item?.lastName}`,
    value: item?.email,
  }));

  // Notification Error Message
  const openNotificationWithIcon = (
    type: NotificationType,
    validateSetting: string
  ) => {
    let description: any = getFileUploadErrorMessages(validateSetting).message;
    if (FORMAT_VALIDATE === validateSetting) {
      description = (
        <Col>
          <span>
            Invalid file type uploaded. Submitted files must be in following
            formats.
          </span>
          <br />
          <span>
            pdf, csv, xlsx, xls, docx, doc, pptx, ppt, png, jpeg, jpg, zip
          </span>
        </Col>
      );
    }
    notification[type]({
      closeIcon: <></>,
      top: 40,
      message: getFileUploadErrorMessages(validateSetting).title,
      description: description,
      icon: <CloseOutlined className={styles.notificationErrorIcon} />,
    });
  };

  const handleRemovableDefaultEmail = () => {
    ccEmailInputState.removableDefaultMailList = [issueLogEmail];
    setShowAlertMessage(false);
    form.setFieldsValue({
      ccList: [
        ...ccEmailInputState.emailList,
        ...ccEmailInputState.removableDefaultMailList,
      ],
    });
  };

  return (
    <div className={styles.container}>
      <Form.Item
        name="toList"
        label={<span className={styles.labelText}>To</span>}
        labelAlign="left"
        labelCol={{ xs: 2 }}
        wrapperCol={{ xs: 22 }}
        rules={[
          { required: true, message: 'Please enter an email' },
          {
            message: '',
            validator: (_) => {
              if (!toEmailInputState.isValidList) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error('Invalid email'));
              }
            },
          },
        ]}
        requiredMark="optional"
      >
        <InputEmailChip
          showAddMore={true}
          popDataLoading={isErAdminsLoading}
          popoverOptions={toEmailData || []}
          checkEmpty={true}
          getEmailList={handleGetToEmailList}
          removableDefaultMailList={[]}
          isEmployerSoldConfirmation={true}
        />
      </Form.Item>
      <Form.Item
        name="ccList"
        label={<span className={styles.labelText}>Cc</span>}
        labelAlign="left"
        labelCol={{ xs: 2 }}
        wrapperCol={{ xs: 22 }}
        rules={[
          {
            message: '',
            validator: (_) => {
              if (!ccEmailInputState.isValidList) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error('Invalid email'));
              }
            },
          },
        ]}
      >
        <InputEmailChip
          checkEmpty={true}
          getEmailList={handleGetCCEmailList}
          loggedUser={{
            email: loggedUser,
            popoverMessage: `Your email is cc'd to ensure you receive updates about this sold confirmation.`,
          }}
          defaultMailList={ccEmailInputState.defaultMailList}
          removableDefaultMailList={ccEmailInputState.removableDefaultMailList}
          isEmployerSoldConfirmation={true}
          setShowAlertMessage={setShowAlertMessage}
          alertEmails={[issueLogEmail]}
        />
      </Form.Item>

      <Form.Item
        name="subject"
        label={<span className={styles.labelText}>Subject</span>}
        labelAlign="left"
        labelCol={{ xs: 2 }}
        wrapperCol={{ xs: 22 }}
      >
        <Input className={styles.subjectInput} />
      </Form.Item>

      {brokerDashboard?.enabledIssueLog && showAlertMessage && (
        <FixedAlertMessage
          wrapperClassName={styles.fixedAlert}
          className={styles.alert}
          type="warning"
          message={
            <div>
              Removing the email {issueLogEmail} will prevent the creation of an
              automated ticket in account log for tracking the finalization
              process of this proposal. To add this email back,{' '}
              <span
                className={styles.warningLink}
                onClick={handleRemovableDefaultEmail}
              >
                click here.
              </span>
            </div>
          }
        />
      )}

      <Form.Item
        name="emailBody"
        rules={[
          {
            required: true,
            message: 'Please enter body text.',
          },
          {
            message: 'Please enter body text.',
            validator: (_, value) => {
              if (!isRichTextEditorEmpty(value)) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error('Please enter body text.'));
              }
            },
          },
        ]}
        noStyle
      >
        <RichTextEditor
          onChange={() => setIsEmailBodyEmpty(false)}
          initialValue={emailBodyValue}
          defaultText={emailBodyValue}
          activateDefaultValue={true}
          maxHeight={380}
        />
      </Form.Item>
      {isEmailBodyEmpty && (
        <div className={styles.bodyTextErrorText}>Please enter body text.</div>
      )}

      <Form.Item name="files" noStyle>
        <InputFileUpload
          allowedFileTypes={issueAllowedFileTypes}
          uploadIconLabel="+ Include Attachment to Email"
          maxFileSizeMB={11}
          totalUploadSizeMB={20}
          getFileList={(fileList) => handleFileUpload(fileList)}
          onValidateFails={(validateSetting, validateCondition) => {
            openNotificationWithIcon('error', validateSetting);
          }}
          defaultFileList={defaultFileList}
          isDefaultFileAdded={true}
        />
      </Form.Item>
    </div>
  );
};

export default EmployerSoldConfirmationMailStep;
