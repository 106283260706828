import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
// import * as data from 'sampledata/test.json';
import axios from 'axios';
import {
  fetchAnswer,
  optimizeAnswer,
  advancedOptimize,
  uploadRfpFile,
} from 'modules/rfp/services/RfpService';
import { getAllTags } from 'modules/rfp/services/RfpTagService';

interface Chunk {
  included: boolean;
  chunkId: string;
  cosineScore: string;
  text: string;
  documentId: string;
}
interface Question {
  index: number;
  uuid: string;
  question: any;
  answer: string;
  isPending?: boolean;
  context: Chunk[];
  isError?: boolean;
  userEnteredContext?: string;
  isLiked?: boolean;
  isUnliked?: boolean;
}

interface TrainData {
  documentId: string;
  documentName: string;
  status: string;
}

interface SystemTags {
  tags: any[];
  isError: any;
}

const initialTagState = {
  tags: [],
  isError: null,
};

interface rfpQuestionState {
  rfpQuestions: Question[];
  isLoading: boolean;
  selectedIndex: number;
  rfpFileName: string;
  trainData: TrainData[];
  selectedRfpId: string;
  rfpAddedList: any;
  inProgressId: string;
  isRfpExtractError: any;
  isRfpFailed: boolean;
  includedChunks: any;
  selectedTrainFile: any;
  tags: SystemTags;
  completedTrainData: [];
  uploadedLocationId: string;
  selectedProvideContextFiles: [];
}

const initialState: rfpQuestionState = {
  rfpQuestions: [],
  rfpAddedList: [],
  selectedRfpId: '',
  isLoading: false,
  selectedIndex: 0,
  rfpFileName: '',
  trainData: [],
  inProgressId: '',
  isRfpExtractError: '',
  isRfpFailed: false,
  includedChunks: [],
  selectedTrainFile: [],
  tags: initialTagState,
  completedTrainData: [],
  selectedProvideContextFiles: [],
  uploadedLocationId: '',
};

export const rfpReducerSlice = createSlice({
  name: 'rfp',
  initialState: initialState,
  reducers: {
    setClearState: () => initialState,

    setSelectedRfpId: (state, action) => {
      state.selectedRfpId = action.payload;
    },

    setTagsError: (state, action) => {
      state.tags.isError = action.payload;
    },

    setSystemTags: (state, action) => {
      state.tags.tags = action.payload;
    },

    setSelectedProvideContextFiles: (state, action) => {
      state.selectedProvideContextFiles = action.payload;
    },

    setSelectedTrainFile: (state, action) => {
      state.selectedTrainFile = action.payload;
    },

    setRfpQuestions: (state, action) => {
      const updatedQuestions = action.payload.map((d: Question) => {
        const generatedId = uuid();
        return { ...d, uuid: generatedId };
      });

      state.rfpQuestions = updatedQuestions;
    },
    setRfpFailed: (state, action) => {
      state.isRfpFailed = action.payload;
    },
    setIncludedChunks: (state, action) => {
      state.includedChunks = action.payload;
    },
    setUpdateQuestion: (state, action) => {
      const { index, value } = action.payload;
      state.rfpQuestions[index].answer = value;
    },
    setUpdateQuestionValue: (state, action) => {
      const { index, value } = action.payload;
      state.rfpQuestions[index].question = value;
    },
    setRfpExtractionError: (state, action) => {
      state.isRfpExtractError = action.payload;
    },
    setRfpList: (state, action) => {
      state.rfpAddedList = action.payload;
    },
    setInprogressId: (state, action) => {
      state.inProgressId = action.payload;
    },
    setAddQuestion: (state, action) => {
      const newQuestion = action.payload;
      const generatedId = uuid();

      const questionWithId = {
        ...newQuestion,
        uuid: generatedId,
        isPending: true,
      };

      state.rfpQuestions.push(questionWithId);
    },

    setSelectedRfpIndex: (state, { payload }: PayloadAction<number>) => {
      state.selectedIndex = payload;
    },

    setTrainData: (state, action) => {
      state.trainData = action.payload;
    },

    setCompletedTrainData: (state, action) => {
      state.completedTrainData = action.payload;
    },

    setRfpFileName: (state, action) => {
      const { fileName } = action.payload;
      state.rfpFileName = fileName;
    },

    setChunkBoolean: (state, action) => {
      const { selectedChunk, value, index } = action.payload;
      const updatedRfpQuestions = [...state.rfpQuestions];

      const updatedSelectedChunk = {
        ...selectedChunk,
        included: value,
      };

      updatedRfpQuestions[index].context = updatedRfpQuestions[
        index
      ].context.map((item) =>
        item.chunkId === selectedChunk.chunkId ? updatedSelectedChunk : item
      );

      state.rfpQuestions[index].context = updatedRfpQuestions[
        index
      ].context.map((item) =>
        item.chunkId === action.payload.selectedChunk.chunkId
          ? updatedSelectedChunk
          : item
      );
    },
    setUserEnteredContext: (state, action) => {
      const { index, value } = action.payload;
      state.rfpQuestions[index].userEnteredContext = value;
    },
    setLoading: (state) => {
      state.isLoading = true;
    },
    setLoadingFalse: (state) => {
      state.isLoading = false;
    },
    setRfpAnswer: (state, action) => {
      const {
        isPending,
        answer: {
          index,
          answer: { generatedAnswer },
          context: context,
        },
      } = action.payload;
      if (!isEmpty(state.rfpQuestions)) {
        state.rfpQuestions[index].isPending = isPending;
        state.rfpQuestions[index].answer = generatedAnswer;
        state.rfpQuestions[index].context =
          context &&
          Object.values(context).map((item: any) => ({
            included: true,
            chunkId: item.chunk_id,
            cosineScore: item.cosine_score,
            text: item.text,
            documentId: item.document_id,
          }));
        state.rfpQuestions[index].isError = false;
        state.rfpQuestions[index].isLiked = false;
        state.rfpQuestions[index].isUnliked = false;
      }
    },

    setAdvancedRfpAnswer: (state, action) => {
      const { answer, uuid } = action.payload;
      const selectedIndex = state.rfpQuestions.findIndex(
        (question) => question.uuid === uuid
      );
      state.rfpQuestions[selectedIndex].answer = answer.generatedAnswer;
      state.rfpQuestions[selectedIndex].isPending = false;
    },

    setIsPendingAnswer: (state) => {
      const index = state.selectedIndex;
      state.rfpQuestions[index].isPending = true;
    },
    setAnswerReceived: (state) => {
      const index = state.selectedIndex;
      state.rfpQuestions[index].isPending = false;
    },

    deleteQuestion: (state, action) => {
      if (action.payload >= 0 && action.payload < state.rfpQuestions.length) {
        state.rfpQuestions.splice(action.payload, 1);
      }
    },
    editQuestion: (state, action) => {
      const { index, quesiton } = action.payload;
      state.rfpQuestions[index].question = quesiton;
    },
    optimzeRfAnswer: (state, action) => {
      const { answer, uuid } = action.payload;
      const selectedIndex = state.rfpQuestions.findIndex(
        (question) => question.uuid === uuid
      );
      state.rfpQuestions[selectedIndex].answer = answer;
      state.rfpQuestions[selectedIndex].isPending = false;
    },
    setAnswerLoadingError: (state, action) => {
      const { index } = action.payload;
      state.rfpQuestions[index].isPending = false;
      state.rfpQuestions[index].isError = true;
    },
    setLikeAnswer: (state, action) => {
      const { index } = action.payload;
      state.rfpQuestions[index].isLiked = true;
    },
    setUnlikeAnswer: (state, action) => {
      const { index } = action.payload;
      state.rfpQuestions[index].isUnliked = true;
    },
    setNewAddedAnswer: (state, action) => {
      const { index, answer, context } = action.payload;
      state.rfpQuestions[index].answer = answer;
      state.rfpQuestions[index].isPending = false;
      state.rfpQuestions[index].isError = false;
      state.rfpQuestions[index].context = context;
    },
    setResetChunks: (state, action) => {
      const { index } = action.payload;
      const updatedContext = state.rfpQuestions[index].context.map((item) => ({
        ...item,
        included: true,
      }));
      const updatedState = {
        ...state,
        rfpQuestions: [
          ...state.rfpQuestions.slice(0, index),
          {
            ...state.rfpQuestions[index],
            context: updatedContext,
          },
          ...state.rfpQuestions.slice(index + 1),
        ],
      };
      Object.assign(state, updatedState);
    },
    setUploadedLocationId: (state, { payload }: PayloadAction<string>) => {
      state.uploadedLocationId = payload;
    },
    clearUploadedLocationId: (state) => {
      state.uploadedLocationId = '';
    },
  },
});

export const {
  setClearState,
  setInprogressId,
  setAddQuestion,
  setSelectedRfpId,
  setTrainData,
  setRfpFileName,
  setUserEnteredContext,
  setChunkBoolean,
  setRfpQuestions,
  setLoading,
  setRfpList,
  setRfpAnswer,
  setAdvancedRfpAnswer,
  deleteQuestion,
  setLoadingFalse,
  setIsPendingAnswer,
  editQuestion,
  setAnswerReceived,
  setSelectedRfpIndex,
  optimzeRfAnswer,
  setAnswerLoadingError,
  setLikeAnswer,
  setUnlikeAnswer,
  setNewAddedAnswer,
  setRfpExtractionError,
  setUpdateQuestion,
  setUpdateQuestionValue,
  setRfpFailed,
  setIncludedChunks,
  setResetChunks,
  setSelectedTrainFile,
  setTagsError,
  setSystemTags,
  setCompletedTrainData,
  setSelectedProvideContextFiles,
  setUploadedLocationId,
  clearUploadedLocationId,
} = rfpReducerSlice.actions;

export default rfpReducerSlice.reducer;

export const getAvailableSystemTags =
  (brokerId: string) => async (dispatch: Dispatch) => {
    getAllTags(brokerId!)
      .then((response: any) => {
        dispatch(setSystemTags(response));
      })
      .catch((error: any) => {
        if (axios.isAxiosError(error)) {
          dispatch(setTagsError(error?.response));
        } else {
          console.log(error);
        }
      });
  };

export const getRfpQuestions =
  (
    brokerId: string | undefined,
    file: any,
    isClosedOrEmpty: boolean,
    locationId: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading());

    uploadRfpFile(brokerId, file, locationId)
      .then((res: any) => {
        dispatch(setSelectedRfpId(res?.rfpId));
        dispatch(setInprogressId(res?.rfpId));
        dispatch(setLoadingFalse());
      })
      .catch((error: any) => {
        if (axios.isAxiosError(error)) {
          dispatch(setLoadingFalse());
          if (error?.response?.data?.code === 'token.limit.has.exceeded') {
            dispatch(setRfpExtractionError(error?.response));
          } else {
            dispatch(setRfpFailed(true));
          }
        } else {
          console.log(error);
        }
      });
  };

export const addQuestion =
  (
    brokerId: string,
    question: string,
    index: number,
    rfpId: string,
    documentIdList: any[]
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading());

    dispatch(
      setAddQuestion({
        question: question,
        index: index - 1,
      })
    );

    const answer = await fetchAnswer(
      brokerId,
      question,
      index - 1,
      rfpId,
      documentIdList
    );
    dispatch(
      setNewAddedAnswer({
        index: index - 1,
        answer: answer?.generatedAnswer,
        context: Object.values(answer?.pineconeContext).map((item: any) => ({
          included: true,
          chunkId: item.chunk_id,
          cosineScore: item.cosine_score,
          text: item.text,
          documentId: item.document_id,
        })),
      })
    );
    dispatch(setLoadingFalse());
  };

export const getAnswer = async (
  brokerId: string | undefined,
  question: string,
  index: number,
  rfpId: string,
  documentIdList: any[],
  dispatch: Dispatch
) => {
  try {
    dispatch(
      setRfpAnswer({
        isPending: true,
        answer: { index: index, answer: '' },
      })
    );
    const answer = await fetchAnswer(
      brokerId,
      question,
      index,
      rfpId,
      documentIdList
    );

    dispatch(
      setRfpAnswer({
        isPending: false,
        answer: {
          index: index,
          answer: answer,
          context: answer.pineconeContext,
        },
      })
    );
  } catch (error) {
    dispatch(
      setAnswerLoadingError({
        index: index,
      })
    );
  }
};

export const deleteRfpQuestion =
  (index: number) => async (dispatch: Dispatch) => {
    try {
      dispatch(deleteQuestion(index));
    } catch (error) {}
  };

export const editRfpQuestion =
  (index: number, quesiton: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(
        editQuestion({
          index: index,
          quesiton: quesiton,
        })
      );
    } catch (error) {}
  };

export const setSelectedRfp = (index: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(setSelectedRfpIndex(index));
  } catch (error) {}
};

export const optimizeRfpAnswer =
  (brokerId: string, answer: string, type: any, uuid: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsPendingAnswer());
      const optimizedAnswer = await optimizeAnswer(brokerId, answer, type);
      dispatch(
        optimzeRfAnswer({
          uuid: uuid,
          type: type,
          answer: optimizedAnswer.optimizedAnswer,
        })
      );
    } catch (error) {}
  };

export const advancedOptimizeAnswer =
  (
    brokerId: string,
    question: string,
    includedChunks: any[],
    userContext: string,
    uuid: string,
    rfpId: string
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setIsPendingAnswer());
      const advancedAnswer = await advancedOptimize(
        brokerId,
        question,
        includedChunks,
        userContext,
        rfpId
      );
      dispatch(setAdvancedRfpAnswer({ uuid: uuid, answer: advancedAnswer }));
    } catch (error) {}
  };

export const likeThisAnswer = (index: number) => async (dispatch: Dispatch) => {
  dispatch(
    setLikeAnswer({
      index: index,
    })
  );
};

export const dislikeThisAnswer =
  (index: number) => async (dispatch: Dispatch) => {
    dispatch(
      setUnlikeAnswer({
        index: index,
      })
    );
  };
