import { FC } from 'react';
import { Row } from 'antd';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { ReactComponent as NoteBook } from 'assets/images/notebook.svg';

import {
  EMPLOYER_MEMBER_RESTRICTED,
  EMP_MEMBER_DBG_RESTRICTED,
} from 'constants/commonConstants';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import styles from './noBenguideDashboardView.module.less';

type NoBenguideViewDashboardProps = {
  className: string;
  primaryButtonAction: () => void;
};

const NoBenguideViewDashboard: FC<NoBenguideViewDashboardProps> = (props) => {
  const { className, primaryButtonAction } = props;

  const createDbgGuideDisabled = (
    <PlanyearPopover content={EMP_MEMBER_DBG_RESTRICTED}>
      <PageActionButton type="primary" className={styles.disabledBenguide}>
        Create a Benefits Guide
      </PageActionButton>
    </PlanyearPopover>
  );

  const createDbgEnabled = (
    <PageActionButton
      type="primary"
      onClick={() => primaryButtonAction()}
      className={className}
    >
      Create a Benefits Guide
    </PageActionButton>
  );

  const securedDbgButton = usePermitByUserType(
    createDbgEnabled,
    EMPLOYER_MEMBER_RESTRICTED
  );

  return (
    <div className={styles.noBenguideView}>
      <div className={styles.noBenguideViewWrapper}>
        <Row className={styles.description}>
          <NoteBook />
        </Row>
        <Row className={styles.description}>
          <h2>You don’t have any Benefits Guides for this plan year</h2>
        </Row>
        <Row className={styles.description}>
          <p>Create one or select a different plan year</p>
        </Row>
        <Row className={styles.description}>
          {securedDbgButton || createDbgGuideDisabled}
        </Row>
      </div>
    </div>
  );
};

export default NoBenguideViewDashboard;
