import { FC } from 'react';
import { Col, Row } from 'antd';
import BenefitGuide from 'model/BenefitGuide';
import BenguidePlanCheck from 'modules/benefitGuide/components/BenguidePlanCheck/BenguidePlanCheck';
import styles from './benguidePublish.module.less';

type BenguidePublishPlanlistProps = {
  benefitGuidePlanList: BenefitGuide;
};

const BenguidePublishPlanlist: FC<BenguidePublishPlanlistProps> = (prop) => {
  const { benefitGuidePlanList } = prop;

  const medicalPage = benefitGuidePlanList && benefitGuidePlanList.medical;
  const dentalPage = benefitGuidePlanList && benefitGuidePlanList.dental;
  const visionPage = benefitGuidePlanList && benefitGuidePlanList.vision;
  const lifeDisabilityPage =
    benefitGuidePlanList && benefitGuidePlanList.lifeAndDisability;
  const holidaysPage =
    benefitGuidePlanList && benefitGuidePlanList.holidaysAndTimeOff;
  const hsaPage = benefitGuidePlanList && benefitGuidePlanList.hsa;
  const fsaPage = benefitGuidePlanList && benefitGuidePlanList.fsa;
  const hraPage = benefitGuidePlanList && benefitGuidePlanList.hra;
  const familyPage =
    benefitGuidePlanList && benefitGuidePlanList.familyAndLeave;
  const volPage = benefitGuidePlanList && benefitGuidePlanList.volBenefits;
  const wellbeingPage = benefitGuidePlanList && benefitGuidePlanList.wellbeing;
  const perkPage = benefitGuidePlanList && benefitGuidePlanList.additionalPerks;
  const telehealthPage =
    benefitGuidePlanList && benefitGuidePlanList.telehealth;
  const commuterPage = benefitGuidePlanList && benefitGuidePlanList.commuter;
  const fourOOnekPage = benefitGuidePlanList && benefitGuidePlanList.fourOOneK;
  const insurancePage =
    benefitGuidePlanList && benefitGuidePlanList.howInsuranceWorks;
  const newHirePage =
    benefitGuidePlanList && benefitGuidePlanList.newHireCheckList;
  const datesPage =
    benefitGuidePlanList && benefitGuidePlanList.datesAndEligibility;
  const resourcesPage = benefitGuidePlanList && benefitGuidePlanList.resources;

  const plansArray = [
    { component: medicalPage, name: 'Medical' },
    { component: lifeDisabilityPage, name: 'Life & Disability' },
    { component: hraPage, name: 'HRA' },
    { component: holidaysPage, name: 'Holidays & Time Off' },
    { component: telehealthPage, name: 'Telehealth/Rx Delivery' },
    { component: volPage, name: 'Voluntary Benefits' },
    { component: fsaPage, name: 'FSA' },
    { component: familyPage, name: 'Family & Leave' },
    { component: dentalPage, name: 'Dental' },
    { component: wellbeingPage, name: 'Wellbeing' },
    { component: hsaPage, name: 'HSA' },
    { component: perkPage, name: 'Additional Perks' },
    { component: visionPage, name: 'Vision' },
    { component: fourOOnekPage, name: '401(k)' },
    { component: commuterPage, name: 'Commuter' },
  ];

  const othersArray = [
    { component: newHirePage, name: 'New Hire Checklist' },
    { component: insurancePage, name: 'How Insurance Works' },
    { component: datesPage, name: 'Dates & Eligibility' },
    {
      component: {
        enabled: resourcesPage && resourcesPage.enabled,
        sections: [],
      },
      name: 'Resources',
    },
  ];

  return (
    <div className={styles.reviewContent}>
      <span className="text-tab-header">2. Review Benefits and Content</span>
      <Row className={`text-tab-header ${styles.plans}`}>Benefits</Row>
      <Row className={styles.benguidePlanYear}>
        {plansArray.map(
          (plan) =>
            plan &&
            plan.component && (
              <Col className={styles.planCol}>
                <BenguidePlanCheck
                  sectionEnabled={plan.component.enabled}
                  planReferences={plan.component.plans}
                  pageSections={[]}
                  isPlan={true}
                  pageName={plan.name}
                />
              </Col>
            )
        )}
      </Row>
      <Row>
        <span className={`text-tab-header ${styles.plans}`}>
          Addtional Content
        </span>
      </Row>
      <Row className={styles.benguidePlanYear}>
        {othersArray.map(
          (plan) =>
            plan &&
            plan.component && (
              <Col className={styles.planCol}>
                <BenguidePlanCheck
                  sectionEnabled={plan.component.enabled}
                  planReferences={[]}
                  pageSections={plan.component.sections}
                  isPlan={false}
                  pageName={plan.name}
                />
              </Col>
            )
        )}
      </Row>
    </div>
  );
};

export default BenguidePublishPlanlist;
