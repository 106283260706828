import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';

import { isEmpty } from 'lodash';
import InputForm from 'components/InputForm/InputForm';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';

import { EMPTY_MESSAGE } from 'constants/commonConstants';
import {
  BasicPlans,
  BenefitTypesDropdownOptions,
} from 'modules/plans/constants';

import {
  deleteNonMdvService,
  emptyValue,
  handleNonMdvDescChange,
} from 'modules/plans/utils';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import AddNonMDVCustomModal from 'modules/plans/components/AddCustomServicesLifeModal/AddNonMDVCustomModal';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  changeLifePlanDescription,
  deleteLifePlanService,
  setChangeService,
} from 'modules/plans/slices/lifePlanSlice';
import { NonMDVServiceType } from 'model/plans/NonMDVServiceType';
import LifeServiceSystemRow from 'modules/plans/life/components/LifeServiceSystemRow/LifeServiceSystemRow';
import { getDisplayNameCamelCase } from 'model/plans/ServiceType';
import { ReactComponent as AIFeedbackRedFlag } from 'assets/images/ai-red-flag.svg';
import InfoIconComponent from 'modules/plans/components/LLMInfoIconComponent/InfoIconComponent';
import { LlmExtractionInfo, LlmInfo } from 'model/plans/LLMExtraction';
import { TDocumentExtractionSource } from 'modules/plans/components/AddPlanModal/AddPlanModal';
import UpdatePlanParametersInfo from 'modules/plans/UpdatePlanParametersInfo/UpdatePlanParametersInfo';
import styles from './basicPlanInformation.module.less';

type PlanInformationProps = {
  isCancelButtonClickConfirm: boolean;
  setFormData: Function;
  formData: any;
  lifePlanType: string;
  isEditMode: boolean;
  selectedOption: string;
  setSelectedOption: Function;
  setAlertMessage?: Function;
  isReviewHighlight?: boolean;
  isTitleVisible?: boolean;
  isAdvancedProfile?: boolean;
  docExtractionSource?: TDocumentExtractionSource;
};

const PlanInformation = forwardRef((props: PlanInformationProps, ref) => {
  const {
    isCancelButtonClickConfirm,
    setFormData,
    formData,
    lifePlanType,
    isEditMode,
    selectedOption,
    setSelectedOption,
    isReviewHighlight = false,
    isTitleVisible = true,
    isAdvancedProfile,
    docExtractionSource,
  } = props;

  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    resetForm() {
      form.resetFields();
      setSelectedOption('');
    },
    validate: async () => ({
      isComplete: getIsComplete(),
    }),
  }));

  const { lifePlanServiceList } = useAppSelector(
    (state) => state.plan.lifePlan
  );

  const { llmExtractionInfo, extractionFinalized, textractJobId } =
    useAppSelector((state) => state.plan.lifePlan?.lifePlan);

  const [showLifeAddServiceModal, setShowLifeAddServiceModal] = useState(false);

  const isUploadedPlan = !!textractJobId && textractJobId !== '';

  const getIsComplete = (): boolean => {
    const fieldsToCheck = [
      'benefitMaximum',
      'guaranteedIssue',
      'ageReduction',
      'multiplier',
      'flatAmount',
    ];

    lifePlanServiceList.forEach((service: any) => {
      if (service?.description?.trim() === '') {
        fieldsToCheck.push(getDisplayNameCamelCase(service.service));
      }
    });

    if (selectedOption === BenefitTypesDropdownOptions.FLAT_AMOUNT.value) {
      fieldsToCheck.push('flatAmount');
    } else if (
      selectedOption === BenefitTypesDropdownOptions.MULTIPLIER_OF_SALARY.value
    ) {
      fieldsToCheck.push('multiplier');
    } else if (
      selectedOption ===
      BenefitTypesDropdownOptions.MULTIPLIER_OF_SALARY_AND_FLAT_AMOUNT.value
    ) {
      fieldsToCheck.push('multiplier', 'flatAmount');
    }

    if (lifePlanType === BasicPlans.BASIC_LIFE.value) {
      fieldsToCheck.push('lifeRate');
    } else if (lifePlanType === BasicPlans.BASIC_ADD.value) {
      fieldsToCheck.push('addRate');
    } else if (lifePlanType === BasicPlans.BASIC_LIFE_AND_ADD.value) {
      fieldsToCheck.push('lifeRate', 'addRate');
    }

    if (Object.keys(BasicPlans).includes(lifePlanType)) {
      fieldsToCheck.push('volume');
    }

    return Object.entries(formData)
      .filter(([key]) => fieldsToCheck.includes(key))
      .every(([_, value]) => !emptyValue(value));
  };

  useEffect(() => {
    if (formData && isEditMode) {
      form.setFieldsValue({ ...formData });
      setSelectedOption(formData.lifeBenefit);
    }
  }, [formData, form, isEditMode, setSelectedOption]);

  useEffect(() => {
    if (isCancelButtonClickConfirm) {
      form.resetFields();
      setSelectedOption('');
    }
  }, [form, isCancelButtonClickConfirm, setSelectedOption]);

  const dispatch = useAppDispatch();
  const onInputChange = async (allValues: any, value: any) => {
    form.setFieldsValue(allValues);

    setFormData({ ...formData, ...allValues });
  };

  const handleDelete = (service: string) => {
    const nonMDVServiceTypes: NonMDVServiceType[] = deleteNonMdvService(
      lifePlanServiceList,
      service
    );
    dispatch(deleteLifePlanService(nonMDVServiceTypes));
  };

  const handleServiceDescriptionChange = (value: string, service: string) => {
    const nonMDVServiceTypes: NonMDVServiceType[] = handleNonMdvDescChange(
      lifePlanServiceList,
      service,
      value
    );
    dispatch(changeLifePlanDescription(nonMDVServiceTypes));
  };

  const getPlanTypeName = (lifePlanType: string) => {
    if (lifePlanType === BasicPlans.BASIC_LIFE.value) {
      return BasicPlans.BASIC_LIFE.label;
    } else if (lifePlanType === BasicPlans.BASIC_LIFE_AND_ADD.value) {
      return BasicPlans.BASIC_LIFE_AND_ADD.label;
    } else {
      return BasicPlans.BASIC_ADD.label;
    }
  };

  // Disabled warnings due to the fact that the useEffect is only dependent on the lifeServices
  useEffect(() => {
    if (formData?.lifeServices) {
      dispatch(setChangeService(formData?.lifeServices));
    }
    // eslint-disable-next-line
  }, [formData?.lifeServices]);

  // check if a value is of type LlmInfo
  const isLlmInfo = (value: any): value is LlmInfo => {
    return value && typeof value.different !== 'undefined';
  };

  // to get custom styles class based on field type and review highlight status
  const getCustomStylesClass = (fieldType: keyof LlmExtractionInfo) => {
    if (isReviewHighlight) {
      return 'highlightField';
    }
    return getIsHighlightedField(fieldType) ? 'highlightedLLMTextField' : '';
  };

  const getIsHighlightedField = (fieldName: keyof LlmExtractionInfo) => {
    const fieldValue = llmExtractionInfo?.[fieldName];
    return (
      !extractionFinalized && isLlmInfo(fieldValue) && fieldValue.different
    );
  };

  return (
    <InputForm
      form={form}
      onValuesChange={onInputChange}
      size={isEditMode ? 'small' : 'middle'}
    >
      {isTitleVisible && (
        <div className={styles.headerText}>Plan Information</div>
      )}
      {!isEmpty(docExtractionSource) && <UpdatePlanParametersInfo />}
      <Row className={styles.lifeAddCol}>
        <Row className={styles.inlineRow}>
          <Col className={styles.iconCol}>
            <span className={styles.inlineIcon}>
              <InfoIconComponent
                showIcon={isUploadedPlan}
                extractionInfo={llmExtractionInfo?.multiplier}
              />
            </span>
          </Col>
          <Col flex="auto">
            <div className={styles.inputWrapper}>
              <Form.Item
                name="multiplier"
                label="Multiplier"
                labelCol={{ span: 6 }}
                rules={[
                  {
                    required: false,
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  className={
                    getIsHighlightedField('multiplier')
                      ? styles.highlightedLLMTextField
                      : ''
                  }
                />
              </Form.Item>
              {getIsHighlightedField('multiplier') && (
                <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
              )}
            </div>
          </Col>
        </Row>

        <Row className={styles.inlineRow}>
          <Col className={styles.iconCol}>
            <span className={styles.inlineIcon}>
              <InfoIconComponent
                showIcon={isUploadedPlan}
                extractionInfo={llmExtractionInfo?.flatAmount}
              />
            </span>
          </Col>
          <Col flex="auto">
            <div className={styles.inputWrapper}>
              <Form.Item
                name="flatAmount"
                label="Flat Amount"
                labelCol={{ span: 6 }}
                rules={[
                  {
                    required: false,
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  className={
                    getIsHighlightedField('flatAmount')
                      ? styles.highlightedLLMTextField
                      : ''
                  }
                />
              </Form.Item>
              {getIsHighlightedField('flatAmount') && (
                <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
              )}
            </div>
          </Col>
        </Row>

        <Row className={styles.inlineRow}>
          <Col className={styles.iconCol}>
            <span className={styles.inlineIcon}>
              <InfoIconComponent
                showIcon={isUploadedPlan}
                extractionInfo={llmExtractionInfo?.benefitMaximum}
              />
            </span>
          </Col>
          <Col flex="auto">
            <div className={styles.inputWrapper}>
              <Form.Item
                name="benefitMaximum"
                label={
                  <span>
                    Benefit
                    <br />
                    Maximum
                  </span>
                }
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 6 }}
                rules={[
                  {
                    required: false,
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  className={
                    getIsHighlightedField('benefitMaximum')
                      ? styles.highlightedLLMTextField
                      : ''
                  }
                />
              </Form.Item>
              {getIsHighlightedField('benefitMaximum') && (
                <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
              )}
            </div>
          </Col>
        </Row>

        <Row className={styles.inlineRow}>
          <Col className={styles.iconCol}>
            <span className={styles.inlineIcon}>
              <InfoIconComponent
                showIcon={isUploadedPlan}
                extractionInfo={llmExtractionInfo?.guaranteedIssue}
              />
            </span>
          </Col>
          <Col flex="auto">
            <div className={styles.inputWrapper}>
              <Form.Item
                name="guaranteedIssue"
                label={
                  <span>
                    Guaranteed
                    <br />
                    Issue
                  </span>
                }
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 6 }}
                rules={[
                  {
                    required: false,
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  className={
                    getIsHighlightedField('guaranteedIssue')
                      ? styles.highlightedLLMTextField
                      : ''
                  }
                />
              </Form.Item>
              {getIsHighlightedField('guaranteedIssue') && (
                <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
              )}
            </div>
          </Col>
        </Row>

        {Object.keys(BasicPlans).includes(lifePlanType) && (
          <Row className={styles.inlineRow}>
            <Col className={styles.iconCol}>
              <span className={styles.inlineIcon}>
                <InfoIconComponent
                  showIcon={isUploadedPlan}
                  extractionInfo={llmExtractionInfo?.ageReduction}
                />
              </span>
            </Col>
            <Col flex="auto">
              <div className={styles.inputWrapper}>
                <Form.Item
                  name="ageReduction"
                  label={
                    <span>
                      Age
                      <br />
                      Reduction
                    </span>
                  }
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: false,
                      message: EMPTY_MESSAGE,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    className={
                      getIsHighlightedField('ageReduction')
                        ? styles.highlightedLLMTextField
                        : ''
                    }
                  />
                </Form.Item>
                {getIsHighlightedField('ageReduction') && (
                  <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                )}
              </div>
            </Col>
          </Row>
        )}

        {lifePlanType === BasicPlans.BASIC_LIFE.value && (
          <Row className={styles.inlineRow}>
            <Col className={styles.iconCol}>
              <span className={styles.inlineIcon}>
                <InfoIconComponent
                  showIcon={isUploadedPlan}
                  extractionInfo={llmExtractionInfo?.lifeRate}
                />
              </span>
            </Col>
            <Col flex="auto">
              <div className={styles.inputWrapper}>
                <Form.Item
                  name="lifeRate"
                  label="Life Rate"
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: false,
                      message: EMPTY_MESSAGE,
                      whitespace: true,
                    },
                  ]}
                >
                  <NumberFormatInput
                    isBrokerScreen={true}
                    decimalScale={3}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    customClass={getCustomStylesClass('lifeRate')}
                  />
                </Form.Item>
                {getIsHighlightedField('lifeRate') && (
                  <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                )}
              </div>
              <div className={styles.rateText}> Rates per $1,000 </div>
            </Col>
          </Row>
        )}

        {lifePlanType === BasicPlans.BASIC_ADD.value && (
          <Row className={styles.inlineRow}>
            <Col className={styles.iconCol}>
              <span className={styles.inlineIcon}>
                <InfoIconComponent
                  showIcon={isUploadedPlan}
                  extractionInfo={llmExtractionInfo?.addRate}
                />
              </span>
            </Col>
            <Col flex="auto">
              <div className={styles.inputWrapper}>
                <Form.Item
                  name="addRate"
                  label="AD&D Rate"
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: false,
                      message: EMPTY_MESSAGE,
                      whitespace: true,
                    },
                  ]}
                >
                  <NumberFormatInput
                    isBrokerScreen={true}
                    decimalScale={3}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    customClass={getCustomStylesClass('addRate')}
                  />
                </Form.Item>
                {getIsHighlightedField('addRate') && (
                  <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                )}
              </div>
              <div className={styles.rateText}> Rates per $1,000 </div>
            </Col>
          </Row>
        )}

        {lifePlanType === BasicPlans.BASIC_LIFE_AND_ADD.value && (
          <>
            <Row className={styles.inlineRow}>
              <Col className={styles.iconCol}>
                <span className={styles.inlineIcon}>
                  <InfoIconComponent
                    showIcon={isUploadedPlan}
                    extractionInfo={llmExtractionInfo?.lifeRate}
                  />
                </span>
              </Col>
              <Col flex="auto">
                <div className={styles.inputWrapper}>
                  <Form.Item
                    name="lifeRate"
                    label="Life Rate"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: false,
                        message: EMPTY_MESSAGE,
                        whitespace: true,
                      },
                    ]}
                  >
                    <NumberFormatInput
                      isBrokerScreen={true}
                      decimalScale={3}
                      fixedDecimalScale={true}
                      allowNegative={false}
                      customClass={getCustomStylesClass('lifeRate')}
                    />
                  </Form.Item>
                  {getIsHighlightedField('lifeRate') && (
                    <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                  )}
                </div>
                <div className={styles.lifeAddRateText}> Rates per $1,000 </div>
              </Col>
            </Row>
            <Row className={styles.inlineRow}>
              <Col className={styles.iconCol}>
                <span className={styles.inlineIcon}>
                  <InfoIconComponent
                    showIcon={isUploadedPlan}
                    extractionInfo={llmExtractionInfo?.addRate}
                  />
                </span>
              </Col>
              <Col flex="auto">
                <div className={styles.inputWrapper}>
                  <Form.Item
                    name="addRate"
                    label="AD&D Rate"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: false,
                        message: EMPTY_MESSAGE,
                        whitespace: true,
                      },
                    ]}
                  >
                    <NumberFormatInput
                      isBrokerScreen={true}
                      decimalScale={3}
                      fixedDecimalScale={true}
                      allowNegative={false}
                      customClass={getCustomStylesClass('addRate')}
                    />
                  </Form.Item>
                  {getIsHighlightedField('addRate') && (
                    <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                  )}
                </div>
                <div className={styles.lifeAddRateText}> Rates per $1,000 </div>
              </Col>
            </Row>
          </>
        )}

        {Object.keys(BasicPlans).includes(lifePlanType) && (
          <Row className={styles.inlineRow}>
            <Col className={styles.iconCol}>
              <span className={styles.inlineIcon}>
                <InfoIconComponent
                  showIcon={isUploadedPlan}
                  extractionInfo={llmExtractionInfo?.volume}
                />
              </span>
            </Col>
            <Col flex="auto">
              <div className={styles.inputWrapper}>
                <Form.Item
                  name="volume"
                  label="Volume"
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: false,
                      message: EMPTY_MESSAGE,
                      whitespace: true,
                    },
                  ]}
                >
                  <NumberFormatInput
                    prefix="$"
                    isBrokerScreen={true}
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    placeholder="$"
                    customClass={getCustomStylesClass('volume')}
                  />
                </Form.Item>
                {getIsHighlightedField('volume') && (
                  <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                )}
              </div>
            </Col>
          </Row>
        )}
        {isAdvancedProfile &&
          lifePlanServiceList?.map((obj) => {
            return (
              <LifeServiceSystemRow
                key={obj.service}
                isDefault={false}
                description={obj.description}
                service={obj.service}
                onConfirmClick={() => handleDelete(obj.service)}
                handleServiceDescriptionChange={(value: string) =>
                  handleServiceDescriptionChange(value, obj.service)
                }
                llmExtractionInfo={llmExtractionInfo}
                lifePlanType={lifePlanType}
                isHighlighted={!extractionFinalized}
                isUploadedPlan={isUploadedPlan}
              />
            );
          })}

        {isAdvancedProfile && (
          <Col className={styles.addServiceLink} span={24}>
            <LinkButton
              onClick={() => {
                setShowLifeAddServiceModal(true);
              }}
            >
              + Add Service
            </LinkButton>
          </Col>
        )}
        <AddNonMDVCustomModal
          visible={showLifeAddServiceModal}
          benefitKind={lifePlanType}
          onClose={() => {
            setShowLifeAddServiceModal(false);
          }}
          planTypeName={getPlanTypeName(lifePlanType)}
          displayedServices={[]}
        />
      </Row>
    </InputForm>
  );
});
PlanInformation.displayName = 'PlanInformation';

export default PlanInformation;
