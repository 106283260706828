import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { ColumnsType } from 'antd/lib/table';
import AlertMessage from 'components/Alert/AlertMessage';
import DataTable from 'components/DataTable/DataTable';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { compareMappedPlansAndGetUnMappedPlanList } from 'modules/renewals/utils/renewalsUtils';
import SelectOptions from 'components/SelectOptions/SelectOptions';
import { formVerificationMsg } from 'constants/commonConstants';

import styles from './transferEnrollmentsModal.module.less';

interface Props {
  isEdit?: boolean;
  visible: boolean;
  mappedPlans: string[];
  currentPlanList: any;
  newPlanList: any[];
  alreadyMappedData: any;
  onContinue: (value: any) => void;
  onClose: () => void;
}

interface ITableData {
  UnMappedCurrentPlanName: string;
  UnMappedCurrentPlanId: string;
  mapNewOfferPlan: null | string;
}

const NOT_APPLICABLE = 'N/A';

const TransferEnrollmentsModal = (props: Props) => {
  const {
    mappedPlans,
    currentPlanList,
    newPlanList,
    alreadyMappedData,
    visible,
    onContinue,
    onClose,
    isEdit = false,
  } = props;
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  useEffect(() => {
    if (visible) {
      const newTableData =
        compareMappedPlansAndGetUnMappedPlanList(
          currentPlanList,
          mappedPlans
        )?.map((element) => {
          const mappedData = isEdit
            ? alreadyMappedData ?? {}
            : alreadyMappedData;
          const UnMappedCurrentPlanId = element?.value;
          const mapNewOfferPlan = mappedData
            ? mappedData[UnMappedCurrentPlanId] || NOT_APPLICABLE
            : null;

          return {
            UnMappedCurrentPlanName: element?.label,
            UnMappedCurrentPlanId,
            mapNewOfferPlan,
          };
        }) || [];

      setTableData(newTableData);
    }
    // eslint-disable-next-line
  }, [visible]);

  const getMapOfferPlansOptionsList = () => {
    const optionList = [
      { label: 'N/A - Offered Separately', value: NOT_APPLICABLE },
    ];
    optionList.push(
      ...(newPlanList ?? [])?.sort((a, b) =>
        a?.label?.toLowerCase()?.localeCompare(b?.label?.toLowerCase())
      )
    );
    return optionList;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'CURRENT PLANS',
      key: 'UnMappedCurrentPlanName',
      dataIndex: 'UnMappedCurrentPlanName',
      render: (colData) => {
        return <span className={styles.currentPlanText}>{colData}</span>;
      },
    },
    {
      title: 'OFFER PLANS',
      width: '50%',
      key: 'mapNewOfferPlan',
      dataIndex: 'mapNewOfferPlan',
      render: (colData, _, index) => {
        return (
          <SelectOptions
            options={getMapOfferPlansOptionsList()}
            onChange={(value: any) => {
              setShowErrorAlert(false);
              const copiedArray = [...tableData];
              copiedArray[index].mapNewOfferPlan = value;
              setTableData(copiedArray);
            }}
            value={colData}
            getPopupContainer={(trigger) => trigger.parentNode}
            className={styles.offerSelect}
            customClass={
              showErrorAlert && isEmpty(colData) ? styles.errorStyles : ''
            }
          />
        );
      },
    },
  ];

  const onSubmit = () => {
    // Check if every item in tableData has an empty mapNewOfferPlan property
    const isTableInvalid = tableData.some((item) =>
      isEmpty(item.mapNewOfferPlan)
    );

    if (isTableInvalid) {
      setShowErrorAlert(true);
      return;
    }

    const validTableData = tableData.filter(
      (value) =>
        !!value.mapNewOfferPlan && value?.mapNewOfferPlan !== NOT_APPLICABLE
    );

    // Create a new Map from tableData
    const submitValueObj = new Map(
      validTableData?.map((item) => [
        item.UnMappedCurrentPlanId,
        item.mapNewOfferPlan,
      ])
    );

    // Convert the Map to an object
    const submitValue = Object.fromEntries(submitValueObj);
    // Call the onContinue function with the submitValue
    onContinue(submitValue);
    // Reset the tableData and close the component
    setTableData([]);
    onClose();
  };

  return (
    <ConfirmationDialog
      width={646}
      destroyOnClose={true}
      centered
      visible={visible}
      onConfirm={onSubmit}
      title="Transfer Enrollments"
      confirmText="Continue"
      cancelText="Cancel"
      confirmBtnFullWidth={true}
      closeModal={() => {
        setTableData([]);
        onClose();
      }}
      isCancelLink
    >
      <div className={styles.contentWrapper}>
        {showErrorAlert && (
          <AlertMessage
            type="error"
            message={formVerificationMsg}
            closeAlert={() => {
              setShowErrorAlert(false);
            }}
            wrapperClassName={styles.wrapperAlert}
          />
        )}
        <p className={styles.description}>
          If the plans in this offer are the only plans you are offering next
          year, then you will need to transfer the enrollments from the current
          plans to one of the offer plans below.
        </p>
        <p>
          If you are planning to offer these plans alongside another carrier,
          then select “N/A - Offered Separately” in the drop-down below.
        </p>
        <div>
          <DataTable data={tableData} columns={columns} pagination={false} />
        </div>
      </div>
    </ConfirmationDialog>
  );
};

export default TransferEnrollmentsModal;
