import { FC } from 'react';
import { Card, CardProps, Col, Menu, Row } from 'antd';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg';
import { ReactComponent as PreviewIcon } from 'assets/images/preview-icon.svg';
import { ReactComponent as UrlIcon } from 'assets/images/url-icon.svg';
import { ReactComponent as CloneIcon } from 'assets/images/clone-icon.svg';
import { ReactComponent as FileArrowIcon } from 'assets/images/file-arrow-up.svg';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { BENEFIT_GUIDE_STATUS } from 'modules/benefitGuide/constants/benefitGuideConstants';
import BenguideBasicData from 'model/BenguideBasicData';
import { setDBGObject } from 'modules/employers/slices/benguideSlice';
import { formatDateToUSFormat } from 'util/dateUtil';
import { baseApi } from 'util/apiUtil';

import {
  EMPLOYER_MEMBER_RESTRICTED,
  IndividualSubTypes,
} from 'constants/commonConstants';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import styles from './benguideCard.module.less';

interface BenguideCardProps extends CardProps {
  onClickPublish: (id: string) => void;
  selectedBenguide: BenguideBasicData;
  benguideStatus: string;
  onBenguideEdit: Function;
  onClickArchived: () => void;
  onDelete: () => void;
  onMoveToInProgress: () => void;
  onClickCopyURL: (urlHash: string, employerName: string) => void;
  onPreview: () => void;
  onViewPassword: () => void;
  onView: () => void;
  onClone: () => void;
  onEditUrl: () => void;
  onExport: () => void;
}

const menu = (
  benguideStatus: string,
  onDelete: () => void,
  onMoveToInProgress: () => void,
  onClickArchived: () => void,
  onViewPassword: () => void,
  onEditUrl: () => void,
  onExport: () => void,
  appBootupInfo?: any
) => (
  <Menu>
    {appBootupInfo?.individualSubType === IndividualSubTypes.EMPLOYER_MEMBER ? (
      <>
        {benguideStatus === BENEFIT_GUIDE_STATUS.PUBLISHED && (
          <Menu.Item key="export" onClick={onExport}>
            Export Benefits Summary
          </Menu.Item>
        )}
      </>
    ) : (
      <>
        {benguideStatus === BENEFIT_GUIDE_STATUS.PUBLISHED && (
          <Menu.Item key="export" onClick={onExport}>
            Export Benefits Summary
          </Menu.Item>
        )}
        {benguideStatus !== BENEFIT_GUIDE_STATUS.ARCHIVED && (
          <Menu.Item key="archive" onClick={onClickArchived}>
            Archive
          </Menu.Item>
        )}
        {benguideStatus === BENEFIT_GUIDE_STATUS.PUBLISHED && (
          <Menu.Item key="editUrl" onClick={() => onEditUrl()}>
            Edit URL
          </Menu.Item>
        )}
        {benguideStatus !== BENEFIT_GUIDE_STATUS.PUBLISHED && (
          <Menu.Item key="delete" onClick={onDelete}>
            Delete
          </Menu.Item>
        )}
        {benguideStatus === BENEFIT_GUIDE_STATUS.PUBLISHED && (
          <Menu.Item key="inProgress" onClick={() => onViewPassword()}>
            View Password
          </Menu.Item>
        )}
        {benguideStatus !== BENEFIT_GUIDE_STATUS.IN_PROGRESS && (
          <Menu.Item key="inProgress" onClick={() => onMoveToInProgress()}>
            Move to In Progress
          </Menu.Item>
        )}
      </>
    )}
  </Menu>
);

const BenguideCard: FC<BenguideCardProps> = ({
  onClickPublish,
  selectedBenguide,
  benguideStatus,
  onBenguideEdit,
  onClickArchived,
  onDelete,
  onMoveToInProgress,
  onClickCopyURL,
  onPreview,
  onView,
  onViewPassword,
  onClone,
  onEditUrl,
  onExport,
}) => {
  const dispatch = useAppDispatch();
  const timeStamp = new Date().getTime();
  const imgSrc = `${baseApi + '/v2/benefit-guides'}/${
    selectedBenguide.masterId
  }/common-images?type=BENGUIDE_THUMBNAIL&time=${timeStamp.toString()}`;

  const appBootupInfo = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );

  const isEmpMember =
    appBootupInfo?.individualSubType === IndividualSubTypes.EMPLOYER_MEMBER;

  const securedEditAction = (
    <>
      {benguideStatus !== BENEFIT_GUIDE_STATUS.ARCHIVED && (
        <Col className={styles.benguideEditColumn}>
          <EditIcon />
          <span
            className={`benguide-actions ${styles.editIconStyle}`}
            onClick={() => {
              dispatch(setDBGObject(selectedBenguide));
              onBenguideEdit(selectedBenguide.urlHash);
            }}
          >
            Edit
          </span>
        </Col>
      )}
    </>
  );

  const securedDbgEditAction = usePermitByUserType(
    securedEditAction,
    EMPLOYER_MEMBER_RESTRICTED
  );

  const empMemberURL = (
    <>
      <Col className={styles.benguideColumn}>
        <UrlIcon />
        <span
          className={`benguide-actions ${styles.iconStyle}`}
          onClick={() =>
            onClickCopyURL(
              selectedBenguide.urlHash,
              selectedBenguide.employerName
            )
          }
        >
          URL
        </span>
      </Col>
    </>
  );

  const securedPublishAction = (
    <>
      {benguideStatus !== BENEFIT_GUIDE_STATUS.ARCHIVED && (
        <Col className={styles.benguideColumn}>
          {benguideStatus === BENEFIT_GUIDE_STATUS.IN_PROGRESS &&
          appBootupInfo?.individualSubType !==
            IndividualSubTypes.EMPLOYER_MEMBER ? (
            <FileArrowIcon
              onClick={() => onClickPublish(selectedBenguide.masterId)}
            />
          ) : (
            <UrlIcon />
          )}
          <span
            className={`benguide-actions ${styles.iconStyle}`}
            onClick={
              benguideStatus === BENEFIT_GUIDE_STATUS.IN_PROGRESS &&
              appBootupInfo?.individualSubType !==
                IndividualSubTypes.EMPLOYER_MEMBER
                ? () => onClickPublish(selectedBenguide.masterId)
                : () =>
                    onClickCopyURL(
                      selectedBenguide.urlHash,
                      selectedBenguide.employerName
                    )
            }
          >
            {benguideStatus === BENEFIT_GUIDE_STATUS.IN_PROGRESS
              ? 'Publish'
              : 'URL'}
          </span>
        </Col>
      )}
    </>
  );
  const securedCloneButton = (
    <Col className={styles.benguideColumn} onClick={onClone}>
      <CloneIcon />
      <span className={`benguide-actions ${styles.iconStyle}`}>Clone</span>
    </Col>
  );

  const securedCloneButtonArea = usePermitByUserType(
    securedCloneButton,
    EMPLOYER_MEMBER_RESTRICTED
  );

  const securedDbgPublishAndCloneAction = usePermitByUserType(
    securedPublishAction,
    EMPLOYER_MEMBER_RESTRICTED
  );

  const allowedStatuses = [
    BENEFIT_GUIDE_STATUS.IN_PROGRESS,
    BENEFIT_GUIDE_STATUS.ARCHIVED,
  ];

  const securedDropDown =
    appBootupInfo?.individualSubType === IndividualSubTypes.EMPLOYER_MEMBER &&
    allowedStatuses.includes(benguideStatus) ? (
      <></>
    ) : (
      <SelectDropdown
        showSelectLabel={false}
        overlay={menu(
          benguideStatus || '',
          onDelete,
          onMoveToInProgress,
          onClickArchived,
          onViewPassword,
          onEditUrl,
          onExport,
          appBootupInfo
        )}
      />
    );

  return (
    <div className={styles.benguideCardWrapper}>
      <Card
        bordered={true}
        cover={
          <img
            src={imgSrc}
            style={{ width: 325 }}
            alt="ScreenShot"
            className={styles.pointer}
            onClick={() => {
              if (benguideStatus !== BENEFIT_GUIDE_STATUS.PUBLISHED) {
                onPreview();
              }
              if (benguideStatus === BENEFIT_GUIDE_STATUS.PUBLISHED) {
                onView();
              }
            }}
          ></img>
        }
      >
        <div className={styles.benguideOptions}>
          <Row className={styles.benguideRow}>
            <span
              className={`benguide-header-name ${styles.pointer}`}
              onClick={() => {
                if (benguideStatus !== BENEFIT_GUIDE_STATUS.PUBLISHED) {
                  onPreview();
                }
                if (benguideStatus === BENEFIT_GUIDE_STATUS.PUBLISHED) {
                  onView();
                }
              }}
            >
              <OverflowPopover popoverContent={selectedBenguide.name}>
                {selectedBenguide.name}
              </OverflowPopover>
            </span>
          </Row>
          <Row className={styles.benguideRow}>
            <span className="benguide-card-data">
              Last Updated{' '}
              {formatDateToUSFormat(selectedBenguide.lastUpdatedTs)} by{' '}
              {selectedBenguide.lastUpdatedLoginName}
            </span>
          </Row>
          <Row className={styles.benguideRow} align="middle">
            <Col>{securedDbgEditAction}</Col>
            {BENEFIT_GUIDE_STATUS.PUBLISHED === benguideStatus && (
              <Col
                className={`${
                  benguideStatus !== BENEFIT_GUIDE_STATUS.ARCHIVED
                    ? styles.benguideColumn
                    : ''
                }`}
              >
                <div onClick={onView} className={styles.previewLink}>
                  <PreviewIcon />
                  <span className={`benguide-actions ${styles.iconStyle}`}>
                    View
                  </span>
                </div>
              </Col>
            )}
            {(BENEFIT_GUIDE_STATUS.IN_PROGRESS === benguideStatus ||
              BENEFIT_GUIDE_STATUS.ARCHIVED === benguideStatus) && (
              <Col
                className={`${
                  benguideStatus !== BENEFIT_GUIDE_STATUS.ARCHIVED
                    ? styles.benguideColumn
                    : ''
                }`}
              >
                <div onClick={onPreview} className={styles.previewLink}>
                  <PreviewIcon />
                  <span className={`benguide-actions ${styles.iconStyle}`}>
                    Preview
                  </span>
                </div>
              </Col>
            )}
            {securedDbgPublishAndCloneAction}
            {isEmpMember &&
              BENEFIT_GUIDE_STATUS.PUBLISHED === benguideStatus &&
              empMemberURL}
            {securedCloneButtonArea}
          </Row>
          <Row className={styles.benguideRow}>
            <div className={styles.benguideSelect}>{securedDropDown}</div>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default BenguideCard;
