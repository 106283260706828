import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

import { Row, Table, Switch, Col } from 'antd';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import BrokerAdminDetails, { AccessibleEmployers } from 'model/BrokerAdmin';
import SearchBar from 'components/SearchBar/SearchBar';
import {
  addTeamMember,
  clearManagingTeamMember,
  getBrokerAdminDetails,
  removeTeamMember,
  updateBrokerAdminDetails,
} from 'modules/admins/slices/brokerAdminSlice';
import BrokerAdminDetailsForm from 'modules/admins/components/BrokerAdminDetailsForm/BrokerAdminDetailsForm';
import { NOT_ALLOWED_TO_REMOVE } from 'modules/admins/constants/adminErrorConstants';
import AlertMessage from 'components/Alert/AlertMessage';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import { buildInitials } from 'util/stringUtil';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { IndividualSubTypes } from 'constants/commonConstants';
import { hasMutualLocations } from 'modules/admins/constants/adminConstants';

import styles from './manageBrokerAdmin.module.less';

type ManageBrokerAdminProps = {
  brokerAdminId: string;
  brokerAdminDetails: BrokerAdminDetails;
  handleEditAdmin: Function;
};

const ManageBrokerAdmin: FC<ManageBrokerAdminProps> = (
  props: ManageBrokerAdminProps
) => {
  const { brokerAdminId, brokerAdminDetails, handleEditAdmin } = props;
  const { avatarUrl, firstName, lastName, accessibleEmployers } =
    brokerAdminDetails;

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [removedTeamMember, setRemovedTeamMember] = useState<string>('');
  const [teamMembers, setTeamMembers] = useState<AccessibleEmployers[]>(
    accessibleEmployers || []
  );
  const [searchText, setSearchText] = useState<string>('');
  const [selectedTeamMember, setSelectedTeamMember] =
    useState<AccessibleEmployers | null>(null);
  const [selectedEmployerCount, setSelectedEmployerCount] = useState<number>(0);
  const dispatch = useAppDispatch();

  const removeTeamMemberError = useAppSelector(
    (state) => state.admins.brokerAdmins.removeMemberError
  );

  const addMemberSuccess = useAppSelector(
    (state) => state.admins.brokerAdmins.addMemberCompleted
  );

  const removeMemberSuccess = useAppSelector(
    (state) => state.admins.brokerAdmins.removeMemberCompleted
  );

  const appBootupInfo = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );

  const getAccessibleEmployerCount = useCallback(
    (teamMembers: AccessibleEmployers[]) => {
      return teamMembers.filter(
        (employer: AccessibleEmployers) => employer.accessible
      ).length;
    },
    []
  );

  const getCheckedEmployers = (
    teamMembers: AccessibleEmployers[] | undefined
  ) => {
    if (teamMembers) {
      return teamMembers.filter(
        (employer: AccessibleEmployers) => employer.accessible
      ).length;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (brokerAdminId) {
      if (searchText) {
        const filteredMembers =
          accessibleEmployers &&
          accessibleEmployers.filter((employer) => {
            return employer.employerName.toLowerCase().includes(searchText);
          });
        setTeamMembers(filteredMembers || []);
      } else {
        setTeamMembers(accessibleEmployers || []);
      }
      setSelectedEmployerCount(getCheckedEmployers(accessibleEmployers));
    }
  }, [
    dispatch,
    brokerAdminId,
    accessibleEmployers,
    getAccessibleEmployerCount,
    searchText,
  ]);

  useEffect(() => {
    if (errorOccurred) {
      setErrorOccurred(false);
      dispatch(getBrokerAdminDetails(brokerAdminId));
      setSelectedEmployerCount(getCheckedEmployers(accessibleEmployers));
    }
  }, [errorOccurred, brokerAdminId, accessibleEmployers, dispatch]);

  useEffect(() => {
    if (
      removeTeamMemberError?.data &&
      removeTeamMemberError.data.code === NOT_ALLOWED_TO_REMOVE
    ) {
      setVisibleAlert(true);
      setErrorOccurred(true);
    }
  }, [dispatch, removeTeamMemberError?.data]);

  useEffect(() => {
    if ((addMemberSuccess || removeMemberSuccess) && selectedTeamMember) {
      const { employerId, accessible } = selectedTeamMember;
      const updatedMembers = teamMembers.map((member: AccessibleEmployers) => {
        if (member.employerId == employerId) {
          return { ...member, accessible };
        }
        return member;
      });
      dispatch(clearManagingTeamMember());
      setSelectedTeamMember(null);
      setTeamMembers(updatedMembers);
    }
  }, [
    dispatch,
    teamMembers,
    addMemberSuccess,
    selectedTeamMember,
    removeMemberSuccess,
    getAccessibleEmployerCount,
  ]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = e.target;
    const _searchText = value.trim().toLowerCase();
    setSearchText(_searchText);
    const filteredMembers =
      accessibleEmployers &&
      accessibleEmployers.filter((employer) => {
        return employer.employerName.toLowerCase().includes(_searchText);
      });
    setTeamMembers(filteredMembers || []);
  };

  const teamMembersColumns = [
    {
      title: 'Employer Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ellipsis: {
        showTitle: false,
      },
      render: (name: string) => <OverflowPopover>{name}</OverflowPopover>,
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '5%',
      render: (data: AccessibleEmployers) => {
        const { employerId, employerName } = data;
        return (
          <Switch
            onChange={(e) => handleTeamMembers(e, employerId, employerName)}
            checked={isAccessibleEmployer(employerId)}
            size="small"
            disabled={
              !hasMutualLocations(
                appBootupInfo?.locationIds,
                brokerAdminDetails?.locations,
                appBootupInfo?.individualSubType,
                brokerAdminDetails?.individualSubType
              )
            }
          />
        );
      },
    },
  ];

  const handleTeamMembers = (
    checked: boolean,
    employerId: string,
    employerName: string
  ) => {
    if (checked) {
      dispatch(addTeamMember(employerId, brokerAdminId));
    } else {
      setVisibleAlert(false);
      setRemovedTeamMember(employerName);
      dispatch(removeTeamMember(employerId, brokerAdminId));
    }
    if (accessibleEmployers) {
      const updatedEmp = accessibleEmployers.map((value) => {
        if (value.employerId === employerId) {
          return {
            ...value,
            accessible: checked,
          };
        } else {
          return value;
        }
      });
      dispatch(updateBrokerAdminDetails(updatedEmp));
      setSelectedEmployerCount(getCheckedEmployers(updatedEmp));
    }
    setSelectedTeamMember({ employerId, employerName, accessible: checked });
  };

  const isAccessibleEmployer = (memberId: string) => {
    return teamMembers.filter(
      (member: AccessibleEmployers) => member.employerId === memberId
    )[0].accessible;
  };

  const convertTeamMembersForTableRender = (
    accessibleEmployers: AccessibleEmployers[]
  ): any[] => {
    return (
      accessibleEmployers
        ?.map((accessibleEmployer) => ({
          key: accessibleEmployer.employerId,
          name: accessibleEmployer.employerName,
          action: accessibleEmployer,
        }))
        .sort(
          (a: any, b: any) =>
            b.action.accessible - a.action.accessible ||
            a.name.localeCompare(b.name)
        ) || []
    );
  };

  const closeAlert = () => {
    setVisibleAlert(false);
    setErrorOccurred(false);
  };

  const editBrokerAdmin = () => {
    handleEditAdmin();
  };

  return (
    <div className={styles.manageBrokerAdminWrapper}>
      <div className={styles.alertContainer}>
        {visibleAlert && (
          <AlertMessage
            type="error"
            message={`This admin is the only team member for this ${removedTeamMember} and cannot be removed.`}
            closeAlert={closeAlert}
          />
        )}
      </div>
      {firstName && (
        <Row className={styles.avatarWrapper}>
          <Col span={1}>
            <ProfileAvatar
              content={buildInitials(firstName, lastName)}
              src={avatarUrl}
              className={styles.avatar}
            />
          </Col>
        </Row>
      )}
      <BrokerAdminDetailsForm brokerAdminDetails={brokerAdminDetails} />
      <Row className={styles.teamMemebersTitle}>Team Member for</Row>
      <SearchBar
        className={styles.serachBar}
        placeholder="Search to filter employers"
        onChange={handleSearch}
        isLarge={true}
      />
      <Table
        showHeader={false}
        columns={teamMembersColumns}
        dataSource={convertTeamMembersForTableRender(teamMembers)}
        pagination={false}
        scroll={{ y: 300 }}
        className={styles.scrollableTableWrapper}
      />
      <Row className={styles.membersCountWrapper}>
        <span
          className={
            selectedEmployerCount > 1000
              ? styles.countBadgeSmall
              : styles.countBadgeLg
          }
        >
          {selectedEmployerCount}
        </span>
        <span className={styles.badgeText}>Employers Selected</span>
      </Row>
      <Row justify="center" className={styles.rowStyle}>
        {appBootupInfo?.individualSubType !== IndividualSubTypes.BROKER_USER &&
          hasMutualLocations(
            appBootupInfo?.locationIds,
            brokerAdminDetails?.locations,
            appBootupInfo?.individualSubType,
            brokerAdminDetails?.individualSubType
          ) && (
            <SubmitButton onClick={editBrokerAdmin} type="primary">
              Edit Broker Admin
            </SubmitButton>
          )}
      </Row>
      <Row className={styles.rowStyle} />
    </div>
  );
};

export default ManageBrokerAdmin;
