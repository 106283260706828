import axios from 'axios';
import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as PlanService from 'modules/plans/services/PlanService';

interface DBGInfoState {
  inProgress: boolean;
  error: any;
  requestType: string;
  dbgData: {
    dbgByFrequency: any;
    rateValidations: { hasMismatchContributions: boolean };
  };
  dbgBasicInfoList: Array<any>;
  getFrequenciesInProgress: boolean;
}

const initialState: DBGInfoState = {
  inProgress: false,
  error: null,
  requestType: '',
  dbgData: {
    dbgByFrequency: {},
    rateValidations: { hasMismatchContributions: false },
  },
  dbgBasicInfoList: [],
  getFrequenciesInProgress: false,
};

const dbgInfoSlice = createSlice({
  name: 'dbgInfo',
  initialState,
  reducers: {
    getBenefitGuidesByFrequencyInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
      state.getFrequenciesInProgress = true;
    },
    getBenefitGuidesByFrequencyCompleted: (
      state,
      action: PayloadAction<any>
    ) => {
      const { payload } = action;
      state.inProgress = false;
      state.dbgData = { ...state.dbgData, dbgByFrequency: payload };
      state.requestType = action.type;
      state.getFrequenciesInProgress = false;
    },
    getBenefitGuidesByFrequencyFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = { response: action.payload };
      state.dbgData = { ...state.dbgData, dbgByFrequency: {} };
      state.requestType = action.type;
      state.getFrequenciesInProgress = false;
    },
    checkBenefitGuideRatesInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
    },
    checkBenefitGuideRatesCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.inProgress = false;
      state.dbgData = { ...state.dbgData, rateValidations: payload };
      state.requestType = action.type;
    },
    checkBenefitGuideRatesFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = { response: action.payload };
      state.dbgData = {
        ...state.dbgData,
        rateValidations: { hasMismatchContributions: false },
      };
      state.requestType = action.type;
    },
    getBenefitGuideBasicInfoInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.dbgBasicInfoList = [];
      state.requestType = action.type;
    },
    getBenefitGuideBasicInfoCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.inProgress = false;
      state.dbgBasicInfoList = payload;
      state.requestType = action.type;
    },
    getBenefitGuideBasicInfoFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = { response: action.payload };
      state.dbgBasicInfoList = [];
      state.requestType = action.type;
    },
  },
});

export const {
  getBenefitGuidesByFrequencyInProgress,
  getBenefitGuidesByFrequencyCompleted,
  getBenefitGuidesByFrequencyFailed,
  checkBenefitGuideRatesInProgress,
  checkBenefitGuideRatesCompleted,
  checkBenefitGuideRatesFailed,
  getBenefitGuideBasicInfoInProgress,
  getBenefitGuideBasicInfoCompleted,
  getBenefitGuideBasicInfoFailed,
} = dbgInfoSlice.actions;

export default dbgInfoSlice.reducer;

export const getBenefitGuidesByFrequency = (
  employerId: string,
  planYearId: string,
  benefitKind: string,
  planId: string,
  benefitGuideId?: string,
  revision?: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getBenefitGuidesByFrequencyInProgress());
      const response = await PlanService.getBenefitGuidesGroupByFrequency(
        employerId,
        planYearId,
        benefitKind,
        planId,
        benefitGuideId,
        revision
      );
      const data = response.data;
      dispatch(getBenefitGuidesByFrequencyCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getBenefitGuidesByFrequencyFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const checkBenefitGuidesRates = (
  benefitGuideMasterId: string,
  benefitKind: string,
  revision: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(checkBenefitGuideRatesInProgress());
      const response = await PlanService.checkDBGRates(
        benefitGuideMasterId,
        benefitKind,
        revision
      );
      const data = response.data;
      dispatch(checkBenefitGuideRatesCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          checkBenefitGuideRatesFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const getBenefitGuidesBasicInfo = (benefitGuideMasterList: any[]) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getBenefitGuideBasicInfoInProgress());
      const response = await PlanService.getBenefitGuideBasicInfo(
        benefitGuideMasterList
      );
      const data = response.data;
      dispatch(getBenefitGuideBasicInfoCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getBenefitGuideBasicInfoFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};
