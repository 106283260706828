import { Checkbox, Row, Select } from 'antd';
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { RenderDOMFunc } from 'rc-select/lib/BaseSelect';

import { ReactComponent as FilterIcon } from 'assets/images/icon-funnel.svg';
import { ReactComponent as FilterIconBlue } from 'assets/images/funnel-simple-blue.svg';
import SearchBar from 'components/SearchBar/SearchBar';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import { removeSpecificItemFromStringArray } from 'util/arrayUtil';

import styles from './filterDropDown.module.less';

const { Option } = Select;

type OptionProps = {
  label: string;
  value: any;
};

type FilterDropDownProps = {
  filterName: string;
  showSearch?: boolean;
  searchPlaceholder?: string;
  showCustomButton?: boolean;
  customButtonDisable?: boolean;
  customButtonLabel?: string;
  customButtonOptions?: any[];
  showSelectDeselect?: boolean;
  optionSort?: boolean;
  placeholder: string;
  options: OptionProps[];
  defaultOptions: any[];
  resetOptions: any[];
  archivedOptions?: OptionProps[];
  handleOkayCallback: (value: string[], filterName: string) => void;
  handleResetCallback: (value: string[], filterName: string) => void;
  archivedText?: string;
  isLoading?: boolean;
  isUseLabelForPlaceholder?: boolean;
  getPopupContainer?: RenderDOMFunc | undefined;
};

const SELECT_ALL = 'SELECT_ALL';
const DESELECT_ALL = 'DESELECT_ALL';

const FilterDropDown = forwardRef(
  (
    {
      filterName,
      showSearch = true,
      searchPlaceholder,
      showCustomButton = true,
      customButtonDisable = false,
      customButtonLabel,
      customButtonOptions = [],
      showSelectDeselect = true,
      optionSort = true,
      placeholder,
      options = [],
      defaultOptions = [],
      archivedOptions = [],
      resetOptions = [],
      handleOkayCallback,
      handleResetCallback,
      archivedText = 'ARCHIVED USERS',
      isLoading = false,
      isUseLabelForPlaceholder,
      getPopupContainer,
    }: FilterDropDownProps,
    ref
  ) => {
    const [searchText, setSearchText] = useState('');
    const [selectedValues, setSelectedValues] = useState<any[]>([]);
    const [tempSelectedValues, setTempSelectedValues] = useState<any[]>([]);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    const selectRef = useRef<any>(null);

    const isAllSelected: boolean =
      Number(options?.length) + Number(archivedOptions?.length) ===
      selectedValues?.length;

    useEffect(() => {
      setSelectedValues(defaultOptions);
    }, [defaultOptions]);

    const getFilteredOption = (options: OptionProps[]) => {
      const optionsArray: OptionProps[] = options?.filter(({ label }) =>
        label?.toLowerCase()?.includes(searchText?.toLowerCase().trim())
      );
      return optionsArray;
    };

    const getSortOptions = (options: any[]) => {
      let optionsArray: OptionProps[] = [];
      if (optionSort) {
        optionsArray = options?.sort((a, b) =>
          a?.label?.localeCompare(b?.label)
        );
      } else {
        optionsArray = options;
      }

      return optionsArray;
    };

    const handleOnSelect = (value: any) => {
      switch (value) {
        case SELECT_ALL: {
          const optionsValueArray: any[] = options?.map(({ value }) => value);
          const archivedOptionsValueArray: any[] =
            archivedOptions?.map(({ value }) => value) || [];
          setSelectedValues([
            ...optionsValueArray,
            ...archivedOptionsValueArray,
          ]);
          break;
        }
        case DESELECT_ALL: {
          setSelectedValues([]);
          break;
        }
        // this done because of normal select behavior not duplicating values
        // so forcing to push that placeholder like value to selected array
        case placeholder: {
          if (selectedValues.includes(value)) {
            const removeDeselectedValue = removeSpecificItemFromStringArray(
              selectedValues,
              value
            );
            return setSelectedValues([...removeDeselectedValue]);
          }
          return setSelectedValues([...selectedValues, value]);
        }
        default: {
          if (selectedValues.includes(value)) {
            const removeDeselectedValue = removeSpecificItemFromStringArray(
              selectedValues,
              value
            );
            return setSelectedValues([...removeDeselectedValue]);
          }
          return setSelectedValues([...selectedValues, value]);
        }
      }
    };

    const handleDeselect = (value: any) => {
      switch (value) {
        case SELECT_ALL:
        case DESELECT_ALL:
          return;
        // this done because of normal select behavior not duplicating values
        // so forcing to push that placeholder like value to selected array
        case placeholder: {
          if (selectedValues.includes(value)) {
            const removeDeselectedValue = removeSpecificItemFromStringArray(
              selectedValues,
              value
            );
            return setSelectedValues([...removeDeselectedValue]);
          }
          return setSelectedValues([...selectedValues, value]);
        }
        default: {
          const removeDeselectedValue = removeSpecificItemFromStringArray(
            selectedValues,
            value
          );
          return setSelectedValues([...removeDeselectedValue]);
        }
      }
    };

    const handleOkay = () => {
      setTempSelectedValues(selectedValues);
      handleOkayCallback(selectedValues, String(filterName?.toUpperCase()));
      setSearchText('');
      setShowDropdown(false);
    };
    const handleReset = () => {
      setSelectedValues(resetOptions);
      setTempSelectedValues(resetOptions);
      handleResetCallback(resetOptions, String(filterName?.toUpperCase()));
      setSearchText('');
      setShowDropdown(false);
    };

    useImperativeHandle(ref, () => ({
      reset: () => {
        setSelectedValues(resetOptions);
        setTempSelectedValues(resetOptions);
        setSearchText('');
        setShowDropdown(false);
      },
    }));

    const addPlaceholderToSelectValue = () => {
      let tempSelectedValues = selectedValues;
      if (isUseLabelForPlaceholder) {
        tempSelectedValues = tempSelectedValues.map(
          (value) => options.find(({ value: key }) => key === value)?.label
        );
      }
      if (selectedValues.length === 0) {
        return tempSelectedValues;
      }
      return [placeholder, ...tempSelectedValues];
    };

    const suffixIcon = () => {
      if (isAllSelected) return <FilterIcon />;
      if (selectedValues?.length !== 0) {
        return <FilterIconBlue />;
      }
      return <FilterIcon />;
    };

    const tagRenderComp = (tag: any) => {
      return (
        <div className={styles.tags}>
          <span className={styles.tagSpan}>{tag.label}</span>
        </div>
      );
    };
    const maxRenderComp = (values: any) => {
      return (
        <div className={styles.maxTagPlaceholderTags}>
          <span className={styles.maxTagPlaceholderTagsSpan}>
            + {values.length}
          </span>
        </div>
      );
    };

    const dropdownRenderComp = (menu: React.ReactNode) => {
      return (
        <>
          {showSearch ? (
            <div className={styles.searchFilter}>
              <SearchBar
                placeholder={searchPlaceholder}
                isLarge={false}
                value={searchText}
                onChange={(e) => setSearchText(e?.target?.value?.trimStart())}
                onKeyDown={(e) => e.stopPropagation()}
              />
            </div>
          ) : null}

          {showCustomButton ? (
            <div
              className={`${styles.customButton} ${
                customButtonDisable
                  ? styles?.customButtonDisabled
                  : styles.customButtonEnabled
              }`}
            >
              <span
                onClick={() => {
                  if (customButtonDisable) return;

                  setSelectedValues(customButtonOptions);
                  setTempSelectedValues(customButtonOptions);
                  handleOkayCallback(
                    customButtonOptions,
                    String(filterName?.toUpperCase())
                  );
                  setSearchText('');
                  setShowDropdown(false);
                }}
              >
                {customButtonLabel}
              </span>
            </div>
          ) : null}

          {menu}
          <Row justify="center">
            <SubmitButton
              type="primary"
              className={styles.confirmButton}
              onClick={handleOkay}
            >
              Okay
            </SubmitButton>
          </Row>
          <Row justify="center">
            <CancelButton className={styles.cancelButton} onClick={handleReset}>
              Reset
            </CancelButton>
          </Row>
        </>
      );
    };

    return (
      <div className={styles.filterWrapper}>
        <Select
          ref={selectRef}
          mode="multiple"
          className={styles.filter}
          optionLabelProp="label"
          direction="ltr"
          maxTagCount="responsive"
          maxTagPlaceholder={maxRenderComp}
          listHeight={190}
          showArrow={true}
          showSearch={false}
          placement="bottomLeft"
          suffixIcon={suffixIcon}
          dropdownClassName={styles.dropdownWrapper}
          dropdownRender={dropdownRenderComp}
          tagRender={tagRenderComp}
          placeholder={placeholder}
          value={!isAllSelected ? addPlaceholderToSelectValue() : []}
          onSelect={handleOnSelect}
          onDeselect={handleDeselect}
          onInputKeyDown={(e: any) => {
            if (e.keyCode === 8 && e.target.value.length === 0) {
              return e.stopPropagation();
            }
          }}
          open={showDropdown}
          onDropdownVisibleChange={(open) => {
            setShowDropdown(open);
            if (!open) {
              setSelectedValues(tempSelectedValues);
            } else {
              setTempSelectedValues(selectedValues);
              selectRef?.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            }
          }}
          loading={isLoading}
          disabled={(options?.length ?? 0) === 0}
          getPopupContainer={getPopupContainer}
        >
          {showSelectDeselect && searchText === '' && options?.length !== 0 ? (
            <Option
              key={'ALL_SELECT_DESELECT'}
              value={isAllSelected ? DESELECT_ALL : SELECT_ALL}
            >
              <Checkbox
                className={styles.selectDeselectCheckbox}
                checked={isAllSelected}
              />
              <span className={styles.selectDeselectLabel}>
                {isAllSelected ? 'Deselect All' : 'Select All'}
              </span>
            </Option>
          ) : null}

          {getFilteredOption(getSortOptions(options))?.map((option, idx) => {
            return (
              <Option
                key={option?.value === null ? idx : option?.value}
                value={option?.value}
                label={option?.label}
              >
                <Checkbox
                  className={styles.singleSelect}
                  checked={selectedValues?.includes(option?.value)}
                  onKeyDown={(e) => e.stopPropagation()}
                >
                  <span onClick={(e) => e.stopPropagation()}>
                    {option?.label}
                  </span>
                </Checkbox>
              </Option>
            );
          })}

          {getFilteredOption(getSortOptions(archivedOptions))?.length !== 0 ? (
            <Option className={styles.archivedLabel} key={'ARCHIVED_LABEL'}>
              {archivedText}
            </Option>
          ) : null}

          {getFilteredOption(getSortOptions(archivedOptions))?.map(
            (option, idx) => {
              return (
                <Option
                  key={option?.value === null ? idx : option?.value}
                  value={option?.value}
                  label={option?.label}
                  className={styles.disabledSelect}
                >
                  <Checkbox
                    className={styles.disableCheckbox}
                    checked={selectedValues?.includes(option?.value)}
                    onKeyDown={(e) => e.stopPropagation()}
                  >
                    <span
                      className={styles.archivedOptionLabel}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {option?.label}
                    </span>
                  </Checkbox>
                </Option>
              );
            }
          )}
        </Select>
      </div>
    );
  }
);
FilterDropDown.displayName = 'FilterDropDown';

export default FilterDropDown;
