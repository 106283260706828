import { FC } from 'react';

import { Row } from 'antd';

import styles from './footerText.module.less';

type FooterProps = {
  messageText: string;
};

const FooterText: FC<FooterProps> = (props) => {
  const { messageText } = props;

  return <Row className={styles.messageText}>{messageText}</Row>;
};

export default FooterText;
