import { ReactNode } from 'react';
import { Col, Row } from 'antd';
import SelectOptions from 'components/SelectOptions/SelectOptions';

import styles from 'modules/renewals/components/StartNewRenewalModal/components/EnrollmentsSection/enrollmentsSection.module.less';
import EnrollmentRateInput from 'modules/renewals/components/StartNewRenewalModal/components/EnrollmentRatesSection/EnrollmentRatesSection';
import { TIER_TYPES } from 'modules/renewals/constants/renewalsConstants';

type EnrollmentsSectionProps = {
  data: any;
  validateEnrollment: boolean;
  handleOnChanges: (value: any, type: string, dataIndex: number) => void;
};
const EnrollmentsSection = (props: EnrollmentsSectionProps) => {
  const { data, validateEnrollment, handleOnChanges } = props;

  const STANDARD_TIERS = ['EE', 'ES', 'EC', 'EF'];

  const getEnrollmentColumns = () => {
    if (data?.tierType === TIER_TYPES.STANDARD_TIER) {
      return getStandardTierEnrollmentColumns();
    } else {
      return getNTierEnrollmentColumns();
    }
  };

  const getNTierEnrollmentColumns = () => {
    return data?.nTierEnrollments?.map((enrollment: any, index: number) => (
      <EnrollmentRateInput
        key={index}
        label={`EE${index == 0 ? '' : `+${index}`}`}
        value={enrollment}
        onChange={(value) => {
          handleOnChanges(value, data?.tierType, index);
        }}
        isInvalid={enrollment === '' && validateEnrollment}
      />
    ));
  };

  const getStandardTierEnrollmentColumns = () => {
    return data?.fourTier?.map((tier: any, index: number) => (
      <EnrollmentRateInput
        key={STANDARD_TIERS[index]}
        label={STANDARD_TIERS[index]}
        value={tier}
        onChange={(value) => {
          handleOnChanges(value, data?.tierType, index);
        }}
        isInvalid={tier === '' && validateEnrollment}
      />
    ));
  };

  const getSectionHeader = (title: string): ReactNode => {
    return (
      <div className={styles.rateSectionHeader}>
        <span className={`text-form-label ${styles.rateSectionLabel}`}>
          {title}
        </span>
        <hr />
      </div>
    );
  };

  return (
    <div className={styles.enrollmentWrapper}>
      {getSectionHeader('Enrollment')}

      <Row className={styles.premiumTypeContainer}>
        <Col xs={12}>
          <span className="text-form-label">Premium Type</span>
        </Col>
        <Col xs={12}>
          <SelectOptions
            options={[
              { value: TIER_TYPES.STANDARD_TIER, label: 'Standard 4 Tier' },
              { value: TIER_TYPES.N_TIER, label: 'N tier' },
            ]}
            value={data?.tierType}
            disabled={true}
            className={styles.premiumTypeSelect}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={9}>
          <span className="text-form-label">Enrollments</span>
        </Col>
        <Col xs={15}>{getEnrollmentColumns()}</Col>
      </Row>
    </div>
  );
};

export default EnrollmentsSection;
