import React, { FC } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Input, Row } from 'antd';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import {
  EDIT_PLAN_TABLE,
  IN_NETWORK_VALUE,
  TOOLTIP_IND_WITH_FAM_DED,
  TOOLTIP_IND_WITH_FAM_OOP,
} from 'modules/renewals/constants/renewalsConstants';
import { findInArray } from 'modules/renewals/utils/addPlanDetailUtil';
import { ReactComponent as AIFeedbackRedFlag } from 'assets/images/ai-red-flag.svg';
import styles from 'modules/renewals/components/AddPlanDetailsModal/AddPlanForm/addPlanForm.module.less';

type CurrencyInputFieldsProps = {
  item: string;
  type: string;
  benefitType: string;
  selectedData?: any;
  updateObject: Function;
  isQuotePlansApproved?: boolean;
};

const CurrencyInputFields: FC<CurrencyInputFieldsProps> = (
  props: CurrencyInputFieldsProps
) => {
  const {
    item,
    type,
    benefitType,
    selectedData,
    updateObject,
    isQuotePlansApproved,
  } = props;

  const showToolTipContent = (type: string) => {
    switch (type) {
      case 'Ind. within Family Deductible':
        return (
          <PlanyearPopover
            content={<>{TOOLTIP_IND_WITH_FAM_DED}</>}
            placement="right"
          >
            <QuestionCircleOutlined className={styles.tooltipIcon} />
          </PlanyearPopover>
        );
      case 'Ind. within Family OOP Max':
        return (
          <PlanyearPopover
            content={<>{TOOLTIP_IND_WITH_FAM_OOP}</>}
            placement="right"
          >
            <QuestionCircleOutlined className={styles.tooltipIcon} />
          </PlanyearPopover>
        );
    }
  };

  const inNetwork =
    EDIT_PLAN_TABLE?.[benefitType?.toUpperCase()]?.[type]?.[item]?.inNetwork;
  const outNetwork =
    EDIT_PLAN_TABLE?.[benefitType?.toUpperCase()]?.[type]?.[item]?.outNetwork;

  let inNetworkDeductibleLLMObj = null;
  let outNetworkDeductibleLLMObj = null;

  if (selectedData?.llmExtractionInfo) {
    inNetworkDeductibleLLMObj = findInArray(
      selectedData?.llmExtractionInfo?.[inNetwork?.property],
      inNetwork?.name,
      inNetwork?.fieldType,
      inNetwork?.fieldSubType
    );
    outNetworkDeductibleLLMObj = findInArray(
      selectedData?.llmExtractionInfo?.[outNetwork?.property],
      outNetwork?.name,
      outNetwork?.fieldType,
      outNetwork?.fieldSubType
    );
  }

  const renderInput = (network: any, llmObj: any) => {
    const value =
      (findInArray(
        selectedData?.[network?.property],
        network?.name,
        network?.fieldType,
        network?.fieldSubType
      )?.value ?? '') === 'null'
        ? ''
        : findInArray(
            selectedData?.[network?.property],
            network?.name,
            network?.fieldType,
            network?.fieldSubType
          )?.value ?? '';

    const isLLMDifferent = !isQuotePlansApproved && llmObj?.different;
    return (
      <div className={`${isLLMDifferent ? styles.llmDifferentWrapper : ''}`}>
        <Input
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateObject(
              network?.property,
              e.target.value,
              true,
              {
                fieldSubType: network?.fieldSubType,
                fieldType: network?.fieldType,
                name: network?.name,
              },
              false,
              network?.validations
            )
          }
          className={`${
            network?.fieldType === IN_NETWORK_VALUE ? styles.premiumInput : ''
          } ant-input`}
        />
        {isLLMDifferent && (
          <AIFeedbackRedFlag
            className={
              network?.fieldType === IN_NETWORK_VALUE
                ? styles.aiFeedbackFlagAdditionalPadding
                : styles.aiFeedbackFlag
            }
          />
        )}
      </div>
    );
  };
  return (
    <Row justify={'space-between'} className={styles.subFieldItems}>
      <div>
        {item} &nbsp; {showToolTipContent(item)}
      </div>
      <div className={styles.currencyInputWrapper}>
        {renderInput(inNetwork, inNetworkDeductibleLLMObj)}
        {renderInput(outNetwork, outNetworkDeductibleLLMObj)}
      </div>
    </Row>
  );
};

export default CurrencyInputFields;
