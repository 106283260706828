import { lumityApi } from 'api/apiSlice';

const dashboardApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardClaims: builder.query<
      any,
      {
        employerId: string | undefined;
      }
    >({
      query: (args) => {
        const { employerId } = args;

        return {
          url: `v3/analytics/employers/${employerId}/claims-breakdown/dashboard`,
          method: 'get',
        };
      },
    }),
  }),
});

export const { useGetDashboardClaimsQuery } = dashboardApi;
