import { ReactNode } from 'react';
import { isEmpty } from 'lodash';
import { useAppSelector } from 'hooks/redux';

// Use this hook as a top order component to secure a given component with the permitted types
export const usePermitIf = (
  ComponentName: ReactNode,
  permittedTypes: string[],
  loginTypes: string[]
) => {
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const type = appBootInfo?.type ? appBootInfo.type : '';
  if (
    !isEmpty(type) &&
    !isEmpty(permittedTypes) &&
    permittedTypes.includes(type)
  ) {
    return ComponentName;
  } else if (
    !isEmpty(loginTypes) &&
    validateLoginTypes(loginTypes, appBootInfo?.roles ? appBootInfo.roles : [])
  ) {
    return null;
  }
};

const validateLoginTypes = (
  allowedLoginTypes: string[],
  currentLoginTypes: string[]
): boolean => {
  let count = 0;
  currentLoginTypes.forEach((role) => {
    if (allowedLoginTypes.includes(role)) {
      count++;
    }
  });

  return count > 0;
};
