/* eslint-disable no-unused-vars */
enum ProcessStatus {
  INITIALIZING = 'INITIALIZING',
  MANUAL = 'MANUAL',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  CANCELLED = 'CANCELLED',
  VALIDATING = 'VALIDATING',
  VALIDATED = 'VALIDATED',
  REVIEWED = 'REVIEWED',
  SAVED = 'SAVED',
  REJECTED = 'REJECTED',
}

export default ProcessStatus;
