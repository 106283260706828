import { useEffect, useState } from 'react';
import { Checkbox, Col, Divider, Input, Popover, Row } from 'antd';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  ADMIN_FEE,
  AGGREGATE_STOP_LOSS_FEE_FIELD,
  AGGREGATE_STOP_LOSS_FEE_NAME,
  AGGREGATE_STOP_LOSS_FIELD,
  AGGREGATE_STOP_LOSS_NAME,
  BASIC_ADD,
  BASIC_ADD_RATE,
  BASIC_LIFE,
  BASIC_LIFE_AND_ADD,
  BASIC_LIFE_RATE,
  EDIT_PLAN_TABLE,
  INDIVIDUAL_STOP_LOSS_FEE_FIELD,
  INDIVIDUAL_STOP_LOSS_FEE_NAME,
  INDIVIDUAL_STOP_LOSS_FIELD,
  INDIVIDUAL_STOP_LOSS_NAME,
  LIFE,
  LIFE_BENEFIT_TYPES,
  LLM_EXTRACTION_POPOVER_TEXT_INFO,
  LLM_WARNING_TITLE_TEXT_QUOTE_READING,
  LTD,
  MDV_PLANS,
  NAME_FIELD_PROPERTY,
  OFFER_CATEGORY,
  OTHER_FEES_FIELD,
  OTHER_FEES_NAME,
  PLAN_NETWORK_FIELD_PROPERTY,
  PLAN_TYPE_FIELD_PROPERTY,
  PLAN_TYPES_MD,
  RATE_GUARANTEE_FIELD_PROPERTY,
  REQUIRED_EMPLOYER_CONTRIBUTION_FIELD_PROPERTY,
  REQUIRED_PARTICIPATION_FIELD_PROPERTY,
  STD,
  THIRD_PARTY_ADMIN_FEE_FIELD,
  THIRD_PARTY_ADMIN_FEE_NAME,
  VOLUNTARY_BENEFIT,
  VOLUNTARY_BENEFIT_TYPES,
} from 'modules/renewals/constants/renewalsConstants';
import SelectOptions from 'components/SelectOptions/SelectOptions';
import { BenefitCategory, FEATURE_KEYS } from 'constants/commonConstants';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import MDVServicesSection from 'modules/renewals/components/AddPlanDetailsModal/AddPlanForm/MDVServicesSection/MDVServicesSection';
import RxSection from 'modules/renewals/components/AddPlanDetailsModal/AddPlanForm/RxSection/RxSection';
import NonMDVInputs from 'modules/renewals/components/AddPlanDetailsModal/AddPlanForm/NonMDVInputs/NonMDVInputs';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import DeductiblesSection from 'modules/renewals/components/AddPlanDetailsModal/AddPlanForm/DeductiblesSection/DeductiblesSection';
import RenewalCustomServiceModal from 'modules/plans/components/RenewalLifeAddServiceModal/RenewalLifeAddServiceModal';
import PopulatableInputFileUpload from 'components/PopulatableInputFileUpload/PopulatableInputFileUpload';
import {
  buildNTierMonthlyPremiums,
  validate,
  validatePlanNameLength,
} from 'modules/renewals/utils/addPlanDetailsModalUtil';
import useGetUserFeatureStatus from 'hooks/useGetUserFeatureStatus';
import FundingType from 'modules/plans/enums/FundingType';
import PremiumType from 'modules/renewals/enums/PremiumType';
import { findInArray } from 'modules/renewals/utils/addPlanDetailUtil';
import {
  PartialArrayItemTemplate,
  Plan,
} from 'modules/renewals/types/planTypes';
import LifeServiceRow from 'modules/plans/components/LifeServiceRow/LifeServiceRow';
import { isNullOrUndefined } from 'modules/plans/utils';
import { handleFileItemDownloadClickForPopulatedFileItem } from 'modules/renewals/services/RenewalService';
import { useNavContext } from 'hooks/useNavContext';
import OfferDocType from 'model/OfferDocType';
import { getFileUploadErrorMessages } from 'util/fileUtil';
import { FORMAT_VALIDATE } from 'util/commonUtil';
import { openNotificationWithIcon } from 'components/Notification/Notification';
import QuoteProcessStatus from 'modules/renewals/enums/QuoteUploadStatus';
import { ReactComponent as AIFeedbackRedFlag } from 'assets/images/ai-red-flag.svg';
import styles from 'modules/renewals/components/AddPlanDetailsModal/AddPlanForm/addPlanForm.module.less';
import { ReviewType } from 'modules/renewals/enums/ReviewType';
import { buildPremiumShortForm } from 'modules/renewals/utils/planTableDataTransformerUtil';

type Props = {
  data: any;
  benefitType: string;
  fundingType?: string;
  premiumType?: string;
  showRequired?: boolean;
  isNoCurrentPlans: boolean;
  nTierCount?: number;
  showValidationsOnLoad?: boolean;
  showValidationHighlight?: boolean;
  triggerPlanNameValidation?: boolean;
  triggerAllValidations?: boolean;
  referenceList?: any[];
  setReferenceList?: Function;
  getFileReference?: Function;
  isReferenceSuccess?: boolean;
  isReferenceLoading?: boolean;
  referenceData?: any;
  disableLifeRate?: boolean;
  disableADDRate?: boolean;
  deletedDocumentList?: any[];
  setDeletedDocumentList?: Function;
  isQuotePlan?: boolean;
  setSelectedData?: (data: Plan | any) => void;
  selectedData?: any;
  updateObject: (
    property: string,
    value: string | any,
    isArray: boolean,
    findObj?: any,
    isObj?: boolean,
    validations?: any
  ) => void;
  extractedDocument?: {
    id: string;
    status: QuoteProcessStatus;
    reviewType: ReviewType;
  };
  isQuotePlansApproved?: boolean;
  showFlagItemWarning?: boolean;
};

const AddPlanForm = (props: Props) => {
  const {
    data,
    benefitType,
    fundingType,
    premiumType,
    showRequired,
    isNoCurrentPlans,
    nTierCount = 6,
    showValidationsOnLoad,
    showValidationHighlight,
    triggerPlanNameValidation,
    triggerAllValidations,
    referenceList,
    setReferenceList,
    getFileReference,
    isReferenceSuccess,
    isReferenceLoading,
    referenceData,
    disableLifeRate,
    disableADDRate,
    deletedDocumentList,
    setDeletedDocumentList,
    selectedData,
    isQuotePlan,
    isQuotePlansApproved,
    setSelectedData,
    updateObject,
    showFlagItemWarning,
  } = props;

  const { brokerId, employerId } = useNavContext();

  const [hoverHRA, setHoverHRA] = useState<boolean>(false);
  const [hoverHSA, setHoverHSA] = useState<boolean>(false);

  const [showLifeAddServiceModal, setShowLifeAddServiceModal] =
    useState<boolean>(false);
  const [isDeleteTriggered, setIsDeleteTriggered] = useState<boolean>(false);
  const [totalDocumentList, setTotalDocumentList] = useState<
    { fileName: string; referenceId: string }[]
  >([]);
  const isAdvancedProfile = useGetUserFeatureStatus(
    brokerId,
    employerId,
    FEATURE_KEYS.ADVANCED_PROFILE,
    false
  );

  const isMDVOffer = MDV_PLANS?.includes(benefitType);
  const isMDOffer = [OFFER_CATEGORY.MEDICAL, OFFER_CATEGORY.DENTAL].includes(
    benefitType
  );

  const allowedBenefitTypesAddService = ['STD', 'LTD', 'LIFE'];

  const changeOfferDocumentsActions = (
    file: OfferDocType,
    actionType: 'ADD' | 'DELETE'
  ) => {
    if (actionType === 'ADD') {
      setTotalDocumentList([...totalDocumentList, file]);
    } else {
      const filteredList = referenceList?.filter(
        (obj) => obj.referenceId !== file.referenceId
      );
      setTotalDocumentList(filteredList || []);
      setDeletedDocumentList?.([...deletedDocumentList!, file]);
    }
  };

  useEffect(() => {
    if (isReferenceSuccess && referenceData) {
      setReferenceList?.([...referenceList!, referenceData]);
      changeOfferDocumentsActions(referenceData, 'ADD');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReferenceSuccess, isReferenceLoading]);

  const setValues = (value: string) => {
    return value === 'null' ? '' : value;
  };

  const premiumShortFormNames = buildPremiumShortForm(
    premiumType === PremiumType.N_TIER,
    nTierCount
  ).longPremiumNames;

  const getEditPlanPremiumFields = () => {
    if (premiumType === PremiumType.N_TIER) {
      return {
        ...EDIT_PLAN_TABLE?.[benefitType?.toUpperCase()],
        PREMIUMS: buildNTierMonthlyPremiums(nTierCount, isNoCurrentPlans),
      };
    } else {
      return EDIT_PLAN_TABLE?.[benefitType?.toUpperCase()];
    }
  };

  const removeLifeService = (name: string) => {
    const modifiedServices = selectedData.lifeServices.filter(
      (obj: any) => obj.name !== name
    );
    setSelectedData?.({ ...selectedData, lifeServices: modifiedServices });
  };

  const updateLifeServices = (name: string, value: string) => {
    const modifiedServices = selectedData.lifeServices.map((obj: any) =>
      obj.name === name ? { ...obj, value: value } : obj
    );
    setSelectedData?.({ ...selectedData, lifeServices: modifiedServices });
  };

  const addLifeServices = (name: string, value: string) => {
    const service = { name, value, added: true };
    if (
      isNullOrUndefined(selectedData.lifeServices) ||
      !selectedData.lifeServices?.length
    ) {
      // data already exist
      setSelectedData?.({ ...selectedData, lifeServices: [service] });
    } else {
      setSelectedData?.({
        ...selectedData,
        lifeServices: [...selectedData.lifeServices, service],
      });
    }
  };

  const openNotificationShow = (
    validateSetting: string,
    individualFileUploadLimit: number,
    totalUploadLimit: number
  ) => {
    let description: any = getFileUploadErrorMessages(
      validateSetting,
      individualFileUploadLimit,
      totalUploadLimit
    ).message;
    if (FORMAT_VALIDATE === validateSetting) {
      description = (
        <Col>
          <span>
            Invalid file type uploaded. Submitted files must be in following
            formats.
          </span>
          <br />
          <span>
            pdf, csv, xlsx, xls, docx, doc, pptx, ppt, png, jpeg, jpg, zip
          </span>
        </Col>
      );
    }
    openNotificationWithIcon(
      'error',
      description,
      getFileUploadErrorMessages(validateSetting, 100, 1000).title,
      40,
      <CloseOutlined className={styles.notificationErrorIcon} />
    );
  };
  const showErrorValidation = (
    initialShowError: boolean,
    property: string
  ): boolean => {
    const isBasicLifeRate = property === BASIC_LIFE_RATE;
    const isBasicAddRate = property === BASIC_ADD_RATE;
    const isSelectedData = selectedData && selectedData.insuranceType;
    const isBasicLife = [BASIC_LIFE, BASIC_LIFE_AND_ADD, STD, LTD].includes(
      isSelectedData
    );
    const isBasicAdd = [BASIC_ADD, BASIC_LIFE_AND_ADD].includes(isSelectedData);

    if (initialShowError && !isBasicLifeRate && !isBasicAddRate) {
      return true;
    } else if (
      initialShowError &&
      ((isBasicLifeRate && isBasicLife) || (isBasicAddRate && isBasicAdd))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getFileList = (files: any) => {
    const referenceNameSet = new Set(
      referenceList?.map((file) => file.fileName)
    );
    const fileSet = new Set(files.map((file: any) => file.name));

    // scenario 1 - new file uploaded
    const uploadedFile = files.find(
      (obj: any) => !referenceNameSet.has(obj.name)
    );
    if (uploadedFile) {
      // upload
      getFileReference?.({
        uploadedFile: uploadedFile,
        employerId: String(employerId),
      });
      return;
    }

    // scenario 2 - delete
    const deletedFile = referenceList?.find(
      (obj) => !fileSet.has(obj.fileName)
    );
    if (deletedFile && isDeleteTriggered) {
      changeOfferDocumentsActions(deletedFile, 'DELETE');
      const filteredReferences = referenceList?.filter(
        (obj) => obj.fileName != deletedFile.fileName
      );
      setIsDeleteTriggered(false);
      setReferenceList?.(filteredReferences);
      return;
    }
  };

  const shouldDisplayWarning = showFlagItemWarning && isQuotePlan;

  const showAiDifferentFlagIcon = isQuotePlan && (
    <AIFeedbackRedFlag className={styles.aiFeedbackFlag} />
  );

  const getLLMDifferentNonArray = (property: string) => {
    const isDifferent =
      !isQuotePlansApproved &&
      selectedData?.llmExtractionInfo?.[property]?.different;

    return {
      flagIcon: isDifferent ? showAiDifferentFlagIcon : null,
      wrapperClass: isDifferent ? styles.llmDifferentWrapper : '',
      numberFieldClass: isDifferent ? styles.llmDifferentNumberField : '',
    };
  };

  const getAdminFeeComponent = () => {
    return (
      <>
        <Row justify={'space-between'}>
          <div
            className={` ${styles.adminFee}
            ${
              validate('EMPTY', selectedData?.administrationFee?.value ?? '') &&
              (showValidationsOnLoad || showValidationHighlight)
                ? styles.errorText
                : ''
            }
              `}
          >
            Admin Fee (PEPM) <sup className={styles.supperScriptFont}>1</sup> *
          </div>
          <div
            className={`${styles.fundingType}  ${
              getLLMDifferentNonArray(ADMIN_FEE).wrapperClass
            }`}
          >
            <NumberFormatInput
              prefix="$"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale={true}
              allowNegative={false}
              onChange={(e: any) =>
                updateObject?.(
                  ADMIN_FEE,
                  e.target.value,
                  false,
                  {
                    fieldSubType: null,
                    fieldType: null,
                    name: 'Admin Fee',
                  },
                  true,
                  { currency: true }
                )
              }
              value={selectedData?.administrationFee?.value ?? ''}
              className={`${
                getLLMDifferentNonArray(ADMIN_FEE).numberFieldClass
              } ${
                validate(
                  'EMPTY',
                  selectedData?.administrationFee?.value ?? ''
                ) &&
                (showValidationsOnLoad || showValidationHighlight)
                  ? styles.inputFormatError
                  : ''
              }`}
            />
            {getLLMDifferentNonArray(ADMIN_FEE).flagIcon}
          </div>
        </Row>
        <Row>
          <div className={styles.description}>
            <span>1</span> This data is shared by all self-funded plans in this
            plan year with the same carrier.
          </div>
        </Row>
      </>
    );
  };

  const getMedicalSelfFundingPlanFields = () => {
    const fields = [
      {
        name: INDIVIDUAL_STOP_LOSS_NAME,
        field: INDIVIDUAL_STOP_LOSS_FIELD,
        showSuperscript: true,
      },
      {
        name: INDIVIDUAL_STOP_LOSS_FEE_NAME,
        field: INDIVIDUAL_STOP_LOSS_FEE_FIELD,
        showSuperscript: true,
      },
      {
        name: AGGREGATE_STOP_LOSS_NAME,
        field: AGGREGATE_STOP_LOSS_FIELD,
        showSuperscript: false,
      },
      {
        name: AGGREGATE_STOP_LOSS_FEE_NAME,
        field: AGGREGATE_STOP_LOSS_FEE_FIELD,
        showSuperscript: true,
      },
      {
        name: THIRD_PARTY_ADMIN_FEE_NAME,
        field: THIRD_PARTY_ADMIN_FEE_FIELD,
        showSuperscript: true,
      },
      {
        name: OTHER_FEES_NAME,
        field: OTHER_FEES_FIELD,
        showSuperscript: true,
      },
    ];

    const renderFieldRow = ({
      name,
      field,
      showSuperscript,
    }: {
      name: string;
      field: string;
      showSuperscript: boolean;
    }) => (
      <Row justify={'space-between'} key={field}>
        <div className={`${styles.adminFee}`}>
          {name}
          {showSuperscript && <sup className={styles.supperScriptFont}>1</sup>}
        </div>
        <div
          className={`${styles.fundingType} ${
            getLLMDifferentNonArray(field).wrapperClass
          }`}
        >
          <NumberFormatInput
            prefix="$"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={false}
            onChange={(e: any) =>
              updateObject?.(
                field,
                e.target.value,
                false,
                { fieldSubType: null, fieldType: null, name },
                true,
                { currency: true }
              )
            }
            value={selectedData?.[field]?.value ?? ''}
            className={`${getLLMDifferentNonArray(field).numberFieldClass}`}
          />
          {getLLMDifferentNonArray(field).flagIcon}
        </div>
      </Row>
    );

    return (
      <>
        {fields.map(renderFieldRow)}
        <Row>
          <div className={styles.descriptionMedical}>
            <span>1</span> This data is shared by all self-funded plans in this
            plan year with the same carrier.
          </div>
        </Row>
      </>
    );
  };

  const renderLifeServiceRows = (
    lifeServices: PartialArrayItemTemplate[],
    llmLifeServiceArray: PartialArrayItemTemplate[]
  ) => {
    if (!isNullOrUndefined(lifeServices)) {
      return lifeServices.map((service) => {
        const { name, value, added } = service;
        const llmLifeServiceObj = llmLifeServiceArray?.find(
          (item) => item?.name === name
        );
        return (
          <LifeServiceRow
            key={name}
            service={name}
            isDefault={false}
            title={name!}
            value={value}
            onOkRemove={() => {
              removeLifeService(name!);
            }}
            onChangeFieldValue={updateLifeServices}
            isLLMDifferent={
              !isQuotePlansApproved && llmLifeServiceObj?.different && !added
            }
          />
        );
      });
    }
  };

  return (
    <>
      {shouldDisplayWarning && (
        <Col span={24}>
          <Row className={styles.createPlanHeader}>
            <AIFeedbackRedFlag />
            {LLM_WARNING_TITLE_TEXT_QUOTE_READING}
            <PlanyearPopover
              content={LLM_EXTRACTION_POPOVER_TEXT_INFO}
              placement="left"
            >
              <QuestionCircleOutlined />
            </PlanyearPopover>
          </Row>
        </Col>
      )}
      <div
        className={`${styles.editPlansWrapper} ${
          isQuotePlan ? styles.editPlanWrapperScroll : ''
        }`}
      >
        <div
          className={`${styles.editPlansContentWrapper} ${
            isQuotePlan ? styles.quoteMargin : styles.generalWrapperMargin
          }`}
        >
          <div className={styles.generalInfoWrapper}>
            <div
              className={`${styles.subTitle} ${
                isQuotePlan ? styles.subTitleGeneralMargin : ''
              }`}
            >
              General Info
            </div>
            <Divider className={styles.divider} />
            <div className={styles.innerWrapper}>
              <Row justify={'space-between'}>
                <div
                  className={
                    (validate('EMPTY', selectedData?.name ?? '') ||
                      validatePlanNameLength(selectedData?.name)) &&
                    (showValidationsOnLoad ||
                      showValidationHighlight ||
                      triggerPlanNameValidation)
                      ? styles.errorText
                      : ''
                  }
                >
                  Plan Name *
                </div>
                <div
                  className={`${
                    getLLMDifferentNonArray(NAME_FIELD_PROPERTY).wrapperClass
                  } ${
                    (validate('EMPTY', selectedData?.name ?? '') ||
                      validatePlanNameLength(selectedData?.name)) &&
                    (showValidationsOnLoad ||
                      showValidationHighlight ||
                      triggerPlanNameValidation)
                      ? styles.inputError
                      : ''
                  }`}
                >
                  <Input
                    onChange={(e: any) => {
                      updateObject?.(
                        NAME_FIELD_PROPERTY,
                        e.target.value,
                        false
                      );
                    }}
                    maxLength={50}
                    value={setValues(selectedData?.name)}
                  />
                  {getLLMDifferentNonArray(NAME_FIELD_PROPERTY).flagIcon}
                </div>
              </Row>
              {fundingType === FundingType.SELF_FUNDED &&
                benefitType === BenefitCategory.STD.value &&
                getAdminFeeComponent()}

              {isMDVOffer && (
                <div>
                  <Row
                    justify={'space-between'}
                    className={styles.subFieldItems}
                  >
                    <div
                      className={`${
                        validate('EMPTY', selectedData?.planType ?? '') &&
                        (showValidationsOnLoad || showValidationHighlight) &&
                        benefitType?.toUpperCase() === OFFER_CATEGORY?.MEDICAL
                          ? styles.errorText
                          : ''
                      }`}
                    >
                      {`Plan Type ${
                        showRequired &&
                        benefitType?.toUpperCase() === OFFER_CATEGORY?.MEDICAL
                          ? '*'
                          : ''
                      }`}
                    </div>
                    <div
                      className={`${
                        getLLMDifferentNonArray(PLAN_TYPE_FIELD_PROPERTY)
                          .wrapperClass
                      }`}
                    >
                      {benefitType === OFFER_CATEGORY.MEDICAL ||
                      benefitType === OFFER_CATEGORY.DENTAL ? (
                        <SelectOptions
                          options={PLAN_TYPES_MD?.[benefitType]}
                          onChange={(e: any) =>
                            updateObject?.(PLAN_TYPE_FIELD_PROPERTY, e, false)
                          }
                          value={
                            PLAN_TYPES_MD?.[benefitType].some(
                              (item: any) =>
                                item.label === selectedData?.planType
                            )
                              ? selectedData?.planType ?? ''
                              : ''
                          }
                          getPopupContainer={(trigger) => trigger.parentNode}
                          className={
                            validate('EMPTY', selectedData?.planType ?? '') &&
                            (showValidationsOnLoad ||
                              showValidationHighlight) &&
                            benefitType?.toUpperCase() ===
                              OFFER_CATEGORY?.MEDICAL
                              ? styles.inputError
                              : ''
                          }
                        />
                      ) : (
                        <Input
                          onChange={(e: any) =>
                            updateObject?.(
                              PLAN_TYPE_FIELD_PROPERTY,
                              e.target.value,
                              false
                            )
                          }
                          value={setValues(selectedData?.planType)}
                        />
                      )}
                      {
                        getLLMDifferentNonArray(PLAN_TYPE_FIELD_PROPERTY)
                          .flagIcon
                      }
                    </div>
                  </Row>
                  <Row
                    justify={'space-between'}
                    className={styles.subFieldItems}
                  >
                    <div>Plan Network</div>
                    <div
                      className={`${
                        getLLMDifferentNonArray(PLAN_NETWORK_FIELD_PROPERTY)
                          .wrapperClass
                      }`}
                    >
                      <Input
                        onChange={(e: any) =>
                          updateObject?.(
                            PLAN_NETWORK_FIELD_PROPERTY,
                            e.target.value,
                            false
                          )
                        }
                        value={selectedData?.planNetwork ?? ''}
                      />
                      {
                        getLLMDifferentNonArray(PLAN_NETWORK_FIELD_PROPERTY)
                          .flagIcon
                      }
                    </div>
                  </Row>
                  {benefitType === OFFER_CATEGORY.MEDICAL && (
                    <Row
                      justify={'space-between'}
                      className={styles.subFieldItems}
                    >
                      <Col xs={isQuotePlan ? 12 : 11}>HSA Eligible</Col>
                      <Col
                        xs={isQuotePlan ? 12 : 13}
                        className={styles.hsaEligibleCheckbox}
                        onMouseEnter={() => setHoverHSA(true)}
                        onMouseLeave={() => setHoverHSA(false)}
                      >
                        <Popover
                          content={
                            <div>This plan is already marked as HRA</div>
                          }
                          overlayClassName={'popoverInner recommendedtooltip'}
                          visible={!!selectedData?.hraCompatible && hoverHSA}
                        >
                          <Checkbox
                            checked={
                              selectedData?.hsaCompatible ||
                              selectedData?.planType === 'HDHP'
                            }
                            disabled={selectedData?.hraCompatible}
                            onChange={(e: any) => {
                              updateObject?.(
                                'hsaCompatible',
                                selectedData?.hsaCompatible
                                  ? !selectedData?.hsaCompatible
                                  : true,
                                false
                              );
                            }}
                          >
                            Yes
                          </Checkbox>
                        </Popover>
                      </Col>
                    </Row>
                  )}{' '}
                  <Row
                    justify={'space-between'}
                    className={styles.subFieldItems}
                  >
                    <Col
                      className={styles.hraEligibleTag}
                      xs={isQuotePlan ? 12 : 11}
                    >
                      HRA Eligible
                    </Col>
                    <Col
                      onMouseEnter={() => setHoverHRA(true)}
                      onMouseLeave={() => setHoverHRA(false)}
                      xs={isQuotePlan ? 12 : 13}
                      className={styles.hraEligibleCheckbox}
                    >
                      <Popover
                        content={<div>This plan is already marked as HSA</div>}
                        overlayClassName={'popoverInner recommendedtooltip'}
                        visible={!!selectedData?.hsaCompatible && hoverHRA}
                      >
                        <Checkbox
                          checked={selectedData?.hraCompatible}
                          disabled={selectedData?.hsaCompatible}
                          onChange={(e) =>
                            setSelectedData?.({
                              ...selectedData,
                              hraCompatible: e.target.checked,
                            })
                          }
                        >
                          Yes
                        </Checkbox>{' '}
                      </Popover>
                    </Col>
                  </Row>
                  {fundingType === FundingType.SELF_FUNDED &&
                    [
                      BenefitCategory.DENTAL.value,
                      BenefitCategory.VISION.value,
                    ].includes(benefitType) &&
                    getAdminFeeComponent()}
                  {fundingType === FundingType.SELF_FUNDED &&
                    [BenefitCategory.MEDICAL.value].includes(benefitType) &&
                    getMedicalSelfFundingPlanFields()}
                </div>
              )}

              {[
                BenefitCategory.DENTAL.value,
                BenefitCategory.VISION.value,
                BenefitCategory.STD.value,
                BenefitCategory.LTD.value,
              ].includes(benefitType) && (
                <>
                  <Row
                    justify={'space-between'}
                    className={styles.subFieldItems}
                  >
                    <div>Rate Guarantee</div>
                    <div
                      className={`${
                        getLLMDifferentNonArray(RATE_GUARANTEE_FIELD_PROPERTY)
                          .wrapperClass
                      }`}
                    >
                      <Input
                        onChange={(e: any) =>
                          updateObject?.(
                            RATE_GUARANTEE_FIELD_PROPERTY,
                            e.target.value,
                            false
                          )
                        }
                        value={selectedData?.rateGuarantee ?? ''}
                      />
                      {
                        getLLMDifferentNonArray(RATE_GUARANTEE_FIELD_PROPERTY)
                          .flagIcon
                      }
                    </div>
                  </Row>
                </>
              )}

              {[
                BenefitCategory.DENTAL.value,
                BenefitCategory.VISION.value,
                BenefitCategory.STD.value,
              ].includes(benefitType) && (
                <>
                  <Row
                    justify={'space-between'}
                    className={styles.subFieldItems}
                  >
                    <div>Required Participation</div>
                    <div
                      className={`${
                        getLLMDifferentNonArray(
                          REQUIRED_PARTICIPATION_FIELD_PROPERTY
                        ).wrapperClass
                      }`}
                    >
                      <Input
                        onChange={(e: any) =>
                          updateObject?.(
                            REQUIRED_PARTICIPATION_FIELD_PROPERTY,
                            e.target.value,
                            false
                          )
                        }
                        value={selectedData?.requiredParticipation ?? ''}
                      />
                      {
                        getLLMDifferentNonArray(
                          REQUIRED_PARTICIPATION_FIELD_PROPERTY
                        ).flagIcon
                      }
                    </div>
                  </Row>
                </>
              )}

              {[
                BenefitCategory.DENTAL.value,
                BenefitCategory.VISION.value,
                BenefitCategory.LTD.value,
              ].includes(benefitType) && (
                <>
                  <Row
                    justify={'space-between'}
                    className={styles.subFieldItems}
                  >
                    <div>
                      {[BenefitCategory.LTD.value].includes(benefitType)
                        ? 'Employee Participation Requirement'
                        : 'Required Employer Contribution'}
                    </div>
                    <div
                      className={`${
                        getLLMDifferentNonArray(
                          REQUIRED_PARTICIPATION_FIELD_PROPERTY
                        ).wrapperClass
                      }`}
                    >
                      <Input
                        onChange={(e: any) =>
                          updateObject?.(
                            REQUIRED_EMPLOYER_CONTRIBUTION_FIELD_PROPERTY,
                            e.target.value,
                            false
                          )
                        }
                        value={selectedData?.requiredEmployerContribution ?? ''}
                      />
                      {
                        getLLMDifferentNonArray(
                          REQUIRED_EMPLOYER_CONTRIBUTION_FIELD_PROPERTY
                        ).flagIcon
                      }
                    </div>
                  </Row>
                </>
              )}
              {[LIFE, VOLUNTARY_BENEFIT].includes(benefitType) && (
                <Row justify={'space-between'} className={styles.subFieldItems}>
                  <div
                    className={
                      validate('EMPTY', selectedData?.insuranceType ?? '') &&
                      (showValidationsOnLoad || showValidationHighlight)
                        ? styles.errorText
                        : ''
                    }
                  >
                    {`Benefit Type*`}
                  </div>
                  <SelectOptions
                    options={
                      benefitType === LIFE
                        ? LIFE_BENEFIT_TYPES
                        : VOLUNTARY_BENEFIT_TYPES
                    }
                    onChange={(e: any) =>
                      updateObject?.('insuranceType', e, false)
                    }
                    listHeight={
                      benefitType === VOLUNTARY_BENEFIT ? 130 : undefined
                    }
                    value={setValues(selectedData?.insuranceType ?? '')}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className={
                      validate('EMPTY', selectedData?.insuranceType ?? '') &&
                      (showValidationsOnLoad || showValidationHighlight)
                        ? styles.inputError
                        : ''
                    }
                  />
                </Row>
              )}

              {benefitType === LIFE && (
                <>
                  <Row
                    justify={'space-between'}
                    className={styles.subFieldItems}
                  >
                    <div>Rate Guarantee</div>
                    <div
                      className={`${
                        getLLMDifferentNonArray(RATE_GUARANTEE_FIELD_PROPERTY)
                          .wrapperClass
                      }`}
                    >
                      <Input
                        onChange={(e: any) =>
                          updateObject?.(
                            RATE_GUARANTEE_FIELD_PROPERTY,
                            e.target.value,
                            false
                          )
                        }
                        value={selectedData?.rateGuarantee ?? ''}
                      />
                      {
                        getLLMDifferentNonArray(RATE_GUARANTEE_FIELD_PROPERTY)
                          .flagIcon
                      }
                    </div>
                  </Row>

                  <Row
                    justify={'space-between'}
                    className={styles.subFieldItems}
                  >
                    <div>Required Participation</div>
                    <div
                      className={`${
                        getLLMDifferentNonArray(
                          REQUIRED_PARTICIPATION_FIELD_PROPERTY
                        ).wrapperClass
                      }`}
                    >
                      <Input
                        onChange={(e: any) =>
                          updateObject?.(
                            REQUIRED_PARTICIPATION_FIELD_PROPERTY,
                            e.target.value,
                            false
                          )
                        }
                        value={selectedData?.requiredParticipation ?? ''}
                      />
                      {
                        getLLMDifferentNonArray(
                          REQUIRED_PARTICIPATION_FIELD_PROPERTY
                        ).flagIcon
                      }
                    </div>
                  </Row>
                </>
              )}
            </div>
          </div>
          {isMDVOffer && (
            <div>
              <div className={styles.subTitle}>
                {`${isNoCurrentPlans ? 'Premiums/Enrollment' : 'Premiums'}`}
              </div>
              <Divider className={styles.divider} />
              <div className={styles.innerWrapper}>
                {Object.keys(getEditPlanPremiumFields()?.PREMIUMS ?? {}).map(
                  (item, index: number) => {
                    const enrollment =
                      getEditPlanPremiumFields()?.PREMIUMS?.[item]?.enrollment;
                    const tier =
                      getEditPlanPremiumFields()?.PREMIUMS?.[item]?.tier;
                    const isLLMDifferent =
                      !isQuotePlansApproved &&
                      findInArray(
                        selectedData?.llmExtractionInfo?.[tier?.property],
                        premiumShortFormNames[tier?.name] || tier?.name,
                        tier?.fieldType,
                        tier?.fieldSubType
                      )?.different;

                    return (
                      <Row
                        justify={'space-between'}
                        className={styles.subFieldItems}
                        key={index}
                      >
                        <div
                          className={
                            (validate(
                              'PREMIUM',
                              findInArray(
                                selectedData?.[tier?.property],
                                tier?.name,
                                tier?.fieldType,
                                tier?.fieldSubType
                              )?.value ?? ''
                            ) ||
                              (validate(
                                'ENROLLMENT',
                                findInArray(
                                  selectedData?.[enrollment?.property],
                                  enrollment?.name,
                                  enrollment?.fieldType,
                                  enrollment?.fieldSubType
                                )?.value ?? ''
                              ) &&
                                isNoCurrentPlans)) &&
                            (showValidationsOnLoad || showValidationHighlight)
                              ? styles.errorText
                              : ''
                          }
                        >
                          {showRequired ? item : item?.replace('*', '')}
                        </div>
                        <Row>
                          <span
                            className={`${
                              isLLMDifferent ? styles.llmDifferentWrapper : ''
                            } ${
                              validate(
                                'PREMIUM',
                                findInArray(
                                  selectedData?.[tier?.property],
                                  tier?.name,
                                  tier?.fieldType,
                                  tier?.fieldSubType
                                )?.value ?? ''
                              ) &&
                              (showValidationsOnLoad || showValidationHighlight)
                                ? styles.inputError
                                : ''
                            }`}
                          >
                            <NumberFormatInput
                              prefix={'$ '}
                              thousandSeparator={true}
                              decimalScale={2}
                              allowNegative={false}
                              onChange={(event: any) => {
                                updateObject?.(
                                  tier?.property,
                                  event.target.value,
                                  true,
                                  {
                                    fieldSubType: tier?.fieldSubType,
                                    fieldType: tier?.fieldType,
                                    name: tier?.name,
                                  }
                                );
                              }}
                              value={
                                findInArray(
                                  selectedData?.[tier?.property],
                                  tier?.name,
                                  tier?.fieldType,
                                  tier?.fieldSubType
                                )?.value
                              }
                              className={`${
                                isLLMDifferent
                                  ? styles.llmDifferentNumberField
                                  : ''
                              } ${
                                isNoCurrentPlans
                                  ? styles.premiumInput
                                  : styles.premiumNumberInput
                              }`}
                            />
                            {isLLMDifferent && (
                              <AIFeedbackRedFlag
                                className={
                                  styles.aiFeedbackFlagAdditionalPadding
                                }
                              />
                            )}
                          </span>
                          {isNoCurrentPlans && (
                            <span
                              className={
                                validate(
                                  'ENROLLMENT',
                                  findInArray(
                                    selectedData?.[enrollment?.property],
                                    enrollment?.name,
                                    enrollment?.fieldType,
                                    enrollment?.fieldSubType
                                  )?.value ?? ''
                                ) &&
                                (showValidationsOnLoad ||
                                  showValidationHighlight)
                                  ? styles.inputErrorEnrollments
                                  : ''
                              }
                            >
                              <Input
                                className={styles.inputDiscount}
                                value={
                                  findInArray(
                                    selectedData?.[enrollment?.property],
                                    enrollment?.name,
                                    enrollment?.fieldType,
                                    enrollment?.fieldSubType
                                  )?.value ?? ''
                                }
                                onChange={(e: any) =>
                                  updateObject?.(
                                    enrollment?.property,
                                    e.target.value,
                                    true,
                                    {
                                      fieldSubType: enrollment?.fieldSubType,
                                      fieldType: enrollment?.fieldType,
                                      name: enrollment?.name,
                                    }
                                  )
                                }
                                suffix={'employees'}
                              />
                            </span>
                          )}
                        </Row>
                      </Row>
                    );
                  }
                )}
              </div>
            </div>
          )}

          {isMDOffer && (
            <DeductiblesSection
              updateObject={updateObject}
              benefitType={benefitType}
              selectedData={selectedData}
              isQuotePlansApproved={isQuotePlansApproved}
            />
          )}
          {isMDVOffer && (
            <MDVServicesSection
              updateObject={updateObject}
              benefitType={benefitType}
              selectedData={selectedData}
              setSelectedData={setSelectedData!}
              isQuotePlansApproved={isQuotePlansApproved}
            />
          )}
          {benefitType === OFFER_CATEGORY.MEDICAL && (
            <RxSection
              updateObject={updateObject}
              selectedData={selectedData}
              setSelectedData={setSelectedData!}
              isQuotePlansApproved={isQuotePlansApproved}
            />
          )}
          {!isMDVOffer && benefitType !== VOLUNTARY_BENEFIT && (
            <>
              <div className={styles.subTitle}>Plan Information</div>
              <Divider className={styles.divider} />
              <div className={styles.innerWrapper}>
                {Object.keys(
                  benefitType === 'STD' &&
                    fundingType === FundingType.FULLY_INSURED
                    ? EDIT_PLAN_TABLE['STD_FULLY_INSURED']?.INFO ?? {}
                    : benefitType === 'STD' &&
                      fundingType === FundingType.SELF_FUNDED
                    ? EDIT_PLAN_TABLE['STD_SELF_FUNDED']?.INFO ?? {}
                    : EDIT_PLAN_TABLE?.[benefitType?.toUpperCase()]?.INFO ?? {}
                ).map((item, index: number) => {
                  return (
                    <NonMDVInputs
                      key={index}
                      item={item}
                      type="INFO"
                      benefitType={benefitType}
                      fundingType={fundingType}
                      showErrorValidation={showErrorValidation}
                      updateObject={updateObject}
                      validate={validate}
                      disableADDRate={disableADDRate}
                      disableLifeRate={disableLifeRate}
                      selectedData={selectedData}
                      showRequired={showRequired}
                      showValidationHighlight={showValidationHighlight}
                      triggerAllValidations={triggerAllValidations}
                      triggerPlanNameValidation={triggerPlanNameValidation}
                      isQuotePlansApproved={isQuotePlansApproved}
                    />
                  );
                })}
              </div>
            </>
          )}

          {isAdvancedProfile &&
            !isMDVOffer &&
            renderLifeServiceRows(
              selectedData?.lifeServices,
              selectedData?.llmExtractionInfo?.lifeServices
            )}

          {allowedBenefitTypesAddService.includes(benefitType) &&
            isAdvancedProfile && (
              <LinkButton
                onClick={() => {
                  setShowLifeAddServiceModal(true);
                }}
              >
                + Add Service
              </LinkButton>
            )}

          <RenewalCustomServiceModal
            visible={showLifeAddServiceModal}
            onClose={() => {
              setShowLifeAddServiceModal(false);
            }}
            benefitKind={benefitType}
            onAddService={addLifeServices}
            planData={selectedData}
            renderRows={renderLifeServiceRows}
          />

          <div>
            <div className={styles.subTitle}>Plan Documents</div>
            <Divider className={styles.divider} />
            <PopulatableInputFileUpload
              isPopulatableDataExist={true}
              populatableData={data?.attachedDocuments}
              populatedDownload={
                handleFileItemDownloadClickForPopulatedFileItem
              }
              triggerDelete={setIsDeleteTriggered}
              getFileList={getFileList}
              uploadIconLabel={'+ Include Attachment'}
              isAllFilesAllowed={true}
              maxFileSizeMB={200}
              totalUploadSizeMB={200}
              onValidateFails={(validateSetting) => {
                openNotificationShow(validateSetting, 200, 200);
              }}
              isDropdownDisabled={isReferenceLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPlanForm;
