import {
  DV_PLANS,
  URL_PARAM_TO_BENEFIT_TYPE,
} from 'modules/renewals/constants/renewalsConstants';
import { CurrentPlanResponseType } from 'modules/renewals/types/currentPlanResponseTypes';

export const checkMDV = (benefitKind: any) => {
  return (
    URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind] ===
      URL_PARAM_TO_BENEFIT_TYPE?.medical ||
    URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind] ===
      URL_PARAM_TO_BENEFIT_TYPE?.dental ||
    URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind] ===
      URL_PARAM_TO_BENEFIT_TYPE?.vision
  );
};

export const checkDV = (benefitKind: string): boolean => {
  return DV_PLANS.includes(benefitKind.toUpperCase());
};

/**
 * Checks if there are no current plans.
 * @param {CurrentPlanResponseType} currentPlans - The current plans response object.
 * @return {boolean} A boolean indicating whether there are no current plans.
 */
export const isNoCurrentPlans = (
  currentPlans: CurrentPlanResponseType
): boolean => {
  return (
    (currentPlans?.currentPlans ?? null) === null ||
    Object?.keys(currentPlans?.currentPlans ?? {})?.length === 0
  );
};
