export const ENABLE_AI_ASSISTANT_WARNING =
  'The limit of available seats purchased by the broker has been surpassed, but this employer may still be enabled. Someone from the Sales Team will be in contact shortly.';

export const ENABLE_AI_HEADER = 'Enable AI Assistant';

export const CONTACT_SALES_HEADER = 'Contact Sales';

export const CONTACT_SALES_DESCRIPTION =
  'You have the option to notify our Sales Team to ask for an increase in user limits.';

export const CONTACT_SALES_SECONDARY_DESCRIPTION =
  'A team member will contact you shortly.';

export const EMPLOYER_SEATS = 'Employer seats available: ';

export const INCREASE_LIMIT = 'Need to increase the limit?';

export const PURCHASED_EMPLOYER = 'PURCHASED BY EMPLOYER';

export const EMP_FOOTER_NOTE =
  'Note: The feature that is directly purchased by the employer is not included in the broker’s total purchase.';

export const USER_SEATS = 'User seats available: ';

export const ENABLED_PREVIOUSLY_BROKER_TOOLTIP =
  'This broker user was enabled, but surpasses the limit of user seats available. Someone from the Sales Team will be in contact shortly.';

export const ENABLED_PREVIOUSLY_EMPLOYER_TOOLTIP =
  'This employer was enabled, but surpasses the limit purchased by the broker. Someone from the Sales Team will be in contact shorly.';

export const BROKER_ENABLE_WARNING =
  'The limit of available seats has been surpassed, but this user may still be enabled. Someone from the Sales Team will be in contact shortly.';

export const EMPLOYER_LIMIT_EXCEEDED =
  'The seat limit cannot exceed the employer limit.';
