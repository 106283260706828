import { cloneDeep, isEmpty, set, some } from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Row, Col, Popover } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { useAppSelector } from 'hooks/redux';
import InfoIconComponent from 'modules/plans/components/LLMInfoIconComponent/InfoIconComponent';
import NetworkTypeRow from 'modules/plans/components/NetworkTypeRow/NetworkTypeRow';
import { IN_NETWORK, OUT_OF_NETWORK } from 'modules/plans/constants';
import { isEmptyValue } from 'modules/plans/utils';
import PlanDeductibleOOPMax from 'model/PlanDeductibleOOPMax';
import PlanDeductibleOOPNetwork from 'model/PlanDeductibleOOPNetwork';
import MedicalPlan from 'model/plans/MedicalPlan';

import styles from './deductiblesOop.module.less';

type DeductiblesOOPMaxProps = {
  onChange: Function;
  isTouched: boolean;
  medicalPlan?: MedicalPlan;
  isReviewProp?: boolean;
};

type DeductibleOOPMaxValueMapping = {
  serviceLabel: string;
  inNetwork: PlanDeductibleOOPNetwork;
  outNetwork: PlanDeductibleOOPNetwork;
};

const DeductiblesOOPMax = forwardRef((props: DeductiblesOOPMaxProps, ref) => {
  const { onChange, isTouched, isReviewProp = false } = props;
  const plan = useAppSelector((state) => state.plan.plans);
  const medicalPlan = props.medicalPlan ? props.medicalPlan : plan.medicalPlan;
  const [deductibleOopMax, setDeductibleOopMax] = useState<
    DeductibleOOPMaxValueMapping[]
  >([]);
  const { extractionFinalized, textractJobId } = medicalPlan;

  const isHighlightVisible = !extractionFinalized;
  const isUploadedPlan: boolean = !!textractJobId && textractJobId !== '';
  useImperativeHandle(ref, () => ({
    validate,
  }));

  useEffect(() => {
    const { deductibles, outOfPocket, llmExtractionInfo } = medicalPlan;

    const individualDeductible = {
      serviceLabel: 'Individual Deductible',

      inNetwork: {
        covered: deductibles?.individualInNetworkApplicable,
        coveredAmount: deductibles?.individualInNetworkCost,
        valueMapping: {
          COVERAGE: 'deductibles.individualInNetworkApplicable',
          COST: 'deductibles.individualInNetworkCost',
        },
        llmExtractionInfo:
          llmExtractionInfo?.deductibles?.individualInNetworkCost,
      } as PlanDeductibleOOPNetwork,
      outNetwork: {
        covered: deductibles?.individualOutOfNetworkApplicable,
        coveredAmount: deductibles?.individualOutOfNetworkCost,
        valueMapping: {
          COVERAGE: 'deductibles.individualOutOfNetworkApplicable',
          COST: 'deductibles.individualOutOfNetworkCost',
        },
        llmExtractionInfo:
          llmExtractionInfo?.deductibles?.individualOutOfNetworkCost,
      } as PlanDeductibleOOPNetwork,
    };

    const familyDeductible = {
      serviceLabel: 'Family Deductible',
      inNetwork: {
        covered: deductibles?.familyInNetworkApplicable,
        coveredAmount: deductibles?.familyInNetworkCost,
        valueMapping: {
          COVERAGE: 'deductibles.familyInNetworkApplicable',
          COST: 'deductibles.familyInNetworkCost',
        },
        llmExtractionInfo: llmExtractionInfo?.deductibles?.familyInNetworkCost,
      } as PlanDeductibleOOPNetwork,
      outNetwork: {
        covered: deductibles?.familyOutOfNetworkApplicable,
        coveredAmount: deductibles?.familyOutOfNetworkCost,
        valueMapping: {
          COVERAGE: 'deductibles.familyOutOfNetworkApplicable',
          COST: 'deductibles.familyOutOfNetworkCost',
        },
        llmExtractionInfo:
          llmExtractionInfo?.deductibles?.familyOutOfNetworkCost,
      } as PlanDeductibleOOPNetwork,
    };

    const individualWithinFamilyDeductible = {
      serviceLabel: 'Ind. within Family Deductible',
      inNetwork: {
        covered: deductibles?.individualWithinFamilyInNetworkApplicable,
        coveredAmount: deductibles?.individualWithinFamilyInNetworkCost,
        valueMapping: {
          COVERAGE: 'deductibles.individualWithinFamilyInNetworkApplicable',
          COST: 'deductibles.individualWithinFamilyInNetworkCost',
        },
        llmExtractionInfo:
          llmExtractionInfo?.deductibles?.individualWithinFamilyInNetworkCost,
      } as PlanDeductibleOOPNetwork,
      outNetwork: {
        covered: deductibles?.individualWithinFamilyOutOfNetworkApplicable,
        coveredAmount: deductibles?.individualWithinFamilyOutOfNetworkCost,
        valueMapping: {
          COVERAGE: 'deductibles.individualWithinFamilyOutOfNetworkApplicable',
          COST: 'deductibles.individualWithinFamilyOutOfNetworkCost',
        },
        llmExtractionInfo:
          llmExtractionInfo?.deductibles
            ?.individualWithinFamilyOutOfNetworkCost,
      } as PlanDeductibleOOPNetwork,
    };

    const individualOopMax = {
      serviceLabel: 'Individual OOP Max',
      inNetwork: {
        covered: outOfPocket?.individualInNetworkApplicable,
        coveredAmount: outOfPocket?.individualInNetworkCost,
        valueMapping: {
          COVERAGE: 'outOfPocket.individualInNetworkApplicable',
          COST: 'outOfPocket.individualInNetworkCost',
        },
        llmExtractionInfo:
          llmExtractionInfo?.outOfPocket?.individualInNetworkCost,
      } as PlanDeductibleOOPNetwork,
      outNetwork: {
        covered: outOfPocket?.individualOutOfNetworkApplicable,
        coveredAmount: outOfPocket?.individualOutOfNetworkCost,
        valueMapping: {
          COVERAGE: 'outOfPocket.individualOutOfNetworkApplicable',
          COST: 'outOfPocket.individualOutOfNetworkCost',
        },
        llmExtractionInfo:
          llmExtractionInfo?.outOfPocket?.individualOutOfNetworkCost,
      } as PlanDeductibleOOPNetwork,
    };

    const familyOopMax = {
      serviceLabel: 'Family OOP Max',
      inNetwork: {
        covered: outOfPocket?.familyInNetworkApplicable,
        coveredAmount: outOfPocket?.familyInNetworkCost,
        valueMapping: {
          COVERAGE: 'outOfPocket.familyInNetworkApplicable',
          COST: 'outOfPocket.familyInNetworkCost',
        },
        llmExtractionInfo: llmExtractionInfo?.outOfPocket?.familyInNetworkCost,
      } as PlanDeductibleOOPNetwork,
      outNetwork: {
        covered: outOfPocket?.familyOutOfNetworkApplicable,
        coveredAmount: outOfPocket?.familyOutOfNetworkCost,
        valueMapping: {
          COVERAGE: 'outOfPocket.familyOutOfNetworkApplicable',
          COST: 'outOfPocket.familyOutOfNetworkCost',
        },
        llmExtractionInfo:
          llmExtractionInfo?.outOfPocket?.familyOutOfNetworkCost,
      } as PlanDeductibleOOPNetwork,
    };

    const individualWithinFamilyOopMax = {
      serviceLabel: 'Ind. within Family OOP Max',
      inNetwork: {
        covered: outOfPocket?.individualWithinFamilyInNetworkApplicable,
        coveredAmount: outOfPocket?.individualWithinFamilyInNetworkCost,
        valueMapping: {
          COVERAGE: 'outOfPocket.individualWithinFamilyInNetworkApplicable',
          COST: 'outOfPocket.individualWithinFamilyInNetworkCost',
        },
        llmExtractionInfo:
          llmExtractionInfo?.outOfPocket?.individualWithinFamilyInNetworkCost,
      } as PlanDeductibleOOPNetwork,
      outNetwork: {
        covered: outOfPocket?.individualWithinFamilyOutOfNetworkApplicable,
        coveredAmount: outOfPocket?.individualWithinFamilyOutOfNetworkCost,
        valueMapping: {
          COVERAGE: 'outOfPocket.individualWithinFamilyOutOfNetworkApplicable',
          COST: 'outOfPocket.individualWithinFamilyOutOfNetworkCost',
        },
        llmExtractionInfo:
          llmExtractionInfo?.outOfPocket
            ?.individualWithinFamilyOutOfNetworkCost,
      } as PlanDeductibleOOPNetwork,
    };

    setDeductibleOopMax([
      individualDeductible,
      familyDeductible,
      individualWithinFamilyDeductible,
      individualOopMax,
      familyOopMax,
      individualWithinFamilyOopMax,
    ]);
  }, [medicalPlan]);

  const onValueChange = (mapping: any, changes: []) => {
    const medicalPlanCopy = cloneDeep(medicalPlan);
    changes.forEach((change) => {
      const { key, value } = change;
      const path = mapping[key];
      set(medicalPlanCopy, path, value);
    });
    onChange(medicalPlanCopy);
  };

  const IndWithinFamDeductibleTooltipContent = () => (
    <div className={styles.popoverContent}>
      <>
        The portion of the family deductible each member must meet individually
      </>
    </div>
  );

  const IndWithinFamOopMaxTooltipContent = () => (
    <div className={styles.popoverContent}>
      <>
        The maximum each member pays as part of the family&apos;s total
        out-of-pocket limit
      </>
    </div>
  );

  const isInvalid = (data: DeductibleOOPMaxValueMapping[]): boolean => {
    return some(data, (item) => {
      if (
        (isEmpty(item.inNetwork.covered) ||
          item.inNetwork.covered === 'COVERED') &&
        isEmptyValue(item.inNetwork.coveredAmount)
      ) {
        return true;
      } else if (
        (isEmpty(item.outNetwork.covered) ||
          item.outNetwork.covered === 'COVERED') &&
        isEmptyValue(item.outNetwork.coveredAmount)
      ) {
        return true;
      }
      return false;
    });
  };

  const validate = async () => {
    return !isInvalid(deductibleOopMax);
  };

  return (
    <div className={styles.deductiblesOopWrapper}>
      <div className="text service-table-col-header">
        <Row>
          <>
            <Col span={10}></Col>
            <Col span={12}>Amount</Col>
          </>
        </Row>
      </div>

      {deductibleOopMax.map((row: PlanDeductibleOOPMax, index: number) => (
        <div key={index}>
          <Row align="middle" className={styles.deductiblesRow}>
            <InfoIconComponent
              showIcon={isUploadedPlan}
              extractionInfo={row?.inNetwork.llmExtractionInfo}
              className={styles.infoIcon}
            />
            <Col span={24}>
              <div className={styles.netWorkTitleRow}>
                {row.serviceLabel} &nbsp;
                {row.serviceLabel === 'Ind. within Family Deductible' ? (
                  <Popover
                    content={IndWithinFamDeductibleTooltipContent}
                    placement="right"
                    overlayClassName={styles.leftPopover}
                  >
                    <QuestionCircleOutlined className={styles.tooltipIcon} />
                  </Popover>
                ) : row.serviceLabel === 'Ind. within Family OOP Max' ? (
                  <Popover
                    content={IndWithinFamOopMaxTooltipContent}
                    placement="right"
                    overlayClassName={styles.leftPopover}
                  >
                    <QuestionCircleOutlined className={styles.tooltipIcon} />
                  </Popover>
                ) : (
                  ''
                )}
              </div>
            </Col>
          </Row>
          <div className={styles.networkTypeRow}>
            <NetworkTypeRow
              id={`${row.serviceLabel}_IN_NETWORK`}
              key={index}
              networkType={IN_NETWORK}
              networkDeductibleOOPObject={row.inNetwork}
              onChange={(changes: []) => {
                onValueChange(row.inNetwork.valueMapping, changes);
              }}
              isTouched={isTouched}
              decimalScale={0}
              isOnlyCovered
              isReviewProp={isReviewProp}
              highlightLLMValue={
                row?.inNetwork?.llmExtractionInfo?.different &&
                isHighlightVisible
              }
            />
          </div>

          <div className={styles.networkTypeRow}>
            <NetworkTypeRow
              id={`${row.serviceLabel}_OUT_OF_NETWORK`}
              key={index}
              networkType={OUT_OF_NETWORK}
              networkDeductibleOOPObject={row.outNetwork}
              onChange={(changes: []) => {
                onValueChange(row.outNetwork.valueMapping, changes);
              }}
              isTouched={isTouched}
              decimalScale={0}
              isOnlyCovered
              isReviewProp={isReviewProp}
              highlightLLMValue={
                row?.outNetwork?.llmExtractionInfo?.different &&
                isHighlightVisible
              }
            />
          </div>
        </div>
      ))}
    </div>
  );
});

DeductiblesOOPMax.displayName = 'DeductiblesOOPMax';

export default DeductiblesOOPMax;
