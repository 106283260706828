import { baseApi } from 'util/apiUtil';

const fileExportUrl = baseApi + '/v1/exports';

export const getExportPlanRatesUrl = (
  employerId: string,
  planYearId: string,
  benefitGroups: string[]
) => {
  const encodedBenefitGroups = benefitGroups.map((group) =>
    encodeURIComponent(group)
  );
  return (
    `${fileExportUrl}/${employerId}/plan-rates?employer-id=${employerId}` +
    `&plan-year-id=${planYearId}&benefit-groups=${encodedBenefitGroups.join(
      ','
    )}`
  );
};
