import { FC } from 'react';
import { useAppSelector } from 'hooks/redux';
import { DentalPlan } from 'model/plans/DentalPlan';
import DeductiblesOOPMax from './DeductiblesOOPMax';

type DeductiblesOOPMaxUPWrapperProps = {
  onChange: Function;
  isTouched: boolean;
  wrappedRef?: any;
};
const DeductiblesOOPMaxSBCWrapper: FC<DeductiblesOOPMaxUPWrapperProps> = (
  props
) => {
  const { onChange, isTouched, wrappedRef } = props;
  const { dentalPlan } = useAppSelector((state) => state.plan.dentalPlan);

  return (
    <DeductiblesOOPMax
      isEdit
      dentalPlan={dentalPlan || ({} as DentalPlan)}
      onChange={onChange}
      isTouched={isTouched}
      ref={wrappedRef}
    />
  );
};

export default DeductiblesOOPMaxSBCWrapper;
