import { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Form } from 'antd';
import isEmpty from 'lodash/isEmpty';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import CreateBrokerLocationForm from 'modules/brokers/components/CreateBrokerLocationForm/CreateBrokerLocationForm';
import { addBroker } from 'modules/brokers/slices/brokerBasicInfoSlice';
import BrokerLocation from 'model/BrokerLocation';
import Address from 'model/Address';
import {
  addBrokerLocationFormFields,
  basicFormFields,
  EMPTY_MESSAGE,
} from 'modules/brokers/constants/brokerConstants';
import { formVerificationMsg } from 'constants/commonConstants';
import AlertMessage from 'components/Alert/AlertMessage';

type BrokerLocationFormProps = {
  onClose?: (...args: any[]) => any;
  expanded: boolean;
  preLoadInPlaceId?: string | null;
  locationCount?: number | null;
  isEdit: boolean;
};

const BrokerLocationForm: FC<BrokerLocationFormProps> = (
  props: BrokerLocationFormProps
) => {
  const { onClose, expanded, preLoadInPlaceId, locationCount, isEdit } = props;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [duplicateLocationFound, setDuplicateLocationFound] =
    useState<boolean>(false);
  const [requireFieldErrorFound, setRequireFieldErrorFound] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);
  const [isDefault, setIsDefault] = useState<boolean>(false);

  const broker = useAppSelector(
    (state) => state.brokers.brokerBasicInfo.brokerObj
  );
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const onInputChange = async (changedValues: any, allValues: any) => {
    if (changedValues && changedValues.name && duplicateLocationFound) {
      if (!isLocationAlreadyAdded(changedValues.name, broker.locations)) {
        setDuplicateLocationFound(false);
      }
    }
    form.setFieldsValue(allValues);
    setFormData({ ...formData, ...allValues });
    if (isSaveClicked) {
      let isFormValid: boolean = true;
      try {
        await form.validateFields(addBrokerLocationFormFields.requiredFields);
      } catch (errorInfo) {
        if (!isEmpty(errorInfo.errorFields)) {
          isFormValid = false;
        }
      }
      if (isFormValid) {
        setRequireFieldErrorFound(false);
      }
    }
  };

  const isLocationAlreadyAdded = (
    name: string,
    locations: BrokerLocation[]
  ): boolean => {
    if (name !== undefined && locations !== undefined) {
      const location = locations.filter(
        (loc: BrokerLocation) =>
          loc.name !== undefined &&
          loc.name.trim().toLowerCase() === name.trim().toLowerCase()
      );
      if (location.length !== 0) {
        return true;
      }
    }
    return false;
  };

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const removePrimaryLocation = (otherLocations: BrokerLocation[]) => {
    return (
      otherLocations.map((location) =>
        location.primary ? { ...location, primary: false } : { ...location }
      ) || []
    );
  };

  const [formData, setFormData] = useState({
    inPlaceId: '',
    name: '',
    primary: false,
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
  });

  useEffect(() => {
    if (preLoadInPlaceId) {
      const filterByPreloadLocations = broker.locations?.filter(
        (t: BrokerLocation) =>
          t.inPlaceId.toLowerCase() === preLoadInPlaceId.toLowerCase()
      );
      if (filterByPreloadLocations && filterByPreloadLocations.length === 1) {
        const brokerLocationObj = filterByPreloadLocations[0];
        if (brokerLocationObj) {
          const data = {
            inPlaceId: brokerLocationObj.inPlaceId,
            name: brokerLocationObj.name,
            primary: false,
            addressLine1: brokerLocationObj.address.addressLine1,
            addressLine2: brokerLocationObj.address.addressLine2,
            city: brokerLocationObj.address.city,
            state: brokerLocationObj.address.state,
            zipCode: brokerLocationObj.address.zipCode,
          };
          setFormData(data);
          form.setFieldsValue({ name: brokerLocationObj.name });
          form.setFieldsValue({
            addressLine1: brokerLocationObj.address.addressLine1,
          });
          form.setFieldsValue({
            addressLine2: brokerLocationObj.address.addressLine2,
          });
          form.setFieldsValue({ city: brokerLocationObj.address.city });
          form.setFieldsValue({ state: brokerLocationObj.address.state });
          form.setFieldsValue({ zipCode: brokerLocationObj.address.zipCode });
          brokerLocationObj.primary ? setIsChecked(true) : setIsChecked(false);
          brokerLocationObj.primary ? setIsDefault(true) : setIsDefault(false);
          setEditMode(true);
          setRequireFieldErrorFound(false);
          setDuplicateLocationFound(false);
        }
      }
    } else {
      setEditMode(false);
      setIsChecked(false);
      form.resetFields(basicFormFields.formFields);
    }
  }, [expanded, preLoadInPlaceId, broker?.locations, form]);

  const onClickSave = async () => {
    let isFormValid: boolean = true;
    try {
      await form.validateFields(basicFormFields.requiredFields);
    } catch (errorInfo) {
      isFormValid = false;
    }
    setIsSaveClicked(true);

    if (isFormValid) {
      setIsSaveClicked(false);
      const {
        name,
        inPlaceId,
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode,
      } = formData;
      setRequireFieldErrorFound(false);
      setDuplicateLocationFound(false);
      const address = {
        addressLine1: addressLine1.trim(),
        addressLine2: addressLine2?.trim(),
        city: city.trim(),
        state,
        zipCode: zipCode?.trim(),
      } as Address;
      if (editMode) {
        const otherLocations = broker.locations.filter(
          (loc: BrokerLocation) => loc.inPlaceId !== inPlaceId
        );
        if (otherLocations) {
          if (isLocationAlreadyAdded(name, otherLocations)) {
            form.setFields([{ name: 'name', errors: [EMPTY_MESSAGE] }]);
            setDuplicateLocationFound(true);
            setAlertMessage('Location name already exists.');
          } else {
            const editLocationFilter = broker.locations.filter(
              (loc: BrokerLocation) => loc.inPlaceId === preLoadInPlaceId
            );
            if (editLocationFilter) {
              const editLocation = editLocationFilter[0];
              if (editLocation) {
                const newLoc = {
                  inPlaceId: editLocation.inPlaceId,
                  name: name.trim(),
                  address: address,
                  primary: isChecked,
                  organizationId: null,
                } as BrokerLocation;
                const brokerLocations = isChecked
                  ? removePrimaryLocation(broker.locations)
                  : [...broker.locations];
                const updatedBroker = {
                  ...broker,
                  locations: brokerLocations.map((location) => {
                    if (location.inPlaceId === inPlaceId) {
                      location = newLoc;
                    }
                    return location;
                  }),
                };
                dispatch(addBroker(JSON.parse(JSON.stringify(updatedBroker))));
                setEditMode(false);
                resetFormData();
                if (onClose) {
                  onClose();
                }
              }
            }
          }
        }
      } else {
        if (isLocationAlreadyAdded(name, broker.locations)) {
          form.setFields([{ name: 'name', errors: [EMPTY_MESSAGE] }]);
          setDuplicateLocationFound(true);
          setAlertMessage('Location name already exists.');
        } else {
          const additionalLocation = {
            inPlaceId: uuidv4(),
            name: name.trim(),
            address,
            primary: isChecked,
            organizationId: '',
          } as BrokerLocation;
          const otherLocations = isChecked
            ? removePrimaryLocation(broker.locations)
            : [...broker.locations];
          const updatedBroker = {
            ...broker,
            locations: [...otherLocations, additionalLocation],
          };
          dispatch(addBroker(JSON.parse(JSON.stringify(updatedBroker))));
          resetFormData();
          if (onClose) {
            onClose();
          }
        }
      }
    } else {
      setRequireFieldErrorFound(true);
      setAlertMessage(formVerificationMsg);
    }
  };

  const closeAlert = () => {
    setRequireFieldErrorFound(false);
    setDuplicateLocationFound(false);
  };
  const onReset = () => {
    resetFormData();
    setIsSaveClicked(false);
    if (onClose) {
      onClose();
    }
  };

  const resetFormData = () => {
    form.resetFields();
    setFormData({
      inPlaceId: '',
      name: '',
      primary: false,
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
    });
    setRequireFieldErrorFound(false);
    setDuplicateLocationFound(false);
  };

  return (
    <>
      {(duplicateLocationFound || requireFieldErrorFound) && (
        <AlertMessage
          type="error"
          message={alertMessage}
          closeAlert={closeAlert}
        />
      )}
      <CreateBrokerLocationForm
        form={form}
        onInputChange={onInputChange}
        expanded={expanded}
        isChecked={isChecked}
        handleOnChange={handleOnChange}
        onClickSave={onClickSave}
        onReset={onReset}
        isDirect={false}
        isEdit={isEdit}
        isDefaultLocation={locationCount === 1 ? true : isDefault}
        locationData={null}
      />
    </>
  );
};

export default BrokerLocationForm;
