import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, Input, Row } from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import {
  PROPOSAL_STATUS_FINALISED,
  SOLD_CONFIRMATION_ACTIONS_OPTIONS,
} from 'modules/renewals/constants/renewalsConstants';
import { ReactComponent as TimerRefreshIcon } from 'assets/images/icon-timer-refresh.svg';

import { loginTypes } from 'constants/authConstants';
import { useAppSelector } from 'hooks/redux';
import PageActionDropdown from 'components/PageActionDropdown/PageActionDropdown';
import {
  CLOSE_CONFIRM_TEXT,
  CLOSE_RENEWAL_WARNING_TEXT,
} from 'constants/commonConstants';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import { useLazyCloseRenewalQuery } from 'modules/renewals/slices/renewalsSlice';
import { useProposalStatusUpdateMutation } from 'modules/renewals/slices/proposalSlice';
import styles from './AfpBanner.module.less';

export type PlanYearDetail = {
  planYearId: string;
  planYearName: string;
  valid: boolean;
};

type AfpBannerProps = {
  isProposalAddToPlanYearVisible: boolean;
  overlapPlanYear: PlanYearDetail | undefined;
  soldConfirmCompleteData: any;
  finalApprovedProposalSummary: any;
  isEmployerSoldConfirmationSent: boolean;
  handleSoldConfirmationsDropDown: Function;
  addProposalToPlanYearModalOpen: Function;
  proposalId: string | undefined;
};

export const AfpBanner: FC<AfpBannerProps> = ({
  isProposalAddToPlanYearVisible,
  overlapPlanYear,
  soldConfirmCompleteData,
  finalApprovedProposalSummary,
  isEmployerSoldConfirmationSent,
  handleSoldConfirmationsDropDown,
  addProposalToPlanYearModalOpen,
  proposalId,
}) => {
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;
  const [userConfirmText, setUserConfirmText] = useState<string>('');
  const [isCloseModalVisible, setIsCloseModalVisible] =
    useState<boolean>(false);
  const { employerId } = useParams();
  const getInputClassName = () => {
    return !isEmpty(userConfirmText) && userConfirmText !== CLOSE_CONFIRM_TEXT
      ? styles.disableTextInputError
      : styles.adminUsersText;
  };

  const [closeRenewal, {}] = useLazyCloseRenewalQuery();
  const [finalizeProposal, {}] = useProposalStatusUpdateMutation();

  const onSubmit = () => {
    closeRenewal({
      planYearId: String(overlapPlanYear?.planYearId),
      employerId: String(employerId),
    }).then(() => {
      finalizeProposal({
        proposalId: proposalId ?? '',
        proposalStatus: PROPOSAL_STATUS_FINALISED,
      }).then(() => {
        window.location.reload();
      });
    });
  };

  return (
    <>
      {isProposalAddToPlanYearVisible && (
        <Row className={styles.reviewApprovedRow} wrap={false}>
          <Col>
            <TimerRefreshIcon className={styles.reviewApprovedIcon} />
          </Col>
          <Col flex={'auto'}>
            <Row className={styles.reviewApprovedHeading}>
              {isErAdmin
                ? 'Final Proposal in Review'
                : overlapPlanYear
                ? 'Pending Renewal'
                : 'Review Final Approved Proposal'}
            </Row>
            <Row>
              {isErAdmin ? (
                'The broker and carrier are in the process of finalizing the proposal for the new Plan Year. '
              ) : overlapPlanYear ? (
                <p className={styles.bannerDescription}>
                  You have an ongoing pending renewal for{' '}
                  <span className={styles.planYear}>
                    {overlapPlanYear.planYearName}
                  </span>
                  . If you’d like to start a new renewal, you must
                  <span
                    className={styles.closeRenewalButton}
                    onClick={() => setIsCloseModalVisible(true)}
                  >
                    {' '}
                    close the pending renewal
                  </span>{' '}
                  beforehand
                </p>
              ) : (
                'Next steps are to create a new Plan Year and add these plans to the system'
              )}
            </Row>
          </Col>
          {!isErAdmin ? (
            <Col className={styles.buttonRow}>
              <Row gutter={16} wrap={false}>
                <Col>
                  <PageActionDropdown
                    options={[
                      soldConfirmCompleteData
                        ? SOLD_CONFIRMATION_ACTIONS_OPTIONS.VIEW_CARRIER_SOLD_CONFIRMATION
                        : SOLD_CONFIRMATION_ACTIONS_OPTIONS.SEND_CARRIER_SOLD_CONFIRMATION,
                      finalApprovedProposalSummary?.employerSoldConfirmationSent ||
                      isEmployerSoldConfirmationSent
                        ? SOLD_CONFIRMATION_ACTIONS_OPTIONS.SEND_EMPLOYER_SOLD_CONFIRMATION_DISABLED
                        : SOLD_CONFIRMATION_ACTIONS_OPTIONS.SEND_EMPLOYER_SOLD_CONFIRMATION,
                    ]}
                    onChange={handleSoldConfirmationsDropDown}
                    label={'Send Confirmations'}
                    className={styles.reviewApprovedButton}
                    buttonClassName={styles.soldConfirmationButton}
                    dropdownClassName={styles.soldConfirmationDropDown}
                    manuallyDisabledVales={
                      finalApprovedProposalSummary?.employerSoldConfirmationSent ||
                      isEmployerSoldConfirmationSent
                        ? [
                            SOLD_CONFIRMATION_ACTIONS_OPTIONS
                              .SEND_EMPLOYER_SOLD_CONFIRMATION_DISABLED.value,
                          ]
                        : []
                    }
                    popOverContents={
                      finalApprovedProposalSummary ? (
                        <div className={styles.popOver}>
                          <p className={styles.sentBy}>
                            Sent{' '}
                            {dayjs(
                              finalApprovedProposalSummary?.employerSoldConfirmationSentTS
                            ).format('MMM D, YYYY')}
                          </p>
                          <p className={styles.sentAt}>
                            by{' '}
                            {
                              finalApprovedProposalSummary?.employerSoldConfirmationSentBy
                            }
                          </p>
                        </div>
                      ) : (
                        ''
                      )
                    }
                  />
                </Col>

                <Col>
                  <Button
                    size="large"
                    className={styles.reviewApprovedButton}
                    onClick={() => {
                      addProposalToPlanYearModalOpen();
                    }}
                  >
                    Add Proposal to New Plan Year
                  </Button>
                </Col>
              </Row>
            </Col>
          ) : null}
        </Row>
      )}
      {isCloseModalVisible && (
        <ConfirmationDialog
          confirmBtnFullWidth
          width={600}
          centered
          visible={isCloseModalVisible}
          disableConfirmButton={userConfirmText !== CLOSE_CONFIRM_TEXT}
          isCancelLink
          onConfirm={() => {
            if (userConfirmText === CLOSE_CONFIRM_TEXT) {
              onSubmit();
              setUserConfirmText('');
              setIsCloseModalVisible(false);
            }
          }}
          closeModal={() => {
            setUserConfirmText('');
            setIsCloseModalVisible(false);
          }}
          title={<>Close Pending Renewal</>}
          confirmText="Close Pending Renewal & Start New Renewal"
          cancelText="Cancel"
          destroyOnClose
        >
          <FixedAlertMessage
            wrapperClassName={styles.cloneMessageBox}
            className={styles.textMessage}
            message={
              <p>
                {CLOSE_RENEWAL_WARNING_TEXT}
                <span className={styles.planYear}>
                  {overlapPlanYear?.planYearName}
                </span>
              </p>
            }
            type="error"
          />
          <b>Type “CLOSE” in the box below to confirm</b>
          <Input
            value={userConfirmText}
            className={getInputClassName()}
            onChange={(e) => {
              setUserConfirmText(e.target.value);
            }}
          />
          {!isEmpty(userConfirmText) &&
            userConfirmText !== CLOSE_CONFIRM_TEXT && (
              <div className={styles.invalidInput}>Invalid Input</div>
            )}
        </ConfirmationDialog>
      )}
    </>
  );
};
