import { FC } from 'react';
import { Button } from 'antd';

import { NativeButtonProps } from 'antd/lib/button/button';

import styles from './pageActionButton.module.less';

interface PageActionButtonProps extends NativeButtonProps {
  className?: string;
  dataCy?: string;
  disabled?: boolean;
  isAdditionalFileUploader?: boolean;
}

const PageActionButton: FC<PageActionButtonProps> = (props) => {
  const {
    className = styles.pageActionBtn,
    dataCy,
    disabled = false,
    isAdditionalFileUploader,
    ...rest
  } = props;

  return (
    <>
      {!isAdditionalFileUploader && (
        <div className={styles.pageActionBtnWrapper}>
          <Button
            disabled={disabled}
            className={className}
            {...rest}
            data-cy={dataCy}
          />
        </div>
      )}
      {isAdditionalFileUploader && (
        <div className={styles.pageAdditionalActionBtnWrapper}>
          <Button
            disabled={disabled}
            className={styles.additionalPageActionBtn}
            {...rest}
            data-cy={dataCy}
          />
        </div>
      )}
    </>
  );
};

export default PageActionButton;
