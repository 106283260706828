// Form validations
export const EMPLOYER_NAME_REQUIRED = 'Employer Name is Required';
export const ADDRESS_1_REQUIRED = 'Address Line 1 is Required';
export const CITY_REQUIRED = 'City is Required';
export const STATE_REQUIRED = 'State is Required';
export const ZIP_REQUIRED = 'Zip is Required';
export const EMPLOYER_LOGO_REQUIRED = 'Employer Logo is Required';
export const PLAN_YEAR_REQUIRED = 'Plan Year Name and dates are Required.';
export const EMPTY_MESSAGE = '';

export const planYearFormFields = {
  requiredFields: ['planYear', 'startDate', 'endDate'],
  formFields: ['planYear', 'startDate', 'endDate'],
};

export const addERAdminFormFields = {
  requiredFields: [
    'email',
    'firstName',
    'lastName',
    'phone',
    'individualSubType',
  ],
};

export const basicInfoFormFields = {
  requiredFields: [
    'managingBroker',
    'locations',
    'employerName',
    'addressLine1',
    'city',
    'zip',
    'state',
    'logoUrl',
    'emailDomains',
  ],
};

export const EMPLOYER_ADMINS_TAB = 'EMPLOYER_ADMINS_TAB';
export const PLAN_YEARS_TAB = 'PLAN_YEARS_TAB';
export const BENEFIT_CLASSES_TAB = 'BENEFIT_CLASSES_TAB';
export const BASIC_INFO_TAB = 'BASIC_INFO_TAB';
export const BROKER_ADMINS_TAB = 'BROKER_ADMINS_TAB';
export const BROKER_LOCATIONS_TAB = 'BROKER_LOCATIONS_TAB';
// ER admin error codes
export const ER_ADMIN_REMOVE_ERROR =
  'There must be at least one Employer Admin for this Employer.';
export const PRIMARY_CONTACT_REMOVE_ERROR =
  'There must be a primary contact for this employer. Please create or assign another primary contact before removing this one.';
export const PRIMARY_CONTACT_EMPTY_ERROR =
  'There must be at least one  primary contact for this employer';
export const ER_ADMIN_EMPTY_ERROR =
  'There must be at least one employer admin associated with this employer ';

// Benefit Class error codes
export const EMPLOYER_GROUP_ALREADY_ATTACHED_TO_A_PLAN =
  'employer.group.already.attached.to.a.plan';
export const BENEFIT_CLASS_ALREADY_ATTACHED_TO_A_BENGUIDE =
  'employer.group.attached.to.published.benguide';

export const DUPLICATE_DATA = 'duplicate.data';

export const INVALID_BENEFIT_CLASS_CHARACTERS = [
  '/',
  ':',
  '*',
  '?',
  '"',
  '<',
  '>',
  '|',
  '\\',
];

// TODO
export const LUMITY_ORGANIZATION_ID = 'edb4be90-96dc-43a9-bb48-43b67869ab8f';

// Dashboard billing claim chart constants
export const POS_DATA_X = -1000;
export const POS_DATA_Y = -10000;

export const BILLING_CLAIM_X = 10;
export const BILLING_CLAIM_TXT_X = 10;
export const BILLING_CLAIM_Y = 15;
export const BILLING_CLAIM_TXT_Y = 35;

// Employer User Label

export const EMPLOYER_USERS = 'Employer Users';
export const EMPLOYER_USER = 'Employer User';
