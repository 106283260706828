export const ServiceTypeDisplayNames: Record<string, string> = {
  ACCELERATED_LIFE_BENEFIT: 'Accelerated Life Benefit',
  GUARANTEED_ISSUE: 'Guaranteed Issue',
  PORTABILITY: 'Portability',
  WAIVER_OF_PREMIUM: 'Waiver of Premium',
  DEFINITION_OF_EARNINGS: 'Definition of Earnings',
  OTHER: 'Other',
  WAITING_PERIOD_INJURY: 'Waiting Period Injury',
  WAITING_PERIOD_ILLNESS: 'Waiting Period Illness',
  SOCIAL_SECURITY_INTEGRATION: 'Social Security Integration',
  W2_PREPARATION: 'W-2 Preparation',
  FICA_MATCH: 'FICA Match',
  RECURRENT_DISABILITY_PROVISION: 'Recurrent Disability Provision',
  CONTINUITY_OF_COVERAGE: 'Continuity of Coverage',
  CONVERTIBILITY: 'Convertibility',
  MIN_MONTHLY_BENEFIT: 'Min Monthly Benefit',
  PRE_EXISTING_CONDITIONS: 'Pre-Existing Conditions',
  STATE_INTEGRATION: 'State Integration',
  REHABILITATION_BENEFIT: 'Rehabilitation Benefit',
  WORKSITE_MODIFICATION_BENEFIT: 'Worksite Modification Benefit',
  RETURN_TO_WORK: 'Return to Work',
  EARNINGS_TEST: 'Earnings Test',
  DISABILITY_LIMITATIONS_MENTAL_HEALTH: 'Disability Limitations-Mental Health',
  DISABILITY_LIMITATIONS_SUBSTANCE_ABUSE:
    'Disability Limitations-Substance Abuse',
  DISABILITY_LIMITATIONS_SELF_REPORTED: 'Disability Limitations-Self-Reported',
  EAP_OFFERED: 'EAP Offered',
};

export const getDisplayName = (type: string): string => {
  return ServiceTypeDisplayNames[type];
};

export const ServiceTypeDisplayNamesCamelCase: Record<string, string> = {
  ACCELERATED_LIFE_BENEFIT: 'acceleratedLifeBenefit',
  GUARANTEED_ISSUE: 'guaranteedIssue',
  PORTABILITY: 'portability',
  WAIVER_OF_PREMIUM: 'waiverOfPremium',
  DEFINITION_OF_EARNINGS: 'definitionOfEarnings',
  OTHER: 'other',
  WAITING_PERIOD_INJURY: 'waitingPeriodInjury',
  WAITING_PERIOD_ILLNESS: 'waitingPeriodIllness',
  SOCIAL_SECURITY_INTEGRATION: 'socialSecurityIntegration',
  W2_PREPARATION: 'w2Preparation',
  FICA_MATCH: 'ficaMatch',
  RECURRENT_DISABILITY_PROVISION: 'recurrentDisabilityProvision',
  CONTINUITY_OF_COVERAGE: 'continuityOfCoverage',
  CONVERTIBILITY: 'convertibility',
  MIN_MONTHLY_BENEFIT: 'minMonthlyBenefit',
  PRE_EXISTING_CONDITIONS: 'preExistingConditions',
  STATE_INTEGRATION: 'stateIntegration',
  REHABILITATION_BENEFIT: 'rehabilitationBenefit',
  WORKSITE_MODIFICATION_BENEFIT: 'worksiteModificationBenefit',
  RETURN_TO_WORK: 'returnToWork',
  EARNINGS_TEST: 'earningsTest',
  DISABILITY_LIMITATIONS_MENTAL_HEALTH: 'disabilityLimitationsMentalHealth',
  DISABILITY_LIMITATIONS_SUBSTANCE_ABUSE: 'disabilityLimitationsSubstanceAbuse',
  DISABILITY_LIMITATIONS_SELF_REPORTED: 'disabilityLimitationsSelfReported',
  EAP_OFFERED: 'eapOffered',
};

export const getDisplayNameCamelCase = (type: string): string => {
  return ServiceTypeDisplayNamesCamelCase[type];
};
