import { useParams } from 'react-router-dom';
import { ChangeEvent } from 'react';
import { Col, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import {
  CAP,
  FIXED_ALERT_MESSAGE_IF_FINAL_APPROVED,
  GUARANTEED,
  OFFER_BENEFIT_TYPE_NAVIGATION_LABELS,
} from 'modules/renewals/constants/renewalsConstants';
import NextButton from 'components/buttons/NextButton/NextButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import TextButton from 'components/buttons/TextButton/TextButton';

import styles from './creditsAndRates.module.less';

type CreditsAndRates = {
  nextStep?: () => void;
  saveAsDraft: () => void;
  offer: any;
  updateOffer: (offer: any) => void;
  offerAttachedProposal?: any;
  saveAsDraftLink: Function;
  isOfferCreateLoading: boolean;
  currentStep: number;
  changeCurrentStep: (step: number) => void;
};

const CreditsAndRates = (props: CreditsAndRates) => {
  const {
    nextStep,
    offer,
    updateOffer,
    offerAttachedProposal,
    saveAsDraftLink,
    isOfferCreateLoading,
    currentStep,
    changeCurrentStep,
  } = props;

  const params = useParams();

  const onChangeField = (event: ChangeEvent) => {
    const { name, value } = event.target as any;

    if (name === 'rateType' && value === GUARANTEED) {
      updateOffer({ ...offer, [name]: value, rateCap: '' });
    } else if (name === 'rateType' && value === CAP) {
      updateOffer({ ...offer, [name]: value, rateGuarantee: '' });
    } else if (name === 'rateCap') {
      updateOffer({
        ...offer,
        [name]: Number(value) > 100 ? offer?.rateCap : value,
      });
    } else {
      updateOffer({
        ...offer,
        [name]: value,
      });
    }
  };

  const onChangeRate = (value: string) => {
    updateOffer({
      ...offer,
      rate: value,
    });
  };

  const handleBack = () => changeCurrentStep(currentStep - 1);

  return (
    <div className={styles.creditsAndRatesWrapper}>
      {offerAttachedProposal?.isFinalApproved ? (
        <div className={styles.finalAlertWrapper}>
          <FixedAlertMessage
            type="info"
            message={FIXED_ALERT_MESSAGE_IF_FINAL_APPROVED}
          />
        </div>
      ) : null}
      <div className={styles.inputContainer}>
        {params?.benefitKind !==
          OFFER_BENEFIT_TYPE_NAVIGATION_LABELS.VOLUNTARY_BENEFIT && (
          <>
            <div className={styles.title}>
              Credit <span>(optional)</span>
            </div>
            <Row>
              <Col span={3}>
                <div className={styles.inputLabel}>
                  <label>Credit Amount</label>
                </div>
              </Col>
              <Col span={20} offset={1}>
                <div className={styles.inputLabel}>
                  <label>Credit Description</label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={3}>
                <NumberFormatInput
                  prefix="$"
                  name="creditAmount"
                  thousandSeparator={true}
                  decimalScale={2}
                  allowNegative={false}
                  value={offer.creditAmount}
                  onChange={onChangeField}
                />
              </Col>
              <Col span={20} offset={1}>
                <TextArea
                  className={styles.description}
                  name="creditDescription"
                  value={offer.creditDescription}
                  onChange={onChangeField}
                />
              </Col>
            </Row>
          </>
        )}
        <div className={styles.rateDetails}>
          <div className={styles.title}>
            Rate Details <span>(optional)</span>
          </div>
          <Row>
            <div className={styles.rateInputWrapper}>
              <div className={styles.rateInputLabel}>
                Enter Rate Cap or Rate Guarantee
              </div>
              <Input
                value={offer?.rate?.toString()}
                onChange={(e) => onChangeRate(e.target.value)}
              />
            </div>
          </Row>
        </div>
      </div>

      {/*  Next and Back section */}
      <Row className={styles.btnRow} gutter={16}>
        <Col className={styles.backBtnWrapper} span={8}>
          <SubmitButton
            className={styles.cancelButton}
            onClick={() => handleBack()}
          >
            Back
          </SubmitButton>
        </Col>
        <Col span={8}>
          <NextButton
            nextStep={() => {
              nextStep && nextStep();
            }}
            className={styles.nextBtn}
            disabled={isOfferCreateLoading}
          />
        </Col>
        <Col span={24} className={styles.saveDraftBtn}>
          <TextButton
            label="Save Draft & Close"
            disabled={isOfferCreateLoading}
            onClick={() => {
              saveAsDraftLink();
            }}
            type="primary"
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreditsAndRates;
