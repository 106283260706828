import { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { useParams } from 'react-router-dom';

import { isEmpty } from 'lodash';
import { Spin } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ReactComponent as RFPLOADED } from 'assets/images/rfp-answer-generated-check.svg';

import {
  advancedOptimizeAnswer,
  setIncludedChunks,
  setUserEnteredContext,
} from 'modules/rfp/slices/rfpQuestionSlice';
import {
  ADVANCED_FLOW_WARNING,
  AI_SIMILAR_RESPONSE,
  PROVIDE_SENTENCES_TEXT,
} from 'modules/rfp/constants/RfpConstants';
import { ReactComponent as RfpIconSelected } from 'assets/images/Rfp-ai-selected.svg';

import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import ChunkArea from './ChunkArea/ChunkArea';
import styles from './draftNewResponse.module.less';

type DraftNewResponseProps = {
  isShowAlert?: boolean;
  index: number;
  uuid: string;
};

export type ChunkType = {
  id: string;
  included: boolean;
};

const DraftNewResponse = forwardRef((props: DraftNewResponseProps, ref) => {
  const { index, uuid, isShowAlert } = props;
  const { brokerId } = useParams();
  const dispatch = useAppDispatch();

  const context = useAppSelector(
    (state) => state.rfp.rfpQuestions[index].context
  );
  const selectedQuestion = useAppSelector((state) => state.rfp.selectedIndex);
  const selectedRfpId = useAppSelector((state) => state.rfp?.selectedRfpId);
  const questions = useAppSelector((state) => state?.rfp?.rfpQuestions);

  const userEntered = useAppSelector(
    (state) => state.rfp.rfpQuestions[selectedQuestion].userEnteredContext
  );
  const [addMoreTextField, setAddmoreTextField] = useState<string>(
    userEntered! || ''
  );

  const contextArray = Object.values(context ?? {});

  const trainData = useAppSelector((state) => state.rfp.trainData);

  const docIds = context.map((item: any) => item?.documentId);

  const trainIds = trainData.map((item: any) => item?.id);

  const filteredTrainIds = trainIds.filter((trainId) =>
    docIds.includes(trainId)
  );

  const [chunkIdValues, setChunkIdValues] = useState<any[]>(
    context
      .filter(
        (item: any) =>
          filteredTrainIds.includes(item?.documentId) && item?.included
      )
      .map((item: any) => item?.chunkId)
  );

  useEffect(() => {
    dispatch(setIncludedChunks(chunkIdValues));
    // eslint-disable-next-line
  }, [chunkIdValues, selectedQuestion]);

  const question = useAppSelector(
    (state) => state.rfp.rfpQuestions[index].question
  );

  const removeDuplicates = (array: any) => {
    return array.filter((value: any, index: any, self: any) => {
      return self.indexOf(value) === index;
    });
  };

  useImperativeHandle(ref, () => ({
    submitDraftResponse() {
      dispatch(
        advancedOptimizeAnswer(
          brokerId!,
          question,
          removeDuplicates(chunkIdValues),
          addMoreTextField,
          uuid,
          selectedRfpId
        )
      );
      dispatch(
        setUserEnteredContext({
          index: selectedQuestion,
          value: addMoreTextField,
        })
      );
      setAddmoreTextField('');
      setChunkIdValues([]);
    },
  }));

  return (
    <div className={styles.draftNewResponseModal}>
      <div className={styles.draftNewResponseWrapper}>
        <div className={styles.loadedCheckIcon}>
          {questions[index]?.isPending || isEmpty(questions[index]?.answer) ? (
            <Spin size="small" />
          ) : (
            <RFPLOADED />
          )}
        </div>
        <hr />
        <div className={styles.questionWrapper}>
          <div className={styles.questionTag}>Question</div>
          <TextArea
            contentEditable={false}
            className={styles.currentQuestionText}
            rows={3}
            value={questions[selectedQuestion]?.question}
          />
        </div>
        {isShowAlert && (
          <div className={styles.warningMessageAdvanced}>
            <FixedAlertMessage type="warning" message={ADVANCED_FLOW_WARNING} />
          </div>
        )}
        <hr />
        <div className={styles.subTitle}>
          <RfpIconSelected />
          Improve AI Response Quality
        </div>
        <p>{PROVIDE_SENTENCES_TEXT}</p>
        <TextArea
          rows={3}
          value={addMoreTextField}
          onChange={(e) => {
            dispatch(
              setUserEnteredContext({
                index: selectedQuestion,
                value: e.target.value,
              })
            );
            setAddmoreTextField(e.target.value);
          }}
          className={styles.addMoreContextField}
        />
        <div className={styles.similarResponseText}>{AI_SIMILAR_RESPONSE}</div>
        <div>
          {contextArray.slice(0, 3).map((item: any, index: number) => {
            return (
              <div key={index}>
                <ChunkArea
                  isIncludedIn={item?.included}
                  documentId={item?.documentId}
                  questionNumber={selectedQuestion}
                  contexts={context}
                  chunkIdValues={chunkIdValues}
                  setChunkIdValues={setChunkIdValues}
                  key={index}
                  chunkId={item?.chunkId}
                  text={item?.text}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

DraftNewResponse.displayName = 'DraftNewResponse';

export default DraftNewResponse;
