import { useRef, useState } from 'react';
import { Col, Menu, Row, Skeleton, Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import DataTable from 'components/DataTable/DataTable';
import { DataColumn } from 'components/DataTable/DataColumn';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import AssociatedBenguideModal from 'modules/plans/components/AssociatedBenguideModal/AssociatedBenguideModal';
import ClonePlanModal from 'modules/plans/components/ClonePlanModal/ClonePlanModal';
import PlanYear from 'model/PlanYear';
import TablePagination from 'model/TablePagination';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import AlertMessage from 'components/Alert/AlertMessage';
import {
  BasicPlans,
  PLAN_DELETE_FAILED_HSA_HRA_DEPENDENCIES,
} from 'modules/plans/constants';
import { SelectedPlanProps } from 'modules/plans/components/AddPlanModal/AddPlanModal';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  MEDICAL,
  DENTAL,
  VISION,
} from 'modules/plans/planRates/pages/PlanRates/PlanRates';
import { deletePlan } from 'modules/plans/slices/planSllice';
import { useNavContext } from 'hooks/useNavContext';
import {
  EMPLOYER_MEMBER_RESTRICTED,
  RENEWALS_STARTED_WARNING,
  mobilePlanEnrollmentWarning,
} from 'constants/commonConstants';
import {
  fetchHRAHSAdeps,
  getPlanEnrollment,
  planHsaHraCheckClear,
} from 'modules/plans/slices/planEnrollmentSlice';
import { getSubtypeAvailability } from 'modules/plans/utils';
import { DocumentExtractionSource } from 'modules/plans/enums/DocumentExtractionSource';
import { ReactComponent as AIFlashIcon } from 'assets/images/ai-flash-white.svg';
import styles from './plansList.module.less';

type PlansListProps = {
  planYear: PlanYear | null;
  planList: any;
  inProgress: boolean;
  limit: number;
  hideCarrier?: boolean;
  paginationConfig: any;
  getData: (config: TablePagination) => void;
  handleEdit?: (plan: any) => Promise<void>;
  goToOverviewPage?: (plan: any) => void;
  benefitCategory: string;
  onSaveClose: Function;
  isRenewalProcessStarted?: boolean;
  setAddPlansPropsToState?: (props: SelectedPlanProps) => void;
};

const MDVPlan = [MEDICAL, DENTAL, VISION];

const PlansList = (props: PlansListProps) => {
  const {
    planYear,
    planList,
    inProgress,
    limit,
    paginationConfig,
    getData,
    goToOverviewPage,
    benefitCategory,
    onSaveClose,
    isRenewalProcessStarted,
    setAddPlansPropsToState,
  } = props;

  const dispatch = useAppDispatch();
  const { inProgress: deletingPlan, error } = useAppSelector(
    (state) => state.plan.dbg.deletePlan
  );
  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );
  const { employerId } = useNavContext();

  const tableRef = useRef<any>(null);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [benefitGuideIdList, setBenefitGuideIdList] = useState<[]>([]);
  const [visibleCloneModal, setVisibleCloneModal] = useState<boolean>(false);
  const [visiblePlanDeleteModal, setVisiblePlanDeleteModal] =
    useState<boolean>(false);
  const [visiblePlanDeleteDBGModal, setVisiblePlanDeleteDBGModal] =
    useState<boolean>(false);
  const [planSccessfullyDeleted, setPlanSccessfullyDeleted] =
    useState<boolean>(false);
  const [planFailedDeleted, setPlanFailedDeleted] = useState<boolean>(false);
  const [plan, setPlan] = useState();
  const [planName, setPlanName] = useState<string>('');

  const { content, metadata } = planList;
  const total = metadata ? metadata.total : '0';

  const {
    planEnrollmentObj,
    hasHraHsaDependencies,
    inProgress: enrollmentsLoading,
  } = useAppSelector((state) => state.plan.planEnrollment);

  const checkHsaHraDependencies = async (planId: string) => {
    dispatch(planHsaHraCheckClear());
    await dispatch(fetchHRAHSAdeps({ planId: planId }));
  };

  const enrollmentChecking = async (plan: any) => {
    setPlanName(plan.name);
    setPlan(plan);
    if (MDVPlan.includes(benefitCategory)) {
      await checkHsaHraDependencies(plan.id);
    }
    setVisiblePlanDeleteModal(true);
    await dispatch(getPlanEnrollment({ planId: plan.id }));
    if (MDVPlan.includes(benefitCategory)) {
      await checkHsaHraDependencies(plan.id);
    }
  };
  const appBootInfo = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo
  );
  const isPermitted = getSubtypeAvailability(
    EMPLOYER_MEMBER_RESTRICTED,
    appBootInfo
  );

  const dataColumns: DataColumn[] = [
    {
      title: 'PLAN NAME',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      render: (plan) => renderPlan(plan),
    },
    {
      title: 'BENEFIT CLASSES',
      dataIndex: 'groups',
      key: 'groups',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      render: (classes) => (
        <span>
          <OverflowPopover popoverContent={classes?.join(', ')}>
            {classes?.join(', ')}
          </OverflowPopover>
        </span>
      ),
    },
    {
      title: 'CARRIER',
      dataIndex: 'benefitCarrier',
      key: 'benefitCarrier',
      width: '12%',
      ellipsis: {
        showTitle: false,
      },
      render: (benefitCarrier) => (
        <OverflowPopover popoverContent={benefitCarrier}>
          {benefitCarrier}
        </OverflowPopover>
      ),
    },
    {
      title: 'PLAN YEAR',
      dataIndex: 'planYear',
      key: 'planYear',
      width: '9%',
      ellipsis: {
        showTitle: false,
      },
      render: (planYear) => (
        <OverflowPopover popoverContent={planYear}>{planYear}</OverflowPopover>
      ),
    },
    {
      title: 'GROUP ID / POLICY #',
      dataIndex: 'groupId',
      key: 'groupId',
      width: '12%',
      ellipsis: {
        showTitle: true,
      },
      render: (plan) => renderGroupId(plan),
    },
    {
      title: 'ASSOCIATED BENEFITS GUIDE',
      dataIndex: 'benefitGuides',
      key: 'benefitGuides',
      width: '15%',
      render: (benefitGuides) => associatedBenguides(benefitGuides),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: '10%',
      render: (colData) => (
        <SelectDropdown
          overlay={
            isPermitted
              ? getActionMenu(colData)
              : getEmployerActionMenu(colData)
          }
        />
      ),
    },
  ];

  const handleUpdatePlanParameters = (plan: any) => {
    if (!plan) return;
    const { id, name } = plan;
    setAddPlansPropsToState?.({
      isOpen: true,
      docExtractionSource: {
        source: DocumentExtractionSource.UPDATE_SYSTEM_PLAN,
        planId: id,
        planName: name,
        updatedPlan: plan,
      },
      subType: plan?.benefitKind,
    });
  };

  const isLifeOrMDVPlan = (plan: any, benefitCategory: string) => {
    const isLifePlan =
      plan?.benefitKind && Object.keys(BasicPlans).includes(plan.benefitKind);
    const isMDVPlan = MDVPlan.includes(benefitCategory);
    return isLifePlan || isMDVPlan;
  };

  const getActionMenu = (plan: any) => {
    return (
      <Menu>
        {isLifeOrMDVPlan(plan, benefitCategory) && (
          <Menu.Item
            disabled={isOpsAdmin}
            onClick={() => handleUpdatePlanParameters(plan)}
          >
            <AIFlashIcon /> Update Plan Parameters
          </Menu.Item>
        )}
        <Menu.Item
          className={
            isLifeOrMDVPlan(plan, benefitCategory)
              ? styles.actionMenuNoIcon
              : ''
          }
          onClick={() => goToOverviewPage && goToOverviewPage(plan)}
        >
          Plan Details
        </Menu.Item>
        <Menu.Item
          className={
            isLifeOrMDVPlan(plan, benefitCategory)
              ? styles.actionMenuNoIcon
              : ''
          }
          onClick={() => {
            setPlan(plan);
            setVisibleCloneModal(true);
          }}
        >
          Clone
        </Menu.Item>
        <Menu.Item
          className={
            isLifeOrMDVPlan(plan, benefitCategory)
              ? styles.actionMenuNoIcon
              : ''
          }
          onClick={() => {
            enrollmentChecking(plan);
          }}
        >
          Delete Plan
        </Menu.Item>
      </Menu>
    );
  };

  const getEmployerActionMenu = (plan: any) => {
    return (
      <Menu>
        <Menu.Item onClick={() => goToOverviewPage && goToOverviewPage(plan)}>
          Plan Details
        </Menu.Item>
      </Menu>
    );
  };

  const convertPlansForTableRender = (plans: any[]): any[] => {
    return (
      plans?.map((plan) => ({
        id: plan.id,
        name: plan,
        groups: plan.groups,
        benefitCarrier: plan.benefitCarrier?.name || '-',
        planYear: planYear?.name,
        benefitGuides: plan?.benGuideIDRevisions,
        groupId: plan,
        actions: plan,
      })) || []
    );
  };

  const renderPlan = (plan: any) => {
    return (
      <a onClick={() => goToOverviewPage && goToOverviewPage(plan)}>
        <OverflowPopover popoverContent={plan.name}>
          <span className={styles.planName}>{plan.name}</span>
        </OverflowPopover>
      </a>
    );
  };

  const renderGroupId = (plan: any) => {
    return (
      <OverflowPopover popoverContent={plan.groupId}>
        {plan.groupId ? plan.groupId : '-'}
      </OverflowPopover>
    );
  };

  const associatedBenguides = (benguides: any) => {
    if (!isEmpty(benguides)) {
      return (
        <div className={styles.linkBtnWrapper}>
          <LinkButton
            onClick={() => {
              setBenefitGuideIdList(benguides);
              setModalVisible(true);
            }}
          >{`${benguides.length} ${
            benguides?.length === 1 ? 'Guide' : 'Guides'
          } `}</LinkButton>
        </div>
      );
    }
    return <span className={styles.emptyGuideWrapper}>{'0 Guides'}</span>;
  };

  const deleteSelectedPlan = (isIgnoreHRAHSADependencies: boolean) => {
    const selectedPlan = plan as any;
    if (isEmpty(selectedPlan?.benGuideIDRevisions)) {
      dispatch(
        deletePlan(
          employerId || '',
          selectedPlan.id,
          selectedPlan.benefitKind ? selectedPlan.benefitKind : benefitCategory,
          (isFailed: boolean) => {
            tableRef?.current.reload();
            setVisiblePlanDeleteModal(false);
            if (isFailed) {
              setPlanFailedDeleted(true);
            } else {
              dispatch(planHsaHraCheckClear());
              setPlanSccessfullyDeleted(true);
            }
          },
          isIgnoreHRAHSADependencies
        )
      );
    } else {
      setVisiblePlanDeleteModal(false);
      dispatch(planHsaHraCheckClear());
      setBenefitGuideIdList(selectedPlan?.benGuideIDRevisions);
      setVisiblePlanDeleteDBGModal(true);
    }
  };

  return (
    <div className={styles.plansListWrapper}>
      <ConfirmationDialog
        width={planEnrollmentObj ? undefined : 360}
        modalClassName={
          planEnrollmentObj
            ? styles.deleteCloseModal
            : styles.deleteConfirmationModal
        }
        title={
          <Col span={24}>
            <Row justify="center">
              {!hasHraHsaDependencies || planEnrollmentObj
                ? 'Delete Plan'
                : `Delete Plan - ${
                    benefitCategory.includes(MEDICAL) ? 'HSA/' : ''
                  }HRA`}
            </Row>
            {isRenewalProcessStarted && (
              <Row justify="center">
                <span className={styles.subHeading}>{planName}</span>
              </Row>
            )}
          </Col>
        }
        confirmText={planEnrollmentObj ? 'Close' : 'Yes - Delete Plan'}
        cancelText="Cancel"
        isCloseOnly={planEnrollmentObj ? true : false}
        closeModal={() => {
          setVisiblePlanDeleteModal(false);
          dispatch(planHsaHraCheckClear());
        }}
        onConfirm={() => {
          planEnrollmentObj
            ? setVisiblePlanDeleteModal(false)
            : !hasHraHsaDependencies
            ? deleteSelectedPlan(false)
            : deleteSelectedPlan(true);
        }}
        visible={visiblePlanDeleteModal || hasHraHsaDependencies}
        confirmLoading={deletingPlan || enrollmentsLoading}
        centered
        destroyOnClose
      >
        <Skeleton loading={enrollmentsLoading} paragraph={{ rows: 2 }}>
          {isRenewalProcessStarted && <p>{RENEWALS_STARTED_WARNING}</p>}
          {planEnrollmentObj ? (
            <p>{mobilePlanEnrollmentWarning(planName)}</p>
          ) : !hasHraHsaDependencies ? (
            <p> Are you sure you want to delete this plan? </p>
          ) : (
            <p>
              {`This plan is currently tied to an existing ${
                benefitCategory.includes(MEDICAL) ? 'HSA or ' : ''
              }HRA plan.`}
              <br />
              Deleting this plan will remove that connection. Are you sure
              <br />
              you want to delete this plan?
            </p>
          )}
        </Skeleton>
      </ConfirmationDialog>

      <div className={styles.notificationsWrapper}>
        {planSccessfullyDeleted && (
          <AlertMessage
            type="success"
            message={'Plan has been successfully deleted.'}
            closeAlert={() => {
              setPlanSccessfullyDeleted(false);
            }}
            wrapperClassName={styles.alertMessage}
          />
        )}
        {planFailedDeleted &&
          error?.data?.code !== PLAN_DELETE_FAILED_HSA_HRA_DEPENDENCIES &&
          error && (
            <AlertMessage
              type="error"
              message={'Delete failed. Please try again.'}
              closeAlert={() => {
                setPlanFailedDeleted(false);
              }}
              wrapperClassName={styles.alertMessage}
            />
          )}
      </div>
      {!inProgress ? (
        <div className={styles.listContainer}>
          <DataTable
            ref={tableRef}
            data={convertPlansForTableRender(content)}
            currentIndex={paginationConfig.paginationIndex}
            columns={dataColumns}
            pagination={true}
            getData={getData}
            total={total}
            pageSize={limit}
            rowKey="id"
            paginationRowText="Plans"
          />
        </div>
      ) : (
        <div className={styles.loadingIconStyle}>
          <Spin />
        </div>
      )}

      <AssociatedBenguideModal
        benefitGuideIdList={benefitGuideIdList}
        planYearName={planYear?.name ? planYear.name : ''}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
      <AssociatedBenguideModal
        benefitGuideIdList={benefitGuideIdList}
        planYearName={planYear?.name ? planYear.name : ''}
        modalVisible={visiblePlanDeleteDBGModal}
        setModalVisible={setVisiblePlanDeleteDBGModal}
        header="Delete Plan"
        description="To delete this plan, you must remove it from the following Benefits Guide first:"
      />
      {visibleCloneModal && (
        <ClonePlanModal
          visible={visibleCloneModal}
          onCancel={setVisibleCloneModal}
          plan={plan}
          benefitCategory={benefitCategory}
          onSaveClose={onSaveClose}
          isRenewalStartedWarning={!!isRenewalProcessStarted}
        ></ClonePlanModal>
      )}
    </div>
  );
};

export default PlansList;
