import { createSlice } from '@reduxjs/toolkit';
import { TreeDataNode } from 'antd';
import axios from 'axios';
import DocumentVO from 'model/DocumentVO';
import Employer from 'model/Employer';
import PlanYear from 'model/PlanYear';
import { IDataState } from 'modules/renewals/types/renewalsTypes';
import { baseApi } from 'util/apiUtil';
import BasicThunkAction from 'model/common/BasicThunkAction';

type PlanAssistanceModalSliceState = {
  employers: IDataState<Employer[]>;
  planYears: IDataState<PlanYear[]>;
  documents: IDataState<DocumentVO[]>;
  documentUpload: IDataState<DocumentVO & { tempId: string }>;
  isDocumentValid: IDataState<boolean>;
  nodes: TreeDataNode[];
};

const initialState: PlanAssistanceModalSliceState = {
  employers: {
    status: { isError: false, isLoading: false },
    data: [],
  },
  planYears: {
    status: { isError: false, isLoading: false },
    data: [],
  },
  documents: {
    status: { isError: false, isLoading: false },
    data: [],
  },
  documentUpload: {
    status: { isError: false, isLoading: false },
    data: {} as any,
  },
  isDocumentValid: {
    status: { isError: false, isLoading: false },
    data: false,
  },
  nodes: [],
};

const planAssistanceModalSlice = createSlice({
  name: 'planAssistanceModalSlice',
  initialState,
  reducers: {
    fetchEmployersStarted: (state) => {
      state.employers.status.isError = false;
      state.employers.status.isLoading = true;
    },
    fetchEmployersCompleted: (state, { payload }) => {
      state.employers.status.isError = false;
      state.employers.status.isLoading = false;
      state.employers.data = payload;
    },
    fetchEmployersFailed: (state) => {
      state.employers.status.isError = false;
      state.employers.status.isLoading = false;
      state.employers.status.isError = true;
    },
    clearEmployers: (state) => {
      state.employers.status.isError = false;
      state.employers.status.isLoading = false;
      state.employers.data = [];
    },
    fetchPlanYearsStarted: (state) => {
      state.planYears.status.isError = false;
      state.planYears.status.isLoading = true;
    },
    fetchPlanYearsCompleted: (state, { payload }) => {
      state.planYears.status.isError = false;
      state.planYears.status.isLoading = false;
      state.planYears.data = payload;
    },
    fetchPlanYearsFailed: (state) => {
      state.planYears.status.isLoading = false;
      state.planYears.status.isError = true;
    },
    clearPlanYears: (state) => {
      state.planYears.status.isError = false;
      state.planYears.status.isLoading = false;
      state.planYears.data = [];
    },
    fetchDocumentsStarted: (state) => {
      state.documents.status.isError = false;
      state.documents.status.isLoading = true;
    },
    fetchDocumentsCompleted: (state, { payload }) => {
      state.documents.status.isError = false;
      state.documents.status.isLoading = false;
      state.documents.data = payload;
    },
    fetchDocumentsFailed: (state) => {
      state.documents.status.isLoading = false;
      state.documents.status.isError = true;
    },
    clearDocuments: (state) => {
      state.documents.status.isError = false;
      state.documents.status.isLoading = false;
      state.documents.data = [];
    },
    uploadDocumentStarted: (state) => {
      state.documentUpload.status.isError = false;
      state.documentUpload.status.isLoading = true;
    },
    uploadDocumentCompleted: (state, { payload }) => {
      state.documentUpload.status.isError = false;
      state.documentUpload.status.isLoading = false;
      state.documentUpload.data = payload;
    },
    uploadDocumentFailed: (state) => {
      state.documentUpload.status.isLoading = false;
      state.documentUpload.status.isError = true;
    },
    clearDocumentUpload: (state, { payload }) => {
      state.documentUpload.status.isError = false;
      state.documentUpload.status.isLoading = false;
      state.documentUpload.data = undefined;
    },
    checkIsDocumentPresentStarted: (state) => {
      state.isDocumentValid.status.isError = false;
      state.isDocumentValid.status.isLoading = true;
    },
    checkIsDocumentPresentCompleted: (state, { payload }) => {
      state.isDocumentValid.status.isError = false;
      state.isDocumentValid.status.isLoading = false;
      state.isDocumentValid.data = payload;
    },
    checkIsDocumentPresentFailed: (state) => {
      state.isDocumentValid.status.isLoading = false;
      state.isDocumentValid.status.isError = true;
    },
    clearIsDocumentValid: (state) => {
      state.isDocumentValid.status.isError = false;
      state.isDocumentValid.status.isLoading = false;
      state.isDocumentValid.data = false;
    },
    setNodes: (state, { payload }) => {
      state.nodes = payload;
    },
  },
});

// Exposed actions
export const {
  clearDocuments,
  clearEmployers,
  clearPlanYears,
  clearDocumentUpload,
  clearIsDocumentValid,
  setNodes,
} = planAssistanceModalSlice.actions;

// Internal actions. Please do not use these outside
const {
  fetchEmployersStarted,
  fetchEmployersCompleted,
  fetchEmployersFailed,
  fetchPlanYearsStarted,
  fetchPlanYearsCompleted,
  fetchPlanYearsFailed,
  fetchDocumentsStarted,
  fetchDocumentsCompleted,
  fetchDocumentsFailed,
  uploadDocumentStarted,
  uploadDocumentCompleted,
  uploadDocumentFailed,
  checkIsDocumentPresentStarted,
  checkIsDocumentPresentCompleted,
  checkIsDocumentPresentFailed,
} = planAssistanceModalSlice.actions;

// Redux Thunks
export const fetchDocuments: BasicThunkAction<
  {
    employerId: string;
    planYearIds: string; // This is named wrong. It should be planYearId
    documentId?: string;
    query?: string;
  },
  { content: DocumentVO[]; parentDocument: DocumentVO }
> =
  ({ employerId, planYearIds, documentId, query }) =>
  async (dispatch) => {
    try {
      dispatch(fetchDocumentsStarted());
      const { data } = await axios.get(baseApi + '/v3/documents', {
        params: {
          employerId,
          planYearIds,
          documentId,
          query: query,
        },
      });
      dispatch(fetchDocumentsCompleted(data));
      return data;
    } catch (error) {
      dispatch(fetchDocumentsFailed());
    }
  };

export const fetchPlanYears: BasicThunkAction<string, PlanYear[]> =
  (employerId) => async (dispatch) => {
    try {
      dispatch(fetchPlanYearsStarted());
      const { data } = await axios.get(
        baseApi + '/v1/employers/' + employerId + '/plan-years'
      );
      dispatch(fetchPlanYearsCompleted(data));
      return data;
    } catch (error) {
      dispatch(fetchPlanYearsFailed());
    }
  };

export const fetchEmployers: BasicThunkAction<string> =
  (organizationId) => async (dispatch) => {
    try {
      dispatch(fetchEmployersStarted());
      const { data } = await axios.get(baseApi + '/v2/employers/list', {
        params: { 'organization-id': organizationId },
      });
      dispatch(fetchEmployersCompleted(data));
    } catch (error) {
      dispatch(fetchEmployersFailed());
    }
  };

export const checkIsDocumentPresent: BasicThunkAction<
  {
    employerId: string;
    parentDocumentId: string;
    fileName: string;
  },
  boolean
> =
  ({ employerId, fileName, parentDocumentId }) =>
  async (dispatch) => {
    try {
      dispatch(checkIsDocumentPresentStarted());
      const { data } = await axios.get(baseApi + '/v2/documents/validate', {
        params: {
          employerId,
          parentDocumentId,
          name: encodeURIComponent(fileName),
        },
      });
      dispatch(checkIsDocumentPresentCompleted(data));
      return data.documentExist;
    } catch (error) {
      dispatch(checkIsDocumentPresentFailed());
    }
  };

export const uploadDocument: BasicThunkAction<
  {
    tempId: string;
    file: File;
    employerId: string;
    parentDocumentId: string;
    isDuplicate: boolean;
  },
  DocumentVO
> =
  ({ employerId, file, isDuplicate, parentDocumentId, tempId }) =>
  async (dispatch) => {
    try {
      dispatch(uploadDocumentStarted());
      const form = new FormData();
      form.append('file', file);
      const { data } = await axios.post(baseApi + '/v2/documents', form, {
        params: {
          employerId,
          parentDocumentId,
          isDuplicate,
        },
      });
      dispatch(uploadDocumentCompleted({ ...data, tempId }));
      return data;
    } catch (error) {
      dispatch(uploadDocumentFailed());
      throw error;
    }
  };

export default planAssistanceModalSlice.reducer;
