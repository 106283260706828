import React, { FC, useEffect, useState } from 'react';

import { Col, Image, Row, Skeleton } from 'antd';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useStateCallback } from 'hooks/updateState';
import {
  editEmployerCompleted,
  editEmployerFailed,
} from 'modules/employers/slices/employerSlice';
import { getEmployer } from 'layout/slices/layoutSlice';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import BasicInfo from 'modules/employers/components/BasicInfo/BasicInfo';
import InfoLabel from 'components/InfoLabel/InfoLabel';
import {
  clearEmployerLogo,
  getBrokerLocations,
} from 'modules/employers/slices/employerCreateStepperSlice';
import EmployerLocation from 'model/EmployerLocation';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';

import { exitWithoutSavingMsg, usStates } from 'constants/commonConstants';

import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { EMPTY_TEAM_MEMBERS } from 'modules/admins/constants/adminErrorConstants';

import styles from './basicInfoView.module.less';

const getLocationsSelected = (
  employerLocations: EmployerLocation[],
  selectedLocationIds: string[] | undefined
) => {
  const locations: EmployerLocation[] = [];
  selectedLocationIds &&
    selectedLocationIds.forEach((id) => {
      const matchedLocation = find(employerLocations, { locationId: id });
      if (matchedLocation) {
        locations.push(matchedLocation);
      }
    });
  return locations;
};

type EmployerBasicInfoPropTypes = {
  isModalOpen: boolean;
  setIsModalOpen: Function;
};

const ALL_LOCATIONS = 'All';

const EmployerBasicInfoView: FC<EmployerBasicInfoPropTypes> = (
  props: EmployerBasicInfoPropTypes
) => {
  const dispatch = useAppDispatch();
  const { employerEditInProgress, requestType, error } = useAppSelector(
    (state) => state.employer.employer
  );
  const employerObj = useAppSelector((state) => state.layout.employer);
  const brokerLocations = useAppSelector(
    (state) => state.employer.employerCreateStepper.brokerLocationList
  );
  const { isModalOpen, setIsModalOpen } = props;
  const { employerId, brokerId } = useNavContext();
  const { name, einTaxId, address, locations, emailDomains } =
    employerObj || {};
  const { addressLine1, addressLine2, city, state, zipCode } = address || {};
  const employerLocations = isEmpty(locations)
    ? [
        {
          locationId: '',
          locationName: ALL_LOCATIONS,
          attachedToEmployer: true,
        },
      ]
    : getLocationsSelected(brokerLocations.employerLocations, locations);
  const selectedUsState = find(usStates, { value: state }) || {
    label: '',
  };

  const [isConfirmOpen, setIsConfirmOpen] = useStateCallback(false);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (employerId) {
      dispatch(getEmployer(employerId));
    }
  }, [dispatch, employerId]);
  useEffect(() => {
    if (brokerId) {
      dispatch(getBrokerLocations(brokerId));
    }
  }, [dispatch, brokerId]);
  useEffect(() => {
    if (!employerEditInProgress) {
      if (requestType === editEmployerCompleted.type) {
        setAlertMessage({
          type: 'success',
          message: 'Employer has been successfully updated.',
        });
        if (employerId) {
          dispatch(getEmployer(employerId));
        }
        setIsModalOpen(false);
        setVisible(true);
      } else if (requestType === editEmployerFailed.type) {
        if (error && error?.response?.data?.code !== EMPTY_TEAM_MEMBERS) {
          setAlertMessage({
            type: 'error',
            message: 'Employer editing failed.',
          });
          setIsModalOpen(false);
          setVisible(true);
        }
      }
    }
  }, [
    dispatch,
    employerEditInProgress,
    employerId,
    requestType,
    setIsModalOpen,
    error,
  ]);

  const closeBasicInfoEditModal = () => {
    setIsConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setIsConfirmOpen(false, () => {
      if (employerId) {
        dispatch(getEmployer(employerId));
        dispatch(clearEmployerLogo());
      }
      setIsModalOpen(false);
    });
  };
  const closeAlert = () => {
    setVisible(false);
    setAlertMessage({
      type: undefined,
      message: '',
    });
  };

  const logoSkelton = (
    <div className={styles.logoImg}>
      <Skeleton.Input className={styles.logoImg} />
    </div>
  );

  return (
    <div className={styles.employerBasicInfoView}>
      <div className={styles.alertWrapper}>
        {visible && (
          <AlertMessage
            type={alertMessage.type}
            message={alertMessage.message}
            closeAlert={closeAlert}
          />
        )}
      </div>
      <Row>
        <Col span={10}>
          <InfoLabel
            labelText="Employer Name"
            value={
              <OverflowPopover>
                <span data-cy="employerBasicInfoName">{name}</span>
              </OverflowPopover>
            }
          />
        </Col>
        <Col span={14}>
          <InfoLabel
            labelText="Location with Access"
            value={
              <>
                {employerLocations.map(({ locationName, locationId }) => (
                  <div key={locationId}>
                    <OverflowPopover>{locationName}</OverflowPopover>
                  </div>
                ))}
              </>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <InfoLabel
            labelText="EIN / Tax ID"
            value={<span data-cy="employerBasicInfoEIN">{einTaxId}</span>}
          />
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <InfoLabel
            labelText="Address"
            value={
              <>
                <div data-cy="employerBasicInfoAddress1">{addressLine1}</div>
                <div data-cy="employerBasicInfoAddress2">{addressLine2}</div>
                <div data-cy="employerBasicInfoCity">{`${city || ''}, ${
                  selectedUsState.label || ''
                }`}</div>
                <div data-cy="employerBasicInfoZip">{zipCode}</div>
              </>
            }
          />
        </Col>
        <Col span={14}>
          <InfoLabel
            labelText="Logo"
            value={
              <Image
                src={employerObj?.logoUrl}
                preview={false}
                alt={employerObj?.name}
                className={styles.logoImg}
                placeholder={logoSkelton}
              />
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <InfoLabel
            labelText="Company Email Domains"
            noExtraCol={false}
            extraColValue3={11}
            value={
              emailDomains?.length ? (
                emailDomains.map((domain: any, index) => (
                  <div key={index}>
                    <OverflowPopover>{domain}</OverflowPopover>
                  </div>
                ))
              ) : (
                <span>-</span>
              )
            }
          />
        </Col>
      </Row>
      <FullScreenModal
        visible={isModalOpen}
        onCancel={closeBasicInfoEditModal}
        footer={false}
        title="Edit Employer Basic Information"
        className={styles.mediaScreen}
      >
        <div className={styles.wrapper}>
          <BasicInfo
            nextStep={() => {}}
            isEditMode={true}
            employerEditData={employerObj}
            isModalOpen={isModalOpen}
          />
        </div>
        {isConfirmOpen && (
          <ConfirmationDialog
            title="Are you sure you want to close?"
            confirmText="Yes - close and do not save"
            cancelText="Cancel"
            closeModal={() => setIsConfirmOpen(false)}
            onConfirm={handleCloseConfirm}
            visible={isConfirmOpen}
          >
            <p className={styles.warningConfirmation}>{exitWithoutSavingMsg}</p>
          </ConfirmationDialog>
        )}
      </FullScreenModal>
    </div>
  );
};

export default EmployerBasicInfoView;
