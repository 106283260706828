import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal, Slider, Typography, Upload } from 'antd';
import Text from 'antd/lib/typography/Text';
import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import Cropper from 'react-easy-crop';
// eslint-disable-next-line import/no-unresolved
import { Area, Point, Size } from 'react-easy-crop/types';
import isEmpty from 'lodash/isEmpty';

import { useStateCallback } from 'hooks/updateState';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import { getCroppedImg } from 'components/ImageUploader/canvasUtils';
import LinkButton from 'components/buttons/LinkButton/LinkButton';

import styles from './imageUploader.module.less';

export const FORMAT_VALIDATE: string = 'FORMAT';
export const SIZE_VALIDATE: string = 'SIZE';

const ACCEPTED_FILE_TYPES = ['.png', '.jpg', '.jpeg'];

type ImageUploaderProps = {
  description?: string;
  cropShape: 'rect' | 'round';
  aspect: number; // rect {4 / 3} / round {1}
  loading?: boolean;
  onConfirm?: (
    image: string,
    originalImage?: string,
    croppedArea?: Area
  ) => void;
  uploadedImage: string | undefined;
  unCroppedImage?: string | null;
  showOperations?: boolean;
  onRemove?: () => void;
  title?: string;
  onValidateFails?: (validateSetting: string) => void;
  helpText?: string;
  dataCyUpload?: string;
  dataCySave?: string;
  defaultCropArea?: Area;
};

const MIN_ZOOM = 0.1;
const MAX_ZOOM = 2;
const DEFAULT_ZOOM = 1;
const ZOOM_STEP = 0.05;

const ImageUploader: FC<ImageUploaderProps> = (props) => {
  const {
    description,
    cropShape,
    aspect,
    onConfirm,
    loading,
    uploadedImage,
    showOperations = false,
    onRemove,
    title,
    onValidateFails,
    helpText,
    dataCyUpload,
    dataCySave,
    defaultCropArea,
    unCroppedImage,
  } = props;

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useStateCallback('');
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });
  const [initialCropArea, setInitialCropArea] = useState<Area | null>(
    defaultCropArea || null
  );

  useEffect(() => {
    if (unCroppedImage) {
      setImageSrc(unCroppedImage);
      setCrop({ x: 0, y: 0 });
    }
  }, [setImageSrc, unCroppedImage]);

  useEffect(() => {
    if (defaultCropArea) {
      setInitialCropArea(defaultCropArea);
    }
  }, [defaultCropArea]);

  const beforeUpload = (file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      if (onValidateFails) {
        onValidateFails(FORMAT_VALIDATE);
      }
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      if (onValidateFails) {
        onValidateFails(SIZE_VALIDATE);
      }
      return false;
    }
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (reader.result !== null) {
        setCrop({ x: 0, y: 0 });
        setInitialCropArea(null);
        setImageSrc('');

        const blob = new Blob([reader.result], {
          type: 'text/plain; charset=utf-8',
        });
        blob.text().then((text) =>
          setImageSrc(text, () => {
            setModalOpen(true);
          })
        );
      }
    });
    reader.readAsDataURL(file);
    return false;
  };

  const handleCancel = () => {
    setModalOpen(false);
    resetParams();
  };

  const resetParams = () => {
    setZoom(DEFAULT_ZOOM);
    setCrop({ x: 0, y: 0 });
    setCroppedAreaPixels({
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    });
    setInitialCropArea(defaultCropArea || null);
    if (unCroppedImage) {
      setImageSrc(unCroppedImage);
    } else {
      setImageSrc('');
    }
  };

  const cropSize = useMemo(() => {
    if (cropShape === 'rect') {
      return { width: 300, height: 80.04 } as Size;
    } else {
      return { width: 300, height: 300 } as Size;
    }
  }, [cropShape]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    if (!isEmpty(imageSrc)) {
      try {
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
        if (croppedImage && onConfirm) {
          onConfirm(croppedImage, imageSrc, croppedAreaPixels);
        }
        setModalOpen(false);
      } catch (e) {
        console.error(e);
      }
    }
  }, [croppedAreaPixels, imageSrc, onConfirm]);

  const uploadButton = (
    <div>
      {modalOpen ? (
        <LoadingOutlined />
      ) : cropShape === 'round' ? (
        <div className={styles.uploadBtnWrapper}>
          <SubmitButton
            type="dashed"
            className={styles.uploadBtnCircle}
            dataCy={dataCyUpload}
          >
            + Upload
          </SubmitButton>
        </div>
      ) : (
        <div className={styles.uploadBtnWrapper}>
          <SubmitButton
            type="dashed"
            className={styles.uploadBtnSquare}
            dataCy={dataCyUpload}
          >
            + Upload
          </SubmitButton>
        </div>
      )}
    </div>
  );

  const imagePreview = (
    <>
      {loading ? (
        <LoadingOutlined />
      ) : (
        uploadedImage && (
          <img
            src={uploadedImage}
            alt="avatar"
            className={
              cropShape === 'round'
                ? styles.imageStyleCircle
                : styles.imageStyleRect
            }
          />
        )
      )}
    </>
  );

  const { Paragraph } = Typography;

  const uploadPhotoTitle = (
    <div>
      <div>
        <div className={styles.title}>{title ? title : 'Upload Photo'}</div>
      </div>
      <br />
      <div className={styles.descriptionContainer}>
        <Paragraph className={styles.center}>{description}</Paragraph>
      </div>
    </div>
  );

  const footer = (
    <div>
      <SubmitButton
        key="submit"
        type="primary"
        onClick={showCroppedImage}
        block
        dataCy={dataCySave}
      >
        Save
      </SubmitButton>
    </div>
  );
  const imgDiv = (
    <div className={styles.previewContainer} onClick={() => setModalOpen(true)}>
      {imagePreview}
    </div>
  );
  return (
    <div className={styles.uploadWrapper}>
      {uploadedImage ? (
        imgDiv
      ) : (
        <Upload
          showUploadList={false}
          beforeUpload={beforeUpload}
          // disables upload action http request
          customRequest={() => null}
          accept={ACCEPTED_FILE_TYPES.join(',')}
          className={styles.primaryUploader}
        >
          {uploadButton}
        </Upload>
      )}

      {helpText && !uploadedImage && (
        <Text type="secondary" className={styles.helpText}>
          {helpText}
        </Text>
      )}
      {showOperations && uploadedImage && !loading && (
        <div className={styles.operations}>
          <LinkButton
            containerClassName={styles.imageUploaderRemoveWrapper}
            onClick={() => {
              setZoom(DEFAULT_ZOOM);
              setInitialCropArea(null);
              onRemove!();
            }}
            className={styles.removeBtn}
          >
            Remove
          </LinkButton>
          <Upload
            showUploadList={false}
            beforeUpload={(file) => {
              beforeUpload(file);
            }}
            customRequest={() => null}
            className={styles.uploadNewContainer}
            accept={ACCEPTED_FILE_TYPES.join(',')}
          >
            <LinkButton className={styles.uploadNewBtn}>
              + Upload New
            </LinkButton>
          </Upload>
        </div>
      )}
      {imageSrc && (
        <Modal
          width={520}
          className={styles.modalClass}
          visible={modalOpen}
          onCancel={handleCancel}
          title={uploadPhotoTitle}
          onOk={showCroppedImage}
          footer={footer}
          closeIcon={<CloseCircleOutlined className={styles.styleClass} />}
          destroyOnClose
          transitionName=""
        >
          <div className={styles.cropContainer}>
            <Cropper
              cropSize={cropSize}
              cropShape={cropShape}
              showGrid={false}
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              minZoom={MIN_ZOOM}
              maxZoom={MAX_ZOOM}
              zoomWithScroll
              classes={{
                containerClassName: styles.canvasBackground,
              }}
              initialCroppedAreaPixels={initialCropArea || undefined}
              restrictPosition={false}
              objectFit="horizontal-cover"
            />
          </div>
          <div className={`${styles.center} ${styles.cropperHelperText}`}>
            Fit logo within constraints above.
          </div>
          <section className={styles.controls}>
            <Button
              type="text"
              onClick={() => setZoom(zoom - ZOOM_STEP)}
              disabled={zoom - ZOOM_STEP < MIN_ZOOM}
            >
              －
            </Button>
            <div className={styles.slider}>
              <Slider
                min={MIN_ZOOM}
                max={MAX_ZOOM}
                step={ZOOM_STEP}
                onChange={(zoom: number) => setZoom(zoom)}
                value={zoom}
                tooltipVisible={false}
              />
            </div>
            <Button
              type="text"
              onClick={() => setZoom(zoom + ZOOM_STEP)}
              disabled={zoom + ZOOM_STEP > MAX_ZOOM}
            >
              ＋
            </Button>
          </section>
        </Modal>
      )}
    </div>
  );
};

export default ImageUploader;
