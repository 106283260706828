import {
  forwardRef,
  MutableRefObject,
  useImperativeHandle,
  useState,
} from 'react';

import PlanBasicInfoForm from 'modules/plans/components/DVPlanBasicInfoForm/PlanBasicInfoForm';
import { VisionPlan } from 'model/plans/VisionPlan';
import { useAppSelector } from 'hooks/redux';

type VisionBasicPlanInfoUPWrapperProps = {
  visionPlan: VisionPlan;
  setVisionPlan: Function;
  basicPlanInfoRef: MutableRefObject<any>;
  originalPlanYear?: string;
  originalPlanBenGroups?: string[];
  isReviewProp?: boolean;
  reviewNewPlanYear?: string;
  reviewBenefitClasses?: string[];
  isReviewHighlight?: boolean;
};

const VisionBasicPlanInfoUPWrapper = forwardRef(
  (props: VisionBasicPlanInfoUPWrapperProps, ref) => {
    const editedVisionPlan = useAppSelector(
      (state) => state.plan.visionPlan.editedVisionPlan
    );

    const [requiredFieldError, setRequiredFieldError] =
      useState<boolean>(false);
    const {
      visionPlan,
      setVisionPlan,
      basicPlanInfoRef,
      originalPlanYear,
      originalPlanBenGroups,
      isReviewProp,
      reviewNewPlanYear,
      isReviewHighlight = false,
      reviewBenefitClasses,
    } = props;

    useImperativeHandle(ref, () => ({
      reset() {
        basicPlanInfoRef.current?.resetForm();
      },
      isValidForm() {
        return basicPlanInfoRef.current?.isValidForm();
      },
      setNameDuplicationError() {
        return basicPlanInfoRef?.current?.setNameDuplicationError();
      },
    }));

    return (
      <PlanBasicInfoForm
        onChange={setVisionPlan}
        plan={visionPlan}
        isCloseConfirmed={false}
        isDBGPlan={true}
        error={[requiredFieldError, setRequiredFieldError]}
        ref={basicPlanInfoRef}
        isUPEdit={true}
        benefitCategory="VISION"
        originalPlanYear={originalPlanYear}
        originalPlanBenGroups={originalPlanBenGroups}
        hasSameContributions={editedVisionPlan?.hasSameContributions}
        isReviewProp={isReviewProp}
        isReviewHighlight={isReviewHighlight}
        reviewNewPlanYear={reviewNewPlanYear}
        reviewBenefitClasses={reviewBenefitClasses}
        isSingleColumn
      />
    );
  }
);

VisionBasicPlanInfoUPWrapper.displayName = 'VisionBasicPlanInfoUPWrapper';
export default VisionBasicPlanInfoUPWrapper;
