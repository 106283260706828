class Contribution {
  tierName: string;
  employerCost: number | string | null;
  employeeCost: number | string | null;
  totalCost: number | string | null;
  employeeBiWeeklyCost: number | string | null;
  employeeSemiMonthlyCost: number | string | null;
  enrollment: number | null;

  constructor() {
    this.tierName = '';
    this.employerCost = null;
    this.employeeCost = null;
    this.totalCost = null;
    this.employeeBiWeeklyCost = null;
    this.employeeSemiMonthlyCost = null;
    this.enrollment = null;
  }
}

export default Contribution;
