import React from 'react';
import { Card, Col, Input, Popover, Row } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import RenewalCommonMainHeader from 'modules/renewals/components/helperComponents/RenewalCommonMainHeader/RenewalCommonMainHeader';
import { NewPlanTag } from 'modules/renewals/components/NewPlanTag/NewPlanTag';
import SummeryCommonTable from 'modules/renewals/components/helperComponents/SummeryCommonTable/SummeryCommonTable';
import TextButton from 'components/buttons/TextButton/TextButton';

import { currencyFormatter, onlyNumberInputUtil } from 'util/commonUtil';
import { isInputNumberValidation } from 'util/stringUtil';
import {
  BASE_PLAN_BUY_UP,
  EE_TIER,
  HRA_CONTRIBUTION,
  HRA_FACE_VALUE,
  HSA_CONTRIBUTION_KEY,
  TIER_LIST_ENUMS,
  TOTAL_WITHOUT_HSA_KEY,
} from 'modules/renewals/constants/renewalsConstants';
import { displayNumberWithDecimalValue } from 'modules/plans/utils';
import { ReactComponent as WarningInfoIcon } from 'assets/images/warning-info-icon.svg';
import styles from './modelingTable.module.less';

type Props = {
  data: any;
  isHsa: boolean;
  isBasePlan: boolean;
  isCustomEdited: boolean;
  isLoading: boolean;
  currentIndex: number;
  totalPlans: number;
  strategy: string;
  isHra: boolean;
  handleTableOnChange: ({
    tableRowIndex,
    modelingKey,
    updatedField,
    value,
  }: {
    tableRowIndex: number;
    modelingKey: string;
    updatedField: string;
    value: any;
  }) => void;
  handleOnBlur: () => void;
  handlePlanLevelAttributeChange: ({
    planId,
    modelingKey,
    value,
  }: {
    planId: string;
    modelingKey: string;
    value: any;
  }) => void;
  handleCustomSort: (move: 'UP' | 'DOWN') => void;
};

const ModelingTable = (props: Props) => {
  const {
    data,
    isHsa,
    isBasePlan,
    isCustomEdited,
    isLoading,
    currentIndex,
    totalPlans,
    strategy,
    handleTableOnChange,
    handleOnBlur,
    handlePlanLevelAttributeChange,
    isHra,
    handleCustomSort,
  } = props;
  const isFirstPlan = currentIndex === 0;
  const isLastPlan = currentIndex === totalPlans - 1;

  const getHeaderPopoverContent = (
    type: 'erSplitAbs' | 'erPremium' | 'eePremium'
  ) => {
    switch (type) {
      case 'erPremium':
        return (
          <div className={styles.headerPopoverContent}>
            <div className={styles.headerPopoverTitle}>
              ER Contribution is calculated as:
            </div>
            <div className={styles.headerPopoverHeading}>For EE Only Tiers</div>
            <div>% of Total EE Only Premium</div>
            <div className={styles.headerPopoverHeading}>
              For EE+Dependent Tiers
            </div>
            <div>
              % of Total EE Only Premium + % of (Dependent Tier Premium - EE
              Only Premium)
            </div>
          </div>
        );
      case 'erSplitAbs':
        return (
          <div className={styles.headerPopoverContent}>
            <div className={styles.headerPopoverTitle}>
              ER Contribution for EE/DEP is the following:
            </div>
            <div className={styles.headerPopoverHeading}>EE %</div>
            <div>
              {`The percentage of the employee's portion of the premium that
                  the employer pays for`}
            </div>
            <div>EE % = EE Only ER Pays/EE Only Total Cost</div>
            <div className={styles.headerPopoverHeading}>DEP %</div>
            <div>
              {`The percentage of the dependent's portion of the premium that
                  the employer pays for`}
            </div>
            <div>
              DEP % = (Family Tier ER Pays - EE Only ER Pays)/(Family Tier Total
              Cost - EE Only Total Cost)
            </div>
          </div>
        );
      case 'eePremium':
        return (
          <div className={styles.headerPopoverContent}>
            <div className={styles.headerPopoverTitle}>
              EE Contribution is calculated as:
            </div>
            <div>EE Contribution = Total Premium - ER Contribution</div>
          </div>
        );
      default:
        return <></>;
    }
  };

  const getEECurrentPopoverContent = (difference: any, eePremium: any) => {
    const eeValue: number = parseFloat(String(eePremium));
    const convertedDifference: number = parseFloat(String(difference));
    const absDifference: number = Math.abs(convertedDifference);

    if (convertedDifference === 0) {
      return (
        <div className={styles.eePopoverContainer}>
          EE CURRENTLY PAYS {currencyFormatter(eeValue)}/MO.
          <br /> Which is same as this modeling
        </div>
      );
    } else if (convertedDifference < 0) {
      return (
        <div className={styles.eePopoverContainer}>
          EE CURRENTLY PAYS{' '}
          <span className={styles.eePopoverHeading}>
            {currencyFormatter(eeValue)}
          </span>
          /MO.
          <br />
          <span className={styles.eePopoverHeading}>
            {currencyFormatter(absDifference)}{' '}
          </span>
          more per month than this modeling
        </div>
      );
    } else {
      return (
        <div className={styles.eePopoverContainer}>
          EE CURRENTLY PAYS{' '}
          <span className={styles.eePopoverHeading}>
            {currencyFormatter(eeValue)}/MO.
          </span>
          <br />
          <span className={styles.eePopoverHeading}>
            {currencyFormatter(absDifference)}
          </span>{' '}
          less per month than this modeling
        </div>
      );
    }
  };

  const generateEePremiumVarianceWithStyling = (
    val: number
  ): React.ReactNode => {
    if (0 === val) {
      return (
        <p id="eePaysVsc" className={styles.changeInPercentageZero}>
          $0.0
        </p>
      );
    } else if (0 > val) {
      return (
        <p id="eePaysVsc" className={styles.changeInPercentagePlus}>
          ({currencyFormatter(val)})
        </p>
      );
    } else {
      return (
        <p id="eePaysVsc" className={styles.changeInPercentageMinus}>
          (+{currencyFormatter(val)})
        </p>
      );
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <>
          <img
            src={data?.carrierLogoUrl}
            alt="Carrier Logo"
            className={styles.tableLogo}
          />
        </>
      ),
      dataIndex: 'tierName',
      key: 'tierName',
      width: '12%',
    },
    {
      title: 'Enrollments',
      dataIndex: 'enrollments',
      align: 'left',
      key: 'enrollments',
      width: '12%',
      render: (_, row, idx) => {
        return (
          <Input
            id="enrollments"
            value={row?.enrollments}
            size="large"
            className={styles.editableField}
            onKeyPress={onlyNumberInputUtil}
            onChange={(e) => {
              handleTableOnChange({
                tableRowIndex: idx,
                modelingKey: 'enrollments',
                updatedField: TIER_LIST_ENUMS.ENROLLMENTS,
                value: e.target.value,
              });
            }}
            onBlur={handleOnBlur}
          />
        );
      },
    },
    {
      title: (
        <div className={styles.labelWrapper}>
          <div>er Contributions (EE/DEP)</div>

          <div>
            <Popover
              trigger="hover"
              content={getHeaderPopoverContent('erSplitAbs')}
              overlayClassName={styles.headerPopoverForER}
              getPopupContainer={(trigger: any) => {
                return trigger.parentElement.parentElement;
              }}
            >
              <WarningInfoIcon className={styles.infoIconAbs} />
            </Popover>
          </div>
        </div>
      ),
      dataIndex: 'erSplitAbs',
      key: 'erSplitAbs',
      align: 'left',
      width: isHsa || isHra ? '20%' : '16%',
      render: (_, row, idx) => {
        if (EE_TIER === row?.tier) {
          return (
            <>
              <div className={styles.erSplitContainer}>
                <Input
                  id="erSplitAbsEr"
                  size="large"
                  value={row?.erSplitAbs
                    ?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })
                    .replace(/[, ]+/g, '')}
                  onBeforeInput={isInputNumberValidation}
                  className={styles.editableField}
                  suffix={'%'}
                  onChange={(e) => {
                    handleTableOnChange({
                      tableRowIndex: idx,
                      modelingKey: 'erSplitAbs',
                      updatedField: TIER_LIST_ENUMS.ER_SPLIT,
                      value: e.target.value,
                    });
                  }}
                  onBlur={handleOnBlur}
                />

                <span className={styles.erSplitAbsDynamicLabel}>
                  {displayNumberWithDecimalValue(0)}%
                </span>
              </div>
            </>
          );
        }
        return (
          <div className={styles.erSplitContainer}>
            <span className={styles.erSplitAbsDynamicPercentage}>
              {displayNumberWithDecimalValue(
                data?.tierContributionsDefault?.find(
                  (item: any) => item?.tier === EE_TIER
                )?.erSplitAbs
              )}
              %
            </span>

            <Input
              id="erSplitAbsDep"
              value={row?.erSplitAbs
                ?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
                .replace(/[, ]+/g, '')}
              size="large"
              className={styles.editableField}
              suffix={'%'}
              onBeforeInput={isInputNumberValidation}
              onChange={(e) => {
                handleTableOnChange({
                  tableRowIndex: idx,
                  modelingKey: 'erSplitAbs',
                  updatedField: TIER_LIST_ENUMS.ER_SPLIT,
                  value: e.target.value,
                });
              }}
              onBlur={handleOnBlur}
            />
          </div>
        );
      },
    },
    {
      title: (
        <div className={styles.labelWrapper}>
          <div>ER pays</div>
          <div>
            <Popover
              trigger="hover"
              content={getHeaderPopoverContent('erPremium')}
              overlayClassName={styles.headerPopoverForER}
              getPopupContainer={(trigger: any) => {
                return trigger.parentElement.parentElement;
              }}
            >
              <WarningInfoIcon className={styles.infoIcon} />
            </Popover>
          </div>
        </div>
      ),
      key: 'erPremium',
      dataIndex: 'erPremium',
      width: '8%',
      render: (_, row) => {
        return currencyFormatter(row?.erPremium);
      },
    },
    {
      title: (
        <div className={styles.labelWrapper}>
          <div>
            EE Pays (<small>Vs current</small>)
          </div>

          <div>
            <Popover
              trigger="hover"
              content={getHeaderPopoverContent('eePremium')}
              overlayClassName={styles.headerPopover}
            >
              <WarningInfoIcon className={styles.infoIconCurrent} />
            </Popover>
          </div>
        </div>
      ),
      key: 'eePremium',
      align: 'left',
      dataIndex: 'eePremium',
      width: '12%',
      render: (_, row, idx) => {
        if (data?.newPlan) {
          return (
            <div className={styles.eeContainer}>
              <span className={styles.currencySection}>$</span>
              <Input
                id="eePays"
                size="large"
                value={row?.eePremium}
                className={styles.editableField}
                onBeforeInput={isInputNumberValidation}
                onChange={(e) => {
                  handleTableOnChange({
                    tableRowIndex: idx,
                    modelingKey: 'eePremium',
                    updatedField: TIER_LIST_ENUMS.EE_PREMIUM,
                    value: e.target.value,
                  });
                }}
                onBlur={handleOnBlur}
              />
              <div className={styles.newPlanFromCurrentWrapper}>-</div>
            </div>
          );
        }
        return (
          <Popover
            trigger="hover"
            content={getEECurrentPopoverContent(
              row?.diffEePremium,
              row?.currentEePremium
            )}
            overlayClassName={styles.eePopOver}
          >
            <div className={styles.eeContainer}>
              <span className={styles.currencySection}>$</span>

              <Input
                id="eePays"
                size="large"
                value={row?.eePremium}
                className={styles.editableField}
                onBeforeInput={isInputNumberValidation}
                onChange={(e) => {
                  handleTableOnChange({
                    tableRowIndex: idx,
                    modelingKey: 'eePremium',
                    updatedField: TIER_LIST_ENUMS.EE_PREMIUM,
                    value: e.target.value,
                  });
                }}
                onBlur={handleOnBlur}
              />

              <div className={styles.diffEeVariance}>
                {generateEePremiumVarianceWithStyling(row?.diffEePremium)}
              </div>
            </div>
          </Popover>
        );
      },
    },
    {
      title: 'Total Cost',
      key: 'contribution',
      align: 'left',
      dataIndex: 'contribution',
      width: '8%',
      render: (_, row) => {
        return (
          <p id="totalCost" className={styles.totalCost}>
            {currencyFormatter(!isHsa ? row?.contribution : row?.premium)}
          </p>
        );
      },
    },
    {
      title: 'HRA Allowance / Yr',
      key: 'hraFaceValue',
      align: 'left',
      dataIndex: 'hraFaceValue',
      width: '11%',
      render: (_, row, idx) => {
        return (
          <Input
            id="hraAllowance"
            size="large"
            value={row?.hraFaceValue}
            className={styles.editableField}
            onBeforeInput={isInputNumberValidation}
            onChange={(e) => {
              handleTableOnChange({
                tableRowIndex: idx,
                modelingKey: 'hraFaceValue',
                updatedField: TIER_LIST_ENUMS.HRA_CONTRIBUTION,
                value: e.target.value,
              });
            }}
            onBlur={handleOnBlur}
          />
        );
      },
    },
    {
      title: 'HRA Cost / Yr',
      key: 'hraContribution',
      align: 'left',
      dataIndex: 'hraContribution',
      width: '8%',
      render: (_, row) => {
        return currencyFormatter(row?.hraContribution);
      },
    },
    {
      title: 'er HSA Contributions',
      key: 'hsaContribution',
      align: 'left',
      width: '11%',
      dataIndex: 'hsaContribution',
      render: (_, row, idx) => {
        return (
          <Input
            id="hsaContribution"
            value={row?.hsaContribution}
            size="large"
            className={styles.editableField}
            onBeforeInput={isInputNumberValidation}
            onChange={(e) => {
              handleTableOnChange({
                tableRowIndex: idx,
                modelingKey: 'hsaContribution',
                updatedField: TIER_LIST_ENUMS.HSA_CONTRIBUTION,
                value: e.target.value,
              });
            }}
            onBlur={handleOnBlur}
          />
        );
      },
    },
    {
      title: 'Total Cost w/HSA',
      key: 'premiumWithoutHsa',
      align: 'left',
      dataIndex: 'contribution',
      width: '11%',
      render: (_, row) => {
        return currencyFormatter(row?.contribution);
      },
    },
  ];

  const getApplicableTableColumns = (): ColumnsType<any> => {
    let tableColumns = [...columns];

    if (!isHsa) {
      tableColumns = tableColumns.filter(
        (obj) =>
          obj.key !== HSA_CONTRIBUTION_KEY && obj.key !== TOTAL_WITHOUT_HSA_KEY
      );
    }
    if (!isHra) {
      tableColumns = tableColumns.filter(
        (obj) => obj.key !== HRA_FACE_VALUE && obj.key !== HRA_CONTRIBUTION
      );
    }

    return tableColumns;
  };
  return (
    <>
      <div className={styles.dynamicRenewalTable}>
        <Row justify="space-between">
          <Col>
            <Row>
              <RenewalCommonMainHeader mainText={data?.planName} />

              {data?.newPlan ? (
                <NewPlanTag className={styles.newPlanTagWrapper} />
              ) : data?.consolidatedPlan ? (
                <NewPlanTag
                  className={styles.consolidatedTagWrapper}
                  label={'CONSOLIDATED PLAN'}
                />
              ) : (
                ''
              )}
            </Row>
          </Col>
        </Row>
        {isBasePlan && (
          <div className={styles.tagWrapper}>
            <Card className={styles.bsePlanTag}>
              <span className={styles.tagText}>Base Plan</span>
            </Card>
          </div>
        )}
        <Row justify="end" className={styles.hraCustomRow}>
          {isCustomEdited && (
            <Col>
              <span className={styles.customEditTag}>Custom Edited</span>
            </Col>
          )}
          {isHra && (
            <Col>
              <span className={styles.hraUtilitzationTitle}>
                hra utilization
              </span>
              <Input
                value={data.hraUtilization
                  ?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                  .replace(/[, ]+/g, '')}
                size="large"
                className={styles.editableFieldMin}
                suffix={'%'}
                onBeforeInput={isInputNumberValidation}
                onChange={(e) => {
                  handlePlanLevelAttributeChange({
                    planId: data.id,
                    modelingKey: 'hraUtilization',
                    value: e.target.value,
                  });
                }}
                onBlur={handleOnBlur}
              />
            </Col>
          )}
        </Row>

        <div className={styles.tableWrapper}>
          <SummeryCommonTable
            data={data?.tierContributionsDefault}
            columns={getApplicableTableColumns()}
            loading={isLoading}
          />
        </div>

        <div className={styles.moveSection}>
          <div className={styles.moveBtnWrapper}>
            <TextButton
              className={styles.moveBtn}
              label={
                <div className={styles.labelWrapper}>
                  <div>
                    <ArrowUpOutlined className={styles.disable} />
                  </div>
                  <div className={styles.labelUpText}>Move</div>
                </div>
              }
              type="default"
              disabled={
                isFirstPlan ||
                isBasePlan ||
                (currentIndex === 1 && strategy === BASE_PLAN_BUY_UP)
              }
              onClick={() => handleCustomSort('UP')}
            />
          </div>
          <div className={styles.moveBtnWrapper}>
            <TextButton
              className={styles.moveBtn}
              label={
                <div className={styles.labelWrapper}>
                  <div className={styles.labelDownText}>Move</div>
                  <div>
                    <ArrowDownOutlined />
                  </div>
                </div>
              }
              type="default"
              disabled={isLastPlan || isBasePlan}
              onClick={() => handleCustomSort('DOWN')}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ModelingTable;
