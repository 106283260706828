import {
  ChangeEvent,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Popover, Switch } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { DataColumn } from 'components/DataTable/DataColumn';
import ToolList from 'components/ToolList/ToolList';
import {
  useGetEmployersQuery,
  useUpdateEmployerFeatureStatusMutation,
} from 'api/featureControl';
import TablePagination from 'model/TablePagination';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import AutomatedEmailSetupModal from 'modules/tools/components/AutomatedEmailSetupModal/AutomatedEmailSetupModal';
import {
  ISSUES_LOG_PROVISION_STATUS_COMPLETED,
  ISSUES_LOG_PROVISION_STATUS_IN_PROGRESS,
  ISSUES_LOG_PROVISION_STATUS_NA,
  ISSUE_TYPE_ACCOUNT,
} from 'modules/issueslog/constants/IssuesLogConstants';
import iconWarning from 'assets/images/alert-warning.svg';
import iconConnectionPending from 'assets/images/icon-connection-pending.svg';
import { showSuccessNotification } from 'components/Notification/Notification';
import AutomatedEmailDeleteModal from 'modules/tools/components/AutomatedEmailDeleteModal/AutomatedEmailDeleteModal';
import ResendAutomatedEmailModal from 'modules/tools/components/ResendAutomatedEmailModal/ResendAutomatedEmailModal';
import AutomatedEmailStatusFilter from 'modules/tools/components/AutomatedEmailStatusFilter/AutomatedEmailStatusFilter';
import IssuesLogEmployerFeatureToggle from 'modules/tools/components/IssuesLogEmployerFeatureToggle/IssuesLogEmployerFeatureToggle';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import {
  ALLOWED_INDIVIDUAL_SUB_TYPES,
  EMPLOYER_ACCESS_LOCKED,
} from 'constants/commonConstants';
import EmployerVisibilityOption from 'components/EmployerVisibilityOption/EmployerVisibilityOption';

import styles from './featureList.module.less';

type AccountTeamProvisionProps = {};

const AccountTeamProvision: FC<AccountTeamProvisionProps> = (
  props: AccountTeamProvisionProps
) => {
  const EMPTY_LABEL = '-';

  const navigate = useNavigate();
  const { brokerId } = useParams();
  const [updateStatus, { isSuccess }] = useUpdateEmployerFeatureStatusMutation(
    {}
  );
  const [searchText, setSearchText] = useState<string>('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState('name');
  const [automatedEmailSetupModalOpen, setAutomatedEmailSetupModalOpen] =
    useState<boolean>(false);
  const [automatedEmailDeleteModal, setAutomatedEmailDeleteModal] =
    useState<boolean>(false);
  const [selectedEmployer, setSelectedEmployer] = useState<any>({});
  const [resendEmailModalOpen, setResendEmailModal] = useState<boolean>(false);
  const {
    data: featureWrappers = [],
    refetch,
    isFetching,
  } = useGetEmployersQuery({
    page: pageIndex,
    organizationId: brokerId as string,
    key: 'ISSUES_LOG',
    query: searchText,
    size: pageSize,
    sort: sortOrder,
  });
  const [filteredWrappers, setFilteredWrappers] = useState(featureWrappers);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [refetch, isSuccess]);

  useEffect(() => {
    refetch();
  }, [refetch, searchText]);

  const navigateToIssuesLog = (employerId: string) => {
    navigate(
      `/brokers/${brokerId}/employers/${employerId}/issues-log/account`,
      {
        state: { bypassInvalidRoute: true },
      }
    );
  };

  const showSaveNotification = () => {
    showSuccessNotification({
      message: (
        <div className={styles.successMailTitle}>Automated Email Added</div>
      ),
      description: `Confirmation of email connection will take a few minutes. Please refresh the page to check the status.`,
      key: 'automatedEmailAdded',
    });
  };

  const SecuredSetupEmail = (employer: any): ReactElement => {
    const allowedUserTypeElement = usePermitByUserType(
      <div
        className={styles.setUpMailLink}
        onClick={() => {
          setSelectedEmployer(employer?.employer);
          setAutomatedEmailSetupModalOpen(true);
        }}
      >
        Setup automated email
      </div>,
      ALLOWED_INDIVIDUAL_SUB_TYPES
    );

    return allowedUserTypeElement || <></>; // Ensure a valid JSX element is always returned
  };

  const SecuredRemoveEmail = (employer: any): ReactElement => {
    return (
      usePermitByUserType(
        <div
          className={styles.closeIcon}
          onClick={() => {
            setSelectedEmployer(employer?.employer);
            setAutomatedEmailDeleteModal(true);
          }}
        >
          x
        </div>,
        ALLOWED_INDIVIDUAL_SUB_TYPES
      ) || <></>
    );
  };

  const SecuredVerifyConnection = (employer: any): ReactElement => {
    return (
      usePermitByUserType(
        <img
          className={styles.iconRetry}
          src={iconConnectionPending}
          alt=""
          onClick={() => {
            setSelectedEmployer(employer?.employer);
            setResendEmailModal(true);
          }}
        />,
        ALLOWED_INDIVIDUAL_SUB_TYPES
      ) || <></>
    );
  };

  const SecuredConnectionPopover = (row: any): ReactElement | null => {
    return usePermitByUserType(
      <Popover
        overlayClassName="provisionStatusPopover"
        placement="left"
        content={
          'Confirming the connection may take several minutes. Return to this page later to see an updated status or send another test email.'
        }
      >
        <img src={iconWarning} className={styles.iconWarning} alt="" />
      </Popover>,
      ALLOWED_INDIVIDUAL_SUB_TYPES
    );
  };

  const dataColumns: DataColumn[] = [
    {
      title: 'EMPLOYER',
      dataIndex: 'employerName',
      key: 'employerName',
      width: '30%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.employerName?.localeCompare(b.employerName),
      defaultSortOrder: 'ascend',
      render: (item, employer: any) => {
        return (
          <OverflowPopover key={employer.employerId}>
            <a
              className={'text table-item-link'}
              onClick={() => {
                navigateToIssuesLog(employer.employerId);
              }}
            >
              {item}
            </a>
          </OverflowPopover>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'AUTOMATED EMAIL',
      dataIndex: 'automatedEmail',
      key: 'automatedEmail',
      width: '30%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        if (isEmpty(a?.automatedEmail?.email)) {
          return 1;
        } else if (isEmpty(b?.automatedEmail?.email)) {
          return -1;
        } else {
          return (a?.automatedEmail?.email).localeCompare(
            b?.automatedEmail?.email
          );
        }
      },
      render: (current: any, employer: any) => {
        if (employer?.automatedEmail?.email) {
          return (
            <div className={styles.automatedEmail}>
              <OverflowPopover popoverContent={employer?.automatedEmail?.email}>
                {employer?.automatedEmail?.email}
              </OverflowPopover>
              <SecuredRemoveEmail employer={employer} />
            </div>
          );
        }

        return <SecuredSetupEmail employer={employer} />;
      },
    },
    {
      title: 'AUTOMATED EMAIL STATUS',
      dataIndex: 'automatedEmailStatus',
      key: 'automatedEmailStatus',
      width: '30%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        if (!a?.automatedEmail?.email) {
          return 1;
        }
        if (a?.automatedEmail?.isVerified && !b?.automatedEmail?.isVerified) {
          return 1;
        } else {
          return -1;
        }
      },
      render(colData, employer, index) {
        if (!employer?.automatedEmail?.email) {
          return <div>-</div>;
        }
        if (employer?.automatedEmail?.isVerified) {
          return <>Connection confirmed</>;
        }
        return (
          <div className={styles.inProgresStatusWrapper}>
            <SecuredConnectionPopover />
            Connection pending
            <SecuredVerifyConnection employer={employer} />
          </div>
        );
      },
    },
    {
      title: '# OPEN ITEMS',
      dataIndex: 'openIssues',
      key: 'openIssues',
      width: '20%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        if (a?.issuesLogSummary?.openIssues > b?.issuesLogSummary?.openIssues) {
          return 1;
        } else {
          return -1;
        }
      },
      render: (colData, record, index) => {
        const openIssues = parseInt(
          record?.issuesLogSummary?.openIssues || '0'
        );
        return <>{openIssues ? openIssues : '-'}</>;
      },
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'lastUpdatedTs',
      key: 'lastUpdatedTs',
      width: '35%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (isEmpty(a.lastUpdatedTs)) {
          return 1;
        } else if (isEmpty(b.lastUpdatedTs)) {
          return -1;
        } else {
          return new Date(a.lastUpdatedTs).getTime() >
            new Date(b.lastUpdatedTs).getTime()
            ? 1
            : -1;
        }
      },
      render: (colData, record, index) => {
        const updatedAt = dayjs(colData).format('MMMM D, YYYY h:mm A');
        return isEmpty(colData) ? (
          <div key={index}>-</div>
        ) : (
          <div key={index}>
            {updatedAt}
            <br></br>
            <span className={styles.addedBy}>
              <OverflowPopover
                popoverContent={record?.lastUpdatedByAdminName}
                maxWidth="85%"
              >
                by {record.lastUpdatedByAdminName}
              </OverflowPopover>
            </span>
          </div>
        );
      },
    },
    {
      title: 'EMPLOYER VISIBILITY',
      dataIndex: 'employerVisibilityEnabled',
      key: 'employerVisibilityEnabled',
      width: '20%',
      render: (colData, record) => {
        return (
          <>
            <EmployerVisibilityOption
              onConfirmToggle={() => {
                updateStatus({
                  enabled: record.enabled,
                  employerVisibilityEnabled: !colData,
                  organizationId: record.organizationId,
                  employerId: record.employerId,
                  key: 'ISSUES_LOG',
                }).then(() => {
                  refetch();
                });
              }}
              enabled={record.enabled}
              employerVisibilityEnabled={colData}
              employerName={record.employerName}
              toolName={'Account Log'}
            />
          </>
        );
      },
      align: 'left',
    },
    {
      title: 'ENABLED',
      dataIndex: 'enabled',
      key: 'enabled',
      width: '15%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (a.enabled) {
          return -1;
        } else {
          return 1;
        }
      },
      render: (colData, record, index) => {
        return (
          <>
            {record?.locked ? (
              <Popover
                trigger="hover"
                content={EMPLOYER_ACCESS_LOCKED}
                placement="left"
                overlayClassName="provisionStatusPopover"
              >
                <Switch onChange={() => {}} checked={false} disabled={true} />
              </Popover>
            ) : (
              <IssuesLogEmployerFeatureToggle
                className="employerIssuesLogStatusToggleWrapper"
                onConfirmToggle={(isDeleteChecked) => {
                  updateStatus({
                    enabled: !colData,
                    employerVisibilityEnabled: record.employerVisibilityEnabled,
                    organizationId: record.organizationId,
                    employerId: record.employerId,
                    key: 'ISSUES_LOG',
                    deleteAutomatedEmail: isDeleteChecked,
                  });
                }}
                enabled={colData}
                recordName={record.employerName}
                title="Account Log"
                key={index}
                email={record?.automatedEmail?.email}
              />
            )}
          </>
        );
      },
      align: 'left',
    },
  ];

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();

    setSearchText(_searchText);
  };

  const getMonthYearString = (dateObject: any) => {
    if (dateObject === null) {
      return EMPTY_LABEL;
    }
    const date = new Date(dateObject);
    return `${date.toLocaleString([], {
      month: 'long',
    })} ${date.getFullYear()}`;
  };

  const buildFeatureData = useCallback((wrappers: []) => {
    return wrappers.map((employerFeature: any) => {
      if (employerFeature.featureData) {
        employerFeature = {
          ...employerFeature,

          claimsLastUpdated: getMonthYearString(
            employerFeature.featureData.lastUpdated
          ),
        };
      } else {
        employerFeature = {
          ...employerFeature,
          fundingType: '-',
          claimsLastUpdated: '-',
        };
      }

      return employerFeature;
    });
  }, []);

  useEffect(() => {
    if (featureWrappers) {
      setFilteredWrappers(buildFeatureData(featureWrappers));
    }
  }, [featureWrappers, buildFeatureData, setFilteredWrappers]);

  useEffect(() => {
    setPaginationConfig(paginationConfig);
    setPageIndex(paginationConfig.paginationIndex);
    setPageSize(paginationConfig.filterInfo.limit);
    const { field, order } = (paginationConfig.sorterInfo as any) || {};
    if (isEmpty(order)) {
      setSortOrder('');
    } else if (field == 'employerName' && order == 'descend') {
      setSortOrder('-name');
    } else if (field == 'employerName' && order == 'ascend') {
      setSortOrder('name');
    }
  }, [paginationConfig, isFetching]);

  const emailStatusFilter = () => {
    return (
      <div>
        <AutomatedEmailStatusFilter
          options={[
            {
              label: 'Connection Pending',
              value: ISSUES_LOG_PROVISION_STATUS_IN_PROGRESS,
            },
            {
              label: 'Connection Confirmed',
              value: ISSUES_LOG_PROVISION_STATUS_COMPLETED,
            },
            { label: 'N/A', value: ISSUES_LOG_PROVISION_STATUS_NA },
          ]}
          placeholder="Automated Email Status"
          onSelectItems={(statuses: string[]) => {
            setSelectedStatuses(statuses);
          }}
          selected={[]}
          isFilterClose
        />
      </div>
    );
  };

  const applyFilters = (unfilteredData: any[]) => {
    return unfilteredData.filter((row) => {
      if (!(isEmpty(selectedStatuses) || selectedStatuses.length === 3)) {
        if (
          selectedStatuses.includes(ISSUES_LOG_PROVISION_STATUS_COMPLETED) &&
          row?.automatedEmail?.isVerified
        ) {
          return true;
        } else if (
          selectedStatuses.includes(ISSUES_LOG_PROVISION_STATUS_NA) &&
          row?.automatedEmail === null
        ) {
          return true;
        } else if (
          selectedStatuses.includes(ISSUES_LOG_PROVISION_STATUS_IN_PROGRESS) &&
          row?.automatedEmail !== null &&
          !row?.automatedEmail?.isVerified
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
  };

  return (
    <div
      className={`${styles.featureTableWrapper} ${styles.accountTeamProvisionWrapper}`}
    >
      <ToolList
        title="Account Log Configuration"
        columns={dataColumns}
        data={applyFilters(filteredWrappers)}
        search={'Search Employers'}
        handleSearch={handleSearch}
        isLoading={isFetching}
        filters={emailStatusFilter()}
        isAccountIssuesLog={true}
      />

      <AutomatedEmailSetupModal
        visible={automatedEmailSetupModalOpen}
        onCancel={() => {
          setAutomatedEmailSetupModalOpen(false);
        }}
        employerName={selectedEmployer.employerName}
        onSave={function (): void {
          refetch();
          setAutomatedEmailSetupModalOpen(false);
          showSaveNotification();
        }}
        type={ISSUE_TYPE_ACCOUNT}
        ownerId={selectedEmployer.employerId}
      />

      <AutomatedEmailDeleteModal
        isSupport={false}
        visible={automatedEmailDeleteModal}
        email={selectedEmployer?.automatedEmail?.email}
        type={ISSUE_TYPE_ACCOUNT}
        onCancel={() => {
          setAutomatedEmailDeleteModal(false);
        }}
        onDelete={() => {
          setAutomatedEmailDeleteModal(false);
          refetch();
        }}
        ownerId={selectedEmployer.employerId}
      />

      <ResendAutomatedEmailModal
        visible={resendEmailModalOpen}
        email={selectedEmployer?.automatedEmail?.email}
        onCancel={() => {
          setResendEmailModal(false);
        }}
        onComplete={() => {
          setResendEmailModal(false);
        }}
      />
    </div>
  );
};

export default AccountTeamProvision;
