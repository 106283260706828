import React from 'react';
import styles from './assistantStatusTag.module.less';

type Props = {
  tagValue: string;
};

const AssistantStatusTag = (props: Props) => {
  const { tagValue } = props;
  return <div className={styles.assistantStatusTagWrapper}>{tagValue}</div>;
};

export default AssistantStatusTag;
