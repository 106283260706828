import { ReactNode, memo } from 'react';
import { Col, Row } from 'antd';
import Feedback from 'modules/assistant/components/ControlFeedback/Feedback';
import BouncingDots from 'modules/assistant/components/BouncingDots/BouncingDots';
import { useAppSelector } from 'hooks/redux';
import { buildInitials } from 'util/stringUtil';
import PlanYearLogo from 'assets/images/icon-logo-smal.svg';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import styles from './chatBubbleLayout.module.less';

type ChatBubbleLayoutProps = {
  children: ReactNode;
  isQuestion?: boolean;
  isPending?: boolean;
  content: string;
};

type ChatBubbleWrapperProps = {
  children: ReactNode;
  isPending?: boolean;
  isQuestion: boolean;
};

const LoadingWrapper: React.FC<ChatBubbleWrapperProps> = ({
  isPending,
  children,
  isQuestion,
}: ChatBubbleWrapperProps) => {
  if (isPending && !isQuestion) {
    return <BouncingDots />;
  }

  return <>{children}</>;
};

const emptyUserData = { avatarUrl: '', firstName: '', lastName: '' };

const ChatBubbleLayout = ({
  children,
  isQuestion = false,
  isPending = false,
  content,
}: ChatBubbleLayoutProps) => {
  const { avatarUrl, firstName, lastName } = useAppSelector(
    (state) => state.auth.auth.appBootupInfo || emptyUserData
  );

  const isQuestionBubble = isQuestion && !isPending;

  return (
    <Row
      align="middle"
      justify="center"
      className={isQuestionBubble ? styles.paddingBody : styles.paddingTop}
    >
      <Col xs={24} sm={18} md={14} lg={14} xl={14}>
        <Row className={styles.chatLayoutContainer}>
          <Col xs={4} sm={3} md={3} lg={2} xl={1}>
            {isQuestionBubble ? (
              <ProfileAvatar
                src={avatarUrl}
                content={buildInitials(firstName, lastName)}
              />
            ) : (
              <img src={PlanYearLogo} alt="Plan Year Logo" />
            )}
          </Col>
          <Col
            xs={20}
            sm={21}
            md={21}
            lg={22}
            xl={23}
            className={`${
              isPending ? styles.loaderWrapper : styles.answerWrapper
            }`}
          >
            <LoadingWrapper isPending={isPending} isQuestion={isQuestionBubble}>
              {children}
            </LoadingWrapper>
          </Col>
        </Row>
        {!isQuestionBubble && !isPending && (
          <Row>
            <Col xs={0} sm={1} />
            <Col xs={24} sm={23}>
              <Feedback content={content} />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default memo(ChatBubbleLayout);
