import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import trim from 'lodash/trim';
import isNumber from 'lodash/isNumber';

import PlanYear from 'model/PlanYear';
import TablePagination from 'model/TablePagination';
import PaginationConfig from 'model/PaginationConfig';
import { ERAdmin } from 'model/DraftEmployer';
import BrokerAdmin from 'model/BrokerAdmin';
import Admin from 'model/admin/Admin';
import {
  AdditionalResourcesRadioTypes,
  EditMdvTypes,
} from 'modules/plans/constants';
import { navContexts } from 'constants/authConstants';
import { EMPLOYER_USER_TYPES_DROPDOWN } from 'constants/commonConstants';
import Option from 'model/Option';
import WebLinkType from 'model/plans/WebLinkType';

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

export const SYSTEM_EMPLOYER = 'SYSTEM';
// Defines how long debounce functions should wait for search functions, in milliseconds
export const DEBOUNCE_WAIT_TIME_SEARCH = 250;

// Defines how long debounce functions should wait for search functions, in milliseconds
export const DEBOUNCE_WAIT_TIME_DOCUMENT_READING = 2500;
export const convertEnumToOptions = (enums: any) => {
  return Object.values(enums).map((configs: any) => ({
    value: configs.value,
    label: configs.label,
  }));
};
type InputObject = { [key: string]: string };

export const convertToOptions = (input: InputObject): Option[] => {
  if (!input) {
    return [];
  }
  const result: Option[] = [];
  for (const [key, value] of Object.entries(input)) {
    result.push({ value: key, label: value });
  }
  return result;
};
export const convertToWebLinkType = (input: InputObject): WebLinkType[] => {
  if (!input) {
    return [];
  }
  const result: WebLinkType[] = [];
  for (const [key, value] of Object.entries(input)) {
    result.push({ weblink: value, planDocumentName: key });
  }
  return result;
};

export const convertArrayToOptions = (enums: Array<any>) => {
  return Object.values(enums).map((configs: any) => ({
    value: configs,
    label: configs,
  }));
};

export const getPlanYearRangeWithCurrent = (planYear: PlanYear) => {
  if (!isEmpty(planYear)) {
    return `(${dayjs(planYear.startDate).format('ll')} to ${dayjs(
      planYear.endDate
    ).format('ll')}) ${planYear.current ? '- CURRENT' : ''}`;
  }
  return '-';
};

export const sortPlanYears = (planYears: Array<PlanYear>) => {
  if (!isEmpty(planYears)) {
    return planYears.sort((a: any, b: any) =>
      a.startDate.localeCompare(b.startDate)
    );
  }
};

export const removeFileExtension = (filename: string) => {
  const lastDotIndex = filename.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return filename;
  }
  return filename.substring(0, lastDotIndex);
};

export const convertPaginationConfig = (
  paginationConfig: TablePagination
): PaginationConfig => {
  const { paginationIndex, filterInfo, sorterInfo } = paginationConfig;
  const { limit, searchText } = filterInfo || {};
  const { columnKey, order } = (sorterInfo as any) || {};
  return {
    page: paginationIndex,
    size: limit,
    sort: sorterInfo ? (order === 'descend' ? `-${columnKey}` : columnKey) : '',
    query: searchText,
  };
};

export const isValidEmailFormat = (email: string): boolean => {
  const emailFormat =
    /^(([^<>()[\]\\.,;:\s@&!#$%~"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailFormat.test(String(email).toLowerCase());
};

export const isValidPhoneNumber = (phone: string): boolean => {
  const phoneFormatOne = '^1-\\d{3}-\\d{3}-\\d{4}$'; // valid phone number format 1-222-333-4444
  const phoneFormatTwo = '^1\\d{10}$'; // valid phone number format 12223334444
  return (
    RegExp(phoneFormatOne).test(phone) || RegExp(phoneFormatTwo).test(phone)
  );
};

export const isValidCarrierContactNumber = (phone: string): boolean => {
  const phoneFormatOne = '^[0-9]-\\d{3}-\\d{3}-\\d{4}$'; // valid phone number format 3-222-333-4444
  const phoneFormatTwo = '^[0-9]\\d{10}$'; // valid phone number format 32223334444
  return (
    RegExp(phoneFormatOne).test(phone) || RegExp(phoneFormatTwo).test(phone)
  );
};

export const isPhoneNumberInputOnly = (data: string): boolean => {
  const phoneNumberAllowedChars = new RegExp('^[0-9,-]+$');
  return phoneNumberAllowedChars.test(data);
};

export const isValidWeblink = (weblink: string): boolean => {
  const webLinkFormat = new RegExp(
    'https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,}'
  );
  return webLinkFormat.test(weblink) && !/\s/g.test(weblink.trim());
};

export const isValidDomain = (domains: string) => {
  const domainArray = domains.toString();
  return domainArray.split(',').every(function (d: string) {
    const reg =
      /^[a-z0-9]+\.[a-z0-9]+(\.[a-z0-9]+)?(\/[a-z0-9\-._~%!$&'()*+,;=:@]*)*\/?$/i;
    return reg.test(d.trim());
  });
};

export const isValidWebLink = (domains: string) => {
  const domainArray = domains.toString();
  const webLinkFormatReg = /[<>^{}[\]]/;
  const hasSpecialCharacters = webLinkFormatReg.test(domainArray);

  if (hasSpecialCharacters) {
    return false;
  } else {
    return true;
  }
};

export const isValidEmail = (domains: string) => {
  const domainArray = domains.toString();
  return domainArray.split(',').every(function (d: string) {
    const reg = /^[A-Za-z0-9-]+\.[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)?$/;
    return reg.test(d.trim());
  });
};

export const splitAndTrim = (values: string) => {
  return values.split(',').map((element) => element.trim());
};

export const hasDuplicates = (array: string[]): boolean => {
  return array.some((element: string, index: number) => {
    return array.indexOf(element) !== index;
  });
};
export const isValidEINTaxID = (einTaxId: string): boolean => {
  const phoneFormat = '\\d{2}-\\d{7}$';
  return RegExp(phoneFormat).test(einTaxId);
};

type Admins = ERAdmin | BrokerAdmin | Admin;

export const adminNameSorter = (a: Admins, b: Admins): number => {
  const { firstName = '', lastName = '' } = a;
  const { firstName: firstNameB = '', lastName: lastNameB = '' } = b;

  const lastNameSortResult = lastName.localeCompare(lastNameB);
  return lastNameSortResult !== 0
    ? lastNameSortResult
    : firstName.localeCompare(firstNameB);
};

export const openLink = (webLink: string) => {
  if (webLink) {
    return window.open(
      webLink.match(/^http[s]?:\/\//) ? webLink : 'http://' + webLink,
      '_blank'
    );
  }
};

export const validateResoursesFields = (
  planObject: { weblink: string; file: string },
  type: string
) => {
  const { weblink, file } = planObject;
  switch (type) {
    case AdditionalResourcesRadioTypes.WEBLINK:
      // replaced isValidWeblink with isValidDomain for weblink validation
      return isValidDomain(weblink);
    case AdditionalResourcesRadioTypes.FILE:
      return trim(file);
    default:
      break;
  }
};

export const FORMAT_VALIDATE: string = 'FORMAT';
export const SIZE_VALIDATE: string = 'SIZE';
export const TOTAL_UPLOAD_SIZE: string = 'TOTAL_UPLOAD_SIZE';
export const INVALID_WEBLINK: string = 'INVALID_WEBLINK';

export const getValidationMessage = (validateSetting: string): string => {
  const lowerCaseKeys = [
    ' pdf',
    ' doc',
    ' docx',
    ' xls',
    ' xlsx',
    ' png',
    ' jpg',
    ' jpeg',
  ];

  switch (validateSetting) {
    case FORMAT_VALIDATE:
      return `This file type is not supported. You can only upload ${lowerCaseKeys?.toString()}  file types.`;
    case SIZE_VALIDATE:
      return 'Maximum size allowed for this upload is 100 MB.';
    case INVALID_WEBLINK:
      return 'Invalid weblink';
    default:
      return '';
  }
};

export const getNavContext = (params: any) => {
  if (params.brokerId && params.employerId) {
    return navContexts.er;
  } else if (params.brokerId) {
    return navContexts.broker;
  } else {
    return navContexts.platform;
  }
};

export const getNavigateUrl = (context: string) => {
  if (context === navContexts.platform) {
    return `/carriers/:carrierId`;
  } else if (context === navContexts.broker) {
    return `/brokers/:brokerId/carriers/:carrierId`;
  } else {
    return `/brokers/:brokerId/employers/:employerId/carriers/:carrierId`;
  }
};

export const mapValueToLabel = (value: string, map: any) => {
  let label = null;
  Object.keys(map).forEach((key) => {
    if (map[key].value === value) {
      label = map[key].label;
    }
  });
  return label;
};

export const currencyFormatter = (
  value: number,
  format?: string,
  currency?: string,
  minimumFractionDigits?: number
) => {
  const formatter = new Intl.NumberFormat(format || 'en-US', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits:
      minimumFractionDigits !== undefined ? minimumFractionDigits : 2,
  });
  return isFinite(value) ? formatter.format(value) : '';
};

export const currencyFormatterWithDecimalPlaceIfExists = (value: number) => {
  return currencyFormatter(value, 'en-US', 'USD', value % 1 === 0 ? 0 : 2);
};

export const currencyFormatterWithAnyNumberDecimalPlaces = (value: number) =>
  isFinite(value) ? `$${value}` : '';

export const currencyFormatterNoDecimals = (
  value: number,
  format?: string,
  currency?: string
) => {
  return currencyFormatter(Math.round(value), format, currency, 0);
};

export const currencyFormatterWithoutZeros = (
  value: number,
  format?: string,
  currency?: string,
  minimumFractionDigits?: number
) => {
  if (!value) {
    value = 0;
  }
  if (!minimumFractionDigits && value.toString().match(/\./) === null) {
    minimumFractionDigits = 0;
  }
  return currencyFormatter(value, format, currency, minimumFractionDigits);
};

export const shortenCurrencyWithSuffix = (
  num: number,
  digits?: number | undefined
) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i: number;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return `$${
    (num / si[i].value).toFixed(digits || 2).replace(rx, '$1') + si[i].symbol
  }`;
};

export const percentagePostfix = (value: number) =>
  isNumber(value)
    ? `${Number.isInteger(value) ? value : value.toFixed(2)}%`
    : '0%';

export const percentagePostfixWithDecimal = (value: number) =>
  value && isNumber(value) && value != 0
    ? `${Number.isInteger(value) ? value : value.toFixed(2)}%`
    : '0.00%';

export const isRichTextEditorEmpty = (value: string) => {
  const emptyReplaced = value
    .replaceAll('&nbsp;', '')
    .replaceAll('<p>', '')
    .replaceAll('</p>', '');
  return isEmpty(emptyReplaced.trim());
};

// For creating IDs for test automation
export const generateID = (id: string) => {
  return id.replace(/ /g, '_').toLowerCase();
};

export const filterByLabel = (input: string, option: any) => {
  return (option!.label as unknown as string)
    .toLowerCase()
    .includes(input.toLowerCase().trim());
};

export const onlyNumberInputUtil = (event: any) => {
  const regex = /^[0-9\b]+$/;
  if (!regex.test(event.key) && event.key !== 'Backspace') {
    event.preventDefault();
  }
};

/**
 * To get a key from an object given that the value for that key is known.
 * Will return '', if for some reason the key is not found
 * @param {any} item - The enum / map / object. Must be serializable
 * @param {any} value - The value whose key we search for
 * @return {string} - The key or ''
 */
export const getKeyFromValue = <T extends Object>(
  item: T,
  value: T[keyof T]
): keyof T => {
  const entry = Object.entries(item).find(([_key, val]) => val == value);
  return (entry ? entry[0] : '') as keyof T;
};

/**
 * Append positive negatives based on the parsed value
 * @param {any} value - any value
 * @return {string} - the applicable symbol
 */
export const appendPlusOrMinusBasedOnValue = (value: any): string => {
  if (!value) {
    return '';
  }
  const formattedNumber: number = parseFloat(value);

  if (formattedNumber < 0) return '-';
  if (formattedNumber === 0) return '';

  return '+';
};

export const removeCurrencySymbols = (content: string): number => {
  const convertContentToString = `${content}`;
  return Number(convertContentToString?.replace(/[$,]/g, ''));
};

/**
 * Will return true if the given string is null, undefined, whitespace, or empty
 * @param {string} str A string
 * @return {boolean}
 */
export const isBlank = (str?: string): boolean => {
  const len = str?.trim().length;
  return len === undefined ? true : len < 1;
};

export const removeSpecialCharactersFromString = (inputString: string) => {
  if (inputString === undefined || inputString === '') {
    return '';
  }
  const replacedString = inputString.replace(
    /[&\/\\#, +()$~%.'":*?<>{}]/g,
    '_'
  );
  return replacedString;
};

export const resetPaginationConfig = (
  paginationConfig: TablePagination
): TablePagination => {
  paginationConfig.paginationIndex = 1;
  paginationConfig.filterInfo.limit = 10;
  return paginationConfig;
};

export const daysPostFix = (value: number) =>
  isNumber(value)
    ? `${Number.isInteger(value) ? value : value.toFixed(2)} days`
    : '';

export const weeksPostFix = (value: number) =>
  isNumber(value)
    ? `${Number.isInteger(value) ? value : value.toFixed(2)} weeks`
    : '';

export const getEditedPlanField = (editedField: string): string => {
  switch (editedField) {
    case EditMdvTypes.DEDUCTIBLES_OOP_MAX.value:
      return 'DEDUCTIBLES';
    case EditMdvTypes.SERVICES.value:
      return EditMdvTypes.SERVICES.value;
    case EditMdvTypes.RX.value:
      return 'RX_COST';
    case EditMdvTypes.RATES.value:
      return EditMdvTypes.RATES.value;
    case EditMdvTypes.ENROLLMENTS.value:
      return EditMdvTypes.ENROLLMENTS.value;
    default:
      return editedField;
  }
};

interface Location {
  locationName: string;
  enabled: boolean;
}

export const getEnabledLocations = (locations: Location[]): string[] => {
  const enabledLocations = locations?.filter((location) => location.enabled);
  const allEnabled = enabledLocations?.length === locations?.length;

  if (allEnabled) {
    return [];
  } else {
    return enabledLocations?.map((location) => location.locationName);
  }
};

export const handleNumericKeyPress = (e: any) => {
  if (!/[0-9]/.test(e.key)) {
    e.preventDefault();
  }
};

export const addSpan = (text: string, secureText: string) => {
  const textWithSpan = text?.replace(
    secureText,
    `<span style="background-color: #00AFD733;">${secureText}</span>`
  );
  return textWithSpan;
};

export const removeSpan = (text: string) => {
  const sanitizedText = text?.replace(/<\s*\/?s*span[^>]*>/g, '');
  return sanitizedText;
};

export const navigateToKnowledgeBase = (knowledgeBaseUrl: string) => {
  window.open(knowledgeBaseUrl, '_blank');
};

export const mailToSupport = (supportEmail: string) => {
  window.location.href = `mailto:${supportEmail}`;
};

export const getLabelFromValue = (value: string) => {
  const userType = EMPLOYER_USER_TYPES_DROPDOWN.find(
    (type) => type.value === value
  );
  return userType ? userType.label : '-';
};
