import { FC } from 'react';
import { Col, Row } from 'antd';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { CarrierContactDetailTypes } from 'model/CarrierContact';
import styles from './carrierContactInfo.module.less';

type CarrierContactDetailsProps = {
  carrierContactData: CarrierContactDetailTypes;
};
type CarrierContactRowProps = {
  fieldName: string;
  value: string;
};

const AdminDetailRow: FC<CarrierContactRowProps> = (
  props: CarrierContactRowProps
) => {
  const { fieldName, value } = props;
  return (
    <Row>
      <Col span={6} className={styles.columnHeader}>
        {fieldName}
      </Col>
      <Col className={styles.columnValue}>
        <OverflowPopover popoverContent={value} maxWidth="300px">
          {value}
        </OverflowPopover>
      </Col>
    </Row>
  );
};

const CarrierContactInfo: FC<CarrierContactDetailsProps> = (
  props: CarrierContactDetailsProps
) => {
  const { carrierContactData } = props;

  return (
    <div className={styles.brokerAdminDetailsWrapper}>
      <AdminDetailRow fieldName="Name" value={carrierContactData?.fullName} />
      <AdminDetailRow
        fieldName="Carrier"
        value={carrierContactData?.associatedCarrierName}
      />
      <AdminDetailRow
        fieldName="Title"
        value={carrierContactData?.title || '-'}
      />
      <AdminDetailRow fieldName="Email" value={carrierContactData?.email} />
      <AdminDetailRow
        fieldName="Phone"
        value={carrierContactData?.phone || '-'}
      />
    </div>
  );
};

export default CarrierContactInfo;
