import axios from 'axios';
import { baseApi } from 'util/apiUtil';

export const getIssuesLogConfigurationsByUniqueName = (uniqueName: string) => {
  return axios.get(
    `${baseApi}/v2/organizations/issues-log?unique-name=${uniqueName}`
  );
};

export const validateDuplicateEmployerAutomatedEmails = (email: string) => {
  return axios.get(
    `${baseApi}/v2/employers/issues-log-automated-emails/duplicate-validate/${email}`
  );
};

export const validateDuplicateOrganizationAutomatedEmails = (email: string) => {
  return axios.get(
    `${baseApi}/v2/organizations/issues-log-automated-emails/duplicate-validate/${email}`
  );
};
