import { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'antd';
import { useParams } from 'react-router-dom';

import { useAppSelector } from 'hooks/redux';
import Button from 'components/buttons/Button/Button';
import TextButton from 'components/buttons/TextButton/TextButton';

import EmployerSoldConfirmationMailStep from 'modules/renewals/components/soldConfirmation/EmployerSoldConfirmationMailStep/EmployerSoldConfirmationMailStep';
import { generateEmployerEmailBody } from 'modules/renewals/utils/soldConfirmationUtil';

import {
  useLazyGetIndividualByIdQuery,
  useSendSoldConfirmationMailForEmployerMutation,
} from 'modules/renewals/slices/soldConfirmationSlice';
import {
  getExportProposalSummaryFile,
  useLazyGetProposalByIdQuery,
  useLazyGetProposalSummaryExportFilterOptionsQuery,
  useProposalStatusUpdateMutation,
} from 'modules/renewals/slices/proposalSlice';
import { OFFER_BENEFIT_TYPES } from 'modules/renewals/constants/renewalsConstants';

import styles from './employerSoldConfirmationModal.module.less';

type Props = {
  finalApprovalId: string;
  visible: boolean;
  proposalName: string;
  effectiveDateRange: string;
  onClose: () => void;
  setIsFinalApprovedCompleted: (isVisible: boolean) => void;
  isAlreadyFinalApproved?: boolean;
  setIsEmployerSoldConfirmationSent?: (isSent: boolean) => void;
};

export type EmailInputStateProps = {
  defaultMailList: string[];
  emailList: string[];
  isValidList: boolean;
  isEmptyList: boolean;
  removableDefaultMailList: string[];
};

const EmployerSoldConfirmationModal = (props: Props) => {
  const {
    visible,
    onClose,
    finalApprovalId,
    proposalName,
    effectiveDateRange,
    setIsFinalApprovedCompleted,
    isAlreadyFinalApproved = false,
    setIsEmployerSoldConfirmationSent = () => {},
  } = props;

  const employerObj: any = useAppSelector((state) => state?.layout?.employer);
  const { employerId } = useParams();

  const [form] = Form.useForm();

  const [toEmailInputState, setToEmailInputState] =
    useState<EmailInputStateProps>({
      defaultMailList: [],
      emailList: [],
      isValidList: false,
      isEmptyList: false,
      removableDefaultMailList: [],
    });

  const [ccEmailInputState, setCCEmailInputState] =
    useState<EmailInputStateProps>({
      defaultMailList: [],
      removableDefaultMailList: [],
      emailList: [],
      isValidList: false,
      isEmptyList: true,
    });
  const [emailBodyValue, setEmailBodyValue] = useState('');
  const [isEmailBodyEmpty, setIsEmailBodyEmpty] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState({
    type: '',
    visible: false,
    message: '',
  });
  const [defaultFile, setDefaultFile] = useState<File>();

  // Selectors
  const appBootInfo: any = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );
  // RTK queriers

  const [getIndividualByIdQuery, { data: loggedUser }] =
    useLazyGetIndividualByIdQuery();

  const [getProposalById, { data: proposal }] = useLazyGetProposalByIdQuery();

  const [
    sendSoldConfirmationForEmployer,
    { isSuccess: isSoldConfirmationSent, isLoading: isSendConfirmationLoading },
  ] = useSendSoldConfirmationMailForEmployerMutation();

  const [
    publishProposal,
    {
      isSuccess: isPublishProposalSuccess,
      isLoading: isPublishProposalLoading,
    },
  ] = useProposalStatusUpdateMutation();

  const [
    getProposalSummaryExportFilterOptions,
    { data: proposalOfferNamesData },
  ] = useLazyGetProposalSummaryExportFilterOptionsQuery();

  useEffect(() => {
    getIndividualByIdQuery({ individualId: appBootInfo?.individualId });
  }, [appBootInfo?.individualId, getIndividualByIdQuery]);

  useEffect(() => {
    getProposalById({ proposalId: finalApprovalId });
  }, [finalApprovalId, getProposalById]);

  useEffect(() => {
    getProposalSummaryExportFilterOptions({
      proposalId: finalApprovalId,
      upcomingPlanYearId: proposal?.effectiveDateId ?? '',
    });
    // eslint-disable-next-line
  }, [finalApprovalId, proposal]);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        if (proposal && proposalOfferNamesData) {
          await getExportProposalSummaryFile(
            employerId,
            proposal?.effectiveDateId,
            finalApprovalId,
            proposalOfferNamesData?.categories,
            employerObj.name,
            [...proposalOfferNamesData.offersInProposal],
            false
          ).then((response) => setDefaultFile(response));
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchFile();
    // eslint-disable-next-line
  }, [
    proposal,
    proposalOfferNamesData,
    employerId,
    employerObj,
    finalApprovalId,
  ]);

  useEffect(() => {
    if (isPublishProposalSuccess) {
      setIsFinalApprovedCompleted(true);
    }
    // eslint-disable-next-line
  }, [isPublishProposalSuccess, setIsFinalApprovedCompleted]);

  useEffect(() => {
    if (isSoldConfirmationSent) {
      setIsFinalApprovedCompleted(true);
      setIsEmployerSoldConfirmationSent(true);
    }
    // eslint-disable-next-line
  }, [isSoldConfirmationSent, setIsFinalApprovedCompleted]);

  useEffect(() => {
    const defaultMail = [loggedUser?.email];
    const removableDefaultMail = employerObj?.issuesLogAccountEmail?.email
      ? [employerObj.issuesLogAccountEmail?.email]
      : [];
    setCCEmailInputState({
      ...ccEmailInputState,
      defaultMailList: defaultMail,
      removableDefaultMailList: removableDefaultMail,
    });
    form.setFieldsValue({
      subject: `${
        employerObj ? employerObj.name ?? '' : ''
      } - Final Decisions Confirmation - ${effectiveDateRange}`,
      ccList: [...defaultMail, ...removableDefaultMail],
    });

    const dateMatcher = effectiveDateRange?.match(/^([a-zA-Z]+ \d+, \d{4})/);
    const effectiveStartDate = dateMatcher ? dateMatcher[1] : '';

    const dateObject = new Date(effectiveStartDate);

    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();

    const formattedDate = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;

    const selectedBenefitTypes = [];
    if (
      !(
        proposal?.primaryMedicalOffer === null ||
        proposal?.primaryMedicalOffer === undefined
      ) ||
      proposal?.supplementaryMedicalOffers?.length > 0
    ) {
      selectedBenefitTypes.push(OFFER_BENEFIT_TYPES.MEDICAL);
    }
    if (
      proposal?.dentalOffers?.length > 0 ||
      !(
        proposal?.primaryDentalOffer === null ||
        proposal?.primaryDentalOffer === undefined
      ) ||
      proposal?.supplementaryDentalOffers?.length > 0
    ) {
      selectedBenefitTypes.push(OFFER_BENEFIT_TYPES.DENTAL);
    }
    if (
      proposal?.visionOffers?.length > 0 ||
      !(
        proposal?.primaryVisionOffer === null ||
        proposal?.primaryVisionOffer === undefined
      ) ||
      proposal?.supplementaryVisionOffers?.length > 0
    ) {
      selectedBenefitTypes.push(OFFER_BENEFIT_TYPES.VISION);
    }
    if (proposal?.lifeAddOffers?.length > 0) {
      selectedBenefitTypes.push(OFFER_BENEFIT_TYPES.LIFE);
    }
    if (proposal?.lifeSTDOffers?.length > 0) {
      selectedBenefitTypes.push(OFFER_BENEFIT_TYPES.STD);
    }
    if (proposal?.lifeLTDOffers?.length > 0) {
      selectedBenefitTypes.push(OFFER_BENEFIT_TYPES.LTD);
    }
    if (proposal?.volLifeOffers?.length > 0) {
      selectedBenefitTypes.push(OFFER_BENEFIT_TYPES.VOLUNTARY_BENEFIT);
    }

    setEmailBodyValue(
      generateEmployerEmailBody(
        employerObj ? employerObj?.name ?? '' : '',
        selectedBenefitTypes.length == 0
          ? ''
          : selectedBenefitTypes.length == 1
          ? selectedBenefitTypes[0]
          : selectedBenefitTypes
              .slice(0, selectedBenefitTypes.length - 1)
              .join(', ') +
            ' and ' +
            selectedBenefitTypes[selectedBenefitTypes.length - 1],
        formattedDate
      )
    );
    // eslint-disable-next-line
  }, [
    effectiveDateRange,
    employerObj,
    loggedUser,
    proposal,
    setCCEmailInputState,
  ]);

  useEffect(() => {
    let time: any;
    if (showAlertMessage.visible) {
      time = setTimeout(() => {
        setShowAlertMessage({ type: '', visible: false, message: '' });
      }, 15000);
    }
    return () => clearTimeout(time);
    // eslint-disable-next-line
  }, [showAlertMessage.visible]);

  // handle TO list in email chip
  const handleGetToEmailList = ({
    emailList = [],
    isValidList = false,
    isEmptyList = false,
    defaultMailList = [],
  }: Partial<EmailInputStateProps>) => {
    setToEmailInputState({
      defaultMailList: defaultMailList,
      emailList,
      isValidList,
      isEmptyList,
      removableDefaultMailList: [],
    });
    form.setFieldsValue({ toList: emailList });
  };

  // handle CC list in email chip
  const handleGetCCEmailList = ({
    emailList = [],
    isValidList = false,
    isEmptyList = false,
    defaultMailList = [],
    removableDefaultMailList = [],
  }: Partial<EmailInputStateProps>) => {
    setCCEmailInputState({
      defaultMailList: defaultMailList,
      removableDefaultMailList: removableDefaultMailList,
      emailList,
      isValidList,
      isEmptyList,
    });
    form.setFieldsValue({
      ccList: emailList,
    });
  };

  const handleFileUpload = (fileList: any) => {
    form.setFieldsValue({ files: fileList });
  };

  const handleFinalApprovedWithEmail = () => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          soldConfirmationId: '',
          soldConfirmationSubject: values.subject,
          soldConfirmationBody: values.emailBody,
          soldConfirmationTo: values.toList,
          soldConfirmationCC: values.ccList,
          files: values.files,
          proposalId: finalApprovalId,
        };

        if (!isAlreadyFinalApproved) {
          publishProposal({
            proposalId: finalApprovalId,
            proposalStatus: 'APPROVED',
            isNotificationActive: true,
            skipBrokerEmailNotification: true,
          });
        }

        sendSoldConfirmationForEmployer(data);
      })
      .catch((err) => {
        const emailBodyCheck = err.errorFields?.some((item: any) =>
          item.name?.includes('emailBody')
        );
        emailBodyCheck && setIsEmailBodyEmpty(true);
      });
  };

  const handleFinalApproved = () => {
    publishProposal({
      proposalId: finalApprovalId,
      proposalStatus: 'APPROVED',
      isNotificationActive: true,
    });
  };

  const title = (
    <>
      <Row>
        <Col xs={24} className={styles.titleWrapper}>
          <span className={styles.title}>
            {isAlreadyFinalApproved
              ? 'Send Confirmation'
              : 'Mark as Final Approved'}
          </span>
        </Col>
        <Col xs={24} className={styles.subTitleWrapper}>
          {`${
            employerObj ? employerObj.name ?? '' : ''
          } - ${proposalName} - ${effectiveDateRange}`}
        </Col>
      </Row>
    </>
  );

  const footer = (
    <>
      <Row>
        <Col xs={24}>
          <Button
            className={styles.sendBtn}
            type="primary"
            label={
              isAlreadyFinalApproved
                ? 'Send Confirmation'
                : 'Send Confirmation and Mark as Final Approved'
            }
            onClick={handleFinalApprovedWithEmail}
            loading={isPublishProposalLoading || isSendConfirmationLoading}
          />
        </Col>
        {!isAlreadyFinalApproved && (
          <Col xs={24}>
            <Button
              className={styles.sendBtn}
              type="ghost"
              label="Mark as Final Approved, But Send Confirmation Later"
              onClick={handleFinalApproved}
              loading={isPublishProposalLoading}
            />
          </Col>
        )}
        <Col xs={24}>
          <TextButton type="primary" label="Cancel" onClick={onClose} />
        </Col>
      </Row>
    </>
  );

  return (
    <Modal
      wrapClassName={styles.modalWrapperContainer}
      visible={visible}
      width={850}
      destroyOnClose={true}
      centered={true}
      title={title}
      footer={footer}
    >
      <Form form={form} preserve={false}>
        <EmployerSoldConfirmationMailStep
          emailBodyValue={emailBodyValue}
          toEmailInputState={toEmailInputState}
          ccEmailInputState={ccEmailInputState}
          handleGetToEmailList={handleGetToEmailList}
          handleGetCCEmailList={handleGetCCEmailList}
          isEmailBodyEmpty={isEmailBodyEmpty}
          setIsEmailBodyEmpty={setIsEmailBodyEmpty}
          loggedUser={loggedUser?.email}
          handleFileUpload={handleFileUpload}
          issueLogEmail={
            employerObj?.issuesLogAccountEmail
              ? employerObj.issuesLogAccountEmail?.email
              : ''
          }
          defaultFileList={defaultFile ? [defaultFile] : []}
          form={form}
        />
      </Form>
    </Modal>
  );
};

export default EmployerSoldConfirmationModal;
