export const COLOR_DARK_SEA_GREEN = '#3EADAB';
export const COLOR_MEDIUM_SEA_GREEN = '#28B667';
export const COLOR_LIGHT_ORANGE = '#F9C747';
export const COLOR_DARK_ORANGE = '#F9C747';
export const COLOR_GOLDEN_ORANGE = '#F5A623';
export const COLOR_GOLDEN_YELLOW = '#F5C123';
export const COLOR_GOLDEN_OLIVE = '#D1C700';
export const COLOR_LIGHT_OLIVE = '#ADAB2D';
export const COLOR_LIGHT_FOREST_GREEN = '#7CAD2D';
export const COLOR_FOREST_SEA_GREEN = '#2DAD53';
export const COLOR_DARK_AQUA_MARINE = '#2DAD7D';
export const COLOR_DARK_DODGER_BLUE = '#2D84AD';
export const COLOR_VAVY_BLUE = '#114E83';

export const COLOR_BADGE_BLUE = '#2b66aa';
export const COLOR_BADGE_YELLOW = '#f5bb2e';
export const COLOR_BADGE_RED = '#ff4d4f';
export const COLOR_MEDIUM_LIGHT_ORANGE = '#FDAE1F';
export const COLOR_LIGHT_GREY = '#EBECED';
export const COLOR_WHITE = '#FFF';
export const COLOR_LOSS_RATIO_YELLOW = '#F9C747';
export const COLOR_BILLING_CHART_NON_HOVER = '#3EA9A733';
export const COLOR_BILLING_CHART_STOKE = '#E9E9E9';
export const COLOR_GREY = '#F7F8FA';
export const COLOR_LIGHT_GREEN = '#F1FFF1';
export const COLOR_DARK_GREY = '#BBBCBF';
export const COLOR_LIGHT_GRAY = '#F0F0F0';
export const COLOR_DARK_GREY_LIGHT_SHADE = '#F0F0F0';

export const COLOR_LEGEND_LABEL_SHADE = '#758184';
export const COLOR_CHART_EMPTY_SECTION = 'rgb(216, 216, 216)';

export const COLOR_DUMMY_CHART_AREA = '#00000014';
export const COLOR_DUMMY_CHART_AREA_PREMIUM = '#75736F';

export const BENEFIT_KIND_MEDICAL = 'Medical';
export const BENEFIT_KIND_LIFE = 'BASIC_LIFE_AND_ADD';
export const BENEFIT_KIND_DENTAL = 'Dental';
export const BENEFIT_KIND_VISION = 'Vision';
export const BENEFIT_KIND_RX = 'Rx';
export const BENEFIT_KIND_SHORT_TERM_DISABILITY = 'STD';
export const BENEFIT_KIND_LONG_TERM_DISABILITY = 'LTD';
export const BENEFIT_KIND_BASIC_LIFE = 'Basic Life';
export const BENEFIT_KIND_VOLUNTARY_LIFE = 'Voluntary Life';

export const HIDDEN_MONTH_TEXT = 'HIDDEN';

export const LARGE_CLAIMS = 'LARGE_CLAIMS';
export const LRCB = 'LRCB';

export const FUNDING_TYPE = {
  FULLY_INSURED: 'FULLY_INSURED',
  SELF_FUNDED: 'SELF_FUNDED',
};

export const FILE_NAME = 'Download the source file';

export const BENEFIT_KIND_LIST = [
  BENEFIT_KIND_MEDICAL,
  BENEFIT_KIND_DENTAL,
  BENEFIT_KIND_VISION,
  BENEFIT_KIND_LIFE,
  BENEFIT_KIND_SHORT_TERM_DISABILITY,
  BENEFIT_KIND_LONG_TERM_DISABILITY,
  BENEFIT_KIND_BASIC_LIFE,
  BENEFIT_KIND_VOLUNTARY_LIFE,
];

export const BENEFIT_KIND_LABEL_MAP: { [key: string]: string } = {
  [BENEFIT_KIND_MEDICAL]: BENEFIT_KIND_MEDICAL,
  [BENEFIT_KIND_DENTAL]: BENEFIT_KIND_DENTAL,
  [BENEFIT_KIND_VISION]: BENEFIT_KIND_VISION,
  [BENEFIT_KIND_LIFE]: 'Life / AD&D',
  [BENEFIT_KIND_SHORT_TERM_DISABILITY]: 'Short Term Disability',
  [BENEFIT_KIND_LONG_TERM_DISABILITY]: 'Long Term Disability',
};

export const UP_CONSOLIDATE_CLAIMS = 'UP_CONSOLIDATE_CLAIMS';

export const SELF_FUNDED_COST_CATEGORY: { [key: string]: any } = {
  TOTAL_COST_PER_MONTH: {
    label: 'Total Cost',
    value: 'TOTAL_COST_PER_MONTH',
    short: 'Total Cost',
  },
  PER_MEMBER_PER_MONTH: {
    label: 'PMPM (Per Member Per Month)',
    value: 'PER_MEMBER_PER_MONTH',
    short: 'Per Member',
  },
  PER_EMPLOYEE_PER_MONTH: {
    label: 'PEPM (Per Employee Per Month)',
    value: 'PER_EMPLOYEE_PER_MONTH',
    short: 'Per Employee',
  },
};

export const SELF_FUNDED_COST_COLORS = {
  ORANGE: '#F67B59',
  GREEN: '#64BDBB',
  YELLOW: '#F7CD4F',
  TRANSPARENT: '#FFFFFF00',
};

export const SFC_CHART_LEGENDS = {
  CLAIMS: { label: 'Claims', color: '#64BDBB' },
  FIXED_FEES: { label: 'Fixed Fees', color: '#F67B59' },
};

export const SFC_MEDICAL_CHART_LEGENDS = {
  ...SFC_CHART_LEGENDS,
  STOP_LOSS_PAID: { label: 'Stop-Loss Paid', color: '#F7CD4F' },
};

export const BENIFIT_TYPES: any = {
  MEDICAL: { code: 'MEDICAL', title: 'Medical' },
  VISION: { code: 'VISION', title: 'Vision' },
  DENTAL: { code: 'DENTAL', title: 'Dental' },
  BASIC_LIFE: { code: 'BASIC_LIFE', title: 'Basic Life' },
  Basic_LTD: { code: 'Basic_LTD', title: 'LTD' },
  Basic_STD: { code: 'Basic_STD', title: 'STD' },
  Basic_AD_D: { code: 'AD&D', title: 'STD' },
  VOLUNTARY_LIFE_W_AD_D: {
    code: 'VOLUNTARY_LIFE_W_AD_D&D',
    title: 'Voluntary Life',
  },
};

export const claimsTypes: { [key: string]: { value: string; label: string } } =
  {
    FULLY_INSURED: { value: 'FULLY_INSURED', label: 'Fully-Insured' },
    SELF_INSURED: { value: 'SELF_INSURED', label: 'Self-Funded' },
  };

export const CLAIMS_IMPORT_FILE_TYPE_ERROR_MSG = `Invalid file type uploaded. Submitted files must be in .xlsx format.`;
export const CLAIMS_IMPORT_FILE_SIZE_ERROR_MSG = `The submitted file exceeds the file size limit of 100 MB. Please confirm that you are uploading the correct file or reduce the file’s size`;
export const CLAIMS_IMPORT_COMMON_ERROR_MSG = `Looks like you have uploaded a wrong file. Please use the template file given to update the information successfully.`;
export const CLAIMS_IMPORT_YEARS_GAP_ERROR_MSG = `There can be no month/year gap or duplicate value for the date range`;
export const CLAIMS_IMPORT_DUPLICATE_DATE_RANGE_ERROR_MSG = `There are duplicate values for this date range`;
export const CLAIMS_EMPLOYER_NAME_ERROR_CODE = 'employer.name.is.not.matching';
