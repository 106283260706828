import { FC } from 'react';
import AddWorkLifePlan from 'modules/plans/components/AddWorkLifePlan/AddWorkLifePlan';

type EditWorkLifePlanProps = {
  onClose: () => {};
  plan: any;
  isSaveDisabled?: boolean;
  isDBGPlan?: boolean;
  isModalVisible?: boolean;
};

const EditWorkLifePlan: FC<EditWorkLifePlanProps> = ({
  onClose,
  plan,
  isSaveDisabled,
  isDBGPlan,
  isModalVisible,
}: EditWorkLifePlanProps) => {
  const { benefitKind } = plan;
  return (
    <div>
      <AddWorkLifePlan
        benefitKind={{ value: benefitKind }}
        closePlanAddModal={onClose}
        isCloseConfirmed={true}
        onClose={onClose}
        isPlanUpdateMode={true}
        plan={plan}
        isSaveDisabled={isSaveDisabled}
        isDBGPlan={isDBGPlan}
        isModalVisible={isModalVisible}
      />
    </div>
  );
};

export default EditWorkLifePlan;
