import { FC } from 'react';
import { Row } from 'antd';
import { ReactComponent as NoteBook } from 'assets/images/notebook-dark.svg';
import {
  BENEFIT_GUIDE_STATUS,
  NO_BENGUIDE_CARD_DETAILS,
} from 'modules/benefitGuide/constants/benefitGuideConstants';

import styles from './noBenguideView.module.less';

type NoBenguideCardProps = {
  benguideStatus: string;
};

const NoBenguideCard: FC<NoBenguideCardProps> = (props) => {
  const { benguideStatus } = props;
  let title = '';
  let description = '';

  if (benguideStatus === BENEFIT_GUIDE_STATUS.IN_PROGRESS) {
    title = NO_BENGUIDE_CARD_DETAILS[0].title;
    description = NO_BENGUIDE_CARD_DETAILS[0].description;
  } else if (benguideStatus === BENEFIT_GUIDE_STATUS.PUBLISHED) {
    title = NO_BENGUIDE_CARD_DETAILS[1].title;
    description = NO_BENGUIDE_CARD_DETAILS[1].description;
  } else if (benguideStatus === BENEFIT_GUIDE_STATUS.ARCHIVED) {
    title = NO_BENGUIDE_CARD_DETAILS[2].title;
    description = NO_BENGUIDE_CARD_DETAILS[2].description;
  }

  return (
    <div className={styles.noBenguideCard}>
      <div className={styles.noBenguideCardWrapper}>
        <Row className={styles.description}>
          <NoteBook />
        </Row>
        <Row className={`${styles.description} ${styles.title}`}>
          <h2>{title}</h2>
        </Row>
        <Row className={styles.description}>
          <p>{description}</p>
        </Row>
      </div>
    </div>
  );
};

export default NoBenguideCard;
