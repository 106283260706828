import { FC, ReactNode } from 'react';
import { Form } from 'antd';
import { FormInstance, FormProps } from 'antd/lib/form/Form';

import styles from './panelInputForm.module.less';

interface Props extends FormProps {
  forwardedRef?: React.Ref<FormInstance<any>>;
  children: ReactNode;
}

const PanelInputForm: FC<Props> = (props: Props) => {
  const { children, forwardedRef, ...rest } = props;
  return (
    <div className={`${styles.panelInputFormContainer} ${props.className}`}>
      <Form ref={forwardedRef} {...rest}>
        {children}
      </Form>
    </div>
  );
};

export default PanelInputForm;
