import { FC, useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { Col, Row, Form, Menu } from 'antd';
import iconInfo from 'assets/images/icon-info.svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import AlertMessage from 'components/Alert/AlertMessage';
import InfoBanner from 'components/InfoBanner/InfoBanner';
import Expandable from 'components/Expandable/Expandable';
import DataTable from 'components/DataTable/DataTable';
import NextButton from 'components/buttons/NextButton/NextButton';
import { DataColumn } from 'components/DataTable/DataColumn';
import PlanYearForm from 'modules/employers/components/PlanYearForm/planYearForm';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { planYearFormFields } from 'modules/employers/constants/employerConstants';
import DraftEmployer from 'model/DraftEmployer';
import PlanYear from 'model/PlanYear';
import {
  createEmployerFailed,
  createEmployerSuccess,
  processCreateEmployer,
  removeBenefitGroup,
} from 'modules/employers/slices/employerCreateStepperSlice';
import AddBenefitClassForm from 'modules/employers/components/BenefitClass/AddBenefitClassForm';
import { formatDateWithoutTime } from 'util/dateUtil';

import styles from './addBenefitClass.module.less';

type BenefitGroupProps = {
  nextStep: () => void;
};

const AddBenefitClass: FC<BenefitGroupProps> = (props: BenefitGroupProps) => {
  const { nextStep } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [preLoadBenefitGroup, setPreLoadBenefitGroup] = useState<string | null>(
    null
  );
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [visibleAPIError, setVisibleAPIError] = useState<boolean>(false);
  const [isInvalidData, setIsInvalidData] = useState<boolean>(false);
  const [isNextClicked, setIsNextClicked] = useState<boolean>(false);

  const [form] = Form.useForm();

  const benefitGroupActionMenu = (benefitGroupString: string) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => onEditAction(benefitGroupString)}>
        Edit
      </Menu.Item>
      <Menu.Item
        key="remove"
        onClick={() => onRemoveAction(benefitGroupString)}
      >
        Remove
      </Menu.Item>
    </Menu>
  );

  const onEditAction = (editBenefitGroupString: string) => {
    setExpanded(true);
    setPreLoadBenefitGroup(editBenefitGroupString);
  };

  const onRemoveAction = (removeBenefitGroupString: string) => {
    if (benefitGroups && benefitGroups.length <= 1) {
      setVisibleAlert(true);
      setAlertMessage(
        'There must be at least one benefit class for this employer'
      );
      return;
    }
    dispatch(removeBenefitGroup(removeBenefitGroupString));
  };

  const dispatch = useAppDispatch();

  const benefitGroups = useAppSelector(
    (state) => state.employer.employerCreateStepper.benefitGroups
  );

  const employerData = useAppSelector(
    (state) => state.employer.employerCreateStepper
  );

  const { error, requestType } = employerData;

  useEffect(() => {
    if (error && requestType === createEmployerFailed.type) {
      setVisibleAPIError(true);
    }
  }, [error, requestType]);

  useEffect(() => {
    if (
      !employerData.inProgress &&
      employerData.requestType === createEmployerSuccess.type
    ) {
      nextStep();
    }
  }, [employerData.inProgress, employerData.requestType, nextStep]);

  const onInputChange = async (changedValues: any, allValues: any) => {
    if (changedValues.startDate) {
      form.setFieldsValue({
        ...allValues,
        endDate: changedValues.startDate.add(1, 'year').subtract(1, 'day'),
      });
    } else {
      form.setFieldsValue({ ...allValues });
    }
    if (isNextClicked) {
      try {
        await form.validateFields();
        setIsInvalidData(false);
        setVisibleAlert(false);
      } catch (error: any) {
        setIsInvalidData(error.errorFields.length > 0);
        setVisibleAlert(false);
      }
    }
  };

  const validatePlanYear = async () => {
    let isFormValid: boolean = true;
    try {
      await form.validateFields(planYearFormFields.requiredFields);
    } catch (errorInfo) {
      isFormValid = false;
    }
    return isFormValid;
  };

  const onClickNext = async () => {
    setIsNextClicked(true);
    const isFormValid = await validatePlanYear();
    const formData = form.getFieldsValue();
    if (isFormValid) {
      const planYear = {
        benefitGroups: employerData.benefitGroups,
        startDate: formatDateWithoutTime(formData.startDate),
        endDate: formatDateWithoutTime(formData.endDate),
        name: formData.planYear.trim(),
      } as PlanYear;
      const tempEmployer = {
        ...employerData.employer,
        locations: employerData.employer.allLocationsSelected
          ? []
          : employerData.employer.locations,
        planYear,
      } as DraftEmployer;
      dispatch(processCreateEmployer(tempEmployer));
    } else {
      setAlertMessage('Please correct the items in red.');
      setVisibleAlert(true);
    }
    setIsInvalidData(!isFormValid);
  };

  const closeAlert = () => setVisibleAlert(false);

  const columns: DataColumn[] = [
    {
      title: 'BENEFIT CLASS NAME',
      dataIndex: '',
      key: 'benefitGroupName',
      width: '45%',
      sorter: (a, b) => {
        return a.localeCompare(b);
      },
      ellipsis: {
        showTitle: false,
      },
      render: (groupName) => <OverflowPopover>{groupName}</OverflowPopover>,
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      render: (colData: any, record: any) => (
        <SelectDropdown overlay={benefitGroupActionMenu(record)} />
      ),
      align: 'center',
    },
  ];

  return (
    <>
      {visibleAPIError && error && (
        <AlertMessage
          type="error"
          message={`Employer creation failed: ${error.message}`}
          closeAlert={() => setVisibleAPIError(false)}
          className={styles.planYearAlert}
        />
      )}
      <div className={styles.addAdminsContainer}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <InfoBanner
              title="What are Plan Years & Benefit Classes?"
              description="A plan year is a period of time (typically 12 months) in which an employer’s benefits coverage is active under a specific set of plans. Benefit Classes are used to define different groups of employees within the same company who have different eligibility rules, contribution structures, or waiting periods."
              logo={<img src={iconInfo} alt="info-icon" />}
            />
          </Col>
        </Row>
        {visibleAlert && (
          <AlertMessage
            type="error"
            message={alertMessage}
            closeAlert={closeAlert}
            className={styles.planYearAlert}
          />
        )}
        <PlanYearForm
          form={form}
          onInputChange={onInputChange}
          isInvalidData={isInvalidData}
          visibleAlert={visibleAlert}
        />
        <div className={styles.benefitsTableWrapper}>
          <DataTable data={benefitGroups} columns={columns} />
        </div>
        <Expandable
          header="+ Add Another Benefit Class"
          isExpanded={expanded}
          onChange={(expanded) => setExpanded(expanded)}
        >
          <AddBenefitClassForm
            expanded={expanded}
            editBenefitGroup={preLoadBenefitGroup}
            onClose={() => {
              setExpanded(false);
              setPreLoadBenefitGroup(null);
            }}
          />
        </Expandable>
        {!expanded && (
          <NextButton
            nextStep={onClickNext}
            disabled={isEmpty(benefitGroups)}
            buttonText="Save Employer"
          />
        )}
      </div>
    </>
  );
};
export default AddBenefitClass;
