import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, notification, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import reactHtmlParser from 'react-html-parser';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { CloseOutlined, DownOutlined, EyeOutlined } from '@ant-design/icons';
import { ReactComponent as XIcon } from 'assets/images/x.svg';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { useNavContext } from 'hooks/useNavContext';
import InlineEditable from 'components/InlineEditing/InlineEditable';
import IEInput from 'components/InlineEditing/EditableInputs/IEInput/IEInput';
import IESelect from 'components/InlineEditing/EditableInputs/IESelect/IESelect';
import IERichTextEditor from 'components/InlineEditing/EditableInputs/IERichTextEditor/IERichTextEditor';
import {
  INLINE_MX_WIDTH_150,
  INLINE_MX_WIDTH_250,
  PLACEMENT_BOTTOM,
  PLACEMENT_INLINE,
} from 'components/InlineEditing/constants/inlineEditConstant';
import Button from 'components/buttons/Button/Button';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import PriorityBadge from 'modules/issueslog/components/PriorityBadge/PriorityBadge';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import IssueStatusBadge from 'modules/issueslog/components/IssueStatusBadge/IssueStatusBadge';
import IEAdvanceSelect from 'components/InlineEditing/EditableInputs/IEAdvanceSelect/IEAdvanceSelect';
import {
  useLazyGetAllBrokerAdminsQuery,
  useLazyGetBrokerAdminsForEmployerQuery,
  useLazyGetEmployersByBrokerIdQuery,
  useLazyGetFileQuery,
  useLazyGetIssueByIdQuery,
  useLazyGetOrganizationByIdQuery,
  useUpdateIssueMutation,
} from 'modules/issueslog/slices/issuesLogSlice';
import { ReactComponent as PencilIcon } from 'assets/images/icon-pencil.svg';
import iconattachment from 'assets/images/icon-attachment.svg';
import CommentsSection from 'modules/issueslog/components/CommentSection/CommentsSection';
import {
  ISSUE_EDIT_TYPES,
  ISSUE_STATUS_MAP,
  ISSUE_SUBTYPE_MAP,
  ISSUE_TYPE_ACCOUNT,
  ISSUE_TYPE_SUPPORT,
  PRIORIRY_MAP,
  PRIORITY_UNKNOWN,
  STATUS_BLOCKED,
  STATUS_IN_PROGRESS,
  STATUS_RE_OPENED,
  STATUS_RESOLVED,
  SUBTYPE_SPAM,
} from 'modules/issueslog/constants/IssuesLogConstants';
import { getConfirmModelContent } from 'modules/issueslog/utils/issueLogUtils';
import { useAppSelector } from 'hooks/redux';
import { loginTypes } from 'constants/authConstants';

import { getTextEllipsis } from 'util/stringUtil';
import { removeSpecificItemFromStringArray } from 'util/arrayUtil';
import { baseApi } from 'util/apiUtil';

import { AccountStatus } from 'modules/admins/constants/adminConstants';
import { IndividualSubTypes } from 'constants/commonConstants';
import styles from './viewIssue.module.less';

type ConfirmStateProps = {
  visible: boolean;
  value: any;
  type: string;
  updatingValues: any;
};
const visibleToEmployerOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const ViewIssue = () => {
  const params = useParams();
  const { setIssueId, setIssueLogType } = useNavContext();

  // description placeholder ref: uses for get the content height
  const descriptionContentRef = useRef<HTMLDivElement>(null);

  // inlineEditingRefs
  const inlineEditPriorityRef = useRef<any>();
  const inlineDescriptionRef = useRef<any>();
  const inlineTitleRef = useRef<any>();
  const inlineSupportTeamRef = useRef<any>();
  const inlineEmpVisibilityRef = useRef<any>();
  const inlineEmployerRef = useRef<any>();
  const inlineIssueTypeRef = useRef<any>();
  const inlineStatusRef = useRef<any>();
  const inlineAssigneeRef = useRef<any>();

  // Confirmation Modal states
  const [modelConfirmState, setModelConfirmState] = useState<ConfirmStateProps>(
    {
      visible: false,
      value: null,
      type: '',
      updatingValues: null,
    }
  );
  const [resolveErrorModelState, setResolveErrorModelState] =
    useState<ConfirmStateProps>({
      visible: false,
      value: null,
      type: '',
      updatingValues: null,
    });

  const appBootInfo: any = useAppSelector(
    (state) => state?.auth?.auth?.appBootupInfo
  );

  const [getIssue, { data: issueData, isLoading: issueDataLoading }] =
    useLazyGetIssueByIdQuery();

  const [
    getEmployers,
    { data: employerData = [], isLoading: employersLoading },
  ] = useLazyGetEmployersByBrokerIdQuery();

  const attachmentDiv = useRef<any>(null);
  const isNotErAdmin: boolean = appBootInfo?.type !== loginTypes.erAdmin;
  const isPlatformAdmin: boolean = appBootInfo?.type === loginTypes.platform;
  const isEmpMember: boolean =
    appBootInfo?.individualSubType === IndividualSubTypes.EMPLOYER_MEMBER;

  const isCreatedBySameEmpUser: boolean =
    appBootInfo?.workEmail === issueData?.createdBy;

  const disableInlineEdit = isEmpMember && !isCreatedBySameEmpUser;
  const ALLOWED_TYPES = [loginTypes.platform, loginTypes.bokerAdmin];

  const isErAdminSubType =
    appBootInfo?.individualSubType === IndividualSubTypes.EMPLOYER_ADMIN;

  const isShowResolveButton =
    (isEmpMember && isCreatedBySameEmpUser) ||
    ALLOWED_TYPES.includes(appBootInfo?.type) ||
    isErAdminSubType;

  useEffect(() => {
    if (params.issueId) {
      if (!params.employerId) {
        setIssueId(params.issueId);
        setIssueLogType(params?.type || '');
      }
      getIssue({ issueId: params.issueId });
    }
  }, [
    getIssue,
    params.employerId,
    params.issueId,
    params?.type,
    setIssueId,
    setIssueLogType,
  ]);

  // get support get if issue type support
  const [getOrganizationByIdQuery, { data: supportTeamData }] =
    useLazyGetOrganizationByIdQuery();
  const [getIssueAttachment] = useLazyGetFileQuery();

  useEffect(() => {
    if (issueData) {
      getOrganizationByIdQuery({ brokerId: issueData?.organizationId });
      getEmployers({ brokerId: issueData?.organizationId, individualId: null });
    }
    // eslint-disable-next-line
  }, [issueData, params?.brokerId]);

  const supportTeamDropdownList = supportTeamData?.issuesLogSupportEmails?.map(
    (item: any) => ({
      label: item?.name,
      value: item?.name,
    })
  );

  // To get support automated Mail List
  const supportTeamAutomatedMailList = useMemo(() => {
    return (
      supportTeamData?.issuesLogSupportEmails?.map((item: any) => item) || []
    );
  }, [supportTeamData]);

  const getMatchingProvisionedEmail = () => {
    return params.type === ISSUE_TYPE_ACCOUNT.toLowerCase()
      ? supportTeamData?.issuesLogConfigurations?.accountShadowEmail
      : supportTeamData?.issuesLogConfigurations?.supportShadowEmail;
  };

  const getIssueListUrl = () => {
    if (params.brokerId && params.employerId) {
      return `/brokers/${params.brokerId}/employers/${params.employerId}/issues-log/${params.type}`;
    } else if (params.brokerId) {
      return `/brokers/${params.brokerId}/issues-log/${params.type}`;
    } else {
      return `/issues-log/${params.type}`;
    }
  };

  const navigation = [{ name: 'All Items', link: getIssueListUrl() }];

  const SideMenuItem = (props: { title?: string; child?: any; ref?: any }) => {
    const { title, child } = props;
    return (
      <div
        className={`${styles.issueSideMenuContent} ${styles.issueSideMenuItem}`}
      >
        <span className={styles.issueInfoBarLabel}>{title}</span>
        {child || '-'}
      </div>
    );
  };

  const downloadAttachment = (attachment: any) => {
    getIssueAttachment({
      s3Key: attachment.downloadUrl,
      fileName: attachment.fileName,
      downloaded: false,
    }).then((data: any) => {
      if (!data.isError) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        const url = `${baseApi}/v1/issues-log/download?s3-key=${attachment.downloadUrl}&file-name=${attachment.fileName}&downloaded=true`;
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        notification.error({
          closeIcon: <></>,
          top: 40,
          message: 'Download Failure',
          description:
            'The requested download timed out. Please try again or try downloading fewer folders/documents at one time',
          icon: <CloseOutlined className={styles.notificationErrorIcon} />,
        });
      }
    });
  };

  const {
    status,
    priority,
    subType,
    generatedId,
    showInEmployer,
    subject,
    description,
    assigneeId,
    createdTime,
    modifiedTime,
    employerId,
    watchers,
    createdByName,
    modifiedBy,
    supportTeam,
    attachments,
    closedByName,
    closedDate,
  } = issueData || {};

  // state use for editing fields subject, description, empID - uses for fetch broker admins according to location
  const [subjectState, setSubjectState] = useState('');
  const [descriptionState, setDescriptionState] = useState('');

  // state use for chracter limiting in attchment names
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [characterLimit, setCharacterLimit] = useState<number>(27);

  // state use for showing issue title empty error message
  const [isShowEmptyErrorMessage, setIsShowEmptyErrorMessage] =
    useState<boolean>(false);

  // constants booleans for watchers
  const isLoggedUserWatcher = watchers?.includes(appBootInfo?.individualId);

  useEffect(() => {
    setSubjectState(subject);
    setDescriptionState(description);
    // eslint-disable-next-line
  }, [issueData]);

  const [getBrokerAdmins, { data: brokerAdminEmpLocationData }] =
    useLazyGetBrokerAdminsForEmployerQuery();

  const [getAllBrokerAdmins, { data: allBrokerAdminsData }] =
    useLazyGetAllBrokerAdminsQuery();

  useEffect(() => {
    if (isNotErAdmin && issueData) {
      if (issueData?.employerId !== null) {
        getBrokerAdmins({
          brokerId: issueData?.organizationId || '',
          employerId: issueData?.employerId,
          isActiveAndNonActive: issueData?.type === ISSUE_TYPE_SUPPORT,
        });
      } else {
        getAllBrokerAdmins({
          organizationId: issueData?.organizationId,
          onlyActiveIndividuals: true,
        });
      }
    }
    // eslint-disable-next-line
  }, [issueData, isNotErAdmin]);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    if (1350 < screenWidth && screenWidth < 1450) {
      setCharacterLimit(18);
    }
    if (1450 < screenWidth && screenWidth < 1550) {
      setCharacterLimit(21);
    }
    if (1550 < screenWidth && screenWidth < 1750) {
      setCharacterLimit(25);
    }
    if (1750 < screenWidth) {
      setCharacterLimit(27);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [screenWidth]);

  const [updateIssue] = useUpdateIssueMutation();

  const tagEmployerBasedOnSupportTeamChange = useMemo(() => {
    return (
      currentSupportTeam: string,
      returnType?: boolean | string | object | undefined
    ): any => {
      const findTheCurrentSupportTeam = supportTeamAutomatedMailList?.find(
        (team: any) => team?.name === currentSupportTeam
      );

      if (typeof returnType === 'boolean') {
        return findTheCurrentSupportTeam?.taggedEmployer ? true : false;
      }
      if (typeof returnType === 'object') {
        return findTheCurrentSupportTeam?.brokerAdmins || [];
      }

      return !isEmpty(findTheCurrentSupportTeam?.taggedEmployer)
        ? findTheCurrentSupportTeam?.taggedEmployer
        : employerId;
    };
  }, [supportTeamAutomatedMailList, employerId]);

  // common function for update all the edit fields with endpoint
  const handleSubmitUpdate = ({
    updatingValues,
    ref,
    modelClose,
  }: {
    updatingValues: any;
    ref?: any;
    modelClose?: any;
  }) => {
    if (updatingValues.supportTeam) {
      const getTagedAdmins = tagEmployerBasedOnSupportTeamChange(
        updatingValues.supportTeam,
        {}
      );
      if (getTagedAdmins.length === 1) {
        const assignneeDetails = getBrokerAdminsAccordingToEmp()?.find(
          (item: any) => item.value === getTagedAdmins[0]
        );
        updatingValues.assigneeId = assignneeDetails.value;
        updatingValues.assigneeName = assignneeDetails.label;
      }
      updatingValues.employerId = tagEmployerBasedOnSupportTeamChange(
        updatingValues.supportTeam
      );
    }

    const updateObj = {
      ...issueData,
      ...updatingValues,
    };

    updateIssue({ data: updateObj, issueId: issueData?.id }).then(() => {
      ref && ref?.current?.closeEdit();
      modelClose &&
        modelClose({
          visible: false,
          value: null,
          type: '',
          updatingValues: null,
        });
      getIssue({ issueId: `${params.issueId}` });
    });
  };

  const isNvApplicable = (broker: any): boolean => {
    return AccountStatus.ACCOUNT_ACTIVATED !== broker.accountStatus;
  };

  const getBrokerAdminsAccordingToEmp = () => {
    if (issueData?.employerId !== null) {
      const brokerAdminOptions: any[] =
        brokerAdminEmpLocationData
          ?.map((admin: any) => ({
            value: admin.id,
            label: `${admin.firstName} ${admin.lastName}`,
            isTagged:
              tagEmployerBasedOnSupportTeamChange(supportTeam, {})?.includes(
                admin.id
              ) || false,
            isUnVerified:
              AccountStatus.ACCOUNT_ACTIVATED !== admin.accountStatus || false,
            isNvApplicable: isNvApplicable(admin),
          }))
          .sort((a: any, b: any) => {
            if (a.value === assigneeId) {
              return -1; // Put 'a' before 'b' if 'a' matches the assigneeId
            } else if (b.value === assigneeId) {
              return 1; // Put 'b' before 'a' if 'b' matches the assigneeId
            }
            // If neither 'a' nor 'b' match assigneeId, sort by isTagged
            return (b.isTagged ? 1 : 0) - (a.isTagged ? 1 : 0);
          }) || [];

      return brokerAdminOptions;
    } else {
      const filteredAllLocation = allBrokerAdminsData?.filter(
        (admin: any) => admin?.allLocations === true
      );
      const allLocationBrokerAdminOptions = filteredAllLocation?.map(
        (admin: any) => ({
          value: admin?.id,
          label: `${admin?.firstName} ${admin?.lastName}`,
        })
      );
      return allLocationBrokerAdminOptions;
    }
  };

  const issueSubTypeLabel = [
    ...ISSUE_SUBTYPE_MAP,
    { value: SUBTYPE_SPAM, label: 'Not Tracked' },
  ].find((typeData) => typeData.value === subType)?.label;

  const getStatusAccordingToLogin = (loginType: string) => {
    switch (loginType) {
      case loginTypes.erAdmin:
        return [{ value: STATUS_RESOLVED, label: 'Resolved' }];
      default:
        return ISSUE_STATUS_MAP;
    }
  };

  // function for select status and execute according to status type and dependent values
  // this use for resolve button as well
  const handelStatusOnSelect = (value: any) => {
    if (value === status) return;
    if (value === STATUS_RESOLVED) {
      if (
        isNotErAdmin &&
        (isEmpty(employerId) ||
          isEmpty(assigneeId) ||
          priority === PRIORITY_UNKNOWN ||
          isEmpty(subType))
      ) {
        setResolveErrorModelState({
          visible: true,
          value: null,
          type: '',
          updatingValues: null,
        });
        return inlineStatusRef?.current?.closeEdit();
      } else {
        setModelConfirmState({
          visible: true,
          type: ISSUE_EDIT_TYPES.RESOLVED,
          value: value,
          updatingValues: { status: value },
        });
        return inlineStatusRef?.current?.closeEdit();
      }
    }

    handleSubmitUpdate({
      updatingValues: { status: value },
      ref: inlineStatusRef,
    });
  };

  const getAlreadyAddedWatcher = (watchers: any[]) => {
    const addedWatcher = getBrokerAdminsAccordingToEmp()?.filter((admin: any) =>
      watchers?.includes(admin?.value)
    );
    return addedWatcher;
  };

  const handleWatcherStartStop = () => {
    if (isLoggedUserWatcher) {
      const isUserAssignee =
        appBootInfo?.individualId === assigneeId && isLoggedUserWatcher;

      if (isUserAssignee) return;

      const userRemovedArray = removeSpecificItemFromStringArray(
        watchers,
        appBootInfo?.individualId
      );
      handleSubmitUpdate({ updatingValues: { watchers: userRemovedArray } });
    } else {
      if (isPlatformAdmin) return;
      const userAddedArray = [...watchers, appBootInfo?.individualId];
      handleSubmitUpdate({ updatingValues: { watchers: userAddedArray } });
    }
  };

  const getLastModifiedDate = () => {
    if (modifiedTime) {
      return `${dayjs(modifiedTime).format('MMMM D, YYYY')} at ${dayjs(
        modifiedTime
      ).format('h:mm A')}`;
    }
    return `${dayjs(createdTime).format('MMMM D, YYYY')} at ${dayjs(
      createdTime
    ).format('h:mm A')}`;
  };

  const formatDateToString = (dateTime: any) => {
    if (dateTime) {
      return `${dayjs(dateTime).format('MMMM D, YYYY')} at ${dayjs(
        dateTime
      ).format('h:mm A')}`;
    }
  };

  const WatcherComponent = () => {
    const updatedWatchersCount = getAlreadyAddedWatcher(watchers);
    if (updatedWatchersCount) {
      let watcherCountText: string;
      if (updatedWatchersCount?.length == 1) {
        watcherCountText = `1 Watcher`;
      } else {
        watcherCountText = `${updatedWatchersCount?.length} Watchers`;
      }
      return (
        <div className={styles.watcherContainer}>
          <EyeOutlined
            className={`${
              updatedWatchersCount?.length == 0 && styles.watcherTextBlack
            }`}
          />
          <span
            className={`${styles.issueInfoBarValue} ${styles.watcherText} ${
              updatedWatchersCount?.length == 0 && styles.watcherTextBlack
            }`}
          >
            {watcherCountText}
          </span>
        </div>
      );
    }
    return <>-</>;
  };

  const EmptyDescription = () => {
    return (
      <div className={styles.emptyDescriptionContainer}>
        {isEmpMember && !isCreatedBySameEmpUser ? (
          <></>
        ) : (
          <>
            <PencilIcon className={styles.editButton} />
            <span className={styles.emptyDescriptionText}>
              Add a brief description
            </span>
          </>
        )}
      </div>
    );
  };

  const getAttachmentName = (attachment: any, index: number) => {
    let fileName = attachment.fileName;
    const fileNameSplit = fileName.split('.') || [];
    const fileExtension = fileName.split('.').pop();
    const fileNameExcludeExtension = fileNameSplit
      .splice(0, fileNameSplit.length - 1)
      .join('.');
    const fileNameTrimmed = fileNameExcludeExtension.substring(
      0,
      characterLimit
    );
    fileName =
      fileNameExcludeExtension.length <= characterLimit
        ? attachment.fileName
        : `${fileNameTrimmed}...${fileExtension}`;
    return (
      <span
        key={attachment.id}
        className={styles.attachmentDiv}
        onClick={() => {
          downloadAttachment(attachment);
        }}
      >
        <img src={iconattachment} alt="icon-attachment" />
        <span className={styles.fileName}>{fileName}</span>
      </span>
    );
  };

  const labelPlaceholder = ({
    label,
    disable = false,
    labelChars = 19,
  }: {
    label: string;
    disable?: boolean;
    labelChars?: number;
  }) => {
    return (
      <div
        className={`${styles.labelWrapper} ${
          disable ? styles.disableLabelWrapper : ''
        }`}
      >
        <Typography.Text className={styles.label} title={label}>
          {getTextEllipsis(label, labelChars)}
        </Typography.Text>
        {!disable && <DownOutlined className={styles.downIcon} />}
      </div>
    );
  };

  if (issueDataLoading || employersLoading) {
    return <></>;
  }

  return (
    <>
      <div className={styles.viewIssue}>
        <Row>
          <Col span={18}>
            <Row className={styles.breadcrumb}>
              <BreadcrumbNavigation breadCrumbData={navigation} />
            </Row>
            <Row className={styles.headerRow}>
              <Col xs={23}>
                <InlineEditable
                  ref={inlineTitleRef}
                  placement={PLACEMENT_INLINE}
                  disabled={!isNotErAdmin || status === STATUS_RESOLVED}
                  placeholderVisibilityHidden={true}
                  onOutsideClickSave={() => {
                    setSubjectState(subject);
                    inlineTitleRef?.current?.closeEdit();
                    setIsShowEmptyErrorMessage(false);
                  }}
                  placeholderComponent={
                    <h1 className={styles.titleValue}>
                      <OverflowPopover>{subject}</OverflowPopover>
                    </h1>
                  }
                >
                  <div className={styles.inlineInputWrapper}>
                    <IEInput
                      isTitle={true}
                      value={subjectState}
                      onChange={(e: any) => {
                        setSubjectState(e.target.value);
                        if ((e.target.value?.trim()?.length ?? 0) !== 0) {
                          setIsShowEmptyErrorMessage(false);
                        }
                      }}
                    />
                    <Row justify="space-between">
                      <Col>
                        {isShowEmptyErrorMessage ? (
                          <div className={styles.textError}>
                            Please enter an item title
                          </div>
                        ) : (
                          ''
                        )}
                      </Col>
                      <Col>
                        <Row
                          className={styles.inlineBtnWrapper}
                          justify="end"
                          gutter={8}
                          onClick={() => {
                            if ((subjectState?.trim()?.length ?? 0) !== 0) {
                              handleSubmitUpdate({
                                updatingValues: {
                                  subject: subjectState.trim(),
                                },
                                ref: inlineTitleRef,
                                modelClose: false,
                              });
                            } else {
                              setIsShowEmptyErrorMessage(true);
                            }
                          }}
                        >
                          <Col>
                            <Button
                              label="Cancel"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSubjectState(subject);
                                inlineTitleRef?.current?.closeEdit();
                                setIsShowEmptyErrorMessage(false);
                              }}
                            />
                          </Col>
                          <Col>
                            <Button
                              type="primary"
                              label="Save"
                              onClick={(e) => {
                                if ((subjectState?.trim()?.length ?? 0) !== 0) {
                                  e.stopPropagation();
                                  handleSubmitUpdate({
                                    updatingValues: {
                                      subject: subjectState.trim(),
                                    },
                                    ref: inlineTitleRef,
                                    modelClose: false,
                                  });
                                }
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </InlineEditable>
              </Col>
            </Row>
            {(subjectState?.trim()?.length ?? 0) === 0 ? (
              <div className={styles.subjectEmptyInputWrapper}></div>
            ) : (
              ''
            )}
            <Row className={styles.issueInfoBar}>
              <Col span={3}>
                <Row
                  className={`${styles.issueInfoContainer} ${styles.issueIdContent}`}
                >
                  <span className={styles.issueInfoBarLabel}>ID: </span>
                  <span className={styles.issueInfoBarValue}>
                    {generatedId}
                  </span>
                </Row>
              </Col>
              {issueData?.type === ISSUE_TYPE_SUPPORT && (
                <Col span={7}>
                  <Row className={styles.issueInfoContainer}>
                    <span className={styles.issueInfoBarLabel}>
                      Support Team:
                    </span>
                    <InlineEditable
                      ref={inlineSupportTeamRef}
                      placement={PLACEMENT_BOTTOM}
                      disabled={!isNotErAdmin || status === STATUS_RESOLVED}
                      showHover={false}
                      minWidth={INLINE_MX_WIDTH_250}
                      placeholderComponent={labelPlaceholder({
                        label:
                          supportTeam === 'unknown' || supportTeam === 'Unknown'
                            ? 'Select'
                            : supportTeam || 'Select',
                        disable: !isNotErAdmin || status === STATUS_RESOLVED,
                        labelChars: 11,
                      })}
                    >
                      <IESelect
                        placeholder="Search Type"
                        sort={true}
                        showSelect={true}
                        value={supportTeam}
                        options={supportTeamDropdownList}
                        onSelectOption={(value) => {
                          if (value?.value === supportTeam) return;
                          setModelConfirmState({
                            visible: true,
                            value: value,
                            type: ISSUE_EDIT_TYPES.SUPPORT_TEAM,
                            updatingValues: { supportTeam: value?.value },
                          });
                          inlineSupportTeamRef?.current?.closeEdit();
                        }}
                      />
                    </InlineEditable>
                  </Row>
                </Col>
              )}
              {isNotErAdmin && (
                <>
                  <Col span={issueData?.type === ISSUE_TYPE_SUPPORT ? 7 : 8}>
                    <Row className={styles.issueInfoContainer}>
                      <span className={styles.issueInfoBarLabel}>
                        Employer:{' '}
                      </span>
                      <InlineEditable
                        ref={inlineEmployerRef}
                        disabled={
                          !isNotErAdmin ||
                          status === STATUS_RESOLVED ||
                          Boolean(
                            tagEmployerBasedOnSupportTeamChange(
                              supportTeam,
                              true
                            )
                          )
                        }
                        placement={PLACEMENT_BOTTOM}
                        showHover={false}
                        minWidth={INLINE_MX_WIDTH_250}
                        placeholderComponent={labelPlaceholder({
                          label:
                            employerData?.find(
                              (item: any) => item?.id === employerId
                            )?.name || 'Select',
                          disable:
                            !isNotErAdmin ||
                            status === STATUS_RESOLVED ||
                            Boolean(
                              tagEmployerBasedOnSupportTeamChange(
                                supportTeam,
                                true
                              )
                            ),
                          labelChars: 15,
                        })}
                      >
                        <IESelect
                          placeholder="Search Employer"
                          sort={true}
                          showSelect={true}
                          value={tagEmployerBasedOnSupportTeamChange(
                            supportTeam
                          )}
                          options={employerData?.map((item: any) => ({
                            value: item?.id,
                            label: item?.name,
                          }))}
                          onSelectOption={(value) => {
                            if (value?.value === employerId) return;
                            setModelConfirmState({
                              visible: true,
                              value: value,
                              type: ISSUE_EDIT_TYPES.EMPLOYER,
                              updatingValues: { employerId: value?.value },
                            });
                            inlineEmployerRef?.current?.closeEdit();
                          }}
                        />
                      </InlineEditable>
                    </Row>
                  </Col>
                  <Col span={7}>
                    <Row className={styles.issueInfoContainer}>
                      <span className={styles.issueInfoBarLabel}>
                        Visible to Employer:
                      </span>
                      <InlineEditable
                        ref={inlineEmpVisibilityRef}
                        disabled={
                          issueData?.type === ISSUE_TYPE_SUPPORT ||
                          !isNotErAdmin ||
                          status === STATUS_RESOLVED
                        }
                        placement={PLACEMENT_BOTTOM}
                        showHover={false}
                        minWidth={INLINE_MX_WIDTH_150}
                        placeholderComponent={labelPlaceholder({
                          label: String(
                            visibleToEmployerOptions?.find(
                              (item) => item?.value === showInEmployer
                            )?.label
                          ),
                          disable:
                            issueData?.type === ISSUE_TYPE_SUPPORT ||
                            status === STATUS_RESOLVED,
                        })}
                      >
                        <IESelect
                          showSelect={true}
                          showSearch={false}
                          value={showInEmployer}
                          options={visibleToEmployerOptions}
                          onSelectOption={(value) => {
                            if (value?.value === showInEmployer) return;
                            setModelConfirmState({
                              visible: true,
                              value: value,
                              type: ISSUE_EDIT_TYPES.VISIBLE_TO_EMPLOYER,
                              updatingValues: {
                                showInEmployer: value?.value,
                              },
                            });
                            inlineEmpVisibilityRef?.current?.closeEdit();
                          }}
                        />
                      </InlineEditable>
                    </Row>
                  </Col>
                </>
              )}
            </Row>
            <Row>
              <Col
                xs={23}
                className={`${styles.issueDescriptionContainer} richTextContent`}
              >
                <span className={styles.issueDescription}>Description</span>
                <InlineEditable
                  ref={inlineDescriptionRef}
                  disabled={status === STATUS_RESOLVED || disableInlineEdit}
                  placement={PLACEMENT_INLINE}
                  showHover={false}
                  positionAbsolute={false}
                  showCursorHand={true}
                  outsideClickClose={false}
                  onOutsideClickSave={() => setDescriptionState(description)}
                  placeholderComponent={
                    <div className={styles.richTextContentWrapper}>
                      {descriptionState ? (
                        <div ref={descriptionContentRef}>
                          {reactHtmlParser(descriptionState)}
                        </div>
                      ) : (
                        <EmptyDescription />
                      )}
                    </div>
                  }
                >
                  <div className={styles.inlineRichtextWrapper}>
                    <IERichTextEditor
                      height={descriptionContentRef?.current?.offsetHeight}
                      value={descriptionState || ''}
                      onChange={(value: any) => setDescriptionState(value)}
                    />
                    <Row
                      className={styles.inlineBtnWrapper}
                      justify="end"
                      gutter={8}
                    >
                      <Col>
                        <Button
                          label="Cancel"
                          onClick={() => {
                            setDescriptionState(description);
                            inlineDescriptionRef?.current?.closeEdit();
                          }}
                        />
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          label="Save"
                          onClick={() => {
                            handleSubmitUpdate({
                              updatingValues: {
                                description: descriptionState,
                              },
                              ref: inlineDescriptionRef,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </InlineEditable>
              </Col>
            </Row>
            <Row>
              <CommentsSection
                issue={issueData}
                getIssue={getIssue}
                downloadAttachment={downloadAttachment}
                provisionedEmail={getMatchingProvisionedEmail()}
                getBrokerAdminsAccordingToEmp={getBrokerAdminsAccordingToEmp}
                supportTeamAutomatedMailList={supportTeamAutomatedMailList}
                isShowReplyButton={
                  (isEmpMember && isCreatedBySameEmpUser) ||
                  ALLOWED_TYPES.includes(appBootInfo?.type) ||
                  isErAdminSubType
                }
              />
            </Row>
          </Col>
          <Col span={6}>
            <Col span={24} className={styles.resolveButtonWrapper}>
              {isShowResolveButton && (
                <Button
                  type="primary"
                  label={
                    status === STATUS_RESOLVED
                      ? 'Re-Open Item'
                      : 'Resolve  Item'
                  }
                  block
                  onClick={() => {
                    if (status === STATUS_RESOLVED) {
                      setModelConfirmState({
                        visible: true,
                        type: ISSUE_EDIT_TYPES.RE_OPENED,
                        value: STATUS_RE_OPENED,
                        updatingValues: { status: STATUS_RE_OPENED },
                      });
                      return;
                    } else {
                      handelStatusOnSelect(STATUS_RESOLVED);
                      return;
                    }
                  }}
                />
              )}
            </Col>
            <Col className={styles.issueSideMenuContent} ref={attachmentDiv}>
              <div className={styles.issueSideMenuHeaderWrapper}>
                <div className="title-overview">Overview</div>
              </div>
              {isNotErAdmin && (
                <>
                  <InlineEditable
                    placement={PLACEMENT_BOTTOM}
                    disabled={!isNotErAdmin || status === STATUS_RESOLVED}
                    placeholderComponent={
                      <SideMenuItem
                        title="Watchers"
                        child={<WatcherComponent />}
                      />
                    }
                  >
                    <IEAdvanceSelect
                      alreadySelectedList={getAlreadyAddedWatcher(watchers)}
                      alreadySelectedListTitle="Watchers"
                      searchPlaceholder="Search Admins"
                      emptyText="No users match your search"
                      addMoreButtonShow={true}
                      addMoreButtonLabel="+ Add Broker Watcher"
                      showActionButton={true}
                      actionButtonLabel={
                        isLoggedUserWatcher ? 'Stop Watching' : 'Start Watching'
                      }
                      alreadySelectedNotRemoveItem={assigneeId}
                      alreadySelectedNotRemovePopMessage="Assignee cannot be removed"
                      onActionButtonClick={handleWatcherStartStop}
                      options={getBrokerAdminsAccordingToEmp() || []}
                      onRemoveAdded={(value) => {
                        const userRemovedArray =
                          removeSpecificItemFromStringArray(
                            watchers,
                            value?.value
                          );
                        handleSubmitUpdate({
                          updatingValues: { watchers: userRemovedArray },
                        });
                      }}
                      onSelectOption={(value) => {
                        const userAddedArray = [...watchers, value.value];
                        handleSubmitUpdate({
                          updatingValues: { watchers: userAddedArray },
                        });
                      }}
                      sort={true}
                      isNvTagApplicable={issueData?.type === ISSUE_TYPE_SUPPORT}
                    />
                  </InlineEditable>

                  <InlineEditable
                    ref={inlineAssigneeRef}
                    disabled={!isNotErAdmin || status === STATUS_RESOLVED}
                    placement={PLACEMENT_BOTTOM}
                    placeholderComponent={
                      <SideMenuItem
                        title="Assigned To"
                        child={
                          <span className={styles.issueInfoBarValue}>
                            {getBrokerAdminsAccordingToEmp()?.find(
                              (item: any) => item?.value === assigneeId
                            )?.label || 'Unknown'}
                          </span>
                        }
                      />
                    }
                  >
                    <IESelect
                      placeholder="Search Assignees"
                      options={getBrokerAdminsAccordingToEmp() || []}
                      showSelect={true}
                      value={assigneeId}
                      onSelectOption={(value) => {
                        if (value?.value === assigneeId) return;
                        setModelConfirmState({
                          visible: true,
                          value: value,
                          type: ISSUE_EDIT_TYPES.ASSIGN_TO,
                          updatingValues: {
                            assigneeId: value?.value,
                            assigneeName: value?.label,
                          },
                        });
                        inlineAssigneeRef?.current?.closeEdit();
                      }}
                    />
                  </InlineEditable>
                </>
              )}

              <InlineEditable
                ref={inlineStatusRef}
                disabled={status === STATUS_RESOLVED || disableInlineEdit}
                placement={PLACEMENT_BOTTOM}
                placeholderComponent={
                  <SideMenuItem
                    title="Status"
                    child={
                      <IssueStatusBadge
                        issueStatus={
                          !isNotErAdmin && status === STATUS_BLOCKED
                            ? STATUS_IN_PROGRESS
                            : status
                        }
                      />
                    }
                  />
                }
              >
                <IESelect
                  showSearch={false}
                  showSelect={true}
                  value={status}
                  options={getStatusAccordingToLogin(String(appBootInfo?.type))}
                  onSelectOption={(value) => handelStatusOnSelect(value?.value)}
                />
              </InlineEditable>

              <InlineEditable
                ref={inlineEditPriorityRef}
                disabled={status === STATUS_RESOLVED || disableInlineEdit}
                placement={PLACEMENT_BOTTOM}
                placeholderComponent={
                  <SideMenuItem
                    title="Priority"
                    child={
                      priority ? (
                        <PriorityBadge onClick={() => {}} priority={priority} />
                      ) : (
                        <span className={styles.issueInfoBarValue}>
                          Unknown
                        </span>
                      )
                    }
                  />
                }
              >
                <IESelect
                  showSearch={false}
                  showSelect={true}
                  value={priority}
                  options={PRIORIRY_MAP}
                  onSelectOption={(value) => {
                    if (value?.value === priority) return;
                    handleSubmitUpdate({
                      updatingValues: { priority: value?.value },
                      ref: inlineEditPriorityRef,
                    });
                  }}
                />
              </InlineEditable>

              <InlineEditable
                ref={inlineIssueTypeRef}
                disabled={status === STATUS_RESOLVED || disableInlineEdit}
                placement={PLACEMENT_BOTTOM}
                placeholderComponent={
                  <SideMenuItem
                    title="Item Type"
                    child={
                      <span className={styles.issueInfoBarValue}>
                        {issueSubTypeLabel || 'Unknown'}
                      </span>
                    }
                  />
                }
              >
                <IESelect
                  placeholder="Search Type"
                  sort={true}
                  showSelect={true}
                  value={subType}
                  options={[
                    ...ISSUE_SUBTYPE_MAP,
                    { value: SUBTYPE_SPAM, label: 'Not Tracked' },
                  ]}
                  onSelectOption={(value) => {
                    if (value?.value === subType) return;
                    if (value?.value === SUBTYPE_SPAM) {
                      setModelConfirmState({
                        visible: true,
                        value: value,
                        type: ISSUE_EDIT_TYPES.ISSUE_TYPE_SPAM,
                        updatingValues: { subType: value?.value },
                      });
                      return inlineIssueTypeRef?.current?.closeEdit();
                    }
                    handleSubmitUpdate({
                      updatingValues: { subType: value?.value },
                      ref: inlineIssueTypeRef,
                    });
                  }}
                />
              </InlineEditable>

              <div
                className={`${styles.issueSideMenuHeaderWrapper} ${styles.issueActivityHeader}`}
              >
                <div className="title-overview">Activity</div>
              </div>
              <SideMenuItem
                title="Opened"
                child={
                  <>
                    <span className={styles.issueInfoBarValue}>
                      {`${dayjs(createdTime).format('MMMM D, YYYY')} at ${dayjs(
                        createdTime
                      ).format('h:mm A')}`}
                    </span>
                    <span
                      className={`${styles.issueInfoBarValue} ${styles.activityUserInfo}`}
                    >
                      by {createdByName}
                    </span>
                  </>
                }
              />
              <SideMenuItem
                title="Last Modified"
                child={
                  <>
                    <span className={styles.issueInfoBarValue}>
                      {getLastModifiedDate()}
                    </span>
                    <span
                      className={`${styles.issueInfoBarValue} ${styles.activityUserInfo}`}
                    >
                      by {modifiedBy || createdByName}
                    </span>
                  </>
                }
              />
              <SideMenuItem
                title="Resolved"
                child={
                  <>
                    {!isEmpty(closedByName) ? (
                      <>
                        <span className={styles.issueInfoBarValue}>
                          {formatDateToString(closedDate)}
                        </span>
                        <span
                          className={`${styles.issueInfoBarValue} ${styles.activityUserInfo}`}
                        >
                          by {closedByName}
                        </span>
                      </>
                    ) : (
                      <p>-</p>
                    )}
                  </>
                }
              />
              {!isEmpty(attachments) && (
                <div
                  className={`${styles.issueSideMenuHeaderWrapper} ${styles.attachmentsWrapper}`}
                >
                  <div className={`title-overview ${styles.attachmentsHeader}`}>
                    Attachments ({attachments?.length})
                  </div>
                  <div className={styles.attachmentsList}>
                    <SideMenuItem
                      child={attachments?.map(
                        (attachment: any, index: number) =>
                          getAttachmentName(attachment, index)
                      )}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Col>
        </Row>
      </div>

      {/* Confirm Model  */}
      <ConfirmationDialog
        visible={modelConfirmState.visible}
        centered={true}
        width={464}
        title={
          getConfirmModelContent(
            modelConfirmState.type,
            modelConfirmState?.value
          )?.title
        }
        confirmText={`${
          getConfirmModelContent(
            modelConfirmState.type,
            modelConfirmState?.value
          )?.confirmText
        }`}
        cancelText="Cancel"
        isCancelLink={true}
        closeModal={() => {
          setModelConfirmState({
            visible: false,
            value: null,
            type: '',
            updatingValues: null,
          });
        }}
        onConfirm={() => {
          handleSubmitUpdate({
            updatingValues: modelConfirmState.updatingValues,
            modelClose: setModelConfirmState,
          });
        }}
      >
        <div className={styles.confirmModelContentWrapper}>
          {
            getConfirmModelContent(
              modelConfirmState.type,
              modelConfirmState?.value
            )?.content
          }
        </div>
      </ConfirmationDialog>

      <ConfirmationDialog
        visible={resolveErrorModelState.visible}
        centered={true}
        title="Cannot Resolve Item"
        isCloseOnly={true}
        confirmText={'Close'}
        onConfirm={() => {
          setResolveErrorModelState({
            visible: false,
            value: null,
            type: '',
            updatingValues: null,
          });
        }}
      >
        <div className={styles?.resolveErrorModelContentWrapper}>
          <p>
            The following fields must be entered before resolving this item:
          </p>
          {isEmpty(employerId) && (
            <div className={styles?.listItemWrapper}>
              <XIcon /> Employer
            </div>
          )}
          {isEmpty(assigneeId) && (
            <div className={styles?.listItemWrapper}>
              <XIcon /> Assigned To
            </div>
          )}
          {priority === PRIORITY_UNKNOWN && (
            <div className={styles?.listItemWrapper}>
              <XIcon /> Priority
            </div>
          )}
          {isEmpty(subType) && (
            <div className={styles?.listItemWrapper}>
              <XIcon /> Item Type
            </div>
          )}
        </div>
      </ConfirmationDialog>
    </>
  );
};
export default ViewIssue;
