import React, { useState, useEffect } from 'react';
import { Row, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { CheckOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { ReactComponent as PreviewIcon } from 'assets/images/preview-dashboard-icon.svg';
import { ReactComponent as GuideIcon } from 'assets/images/Guide-icon.svg';
import { ReactComponent as UrlIcon } from 'assets/images/url-dashboard-icon.svg';
import { useGetListOfGuidesQuery } from 'modules/employers/slices/benguideDashboardSlice';
import PlanYearDropdown from 'components/PlanYearDropdown/PlanYearDropdown';
import PlanYear from 'model/PlanYear';
import { benGuideConstants } from 'constants/benguideDashboardConstants';
import { benguideUrl } from 'util/apiUtil';
import AlertMessage from 'components/Alert/AlertMessage';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import BenguideContainer from 'modules/benefitGuide/components/BenguideContainer/BenguideContainer';
import {
  clearDBGObject,
  getBenefitGuideByHashAndRevision,
} from 'modules/employers/slices/benguideSlice';
import {
  changedBenguideModalState,
  changedEmployerIdState,
  previewBenguide,
} from 'modules/benefitGuide/slices/benguideSlice';

import { useNavContext } from 'hooks/useNavContext';
import NoBenguideViewDashboard from 'modules/benefitGuide/NoBenguideViewDashboard/NoBenguideViewDashboard';
import useDBGClient from 'modules/clients/DBGClient/useDBGClient';
import {
  BENGUIDE_COMMON_CHANNEL,
  BENGUIDE_LOADING_COMPLETED,
  BENGUIDE_SAVE_COMPLETED,
  CLOSE_CREATE_BENGUIDE_MODAL,
  CLOSE_DBG_MODAL,
  CREATE_BENGUIDE_CHANNEL,
  ENABLE_EDIT_MODE,
  EXIT_BENGUIDE,
  PREVIEW_BENGUIDE,
  PUBLISH_BENGUIDE,
  UP_COMMON_CHANNEL,
} from 'modules/clients/DBGClient/DBGClientConts';
import DBGClient from 'modules/clients/DBGClient/DBGClient';

import styles from './BenguideList.module.less';

interface DataType {
  key: string;
  name: string;
  status: string[];
  updatedDetails: string;
}
const generateTagBasedOnTheBenStatus = (obj: any): string => {
  const { status } = obj;

  switch (status) {
    case benGuideConstants.published:
      return styles.published;
    case benGuideConstants.draft:
      return styles.inprogress;
    default:
      return styles.archived;
  }
};
const generateTagNameBasedOnTheBenStatus = (obj: any): string => {
  const { status } = obj;

  switch (status) {
    case benGuideConstants.published:
      return benGuideConstants.published;
    case benGuideConstants.draft:
      return benGuideConstants.inprogress;
    default:
      return benGuideConstants.archived;
  }
};

const BenguideList: React.FC = () => {
  const { brokerId } = useNavContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [copyAlertVisible, setCopyAlertVisible] = useState<boolean>(false);
  const { employerId } = useParams();
  const [currentPlanYearId, setCurrentPlanYearId] = useState<string>('');
  const [isCreateMode, setCreateMode] = useState<boolean>(false);

  const path = isCreateMode ? `create?employerId=${employerId}` : '';

  const isDependantsNotNullOrEmpty = (): boolean => {
    return !Boolean(currentPlanYearId);
  };
  const upChannel = useDBGClient();
  const handleActions = (event: string, data: any) => {
    if (BENGUIDE_LOADING_COMPLETED === event) {
      upChannel.postMessage({
        channel: UP_COMMON_CHANNEL,
        event: ENABLE_EDIT_MODE,
        data: {},
      });
    }
    if (CLOSE_DBG_MODAL === event) {
      dispatch(changedBenguideModalState(false));
      if (data && data?.planYearId && data?.status && employerId && brokerId) {
        navigate(`brokers/${brokerId}/employers/${employerId}`);
      }
      dispatch(changedEmployerIdState(''));
    }
    if (PUBLISH_BENGUIDE === event) {
      dispatch(getBenefitGuideByHashAndRevision(data.urlHash, data.revision));
      setBenguideModal(true);
    }
    if (PREVIEW_BENGUIDE === event) {
      dispatch(previewBenguide(data.id, data.revision));
    }
    if (BENGUIDE_SAVE_COMPLETED === event) {
      setBenguideModal(false);
    }
    if (EXIT_BENGUIDE === event) {
      setBenguideModal(false);
    }
    if (event === CLOSE_CREATE_BENGUIDE_MODAL) {
      setBenguideModal(false);
    }
  };
  const setBenguideModal = (state: boolean) => {
    dispatch(changedBenguideModalState(state));
  };
  const benguideSlice = useAppSelector((state) => state.benguide.benguideSlice);
  const { isBenguideModalOpen } = benguideSlice;

  const {
    data,
    isLoading,
    isUninitialized,
    refetch: refetchListOfGuides,
  } = useGetListOfGuidesQuery(
    {
      employerId,
      planYearId: currentPlanYearId,
    },
    { skip: isDependantsNotNullOrEmpty() }
  );

  useEffect(() => {
    refetchListOfGuides();
  }, [refetchListOfGuides, currentPlanYearId]);

  const onChangePlanYear = (val: PlanYear) => {
    setCurrentPlanYearId(val.id);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: false,
      dataIndex: 'name',
      key: 'id',
      render: (_, data: any) => (
        <>
          <GuideIcon className={styles.guideIcon} />
          <a className={styles.guideName}>
            <a className={styles.benguideName}>{data.name}</a>
            <a className={styles.benguideStatus}>
              <Tag
                icon={<CheckOutlined />}
                className={generateTagBasedOnTheBenStatus(data)}
                key={data.id}
              >
                {generateTagNameBasedOnTheBenStatus(data)}
              </Tag>
            </a>
          </a>
          <a className={styles.lastUpdate}>
            {`Last updated  ${new Date(data.lastUpdatedTs).toLocaleDateString(
              'en-US',
              {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
              }
            )} by ${data.lastUpdatedLoginName}`}
          </a>
        </>
      ),
    },
    {
      title: false,
      key: 'action',
      render: (_, data: any) => (
        <Row className={styles.actionItems}>
          <Space size="middle">
            <PreviewIcon
              className={styles.actionIcons}
              onClick={() => {
                dispatch(previewBenguide(data.masterId));
              }}
            />
            {data.status === benGuideConstants.archived ||
            data.status === benGuideConstants.draft ? (
              <UrlIcon className={styles.actionIconsDisabled} />
            ) : (
              <UrlIcon
                className={styles.actionIcons}
                onClick={() => {
                  setCopyAlertVisible(true);
                  navigator.clipboard.writeText(
                    `${benguideUrl}/${data.urlHash}/${
                      data.employerName ? data.employerName : ''
                    }`
                  );
                }}
              />
            )}
          </Space>
        </Row>
      ),
    },
  ];
  return (
    <>
      <DBGClient channel={BENGUIDE_COMMON_CHANNEL} subscribe={handleActions} />
      <DBGClient channel={CREATE_BENGUIDE_CHANNEL} subscribe={handleActions} />
      <FullScreenModal
        visible={isBenguideModalOpen}
        showConfirm={false}
        onCancel={() => {
          dispatch(clearDBGObject());
          setBenguideModal(!isBenguideModalOpen);
        }}
        className={styles.benGuideModalWrapper}
        forceRender
        footer={false}
      >
        {isBenguideModalOpen && (
          <BenguideContainer path={path} id="dbg-home-iframe" />
        )}
      </FullScreenModal>
      {copyAlertVisible && (
        <AlertMessage
          type={'success'}
          message="Copied the URL to Clipboard"
          closeAlert={() => {
            setCopyAlertVisible(false);
          }}
          className={styles.dbgActionMessage}
        />
      )}
      <div className={styles.benefitGuidePlanYearFilter}>
        <Row>
          <PlanYearDropdown
            onChange={onChangePlanYear}
            className={styles.planYearLabel}
            planYearOptionsStyle={styles.planYearOptions}
          />
        </Row>
      </div>
      {isEmpty(data?.content) && !isLoading && !isUninitialized ? (
        <NoBenguideViewDashboard
          className={styles.benguidePageActionsButton}
          primaryButtonAction={() => {
            setCreateMode(true);
            setBenguideModal(!isBenguideModalOpen);
          }}
        />
      ) : (
        <Table
          className={styles.benguideDetails}
          columns={columns}
          dataSource={data ? data.content : []}
          pagination={{ pageSize: 4 }}
          loading={isLoading || isUninitialized}
        />
      )}
      {!isEmpty(data?.content) && (
        <p
          className={styles.viewMore}
          onClick={() => {
            navigate(
              `/brokers/${brokerId}/employers/${employerId}/benefit-guides`
            );
          }}
        >
          View More
        </p>
      )}
    </>
  );
};

export default BenguideList;
