import { FC, useEffect, useState } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { useAppDispatch } from 'hooks/redux';

import Consultant from 'model/benefitsConsultation/Consultant';

import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';

import CheckboxListSelector from 'modules/benefitsConsultation/components/CheckboxListSelector/CheckboxListSelector';
import AddExternalBC from 'modules/benefitsConsultation/components/AddExternalBC/AddExternalBC';
import FooterSection from 'modules/benefitsConsultation/components/FooterSection/FooterSection';
import ConsultationLevel from 'modules/benefitsConsultation/enums/ConsultationLevel';
import { clearAddRemoveConsultantsFailed } from 'modules/benefitsConsultation/slices/brokerLevelSlice';

import styles from './addBenefitConsultant.module.less';

type AddBenefitConsultantProps = {
  visible: boolean;
  setVisible: Function;
  listIsLoading: boolean;
  saveInProgress: boolean;
  onSave: React.MouseEventHandler<HTMLElement>;
  addExternalBCRef?: any;
  checkboxListSelectorRef: any;
  activeAdminsList: Consultant[];
  externalConsultantsList?: Consultant[];
  modalHeading: string;
  consultantTypeText: string;
  level?: ConsultationLevel;
  isRemovableMode?: boolean;
  employerName?: string;
  addRemoveFailed?: boolean;
  isFieldsEmpty?: boolean;
  setIsFieldsEmpty?: Function;
};

const AddBenefitConsultant: FC<AddBenefitConsultantProps> = (
  props: AddBenefitConsultantProps
) => {
  const {
    visible,
    setVisible,
    listIsLoading,
    saveInProgress,
    activeAdminsList,
    externalConsultantsList = [],
    onSave,
    checkboxListSelectorRef,
    addExternalBCRef,
    modalHeading,
    consultantTypeText,
    level,
    isRemovableMode = false,
    employerName = '',
    addRemoveFailed = false,
    isFieldsEmpty,
    setIsFieldsEmpty,
  } = props;

  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [isSubmitPressed, setIsSubmitPressed] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: 'error',
    message: '',
  });
  const [isFormFilled, setIsFormFilled] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    !visible && setIsFormFilled(false);
  }, [visible]);

  useEffect(() => {
    if (addRemoveFailed) {
      // TODO: Add generic message and handle error object for this scenario
      setAlertVisible(true);
      setAlertMessage({
        type: 'error',
        message:
          'An External Consultant with the same email already exists. Please remove the existing consultant and try again if you want to add this user as a Consultant.',
      });
      setIsSubmitPressed(true);
    }
  }, [addRemoveFailed]);

  return (
    <FullScreenModal
      title={modalHeading}
      visible={visible}
      className={styles.addBCWrapper}
      onCancel={() => {
        addExternalBCRef?.current?.resetData();
        setVisible(false);
        setAlertVisible(false);
        dispatch(clearAddRemoveConsultantsFailed());
      }}
      footer={false}
      closeIcon={<CloseCircleOutlined />}
      destroyOnClose
      afterClose={() => {
        setIsSubmitPressed(false);
        checkboxListSelectorRef?.current?.resetData();
      }}
    >
      <div className={styles.alertWrapper}>
        {alertVisible && (
          <AlertMessage
            type={alertMessage.type}
            message={alertMessage.message}
            closeAlert={() => setAlertVisible(false)}
          />
        )}
      </div>
      <div className={styles.overallContent}>
        {level === ConsultationLevel.ER_BROKERS && (
          <>
            <div className={styles.employerNameHeader}>
              <Row>
                <Col span={10}>
                  <span className={styles.label}>Employer</span>
                </Col>
                <Col>
                  <span className={styles.value}>{employerName}</span>
                </Col>
              </Row>
            </div>

            <div className={styles.bcHeader}>
              <Row>
                <span className={styles.label}>
                  <b>Broker Consultants</b>
                </span>
              </Row>
            </div>
          </>
        )}

        <CheckboxListSelector
          consultantList={activeAdminsList}
          ref={checkboxListSelectorRef}
          consultantTypeText={consultantTypeText}
          inProgress={listIsLoading}
          isRemovableMode={isRemovableMode}
          invalidCheckbox={isSubmitPressed}
          existingExternalEmailsList={externalConsultantsList.map(
            (consultant) => consultant.email
          )}
          setIsFieldsEmpty={setIsFieldsEmpty}
        />

        {level !== ConsultationLevel.ER_BROKERS && (
          <AddExternalBC
            existingList={externalConsultantsList}
            activeAdminsList={activeAdminsList}
            ref={addExternalBCRef}
            consultantTypeText={consultantTypeText}
            setIsFormFilled={setIsFormFilled}
          />
        )}

        <FooterSection
          onSave={onSave}
          onCancel={() => {
            addExternalBCRef?.current?.resetData();
            setVisible(false);
            setAlertVisible(false);
            dispatch(clearAddRemoveConsultantsFailed());
          }}
          isLoading={saveInProgress}
          disabled={isFieldsEmpty && !isFormFilled}
        />
      </div>
    </FullScreenModal>
  );
};

export default AddBenefitConsultant;
