import { ReactElement } from 'react';
import { DeepPartial } from '@reduxjs/toolkit';

import {
  CREDITS_INCLUSION,
  DRAFTS_TAB,
  FOR_REVIEW_TAB,
  OFFER_BENEFIT_TYPES,
  OFFER_STATUSES,
  OFFER_TAGS,
  OfferType,
  OPEN_OFFERS_SEARCH,
  PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS,
  PROPOSAL_CARD_ACTION_OPTIONS,
  PROPOSAL_CARD_TAGS,
  PROPOSAL_SORT_METHODS,
  RECOMMENDED_OFFER,
  SOLD_CONFIRMATION_ACTIONS_OPTIONS,
} from 'modules/renewals/constants/renewalsConstants';

import {
  ADD_TO_PLAN_YEAR_FUNDING_TYPE,
  PLAN_TYPE_LABEL_MAP,
} from 'modules/renewals/constants/addToPlanYearConstants';
import ProposalSummaryVO from 'model/ProposalSummaryVO';
import { Plan } from 'model/plans/Plan';
import EmployerContribution from 'model/plans/EmployerContribution';
import FundingType from 'modules/plans/enums/FundingType';

export type OfferBenefitType = keyof typeof OFFER_BENEFIT_TYPES;
export type OfferBenefitValueType =
  typeof OFFER_BENEFIT_TYPES[keyof typeof OFFER_BENEFIT_TYPES];
export type ProposalCardTagValueType =
  typeof PROPOSAL_CARD_TAGS[keyof typeof PROPOSAL_CARD_TAGS];
export type ProposalCardTagKeyType = keyof typeof PROPOSAL_CARD_TAGS;
export type ProposalCardActionType = keyof typeof PROPOSAL_CARD_ACTION_OPTIONS;
export type ProposalCardActionOptionType =
  typeof PROPOSAL_CARD_ACTION_OPTIONS[keyof typeof PROPOSAL_CARD_ACTION_OPTIONS];
export type CreditsInclusionType = keyof typeof CREDITS_INCLUSION;
export type ProposalSortMethodType = keyof typeof PROPOSAL_SORT_METHODS;
export type CardTopStatusType =
  | typeof RECOMMENDED_OFFER
  | typeof OfferType.RENEWAL
  | typeof OFFER_TAGS.DTQ
  | typeof OFFER_TAGS.PENDING
  | ProposalCardTagValueType;
export type OfferStatusKeyType = keyof typeof OFFER_STATUSES;
export type ProposalBenefitsPageActionType =
  keyof typeof PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS;
export type AddToPlanYearFundingType =
  keyof typeof ADD_TO_PLAN_YEAR_FUNDING_TYPE;
export type SoldConfirmationDropDownType =
  keyof typeof SOLD_CONFIRMATION_ACTIONS_OPTIONS;

export interface PlanYearDropDownType {
  label: string;
  value: any;
}

export interface ProposalOfferDetailsCardDataType {
  type: string;
  id: string;
  title: string;
  isHra: boolean;
  isHsa: boolean;
  hraUtilization: string;
  tableData: {
    imageUrl: string;
    rowData:
      | {
          type?: string;
          name?: string;
          enrollment?: number;
          splits?: string;
          erPaysPerMonth?: number;
          erHsaContributions?: number;
          eePaysPerMonth?: {
            value?: number;
            fromCurrent?: number;
          };
          totalMonthlyCost?: number;
          volume?: number;
          rate?: number;
          lifeRate?: number;
          addRate?: number;
          hraFaceValue?: number;
          hraContribution?: number;
          premium?: number;
        }[]
      | any[];
  };
  summaryData: {
    erPaysPerMonth: { month?: number; year?: number };
    eePaysPerMonth: { month?: number; year?: number };
    totalMonthlyCost: { month?: number; year?: number };
    erHsaContributions?: { month?: number; year?: number };
    premiumContribution?: { month?: number; year?: number };
    erHraContributions?: { month?: number; year?: number };
    erHraFaceValueContributions?: { month?: number; year?: number };
  };
  newPlan: boolean;
  planId: String;
  stdFundingType?: string;
  planYearId: string;
}

export interface IProposalPageTab {
  data?: IProposalCardData[];
  outdatedData?: IProposalCardData[];
  key: typeof FOR_REVIEW_TAB | typeof DRAFTS_TAB;
  name: string;
  show: boolean;
  alert: {
    subText: string;
    mainText: string;
    mainBtnText: string;
    isBtnDisable: boolean;
  };
}

export interface IProposalCardData {
  id?: string;
  title?: string;
  status?: string;
  description?: string;
  isOutdated?: boolean;
  period?: string;
  effectiveDateRange?: string;
  tags?: ProposalCardTagKeyType[];
  tableData?: IProposalCardTableRow[];
  overviewData?: IProposalCardOverviewData;
  changedBy?: string;
  changeByName?: string;
  effectiveDateId?: string;
}

export interface IProposalCardTableRow {
  benefit: OfferBenefitValueType;
  important?: boolean;
  benefitsCost?: string;
  erCost?: string;
  eeCost?: string;
  hsaCost?: string;
  hraCost?: string;
}

export interface IProposalCardOverviewData {
  erHsaFunding?: number;
  totalBenefitCost?: {
    value?: number;
    percentage?: number;
    diffFromCurrentValue?: number;
  };
  eeCost?: {
    value?: number;
    percentage?: number;
    diffFromCurrentValue?: number;
  };
  erCost?: {
    value?: number;
    percentage?: number;
    diffFromCurrentValue?: number;
  };
}

export interface ISelectOption {
  value: any;
  label: string;
  hideSelect?: boolean;
  planYearId?: string;
}

export interface IProposalBenefitsTableRow {
  name: string;
  enrollment?: number;
  splits?: string;
  annualPremium?: number;
  fromCurrent?: { value: number; percentage: number };
  newPlan?: boolean;
  planDocuments?: any[];
  isPlaceHolder?: boolean;
  planId?: String;
  fundingType?: FundingType;
}

export interface IFromCurrentOverviewData {
  value?: number;
  differenceValue?: number;
  differencePercentage?: number;
  erHSA?: number;
}

export interface IProposalBenefitsCardData {
  id?: string;
  type: OfferBenefitValueType;
  name: string;
  overviewData: {
    eeCost: IFromCurrentOverviewData;
    erCost: IFromCurrentOverviewData;
    estimatedAnnualPremium: IFromCurrentOverviewData;
    credits?: IFromCurrentOverviewData & { description?: string };
    totalHraCost?: number;
  };
  tableData: {
    carrierLogo: string;
    rowData: IProposalBenefitsTableRow[];
  }[];
  isPlaceHolder?: boolean;
}

export interface ICreditsDiscountsDescriptionItem {
  type?: string;
  description: string;
  offerName?: string;
  percentage?: number;
  value: number;
  eeAmount?: number;
  erAmount?: number;
}

export interface IProposalBenefitsPageOverviewData {
  eeCost?: IFromCurrentOverviewData;
  erCost?: IFromCurrentOverviewData;
  totalBenefitsCost?: IFromCurrentOverviewData;
  creditsAndDiscounts?: {
    totalCredits?: number;
    totalDiscounts?: number;
    totalCreditsAndDiscounts?: number;
  };
}

export type OpenOffersQueryParams = {
  statuses: [];
  employernames: [];
  types: [];
  categories: [];
  searchKeyValue: string;
  carriers: [];
  offerStatus: [];
  renewalDates: [];
  page: number;
  brokers: [];
  size: number;
  sort: string;
  lastmodified: [];
  bidstatus: [];
  isAllEmployerSelected: boolean;
};

export type saveFilterTypes = {
  clickedSaved: boolean;
  searchKey: string;
  filterType: any;
  filterValues: any[] | string;
  page: any;
  size: number;
};

export interface IProposalActionsDropDownData {
  title: string;
  subTitle?: string;
  body: string | ReactElement;
  confirmTitle?: string;
  showFileLinks?: boolean;
  excelFile?: string;
  powerpointFile?: string;
  onConfirm: Function;
}

export interface IPreferenceObj {
  individualId: string;
  preferenceType: typeof OPEN_OFFERS_SEARCH;
  preferenceObject: IOpenOfferFilterDynamicObj[];
}

export interface IOpenOfferFilterDynamicObj {
  isDefault: boolean;
  name: string;
  values: any;
}

export interface IStepperStep {
  isOpen?: boolean;
  onNextStep?: () => void;
  onPreviousStep?: () => void;
  updateProposalStatus?: Function;
  setIsProposalAddToPlanYearVisible?: Function;
  onCloseModal?: (showConfirmMessage?: boolean) => void;
}

export interface IDataState<T> {
  data?: T;
  status: {
    isLoading: boolean;
    isError: boolean;
  };
}

export interface IPlanDetails {
  proposalPlanName: string;
  benefitClasses: string[];
  fundingType: AddToPlanYearFundingType | '';
  category: OfferBenefitType;
  data: any;
}

export interface IAddProposalToPlanYearState {
  basicDetails: {
    planYearName?: string;
    benefitClasses: IDataState<ISelectOption[]>;
    startDate?: string;
    endDate?: string;
    employerId?: string;
    proposalId?: string;
  };
  plans: {
    // eslint-disable-next-line
    [key in
      | 'MEDICAL'
      | 'DENTAL'
      | 'VISION'
      | 'LIFE_AND_DISABILITY'
      | 'VOLUNTARY_BENEFIT'
      | 'TAX_ADVANTAGE_ACCOUNTS']: DeepPartial<TransformedPlan>[];
  };
  proposalSummary: IDataState<ProposalSummaryVO>;
  selectPlanCreateType: 'MERGE' | 'NEW';
  currentSelectedPlan?: DeepPartial<Plan> | null;
  currentSelectedPlanLoading: boolean;
  isCurrentSelectedPlanReviewed: boolean;
  isCurrentSelectedPlanMerged: boolean;
  isUsingMergePlanData?: boolean;
  isLoading?: boolean;
  isError?: boolean;
}

export type TransformedPlan = {
  id: string;
  planType?: keyof typeof PLAN_TYPE_LABEL_MAP;
  isMerged: boolean;
  isReviewed: boolean;
  planName: string;
  mergedPlanId: string;
  mergablePlans: ISelectOption[];
  benefit: keyof IAddProposalToPlanYearState['plans'];
  plan: Plan;
  premiumType?: string | null;
  tierCount?: number;
  taxAdvId?: string;
  parentId?: string;
  employerContribution?: EmployerContribution;
  category?: string;
  tempId?: string;
  parentPlan?: any;
  initialPlanId?: string;
  hraPlanId?: string;
  hraUtilization?: number;
  volume?: number;
  enrollment?: any;
  planContributionId?: any;
};

export type ReviewModalFragment = {
  type?: keyof IAddProposalToPlanYearState['plans'] | undefined;
  benefitKind?: string;
  setState: Function;
  state: DeepPartial<Plan>;
  benefitClasses?: string[];
  formRef?: any;
  isModalVisible?: boolean;
};

export type Validations = {
  number?: boolean;
  currency?: boolean;
  percentage?: boolean;
  timePeriod?: string;
  decimalScale?: number;
};

export type Premium = {
  tier: any;
  enrollment: any;
};

export type InNetworkOutNetwork = {
  property: string;
  name: string;
  fieldType: string;
  fieldSubType: string | null;
  added?: boolean;
};

export type EditDetailRxTiersTypes = {
  [key: string]: {
    inNetwork: InNetworkOutNetwork;
    outNetwork: InNetworkOutNetwork;
  };
};

export enum ActionStatus {
  // eslint-disable-next-line no-unused-vars
  APPROVE = 'APPROVE',
  // eslint-disable-next-line no-unused-vars
  SAVE = 'SAVE',
}

// Define the state type
export interface ReviewModalState {
  isOpen: boolean;
  action?: ActionStatus | undefined;
}
