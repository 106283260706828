import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { Popover, Switch } from 'antd';
import { DataColumn } from 'components/DataTable/DataColumn';
import ToolList from 'components/ToolList/ToolList';
import {
  useGetEmployersQuery,
  useUpdateEmployerFeatureStatusMutation,
} from 'api/featureControl';
import TablePagination from 'model/TablePagination';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { EMPLOYER_ACCESS_LOCKED } from 'constants/commonConstants';
import EmployerFeatureToggle from 'components/EmployerFeatureToggle/EmployerFeatureToggle';
import EmployerVisibilityOption from 'components/EmployerVisibilityOption/EmployerVisibilityOption';

import styles from './featureList.module.less';

type EmployerIdCardsListProps = {};

const EmployerIdCardsList: FC<EmployerIdCardsListProps> = (
  props: EmployerIdCardsListProps
) => {
  const { brokerId } = useParams();
  const [updateStatus] = useUpdateEmployerFeatureStatusMutation({});
  const [searchText, setSearchText] = useState<string>('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState('name');
  const {
    data: featureWrappers = [],
    refetch,
    isFetching,
  } = useGetEmployersQuery({
    page: pageIndex,
    organizationId: brokerId as string,
    key: 'ID_CARDS',
    query: searchText,
    size: pageSize,
    sort: sortOrder,
  });

  useEffect(() => {
    refetch();
  }, [refetch, searchText]);

  const dataColumns: DataColumn[] = [
    {
      title: 'EMPLOYER',
      dataIndex: 'employerName',
      key: 'employerName',
      width: '40%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.employerName.localeCompare(b.employerName),
      defaultSortOrder: 'ascend',
      render: (item) => {
        return (
          <OverflowPopover>
            <span className="text table-item-link">{item}</span>
          </OverflowPopover>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'COMPANY EMAIL DOMAIN',
      dataIndex: 'featureData',
      key: 'featureData',
      width: '50%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        if (isEmpty(a.featureData)) {
          return 1;
        } else {
          return a.featureData.localeCompare(b.featureData);
        }
      },
      defaultSortOrder: 'ascend',
      render: (item) => {
        return (
          <OverflowPopover>
            <span className="text table-item-link">{item ? item : '-'}</span>
          </OverflowPopover>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'lastUpdatedTs',
      key: 'lastUpdatedTs',
      width: '50%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (isEmpty(a.lastUpdatedTs)) {
          return 1;
        } else {
          return a.lastUpdatedTs.localeCompare(b.lastUpdatedTs);
        }
      },
      render: (colData, record) => {
        const updatedAt = dayjs(colData).format('MMMM D, YYYY h:mm A');
        return isEmpty(colData) ? (
          <div>-</div>
        ) : (
          <div>
            {updatedAt}
            <br></br>
            <span className={styles.addedBy}>
              by {record.lastUpdatedByAdminName}
            </span>
          </div>
        );
      },
    },
    {
      title: 'EMPLOYER VISIBILITY',
      dataIndex: 'employerVisibilityEnabled',
      key: 'employerVisibilityEnabled',
      width: '20%',
      render: (colData, record) => {
        return (
          <>
            <EmployerVisibilityOption
              onConfirmToggle={() => {
                updateStatus({
                  enabled: record.enabled,
                  employerVisibilityEnabled: !colData,
                  organizationId: record.organizationId,
                  employerId: record.employerId,
                  key: 'ID_CARDS',
                }).then(() => {
                  refetch();
                });
              }}
              enabled={record.enabled}
              employerVisibilityEnabled={colData}
              employerName={record.employerName}
              toolName={'ID Cards'}
            />
          </>
        );
      },
      align: 'left',
    },
    {
      title: 'ENABLED',
      dataIndex: 'enabled',
      key: 'enabled',
      width: '15%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (a.enabled) {
          return -1;
        } else {
          return 1;
        }
      },
      render: (colData, record) => {
        return (
          <>
            {record.locked ? (
              <Popover
                trigger="hover"
                content={EMPLOYER_ACCESS_LOCKED}
                placement="left"
                overlayClassName="provisionStatusPopover"
              >
                <Switch onChange={() => {}} checked={false} disabled={true} />
              </Popover>
            ) : (
              <EmployerFeatureToggle
                disabled={!record.featureData}
                onConfirmToggle={() => {
                  updateStatus({
                    enabled: !colData,
                    employerVisibilityEnabled: record.employerVisibilityEnabled,
                    organizationId: record.organizationId,
                    employerId: record.employerId,
                    key: 'ID_CARDS',
                  }).then(() => {
                    refetch();
                  });
                }}
                enabled={colData}
                recordName={record.employerName}
                enableMessage={
                  <div>
                    Are you sure you want to enable the ID Cards? This tool will
                    be immediately available to the employer and their employees
                    via the Mobile app or Benefits Guide.
                    <br />
                    <br />
                    Employees must use their company email to register in the
                    app.
                  </div>
                }
                disableMessage={
                  'Are you sure you want to disable the ID Cards? Once disabled, employees will no longer be able to register or log in the Mobile app or Benefits Guide.'
                }
                title={'ID Cards'}
                disableText="Disable ID Cards"
                enableText="Enable ID Cards"
              />
            )}
          </>
        );
      },
      align: 'left',
    },
  ];

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();

    setSearchText(_searchText);
  };

  useEffect(() => {
    setPaginationConfig(paginationConfig);
    setPageIndex(paginationConfig.paginationIndex);
    setPageSize(paginationConfig.filterInfo.limit);
    const { field, order } = (paginationConfig.sorterInfo as any) || {};
    if (isEmpty(order)) {
      setSortOrder('');
    } else if (field == 'employerName' && order == 'descend') {
      setSortOrder('-name');
    } else if (field == 'employerName' && order == 'ascend') {
      setSortOrder('name');
    }
  }, [paginationConfig, isFetching]);

  return (
    <div className={styles.featureTableWrapper}>
      <ToolList
        title="ID Cards Configuration"
        columns={dataColumns}
        data={featureWrappers}
        hasBenefitsCategoryFilter={false}
        search={'Search Employers'}
        handleSearch={handleSearch}
        isLoading={isFetching}
      ></ToolList>
    </div>
  );
};

export default EmployerIdCardsList;
