import { forwardRef, useImperativeHandle, useState } from 'react';
import { Input, Switch } from 'antd';
import { isEmpty } from 'lodash';
import LocationBasedFeatureModal from 'modules/tools/components/LocationBasedFeatures/LocationBasedFeatureModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { BrokerType, BrokerPopUpState } from 'modules/plans/enums/CommonTypes';

import { SELF_REVIEW_MESSAGE } from 'constants/commonConstants';
import styles from './featureToggle.module.less';

type FeatureToggleProps = {
  onConfirmToggle: any;
  recordName: any;
  enableMessage: string | React.ReactFragment;
  disableMessage: string | React.ReactFragment;
  title: string;
  enabled: boolean;
  disabled?: boolean;
  className?: string;
  width?: number;
  customConfirmText?: string;
  disableText?: string;
  enableText?: string;
  organizationId: string;
  featureType?: string;
  isRfp?: boolean;
  refetchBrokers?: Function;
  allLocationEnabled?: boolean;
  record?: BrokerType;
  isSelfReview?: boolean;
};

const FeatureToggle = forwardRef((props: FeatureToggleProps, ref) => {
  const {
    onConfirmToggle,
    recordName,
    enableMessage,
    disableMessage,
    title,
    enabled,
    disabled,
    enableText,
    disableText,
    customConfirmText = 'Yes - Enable',
    featureType,
    isRfp = false,
    refetchBrokers,
    allLocationEnabled,
    record,
    isSelfReview = false,
  } = props;

  const initialBrokerPopupState = {
    showLocationPopup: false,
    showDisablePopup: false,
  };

  const [showConfirm, setShowConfirm] = useState(false);
  const [disbaleBtn, setDisableBtn] = useState<boolean>(true);
  const [invalidInput, setinvalidInput] = useState<boolean>(false);
  const [currentLocationState, setCurrentLocationState] =
    useState<BrokerPopUpState>(initialBrokerPopupState);
  const [inputText, setinputText] = useState('');

  const [brokerDetails, setBrokerDetails] = useState<BrokerType>();

  const confirmToggle = () => {
    onConfirmToggle();
    setShowConfirm!(false);
    setinvalidInput(false);
    setinputText('');
  };

  const getConfirmText = () => {
    if (enabled) {
      if (disableText !== undefined) {
        return disableText;
      }
      return `${
        !allLocationEnabled
          ? `Disable ${title} for ${recordName}`
          : `Disable ${title} for Above Locations`
      }`;
    }
    if (enableText !== undefined) {
      return enableText;
    }
    return `${customConfirmText} ${title}`;
  };

  useImperativeHandle(ref, () => ({
    openLocationModal(state: boolean, record: BrokerType) {
      setCurrentLocationState({
        showLocationPopup: state,
        showDisablePopup: false,
      });
      setBrokerDetails(record);
      setShowConfirm(true);
    },
    closeDisableModal() {
      setCurrentLocationState({
        showLocationPopup: false,
        showDisablePopup: false,
      });
      setDisableBtn(true);
    },
  }));

  const onModalClose = () => {
    setCurrentLocationState({
      showLocationPopup: false,
      showDisablePopup: false,
    });
    setShowConfirm(false);
  };

  const disableModalOnClose = () => {
    setinvalidInput(false);
    setinputText('');
    setShowConfirm!(false);
    setDisableBtn(true);
    setCurrentLocationState({
      showDisablePopup: false,
      showLocationPopup: false,
    });
  };

  return (
    <div className={styles.switchWrapper}>
      <Switch
        onChange={() => {
          setBrokerDetails(record);
          setShowConfirm(true);
        }}
        onClick={() => {
          if (enabled) {
            setCurrentLocationState({
              showLocationPopup: false,
              showDisablePopup: true,
            });
          }
        }}
        checked={enabled}
        disabled={disabled}
      />

      <LocationBasedFeatureModal
        refetchBrokers={refetchBrokers!}
        isRfp={isRfp}
        title={title}
        visible={
          (showConfirm && !enabled) ||
          (showConfirm && currentLocationState.showLocationPopup)
        }
        onSave={onModalClose}
        onCancel={onModalClose}
        brokerName={brokerDetails?.brokerName}
        organizationId={brokerDetails?.organizationId}
        featureType={featureType ?? ''}
        isSelfReview={isSelfReview}
      />

      <ConfirmationDialog
        title={`${enabled ? 'Disable' : 'Enable'} ${title}`}
        confirmText={getConfirmText()}
        cancelText="Cancel"
        closeModal={() => disableModalOnClose()}
        onConfirm={confirmToggle}
        visible={currentLocationState.showDisablePopup}
        isCancelLink
        isDanger={enabled}
        disableConfirmButton={disbaleBtn}
        confirmBtnFullWidth
        recordName={recordName}
        width={600}
        showLongConfirmButton
      >
        {enabled ? (
          <>
            <p className={styles.warningConfirmation}>{disableMessage}</p>
            <div className={styles.warningConfirmation}>
              <div
                className={styles.disableWarningText}
              >{`Type "DISABLE" in the box below to confirm`}</div>
              <Input
                value={inputText}
                className={`${
                  invalidInput
                    ? styles.disableTextInputError
                    : styles.disableTextInput
                } disableInputField`}
                type="text"
                onChange={(e) => {
                  setinputText(e.target.value);
                  if (e.target.value == 'DISABLE') {
                    setDisableBtn(false);
                    setinvalidInput(false);
                  } else if (isEmpty(e.target.value)) {
                    setDisableBtn(true);
                    setinvalidInput(false);
                  } else {
                    setDisableBtn(true);
                    setinvalidInput(true);
                  }
                }}
                bordered={true}
              />
              {isSelfReview && (
                <p className={styles.selfReviewText}>{SELF_REVIEW_MESSAGE}</p>
              )}
              {invalidInput ? (
                <div className={styles.invalidInput}>Invalid Input</div>
              ) : (
                ''
              )}
            </div>
          </>
        ) : (
          <p className={styles.disableWarningTextEnable}>{enableMessage}</p>
        )}
      </ConfirmationDialog>
    </div>
  );
});

FeatureToggle.displayName = 'FeatureToggle';

export default FeatureToggle;
