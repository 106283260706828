import { forwardRef, useEffect, useState } from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import { isEmpty } from 'lodash';
import { UploadProps } from 'antd';
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';

import { useParams } from 'react-router-dom';
import {
  ALLOWED_FILES_MESSAGE,
  NOT_ALLOWED_FILES_MESSAGE,
  TOKEN_LIMIT_EXCEEDED,
  UPLOAD_LEARN_ALERT_MESSAGE,
  UPLOAD_LEARN_HEADER,
} from 'modules/rfp/constants/RfpConstants';

import {
  getRfpQuestions,
  setRfpExtractionError,
  setRfpFailed,
  setRfpFileName,
  setUploadedLocationId,
} from 'modules/rfp/slices/rfpQuestionSlice';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useGetLocationsQuery } from 'api/featureControl';

import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import NextButton from 'components/buttons/NextButton/NextButton';

import ChooseLocationModal from 'modules/rfp/components/ChooseLocationModal/ChooseLocationModal';

import SelectOption from 'model/SelectOption';

import LoadingBar from './LoadingBar/LoadingBar';
import styles from './uploadLearn.module.less';

type UploadAndLearnProps = {
  closeModal: () => void;
  nextStep: () => void;
  isModalOpen: boolean;
  setIsModalOpen?: (isModalOpen: boolean) => void;
  refetch?: () => void;
  isEditMode?: boolean;
};

const allowedFormats = ['doc', 'docx', 'pdf'];
const UploadAndLearn = forwardRef((props: UploadAndLearnProps, ref) => {
  const { brokerId } = useParams();
  const dispatch = useAppDispatch();

  const { isModalOpen, nextStep, closeModal } = props;

  const [isErrorFileType, setIsErrorFileType] = useState<boolean>(false);
  const [isChooseLocationModalOpen, setIsChooseLocationModalOpen] =
    useState<boolean>(false);

  const [addedFile, setAddedFile] = useState<any>('');

  const userLocations = useAppSelector(
    (state) => state.auth.auth.appBootupInfo?.locationIds
  );
  const completedTrainData = useAppSelector(
    (state) => state.rfp?.completedTrainData
  );

  const isLoading = useAppSelector((state) => state.rfp.isLoading);
  const isError = useAppSelector((state) => state.rfp?.isRfpExtractError);
  const isFailed = useAppSelector((state) => state.rfp?.isRfpFailed);
  const inProgressId = useAppSelector((state) => state.rfp?.inProgressId);

  const { data: allLocations } = useGetLocationsQuery({
    organizationId: brokerId,
    key: 'RFP',
  });

  const [locationDropdownOptions, setLocationDropdownOptions] = useState<
    SelectOption[]
  >([]);

  const isUserInMultipleLocations =
    isEmpty(userLocations) || (userLocations && userLocations.length > 1);

  const uploadFile: UploadProps = {
    name: 'file',
    action: '',
    customRequest: (options) => {
      return new Promise((resolve, reject) => {
        // Simulating a file upload process
        setTimeout(() => {
          const response = {
            status: 'success',
            message: 'File uploaded successfully',
          };
          if (options.onSuccess) {
            options.onSuccess(response);
          }
          resolve(response);
        }, 500);
      });
    },
    onDrop: () => {},
    onChange(event) {
      setIsErrorFileType(false);
      const fileExt = event.file.name.split('.').pop().toLowerCase();
      if (!allowedFormats.includes(fileExt)) {
        setIsErrorFileType(true);
      } else {
        dispatch(setRfpFailed(false));
        dispatch(setRfpExtractionError(''));
        dispatch(
          setRfpFileName({
            fileName: event.file.name,
          })
        );
        setAddedFile(event.file);
      }
    },
  };

  useEffect(() => {
    if (allLocations) {
      const options = allLocations?.locationFeatures
        .filter(({ enabled }: any) => enabled)
        .filter(
          ({ id }: any) => isEmpty(userLocations) || userLocations!.includes(id)
        )
        .map(
          ({ id, locationName }: any) =>
            ({
              label: locationName,
              value: id,
              isDisabled: !completedTrainData.some(
                ({ locationId }: any) => locationId === id
              ),
            } as SelectOption)
        );
      setLocationDropdownOptions(options);
    }
  }, [allLocations, completedTrainData, userLocations]);

  useEffect(() => {
    if (inProgressId) {
      nextStep();
    }
    // eslint-disable-next-line
  }, [inProgressId]);

  // Remove the added file on modal close
  useEffect(() => {
    if (!isModalOpen) {
      setAddedFile(null);
    }
    // eslint-disable-next-line
  }, [closeModal]);

  const handleConfirmUpload = async ({
    value: locationId,
  }: Partial<SelectOption>) => {
    setIsChooseLocationModalOpen(false);
    await dispatch(
      getRfpQuestions(brokerId, addedFile, isModalOpen, locationId!)
    );
    dispatch(setUploadedLocationId(locationId!));
    setAddedFile('');
  };

  const handleNext = () => {
    if (isUserInMultipleLocations) {
      setIsChooseLocationModalOpen(true);
    } else if (userLocations) {
      handleConfirmUpload({ value: userLocations[0] });
    }
  };

  return (
    <>
      <div className={styles.uploadLearnWrapper}>
        <div className={styles.uploadScreenPrompt}>
          <p>{UPLOAD_LEARN_HEADER}</p>
        </div>
        <div className={styles.alertForUploadLearn}>
          <FixedAlertMessage type="info" message={UPLOAD_LEARN_ALERT_MESSAGE} />
        </div>
        <div className={styles.alertForUploadLearn}>
          {isError && (
            <FixedAlertMessage
              closable
              closeText="close"
              type="error"
              message={TOKEN_LIMIT_EXCEEDED}
            />
          )}
        </div>
        {isErrorFileType && (
          <FixedAlertMessage
            closeAlert={() => {
              setIsErrorFileType(false);
            }}
            className={styles.alertForUploadLearn}
            type="error"
            message={NOT_ALLOWED_FILES_MESSAGE}
            closeText="close"
            closable
          />
        )}
        <div>
          {isFailed && (
            <FixedAlertMessage
              className={styles.alertForUploadLearn}
              type="error"
              message="AI cannot process scanned documents or documents primarily consisting of images. Please check your file."
              closeText="close"
              closable
            />
          )}
        </div>
        {!isLoading ? (
          <>
            <Dragger
              showUploadList={false}
              maxCount={1}
              multiple={false}
              accept=".pdf,.docx,.doc"
              className={styles.uploadFileDragger}
              listType="text"
              type="drag"
              action={''}
              {...uploadFile}
            >
              <p>
                <InboxOutlined />
              </p>
              <p>{ALLOWED_FILES_MESSAGE}</p>
              <b>PDF, DOC and DOCX formats are supported.</b>
            </Dragger>
            <span className={styles.addedFileName}>
              {addedFile?.name}
              {addedFile?.name && (
                <DeleteOutlined
                  className={styles.deleteFileIcon}
                  onClick={() => {
                    setAddedFile('');
                  }}
                />
              )}
            </span>
          </>
        ) : (
          <div className={styles.fileUploadingScreen}>
            <LoadingBar duration={108000} />
            <div className={styles.extractedQuestionText}>
              <p className={styles.extractingQuestionsText}>
                Extracting questions ...
              </p>
              <p>Generating responses ...</p>
            </div>
          </div>
        )}

        {addedFile ? (
          <NextButton
            className={styles.nextButton}
            buttonText="Next"
            loading={isLoading}
            nextStep={handleNext}
          />
        ) : (
          <NextButton
            className={styles.nextButtonDisabled}
            buttonText="Next"
            nextStep={() => {}}
          />
        )}
      </div>
      <ChooseLocationModal
        visible={isChooseLocationModalOpen}
        locations={locationDropdownOptions}
        onConfirm={handleConfirmUpload}
        closeModal={() => setIsChooseLocationModalOpen(false)}
      />
    </>
  );
});

UploadAndLearn.displayName = 'UploadAndLearn';

export default UploadAndLearn;
