import { FC, useCallback, useEffect, useState } from 'react';
import {
  generatePath,
  Location,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Layout, notification } from 'antd';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import { sendUserToAnalytics } from 'api/initGA4';
import {
  loginTypes,
  navContexts,
  topLevelMenus,
} from 'constants/authConstants';
import {
  clearBroker,
  clearCarrier,
  clearChatHistory,
  clearEmployer,
  getBroker,
  getCarrier,
  getChatHistory,
  getEmployer,
} from 'layout/slices/layoutSlice';
import { clearFilter } from 'modules/issueslog/slices/issuesLogFilterSlice';
import { logout } from 'modules/auth/slices/authSlice';

import routes, { ChildRoute, RouteProp } from 'routes/navigationTree';
import { LOGIN_PATH } from 'modules/auth/routes';
import { clearBrokerDashboard } from 'modules/brokers/slices/brokerDashboardSlice';
import { getNavContext } from 'util/commonUtil';

import { MASTER } from 'constants/commonConstants';
import { PLAN_NOTIFICATION_KEY } from 'constants/benguideCollaborationConstants';
import { NEW_CHAT_NAV_CONSTANT } from 'modules/assistant/constants/constants';

import AppSider from './AppSider';
import AppHeaderNav from './AppHeaderNav';
import SiderLogo from './components/SiderLogo/SiderLogo';
import HeaderLogo from './components/HeaderLogo/HeaderLogo';

import styles from './layout.module.less';

const { Content } = Layout;

type LocationState = {
  from: Location;
};

const AppLayout: FC = () => {
  const { auth } = useAppSelector((state) => state.auth);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const dispatch = useAppDispatch();
  const {
    setBrokerId,
    brokerId,
    context,
    employerId,
    setEmployerId,
    setBroker,
    setEmployer,
    carrier,
    setCarrier,
    setIssueId,
    issueId,
    setIssueLogType,
  } = useNavContext();
  const { appBootupInfo } = auth;

  const [headerMenuItems, setHeaderMenuItems] = useState<RouteProp[]>([]);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([]);
  const [selectedTopNav, setSelectedTopNav] = useState<
    string | null | undefined
  >(null);
  const [childrenNavs, setChildrenNavs] = useState<ChildRoute[]>([]);
  const [defaultSelectedSideKeys, setDefaultSelectedSideKeys] = useState<
    string[]
  >([]);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState<string[]>([]);

  const brokerDetails = useAppSelector((state) => state.layout.broker);
  const employerDetials = useAppSelector((state) => state.layout.employer);
  const carrierRoutes = useAppSelector((state) => state.layout.carrierRoutes);
  const aiAssistantChatRoutes = useAppSelector(
    (state) => state.layout.aiAssistantChatRoutes
  );
  const carrierDetails = useAppSelector((state) => state.layout.carrier);
  const [notificationKey] = useState(PLAN_NOTIFICATION_KEY);

  const isIssueLogContext = () => {
    return (
      !window.location.pathname.includes('employers') &&
      (window.location.pathname.includes('issues-log/account') ||
        window.location.pathname.includes('issues-log/support'))
    );
  };

  useEffect(() => {
    if (appBootupInfo) {
      sendUserToAnalytics(appBootupInfo?.individualId);
    }
  }, [appBootupInfo]);

  useEffect(() => {
    if (carrierDetails && carrierDetails.id) {
      setCarrier(carrierDetails.id, carrierDetails.name, null);
    }
  }, [carrierDetails, setCarrier]);

  useEffect(() => {
    if (
      brokerDetails &&
      brokerDetails.id &&
      appBootupInfo?.type !== loginTypes.erAdmin
    ) {
      setBroker(brokerDetails.id, brokerDetails.name, brokerDetails.logoUrl);
    }
    // eslint-disable-next-line
  }, [brokerDetails, setBroker]);

  useEffect(() => {
    if (
      employerDetials &&
      employerDetials.id &&
      brokerDetails &&
      brokerDetails.id
    ) {
      setEmployer(
        employerDetials.id,
        employerDetials.name,
        employerDetials.logoUrl
      );
    }
  }, [employerDetials, brokerDetails, setEmployer]);

  // Set broker/ER context again here to avoid data loss due to page reload
  useEffect(() => {
    if (params && params.brokerId && !params.employerId) {
      dispatch(getBroker(params.brokerId));
      setBrokerId(params.brokerId);
    } else if (params && params.brokerId && params.employerId) {
      dispatch(getBroker(params.brokerId));
      dispatch(getEmployer(params.employerId));
      setEmployerId(params.employerId, params.brokerId);
    }
    if (!params.brokerId) {
      // Set context to Platform admin if broker ID is null
      setBrokerId(null);
    }

    if (isIssueLogContext()) {
      setIssueLogType(params.type || '');
      setIssueId(params.issueId || '');
    }
    if (params && !params.carrierId) {
      setCarrier(null, null, null);
      dispatch(clearCarrier());
    }
    if (params && !params.employer) {
      dispatch(clearEmployer());
    }
    if (params && !params.chatId) {
      dispatch(clearChatHistory());
    }
    // eslint-disable-next-line
  }, [
    params,
    setBrokerId,
    setEmployerId,
    location,
    dispatch,
    setCarrier,
    setIssueId,
  ]);

  useEffect(() => {
    if (context === navContexts.assistant) {
      dispatch(getChatHistory());
    }
  }, [context, dispatch]);

  useEffect(() => {
    if (params && params.carrierId) {
      dispatch(
        getCarrier(
          params.brokerId,
          params.employerId,
          getNavContext(params) === navContexts.platform
            ? MASTER
            : getNavContext(params),
          params.carrierId,
          getNavContext(params)
        )
      );
      setCarrier(params.carrierId, null, null);
    }
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (appBootupInfo && location.pathname === '/' && navigate) {
      const { type, organizationId, primaryEmployerId } = appBootupInfo;
      // TODO: handle checking user permissions to access url
      if (locationState?.from) {
        navigate(locationState.from);
        return;
      }
      // redirected to the login page
      switch (type) {
        case loginTypes.platform:
          navigate('/');
          break;
        case loginTypes.bokerAdmin:
          navigate(`/brokers/${organizationId}`);
          // TODO get the broker home path from the routes array
          break;
        case loginTypes.erAdmin:
          navigate(`/brokers/${organizationId}/employers/${primaryEmployerId}`);
          // TODO get the ER home path from the routes array
          break;
      }
    }
  }, [appBootupInfo, location.pathname, navigate, locationState]);

  const getSelectedNavObj = useCallback((): RouteProp | null => {
    // this method is used to fetch selected top level menu item and then identify which left nav to be rendered
    let selected: RouteProp | null = null;
    let currentRoutes: RouteProp[] = [];
    switch (context) {
      case navContexts.platform:
        currentRoutes = routes.lumity;
        break;
      case navContexts.broker:
        currentRoutes = routes.broker(appBootupInfo?.type || '');
        break;
      case navContexts.er:
        currentRoutes = routes.employer(appBootupInfo?.type || '');
        break;
      case navContexts.carrier:
        currentRoutes = carrierRoutes;
        break;
      case navContexts.issuesLog:
        currentRoutes =
          appBootupInfo?.type === loginTypes.platform
            ? JSON.parse(JSON.stringify(routes.techIssuesLog))
            : JSON.parse(JSON.stringify(routes.brokerIssuesLog));
        break;
      case navContexts.assistant:
        currentRoutes = aiAssistantChatRoutes;
        break;
    }

    currentRoutes?.forEach((item) => {
      item?.children?.forEach((child) => {
        let currentPath = child.path;
        if (child.children?.length) {
          // if the menu item has children menu items need to setup the top level selected nav item by getting first level as the selected navigation
          for (const subChild of child.children) {
            if (subChild?.children && subChild.isMenuItem) {
              for (const thirdSub of subChild.children) {
                if (brokerId && employerId) {
                  currentPath = generatePath(thirdSub.path, {
                    brokerId: brokerId,
                    employerId: employerId,
                    planId: params.planId ? params.planId : '',
                  });
                  selected = item;
                  return;
                }
              }
            } else {
              if (context === navContexts.broker && brokerId) {
                currentPath = generatePath(subChild.path, {
                  brokerId: brokerId,
                });
              } else if (context === navContexts.er && brokerId && employerId) {
                currentPath = generatePath(subChild.path, {
                  brokerId: brokerId,
                  employerId: employerId,
                  planId: params.planId ? params.planId : '',
                });
              }
            }

            // TODO : This is a temporary fix for children of plans.Need to do a generic fix
            if (
              currentPath === location.pathname ||
              (params.planId && location.pathname.includes(currentPath)) ||
              (params.type &&
                location.pathname.includes(`issues-log/${params.type}`))
            ) {
              selected = item;
              return;
            }
          }
        }

        if (context === navContexts.broker && brokerId) {
          currentPath = generatePath(child.path, { brokerId: brokerId });
        } else if (context === navContexts.er && brokerId && employerId) {
          currentPath = generatePath(child.path, {
            brokerId: brokerId,
            employerId: employerId,
          });
        } else if (context === navContexts.carrier && carrier.id) {
          if (params && params.carrierId) {
            if (brokerId && employerId) {
              currentPath = generatePath(child.path, {
                brokerId: brokerId,
                employerId: employerId,
                carrierId: carrier.id,
              });
            } else if (brokerId) {
              currentPath = generatePath(child.path, {
                brokerId: brokerId,
                carrierId: carrier.id,
              });
            } else {
              currentPath = generatePath(child.path, {
                carrierId: carrier.id,
              });
            }
          } else {
            setCarrier(null, null, null);
            dispatch(clearCarrier());
          }
        } else if (context === navContexts.issuesLog && issueId) {
          currentPath = generatePath(child.path, {
            brokerId: brokerId || '',
            employerId: employerId || '',
            issueId: issueId,
          });
        } else if (context === navContexts.assistant && brokerId) {
          currentPath = generatePath(child.path, { brokerId: brokerId! });
        }

        if (currentPath === location.pathname) {
          selected = item;
          return;
        }
      });
    });

    return selected;
  }, [
    context,
    carrierRoutes,
    appBootupInfo?.type,
    brokerId,
    employerId,
    carrier.id,
    issueId,
    location.pathname,
    params,
    aiAssistantChatRoutes,
    setCarrier,
    dispatch,
  ]);

  useEffect(() => {
    if (appBootupInfo) {
      const selected = getSelectedNavObj();
      switch (appBootupInfo.type) {
        case loginTypes.platform:
          setHeaderMenuItems(routes.lumity);
          if (context === navContexts.broker) {
            setDefaultSelectedKeys(['/brokers']);
          } else if (context === navContexts.er) {
            setDefaultSelectedKeys(['/employers']);
          } else {
            setDefaultSelectedKeys([selected?.path as string]);
          }
          break;
        case loginTypes.bokerAdmin:
          setHeaderMenuItems(routes.broker(appBootupInfo?.type || ''));
          if (context === navContexts.er) {
            const erNav = routes
              .broker(appBootupInfo?.type || '')
              .find((route) => route.name === topLevelMenus.employers);
            setDefaultSelectedKeys([erNav?.path as string]);
          } else if (context === navContexts.issuesLog) {
            const erNav = routes.brokerIssuesLog.find(
              (route) => route.name === topLevelMenus.issuesLog
            );
            setDefaultSelectedKeys([erNav?.path as string]);
          } else if (context === navContexts.assistant) {
            const assistantNav = routes.brokerAiAssistant.find(
              (item) => item.name === topLevelMenus.assistant
            );
            setDefaultSelectedKeys([assistantNav?.path as string]);
          } else {
            setDefaultSelectedKeys([selected?.path as string]);
          }
          break;
        case loginTypes.erAdmin:
          setHeaderMenuItems(routes.employer(appBootupInfo?.type || ''));
          setDefaultSelectedKeys([selected?.path as string]);
          break;
      }
      if (context === navContexts.assistant) {
        setSelectedTopNav(topLevelMenus.assistant);
      } else {
        if (selected) {
          setSelectedTopNav(selected.name);
        }
      }
    }
  }, [
    appBootupInfo,
    location,
    brokerId,
    context,
    employerId,
    getSelectedNavObj,
  ]);

  const getSideNavItems = useCallback(
    (
      routes: RouteProp[],
      path: string | null | undefined,
      context: string,
      brokerId?: string | null,
      employerId?: string | null,
      carrierId?: string | null
    ) => {
      let children: ChildRoute[] = [];
      if (context !== navContexts.assistant) {
        routes.forEach((route) => {
          if (route.name === path) {
            children.push(...route.children);
          }
        });
      }
      // This is where URL params getting added ø
      switch (context) {
        case navContexts.broker:
          if (brokerId && !employerId) {
            children = children.map((child) => {
              child.path = generatePath(child.path, { brokerId: brokerId });
              if (child.children) {
                const urlParams = {
                  brokerId: brokerId || '',
                  employerId: employerId || '',
                };
                const sub = child.children.map((subChild) => {
                  subChild.path = generatePath(subChild.path, urlParams);
                  return subChild;
                });
                child.children = sub;
              }
              return child;
            });
          }
          break;
        case navContexts.er:
          if (brokerId && employerId) {
            const urlParams = {
              brokerId: brokerId,
              employerId: employerId,
              planId: params.planId ? params.planId : '',
              issueId: params.issueId ? params.issueId : '',
            };
            // There is a 2nd level side navigation in ER context (Benefits)
            children = children.map((child) => {
              if (child.children) {
                const sub = child.children.map((subChild) => {
                  if (subChild.children) {
                    subChild.children = subChild.children?.map((child) => {
                      child.path = generatePath(child.path, urlParams);
                      return child;
                    });
                  }
                  subChild.path = generatePath(subChild.path, urlParams);
                  return subChild;
                });
                child.children = sub;
              }
              child.path = generatePath(child.path, urlParams);
              return child;
            });
          }
          break;
        case navContexts.issuesLog:
          const urlParams = {
            brokerId: brokerId || '',
            employerId: employerId || '',
            issueId: params.issueId ? params.issueId : '',
          };
          // There is a 2nd level side navigation in ER context (Benefits)
          children = children.map((child) => {
            if (child.children) {
              const sub = child.children.map((subChild) => {
                subChild.path = generatePath(subChild.path, urlParams);
                return subChild;
              });
              child.children = sub;
            }
            child.path = generatePath(child.path, urlParams);
            return child;
          });
          break;
        case navContexts.carrier:
          if (carrierId) {
            // There is a 2nd level side navigation in Carriers
            children = children.map((child) => {
              if (child.children && params && params.carrierId) {
                const sub = child.children.map((subChild) => {
                  if (brokerId && employerId) {
                    subChild.path = generatePath(subChild.path, {
                      carrierId: carrierId,
                      brokerId: brokerId,
                      employerId: employerId,
                    });
                  } else if (brokerId) {
                    subChild.path = generatePath(subChild.path, {
                      carrierId: carrierId,
                      brokerId: brokerId,
                    });
                  } else {
                    subChild.path = generatePath(subChild.path, {
                      carrierId: carrierId,
                    });
                  }
                  return subChild;
                });
                child.children = sub;
              }
              if (params && params.carrierId) {
                if (brokerId && employerId) {
                  child.path = generatePath(child.path, {
                    brokerId: brokerId,
                    employerId: employerId,
                    carrierId: carrierId,
                  });
                } else if (brokerId) {
                  child.path = generatePath(child.path, {
                    brokerId: brokerId,
                    carrierId: carrierId,
                  });
                } else {
                  child.path = generatePath(child.path, {
                    carrierId: carrierId,
                  });
                }
              } else {
                setCarrier(null, null, null);
                dispatch(clearCarrier());
              }
              return child;
            });
          }
          break;
        case navContexts.assistant:
          children = routes?.map((route) => {
            return {
              ...route,
              path: generatePath(route.path, { brokerId: brokerId! }),
            };
          });
      }
      return children;
    },
    [params, setCarrier, dispatch]
  );

  useEffect(() => {
    if (params?.chatId == NEW_CHAT_NAV_CONSTANT) {
      setDefaultSelectedSideKeys([]);
    }
    switch (context) {
      case navContexts.platform:
        // Perform deep copy to prevent copying object references.
        setChildrenNavs(
          getSideNavItems(
            JSON.parse(JSON.stringify(routes.lumity)),
            selectedTopNav,
            context
          )
        );
        break;
      case navContexts.broker:
        // Perform deep copy to prevent copying object references.
        setChildrenNavs(
          getSideNavItems(
            JSON.parse(
              JSON.stringify(routes.broker(appBootupInfo?.type || ''))
            ),
            selectedTopNav,
            context,
            brokerId
          )
        );
        break;
      case navContexts.er:
        // Perform deep copy to prevent copying object references.
        setChildrenNavs(
          getSideNavItems(
            JSON.parse(
              JSON.stringify(routes.employer(appBootupInfo?.type || ''))
            ),
            selectedTopNav,
            context,
            brokerId,
            employerId
          )
        );
        break;
      case navContexts.carrier:
        // Perform deep copy to prevent copying object references.
        setChildrenNavs(
          getSideNavItems(
            JSON.parse(JSON.stringify(carrierRoutes)),
            'Carriers',
            context,
            brokerId,
            employerId,
            carrier?.id
          )
        );
        break;
      case navContexts.issuesLog:
        // Perform deep copy to prevent copying object references.
        setChildrenNavs(
          getSideNavItems(
            appBootupInfo?.type === loginTypes.platform
              ? JSON.parse(JSON.stringify(routes.techIssuesLog))
              : JSON.parse(JSON.stringify(routes.brokerIssuesLog)),
            selectedTopNav,
            context,
            brokerId,
            employerId,
            null
          )
        );
        break;

      case navContexts.assistant:
        setChildrenNavs(
          getSideNavItems(
            aiAssistantChatRoutes,
            selectedTopNav,
            context,
            brokerId,
            employerId,
            null
          )
        );
        break;
    }
  }, [
    context,
    selectedTopNav,
    brokerId,
    employerId,
    getSideNavItems,
    carrierRoutes,
    aiAssistantChatRoutes,
    carrier?.id,
    appBootupInfo?.type,
    params,
  ]);

  useEffect(() => {
    setDefaultOpenKeys([]);
    if (childrenNavs.length) {
      for (const child of childrenNavs) {
        if (child.children?.length) {
          for (const subChild of child.children) {
            let path = subChild.path;
            if (brokerId && !employerId) {
              path = generatePath(subChild.path, {
                brokerId: brokerId,
                carrierId: carrier?.id || '',
              });
            } else if (brokerId && employerId) {
              path = generatePath(subChild.path, {
                brokerId: brokerId,
                employerId,
                planId: params.planId ? params.planId : '',
                carrierId: carrier?.id || '',
              });
            }
            // TODO : This is a temporary fix for children of plans.Need to do a generic fix
            if (
              path === location.pathname ||
              (params.planId && location.pathname.includes(path)) ||
              (params.issueId && location.pathname.includes(path)) ||
              (params.proposalId && location.pathname.includes(path))
            ) {
              if (subChild.children && subChild.isSelectableMenu) {
                if (defaultOpenKeys.includes(`subMenu-${subChild.path}`)) {
                  setDefaultOpenKeys(
                    defaultOpenKeys.filter(
                      (key) => key !== `subMenu-${subChild.path}`
                    )
                  );
                } else {
                  setDefaultOpenKeys([
                    `subMenu-${child.path}`,
                    `subMenu-${subChild.path}`,
                  ]);
                }
              } else {
                setDefaultOpenKeys([`subMenu-${child.path}`]);
              }
              setDefaultSelectedSideKeys([subChild.path]);
              return;
            }
            // this fix is for third level navigation to keep nav collapse open
            if (subChild.children) {
              for (const subChildLowest of subChild.children) {
                let path = subChildLowest.path;
                // 3rd level is only for ERs as of now so both broker & employer ids should be present
                if (brokerId && employerId) {
                  path = generatePath(subChildLowest.path, {
                    brokerId: brokerId,
                    employerId,
                    planId: params.planId ? params.planId : '',
                    carrierId: carrier?.id || '',
                    issueId: params.issueId ? params.issueId : '',
                  });
                }
                if (
                  path === location.pathname ||
                  (params.offerId && location.pathname.includes(path))
                ) {
                  setDefaultSelectedSideKeys([subChildLowest.path]);
                  setDefaultOpenKeys([
                    `subMenu-${child.path}`,
                    `subMenu-${subChild.path}`,
                  ]);
                  return;
                }
              }
            }
          }
        }
        let path = child.path;
        if (brokerId && !employerId) {
          path = generatePath(child.path, {
            brokerId: brokerId,
            carrierId: carrier?.id || '',
          });
        } else if (brokerId && employerId) {
          path = generatePath(child.path, {
            brokerId: brokerId,
            employerId: employerId,
            carrierId: carrier?.id || '',
          });
        }
        if (path === location.pathname) {
          setDefaultSelectedSideKeys([child.path]);
          return;
        }
      }
    }
    // eslint-disable-next-line
  }, [childrenNavs, location, brokerId, employerId, params, carrier?.id]);

  const onClickMenuItem = (item: any) => {
    notification.close(notificationKey);
    dispatch(clearCarrier());
    setCarrier(null, null, null);
    if (context && context === navContexts.broker && brokerId) {
      routes.broker(appBootupInfo?.type || '').forEach((route) => {
        if (
          generatePath(route.path, {
            brokerId: brokerId,
            chatId: NEW_CHAT_NAV_CONSTANT,
          }) === item.path
        ) {
          setSelectedTopNav(route.name);
        }
      });
      dispatch(clearEmployer());
    } else if (
      context &&
      context === navContexts.er &&
      brokerId &&
      employerId
    ) {
      dispatch(clearEmployer());
      routes.employer(appBootupInfo?.type || '').forEach((route) => {
        if (route.path === item.path) {
          setSelectedTopNav(route.name);
        }
      });
    } else if (context && context === navContexts.assistant) {
      setSelectedTopNav(topLevelMenus.assistant);
    } else {
      routes.lumity.forEach((route) => {
        if (route.path === item.path) {
          setSelectedTopNav(route.name);
        }
      });
    }
    if (appBootupInfo && appBootupInfo.type === loginTypes.platform) {
      setBrokerId(null);
      dispatch(clearBroker());
    }
  };

  const sideMenuOnClick = (item: any) => {
    setDefaultSelectedSideKeys([item.key]);
    notification.close(notificationKey);
  };

  const clearIssueFilter = () => {
    dispatch(clearFilter());
  };

  const logoutHandler = () => {
    dispatch(
      logout(() => {
        navigate(LOGIN_PATH);
        dispatch(clearCarrier());
        dispatch(clearChatHistory());
        setCarrier(null, null, null);
        setBrokerId(null);
        clearIssueFilter();
        dispatch(clearBroker());
        dispatch(clearEmployer());
        dispatch(clearBrokerDashboard());
      })
    );
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AppHeaderNav
        menuItems={headerMenuItems}
        defaultSelectedKeys={defaultSelectedKeys}
        logout={logoutHandler}
        logo={
          appBootupInfo && (
            <HeaderLogo loginType={appBootupInfo.type} broker={brokerDetails} />
          )
        }
        onClickMenuItem={onClickMenuItem}
        appBootupInfo={appBootupInfo}
        brokerId={brokerId}
        employerId={employerId}
      />
      <Layout>
        <AppSider
          defaultSelectedKeys={defaultSelectedSideKeys}
          defaultOpenKeys={defaultOpenKeys}
          childrenNavs={childrenNavs}
          menuOnClick={sideMenuOnClick}
          logo={
            appBootupInfo && (
              <SiderLogo
                loginType={appBootupInfo?.type}
                context={context}
                brokerName={brokerDetails?.name}
                logoUrl={brokerDetails?.logoUrl}
                erLogoUrl={employerDetials?.logoUrl}
                carrierLogoUrl={carrierDetails?.logoUrl}
              />
            )
          }
          loginType={appBootupInfo?.type}
          selectedTopNav={selectedTopNav}
        />
        <Layout>
          <Content
            className={styles.appContent}
            style={{
              padding: context === navContexts.assistant ? 0 : 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
