import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Col, Menu, Row } from 'antd';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { AdditionalPlan } from 'model/plans/AdditionalPlan';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import SearchBar from 'components/SearchBar/SearchBar';
import PlanSelectButton from 'modules/plans/components/PlanSelectButton/PlanSelectButton';
import EmptyAddPlan from 'modules/plans/components/EmptyAddPlan/EmptyAddPlan';
import PlansList from 'modules/plans/components/PlansList/PlansList';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import AddWorkLifePlan from 'modules/plans/components/AddWorkLifePlan/AddWorkLifePlan';
import TablePagination from 'model/TablePagination';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
  resetPaginationConfig,
} from 'util/commonUtil';
import { ReactComponent as IconHolidayTimeOff } from 'assets/images/benefitCategories/icon-time-off.svg';
import PlanYear from 'model/PlanYear';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import { getWorkLifePlanList } from 'modules/plans/slices/workLifePlanSlice';
import PlanListPlanYearDropDown from 'components/PlanListPlanYearDropDown/PlanListPlanYearDropDown';
import { AlertInfo } from 'components/Alert/AlertMessage';
import {
  BenefitCategory,
  EMPLOYER_MEMBER_RESTRICTED,
  exitWithoutSavingMsg,
  planCreateSuccess,
} from 'constants/commonConstants';
import AlertMessageWrapper from 'modules/plans/components/AlertMessageWrapper/AlertMessageWrapper';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { usePrevious } from 'hooks/usePrevious';
import { usePermitByUserType } from 'hooks/usePermitByUserType';

import styles from './holidaysTimeOffPlanList.module.less';

const planData = [
  { value: 'PAID_TIME_OFF', label: 'Paid Time Off' },
  { value: 'SICK', label: 'Sick Days' },
  { value: 'HOLIDAY', label: 'Company Holidays' },
  { value: 'FLEXIBLE_WORKING_HOURS', label: 'Flexible Working Hours' },
  { value: 'OTHER_TIME_OFF', label: 'Other Time Off' },
];

const HolidaysTimeOffPlanList: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { employerId, employer, brokerId } = useNavContext();

  const [fullScreenModalView, setFullScreenModalView] =
    useState<boolean>(false);
  const [planYear, setPlanYear] = useState<PlanYear | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [benefitKind, setBenefitKind] = useState<{ value: ''; label: '' }>({
    value: '',
    label: '',
  });
  const [isCloseConfirmed, setClosedConfirmed] = useState<boolean>(false);
  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText,
    },
    filters: {},
  });

  const { workLifePlanList, inProgress, error } = useAppSelector(
    (state) => state.plan.holidaysTimeOffPlan
  );

  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [visible, setVisible] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { content, metadata } = workLifePlanList;
  const total = metadata ? metadata.total : 0;
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );
  const addPlanRef = useRef<any>();
  useEffect(() => {
    if (employerId) {
      dispatch(getPlanYears(employerId));
    }
  }, [dispatch, employerId]);

  useEffect(() => {
    if (fullScreenModalView) {
      setClosedConfirmed(false);
    }
  }, [fullScreenModalView]);

  const previousPlanYear = usePrevious(planYear?.id);

  useEffect(() => {
    if (employerId && planYear?.id) {
      if (previousPlanYear !== planYear?.id) {
        setPaginationConfig(resetPaginationConfig(paginationConfig));
      }
      dispatch(
        getWorkLifePlanList(
          convertPaginationConfig(paginationConfig),
          employerId,
          planYear.id,
          BenefitCategory.HOLIDAYS_AND_TIME_OFF.value
        )
      );
    }
    // eslint-disable-next-line
  }, [dispatch, employerId, paginationConfig, planYear?.id]);

  useEffect(() => {
    if (fullScreenModalView) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [fullScreenModalView]);

  const getHolidaysAndTimeOffPlanTypes = () => {
    return (
      <Menu>
        {planData.map((item, key) => {
          return (
            <Menu.Item
              key={key}
              className={styles.menuItemHeader}
              onClick={() => openModal(item)}
            >
              {item.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const closePlanAddModal = () => {
    setVisibleConfirmation(true);
  };

  const toggleVisibleConfirmation = () => {
    setVisibleConfirmation(false);
    setClosedConfirmed(false);
  };

  const onConfirm = () => {
    if (employerId && planYear) {
      dispatch(
        getWorkLifePlanList(
          convertPaginationConfig(paginationConfig),
          employerId,
          planYear.id,
          BenefitCategory.HOLIDAYS_AND_TIME_OFF.value
        )
      );
    }
    setFullScreenModalView(false);
    setVisibleConfirmation(false);
    setClosedConfirmed(true);
    addPlanRef.current.reset();
  };

  const openModal = (category: any) => {
    setBenefitKind(category);
    setFullScreenModalView(true);
    setVisible(false);
  };

  const onClose = () => {
    setClosedConfirmed(true);
    if (employerId && planYear) {
      if (!error) {
        setAlertMessage({
          type: 'success',
          message: planCreateSuccess,
        });
        setVisible(true);
      }
      dispatch(
        getWorkLifePlanList(
          convertPaginationConfig(paginationConfig),
          employerId,
          planYear.id,
          BenefitCategory.HOLIDAYS_AND_TIME_OFF.value
        )
      );
    }
    setFullScreenModalView(false);
  };

  const getData = (dataFilters: TablePagination) => {
    const { sorterInfo, paginationIndex, filterInfo, filters } = dataFilters;
    if (sorterInfo) {
      const paginationConfigData = {
        sorterInfo,
        paginationIndex,
        filterInfo: {
          limit: filterInfo.limit,
          offset: filterInfo.offset,
          searchText,
        },
        filters,
      };
      setPaginationConfig(paginationConfigData);
    } else {
      const paginationConfigData = {
        sorterInfo: {
          columnKey: 'name',
          field: 'name',
          order: 'ascend',
        },
        paginationIndex,
        filterInfo: {
          limit: filterInfo.limit,
          offset: filterInfo.offset,
          searchText,
        },
        filters,
      } as TablePagination;
      setPaginationConfig(paginationConfigData);
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setSearchText(_searchText);
    const pageInfo = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText: _searchText },
    };
    debounceLoadData(pageInfo);
  };

  const goToOverviewPage = (holidayAndTimeOffPlan: AdditionalPlan) => {
    navigate(
      `/brokers/${brokerId}/employers/${employerId}/holiday-time-off/${holidayAndTimeOffPlan.id}/overview`
    );
  };

  const setSelectedPlanYear = (planYear: PlanYear | null | undefined) => {
    if (employerId && planYear) {
      setPlanYear(planYear);
    }
  };

  const closeAlert = () => setVisible(false);

  const addPlanButton = (
    <PlanSelectButton
      overlay={getHolidaysAndTimeOffPlanTypes()}
      selectLabel="Add Holiday & Time Off Plan"
      className={styles.actionButton}
    />
  );

  const permittedAddPlanButton = usePermitByUserType(
    addPlanButton,
    EMPLOYER_MEMBER_RESTRICTED
  );

  return (
    <ContentContainer>
      <AlertMessageWrapper
        type={alertMessage.type}
        message={alertMessage.message}
        closeAlert={closeAlert}
        visible={visible}
      />
      <Row>
        <Col span={18}>
          <div className={styles.employerName}>
            <OverflowPopover
              popoverContent={`${employer?.name}`}
              maxWidth="40%"
            >
              {`${employer?.name} `}
            </OverflowPopover>
            Holiday & Timeoff Plans
          </div>
        </Col>
        <Col span={6} className={styles.addPlanButtonWrapper}>
          {((!inProgress && total !== 0) ||
            (!inProgress && total == 0 && !isEmpty(searchText))) &&
            permittedAddPlanButton}
        </Col>
      </Row>
      <Row>
        <Col>
          <PlanListPlanYearDropDown onPlanYearSelect={setSelectedPlanYear} />
        </Col>
      </Row>
      {!inProgress && content && total === 0 && isEmpty(searchText) ? (
        <EmptyAddPlan
          title="You have no Holidays and Time Off plans set up!"
          description="Get started by adding your first plan"
          icon={<IconHolidayTimeOff />}
          onActionClick={() => {
            setFullScreenModalView(true);
            setVisible(false);
          }}
          selectButton={
            <div className={styles.emptySelectButtonWrapper}>
              <PlanSelectButton
                overlay={getHolidaysAndTimeOffPlanTypes()}
                selectLabel="+ Add Holidays & Time Off Plan"
                className={styles.emptyDropdownButton}
              />
            </div>
          }
        />
      ) : (
        <>
          <div className={styles.searchBar}>
            <SearchBar placeholder="Search Plans" onChange={handleSearch} />
          </div>
          <PlansList
            planYear={planYear}
            planList={workLifePlanList}
            inProgress={inProgress}
            limit={limit}
            paginationConfig={paginationConfig}
            getData={getData}
            goToOverviewPage={goToOverviewPage}
            benefitCategory={BenefitCategory.HOLIDAYS_AND_TIME_OFF.value}
            onSaveClose={onClose}
          />
        </>
      )}

      <FullScreenModal
        visible={fullScreenModalView}
        onCancel={closePlanAddModal}
        footer={false}
        title={`${employer?.name} - Add ${benefitKind?.label} Plan`}
        className={styles.mediaScreen}
      >
        <div className={styles.addworkLifePlanWrapper}>
          <AddWorkLifePlan
            benefitKind={benefitKind}
            isCloseConfirmed={isCloseConfirmed}
            closePlanAddModal={closePlanAddModal}
            onClose={onClose}
            isPlanUpdateMode={false}
            isModalVisible={isModalVisible}
            ref={addPlanRef}
          />
        </div>
      </FullScreenModal>
      <ConfirmationDialog
        title="Are you sure you want to close?"
        confirmText="Yes - Close and do not save plan"
        cancelText="Cancel"
        closeModal={toggleVisibleConfirmation}
        onConfirm={onConfirm}
        visible={visibleConfirmation}
      >
        <p className={styles.warningConfirmation}>{exitWithoutSavingMsg}</p>
      </ConfirmationDialog>
    </ContentContainer>
  );
};

export default HolidaysTimeOffPlanList;
