import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Dropdown, Menu } from 'antd';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import Button from 'components/buttons/Button/Button';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import {
  FORMAT_VALIDATE,
  SIZE_VALIDATE,
  TOTAL_UPLOAD_SIZE,
} from 'util/commonUtil';
import { getFileNameEllipsis } from 'util/stringUtil';
import { getFileSizeInMB } from 'util/fileUtil';
import iconWarning from 'assets/images/icon-warning.svg';

import styles from 'components/PopulatableInputFileUpload/populatableInputFileUpload.module.less';

type InputFileUploadProps = {
  uploadIconLabel: string;
  allowedFileTypes?: string[];
  showConfirmationRemove?: boolean;
  maxFileSizeMB?: number;
  totalUploadSizeMB?: number;
  onValidateFails?: (
    validateSetting: string,
    validateCondition?: string
  ) => void;
  getFileList?: (fileList: any) => void;
  isPopulatableDataExist?: boolean;
  populatableData?: any[];
  populatedDownload?: (fileName: string, s3Key: string) => void;
  triggerDelete: (trigger: boolean) => void;
  isDropdownDisabled?: boolean;
  isAllFilesAllowed?: boolean;
};

interface PopulatebleFileList extends File {
  isPopulated?: boolean;
  downloadUrl?: string;
}

const PopulatableInputFileUpload = forwardRef(
  (
    {
      uploadIconLabel,
      allowedFileTypes = [],
      maxFileSizeMB = 100,
      showConfirmationRemove = false,
      totalUploadSizeMB = 100,
      onValidateFails,
      getFileList,
      isPopulatableDataExist = false,
      populatableData,
      populatedDownload,
      triggerDelete,
      isDropdownDisabled,
      isAllFilesAllowed,
    }: InputFileUploadProps,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [fileList, setFileList] = useState<PopulatebleFileList[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
      if (populatableData) {
        const files: PopulatebleFileList[] = populatableData.map((item) => {
          // Create an empty Blob
          const blob = new Blob([], { type: 'application/octet-stream' });
          // Set the Blob's name property to the fileName
          (blob as any).name = item.fileName;
          const data = blob as PopulatebleFileList;
          data.isPopulated = true;
          data.downloadUrl = item.referenceId;
          return data;
        });
        setFileList(files);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPopulatableDataExist]);

    const viewBtnLabel = (
      <>
        Attachments ({fileList.length}) <DownOutlined />
      </>
    );

    useEffect(() => {
      getFileList && getFileList(fileList);
      // eslint-disable-next-line
    }, [fileList]);

    useImperativeHandle(ref, () => ({
      reset() {
        setFileList([]);
      },
    }));

    const getDuplicateFileListAndCount = (fileName: string) => {
      const duplicateFile = fileList.find((uploadedFile) => {
        return uploadedFile.name == fileName;
      });
      const fileNames = fileList.map((x) => x.name);

      const duplicateMap: Map<
        string,
        { duplicateCount: number; totalCount: number; duplicates: string[] }
      > = new Map();

      fileNames.forEach((fileName) => {
        const match = fileName.match(/^(.+?)(\(\d+\))?(\s*)(\.\w+)?$/);

        if (match) {
          const baseFileName = match[1] + match[3];
          const fileType = match[4] || '';
          const duplicateCount = match[2] ? Number(match[2].slice(1, -1)) : 0;

          if (duplicateMap.has(baseFileName + fileType)) {
            duplicateMap.get(baseFileName + fileType)!.duplicateCount +=
              duplicateCount;
            duplicateMap.get(baseFileName + fileType)!.totalCount++;
            duplicateMap
              .get(baseFileName + fileType)!
              .duplicates.push(fileName);
          } else {
            duplicateMap.set(baseFileName + fileType, {
              duplicateCount,
              totalCount: 1,
              duplicates: [fileName],
            });
          }
        }
      });

      if (duplicateFile) {
        const originalFullFile = `${duplicateFile.name}`;
        const duplicateDataObject = duplicateMap.get(originalFullFile);
        const updatedCount = duplicateDataObject?.totalCount;
        const highestNumbers: (number | null)[] =
          duplicateDataObject?.duplicates.map(extractHighestNumber) ?? [];

        const filteredNumbers: number[] = highestNumbers.filter(
          (num) => num !== null
        ) as number[];

        const highestValue: number | null =
          filteredNumbers.length > 0 ? Math.max(...filteredNumbers) : null;
        const regexPattern = new RegExp(
          `${fileName.replace(/\s*\(\d+\)/g, '')}`,
          'i'
        );

        return {
          count:
            highestValue === updatedCount ? highestValue + 1 : updatedCount,
          fileName: regexPattern,
        };
      } else {
        return null;
      }
    };

    const extractHighestNumber = (input: string): number | null => {
      const regex = /\((\d+)\)(?=[^.]*\.\w+$)/g;
      const numbers: number[] = [];
      let match;

      while ((match = regex.exec(input)) !== null) {
        numbers.push(Number(match[1]));
      }

      if (numbers.length > 0) {
        return Math.max(...numbers);
      }

      return null;
    };

    const beforeUpload = (event: any) => {
      const file: any = event?.target?.files[0];

      if (!file) {
        return;
      }

      const isAllowedType: boolean =
        allowedFileTypes.includes(file?.type) || (isAllFilesAllowed ?? false);
      const isAllowedFileSize: boolean =
        getFileSizeInMB(file?.size) < maxFileSizeMB;

      const isAllowedTotalFileSize = () => {
        const uploadedFiles = [...fileList, file];
        const totalUploadedFileSize = uploadedFiles?.reduce(
          (a, { size }) => a + getFileSizeInMB(Number(size)),
          0
        );
        const isTotalUploadSizeValid =
          totalUploadedFileSize <= totalUploadSizeMB;

        return !isTotalUploadSizeValid && uploadedFiles?.length > 1;
      };

      if (isAllowedTotalFileSize()) {
        onValidateFails &&
          onValidateFails(TOTAL_UPLOAD_SIZE, String(totalUploadSizeMB));
        event.target.value = '';
        return;
      }
      if (!isAllowedType) {
        onValidateFails &&
          onValidateFails(
            FORMAT_VALIDATE,
            allowedFileTypes?.map((item) => item.split('/')[1]).join(',')
          );
        event.target.value = '';
        return;
      }
      if (!isAllowedFileSize) {
        onValidateFails &&
          onValidateFails(SIZE_VALIDATE, String(maxFileSizeMB));
        event.target.value = '';
        return;
      }

      if (isAllowedType && isAllowedFileSize && !isAllowedTotalFileSize()) {
        const filteredfile = getDuplicateFileListAndCount(file.name);
        if (filteredfile) {
          const duplicateName = file?.name?.split('.');
          const extension = duplicateName.pop();
          const fileNameWithoutExtension = duplicateName.join('.');
          const updatedName =
            fileNameWithoutExtension + `(${filteredfile.count}).${extension}`;

          const updatedFile = new File([file], updatedName);
          setFileList([...fileList, updatedFile]);
        } else {
          setFileList([...fileList, file]);
        }
      }
      return (event.target.value = '');
    };

    const downloadFileObj = async (data: any) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      const url = window.URL.createObjectURL(data);
      a.href = url;
      a.download = data.name;
      a.click();
      window.URL.revokeObjectURL(url);
    };

    const handelFileRemove = (index: number) => {
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      triggerDelete(true);
      setFileList(newFileList);
      handleClose();
    };

    const handleClose = () => {
      setShowDropdown(false);
    };

    const renderDropdown = (
      <Menu className={styles.scrollableMenu}>
        {fileList?.map((item, index) => (
          <Menu.Item key={index}>
            <div className={styles.menuItemContainer}>
              <div
                className={styles.menuItemLabel}
                onClick={() => {
                  if (item.isPopulated && populatedDownload) {
                    populatedDownload(String(item?.downloadUrl), item.name);
                  } else {
                    downloadFileObj(item);
                  }
                }}
                title={item?.name}
              >
                {getFileNameEllipsis({
                  fileName: String(item?.name),
                  limit: 25,
                })}
              </div>
              {showConfirmationRemove ? (
                <ConfirmationModal
                  title={
                    <div className={styles.cancelUploadBody}>
                      Are you sure you want to remove this file?
                    </div>
                  }
                  icon={
                    <img
                      src={iconWarning}
                      alt="warning-icon"
                      className={styles.iconWarning}
                    />
                  }
                  confirmModalTrigger={
                    <div className={styles.menuItemIcon}>
                      <CloseOutlined className={styles.closeIcon} />
                    </div>
                  }
                  onConfirm={() => handelFileRemove(index)}
                  okText="Yes, remove file"
                  placement="topRight"
                />
              ) : (
                <div
                  className={styles.menuItemIcon}
                  onClick={() => handelFileRemove(index)}
                >
                  <CloseOutlined className={styles.closeIcon} />
                </div>
              )}
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <div className={styles.fileUploadMainContainer}>
        <div>
          <LinkButton
            disabled={isDropdownDisabled}
            type="link"
            onClick={() => inputRef?.current?.click()}
          >
            {uploadIconLabel}
          </LinkButton>
          <input
            type="file"
            accept={allowedFileTypes.join(',')}
            onChange={beforeUpload}
            ref={inputRef}
            hidden
          />
        </div>
        <div className={styles.viewUploadedContainer}>
          <Dropdown
            overlay={renderDropdown}
            trigger={['click']}
            overlayClassName="fileOverlay"
            visible={showDropdown}
            disabled={fileList.length === 0 || isDropdownDisabled}
            onVisibleChange={() => setShowDropdown(!showDropdown)}
            placement="bottomRight"
          >
            <Button label={viewBtnLabel} block />
          </Dropdown>
        </div>
      </div>
    );
  }
);

PopulatableInputFileUpload.displayName = 'InputFileUpload';

export default PopulatableInputFileUpload;
