import { FC } from 'react';
import planYearLogo from 'assets/images/navigation/PlanYearLogo.svg';

import styles from './authHeading.module.less';

type Props = {
  header?: string;
};

const AuthHeading: FC<Props> = (props) => {
  const { header } = props;

  return (
    <div className={styles.wrapper}>
      <img src={planYearLogo} alt="lumity logo" className={styles.lumityLogo} />
      {header ? <h1>{header}</h1> : <br />}
    </div>
  );
};

export default AuthHeading;
