import React, {
  useRef,
  useState,
  useEffect,
  MutableRefObject,
  useMemo,
} from 'react';
import { Row, Spin } from 'antd';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Button from 'components/buttons/Button/Button';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import {
  clear,
  validateFile,
  extractSBCFile,
  redirectToOps,
  cancel,
  setStatus,
  fetchFailedPlan,
  validateFailed,
} from 'modules/plans/slices/aiSbcUploaderSlice';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import { getPDFViewURL } from 'modules/plans/services/AiSBCUploaderService';
import {
  AI_FILE_TYPE,
  AI_SBC_ERROR_MESSAGES,
  BENEFIT_SUMMARY_CANCELED_MESSAGE,
  PANEL_HEADINGS_SHORT,
  PlanApproach,
  SBC_MEDICAL_CANCELED_MESSAGE,
  TooltipHeadings,
  SBC_ADDITIONAL_PROCESSING_WARNING_MESSAGE,
  AI_SBC_SUPPORT_APPLICABLE_ERROR_KEYS,
} from 'modules/plans/constants';
import { ReviewType } from 'modules/plans/enums/ReviewType';
import { buildCommaSeparatedString } from 'util/stringUtil';
import { BasicPlanType } from 'modules/plans/types/types';
import { ReactComponent as IconAI } from 'assets/images/Rfp-ai-selected.svg';
import { ReactComponent as IconPDF } from 'assets/images/icon-pdf-upload.svg';
import { ReactComponent as IconTime } from 'assets/images/icon-time.svg';
import { BenefitCategory } from 'constants/commonConstants';
import PDFViewer from 'components/PDFViewer/PDFViewer';
import {
  MEDICAL_PLAN_UPLOAD_WARNING,
  DENTAL_PLAN_UPLOAD_WARNING,
  VISION_PLAN_UPLOAD_WARNING,
  OTHER_PLAN_UPLOAD_WARNING,
} from 'modules/renewals/constants/renewalsConstants';
import { TDocumentExtractionSource } from 'modules/plans/components/AddPlanModal/AddPlanModal';
import ForeverLoadingBar from './ForeverLoadingBar/ForeverLoadingBar';

import styles from './aiSBCUploader.module.less';

export type AiSBCUploaderProps = {
  benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE';
  populatedSections: readonly (keyof typeof TooltipHeadings)[];
  employerId: string;
  brokerId: string;
  onUploadSuccess?: (
    plan?: any,
    benefitCodes?: any,
    docExtractionSource?: TDocumentExtractionSource
  ) => any;
  onUploadStart?: (file: File) => any;
  onCancel?: Function;
  isUploadCancelled?: boolean;
  setIsIntialized: Function;
  setPlanApproach: Function;
  subType: BasicPlanType | undefined;
  addPlanRef: MutableRefObject<any>;
  setUploadedFileName?: (string: string) => void;
  handleModalClose: ({
    status,
    sendFailedToOps,
  }: {
    status?: ProcessStatus;
    sendFailedToOps?: boolean;
  }) => void;
  docExtractionSource?: TDocumentExtractionSource;
};

const AiSBCUploader = ({
  benefit,
  populatedSections,
  employerId,
  brokerId,
  onUploadStart,
  onCancel,
  setIsIntialized,
  setPlanApproach,
  subType = undefined,
  addPlanRef,
  setUploadedFileName,
  onUploadSuccess,
  handleModalClose,
  docExtractionSource = undefined,
}: AiSBCUploaderProps) => {
  const dispatch = useAppDispatch();
  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo
  );
  const {
    jobId,
    status,
    reviewType,
    errorCode: uploadErrorCode = '',
    currentPage,
  } = useAppSelector((state) => state.plan.aiSbc);
  const hideOptionsOnDocumentExtractionSource = isEmpty(
    docExtractionSource?.planId
  );

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isError, setIsError] = useState(false);
  const [errorCode, setErrorCode] = useState<string>('default');
  const pdfViewerRef = useRef<any>(null);

  const extractionComplete = [
    ProcessStatus.SUCCESS,
    ProcessStatus.REVIEWED,
    ProcessStatus.SAVED,
  ].includes(status);

  useEffect(() => {
    pdfViewerRef?.current?.jumpToPage(currentPage);
  }, [currentPage]);

  /** Runs when an error occurs during upload */
  useEffect(() => {
    if (Object.keys(AI_SBC_ERROR_MESSAGES).includes(uploadErrorCode)) {
      dispatch(clear());
      setIsError(true);
      setErrorCode(uploadErrorCode);
    } else if (uploadErrorCode) {
      dispatch(clear());
      setIsError(true);
      setErrorCode('default');
    }
    // eslint-disable-next-line
  }, [uploadErrorCode]);

  const handleUploadClick = () => {
    if (isOpsAdmin) {
      return;
    }
    setIsError(false);
    dispatch(setStatus(ProcessStatus.MANUAL));
    setIsIntialized(false);
    setPlanApproach(PlanApproach.NOT_SELECTED);
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
    if (addPlanRef.current !== null) {
      addPlanRef?.current?.reset?.();
    }
  };

  const handleOpsReview = async () => {
    if (jobId) {
      await dispatch(
        extractSBCFile(
          jobId,
          benefit,
          benefit === BenefitCategory.MEDICAL.value
            ? AI_FILE_TYPE.SBC
            : AI_FILE_TYPE.BENEFIT_SUMMARY
        )
      );
      handleModalClose({ status: ProcessStatus.PROCESSING });
    }
  };

  const redirectToOpsOnFailedStatus = async () => {
    if (jobId) {
      await dispatch(
        redirectToOps(
          jobId,
          benefit,
          () => {
            handleModalClose({
              status: ProcessStatus.PROCESSING,
              sendFailedToOps: true,
            });
          },
          () => {
            handleModalClose({
              status: ProcessStatus.PROCESSING,
              sendFailedToOps: true,
            });
          }
        )
      );
    }
  };

  const handleFailedPlanUpload = async () => {
    dispatch(
      fetchFailedPlan(employerId, benefit, onUploadSuccess, handleModalClose)
    );
  };

  const handleUploadStart = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (!file) return;
    if ('pdf' !== file?.name?.toLowerCase()?.split('.')?.pop()) {
      setIsError(true);
      dispatch(validateFailed('incorrect.format'));
      setErrorCode('incorrect.format');
      return;
    }

    if (file.size / (1024 * 1024) > 100) {
      setIsError(true);
      dispatch(validateFailed('too.large'));
      setErrorCode('too.large');
      return;
    }
    const data = new FormData();
    data.append('file', file);
    dispatch(
      validateFile(
        file,
        employerId!,
        brokerId!,
        benefit,
        benefit === BenefitCategory.MEDICAL.value
          ? AI_FILE_TYPE.SBC
          : AI_FILE_TYPE.BENEFIT_SUMMARY,
        subType,
        docExtractionSource
      )
    );
    onUploadStart?.(file);
    setUploadedFileName?.(file?.name);
  };

  const getPopulatedSectionsText = (): string => {
    return (
      buildCommaSeparatedString(
        populatedSections.map((str) => PANEL_HEADINGS_SHORT[str]!)
      ) ?? ''
    );
  };

  const UploadCancelled = () => (
    <div className={styles.uploadCancelledContainer}>
      <div>
        {benefit === BenefitCategory.MEDICAL.value
          ? SBC_MEDICAL_CANCELED_MESSAGE
          : BENEFIT_SUMMARY_CANCELED_MESSAGE}
        {
          <text
            className={styles.clickableText}
            onClick={() => handleUploadClick()}
          >
            {benefit === BenefitCategory.MEDICAL.value
              ? `upload an SBC/Plan Summary File to `
              : `upload a Benefit Summary File to`}
          </text>
        }
        {` autofill ${getPopulatedSectionsText()} info.`}
      </div>
    </div>
  );

  const extractingPlan = useMemo(
    () => (
      <>
        <Row justify="center">
          {status === ProcessStatus.VALIDATING ? (
            <p>Examining File...</p>
          ) : (
            <p>Extracting plan details...</p>
          )}
        </Row>
        <ForeverLoadingBar />
        <Row justify="center">
          {status === ProcessStatus.VALIDATING ? (
            <i>File is being examined. This process may take a few minutes.</i>
          ) : (
            <i>File is now uploading. This process may take a few minutes.</i>
          )}
        </Row>
      </>
    ),
    [status]
  );

  const OpsView = () => (
    <>
      <Row justify="center">
        <IconTime />
      </Row>
      <Row justify="center">{SBC_ADDITIONAL_PROCESSING_WARNING_MESSAGE}</Row>
      <p></p>
      <Row justify="center">
        <Button
          type="primary"
          label={'Process File (up to 24 hour waiting period)'}
          onClick={() => {
            handleOpsReview();
          }}
          className={styles.chooseFileButton}
          loading={status === ProcessStatus.PROCESSING}
        />
      </Row>
      {hideOptionsOnDocumentExtractionSource && (
        <Row justify="center">
          <LinkButton
            disabled={status === ProcessStatus.PROCESSING}
            containerClassName={styles.dontUploadButton}
            onClick={() => {
              dispatch(cancel());
              onCancel?.();
            }}
          >
            Skip and Enter Plan Details Manually
          </LinkButton>
        </Row>
      )}
    </>
  );

  /**
   * This view is shown for files using the self-review flow. This view is only show if the
   * file has encountered some error requiring manual review. When confirmed through this view, the
   * file is sent to the Ops team for manual review. Otherwise they will be direct to the standard
   * flow where all details must be entered manually.
   * @return {JSX.Element} JSX Element
   */
  // eslint-disable-next-line
  const SelfReviewAdditionProcessingView = () => (
    <>
      <Row justify="center">
        <IconTime />
      </Row>
      <Row>
        <p className={styles.selfReviewFailText}>
          {`This file requires additional processing and may take up to 1 business day to finish. We recommend that you choose to wait for additional processing for data accuracy. You'll be notified when the processing is complete. Alternatively, you may opt to proceed and enter the data manually without undergoing additional file processing.`}
        </p>
      </Row>
      <Row justify="center">
        <Button
          type="primary"
          label={'Opt for Additional Processing'}
          onClick={() => {
            redirectToOpsOnFailedStatus();
          }}
          className={styles.chooseFileButton}
          loading={status === ProcessStatus.PROCESSING}
        />
      </Row>
      {hideOptionsOnDocumentExtractionSource && (
        <Row justify="center">
          <LinkButton
            disabled={status === ProcessStatus.PROCESSING}
            containerClassName={styles.dontUploadButton}
            onClick={() => handleFailedPlanUpload()}
          >
            Decline and enter manually without additional file processing
          </LinkButton>
        </Row>
      )}
    </>
  );

  const getBenefitMessage = (benefit: string): React.ReactElement => {
    switch (benefit) {
      case BenefitCategory.MEDICAL.value:
        return (
          <>
            {MEDICAL_PLAN_UPLOAD_WARNING.mainMessage}
            <br />
            <div className={styles.subMessageArea}>
              {MEDICAL_PLAN_UPLOAD_WARNING.subMessage}
            </div>
          </>
        );
      case BenefitCategory.DENTAL.value:
        return (
          <>
            {DENTAL_PLAN_UPLOAD_WARNING.mainMessage}
            <br />
            <div className={styles.subMessageArea}>
              {DENTAL_PLAN_UPLOAD_WARNING.subMessage}
            </div>
          </>
        );
      case BenefitCategory.VISION.value:
        return (
          <>
            {VISION_PLAN_UPLOAD_WARNING.mainMessage}
            <br />
            <div className={styles.subMessageArea}>
              {VISION_PLAN_UPLOAD_WARNING.subMessage}
            </div>
          </>
        );
      case BenefitCategory.LIFE.value:
        return (
          <>
            {OTHER_PLAN_UPLOAD_WARNING.mainMessage}
            <br />
            <div className={styles.subMessageArea}>
              {OTHER_PLAN_UPLOAD_WARNING.subMessage}
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  const UploadForm = () => (
    <>
      <Row justify="center" className={styles.benefitTypeMessage}>
        {getBenefitMessage(benefit)}
      </Row>
      {isError && (
        <Row justify="center" className={styles.errorText}>
          <p>
            {
              AI_SBC_ERROR_MESSAGES[
                errorCode as keyof typeof AI_SBC_ERROR_MESSAGES
              ]
            }
            {AI_SBC_SUPPORT_APPLICABLE_ERROR_KEYS.includes(errorCode) && (
              <>
                {' If the issue persists, please contact support at '}
                <a
                  href="mailto:support@planyear.com"
                  className={styles.supportLinkClass}
                >
                  support@planyear.com
                </a>
                {' for further assistance.'}
              </>
            )}
          </p>
        </Row>
      )}
      <Row justify="center">
        <Button
          type="primary"
          label={'Choose File'}
          onClick={() => {
            handleUploadClick();
          }}
          className={styles.chooseFileButton}
          disabled={isOpsAdmin}
        />
      </Row>
      {hideOptionsOnDocumentExtractionSource && (
        <Row justify="center">
          <LinkButton
            containerClassName={styles.dontUploadButton}
            onClick={() => {
              dispatch(cancel());
              onCancel?.();
            }}
          >
            Don&apos;t Upload
          </LinkButton>
        </Row>
      )}
    </>
  );

  const getFileUploadView = useMemo(() => {
    switch (status) {
      case ProcessStatus.CANCELLED:
        return <UploadCancelled />;
      case ProcessStatus.PROCESSING:
      case ProcessStatus.VALIDATING:
        return extractingPlan;
      case ProcessStatus.VALIDATED:
        return <OpsView />;
      case ProcessStatus.FAILED:
        if (!isOpsAdmin && reviewType === ReviewType.AUTOMATIC) {
          return <SelfReviewAdditionProcessingView />;
        }
        return <UploadForm />;
      default:
        return <UploadForm />;
    }
    // disable exhaustive-deps because the function is a memoized function
    // we only need to render this when the status changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, isOpsAdmin, reviewType, docExtractionSource]);

  if (!extractionComplete) {
    if (status === ProcessStatus.INITIALIZING) {
      return <Spin />;
    } else {
      return (
        <div className={styles.aiSbcUploaderContainer}>
          <IconPDF className={styles.topIcon} />
          <Row justify="center" className={styles.titleRow}>
            <IconAI />
            <h2>File Upload</h2>
          </Row>
          {getFileUploadView}
          <input
            type="file"
            name="file"
            onClick={(e) => (e.currentTarget.value = '')}
            onChange={handleUploadStart}
            ref={fileInputRef}
            hidden
          />
        </div>
      );
    }
  }

  return (
    <>
      <div className={styles.pdfViewContainer}>
        <PDFViewer
          ref={pdfViewerRef}
          fileType={
            benefit === BenefitCategory.MEDICAL.value
              ? 'SBC/Plan Summary Upload'
              : 'Benefit Summary Upload'
          }
          file={getPDFViewURL(jobId!)}
        />
      </div>
    </>
  );
};

export default AiSBCUploader;
