import { FC } from 'react';
import { Col, Row } from 'antd';
import { getTimezone } from 'countries-and-timezones';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

import BenefitsConsultant from 'model/benefitsConsultation/BenefitsConsultant';

import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';

import { buildInitials } from 'util/stringUtil';
import { TIME_ZONES } from 'modules/benefitsConsultation/constants/benefitsConsultation';
import { invitedDateFormat } from 'constants/dateFormat';

import styles from './viewBasicInfo.module.less';

type ViewBasicInfoProps = {
  consultant: BenefitsConsultant;
};

type DetailRowProps = {
  fieldName: string;
  value: string;
  showConfDetails?: boolean;
};

const DetailRow: FC<DetailRowProps> = (props: DetailRowProps) => {
  const { fieldName, value, showConfDetails = false } = props;
  return (
    <Row>
      <Col span={10}>
        <span className={styles.fieldName}>{fieldName}</span>
      </Col>
      <Col span={14}>
        {showConfDetails && <div className={styles.confDetails}>{value}</div>}
        {!showConfDetails && (
          <OverflowPopover popoverContent={value} maxWidth="256px">
            <span className={styles.fieldValue}>{value}</span>
          </OverflowPopover>
        )}
      </Col>
    </Row>
  );
};

const ViewBasicInfo: FC<ViewBasicInfoProps> = (props: ViewBasicInfoProps) => {
  const { consultant } = props;
  const {
    fullName,
    email,
    avatarUrl,
    status,
    timezone = '',
    conferenceDetails = '',
  } = consultant;

  const getTimezoneAbbreviation = () => {
    let abbreviationText = '';
    if (timezone) {
      const zone = TIME_ZONES.find(
        (timezoneConst) => timezoneConst.id === timezone
      );
      if (zone) {
        const timezoneObj = getTimezone(zone.id);
        const timezoneText = zone.name;
        abbreviationText = timezoneObj
          ? `(GMT${timezoneObj.dstOffsetStr}) ${timezoneText}`
          : `(GMT ${timezoneText}`;
      }
    }
    return abbreviationText;
  };

  const firstName = fullName.split(' ')[0] || '';
  const lastName = fullName.split(' ')[1] || '';
  return (
    <div className={styles.basicInfoWrapper}>
      <div className={styles.avatarWrapper}>
        <ProfileAvatar
          content={buildInitials(firstName, lastName)}
          src={avatarUrl}
          className={styles.avatar}
        />
      </div>

      <DetailRow fieldName="Name" value={fullName} />
      <DetailRow fieldName="Email" value={email} />
      <DetailRow
        fieldName="Time Zone"
        value={
          !isEmpty(timezone)
            ? getTimezoneAbbreviation()
            : 'Pacific Standard Time'
        }
      />
      <DetailRow
        fieldName="Calendar Status"
        value={
          status?.calendarSyncStatus
            ? 'Synced'
            : status?.invitedDate
            ? `Invited ${
                dayjs(status.invitedDate)?.format(invitedDateFormat) || '-'
              }`
            : 'Not Synced'
        }
      />
      <DetailRow
        fieldName="Conference Details"
        value={conferenceDetails}
        showConfDetails
      />
    </div>
  );
};

export default ViewBasicInfo;
