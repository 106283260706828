import { Result } from 'antd';
import React, { Component, ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | undefined;
}

class StandardErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
      error: undefined,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      hasError: true,
      error: error,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.warn('Error caught!');
    console.error(error);
    console.warn(errorInfo);

    // Record the error in an APM tool...
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return <Result status="warning" title="Something went wrong!" />;
    } else {
      return this.props.children;
    }
  }
}

export default StandardErrorBoundary;
