import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  dateFormat,
  invitedDateFormat,
  invitedDateFormatWithMonthName,
  usDataFormat,
} from 'constants/dateFormat';

dayjs.extend(localizedFormat);

export const getCurrentUnixTime = () => {
  return Math.floor(new Date().getTime() / 1000);
};

export const getInvitedTime = (time: string) => {
  return dayjs(time).format(invitedDateFormat);
};

export const getInvitedTimeWithMonthName = (time: string) => {
  return dayjs(time).format(invitedDateFormatWithMonthName);
};

export const formatLocalized = (date: string | undefined | Date) => {
  if (date) {
    return dayjs(date).format('ll');
  } else {
    return '';
  }
};

export const convertStringToDate = (date: string | undefined) => {
  if (date) {
    return dayjs(date, dateFormat);
  } else {
    return '';
  }
};

export const formatDateWithoutTime = (date: string | undefined | Date) => {
  if (date) {
    return dayjs(date).format(dateFormat);
  } else {
    return '';
  }
};

export const formatDateToUSFormat = (date: string | undefined | Date) => {
  if (date) {
    return dayjs(date).format(usDataFormat);
  }
};

export const formatLocalizedWithTime = (date: string | undefined | Date) => {
  if (date) {
    return dayjs(date).format('lll');
  } else {
    return '';
  }
};

export const formatDate = (date: string | undefined | Date, format: string) => {
  return date ? dayjs(date).format(format) : '';
};

export const formatOffsetDateToLocalized = (
  date: string | undefined | Date
): string => {
  return dayjs(date).format('MMM D, YYYY h:mm A');
};
