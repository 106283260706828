import { FC, MutableRefObject, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import MedicalPlan from 'model/plans/MedicalPlan';
import Services from 'modules/plans/components/Services/Services';
import {
  getPlanServicesList,
  updateMedicalPlan,
} from 'modules/plans/slices/medicalPlanSlice';
import { BenefitCategory } from 'constants/commonConstants';
import { useNavContext } from 'hooks/useNavContext';

import AddCustomServiceModal from 'modules/plans/components/AddCustomServiceModal/AddCustomServiceModal';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import PanelSection from 'modules/plans/enums/PanelSection';
import iconAdd from 'assets/images/icon-add.svg';
import PlanServiceObject from 'model/PlanService';

import styles from './services.module.less';

type ServiceDBGWrapperProps = {
  type: string;
  wrappedRef?: MutableRefObject<any>;
  medicalPlan: MedicalPlan;
};

const ServiceDBGWrapper: FC<ServiceDBGWrapperProps> = (
  props: ServiceDBGWrapperProps
) => {
  const { type, medicalPlan, wrappedRef } = props;
  const { employerId } = useNavContext();
  const dispatch = useAppDispatch();
  const [isAddCustomServiceShow, setAddCustomServiceShow] =
    useState<boolean>(false);
  const plan = useAppSelector((state) => state.plan.plans);
  const { displayedServices: _displayedServices } = plan;
  const displayedServices = _displayedServices as PlanServiceObject[];

  useEffect(() => {
    dispatch(updateMedicalPlan(medicalPlan));
    dispatch(
      getPlanServicesList(BenefitCategory.MEDICAL.value, employerId || '')
    );
  }, [dispatch, medicalPlan, employerId]);

  const onChange = (medicalPlan: MedicalPlan) => {
    dispatch(updateMedicalPlan(medicalPlan));
  };

  return (
    <>
      <div className={styles.serviceDBGWrapper}>
        <Services
          formType={type}
          onChange={onChange}
          isDBGView={true}
          isTouched={false}
          ref={wrappedRef}
        />
        <AddCustomServiceModal
          visible={isAddCustomServiceShow}
          displayedServices={displayedServices}
          onClose={() => setAddCustomServiceShow(false)}
          benefitKind={BenefitCategory.MEDICAL.value}
          planTypeName={BenefitCategory.MEDICAL.label}
          hsaCompatible={medicalPlan?.hsaCompatible}
          addedCustomServices={medicalPlan?.customServices}
        />
      </div>
      {PanelSection.SERVICES === type && (
        <div className={styles.addNewServiceBtn}>
          <LinkButton
            onClick={() => {
              setAddCustomServiceShow(true);
            }}
          >
            <img src={iconAdd} alt="add-icon" className={styles.iconStyle} />
            Add Service
          </LinkButton>
        </div>
      )}
    </>
  );
};

export default ServiceDBGWrapper;
