import { FC, forwardRef, useImperativeHandle, useState } from 'react';
import { Col, Row, Form, Input, Select } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { FormInstance, RuleObject } from 'antd/lib/form';

import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import InputForm from 'components/InputForm/InputForm';
import AlertMessage from 'components/Alert/AlertMessage';

import PlanYear from 'model/PlanYear';
import { EMPTY_MESSAGE } from 'constants/commonConstants';
import styles from './addEditBenefitClass.module.less';

const { Option } = Select;

type AddEditBenefitClassProps = {
  planYearsList: PlanYear[];
  selectedPlanYear: PlanYear;
  employerId: string | null;
  onSave: (benefitClass: string) => void;
  loading: boolean;
  selectedBenefitClass: string;
  form: FormInstance;
  ref: React.Ref<any>;
};

const AddEditBenefitClass: FC<AddEditBenefitClassProps> = forwardRef(
  (props: AddEditBenefitClassProps, ref) => {
    const {
      planYearsList,
      selectedPlanYear,
      onSave,
      loading,
      selectedBenefitClass,
      form,
    } = props;

    const [alertVisible, setAlertVisible] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');

    if (selectedBenefitClass) {
      if (!form.getFieldValue('benefitClass')) {
        form.setFieldsValue({
          benefitClass: selectedBenefitClass,
        });
      }
    }

    if (selectedPlanYear) {
      form.setFieldsValue({
        planYear: selectedPlanYear.id,
      });
    }

    const clearAlert = () => {
      setAlertVisible(false);
      setAlertMessage('');
    };

    useImperativeHandle(
      ref,
      () => ({
        clearAllAlerts() {
          clearAlert();
        },
      }),
      []
    );

    const validateForm = async () => {
      try {
        await form.validateFields();
        return true;
      } catch (errorInfo) {}
    };

    const handleSave = async () => {
      const formValid = await validateForm();
      if (formValid) {
        onSave(form.getFieldValue('benefitClass'));
      }
    };

    const isBenefitGroupValid = () => {
      const currentBenefitClass = form.getFieldValue('benefitClass') || '';
      const benefitClassTaken = selectedPlanYear.benefitGroups.filter(
        (benClass) =>
          benClass.toLowerCase() === currentBenefitClass.trim().toLowerCase()
      );
      const benArray = selectedPlanYear.benefitGroups.map((benClass) =>
        benClass.toLowerCase()
      );

      if (!isEmpty(benefitClassTaken)) {
        if (benArray.includes(currentBenefitClass.toLowerCase().trim())) {
          if (
            selectedBenefitClass.toLowerCase().trim() ===
            currentBenefitClass.toLowerCase().trim()
          ) {
            return true;
          } else if (
            selectedBenefitClass.toLowerCase().trim() !==
            currentBenefitClass.toLowerCase().trim()
          ) {
            setAlertMessage('A benefit class with this name already exists.');
          }
        }
      }
      if (currentBenefitClass.includes('.')) {
        setAlertMessage(
          'Periods (.) are not allowed. Please update the name and try again.'
        );
      } else {
        return true;
      }
    };

    const validateBenefitClass = (rule: RuleObject, value: string) => {
      if (value) {
        const isBenefitClassValid = isBenefitGroupValid();
        if (!isBenefitClassValid) {
          setAlertVisible(true);
          return Promise.reject(new Error(''));
        } else if (isEmpty(value.trim())) {
          setAlertVisible(true);
          setAlertMessage('Please specify a valid benefit group name.');
          return Promise.reject(new Error(''));
        } else {
          return Promise.resolve();
        }
      }
      setAlertVisible(true);
      setAlertMessage('Please specify a valid benefit group name.');
      return Promise.reject(new Error(''));
    };

    return (
      <div className={styles.addEditBenefitClassWrapper}>
        {alertVisible && (
          <AlertMessage
            type="error"
            message={alertMessage}
            closeAlert={() => setAlertVisible(false)}
          />
        )}
        <InputForm
          form={form}
          layout="vertical"
          requiredMark="optional"
          initialValues={{
            remember: true,
          }}
        >
          <Row gutter={60}>
            <Col span={12}>
              <Form.Item
                name="benefitClass"
                label="Benefit Class"
                rules={[
                  {
                    required: true,
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                  },
                  {
                    validator: validateBenefitClass,
                    validateTrigger: 'onSubmit',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="planYear"
                label="Plan Year"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select value={selectedPlanYear.id} size="large" disabled>
                  {planYearsList.map((value, index) => (
                    <Option value={value.id} key={index}>
                      {value.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </InputForm>

        <div className={styles.submitBtn}>
          <SubmitButton
            key="submit"
            type="primary"
            onClick={handleSave}
            loading={loading}
            htmlType="submit"
          >
            Save
          </SubmitButton>
        </div>
      </div>
    );
  }
);
AddEditBenefitClass.displayName = 'AddEditBenefitClass';
export default AddEditBenefitClass;
