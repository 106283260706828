import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as BenefitConsultationService from 'modules/benefitsConsultation/services/BenefitConsultationService';
import Consultant from 'model/benefitsConsultation/Consultant';
import ConsultationLevel from 'modules/benefitsConsultation/enums/ConsultationLevel';
import ConsultantType from 'modules/benefitsConsultation/enums/ConsultantType';
import PaginationConfig from 'model/PaginationConfig';
import BenefitsConsultant from 'model/benefitsConsultation/BenefitsConsultant';
import MetaData from 'model/MetaData';

export interface BrokerLevelBCState {
  inProgress: boolean;
  error: any;
  requestType: string;
  activeBrokerAdminsList: Consultant[];
  externalBrokerConsultantsList: Consultant[];
  pendingInviteConsultantsList: BenefitsConsultant[];
  brokerConsultants: { content: Array<BenefitsConsultant>; metadata: MetaData };
}

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  activeBrokerAdminsList: [],
  externalBrokerConsultantsList: [],
  pendingInviteConsultantsList: [],
  brokerConsultants: { content: [], metadata: {} },
} as BrokerLevelBCState;

const brokerLevelSlice = createSlice({
  name: 'brokerLevelBC',
  initialState,
  reducers: {
    getConsultantsSummaryStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
      state.error = null;
      state.activeBrokerAdminsList = [];
      state.externalBrokerConsultantsList = [];
    },
    getConsultantsSummarySuccess(state, action: PayloadAction<Consultant[]>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.activeBrokerAdminsList = action.payload.filter(
        (admin) => admin.type === ConsultantType.BROKER
      );
      state.externalBrokerConsultantsList = action.payload.filter(
        (admin) => admin.type === ConsultantType.BROKER_EXTERNAL
      );
    },
    getConsultantsSummaryFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = action.payload;
      state.activeBrokerAdminsList = [];
    },
    addRemoveConsultantsStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
      state.pendingInviteConsultantsList = [];
      state.error = null;
    },
    addRemoveConsultantsSuccess(state, action: PayloadAction<any>) {
      const {
        consultantsList,
        externalConsultantsList,
        pendingInviteConsultantsList,
      } = action.payload;

      state.inProgress = false;
      state.requestType = action.type;
      state.error = null;
      state.activeBrokerAdminsList = consultantsList;
      state.pendingInviteConsultantsList = pendingInviteConsultantsList;
      state.externalBrokerConsultantsList = externalConsultantsList;
    },

    addRemoveConsultantsFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.pendingInviteConsultantsList = [];
      state.error = action.payload;
    },
    getBrokerConsultantsListInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    getBrokerConsultantsListCompleted: (
      state,
      action: PayloadAction<{
        content: Array<BenefitsConsultant>;
        metadata: {};
      }>
    ) => {
      state.inProgress = false;
      state.brokerConsultants = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getBrokerConsultantsListFailed: (state, action) => {
      state.inProgress = false;
      state.brokerConsultants = { content: [], metadata: {} };
      state.error = action.payload;
      state.requestType = action.type;
    },
    clearAddRemoveConsultantsFailed(state) {
      state.inProgress = false;
      state.requestType = '';
      state.pendingInviteConsultantsList = [];
      state.error = null;
    },
  },
});

export const {
  getConsultantsSummaryStarted,
  getConsultantsSummarySuccess,
  getConsultantsSummaryFailed,
  addRemoveConsultantsStarted,
  addRemoveConsultantsSuccess,
  addRemoveConsultantsFailed,
  getBrokerConsultantsListInProgress,
  getBrokerConsultantsListCompleted,
  getBrokerConsultantsListFailed,
  clearAddRemoveConsultantsFailed,
} = brokerLevelSlice.actions;

export default brokerLevelSlice.reducer;

export const getConsultantsSummary = (organizationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getConsultantsSummaryStarted());
    try {
      const response = await BenefitConsultationService.getConsultantsSummary(
        ConsultationLevel.BROKER,
        organizationId
      );
      dispatch(getConsultantsSummarySuccess(response.data));
    } catch (error) {
      dispatch(getConsultantsSummaryFailed(JSON.parse(JSON.stringify(error))));
    }
  };
};

export const addRemoveConsultants = (
  addedConsulants: Consultant[],
  consultantsList: Consultant[],
  newExternalConsultants: Consultant[],
  externalConsultantsList: Consultant[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(addRemoveConsultantsStarted());
    try {
      const pendingInviteConsultantsList =
        await BenefitConsultationService.addRemoveConsultants(
          ConsultationLevel.BROKER,
          addedConsulants.concat(newExternalConsultants)
        );
      const data = {
        consultantsList,
        externalConsultantsList,
        pendingInviteConsultantsList: pendingInviteConsultantsList.data,
      };
      dispatch(addRemoveConsultantsSuccess(data));
    } catch (error) {
      dispatch(addRemoveConsultantsFailed(JSON.parse(JSON.stringify(error))));
    }
  };
};

export const getBrokerConsultantsList = (
  { page, size, sort, query }: PaginationConfig,
  organizationId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getBrokerConsultantsListInProgress());
    try {
      const response = await BenefitConsultationService.getConsultantsList(
        page,
        size,
        sort,
        query,
        organizationId,
        ConsultationLevel.BROKER
      );
      const data = response.data;
      dispatch(getBrokerConsultantsListCompleted(data));
    } catch (error) {
      dispatch(
        getBrokerConsultantsListFailed(JSON.parse(JSON.stringify(error)))
      );
    }
  };
};
