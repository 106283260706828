import { LlmInfo } from './LLMExtraction';

class CostSharing {
  copay: string;
  coinsurance: string;
  maxVisit: string;
  copayPriorToDeductible: string;
  info: string;
  benefitCovered: string;
  benefitExcludedFromOOPMax: string;
  copayFrequency: string;
  coinsuranceAfterDeductible: string;
  costSharingPolicy: string;
  llmExtractionInfo: LlmInfo | null | undefined;

  constructor() {
    this.copay = '';
    this.coinsurance = '';
    this.maxVisit = '';
    this.copayPriorToDeductible = '';
    this.info = '';
    this.benefitCovered = '';
    this.benefitExcludedFromOOPMax = '';
    this.copayFrequency = '';
    this.coinsuranceAfterDeductible = '';
    this.costSharingPolicy = '';
    this.llmExtractionInfo = null;
  }
}

export default CostSharing;
