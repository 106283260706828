import TagManager from 'react-gtm-module';
import { gtmID } from 'util/apiUtil';

const tagManagerArgs = {
  gtmId: gtmID,
};

export const initGTM = () => {
  TagManager.initialize(tagManagerArgs);
};

export const pushGTM = (eventObj: any) => {
  TagManager.dataLayer({
    dataLayer: eventObj,
  });
};
