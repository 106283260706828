import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Col, Row } from 'antd';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import EmptyAddPlan from 'modules/plans/components/EmptyAddPlan/EmptyAddPlan';
import AddAdditionalPerkPlan from 'modules/plans/additionalPerk/components/AddAdditionalPerkPlan/AddAdditionalPerkPlan';
import PlansList from 'modules/plans/components/PlansList/PlansList';
import SearchBar from 'components/SearchBar/SearchBar';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import { AdditionalPlan } from 'model/plans/AdditionalPlan';
import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
  resetPaginationConfig,
} from 'util/commonUtil';
import { ReactComponent as IconAdditionalPerks } from 'assets/images/benefitCategories/icon-additional-perks.svg';
import PlanYear from 'model/PlanYear';
import TablePagination from 'model/TablePagination';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import { getAdditionalPerkPlanList } from 'modules/plans/slices/additionalPerkPlanSlice';
import PlanListPlanYearDropDown from 'components/PlanListPlanYearDropDown/PlanListPlanYearDropDown';
import { AlertInfo } from 'components/Alert/AlertMessage';
import {
  BenefitCategory,
  EMPLOYER_MEMBER_RESTRICTED,
  planCreateSuccess,
} from 'constants/commonConstants';
import AlertMessageWrapper from 'modules/plans/components/AlertMessageWrapper/AlertMessageWrapper';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { usePrevious } from 'hooks/usePrevious';
import { usePermitByUserType } from 'hooks/usePermitByUserType';

import styles from './additionalPerkPlanList.module.less';

const AdditionalPerkPlanList: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { employerId, employer, brokerId } = useNavContext();

  const { additionalPerkPlanList, inProgress, error } = useAppSelector(
    (state) => state.plan.additionalPerk
  );

  const [fullScreenModalView, setFullScreenModalView] =
    useState<boolean>(false);
  const [planYear, setPlanYear] = useState<PlanYear | null>(null);
  const [isCloseConfirmed, setClosedConfirmed] = useState<boolean>(false);
  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText,
    },
    filters: {},
  });
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [visible, setVisible] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );
  const addPlanRef = useRef<any>();
  const { metadata } = additionalPerkPlanList || {};
  const total = metadata ? metadata.total : 0;
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  useEffect(() => {
    if (fullScreenModalView) {
      setClosedConfirmed(false);
    }
  }, [fullScreenModalView]);

  useEffect(() => {
    if (employerId) {
      dispatch(getPlanYears(employerId));
    }
  }, [dispatch, employerId]);

  const previousPlanYear = usePrevious(planYear?.id);

  useEffect(() => {
    if (employerId && planYear?.id) {
      if (previousPlanYear !== planYear?.id) {
        setPaginationConfig(resetPaginationConfig(paginationConfig));
      }
      dispatch(
        getAdditionalPerkPlanList(
          convertPaginationConfig(paginationConfig),
          employerId,
          planYear.id
        )
      );
    }
    // eslint-disable-next-line
  }, [dispatch, employerId, planYear?.id, paginationConfig]);

  useEffect(() => {
    if (fullScreenModalView) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [fullScreenModalView]);

  const openConfirmationModal = () => {
    setVisibleConfirmation(true);
  };

  const closeVisibleConfirmation = () => {
    setVisibleConfirmation(false);
    setClosedConfirmed(false);
  };

  const onConfirm = () => {
    setClosedConfirmed(true);
    setVisibleConfirmation(false);
    setFullScreenModalView(false);
    addPlanRef.current.reset();
  };

  const onClose = () => {
    setClosedConfirmed(true);
    setFullScreenModalView(false);
    if (employerId && planYear?.id) {
      if (!error) {
        setAlertMessage({
          type: 'success',
          message: planCreateSuccess,
        });
        setVisible(true);
      }
      dispatch(
        getAdditionalPerkPlanList(
          convertPaginationConfig(paginationConfig),
          employerId,
          planYear.id
        )
      );
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setSearchText(_searchText);
    const pageInfo = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText: _searchText },
    };
    debounceLoadData(pageInfo);
  };

  const getData = (filters: TablePagination) => {
    const paginationConfigData = {
      sorterInfo: {
        columnKey: 'name',
        field: 'name',
        order: 'ascend',
      },
      paginationIndex: filters.paginationIndex,
      filterInfo: {
        limit: filters.filterInfo.limit,
        offset: filters.filterInfo.offset,
        searchText: searchText,
      },
      filters: filters.filters,
    } as TablePagination;
    setPaginationConfig(paginationConfigData);
  };

  const goToOverviewPage = (additionalPerkPlan: AdditionalPlan) => {
    navigate(
      `/brokers/${brokerId}/employers/${employerId}/additional-perks/${additionalPerkPlan.id}/overview`
    );
  };

  const setSelectedPlanYear = (planYear: PlanYear | null | undefined) => {
    if (employerId && planYear) {
      setPlanYear(planYear);
    }
  };

  const closeAlert = () => setVisible(false);

  const openModal = () => {
    setFullScreenModalView(true);
    setVisible(false);
  };

  const addPlanButton = (
    <PageActionButton
      type="primary"
      onClick={() => openModal()}
      className={styles.actionButton}
    >
      Add Additional Perks Plan
    </PageActionButton>
  );

  const permittedAddPlanButton = usePermitByUserType(
    addPlanButton,
    EMPLOYER_MEMBER_RESTRICTED
  );

  return (
    <ContentContainer>
      <AlertMessageWrapper
        type={alertMessage.type}
        message={alertMessage.message}
        closeAlert={closeAlert}
        visible={visible}
      />
      <Row>
        <Col span={18}>
          <div className={styles.employerName}>
            <OverflowPopover
              popoverContent={`${employer?.name}`}
              maxWidth="50%"
            >
              {`${employer?.name} `}
            </OverflowPopover>
            Additional Perk Plans
          </div>
        </Col>
        <Col span={6} className={styles.addPlanButtonWrapper}>
          {((!inProgress && total !== 0) ||
            (!inProgress && total == 0 && !isEmpty(searchText))) &&
            permittedAddPlanButton}
        </Col>
      </Row>
      <Row>
        <Col>
          <PlanListPlanYearDropDown onPlanYearSelect={setSelectedPlanYear} />
        </Col>
      </Row>
      {!inProgress && total === 0 && isEmpty(searchText) ? (
        <EmptyAddPlan
          title="You have no Additional Perk plans set up!"
          description="Get started by adding your first plan"
          icon={<IconAdditionalPerks />}
          actionTitle="+ Add Additional Perks Plan"
          onActionClick={() => openModal()}
        />
      ) : (
        <>
          <div className={styles.searchBar}>
            <SearchBar placeholder="Search Plans" onChange={handleSearch} />
          </div>
          <PlansList
            planYear={planYear}
            planList={additionalPerkPlanList}
            inProgress={inProgress}
            limit={limit}
            paginationConfig={paginationConfig}
            getData={getData}
            goToOverviewPage={goToOverviewPage}
            benefitCategory={BenefitCategory.ADDITIONAL_PERK.value}
            onSaveClose={onClose}
          />
        </>
      )}
      <FullScreenModal
        visible={fullScreenModalView}
        onCancel={openConfirmationModal}
        footer={false}
        title={`${employer?.name} - Add Additional Perk Plan`}
        className={styles.mediaScreen}
      >
        <AddAdditionalPerkPlan
          isCloseConfirmed={isCloseConfirmed}
          closePlanAddModal={openConfirmationModal}
          onClose={onClose}
          isPlanUpdateMode={false}
          isModalVisible={isModalVisible}
          ref={addPlanRef}
        />
      </FullScreenModal>
      <ConfirmationDialog
        title="Are you sure you want to close?"
        confirmText="Yes - close and do not save"
        cancelText="Cancel"
        closeModal={closeVisibleConfirmation}
        onConfirm={onConfirm}
        visible={visibleConfirmation}
      >
        <p className={styles.warningConfirmation}>
          If you leave now, you will lose any unsaved data. Do you wish to
          continue?
        </p>
      </ConfirmationDialog>
    </ContentContainer>
  );
};

export default AdditionalPerkPlanList;
