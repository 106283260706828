import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Menu, Row } from 'antd';
import debounce from 'lodash/debounce';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import DataTable from 'components/DataTable/DataTable';
import { DataColumn } from 'components/DataTable/DataColumn';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import OutlinedText from 'components/OutlinedText/OutlinedText';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import { buildFullName, buildInitials } from 'util/stringUtil';

import BrokerAdmin from 'model/BrokerAdmin';
import Admin from 'model/admin/Admin';
import {
  AccountStatus,
  ACTIVE,
  CONSULTANT_EMAIL_WARNING_MSG_IF_ASSIGN_TO_EMP,
  hasMutualLocations,
} from 'modules/admins/constants/adminConstants';
import { getBrokerAdmins } from 'modules/admins/slices/brokerAdminSlice';
import {
  findAdminByAdminId,
  clearAdminFind,
  deleteAdmin,
  clearAdminDeletion,
  inviteAdmin,
  clearinviteAdmin,
  deactivateAdminInvite,
  clearDeactivateAdminInvite,
} from 'modules/admins/slices/adminSlice';
import {
  consultantValidateClear,
  getAdminConsultantValidation,
} from 'modules/admins/slices/adminConsultantValidateSlice';
import { getBrokerLocationList } from 'modules/brokers/slices/brokerLocationSlice';

import TablePagination from 'model/TablePagination';
import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
} from 'util/commonUtil';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import SearchBar from 'components/SearchBar/SearchBar';
import ListComponent from 'components/Helpers/ListComponent/ListComponent';
import CreateAdmin from 'modules/admins/pages/CreateAdmin/CreateAdmin';
import SendInviteTemplate from 'modules/admins/pages/SendInviteTemplate/SendInviteTemplate';
import {
  BROKER_ADMIN_TYPE_LABELS,
  IndividualSubTypes,
  IndividualType,
} from 'constants/commonConstants';
import { getInvitedTime } from 'util/dateUtil';
import BrokerAdminDetails from 'modules/admins/pages/BrokerAdminDetails/BrokerAdminDetails';
import AlertDialog from 'components/AlertDialog/AlertDialog';
import { BROKER_ADMIN_DELETE_FAILED_TEAM_MEMBER } from 'modules/admins/constants/adminErrorConstants';

import styles from './brokerAdminList.module.less';

const convertBrokerLocations = (
  locations: Map<string, string>,
  allLocations: boolean | undefined
): string => {
  if (allLocations) {
    return 'All';
  }
  return Object.values(locations).sort().join(', ');
};

const convertAdminsForTableRender = (brokerAdmins: BrokerAdmin[]): any[] => {
  return (
    brokerAdmins?.map((brokerAdmin) => ({
      key: brokerAdmin.inPlaceId,
      admin: brokerAdmin,
      location: convertBrokerLocations(
        brokerAdmin.locationMap || new Map(),
        brokerAdmin.allLocations
      ),
      email: brokerAdmin.email,
      accountStatus: brokerAdmin,
      individualSubType: brokerAdmin.individualSubType,
    })) || []
  );
};

type BrokerAdminListProps = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  setAddBrokerAdminModal?: (open: boolean) => void;
  addBrokerAdminModal?: boolean;
};

const BrokerAdminList: FC<BrokerAdminListProps> = (
  props: BrokerAdminListProps
) => {
  const {
    isModalOpen,
    setIsModalOpen,
    setAddBrokerAdminModal,
    addBrokerAdminModal,
  } = props;
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const [searchText, setSearchText] = useState<string>('');
  const [admin, setAdmin] = useState<Admin | null>(null);

  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'admin',
      field: 'admin',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText,
    },
    filters: {},
  });
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);
  const [visibleConfirmationDelete, setVisibleConfirmationDelete] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [visible, setVisible] = useState<boolean>(false);
  const [invitedEmail, setInvitedEmail] = useState<string>('');
  const [displayTemplate, setDisplayTemplate] = useState<boolean>(false);
  const [locationMap, setLocationMap] = useState(new Map<string, string>());
  const [selectedBrokerAdminId, setSelectedBrokerAdminId] =
    useState<string>('');
  const dispatch = useAppDispatch();
  const brokerAdmins = useAppSelector(
    (state) => state.admins.brokerAdmins.brokerAdmins
  );

  const brokerLocations = useAppSelector(
    (state) => state.brokers.brokerLocations.brokerLocations
  );

  const inProgress = useAppSelector(
    (state) => state.admins.brokerAdmins.inProgress
  );

  const adminStatus = useAppSelector((state) => state.admins.admins);

  const adminConsultantValidateState = useAppSelector(
    (state) => state.admins.adminConsultantValidate
  );

  const { content, metadata } = brokerAdmins || {};
  const { brokerId } = useNavContext();
  const { total } = metadata;
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  useEffect(() => {
    if (brokerId) {
      dispatch(
        getBrokerAdmins(convertPaginationConfig(paginationConfig), brokerId)
      );
    }
  }, [dispatch, paginationConfig, brokerId]);

  useEffect(() => {
    if (brokerId) {
      dispatch(getBrokerLocationList(brokerId));
    }
  }, [dispatch, brokerId]);

  useEffect(() => {
    if (
      !adminStatus.adminFindInProgress &&
      !isEmpty(adminStatus.adminObj) &&
      adminStatus.error === null
    ) {
      setAdmin(adminStatus.adminObj);
      dispatch(clearAdminFind());
      if (!isModalOpen && !visibleConfirmationDelete) {
        setIsModalOpen(true);
        setVisibleConfirmation(false);
      }
    }
  }, [
    dispatch,
    adminStatus?.adminFindInProgress,
    adminStatus?.adminObj,
    adminStatus?.error,
    isModalOpen,
    visibleConfirmationDelete,
    setIsModalOpen,
  ]);

  useEffect(() => {
    if (!adminStatus.deactivateInProgress && adminStatus.deactivateCompleted) {
      setVisible(true);
      setAdmin(null);
      setIsModalOpen(false);
      dispatch(clearDeactivateAdminInvite());
      if (brokerId) {
        dispatch(
          getBrokerAdmins(convertPaginationConfig(paginationConfig), brokerId)
        );
      }
      if (adminStatus.error === null) {
        setAlertMessage({
          type: 'success',
          message: `Invite has been deactivated for ${invitedEmail}.`,
        });
      } else {
        setAlertMessage({
          type: 'error',
          message: 'Invite deactivation failed.',
        });
      }
    }
  }, [
    dispatch,
    adminStatus.deactivateInProgress,
    adminStatus.deactivateCompleted,
    adminStatus.error,
    paginationConfig,
    invitedEmail,
    brokerId,
    setIsModalOpen,
  ]);

  useEffect(() => {
    if (!adminStatus.deleteInProgress && adminStatus.deleteCompleted) {
      setVisibleConfirmationDelete(false);
      setVisible(true);
      setAdmin(null);
      dispatch(clearAdminDeletion());
      if (brokerId) {
        dispatch(
          getBrokerAdmins(convertPaginationConfig(paginationConfig), brokerId)
        );
      }
      if (adminStatus.error === null) {
        setAlertMessage({
          type: 'success',
          message: 'Broker Admin has been removed.',
        });
      } else {
        if (
          adminStatus.error.data &&
          adminStatus.error.data.code &&
          adminStatus.error.data.code === BROKER_ADMIN_DELETE_FAILED_TEAM_MEMBER
        ) {
          setAlertMessage({
            type: 'error',
            message:
              'This Broker Admin is the only Team Member listed for ' +
              adminStatus.error.data.details.employer +
              ', please add another Team Member before deleting this Admin',
          });
        } else {
          setAlertMessage({
            type: 'error',
            message: 'Broker Admin delete failed.',
          });
        }
      }
    }
  }, [
    dispatch,
    adminStatus.deleteInProgress,
    adminStatus.deleteCompleted,
    adminStatus.error,
    brokerId,
    paginationConfig,
  ]);

  useEffect(() => {
    if (!adminStatus.inviteInProgress && adminStatus.inviteCompleted) {
      setVisible(true);
      setAdmin(null);
      setIsModalOpen(false);
      dispatch(clearinviteAdmin());
      dispatch(clearAdminFind());
      setDisplayTemplate(false);
      if (brokerId) {
        dispatch(
          getBrokerAdmins(convertPaginationConfig(paginationConfig), brokerId)
        );
      }
      if (adminStatus.error === null) {
        setAlertMessage({
          type: 'success',
          message: `Invite has been sent to ${invitedEmail}.`,
        });
      } else {
        setAlertMessage({
          type: 'error',
          message: 'Invite sent failed.',
        });
      }
    }
  }, [
    dispatch,
    adminStatus.inviteInProgress,
    adminStatus.inviteCompleted,
    adminStatus.error,
    brokerId,
    paginationConfig,
    invitedEmail,
    setIsModalOpen,
  ]);

  const getData = (filters: TablePagination) => {
    if (filters.sorterInfo) {
      const paginationConfigData = {
        sorterInfo: filters.sorterInfo,
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      };
      setPaginationConfig(paginationConfigData);
    } else {
      const paginationConfigData = {
        sorterInfo: {
          columnKey: 'admin',
          field: 'admin',
          order: 'ascend',
        },
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      } as TablePagination;
      setPaginationConfig(paginationConfigData);
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setSearchText(_searchText);
    const pageInfo = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText: _searchText },
    };
    debounceLoadData(pageInfo);
  };

  const closeBrokerModal = () => {
    if (displayTemplate) {
      setAdmin(null);
      if (brokerId) {
        dispatch(
          getBrokerAdmins(convertPaginationConfig(paginationConfig), brokerId)
        );
      }
      setIsModalOpen(false);
    } else {
      toggleConfirmationPopup();
    }
    setDisplayTemplate(false);
  };

  const confirmCloseModal = async () => {
    visibleConfirmation && (await toggleConfirmationPopup());
    setAdmin(null);
    if (brokerId) {
      dispatch(
        getBrokerAdmins(convertPaginationConfig(paginationConfig), brokerId)
      );
    }
    setIsModalOpen(false);
  };

  const toggleConfirmationPopup = async () => {
    setVisibleConfirmation(!visibleConfirmation);
  };

  const toggleAdminCreatedInviteSent = () => {
    setAlertMessage({
      type: 'success',
      message: 'Admin has been successfully added and an invite has been sent',
    });
    setVisible(true);
  };

  const toggleAdminCreatedInviteNotSent = () => {
    setAlertMessage({
      type: 'success',
      message: 'Admin has been successfully added',
    });
    setVisible(true);
  };

  const toggleAdminUpdated = () => {
    setAlertMessage({
      type: 'success',
      message: 'Admin has been successfully updated',
    });
    setVisible(true);
  };

  const toggleConfirmationDeletePopup = () => {
    dispatch(consultantValidateClear());
    setVisibleConfirmationDelete(!visibleConfirmationDelete);
  };

  const handleDelete = (admin: BrokerAdmin) => {
    closeAlert();

    dispatch(findAdminByAdminId(admin.id));
    dispatch(
      getAdminConsultantValidation({
        adminType: IndividualType.ORGANIZATION_ADMIN,
        employerId: admin.employerId,
        orgId: admin.organizationId,
        individualId: admin.id,
      })
    );
    setVisibleConfirmationDelete(!visibleConfirmationDelete);
  };

  const handleSendInvite = (admin: BrokerAdmin) => {
    closeAlert();
    dispatch(findAdminByAdminId(admin.id));
    if (admin.locationMap) {
      setLocationMap(admin.locationMap);
    }
    setDisplayTemplate(true);
  };

  const handleDeactivateInvite = (admin: BrokerAdmin) => {
    closeAlert();
    if (admin) {
      setInvitedEmail(admin.email);
      dispatch(deactivateAdminInvite(admin.id));
    }
  };

  const confirmDelete = () => {
    if (admin) {
      dispatch(
        deleteAdmin(
          admin.organizationId ? admin.organizationId : null,
          admin.id
        )
      );
    }
  };

  const sendInvite = () => {
    if (admin) {
      setInvitedEmail(admin.email);
      dispatch(inviteAdmin(IndividualType.ORGANIZATION_ADMIN, admin.id));
    }
  };

  const getBrokerAdminAccountStatus = (admin: BrokerAdmin): any => {
    if (admin.accountStatus === AccountStatus.SEND_INVITE) {
      return 'Not Invited';
    } else if (admin.accountStatus === AccountStatus.INVITED) {
      return (
        <div>
          Invited
          <span className={styles.sendInviteDate}>
            {getInvitedTime(admin.invitedTime)}
          </span>
          <div className={styles.resendInvite}>
            <LinkButton onClick={() => handleSendInvite(admin)}>
              Resend Invite
            </LinkButton>
          </div>
        </div>
      );
    } else if (admin.accountStatus === AccountStatus.ACCOUNT_ACTIVATED) {
      return ACTIVE;
    } else {
      return;
    }
  };

  const closeAlert = () => {
    dispatch(clearAdminDeletion());
    dispatch(clearinviteAdmin());
    dispatch(clearDeactivateAdminInvite());
    setVisible(false);
    setAlertMessage({
      type: undefined,
      message: '',
    });
    setInvitedEmail('');
  };

  const handleAdminDetails = (admin: BrokerAdmin) => {
    closeAlert();
    setSelectedBrokerAdminId(admin.id);
    setIsDetailsModalOpen(true);
  };

  const handleEmployerClick = (empId: any) => {
    window.open(
      `/brokers/${brokerId}/employers/${empId}/broker-consultation`,
      '_blank'
    );
  };

  const adminActionsMenu = (admin: BrokerAdmin) => {
    return (
      <Menu>
        <Menu.Item key="view" onClick={() => handleAdminDetails(admin)}>
          Admin Details
        </Menu.Item>
        {admin.accountStatus === AccountStatus.SEND_INVITE && (
          <Menu.Item key="send" onClick={() => handleSendInvite(admin)}>
            Send Invite
          </Menu.Item>
        )}
        {admin.accountStatus === AccountStatus.INVITED && (
          <Menu.Item key="send" onClick={() => handleDeactivateInvite(admin)}>
            Deactivate Invite
          </Menu.Item>
        )}
        {!admin.primary &&
          appBootInfo?.individualSubType !== IndividualSubTypes.BROKER_USER &&
          hasMutualLocations(
            appBootInfo?.locationIds,
            admin?.locations,
            appBootInfo?.individualSubType,
            admin?.individualSubType
          ) && (
            <Menu.Item key="delete" onClick={() => handleDelete(admin)}>
              Remove Admin
            </Menu.Item>
          )}
      </Menu>
    );
  };

  const brokerAdminTableColumns: DataColumn[] = [
    {
      title: 'BROKER ADMIN',
      dataIndex: 'admin',
      key: 'admin',
      width: '30%',
      sorter: (a: any, b: any) => 0, // this sort is done by backend.
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: 'ascend',
      render: (admin) => {
        return (
          <div className={styles.name}>
            <ProfileAvatar
              content={buildInitials(admin.firstName, admin.lastName)}
              src={admin.avatarUrl}
            />
            <span
              className={styles.nameColStyle}
              onClick={() => handleAdminDetails(admin)}
            >
              <OverflowPopover
                popoverContent={buildFullName(admin.firstName, admin.lastName)}
                maxWidth={admin.primary ? '100px' : '246px'}
              >
                {buildFullName(admin.firstName, admin.lastName)}
              </OverflowPopover>
              {admin.primary && <OutlinedText text="PRIMARY CONTACT" />}
              {admin?.individualType === IndividualType.OPS_ADMIN && (
                <OutlinedText
                  showCheck={false}
                  text="OPS ADMIN"
                  type="disabled"
                />
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: 'USER TYPE',
      dataIndex: 'individualSubType',
      key: 'individualSubType',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (individualSubType) => {
        return <>{BROKER_ADMIN_TYPE_LABELS[individualSubType]}</>;
      },
    },
    {
      title: 'LOCATION ACCESS',
      dataIndex: 'location',
      key: 'location',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: 'ascend',
      render: (colData) => <OverflowPopover>{colData}</OverflowPopover>,
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (coldata) => <OverflowPopover>{coldata}</OverflowPopover>,
    },
    {
      title: 'ACCOUNT STATUS',
      dataIndex: 'accountStatus',
      key: 'accountStatus',
      width: '15%',
      render: (colData) => getBrokerAdminAccountStatus(colData),
    },

    {
      title: 'ACTIONS',
      dataIndex: 'admin',
      key: 'admin',
      width: '15%',
      render: (admin) => <SelectDropdown overlay={adminActionsMenu(admin)} />,
      align: 'center',
    },
  ];

  return (
    <div className={styles.adminTableWrapper}>
      <div className={styles.alertWrapper}>
        {visible && (
          <AlertMessage
            type={alertMessage.type}
            message={alertMessage.message}
            closeAlert={closeAlert}
          />
        )}
      </div>
      <Row className={styles.searchBarWrapper}>
        <SearchBar placeholder="Search Admins" onChange={handleSearch} />
      </Row>
      <div className={styles.summaryTable}>
        <DataTable
          currentIndex={paginationConfig.paginationIndex}
          data={convertAdminsForTableRender(content)}
          columns={brokerAdminTableColumns}
          pagination={true}
          getData={getData}
          total={total}
          loading={inProgress}
          pageSize={limit}
        />
      </div>
      {!adminStatus.adminFindInProgress && (
        <>
          {admin?.id !== appBootInfo?.individualId &&
            visibleConfirmationDelete && (
              <ConfirmationDialog
                title="Remove Broker Admin"
                confirmText="Yes - Remove Broker Admin"
                cancelText="Cancel"
                closeModal={toggleConfirmationDeletePopup}
                onConfirm={confirmDelete}
                confirmLoading={adminConsultantValidateState.inProgress}
                visible={visibleConfirmationDelete}
              >
                <div className={styles.warningConfirmation}>
                  <p>
                    Are you sure you want to remove this broker admin? They will
                    <br />
                    no longer be able to access the system; this action cannot
                    be <br />
                    un-done.
                  </p>
                  {Array.isArray(
                    adminConsultantValidateState?.consultantValidateObj
                  ) &&
                  adminConsultantValidateState?.consultantValidateObj
                    ?.length !== 0 ? (
                    <>
                      <p>
                        This Broker Admin is also a Broker Consultant in the
                        system. {CONSULTANT_EMAIL_WARNING_MSG_IF_ASSIGN_TO_EMP}
                      </p>
                      <ListComponent
                        className={styles.empList}
                        dataSource={adminConsultantValidateState?.consultantValidateObj?.map(
                          (item: any) => ({
                            label: item?.name,
                            value: item?.id,
                          })
                        )}
                        onClickItem={(data: any) =>
                          handleEmployerClick(data?.value)
                        }
                      />
                    </>
                  ) : null}
                </div>
              </ConfirmationDialog>
            )}
          {admin?.id === appBootInfo?.individualId &&
            visibleConfirmationDelete && (
              <AlertDialog
                title="Unable to Remove"
                onCancel={toggleConfirmationDeletePopup}
                closeModal={toggleConfirmationDeletePopup}
                visible={visibleConfirmationDelete}
              >
                <p className={styles.warningConfirmation}>
                  You are not able to remove yourself from the system.
                </p>
              </AlertDialog>
            )}
        </>
      )}
      <FullScreenModal
        visible={isModalOpen}
        onCancel={closeBrokerModal}
        footer={false}
        title={!displayTemplate ? 'Add Broker Admin' : ''}
        className={styles.mediaScreen}
      >
        {!displayTemplate && (
          <CreateAdmin
            type={IndividualType.ORGANIZATION_ADMIN}
            confirmCloseModal={confirmCloseModal}
            visibleConfirmation={visibleConfirmation}
            toggleConfirmationPopup={toggleConfirmationPopup}
            adminCreatedInviteSent={toggleAdminCreatedInviteSent}
            adminCreatedInviteNotSent={toggleAdminCreatedInviteNotSent}
            adminUpdated={toggleAdminUpdated}
            brokerLocations={brokerLocations}
            addBrokerAdminModal={addBrokerAdminModal}
            setAddBrokerAdminModal={setAddBrokerAdminModal}
          />
        )}
        {displayTemplate && admin && (
          <SendInviteTemplate
            admin={admin}
            closeBrokerModal={closeBrokerModal}
            sendInvite={sendInvite}
            locationMap={locationMap}
          />
        )}
      </FullScreenModal>
      {isDetailsModalOpen && !displayTemplate && (
        <BrokerAdminDetails
          isModalOpen={isDetailsModalOpen}
          setIsModalOpen={setIsDetailsModalOpen}
          brokerAdminId={selectedBrokerAdminId}
          confirmCloseModal={confirmCloseModal}
          brokerLocations={brokerLocations}
        />
      )}
    </div>
  );
};

export default BrokerAdminList;
