import React, { FC, useEffect, useState } from 'react';

import { Menu, Tag } from 'antd';
import { startCase, toLower } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { loginTypes } from 'constants/authConstants';
import DataTable from 'components/DataTable/DataTable';
import { DataColumn } from 'components/DataTable/DataColumn';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import CustomSpinner from 'components/Spinner/CustomSpinner';
import styles from 'modules/brokers/pages/RenewalInformation/renewalInfo.module.less';
import { useRenewalDashboardQuery } from 'modules/brokers/slices/dashboardRenewalSlice';
import { recentBillingDashboardConstants } from 'constants/benguideDashboardConstants';
import Employer from 'model/Employer';
import { clearEmployer } from 'layout/slices/layoutSlice';
import { BASIC_INFO_TAB } from 'modules/employers/constants/employerConstants';
import { getEmployer } from 'modules/employers/slices/employerSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  ALL_FEATURE_KEYS,
  IndividualSubTypes,
} from 'constants/commonConstants';
import { useNavContext } from 'hooks/useNavContext';
import EditBrokerTeamModal from 'modules/employers/components/EditBrokerTeamModal/EditBrokerTeamModal';
import NoRenewalData from './NoRenewalData/NoRenewalData';

/*
 * Generate the tag color based on the tag ref
 * @param val
 */
const generateTagColor = (val: string): string => {
  switch (val) {
    case recentBillingDashboardConstants.pastRenewal:
      return styles.pastRenewalTag;
    case recentBillingDashboardConstants.upComing:
      return styles.upcomingTag;
    case recentBillingDashboardConstants.oneMonth:
      return styles.withinMonthTag;
    default:
      return styles.withinMonthTag;
  }
};

type RenewalInfoProps = {
  issueLogEnabled: boolean;
};

const RenewalInfo: FC<RenewalInfoProps> = (props: RenewalInfoProps) => {
  const { issueLogEnabled } = props;
  const params = useParams();
  const navigate = useNavigate();
  const brokerId = params.brokerId;
  const dispatch = useAppDispatch();
  const { setEmployerId } = useNavContext();
  const [isTeamModalOpen, setIsTeamModalOpen] = useState<boolean>(false);
  const brokerEmployerPrefixUrl = `/brokers/${brokerId}/employers`;

  const employerObj = useAppSelector(
    (state) => state.employer.employer.employerObj
  );
  const [selectedEmployer, setSelectedEmployer] = useState<string>('');

  const {
    data,
    isLoading,
    isError,
    isSuccess,
    refetch: refetchRenewalData,
  } = useRenewalDashboardQuery({
    brokerId,
  });

  useEffect(() => {
    refetchRenewalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchRenewalData]);

  const goToEmployer = (_employerId: string) => {
    dispatch(clearEmployer());
    navigate(`${brokerEmployerPrefixUrl}/${_employerId}`);
  };

  const goToEmployerAdmins = (employerId: string, brokerId: any) => {
    setEmployerId(employerId, brokerId);
    navigate(`${brokerEmployerPrefixUrl}/${employerId}/info`);
  };

  const goToEmployerInfo = (employerId: string) => {
    navigate(`${brokerEmployerPrefixUrl}/${employerId}/info`, {
      state: { tab: BASIC_INFO_TAB },
    });
  };

  const hadleEditBrokerTeam = (employerId: string) => {
    dispatch(getEmployer(employerId));
    setSelectedEmployer(employerId);
    setIsTeamModalOpen(true);
  };

  const employerActions = (data: Employer) => {
    const { id } = data;
    const organizationId = brokerId;
    return (
      <Menu>
        <Menu.Item key="viewDetails" onClick={() => goToEmployer(id)}>
          Employer Dashboard
        </Menu.Item>
        <Menu.Item key="editBrokerTeam" onClick={() => hadleEditBrokerTeam(id)}>
          Edit Broker Team
        </Menu.Item>
        <Menu.Item
          key="editAdmins"
          onClick={() => goToEmployerAdmins(id, organizationId)}
        >
          Edit Employer Users
        </Menu.Item>
        <Menu.Item key="editInfo" onClick={() => goToEmployerInfo(id)}>
          Edit Employer Info and Access
        </Menu.Item>
      </Menu>
    );
  };

  /*
   * Navigate employer to employer dashboard
   * @param val employer id
   */
  const handleEmployerClick = (empId: string) => {
    navigate(`${brokerEmployerPrefixUrl}/${empId}`);
  };

  const dataColumns: DataColumn[] = [
    {
      title: 'EMPLOYER',
      dataIndex: 'name',
      key: 'name',
      width: '45%',
      render: (val: any, colData: any) => {
        return (
          <div>
            <span
              className={styles.EmployerName}
              onClick={() => handleEmployerClick(colData?.id)}
            >
              {colData?.name}
            </span>
            {colData?.renewalTagStatus ? (
              <Tag className={generateTagColor(colData?.renewalTagStatus)}>
                {startCase(toLower(colData?.renewalTagStatus))}
              </Tag>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
    {
      title: 'RENEWAL DATE',
      dataIndex: 'renewalDate',
      key: 'id',
      width: '30%',
      sorter: (a, b) => {
        return a.renewalDate.localeCompare(b.renewalDate);
      },
      render: (val: any, colData: any) => {
        return (
          <div
            className={
              colData?.isPastRenewal
                ? styles.RenewalDateHighlighted
                : styles.RenewalDate
            }
          >
            {colData?.renewalDate
              ? moment(colData?.renewalDate).format('ll')
              : ''}
          </div>
        );
      },
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'id',
      width: '15%',
      render: (val: any, colData: any) => {
        return (
          <div className={styles.dropdownWrapper}>
            <SelectDropdown
              className={styles.dropdownWrapper}
              overlay={employerActions(colData)}
            />
          </div>
        );
      },
    },
  ];

  const appBootInfo: any = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo
  );
  const isTechAdmin: boolean = appBootInfo?.type === loginTypes.platform;

  const dashboardTransferData = {
    isCameFromRenewalDashboard: true,
  };

  const handleRenewalViewMoreClick = () => {
    if (isTechAdmin) {
      navigate(`/employers`, {
        state: {
          loggedInUserBroker: brokerId,
          featureTools: ALL_FEATURE_KEYS.RENEWAL.value,
        },
      });
    } else {
      navigate(`/brokers/${params.brokerId}/employers`, {
        state: {
          dashboardTransferData,
          featureTools: ALL_FEATURE_KEYS.RENEWAL.value,
        },
      });
    }
  };

  return (
    <div className={styles.renewalInfo}>
      <h3
        className={`${styles.mainTitle} ${
          appBootInfo?.individualSubType === IndividualSubTypes.BROKER_USER &&
          !issueLogEnabled
            ? styles.userTypeRenewalInfo
            : ''
        }`}
      >
        Upcoming Renewals
      </h3>
      <p className={styles.mutedSubtitle}>
        Your Employers with upcoming renewals
      </p>

      {isSuccess && data?.renewals?.length ? (
        <>
          <div className={styles?.renewalInfoTable}>
            <DataTable
              data={data?.renewals}
              columns={dataColumns}
              pagination={false}
            />
          </div>

          <p
            className={styles.tableEndText}
            onClick={handleRenewalViewMoreClick}
          >
            View More
          </p>
        </>
      ) : (
        <></>
      )}
      {!isError ? (
        isLoading ? (
          <CustomSpinner />
        ) : isSuccess && !data?.renewals?.length ? (
          <NoRenewalData />
        ) : (
          <></>
        )
      ) : (
        <NoRenewalData />
      )}
      <EditBrokerTeamModal
        isModalOpen={isTeamModalOpen}
        setIsModalOpen={setIsTeamModalOpen}
        brokerName={employerObj ? employerObj.name : ''}
        selectedEmployer={selectedEmployer}
      />
    </div>
  );
};

export default RenewalInfo;
