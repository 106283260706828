import { MouseEventHandler } from 'react';

import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import reactHtmlParser from 'react-html-parser';
import { isEmpty } from 'lodash';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import IssueComment from 'model/IssueComment';
import { buildFullName, buildInitials } from 'util/stringUtil';
import iconattachment from 'assets/images/icon-attachment.svg';
import {
  ISSUE_COMMENT_TYPE_EMAIL,
  ISSUE_COMMENT_TYPE_INTERNAL,
} from 'modules/issueslog/constants/IssuesLogConstants';

import styles from './viewComment.module.less';

type Props = {
  issueComment: IssueComment;
  issueSubject?: string;
  downloadAttachment: Function;
  provisionedEmail: string;
  issueType: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const ViewComment = (props: Props) => {
  const {
    issueComment,
    issueSubject,
    downloadAttachment,
    provisionedEmail,
    onClick,
  } = props;

  const {
    createdByFirstName,
    createdByLastName,
    creatorAvatarUrl,
    description,
    createdTime,
    attachments,
    toList,
    ccList,
    commentType,
    fromEmail,
  } = issueComment;

  const getFormattedEmail = () => {
    if (!fromEmail) {
      return `${buildFullName(
        createdByFirstName,
        createdByLastName
      )} <${provisionedEmail}>`;
    }

    // For issues that created from a email
    if (isEmpty(createdByFirstName) || isEmpty(createdByLastName)) {
      return fromEmail;
    } else {
      return `${buildFullName(
        createdByFirstName || '',
        createdByLastName || ''
      )} <${fromEmail}>`;
    }
  };

  return (
    <div className={styles.commentDetailContainer}>
      <div className={styles.clickableDiv} onClick={onClick}>
        <Row>
          <Col xs={17} xxl={18}>
            {commentType === ISSUE_COMMENT_TYPE_INTERNAL ? (
              <>
                <ProfileAvatar
                  content={buildInitials(createdByFirstName, createdByLastName)}
                  src={creatorAvatarUrl}
                />
                <span className={styles.commentDetailHeader}>
                  {buildFullName(createdByFirstName, createdByLastName)}
                </span>
              </>
            ) : (
              <div className={styles.textWrapperEllipsis}>
                <span className={styles.emailsRecipientLabel}>From:</span>
                <span
                  className={`${styles.emailsList} ${styles.emailsCreatorName}`}
                >
                  {getFormattedEmail()}
                </span>
              </div>
            )}
          </Col>
          <Col xs={7} xxl={6}>
            <Row
              className={`${styles.createdTimeDiv} ${
                styles.commentDetailHeader
              } ${
                issueComment.commentType === ISSUE_COMMENT_TYPE_EMAIL
                  ? styles.emailCommentCreatedTime
                  : ''
              }`}
            >{`${dayjs(createdTime).format('MMMM D, YYYY')} at ${dayjs(
              createdTime
            ).format('h:mm A')}`}</Row>
          </Col>
        </Row>

        {issueComment.commentType === ISSUE_COMMENT_TYPE_EMAIL && (
          <>
            <Row className={styles.emailsRecipients}>
              <span className={styles.emailsRecipientLabel}>To:</span>
              <span className={styles.emailsList}>{toList.join(', ')}</span>
            </Row>
            {!isEmpty(ccList) && (
              <Row className={styles.emailsRecipients}>
                <span className={styles.emailsRecipientLabel}>Cc:</span>
                <span className={styles.emailsList}>{ccList.join(', ')}</span>
              </Row>
            )}
            {issueSubject && (
              <Row className={styles.emailsRecipients}>
                <span className={styles.emailsRecipientLabel}>Subject:</span>
                <span className={styles.emailsList}>{issueSubject}</span>
              </Row>
            )}
          </>
        )}
      </div>
      <Row className={`${styles.issueDescriptionContainer} richTextContent`}>
        <div className={styles.description}>{reactHtmlParser(description)}</div>
      </Row>
      {!isEmpty(attachments) && (
        <>
          <Row>
            <span
              className={`${styles.commentDetailHeader} ${styles.attachmentsDetailHeader}`}
            >
              Attachments ({attachments.length})
            </span>
          </Row>
          <Row>
            {attachments.map((attachment: any, index: number) => {
              return (
                <span
                  key={attachment.id}
                  className={`${styles.attachmentDiv} ${styles.attachmentLabel}`}
                  onClick={() => {
                    downloadAttachment(attachment);
                  }}
                >
                  <img src={iconattachment} alt="icon-attachment" />
                  <span className={styles.fileName}>{attachment.fileName}</span>
                </span>
              );
            })}
          </Row>
        </>
      )}
    </div>
  );
};

export default ViewComment;
