import { FC, MutableRefObject, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { VisionPlan } from 'model/plans/VisionPlan';
import Services from 'modules/plans/components/DVServices/Services';
import { getVisionPlanServicesList } from 'modules/plans/slices/visionPlanSlice';
import { BenefitCategory } from 'constants/commonConstants';
import { useNavContext } from 'hooks/useNavContext';
import AddCustomServiceModal from 'modules/plans/components/AddCustomServiceModal/AddCustomServiceModal';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import PanelSection from 'modules/plans/enums/PanelSection';
import iconAdd from 'assets/images/icon-add.svg';
import PlanServiceObject from 'model/PlanService';
import CustomPlan from 'model/plans/CustomPlan';

import styles from './services.module.less';

type ServiceUPEditWrapperProps = {
  type: string;
  wrappedRef?: MutableRefObject<any>;
  visionPlan: VisionPlan;
  setVisionPlan: Function;
};

const ServiceUPEditWrapper: FC<ServiceUPEditWrapperProps> = (
  props: ServiceUPEditWrapperProps
) => {
  const { type, visionPlan, setVisionPlan } = props;
  const { employerId } = useNavContext();
  const dispatch = useAppDispatch();
  const [isAddCustomServiceModalOpen, setIsAddCustomServiceModalOpen] =
    useState<boolean>(false);
  const plan = useAppSelector((state) => state.plan.visionPlan);
  const { displayedVisionServices: _displayedVisionServices } = plan;
  const displayedVisionServices =
    _displayedVisionServices as PlanServiceObject[];

  useEffect(() => {
    dispatch(
      getVisionPlanServicesList(BenefitCategory.VISION.value, employerId || '')
    );
  }, [dispatch, employerId]);

  const addCustomService = (customServiceCopy: CustomPlan) => {
    let { customServices = [] } = visionPlan;
    const existingServiceIndex = customServices.findIndex(
      (s) =>
        (customServiceCopy.benefitCode.code &&
          customServiceCopy.benefitCode.code === s.benefitCode.code) ||
        customServiceCopy.benefitCode.shortName === s.benefitCode.shortName
    );
    if (existingServiceIndex !== -1) {
      customServices[existingServiceIndex] = customServiceCopy;
    } else {
      customServices = [...customServices, customServiceCopy];
    }
    const copy = [...customServices];
    setVisionPlan({ ...visionPlan, customServices: copy });
  };

  return (
    <>
      <div className={styles.visionServiceDBGWrapper}>
        <Services
          plan={visionPlan}
          onChange={setVisionPlan}
          isDBGView={true}
          isTouched={false}
          isUpEdit={true}
          planType={BenefitCategory.VISION.value}
        />
        <AddCustomServiceModal
          planTypeName={BenefitCategory.VISION.label}
          benefitKind={BenefitCategory.VISION.value}
          visible={isAddCustomServiceModalOpen}
          displayedServices={displayedVisionServices}
          onClose={() => setIsAddCustomServiceModalOpen(false)}
          hsaCompatible={false}
          onPlanChange={addCustomService}
          addedCustomServices={visionPlan.customServices}
        />
      </div>
      {PanelSection.SERVICES === type && (
        <div className={styles.addNewServiceBtn}>
          <LinkButton
            onClick={() => {
              setIsAddCustomServiceModalOpen(true);
            }}
          >
            <img src={iconAdd} alt="add-icon" className={styles.iconStyle} />
            Add Service
          </LinkButton>
        </div>
      )}
    </>
  );
};

export default ServiceUPEditWrapper;
