import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clients: {},
};

const clientSlince = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    addClient: (state, { payload }) => {
      state.clients = { ...state.clients, [payload.id]: payload.clientId };
    },
  },
});

export const { addClient } = clientSlince.actions;

export default clientSlince.reducer;
