import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import ValidateTokenResponse from 'model/auth/ValidateTokenReponse';
import ErrorDetails from 'model/ErrorDetails';
import * as AuthService from 'modules/auth/services/AuthService';

interface CreatePwdState {
  inProgress: boolean;
  username: string;
  requestType: string;
  error: ErrorDetails | null;
}

const initialState = {
  inProgress: false,
  username: '',
  requestType: '',
  error: null,
} as CreatePwdState;

const createPwdSlice = createSlice({
  name: 'createPwd',
  initialState,
  reducers: {
    validateResetTokenStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
    },
    validateResetTokenSuccess(
      state,
      action: PayloadAction<ValidateTokenResponse>
    ) {
      state.inProgress = false;
      state.requestType = action.type;
      state.username = action.payload.username;
    },
    validateResetTokenFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = action.payload;
      state.username = '';
    },
    createPwdStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
      state.error = null;
    },
    createPwdSuccess(state, action: PayloadAction<string>) {
      state.inProgress = false;
      state.requestType = action.type;
    },
    createPwdFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = action.payload;
    },
  },
});

export const {
  validateResetTokenStarted,
  validateResetTokenSuccess,
  validateResetTokenFailed,
  createPwdStarted,
  createPwdSuccess,
  createPwdFailed,
} = createPwdSlice.actions;

export default createPwdSlice.reducer;

export const validatePwdResetToken =
  (token: string) => async (dispatch: Dispatch) => {
    dispatch(validateResetTokenStarted());
    try {
      const response = await AuthService.validateResetPwdToken(token);
      dispatch(validateResetTokenSuccess(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorDetails = {
          data: error.response?.data,
        };
        dispatch(validateResetTokenFailed(errorDetails));
      } else {
        console.error(error);
      }
    }
  };

export const createNewPassword =
  (token: string, password: string) => async (dispatch: Dispatch) => {
    dispatch(createPwdStarted());
    try {
      const response = await AuthService.resetPassword(token, password);
      dispatch(createPwdSuccess(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorDetails = {
          data: error.response?.data,
        } as ErrorDetails;
        dispatch(createPwdFailed(errorDetails));
      } else {
        console.error(error);
      }
    }
  };
