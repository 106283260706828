import { BenefitCategory } from 'constants/commonConstants';
import {
  benefitCode,
  PriorToDeductible,
  RxTiersDefault,
} from 'modules/plans/constants';
import BenefitCode from './BenefitCode';
import CostSharing from './CostSharing';
import CustomPlan from './CustomPlan';
import DeductiblesForIndividualWithFamily from './DeductiblesForIndividualWithFamily';
import RxForDeductiblesAndOopMax from './RxForDeductiblesAndOopMax';
import { Plan } from './Plan';
import RxCost from './RxCost';
import { AIUploaderFeedback } from './UserFeedback';
import { LlmExtractionInfo } from './LLMExtraction';

class MedicalPlan extends Plan {
  type: string;
  rates?: any;
  hsaCompatible: boolean;
  fsaCompatible?: boolean;
  deductibles: DeductiblesForIndividualWithFamily;
  outOfPocket: DeductiblesForIndividualWithFamily;
  rxDeductiblesAndOop: RxForDeductiblesAndOopMax;
  rxCosts?: RxCost;
  planNetworkName?: string;
  customServices?: CustomPlan[];
  mailOrderRxCosts?: RxCost;
  textractJobId: string | null;
  hasSameContributions?: boolean;
  individualStopLoss?: string;
  individualAdministrationFee?: string;
  aggregatedStopLoss?: string;
  aggregatedAdministrationFee?: string;
  thirdPartyAdministrationFee?: string;
  otherFees?: string;
  rxBinNumber?: string;
  rxPcnNumber?: string;
  rxGroupNumber?: string;
  hraCompatible?: boolean;
  hraPlanId?: string;
  planFeedback?: AIUploaderFeedback[];
  llmExtractionInfo?: LlmExtractionInfo | null | undefined;
  extractionFinalized?: boolean;

  constructor() {
    super();
    this.type = '';
    this.rates = {} as any;
    this.hsaCompatible = false;
    this.fsaCompatible = false;
    this.deductibles = new DeductiblesForIndividualWithFamily();
    this.outOfPocket = new DeductiblesForIndividualWithFamily();
    this.rxDeductiblesAndOop = new RxForDeductiblesAndOopMax();
    this.rxCosts = new RxCost();
    this.planNetworkName = '';
    this.mailOrderRxCosts = {} as RxCost;
    this.states = [];
    this.textractJobId = null;
    this.documentReferences = {};
    this.hasSameContributions = false;
    this.rxBinNumber = '';
    this.rxPcnNumber = '';
    this.rxGroupNumber = '';
    this.hraCompatible = false;
    this.hraPlanId = undefined;
    this.planFeedback = [];
    this.llmExtractionInfo = null;
    this.extractionFinalized = false;

    const rxCosts = new RxCost();
    Object.keys(RxTiersDefault).forEach((tier) => {
      rxCosts.inNetwork[tier] = {};
      rxCosts.outOfNetwork[tier] = {};
    });
    this.rxCosts = rxCosts;

    const benefitCodePreventiveCare = new BenefitCode();
    benefitCodePreventiveCare.benefitKind = BenefitCategory.MEDICAL.value;
    benefitCodePreventiveCare.code = benefitCode.MEDICAL_PREVENTIVE_CARE.code;
    benefitCodePreventiveCare.description =
      benefitCode.MEDICAL_PREVENTIVE_CARE.description;
    benefitCodePreventiveCare.shortName =
      benefitCode.MEDICAL_PREVENTIVE_CARE.shortName;

    const inNetwork = new CostSharing();
    inNetwork.benefitCovered = '';
    inNetwork.copayPriorToDeductible = PriorToDeductible.NO;

    const outOfNetwork = new CostSharing();
    outOfNetwork.benefitCovered = '';
    outOfNetwork.copayPriorToDeductible = PriorToDeductible.NO;

    const preventiveCare = new CustomPlan();
    preventiveCare.benefitCode = benefitCodePreventiveCare;
    preventiveCare.serviceValue.inNetwork = inNetwork;
    preventiveCare.serviceValue.outOfNetwork = outOfNetwork;
    preventiveCare.isDefault = true;
    this.customServices = [preventiveCare];

    this.individualStopLoss = '';
    this.individualAdministrationFee = '';
    this.aggregatedStopLoss = '';
    this.aggregatedAdministrationFee = '';
    this.thirdPartyAdministrationFee = '';
    this.otherFees = '';
  }
}

export default MedicalPlan;
