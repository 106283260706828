import { Row, Col, Form, Input } from 'antd';

import {
  ChangeEvent,
  ChangeEventHandler,
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
} from 'react';
import InputForm from 'components/InputForm/InputForm';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import LabelWithTooltip from 'components/LabelWithTooltip/LabelWithTooltip';
import EmployerContributionDetails from 'modules/plans/taxAdvantaged/components/EmployerContributionDetails/EmployerContributionDetails';
import { TaxAdvantagedAccountFormData } from 'modules/plans/taxAdvantaged/components/AddTaxAdvantagedPlan/AddTaxAdvantagedPlan';
import FrequencySelect from 'modules/plans/taxAdvantaged/components/FrequencySelect/FrequencySelect';
import {
  TaxAdvantagedAccountPlanType,
  TaxAdvantageFields,
} from 'modules/plans/constants';

import styles from './hsaPlanInformation.module.less';

const { TextArea } = Input;

type HSAPlanInformationProps = {
  isCancelButtonClickConfirm: boolean;
  setFormData: Function;
  formData: any;
  otherEmployerContributionsTooltipContent: ReactNode;
  onNumberInputChange: ChangeEventHandler<HTMLInputElement>;
  changeInputStyle: Function;
  isFrequencyError: boolean;
  isEditMode?: boolean;
  tierCount?: number;
};

const HSAPlanInformation = forwardRef((props: HSAPlanInformationProps, ref) => {
  const {
    isCancelButtonClickConfirm,
    setFormData,
    formData,
    otherEmployerContributionsTooltipContent,
    onNumberInputChange,
    changeInputStyle,
    isFrequencyError,
    isEditMode = false,
    tierCount = 0,
  } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (formData && isEditMode) {
      form.setFieldsValue({ ...formData });
    }
  }, [formData, form, isEditMode]);

  const onInputChange = (changedValue: any, allValues: any) => {
    setFormData((prevData: any) => ({ ...prevData, ...allValues }));
  };

  useEffect(() => {
    if (isCancelButtonClickConfirm) {
      form.resetFields();
    }
  }, [isCancelButtonClickConfirm, form]);

  useImperativeHandle(ref, () => ({
    resetForm() {
      form.resetFields();
    },
  }));

  const setContributionsDisable = () => {
    if (!form.getFieldValue('frequency')) {
      form.setFieldsValue({
        employerEEContribution: '',
      });
      form.setFieldsValue({
        employerESContribution: '',
      });
      form.setFieldsValue({
        employerECContribution: '',
      });
      form.setFieldsValue({
        employerEFContribution: '',
      });
      return true;
    }
    return false;
  };

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    form.setFieldsValue({ [name]: value });

    setFormData((prevData: TaxAdvantagedAccountFormData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleStartEndSpaces = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    form.setFieldsValue({
      [name]: value.trimStart().trimEnd(),
    });

    setFormData((prevData: TaxAdvantagedAccountFormData) => ({
      ...prevData,

      [name]: value.trimStart().trimEnd(),
    }));
  };

  const handleFormattedNumberInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  return (
    <>
      <InputForm
        form={form}
        onValuesChange={onInputChange}
        size={isEditMode ? 'small' : 'middle'}
      >
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="frequency"
              label="Employer Contribution Frequency"
              labelCol={{ span: 24 }}
            >
              <FrequencySelect
                planType={TaxAdvantagedAccountPlanType.HSA.value}
                isWarning={isFrequencyError}
              />
            </Form.Item>
          </Col>
          <Col span={isEditMode ? 12 : 6}>
            <Form.Item
              name="employerEEContribution"
              label="Employer EE Contribution"
              labelCol={{ span: 24 }}
            >
              <NumberFormatInput
                prefix="$"
                isBrokerScreen={true}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                name={TaxAdvantageFields.EMPLOYER_EE_CONTRIBUTION}
                className={
                  changeInputStyle(TaxAdvantageFields.EMPLOYER_EE_CONTRIBUTION)
                    ? styles.warningInput
                    : ''
                }
                onChange={onNumberInputChange}
                disabled={setContributionsDisable()}
                allowNegative={false}
              />
            </Form.Item>
          </Col>
          {!isEditMode && (
            <Col span={6}>
              <Form.Item
                name={
                  tierCount === 0
                    ? 'employerESContribution'
                    : 'employerEE1Contribution'
                }
                label={
                  tierCount === 0
                    ? 'Employer ES Contribution'
                    : 'Employer EE + 1 Contribution'
                }
                labelCol={{ span: 24 }}
              >
                <NumberFormatInput
                  prefix="$"
                  isBrokerScreen={true}
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  name={
                    tierCount === 0
                      ? TaxAdvantageFields.EMPLOYER_ES_CONTRIBUTION
                      : 'employerESContribution'
                  }
                  className={
                    changeInputStyle(
                      tierCount === 0
                        ? TaxAdvantageFields.EMPLOYER_ES_CONTRIBUTION
                        : 'employerESContribution'
                    )
                      ? styles.warningInput
                      : ''
                  }
                  onChange={onNumberInputChange}
                  disabled={setContributionsDisable()}
                  allowNegative={false}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={48}>
          <Col span={12} />
          <Col span={12}>
            <EmployerContributionDetails
              isEditMode={isEditMode}
              changeInputStyle={changeInputStyle}
              onNumberInputChange={onNumberInputChange}
              setContributionsDisable={setContributionsDisable()}
              tierCount={tierCount}
              planType={TaxAdvantagedAccountPlanType.HSA.value}
            />
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12} />
          <Col span={12}>
            <Form.Item
              name="otherContribution"
              labelCol={{ span: 24 }}
              label={
                <Row>
                  <LabelWithTooltip
                    content={otherEmployerContributionsTooltipContent}
                    mandatoryField={false}
                    fieldText={'Other Employer Contributions'}
                  />
                </Row>
              }
            >
              <TextArea
                onChange={handleTextAreaChange}
                name="otherContribution"
                value={formData.otherContribution}
                showCount
                maxLength={200}
                autoSize={{ minRows: 3, maxRows: 5 }}
                onBlur={handleStartEndSpaces}
              />
            </Form.Item>
            <div
              className={`${styles.characterCountText} ${
                !isEditMode
                  ? styles.characterCountTextOther
                  : styles.characterCountTextOtherEdit
              }`}
            >
              Character Limit
            </div>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="maxYearlyContributionIndividual"
              label="Max Yearly Contribution Individual"
              labelCol={{ span: 24 }}
            >
              <NumberFormatInput
                prefix="$"
                isBrokerScreen={true}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                allowNegative={false}
                onChange={handleFormattedNumberInput}
                value={formData.maxYearlyContributionIndividual}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Max Yearly Contribution Family"
              labelCol={{ span: 24 }}
              name="maxYearlyContributionFamily"
            >
              <NumberFormatInput
                prefix="$"
                isBrokerScreen={true}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                allowNegative={false}
                onChange={handleFormattedNumberInput}
                value={formData.maxYearlyContributionFamily}
              />
            </Form.Item>
          </Col>
        </Row>
      </InputForm>
    </>
  );
});

HSAPlanInformation.displayName = 'HSAPlanInformation';

export default HSAPlanInformation;
