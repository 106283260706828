import { ChangeEvent, FC, useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import isEmpty from 'lodash/isEmpty';

import iconInfo from 'assets/images/icon-info.svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import AlertMessage from 'components/Alert/AlertMessage';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import NextButton from 'components/buttons/NextButton/NextButton';
import CheckboxSelect from 'components/CheckboxSelect/CheckboxSelect';
import DataTable from 'components/DataTable/DataTable';
import InfoBanner from 'components/InfoBanner/InfoBanner';
import SearchBar from 'components/SearchBar/SearchBar';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import { DataColumn } from 'components/DataTable/DataColumn';
import MultiSelectDropdown from 'components/MultiSelectDropdown/MultiSelectDropdown';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { multiSelectTypes } from 'constants/commonConstants';
import {
  getBrokerAdminsForLocations,
  saveTeamMembers,
} from 'modules/employers/slices/employerCreateStepperSlice';
import BrokerAdmin from 'model/BrokerAdmin';
import { buildFullName, isContainsDataInArray } from 'util/stringUtil';
import { adminNameSorter } from 'util/commonUtil';

import styles from './assignTeamMembers.module.less';

const renderMember = (member: BrokerAdmin) => {
  const fullName = buildFullName(member.firstName, member.lastName);
  return (
    <OverflowPopover popoverContent={fullName}>
      <ProfileAvatar content={fullName} src={member.avatarUrl} />
      <span className={styles.memberName}>{fullName}</span>
    </OverflowPopover>
  );
};

const renderLocation = (admin: BrokerAdmin) => {
  const locations = admin.locationMap && Object.values(admin.locationMap);
  return <OverflowPopover>{locations?.sort().join(',')}</OverflowPopover>;
};

type TeamMemberProps = {
  nextStep: () => void;
};

const AssignTeamMembers: FC<TeamMemberProps> = (props: TeamMemberProps) => {
  const { nextStep } = props;
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [admins, setAdmins] = useState<BrokerAdmin[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedAdmins, setSelectedAdmins] = useState<{
    [id: string]: boolean;
  }>({});
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [isSelectAllClicked, setIsSelectAllCLicked] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const dispatch = useAppDispatch();
  const locationsForEmployer = useAppSelector(
    (state) => state.employer.employerCreateStepper.employer.locations
  );
  const organizationId = useAppSelector(
    (state) => state.employer.employerCreateStepper.employer.organizationId
  );
  const brokerAdmins = useAppSelector(
    (state) => state.employer.employerCreateStepper.brokerAdmins
  );

  const brokerLocations = useAppSelector(
    (state) => state.employer.employerCreateStepper.brokerLocationList
  );

  const draftEmployer = useAppSelector(
    (state) => state.employer.employerCreateStepper.employer
  );

  const brokerList = useAppSelector(
    (state) => state.employer.employerCreateStepper.brokerList
  );

  const selectedBroker = brokerList.find(
    (broker) => broker.id === organizationId
  );

  useEffect(() => {
    if (
      admins.length === Object.values(selectedAdmins).length &&
      !Object.values(selectedAdmins).includes(false)
    ) {
      setIsSelectAllCLicked(true);
    } else {
      setIsSelectAllCLicked(false);
    }
  }, [admins.length, selectedAdmins]);

  useEffect(() => {
    if (draftEmployer.teamMembers && !isEmpty(draftEmployer.teamMembers)) {
      const selectedMembers: { [id: string]: boolean } = {};
      draftEmployer.teamMembers.forEach((adminId) => {
        selectedMembers[adminId] = true;
      });
      setSelectedAdmins(selectedMembers);
    }
  }, [draftEmployer.teamMembers]);

  useEffect(() => {
    if (organizationId && !isEmpty(selectedLocations)) {
      const selectLocationIds = selectedLocations;
      dispatch(getBrokerAdminsForLocations(organizationId, selectLocationIds));
    } else if (
      organizationId &&
      isEmpty(selectedLocations) &&
      locationsForEmployer
    ) {
      dispatch(
        getBrokerAdminsForLocations(organizationId, locationsForEmployer)
      );
      updateSelectedLocations(locationsForEmployer, true);
    }
  }, [dispatch, organizationId, selectedLocations, locationsForEmployer]);

  useEffect(() => {
    if (brokerAdmins) {
      setAdmins(brokerAdmins);
    }
  }, [brokerAdmins]);

  useEffect(() => {
    if (searchText && !isEmpty(searchText)) {
      const searchWorkArray = searchText.split(' ');
      const filteredMembers =
        brokerAdmins &&
        brokerAdmins.filter((brokerAdmin) => {
          return (
            isContainsDataInArray(searchWorkArray, brokerAdmin.firstName) ||
            isContainsDataInArray(searchWorkArray, brokerAdmin.lastName)
          );
        });
      setAdmins(filteredMembers);
    } else {
      setAdmins(brokerAdmins);
    }
  }, [brokerAdmins, searchText]);

  useEffect(() => {
    if (organizationId && locationsForEmployer) {
      dispatch(
        getBrokerAdminsForLocations(organizationId, locationsForEmployer)
      );
      updateSelectedLocations(locationsForEmployer, true);
    }
  }, [dispatch, organizationId, locationsForEmployer]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = e.target;
    const searchText = value.trim()?.toLowerCase();
    setSearchText(searchText);
  };

  const updateSelectedLocations = (
    selectedOptions: string[],
    allOptionsSelected: boolean
  ) => {
    setAllSelected(allOptionsSelected);
    setSelectedLocations(selectedOptions);
  };

  const getOptionsFromLocations = (locations: string[] | undefined) => {
    if (locations && brokerLocations) {
      return brokerLocations.employerLocations
        .filter((location) => locations.includes(location.locationId))
        .map((location) => ({
          value: location.locationId,
          label: location.locationName,
        }));
    }
    return [];
  };

  const handleSelectAll = () => {
    setIsSelectAllCLicked(!isSelectAllClicked);
    const selection: { [id: string]: boolean } = {};
    admins.forEach((admin) => {
      selection[admin.id] = true;
    });
    !isSelectAllClicked
      ? setSelectedAdmins({ ...selectedAdmins, ...selection })
      : setSelectedAdmins({});
  };

  const columns: DataColumn[] = [
    {
      title: 'ADMINS',
      dataIndex: '',
      key: 'admin',
      width: '40%',
      sorter: (a, b) => adminNameSorter(a, b),
      defaultSortOrder: 'ascend',
      ellipsis: {
        showTitle: false,
      },
      render: (admin) => renderMember(admin),
    },
    {
      title: 'LOCATIONS',
      dataIndex: '',
      key: '',
      width: '30%',
      ellipsis: {
        showTitle: false,
      },
      sorter: (a, b) => {
        a =
          Object.values(a.locationMap).length > 0
            ? Object.values(a.locationMap).join(',')
            : '';
        b =
          Object.values(b.locationMap).length > 0
            ? Object.values(b.locationMap).join(',')
            : '';
        return a.localeCompare(b);
      },
      render: (location) => renderLocation(location),
    },
    {
      title: (
        <>
          <div className={isInvalid ? styles.error : ''}>ADD TO TEAM</div>
          <LinkButton
            className={`${styles.selectAllBtn} ${
              admins.length === Object.values(selectedAdmins).length &&
              !Object.values(selectedAdmins).includes(false)
                ? styles.deSelectAllBtnColor
                : styles.selectAllBtnColor
            }`}
            onClick={handleSelectAll}
          >
            {admins.length === Object.values(selectedAdmins).length &&
            !Object.values(selectedAdmins).includes(false)
              ? '(Deselect all)'
              : '(Select all)'}
          </LinkButton>
        </>
      ),
      dataIndex: '',
      key: 'actions',
      width: '20%',
      render: (colData) => addToTeam(colData),
      align: 'center',
      className: styles.addToTeam,
    },
  ];

  const isAdminSelected = (adminId: string): boolean => {
    if (selectedAdmins[adminId]) {
      return true;
    }
    return false;
  };

  const addToTeam = (admin: any) => {
    return (
      <CheckboxSelect
        invalid={isInvalid}
        onChange={(e) => handleAddToTeam(e, admin.id)}
        checked={isAdminSelected(admin.id)}
      />
    );
  };

  const handleAddToTeam = (event: CheckboxChangeEvent, id: string) => {
    setSelectedAdmins({ ...selectedAdmins, [id]: event.target.checked });
    if (isInvalid && event.target.checked) {
      setIsInvalid(false);
    }
  };

  const handleNextClick = () => {
    const assignedTeamMembers = !isEmpty(selectedAdmins)
      ? Object.keys(selectedAdmins).filter((admin) => {
          return selectedAdmins[admin];
        })
      : [];
    if (isEmpty(assignedTeamMembers)) {
      setIsInvalid(true);
      return;
    }
    dispatch(saveTeamMembers(assignedTeamMembers));

    nextStep();
  };

  return (
    <div className={styles.addMembersContainer}>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <InfoBanner
            title="Assigning Team Members for This Employer"
            description={`This is your internal ${
              selectedBroker ? selectedBroker.name : ''
            } team managing the new Employer. These team members will be the primary contacts for the new Employer.`}
            logo={<img src={iconInfo} alt="info-icon" />}
          />
        </Col>
      </Row>
      {isInvalid && (
        <AlertMessage
          type="error"
          message="At least one team member is required."
          closeAlert={() => setIsInvalid(false)}
        />
      )}
      <div className={styles.membersTableWrapper}>
        <Row className={styles.searchBar}>
          <Col xs={10} md={8}>
            <SearchBar
              placeholder={`Search ${
                selectedBroker ? selectedBroker.name : ''
              } Admins`}
              onChange={handleSearch}
            />
          </Col>
          <Col xs={8} md={6}>
            <div className={styles.locationFilter}>
              <MultiSelectDropdown
                mode="vertical"
                type={multiSelectTypes.FILTER}
                options={getOptionsFromLocations(locationsForEmployer)}
                allOption="Select All "
                deSelectOption="Unselect All"
                updateSelected={updateSelectedLocations}
                selected={selectedLocations}
                showArrow={false}
                allOptionsSelected={allSelected}
                allSelectedPanelLabel="All Locations"
              />
            </div>
          </Col>
        </Row>
        <div className={styles.teamMemberTable}>
          <DataTable data={admins} columns={columns} />
        </div>
      </div>
      <NextButton nextStep={handleNextClick} />
    </div>
  );
};
export default AssignTeamMembers;
