import { FC, useEffect, useState } from 'react';
import { Button, Divider, Row } from 'antd';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  useDeleteOfferDocumentsMutation,
  useGetUploadFileReferenceMutation,
} from 'modules/renewals/slices/renewalsSlice';
import { Validations } from 'modules/renewals/types/renewalsTypes';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import TextButton from 'components/buttons/TextButton/TextButton';
import AlertMessage from 'components/Alert/AlertMessage';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';

import {
  BASIC_ADD,
  BASIC_LIFE,
  BASIC_LIFE_AND_ADD,
  DENTAL_OFFER,
  LIFE,
  MDV_PLANS,
  NON_MDV_ENROLLMENT,
  PLAN_TYPES,
  PROPOSAL_EXISTS_INFO_TEXT,
  TIME_PERIOD_VALIDATION,
  TOTAL_ELIGIBLE_EMPLOYEES,
  VOLUNTARY_BENEFIT,
} from 'modules/renewals/constants/renewalsConstants';
import {
  ENROLLMENTS,
  HSA_COMPATIBLE,
  NEW_OFFER_PLAN,
} from 'modules/renewals/constants/planTableConstants';
import { validateNonMDVObj } from 'modules/renewals/utils/renewalsUtils';
import {
  nameExists,
  validate,
  validateObj,
  validatePlanName,
  validatePlanNameLength,
} from 'modules/renewals/utils/addPlanDetailsModalUtil';
import {
  currencyFormatterWithAnyNumberDecimalPlaces,
  daysPostFix,
  percentagePostfix,
  weeksPostFix,
} from 'util/commonUtil';
import OfferDocType from 'model/OfferDocType';
import PremiumType from 'modules/renewals/enums/PremiumType';
import { buildEnrollments } from 'modules/renewals/utils/addPlanDetailUtil';
import { Plan } from 'modules/renewals/types/planTypes';
import SplitContainer from 'modules/renewals/components/AddPlanDetailsModal/layout/SplitContainer';
import QuoteProcessStatus from 'modules/renewals/enums/QuoteUploadStatus';
import { ReviewType } from 'modules/renewals/enums/ReviewType';

import AddPlanForm from 'modules/renewals/components/AddPlanDetailsModal/AddPlanForm/AddPlanForm';
import PdfViewer from 'modules/renewals/components/AddPlanDetailsModal/PdfViewer/PdfViewer';
import { getPDFViewURL } from 'modules/plans/services/AiSBCUploaderService';
import styles from './addPlanDetailsModal.module.less';

type AddPlanDetailsModalProps = {
  visible: boolean;
  onClose: Function;
  data: any;
  dataIndex?: number;
  benefitType: string;
  setUpdateData?: Function;
  isAddPlan?: boolean;
  addIndexList?: Function;
  indexList: number[];
  showValidationsOnLoad?: boolean;
  updatedData?: any[];
  triggerAllValidations?: boolean;
  showRequired?: boolean;
  premiumType?: PremiumType;
  nTierCount?: number;
  isImportPlans?: boolean;
  isNoCurrentPlans?: boolean;
  isProposalsAttached?: boolean;
  fundingType?: string;
  isQuoteProcessingEnabled?: boolean;
  extractedDocument?: {
    id: string;
    status: QuoteProcessStatus;
    reviewType: ReviewType;
  };
  isQuotePlansApproved?: boolean;
  showFlagItemWarning?: boolean;
};

const AddPlanDetailsModal: FC<AddPlanDetailsModalProps> = ({
  visible,
  onClose,
  data,
  setUpdateData,
  benefitType,
  isAddPlan = false,
  addIndexList,
  indexList,
  showValidationsOnLoad = false,
  updatedData,
  triggerAllValidations,
  showRequired,
  premiumType = PremiumType.N_TIER,
  nTierCount = 6,
  isImportPlans = false,
  isNoCurrentPlans = false,
  isProposalsAttached = false,
  fundingType,
  isQuoteProcessingEnabled,
  extractedDocument,
  isQuotePlansApproved,
  showFlagItemWarning,
}) => {
  const [selectedData, setSelectedData] = useState<Plan | any>(null);

  const [showValidationEmptyMessage, setShowEmptyValidationMessage] =
    useState<boolean>(false);
  const [showValidationHighlight, setShowValidationHighlight] =
    useState<boolean>(false);
  const [showValidationNameExists, setShowValidationNameExists] =
    useState<boolean>(false);
  const [triggerPlanNameValidation, setTriggerPlanNameValidation] =
    useState<boolean>(false);
  const [showPlanNameSizeError, setShowPlanNameSizeError] =
    useState<boolean>(false);

  const [disableLifeRate, setDisableLifeRate] = useState<boolean>(false);
  const [disableADDRate, setDisableADDRate] = useState<boolean>(false);
  const [referenceList, setReferenceList] = useState<OfferDocType[]>([]);

  const [deletedDocumentList, setDeletedDocumentList] = useState<
    { fileName: string; referenceId: string }[]
  >([]);

  const isQuotePlan =
    isQuoteProcessingEnabled && !isEmpty(selectedData?.extractionId);

  const [deleteOfferDocument] = useDeleteOfferDocumentsMutation();

  const [
    getFileReference,
    {
      isSuccess: isReferenceSuccess,
      isLoading: isReferenceLoading,
      data: referenceData,
      reset: resetReferenceData,
    },
  ] = useGetUploadFileReferenceMutation();

  useEffect(() => {
    setReferenceList(data?.attachedDocuments ?? []);
  }, [data]);

  /**
   * Builds initial data object based on certain conditions.
   *
   * return type is not initialized in FE and dynamically generated complex data object according to the BE responses.
   * @return {Plan} Initial data object with services and enrolments or the original data object if conditions are not met.
   */
  const buildInitialData = (): Plan => {
    if (MDV_PLANS?.includes(benefitType?.toUpperCase())) {
      return {
        ...data,
        services: data?.services ?? [],
        enrolments: buildEnrollments(
          data?.enrolments,
          premiumType,
          nTierCount,
          validate
        ),
      };
    } else {
      return data;
    }
  };

  useEffect(() => {
    if (!isAddPlan && visible) {
      const initialData = buildInitialData();
      // Removing 'HDHP' planType
      if (initialData.planType === PLAN_TYPES.HDHP) {
        initialData.planType = null;
        initialData.hsaCompatible = true;
      }
      // If imported from current plans reset the values
      if (
        isImportPlans &&
        !!initialData?.hsaCompatible &&
        !!initialData?.hraCompatible
      ) {
        initialData.hsaCompatible = false;
        initialData.hraCompatible = false;
      }
      setSelectedData(initialData);
      // Setting disable rates based on insurance type
      if (benefitType === LIFE) {
        // Insurance type falls under LIFE benefit type and hence, handle updating rates states in nested if-else conditions.
        if (data?.insuranceType === BASIC_LIFE) {
          setDisableADDRate(true);
          setDisableLifeRate(false);
        }
        if (data?.insuranceType === BASIC_ADD) {
          setDisableADDRate(false);
          setDisableLifeRate(true);
        }
        if (data?.insuranceType === BASIC_LIFE_AND_ADD) {
          setDisableADDRate(false);
          setDisableLifeRate(false);
        }
      }
      // Setting reference list
      if (data?.attachedDocuments) {
        setReferenceList(data?.attachedDocuments);
      }
    }
    // This useEffect should only run when data, isAddPlan, visible dependencies change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isAddPlan, visible]);

  useEffect(() => {
    if (benefitType === LIFE) {
      if (selectedData?.insuranceType === BASIC_LIFE) {
        setSelectedData?.({
          ...selectedData,
          basicAddRate: [
            {
              name: 'Basic AD&D Rate*',
              fieldType: null,
              fieldSubType: null,
              value: null,
              formattedValue: null,
            },
          ],
        });
        setDisableADDRate(true);
        setDisableLifeRate(false);
      } else if (selectedData?.insuranceType === BASIC_ADD) {
        setSelectedData?.({
          ...selectedData,
          lifeRate: [
            {
              name: 'Basic Life Rate*',
              fieldType: null,
              fieldSubType: null,
              value: null,
              formattedValue: null,
            },
          ],
        });
        setDisableADDRate(false);
        setDisableLifeRate(true);
      } else if (selectedData?.insuranceType === BASIC_LIFE_AND_ADD) {
        setDisableADDRate(false);
        setDisableLifeRate(false);
      }
    }
    // eslint-disable-next-line
  }, [selectedData?.insuranceType]);

  const isMDVOffer = MDV_PLANS?.includes(benefitType);

  const isAlertShouldShow =
    showValidationEmptyMessage ||
    showValidationNameExists ||
    showPlanNameSizeError;
  /**
   * Retrieves the updated value based on the provided property, value and validations.
   * @param {string} property - The property for which the value needs to be updated.
   * @param {string} value - The current value of the property.
   * @param {Validations} validations - Validations object containing validation rules.
   *
   * @return {string} The updated value according to the specified property and validations.
   */
  const getUpdatedValue = (
    property: string,
    value: string,
    validations: Validations
  ): string => {
    switch (property) {
      case ENROLLMENTS:
      case NON_MDV_ENROLLMENT:
      case TOTAL_ELIGIBLE_EMPLOYEES:
        // If value is not all zeros or is empty, remove non-numeric characters and format it
        const updatedValue = (
          !/^0*$/.test(value) || isEmpty(value) ? value : '0'
        )?.replace(/[^0-9]/g, '');
        // If the resulting value is not a number, return an empty string, else format it and return
        return Number.isNaN(parseInt(updatedValue))
          ? ''
          : parseInt(updatedValue).toLocaleString();
      // For property related to HSA compatibility, return the value as is
      case HSA_COMPATIBLE:
        return value;
      default:
        // For other properties
        if (isEmpty(value?.trim())) {
          // If value is empty or consists of only whitespace characters, return an empty string
          return '';
        }
        if (validations?.currency) {
          // If currency validation is enabled, format the value as currency
          return currencyFormatterWithAnyNumberDecimalPlaces(Number(value));
        }
        if (validations?.percentage) {
          // If percentage validation is enabled, format the value as percentage
          return percentagePostfix(Number(value));
        }
        if (validations?.timePeriod === TIME_PERIOD_VALIDATION.DAYS) {
          // If time period validation is enabled for days, format the value with days postfix
          return daysPostFix(Number(value));
        }
        if (validations?.timePeriod === TIME_PERIOD_VALIDATION.WEEKS) {
          // If time period validation is enabled for weeks, format the value with weeks postfix
          return weeksPostFix(Number(value));
        }
        // If no specific validation is enabled, return the value as is
        return value;
    }
  };

  const updateObject = (
    property: string,
    value: string | any,
    isArray: boolean,
    findObj?: any,
    isObj?: boolean,
    validations?: any
  ) => {
    if (isObj) {
      setSelectedData({
        ...selectedData,
        [property]: {
          ...findObj,
          value: getUpdatedValue(property, value, validations),
          formattedValue: getUpdatedValue(property, value, validations),
        },
      });
    } else if (!isArray) {
      setSelectedData({
        ...selectedData,
        [property]: getUpdatedValue(property, value, validations),
      });
    } else {
      let array = [];
      let exists = false;

      if ((selectedData?.[property] ?? [])?.length !== 0) {
        array = selectedData?.[property]?.map((item: any) => {
          if (
            item?.name === findObj?.name &&
            item?.fieldType === findObj?.fieldType &&
            item?.fieldSubType === findObj?.fieldSubType
          ) {
            exists = true;
            return {
              ...item,
              value: getUpdatedValue(property, value, validations),
              formattedValue: getUpdatedValue(property, value, validations),
            };
          } else {
            return item;
          }
        });
      }

      if (!exists) {
        array = [
          ...array,
          {
            name: findObj?.name,
            fieldType: findObj?.fieldType,
            fieldSubType: findObj?.fieldSubType,
            value: getUpdatedValue(property, value, validations),
          },
        ];
      }

      setSelectedData({ ...selectedData, [property]: array });
    }
  };

  const triggerDocumentDeleteIfExist = () => {
    if (!isEmpty(deletedDocumentList)) {
      const references: string[] = deletedDocumentList.map(
        (obj) => obj.referenceId
      );
      deleteOfferDocument({ references });
    }
  };

  const setOrthodontiaValues = (orthodontiaMax: any) => {
    let newOrthodontiaMax: any = [];
    if (benefitType === DENTAL_OFFER) {
      orthodontiaMax?.map((item: any) => {
        const orthodontiaObj = {
          currencyFormattedValue: item?.value ?? null,
          dataFormat: item?.dataFormat ?? null,
          fieldSubType: item?.fieldSubType ?? null,
          fieldType: item?.fieldType ?? null,
          formattedValue: item?.formattedValue ?? null,
          name: item?.name ?? null,
          optionalFormattedValue: null ?? null,
          value: item?.value ?? null,
        };
        newOrthodontiaMax.push(orthodontiaObj);
      });

      newOrthodontiaMax = newOrthodontiaMax.filter(
        (item: any, index: number, array: any[]) => {
          const hasSameFieldType = array.some((otherItem, otherIndex) => {
            return (
              index !== otherIndex && otherItem.fieldType === item.fieldType
            );
          });
          return !(
            hasSameFieldType &&
            (item.fieldType === 'In Network' ||
              item.fieldType === 'Out of Network') &&
            item.value === null
          );
        }
      );
      return newOrthodontiaMax;
    }
  };

  /**
   * Update states based on validation results.
   * @param {boolean} isValidPlanName Whether the plan name is valid.
   * @param {boolean} isValidPlanNameLength Whether the plan name length is valid.
   * @param {boolean} isNameExists Whether the plan name already exists.
   */
  const updateStatesByValidations = (
    isValidPlanName: boolean,
    isValidPlanNameLength: boolean,
    isNameExists: boolean
  ): void => {
    if (isValidPlanName) {
      setShowEmptyValidationMessage(true);
      setTriggerPlanNameValidation(true);
    } else if (isValidPlanNameLength) {
      setShowPlanNameSizeError(true);
      setTriggerPlanNameValidation(true);
    } else if (isNameExists) {
      setShowEmptyValidationMessage(false);
      setShowValidationNameExists(true);
    }
  };

  /**
   * Trims whitespace from the `value` and `formattedValue` properties
   * of the `multiplier` and `flatAmount` fields in the given plan object.
   *
   * @param {Object} plan - The plan object containing the fields to be trimmed.
   * @return {Object} The plan object with the trimmed fields.
   */
  const trimFields = (plan: Plan | any) => {
    if (plan?.multiplier && plan?.multiplier?.value) {
      plan = {
        ...plan,
        multiplier: {
          ...plan.multiplier,
          value: plan.multiplier.value.trim(),
          formattedValue: plan.multiplier.formattedValue.trim(),
        },
      };
    }

    if (plan?.flatAmount && plan?.flatAmount?.value) {
      plan = {
        ...plan,
        flatAmount: {
          ...plan.flatAmount,
          value: plan.flatAmount.value.trim(),
          formattedValue: plan.flatAmount.formattedValue.trim(),
        },
      };
    }

    return plan;
  };

  /**
   * Handles saving a plan.
   * This function performs various validations and updates states accordingly.
   */
  const handleSavePlan = (): void => {
    if (
      validateObj(
        selectedData,
        fundingType,
        benefitType,
        premiumType,
        isNoCurrentPlans,
        nTierCount
      ) &&
      !triggerAllValidations &&
      isMDVOffer
    ) {
      setShowEmptyValidationMessage(true);
      setShowValidationHighlight(true);
      return;
    }
    if (
      validateNonMDVObj(selectedData, benefitType, fundingType) &&
      !triggerAllValidations &&
      !isMDVOffer &&
      benefitType !== VOLUNTARY_BENEFIT
    ) {
      setShowEmptyValidationMessage(true);
      setShowValidationHighlight(true);
      return;
    }
    if (validatePlanName(selectedData, benefitType)) {
      updateStatesByValidations(
        validatePlanName(selectedData, benefitType),
        validatePlanNameLength(selectedData?.name),
        nameExists(updatedData, selectedData, data)
      );
      return;
    }
    setShowEmptyValidationMessage(false);
    setShowValidationHighlight(false);
    setTriggerPlanNameValidation(false);
    setShowValidationNameExists(false);
    const updateSelectedData = { ...selectedData };
    if (benefitType === DENTAL_OFFER) {
      updateSelectedData.orthodontiaMax = setOrthodontiaValues(
        selectedData.orthodontiaMax
      );
    }
    const trimmedPlan = trimFields(updateSelectedData);

    setUpdateData &&
      setUpdateData({
        ...trimmedPlan,
        name: selectedData?.name?.trim(),
        keyRefId: selectedData?.keyRefId
          ? selectedData?.keyRefId
          : uuidv4() + NEW_OFFER_PLAN,
        attachedDocuments: referenceList,
        rateGuarantee: updateSelectedData.rateGuarantee?.trim(),
        requiredEmployerContribution:
          updateSelectedData.requiredEmployerContribution?.trim(),
        requiredParticipation: updateSelectedData.requiredParticipation?.trim(),
      });
    addIndexList && addIndexList(indexList);
    onClose(false);
    triggerDocumentDeleteIfExist();
    resetReferenceData();
  };

  // Handles canceling the modal
  const handleCancel = () => {
    onClose(false);
    setSelectedData(null);
    setShowEmptyValidationMessage(false);
    setShowValidationHighlight(false);
    setShowValidationNameExists(false);
    setTriggerPlanNameValidation(false);
    resetReferenceData();
  };

  const getAlertValidationMessage = () => {
    if (showValidationEmptyMessage)
      return 'Please correct all items highlighted in red.';
    if (showValidationNameExists) return 'A Plan with this name already exists';
    if (showPlanNameSizeError)
      return 'Please limit the plan name to a maximum of 50 characters.';
  };

  return (
    <HalfScreenModal
      visible={visible}
      onCancel={() => {}}
      width={isQuotePlan ? 1500 : 850}
      destroyOnClose={true}
    >
      <div className={styles.headerWrapper}>
        <div className={styles.title}>Enter Plan Details</div>
        <div className={styles.planName}>{data?.name ?? '-'}</div>
      </div>
      {isAlertShouldShow && (
        <div className={styles.alertWrapper}>
          <AlertMessage
            type="error"
            message={getAlertValidationMessage()}
            closeAlert={() => {
              setShowEmptyValidationMessage?.(false);
              setShowValidationNameExists?.(false);
              setShowPlanNameSizeError?.(false);
            }}
            className={styles.alertMessage}
          />
        </div>
      )}
      {isNoCurrentPlans && isProposalsAttached && (
        <FixedAlertMessage
          className={styles.proposalExistsBanner}
          type="info"
          message={PROPOSAL_EXISTS_INFO_TEXT}
        />
      )}
      <SplitContainer leftWeight={1} rightWeight={1} hideLeft={!isQuotePlan}>
        <div className={styles.pdfViewerContainer}>
          <PdfViewer
            file={getPDFViewURL(extractedDocument?.id!)}
            fileType="Quote File"
          />
        </div>
        <AddPlanForm
          data={data}
          benefitType={benefitType}
          fundingType={fundingType}
          premiumType={premiumType}
          showRequired={showRequired}
          isNoCurrentPlans={isNoCurrentPlans}
          nTierCount={nTierCount}
          setSelectedData={setSelectedData}
          selectedData={selectedData}
          showValidationsOnLoad={showValidationsOnLoad}
          showValidationHighlight={showValidationHighlight}
          triggerPlanNameValidation={triggerPlanNameValidation}
          referenceList={referenceList}
          setReferenceList={setReferenceList}
          getFileReference={getFileReference}
          referenceData={referenceData}
          isReferenceLoading={isReferenceLoading}
          isReferenceSuccess={isReferenceSuccess}
          disableLifeRate={disableLifeRate}
          disableADDRate={disableADDRate}
          deletedDocumentList={deletedDocumentList}
          setDeletedDocumentList={setDeletedDocumentList}
          updateObject={updateObject}
          isQuotePlan={isQuotePlan}
          extractedDocument={extractedDocument}
          isQuotePlansApproved={isQuotePlansApproved}
          showFlagItemWarning={showFlagItemWarning}
        />
      </SplitContainer>

      <Divider className={styles.mainDivider} />
      <Row justify="center">
        <Button
          loading={isReferenceLoading}
          className={styles.saveButton}
          onClick={handleSavePlan}
        >
          Save
        </Button>
      </Row>

      <Row justify={'center'} className={styles.cancelButtonWrapper}>
        <TextButton
          className={styles.cancelButton}
          type="primary"
          onClick={handleCancel}
          label="Cancel"
        />
      </Row>
    </HalfScreenModal>
  );
};

export default AddPlanDetailsModal;
