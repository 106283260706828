import {
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Collapse, Spin } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { some, get, cloneDeep, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import AddEnrollments from 'modules/plans/components/Enrollments/AddEnrollments/AddEnrollments';
import RatesUPWrapper from 'modules/plans/dental/components/Rates/RatesUPWrapper';
import PanelSection from 'modules/plans/enums/PanelSection';
import DentalBasicPlanInfoWrapper from 'modules/plans/dental/components/PlanBasicInfo/DentalBasicPlanInfoWrapper';
import { DentalPlan } from 'model/plans/DentalPlan';
import BenefitCode from 'model/plans/BenefitCode';
import CostSharing from 'model/plans/CostSharing';
import CustomPlan from 'model/plans/CustomPlan';
import {
  getDentalPlanServicesList,
  removeUserAiFeedback,
  resetDentalPlanReduxStore,
  resetStateCompleted,
  saveDentalPlan,
  setUserAiFeedback,
  updateDentalPlanCompleted,
} from 'modules/plans/slices/dentalPlanSlice';
import PanelFooter from 'modules/plans/components/PanelFooter/PanelFooter';
import DeductiblesOOPMaxSBCWrapper from 'modules/plans/dental/components/DeductiblesOOPMax/DeductiblesOOPMaxUPWrapper';
import ServicesUPWrapper from 'modules/plans/dental/components/Services/ServicesUPWrapper';
import { useNavContext } from 'hooks/useNavContext';
import {
  BenefitCategory,
  FEATURE_KEYS,
  planAlreadyExistsError,
} from 'constants/commonConstants';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import PlanDocumentUPWrapper from 'modules/plans/dental/components/PlanDocuments/PlanDocumentsUPWrapper';
import AddCustomServiceModal from 'modules/plans/components/AddCustomServiceModal/AddCustomServiceModal';
import PanelHeaderExtra from 'modules/plans/components/PanelHeaderExtra/PanelHeaderExtra';
import NextButton from 'components/buttons/NextButton/NextButton';

import {
  AI_FILE_TYPE,
  benefitCode,
  MDV_FEEDBACK_PANEL_TYPES,
  PANEL_HEADINGS_LONG,
  PLAN_ADDITIONAL_DOCUMENT,
  PlanApproach,
  PriorToDeductible,
  TooltipHeadings,
  Tooltips,
  VALIDATION_NAME_DUPLICATED,
} from 'modules/plans/constants';
import { getValidationMessage } from 'util/commonUtil';
import {
  buildRates,
  getDefaultPlanRates,
  handleScrollToNextPanel,
  createMockPlanObject,
} from 'modules/plans/utils';
import { DocumentExtractionSource } from 'modules/plans/enums/DocumentExtractionSource';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import AiFeedbackReceiver from 'modules/plans/components/AiFeedbackReceiver/aiFeedbackReceiver';
import { AIUploaderFeedback } from 'model/plans/UserFeedback';
import {
  AddPlanFormProps,
  PlanSectionFormRef,
} from 'modules/plans/types/types';
import useGetUserFeatureStatus from 'hooks/useGetUserFeatureStatus';
import PlanUpdateWarningModal, {
  UpdateDocumentExtractionSource,
} from 'modules/plans/components/PlanUpldateWarningModal/PlanUpdateWarningModal';
import AdditionalPlanResources from 'modules/plans/components/AdditionalPlanResources/AdditionalPlanResources';
import FileType from 'model/plans/FileType';
import WebLinkType from 'model/plans/WebLinkType';
import {
  removePlanDocuments,
  updateWeblinks,
  uploadPlanDocument,
  uploadRemovePlanDocument,
} from 'modules/plans/slices/planDocumentsSlice';
import UpdatePlanParametersInfo from 'modules/plans/UpdatePlanParametersInfo/UpdatePlanParametersInfo';
import styles from './addDentalPlanDialog.module.less';

type PanelVisitedStatus = {
  basicPlanInfo: boolean;
  rates: boolean;
  deductiblesOopMax: boolean;
  services: boolean;
  planDocuments: boolean;
  enrollments: boolean;
};

const getDefaultVisitedStates = (): PanelVisitedStatus => {
  return {
    basicPlanInfo: false,
    rates: false,
    deductiblesOopMax: false,
    services: false,
    planDocuments: false,
    enrollments: false,
  };
};

const OPS_VIEW_PANELS = [
  PanelSection.SERVICES,
  PanelSection.DEDUCTIBLES_OOP_MAX,
];

type TrackPanelChanges = {
  deductiblesOopMax: boolean;
  services: boolean;
};

const getDefaultNonSBCValues = (): TrackPanelChanges => {
  return {
    deductiblesOopMax: false,
    services: false,
  };
};

const getSBCDefaultValues = (): TrackPanelChanges => {
  return {
    deductiblesOopMax: true,
    services: true,
  };
};

const panelsInOrder = [
  PanelSection.BASIC_PLAN_INFO,
  PanelSection.RATES,
  PanelSection.DEDUCTIBLES_OOP_MAX,
  PanelSection.SERVICES,
  PanelSection.ENROLLMENTS,
  PanelSection.PLAN_DOCUMENTS,
];

type AddDentalPlanDialogProps = AddPlanFormProps & {
  isRenewalProcessStarted: boolean;
};

const AddDentalPlanDialog = forwardRef(
  (props: AddDentalPlanDialogProps, ref) => {
    const {
      isOpen,
      dbgPlanYear,
      isDisable = false,
      isRenewalProcessStarted = false,
      planApproach = PlanApproach.MANUAL,
      onError,
      isReview,
      status = ProcessStatus.CANCELLED,
      onSave,
      jobId,
      skipFailedDocument,
      formRightWrapperRef,
      docExtractionSource,
      onClose,
    } = props;
    const { Panel } = Collapse;
    const dispatch = useAppDispatch();

    const basicPlanInfoRef = useRef<any>();
    const servicesRef = useRef<any>();
    const documentsRef = useRef<any>();
    const ratesRef = useRef<any>();
    const enrollmentsRef = useRef<any>();
    const deductiblesRef = useRef<any>();
    const {
      dentalPlan,
      dentalPlanServiceList,
      displayedDentalServices,
      editedDentalPlan,
      inProgress,
    } = useAppSelector((state) => state.plan.dentalPlan);
    const warningError = useAppSelector((state) => state.plan.dentalPlan.error);
    const { isOpsAdmin } = useAppSelector(
      (state: any) => state.auth.auth.appBootupInfo ?? {}
    );
    const plan = useSelector((state: any) => state.plan.plans);
    const { inProgress: documentUploadInProgress } = useAppSelector(
      (state) => state.plan.planDocuments
    );

    const [showUpdatePlanWarning, setShowUpdatePlanWarning] =
      useState<boolean>(false);
    const [isSBCPanelVsited, setSBCPanelVisited] = useState<TrackPanelChanges>(
      getDefaultNonSBCValues()
    );
    const {
      defaults: serviceTypes,
      benefitKind: serviceTypeBenefitKind,
      error,
    } = dentalPlanServiceList || {};
    const { employerId, brokerId } = useNavContext();
    const [activePanel, setActivePanel] = useState<string>('');
    const [panelVisitedStatus, setPanelVisitedStatus] =
      useState<PanelVisitedStatus>(getDefaultVisitedStates());
    const [isAddCustomServiceModalOpen, setIsAddCustomServiceModalOpen] =
      useState<boolean>(false);
    const [requiredFieldError, setRequiredFieldError] =
      useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [touchedSection, setTouchedSection] = useState<string>('');
    const [planAlreadyExists, setPlanAlreadyExists] = useState<boolean>(false);
    const [isPanelComplete, setIsPanelComplete] = useState<PanelVisitedStatus>(
      getDefaultVisitedStates()
    );
    const [selectedFileList, setSelectedFileList] = useState<FileType[]>([]);
    const [isDocRemoved, setIsDocRemoved] = useState<{
      [key: string]: boolean;
    }>({});
    const [selectedWeblink, setSelectedWeblink] = useState<WebLinkType[]>([]);

    const isFromUpdatePan = !isEmpty(docExtractionSource);

    const isAnUpdateOfferedPlan =
      isFromUpdatePan &&
      docExtractionSource?.source ===
        DocumentExtractionSource.UPDATE_QUOTE_PLAN;

    const isDocumentUploading = some(
      Object.values(get(dentalPlan, 'documentReferences', {})),
      (value) => get(value, 'uploading', false)
    );

    const disableOnUpdatePlan =
      !isEmpty(docExtractionSource) &&
      !isOpsAdmin &&
      [ProcessStatus.REVIEWED].includes(status);

    const isSaveDisabled =
      (!disableOnUpdatePlan && !panelVisitedStatus.basicPlanInfo) ||
      inProgress ||
      isDisable ||
      documentUploadInProgress ||
      (isReview && !disableOnUpdatePlan) ||
      planApproach === PlanApproach.NOT_SELECTED;

    const showFeedbackPanel =
      planApproach === PlanApproach.SBC &&
      !isReview &&
      docExtractionSource?.source !==
        DocumentExtractionSource.UPDATE_QUOTE_PLAN;

    useImperativeHandle(ref, () => ({
      setActivePanel,
      getPlan: () => dentalPlan,
      reset() {
        dispatch(resetStateCompleted());
        setActivePanel(PanelSection.BASIC_PLAN_INFO);
        setPanelVisitedStatus(getDefaultVisitedStates());
        basicPlanInfoRef.current?.resetForm?.();
        documentsRef.current?.resetAll?.();
        ratesRef.current?.resetAll?.();
        const clonedDentalPlan = cloneDeep(dentalPlan);
        delete clonedDentalPlan?.customServices;
        delete clonedDentalPlan?.rates;
        delete clonedDentalPlan?.documentReferences?.[
          AI_FILE_TYPE.PLAN_SUMMARY
        ];
        delete clonedDentalPlan?.documents?.[AI_FILE_TYPE.PLAN_SUMMARY];
        dispatch(updateDentalPlanCompleted(clonedDentalPlan));
      },
    }));

    const isAdvancedProfile = useGetUserFeatureStatus(
      brokerId,
      employerId,
      FEATURE_KEYS.ADVANCED_PROFILE,
      false
    );

    const getPanelClassName = (panel: PanelSection): string => {
      const defaultValues = getSBCDefaultValues();
      const isPanelInDefaultValues = Object.keys(defaultValues).includes(panel);
      const showUpdatePlanPanels = isFromUpdatePan && !isPanelInDefaultValues;

      return showUpdatePlanPanels
        ? styles.disabledPanelColor
        : panelVisitedStatus[panel as keyof PanelVisitedStatus]
        ? styles.visitPanel
        : styles.notVisitPanel;
    };

    useEffect(() => {
      if (
        (serviceTypes.length === 0 && !error) ||
        serviceTypeBenefitKind !== BenefitCategory.DENTAL.value
      ) {
        dispatch(
          getDentalPlanServicesList(
            BenefitCategory.DENTAL.value,
            employerId || ''
          )
        );
      }
    }, [dispatch, serviceTypes, employerId, error, serviceTypeBenefitKind]);

    useEffect(() => {
      dispatch(resetDentalPlanReduxStore());
    }, [dispatch]);

    useEffect(() => {
      if (warningError?.data?.code === VALIDATION_NAME_DUPLICATED) {
        setAlertMessage(planAlreadyExistsError);
        setPlanAlreadyExists(true);
        setActivePanel(PanelSection.BASIC_PLAN_INFO);
        setRequiredFieldError(true);
        setShowUpdatePlanWarning(false);
      }
    }, [warningError]);

    const onChange = useCallback(
      async (dentalPlan: DentalPlan) => {
        dispatch(updateDentalPlanCompleted(dentalPlan));
      },
      [dispatch]
    );

    const changeActivePanel = (
      currentKey: PanelSection,
      nextKey?: PanelSection
    ) => {
      setPanelVisitedStatus({ ...panelVisitedStatus, [currentKey]: true });
      setActivePanel(nextKey ?? ('' as PanelSection));
      setTouchedSection('');

      const clonedDentalPlan = cloneDeep(dentalPlan);
      if (
        nextKey === PanelSection.SERVICES &&
        !clonedDentalPlan.customServices
      ) {
        const benefitCodePreventiveCare = new BenefitCode();
        benefitCodePreventiveCare.benefitKind = BenefitCategory.DENTAL.value;
        benefitCodePreventiveCare.code =
          benefitCode.DENTAL_PREVENTIVE_CARE.code;
        benefitCodePreventiveCare.description =
          benefitCode.DENTAL_PREVENTIVE_CARE.description;
        benefitCodePreventiveCare.shortName =
          benefitCode.DENTAL_PREVENTIVE_CARE.shortName;

        const inNetwork = new CostSharing();
        const outOfNetwork = new CostSharing();
        outOfNetwork.benefitCovered = '';
        outOfNetwork.copayPriorToDeductible = PriorToDeductible.NO;

        const preventiveCare = new CustomPlan();
        preventiveCare.benefitCode = benefitCodePreventiveCare;
        preventiveCare.serviceValue.inNetwork = inNetwork;
        preventiveCare.serviceValue.outOfNetwork = outOfNetwork;
        preventiveCare.isDefault = true;
        const customServices = [preventiveCare];
        clonedDentalPlan.customServices = customServices;
        dispatch(updateDentalPlanCompleted(clonedDentalPlan));
      }
      handleScrollToNextPanel(currentKey, formRightWrapperRef);
    };

    const collapseOnChange = (key: any) => {
      setActivePanel(key);
      const keysOfIsNext = panelsInOrder;
      const panelsBelow = keysOfIsNext.slice(keysOfIsNext.indexOf(key));
      const newIsNextObj = Object.assign(
        panelVisitedStatus,
        ...panelsBelow.map((panelIsVisited) => ({
          [panelIsVisited]: false,
        }))
      );
      setPanelVisitedStatus(newIsNextObj);
    };

    const onSaveDentalPlan = (isSuccess?: boolean, id?: string) => {
      uploadDocument(id, isSuccess);
    };

    const onValidateFails = (validateSetting: string) => {
      setAlertMessage(getValidationMessage(validateSetting));
      setRequiredFieldError(true);
    };

    const getCollapsible = (enabled: boolean) => {
      if (!(planApproach !== PlanApproach.NOT_SELECTED && enabled))
        return 'disabled';
    };

    /**
     * As the error display logic was abstracted out of this component. This use effect is used to
     * signal errors that occur to the parent component so it can show its own error views.
     */
    useEffect(() => {
      if (requiredFieldError || planAlreadyExists) {
        onError?.(alertMessage ? alertMessage : 'Please fill required fields');
      } else if (!requiredFieldError) {
        onError?.('');
      }
    }, [requiredFieldError, planAlreadyExists, alertMessage, onError]);

    const getPanelHeader = (panel: PanelSection) => {
      const panelNumber = panelsInOrder.indexOf(panel) + 1;
      const panelTitle = PANEL_HEADINGS_LONG[panel];
      return (
        <span
          className={getPanelClassName(panel)}
        >{`${panelNumber}. ${panelTitle}`}</span>
      );
    };

    const getPanelHeaderExtra = (
      panel: PanelSection,
      isAiCheck?: boolean,
      isSBC?: boolean
    ) => {
      const panelKey = Object.keys(PanelSection)[
        Object.values(PanelSection).indexOf(panel)
      ] as keyof typeof Tooltips;

      return (
        planApproach !== PlanApproach.NOT_SELECTED && (
          <PanelHeaderExtra
            isSBC={isSBC}
            isAi={isAiCheck}
            isOpsAdmin={isOpsAdmin}
            isFromUpdatePan={isFromUpdatePan}
            isActive={activePanel === panel}
            isComplete={isPanelComplete[panel as keyof typeof isPanelComplete]}
            isUploading={[ProcessStatus.PROCESSING].includes(status)}
            popoverContent={
              Tooltips[panelKey] && (
                <div>
                  <QuestionCircleOutlined />
                  <span className="text popover-title">
                    {TooltipHeadings[panelKey]}
                  </span>
                  <p> {Tooltips[panelKey]}</p>
                </div>
              )
            }
          />
        )
      );
    };

    /**
     * We pass this prop object rather than making a new Panel componnent that consolidates this logic
     * as when we do that, the collapsible does not work properly. It does not expand or collapse when clicked.
     * @param {PanelSection} section Which panel section
     * @return {Object} Props object
     */
    const getPanelProps = (section: PanelSection) => {
      let isEnabled: boolean = (panelVisitedStatus as any)[section];
      if (isReview) {
        isEnabled = OPS_VIEW_PANELS.includes(section);
      }

      const panelKey = Object.values(PanelSection)[
        Object.values(PanelSection).indexOf(section)
      ] as keyof typeof isSBCPanelVsited;

      const sbcEnabled = OPS_VIEW_PANELS.includes(section);

      return {
        header: getPanelHeader(section),
        key: section,
        showArrow: false,
        collapsible: getCollapsible(isEnabled),
        extra: getPanelHeaderExtra(
          section,
          sbcEnabled && isSBCPanelVsited[panelKey],
          sbcEnabled && !isEmpty(jobId)
        ),
      } as const;
    };

    const addAiFeedback = (planFeedback: AIUploaderFeedback) => {
      dispatch(setUserAiFeedback(planFeedback));
    };

    const removeAiFeedback = (feedbackPanel: string) => {
      dispatch(removeUserAiFeedback(feedbackPanel));
    };

    /**
     * This function is used to create the next step handler for each panel section. It is used to validate the passed in section
     * and then move to the next panel if the validation passes while recording the touched and completion status of the panel.
     *
     * The panel contains some logic used to build data necessary for the rates and enrollments section. This is necessary
     * to get the enrollments to function properly. Otherwise there will be an issue where the enrollments section is empty.
     *
     * @param {MutableRefObject<any>} ref The ref of the panel. Either the ref of the form itself or the wrapper
     * @param {PanelSection} section The current panel section
     * @return {Function} The next step handler
     */
    const createNextStepHandler =
      (ref: MutableRefObject<PlanSectionFormRef>, section: PanelSection) =>
      async () => {
        const { isComplete = true, isValid = true } =
          (await ref.current?.validate?.()) ?? {};

        setTouchedSection(section);
        if (
          [PanelSection.DEDUCTIBLES_OOP_MAX, PanelSection.SERVICES].includes(
            section
          )
        ) {
          setSBCPanelVisited({
            ...isSBCPanelVsited,
            [section]: false,
          });
        }
        if (isValid && !inProgress) {
          const crntPanelIdx = panelsInOrder.indexOf(section);
          const nextSection =
            crntPanelIdx !== -1
              ? panelsInOrder[panelsInOrder.indexOf(section) + 1]
              : undefined;

          changeActivePanel(section, nextSection);
          setIsPanelComplete((prev) => ({
            ...prev,
            [section]: isComplete,
          }));

          /**
           * This is okay for anything except the below two sections because those below two sections
           * have problems that require special work arounds.
           */
          if (
            ![
              PanelSection.BASIC_PLAN_INFO,
              PanelSection.PLAN_DOCUMENTS,
            ].includes(section)
          ) {
            await onChange(editedDentalPlan);
          }
        }
      };

    /**
     * This function is used to handle the change of the basic plan info section.
     * This needs to be specially handled as if we update it in the createNextStepHandler
     * func there will be multiple back-to-back updates that end with the initial state
     * not changing.
     *
     * This is made as a seperate useCallback function as otherwise redux will complain endlessly
     * about non-seriliazable values. Why? because buildRates returns classes. Even with this it complains
     * like 5 times.
     */
    const handleBasicInfoChange = useCallback(
      (editedPlan: DentalPlan) => {
        onChange({
          ...editedPlan,
          rates: buildRates(editedPlan.groups, getDefaultPlanRates(editedPlan)),
        });
      },
      [onChange]
    );

    const savePlan = async (skipForUpdate?: boolean) => {
      setShowUpdatePlanWarning(false);
      let updatedPlan = cloneDeep(dentalPlan);

      if (skipForUpdate) {
        updatedPlan = { ...updatedPlan, ...createMockPlanObject() };
      }
      await dispatch(saveDentalPlan(updatedPlan, onSaveDentalPlan));
    };

    // Below triggers to set the values as AI when job id is available
    useEffect(() => {
      if (
        [
          ProcessStatus.PROCESSING,
          ProcessStatus.REVIEWED,
          isOpsAdmin && (ProcessStatus.SUCCESS || ProcessStatus.SAVED),
        ].includes(status)
      ) {
        setSBCPanelVisited(getSBCDefaultValues());
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, jobId]);

    const handleNextStep = async () => {
      setShowUpdatePlanWarning(false);
      const { isValid } = (await ratesRef.current?.validate?.()) ?? {};
      if (!isEmpty(docExtractionSource)) {
        return setShowUpdatePlanWarning(true);
      } else if (isValid) {
        await savePlan();
      }
    };

    const uploadDocument = async (id?: string, isSuccess?: boolean) => {
      const documentUploadSuccess = () => {
        setSelectedFileList([]);
        setSelectedWeblink([]);
        dispatch(
          getDentalPlanServicesList(
            BenefitCategory.DENTAL.value,
            employerId || ''
          )
        );
        onSave?.(isSuccess ?? false);
        onClose();
      };

      const planId = id ?? (dentalPlan.id as string);
      const removeDocs = Object.keys(isDocRemoved)
        .filter((documentType) => isDocRemoved[documentType])
        .map((documentType) => ({
          docType: PLAN_ADDITIONAL_DOCUMENT,
          planDocumentName: documentType,
        }));
      if (!isEmpty(selectedFileList) && isEmpty(removeDocs)) {
        await dispatch(
          uploadPlanDocument(
            selectedFileList,
            planId,
            BenefitCategory.DENTAL.value
          )
        );
      } else if (!isEmpty(removeDocs) && isEmpty(selectedFileList)) {
        await dispatch(
          removePlanDocuments(planId, BenefitCategory.DENTAL.value, removeDocs)
        );
      } else if (!isEmpty(removeDocs) && !isEmpty(selectedFileList)) {
        await dispatch(
          uploadRemovePlanDocument(
            selectedFileList,
            planId,
            BenefitCategory.DENTAL.value,
            removeDocs
          )
        );
      }
      if (selectedWeblink.length > 0) {
        await dispatch(
          updateWeblinks(selectedWeblink, planId, BenefitCategory.DENTAL.value)
        );
      }
      documentUploadSuccess();
    };

    return (
      <>
        <div className={styles.addPlanWrapper}>
          <Collapse
            activeKey={activePanel}
            bordered={false}
            accordion
            onChange={collapseOnChange}
            defaultActiveKey={PanelSection.BASIC_PLAN_INFO}
          >
            <Panel {...getPanelProps(PanelSection.BASIC_PLAN_INFO)}>
              <div className={styles.infoText}>
                This info is not available from the Benefit Summary. Please add
                details to finalize plan.
              </div>
              <DentalBasicPlanInfoWrapper
                ref={basicPlanInfoRef}
                onChange={handleBasicInfoChange}
                isCloseConfirmed={!isOpen}
                error={[requiredFieldError, setRequiredFieldError]}
                dbgPlanYear={dbgPlanYear}
                planAlreadyExists={planAlreadyExists}
                setPlanAlreadyExists={setPlanAlreadyExists}
                setAlertMessage={setAlertMessage}
              />
              <PanelFooter
                nextStep={createNextStepHandler(
                  basicPlanInfoRef,
                  PanelSection.BASIC_PLAN_INFO
                )}
                isDisable={isDisable || inProgress}
              />
            </Panel>
            <Panel {...getPanelProps(PanelSection.RATES)}>
              <div className={styles.infoText}>
                This info is not available from the Benefit Summary. Please add
                details to finalize plan.
              </div>
              <RatesUPWrapper
                ref={ratesRef}
                currentSection={activePanel}
                error={[requiredFieldError, setRequiredFieldError]}
                onChange={onChange}
              />
              <PanelFooter
                nextStep={createNextStepHandler(ratesRef, PanelSection.RATES)}
                isDisable={isDisable || inProgress}
              />
            </Panel>
            <Panel {...getPanelProps(PanelSection.DEDUCTIBLES_OOP_MAX)}>
              {isFromUpdatePan && <UpdatePlanParametersInfo />}
              <DeductiblesOOPMaxSBCWrapper
                wrappedRef={deductiblesRef}
                onChange={onChange}
                isTouched={PanelSection.DEDUCTIBLES_OOP_MAX === touchedSection}
              />
              <PanelFooter
                nextStep={createNextStepHandler(
                  deductiblesRef,
                  PanelSection.DEDUCTIBLES_OOP_MAX
                )}
                isDisable={isDisable || inProgress}
                buttonText={
                  planApproach === PlanApproach.SBC && !skipFailedDocument
                    ? 'Approve'
                    : undefined
                }
              />
              {showFeedbackPanel && (
                <AiFeedbackReceiver
                  planSection={
                    MDV_FEEDBACK_PANEL_TYPES[PanelSection.DEDUCTIBLES_OOP_MAX]
                  }
                  setFeedback={addAiFeedback}
                  removeFeedback={removeAiFeedback}
                />
              )}
            </Panel>
            <Panel {...getPanelProps(PanelSection.SERVICES)}>
              {isFromUpdatePan && <UpdatePlanParametersInfo />}
              <ServicesUPWrapper
                wrappedRef={servicesRef}
                onChange={onChange}
                isTouched={PanelSection.SERVICES === touchedSection}
              />
              <PanelFooter
                serviceName="Service"
                onAddBtnClicked={(e: any) =>
                  setIsAddCustomServiceModalOpen(true)
                }
                nextStep={createNextStepHandler(
                  servicesRef,
                  PanelSection.SERVICES
                )}
                isDisable={
                  isDisable || isReview || inProgress || disableOnUpdatePlan
                }
                disableServices={isOpsAdmin || inProgress}
                buttonText={
                  planApproach === PlanApproach.SBC && !skipFailedDocument
                    ? 'Approve'
                    : undefined
                }
              />
              {showFeedbackPanel && (
                <AiFeedbackReceiver
                  planSection={MDV_FEEDBACK_PANEL_TYPES[PanelSection.SERVICES]}
                  setFeedback={addAiFeedback}
                  removeFeedback={removeAiFeedback}
                />
              )}
              <AddCustomServiceModal
                planTypeName="Dental"
                benefitKind={BenefitCategory.DENTAL.value}
                visible={isAddCustomServiceModalOpen}
                displayedServices={displayedDentalServices}
                onClose={() => setIsAddCustomServiceModalOpen(false)}
                hsaCompatible={false}
                isAdvancedProfileEmployer={isAdvancedProfile}
              />
            </Panel>
            <Panel {...getPanelProps(PanelSection.ENROLLMENTS)}>
              <div className={styles.infoText}>
                This info is only necessary for renewals.
              </div>
              <AddEnrollments
                benefitKind={BenefitCategory.DENTAL.value}
                onChange={onChange}
                plan={dentalPlan}
                isEdit={false}
                ref={enrollmentsRef}
              />
              <PanelFooter
                nextStep={createNextStepHandler(
                  enrollmentsRef,
                  PanelSection.ENROLLMENTS
                )}
                isDisable={isDisable || inProgress}
              />
            </Panel>
            <Panel {...getPanelProps(PanelSection.PLAN_DOCUMENTS)}>
              <PlanDocumentUPWrapper
                wrappedRef={documentsRef}
                onChange={onChange}
                onValidateFails={onValidateFails}
                isDisable={isDisable || inProgress}
              />
              <AdditionalPlanResources
                ref={ref}
                plan={plan}
                benefitKind={BenefitCategory.MEDICAL.value}
                isCloseConfirmed={false}
                selectedFileList={selectedFileList}
                setSelectedFileList={setSelectedFileList}
                selectedWebLinkList={selectedWeblink}
                setSelectedWebLinkList={setSelectedWeblink}
                setIsDocRemoved={setIsDocRemoved}
              />
              <PanelFooter
                showLoadingIcon={isDocumentUploading || inProgress}
                nextStep={createNextStepHandler(
                  documentsRef,
                  PanelSection.PLAN_DOCUMENTS
                )}
                isDisable={isDisable || inProgress}
                planName={editedDentalPlan?.name ?? ''}
                isRenewalProcessStarted={isRenewalProcessStarted}
              />
            </Panel>
          </Collapse>
        </div>
        <NextButton
          type="primary"
          buttonText={
            <>
              {(documentUploadInProgress || inProgress) && <Spin />}
              Complete and Save Plan {isAnUpdateOfferedPlan ? 'Parameters' : ''}
            </>
          }
          className={styles.completeButton}
          disabled={isSaveDisabled}
          nextStep={handleNextStep}
        />
        {!isEmpty(docExtractionSource) && (
          <PlanUpdateWarningModal
            type={docExtractionSource?.source as UpdateDocumentExtractionSource}
            isLoading={false}
            isOpen={showUpdatePlanWarning}
            onClose={() => setShowUpdatePlanWarning(false)}
            onConfirm={savePlan}
            confirmText="Continue"
          />
        )}
      </>
    );
  }
);

AddDentalPlanDialog.displayName = 'AddDentalPlanDialog';

export default AddDentalPlanDialog;
