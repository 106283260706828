import { FC, useCallback, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

import { LOGIN_PATH } from 'modules/auth/routes';
import { initGTM } from 'api/initGTM';
import { initGA4 } from 'api/initGA4';
import { useGAPageViews } from 'hooks/useGAPageView';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { resetRefreshRequirement } from 'layout/slices/layoutSlice';
import { ACTIVITY_LOGGING_CONTEXT } from 'util/userActivityUtilis';

const LayoutWrapper: FC = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isRefreshRequired: boolean = useAppSelector(
    (state) => state.layout?.sessionExpiryDto?.isRefreshRequired
  );

  useEffect(() => {
    initGTM();
    initGA4();
  }, []);

  const configureInterceptor = useCallback(() => {
    const interceptor = axios.interceptors.response.use(
      (res) => {
        // TODO need to exclude log session data API call
        if (
          isRefreshRequired &&
          !ACTIVITY_LOGGING_CONTEXT.includes(res.request?.responseURL)
        ) {
          dispatch(resetRefreshRequirement());
        }
        return res;
      },
      (error: AxiosError) => {
        const { response } = error;
        if (response?.status === 401) {
          dispatch(resetRefreshRequirement());
          navigate(LOGIN_PATH, { state: { from: location } });
        }
        return Promise.reject(error);
      }
    );
    // Clean up the interceptor on component unmount
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshRequired]);

  useEffect(() => {
    return configureInterceptor();
  }, [configureInterceptor]);

  useGAPageViews();
  return <>{children}</>;
};

export default LayoutWrapper;
