import { FC } from 'react';
import { Col, Row } from 'antd';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import styles from './contact.module.less';

type ContactProps = {
  contactName: string;
  avatarUrl?: string;
  email: string;
  title?: string;
  contactTitle?: string;
};

const Contact: FC<ContactProps> = (props) => {
  const { contactName, avatarUrl, email, title, contactTitle } = props;
  const emailApplication = `mailto:${email}`;

  return (
    <div className={styles.contact}>
      {contactTitle && <p className={styles.contactTitle}>{contactTitle}</p>}
      <Row className={styles.rowWrapper}>
        <Col xs="2">
          <ProfileAvatar content={contactName} src={avatarUrl} />
        </Col>
        <Col xs="4" className={styles.nameColStyle}>
          <Row className={`${styles.nameWrapper} text-ellipsis`}>
            <OverflowPopover>{contactName}</OverflowPopover>
          </Row>
          <Row className={styles.titleWrapper}>
            <OverflowPopover>{title}</OverflowPopover>
          </Row>
          <Row>
            <a
              className={styles.emailWrapper}
              title={email}
              href={emailApplication}
            >
              {email}
            </a>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Contact;
