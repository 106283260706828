import { Table } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import moveIcon from 'assets/images/icon-move.svg';
import styles from 'modules/employers/components/SelectedCarrierContactsTable/SelectedCarrierContactsTable.module.less';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

type Props = {
  data: any[];
  updateSelectedCarrierContacts?: (contact: any) => void;
  updateSelectedCarrierContactListOrder?: (contacts: any[]) => void;
};

const SelectedCarrierContactsTable: FC<Props> = (props: Props) => {
  const {
    data,
    updateSelectedCarrierContacts,
    updateSelectedCarrierContactListOrder,
  } = props;

  const [selectedCarrierContactList, setSelectedCarrierContactList] = useState<
    any[]
  >([]);
  // eslint-disable-next-line
  useEffect(() => {
    setSelectedCarrierContactList(data);
  }, [data]);
  // eslint-disable-next-line
  const DragHandle = SortableHandle(() => (
    <img src={moveIcon} alt="move-icon" className={styles.moveIcon} />
  ));

  const teamMembersColumns: any = [
    {
      title: 'Selected Carrier Contact Name',
      dataIndex: 'fullName',
      key: 'fullName',
      width: '80%',
      render: (_: any, record: any) => {
        return (
          <div
            className={styles.carrierContactNameText}
            onClick={() => {
              updateSelectedCarrierContacts?.(record);
            }}
          >
            <OverflowPopover maxWidth="200px">
              {record.fullName}
            </OverflowPopover>
            <div className={styles.carrierContactCarrierText}>
              <OverflowPopover maxWidth="200px">
                {` - ${record.associatedCarrierName}`}
              </OverflowPopover>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Selected Carrier Contact Name',
      dataIndex: 'fullName',
      key: 'fullName',
      align: 'right',
      ellipsis: {
        showTitle: false,
      },
      render: () => {
        return <DragHandle />;
      },
    },
  ];

  const DraggableBodyRow = ({ ...restProps }) => {
    const index = selectedCarrierContactList.findIndex(
      (x) => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };
  // eslint-disable-next-line
  const SortableItem = SortableElement((props: any) => <tr {...props} />);

  // eslint-disable-next-line
  const SortableBody = SortableContainer((props: any) => <tbody {...props} />);

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        selectedCarrierContactList,
        oldIndex,
        newIndex
      );
      setSelectedCarrierContactList(newData);
      updateSelectedCarrierContactListOrder &&
        updateSelectedCarrierContactListOrder(newData);
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableBody
      useDragHandle
      onSortEnd={onSortEnd}
      disableAutoscroll
      helperClass="rowDragging"
      {...props}
    />
  );

  return (
    <Table
      className={styles.selectedTable}
      showHeader={false}
      pagination={false}
      columns={teamMembersColumns}
      dataSource={selectedCarrierContactList}
      scroll={{ y: 300 }}
      rowKey="index"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};

export default SelectedCarrierContactsTable;
