import { FC, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  AI_FEEDBACK_MODAL_DESCRIPTION,
  AI_FEEDBACK_MODAL_TITLE,
  AI_FEEDBACK_RATE_AI_TEXT,
  AI_FEEDBACK_TEXT_FEEDBACK_TEXT,
  AI_FEEDBACK_THUMBS_UP_TEXT,
  NEGETIVE_FEEDBACK,
  POSITIVE_FEEDBACK,
} from 'modules/plans/constants';
import { useAppSelector } from 'hooks/redux';
import { ReactComponent as ThumbsUpNotSelected } from 'assets/images/sbc-ai-feedback-response-thumbs-up-not-selected.svg';
import { ReactComponent as ThumbsUpSelected } from 'assets/images/sbc-ai-feedback-thumbs-up-active.svg';
import { ReactComponent as ThumbsDown } from 'assets/images/sbc-ai-feedback-thumbs-down-not-selected.svg';
import { ReactComponent as ThumbsDownSelected } from 'assets/images/sbc-ai-feedback-thumbs-down-active.svg';
import { AIUploaderFeedback } from 'model/plans/UserFeedback';
import styles from './aiFeedbackReceiver.module.less';

type AiFeedbackReceiverProps = {
  planSection: string;
  setFeedback: (feedbackObject: AIUploaderFeedback) => void;
  removeFeedback: (feedbackPanel: string) => void;
};

const AiFeedbackReceiver: FC<AiFeedbackReceiverProps> = ({
  planSection,
  setFeedback,
  removeFeedback,
}) => {
  const { skipFailedDocument } = useAppSelector((state) => state.plan.aiSbc);
  const [isFeedbackTextModalVisible, setIsFeedbackTextModalVisible] =
    useState<boolean>(false);
  const [aiTextFeedback, setAiTextFeedback] = useState<string>('');
  const [feedbackStatus, setFeedbackStatus] = useState<string | null>(null);
  const [isFeedbackGiven, setIsFeedbackGiven] = useState<boolean>(false);

  const onClickItem = (inputFeedbackType: string) => {
    const feedbackObject = {
      feedbackType: inputFeedbackType,
      section: planSection,
      feedback: '',
    };

    if (!feedbackStatus) {
      setFeedbackStatus(inputFeedbackType);
      setFeedback(feedbackObject);
    } else if (inputFeedbackType === feedbackStatus) {
      resetFeedback();
      removeFeedback(planSection);
    } else {
      resetFeedback();
      removeFeedback(planSection);
      setFeedbackStatus(inputFeedbackType);
      setFeedback(feedbackObject);
    }
  };

  const viewFeedbackText = () => {
    setIsFeedbackTextModalVisible(true);
  };

  const onCloseFeedback = () => {
    setAiTextFeedback('');
    setIsFeedbackTextModalVisible(false);
  };

  const onConfirmFeedback = () => {
    setIsFeedbackGiven(true);
    setFeedback({
      feedbackType: NEGETIVE_FEEDBACK,
      section: planSection,
      feedback: aiTextFeedback,
    });
    setIsFeedbackTextModalVisible(false);
  };

  const resetFeedback = () => {
    setFeedbackStatus(null);
    setAiTextFeedback('');
    setIsFeedbackGiven(false);
  };

  const thumbsUpIcon =
    feedbackStatus === POSITIVE_FEEDBACK ? (
      <ThumbsUpSelected
        className={styles.cursorPointer}
        onClick={() => onClickItem(POSITIVE_FEEDBACK)}
      />
    ) : (
      <ThumbsUpNotSelected
        className={styles.cursorPointer}
        onClick={() => onClickItem(POSITIVE_FEEDBACK)}
      />
    );

  const thumbsDownIcon =
    feedbackStatus === NEGETIVE_FEEDBACK ? (
      <ThumbsDownSelected
        className={styles.cursorPointer}
        onClick={() => onClickItem(NEGETIVE_FEEDBACK)}
      />
    ) : (
      <ThumbsDown
        className={styles.cursorPointer}
        onClick={() => onClickItem(NEGETIVE_FEEDBACK)}
      />
    );

  const thumbsUpFeedbackText = (
    <div className={styles.thumbsUpFeedbackText}>
      {AI_FEEDBACK_THUMBS_UP_TEXT}
    </div>
  );

  const textFeedbackLinkText = (
    <div className={styles.textFeedbackLinkText}>
      <span onClick={viewFeedbackText}>{AI_FEEDBACK_TEXT_FEEDBACK_TEXT}</span>
    </div>
  );

  if (!skipFailedDocument) {
    return (
      <div className={styles.add}>
        <div className={styles.feedbackInput}>
          <div className={styles.rateResponseTag}>
            {AI_FEEDBACK_RATE_AI_TEXT}
          </div>
          <div className={styles.feedbackIconsWrapper}>
            {thumbsUpIcon}
            {thumbsDownIcon}
          </div>
        </div>
        <div>
          {(feedbackStatus === POSITIVE_FEEDBACK || isFeedbackGiven) &&
            thumbsUpFeedbackText}
          {feedbackStatus === NEGETIVE_FEEDBACK &&
            !isFeedbackTextModalVisible &&
            !isFeedbackGiven &&
            textFeedbackLinkText}
        </div>

        <ConfirmationDialog
          title={AI_FEEDBACK_MODAL_TITLE}
          confirmText="Send Feedback"
          cancelText="Cancel"
          isCancelLink
          visible={isFeedbackTextModalVisible}
          onCancel={onCloseFeedback}
          onConfirm={onConfirmFeedback}
          closeModal={onCloseFeedback}
          width={464}
        >
          <div className={styles.wrapperContainer}>
            <p>{AI_FEEDBACK_MODAL_DESCRIPTION}</p>
            <TextArea
              value={aiTextFeedback}
              onChange={(e) => setAiTextFeedback(e.target.value)}
              rows={6}
            />
          </div>
        </ConfirmationDialog>
      </div>
    );
  }
  return <></>;
};

export default AiFeedbackReceiver;
