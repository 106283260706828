import { combineReducers } from '@reduxjs/toolkit';

import BrokerLevelBCReducer from 'modules/benefitsConsultation/slices/brokerLevelSlice';
import EmployerLevelBCReducer from 'modules/benefitsConsultation/slices/employerLevelSlice';
import CommonBCReducer from 'modules/benefitsConsultation/slices/commonSlice';

const BenefitsConsultationRootReducer = combineReducers({
  brokerLevel: BrokerLevelBCReducer,
  employerLevel: EmployerLevelBCReducer,
  common: CommonBCReducer,
});

export default BenefitsConsultationRootReducer;
