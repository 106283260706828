import FundingType from 'modules/plans/enums/FundingType';
import styles from './fundingTypeTag.module.less';

export const FundingTypeTag = (props: {
  className?: string;
  fundingType?: string;
}) => {
  const { className, fundingType } = props;
  return (
    <div
      className={`${
        fundingType === FundingType.SELF_FUNDED
          ? styles.selfFunded
          : fundingType === FundingType.LEVEL_FUNDED
          ? styles.levelFunded
          : styles.fullyInsured
      } ${className}`}
    >
      {fundingType === FundingType.SELF_FUNDED
        ? 'SELF-FUNDED'
        : fundingType === FundingType.LEVEL_FUNDED
        ? 'LEVEL-FUNDED'
        : 'FULLY-INSURED'}
    </div>
  );
};
