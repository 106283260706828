/* eslint-disable no-unused-vars */
enum PanelSection {
  BASIC_PLAN_INFO = 'basicPlanInfo',
  RATES = 'rates',
  DEDUCTIBLES_OOP_MAX = 'deductiblesOopMax',
  SERVICES = 'services',
  RX = 'rx',
  ENROLLMENTS = 'enrollments',
  PLAN_DOCUMENTS = 'planDocuments',
  ID_CARD_INFO = 'idCardInfo',
  PLAN_INFORMATION = 'planInformation',
  ADDITIONAL_RESOURCES = 'additionalResources',
}

export default PanelSection;
