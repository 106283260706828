import { useGetConfigurationStatusQuery } from 'api/navigationSlice';

const useGetUserFeatureStatus = (
  brokerId: any,
  employerId: string | null = null,
  featureKey: string,
  defaultState: boolean
) => {
  const { currentData } = useGetConfigurationStatusQuery(
    {
      brokerId: brokerId,
      employerId: employerId,
    },
    { refetchOnMountOrArgChange: true }
  );

  const data = currentData;
  const { features } = data || {};
  if (features) {
    const featureSetting = features.find(
      (feature) => feature.feature === featureKey
    );

    return featureSetting?.enabled;
  }

  return defaultState;
};

export default useGetUserFeatureStatus;
