import React from 'react';
import * as _ from 'lodash';

import styles from './legendLabel.module.less';

type Props = {
  className?: { wrapperClass: string; headerClass: string };
  title: string;
  prefixShape: any;
};

const LegendLabel = (props: Props) => {
  const {
    prefixShape,
    title,
    className = { wrapperClass: '', headerClass: '' },
  } = props;
  const { circle, color, width, height } = prefixShape;

  return (
    <div className={`${styles.legendLabel} ${className.wrapperClass}`}>
      {color && (
        <div
          className={circle ? styles.circle : styles.square}
          style={
            prefixShape && {
              position: 'relative',
              backgroundColor: color,
              width: width,
              height: height,
            }
          }
        />
      )}
      <span
        className={`header-title ${className.headerClass} ${
          _.isEmpty(color) ? 'label-only' : ''
        }`}
      >
        {title}
      </span>
    </div>
  );
};

export default LegendLabel;
