import { FC, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import Rates from 'modules/plans/components/Rates/Rates';
import { getBenefitGuidesByFrequency } from 'modules/plans/slices/dbgInfoSlice';
import { BenefitCategory } from 'constants/commonConstants';
import { DentalPlan } from 'model/plans/DentalPlan';
import {
  getPlanContributionByFrequency,
  resetDentalPlanReduxStore,
  updateDentalPlan,
  updateDentalPlanByPath,
  getPlanContributionInProgress,
} from 'modules/plans/slices/dentalPlanSlice';
import PanelSection from 'modules/plans/enums/PanelSection';

type RatesDBGWrapperProps = {
  currentSection: string;
  dentalPlan: DentalPlan;
  dbgMetaData: any;
};

const RatesDBGDentalWrapper: FC<RatesDBGWrapperProps> = forwardRef(
  (props: RatesDBGWrapperProps, refs) => {
    const { editedDentalPlan: dentalPlanState } = useAppSelector(
      (state) => state.plan.dentalPlan
    );
    const requestTypePlans = useAppSelector(
      (state) => state.plan.dentalPlan.requestType
    );
    const { dbgData, requestType } = useAppSelector(
      (state) => state.plan.dgbInfo
    );
    const { dbgMetaData, dentalPlan } = props;
    const [ratesForm] = Form.useForm();
    const dispatch = useAppDispatch();
    const { employerId } = useNavContext();
    const { dbgByFrequency, rateValidations } = dbgData;
    const ratesRef = useRef<any>();

    useImperativeHandle(refs, () => ({
      reset() {
        dispatch(resetDentalPlanReduxStore());
        ratesRef?.current?.resetAll();
      },
    }));

    useEffect(() => {
      employerId &&
        dentalPlan.id &&
        dispatch(
          getBenefitGuidesByFrequency(
            employerId,
            dentalPlan.planYearId,
            BenefitCategory.DENTAL.value,
            dentalPlan.id
          )
        );
      dispatch(updateDentalPlan(dentalPlan));
    }, [dispatch, employerId, dentalPlan]);

    if (isEmpty(dentalPlanState)) {
      return <div></div>;
    }

    return (
      <Rates
        benefitCategory={BenefitCategory.DENTAL.value}
        updatePlanByPathAction={updateDentalPlanByPath}
        getContributionAction={getPlanContributionByFrequency}
        benefitGuideObj={dbgMetaData}
        plan={dentalPlanState}
        form={ratesForm}
        currentSection={PanelSection.RATES}
        dbgByFrequency={dbgByFrequency}
        isDBGView={true}
        ref={ratesRef}
        requestType={requestType}
        rateValidations={rateValidations}
        getContributionInProgress={
          requestTypePlans === getPlanContributionInProgress.type
        }
      />
    );
  }
);
RatesDBGDentalWrapper.displayName = 'RatesDBGDentalWrapper';
export default RatesDBGDentalWrapper;
