import React, { FC } from 'react';
import { Spin } from 'antd';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import styles from './LoadingScreen.module.less';

type Props = {
  saveReturnOnclick: () => void;
};

const LoadingScreen: FC<Props> = (props: Props) => {
  const { saveReturnOnclick } = props;
  return (
    <div className={styles.loadingScreenWrapper}>
      <div className={styles.screenWrapper}>
        <Spin />
        <b>Generating New Responses ...</b>
        <LinkButton onClick={saveReturnOnclick}>
          Save and return later
        </LinkButton>
      </div>
    </div>
  );
};

export default LoadingScreen;
