import { FC } from 'react';
import { Avatar, AvatarProps } from 'antd';

import isEmpty from 'lodash/isEmpty';
import { getAvatarName } from 'util/stringUtil';

import styles from './profileAvatar.module.less';

interface ProfileAvatarProps extends AvatarProps {
  content: string;
  className?: string;
}

const ProfileAvatar: FC<ProfileAvatarProps> = ({
  content,
  className,
  ...rest
}) => {
  return (
    <Avatar
      {...rest}
      className={`${rest && isEmpty(rest.src) ? styles.avatarContent : ''} ${
        className ? className : styles.avatarStyle
      }`}
    >
      {getAvatarName(content)}
    </Avatar>
  );
};

export default ProfileAvatar;
