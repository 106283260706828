import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import AuthRootReducer from 'modules/auth/slices/authRoot';
import BrokerRootReducer from 'modules/brokers/slices/brokerRoot';
import PlanReducer from 'modules/plans/slices/planRoot';
import AdminRootReducer from 'modules/admins/slices/adminRoot';
import EmployerRootReducer from 'modules/employers/slices/employerRoot';
import LayoutReducer from 'layout/slices/layoutSlice';
import BenguideRootReducer from 'modules/benefitGuide/slices/benguideRoot';
import ClientReducer from 'modules/clients/slices/clientSlice';
import CarrierReducer from 'modules/carriers/slices/carrierSlice';
import InAppNotificationReducer from 'modules/communication/slices/inAppNotificationSlice';
import BenefitsConsultationRootReducer from 'modules/benefitsConsultation/slices/benefitsConsultationRoot';
import DocumentsReducer from 'modules/documents/slices/doucmentSlice';
import ChatBotReducer from 'modules/chatbot/slices/chatbotSlice';
import getPlanRecommenderReducer from 'modules/plans/slices/planRecommenderSllice';
import issuesLogFilterReducer from 'modules/issueslog/slices/issuesLogFilterSlice';
import { lumityApi } from 'api/apiSlice';
import RenewalRootReducer from 'modules/renewals/slices/renewalsRootSlice';
import ProposalCreateStepperSlice from 'modules/renewals/slices/proposalCreateStepperSlice';
import OpenOfferFilterReducer from 'modules/renewals/slices/openOfferFiltersSlice';
import rfpReducer from 'modules/rfp/slices/rfpQuestionSlice';
import IdCardsReducer from 'modules/idCards/slices/idCardSlice';
import AssistantReducer from 'modules/assistant/slices/assistantReducer';

export const store = configureStore({
  reducer: {
    auth: AuthRootReducer,
    brokers: BrokerRootReducer,
    plan: PlanReducer,
    employer: EmployerRootReducer,
    admins: AdminRootReducer,
    layout: LayoutReducer,
    benguide: BenguideRootReducer,
    client: ClientReducer,
    carriers: CarrierReducer,
    inAppNotifications: InAppNotificationReducer,
    benefitsConsultation: BenefitsConsultationRootReducer,
    documents: DocumentsReducer,
    planRecommender: getPlanRecommenderReducer,
    [lumityApi.reducerPath]: lumityApi.reducer,
    filters: issuesLogFilterReducer,
    renewals: RenewalRootReducer,
    proposalOnBoarding: ProposalCreateStepperSlice,
    openOfferFilters: OpenOfferFilterReducer,
    idCards: IdCardsReducer,
    rfp: rfpReducer,
    assistant: AssistantReducer,
    chatBot: ChatBotReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
