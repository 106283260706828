import React from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { getCreditIncludedExcludedText } from 'modules/renewals/utils/renewalsUtils';

import styles from './creditIncludedExcludedModal.module.less';

type Props = {
  type: any;
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const CreditIncludedExcludedModal = (props: Props) => {
  const { type, visible, onClose, onConfirm } = props;
  return (
    <ConfirmationDialog
      title={getCreditIncludedExcludedText(type).title}
      visible={visible}
      confirmText={getCreditIncludedExcludedText(type).saveBtnText}
      onConfirm={onConfirm}
      closeModal={onClose}
      cancelText="Cancel"
      isCancelLink={true}
      centered={true}
      width={582}
      confirmBtnFullWidth={true}
    >
      <div className={styles.contentWrapper}>
        <p>{getCreditIncludedExcludedText(type).content}</p>
      </div>
    </ConfirmationDialog>
  );
};

export default React.memo(CreditIncludedExcludedModal);
