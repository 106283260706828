import axios from 'axios';
import { BenefitGuideCloneRequest } from 'model/BenefitGuideCloneRequest';
import { Secured } from 'model/BenguideBasicData';
import EmployerName from 'model/EmployerName';
import { baseApi } from 'util/apiUtil';

const v2Url = baseApi + '/v2/benefit-guides';

export const deleteBenguide = (benguideId: string) => {
  return axios.delete(`${v2Url}/${benguideId}`);
};

export const moveToInProgress = (id: string) => {
  return axios.post(`${v2Url}/${id}/in-progress`);
};

export const getPreviewToken = (id: string) => {
  return axios.post(`${v2Url}/${id}/preview`);
};

export const getPassword = (id: string) => {
  return axios.get(`${v2Url}/${id}/secure`);
};

export const savePassword = (id: string, secure: Secured) => {
  return axios.put(`${v2Url}/${id}/secure`, secure);
};

export const cloneBenguide = (
  id: string,
  request: BenefitGuideCloneRequest
) => {
  return axios.post(`${v2Url}/${id}/clone`, {
    ...request,
    benefitClasses: request?.benefitClasses ?? [],
  });
};

export const populatePlans = (id: string, fromDBGId: string) => {
  return axios.put(`${v2Url}/${id}/populate-plans`, {
    originalBenefitGuideId: fromDBGId,
  });
};

export const enableEditMode = async (id: string) => {
  return await axios.post(`${v2Url}/${id}/edit-mode`);
};

export const getPreviewTokenWithBenefit = (id: string, benefitKind: string) => {
  return axios.post(
    `${v2Url}/${id}/preview-benefit?benefitKind=${benefitKind}`
  );
};

export const updateEmployerName = (id: string, employerName: EmployerName) => {
  return axios.put(`${v2Url}/${id}/employer-name`, { ...employerName });
};
