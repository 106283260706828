import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import Drawer from 'components/Drawer/Drawer';

import PlanTable from 'modules/renewals/components/PlanTable/PlanTable';
import RenewalCommonSubHeader from 'modules/renewals/components/helperComponents/RenewalCommonSubHeader/RenewalCommonSubHeader';
import {
  IN_NETWORK_VALUE,
  MDV_PLANS,
  NAME_FIELD_PROPERTY,
  OFFER_SINGLE_PLAN_PROPERTY,
  PROPOSAL_DETAILED_RENEWAL_TYPES,
} from 'modules/renewals/constants/renewalsConstants';
import { getPlanTableColumnLabels } from 'modules/renewals/utils/planTableLabelUtil';
import { useLazyGetPlanDataQuery } from 'modules/renewals/slices/proposalSlice';

import styles from './proposalOfferDetailSideBar.module.less';

type Props = {
  offerData?: any;
  visible: boolean;
  onClose: () => void;
};

const ProposalOfferDetailSideBar = (props: Props) => {
  const { visible, onClose, offerData } = props;
  const { renewalsType = '' } = useParams();

  const [
    getPlanData,
    {
      data: planDetailsData,
      isFetching: planDetailsIsFetching,
      isError: planDetailsIsError,
    },
  ] = useLazyGetPlanDataQuery();

  const [selectedNetworkType, setSelectedNetworkType] =
    useState<string>(IN_NETWORK_VALUE);

  useEffect(() => {
    setSelectedNetworkType(IN_NETWORK_VALUE);
    if (offerData !== undefined && visible) {
      getPlanData({
        planId: offerData?.planId ?? '',
        benefitCategory: MDV_PLANS.includes(renewalsType.toUpperCase())
          ? renewalsType.toUpperCase()
          : PROPOSAL_DETAILED_RENEWAL_TYPES?.[
              offerData?.planType.toLowerCase()
            ],
      });
    }
    // eslint-disable-next-line
  }, [offerData, visible]);

  const arrangeColData = useMemo(() => {
    return getPlanTableColumnLabels({
      benefitType: MDV_PLANS.includes(renewalsType.toUpperCase())
        ? renewalsType.toUpperCase()
        : PROPOSAL_DETAILED_RENEWAL_TYPES?.[offerData?.planType.toLowerCase()],
      plans: [
        planDetailsData?.[
          OFFER_SINGLE_PLAN_PROPERTY?.[renewalsType?.toUpperCase()]
        ],
      ],
      fundingType:
        planDetailsData?.[
          OFFER_SINGLE_PLAN_PROPERTY?.[renewalsType?.toUpperCase()]
        ]?.fundingType,
      isPlanDetails: true,
      isPlanDetailSidebar: true,
    });
    // eslint-disable-next-line
  }, [planDetailsData, selectedNetworkType]);

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      closable={false}
      width={550}
      bodyStyle={{ padding: '40px 34px' }}
    >
      {planDetailsIsFetching || planDetailsIsError ? (
        <Spin />
      ) : (
        <>
          <RenewalCommonSubHeader subText={'Plan Details'} />
          <div className={styles.contentWrapper}>
            <div className={styles.tableWrapper}>
              <PlanTable
                data={[
                  planDetailsData?.[
                    OFFER_SINGLE_PLAN_PROPERTY?.[renewalsType?.toUpperCase()]
                  ],
                ]}
                labelColumnData={arrangeColData}
                titleProperty={NAME_FIELD_PROPERTY}
                logoProperty={offerData?.carrierLogoUrl}
                benefitType={
                  MDV_PLANS.includes(renewalsType.toUpperCase())
                    ? renewalsType.toUpperCase()
                    : PROPOSAL_DETAILED_RENEWAL_TYPES?.[
                        offerData?.planType.toLowerCase()
                      ]
                }
                showEndLine={true}
                fixedWidth={true}
                isPlanDetailSidebar={true}
              />
            </div>
          </div>
        </>
      )}
    </Drawer>
  );
};

export default ProposalOfferDetailSideBar;
