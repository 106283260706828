import { FC, ReactNode } from 'react';
import { Col, Row } from 'antd';

import styles from './carrierInfoLabel.module.less';

type CarrierInfoLabelPropTypes = {
  labelText: string;
  value: ReactNode;
};

const CarrierInfoLabel: FC<CarrierInfoLabelPropTypes> = (
  props: CarrierInfoLabelPropTypes
) => {
  const { labelText, value } = props;
  return (
    <Row className={styles.infoLabelText}>
      <Col span={4}>
        <span>{labelText}</span>
      </Col>
      <Col span={20} className={styles.infoValueSection}>
        {value}
      </Col>
    </Row>
  );
};

export default CarrierInfoLabel;
