import { ChangeEvent, FC, useEffect, useState } from 'react';

import { Row, Switch, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import isEmpty from 'lodash/isEmpty';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useStateCallback } from 'hooks/updateState';
import SearchBar from 'components/SearchBar/SearchBar';
import {
  addTeamMember,
  clearManagingTeamMember,
  removeTeamMember,
} from 'modules/admins/slices/brokerAdminSlice';
import { buildFullName, isContainsDataInArray } from 'util/stringUtil';
import { NOT_ALLOWED_TO_REMOVE } from 'modules/admins/constants/adminErrorConstants';
import AlertMessage from 'components/Alert/AlertMessage';
import TeamMember from 'model/TeamMember';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import {
  getAccessibleTeamMembers,
  updateTeamMembers,
} from 'modules/employers/slices/employerSlice';
import EmployerAccessibleTeam from 'model/EmployerAccessibleTeam';

import styles from './addBrokerAdmin.module.less';

type AddBrokerAdminProps = {
  employerId: string;
  isModalOpen: boolean;
  setIsModalOpen: Function;
  accessibleTeamMembers: TeamMember[];
};

const AddBrokerAdmin: FC<AddBrokerAdminProps> = (
  props: AddBrokerAdminProps
) => {
  const { isModalOpen, employerId, setIsModalOpen, accessibleTeamMembers } =
    props;

  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [teamMembersCount, setTeamMembersCount] = useState<number>(0);
  const [teamMembers, setTeamMembers] = useStateCallback(accessibleTeamMembers);
  const [searchText, setSearchText] = useState('');
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [minCount, setMinCount] = useState<boolean>(true);

  const [selectedTeamMember, setSelectedTeamMember] =
    useState<TeamMember | null>(null);
  const dispatch = useAppDispatch();

  const removeTeamMemberError = useAppSelector(
    (state) => state.admins.brokerAdmins.removeMemberError
  );

  const addMemberSuccess = useAppSelector(
    (state) => state.admins.brokerAdmins.addMemberCompleted
  );

  const removeMemberSuccess = useAppSelector(
    (state) => state.admins.brokerAdmins.removeMemberCompleted
  );

  const getAssignedAdminCount = (teamMembers: TeamMember[]) => {
    return teamMembers.filter((brokerAdmin: TeamMember) => brokerAdmin.assigned)
      .length;
  };

  const getAssignedAdminId = (teamMembers: TeamMember[]) => {
    return teamMembers.map((brokerAdmin: TeamMember) => {
      if (brokerAdmin.assigned === true) {
        return brokerAdmin.id;
      }
    });
  };

  useEffect(() => {
    if (employerId) {
      if (searchText) {
        const filteredMembers = searchTeamMembers(
          searchText,
          accessibleTeamMembers
        );
        setTeamMembers(filteredMembers);
      } else {
        setTeamMembers(accessibleTeamMembers);
      }
      setTeamMembersCount(getAssignedAdminCount(accessibleTeamMembers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerId, accessibleTeamMembers, searchText]);

  useEffect(() => {
    if (errorOccurred) {
      setErrorOccurred(false);
      dispatch(getAccessibleTeamMembers(employerId));
      setTeamMembersCount(getAssignedAdminCount(accessibleTeamMembers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorOccurred, employerId, accessibleTeamMembers]);

  useEffect(() => {
    if (
      removeTeamMemberError?.data &&
      removeTeamMemberError.data.code === NOT_ALLOWED_TO_REMOVE
    ) {
      if (removeTeamMemberError !== null) {
        setIsAlertVisible(true);
      }
    }
  }, [removeTeamMemberError, removeTeamMemberError?.data]);

  useEffect(() => {
    setIsAlertVisible(teamMembersCount === 0);
    setMinCount(teamMembersCount === 1);
  }, [teamMembersCount]);

  useEffect(() => {
    if (!removeTeamMemberError) {
      setErrorOccurred(false);
    }
  }, [removeTeamMemberError]);

  useEffect(() => {
    if ((addMemberSuccess || removeMemberSuccess) && selectedTeamMember) {
      const { id, assigned } = selectedTeamMember;
      const updatedMembers = teamMembers.map((member: TeamMember) => {
        if (member.id == id) {
          return { ...member, assigned };
        }
        return member;
      });
      dispatch(clearManagingTeamMember());
      setSelectedTeamMember(null);
      setTeamMembers(updatedMembers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMembers, addMemberSuccess, selectedTeamMember, removeMemberSuccess]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = e.target;
    const _searchText = value.trim().toLowerCase();
    setSearchText(_searchText);
  };

  const searchTeamMembers = (
    _searchText: string,
    data: TeamMember[]
  ): TeamMember[] => {
    const searchWorkArray = _searchText.split(' ');
    const assignedTeamMembers =
      data &&
      data.filter((teamMember) => {
        return teamMember.assigned;
      });
    const filteredMembers =
      data &&
      data.filter((tm) => {
        return (
          tm.assigned === false &&
          (isContainsDataInArray(searchWorkArray, tm.firstName) ||
            isContainsDataInArray(searchWorkArray, tm.lastName))
        );
      });
    return assignedTeamMembers.concat(filteredMembers);
  };

  const teamMembersColumns = [
    {
      title: 'Broker Admin Name',
      dataIndex: 'name',
      key: 'name',
      width: '50%',
      ellipsis: {
        showTitle: false,
      },
    },
  ];

  const handleTeamMembers = (
    checked: boolean,
    brokerAdminId: string,
    firstName: string,
    lastName: string,
    email: string
  ) => {
    const temp = getAssignedAdminId(accessibleTeamMembers).toString();

    if (checked === false && minCount === true) {
      setIsAlertVisible(true);
    }
    const updatedTeamMembers = accessibleTeamMembers.map((value) => {
      if (value.id === brokerAdminId) {
        return {
          ...value,
          assigned: checked,
        };
      } else {
        return value;
      }
    });
    const employer = {
      id: employerId,
      accessibleTeamMembers: updatedTeamMembers,
    } as EmployerAccessibleTeam;

    if (!temp.includes(brokerAdminId) || minCount === false) {
      if (checked) {
        dispatch(addTeamMember(employerId, brokerAdminId));
        dispatch(updateTeamMembers(employer));

        setTeamMembersCount(getAssignedAdminCount(updatedTeamMembers));
        setSelectedTeamMember({
          id: brokerAdminId,
          firstName,
          lastName,
          assigned: checked,
          email,
        });
      } else {
        dispatch(removeTeamMember(employerId, brokerAdminId));
        dispatch(updateTeamMembers(employer));

        setTeamMembersCount(getAssignedAdminCount(updatedTeamMembers));
        setSelectedTeamMember({
          id: brokerAdminId,
          firstName,
          lastName,
          assigned: checked,
          email,
        });
      }
    }
  };

  const isAssignedAdmin = (memberId: string) => {
    return teamMembers.filter((member: TeamMember) => member.id === memberId)[0]
      .assigned;
  };

  const getTeamMembers = () => {
    return teamMembers
      .map((item: TeamMember) => ({
        id: item.id,
        name: buildFullName(item.firstName, item.lastName),
        firstName: item.firstName,
        lastName: item.lastName,
        assigned: item.assigned,
        email: item.email,
      }))
      .sort(
        (a: any, b: any) =>
          b.assigned - a.assigned || a.name.localeCompare(b.name)
      );
  };

  const getColumnData = (
    accessibleMember: any,
    previousMember: TeamMember,
    nextMember: TeamMember
  ) => {
    const { id, firstName, lastName, email } = accessibleMember;
    return (
      <div
        className={
          ((!isEmpty(previousMember) && previousMember?.assigned) ||
            isEmpty(previousMember)) &&
          !isEmpty(nextMember) &&
          !nextMember?.assigned &&
          accessibleMember.assigned
            ? styles.assignMembersDivider
            : styles.assignMembers
        }
      >
        <OverflowPopover maxWidth="300px">
          {accessibleMember.name}
        </OverflowPopover>
        <div className={styles.switchWrapper}>
          <Switch
            onChange={(e) =>
              handleTeamMembers(e, id, firstName, lastName, email)
            }
            checked={isAssignedAdmin(id)}
            size="small"
          />
        </div>
      </div>
    );
  };

  const convertTeamMembersForTableRender = (): any[] => {
    const _teamMembers = getTeamMembers();
    return (
      _teamMembers?.map((accessibleMember: any, index: any) => ({
        key: accessibleMember.id,
        name: getColumnData(
          accessibleMember,
          _teamMembers[index - 1],
          _teamMembers[index + 1]
        ),
      })) || []
    );
  };

  const closeAlert = () => {
    setIsAlertVisible(false);
  };

  const closeAddAdmin = () => {
    dispatch(clearManagingTeamMember());
    setIsAlertVisible(false);
    setIsModalOpen();
  };

  const footerComponent = (
    <Row>
      <SubmitButton
        type="primary"
        className={styles.cancelButton}
        onClick={closeAddAdmin}
      >
        Close
      </SubmitButton>
    </Row>
  );

  return (
    <Modal
      wrapClassName={styles.brokerAdminModalWrapper}
      title="Add Broker Admin"
      width={650}
      visible={isModalOpen}
      footer={footerComponent}
    >
      <div className={styles.addBrokerAdminWrapper}>
        <div className={styles.alertContainer}>
          {isAlertVisible && (
            <AlertMessage
              type="error"
              message="At least one Team Member is required"
              closeAlert={closeAlert}
            />
          )}
        </div>
        <div className={styles.searchBar}>
          <SearchBar
            placeholder="Search to filter broker admins"
            onChange={handleSearch}
            isLarge={true}
            allowClear={true}
          />
        </div>
        <div className={styles.tableWrapper}>
          <Table
            showHeader={false}
            columns={teamMembersColumns}
            dataSource={convertTeamMembersForTableRender()}
            pagination={false}
            scroll={{ y: 300 }}
            className={styles.scrollableTableWrapper}
          />
        </div>
        <Row className={styles.membersCountWrapper}>
          <span
            className={
              teamMembersCount > 1000
                ? styles.smallCountBadge
                : styles.largeCountBadge
            }
          >
            {teamMembersCount}
          </span>
          <span className={styles.badgeText}>Broker Admins Selected</span>
        </Row>
      </div>
    </Modal>
  );
};

export default AddBrokerAdmin;
