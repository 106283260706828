import { useAppSelector } from 'hooks/redux';
import ServiceUPWrapper from 'modules/plans/components/Services/ServiceUPWrapper';
import PanelSection from 'modules/plans/enums/PanelSection';

type Props = {
  setState: Function;
  state: any;
};

const RxTiers = ({ setState, state }: Props) => {
  const planCreateType = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.selectPlanCreateType
  );
  const isPlanReviewed = useAppSelector(
    (state) =>
      state.renewals.addProposalToPlanYear.isCurrentSelectedPlanReviewed
  );
  const isPlanMerged = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.isCurrentSelectedPlanMerged
  );

  const isMerging =
    planCreateType === 'MERGE' && !(isPlanReviewed === isPlanMerged);
  return (
    <div>
      <ServiceUPWrapper
        type={PanelSection.RX}
        medicalPlan={state}
        setMedicalPlan={setState}
        isReviewProp={isMerging}
      />
    </div>
  );
};

export default RxTiers;
