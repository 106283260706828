import { isEmpty } from 'lodash';
import {
  currencyFormatterWithDecimalPlaceIfExists,
  removeCurrencySymbols,
} from 'util/commonUtil';
import {
  CONTACT_LENSES_FREQUENCY,
  IN_NETWORK_VALUE,
  MDV_PLANS,
  OFFER_CATEGORY,
} from 'modules/renewals/constants/renewalsConstants';

export const issueAllowedFileTypes = [
  'application/pdf',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/zip',
  'application/x-zip-compressed',
  'multipart/x-zip',
];

type GenerateEmailBody = {
  employerName: string;
  carrierName: string;
  effectiveDate: string;
  benefitType: string;
  offerDetails: any[];
  planDuration: string;
};

export const generateEmailBody = (obj: GenerateEmailBody) => {
  return `<p style="font-size:15px;color:#000">Hi,</p>
  <p style="font-size:15px;color:#000">Great news! ${
    obj.employerName
  } has decided to proceed with ${obj.carrierName} effective ${
    obj.effectiveDate
  } for their ${obj.benefitType} insurance coverage. 
  Please confirm the receipt of this email and the information below.</p>
  <ul style="font-size:15px;color:#000">
  <li>Effective Date: ${obj.effectiveDate}</li>
  <li>Plan Duration: ${obj.planDuration}</li>
  <li>Rate Guarantee/ Cap:</li>
  <li>Credits &amp; Discounts:</li>
  <li>Commission:</li>
  <li>Items Needed:</li>
  <li>Plan and Rate Details:</li>
  </ul>
  <br/>
  ${generatePlanDetailTableInEmailBody(obj.offerDetails)}
  `;
};

export const generateEmployerEmailBody = (
  employerName: string,
  benefitTypes: string,
  effectiveStartDate: string
) => {
  return `<p style="font-size:15px;color:#000">Hi ${employerName} Team,</p>
  <p style="font-size:15px;color:#000">Please see the attached documents for your ${benefitTypes} renewal final decisions 
  effective ${effectiveStartDate}.</p>
  <p style="font-size:15px;color:#000">Please reply to this email to confirm your final decisions.</p>
  `;
};

const generatePlanDetailTableInEmailBody = (data: any[]) => {
  const filteredVol = data?.filter(
    (item) => item?.benefitCategory !== OFFER_CATEGORY.VOLUNTARY
  );
  const volList = data
    ?.filter((item) => item?.benefitCategory === OFFER_CATEGORY.VOLUNTARY)
    .map((item) => {
      return item[getPlansAccordingToType(item?.benefitCategory)]?.map(
        (subItem: any) => {
          return `
        <h2 style="font-size:15px;font-weight:bold;line-height: 18px;color: #2A3233; margin-bottom:14px">${item?.carrier?.name} ${subItem?.name} </h2>`;
        }
      );
    })
    .flat();

  const otherList = filteredVol
    ?.map((item) => {
      return item[getPlansAccordingToType(item?.benefitCategory)]?.map(
        (subItem: any) => {
          return `
          <h2 style="font-size:15px;font-weight:bold;line-height: 18px;color: #2A3233; margin-bottom:14px">${
            item?.carrier?.name
          } ${subItem?.name} </h2>

        <table style="border:1px solid #2A3233;border-left:none;border-right:none;border-spacing: 0">
          <tbody>
            ${getTableData(subItem, item?.benefitCategory)
              ?.map((colData, index) => {
                return !isEmpty(colData)
                  ? colData?.title
                    ? `<tr> 
                    <td style="width:250px;padding:5px;padding-top:20px;font-weight:bold;border:none;border-bottom:1px solid #2A3233;font-size: 12px;color:#212326;line-height: 29px;">${colData?.title}</td>
                    <td style="width:220px;padding:5px 5px 5px 20px;border:none;border-bottom:1px solid #2A3233;border-left:3px solid #2A3233;font-size: 12px" />
                    </tr>`
                    : `
                <tr>
                  <td style="width:250px;padding-left:5px;border:none;font-size: 12px;line-height: 28px;color: #2A3233"> ${
                    colData.label
                  }</td>
                  <td style="width:220px;padding-left: 20px;border:none;border-left:3px solid #2A3233;font-size: 12px;line-height: 28px;color: #2A3233;;background-color:${
                    index % 2 == 0 ? '#F9F9F9' : '#FFF'
                  }">${colData.value}</td>
                </tr>`
                  : null;
              })
              .join('')}
          </tbody>
        </table>
      `;
        }
      );
    })
    .flat();

  return [otherList.join('<br/>'), '<br/>', volList.join('')].join('');
};

const getPlansAccordingToType = (type: any) => {
  switch (type) {
    case OFFER_CATEGORY.MEDICAL:
      return 'offeredMedicalPlans';
    case OFFER_CATEGORY.DENTAL:
      return 'offeredDentalPlans';
    case OFFER_CATEGORY.VISION:
      return 'offeredVisionPlans';
    case OFFER_CATEGORY.LIFE_AD:
    case OFFER_CATEGORY.SHORT_TERM_DISABILITY:
    case OFFER_CATEGORY.LONG_TERM_DISABILITY:
      return 'offeredLifePlans';
    case OFFER_CATEGORY.VOLUNTARY:
      return 'offeredVoluntaryPlans';
    default:
      return '';
  }
};
const inNetworkValuesCheck = [IN_NETWORK_VALUE, null];

const getNestedObjectArrayForCommon = (
  item: any,
  label: string,
  value: any
) => {
  const formatValue = ['null', undefined, '', null].includes(value)
    ? '-'
    : value;
  return inNetworkValuesCheck.includes(item?.fieldType)
    ? {
        label: label,
        value: formatValue || '-',
      }
    : {};
};

const getNestedObjectArrayForCommonWithCurrencyFormatter = (item: any) => {
  return inNetworkValuesCheck.includes(item?.fieldType)
    ? {
        label: item?.name,
        value:
          currencyFormatterWithDecimalPlaceIfExists(
            removeCurrencySymbols(item?.value)
          ) || '-',
      }
    : {};
};

const getTableData = (offerData: any, type: string): any[] => {
  const MDVCommon: any[] = MDV_PLANS.includes(type)
    ? [
        {
          label: 'Plan Type',
          value: offerData?.planType || '-',
        },
        {
          label: 'Plan Network',
          value: offerData?.planNetwork || '-',
        },
        { title: 'PREMIUMS' },
        ...offerData?.monthlyPremiums?.map((item: any) =>
          getNestedObjectArrayForCommonWithCurrencyFormatter(item)
        ),
      ]
    : [];

  // FREQUENCIES section is only visible when there is at least one frequency with a value
  // Only show the additionalServices with a value under frequencies section
  // Exclude the 'Contact Lenses Frequency' always
  const additionalVisionServicesAsFrequencies =
    offerData?.additionalServices?.length > 0
      ? [
          { title: 'FREQUENCIES' },
          ...offerData?.additionalServices
            ?.filter((item: any) => item?.name !== CONTACT_LENSES_FREQUENCY)
            ?.map((item: any) =>
              getNestedObjectArrayForCommon(
                item,
                item?.name?.replace('Frequency', ''),
                item?.value
              )
            ),
        ]
      : [];

  /**
   * use to add the prefix from Individual, family and Ind. within Family for deductibles
   *
   * @param {string} fieldSubType
   * @return {string}
   */
  const setPrefixForDeductible = (fieldSubType: string) => {
    switch (fieldSubType) {
      case 'Single':
        return 'Individual';
      case 'Family':
        return 'Family';
      case 'SingleWithinFamily':
        return 'Ind. within Family';
      default:
        return '';
    }
  };

  switch (type) {
    case OFFER_CATEGORY.MEDICAL:
      return [
        ...MDVCommon,
        {
          title: 'DEDUCTIBLES & OOP MAX',
        },
        ...offerData?.deductibles?.map((item: any) =>
          (item?.value === '' || item?.value === 'null') &&
          item?.fieldSubType === 'SingleWithinFamily'
            ? ''
            : getNestedObjectArrayForCommon(
                item,
                `${setPrefixForDeductible(item?.fieldSubType)} ${item?.name}`,
                item?.value === 'null' ? '-' : item?.value
              )
        ),
        ...offerData?.oops?.map((item: any) =>
          (item?.value === '' || item?.value === 'null') &&
          item?.fieldSubType === 'SingleWithinFamily'
            ? ''
            : getNestedObjectArrayForCommon(
                item,
                `${setPrefixForDeductible(item?.fieldSubType)} ${item?.name}`,
                item?.value === 'null' ? '-' : item?.value
              )
        ),
        { title: 'SERVICES' },
        ...offerData?.services?.map((item: any) =>
          getNestedObjectArrayForCommon(item, item?.name, item?.value)
        ),
        {
          title: 'RX',
        },
        ...offerData?.rxDeductible?.map((item: any) =>
          item?.value === '' || item?.value === 'null'
            ? ''
            : getNestedObjectArrayForCommon(
                item,
                `Rx Deductible ${
                  item?.name === 'RxDeductibleAndOopIndividual'
                    ? '(Individual)'
                    : '(Family)'
                }
              `,
                item?.value
              )
        ),
        ...offerData?.rxOop?.map((item: any) =>
          item?.value === '' || item?.value === 'null'
            ? ''
            : getNestedObjectArrayForCommon(
                item,
                `Rx OOP Max ${
                  item?.name === 'RxDeductibleAndOopIndividual'
                    ? '(Individual)'
                    : '(Family)'
                }
          `,
                item?.value
              )
        ),
        ...offerData?.rxTiers?.map((item: any) =>
          getNestedObjectArrayForCommon(item, item?.name, item?.value)
        ),
      ];
    case OFFER_CATEGORY.DENTAL:
      return [
        ...MDVCommon,
        { title: 'DEDUCTIBLE & MAXIMUMS' },
        ...offerData?.deductibles?.map((item: any) =>
          getNestedObjectArrayForCommon(
            item,
            `${item?.name} (${item?.fieldSubType})`,
            item?.value
          )
        ),
        ...offerData?.maximumBenefits?.map((item: any) =>
          getNestedObjectArrayForCommon(item, item?.name, item?.formattedValue)
        ),
        { title: 'SERVICES' },
        ...offerData?.services?.map((item: any) =>
          getNestedObjectArrayForCommon(item, item?.name, item?.value)
        ),
      ];
    case OFFER_CATEGORY.VISION:
      return [
        ...MDVCommon,
        ...additionalVisionServicesAsFrequencies,
        { title: 'SERVICES' },
        ...offerData?.services?.map((item: any) =>
          getNestedObjectArrayForCommon(item, item?.name, item?.value)
        ),
      ];
    case OFFER_CATEGORY.LIFE_AD:
      return [
        {
          label: offerData?.multiplier?.name || 'Multiplier',
          value: offerData?.multiplier?.formattedValue || '-',
        },
        {
          label: offerData?.flatAmount?.name || 'Flat Amount',
          value: offerData?.flatAmount?.formattedValue || '-',
        },
        {
          label: offerData?.lifeAndAddBenefit?.name || 'Benefit',
          value: offerData?.lifeAndAddBenefit?.formattedValue || '-',
        },
        {
          label: offerData?.lifeAndAddMaximum?.name || 'Benefit Maximum',
          value: offerData?.lifeAndAddMaximum?.formattedValue || '-',
        },
        {
          label: offerData?.guaranteedIssue?.name || 'Guaranteed Issue',
          value: offerData?.guaranteedIssue?.formattedValue || '-',
        },
        {
          label: offerData?.ageReductionFromBase?.name || 'Age Reduction',
          value: offerData?.ageReductionFromBase?.formattedValue || '-',
        },
        { title: 'RATES' },
        {
          label: offerData?.volume?.name || 'Volume',
          value: offerData?.volume?.formattedValue || '-',
        },
        ...(offerData?.lifeRate
          ? offerData.lifeRate.map((item: any) =>
              getNestedObjectArrayForCommonWithCurrencyFormatter(item)
            )
          : []),
        ...(offerData?.basicAddRate
          ? offerData.basicAddRate.map((item: any) =>
              getNestedObjectArrayForCommonWithCurrencyFormatter(item)
            )
          : []),
      ];
    case OFFER_CATEGORY.SHORT_TERM_DISABILITY:
      return [
        {
          label: offerData?.benefitLevel?.name || 'Benefit',
          value: offerData?.benefitLevel?.formattedValue || '-',
        },
        {
          label: offerData?.weeklyBenefitMax?.name || 'Max Weekly Benefit',
          value: offerData?.weeklyBenefitMax?.formattedValue || '-',
        },
        {
          label: offerData?.eliminationPeriod?.name || 'Waiting Period',
          value: offerData?.eliminationPeriod?.formattedValue || '-',
        },
        {
          label: offerData?.benefitDuration?.name || 'Max Benefit Duration',
          value: offerData?.benefitDuration?.formattedValue || '-',
        },
        {
          label:
            offerData?.definitionOfDisability?.name ||
            'Definition of Disability',
          value: offerData?.definitionOfDisability?.formattedValue || '-',
        },
        {
          label:
            offerData?.preExistingConditions?.name || 'Pre-Existing Conditions',
          value: offerData?.preExistingConditions?.formattedValue || '-',
        },
        {
          label:
            offerData?.stateDisabilityIntegration?.name ||
            'State Disability Integration',
          value: offerData?.stateDisabilityIntegration?.formattedValue || '-',
        },
        { title: 'RATES' },
        {
          label: offerData?.volume?.name || 'Volume',
          value: offerData?.volume?.formattedValue || '-',
        },
        ...(offerData?.lifeRate
          ? offerData.lifeRate.map((item: any) =>
              getNestedObjectArrayForCommonWithCurrencyFormatter(item)
            )
          : []),
        ...(offerData?.basicAddRate
          ? offerData.basicAddRate.map((item: any) =>
              getNestedObjectArrayForCommonWithCurrencyFormatter(item)
            )
          : []),
      ];
    case OFFER_CATEGORY.LONG_TERM_DISABILITY:
      return [
        {
          label: offerData?.benefitLevel?.name || 'Benefit',
          value: offerData?.benefitLevel?.formattedValue || '-',
        },
        {
          label: offerData?.monthlyBenefitMax?.name || 'Max Monthly Benefit',
          value: offerData?.monthlyBenefitMax?.formattedValue || '-',
        },

        {
          label: offerData?.eliminationPeriod?.name || 'Waiting Period',
          value: offerData?.eliminationPeriod?.formattedValue || '-',
        },
        {
          label: offerData?.benefitDuration?.name || 'Max Benefit Duration',
          value: offerData?.benefitDuration?.formattedValue || '-',
        },
        {
          label:
            offerData?.definitionOfDisability?.name ||
            'Definition of Disability',
          value: offerData?.definitionOfDisability?.formattedValue || '-',
        },
        {
          label:
            offerData?.ownOccupationPeriod?.name || 'Own Occupation Period',
          value: offerData?.ownOccupationPeriod?.formattedValue || '-',
        },
        {
          label: offerData?.w2Preparation?.name || 'W-2 Preparation',
          value: offerData?.w2Preparation?.formattedValue || '-',
        },
        { title: 'RATES' },
        {
          label: offerData?.volume?.name || 'Volume',
          value: offerData?.volume?.formattedValue || '-',
        },
        ...(offerData?.lifeRate
          ? offerData.lifeRate.map((item: any) =>
              getNestedObjectArrayForCommonWithCurrencyFormatter(item)
            )
          : []),
        ...(offerData?.basicAddRate
          ? offerData.basicAddRate.map((item: any) =>
              getNestedObjectArrayForCommonWithCurrencyFormatter(item)
            )
          : []),
      ];
    default:
      return [];
  }
};
