import axios from 'axios';
import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as AdminService from 'modules/admins/services/AdminService';

import PaginationConfig from 'model/PaginationConfig';
import MetaData from 'model/MetaData';
import ERAdmin from 'model/admin/Admin';
import ErrorDetails from 'model/ErrorDetails';
export interface ERAdminState {
  inProgress: boolean;
  error: ErrorDetails | null;
  requestType: string;
  erAdmins: { content: Array<ERAdmin>; metadata: MetaData };
  erAdminsForEmployer: Array<ERAdmin>;
}

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  erAdmins: { content: [], metadata: {} },
  erAdminsForEmployer: [],
} as ERAdminState;

const erAdminSlice = createSlice({
  name: 'erAdmins',
  initialState,
  reducers: {
    getErAdminInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.erAdmins = { content: [], metadata: {} };
      state.error = null;
      state.requestType = action.type;
    },
    getErAdminCompleted: (
      state,
      action: PayloadAction<{ content: Array<ERAdmin>; metadata: {} }>
    ) => {
      state.inProgress = false;
      state.erAdmins = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getErAdminFailed: (state, action) => {
      state.inProgress = false;
      state.erAdmins = { content: [], metadata: {} };
      state.error = { data: action.payload?.data };
      state.requestType = action.type;
    },
    getErAdminsForEmployerInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.erAdminsForEmployer = [];
      state.error = null;
      state.requestType = action.type;
    },
    getErAdminsForEmployerCompleted: (state, action: PayloadAction<[]>) => {
      state.inProgress = false;
      state.erAdminsForEmployer = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getErAdminsForEmployerFailed: (state, action) => {
      state.inProgress = false;
      state.erAdminsForEmployer = [];
      state.error = { data: action.payload?.data };
      state.requestType = action.type;
    },
  },
});

export const {
  getErAdminFailed,
  getErAdminInProgress,
  getErAdminCompleted,
  getErAdminsForEmployerInProgress,
  getErAdminsForEmployerCompleted,
  getErAdminsForEmployerFailed,
} = erAdminSlice.actions;
export default erAdminSlice.reducer;

export const getERAdmins = (
  { page, size, sort, query }: PaginationConfig,
  brokerId: string,
  employerId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getErAdminInProgress());
    try {
      const response = await AdminService.getERAdminList(
        page,
        size,
        sort,
        query,
        brokerId,
        employerId
      );
      const data = response.data;
      dispatch(getErAdminCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(getErAdminFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
};

export const getErAdminsByEmployerId = (employerId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getErAdminsForEmployerInProgress());
    try {
      const response = await AdminService.findErAdminsByEmployer(employerId);
      const data = response.data;
      dispatch(getErAdminsForEmployerCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(getErAdminFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
};
