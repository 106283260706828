import { FC, useEffect, useState } from 'react';
import { Alert, Checkbox, Collapse, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import { ReactComponent as PopoutIcon } from 'assets/images/popout-icon.svg';
import {
  PLAN_OVERVIEW_URL_MAP,
  BENEFIT_DISPLAY_NAMES,
  CURRENT_PLANS_CONFIRMATION,
} from 'modules/renewals/constants/addToPlanYearConstants';
import { VoluntaryPlans } from 'modules/plans/constants';
import { PlansState } from 'modules/renewals/components/StartNewRenewalModal/StartNewRenewalModal';
import { useLazyGetPlanYearsQuery } from 'modules/renewals/slices/renewalsSlice';
import styles from './confirmCurrentPlansModal.module.less';

type ConfirmCurrentPlansModalProps = {
  visible: boolean;
  setConfirmCurrentPlansModalVisible: any;
  onNextStep: (options?: object) => any;
  plans: PlansState;
};

const { Panel } = Collapse;

const ConfirmCurrentPlansModal: FC<ConfirmCurrentPlansModalProps> = (props) => {
  const { visible, setConfirmCurrentPlansModalVisible, onNextStep, plans } =
    props;

  const { brokerId, employerId } = useParams();

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [currentPlanYearName, setCurrentPlanYearName] = useState<string | null>(
    null
  );

  const [fetchPlanYears, { data: planYears }] = useLazyGetPlanYearsQuery();

  useEffect(() => {
    if (employerId) {
      fetchPlanYears({ employerId });
    }
  }, [employerId, fetchPlanYears]);

  useEffect(() => {
    if (planYears) {
      const currentYear = planYears.find((year: any) => year.current)?.name;
      setCurrentPlanYearName(currentYear);
    }
  }, [planYears]);

  const handleSaveClick = async () => {
    try {
      onNextStep({ hasCurrentPlans: true });
      setConfirmCurrentPlansModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelClick = () => {
    setIsCheckboxChecked(false);
    setConfirmCurrentPlansModalVisible(false);
  };

  const handlePlanLinkClick = (
    id: string,
    benefit: string,
    planType: string
  ) => {
    if (id !== '' && id !== undefined) {
      if (
        [
          VoluntaryPlans.VOLUNTARY_STD.value,
          VoluntaryPlans.VOLUNTARY_LTD.value,
          VoluntaryPlans.VOLUNTARY_LIFE.value,
          VoluntaryPlans.VOLUNTARY_ADD.value,
          VoluntaryPlans.VOLUNTARY_LIFE_ADD.value,
        ].includes(planType)
      ) {
        window.open(
          `/brokers/${brokerId}/employers/${employerId}/${PLAN_OVERVIEW_URL_MAP.LIFE_AND_DISABILITY}/${id}/overview`,
          '_blank',
          'noreferrer'
        );
      } else {
        window.open(
          `/brokers/${brokerId}/employers/${employerId}/${benefit}/${id}/overview`,
          '_blank',
          'noreferrer'
        );
      }
    }
  };

  const handleAddPlans = () => {
    window.open(
      `/brokers/${brokerId}/employers/${employerId}/medical`,
      '_blank',
      'noreferrer'
    );
  };

  const renderPlanPanel = (category: string, plans: any) => (
    <Panel
      header={
        <span>
          {BENEFIT_DISPLAY_NAMES[category]}{' '}
          <span className={plans.length === 0 ? styles.redText : styles.text}>
            ({plans.length} Current Plans)
          </span>
        </span>
      }
      key={category}
    >
      {plans.length > 0 &&
        plans.map((plan: any, index: any) => (
          <div
            key={index}
            className={styles.planNameWrapper}
            onClick={() =>
              handlePlanLinkClick(plan.masterId, category, plan.tierType)
            }
          >
            {plan.name}
            <PopoutIcon className={styles.linkIcon} />
          </div>
        ))}
    </Panel>
  );

  return (
    <Modal
      centered
      visible={visible}
      width={600}
      closeIcon={<></>}
      footer={false}
      className={styles.confirmCurrentPlansModal}
      destroyOnClose={true}
    >
      <div className={styles.confirmCurrentPlansModalWrapper}>
        <div className={styles.title}>Confirm Current Plans</div>
        <div className={styles.subTitle}>
          Current Plan Year:{' '}
          <span className={styles.boldText}>{currentPlanYearName}</span>
        </div>
        <div className={styles.description}>
          The plans for the current plan year are listed below. If you need to
          add to the list of current plans, please{' '}
          <span className={styles.warningLink} onClick={handleAddPlans}>
            add them here
          </span>{' '}
          before starting the new renewal period.
        </div>
        <div className={styles.outlinedDiv}>
          <Collapse ghost>
            {renderPlanPanel('medical', plans.MEDICAL)}
            {renderPlanPanel('dental', plans.DENTAL)}
            {renderPlanPanel('vision', plans.VISION)}
            {renderPlanPanel('life', plans.LIFE)}
            {renderPlanPanel('voluntary', plans.VOLUNTARY_BENEFIT)}
          </Collapse>
        </div>
        <div className={styles.footer}>
          <Alert
            className={styles.confirmationMessage}
            type="warning"
            message={
              <div className={styles.alertContent}>
                <Checkbox
                  className={styles.checkbox}
                  checked={isCheckboxChecked}
                  onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                />
                {CURRENT_PLANS_CONFIRMATION}
              </div>
            }
          />
          <PageActionButton
            disabled={!isCheckboxChecked}
            className={styles.saveBtn}
            type="primary"
            onClick={handleSaveClick}
          >
            Confirm Current Plans and Start Renewal Period
          </PageActionButton>
          <CancelButton
            className={styles.cancelBtn}
            onClick={handleCancelClick}
          >
            Go Back
          </CancelButton>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmCurrentPlansModal;
