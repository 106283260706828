import axios from 'axios';

import { lumityApi } from 'api/apiSlice';

import { baseApi, CONTENT_TYPE_MULTIPART_FORM } from 'util/apiUtil';

import EmployeeElectionDetailsVO from 'model/EmployeeElectionDetailsVO';
import { IndividualVO } from 'model/ProposalSummaryVO';
import PlanYear from 'model/PlanYear';
import { MIME_TYPES } from 'util/fileUtil';

const idCardAPI = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    importIdCardFile: builder.mutation<
      any,
      {
        planYearId: string;
        employerId: string;
        file: File | null;
        isNotificationActive: boolean;
        isCurrentPlanYear: boolean;
      }
    >({
      query: (args) => {
        const {
          planYearId,
          employerId,
          file,
          isNotificationActive,
          isCurrentPlanYear,
        } = args;
        const formData = new FormData();
        formData.append('file', file ?? '');
        formData.append('plan-year-id', planYearId);
        formData.append('isActive', isNotificationActive ? 'active' : '');
        return {
          url: `v1/employers/${employerId}/enrolment-file?is-current-plan-year=${isCurrentPlanYear}`,
          method: 'POST',
          headers: {
            'content-type': CONTENT_TYPE_MULTIPART_FORM,
          },
          data: formData,
        };
      },
    }),
    employeeList: builder.query<
      any,
      {
        planYearId: string;
        employerId: string;
        searchKey: string;
        page: number;
        size: number;
        sort: string;
      }
    >({
      query: (args) => {
        const { planYearId, employerId, searchKey, page, size, sort } = args;
        return {
          url: `v1/employees/employers/${employerId}/election-summaries`,
          method: 'GET',
          params: {
            planYearId,
            searchKey,
            _page: page,
            _size: size,
            _sort: sort,
          },
        };
      },
    }),
    importFilePreview: builder.mutation<
      {
        electionSummaries: any[];
        errorRecords: any[];
        dependantsToDeactivateCount: number;
        employeesToDeactivateCount: number;
        toBeDeactivatedLoginCount: number;
        containsCriticalError: boolean;
      },
      { planYearId: string; employerId: string; file: File | null }
    >({
      query: (args) => {
        const { planYearId, employerId, file } = args;
        const formData = new FormData();
        formData.append('file', file ?? '');
        formData.append('plan-year-id', planYearId);
        return {
          url: `v1/employers/${employerId}/enrolment-file/preview`,
          method: 'POST',
          headers: {
            'content-type': CONTENT_TYPE_MULTIPART_FORM,
          },
          data: formData,
        };
      },
    }),
    fileHistory: builder.query<
      any,
      {
        employerId: string;
        searchKey: string;
        page: number;
        size: number;
        sort: string;
      }
    >({
      query: (args) => {
        const { employerId, searchKey, page, size, sort } = args;
        return {
          url: `v1/employers/${employerId}/enrolment-file`,
          method: 'GET',
          params: {
            searchKey: searchKey,
            _page: page,
            _size: size,
            _sort: sort,
          },
        };
      },
    }),
    deleteFile: builder.mutation<
      any,
      {
        recordId: string;
      }
    >({
      query: (args) => {
        const { recordId } = args;
        return {
          url: `v1/employers/enrolment-file/${recordId}`,
          method: 'DELETE',
        };
      },
    }),
    getEmployeeElectionDetails: builder.query<
      EmployeeElectionDetailsVO,
      { employeeId: string; employerId: string; planYearId: string }
    >({
      query: ({ employeeId, employerId, planYearId }) => ({
        url: `v1/employees/employers/${employerId}/election-details`,
        method: 'GET',
        params: { 'employee-id': employeeId, 'plan-year-id': planYearId },
      }),
    }),
    getEmployeeDependentList: builder.query<
      IndividualVO[],
      { employeeId: string; planYearId: string }
    >({
      query: ({ employeeId, planYearId }) => ({
        url: `v1/employees/${employeeId}/dependents`,
        method: 'GET',
        params: { 'plan-year-id': planYearId },
      }),
    }),
    deleteEmployee: builder.mutation<void, { employeeId: string }>({
      query: ({ employeeId }) => ({
        url: `v2/mobile/account/employee/${employeeId}`,
        method: 'DELETE',
      }),
    }),
    getEmployerPlanYearsList: builder.query<PlanYear[], { employerId: string }>(
      {
        query: ({ employerId }) => ({
          url: `v1/employers/${employerId}/plan-years`,
          method: 'GET',
        }),
      }
    ),
    sendNotificationOnEnrollmentFile: builder.query<
      any,
      { recordId: string; isCurrentPlanYear: boolean }
    >({
      query: ({ recordId, isCurrentPlanYear }) => ({
        url: `v1/employers/enrolment-file-notification/${recordId}?is-current-plan-year=${isCurrentPlanYear}`,
        method: 'GET',
      }),
    }),
    getIdCardPlanDetails: builder.query<
      any,
      {
        employeeId: string;
        employerId: string;
        individualId: string;
        planYearId: string;
      }
    >({
      query: ({ employeeId, employerId, individualId, planYearId }) => ({
        url: `v1/plans/plan-id-cards`,
        method: 'GET',
        params: {
          'employer-id': employerId,
          'employee-id': employeeId,
          'individual-id': individualId,
          'plan-year-id': planYearId,
        },
      }),
    }),
    updateElectionMemberId: builder.mutation<
      any,
      {
        benefitKind: string;
        memberId: string;
        planYearId: string;
        individualId: string;
      }
    >({
      query: (data) => {
        const { benefitKind, memberId, planYearId, individualId } = data;
        return {
          url: `v2/elections/individual/${individualId}`,
          method: 'PUT',
          data: {
            benefitKind,
            memberId,
            planYearId,
            isUserAmended: true,
          },
        };
      },
    }),
    getEmployeeByIndividualId: builder.query<any, { individualId: string }>({
      query: ({ individualId }) => ({
        url: `v1/employees/individualId/${individualId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const exportErrorFile = async (file: File | null, errors: any) => {
  const errorData = {
    errorRecords: errors,
  };
  const formData = new FormData();
  formData.append('file', file ?? '');
  formData.append(
    'errors',
    new Blob([JSON.stringify(errorData)], { type: 'application/json' })
  );
  const response = await axios.post(
    `${baseApi}/v1/employers/error-file`,
    formData,
    {
      responseType: 'blob',
    }
  );
  const blob = new Blob([response.data], {
    type: `${MIME_TYPES.XLSX};charset=utf-8`,
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `Error File - ${file?.name}`);
  document.body && document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const exportTemplateFile = async () => {
  window.location.href = `${baseApi}/v1/employers/enrolment-file/template/download`;
};

export const {
  useImportIdCardFileMutation,
  useEmployeeListQuery,
  useImportFilePreviewMutation,
  useFileHistoryQuery,
  useDeleteFileMutation,
  useGetEmployeeElectionDetailsQuery,
  useLazyGetEmployeeElectionDetailsQuery,
  useGetEmployeeDependentListQuery,
  useLazyGetEmployeeDependentListQuery,
  useDeleteEmployeeMutation,
  useGetEmployerPlanYearsListQuery,
  useLazyGetEmployerPlanYearsListQuery,
  useLazySendNotificationOnEnrollmentFileQuery,
  useLazyGetIdCardPlanDetailsQuery,
  useUpdateElectionMemberIdMutation,
  useLazyGetEmployeeByIndividualIdQuery,
} = idCardAPI;
