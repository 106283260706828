import { ReactNode, useState, useEffect } from 'react';

import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch } from 'hooks/redux';

import DBGClient from 'modules/clients/DBGClient/DBGClient';
import AddFourOOneKPlanDBGWrapper from 'modules/plans/retirement/components/AddFourOOneKPlan/AddFourOOneKPlanDBGWrapper';
import EditFourOOneKPlan from 'modules/plans/retirement/components/EditFourOOneKPlan/EditFourOOneKPlan';
import useDBGClient from 'modules/clients/DBGClient/useDBGClient';

import { getCarriersByBenefitKind } from 'modules/plans/slices/basicPlanInfoSlice';
import { changedBenguideModalState } from 'modules/benefitGuide/slices/benguideSlice';
import BenefitGuideNotificationsChannel from 'modules/clients/DBGClient/channels/BenefitGuideNotificationsChannel';

import {
  FOUR_O_ONE_K_PLAN_CHANNEL,
  RELOAD_FOUR_O_ONE_K_PLAN,
  OPEN_ADD_NEW_PLAN_MODAL,
  OPEN_FOUR_O_ONE_K_PLAN_EDIT_MODAL,
  RELOAD_FOUR_O_ONE_K_PLAN_LIST,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  EXIT_BENGUIDE,
  BENGUIDE_SAVE_COMPLETED,
  BENGUIDE_ACK_NOTIFICATION,
  CLOSE_MODALS,
} from 'modules/clients/DBGClient/DBGClientConts';
import { BenefitCategory } from 'constants/commonConstants';

type EventComponent = {
  component: ReactNode;
  props?: object;
};

type EventDataType = {
  item: {
    value: string;
    label: string;
  };
  benefitKind: string;
};

const FourOOneKPlansChannel = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [eventData, setEventData] = useState<EventDataType>();
  const [subscribedEvent, setSubscribedEvent] = useState('');
  const [planYearId, setPlanYearID] = useState<string>('');
  const [disableSave, setDisableSave] = useState(false);
  const { brokerId, employerId } = useNavContext();
  const dispatch = useAppDispatch();

  const cancelModalWhenRefresh = () => {
    setModalVisible(false);
  };

  const cancelModalWhenExit = () => {
    setModalVisible(false);
    dispatch(changedBenguideModalState(false));
  };

  useEffect(() => {
    if (
      brokerId &&
      employerId &&
      subscribedEvent === OPEN_FOUR_O_ONE_K_PLAN_EDIT_MODAL &&
      isModalVisible
    ) {
      dispatch(
        getCarriersByBenefitKind(
          BenefitCategory.RETIREMENT.value,
          brokerId,
          employerId
        )
      );
    }
  }, [dispatch, subscribedEvent, brokerId, isModalVisible, employerId]);

  const showOverlay = () => {
    if (disableSave) {
      dbgClient.postMessage({
        channel: FOUR_O_ONE_K_PLAN_CHANNEL,
        event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
        data: {},
      });
    }
  };

  const eventComponentMapper = {
    [OPEN_ADD_NEW_PLAN_MODAL]: {
      component: AddFourOOneKPlanDBGWrapper,
      props: {
        visible: isModalVisible,
        onClose: () => {
          setModalVisible(false);
          dbgClient.postMessage({
            channel: FOUR_O_ONE_K_PLAN_CHANNEL,
            event: RELOAD_FOUR_O_ONE_K_PLAN_LIST,
            data: {},
          });
          showOverlay();
        },
        onSave: () => {
          setModalVisible(false);
          dbgClient.postMessage({
            channel: FOUR_O_ONE_K_PLAN_CHANNEL,
            event: RELOAD_FOUR_O_ONE_K_PLAN_LIST,
            data: {},
          });
        },
        benefitCategory: BenefitCategory.RETIREMENT,
        isDBGPlan: true,
        dbgPlanYear: planYearId,
        isSaveDisabled: disableSave,
      },
    } as EventComponent,
    [OPEN_FOUR_O_ONE_K_PLAN_EDIT_MODAL]: {
      component: EditFourOOneKPlan,
      props: {
        onSave: () => {
          dbgClient.postMessage({
            channel: FOUR_O_ONE_K_PLAN_CHANNEL,
            event: RELOAD_FOUR_O_ONE_K_PLAN,
            data: {},
          });
        },
        onClose: () => {
          setModalVisible(false);
          showOverlay();
        },
        visible: isModalVisible,
        plan: eventData,
        benefitCategory: BenefitCategory.RETIREMENT,
        isDBGPlan: true,
        isSaveDisabled: disableSave,
      },
    } as EventComponent,
  } as any;

  const { component: Component, props } =
    eventComponentMapper[subscribedEvent] || {};

  const dbgClient = useDBGClient();

  return (
    <>
      <DBGClient
        channel={FOUR_O_ONE_K_PLAN_CHANNEL}
        subscribeCommon={(event: string) => {
          if (CLOSE_MODALS === event) {
            setModalVisible(false);
          }
        }}
        subscribe={(event: string, data: any, dbgMetaData: any) => {
          switch (event) {
            case EXIT_BENGUIDE:
              cancelModalWhenExit();
              break;
            case BENGUIDE_SAVE_COMPLETED:
              cancelModalWhenRefresh();
              break;
            case BENGUIDE_ACK_NOTIFICATION:
              setDisableSave(true);
              break;
            default:
              setSubscribedEvent(event);
              setEventData(data);
              setModalVisible(true);
              setDisableSave(false);
              setPlanYearID(dbgMetaData?.planYearId);
              break;
          }
        }}
      />
      <BenefitGuideNotificationsChannel
        setDisableSave={() => setDisableSave(true)}
        isPlanEditModalOpen={isModalVisible}
        benefitKind={BenefitCategory.RETIREMENT.value}
      />
      {Component && <Component {...props} />}
    </>
  );
};

export default FourOOneKPlansChannel;
