import { combineReducers } from '@reduxjs/toolkit';

import employerSlice from 'modules/employers/slices/employerSlice';
import benguideSlice from 'modules/benefitGuide/slices/benguideSlice';

const BenguideRootReducer = combineReducers({
  employerSlice,
  benguideSlice,
});

export default BenguideRootReducer;
