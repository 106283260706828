import { useEffect, useState } from 'react';
import { Checkbox, Input, Switch } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isEmpty } from 'lodash';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import styles from './issuesLogEmployerFeatureToggle.module.less';

type IssuesLogEmployerFeatureToggleProps = {
  onConfirmToggle: (isDeleteChecked: boolean) => void;
  recordName: any;
  title: string;
  enabled: boolean;
  disabled?: boolean;
  className?: string;
  email: string;
};

const IssuesLogEmployerFeatureToggle = (
  props: IssuesLogEmployerFeatureToggleProps
) => {
  const { onConfirmToggle, recordName, title, enabled, disabled, email } =
    props;
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [disbale, setDisable] = useState<boolean>(enabled);
  const [invalidInput, setinvalidInput] = useState<boolean>(false);
  const [inputText, setinputText] = useState('');
  const [deleteEmailStatus, setDeleteEmail] = useState<boolean>(false);

  const confirmToggle = () => {
    onConfirmToggle(deleteEmailStatus);
    setShowConfirm(false);
    setinvalidInput(false);
    setinputText('');
    if (enabled) setDisable(true);
  };

  useEffect(() => {
    setDisable(enabled);
  }, [enabled]);

  useEffect(() => {
    if (showConfirm) {
      setDeleteEmail(false);
    }
  }, [showConfirm]);

  return (
    <div className={styles.switchWrapper}>
      <Switch
        onChange={() => {
          setShowConfirm(true);
        }}
        checked={enabled}
        disabled={disabled}
      />
      <ConfirmationDialog
        title={`${enabled ? 'Disable' : 'Enable'} ${title}`}
        confirmText={`${enabled ? 'Disable' : 'Enable'} ${title}`}
        cancelText="Cancel"
        closeModal={() => {
          setShowConfirm(false);
          setinvalidInput(false);
          setinputText('');
        }}
        onConfirm={confirmToggle}
        visible={showConfirm}
        isCancelLink
        isDanger={enabled}
        disableConfirmButton={disbale}
        recordName={''}
        width={600}
        modalClassName={props.className}
      >
        <div className={styles.subTitle}> {recordName}</div>
        {enabled ? (
          <>
            <p className={styles.warningConfirmation}>
              Are you sure you want to disable Account Log? This tool will be
              immediately hidden from the employers view.
            </p>
            <p className={styles.warningConfirmation}>
              Automated ticket creation via email will remain active for brokers
              Account Log unless the email address is deleted below:
            </p>

            {email && (
              <div className={styles.checkboxWrapper}>
                <Checkbox
                  checked={deleteEmailStatus}
                  onChange={(event: CheckboxChangeEvent) => {
                    setDeleteEmail(event.target.checked);
                  }}
                />
                <span className={styles.text}>Delete automated email</span>
                <span className={styles.email}>{email}</span>
              </div>
            )}

            <div className={styles.warningConfirmation}>
              <div
                className={styles.disableWarningText}
              >{`Type "DISABLE" in the box below to confirm`}</div>
              <Input
                value={inputText}
                className={`${
                  invalidInput
                    ? styles.disableTextInputError
                    : styles.disableTextInput
                } disableInputField`}
                type="text"
                onChange={(e) => {
                  setinputText(e.target.value);
                  if (e.target.value == 'DISABLE') {
                    setDisable(false);
                    setinvalidInput(false);
                  } else if (isEmpty(e.target.value)) {
                    setDisable(true);
                    setinvalidInput(false);
                  } else {
                    setDisable(true);
                    setinvalidInput(true);
                  }
                }}
                bordered={true}
              />
              {invalidInput ? (
                <div className={styles.invalidInput}>Invalid Input</div>
              ) : (
                ''
              )}
            </div>
          </>
        ) : (
          <p className={styles.disableWarningTextEnable}>
            Are you sure you want to enable Account Log? This tool will be
            immediately available to the employer.
          </p>
        )}
      </ConfirmationDialog>
    </div>
  );
};

export default IssuesLogEmployerFeatureToggle;
