import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Table } from 'antd';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';

import Consultant from 'model/benefitsConsultation/Consultant';
import SelectionListRow from 'model/benefitsConsultation/SelectionListRow';
import AssignedEmployer from 'model/benefitsConsultation/AssignedEmployer';

import SearchBar from 'components/SearchBar/SearchBar';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import CountLabel from 'components/CountLabel/CountLabel';
import CheckboxSelect from 'components/CheckboxSelect/CheckboxSelect';

import styles from './checkboxListSelector.module.less';

const convertTableDataForTableRender = (
  tableData: SelectionListRow[]
): any[] => {
  return (
    tableData
      ?.map((data) => ({
        key: data.id,
        name: data.fullName || data.name,
        email: data.email || '',
        action: data,
      }))
      .sort((a: any, b: any) => a.name.localeCompare(b.name)) || []
  );
};

type CheckboxListSelectorProps = {
  consultantList: Consultant[] | AssignedEmployer[];
  consultantTypeText: string;
  inProgress?: boolean;
  isRemovableMode?: boolean;
  saveInProgress?: boolean;
  invalidCheckbox?: boolean;
  existingExternalEmailsList?: string[];
  setIsFieldsEmpty?: Function;
  isEmployersMode?: boolean;
};

const CheckboxListSelector = forwardRef(
  (props: CheckboxListSelectorProps, ref) => {
    const {
      consultantList,
      consultantTypeText,
      inProgress = false,
      isRemovableMode = false,
      saveInProgress = false,
      invalidCheckbox = false,
      existingExternalEmailsList,
      setIsFieldsEmpty,
      isEmployersMode = false,
    } = props;

    const getInitialData = useCallback(
      (isSelected: boolean) => {
        const tableData = isRemovableMode
          ? groupBy(consultantList, 'isAssigned')
          : groupBy(consultantList, 'isConsultant');

        if (isSelected && tableData.true) {
          return tableData.true.map((data: any) => {
            return {
              ...data,
              previous: true,
            };
          });
        } else if (!isSelected && tableData.false) {
          return tableData.false.map((data: any) => {
            return {
              ...data,
              previous: false,
            };
          });
        } else {
          return [];
        }
      },
      [consultantList, isRemovableMode]
    );

    const [tableDataSelected, setTableDataSelected] = useState<
      SelectionListRow[]
    >([]);
    const [tableDataUnselected, setTableDataUnselected] = useState<
      SelectionListRow[]
    >([]);
    const [unfilteredDataUnselected, setUnfilteredDataUnselected] = useState<
      SelectionListRow[]
    >([]);
    const [unfilteredDataSelected, setUnfilteredDataSelected] = useState<
      SelectionListRow[]
    >([]);

    const [selectedBrokerAdminCount, setSelectedBrokerAdminCount] =
      useState<number>(0);

    useImperativeHandle(ref, () => ({
      getConsultantsToBeAdded() {
        if (isRemovableMode) {
          return tableDataSelected.concat(tableDataUnselected);
        } else {
          return tableDataUnselected;
        }
      },

      resetData() {
        setTableDataSelected(getInitialData(true));
        setTableDataUnselected(getInitialData(false));
        setUnfilteredDataUnselected(getInitialData(false));
        setUnfilteredDataSelected(getInitialData(true));
      },
    }));

    useEffect(() => {
      setTableDataSelected(getInitialData(true));
      setTableDataUnselected(getInitialData(false));
      setUnfilteredDataUnselected(getInitialData(false));
      setUnfilteredDataSelected(getInitialData(true));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!saveInProgress) {
        const currentlySelectedList = isRemovableMode
          ? tableDataUnselected.filter((list) => list.isAssigned)
          : tableDataUnselected.filter((list) => list.isConsultant);

        const previouslySelectedList = isRemovableMode
          ? tableDataSelected.filter((list) => list.isAssigned)
          : tableDataSelected.filter((list) => list.isConsultant);

        setSelectedBrokerAdminCount(
          currentlySelectedList.length + previouslySelectedList.length
        );

        if (currentlySelectedList.length === 0) {
          setIsFieldsEmpty && setIsFieldsEmpty(true);
        } else {
          setIsFieldsEmpty && setIsFieldsEmpty(false);
        }
      }
    }, [
      isRemovableMode,
      saveInProgress,
      setIsFieldsEmpty,
      tableDataSelected,
      tableDataUnselected,
    ]);

    const tableDataColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: `${isEmployersMode ? '50%' : '20%'}`,
        ellipsis: {
          showTitle: false,
        },
        render: (name: string) => <OverflowPopover>{name}</OverflowPopover>,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '30%',
        ellipsis: {
          showTitle: false,
        },
        render: (email: string) => (
          <OverflowPopover>
            <span className={styles.email}>{email}</span>
          </OverflowPopover>
        ),
      },
      {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        width: '5%',
        render: (rowData: SelectionListRow) => {
          const { isConsultant, isAssigned, previous, email } = rowData;

          const onSelectionChange = () => {
            if (isRemovableMode) {
              rowData.isAssigned = !isAssigned;
            } else {
              rowData.isConsultant = !isConsultant;
              rowData.isAssigned = !isAssigned;
            }

            const currentList = cloneDeep(tableDataUnselected).map((list) =>
              list.id === rowData.id ? rowData : list
            );
            setTableDataUnselected(currentList);
            setUnfilteredDataUnselected(currentList);

            const currentPreviouslySelectedList = cloneDeep(
              tableDataSelected
            ).map((list) => (list.id === rowData.id ? rowData : list));
            setUnfilteredDataSelected(currentPreviouslySelectedList);
          };

          return (
            <CheckboxSelect
              defaultChecked={isRemovableMode ? isAssigned : isConsultant}
              onChange={onSelectionChange}
              disabled={previous && !isRemovableMode}
              invalid={
                invalidCheckbox &&
                isConsultant &&
                existingExternalEmailsList?.includes(email)
              }
            />
          );
        },
      },
    ];

    const getFilteredData = (
      list: SelectionListRow[],
      searchText: string
    ): SelectionListRow[] => {
      return list.filter(
        (data) =>
          (data.fullName && data.fullName.toLowerCase().includes(searchText)) ||
          (data.name && data.name.toLowerCase().includes(searchText)) ||
          (data.email && data.email.toLowerCase().includes(searchText))
      );
    };

    const getUserSelectionBeforeFilter = (
      filteredData: SelectionListRow[],
      unfilteredData: SelectionListRow[]
    ) => {
      filteredData.forEach((filtered) => {
        unfilteredData.forEach((unfiltered) => {
          if (unfiltered.id === filtered.id) {
            filtered.isAssigned = unfiltered.isAssigned;
            filtered.isConsultant = unfiltered.isConsultant;
          }
        });
      });
    };

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
      const { value = '' } = e.target;
      const searchText = value.trim().toLowerCase();

      const filteredUnselectedData = getFilteredData(
        getInitialData(false),
        searchText
      );
      getUserSelectionBeforeFilter(
        filteredUnselectedData,
        unfilteredDataUnselected
      );
      setTableDataUnselected(filteredUnselectedData || []);

      const filteredSelectedData = getFilteredData(
        getInitialData(true),
        searchText
      );
      getUserSelectionBeforeFilter(
        filteredSelectedData,
        unfilteredDataSelected
      );
      setTableDataSelected(filteredSelectedData || []);
    };

    const columnData = isEmployersMode
      ? tableDataColumns.filter((col) => col.dataIndex !== 'email')
      : tableDataColumns;
    return (
      <div className={styles.checkboxListSelectorWrapper}>
        <SearchBar
          placeholder={`Search to filter ${consultantTypeText}`}
          onChange={handleSearch}
          isLarge
        />
        <div className={styles.tables}>
          {!isEmpty(tableDataSelected) && (
            <Table
              showHeader={false}
              columns={columnData}
              dataSource={convertTableDataForTableRender(tableDataSelected)}
              pagination={false}
              className={`${styles.tableWrapper} ${
                isEmployersMode ? styles.employerMode : ''
              }`}
              loading={inProgress}
            />
          )}

          {!isEmpty(tableDataUnselected) && !isEmpty(tableDataSelected) && (
            <hr className={styles.tableSeparator} />
          )}

          {!isEmpty(tableDataUnselected) && (
            <Table
              showHeader={false}
              columns={columnData}
              dataSource={convertTableDataForTableRender(tableDataUnselected)}
              pagination={false}
              className={`${styles.tableWrapper} ${
                isEmployersMode ? styles.employerMode : ''
              }`}
              loading={inProgress}
            />
          )}

          {isEmpty(tableDataSelected) && isEmpty(tableDataUnselected) && (
            <Table
              showHeader={false}
              pagination={false}
              className={styles.tableWrapper}
              loading={inProgress}
            />
          )}
        </div>

        <CountLabel
          count={selectedBrokerAdminCount}
          label={consultantTypeText}
        />
      </div>
    );
  }
);

CheckboxListSelector.displayName = 'CheckboxListSelector';

export default CheckboxListSelector;
