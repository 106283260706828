import { FC, useState } from 'react';

import { Popover } from 'antd';
import LinkButton from 'components/buttons/LinkButton/LinkButton';

import iconAdd from 'assets/images/icon-add.svg';
import iconAddDisabled from 'assets/images/icon-add-disabled.svg';

import styles from './addTierButton.module.less';

type AddTierButtonProps = {
  btnName?: string;
  disabled?: boolean;
  onAddBtnClicked?: React.MouseEventHandler<HTMLElement>;
};

const AddTierButton: FC<AddTierButtonProps> = (props: AddTierButtonProps) => {
  const { disabled = false, onAddBtnClicked = () => {}, btnName } = props;
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={styles.addTierBtnWrapper}
    >
      <Popover
        content="Maximum Tiers Reached"
        overlayClassName="rx-maximum-popover"
        placement="right"
        trigger="hover"
        visible={hover && disabled}
      >
        <LinkButton
          onMouseLeave={() => setHover(false)}
          className={`${styles.addTierBtn}  ${
            disabled ? styles.disabledBtn : ''
          }`}
          onClick={(e) => {
            if (!disabled) onAddBtnClicked(e);
          }}
        >
          <img
            src={disabled ? iconAddDisabled : iconAdd}
            alt="add-icon"
            className={styles.iconStyle}
          />
          Add {btnName}
        </LinkButton>
      </Popover>
    </div>
  );
};

export default AddTierButton;
