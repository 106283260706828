import { FC, useEffect, useState } from 'react';
import { DefaultOptionType } from 'antd/lib/select';
import { isEmpty } from 'lodash';
import {
  Alert,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
} from 'antd';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import alertInfo from 'assets/images/alert-info.svg';
import {
  useGetEmployersByBrokerIdQuery,
  useLazyGetBrokerAdminsForEmployerQuery,
  useUpdateSupportTeamAdminListMutation,
} from 'modules/issueslog/slices/issuesLogSlice';
import Employer from 'model/Employer';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import MultiSelectScroll, {
  MultiScrollSelectOption,
} from 'modules/tools/components/MultiSelectScroll/MultiSelectScroll';
import { useNavContext } from 'hooks/useNavContext';
import { filterByLabel } from 'util/commonUtil';
import { ALLOWED_INDIVIDUAL_SUB_TYPES } from 'constants/commonConstants';

import { AccountStatus } from 'modules/admins/constants/adminConstants';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import styles from './configureAutomatedModalEmail.module.less';

type AutomatedEmailSetupModalProps = {
  isModalOpen: boolean;
  supportTeam: any;
  refetchForSupportEmails: any;
  setIsModalOpen: (open: boolean) => void;
};

const ConfigureAutomatedEmailModal: FC<AutomatedEmailSetupModalProps> = (
  props
) => {
  const { isModalOpen, setIsModalOpen, supportTeam, refetchForSupportEmails } =
    props;
  const [form] = Form.useForm();
  const { brokerId } = useNavContext();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [visible, setVisible] = useState<boolean>(false);
  const { employerId } = form.getFieldsValue();
  const [selectOptionsList, setSelectOptionsList] = useState<
    MultiScrollSelectOption[]
  >([]);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });

  const [updateSuportAdminList] = useUpdateSupportTeamAdminListMutation();
  const { data: employerData = [], isFetching: isLoadingEmpData } =
    useGetEmployersByBrokerIdQuery({
      brokerId: brokerId || '',
      individualId: null,
    });
  const [
    getBrokerAdminsForEmployerQuery,
    { isFetching: loadingBrokerList, data: brokerAdminData = [] },
  ] = useLazyGetBrokerAdminsForEmployerQuery();

  const employerList = employerData.map((employer: Employer) => {
    return { value: employer.id, label: employer.name };
  });

  const isNvApplicable = (broker: any): boolean => {
    return AccountStatus.ACCOUNT_ACTIVATED !== broker.accountStatus;
  };

  const getBrokerSelectOptionsList = (): MultiScrollSelectOption[] => {
    return brokerAdminData.map((broker: any) => {
      const foundCheckedBroker = supportTeam?.brokerAdmins?.find(
        (borkerAdminID: any) => borkerAdminID === broker?.id
      );
      return {
        name: {
          value: broker?.id,
          label: (
            <Row>
              <OverflowPopover maxWidth={isNvApplicable(broker) ? 100 : 160}>
                {`${broker?.firstName} ${broker?.lastName} `}
              </OverflowPopover>
              {isNvApplicable(broker) && (
                <span className={styles.notVerifiedLabel}>(not verified)</span>
              )}
            </Row>
          ),
          isChecked: foundCheckedBroker ? true : false,
        },
        email: {
          value: broker?.email,
          label: broker?.email,
        },
      };
    });
  };

  const handleOnSave = async () => {
    try {
      setIsLoading(true);

      const selectedIds = selectOptionsList
        .filter((option) => option?.name?.isChecked === true)
        .map((option) => option.name.value);

      if (!isEmpty(employerId) && isEmpty(selectedIds)) {
        setVisible(true);
        setAlertMessage({
          type: 'error',
          message: 'Please select at least one broker admin',
        });
        return;
      }

      form.setFieldsValue({
        brokerAdmins: selectedIds,
      });

      const formData = await form.validateFields();

      await updateSuportAdminList({
        data: formData,
        brokerId: brokerId!,
      }).then(() => {
        refetchForSupportEmails();
        handleModalOnClose();
      });
    } catch (error) {
      setVisible(true);
      setAlertMessage({
        type: 'error',
        message: 'Please correct all highlighted items and try again!',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const closeAlert = () => {
    setVisible(false);
    setAlertMessage({
      type: undefined,
      message: '',
    });
  };

  const filterSort = (
    optionA: DefaultOptionType,
    optionB: DefaultOptionType
  ) => {
    return (optionA.label?.toString().toLowerCase() || '').localeCompare(
      optionB.label?.toString().toLowerCase() || ''
    );
  };

  const handleOnSelect = (employerId: string) => {
    setSelectOptionsList([]);
    if (brokerId && employerId) {
      getBrokerAdminsForEmployerQuery({
        brokerId: brokerId,
        employerId: employerId,
        isActiveAndNonActive: true,
      });
    }
  };

  const handleModalOnClose = () => {
    form.resetFields();
    setSelectOptionsList([]);
    setIsModalOpen(false);
    setVisible(false);
    setAlertMessage({
      type: undefined,
      message: '',
    });
  };

  useEffect(() => {
    if (isModalOpen) {
      if (supportTeam.taggedEmployer && !isEmpty(supportTeam.taggedEmployer)) {
        if (brokerId) {
          getBrokerAdminsForEmployerQuery({
            brokerId: brokerId,
            employerId: supportTeam?.taggedEmployer,
            isActiveAndNonActive: true,
          });
        }
        form.setFieldsValue({
          employerId: supportTeam?.taggedEmployer,
        });
      }
      form.setFieldsValue({
        name: supportTeam?.name,
        email: supportTeam?.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, form, supportTeam, brokerId]);

  useEffect(() => {
    if (!isEmpty(employerId)) {
      setSelectOptionsList(getBrokerSelectOptionsList());
    } else {
      setSelectOptionsList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerId]);

  const navigateUserToBrokerAdmin = () => {
    window.open(`/brokers/${brokerId}/basicInfo?isBrokerAdmin=true`, '_blank');
  };

  const securedAddBrokerAdmin = usePermitByUserType(
    <Popover
      content={
        'Click and follow the steps to add a new broker. After successfully adding, refresh the page to view the updated broker admins list.'
      }
      placement="top"
      overlayClassName="popover"
    >
      <p className={styles.addAdminHeader} onClick={navigateUserToBrokerAdmin}>
        + Add Broker Admin
      </p>
    </Popover>,
    ALLOWED_INDIVIDUAL_SUB_TYPES
  );

  return (
    <Modal
      centered
      visible={isModalOpen}
      width={600}
      closeIcon={<></>}
      footer={false}
      className={styles.automatedEmailSetupModal}
      destroyOnClose={true}
    >
      <div className={styles.automatedEmailSetupModalWrapper}>
        <div className={styles.title}>Setup Automated Email</div>
        <div className={styles.subTitle}>Support Team</div>

        <div className={styles.description}>
          <p className={styles.descriptionOne}>
            Please enter a support team name and the email address below that
            has been set up to work with the PlanYear account log. Once
            configured, we will send a test email to that address to ensure it
            was properly set up. The status will show in the &quot;Email
            Status&quot; column of the configuration table.
          </p>

          <p className={styles.descriptionTwo}>
            Confirming the connection may take a few minutes. If you still see
            &quot;Connection pending&quot;, please confirm that the setup
            instructions were followed properly.
          </p>

          <div className={styles.alertInfoWrapper}>
            <Alert
              message={
                <div className={styles.alertWrapper}>
                  <span className={styles.alertTitleWrapper}>
                    <img
                      src={alertInfo}
                      alt="info icon"
                      className={styles.infoIcon}
                    />
                    To send another test email, click the configuration icon
                    that will appear
                    <div className={styles.secondRow}>
                      when you hover over &quot;Connection pending&quot;.
                    </div>
                  </span>
                </div>
              }
              type="info"
              icon={<img src={alertInfo} alt="info icon" />}
            />
          </div>
        </div>
        <div className={styles.errorWrapper}>
          {visible && (
            <AlertMessage
              type={alertMessage.type}
              message={alertMessage.message}
              closeAlert={closeAlert}
              wrapperClassName={styles.errorWrapper}
            />
          )}
        </div>
        <Form form={form} layout="vertical" className={styles.inputForm}>
          <Row justify="space-between">
            <Col xs={11}>
              <Form.Item name="name" label="Support Team Name *">
                <Input placeholder="e.g. General Support" disabled />
              </Form.Item>
            </Col>

            <Col xs={11}>
              <Form.Item name="email" label="Support Email Address *">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>{' '}
          <Divider />
          <div className={styles.optionalCustomWrapper}>
            <div className={styles.secondaryHeading}>
              Employer Support Email Customization{' '}
              <span className={styles.optionalSecondaryHeading}>
                (optional)
              </span>
            </div>
            <p className={styles.descriptionThree}>
              Emails sent to the support address can also be forwarded to broker
              admins.
            </p>{' '}
            <Form.Item labelAlign="left" label="Employer" name="employerId">
              <Select
                disabled={isLoadingEmpData || supportTeam?.taggedEmployer}
                showSearch
                filterOption={filterByLabel}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                options={employerList}
                onChange={handleOnSelect}
                allowClear
                listHeight={128}
                filterSort={filterSort}
              ></Select>
            </Form.Item>
            {securedAddBrokerAdmin}
            <Form.Item
              label="Support Team Broker Admins *"
              labelCol={{ span: 24 }}
              name="brokerAdmins"
              rules={[
                { required: false, message: 'Please select Broker Admins' },
              ]}
            >
              <MultiSelectScroll
                selectOptionsList={selectOptionsList}
                setSelectOptionsList={setSelectOptionsList}
                isLoading={loadingBrokerList}
              />
            </Form.Item>
          </div>
        </Form>

        <PageActionButton
          className={styles.saveBtn}
          type="primary"
          onClick={handleOnSave}
          disabled={isLoading}
          loading={isLoading}
        >
          Save
        </PageActionButton>

        <Row justify="center" className={styles.cancelWrapper}>
          <CancelButton disabled={isLoading} onClick={handleModalOnClose}>
            Cancel
          </CancelButton>
        </Row>
      </div>{' '}
    </Modal>
  );
};

export default ConfigureAutomatedEmailModal;
