import { Col, Modal, Row } from 'antd';

import Button from 'components/buttons/Button/Button';
import TextButton from 'components/buttons/TextButton/TextButton';
import styles from './ConfirmationWithThreeButtons.module.less';

type Props = {
  header?: React.ReactNode;
  firstButtonText: string;
  secondButtonText: string;
  showFirstButton?: boolean;
  showSecondButton?: boolean;
  firstButtonAction: () => void;
  secondButtonAction: () => void;
  visible: boolean;
  title: string;
  onCancel: (value: boolean) => void;
  centered?: boolean;
  width?: number;
  children?: React.ReactNode;
};

const ConfirmationWithThreeButtons = (props: Props) => {
  const {
    firstButtonText,
    secondButtonText,
    showFirstButton = true,
    showSecondButton = true,
    firstButtonAction,
    secondButtonAction,
    onCancel,
    visible,
    header,
    children,
    title,
    centered = false,
    width = 532,
  } = props;

  const titleHeader = (
    <>
      <Col xs={24}>
        <div className={styles.titleWrapper}>
          <span className={styles.title}>{title}</span>
        </div>
      </Col>
      <Col xs={24}>
        <span className={styles.headerText}>{header}</span>
      </Col>
    </>
  );

  const footer = (
    <>
      <Row>
        <Col xs={24}>
          {showFirstButton && (
            <Button
              className={styles.sendBtn}
              type="primary"
              onClick={() => firstButtonAction()}
              label={firstButtonText}
            />
          )}
          {showSecondButton && (
            <Button
              className={` ${
                showFirstButton ? styles.secondaryBtn : styles.sendBtn
              }`}
              type="primary"
              onClick={() => secondButtonAction()}
              label={secondButtonText}
            />
          )}
        </Col>
        <Col xs={24}>
          <TextButton
            type="primary"
            label="Cancel"
            className={styles.cancelLink}
            onClick={() => onCancel(false)}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <Modal
      visible={visible}
      wrapClassName={styles.modalWrapperContainer}
      width={width}
      centered={centered}
      destroyOnClose={true}
      title={titleHeader}
      cancelText={'cancel'}
      footer={footer}
    >
      {children}
    </Modal>
  );
};

export default ConfirmationWithThreeButtons;
