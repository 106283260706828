import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Row, Col } from 'antd';
import { isEmpty, set, cloneDeep } from 'lodash';

import NetworkTypeRow from 'modules/plans/components/NetworkTypeRow/NetworkTypeRow';
import { isEmptyValue } from 'modules/plans/utils';

import { IN_NETWORK, OUT_OF_NETWORK } from 'modules/plans/constants';
import InfoIconComponent from 'modules/plans/components/LLMInfoIconComponent/InfoIconComponent';

import PlanDeductibleOOPMax from 'model/PlanDeductibleOOPMax';
import PlanDeductibleOOPNetwork from 'model/PlanDeductibleOOPNetwork';
import { DentalPlan } from 'model/plans/DentalPlan';

import styles from './deductiblesOop.module.less';

type DeductiblesOOPMaxProps = {
  onChange: Function;
  dentalPlan: DentalPlan;
  isTouched: boolean;
  isEdit?: boolean;
  isReviewProp?: boolean;
};

type DeductibleOOPMaxValueMapping = {
  serviceLabel: string;
  inNetwork: PlanDeductibleOOPNetwork;
  outNetwork: PlanDeductibleOOPNetwork;
};

const DeductiblesOOPMax = forwardRef<any, DeductiblesOOPMaxProps>(
  (props, ref) => {
    const {
      onChange,
      dentalPlan,
      isTouched,
      isEdit = false,
      isReviewProp = false,
    } = props;

    const [deductibleOopMax, setDeductibleOopMax] = useState<
      DeductibleOOPMaxValueMapping[]
    >([]);

    const isHighlightVisible = !dentalPlan?.extractionFinalized;
    const isUploadedPlan =
      !!dentalPlan?.textractJobId && dentalPlan?.textractJobId !== '';
    useEffect(() => {
      const {
        deductibles,
        calendarYearMax,
        orthodontiaLifetimeMax,
        llmExtractionInfo,
      } = dentalPlan || {};

      const individualDeductible = {
        serviceLabel: 'Individual Deductible',
        inNetwork: {
          covered: deductibles?.individualInNetworkApplicable,
          coveredAmount: deductibles?.individualInNetworkCost,
          valueMapping: {
            COVERAGE: 'deductibles.individualInNetworkApplicable',
            COST: 'deductibles.individualInNetworkCost',
          },
          llmExtractionInfo:
            llmExtractionInfo?.deductibles?.individualInNetworkCost,
        } as PlanDeductibleOOPNetwork,
        outNetwork: {
          covered: deductibles?.individualOutOfNetworkApplicable,
          coveredAmount: deductibles?.individualOutOfNetworkCost,
          valueMapping: {
            COVERAGE: 'deductibles.individualOutOfNetworkApplicable',
            COST: 'deductibles.individualOutOfNetworkCost',
          },
          llmExtractionInfo:
            llmExtractionInfo?.deductibles?.individualOutOfNetworkCost,
        } as PlanDeductibleOOPNetwork,
      };

      const familyDeductible = {
        serviceLabel: 'Family Deductible',
        inNetwork: {
          covered: deductibles?.familyInNetworkApplicable,
          coveredAmount: deductibles?.familyInNetworkCost,
          valueMapping: {
            COVERAGE: 'deductibles.familyInNetworkApplicable',
            COST: 'deductibles.familyInNetworkCost',
          },
          llmExtractionInfo:
            llmExtractionInfo?.deductibles?.familyInNetworkCost,
        } as PlanDeductibleOOPNetwork,
        outNetwork: {
          covered: deductibles?.familyOutOfNetworkApplicable,
          coveredAmount: deductibles?.familyOutOfNetworkCost,
          valueMapping: {
            COVERAGE: 'deductibles.familyOutOfNetworkApplicable',
            COST: 'deductibles.familyOutOfNetworkCost',
          },
          llmExtractionInfo:
            llmExtractionInfo?.deductibles?.familyOutOfNetworkCost,
        } as PlanDeductibleOOPNetwork,
      };

      const planYearMax = {
        serviceLabel: 'Plan Year Max',
        inNetwork: {
          covered: calendarYearMax?.inNetworkApplicable,
          coveredAmount: calendarYearMax?.inNetworkCost,
          valueMapping: {
            COVERAGE: 'calendarYearMax.inNetworkApplicable',
            COST: 'calendarYearMax.inNetworkCost',
          },
          llmExtractionInfo: llmExtractionInfo?.calendarYearMax?.inNetworkCost,
        } as PlanDeductibleOOPNetwork,
        outNetwork: {
          covered: calendarYearMax?.outOfNetworkApplicable,
          coveredAmount: calendarYearMax?.outOfNetworkCost,
          valueMapping: {
            COVERAGE: 'calendarYearMax.outOfNetworkApplicable',
            COST: 'calendarYearMax.outOfNetworkCost',
          },
          llmExtractionInfo:
            llmExtractionInfo?.calendarYearMax?.outOfNetworkCost,
        } as PlanDeductibleOOPNetwork,
      };

      const orthodontiaMax = {
        serviceLabel: 'Orthodontia Max',
        inNetwork: {
          covered: orthodontiaLifetimeMax?.inNetworkApplicable,
          coveredAmount: orthodontiaLifetimeMax?.inNetworkCost,
          valueMapping: {
            COVERAGE: 'orthodontiaLifetimeMax.inNetworkApplicable',
            COST: 'orthodontiaLifetimeMax.inNetworkCost',
          },
          llmExtractionInfo:
            llmExtractionInfo?.orthodontiaLifetimeMax?.inNetworkCost,
        } as PlanDeductibleOOPNetwork,
        outNetwork: {
          covered: orthodontiaLifetimeMax?.outOfNetworkApplicable,
          coveredAmount: orthodontiaLifetimeMax?.outOfNetworkCost,
          valueMapping: {
            COVERAGE: 'orthodontiaLifetimeMax.outOfNetworkApplicable',
            COST: 'orthodontiaLifetimeMax.outOfNetworkCost',
          },
          llmExtractionInfo:
            llmExtractionInfo?.orthodontiaLifetimeMax?.outOfNetworkCost,
        } as PlanDeductibleOOPNetwork,
      };

      setDeductibleOopMax([
        individualDeductible,
        familyDeductible,
        planYearMax,
        orthodontiaMax,
      ]);
    }, [dentalPlan]);

    useImperativeHandle(ref, () => ({
      validate: () => ({ isValid: true, isComplete: getIsComplete() }),
    }));

    const getIsComplete = (): boolean => {
      let isComplete = false;

      // Check completion for plan year max and orthodontia lifetime max
      isComplete = [
        dentalPlan.calendarYearMax,
        dentalPlan.orthodontiaLifetimeMax,
      ].every((deductible: any) => {
        if (isEmpty(deductible)) return false;
        return ['inNetwork', 'outOfNetwork'].every((network) => {
          const applicableField = network + 'Applicable';
          const costField = network + 'Cost';
          if (isEmpty(deductible[applicableField])) return false;
          if (deductible[applicableField] === 'COVERED') {
            return !isEmptyValue(deductible?.[costField]);
          }
          return true;
        });
      });

      // Check completion for individual and family deductible
      const allFields = [
        'familyInNetwork',
        'familyOutOfNetwork',
        'individualInNetwork',
        'individualOutOfNetwork',
      ] as const;
      const deductibles: any = dentalPlan.deductibles;
      isComplete =
        isComplete &&
        allFields.every((network) => {
          const applicableField = network + 'Applicable';
          const costField = network + 'Cost';
          if (isEmpty(deductibles[applicableField])) return false;
          if (deductibles[applicableField] === 'COVERED') {
            return !isEmptyValue(deductibles[costField]);
          }
          return true;
        });

      return isComplete;
    };

    const onValueChange = (mapping: any, changes: []) => {
      const dentalPlanCopy = cloneDeep(dentalPlan) || {};
      changes.forEach((change) => {
        const { key, value } = change;
        const path = mapping[key];
        set(dentalPlanCopy, path, value);
      });

      onChange(dentalPlanCopy);
    };

    return (
      <div className={styles.deductiblesOopWrapper}>
        <Row>
          {deductibleOopMax.map((row: PlanDeductibleOOPMax, index: number) => (
            <Col key={`deductible-oop-max-row-${index}`} md={!isEdit ? 12 : 24}>
              <div
                className={
                  isEdit
                    ? styles.deductiblesEditItemWrapper
                    : styles.deductibleOopItemWrapper
                }
              >
                <Row>
                  <InfoIconComponent
                    showIcon={isUploadedPlan}
                    extractionInfo={row?.inNetwork.llmExtractionInfo}
                    className={styles.infoIcon}
                  />
                  <Col span={24}>
                    <div className="text table-row-title">
                      {row.serviceLabel}
                    </div>
                  </Col>
                </Row>
                <div
                  className={
                    isEdit ? styles.editNetworkRow : styles.networkTypeRow
                  }
                >
                  <NetworkTypeRow
                    key={index}
                    networkType={IN_NETWORK}
                    networkDeductibleOOPObject={row.inNetwork}
                    onChange={(changes: []) => {
                      onValueChange(row.inNetwork.valueMapping, changes);
                    }}
                    deductibleLabelSpan={8}
                    isTouched={isTouched}
                    decimalScale={0}
                    isOnlyCovered
                    isReviewProp={isReviewProp}
                    highlightLLMValue={
                      row?.inNetwork?.llmExtractionInfo?.different &&
                      isHighlightVisible
                    }
                  />
                </div>

                <div
                  className={
                    isEdit ? styles.editNetworkRow : styles.networkTypeRow
                  }
                >
                  <NetworkTypeRow
                    key={index}
                    networkType={OUT_OF_NETWORK}
                    networkDeductibleOOPObject={row.outNetwork}
                    onChange={(changes: []) => {
                      onValueChange(row.outNetwork.valueMapping, changes);
                    }}
                    deductibleLabelSpan={8}
                    isTouched={isTouched}
                    decimalScale={0}
                    isOnlyCovered
                    isReviewProp={isReviewProp}
                    highlightLLMValue={
                      row?.outNetwork?.llmExtractionInfo?.different &&
                      isHighlightVisible
                    }
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
);

DeductiblesOOPMax.displayName = 'DeductiblesOOPMax';

export default DeductiblesOOPMax;
