import React, { FC, useEffect, useState } from 'react';

import { Col, Input, Row } from 'antd';

import dayjs from 'dayjs';
import IdCardEmployeeDataBody from 'modules/idCards/components/IDCardEmployeeDataBody/IdCardEmployeeDataBody';
import IdCardEmployeeData from 'modules/idCards/components/IDCardEmployeeData/IdCardEmployeeData';
import { useUpdateElectionMemberIdMutation } from 'modules/idCards/slices/idCardApiSlice';
import { usDataFormat } from 'constants/dateFormat';
import { OFFER_CATEGORY } from 'modules/renewals/constants/renewalsConstants';
import styles from './idCrad.module.less';

const IdCard: FC<any> = ({
  idData,
  benefitKind,
  planYearId,
  triggerRefresh,
  isEmployeeSelected,
  employeeDetails,
  isOpsAdmin,
}) => {
  const isNonEditable = (): boolean => {
    if (employeeDetails?.selfDeclared) {
      return false;
    }
    switch (benefitKind) {
      case OFFER_CATEGORY.MEDICAL:
        return employeeDetails?.medicalUserAmended;
      case OFFER_CATEGORY.DENTAL:
        return employeeDetails?.dentalUserAmended;
      case OFFER_CATEGORY.VISION:
        return employeeDetails?.visionUserAmended;
      default:
        return false;
    }
  };

  const getApplicableMemberDataWithoutPrefix = (): string => {
    switch (benefitKind) {
      case 'MEDICAL':
        return idData?.medicalMemberId;
      case 'DENTAL':
        return idData?.dentalMemberId;
      case 'VISION':
        return idData?.visionMemberId;
      default:
        return '';
    }
  };

  const getApplicableMemberData = (): string => {
    if (isOpsAdmin) {
      return 'encrypted';
    }
    switch (benefitKind) {
      case 'MEDICAL':
        return concatAlphaIfExist(idData?.medicalMemberId);
      case 'DENTAL':
        return idData?.dentalMemberId;
      case 'VISION':
        return idData?.visionMemberId;
      default:
        return '';
    }
  };

  const [updateMemberIdData, { isSuccess: isUpdateSuccess }] =
    useUpdateElectionMemberIdMutation();

  useEffect(() => {
    if (isUpdateSuccess) {
      setIsEditEnabled(false);
      setIsSaveVisible(false);
      triggerRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (!isEmployeeSelected) {
      setIsEditEnabled(false);
      setIsSaveVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmployeeSelected]);

  const getMemberId = (): any => {
    return (
      <>
        {!isEditEnabled || isOpsAdmin
          ? getApplicableMemberData()
          : generateEditableText()}
      </>
    );
  };

  const generateEditableText = () => {
    return (
      <Input
        className={styles.editableInput}
        value={memberId}
        onChange={handleEditableInputChange}
      />
    );
  };

  const handleEditableInputChange = (e: any) => {
    setMemberId(e.target.value);
  };

  const [isEditEnabled, setIsEditEnabled] = useState<boolean>(false);
  const [isSaveVisible, setIsSaveVisible] = useState<boolean>(false);
  const [memberId, setMemberId] = useState<string>('');

  const handleEditClick = () => {
    setMemberId(getApplicableMemberDataWithoutPrefix());
    setIsEditEnabled(true);
    setIsSaveVisible(true);
  };

  const handleSaveClick = () => {
    // send api call to handle save
    updateMemberIdData({
      benefitKind,
      memberId,
      planYearId,
      individualId: idData?.individualId,
    });
  };

  const concatAlphaIfExist = (id: string) => {
    if (idData?.alphaValue) {
      const alpha: string = idData?.alphaValue;
      return alpha.concat(id);
    }
    return id;
  };

  return (
    <div className={styles.idCard}>
      <div className={styles.cardHeader}>
        <Row justify={'space-between'}>
          <Col>
            <img src={idData?.carrierLogoUrl} className={styles.logo1} />
          </Col>
          <Col>
            <img src={idData?.employerLogoUrl} className={styles.logo1} />
          </Col>
        </Row>
        <Row className={idData?.basicInfo && styles.basicInfo}>
          {!idData?.basicInfo && (
            <Col>
              <h1 className={styles.heading}>{idData?.planName}</h1>
              <span className={styles.effectiveDates}>
                Effective Dates: {dayjs(idData?.startDate).format(usDataFormat)}
              </span>
            </Col>
          )}
        </Row>
      </div>
      <div className={styles.cardBody}>
        <Row>
          <Col span={14} className={styles.cardText}>
            <IdCardEmployeeData
              data={{ name: 'Name', primarySubscriber: `Primary Subscriber` }}
            />
          </Col>
          <Col span={10} className={styles.employeeData}>
            <IdCardEmployeeData
              data={{
                name: `${idData?.individualName?.firstName} ${idData?.individualName?.lastName}`,
                primarySubscriber: `${idData?.subscriberName?.firstName} ${idData?.subscriberName?.lastName}`,
              }}
            />
          </Col>
        </Row>
        <hr className={styles.line} />
        <Row>
          <Col span={14}>
            <Row gutter={4}>
              <Col span={8} className={styles.cardText}>
                <IdCardEmployeeDataBody
                  data={{
                    field1: 'ID #',
                    field2: 'Group ID #',
                    field3: 'Phone',
                  }}
                />
              </Col>
              <Col span={16} className={styles.employeeData}>
                <IdCardEmployeeDataBody
                  data={{
                    field1: getMemberId(),
                    field2: idData?.groupId ?? '',
                    phone: idData?.carrierPhone ?? ' ',
                  }}
                  isEditApplicable={isEmployeeSelected && !isOpsAdmin}
                  isNonEditable={isNonEditable()}
                  handleEditClick={handleEditClick}
                  isSaveVisible={isSaveVisible}
                  handleSaveClick={handleSaveClick}
                  withoutPrefixId={getApplicableMemberDataWithoutPrefix()}
                />
              </Col>
            </Row>
          </Col>
          {OFFER_CATEGORY.MEDICAL === benefitKind && (
            <Col span={10}>
              <Row gutter={40}>
                <Col span={10} className={styles.cardText}>
                  <IdCardEmployeeDataBody
                    data={{
                      field1: 'Rx Bin #',
                      field2: 'Rx Pcn #',
                      field3: 'Rx Group #',
                    }}
                  />
                </Col>
                <Col span={14} className={styles.employeeData}>
                  <IdCardEmployeeDataBody
                    isCompact={true}
                    data={{
                      field1: idData?.rxBin,
                      field2: idData?.rxPcn,
                      field3: idData?.rxGroup,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default IdCard;
