import { FC, ReactNode } from 'react';
import styles from './ContentContainer.module.less';

interface Props {
  actionsPanel?: ReactNode;
  children: ReactNode;
}

const ContentContainer: FC<Props> = (props: Props) => {
  const { children, actionsPanel } = props;

  return (
    <div className={styles.contentContainer}>
      <div className={styles.mainContent}>{children}</div>
      {actionsPanel && <div className={styles.panelRight}>{actionsPanel}</div>}
    </div>
  );
};

export default ContentContainer;
