import { ChangeEvent, FC, useState, useEffect, useMemo } from 'react';

import { Menu, Row, Image, Skeleton } from 'antd';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import DataTable from 'components/DataTable/DataTable';
import Filter from 'components/Filter/Filter';
import { DataColumn } from 'components/DataTable/DataColumn';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import SearchBar from 'components/SearchBar/SearchBar';

import { allOption, MASTER } from 'constants/commonConstants';
import { benefitTypes } from 'modules/carriers/constants/carrierConstants';

import TablePagination from 'model/TablePagination';
import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
  getNavContext,
} from 'util/commonUtil';
import BenefitCarrier from 'model/BenefitCarrier';

import { getCarrierList } from 'modules/carriers/slices/carrierSlice';
import { navContexts } from 'constants/authConstants';
import CustomCarrier from 'modules/carriers/components/CustomCarrier/customCarrier';

import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import styles from './brokerCarrierList.module.less';

type BrokerCarrierListProps = {};

const BrokerCarrierList: FC<BrokerCarrierListProps> = (
  props: BrokerCarrierListProps
) => {
  const [searchText, setSearchText] = useState<string>('');
  const [selectedBenefitCategories, setSelectedBenefitCategories] =
    useState<string>('');
  const { state: stateValue } = useLocation();
  const { userId } = stateValue || ({} as any);

  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );

  const getData = (filters: TablePagination) => {
    if (filters.sorterInfo) {
      const paginationConfigData = {
        sorterInfo: filters.sorterInfo,
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      };
      setPaginationConfig(paginationConfigData);
    } else {
      const paginationConfigData = {
        sorterInfo: {
          columnKey: 'name',
          field: 'name',
          order: 'ascend',
        },
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      } as TablePagination;
      setPaginationConfig(paginationConfigData);
    }
  };

  const [isModalOpen] = useState<boolean>(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  const navigate = useNavigate();
  const { setCarrier, context, brokerId, employerId } = useNavContext();

  const dispatch = useAppDispatch();
  const carrierList = useAppSelector((state) => state.carriers.carrierList);
  const carrierListInProgress = useAppSelector(
    (state) => state.carriers.carrierListInProgress
  );

  const { metadata } = carrierList || {};
  const total = metadata ? metadata.total : '0';
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};
  const params = useParams();
  useEffect(() => {
    setCarrier(null, null, null);
    dispatch(
      getCarrierList(
        convertPaginationConfig(paginationConfig),
        brokerId,
        employerId,
        getNavContext(params) === navContexts.platform
          ? MASTER
          : getNavContext(params),
        selectedBenefitCategories
      )
    );
  }, [
    dispatch,
    employerId,
    paginationConfig,
    selectedBenefitCategories,
    params,
    brokerId,
    setCarrier,
  ]);

  const getBenefitCategoryList = (
    benefitCarrierList: any,
    isDelete?: boolean
  ) => {
    const benefitCategories: any[] = [];
    if (benefitCarrierList) {
      benefitCarrierList.forEach(function (benefitCarrier: any) {
        benefitCategories.push(benefitCarrier);
      });
    }
    if (isDelete) {
      return benefitCategories.map((benefitCategory) => (
        <div
          className={styles.carrierBenefits}
          key={benefitTypes[benefitCategory]?.value}
        >
          {benefitTypes[benefitCategory]?.label}
        </div>
      ));
    } else {
      return benefitCategories
        .map((benefitCategory) => benefitTypes[benefitCategory]?.label)
        .join(', ');
    }
  };

  const convertCarriersForTableRender = (carriers: BenefitCarrier[]): any[] => {
    if (carriers != null) {
      return carriers.map((element) => ({
        key: element.id,
        name: element,
        benefitCategory: (
          <OverflowPopover>
            {getBenefitCategoryList(element.benefitCategories)}
          </OverflowPopover>
        ),
        actions: element,
      }));
    }
    return [];
  };

  const getNavigateUrl = (id: string) => {
    if (context === navContexts.platform) {
      return `/carriers/${id}/info`;
    } else if (context === navContexts.broker) {
      return `/brokers/${brokerId}/carriers/${id}/info`;
    } else {
      return `/brokers/${brokerId}/employers/${employerId}/carriers/${id}/info`;
    }
  };

  const carrierActions = (carrier: BenefitCarrier) => {
    return (
      <Menu>
        <Menu.Item
          key="archive"
          onClick={() => {
            setCarrier(carrier.id, carrier.name, null);
            navigate(getNavigateUrl(carrier.id));
          }}
        >
          View
        </Menu.Item>
      </Menu>
    );
  };
  const logoSkelton = (
    <div>
      <Skeleton.Input className={styles.logoImg} />
    </div>
  );
  const carrierTableColumns: DataColumn[] = [
    {
      title: 'CARRIER',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      defaultSortOrder: 'ascend',
      ellipsis: {
        showTitle: false,
      },
      sorter: (a: any, b: any) => 0, // this sort is done by backend.
      render: (item: BenefitCarrier) => {
        const { id, name, customCarrier, logoUrl } = item;
        return (
          <span
            className={styles.nameColStyle}
            onClick={() => {
              setCarrier(id, name, null);
              navigate(getNavigateUrl(id));
            }}
          >
            <Image
              src={logoUrl}
              preview={false}
              alt={name}
              className={styles.logoImg}
              placeholder={logoSkelton}
            />
            <span className={styles.carrierName}>
              <OverflowPopover popoverContent={name} maxWidth={200}>
                {name}
              </OverflowPopover>
            </span>
            <>{customCarrier && <CustomCarrier />}</>
          </span>
        );
      },
    },
    {
      title: 'BENEFIT CATEGORIES',
      dataIndex: 'benefitCategory',
      key: 'benefitCategory',
      width: '40%',
      defaultSortOrder: 'ascend',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      render: (item: BenefitCarrier) => {
        return (
          <div className={styles.dropdownWrapper}>
            <SelectDropdown
              overlay={carrierActions(item)}
              className="text-select-action"
            />
          </div>
        );
      },
      align: 'left',
    },
  ];

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setSearchText(_searchText);
    const pageInfo = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText: _searchText },
    };
    debounceLoadData(pageInfo);
  };

  const filterBenefitCategories = () => {
    const allOptions = { label: 'All Benefit Categories', value: allOption };
    const options =
      Object.values(benefitTypes)
        ?.map((item) => ({
          label: item.label,
          value: item.value,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [];
    return [allOptions].concat(options);
  };

  const onSelectItems = (selectedItems: string[]) => {
    const filters = selectedItems.includes(allOption)
      ? ''
      : selectedItems.toString();
    setPaginationConfig({ ...paginationConfig, paginationIndex: 1 });
    return filters;
  };

  const onSelectBenefitCategories = (selectedItems: string[]) => {
    const filters = onSelectItems(selectedItems);
    setSelectedBenefitCategories(filters);
  };

  return (
    <div className={styles.carrierTableWrapper}>
      <br />
      <Row className={styles.filterContainer}>
        <SearchBar placeholder="Search Carriers" onChange={handleSearch} />
        <Filter
          options={filterBenefitCategories()}
          placeholder="All Benefit Categories"
          onSelectItems={onSelectBenefitCategories}
          filterValue={userId}
          searchName="Search Benefit Categories"
          selected={[]}
          isFilterClose={isModalOpen}
        />
      </Row>
      <br />
      <DataTable
        currentIndex={paginationConfig.paginationIndex}
        data={convertCarriersForTableRender(carrierList.content)}
        columns={carrierTableColumns}
        pagination={true}
        loading={carrierListInProgress}
        getData={getData}
        total={total}
        pageSize={limit}
      />
    </div>
  );
};
export default BrokerCarrierList;
