import { Dispatch } from 'redux';
import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdditionalPlan } from 'model/plans/AdditionalPlan';
import * as WellbeingPlanService from 'modules/plans/services/WellbeingPlanService';
import MetaData from 'model/MetaData';
import PaginationConfig from 'model/PaginationConfig';
import ErrorDetails from 'model/ErrorDetails';
import ClonePlanDocument from 'model/plans/ClonePlanDocument';
import { BenefitCategory } from 'constants/commonConstants';
import { clonePlanDocuments } from './planDocumentsSlice';

export type WellbeingPlanServiceState = {
  inProgress: boolean;
  error: any;
  requestType: string;
  wellbeingPlan: AdditionalPlan;
  wellbeingPlanList: { content: Array<AdditionalPlan>; metadata: MetaData };
};

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  wellbeingPlan: {} as AdditionalPlan,
  wellbeingPlanList: { content: [], metadata: {} },
} as WellbeingPlanServiceState;

const wellbeingPlanSlice = createSlice({
  name: 'wellbeingPlan',
  initialState,
  reducers: {
    createWellbeingPlan: (state, action: PayloadAction<AdditionalPlan>) => {
      state.wellbeingPlan = action.payload;
      state.inProgress = false;
      state.error = null;
      state.requestType = action.type;
    },
    updateWellbeingPlanInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    updateWellbeingPlanCompleted: (
      state,
      action: PayloadAction<AdditionalPlan>
    ) => {
      state.inProgress = false;
      state.wellbeingPlan = { ...state.wellbeingPlan, ...action.payload };
      state.error = null;
      state.requestType = action.type;
    },
    updateWellbeingPlanFailed: (state, action) => {
      state.inProgress = false;
      state.error = JSON.parse(JSON.stringify(action.payload));
      state.requestType = action.type;
    },
    getWellbeingPlansListInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    getWellbeingPlansListCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.inProgress = false;
      state.error = null;
      state.wellbeingPlanList = payload;
    },
    getWellbeingPlansListFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = { response: action.payload };
    },
    getPlanByIdStarted: (state) => {
      state.inProgress = true;
      state.wellbeingPlan = {} as AdditionalPlan;
      state.error = null;
    },
    getPlanByIdCompleted: (state, action: PayloadAction<AdditionalPlan>) => {
      state.inProgress = false;
      state.wellbeingPlan = action.payload;
      state.error = null;
    },
    getPlanByIdFailed: (state, action) => {
      state.inProgress = false;
      state.wellbeingPlan = {} as AdditionalPlan;
      state.error = { response: action.payload };
    },
    clearWellBeingPlanApiErrors: (state) => {
      state.error = null;
    },
  },
});

export const {
  createWellbeingPlan,
  updateWellbeingPlanInProgress,
  updateWellbeingPlanCompleted,
  updateWellbeingPlanFailed,
  getWellbeingPlansListInProgress,
  getWellbeingPlansListCompleted,
  getWellbeingPlansListFailed,
  getPlanByIdStarted,
  getPlanByIdCompleted,
  getPlanByIdFailed,
  clearWellBeingPlanApiErrors,
} = wellbeingPlanSlice.actions;

export default wellbeingPlanSlice.reducer;

export const saveWellbeingPlan = (
  plan: AdditionalPlan,
  onSave: Function,
  cloneDocuments?: boolean,
  sourcePlanId?: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateWellbeingPlanInProgress());
      let response = {} as any;
      if (plan.id) {
        response = await WellbeingPlanService.createWellbeingPlan(plan, true);
      } else {
        response = await WellbeingPlanService.createWellbeingPlan(plan);
      }
      const data = response.data;
      dispatch(updateWellbeingPlanCompleted(data));
      if (data && cloneDocuments && plan.documents && sourcePlanId) {
        const clonePlanDoc: ClonePlanDocument = {
          employerId: data.employerId,
          sourcePlanId: sourcePlanId,
          targetPlanId: data.id,
          benefitCategory: BenefitCategory.WELLBEING.value,
          benefitKind: plan.benefitKind,
        };
        dispatch(clonePlanDocuments(clonePlanDoc));
      }
      onSave(data.id);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            updateWellbeingPlanFailed({
              data: error.response.data,
            } as ErrorDetails)
          );
        }
      }
    }
  };
};

export const getWellbeingPlanList = (
  { page, size, sort, query }: PaginationConfig,
  employerId: string,
  planYearId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getWellbeingPlansListInProgress());
    try {
      const response = await WellbeingPlanService.getWellbeingPlanList(
        page,
        size,
        sort,
        query,
        employerId,
        planYearId,
        benefitKind
      );
      dispatch(getWellbeingPlansListCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getWellbeingPlansListFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const findWellbeingPlanById = (planId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPlanByIdStarted());
    try {
      const response = await WellbeingPlanService.getPlanById(planId);
      dispatch(getPlanByIdCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(getPlanByIdFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
};
