import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty, isUndefined } from 'lodash';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Stepper from 'components/Stepper/Stepper';
import BasicInfo from 'modules/renewals/components/AddOfferComponents/BasicInfo/BasicInfo';
import AddPlans from 'modules/renewals/components/AddOfferComponents/AddPlans/AddPlans';
import PreviewOffer from 'modules/renewals/components/PreviewOffer/PreviewOffer';
import CreditsAndRates from 'modules/renewals/components/AddOfferComponents/CreditsAndRates/CreditsAndRates';
import {
  useCreateOfferMutation,
  useLazyGetOfferExistsQuery,
  usePublishOrDraftOfferMutation,
  useGetCurrentPlansQuery,
  useGetOfferedPlanProcessingStatusByOfferIdQuery,
} from 'modules/renewals/slices/offersSlice';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  ADD_PLAN_OPTIONS,
  ALTERNATIVE,
  BASIC_LIFE,
  BASIC_LIFE_AND_ADD,
  FILE_IMPORT,
  FROM_EXISTING_PLANS,
  LIFE,
  LIFE_PLANS,
  LTD,
  MANUAL_PLANS,
  MDV_PLANS,
  NONE,
  OFFER_BENEFIT_TYPE_NAVIGATION_LABELS,
  OFFER_CARD_STATUS,
  OFFER_CATEGORY,
  OFFER_PLAN_PROPERTY,
  OFFER_STATUS,
  OFFER_STATUSES,
  ORTHODONTIA_MAX,
  QUOTE_ADDING_PROPERTY,
  STD,
  URL_PARAM_TO_BENEFIT_TYPE,
  VOLUNTARY_BENEFIT,
} from 'modules/renewals/constants/renewalsConstants';
import { ReviewType } from 'modules/renewals/enums/ReviewType';
import ProcessStatus from 'modules/renewals/enums/QuoteUploadStatus';
import UpdateConfirmationListModal from 'modules/renewals/components/helperComponents/UpdateConfirmationListModal/UpdateConfirmationListModal';
import {
  clearAssociatedProposalList,
  getAssociatedProposals,
} from 'modules/renewals/slices/offerReducerSlice';
import { isNullOrUndefined } from 'modules/plans/utils';
import { CurrentPlanResponseType } from 'modules/renewals/types/currentPlanResponseTypes';
import {
  buildPremiums,
  compareMappedPlansAndGetUnMappedPlanList,
  getCurrentPlansForSubmit,
  getEffectiveDateFormatted,
  handleRedirectToProposalsDetails,
  initialQuoteMappingData,
  isDtqOrPending,
  validateNonMDVObj,
} from 'modules/renewals/utils/renewalsUtils';
import {
  clear,
  getDuelLLMEnable,
  setOfferId,
} from 'modules/renewals/slices/carrierQuoteAiSlice';
import { QuoteMappingType } from 'modules/renewals/types/QuoteMappingType';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import {
  checkMDV,
  isNoCurrentPlans,
} from 'modules/renewals/utils/addOfferUtil';
import { isEmptyOrUndefined } from 'util/stringUtil';
import { LLM_PROMPT_TYPE } from 'constants/commonConstants';

import styles from './addOffer.module.less';

type Props = {
  currentStep: number;
  closeModal: () => void;
  nextStep: () => void;
  changeCurrentStep: (step: number) => any;
  isEdit: boolean;
  onAddOffer: () => void;
  editData?: any;
  isClonedOffer: boolean | undefined;
  closeModalWithoutPopup: ({
    isRedirectToOps,
    isNavigateToCurrentTab,
  }: {
    isRedirectToOps?: boolean;
    isNavigateToCurrentTab: boolean;
  }) => void;
  setSelectedTab?: Function;
  onExternalPublish: Function;
  refetchPlanDetails: () => void;
};

const getInitialState = (
  editData: any,
  isClonedOffer: boolean,
  benefitKind: string,
  brokerId: string,
  employerId: string,
  documentDetails: any,
  currentPlans: CurrentPlanResponseType
) => {
  if (editData === undefined) {
    return {
      offerName: '',
      carrierId: '',
      carrierType: '',
      effectiveDates: '',
      offerStatus: '',
      offerDescription: '',
      benefitCategory: URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? ''],
      organizationId: brokerId,
      employerId: employerId,
      s3DocumentDetails: documentDetails,
      fundingType: '',
      type: ALTERNATIVE,
      carrierDocuments: [],
      extractionDetails: null,
      isNoCurrentPlans: isNoCurrentPlans(currentPlans),
    };
  } else {
    return {
      offerName: `${isClonedOffer ? 'Clone - ' : ''}${editData?.name}`,
      carrierId: editData?.carrier?.id,
      carrierType: editData?.carrierType,
      carrierDocuments: editData?.carrierDocuments,
      effectiveDates: getEffectiveDateFormatted(
        editData?.effectiveDateFrom,
        editData?.effectiveDateTo
      ),
      offerStatus:
        editData?.offerStatus === null ? 'RECEIVED' : editData?.offerStatus,
      offerDescription: editData?.description ?? '',
      benefitCategory: URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? ''],
      employerId: employerId,
      organizationId: brokerId,
      s3DocumentDetails: editData?.s3DocumentDetails,
      fundingType: editData?.fundingType ?? '',
      currentPlans: editData?.currentPlans ?? [],
      [QUOTE_ADDING_PROPERTY?.[benefitKind?.toUpperCase() ?? '']]: editData?.[
        OFFER_PLAN_PROPERTY?.[URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? '']]
      ]?.map((item: any) => ({ ...item, keyRefId: item?.id })),
      creditAmount: editData?.discountAndCredit,
      creditDescription: editData?.discountAndCreditDescription,
      rate: editData?.rate,
      type: editData?.type,
      discontinuedPlanMap: editData?.discontinuedPlanMap,
      offerId: editData?.offerId,
      effectiveDateFrom: editData?.effectiveDateFrom,
      effectiveDateTo: editData?.effectiveDateTo,
      planCreationType: editData?.planCreationType,
      extractionDetails: editData?.extractionDetails,
      jobId: editData?.jobId,
      quoteOfferApproved: editData?.quoteOfferApproved ?? false,
      quoteProcessingStatus: editData?.quoteProcessingStatus ?? null,
      isNoCurrentPlans: isNoCurrentPlans(currentPlans),
    };
  }
};

const AddOffer = (props: Props, ref: any) => {
  const {
    nextStep,
    currentStep,
    changeCurrentStep,
    closeModal,
    isEdit,
    onAddOffer,
    editData,
    isClonedOffer,
    closeModalWithoutPopup,
    setSelectedTab,
    onExternalPublish,
  } = props;
  const addOffersRef = useRef<any>(null);
  const addPlansRef = useRef<any>(null);
  const { status, jobId, reviewType } = useAppSelector(
    (state) => state.renewals.quoteReaderSlice
  );

  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );
  const { employerId, benefitKind, brokerId } = useParams();
  const dispatch = useAppDispatch();

  const [documentDetails, setDocumentDetails] = useState<any>(
    editData !== undefined &&
      (editData?.s3DocumentDetails?.fileName ?? null) !== null
      ? editData?.s3DocumentDetails
      : null
  );

  const { data: currentPlans, isSuccess: currentPlanIsSuccess } =
    useGetCurrentPlansQuery(
      {
        employerId: employerId ?? '',
        benefitType: URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? ''],
        offerStatus: editData?.status,
      },
      { refetchOnMountOrArgChange: true }
    );

  const [showMappedPlansError, setShowMappedPlansError] =
    useState<boolean>(false);

  const initialState = getInitialState(
    editData,
    isClonedOffer ?? false,
    benefitKind ?? '',
    brokerId!,
    employerId!,
    documentDetails,
    currentPlans
  );
  const [offer, setOffer] = useState<any>(initialState);

  const [offerStatus, setOfferStatus] = useState<string>(
    isEmpty(offer?.offerStatus) ? '' : offer?.offerStatus
  );

  const initialQuotePlanMappings = () => {
    return editData === undefined
      ? initialQuoteMappingData
      : editData?.quotePlanMapping ?? initialQuoteMappingData;
  };

  const [quoteMappings, setQuoteMappings] = useState<QuoteMappingType[]>(
    initialQuotePlanMappings()
  );

  const offerAssociatedProposalData = useAppSelector(
    (state) => state.renewals.offers
  );

  const [existsPublishedOffer, setExistsPublishedOffer] =
    useState<boolean>(false);

  const [existsDraftOffer, setExistsDraftOffer] = useState<boolean>(false);

  const checkExistsPublishedAndDraftOffer = async () => {
    const response = await getOfferNameExists({
      employerId: employerId ?? '',
      status: 'PUBLISHED',
      name: encodeURIComponent(offer?.offerName),
      category: offer?.benefitCategory,
    }).unwrap();
    setExistsPublishedOffer(response.existsPublishedOffer);
    setExistsDraftOffer(response.existsDraftOffer);
  };

  useEffect(() => {
    checkExistsPublishedAndDraftOffer();
    if (URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? ''] !== VOLUNTARY_BENEFIT) {
      dispatch(
        getDuelLLMEnable({
          promptType: LLM_PROMPT_TYPE.CARRIER_QUOTES,
          benefit:
            URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? ''] === LIFE
              ? BASIC_LIFE
              : URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? ''],
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: planExtractionData,
    isSuccess: isSuccessOfferedPlanStatusFetch,
  } = useGetOfferedPlanProcessingStatusByOfferIdQuery(
    {
      offerId: editData?.offerId,
      timestamp: isEdit,
    },
    { skip: !editData?.offerId }
  );
  const [isExternalPublishFlow, setIsExternalPublishFlow] =
    useState<boolean>(false);
  const [selectedAddingType, setSelectedAddingType] = useState<string>('');

  const [selectedFile, setSelectedFile] = useState<File | null>(
    !isNullOrUndefined(editData) &&
      !isNullOrUndefined(editData?.s3DocumentDetails?.fileName)
      ? new File([], editData?.s3DocumentDetails?.fileName ?? '')
      : null
  );
  const [updatedData, setUpdatedData] = useState<any>(
    editData === undefined
      ? null
      : editData?.[
          OFFER_PLAN_PROPERTY?.[URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? '']]
        ]?.map((item: any) => ({
          ...item,
          keyRefId:
            item?.id === null &&
            editData?.planCreationType === ADD_PLAN_OPTIONS.CARRIER_QUOTE.key
              ? uuidv4()
              : item?.id,
        })) ?? null
  );

  const [carrierImage, setCarrierImage] = useState<string>('');
  const [mappedPlans, setMappedPlans] = useState<any[]>(
    editData === undefined ? [] : editData?.currentPlans ?? []
  );

  const [previewOffer, setPreviewOffer] = useState<any>();
  const [showOverwritePopup, setShowOverwritePopup] = useState<boolean>(false);
  const [tempData, setTempData] = useState<any>(null);
  const [selectedDropdownPlans, setSelectedDropdownPlans] = useState<string[]>(
    []
  );

  const [savingMode, setSavingMode] = useState<string>('');
  const [showUpdateOfferInPropModal, setShowUpdateOfferInPropModal] = useState({
    show: false,
    overwrite: false,
  });
  const [carrierName, setCarrierName] = useState<string>('');
  const [offerNameExists, setOfferNameExists] = useState<boolean>(false);
  const [isNotificationActive, setIsNotificationActive] =
    useState<boolean>(false);

  const [
    publishOrDraftOffer,
    { isSuccess: isPublishOrDraftSuccess, isLoading: isPublishOrDraftLoading },
  ] = usePublishOrDraftOfferMutation();
  const [getOfferExists, {}] = useLazyGetOfferExistsQuery();
  const [getOfferNameExists] = useLazyGetOfferExistsQuery();
  const [
    createOffer,
    {
      data: draftData,
      isSuccess: isOfferCreateSuccess,
      isLoading: isOfferCreateLoading,
    },
  ] = useCreateOfferMutation();

  const isQuoteProcessingEnabled =
    offer?.planCreationType === ADD_PLAN_OPTIONS.CARRIER_QUOTE.key ?? false;

  const params = useParams();
  const navigate = useNavigate();
  const offerDraftStatus: string[] = [
    OFFER_STATUS.PUBLISHED,
    OFFER_STATUS.DRAFT,
  ];

  const navigateBackToOfferList = () => {
    return navigate(
      `/brokers/${params?.brokerId}/employers/${params?.employerId}/renewals/carrier/${params.benefitKind}`,
      { state: { status } }
    );
  };

  useEffect(() => {
    // sets offerId in carrierQuoteAiSlice if it is not set
    const offerId = draftData?.offerId;
    const draftPlanType = draftData?.planCreationType;

    if (offerId && draftPlanType === ADD_PLAN_OPTIONS.CARRIER_QUOTE.key) {
      dispatch(setOfferId(offerId));
    }
  }, [dispatch, draftData?.offerId, draftData?.planCreationType]);

  const isNoCurrentPlansCallback = useCallback(isNoCurrentPlans, [
    currentPlans?.currentPlans,
  ]);

  useEffect(() => {
    if (currentPlanIsSuccess) {
      setOffer({
        ...offer,
        isNoCurrentPlans: isNoCurrentPlansCallback(currentPlans),
      });
    }
    // eslint-disable-next-line
  }, [currentPlanIsSuccess]);

  /**
   * Sets mapped plans array based on the length of updated data if there are no current plans and the current plan operation is successful.
   * @param {boolean} isNoCurrentPlansCallback - A boolean indicating whether there are no current plans.
   * @param {boolean} currentPlanIsSuccess - A boolean indicating whether the current plan operation is successful.
   * @param {any[]} updatedData - The updated data array.
   */
  useEffect(() => {
    if (isNoCurrentPlansCallback(currentPlans) && currentPlanIsSuccess) {
      const mappedPlanArray = Array?.from({
        length: updatedData?.length || 0,
      }).fill(null);
      setMappedPlans(mappedPlanArray);
    }
    // only need to update when updatedData changes
    // eslint-disable-next-line
  }, [updatedData]);

  const { planYearsList } = useAppSelector((state) => state.employer.employer);

  useEffect(() => {
    if (employerId) {
      dispatch(getPlanYears(employerId));
    }
  }, [dispatch, employerId]);

  const benefitClasses = planYearsList.filter(
    (planYear: any) => planYear?.current
  )[0]?.benefitGroups?.length;

  const isMultipleBenClass = benefitClasses > 1;

  useEffect(() => {
    if (isEdit) {
      dispatch(getAssociatedProposals(editData?.offerId));
    }
    return () => {
      dispatch(clearAssociatedProposalList());
    };
    // eslint-disable-next-line
  }, [isEdit]);

  const resetOfferModal = () => {
    // should not close the modal when processing in self review mode

    const isSelfReviewQuotesProcessing: boolean =
      [ProcessStatus.VALIDATED, ProcessStatus.PROCESSING].includes(status) &&
      reviewType === ReviewType.AUTOMATIC;

    if (
      !isSelfReviewQuotesProcessing &&
      (isPublishOrDraftSuccess || isOfferCreateSuccess)
    ) {
      const isCarrierQuoteWithValidatedStatus =
        (draftData?.planCreationType === ADD_PLAN_OPTIONS.CARRIER_QUOTE.key &&
          [ProcessStatus.VALIDATED, ProcessStatus.FAILED].includes(
            draftData?.extractionDetails?.status
          )) ||
        (draftData?.planCreationType === ADD_PLAN_OPTIONS.CARRIER_QUOTE.key &&
          draftData?.extractionDetails === null);

      setSelectedTab?.((currentTab: string) => {
        return isCarrierQuoteWithValidatedStatus ||
          savingMode === OFFER_STATUS.IN_PROGRESS
          ? currentTab
          : savingMode === OFFER_STATUS.PUBLISHED
          ? OFFER_CARD_STATUS.PUBLISHED
          : OFFER_CARD_STATUS.IN_PROGRESS;
      });

      onAddOffer();
      setShowOverwritePopup(false);
      setIsExternalPublishFlow(false);
      setShowUpdateOfferInPropModal({ show: false, overwrite: false });
      setOfferNameExists(false);
      closeModalWithoutPopup({ isNavigateToCurrentTab: false });
      navigateBackToOfferList();
      dispatch(clear());
    }
  };

  useEffect(() => {
    resetOfferModal();
    // eslint-disable-next-line
  }, [isOfferCreateSuccess, isPublishOrDraftSuccess]);

  const offerAttachedProposal = useMemo(() => {
    const optionArray =
      offerAssociatedProposalData?.associatedProposalList?.map((item) => ({
        label: item?.proposalName,
        value: item?.id,
      }));
    const finalApproved =
      offerAssociatedProposalData?.associatedProposalList?.find(
        (item) => item?.status === 'APPROVED'
      );
    return {
      list: optionArray,
      isFinalApproved: Boolean(finalApproved),
      finalApprovedDetails: finalApproved,
    };
  }, [offerAssociatedProposalData?.associatedProposalList]);

  const getIsEligibleEdit = () => {
    if (isEdit && offerAttachedProposal?.list?.length !== 0) {
      const nullCheckedStatus =
        editData?.offerStatus === null
          ? OFFER_STATUSES.RECEIVED.value
          : editData?.offerStatus;

      const currentOfferStatus = [
        OFFER_STATUSES.DTQ.value,
        OFFER_STATUSES.PENDING.value,
      ].includes(offer.offerStatus)
        ? offer.offerStatus
        : false;

      const checkOfferStatus = currentOfferStatus
        ? currentOfferStatus !== nullCheckedStatus
        : false;

      const checkDate =
        offer?.effectiveDates !==
        getEffectiveDateFormatted(
          editData?.effectiveDateFrom,
          editData?.effectiveDateTo
        );
      return checkOfferStatus || checkDate;
    }
    return false;
  };

  const buildLifePlans = (discontinuedPlanMap?: any) => {
    const lifePlanList = (updatedData ?? []).map((item: any) => ({
      ...item,
      volume: item?.volume?.formattedValue ?? item?.volume?.value ?? null,
      weeklyBenefitMax:
        item?.weeklyBenefitMax?.formattedValue ??
        item?.weeklyBenefitMax?.value ??
        null,
      insuranceType:
        isEmpty(item?.insuranceType) || (item?.insuranceType ?? null) === null
          ? BASIC_LIFE_AND_ADD
          : item?.insuranceType,
      benefitLevel:
        item?.benefitLevel?.formattedValue ?? item?.benefitLevel?.value ?? null,
      ageReductionFromBaseField: item?.ageReductionFromBase,
      guaranteedIssueField: item?.guaranteedIssue,
      lifeAndAddBenefitField: item?.lifeAndAddBenefit,
      lifeAndAddMaximumField: item?.lifeAndAddMaximum,
      totalEligibleEnrollmentsField: item?.totalEligibleEnrollments,
      eliminationPeriodField: item?.eliminationPeriod,
      definitionOfDisabilityField: item?.definitionOfDisability,
      benefitDurationField: item?.benefitDuration,
      preExistingConditionsField: item?.preExistingConditions,
      stateDisabilityIntegrationField: item?.stateDisabilityIntegration,
      monthlyBenefitMaxField: item?.monthlyBenefitMax,
      ownOccupationPeriodField: item?.ownOccupationPeriod,
      w2PreparationField: item?.w2Preparation,
      ageReduction: item?.ageReduction?.value,
      waiverOfPremium: item?.waiverOfPremium?.value,
      acceleratedLifeBenefit: item?.acceleratedLifeBenefit?.value,
      portability: item?.portability?.value,
      definitionOfEarnings: item?.definitionOfEarnings?.value,
      additionalServices: item?.additionalServices,
    }));
    const nullPlanIdList = (lifePlanList ?? []).map((item: any) => ({
      ...item,
      id: null,
    }));

    return {
      ...offer,
      currentPlans: getCurrentPlansForSubmit(mappedPlans, updatedData),
      lifePlans: nullPlanIdList,
      s3DocumentDetails: documentDetails,
      planCreationType: selectedAddingType === '' ? NONE : selectedAddingType,
      discontinuedPlanMap: discontinuedPlanMap
        ? discontinuedPlanMap
        : offer?.discontinuedPlanMap,
      quotePlanMapping: filteredQuoteMappings(),
      jobId: jobId,
    };
  };

  const modifyOrthodontiaMax = (orthodontiaMax: any[]) => {
    return (
      orthodontiaMax?.map((item) => {
        return {
          ...item,
          name: ORTHODONTIA_MAX,
        };
      }) ?? []
    );
  };

  const filteredQuoteMappings = () => {
    return quoteMappings?.filter(
      (item) =>
        !isEmptyOrUndefined(item?.currentPlan) &&
        !isEmptyOrUndefined(item?.planIdentifier)
    );
  };

  const buildPlans = (discontinuedPlanMap?: any) => {
    if (MDV_PLANS?.includes(offer?.benefitCategory)) {
      const plans =
        (updatedData ?? [])?.map((item: any) => {
          let modifiedItem = {
            ...item,
            monthlyPremiums: buildPremiums(
              item?.monthlyPremiums,
              currentPlans?.planTierType
            ),
          };
          if (
            offer?.benefitCategory === OFFER_CATEGORY.DENTAL &&
            item?.orthodontiaMax
          ) {
            modifiedItem = {
              ...modifiedItem,
              orthodontiaMax: modifyOrthodontiaMax(item?.orthodontiaMax),
            };
          }
          return modifiedItem;
        }) ?? [];

      const nullPlanId = (plans ?? []).map((item: any) => ({
        ...item,
        id: null,
      }));

      return {
        ...offer,
        currentPlans: getCurrentPlansForSubmit(mappedPlans, updatedData),
        [QUOTE_ADDING_PROPERTY?.[offer?.benefitCategory ?? ''] ?? 'plans']:
          nullPlanId === null || nullPlanId === undefined ? [] : nullPlanId,
        s3DocumentDetails: documentDetails,
        planCreationType: selectedAddingType === '' ? NONE : selectedAddingType,
        discontinuedPlanMap: discontinuedPlanMap
          ? discontinuedPlanMap
          : offer?.discontinuedPlanMap,
        quotePlanMapping: filteredQuoteMappings(),
        jobId: jobId,
      };
    } else {
      const nullPlanId = (updatedData ?? []).map((item: any) => ({
        ...item,
        id: null,
      }));

      return {
        ...offer,
        currentPlans: getCurrentPlansForSubmit(mappedPlans, updatedData),
        [QUOTE_ADDING_PROPERTY?.[benefitKind?.toUpperCase() ?? '']]:
          nullPlanId === null || nullPlanId === undefined ? [] : nullPlanId,
        s3DocumentDetails: documentDetails,
        planCreationType: selectedAddingType === '' ? NONE : selectedAddingType,
        discontinuedPlanMap: discontinuedPlanMap
          ? discontinuedPlanMap
          : offer?.discontinuedPlanMap,
        quotePlanMapping: filteredQuoteMappings(),
        jobId: jobId,
      };
    }
  };

  useEffect(() => {
    if (editData !== undefined && selectedAddingType === '') {
      if (editData?.planCreationType === FROM_EXISTING_PLANS) {
        const initialPlans =
          editData?.[
            OFFER_PLAN_PROPERTY?.[
              URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? '']
            ]
          ] ?? [];

        setSelectedDropdownPlans(
          initialPlans?.map(
            (item: any) =>
              `${item?.referenceId}${
                isMultipleBenClass && checkMDV(benefitKind)
                  ? '_' + item?.benefitClassName
                  : ''
              }`
          )
        );
      }

      if (editData?.planCreationType === NONE) {
        setSelectedAddingType('');
      } else if (editData?.planCreationType === FILE_IMPORT) {
        setSelectedAddingType(MANUAL_PLANS);
      } else {
        setSelectedAddingType(editData?.planCreationType);
      }
    }
    // eslint-disable-next-line
  }, [editData]);

  useImperativeHandle(ref, () => ({
    basicInfoValuesSave: async () => {
      let formData = null as any;
      let response = false as any;
      let planData = updatedData;
      if (currentStep === 0) {
        await addOffersRef?.current
          ?.getOfferBasicInfoFormData()
          ?.then((data: any) => {
            formData = data;
          });
      }

      if (currentStep === 0 && validateBasicInfoForm(formData)) {
        response = await handleBasicInfoNextStep(formData, true);
      } else if (currentStep !== 0) {
        response = offer;
      }

      if (formData?.carrierId !== offer?.carrierId && currentStep === 0) {
        planData = null;
      }

      if (response && (currentStep === 0 || currentStep === 1)) {
        if (!validatePlans(response?.offerStatus, planData)) {
          const nullPlanId = (updatedData ?? []).map((item: any) => ({
            ...item,
            id: null,
          }));

          response = {
            ...response,
            currentPlans: getCurrentPlansForSubmit(mappedPlans, updatedData),
            [QUOTE_ADDING_PROPERTY?.[benefitKind?.toUpperCase() ?? '']]:
              nullPlanId,
            s3DocumentDetails: documentDetails,
            planCreationType:
              selectedAddingType === '' ? NONE : selectedAddingType,
            discontinuedPlanMap: offer?.discontinuedPlanMap ?? {},
            quoteMappings: filteredQuoteMappings(),
          };
        } else {
          response = false;
        }
      } else if (currentStep !== 2 && currentStep !== 3) {
        response = false;
      }

      return response;
    },
    handlePublishOrDraftOffer: onPublishOrDraftOfferExternal,
    getIsFinalApprovedProposal: () => !!offerAttachedProposal?.isFinalApproved,
    getIsShowUpdateOfferInProposalModal: () =>
      !!showUpdateOfferInPropModal?.show,
    getIsEligibleEdit,
    getIsValidUpdate: addOffersRef?.current?.getIsValidUpdate,
    getPremiumTypeAndCount: () => {
      return {
        premiumType: currentPlans?.planTierType,
        nTierCount: currentPlans?.planTierCount - 1,
      };
    },
    handleSaveAsDraft: () => addPlansRef?.current.handleSaveAsDraft(),
    handleSaveAsPublished: () => addPlansRef?.current.handleSaveAsPublished(),
    isQuotePlanApproved: () => {
      if (offer.planCreationType === ADD_PLAN_OPTIONS.CARRIER_QUOTE.key) {
        return offer?.quoteOfferApproved;
      }
      return true;
    },
  }));

  const updateDateFormat = (formValues: any) => {
    const dateStr = formValues?.effectiveDates?.split(' - ');
    formValues.effectiveDateFrom = dayjs(dateStr[0]).format('YYYY-MM-DD');
    formValues.effectiveDateTo = dayjs(dateStr[1]).format('YYYY-MM-DD');
  };

  const saveOffer = async (
    state?: string,
    discontinuedPlanMap?: any,
    shouldInitializeQuote?: boolean,
    saveProgressAsOps?: boolean
  ) => {
    const newStatus = state || savingMode;
    const editOfferId =
      editData?.status === OFFER_STATUS.PUBLISHED &&
      newStatus === OFFER_STATUS.DRAFT
        ? undefined
        : editData?.offerId;
    if (currentStep < 3 && editData?.status === OFFER_STATUS.PUBLISHED) {
      await createOffer({
        offer:
          offer?.benefitCategory === LIFE ||
          offer?.benefitCategory === STD ||
          offer?.benefitCategory === LTD
            ? buildLifePlans(discontinuedPlanMap)
            : buildPlans(discontinuedPlanMap),
        draft: true,
        editOfferId: undefined,
        jobId: jobId,
        isOpsAdmin: saveProgressAsOps ?? isOpsAdmin,
        shouldInitializeQuote: shouldInitializeQuote,
        preventDocExtractionDetailsUpdate: OFFER_STATUS.IN_PROGRESS === state,
      }).unwrap();
    } else if (currentStep < 3) {
      await createOffer({
        offer:
          offer?.benefitCategory === LIFE ||
          offer?.benefitCategory === STD ||
          offer?.benefitCategory === LTD
            ? buildLifePlans(discontinuedPlanMap)
            : buildPlans(discontinuedPlanMap),
        draft: true,
        editOfferId: editData === undefined ? undefined : editData?.offerId,
        jobId: jobId,
        isOpsAdmin: saveProgressAsOps ?? isOpsAdmin,
        shouldInitializeQuote: shouldInitializeQuote,
        preventDocExtractionDetailsUpdate: OFFER_STATUS.IN_PROGRESS === state,
      }).unwrap();
    } else {
      await publishOrDraftOffer({
        offerDocumentId: previewOffer?.offerDocumentId,
        saveStatus: state || savingMode,
        benefitCategory: offer?.benefitCategory,
        editOfferId,
        isNotificationActive,
      });
    }
  };

  const onPublishOrDraftOfferExternal = async (state: string) => {
    if (currentStep === 3) {
      await onPublishOrDraftOffer(state);
      return false;
    }

    setSavingMode(state);
    setIsExternalPublishFlow(true);

    const { existsDraftOffer, existsPublishedOffer } = await getOfferExists({
      employerId: employerId ?? '',
      status: state,
      name: encodeURIComponent(offer?.offerName),
      category: offer?.benefitCategory,
    }).unwrap();
    const isDraftStatus = editData?.status === OFFER_STATUS.DRAFT;
    const isPublishedStatus = editData?.status === OFFER_STATUS.PUBLISHED;

    if (
      offerAttachedProposal.list.length !== 0 &&
      offerDraftStatus.includes(state)
    ) {
      const show = true;
      const overwrite =
        isDraftStatus &&
        state === OFFER_STATUS.PUBLISHED &&
        existsPublishedOffer;

      setShowUpdateOfferInPropModal({ show, overwrite });
      return true;
    } else {
      if (
        (isDraftStatus && state === OFFER_STATUS.DRAFT) ||
        (isPublishedStatus && state === OFFER_STATUS.PUBLISHED)
      ) {
        return false;
      } else if (
        (state === OFFER_STATUS.PUBLISHED && existsPublishedOffer) ||
        (state === OFFER_STATUS.DRAFT && existsDraftOffer)
      ) {
        setShowOverwritePopup(true);
        return true;
      } else {
        return false;
      }
    }
  };

  const onPublishOrDraftOffer = async (
    state: string,
    discontinuedPlanMap?: any,
    shouldInitializeQuote?: boolean,
    saveProgressAsOps?: boolean
  ) => {
    setSavingMode(state);
    const { existsDraftOffer, existsPublishedOffer } = await getOfferExists({
      employerId: employerId ?? '',
      status: state,
      name: encodeURIComponent(offer?.offerName),
      category: offer?.benefitCategory,
    }).unwrap();

    const isDraftStatus = editData?.status === OFFER_STATUS.DRAFT;
    const isPublishedStatus = editData?.status === OFFER_STATUS.PUBLISHED;

    if (
      offerAttachedProposal.list.length !== 0 &&
      (state === OFFER_STATUS.PUBLISHED || state === editData.status)
    ) {
      const show = true;
      const overwrite =
        isDraftStatus &&
        state === OFFER_STATUS.PUBLISHED &&
        existsPublishedOffer;

      setShowUpdateOfferInPropModal({ show, overwrite });
    } else {
      if (
        (isDraftStatus && state === OFFER_STATUS.DRAFT) ||
        (isPublishedStatus && state === OFFER_STATUS.PUBLISHED)
      ) {
        if (
          offer?.offerName !== editData?.name &&
          ((state === OFFER_STATUS.PUBLISHED && existsPublishedOffer) ||
            (state === OFFER_STATUS.DRAFT && existsDraftOffer))
        ) {
          setShowOverwritePopup(true);
        } else {
          await saveOffer(
            state,
            discontinuedPlanMap,
            shouldInitializeQuote,
            saveProgressAsOps
          );
        }
      } else if (
        (state === OFFER_STATUS.PUBLISHED && existsPublishedOffer) ||
        (state === OFFER_STATUS.DRAFT && existsDraftOffer)
      ) {
        setShowOverwritePopup(true);
      } else {
        await saveOffer(
          state,
          discontinuedPlanMap,
          shouldInitializeQuote,
          saveProgressAsOps
        );
      }
    }
  };

  const basicInfoNext = (
    formValues: any,
    isClosePopup: boolean,
    isTopNav: boolean = false
  ) => {
    let updatedOffer = offer;
    updateDateFormat(formValues);
    updatedOffer = { ...updatedOffer, ...formValues };

    // The below code resets back to the previous state on the offer status change
    if (!validatePlans(formValues?.offerStatus, updatedData)) {
      const nullPlanId = (updatedData ?? []).map((item: any) => ({
        ...item,
        id: null,
      }));

      updatedOffer = {
        ...updatedOffer,
        currentPlans: getCurrentPlansForSubmit(mappedPlans, updatedData),
        [QUOTE_ADDING_PROPERTY?.[benefitKind?.toUpperCase() ?? '']]: nullPlanId,
        s3DocumentDetails: documentDetails,
        planCreationType: selectedAddingType === '' ? NONE : selectedAddingType,
        discontinuedPlanMap: offer?.discontinuedPlanMap ?? {},
      };
    }

    if (formValues?.offerStatus === OFFER_STATUSES.DTQ.value) {
      if (
        ((offer as any)?.[
          QUOTE_ADDING_PROPERTY?.[benefitKind?.toUpperCase() ?? '']
        ]?.length ?? 0) === 0
      ) {
        setUpdatedData(null);
        setMappedPlans([]);
      }
      updatedOffer = {
        ...updatedOffer,
        [QUOTE_ADDING_PROPERTY?.[benefitKind?.toUpperCase() ?? '']]: [],
        currentPlans: [],
        planCreationType: NONE,
      };
    } else if (formValues?.offerStatus === OFFER_STATUSES.PENDING.value) {
      if ((updatedData?.length ?? 0) === 0) {
        setUpdatedData(null);
        setMappedPlans([]);
      }
    } else if (
      (formValues?.offerStatus === OFFER_STATUSES.RECEIVED.value ||
        formValues?.offerStatus === OFFER_STATUSES.RECOMMENDED.value) &&
      isEdit &&
      (updatedData?.length ?? 0) === 0
    ) {
      setUpdatedData(null);
      setMappedPlans([]);
      updatedOffer = {
        ...updatedOffer,
        [QUOTE_ADDING_PROPERTY?.[benefitKind?.toUpperCase() ?? '']]: null,
      };
    }

    if (
      !isEmpty(offer?.carrierId) &&
      offer?.carrierId !== formValues?.carrierId
    ) {
      setSelectedFile(null);
    }

    if (!isClosePopup) {
      setOffer({
        ...updatedOffer,
        offerName: formValues?.offerName?.trim(),
      });

      getOfferExists({
        employerId: employerId ?? '',
        status: 'PUBLISHED',
        name: encodeURIComponent(formValues?.offerName?.trim()),
        category: URL_PARAM_TO_BENEFIT_TYPE?.[params?.benefitKind ?? ''],
      });

      if (formValues?.offerStatus === OFFER_STATUSES.DTQ.value) {
        changeCurrentStep(3);
      } else {
        if (!isTopNav) {
          nextStep();
        }
      }
    }

    return updatedOffer;
  };

  const setNotificationActive = (val: boolean) => {
    setIsNotificationActive(val);
  };

  const handleBasicInfoNextStep = async (
    formValues: any,
    isClosePopup: boolean,
    isTopNav: boolean = false
  ) => {
    if (editData === undefined || isClonedOffer) {
      let existsResponse = null as any;
      await getOfferNameExists({
        employerId: employerId ?? '',
        status: 'PUBLISHED',
        name: encodeURIComponent(formValues?.offerName?.trim()),
        category: URL_PARAM_TO_BENEFIT_TYPE?.[params?.benefitKind ?? ''],
        offerId: editData?.offerId,
      })
        .unwrap()
        .then((response: any) => {
          existsResponse = response;
        });

      setOfferNameExists(
        existsResponse?.existsDraftOffer || existsResponse?.existsPublishedOffer
      );
      if (
        !existsResponse?.existsDraftOffer &&
        !existsResponse?.existsPublishedOffer
      ) {
        return basicInfoNext(formValues, isClosePopup, isTopNav);
      } else {
        return false;
      }
    } else {
      return basicInfoNext(formValues, isClosePopup, isTopNav);
    }
  };

  const validateBasicInfoForm = (formData: any) => {
    let isValidated = true;

    if (
      isEmpty(formData.offerName) ||
      isEmpty(formData.carrierId) ||
      (isEmpty(formData.carrierType) && params.benefitType === 'MEDICAL') ||
      isEmpty(formData.effectiveDates) ||
      isEmpty(formData.offerStatus)
    ) {
      isValidated = false;
    }

    return isValidated;
  };

  const validateUpdatedData = () => {
    const data =
      updatedData !== null || updatedData !== undefined
        ? updatedData
        : tempData;
    const names = (data ?? []).map((item: any) => item?.name?.toLowerCase());

    if (new Set(names ?? []).size !== (updatedData?.length ?? 0)) {
      return true;
    } else {
      return false;
    }
  };

  const validate = (type: string, value: string): boolean => {
    if (value === '' || value === null) {
      return true;
    }
    if (type === 'PREMIUM') {
      value = value.replace('$', '');
      value = value.replace('.', '');
      value = value.replace(',', '');
      return !/^\d+$/.test(value);
    } else {
      return false;
    }
  };

  const validateAndSaveOnTopNavigate = async (incomingStep: number) => {
    const isBack = incomingStep < currentStep;
    let isValid = false;

    switch (currentStep) {
      case 0:
        isValid = await addOffersRef?.current?.onSubmitNext();
        break;
      case 1:
        isValid = await addPlansRef?.current?.onSubmitNext({
          isBack: isBack,
          incomingStep: incomingStep,
        });
        break;
      default:
        isValid = true;
    }

    if ((isValid || isUndefined(isValid)) && !isOpsAdmin) {
      changeCurrentStep(incomingStep);
    }
  };

  const validateObj = (planData: any): boolean => {
    if (planData?.name === '' || planData?.name === null) {
      return true;
    }
    if (MDV_PLANS.includes(benefitKind?.toUpperCase() ?? '')) {
      if (
        (planData?.planType === '' ||
          planData?.planType === null ||
          planData?.planType === 'null') &&
        benefitKind?.toUpperCase() === OFFER_CATEGORY?.MEDICAL
      ) {
        return true;
      } else if (
        planData?.monthlyPremiums?.filter((item: any) =>
          validate('PREMIUM', item?.value)
        )?.length !== 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const validateFormatting = () => {
    const data =
      updatedData !== null || updatedData !== undefined
        ? updatedData
        : tempData;

    let isValid = true;
    (data ?? []).map((item: any) => {
      if (
        isValid &&
        MDV_PLANS?.includes(URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? ''])
      ) {
        isValid = !validateObj(item);
      } else if (
        isValid &&
        LIFE_PLANS?.includes(URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? ''])
      ) {
        isValid = !validateNonMDVObj(
          item,
          URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? ''],
          offer?.fundingType
        );
      }
    });

    return !isValid && !isDtqOrPending(offer?.offerStatus);
  };

  const validatePlans = (status: string, planData: any) => {
    return (
      validateUpdatedData() ||
      (validateFormatting() && !isDtqOrPending(status)) ||
      ((planData === null || planData === undefined) && !isDtqOrPending(status))
    );
  };

  const isDisabledStep = (index: number): boolean => {
    if (isOpsAdmin || isOfferCreateLoading) {
      return true;
    }
    if (offerStatus === OFFER_STATUSES.DTQ.value) {
      return index === 1 || index === 2;
    }
    if (isQuoteProcessingEnabled && !offer?.quoteOfferApproved) {
      return index >= 2;
    }
    if (validateFormatting() || showMappedPlansError) {
      return index === 0 || index >= 2;
    }
    if (isEdit && !isNullOrUndefined(updatedData)) {
      return false;
    }
    return index > currentStep;
  };

  const onConfirmationConfirm = () => {
    // check existing plans are edited or not
    let changedPlans = 0;
    if (!isNullOrUndefined(currentPlans)) {
      const plans = Object.entries(currentPlans?.currentPlans).map(
        (item: any) => {
          return {
            value: item[0],
            label: item[1],
          };
        }
      );
      changedPlans = compareMappedPlansAndGetUnMappedPlanList(
        plans,
        mappedPlans
      ).length;
    }

    if (changedPlans !== 0) {
      setShowOverwritePopup(true);
      setShowUpdateOfferInPropModal({ show: false, overwrite: false });
    } else {
      if (isExternalPublishFlow) {
        onExternalPublish();
      } else {
        saveOffer(OFFER_STATUS.PUBLISHED);
      }
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className={styles.step}>
            <BasicInfo
              ref={addOffersRef}
              nextStep={async (formValues) => {
                await handleBasicInfoNextStep(formValues, false);
              }}
              nextOnTopNav={async (formValues) => {
                await handleBasicInfoNextStep(formValues, false, true);
              }}
              isClonedOffer={isClonedOffer}
              setCarrierImage={setCarrierImage}
              closeModal={closeModal}
              isEdit={isEdit}
              offer={offer}
              editInitialData={editData}
              isDuplicateNameError={offerNameExists}
              offerAttachedProposal={offerAttachedProposal}
              setCarrierName={setCarrierName}
              setUpdatedData={setUpdatedData}
              setOfferStatus={setOfferStatus}
              updatedData={updatedData}
            />
          </div>
        );
      case 1:
        return (
          <div className={styles.step}>
            <AddPlans
              offer={offer}
              ref={addPlansRef}
              updateOffer={setOffer}
              nextStep={nextStep}
              selectedAddingType={selectedAddingType}
              updateAddingType={setSelectedAddingType}
              selectedFile={selectedFile}
              changeSelectedFile={setSelectedFile}
              updatedData={updatedData}
              documentDetails={documentDetails}
              changeUpdatedData={setUpdatedData}
              changeDocumentDetails={setDocumentDetails}
              carrierImage={carrierImage}
              setMappedPlans={setMappedPlans}
              mappedPlans={mappedPlans}
              setTempData={setTempData}
              tempData={tempData}
              dropdownPlansSelected={selectedDropdownPlans}
              changeDropdownPlansSelected={setSelectedDropdownPlans}
              isEdit={isEdit}
              offerAttachedProposal={offerAttachedProposal}
              saveAsDraft={(
                discontinuedPlanMap?: any,
                shouldInitializeQuote?: boolean,
                saveProgressAsOps?: boolean
              ) => {
                onPublishOrDraftOffer(
                  OFFER_STATUS.DRAFT,
                  discontinuedPlanMap,
                  shouldInitializeQuote,
                  saveProgressAsOps
                );
              }}
              saveInProgress={() => {
                onPublishOrDraftOffer(
                  OFFER_STATUS.IN_PROGRESS,
                  {},
                  false,
                  false
                );
              }}
              carrierName={carrierName}
              isOfferCreateLoading={isOfferCreateLoading}
              editData={editData}
              currentStep={currentStep}
              changeCurrentStep={changeCurrentStep}
              premiumType={currentPlans?.planTierType}
              nTierCount={currentPlans?.planTierCount - 1}
              currentPlans={currentPlans}
              onExternalPublish={PublishingDraftOfferCheckPoint}
              onPublishOrDraftOfferExternal={onPublishOrDraftOfferExternal}
              isMultipleBenClass={isMultipleBenClass}
              quoteMapping={quoteMappings}
              changeQuoteMapping={setQuoteMappings}
              showMappedPlansError={showMappedPlansError}
              setShowMappedPlansError={setShowMappedPlansError}
              checkExistsPublishedOffer={existsPublishedOffer}
              checkExistsDraftOffer={existsDraftOffer}
              setShowOverwritePopup={setShowOverwritePopup}
              isNoCurrentPlans={isNoCurrentPlansCallback(currentPlans)}
              closeModalWithoutPopup={closeModalWithoutPopup}
              isClonedOffer={isClonedOffer}
            />
          </div>
        );
      case 2:
        return (
          <div className={styles.step}>
            <CreditsAndRates
              nextStep={nextStep}
              saveAsDraft={() => {}}
              offer={offer}
              updateOffer={setOffer}
              offerAttachedProposal={offerAttachedProposal}
              saveAsDraftLink={() => {
                onPublishOrDraftOffer(OFFER_STATUS.DRAFT);
              }}
              isOfferCreateLoading={isOfferCreateLoading}
              currentStep={currentStep}
              changeCurrentStep={changeCurrentStep}
            />
          </div>
        );
      case 3:
        return (
          <div className={styles.step}>
            <PreviewOffer
              offer={offer}
              publishOffer={() => {
                onPublishOrDraftOffer(OFFER_STATUS.PUBLISHED);
              }}
              saveAsDraft={() => {
                onPublishOrDraftOffer(OFFER_STATUS.DRAFT);
              }}
              benefitCategory={offer.benefitCategory}
              carrierImage={carrierImage}
              buttonLoading={
                isPublishOrDraftLoading && !showUpdateOfferInPropModal.show
              }
              setPreviewOffer={setPreviewOffer}
              publishButtonDisable={
                offerAttachedProposal.isFinalApproved ||
                getIsEligibleEdit() ||
                showUpdateOfferInPropModal.show
              }
              isSaveAsDraftBtnDisabled={showUpdateOfferInPropModal.show}
              offerAttachedProposal={offerAttachedProposal}
              isEdit={isEdit}
              isNotEligibleToEdit={getIsEligibleEdit()}
              changeOffer={setOffer}
              editData={editData}
              notificationActive={setNotificationActive}
              currentStep={currentStep}
              changeCurrentStep={changeCurrentStep}
              premiumType={currentPlans?.planTierType}
              nTierCount={currentPlans?.planTierCount - 1}
              planDetailsData={offer}
              quoteMappings={filteredQuoteMappings()}
            />
          </div>
        );
      default:
        return;
    }
  };

  const PublishingDraftOfferCheckPoint = () => {
    if (existsPublishedOffer) {
      setSavingMode(OFFER_STATUS.PUBLISHED);
      setIsExternalPublishFlow(true);
      setShowOverwritePopup(true);
    } else {
      onExternalPublish();
    }
  };

  useEffect(() => {
    if (editData?.offerId) {
      setOffer({
        ...offer,
        isOfferedPlanProcessing: planExtractionData?.planProcessing ?? false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planExtractionData, isSuccessOfferedPlanStatusFetch, editData?.offerId]);

  return (
    <>
      <div className={styles.stepWrapper}>
        <div className={styles.subTitle}>
          {currentStep !== 0 && (
            <>
              {offer?.offerName} - {offer?.effectiveDates}
            </>
          )}
        </div>
        <Stepper
          headers={[
            'Basic Info',
            'Add Plans',
            benefitKind ===
            OFFER_BENEFIT_TYPE_NAVIGATION_LABELS.VOLUNTARY_BENEFIT
              ? 'Rate Details'
              : 'Credits & Rate Details',
            'Preview',
          ]}
          activeStep={currentStep}
          isEditMode={isEdit}
          changeCurrentStep={validateAndSaveOnTopNavigate}
          renderStepContent={renderStepContent}
          isDisabledStep={isDisabledStep}
        />
      </div>
      <ConfirmationDialog
        destroyOnClose={true}
        confirmText={`Yes - ${
          savingMode === OFFER_STATUS.PUBLISHED ? 'Publish' : 'Save Draft'
        } Offer`}
        onConfirm={() => {
          if (isExternalPublishFlow) {
            onExternalPublish();
          } else {
            saveOffer();
          }
        }}
        visible={showOverwritePopup}
        onCancel={() => {
          setShowOverwritePopup(false);
        }}
        closeModal={() => {
          setOfferNameExists(false);
          setShowOverwritePopup(false);
        }}
        title={`Overwrite Existing ${
          savingMode === OFFER_STATUS.PUBLISHED ? 'Published' : 'Draft'
        } Offer?`}
        cancelText="Cancel"
        confirmLoading={isPublishOrDraftLoading}
        buttonsExpanded={true}
      >
        <p className={styles.claimsConfirmation}>
          <div>
            A {savingMode === OFFER_STATUS.PUBLISHED ? 'published ' : 'draft '}
            version of this offer already exists.
            {savingMode === OFFER_STATUS.PUBLISHED
              ? ' Publishing this offer '
              : ' Saving this offer as draft '}
            will overwrite that data. Do you wish to continue?
          </div>
        </p>
      </ConfirmationDialog>
      <UpdateConfirmationListModal
        visible={showUpdateOfferInPropModal.show}
        confirmText={
          showUpdateOfferInPropModal?.overwrite
            ? 'Publish Offer'
            : 'Save Offer Changes'
        }
        onConfirm={onConfirmationConfirm}
        closeModal={() => {
          setOfferNameExists(false);
          setShowUpdateOfferInPropModal({ show: false, overwrite: false });
        }}
        cancelText="Cancel"
        title="Update Offers in Proposal"
        listTitle="Proposal name"
        description={
          showUpdateOfferInPropModal.overwrite
            ? 'A published version of this offer exists in the proposals listed below. Any rate changes to the offer will automatically update the associated proposals.'
            : 'This offer exists in the proposals listed below. Any rate changes to the offer will automatically update the associated proposals.'
        }
        confirmBtnFullWidth={true}
        isCancelLink={true}
        listData={offerAttachedProposal.list}
        onClickItem={(item: any) =>
          handleRedirectToProposalsDetails(item.value, params)
        }
        confirmLoading={isPublishOrDraftLoading}
      />
    </>
  );
};

export default forwardRef(AddOffer);
