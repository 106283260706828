import { FC, useEffect, useRef, useState } from 'react';
import { Form, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import CloseCircleOutlined from '@ant-design/icons/lib/icons/CloseCircleOutlined';

import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';

import ViewBasicInfo from 'modules/benefitsConsultation/components/ViewBasicInfo/ViewBasicInfo';
import ViewAvailability from 'modules/benefitsConsultation/components/ViewAvailability/ViewAvailability';
import ViewAssignedEmployers from 'modules/benefitsConsultation/components/ViewAssignedEmployers/ViewAssignedEmployers';
import EditBasicInfo from 'modules/benefitsConsultation/components/EditBasicInfo/EditBasicInfo';
import EditAvailability from 'modules/benefitsConsultation/components/EditAvailability/EditAvailability';
import CheckboxListSelector from 'modules/benefitsConsultation/components/CheckboxListSelector/CheckboxListSelector';

import BenefitsConsultant from 'model/benefitsConsultation/BenefitsConsultant';
import AssignedEmployer from 'model/benefitsConsultation/AssignedEmployer';
import WeekTimeConfig from 'model/benefitsConsultation/WeekTimeConfig';
import ConsultantStatus from 'model/benefitsConsultation/ConsultantStatus';

import {
  editConsultantDetails,
  editConsultantDetailsSuccess,
} from 'modules/benefitsConsultation/slices/commonSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  DaysOfTheWeek,
  TIME_OPTIONS,
} from 'modules/benefitsConsultation/constants/benefitsConsultation';
import { mapValueToLabel } from 'util/commonUtil';
import { formVerificationMsg, IndividualType } from 'constants/commonConstants';
import { resetStartEndTimeForUnavailable } from 'modules/benefitsConsultation/utils/commonConsultLvlsUtils';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import SelectionListRow from 'model/benefitsConsultation/SelectionListRow';
import { getAdminConsultantValidation } from 'modules/admins/slices/adminConsultantValidateSlice';
import { useNavContext } from 'hooks/useNavContext';
import ListComponent from 'components/Helpers/ListComponent/ListComponent';
import styles from './brokerConsultantDetails.module.less';

type BrokerConsultantDetailsProps = {
  visible: boolean;
  setVisible: Function;
  consultant: BenefitsConsultant;
  setAlertVisibleParent: Function;
  setAlertMessageParent: Function;
  showEditMode: boolean;
  updatedStatus?: ConsultantStatus;
  updateTableData: Function;
};

const BrokerConsultantDetails: FC<BrokerConsultantDetailsProps> = (
  props: BrokerConsultantDetailsProps
) => {
  const [form] = Form.useForm();

  const {
    visible,
    setVisible,
    consultant,
    setAlertVisibleParent,
    setAlertMessageParent,
    showEditMode,
    updatedStatus,
    updateTableData,
  } = props;
  const dispatch = useAppDispatch();

  const requestType = useAppSelector(
    (state) => state.benefitsConsultation.common.requestType
  );
  const inProgress = useAppSelector(
    (state) => state.benefitsConsultation.common.inProgress
  );

  const { brokerId } = useNavContext();

  const [editMode, setEditMode] = useState<boolean>(false);
  const editBasicInfoRef = useRef<any>(null);
  const [formData, setFormData] = useState<any>();
  const [showAvailabilityInvalid, setShowAvailabilityInvalid] =
    useState<boolean>(false);
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [editEmployerConfirmationVisible, setEditEmployerConfirmationVisible] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [filteredEmployerList, setFilteredEmployerList] = useState<any[]>([]);

  const [validatedTempConsultantData, setValidatedTempConsultantData] =
    useState<any>({
      validation: {},
      mappedEmployers: [],
    });

  const adminConsultantValidateState = useAppSelector(
    (state) => state.admins.adminConsultantValidate
  );

  const editAvailabilityRef = useRef<any>(null);
  const checkboxListSelectorRef = useRef<any>(null);

  useEffect(() => {
    setEditMode(showEditMode);
  }, [showEditMode]);

  useEffect(() => {
    if (requestType === editConsultantDetailsSuccess.type) {
      setVisible(false);
      setEditMode(false);
    }
  }, [requestType, setVisible]);

  useEffect(() => {
    dispatch(
      getAdminConsultantValidation({
        adminType: IndividualType.ORGANIZATION_ADMIN,
        orgId: brokerId,
        individualId: null,
        employerId: null,
        consultantId: consultant.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brokerId, consultant.id]);

  useEffect(() => {
    const newFormData = pick(consultant, [
      'email',
      'fullName',
      'timezone',
      'status',
      'conferenceDetails',
    ]);
    setFormData(newFormData);
    form.setFieldsValue(newFormData);
  }, [consultant, form]);

  useEffect(() => {
    if (editMode) {
      editBasicInfoRef?.current?.initialFocus();
    }
  }, [editMode]);

  const validateFields = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errorInfo: any) {
      return isEmpty(errorInfo.errorFields);
    }
  };

  const checkIfValidationIsRequired = (
    updatedEmployers: SelectionListRow[]
  ): boolean => {
    const uncheckedEmployers = updatedEmployers.filter(
      (obj) => !obj.isAssigned && obj.previous
    );

    if (!uncheckedEmployers.length) return false;

    if (
      Array.isArray(adminConsultantValidateState?.consultantValidateObj) &&
      !adminConsultantValidateState?.consultantValidateObj?.length
    ) {
      return false;
    }

    const filteredDataSet = uncheckedEmployers?.filter((obj: any) =>
      adminConsultantValidateState?.consultantValidateObj?.some(
        (emp: any) => obj.id === emp.id
      )
    );

    setFilteredEmployerList(filteredDataSet);

    return !isEmpty(filteredDataSet);
  };

  const finalizeEditConsultationDetails = (
    updatedEmployers: any[],
    validations: any
  ) => {
    const mappedEmployers = updatedEmployers.map(
      (employer: AssignedEmployer) => ({
        employerId: employer.id,
        name: employer.name,
        assignedToEmployer: employer.isAssigned,
      })
    );

    const currentConsultant = {
      id: consultant.id,
      organizationId: consultant.organizationId,
      individualId: consultant.individualId,
      avatarUrl: consultant.avatarUrl,
      type: consultant.type,
      status: consultant.status,
      availability: validations.dayConfig,
      mappedEmployers: mappedEmployers,
    };

    const updatedConsultant = {
      ...currentConsultant,
      ...form.getFieldsValue(),
    };

    dispatch(editConsultantDetails(consultant.id, updatedConsultant));
  };

  const handleEmployerClick = (val: any) => {
    window.open(
      `/brokers/${brokerId}/employers/${val.value}/broker-consultation`,
      '_blank'
    );
  };

  const onSubmit = async () => {
    if (editMode) {
      const isValid = await validateFields();
      const validations = await editAvailabilityRef?.current?.validateData();

      resetStartEndTimeForUnavailable(validations.dayConfig);

      if (!isValid || validations.isAvailabilityEmpty) {
        setAlertVisible(true);
        setAlertMessage({
          type: 'error',
          message: formVerificationMsg,
        });
      } else if (validations.isDurationInvalid) {
        setAlertVisible(true);
        setAlertMessage({
          type: 'error',
          message: 'The end time should be greater than the start time.',
        });
      } else {
        const updatedEmployers =
          checkboxListSelectorRef?.current?.getConsultantsToBeAdded();

        const isValidationRequired: boolean =
          checkIfValidationIsRequired(updatedEmployers);

        if (isValidationRequired) {
          setEditEmployerConfirmationVisible(!editEmployerConfirmationVisible);
          setValidatedTempConsultantData({
            validation: validations,
            mappedEmployers: updatedEmployers,
          });
          return;
        }
        finalizeEditConsultationDetails(updatedEmployers, validations);
      }
    } else {
      setEditMode(true);
    }
  };

  const handleConfirmationClose = () => {
    setEditEmployerConfirmationVisible(!editEmployerConfirmationVisible);

    setValidatedTempConsultantData({
      validation: {},
      mappedEmployers: [],
    });
  };

  const availabilityDetails =
    consultant.availability &&
    Object.entries(consultant.availability).map((availability) => {
      const startTimeLabel =
        TIME_OPTIONS.find(
          (time) => time.value === availability[1].startTime.toString()
        )?.label || '';
      const endTimeLabel =
        TIME_OPTIONS.find(
          (time) => time.value === availability[1].endTime.toString()
        )?.label || '';

      return {
        day: `${mapValueToLabel(availability[0], DaysOfTheWeek)}`,
        isOpen: availability[1].isOpen,
        duration: `${startTimeLabel} - ${endTimeLabel}`,
      };
    });

  const assignedEmployers = consultant.mappedEmployers.filter(
    (employer) => employer.assignedToEmployer
  );

  const employers = consultant.mappedEmployers.map((employer) => ({
    id: employer.employerId,
    name: employer.name,
    isAssigned: employer.assignedToEmployer,
  }));

  const handleConfirmation = () => {
    if (
      validatedTempConsultantData.validation &&
      validatedTempConsultantData.mappedEmployers
    ) {
      setEditEmployerConfirmationVisible(!editEmployerConfirmationVisible);
      finalizeEditConsultationDetails(
        validatedTempConsultantData.mappedEmployers,
        validatedTempConsultantData.validation
      );
    }
    return;
  };

  let defaultAvailabilityObj = {} as WeekTimeConfig;
  Object.values(DaysOfTheWeek).forEach((day) => {
    defaultAvailabilityObj = {
      ...defaultAvailabilityObj,
      ...{
        [day.value]: {
          startTime: 900,
          endTime: 1700,
          is24Hours: false,
          isOpen: null,
        },
      },
    };
  });
  return (
    <FullScreenModal
      title={`${editMode ? 'Edit' : ''} Consultant Details`}
      visible={visible}
      className={styles.detailsWrapper}
      onCancel={() => {
        setEditMode(false);
        setVisible(false);
        form.resetFields();
      }}
      footer={false}
      closeIcon={<CloseCircleOutlined />}
      afterClose={() => {
        if (requestType === editConsultantDetailsSuccess.type) {
          updateTableData();
          setAlertVisibleParent(true);
          setAlertMessageParent({
            type: 'success',
            message: 'Broker Consultant has been successfully updated.',
          });
        }
      }}
    >
      <div className={styles.alertWrapper}>
        {alertVisible && (
          <AlertMessage
            type={alertMessage.type}
            message={alertMessage.message}
            closeAlert={() => setAlertVisible(false)}
          />
        )}
      </div>

      {!editMode && (
        <div className={styles.overallContent}>
          <input type="text" autoFocus hidden />
          <div className={styles.sectionHeading}>Basic Information</div>
          <ViewBasicInfo consultant={consultant} />

          <div className={styles.sectionHeading}>Availability</div>
          <ViewAvailability availabilityList={availabilityDetails} />

          <div className={styles.sectionHeading}>Assigned Employers</div>
          <ViewAssignedEmployers assignedEmployersList={assignedEmployers} />
        </div>
      )}

      {editMode && (
        <div className={styles.overallEditContent}>
          <div tabIndex={1} className={styles.sectionHeadingEdit}>
            Basic Information
          </div>
          <EditBasicInfo
            ref={editBasicInfoRef}
            form={form}
            formData={formData}
            consultant={consultant}
            setAlertMessage={setAlertMessage}
            setAlertVisible={setAlertVisible}
            updatedStatus={updatedStatus}
          />

          <div
            className={`${styles.sectionHeadingEdit} ${
              showAvailabilityInvalid ? styles.invalid : ''
            }`}
          >
            Availability *
          </div>
          <EditAvailability
            availabilityObj={
              consultant.availability
                ? consultant.availability
                : defaultAvailabilityObj
            }
            ref={editAvailabilityRef}
            setShowAvailabilityInvalid={setShowAvailabilityInvalid}
          />

          <div className={styles.sectionHeadingEdit}>Assign to Employers</div>
          <div className={styles.assignEmployers}>
            <CheckboxListSelector
              consultantList={employers}
              isRemovableMode={true}
              ref={checkboxListSelectorRef}
              consultantTypeText="Employers"
              saveInProgress={inProgress}
              isEmployersMode
            />
          </div>
        </div>
      )}

      <Row justify="center">
        <SubmitButton
          onClick={onSubmit}
          type="primary"
          disabled={inProgress}
          loading={inProgress}
        >
          {editMode ? 'Save Consultant' : 'Edit Consultant Details'}
        </SubmitButton>
      </Row>
      <Row justify="center">
        <CancelButton disabled={inProgress}>
          <span
            onClick={() => {
              setVisible(false);
              setEditMode(false);
              form.resetFields();
            }}
          >
            Cancel
          </span>
        </CancelButton>
      </Row>

      <Row>
        <ConfirmationDialog
          title="Edit Consultant Details"
          confirmText="Yes - Save Consultant"
          cancelText="Cancel"
          closeModal={handleConfirmationClose}
          onConfirm={handleConfirmation}
          visible={editEmployerConfirmationVisible}
          isRecordApplicable={true}
          confirmLoading={adminConsultantValidateState.inProgress}
        >
          <div className={styles.warningConfirmation}>
            Are you sure you want to unassign this consultant from employers?
            Performing this action will turn off Broker Consultation for the
            following employers because they don’t have other consultants
            assigned to them.
            {Array.isArray(
              adminConsultantValidateState?.consultantValidateObj
            ) && adminConsultantValidateState?.consultantValidateObj?.length ? (
              <div className={styles.additionalWarningSectionList}>
                <ListComponent
                  className={styles.employerList}
                  dataSource={filteredEmployerList?.map((item: any) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  onClickItem={handleEmployerClick}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </ConfirmationDialog>
      </Row>
    </FullScreenModal>
  );
};

export default BrokerConsultantDetails;
