import { FC, ReactNode, useEffect, useState } from 'react';
import { Col, Popover, Row } from 'antd';
import dayjs from 'dayjs';

import BenefitsConsultant from 'model/benefitsConsultation/BenefitsConsultant';

import {
  getConsultantDetails,
  inviteConsultants,
  inviteConsultantsInProgress,
} from 'modules/benefitsConsultation/slices/commonSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { invitedDateFormat } from 'constants/dateFormat';

import styles from './incompleteDetailsPopover.module.less';

type IncompleteDetailsPopoverProps = {
  children: ReactNode;
  benefitsConsultant: BenefitsConsultant;
  setConsultantDetailsModalVisible: Function;
  setShowEditMode: Function;
  setAlertMessage: Function;
  setAlertVisible: Function;
};

const IncompleteDetailsPopover: FC<IncompleteDetailsPopoverProps> = (
  props: IncompleteDetailsPopoverProps
) => {
  const {
    children,
    benefitsConsultant,
    setConsultantDetailsModalVisible,
    setShowEditMode,
    setAlertMessage,
    setAlertVisible,
  } = props;
  const { status } = benefitsConsultant;

  const [isIncompleteDetailsModalVisible, setIsIncompleteDetailsModalVisible] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();
  const consultant = useAppSelector(
    (state) => state.benefitsConsultation.common.consultant
  );
  const inProgress = useAppSelector(
    (state) => state.benefitsConsultation.common.inProgress
  );
  const requestTypeInvite = useAppSelector(
    (state) => state.benefitsConsultation.common.requestTypeInvite
  );

  useEffect(() => {
    if (isIncompleteDetailsModalVisible && benefitsConsultant.id) {
      dispatch(getConsultantDetails(benefitsConsultant.id));
    }
  }, [benefitsConsultant.id, dispatch, isIncompleteDetailsModalVisible]);

  const sendInvite = () => {
    dispatch(inviteConsultants([consultant])).then(() => {
      setIsIncompleteDetailsModalVisible(false);
      setAlertVisible(true);
      setAlertMessage({
        type: 'success',
        message: 'Calendar Sync Invite sent successfully.',
      });
    });
  };

  const linkStyling = `${styles.link} ${
    requestTypeInvite === inviteConsultantsInProgress.type && inProgress
      ? styles.loading
      : ''
  }`;

  return (
    <div>
      <Popover
        trigger="click"
        content={
          <>
            <Row>
              <Col span={14}>Calendar Sync Status</Col>
              <Col span={10}>
                <div className={styles.details}>
                  {status.invitedDate && !status.calendarSyncStatus && (
                    <>
                      Invited&nbsp;
                      {dayjs(status.invitedDate)?.format(invitedDateFormat) ||
                        '-'}
                      <div className={linkStyling} onClick={sendInvite}>
                        Resend Invite
                      </div>
                    </>
                  )}
                  {!status.invitedDate && (
                    <>
                      Not Synced
                      <div className={linkStyling} onClick={sendInvite}>
                        Send Invite
                      </div>
                    </>
                  )}
                  {status.calendarSyncStatus && 'Synced'}
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={14}>Consultant Details Status</Col>
              <Col span={10}>
                <div className={styles.details}>
                  {status.consultantAvailabilityStatus ? (
                    <>Complete</>
                  ) : (
                    <>
                      Incomplete
                      <div
                        className={styles.link}
                        onClick={() => {
                          dispatch(
                            getConsultantDetails(benefitsConsultant.id)
                          ).then(() => {
                            setConsultantDetailsModalVisible(true);
                            setShowEditMode(true);
                            setIsIncompleteDetailsModalVisible(false);
                          });
                        }}
                      >
                        Update Details
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </>
        }
        overlayClassName={`${styles.popoverBC} ${
          inProgress && requestTypeInvite === inviteConsultantsInProgress.type
            ? styles.loading
            : ''
        }`}
        placement="bottom"
        onVisibleChange={() =>
          setIsIncompleteDetailsModalVisible(!isIncompleteDetailsModalVisible)
        }
        visible={isIncompleteDetailsModalVisible}
      >
        {children}
      </Popover>
      {isIncompleteDetailsModalVisible && (
        <div className={styles.overlay}></div>
      )}
    </div>
  );
};

export default IncompleteDetailsPopover;
