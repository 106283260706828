import { forwardRef, useEffect, useState } from 'react';

import { Form } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Employer from 'model/Employer';
import RenewalInnerStepper from 'modules/renewals/components/renewalInnerStepper/RenewalInnerStepper';
import RenewalInnerStepperDto from 'model/RenewalInnerStepperDto';
import { ReactComponent as MedicalOfferIcon } from 'assets/images/medical-offer-icon.svg';
import { ReactComponent as DentalOfferIcon } from 'assets/images/dental-offer-icon.svg';
import { ReactComponent as VisionOfferIcon } from 'assets/images/vision-offer-icon.svg';
import { ReactComponent as VisionOfferDisabledIcon } from 'assets/images/vision-disabled-offer-icon.svg';
import { ReactComponent as DentalOfferDisabledIcon } from 'assets/images/dental-disabled-offer-icon.svg';
import { ReactComponent as LdOfferIcon } from 'assets/images/ld-offer-icon.svg';
import { ReactComponent as LdOfferDisabledIcon } from 'assets/images/ld-disabled-offer-icon.svg';
import { ReactComponent as VoluntaryOfferIcon } from 'assets/images/voluntary-offer-icon.svg';
import { ReactComponent as VoluntaryDisabledIcon } from 'assets/images/voluntary-disabled-offer-icon.svg';
import OffersContainerWrapper from 'modules/renewals/components/addProposalsComponents/OffersContainerWrapper/OffersContainerWrapper';
import OffersContainerWrapperWithSubStepper from 'modules/renewals/components/addProposalsComponents/OffersContainerWrapperWithSubStepper/OffersContainerWrapperWithSubStepper';
import {
  OFFER_CATEGORY,
  PROPOSAL_OFFER_SELECT_SECTION,
} from 'modules/renewals/constants/renewalsConstants';

import RenewalInnerStepperInfoDto from 'model/RenewalInnerStepperInfoDto';
import styles from './selectOffers.module.less';

type BasicInfoProps = {
  nextStep: () => void;
  onPreviousStep: () => void;
  closeModal: (
    notShowConfirmMessage: any,
    proposalStatus?: string | undefined
  ) => void;
  isModalOpen?: boolean;
  isEditMode?: boolean;
  employerEditData?: Employer | null;
  proposalId: string | undefined;
  isProposalDetailedView?: boolean;
};

const SelectOffers = (props: BasicInfoProps, ref: any) => {
  const {
    nextStep,
    proposalId,
    onPreviousStep,
    closeModal,
    isProposalDetailedView,
  } = props;
  const [form] = Form.useForm();
  const proposalData = useSelector(
    (state: any) => state.renewals.proposal.proposal
  );
  const isEditMode: boolean = useSelector(
    (state: any) => state.renewals.proposal.isProposalBasicDataSubmitted
  );

  const defaultInnerStepperData: RenewalInnerStepperDto[] = [
    {
      id: 0,
      mainTxt: 'Medical Offers',
      mainEnabledIcon: <MedicalOfferIcon className={styles.mainIcon} />,
      mainDisabledIcon: <MedicalOfferIcon className={styles.mainIcon} />,
      isCompleted: false,
      isActive: true,
      isEnabled: true,
      benefitKind: OFFER_CATEGORY.MEDICAL,
    },
    {
      id: 1,
      mainTxt: 'Dental Offers',
      mainEnabledIcon: <DentalOfferIcon className={styles.mainIcon} />,
      mainDisabledIcon: <DentalOfferDisabledIcon className={styles.mainIcon} />,
      isCompleted: false,
      isActive: false,
      isEnabled: false,
      benefitKind: OFFER_CATEGORY.DENTAL,
    },
    {
      id: 2,
      mainTxt: 'Vision Offers',
      mainEnabledIcon: <VisionOfferIcon className={styles.mainIcon} />,
      mainDisabledIcon: <VisionOfferDisabledIcon className={styles.mainIcon} />,
      isCompleted: false,
      isActive: false,
      isEnabled: false,
      benefitKind: OFFER_CATEGORY.VISION,
    },
    {
      id: 3,
      mainTxt: 'Life & Disability',
      mainEnabledIcon: <LdOfferIcon className={styles.mainIcon} />,
      mainDisabledIcon: <LdOfferDisabledIcon className={styles.mainIcon} />,
      isCompleted: false,
      isActive: false,
      isEnabled: false,
      benefitKind: OFFER_CATEGORY.LIFE_AD,
    },
    {
      id: 4,
      mainTxt: 'Voluntary',
      mainEnabledIcon: <VoluntaryOfferIcon className={styles.mainIcon} />,
      mainDisabledIcon: <VoluntaryDisabledIcon className={styles.mainIcon} />,
      isCompleted: false,
      isActive: false,
      isEnabled: false,
      benefitKind: OFFER_CATEGORY.VOLUNTARY,
    },
  ];

  const subStepperDataInitalSet: RenewalInnerStepperDto[] = [
    {
      id: 0,
      mainTxt: 'Life/AD&D',
      isCompleted: false,
      isActive: true,
      isEnabled: true,
      benefitKind: OFFER_CATEGORY.LIFE_AD,
    },
    {
      id: 1,
      mainTxt: 'Short Term Disability',
      isCompleted: false,
      isActive: false,
      isEnabled: false,
      benefitKind: OFFER_CATEGORY.SHORT_TERM_DISABILITY,
    },
    {
      id: 2,
      mainTxt: 'Long Term Disability',
      isCompleted: false,
      isActive: false,
      isEnabled: false,
      benefitKind: OFFER_CATEGORY.LONG_TERM_DISABILITY,
    },
  ];

  const [mainStepperData, setMainStepperData] = useState<
    RenewalInnerStepperDto[]
  >(defaultInnerStepperData);
  const [subStepperData, setSubStepperData] = useState<
    RenewalInnerStepperDto[]
  >(subStepperDataInitalSet);
  const [completedSteps] = useState<number[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentStepInfo, setCurrentStepInfo] =
    useState<RenewalInnerStepperInfoDto | null>(null);

  // set default values if edit mode
  useEffect(() => {
    if (isEditMode) {
      setFormValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  useEffect(() => {
    const data = proposalData?.payload || {};
    const isLifeOffersSelected =
      data?.lifeAddOffers?.length !== 0 ||
      data?.lifeSTDOffers?.length !== 0 ||
      data?.lifeLTDOffers?.length !== 0;

    setMainStepperData((prev) => {
      return prev.map((item) => {
        if (item.id === 3) {
          return {
            ...item,
            isCompleted: isLifeOffersSelected,
          };
        }
        return item;
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalData?.payload]);

  // switch to proposal modeling if the select offers is completed

  const setFormValues = () => {
    form.setFieldsValue({
      effectiveDates: proposalData.effectiveDate,
      description: proposalData.description,
      proposalName: proposalData.proposalName,
    });
  };

  const params = useParams();
  const navigate = useNavigate();

  const handleNavigation = (): void => {
    navigate(
      `/brokers/${params.brokerId}/employers/${params.employerId}/renewals/proposals`
    );
  };

  const handleMainStepperComplete = (
    key: number,
    isFullyCompleted: boolean,
    isNextClick: boolean
  ) => {
    const stepperInfo: RenewalInnerStepperInfoDto = {
      isFullyCompleted,
      key,
      isNextClick,
    };
    setCurrentStepInfo(stepperInfo);
  };

  const generateViewBasingStepper = () => {
    switch (currentStep) {
      case 0:
        return (
          <OffersContainerWrapper
            ref={ref}
            type={OFFER_CATEGORY.MEDICAL}
            handleStepperComplete={handleMainStepperComplete}
            currentStep={currentStep}
            nextStep={nextStep}
            onPreviousStep={onPreviousStep}
            closeModal={closeModal}
            handleNavigation={handleNavigation}
            isProposalDetailedView={isProposalDetailedView}
          />
        );
      case 1:
        return (
          <OffersContainerWrapper
            ref={ref}
            type={OFFER_CATEGORY.DENTAL}
            handleStepperComplete={handleMainStepperComplete}
            currentStep={currentStep}
            nextStep={nextStep}
            onPreviousStep={onPreviousStep}
            closeModal={closeModal}
            handleNavigation={handleNavigation}
            isProposalDetailedView={isProposalDetailedView}
          />
        );
      case 2:
        return (
          <OffersContainerWrapper
            ref={ref}
            type={OFFER_CATEGORY.VISION}
            handleStepperComplete={handleMainStepperComplete}
            currentStep={currentStep}
            nextStep={nextStep}
            onPreviousStep={onPreviousStep}
            closeModal={closeModal}
            handleNavigation={handleNavigation}
            isProposalDetailedView={isProposalDetailedView}
          />
        );
      case 3:
        return (
          <OffersContainerWrapperWithSubStepper
            ref={ref}
            subStepperSteps={subStepperData}
            setSubStepperData={setSubStepperData}
            toggleMainStepper={handleMainStepperComplete}
            closeModal={closeModal}
            handleNavigation={handleNavigation}
            isProposalDetailedView={isProposalDetailedView}
            proposalId={proposalId}
          />
        );
      case 4:
        return (
          <OffersContainerWrapper
            ref={ref}
            type={OFFER_CATEGORY.VOLUNTARY}
            handleStepperComplete={handleMainStepperComplete}
            currentStep={currentStep}
            nextStep={nextStep}
            onPreviousStep={onPreviousStep}
            closeModal={closeModal}
            handleNavigation={handleNavigation}
            isProposalDetailedView={isProposalDetailedView}
          />
        );

      default:
        return (
          <OffersContainerWrapper
            ref={ref}
            type={OFFER_CATEGORY.MEDICAL}
            handleStepperComplete={handleMainStepperComplete}
            currentStep={currentStep}
            nextStep={nextStep}
            onPreviousStep={onPreviousStep}
            closeModal={closeModal}
            handleNavigation={handleNavigation}
            isProposalDetailedView={isProposalDetailedView}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      <RenewalInnerStepper
        ref={ref}
        data={mainStepperData}
        setCurrentStep={setCurrentStep}
        setInnerStepperData={setMainStepperData}
        completedSteps={completedSteps}
        individualItemStyling={styles.stepperItemStyling}
        isSubStepperExist={false}
        completedStepInfo={currentStepInfo}
        proposalId={proposalId}
        mainSection={PROPOSAL_OFFER_SELECT_SECTION}
      />

      {/* Dynamic Section */}
      {generateViewBasingStepper()}
    </div>
  );
};

export default forwardRef(SelectOffers);
