import he from 'he';

export const getArrayFromDOMElementsAttribute = ({
  domString,
  domSelector,
  domSelectorAttribute,
}: {
  domString: string;
  domSelector: string;
  domSelectorAttribute: string;
}): any[] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(domString, 'text/html');
  const elementArray = Array.from(doc.querySelectorAll(domSelector));

  return elementArray.map((item) => item.getAttribute(domSelectorAttribute));
};

/**
 * Removes all HTML tags and special HTML encoding from a given string
 * @see https://www.w3.org/MarkUp/html-spec/html-spec_13.html
 * @param {string} html A string with HTML tags or encoding
 * @return {string} A decoded string
 */
export const sanitizeRichText = (html: string): string =>
  he.decode(html.replace(/<[^>]+>/g, ' '));

// use this when antd modal need to scroll top
export const setAntDModalScrollToTop = () =>
  document.getElementsByClassName('ant-modal-wrap')[0].scrollTo(0, 0);
