import FourTierContributions from 'model/plans/FourTierContributions';
import NTierContributions from 'model/plans/NTierContributions';
import { ContributionType, RateFrequency } from 'modules/plans/constants';

class BenefitClassContribution {
  type: string;
  frequency: string;
  contributionType: string;
  ntierContributions: NTierContributions;
  fourTierContributions: FourTierContributions;

  constructor() {
    this.type = '';
    this.frequency = RateFrequency.MONTHLY.value;
    this.contributionType = ContributionType.FIXED.value;
    this.ntierContributions = new NTierContributions();
    this.fourTierContributions = new FourTierContributions();
  }
}

export default BenefitClassContribution;
