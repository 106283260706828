import axios from 'axios';
import BenefitGuide from 'model/BenefitGuide';
import EmployerName from 'model/EmployerName';
import PasswordAssociation from 'model/PasswordAssociation';
import { baseApi } from 'util/apiUtil';

const benguideUrl = baseApi + '/v1/benefit-guides';
const v2Url = baseApi + '/v2/benefit-guides';

export const isBenguideAssignedForPlanYear = (
  planYearId: string,
  employerId: string
) => {
  return axios.get(
    `${benguideUrl}/employers/${employerId}?planYearId=${planYearId}`
  );
};

export const findAllBenguidesByEmployerIdAndPlanYear = (
  planYearId: string,
  employerId: string
) => {
  return axios.get(`${v2Url}/employer/${employerId}/plan-year/${planYearId}`);
};

export const getPlanYearBenefitGuideInfo = (employerId: string) => {
  return axios.get(`${v2Url}/employer/${employerId}/by-plan-years`);
};

export const getBenefitGuidePlanList = (id: string) => {
  return axios.get(`${v2Url}/${id}`);
};

export const getBenefitGuideByHashAndRevision = (
  hash: string,
  revision: number
) => {
  return axios.get(`${v2Url}/edit-mode?url-hash=${hash}&revision=${revision}`);
};

export const updateBenguide = (
  benguideId: string,
  updatedBenguide: BenefitGuide
) => {
  return axios.put(`${v2Url}/${benguideId}`, { ...updatedBenguide });
};

export const publish = (id: string) => {
  return axios.post(`${v2Url}/${id}/publish`);
};

export const archive = (id: string) => {
  return axios.post(`${v2Url}/${id}/archive`);
};

export const savePassword = (id: string, password: PasswordAssociation) => {
  return axios.put(`${v2Url}/${id}/secure`, { ...password });
};

export const updateEmployerName = (id: string, employerName: EmployerName) => {
  return axios.put(`${v2Url}/${id}/employer-name`, { ...employerName });
};
