import starIcon from 'assets/images/icon-star.svg';

export const TAG_STATUSES = {
  CURRENT: { value: 'CURRENT', label: 'RENEWAL', color: 'blue' },
  PENDING: { value: 'PENDING', label: 'PENDING', color: 'grey' },
  DTQ: { value: 'DTQ', label: 'DECLINED TO QUOTE', color: 'darkGrey' },
  RECEIVED: { value: 'RECEIVED', label: 'RECEIVED', color: 'grey' },
  DRAFT: { value: 'DRAFT', label: 'DRAFT', color: 'whiteGreyBorder' },
  RECOMMENDED: {
    value: 'RECOMMENDED',
    label: 'RECOMMENDED',
    color: 'yellow',
    startIcon: starIcon,
  },
  NEW_PLAN: { value: 'NEW_PLAN', label: 'NEW PLAN', color: 'whiteBlueBorder' },
  FINAL_APPROVED: {
    value: 'FINAL_APPROVED',
    label: 'FINAL APPROVED',
    color: 'black',
  },
  PUBLISH: {
    value: 'PUBLISH',
    label: 'PUBLISH',
    color: 'whiteBlueBorder',
  },
  QUOTE_PROCESSING: {
    value: 'QUOTE PROCESSING',
    label: 'QUOTE PROCESSING',
    color: 'black',
  },
  USER_AMENDED: { value: 'USER_AMENDED', label: 'USER AMENDED', color: 'blue' },
  SELF_DECLARED: {
    value: 'SELF_DECLARED',
    label: 'SELF DECLARED',
    color: 'grey',
  },
} as const;
