import PriorityIcon from 'modules/issueslog/components/PriorityBadge/PriorityIcon/PriorityIcon';
import { EMDASH_LITERAL } from 'constants/commonConstants';
import {
  PRIORITY_HIGH,
  PRIORITY_LOW,
  PRIORITY_MEDIUM,
  PRIORITY_UNKNOWN,
} from 'modules/issueslog/constants/IssuesLogConstants';

import styles from './PriorityBadge.module.less';

type Props = {
  onClick?: () => void;
  priority: string;
  showLabel?: boolean;
};
export const PriorityType: {
  [key: string]: {
    value: string;
    label: string;
    color: string;
    hidden?: boolean;
  };
} = {
  UNKNOWN: {
    value: PRIORITY_UNKNOWN,
    label: 'Unknown',
    color: 'unknown',
    hidden: true,
  },
  HIGH: { value: PRIORITY_HIGH, label: 'High', color: 'orange' },
  MEDIUM: { value: PRIORITY_MEDIUM, label: 'Medium', color: 'yellow' },
  LOW: { value: PRIORITY_LOW, label: 'Low', color: 'blue' },
};
const PriorityBadge = (props: Props) => {
  const { showLabel = true, priority, onClick } = props;
  const priorityConfig = PriorityType[priority];

  if (!priorityConfig) {
    return <>{EMDASH_LITERAL}</>;
  }

  const { color, label } = priorityConfig;

  return (
    <div
      className={`${styles.priorityBadgeWrapper} ${color}`}
      onClick={onClick}
    >
      <PriorityIcon color={color} />
      {showLabel && <div>{label}</div>}
    </div>
  );
};

export default PriorityBadge;
