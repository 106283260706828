import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { isEmpty } from 'lodash';
import { Dropdown, Menu } from 'antd';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import Button from 'components/buttons/Button/Button';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import {
  FORMAT_VALIDATE,
  SIZE_VALIDATE,
  TOTAL_UPLOAD_SIZE,
} from 'util/commonUtil';
import { getFileNameEllipsis } from 'util/stringUtil';
import { getFileSizeInMB } from 'util/fileUtil';
import iconWarning from 'assets/images/icon-warning.svg';

import styles from './inputFileUpload.module.less';

type InputFileUploadProps = {
  uploadIconLabel: string;
  allowedFileTypes: string[];
  showConfirmationRemove?: boolean;
  maxFileSizeMB?: number;
  totalUploadSizeMB?: number;
  onValidateFails?: (
    validateSetting: string,
    validateCondition?: string
  ) => void;
  getFileList?: (fileList: any) => void;
  defaultFileList?: File[];
  isDefaultFileAdded?: boolean;
};

const InputFileUpload = forwardRef(
  (
    {
      uploadIconLabel,
      allowedFileTypes,
      maxFileSizeMB = 100,
      showConfirmationRemove = false,
      totalUploadSizeMB = 100,
      onValidateFails,
      getFileList,
      defaultFileList = [],
      isDefaultFileAdded = false,
    }: InputFileUploadProps,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [fileList, setFileList] = useState<File[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [duplicateFilesList, setDuplicateFilesList] = useState<{
      [key: string]: number;
    }>({});

    const viewBtnLabel = (
      <>
        Attachments ({fileList.length}) <DownOutlined />
      </>
    );

    useEffect(() => {
      getFileList && getFileList(fileList);
      // eslint-disable-next-line
    }, [fileList]);

    useEffect(() => {
      if (!isEmpty(defaultFileList) && !isDefaultFileAdded) {
        setFileList(defaultFileList);
      }
    }, [defaultFileList, isDefaultFileAdded]);

    useEffect(() => {
      if (
        !isEmpty(defaultFileList) &&
        isDefaultFileAdded &&
        isEmpty(fileList)
      ) {
        setFileList(defaultFileList);
      }
    }, [defaultFileList, fileList, isDefaultFileAdded]);

    useImperativeHandle(ref, () => ({
      reset() {
        setFileList([]);
      },
    }));

    const beforeUpload = (event: any) => {
      const file: any = event?.target?.files[0];

      if (!file) {
        return;
      }

      const isAllowedType: boolean = allowedFileTypes.includes(file?.type);
      const isAllowedFileSize: boolean =
        getFileSizeInMB(file?.size) < maxFileSizeMB;

      const isAllowedTotalFileSize = () => {
        const uploadedFiles = [...fileList, file];
        const totalUploadedFileSize = uploadedFiles?.reduce(
          (a, { size }) => a + getFileSizeInMB(Number(size)),
          0
        );
        const isTotalUploadSizeValid =
          totalUploadedFileSize <= totalUploadSizeMB;

        return !isTotalUploadSizeValid && uploadedFiles?.length > 1;
      };

      const duplicateFile = fileList.find((uploadedFile) => {
        return uploadedFile.name == file?.name;
      });

      if (isAllowedTotalFileSize()) {
        onValidateFails &&
          onValidateFails(TOTAL_UPLOAD_SIZE, String(totalUploadSizeMB));
        event.target.value = '';
        return;
      }
      if (!isAllowedType) {
        onValidateFails &&
          onValidateFails(
            FORMAT_VALIDATE,
            allowedFileTypes?.map((item) => item.split('/')[1]).join(',')
          );
        event.target.value = '';
        return;
      }
      if (!isAllowedFileSize) {
        onValidateFails &&
          onValidateFails(SIZE_VALIDATE, String(maxFileSizeMB));
        event.target.value = '';
        return;
      }

      if (isAllowedType && isAllowedFileSize && !isAllowedTotalFileSize()) {
        if (duplicateFile) {
          setDuplicateFilesList({
            ...duplicateFilesList,
            [duplicateFile.name]:
              (duplicateFilesList[duplicateFile.name] ?? 1) + 1,
          });
          const fileName = duplicateFile.name.split('.');
          const extension = fileName.pop();
          const fileNameWithoutExtension = fileName.join('.');
          const updatedName =
            fileNameWithoutExtension +
            `(${duplicateFilesList[duplicateFile.name] ?? 1}).` +
            extension;
          const updatedFile = new File([duplicateFile], updatedName);
          setFileList([...fileList, updatedFile]);
        } else {
          setFileList([...fileList, file]);
        }
        return (event.target.value = '');
      }
    };

    const downloadFileObj = async (data: any) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      const url = window.URL.createObjectURL(data);
      a.href = url;
      a.download = data.name;
      a.click();
      window.URL.revokeObjectURL(url);
    };

    const handelFileRemove = (index: number) => {
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      handleClose();
    };

    const handleClose = () => {
      setShowDropdown(false);
    };

    const renderDropdown = (
      <Menu>
        {fileList?.map((item, index) => (
          <Menu.Item key={index}>
            <div className={styles.menuItemContainer}>
              <div
                className={styles.menuItemLabel}
                onClick={() => downloadFileObj(item)}
                title={item?.name}
              >
                {getFileNameEllipsis({
                  fileName: String(item?.name),
                  limit: 25,
                })}
              </div>
              {showConfirmationRemove ? (
                <ConfirmationModal
                  title={
                    <div className={styles.cancelUploadBody}>
                      Are you sure you want to remove this file?
                    </div>
                  }
                  icon={
                    <img
                      src={iconWarning}
                      alt="warning-icon"
                      className={styles.iconWarning}
                    />
                  }
                  confirmModalTrigger={
                    <div className={styles.menuItemIcon}>
                      <CloseOutlined className={styles.closeIcon} />
                    </div>
                  }
                  onConfirm={() => handelFileRemove(index)}
                  okText="Yes, remove file"
                  placement="topRight"
                />
              ) : (
                <div
                  className={styles.menuItemIcon}
                  onClick={() => handelFileRemove(index)}
                >
                  <CloseOutlined className={styles.closeIcon} />
                </div>
              )}
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <div className={styles.fileUploadMainContainer}>
        <div>
          <LinkButton type="link" onClick={() => inputRef?.current?.click()}>
            {uploadIconLabel}
          </LinkButton>
          <input
            type="file"
            accept={allowedFileTypes.join(',')}
            onChange={beforeUpload}
            ref={inputRef}
            hidden
          />
        </div>
        <div className={styles.viewUploadedContainer}>
          <Dropdown
            overlay={renderDropdown}
            trigger={['click']}
            overlayClassName="fileOverlay"
            visible={showDropdown}
            disabled={fileList.length === 0}
            onVisibleChange={() => setShowDropdown(!showDropdown)}
            placement="bottomRight"
          >
            <Button label={viewBtnLabel} block />
          </Dropdown>
        </div>
      </div>
    );
  }
);

InputFileUpload.displayName = 'InputFileUpload';

export default InputFileUpload;
