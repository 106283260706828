import { FC, useState } from 'react';
import { Checkbox, Row, Select } from 'antd';

import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import { FundingTypeTag } from 'components/StdFundingTypeTag/FundingTypeTag';
import styles from './planDropdown.module.less';

type PlanDropDownProps = {
  selectOptions: any[];
  dropdownPlansSelected: string[];
  changeDropdownPlansSelected: Function;
  onSystemPlanSave: Function;
  isLoading: boolean;
  isFundingTypeApplicable: boolean;
};

const PlanDropDown: FC<PlanDropDownProps> = ({
  selectOptions,
  dropdownPlansSelected,
  changeDropdownPlansSelected,
  onSystemPlanSave,
  isLoading,
  isFundingTypeApplicable,
}) => {
  const [tempSelectedValues, setTempSelectedValues] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [showRemovePopup, setShowRemovePopup] = useState<boolean>(false);

  const isSelectedAll = tempSelectedValues?.length === selectOptions?.length;

  const arraysEqual = (a: string[], b: string[]) => {
    if (a.length !== b.length) return false;
    const setA = new Set(a);
    return b.every((item) => setA.has(item));
  };

  const handleConfirm = () => {
    // Check if any changes are made to the plan selection, if not simply close the dropdown.
    if (arraysEqual(tempSelectedValues, dropdownPlansSelected)) {
      setShowDropdown(false);
      return;
    }
    // If no plans added previously, then not showing the reset popup. Otherwise, show the popup.
    if (dropdownPlansSelected.length === 0) {
      const temp = [...tempSelectedValues];
      changeDropdownPlansSelected(temp);
      setTempSelectedValues([]);
      onSystemPlanSave(temp);
      setShowDropdown(false);
    } else {
      setShowRemovePopup(true);
    }
  };

  const handleDeSelect = (value: string[]) => {
    if (value?.toString() === 'all') {
      setTempSelectedValues([]);
    } else {
      setTempSelectedValues(
        tempSelectedValues.filter((item: string) => item !== value?.toString())
      );
    }
  };

  const handleSelect = (value: string[]) => {
    if (value.includes('all')) {
      if (isSelectedAll) {
        setTempSelectedValues([]);
      } else {
        setTempSelectedValues(selectOptions.map((item) => item.value));
      }
    } else {
      setTempSelectedValues(tempSelectedValues.concat(value));
    }
  };

  return (
    <div className={styles.planDropdownWrapper}>
      <Select
        size="large"
        optionLabelProp="label"
        mode={'multiple'}
        disabled={isLoading}
        value={!showDropdown ? dropdownPlansSelected : tempSelectedValues}
        dropdownClassName={styles.addingOptionDropdown}
        maxTagCount={'responsive'}
        showArrow={true}
        getPopupContainer={(trigger) => trigger.parentNode}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Row justify="center">
              <SubmitButton
                type="primary"
                className={styles.confirmButton}
                onClick={handleConfirm}
              >
                Okay
              </SubmitButton>
            </Row>
          </div>
        )}
        tagRender={(tag) => {
          return (
            <div>
              {tag?.value !== null &&
              tag?.value !==
                (showDropdown
                  ? tempSelectedValues?.[0]
                  : dropdownPlansSelected?.[0])
                ? ','
                : ''}
              &nbsp;
              {selectOptions?.find((item) => item?.value === tag?.value)?.label}
            </div>
          );
        }}
        open={showDropdown}
        onDropdownVisibleChange={(open) => {
          if (!showRemovePopup) {
            if (!open) {
              setTempSelectedValues([]);
            } else {
              setTempSelectedValues(dropdownPlansSelected);
            }
            setShowDropdown(open);
          }
        }}
        menuItemSelectedIcon={false}
        onSelect={(value: string[]) => {
          handleSelect(value);
          setShowDropdown(true);
        }}
        onDeselect={(value: string[]) => {
          handleDeSelect(value);
          setShowDropdown(true);
        }}
      >
        {(selectOptions?.length ?? 0) !== 0 && (
          <Select.Option value={'all'} key={'all'}>
            <Checkbox
              checked={isSelectedAll}
              className={`${styles.selectAll} ${
                isSelectedAll ? styles.selectAllChecked : ''
              }`}
            />
            <span className={styles.selectLabelAllPlans}>
              {'Import All Plans'}
            </span>
          </Select.Option>
        )}
        {selectOptions?.map(
          (option: { value: string; label: string; fundingType: string }) => (
            <Select.Option value={option.value} key={option.value}>
              <div className={styles.selectOptionArea}>
                <Checkbox
                  checked={tempSelectedValues?.includes(option.value)}
                  className={styles.singleSelect}
                />
                <span className={styles.selectLabel}>{option.label}</span>
                {isFundingTypeApplicable && (
                  <FundingTypeTag fundingType={option.fundingType} />
                )}
              </div>
            </Select.Option>
          )
        )}
      </Select>
      <ConfirmationDialog
        visible={showRemovePopup}
        confirmText={'Yes - Reset Plans'}
        cancelText="Cancel"
        isCancelLink
        title={'Import from existing plans'}
        buttonsExpanded={true}
        destroyOnClose={true}
        closeModal={() => setShowRemovePopup(false)}
        onConfirm={() => {
          const temp = [...tempSelectedValues];
          changeDropdownPlansSelected(temp);
          onSystemPlanSave(temp);
          setTempSelectedValues([]);
          setShowDropdown(false);
          setShowRemovePopup(false);
        }}
      >
        Performing this action will reset all plans. Are you sure you want to
        continue?
      </ConfirmationDialog>
    </div>
  );
};

export default PlanDropDown;
