import React, { FC, useState } from 'react';

import { Button, Input } from 'antd';
import { useParams } from 'react-router-dom';
import { isNullOrUndefined } from 'modules/plans/utils';
import NextButton from 'components/buttons/NextButton/NextButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';

import { ReactComponent as WordIcon } from 'assets/images/word-logo.svg';
import { ReactComponent as CopyIcon } from 'assets/images/copy-text-icon.svg';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { updateRfp } from 'modules/rfp/services/RfpService';
import {
  exportToTxt,
  getQuestions,
  htmlToText,
} from 'modules/rfp/utils/exportUtil';
import ConfirmationWithThreeButtons from 'components/ConfirmationWithThreeButtons/ConfirmationWithThreeButtons';
import { setClearState } from 'modules/rfp/slices/rfpQuestionSlice';
import styles from './saveAndexport.module.less';

type SaveExportProps = {
  closeModal: (isForce?: boolean) => void;
  rfpName?: string;
  refetch?: () => void;
  setRfpSavedAlert?: (value: boolean) => void;
  setIsModalOpen?: (val: boolean) => void;
};

const SaveExport: FC<SaveExportProps> = (props: SaveExportProps) => {
  const { closeModal, rfpName, refetch, setRfpSavedAlert, setIsModalOpen } =
    props;
  const { brokerId } = useParams();
  const dispatch = useAppDispatch();

  const questions = useAppSelector((state) => state.rfp.rfpQuestions);

  const rfpFileName = useAppSelector((state) => state.rfp?.rfpFileName);

  const selectedRfpId = useAppSelector((state) => state.rfp?.selectedRfpId);

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [rfpDocumentName, setRfpDocumentName] = useState<string>(
    rfpName! || rfpFileName
  );
  const [rfpNameEntered, setRfpNameEntered] = useState<boolean>(false);
  const [richTextvalue, setRichTextValue] = useState<string>('');
  const [copyTextClicked, setCopyTextClicked] = useState<boolean>(false);

  const handleCloseModal = () => {
    setIsConfirmOpen(true);
  };

  const showRfpSavedMessage = () => {
    setRfpSavedAlert!(true);

    setTimeout(() => {
      setRfpSavedAlert!(false);
    }, 3000);
  };

  const handleCloseConfirm = () => {
    setIsConfirmOpen(false);
    exportToTxt(richTextvalue, rfpDocumentName);
    if (isNullOrUndefined(selectedRfpId)) {
      saveRfpHandler();
      closeModal(true);
    } else {
      updateRfp(
        brokerId!,
        selectedRfpId,
        rfpDocumentName,
        questions,
        richTextvalue,
        'SAVED'
      ).then(() => {
        refetch!();
      });
      setIsModalOpen!(false);
    }
  };

  const copyAllText = () => {
    navigator.clipboard
      .writeText(htmlToText(richTextvalue))
      .then(() => {})
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
    setCopyTextClicked(true);
  };

  const saveRfpHandler = () => {
    updateRfp(
      brokerId!,
      selectedRfpId,
      rfpDocumentName,
      questions,
      richTextvalue,
      'SAVED'
    ).then(() => {
      refetch!();
    });

    showRfpSavedMessage();
    setIsModalOpen!(false);
    dispatch(setClearState());
  };

  return (
    <div className={styles.saveExportWrapper}>
      <div>
        <div className={styles.employerName}>
          <p className={styles.titleTag}>
            <b>RFP Name</b>
          </p>
          <Input
            value={rfpDocumentName}
            onChange={(e) => {
              setRfpDocumentName(e.target.value);
              const val = e.target.value;
              if (val === '') {
                setRfpNameEntered(true);
              } else {
                setRfpNameEntered(false);
              }
            }}
            className={styles.inputForEmployer}
          />
        </div>
        <div className={styles.toolList}>
          <p>
            <b>Full RFP Document</b>
          </p>
          <LinkButton onClick={() => copyAllText()} icon={<CopyIcon />}>
            {!copyTextClicked ? 'Copy all text' : 'Copied !'}
          </LinkButton>
        </div>
        <div className={styles.richTextEditor}>
          <RichTextEditor
            maxHeight={750}
            onChange={(value: string) => {
              setRichTextValue(value);
            }}
            initialValue={getQuestions(questions).replace(/\n/g, '<br/>')}
          />
        </div>
      </div>
      <NextButton
        disabled={isNullOrUndefined(rfpDocumentName) || rfpNameEntered}
        className={styles.nextButton}
        buttonText="Save & Export RFP"
        nextStep={handleCloseModal}
      />
      <div className={styles.cancelLink}></div>

      <Button
        disabled={isNullOrUndefined(rfpDocumentName) || rfpNameEntered}
        type="default"
        className={styles.saveAndSendRfpButton}
        onClick={() => saveRfpHandler()}
      >
        <b>Save RFP</b>
      </Button>
      <div className={styles.cancelLink}>
        <LinkButton onClick={() => closeModal()}>Cancel</LinkButton>
      </div>

      {isConfirmOpen && (
        <ConfirmationWithThreeButtons
          onCancel={() => setIsConfirmOpen(false)}
          title="RFP Saved"
          header={
            <p className={styles.wordExportConfirm}>
              Do you want to export as a
              <WordIcon className={styles.wordIconSvg} /> Word Document?
            </p>
          }
          visible={isConfirmOpen}
          firstButtonAction={() => {
            handleCloseConfirm();
          }}
          firstButtonText="Export to Word"
          secondButtonText="Go to Dashboard"
          secondButtonAction={() => {
            saveRfpHandler();
          }}
        />
      )}
    </div>
  );
};

export default SaveExport;
