import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Menu, Row } from 'antd';
import debounce from 'lodash/debounce';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';

import DataTable from 'components/DataTable/DataTable';
import { DataColumn } from 'components/DataTable/DataColumn';
import OutlinedText from 'components/OutlinedText/OutlinedText';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import LinkButton from 'components/buttons/LinkButton/LinkButton';

import { buildFullName } from 'util/stringUtil';
import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
  getLabelFromValue,
} from 'util/commonUtil';
import { getInvitedTime } from 'util/dateUtil';

import ERAdmin from 'model/admin/Admin';
import { getERAdmins } from 'modules/admins/slices/erAdminSlice';
import {
  findAdminByAdminId,
  clearAdminFind,
  deleteAdmin,
  clearAdminDeletion,
  inviteAdmin,
  clearinviteAdmin,
  deactivateAdminInvite,
  clearDeactivateAdminInvite,
} from 'modules/admins/slices/adminSlice';
import {
  consultantValidateClear,
  getAdminConsultantValidation,
} from 'modules/admins/slices/adminConsultantValidateSlice';
import CreateAdmin from 'modules/admins/pages/CreateAdmin/CreateAdmin';
import SendInviteTemplate from 'modules/admins/pages/SendInviteTemplate/SendInviteTemplate';
import {
  AccountStatus,
  CONSULTANT_EMAIL_WARNING_MSG_ER_ADMIN,
} from 'modules/admins/constants/adminConstants';
import TablePagination from 'model/TablePagination';

import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import SearchBar from 'components/SearchBar/SearchBar';

import { IndividualSubTypes, IndividualType } from 'constants/commonConstants';
import AlertDialog from 'components/AlertDialog/AlertDialog';
import NoERAdminView from 'modules/employers/components/NoERAdminView/NoERAdminView';
import {
  EMPLOYER_USER,
  EMPLOYER_USERS,
} from 'modules/employers/constants/employerConstants';

import styles from './erAdminList.module.less';

const convertERAdminsForTableRender = (erAdmins: ERAdmin[]) => {
  if (erAdmins != null) {
    return erAdmins.map((admin) => ({
      key: admin.inPlaceId,
      admin: admin,
      email: admin.email,
      phone: admin.phone,
      primary: admin.primary,
      accountStatus: admin.accountStatus,
    }));
  }
  return [];
};

type ERAdminListProps = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
};

const ERAdminList: FC<ERAdminListProps> = (props: ERAdminListProps) => {
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const [searchText, setSearchText] = useState<string>('');
  const [admin, setAdmin] = useState<ERAdmin | null>(null);
  const [deleteEligibleAdmin, setDeleteEligibleAdmin] =
    useState<ERAdmin | null>(null);

  const individualSubType = useAppSelector(
    (state) => state?.auth?.auth?.appBootupInfo?.individualSubType
  );

  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );

  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);
  const [visibleConfirmationDelete, setVisibleConfirmationDelete] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [visible, setVisible] = useState<boolean>(false);
  const [invitedEmail, setInvitedEmail] = useState<string>('');
  const [displayTemplate, setDisplayTemplate] = useState<boolean>(false);
  const [isAdminDetailsView, setAdminDetailsView] = useState<boolean>(true);
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'admin',
      field: 'admin',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText,
    },
    filters: {},
  });
  const [accountStatus, setAccountStatus] = useState<string>('');
  const [displayMessage, setDisplayMessage] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const erAdmins = useAppSelector((state) => state.admins.erAdmins.erAdmins);
  const adminStatus = useAppSelector((state) => state.admins.admins);
  const inProgress = useAppSelector(
    (state) => state.admins.erAdmins.inProgress
  );
  const adminConsultantValidateState = useAppSelector(
    (state) => state.admins.adminConsultantValidate
  );

  const { content, metadata } = erAdmins || {};
  const { brokerId, employerId } = useNavContext();
  const { total } = metadata;
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};
  const { isModalOpen, setIsModalOpen } = props;

  useEffect(() => {
    if (brokerId && employerId) {
      dispatch(
        getERAdmins(
          convertPaginationConfig(paginationConfig),
          brokerId,
          employerId
        )
      );
    }
  }, [dispatch, paginationConfig, brokerId, employerId]);

  useEffect(() => {
    setDisplayMessage(true);
    if (isModalOpen) {
      setVisibleConfirmation(false);
      dispatch(clearAdminDeletion());
      dispatch(clearinviteAdmin());
      dispatch(clearDeactivateAdminInvite());
      setVisible(false);
      setAlertMessage({
        type: undefined,
        message: '',
      });
      setInvitedEmail('');
      setDisplayMessage(false);
    }
  }, [dispatch, isModalOpen]);

  useEffect(() => {
    if (
      !adminStatus.adminFindInProgress &&
      !isEmpty(adminStatus.adminObj) &&
      adminStatus.error === null
    ) {
      dispatch(clearAdminFind());
      if (!isModalOpen && !visibleConfirmationDelete) {
        setIsModalOpen(true);
        setVisibleConfirmation(false);
        setAdminDetailsView(true);
        setAdmin(adminStatus.adminObj);
      }
    }
  }, [
    dispatch,
    adminStatus?.adminFindInProgress,
    adminStatus?.adminObj,
    adminStatus?.error,
    isModalOpen,
    setIsModalOpen,
    visibleConfirmationDelete,
  ]);

  useEffect(() => {
    if (!adminStatus.deactivateInProgress && adminStatus.deactivateCompleted) {
      setVisible(true);
      setAdmin(null);
      setIsModalOpen(false);
      dispatch(clearDeactivateAdminInvite());
      if (brokerId && employerId) {
        dispatch(
          getERAdmins(
            convertPaginationConfig(paginationConfig),
            brokerId,
            employerId
          )
        );
      }
      if (adminStatus.error === null) {
        setAlertMessage({
          type: 'success',
          message: `Invite has been deactivated for ${invitedEmail}.`,
        });
      } else {
        setAlertMessage({
          type: 'error',
          message: 'Invite deactivation failed.',
        });
      }
    }
  }, [
    dispatch,
    adminStatus.deactivateInProgress,
    adminStatus.deactivateCompleted,
    adminStatus.error,
    paginationConfig,
    invitedEmail,
    brokerId,
    employerId,
    setIsModalOpen,
  ]);

  useEffect(() => {
    if (!adminStatus.deleteInProgress && adminStatus.deleteCompleted) {
      setVisibleConfirmationDelete(false);
      setVisible(true);
      setAdmin(null);
      dispatch(clearAdminDeletion());
      if (brokerId && employerId) {
        dispatch(
          getERAdmins(
            convertPaginationConfig(paginationConfig),
            brokerId,
            employerId
          )
        );
      }
      if (adminStatus.error === null) {
        setAlertMessage({
          type: 'success',
          message: 'Employer User has been removed.',
        });
      } else {
        setAlertMessage({
          type: 'error',
          message: 'Employer User delete failed.',
        });
      }
    }
  }, [
    dispatch,
    adminStatus.deleteInProgress,
    adminStatus.deleteCompleted,
    adminStatus.error,
    brokerId,
    employerId,
    paginationConfig,
  ]);

  useEffect(() => {
    if (!adminStatus.inviteInProgress && adminStatus.inviteCompleted) {
      setVisible(true);
      setAdmin(null);
      setIsModalOpen(false);
      dispatch(clearinviteAdmin());
      setDisplayTemplate(false);
      if (brokerId && employerId) {
        dispatch(
          getERAdmins(
            convertPaginationConfig(paginationConfig),
            brokerId,
            employerId
          )
        );
      }
      if (adminStatus.error === null) {
        setAlertMessage({
          type: 'success',
          message: `Invite has been sent to ${invitedEmail}.`,
        });
      } else {
        setAlertMessage({
          type: 'error',
          message: 'Invite sent failed.',
        });
      }
    }
  }, [
    dispatch,
    adminStatus.inviteInProgress,
    adminStatus.inviteCompleted,
    adminStatus.error,
    paginationConfig,
    brokerId,
    employerId,
    invitedEmail,
    setIsModalOpen,
  ]);

  const getData = (filters: TablePagination) => {
    if (filters.sorterInfo) {
      const paginationConfigData = {
        sorterInfo: filters.sorterInfo,
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      };
      setPaginationConfig(paginationConfigData);
    } else {
      const paginationConfigData = {
        sorterInfo: {
          columnKey: 'admin',
          field: 'admin',
          order: 'ascend',
        },
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      } as TablePagination;
      setPaginationConfig(paginationConfigData);
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setSearchText(_searchText);
    const pageInfo = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText: _searchText },
    };
    debounceLoadData(pageInfo);
  };

  const closeBrokerModal = () => {
    if ((isAdminDetailsView && admin !== null) || displayTemplate) {
      setIsModalOpen(false);
      setAdmin(null);
      if (brokerId && employerId) {
        dispatch(
          getERAdmins(
            convertPaginationConfig(paginationConfig),
            brokerId,
            employerId
          )
        );
      }
    } else {
      toggleConfirmationPopup();
    }
    setDisplayTemplate(false);
  };

  const confirmCloseModal = async (closeModal: boolean = true) => {
    visibleConfirmation && (await toggleConfirmationPopup());
    if (closeModal) {
      setAdminDetailsView(false);
      setAdmin(null);
      if (brokerId && employerId) {
        dispatch(
          getERAdmins(
            convertPaginationConfig(paginationConfig),
            brokerId,
            employerId
          )
        );
      }
      setIsModalOpen(false);
    }
  };

  const toggleConfirmationPopup = async () => {
    setVisibleConfirmation(!visibleConfirmation);
  };

  const toggleAdminCreatedInviteSent = () => {
    setAlertMessage({
      type: 'success',
      message: 'User has been successfully added and an invite has been sent',
    });
    setVisible(true);
  };

  const toggleAdminCreatedInviteNotSent = () => {
    setAlertMessage({
      type: 'success',
      message: 'User has been successfully added',
    });
    setVisible(true);
  };

  const toggleAdminUpdated = () => {
    setAlertMessage({
      type: 'success',
      message: 'User has been successfully updated',
    });
    setVisible(true);
  };

  const toggleConfirmationDeletePopup = () => {
    dispatch(consultantValidateClear());
    setVisibleConfirmationDelete(!visibleConfirmationDelete);
  };

  const toggleAdminDetailsView = () => {
    setAdminDetailsView(!isAdminDetailsView);
  };

  const sendInvite = () => {
    if (admin) {
      setInvitedEmail(admin.email);
      dispatch(inviteAdmin(IndividualType.ER_ADMIN, admin.id));
    }
  };

  const handleSendInvite = (admin: ERAdmin) => {
    closeAlert();
    dispatch(findAdminByAdminId(admin.id));
    setDisplayTemplate(true);
  };

  const handleDeactivateInvite = (admin: ERAdmin) => {
    closeAlert();
    if (admin) {
      setInvitedEmail(admin.email);
      dispatch(deactivateAdminInvite(admin.id));
    }
  };

  const getERAdminAccountStatus = (admin: ERAdmin): any => {
    if (admin.accountStatus === AccountStatus.SEND_INVITE) {
      return 'Not Invited';
    } else if (admin.accountStatus === AccountStatus.INVITED) {
      return (
        <div>
          Invited
          <span className={styles.sendInviteDate}>
            {getInvitedTime(admin.invitedTime)}
          </span>
          <div className={styles.resendInvite}>
            <LinkButton
              disabled={
                individualSubType === IndividualSubTypes.EMPLOYER_MEMBER
              }
              onClick={() => handleSendInvite(admin)}
            >
              Resend Invite
            </LinkButton>
          </div>
        </div>
      );
    } else if (AccountStatus.ACCOUNT_ACTIVATED) {
      return 'Account Active';
    } else {
      return;
    }
  };

  const employerMemberMenu = (admin: ERAdmin) => {
    return (
      <Menu>
        <Menu.Item key="edit" onClick={() => handleEdit(admin)}>
          User Details
        </Menu.Item>
      </Menu>
    );
  };

  const adminActionsMenu = (admin: ERAdmin) => {
    return (
      <Menu>
        <Menu.Item key="edit" onClick={() => handleEdit(admin)}>
          User Details
        </Menu.Item>
        {admin.accountStatus === AccountStatus.SEND_INVITE && (
          <Menu.Item key="send" onClick={() => handleSendInvite(admin)}>
            Send Invite
          </Menu.Item>
        )}
        {admin.accountStatus === AccountStatus.INVITED && (
          <Menu.Item key="send" onClick={() => handleDeactivateInvite(admin)}>
            Deactivate Invite
          </Menu.Item>
        )}
        <Menu.Item key="delete" onClick={() => handleDelete(admin)}>
          Remove User
        </Menu.Item>
      </Menu>
    );
  };

  const handleEdit = (admin: ERAdmin) => {
    if (admin.accountStatus === AccountStatus.SEND_INVITE) {
      setAccountStatus('Not Invited');
    } else if (admin.accountStatus === AccountStatus.INVITED) {
      setAccountStatus('Invited');
    } else if (admin.accountStatus === AccountStatus.ACCOUNT_ACTIVATED) {
      setAccountStatus('Account Active');
    }
    closeAlert();
    dispatch(findAdminByAdminId(admin.id));
  };

  const handleDelete = (admin: ERAdmin) => {
    setDeleteEligibleAdmin(admin);
    closeAlert();
    dispatch(findAdminByAdminId(admin.id));
    dispatch(
      getAdminConsultantValidation({
        adminType: IndividualType.ER_ADMIN,
        employerId: admin.employerId,
        individualId: admin.id,
        orgId: admin.organizationId,
      })
    );
    toggleConfirmationDeletePopup();
  };

  const confirmDelete = () => {
    if (deleteEligibleAdmin) {
      dispatch(
        deleteAdmin(
          deleteEligibleAdmin.organizationId || null,
          deleteEligibleAdmin.id
        )
      );
      setDeleteEligibleAdmin(null);
    }
  };

  const closeAlert = () => {
    dispatch(clearAdminDeletion());
    dispatch(clearinviteAdmin());
    dispatch(clearDeactivateAdminInvite());
    setVisible(false);
    setAlertMessage({
      type: undefined,
      message: '',
    });
    setInvitedEmail('');
  };

  const renderMember = (admin: ERAdmin) => {
    return (
      <div className={styles.name}>
        <span className={styles.nameColStyle} onClick={() => handleEdit(admin)}>
          <OverflowPopover
            popoverContent={buildFullName(admin.firstName, admin.lastName)}
            maxWidth={admin.primary ? '100px' : '246px'}
          >
            {buildFullName(admin.firstName, admin.lastName)}
          </OverflowPopover>
          {admin.primary && <OutlinedText text="PRIMARY CONTACT" />}
        </span>
      </div>
    );
  };

  const adminTableColumns: DataColumn[] = [
    {
      title: EMPLOYER_USER?.toUpperCase(),
      dataIndex: 'admin',
      key: 'admin',
      width: '27%',
      ellipsis: {
        showTitle: false,
      },
      sorter: (a: any, b: any) => 0, // this sort is done by backend.
      defaultSortOrder: 'ascend',
      render: (admin) => renderMember(admin),
      align: 'left',
    },
    {
      title: 'USER TYPE',
      dataIndex: 'individualSubType',
      key: 'individualSubType',
      width: '15%',
      ellipsis: {
        showTitle: false,
      },
      render: (_, admin: any) => {
        return <>{getLabelFromValue(admin?.admin?.individualSubType)}</>;
      },
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      width: '25%',
      ellipsis: {
        showTitle: false,
      },
      render: (email) => <OverflowPopover>{email}</OverflowPopover>,
    },
    {
      title: 'ACCOUNT STATUS',
      dataIndex: 'admin',
      key: 'admin',
      width: '20%',
      render: (admin) => getERAdminAccountStatus(admin),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'admin',
      key: 'admin',
      align: 'center',
      render: (admin) => (
        <SelectDropdown
          overlay={
            individualSubType === IndividualSubTypes.EMPLOYER_MEMBER
              ? employerMemberMenu(admin)
              : adminActionsMenu(admin)
          }
        />
      ),
    },
  ];

  return isEmpty(content) &&
    displayMessage &&
    !inProgress &&
    isEmpty(searchText) ? (
    <div className={styles.noERAdminCardWrapper}>
      <NoERAdminView isModalOpen={true} setIsModalOpen={setIsModalOpen} />
    </div>
  ) : (
    <div className={styles.membersTableWrapper}>
      <div className={styles.alertWrapper}>
        {visible && (
          <AlertMessage
            type={alertMessage.type}
            message={alertMessage.message}
            closeAlert={closeAlert}
          />
        )}
      </div>
      <Row className={styles.searchBarWrapper}>
        <SearchBar
          placeholder={'Search ' + EMPLOYER_USERS}
          onChange={handleSearch}
        />
      </Row>
      <DataTable
        currentIndex={paginationConfig.paginationIndex}
        data={convertERAdminsForTableRender(content)}
        columns={adminTableColumns}
        pagination={true}
        getData={getData}
        loading={inProgress}
        total={total}
        pageSize={limit}
      />
      {!adminStatus.adminFindInProgress && (
        <>
          {admin?.id !== appBootInfo?.individualId &&
            visibleConfirmationDelete && (
              <ConfirmationDialog
                title="Remove Employer User"
                confirmText="Yes - Remove Employer User"
                cancelText="Cancel"
                closeModal={toggleConfirmationDeletePopup}
                onConfirm={confirmDelete}
                confirmLoading={adminConsultantValidateState.inProgress}
                visible={visibleConfirmationDelete}
              >
                <div className={styles.warningConfirmation}>
                  <p>
                    Are you sure you want to remove this Employer User? They
                    <br /> will no longer be able to access the system; this
                    action cannot <br /> be un-done.
                  </p>
                  {Boolean(
                    adminConsultantValidateState?.consultantValidateObj
                  ) ? (
                    <p>
                      This Employer User is also an Employer Consultant in the
                      system. {CONSULTANT_EMAIL_WARNING_MSG_ER_ADMIN}
                    </p>
                  ) : null}
                </div>
              </ConfirmationDialog>
            )}
          {admin?.id === appBootInfo?.individualId &&
            visibleConfirmationDelete && (
              <AlertDialog
                title="Unable to Remove"
                onCancel={toggleConfirmationDeletePopup}
                closeModal={toggleConfirmationDeletePopup}
                visible={visibleConfirmationDelete}
              >
                <p className={styles.warningConfirmation}>
                  You are not able to remove yourself from the system.
                </p>
              </AlertDialog>
            )}
        </>
      )}
      <FullScreenModal
        visible={isModalOpen}
        onCancel={closeBrokerModal}
        footer={false}
        title={
          !displayTemplate
            ? isAdminDetailsView && admin !== null
              ? `Employer User Details`
              : `${admin ? 'Edit' : 'Add'} Employer User`
            : ''
        }
        className={styles.mediaScreen}
        destroyOnClose
      >
        {!displayTemplate && (
          <CreateAdmin
            type={IndividualType.ER_ADMIN}
            admin={admin}
            confirmCloseModal={(closeModal: boolean) =>
              confirmCloseModal(closeModal)
            }
            visibleConfirmation={visibleConfirmation}
            toggleConfirmationPopup={toggleConfirmationPopup}
            adminCreatedInviteSent={toggleAdminCreatedInviteSent}
            adminCreatedInviteNotSent={toggleAdminCreatedInviteNotSent}
            adminUpdated={toggleAdminUpdated}
            toggleAdminDetailsView={toggleAdminDetailsView}
            adminDetailsView={isAdminDetailsView}
            adminAccountStatus={accountStatus}
          />
        )}
        {displayTemplate && admin && (
          <SendInviteTemplate
            admin={admin}
            closeBrokerModal={closeBrokerModal}
            sendInvite={sendInvite}
          />
        )}
      </FullScreenModal>
    </div>
  );
};

export default ERAdminList;
