import { FC, useState } from 'react';
import { SortableHandle } from 'react-sortable-hoc';

import { Row } from 'antd';
import moveIcon from 'assets/images/icon-move.svg';
import { buildFullName, buildInitials } from 'util/stringUtil';
import TeamMember from 'model/TeamMember';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import DraggableDataTable from 'components/DraggableDataTable/DraggableDataTable';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import AddBrokerAdmin from 'modules/employers/components/AddBrokerAdmin/AddBrokerAdmin';

import styles from './editBrokerTeam.module.less';

type Props = {
  employerId: string;
  updateOrder: Function;
  closeEditModal: Function;
  getUpdatedTeamMembers: Function;
  accessibleTeamMembers: TeamMember[];
  teamMembers: TeamMember[];
};

// Need to start from capital since this is used as a component
// eslint-disable-next-line
const DragHandle = SortableHandle(() => (
  <img src={moveIcon} alt="move-icon" className={styles.moveIcon} />
));

const EditBrokerTeam: FC<Props> = (props) => {
  const {
    employerId,
    updateOrder,
    accessibleTeamMembers,
    getUpdatedTeamMembers,
    closeEditModal,
    teamMembers,
  } = props;
  const [isOpenBrokerAdmins, setIsOpenBrokerAdmins] = useState<boolean>(false);

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      className: 'dragVisible',
      render: () => <DragHandle />,
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      render: (member: any) => {
        return (
          <ProfileAvatar
            content={buildInitials(member.firstName, member.lastName)}
            src={member.avatarUrl}
          />
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '320px',
      className: 'dragVisible',
    },
  ];

  const convertTeamMembersForTableRender = (
    teamMembers: TeamMember[]
  ): any[] => {
    if (teamMembers) {
      return teamMembers
        .filter((member) => member.assigned)
        .map((member, index) => ({
          index,
          avatar: member,
          key: member.id,
          name: buildFullName(member.firstName, member.lastName),
        }));
    }
    return [];
  };

  const closeAddBrokerAdminModal = () => {
    getUpdatedTeamMembers();
    setIsOpenBrokerAdmins(false);
  };

  const updateTeamOrder = () => {
    updateOrder();
  };

  return (
    <>
      <Row className={styles.editTeamWrapper}>
        <DraggableDataTable
          columns={columns}
          data={convertTeamMembersForTableRender(teamMembers)}
        />
      </Row>
      <Row justify="center" className={styles.linkWrapper}>
        <LinkButton
          onClick={() => setIsOpenBrokerAdmins(true)}
          className={styles.addAdminButton}
        >
          + Add Broker Admin
        </LinkButton>
      </Row>
      <Row justify="center" className={styles.rowStyle}>
        <SubmitButton type="primary" onClick={updateTeamOrder}>
          Update Order
        </SubmitButton>
      </Row>

      <Row justify="center" className={styles.cancelWrapper}>
        <CancelButton disabled={false} onClick={() => closeEditModal(false)}>
          Cancel
        </CancelButton>
      </Row>
      {isOpenBrokerAdmins && (
        <AddBrokerAdmin
          employerId={employerId}
          isModalOpen={isOpenBrokerAdmins}
          setIsModalOpen={closeAddBrokerAdminModal}
          accessibleTeamMembers={accessibleTeamMembers}
        />
      )}
    </>
  );
};
export default EditBrokerTeam;
