import { ReactNode, useState, useRef, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import DBGClient from 'modules/clients/DBGClient/DBGClient';
import DeductiblesOOPMaxDBGWrapper from 'modules/plans/components/DeductiblesOOPMax/DeductiblesOOPMaxDBGWrapper';
import MedicalPlan from 'model/plans/MedicalPlan';
import BasicPlanInfoDBGWrapper from 'modules/plans/components/BasicPlanInfo/BasicPlanInfoDBGWrapper';
import {
  MEDICAL_PLAN_CHANNEL,
  OPEN_ADD_NEW_PLAN_MODAL,
  OPEN_BASIC_INFO_MODAL,
  OPEN_DEDUCTIBLE_MODAL,
  OPEN_RX_MODAL,
  OPEN_SERVICE_MODAL,
  OPEN_RATES_MODAL,
  RELOAD_MEDICAL_PLAN,
  EXIT_BENGUIDE,
  BENGUIDE_SAVE_COMPLETED,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  CLOSE_MODALS,
} from 'modules/clients/DBGClient/DBGClientConts';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  findMedicalPlanById,
  resetPlanReduxStore,
  resetStateCompleted,
  saveMedicalPlan,
} from 'modules/plans/slices/medicalPlanSlice';
import { useNavContext } from 'hooks/useNavContext';
import useDBGClient from 'modules/clients/DBGClient/useDBGClient';
import ServiceDBGWrapper from 'modules/plans/components/Services/ServiceDBGWrapper';
import RatesDBGWrapper from 'modules/plans/components/Rates/RatesDBGWrapper';
import PanelSection from 'modules/plans/enums/PanelSection';
import IconInfo from 'assets/images/icon-info.svg';
import { BenefitCategory } from 'constants/commonConstants';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import { ERROR_MESSAGE_PLAN_EDITING } from 'constants/benguideCollaborationConstants';
import { changedBenguideModalState } from 'modules/benefitGuide/slices/benguideSlice';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import MedicalPlanRecommenderPopup from 'components/MedicalPlanRecommenderPopup/MedicalPlanRecommenderPopup';
import { getPlanRecommenderStatus } from 'modules/plans/slices/planRecommenderSllice';
import AddPlanModal from 'modules/plans/components/AddPlanModal/AddPlanModal';
import BenefitGuideNotificationsChannel from './BenefitGuideNotificationsChannel';

import styles from './medicalPlansChannel.module.less';

type EventComponent = {
  component: ReactNode;
  title: string;
  props?: object;
};

const MedicalPlansChannel = () => {
  const [isRenewalStarted, setIsRenewalStarted] = useState(false);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [eventData, setEventData] = useState(new MedicalPlan());
  const [dbgMetaData, setDbgMetaData] = useState();
  const [subscribedEvent, setSubscribedEvent] = useState('');
  const [disableSave, setDisableSave] = useState(false);
  const [planYearId, setPlanYearID] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmationVisible, setConfirmationVisible] =
    useState<boolean>(false);
  const [dispatched, setDispatched] = useState<boolean>(false);
  const { planYearsList } = useAppSelector((state) => state.employer.employer);
  const [getUpcomingPlanYears, { data: upcomingData }] =
    useLazyGetUpcomingPlanYearsByEmployerQuery();
  const { isLoading, planRecommender } = useAppSelector(
    (state) => state.planRecommender
  );
  const { employer } = useNavContext();
  const { employerId } = useParams();
  const medicalPlanRef = useRef<any>();
  const medicalPlanRefServices = useRef<any>();

  const resetPlanRef = () => {
    if (eventData.id && subscribedEvent === OPEN_RATES_MODAL) {
      dispatch(findMedicalPlanById(eventData.id));
    }
  };

  const cancelModal = () => {
    dispatch(resetPlanReduxStore(BenefitCategory.MEDICAL.value, employerId!));
    medicalPlanRef?.current?.reset();
    medicalPlanRefServices?.current?.reset();
    setModalVisible(false);
  };

  const cancelModalWhenExit = () => {
    cancelModal();
    dispatch(changedBenguideModalState(false));
  };

  const cancelModalWhenRefresh = () => {
    cancelModal();
    dbgClient.postMessage({
      channel: MEDICAL_PLAN_CHANNEL,
      event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
      data: {},
    });
  };

  const eventComponentMapper = {
    [OPEN_DEDUCTIBLE_MODAL]: {
      component: DeductiblesOOPMaxDBGWrapper,
      title: 'Edit Deductibles & OOP MAX',
      width: 600,
    } as EventComponent,
    [OPEN_BASIC_INFO_MODAL]: {
      component: BasicPlanInfoDBGWrapper,
      title: 'Edit Basic Info',
      width: 700,
      props: {
        ref: medicalPlanRef,
        dbgPlanYear: planYearId,
      },
    } as EventComponent,
    [OPEN_SERVICE_MODAL]: {
      component: ServiceDBGWrapper,
      title: 'Edit Services',
      props: {
        type: PanelSection.SERVICES,
        wrappedRef: medicalPlanRefServices,
      },
      width: 700,
    } as EventComponent,
    [OPEN_RX_MODAL]: {
      component: ServiceDBGWrapper,
      title: 'Edit RX',
      props: {
        type: PanelSection.RX,
        wrappedRef: medicalPlanRefServices,
      },
      width: 700,
    } as EventComponent,
    [OPEN_ADD_NEW_PLAN_MODAL]: {
      component: AddPlanModal,
      title: 'Add Plan',
      props: {
        benefit: 'MEDICAL',
        isOpen: isModalVisible,
        onClose: () => {
          setModalVisible(false);
        },
        dbgPlanYear: planYearId,
        isDisable: disableSave,
        isRenewalProcessStarted: isRenewalStarted,
      },
      useInbuiltModal: true,
    } as EventComponent,
    [OPEN_RATES_MODAL]: {
      component: RatesDBGWrapper,
      title: 'Edit Premiums & Contributions',
      width: 800,
      props: {
        ref: medicalPlanRef,
      },
    } as EventComponent,
  } as any;

  const {
    component: Component,
    title,
    props,
    useInbuiltModal,
    width,
  } = eventComponentMapper[subscribedEvent] || {};

  const dispatch = useAppDispatch();
  const plan = useAppSelector((state) => state.plan.plans);
  const { medicalPlan, inProgress } = plan;
  const dbgClient = useDBGClient();

  useEffect(() => {
    setLoading(inProgress);
  }, [inProgress]);

  const savePlan = useCallback(async () => {
    if (
      (subscribedEvent === OPEN_BASIC_INFO_MODAL &&
        (await medicalPlanRef?.current?.isValidForm())) ||
      subscribedEvent !== OPEN_BASIC_INFO_MODAL
    ) {
      setLoading(true);
      dispatch(
        saveMedicalPlan(medicalPlan, () => {
          dbgClient.postMessage({
            channel: MEDICAL_PLAN_CHANNEL,
            event: RELOAD_MEDICAL_PLAN,
            data: medicalPlan,
          });
        })
      );
    }
  }, [dbgClient, dispatch, medicalPlan, subscribedEvent]);

  const checkPlanRecommenderStatus = async () => {
    setDispatched(true);
    await dispatch(getPlanRecommenderStatus(medicalPlan));
  };

  useEffect(() => {
    if (employer) {
      dispatch(getPlanYears(employer?.id ?? ''));
      getUpcomingPlanYears({ employerId: employer?.id ?? '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employer?.id]);

  useEffect(() => {
    const isPlanYearInList = !!planYearsList?.find(
      (planYear: any) => planYear.id === planYearId
    )?.current;

    const hasUpcomingPlanYearWithNullId = upcomingData?.upcomingPlanYears?.some(
      (obj: any) => obj.planYearId === null
    );

    const isRenewalStarted = isPlanYearInList && hasUpcomingPlanYearWithNullId;

    setIsRenewalStarted(isRenewalStarted ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcomingData, planYearsList]);

  useEffect(() => {
    if (!isLoading && dispatched) {
      if (
        planRecommender.anyChangesAffectToPlanRecommender &&
        planRecommender.planRecommenderEnabled
      ) {
        setConfirmationVisible(true);
        setDispatched(false);
      } else if (!planRecommender.anyChangesAffectToPlanRecommender) {
        savePlan();
        setDispatched(false);
      }
    }
  }, [isLoading, dispatched, planRecommender, savePlan]);

  return (
    <>
      <DBGClient
        channel={MEDICAL_PLAN_CHANNEL}
        subscribeCommon={(event: string) => {
          if (CLOSE_MODALS === event) {
            setModalVisible(false);
            setLoading(false);
            dispatch(resetStateCompleted());
          }
        }}
        subscribe={(event: string, data: any, dbgMetaData: any) => {
          switch (event) {
            case EXIT_BENGUIDE:
              cancelModalWhenExit();
              break;
            case BENGUIDE_SAVE_COMPLETED:
              cancelModal();
              break;
            default:
              setSubscribedEvent(event);
              setEventData(data);
              setDbgMetaData(dbgMetaData);
              setModalVisible(true);
              setDisableSave(false);
              setPlanYearID(dbgMetaData?.planYearId);
              break;
          }
        }}
      />
      <BenefitGuideNotificationsChannel
        setDisableSave={() => setDisableSave(true)}
        isPlanEditModalOpen={isModalVisible}
        benefitKind={BenefitCategory.MEDICAL.value}
      />
      {Component && useInbuiltModal ? (
        <Component medicalPlan={eventData} {...props} />
      ) : (
        <>
          <Modal
            width={width}
            title={<strong>{title}</strong>}
            visible={isModalVisible}
            onCancel={cancelModal}
            okText="Done"
            footer={false}
            className={styles.medicalPlanChannelModal}
            closable={false}
            confirmLoading={!inProgress || !isLoading}
            maskClosable={false}
            destroyOnClose={true}
            afterClose={resetPlanRef}
          >
            <div className={styles.medicalPlanChannelWrapper}>
              <div className={styles.leftBorder}></div>
              <div className={styles.medicalPlanChannel}>
                <div>
                  <img src={IconInfo} alt="Icon Info" />
                </div>
                <div className={styles.defaultText}>
                  Changing plan information here will change the plan
                  information everywhere this plan is shown
                </div>
              </div>
              {disableSave && (
                <FixedAlertMessage
                  type={'error'}
                  message={ERROR_MESSAGE_PLAN_EDITING}
                />
              )}
              {Component && (
                <Component
                  medicalPlan={eventData}
                  dbgMetaData={dbgMetaData}
                  {...props}
                />
              )}
              <div className={styles.buttons}>
                <Button
                  onClick={disableSave ? cancelModalWhenRefresh : cancelModal}
                  className={styles.cancel}
                >
                  Cancel
                </Button>
                <Button
                  disabled={disableSave}
                  onClick={checkPlanRecommenderStatus}
                  className={disableSave ? styles.disabled : styles.done}
                  loading={loading}
                >
                  Done
                </Button>
              </div>
            </div>
            <MedicalPlanRecommenderPopup
              isModalVisible={confirmationVisible}
              planRecommender={planRecommender}
              setCloseModal={setConfirmationVisible}
              savePlan={savePlan}
            ></MedicalPlanRecommenderPopup>
          </Modal>
        </>
      )}
    </>
  );
};

export default MedicalPlansChannel;
