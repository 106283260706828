import { Card, Row, Col, Divider } from 'antd';
import { FC } from 'react';

import LifeAndDisabilityPlanRates from 'model/plans/LifeAndDisabilityPlanRates';
import MDVPlanRates from 'model/plans/MDVPlanRates';
import * as PlanRates from 'modules/plans/planRates/pages/PlanRates/PlanRates';
import { ReactComponent as IconBenefits } from 'assets/images/navigation/DigitalBenefitsGuide.svg';
import MDVPlanRatesData from 'model/plans/MDVPlanRatesData';
import LifeAndDisabilityPlanRatesData from 'model/plans/LifeAndDisabilityPlanRatesData';
import MDVContributionData from 'model/plans/MDVContributionData';
import {
  BasicPlans,
  BenefitTypesDropdownOptions,
  FourTier,
  RateType,
} from 'modules/plans/constants';
import { displayNumberWithDecimalValue } from 'modules/plans/utils';
import styles from './planRatesList.module.less';

type PlanRatesListProps = {
  benefitKind: string;
  mdvRates?: { [key: string]: Array<MDVPlanRates> } | null;
  lifeDisabilityRates?: {
    [key: string]: Array<LifeAndDisabilityPlanRates>;
  } | null;
  noGroupsSelected: boolean;
};

const EE_PLUS_PREFIX = 'EE_PLUS_';
const EE_ONLY = 'EE_ONLY';
const EE_ONLY_LABEL = 'EE Only';
const ES_LABEL = 'EE + Spouse';
const EC_LABEL = 'EE + Child(ren)';
const EF_LABEL = 'EE + Family';

const PlanRatesList: FC<PlanRatesListProps> = (props) => {
  const { benefitKind, mdvRates, lifeDisabilityRates, noGroupsSelected } =
    props;

  const getTier = (tier: string) => {
    if (
      tier.startsWith(EE_PLUS_PREFIX) &&
      !isNaN(Number(tier.charAt(tier.length - 1)))
    ) {
      return `${FourTier.EE.value} + ${Number(tier.charAt(tier.length - 1))}`;
    }

    switch (tier) {
      case FourTier.EE.value:
        return EE_ONLY_LABEL;
      case FourTier.ES.value:
        return ES_LABEL;
      case FourTier.EC.value:
        return EC_LABEL;
      case FourTier.EF.value:
        return EF_LABEL;
      case EE_ONLY:
        return EE_ONLY_LABEL;
      default:
        return tier;
    }
  };

  const getLifeDisabilityBenefitKind = (benefitKind: string) => {
    switch (benefitKind) {
      case BasicPlans.BASIC_LIFE.value:
        return BasicPlans.BASIC_LIFE.label;
      case BasicPlans.BASIC_ADD.value:
        return BasicPlans.BASIC_ADD.label;
      case BasicPlans.BASIC_LIFE_AND_ADD.value:
        return BasicPlans.BASIC_LIFE_AND_ADD.label;
      case BasicPlans.LTD.value:
        return BasicPlans.LTD.label;
      case BasicPlans.STD.value:
        return BasicPlans.STD.label;
      default:
        return benefitKind;
    }
  };

  const getPremiumSplit = (data: MDVContributionData) => {
    let premiumSplitString = '';
    if (isNaN(Number(data.employerPremiumSplit))) {
      premiumSplitString += '-';
    } else {
      premiumSplitString += data.employerPremiumSplit;
    }
    premiumSplitString += '/';
    if (isNaN(Number(data.employeePremiumSplit))) {
      premiumSplitString += '-';
    } else {
      premiumSplitString += data.employeePremiumSplit;
    }

    return premiumSplitString;
  };

  const getErCost = (
    data: MDVPlanRatesData,
    contributionVOMap: MDVContributionData
  ) => {
    if (data.hsaCompatible) {
      if (
        isNaN(contributionVOMap.employerCost) &&
        isNaN(contributionVOMap.hsaContribution)
      ) {
        return '-';
      }
      return `$${
        displayNumberWithDecimalValue(contributionVOMap.employerCost) ||
        displayNumberWithDecimalValue(0)
      } + $${
        displayNumberWithDecimalValue(contributionVOMap.hsaContribution) ||
        displayNumberWithDecimalValue(0)
      }`;
    } else {
      return contributionVOMap.employerCost
        ? `$${displayNumberWithDecimalValue(contributionVOMap.employerCost)}`
        : '-';
    }
  };

  const getBenefit = (planData: LifeAndDisabilityPlanRatesData) => {
    if (
      planData.lifeBenefit === BenefitTypesDropdownOptions.FLAT_AMOUNT.value
    ) {
      return planData.benefitAsFlatAmount
        ? `$${displayNumberWithDecimalValue(planData.benefitAsFlatAmount)}`
        : '-';
    }
    if (
      planData.lifeBenefit ===
      BenefitTypesDropdownOptions.MULTIPLIER_OF_SALARY.value
    ) {
      return planData.benefitAsSalaryMultiplier
        ? `${planData.benefitAsSalaryMultiplier}x Salary`
        : '-';
    }
    if (
      planData.lifeBenefit ===
      BenefitTypesDropdownOptions.MULTIPLIER_OF_SALARY_AND_FLAT_AMOUNT.value
    ) {
      return `${
        planData.benefitAsSalaryMultiplier
          ? `${planData.benefitAsSalaryMultiplier}x Salary`
          : '-'
      } + ${
        planData.benefitAsFlatAmount
          ? `$${displayNumberWithDecimalValue(planData.benefitAsFlatAmount)}`
          : '-'
      }`;
    }

    return '-';
  };

  const getLifeOrAddRate = (planData: LifeAndDisabilityPlanRatesData) => {
    if (planData.benefitKind === BasicPlans.BASIC_LIFE.value) {
      return planData.lifeRate ? planData.lifeRate.toFixed(3) : '-';
    }

    if (planData.benefitKind === BasicPlans.BASIC_ADD.value) {
      return planData.addRate ? planData.addRate.toFixed(3) : '-';
    }

    return '-';
  };

  const formatLifeDisabilityRates = (
    planData: LifeAndDisabilityPlanRatesData
  ) => {
    switch (planData.benefitKind) {
      case BasicPlans.BASIC_LIFE.value:
      case BasicPlans.BASIC_ADD.value:
        return (
          <>
            <Row className={styles.planRatesHeader}>
              <Col span={8}>
                <p>PLAN</p>
              </Col>
              <Col span={4}>
                <p>BENEFIT</p>
              </Col>
              <Col span={5}>
                <p>BENEFIT MAXIMUM</p>
              </Col>
              <Col span={5}>
                <p>GUARANTEED ISSUE</p>
              </Col>
              <Col span={2}>
                <p>
                  {planData.benefitKind === BasicPlans.BASIC_LIFE.value
                    ? 'LIFE RATE'
                    : 'AD&D RATE'}
                </p>
              </Col>
            </Row>
            <Divider className={styles.dividerBottom}></Divider>
            <Row>
              <Col span={8}>
                <p className={styles.tierHeader}>
                  {planData.planName ? planData.planName : '-'}
                </p>
              </Col>
              <Col span={4}>
                <p>{getBenefit(planData)}</p>
              </Col>
              <Col span={5}>
                <p>
                  {planData.benefitMaximum
                    ? `$${displayNumberWithDecimalValue(
                        planData.benefitMaximum
                      )}`
                    : '-'}
                </p>
              </Col>
              <Col span={5}>
                <p>
                  {planData.grantedIssue
                    ? `$${displayNumberWithDecimalValue(planData.grantedIssue)}`
                    : '-'}
                </p>
              </Col>
              <Col span={2}>
                <p>{getLifeOrAddRate(planData)}</p>
              </Col>
            </Row>
          </>
        );
      case BasicPlans.BASIC_LIFE_AND_ADD.value:
        return (
          <>
            <Row className={styles.planRatesHeader}>
              <Col span={8}>
                <p>PLAN</p>
              </Col>
              <Col span={4}>
                <p>BENEFIT</p>
              </Col>
              <Col span={4}>
                <p>BENEFIT MAXIMUM</p>
              </Col>
              <Col span={4}>
                <p>GUARANTEED ISSUE</p>
              </Col>
              <Col span={2}>
                <p>LIFE RATE</p>
              </Col>
              <Col span={2}>
                <p>AD&D RATE</p>
              </Col>
            </Row>
            <Divider className={styles.dividerBottom}></Divider>
            <Row>
              <Col span={8}>
                <p className={styles.tierHeader}>
                  {planData.planName ? planData.planName : '-'}
                </p>
              </Col>
              <Col span={4}>
                <p>{getBenefit(planData)}</p>
              </Col>
              <Col span={4}>
                <p>
                  {planData.benefitMaximum
                    ? `$${displayNumberWithDecimalValue(
                        planData.benefitMaximum
                      )}`
                    : '-'}
                </p>
              </Col>
              <Col span={4}>
                <p>
                  {planData.grantedIssue
                    ? `$${displayNumberWithDecimalValue(planData.grantedIssue)}`
                    : '-'}
                </p>
              </Col>
              <Col span={2}>
                <p>{planData.lifeRate ? planData.lifeRate?.toFixed(3) : '-'}</p>
              </Col>
              <Col span={2}>
                <p>{planData.addRate ? planData.addRate?.toFixed(3) : '-'}</p>
              </Col>
            </Row>
          </>
        );
      case BasicPlans.LTD.value:
      case BasicPlans.STD.value:
        return (
          <>
            <Row className={styles.planRatesHeader}>
              <Col span={8}>
                <p>PLAN</p>
              </Col>
              <Col span={5}>
                <p>BENEFIT</p>
              </Col>
              <Col span={6}>
                <p>BENEFIT MAXIMUM</p>
              </Col>
              <Col span={5}>
                <p>
                  {planData.benefitKind === BasicPlans.LTD.value
                    ? 'LTD RATE'
                    : 'STD RATE'}
                </p>
              </Col>
            </Row>
            <Divider className={styles.dividerBottom}></Divider>
            <Row>
              <Col span={8}>
                <p className={styles.tierHeader}>
                  {planData.planName ? planData.planName : '-'}
                </p>
              </Col>
              <Col span={5}>
                <p>{planData.benefit ? `${planData.benefit}%` : '-'}</p>
              </Col>
              <Col span={6}>
                <p>
                  {planData.benefitMaximum
                    ? `$${displayNumberWithDecimalValue(
                        planData.benefitMaximum
                      )}`
                    : '-'}
                </p>
              </Col>
              <Col span={5}>
                <p>{planData.lifeRate ? planData.lifeRate?.toFixed(3) : '-'}</p>
              </Col>
            </Row>
          </>
        );
    }
  };

  if (noGroupsSelected) {
    return (
      <>
        <div className={styles.noPlans}>
          <IconBenefits />
          <p>No benefit classes have been selected</p>
        </div>
      </>
    );
  }

  if (
    (!mdvRates || Object.values(mdvRates).length === 0) &&
    (!lifeDisabilityRates || Object.values(lifeDisabilityRates).length === 0)
  ) {
    return (
      <>
        <div className={styles.noPlans}>
          <IconBenefits />
          <p>There are no plans that fit this benefit class</p>
        </div>
      </>
    );
  }

  if (
    benefitKind === PlanRates.MEDICAL ||
    benefitKind == PlanRates.DENTAL ||
    benefitKind == PlanRates.VISION
  ) {
    const groupedRates: { [key: string]: Array<MDVPlanRates> } = mdvRates
      ? mdvRates
      : {};

    return (
      <div className={styles.ratesTable}>
        {Object.values(groupedRates).map((value, index) => (
          <Card key={index} className={styles.planRatesCard}>
            <Row justify="space-between">
              <Col>
                <h2>{value[0].data.planName}</h2>
              </Col>
              <Col>
                <img
                  src={value[0].carrierLogoUrl}
                  alt={'carrier logo'}
                  className={styles.carrierLogo}
                />
              </Col>
            </Row>
            {value?.map((rates, index) => (
              <div key={index}>
                {index > 0 ? <br /> : null}
                <p className={styles.planRatesHeader}>
                  {`Benefit Classes: ${rates.benefitGroups.join(', ')}`}
                </p>
                {rates.data.rateType === RateType.AGE_BAND.value ? (
                  <Row>
                    <Col className={styles.ageBandedCol}>
                      <h2>Age Banded Plan</h2>
                      <p>
                        Rates for age banded plans are based on the ages of plan
                        enrollees instead of specific tiers.
                        <br />
                        Reference your plan documents for this plan&#39;s age
                        banded rates table.
                      </p>
                    </Col>
                  </Row>
                ) : (
                  <Row className={styles.planRatesHeader}>
                    <Col span={5}>
                      <p>TIER</p>
                    </Col>
                    <Col span={6}>
                      <p>PREMIUM SPLITS ER/EE (%)</p>
                    </Col>
                    <Col span={5}>
                      <p className={styles.hasHsaHeader}>ER COST / MO.</p>
                      <p>{rates.data.hsaCompatible ? 'PREMIUM + HSA' : ''}</p>
                    </Col>
                    <Col span={5}>
                      <p>EE COST / MO.</p>
                    </Col>
                    <Col span={3}>
                      <p>TOTAL COST / MO.</p>
                    </Col>
                  </Row>
                )}
                {rates.data.rateType === RateType.AGE_BAND.value ||
                !rates.data.contributionVOMap
                  ? null
                  : Object.keys(rates.data.contributionVOMap).map(
                      (tier, index) => (
                        <div key={index}>
                          <Divider className={styles.dividerBottom}></Divider>
                          <Row>
                            <Col span={5}>
                              <p className={styles.tierHeader}>
                                {getTier(tier)}
                              </p>
                            </Col>
                            <Col span={6}>
                              <p>
                                {getPremiumSplit(
                                  (rates.data.contributionVOMap as any)[tier]
                                )}
                              </p>
                            </Col>
                            <Col span={5}>
                              <p>
                                {getErCost(
                                  rates.data,
                                  (rates.data.contributionVOMap as any)[tier]
                                )}
                              </p>
                            </Col>
                            <Col span={5}>
                              <p>
                                {!isNaN(
                                  Number(
                                    (rates.data.contributionVOMap as any)[tier]
                                      .employeeCost
                                  )
                                )
                                  ? `$${displayNumberWithDecimalValue(
                                      (rates.data.contributionVOMap as any)[
                                        tier
                                      ].employeeCost
                                    )}`
                                  : '-'}
                              </p>
                            </Col>
                            <Col span={3}>
                              <p>
                                {Number(
                                  (rates.data.contributionVOMap as any)[tier]
                                    .totalCost
                                )
                                  ? `$${displayNumberWithDecimalValue(
                                      (rates.data.contributionVOMap as any)[
                                        tier
                                      ].totalCost
                                    )}`
                                  : '-'}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      )
                    )}
              </div>
            ))}
          </Card>
        ))}
      </div>
    );
  }

  if (benefitKind === PlanRates.LIFE_DISABILITY) {
    const groupedRates: { [key: string]: Array<LifeAndDisabilityPlanRates> } =
      lifeDisabilityRates ? lifeDisabilityRates : {};

    return (
      <div className={styles.ratesTable}>
        {Object.keys(groupedRates).map((benefitKind: string) => (
          <Card key={benefitKind} className={styles.planRatesCard}>
            <Row justify="space-between">
              <Col>
                <h2>{getLifeDisabilityBenefitKind(benefitKind)}</h2>
              </Col>
              <Col>
                <img
                  src={groupedRates[benefitKind][0].carrierLogoUrl}
                  alt={'carrier logo'}
                  className={styles.carrierLogo}
                />
              </Col>
            </Row>
            <div>
              {groupedRates[benefitKind].map((value, index) => (
                <div key={index}>
                  {index !== 0 ? (
                    <>
                      <br />
                      <br />
                    </>
                  ) : null}
                  <Row>
                    <p className={styles.planRatesHeader}>
                      {`Benefit Classes: ${value.benefitGroups.join(', ')}`}
                    </p>
                  </Row>
                  {formatLifeDisabilityRates(value.data)}
                </div>
              ))}
            </div>
          </Card>
        ))}
      </div>
    );
  }

  return null;
};

export default PlanRatesList;
