import { Row } from 'antd';

import LinkButton from 'components/buttons/LinkButton/LinkButton';

import { currencyFormatter } from 'util/commonUtil';

import styles from 'modules/renewals/components/CreditsDiscountsDetailsFooter/CreditsDiscountsDetailsFooter.module.less';

type Props = {
  credits?: number;
  discounts?: number;
  showViewDescription?: boolean;
  onViewDescriptionClick?: () => void;
};

const CreditsDiscountsDetailsFooter = ({
  credits,
  discounts,
  showViewDescription,
  onViewDescriptionClick,
}: Props) => {
  return (
    <Row>
      {(credits === undefined || discounts === undefined) && (
        <div className={styles.creditsDiscountsRow}>
          {credits && (
            <div className={styles.creditsDiscountsItem}>
              <span>Credits: </span>
              <span className={styles.value}>
                {currencyFormatter(
                  Math.round(credits),
                  undefined,
                  undefined,
                  0
                )}
              </span>
            </div>
          )}
          {discounts && (
            <div className={styles.creditsDiscountsItem}>
              <span>Discounts: </span>
              <span className={styles.value}>
                {currencyFormatter(
                  Math.round(discounts),
                  undefined,
                  undefined,
                  0
                )}
              </span>
            </div>
          )}
        </div>
      )}
      {showViewDescription && (
        <Row>
          <LinkButton
            onClick={onViewDescriptionClick}
            className={styles.viewDescriptionButton}
          >
            View Description
          </LinkButton>
        </Row>
      )}
    </Row>
  );
};

export default CreditsDiscountsDetailsFooter;
