import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import PlanBasicInfoForm from 'modules/plans/components/DVPlanBasicInfoForm/PlanBasicInfoForm';
import { VisionPlan } from 'model/plans/VisionPlan';
import { updateVisionPlan } from 'modules/plans/slices/visionPlanSlice';

type VisionBasicPlanInfoDBGWrapperProps = {
  visionPlan: VisionPlan;
  error: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

const VisionBasicPlanInfoDBGWrapper = forwardRef(
  (props: VisionBasicPlanInfoDBGWrapperProps, ref) => {
    const basicPlanInfoRef = useRef<any>();
    const dispatch = useAppDispatch();
    const editedVisionPlan = useAppSelector(
      (state) => state.plan.visionPlan.editedVisionPlan
    );
    const [requiredFieldError, setRequiredFieldError] =
      useState<boolean>(false);
    const { visionPlan } = props;
    const changeValues = (changedPlan: VisionPlan) => {
      dispatch(updateVisionPlan(changedPlan));
    };

    useEffect(() => {
      dispatch(updateVisionPlan(visionPlan));
    }, [dispatch, visionPlan]);

    useImperativeHandle(ref, () => ({
      reset() {
        basicPlanInfoRef.current?.resetForm();
      },
      isValidForm() {
        return basicPlanInfoRef.current?.isValidForm();
      },
    }));

    return (
      <PlanBasicInfoForm
        onChange={changeValues}
        plan={visionPlan}
        isCloseConfirmed={false}
        isDBGPlan={true}
        error={[requiredFieldError, setRequiredFieldError]}
        ref={basicPlanInfoRef}
        benefitCategory="VISION"
        hasSameContributions={editedVisionPlan?.hasSameContributions}
        isSingleColumn
      />
    );
  }
);

VisionBasicPlanInfoDBGWrapper.displayName = 'VisionBasicPlanInfoDBGWrapper';
export default VisionBasicPlanInfoDBGWrapper;
