import { FC, useEffect, useState } from 'react';

import { Col, Row, Form, Input } from 'antd';
import { RuleObject } from 'antd/lib/form';
import isEmpty from 'lodash/isEmpty';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import InputForm from 'components/InputForm/InputForm';
import { EMPTY_MESSAGE } from 'modules/employers/constants/employerConstants';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  saveAllBenefitGroup,
  saveBenefitGroup,
} from 'modules/employers/slices/employerCreateStepperSlice';
import AlertMessage from 'components/Alert/AlertMessage';
import { formVerificationMsg } from 'constants/commonConstants';
import styles from './addBenefitClass.module.less';

type BenefitGroupFormProps = {
  onClose: (...args: any[]) => any;
  expanded: boolean;
  editBenefitGroup?: string | null;
};

const { Item } = Form;

const AddBenefitClassForm: FC<BenefitGroupFormProps> = (
  props: BenefitGroupFormProps
) => {
  const { onClose, editBenefitGroup } = props;
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const benefitGroups = useAppSelector(
    (state) => state.employer.employerCreateStepper.benefitGroups
  );

  useEffect(() => {
    if (editBenefitGroup) {
      form.setFieldsValue({ benefitGroup: editBenefitGroup });
    }
  }, [editBenefitGroup, form]);

  const onReset = () => {
    form.resetFields();
    onClose();
    setAlertVisible(false);
  };

  const validateBenefitGroupName = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errorInfo) {
      return false;
    }
  };

  const onInputChange = async (changedValues: any, allValues: any) => {
    form.setFieldsValue(allValues);
    const valid = await validateBenefitGroupName();
    if (valid) {
      setAlertVisible(false);
    }
  };

  const handleSave = async (values: any) => {
    const formValid = await validateBenefitGroupName();
    if (formValid) {
      const { benefitGroup } = values;
      if (editBenefitGroup) {
        const updatedBenefitGroups: string[] = [];
        benefitGroups.forEach((t) => {
          if (t === editBenefitGroup) {
            updatedBenefitGroups.push(benefitGroup.trim());
          } else {
            updatedBenefitGroups.push(t);
          }
        });
        dispatch(saveAllBenefitGroup(updatedBenefitGroups));
      } else {
        dispatch(saveBenefitGroup(benefitGroup.trim()));
      }
      onReset();
    }
  };

  const validateBenefitGroup = (rule: RuleObject, value: string) => {
    if (value) {
      const existingValues = benefitGroups.filter(
        (t) =>
          t.toLowerCase() === value.toLowerCase().trim() &&
          t.toLocaleLowerCase() !== editBenefitGroup?.toLocaleLowerCase().trim()
      );
      if (existingValues && existingValues.length > 0) {
        setAlertVisible(true);
        setAlertMessage('A benefit class with this name already exists.');
        return Promise.reject(new Error(''));
      } else if (isEmpty(value.trim())) {
        return Promise.reject(new Error(''));
      } else {
        setAlertVisible(false);
        return Promise.resolve();
      }
    }
    setAlertVisible(true);
    setAlertMessage(formVerificationMsg);
    return Promise.reject(new Error(''));
  };

  const validate = () => {
    const { benefitGroup } = form.getFieldsValue(['benefitGroup']);
    if (benefitGroup && isEmpty(benefitGroup.trim())) {
      setAlertVisible(true);
      setAlertMessage(formVerificationMsg);
      return;
    }
  };

  return (
    <>
      {alertVisible && (
        <AlertMessage
          type="error"
          message={alertMessage}
          closeAlert={() => setAlertVisible(false)}
        />
      )}
      <InputForm
        form={form}
        onFinish={handleSave}
        onValuesChange={onInputChange}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Item
              label="Benefit Class Name"
              name="benefitGroup"
              rules={[
                { required: true, whitespace: true, message: EMPTY_MESSAGE },
                {
                  validator: validateBenefitGroup,
                  validateTrigger: 'onSubmit',
                },
              ]}
              labelCol={{ span: 24 }}
            >
              <Input id="benefitGroup" size="large" />
            </Item>
          </Col>
        </Row>
        <Row justify="center" className={styles.btnWrapper}>
          <Col>
            <SubmitButton htmlType="submit" onClick={validate}>
              Save Benefit Class
            </SubmitButton>
          </Col>
        </Row>

        <Row justify="center" className={styles.btnWrapper}>
          <Col>
            <CancelButton disabled={false} onClick={onReset}>
              Cancel
            </CancelButton>
          </Col>
        </Row>
      </InputForm>
    </>
  );
};

export default AddBenefitClassForm;
