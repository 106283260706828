import {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form, notification } from 'antd';
import { get, isEmpty, isEqual } from 'lodash';

import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import PlanBasicForm from 'modules/plans/components/PlanBasicForm/PlanBasicForm';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { AdditionalPlan } from 'model/plans/AdditionalPlan';
import { useNavContext } from 'hooks/useNavContext';
import {
  PLAN_ADDITIONAL_DOCUMENT,
  VALIDATION_NAME_DUPLICATED,
} from 'modules/plans/constants';
import {
  BenefitCategory,
  benefitGroupsChangedMsg,
  BenefitKind,
  EMPTY_MESSAGE,
  formVerificationMsg,
  HOLIDAYS_AND_TIME_OFF_BENEFIT_KINDS,
  planAlreadyExistsError,
  planYearChangedMsg,
} from 'constants/commonConstants';
import {
  clearApiErrors,
  findPlanById,
  saveWorkLifePlan,
} from 'modules/plans/slices/workLifePlanSlice';
import { getCarriersByBenefitKind } from 'modules/plans/slices/basicPlanInfoSlice';
import {
  ERROR_MESSAGE_PLAN_EDITING,
  PLAN_NOTIFICATION_KEY,
} from 'constants/benguideCollaborationConstants';
import { holidayAndTimeOffPlanTypes } from 'modules/plans/utils';

import AdditionalPlanResources from 'modules/plans/components/AdditionalPlanResources/AdditionalPlanResources';
import FileType from 'model/plans/FileType';
import WebLinkType from 'model/plans/WebLinkType';
import {
  removePlanDocuments,
  updateWeblinks,
  uploadPlanDocument,
  uploadRemovePlanDocument,
} from 'modules/plans/slices/planDocumentsSlice';
import styles from './addWorkLifePlan.module.less';

export const HolidaysTimeOffPlanBasicInfoFormFields = {
  requiredFields: ['planName', 'planYear', 'benefitClass'],
  formFields: [
    'planName',
    'planYear',
    'benefitCarrier',
    'benefitClass',
    'file',
    'weblink',
    'groupId',
  ],
};
type HolidaysTimeOffPlanProps = {
  onClose: () => void;
  closePlanAddModal: () => void;
  isCloseConfirmed: boolean;
  benefitKind: any;
  isPlanUpdateMode?: boolean;
  plan?: any;
  isSaveDisabled?: boolean;
  dbgPlanYear?: string;
  isDBGPlan?: boolean;
  isModalVisible?: boolean;
};

const AddWorkLifePlan = forwardRef((props: HolidaysTimeOffPlanProps, ref) => {
  const {
    isCloseConfirmed,
    benefitKind,
    onClose,
    closePlanAddModal,
    isPlanUpdateMode,
    plan,
    isSaveDisabled = false,
    dbgPlanYear,
    isDBGPlan,
    isModalVisible,
  } = props;

  type formDataType = {
    planName: '';
    benefitCarrier: '';
    planYear: '';
    benefitClass: string[];
    weblink: '';
    fileName: '';
    groupId: '';
  };

  const dispatch = useAppDispatch();
  const { employerId, brokerId } = useNavContext();
  const { planYearsList } = useAppSelector((state) => state.employer.employer);
  const { error, inProgress } = useAppSelector(
    (state) => state.plan.holidaysTimeOffPlan
  );
  const basicPlanInfo = useAppSelector((state) => state.plan.planBasicInfo);
  const { inProgress: documentUploadInProgress } = useAppSelector(
    (state) => state.plan.planDocuments
  );
  const { carriersList } = basicPlanInfo;

  const additionalResourcesRef = useRef<any>();

  const [form] = Form.useForm();
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });

  const [formData, setFormData] = useState<formDataType>({
    planName: '',
    benefitCarrier: '',
    planYear: '',
    benefitClass: [],
    weblink: '',
    fileName: '',
    groupId: '',
  });

  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [notificationKey] = useState(PLAN_NOTIFICATION_KEY);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPlanYearWarning, setShowPlanYearWarning] =
    useState<boolean>(false);

  const [selectedFileList, setSelectedFileList] = useState<FileType[]>([]);
  const [isDocRemoved, setIsDocRemoved] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedWeblink, setSelectedWeblink] = useState<WebLinkType[]>([]);

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedFileList([]);
      setSelectedWeblink([]);
      form.resetFields();
    },
  }));

  useEffect(() => {
    if (error?.data?.code === VALIDATION_NAME_DUPLICATED) {
      form.setFields([{ name: 'planName', errors: [EMPTY_MESSAGE] }]);

      setAlertMessage({
        type: 'error',
        message: planAlreadyExistsError,
      });
      setShowAlert(true);
      dispatch(clearApiErrors());
      setLoading(false);
    }
  }, [dispatch, error, form]);

  useEffect(() => {
    if (isCloseConfirmed) {
      setShowWarning(false);
      setShowPlanYearWarning(false);
      setShowAlert(false);
    }
  }, [isCloseConfirmed, form]);

  useEffect(() => {
    if (plan) {
      const {
        name,
        groups,
        planYearId,
        webLink,
        documents,
        benefitCarrier,
        groupId,
      } = plan;
      const attachedFileName = get(documents, 'PLAN_DOCUMENT');
      const { id } = benefitCarrier || {};
      const formData = {
        planName: name,
        benefitClass: groups,
        planYear: planYearId,
        benefitCarrier: id ? id : null,
        weblink: attachedFileName ? null : webLink,
        fileName: attachedFileName,
        groupId: groupId,
      } as formDataType;

      form.setFieldsValue(formData);
      setFormData(formData);
    }
  }, [setFormData, form, plan]);

  const planBenefitKind = holidayAndTimeOffPlanTypes.includes(
    benefitKind?.value
  )
    ? BenefitCategory.HOLIDAYS_AND_TIME_OFF.value
    : BenefitCategory.FAMILY_AND_LEAVE.value;

  useEffect(() => {
    if (brokerId && employerId && isModalVisible) {
      dispatch(getCarriersByBenefitKind(planBenefitKind, brokerId, employerId));
    }
  }, [dispatch, brokerId, employerId, planBenefitKind, isModalVisible]);

  useEffect(() => {
    setShowAlert(false);
    setShowWarning(false);
    setShowPlanYearWarning(false);
    if (isPlanUpdateMode && !isEqual(plan?.groups, formData.benefitClass)) {
      setShowPlanYearWarning(false);
      setShowWarning(true);
      setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
      setShowAlert(true);
    }
    if (
      isPlanUpdateMode &&
      !isEqual(plan?.planYearId, formData.planYear?.trim())
    ) {
      setShowPlanYearWarning(true);
      setAlertMessage({ message: planYearChangedMsg, type: 'warning' });
      setShowAlert(true);
    }
    if (
      isPlanUpdateMode &&
      !isEqual(plan?.planYearId, formData.planYear?.trim()) &&
      !isEqual(plan?.groups, formData.benefitClass)
    ) {
      setShowPlanYearWarning(true);
      setShowWarning(true);
      setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
      setShowAlert(true);
    }
  }, [plan, formData.benefitClass, isPlanUpdateMode, formData.planYear]);

  const validateBasicInfo = async () => {
    try {
      await form.validateFields(
        HolidaysTimeOffPlanBasicInfoFormFields.requiredFields
      );
      return true;
    } catch (errorInfo: any) {
      return errorInfo.errorFields.length === 0;
    }
  };

  const getValidationResult = async () => {
    const isFormValid = await validateBasicInfo();

    if (isFormValid) {
      return true;
    } else {
      setShowAlert(true);
      return false;
    }
  };

  const closeOnSave = async (id: string) => {
    await uploadDocument(id);
  };

  const onClickSave = async () => {
    setShowAlert(false);
    setShowWarning(false);
    const isFormValid = await getValidationResult();

    const formValues = form.getFieldsValue();
    const { benefitClass, planName, planYear, benefitCarrier, groupId } =
      formValues;

    const selectedPlanYear = planYearsList.find((t) => t.id === planYear);

    const newPlan = {
      ...plan,
      benefitKind: benefitKind.value,
      employerId: employerId,
      planYearId: planYear?.trim(),
      name: planName,
      startDate: selectedPlanYear?.startDate,
      endDate: selectedPlanYear?.endDate,
      groups: benefitClass,
      benefitCarrierId: benefitCarrier ? benefitCarrier.trim() : null,
      groupId: groupId ? groupId : '',
    } as AdditionalPlan;

    delete newPlan.additionalDocumentReferences;
    delete newPlan.additionalWeblinks;

    if (isEmpty(newPlan?.documentReferences)) {
      delete newPlan.documentReferences;
    }

    if (isPlanUpdateMode) {
      newPlan.id = plan.id;
    }

    if (isFormValid) {
      if (isPlanUpdateMode && isDBGPlan) {
        setLoading(true);
      }
      await dispatch(
        saveWorkLifePlan(newPlan, (id: string) => {
          closeOnSave(id);
        })
      );
      notification.close(notificationKey);
    } else {
      setAlertMessage({
        type: 'error',
        message: formVerificationMsg,
      });
      setShowAlert(true);
    }
  };

  const onCancel = () => {
    closePlanAddModal();
  };

  const uploadDocument = async (id: string) => {
    const uploadDocuementSuccess = () => {
      setSelectedFileList([]);
      setSelectedWeblink([]);
      setShowAlert(false);
      setShowPlanYearWarning(false);
      onClose();
      if (plan && plan.id) {
        dispatch(findPlanById(plan.id));
      }
    };

    const planId = id ?? plan.id;
    const removeDocs = Object.keys(isDocRemoved)
      .filter((documentType) => isDocRemoved[documentType])
      .map((documentType) => ({
        docType: PLAN_ADDITIONAL_DOCUMENT,
        planDocumentName: documentType,
      }));
    if (!isEmpty(selectedFileList) && isEmpty(removeDocs)) {
      await dispatch(
        uploadPlanDocument(selectedFileList, planId, benefitKind.value)
      );
    } else if (!isEmpty(removeDocs) && isEmpty(selectedFileList)) {
      await dispatch(
        removePlanDocuments(planId, benefitKind.value, removeDocs)
      );
    } else if (!isEmpty(removeDocs) && !isEmpty(selectedFileList)) {
      await dispatch(
        uploadRemovePlanDocument(
          selectedFileList,
          planId,
          benefitKind.value,
          removeDocs
        )
      );
    }
    if (selectedWeblink.length > 0) {
      await dispatch(
        updateWeblinks(selectedWeblink, planId, benefitKind.value)
      );
    }
    uploadDocuementSuccess();
  };

  return (
    <div
      className={`${
        isPlanUpdateMode ? styles.planEditModeWrapper : styles.wrapperForm
      }  `}
    >
      {showAlert && (
        <AlertMessage
          type={alertMessage.type}
          className={
            isPlanUpdateMode ? styles.alertWrapper : styles.alertMessageWrapper
          }
          message={alertMessage.message}
          closeAlert={() => {
            setShowAlert(false);
          }}
        />
      )}
      {isSaveDisabled && !plan && (
        <FixedAlertMessage
          type={'error'}
          message={ERROR_MESSAGE_PLAN_EDITING}
        />
      )}
      <PlanBasicForm
        benefitCategory={
          HOLIDAYS_AND_TIME_OFF_BENEFIT_KINDS.includes(benefitKind.value)
            ? BenefitCategory.HOLIDAYS_AND_TIME_OFF.value
            : BenefitCategory.FAMILY_AND_LEAVE.value
        }
        carriersList={carriersList}
        setRequiredFieldError={setShowAlert}
        setFormData={setFormData}
        formData={formData}
        form={form}
        isPlanUpdateMode={isPlanUpdateMode}
        defaultPlanName={
          benefitKind.value === BenefitKind.OTHER_TIME_OFF.value ||
          benefitKind.value === BenefitKind.FAMILY_AND_LEAVE_OTHER.value
            ? null
            : benefitKind.label
        }
        showWarning={showWarning}
        dbgPlanYear={dbgPlanYear}
        isDBGPlan={isDBGPlan}
        isModalVisible={isModalVisible}
        isCarrierRequired={false}
        showPlanYearWarning={showPlanYearWarning}
      />
      <div className={styles.header}>Plan Resources</div>
      <AdditionalPlanResources
        ref={additionalResourcesRef}
        plan={plan}
        benefitKind={benefitKind.value}
        isCloseConfirmed={isCloseConfirmed}
        selectedFileList={selectedFileList}
        setSelectedFileList={setSelectedFileList}
        selectedWebLinkList={selectedWeblink}
        setSelectedWebLinkList={setSelectedWeblink}
        setIsDocRemoved={setIsDocRemoved}
      />
      <div className={styles.btnWrapper}>
        <SubmitButton
          disabled={isSaveDisabled}
          type="primary"
          className={styles.saveButtonWrapper}
          onClick={() => onClickSave()}
          loading={inProgress || loading || documentUploadInProgress}
        >
          {isPlanUpdateMode ? 'Done' : 'Save'}
        </SubmitButton>
        <CancelButton
          className={styles.cancelButtonWrapper}
          onClick={() => onCancel()}
          withBorder={isPlanUpdateMode}
        >
          Cancel
        </CancelButton>
      </div>
    </div>
  );
});
AddWorkLifePlan.displayName = 'AddWorkLifePlan';
export default AddWorkLifePlan;
