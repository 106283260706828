import { FC, ReactNode } from 'react';
import AlertMessage from 'components/Alert/AlertMessage';

import styles from './alertMessageWrapper.module.less';

type AlertMessageWrapperProps = {
  type: 'success' | 'error' | 'info' | 'warning' | undefined;
  message: string | ReactNode;
  visible: boolean;
  closeAlert: () => void;
};
const AlertMessageWrapper: FC<AlertMessageWrapperProps> = (props) => {
  const { type, message, closeAlert, visible, ...rest } = props;
  return (
    <div className={styles.alertWrapper}>
      {visible && (
        <AlertMessage
          type={type}
          message={message}
          closeAlert={closeAlert}
          {...rest}
        />
      )}
    </div>
  );
};

export default AlertMessageWrapper;
