import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CheckOutlined,
  DownOutlined,
  QuestionCircleOutlined,
  UpOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  notification,
  Popover,
  Row,
  Switch,
} from 'antd';
import { isEmpty } from 'lodash';
import { useAppSelector } from 'hooks/redux';
import NoClaimsView from 'modules/claims/components/NoClaimsView/NoClaimsView';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import DocumentUploader from 'modules/claims/components/DocumentUploader/DocumentUploader';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { useNavContext } from 'hooks/useNavContext';
import {
  exportErrorFile,
  useLazyGetClaimsSettingsQuery,
  usePublishClaimsMutation,
  useUploadFileMutation,
} from 'modules/claims/slices/claimsSlice';
import ClaimsView from 'modules/claims/pages/ClaimsView';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import IconCheck from 'assets/images/icon-check.svg';
import { baseApi } from 'util/apiUtil';
import {
  useGetBrokerFeatureStatusQuery,
  useGetEmployerFeatureStatusQuery,
} from 'api/featureControl';
import { FEATURE_KEYS } from 'constants/commonConstants';
import {
  CLAIMS_IMPORT_FILE_SIZE_ERROR_MSG,
  CLAIMS_IMPORT_FILE_TYPE_ERROR_MSG,
  CLAIMS_IMPORT_COMMON_ERROR_MSG,
  CLAIMS_IMPORT_DUPLICATE_DATE_RANGE_ERROR_MSG,
  CLAIMS_IMPORT_YEARS_GAP_ERROR_MSG,
  UP_CONSOLIDATE_CLAIMS,
  CLAIMS_EMPLOYER_NAME_ERROR_CODE,
} from 'modules/claims/constants/constants';
import { SIZE_VALIDATE } from 'util/commonUtil';
import alert from 'assets/images/alert-info-error.svg';
import styles from './claimsUpload.module.less';

const { Panel } = Collapse;

type ClaimUploaderProps = {
  isUploaderOpen: boolean;
  setIsUploaderOpen: Function;
  openManageClaimsModel: Function;
  isEmptyFileHistory?: boolean;

  isNoClaimApplicable: boolean;
};

const ClaimUpload = forwardRef((props: ClaimUploaderProps, ref) => {
  const {
    isUploaderOpen,
    setIsUploaderOpen,
    openManageClaimsModel,
    isEmptyFileHistory,
    isNoClaimApplicable,
  } = props;

  const employer = useAppSelector((state: any) => state.layout.employer?.name);

  const [activePanel, setActivePanel] = useState<string>('1');
  const [claimFile, setClaimFile] = useState<File | null>(null);
  const [claimFileName, setClaimFileName] = useState<string>('');
  const [isRemoved, setIsRemoved] = useState<boolean>(false);
  const [tabStatus, setTabStatus] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [sendNotification, setsendNotification] = useState<boolean>(true);
  const [errorNotification, setErrorNotification] = useState<string>('');
  const [fileUploadErrors, setFileUploadErrors] = useState<any>({});
  const [fileUploadColumnErrors, setFileUploadColumnErrors] = useState<any[]>(
    []
  );

  const documentUploaderRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const { employerId } = useNavContext();

  const [getClaimsSettings] = useLazyGetClaimsSettingsQuery();

  const { data: employerFeatureStatus, refetch: refetchEmployerFeatureStatus } =
    useGetEmployerFeatureStatusQuery({
      employerId: params.employerId,
      key: FEATURE_KEYS.CLAIMS,
    });
  const { data: brokerFeatureStatus, refetch: refetchBrokerFeatureStatus } =
    useGetBrokerFeatureStatusQuery({
      organizationId: params.brokerId,
      key: FEATURE_KEYS.CLAIMS,
    });

  const [
    uploadFile,
    { error, isLoading, isError, isSuccess, reset, isUninitialized },
  ] = useUploadFileMutation();

  const [invalidFile, setInvalidFile] = useState<boolean>(false);

  const [
    publishClaims,
    {
      isLoading: claimPublishInProgress,
      isError: claimsPublishFailed,
      isSuccess: claimsPublished,
      reset: resetClaimPublishStore,
      isUninitialized: claimPublishPending,
    },
  ] = usePublishClaimsMutation();

  useImperativeHandle(ref, () => ({
    claimsPublished: claimsPublished,
    claimsPublishInProgress: claimPublishInProgress,
    claimsPublishFailed: claimsPublishFailed,
    claimPublishPending: claimPublishPending,
  }));

  useEffect(() => {
    refetchBrokerFeatureStatus();
    refetchEmployerFeatureStatus;
  }, [refetchBrokerFeatureStatus, refetchEmployerFeatureStatus]);

  useEffect(() => {
    if (!isUninitialized) {
      if (!isError && !isLoading) {
        getClaimsSettings({
          employerId: params.employerId,
          preview: true,
        });
        setActivePanel('2');
      }
    }
  }, [
    isUninitialized,
    isLoading,
    isError,
    params.employerId,
    getClaimsSettings,
  ]);

  useEffect(() => {
    if (!claimPublishPending && claimsPublished) {
      if (!claimsPublishFailed && !claimPublishInProgress) {
        navigate(
          `/brokers/${params.brokerId}/employers/${params.employerId}/claims`
        );
        setIsUploaderOpen(false);
        notification.open({
          message: 'Publish Successful',
          description: `Your claims file was successfully published`,
          placement: 'topRight',
          duration: 3,
          className: styles.notification,
          closeIcon: <></>,
          key: 'claimPublished',
          icon: (
            <img className={styles.folderDownloadIcon} src={IconCheck} alt="" />
          ),
        });
      }
    }
  }, [
    claimPublishInProgress,
    claimPublishPending,
    claimsPublishFailed,
    claimsPublished,
    navigate,
    params.brokerId,
    params.employerId,
    setIsUploaderOpen,
  ]);

  useEffect(() => {
    if (isUploaderOpen) {
      setActivePanel('1');
    }
  }, [isUploaderOpen]);

  useEffect(() => {
    if (error && 'data' in error) {
      const errors: any = error.data;
      if (errors.errorsList !== undefined && errors.errorsList !== null) {
        setFileUploadErrors(errors.errorsList);
      } else if (errors.excelColumnErrors !== undefined) {
        setFileUploadColumnErrors(errors.excelColumnErrors);
      } else if (errors.code !== undefined) {
        setInvalidFile(true);
        if (
          errors.code === CLAIMS_IMPORT_DUPLICATE_DATE_RANGE_ERROR_MSG ||
          errors.code === CLAIMS_IMPORT_YEARS_GAP_ERROR_MSG
        ) {
          setErrorNotification(
            `${errors.code}. Please update and try uploading again.`
          );
        } else if (errors.code === CLAIMS_EMPLOYER_NAME_ERROR_CODE) {
          setErrorNotification(
            'File name does not match with the employer for upload. Please update and try again.'
          );
        } else {
          setErrorNotification(CLAIMS_IMPORT_COMMON_ERROR_MSG);
        }
      }
    }
  }, [error]);

  const collapseOnChange = (e: any) => {
    setActivePanel(e);
  };

  const onFileChange = (file: File) => {
    setInvalidFile(false);
    setIsRemoved(false);
    setTabStatus(!tabStatus);
    reset();
    resetClaimPublishStore();
    setClaimFile(file);
    const { name } = file;
    setClaimFileName(name);
    setFileUploadErrors({});
    setErrorNotification('');
    setFileUploadColumnErrors([]);
  };

  const removeFile = () => {
    setInvalidFile(false);
    reset();
    resetClaimPublishStore();
    setClaimFile(null);
    setIsRemoved(true);
    setTabStatus(!tabStatus);
    setFileUploadErrors({});
    setErrorNotification('');
    setFileUploadColumnErrors([]);
  };

  const downloadFileObj = (file: File) => {
    if (file) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      const url = window.URL.createObjectURL(file);
      a.href = url;
      a.download = file.name;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const onValidateFails = (error: any) => {
    if (error === SIZE_VALIDATE) {
      setErrorNotification(CLAIMS_IMPORT_FILE_SIZE_ERROR_MSG);
    } else {
      setErrorNotification(CLAIMS_IMPORT_FILE_TYPE_ERROR_MSG);
    }
    setInvalidFile(true);
  };

  const downloadTemplateFile = () => {
    return `${baseApi}/v1/analytics/employers/${employerId}/download?downloadType=TEMPLATE_FILE&documentType=${UP_CONSOLIDATE_CLAIMS}`;
  };

  const importFile = () => {
    if (claimFile && params.employerId) {
      uploadFile({ file: claimFile, employerId: params.employerId });
    }
  };

  const closeClaimUploadPopup = () => {
    if (isEmpty(claimFile?.name ?? null)) {
      closeClaimConfirmation();
    } else {
      setOpenModal(!openModal);
    }
  };

  const cancelClaimConfirmation = () => {
    setOpenModal(false);
  };

  const closeClaimConfirmation = () => {
    resetClaimPublishStore();
    reset();
    setIsUploaderOpen(false);
    setOpenModal(false);
    setClaimFile(null);
    setIsRemoved(true);
    setClaimFileName('');
    isEmptyFileHistory
      ? openManageClaimsModel(false)
      : openManageClaimsModel(true);
    setsendNotification(true);
    setInvalidFile(false);
  };

  const locationTooltipContent = () => (
    <div className={styles.popoverContent}>
      You can populate and upload the <br /> template file below to import
      claims <br /> data.
    </div>
  );

  const claimsPublishTooltipContent = () => (
    <div className={styles.popoverContentClaims}>
      Notifications will be sent to the <br />
      broker team and the employer admins <br />
      when this file is published.
    </div>
  );

  const claimsPublishToggleTooltipContent = () => (
    <div className={styles.popoverContentClaims}>
      Feature must be enabled for employer
      <br />
      in order to send notifications.
    </div>
  );

  const handleDownloadErrorFile = () => {
    exportErrorFile(claimFile, fileUploadErrors, fileUploadColumnErrors);
  };

  const getFileUploadErrorsCount = () => {
    if (fileUploadColumnErrors.length > 0) {
      return fileUploadColumnErrors.length;
    } else if (fileUploadErrors) {
      let errorsCount = 0;
      for (const key in fileUploadErrors) {
        if (fileUploadErrors.hasOwnProperty(key)) {
          errorsCount = errorsCount + fileUploadErrors[key].length;
        }
      }
      return errorsCount;
    } else {
      return 0;
    }
  };

  const getClaimsUploader = () => {
    return (
      <div className={styles.claimsUpload}>
        <div className={styles.employerName}>{employer}</div>
        <Collapse
          bordered={false}
          activeKey={activePanel}
          onChange={collapseOnChange}
          accordion
        >
          <Panel
            header={
              <div className={styles.collapseSectionHeader}>
                {!isEmpty(claimFileName) && isSuccess && (
                  <CheckOutlined className={styles.claimUploadSuccessIcon} />
                )}
                <span className={styles.collapseTitle}>
                  Step 1: Select Claims File
                </span>
              </div>
            }
            key={'1'}
            showArrow={false}
            extra={activePanel === '1' ? <UpOutlined /> : <DownOutlined />}
            className={styles.claimUploadCollapse}
            collapsible={isEmpty(claimFileName) ? 'disabled' : undefined}
          >
            <div className={styles.claimUploadWrapper}>
              <div className={styles.claimUploadContent}>
                <span className={styles.claimFileText}>Select Claims File</span>
                <Popover
                  content={locationTooltipContent}
                  placement="right"
                  overlayClassName="popoverInner"
                >
                  <QuestionCircleOutlined className={styles.popoverIcon} />
                </Popover>
                <Row
                  className={`${styles.documentUploaderWrapper} ${
                    activePanel === '1' ? styles.documentUploader : ''
                  }`}
                >
                  <Col>
                    <DocumentUploader
                      ref={documentUploaderRef}
                      onChange={onFileChange}
                      onRemove={removeFile}
                      isRemoved={isRemoved}
                      allowedFileTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      maxFileSizeMB={100}
                      defaultFile={null}
                      hideNoFileMessage={false}
                      onFileNameClick={(file: File) => downloadFileObj(file)}
                      onValidateFails={onValidateFails}
                      uploadError={error}
                    />
                  </Col>

                  {error && !invalidFile && (
                    <Col className={styles.errorFileNotification} span={18}>
                      <Row className={styles.errorFileRow}>
                        <Col span={2}>
                          <img
                            className={styles.errorFileIcon}
                            src={alert}
                          ></img>
                        </Col>
                        <Col className={styles.errorFileText} span={21}>
                          The submitted file contains{' '}
                          {getFileUploadErrorsCount()}{' '}
                          {getFileUploadErrorsCount() > 1 ? 'errors' : 'error'}{' '}
                          which are marked in this{' '}
                          <span
                            onClick={handleDownloadErrorFile}
                            className={styles.errorFileLink}
                          >
                            error file
                          </span>
                          . Please fix any errors and try uploading again.
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {invalidFile && (
                    <Col className={styles.errorFileNotification} span={18}>
                      <Row className={styles.errorFileRow}>
                        <Col span={2}>
                          <img
                            className={styles.errorFileIcon}
                            src={alert}
                          ></img>
                        </Col>
                        <Col className={styles.errorFileText} span={21}>
                          {errorNotification}
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col className={styles.templateFile}>
                    <LinkButton href={downloadTemplateFile()}>
                      Template File
                    </LinkButton>
                  </Col>
                </Row>
                <Button
                  loading={isLoading}
                  onClick={importFile}
                  className={styles.previewClaimsButton}
                  disabled={isEmpty(claimFileName)}
                >
                  Import & Preview Claims File
                </Button>
              </div>
            </div>
          </Panel>
          <Panel
            header={
              <div className={styles.collapseSectionHeader}>
                <span
                  className={`${styles.collapseTitle} ${
                    activePanel !== '2' ? styles.collapseTitleDisabled : ''
                  }`}
                >
                  Step 2 : Preview Claims File
                </span>
              </div>
            }
            key={'2'}
            showArrow={false}
            extra={activePanel === '2' ? <UpOutlined /> : <DownOutlined />}
            className={styles.claimPreviewCollapse}
            collapsible={
              isEmpty(claimFileName) || !isSuccess || isUninitialized
                ? 'disabled'
                : undefined
            }
          >
            <ClaimsView
              key={activePanel}
              preview={true}
              displayDummyData={false}
              isRemoved={tabStatus}
            />
            <div className={styles.buttonWrapper}>
              <div className={styles.sendNotificationWrapper}>
                <Row>
                  <div className={styles.sendNotificationText}>
                    Send Notification after Publish &nbsp;
                    <Popover
                      content={claimsPublishTooltipContent}
                      placement="top"
                      overlayClassName="popoverInner claimsPublishTooltip"
                    >
                      <QuestionCircleOutlined />
                    </Popover>
                  </div>
                  {employerFeatureStatus &&
                    brokerFeatureStatus &&
                    employerFeatureStatus.enabled &&
                    brokerFeatureStatus.enabled && (
                      <Switch
                        className={styles.sendNotificationSwitch}
                        checked={sendNotification}
                        onChange={() => {
                          setsendNotification(!sendNotification);
                        }}
                      />
                    )}
                  {employerFeatureStatus &&
                    brokerFeatureStatus &&
                    (!employerFeatureStatus.enabled ||
                      !brokerFeatureStatus.enabled) && (
                      <Popover
                        content={claimsPublishToggleTooltipContent}
                        overlayClassName="popoverInner claimsPublishTooltip"
                      >
                        <Switch
                          className={styles.sendNotificationSwitch}
                          checked={sendNotification}
                          disabled={true}
                        />
                      </Popover>
                    )}
                </Row>
              </div>
              <PageActionButton
                type="primary"
                onClick={() => {
                  publishClaims({
                    employerId: params.employerId,
                    notification: sendNotification,
                  });
                }}
                className={styles.publishClaimsButton}
                loading={claimPublishInProgress}
                disabled={isEmpty(claimFileName)}
              >
                Publish Claims File
              </PageActionButton>
              <div className={styles.cancelClaimsButton}>
                <LinkButton onClick={closeClaimUploadPopup}>Cancel</LinkButton>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  };

  return (
    <>
      {isNoClaimApplicable ? (
        <NoClaimsView
          className=""
          primaryButtonAction={() => {
            setIsUploaderOpen(true);
          }}
        />
      ) : (
        <></>
      )}

      <FullScreenModal
        visible={isUploaderOpen}
        onCancel={() => {
          closeClaimUploadPopup();
        }}
        footer={false}
        title="Import Claims File"
      >
        <div className={styles.employerName}></div>
        {getClaimsUploader()}
      </FullScreenModal>
      <ConfirmationDialog
        confirmText="Yes - close and do not save"
        onConfirm={closeClaimConfirmation}
        visible={openModal}
        onCancel={cancelClaimConfirmation}
        closeModal={cancelClaimConfirmation}
        title="Are you sure you want to close?"
        cancelText="Cancel"
      >
        <p className={styles.claimsConfirmation}>
          <div>
            If you leave now, you will lose any unsaved data. Do you wish to
            continue?
          </div>
        </p>
      </ConfirmationDialog>
    </>
  );
});

ClaimUpload.displayName = 'ClaimUpload';

export default ClaimUpload;
