import axios from 'axios';
import { baseApi, getPagingQueryString } from 'util/apiUtil';
import { FourOOneKPlan } from 'model/plans/FourOOneKPlan';

const planUrl = baseApi + '/v1/plans';

export const createRetirementPlan = (payload: FourOOneKPlan) => {
  return axios.post(`${planUrl}/retirements`, payload);
};

export const getRetirementPlanList = (
  page: number,
  size: number,
  sort: string,
  query: string,
  employerId: string,
  planYearId: string
) => {
  return axios.get(
    `${planUrl}/retirements?${getPagingQueryString(page, size, sort, query)}`,
    {
      params: {
        'employer-id': employerId,
        'plan-year-id': planYearId,
      },
    }
  );
};

export const getRetirementPlanById = (planId: string) => {
  return axios.get(`${planUrl}/retirements/${planId}`);
};

export const updateRetirementPlan = (payload: FourOOneKPlan) => {
  try {
    return axios.put(`${planUrl}/retirements/${payload.id}`, payload);
  } catch (error) {
    throw error;
  }
};

export const getPlanDocument = async (
  planId: string,
  documentType: string,
  benefitKind: string
) => {
  return await axios.get(
    `${planUrl}/${planId}/documents?documentType=${documentType}&benefitKind=${benefitKind}&isOpen=true`,
    { responseType: 'blob' }
  );
};
