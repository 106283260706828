import { Menu, Skeleton } from 'antd';
import { Feature } from 'api/navigationSlice';
import { loginTypes } from 'constants/authConstants';
import { useIsAuthorizedUserRole } from 'hooks/useIsAuthorizedUserRole';
import { featuresKeys } from 'util/featureKeysUtil';

type PrivateMenuItemProps = {
  children: JSX.Element;
  validator?: string;
  disabled?: boolean;
  isLoading: boolean;
  features: Feature[];
};

const PrivateMenuItem = (props: PrivateMenuItemProps) => {
  const { validator, disabled, isLoading, features } = props;

  const isErAdmin = useIsAuthorizedUserRole([loginTypes.erAdmin]);

  const availableFeaturesKeys = featuresKeys(features, isErAdmin);

  // All disabled menu items are hidden temporary
  if (disabled) {
    return <></>;
  }

  if (isLoading) {
    return <Skeleton paragraph={false} />;
  }

  if (validator) {
    if (availableFeaturesKeys.includes(validator)) {
      return <Menu.Item {...props} disabled={disabled} />;
    } else {
      return <></>;
    }
  }

  return <Menu.Item {...props} disabled={disabled} />;
};

export default PrivateMenuItem;
