import React from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  SBC_OPS_REVIEW_MODAL_TITLE,
  SBC_OPS_REVIEW_MODAL_WARNING,
} from 'modules/plans/constants';

import styles from './addPlanReviewFinalizeModel.module.less';

type Props = {
  onClose: () => void;
  isLoading: boolean;
  isVisible: boolean;
  onConfirm: () => void;
};

const AddPlanReviewFinalizeModal = ({
  isLoading,
  isVisible,
  onClose,
  onConfirm,
}: Props) => {
  return (
    <ConfirmationDialog
      confirmText="Finalize and Send Notification"
      isCancelLink
      title={
        <div className={styles.reviewModalWrapper}>
          {SBC_OPS_REVIEW_MODAL_TITLE}
        </div>
      }
      cancelText="Cancel"
      closeModal={onClose}
      confirmLoading={isLoading}
      onConfirm={onConfirm}
      visible={isVisible}
    >
      <div className={styles.reviewModalWrapper}>
        {SBC_OPS_REVIEW_MODAL_WARNING}
      </div>
    </ConfirmationDialog>
  );
};

export default AddPlanReviewFinalizeModal;
