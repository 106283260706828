import React, { ReactNode, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Layout, Menu, Skeleton } from 'antd';
import { useLazyGetNavigationsQuery } from 'api/navigationSlice';
import { ChildRoute, SEPARATOR } from 'routes/navigationTree';
import { loginTypes, topLevelMenus } from 'constants/authConstants';
import { isNullOrUndefined } from 'modules/plans/utils';
import {
  FEATURE_KEYS,
  TOOLS_CONFIGURATION,
  TOP_NAVIGATION,
} from 'constants/commonConstants';
import { useAppSelector } from 'hooks/redux';
import { featuresKeys } from 'util/featureKeysUtil';
import PrivateMenuItem from './PrivateMenuItem/PrivateMenuItem';

import styles from './layout.module.less';

const { Sider } = Layout;
const { SubMenu } = Menu;

type AppSiderProps = {
  defaultSelectedKeys: string[];
  defaultOpenKeys: string[];
  childrenNavs: ChildRoute[];
  menuOnClick: Function;
  logo?: ReactNode | null | undefined; // TODO
  loginType?: string;
  selectedTopNav?: string | null | undefined;
};

const AppSider = (props: AppSiderProps) => {
  const {
    defaultSelectedKeys,
    childrenNavs,
    logo,
    menuOnClick,
    defaultOpenKeys,
    loginType,
    selectedTopNav,
  } = props;

  const { brokerId, employerId } = useParams();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  useEffect(() => {
    setOpenKeys(defaultOpenKeys);
  }, [defaultOpenKeys]);

  const { inProgress } = useAppSelector((state) => state.layout);
  const [getNavigations, { data, isLoading, isFetching, isUninitialized }] =
    useLazyGetNavigationsQuery();

  useEffect(() => {
    getNavigations({
      brokerId: brokerId || null,
      employerId: employerId || null,
    });
  }, [brokerId, employerId, getNavigations]);

  const { features = [] } = data || {};

  const loadingMenu = isLoading || isUninitialized || isFetching || inProgress;

  const isBrokerToolsEnabled = features.some(
    (feature: any) =>
      feature?.enabled && feature?.feature !== FEATURE_KEYS.PLAN_RATES
  );

  const isConfigurationEnabledForBrokers = features.some(
    (feature: any) =>
      feature?.enabled && feature?.feature === FEATURE_KEYS.BENEFIT_CONSULTATION
  );

  const featureNamesToCheck = [
    FEATURE_KEYS.BENEFIT_GUIDE,
    FEATURE_KEYS.BENEFIT_CONSULTATION,
    TOOLS_CONFIGURATION.ID_CARDS,
  ];

  const isEmployeeToolEnabled = features.some(
    (feature: any) =>
      feature?.enabled && featureNamesToCheck.includes(feature?.feature)
  );

  const isBrokerAdmin = loginType === loginTypes.bokerAdmin;
  const isAiAssistant = selectedTopNav === topLevelMenus.assistant;
  const isErAdmin = loginType === loginTypes.erAdmin;

  const renderSeparatorsOnSideNav = (
    nav: ChildRoute,
    loadingMenu: boolean,
    isBrokerToolsEnabled: boolean,
    isEmployeeToolEnabled: boolean
  ) => {
    if (loadingMenu) {
      return <Skeleton paragraph={false} />;
    }

    if (
      !isNullOrUndefined(loginType) &&
      !isNullOrUndefined(selectedTopNav) &&
      isBrokerAdmin &&
      !isConfigurationEnabledForBrokers &&
      selectedTopNav === TOP_NAVIGATION.HOME
    ) {
      return <></>;
    }

    const isToolConfig =
      !isBrokerToolsEnabled && nav?.name === 'TOOLS CONFIGURATION';
    const isEmployeeTool =
      !isEmployeeToolEnabled && nav?.name === 'EMPLOYEE TOOLS';

    if (isToolConfig || isEmployeeTool) {
      return <></>;
    }

    return <div className={styles.separator}>{nav?.name}</div>;
  };

  return (
    <div
      className={`${styles.sideNav} ${
        isAiAssistant ? styles.aiAssistantScrollPaddingRight : ''
      }`}
    >
      {(childrenNavs.length > 0 || isAiAssistant) && (
        <Sider
          width={isAiAssistant ? 272 : 280}
          className={`${isAiAssistant ? styles.siderScroll : ''}`}
        >
          {logo}
          <Menu
            theme="light"
            mode="inline"
            className={styles.navMenu}
            openKeys={openKeys}
            selectedKeys={defaultSelectedKeys}
            onClick={(item: any) => menuOnClick(item)}
          >
            {childrenNavs.map((nav) => {
              if (nav.type === SEPARATOR) {
                return renderSeparatorsOnSideNav(
                  nav,
                  loadingMenu,
                  isBrokerToolsEnabled,
                  isEmployeeToolEnabled
                );
              }
              if (nav.children) {
                if (loadingMenu) {
                  return <Skeleton paragraph={false} />;
                }

                const availableFeaturesKeys = featuresKeys(features, isErAdmin);

                if (
                  nav.validator &&
                  !availableFeaturesKeys.includes(nav.validator)
                ) {
                  return <></>;
                }

                return (
                  <SubMenu
                    onTitleClick={(selectedNav) => {
                      if (openKeys.includes(selectedNav.key)) {
                        setOpenKeys(
                          openKeys.filter((key) => key !== selectedNav.key)
                        );
                      } else {
                        setOpenKeys([...openKeys, selectedNav.key]);
                      }
                    }}
                    key={`subMenu-${nav.path}`}
                    icon={
                      <img
                        src={nav.icon}
                        alt={nav.name}
                        className={styles.sideNavIcon}
                      />
                    }
                    title={nav.name}
                  >
                    {nav.children.map((sub) => {
                      if (sub.children && sub.isMenuItem) {
                        if (loadingMenu) {
                          return <Skeleton paragraph={false} />;
                        }

                        const availableFeaturesKeys = featuresKeys(
                          features,
                          isErAdmin
                        );

                        if (
                          nav.validator &&
                          !availableFeaturesKeys.includes(nav.validator)
                        ) {
                          return <></>;
                        }

                        return (
                          <SubMenu
                            className={
                              window.location.pathname.includes(sub.path)
                                ? styles.selectedThirdLevelMenu
                                : ''
                            }
                            onTitleClick={(selectedNav) => {
                              if (openKeys.includes(selectedNav.key)) {
                                setOpenKeys(
                                  openKeys.filter(
                                    (key) => key !== selectedNav.key
                                  )
                                );
                              } else {
                                setOpenKeys([...openKeys, selectedNav.key]);
                              }
                            }}
                            key={`subMenu-${sub.path}`}
                            icon={
                              sub.icon ? (
                                <img
                                  src={sub.icon}
                                  alt={sub.name}
                                  className={styles.sideNavIcon}
                                />
                              ) : (
                                <div className={styles.secondLevelNoIcon}></div>
                              )
                            }
                            title={
                              <Link
                                to={sub.path}
                                className={
                                  window.location.pathname.includes(sub.path)
                                    ? styles.selectedThirdLevelMenuText
                                    : ''
                                }
                              >
                                <span>{sub.name}</span>
                              </Link>
                            }
                          >
                            {sub.children.map((subItem) => {
                              return (
                                <PrivateMenuItem
                                  key={subItem.path}
                                  validator={subItem.validator}
                                  disabled={nav.disabled}
                                  isLoading={loadingMenu}
                                  features={features}
                                >
                                  <Link
                                    className={styles.thirdLevelLink}
                                    to={subItem.path}
                                  >
                                    {subItem.name}
                                  </Link>
                                </PrivateMenuItem>
                              );
                            })}
                          </SubMenu>
                        );
                      } else {
                        return (
                          <PrivateMenuItem
                            key={sub.path}
                            validator={sub.validator}
                            disabled={nav.disabled}
                            isLoading={loadingMenu}
                            features={features}
                          >
                            <Link to={sub.path}>{sub.name}</Link>
                          </PrivateMenuItem>
                        );
                      }
                    })}
                  </SubMenu>
                );
              } else {
                return (
                  <PrivateMenuItem
                    key={nav.path}
                    validator={nav.validator}
                    disabled={nav.disabled}
                    isLoading={loadingMenu}
                    features={features}
                  >
                    <Link to={nav.path}>
                      {nav.icon ? (
                        <img
                          src={nav.icon}
                          alt={nav.name}
                          className={styles.sideNavIcon}
                        />
                      ) : (
                        <img className={styles.noSideNavIcon} alt="" />
                      )}
                      {isAiAssistant ? nav?.displayName : nav?.name}
                    </Link>
                  </PrivateMenuItem>
                );
              }
            })}
          </Menu>
        </Sider>
      )}
    </div>
  );
};

export default AppSider;
