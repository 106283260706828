import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Input, Modal, Row } from 'antd';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import {
  useDeleteAccoutEmailMutation,
  useDeleteSupportEmailsMutation,
} from 'modules/tools/slices/issuesLogProvisioningSlice';
import {
  DELETE_EMAIL_ADDRESS,
  DELETE_SUPPORT_TEAM,
  ISSUE_TYPE_ACCOUNT,
  ISSUE_TYPE_SUPPORT,
  ORGANIZATION_ID_KEY,
  SUPPORT_TEAM_KEY,
} from 'modules/issueslog/constants/IssuesLogConstants';
import { usePrevious } from 'hooks/usePrevious';
import { showErrorNotification } from 'components/Notification/Notification';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';

import { loginTypes } from 'constants/authConstants';
import { useAppSelector } from 'hooks/redux';
import { downloadInstructionPdf } from 'modules/issueslog/slices/issuesLogSlice';
import styles from './automatedEmailDeleteModal.module.less';

type AutomatedEmailDeleteModalProps = {
  visible: boolean;
  onCancel: () => void;
  onDelete: () => void;
  email: string;
  type: string;
  ownerId: string;
  deleteSupportTeam?: boolean;
  teamName?: string;
  unresolvedCount?: number;
  isSupport: boolean;
};

const AutomatedEmailDeleteModal: FC<AutomatedEmailDeleteModalProps> = ({
  visible,
  onCancel,
  onDelete,
  email,
  type,
  ownerId,
  deleteSupportTeam,
  teamName,
  unresolvedCount,
  isSupport,
}) => {
  const { brokerId } = useParams();
  const [enteredText, setEnteredText] = useState<string>('');
  const isConfirmed = enteredText === 'DELETE';

  const [deleteAccountEmail, { isLoading, isSuccess }] =
    useDeleteAccoutEmailMutation();

  const [
    deleteSupportEmail,
    {
      isLoading: supportEmailDeleting,
      isSuccess: supportEmailDeleted,
      isError: isSupportEmailDeleteError,
      error: supportEmailDeleteError,
    },
  ] = useDeleteSupportEmailsMutation();

  const isLoadingPrevious = usePrevious(isLoading);
  const issupportEmailDeletingPrevious = usePrevious(supportEmailDeleting);
  const appBootInfo: any = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );
  const isBrokerAdmin: boolean = appBootInfo?.type === loginTypes.bokerAdmin;

  useEffect(() => {
    if (visible) {
      setEnteredText('');
    }
  }, [visible]);

  useEffect(() => {
    if (isLoadingPrevious && !isLoading && isSuccess) {
      onDelete();
    }
  }, [isLoadingPrevious, isSuccess, isLoading, onDelete]);

  useEffect(() => {
    if (
      issupportEmailDeletingPrevious &&
      !supportEmailDeleting &&
      supportEmailDeleted
    ) {
      onDelete();
    }
  }, [
    issupportEmailDeletingPrevious,
    supportEmailDeleted,
    supportEmailDeleting,
    onDelete,
  ]);

  useEffect(() => {
    if (
      issupportEmailDeletingPrevious &&
      !supportEmailDeleting &&
      isSupportEmailDeleteError
    ) {
      if ((supportEmailDeleteError as any)?.data?.code === 'issues.exists') {
        showErrorNotification({
          message: 'Delete Team Failed',
          description: `There are existing support items assigned to this team.`,
          key: 'deleteSupportEmail',
        });
      }
    }
  }, [
    issupportEmailDeletingPrevious,
    isSupportEmailDeleteError,
    supportEmailDeleting,
    supportEmailDeleteError,
  ]);

  const handleUnresolvedClick = () => {
    const redirectionUrl: string = isBrokerAdmin
      ? `/brokers/${brokerId}/issues-log/support?${SUPPORT_TEAM_KEY}=${teamName}`
      : `/issues-log/support?supportTeam=${teamName}&${ORGANIZATION_ID_KEY}=${brokerId}`;
    window.open(redirectionUrl, '_blank');
  };

  return (
    <Modal
      visible={visible}
      footer={false}
      className={styles.automatedEmailDeleteModal}
      width={600}
      closeIcon={<></>}
      centered
      destroyOnClose={true}
    >
      <h1 className={styles.title}>
        {isSupport ? DELETE_SUPPORT_TEAM : DELETE_EMAIL_ADDRESS}
      </h1>
      <div className={styles.email}>{isSupport ? teamName : email}</div>
      {isSupport ? (
        <p className={styles.warningMsg}>
          Are you sure you want to delete this support team?
          <br />
          {unresolvedCount && 0 !== unresolvedCount ? (
            <>
              This team currently has{' '}
              <span
                className={styles.primaryText}
                onClick={handleUnresolvedClick}
              >
                {unresolvedCount}
              </span>{' '}
              unresolved issue(s) assigned to it. <br />
            </>
          ) : (
            <></>
          )}
          <br />
          Please note that in order to support item creation in our system, it
          was required to follow the steps given{' '}
          <span
            className={styles.primaryText}
            onClick={() => {
              downloadInstructionPdf(brokerId);
            }}
          >
            here
          </span>{' '}
          to setup the automated email for this team. You may need to reverse
          these steps to stop automated item creation.
        </p>
      ) : (
        <p className={styles.warningMsg}>
          Are you sure you want to delete this automated email address?
          <br />
          <br />
          Please note that in order to support item creation in our system, it
          was required to follow the steps given{' '}
          <span
            className={styles.primaryText}
            onClick={() => {
              downloadInstructionPdf(brokerId);
            }}
          >
            here
          </span>{' '}
          to setup this email as the automated email. You may need to reverse
          these steps to stop automated issue creation.
        </p>
      )}
      <div className={styles.typeInstructions}>
        Type “DELETE” in the box below to confirm
      </div>
      <Input
        className={
          !isEmpty(enteredText) && !isConfirmed ? styles.invalidInputField : ''
        }
        onChange={(event) => {
          setEnteredText(event?.target?.value);
        }}
      />
      {!isEmpty(enteredText) && !isConfirmed && (
        <div className={styles.invalidInput}>Invalid Input</div>
      )}
      <PageActionButton
        className={`${styles.deleteButton} ${
          isConfirmed ? '' : styles.disabledButton
        }`}
        type="primary"
        onClick={() => {
          if (isConfirmed) {
            if (ISSUE_TYPE_ACCOUNT === type) {
              deleteAccountEmail({ ownerId: ownerId });
            } else if (ISSUE_TYPE_SUPPORT == type) {
              deleteSupportEmail({
                teamName: teamName,
                organizationId: brokerId,
                deleteTeam: deleteSupportTeam,
              });
            }
          }
        }}
        loading={isLoading || supportEmailDeleting}
      >
        {isSupport ? DELETE_SUPPORT_TEAM : DELETE_EMAIL_ADDRESS}
      </PageActionButton>
      <Row justify="center" className={styles.cancelWrapper}>
        <CancelButton disabled={false} onClick={onCancel}>
          Cancel
        </CancelButton>
      </Row>
    </Modal>
  );
};

export default AutomatedEmailDeleteModal;
