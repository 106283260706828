import React from 'react';
import { Spin } from 'antd';
import styles from './customSpinner.module.less';

const CustomerSpinner: React.FC = () => (
  <div className={styles.spinnerContext}>
    <Spin />
  </div>
);

export default CustomerSpinner;
