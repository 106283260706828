import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styles from './summeryCommonTable.module.less';

type Props = {
  data: any[];
  columns: ColumnsType<any>;
  summary?: (data: readonly any[]) => React.ReactNode;
  tableClassName?: string;
  loading?: boolean;
  emptyMessage?: React.ReactNode;
  scroll?: any;
};

const SummeryCommonTable = (props: Props) => {
  const {
    data,
    columns,
    tableClassName,
    summary,
    loading = false,
    scroll,
  } = props;

  return (
    <div className={styles.tableWrapper}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        summary={summary}
        className={tableClassName}
        loading={loading}
        locale={{
          emptyText: props.emptyMessage,
        }}
        scroll={scroll}
      />
    </div>
  );
};

export default SummeryCommonTable;
