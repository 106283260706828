import axios from 'axios';
import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as CarrierService from 'modules/carriers/services/CarrierService';
import PaginationConfig from 'model/PaginationConfig';
import MetaData from 'model/MetaData';
import BenefitCarrier from 'model/BenefitCarrier';
import ErrorDetails from 'model/ErrorDetails';
import CarrierPlanInfo from 'model/plans/CarrierPlanInfo';
import { getBlobFromEncodedImageString } from 'util/fileUtil';

export interface CarrierState {
  carrierList: { content: Array<BenefitCarrier>; metadata: MetaData };
  carrierListInProgress: boolean;
  carrierListSuccess: boolean;
  createInProgress: boolean;
  carrierCreationSuccess: boolean;
  carrierCreateError: ErrorDetails | null;
  carrierObj: BenefitCarrier;
  logoUrl: string | undefined;
  logoReference: string | null;
  originalImgRef: string | null;
  inProgress: boolean;
  error: any;
  carrierFetchInProgress: boolean;
  carrierDeleteValidation: CarrierPlanInfo[];
  carrierDeleteValidationInProgress: boolean;
  carrierDeleteInProgress: boolean;
  carrierDeleteSuccess: boolean;
  requestType: string;
}

const initialState = {
  carrierList: { content: [], metadata: {} },
  carrierListInProgress: false,
  carrierListSuccess: false,
  createInProgress: false,
  carrierCreationSuccess: false,
  carrierCreateError: null,
  carrierObj: {} as BenefitCarrier,
  logoUrl: undefined,
  logoReference: null,
  originalImgRef: null,
  inProgress: false,
  error: null,
  carrierFetchInProgress: false,
  carrierDeleteValidation: [] as CarrierPlanInfo[],
  carrierDeleteValidationInProgress: false,
  carrierDeleteInProgress: false,
  carrierDeleteSuccess: false,
  requestType: '',
} as CarrierState;

const brokerBasicInfoSlice = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
    carrierListFetchStarted: (state) => {
      state.carrierListInProgress = true;
      state.carrierList = { content: [], metadata: {} };
      state.carrierListSuccess = false;
    },
    carrierListFetchSuccess: (state, { payload }) => {
      state.carrierListInProgress = false;
      state.carrierList = payload;
      state.carrierListSuccess = true;
    },
    carrierListFetchError: (state, { payload }) => {
      state.carrierListInProgress = false;
      state.carrierList = { content: [], metadata: {} };
      state.carrierListSuccess = false;
    },
    carrierCreateStarted: (state) => {
      state.createInProgress = true;
      state.carrierCreateError = null;
      state.carrierCreationSuccess = false;
    },
    carrierCreateSuceeded: (state, action: PayloadAction<BenefitCarrier>) => {
      state.carrierObj = action.payload;
      state.carrierCreationSuccess = true;
      state.carrierCreateError = null;
      state.createInProgress = false;
    },
    carrierCreateFailed: (state, action) => {
      state.createInProgress = false;
      state.carrierCreateError = action.payload;
      state.carrierCreationSuccess = false;
    },
    clearCarrierCreation: (state) => {
      state.createInProgress = false;
      state.carrierObj = {} as BenefitCarrier;
      state.carrierCreateError = null;
      state.carrierCreationSuccess = false;
    },
    uploadCarrierLogoStart(state, action: PayloadAction<string>) {
      state.inProgress = true;
      state.logoUrl = action.payload;
      state.requestType = action.type;
    },
    uploadCarrierLogoSuccess(
      state,
      action: PayloadAction<{ originalImgRef: string; logoImgRef: string }>
    ) {
      state.inProgress = false;
      state.logoReference = action.payload.logoImgRef;
      state.originalImgRef = action.payload.originalImgRef;
      state.error = null;
      state.requestType = action.type;
    },
    uploadCarrierLogoFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = { response: action.payload };
      state.logoReference = '';
      state.originalImgRef = null;
      state.logoUrl = '';
      state.requestType = action.type;
    },
    clearCarrierLogo: (state) => {
      state.logoUrl = '';
      state.originalImgRef = null;
      state.logoReference = null;
    },
    setCarrierLogo(state, action: PayloadAction<string>) {
      state.logoUrl = action.payload;
    },
    carrierFetchStarted: (state) => {
      state.carrierFetchInProgress = true;
      state.error = null;
    },
    carrierFetchSucceeded: (state, action: any) => {
      state.carrierObj = action.payload.data;
      state.carrierFetchInProgress = false;
      state.error = null;
    },
    carrierFetchFailed: (state, { payload }) => {
      state.carrierFetchInProgress = false;
      state.error = payload;
    },
    carrierDeleteValidationStarted: (state) => {
      state.carrierDeleteValidationInProgress = true;
      state.error = null;
    },
    carrierDeleteValidationSucceeded: (state, action: any) => {
      state.carrierDeleteValidation = action.payload.data;
      state.carrierDeleteValidationInProgress = false;
      state.error = null;
    },
    carrierDeleteValidationFailed: (state, { payload }) => {
      state.carrierDeleteValidationInProgress = false;
      state.error = payload;
    },
    carrierDeleteStarted: (state) => {
      state.carrierDeleteInProgress = true;
      state.carrierDeleteSuccess = false;
      state.error = null;
    },
    carrierDeleteSucceeded: (state) => {
      state.carrierDeleteInProgress = false;
      state.carrierDeleteSuccess = true;
      state.error = null;
    },
    carrierDeleteFailed: (state, { payload }) => {
      state.carrierDeleteSuccess = false;
      state.carrierDeleteInProgress = false;
      state.error = payload;
    },
    clearCarrierApiErrors: (state) => {
      state.carrierCreateError = null;
    },
  },
});

export const {
  carrierListFetchError,
  carrierListFetchStarted,
  carrierListFetchSuccess,
  carrierCreateStarted,
  carrierCreateSuceeded,
  carrierCreateFailed,
  clearCarrierCreation,
  uploadCarrierLogoStart,
  uploadCarrierLogoSuccess,
  uploadCarrierLogoFailed,
  clearCarrierLogo,
  setCarrierLogo,
  carrierFetchStarted,
  carrierFetchSucceeded,
  carrierFetchFailed,
  carrierDeleteValidationStarted,
  carrierDeleteValidationSucceeded,
  carrierDeleteValidationFailed,
  carrierDeleteStarted,
  carrierDeleteSucceeded,
  carrierDeleteFailed,
  clearCarrierApiErrors,
} = brokerBasicInfoSlice.actions;
export default brokerBasicInfoSlice.reducer;

export const getCarrierList = (
  { page, size, sort, query }: PaginationConfig,
  organizationId: string | null | undefined,
  employerId: string | null | undefined,
  type: string,
  benefitCategories: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(carrierListFetchStarted());
    try {
      const response = await CarrierService.getCarrierList(
        page,
        size,
        sort,
        query,
        organizationId,
        employerId,
        type,
        benefitCategories
      );
      const data = response.data;
      dispatch(carrierListFetchSuccess(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          carrierListFetchError(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const addCarrier = (data: BenefitCarrier, editedCarrierType: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(carrierCreateStarted());
    try {
      let response = null;
      if (data.id) {
        response = await CarrierService.updateCarrier(data, editedCarrierType);
      } else {
        response = await CarrierService.createCarrier(data);
      }
      dispatch(carrierCreateSuceeded(response.data));
    } catch (error: any) {
      dispatch(carrierCreateFailed(error.response));
    }
  };
};

export const addBenefitCarrier = (
  data: BenefitCarrier,
  editedCarrierType: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(carrierCreateStarted());
    try {
      let response = null;
      if (data.id) {
        response = await CarrierService.updateBenefitCarrier(
          data,
          editedCarrierType
        );
      } else {
        response = await CarrierService.createBenefitCarrier(data);
      }
      dispatch(carrierCreateSuceeded(response.data));
    } catch (error: any) {
      dispatch(carrierCreateFailed(error.response));
    }
  };
};

export const handleCarrierLogoUpload =
  (data: any, originalImage: string) => async (dispatch: Dispatch) => {
    dispatch(uploadCarrierLogoStart(data));
    try {
      const originalImgBlob = await getBlobFromEncodedImageString(
        originalImage
      );
      const originalImgResponse = await CarrierService.uploadCarrierLogo(
        originalImgBlob
      );
      const originalImgRef = originalImgResponse.data;

      const imageBlob = await getBlobFromEncodedImageString(data);
      const response = await CarrierService.uploadCarrierLogo(imageBlob);

      dispatch(
        uploadCarrierLogoSuccess({
          logoImgRef: response.data,
          originalImgRef: originalImgRef,
        })
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          uploadCarrierLogoFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };

export const attachCarrierLogo =
  (logoUrl: string) => async (dispatch: Dispatch) => {
    dispatch(setCarrierLogo(logoUrl));
  };

export const getCarrier = (
  organizationId: string | null | undefined,
  employerId: string | null | undefined,
  type: string,
  carrierId: string | null
) => {
  return async (dispatch: Dispatch) => {
    dispatch(carrierFetchStarted());
    try {
      const response = await CarrierService.getCarrierById(
        organizationId,
        employerId,
        type,
        carrierId
      );
      const data = { data: response.data };
      dispatch(carrierFetchSucceeded(data));
    } catch (error: any) {
      dispatch(carrierFetchFailed(error.response));
    }
  };
};

export const getBenefitCarrierById = (
  benefitCarrierId: string | null | undefined
) => {
  return async (dispatch: Dispatch) => {
    dispatch(carrierFetchStarted());
    try {
      const response = await CarrierService.getBenefitCarrierById(
        benefitCarrierId
      );
      const data = { data: response.data };
      dispatch(carrierFetchSucceeded(data));
    } catch (error: any) {
      dispatch(carrierFetchFailed(error.response));
    }
  };
};

export const validateCarrierDelete = (
  carrierId: string | null | undefined,
  organizationId: string | undefined,
  employerId: string | undefined,
  benefitCarrier: boolean
) => {
  return async (dispatch: Dispatch) => {
    dispatch(carrierDeleteValidationStarted());
    try {
      const response = await CarrierService.validateCarrierDelete(
        carrierId,
        organizationId,
        employerId,
        benefitCarrier
      );
      const data = { data: response.data };
      dispatch(carrierDeleteValidationSucceeded(data));
    } catch (error: any) {
      dispatch(carrierDeleteValidationFailed(error.response));
    }
  };
};

export const deleteCarrier = (
  carrierId: string | null | undefined,
  carrierType: string,
  employerId: string | undefined,
  organizationId: string | undefined
) => {
  return async (dispatch: Dispatch) => {
    dispatch(carrierDeleteStarted());
    try {
      await CarrierService.deleteCarrier(
        carrierId,
        carrierType,
        employerId,
        organizationId
      );
      dispatch(carrierDeleteSucceeded());
    } catch (error: any) {
      dispatch(carrierDeleteFailed(error.response));
    }
  };
};

export const deleteBenefitCarrier = (carrierId: string | null | undefined) => {
  return async (dispatch: Dispatch) => {
    dispatch(carrierDeleteStarted());
    try {
      await CarrierService.deleteBenefitCarrier(carrierId);
      dispatch(carrierDeleteSucceeded());
    } catch (error: any) {
      dispatch(carrierDeleteFailed(error.response));
    }
  };
};

export const resetCarrierDeleteStatus = () => {
  return async (dispatch: Dispatch) => {
    dispatch(carrierDeleteStarted());
  };
};
