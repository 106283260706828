import React from 'react';
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from 'components/ConfirmationDialog/ConfirmationDialog';
import ListComponent from 'components/Helpers/ListComponent/ListComponent';
import styles from './updateConfirmationListModal.module.less';

interface Props extends ConfirmationDialogProps {
  listTitle?: string;
  description?: string;
  onClickItem?: Function;
  listData: any[];
}

const UpdateConfirmationListModal = (props: Props) => {
  const { listTitle, listData, description, onClickItem, ...rest } = props;
  return (
    <ConfirmationDialog
      width={464}
      destroyOnClose={true}
      centered
      {...rest}
      bodyStyle={{ paddingTop: 0 }}
    >
      <div className={styles.contentWrapper}>
        <p className={styles.description}>{description}</p>
        <div>
          <h4 className={styles.listTitle}>{listTitle}</h4>
          <ListComponent
            dataSource={listData}
            className={styles.listWrapper}
            bordered={false}
            onClickItem={onClickItem}
            listItemClassName={styles.listItemStyles}
          />
        </div>
      </div>
    </ConfirmationDialog>
  );
};

export default UpdateConfirmationListModal;
