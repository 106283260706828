import axios from 'axios';
import { baseApi } from 'util/apiUtil';
import BrokerAdmin from 'model/BrokerAdmin';

const baseUrl = `${baseApi}/v1/logins`;
const v2Url = `${baseApi}/v2/logins`;

// TODO: Mayura - These APIs are not complete as backend APIs are still in development.
export const getAllBrokerAdmins = (id: string) => {
  return axios.get(`${baseUrl}/admins?organizationId=${id}`);
};

export const postBrokerAdmin = (brokerAdmin: BrokerAdmin) => {
  return axios.post(`${baseUrl}/admin`, brokerAdmin);
};

export const putBrokerAdmin = (brokerAdmin: BrokerAdmin) => {
  return axios.put(`${baseUrl}/admin`, brokerAdmin);
};

export const uploadAdminAvatar = async (image: Blob, original?: boolean) => {
  const formData = new FormData();
  formData.append('avatar', image);

  if (original) {
    formData.append('original', 'true');
  }

  return axios.post(`${v2Url}/avatar`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
