import { baseApi } from 'util/apiUtil';

export const downloadOfferImportFile = async (documentId: string) => {
  try {
    const url = `${baseApi}/v1/renewals/offer-files/download/${documentId}`;
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (e) {}
};

export const handleFileItemDownloadClickForPopulatedFileItem = (
  s3Key: string,
  item: string
) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  const url = `${baseApi}/v1/renewals/offers/offer-carrier/download?s3-key=${s3Key}&file-name=${item}`;
  a.href = url;
  a.click();
  window.URL.revokeObjectURL(url);
};
