import { Modal } from 'antd';
import { FC, ReactNode } from 'react';

import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import { ERROR_MESSAGE_PLAN_EDITING } from 'constants/benguideCollaborationConstants';
import infoIcon from 'assets/images/icon-info.svg';

import styles from './editPlanModal.module.less';

type EditPlanModalProps = {
  title: string;
  visible: boolean;
  onCancel: () => void;
  className?: string;
  width?: number;
  children: ReactNode;
  isSaveDisabled?: boolean;
};

const EditPlanModal: FC<EditPlanModalProps> = (props: EditPlanModalProps) => {
  const {
    width = 600,
    title,
    visible,
    onCancel,
    className = '',
    children,
    isSaveDisabled = false,
  } = props;

  return (
    <Modal
      width={width}
      visible={visible}
      onCancel={onCancel}
      okText="Done"
      footer={false}
      className={`${styles.wrapper} ${className}`}
      closable={false}
      maskClosable={false}
      confirmLoading={true}
      destroyOnClose={true}
    >
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        <div className={styles.defaultTextContainer}>
          <div>
            <img className={styles.editIcon} src={infoIcon} alt="info icon" />
          </div>
          <div className={styles.defaultText}>
            Changing plan information here will change the plan information
            everywhere this plan is shown
          </div>
        </div>
        {isSaveDisabled && (
          <FixedAlertMessage
            type="error"
            message={ERROR_MESSAGE_PLAN_EDITING}
          />
        )}
        {children}
      </div>
    </Modal>
  );
};

export default EditPlanModal;
