import { forwardRef, useState } from 'react';
import RenewalInnerStepperDto from 'model/RenewalInnerStepperDto';
import RenewalInnerStepper from 'modules/renewals/components/renewalInnerStepper/RenewalInnerStepper';
import OffersContainerWrapper from 'modules/renewals/components/addProposalsComponents/OffersContainerWrapper/OffersContainerWrapper';
import {
  OFFER_CATEGORY,
  PROPOSAL_OFFER_SELECT_SECTION,
} from 'modules/renewals/constants/renewalsConstants';
import RenewalInnerStepperInfoDto from 'model/RenewalInnerStepperInfoDto';
import styles from './offersContainerWrapperWithSubStepper.module.less';

interface IOffersContainerProps {
  subStepperSteps: RenewalInnerStepperDto[];
  setSubStepperData: (data: RenewalInnerStepperDto[]) => void;
  toggleMainStepper: (
    key: number,
    isFullyComplete: boolean,
    isNextClick: boolean
  ) => void;
  closeModal: (notShowConfirmMessage: any) => void;
  handleNavigation: () => void;
  isProposalDetailedView?: boolean;
  proposalId: string | undefined;
}

const OffersContainerWrapperWithSubStepper = (
  {
    subStepperSteps,
    setSubStepperData,
    toggleMainStepper,
    closeModal,
    handleNavigation,
    isProposalDetailedView,
    proposalId,
  }: IOffersContainerProps,
  ref: any
) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [completedSteps] = useState<number[]>([]);
  const [subStepperInfo, setSubStepperInfo] =
    useState<RenewalInnerStepperInfoDto | null>(null);

  const handleSubStepperComplete = (
    key: number,
    isFullyCompleted: boolean,
    isNextClick: boolean
  ) => {
    const stepperInfo: RenewalInnerStepperInfoDto = {
      isFullyCompleted,
      key,
      isNextClick,
    };
    setSubStepperInfo(stepperInfo);
    // In the last step navigate to last step of main
    if (isNextClick) {
      if (2 === key) {
        toggleMainStepper(3, isFullyCompleted, isNextClick);
      }
    } else {
      if (0 === key) {
        toggleMainStepper(3, isFullyCompleted, isNextClick);
      }
    }
  };

  const generateViewBasingStepper = () => {
    switch (currentStep) {
      case 0:
        return (
          <OffersContainerWrapper
            ref={ref}
            type={OFFER_CATEGORY.LIFE_AD}
            handleStepperComplete={handleSubStepperComplete}
            closeModal={closeModal}
            handleNavigation={handleNavigation}
            isProposalDetailedView={isProposalDetailedView}
          />
        );
      case 1:
        return (
          <OffersContainerWrapper
            ref={ref}
            type={OFFER_CATEGORY.SHORT_TERM_DISABILITY}
            handleStepperComplete={handleSubStepperComplete}
            closeModal={closeModal}
            handleNavigation={handleNavigation}
            isProposalDetailedView={isProposalDetailedView}
          />
        );
      case 2:
        return (
          <OffersContainerWrapper
            ref={ref}
            type={OFFER_CATEGORY.LONG_TERM_DISABILITY}
            handleStepperComplete={handleSubStepperComplete}
            closeModal={closeModal}
            handleNavigation={handleNavigation}
            isProposalDetailedView={isProposalDetailedView}
          />
        );
      default:
        return (
          <OffersContainerWrapper
            ref={ref}
            type={OFFER_CATEGORY.LIFE_AD}
            handleStepperComplete={handleSubStepperComplete}
            closeModal={closeModal}
            handleNavigation={handleNavigation}
            isProposalDetailedView={isProposalDetailedView}
          />
        );
    }
  };

  return (
    <div className={styles.subWrapperWithStepper}>
      <RenewalInnerStepper
        ref={ref}
        data={subStepperSteps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setInnerStepperData={setSubStepperData}
        completedSteps={completedSteps}
        individualItemStyling={styles.stepperItem}
        isSubStepperExist={true}
        completedStepInfo={subStepperInfo}
        mainSection={PROPOSAL_OFFER_SELECT_SECTION}
        proposalId={proposalId}
      />
      {generateViewBasingStepper()}
    </div>
  );
};

export default forwardRef(OffersContainerWrapperWithSubStepper);
