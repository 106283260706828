import { useEffect, forwardRef } from 'react';
import { Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as BlueCheckIcon } from 'assets/images/blue-check.svg';
import RenewalInnerStepperDto from 'model/RenewalInnerStepperDto';
import RenewalInnerStepperInfoDto from 'model/RenewalInnerStepperInfoDto';
import { getSelectedOffersBasedOnTheType } from 'modules/renewals/utils/renewalsUtils';
import {
  OFFER_CATEGORY,
  PROPOSAL_MODELING_SECTION,
  PROPOSAL_OFFER_SELECT_SECTION,
} from 'modules/renewals/constants/renewalsConstants';

import { useAppSelector } from 'hooks/redux';

import styles from './renewalInnerStepper.module.less';

interface IRenewalInnerStepperProps {
  data: RenewalInnerStepperDto[];
  currentStep?: number;
  setCurrentStep: (step: number) => void;
  setInnerStepperData: (data: RenewalInnerStepperDto[]) => void;
  completedSteps: number[];
  individualItemStyling: string;
  isSubStepperExist: boolean;
  completedStepInfo: RenewalInnerStepperInfoDto | any;
  proposalId?: string | undefined;
  mainSection: string;
}

const RenewalInnerStepper = (
  {
    data,
    currentStep,
    setCurrentStep,
    completedSteps,
    setInnerStepperData,
    individualItemStyling,
    isSubStepperExist = false,
    completedStepInfo,
    proposalId,
    mainSection,
  }: IRenewalInnerStepperProps,
  ref: any
) => {
  const proposalDetails = useAppSelector(
    (state) => state.proposalOnBoarding.proposal
  );
  const completedProposalSteps = useAppSelector(
    (state) => state.proposalOnBoarding.completedProposalSteps
  );

  const handleClick = (obj: RenewalInnerStepperDto, index: number) => {
    if (currentStep !== index) {
      ref?.current?.handleClose();
    }

    if (obj.isEnabled) {
      modifyActiveStep(obj.id);
      setCurrentStep(obj.id);
    }
  };

  useEffect(() => {
    setCompletedSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedSteps]);

  useEffect(() => {
    if (completedStepInfo) {
      handleStepperComplete(completedStepInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedStepInfo]);

  // Makes sure the current step is always the active one.
  useEffect(() => {
    if (currentStep !== undefined) {
      const innerStepperData = [...data];
      innerStepperData.forEach((step) => {
        step.isActive = step.id === currentStep;
      });
      setInnerStepperData(innerStepperData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    setSavedSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalDetails, completedProposalSteps]);

  const handleStepperComplete = (infoDto: RenewalInnerStepperInfoDto) => {
    const { isFullyCompleted, key, isNextClick } = infoDto;
    const changedDataSet: RenewalInnerStepperDto[] = data.map(
      (step: RenewalInnerStepperDto) => {
        if (key === step.id) {
          step.isCompleted = isFullyCompleted;
          step.isActive = false;
        }
        // next object
        if (isNextClick) {
          if (key + 1 === step.id) {
            step.isEnabled = true;
            step.isActive = true;
          }
        } else {
          if (key - 1 === step.id) {
            step.isEnabled = true;
            step.isActive = true;
          }
        }

        return step;
      }
    );
    setInnerStepperData(changedDataSet);
    if (isNextClick) {
      setCurrentStep(key + 1);
    } else {
      setCurrentStep(key - 1);
    }
  };

  const modifyActiveStep = (index: number) => {
    const activeFilteredList: RenewalInnerStepperDto[] = data.map(
      (stepDto: RenewalInnerStepperDto) => {
        stepDto.isActive = stepDto.id === index;
        return stepDto;
      }
    );
    setInnerStepperData(activeFilteredList);
  };

  const setCompletedSteps = () => {
    const activeFilteredList = data.map((stepDto) => {
      if (completedSteps.includes(stepDto.id)) {
        return {
          ...stepDto,
          isCompleted: true,
        };
      } else {
        return {
          ...stepDto,
          isCompleted: false,
        };
      }
    });
    setInnerStepperData(activeFilteredList);
  };

  const getIsCurrentStepCompleted = (stepDto: RenewalInnerStepperDto) => {
    if (stepDto.benefitKind === OFFER_CATEGORY.LIFE_AD) {
      return {
        ...stepDto,
        isCompleted: false,
        isEnabled:
          completedProposalSteps.includes(
            `${mainSection}_${OFFER_CATEGORY.LONG_TERM_DISABILITY}`
          ) ||
          completedProposalSteps.includes(
            `${mainSection}_${OFFER_CATEGORY.SHORT_TERM_DISABILITY}`
          ) ||
          completedProposalSteps.includes(
            `${mainSection}_${OFFER_CATEGORY.LIFE_AD}`
          ),
      };
    }
    return {
      ...stepDto,
      isCompleted: mainSection === PROPOSAL_MODELING_SECTION,
      isEnabled: completedProposalSteps.includes(
        `${mainSection}_${stepDto.benefitKind}`
      ),
    };
  };

  const setSavedSteps = () => {
    const activeFilteredList = data.map((stepDto) => {
      let offers = getSelectedOffersBasedOnTheType(
        proposalDetails?.payload,
        stepDto.benefitKind
      );
      if (
        stepDto.benefitKind === OFFER_CATEGORY.MEDICAL &&
        proposalDetails?.payload?.primaryMedicalOffer
      ) {
        offers = [...offers, proposalDetails?.payload?.primaryMedicalOffer];
      }
      if (
        stepDto.benefitKind === OFFER_CATEGORY.DENTAL &&
        proposalDetails?.payload?.primaryDentalOffer
      ) {
        offers = [...offers, proposalDetails?.payload?.primaryDentalOffer];
      }
      if (
        stepDto.benefitKind === OFFER_CATEGORY.VISION &&
        proposalDetails?.payload?.primaryVisionOffer
      ) {
        offers = [...offers, proposalDetails?.payload?.primaryVisionOffer];
      }
      if (proposalId) {
        return {
          ...stepDto,
          isCompleted: !isEmpty(offers),
          isEnabled: true,
        };
      }
      if (!isEmpty(offers) && mainSection === PROPOSAL_OFFER_SELECT_SECTION) {
        return {
          ...stepDto,
          isCompleted: true,
          isEnabled: true,
        };
      } else if (!isEmpty(completedProposalSteps)) {
        return getIsCurrentStepCompleted(stepDto);
      } else {
        return {
          ...stepDto,
          isCompleted: false,
        };
      }
    });
    setInnerStepperData(activeFilteredList);
  };
  return (
    <div
      className={`${styles.renewalInnerStepper} ${
        isSubStepperExist && styles.renewalInnerStepperSub
      } ${!isSubStepperExist && styles.renewalInnerStepperSubMain}`}
    >
      <Row className={styles.renewalStepperContainer}>
        {data.map((stepperDto: RenewalInnerStepperDto, index: number) => {
          return (
            <div
              key={index}
              onClick={() => {
                handleClick(stepperDto, index);
              }}
              className={`${styles.renewalStepperItem} ${
                stepperDto.isActive && styles.selectedItem
              } ${stepperDto.isEnabled && styles.enabledItem}
               ${individualItemStyling && individualItemStyling}`}
            >
              {!isSubStepperExist && (
                <Row justify={'space-between'} className={styles.mainTxt}>
                  <p
                    className={`${
                      stepperDto.isEnabled && styles.enabledMainTxt
                    } `}
                  >
                    Step {index + 1}
                  </p>
                  {stepperDto.isCompleted && stepperDto.isEnabled && (
                    <BlueCheckIcon className={styles.blueTick} />
                  )}
                </Row>
              )}
              <Row
                className={`${styles.mainTxtWrapper} ${
                  isSubStepperExist && styles.subStepperRow
                }`}
              >
                {stepperDto.isEnabled
                  ? stepperDto.mainEnabledIcon
                  : stepperDto.mainDisabledIcon}
                <p
                  className={`${styles.mainTitle} ${
                    stepperDto.isEnabled && styles.mainTitleEnabled
                  } ${isSubStepperExist && styles.mainTitleSubStepper}`}
                >
                  {stepperDto.mainTxt}
                </p>
              </Row>
            </div>
          );
        })}
      </Row>
    </div>
  );
};

export default forwardRef(RenewalInnerStepper);
