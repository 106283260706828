import { FC, useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import first from 'lodash/first';
import isEqual from 'lodash/isEqual';

import dayjs, { Dayjs } from 'dayjs';
import { Col, FormInstance, Row } from 'antd';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import PlanYearForm from 'modules/employers/components/PlanYearForm/planYearForm';
import {
  EMPTY_MESSAGE,
  planYearFormFields,
} from 'modules/employers/constants/employerConstants';
import AlertMessage from 'components/Alert/AlertMessage';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import PlanYear from 'model/PlanYear';

import { getPlanYears } from 'modules/employers/slices/employerSlice';
import {
  addPlanYear,
  clearPlanYearCreation,
  updatePlanYear,
} from 'modules/employers/slices/employerPlanYearSlice';
import { dateFormat } from 'constants/dateFormat';
import { convertStringToDate, formatDateWithoutTime } from 'util/dateUtil';

import { formVerificationMsg } from 'constants/commonConstants';

import styles from './addPlanYear.module.less';

type AddPlanYearProps = {
  closeModal: () => void;
  editPlanYear: PlanYear | null;
  form: FormInstance;
};

const AddPlanYear: FC<AddPlanYearProps> = (props: AddPlanYearProps) => {
  const { closeModal, editPlanYear, form } = props;

  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [disableStartDateValue, setDisableStartDateValue] =
    useState<boolean>(false);
  const [disableEndDateValue, setDisableEndDateValue] =
    useState<boolean>(false);
  const [isInvalidData, setIsInvalidData] = useState<boolean>(false);
  const [defaultEndDatePickerValue, setDefaultEndDatePickerValue] =
    useState<Dayjs>(dayjs());
  const [isInputFieldEdited, setIsInputFieldEdited] = useState<boolean>(false);
  const [previousPlanYearEndDate, setPreviousPlanYearEndDate] =
    useState<Dayjs | null>(dayjs());
  const [nextPlanYearStartDate, setNextPlanYearStartDate] =
    useState<Dayjs | null>(dayjs());

  const { brokerId, employerId } = useNavContext();

  const dispatch = useAppDispatch();
  const { planYearsList } = useAppSelector((state) => state.employer.employer);

  useEffect(() => {
    if (brokerId && employerId) {
      dispatch(getPlanYears(employerId));
    }
  }, [dispatch, brokerId, employerId]);

  useEffect(() => {
    if (editPlanYear) {
      setIsInvalidData(false);
      closeAlert();
      const data = {
        id: editPlanYear.id,
        planYear: editPlanYear.name,
        startDate: editPlanYear.startDate,
        endDate: editPlanYear.endDate,
      };
      form.setFieldsValue({
        ...data,
        startDate: convertStringToDate(editPlanYear.startDate),
        endDate: convertStringToDate(editPlanYear.endDate),
      });
      isEqual(first(sortBy(planYearsList, 'startDate')), editPlanYear)
        ? setDisableStartDateValue(false)
        : setDisableStartDateValue(true);
      isEqual(last(sortBy(planYearsList, 'endDate')), editPlanYear)
        ? setDisableEndDateValue(false)
        : setDisableEndDateValue(true);
      const sortedPlanYearList = sortBy(planYearsList, 'startDate');
      const indexOfEditPlanYear = sortedPlanYearList.findIndex(
        (planYear) => planYear.id === editPlanYear.id
      );

      if (indexOfEditPlanYear === 0) {
        setPreviousPlanYearEndDate(null);
        if (planYearsList.length > 1) {
          setNextPlanYearStartDate(
            dayjs(sortedPlanYearList[indexOfEditPlanYear + 1]?.startDate)
          );
        } else {
          setNextPlanYearStartDate(null);
        }
      } else if (indexOfEditPlanYear === planYearsList.length - 1) {
        setNextPlanYearStartDate(null);
        setPreviousPlanYearEndDate(
          dayjs(sortedPlanYearList[indexOfEditPlanYear - 1]?.endDate)
        );
      } else {
        setPreviousPlanYearEndDate(
          dayjs(sortedPlanYearList[indexOfEditPlanYear - 1]?.endDate)
        );
        setNextPlanYearStartDate(
          dayjs(sortedPlanYearList[indexOfEditPlanYear + 1]?.startDate)
        );
      }
      setDefaultEndDatePickerValue(form.getFieldValue('endDate'));
    } else {
      form.resetFields(planYearFormFields.requiredFields);
      closeAlert();
      dispatch(clearPlanYearCreation());
      setIsInvalidData(false);
      setIsSaveClicked(false);
      setPreviousPlanYearEndDate(
        planYearsList.length > 0
          ? dayjs(last(sortBy(planYearsList, 'endDate'))?.endDate)
          : null
      );
      setNextPlanYearStartDate(null);
    }
  }, [dispatch, editPlanYear, form, planYearsList]);

  useEffect(() => {
    if (!editPlanYear && !isEmpty(planYearsList)) {
      setDisableStartDateValue(true);
      setDisableEndDateValue(false);
      form.setFieldsValue({
        startDate: dayjs(
          last(sortBy(planYearsList, 'endDate'))?.endDate,
          dateFormat
        ).add(1, 'day'),
      });
      setDefaultEndDatePickerValue(form.getFieldValue('startDate'));
    }
  }, [editPlanYear, planYearsList, form]);

  const onInputChange = async (changedValues: any, allValues: any) => {
    changedValues && setIsInputFieldEdited(true);
    if (isSaveClicked) {
      const valid = await isValidPlanYear();
      if (valid) {
        form.setFieldsValue({ ...allValues });
        setIsAlertVisible(false);
        setIsInvalidData(false);
      }
    }
  };

  const isValidPlanYear = async () => {
    try {
      await form.validateFields(planYearFormFields.requiredFields);
      return true;
    } catch (errorInfo: any) {
      return errorInfo.errorFields.length === 0;
    }
  };

  const onClickSave = async () => {
    setIsSaveClicked(true);
    const isFormValid = await isValidPlanYear();
    if (isFormValid) {
      const values = form.getFieldsValue();
      const { planYear } = values || {};
      if (planYear) {
        const planYearNameExists = planYearsList.filter(
          (item) =>
            item.name.toLowerCase() === planYear.trim().toLowerCase() &&
            item.id !== editPlanYear?.id
        );

        if (isEmpty(planYearNameExists)) {
          if (editPlanYear && isInputFieldEdited) {
            const updatedPlanYear = {
              id: editPlanYear.id,
              employerId: editPlanYear.employerId,
              name: planYear,
              benefitGroups: editPlanYear.benefitGroups,
              startDate: formatDateWithoutTime(values.startDate),
              endDate: formatDateWithoutTime(values.endDate),
            } as PlanYear;
            dispatch(updatePlanYear(updatedPlanYear));
            setIsInputFieldEdited(false);
            resetForm();
          } else {
            const newPlanYear = {
              employerId: employerId,
              startDate: formatDateWithoutTime(values.startDate),
              endDate: formatDateWithoutTime(values.endDate),
              name: planYear,
            } as PlanYear;
            dispatch(addPlanYear(newPlanYear));
            resetForm();
          }
        } else {
          setIsAlertVisible(true);
          form.setFields([{ name: 'planYear', errors: [EMPTY_MESSAGE] }]);
          // TODO: need to change error message.
          setAlertMessage('A plan year with this name already exists');
        }
      }
    } else {
      setIsAlertVisible(true);
      setAlertMessage(formVerificationMsg);
    }
    setIsInvalidData(!isFormValid);
  };

  const resetForm = () => {
    setIsAlertVisible(false);
    setIsInvalidData(false);
    setIsSaveClicked(false);
    form.resetFields();
    closeModal();
  };

  const closeAlert = () => setIsAlertVisible(false);

  return (
    <>
      {isAlertVisible && (
        <AlertMessage
          type="error"
          message={alertMessage}
          closeAlert={closeAlert}
          className={styles.planYearFormAlertWrapper}
        />
      )}
      <div className={styles.planYearFormWrapper}>
        <PlanYearForm
          form={form}
          onInputChange={onInputChange}
          disableStartDateValue={disableStartDateValue}
          disableEndDateValue={disableEndDateValue}
          isInvalidData={isInvalidData}
          visibleAlert={false}
          defaultEndDatePickerValue={defaultEndDatePickerValue}
          editPlanYear={editPlanYear}
          previousPlanYearEndDate={previousPlanYearEndDate}
          nextPlanYearStartDate={nextPlanYearStartDate}
        />
      </div>
      <Row justify="center" className={styles.btnWrapper}>
        <Col>
          <SubmitButton type="primary" onClick={onClickSave}>
            Save
          </SubmitButton>
        </Col>
      </Row>
    </>
  );
};
export default AddPlanYear;
