import styles from 'modules/carriers/components/CustomCarrier/cusomCarrier.module.less';

const CustomCarrier = () => {
  return (
    <div className={styles.customCarrier}>
      <span className={styles.customCarrierLabel}>CUSTOM CARRIER</span>
    </div>
  );
};

export default CustomCarrier;
