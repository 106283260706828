import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty, orderBy } from 'lodash';
import { Collapse, Row, notification } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import OverviewHeader from 'modules/plans/components/OverviewPage/OverviewHeader/OverviewHeader';
import {
  BenefitCategory,
  ENROLMENTS_INFO_WHEN_AGE_BAND_SELECTED,
} from 'constants/commonConstants';
import { PLAN_NOTIFICATION_KEY } from 'constants/benguideCollaborationConstants';
import { findMedicalPlanById } from 'modules/plans/slices/medicalPlanSlice';
import { findDentalPlanById } from 'modules/plans/slices/dentalPlanSlice';
import { findVisionPlanById } from 'modules/plans/slices/visionPlanSlice';
import { useAppDispatch } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import {
  FourTier,
  RateType,
  NTier,
  EditMdvTypes,
  FourTierOrder,
  NTierOrder,
} from 'modules/plans/constants';
import { isNullOrUndefined } from 'modules/plans/utils';
import { DentalPlan } from 'model/plans/DentalPlan';
import { VisionPlan } from 'model/plans/VisionPlan';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';
import EditEnrollmentsMDVModal from 'modules/plans/components/Enrollments/EditEnrollments/EditEnrollmentsMDVModal';
import MedicalPlan from 'model/plans/MedicalPlan';
import Contribution from 'model/plans/Contribution';

import styles from './enrollmentOverview.module.less';

const { Panel } = Collapse;

type EnrollmentOverviewProps = {
  heading: string;
  plan?: DentalPlan | VisionPlan | MedicalPlan;
  editedPlan?: DentalPlan | VisionPlan | MedicalPlan;
  benefitKind: string;
  fetchPlanWhenUpdated?: any;
  disableEditButton?: boolean;
  viewPlanEnrollmentModal?: boolean;
  setViewPlanEnrollmentModal?: (value: boolean) => void;
};

const EnrollmentOverview = (props: EnrollmentOverviewProps) => {
  const {
    plan,
    benefitKind,
    setViewPlanEnrollmentModal,
    fetchPlanWhenUpdated,
    heading,
    disableEditButton,
    viewPlanEnrollmentModal,
    editedPlan,
  } = props;
  const isTierAgeBanned: boolean = plan?.rates
    ? (Object.values(plan.rates)[0] as any)?.type === RateType.AGE_BAND.value
    : false;

  const params = useParams();
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();

  const getEnrollmentsByGroupsForEdit = (group: string) => {
    const {
      type: tierType,
      fourTierContributions,
      ntierContributions,
    } = plan?.rates?.[group] ?? {
      type: RateType.FOUR_TIER.value,
      fourTierContributions: {},
      ntierContributions: {},
    };

    const F_TIER =
      (fourTierContributions?.contributions &&
        orderBy(
          fourTierContributions?.contributions,
          (val: any) => FourTierOrder[val?.tierName]
        )) ??
      [];

    const N_TIER =
      (ntierContributions?.contributions &&
        orderBy(
          ntierContributions?.contributions,
          (val: any) => NTierOrder[val?.tierName]
        )) ??
      [];

    if (isEmpty(F_TIER) && isEmpty(N_TIER)) {
      return <></>;
    }

    switch (tierType) {
      case RateType.N_TIER.value:
        return N_TIER?.map((contribution: Contribution, index: number) => (
          <InfoLabelSection
            key={index}
            labelText={NTier[contribution?.tierName]?.label}
            value={
              !isNullOrUndefined(contribution?.enrollment)
                ? `${contribution?.enrollment} employees`
                : '-'
            }
          />
        ));

      case RateType.FOUR_TIER.value:
        return F_TIER?.map((contribution: Contribution, index: number) => (
          <InfoLabelSection
            key={index}
            labelText={FourTier[contribution?.tierName]?.label}
            value={
              !isNullOrUndefined(contribution?.enrollment)
                ? `${contribution?.enrollment} employees`
                : '-'
            }
          />
        ));
    }
  };

  const fetchPlanOnNotificationReceived = () => {
    if (params.planId) {
      switch (benefitKind) {
        case BenefitCategory.MEDICAL.value:
          dispatch(findMedicalPlanById(params.planId));
          break;
        case BenefitCategory.DENTAL.value:
          dispatch(findDentalPlanById(params.planId));
          break;
        case BenefitCategory.VISION.value:
          dispatch(findVisionPlanById(params.planId));
          break;
      }
    }
  };

  const editPlan = () => {
    setViewPlanEnrollmentModal && setViewPlanEnrollmentModal(true);
    if (fetchPlanWhenUpdated) {
      fetchPlanOnNotificationReceived();
      notification.close(PLAN_NOTIFICATION_KEY);
    }
  };

  const onClose = () => {
    setViewPlanEnrollmentModal && setViewPlanEnrollmentModal(false);
    if (params.planId && !fetchPlanWhenUpdated) {
      switch (benefitKind) {
        case BenefitCategory.MEDICAL.value:
          dispatch(findMedicalPlanById(params.planId));
          break;
        case BenefitCategory.DENTAL.value:
          dispatch(findDentalPlanById(params.planId));
          break;
        case BenefitCategory.VISION.value:
          dispatch(findVisionPlanById(params.planId));
          break;
      }
    }
  };

  useEffect(() => {
    if (employerId && brokerId && params.planId && !plan) {
      switch (benefitKind) {
        case BenefitCategory.MEDICAL.value:
          dispatch(findMedicalPlanById(params.planId));
          break;
        case BenefitCategory.DENTAL.value:
          dispatch(findDentalPlanById(params.planId));
          break;
        case BenefitCategory.VISION.value:
          dispatch(findVisionPlanById(params.planId));
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerId, brokerId, params.planId, benefitKind, plan]);

  return (
    <>
      <Row>
        <OverviewHeader
          title={heading}
          onEditClick={() => {
            editPlan();
          }}
          disabled={disableEditButton}
        />
      </Row>
      {isTierAgeBanned ? (
        <Row className={styles.infoWrapper}>
          <p className={styles.warningText}>
            {ENROLMENTS_INFO_WHEN_AGE_BAND_SELECTED}
          </p>
        </Row>
      ) : (
        <div className={styles.editEnrollmentContainer}>
          <div className={styles.tableWrapper}>
            <div className={styles.itemWrapper}>
              {plan?.groups?.map((group: string, index: number) => (
                <div className={styles.panelWrapper} key={index}>
                  <Collapse
                    bordered={false}
                    expandIconPosition={'right'}
                    expandIcon={({ isActive }) => (
                      <RightOutlined rotate={isActive ? -90 : 90} />
                    )}
                  >
                    <Panel header={group} key={index} showArrow={true}>
                      {getEnrollmentsByGroupsForEdit(group)}
                    </Panel>
                  </Collapse>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {[
        BenefitCategory.MEDICAL.value,
        BenefitCategory.DENTAL.value,
        BenefitCategory.VISION.value,
      ].includes(benefitKind) &&
        viewPlanEnrollmentModal && (
          <EditEnrollmentsMDVModal
            editedPlan={editedPlan}
            benefitKind={benefitKind}
            onClose={onClose}
            viewPlanEnrollmentModal={viewPlanEnrollmentModal}
            editType={EditMdvTypes.ENROLLMENTS}
            setPlanEnrollmentModal={() => setViewPlanEnrollmentModal}
          />
        )}
    </>
  );
};

export default EnrollmentOverview;
