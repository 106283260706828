import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import unionBy from 'lodash/unionBy';
import * as BenefitConsultationService from 'modules/benefitsConsultation/services/BenefitConsultationService';
import Consultant from 'model/benefitsConsultation/Consultant';
import ConsultationLevel from 'modules/benefitsConsultation/enums/ConsultationLevel';
import ConsultantType from 'modules/benefitsConsultation/enums/ConsultantType';
import PaginationConfig from 'model/PaginationConfig';
import BenefitsConsultant from 'model/benefitsConsultation/BenefitsConsultant';
import MetaData from 'model/MetaData';

export interface EmployerLevelBCState {
  inProgress: boolean;
  error: any;
  requestType: string;
  activeEmployerAdminsList: Consultant[];
  externalEmployerConsultantsList: Consultant[];
  employerBrokerConsultants: {
    content: Array<BenefitsConsultant>;
    metadata: MetaData;
  };
  employerERConsultants: {
    content: Array<BenefitsConsultant>;
    metadata: MetaData;
  };
  brokerConsultantsList: Consultant[];
  pendingInviteConsultantsList: BenefitsConsultant[];
}

const commonStarted = (state: EmployerLevelBCState, action: PayloadAction) => {
  state.inProgress = true;
  state.requestType = action.type;
  state.error = null;
};

const commonFailed = (state: EmployerLevelBCState, action: PayloadAction) => {
  state.inProgress = false;
  state.requestType = action.type;
  state.error = action.payload;
  state.pendingInviteConsultantsList = [];
};

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  activeEmployerAdminsList: [],
  externalEmployerConsultantsList: [],
  pendingInviteConsultantsList: [],
  employerBrokerConsultants: { content: [], metadata: {} },
  employerERConsultants: { content: [], metadata: {} },
  brokerConsultantsList: [],
} as EmployerLevelBCState;

const employerLevelSlice = createSlice({
  name: 'employerLevelBC',
  initialState,
  reducers: {
    getConsultantsSummaryStarted(state, action: PayloadAction) {
      commonStarted(state, action);
    },
    getConsultantsSummarySuccess(state, action: PayloadAction<Consultant[]>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.activeEmployerAdminsList = action.payload.filter(
        (admin) => admin.type === ConsultantType.EMPLOYER
      );
      state.externalEmployerConsultantsList = action.payload.filter(
        (admin) => admin.type === ConsultantType.EMPLOYER_EXTERNAL
      );
    },
    getConsultantsSummaryFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = action.payload;
      state.activeEmployerAdminsList = [];
    },
    addRemoveConsultantsStarted(state, action: PayloadAction) {
      commonStarted(state, action);
    },
    addRemoveConsultantsSuccess(state, action: PayloadAction<any>) {
      const {
        consultantsList,
        externalConsultantsList,
        pendingInviteConsultantsList,
      } = action.payload;

      state.inProgress = false;
      state.requestType = action.type;
      state.error = null;
      state.activeEmployerAdminsList = consultantsList;
      state.externalEmployerConsultantsList = externalConsultantsList;
      state.pendingInviteConsultantsList = pendingInviteConsultantsList;
    },
    addRemoveConsultantsFailed(state, action: PayloadAction<any>) {
      commonFailed(state, action);
    },
    getEmployerBrokerConsultantsListInProgress: (
      state,
      action: PayloadAction
    ) => {
      state.inProgress = true;
      state.error = null;
      state.pendingInviteConsultantsList = [];
      state.requestType = action.type;
    },
    getEmployerBrokerConsultantsListCompleted: (
      state,
      action: PayloadAction<{
        content: Array<BenefitsConsultant>;
        metadata: {};
      }>
    ) => {
      state.inProgress = false;
      state.employerBrokerConsultants = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getEmployerBrokerConsultantsListFailed: (state, action) => {
      state.inProgress = false;
      state.employerBrokerConsultants = { content: [], metadata: {} };
      state.error = action.payload;
      state.requestType = action.type;
    },
    getEmployerERConsultantsListInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    getEmployerERConsultantsListCompleted: (
      state,
      action: PayloadAction<{
        content: Array<BenefitsConsultant>;
        metadata: {};
      }>
    ) => {
      state.inProgress = false;
      state.employerERConsultants = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getEmployerERConsultantsListFailed: (state, action) => {
      state.inProgress = false;
      state.employerERConsultants = { content: [], metadata: {} };
      state.error = action.payload;
      state.requestType = action.type;
    },
    getAllBrokerConsultantsStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
      state.error = null;
      state.brokerConsultantsList = [];
    },
    getAllBrokerConsultantsSuccess(state, action: PayloadAction<Consultant[]>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.brokerConsultantsList = action.payload;
    },
    getAllBrokerConsultantsFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = action.payload;
      state.brokerConsultantsList = [];
    },
    assignUnassignConsultantsStarted(state, action: PayloadAction) {
      commonStarted(state, action);
    },
    assignUnassignConsultantsSuccess(
      state,
      action: PayloadAction<Consultant[]>
    ) {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = null;
      state.brokerConsultantsList = unionBy(
        action.payload,
        state.brokerConsultantsList,
        'id'
      );
    },
    assignUnassignConsultantsFailed(state, action: PayloadAction<any>) {
      commonFailed(state, action);
    },
  },
});

export const {
  getConsultantsSummaryStarted,
  getConsultantsSummarySuccess,
  getConsultantsSummaryFailed,
  addRemoveConsultantsStarted,
  addRemoveConsultantsSuccess,
  addRemoveConsultantsFailed,
  getEmployerBrokerConsultantsListInProgress,
  getEmployerBrokerConsultantsListCompleted,
  getEmployerBrokerConsultantsListFailed,
  getEmployerERConsultantsListInProgress,
  getEmployerERConsultantsListCompleted,
  getEmployerERConsultantsListFailed,
  getAllBrokerConsultantsStarted,
  getAllBrokerConsultantsSuccess,
  getAllBrokerConsultantsFailed,
  assignUnassignConsultantsStarted,
  assignUnassignConsultantsSuccess,
  assignUnassignConsultantsFailed,
} = employerLevelSlice.actions;

export default employerLevelSlice.reducer;

export const getERConsultantsSummary = (
  organizationId: string,
  employerId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getConsultantsSummaryStarted());
    try {
      const response = await BenefitConsultationService.getConsultantsSummary(
        ConsultationLevel.ER,
        organizationId,
        employerId
      );
      dispatch(getConsultantsSummarySuccess(response.data));
    } catch (error) {
      dispatch(getConsultantsSummaryFailed(JSON.parse(JSON.stringify(error))));
    }
  };
};

export const addRemoveERConsultants = (
  addedConsulants: Consultant[],
  consultantsList: Consultant[],
  newExternalConsultants: Consultant[],
  externalConsultantsList: Consultant[]
) => {
  return async (dispatch: Dispatch) => {
    dispatch(addRemoveConsultantsStarted());
    try {
      const pendingInviteConsultantsList =
        await BenefitConsultationService.addRemoveConsultants(
          ConsultationLevel.ER,
          addedConsulants.concat(newExternalConsultants)
        );
      const data = {
        consultantsList,
        externalConsultantsList,
        pendingInviteConsultantsList: pendingInviteConsultantsList.data,
      };
      dispatch(addRemoveConsultantsSuccess(data));
    } catch (error) {
      dispatch(addRemoveConsultantsFailed(JSON.parse(JSON.stringify(error))));
    }
  };
};

// getEmployerBroker
export const getEmployerConsultantsList = (
  { page, size, sort, query }: PaginationConfig,
  organizationId: string,
  employerId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getEmployerBrokerConsultantsListInProgress());
    try {
      const response = await BenefitConsultationService.getConsultantsList(
        page,
        size,
        sort,
        query,
        organizationId,
        ConsultationLevel.ER_BROKERS,
        employerId
      );
      const data = response.data;
      dispatch(getEmployerBrokerConsultantsListCompleted(data));
    } catch (error) {
      dispatch(
        getEmployerBrokerConsultantsListFailed(
          JSON.parse(JSON.stringify(error))
        )
      );
    }
  };
};

export const getEmployerERConsultantsList = (
  { page, size, sort, query }: PaginationConfig,
  organizationId: string,
  employerId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getEmployerERConsultantsListInProgress());
    try {
      const response = await BenefitConsultationService.getConsultantsList(
        page,
        size,
        sort,
        query,
        organizationId,
        ConsultationLevel.ER,
        employerId
      );
      const data = response.data;
      dispatch(getEmployerERConsultantsListCompleted(data));
    } catch (error) {
      dispatch(
        getEmployerERConsultantsListFailed(JSON.parse(JSON.stringify(error)))
      );
    }
  };
};

export const manageERBrokerConsultants = (
  consultantsList: Consultant[],
  employerId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(assignUnassignConsultantsStarted());
    try {
      await BenefitConsultationService.assignUnassignConsultants(
        ConsultationLevel.ER_BROKERS,
        consultantsList,
        employerId
      );

      dispatch(assignUnassignConsultantsSuccess(consultantsList));
    } catch (error) {
      dispatch(
        assignUnassignConsultantsFailed(JSON.parse(JSON.stringify(error)))
      );
    }
  };
};

export const getAllBrokerConsultants = (
  organizationId: string,
  employerId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getAllBrokerConsultantsStarted());
    try {
      const response = await BenefitConsultationService.getConsultantsSummary(
        ConsultationLevel.ER_BROKERS,
        organizationId,
        employerId
      );
      dispatch(getAllBrokerConsultantsSuccess(response.data));
    } catch (error) {
      dispatch(
        getAllBrokerConsultantsFailed(JSON.parse(JSON.stringify(error)))
      );
    }
  };
};
