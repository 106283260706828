import React from 'react';
import { ReactComponent as BriefCase } from 'assets/images/briefCase-icon.svg';
import styles from 'modules/brokers/pages/RenewalInformation/renewalInfo.module.less';

const NoRenewalData = () => {
  return (
    <div>
      <div className={styles.icon}>
        <BriefCase />
      </div>
      <p className={styles.contentBold}>
        You have no renewals within the next six months
      </p>
      <p className={styles.contentNotBold}>
        Employers will show up here when they have upcoming renewals
      </p>
    </div>
  );
};

export default NoRenewalData;
