import { FC, ReactElement } from 'react';
import { Button, Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { ReactComponent as VectorDown } from 'assets/images/vector-down.svg';

import styles from './selectDropdown.module.less';

type SelectDropdownProps = {
  overlay: ReactElement;
  className?: string;
  dataCy?: string;
  showSelectLabel?: boolean;
  label?: ReactElement | boolean;
};

const SelectDropdown: FC<SelectDropdownProps> = ({
  overlay,
  className = styles.dropdownButton,
  dataCy,
  showSelectLabel = true,
  label = false,
}) => {
  return (
    <Dropdown
      overlay={overlay}
      trigger={['click']}
      overlayClassName="actionDropdownMenu"
      getPopupContainer={(triggerNode) =>
        triggerNode.parentElement ? triggerNode.parentElement : triggerNode
      }
    >
      {showSelectLabel ? (
        <Button
          type="link"
          className={className}
          onClick={(e) => e.preventDefault()}
          data-cy={dataCy}
        >
          {label ? (
            label
          ) : (
            <div>
              Select <CaretDownOutlined className={styles.caretDownOutlined} />
            </div>
          )}
        </Button>
      ) : (
        <Button
          type="link"
          className={className}
          onClick={(e) => e.preventDefault()}
          data-cy={dataCy}
        >
          <span>
            <VectorDown />
            <span className={styles.moreActions}>More Actions</span>
          </span>
        </Button>
      )}
    </Dropdown>
  );
};

export default SelectDropdown;
