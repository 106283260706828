import { useParams } from 'react-router-dom';
import SiderBarLogo from 'layout/components/SiderBarLogo/SiderBarLogo';

import { loginTypes, navContexts } from 'constants/authConstants';
import styles from './siderLogo.module.less';

type LogoProps = {
  loginType?: string;
  context: string | null;
  brokerName?: string | null;
  logoUrl?: string | null;
  erLogoUrl?: string | null;
  carrierLogoUrl?: string | null;
};

const SiderLogo = (props: LogoProps) => {
  const { loginType, context, logoUrl, brokerName, erLogoUrl, carrierLogoUrl } =
    props;
  const params = useParams();

  if (loginType === loginTypes.platform && context === navContexts.broker) {
    return (
      <>
        <div className={styles.siderTitle}>BROKER</div>
        <SiderBarLogo logoUrl={logoUrl} />
      </>
    );
  } else if (loginType === loginTypes.platform && context === navContexts.er) {
    return (
      <>
        <div className={styles.siderTitle}>{`${
          brokerName ? brokerName : 'BROKER'
        } > EMPLOYER`}</div>
        <SiderBarLogo logoUrl={erLogoUrl} />
      </>
    );
  } else if (
    loginType === loginTypes.platform &&
    context === navContexts.carrier
  ) {
    if (params && params.brokerId && !params.employerId) {
      return (
        <>
          <div className={styles.siderTitle}>BROKER</div>
          <SiderBarLogo logoUrl={logoUrl} />
          <div className={styles.siderTitle}>CARRIER</div>
          <SiderBarLogo logoUrl={carrierLogoUrl} />
        </>
      );
    } else if (params && params.brokerId && params.employerId) {
      return (
        <>
          <div className={styles.siderTitle}>{`${
            brokerName ? brokerName : 'BROKER'
          } > EMPLOYER`}</div>
          <SiderBarLogo logoUrl={erLogoUrl} />
          <div className={styles.siderTitle}>CARRIER</div>
          <SiderBarLogo logoUrl={carrierLogoUrl} />
        </>
      );
    } else {
      return (
        <>
          <div className={styles.siderTitle}>CARRIER</div>
          <SiderBarLogo logoUrl={carrierLogoUrl} />
        </>
      );
    }
  } else if (
    (loginType === loginTypes.bokerAdmin || loginType === loginTypes.erAdmin) &&
    context === navContexts.er
  ) {
    return (
      <>
        <div className={styles.siderTitle}>EMPLOYER</div>
        <SiderBarLogo logoUrl={erLogoUrl} />
      </>
    );
  } else if (
    (loginType === loginTypes.bokerAdmin || loginType === loginTypes.erAdmin) &&
    context === navContexts.carrier
  ) {
    if (params && params.brokerId && !params.employerId) {
      return (
        <>
          <div className={styles.siderTitle}>CARRIER</div>
          <SiderBarLogo logoUrl={carrierLogoUrl} />
        </>
      );
    } else if (params && params.brokerId && params.employerId) {
      return (
        <>
          <div className={styles.siderTitle}>EMPLOYER</div>
          <SiderBarLogo logoUrl={erLogoUrl} />
          <div className={styles.siderTitle}>CARRIER</div>
          <SiderBarLogo logoUrl={carrierLogoUrl} />
        </>
      );
    }
  }
  return null;
};

export default SiderLogo;
