import { FC, ReactNode } from 'react';
import { Form } from 'antd';
import { FormProps } from 'antd/lib/form/Form';

import styles from './inputForm.module.less';

interface Props extends FormProps {
  children: ReactNode;
  size?: 'middle' | 'small';
}

const InputForm: FC<Props> = (props: Props) => {
  const { children, size = 'middle', className = '', ...rest } = props;
  return (
    <div className={styles.formContainer}>
      <Form
        className={`${size === 'small' ? styles.small : ''} ${className}`}
        {...rest}
      >
        {children}
      </Form>
    </div>
  );
};

export default InputForm;
