import { FC, useState, useRef, useEffect } from 'react';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import Option from 'model/Option';
import AddLifePlan from 'modules/plans/life/components/AddLifePlan/AddLifePlan';
import { exitWithoutSavingMsg } from 'constants/commonConstants';
import { clearDocumentsAttached } from 'modules/plans/slices/lifePlanSlice';
import { useAppDispatch } from 'hooks/redux';
import { IAddProposalToPlanYearState } from 'modules/renewals/types/renewalsTypes';

type Props = {
  visible: boolean;
  employerName?: string | null;
  planYearId?: string;
  lifePlanType: Option;
  onClose: Function;
  onSaveClose: Function;
  isSaveDisabled?: boolean;
  dbgPlanYear?: string;
  isDBGPlan?: boolean;
  type?: keyof IAddProposalToPlanYearState['plans'] | undefined;
  isRenewalProcessStarted: boolean;
};

const AddLifePlanModal: FC<Props> = (props) => {
  const {
    visible,
    employerName = '',
    lifePlanType,
    onClose,
    onSaveClose,
    isSaveDisabled = false,
    dbgPlanYear,
    isDBGPlan,
    type,
    isRenewalProcessStarted,
  } = props;

  const [visibleConfirmation, setVisibleConfirmation] = useState(false);
  const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);

  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      setIsCancelButtonClicked(false);
    }
  }, [visible]);

  const addLifePlanRef = useRef<any>();

  const onConfirmClose = () => {
    addLifePlanRef.current.reset();
    setIsCancelButtonClicked(true);
    onClose(false);
    setVisibleConfirmation(false);
    dispatch(clearDocumentsAttached());
  };

  return (
    <>
      <FullScreenModal
        visible={visible}
        onCancel={() => setVisibleConfirmation(true)}
        footer={false}
        title={`${employerName} - Add ${lifePlanType.label} Plan`}
      >
        <AddLifePlan
          ref={addLifePlanRef}
          onReset={() => setVisibleConfirmation(true)}
          isCancelButtonClickConfirm={isCancelButtonClicked}
          lifePlanType={lifePlanType.value}
          onSaveClose={onSaveClose}
          closePlanAddModal={onConfirmClose}
          isSaveDisabled={isSaveDisabled}
          dbgPlanYear={dbgPlanYear}
          isReviewType={type}
          isEdit={false}
          isDBGPlan={isDBGPlan}
          isModalVisible={isModalVisible}
          isRenewalProcessStarted={isRenewalProcessStarted}
        />
      </FullScreenModal>
      <ConfirmationDialog
        title="Are you sure you want to close?"
        confirmText="Yes - Close and do not save plan"
        cancelText="Cancel"
        closeModal={() => setVisibleConfirmation(false)}
        onConfirm={onConfirmClose}
        visible={visibleConfirmation}
      >
        <p>{exitWithoutSavingMsg}</p>
      </ConfirmationDialog>
    </>
  );
};

export default AddLifePlanModal;
