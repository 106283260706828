export const basicFormFields = {
  requiredFields: [
    'name',
    'addressLine1',
    'city',
    'zipCode',
    'locationName',
    'state',
    'logoUrl',
    'emailDomains',
    'employerLimit',
    'employerLimitCount',
  ],
  formFields: [
    'name',
    'addressLine1',
    'addressLine2',
    'state',
    'name',
    'city',
    'zipCode',
    'locationName',
    'emailDomains',
    'employerLimit',
    'employerLimitCount',
  ],
};

export const addBrokerLocationFormFields = {
  requiredFields: ['name', 'addressLine1', 'city', 'zipCode', 'state'],
  formFields: [
    'name',
    'addressLine1',
    'addressLine2',
    'state',
    'city',
    'zipCode',
  ],
};

export const addBrokerAdminFormFields = {
  requiredFields: [
    'email',
    'firstName',
    'lastName',
    'phone',
    'location',
    'individualSubType',
  ],
  formFields: [
    'email',
    'firstName',
    'lastName',
    'phone',
    'title',
    'photoAvatar',
    'location',
    'individualSubType',
  ],
};

export const deleteBrokerLocationFields = {
  requiredFields: ['employerLocationId', 'adminLocationId'],
  formFields: ['employerLocationId', 'adminLocationId'],
};

// Form validations
export const EMAIL_MANDATORY_MSG = 'Please add your email';
export const ADMIN_FIRST_NAME_MANDATORY_MSG = 'Please add admin first name';
export const ADMIN_LAST_NAME_MANDATORY_MSG = 'Please add admin last name';
export const ADMIN_PHONE_NO_MANDATORY_MSG = 'Please add admin phone';
export const ADDRESS_1_MANDATORY_MSG = 'Please input your address line 1';
export const CITY_MANDATORY_MSG = 'Please input your city';
export const STATE_MANDATORY_MSG = 'Please input your state';
export const ZIP_MANDATORY_MSG = 'Please input your zip';
export const LOCATION_MANDATORY_MSG = 'Please add your location';
export const EMPTY_MESSAGE = '';

// Error Codes
export const EXISTING_LOGIN_FOUND = 'existing.login.found';
export const EXISTING_BROKER_FOUND = 'existing.organization.found';
export const EXISTING_EMAIL_DOMAIN_FOUND = 'existing.emaildomain.found';
export const EXISTING_EMPLOYER_FOUND = 'employer.already.exists';
export const EXISTING_LOCATION_FOUND = 'location.name.already.exists';
export const RESTRICTED_EMAIL_DOMAIN_FOUND = 'restricted.emaildomain.found';

// Broker admin error codes
export const BROKER_ADMIN_REMOVE_ERROR =
  'There must be at least one broker admin for this broker.';
export const PRIMARY_CONTACT_ERROR =
  'There must be at least one primary contact.';
export const PRIMARY_CONTACT_REMOVE_ERROR =
  'There must be a primary contact for this broker. Please create or assign another primary contact before removing this one.';
export const BROKER_ADMIN_EMPTY_ERROR =
  'There must be at least one broker admin associated with this broker. ';

// Broker add employer limit add/edit
export const EMPLOYER_LIMIT_CONSTANTS = {
  UNLIMITED: 'UNLIMITED',
  LIMITED: 'LIMITED',
};

export const EMPLOYER_UNLIMITED_PASSING_VALUE = null;

export const EMPLOYER_LIMIT_DROPDOWN = [
  { label: 'Unlimited', value: EMPLOYER_LIMIT_CONSTANTS.UNLIMITED },
  { label: 'Limited', value: EMPLOYER_LIMIT_CONSTANTS.LIMITED },
];

export const EMPLOYER_EMAILDOMAIN_ERROR_FOUND =
  'Company Email Domains should be unique between employers or within the same employer.';
export const BROKER_EMAILDOMAIN_ERROR_FOUND =
  'Company Email Domains should be unique between broker or within the same broker.';
export const RESTRICTED_EMAIDOMAIN_ERROR_FOUND =
  'Public email domains cannot be used as company email domains.';
