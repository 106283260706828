import { FC, useEffect, useState } from 'react';
import { Form, Modal, Switch } from 'antd';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { useEnableDLPFeatureMutation } from 'api/featureControl';
import { usePrevious } from 'hooks/usePrevious';
import AlertMessage from 'components/Alert/AlertMessage';
import { FEATURE_KEYS } from 'constants/commonConstants';
import {
  DLP_FEATURE_ENABLE_TOPIC,
  DLP_FEATURE_ENABLE_SUB_TOPIC,
  DLP_FEATURE_ENABLE_DESCRIPTION,
} from 'constants/dataLossPreventionConstants';

import styles from './dlpSetupModal.module.less';

type DLPSetupModalProps = {
  visible: boolean;
  onCancel: () => void;
  brokerName: string;
  isDLPEnable: boolean;
  onSave: () => void;
  brokerId: string;
};

const DLPSetupModal: FC<DLPSetupModalProps> = ({
  visible,
  onCancel,
  brokerName,
  isDLPEnable,
  onSave,
  brokerId,
}) => {
  const [form] = Form.useForm();
  const [provisionIssuesLog, { isSuccess, isLoading, isError }] =
    useEnableDLPFeatureMutation();

  const [savingError, setSavingError] = useState<boolean>(false);
  const [dlpStatus, setDlpStatus] = useState<boolean>(false);
  const [initialDlpStatus, setInitialDlpStatus] = useState<boolean>(false);

  useEffect(() => {
    setDlpStatus(isDLPEnable);
    setInitialDlpStatus(isDLPEnable);
  }, [isDLPEnable]);

  const isLoadingPrevious = usePrevious(isLoading);

  useEffect(() => {
    if (isLoadingPrevious && !isLoading && isSuccess) {
      onSave();
    }
  }, [isLoadingPrevious, isSuccess, isLoading, onSave]);

  useEffect(() => {
    if (isLoadingPrevious && !isLoading && isError) {
      setSavingError(true);
    }
  }, [isLoadingPrevious, isError, isLoading, onSave]);

  const save = () => {
    form.validateFields().then(() => {
      provisionIssuesLog({
        organizationId: brokerId,
        key: FEATURE_KEYS.ISSUES_LOG,
        dlpEnable: dlpStatus,
      });
    });
  };

  const handleCancel = () => {
    setDlpStatus(initialDlpStatus);
    onCancel();
  };

  return (
    <Modal
      centered
      visible={visible}
      width={600}
      closeIcon={<></>}
      footer={false}
      className={styles.dlpSetupModal}
      destroyOnClose={true}
    >
      <div className={styles.dlpSetupModalWrapper}>
        <div className={styles.title}>{DLP_FEATURE_ENABLE_TOPIC}</div>
        <div className={styles.subTitle}>
          {DLP_FEATURE_ENABLE_SUB_TOPIC}
          {brokerName}
        </div>

        {savingError && (
          <div className={styles.errorMessageWrapper}>
            <AlertMessage
              className={styles.errorMessage}
              type="error"
              message="Failed to save DLP status. Please try again."
              closeAlert={() => {
                setSavingError(false);
              }}
            />
          </div>
        )}
        <div className={styles.description}>
          {DLP_FEATURE_ENABLE_DESCRIPTION}
        </div>
        <div className={styles.inputWrapper}>
          <Form form={form} layout="vertical">
            <Form.Item
              className={styles.dlpField}
              label="Data Loss Prevention (DLP)"
              name="dlpStatus"
            >
              <div>
                <span
                  className={
                    dlpStatus ? styles.statusText : styles.disabledText
                  }
                >
                  {dlpStatus ? 'Enabled' : 'Disabled'}
                </span>
                <Switch
                  checked={dlpStatus}
                  onChange={(dlpStatus: boolean) => setDlpStatus(dlpStatus)}
                  className={`${styles.dlpSwitch} ${
                    dlpStatus ? styles.slider : ''
                  }`}
                />
              </div>
            </Form.Item>
            <hr className={styles.horizontalLine}></hr>
          </Form>
        </div>

        <div className={styles.footer}>
          <PageActionButton
            className={styles.saveBtn}
            type="primary"
            onClick={save}
            loading={isLoading}
          >
            Save
          </PageActionButton>
          <div onClick={handleCancel} className={styles.cancelLink}>
            Cancel
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DLPSetupModal;
