import { useEffect, useState } from 'react';
import { useLocation, Navigate, useParams, useNavigate } from 'react-router';
import { StompSessionProvider } from 'react-stomp-hooks';
import { Spin } from 'antd';

import DocumentNotification from 'components/DocumentNotification/DocumentNotification';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { ping } from 'modules/auth/slices/authSlice';
import { LOGIN_PATH, NOT_FOUND } from 'modules/auth/routes';
import { loginTypes } from 'constants/authConstants';
import { wsBaseApi } from 'util/apiUtil';
import { useLazyGetNavigationsQuery } from 'api/navigationSlice';
import { useIsAuthorizedUserRole } from 'hooks/useIsAuthorizedUserRole';
import { featuresKeys } from 'util/featureKeysUtil';

type AuthRoutePropType = {
  children: JSX.Element | null;
  allowedLoginTypes?: string[];
  validator?: string;
  path: string;
};

const PrivateRoute = ({
  children,
  allowedLoginTypes,
  validator,
  path,
}: AuthRoutePropType) => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [allowed, setAllowed] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { auth } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const match: any = location.state;
  const { appBootupInfo, pingInprogress, pingError } = auth;
  const { brokerId, employerId } = useParams();

  const [
    getNavigationData,
    { data: navigationData, isLoading, isFetching, isUninitialized },
  ] = useLazyGetNavigationsQuery();

  const { features = [] } = navigationData || {};
  const isErAdmin = useIsAuthorizedUserRole([loginTypes.erAdmin]);
  const availableFeaturesKeys = featuresKeys(features, isErAdmin);
  const invalidRoute = validator && !availableFeaturesKeys.includes(validator);

  const navigate = useNavigate();

  useEffect(() => {
    getNavigationData({
      brokerId: brokerId || null,
      employerId: employerId || null,
    });
    // eslint-disable-next-line
  }, [brokerId, employerId, location?.pathname]);

  useEffect(() => {
    dispatch(ping());
  }, [dispatch]);

  useEffect(() => {
    // If the last character of the url is '/'
    if (location.pathname.match('/.*/$')) {
      return navigate(location.pathname.replace(/\/+$/, ''), { replace: true });
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (pingInprogress && !appBootupInfo) {
      setLoading(true);
    }
    if (!pingInprogress && appBootupInfo) {
      setLoading(false);
      setAuthenticated(true);
    }

    if (pingError && !pingInprogress && !appBootupInfo) {
      setLoading(false);
      setAuthenticated(false);
    }

    if (appBootupInfo && allowedLoginTypes) {
      const { type, organizationId, primaryEmployerId } = appBootupInfo;

      // platform admin
      if (!organizationId) {
        setAllowed(true);
        return;
      }

      // broker level user, without the brokerId in url
      if (!brokerId) {
        navigate(`/brokers/${organizationId}`);
        return;
      }

      if (allowedLoginTypes.includes(type)) {
        setAllowed(organizationId === brokerId);
      } else {
        setAllowed(false);
      }

      if (type === loginTypes.erAdmin) {
        setAllowed(primaryEmployerId === employerId);
      }
    }
  }, [
    appBootupInfo,
    pingInprogress,
    allowedLoginTypes,
    pingError,
    brokerId,
    navigate,
    employerId,
  ]);

  if (loading || isLoading || isUninitialized || isFetching) {
    return <Spin />;
  }

  if (!authenticated) {
    return <Navigate to={LOGIN_PATH} state={{ from: location }} replace />;
  }

  if (
    invalidRoute &&
    !(match?.bypassInvalidRoute !== undefined && match?.bypassInvalidRoute)
  ) {
    return <Navigate to={NOT_FOUND} state={{ from: location }} replace />;
  }

  if (authenticated) {
    if (allowed) {
      return (
        <StompSessionProvider
          url={`${wsBaseApi}/websocket-common`}
          onWebSocketError={(e) => console.error(e)}
          reconnectDelay={500}
        >
          {children}
          {
            <DocumentNotification
              individualId={auth?.appBootupInfo?.individualId}
            />
          }
        </StompSessionProvider>
      );
    } else {
      // TODO Proper access forhibiden page
      return (
        <div>
          <h1>403</h1>
        </div>
      );
    }
  }

  return <Navigate to={LOGIN_PATH} state={{ from: location }} replace />;
};

export default PrivateRoute;
