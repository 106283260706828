import React, { FC, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Form, Input, Row, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import InputForm from 'components/InputForm/InputForm';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import InputPhoneNumber from 'components/InputPhoneNumber/InputPhoneNumber';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import { showErrorNotification } from 'components/Notification/Notification';
import {
  useCreateCarrierContactMutation,
  useLazyGetCarrierContactsByBrokerIdQuery,
  useLazyGetCarriersByBrokerIdQuery,
  useLazyGetEmployersByBrokerIdQuery,
  useUpdateCarrierContactMutation,
} from 'modules/carriers/slices/carrierContactSlice';
import { CarrierContactCreateTypes } from 'model/CarrierContact';
import {
  isValidCarrierContactNumber,
  isValidEmailFormat,
} from 'util/commonUtil';
import styles from './CarrierContactCreate.module.less';

type Props = {
  isEdit?: boolean;
  carrierContactId?: string;
  isModalOpen: boolean;
  carrierContactRef?: any;
  onCancel: () => void;
  closeForm: () => void;
  fetchData: () => void;
  handleCloseDetailModal: () => void;
};
type OptionProps = {
  label: string;
  value: any;
};

const CarrierContactCreate: FC<Props> = (props: Props) => {
  const {
    isModalOpen,
    isEdit,
    carrierContactId,
    carrierContactRef,
    closeForm,
    onCancel,
    fetchData,
    handleCloseDetailModal,
  } = props;

  const { brokerId } = useParams();

  const [form] = Form.useForm();

  const empSelectRef = useRef<any>(null);
  const [editEmployerIds, setEditEmployerIds] = useState<string[]>([]);
  const [nonMatchingEmployerIds, setNonMatchingEmployerIds] = useState<
    string[]
  >([]);

  const [
    getEmployersByBrokerId,
    { data: employerData, isFetching: isEmployerDataFetching },
  ] = useLazyGetEmployersByBrokerIdQuery();

  const [
    getCarriersByBrokerId,
    { data: carrierData, isFetching: isCarrierDataFetching },
  ] = useLazyGetCarriersByBrokerIdQuery();

  const [getCarrierContactDetails, { data: carrierDetailData }] =
    useLazyGetCarrierContactsByBrokerIdQuery();

  const [createCarrierContact, { isLoading: isCarrierContactCreateLoading }] =
    useCreateCarrierContactMutation();

  const [updateCarrierContact, { isLoading: isCarrierContactUpdateLoading }] =
    useUpdateCarrierContactMutation();
  useEffect(() => {
    if (isModalOpen) {
      getEmployersByBrokerId({ brokerId: brokerId!, individualId: null });
      getCarriersByBrokerId({ organizationId: brokerId! });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  useEffect(() => {
    if (carrierData && carrierDetailData) {
      form.setFieldsValue({
        associatedCarrierId: carrierDetailData?.associatedCarrierId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCarrierDataFetching, carrierDetailData]);

  useEffect(() => {
    if (employerData && carrierDetailData) {
      const employerIdsInCarrierContact = carrierDetailData?.employersIds;

      const matchingEmployers = employerData.filter((employer: any) => {
        return employerIdsInCarrierContact.includes(employer.id);
      });

      const matchingEmployerIds = matchingEmployers.map(
        (employer: any) => employer.id
      );

      const nonMatchingEmployerIds = employerIdsInCarrierContact.filter(
        (employerId: string) => {
          return !employerData.some(
            (employer: any) => employer.id === employerId
          );
        }
      );
      setNonMatchingEmployerIds(nonMatchingEmployerIds);

      form.setFieldsValue({
        associatedEmployers: matchingEmployerIds,
      });
      setEditEmployerIds(matchingEmployerIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmployerDataFetching, carrierDetailData]);

  useEffect(() => {
    if (isModalOpen && isEdit && carrierContactId) {
      getCarrierContactDetails({
        brokerId: brokerId!,
        carrierContactId,
        isEditMode: true,
      })
        .unwrap()
        .then((data: any) => {
          form.setFieldsValue({
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            title: data.title,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, carrierContactId, isModalOpen]);

  const employerOptions: OptionProps[] =
    employerData?.map((employer: any) => ({
      label: employer.name,
      value: employer.id,
    })) || [];

  const carrierOptions: OptionProps[] =
    carrierData?.map((carrier: any) => ({
      label: carrier.name,
      value: carrier.id,
    })) || [];

  const onClickSave = () => {
    form.validateFields().then(async (values) => {
      try {
        const data: CarrierContactCreateTypes = {
          email: values.email.trim().toLowerCase(),
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          phone: values.phone,
          title: values.title === undefined ? '' : values.title.trim(),
          associatedCarrierId: values.associatedCarrierId,
          employersIds: values.associatedEmployers,
          organizationId: brokerId!,
        };
        if (isEdit) {
          await updateCarrierContact({
            ...data,
            id: carrierDetailData?.id!,
            employersIds: [
              ...values.associatedEmployers,
              ...nonMatchingEmployerIds,
            ],
          }).unwrap();
          closeForm();
          form.resetFields();
          setEditEmployerIds([]);
          empSelectRef.current?.reset();
          handleCloseDetailModal();
          fetchData();
        } else {
          await createCarrierContact(data).unwrap();
          closeForm();
          form.resetFields();
          empSelectRef.current?.reset();
          carrierContactRef?.current?.fetchData();
        }
      } catch (error: any) {
        showErrorNotification({
          icon: <CloseOutlined className={styles.notificationErrorIcon} />,
          message: 'Something went wrong!',
          description: error?.data?.code,
        });
      }
    });
  };

  return (
    <div className={styles.carrierContactCreate}>
      <>
        <InputForm form={form}>
          <Row gutter={60}>
            <Col xs={24}>
              <Form.Item
                name="email"
                label="Email"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: 'Please enter an email.',
                    validateTrigger: ['onSubmit'],
                  },
                  {
                    validator: (_, value) => {
                      if (value && !isValidEmailFormat(value.trim())) {
                        return Promise.reject(
                          new Error('Please enter a valid email')
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                    validateTrigger: ['onSubmit'],
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={60}>
            <Col xs={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a first name.',
                    validateTrigger: ['onSubmit'],
                  },
                  {
                    whitespace: true,
                    message: 'Please enter a first name.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="lastName"
                label="Last Name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a last name.',
                    validateTrigger: ['onSubmit'],
                  },
                  {
                    whitespace: true,
                    message: 'Please enter a last name.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={60}>
            <Col xs={12}>
              <Form.Item
                name="phone"
                label="Phone"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    validator: (rule, value) => {
                      if (value && !isValidCarrierContactNumber(value)) {
                        return Promise.reject(new Error('Invalid format'));
                      } else {
                        return Promise.resolve();
                      }
                    },
                    validateTrigger: ['onSubmit'],
                  },
                ]}
              >
                <InputPhoneNumber />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="title"
                label="Title"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={60}>
            <Col xs={12}>
              <Form.Item
                name="associatedCarrierId"
                label="Associated Carrier"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: 'Please select an associated  carrier. ',
                    validateTrigger: ['onSubmit'],
                  },
                ]}
              >
                <Select
                  allowClear
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  showSearch
                  loading={isCarrierDataFetching}
                >
                  {carrierOptions
                    ?.sort((a, b) => a?.label?.localeCompare(b?.label))
                    ?.map((carrier: any) => (
                      <Select.Option key={carrier.value} value={carrier.value}>
                        {carrier.label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="associatedEmployers"
                label="Associated Employers"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: 'Please select associated employers.',
                  },
                ]}
              >
                <MultiSelect
                  ref={empSelectRef}
                  selectName="associatedEmployers"
                  listHeight={150}
                  defaultOptions={isEdit ? editEmployerIds : []}
                  resetOptions={isEdit ? editEmployerIds : []}
                  handleOkayCallback={(value, selectName) => {
                    form.setFieldsValue({ [selectName]: value });
                  }}
                  handleResetCallback={(value, selectName) => {
                    form.setFieldsValue({ [selectName]: value });
                  }}
                  options={employerOptions}
                  searchPlaceholder="Search Employers"
                  isLoading={isEmployerDataFetching}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" className={styles.margins}>
            <Col>
              <PageActionButton
                onClick={onClickSave}
                type="primary"
                className={styles.confirmButton}
                dataCy="saveCarrierBtn"
                loading={
                  isCarrierContactCreateLoading || isCarrierContactUpdateLoading
                }
              >
                Save Carrier Contact
              </PageActionButton>
            </Col>
          </Row>
          <Row justify="center" className={styles.margins}>
            <Col>
              <CancelButton
                htmlType="button"
                onClick={onCancel}
                className={styles.cancelBtnStyle}
              >
                Cancel
              </CancelButton>
            </Col>
          </Row>
        </InputForm>
      </>
    </div>
  );
};

export default CarrierContactCreate;
