import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import { navContexts } from 'constants/authConstants';
import { DataColumn } from 'components/DataTable/DataColumn';
import ToolList from 'components/ToolList/ToolList';
import {
  useGetBrokerFeatureStatusQuery,
  useGetEmployersQuery,
  useUpdateEmailUserContactMutation,
  useUpdateEmployerFeatureStatusMutation,
  useUpdateUserLimitMutation,
} from 'api/featureControl';
import TablePagination from 'model/TablePagination';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import {
  EMPLOYER_LIMIT_EXCEEDED,
  EMPLOYER_SEATS,
  INCREASE_LIMIT,
  PURCHASED_EMPLOYER,
} from 'constants/aiAsstantConfigurationConstants';
import ContactSales from 'modules/tools/components/AIAssistantConfiguration/ContactSalesModal/ContactSales';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import EmployerFeatureToggle from 'components/EmployerFeatureToggle/EmployerFeatureToggle';
import { FEATURE_KEYS } from 'constants/commonConstants';
import useValidateSeats from 'modules/tools/components/AIAssistantConfiguration/ValidateSeats/ValidateSeats';
import AssistantStatusTag from 'modules/tools/components/AIAssistantConfiguration/AssistantPurchasedTag/AssistantStatusTag';
import { EmailType } from 'modules/plans/enums/CommonTypes';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import styles from './featureList.module.less';

type EmployerAIListProps = {
  isPlatformAdmin?: boolean;
};

const EmployerAIList: FC<EmployerAIListProps> = (
  props: EmployerAIListProps
) => {
  const { isPlatformAdmin } = props;
  const { brokerId } = useParams();

  const locationModalRef = useRef<any>();

  const { data: aiAssistantFeature, refetch: refetchBrokerFeature } =
    useGetBrokerFeatureStatusQuery({
      key: 'AI_ASSISTANT',
      organizationId: brokerId as string,
    });

  const [updateStatus] = useUpdateEmployerFeatureStatusMutation({});
  const [updateEmployerLimit] = useUpdateUserLimitMutation({});
  const [updateSendEmail] = useUpdateEmailUserContactMutation({});

  const [searchText, setSearchText] = useState<string>('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState('name');
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [employerLimit, setEmployerLimit] = useState<number>(
    aiAssistantFeature?.employerLimit
  );
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
  const [selectedEmpName, setSelectedEmpName] = useState('');
  const [selectedEmpId, setSelectedEmpId] = useState<string | undefined>();

  const [empLimitReachedModal, setShowEmpLimitReachedModal] =
    useState<boolean>(false);

  const { SeatValidateModal, validate } = useValidateSeats();

  useEffect(() => {
    setEmployerLimit(aiAssistantFeature?.employerLimit);
  }, [aiAssistantFeature?.employerLimit]);

  const {
    data: featureWrappers = [],
    refetch: refetchEmployers,
    isFetching,
  } = useGetEmployersQuery({
    page: pageIndex,
    key: 'AI_ASSISTANT',
    query: searchText,
    organizationId: brokerId as string,
    size: pageSize,
    sort: sortOrder,
    defaultEnabled: false,
  });

  useEffect(() => {
    refetchEmployers();
  }, [refetchEmployers, searchText]);

  const purchasedByEmpRow: DataColumn[] = [
    {
      title: 'PURCHASED BY EMPLOYER',
      dataIndex: 'selfPurchased',
      key: 'selfPurchased',
      width: '15%',
      render: (colData, record, index) => {
        return (
          <EmployerFeatureToggle
            onConfirmToggle={() => {
              locationModalRef.current?.closeDisableModal();
              updateStatus({
                enabled: !record?.selfPurchased,
                organizationId: record.organizationId,
                key: navContexts.assistant,
                employerId: record.employerId,
                selfPurchased: !record?.selfPurchased,
              }).then(() => {
                refetchEmployers();
              });
            }}
            enabled={colData}
            recordName={record.brokerName}
            enableMessage={' Are you sure you want to enable Ai Assistant?'}
            disableMessage={
              "Are you sure you want to disable Ai Assistant? This tool will be immediately hidden from the employer's view."
            }
            title={'AI Assistant'}
            enableText="Enable AI-Assistant"
          />
        );
      },
      align: 'left',
    },
  ];

  const techAdminColumns: DataColumn[] = [
    {
      title: 'EMPLOYER',
      dataIndex: 'employerName',
      key: 'employerName',
      width: '60%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) =>
        a?.employerName.localeCompare(b?.employerName),
      defaultSortOrder: 'ascend',
      render: (item, record) => {
        return (
          <OverflowPopover>
            <div className={styles.recordNameArea}>
              <span className="text table-item-link">{item}</span>{' '}
              {record?.selfPurchased && (
                <AssistantStatusTag tagValue={PURCHASED_EMPLOYER} />
              )}
            </div>
          </OverflowPopover>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'lastUpdatedTs',
      key: 'lastUpdatedTs',
      width: '40%',
      showSorterTooltip: false,
      render: (colData, record) => {
        const updatedAt = dayjs(colData).format('MMMM D, YYYY h:mm A');
        return isEmpty(colData) ? (
          <div>-</div>
        ) : (
          <div>
            {updatedAt}
            <br></br>
            <span className={styles.addedBy}>
              by {record.lastUpdatedByAdminName}
            </span>
          </div>
        );
      },
    },
    {
      title: 'ENABLED',
      dataIndex: 'enabled',
      key: 'enabled',
      width: '15%',
      render: (colData, record, index) => {
        return (
          <EmployerFeatureToggle
            disabled={record.selfPurchased}
            onConfirmToggle={async () => {
              setSelectedEmpName(record.employerName);
              setSelectedEmpId(record.employerId);
              const hasSeats =
                colData ||
                (await validate(
                  aiAssistantFeature.employerLimit,
                  aiAssistantFeature.employerCount
                ));
              if (hasSeats) {
                updateStatus({
                  enabled: !colData,
                  organizationId: record.organizationId,
                  employerId: record.employerId,
                  key: FEATURE_KEYS.AI_ASSISTANT,
                }).then(() => {
                  if (
                    !colData &&
                    aiAssistantFeature.employerLimit <=
                      aiAssistantFeature.employerCount
                  ) {
                    updateSendEmail({
                      organizationId: brokerId,
                      featureKey: FEATURE_KEYS.AI_ASSISTANT,
                      employerId: selectedEmpId,
                    });
                  }
                  refetchEmployers();
                  refetchBrokerFeature();
                });
              }
            }}
            enabled={colData}
            recordName={record.brokerName}
            enableMessage={' Are you sure you want to enable Ai Assistant?'}
            disableMessage={
              "Are you sure you want to disable Ai Assistant? This tool will be immediately hidden from the employer's view."
            }
            title={'AI Assistant'}
            enableText="Enable AI-Assistant"
          />
        );
      },
      align: 'left',
    },
  ];

  const columns = isPlatformAdmin
    ? [...techAdminColumns, ...purchasedByEmpRow]
    : techAdminColumns;

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();

    setSearchText(_searchText);
  };

  useEffect(() => {
    setPaginationConfig(paginationConfig);
    setPageIndex(paginationConfig.paginationIndex);
    setPageSize(paginationConfig.filterInfo.limit);
    const { field, order } = (paginationConfig.sorterInfo as any) || {};
    if (isEmpty(order)) {
      setSortOrder('');
    } else if (field == 'brokerName' && order == 'descend') {
      setSortOrder('-name');
    } else if (field == 'brokerName' && order == 'ascend') {
      setSortOrder('name');
    }
  }, [paginationConfig, isFetching]);

  const getSearchString = (isPlatformAdmin: boolean) => {
    if (isPlatformAdmin) {
      return 'Search Employers';
    } else {
      return 'Search Broker';
    }
  };

  const isSaveBtnDisabled =
    saveButtonDisabled || employerLimit === aiAssistantFeature?.employerLimit;

  const handleSaveClick = async () => {
    try {
      await updateEmployerLimit({
        employerLimit: employerLimit,
        organizationId: brokerId as string,
        featureKey: FEATURE_KEYS.AI_ASSISTANT,
      }).unwrap();
      refetchEmployers();
      setSaveButtonDisabled(true);
      refetchBrokerFeature();
    } catch (error) {
      setShowEmpLimitReachedModal(true);
      setEmployerLimit(aiAssistantFeature?.employerLimit);
    }
  };

  return (
    <div className={styles.featureTableWrapper}>
      <div className={styles.employeeAiHeader}>
        <div className={styles.seatsAvailableSection}>
          {EMPLOYER_SEATS}
          <div>
            <NumberFormatInput
              disabled={!isPlatformAdmin}
              value={employerLimit}
              onChange={(e) => {
                setEmployerLimit(+e.target.value);
                setSaveButtonDisabled(false);
              }}
              className={
                !isPlatformAdmin ? styles.disabledInput : styles.seatsEmpInput
              }
              allowNegative={false}
            />
          </div>
          {isPlatformAdmin && (
            <div>
              {isSaveBtnDisabled ? (
                <div className={styles.disabledArea}>
                  <b>Save</b>
                </div>
              ) : (
                <LinkButton
                  disabled={isSaveBtnDisabled}
                  onClick={handleSaveClick}
                >
                  Save
                </LinkButton>
              )}
            </div>
          )}
        </div>
        <div className={styles.cotactSalesDiv}>
          {INCREASE_LIMIT}{' '}
          <LinkButton
            onClick={() => {
              setShowContactModal(true);
            }}
          >
            Contact our Sales Team
          </LinkButton>
        </div>
        <ConfirmationDialog
          visible={empLimitReachedModal}
          confirmText="Close"
          onCancel={() => setShowEmpLimitReachedModal(false)}
          onConfirm={() => setShowEmpLimitReachedModal(false)}
          centered
          confirmBtnFullWidth
          title="Employer Limit Reached"
          isCancelLink={false}
        >
          {EMPLOYER_LIMIT_EXCEEDED}
        </ConfirmationDialog>
      </div>
      <ToolList
        title="AI Assistant"
        showHeader={false}
        columns={columns}
        data={featureWrappers}
        hasBenefitsCategoryFilter={false}
        search={getSearchString(isPlatformAdmin!)}
        handleSearch={handleSearch}
        isLoading={isFetching}
        showFooter={true}
      ></ToolList>

      <ContactSales
        visible={showContactModal}
        onCancel={() => setShowContactModal(false)}
        onConfirm={() => {
          updateSendEmail({
            organizationId: brokerId as string,
            featureKey: FEATURE_KEYS.AI_ASSISTANT,
            triggerType: EmailType.EMPLOYER,
          });
          setShowContactModal(false);
        }}
      />
      <SeatValidateModal employerName={selectedEmpName} />
    </div>
  );
};

export default EmployerAIList;
