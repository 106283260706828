import { FC, ReactNode } from 'react';
import { Col, Row } from 'antd';

import styles from './infoLabel.module.less';

type BasicInfoLabelPropTypes = {
  labelText: string | ReactNode;
  value: ReactNode;
  extraColValue1?: ReactNode;
  extraColValue2?: ReactNode;
  noExtraCol?: boolean;
  extraColValue3?: number;
  extraTitleColValue?: number;
};

const InfoLabel: FC<BasicInfoLabelPropTypes> = (
  props: BasicInfoLabelPropTypes
) => {
  const {
    labelText,
    value,
    extraColValue1,
    extraColValue2,
    noExtraCol,
    extraColValue3,
    extraTitleColValue,
  } = props;
  return (
    <Row className={styles.infoLabelText} gutter={48}>
      <Col
        span={extraTitleColValue ? extraTitleColValue : noExtraCol ? 4 : 8}
        className={extraTitleColValue ? '' : `info-label-header`}
      >
        <span>{labelText}</span>
      </Col>
      <Col
        span={extraColValue3 ? extraColValue3 : noExtraCol ? 20 : 6}
        className={`${styles.infoValueSection} info-label-value`}
      >
        {value}
      </Col>
      {extraColValue1 && (
        <Col span={6} className={`${styles.infoValueSection} info-label-value`}>
          {extraColValue1}
        </Col>
      )}
      {extraColValue2 && (
        <Col span={4} className={`${styles.infoValueSection} info-label-value`}>
          {extraColValue2}
        </Col>
      )}
    </Row>
  );
};

export default InfoLabel;
