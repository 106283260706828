import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Checkbox, Row, Select } from 'antd';
import SearchBar from 'components/SearchBar/SearchBar';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';

import { removeSpecificItemFromStringArray } from 'util/arrayUtil';
import styles from './multiSelect.module.less';

const { Option } = Select;

type Props = {
  searchPlaceholder: string;
  options: OptionProps[];
  optionSort?: boolean;
  defaultOptions: any[];
  resetOptions: any[];
  selectName: string;
  listHeight?: number;
  isLoading?: boolean;
  optionHeading?: string;
  handleOkayCallback: (value: string[], selectName: string) => void;
  handleResetCallback: (value: string[], selectName: string) => void;
};

type OptionProps = {
  label: string;
  value: any;
};

const MultiSelect = forwardRef((props: Props, ref) => {
  const {
    selectName,
    searchPlaceholder,
    optionSort = true,
    defaultOptions = [],
    resetOptions = [],
    isLoading,
    options,
    listHeight = 190,
    handleOkayCallback,
    handleResetCallback,
    optionHeading,
  } = props;

  const selectRef = useRef<any>(null);
  const [searchText, setSearchText] = useState('');
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const [tempSelectedValues, setTempSelectedValues] = useState<any[]>([]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  useEffect(() => {
    if (defaultOptions.length === 0) return;
    setSelectedValues(defaultOptions);
  }, [defaultOptions]);

  const handleOkay = () => {
    setTempSelectedValues(selectedValues);
    handleOkayCallback(selectedValues, String(selectName));
    setSearchText('');
    setShowDropdown(false);
  };

  const handleReset = () => {
    setSelectedValues(resetOptions);
    setTempSelectedValues(resetOptions);
    handleResetCallback(resetOptions, String(selectName));
    setSearchText('');
    setShowDropdown(false);
  };

  const handleOnSelect = (value: any) => {
    if (selectedValues.includes(value)) {
      const removeDeselectedValue = removeSpecificItemFromStringArray(
        selectedValues,
        value
      );
      return setSelectedValues([...removeDeselectedValue]);
    }
    return setSelectedValues([...selectedValues, value]);
  };

  const handleDeselect = (value: any) => {
    const removeDeselectedValue = removeSpecificItemFromStringArray(
      selectedValues,
      value
    );
    return setSelectedValues([...removeDeselectedValue]);
  };

  useImperativeHandle(ref, () => ({
    reset: () => {
      setSelectedValues(resetOptions);
      setTempSelectedValues(resetOptions);
      setSearchText('');
      setShowDropdown(false);
    },
  }));

  const getFilteredOption = (options: OptionProps[]) => {
    const optionsArray: OptionProps[] = options?.filter(({ label }) =>
      label?.toLowerCase()?.includes(searchText?.toLowerCase().trim())
    );
    return optionsArray;
  };

  const getSortOptions = (options: any[]) => {
    let optionsArray: OptionProps[] = [];
    if (optionSort) {
      optionsArray = options?.sort((a, b) => a?.label?.localeCompare(b?.label));
    } else {
      optionsArray = options;
    }

    return optionsArray;
  };

  const tagRenderComp = (tag: any) => {
    return (
      <div className={styles.tags}>
        <span className={styles.tagSpan}>{tag.label}</span>
      </div>
    );
  };
  const maxRenderComp = (values: any) => {
    return (
      <div className={styles.maxTagPlaceholderTags}>
        <span className={styles.maxTagPlaceholderTagsSpan}>
          + {values.length}
        </span>
      </div>
    );
  };

  const dropdownRenderComp = (menu: React.ReactNode) => {
    return (
      <>
        <div className={styles.searchFilter}>
          <SearchBar
            placeholder={searchPlaceholder}
            isLarge={false}
            value={searchText}
            onChange={(e) => setSearchText(e?.target?.value?.trimStart())}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </div>
        {optionHeading && <div className={styles.allTags}>{optionHeading}</div>}
        {menu}
        <Row justify="center">
          <SubmitButton
            type="primary"
            className={styles.confirmButton}
            onClick={handleOkay}
          >
            Okay
          </SubmitButton>
        </Row>
        <Row justify="center">
          <span className={styles.cancelButton} onClick={handleReset}>
            Reset
          </span>
        </Row>
      </>
    );
  };

  return (
    <div className={styles.filterWrapper}>
      <Select
        ref={selectRef}
        mode="multiple"
        className={styles.filter}
        optionLabelProp="label"
        direction="ltr"
        maxTagCount="responsive"
        maxTagPlaceholder={maxRenderComp}
        listHeight={listHeight}
        showArrow={true}
        showSearch={false}
        placement="bottomRight"
        dropdownClassName={styles.dropdownWrapper}
        dropdownRender={dropdownRenderComp}
        tagRender={tagRenderComp}
        value={selectedValues}
        onSelect={handleOnSelect}
        onDeselect={handleDeselect}
        onInputKeyDown={(e: any) => {
          if (e.keyCode === 8 && e.target.value.length === 0) {
            return e.stopPropagation();
          }
        }}
        open={showDropdown}
        onDropdownVisibleChange={(open) => {
          setShowDropdown(open);
          if (!open) {
            setSelectedValues(tempSelectedValues);
          } else {
            setTempSelectedValues(selectedValues);
            selectRef?.current?.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }
        }}
        getPopupContainer={(trigger: any) =>
          trigger.parentElement.parentElement
        }
        loading={isLoading}
      >
        {getFilteredOption(getSortOptions(options))?.map((option, idx) => {
          return (
            <Option
              key={option?.value === null ? idx : option?.value}
              value={option?.value}
              label={option?.label}
            >
              <Checkbox
                className={styles.singleSelect}
                checked={selectedValues?.includes(option?.value)}
                onKeyDown={(e) => e.stopPropagation()}
              >
                <span onClick={(e) => e.stopPropagation()}>
                  {option?.label}
                </span>
              </Checkbox>
            </Option>
          );
        })}
      </Select>
    </div>
  );
});

MultiSelect.displayName = 'MultiSelect';

export default MultiSelect;
