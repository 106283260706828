import { isEmpty } from 'lodash';
import { Message } from 'modules/assistant/models/Message';
import { MessageVO } from 'modules/assistant/models/AssistantChatResponseVO';

/**
 * Transforms the response of chat history conversation API to an array of current chat objects.
 * @param {ChatHistoryConversation[]} messages - The response data from the chat history conversation API.
 * @return {Message[]} An array of current chat objects.
 */
export const transformMessages = (messages: MessageVO[]): Message[] => {
  return messages?.map((chat) => ({
    id: chat.id ?? '',
    question: chat.question || '',
    answer: chat?.content?.content || '',
    animate: false,
    isPending: isEmpty(chat?.content?.content),
    error: !isEmpty(chat?.content?.error),
  }));
};
