import { FC } from 'react';
import { Col, Row } from 'antd';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';

import styles from './footerSection.module.less';

type FooterSectionProps = {
  disabled?: boolean;
  isLoading?: boolean;
  onSave: React.MouseEventHandler<HTMLElement>;
  onCancel: React.MouseEventHandler<HTMLElement>;
};

const FooterSection: FC<FooterSectionProps> = (props: FooterSectionProps) => {
  const { onSave, onCancel, isLoading = false, disabled } = props;

  return (
    <>
      <Row justify="center" className={styles.rowStyle}>
        <Col span={24}>
          <PageActionButton
            onClick={onSave}
            type="primary"
            className={styles.saveBtn}
            loading={isLoading}
            disabled={disabled}
          >
            Save
          </PageActionButton>
        </Col>
      </Row>
      <Row justify="center" className={styles.cancelWrapper}>
        <Col>
          <CancelButton
            disabled={isLoading}
            htmlType="button"
            onClick={onCancel}
          >
            Cancel
          </CancelButton>
        </Col>
      </Row>
    </>
  );
};

export default FooterSection;
