import { getTimezone } from 'countries-and-timezones';

export const ConsultationTypes = {
  BROKER_EXTERNAL: { value: 'BROKER_EXTERNAL', label: 'External' },
  BROKER: { value: 'BROKER', label: 'Broker Admin' },
  EMPLOYER: {
    value: 'EMPLOYER',
    label: 'Employer User',
  },
  EMPLOYER_EXTERNAL: {
    value: 'EMPLOYER_EXTERNAL',
    label: 'External',
  },
};

export const SEND_INVITE_NOW = 'Send Now';

export const DaysOfTheWeek = {
  MON: { value: 'mon', label: 'Monday' },
  TUE: { value: 'tue', label: 'Tuesday' },
  WED: { value: 'wed', label: 'Wednesday' },
  THU: { value: 'thu', label: 'Thursday' },
  FRI: { value: 'fri', label: 'Friday' },
  SAT: { value: 'sat', label: 'Saturday' },
  SUN: { value: 'sun', label: 'Sunday' },
};

export const TIME_ZONES = [
  { id: 'America/New_York', name: 'Eastern Time (U.S.)', abbreviation: 'ET' },
  { id: 'America/Chicago', name: 'Central Time (U.S.)', abbreviation: 'CT' },
  { id: 'America/Denver', name: 'Mountain Time (U.S.)', abbreviation: 'MT' },
  { id: 'America/Phoenix', name: 'Arizona Time (U.S.)' },
  {
    id: 'America/Los_Angeles',
    name: 'Pacific Time (U.S.)',
    abbreviation: 'PT',
  },
  { id: 'America/Anchorage', name: 'Alaska Time (U.S.)', abbreviation: 'AK' },
  { id: 'Pacific/Honolulu', name: 'Hawaii Time (U.S.)', abbreviation: 'HAST' },
];

export const TIME_ZONES_IN_GMT = TIME_ZONES.map((timezone) => {
  const timezoneObj = getTimezone(timezone.id);
  let timezoneText = timezone.name;
  if (timezoneObj) {
    timezoneText = timezoneObj.dstOffsetStr;
  }
  return {
    value: timezone.id,
    label: timezone.name,
    timezone: parseInt(timezoneText.replace(':', '')),
    name: timezone.name,
  };
});

export const TIME_OPTIONS = [
  { value: '0000', label: '-', disabled: true },
  { value: '0', label: '12:00 am' },
  { value: '30', label: '12:30 am' },
  { value: '100', label: '01:00 am' },
  { value: '130', label: '01:30 am' },
  { value: '200', label: '02:00 am' },
  { value: '230', label: '02:30 am' },
  { value: '300', label: '03:00 am' },
  { value: '330', label: '03:30 am' },
  { value: '400', label: '04:00 am' },
  { value: '430', label: '04:30 am' },
  { value: '500', label: '05:00 am' },
  { value: '530', label: '05:30 am' },
  { value: '600', label: '06:00 am' },
  { value: '630', label: '06:30 am' },
  { value: '700', label: '07:00 am' },
  { value: '730', label: '07:30 am' },
  { value: '800', label: '08:00 am' },
  { value: '830', label: '08:30 am' },
  { value: '900', label: '09:00 am' },
  { value: '930', label: '09:30 am' },
  { value: '1000', label: '10:00 am' },
  { value: '1030', label: '10:30 am' },
  { value: '1100', label: '11:00 am' },
  { value: '1130', label: '11:30 am' },
  { value: '1200', label: '12:00 pm' },
  { value: '1230', label: '12:30 pm' },
  { value: '1300', label: '01:00 pm' },
  { value: '1330', label: '01:30 pm' },
  { value: '1400', label: '02:00 pm' },
  { value: '1430', label: '02:30 pm' },
  { value: '1500', label: '03:00 pm' },
  { value: '1530', label: '03:30 pm' },
  { value: '1600', label: '04:00 pm' },
  { value: '1630', label: '04:30 pm' },
  { value: '1700', label: '05:00 pm' },
  { value: '1730', label: '05:30 pm' },
  { value: '1800', label: '06:00 pm' },
  { value: '1830', label: '06:30 pm' },
  { value: '1900', label: '07:00 pm' },
  { value: '1930', label: '07:30 pm' },
  { value: '2000', label: '08:00 pm' },
  { value: '2030', label: '08:30 pm' },
  { value: '2100', label: '09:00 pm' },
  { value: '2130', label: '09:30 pm' },
  { value: '2200', label: '10:00 pm' },
  { value: '2230', label: '10:30 pm' },
  { value: '2300', label: '11:00 pm' },
  { value: '2330', label: '11:30 pm' },
  { value: '2400', label: '12:00 am' },
];
export const CALENDAR_SYNC_DENIED = 'calendar.permission.denied';
