import { Popover, Spin } from 'antd';
import Icon, { QuestionCircleOutlined } from '@ant-design/icons';
import { useAppSelector } from 'hooks/redux';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import { ReactComponent as IconTagCallout } from 'assets/images/icon-tag-callout.svg';
import { ReactComponent as IconLoading } from 'assets/images/spinner-gap.svg';
import { ReactComponent as IconAI } from 'assets/images/Rfp-ai-selected.svg';
import styles from './panelHeaderExtra.module.less';

type Props = {
  isActive: boolean;
  isComplete: boolean;
  isAi?: boolean;
  isUploading?: boolean;
  popoverContent?: any;
  isOpsAdmin?: boolean;
  isSBC?: boolean;
  isFromUpdatePan: boolean;
};

const PanelHeaderExtra = ({
  isAi,
  isComplete,
  isActive,
  isUploading,
  popoverContent,
  isOpsAdmin,
  isSBC,
  isFromUpdatePan,
}: Props) => {
  const { status, skipFailedDocument } = useAppSelector(
    (state) => state.plan.aiSbc
  );

  // Show popover icon with content if the panel is active and popoverContent is provided
  if (isActive && popoverContent) {
    return (
      <Popover
        content={popoverContent}
        placement="bottomRight"
        overlayClassName="popoverInnerPlanTooltip"
      >
        <QuestionCircleOutlined className={styles.popoverIcon} />
      </Popover>
    );
  }

  // Show custom spinner if the file is uploading
  if (isUploading) {
    const customSpinner = (
      <Icon component={IconLoading} className={styles.spinner} spin />
    );
    return (
      <span className="text service-table-col-header">
        <Spin indicator={customSpinner} /> File Uploading...
      </span>
    );
  }

  // Show "Ready for Review" for Ops Admin when SBC is ready for review
  const isReviewReady = [
    ProcessStatus.SUCCESS,
    ProcessStatus.SAVED,
    ProcessStatus.REVIEWED,
  ].includes(status);

  if (isOpsAdmin && isSBC && isReviewReady) {
    return (
      <div className={styles.extraText}>
        <IconAI />
        Ready for Review
      </div>
    );
  }

  // Handle incomplete panels
  if (!isComplete) {
    if (isAi && !skipFailedDocument) {
      return (
        <div className={styles.extraText}>
          <IconAI />
          Ready for Review
        </div>
      );
    }

    if (isSBC && !skipFailedDocument) {
      return (
        <div className={styles.extraText}>
          <IconAI />
          Reviewed
        </div>
      );
    }

    // Skip rendering if the panel is from update plan
    if (isFromUpdatePan) return null;

    return (
      <div className={styles.addDetailsText}>
        <IconTagCallout className={styles.animatedCallout} />
        Add Details
      </div>
    );
  }

  // Handle completed SBC panels that aren't skipped
  if (isSBC && !skipFailedDocument) {
    return (
      <div className={styles.extraText}>
        <IconAI />
        Reviewed
      </div>
    );
  }

  // Default case for completed panels
  return <div className={styles.completeText}>Complete</div>;
};

export default PanelHeaderExtra;
