import { FC } from 'react';
import { Table } from 'antd';
import isEmpty from 'lodash/isEmpty';

import { DaysOfTheWeek } from 'modules/benefitsConsultation/constants/benefitsConsultation';

import styles from './viewAvailability.module.less';

type Availability = {
  day: string;
  isOpen?: boolean;
  duration: string;
};

type ViewAvailabilityProps = {
  availabilityList: Availability[];
};

const convertTableDataForTableRender = (tableData: Availability[]): any[] => {
  return tableData?.map((data) => ({
    key: data.day,
    day: data.day,
    isOpen: data.isOpen,
    duration: data,
  }));
};

const ViewAvailability: FC<ViewAvailabilityProps> = (
  props: ViewAvailabilityProps
) => {
  const { availabilityList } = props;

  const tableDataColumns = [
    {
      title: 'Day',
      dataIndex: 'day',
      key: 'day',
      width: '35%',
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'Open',
      dataIndex: 'isOpen',
      key: 'isOpen',
      width: '35%',
      ellipsis: {
        showTitle: false,
      },
      render: (isOpen: boolean) => (
        <span className={styles.isOpen}>
          {isOpen === undefined ? '-' : isOpen ? 'Available' : 'Unavailable'}
        </span>
      ),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      width: '40%',
      ellipsis: {
        showTitle: false,
      },
      render: (data: Availability) => (
        <span>{data.isOpen ? data.duration : ''}</span>
      ),
    },
  ];
  return (
    <div className={styles.viewAvailabilityWrapper}>
      {!isEmpty(availabilityList) && (
        <Table
          showHeader={false}
          columns={tableDataColumns}
          dataSource={convertTableDataForTableRender(availabilityList)}
          pagination={false}
          className={styles.tableWrapper}
        />
      )}
      {isEmpty(availabilityList) && (
        <div className={styles.defaultData}>
          {Object.values(DaysOfTheWeek).map((day, key) => (
            <div key={key}>{day.label}</div>
          ))}
          <div className={styles.noDataMsg}>No Data</div>
        </div>
      )}
    </div>
  );
};

export default ViewAvailability;
