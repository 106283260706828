export const BENEFIT_GUIDE_STATUS = {
  IN_PROGRESS: 'DRAFT',
  ARCHIVED: 'ARCHIVED',
  PUBLISHED: 'PUBLISHED',
};

export const BENEFIT_GUIDE_STATUS_ARRAY = [
  BENEFIT_GUIDE_STATUS.IN_PROGRESS,
  BENEFIT_GUIDE_STATUS.PUBLISHED,
  BENEFIT_GUIDE_STATUS.ARCHIVED,
];

export const NO_BENGUIDE_CARD_DETAILS = [
  {
    title: 'No guides are currently in progress',
    description:
      'Newly created guides will show up here before being published or archived',
  },

  {
    title: 'No published guides exist',
    description: 'Guides will show up here after publishing',
  },

  {
    title: 'No archived guides exist',
    description: 'Guides will show up here after archiving',
  },
];

export const BENEFIT_GUIDE_ALREADY_EXISTS = 'benguide.name.already.exists';
export const BENEFIT_GUIDE_DOESNT_EXIST = 'benguide.not.found';

export const BENEFIT_GUIDE_HIGHLIGHTED_ISSUES_WARNING_MESSAGE =
  'Please correct all highlighed issues and try again!';

export const BENEFIT_GUIDE_CLONING_INFO_MESSAGE =
  'All custom text from this Benefits Guide will be included in the new cloned Benefits Guide.';

export const BENEFIT_GUIDE_CLONING_WARNING_MESSAGE =
  "Only the general descriptions are copied when cloning to a different plan year; plans and custom descriptions aren't cloned.";
