import { CheckOutlined } from '@ant-design/icons';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import styles from './quoteProcessingStatusTag.module.less';

export const QuoteProcessingStatusTag = (props: {
  className?: string;
  status?: string;
  isOpsAdmin?: boolean;
}) => {
  const { className, status, isOpsAdmin } = props;

  let tagContent: React.ReactNode = '';
  let tagStyle = '';

  if (isOpsAdmin) {
    switch (status) {
      case ProcessStatus.SUCCESS:
        tagContent = (
          <>
            <CheckOutlined /> READY FOR REVIEW
          </>
        );
        tagStyle = styles.readyForReview;
        break;
      case ProcessStatus.FAILED:
        tagContent = 'PROCESSING ERROR';
        tagStyle = styles.error;
        break;
    }
  } else {
    switch (status) {
      case ProcessStatus.REVIEWED:
        tagContent = (
          <>
            <CheckOutlined /> READY FOR REVIEW
          </>
        );
        tagStyle = styles.readyForReview;
        break;
    }
  }
  return <div className={`${tagStyle} ${className}`}>{tagContent}</div>;
};
