import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Form, notification } from 'antd';
import { get, isEmpty, isEqual } from 'lodash';

import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import PlanBasicForm from 'modules/plans/components/PlanBasicForm/PlanBasicForm';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { AdditionalPlan } from 'model/plans/AdditionalPlan';
import { useNavContext } from 'hooks/useNavContext';
import {
  PLAN_ADDITIONAL_DOCUMENT,
  VALIDATION_NAME_DUPLICATED,
} from 'modules/plans/constants';
import {
  BenefitCategory,
  benefitGroupsChangedMsg,
  EMPTY_MESSAGE,
  formVerificationMsg,
  planAlreadyExistsError,
  planYearChangedMsg,
  RX_DELIVERY_RX_COUPONS,
} from 'constants/commonConstants';
import {
  clearTelehealthPlanApiErrors,
  findPlanById,
  saveTelehealthRxPlan,
} from 'modules/plans/slices/telehealthRxPlanSlice';
import { getCarriersByBenefitKind } from 'modules/plans/slices/basicPlanInfoSlice';

import AdditionalPlanResources from 'modules/plans/components/AdditionalPlanResources/AdditionalPlanResources';
import FileType from 'model/plans/FileType';
import WebLinkType from 'model/plans/WebLinkType';
import {
  ERROR_MESSAGE_PLAN_EDITING,
  PLAN_NOTIFICATION_KEY,
} from 'constants/benguideCollaborationConstants';
import {
  removePlanDocuments,
  updateWeblinks,
  uploadPlanDocument,
  uploadRemovePlanDocument,
} from 'modules/plans/slices/planDocumentsSlice';
import styles from './addTelehealthRxPlan.module.less';

export const TelehealthRxPlanBasicInfoFormFields = {
  requiredFields: ['planName', 'planYear', 'benefitClass', 'benefitCarrier'],
  formFields: [
    'planName',
    'planYear',
    'benefitCarrier',
    'benefitClass',
    'file',
    'weblink',
    'groupId',
  ],
};
type AddTelehealthRxPlanProps = {
  onClose: () => void;
  closePlanAddModal: () => void;
  isCloseConfirmed: boolean;
  benefitCategory: any;
  isPlanUpdateMode?: boolean;
  plan?: any;
  isSaveDisabled?: boolean;
  dbgPlanYear?: string;
  isDBGPlan?: boolean;
  isModalVisible?: boolean;
};

const formInitialValues = {
  planName: '',
  benefitCarrier: '',
  planYear: '',
  benefitClass: [],
  weblink: '',
  fileName: '',
  groupId: '',
};

const AddTelehealthRxPlan = forwardRef(
  (props: AddTelehealthRxPlanProps, ref) => {
    const {
      isCloseConfirmed,
      benefitCategory,
      onClose,
      closePlanAddModal,
      isPlanUpdateMode,
      plan,
      isSaveDisabled = false,
      dbgPlanYear,
      isDBGPlan,
      isModalVisible,
    } = props;

    const dispatch = useAppDispatch();
    const { employerId, brokerId } = useNavContext();
    const { planYearsList } = useAppSelector(
      (state) => state.employer.employer
    );
    const basicPlanInfo = useAppSelector((state) => state.plan.planBasicInfo);
    const error = useAppSelector((state) => state.plan.telehealthRxPlan.error);
    const { inProgress } = useAppSelector(
      (state) => state.plan.telehealthRxPlan
    );
    const { inProgress: documentUploadInProgress } = useAppSelector(
      (state) => state.plan.planDocuments
    );
    const { carriersList } = basicPlanInfo;
    const [form] = Form.useForm();
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<AlertInfo>({
      type: undefined,
      message: '',
    });
    const [formData, setFormData] = useState(formInitialValues);

    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showPlanYearWarning, setShowPlanYearWarning] =
      useState<boolean>(false);
    const additionalResourcesRef = useRef<any>();
    const basicPlanInfoRef = useRef<any>();
    const [notificationKey] = useState(PLAN_NOTIFICATION_KEY);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedFileList, setSelectedFileList] = useState<FileType[]>([]);
    const [isDocRemoved, setIsDocRemoved] = useState<{
      [key: string]: boolean;
    }>({});
    const [selectedWeblink, setSelectedWeblink] = useState<WebLinkType[]>([]);

    useImperativeHandle(ref, () => ({
      validate() {
        return getValidationResult();
      },
      reset() {
        setSelectedFileList([]);
        setSelectedWeblink([]);
        setShowWarning(false);
        setShowPlanYearWarning(false);
        form.resetFields();
      },
    }));

    useEffect(() => {
      if (brokerId && employerId && isModalVisible) {
        let benefitKind = benefitCategory.value;
        if (benefitCategory.value !== BenefitCategory.TELEHEALTH.value) {
          benefitKind = RX_DELIVERY_RX_COUPONS;
        }
        dispatch(getCarriersByBenefitKind(benefitKind, brokerId, employerId));
      }
    }, [dispatch, brokerId, benefitCategory.value, employerId, isModalVisible]);

    useEffect(() => {
      if (isCloseConfirmed) {
        setFormData(formInitialValues);
        basicPlanInfoRef.current?.resetForm();
        setShowAlert(false);
      }
    }, [form, isCloseConfirmed]);

    useEffect(() => {
      if (error?.data?.code === VALIDATION_NAME_DUPLICATED) {
        form.setFields([{ name: 'planName', errors: [EMPTY_MESSAGE] }]);

        setAlertMessage({
          type: 'error',
          message: planAlreadyExistsError,
        });
        setShowAlert(true);
        dispatch(clearTelehealthPlanApiErrors());
        setLoading(false);
      }
    }, [dispatch, error, form]);

    useEffect(() => {
      if (plan) {
        const {
          name,
          groups,
          planYearId,
          webLink,
          benefitCarrier,
          documents,
          groupId,
        } = plan;
        const attachedFileName = get(documents, 'PLAN_DOCUMENT');

        const { id } = benefitCarrier || {};
        const formData = {
          planName: name,
          benefitClass: groups,
          planYear: planYearId,
          benefitCarrier: id ? id : null,
          weblink: attachedFileName ? null : webLink,
          fileName: attachedFileName,
          groupId: groupId,
        };

        form.setFieldsValue(formData);
        setFormData(formData);
      }
    }, [setFormData, form, plan]);

    useEffect(() => {
      setShowWarning(false);
      setShowPlanYearWarning(false);
      setShowAlert(false);
      if (isPlanUpdateMode && !isEqual(plan?.groups, formData.benefitClass)) {
        setShowPlanYearWarning(false);
        setShowWarning(true);
        setShowAlert(true);
        setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
      }
      if (
        isPlanUpdateMode &&
        !isEqual(plan?.planYearId, formData.planYear?.trim())
      ) {
        setShowPlanYearWarning(true);
        setShowAlert(true);
        setAlertMessage({ message: planYearChangedMsg, type: 'warning' });
      }
      if (
        isPlanUpdateMode &&
        !isEqual(plan?.planYearId, formData.planYear?.trim()) &&
        !isEqual(plan?.groups, formData.benefitClass)
      ) {
        setShowPlanYearWarning(true);
        setShowWarning(true);
        setShowAlert(true);
        setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
      }
    }, [plan, formData.benefitClass, isPlanUpdateMode, formData.planYear]);

    const validateBasicInfo = async () => {
      try {
        await form.validateFields(
          TelehealthRxPlanBasicInfoFormFields.requiredFields
        );
        return true;
      } catch (errorInfo: any) {
        return errorInfo.errorFields.length === 0;
      }
    };

    const getValidationResult = async () => {
      const isFormValid = await validateBasicInfo();

      if (isFormValid) {
        return true;
      } else {
        setShowAlert(true);
        return false;
      }
    };

    const closeOnSave = async (id: string) => {
      await uploadDocument(id);
    };

    const onClickSave = async () => {
      setShowAlert(false);
      setShowWarning(false);
      setShowPlanYearWarning(false);
      const isFormValid = await getValidationResult();

      const formValues = form.getFieldsValue();
      const { benefitClass, planName, planYear, benefitCarrier, groupId } =
        formValues;

      const selectedPlanYear = planYearsList.find((t) => t.id === planYear);
      const newPlan = {
        ...plan,
        benefitKind: benefitCategory.value,
        employerId: employerId,
        planYearId: planYear?.trim(),
        name: planName,
        benefitCarrierId: benefitCarrier ? benefitCarrier.trim() : null,
        startDate: selectedPlanYear?.startDate,
        endDate: selectedPlanYear?.endDate,
        groups: benefitClass,
        groupId: groupId ? groupId : '',
      } as AdditionalPlan;

      if (isEmpty(newPlan?.documentReferences)) {
        delete newPlan.documentReferences;
      }

      delete newPlan.additionalDocumentReferences;
      delete newPlan.additionalWeblinks;

      if (isPlanUpdateMode) {
        newPlan.id = plan.id;
      }

      if (isFormValid) {
        if (isPlanUpdateMode && isDBGPlan) {
          setLoading(true);
        }
        await dispatch(
          saveTelehealthRxPlan(newPlan, (id: string) => {
            closeOnSave(id);
          })
        );

        notification.close(notificationKey);
      } else {
        setAlertMessage({
          type: 'error',
          message: formVerificationMsg,
        });
        setShowAlert(true);
      }
    };

    const onCancel = () => {
      setShowWarning(false);
      setShowPlanYearWarning(false);
      closePlanAddModal();
    };

    const uploadDocument = async (id: string) => {
      const documentUploadSuccess = () => {
        setSelectedFileList([]);
        setSelectedWeblink([]);
        onClose();
        setShowWarning(false);
        setShowAlert(false);
        setShowPlanYearWarning(false);
        if (plan && plan.id) {
          dispatch(findPlanById(plan.id));
        }
      };

      const planId = id ?? plan.id;
      const removeDocs = Object.keys(isDocRemoved)
        .filter((documentType) => isDocRemoved[documentType])
        .map((documentType) => ({
          docType: PLAN_ADDITIONAL_DOCUMENT,
          planDocumentName: documentType,
        }));
      if (!isEmpty(selectedFileList) && isEmpty(removeDocs)) {
        await dispatch(
          uploadPlanDocument(
            selectedFileList,
            planId,
            BenefitCategory.TELEHEALTH.value
          )
        );
      } else if (!isEmpty(removeDocs) && isEmpty(selectedFileList)) {
        await dispatch(
          removePlanDocuments(
            planId,
            BenefitCategory.TELEHEALTH.value,
            removeDocs
          )
        );
      } else if (!isEmpty(removeDocs) && !isEmpty(selectedFileList)) {
        await dispatch(
          uploadRemovePlanDocument(
            selectedFileList,
            planId,
            BenefitCategory.TELEHEALTH.value,
            removeDocs
          )
        );
      }
      if (selectedWeblink.length > 0) {
        await dispatch(
          updateWeblinks(
            selectedWeblink,
            planId,
            BenefitCategory.TELEHEALTH.value
          )
        );
      }
      documentUploadSuccess();
    };

    return (
      <div
        className={`${
          isPlanUpdateMode ? styles.planEditModeWrapper : styles.wrapperForm
        }  `}
      >
        {showAlert && (
          <AlertMessage
            type={alertMessage.type}
            className={
              isPlanUpdateMode
                ? styles.alertWrapper
                : styles.alertMessageWrapper
            }
            message={alertMessage.message}
            closeAlert={() => {
              setShowAlert(false);
            }}
          />
        )}
        {isSaveDisabled && !plan && (
          <FixedAlertMessage
            type={'error'}
            message={ERROR_MESSAGE_PLAN_EDITING}
          />
        )}
        <PlanBasicForm
          benefitCategory={
            benefitCategory.value === BenefitCategory.TELEHEALTH.value
              ? BenefitCategory.TELEHEALTH.value
              : RX_DELIVERY_RX_COUPONS
          }
          carriersList={carriersList}
          setRequiredFieldError={setShowAlert}
          setFormData={setFormData}
          formData={formData}
          form={form}
          defaultPlanName={benefitCategory.label}
          isPlanUpdateMode={isPlanUpdateMode}
          ref={basicPlanInfoRef}
          showWarning={showWarning}
          showPlanYearWarning={showPlanYearWarning}
          dbgPlanYear={dbgPlanYear}
          isDBGPlan={isDBGPlan}
          isModalVisible={isModalVisible}
          currentPlanId={plan?.id}
        />
        <div className={styles.header}>Plan Resources</div>
        <AdditionalPlanResources
          ref={additionalResourcesRef}
          plan={plan}
          benefitKind={BenefitCategory.TELEHEALTH.value}
          isCloseConfirmed={isCloseConfirmed}
          selectedFileList={selectedFileList}
          setSelectedFileList={setSelectedFileList}
          selectedWebLinkList={selectedWeblink}
          setSelectedWebLinkList={setSelectedWeblink}
          setIsDocRemoved={setIsDocRemoved}
        />
        <div className={styles.btnWrapper}>
          <SubmitButton
            disabled={isSaveDisabled}
            className={styles.saveButtonWrapper}
            type="primary"
            onClick={() => onClickSave()}
            loading={inProgress || loading || documentUploadInProgress}
          >
            {isPlanUpdateMode ? 'Done' : 'Save'}
          </SubmitButton>
          <CancelButton
            className={styles.cancelButtonWrapper}
            onClick={() => onCancel()}
            withBorder={isPlanUpdateMode}
          >
            Cancel
          </CancelButton>
        </div>
      </div>
    );
  }
);
AddTelehealthRxPlan.displayName = 'AddTelehealthRxPlan';
export default AddTelehealthRxPlan;
