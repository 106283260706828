import { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import NumberFormatInput, {
  preventMoreThanHundredForPercentages,
} from 'components/FormInput/NumberFormatInput';
import { useUpdateHRAUtilizationsMutation } from 'modules/plans/slices/planSllice';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import DataTable from 'components/DataTable/DataTable';

import styles from './updateHRAValues.module.less';

type UpdateHRAValuesProps = {
  hraPlans: [];
  onSaved: () => void;
  visible: boolean;
  onCancel: () => void;
};

const UpdateHRAValues = (props: UpdateHRAValuesProps) => {
  const { hraPlans = [], onSaved, visible, onCancel } = props;
  const [updateHra, { isLoading: saving, isSuccess }] =
    useUpdateHRAUtilizationsMutation();

  const [updatedValues, setUpdatedValues] = useState<any>({});

  const updateValues = (planId: string, hraUtillization: number) => {
    setUpdatedValues({ ...updatedValues, [planId]: hraUtillization });
  };

  useEffect(() => {
    if (isSuccess) {
      onSaved();
    }
  }, [isSuccess, onSaved]);

  const update = () => {
    const hraValues = Object.keys(updatedValues).map((key) => ({
      planId: key,
      hraUtilization: updatedValues[key] as number,
    }));
    updateHra(hraValues);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'CURRENT PLANS',
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => {
        a = a.name || '';
        b = b.name || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: false,
      sortOrder: 'ascend',
      render: (name) => {
        return <span className={styles.currentPlanText}>{name}</span>;
      },
    },
    {
      title: 'HRA UTILIZATION',
      width: '30%',
      key: 'id',
      dataIndex: 'id',
      render: (id) => {
        return (
          <NumberFormatInput
            suffix="%"
            isBrokerScreen={true}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={false}
            placeholder="%"
            onChange={(event) => {
              updateValues(id, parseFloat(event?.target?.value) || 0);
            }}
            isAllowed={preventMoreThanHundredForPercentages}
            style={{ height: '30px' }}
          />
        );
      },
    },
  ];

  return (
    <ConfirmationDialog
      title="Update HRA Utilization"
      confirmText="Update"
      cancelText="Cancel"
      closeModal={onCancel}
      visible={visible}
      onConfirm={update}
      buttonsExpanded={true}
      isCancelLink={true}
      width={664}
      confirmBtnFullWidth={true}
      disableConfirmButton={saving}
    >
      <div className={styles.updateHRAValuesWrapper}>
        <div className={styles.description}>
          <p>
            This proposal has HRA plans, and the total benefits cost includes
            the calculated HRA Cost. However, utilization percentages for the
            following HRA plans in the current year have not been provided.
          </p>
          <p>Please enter these values below for improved cost comparisons.</p>
        </div>
        <div>
          <DataTable data={hraPlans} columns={columns} pagination={false} />
        </div>
      </div>
    </ConfirmationDialog>
  );
};

export default UpdateHRAValues;
