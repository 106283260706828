import { useEffect, useState } from 'react';

import { Row } from 'antd';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { isEmpty } from 'lodash';

import { issueAnalyticsDateRange } from 'modules/issueslog/utils/issueLogUtils';
import {
  COLOR_ISSUE_VELOCITY_CHART_NON_HOVER,
  COLOR_ISSUE_VELOCITY_CHAT_STOKE,
  ISSUE_VELOCITY_CHART_SECONDARY_COLOR,
  COLOR_LEGEND_LABEL_SHADE,
  COLOR_LIGHT_GREY,
  ISSUE_VELOCITY_CHART_PRIMARY_COLOR,
} from 'modules/issueslog/constants/IssuesLogConstants';

import styles from 'modules/issueslog/components/IssueVelocityBarChart/issueVelocityBarChart.module.less';

type IssueVelocityBarChartProps = {
  chartData: any;
  isNoView?: boolean;
  selectedDateRange: string;
};

const IssueVelocityBarChart = (props: IssueVelocityBarChartProps) => {
  const { chartData } = props;

  const [posData, setposData] = useState({ name: '', x: -1000, y: -1000 });

  const [chartObj, setChartObj] = useState<any[]>(chartData);

  const xAxisTick = (value: string, x: number, y: number) => {
    const isWeekly: boolean =
      props.selectedDateRange === issueAnalyticsDateRange.FOUR_WEEKS ||
      props.selectedDateRange === issueAnalyticsDateRange.TWELVE_WEEKS;
    const isSingleLine: boolean =
      isWeekly &&
      props.selectedDateRange === issueAnalyticsDateRange.FOUR_WEEKS;

    const splitted = value.split(isWeekly ? '-' : ' ');
    return value && isSingleLine ? (
      <text
        x={x - 45}
        y={y + 15}
        fill={COLOR_LEGEND_LABEL_SHADE}
        fontSize={13}
        fontWeight={600}
      >
        {value}
      </text>
    ) : (
      <>
        <text
          x={x - 10}
          y={y + 15}
          fill={COLOR_LEGEND_LABEL_SHADE}
          fontSize={13}
          fontWeight={600}
        >
          {splitted[0].concat(isWeekly ? '-' : '')}
        </text>
        <text
          fontSize={13}
          x={x - 10}
          y={y + 35}
          fill={COLOR_LEGEND_LABEL_SHADE}
          fontWeight={600}
        >
          {splitted[1]}
        </text>
      </>
    );
  };

  useEffect(() => {
    if (chartData) {
      const reversedChartData = [...chartData];
      reversedChartData.reverse();
      setChartObj(reversedChartData);
    }
  }, [chartData]);

  console.log(props.selectedDateRange);

  const checkDateRange = (): boolean => {
    if (
      props.selectedDateRange === issueAnalyticsDateRange.FOUR_WEEKS ||
      props.selectedDateRange === issueAnalyticsDateRange.TWELVE_WEEKS
    ) {
      return true;
    } else if (
      props.selectedDateRange === issueAnalyticsDateRange.SEVEN_DAYS ||
      props.selectedDateRange === issueAnalyticsDateRange.TWELVE_MONTHS
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className={styles.billingView}>
      <Row>
        <ResponsiveContainer width="98%" height={321}>
          <BarChart
            data={chartObj}
            barGap={0}
            barCategoryGap={4}
            barSize={
              issueAnalyticsDateRange.FOUR_WEEKS == props.selectedDateRange
                ? 65
                : 35
            }
          >
            <CartesianGrid
              vertical={false}
              stroke={COLOR_ISSUE_VELOCITY_CHAT_STOKE}
            />
            <XAxis
              dataKey="label"
              hide={false}
              axisLine={false}
              minTickGap={0}
              tickMargin={10}
              interval={0}
              padding={{ left: 0, right: 0 }}
              label={''}
              tick={({ payload, x, y }: any) => {
                const { value } = payload;
                return xAxisTick(value, x, y);
              }}
              tickLine={{ height: 40, stroke: COLOR_LIGHT_GREY }}
              height={50}
            />
            <YAxis
              hide={false}
              axisLine={false}
              tick={(data) => {
                return (
                  <g transform={`translate(${0},${data.y})`}>
                    <text
                      x={0}
                      y={data.index === 4 ? -2 : 5}
                      textAnchor="start"
                      fill="#666"
                      className={styles.yAxisTick}
                    >
                      <>{data.payload.value}</>
                    </text>
                  </g>
                );
              }}
              minTickGap={0}
              width={70}
              tickMargin={10}
              label={''}
              tickLine={{ height: 40, stroke: COLOR_LIGHT_GREY }}
              height={50}
            />
            <ChartTooltip
              cursor={false}
              position={{
                x: checkDateRange() ? posData.x - 87 : posData.x - 60,
                y: posData.y - 110,
              }}
              active={false}
              content={(data: any) => {
                return !isEmpty(data.payload) ? (
                  <div className={styles.tooltipWrapper}>
                    <div className={styles.amount}>
                      {data.payload[0]?.payload?.label.replace(/'/g, 20)}
                      <br />
                      <div className={styles.tooltipTitle}>
                        {data.payload[0]?.payload?.openedCount}
                      </div>
                      <div className={styles.tooltipSubTitle}>New Items</div>
                      <div className={styles.tooltipTitle}>
                        {data.payload[0]?.payload?.resolvedCount}
                      </div>
                      <div className={styles.tooltipSubTitle}>
                        Resolved Item
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                );
              }}
            />
            <Bar
              className={styles.barChart}
              fill={COLOR_ISSUE_VELOCITY_CHART_NON_HOVER}
              dataKey="openedCount"
              maxBarSize={60}
              onMouseOver={(data) => {
                setposData(data);
              }}
              onMouseLeave={() => {
                setposData({ name: '', x: -1000, y: -10000 });
              }}
              onMouseOut={() => {
                setposData({ name: '', x: -1000, y: -10000 });
              }}
            >
              {chartObj?.map((data: any, index: any) => {
                return (
                  <Cell fill={ISSUE_VELOCITY_CHART_PRIMARY_COLOR} key={index} />
                );
              })}
            </Bar>

            <Bar
              className={styles.barChart}
              fill={COLOR_ISSUE_VELOCITY_CHART_NON_HOVER}
              dataKey="resolvedCount"
              maxBarSize={60}
              onMouseOver={(data) => {
                setposData(data);
              }}
              onMouseLeave={() => {
                setposData({ name: '', x: -1000, y: -10000 });
              }}
              onMouseOut={() => {
                setposData({ name: '', x: -1000, y: -10000 });
              }}
            >
              {chartObj?.map((data: any, index: any) => {
                return (
                  <Cell
                    fill={ISSUE_VELOCITY_CHART_SECONDARY_COLOR}
                    key={index}
                  />
                );
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Row>
    </div>
  );
};
export default IssueVelocityBarChart;
