import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
} from 'react';

import { Form } from 'antd';
import { useAppSelector } from 'hooks/redux';
import Rates from 'modules/plans/components/Rates/Rates';
import BenguideBasicData from 'model/BenguideBasicData';
import {
  updateDentalPlanByPath,
  getPlanContributionByFrequency,
  getPlanContributionInProgress,
} from 'modules/plans/slices/dentalPlanSlice';
import { BenefitCategory } from 'constants/commonConstants';
import { DentalPlan } from 'model/plans/DentalPlan';

import styles from './ratesUPWrapper.module.less';

type RatesUPEditWrapperProps = {
  wrappedRef: MutableRefObject<any>;
  currentSection: string;
  dentalPlan: DentalPlan;
  setDentalPlan: Function;
};

const RatesUPEditWrapper = forwardRef((props: RatesUPEditWrapperProps, ref) => {
  const { wrappedRef, currentSection, dentalPlan, setDentalPlan } = props;
  const { dentalRates, editedDentalPlan } = useAppSelector(
    (state) => state.plan.dentalPlan
  );
  const requestType = useAppSelector(
    (state) => state.plan.dentalPlan.requestType
  );
  const [ratesForm] = Form.useForm();

  useEffect(() => {
    if (dentalRates) {
      setDentalPlan({
        ...dentalPlan,
        rates: dentalRates,
        hasSameContributions: editedDentalPlan.hasSameContributions,
      });
    }
    // eslint-disable-next-line
  }, [setDentalPlan, dentalRates, editedDentalPlan.hasSameContributions]);

  useImperativeHandle(ref, () => ({
    validate() {
      return wrappedRef.current?.validate();
    },
  }));

  return (
    <div className={styles.ratesWrapper}>
      <Rates
        benefitCategory={BenefitCategory.DENTAL.value}
        updatePlanByPathAction={updateDentalPlanByPath}
        getContributionAction={getPlanContributionByFrequency}
        benefitGuideObj={{} as BenguideBasicData}
        plan={editedDentalPlan}
        ref={wrappedRef}
        currentSection={currentSection}
        form={ratesForm}
        isDBGView={false}
        isUPEdit
        getContributionInProgress={
          requestType === getPlanContributionInProgress.type
        }
      />
    </div>
  );
});

RatesUPEditWrapper.displayName = 'RatesUPEditWrapper';
export default RatesUPEditWrapper;
