import React, { useEffect, useState } from 'react';
import loaderGif from 'assets/images/rfp-file-upload-pending.gif';

import styles from './foreverLoadingBar.module.less';

type Props = {};

const ForeverLoadingBar: React.FC<Props> = ({}) => {
  const [progress, setProgress] = useState(0);
  const [count, setCount] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress(100 * (1 - Math.pow(Math.E, -0.02 * count)));
      setCount(count + 1);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, [count]);

  return (
    <div className={styles.loaderContainer}>
      <img src={loaderGif} alt="scanning" className={styles.loadingGif} />
      <div className={styles.loadingBarContainer}>
        <div className={styles.loadingBar} style={{ width: `${progress}%` }} />
      </div>
    </div>
  );
};

export default ForeverLoadingBar;
