import * as React from 'react';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Col, Menu, Row, Select, Table, TablePaginationConfig } from 'antd';
import { cloneDeep, isEqual } from 'lodash';
import dayjs from 'dayjs';

import { useParams } from 'react-router-dom';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import { useGetClaimsFileHistoryQuery } from 'modules/claims/slices/claimsSlice';
import { useNavContext } from 'hooks/useNavContext';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { baseApi } from 'util/apiUtil';
import SearchBar from 'components/SearchBar/SearchBar';
import tableStyles from 'components/DataTable/datatable.module.less';
import selectIcon from 'assets/images/icon-caret-down.svg';
import { DataColumn } from 'components/DataTable/DataColumn';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import {
  EMPLOYER_MEMBER_RESTRICTED,
  IndividualSubTypes,
} from 'constants/commonConstants';

import { useAppSelector } from 'hooks/redux';
import styles from './claimsFileHistory.module.less';

type NoClaimsViewProps = {
  openImportClaims: Function;
  closeManageClaimsModal: Function;
  isClosed: boolean;
  openDeleteClaimsFileModal: Function;
};

const ClaimsFileHistory: FC<NoClaimsViewProps> = (props) => {
  const {
    openImportClaims,
    closeManageClaimsModal,
    isClosed,
    openDeleteClaimsFileModal,
  } = props;

  const userType = useAppSelector(
    (state) => state.auth.auth.appBootupInfo?.individualSubType
  );

  const [searchText, setSearchText] = useState<string>();
  const [fileHistory, setFileHistory] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: pageSize,
    current: currentIndex,
  });
  const [sorterValue, setSorterValue] = useState({});

  const params = useParams();
  const { employer, employerId } = useNavContext();

  const {
    data: claimsFileHistory,
    isFetching,
    isUninitialized,
    refetch: refetchFileHistory,
  } = useGetClaimsFileHistoryQuery({
    employerId: params.employerId,
  });

  useEffect(() => {
    if (!isFetching && !isUninitialized) {
      setFileHistory(claimsFileHistory);
    }
  }, [isFetching, claimsFileHistory, isUninitialized]);

  useEffect(() => {
    if (openImportClaims) {
      setSearchText('');
    }
  }, [openImportClaims, refetchFileHistory]);

  const getFileActions = (fileId: string) => {
    return (
      <Menu>
        <Menu.Item
          key="viewDetails"
          onClick={() => {
            downloadHistoryFile(fileId);
          }}
        >
          Download Claims File
        </Menu.Item>

        {userType !== IndividualSubTypes.EMPLOYER_MEMBER && (
          <Menu.Item
            key="deleteClaimFile"
            onClick={() => {
              openDeleteClaimsFileModal(fileId);
            }}
          >
            Delete Claims File
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const fileHistoryTableColumns: DataColumn[] = [
    {
      title: 'FILE NAME',
      dataIndex: 'fileName',
      key: 'fileName',
      width: '40%',
      render: (fileName: string) => {
        const updatedFileName = fileName?.replace(/\.[^/.]+$/, '');
        return (
          <span className={styles.claimsFileName}>
            <OverflowPopover popoverContent={updatedFileName}>
              {updatedFileName}
            </OverflowPopover>
          </span>
        );
      },
    },
    {
      title: 'DATE UPLOADED',
      dataIndex: 'lastUpdatedTs',
      key: 'lastUpdatedTs',
      width: '30%',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, record: any) => {
        return (
          <div>
            <Row>
              <span>{dayjs(text).format('MMMM DD YYYY hh:mma ')}</span>
            </Row>
            <Row>
              <span
                className={styles.claimsFileUploadedUser}
              >{`by ${record.lastUpdatedByLoginId}`}</span>
            </Row>
          </div>
        );
      },
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      align: 'right',

      render: (text: string, data: any) => {
        return (
          <div className={styles.dropdownWrapper}>
            <SelectDropdown overlay={getFileActions(data.id)} />
          </div>
        );
      },
    },
  ];

  const downloadHistoryFile = (fileId: string) => {
    window.location.href = `${baseApi}/v1/analytics/employers/${employerId}/file-history/${fileId}/download`;
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    const clonedFileHistory = cloneDeep(claimsFileHistory);
    if (event.target.value) {
      setFileHistory(
        clonedFileHistory.filter((file: { fileName: string }) =>
          file.fileName.toLowerCase().includes(event.target.value.toLowerCase())
        )
      );
    } else {
      setFileHistory(clonedFileHistory);
    }
  };

  useEffect(() => {
    setPagination({
      pageSize: pageSize,
      current: currentIndex,
    });
  }, [currentIndex, pageSize]);

  useEffect(() => {
    setPageSize(10);
    setCurrentIndex(1);
  }, [isClosed]);

  const importClaimsFileButton = (
    <LinkButton
      onClick={() => {
        openImportClaims();
      }}
    >
      + Import Claims File
    </LinkButton>
  );

  const employerLevelImportClaimsFileButton = usePermitByUserType(
    importClaimsFileButton,
    EMPLOYER_MEMBER_RESTRICTED
  );

  return (
    <div className={styles.manageClaimsWrapper}>
      <div className={styles.manageClaims}>
        <Row className={styles.employerName}>
          <div>{employer?.name}</div>
        </Row>
        <Row className={styles.manageClaimsTitle}>
          <Col span={12}>
            <h2>Claims History</h2>
          </Col>
          <Col span={12}>{employerLevelImportClaimsFileButton}</Col>
        </Row>
        <SearchBar
          value={searchText}
          placeholder="Search"
          onChange={handleSearch}
        />
        <Row>
          <Col xs={24} className={styles.documentTable}>
            <Table
              className={`${tableStyles.tableWrapper} ${styles.tableWrapper}`}
              dataSource={fileHistory}
              columns={fileHistoryTableColumns}
              pagination={pagination}
              loading={isFetching}
              tableLayout="fixed"
              onChange={(pagination, filter, sorter) => {
                const { current } = pagination;
                setCurrentIndex(current ?? 0);

                if (!isEqual(sorterValue, sorter)) {
                  setCurrentIndex(1);
                  setSorterValue(sorter);
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              value={pagination.pageSize}
              onChange={(e) => {
                setPageSize(e);
                setCurrentIndex(1);
              }}
              bordered={false}
              className={`${tableStyles.rowDropdown} ${styles.rowDropdown}`}
              suffixIcon={<img src={selectIcon} alt="select-icon" />}
            >
              {[10, 20, 50].map((pageSize) => (
                <Select.Option key={pageSize} value={pageSize}>
                  View: {pageSize} Rows
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row className={styles.employerName}>
          <PageActionButton
            type="primary"
            onClick={() => {
              setPageSize(10);
              setCurrentIndex(1);
              closeManageClaimsModal(false);
            }}
            className={styles.claimsManageCloseButton}
          >
            Close
          </PageActionButton>
        </Row>
      </div>
    </div>
  );
};

export default ClaimsFileHistory;
