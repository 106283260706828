import { FC, ReactNode } from 'react';

import Checkbox, { CheckboxProps } from 'antd/lib/checkbox/Checkbox';

import styles from './checkboxSelect.module.less';

interface CheckboxSelectProps extends CheckboxProps {
  children?: ReactNode;
  invalid?: boolean;
}

const CheckboxSelect: FC<CheckboxSelectProps> = (
  props: CheckboxSelectProps
) => {
  const { onChange, children, invalid = false, ...rest } = props;
  return (
    <div
      className={`${styles.checkboxContainer} ${invalid ? styles.invalid : ''}`}
    >
      <Checkbox onChange={onChange} {...rest}>
        {children}
      </Checkbox>
    </div>
  );
};

export default CheckboxSelect;
