import { FC, useEffect, useRef, useState } from 'react';
import { Col, Form } from 'antd';

import Expandable from 'components/Expandable/Expandable';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import InputForm from 'components/InputForm/InputForm';
import InvitationTemplate from './InvitationTemplate';
import styles from './sendInvitesForm.module.less';

export const SendInvitesForm: FC = () => {
  const [hasFocus, setHasFocus] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const firstRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expanded) {
      const firstRowElement = firstRowRef.current;
      if (firstRowElement) {
        setHasFocus(true);
      }
    }
  }, [expanded]);

  useEffect(() => {
    if (hasFocus && expanded) {
      const firstRowElement = firstRowRef.current;

      if (firstRowElement) {
        firstRowElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hasFocus, expanded]);

  const showHidePreview = () => {
    expanded ? setExpanded(false) : setExpanded(true);
  };

  return (
    <div ref={firstRowRef}>
      <Col>
        <LinkButton
          onClick={showHidePreview}
          block
          className={styles.previewBtn}
        >
          {!expanded ? 'Show a preview of Invitation Email' : 'Hide preview'}
        </LinkButton>
      </Col>
      <Expandable
        header=""
        isExpanded={expanded}
        onChange={(expanded) => setExpanded(true)}
      >
        <InputForm className={styles.templateWrapper}>
          <div className={styles.centerTemplate}>
            <Form.Item
              name="message"
              label="Here's what we'll send: "
              labelCol={{ span: 24 }}
              colon={false}
            >
              {/* TODO: need to change logo */}
              <InvitationTemplate firstName="John" />
            </Form.Item>
          </div>
        </InputForm>
      </Expandable>
    </div>
  );
};
