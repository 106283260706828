import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import { DataColumn } from 'components/DataTable/DataColumn';
import ToolList from 'components/ToolList/ToolList';
import {
  useGetBrokerAdminListQuery,
  useGetBrokerFeatureStatusQuery,
  useUpdateBrokerUserLimitMutation,
  useUpdateEmailUserContactMutation,
  useUpdateIndividualConfigMutation,
} from 'api/featureControl';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { getEnabledLocations } from 'util/commonUtil';
import DisableFeatureMessage from 'components/DisableFeatureMessage/DisableFeatureMessage';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import {
  INCREASE_LIMIT,
  USER_SEATS,
} from 'constants/aiAsstantConfigurationConstants';
import ContactSales from 'modules/tools/components/AIAssistantConfiguration/ContactSalesModal/ContactSales';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import { FEATURE_KEYS } from 'constants/commonConstants';
import EmployerFeatureToggle from 'components/EmployerFeatureToggle/EmployerFeatureToggle';
import TablePagination from 'model/TablePagination';
import useValidateSeats from 'modules/tools/components/AIAssistantConfiguration/ValidateSeats/ValidateSeats';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import { buildInitials } from 'util/stringUtil';
import { isNullOrUndefined } from 'modules/plans/utils';
import { EmailType } from 'modules/plans/enums/CommonTypes';
import styles from './featureList.module.less';

type BrokerAIListProps = {
  isPlatformAdmin?: boolean;
};

const BrokerAIList: FC<BrokerAIListProps> = (props: BrokerAIListProps) => {
  const { isPlatformAdmin } = props;
  const { brokerId } = useParams();
  const locationModalRef = useRef<any>();

  const { data: aiAssistantFeature, refetch: refetchBrokerFeature } =
    useGetBrokerFeatureStatusQuery({
      key: FEATURE_KEYS.AI_ASSISTANT,
      organizationId: brokerId as string,
    });

  const [updateStatus] = useUpdateIndividualConfigMutation({});
  const [updateBrokerLimit] = useUpdateBrokerUserLimitMutation({});
  const [updateSendEmail] = useUpdateEmailUserContactMutation({});

  const [searchText, setSearchText] = useState<string>('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState('name');
  const [showContactModal, setShowContactModal] = useState<boolean>(false);

  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);

  // Disabling warnings due to only need to send email ifupdate feature is success and only in enabling email

  const [brokerName, setBrokerName] = useState('');

  const [brokerCount, setBrokerCount] = useState<number>(
    aiAssistantFeature?.brokerLimit
  );

  const { SeatValidateModal, validate } = useValidateSeats();

  useEffect(() => {
    setBrokerCount(aiAssistantFeature?.brokerLimit);
  }, [aiAssistantFeature?.brokerLimit]);

  const {
    data: featureWrappers = [],
    refetch,
    isFetching,
  } = useGetBrokerAdminListQuery({
    query: searchText,
    size: pageSize,
    sort: sortOrder,
    page: pageIndex,
    featureKey: FEATURE_KEYS.BROKER_AI_ASSISTANT,
    organizationId: brokerId as string,
  });

  useEffect(() => {
    if (brokerId) {
      refetch();
    }
    // eslint-disable-next-line
  }, [brokerId]);

  const dataColumns: DataColumn[] = [
    {
      title: 'BROKER USER',
      dataIndex: 'firstName',
      key: 'firstName',
      width: '60%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.firstName.localeCompare(b.firstName),
      defaultSortOrder: 'ascend',
      render: (item, record) => {
        return (
          <OverflowPopover>
            <ProfileAvatar
              src={record?.avatarUrl}
              content={buildInitials(record?.firstName, record?.lastName)}
            />
            <span className="text table-item-link">
              {record?.firstName} {record?.lastName}
            </span>
          </OverflowPopover>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      width: '35%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      render: (item) => {
        return <>{item}</>;
      },
      align: 'left',
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'lastUpdatedTs',
      key: 'lastUpdatedTs',
      width: '50%',
      showSorterTooltip: false,
      render: (colData, record) => {
        const updatedAt = dayjs(record?.lastUpdatedTs).format(
          'MMMM D, YYYY h:mm A'
        );
        return isEmpty(colData) || isNullOrUndefined(colData) ? (
          <div>-</div>
        ) : (
          <div>
            {updatedAt}
            <br></br>
            <span className={styles.addedBy}>
              by {record?.lastUpdatedByLoginId}
            </span>
          </div>
        );
      },
    },

    {
      title: 'ENABLE FOR BROKER USER',
      dataIndex: 'enabled',
      key: 'enabled',
      width: '20%',
      showSorterTooltip: false,
      render: (colData, record, index) => {
        const enabledLocations = getEnabledLocations(record?.locationVOS);
        return (
          <EmployerFeatureToggle
            onConfirmToggle={async () => {
              setBrokerName(record?.firstName + ' ' + record?.lastName);
              locationModalRef.current?.closeDisableModal();
              const hasSeats =
                colData ||
                (await validate(
                  aiAssistantFeature.brokerLimit,
                  aiAssistantFeature.brokerCount
                ));
              if (hasSeats) {
                updateStatus({
                  featureKey: FEATURE_KEYS.BROKER_AI_ASSISTANT,
                  enabled: !colData,
                  organizationId: brokerId as string,
                  individualId: record?.individualId,
                }).then(() => {
                  if (
                    !colData &&
                    aiAssistantFeature.brokerLimit <=
                      aiAssistantFeature.brokerCount
                  ) {
                    updateSendEmail({
                      organizationId: brokerId,
                      featureKey: FEATURE_KEYS.AI_ASSISTANT,
                    });
                  }
                  refetchBrokerFeature();
                  refetch();
                });
              }
            }}
            enabled={colData}
            recordName={record.brokerName}
            enableMessage={' Are you sure you want to enable Ai Assistant?'}
            disableMessage={
              <DisableFeatureMessage
                locations={enabledLocations}
                featureKey="AI Assistant"
              />
            }
            title={'AI Assistant'}
            enableText="Enable AI-Assistant"
          />
        );
      },
      align: 'left',
    },
  ];

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();

    setSearchText(_searchText);
  };

  useEffect(() => {
    setPaginationConfig(paginationConfig);
    setPageIndex(paginationConfig.paginationIndex);
    setPageSize(paginationConfig.filterInfo.limit);
    const { field, order } = (paginationConfig.sorterInfo as any) || {};
    if (isEmpty(order)) {
      setSortOrder('');
    } else if (field == 'brokerName' && order == 'descend') {
      setSortOrder('-name');
    } else if (field == 'brokerName' && order == 'ascend') {
      setSortOrder('name');
    }
  }, [paginationConfig, isFetching]);

  const getSearchString = (isPlatformAdmin: boolean) => {
    if (isPlatformAdmin) {
      return 'Search Broker User';
    } else {
      return 'Search Broker';
    }
  };

  const isSaveBtnDisabled =
    saveButtonDisabled || brokerCount === aiAssistantFeature?.brokerLimit;

  return (
    <div className={styles.featureTableWrapper}>
      <div className={styles.employeeAiHeader}>
        <div className={styles.seatsAvailableSection}>
          {USER_SEATS}
          <div>
            <NumberFormatInput
              value={brokerCount}
              onChange={(e) => {
                setBrokerCount(+e.target.value);
                setSaveButtonDisabled(false);
              }}
              disabled={!isPlatformAdmin}
              className={
                !isPlatformAdmin ? styles.disabledInput : styles.seatsEmpInput
              }
              allowNegative={false}
              width={60}
            />
          </div>
          {isPlatformAdmin && (
            <>
              {isSaveBtnDisabled ? (
                <div className={styles.disabledArea}>
                  <b>Save</b>
                </div>
              ) : (
                <LinkButton
                  disabled={isSaveBtnDisabled}
                  onClick={() => {
                    updateBrokerLimit({
                      organizationId: brokerId as string,
                      featureKey: FEATURE_KEYS.AI_ASSISTANT,
                      brokerLimit: brokerCount,
                    }).then(() => {
                      setSaveButtonDisabled(true);
                      refetchBrokerFeature();
                    });
                  }}
                >
                  Save
                </LinkButton>
              )}
            </>
          )}
        </div>
        <div className={styles.cotactSalesDiv}>
          {INCREASE_LIMIT}
          <LinkButton
            onClick={() => {
              setShowContactModal(true);
            }}
          >
            Contact our Sales Team
          </LinkButton>
        </div>
      </div>
      <ToolList
        title="AI Assistant"
        showHeader={false}
        columns={dataColumns}
        data={featureWrappers}
        hasBenefitsCategoryFilter={false}
        search={getSearchString(isPlatformAdmin!)}
        handleSearch={handleSearch}
        isLoading={isFetching}
      ></ToolList>

      <ContactSales
        visible={showContactModal}
        onCancel={() => setShowContactModal(false)}
        onConfirm={() => {
          updateSendEmail({
            organizationId: brokerId as string,
            featureKey: FEATURE_KEYS.AI_ASSISTANT,
            triggerType: EmailType.BROKER,
          });
          setShowContactModal(false);
        }}
      />

      <SeatValidateModal employerName={brokerName} isBrokerWarning />
    </div>
  );
};

export default BrokerAIList;
