import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, notification } from 'antd';
import { isEmpty } from 'lodash';
import { useAppDispatch } from 'hooks/redux';

import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import MedicalPlan from 'model/plans/MedicalPlan';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';
import EditMedicalPlanModal from 'modules/plans/medical/components/EditMedicalPlanModal/EditMedicalPlanModal';
import OverviewHeader from 'modules/plans/components/OverviewPage/OverviewHeader/OverviewHeader';
import { findMedicalPlanById } from 'modules/plans/slices/medicalPlanSlice';
import { BenefitCategory } from 'constants/commonConstants';
import { PLAN_NOTIFICATION_KEY } from 'constants/benguideCollaborationConstants';
import { EditMdvTypes } from 'modules/plans/constants';
import styles from './rxCardInfoOverview.module.less';

type RxCardInfoOverviewProps = {
  heading: string;
  disableEditButton: boolean;
  fetchPlanWhenUpdated: boolean;
  benefitKind: string;
  medicalPlan: MedicalPlan;
  rxBinNumber?: string;
  rxPcnNumber?: string;
  rxGroupNumber?: string;
};

const RxCardInfoOverview: FC<RxCardInfoOverviewProps> = (props) => {
  const {
    heading,
    disableEditButton,
    fetchPlanWhenUpdated,
    benefitKind,
    medicalPlan,
    rxBinNumber,
    rxPcnNumber,
    rxGroupNumber,
  } = props;

  const params = useParams();
  const dispatch = useAppDispatch();

  const [viewRxCardInfoModal, setViewRxCardInfoModal] = useState(false);

  const fetchPlanOnNotificationReceived = () => {
    if (benefitKind === BenefitCategory.MEDICAL.value && params.planId) {
      dispatch(findMedicalPlanById(params.planId));
    }
  };

  function editRxCardInfo() {
    setViewRxCardInfoModal(true);
    if (fetchPlanWhenUpdated) {
      fetchPlanOnNotificationReceived();
      notification.close(PLAN_NOTIFICATION_KEY);
    }
  }

  return (
    <div>
      <Row>
        <OverviewHeader
          title={heading}
          onEditClick={() => {
            editRxCardInfo();
          }}
          disabled={disableEditButton}
        />
      </Row>
      <div className={styles.tableWrapper}>
        <InfoLabelSection
          labelText="Rx Bin #"
          value={
            <OverflowPopover>
              {!isEmpty(rxBinNumber) ? rxBinNumber : '-'}
            </OverflowPopover>
          }
        />
        <InfoLabelSection
          labelText="Rx PCN #"
          value={
            <OverflowPopover>
              {!isEmpty(rxPcnNumber) ? rxPcnNumber : '-'}
            </OverflowPopover>
          }
        />
        <InfoLabelSection
          labelText="Rx Group #"
          value={
            <OverflowPopover>
              {!isEmpty(rxGroupNumber) ? rxGroupNumber : '-'}
            </OverflowPopover>
          }
        />
      </div>
      {benefitKind === BenefitCategory.MEDICAL.value && viewRxCardInfoModal && (
        <EditMedicalPlanModal
          plan={medicalPlan}
          onClose={() => {
            setViewRxCardInfoModal(false);
          }}
          isModalVisible={viewRxCardInfoModal}
          editType={EditMdvTypes.RX_CARD_INFO}
          setViewModal={setViewRxCardInfoModal}
        />
      )}
    </div>
  );
};

export default RxCardInfoOverview;
