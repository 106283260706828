import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type IdCardStateType = {
  selectedPlanYearId?: string;
};

const initialState: IdCardStateType = {
  selectedPlanYearId: undefined,
};

const idCardSlice = createSlice({
  name: 'idCards',
  initialState,
  reducers: {
    setSelectedPlanYearId: (
      state,
      { payload }: PayloadAction<{ planYearId?: string }>
    ) => {
      state.selectedPlanYearId = payload.planYearId;
    },
  },
});

export const { setSelectedPlanYearId } = idCardSlice.actions;

export default idCardSlice.reducer;
