import axios from 'axios';
import { baseApi } from 'util/apiUtil';

export const fetchExistEnrollments = async (PlanId: string) => {
  try {
    const response = await axios.get(`${baseApi}/v1/elections/plans/${PlanId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchHsaHraDependencies = async (planId: string) => {
  try {
    const response = await axios.get(
      `${baseApi}/v1/plans/${planId}/taa-details`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
