import axios from 'axios';
import { baseApi } from 'util/apiUtil';

const baseUrl = baseApi + '/v2/documents';
const baseUrlV3 = baseApi + '/v3/documents';

const CancelToken = axios.CancelToken;
let cancelToken: any = undefined;
export const getDocuments = (
  employerId: string,
  planYearId: string,
  documentId: string | null,
  searchQuery: string | null
) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }
  cancelToken = CancelToken.source();
  return axios.get(
    `${baseUrlV3}?employerId=${employerId}&planYearIds=${planYearId}${
      documentId ? `&documentId=${documentId}` : ''
    }${searchQuery ? `&query=${searchQuery}` : '&query='}`,
    { cancelToken: cancelToken.token }
  );
};

export const validateDocument = (documentId: string) => {
  return axios.get(`${baseUrl}/${documentId}/validate`);
};

export const getDownloadUrl = (documentId: string) => {
  return `${baseUrl}/${documentId}`;
};

export const deleteDocument = (documentId: string) => {
  return axios.delete(`${baseUrl}/${documentId}`);
};

export const createFolder = (
  employerId: string,
  parentDocumentId: string,
  folderName: string
) => {
  return axios.post(
    `${baseUrl}?employerId=${employerId}&parentDocumentId=${parentDocumentId}&folderName=${folderName}`
  );
};

export const renameFolder = (
  documentId: string,
  parentDocumentId: string,
  folderName: string
) => {
  return axios.put(
    `${baseUrl}/${documentId}?name=${folderName}&parentDocumentId=${parentDocumentId}`
  );
};

export const getDocumentAvailability = (
  employerId: string,
  parentDocumentId: string,
  fileName: string
) => {
  return axios.get(
    `${baseUrl}/validate?employerId=${employerId}&parentDocumentId=${parentDocumentId}&name=${fileName}`
  );
};

export const getFolderAvailability = (
  employerId: string,
  parentDocumentId: string,
  fileName: string
) => {
  return axios.get(
    `${baseUrl}/validate?employerId=${employerId}&parentDocumentId=${parentDocumentId}&name=${fileName}&type=folder`
  );
};

export const uploadDocument = (
  employerId: string,
  parentDocumentId: string,
  isDuplicate: boolean,
  file: any
) => {
  const data = new FormData();
  data.append('file', file);
  return axios.post(
    `${baseUrl}?employerId=${employerId}&parentDocumentId=${parentDocumentId}&isDuplicate=${isDuplicate}`,
    data
  );
};

export const downloadFolder = (employerId: string, documentId: string) => {
  return axios.post(
    `${baseUrl}/${documentId}/download?employer-id=${employerId}`
  );
};
