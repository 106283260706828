import { FC } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { Col, Input, Row, Form, FormInstance } from 'antd';
import { ReactComponent as CalendarIcon } from 'assets/images/icon-calendar.svg';

import DatePickerInput from 'components/DatePickerInput/DatePickerInput';
import InputForm from 'components/InputForm/InputForm';
import {
  EMPTY_MESSAGE,
  PLAN_YEAR_REQUIRED,
} from 'modules/employers/constants/employerConstants';
import { dateFormat } from 'constants/dateFormat';
import PlanYear from 'model/PlanYear';
import { formatDateWithoutTime } from 'util/dateUtil';
import styles from './planYearForm.module.less';

const { Item } = Form;

type CreatePlanYearFormProps = {
  form: FormInstance;
  onInputChange?: any;
  disableStartDateValue?: boolean;
  disableEndDateValue?: boolean;
  isInvalidData: boolean;
  visibleAlert: boolean;
  defaultEndDatePickerValue?: Dayjs;
  editPlanYear?: PlanYear | null;
  previousPlanYearEndDate?: Dayjs | null;
  nextPlanYearStartDate?: Dayjs | null;
};

const CreatePlanYearForm: FC<CreatePlanYearFormProps> = (
  props: CreatePlanYearFormProps
) => {
  const {
    form,
    onInputChange,
    visibleAlert,
    isInvalidData,
    defaultEndDatePickerValue,
    editPlanYear,
    previousPlanYearEndDate,
    nextPlanYearStartDate,
  } = props;

  const isStartDateDisabled = (date: Dayjs): boolean => {
    const { endDate } = form.getFieldsValue() || {};
    let isDateEnabled: boolean = false;
    if (editPlanYear && endDate) {
      if (editPlanYear.endDate === formatDateWithoutTime(endDate)) {
        isDateEnabled = date && date >= dayjs(endDate);
      } else {
        isDateEnabled = date && date >= dayjs(endDate).subtract(1, 'day');
      }
    } else if (endDate) {
      isDateEnabled = date && date >= dayjs(endDate);
    }

    if (previousPlanYearEndDate) {
      return isDateEnabled || (date && date <= dayjs(previousPlanYearEndDate));
    }
    return isDateEnabled;
  };

  const isEndDateDisabled = (date: Dayjs): boolean => {
    const { startDate } = form.getFieldsValue() || {};
    let isDateEnabled: boolean = false;
    if (startDate) {
      isDateEnabled =
        date &&
        (date > dayjs(startDate).add(2, 'year') || date <= dayjs(startDate));
    }

    if (nextPlanYearStartDate) {
      return isDateEnabled || (date && date >= dayjs(nextPlanYearStartDate));
    }
    return isDateEnabled;
  };

  return (
    <InputForm
      form={form}
      onValuesChange={onInputChange}
      className={
        visibleAlert || isInvalidData
          ? styles.planYearErrors
          : styles.planYearForm
      }
    >
      <Row gutter={36}>
        <Col span={14}>
          <Item
            name="planYear"
            label="Plan Year Name"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: EMPTY_MESSAGE,
                whitespace: true,
              },
            ]}
            className={styles.label}
          >
            <Input maxLength={30} />
          </Item>
          {isInvalidData && (
            <span className={styles.errorMsg}>{PLAN_YEAR_REQUIRED}</span>
          )}
        </Col>
        <Col span={5}>
          <Item
            name="startDate"
            label="Start Date"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: EMPTY_MESSAGE,
              },
            ]}
            className={styles.label}
          >
            <DatePickerInput
              placeholder={dateFormat}
              suffixIcon={<CalendarIcon />}
              disabledDate={isStartDateDisabled}
            />
          </Item>
        </Col>
        <Col span={5}>
          <Item
            name="endDate"
            label="End Date"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: EMPTY_MESSAGE,
              },
            ]}
            className={styles.label}
          >
            <DatePickerInput
              placeholder={dateFormat}
              suffixIcon={<CalendarIcon />}
              disabledDate={isEndDateDisabled}
              defaultPickerValue={defaultEndDatePickerValue}
            />
          </Item>
        </Col>
      </Row>
    </InputForm>
  );
};

export default CreatePlanYearForm;
