import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Divider, Spin, Alert, Collapse } from 'antd';
import {
  useGetDocumentExtractionByIdQuery,
  useGetDocumentExtractionDetailsByIdQuery,
} from 'modules/plans/slices/documentExtractionSlice';
import { getTheZipFile } from 'modules/plans/services/AiSBCUploaderService';

const { Panel } = Collapse;

const ViewPlanJson = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const planId = queryParams.get('planId');

  const [planDetails, setPlanDetails] = useState<any>(null);
  const [planExtractionDetails, setPlanExtractionDetails] = useState<any>(null);
  const [documentName, setDocumentName] = useState<string | null>(null);

  const {
    data: planData,
    error: planError,
    isLoading: planLoading,
  } = useGetDocumentExtractionByIdQuery({
    id: planId ?? '',
  });

  const {
    data: planExtractionData,
    error: planExtractionError,
    isLoading: planExtractionLoading,
  } = useGetDocumentExtractionDetailsByIdQuery({
    id: planId ?? '',
  });

  useEffect(() => {
    if (planData) {
      setPlanDetails(planData);
    }
  }, [planData]);

  useEffect(() => {
    if (planExtractionData) {
      setPlanExtractionDetails(planExtractionData);
    }
  }, [planExtractionData]);

  useEffect(() => {
    if (planExtractionDetails) {
      setDocumentName(planExtractionDetails.documentName);
    }
  }, [planExtractionDetails]);

  const renderLoading = (loadingMessage: string) => (
    <Spin tip={loadingMessage} />
  );

  const renderError = (errorMessage: string) => (
    <Alert message="Error" description={errorMessage} type="error" />
  );

  const renderCollapsePanel = (header: string, key: string, jsonData: any) => (
    <Collapse>
      <Panel header={header} key={key}>
        <pre>{JSON.stringify(jsonData, null, 2)}</pre>
      </Panel>
    </Collapse>
  );

  return (
    <div>
      <h1>View Plan Json</h1>
      <Divider />
      <p>
        <strong>Document Name:</strong> {documentName}
      </p>
      <Divider />
      <p>
        <strong>Plan ID:</strong> {planId}
      </p>
      <Divider />
      <p>
        <a onClick={() => planId && getTheZipFile(planId)}>
          <strong>Download Zip (link) </strong>
        </a>
      </p>
      <Divider />
      {planLoading && renderLoading('Loading plan details...')}
      {planError && renderError('Failed to load plan details.')}
      {planDetails && (
        <>
          {renderCollapsePanel('Plan Details', '1', planDetails)}
          <Divider />
        </>
      )}
      {planExtractionLoading &&
        renderLoading('Loading plan extraction details...')}
      {planExtractionError &&
        renderError('Failed to load plan extraction details.')}
      {planExtractionDetails && (
        <>
          {renderCollapsePanel(
            'Plan Extraction Details',
            '2',
            planExtractionDetails
          )}
          <Divider />
        </>
      )}
    </div>
  );
};

export default ViewPlanJson;
export {};
