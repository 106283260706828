import { Ref, forwardRef } from 'react';
import CreateOfferInfoForm from 'modules/renewals/components/AddOfferComponents/CreateOfferInfoForm/CreateOfferInfoForm';

type BasicInfoProps = {
  nextStep: (formValues: any) => void;
  nextOnTopNav: (formValues: any) => void;
  closeModal: () => void;
  isEdit: boolean;
  offer: any;
  setCarrierImage: Function;
  isDuplicateNameError: boolean;
  offerAttachedProposal: any;
  editInitialData: any;
  setCarrierName: Function;
  setUpdatedData: Function;
  setOfferStatus: Function;
  updatedData: any;
  isClonedOffer?: boolean;
};

const BasicInfo = (props: BasicInfoProps, ref: Ref<any>) => {
  const {
    nextStep,
    nextOnTopNav,
    closeModal,
    isEdit,
    offer,
    setCarrierImage,
    isDuplicateNameError,
    offerAttachedProposal,
    editInitialData,
    setCarrierName,
    setUpdatedData,
    setOfferStatus,
    updatedData,
    isClonedOffer,
  } = props;

  return (
    <CreateOfferInfoForm
      ref={ref}
      edit={isEdit}
      isClonedOffer={isClonedOffer}
      nextStep={nextStep}
      nextOnTopNav={nextOnTopNav}
      closeModal={closeModal}
      offer={offer}
      setCarrierImage={setCarrierImage}
      isDuplicateNameError={isDuplicateNameError}
      offerAttachedProposal={offerAttachedProposal}
      editInitialData={editInitialData}
      setCarrierName={setCarrierName}
      setUpdatedData={setUpdatedData}
      setOfferStatus={setOfferStatus}
      updatedData={updatedData}
    />
  );
};

export default forwardRef(BasicInfo);
