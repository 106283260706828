import { FC, useState } from 'react';

import { Col, Row } from 'antd';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import DashBoardCard from 'components/Card/DashBoardCard';
import AddEmployerModal from 'modules/employers/components/AddEmployerModal/AddEmployerModal';
import { ReactComponent as Hand } from 'assets/images/hand.svg';
import AddBrokerModal from 'modules/brokers/pages/AddBroker/AddBrokerModal';

import {
  clearBrokerData,
  clearUpdateBrokerUpdation,
} from 'modules/brokers/slices/brokerBasicInfoSlice';

import styles from './platformDashboard.module.less';

const PlatformDashboard: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEmployerModalOpen, setIsEmployerModalOpen] =
    useState<boolean>(false);

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const dispatch = useAppDispatch();

  const setBrokerModalOpen = () => {
    dispatch(clearBrokerData());
    dispatch(clearUpdateBrokerUpdation());
    setIsModalOpen(true);
  };

  return (
    <ContentContainer>
      <Row className={styles.platformDashboard}>
        <Col xl={20}>
          <Row>
            <Col>
              <Hand className={styles.handIcon} />
            </Col>
            <Col>
              <Row className={styles.dashboardUser}>
                Hello {appBootInfo && appBootInfo?.firstName}
              </Row>
              <Row className={styles.dashboardMessage}>
                What do you want to do today?
              </Row>
            </Col>
          </Row>
          <Row className={styles.platformDashboardCardWrapper}>
            <Col xl={12}>
              <div className={styles.broker}>
                <DashBoardCard
                  className={`${styles.brokerCardWrapper} ${styles.brokerCard}`}
                  onClick={() => setBrokerModalOpen()}
                  cardHeader={'Add a Broker'}
                  isBroker={true}
                />
              </div>
            </Col>
            <Col xl={12}>
              <div className={styles.employer}>
                <DashBoardCard
                  className={`${styles.brokerCardWrapper} ${styles.employerCard}`}
                  onClick={() => setIsEmployerModalOpen(true)}
                  cardHeader={'Add an Employer'}
                  isBroker={false}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <AddBrokerModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <AddEmployerModal
        isModalOpen={isEmployerModalOpen}
        setIsModalOpen={setIsEmployerModalOpen}
      />
    </ContentContainer>
  );
};

export default PlatformDashboard;
