import React from 'react';
import { Card, Popover } from 'antd';
import { ReactComponent as YellowInfoIcon } from 'assets/images/yellow-info-icon.svg';
import styles from './draftTagOnPublishedOffer.module.less';

const DraftTagOnPublishedOffer = () => {
  return (
    <div className={styles.draftTagForSameNamePublishedOfferWrapper}>
      <Card className={styles.tag}>
        <div className={styles.text}>
          <Popover
            content="This offer has a more recent draft"
            placement="top"
            overlayClassName="popoverInner recommendedtooltip"
          >
            <YellowInfoIcon className={styles.icon} />
          </Popover>
          1 DRAFT
        </div>
      </Card>
    </div>
  );
};

export default DraftTagOnPublishedOffer;
