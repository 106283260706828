import { useState } from 'react';
import { RuleObject } from 'antd/lib/form';
import { Button, Col, Divider, Form, Input, Menu, Row } from 'antd';
import dayjs from 'dayjs';
import { FormInstance } from 'antd/es/form/Form';

import AlertMessage from 'components/Alert/AlertMessage';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import NextButton from 'components/buttons/NextButton/NextButton';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import TextButton from 'components/buttons/TextButton/TextButton';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  addBenefitClass,
  editBenefitClass,
  removeBenefitClass,
  setBasicDetails,
} from 'modules/renewals/slices/addProposalToPlanYearSlice';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import { IStepperStep } from 'modules/renewals/types/renewalsTypes';

import styles from 'modules/renewals/components/AppProposalToPlanYearComponents/PlanYearSetup/PlanYearSetup.module.less';

interface Props extends IStepperStep {}

const PlanYearSetup = ({
  onNextStep = () => {},
  onCloseModal = () => {},
}: Props) => {
  const [addBenefitClassForm] = Form.useForm();
  const [editBenefitClassForm] = Form.useForm();

  const [isAddingBenefitClass, setIsAddingBenefitClass] =
    useState<boolean>(false);
  const [isEditingBenefitClassIdx, setIsEditingBenefitClassIdx] =
    useState<number>(); // If undefined its not editing
  const [isAddBenefitClassSaveBtnEnabled, setIsAddBenefitClassSaveBtnEnabled] =
    useState<boolean>(false);
  const [
    isEditBenefitClassSaveBtnEnabled,
    setIsEditBenefitClassSaveBtnEnabled,
  ] = useState<boolean>(false);
  const [selectedBenefitClassIdx, setSelectedBenefitClassIdx] =
    useState<number>(0);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const [isPlanYearError, setIsPlanYearError] = useState<boolean>();
  const [isbenefitClassNameValid, setIsbenefitClassNameValid] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();
  const proposal = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.proposalSummary.data
  );
  const basicDetails = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.basicDetails
  );
  const { planYearsList } = useAppSelector((state) => state.employer.employer);

  const benefitClasses = basicDetails.benefitClasses.data;

  const isNextBtnEnabled = () => {
    const isValidPlanYearName = ![undefined, ''].includes(
      basicDetails.planYearName?.trim()
    );
    const isBenefitClassesPresent = (benefitClasses?.length || 0) > 0;
    return isValidPlanYearName && isBenefitClassesPresent;
  };

  const checkNameValidity = (name: string) => {
    if ([''].includes(name.trim())) {
      setIsbenefitClassNameValid(false);
    } else {
      setIsbenefitClassNameValid(true);
    }
  };

  const handleBenefitClassNameOnChange = async (idx?: number) => {
    const form = idx === undefined ? addBenefitClassForm : editBenefitClassForm;
    let isEnabled = true;
    try {
      await form.validateFields();
    } catch (error) {
      isEnabled = false;
    }
    if (idx === undefined) {
      setIsAddBenefitClassSaveBtnEnabled(isEnabled);
    } else {
      setIsEditBenefitClassSaveBtnEnabled(isEnabled);
    }
  };

  const handlePlanYearNameOnChange = (planYearName: string) => {
    setIsPlanYearError(false);
    dispatch(
      setBasicDetails({
        ...basicDetails,
        planYearName,
      })
    );
  };

  const handleAddBenefitClassCancel = (index: number | undefined) => {
    if (index) {
      setIsEditingBenefitClassIdx(undefined);
    } else {
      setIsAddingBenefitClass(false);
      addBenefitClassForm.resetFields();
    }
  };

  const handleSaveBenefitClass = async (idx?: number) => {
    const form = idx === undefined ? addBenefitClassForm : editBenefitClassForm;
    const { benefitClassName } = await form.validateFields();

    if (idx === undefined) {
      dispatch(addBenefitClass(benefitClassName.trim()));
      setIsAddBenefitClassSaveBtnEnabled(false);
      setIsAddingBenefitClass(false);
    } else {
      dispatch(editBenefitClass({ idx, name: benefitClassName.trim() }));
      setIsEditBenefitClassSaveBtnEnabled(false);
      setIsEditingBenefitClassIdx(undefined);
    }

    form.resetFields();
  };

  const handleNextStepClick = async () => {
    try {
      await validatePlanYearDoesNotExist(basicDetails?.planYearName ?? '');
      setIsAddingBenefitClass(false);
      addBenefitClassForm.resetFields();
      onNextStep();
    } catch (error) {
      setIsPlanYearError(true);
    }
  };

  const handleEditBenefitClass = (idx: number) => {
    setIsEditingBenefitClassIdx(idx);
  };

  const handleDeleteBenefitClass = (idx: number) => {
    setSelectedBenefitClassIdx(idx);
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleDeleteConfirmationModalClose = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleDeleteConfirmationModalConfirm = () => {
    dispatch(removeBenefitClass(selectedBenefitClassIdx));
    setIsDeleteConfirmationModalOpen(false);
  };

  const validateBenefitClassIsNotDuplicate = async (
    _: RuleObject,
    value: string
  ) => {
    if (
      benefitClasses
        ?.map((item) => item.label.toLowerCase().trim())
        .includes(value.toLowerCase().trim())
    )
      throw Error();
  };

  const validatePlanYearDoesNotExist = async (value: string) => {
    if (planYearsList.map((item) => item.name).includes(value)) throw Error();
  };

  const getSelectOverlay = (idx: number) => (
    <Menu>
      <Menu.Item key={'EDIT'} onClick={() => handleEditBenefitClass(idx)}>
        Edit
      </Menu.Item>
      <Menu.Item key={'DELETE'} onClick={() => handleDeleteBenefitClass(idx)}>
        Delete Benefit Class
      </Menu.Item>
    </Menu>
  );

  const getBenefitClassEditor = ({
    idx,
    form,
  }: {
    idx?: number;
    form: FormInstance<any>;
  }) => (
    <Row gutter={16}>
      <Col flex={'auto'}>
        <Form form={form}>
          <Form.Item
            name={'benefitClassName'}
            rules={[
              { required: true },
              { validator: validateBenefitClassIsNotDuplicate },
            ]}
            noStyle
          >
            <Input
              defaultValue={
                idx !== undefined && benefitClasses !== undefined
                  ? benefitClasses[idx].label
                  : ''
              }
              onChange={(e) => {
                checkNameValidity(e.target.value);
                handleBenefitClassNameOnChange(idx);
              }}
              className={styles.inputField}
              placeholder={'Benefit Class Name'}
            />
          </Form.Item>
        </Form>
      </Col>
      <Col span={3}>
        <PlanyearPopover
          isDisabled={
            idx === undefined
              ? isAddBenefitClassSaveBtnEnabled
              : isEditBenefitClassSaveBtnEnabled
          }
          content={'Benefit class names must be unique and non-empty'}
        >
          <Button
            type="primary"
            className={styles.saveBtn}
            disabled={
              idx === undefined
                ? !isAddBenefitClassSaveBtnEnabled || !isbenefitClassNameValid
                : !isEditBenefitClassSaveBtnEnabled || !isbenefitClassNameValid
            }
            onClick={() => handleSaveBenefitClass(idx)}
          >
            Save
          </Button>
        </PlanyearPopover>
      </Col>
      <Col span={3} className={styles.textBtnWrapper}>
        <TextButton
          label="Cancel"
          type="primary"
          onClick={() => handleAddBenefitClassCancel(idx)}
        />
      </Col>
    </Row>
  );

  const getEffectiveDateRange = (): string =>
    `${dayjs(proposal?.effectiveStartDate).format('ll')} - ${dayjs(
      proposal?.effectiveEndDate
    ).format('ll')}`;

  const getPlanYearNamePlaceholder = (): string =>
    `${dayjs(proposal?.effectiveStartDate).format('YYYY')} - ${dayjs(
      proposal?.effectiveEndDate
    ).format('YYYY')}`;

  return (
    <div className={styles.container}>
      {isPlanYearError && (
        <AlertMessage
          closeAlert={() => {
            setIsPlanYearError(false);
          }}
          wrapperClassName={styles.alertMessageWrapper}
          className={styles.alertMessage}
          message={'A plan year with this name already exists'}
          type="error"
        />
      )}
      <p className={styles.descriptionText}>
        Before proposal plans can be added, a new Plan Year and its respective
        Benefit Classes must be set up first.
      </p>
      <div className={styles.planYearSection}>
        <Row className={styles.headerRow} gutter={28}>
          <Col span={16} className={isPlanYearError ? styles.errorLabel : ''}>
            Plan Year Name *
          </Col>
          <Col span={8}>Effective Dates</Col>
        </Row>
        <Row gutter={28} align="middle">
          <Col span={16}>
            <Input
              className={styles.inputField}
              value={basicDetails.planYearName ?? ''}
              placeholder={getPlanYearNamePlaceholder()}
              onChange={(e) => handlePlanYearNameOnChange(e.target.value)}
            />
          </Col>
          <Col span={8}>{getEffectiveDateRange()}</Col>
        </Row>
      </div>
      <div className={styles.benefitClassSection}>
        <Row className={styles.headerRow}>Benefit Classes *</Row>
        <Divider />
        {benefitClasses?.map((item: any, idx) => (
          <div key={idx}>
            {isEditingBenefitClassIdx === idx ? (
              <div className={styles.benefitClassEditorWrapper}>
                {getBenefitClassEditor({ idx, form: editBenefitClassForm })}
              </div>
            ) : (
              <Row className={styles.headerRow}>
                <Col flex={'auto'}>{item.label}</Col>
                {item?.hideSelect ? (
                  ''
                ) : (
                  <Col span={3}>
                    <SelectDropdown overlay={getSelectOverlay(idx)} />
                  </Col>
                )}
              </Row>
            )}
            <Divider />
          </div>
        ))}
      </div>
      <div className={styles.addBenefitClassSection}>
        {isAddingBenefitClass ? (
          getBenefitClassEditor({ form: addBenefitClassForm })
        ) : (
          <TextButton
            type="primary"
            className={styles.textBtnWrapper}
            label="+ Add Another Benefit Class"
            onClick={() => setIsAddingBenefitClass(true)}
          />
        )}
      </div>
      <NextButton
        className={styles.nextBtn}
        disabled={!isNextBtnEnabled()}
        nextStep={() => handleNextStepClick()}
      />
      <div className={styles.modalCancelBtnWrapper}>
        <TextButton
          label="Cancel"
          type="primary"
          className={styles.cancelButton}
          onClick={() => onCloseModal(true)}
        />
      </div>
      <ConfirmationDialog
        title="Remove Benefit Class"
        confirmText={`Yes - Remove ${benefitClasses?.[selectedBenefitClassIdx]?.label}`}
        cancelText="Cancel"
        closeModal={handleDeleteConfirmationModalClose}
        onConfirm={handleDeleteConfirmationModalConfirm}
        visible={isDeleteConfirmationModalOpen}
      >
        <p className={styles.warningConfirmation}>
          Are you sure you want to remove?
        </p>
      </ConfirmationDialog>
    </div>
  );
};

export default PlanYearSetup;
