import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'app/store';
import BrokerAdmin from 'model/BrokerAdmin';
import {
  getAllBrokerAdmins,
  postBrokerAdmin,
  putBrokerAdmin,
  uploadAdminAvatar,
} from 'modules/brokers/services/BrokerAdminService';
import ErrorDetails from 'model/ErrorDetails';
import { clearBrokerObject } from 'modules/brokers/slices/brokerBasicInfoSlice';
import { getBlobFromEncodedImageString } from 'util/fileUtil';

export interface BrokerAdminState {
  inProgress: boolean;
  brokerAdminCreationInProgress: boolean;
  brokerAdmins: BrokerAdmin[];
  brokerAdminObj: BrokerAdmin | null;
  error: ErrorDetails | null;
  adminAvatar: {
    inProgress: boolean;
    error: ErrorDetails | null;
    data: string | null;
  };
  avatarImageMap: {
    [id: string]: { croppedAvatar: string; originalAvatar: string };
  };
  originalAvatarUrl: string | null;
}
const initialState: BrokerAdminState = {
  inProgress: false,
  brokerAdminCreationInProgress: false,
  brokerAdmins: [],
  brokerAdminObj: null,
  error: null,
  adminAvatar: {
    inProgress: false,
    error: null,
    data: null,
  },
  avatarImageMap: {},
  originalAvatarUrl: null,
};

const brokerAdminSlice = createSlice({
  name: 'brokerAdmins',
  initialState,
  reducers: {
    getAdminListStarted: (state) => {
      state.inProgress = true;
    },
    getAdminListFailed: (state, { payload }) => {
      state.inProgress = false;
      state.error = { data: payload?.data };
    },
    getAdminListSuccess: (state, { payload }) => {
      state.inProgress = false;
      state.brokerAdmins = payload;
    },
    addBrokerAdminStarted: (state) => {
      state.brokerAdminCreationInProgress = true;
    },
    addBrokerAdminFailed: (state, { payload }) => {
      state.brokerAdminCreationInProgress = false;
      state.error = { data: payload?.data };
    },
    addBrokerAdminSuccess: (state, { payload }) => {
      state.brokerAdminCreationInProgress = false;
      state.brokerAdminObj = payload;
    },
    updateBrokerAdminStarted: (state) => {
      state.brokerAdminCreationInProgress = true;
    },
    updateBrokerAdminFailed: (state, { payload }) => {
      state.brokerAdminCreationInProgress = false;
      state.error = { data: payload?.data };
    },
    updateBrokerAdminSuccess: (state, { payload }) => {
      state.brokerAdminCreationInProgress = false;
      state.brokerAdminObj = payload;
    },
    uploadAdminAvatarStarted: (state) => {
      state.adminAvatar.inProgress = true;
    },
    uploadAdminAvatarSuccess: (state, { payload }) => {
      state.adminAvatar.inProgress = false;
      state.adminAvatar.data = payload;
    },
    uploadAdminAvatarFailed: (state, { payload }) => {
      state.adminAvatar.inProgress = false;
      state.adminAvatar.error = { data: payload?.data };
    },
    clearAdminAvatar: (state) => {
      state.adminAvatar.inProgress = false;
      state.adminAvatar.data = null;
      state.adminAvatar.error = null;
      state.originalAvatarUrl = null;
    },
    updateOriginalAvatarUrl(state, action: PayloadAction<string>) {
      state.originalAvatarUrl = action.payload;
    },
    updateAdminAvatarMap: (
      state,
      action: PayloadAction<{ id: string; image: string; originalImg: string }>
    ) => {
      const { id, image, originalImg } = action.payload;
      state.avatarImageMap[id] = {
        croppedAvatar: image,
        originalAvatar: originalImg,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearBrokerObject, (state) => {
      state.avatarImageMap = {};
      state.adminAvatar = initialState.adminAvatar;
      state.originalAvatarUrl = null;
    });
  },
});

export const {
  getAdminListStarted,
  getAdminListFailed,
  getAdminListSuccess,
  addBrokerAdminStarted,
  addBrokerAdminFailed,
  addBrokerAdminSuccess,
  updateBrokerAdminStarted,
  updateBrokerAdminSuccess,
  updateBrokerAdminFailed,
  uploadAdminAvatarStarted,
  uploadAdminAvatarSuccess,
  uploadAdminAvatarFailed,
  clearAdminAvatar,
  updateAdminAvatarMap,
  updateOriginalAvatarUrl,
} = brokerAdminSlice.actions;

export default brokerAdminSlice.reducer;

export const getBrokerAdminList = (id: string) => (dispatch: any) => {
  dispatch(getAdminListStarted());
  getAllBrokerAdmins(id)
    .then(({ data }) => {
      dispatch(getAdminListSuccess(data));
    })
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        dispatch(
          getAdminListFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    });
};

export const addBrokerAdmin = (brokerAdmin: BrokerAdmin) => (dispatch: any) => {
  dispatch(addBrokerAdminStarted());
  postBrokerAdmin(brokerAdmin)
    .then(({ data }) => {
      dispatch(addBrokerAdminSuccess(data));
    })
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        dispatch(
          addBrokerAdminFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    });
};

export const updateBrokerAdmin =
  (brokerAdmin: BrokerAdmin) => (dispatch: any) => {
    dispatch(updateBrokerAdminStarted());
    putBrokerAdmin(brokerAdmin)
      .then(({ data }) => {
        dispatch(updateBrokerAdminSuccess(data));
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          dispatch(
            updateBrokerAdminFailed(JSON.parse(JSON.stringify(error.response)))
          );
        } else {
          console.error(error);
        }
      });
  };

export const selectBrokerAdmins = (state: RootState) =>
  state.brokers.brokerAdmins;

export const uploadBrokerAdminAvatar =
  (image: string, originalImage: string) => async (dispatch: any) => {
    if (image) {
      dispatch(uploadAdminAvatarStarted());
      try {
        if (originalImage) {
          const originalImgBlob = await getBlobFromEncodedImageString(
            originalImage
          );
          const originalImageRes = await uploadAdminAvatar(originalImgBlob);
          dispatch(updateOriginalAvatarUrl(originalImageRes.data.avatarUrl));
        }
        const imageBlob = await getBlobFromEncodedImageString(image);
        const response = await uploadAdminAvatar(imageBlob);
        dispatch(uploadAdminAvatarSuccess(response.data.avatarUrl));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          dispatch(
            uploadAdminAvatarFailed(JSON.parse(JSON.stringify(error.response)))
          );
        } else {
          console.error(error);
        }
      }
    }
  };
