import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Badge, Col, notification, Row, Spin } from 'antd';
import { StarFilled } from '@ant-design/icons';
import reactHtmlParser from 'react-html-parser';
import { usePermitIf } from 'hooks/usePermitIf';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  useCreateOfferNoteMutation,
  useEditRateDetailsMutation,
  useLazyGetIndividualOfferNotesForIdQuery,
  useLazyGetPlanDetailsQuery,
  usePublishOrDraftSingleOfferMutation,
  useUpdateOfferNoteAsViewedMutation,
  useUpdateOfferStatusAsRecommendedMutation,
} from 'modules/renewals/slices/offersSlice';
import { clear } from 'modules/renewals/slices/carrierQuoteAiSlice';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import PlanTable from 'modules/renewals/components/PlanTable/PlanTable';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import { allowedCommonButtonPermittedTypes } from 'constants/permittedConstants';
import OfferStatusTag from 'components/OfferStatusTag/OfferStatusTag';
import OfferCardOverview from 'modules/renewals/components/helperComponents/OfferCardOverview/OfferCardOverview';
import FromCurrentPercentage from 'modules/renewals/components/helperComponents/FromCurrentPercentage/FromCurrentPercentage';
import {
  ADD_PLAN_OPTIONS,
  CARRIER_DOCUMENTS,
  CREDIT_DROPDOWN_LABELS,
  NAME_FIELD_PROPERTY,
  OFFER_BENEFIT_TYPE_NAVIGATION_LABELS,
  OFFER_BENEFIT_TYPES,
  OFFER_PLAN_PROPERTY,
  OFFER_STATUS,
  OFFER_TAGS_DTQ,
  OFFER_TAGS_PENDING,
  OfferType,
} from 'modules/renewals/constants/renewalsConstants';
import {
  formatHeaderValues,
  getRateDetails,
  isDtqOrPending,
} from 'modules/renewals/utils/renewalsUtils';
import { currencyFormatter } from 'util/commonUtil';
import ViewCreditDescription from 'modules/renewals/components/helperComponents/ViewCreditDescription/ViewCreditDescription';
import CreditInclusionPopup from 'modules/renewals/components/helperComponents/CreditInclusionPopup/CreditInclusionPopup';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import ActionDropdown from 'modules/renewals/components/helperComponents/ActionDropdown/ActionDropdown';
import CarrierDocuments from 'modules/renewals/components/helperComponents/CarrierDocuments/CarrierDocuments';
import { ReactComponent as CaretDownIcon } from 'assets/images/icon-caret-down-black.svg';
import IconSuccess from 'assets/images/checkmark.svg';
import DownloadIcon from 'assets/images/icon-download-black.svg';
import QuoteProcessStatus from 'modules/renewals/enums/QuoteUploadStatus';
import useDocumentExtractionReviewModal from 'hooks/useDocumentExtractionReviewModal';
import AddOfferModal from 'modules/renewals/components/AddOfferModal/AddOfferModal';
import { loginTypes } from 'constants/authConstants';
import { ReactComponent as InternalNote } from 'assets/images/internal-note.svg';
import { getPlanTableColumnLabels } from 'modules/renewals/utils/planTableLabelUtil';
import Drawer from 'components/Drawer/Drawer';
import NotesCommonContent from 'modules/renewals/components/NotesCommonContent/NotesCommonContent';
import { baseApi } from 'util/apiUtil';
import { CommonNoteDto } from 'modules/renewals/models/CommonNoteDto';
import { OfferNoteDto } from 'modules/renewals/models/OfferNoteDto';
import {
  EMPLOYER_MEMBER_RESTRICTED,
  IndividualSubTypes,
} from 'constants/commonConstants';
import { ReviewType } from 'modules/renewals/enums/ReviewType';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import styles from './planDetails.module.less';

const PlanDetails = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;
  const isBrokerAdmin: boolean = appBootInfo?.type === loginTypes.bokerAdmin;
  const isPlatformAdmin: boolean = appBootInfo?.type === loginTypes.platform;
  const isOpsAdmin: boolean = appBootInfo?.isOpsAdmin ?? false;
  const [isCreditDescriptionModalOpen, setCreditDescriptionModalOpen] =
    useState<boolean>(false);
  const [creditModes, setCreditModes] = useState<string[]>([]);
  const [selectedCreditDropDown, setSelectedCreditDropDown] = useState<any>(0);
  const [isCreditsIncludedModalVisible, setIsCreditsIncludedModalVisible] =
    useState<boolean>(false);
  const [isCreditsExcludedModalVisible, setIsCreditsExcludedModalVisible] =
    useState<boolean>(false);
  const [offerStatusField, setOfferStatusField] = useState<string | null>(null);
  const [isRecommendedStatusMarked, setRecommendedStatusMarked] =
    useState<boolean>(false);
  const [updateOfferStatusAsRecommended, { isSuccess: markedAsRecommended }] =
    useUpdateOfferStatusAsRecommendedMutation();
  const [isCarrierDocumentsVisible, setIsCarrierDocumentsVisible] =
    useState<boolean>(false);
  const [editOfferVisible, setEditOfferVisible] = useState<boolean>(false);
  const location: any = useLocation();
  const offerStatus: string = location.state
    ? location.state.status
    : OFFER_STATUS.PUBLISHED;
  const [isNotesVisible, setIsNotesVisible] = useState<boolean>(false);
  const [isCloneOffer, setCloneOffer] = useState<boolean>();
  const [rateDetails, setRateDetails] = useState<string>('');
  const planYearId: string | null =
    location.state?.planYearId ?? location.state?.planYear ?? null;

  const isEmpMember =
    appBootInfo?.individualSubType === IndividualSubTypes.EMPLOYER_MEMBER;

  const [
    createOfferInternalNote,
    {
      isLoading: isCreateOfferNoteLoading,
      isSuccess: isCreateOfferNoteSuccess,
    },
  ] = useCreateOfferNoteMutation();

  const [
    getIndividualOfferNotesForOfferId,
    {
      data: offerNotes,
      isLoading: offerNoteDataIsLoading,
      isSuccess: offerNoteDataIsSuccess,
    },
  ] = useLazyGetIndividualOfferNotesForIdQuery();

  const [
    updateOfferNoteAsViewed,
    {
      isLoading: isUpdateOfferNoteAsViewedLoading,
      isSuccess: isUpdateOfferNoteAsViewedSuccess,
    },
  ] = useUpdateOfferNoteAsViewedMutation();

  const [publishOrDraftSingleOffer, { isSuccess: isPublishOrDraftSuccess }] =
    usePublishOrDraftSingleOfferMutation();

  const [
    getPlanDetails,
    {
      data: planDetailsData,
      isFetching: planDetailsIsFetching,
      isSuccess: planDetailsIsSuccess,
    },
  ] = useLazyGetPlanDetailsQuery();

  useEffect(() => {
    if (!editOfferVisible) {
      setCloneOffer(false);
      dispatch(clear());
    }
  }, [dispatch, editOfferVisible]);

  const selectedCreditMode = useAppSelector(
    (state) => state?.renewals.offers.selectedCreditMode
  );

  useEffect(() => {
    if (isCreateOfferNoteSuccess) {
      getIndividualOfferNotesForOfferId({ offerId: params?.offerId ?? '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOfferNoteSuccess, isCreateOfferNoteLoading]);

  useEffect(() => {
    if (isUpdateOfferNoteAsViewedSuccess) {
      setIsNotesVisible(false);
      getPlanDetails({
        offerId: params?.offerId ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateOfferNoteAsViewedLoading, isUpdateOfferNoteAsViewedSuccess]);

  useEffect(() => {
    if (markedAsRecommended) {
      notification.open({
        message: 'Successfully Updated',
        description: isRecommendedStatusMarked
          ? 'The offer has been successfully marked as Recommended'
          : 'The offer has been successfully removed from Recommended offers',
        placement: 'topRight',
        duration: 3,
        className: styles.notification,
        closeIcon: <></>,
        key: 'locationUpdated',
        icon: (
          <img className={styles.notificationIcon} src={IconSuccess} alt="" />
        ),
      });
    }
    // eslint-disable-next-line
  }, [markedAsRecommended]);

  const [editRateDetails, { isLoading: editRateIsLoading }] =
    useEditRateDetailsMutation();

  useEffect(() => {
    if (!isCarrierDocumentsVisible) {
      getPlanDetails({
        offerId: params?.offerId ?? '',
      });
    }
  }, [
    params?.offerId,
    getPlanDetails,
    isCarrierDocumentsVisible,
    isPublishOrDraftSuccess,
  ]);

  const handleNotesDrawerClose = () => {
    updateOfferNoteAsViewed({
      offerId: params?.offerId ?? '',
      individualId: String(appBootInfo?.individualId),
    });
  };

  const quoteProcessingStatus =
    ![QuoteProcessStatus.SAVED, QuoteProcessStatus.REVIEWED].includes(
      planDetailsData?.extractionDetails?.status as any
    ) &&
    planDetailsData?.planCreationType === ADD_PLAN_OPTIONS.CARRIER_QUOTE.key;

  const handleFileItemDownloadClickForInternalNote = (
    s3Key: string,
    item: string
  ) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const url = `${baseApi}/v1/renewals/offers/offer-notes/download?s3-key=${s3Key}&file-name=${item}`;
    a.href = url;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleCreateOfferNoteSubmit = (offerNote: CommonNoteDto) => {
    const offerNoteDto: OfferNoteDto = {
      offerId: params?.offerId ?? '',
      comment: offerNote.commentTxt,
      files: offerNote.fileList,
      organizationId: String(params?.brokerId),
      individualId: String(appBootInfo?.individualId),
    };

    createOfferInternalNote({ offerNote: offerNoteDto });
  };

  useEffect(() => {
    getPlanDetails({
      offerId: params?.offerId ?? '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.offerId, markedAsRecommended, getPlanDetails]);

  const getIsNavigateToOfferList = useCallback(() => {
    const isSelfReviewQuote =
      planDetailsData?.extractionDetails?.reviewType === ReviewType.AUTOMATIC;
    const isQuoteProcessingStatusSuccess =
      planDetailsData?.extractionDetails?.status === QuoteProcessStatus.SUCCESS;
    return isSelfReviewQuote && !isOpsAdmin && isQuoteProcessingStatusSuccess;
  }, [
    isOpsAdmin,
    planDetailsData?.extractionDetails?.status,
    planDetailsData?.extractionDetails?.reviewType,
  ]);

  const handleOpenOfferModal = () => {
    setEditOfferVisible(true);
  };

  const handleNavigateSelfToOfferListWhenOpsInProgress = () => {
    navigate(
      `/brokers/${params?.brokerId}/employers/${
        params?.employerId
      }/renewals/carrier/${planDetailsData?.benefitCategory?.toLowerCase()}`,
      {
        state: {
          quoteProcessingStatus: planDetailsData?.extractionDetails?.status,
          offerId: planDetailsData?.offerId,
        },
      }
    );
  };

  /**
   * Custom hook for handling QuoteReview modal.
   * This hook abstracts the logic for opening a modal based on URL parameters,
   */
  useDocumentExtractionReviewModal(
    isOpsAdmin,
    dispatch,
    handleOpenOfferModal,
    true,
    getIsNavigateToOfferList(),
    handleNavigateSelfToOfferListWhenOpsInProgress
  );

  const setCreditsDropdown = () => {
    const mappedSet: any[] = CREDIT_DROPDOWN_LABELS.map(
      (creditMode: string, index: number) => {
        return {
          label: creditMode,
          value: index.toString(),
        };
      }
    );

    setCreditModes(mappedSet);
  };

  useEffect(() => {
    setCreditsDropdown();
  }, []);

  useEffect(() => {
    if (planDetailsData) {
      setOfferStatusField(planDetailsData.offerStatus);
    }
  }, [setOfferStatusField, planDetailsData]);

  useEffect(() => {
    setRateDetails(planDetailsData?.rate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planDetailsData]);

  const getOffersUrl = () => {
    return `/brokers/${params.brokerId}/employers/${
      params.employerId
    }/renewals/carrier/${
      OFFER_BENEFIT_TYPE_NAVIGATION_LABELS?.[
        planDetailsData?.benefitCategory ?? ''
      ]
    }`;
  };

  const isPlaceHolderOffer = (): boolean => {
    return planDetailsData?.isPlaceholderCreated;
  };

  const navigation = {
    name: `All ${
      OFFER_BENEFIT_TYPES?.[
        planDetailsData?.benefitCategory as keyof typeof OFFER_BENEFIT_TYPES
      ]
    } Offers`,
    link: getOffersUrl(),
  };
  const navigations: { name: string; link: string }[] = [];
  navigations[0] = navigation;

  const SecuredBreadcrumb = usePermitIf(
    <BreadcrumbNavigation breadCrumbData={navigations} />,
    allowedCommonButtonPermittedTypes,
    []
  );

  const arrangeColData = useMemo(() => {
    return getPlanTableColumnLabels({
      benefitType: planDetailsData?.benefitCategory,
      plans:
        planDetailsData?.[
          OFFER_PLAN_PROPERTY?.[planDetailsData?.benefitCategory]
        ],
      addFooterRow: false,
      addEnrollmentsForPremiums: false,
      showErrorHighlight: false,
      isNTier: false,
      isComparePlans: false,
      isPlanDetails: true,
      fundingType: planDetailsData?.fundingType ?? '',
    });

    // eslint-disable-next-line
  }, [planDetailsData]);

  const creditsFooter = () => {
    if (planDetailsData?.discountAndCreditDescription !== null) {
      return (
        <span
          className={styles.footerLink}
          onClick={() => setCreditDescriptionModalOpen(true)}
        >
          View Description
        </span>
      );
    }
  };

  const changeCreditMode = (value: any) => {
    setSelectedCreditDropDown(value);
    if (value == 0) {
      setIsCreditsExcludedModalVisible(true);
    } else {
      setIsCreditsIncludedModalVisible(true);
    }
  };

  const totalAnnualPremiumFooter = () => {
    return (
      <span className={styles.footerLink}>
        <SingleSelectFilter
          data={creditModes}
          placeholder={'-'}
          showSearch={false}
          setFilterSelectedValue={(value: any) => changeCreditMode(value)}
          defaultValue={selectedCreditMode.toString()}
          selectedValue={selectedCreditMode.toString()}
          applicableIcon={<CaretDownIcon />}
          inlineDropdown={true}
          isDisabled={false}
          isWiderWrapper={true}
          isSelectionHighlightAndTickApplicable={false}
        />
      </span>
    );
  };

  const getDtqOrPendingHeader = () => {
    return offerStatusField === OFFER_TAGS_DTQ
      ? 'Carrier Declined to Quote'
      : 'Offer is Pending';
  };

  const getDtqOrPendingSubheader = () => {
    return offerStatusField === OFFER_TAGS_DTQ
      ? 'This carrier is not providing a quote for this Offer'
      : 'Awaiting Offer details from this carrier';
  };

  const changeOfferStatus = (offerId: string, recommended: boolean) => {
    setRecommendedStatusMarked(recommended);
    updateOfferStatusAsRecommended({
      offerId: offerId,
      isRecommended: recommended,
    });
  };

  const isPlanYearApplicable = (): boolean => {
    return !!planYearId;
  };

  const changeOfferPublishStatus = (offerId: string, status: string) => {
    publishOrDraftSingleOffer({
      offerId: offerId,
      saveStatus: status,
    });
  };

  const onRateDetailsSave = async (rate: string) => {
    await editRateDetails({
      offerId: params?.offerId ?? '',
      rate: encodeURIComponent(rate),
    });
    getPlanDetails({
      offerId: params?.offerId ?? '',
    });
  };

  const carrierDocumentArea = (
    <Col span={8}>
      <span>
        <img src={DownloadIcon} alt=""></img>
      </span>
      <span
        className={styles.carrierDocuments}
        onClick={() => setIsCarrierDocumentsVisible(true)}
      >
        {CARRIER_DOCUMENTS}
      </span>
    </Col>
  );

  const securedCarrierDocument = usePermitByUserType(
    carrierDocumentArea,
    EMPLOYER_MEMBER_RESTRICTED
  );

  if (planDetailsIsFetching || !planDetailsIsSuccess) {
    return <Spin />;
  }

  return (
    <div className={styles.planDetailWrapper}>
      <Row className={styles.rowWrapper}>
        <div className={styles.headerWrapper}>
          <Row>
            <div className={styles.backButton}>{SecuredBreadcrumb}</div>
          </Row>
          <div className={styles.title}>
            <Row>
              <span className={styles.titleWrapper}>
                {planDetailsData?.name}{' '}
              </span>
              {quoteProcessingStatus && (
                <span className={styles.tagWrapper}>
                  <OfferStatusTag offerStatus={'QUOTE_PROCESSING'} />
                </span>
              )}
              {planDetailsData?.type === OfferType.RENEWAL ? (
                <span className={styles.tagWrapper}>
                  <OfferStatusTag offerStatus={'CURRENT'} />
                </span>
              ) : (
                ''
              )}
              {offerStatusField === OFFER_TAGS_DTQ ? (
                <span className={styles.tagWrapper}>
                  <OfferStatusTag offerStatus={'DTQ'} />
                </span>
              ) : (
                ''
              )}
              {offerStatusField === OFFER_TAGS_PENDING ? (
                <span className={styles.tagWrapper}>
                  <OfferStatusTag offerStatus={'PENDING'} />
                </span>
              ) : (
                ''
              )}
              {planDetailsData?.isRecommended ? (
                <span className={styles.tagWrapper}>
                  <OfferStatusTag
                    offerStatus={'RECOMMENDED'}
                    icon={<StarFilled />}
                  />
                </span>
              ) : (
                ''
              )}

              <div className={styles.headerButtons}>
                {!isErAdmin && (
                  <PageActionButton
                    type="primary"
                    className={styles.actionButton}
                    disabled={isPlanYearApplicable()}
                    onClick={() => {
                      setEditOfferVisible(true);
                    }}
                  >
                    Edit Offer
                  </PageActionButton>
                )}
                {!isErAdmin && (
                  <ActionDropdown
                    offer={planDetailsData}
                    setOfferStatusField={setOfferStatusField}
                    onOfferRecommendedStateChange={(recommended: boolean) => {
                      const offer = planDetailsData as any;
                      changeOfferStatus(offer?.offerId, recommended);
                    }}
                    offerStatusField={offerStatusField}
                    isPlanDetails={true}
                    offerClone={() => {
                      setCloneOffer(true);
                      setEditOfferVisible(true);
                    }}
                    loadData={() => {
                      getPlanDetails({
                        offerId: params?.offerId ?? '',
                      });
                    }}
                    activeStatus={offerStatus}
                    isEditableCreateDisabled={isPlanYearApplicable()}
                    changeOfferPublishStatus={changeOfferPublishStatus}
                    fromDetailsPage={true}
                    isChangeStyle={isErAdmin}
                  />
                )}
              </div>
            </Row>
          </div>
        </div>
      </Row>

      <Row justify={'space-between'} gutter={24}>
        {planDetailsData?.benefitCategory !== 'VOLUNTARY_BENEFIT' && (
          <>
            <Col span={5}>
              <OfferCardOverview
                valueSizeMagnified={true}
                headerText={'Total Est. Annual Premium'}
                value={formatHeaderValues(
                  selectedCreditMode == 0
                    ? planDetailsData?.totalAnnualPremium
                    : planDetailsData?.totalAnnualPremiumWithCredit,
                  'CURRENCY',
                  false
                )}
                footerElement={totalAnnualPremiumFooter()}
                isDtqOrPending={isDtqOrPending(offerStatusField)}
              />
            </Col>
            <Col span={5}>
              <OfferCardOverview
                valueSizeMagnified={true}
                headerText={'From Current'}
                value={
                  !isPlaceHolderOffer()
                    ? formatHeaderValues(
                        selectedCreditMode == 0
                          ? planDetailsData?.fromCurrent
                          : planDetailsData?.fromCurrentWithCredit,
                        'CURRENCY',
                        true
                      )
                    : formatHeaderValues(
                        selectedCreditMode == 0
                          ? planDetailsData?.totalAnnualPremium
                          : planDetailsData?.totalAnnualPremiumWithCredit,
                        'CURRENCY',
                        false
                      )
                }
                footerElement={
                  <Row>
                    {!isPlaceHolderOffer() &&
                      !planDetailsData?.noCurrentPlans && (
                        <FromCurrentPercentage
                          percentage={
                            selectedCreditMode == 0
                              ? planDetailsData?.fromCurrentPercentage
                              : planDetailsData?.fromCurrentPercentageWithCredit
                          }
                          decimalPlaces={2}
                        />
                      )}
                    {isPlaceHolderOffer() || planDetailsData?.noCurrentPlans ? (
                      <span className={styles.offset}>N/A</span>
                    ) : (
                      ''
                    )}
                    <span className={styles.fromCurrent}>from Current</span>
                  </Row>
                }
                isDtqOrPending={isDtqOrPending(offerStatusField)}
              />
            </Col>
            <Col span={5}>
              <OfferCardOverview
                valueSizeMagnified={true}
                headerText={'Credits'}
                value={
                  planDetailsData?.discountAndCredit
                    ? currencyFormatter(
                        Math.round(planDetailsData?.discountAndCredit),
                        undefined,
                        undefined,
                        0
                      )
                    : '-'
                }
                footerElement={creditsFooter()}
                isDtqOrPending={isDtqOrPending(offerStatusField)}
              />
            </Col>
          </>
        )}
        <Col span={5}>
          <OfferCardOverview
            valueSizeMagnified={true}
            headerText={'Rate Details'}
            value={getRateDetails(rateDetails)}
            footerElement={<span></span>}
            isDtqOrPending={isDtqOrPending(offerStatusField)}
            editIcon={!isEmpMember}
            setValue={setRateDetails}
            stringValue={rateDetails}
            saveEdited={onRateDetailsSave}
            editTitle={'Add Rate Details'}
            editBody={'Enter Rate Cap or Rate Guarantee'}
            isLoading={editRateIsLoading}
            showEllipsis={true}
          />
        </Col>
      </Row>

      <div className={styles.descriptionWrapper}>
        <Row>
          <Col span={8}>
            <div className={styles.subTitle}>Offer Description</div>
          </Col>
          {(isBrokerAdmin || isPlatformAdmin) && (
            <Col className={styles.internalNotesContainer} span={16}>
              <p
                className={styles.brokerNotes}
                onClick={() => {
                  getIndividualOfferNotesForOfferId({
                    offerId: params?.offerId ?? '',
                  });
                  setIsNotesVisible(true);
                }}
              >
                {planDetailsData?.isNewNotesExist && (
                  <Badge color={'red'} className={styles.newComment} />
                )}
                <InternalNote className={styles.internalNoteIcon} />
                Broker Notes ({planDetailsData?.internalNoteCount})
              </p>
            </Col>
          )}
        </Row>
        <div className={styles.description}>
          {isEmpty(planDetailsData?.description)
            ? '-'
            : reactHtmlParser(planDetailsData?.description ?? '-')}
        </div>
      </div>

      {quoteProcessingStatus && (
        <div className={styles.planTableWrapper}>
          <div className={styles.subTitle}>Plan Details</div>
          <h2 className={styles.dtqOrPendingHeader}>Quote Processing</h2>
          <p className={styles.dtqOrPendingSubheader}>
            Awaiting Offer details; please check back later
          </p>
        </div>
      )}

      {!quoteProcessingStatus ? (
        isDtqOrPending(offerStatusField) ? (
          <div className={styles.planTableWrapper}>
            <div className={styles.subTitle}>Plan Details</div>
            <h2 className={styles.dtqOrPendingHeader}>
              {getDtqOrPendingHeader()}
            </h2>
            <p className={styles.dtqOrPendingSubheader}>
              {getDtqOrPendingSubheader()}
            </p>
          </div>
        ) : (
          <>
            <div className={styles.planTableWrapper}>
              <div className={styles.subTitle}>Plan Details</div>
              <Row>
                {securedCarrierDocument}
                {<Col span={8} offset={22} />}
              </Row>
              <PlanTable
                data={
                  planDetailsData?.[
                    OFFER_PLAN_PROPERTY?.[planDetailsData?.benefitCategory]
                  ]
                }
                labelColumnData={arrangeColData}
                titleProperty={NAME_FIELD_PROPERTY}
                logoProperty={planDetailsData?.carrier?.logoUrl}
                benefitType={planDetailsData?.benefitCategory}
                offer={planDetailsData}
                offerStatus={offerStatus}
                isCompareSimilarPlansApplicable={true}
                isPlanDetails={true}
              />
            </div>
            <ViewCreditDescription
              carrier={planDetailsData?.carrier.name}
              isModalVisible={isCreditDescriptionModalOpen}
              credit={planDetailsData?.discountAndCredit}
              creditDescription={planDetailsData?.discountAndCreditDescription}
              handleClose={setCreditDescriptionModalOpen}
            />
            <CreditInclusionPopup
              selectedCreditDropDown={selectedCreditDropDown}
              isCreditsExcludedModalVisible={isCreditsExcludedModalVisible}
              isCreditsIncludedModalVisible={isCreditsIncludedModalVisible}
              setIsCreditsExcludedModalVisible={
                setIsCreditsExcludedModalVisible
              }
              setIsCreditsIncludedModalVisible={
                setIsCreditsIncludedModalVisible
              }
            />
            <CarrierDocuments
              isModalVisible={isCarrierDocumentsVisible}
              setIsModalVisible={setIsCarrierDocumentsVisible}
              offerId={planDetailsData?.offerId}
              carrierQuote={planDetailsData?.carrierQuote}
              otherDocumentsFile={planDetailsData?.otherDocumentsFile}
            />
          </>
        )
      ) : (
        ''
      )}

      <AddOfferModal
        isModalOpen={editOfferVisible}
        setIsModalOpen={setEditOfferVisible}
        isEdit={true}
        onAddOffer={() => {}}
        offerId={planDetailsData?.offerId}
        isClonedOffer={isCloneOffer}
      />
      <Drawer
        visible={isNotesVisible}
        onClose={handleNotesDrawerClose}
        closable={false}
        width={550}
        bodyStyle={{ padding: '40px 34px' }}
      >
        <NotesCommonContent
          handleFileItemClick={handleFileItemDownloadClickForInternalNote}
          noteName={planDetailsData.name}
          isCreateLoading={isCreateOfferNoteLoading}
          handleSubmit={handleCreateOfferNoteSubmit}
          isFetchLoading={offerNoteDataIsLoading}
          listOfComments={offerNotes}
          individualId={String(appBootInfo?.individualId)}
          visibilityToggle={isNotesVisible}
          notesHeading={`Broker Notes (${
            !offerNoteDataIsSuccess ? '' : offerNotes.length
          })`}
        />
      </Drawer>
    </div>
  );
};

export default PlanDetails;
