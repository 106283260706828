import { useEffect, useRef, useState } from 'react';
import { Col, Divider, notification, Row } from 'antd';
import { useParams } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { CloseOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';

import TabView from 'components/TabView/TabView';
import RichTextEditor, {
  MentionOptionProps,
} from 'components/RichTextEditor/RichTextEditor';
import Button from 'components/buttons/Button/Button';
import InputFileUpload from 'components/InputFileUpload/InputFileUpload';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  useCreateIssueCommentMutation,
  useLazyGetAdminsByEmployerQuery,
  useLazyGetEmployerByIdQuery,
  useLazyGetIndividualByIdQuery,
  useLazyGetIssueCommentsQuery,
  useLazyGetSupportTeamByIdQuery,
  handleDlpVerify,
  dlpStatusCheck,
} from 'modules/issueslog/slices/issuesLogSlice';
import Issue from 'model/Issue';
import {
  ISSUE_COMMENT_TYPE_EMAIL,
  ISSUE_COMMENT_TYPE_INTERNAL,
  ISSUE_TYPE_ACCOUNT,
  ISSUE_TYPE_SUPPORT,
  ISSUE_COMMENT_COLLAPSE_STATE,
} from 'modules/issueslog/constants/IssuesLogConstants';
import IssueComment from 'model/IssueComment';
import TabType from 'model/TabType';
import ViewComment from 'modules/issueslog/components/ViewComment/ViewComment';
import CollapsedViewComment from 'modules/issueslog/components/CollapsedViewComment/CollapsedViewComment';

import ExpandCollapseButton from 'modules/issueslog/components/ExpandCollapseButton/ExpandCollapseButton';
import { ReactComponent as InternalNote } from 'assets/images/internal-note.svg';
import { loginTypes } from 'constants/authConstants';
import { issueAllowedFileTypes } from 'modules/issueslog/utils/issueLogUtils';
import { buildInitials } from 'util/stringUtil';
import { getArrayFromDOMElementsAttribute } from 'util/domUtils';
import {
  FORMAT_VALIDATE,
  isRichTextEditorEmpty,
  addSpan,
  removeSpan,
} from 'util/commonUtil';
import InputEmailChip from 'components/InputEmailChip/InputEmailChip';
import { AccountStatus } from 'modules/admins/constants/adminConstants';
import {
  removeDuplicateEmails,
  removeSpecificItemFromStringArray,
} from 'util/arrayUtil';
import { getFileUploadErrorMessages } from 'util/fileUtil';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import FormInput from 'components/FormInput/FormInput';
import { IndividualType, FEATURE_KEYS } from 'constants/commonConstants';

import DLPVerifyModal from 'modules/issueslog/components/DLPVerifyModal/DLPVerifyModal';

import styles from './commentsSection.module.less';

type CommentData = {
  description: string;
  subject?: string;
};

type EmailInputStateProps = {
  defaultMailList: string[];
  emailList: string[];
  isValidList: boolean;
  isEmptyList: boolean;
  removableDefaultMails: string[];
};

type Props = {
  issue: Issue;
  getIssue: Function;
  downloadAttachment: Function;
  provisionedEmail: string;
  supportTeamAutomatedMailList: string[];
  getBrokerAdminsAccordingToEmp: () => [];
  isShowReplyButton?: boolean;
};

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const CommentsSection = (props: Props) => {
  const {
    issue = {} as Issue,
    getIssue,
    downloadAttachment,
    provisionedEmail,
    supportTeamAutomatedMailList,
    getBrokerAdminsAccordingToEmp,
    isShowReplyButton = true,
  } = props;
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const { avatarUrl, firstName, lastName } = appBootInfo || {};
  const [highlightedDescription, setHighlightedDescription] =
    useState<string>('');
  const prevDlpVerifyResponse = useRef<string | null>(null);
  const [dlpPoints, setdlpPoints] = useState<string[]>([]);
  const clearDlpBulletPoints = () => {
    setdlpPoints([]);
  };

  const [selectedTab, setSelectedTab] = useState<string>(
    ISSUE_COMMENT_TYPE_EMAIL
  );
  const [attachments, setAttachments] = useState<UploadFile[]>([]);
  const [formData, setFormData] = useState<CommentData>({
    description: '',
    subject: issue.subject,
  });
  const [viewAddComment, setViewAddComment] = useState<boolean>(false);
  const [ccEmailInputState, setCCEmailInputState] =
    useState<EmailInputStateProps>({
      defaultMailList: [],
      emailList: [],
      isValidList: false,
      isEmptyList: true,
      removableDefaultMails: [],
    });
  const [toEmailInputState, setToEmailInputState] =
    useState<EmailInputStateProps>({
      defaultMailList: [],
      emailList: [],
      isValidList: false,
      isEmptyList: true,
      removableDefaultMails: [],
    });

  const [getIndividualByIdQuery, { data: loggedUser }] =
    useLazyGetIndividualByIdQuery();
  const [createComment, { isLoading, isSuccess: isCreateCommentSuccess }] =
    useCreateIssueCommentMutation();
  const [
    getComments,
    {
      data: comments,
      isLoading: commentsLoading,
      isUninitialized: isCommentsFetched,
    },
  ] = useLazyGetIssueCommentsQuery();

  const [getAdminsByEmployer, { data: admins }] =
    useLazyGetAdminsByEmployerQuery();

  const [getSupportTeam, { data: supportTeam, isLoading: supportTeamLoading }] =
    useLazyGetSupportTeamByIdQuery();

  const [getEmployerById, { data: employerDetails }] =
    useLazyGetEmployerByIdQuery();

  const params = useParams();
  const dispatch = useAppDispatch();

  const employerObj: any = useAppSelector((state) => state?.layout?.employer);

  const [isEmailCollapsedArr, setIsEmailCollapsedArr] = useState<boolean[]>([]);
  const [isNoteCollapsedArr, setIsNoteCollapsedArr] = useState<boolean[]>([]);

  const [lastAllEmailState, setLastAllEmailState] = useState<
    string | undefined
  >(undefined);
  const [lastAllNoteState, setLastAllNoteState] = useState<string | undefined>(
    undefined
  );

  const [isBrokerTeamMemberInvalid, setIsBrokerTeamMemberInvalid] =
    useState<boolean>(false);

  const [openDLPVerifyModal, setOpenDLPVerifyModal] = useState<boolean>(false);

  /* Runs each time the comments are refreshed. Creates a new array for
   * each isCollapsedArr based on previous state by combining it with new state.
   * Preserves old Expand/Collapse state on comment load
   */
  useEffect(() => {
    const emailComments = getCommentsByType(ISSUE_COMMENT_TYPE_EMAIL);
    if (!isEmpty(emailComments)) {
      const isNewEmailCollapsedArr: boolean[] = Array(
        emailComments.length - isEmailCollapsedArr.length
      ).fill(true);

      isNewEmailCollapsedArr[isNewEmailCollapsedArr.length - 1] = false;
      setIsEmailCollapsedArr((prev) => [...prev, ...isNewEmailCollapsedArr]);
      setLastAllEmailState((prev) =>
        !!prev ? prev : ISSUE_COMMENT_COLLAPSE_STATE.COLLAPSED
      );
    }

    const noteComments = getCommentsByType(ISSUE_COMMENT_TYPE_INTERNAL);
    if (!isEmpty(noteComments)) {
      const isNewNoteCollapsedArr: boolean[] = Array(
        noteComments.length - isNoteCollapsedArr.length
      ).fill(true);

      isNewNoteCollapsedArr[isNewNoteCollapsedArr.length - 1] = false;
      setIsNoteCollapsedArr((prev) => [...prev, ...isNewNoteCollapsedArr]);
      setLastAllNoteState((prev) =>
        !!prev ? prev : ISSUE_COMMENT_COLLAPSE_STATE.COLLAPSED
      );
    }
    // eslint-disable-next-line
  }, [comments]);

  /* Runs each time a note is pressed. This checks whether all notes are expanded or collapsed.
   * It saves that state so it can be used by the ExpandCollapseButton component
   * to produce desired behaviour. ONLY updates state related to button is either all collapsed or
   * all expanded. Otherwise previous state is persisted
   */
  useEffect(() => {
    if (isNoteCollapsedArr.length > 0) {
      const isAllNotesCollapsed = isNoteCollapsedArr.every((v) => v === true);
      const isAllNotesExpanded = isNoteCollapsedArr.every((v) => v === false);

      if (isAllNotesCollapsed) {
        setLastAllNoteState(ISSUE_COMMENT_COLLAPSE_STATE.COLLAPSED);
      }
      if (isAllNotesExpanded) {
        setLastAllNoteState(ISSUE_COMMENT_COLLAPSE_STATE.EXPANDED);
      }
    }
  }, [isNoteCollapsedArr]);

  // Same as prior useEffect but for emails instead
  useEffect(() => {
    if (isEmailCollapsedArr.length > 0) {
      const isAllEmailsCollapsed = isEmailCollapsedArr.every((v) => v === true);
      const isAllEmailsExpanded = isEmailCollapsedArr.every((v) => v === false);

      if (isAllEmailsCollapsed) {
        setLastAllEmailState(ISSUE_COMMENT_COLLAPSE_STATE.COLLAPSED);
      }
      if (isAllEmailsExpanded) {
        setLastAllEmailState(ISSUE_COMMENT_COLLAPSE_STATE.EXPANDED);
      }
    }
  }, [isEmailCollapsedArr]);

  useEffect(() => {
    if (params.issueId) {
      getComments({ issueId: params.issueId });
    }
  }, [getComments, params.issueId]);

  useEffect(() => {
    if (!commentsLoading && !isCommentsFetched) {
      setViewAddComment(getCommentsByType(selectedTab)?.length === 0);
      const emailComments = getCommentsByType(ISSUE_COMMENT_TYPE_EMAIL);
      if (selectedTab === ISSUE_COMMENT_TYPE_EMAIL) {
        if (!isEmpty(emailComments)) {
          const latestComment = emailComments[emailComments.length - 1];

          /**
           * If account issue added from mail to To and if from mail equals to provision mail return []
           * and remove automated employer mail from To
           * If support issue added from mail to To and if from mail equals to provision support mail return []
           * and remove automated broker support team mail from To
           * @return {string[]}
           */
          const getRemovableToMail = (): string[] => {
            const fromMail: string[] =
              latestComment?.fromEmail !== null &&
              latestComment?.fromEmail !== provisionedEmail
                ? [latestComment?.fromEmail]
                : [];

            if (issue?.type === ISSUE_TYPE_ACCOUNT) {
              const removeAutomatedAccMailList: string[] =
                latestComment?.toList?.filter(
                  (item: any) =>
                    item.toLowerCase() !==
                    employerDetails?.issuesLogAccountEmail?.email
                );

              const removeOutProvisionMail: string[] =
                removeSpecificItemFromStringArray(
                  [...fromMail, ...removeAutomatedAccMailList],
                  provisionedEmail
                );

              return removeOutProvisionMail;
            } else {
              const supportTeamAutomatedStringMailArray: string[] =
                supportTeamAutomatedMailList?.map((item: any) => item?.email);
              const removeAutomatedSupportMailList: string[] =
                latestComment?.toList?.filter(
                  (item: any) =>
                    !supportTeamAutomatedStringMailArray?.includes(
                      item.toLowerCase()
                    )
                );

              const removeOutProvisionMail: string[] =
                removeSpecificItemFromStringArray(
                  [...fromMail, ...removeAutomatedSupportMailList],
                  provisionedEmail
                );
              return removeOutProvisionMail;
            }
          };

          /**
           * If account issue return ccList, employers automated mail and logged user mail
           * If support issue  return ccList, find issue assigned support team automated
           * support Team mail and logged user mail
           * @return {string[]}
           */
          const getRemovableCCMail = (): string[] => {
            if (issue?.type === ISSUE_TYPE_ACCOUNT) {
              const accountAutomatedEmail = employerDetails
                ?.issuesLogAccountEmail?.email
                ? [employerDetails?.issuesLogAccountEmail?.email]
                : [];

              return [
                ...latestComment.ccList,
                ...accountAutomatedEmail,
                loggedUser?.email,
              ];
            } else {
              const findAssignedSupportTeamAutomatedEmail: any =
                supportTeamAutomatedMailList?.find(
                  (item: any) => item?.name === issue?.supportTeam
                );

              const supportTeamAutomatedEmail =
                findAssignedSupportTeamAutomatedEmail?.email
                  ? [findAssignedSupportTeamAutomatedEmail?.email]
                  : [];

              return [
                ...latestComment.ccList,
                ...supportTeamAutomatedEmail,
                loggedUser?.email,
              ];
            }
          };

          setToEmailInputState({
            defaultMailList: [],
            emailList: getRemovableToMail(),
            isValidList: false,
            isEmptyList: false,
            removableDefaultMails: getRemovableToMail(),
          });
          setCCEmailInputState({
            defaultMailList: [loggedUser?.email],
            emailList: removeDuplicateEmails(getRemovableCCMail()),
            removableDefaultMails: removeDuplicateEmails(getRemovableCCMail()),
            isValidList: false,
            isEmptyList: false,
          });
          setFormData({ description: '', subject: latestComment.subject });
        } else {
          /**
           * isCreateCommentSuccess added because of first time create comment & when fetching the list emailComments getting length 0
           * & then getting the comment to prevent the executing below logic when that happens  **/
          if (
            appBootInfo?.type === loginTypes.erAdmin &&
            employerObj &&
            !isCreateCommentSuccess
          ) {
            const removableDefaultMails: any = [];
            if (employerObj?.issuesLogAccountEmail) {
              removableDefaultMails.push(
                employerObj?.issuesLogAccountEmail?.email
              );
            } else {
              supportTeam?.map((item: any) => {
                removableDefaultMails.push(item.email);
              });
            }
            setToEmailInputState({
              ...toEmailInputState,
              isEmptyList: isEmpty(removableDefaultMails) ? true : false,
              removableDefaultMails: removableDefaultMails,
              emailList: removeDuplicateEmails([
                ...toEmailInputState.emailList,
                ...toEmailInputState.removableDefaultMails,
              ]),
            });
          }
          setFormData({ description: '', subject: issue.subject });
        }
      }
    }
    // eslint-disable-next-line
  }, [
    comments,
    commentsLoading,
    isCommentsFetched,
    selectedTab,
    supportTeam,
    employerObj,
    issue,
    employerDetails,
    supportTeamAutomatedMailList,
  ]);

  useEffect(() => {
    if (!isLoading && params.issueId) {
      getComments({ issueId: params.issueId });
      getIssue({ issueId: params.issueId });
    }
  }, [getComments, getIssue, isLoading, params.issueId]);

  useEffect(() => {
    if (appBootInfo?.individualId) {
      getIndividualByIdQuery({ individualId: appBootInfo?.individualId });
    }
    if (issue?.employerId) {
      getEmployerById({ employerId: issue?.employerId });
      getAdminsByEmployer({
        employerId: String(issue?.employerId),
        type: loginTypes.erAdmin,
      });
      getSupportTeam({
        employerId: issue?.employerId,
        organizationId: issue?.organizationId,
      });
    }
    const emailComments = getCommentsByType(ISSUE_COMMENT_TYPE_EMAIL);
    if (isEmpty(emailComments)) {
      setFormData({ description: '', subject: issue?.subject });
    }
    // eslint-disable-next-line
  }, [
    appBootInfo,
    dispatch,
    getAdminsByEmployer,
    getIndividualByIdQuery,
    params?.employerId,
    issue?.employerId,
    issue?.organizationId,
    issue?.subject,
    getSupportTeam,
  ]);

  useEffect(() => {
    const emailComments = getCommentsByType(ISSUE_COMMENT_TYPE_EMAIL);
    if (isEmpty(emailComments)) {
      const defaultMail = [];
      defaultMail.push(loggedUser?.email);
      setCCEmailInputState({
        ...ccEmailInputState,
        defaultMailList: defaultMail,
      });
    }
    // no need to rerender when ccEmailInputState change
    // eslint-disable-next-line
  }, [loggedUser]);

  const onTextEditorChange = (value: string) => {
    const updatedFormData = cloneDeep(formData);
    setFormData({ ...updatedFormData, description: value });
  };

  const onCommentSubjectChange = (e: any) => {
    setFormData({
      description: formData?.description || '',
      subject: e.target?.value,
    });
  };

  const getCommentsByType = (type: string) => {
    return (
      comments?.filter(
        (comment: IssueComment) => comment.commentType === type
      ) || []
    );
  };
  const openNotificationWithIcon = (
    type: NotificationType,
    validateSetting: string,
    individualFileUploadLimit: number,
    totalUploadLimit: number
  ) => {
    let description: any = getFileUploadErrorMessages(
      validateSetting,
      individualFileUploadLimit,
      totalUploadLimit
    ).message;
    if (FORMAT_VALIDATE === validateSetting) {
      description = (
        <Col>
          <span>
            Invalid file type uploaded. Submitted files must be in following
            formats.
          </span>
          <br />
          <span>
            pdf, csv, xlsx, xls, docx, doc, pptx, ppt, png, jpeg, jpg, zip
          </span>
        </Col>
      );
    }
    notification[type]({
      closeIcon: <></>,
      top: 40,
      message: getFileUploadErrorMessages(validateSetting, 100, 1000).title,
      description: description,
      icon: <CloseOutlined className={styles.notificationErrorIcon} />,
    });
  };

  // mention api
  const fetchBrokerAdmins = (): MentionOptionProps[] => {
    return getBrokerAdminsAccordingToEmp();
  };

  const internalCommentsExits = !isEmpty(
    getCommentsByType(ISSUE_COMMENT_TYPE_INTERNAL)
  );

  const getInternalNoteTextEditor = () => {
    return (
      <>
        {!isEmpty(getCommentsByType(ISSUE_COMMENT_TYPE_INTERNAL)) && (
          <Divider />
        )}
        <Row className={styles.commentEditorContainer}>
          <Col span={1}>
            <ProfileAvatar
              content={buildInitials(firstName || '', lastName || '')}
              src={avatarUrl}
            />
          </Col>
          <Col span={23}>
            <Row>
              <RichTextEditor
                isMentionEnable={true}
                fetchMentionItems={fetchBrokerAdmins}
                onChange={onTextEditorChange}
                maxHeight={280}
              />
            </Row>
            <Row>
              <Col>
                <Button
                  loading={isLoading}
                  type="primary"
                  onClick={() => {
                    createComment({
                      employerId: issue.employerId,
                      organizationId: issue.organizationId,
                      issueComment: {
                        description: formData?.description,
                        commentType: selectedTab,
                        issueId: issue.id,
                        subject: issue.subject,
                        mentions: getArrayFromDOMElementsAttribute({
                          domString: formData?.description,
                          domSelector: '[id=mention_id]',
                          domSelectorAttribute: 'name',
                        }),
                      },
                      files: attachments,
                    });
                  }}
                  className={styles.internalNoteButton}
                  label="Post Internal Note"
                  disabled={isRichTextEditorEmpty(formData?.description || '')}
                />
              </Col>
              {internalCommentsExits ? (
                <Col>
                  <SubmitButton
                    className={styles.cancelTextEditor}
                    onClick={() => setViewAddComment(false)}
                    disabled={false}
                  >
                    Cancel
                  </SubmitButton>
                </Col>
              ) : (
                ''
              )}
              <Col flex={'auto'} className={styles.attachmentColumn}>
                <InputFileUpload
                  allowedFileTypes={issueAllowedFileTypes}
                  getFileList={(fileList) => setAttachments(fileList)}
                  uploadIconLabel="+ Include Attachment on Note"
                  totalUploadSizeMB={1000}
                  onValidateFails={(validateSetting) => {
                    openNotificationWithIcon(
                      'error',
                      validateSetting,
                      100,
                      1000
                    );
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const handleGetCCEmailList = ({
    emailList,
    isValidList,
    isEmptyList,
    defaultMailList,
    removableDefaultMails,
  }: EmailInputStateProps) => {
    setCCEmailInputState({
      emailList,
      isValidList,
      isEmptyList,
      defaultMailList: defaultMailList,
      removableDefaultMails: removableDefaultMails,
    });
  };

  const handleGetToEmailList = ({
    emailList,
    isValidList,
    isEmptyList,
    defaultMailList,
    removableDefaultMails,
  }: EmailInputStateProps) => {
    setToEmailInputState({
      emailList,
      isValidList,
      isEmptyList,
      defaultMailList: defaultMailList,
      removableDefaultMails: removableDefaultMails,
    });
    setIsBrokerTeamMemberInvalid(false);
  };

  const validateSendEmail = () => {
    const emailComments = getCommentsByType(ISSUE_COMMENT_TYPE_EMAIL);
    return (
      toEmailInputState.isEmptyList ||
      toEmailInputState.isValidList ||
      isRichTextEditorEmpty(formData?.description || '') ||
      (!ccEmailInputState.isEmptyList && ccEmailInputState.isValidList) ||
      (isEmpty(emailComments) && isEmpty(formData?.subject?.trim()))
    );
  };

  const isUserAbleToReply = () => {
    if (loggedUser?.individualType !== IndividualType.ER_ADMIN) {
      return true;
    }

    return toEmailInputState.emailList.some(
      (email) =>
        [supportTeam, supportTeamAutomatedMailList]
          .flat()
          .some((member) => member.email === email) ||
        employerDetails?.issuesLogAccountEmail?.email === email
    );
  };

  const sendReply = async () => {
    const isAbleToReply = isUserAbleToReply();
    setIsBrokerTeamMemberInvalid(!isAbleToReply);

    if (isAbleToReply) {
      try {
        const dlpStatus = await dlpStatusCheck(
          issue.organizationId,
          FEATURE_KEYS.ISSUES_LOG
        );
        if (dlpStatus.dlpenable) {
          const dlpVerifyResponse = await handleDlpVerify(
            issue.organizationId,
            formData?.description
          );
          if (dlpVerifyResponse?.result?.findings?.length > 0) {
            const uniqueInfoTypes = new Set<string>();

            dlpVerifyResponse.result.findings.forEach(
              (finding: { infoType: { displayName: string } }) => {
                uniqueInfoTypes.add(finding.infoType.displayName);
              }
            );
            setdlpPoints(Array.from(uniqueInfoTypes));
            setOpenDLPVerifyModal(true);
            const cleanedDescription = removeSpan(formData?.description);
            const quotesArray = dlpVerifyResponse.result.findings.map(
              (finding: {
                location: { byteRange: { start: string; end: string } };
              }) => ({
                ...finding,
                location: {
                  ...finding.location,
                  byteRange: {
                    ...finding.location.byteRange,
                    start: parseInt(finding.location.byteRange.start),
                    end: parseInt(finding.location.byteRange.end),
                  },
                },
              })
            );
            let highlightedDescription = cleanedDescription;
            setHighlightedDescription('');

            quotesArray.forEach(
              (finding: {
                location: { byteRange: { start: number; end: number } };
              }) => {
                const { start, end } = finding.location.byteRange;
                const secureText = cleanedDescription.substring(start, end);
                highlightedDescription = addSpan(
                  highlightedDescription,
                  secureText
                );
              }
            );

            setHighlightedDescription(highlightedDescription);

            prevDlpVerifyResponse.current = dlpVerifyResponse.quote;
          } else {
            await createIssueComment();
          }
        } else {
          await createIssueComment();
        }
      } catch (error) {
        console.error('Error in sendReply:', error);
      }
      setIsBrokerTeamMemberInvalid(false);
    }
  };

  const createIssueComment = async () => {
    try {
      const cleanedDescription = removeSpan(formData?.description);
      await createComment({
        employerId: issue.employerId,
        organizationId: issue.organizationId,
        issueComment: {
          description: cleanedDescription,
          commentType: selectedTab,
          issueId: issue.id,
          toList: removeDuplicateEmails([...toEmailInputState.emailList]) || [],
          ccList:
            removeDuplicateEmails([
              ...ccEmailInputState.emailList,
              ...ccEmailInputState.defaultMailList,
            ]) || [],
          subject: formData.subject,
          mentions: [],
        },
        files: attachments,
      });
    } catch (error) {
      console.error('Error in createIssueComment:', error);
    }
  };

  const getEmailCommentTextEditor = () => {
    let inputEmailPopoverDropdownData = [];

    if (issue.type === ISSUE_TYPE_ACCOUNT) {
      if (appBootInfo?.type === loginTypes.erAdmin) {
        inputEmailPopoverDropdownData = supportTeam?.map((item: any) => ({
          label: `${item?.firstName} ${item?.lastName}`,
          value: item?.email,
        }));
      } else {
        inputEmailPopoverDropdownData = admins
          ?.filter(
            (admin: any) =>
              admin.accountStatus === AccountStatus.ACCOUNT_ACTIVATED
          )
          ?.map((item: any) => ({
            label: `${item?.firstName} ${item?.lastName}`,
            value: item?.email,
          }));
      }
    }
    const emailComments = getCommentsByType(ISSUE_COMMENT_TYPE_EMAIL);
    const emailCommentsExists = !isEmpty(emailComments);
    return (
      <>
        {!isEmpty(emailComments) && <Divider />}
        <Row
          className={
            isEmpty(emailComments)
              ? styles.commentEmptyEditorContainer
              : styles.commentEditorContainer
          }
        >
          <Col span={24}>
            <Row>
              {isBrokerTeamMemberInvalid && (
                <Col xs={24} className={styles.errorText}>
                  At least one member from the broker team should be added to
                  this email thread.
                </Col>
              )}
            </Row>
            <Row className={styles.emailCommentInputWrapper}>
              <Col span={isEmpty(emailComments) ? 2 : 1}>
                <span className={styles.emailsRecipientLabel}>To:</span>
              </Col>
              <Col span={isEmpty(emailComments) ? 22 : 23}>
                <InputEmailChip
                  getEmailList={handleGetToEmailList}
                  loggedUser={{
                    email: loggedUser?.email,
                    popoverMessage: `Your email is cc'd to ensure you received updates about this item`,
                  }}
                  showAddMore={issue.type !== ISSUE_TYPE_SUPPORT}
                  popoverOptions={inputEmailPopoverDropdownData || []}
                  popDataLoading={supportTeamLoading}
                  checkEmpty={true}
                  removableDefaultMailList={
                    toEmailInputState.removableDefaultMails
                  }
                />
              </Col>
            </Row>
            <Row className={styles.emailCommentInputWrapper}>
              <Col span={isEmpty(emailComments) ? 2 : 1}>
                <span className={styles.emailsRecipientLabel}>Cc:</span>
              </Col>
              <Col span={isEmpty(emailComments) ? 22 : 23}>
                <InputEmailChip
                  getEmailList={handleGetCCEmailList}
                  loggedUser={{
                    email: loggedUser?.email,
                    popoverMessage: `Your email is cc'd to ensure you received updates about this item`,
                  }}
                  defaultMailList={ccEmailInputState.defaultMailList}
                  removableDefaultMailList={
                    ccEmailInputState.removableDefaultMails
                  }
                />
              </Col>
            </Row>
            {isEmpty(emailComments) && (
              <Row className={styles.emailCommentInputWrapper}>
                <Col span={2}>
                  <span
                    className={`${styles.emailsRecipientLabel} ${styles.emailsSubjectLabel}`}
                  >
                    Subject:
                  </span>
                </Col>
                <Col span={22}>
                  <FormInput
                    value={formData.subject}
                    maxLength={255}
                    onChange={onCommentSubjectChange}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <RichTextEditor
                defaultText={highlightedDescription}
                onChange={onTextEditorChange}
                maxHeight={280}
              />
            </Row>
            <Row className={styles.sendSectionContainer}>
              <Col>
                <Button
                  loading={isLoading}
                  type="primary"
                  onClick={sendReply}
                  className={styles.internalNoteButton}
                  label="Send Email"
                  disabled={validateSendEmail()}
                />
              </Col>
              {emailCommentsExists ? (
                <Col>
                  <SubmitButton
                    className={styles.cancelTextEditor}
                    onClick={() => {
                      setFormData({
                        description: '',
                        subject: issue.subject,
                      });
                      setViewAddComment(false);
                    }}
                    disabled={false}
                  >
                    Cancel
                  </SubmitButton>
                </Col>
              ) : (
                ''
              )}
              <Col flex={'auto'} className={styles.attachmentColumn}>
                <InputFileUpload
                  allowedFileTypes={issueAllowedFileTypes}
                  getFileList={(fileList) => setAttachments(fileList)}
                  uploadIconLabel="+ Include Attachment to Email"
                  totalUploadSizeMB={20}
                  maxFileSizeMB={10}
                  onValidateFails={(validateSetting) => {
                    openNotificationWithIcon('error', validateSetting, 10, 20);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const getInternalCommentSection = () => {
    const filteredComments = getCommentsByType(ISSUE_COMMENT_TYPE_INTERNAL);
    return (
      <div>
        {filteredComments.map((comment: IssueComment, index: number) => {
          return (
            <>
              {isNoteCollapsedArr[index] ? (
                <CollapsedViewComment
                  key={index}
                  issueComment={comment}
                  provisionedEmail={provisionedEmail}
                  onClick={() =>
                    commentClickHandler(ISSUE_COMMENT_TYPE_INTERNAL, index)
                  }
                />
              ) : (
                <ViewComment
                  issueComment={comment}
                  key={index}
                  downloadAttachment={downloadAttachment}
                  onClick={() =>
                    commentClickHandler(ISSUE_COMMENT_TYPE_INTERNAL, index)
                  }
                  issueType={issue?.type}
                  provisionedEmail={provisionedEmail}
                />
              )}
              {index < filteredComments.length - 1 && (
                <Divider className={styles.divider} />
              )}
            </>
          );
        })}

        {getCommentsByType(selectedTab)?.length > 0 && !viewAddComment && (
          <Row>
            <Button
              type="primary"
              onClick={() => {
                setViewAddComment(true);
              }}
              className={styles.actionButton}
              label="Reply"
            />
          </Row>
        )}
        {viewAddComment && getInternalNoteTextEditor()}
      </div>
    );
  };

  const getEmailCommentSection = () => {
    const filteredComments = getCommentsByType(ISSUE_COMMENT_TYPE_EMAIL);
    return (
      <div>
        {filteredComments.map((comment: IssueComment, index: number) => {
          return (
            <>
              {isEmailCollapsedArr[index] ? (
                <CollapsedViewComment
                  key={index}
                  issueComment={comment}
                  provisionedEmail={provisionedEmail}
                  onClick={() =>
                    commentClickHandler(ISSUE_COMMENT_TYPE_EMAIL, index)
                  }
                />
              ) : (
                <ViewComment
                  issueComment={comment}
                  key={index}
                  issueSubject={
                    index === 0 ? comment.subject || issue.subject : ''
                  }
                  onClick={() =>
                    commentClickHandler(ISSUE_COMMENT_TYPE_EMAIL, index)
                  }
                  downloadAttachment={downloadAttachment}
                  provisionedEmail={provisionedEmail}
                  issueType={issue?.type}
                />
              )}
              {index < filteredComments.length - 1 && (
                <Divider className={styles.divider} />
              )}
            </>
          );
        })}

        {getCommentsByType(selectedTab)?.length > 0 && !viewAddComment && (
          <Row>
            {isShowReplyButton && (
              <Button
                type="primary"
                onClick={() => {
                  setViewAddComment(true);
                }}
                className={styles.actionButton}
                label="Reply"
              />
            )}
          </Row>
        )}
        {viewAddComment && getEmailCommentTextEditor()}
      </div>
    );
  };

  const getTabDataList = () => {
    const tabs: TabType[] = [
      {
        tabLabel: (
          <span className={styles.internalNoteTabLabel}>
            Email Thread ({getCommentsByType(ISSUE_COMMENT_TYPE_EMAIL).length})
          </span>
        ),
        tabKey: ISSUE_COMMENT_TYPE_EMAIL,
        tabData: getEmailCommentSection(),
      },
    ];
    if (appBootInfo?.type !== loginTypes.erAdmin) {
      tabs.push({
        tabLabel: (
          <div className={styles.internalNoteTab}>
            <InternalNote />
            <span className={styles.internalNoteTabLabel}>
              Internal Notes (
              {getCommentsByType(ISSUE_COMMENT_TYPE_INTERNAL).length})
            </span>
          </div>
        ),
        tabKey: ISSUE_COMMENT_TYPE_INTERNAL,
        tabData: getInternalCommentSection(),
      });
    }
    return tabs;
  };

  const onTabClick = (key: string) => {
    setSelectedTab(key);
  };

  /**
   * Creates and sets a new array to the appropriate isCollapsedArr
   * after flipping the boolean responsible for pressed item
   * @param {string} type - Either an email or internal note
   * @param {number} idx - Index of relevant comment
   */
  const commentClickHandler = (type: string, idx: number): void => {
    if (type === ISSUE_COMMENT_TYPE_EMAIL) {
      const temp = [...isEmailCollapsedArr];
      temp[idx] = !temp[idx];
      setIsEmailCollapsedArr(temp);
    }

    if (type === ISSUE_COMMENT_TYPE_INTERNAL) {
      const temp = [...isNoteCollapsedArr];
      temp[idx] = !temp[idx];
      setIsNoteCollapsedArr(temp);
    }
  };

  return (
    <Row className={styles.commentsSection}>
      <TabView
        tabOptions={getTabDataList()}
        defaultTabKey={selectedTab}
        onClick={() => {}}
        onChange={onTabClick}
        className={styles.commentSectionTabWrapper}
        tabBarExtraContent={
          <ExpandCollapseButton
            isEmailCollapsedArr={isEmailCollapsedArr}
            isNoteCollapsedArr={isNoteCollapsedArr}
            lastAllEmailState={lastAllEmailState}
            lastAllNoteState={lastAllNoteState}
            selectedTab={selectedTab}
            setIsEmailCollapsedArr={setIsEmailCollapsedArr}
            setIsNoteCollapsedArr={setIsNoteCollapsedArr}
          />
        }
      />
      <DLPVerifyModal
        visible={openDLPVerifyModal}
        dlpBulletPoints={dlpPoints}
        onCancel={() => {
          setOpenDLPVerifyModal(false);
        }}
        clearDlpBulletPoints={clearDlpBulletPoints}
      ></DLPVerifyModal>
    </Row>
  );
};

export default CommentsSection;
