import benefitsGuideIcon from 'assets/images/navigation/DigitalBenefitsGuide.svg';
import dashbordIcon from 'assets/images/navigation/Dashboard.svg';
import adminsIcon from 'assets/images/navigation/Admins.svg';
import benefitsIcon from 'assets/images/navigation/AllBenefits.svg';
import informationIcon from 'assets/images/navigation/Information.svg';
import brokersIcon from 'assets/images/navigation/Brokers.svg';
import consultationIcon from 'assets/images/navigation/Consultation.svg';
import carrierIcon from 'assets/images/navigation/Carriers.svg';
import planRatesIcon from 'assets/images/navigation/PlanRates.svg';
import documentIcon from 'assets/images/navigation/Documents.svg';
import issuesLogIcon from 'assets/images/navigation/IssuesLog.svg';
import calenderIcon from 'assets/images/navigation/Calendar.svg';
import billingIcon from 'assets/images/navigation/Billing.svg';
import claimsIcon from 'assets/images/navigation/Claims.svg';
import renewalsIcon from 'assets/images/navigation/Renewals.svg';
import RFPIcon from 'assets/images/navigation/RFP.svg';
import idCardIcon from 'assets/images/navigation/IdCard.svg';
import openOfferIcon from 'assets/images/navigation/Open-Offer-Icon.svg';
import ssoIcon from 'assets/images/navigation/Sso.svg';
import SelfReviewIcon from 'assets/images/navigation/SelfReview.svg';
import AiAssistantIcon from 'assets/images/navigation/AiAssistant.svg';
import AdvancedProfileIcon from 'assets/images/navigation/AdvancedProfileIcon.svg';

import { topLevelMenus, loginTypes } from 'constants/authConstants';

export const SEPARATOR = 'SEPARATOR';

export type ChildRoute = {
  path: string;
  name: string;
  icon?: string;
  children?: ChildRoute[];
  type?: string;
  validator?: string;
  disabled?: boolean;
  isMenuItem?: boolean;
  isSelectableMenu?: boolean;
  displayName?: string;
};

export type RouteProp = {
  path: string;
  name: string;
  displayName: string;
  children: ChildRoute[];
  validator?: string;
};

const brokerIssuesLogRoutes = {
  path: '/brokers/:brokerId/issues-log/account',
  name: topLevelMenus.issuesLog,
  displayName: topLevelMenus.issuesLog,
  validator: 'ISSUES_LOG',
  children: [
    {
      path: '/brokers/:brokerId/issues-log/account',
      name: 'Active Employers',
      icon: carrierIcon,
      children: [
        {
          path: '/brokers/:brokerId/issues-log/account',
          name: 'Account Team',
          icon: carrierIcon,
          children: [
            {
              path: '/brokers/:brokerId/issues-log/account/:issueId',
              name: 'Account Team',
              icon: carrierIcon,
            },
          ],
        },
        {
          path: '/brokers/:brokerId/issues-log/support',
          name: 'Support Team',
          icon: carrierIcon,
        },
      ],
    },
  ],
};

const techAdminIssuesLogRoutes = {
  path: '/issues-log/account',
  name: topLevelMenus.issuesLog,
  displayName: topLevelMenus.issuesLog,
  children: [
    {
      path: '/issues-log/account',
      name: 'Active Employers',
      icon: carrierIcon,
      children: [
        {
          path: '/issues-log/account',
          name: 'Account Team',
          icon: carrierIcon,
        },
        {
          path: '/issues-log/support',
          name: 'Support Team',
          icon: carrierIcon,
        },
      ],
    },
  ],
};

const techAdminRenewalsRoutes = {
  path: '/renewals/open-offers',
  name: topLevelMenus.renewals,
  displayName: topLevelMenus.renewals,
  children: [
    {
      path: '/renewals/open-offers',
      name: 'Open Offers',
      icon: openOfferIcon,
    },
  ],
};

const configurationChildRoutes = [
  {
    type: SEPARATOR,
    name: 'TOOLS CONFIGURATION',
    path: '',
  },
  {
    path: '/configuration',
    name: 'Configuration',
    disabled: true,
  },
  {
    path: '/configuration/advanced-profile',
    name: 'Advanced Profile',
    icon: AdvancedProfileIcon,
  },
  {
    path: '/configuration/benefit-guide',
    name: 'Benefits Guides',
    icon: benefitsGuideIcon,
  },
  {
    path: '/configuration/benefits-consultation',
    name: 'Benefits Consultation',
    icon: consultationIcon,
  },
  {
    path: '/configuration/issues-log',
    name: 'Account Log',
    icon: issuesLogIcon,
  },
  {
    path: '',
    name: 'Calendar',
    icon: calenderIcon,
    disabled: true,
  },
  {
    path: '/configuration/billing',
    name: 'Billing',
    icon: billingIcon,
  },
  {
    path: '/configuration/claims',
    name: 'Claims',
    icon: claimsIcon,
  },
  {
    path: '/planRates',
    name: 'Plan Rates',
    icon: planRatesIcon,
    disabled: true,
  },
  {
    path: '/configuration/renewals',
    name: 'Renewals',
    icon: renewalsIcon,
  },
  {
    path: '',
    name: 'Documents',
    icon: documentIcon,
    disabled: true,
  },
  {
    path: '/configuration/id-cards',
    name: 'ID Cards',
    icon: idCardIcon,
  },
  {
    path: '/configuration/sso',
    name: 'Single Sign-On (SSO)',
    icon: ssoIcon,
  },
  {
    path: '/configuration/rfp',
    name: 'RFP',
    icon: RFPIcon,
  },
  {
    path: '/configuration/self-review',
    name: 'Self-Review',
    icon: SelfReviewIcon,
  },
  {
    path: '/configuration/ai-assistant',
    name: 'AI Assistant',
    icon: AiAssistantIcon,
  },
];

const configurationRoutes = {
  path: '/configuration',
  name: topLevelMenus.configuration,
  displayName: topLevelMenus.configuration,
  children: [...configurationChildRoutes],
};

const brokerAdminRenewalsRoutes = {
  path: '/brokers/:brokerId/renewals/open-offers',
  name: topLevelMenus.renewals,
  displayName: topLevelMenus.renewals,
  validator: 'RENEWAL',
  children: [
    {
      path: '/brokers/:brokerId/renewals/open-offers',
      name: 'Open Offers',
      icon: openOfferIcon,
    },
  ],
};

const brokerAdminRfpRoutes = {
  path: '/brokers/:brokerId/rfp',
  name: topLevelMenus.rfps,
  displayName: topLevelMenus.rfps,
  validator: 'RFP',
  children: [
    {
      path: '/brokers/:brokerId/rfp',
      name: 'RFPs',
      icon: RFPIcon,
    },
  ],
};

const brokerAdminAiAssistantRoutes = {
  path: '/brokers/:brokerId/assistant/:chatId',
  name: topLevelMenus.assistant,
  displayName: topLevelMenus.assistant,
  validator: 'AI_ASSISTANT_CHAT',
  children: [],
};

const brokerAdminConfigRoutes = {
  path: '/brokers/:brokerId/configuration',
  name: topLevelMenus.configuration,
  displayName: topLevelMenus.configuration,
  children: [
    {
      path: '/brokers/:brokerId/configuration',
      name: 'Configuration',
      disabled: true,
    },
    {
      type: SEPARATOR,
      name: 'TOOLS CONFIGURATION',
      path: '',
    },

    {
      path: '/brokers/:brokerId/configuration/benefit-guide',
      name: 'Benefits Guides',
      icon: benefitsGuideIcon,
      validator: 'BENEFIT_GUIDE',
    },
    {
      path: '/brokers/:brokerId/configuration/benefits-consultation',
      name: 'Benefits Consultation',
      icon: consultationIcon,
      validator: 'BENEFIT_CONSULTATION',
    },
    {
      path: '/brokers/:brokerId/configuration/issues-log-provisioning',
      name: 'Account Log',
      icon: issuesLogIcon,
      validator: 'ISSUES_LOG',
      children: [
        {
          path: '/brokers/:brokerId/configuration/issues-log-provisioning/account',
          name: 'Account Team',
          validator: 'ISSUES_LOG',
        },
        {
          path: '/brokers/:brokerId/configuration/issues-log-provisioning/support',
          name: 'Support Team',
          validator: 'ISSUES_LOG',
        },
      ],
    },

    {
      path: '',
      name: 'Calendar',
      icon: calenderIcon,
      disabled: true,
    },
    {
      path: '/brokers/:brokerId/configuration/billing',
      name: 'Billing',
      icon: billingIcon,
      validator: 'BILLING',
    },
    {
      path: '/brokers/:brokerId/configuration/claims',
      name: 'Claims',
      icon: claimsIcon,
      validator: 'CLAIMS',
    },
    {
      path: '',
      name: 'Plan Rates',
      icon: planRatesIcon,
      disabled: true,
    },
    {
      path: '/brokers/:brokerId/configuration/renewals',
      name: 'Renewals',
      icon: renewalsIcon,
      validator: 'RENEWAL',
    },
    {
      path: '',
      name: 'Documents',
      icon: documentIcon,
      disabled: true,
    },
    {
      path: '/brokers/:brokerId/configuration/id-cards',
      name: 'ID Cards',
      icon: idCardIcon,
      validator: 'ID_CARDS',
    },
    {
      path: '/brokers/:brokerId/configuration/sso',
      name: 'Single Sign-On (SSO)',
      icon: ssoIcon,
      validator: 'SSO',
    },
    {
      path: '/brokers/:brokerId/configuration/ai-assistant',
      name: 'AI Assistant',
      icon: AiAssistantIcon,
      validator: 'AI_ASSISTANT',
    },
  ],
};

const lumityAdminRoutesTree: RouteProp[] = [
  {
    path: '/', // Top horizontal navigation
    name: topLevelMenus.home,
    displayName: topLevelMenus.home,
    children: [
      {
        path: '/', // side navigations
        name: 'Dashboard',
        icon: dashbordIcon,
      },
      {
        path: '/lumity-admins', // side navigations
        name: 'Tech Admins',
        icon: adminsIcon,
      },
    ],
  },
  {
    path: '/brokers',
    name: topLevelMenus.brokers,
    displayName: topLevelMenus.brokers,
    children: [
      {
        path: '/brokers',
        name: 'All Brokers',
        icon: brokersIcon,
      },
    ],
  },

  {
    path: '/employers',
    name: topLevelMenus.employers,
    displayName: topLevelMenus.employers,
    children: [
      {
        path: '/employers',
        name: 'All Employers',
        icon: brokersIcon,
      },
    ],
  },
  { ...techAdminIssuesLogRoutes },
  {
    path: '/carriers',
    name: topLevelMenus.carriers,
    displayName: topLevelMenus.carriers,
    children: [
      {
        path: '/carriers',
        name: 'Active Carriers',
        icon: carrierIcon,
      },
    ],
  },
  { ...techAdminRenewalsRoutes },
  { ...configurationRoutes },
];

const brokerAdminRoutesTree = (currentUserLoginType: string): RouteProp[] => {
  if (currentUserLoginType === loginTypes.platform) {
    return [
      {
        path: '/brokers/:brokerId',
        name: topLevelMenus.brokers,
        displayName: topLevelMenus.home,
        children: [
          {
            path: '/brokers/:brokerId',
            name: 'Dashboard',
            icon: dashbordIcon,
          },
          {
            path: '/brokers/:brokerId/basicInfo',
            name: 'Broker Details',
            icon: informationIcon,
          },
          {
            path: '/brokers/:brokerId/carriers',
            name: 'Carriers',
            icon: carrierIcon,
          },
          {
            type: SEPARATOR,
            name: 'TOOLS CONFIGURATION',
            path: '',
          },
          {
            path: '/brokers/:brokerId/configuration/benefit-guide',
            name: 'Benefits Guides',
            icon: benefitsGuideIcon,
            validator: 'BENEFIT_GUIDE',
          },
          {
            path: '/brokers/:brokerId/configuration/benefits-consultation',
            name: 'Benefits Consultation',
            icon: consultationIcon,
            validator: 'BENEFIT_CONSULTATION',
          },
          {
            path: '/brokers/:brokerId/configuration/issues-log-provisioning',
            name: 'Account Log',
            icon: issuesLogIcon,
            validator: 'ISSUES_LOG',
            children: [
              {
                path: '/brokers/:brokerId/configuration/issues-log-provisioning/account',
                name: 'Account Team',
                validator: 'ISSUES_LOG',
              },
              {
                path: '/brokers/:brokerId/configuration/issues-log-provisioning/support',
                name: 'Support Team',
                validator: 'ISSUES_LOG',
              },
            ],
          },

          {
            path: '',
            name: 'Calendar',
            icon: calenderIcon,
            disabled: true,
          },
          {
            path: '/brokers/:brokerId/configuration/billing',
            name: 'Billing',
            icon: billingIcon,
            validator: 'BILLING',
          },
          {
            path: '/brokers/:brokerId/configuration/claims',
            name: 'Claims',
            icon: claimsIcon,
            validator: 'CLAIMS',
          },
          {
            path: '',
            name: 'Plan Rates',
            icon: planRatesIcon,
            disabled: true,
          },
          {
            path: '/brokers/:brokerId/configuration/renewals',
            name: 'Renewals',
            icon: renewalsIcon,
            validator: 'RENEWAL',
          },
          {
            path: '',
            name: 'Documents',
            icon: documentIcon,
            disabled: true,
          },
          {
            path: '/brokers/:brokerId/configuration/id-cards',
            name: 'ID Cards',
            icon: idCardIcon,
            validator: 'ID_CARDS',
          },
          {
            path: '/brokers/:brokerId/configuration/sso',
            name: 'Single Sign-On (SSO)',
            icon: ssoIcon,
            validator: 'SSO',
          },
          {
            path: '/brokers/:brokerId/configuration/rfp',
            name: 'RFP',
            icon: RFPIcon,
            validator: 'RFP',
          },
        ],
      },
      { ...brokerIssuesLogRoutes },
      { ...brokerAdminRenewalsRoutes },
      {
        path: '/brokers/:brokerId/employers',
        name: topLevelMenus.employers,
        displayName: topLevelMenus.employers,
        children: [
          {
            path: '/brokers/:brokerId/employers',
            name: 'All Employers',
            icon: dashbordIcon,
          },
        ],
      },
      {
        path: '/brokers/:brokerId/configuration',
        name: topLevelMenus.configuration,
        displayName: topLevelMenus.configuration,
        children: [
          {
            path: '/brokers/:brokerId',
            name: 'Dashboard',
            icon: dashbordIcon,
          },
          {
            path: '/brokers/:brokerId/basicInfo',
            name: 'Broker Details',
            icon: informationIcon,
          },
          {
            path: '/brokers/:brokerId/carriers',
            name: 'Carriers',
            icon: carrierIcon,
          },
          {
            type: SEPARATOR,
            name: 'TOOLS CONFIGURATION',
            path: '',
          },
          {
            path: '/brokers/:brokerId/configuration/benefit-guide',
            name: 'Benefits Guides',
            icon: benefitsGuideIcon,
            validator: 'BENEFIT_GUIDE',
          },
          {
            path: '/brokers/:brokerId/benefits-consultation',
            name: 'Benefits Consultation',
            icon: consultationIcon,
            validator: 'BENEFIT_CONSULTATION',
          },
          {
            path: '/brokers/:brokerId/configuration/issues-log-provisioning',
            name: 'Account Log',
            icon: issuesLogIcon,
            validator: 'ISSUES_LOG',
            children: [
              {
                path: '/brokers/:brokerId/configuration/issues-log-provisioning/account',
                name: 'Account Team',
                validator: 'ISSUES_LOG',
              },
              {
                path: '/brokers/:brokerId/configuration/issues-log-provisioning/support',
                name: 'Support Team',
                validator: 'ISSUES_LOG',
              },
            ],
          },

          {
            path: '',
            name: 'Calendar',
            icon: calenderIcon,
            disabled: true,
          },
          {
            path: '/brokers/:brokerId/configuration/billing',
            name: 'Billing',
            icon: billingIcon,
            validator: 'BILLING',
          },
          {
            path: '/brokers/:brokerId/configuration/claims',
            name: 'Claims',
            icon: claimsIcon,
            validator: 'CLAIMS',
          },
          {
            path: '',
            name: 'Plan Rates',
            icon: planRatesIcon,
            disabled: true,
          },
          {
            path: '/brokers/:brokerId/configuration/renewals',
            name: 'Renewals',
            icon: renewalsIcon,
            validator: 'RENEWAL',
          },
          {
            path: '',
            name: 'Documents',
            icon: documentIcon,
            disabled: true,
          },
          {
            path: '/brokers/:brokerId/configuration/id-cards',
            name: 'ID Cards',
            icon: idCardIcon,
            validator: 'ID_CARDS',
          },
          {
            path: '/brokers/:brokerId/configuration/sso',
            name: 'Single Sign-On (SSO)',
            icon: ssoIcon,
            validator: 'SSO',
          },
          {
            path: '/brokers/:brokerId/configuration/rfp',
            name: 'RFP',
            icon: RFPIcon,
            validator: 'RFP',
          },
          {
            path: '/brokers/:brokerId/configuration/ai-assistant',
            name: 'AI Assistant',
            icon: AiAssistantIcon,
            validator: 'AI_ASSISTANT',
          },
        ],
      },
    ];
  } else if (currentUserLoginType === loginTypes.bokerAdmin) {
    return [
      {
        path: '/brokers/:brokerId',
        name: topLevelMenus.brokers,
        displayName: topLevelMenus.home,
        children: [
          {
            path: '/brokers/:brokerId',
            name: 'Dashboard',
            icon: dashbordIcon,
          },
          {
            path: '/brokers/:brokerId/basicInfo',
            name: 'Broker Details',
            icon: informationIcon,
          },
          {
            path: '/brokers/:brokerId/carriers',
            name: 'Carriers',
            icon: carrierIcon,
          },
          {
            type: SEPARATOR,
            name: 'TOOLS CONFIGURATION',
            path: '',
          },
          {
            path: '/brokers/:brokerId/benefits-consultation',
            name: 'Benefits Consultation',
            icon: consultationIcon,
            validator: 'BENEFIT_CONSULTATION',
          },
        ],
      },
      {
        path: '/brokers/:brokerId/employers',
        name: topLevelMenus.employers,
        displayName: topLevelMenus.employers,
        children: [
          {
            path: '/brokers/:brokerId/employers',
            name: 'All Employers',
            icon: dashbordIcon,
          },
        ],
      },

      { ...brokerIssuesLogRoutes },
      { ...brokerAdminRenewalsRoutes },
      { ...brokerAdminRfpRoutes },
      { ...brokerAdminAiAssistantRoutes },
      { ...brokerAdminConfigRoutes },
    ];
  }

  return [];
};

const erAdminRoutesTree = (currentUserLoginType: string): RouteProp[] => [
  {
    path: '/brokers/:brokerId/employers/:employerId',
    name: topLevelMenus.employers,
    displayName: topLevelMenus.home,
    children: [
      {
        path: '/brokers/:brokerId/employers/:employerId',
        name: 'Dashboard',
        icon: dashbordIcon,
      },
      {
        path: '/brokers/:brokerId/employers/:employerId/info',
        name: 'Employer Details',
        icon: informationIcon,
      },
      {
        type: SEPARATOR,
        name: 'BENEFITS',
        path: '',
      },
      {
        path: '/brokers/:brokerId/employers/:employerId/tax-advantaged-accts',
        name: 'Plans',
        icon: benefitsIcon,
        children: [
          {
            path: '/brokers/:brokerId/employers/:employerId/medical',
            name: 'Medical',
            children: [
              {
                path: '/brokers/:brokerId/employers/:employerId/medical/:planId/overview',
                name: 'Medical',
              },
            ],
          },
          {
            path: '/brokers/:brokerId/employers/:employerId/telehealth-rx',
            name: 'Telehealth/RX Delivery',
          },
          {
            path: '/brokers/:brokerId/employers/:employerId/dental',
            name: 'Dental',
            children: [
              {
                path: '/brokers/:brokerId/employers/:employerId/dental/:planId/overview',
                name: 'Dental',
              },
            ],
          },
          {
            path: '/brokers/:brokerId/employers/:employerId/vision',
            name: 'Vision',
            children: [
              {
                path: '/brokers/:brokerId/employers/:employerId/vision/:planId/overview',
                name: 'Vision',
              },
            ],
          },
          {
            path: '/brokers/:brokerId/employers/:employerId/life',
            name: 'Life & Disability',
          },
          {
            path: '/brokers/:brokerId/employers/:employerId/voluntary',
            name: 'Voluntary Benefits',
          },
          {
            path: '/brokers/:brokerId/employers/:employerId/wellbeing',
            name: 'Wellbeing',
          },
          {
            path: '/brokers/:brokerId/employers/:employerId/tax-advantaged-accts',
            name: 'Tax Advantaged Accts',
          },
          {
            path: '/brokers/:brokerId/employers/:employerId/401k',
            name: '401(k)',
          },
          {
            path: '/brokers/:brokerId/employers/:employerId/holiday-time-off',
            name: 'Holidays & Time Off',
          },
          {
            path: '/brokers/:brokerId/employers/:employerId/family-and-leave',
            name: 'Family & Leave',
          },
          {
            path: '/brokers/:brokerId/employers/:employerId/additional-perks',
            name: 'Additional Perks',
          },
        ],
      },
      {
        path: '/brokers/:brokerId/employers/:employerId/carriers',
        name: 'Carriers',
        icon: carrierIcon,
      },
      {
        type: SEPARATOR,
        name: 'EMPLOYEE TOOLS',
        path: '',
      },
      {
        path: '/brokers/:brokerId/employers/:employerId/benefit-guides',
        name: 'Benefits Guides',
        icon: benefitsGuideIcon,
        validator: 'BENEFIT_GUIDE',
      },
      {
        path: '/brokers/:brokerId/employers/:employerId/benefits-consultation',
        name: 'Benefits Consultation',
        icon: consultationIcon,
        validator: 'BENEFIT_CONSULTATION',
        children: [
          {
            path: '/brokers/:brokerId/employers/:employerId/broker-consultation',
            name: 'Broker Consultation',
          },
          {
            path: '/brokers/:brokerId/employers/:employerId/employer-consultation',
            name: 'Employer Consultation',
          },
        ],
      },
      {
        path: '/brokers/:brokerId/employers/:employerId/id-cards',
        name: 'ID Cards',
        icon: idCardIcon,
        validator: 'ID_CARDS',
      },
      {
        type: SEPARATOR,
        name: 'EMPLOYER TOOLS',
        path: '',
      },
      {
        path: '/brokers/:brokerId/employers/:employerId/issues-log/account',
        name: 'Account Log',
        icon: issuesLogIcon,
        validator: 'ISSUES_LOG',
      },
      {
        path: '',
        name: 'Calendar',
        icon: calenderIcon,
        disabled: true,
      },
      {
        path: '/brokers/:brokerId/employers/:employerId/billing',
        name: 'Billing',
        icon: billingIcon,
        validator: 'BILLING',
      },
      {
        path: '/brokers/:brokerId/employers/:employerId/claims',
        name: 'Claims',
        icon: claimsIcon,
        validator: 'CLAIMS',
      },
      {
        path: '',
        name: 'Plan Rates',
        icon: planRatesIcon,
        disabled: true,
      },
      {
        path: '/brokers/:brokerId/employers/:employerId/plan-rates',
        name: 'Plan Rates',
        icon: planRatesIcon,
      },
      {
        path: '/brokers/:brokerId/employers/:employerId/documents',
        name: 'Documents',
        icon: documentIcon,
      },
      {
        path: '/brokers/:brokerId/employers/:employerId/renewals',
        name: 'Renewals',
        icon: renewalsIcon,
        validator: 'RENEWAL',
        children: [
          {
            path: '/brokers/:brokerId/employers/:employerId/renewals/carrier/summary',
            name: 'Carrier Offers',
            isMenuItem: true,
            isSelectableMenu: true,
            children: [
              {
                path: '/brokers/:brokerId/employers/:employerId/renewals/carrier/medical',
                name: 'Medical',
                children: [
                  {
                    path: '/brokers/:brokerId/employers/:employerId/renewals/carrier/medical/:offerId/detail',
                    name: 'Medical',
                    icon: carrierIcon,
                  },
                ],
              },
              {
                path: '/brokers/:brokerId/employers/:employerId/renewals/carrier/dental',
                name: 'Dental',
                children: [
                  {
                    path: '/brokers/:brokerId/employers/:employerId/renewals/carrier/dental/:offerId/detail',
                    name: 'Dental',
                    icon: carrierIcon,
                  },
                ],
              },
              {
                path: '/brokers/:brokerId/employers/:employerId/renewals/carrier/vision',
                name: 'Vision',
                children: [
                  {
                    path: '/brokers/:brokerId/employers/:employerId/renewals/carrier/vision/:offerId/detail',
                    name: 'Vision',
                    icon: carrierIcon,
                  },
                ],
              },
              {
                path: '/brokers/:brokerId/employers/:employerId/renewals/carrier/life-add',
                name: 'Life/AD&D',
              },
              {
                path: '/brokers/:brokerId/employers/:employerId/renewals/carrier/std',
                name: 'Short Term Disability',
              },
              {
                path: '/brokers/:brokerId/employers/:employerId/renewals/carrier/ltd',
                name: 'Long Term Disability',
              },
              {
                path: '/brokers/:brokerId/employers/:employerId/renewals/carrier/vol-life',
                name: 'Voluntary Benefits',
              },
            ],
          },
          {
            path: '/brokers/:brokerId/employers/:employerId/renewals/proposals',
            name: 'Proposals',
          },
        ],
      },
    ],
  },
];

const routes = {
  lumity: lumityAdminRoutesTree,
  broker: brokerAdminRoutesTree,
  employer: erAdminRoutesTree,
  brokerAiAssistant: [brokerAdminAiAssistantRoutes],
  brokerIssuesLog: [brokerIssuesLogRoutes],
  techIssuesLog: [techAdminIssuesLogRoutes],
};

export default routes;
