import { useCallback, useEffect, useState } from 'react';
import { Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { useNavContext } from 'hooks/useNavContext';
import { useAppSelector } from 'hooks/redux';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import {
  AI_PLAN_HISTORY_DELETE_MESSAGE,
  SBC_PROCESSING_POP_UP,
} from 'modules/plans/constants';
import { ReactComponent as AlertInfoGrey } from 'assets/images/icon-info.svg';
import { ReviewType } from 'modules/plans/enums/ReviewType';
import { ReactComponent as RfpIconSelected } from 'assets/images/Rfp-ai-selected.svg';
import { ReactComponent as TrashIcon } from 'assets/images/trash-gray.svg';
import { ReactComponent as PopoutIcon } from 'assets/images/popout-icon-blue.svg';
import { ReactComponent as RedDotIcon } from 'assets/images/red-icon-quotes.svg';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { TDocumentExtractionSource } from 'modules/plans/components/AddPlanModal/AddPlanModal';
import {
  deleteSBC,
  getTheZipFile,
} from 'modules/plans/services/AiSBCUploaderService';
import { DocumentExtractionSource } from 'modules/plans/enums/DocumentExtractionSource';
import { useGetQuoteUploadHistoryQuery } from 'modules/renewals/slices/carrierQuoteFilesAiSlice';
import { BenefitType } from 'modules/renewals/models/PlanTable';
import { getBenefitTitleFromUrlParam } from 'modules/renewals/utils/renewalsUtils';
import styles from './quoteUploadHistoryModal.module.less';

interface JobItem {
  key: string;
  documentName: string;
  startedTime: Date;
  status: ProcessStatus;
  reviewType: ReviewType;
  createdBy: string;
  canceled: boolean;
  isReadyForReview: boolean;
}

type QuoteUploadHistoryModalProps = {
  isOpen?: boolean;
  benefit: BenefitType;
  onClose?: () => any;
  refetch?: () => void;
  setEditOfferVisible: (value: boolean) => void;
  setSelectedOfferId: (value: string) => void;
  openDocExtractionPlanModal: (args: {
    jobId: string;
    docExtractionSource: TDocumentExtractionSource;
  }) => void;
  refetchOfferList: Function;
};

const QuoteUploadHistoryModal = ({
  isOpen,
  onClose = () => {},
  benefit,
  setEditOfferVisible,
  setSelectedOfferId,
  openDocExtractionPlanModal,
  refetchOfferList,
}: QuoteUploadHistoryModalProps) => {
  const { brokerId, employerId } = useNavContext();

  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );

  const { data, refetch, isFetching, isLoading } =
    useGetQuoteUploadHistoryQuery({
      brokerId: brokerId ?? '',
      employerId: employerId ?? '',
      category: benefit ?? '',
    });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<{
    jobId: string;
    open: boolean;
  }>({ jobId: '', open: false });
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const determineStatus = (isOpsAdmin: boolean, item: JobItem) => {
    const { SUCCESS, FAILED, PROCESSING } = ProcessStatus;
    const { AUTOMATIC } = ReviewType;

    const thresholdReached = item?.isReadyForReview;

    if (!isOpsAdmin) return item?.status;

    if (isOpsAdmin) {
      if (item?.status === SUCCESS && item.reviewType === AUTOMATIC) {
        if (thresholdReached) {
          return SUCCESS;
        }
        return FAILED;
      } else if (item.status === FAILED && item.reviewType === AUTOMATIC) {
        return PROCESSING;
      }
    }

    return item?.status;
  };

  const mapDataToJobItems = useCallback(
    (data, isOpsAdmin) => {
      if (data) {
        return data?.map((item: any) => {
          let planName = '';
          let offerName = '';

          try {
            planName = decodeURIComponent(item?.planName ?? '');
          } catch (error) {
            if (error instanceof URIError) {
              console.error('Malformed planName URL:', error);
              planName = item?.planName ?? ''; // Fallback to the original value
            } else {
              throw error;
            }
          }

          try {
            offerName = decodeURIComponent(item?.offerName ?? '');
          } catch (error) {
            if (error instanceof URIError) {
              console.error('Malformed offerName URL:', error);
              offerName = item?.offerName ?? ''; // Fallback to the original value
            } else {
              throw error;
            }
          }

          return {
            key: item?.id,
            offerId: item?.offerId,
            documentName: item?.documentName,
            source: item?.source,
            planName: planName,
            offerName: offerName,
            canceled: item?.canceled,
            startedTime: item?.startedTime
              ? new Date(item?.startedTime)
              : undefined,
            status: determineStatus(isOpsAdmin, item) as ProcessStatus,
            reviewType: item?.reviewType as ReviewType,
            createdBy: item?.createdBy,
          };
        });
      }
      return [];
    },
    // disabling because this function only needs to run when the data is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, isOpsAdmin]
  );

  const filterJobItems = (
    items: JobItem[] = [],
    isOpsAdmin: boolean
  ): JobItem[] => {
    const allowedStatuses = [
      ProcessStatus.REVIEWED,
      ProcessStatus.PROCESSING,
      ProcessStatus.SUCCESS,
      ProcessStatus.SAVED,
      ProcessStatus.FAILED,
      ProcessStatus.REJECTED,
    ];

    return items?.filter((item) => {
      const allowedReviewedTypes = [ReviewType.MANUAL, ReviewType.AUTOMATIC];

      if (
        isOpsAdmin &&
        item?.reviewType === ReviewType.AUTOMATIC &&
        [ProcessStatus.FAILED, ProcessStatus.REJECTED].includes(item?.status)
      ) {
        allowedReviewedTypes?.push(ReviewType.AUTOMATIC);
      } else if (
        isOpsAdmin &&
        item?.reviewType === ReviewType.AUTOMATIC &&
        item?.status === ProcessStatus.REVIEWED
      ) {
        allowedReviewedTypes?.push(ReviewType.AUTOMATIC);
      }

      if (
        isOpsAdmin &&
        item?.reviewType === ReviewType.AUTOMATIC &&
        item?.status === ProcessStatus.SAVED
      ) {
        allowedReviewedTypes?.push(ReviewType.AUTOMATIC);
      }

      return (
        allowedReviewedTypes?.includes(item?.reviewType) &&
        allowedStatuses?.includes(item.status)
      );
    });
  };

  const jobItems = mapDataToJobItems(data, isOpsAdmin) ?? [];
  const filteredJobItems = filterJobItems(jobItems, isOpsAdmin) ?? [];

  const handleDeleteFile = async () => {
    setIsDeleting(true);
    if (isDeleteModalOpen.jobId) {
      await deleteSBC(isDeleteModalOpen.jobId).then(() => {
        refetch();
        setIsDeleteModalOpen({ jobId: '', open: false });
      });
    }
    refetchOfferList?.();
    setIsDeleting(false);
  };

  const openPlanModal = (
    jobId: string,
    docExtractionSource: TDocumentExtractionSource,
    offerId: string
  ) => {
    onClose();
    if (
      docExtractionSource.source === DocumentExtractionSource.UPDATE_QUOTE_PLAN
    ) {
      openDocExtractionPlanModal({
        jobId,
        docExtractionSource,
      });
    } else {
      setSelectedOfferId(offerId);
      setEditOfferVisible(true);
    }
  };

  const formatDateTime = (dateTime: string) => {
    return dayjs(dateTime).format('MMM DD, YYYY hh:mm A');
  };

  const inProgressDisabled = () => {
    return (
      <b className={`${!isOpsAdmin && styles.isDisabled} ${styles.flexRow}`}>
        In Progress...
        {!isOpsAdmin && (
          <PlanyearPopover
            content={SBC_PROCESSING_POP_UP}
            zIndex={9999}
            placement="top"
          >
            <AlertInfoGrey className={styles.alertInfoIcon} />
          </PlanyearPopover>
        )}
      </b>
    );
  };

  const inProgressEnabled = (
    jobId: string,
    source: TDocumentExtractionSource,
    offerId: string,
    isOpsAdmin: boolean
  ) => {
    if (isOpsAdmin) {
      return (
        <LinkButton onClick={() => openPlanModal?.(jobId, source, offerId)}>
          Ready For Review
        </LinkButton>
      );
    }
    return (
      <LinkButton onClick={() => openPlanModal?.(jobId, source, offerId)}>
        In Progress
      </LinkButton>
    );
  };

  const getStatus = (status: ProcessStatus, key: any) => {
    const buildSource = {
      sourceOfferId: key.offerId,
      sourceOfferName: key.offerName,
      planId: key.key,
      source: key.source,
      planName: key.planName,
    } as TDocumentExtractionSource;

    const documentExtractionInterruptedState = key?.canceled;

    if (documentExtractionInterruptedState) {
      return (
        <div className={styles.errorTagInStatusAI}>
          <b>Processing Canceled</b>
        </div>
      );
    }

    switch (status) {
      case ProcessStatus.REVIEWED:
        return (
          <LinkButton
            className={isOpsAdmin ? styles.isProcessed : styles.isdefaultview}
            onClick={() =>
              !isOpsAdmin && openPlanModal?.(key?.key, buildSource, key.offerId)
            }
          >
            Ready For Review
          </LinkButton>
        );
      case ProcessStatus.SUCCESS:
        if (!isOpsAdmin) {
          return inProgressDisabled();
        } else {
          return inProgressEnabled(
            key.key,
            buildSource,
            key.offerId,
            isOpsAdmin
          );
        }
      case ProcessStatus.PROCESSING:
        if (!isOpsAdmin) {
          if (key.reviewType === ReviewType.AUTOMATIC) {
            return inProgressEnabled(
              key.key,
              buildSource,
              key.offerId,
              isOpsAdmin
            );
          }
          return inProgressDisabled();
        } else {
          return inProgressDisabled();
        }

      case ProcessStatus.FAILED:
        if (!isOpsAdmin) {
          if (key.reviewType === ReviewType.AUTOMATIC) {
            return (
              <div className={styles.errorTagInStatusAI}>
                <b>Action Required</b>
                <PopoutIcon
                  className={styles.trashIcon}
                  onClick={() =>
                    openPlanModal?.(key?.key, buildSource, key.offerId)
                  }
                />
              </div>
            );
          }
          return inProgressDisabled();
        } else {
          return (
            <div className={styles.errorTagInStatusAI}>
              <b>Error in Processing</b>
              <PopoutIcon
                className={styles.trashIcon}
                onClick={() =>
                  openPlanModal?.(key?.key, buildSource, key.offerId)
                }
              />
            </div>
          );
        }
      case ProcessStatus.SAVED:
        return <b>Saved to Offers</b>;

      case ProcessStatus.REJECTED:
        if (!isOpsAdmin) {
          return (
            <div className={styles.errorTagInStatusAI}>
              <b>Processing Failed</b>
            </div>
          );
        } else {
          return (
            <div className={styles.errorTagInStatusAI}>
              <b>Error in Processing</b>
              <PopoutIcon
                className={styles.trashIcon}
                onClick={() =>
                  openPlanModal?.(key?.key, buildSource, key?.offerId)
                }
              />
            </div>
          );
        }
    }
  };

  const opsColumns: ColumnsType<any> = [
    {
      title: 'FILE NAME',
      dataIndex: 'documentName',
      key: 'documentName',
      width: '20%',
      render: (text: string, record) => {
        const queryParams = `offerId=${record.offerId}`;
        const documentExtractionInterruptedState = record?.canceled;
        return (
          <>
            <div className={styles.fileNameArea}>
              {[ProcessStatus.SUCCESS].includes(record?.status) &&
                !documentExtractionInterruptedState && (
                  <div className={styles.redDotIcon}>
                    <RedDotIcon />
                  </div>
                )}
              <div>
                <b>{text}</b>
              </div>
            </div>
            <div
              className={styles.hiddenZipDownloadSpan}
              onClick={() => {
                const url = `${window.location.pathname}/jsonerror?${queryParams}`;
                window.open(url, '_blank');
              }}
            >
              --{'>'} ViewDetails
            </div>
          </>
        );
      },
    },
    {
      title: 'OFFER',
      dataIndex: 'offerName',
      key: 'offerName',
      width: '15%',
      render: (text: string, record) => {
        return <>{isEmpty(text) ? '-' : text}</>;
      },
    },
    {
      title: 'PLAN NAME',
      dataIndex: 'planName',
      key: 'planName',
      width: '15%',
      render: (text: string, record) => {
        return <>{isEmpty(text) ? '-' : text}</>;
      },
    },
    {
      title: 'STARTED TIME',
      dataIndex: 'startedTime',
      key: 'startedTime',
      width: '20%',
      render: (text: string, record) => {
        return (
          <>
            {formatDateTime(text)} by {record.createdBy}
            <span className={styles.hiddenZipDownloadSpan}>
              <a onClick={() => getTheZipFile(record?.key)}>zip</a>
            </span>
          </>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: getStatus,
    },
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'FILE NAME',
      dataIndex: 'documentName',
      key: 'documentName',
      width: '18%',
      render: (text: string, record) => {
        const queryParams = `offerId=${record.offerId}`;
        const documentExtractionInterruptedState = record?.canceled;
        return (
          <>
            <div className={styles.fileNameArea}>
              {[ProcessStatus.REVIEWED].includes(record?.status) &&
                !documentExtractionInterruptedState && (
                  <div className={styles.redDotIcon}>
                    <RedDotIcon />
                  </div>
                )}
              <div>
                <b>{text}</b>
              </div>
            </div>
            <div
              className={styles.hiddenZipDownloadSpan}
              onClick={() => {
                const url = `${window.location.pathname}/jsonerror?${queryParams}`;
                window.open(url, '_blank');
              }}
            >
              --{'>'} ViewDetails
            </div>
          </>
        );
      },
    },
    {
      title: 'OFFER',
      dataIndex: 'offerName',
      key: 'offerName',
      width: '15%',
      render: (text: string, record) => {
        return <>{isEmpty(text) ? '-' : text}</>;
      },
    },
    {
      title: 'PLAN NAME',
      dataIndex: 'planName',
      key: 'planName',
      width: '15%',
      render: (text: string, record) => {
        return <>{isEmpty(text) ? '-' : text}</>;
      },
    },
    {
      title: 'STARTED TIME',
      dataIndex: 'startedTime',
      key: 'startedTime',
      width: '20%',
      render: (text: string, record) => {
        return (
          <>
            {formatDateTime(text)} by {record.createdBy}
          </>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: getStatus,
    },
    {
      title: 'REMOVE',
      key: 'key',
      dataIndex: 'key',
      width: '6%',
      render: (_, record: any) => {
        const documentExtractionInterruptedState = record?.canceled;

        const isSelfReviewFailed =
          record?.reviewType === ReviewType.AUTOMATIC &&
          [ProcessStatus.FAILED, ProcessStatus.REJECTED].includes(
            record?.status
          );

        if (
          documentExtractionInterruptedState ||
          [
            ProcessStatus.REVIEWED,
            ProcessStatus.SAVED,
            ProcessStatus.REJECTED,
            isSelfReviewFailed && ProcessStatus.FAILED,
          ].includes(record.status)
        ) {
          return (
            <Row justify="center">
              <TrashIcon
                className={styles.trashIcon}
                onClick={() =>
                  setIsDeleteModalOpen({ jobId: record.key, open: true })
                }
              />
            </Row>
          );
        }
      },
    },
  ];

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <ConfirmationDialog
        centered
        className={styles.aiQuoteUploadHistoryModal}
        confirmBtnFullWidth
        visible={isOpen}
        closable
        closeModal={onClose}
        width={1250}
        icon={<RfpIconSelected />}
        title={'Offer Upload History'}
        confirmText="Close"
        destroyOnClose
        onConfirm={onClose}
        isCancelApplicable={false}
      >
        <p className={styles.benefitType}>
          {getBenefitTitleFromUrlParam(benefit.toUpperCase())}
        </p>
        <p>
          Files added via the Carrier Offer Uploader tool must be reviewed
          before saving the new offer into the system.
        </p>
        <Table
          loading={isFetching || isLoading}
          pagination={false}
          columns={isOpsAdmin ? opsColumns : columns}
          dataSource={filteredJobItems}
          scroll={{ y: 400 }}
          className={styles.uploadHistoryTable}
        />
      </ConfirmationDialog>
      <ConfirmationDialog
        centered
        confirmLoading={isFetching || isLoading || isDeleting}
        visible={isDeleteModalOpen.open}
        closeModal={() => setIsDeleteModalOpen({ jobId: '', open: false })}
        title={'Delete File'}
        confirmText="Yes - Delete File"
        isCancelLink
        cancelText="Cancel"
        destroyOnClose
        onConfirm={handleDeleteFile}
        zIndex={1050}
        className={styles.deleteConfirmationModal}
      >
        <p>{AI_PLAN_HISTORY_DELETE_MESSAGE}</p>
      </ConfirmationDialog>
    </>
  );
};

export default QuoteUploadHistoryModal;
