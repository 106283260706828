import { FC } from 'react';
import AddTelehealthRxPlan from 'modules/plans/telehealthRx/components/AddTelehealthRxPlan/AddTelehealthRxPlan';

type EditTelehealthPlanProps = {
  onClose: () => {};
  plan: any;
  isSaveDisabled?: boolean;
  isDBGPlan?: boolean;
  isModalVisible?: boolean;
};

const EditTelehealthPlan: FC<EditTelehealthPlanProps> = ({
  onClose,
  plan,
  isSaveDisabled,
  isDBGPlan,
  isModalVisible,
}: EditTelehealthPlanProps) => {
  const { benefitKind } = plan;
  return (
    <div>
      <AddTelehealthRxPlan
        benefitCategory={{ value: benefitKind }}
        closePlanAddModal={onClose}
        isCloseConfirmed={true}
        onClose={onClose}
        isPlanUpdateMode={true}
        plan={plan}
        isSaveDisabled={isSaveDisabled}
        isDBGPlan={isDBGPlan}
        isModalVisible={isModalVisible}
      />
    </div>
  );
};

export default EditTelehealthPlan;
