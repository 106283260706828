import { FC, useEffect, useState, useCallback } from 'react';
import { StompSessionProvider } from 'react-stomp-hooks';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, PageHeader, Row } from 'antd';

import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { usePermitIf } from 'hooks/usePermitIf';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import { findPlanById } from 'modules/plans/slices/telehealthRxPlanSlice';
import BasicPlanInfoOverview from 'modules/plans/components/OverviewPage/BasicPlanInfoOverview/BasicPlanInfoOverview';
import PlanDocumentsOverview from 'modules/plans/components/OverviewPage/PlanDocumentsOverview/PlanDocumentOverview';

import { allowedCommonButtonPermittedTypes } from 'constants/permittedConstants';
import { wsBaseApi } from 'util/apiUtil';

import PlanCollaborationNotification from 'components/PlanCollaborationNotification/PlanCollaborationNotification';
import styles from './telehealthRxPlanOverview.module.less';

const TelehealthRxPlanOverview: FC = () => {
  const params = useParams();
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();

  const [viewPlanDocumentsModal, setViewPlanDocumentsModal] = useState(false);

  const { telehealthRxPlan } = useAppSelector(
    (state) => state.plan.telehealthRxPlan
  );

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const navigate = useNavigate();

  const [viewPlanModal, setViewPlanModal] = useState(false);
  const [disableEditButton, setDisableEditButton] = useState(false);
  const [fetchPlanWhenUpdated, setFetchPlanWenUpdated] =
    useState<boolean>(false);

  const refreshPlan = useCallback(() => {
    if (employerId && brokerId && params.planId) {
      dispatch(findPlanById(params.planId));
    }
    setFetchPlanWenUpdated(false);
  }, [dispatch, employerId, brokerId, params.planId]);

  useEffect(() => {
    if (employerId && brokerId && params.planId && !telehealthRxPlan) {
      dispatch(findPlanById(params.planId));
    }
    setFetchPlanWenUpdated(false);
  }, [dispatch, employerId, brokerId, params.planId, telehealthRxPlan]);

  const exitPlan = () => {
    navigate(`/brokers/${brokerId}/employers/${employerId}/telehealth-rx`);
  };

  const updateViewPlanModal = (value: boolean) => {
    setViewPlanModal(value);
  };

  const navigation: { name: string; link: string }[] = [
    {
      name: 'All Telehealth/Rx Delivery Plans',
      link: `/brokers/${brokerId}/employers/${employerId}/telehealth-rx`,
    },
  ];

  const securedBreadcrumb = usePermitIf(
    <BreadcrumbNavigation breadCrumbData={navigation} />,
    allowedCommonButtonPermittedTypes,
    []
  );

  const updateViewPlanDocumentsModal = (value: boolean) => {
    setViewPlanDocumentsModal(value);
  };

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col span={24}>
          <Row>{securedBreadcrumb}</Row>
          <Col span={18}>
            <PageHeader
              className={styles.pageTitle}
              title={telehealthRxPlan.name}
            />
          </Col>
        </Col>
      </Row>
      <div className={styles.overview}>
        <StompSessionProvider
          url={`${wsBaseApi}/websocket-plan-update`}
          onWebSocketError={(e) => console.error(e)}
          reconnectDelay={500}
        >
          <PlanCollaborationNotification
            loggedInUserIndividualId={appBootInfo?.individualId}
            exitPlan={() => exitPlan()}
            refreshPlan={() => refreshPlan()}
            planId={telehealthRxPlan.name}
            isInEdit={viewPlanModal}
            disableEdit={() => setDisableEditButton(true)}
            fetchPlan={() => setFetchPlanWenUpdated(true)}
          />
        </StompSessionProvider>
        <div className={styles.basicInfoWrapper}>
          <BasicPlanInfoOverview
            heading={'Plan Details'}
            planName={telehealthRxPlan.name}
            planYearName={telehealthRxPlan.planYear?.name}
            benefitClasses={telehealthRxPlan.groups}
            carrierName={telehealthRxPlan.benefitCarrier?.name}
            type={'TELEHEALTH'}
            viewPlanModal={viewPlanModal}
            setViewPlanModal={updateViewPlanModal}
            disableEditButton={disableEditButton}
            fetchPlanWhenUpdated={fetchPlanWhenUpdated}
            groupId={telehealthRxPlan.groupId}
            exitPlan={() => exitPlan()}
          />
        </div>
        <div className={styles.overview} id={'Plan_Doc'}>
          <PlanDocumentsOverview
            heading={'Plan Resources'}
            plan={telehealthRxPlan}
            documents={telehealthRxPlan.documents}
            benefitKind={telehealthRxPlan.benefitKind}
            viewPlanDocumentsModal={viewPlanDocumentsModal}
            setViewPlanDocumentsModal={(value) =>
              updateViewPlanDocumentsModal(value)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default TelehealthRxPlanOverview;
