import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import StepController from 'modules/renewals/components/AppProposalToPlanYearComponents/StepController/StepController';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  clearData,
  fetchBenefitClasses,
  fetchProposalSummary,
  setBasicDetails,
  setPlans,
} from 'modules/renewals/slices/addProposalToPlanYearSlice';

import { transformProposalSummary } from 'modules/renewals/utils/addToPlanYearUtil';
import { exitWithoutSavingMsg } from 'constants/commonConstants';

import styles from 'modules/renewals/components/AppProposalToPlanYearComponents/AddProposalToPlanYearModal/AddProposalToPlanYearModal.module.less';
import { getPlanYears } from 'modules/employers/slices/employerSlice';

type Props = {
  finalApprovedProposalId: string;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  updateProposalStatus?: Function;
  setIsProposalAddToPlanYearVisible?: Function;
};

const AddProposalToPlanYearModal = ({
  finalApprovedProposalId = '',
  isOpen = false,
  setIsOpen = () => {},
  updateProposalStatus = Function,
  setIsProposalAddToPlanYearVisible = Function,
}: Props) => {
  const { employerId = '' } = useParams();
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const proposal = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.proposalSummary
  );
  const basicDetails = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.basicDetails
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(
        fetchProposalSummary({
          proposalId: finalApprovedProposalId,
          employerId,
        })
      );
      dispatch(getPlanYears(employerId));
      dispatch(fetchBenefitClasses({ employerId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalApprovedProposalId, employerId, isOpen]);

  useEffect(() => {
    if (!proposal.status.isLoading) {
      dispatch(
        setBasicDetails({
          ...basicDetails,
          endDate: proposal.data?.effectiveEndDate,
          startDate: proposal.data?.effectiveStartDate,
          employerId,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal.status.isLoading]);

  useEffect(() => {
    if (proposal?.data) {
      dispatch(setPlans(transformProposalSummary(proposal?.data)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal, basicDetails?.benefitClasses?.data]);

  const handleCloseModal = () => {
    setIsConfirmationDialogOpen(true);
  };

  const handleCloseConfirm = () => {
    setCurrentStep(0);
    setIsOpen(false);
    setIsConfirmationDialogOpen(false);
    dispatch(clearData());
  };

  const getEffectiveDateRange = (): string =>
    `${dayjs(proposal?.data?.effectiveStartDate).format('ll')} - ${dayjs(
      proposal?.data?.effectiveEndDate
    ).format('ll')}`;

  return (
    <FullScreenModal
      forceRender
      footer={false}
      visible={isOpen}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      title={
        <>
          <span>Add Proposal To New Plan Year</span>
          <br />
          {!proposal.status.isLoading && (
            <span className={styles.modalSubText}>
              {proposal?.data?.proposalName} - {getEffectiveDateRange()}
            </span>
          )}
        </>
      }
    >
      {!proposal.status.isLoading ? (
        <div className={styles.contentWrapper}>
          <StepController
            isOpen={isOpen}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            onCloseModal={(showConfirm) =>
              showConfirm ? handleCloseModal() : handleCloseConfirm()
            }
            updateProposalStatus={updateProposalStatus}
            setIsProposalAddToPlanYearVisible={
              setIsProposalAddToPlanYearVisible
            }
          />
        </div>
      ) : (
        <div className={styles.spinnerWrapper}>
          <Spin />
        </div>
      )}
      {isConfirmationDialogOpen && (
        <ConfirmationDialog
          title="Are you sure you want to close?"
          confirmText="Yes - close and do not save"
          cancelText="Cancel"
          closeModal={() => setIsConfirmationDialogOpen(false)}
          onConfirm={handleCloseConfirm}
          visible={isConfirmationDialogOpen}
        >
          <p className={styles.warningConfirmation}>{exitWithoutSavingMsg}</p>
        </ConfirmationDialog>
      )}
    </FullScreenModal>
  );
};

export default AddProposalToPlanYearModal;
