import { FC, useEffect, useState } from 'react';
import { size } from 'lodash';
import { Col, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { buildReferencePlanOptions } from 'modules/plans/utils';
import { useNavContext } from 'hooks/useNavContext';
import { getHraPlanList } from 'modules/plans/slices/planSllice';
import {
  EmployerContributionFrequency,
  TaxAdvantagedAccountPlanType,
} from 'modules/plans/constants';
import { TaxAdvantagedAccountPlan } from 'model/plans/TaxAdvantagedAccountPlan';
import {
  FOUR_TIER,
  N_TIER,
  N_TIER_PLAN_PROPERTIES,
} from 'modules/renewals/constants/renewalsConstants';
import PlanDocumentsOverview from 'modules/plans/components/OverviewPage/PlanDocumentsOverview/PlanDocumentOverview';

import styles from './hraHealthCareFSALimitedFSAPlanOverview.module.less';

type HRAHealthCareFSALimitedFSAPlanOverviewProps = {
  plan: TaxAdvantagedAccountPlan;
  heading?: string;
};

const HRAHealthCareFSALimitedFSAPlanOverview: FC<
  HRAHealthCareFSALimitedFSAPlanOverviewProps
> = (props) => {
  const { plan } = props;
  const dispatch = useAppDispatch();
  const { employerId } = useNavContext();
  const { data: hraPlanList } = useAppSelector(
    (state) => state.plan.dbg.hraPlans
  );
  const [viewPlanDocumentsModal, setViewPlanDocumentsModal] = useState(false);

  const filterSelectedPlans = () => {
    const selectedMedicalDentalVisionPlans = [
      ...plan.selectedMedicalPlans,
      ...plan.selectedDentalPlans,
      ...plan.selectedVisionPlans,
    ];
    const hraPlanOptions = buildReferencePlanOptions(hraPlanList);

    const filteredHraPlans = hraPlanOptions
      ?.filter((planOption: any) =>
        selectedMedicalDentalVisionPlans?.some(
          (selectedPlan: any) => selectedPlan === planOption?.value
        )
      )
      ?.map(({ label, value }) => ({ name: label, id: value }));

    return filteredHraPlans;
  };

  const getFrequencyValue = (frequency: string) => {
    const selectedFrequency = EmployerContributionFrequency.find(
      (item) => item.value === frequency
    );
    return selectedFrequency?.label;
  };

  const getFormattedDecimalValue = (value: number) => {
    return `$${Number(parseFloat(`${value}`).toFixed(2)).toLocaleString('en', {
      minimumFractionDigits: 2,
    })}`;
  };

  const getFormattedPrecentageValue = (value: number) => {
    if (value === 0) {
      return 0;
    }
    return `${Number(parseFloat(`${value}`).toFixed(2)).toLocaleString('en', {
      minimumFractionDigits: 2,
    })}`;
  };

  const hraPlansInfo = [
    {
      label: 'Selected Associated Plans',
      value: (
        <OverflowPopover>
          {!isEmpty(filterSelectedPlans())
            ? filterSelectedPlans()
                .map((data) => data?.name)
                .join(' , ')
            : '-'}{' '}
        </OverflowPopover>
      ),
      types: [TaxAdvantagedAccountPlanType.HRA.value],
    },
    {
      label: 'HRA Utilization Percentage',
      value: (
        <OverflowPopover>
          {plan.hraUtilization === 0 || plan.hraUtilization
            ? `${getFormattedPrecentageValue(plan.hraUtilization)}%`
            : '-'}
        </OverflowPopover>
      ),
      types: [TaxAdvantagedAccountPlanType.HRA.value],
    },
  ];

  const contributionOverview = [
    {
      label: 'Employer Allowance Frequency',
      value: plan.employerContribution?.frequency
        ? getFrequencyValue(plan.employerContribution?.frequency)
        : '-',
      types: [
        TaxAdvantagedAccountPlanType.HRA.value,
        TaxAdvantagedAccountPlanType.HEALTHCARE_FSA.value,
        TaxAdvantagedAccountPlanType.LIMITED_PURPOSE_FSA.value,
      ],
    },
    {
      label: 'Employer EE Allowance',
      value:
        plan.employerContribution?.employerEEContribution ||
        plan.employerContribution?.employerEEContribution === 0
          ? getFormattedDecimalValue(
              plan.employerContribution?.employerEEContribution
            )
          : '-',
      types: [
        TaxAdvantagedAccountPlanType.HRA.value,
        TaxAdvantagedAccountPlanType.HEALTHCARE_FSA.value,
        TaxAdvantagedAccountPlanType.LIMITED_PURPOSE_FSA.value,
      ],
    },
  ];

  const getHRAOverview = () => {
    let initialLabels: any[] = hraPlansInfo;
    const hraPlan = plan as any;
    let nTierCount = 0;
    let tierType = FOUR_TIER;

    const transformedHRAPlanList: any =
      Object.values(hraPlanList)?.flat() ?? [];

    if (plan?.benefitKind === TaxAdvantagedAccountPlanType.HRA.value) {
      const taggedPlan =
        transformedHRAPlanList?.find(
          (item: any) => item?.hraPlanId === plan?.id
        ) ?? null;

      if (!taggedPlan) {
        tierType = '';
      } else {
        const rates = Object.entries(taggedPlan?.rates || {})[0];
        const [key, value]: any = rates || [];

        if (key && value?.ntierContributions?.contributions) {
          tierType = N_TIER;
          nTierCount = size(value?.ntierContributions?.contributions) - 2;
        } else if (key && value?.fourTierContributions) {
          tierType = FOUR_TIER;
        }
      }
    }

    if ([FOUR_TIER, N_TIER].includes(tierType)) {
      initialLabels = [...hraPlansInfo, ...contributionOverview];
    }

    if (tierType === FOUR_TIER) {
      initialLabels = [
        ...initialLabels,
        {
          label: 'Employer ES Allowance',
          value:
            plan.employerContribution?.employerESContribution ||
            plan.employerContribution?.employerESContribution === 0
              ? getFormattedDecimalValue(
                  plan.employerContribution?.employerESContribution
                )
              : '-',
          types: [
            TaxAdvantagedAccountPlanType.HRA.value,
            TaxAdvantagedAccountPlanType.HEALTHCARE_FSA.value,
            TaxAdvantagedAccountPlanType.LIMITED_PURPOSE_FSA.value,
          ],
        },
        {
          label: 'Employer EC Allowance',
          value:
            plan.employerContribution?.employerECContribution ||
            plan.employerContribution?.employerECContribution === 0
              ? getFormattedDecimalValue(
                  plan.employerContribution?.employerECContribution
                )
              : '-',
          types: [
            TaxAdvantagedAccountPlanType.HRA.value,
            TaxAdvantagedAccountPlanType.HEALTHCARE_FSA.value,
            TaxAdvantagedAccountPlanType.LIMITED_PURPOSE_FSA.value,
          ],
        },
        {
          label: 'Employer EF Allowance',
          value:
            plan.employerContribution?.employerEFContribution ||
            plan.employerContribution?.employerEFContribution === 0
              ? getFormattedDecimalValue(
                  plan.employerContribution?.employerEFContribution
                )
              : '-',
          types: [
            TaxAdvantagedAccountPlanType.HRA.value,
            TaxAdvantagedAccountPlanType.HEALTHCARE_FSA.value,
            TaxAdvantagedAccountPlanType.LIMITED_PURPOSE_FSA.value,
          ],
        },
      ];
    }

    if (tierType === N_TIER) {
      N_TIER_PLAN_PROPERTIES?.map((item: string, index: number) => {
        if (index <= nTierCount) {
          initialLabels = [
            ...initialLabels,
            {
              label: `Employer EE + ${index + 1} Contribution`,
              value:
                hraPlan?.employerContribution?.[item] ||
                hraPlan?.employerContribution?.[item] === 0
                  ? getFormattedDecimalValue(
                      hraPlan?.employerContribution?.[item]
                    )
                  : '-',
              types: [TaxAdvantagedAccountPlanType.HRA.value],
            },
          ];
        }
      });
    }

    return [
      ...initialLabels,
      {
        label: 'Other Employer Allowances',
        value: (
          <OverflowPopover>
            {plan.employerContribution?.otherContribution
              ? `${plan.employerContribution?.otherContribution}`
              : '-'}
          </OverflowPopover>
        ),
        types: [
          (tierType === N_TIER || tierType === FOUR_TIER) &&
            TaxAdvantagedAccountPlanType.HRA.value,
          TaxAdvantagedAccountPlanType.HEALTHCARE_FSA.value,
          TaxAdvantagedAccountPlanType.LIMITED_PURPOSE_FSA.value,
        ],
      },
      {
        label: 'Max Yearly Allowance',
        value:
          plan.maxYearlyContribution || plan.maxYearlyContribution === 0
            ? getFormattedDecimalValue(plan.maxYearlyContribution)
            : '-',
        types: [
          TaxAdvantagedAccountPlanType.HEALTHCARE_FSA.value,
          TaxAdvantagedAccountPlanType.LIMITED_PURPOSE_FSA.value,
        ],
      },
      {
        label: 'End of Year Policy',
        value: plan.endOfYearPolicy ? `${plan.endOfYearPolicy}` : '-',
        types: [
          TaxAdvantagedAccountPlanType.HEALTHCARE_FSA.value,
          TaxAdvantagedAccountPlanType.LIMITED_PURPOSE_FSA.value,
        ],
      },
    ];
  };

  useEffect(() => {
    if (employerId && plan?.planYearId) {
      dispatch(getHraPlanList(employerId, plan?.planYearId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerId && plan?.planYearId]);

  const updateViewPlanDocumentsModal = (value: boolean) => {
    setViewPlanDocumentsModal(value);
  };

  return (
    <div className={styles.wrapper}>
      {getHRAOverview()?.map((item, Index) => {
        if (item.types.includes(plan.benefitKind)) {
          return (
            <Row key={Index}>
              <Col span={24}>
                <InfoLabelSection labelText={item.label} value={item.value} />
              </Col>
            </Row>
          );
        }
      })}
      <div className={styles.overview} id={'Plan_Doc'}>
        <PlanDocumentsOverview
          heading={'Plan Resources'}
          plan={plan}
          documents={plan?.documents}
          benefitKind={plan.benefitKind}
          viewPlanDocumentsModal={viewPlanDocumentsModal}
          setViewPlanDocumentsModal={(value) =>
            updateViewPlanDocumentsModal(value)
          }
        />
      </div>
    </div>
  );
};

export default HRAHealthCareFSALimitedFSAPlanOverview;
