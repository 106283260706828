import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  Button,
  Col,
  InputNumber,
  notification,
  Row,
  Select,
  Table,
} from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import Modal from 'antd/lib/modal/Modal';
import { CloseOutlined } from '@ant-design/icons';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { PlansState } from 'modules/renewals/components/StartNewRenewalModal/StartNewRenewalModal';
import { getTypeIcon } from 'modules/renewals/utils/renewalsUtils';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import EnrollmentsSection from 'modules/renewals/components/StartNewRenewalModal/components/EnrollmentsSection/EnrollmentsSection';
import {
  OFFER_BENEFIT_TYPES,
  PREMIUM_TYPES,
  TIER_TYPES,
} from 'modules/renewals/constants/renewalsConstants';

import styles from 'modules/renewals/components/StartNewRenewalModal/components/CurrentPlanInfo/currentPlanInfo.module.less';
import {
  VALIDATION_ERROR_DESCRIPTION,
  VALIDATION_ERROR_NOTICE,
  VALIDATION_ERROR_STD_FIELDS_DESCRIPTION,
} from 'constants/commonConstants';
import FundingType from 'modules/plans/enums/FundingType';
import { FundingTypeTag } from 'components/StdFundingTypeTag/FundingTypeTag';
import { isNullOrUndefined } from 'modules/plans/utils';
import ConfirmCurrentPlansModal from 'modules/renewals/components/StartNewRenewalModal/ConfirmCurrentPlansModal/ConfirmCurrentPlansModal';

type Props = {
  plans: PlansState;
  isLoading: boolean;
  isEdit?: boolean;
  setPlans: (plansState: PlansState) => void;
  onNextStep: (options?: object) => any;
  onCancel: (...args: any[]) => any;
  changeMedicalPremiumType: Function;
  changeDentalPremiumType: Function;
  changeVisionPremiumType: Function;
  selectedPremiumTypes: any;
};

const CurrentPlanInfo = ({
  plans,
  onNextStep,
  isLoading,
  onCancel,
  setPlans,
  changeMedicalPremiumType,
  changeDentalPremiumType,
  changeVisionPremiumType,
  selectedPremiumTypes,
}: Props) => {
  const { Option } = Select;

  const [isEnrollmentsModalVisible, setIsEnrollmentModalVisible] =
    useState<boolean>(false);

  const [setUpEnrollmentData, setSetUpEnrollmentData] = useState<any>(null);
  const [validateEnrollment, setValidateEnrollment] = useState<boolean>(false);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false);
  const [
    showConfirmCurrentPlansModalVisible,
    setConfirmCurrentPlansModalVisible,
  ] = useState(false);

  useEffect(() => {
    const dataLife = [...plans.LIFE].filter((plan) => !plan.isVoluntary);

    const validation = dataLife.map((item) => {
      return (
        (isNullOrUndefined(item?.fundingType) ||
          item?.fundingType === FundingType.FULLY_INSURED) &&
        (item.volume === '' ||
          item.volume === 0 ||
          item.volume === '0' ||
          isNullOrUndefined(item.volume ?? null))
      );
    });

    if (validation.includes(true)) {
      setSaveBtnDisabled(true);
    } else {
      setSaveBtnDisabled(false);
    }
  }, [plans]);

  const commonSections = [
    { name: 'Medical', plans: plans.MEDICAL },
    { name: 'Dental', plans: plans.DENTAL },
    { name: 'Vision', plans: plans.VISION },
  ];
  const lifeSection = {
    name: 'Life & Disability',
    plans: plans.LIFE.filter((item) => !item.isVoluntary),
  };
  const voluntarySection = {
    name: 'Voluntary Benefits',
    plans: [
      ...plans.VOLUNTARY_BENEFIT,
      ...plans.LIFE.filter((item) => item.isVoluntary),
    ].sort((a, b) => a.name.localeCompare(b.name)),
  };
  const isPlansEmpty = Object.values(plans).every(
    (planArr) => planArr.length === 0
  );

  const validatePlanData = () => {
    const MDV = [...plans.MEDICAL, ...plans.DENTAL, ...plans.VISION];
    const dataLife = [...plans.LIFE].filter((plan) => !plan.isVoluntary);

    if (
      (plans?.MEDICAL?.length ?? 0) === 0 &&
      isEmpty(selectedPremiumTypes?.Medical)
    ) {
      return false;
    }

    if (
      (plans?.DENTAL?.length ?? 0) === 0 &&
      isEmpty(selectedPremiumTypes?.Dental)
    ) {
      return false;
    }

    if (
      (plans?.VISION?.length ?? 0) === 0 &&
      isEmpty(selectedPremiumTypes?.Vision)
    ) {
      return false;
    }

    const validationArrayMDV = MDV.map((item) => {
      return item.tierType === TIER_TYPES.STANDARD_TIER
        ? item.fourTier.includes('')
        : item.nTierEnrollments.includes('');
    });

    const validationArrayLife = dataLife.map((item) => {
      return (
        (item.fundingType === FundingType.SELF_FUNDED &&
          (!item.employees || item.employees === '')) ||
        (item.fundingType === FundingType.FULLY_INSURED &&
          item.volume === '') ||
        (item.fundingType === FundingType.SELF_FUNDED &&
          item.administrationFee === '')
      );
    });

    return ![...validationArrayMDV, ...validationArrayLife].includes(true);
  };

  type NotificationType = 'success' | 'info' | 'warning' | 'error';

  const openNotificationWithIcon = (
    type: NotificationType,
    stdField: boolean
  ) => {
    const description = (
      <Col>
        <span>
          {stdField
            ? VALIDATION_ERROR_STD_FIELDS_DESCRIPTION
            : VALIDATION_ERROR_DESCRIPTION}
        </span>
      </Col>
    );

    notification[type]({
      closeIcon: <></>,
      top: 40,
      message: VALIDATION_ERROR_NOTICE,
      description: description,
      icon: <CloseOutlined className={styles.notificationErrorIcon} />,
    });
  };

  const validateStdFields = () => {
    const dataLife = [...plans.LIFE].filter((plan) => !plan.isVoluntary);

    const validation = dataLife.map((item) => {
      return (
        !item.employees ||
        item.employees === '' ||
        (item.fundingType === FundingType.FULLY_INSURED &&
          item.volume === '') ||
        (item.fundingType === FundingType.SELF_FUNDED &&
          item.administrationFee === '')
      );
    });

    return !validation.includes(true);
  };

  /** Will only run in the case current plans are present */
  const handleSaveClick = async () => {
    try {
      if (validatePlanData()) {
        setConfirmCurrentPlansModalVisible(true);
      } else if (!validateStdFields()) {
        openNotificationWithIcon('error', true);
      } else {
        openNotificationWithIcon('error', false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openEnrollmentModal = (rowData: any) => {
    setSetUpEnrollmentData(rowData);
    setIsEnrollmentModalVisible(true);
  };
  const closeEnrollmentModal = () => {
    setSetUpEnrollmentData(null);
    setValidateEnrollment(false);
    setIsEnrollmentModalVisible(false);
  };

  const handleOnChanges = (event: any, type: any, dataIndex: any) => {
    const copyData = cloneDeep(setUpEnrollmentData);
    setValidateEnrollment(false);
    if (type === TIER_TYPES.STANDARD_TIER) {
      copyData.fourTier[dataIndex] = event.target.value;
    } else {
      copyData.nTierEnrollments[dataIndex] = event.target.value;
    }
    setSetUpEnrollmentData(copyData);
  };

  const handleLifeOnChanges = (
    value: any,
    dataIndex: number,
    changeKey: 'employees' | 'volume' | 'administrationFee'
  ) => {
    const copyData = cloneDeep(plans);
    const lifePlans = copyData.LIFE.filter((item) => !item.isVoluntary);
    const nonLifePlans = copyData.LIFE.filter((item) => item.isVoluntary);

    lifePlans[dataIndex][changeKey] = value;
    copyData.LIFE = [...lifePlans, ...nonLifePlans];
    setPlans(copyData);
  };

  const handleSaveEnrollment = () => {
    const {
      benefitType,
      masterId,
      fourTier,
      nTierEnrollments,
      tierType,
      benefitClassId,
    } = setUpEnrollmentData;

    if (tierType === TIER_TYPES.STANDARD_TIER && fourTier.includes('')) {
      setValidateEnrollment(true);
      return;
    }
    if (tierType === TIER_TYPES.N_TIER && nTierEnrollments.includes('')) {
      setValidateEnrollment(true);
      return;
    }

    const copyPlans = cloneDeep(plans);
    const benefitTypePlans =
      copyPlans[benefitType.toUpperCase() as keyof typeof copyPlans];
    const planIndex = benefitTypePlans.findIndex(
      (plan: any) =>
        plan.masterId === masterId && plan.benefitClassId === benefitClassId
    );

    const copyEditedData = cloneDeep(setUpEnrollmentData);
    delete copyEditedData.benefitType;

    benefitTypePlans[planIndex] = {
      ...benefitTypePlans[planIndex],
      ...copyEditedData,
    };
    setPlans(copyPlans);
    setSetUpEnrollmentData(null);
    setIsEnrollmentModalVisible(false);
  };

  const commonColumns = (benefitType: string) => {
    return [
      {
        title: 'CURRENT PLANS',
        dataIndex: 'name',
        key: 'name',
        className: 'proposal-plan-column',
        width: '40%',
        render: (text: any, record: any) => (
          <div className={styles.planTitleWrapper}>
            <div className={styles.planTitle}>{text}</div>
            {['Dental', 'Vision', 'Medical'].includes(benefitType) && (
              <FundingTypeTag fundingType={record?.fundingType} />
            )}
          </div>
        ),
      },
      {
        title: 'ENROLLMENTS',
        dataIndex: 'employees',
        key: 'employees',
        width: '25%',
        render: (_: any, record: any) => {
          const { tierType, fourTier, nTierEnrollments } = record;
          const enrollmentProperty =
            tierType === TIER_TYPES.STANDARD_TIER ? fourTier : nTierEnrollments;
          const isSetUp = enrollmentProperty?.every((item: any) => item !== '');
          const totalEnrollments = enrollmentProperty?.reduce(
            (a: any, b: any) => +a + +b,
            0
          );

          return !isSetUp ? (
            <div className={styles.enrollmentsButtonWrapper}>
              <PageActionButton
                className={styles.enrollmentsButton}
                onClick={() => openEnrollmentModal({ ...record, benefitType })}
              >
                Setup Enrollments
              </PageActionButton>
            </div>
          ) : (
            <span
              className={styles.count}
              onClick={() => {
                openEnrollmentModal({ ...record, benefitType });
              }}
            >
              {totalEnrollments}
            </span>
          );
        },
      },
    ];
  };
  const lifeColumns = [
    {
      title: 'CURRENT PLANS',
      dataIndex: 'name',
      key: 'name',
      className: 'proposal-plan-column',
      width: '40%',
      render: (text: any, record: any) => (
        <div className={styles.planTitleWrapper}>
          <div className={styles.planTitle}>{text}</div>
          <div>
            {record.fundingType &&
              [FundingType.SELF_FUNDED, FundingType.FULLY_INSURED].includes(
                record.fundingType
              ) && <FundingTypeTag fundingType={record.fundingType} />}
          </div>
        </div>
      ),
    },
    {
      title: 'ENROLLMENTS',
      dataIndex: 'employees',
      key: 'employees',
      className: 'life-employees-column',
      width: '15%',
      render: (text: any, record: any, index: number) => {
        if (record?.fundingType === FundingType.SELF_FUNDED) {
          return (
            <InputNumber
              min={0}
              value={record.employees}
              style={{ width: '100%' }}
              onChange={(event) =>
                handleLifeOnChanges(event, index, 'employees')
              }
            />
          );
        } else {
          return '-';
        }
      },
    },
    {
      title: 'VOLUME',
      dataIndex: 'volume',
      key: 'volume',
      className: 'volume-column',

      width: '15%',
      render: (text: any, record: any, index: number) => {
        if (record.fundingType !== 'SELF_FUNDED') {
          return (
            <InputNumber
              min={0}
              formatter={(value) =>
                value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
              }
              parser={parseInput}
              decimalSeparator="."
              precision={2}
              step={0.01}
              style={{
                width: '100%',
              }}
              onChange={(event) => handleLifeOnChanges(event, index, 'volume')}
              value={record.volume}
            />
          );
        } else {
          return '-';
        }
      },
    },
    {
      title: 'ADMIN FEE',
      dataIndex: 'administrationFee',
      key: 'administrationFee',
      className: 'admin-fee-column',
      width: '15%',
      render: (text: any, record: any, index: number) => {
        if (record.fundingType === 'SELF_FUNDED') {
          return (
            <InputNumber
              min={0}
              formatter={(value) =>
                value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
              }
              parser={parseInput}
              decimalSeparator="."
              precision={2}
              step={0.01}
              style={{
                width: '100%',
              }}
              onChange={(event) =>
                handleLifeOnChanges(event, index, 'administrationFee')
              }
              value={record.administrationFee}
            />
          );
        } else {
          return '-';
        }
      },
    },
  ];

  const volColumns = [
    {
      title: 'CURRENT PLANS',
      dataIndex: 'name',
      key: 'name',
      className: 'proposal-plan-column',
      width: '55%',
      render: (text: any) => <div className={styles.planTitle}>{text}</div>,
    },
  ];

  const parseInput = (value?: string) => {
    if (value) {
      return parseFloat(value.replace(/\$\s?|(,*)/g, '')) || 0;
    }
    return 0;
  };

  const onPremiumTypeSelect = (name: String, selectedItem: String) => {
    if (name === OFFER_BENEFIT_TYPES.MEDICAL) {
      changeMedicalPremiumType(selectedItem);
    } else if (name === OFFER_BENEFIT_TYPES.DENTAL) {
      changeDentalPremiumType(selectedItem);
    } else if (name === OFFER_BENEFIT_TYPES.VISION) {
      changeVisionPremiumType(selectedItem);
    }
  };

  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.sectionDescriptionWrapper}>
        <div className={styles.currentPlanTitle}>Current Plans</div>
        <p>
          {isPlansEmpty
            ? 'You currently have no current plans. You can include enrollment information when you add a new offer'
            : 'To start the new renewal, we require current plan information to be entered for comparisons between offers. Please enter all enrollment and volume data below.'}
        </p>
      </div>

      {commonSections.map((section, index) => (
        <div key={index} className={styles.benefitTableWrapper}>
          <div>
            <img
              src={getTypeIcon(section.name).icon}
              className={styles.headerIcon}
            />
            <span className={styles.benefitTypeTitle}>{section.name}</span>
          </div>
          <div
            className={`${
              (section?.plans?.length ?? 0) === 0 ? styles.emptyTable : ''
            }`}
          >
            <Table
              columns={commonColumns(section.name)}
              dataSource={section.plans}
              pagination={false}
              className={styles.tableWrapper}
            />
            {(section?.plans?.length ?? 0) === 0 && (
              <Col className={styles.emptyText}>
                You have no current plans for this benefit. Choose a premium
                type before adding enrollment information when adding a new
                offer
                <br />
                <Select
                  className={styles.selectPremium}
                  getPopupContainer={(trigger: any) => {
                    return trigger.parentElement.parentElement.parentElement;
                  }}
                  onSelect={(selectedItem: any) =>
                    onPremiumTypeSelect(section?.name, selectedItem)
                  }
                  value={selectedPremiumTypes?.[section?.name] ?? ''}
                >
                  {PREMIUM_TYPES?.map((item, index) => (
                    <Option value={item.value} key={index} label={item.label}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Col>
            )}
          </div>
        </div>
      ))}
      <div className={styles.benefitTableWrapper}>
        <div>
          <img
            src={getTypeIcon('Life/AD&D').icon}
            className={styles.headerIcon}
          />
          <span className={styles.benefitTypeTitle}>{lifeSection.name}</span>
        </div>
        <Table
          columns={lifeColumns}
          dataSource={lifeSection?.plans}
          pagination={false}
          className={styles.tableWrapper}
        />
      </div>
      <div className={styles.benefitTableWrapper}>
        <div>
          <img
            src={getTypeIcon('Voluntary Benefits').icon}
            className={styles.headerIcon}
          />
          <span className={styles.benefitTypeTitle}>
            {voluntarySection.name}
          </span>
        </div>
        <Table
          columns={volColumns}
          dataSource={voluntarySection.plans}
          pagination={false}
          className={styles.tableWrapper}
        />
      </div>

      <Row justify="center">
        <Button
          type="primary"
          className={styles.currentPlansSaveButton}
          onClick={handleSaveClick}
          loading={isLoading}
          disabled={saveBtnDisabled}
        >
          Save
        </Button>
        <LinkButton
          className={styles.currentOffersCancelButton}
          onClick={onCancel}
        >
          Cancel
        </LinkButton>
      </Row>

      <Modal
        width={552}
        destroyOnClose={true}
        visible={isEnrollmentsModalVisible}
        closable={false}
        footer={false}
      >
        <div className={styles.enrollmentModalWrapper}>
          <div className={styles.leftBorder} />

          <h2 className={styles.title}>Enrollment Details</h2>

          <EnrollmentsSection
            data={setUpEnrollmentData}
            handleOnChanges={handleOnChanges}
            validateEnrollment={validateEnrollment}
          />
          <div className={styles.buttonsContainer}>
            <Button onClick={closeEnrollmentModal} className={styles.cancel}>
              Cancel
            </Button>
            <Button onClick={handleSaveEnrollment} className={styles.done}>
              Done
            </Button>
          </div>
        </div>
      </Modal>
      <ConfirmCurrentPlansModal
        visible={showConfirmCurrentPlansModalVisible}
        setConfirmCurrentPlansModalVisible={setConfirmCurrentPlansModalVisible}
        onNextStep={onNextStep}
        plans={plans}
      />
    </div>
  );
};

export default CurrentPlanInfo;
