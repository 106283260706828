import { FC, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { notification, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';

import OverviewHeader from 'modules/plans/components/OverviewPage/OverviewHeader/OverviewHeader';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import EditWellbeingPlanUPWrapper from 'modules/plans/wellbeing/components/EditWellbeingPlan/EditWellbeingPlanUPWrapper';
import EditTelehealthPlanUPWrapper from 'modules/plans/telehealthRx/components/EditTelehealthPlan/EditTelehealthPlanUPWrapper';
import EditWorkLifePlanUPWrapper from 'modules/plans/holidaysTimeOff/components/EditWorkLifePlan/EditWorkLifePlanUPWrapper';
import EditAdditionalPerkPlanUPWrapper from 'modules/plans/additionalPerk/components/EditAdditionalPerkPlan/EditAdditionalPerkPlanUPWrapper';
import EditVoluntaryBenefitPlanUPWrapper from 'modules/plans/voluntary/components/EditVoluntaryBenefitsPlan/EditVoluntaryBenefitPlanUPWrapper';
import EditTaxAdvantagedPlanModal from 'modules/plans/taxAdvantaged/components/EditTaxAdvantagedPlan/EditTaxAdvantagedPlanModal';
import EditFourOOneKPlan from 'modules/plans/retirement/components/EditFourOOneKPlan/EditFourOOneKPlan';
import EditMedicalPlanModal from 'modules/plans/medical/components/EditMedicalPlanModal/EditMedicalPlanModal';
import EditDentalPlanModal from 'modules/plans/dental/components/EditDentalPlanModal/EditDentalPlanModal';
import EditVisionPlanModal from 'modules/plans/vision/components/EditVisionPlanModal/EditVisionPlanModal';
import {
  CARRIER_OPTIONAL_PLAN_TYPES,
  EditMdvTypes,
  FUNDING_TYPES_LIST,
  TaxAdvantagedAccountPlanType,
} from 'modules/plans/constants';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import {
  clearWellBeingPlanApiErrors,
  findWellbeingPlanById,
} from 'modules/plans/slices/wellbeingPlanSlice';
import {
  clearTelehealthPlanApiErrors,
  findPlanById,
} from 'modules/plans/slices/telehealthRxPlanSlice';
import {
  clearApiErrors,
  findPlanById as findWorkLifePlanById,
} from 'modules/plans/slices/workLifePlanSlice';
import {
  clearAdditionalPerksApiErrors,
  findAdditionalPerkPlanById,
} from 'modules/plans/slices/additionalPerkPlanSlice';
import {
  clearVolBenefitPlanApiErrors,
  findPlanById as findVoluntaryPlanById,
} from 'modules/plans/slices/voluntaryBenefitPlanSlice';
import {
  clearDocumentsAttached,
  clearRetirementPlanApiErrors,
  findPlanById as findRetirementPlanById,
} from 'modules/plans/slices/retirementPlanSlice';
import {
  clearDocuments as clearLifePlanDocuments,
  clearLifePlanApiErrors,
  findPlanById as findLifePlanById,
} from 'modules/plans/slices/lifePlanSlice';
import {
  findPlanById as findTaxAdvantagedPlanById,
  unsetApiError,
} from 'modules/plans/slices/taxAdvantagedAccountPlanSlice';
import {
  clearMedicalPlanApiErrors,
  findMedicalPlanById,
} from 'modules/plans/slices/medicalPlanSlice';
import {
  clearDentalPlanApiErrors,
  findDentalPlanById,
} from 'modules/plans/slices/dentalPlanSlice';
import {
  clearVisionPlanApiErrors,
  findVisionPlanById,
} from 'modules/plans/slices/visionPlanSlice';

import { BenefitCategory, PLAN_NOT_FOUND } from 'constants/commonConstants';
import { PLAN_NOTIFICATION_KEY } from 'constants/benguideCollaborationConstants';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';
import EditLifePlan from 'modules/plans/life/components/EditLifePlan/EditLifePlan';
import FundingType from 'modules/plans/enums/FundingType';
import { displayNumberWithDecimals } from 'modules/plans/utils';

import styles from './basicPlanInfoOverview.module.less';

type BasicPlanInfoOverviewProps = {
  heading: string;
  planName: string;
  planYearName?: string;
  benefitClasses: string[];
  carrierName?: string;
  groupId?: string;
  planNetwork?: string;
  planType?: string;
  hsaEligibile?: boolean;
  hraEligibile?: boolean;
  statesTerritories?: string[];
  type?:
    | 'WELLBEING'
    | 'FAMILY_HOLIDAY_TIME_OFF'
    | 'ADDITIONAL_PERKS'
    | 'TELEHEALTH'
    | 'VOLUNTARY'
    | 'FOUR_O_ONE_K'
    | 'LIFE'
    | 'TAX_ADVANTAGED'
    | 'DENTAL'
    | 'VISION'
    | 'MEDICAL';
  viewPlanModal?: boolean;
  setViewPlanModal?: any;
  disableEditButton?: boolean;
  fetchPlanWhenUpdated?: any;
  editType?: string;
  exitPlan?: () => void;
  fundingType?: FundingType;
  individualStopLoss?: string;
  individualAdministrationFee?: string;
  aggregatedStopLoss?: string;
  aggregatedAdministrationFee?: string;
  thirdPartyAdministrationFee?: string;
  otherFees?: string;
  administrationFee?: string;
  benefitKind?: string;
  portability?: string;
  waiverOfPremium?: string;
  definitionOfEarnings?: string;
  acceleratedLifeBenefit?: string;
  ageReduction?: string;
  guaranteedIssue?: string;
  additionalServices?: any[];
};
const FAMILY_HOLIDAY_TIME_OFF = 'FAMILY_HOLIDAY_TIME_OFF';
const ADDITIONAL_PERKS = 'ADDITIONAL_PERKS';
const VOLUNTARY = 'VOLUNTARY';
const FOUR_O_ONE_K = 'FOUR_O_ONE_K';
const LIFE = 'LIFE';
const TAX_ADVANTAGED = 'TAX_ADVANTAGED';
const MEDICAL = 'MEDICAL';
const DENTAL = 'DENTAL';
const VISION = 'VISION';

const BasicPlanInfoOverview: FC<BasicPlanInfoOverviewProps> = (props) => {
  const {
    heading,
    planName,
    planYearName,
    benefitClasses,
    carrierName,
    groupId,
    planNetwork,
    planType,
    hsaEligibile,
    hraEligibile,
    statesTerritories,
    type,
    viewPlanModal,
    setViewPlanModal,
    disableEditButton,
    fetchPlanWhenUpdated,
    exitPlan,
    fundingType,
    individualStopLoss,
    individualAdministrationFee,
    aggregatedStopLoss,
    aggregatedAdministrationFee,
    thirdPartyAdministrationFee,
    otherFees,
    administrationFee,
    benefitKind,
  } = props;

  const params = useParams();
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();
  const { medicalPlan, error: medicalPlanError } = useAppSelector(
    (state) => state.plan.plans
  );

  const { dentalPlan, error: dentalPlanError } = useAppSelector(
    (state) => state.plan.dentalPlan
  );

  const { visionPlan, error: visionPlanError } = useAppSelector(
    (state) => state.plan.visionPlan
  );

  const { wellbeingPlan, error: wellbeingPlanError } = useAppSelector(
    (state) => state.plan.wellbeing
  );

  const { telehealthRxPlan, error: telehealthPlanError } = useAppSelector(
    (state) => state.plan.telehealthRxPlan
  );
  const { workLifePlan, error: workLifePlanError } = useAppSelector(
    (state) => state.plan.holidaysTimeOffPlan
  );
  const { additionalPerkPlan, error: additionalPerkPlanError } = useAppSelector(
    (state) => state.plan.additionalPerk
  );
  const { voluntaryBenefitPlan, error: voluntaryPlanError } = useAppSelector(
    (state) => state.plan.voluntaryBenefitPlan
  );
  const { retirementPlan, error: retirementPlanError } = useAppSelector(
    (state) => state.plan.retirementPlan
  );
  const { taxAdvantagedAccountPlan, error: taxAdvantagedPlanError } =
    useAppSelector((state) => state.plan.taxAdvantagedAccountPlan);
  const { lifePlan, error: lifePlanError } = useAppSelector(
    (state) => state.plan.lifePlan
  );

  const isMDVplan = [
    BenefitCategory.MEDICAL.value,
    BenefitCategory.DENTAL.value,
    BenefitCategory.VISION.value,
  ];

  useEffect(() => {
    if (employerId && brokerId) {
      if (
        type === BenefitCategory.MEDICAL.value &&
        medicalPlanError?.response?.data?.code === PLAN_NOT_FOUND &&
        exitPlan
      ) {
        exitPlan();
        dispatch(clearMedicalPlanApiErrors());
      } else if (
        type === BenefitCategory.DENTAL.value &&
        dentalPlanError?.data?.code === PLAN_NOT_FOUND &&
        exitPlan
      ) {
        exitPlan();
        dispatch(clearDentalPlanApiErrors());
      } else if (
        type === BenefitCategory.VISION.value &&
        visionPlanError?.response?.data?.code === PLAN_NOT_FOUND &&
        exitPlan
      ) {
        exitPlan();
        dispatch(clearVisionPlanApiErrors());
      } else if (
        type === BenefitCategory.WELLBEING.value &&
        wellbeingPlanError?.response?.data?.code === PLAN_NOT_FOUND &&
        exitPlan
      ) {
        exitPlan();
        dispatch(clearWellBeingPlanApiErrors());
      } else if (
        type === BenefitCategory.TELEHEALTH.value &&
        telehealthPlanError?.response?.data?.code === PLAN_NOT_FOUND &&
        exitPlan
      ) {
        exitPlan();
        dispatch(clearTelehealthPlanApiErrors());
      } else if (
        type === FAMILY_HOLIDAY_TIME_OFF &&
        workLifePlanError?.response?.data?.code === PLAN_NOT_FOUND &&
        exitPlan
      ) {
        exitPlan();
        dispatch(clearApiErrors());
      } else if (
        type === ADDITIONAL_PERKS &&
        additionalPerkPlanError?.response?.data?.code === PLAN_NOT_FOUND &&
        exitPlan
      ) {
        exitPlan();
        dispatch(clearAdditionalPerksApiErrors());
      } else if (
        type === VOLUNTARY &&
        voluntaryPlanError?.response?.data?.code === PLAN_NOT_FOUND &&
        exitPlan
      ) {
        exitPlan();
        dispatch(clearVolBenefitPlanApiErrors());
      } else if (
        type === FOUR_O_ONE_K &&
        retirementPlanError?.response?.data?.code === PLAN_NOT_FOUND &&
        exitPlan
      ) {
        exitPlan();
        dispatch(clearRetirementPlanApiErrors());
      } else if (
        type === LIFE &&
        lifePlanError?.data?.code === PLAN_NOT_FOUND &&
        exitPlan
      ) {
        exitPlan();
        dispatch(clearLifePlanApiErrors());
      } else if (
        type === TAX_ADVANTAGED &&
        taxAdvantagedPlanError?.response?.data?.code === PLAN_NOT_FOUND &&
        exitPlan
      ) {
        exitPlan();
        dispatch(unsetApiError());
      }
    }
  }, [
    dispatch,
    employerId,
    brokerId,
    type,
    medicalPlanError,
    dentalPlanError,
    visionPlanError,
    wellbeingPlanError,
    telehealthPlanError,
    workLifePlanError,
    additionalPerkPlanError,
    voluntaryPlanError,
    retirementPlanError,
    taxAdvantagedPlanError,
    lifePlanError,
    exitPlan,
  ]);

  useEffect(() => {
    if (employerId && brokerId && params.planId) {
      if (type === BenefitCategory.MEDICAL.value) {
        dispatch(findMedicalPlanById(params.planId));
      }
      if (type === BenefitCategory.DENTAL.value) {
        dispatch(findDentalPlanById(params.planId));
      }
      if (type === BenefitCategory.VISION.value) {
        dispatch(findVisionPlanById(params.planId));
      }
      if (type === BenefitCategory.WELLBEING.value) {
        dispatch(findWellbeingPlanById(params.planId));
      }
      if (type === BenefitCategory.TELEHEALTH.value) {
        dispatch(findPlanById(params.planId));
      }
      if (type === FAMILY_HOLIDAY_TIME_OFF) {
        dispatch(findWorkLifePlanById(params.planId));
      }
      if (type === ADDITIONAL_PERKS) {
        dispatch(findAdditionalPerkPlanById(params.planId));
      }
      if (type === VOLUNTARY) {
        dispatch(findVoluntaryPlanById(params.planId));
      }
      if (type === FOUR_O_ONE_K) {
        dispatch(findRetirementPlanById(params.planId));
      }
      if (type === LIFE) {
        dispatch(findLifePlanById(params.planId));
      }
      if (type === TAX_ADVANTAGED) {
        dispatch(findTaxAdvantagedPlanById(params.planId));
      }
    }
  }, [dispatch, employerId, brokerId, params.planId, type]);

  const editBasicPlan = () => {
    setViewPlanModal(true);
    if (fetchPlanWhenUpdated) {
      fetchPlanOnNotificationReceived();
      notification.close(PLAN_NOTIFICATION_KEY);
    }
  };

  const onClose = () => {
    if (type === BenefitCategory.WELLBEING.value && setViewPlanModal) {
      setViewPlanModal(false);
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(findWellbeingPlanById(params.planId));
      }
    } else if (type === BenefitCategory.TELEHEALTH.value && setViewPlanModal) {
      setViewPlanModal(false);
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(findPlanById(params.planId));
      }
    } else if (type === FAMILY_HOLIDAY_TIME_OFF && setViewPlanModal) {
      setViewPlanModal(false);
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(findWorkLifePlanById(params.planId));
      }
    } else if (type === ADDITIONAL_PERKS && setViewPlanModal) {
      setViewPlanModal(false);
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(findAdditionalPerkPlanById(params.planId));
      }
    } else if (type === VOLUNTARY && setViewPlanModal) {
      setViewPlanModal(false);
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(findVoluntaryPlanById(params.planId));
      }
    } else if (type === FOUR_O_ONE_K && setViewPlanModal) {
      setViewPlanModal(false);
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(clearDocumentsAttached());
        dispatch(findRetirementPlanById(params.planId));
      }
    } else if (type === LIFE && setViewPlanModal) {
      setViewPlanModal(false);
      dispatch(clearLifePlanDocuments());
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(findLifePlanById(params.planId));
      }
    } else if (type === TAX_ADVANTAGED && setViewPlanModal) {
      setViewPlanModal(false);
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(findTaxAdvantagedPlanById(params.planId));
      }
    } else if (type === MEDICAL && setViewPlanModal) {
      setViewPlanModal(false);
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(findMedicalPlanById(params.planId));
      }
    } else if (type === DENTAL && setViewPlanModal) {
      setViewPlanModal(false);
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(findDentalPlanById(params.planId));
      }
    } else if (type === VISION && setViewPlanModal) {
      setViewPlanModal(false);
      if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
        dispatch(findVisionPlanById(params.planId));
      }
    }
  };

  const extraColumnHeaderValue =
    benefitKind === TaxAdvantagedAccountPlanType.DEPENDENT_CARE_FSA.value
      ? 9
      : undefined;

  const fetchPlanOnNotificationReceived = () => {
    if (type === BenefitCategory.WELLBEING.value && params.planId) {
      dispatch(findWellbeingPlanById(params.planId));
    } else if (type === BenefitCategory.TELEHEALTH.value && params.planId) {
      dispatch(findPlanById(params.planId));
    } else if (type === FAMILY_HOLIDAY_TIME_OFF && params.planId) {
      dispatch(findWorkLifePlanById(params.planId));
    } else if (type === ADDITIONAL_PERKS && params.planId) {
      dispatch(findAdditionalPerkPlanById(params.planId));
    } else if (type === VOLUNTARY && params.planId) {
      dispatch(findVoluntaryPlanById(params.planId));
    } else if (type === FOUR_O_ONE_K && params.planId) {
      dispatch(findRetirementPlanById(params.planId));
    } else if (type === LIFE && params.planId) {
      dispatch(findLifePlanById(params.planId));
    } else if (type === TAX_ADVANTAGED && params.planId) {
      dispatch(findTaxAdvantagedPlanById(params.planId));
    } else if (type === MEDICAL && params.planId) {
      dispatch(findMedicalPlanById(params.planId));
    } else if (type === DENTAL && params.planId) {
      dispatch(findDentalPlanById(params.planId));
    } else if (type === VISION && params.planId) {
      dispatch(findVisionPlanById(params.planId));
    }
  };

  const formatSFCValue = (
    value: any | undefined,
    isWithoutDollarSign?: boolean
  ) => {
    if (typeof value === 'undefined' || value === null) {
      return '-';
    }
    if (isWithoutDollarSign && value.toString().trim().length !== 0) {
      return value.toString().trim();
    }
    if (value.toString().trim().length !== 0) {
      return `$${displayNumberWithDecimals(value.toString().trim())}`;
    }
    return '-';
  };

  return (
    <div>
      <Row>
        <OverviewHeader
          title={heading}
          onEditClick={() => {
            editBasicPlan();
          }}
          disabled={disableEditButton}
        />
      </Row>
      <div className={styles.tableWrapper}>
        <InfoLabelSection
          labelText="Plan Name*"
          value={<OverflowPopover>{planName}</OverflowPopover>}
          extraTitleColValue={extraColumnHeaderValue}
        />
        <InfoLabelSection
          labelText="Effective Dates*"
          value={planYearName}
          extraTitleColValue={extraColumnHeaderValue}
        />
        <InfoLabelSection
          labelText="Benefit Classes*"
          value={
            <OverflowPopover>{benefitClasses?.join(', ')}</OverflowPopover>
          }
          extraTitleColValue={extraColumnHeaderValue}
        />
        <InfoLabelSection
          labelText={`${
            type && CARRIER_OPTIONAL_PLAN_TYPES.includes(type)
              ? 'Carrier'
              : 'Carrier*'
          }`}
          value={carrierName ? carrierName : '-'}
          className={styles.carrierStyles}
          extraTitleColValue={extraColumnHeaderValue}
        />
        <InfoLabelSection
          labelText="Group ID / Policy #"
          value={<OverflowPopover>{groupId ? groupId : '-'}</OverflowPopover>}
          extraTitleColValue={extraColumnHeaderValue}
        />
        {isMDVplan.includes(type!) && (
          <InfoLabelSection
            labelText="Plan Network"
            value={
              <OverflowPopover>
                {planNetwork ? planNetwork : '-'}
              </OverflowPopover>
            }
          />
        )}
        {isMDVplan.includes(type!) && (
          <InfoLabelSection
            labelText="Plan Type"
            value={planType ? planType : '-'}
          />
        )}
        {type === BenefitCategory.MEDICAL.value && (
          <InfoLabelSection
            labelText="HSA Eligible"
            value={hsaEligibile ? 'Yes' : 'No'}
          />
        )}
        {isMDVplan.includes(type!) && (
          <InfoLabelSection
            labelText={'HRA Eligible'}
            value={hraEligibile ? 'Yes' : 'No'}
          />
        )}
        {type === BenefitCategory.MEDICAL.value && (
          <InfoLabelSection
            labelText="States & Territories"
            value={
              <OverflowPopover>
                {!isEmpty(statesTerritories)
                  ? statesTerritories?.join(', ')
                  : '-'}
              </OverflowPopover>
            }
          />
        )}
        {(fundingType || isMDVplan.includes(type!)) && (
          <InfoLabelSection
            labelText="Funding Type*"
            value={
              FUNDING_TYPES_LIST.find((type) => type.value == fundingType)
                ?.label
            }
          />
        )}
        {fundingType &&
          fundingType === FundingType.SELF_FUNDED &&
          type === BenefitCategory.MEDICAL.value && (
            <>
              {
                <InfoLabelSection
                  labelText="Individual Stop Loss"
                  value={formatSFCValue(individualStopLoss)}
                />
              }
              {
                <InfoLabelSection
                  labelText="Individual Stop Loss Fee"
                  value={formatSFCValue(individualAdministrationFee)}
                />
              }
              {
                <InfoLabelSection
                  labelText="Aggregate Stop Loss"
                  value={formatSFCValue(aggregatedStopLoss)}
                />
              }
              {
                <InfoLabelSection
                  labelText="Aggregate Stop Loss Fee"
                  value={formatSFCValue(aggregatedAdministrationFee)}
                />
              }
              {
                <InfoLabelSection
                  labelText="3rd Party Admin Fee"
                  value={formatSFCValue(thirdPartyAdministrationFee)}
                />
              }
              {
                <InfoLabelSection
                  labelText="Other Fees"
                  value={formatSFCValue(otherFees)}
                />
              }
            </>
          )}
        {fundingType &&
          fundingType === FundingType.SELF_FUNDED &&
          (type === BenefitCategory.DENTAL.value ||
            type === BenefitCategory.VISION.value ||
            type === BenefitCategory.LIFE.value) && (
            <>
              {
                <InfoLabelSection
                  labelText={
                    type !== BenefitCategory.LIFE.value
                      ? 'Admin Fee (PEPM)'
                      : 'Admin Fee'
                  }
                  value={formatSFCValue(administrationFee)}
                />
              }
            </>
          )}
      </div>
      {type === BenefitCategory.MEDICAL.value && viewPlanModal && (
        <EditMedicalPlanModal
          plan={medicalPlan}
          onClose={onClose}
          isModalVisible={viewPlanModal}
          editType={EditMdvTypes.BASIC_INFO}
          setViewModal={setViewPlanModal}
        />
      )}
      {type === BenefitCategory.DENTAL.value && viewPlanModal && (
        <EditDentalPlanModal
          plan={dentalPlan}
          onClose={onClose}
          isModalVisible={viewPlanModal}
          editType={EditMdvTypes.BASIC_INFO}
          setViewModal={setViewPlanModal}
        />
      )}
      {type === BenefitCategory.VISION.value && viewPlanModal && (
        <EditVisionPlanModal
          plan={visionPlan}
          onClose={onClose}
          isModalVisible={viewPlanModal}
          editType={EditMdvTypes.BASIC_INFO}
          setViewModal={setViewPlanModal}
        />
      )}
      {type === BenefitCategory.WELLBEING.value && viewPlanModal && (
        <EditWellbeingPlanUPWrapper
          plan={wellbeingPlan}
          onClose={onClose}
          isModalVisible={viewPlanModal}
        />
      )}
      {type === BenefitCategory.TELEHEALTH.value && viewPlanModal && (
        <EditTelehealthPlanUPWrapper
          plan={telehealthRxPlan}
          onClose={onClose}
          isModalVisible={viewPlanModal}
        />
      )}
      {type === FAMILY_HOLIDAY_TIME_OFF && viewPlanModal && (
        <EditWorkLifePlanUPWrapper
          plan={workLifePlan}
          onClose={onClose}
          isModalVisible={viewPlanModal}
        />
      )}
      {type === ADDITIONAL_PERKS && viewPlanModal && (
        <EditAdditionalPerkPlanUPWrapper
          plan={additionalPerkPlan}
          onClose={onClose}
          isModalVisible={viewPlanModal}
        />
      )}
      {type === VOLUNTARY && viewPlanModal && (
        <EditVoluntaryBenefitPlanUPWrapper
          plan={voluntaryBenefitPlan}
          onClose={onClose}
          isModalVisible={viewPlanModal}
        />
      )}
      {type === FOUR_O_ONE_K && viewPlanModal && (
        <EditFourOOneKPlan
          plan={retirementPlan}
          onClose={onClose}
          onSave={onClose}
          visible={viewPlanModal}
        />
      )}
      {type === LIFE && viewPlanModal && (
        <EditLifePlan
          plan={lifePlan}
          onCancel={onClose}
          visible={viewPlanModal}
          onSave={onClose}
        />
      )}
      {type === TAX_ADVANTAGED && viewPlanModal && (
        <EditTaxAdvantagedPlanModal
          plan={taxAdvantagedAccountPlan}
          onClose={onClose}
          isModalVisible={viewPlanModal}
          onSave={onClose}
        />
      )}
    </div>
  );
};

export default BasicPlanInfoOverview;
