import { FC } from 'react';
import { Modal } from 'antd';
import get from 'lodash/get';

import EditAdditionalPerkPlan from 'modules/plans/additionalPerk/components/EditAdditionalPerkPlan/EditAdditionalPerkPlan';

import { BenefitCategory } from 'constants/commonConstants';

import IconInfo from 'assets/images/icon-info.svg';

import styles from './editAdditionalPerkPlanUPWrapper.module.less';

type EditAdditionalPerkPlanUPWrapperProps = {
  plan: any;
  onClose: any;
  isModalVisible: boolean;
};
const EditAdditionalPerkPlanUPWrapper: FC<
  EditAdditionalPerkPlanUPWrapperProps
> = ({
  plan,
  onClose,
  isModalVisible,
}: EditAdditionalPerkPlanUPWrapperProps) => {
  return (
    <Modal
      width={600}
      visible={isModalVisible}
      okText="Done"
      footer={false}
      className={styles.additionalPerkPlanChannelModal}
      closable={false}
    >
      <div className={styles.additionalPerkPlanChannelWrapper}>
        <div className={styles.title}>{`Edit ${
          get(BenefitCategory, plan?.benefitKind || '', '').label
        } Plan`}</div>
        <div className={styles.leftBorder}></div>
        <div className={styles.additionalPerkPlanChannel}>
          <div>
            <img src={IconInfo} alt="Icon Info" />
          </div>
          <div className={styles.defaultText}>
            Changing plan information here will change the plan information
            everywhere this plan is shown
          </div>
        </div>
        <EditAdditionalPerkPlan
          plan={plan}
          onClose={onClose}
          isModalVisible={isModalVisible}
        />
      </div>
    </Modal>
  );
};

export default EditAdditionalPerkPlanUPWrapper;
