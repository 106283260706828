import React from 'react';

import { Row } from 'antd';

import styles from './printableIdCardEmployeeData.module.less';

const PrintableIdCardEmployeeData = (props: any) => {
  const { data } = props;
  return (
    <>
      <Row>
        <span className={styles.rowName}>{data.name}</span>
      </Row>
      <Row>
        <span className={styles.rowName}>{data.details}</span>
      </Row>
    </>
  );
};

export default PrintableIdCardEmployeeData;
