import { FC, useState, useEffect, useRef } from 'react';

import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import { useNavContext } from 'hooks/useNavContext';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { exitWithoutSavingMsg } from 'constants/commonConstants';
import AddVoluntaryBenefitPlan from 'modules/plans/voluntary/components/AddVoluntaryBenefitPlan/AddVoluntaryBenefitPlan';

import styles from './addVoluntaryBenefitPlan.module.less';

type AddVolBenefitsPlanDBGWrapperProps = {
  visible: boolean;
  onClose: () => {};
  benefitKind: {
    value:
      | 'ACCIDENT'
      | 'CRITICAL_ILLNESS'
      | 'HOSPITAL'
      | 'CUSTOM_VOLUNTARY_BENEFIT';
    label: string;
  };
  isSaveDisabled?: boolean;
  dbgPlanYear?: string;
  isDBGPlan?: boolean;
  isRenewalProcessStarted: boolean;
};

const AddVoluntaryBenefitsPlanDBGWrapper: FC<
  AddVolBenefitsPlanDBGWrapperProps
> = ({
  visible,
  onClose,
  benefitKind,
  isSaveDisabled = false,
  dbgPlanYear,
  isDBGPlan,
  isRenewalProcessStarted,
}: AddVolBenefitsPlanDBGWrapperProps) => {
  const { employer } = useNavContext();
  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);
  const [isCloseConfirmed, setClosedConfirmed] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const addPlanRef = useRef<any>();
  useEffect(() => {
    if (visible) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [visible]);

  const closePlanAddModal = () => {
    setVisibleConfirmation(true);
    setClosedConfirmed(false);
  };

  const toggleVisibleConfirmation = () => {
    setVisibleConfirmation(false);
    setClosedConfirmed(false);
  };

  const onConfirm = () => {
    setVisibleConfirmation(false);
    setClosedConfirmed(true);
    onClose();
    addPlanRef.current.reset();
  };

  return (
    <>
      <FullScreenModal
        visible={visible}
        onCancel={closePlanAddModal}
        footer={false}
        title={`${employer?.name} - Add ${benefitKind?.label} Plan`}
        className={styles.mediaScreen}
      >
        <div className={styles.addworkLifePlanWrapper}>
          <AddVoluntaryBenefitPlan
            benefitCategory={benefitKind}
            isCloseConfirmed={isCloseConfirmed}
            closePlanAddModal={closePlanAddModal}
            onClose={onClose}
            isSaveDisabled={isSaveDisabled}
            dbgPlanYear={dbgPlanYear}
            isEdit={false}
            isDBGPlan={isDBGPlan}
            isModalVisible={isModalVisible}
            ref={addPlanRef}
            isRenewalProcessStarted={isRenewalProcessStarted}
          />
        </div>
      </FullScreenModal>
      <ConfirmationDialog
        title="Are you sure you want to close?"
        confirmText="Yes - Close and do not save plan"
        cancelText="Cancel"
        closeModal={toggleVisibleConfirmation}
        onConfirm={onConfirm}
        visible={visibleConfirmation}
      >
        <p className={styles.warningConfirmation}>{exitWithoutSavingMsg}</p>
      </ConfirmationDialog>
    </>
  );
};

export default AddVoluntaryBenefitsPlanDBGWrapper;
