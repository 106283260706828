import moment from 'moment';
import momentTz from 'moment-timezone';
import axios from 'axios';
import { lumityApi } from 'api/apiSlice';
import Issue from 'model/Issue';
import IssueComment from 'model/IssueComment';
import { IssueListPostTypes, IssueListReturnTypes } from 'model/issueList';
import { baseApi } from 'util/apiUtil';
import ChartVelocityDto from 'model/ChartVelocityDto';
import CheckIssueLogAnalyticsDto from 'model/CheckIssueLogAnalyticsDto';
import { removeSpan } from 'util/commonUtil';

const issuesLogApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmployersByBrokerId: builder.query<
      any,
      { brokerId: string; individualId: string | null }
    >({
      query: (args) => {
        const { brokerId, individualId } = args;
        return {
          url: `v2/employers/list`,
          method: 'GET',
          params: {
            'organization-id': brokerId,
            'individual-id': individualId,
          },
        };
      },
    }),
    getBrokerAdminsForEmployer: builder.query<
      any,
      { brokerId: string; employerId: string; isActiveAndNonActive?: boolean }
    >({
      query: (args) => {
        const { brokerId, employerId, isActiveAndNonActive } = args;
        return {
          url: `v2/individuals/admins/location`,
          method: 'GET',
          params: {
            'organization-id': brokerId,
            'employer-id': employerId,
            'non-active-exist': isActiveAndNonActive,
          },
        };
      },
    }),
    createIssue: builder.mutation<Issue, Issue>({
      query: (args) => {
        const { employerId, organizationId, files, ...rest } = args;
        const formData = new FormData();
        files?.forEach((file) => {
          formData.append('files', file);
        });
        Object.entries(rest).map(([key, value]: [key: string, value: any]) => {
          formData.append(key, value);
        });

        return {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          url: `v1/issues-log`,
          method: 'post',
          params: {
            'employer-id': employerId,
            'organization-id': organizationId,
          },
          data: formData,
        };
      },
    }),
    getIssueById: builder.query<any, { issueId: string }>({
      query: (args) => {
        const { issueId } = args;
        return {
          url: `v1/issues-log/${issueId}`,
          method: 'GET',
        };
      },
    }),
    createIssueComment: builder.mutation<IssueComment, any>({
      query: ({ employerId, organizationId, issueComment, files }) => {
        const formData = new FormData();
        files.forEach((file: any) => {
          formData.append('files', file);
        });
        Object.entries(issueComment).map(
          ([key, value]: [key: string, value: any]) => {
            formData.append(key, value);
          }
        );
        return {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          url: `v1/issues-log/comment`,
          method: 'post',
          params: {
            'employer-id': employerId,
            'organization-id': organizationId,
          },
          data: formData,
        };
      },
    }),
    getIssueComments: builder.query<any, { issueId: string }>({
      query: (args) => {
        const { issueId } = args;
        return {
          url: `v1/issues-log/${issueId}/comments`,
          method: 'GET',
        };
      },
    }),
    getIndividualById: builder.query<any, { individualId: string }>({
      query: (args) => {
        const { individualId } = args;
        return {
          url: `v2/individuals/${individualId}`,
          method: 'GET',
        };
      },
    }),
    getFile: builder.query<
      any,
      { s3Key: string; fileName: string; downloaded: boolean }
    >({
      query: (args) => {
        const { s3Key, fileName, downloaded } = args;
        return {
          url: `v1/issues-log/download?s3-key=${s3Key}&file-name=${fileName}&downloaded=${downloaded}`,
          method: 'GET',
        };
      },
    }),
    getOrganizationById: builder.query<any, { brokerId: string }>({
      query: (args) => {
        const { brokerId } = args;
        return {
          url: `v2/organizations/${brokerId}`,
          method: 'GET',
        };
      },
    }),
    getIssuesList: builder.query<IssueListReturnTypes, IssueListPostTypes>({
      query: (args) => {
        const {
          organizationIds,
          employerIds,
          searchKey,
          filters,
          page,
          size,
          sort,
          type,
          isDefault,
        } = args;

        const data = {
          criteriaVOList: filters || [],
          searchKey: searchKey,
          organizationIds: organizationIds,
          employerIds: employerIds,
          isDefault: isDefault,
        };
        return {
          url: `v1/issues-log/filter?_page=${page}&_size=${size}${
            sort === '' ? '' : `&_sort=${sort}`
          }&type=${type}`,
          method: 'POST',
          data: data,
        };
      },
    }),
    getAdminsByEmployer: builder.query<
      any,
      { employerId: string; type: string }
    >({
      query: (args) => {
        const { employerId, type } = args;
        return {
          url: `v2/individuals/admins/employer`,
          method: 'GET',
          params: {
            employerId: employerId,
            type: type,
          },
        };
      },
    }),
    getEmployerById: builder.query<any, { employerId: string }>({
      query: (args) => {
        const { employerId } = args;
        return {
          url: `v2/employers/${employerId}`,
          method: 'GET',
        };
      },
    }),
    getSupportTeamById: builder.query<
      any,
      { employerId: string; organizationId: string }
    >({
      query: (args) => {
        const { employerId, organizationId } = args;
        return {
          url: `v2/individuals/team-members?organization-id=${organizationId}&employer-id=${employerId}`,
          method: 'GET',
        };
      },
    }),
    getAllBrokerAdmins: builder.query<
      any,
      { organizationId: string; onlyActiveIndividuals: boolean }
    >({
      query: (args) => {
        const { organizationId, onlyActiveIndividuals } = args;
        return {
          url: `v2/individuals/admins/list`,
          method: 'GET',
          params: {
            active: onlyActiveIndividuals,
            organizationId: organizationId,
            type: 'ORGANIZATION_ADMIN',
          },
        };
      },
    }),
    updateIssue: builder.mutation<any, { data: any; issueId: string }>({
      query: (args) => {
        const { data, issueId } = args;
        return {
          url: `v1/issues-log/${issueId}`,
          method: 'PUT',
          data: data,
        };
      },
    }),
    updateSupportTeamAdminList: builder.mutation<
      any,
      { data: any; brokerId: string }
    >({
      query: (args) => {
        const { data, brokerId } = args;
        return {
          url: `v2/employers/${brokerId}/issues-log-automated-emails-support`,
          method: 'PUT',
          data: data,
        };
      },
    }),
    updateIssueFromList: builder.mutation<any, { data: any; issueId: string }>({
      query: (args) => {
        const { data, issueId } = args;
        return {
          url: `v1/issues-log/${issueId}/field`,
          method: 'PUT',
          data: data,
        };
      },
    }),
    getAllOrganizations: builder.query<any, {}>({
      query: () => {
        return {
          url: `v1/organizations/list`,
          method: 'GET',
        };
      },
    }),
    getAllAdmins: builder.query<any, {}>({
      query: () => {
        return {
          url: `v2/individuals/admins/all?type=ORGANIZATION_ADMIN&active=true`,
          method: 'GET',
        };
      },
    }),
    getAllEmployerAndSupportTeam: builder.query<any, {}>({
      query: () => {
        return {
          url: `v2/employers/all`,
          method: 'GET',
        };
      },
    }),
    getAllSimplifiedEmployerAndSupportTeam: builder.query<any, {}>({
      query: () => {
        return {
          url: `v2/employers/all-simplified`,
          method: 'GET',
        };
      },
    }),
    getAssigneeArchivedToIssues: builder.query<any, { brokerID: string }>({
      query: (args) => {
        const { brokerID } = args;

        const organizationID =
          brokerID !== 'undefined' ? `?organization-id=${brokerID}` : '';
        return {
          url: `v1/issues-log/assignees${organizationID}`,
          method: 'GET',
        };
      },
    }),
    getArchivedSupportTeams: builder.query<any, { brokerID: any }>({
      query: (args) => {
        const { brokerID } = args;
        return {
          url: `v1/issues-log/archived-support-teams`,
          method: 'GET',
          params: {
            'organization-id': brokerID,
          },
        };
      },
    }),
    getAllIndividualsByOrganizationIdAndLocations: builder.query<
      any,
      { brokerID: string; locations: string[]; type: string }
    >({
      query: (args) => {
        const { brokerID, locations, type } = args;

        return {
          url: `v2/individuals/admins/list`,
          method: 'GET',
          params: {
            type: type,
            organizationId: brokerID,
            locations: locations.join(','),
          },
        };
      },
    }),
    getAnalyticsEmployerData: builder.query<
      any,
      {
        brokerID: string;
        type: string;
        adminId: string | null;
        supportTeam: string | null;
      }
    >({
      query: (args) => {
        const { brokerID, type, adminId, supportTeam } = args;
        return {
          url: `v1/issues-log/employer-analytics`,
          method: 'GET',
          params: {
            'organization-id': brokerID,
            'issue-type': type,
            'assignee-id': adminId,
            'support-team': supportTeam,
          },
        };
      },
    }),
    getAnalyticsAdminData: builder.query<
      any,
      {
        brokerID: string;
        type: string;
        employerId: string | null;
        supportTeam: string | null;
      }
    >({
      query: (args) => {
        const { brokerID, type, employerId, supportTeam } = args;
        return {
          url: `v1/issues-log/admin-analytics`,
          method: 'GET',
          params: {
            'organization-id': brokerID,
            'issue-type': type,
            'employer-id': employerId,
            'support-team': supportTeam,
          },
        };
      },
    }),
    getLogVelocityChatData: builder.query<any, ChartVelocityDto>({
      query: (args) => {
        const { brokerId, issueType, supportTeam, timeRange, employerId } =
          args;
        return {
          url: `v1/issues-log/${brokerId}/velocity-chart`,
          method: 'GET',
          params: {
            'employer-id': employerId,
            'issue-type': issueType,
            'support-team': supportTeam,
            'time-range': timeRange,
          },
        };
      },
    }),
    checkIfIssueExistForAnalytics: builder.query<
      any,
      CheckIssueLogAnalyticsDto
    >({
      query: (args) => {
        const { brokerId, issueType, supportTeam } = args;
        return {
          url: `v1/issues-log/${brokerId}/check-availability`,
          method: 'GET',
          params: {
            'issue-type': issueType,
            'support-team': supportTeam,
          },
        };
      },
    }),
    getAnalyticsSummary: builder.query<
      any,
      {
        brokerID: string;
        type: string;
        supportTeam: string | null;
      }
    >({
      query: (args) => {
        const { brokerID, type, supportTeam } = args;
        return {
          url: `v1/issues-log/analytics-summary`,
          method: 'GET',
          params: {
            'organization-id': brokerID,
            'issue-type': type,
            'support-team': supportTeam,
          },
        };
      },
    }),
  }),
});

export const exportIssuesLog = async (
  filters: any[],
  searchKey: string,
  viewLevel: string,
  issuesLogType: string | undefined,
  organizationIds: string[],
  employerIds: string[]
) => {
  const zone = momentTz.tz.guess();

  const response = await axios.post(
    `${baseApi}/v1/issues-log/export?zone=${zone}&view-level=${viewLevel}&issues-type=${issuesLogType}`,
    {
      criteriaVOList: filters,
      searchKey: searchKey,
      organizationIds,
      employerIds,
    },
    {
      responseType: 'blob',
    }
  );

  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `AccountLog_Export_${moment().format('MM-DD-YYYY')}.xlsx`
  );
  document.body && document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const exportIssuesLogAnalyticsAdmin = async (
  brokerID: string | undefined,
  type: string | undefined,
  employerId: string | null,
  supportTeam: string | null,
  searchText: string | null,
  field: string | undefined,
  order: 'ASC' | 'DESC'
) => {
  const response = await axios.post(
    `${baseApi}/v1/issues-log/admin-analytics/export?organization-id=${brokerID}&issue-type=${type}&employer-id=${employerId}&support-team=${supportTeam}&search-team=${searchText}`,
    {
      field: field,
      sortOrder: order,
    },
    {
      responseType: 'blob',
    }
  );
  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
  });

  const suportName =
    supportTeam === '' || null ? 'GeneralSupport' : supportTeam;
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    type === 'ACCOUNT'
      ? `AccountTeam_Admin_Analytics_Export_${moment().format(
          'MM-DD-YYYY'
        )}.xlsx`
      : `${suportName}_Admin_Analytics_Export_${moment().format(
          'MM-DD-YYYY'
        )}.xlsx`
  );
  document.body && document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const exportIssuesLogAnalyticsEmployer = async (
  brokerID: string | undefined,
  type: string | undefined,
  assigneeId: string | null,
  supportTeam: string | null,
  searchText: string | null,
  field: string | undefined,
  order: 'ASC' | 'DESC'
) => {
  const response = await axios.post(
    `${baseApi}/v1/issues-log/employer-analytics/export?organization-id=${brokerID}&issue-type=${type}&assignee-id=${assigneeId}&support-team=${supportTeam}&search-team=${searchText}`,
    {
      field: field,
      sortOrder: order,
    },
    {
      responseType: 'blob',
    }
  );
  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
  });

  const suportName =
    supportTeam === '' || null ? 'GeneralSupport' : supportTeam;
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    type === 'ACCOUNT'
      ? `AccountTeam_Employer_Analytics_Export_${moment().format(
          'MM-DD-YYYY'
        )}.xlsx`
      : `${suportName}_Employer_Analytics_Export_${moment().format(
          'MM-DD-YYYY'
        )}.xlsx`
  );
  document.body && document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const downloadInstructionPdf = (brokerId: string | undefined) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  const url =
    baseApi + `/v1/issues-log/organizations/${brokerId}/setup-instructions`;
  a.href = url;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const {
  useGetEmployersByBrokerIdQuery,
  useLazyGetBrokerAdminsForEmployerQuery,
  useCreateIssueMutation,
  useLazyGetIssueByIdQuery,
  useCreateIssueCommentMutation,
  useLazyGetIssueCommentsQuery,
  useLazyGetIndividualByIdQuery,
  useLazyGetFileQuery,
  useLazyGetOrganizationByIdQuery,
  useLazyGetIssuesListQuery,
  useLazyGetAdminsByEmployerQuery,
  useLazyGetEmployerByIdQuery,
  useLazyGetEmployersByBrokerIdQuery,
  useLazyGetSupportTeamByIdQuery,
  useLazyGetAllBrokerAdminsQuery,
  useUpdateIssueMutation,
  useUpdateIssueFromListMutation,
  useUpdateSupportTeamAdminListMutation,
  useGetAllBrokerAdminsQuery,
  useGetAllOrganizationsQuery,
  useGetSupportTeamByIdQuery,
  useGetOrganizationByIdQuery,
  useGetAllAdminsQuery,
  useGetAllEmployerAndSupportTeamQuery,
  useGetAllSimplifiedEmployerAndSupportTeamQuery,
  useGetAssigneeArchivedToIssuesQuery,
  useGetArchivedSupportTeamsQuery,
  useGetAllIndividualsByOrganizationIdAndLocationsQuery,
  useGetIndividualByIdQuery,
  useGetAnalyticsEmployerDataQuery,
  useGetAnalyticsAdminDataQuery,
  useGetLogVelocityChatDataQuery,
  useCheckIfIssueExistForAnalyticsQuery,
  useGetAnalyticsSummaryQuery,
} = issuesLogApi;

export const handleDlpVerify = async (
  organizationId: string,
  subject: string
) => {
  const cleanedSubject = removeSpan(subject);
  try {
    const response = await axios.post(
      `${baseApi}/v1/issues-log/dlpVerify`,
      null,
      {
        params: {
          'organization-id': organizationId,
          subject: cleanedSubject,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};
export const dlpStatusCheck = async (organizationId: string, key: string) => {
  try {
    const response = await axios.get(
      `${baseApi}/v2/organizations/configurations/feature?`,
      {
        params: {
          'organization-id': organizationId,
          'feature-key': key,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
};
