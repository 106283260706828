import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import dayjs from 'dayjs';
import TextArea from 'antd/lib/input/TextArea';

import InputForm from 'components/InputForm/InputForm';
import LabelWithTooltip from 'components/LabelWithTooltip/LabelWithTooltip';
import BenefitsConsultant from 'model/benefitsConsultation/BenefitsConsultant';
import ConsultantStatus from 'model/benefitsConsultation/ConsultantStatus';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { TIME_ZONES_IN_GMT } from 'modules/benefitsConsultation/constants/benefitsConsultation';
import { EMPTY_MESSAGE } from 'constants/commonConstants';
import { invitedDateFormat } from 'constants/dateFormat';
import {
  inviteConsultants,
  inviteConsultantsInProgress,
  inviteConsultantsSuccess,
} from 'modules/benefitsConsultation/slices/commonSlice';

import styles from './editBasicInfo.module.less';

type EditBasicInfoProps = {
  formData: any;
  form: FormInstance;
  consultant: BenefitsConsultant;
  setAlertMessage: Function;
  setAlertVisible: Function;
  updatedStatus?: ConsultantStatus;
};

const EditBasicInfo = forwardRef((props: EditBasicInfoProps, ref) => {
  const {
    formData,
    form,
    consultant,
    setAlertMessage,
    setAlertVisible,
    updatedStatus,
  } = props;
  const dispatch = useAppDispatch();
  const inProgress = useAppSelector(
    (state) => state.benefitsConsultation.common.inProgress
  );
  const requestType = useAppSelector(
    (state) => state.benefitsConsultation.common.requestType
  );
  const requestTypeInvite = useAppSelector(
    (state) => state.benefitsConsultation.common.requestTypeInvite
  );

  const confRef = useRef<HTMLTextAreaElement>(null);

  useImperativeHandle(ref, () => ({
    initialFocus() {
      confRef.current?.focus();
    },
  }));

  useEffect(() => {
    if (requestType === inviteConsultantsSuccess.type) {
      if (requestType === inviteConsultantsSuccess.type) {
        setAlertVisible(true);
        setAlertMessage({
          type: 'success',
          message: 'Calendar Sync Invite sent successfully.',
        });
      }
    }
  }, [dispatch, requestType, setAlertMessage, setAlertVisible]);

  const sendInvite = () => {
    dispatch(inviteConsultants([consultant]));
  };

  const invitedDate = updatedStatus
    ? updatedStatus.invitedDate
    : formData?.status?.invitedDate;
  const calendarSyncStatus = updatedStatus
    ? updatedStatus.calendarSyncStatus
    : formData?.status?.calendarSyncStatus;

  const linkStyling = `${styles.invLink} ${
    requestTypeInvite === inviteConsultantsInProgress.type && inProgress
      ? styles.loading
      : ''
  }`;

  return (
    <div className={styles.basicInfoEditWrapper}>
      <InputForm form={form} initialValues={formData}>
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item name="fullName" label="Name" labelCol={{ span: 24 }}>
              <Input data-cy="fullName" title={formData.fullName} disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className={styles.filterWrapper}
              label="Email"
              labelCol={{ span: 24 }}
              name="email"
            >
              <Input data-cy="email" title={formData.email} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="timezone"
              label="Time Zone"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                },
              ]}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                options={TIME_ZONES_IN_GMT}
                allowClear
                listHeight={128}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className={styles.filterWrapper}
              label="Calendar Status"
              labelCol={{ span: 24 }}
            >
              {!calendarSyncStatus && !invitedDate && (
                <>
                  <span className={styles.notSynced}>Not Synced -</span>
                  <Button
                    type="link"
                    className={linkStyling}
                    onClick={sendInvite}
                  >
                    Send Invite
                  </Button>
                </>
              )}
              {invitedDate && !calendarSyncStatus && (
                <>
                  <span>
                    Invited&nbsp;
                    {dayjs(invitedDate)?.format(invitedDateFormat) || '-'}
                    &nbsp; -
                  </span>
                  <Button
                    type="link"
                    className={linkStyling}
                    onClick={sendInvite}
                  >
                    Resend Invite
                  </Button>
                </>
              )}
              {calendarSyncStatus && `Synced`}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              name="conferenceDetails"
              label={
                <Row>
                  <LabelWithTooltip
                    content={
                      <>
                        The consultant&apos;s meeting room link and other
                        details should be given here.
                        <br />
                        These details will be shown in the booking confirmation
                        email received by the employee.
                      </>
                    }
                    mandatoryField
                    fieldText="Conference Details "
                  />
                </Row>
              }
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: EMPTY_MESSAGE,
                },
              ]}
            >
              <TextArea ref={confRef} autoSize={{ minRows: 3, maxRows: 5 }} />
            </Form.Item>
          </Col>
        </Row>
      </InputForm>
    </div>
  );
});
EditBasicInfo.displayName = 'EditBasicInfo';
export default EditBasicInfo;
