import { FC, useEffect, useState, useCallback } from 'react';
import { StompSessionProvider } from 'react-stomp-hooks';

import { Col, Row } from 'antd';

import { useParams, useNavigate } from 'react-router-dom';
import { usePermitIf } from 'hooks/usePermitIf';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import EnrollmentOverview from 'modules/plans/components/Enrollments/EnrollmentOverview/EnrollmentOverview';
import BasicPlanInfoOverview from 'modules/plans/components/OverviewPage/BasicPlanInfoOverview/BasicPlanInfoOverview';
import PremiumsOverview from 'modules/plans/components/OverviewPage/PremiumsOverview/PremiumsOverview';
import ServicesOverview from 'modules/plans/components/OverviewPage/ServicesOverview/ServicesOverview';
import PlanDocumentsOverview from 'modules/plans/components/OverviewPage/PlanDocumentsOverview/PlanDocumentOverview';
import DeductiblesOverview from 'modules/plans/components/OverviewPage/DeductiblesOverview/DeductiblesOverview';
import PlanCollaborationNotification from 'components/PlanCollaborationNotification/PlanCollaborationNotification';
import HeaderNavigation from 'modules/plans/components/HeaderNavigation/HeaderNavigation';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import { allowedCommonButtonPermittedTypes } from 'constants/permittedConstants';
import { BenefitCategory } from 'constants/commonConstants';
import { wsBaseApi } from 'util/apiUtil';
import {
  findDentalPlanById,
  getDentalPlanServicesList,
} from 'modules/plans/slices/dentalPlanSlice';

import styles from './dentalOverviewPage.module.less';

const DentalOverviewPage: FC = () => {
  const params = useParams();
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();

  const { dentalPlan, editedDentalPlan, dentalPlanServiceList, inProgress } =
    useAppSelector((state) => state.plan.dentalPlan);

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const navigate = useNavigate();

  const [viewPlanModal, setViewPlanModal] = useState(false);
  const [viewPlanPremiumModal, setViewPlanPremiumModal] = useState(false);
  const [viewPlanEnrollmentModal, setViewEnrollmentModal] = useState(false);
  const [viewPlanDeductibleModal, setViewPlanDeductibleModal] = useState(false);
  const [viewPlanServicesModal, setViewPlanServicesModal] = useState(false);
  const [viewPlanDocumentsModal, setViewPlanDocumentsModal] = useState(false);

  const [disableEditButton, setDisableEditButton] = useState(false);
  const [fetchPlanWhenUpdated, setFetchPlanWenUpdated] =
    useState<boolean>(false);

  const refreshPlan = useCallback(() => {
    if (employerId && brokerId && params.planId) {
      dispatch(findDentalPlanById(params.planId));
    }
    setFetchPlanWenUpdated(false);
  }, [dispatch, employerId, brokerId, params.planId]);

  useEffect(() => {
    refreshPlan();
  }, [refreshPlan]);

  useEffect(() => {
    dispatch(
      getDentalPlanServicesList(BenefitCategory.DENTAL.value, employerId || '')
    );
  }, [dispatch, employerId]);

  const getDefaultDentalServices = () => {
    return dentalPlanServiceList.defaults
      .filter((service) => service.isDefault && service.isSaasSupported)
      .map((service) => ({
        shortName: service.shortName,
        code: service.code ?? 0,
      }));
  };

  const exitPlan = () => {
    navigate(`/brokers/${brokerId}/employers/${employerId}/dental`);
  };

  const updateViewPlanModal = (value: boolean) => {
    setViewPlanModal(value);
  };

  const updateViewPlanPremiumModal = (value: boolean) => {
    setViewPlanPremiumModal(value);
  };

  const updateViewPlanEnrollment = (value: boolean) => {
    setViewEnrollmentModal(value);
  };

  const updateViewPlanDeductibleModal = (value: boolean) => {
    setViewPlanDeductibleModal(value);
  };

  const updateViewPlanServicesModal = (value: boolean) => {
    setViewPlanServicesModal(value);
  };

  const updateViewPlanDocumentsModal = (value: boolean) => {
    setViewPlanDocumentsModal(value);
  };

  const navigation = {
    name: 'All Dental Plans',
    link: `/brokers/${brokerId}/employers/${employerId}/dental`,
  };

  const securedBreadcrumb = usePermitIf(
    <BreadcrumbNavigation breadCrumbData={[navigation]} />,
    allowedCommonButtonPermittedTypes,
    []
  );

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col span={24}>
          <Row>{securedBreadcrumb}</Row>
          <Row>
            <Col span={18}>
              <h1 className={styles.pageTitle}>
                {dentalPlan?.name && (
                  <OverflowPopover>{`${dentalPlan.name}`}</OverflowPopover>
                )}
              </h1>
            </Col>
          </Row>
          <Row>
            <HeaderNavigation
              headerItems={[
                {
                  headerName: 'Basic Plan Info',
                  headerNavigation: '#Plan-Info',
                },
                {
                  headerName: 'Premiums & Contributions',
                  headerNavigation: '#Premium',
                },
                {
                  headerName: 'Deductibles & Maximums',
                  headerNavigation: '#Deductible',
                },
                { headerName: 'Services', headerNavigation: '#Service' },
                {
                  headerName: 'Enrollments',
                  headerNavigation: '#Enrollments',
                },
                { headerName: 'Plan Resources', headerNavigation: '#Plan_Doc' },
              ]}
            />
          </Row>
          <div className={styles.overview} id={'Plan-Info'}>
            <StompSessionProvider
              url={`${wsBaseApi}/websocket-plan-update`}
              onWebSocketError={(e) => console.error(e)}
              reconnectDelay={500}
            >
              <PlanCollaborationNotification
                loggedInUserIndividualId={appBootInfo?.individualId}
                exitPlan={() => exitPlan()}
                refreshPlan={() => refreshPlan()}
                planId={dentalPlan.name}
                isInEdit={
                  viewPlanModal ||
                  viewPlanPremiumModal ||
                  viewPlanDeductibleModal ||
                  viewPlanServicesModal ||
                  viewPlanEnrollmentModal ||
                  viewPlanDocumentsModal
                }
                disableEdit={() => setDisableEditButton(true)}
                fetchPlan={() => setFetchPlanWenUpdated(true)}
              />
            </StompSessionProvider>
            <BasicPlanInfoOverview
              heading={'Basic Plan Info'}
              planName={dentalPlan.name}
              planYearName={dentalPlan.planYear?.name}
              benefitClasses={dentalPlan.groups}
              carrierName={
                dentalPlan.benefitCarrier ? dentalPlan.benefitCarrier?.name : ''
              }
              planNetwork={dentalPlan.planNetworkName}
              type={'DENTAL'}
              viewPlanModal={viewPlanModal}
              setViewPlanModal={updateViewPlanModal}
              disableEditButton={disableEditButton}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              exitPlan={() => exitPlan()}
              fundingType={dentalPlan.fundingType}
              administrationFee={dentalPlan.administrationFee}
              groupId={dentalPlan.groupId}
              hraEligibile={dentalPlan.hraCompatible}
              planType={dentalPlan.type}
            />
          </div>
          <div className={styles.overview} id={'Premium'}>
            <PremiumsOverview
              heading={'Premiums & Contributions'}
              plan={dentalPlan}
              benefitKind={BenefitCategory.DENTAL.value}
              disableEditButton={disableEditButton}
              editedPlan={editedDentalPlan}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              viewPlanPremiumModal={viewPlanPremiumModal}
              setViewPlanPremiumModal={(value) =>
                updateViewPlanPremiumModal(value)
              }
            />
          </div>
          <div className={styles.overview} id={'Deductible'}>
            <DeductiblesOverview
              heading={'Deductibles & Maximums'}
              plan={dentalPlan}
              benefitKind={BenefitCategory.DENTAL.value}
              disableEditButton={disableEditButton}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              viewPlanDeductibleModal={viewPlanDeductibleModal}
              setViewPlanDeductibleModal={(value) =>
                updateViewPlanDeductibleModal(value)
              }
            />
          </div>
          <div className={styles.overview} id={'Service'}>
            <ServicesOverview
              heading={'Services'}
              plan={dentalPlan}
              benefitKind={BenefitCategory.DENTAL.value}
              disableEditButton={disableEditButton}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              defaultServices={getDefaultDentalServices()}
              isLoading={inProgress}
              viewPlanServicesModal={viewPlanServicesModal}
              setViewPlanServicesModal={(value) =>
                updateViewPlanServicesModal(value)
              }
            />
          </div>
          <div className={styles.overview} id={'Enrollments'}>
            <EnrollmentOverview
              heading={'Enrollments'}
              plan={dentalPlan}
              editedPlan={editedDentalPlan}
              benefitKind={BenefitCategory.DENTAL.value}
              disableEditButton={disableEditButton}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              viewPlanEnrollmentModal={viewPlanEnrollmentModal}
              setViewPlanEnrollmentModal={(value) =>
                updateViewPlanEnrollment(value)
              }
            />
          </div>
          <div className={styles.overview} id={'Plan_Doc'}>
            <PlanDocumentsOverview
              heading={'Plan Resources'}
              plan={dentalPlan}
              documents={dentalPlan.documents}
              benefitKind={BenefitCategory.DENTAL.value}
              disableEditButton={disableEditButton}
              viewPlanDocumentsModal={viewPlanDocumentsModal}
              setViewPlanDocumentsModal={(value) =>
                updateViewPlanDocumentsModal(value)
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DentalOverviewPage;
