import { useCallback, useEffect, useState } from 'react';
import { Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useNavContext } from 'hooks/useNavContext';
import { useAppSelector } from 'hooks/redux';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import { useGetAiExtractionHistoryQuery } from 'modules/plans/slices/documentExtractionSlice';
import {
  AI_PLAN_HISTORY_DELETE_MESSAGE,
  AI_FILE_TYPE,
  SBC_PROCESSING_POP_UP,
} from 'modules/plans/constants';
import { ReactComponent as AlertInfoGrey } from 'assets/images/icon-info.svg';
import { ReviewType } from 'modules/plans/enums/ReviewType';
import { ReactComponent as RfpIconSelected } from 'assets/images/Rfp-ai-selected.svg';
import { ReactComponent as TrashIcon } from 'assets/images/trash-gray.svg';
import { ReactComponent as PopoutIcon } from 'assets/images/popout-icon-blue.svg';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { deleteSBC } from 'modules/plans/services/AiSBCUploaderService';

import styles from './aiPlanUploadHistoryModal.module.less';

interface JobItem {
  key: string;
  documentName: string;
  startedTime: Date;
  status: ProcessStatus;
  reviewType: ReviewType;
  createdBy: string;
  isReadyForReview: boolean;
}

type AiPlanUploadHistoryModalProps = {
  isOpen?: boolean;
  benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE';
  planYearName?: string;
  onClose?: () => any;
  openSBCplanModal?: (jobId: string) => void;
  refetch?: () => void;
};

const AiPlanUploadHistoryModal = ({
  isOpen,
  onClose = () => {},
  planYearName,
  openSBCplanModal,
  benefit,
}: AiPlanUploadHistoryModalProps) => {
  const { brokerId, employerId } = useNavContext();
  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );
  const { data, refetch, isFetching, isLoading } =
    useGetAiExtractionHistoryQuery({
      benefit: benefit ?? '',
      brokerId: brokerId || '',
      employerId: employerId || '',
      fileType:
        benefit === 'MEDICAL' ? AI_FILE_TYPE.SBC : AI_FILE_TYPE.BENEFIT_SUMMARY,
    });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<{
    jobId: string;
    open: boolean;
  }>({ jobId: '', open: false });
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const determineStatus = (isOpsAdmin: boolean, item: JobItem) => {
    const { SUCCESS, FAILED, PROCESSING } = ProcessStatus;
    const { AUTOMATIC } = ReviewType;

    const thresholdReached = item?.isReadyForReview;

    if (!isOpsAdmin) return item?.status;

    if (isOpsAdmin) {
      if (item.status === SUCCESS && item.reviewType === AUTOMATIC) {
        if (thresholdReached) {
          return SUCCESS;
        }
        return FAILED;
      } else if (item.status === FAILED && item.reviewType === AUTOMATIC) {
        return PROCESSING;
      }
    }

    return item?.status;
  };
  const mapDataToJobItems = useCallback(
    (data, isOpsAdmin) => {
      if (data) {
        return data?.map((item: any) => {
          let planName = '';

          try {
            planName = decodeURIComponent(item?.planName ?? '');
          } catch (error) {
            if (error instanceof URIError) {
              console.error('Malformed planName URL:', error);
              planName = item?.planName ?? ''; // Fallback to the original value
            } else {
              throw error;
            }
          }

          return {
            key: item?.id,
            documentName: item?.documentName,
            source: item?.source,
            planName: planName,
            startedTime: item?.startedTime
              ? new Date(item?.startedTime)
              : undefined,
            status: determineStatus(isOpsAdmin, item) as ProcessStatus,
            reviewType: item?.reviewType as ReviewType,
            createdBy: item?.createdBy,
            isReadyForReview: item?.isReadyForReview,
          };
        });
      }
      return [];
    },
    // disabling because this function only needs to run when the data is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, isOpsAdmin]
  );

  const filterJobItems = (
    items: JobItem[] = [],
    isOpsAdmin: boolean
  ): JobItem[] => {
    const allowedStatuses = [
      ProcessStatus.REVIEWED,
      ProcessStatus.PROCESSING,
      ProcessStatus.SUCCESS,
      ProcessStatus.SAVED,
      ProcessStatus.FAILED,
      ProcessStatus.REJECTED,
    ];

    return items?.filter((item) => {
      const allowedReviewedTypes = isOpsAdmin
        ? [ReviewType.MANUAL]
        : [ReviewType.MANUAL, ReviewType.AUTOMATIC];

      if (
        isOpsAdmin &&
        item?.reviewType === ReviewType.AUTOMATIC &&
        [ProcessStatus.FAILED, ProcessStatus.REJECTED].includes(item?.status)
      ) {
        allowedReviewedTypes?.push(ReviewType.AUTOMATIC);
      } else if (
        isOpsAdmin &&
        item?.reviewType === ReviewType.AUTOMATIC &&
        item?.status === ProcessStatus.REVIEWED
      ) {
        allowedReviewedTypes?.push(ReviewType.AUTOMATIC);
      }

      if (
        isOpsAdmin &&
        item?.reviewType === ReviewType.AUTOMATIC &&
        item?.status === ProcessStatus.SAVED
      ) {
        allowedReviewedTypes?.push(ReviewType.AUTOMATIC);
      }

      if (
        isOpsAdmin &&
        item?.reviewType === ReviewType.AUTOMATIC &&
        item?.status === ProcessStatus.SUCCESS &&
        item?.isReadyForReview
      ) {
        allowedReviewedTypes?.push(ReviewType.AUTOMATIC);
      }

      return (
        allowedReviewedTypes?.includes(item?.reviewType) &&
        allowedStatuses?.includes(item.status)
      );
    });
  };

  const jobItems = mapDataToJobItems(data, isOpsAdmin) ?? [];
  const filteredJobItems = filterJobItems(jobItems, isOpsAdmin) ?? [];

  const handleDeleteFile = async () => {
    setIsDeleting(true);
    if (isDeleteModalOpen.jobId) {
      await deleteSBC(isDeleteModalOpen.jobId).then(() => {
        refetch();
        setIsDeleteModalOpen({ jobId: '', open: false });
      });
    }
    setIsDeleting(false);
  };

  const openPlanModal = (jobId: string) => {
    onClose();
    openSBCplanModal?.(jobId ?? '');
  };

  const formatDateTime = (dateTime: string) => {
    return dayjs(dateTime).format('MMM DD, YYYY hh:mm A');
  };

  const inProgressDisabled = () => {
    return (
      <b className={`${!isOpsAdmin && styles.isDisabled} ${styles.flexRow}`}>
        In Progress...
        {!isOpsAdmin && (
          <PlanyearPopover
            content={SBC_PROCESSING_POP_UP}
            zIndex={9999}
            placement="top"
          >
            <AlertInfoGrey className={styles.alertInfoIcon} />
          </PlanyearPopover>
        )}
      </b>
    );
  };

  const inProgressEnabled = (id: string, isOpsAdmin?: boolean) => {
    if (isOpsAdmin) {
      return (
        <LinkButton onClick={() => openPlanModal?.(id)}>
          Ready For Review
        </LinkButton>
      );
    }
    return (
      <LinkButton onClick={() => openPlanModal?.(id)}>In Progress</LinkButton>
    );
  };

  const getStatus = (status: ProcessStatus, key: any) => {
    switch (status) {
      case ProcessStatus.REVIEWED:
        return (
          <LinkButton
            className={isOpsAdmin ? styles.isProcessed : styles.isdefaultview}
            onClick={() => openPlanModal?.(key.key)}
          >
            Ready For Review
          </LinkButton>
        );
      case ProcessStatus.SUCCESS:
        if (!isOpsAdmin) {
          return inProgressDisabled();
        } else {
          return inProgressEnabled(key.key, isOpsAdmin);
        }
      case ProcessStatus.PROCESSING:
        if (!isOpsAdmin) {
          if (key.reviewType === ReviewType.AUTOMATIC) {
            return inProgressEnabled(key.key);
          }
          return inProgressDisabled();
        } else {
          return inProgressDisabled();
        }

      case ProcessStatus.FAILED:
        if (!isOpsAdmin) {
          if (key.reviewType === ReviewType.AUTOMATIC) {
            return (
              <div className={styles.errorTagInStatusAI}>
                <b>Action Required</b>
                <PopoutIcon
                  className={styles.trashIcon}
                  onClick={() => openPlanModal(key?.key)}
                />
              </div>
            );
          }
          return inProgressDisabled();
        } else {
          return (
            <div className={styles.errorTagInStatusAI}>
              <b>Error in Processing</b>
              <PopoutIcon
                className={styles.trashIcon}
                onClick={() => openPlanModal(key?.key)}
              />
            </div>
          );
        }
      case ProcessStatus.SAVED:
        if (!isOpsAdmin) {
          return <b>Saved to Plans</b>;
        } else {
          return (
            <LinkButton
              className={styles.isProcessed}
              onClick={() => openPlanModal?.(key.key)}
            >
              Ready For Review
            </LinkButton>
          );
        }
      case ProcessStatus.REJECTED:
        if (!isOpsAdmin) {
          return (
            <div className={styles.errorTagInStatusAI}>
              <b>Processing Failed</b>
            </div>
          );
        } else {
          return (
            <div className={styles.errorTagInStatusAI}>
              <b>Error in Processing</b>
              <PopoutIcon
                className={styles.trashIcon}
                onClick={() => openPlanModal(key?.key)}
              />
            </div>
          );
        }
    }
  };

  const opsColumns: ColumnsType<any> = [
    {
      title: 'FILE NAME',
      dataIndex: 'documentName',
      key: 'documentName',
      width: '30%',
      render: (text: string, record) => {
        const queryParams = `planId=${record?.key}`;
        return (
          <>
            <b>{text}</b>
            <div
              className={styles.hiddenZipDownloadSpan}
              onClick={() => {
                const url = `${window.location.pathname}/jsonerror?${queryParams}`;
                window.open(url, '_blank');
              }}
            >
              --{'>'} ViewDetails
            </div>
          </>
        );
      },
    },
    {
      title: 'PLAN NAME',
      dataIndex: 'planName',
      key: 'planName',
      width: '20%',
      render: (text: string, record) => {
        return <>{record?.planName ? record?.planName : '-'}</>;
      },
    },
    {
      title: 'STARTED TIME',
      dataIndex: 'startedTime',
      key: 'startedTime',
      width: '25%',
      render: (text: string, record) => {
        return (
          <>
            {formatDateTime(text)} by {record.createdBy}
          </>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: getStatus,
      width: '25%',
    },
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'FILE NAME',
      dataIndex: 'documentName',
      key: 'documentName',
      width: '22%',
      render: (text: string, record) => {
        const queryParams = `planId=${record?.key}`;
        return (
          <>
            <b>{text}</b>
            <div
              className={styles.hiddenZipDownloadSpan}
              onClick={() => {
                const url = `${window.location.pathname}/jsonerror?${queryParams}`;
                window.open(url, '_blank');
              }}
            >
              --{'>'} ViewDetails
            </div>
          </>
        );
      },
    },
    {
      title: 'PLAN NAME',
      dataIndex: 'planName',
      key: 'planName',
      width: '20%',
      render: (text: string, record) => {
        return <>{record?.planName ? record?.planName : '-'}</>;
      },
    },
    {
      title: 'STARTED TIME',
      dataIndex: 'startedTime',
      key: 'startedTime',
      width: '25%',
      render: (text: string, record) => {
        return (
          <>
            {formatDateTime(text)} by {record.createdBy}
          </>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: getStatus,
      width: '20%',
    },
    {
      title: 'REMOVE',
      key: 'key',
      dataIndex: 'key',
      width: '10%',
      render: (_, record: any) => {
        const isSelfReviewFailed =
          record?.reviewType === ReviewType.AUTOMATIC &&
          [ProcessStatus.FAILED, ProcessStatus.REJECTED].includes(
            record?.status
          );
        if (
          [
            ProcessStatus.REVIEWED,
            ProcessStatus.SAVED,
            ProcessStatus.REJECTED,
            isSelfReviewFailed && ProcessStatus.FAILED,
          ].includes(record.status)
        ) {
          return (
            <Row justify="center">
              <TrashIcon
                className={styles.trashIcon}
                onClick={() =>
                  setIsDeleteModalOpen({ jobId: record.key, open: true })
                }
              />
            </Row>
          );
        }
      },
    },
  ];

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <ConfirmationDialog
        centered
        className={styles.aiPlanHistoryModal}
        confirmBtnFullWidth
        visible={isOpen}
        closable
        closeModal={onClose}
        width={950}
        icon={<RfpIconSelected />}
        title={'Plan Upload History'}
        confirmText="Close"
        destroyOnClose
        onConfirm={onClose}
        isCancelApplicable={false}
      >
        <p className={styles.planYearTag}>{planYearName}</p>
        <p>
          Files added via the AI Plan Uploader tool must be reviewed before
          saving the new plan into the system.
        </p>
        <Table
          loading={isFetching || isLoading}
          pagination={false}
          columns={isOpsAdmin ? opsColumns : columns}
          dataSource={filteredJobItems}
          scroll={{ y: 400 }}
          className={styles.uploadHistoryTable}
        />
      </ConfirmationDialog>
      <ConfirmationDialog
        centered
        confirmLoading={isFetching || isLoading || isDeleting}
        visible={isDeleteModalOpen.open}
        closeModal={() => setIsDeleteModalOpen({ jobId: '', open: false })}
        title={'Delete File'}
        confirmText="Yes - Delete File"
        isCancelLink
        cancelText="Cancel"
        destroyOnClose
        onConfirm={handleDeleteFile}
        zIndex={1050}
        className={styles.deleteConfirmationModal}
      >
        <p>{AI_PLAN_HISTORY_DELETE_MESSAGE}</p>
      </ConfirmationDialog>
    </>
  );
};

export default AiPlanUploadHistoryModal;
