import { FC, useEffect, useState, useCallback } from 'react';
import { StompSessionProvider } from 'react-stomp-hooks';
import { Col, PageHeader, Row } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';

import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import PlanDocumentsOverview from 'modules/plans/components/OverviewPage/PlanDocumentsOverview/PlanDocumentOverview';
import BasicPlanInfoOverview from 'modules/plans/components/OverviewPage/BasicPlanInfoOverview/BasicPlanInfoOverview';

import { allowedCommonButtonPermittedTypes } from 'constants/permittedConstants';
import { useNavContext } from 'hooks/useNavContext';
import { usePermitIf } from 'hooks/usePermitIf';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { findPlanById } from 'modules/plans/slices/voluntaryBenefitPlanSlice';

import { wsBaseApi } from 'util/apiUtil';
import PlanCollaborationNotification from 'components/PlanCollaborationNotification/PlanCollaborationNotification';

import styles from './voluntaryOverview.module.less';

type VoluntaryOverViewPageProps = {};

const VoluntaryOverViewPage: FC<VoluntaryOverViewPageProps> = (
  props: VoluntaryOverViewPageProps
) => {
  const { employerId, brokerId } = useNavContext();
  const params = useParams();
  const dispatch = useAppDispatch();

  const [viewPlanDocumentsModal, setViewPlanDocumentsModal] = useState(false);

  const navigation = {
    name: 'All Voluntary Benefits Plans',
    link: `/brokers/${brokerId}/employers/${employerId}/voluntary`,
  };

  const SecuredBreadcrumb = usePermitIf(
    <BreadcrumbNavigation breadCrumbData={[navigation]} />,
    allowedCommonButtonPermittedTypes,
    []
  );

  const { voluntaryBenefitPlan } = useAppSelector(
    (state) => state.plan.voluntaryBenefitPlan
  );

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const navigate = useNavigate();

  const [viewPlanModal, setViewPlanModal] = useState(false);
  const [disableEditButton, setDisableEditButton] = useState(false);
  const [fetchPlanWhenUpdated, setFetchPlanWenUpdated] =
    useState<boolean>(false);

  const refreshPlan = useCallback(() => {
    if (employerId && brokerId && params.planId) {
      dispatch(findPlanById(params.planId));
    }
    setFetchPlanWenUpdated(false);
  }, [dispatch, employerId, brokerId, params.planId]);

  useEffect(() => {
    if (employerId && brokerId && params.planId && !voluntaryBenefitPlan) {
      dispatch(findPlanById(params.planId));
    }
    setFetchPlanWenUpdated(false);
  }, [dispatch, employerId, brokerId, params.planId, voluntaryBenefitPlan]);

  const exitPlan = () => {
    navigate(`/brokers/${brokerId}/employers/${employerId}/voluntary`);
  };

  const updateViewPlanModal = (value: boolean) => {
    setViewPlanModal(value);
  };

  const updateViewPlanDocumentsModal = (value: boolean) => {
    setViewPlanDocumentsModal(value);
  };

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col span={24}>
          <Row>{SecuredBreadcrumb}</Row>
          <Col span={18}>
            <PageHeader
              className={styles.pageTitle}
              title={
                voluntaryBenefitPlan.name ? `${voluntaryBenefitPlan.name} ` : ''
              }
            />
          </Col>
        </Col>
      </Row>

      <div className={styles.overView}>
        <StompSessionProvider
          url={`${wsBaseApi}/websocket-plan-update`}
          onWebSocketError={(e) => console.error(e)}
          reconnectDelay={500}
        >
          <PlanCollaborationNotification
            loggedInUserIndividualId={appBootInfo?.individualId}
            exitPlan={() => exitPlan()}
            refreshPlan={() => refreshPlan()}
            planId={voluntaryBenefitPlan.name}
            isInEdit={viewPlanModal}
            disableEdit={() => setDisableEditButton(true)}
            fetchPlan={() => setFetchPlanWenUpdated(true)}
          />
        </StompSessionProvider>
        <div className={styles.basicInfoWrapper}>
          <BasicPlanInfoOverview
            heading={'Plan Details'}
            planName={voluntaryBenefitPlan.name}
            planYearName={voluntaryBenefitPlan.planYear?.name}
            benefitClasses={voluntaryBenefitPlan.groups}
            carrierName={voluntaryBenefitPlan.benefitCarrier?.name}
            type={'VOLUNTARY'}
            viewPlanModal={viewPlanModal}
            setViewPlanModal={updateViewPlanModal}
            disableEditButton={disableEditButton}
            fetchPlanWhenUpdated={fetchPlanWhenUpdated}
            exitPlan={() => exitPlan()}
            groupId={voluntaryBenefitPlan.groupId}
          />
        </div>
        <div className={styles.overview} id={'Plan_Doc'}>
          <PlanDocumentsOverview
            heading={'Plan Resources'}
            plan={voluntaryBenefitPlan}
            documents={voluntaryBenefitPlan?.documents}
            benefitKind={voluntaryBenefitPlan.benefitKind}
            viewPlanDocumentsModal={viewPlanDocumentsModal}
            setViewPlanDocumentsModal={(value) =>
              updateViewPlanDocumentsModal(value)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default VoluntaryOverViewPage;
