import React, { FC } from 'react';
import { Col, Row } from 'antd';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import styles from './renewalCommerAlert.module.less';

type IProps = {
  mainText: string;
  subText: string;
  mainBtnTxt?: string;
  mainBtnHandler?: () => void;
  secondaryBtnTxt?: string;
  secondaryBtnHandler?: any;
  alertIcon: any;
  mainBtnStyling?: string;
  secondaryBtnStyling?: string;
  isFloatingNoDataView?: boolean;
  isErAdmin: boolean;
  isMainBtnDisabled?: boolean;
};

const RenewalCommonAlert: FC<IProps> = ({
  mainText,
  subText,
  mainBtnHandler,
  secondaryBtnHandler,
  mainBtnTxt,
  mainBtnStyling,
  secondaryBtnTxt,
  alertIcon,
  secondaryBtnStyling,
  isFloatingNoDataView = false,
  isErAdmin = false,
  isMainBtnDisabled = false,
}) => {
  return (
    <Row
      className={`${styles.emptyAlert} ${
        !isFloatingNoDataView && styles.nonFloatingNoDataView
      }`}
    >
      <Col xl={15}>
        {alertIcon}
        <p className={styles.alertMainText}>{mainText}</p>
        {!isErAdmin && <p className={styles.alertDescription}>{subText}</p>}
        {mainText && mainBtnHandler && !isErAdmin && (
          <PageActionButton
            type="primary"
            onClick={mainBtnHandler}
            className={mainBtnStyling}
            disabled={isMainBtnDisabled}
          >
            {mainBtnTxt}
          </PageActionButton>
        )}
        {secondaryBtnTxt && secondaryBtnHandler && (
          <PageActionButton
            type="primary"
            onClick={secondaryBtnHandler}
            className={secondaryBtnStyling}
          >
            {secondaryBtnTxt}
          </PageActionButton>
        )}
      </Col>
    </Row>
  );
};

export default RenewalCommonAlert;
