import React, { useCallback, useState } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import {
  BROKER_ENABLE_WARNING,
  ENABLE_AI_ASSISTANT_WARNING,
  ENABLE_AI_HEADER,
} from 'constants/aiAsstantConfigurationConstants';
import styles from './validateSeats.module.less';

type Props = {
  employerName: string;
  onConfirm: () => void;
  visible: boolean;
  onCancel: () => void;
  isBrokerWarning?: boolean;
};

const EnableAssistant = (props: Props) => {
  const { onConfirm, visible, employerName, onCancel, isBrokerWarning } = props;

  return (
    <div>
      <ConfirmationDialog
        width={464}
        title={
          <>
            {ENABLE_AI_HEADER}
            <p className={styles.employerName}>{employerName}</p>
          </>
        }
        onConfirm={onConfirm}
        centered
        confirmBtnFullWidth
        isDanger={false}
        isCancelLink
        cancelText="Cancel"
        confirmText={ENABLE_AI_HEADER}
        closeModal={() => onCancel()}
        visible={visible}
      >
        <FixedAlertMessage
          className={styles.alertMessageWarning}
          type="warning"
          message={
            !isBrokerWarning
              ? ENABLE_AI_ASSISTANT_WARNING
              : BROKER_ENABLE_WARNING
          }
        />
      </ConfirmationDialog>
    </div>
  );
};

const useValidateSeats = () => {
  const [show, setShow] = useState<boolean>(false);
  const [resolvePromise, setResolvePromise] = useState<any>(null);

  const validate: any = (limit: number, count: number) => {
    return new Promise((resolve, reject) => {
      if (limit > count) {
        resolve(true);
      } else {
        setResolvePromise(() => resolve);
        setShow(true);

        setResolvePromise(() => (result: boolean) => {
          if (result) {
          }
          resolve(result);
        });
      }
    });
  };

  type SeatValidateModalProps = {
    employerName: string;
    isBrokerWarning?: boolean;
  };

  const handleConfirm = useCallback(() => {
    if (resolvePromise) {
      resolvePromise(true);
      setShow(false);
    }
  }, [resolvePromise]);

  const handleCancel = useCallback(() => {
    if (resolvePromise) {
      resolvePromise(false);
      setShow(false);
    }
  }, [resolvePromise]);

  const SeatValidateModal = (props: SeatValidateModalProps) => (
    <EnableAssistant
      visible={show}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      employerName={props.employerName}
      isBrokerWarning={props.isBrokerWarning}
    />
  );

  return {
    validate,
    SeatValidateModal,
  };
};

export default useValidateSeats;
