import { useState } from 'react';

import DBGClient from 'modules/clients/DBGClient/DBGClient';
import {
  BENGUIDE_ACK_NOTIFICATION,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  BENGUIDE_SAVE_COMPLETED,
  EXIT_BENGUIDE,
  OPEN_ADD_NEW_PLAN_MODAL,
  OPEN_TAX_ADVANTAGED_PLAN_EDIT_MODAL,
  RELOAD_TAX_ADVANTAGED_PLAN,
  RELOAD_TAX_ADVANTAGED_PLAN_LIST,
  TAX_ADVANTAGED_PLAN_CHANNEL,
  CLOSE_MODALS,
} from 'modules/clients/DBGClient/DBGClientConts';
import AddTaxAdvantagePlanModal from 'modules/plans/taxAdvantaged/components/AddTaxAdvantagedModal/AddTaxAdvantagedModal';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch } from 'hooks/redux';

import { TaxAdvantagedAccountPlan } from 'model/plans/TaxAdvantagedAccountPlan';
import useDBGClient from 'modules/clients/DBGClient/useDBGClient';
import { changedBenguideModalState } from 'modules/benefitGuide/slices/benguideSlice';
import BenefitGuideNotificationsChannel from 'modules/clients/DBGClient/channels/BenefitGuideNotificationsChannel';

import {
  TaxAdvantagedAccountBenefitKind,
  TaxAdvantagedAccountPlanType,
} from 'modules/plans/constants';
import EditTaxAdvantagedPlanModal from 'modules/plans/taxAdvantaged/components/EditTaxAdvantagedPlan/EditTaxAdvantagedPlanModal';
import { BenefitCategory } from 'constants/commonConstants';

type CreatePlanData = {
  benefitKind: string;
  planYearId: string;
};

const TaxAdvantageChannel = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createPlanData, setCreatePlanData] = useState<CreatePlanData>({
    benefitKind: '',
    planYearId: '',
  });
  const [plan, setPlan] = useState<TaxAdvantagedAccountPlan | null>(null);
  const [subscribedEvent, setSubscribedEvent] = useState('');
  const [planYearId, setPlanYearID] = useState<string>('');
  const [disableSave, setDisableSave] = useState(false);
  const dbgClient = useDBGClient();
  const { employer } = useNavContext();
  const dispatch = useAppDispatch();

  const cancelModalWhenRefresh = () => {
    setIsModalVisible(false);
  };

  const cancelModalWhenExit = () => {
    setIsModalVisible(false);
    dispatch(changedBenguideModalState(false));
  };

  const showOverlay = () => {
    if (disableSave) {
      dbgClient.postMessage({
        channel: TAX_ADVANTAGED_PLAN_CHANNEL,
        event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
        data: {},
      });
    }
  };

  const handleClientEvent = (
    event: string,
    data: unknown,
    dbgMetaData: any
  ) => {
    switch (event) {
      case OPEN_TAX_ADVANTAGED_PLAN_EDIT_MODAL:
        setPlan(data as TaxAdvantagedAccountPlan);
        setSubscribedEvent(event);
        setIsModalVisible(true);
        setDisableSave(false);
        break;
      case OPEN_ADD_NEW_PLAN_MODAL:
        setCreatePlanData(data as CreatePlanData);
        setSubscribedEvent(event);
        setIsModalVisible(true);
        setPlanYearID(dbgMetaData?.planYearId);
        setDisableSave(false);
        break;
      case EXIT_BENGUIDE:
        cancelModalWhenExit();
        break;
      case BENGUIDE_SAVE_COMPLETED:
        cancelModalWhenRefresh();
        break;
      case BENGUIDE_ACK_NOTIFICATION:
        setDisableSave(true);
        break;
      // TODO: handle notification events
    }
  };

  const eventComponentMapper = {
    [OPEN_ADD_NEW_PLAN_MODAL]: {
      component: AddTaxAdvantagePlanModal,
      props: {
        visible: isModalVisible,
        employerName: employer?.name,
        planYearId: createPlanData.planYearId,
        planType: {
          value:
            TaxAdvantagedAccountPlanType[
              createPlanData.benefitKind as TaxAdvantagedAccountBenefitKind
            ]?.value,
          label:
            TaxAdvantagedAccountPlanType[
              createPlanData.benefitKind as TaxAdvantagedAccountBenefitKind
            ]?.label,
        },
        onClose: () => {
          setIsModalVisible(false);
          dbgClient.postMessage({
            channel: TAX_ADVANTAGED_PLAN_CHANNEL,
            event: RELOAD_TAX_ADVANTAGED_PLAN_LIST,
            data: {
              benefitKind:
                TaxAdvantagedAccountPlanType[
                  createPlanData.benefitKind as TaxAdvantagedAccountBenefitKind
                ]?.value,
            },
          });
          showOverlay();
        },
        onSaveClose: () => {
          setIsModalVisible(false);
          dbgClient.postMessage({
            channel: TAX_ADVANTAGED_PLAN_CHANNEL,
            event: RELOAD_TAX_ADVANTAGED_PLAN_LIST,
            data: {
              benefitKind:
                TaxAdvantagedAccountPlanType[
                  createPlanData.benefitKind as TaxAdvantagedAccountBenefitKind
                ]?.value,
            },
          });
        },
        defaultPlanYear: createPlanData.planYearId,
        isDBGPlan: true,
        dbgPlanYear: planYearId,
        isSaveDisabled: disableSave,
      },
    },
    [OPEN_TAX_ADVANTAGED_PLAN_EDIT_MODAL]: {
      component: EditTaxAdvantagedPlanModal,
      props: {
        plan: plan,
        onClose: () => {
          setIsModalVisible(false);
          showOverlay();
        },
        isModalVisible: isModalVisible,
        isDBGPlan: true,
        onSave: () => {
          dbgClient.postMessage({
            channel: TAX_ADVANTAGED_PLAN_CHANNEL,
            event: RELOAD_TAX_ADVANTAGED_PLAN,
            data: { benefitKind: plan?.benefitKind },
          });
        },
        isSaveDisabled: disableSave,
      },
    },
  } as any;

  const { component: Component, props } =
    eventComponentMapper[subscribedEvent] || {};

  return (
    <>
      <DBGClient
        channel={TAX_ADVANTAGED_PLAN_CHANNEL}
        subscribeCommon={(event: string) => {
          if (CLOSE_MODALS === event) {
            setIsModalVisible(false);
          }
        }}
        subscribe={(event: string, data: unknown, dbgMetaData: any) => {
          handleClientEvent(event, data, dbgMetaData);
        }}
      />
      <BenefitGuideNotificationsChannel
        setDisableSave={() => setDisableSave(true)}
        isPlanEditModalOpen={isModalVisible}
        benefitKind={BenefitCategory.TAX_ADVANTAGE.value}
      />
      {Component && <Component {...props} />}
      {/* TODO: Add BenefitGuideNotificationsChannel  */}
    </>
  );
};

export default TaxAdvantageChannel;
