import React, {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Col, Popover, Row } from 'antd';
import {
  cloneDeep,
  get,
  isEmpty,
  isEqual,
  omit,
  set,
  some,
  sortBy,
  trim,
} from 'lodash';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useStateCallback } from 'hooks/updateState';
import { usePrevious } from 'hooks/usePrevious';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import NetworkTypeRow from 'modules/plans/components/NetworkTypeRow/NetworkTypeRow';
import AddTierButton from 'modules/plans/components/AddTierButton/AddTierButton';

import PlanServiceObject from 'model/PlanService';
import iconRemove from 'assets/images/icon-remove-red.svg';
import iconWarning from 'assets/images/icon-warning.svg';

import iconAdd from 'assets/images/icon-add.svg';
import {
  BenefitCovered,
  COINSURANCE,
  COPAY,
  COPAY_FREQUENCY,
  COPAY_PRIOR_TO_DEDUCTIBLE,
  COST_TYPE,
  CostType,
  coverageOption,
  defaultBenefitTypes,
  IN_NETWORK,
  INFO,
  OUT_OF_NETWORK,
  RxTier,
  RxTierMailOrders,
  RxTiers,
  ServiceType,
} from 'modules/plans/constants';
import {
  resetStateCompleted,
  setDisplayedServices,
} from 'modules/plans/slices/medicalPlanSlice';
import InfoIconComponent from 'modules/plans/components/LLMInfoIconComponent/InfoIconComponent';
import { LlmInfo, LlmRxCosts } from 'model/plans/LLMExtraction';
import PanelSection from 'modules/plans/enums/PanelSection';
import CostSharing from 'model/plans/CostSharing';
import CustomPlan from 'model/plans/CustomPlan';
import RxCost from 'model/plans/RxCost';
import MedicalPlan from 'model/plans/MedicalPlan';
import BenefitCode from 'model/plans/BenefitCode';
import {
  getNonDefaultExistingAndCustomOrder,
  getLlmServiceInfo,
  getLlmSCostInfo,
} from 'modules/plans/utils';
import PlanDeductibleOOPNetwork from 'model/PlanDeductibleOOPNetwork';
import PlanDeductibleOOPMax from 'model/PlanDeductibleOOPMax';

import styles from './services.module.less';

type ServiceRowProps = {
  customService: CustomPlan;
  formType?: string;
  title: string;
  index: number;
  onServiceRemove: (index: number) => void;
  onChange: Function;
  medicalPlan: MedicalPlan;
  isTouched: boolean;
  isDefault: boolean;
  isAdded?: boolean;
  selectAll: boolean | null;
  trigger?: boolean;
  setHasCostType: Function;
};

type RxServiceRowProps = {
  inNetwork: CostSharing;
  outOfNetwork: CostSharing;
  formType?: string;
  title: string;
  onConfirm?: (e?: React.MouseEvent<HTMLElement>) => void;
  showRemove?: boolean;
  onChange: Function;
  isTouched: boolean;
  hsaCompatible: boolean;
  selectAll: boolean | null;
  llmRxCostExtractionInfo: LlmRxCosts | undefined | null;
  tierCode: string;
  isHighlightVisible?: boolean;
  isUploadedPlan: boolean;
};

type RxTableDataObj = {
  inNetwork: CostSharing;
  outOfNetwork: CostSharing;
  title: string;
  tierCode: string;
};

const ServiceRow: FC<ServiceRowProps> = (props: ServiceRowProps) => {
  const {
    customService,
    formType,
    index,
    onServiceRemove,
    medicalPlan,
    onChange: _onChange,
    isTouched,
    isDefault,
    selectAll,
    trigger,
    setHasCostType,
  } = props;

  const dispatch = useAppDispatch();

  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo
  );
  const { benefitCode, serviceValue } = customService;
  const { inNetwork, outOfNetwork } = serviceValue;
  const [visible, setVisible] = useStateCallback(false);

  const {
    displayedServices: medicalDisplayServices,
    medicalPlan: { extractionFinalized, textractJobId },
  } = useAppSelector((state) => state.plan.plans);

  const isHighlightVisible = !extractionFinalized;
  const isUploadedPlan: boolean = !!textractJobId && textractJobId !== '';

  const onChange = (pathKey: any, changes: []) => {
    const customServiceCopy = cloneDeep(customService);
    changes.forEach((change) => {
      const { key, value } = change;
      set(customServiceCopy, `serviceValue.${pathKey}.${key}`, value);
      if (key === 'costSharingPolicy' && value !== coverageOption.NOT_COVERED) {
        set(
          customServiceCopy,
          `serviceValue.${pathKey}.benefitCovered`,
          BenefitCovered.YES
        );
      }
    });

    updatedCustomService(customServiceCopy);
  };

  const updatedCustomService = (customServiceCopy: CustomPlan) => {
    const customServices = [...(medicalPlan.customServices || [])];
    const existingServiceIndex = customServices.findIndex(
      (s) =>
        (customServiceCopy.benefitCode.code &&
          customServiceCopy.benefitCode.code === s.benefitCode.code) ||
        customServiceCopy.benefitCode.shortName === s.benefitCode.shortName
    );
    if (existingServiceIndex !== -1) {
      customServices[existingServiceIndex] = {
        ...customServiceCopy,
        isDefault: customServiceCopy.isDefault,
        added: customServiceCopy.added,
      };
    } else {
      customServices.push(customServiceCopy);
    }
    const updatedMedicalPlan = {
      ...medicalPlan,
      customServices,
    };

    const updatedDisplayServices = medicalDisplayServices.map((s) => {
      if (
        s.code === customServiceCopy.benefitCode.code &&
        s.benefitKind === customServiceCopy.benefitCode.benefitKind
      ) {
        return { ...s, added: customServiceCopy.added };
      }
      return s;
    });

    dispatch(setDisplayedServices(updatedDisplayServices));

    _onChange(updatedMedicalPlan);
  };

  const removeMedicalPlan = (benefitCode: BenefitCode) => {
    const { code, shortName } = benefitCode;
    const { customServices = [] } = medicalPlan;

    const newCustomServices = customServices.filter(
      (service) =>
        (code && code !== service.benefitCode.code) ||
        (!code && shortName !== service.benefitCode.shortName)
    );

    const updatedMedicalPlan = {
      ...medicalPlan,
      customServices: newCustomServices,
    };
    _onChange(updatedMedicalPlan);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onConfirmClick = () => {
    setVisible(false, () => {
      removeMedicalPlan(benefitCode);
      onServiceRemove(index);
    });
  };

  const removeFromAddedFlag = (service: CustomPlan) => {
    setVisible(false, () => {
      updatedCustomService({ ...service, added: false });
    });
  };

  const shouldVisibleRemoveButton = isEmpty(medicalPlan.id)
    ? !isDefault && customService.added
    : !isDefault;

  const isDisabledCustomService =
    isEmpty(medicalPlan.id) &&
    !customService.added &&
    !customService.isDefault &&
    !isOpsAdmin;

  const llmServiceInfo = getLlmServiceInfo(
    benefitCode?.shortName,
    medicalPlan?.llmExtractionInfo?.customServices ?? []
  );

  const llmExtractionServiceInNetwork = llmServiceInfo?.inNetwork ?? null;
  const llmExtractionServiceOutNetwork = llmServiceInfo?.outOfNetwork ?? null;

  const Component = (isDisabledCustomService ? Popover : React.Fragment) as any;

  const showAddIcon =
    isEmpty(medicalPlan.id) &&
    !isDefault &&
    !customService.added &&
    !isOpsAdmin;

  const isAddOrRemoveIconVisible = showAddIcon || shouldVisibleRemoveButton;
  return (
    <div className={styles.tableSelector}>
      <Row>
        <Col>
          {shouldVisibleRemoveButton && (
            <ConfirmationModal
              onVisibleChange={() => {
                !visible && setVisible(true);
              }}
              title="Are you sure you want to remove this service?"
              icon={
                <img
                  src={iconWarning}
                  alt="warning-icon"
                  className={styles.iconWarning}
                />
              }
              confirmModalTrigger={
                !isOpsAdmin && (
                  <img
                    src={iconRemove}
                    alt="remove-service-icon"
                    className={styles.iconRemove}
                  />
                )
              }
              visible={visible}
              onConfirm={
                customService.manualAdded || !isDefault
                  ? onConfirmClick
                  : () => {
                      return removeFromAddedFlag(customService);
                    }
              }
              onCancel={onCancel}
              okText="Yes, remove service"
              placement="topLeft"
            />
          )}
        </Col>
        <Component
          key={index}
          content='These non-default services were imported from the SBC. You can add them by clicking the "+" icon to the left of the service.'
          placement="rightTop"
          overlayClassName="nonDefaultPopover"
        >
          <Col xxl={6} xl={6}>
            <div
              className={`${styles.benefitNameContainer} text table-row-title`}
            >
              {!isAddOrRemoveIconVisible && (
                <InfoIconComponent
                  showIcon={isUploadedPlan}
                  extractionInfo={llmExtractionServiceInNetwork}
                  className={styles.infoIconServices}
                />
              )}
              <span className={styles.benefitNameText}>
                {benefitCode?.shortName}
                {isAddOrRemoveIconVisible && (
                  <span className={styles.inlineIcon}>
                    <InfoIconComponent
                      showIcon={isUploadedPlan}
                      extractionInfo={llmExtractionServiceInNetwork}
                    />
                  </span>
                )}
              </span>
            </div>
          </Col>
        </Component>
      </Row>

      {showAddIcon && (
        <img
          src={iconAdd}
          alt="add-icon"
          className={styles.addIcon}
          onClick={() => {
            const temp = { ...customService, added: true };
            updatedCustomService(temp);
          }}
        />
      )}

      <div className={styles.networkTypeRow}>
        <NetworkTypeRow
          networkType={IN_NETWORK}
          networkObject={inNetwork}
          formType={formType}
          onChange={(changes: []) => {
            const key = `inNetwork`;
            onChange(key, changes);
          }}
          hsaCompatible={medicalPlan?.hsaCompatible}
          isTouched={isTouched}
          isDisabled={isDisabledCustomService}
          serviceTitle={benefitCode?.shortName}
          trigger={trigger}
          selectAll={selectAll}
          isDefault={isDefault}
          setHasCostType={setHasCostType}
          highlightLLMValue={
            llmExtractionServiceInNetwork?.different && isHighlightVisible
          }
        />
      </div>
      <NetworkTypeRow
        networkType={OUT_OF_NETWORK}
        networkObject={outOfNetwork}
        formType={formType}
        onChange={(changes: any) => {
          const key = `outOfNetwork`;
          onChange(key, changes);
        }}
        hsaCompatible={medicalPlan?.hsaCompatible}
        isTouched={isTouched}
        isDisabled={isDisabledCustomService}
        serviceTitle={benefitCode?.shortName}
        trigger={trigger}
        selectAll={selectAll}
        setHasCostType={setHasCostType}
        highlightLLMValue={
          llmExtractionServiceOutNetwork?.different && isHighlightVisible
        }
      />
    </div>
  );
};

const RxServiceRow: FC<RxServiceRowProps> = (props: RxServiceRowProps) => {
  const {
    inNetwork,
    outOfNetwork,
    formType,
    title,
    onConfirm,
    showRemove,
    onChange,
    isTouched,
    hsaCompatible,
    selectAll,
    llmRxCostExtractionInfo: llmRxCosts,
    tierCode,
    isHighlightVisible,
    isUploadedPlan,
  } = props;

  const [visible, setVisible] = useStateCallback(false);

  const onCancel = () => {
    setVisible(false);
  };

  const llmRxServicesInfoInNetwork = getLlmSCostInfo(
    tierCode,
    'inNetwork',
    llmRxCosts
  );

  const llmRxServicesInfoOutOfNetwork = getLlmSCostInfo(
    tierCode,
    'outOfNetwork',
    llmRxCosts
  );

  return (
    <div className={styles.tableSelector}>
      <Row>
        <Col>
          {showRemove && (
            <ConfirmationModal
              onVisibleChange={() => {
                !visible && setVisible(true);
              }}
              title="Are you sure you want to remove this tier?"
              icon={
                <img
                  src={iconWarning}
                  alt="warning-icon"
                  className={styles.iconWarning}
                />
              }
              visible={visible}
              onConfirm={onConfirm}
              onCancel={onCancel}
              confirmModalTrigger={
                <img
                  src={iconRemove}
                  alt="remove-service-icon"
                  className={styles.iconRemove}
                />
              }
              placement="topLeft"
            />
          )}
        </Col>
        <Col span={8}>
          <div
            className={`${styles.benefitNameContainer} text table-row-title`}
          >
            {!showRemove && (
              <InfoIconComponent
                showIcon={isUploadedPlan}
                extractionInfo={llmRxServicesInfoInNetwork}
                className={styles.infoIconRXRetail}
              />
            )}
            <span className={styles.benefitNameText}>{title}</span>
            {showRemove && (
              <InfoIconComponent
                showIcon={isUploadedPlan}
                className={styles.infoIconRXRetailAfter}
                extractionInfo={llmRxServicesInfoInNetwork}
              />
            )}
          </div>
        </Col>
      </Row>
      <div className={styles.networkTypeRow}>
        <NetworkTypeRow
          networkType={IN_NETWORK}
          networkObject={inNetwork}
          formType={formType}
          onRowChange={(
            key: string,
            value: string,
            ptd: string,
            costSharingPolicy?: string
          ) => {
            onChange('inNetwork', key, value, ptd, costSharingPolicy);
          }}
          isTouched={isTouched}
          hsaCompatible={hsaCompatible}
          serviceTitle={title}
          selectAll={selectAll}
          isDefault={true}
          highlightLLMValue={
            llmRxServicesInfoInNetwork?.different && isHighlightVisible
          }
        />
      </div>

      <NetworkTypeRow
        networkType={OUT_OF_NETWORK}
        networkObject={outOfNetwork}
        formType={formType}
        onRowChange={(
          key: string,
          value: string,
          ptd: string,
          costSharingPolicy?: string
        ) => {
          onChange('outOfNetwork', key, value, ptd, costSharingPolicy);
        }}
        isTouched={isTouched}
        hsaCompatible={hsaCompatible}
        serviceTitle={title}
        selectAll={selectAll}
        highlightLLMValue={
          llmRxServicesInfoOutOfNetwork?.different && isHighlightVisible
        }
      />
    </div>
  );
};

type ServiceProps = {
  formType: string;
  onServiceRemove?: (index: number) => void;
  onServiceAdded?: (planServiceObject: PlanServiceObject[]) => void;
  onChange: Function;
  isDBGView?: boolean;
  isTouched: boolean;
  currentPlan?: MedicalPlan;
  isReviewProp?: boolean;
};

type DeductibleOOPMaxValueMapping = {
  serviceLabel: string;
  llmExtractionInfo?: LlmInfo | undefined;
  inNetwork: PlanDeductibleOOPNetwork;
  outNetwork: PlanDeductibleOOPNetwork;
};

const Services = forwardRef((props: ServiceProps, ref) => {
  const plan = useAppSelector((state) => state.plan.plans);
  const {
    formType,
    onChange: _onChange,
    isDBGView = false.valueOf,
    isTouched,
    isReviewProp,
  } = props;
  const [serviceTableData, setServiceTableData] = useState<CustomPlan[]>([]);
  const [rxTableData, setRxTableData] = useState<RxTableDataObj[]>([]);
  const [rxMailOrderTableData, setRxMailOrderTableData] = useState<
    RxTableDataObj[]
  >([]);
  const [behaveAsRemove, setBehaveAsRemove] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean | null>(null);
  const [isSelectAllTextChangedService, setSelectAllTextChangedService] =
    useState<boolean>(!!plan?.medicalPlan?.id);
  const [isSelectAllTextChangedRx, setSelectAllTextChangedRx] =
    useState<boolean>(!!plan?.medicalPlan?.id);
  const [selectText, _setSelectText] = useState<string>('(Select All)');
  const [rxDeductibleOopMax, setRxDeductibleOopMax] = useState<
    DeductibleOOPMaxValueMapping[]
  >([]);
  const [hasCostType, setHasCostType] = useState<boolean>(false);

  const setSelectText = useCallback(
    (text: string) => {
      if (formType === PanelSection.SERVICES) {
        setSelectAllTextChangedService(true);
      }
      if (formType === PanelSection.RX) {
        setSelectAllTextChangedRx(true);
      }
      _setSelectText(text);
    },
    [formType]
  );

  const [trigger, setTrigger] = useState(false);

  const componentDidMount = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const medicalPlan = props.currentPlan ? props.currentPlan : plan.medicalPlan;
  const { displayedServices } = plan;
  const previousRxCost = usePrevious(medicalPlan.rxCosts);
  const { extractionFinalized, textractJobId } = medicalPlan;

  const isHighlightVisible = !extractionFinalized;
  const isUploadedPlan: boolean = !!textractJobId && textractJobId !== '';

  useImperativeHandle(ref, () => ({
    validate() {
      return getCompleteness();
    },
    reset() {
      setServiceTableData([]);
      setRxTableData([]);
      setRxMailOrderTableData([]);
      setRxDeductibleOopMax([]);
      dispatch(resetStateCompleted());
      setBehaveAsRemove(false);
      componentDidMount.current = false;
    },
  }));

  const getCompleteness = () => {
    const { rxCosts, mailOrderRxCosts } = medicalPlan;
    const isRxComplete = getCompletenessForRxOrMailOrder(rxTableData, rxCosts);
    const isMailOrderComplete = getCompletenessForRxOrMailOrder(
      rxMailOrderTableData,
      mailOrderRxCosts
    );
    const isComplete = isRxComplete || isMailOrderComplete;
    return { isComplete: !isComplete };
  };

  const isEmptyValue = (value: any) => {
    return (
      value === undefined ||
      value === null ||
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0)
    );
  };

  const getCompletenessForRxOrMailOrder = (
    tableData: RxTableDataObj[],
    costData: RxCost = {
      inNetwork: {},
      outOfNetwork: {},
    }
  ) => {
    const visibleTiers = tableData?.map((tier) => tier.tierCode);
    return visibleTiers?.some((tierCode) => {
      if (!isEmpty(costData?.inNetwork) && !isEmpty(costData?.outOfNetwork)) {
        const { inNetwork, outOfNetwork } = costData;
        return some([inNetwork, outOfNetwork], (rowValue) => {
          const costSharingObject = get(rowValue, tierCode);
          if (isEmptyValue(costSharingObject)) {
            return true;
          }
          if (costSharingObject.costSharingPolicy === CostType.NOT_COVERED) {
            return false;
          }
          if (
            medicalPlan.hsaCompatible &&
            costSharingObject.costSharingPolicy === CostType.COPAY
          ) {
            return isEmptyValue(costSharingObject.copayPriorToDeductible);
          }
          if (costSharingObject.costSharingPolicy === CostType.OTHER) {
            return isEmptyValue(costSharingObject.info);
          }
          return (
            isEmptyValue(costSharingObject.costSharingPolicy) ||
            (isEmptyValue(costSharingObject.copay) &&
              isEmptyValue(costSharingObject.coinsurance)) ||
            (isEmpty(costSharingObject.copayPriorToDeductible) &&
              isEmpty(costSharingObject.coinsuranceAfterDeductible))
          );
        });
      }
    });
  };

  useEffect(() => {
    if (PanelSection.SERVICES === formType) {
      const customServices = displayedServices
        .filter((service) => service.isSaasSupported)
        .map((service: PlanServiceObject) => {
          const customService =
            medicalPlan.customServices?.find(
              (s) =>
                (s.benefitCode.code !== null &&
                  s.benefitCode.code === service.code) ||
                s.benefitCode.shortName === service.shortName
            ) || new CustomPlan();
          const sCopy = JSON.parse(JSON.stringify(customService));
          sCopy.benefitCode = service;
          sCopy.isDefault = service.isDefault;
          return sCopy;
        });
      if (isDBGView) {
        medicalPlan.customServices?.forEach((service: any) => {
          if (
            !(service in customServices) &&
            isEmpty(
              customServices.filter(
                (item) => item.benefitCode.code === service.benefitCode.code
              )
            )
          ) {
            customServices.push(service);
          }
        });
      }

      const newCustomServices = cloneDeep(customServices);
      newCustomServices.forEach((service) => {
        if (
          service.serviceValue.inNetwork.costSharingPolicy !==
          CostType.NOT_APPLICABLE
        ) {
          if (
            service.serviceValue.inNetwork.benefitCovered ===
              BenefitCovered.NO &&
            service.serviceValue.inNetwork.costSharingPolicy !==
              BenefitCovered.CLEAR
          ) {
            service.serviceValue.inNetwork.costSharingPolicy =
              coverageOption.NOT_COVERED;
          }
        }

        if (
          service.serviceValue.outOfNetwork.benefitCovered ===
            BenefitCovered.NO &&
          service.serviceValue.outOfNetwork.costSharingPolicy !==
            BenefitCovered.CLEAR
        ) {
          service.serviceValue.outOfNetwork.costSharingPolicy =
            coverageOption.NOT_COVERED;
        }
      });

      const { nonDefaultExistingServices, nonDefaultCustomServices } =
        getNonDefaultExistingAndCustomOrder(newCustomServices, true);
      setServiceTableData([
        ...newCustomServices.filter(
          (service) =>
            service.isDefault &&
            !(
              service.benefitCode.code >= 9000 ||
              service.benefitCode.code === null
            )
        ),
        ...sortBy(nonDefaultExistingServices, (service) => !service.added),
        ...sortBy(nonDefaultCustomServices, (service) => !service.added),
      ]);
    }
  }, [displayedServices, formType, medicalPlan.customServices, isDBGView]);

  const mapDataToTiers = (
    rxCosts: RxCost | undefined,
    noOfTiers: number,
    tier: object
  ) => {
    return Object.values(tier)
      .slice(0, noOfTiers)
      .map((tierConfig: { value: string; label: string }) => {
        const { value: tierCode, label: tierName } = tierConfig;
        const inNetwork =
          (rxCosts && rxCosts.inNetwork && rxCosts.inNetwork[tierCode]) ||
          ({} as CostSharing);
        const outOfNetwork =
          (rxCosts && rxCosts.outOfNetwork && rxCosts.outOfNetwork[tierCode]) ||
          ({} as CostSharing);

        return {
          inNetwork,
          outOfNetwork,
          title: tierName,
          tierCode,
        };
      });
  };

  const getRxDataLength = useCallback(
    (rxCost: RxCost | undefined) => {
      const DEFAULT_DATA_LENGTH = 4;

      const availableTiers = Object.keys(rxCost?.inNetwork || {})?.concat(
        Object.keys(rxCost?.outOfNetwork || {})
      );
      if (availableTiers) {
        if (availableTiers.includes(RxTiers.TIER_8)) {
          return 8;
        } else if (availableTiers.includes(RxTiers.TIER_7)) {
          return rxTableData.length > 7 ? rxTableData.length : 7;
        } else if (availableTiers.includes(RxTiers.TIER_6)) {
          return rxTableData.length > 6 ? rxTableData.length : 6;
        } else if (availableTiers.includes(RxTiers.TIER_5)) {
          return rxTableData.length > 5 ? rxTableData.length : 5;
        } else if (availableTiers.includes(RxTiers.TIER_4)) {
          return rxTableData.length > 4 ? rxTableData.length : 4;
        } else if (availableTiers.includes(RxTiers.TIER_3)) {
          return rxTableData.length > 3 ? rxTableData.length : 3;
        } else if (availableTiers.includes(RxTiers.TIER_2)) {
          return rxTableData.length > 2 ? rxTableData.length : 2;
        } else if (availableTiers.includes(RxTiers.TIER_1)) {
          return rxTableData.length > 1 ? rxTableData.length : 1;
        }
      }
      return DEFAULT_DATA_LENGTH;
    },
    [rxTableData.length]
  );

  const setRxData = useCallback(() => {
    const rxCosts = medicalPlan.rxCosts;
    const rxDataLength = getRxDataLength(rxCosts);

    const rxData = mapDataToTiers(rxCosts, rxDataLength, RxTier);
    const rxMailOrderCosts = medicalPlan.mailOrderRxCosts;

    const setNotCovered = (data: any) => {
      data.forEach((rx: any) => {
        if (rx.inNetwork.costSharingPolicy !== CostType.NOT_APPLICABLE) {
          if (
            rx.inNetwork.benefitCovered === BenefitCovered.NO &&
            rx.inNetwork.costSharingPolicy !== BenefitCovered.CLEAR
          ) {
            rx.inNetwork.costSharingPolicy = coverageOption.NOT_COVERED;
          }
        }

        if (
          rx.outOfNetwork.benefitCovered === BenefitCovered.NO &&
          rx.outOfNetwork.costSharingPolicy !== BenefitCovered.CLEAR
        ) {
          rx.outOfNetwork.costSharingPolicy = coverageOption.NOT_COVERED;
        }
      });
    };

    if (
      rxMailOrderCosts &&
      !isEmpty(rxMailOrderCosts) &&
      (!isEmpty(rxMailOrderCosts.inNetwork) ||
        !isEmpty(rxMailOrderCosts.outOfNetwork))
    ) {
      const rxMailOrderLength = getRxDataLength(rxMailOrderCosts);

      const rxMailOrderData = mapDataToTiers(
        rxMailOrderCosts,
        rxMailOrderLength,
        RxTierMailOrders
      );
      const newRxMailOrderData = cloneDeep(rxMailOrderData);
      setNotCovered(newRxMailOrderData);
      setRxMailOrderTableData(newRxMailOrderData);
    }
    const newRxData = cloneDeep(rxData);
    setNotCovered(newRxData);

    setRxTableData(newRxData);
  }, [medicalPlan.mailOrderRxCosts, medicalPlan.rxCosts, getRxDataLength]);

  useEffect(() => {
    setRxData();
  }, [setRxData, medicalPlan.mailOrderRxCosts, medicalPlan.rxCosts]);

  useEffect(() => {
    const availableTiers = Object.keys(
      medicalPlan.rxCosts?.inNetwork || {}
    )?.concat(Object.keys(medicalPlan.rxCosts?.outOfNetwork || {}));
    const previousTiers = Object.keys(previousRxCost?.inNetwork || {})?.concat(
      Object.keys(previousRxCost?.outOfNetwork || {})
    );
    if (!isEqual(availableTiers, previousTiers)) {
      setRxTableData([]);
    }
  }, [medicalPlan.rxCosts, previousRxCost]);

  useEffect(() => {
    if (!componentDidMount.current) {
      setRxData();
      componentDidMount.current = true;
    }
  }, [setRxData]);

  const onAddTier = () => {
    const remainingRxTiers = Object.values(RxTier).filter(
      (rxTier) => !rxTableData.some((rxTable) => rxTier.label === rxTable.title)
    );
    const newRxData = {
      inNetwork: {} as CostSharing,
      outOfNetwork: {} as CostSharing,
      title: remainingRxTiers[0].label,
      tierCode: remainingRxTiers[0].value,
    };
    const updatedRXCosts = [...rxTableData, newRxData];
    setRxTableData(updatedRXCosts);
    const clonedMedicalPlan = cloneDeep(medicalPlan);
    if (clonedMedicalPlan.rxCosts) {
      const clonedRxCosts = clonedMedicalPlan.rxCosts;
      updatedRXCosts.forEach((data) => {
        clonedRxCosts.inNetwork[data.tierCode] = data.inNetwork || {};
        clonedRxCosts.outOfNetwork[data.tierCode] = data.outOfNetwork || {};
      });
      clonedMedicalPlan.rxCosts = clonedRxCosts;
      _onChange(clonedMedicalPlan);
    }
  };

  const onAddMailOrder = useCallback(() => {
    const newRxMailOrderRows = Object.values(RxTierMailOrders)
      .slice(rxMailOrderTableData.length, rxTableData.length)
      .map((tier) => ({
        inNetwork: {} as CostSharing,
        outOfNetwork: {} as CostSharing,
        title: tier.label,
        tierCode: tier.value,
      }));

    const updatedMailOrderRXCosts = [
      ...rxMailOrderTableData,
      ...newRxMailOrderRows,
    ];
    setRxMailOrderTableData(updatedMailOrderRXCosts);
    const clonedMedicalPlan = cloneDeep(medicalPlan);
    if (clonedMedicalPlan.mailOrderRxCosts) {
      const clonedMailOrderRxCosts =
        clonedMedicalPlan.mailOrderRxCosts &&
        !isEmpty(clonedMedicalPlan.mailOrderRxCosts)
          ? clonedMedicalPlan.mailOrderRxCosts
          : new RxCost();
      updatedMailOrderRXCosts.forEach((data) => {
        clonedMailOrderRxCosts.inNetwork[data.tierCode] = data.inNetwork || {};
        clonedMailOrderRxCosts.outOfNetwork[data.tierCode] =
          data.outOfNetwork || {};
      });
      clonedMedicalPlan.mailOrderRxCosts = clonedMailOrderRxCosts;
      _onChange(clonedMedicalPlan);
    }
  }, [_onChange, medicalPlan, rxMailOrderTableData, rxTableData.length]);

  const onRemoveMailOrder = () => {
    setRxMailOrderTableData([]);
    const clonedMedicalPlan = cloneDeep(medicalPlan);
    clonedMedicalPlan.mailOrderRxCosts = {} as RxCost;
    _onChange(clonedMedicalPlan);
  };

  const removeRxAndMailOrderTier = () => {
    const rxTiersInTable = [...rxTableData];
    const rxMailOrderTiersInTable = [...rxMailOrderTableData];
    const removedRx = rxTiersInTable.pop();
    setRxTableData(rxTiersInTable);

    const clonedMedicalPlan = cloneDeep(medicalPlan);
    if (removedRx?.tierCode) {
      set(
        clonedMedicalPlan,
        'rxCosts.inNetwork',
        omit(medicalPlan.rxCosts?.inNetwork, removedRx?.tierCode)
      );
      set(
        clonedMedicalPlan,
        'rxCosts.outOfNetwork',
        omit(clonedMedicalPlan.rxCosts?.outOfNetwork, removedRx?.tierCode)
      );
    }

    if (!isEmpty(rxMailOrderTiersInTable)) {
      rxMailOrderTiersInTable.pop();
      setRxMailOrderTableData(rxMailOrderTiersInTable);

      if (removedRx?.tierCode) {
        set(
          clonedMedicalPlan,
          'mailOrderRxCosts.inNetwork',
          omit(
            clonedMedicalPlan.mailOrderRxCosts?.inNetwork,
            removedRx?.tierCode
          )
        );
        set(
          clonedMedicalPlan,
          'mailOrderRxCosts.outOfNetwork',
          omit(
            clonedMedicalPlan.mailOrderRxCosts?.outOfNetwork,
            removedRx?.tierCode
          )
        );
      }
    }
    _onChange(clonedMedicalPlan);
  };

  const onServiceRemove = (plan: CustomPlan) => {
    const updatedServices = displayedServices.filter(
      (service) =>
        service?.code !== plan?.benefitCode?.code ||
        service?.shortName !== plan?.benefitCode?.shortName
    );
    dispatch(setDisplayedServices(updatedServices));
  };

  const getServices = () =>
    serviceTableData.map((serviceObject: CustomPlan, index) => {
      const isDisabledCustomService =
        isEmpty(medicalPlan.id) &&
        !serviceObject.added &&
        !serviceObject.isDefault;

      const isDefault = !defaultBenefitTypes.find((item) => {
        return (
          trim(item.shortName) === trim(serviceObject.benefitCode.shortName)
        );
      });

      if (isDefault) serviceObject = { ...serviceObject, isDefault: false };

      return (
        <React.Fragment key={index}>
          <div className={isDisabledCustomService ? styles.serviceWrapper : ''}>
            <ServiceRow
              index={index}
              onServiceRemove={() => {
                onServiceRemove(serviceObject);
              }}
              customService={serviceObject}
              formType={formType}
              title={serviceObject?.benefitCode?.shortName}
              onChange={_onChange}
              medicalPlan={medicalPlan}
              isTouched={isTouched}
              isDefault={serviceObject.isDefault || false}
              selectAll={isSelected}
              trigger={trigger}
              setHasCostType={setHasCostType}
            />
          </div>
        </React.Fragment>
      );
    });

  const onChange = (
    serviceType: string,
    tierCode: string,
    network: string,
    key: string,
    value: string,
    ptd: string,
    costSharingPolicyParam?: string
  ) => {
    const clonedMedicalPlan = cloneDeep(medicalPlan);

    const rxObj =
      ServiceType.RX === serviceType
        ? clonedMedicalPlan.rxCosts || new RxCost()
        : clonedMedicalPlan.mailOrderRxCosts || new RxCost();
    if (key === COST_TYPE) {
      set(rxObj, `${network}.${tierCode}`, {
        costSharingPolicy: value,
        copayPriorToDeductible: ptd,
      });
    } else {
      const costSharingPolicy = costSharingPolicyParam || 'OTHER';

      const mappings = {
        COPAY: {
          amount: COPAY,
          frequency: COPAY_FREQUENCY,
          deductiblePrior: COPAY_PRIOR_TO_DEDUCTIBLE,
        },
        COINSURANCE: {
          amount: COINSURANCE,
          deductiblePrior: COPAY_PRIOR_TO_DEDUCTIBLE,
        },
        OTHER: {
          info: INFO,
          deductiblePrior: COPAY_PRIOR_TO_DEDUCTIBLE,
        },
      };

      set(
        rxObj,
        `${network}.${tierCode}.${get(
          mappings,
          `${costSharingPolicy}.${key}`
        )}`,
        value
      );
    }

    // replace rx cost sharing policy and info if not data exist
    replaceRxWhenNoDataExist(rxObj);

    if (ServiceType.RX === serviceType) {
      clonedMedicalPlan.rxCosts = rxObj;
    } else {
      clonedMedicalPlan.mailOrderRxCosts = rxObj;
    }
    _onChange(clonedMedicalPlan);
  };

  /**
   * THis Method checks if RX object contain tiers without cost policy and info
   * if it doesn't when append 0 and OTHER as the default attributes and parsing to
   * proceed for the on change
   * NOTE - passed as any as the data structure inside the rx object is dynamic, we cannot dynamically
   * identify this as types increase with record count
   * @param {any} rxObj
   */
  const replaceRxWhenNoDataExist = (rxObj: any) => {
    for (const categoryKey in rxObj) {
      if (Object.prototype.hasOwnProperty.call(rxObj, categoryKey)) {
        const category = rxObj[categoryKey];
        for (const tierKey in category) {
          if (Object.prototype.hasOwnProperty.call(category, tierKey)) {
            const tier = category[tierKey];
            if (!tier.costSharingPolicy || !tier.info) {
              tier.costSharingPolicy = tier.costSharingPolicy || 'OTHER';
              tier.info = tier.info || '';
            }
          }
        }
      }
    }
  };

  const getRxServices = () =>
    rxTableData.map((serviceObject, index) => {
      return (
        <RxServiceRow
          inNetwork={serviceObject.inNetwork}
          outOfNetwork={serviceObject.outOfNetwork}
          formType={formType}
          title={serviceObject.title}
          onConfirm={removeRxAndMailOrderTier}
          showRemove={index === rxTableData.length - 1 && index !== 0}
          key={index}
          onChange={(
            network: string,
            key: string,
            value: string,
            ptd: string,
            costSharingPolicy?: string
          ) => {
            onChange(
              ServiceType.RX,
              serviceObject.tierCode,
              network,
              key,
              value,
              ptd,
              costSharingPolicy
            );
          }}
          isTouched={isTouched}
          hsaCompatible={medicalPlan?.hsaCompatible}
          selectAll={isSelected}
          llmRxCostExtractionInfo={medicalPlan.llmExtractionInfo?.rxCosts}
          isHighlightVisible={isHighlightVisible}
          tierCode={serviceObject.tierCode}
          isUploadedPlan={isUploadedPlan}
        />
      );
    });

  const getRxServicesMailOrders = () =>
    rxMailOrderTableData.map((serviceObject, index) => {
      return (
        <RxServiceRow
          inNetwork={serviceObject.inNetwork}
          outOfNetwork={serviceObject.outOfNetwork}
          formType={formType}
          key={index}
          title={serviceObject.title}
          showRemove={false}
          onChange={(
            network: string,
            key: string,
            value: string,
            ptd: string,
            costSharingPolicy?: string
          ) => {
            onChange(
              ServiceType.MAILORDER,
              serviceObject.tierCode,
              network,
              key,
              value,
              ptd,
              costSharingPolicy
            );
          }}
          isTouched={isTouched}
          hsaCompatible={medicalPlan?.hsaCompatible}
          selectAll={isSelected}
          tierCode={serviceObject.tierCode}
          llmRxCostExtractionInfo={
            medicalPlan.llmExtractionInfo?.mailOrderRxCosts
          }
          isUploadedPlan={isUploadedPlan}
        />
      );
    });

  const addTierIsDisabled = rxTableData.length === Object.values(RxTier).length;

  useEffect(() => {
    if (rxMailOrderTableData.length < rxTableData.length && behaveAsRemove) {
      onAddMailOrder();
    }
  }, [
    behaveAsRemove,
    onAddMailOrder,
    rxMailOrderTableData.length,
    rxTableData.length,
  ]);

  useEffect(() => {
    if (formType === PanelSection.SERVICES) {
      const t1 = serviceTableData.filter(
        (service) =>
          !isEmpty(service.serviceValue.inNetwork.costSharingPolicy) &&
          service.serviceValue.inNetwork.costSharingPolicy !==
            coverageOption.NOT_COVERED
      );
      const t2 = serviceTableData.filter(
        (service) =>
          !isEmpty(service.serviceValue.outOfNetwork.costSharingPolicy) &&
          service.serviceValue.outOfNetwork.costSharingPolicy !==
            coverageOption.NOT_COVERED
      );
      if (
        t1.every(
          (service) =>
            service.serviceValue.inNetwork.copayPriorToDeductible === 'YES'
        ) &&
        t2.every(
          (service) =>
            service.serviceValue.outOfNetwork.copayPriorToDeductible === 'YES'
        )
      ) {
        setSelectText('(Deselect All)');
      } else {
        setSelectText('(Select All)');
      }
    }
  }, [
    formType,
    serviceTableData,
    setSelectText,
    isSelectAllTextChangedService,
  ]);

  useEffect(() => {
    if (formType === PanelSection.RX) {
      const r1 = rxTableData.filter(
        (rx) => !isEmpty(rx.inNetwork.costSharingPolicy)
      );
      const r2 = rxTableData.filter(
        (rx) => !isEmpty(rx.outOfNetwork.costSharingPolicy)
      );
      const r3 = rxMailOrderTableData.filter(
        (rx) => !isEmpty(rx.inNetwork.costSharingPolicy)
      );
      const r4 = rxMailOrderTableData.filter(
        (rx) => !isEmpty(rx.outOfNetwork.costSharingPolicy)
      );

      if (
        r1.every(
          (rx) =>
            rx.inNetwork.copayPriorToDeductible === 'YES' ||
            rx.inNetwork.costSharingPolicy === coverageOption.NOT_COVERED
        ) &&
        r2.every(
          (rx) =>
            rx.outOfNetwork.copayPriorToDeductible === 'YES' ||
            rx.outOfNetwork.costSharingPolicy === coverageOption.NOT_COVERED
        ) &&
        r3.every(
          (rx) =>
            rx.inNetwork.copayPriorToDeductible === 'YES' ||
            rx.inNetwork.costSharingPolicy === coverageOption.NOT_COVERED
        ) &&
        r4.every(
          (rx) =>
            rx.outOfNetwork.copayPriorToDeductible === 'YES' ||
            rx.outOfNetwork.costSharingPolicy === coverageOption.NOT_COVERED
        )
      ) {
        setSelectText('(Deselect All)');
      } else {
        setSelectText('(Select All)');
      }
    }
  }, [
    formType,
    rxTableData,
    rxMailOrderTableData,
    isSelectAllTextChangedRx,
    setSelectText,
  ]);

  useEffect(() => {
    const { rxDeductiblesAndOop, llmExtractionInfo = null } = medicalPlan;
    const { rxDeductiblesAndOop: rxDeductiblesAndOopLLMInfo } =
      llmExtractionInfo || {};

    const individualRxDeductible = {
      serviceLabel: 'Individual Rx Deductible',
      inNetwork: {
        covered: rxDeductiblesAndOop?.individualDeductibleInNetworkApplicable,
        coveredAmount: rxDeductiblesAndOop?.individualDeductibleInNetworkCost,
        valueMapping: {
          COVERAGE:
            'rxDeductiblesAndOop.individualDeductibleInNetworkApplicable',
          COST: 'rxDeductiblesAndOop.individualDeductibleInNetworkCost',
        },
        llmExtractionInfo:
          rxDeductiblesAndOopLLMInfo?.individualDeductibleInNetworkCost,
      } as PlanDeductibleOOPNetwork,
      outNetwork: {
        covered:
          rxDeductiblesAndOop?.individualDeductibleOutOfNetworkApplicable,
        coveredAmount:
          rxDeductiblesAndOop?.individualDeductibleOutOfNetworkCost,
        valueMapping: {
          COVERAGE:
            'rxDeductiblesAndOop.individualDeductibleOutOfNetworkApplicable',
          COST: 'rxDeductiblesAndOop.individualDeductibleOutOfNetworkCost',
        },
        llmExtractionInfo:
          rxDeductiblesAndOopLLMInfo?.individualDeductibleOutOfNetworkCost,
      } as PlanDeductibleOOPNetwork,
    };

    const familyRxDeductible = {
      serviceLabel: 'Family Rx Deductible',
      inNetwork: {
        covered: rxDeductiblesAndOop?.familyDeductibleInNetworkApplicable,
        coveredAmount: rxDeductiblesAndOop?.familyDeductibleInNetworkCost,
        valueMapping: {
          COVERAGE: 'rxDeductiblesAndOop.familyDeductibleInNetworkApplicable',
          COST: 'rxDeductiblesAndOop.familyDeductibleInNetworkCost',
        },
        llmExtractionInfo:
          rxDeductiblesAndOopLLMInfo?.familyDeductibleInNetworkCost,
      } as PlanDeductibleOOPNetwork,
      outNetwork: {
        covered: rxDeductiblesAndOop?.familyDeductibleOutOfNetworkApplicable,
        coveredAmount: rxDeductiblesAndOop?.familyDeductibleOutOfNetworkCost,
        valueMapping: {
          COVERAGE:
            'rxDeductiblesAndOop.familyDeductibleOutOfNetworkApplicable',
          COST: 'rxDeductiblesAndOop.familyDeductibleOutOfNetworkCost',
        },
        llmExtractionInfo:
          rxDeductiblesAndOopLLMInfo?.familyDeductibleOutOfNetworkCost,
      } as PlanDeductibleOOPNetwork,
    };

    const individualRxOopMax = {
      serviceLabel: 'Individual Rx OOP Max',
      inNetwork: {
        covered: rxDeductiblesAndOop?.individualOopMaxInNetworkApplicable,
        coveredAmount: rxDeductiblesAndOop?.individualOopMaxInNetworkCost,
        valueMapping: {
          COVERAGE: 'rxDeductiblesAndOop.individualOopMaxInNetworkApplicable',
          COST: 'rxDeductiblesAndOop.individualOopMaxInNetworkCost',
        },
        llmExtractionInfo:
          rxDeductiblesAndOopLLMInfo?.individualOopMaxInNetworkCost,
      } as PlanDeductibleOOPNetwork,
      outNetwork: {
        covered: rxDeductiblesAndOop?.individualOopMaxOutOfNetworkApplicable,
        coveredAmount: rxDeductiblesAndOop?.individualOopMaxOutOfNetworkCost,
        valueMapping: {
          COVERAGE:
            'rxDeductiblesAndOop.individualOopMaxOutOfNetworkApplicable',
          COST: 'rxDeductiblesAndOop.individualOopMaxOutOfNetworkCost',
        },
        llmExtractionInfo:
          rxDeductiblesAndOopLLMInfo?.individualOopMaxOutOfNetworkCost,
      } as PlanDeductibleOOPNetwork,
    };

    const familyRxOopMax = {
      serviceLabel: 'Family Rx OOP Max',
      inNetwork: {
        covered: rxDeductiblesAndOop?.familyOopMaxInNetworkApplicable,
        coveredAmount: rxDeductiblesAndOop?.familyOopMaxInNetworkCost,
        valueMapping: {
          COVERAGE: 'rxDeductiblesAndOop.familyOopMaxInNetworkApplicable',
          COST: 'rxDeductiblesAndOop.familyOopMaxInNetworkCost',
        },
        llmExtractionInfo:
          rxDeductiblesAndOopLLMInfo?.familyOopMaxInNetworkCost,
      } as PlanDeductibleOOPNetwork,
      outNetwork: {
        covered: rxDeductiblesAndOop?.familyOopMaxOutOfNetworkApplicable,
        coveredAmount: rxDeductiblesAndOop?.familyOopMaxOutOfNetworkCost,
        valueMapping: {
          COVERAGE: 'rxDeductiblesAndOop.familyOopMaxOutOfNetworkApplicable',
          COST: 'rxDeductiblesAndOop.familyOopMaxOutOfNetworkCost',
        },
        llmExtractionInfo:
          rxDeductiblesAndOopLLMInfo?.familyOopMaxOutOfNetworkCost,
      } as PlanDeductibleOOPNetwork,
    };

    setRxDeductibleOopMax([
      individualRxDeductible,
      familyRxDeductible,
      individualRxOopMax,
      familyRxOopMax,
    ]);
  }, [medicalPlan]);

  const changeAllPTD = (selectAll: boolean) => {
    const updatedPlan = cloneDeep(medicalPlan);
    if (formType === PanelSection.SERVICES) {
      updatedPlan.customServices?.map((service: CustomPlan) => {
        if (!isEmpty(service.serviceValue.inNetwork.costSharingPolicy)) {
          service.serviceValue.inNetwork.copayPriorToDeductible = selectAll
            ? 'YES'
            : 'NO';
        }
        if (!isEmpty(service.serviceValue.outOfNetwork.costSharingPolicy)) {
          service.serviceValue.outOfNetwork.copayPriorToDeductible = selectAll
            ? 'YES'
            : 'NO';
        }
      });
    } else {
      if (updatedPlan.rxCosts?.inNetwork) {
        Object.keys(updatedPlan.rxCosts?.inNetwork).forEach((key) => {
          if (!isEmpty(updatedPlan.rxCosts?.inNetwork[key].costSharingPolicy)) {
            (
              updatedPlan.rxCosts?.inNetwork[key] as CostSharing
            ).copayPriorToDeductible = selectAll ? 'YES' : 'NO';
          }
        });
      }

      if (updatedPlan.rxCosts?.outOfNetwork) {
        Object.keys(updatedPlan.rxCosts?.outOfNetwork).forEach((key) => {
          if (
            !isEmpty(updatedPlan.rxCosts?.outOfNetwork[key].costSharingPolicy)
          ) {
            (
              updatedPlan.rxCosts?.outOfNetwork[key] as CostSharing
            ).copayPriorToDeductible = selectAll ? 'YES' : 'NO';
          }
        });
      }
      if (updatedPlan.mailOrderRxCosts?.inNetwork) {
        Object.keys(updatedPlan.mailOrderRxCosts?.inNetwork).forEach((key) => {
          if (
            !isEmpty(
              updatedPlan.mailOrderRxCosts?.inNetwork[key].costSharingPolicy
            )
          ) {
            (
              updatedPlan.mailOrderRxCosts?.inNetwork[key] as CostSharing
            ).copayPriorToDeductible = selectAll ? 'YES' : 'NO';
          }
        });
      }

      if (updatedPlan.mailOrderRxCosts?.outOfNetwork) {
        Object.keys(updatedPlan.mailOrderRxCosts?.outOfNetwork).forEach(
          (key) => {
            if (
              !isEmpty(
                updatedPlan.mailOrderRxCosts?.outOfNetwork[key]
                  .costSharingPolicy
              )
            ) {
              (
                updatedPlan.mailOrderRxCosts?.outOfNetwork[key] as CostSharing
              ).copayPriorToDeductible = selectAll ? 'YES' : 'NO';
            }
          }
        );
      }
    }
    _onChange(updatedPlan);
  };

  const onSelectAllOrUnselectAll = () => {
    if (formType === PanelSection.SERVICES) {
      const t1 = serviceTableData.filter(
        (service) =>
          !isEmpty(service.serviceValue.inNetwork.costSharingPolicy) &&
          service.serviceValue.inNetwork.costSharingPolicy !==
            coverageOption.NOT_COVERED
      );
      const t2 = serviceTableData.filter(
        (service) =>
          !isEmpty(service.serviceValue.outOfNetwork.costSharingPolicy) &&
          service.serviceValue.outOfNetwork.costSharingPolicy !==
            coverageOption.NOT_COVERED
      );

      if (isEmpty(t1) && isEmpty(t2)) {
        return;
      }

      setSelectAllTextChangedService(true);
    }
    if (formType === PanelSection.RX) {
      const r1 = rxTableData.filter(
        (rx) => !isEmpty(rx.inNetwork.costSharingPolicy)
      );
      const r2 = rxTableData.filter(
        (rx) => !isEmpty(rx.outOfNetwork.costSharingPolicy)
      );
      const r3 = rxMailOrderTableData.filter(
        (rx) => !isEmpty(rx.inNetwork.costSharingPolicy)
      );
      const r4 = rxMailOrderTableData.filter(
        (rx) => !isEmpty(rx.outOfNetwork.costSharingPolicy)
      );

      if (isEmpty(r1) && isEmpty(r2) && isEmpty(r3) && isEmpty(r4)) {
        return;
      }

      setSelectAllTextChangedRx(true);
    }

    if (isSelected === null) {
      if (selectText === '(Select All)') {
        setIsSelected(true);
        changeAllPTD(true);
      }
      if (selectText === '(Deselect All)') {
        setIsSelected(false);
        changeAllPTD(false);
      }
    } else {
      if (selectText === '(Deselect All)') {
        setSelectText('(Select All)');
        setIsSelected(false);
        changeAllPTD(false);
      } else {
        setSelectText('(Deselect All)');
        setIsSelected(true);
        changeAllPTD(true);
      }
    }

    // we need this for useEffect in networkTypeRow to trigger
    setTrigger((trigger) => !trigger);
  };

  const onValueChange = (mapping: any, changes: []) => {
    const medicalPlanCopy = cloneDeep(medicalPlan);
    changes.forEach((change) => {
      const { key, value } = change;
      const path = mapping[key];
      set(medicalPlanCopy, path, value);
    });
    _onChange(medicalPlanCopy);
  };

  return (
    <>
      {formType === PanelSection.RX && (
        <>
          <div className={styles.deductiblesOopWrapper}>
            <div>
              {rxDeductibleOopMax.map(
                (row: PlanDeductibleOOPMax, index: number) => (
                  <div key={index}>
                    <Row>
                      <Col span={24}>
                        <div className="text table-row-title">
                          <Row>
                            <Col span={24}>
                              <div
                                className={`${styles.benefitNameContainer} text table-row-title`}
                              >
                                <InfoIconComponent
                                  showIcon={isUploadedPlan}
                                  extractionInfo={
                                    row?.inNetwork.llmExtractionInfo
                                  }
                                  className={styles.infoIconRX}
                                />
                                <span className={styles.benefitNameText}>
                                  {row.serviceLabel} &nbsp;
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <div className={styles.networkTypeRow}>
                      <NetworkTypeRow
                        id={`${row.serviceLabel}_IN_NETWORK`}
                        key={index}
                        networkType={IN_NETWORK}
                        networkDeductibleOOPObject={row.inNetwork}
                        onChange={(changes: []) => {
                          onValueChange(row.inNetwork.valueMapping, changes);
                        }}
                        isTouched={isTouched}
                        decimalScale={0}
                        isOnlyCovered
                        isReviewProp={isReviewProp}
                        highlightLLMValue={
                          row?.inNetwork?.llmExtractionInfo?.different &&
                          isHighlightVisible
                        }
                      />
                    </div>
                    <div className={styles.networkTypeRow}>
                      <NetworkTypeRow
                        id={`${row.serviceLabel}_OUT_OF_NETWORK`}
                        key={index}
                        networkType={OUT_OF_NETWORK}
                        networkDeductibleOOPObject={row.outNetwork}
                        onChange={(changes: []) => {
                          onValueChange(row.outNetwork.valueMapping, changes);
                        }}
                        isTouched={isTouched}
                        decimalScale={0}
                        isReviewProp={isReviewProp}
                        isOnlyCovered
                        highlightLLMValue={
                          row.outNetwork.llmExtractionInfo?.different &&
                          isHighlightVisible
                        }
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <br />
        </>
      )}
      <div className={`${styles.planServicesWrapper} planServicesWrapper`}>
        <div className="text service-table-col-header">
          <Row>
            {hasCostType ? (
              <>
                <Col span={7}></Col>
                <Col span={5}>
                  <div className={styles.coulmnWrapperFlexWrapper}>
                    Cost Type
                  </div>
                </Col>
                <Col span={4}>
                  <div className={styles.coulmnWrapperFlexWrapper}>Amount</div>
                </Col>
                <Col span={5}>
                  <div className={styles.coulmnWrapperFlexWrapper}>
                    Frequency
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Col span={6}></Col>
                <Col span={14}>
                  <div className={styles.coulmnWrapperFlexWrapper}>
                    Amount/Frequency
                  </div>
                </Col>
              </>
            )}
            <Col span={4}>
              <div className={styles.selectAllWrapper}>
                Prior to Deductible <br />
                <a onClick={onSelectAllOrUnselectAll}>
                  <div className={styles.selectAllText}>{selectText}</div>
                </a>
              </div>
            </Col>
          </Row>
        </div>

        {formType === PanelSection.SERVICES && getServices()}
        {formType === PanelSection.RX && (
          <>
            {getRxServices()}
            {getRxServicesMailOrders()}
            <div className={styles.panelFooter}>
              <AddTierButton
                btnName="Tier"
                disabled={addTierIsDisabled}
                onAddBtnClicked={onAddTier}
              />
              <LinkButton
                className={styles.addServiceBtn}
                onClick={() => {
                  if (behaveAsRemove) {
                    onRemoveMailOrder();
                  } else {
                    onAddMailOrder();
                  }
                  setBehaveAsRemove(!behaveAsRemove);
                }}
              >
                <img
                  src={behaveAsRemove ? iconRemove : iconAdd}
                  alt="add-remove-icon"
                  className={styles.iconFooterSection}
                />
                Mail Order
              </LinkButton>
            </div>
          </>
        )}
      </div>
    </>
  );
});

Services.displayName = 'Services';

export default Services;
