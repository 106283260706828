export const PLAN_CLONE_BENEFIT_CATEGORIES = {
  MEDICAL: 'MEDICAL',
  TELEHEALTH: 'TELEHEALTH',
  DENTAL: 'DENTAL',
  VISION: 'VISION',
  LIFE: 'LIFE',
  VOLUNTARY_BENEFIT: 'VOLUNTARY_BENEFIT',
  WELLBEING: 'WELLBEING',
  SAVING: 'SAVING',
  RETIREMENT: 'RETIREMENT',
  HOLIDAYS_AND_TIME_OFF: 'HOLIDAYS_AND_TIME_OFF',
  FAMILY_AND_LEAVE: 'FAMILY_AND_LEAVE',
  ADDITIONAL_PERK: 'ADDITIONAL_PERK',
};

export const SET_CURRENT_PLAN_YEAR = {
  VALUE: 'current',
  URL_PRAM_NAME: 'plan-year',
};

export const PLAN_YEAR_CREATE_AFP_SUCCESS_MESSAGE =
  'You can always skip this and later access this feature on the Plan Year management page.';

export const CLONE_TYPE = {
  PLAN: 'PLAN',
  BEN_GUIDE: 'BEN_GUIDE',
};

export const ERROR_CODE_DUPLICATE = [
  'document.clone.failed',
  'validation.duplicate.exists',
];
export const DUPLICATE_ERROR_MESSAGE =
  'The plan names provided for specific plans already exist.';
