import { useAppSelector } from 'hooks/redux';
import { ReviewModalFragment } from 'modules/renewals/types/renewalsTypes';
import DeductiblesOOPMaxUPEditWrapper from 'modules/plans/dental/components/DeductiblesOOPMax/DeductiblesOOPMaxUPEditWrapper';

import { DentalPlan } from 'model/plans/DentalPlan';

type Props = Omit<ReviewModalFragment, 'type'>;

const DeductiblesAndMaximums = ({ setState, state }: Props) => {
  const planCreateType = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.selectPlanCreateType
  );
  const isPlanReviewed = useAppSelector(
    (state) =>
      state.renewals.addProposalToPlanYear.isCurrentSelectedPlanReviewed
  );
  const isPlanMerged = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.isCurrentSelectedPlanMerged
  );

  const isMerging =
    planCreateType === 'MERGE' && !(isPlanReviewed === isPlanMerged);

  return (
    <DeductiblesOOPMaxUPEditWrapper
      dentalPlan={state as DentalPlan}
      setDentalPlan={setState}
      isReviewProp={isMerging}
    />
  );
};

export default DeductiblesAndMaximums;
