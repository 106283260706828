import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Popover } from 'antd';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { DataColumn } from 'components/DataTable/DataColumn';
import ToolList from 'components/ToolList/ToolList';
import {
  useGetBrokersQuery,
  useUpdateBrokerFeatureStatusMutation,
} from 'api/featureControl';
import FeatureToggle from 'components/FeatureToggle/FeatureToggle';
import TablePagination from 'model/TablePagination';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import {
  ISSUES_LOG_PROVISION_STATUS_ABORTED,
  ISSUES_LOG_PROVISION_STATUS_COMPLETED,
  ISSUES_LOG_PROVISION_STATUS_IN_PROGRESS,
} from 'modules/issueslog/constants/IssuesLogConstants';
import iconWarning from 'assets/images/alert-warning.svg';
import ProvisionSetupModal from 'modules/tools/components/ProvisionSetupModal/ProvisionSetupModal';
import DLPSetupModal from 'modules/tools/components/DLPStatusChangeModal/DLPSetupModal';
import { useLazyGetProvisionStatusQuery } from 'modules/tools/slices/issuesLogProvisioningSlice';
import DisableFeatureMessage from 'components/DisableFeatureMessage/DisableFeatureMessage';

import { getEnabledLocations } from 'util/commonUtil';
import BrokerLocationStatus from 'modules/tools/components/GetLocationTextList/BrokerLocationStatus';

import styles from './featureList.module.less';

type BrokerIssuesLogListProps = {};

const IssuesLogFeatureList: FC<BrokerIssuesLogListProps> = (
  props: BrokerIssuesLogListProps
) => {
  const [updateStatus, { isSuccess }] = useUpdateBrokerFeatureStatusMutation(
    {}
  );
  const locationModalRef = useRef<any>();

  const [searchText, setSearchText] = useState<string>('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState('name');

  const [openProvisionModal, setOpenProvisionModal] = useState<boolean>(false);
  const [openDLPModal, setOpenDLPModal] = useState<boolean>(false);
  const [selectedBroker, setSelectedBroker] = useState<any>({});
  const [getProvisionStatus] = useLazyGetProvisionStatusQuery();

  const {
    data: featureWrappers = [],
    refetch,
    isFetching,
  } = useGetBrokersQuery({
    page: pageIndex,
    key: 'ISSUES_LOG',
    query: searchText,
    size: pageSize,
    sort: sortOrder,
  });

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [refetch, isSuccess]);

  useEffect(() => {
    refetch();
  }, [refetch, searchText]);

  const getProvisionStatusText = (record: any) => {
    if (
      ISSUES_LOG_PROVISION_STATUS_COMPLETED ===
      record?.issuesLogConfigurations?.status
    ) {
      return <div>Completed</div>;
    } else if (
      ISSUES_LOG_PROVISION_STATUS_IN_PROGRESS ===
      record?.issuesLogConfigurations?.status
    ) {
      return (
        <div>
          <Popover
            overlayClassName="provisionStatusPopover"
            placement="left"
            content={
              'Provisioning may take several minutes. Return to this page later to see an updated status.'
            }
          >
            <img src={iconWarning} className={styles.iconWarning} />
          </Popover>
          Provisioning in progress
        </div>
      );
    } else if (
      ISSUES_LOG_PROVISION_STATUS_ABORTED ===
      record?.issuesLogConfigurations?.status
    ) {
      return <div>Failed</div>;
    }
    return <>-</>;
  };

  const dataColumns: DataColumn[] = [
    {
      title: 'BROKER',
      dataIndex: 'brokerName',
      key: 'brokerName',
      width: '20%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.brokerName.localeCompare(b.brokerName),
      defaultSortOrder: 'ascend',
      render: (item) => {
        return (
          <OverflowPopover>
            <span className="text table-item-link">{item}</span>
          </OverflowPopover>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'PROVISIONED EMAILS',
      dataIndex: 'provision',
      width: '25%',
      key: 'provision',
      render: (colData, record) => {
        if (record?.issuesLogConfigurations?.status) {
          return (
            <div className={styles.provisionedEmailsWrapper}>
              <div className={styles.accountsEmail}>
                <OverflowPopover
                  popoverContent={
                    record?.issuesLogConfigurations?.accountShadowEmail
                  }
                >
                  {record?.issuesLogConfigurations?.accountShadowEmail}
                </OverflowPopover>
              </div>
              <div className={styles.supportEmail}>
                <OverflowPopover
                  popoverContent={
                    record?.issuesLogConfigurations?.supportShadowEmail
                  }
                >
                  {record?.issuesLogConfigurations?.supportShadowEmail}
                </OverflowPopover>
              </div>
            </div>
          );
        } else {
          return (
            <div
              className={styles.setupLink}
              onClick={() => {
                setSelectedBroker(record);
                setOpenProvisionModal(true);
              }}
            >
              + Setup Inboxes
            </div>
          );
        }
      },
    },
    {
      title: 'PROVISION STATUS',
      dataIndex: 'provision',
      width: '25%',
      key: 'provision',
      render: (colData, record) => {
        return (
          <div
            onClick={() => {
              getProvisionStatus({
                organizationId: record?.organizationId,
                currentTs: new Date().getTime(),
              });
            }}
          >
            {getProvisionStatusText(record)}
          </div>
        );
      },
    },
    {
      title: 'DATA LOSS PREVENTION',
      dataIndex: 'isDLPEnable',
      width: '25%',
      key: 'isDLPEnable',
      render: (colData, record) => {
        if (record?.dlpenable) {
          return (
            <div
              className={styles.setupLink}
              onClick={() => {
                setSelectedBroker(record);
                setOpenDLPModal(true);
              }}
            >
              DLP Active
            </div>
          );
        } else {
          return (
            <div
              className={styles.setupLink}
              onClick={() => {
                setSelectedBroker(record);
                setOpenDLPModal(true);
              }}
            >
              Enable
            </div>
          );
        }
      },
    },
    {
      title: 'LAST STATUS UPDATED',
      dataIndex: 'lastUpdatedTs',
      key: 'lastUpdatedTs',
      width: '40%',
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (isEmpty(a.lastUpdatedTs)) {
          return -1;
        } else {
          return new Date(a.lastUpdatedTs).getTime() >
            new Date(b.lastUpdatedTs).getTime()
            ? 1
            : -1;
        }
      },
      render: (colData, record, index) => {
        const updatedAt = dayjs(colData).format('MMMM D, YYYY h:mm A');
        return isEmpty(colData) ? (
          <div>-</div>
        ) : (
          <div>
            {updatedAt}
            <br></br>
            <span className={styles.addedBy}>
              by {record.lastUpdatedByAdminName}
            </span>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'enabled',
      key: 'locationCount',
      width: '17%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      render: (colData, record, index) => {
        return (
          <BrokerLocationStatus
            locationModalRef={locationModalRef}
            record={record}
          />
        );
      },
      align: 'left',
    },
    {
      title: 'ENABLED',
      dataIndex: 'enabled',
      key: 'enabled',
      width: '15%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (a.enabled) {
          return -1;
        } else {
          return 1;
        }
      },
      render: (colData, record, index) => {
        const locations = getEnabledLocations(record?.locationVOS);
        return (
          <FeatureToggle
            ref={locationModalRef}
            record={record}
            refetchBrokers={refetch}
            allLocationEnabled={!isEmpty(locations)}
            className="issuesLogStatusToggleWrapper"
            disabled={
              ISSUES_LOG_PROVISION_STATUS_COMPLETED !==
              record?.issuesLogConfigurations?.status
            }
            onConfirmToggle={() => {
              locationModalRef.current?.closeDisableModal();
              updateStatus({
                enabled: !colData,
                organizationId: record.organizationId,
                key: 'ISSUES_LOG',
              }).then(() => {
                refetch();
              });
            }}
            enabled={colData}
            recordName={record.brokerName}
            organizationId={record.organizationId}
            enableMessage={
              'Are you sure you want to enable account log? This broker and their employers will be able to access their account log data.'
            }
            disableMessage={
              <DisableFeatureMessage
                locations={locations}
                featureKey={'Account Log'}
              />
            }
            title={'Account Log'}
            width={600}
            featureType={'ISSUES_LOG'}
          />
        );
      },
      align: 'left',
    },
  ];

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();

    setSearchText(_searchText);
  };

  useEffect(() => {
    setPaginationConfig(paginationConfig);
    setPageIndex(paginationConfig.paginationIndex);
    setPageSize(paginationConfig.filterInfo.limit);
    const { field, order } = (paginationConfig.sorterInfo as any) || {};
    if (isEmpty(order)) {
      setSortOrder('');
    } else if (field == 'brokerName' && order == 'descend') {
      setSortOrder('-name');
    } else if (field == 'brokerName' && order == 'ascend') {
      setSortOrder('name');
    }
  }, [paginationConfig, isFetching]);

  return (
    <div
      className={`${styles.featureTableWrapper} ${styles.issuesLogFeatureTableWrapper}`}
    >
      <ToolList
        title="Account Log"
        columns={dataColumns}
        data={featureWrappers}
        hasBenefitsCategoryFilter={false}
        search={'Search Broker'}
        handleSearch={handleSearch}
        isLoading={isFetching}
      ></ToolList>

      <ProvisionSetupModal
        visible={openProvisionModal}
        onCancel={() => {
          setOpenProvisionModal(false);
        }}
        brokerName={selectedBroker?.brokerName}
        brokerId={selectedBroker.organizationId}
        onSave={() => {
          setOpenProvisionModal(false);
          refetch();
        }}
      />

      <DLPSetupModal
        visible={openDLPModal}
        onCancel={() => {
          setOpenDLPModal(false);
        }}
        brokerName={selectedBroker?.brokerName}
        brokerId={selectedBroker.organizationId}
        isDLPEnable={selectedBroker.dlpenable}
        onSave={() => {
          setOpenDLPModal(false);
          refetch();
        }}
      />
    </div>
  );
};

export default IssuesLogFeatureList;
