import { Col, Popover, Row } from 'antd';

import React from 'react';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import OfferCardOverview from 'modules/renewals/components/helperComponents/OfferCardOverview/OfferCardOverview';
import { isFromCurrentExist } from 'modules/renewals/utils/renewalsUtils';
import {
  CREDITS_INCLUSION,
  OFFER_BENEFIT_TYPES,
} from 'modules/renewals/constants/renewalsConstants';
import {
  currencyFormatter,
  currencyFormatterWithoutZeros,
} from 'util/commonUtil';
import {
  CreditsInclusionType,
  IProposalCardOverviewData,
  IProposalCardTableRow,
} from 'modules/renewals/types/renewalsTypes';

import styles from 'modules/renewals/components/ProposalCard/ProposalCardOverview/ProposalCardOverview.module.less';

type Props = {
  data: IProposalCardOverviewData;
  onCreditsInclusionChange: Function;
  creditsInclusion: CreditsInclusionType;
  tableData: IProposalCardTableRow[];
};

const ProposalCardOverview = ({
  data,
  creditsInclusion,
  onCreditsInclusionChange,
  tableData,
}: Props) => {
  const { totalBenefitCost, eeCost, erCost } = data;

  const getPercentage = (percentage: number) => {
    const str = percentage.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (percentage > 0) {
      return `+${str}%`;
    } else {
      return `${str}%`;
    }
  };

  const findHSAContribution = () => {
    return Number(
      tableData?.find((item) => item?.benefit === OFFER_BENEFIT_TYPES.MEDICAL)
        ?.hsaCost ?? 0
    );
  };

  const findTotalHraCost = (): number => {
    return tableData?.reduce((total, benefit) => {
      if (benefit.hraCost !== undefined) {
        return total + Number(benefit.hraCost);
      }
      return total;
    }, 0);
  };

  const setStyleBasedOnValue = (value: { percentage?: number }) => {
    const percentage = value.percentage || 0;

    if (percentage > 0) {
      return styles.errorText;
    } else if (percentage < 0) {
      return styles.successText;
    } else {
      return null;
    }
  };

  const getAmount = (value: any): string => {
    const str = currencyFormatter(
      Math.round(value || 0),
      undefined,
      undefined,
      0
    );
    if ((value || 0) > -1) {
      return `+${str}`;
    } else {
      return `${str}`;
    }
  };

  const isHsa = (): boolean => findHSAContribution() !== 0;
  const isHra = (): boolean => findTotalHraCost() !== 0;

  return (
    <OfferCardOverview
      className={styles.overviewWrapper}
      headerText={
        <span>
          Total Benefits Cost{' '}
          {isHsa() && isHra() ? (
            <Popover
              content={
                <>
                  <span>
                    ER HSA Funding :{' '}
                    {currencyFormatterWithoutZeros(findHSAContribution())}
                  </span>
                  <br />
                  <span>
                    ER HRA Cost :{' '}
                    {currencyFormatterWithoutZeros(findTotalHraCost())}
                  </span>
                </>
              }
              overlayClassName={'popoverInner recommendedtooltip'}
            >
              <span className={styles.inclErHsaText}>(Incl. ER HSA/HRA)</span>
            </Popover>
          ) : // only hra exist
          isHra() ? (
            <Popover
              content={`ER HRA Cost : ${currencyFormatterWithoutZeros(
                findTotalHraCost()
              )}`}
              overlayClassName={'popoverInner recommendedtooltip'}
            >
              <span className={styles.inclErHsaText}>(Incl. ER HRA)</span>
            </Popover>
          ) : (
            // only hsa
            isHsa() && (
              <Popover
                content={`ER HSA Funding : ${currencyFormatterWithoutZeros(
                  findHSAContribution()
                )}`}
                overlayClassName={'popoverInner recommendedtooltip'}
              >
                <span className={styles.inclErHsaText}>(Incl. ER HSA)</span>
              </Popover>
            )
          )}
        </span>
      }
      value={
        <>
          <div className={styles.totalBenefitsCostWrapper}>
            {totalBenefitCost?.value ? (
              <>
                <span>
                  {currencyFormatter(
                    Math.round(totalBenefitCost.value),
                    undefined,
                    undefined,
                    0
                  )}
                </span>{' '}
                <Popover
                  content={
                    <div>
                      <span
                        className={`${setStyleBasedOnValue(totalBenefitCost)} ${
                          styles.boldText
                        }`}
                      >
                        {!isFromCurrentExist(totalBenefitCost.percentage)
                          ? getPercentage(totalBenefitCost.percentage || 0)
                          : 'N/A'}
                      </span>{' '}
                      From Current
                    </div>
                  }
                  overlayClassName={'popoverInner recommendedtooltip'}
                >
                  <span
                    className={`${
                      styles.totalBenefitsCostPercentage
                    } ${setStyleBasedOnValue(totalBenefitCost)}`}
                  >
                    ({`${getAmount(totalBenefitCost.diffFromCurrentValue)}`})
                  </span>
                </Popover>
              </>
            ) : (
              '-'
            )}
          </div>
        </>
      }
      footerElement={
        <>
          <Row className={styles.totalBenefitsCostFooter}>
            <Col span={12}>
              Total ER:
              <span className={styles.thickText}>
                {erCost?.value ? (
                  <>
                    <span>{` ${currencyFormatter(
                      Math.round(erCost.value),
                      undefined,
                      undefined,
                      0
                    )}`}</span>{' '}
                    <Popover
                      content={
                        <div>
                          <span
                            className={`${setStyleBasedOnValue(erCost)} ${
                              styles.boldText
                            }`}
                          >
                            {getPercentage(erCost.percentage || 0)}
                          </span>{' '}
                          From Current
                        </div>
                      }
                      overlayClassName={'popoverInner recommendedtooltip'}
                    >
                      <span className={`${setStyleBasedOnValue(erCost)}`}>
                        {`(${getAmount(erCost.diffFromCurrentValue)})`}
                      </span>
                    </Popover>
                  </>
                ) : (
                  ' -'
                )}
              </span>
            </Col>
            <Col span={12}>
              Total EE:
              <span className={styles.thickText}>
                {eeCost?.value ? (
                  <>
                    <span>
                      {` ${currencyFormatter(
                        Math.round(eeCost.value),
                        undefined,
                        undefined,
                        0
                      )}`}
                    </span>{' '}
                    <Popover
                      content={
                        <div>
                          <span
                            className={`${setStyleBasedOnValue(eeCost)} ${
                              styles.boldText
                            }`}
                          >
                            {getPercentage(eeCost.percentage || 0)}
                          </span>{' '}
                          From Current
                        </div>
                      }
                      overlayClassName={'popoverInner recommendedtooltip'}
                    >
                      <span className={`${setStyleBasedOnValue(eeCost)}`}>
                        {`(${getAmount(eeCost.diffFromCurrentValue)})`}
                      </span>
                    </Popover>
                  </>
                ) : (
                  ' -'
                )}
              </span>
            </Col>
            <Col span={24}>
              <SingleSelectFilter
                inlineDropdown
                placeholder="Action"
                showSearch={false}
                selectedValue={creditsInclusion}
                className={styles.creditsDropdown}
                setFilterSelectedValue={onCreditsInclusionChange}
                data={Object.values(CREDITS_INCLUSION)}
                defaultValue={CREDITS_INCLUSION.EXCLUDED.value}
                isSelectionHighlightAndTickApplicable={false}
                isNoWrapWrapper
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};

export default ProposalCardOverview;
