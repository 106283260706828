import { Select, SelectProps } from 'antd';

import { getFrequencyAccordingToPlanType } from 'modules/plans/utils';

import styles from './frequencySelect.module.less';

interface FrequencySelectProps extends SelectProps {
  isWarning: boolean;
  planType?: string;
}

const FrequencySelect = (props: FrequencySelectProps) => {
  const { isWarning, planType } = props;

  return (
    <div className={isWarning ? styles.warningSelect : ''}>
      <Select
        size="large"
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        {...props}
        className={styles.frequencySelect}
        allowClear
      >
        {getFrequencyAccordingToPlanType(planType!).map((item) => (
          <Select.Option key={item.value} value={item.value}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default FrequencySelect;
