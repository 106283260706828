import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Col, Form, Input, Radio, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import InputForm from 'components/InputForm/InputForm';
import DocumentUploader from 'components/DocumentUploader/DocumentUploader';
import { AdditionalResourcesRadioTypes } from 'modules/plans/constants';
import { getPlanDocument } from 'modules/plans/services/PlanService';

import {
  BenefitCategory,
  BenefitKind,
  maxPlanNameSize,
} from 'constants/commonConstants';
import { EMPTY_MESSAGE } from 'modules/brokers/constants/brokerConstants';
import TextButton from 'components/buttons/TextButton/TextButton';
import { isValidWeblink } from 'util/commonUtil';
import { MIME_TYPES } from 'util/fileUtil';
import styles from './planResources.module.less';

export type InvalidField = 'documentInvalid' | 'webLinkInvalid' | '';
type PlanResourcesProps = {
  onFileChange: Function;
  onFileRemove: Function;
  setFormData: Function;
  formData: any;
  form: any;
  isRemoved: boolean;
  isUploading?: boolean;
  planId?: string;
  benefitKind?: string;
  isCloseConfirmed: boolean;
  additionalResourceType: any;
  setAdditionalResourceType: Function;
  onValidateFails?: (validateSetting: string) => void;
  invalidField?: InvalidField;
  setIsAdditionalInfoValid: Function;
  onFileRemoveAction?: Function;
  onSave: Function;
  onCancel: Function;
  file: File;
  saveDisable?: boolean;
};

const PLAN_SUMMARY_DOC_TYPE = 'PLAN_SUMMARY';
const PLAN_DOCUMENT_TYPE = 'PLAN_DOCUMENT';
const DOC_UPLOAD_BENEFIT_KIND = [
  BenefitCategory.MEDICAL.value,
  BenefitCategory.DENTAL.value,
  BenefitCategory.VISION.value,
  BenefitCategory.BASIC_LIFE.value,
  BenefitCategory.BASIC_ADD.value,
  BenefitCategory.BASIC_LIFE_AND_ADD.value,
  BenefitCategory.STD.value,
  BenefitCategory.LTD.value,
];

const PlanResources = forwardRef((props: PlanResourcesProps, ref) => {
  const {
    onFileChange,
    onFileRemove,
    setFormData,
    formData,
    form,
    isRemoved,
    planId,
    benefitKind,
    isUploading,
    isCloseConfirmed,
    additionalResourceType,
    setAdditionalResourceType,
    onValidateFails,
    invalidField,
    setIsAdditionalInfoValid,
    onFileRemoveAction,
    onSave,
    onCancel,
    file,
    saveDisable = false,
  } = props;

  const documenUploaderRef = useRef<any>();

  useEffect(() => {
    if (isCloseConfirmed) {
      const { current = {} } = documenUploaderRef;
      const { reset = () => {} } = current;
      reset();
      form.resetFields();
    }
  }, [form, isCloseConfirmed]);

  const onInputChange = (changedValues: any, allValues: any) => {
    form.setFieldsValue(allValues);
    setFormData({ ...formData, ...allValues });
    setIsAdditionalInfoValid('');
  };

  const removeFile = () => {
    const { current = {} } = documenUploaderRef;
    const { reset = () => {} } = current;
    reset();
    onFileRemove();
  };

  const handleClick = (val: CheckboxChangeEvent) => {
    if (val.target.value === additionalResourceType) {
      setAdditionalResourceType('');
      removeFile();
      form.setFieldsValue({ weblink: '' });
    } else {
      removeFile();
      form.setFieldsValue({ weblink: '' });
      setAdditionalResourceType(val.target.value);
    }
    setIsAdditionalInfoValid('');
  };

  useImperativeHandle(ref, () => ({
    reset() {
      const { current = {} } = documenUploaderRef;
      const { reset = () => {} } = current;
      reset();
    },
  }));

  const isVolBenefitKind = (benefitKind: string) => {
    return (
      benefitKind === BenefitKind.ACCIDENT.value ||
      benefitKind === BenefitKind.CRITICAL_ILLNESS.value ||
      benefitKind === BenefitKind.HOSPITAL.value ||
      benefitKind === BenefitKind.CUSTOM_VOLUNTARY_BENEFIT.value
    );
  };

  const downloadFileObj = async (data: File) => {
    if (isEmpty(formData.fileName)) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      const url = window.URL.createObjectURL(data);
      a.href = url;
      a.download = data.name;
      a.click();
      window.URL.revokeObjectURL(url);
    }

    if (planId && benefitKind && formData.fileName) {
      const response = await getPlanDocument(
        planId,
        isVolBenefitKind(benefitKind)
          ? PLAN_SUMMARY_DOC_TYPE
          : PLAN_DOCUMENT_TYPE,
        benefitKind
      );

      const data = new File([response.data], formData.fileName, {
        type: 'application/pdf',
      });

      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      const url = window.URL.createObjectURL(data);
      a.href = url;
      a.download = data.name;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <div
      className={`${styles.wrapperForm} ${
        !planId && benefitKind && !DOC_UPLOAD_BENEFIT_KIND.includes(benefitKind)
          ? styles.addFormWrapper
          : ''
      }`}
    >
      <InputForm form={form} onValuesChange={onInputChange}>
        <Row>
          <Col span={24}>
            <Form.Item
              className={styles.planNameWrapper}
              name="planDocumentName"
              label="Name"
              rules={[
                {
                  required: false,
                  message: EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
            >
              <Input
                data-cy="planDocumentName"
                maxLength={maxPlanNameSize}
                className={styles.fileNameInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Radio.Group name="radiogroup" value={additionalResourceType}>
          <Row className={styles.docWrapper} justify={'space-between'}>
            <Col span={12}>
              <Row gutter={48} className={styles.rowContainer}>
                <Col span={6}>
                  <Radio
                    value={AdditionalResourcesRadioTypes.FILE}
                    onChange={handleClick}
                  >
                    <div
                      className={
                        invalidField === 'documentInvalid'
                          ? styles.errorOption
                          : styles.optionalTitle
                      }
                    >
                      File{' '}
                    </div>
                  </Radio>
                </Col>
                <Col>
                  <div className={styles.fileUploader}>
                    <DocumentUploader
                      ref={documenUploaderRef}
                      onChange={(file: File) => {
                        onFileChange(file);
                      }}
                      onRemove={() => {
                        removeFile();
                        onFileRemoveAction?.();
                      }}
                      isRemoved={isRemoved}
                      isLoading={isUploading}
                      allowedFileTypes={[
                        MIME_TYPES.PDF,
                        MIME_TYPES.DOC,
                        MIME_TYPES.DOCX,
                        MIME_TYPES.XLS,
                        MIME_TYPES.XLSX,
                        MIME_TYPES.PNG,
                        MIME_TYPES.JPG,
                        MIME_TYPES.JPEG,
                      ]}
                      maxFileSizeMB={100}
                      defaultFile={file}
                      disabled={
                        additionalResourceType !==
                        AdditionalResourcesRadioTypes.FILE
                      }
                      hideNoFileMessage={
                        additionalResourceType !==
                        AdditionalResourcesRadioTypes.FILE
                      }
                      onFileNameClick={(file: File) => downloadFileObj(file)}
                      onValidateFails={onValidateFails}
                      wrapperClassName={styles.fileUploaderWrapper}
                      isAdditionalFileUploader={true}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={48} className={styles.rowContainer}>
                <Col span={6}>
                  <div className={styles.weblink}>
                    <Row>
                      <Radio
                        value={AdditionalResourcesRadioTypes.WEBLINK}
                        onChange={handleClick}
                      >
                        <div
                          className={
                            invalidField === 'webLinkInvalid'
                              ? styles.errorOptionWeb
                              : styles.optionalTitleWeb
                          }
                        >
                          Weblink
                        </div>
                      </Radio>
                    </Row>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Form.Item name="weblink" className={styles.weblinkInput}>
                      <Input
                        disabled={
                          additionalResourceType !==
                          AdditionalResourcesRadioTypes.WEBLINK
                        }
                        className={styles.weblinkInput}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Radio.Group>
      </InputForm>
      <Row className={styles.docBtnWrapper}>
        <Col span={4}>
          <TextButton
            label="Cancel"
            className={styles.cancelButtonWrapper}
            onClick={() => {
              setAdditionalResourceType(null);
              onCancel();
            }}
            type="default"
          />
        </Col>
        <Col>
          <TextButton
            label="Save"
            className={styles.saveButtonWrapper}
            onClick={() => {
              const weblink = form.getFieldValue('weblink');
              if (additionalResourceType === 'file' && !file) {
                setIsAdditionalInfoValid('documentInvalid');
              } else if (
                additionalResourceType === 'webLink' &&
                !isValidWeblink(weblink)
              ) {
                setIsAdditionalInfoValid('webLinkInvalid');
              } else {
                onSave();
              }
            }}
            disabled={saveDisable}
            type="primary"
          />
        </Col>
      </Row>
    </div>
  );
});
PlanResources.displayName = 'PlanResources';
export default PlanResources;
