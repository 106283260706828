import Contribution from 'model/plans/Contribution';
import { FourTier } from 'modules/plans/constants';

class FourTierContributions {
  contributions: Contribution[];

  constructor() {
    this.contributions = [
      { ...new Contribution(), tierName: FourTier.EE.value },
      { ...new Contribution(), tierName: FourTier.ES.value },
      { ...new Contribution(), tierName: FourTier.EC.value },
      { ...new Contribution(), tierName: FourTier.EF.value },
    ];
  }
}

export default FourTierContributions;
