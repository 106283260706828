import { lumityApi } from './apiSlice';

export const brokerApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getBrokers: builder.query({
      query: ({ page, key, query, size, sort }) => {
        return {
          url: 'v2/organizations/configurations?',
          method: 'GET',
          params: {
            _page: page,
            'feature-key': key,
            query: query,
            _size: size,
            _sort: sort,
          },
        };
      },
    }),
    getBrokerFeatureStatus: builder.query({
      query: ({ organizationId, key }) => {
        return {
          url: 'v2/organizations/configurations/feature?',
          method: 'GET',
          params: {
            'organization-id': organizationId,
            'feature-key': key,
          },
        };
      },
    }),
    updateBrokerFeatureStatus: builder.mutation({
      query: ({ enabled, organizationId, key }) => {
        return {
          url: 'v2/organizations/configurations/feature?',
          method: 'PUT',
          params: {
            'organization-id': organizationId,
            enabled: enabled,
            'feature-key': key,
          },
        };
      },
    }),
    enableDLPFeature: builder.mutation({
      query: (args) => {
        const { organizationId, key, dlpEnable } = args;
        return {
          url: `v2/organizations/configurations/feature/dlp`,
          method: 'PUT',
          params: {
            'organization-id': organizationId,
            'feature-key': key,
            'dlp-enable': dlpEnable,
          },
        };
      },
    }),
    getDLPFeatureStatus: builder.query({
      query: ({ organizationId, key, query }) => {
        return {
          url: `v2/organizations/configurations/feature/dlp-status/${organizationId}?`,
          method: 'GET',
          params: {
            'organization-id': organizationId,
            'feature-key': key,
          },
        };
      },
    }),
    updateLocationFeatureStatus: builder.mutation({
      query: ({ data, key, organizationId }) => {
        return {
          url: 'v2/locations/configurations/feature', // need to change the url accordingly
          method: 'PUT',
          data: data,
          params: {
            'feature-key': key,
            'organization-id': organizationId,
          },
        };
      },
    }),
    getLocations: builder.query({
      query: ({ organizationId, key, query }) => {
        return {
          url: `v2/locations/configurations/${organizationId}?`,
          method: 'GET',
          params: {
            'feature-key': key,
            query: query,
          },
        };
      },
    }),
    getBrokerLevelDisabledLocations: builder.query<
      any,
      {
        isDeleteRfp?: boolean;
        newLocation: string;
        oldLocation: string;
        organizationId?: string;
      }
    >({
      query: (args) => {
        const { newLocation, oldLocation, organizationId } = args;
        return {
          url: 'v2/locations/configurations/broker/is-feature-disabled',
          method: 'GET',
          params: {
            newLocationId: newLocation,
            oldLocationId: oldLocation,
            organizationId: organizationId,
          },
        };
      },
    }),
    getUserCountForReassignLocation: builder.query<
      any,
      {
        organizationId?: string;
        newLocationId?: string;
        prevLocationId?: string;
      }
    >({
      query: (args) => {
        const { organizationId, newLocationId, prevLocationId } = args;
        return {
          url: `v2/locations/configurations/${organizationId}/reassign-location-user-count`,
          method: 'GET',
          params: {
            'new-location-id': newLocationId,
            'old-location-id': prevLocationId,
          },
        };
      },
    }),
    getBrokerAdminsRfpLocations: builder.query<
      any,
      {
        organizationId?: string | undefined | null;
      }
    >({
      query: (args) => {
        const { organizationId } = args;
        return {
          url: `v2/locations/configurations/${organizationId}/is-rfp-exceeded`,
          method: 'GET',
        };
      },
    }),
    sendNotificationForRfpExceed: builder.query<
      any,
      {
        organizationId?: string | undefined | null;
        individualId?: string | undefined | null;
      }
    >({
      query: (args) => {
        const { organizationId, individualId } = args;
        return {
          url: `v2/locations/configurations/${organizationId}/rfp-limit-exceeded`,
          method: 'GET',
          params: {
            'individual-id': individualId,
          },
        };
      },
    }),
    getBrokerAdminList: builder.query<
      any,
      {
        organizationId?: string;
        featureKey?: string;
        query?: string;
        page?: number;
        size?: number;
        sort?: string;
      }
    >({
      query: (args) => {
        const { organizationId, featureKey, page, query, size, sort } = args;
        return {
          url: `v2/individual/configurations/?organization-id=${organizationId}&feature-key=${featureKey}`,
          method: 'GET',
          params: {
            query: query,
            _page: page,
            _size: size,
            _sort: sort,
          },
        };
      },
    }),
    updateIndividualConfig: builder.mutation({
      query: ({ organizationId, featureKey, enabled, individualId }) => {
        return {
          url: `v2/individual/configurations/feature`,
          method: 'PUT',
          params: {
            'organization-id': organizationId,
            'individual-id': individualId,
            'feature-key': featureKey,
            enabled: enabled,
          },
        };
      },
    }),
    updateUserLimit: builder.mutation({
      query: ({ organizationId, featureKey, employerLimit }) => {
        return {
          url: `v2/organizations/configurations/employer-limit`,
          method: 'PUT',
          params: {
            'organization-id': organizationId,
            'feature-key': featureKey,
            'employer-limit': employerLimit,
          },
        };
      },
    }),
    updateBrokerUserLimit: builder.mutation({
      query: ({ organizationId, featureKey, brokerLimit }) => {
        return {
          url: `v2/organizations/configurations/broker-limit`,
          method: 'PUT',
          params: {
            'organization-id': organizationId,
            'feature-key': featureKey,
            'broker-limit': brokerLimit,
          },
        };
      },
    }),
    updateEmailUserContact: builder.mutation({
      query: ({ organizationId, featureKey, employerId, triggerType }) => {
        return {
          url: `v2/organizations/configurations/limit-exceed-email`,
          method: 'POST',
          params: {
            'organization-id': organizationId,
            'feature-key': featureKey,
            'employer-id': employerId,
            'trigger-type': triggerType,
          },
        };
      },
    }),
  }),
});

export const employerApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmployers: builder.query({
      query: ({
        page,
        organizationId,
        key,
        query,
        size,
        sort,
        defaultEnabled,
      }) => {
        return {
          url: 'v2/employers/configurations?',
          method: 'GET',
          params: {
            _page: page,
            'organization-id': organizationId,
            'feature-key': key,
            query: query,
            _size: size,
            _sort: sort,
            'default-enabled': defaultEnabled,
          },
        };
      },
    }),
    getEmployerFeatureStatus: builder.query({
      query: ({ employerId, key }) => {
        return {
          url: 'v2/employers/configurations/feature?',
          method: 'GET',
          params: {
            'employer-id': employerId,
            'feature-key': key,
          },
        };
      },
    }),
    updateEmployerFeatureStatus: builder.mutation({
      query: ({
        organizationId,
        employerId,
        key,
        enabled,
        deleteAutomatedEmail,
        selfPurchased,
        employerVisibilityEnabled,
      }) => {
        return {
          url: 'v2/employers/configurations/feature?',
          method: 'PUT',
          params: {
            'organization-id': organizationId,
            'employer-id': employerId,
            enabled: enabled,
            'feature-key': key,
            'delete-automated-email': deleteAutomatedEmail,
            'self-purchased': selfPurchased,
            'visibility-enabled': employerVisibilityEnabled,
          },
        };
      },
    }),
    getEmployerLevelDisabledLocations: builder.query<
      any,
      {
        isAllEnabled: boolean;
        locations: string;
        employerId?: string;
      }
    >({
      query: (args) => {
        const { isAllEnabled, locations, employerId } = args;
        return {
          url: 'v2/locations/configurations/employer/is-feature-disabled',
          method: 'GET',
          params: {
            locations: locations,
            'all-enabled': isAllEnabled,
            employerId: employerId,
          },
        };
      },
    }),
  }),
});

export const {
  useGetBrokersQuery,
  useGetBrokerFeatureStatusQuery,
  useUpdateBrokerFeatureStatusMutation,
  useEnableDLPFeatureMutation,
  useUpdateLocationFeatureStatusMutation,
  useGetLocationsQuery,
  useLazyGetLocationsQuery,
  useLazyGetBrokerLevelDisabledLocationsQuery,
  useLazyGetUserCountForReassignLocationQuery,
  useGetBrokerAdminsRfpLocationsQuery,
  useLazySendNotificationForRfpExceedQuery,
  useGetBrokerAdminListQuery,
  useLazyGetBrokerAdminListQuery,
  useUpdateIndividualConfigMutation,
  useUpdateUserLimitMutation,
  useUpdateBrokerUserLimitMutation,
  useUpdateEmailUserContactMutation,
} = brokerApi;
export const {
  useGetEmployersQuery,
  useGetEmployerFeatureStatusQuery,
  useUpdateEmployerFeatureStatusMutation,
  useLazyGetEmployerLevelDisabledLocationsQuery,
} = employerApi;
