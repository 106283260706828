import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Radio, RadioChangeEvent, Space } from 'antd';
import { isEmpty } from 'lodash';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import NextButton from 'components/buttons/NextButton/NextButton';
import TextButton from 'components/buttons/TextButton/TextButton';
import MergePriorPlans from 'modules/renewals/components/AppProposalToPlanYearComponents/MergePriorPlans/MergePriorPlans';
import {
  prepareForMerge,
  prepareForReview,
  setPlans,
  setSelectPlanCreateType,
} from 'modules/renewals/slices/addProposalToPlanYearSlice';

import { IStepperStep } from 'modules/renewals/types/renewalsTypes';

import styles from 'modules/renewals/components/AppProposalToPlanYearComponents/PlanDetails/PlanDetails.module.less';
import { transformProposalSummary } from 'modules/renewals/utils/addToPlanYearUtil';

interface Props extends IStepperStep {}

const PlanDetails = ({
  onNextStep = () => {},
  onCloseModal = () => {},
}: Props) => {
  const { employerId } = useParams();
  const dispatch = useAppDispatch();
  const selectedRadioValue = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.selectPlanCreateType
  );
  const planData = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.plans
  );
  const proposal = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.proposalSummary
  );

  const [isInnerVisible, setIsInnerVisible] = useState<boolean>(false);

  const hasNoMergablePlans = Object.values(planData).every((planSet) =>
    planSet.every((plan) => isEmpty(plan.mergablePlans))
  );
  const isNextBtnDisabled =
    selectedRadioValue === 'MERGE' && isInnerVisible && hasNoMergablePlans;

  const handleRadioChange = (e: RadioChangeEvent) => {
    dispatch(setSelectPlanCreateType(e.target.value));
    if (proposal?.data) {
      dispatch(setPlans(transformProposalSummary(proposal?.data)));
    }
  };

  const handleNextStepClick = () => {
    if (selectedRadioValue === 'NEW') {
      onNextStep();
      dispatch(prepareForReview());
    } else if (employerId !== undefined) {
      dispatch(prepareForMerge({ employerId }));
      setIsInnerVisible(true);
    }
  };

  return (
    <div className={styles.container}>
      {!isInnerVisible ? (
        <>
          <p className={styles.radioHeader}>
            <b>
              Choose how to add Final Approved Plans into the new Plan Year:
            </b>
          </p>
          <div>
            <Radio.Group
              value={selectedRadioValue}
              onChange={handleRadioChange}
              className={styles.radioGroup}
            >
              <Space direction="vertical" size={20}>
                <Radio value="NEW">
                  <b>Create Using Only Proposal Plan Details</b>
                </Radio>
                <Radio value="MERGE">
                  <b>Create by Merging with Current Plans</b> - This option can
                  be selected if the plan parameters from the current plans are
                  similar to the final approved plans that will be added to the
                  new plan year.
                </Radio>
              </Space>
            </Radio.Group>
          </div>
        </>
      ) : (
        <MergePriorPlans />
      )}

      <NextButton
        className={styles.nextBtn}
        nextStep={() => (isInnerVisible ? onNextStep() : handleNextStepClick())}
        disabled={isNextBtnDisabled}
      />
      <div className={styles.modalCancelBtnWrapper}>
        <TextButton
          label="Cancel"
          className={styles.cancelButton}
          onClick={() => onCloseModal(true)}
          type="primary"
        />
      </div>
    </div>
  );
};

export default PlanDetails;
