import React from 'react';
import styles from './sendSelfToOPSSuccessComponent.module.less';

type Props = {
  onClick: () => void;
  isSendOpsAdmin?: boolean;
};

const SendSelfToOPSSuccessComponent = (props: Props) => {
  const { onClick, isSendOpsAdmin } = props;
  return isSendOpsAdmin ? (
    <p>
      The file has been saved under the{' '}
      <span className={styles.linkToPlanUpload} onClick={onClick}>
        Plan Uploads link
      </span>{' '}
      and will be processed within 1 business day. You’ll receive a notification
      when processing is complete.
    </p>
  ) : (
    <p>
      The file has been saved under the{' '}
      <span className={styles.linkToPlanUpload} onClick={onClick}>
        Plan Uploads link
      </span>{' '}
      and is currently being processed.
      <br />
      You’ll receive a notification when processing is complete.
    </p>
  );
};

export default SendSelfToOPSSuccessComponent;
