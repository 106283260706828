import { FC, MutableRefObject, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { DentalPlan } from 'model/plans/DentalPlan';
import Services from 'modules/plans/components/DVServices/Services';
import {
  getDentalPlanServicesList,
  updateDentalPlan,
} from 'modules/plans/slices/dentalPlanSlice';
import { BenefitCategory, FEATURE_KEYS } from 'constants/commonConstants';
import { useNavContext } from 'hooks/useNavContext';
import AddCustomServiceModal from 'modules/plans/components/AddCustomServiceModal/AddCustomServiceModal';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import PanelSection from 'modules/plans/enums/PanelSection';
import iconAdd from 'assets/images/icon-add.svg';
import PlanServiceObject from 'model/PlanService';
import useGetUserFeatureStatus from 'hooks/useGetUserFeatureStatus';

import styles from './services.module.less';

type ServiceDBGWrapperProps = {
  type: string;
  wrappedRef?: MutableRefObject<any>;
  dentalPlan: DentalPlan;
};

const ServiceDBGWrapper: FC<ServiceDBGWrapperProps> = (
  props: ServiceDBGWrapperProps
) => {
  const { type, dentalPlan, wrappedRef } = props;
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();
  const [isAddCustomServiceModalOpen, setIsAddCustomServiceModalOpen] =
    useState<boolean>(false);
  const plan = useAppSelector((state) => state.plan.dentalPlan);
  const { displayedDentalServices: _displayedDentalServices } = plan;
  const displayedDentalServices =
    _displayedDentalServices as PlanServiceObject[];

  useEffect(() => {
    dispatch(updateDentalPlan(dentalPlan));
    dispatch(
      getDentalPlanServicesList(BenefitCategory.DENTAL.value, employerId || '')
    );
  }, [dispatch, dentalPlan, employerId]);

  const onChange = (dentalPlan: DentalPlan) => {
    dispatch(updateDentalPlan(dentalPlan));
  };

  const isAdvancedProfile = useGetUserFeatureStatus(
    brokerId,
    employerId,
    FEATURE_KEYS.ADVANCED_PROFILE,
    false
  );

  return (
    <>
      <div className={styles.dentalServiceDBGWrapper}>
        <Services
          onChange={onChange}
          planType={BenefitCategory.DENTAL.value}
          plan={dentalPlan}
          isDBGView={true}
          isTouched={false}
          ref={wrappedRef}
          isAdvancedEmployerProfile={isAdvancedProfile}
        />
        <AddCustomServiceModal
          planTypeName={BenefitCategory.DENTAL.label}
          benefitKind={BenefitCategory.DENTAL.value}
          visible={isAddCustomServiceModalOpen}
          displayedServices={displayedDentalServices}
          onClose={() => setIsAddCustomServiceModalOpen(false)}
          hsaCompatible={false}
          addedCustomServices={dentalPlan.customServices}
          isAdvancedProfileEmployer={isAdvancedProfile}
        />
      </div>
      {PanelSection.SERVICES === type && (
        <div className={styles.addNewServiceBtn}>
          <LinkButton
            onClick={() => {
              setIsAddCustomServiceModalOpen(true);
            }}
          >
            <img src={iconAdd} alt="add-icon" className={styles.iconStyle} />
            Add Service
          </LinkButton>
        </div>
      )}
    </>
  );
};

export default ServiceDBGWrapper;
