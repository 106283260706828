import { FC } from 'react';

import { Row } from 'antd';
import notebookIcon from 'assets/images/notebook-dark.svg';

import styles from './notFound.module.less';

const NotFound: FC = () => {
  return (
    <div className={styles.errorBoundaryWrapper}>
      <Row className={styles.detailsWrapper}>
        <img src={notebookIcon} alt="edit" />
      </Row>
      <Row className={styles.header}>It’s quiet in here</Row>
      <Row className={styles.subHeader}>
        Please contact your system administrator to enable plans for this
        benefit
      </Row>
    </div>
  );
};

export default NotFound;
