import React from 'react';

import ProgressBar from 'modules/claims/components/ProgressBar/ProgressBar';
import LegendLabel from 'modules/claims/components/LegendLabel/LegendLabel';
import LargeClaimsTable from 'modules/claims/components/LargeClaimsTable/LargeClaims';

import { currencyFormatter } from 'util/commonUtil';
import {
  COLOR_DARK_SEA_GREEN,
  COLOR_MEDIUM_SEA_GREEN,
  COLOR_LIGHT_ORANGE,
} from 'modules/claims/constants/constants';

import style from './claimsBreakdown.module.less';

type TableHeaderProps = {
  title: string;
  key: string;
  color: string;
  width: string;
  className?: string | undefined;
};

type ClaimsBreakDownChartDataProps = {
  month: string;
  progress: string;
  medClaims: string;
  rxClaims: string;
  capitation: string;
  total: string;
};

const colorsList = [
  COLOR_DARK_SEA_GREEN,
  COLOR_MEDIUM_SEA_GREEN,
  COLOR_LIGHT_ORANGE,
];
const monthList: { [key: string]: string } = {
  '1': 'JAN',
  '2': 'FEB',
  '3': 'MAR',
  '4': 'APR',
  '5': 'MAY',
  '6': 'JUN',
  '7': 'JUL',
  '8': 'AUG',
  '9': 'SEP',
  '10': 'OCT',
  '11': 'NOV',
  '12': 'DEC',
};

const tableHeaders = [
  {
    title: '',
    key: 'month',
    color: '',
    width: '6%',
    className: 'large-claim-month',
  },
  { title: '', key: 'progress', color: '', width: '25%' },
  {
    title: 'Total Medical Claims',
    key: 'medClaims',
    color: colorsList[0],
    width: '20%',
  },
  {
    title: 'Total Rx Claims',
    key: 'rxClaims',
    color: colorsList[1],
    width: '17%',
  },
  {
    title: 'Total Capitation',
    key: 'capitation',
    color: colorsList[2],
    width: '17%',
  },
  { title: 'Total Claims', key: 'total', color: '', width: '15%' },
];

const selfFundedHeaders = [
  {
    title: '',
    key: 'month',
    color: '',
    width: '6%',
    className: 'large-claim-month',
  },
  { title: '', key: 'progress', color: '', width: '30%' },
  {
    title: 'Total Medical Claims',
    key: 'medClaims',
    color: colorsList[0],
    width: '24%',
  },
  {
    title: 'Total Rx Claims',
    key: 'rxClaims',
    color: colorsList[1],
    width: '20%',
  },
  { title: 'Total Claims', key: 'total', color: '', width: '20%' },
];

const tableHeaderGenerator = (headerList: TableHeaderProps[]) => {
  return headerList.map(({ title, key, color, width, className }) => ({
    title: title && (
      <LegendLabel
        title={title}
        prefixShape={{
          circle: true,
          color: color,
          width: '9px',
          height: '9px',
        }}
        className={{
          wrapperClass: style.claimsBreakdownLabel,
          headerClass: style.claimsBreakdownHeader,
        }}
      />
    ),
    key: key,
    width: width,
    className: className || '',
  }));
};

const dataSourceGenerator = (dataList: any[], isSelfFunded: any) => {
  const defaultList: ClaimsBreakDownChartDataProps[] = [];
  Object.keys(monthList).forEach((key: string) => {
    return defaultList.push({
      month: monthList[key],
      progress: '-',
      medClaims: '-',
      rxClaims: '-',
      capitation: '-',
      total: '-',
    });
  });

  const formatter = (value: number) => {
    return currencyFormatter(Math.round(value), undefined, undefined, 0);
  };

  return dataList.length > 0
    ? dataList.map((chartData) => {
        const {
          displayLabel,
          totalMedicalClaims,
          totalRxClaims,
          totalCapitation,
          totalCost,
        } = chartData;
        return {
          month: (
            <>
              {!isSelfFunded && (
                <span className={style.claimBreakdownYearLabel}>
                  {displayLabel.split(' ')[0]}
                </span>
              )}
              <span className={style.claimBreakdownMonthLabel}>
                {displayLabel.split(' ')[1]}
              </span>
            </>
          ),
          progress:
            totalCost !== 0 ? (
              <ProgressBar
                segments={[
                  {
                    value: totalMedicalClaims,
                    color: colorsList[0],
                  },
                  {
                    value: totalRxClaims,
                    color: colorsList[1],
                  },
                  {
                    value: totalCapitation,
                    color: colorsList[2],
                  },
                ]}
                maxValue={Math.max(
                  ...dataList.map((data) =>
                    Math.max(
                      data.totalMedicalClaims,
                      data.totalRxClaims,
                      data.totalCapitation,
                      data.totalCost
                    )
                  )
                )}
                barHeight={18}
                borderRadius={false}
              />
            ) : (
              <div style={{ textAlign: 'left', paddingLeft: '20px' }}>—</div>
            ),
          medClaims:
            totalMedicalClaims !== 0 ? formatter(totalMedicalClaims) : '—',
          rxClaims: totalRxClaims !== 0 ? formatter(totalRxClaims) : '—',
          capitation: totalCapitation !== 0 ? formatter(totalCapitation) : '—',
          total: totalCost !== 0 ? formatter(totalCost) : '—',
        };
      })
    : defaultList;
};

type Props = {
  claimsBreakdown: any[];
  isSelfFunded: boolean;
};

const ClaimsBreakdown = (props: Props) => {
  const { claimsBreakdown, isSelfFunded } = props;
  return (
    <LargeClaimsTable
      tableHeaders={
        isSelfFunded
          ? tableHeaderGenerator(selfFundedHeaders)
          : tableHeaderGenerator(tableHeaders)
      }
      dataSource={
        claimsBreakdown && dataSourceGenerator(claimsBreakdown, isSelfFunded)
      }
    />
  );
};

export default ClaimsBreakdown;
