import { Dispatch } from 'redux';
import axios, { AxiosResponse } from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import * as benguideService from 'modules/benefitGuide/services/BenguideService';
import { getBenguidesByEmployerIdAndPlanYearId } from 'modules/employers/slices/benguideSlice';
import BenguideBasicData, { Secured } from 'model/BenguideBasicData';
import { BenefitGuideCloneRequest } from 'model/BenefitGuideCloneRequest';
import EmployerName from 'model/EmployerName';

type BenguideStateProps = {
  isBenguideModalOpen: boolean;
  deleteBenguide: {
    inprogress: boolean;
    error: any;
    deleteSuccess: boolean;
  };
  moveToInProgress: {
    inprogress: boolean;
    error: any;
  };
  benguidePassword: {
    inprogress: boolean;
    data: Secured | null;
    error: any;
  };
  cloneBenguide: {
    inprogress: boolean;
    error: any;
    createdPlanId: string | null;
    isPlanIncluded: false;
  };
  populateBenguidePlans: {
    inprogress: boolean;
    error: any;
  };
  previewData: {
    inProgress: boolean;
    error: any;
  };
  selectedEmployerId: string;
  updateEmployerName: {
    inProgress: boolean;
    error: any;
  };
};

const initialState = {
  isBenguideModalOpen: false,
  deleteBenguide: {
    inprogress: false,
    error: null,
    deleteSuccess: false,
  },
  moveToInProgress: {
    inprogress: false,
    error: null,
  },
  benguidePassword: {
    inprogress: false,
    data: null,
    error: null,
  },
  cloneBenguide: {
    inprogress: false,
    error: null,
    createdPlanId: null,
  },
  populateBenguidePlans: {
    inprogress: false,
    error: null,
  },
  previewData: {
    inProgress: false,
    error: null,
  },
  selectedEmployerId: '',
  updateEmployerName: {
    inProgress: false,
    error: null,
  },
} as BenguideStateProps;

const benguideSlice = createSlice({
  name: 'benguide',
  initialState,
  reducers: {
    changedBenguideModalState: (state, { payload }) => {
      state.isBenguideModalOpen = payload;
    },
    changedEmployerIdState: (state, { payload }) => {
      state.selectedEmployerId = payload;
    },
    deleteBenguideStarted: (state) => {
      state.deleteBenguide.inprogress = true;
      state.deleteBenguide.deleteSuccess = false;
    },
    deleteBenguideCompleted: (state) => {
      state.deleteBenguide.inprogress = false;
      state.deleteBenguide.deleteSuccess = true;
    },
    deleteBenguideFailed: (state, { payload }) => {
      state.deleteBenguide.inprogress = false;
      state.deleteBenguide.error = { response: payload };
    },
    resetBenguideDeleteState: (state) => {
      state.deleteBenguide.deleteSuccess = false;
      state.deleteBenguide.error = null;
      state.deleteBenguide.inprogress = false;
    },
    benguideMoveToInProgressStarted: (state) => {
      state.moveToInProgress.inprogress = true;
    },
    benguideMoveToInProgressCompleted: (state) => {
      state.moveToInProgress.inprogress = false;
    },
    benguideMoveToInProgressFailed: (state, { payload }) => {
      state.moveToInProgress.inprogress = false;
      state.moveToInProgress.error = { response: payload };
    },
    fetchBenguidePasswordStarted: (state) => {
      state.benguidePassword.inprogress = true;
    },
    fetchBenguidePasswordCompleted: (state, { payload }) => {
      state.benguidePassword.inprogress = false;
      state.benguidePassword.data = payload;
      state.benguidePassword.error = null;
    },
    fetchBenguidePasswordFailed: (state, { payload }) => {
      state.benguidePassword.inprogress = false;
      state.benguidePassword.data = null;
      state.benguidePassword.error = { response: payload };
    },
    benefitGuideCloningStarted: (state) => {
      state.cloneBenguide.inprogress = true;
      state.cloneBenguide.error = null;
      state.cloneBenguide.createdPlanId = null;
      state.cloneBenguide.isPlanIncluded = false;
    },
    benefitGuideCloningCompleted: (state, { payload }) => {
      state.cloneBenguide.inprogress = false;
      state.cloneBenguide.error = null;
      state.cloneBenguide.createdPlanId = payload.data.masterId;
      state.cloneBenguide.isPlanIncluded = payload.data.planIncluded;
    },
    benefitGuideCloningFailed: (state, { payload }) => {
      state.cloneBenguide.inprogress = false;
      state.cloneBenguide.error = { response: payload };
      state.cloneBenguide.createdPlanId = null;
      state.cloneBenguide.isPlanIncluded = false;
    },
    clearBenefitGuideCloning: (state) => {
      state.cloneBenguide.inprogress = false;
      state.cloneBenguide.error = null;
      state.cloneBenguide.createdPlanId = null;
      state.cloneBenguide.isPlanIncluded = false;
    },
    populatePlanStarted: (state) => {
      state.populateBenguidePlans.inprogress = true;
      state.populateBenguidePlans.error = null;
    },
    populatePlanCompleted: (state) => {
      state.populateBenguidePlans.inprogress = false;
      state.populateBenguidePlans.error = null;
    },
    populatePlanFailed: (state, { payload }) => {
      state.populateBenguidePlans.inprogress = false;
      state.populateBenguidePlans.error = { response: payload };
    },
    previewBenguideStarted: (state) => {
      state.previewData.inProgress = true;
      state.previewData.error = null;
    },
    previewBenguideSuccess: (state) => {
      state.previewData.inProgress = false;
      state.previewData.error = null;
    },
    previewBenguideError: (state, { payload }) => {
      state.previewData.inProgress = false;
      state.previewData.error = { response: payload };
    },
    updateEmployerNameStarted: (state) => {
      state.updateEmployerName.inProgress = true;
      state.updateEmployerName.error = null;
    },
    updateEmployerNameCompleted: (state) => {
      state.updateEmployerName.inProgress = false;
      state.updateEmployerName.error = null;
    },
    updateEmployerNameFailed: (state, { payload }) => {
      state.updateEmployerName.inProgress = false;
      state.updateEmployerName.error = { response: payload };
    },
  },
});

export const {
  changedBenguideModalState,
  deleteBenguideCompleted,
  deleteBenguideFailed,
  deleteBenguideStarted,
  resetBenguideDeleteState,
  benguideMoveToInProgressStarted,
  benguideMoveToInProgressCompleted,
  benguideMoveToInProgressFailed,
  fetchBenguidePasswordCompleted,
  fetchBenguidePasswordFailed,
  fetchBenguidePasswordStarted,
  benefitGuideCloningCompleted,
  benefitGuideCloningFailed,
  benefitGuideCloningStarted,
  populatePlanCompleted,
  populatePlanFailed,
  populatePlanStarted,
  previewBenguideStarted,
  previewBenguideSuccess,
  previewBenguideError,
  clearBenefitGuideCloning,
  changedEmployerIdState,
  updateEmployerNameCompleted,
  updateEmployerNameFailed,
  updateEmployerNameStarted,
} = benguideSlice.actions;

export default benguideSlice.reducer;

export const deleteBenguide = (
  benguideId: string,
  planYearId: string,
  employerId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteBenguideStarted());
    benguideService
      .deleteBenguide(benguideId)
      .then(() => {
        dispatch(deleteBenguideCompleted());
        dispatch(getBenguidesByEmployerIdAndPlanYearId(planYearId, employerId));
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          dispatch(
            deleteBenguideFailed(JSON.parse(JSON.stringify(error.response)))
          );
        } else {
          console.error(error);
        }
      });
  };
};

export const moveToBenguideInProgress = (
  benguideId: string,
  planYearId: string,
  employerId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(benguideMoveToInProgressStarted());
    benguideService
      .moveToInProgress(benguideId)
      .then(() => {
        dispatch(benguideMoveToInProgressCompleted());
        dispatch(getBenguidesByEmployerIdAndPlanYearId(planYearId, employerId));
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          dispatch(
            benguideMoveToInProgressFailed(
              JSON.parse(JSON.stringify(error.response))
            )
          );
        } else {
          console.error(error);
        }
      });
  };
};

export const previewBenguide = (benguideId: string, revision?: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(previewBenguideStarted());
    try {
      const { data } = await benguideService.getPreviewToken(benguideId);
      const { previewUrl } = data;
      dispatch(previewBenguideSuccess());
      if (revision) {
        window.open(`${previewUrl}&revision=${revision}`, '_blank');
      } else {
        window.open(previewUrl);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        dispatch(
          previewBenguideError(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const fetchBenguidePassword = (benguideId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchBenguidePasswordStarted());
    benguideService
      .getPassword(benguideId)
      .then(({ data }) => {
        dispatch(fetchBenguidePasswordCompleted(data));
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          dispatch(
            fetchBenguidePasswordFailed(
              JSON.parse(JSON.stringify(error.response))
            )
          );
        } else {
          console.error(error);
        }
      });
  };
};

export const saveBenguidePassword = (benguideId: string, secured: Secured) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchBenguidePasswordStarted());
    benguideService
      .savePassword(benguideId, secured)
      .then(({ data }) => {
        dispatch(fetchBenguidePasswordCompleted(data));
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          dispatch(
            fetchBenguidePasswordFailed(
              JSON.parse(JSON.stringify(error.response))
            )
          );
        } else {
          console.error(error);
        }
      });
  };
};

export const cloneBenguide = (
  benguideId: string,
  planYearId: string,
  employerId: string,
  cloneRequest: BenefitGuideCloneRequest
) => {
  return async (dispatch: Dispatch) => {
    dispatch(benefitGuideCloningStarted());
    benguideService
      .cloneBenguide(benguideId, cloneRequest)
      .then((response: AxiosResponse<BenguideBasicData>) => {
        dispatch(benefitGuideCloningCompleted(response));
        dispatch(getBenguidesByEmployerIdAndPlanYearId(planYearId, employerId));
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          dispatch(
            benefitGuideCloningFailed(
              JSON.parse(JSON.stringify(error.response))
            )
          );
        } else {
          console.error(error);
        }
      });
  };
};

export const populatePlans = (
  benguideId: string,
  planYearId: string,
  employerId: string,
  fromBenguodeId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(populatePlanStarted());
    benguideService
      .populatePlans(benguideId, fromBenguodeId)
      .then((res) => {
        dispatch(populatePlanCompleted());
        dispatch(getBenguidesByEmployerIdAndPlanYearId(planYearId, employerId));
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          dispatch(
            populatePlanFailed(JSON.parse(JSON.stringify(error.response)))
          );
        } else {
          console.error(error);
        }
      });
  };
};

export const enableEditModeForPublish = (
  benguideId: string,
  completed: Function
) => {
  return async (dispatch: Dispatch) => {
    const { data } = await benguideService.enableEditMode(benguideId);
    completed(data);
  };
};

export const previewBenguideWithBenefit = (
  benguideId: string,
  benefitKind: string
) => {
  return async (dispatch: Dispatch) => {
    const { data } = await benguideService.getPreviewTokenWithBenefit(
      benguideId,
      benefitKind
    );
    const { previewUrl } = data;
    window.open(previewUrl, '_blank');
  };
};

export const updateEmployerName = (
  benguideId: string,
  employerName: EmployerName,
  planYearId: string,
  employerId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateEmployerNameStarted());
    benguideService
      .updateEmployerName(benguideId, employerName)
      .then(() => {
        dispatch(updateEmployerNameCompleted());
        dispatch(getBenguidesByEmployerIdAndPlanYearId(planYearId, employerId));
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          dispatch(
            updateEmployerNameFailed(JSON.parse(JSON.stringify(error.response)))
          );
        } else {
          console.error(error);
        }
      });
  };
};
