import { lumityApi } from 'api/apiSlice';
import { ISSUE_TYPE_ACCOUNT } from 'modules/issueslog/constants/IssuesLogConstants';

const issuesLogProvisioningApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    provisionIssuesLog: builder.mutation<any, any>({
      query: (args) => {
        const { organizationId, uniqueName } = args;
        return {
          url: `v1/issues-log/provision`,
          method: 'post',
          data: { brokerId: organizationId, uniqueName: uniqueName },
        };
      },
    }),
    getProvisionStatus: builder.query<any, any>({
      query: (args) => {
        const { organizationId, currentTs } = args;
        return {
          url: `v1/issues-log/provision/${organizationId}`,
          method: 'GET',
          params: { currentTs: currentTs },
        };
      },
    }),
    updateAutomatedEmail: builder.mutation<
      void,
      { type: string; ownerId: string; email: string }
    >({
      query: (args) => {
        const { type, ownerId, email } = args;
        return {
          url: `${
            ISSUE_TYPE_ACCOUNT === type
              ? `v2/employers/${ownerId}/issues-log-automated-emails`
              : `v2/organizations/${ownerId}/issues-log-automated-emails`
          }`,
          method: 'PUT',
          data: email,
        };
      },
    }),
    getSupportEmails: builder.query<any, any>({
      query: (args) => {
        const { organizationId } = args;
        return {
          url: `v2/organizations/${organizationId}/issues-log-automated-emails`,
          method: 'GET',
        };
      },
    }),
    deleteSupportEmails: builder.mutation<any, any>({
      query: (args) => {
        const { organizationId, teamName, deleteTeam } = args;
        return {
          url: `v2/organizations/${organizationId}/issues-log-automated-emails`,
          method: 'DELETE',
          params: {
            'team-name': teamName,
            'delete-team': deleteTeam,
          },
        };
      },
    }),
    deleteAccoutEmail: builder.mutation<any, any>({
      query: (args) => {
        const { ownerId } = args;
        return {
          url: `v2/employers/${ownerId}/issues-log-automated-emails`,
          method: 'DELETE',
        };
      },
    }),
    retryAutomatedEmailVarification: builder.mutation<any, any>({
      query: (args) => {
        const { email } = args;
        return {
          url: `v2/organizations/issues-log-automated-emails/resend-verification`,
          method: 'PUT',
          params: {
            email: email,
          },
        };
      },
    }),
  }),
});

export const {
  useProvisionIssuesLogMutation,
  useUpdateAutomatedEmailMutation,
  useLazyGetProvisionStatusQuery,
  useLazyGetSupportEmailsQuery,
  useDeleteSupportEmailsMutation,
  useDeleteAccoutEmailMutation,
  useRetryAutomatedEmailVarificationMutation,
} = issuesLogProvisioningApi;
