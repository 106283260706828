import { lumityApi } from 'api/apiSlice';

const dashboardRenewalApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    renewalDashboard: builder.query<
      any,
      {
        brokerId: string | undefined;
      }
    >({
      query: (args) => {
        const { brokerId } = args;
        return {
          url: `v2/employers/renewals/dashboard`,
          method: 'get',
          params: {
            'organization-id': brokerId,
          },
        };
      },
    }),
  }),
});
export const { useRenewalDashboardQuery } = dashboardRenewalApi;
