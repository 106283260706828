import { FC, useState, useEffect } from 'react';
import { Checkbox, Col, Dropdown, Menu, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import styles from './benefitClassDropdown.module.less';

type BenefitClassDropdownProps = {
  onChange: (value: Array<string>) => void;
  className: string;
  allBenefitGroups: Array<string>;
};

const BenefitClassDropdown: FC<BenefitClassDropdownProps> = (props) => {
  const { onChange, className, allBenefitGroups } = props;

  const [selectAll, setSelectAll] = useState(true);
  const [visible, setVisible] = useState(false);
  const [selectedBenefitGroups, setSelectedBenefitGroups] = useState<string[]>(
    []
  );
  const [selectedValue, setSelectedValue] = useState('All');

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  const onClickSelectAll = () => {
    if (selectAll) {
      setSelectAll(false);
      setSelectedBenefitGroups([]);
    } else {
      setSelectAll(true);
      setSelectedBenefitGroups(allBenefitGroups);
    }
  };

  const onCheckboxClick = (group: string) => {
    if (selectedBenefitGroups.includes(group)) {
      const newSelectedBenefitGroups: Array<string> = [];
      selectedBenefitGroups.forEach((value) => {
        if (value !== group) {
          newSelectedBenefitGroups.push(value);
        }
      });
      setSelectAll(false);
      setSelectedBenefitGroups(newSelectedBenefitGroups);
    } else {
      const newSelectedBenefitGroups = [...selectedBenefitGroups];
      newSelectedBenefitGroups.push(group);
      setSelectedBenefitGroups(newSelectedBenefitGroups);
    }
  };

  useEffect(() => {
    if (allBenefitGroups) {
      // initialize state
      setSelectedBenefitGroups(allBenefitGroups);
    }
  }, [allBenefitGroups]);

  useEffect(() => {
    if (selectAll) {
      setSelectedValue('All');
    } else {
      setSelectedValue(
        `(${selectedBenefitGroups.length}) ${selectedBenefitGroups.join(', ')}`
      );
    }
    onChange(selectedBenefitGroups);
  }, [selectedBenefitGroups, onChange, selectAll]);

  useEffect(() => {
    if (selectedBenefitGroups.length === allBenefitGroups.length) {
      setSelectAll(true);
    }
  }, [selectedBenefitGroups.length, allBenefitGroups.length]);

  const menu = (
    <Menu selectable={false} className={styles.dropdownMenu}>
      <Menu.Item key={-1}>
        <Checkbox
          checked={selectAll}
          className={styles.checkbox}
          onClick={(e) => {
            onClickSelectAll();
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          All
        </Checkbox>
      </Menu.Item>
      {allBenefitGroups.map((group) => (
        <Menu.Item key={group}>
          <Checkbox
            checked={selectedBenefitGroups.includes(group)}
            onClick={(e) => {
              onCheckboxClick(group);
              e.stopPropagation();
              e.preventDefault();
            }}
            className={styles.menuCheckbox}
          >
            <div className={styles.benefitGroupLabels}>{group}</div>
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <div className={className}>
        <span className={styles.benefitClassesLabel}>Benefit Classes:</span>
      </div>
      <Dropdown
        onVisibleChange={handleVisibleChange}
        visible={visible}
        overlay={menu}
        trigger={['click']}
      >
        <div className={styles.dropdownContainer}>
          <Row>
            <Col>
              <div className={styles.selectedLabel}>{selectedValue}</div>
            </Col>
            <Col>
              <DownOutlined className={styles.dropdownArrow} />
            </Col>
          </Row>
        </div>
      </Dropdown>
    </>
  );
};

export default BenefitClassDropdown;
