import { lumityApi } from 'api/apiSlice';

const rfpApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    generateRfpAnswers: builder.mutation<
      any,
      { brokerId: string; question: string; prompt: string }
    >({
      query: (args) => {
        const { brokerId, question, prompt } = args;
        return {
          url: `v1/ai-tooling/rfp/${brokerId}/answer`,
          method: 'POST',
          data: {
            question: question,
            metadata: '',
            prompt: prompt,
          },
        };
      },
    }),
    getRFPHistory: builder.query<any, { brokerId: string }>({
      query: (args) => {
        const { brokerId } = args;
        return {
          url: `v1/ai-tooling/rfp/${brokerId}/history`,
          method: 'GET',
        };
      },
    }),

    uploadRfpDocument: builder.mutation<
      any,
      { brokerId: string; fileList: any; metaData: string }
    >({
      query: (args) => {
        const { brokerId, fileList, metaData } = args;

        const formData = new FormData();
        formData.append('file', fileList);
        formData.append('metadata', metaData);

        return {
          url: `v1/ai-tooling/rfp/${brokerId}/train`,
          method: 'POST',
          data: formData,
        };
      },
    }),

    getRfp: builder.mutation({
      query: ({
        page,
        key,
        query,
        size,
        sort,
        brokerId,
        start,
        locationIds,
      }) => {
        return {
          url: `v1/ai-tooling/rfp/${brokerId}/search`,
          method: 'POST',
          data: {
            locationIds,
          },
          params: {
            page: page,
            size: size,
          },
        };
      },
    }),
  }),
});

export const {
  useGetRfpMutation,
  useGenerateRfpAnswersMutation,
  useGetRFPHistoryQuery,
  useLazyGetRFPHistoryQuery,
  useUploadRfpDocumentMutation,
} = rfpApi;
