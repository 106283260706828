import { Dispatch } from 'redux';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import * as planService from 'modules/plans/services/PlanService';
import ErrorDetails from 'model/ErrorDetails';
import { lumityApi } from 'api/apiSlice';
import SFCPlanConfig from 'model/plans/SFCPlanConfig';

export type DeletePlanState = {
  deletePlan: {
    inProgress: boolean;
    data: any;
    error: any;
  };
  hraPlans: {
    inProgress: boolean;
    data: any;
    error: any;
  };
};

const initialState = {
  deletePlan: {
    inProgress: false,
    error: null,
  },
  hraPlans: {
    inProgress: false,
    data: [],
    error: null,
  },
} as DeletePlanState;

const deletePlanSlice = createSlice({
  name: 'dbg',
  initialState,
  reducers: {
    startDeletePlan: (state) => {
      state.deletePlan.inProgress = true;
      state.deletePlan.error = null;
    },
    completedDeletePlan: (state) => {
      state.deletePlan.inProgress = false;
      state.deletePlan.error = null;
    },
    failedDeletePlan: (state, action) => {
      state.deletePlan.inProgress = false;
      state.deletePlan.error = JSON.parse(JSON.stringify(action.payload));
    },
    getHraPlanListInProgress(state) {
      state.hraPlans.inProgress = true;
      state.hraPlans.error = null;
    },
    getHraPlanListCompleted(state, action) {
      const { payload } = action;
      state.hraPlans.inProgress = false;
      state.hraPlans.error = null;
      state.hraPlans.data = payload;
    },
    getHraPlanListFailed(state, action) {
      state.hraPlans.inProgress = false;
      state.hraPlans.error = { response: action.payload };
      state.hraPlans.data = [];
    },
    setHraPlanList(state, action) {
      const { payload } = action;
      state.hraPlans.inProgress = false;
      state.hraPlans.error = null;
      state.hraPlans.data = payload;
    },
  },
});

export const {
  completedDeletePlan,
  failedDeletePlan,
  startDeletePlan,
  getHraPlanListCompleted,
  getHraPlanListFailed,
  getHraPlanListInProgress,
  setHraPlanList,
} = deletePlanSlice.actions;

export default deletePlanSlice.reducer;

export const deletePlan = (
  employerId: string,
  planMasterId: string,
  benefitKind: string,
  callBack: Function,
  isIgnoreHRAHSADependencies: boolean
) => {
  return async (dispatch: Dispatch) => {
    dispatch(startDeletePlan());
    try {
      await planService.deletePlan(
        employerId,
        planMasterId,
        benefitKind,
        isIgnoreHRAHSADependencies
      );
      dispatch(completedDeletePlan());
      callBack();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            failedDeletePlan({
              data: error.response.data,
            } as ErrorDetails)
          );
        }
      }
      callBack(true);
    }
  };
};

export const getHraPlanList = (employerId: string, planYearId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getHraPlanListInProgress());
    try {
      const medicalResponse = planService.getMedicalHraPlans(
        employerId,
        planYearId
      );
      const dentalResponse = planService.getDentalHraPlans(
        employerId,
        planYearId
      );
      const visionResponse = planService.getVisionHraPlans(
        employerId,
        planYearId
      );

      const [medical, dental, vision] = await Promise.all([
        medicalResponse,
        dentalResponse,
        visionResponse,
      ]);

      const response = {
        medical: medical.data,
        dental: dental.data,
        vision: vision.data,
      };
      dispatch(getHraPlanListCompleted(response));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getHraPlanListFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const planApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getSFCValues: builder.query<SFCPlanConfig, any>({
      query: (args) => {
        const { employerId, benefitKind, planYearId, carrierId, currentTs } =
          args;

        return {
          url: `v2/plans/employers/${employerId}/self-funded-claims`,
          method: 'get',
          params: {
            benefitKind: benefitKind,
            planYearId: planYearId,
            carrierId: carrierId,
            currentTs: currentTs,
          },
        };
      },
    }),
    updateHRAUtilizations: builder.mutation<any, any>({
      query: (args) => {
        return {
          url: `v1/plans/tax-advantage-accounts/sync-with-renewals`,
          method: 'PUT',
          data: args,
        };
      },
    }),
  }),
});

export const {
  useGetSFCValuesQuery,
  useLazyGetSFCValuesQuery,
  useUpdateHRAUtilizationsMutation,
} = planApi;
