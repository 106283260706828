import { FC, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useStateCallback } from 'hooks/updateState';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import CarrierContactsCreate from 'modules/carriers/components/CarrierContactCreateEdit/CarrierContactCreate';
import styles from './carrierContactCreateModal.module.less';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  carrierContactId?: string;
  fetchData?: () => void;
  carrierContactRef?: any;
  isEditFromDetail?: boolean;
  navigateFromSomewhereElse?: boolean;
  setIsDetailModalOpen?: (isModalOpen: boolean) => void;
};

const CarrierContactsCreateModal: FC<Props> = (props: Props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    carrierContactId,
    fetchData,
    carrierContactRef,
    isEditFromDetail,
    navigateFromSomewhereElse,
    setIsDetailModalOpen,
  } = props;

  const closeModalRef = useRef<any>(null);
  const [isConfirmOpen, setIsConfirmOpen] = useStateCallback(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isEdit = !!carrierContactId;

  const handleCloseModal = () => {
    setIsConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setIsConfirmOpen(false, () => {
      setIsModalOpen(false);
      fetchData?.();
    });
    if (closeModalRef.current) {
      closeModalRef.current.closeAll();
    }
    navigateFromSomewhereElse && navigate(location.pathname, { replace: true });
  };

  const closeWithoutConfirm = () => {
    setIsConfirmOpen(false);
    setIsModalOpen(false);
    navigateFromSomewhereElse && navigate(location.pathname, { replace: true });
  };

  const handleCloseDetailModal = () => {
    if (isEditFromDetail) return setIsDetailModalOpen?.(false);
  };

  return (
    <FullScreenModal
      visible={isModalOpen}
      onCancel={handleCloseModal}
      footer={false}
      title={`${isEdit ? 'Edit' : 'Add'} Carrier Contact`}
      destroyOnClose
    >
      <CarrierContactsCreate
        closeForm={closeWithoutConfirm}
        isEdit={isEdit}
        isModalOpen={isModalOpen}
        onCancel={handleCloseModal}
        carrierContactId={carrierContactId}
        fetchData={fetchData!}
        carrierContactRef={carrierContactRef}
        handleCloseDetailModal={handleCloseDetailModal}
      />
      {isConfirmOpen && (
        <ConfirmationDialog
          title="Are you sure you want to close?"
          confirmText="Yes - close and do not save"
          cancelText="Cancel"
          closeModal={() => setIsConfirmOpen(false)}
          onConfirm={handleCloseConfirm}
          visible={isConfirmOpen}
        >
          <p className={styles.warningConfirmation}>
            If you leave now, you will lose any unsaved data. Do you wish to
            continue?
          </p>
        </ConfirmationDialog>
      )}
    </FullScreenModal>
  );
};

export default CarrierContactsCreateModal;
