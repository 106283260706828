import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import { ColumnsType } from 'antd/lib/table';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { toUpper } from 'lodash';

import NextButton from 'components/buttons/NextButton/NextButton';
import {
  DEFAULT_UPLOADED_AT_FILTER_VALUES,
  FILTER_RFP_BY_DATE,
  PROVIDE_CONTEXT_HEADER_MESSAGE,
} from 'modules/rfp/constants/RfpConstants';
import RfpTag from 'modules/rfp/components/RfpForm/RfpTag/RfpTag';
import { ReactComponent as PopoutIcon } from 'assets/images/popout-icon-blue.svg';
import DataTable from 'components/DataTable/DataTable';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { formatDate } from 'modules/rfp/utils/exportUtil';
import SearchBar from 'components/SearchBar/SearchBar';
import TablePagination from 'model/TablePagination';
import FilterDropDown from 'components/FilterDropDown/FilterDropDown';
import {
  getSingleRfp,
  getTrainingStatus,
  startRfpExtractProcess,
  downloadRfpTrainFile,
} from 'modules/rfp/services/RfpService';
import { useGetAllBrokerAdminsQuery } from 'modules/issueslog/slices/issuesLogSlice';
import {
  setLoading,
  setLoadingFalse,
  setRfpQuestions,
  setSelectedProvideContextFiles,
} from 'modules/rfp/slices/rfpQuestionSlice';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import { getAllTags } from 'modules/rfp/services/RfpTagService';
import LoadingScreen from './LoadingScreen/LoadingScreen';

import styles from './provideContext.module.less';

type ProvideContextProps = {
  nextStep: () => void;
  closeModal: () => void;
  setIsModalOpen?: (isModalOpen: boolean) => void;
  isModalOpen: boolean;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: Function;
};

const initialTrainingPagination: TablePagination = {
  sorterInfo: {
    columnKey: 'documentName',
    field: 'documentName',
    order: 'ascend',
  },
  paginationIndex: 1,
  filterInfo: {
    limit: 10,
    offset: 0,
    searchText: '',
  },
  filters: {},
};

const ProvideContext: FC<ProvideContextProps> = (
  props: ProvideContextProps
) => {
  const {
    nextStep,
    setIsModalOpen,
    isModalOpen,
    selectedRowKeys,
    setSelectedRowKeys,
  } = props;
  const { brokerId } = useParams();
  const dispatch = useAppDispatch();

  const uploadedLocationId = useAppSelector(
    (state) => state.rfp.uploadedLocationId
  );
  const completedTrainData = useAppSelector(
    (state) => state.rfp?.completedTrainData
  );

  const selectedContextIds = useAppSelector(
    (state) => state.rfp?.selectedProvideContextFiles
  );

  const inProgressId = useAppSelector((state) => state.rfp?.inProgressId);
  const isLoading = useAppSelector((state) => state.rfp?.isLoading);

  const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(false);

  const [addedTags, setAddedTags] = useState<any[]>([]);

  const [paginationConfigTraining, setPaginationConfigTraining] =
    useState<TablePagination>(initialTrainingPagination);

  const { paginationIndex, filterInfo } = paginationConfigTraining;
  const { limit, offset } = filterInfo;

  const startIndex = (paginationIndex - 1) * limit + offset;
  const endIndex = startIndex + limit;
  const onSelectChange = (newSelectedRowKeys: any[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    dispatch(setSelectedProvideContextFiles(newSelectedRowKeys));
  };

  const [searchText, setSearchText] = useState<String>('');

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const { data: allBrokerAdminsData, isLoading: isLoadingBrokerData } =
    useGetAllBrokerAdminsQuery({
      organizationId: brokerId!,
      onlyActiveIndividuals: false,
    });

  // eslint-disable-next-line
  const [rfpTrainingHistory, setRfpTrainingHistory] = useState<any[]>([]);
  const [filteredRfpTrainingHistory, setFilteredRfpTrainingHistory] = useState<
    any[]
  >([]);
  const [uploadedAtFilterValues, setUploadedAtFilterValues] = useState<any[]>(
    DEFAULT_UPLOADED_AT_FILTER_VALUES
  );
  const [uploadedByFilterValues, setUploadedByFilterValues] = useState<any[]>(
    []
  );
  const [tagsFilterValues, setTagsFilterValues] = useState<any[]>([]);

  const searchTextPredicate = (item: any) => {
    // Check if searchText is empty, if so, return true to include all items
    if (!searchText) return true;
    const lowerSearchText = searchText?.toLowerCase();
    const nameMatch = item?.documentName
      ?.toLowerCase()
      ?.includes(lowerSearchText);
    const tagMatch = item?.tags?.some((tag: any) =>
      tag?.tagName?.toLowerCase()?.includes(lowerSearchText)
    );
    // Return true if either nameMatch or tagMatch is true
    return nameMatch || tagMatch;
  };

  const tagsFilterPredicate = (record: any) => {
    if (!tagsFilterValues?.length) return true;

    const tagMatch = record?.tags?.some((tag: any) =>
      tagsFilterValues.includes(tag?.id)
    );

    return tagMatch;
  };

  useEffect(() => {
    getTrainingStatus(brokerId || '').then(({ data }) => {
      setRfpTrainingHistory(
        data.sort(
          (a: any, b: any) =>
            new Date(b.createdTs).getTime() - new Date(a.createdTs).getTime()
        )
      );
    });
    // set selected row keys on mount
    setSelectedRowKeys(selectedContextIds);
    // eslint-disable-next-line
  }, []);

  const uploadedAtPredicate = (record: any) => {
    if (!uploadedAtFilterValues?.length) return false;
    const uploadedAt = dayjs(record?.createdTs);

    const rangedDates = uploadedAtFilterValues.map(([a, b]) => [
      dayjs().subtract(a, 'd'),
      dayjs().subtract(b, 'd'),
    ]);

    return rangedDates.some(([a, b]) => {
      if (a.isBefore(b)) {
        // Normal case
        return uploadedAt.isAfter(b) && uploadedAt.isBefore(a);
      } else {
        // Handle leap year case
        return (
          (uploadedAt.isAfter(b) && uploadedAt.isBefore(a)) ||
          (uploadedAt.month() === 2 && uploadedAt.date() === 29)
        );
      }
    });
  };

  const uploadedByPredicate = (record: any) => {
    if (!uploadedByFilterValues?.length) return true;
    const isUploadedBy = uploadedByFilterValues
      .map((email) => email?.toLowerCase())
      .some((email) => email === record?.lastUpdatedBy?.toLowerCase());
    return isUploadedBy;
  };

  const locationPredicate = (record: any) => {
    if (!uploadedLocationId) return true;
    return record.locationId === uploadedLocationId;
  };

  const applyFilters = () => {
    setFilteredRfpTrainingHistory(
      completedTrainData
        .filter(locationPredicate)
        .filter(searchTextPredicate)
        .filter(uploadedAtPredicate)
        .filter(uploadedByPredicate)
        .filter(tagsFilterPredicate)
    );
    setPaginationConfigTraining(initialTrainingPagination);
  };

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line
  }, [
    completedTrainData,
    uploadedAtFilterValues,
    uploadedByFilterValues,
    tagsFilterValues,
    searchText,
  ]);

  const rfpLisatStatusInterval = useRef<any>();
  const isError = useAppSelector((state) => state.rfp?.isRfpExtractError);
  const isFailed = useAppSelector((state) => state.rfp?.isRfpFailed);
  const rfpQuestions = useAppSelector((state) => state.rfp?.rfpQuestions);

  const pingRfp = async (inProgressId: string) => {
    const data = await getSingleRfp(brokerId!, inProgressId);
    if (data?.status === 'COMPLETED') {
      const questions = data?.questionsAndAnswers.map(
        (item: any, index: any) => ({
          question: item?.question,
          answer: item?.answer,
          isPending: false,
          index: index,
          context: item?.context,
        })
      );
      dispatch(setRfpQuestions(questions));
      dispatch(setLoadingFalse());
      nextStep();
      clearInterval(rfpLisatStatusInterval.current);
    } else if (data?.status === 'FAILED') {
      dispatch(setLoadingFalse());
      clearInterval(rfpLisatStatusInterval.current);
    }
  };

  const getData = (filters: TablePagination) => {
    const paginationConfigData = {
      paginationIndex: filters.paginationIndex,
      filterInfo: {
        limit: filters.filterInfo.limit,
        offset: filters.filterInfo.offset,
        searchText: searchText,
      },
      filters: filters.filters,
    } as TablePagination;
    setPaginationConfigTraining(paginationConfigData);
  };

  useEffect(() => {
    if (isError || isFailed) {
      dispatch(setLoadingFalse());
      clearInterval(rfpLisatStatusInterval.current);
    }
    // eslint-disable-next-line
  }, [isError, isFailed]);

  const startPingRfpStatus = (inProgressId: string) => {
    rfpLisatStatusInterval.current = setInterval(() => {
      if (inProgressId) {
        pingRfp(inProgressId);
      }
    }, 3000);
  };

  useEffect(() => {
    return () => {
      if (rfpQuestions) {
        clearInterval(rfpLisatStatusInterval.current);
      }
    };
  }, [rfpQuestions]);

  const columns: ColumnsType<any> = [
    {
      title: 'TRAINING FILE NAME',
      width: '75%',
      dataIndex: 'trainingFilename',
      key: 'trainingFilename',
      defaultSortOrder: 'ascend',
      render: (item, rfp: any) => {
        return (
          <div className={styles.tableNameWrapper}>
            <div className={styles.nameAndPopIconArea}>
              <b>{item}</b>
              <div className={styles.popOutIcon}>
                <PopoutIcon
                  onClick={() => {
                    downloadRfpTrainFile(rfp?.key, item);
                  }}
                />
              </div>
            </div>
            <div className={styles.tagWrapper}>
              {rfp?.tags?.map((tag: any) => {
                return (
                  <RfpTag key={tag?.tagName} tagValue={toUpper(tag?.tagName)} />
                );
              })}
            </div>
          </div>
        );
      },
    },
    {
      title: 'UPLOADED BY',
      width: '25%',
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
      defaultSortOrder: 'ascend',
      render: (item: any, rfp: any) => {
        return (
          <>
            {formatDate(rfp?.createdTs)} <br />
            <div className={styles.lastEditedAreaDiv}>by {item}</div>
          </>
        );
      },
    },
  ];

  const getFullName = (item: any) => {
    const foundItem = allBrokerAdminsData?.find((broker: any) => {
      return broker?.email === item;
    });
    if (foundItem) {
      return foundItem?.firstName + ' ' + foundItem?.lastName;
    } else {
      return item;
    }
  };

  const mappedData = filteredRfpTrainingHistory.map((item: any) => ({
    key: item?.id,
    trainingFilename: item?.documentName,
    createdTs: item?.createdTs,
    tags: item?.tags,
    uploadedBy: getFullName(item?.lastUpdatedBy),
  }));

  const handleTrainSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setPaginationConfigTraining(initialTrainingPagination);
    const _searchText = e.target.value.trim();
    setSearchText(_searchText);
  };

  const handleNextButtonClick = async () => {
    try {
      dispatch(setLoading());
      setShowLoadingScreen(true);
      await startRfpExtractProcess(brokerId, inProgressId, selectedRowKeys);
      startPingRfpStatus(inProgressId);
    } catch (error) {
      console.log(error);
    }
  };

  const resetOnModalClose = () => {
    setPaginationConfigTraining(initialTrainingPagination);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    if (!isModalOpen) {
      resetOnModalClose();
    }
    return () => {
      resetOnModalClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const manageTagsClicked = async () => {
    const tags = await getAllTags(brokerId!);
    setAddedTags(tags);
  };

  useEffect(() => {
    manageTagsClicked();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.provideContextWrapper}>
      <FixedAlertMessage
        className={styles.headerText}
        type="info"
        message={
          <>
            <b>Optional : </b>
            {PROVIDE_CONTEXT_HEADER_MESSAGE}
          </>
        }
      />
      {showLoadingScreen && (
        <LoadingScreen
          saveReturnOnclick={() => {
            setIsModalOpen!(false);
          }}
        />
      )}
      <div
        className={
          !showLoadingScreen
            ? `${styles.mainProvideContextArea}`
            : `${styles.disabledArea}`
        }
      >
        <div className={styles.filterArea}>
          <SearchBar onChange={handleTrainSearch} placeholder="Search" />
          <div className={styles.filterDropDownArea}>
            <FilterDropDown
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              defaultOptions={tagsFilterValues}
              resetOptions={[]}
              options={addedTags?.map((tag: any) => {
                return {
                  value: tag?.id,
                  label: toUpper(tag?.tagName),
                };
              })}
              showSearch
              showCustomButton={false}
              searchPlaceholder="Search Tags"
              handleResetCallback={() => {
                setTagsFilterValues([]);
              }}
              handleOkayCallback={(value) => {
                setTagsFilterValues(value);
              }}
              placeholder="Filter by Tag"
              filterName="Filter by Tag"
            />
          </div>
          <div className={styles.filterDropDownArea}>
            <FilterDropDown
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              defaultOptions={uploadedByFilterValues}
              resetOptions={[]}
              isLoading={isLoadingBrokerData}
              options={allBrokerAdminsData?.map((broker: any) => {
                return {
                  value: broker?.email,
                  label: broker?.firstName + ' ' + broker?.lastName,
                };
              })}
              showSearch
              showCustomButton={false}
              searchPlaceholder="Search Admins"
              handleResetCallback={() => {
                setUploadedByFilterValues([]);
              }}
              handleOkayCallback={(value) => {
                setUploadedByFilterValues(value);
              }}
              placeholder="Uploaded By"
              filterName="Uploaded By"
            />
          </div>
          <div className={styles.filterDropDownArea}>
            <FilterDropDown
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              isUseLabelForPlaceholder
              showCustomButton={false}
              defaultOptions={uploadedAtFilterValues}
              resetOptions={DEFAULT_UPLOADED_AT_FILTER_VALUES}
              showSearch={false}
              optionSort={false}
              options={FILTER_RFP_BY_DATE}
              handleResetCallback={() => {
                setUploadedAtFilterValues(DEFAULT_UPLOADED_AT_FILTER_VALUES);
              }}
              handleOkayCallback={(value) => {
                setUploadedAtFilterValues(value);
              }}
              placeholder="Uploaded Date"
              filterName="Uploaded Date"
            />
          </div>
        </div>
        <div className={styles.tagTableWrapper}>
          <DataTable
            rowSelection={rowSelection}
            data={mappedData?.slice(startIndex, endIndex)}
            columns={columns}
            pagination
            getData={getData}
            currentIndex={paginationConfigTraining?.paginationIndex}
            total={String(mappedData?.length)}
            pageSize={paginationConfigTraining?.filterInfo?.limit}
            rfpContextPadding
          />
        </div>
      </div>
      <span className={styles.selectedAmountDiv}>
        {hasSelected ? `${selectedRowKeys.length} files Selected` : ''}
      </span>
      {isLoading ? (
        <NextButton
          className={styles.nextButtonDisabled}
          buttonText="Next"
          nextStep={() => {}}
        />
      ) : (
        <NextButton
          className={styles.nextButton}
          buttonText="Next"
          nextStep={nextStep}
          onClick={handleNextButtonClick}
        />
      )}{' '}
    </div>
  );
};

export default ProvideContext;
