import { ReactNode, useState, useEffect } from 'react';

import { Modal } from 'antd';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch } from 'hooks/redux';

import DBGClient from 'modules/clients/DBGClient/DBGClient';
import AddWellbeingPlanDBGWrapper from 'modules/plans/wellbeing/components/AddWellbeingPlan/AddWellbeingPlanDBGWrapper';
import EditWellbeingPlan from 'modules/plans/wellbeing/components/EditWellbeingPlan/EditWellbeingPlan';
import useDBGClient from 'modules/clients/DBGClient/useDBGClient';

import { getCarriersByBenefitKind } from 'modules/plans/slices/basicPlanInfoSlice';

import {
  WELLBEING_PLAN_CHANNEL,
  RELOAD_WELLBEING_PLAN,
  OPEN_ADD_NEW_PLAN_MODAL,
  OPEN_WELLBEING_PLAN_EDIT_MODAL,
  RELOAD_WELLBEING_PLAN_LIST,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  EXIT_BENGUIDE,
  BENGUIDE_SAVE_COMPLETED,
  BENGUIDE_ACK_NOTIFICATION,
  CLOSE_MODALS,
} from 'modules/clients/DBGClient/DBGClientConts';
import { BenefitCategory } from 'constants/commonConstants';

import IconInfo from 'assets/images/icon-info.svg';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import { ERROR_MESSAGE_PLAN_EDITING } from 'constants/benguideCollaborationConstants';
import { changedBenguideModalState } from 'modules/benefitGuide/slices/benguideSlice';
import BenefitGuideNotificationsChannel from './BenefitGuideNotificationsChannel';

import styles from './wellbeingPlansChannel.module.less';

type EventComponent = {
  component: ReactNode;
  title: string;
  props?: object;
};

type EventDataType = {
  item: {
    value: string;
    label: string;
  };
  benefitKind: string;
};

const WellbeingPlansChannel = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [eventData, setEventData] = useState<EventDataType>();
  const [subscribedEvent, setSubscribedEvent] = useState('');
  const [disableSave, setDisableSave] = useState(false);
  const [planYearId, setPlanYearID] = useState<string>('');

  const { brokerId, employerId } = useNavContext();
  const dispatch = useAppDispatch();

  const cancelModalWhenRefresh = () => {
    setModalVisible(false);
  };

  const cancelModalWhenExit = () => {
    setModalVisible(false);
    dispatch(changedBenguideModalState(false));
  };

  const showOverlay = () => {
    if (disableSave) {
      dbgClient.postMessage({
        channel: WELLBEING_PLAN_CHANNEL,
        event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
        data: {},
      });
    }
  };

  useEffect(() => {
    if (
      brokerId &&
      employerId &&
      subscribedEvent === OPEN_WELLBEING_PLAN_EDIT_MODAL &&
      isModalVisible
    ) {
      dispatch(
        getCarriersByBenefitKind(
          BenefitCategory.WELLBEING.value,
          brokerId,
          employerId
        )
      );
    }
  }, [dispatch, subscribedEvent, brokerId, isModalVisible, employerId]);

  const eventComponentMapper = {
    [OPEN_ADD_NEW_PLAN_MODAL]: {
      component: AddWellbeingPlanDBGWrapper,
      title: '',
      props: {
        visible: isModalVisible,
        onClose: () => {
          setModalVisible(false);
          dbgClient.postMessage({
            channel: WELLBEING_PLAN_CHANNEL,
            event: RELOAD_WELLBEING_PLAN_LIST,
            data: {},
          });
          showOverlay();
        },
        benefitCategory: BenefitCategory.WELLBEING,
        isSaveDisabled: disableSave,
        isDBGPlan: true,
        dbgPlanYear: planYearId,
      },
      useInbuiltModal: true,
    } as EventComponent,
    [OPEN_WELLBEING_PLAN_EDIT_MODAL]: {
      component: EditWellbeingPlan,
      title: `Edit Wellbeing Plan`,
      props: {
        onClose: () => {
          dbgClient.postMessage({
            channel: WELLBEING_PLAN_CHANNEL,
            event: RELOAD_WELLBEING_PLAN,
            data: {},
          });
          showOverlay();
        },
        benefitCategory: BenefitCategory.WELLBEING,
        isDBGPlan: true,
        isSaveDisabled: disableSave,
      },
      useInbuiltModal: false,
      width: 600,
    } as EventComponent,
  } as any;

  const {
    component: Component,
    props,
    useInbuiltModal,
    width,
    title,
    cancelModal,
  } = eventComponentMapper[subscribedEvent] || {};

  const dbgClient = useDBGClient();

  return (
    <>
      <DBGClient
        channel={WELLBEING_PLAN_CHANNEL}
        subscribeCommon={(event: string) => {
          if (CLOSE_MODALS === event) {
            setModalVisible(false);
          }
        }}
        subscribe={(event: string, data: any, dbgMetaData: any) => {
          switch (event) {
            case EXIT_BENGUIDE:
              cancelModalWhenExit();
              break;
            case BENGUIDE_SAVE_COMPLETED:
              cancelModalWhenRefresh();
              break;
            case BENGUIDE_ACK_NOTIFICATION:
              setDisableSave(true);
              break;
            default:
              setSubscribedEvent(event);
              setEventData(data);
              setModalVisible(true);
              setDisableSave(false);
              setPlanYearID(dbgMetaData?.planYearId);
              break;
          }
        }}
      />
      <BenefitGuideNotificationsChannel
        setDisableSave={() => setDisableSave(true)}
        isPlanEditModalOpen={isModalVisible}
        benefitKind={BenefitCategory.WELLBEING.value}
      />
      {Component && useInbuiltModal ? (
        <Component {...props} />
      ) : (
        <Modal
          width={width}
          visible={isModalVisible}
          onCancel={cancelModal}
          okText="Done"
          footer={false}
          className={styles.wellbeingPlanChannelModal}
          closable={false}
          maskClosable={!disableSave}
          confirmLoading={true}
          destroyOnClose
        >
          <div className={styles.wellbeingPlanChannelWrapper}>
            <div className={styles.title}>{title}</div>
            <div className={styles.leftBorder}></div>
            <div className={styles.wellbeingPlanChannel}>
              <div>
                <img src={IconInfo} alt="Icon Info" />
              </div>
              <div className={styles.defaultText}>
                Changing plan information here will change the plan information
                everywhere this plan is shown.
              </div>
            </div>
            {disableSave && (
              <FixedAlertMessage
                type={'error'}
                message={ERROR_MESSAGE_PLAN_EDITING}
              />
            )}
            {Component && <Component plan={eventData} {...props} />}
          </div>
        </Modal>
      )}
    </>
  );
};

export default WellbeingPlansChannel;
