export const GENERAL_UPDATE_TITLE = 'Update Required';
export const GENERAL_UPDATE_MESSAGE =
  'has made a change to this benefits guide. Refresh to work from the latest version.';
export const DELETE_TITLE = 'Benefits Guide Deleted';
export const DELETE_MESSAGE = 'has deleted this benefits guide.';
export const ARCHIVED_TITLE = 'Benefits Guide Archived';
export const ARCHIVED_MESSAGE =
  'has archived this benefits guide. This guide can no longer be edited until it is moved to In Progress.';
export const STATUS_CHANGE_PUBLISHED_MESSAGE =
  'has published this benefits guide. Refresh to work from the latest version.';
export const STATUS_CHANGE_IN_PROGRESS_MESSAGE =
  'has moved this guide from Published to In Progress. Refresh to work from the latest version.';
// statuses
export const PUBLISHED = 'PUBLISHED';
export const IN_PROGRESS = 'DRAFT';
// notification keys
export const DBG_NOTIFICATION_KEY = 'dgbNotification';
export const PLAN_NOTIFICATION_KEY = 'planNotification';
// error message
export const ERROR_MESSAGE_PLAN_EDITING = (
  <span>
    Changes cannot be saved until guide is refreshed. Please take note of your
    changes as they <u>will not</u> be saved when you refresh this page.
  </span>
);

export const RENEWAL_COMMON_WARNING_MESSAGE_BASIC_INFO = (
  <span>
    Changes made to the Plan Year and Benefit Classes fields will not be applied
    to the ongoing renewal process. If updates are required for renewals, the
    renewal process must be reset.
  </span>
);

export const RENEWAL_COMMON_WARNING_MESSAGE_PREMIUM_CONTRIBUTIONS = (
  <span>
    Changes made to the Premium Type or the Tier Structure will not be applied
    to the ongoing renewal process. If updates are required for renewals, the
    renewal process must be reset.
  </span>
);

export const CONTRIBUTIONS_CHANGE_UP_EDIT_MESSAGE =
  'Updates to the contributions in this plan will be reflected in the associated Benefits Guides.';
