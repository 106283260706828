import { FC, useEffect } from 'react';

import { Alert, AlertProps } from 'antd';

import alertError from 'assets/images/alert-error.svg';
import alertInfo from 'assets/images/alert-info.svg';
import alertSuccess from 'assets/images/alert-success.svg';
import alertWarning from 'assets/images/alert-warning.svg';

import styles from './alertMessage.module.less';

interface Props extends AlertProps {
  closeAlert?: () => void;
  wrapperClassName?: string;
}

const ALERT_TYPE = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning',
};

export type AlertInfo = {
  type: 'success' | 'error' | 'info' | 'warning' | undefined;
  message: string | React.ReactNode;
};

export interface NavigationState {
  warning?: AlertInfo | null;
}

const AlertMessage: FC<Props> = (props) => {
  const {
    type,
    message,
    icon,
    closeAlert = () => {},
    className = styles.alert,
    wrapperClassName = '',
    closeText = <span onClick={closeAlert}>Close</span>,
  } = props;

  useEffect(() => {
    const time = setTimeout(() => {
      closeAlert();
    }, 15000);
    return () => clearTimeout(time);
  }, [closeAlert]);

  return (
    <div
      className={`${styles.alertWrapper} ${wrapperClassName} ${
        type === 'warning' && styles.alertWarningStlye
      }`}
    >
      <Alert
        type={type}
        message={message}
        icon={icon ? icon : getAlertIconByType(type)}
        closeText={closeText}
        showIcon
        className={className}
      />
    </div>
  );
};

const getAlertIconByType = (type: string | undefined) => {
  switch (type) {
    case ALERT_TYPE.success:
      return <img src={alertSuccess} alt="success icon" />;
    case ALERT_TYPE.error:
      return <img src={alertError} alt="error icon" />;
    case ALERT_TYPE.info:
      return <img src={alertInfo} alt="info icon" />;
    case ALERT_TYPE.warning:
      return <img src={alertWarning} alt="warning icon" />;
  }
};

export default AlertMessage;
