import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Button, Spin } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';

import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import { useAppSelector } from 'hooks/redux';
import { ReactComponent as Spinner } from 'assets/images/spinner-big.svg';
import {
  cancelAppointment,
  cancelAppointmentFailed,
  cancelAppointmentSuccess,
  confirmInvite,
  confirmInviteInProgress,
  confirmInviteSuccess,
  getCancelStatus,
  getConsultantCalendarSyncStatus,
  getConsultantType,
} from 'modules/benefitsConsultation/slices/commonSlice';
import planYearLogo from 'assets/images/navigation/PlanYearLogo.svg';
import googleCalendar from 'assets/images/google-calendar.svg';
import outlook from 'assets/images/outlook.png';
import ConsultantType from 'modules/benefitsConsultation/enums/ConsultantType';
import { CALENDAR_SYNC_DENIED } from 'modules/benefitsConsultation/constants/benefitsConsultation';

import styles from './publicBenefitConsultation.module.less';

type PublicBenefitConsultationType = {
  type: string;
};

const PublicBenefitConsultation: FC<PublicBenefitConsultationType> = (
  props: PublicBenefitConsultationType
) => {
  const { type } = props;
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const requestType = useAppSelector(
    (state) => state.benefitsConsultation.common.requestType
  );
  const inProgress = useAppSelector(
    (state) => state.benefitsConsultation.common.inProgress
  );
  const cancelStatus = useAppSelector(
    (state) => state.benefitsConsultation.common.cancelStatus
  );
  const consultantType = useAppSelector(
    (state) => state.benefitsConsultation.common.consultantType
  );
  const consultantCalendarSyncStatus = useAppSelector(
    (state) => state.benefitsConsultation.common.consultantCalendarSyncStatus
  );
  const error = useAppSelector(
    (state) => state.benefitsConsultation.common.error
  );

  const [selectedType, setSelectedType] = useState<string>(type);

  useEffect(() => {
    if (selectedType === 'invite-confirm') {
      dispatch(
        confirmInvite(searchParams.get('ref') as string, 'confirm-invite')
      );
    }
  }, [dispatch, searchParams, selectedType]);

  useEffect(() => {
    if (selectedType === 'cancel-appointment') {
      dispatch(
        getCancelStatus('appointment-status', searchParams.get('ref') as string)
      );
    }
  }, [dispatch, searchParams, selectedType]);

  useEffect(() => {
    if (
      selectedType === 'invite-confirm' &&
      requestType === confirmInviteSuccess.type
    ) {
      dispatch(getConsultantType(searchParams.get('ref') as string));
    }
  }, [dispatch, selectedType, requestType, searchParams]);

  useEffect(() => {
    if (selectedType === 'invite') {
      dispatch(
        getConsultantCalendarSyncStatus(searchParams.get('ref') as string)
      );
    }
  }, [dispatch, searchParams, selectedType]);

  const customSpinner = <Icon component={Spinner} spin />;

  const syncCalendar = async (type: string) => {
    dispatch(
      confirmInvite(
        searchParams.get('ref') as string,
        'pick-calendar-sync-type',
        type.toUpperCase()
      )
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img
          src={planYearLogo}
          alt="planyear logo"
          className={styles.lumityLogo}
        />
      </div>
      <div className={styles.content}>
        {inProgress && selectedType !== 'invite-confirm' ? (
          <>
            <h2>Please wait.</h2>
            <div>
              <div className={styles.spinnerWrapper}>
                <Spin indicator={customSpinner} className={styles.spinner} />
              </div>
            </div>
          </>
        ) : (
          <>
            {selectedType === 'invite' &&
              requestType !== confirmInviteSuccess.type && (
                <>
                  {consultantCalendarSyncStatus ? (
                    <h2>
                      Calendar has already been synced for this consultant.
                    </h2>
                  ) : (
                    <>
                      <h2>
                        Please select your calendar type to sync your calendar.
                      </h2>
                      <div className={styles.buttonArea}>
                        <Button
                          className={styles.calenderType}
                          onClick={async () => await syncCalendar('google')}
                        >
                          <img src={googleCalendar} />
                        </Button>
                        <Button
                          className={styles.calenderType}
                          onClick={async () => await syncCalendar('outlook')}
                        >
                          <img src={outlook} />
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
            {selectedType === 'invite-confirm' &&
              requestType === confirmInviteInProgress.type && (
                <>
                  <h2>Please wait. Your calendar is being synced.</h2>
                  <div>
                    <div className={styles.spinnerWrapper}>
                      <Spin
                        indicator={customSpinner}
                        className={styles.spinner}
                      />
                    </div>
                  </div>
                </>
              )}
            {error?.response?.data?.code === CALENDAR_SYNC_DENIED && (
              <>
                <h2>Calendar Sync has been canceled.</h2>
                <div className={styles.cancelSuccess}>
                  <p>
                    If you did not intend to cancel the sync, click
                    <Button
                      className={`${styles.link} ${styles.buttonLink}`}
                      type="link"
                      onClick={() => setSelectedType('invite')}
                    >
                      here
                    </Button>
                    to restart the process.
                  </p>
                </div>
              </>
            )}
            {selectedType === 'invite-confirm' && !isEmpty(consultantType) && (
              <>
                <h2>
                  You have successfully synced your calendar for Benefits
                  Consultation!
                </h2>
                <div>
                  <div className={styles.subContent}>
                    <p className={styles.paragraphContent}>
                      You are required to set up your availability and
                      conference details before employees can schedule
                      consultations with you.
                    </p>
                    <br />
                    <p className={styles.paragraphContent}>
                      {consultantType === ConsultantType.EMPLOYER ||
                      consultantType === ConsultantType.BROKER
                        ? 'Please log in to the platform to complete this process.'
                        : 'Please contact a system administrator from your company who can complete this process for you within our platform.'}
                    </p>
                  </div>
                </div>
              </>
            )}
            {selectedType === 'cancel-appointment' &&
              cancelStatus?.status === 'CN' && (
                <>
                  <h2>This consultation has already been canceled.</h2>
                  <div className={styles.cancelSuccess}>
                    <p>
                      Schedule a new consultation through your
                      <a
                        href={atob(searchParams.get('url') as string)}
                        className={styles.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        Benefits Guide.
                      </a>
                    </p>
                  </div>
                </>
              )}
            {selectedType === 'cancel-appointment' &&
              cancelStatus?.status !== 'CN' && (
                <>
                  <h2>Are you sure you want to cancel this consultation?</h2>
                  <div className={styles.buttonArea}>
                    <SubmitButton
                      className={styles.button}
                      onClick={() => setSelectedType('not-canceled')}
                    >
                      No
                    </SubmitButton>
                    <SubmitButton
                      className={styles.button}
                      type="primary"
                      onClick={() => {
                        dispatch(
                          cancelAppointment(
                            'cancel-appointment',
                            searchParams.get('ref') as string
                          )
                        );
                        setSelectedType('canceled');
                      }}
                    >
                      Yes
                    </SubmitButton>
                  </div>
                </>
              )}

            {selectedType === 'canceled' &&
              requestType === cancelAppointmentFailed.type && (
                <h2>
                  There was an error while trying to cancel the appointment.
                </h2>
              )}

            {selectedType === 'canceled' &&
              requestType === cancelAppointmentSuccess.type && (
                <>
                  <h2>You have successfully canceled the consultation.</h2>
                  <div className={styles.cancelSuccess}>
                    <p>
                      Schedule a new consultation through your
                      <a
                        href={atob(searchParams.get('url') as string)}
                        className={styles.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        Benefits Guide.
                      </a>
                    </p>
                  </div>
                </>
              )}

            {selectedType === 'not-canceled' && (
              <>
                <h2>Your consultation will remain as scheduled.</h2>
                <div className={styles.cancelSuccess}>
                  <p>
                    You may use the same cancelation link in the email to cancel
                    it if needed.
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PublicBenefitConsultation;
