import { FC, useEffect, useState } from 'react';
import { Form } from 'antd';

import { useNavContext } from 'hooks/useNavContext';

import { useAppDispatch } from 'hooks/redux';
import AddFourOOneKPlan from 'modules/plans/retirement/components/AddFourOOneKPlan/AddFourOOneKPlan';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { clearDocumentsAttached } from 'modules/plans/slices/retirementPlanSlice';

import styles from './addFourOOneKPlan.module.less';

type AddFourOOneKPlanDBGWrapperProps = {
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
  dbgPlanYear?: string;
  isDBGPlan?: boolean;
  isSaveDisabled?: boolean;
};

const AddFourOOneKPlanDBGWrapper: FC<AddFourOOneKPlanDBGWrapperProps> = ({
  visible,
  onClose,
  onSave,
  dbgPlanYear,
  isDBGPlan,
  isSaveDisabled = false,
}: AddFourOOneKPlanDBGWrapperProps) => {
  const [form] = Form.useForm();

  const { employer } = useNavContext();
  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);
  const [isCloseConfirmed, setClosedConfirmed] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (visible) {
      setIsModalVisible(true);
      setClosedConfirmed(false);
    } else {
      setIsModalVisible(false);
    }
  }, [visible]);

  const closePlanAddModal = () => {
    setVisibleConfirmation(true);
  };

  const toggleVisibleConfirmation = () => {
    setVisibleConfirmation(false);
    setClosedConfirmed(false);
  };

  const onConfirm = () => {
    setVisibleConfirmation(false);
    setClosedConfirmed(true);
    onClose();
    form.resetFields();
    dispatch(clearDocumentsAttached());
  };

  const handleSave = () => {
    onConfirm();
    onSave();
  };

  return (
    <>
      <FullScreenModal
        visible={visible}
        onCancel={closePlanAddModal}
        footer={false}
        title={`${employer?.name} - Add 401(k) Plan`}
        className={styles.mediaScreen}
      >
        <div>
          <AddFourOOneKPlan
            isCloseConfirmed={isCloseConfirmed}
            onCancel={closePlanAddModal}
            onSave={handleSave}
            form={form}
            dbgPlanYear={dbgPlanYear}
            isPlanUpdateMode={false}
            isDBGPlan={isDBGPlan}
            isModalVisible={isModalVisible}
            isSaveDisabled={isSaveDisabled}
          />
        </div>
      </FullScreenModal>
      <ConfirmationDialog
        title="Are you sure you want to close?"
        confirmText="Yes - Close and do not save plan"
        cancelText="Cancel"
        closeModal={toggleVisibleConfirmation}
        onConfirm={onConfirm}
        visible={visibleConfirmation}
      >
        <p className={styles.warningConfirmation}>
          If you leave now, you will lose any unsaved data. Do you wish to
          continue?
        </p>
      </ConfirmationDialog>
    </>
  );
};

export default AddFourOOneKPlanDBGWrapper;
