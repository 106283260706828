import { FC, useEffect, useState } from 'react';
import { Badge, Card, Col, Popover, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import reactHtmlParser from 'react-html-parser';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import {
  CREDIT_DROPDOWN_LABELS,
  LIFE,
  LTD,
  OFFER_TAGS,
  OFFER_TYPES,
  OfferType,
  STD,
} from 'modules/renewals/constants/renewalsConstants';
import { BenefitCategory } from 'constants/commonConstants';
import { Offer } from 'modules/renewals/models/Offer';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import OfferCardOverview from 'modules/renewals/components/helperComponents/OfferCardOverview/OfferCardOverview';
import OfferCardPlansTable, {
  PlanProcessing,
} from 'modules/renewals/components/helperComponents/OfferCardPlansTable/OfferCardPlansTable';
import FromCurrentPercentage from 'modules/renewals/components/helperComponents/FromCurrentPercentage/FromCurrentPercentage';
import DraftTagOnPublishedOffer from 'modules/renewals/components/helperComponents/DraftTagOnPublishedOffer/DraftTagOnPublishedOffer';
import {
  formatHeaderValues,
  isDtqOrPending,
} from 'modules/renewals/utils/renewalsUtils';
import { ReactComponent as CaretDownIcon } from 'assets/images/icon-caret-down-black.svg';
import { ReactComponent as IconStar } from 'assets/images/icon-star.svg';
import { ReactComponent as PrimaryCarrier } from 'assets/images/icon-primary.svg';
import { ReactComponent as SupplimentaryCarrier } from 'assets/images/icon-supplimentary.svg';
import { ReactComponent as CalendarIcon } from 'assets/images/icon-calendar-small.svg';
import { ReactComponent as InternalNote } from 'assets/images/internal-note.svg';
import { currencyFormatter } from 'util/commonUtil';
import { SelectedPlanProps } from 'modules/plans/components/AddPlanModal/AddPlanModal';
import ViewCreditDescription from 'modules/renewals/components/helperComponents/ViewCreditDescription/ViewCreditDescription';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import CreditInclusionPopup from 'modules/renewals/components/helperComponents/CreditInclusionPopup/CreditInclusionPopup';
import ActionDropdown from 'modules/renewals/components/helperComponents/ActionDropdown/ActionDropdown';
import { setIsFromOfferNavigatePY } from 'modules/renewals/slices/offerReducerSlice';
import { loginTypes } from 'constants/authConstants';
import { FundingTypeTag } from 'components/StdFundingTypeTag/FundingTypeTag';
import { QuoteProcessingStatusTag } from 'components/QuoteProcessingStatusTag/QuoteProcessingStatusTag';
import { useLazyGetOfferedPlanProcessingStatusByOfferIdQuery } from 'modules/renewals/slices/offersSlice';

import styles from './offerCard.module.less';

type OfferCardProps = {
  offer: Offer;
  detailPageNavigateUrl?: string;
  selectedEffectiveDate?: any;
  isHide?: boolean;
  creditDropDownDefault: any;
  setCreditDropDownDefault: (flag: any) => void;
  changeOfferStatus: Function;
  status: string;
  offerDeleteSuccess?: Function;
  offerEdit?: Function;
  setSelectedOfferId?: Function;
  offerClone: Function;
  loadList: Function;
  handleInternalNotesClick?: (offer: any) => void;
  changeOfferPublishStatus: Function;
  planYearId: string | null;
  hasSameNameDraftOffer: boolean;
  setAddPlansPropsToState: (props: SelectedPlanProps) => void;
};

const OfferCard: FC<OfferCardProps> = (props: OfferCardProps) => {
  const {
    offer,
    detailPageNavigateUrl = '',
    selectedEffectiveDate,
    isHide = false,
    creditDropDownDefault,
    setCreditDropDownDefault,
    changeOfferStatus,
    status,
    changeOfferPublishStatus,
    offerDeleteSuccess = () => {},
    offerEdit,
    offerClone,
    loadList,
    handleInternalNotesClick,
    planYearId = null,
    hasSameNameDraftOffer,
    setAddPlansPropsToState,
  } = props;
  const [isCreditDescriptionModalOpen, setCreditDescriptionModalOpen] =
    useState<boolean>(false);
  const [creditModes, setCreditModes] = useState<string[]>([]);
  const [selectedCreditDropDown, setSelectedCreditDropDown] = useState<any>(0);
  const [isCreditsIncludedModalVisible, setIsCreditsIncludedModalVisible] =
    useState<boolean>(false);
  const [isCreditsExcludedModalVisible, setIsCreditsExcludedModalVisible] =
    useState<boolean>(false);
  const [offerStatusField, setOfferStatusField] = useState<string | null>(
    offer.offerStatus
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedCreditMode = useAppSelector(
    (state) => state?.renewals.offers.selectedCreditMode
  );
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );
  const isBrokerAdmin: boolean = appBootInfo?.type === loginTypes.bokerAdmin;
  const isPlatformAdmin: boolean = appBootInfo?.type === loginTypes.platform;
  const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;

  const [
    getOfferedPlanByOfferStatus,
    { data: planExtractionData, isLoading: planExtractionDataLoading },
  ] = useLazyGetOfferedPlanProcessingStatusByOfferIdQuery();

  useEffect(() => {
    const mappedSet: any[] = CREDIT_DROPDOWN_LABELS.map(
      (creditMode: string, index: number) => {
        return {
          label: creditMode,
          value: index.toString(),
        };
      }
    );

    setCreditModes(mappedSet);
  }, [creditDropDownDefault]);

  const isPlanYearApplicable = (): boolean => {
    return null !== planYearId;
  };

  const getOfferStatusTag = () => {
    if (offer?.recommended) {
      return (
        <Card className={styles.recommendedTag}>
          <span className={styles.tagText}>
            <IconStar className={styles.starIcon} />
            RECOMMENDED
          </span>
        </Card>
      );
    } else if (offerStatusField === OFFER_TAGS.DTQ) {
      return (
        <Card className={styles.dtqTag}>
          <span className={styles.dtqTagText}>DECLINED TO QUOTE</span>
        </Card>
      );
    } else if (offerStatusField === OFFER_TAGS.PENDING) {
      return (
        <Card className={styles.pendingTag}>
          <span className={styles.pendingTagText}>PENDING</span>
        </Card>
      );
    }

    return null;
  };

  const changeCreditMode = (value: any) => {
    setSelectedCreditDropDown(value);
    if (value == 0) {
      setIsCreditsExcludedModalVisible(true);
    } else {
      setIsCreditsIncludedModalVisible(true);
    }
  };

  const totalAnnualPremiumFooter = () => {
    return (
      <span className={styles.footerLink}>
        <SingleSelectFilter
          data={creditModes}
          placeholder={'-'}
          showSearch={false}
          setFilterSelectedValue={(value: any) => changeCreditMode(value)}
          defaultValue={selectedCreditMode.toString()}
          selectedValue={selectedCreditMode.toString()}
          applicableIcon={<CaretDownIcon />}
          inlineDropdown={true}
          isDisabled={false}
          isWiderWrapper={true}
          isSelectionHighlightAndTickApplicable={false}
        />
      </span>
    );
  };

  const creditsFooter = () => {
    if (offer.discountAndCreditDescription !== null) {
      return (
        <span
          className={styles.footerLink}
          onClick={() => setCreditDescriptionModalOpen(true)}
        >
          View Description
        </span>
      );
    }
  };

  const isPlaceHolderOffer = (): boolean => {
    return offer?.placeholderCreated;
  };

  const fromCurrentFooter = () => {
    return offer.comparisons.initialDiffPercent !== null ? (
      <Row>
        {!isPlaceHolderOffer() && !offer?.noCurrentPlans && (
          <FromCurrentPercentage
            percentage={
              selectedCreditMode == 0
                ? offer.comparisons.initialDiffPercent
                : offer.fromCurrentPercentageWithCredit
            }
            decimalPlaces={2}
          />
        )}{' '}
        {isPlaceHolderOffer() || offer?.noCurrentPlans ? (
          <span className={styles.naLabel}>N/A</span>
        ) : (
          ''
        )}
        <span className={styles.fromCurrent}>from Current</span>
      </Row>
    ) : (
      <span></span>
    );
  };

  const planProcessing: PlanProcessing = {
    isLoading: planExtractionDataLoading,
    isProcessing: planExtractionData?.planProcessing ?? false,
  };

  const getPlanListHeader = () => {
    return (
      <img
        src={offer.carrierLogoUrl}
        alt={'carrier logo'}
        className={styles.carrierLogo}
      />
    );
  };

  const monthText = (monthCount: number) => {
    return monthCount === 1 ? 'MONTH' : 'MONTHS';
  };

  const getCarrierTypeAndEffectiveMonths = () => {
    if (OFFER_TYPES.PRIMARY === offer.carrierType) {
      return (
        <Row className={styles.carrierType}>
          <Col>
            <PrimaryCarrier className={styles.primaryCarrier} />{' '}
            {offer.carrierType}
          </Col>
          <Col
            className={
              selectedEffectiveDate === 0
                ? styles.displayEffectiveMonths
                : styles.hideEffectiveMonths
            }
          >
            |{' '}
            <Popover
              content={`${offer.effectiveDateFrom} - ${offer.effectiveDateTo}`}
              overlayClassName={'popoverInner recommendedtooltip'}
            >
              <div className={styles.effectiveMonths}>
                <CalendarIcon className={styles.calendarIcon} />
                {`${offer?.effectiveMonths} ${monthText(
                  offer?.effectiveMonths ?? 0
                )}`}
              </div>
            </Popover>
          </Col>
        </Row>
      );
    } else if (OFFER_TYPES.SUPPLEMENTARY === offer.carrierType) {
      return (
        <Row className={styles.carrierType}>
          <Col className={styles.carrierIconAndName}>
            <div className={styles.supplimentaryCarrier}>
              <SupplimentaryCarrier />{' '}
            </div>
            {offer.carrierType}
          </Col>
          <Col
            className={
              selectedEffectiveDate === 0
                ? styles.displayEffectiveMonths
                : styles.hideEffectiveMonths
            }
          >
            |{' '}
            <Popover
              content={`${offer.effectiveDateFrom} - ${offer.effectiveDateTo}`}
              overlayClassName={'popoverInner recommendedtooltip'}
            >
              <div className={styles.effectiveMonths}>
                <CalendarIcon className={styles.calendarIcon} />
                {`${offer?.effectiveMonths} ${monthText(
                  offer?.effectiveMonths ?? 0
                )}`}
              </div>
            </Popover>
          </Col>
        </Row>
      );
    } else {
      return (
        <div
          className={
            selectedEffectiveDate === 0
              ? styles.displayEffectiveMonths
              : styles.hideEffectiveMonths
          }
        >
          <Row className={styles.nonMedicalOffers}>
            <Popover
              content={`${offer.effectiveDateFrom} - ${offer.effectiveDateTo}`}
              overlayClassName={'popoverInner recommendedtooltip'}
            >
              <div className={styles.effectiveMonths}>
                <CalendarIcon className={styles.calendarIcon} />
                {`${offer?.effectiveMonths} ${monthText(
                  offer?.effectiveMonths ?? 0
                )}`}
              </div>
            </Popover>
          </Row>
        </div>
      );
    }
  };

  useEffect(() => {
    getOfferedPlanByOfferStatus({ offerId: offer?.id });
  }, [getOfferedPlanByOfferStatus, offer?.id]);

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.tagWrapper}>
        {offer.type === OfferType.RENEWAL && (
          <Card className={styles.currentCarrierTag}>
            <span className={styles.tagText}>RENEWAL</span>
          </Card>
        )}
        {getOfferStatusTag()}
        {(isBrokerAdmin || isPlatformAdmin) && hasSameNameDraftOffer && (
          <DraftTagOnPublishedOffer />
        )}
      </div>

      <Card className={styles.cardStyle}>
        <Row justify="space-between">
          <Col>
            {getCarrierTypeAndEffectiveMonths()}
            <Row
              className={
                ['STD', 'DENTAL', 'VISION', 'MEDICAL'].includes(offer?.category)
                  ? styles.stdCardTitle
                  : styles.cardTitle
              }
            >
              <span>{offer.name}</span>
            </Row>
            <Row className={styles.tagRow}>
              {['STD', 'DENTAL', 'VISION', 'MEDICAL'].includes(
                offer?.category
              ) && (
                <Row className={styles.stdOfferFundingTypeTag}>
                  <FundingTypeTag fundingType={offer?.fundingType} />
                </Row>
              )}
              {offer.extractionDetails && (
                <Row className={styles.stdOfferFundingTypeTag}>
                  <QuoteProcessingStatusTag
                    status={offer?.extractionDetails?.status}
                    isOpsAdmin={isOpsAdmin}
                  />
                </Row>
              )}
            </Row>
          </Col>

          <Col>
            <Row align="top">
              {(isBrokerAdmin || isPlatformAdmin) && (
                <Col className={styles.internalNotesContainer}>
                  <p
                    className={styles.brokerNotes}
                    onClick={() => {
                      if (handleInternalNotesClick) {
                        handleInternalNotesClick(offer);
                      }
                    }}
                  >
                    {offer.isNewNotesExist && (
                      <Badge color={'red'} className={styles.newComment} />
                    )}
                    <InternalNote className={styles.internalNoteIcon} />
                    Broker Notes ({offer.internalNoteCount})
                  </p>
                </Col>
              )}
              <Col>
                <Row>
                  <PageActionButton
                    className={styles.offerDetailsButton}
                    onClick={() => {
                      dispatch(setIsFromOfferNavigatePY(planYearId));
                      navigate(detailPageNavigateUrl, {
                        state: { status, planYearId },
                      });
                    }}
                  >
                    View Offer Details
                  </PageActionButton>
                </Row>
                {!isErAdmin && (
                  <Row className={styles.actionsDropdownWrapper}>
                    <ActionDropdown
                      offer={offer}
                      setOfferStatusField={setOfferStatusField}
                      onOfferRecommendedStateChange={(recommended: boolean) => {
                        changeOfferStatus(offer?.id, recommended);
                      }}
                      offerStatusField={offerStatusField}
                      offerDeleteSuccess={offerDeleteSuccess}
                      offerEdit={() => {
                        offerEdit && offerEdit(offer?.id);
                      }}
                      offerClone={() => {
                        offerClone && offerClone(offer?.id);
                      }}
                      loadData={loadList}
                      changeOfferPublishStatus={changeOfferPublishStatus}
                      isEditableCreateDisabled={isPlanYearApplicable()}
                    />
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        {!isHide && (
          <Row>
            <Col span={8}>
              <OfferCardOverview
                headerText="Total Est. Annual Premium"
                value={formatHeaderValues(
                  selectedCreditMode == 0
                    ? offer?.totalPremium
                    : offer?.totalAnnualPremiumWithCredit,
                  'CURRENCY',
                  false
                )}
                footerElement={totalAnnualPremiumFooter()}
                isDtqOrPending={isDtqOrPending(offerStatusField)}
              />
            </Col>
            <Col span={8}>
              <OfferCardOverview
                headerText="From Current"
                value={
                  !isPlaceHolderOffer()
                    ? formatHeaderValues(
                        selectedCreditMode == 0
                          ? offer.comparisons.initialDiff
                          : offer.fromCurrentWithCredit,
                        'CURRENCY',
                        true
                      )
                    : formatHeaderValues(
                        selectedCreditMode == 0
                          ? offer?.totalPremium
                          : offer?.totalAnnualPremiumWithCredit,
                        'CURRENCY',
                        false
                      )
                }
                footerElement={fromCurrentFooter()}
                isDtqOrPending={isDtqOrPending(offerStatusField)}
              />
            </Col>
            <Col span={8}>
              <OfferCardOverview
                headerText="Credits"
                value={
                  offer.discountAndCredit
                    ? currencyFormatter(
                        Math.round(offer.discountAndCredit),
                        undefined,
                        undefined,
                        0
                      )
                    : '-'
                }
                footerElement={creditsFooter()}
                isDtqOrPending={isDtqOrPending(offerStatusField)}
              />
            </Col>
          </Row>
        )}

        {offer.description && (
          <span className={styles.offerDescription}>
            {reactHtmlParser(offer?.description)}
          </span>
        )}
        <OfferCardPlansTable
          plans={
            offer.category === BenefitCategory.VOLUNTARY_BENEFIT.value
              ? offer.voluntaryPlans
              : offer.planContributions
          }
          headerElement={getPlanListHeader()}
          isVoluntaryBenefit={
            offer.category === BenefitCategory.VOLUNTARY_BENEFIT.value
          }
          isDtqOrPending={isDtqOrPending(offerStatusField)}
          isPlaceHolderCreated={offer?.placeholderCreated}
          isNonMdv={[LIFE, STD, LTD].includes(offer.category)}
          planDocuments={offer.attachedDocuments}
          setAddPlansPropsToState={setAddPlansPropsToState}
          offer={offer}
          planProcessing={planProcessing}
          planYearId={planYearId}
          category={offer.category}
        />
      </Card>
      <ViewCreditDescription
        carrier={offer.carrier}
        isModalVisible={isCreditDescriptionModalOpen}
        credit={offer.discountAndCredit}
        creditDescription={offer.discountAndCreditDescription}
        handleClose={setCreditDescriptionModalOpen}
      />
      <CreditInclusionPopup
        selectedCreditDropDown={selectedCreditDropDown}
        isCreditsExcludedModalVisible={isCreditsExcludedModalVisible}
        isCreditsIncludedModalVisible={isCreditsIncludedModalVisible}
        setIsCreditsExcludedModalVisible={setIsCreditsExcludedModalVisible}
        setIsCreditsIncludedModalVisible={setIsCreditsIncludedModalVisible}
        setCreditDropDownDefault={setCreditDropDownDefault}
      />
    </div>
  );
};

export default OfferCard;
