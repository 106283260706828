import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { uniqBy } from 'lodash';
import SearchBar from 'components/SearchBar/SearchBar';
import { loginTypes } from 'constants/authConstants';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  useGetAllOrganizationsQuery,
  useGetArchivedSupportTeamsQuery,
  useGetAssigneeArchivedToIssuesQuery,
  useGetEmployersByBrokerIdQuery,
  useLazyGetOrganizationByIdQuery,
  useGetAllSimplifiedEmployerAndSupportTeamQuery,
} from 'modules/issueslog/slices/issuesLogSlice';
import { saveFilter } from 'modules/issueslog/slices/issuesLogFilterSlice';
import {
  convertToFlatNumberArray,
  createUniqueId,
  jsonParseStringArray,
} from 'modules/issueslog/utils/issueLogUtils';
import {
  ISSUE_DAYS_OPEN_DEFAULT_MAP,
  ISSUE_EMPLOYER_STATUS_DEFAULT_MAP,
  ISSUE_LAST_MODIFIED_DEFAULT_MAP,
  ISSUE_LOG_SAVE_FILTER_ID_TYPES,
  ISSUE_STATUS_BROKER_TECH_DEFAULT_MAP,
  ISSUE_STATUS_DASHBOARD_MAP,
  ISSUE_SUBTYPE_DEFAULT_MAP,
  ISSUES_LOG_FILTER_TYPES,
  PRIORITY_DEFAULT_MAP,
  VIEWABLE_DEFAULT_MAP,
  WATCHING_FILTER_DEFAULT_MAP,
  WATCHING_YES,
  SUPPORT_TEAM_VALUE_UNKNOWN,
  SUPPORT_TEAM_LABEL_UNKNOWN,
} from 'modules/issueslog/constants/IssueLogFilterConstants';
import {
  ORGANIZATION_ID_KEY,
  SUPPORT_TEAM_KEY,
} from 'modules/issueslog/constants/IssuesLogConstants';
import ErAdminFilters from './ErAdminFilters/ErAdminFilters';
import BrTechAdminFilters from './BrTechAdminFilters/BrOrTechAdminFilters';

import styles from './filterSection.module.less';

type FilterSectionProps = {
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  filterRefs: any;
  defaultIssuesRef?: any;
  setSearchValue: (value: string) => void;
};

const FilterSection = ({
  handleSearch,
  searchValue,
  filterRefs,
  defaultIssuesRef,
  setSearchValue,
}: FilterSectionProps) => {
  const params = useParams();
  const location: any = useLocation();
  const dispatch = useAppDispatch();

  const appBootInfo: any = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );

  const isCameFromDashboard: boolean =
    location.state?.isCameFromDashboard || false;

  const searchParams = new URLSearchParams(window.location.search);
  const isCameFromSupportTeamDelete: boolean =
    searchParams.has(SUPPORT_TEAM_KEY) || false;

  const filterState = useAppSelector((state) => state.filters);
  const { showMoreFilters } = filterState;

  // login type constants
  const isNotErAdmin: boolean = appBootInfo?.type !== loginTypes.erAdmin;
  const isEmployerView = !!params?.employerId;
  const isPlatformAdmin: boolean = appBootInfo?.type === loginTypes.platform;
  const isBrokerAdmin: boolean = appBootInfo?.type === loginTypes.bokerAdmin;

  const saveFiltersID = createUniqueId(
    params,
    isEmployerView,
    appBootInfo?.type
  );
  const savedFilterState = useAppSelector(
    (state) => state?.filters[saveFiltersID]
  );

  const { data: brokerEmployers, refetch: refetchAllEmployersByBroker } =
    useGetEmployersByBrokerIdQuery(
      {
        brokerId: String(params.brokerId),
        individualId: null,
      },
      { skip: isEmployerView }
    );

  const { data: brokerEmployersTeam, refetch: refetchMyEmployers } =
    useGetEmployersByBrokerIdQuery(
      {
        brokerId: String(params.brokerId),
        individualId: appBootInfo.individualId,
      },
      { skip: isEmployerView }
    );

  const { data: allEmployers } = useGetAllSimplifiedEmployerAndSupportTeamQuery(
    {},
    { skip: !isPlatformAdmin }
  );

  const [fetchOrganizationData, { data: supportTeams }] =
    useLazyGetOrganizationByIdQuery();

  const { data: allOrganizations } = useGetAllOrganizationsQuery(
    {},
    { skip: !isPlatformAdmin }
  );

  const { data: allArchivedAndNotBRAdmins } =
    useGetAssigneeArchivedToIssuesQuery(
      {
        brokerID: String(params.brokerId),
      },
      { skip: !isNotErAdmin }
    );

  const {
    data: allArchivedSupportTeams,
    refetch: refetchArchivedSupportTeams,
  } = useGetArchivedSupportTeamsQuery({
    brokerID: isBrokerAdmin ? String(params.brokerId) : null,
  });

  useEffect(() => {
    if (defaultIssuesRef.current) {
      refetchAllEmployersByBroker();
      refetchMyEmployers();
      refetchArchivedSupportTeams();
      if (params.brokerId) {
        fetchOrganizationData({
          brokerId: String(params.brokerId),
        });
      }
    }

    // eslint-disable-next-line
  }, [
    refetchAllEmployersByBroker,
    refetchMyEmployers,
    refetchArchivedSupportTeams,
    defaultIssuesRef.current,
  ]);

  const getEmployerOptions = () => {
    const unknownOption = { value: null, label: '- Unknown -' };
    if (isPlatformAdmin && !isEmployerView) {
      const optionArray: any[] =
        allEmployers?.map(({ name, id }: { name: string; id: string }) => ({
          value: id,
          label: name,
        })) || [];
      return [unknownOption, ...optionArray];
    } else {
      const optionArray: any[] =
        brokerEmployers?.map(({ name, id }: { name: string; id: string }) => ({
          value: id,
          label: name,
        })) || [];
      return [unknownOption, ...optionArray];
    }
  };

  const getEmployerTeamOption = () => {
    const optionArray: any[] =
      brokerEmployersTeam?.map((EmpTeamMember: any) => ({
        value: EmpTeamMember?.id,
        label: EmpTeamMember?.name,
      })) || [];

    return optionArray;
  };

  const getSupportTeamOption = () => {
    if (isPlatformAdmin && !isEmployerView) {
      const optionArray: any[] = [];
      allOrganizations?.map((organization: any) =>
        organization?.issuesLogSupportEmails?.map((supportTeam: any) => {
          optionArray?.push({
            value: supportTeam?.name,
            label: supportTeam?.name,
          });
        })
      ) || [];

      const uniqArray = uniqBy(optionArray, 'value')?.map(
        (item: any, idx: number) => ({
          label: item?.label,
          value: idx,
        })
      );
      const unknownOption = {
        value: SUPPORT_TEAM_VALUE_UNKNOWN,
        label: SUPPORT_TEAM_LABEL_UNKNOWN,
      };
      return [unknownOption, ...uniqArray];
    } else {
      const optionArray: any[] =
        supportTeams?.issuesLogSupportEmails?.map(
          (supportTeam: any, idx: number) => ({
            value: idx,
            label: supportTeam?.name,
          })
        ) || [];
      const unknownOption = {
        value: SUPPORT_TEAM_VALUE_UNKNOWN,
        label: SUPPORT_TEAM_LABEL_UNKNOWN,
      };
      return uniqBy([unknownOption, ...optionArray], 'label');
    }
  };
  const getAllBrokerages = () => {
    const optionArray: any[] =
      allOrganizations?.map((organization: any) => ({
        value: organization?.id,
        label: organization?.name,
      })) || [];
    return optionArray;
  };

  const getAssigneeOptions = () => {
    const unknownOption = { label: '- Unknown -', value: null };
    const optionArray: any[] =
      allArchivedAndNotBRAdmins
        ?.filter((item: any) => item?.active === true)
        ?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
        })) || [];
    return [unknownOption, ...optionArray];
  };

  const getAssigneeArchivedOptions = () => {
    const optionArray: any[] =
      allArchivedAndNotBRAdmins
        ?.filter((item: any) => item?.active === false)
        ?.map((item: any) => ({ label: item?.name, value: item?.id })) || [];

    return optionArray;
  };

  const getSupportArchivedOptions = () => {
    const optionArray: any[] =
      allArchivedSupportTeams
        ?.filter((option: string) => option !== 'Unknown')
        ?.map((item: any) => ({
          label: item,
          value: item,
        })) || [];
    return optionArray;
  };

  // This done to get label value array to pass when default option
  const getSupportTeamValueArray = () => {
    // merge support team options with archived once, set archived with the initial fetch
    const supportOptions: any[] = getSupportTeamOption()?.map(
      ({ label }) => label
    );
    const archivedSupportTeams = allArchivedSupportTeams || [];
    const addOptionValue = [...supportOptions, ...archivedSupportTeams];
    const index = addOptionValue?.indexOf(SUPPORT_TEAM_LABEL_UNKNOWN);
    if (index !== -1) {
      addOptionValue[index] = SUPPORT_TEAM_VALUE_UNKNOWN;
    }

    return addOptionValue;
  };

  const getSetDefaultFilters = (isDefault: boolean) => {
    let filters: { name: string; values: any[] }[] = [];
    switch (saveFiltersID) {
      case ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_ACCOUNT:
      case ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER: {
        filters = [
          { name: ISSUES_LOG_FILTER_TYPES.ISSUE_TYPE, values: ['ACCOUNT'] },
          {
            name: ISSUES_LOG_FILTER_TYPES.STATUS,
            values: convertToFlatNumberArray(ISSUE_EMPLOYER_STATUS_DEFAULT_MAP),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.PRIORITY,
            values: PRIORITY_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.SUB_TYPE,
            values: ISSUE_SUBTYPE_DEFAULT_MAP,
          },
        ];
        break;
      }
      case ISSUE_LOG_SAVE_FILTER_ID_TYPES.EMPLOYER_SUPPORT: {
        filters = [
          { name: ISSUES_LOG_FILTER_TYPES.ISSUE_TYPE, values: ['SUPPORT'] },
          {
            name: ISSUES_LOG_FILTER_TYPES.WATCHING,
            values: WATCHING_FILTER_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.SUPPORT_TEAM,
            values: isDefault ? ['all'] : getSupportTeamValueArray(),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.ASSIGNED_TO,
            values: isDefault
              ? ['all']
              : [...getAssigneeOptions(), ...getAssigneeArchivedOptions()]?.map(
                  ({ value }) => value
                ),
          },
        ];
        break;
      }
      case ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_ACCOUNT: {
        filters = [
          { name: ISSUES_LOG_FILTER_TYPES.ISSUE_TYPE, values: ['ACCOUNT'] },
          {
            name: ISSUES_LOG_FILTER_TYPES.WATCHING,
            values: isCameFromDashboard
              ? [WATCHING_YES]
              : WATCHING_FILTER_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.EMPLOYER,
            values: isDefault
              ? ['all']
              : getEmployerOptions()?.map(({ value }) => value),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.STATUS,
            values: isCameFromDashboard
              ? ISSUE_STATUS_DASHBOARD_MAP
              : ISSUE_STATUS_BROKER_TECH_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.ASSIGNED_TO,
            values: isDefault
              ? ['all']
              : [...getAssigneeOptions(), ...getAssigneeArchivedOptions()]?.map(
                  ({ value }) => value
                ),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.PRIORITY,
            values: PRIORITY_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.SUB_TYPE,
            values: ISSUE_SUBTYPE_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.DAYS_OPEN,
            values: jsonParseStringArray(ISSUE_DAYS_OPEN_DEFAULT_MAP),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.LAST_MODIFIED,
            values: jsonParseStringArray(ISSUE_LAST_MODIFIED_DEFAULT_MAP),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.VIEWABLE_BY_EMPLOYER,
            values: VIEWABLE_DEFAULT_MAP,
          },
        ];
        break;
      }
      case ISSUE_LOG_SAVE_FILTER_ID_TYPES.BR_SUPPORT: {
        filters = [
          { name: ISSUES_LOG_FILTER_TYPES.ISSUE_TYPE, values: ['SUPPORT'] },
          {
            name: ISSUES_LOG_FILTER_TYPES.WATCHING,
            values: isCameFromDashboard
              ? [WATCHING_YES]
              : WATCHING_FILTER_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.SUPPORT_TEAM,
            values: isCameFromSupportTeamDelete
              ? [searchParams.get(SUPPORT_TEAM_KEY)]
              : isDefault
              ? ['all']
              : getSupportTeamValueArray(),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.EMPLOYER,
            values: isDefault
              ? ['all']
              : getEmployerOptions()?.map(({ value }) => value),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.ASSIGNED_TO,
            values: isDefault
              ? ['all']
              : [...getAssigneeOptions(), ...getAssigneeArchivedOptions()]?.map(
                  ({ value }) => value
                ),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.STATUS,
            values: isCameFromDashboard
              ? ISSUE_STATUS_DASHBOARD_MAP
              : ISSUE_STATUS_BROKER_TECH_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.PRIORITY,
            values: PRIORITY_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.SUB_TYPE,
            values: ISSUE_SUBTYPE_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.DAYS_OPEN,
            values: jsonParseStringArray(ISSUE_DAYS_OPEN_DEFAULT_MAP),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.LAST_MODIFIED,
            values: jsonParseStringArray(ISSUE_LAST_MODIFIED_DEFAULT_MAP),
          },
        ];
        break;
      }
      case ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_ACCOUNT: {
        filters = [
          { name: ISSUES_LOG_FILTER_TYPES.ISSUE_TYPE, values: ['ACCOUNT'] },
          {
            name: ISSUES_LOG_FILTER_TYPES.BROKERAGE,
            values: isCameFromDashboard
              ? [location?.state.brokerId]
              : isDefault
              ? ['all']
              : getAllBrokerages()?.map(({ value }) => value),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.EMPLOYER,
            values: isDefault
              ? ['all']
              : getEmployerOptions()?.map(({ value }) => value),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.STATUS,
            values: isCameFromDashboard
              ? ISSUE_STATUS_DASHBOARD_MAP
              : ISSUE_STATUS_BROKER_TECH_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.ASSIGNED_TO,
            values: isDefault
              ? ['all']
              : [...getAssigneeOptions(), ...getAssigneeArchivedOptions()]?.map(
                  ({ value }) => value
                ),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.PRIORITY,
            values: PRIORITY_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.SUB_TYPE,
            values: ISSUE_SUBTYPE_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.DAYS_OPEN,
            values: jsonParseStringArray(ISSUE_DAYS_OPEN_DEFAULT_MAP),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.LAST_MODIFIED,
            values: jsonParseStringArray(ISSUE_LAST_MODIFIED_DEFAULT_MAP),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.VIEWABLE_BY_EMPLOYER,
            values: VIEWABLE_DEFAULT_MAP,
          },
        ];
        break;
      }
      case ISSUE_LOG_SAVE_FILTER_ID_TYPES.TEC_SUPPORT: {
        filters = [
          { name: ISSUES_LOG_FILTER_TYPES.ISSUE_TYPE, values: ['SUPPORT'] },
          {
            name: ISSUES_LOG_FILTER_TYPES.BROKERAGE,
            values: isCameFromDashboard
              ? [location?.state.brokerId]
              : isCameFromSupportTeamDelete
              ? [searchParams.get(ORGANIZATION_ID_KEY)]
              : isDefault
              ? ['all']
              : getAllBrokerages()?.map(({ value }) => value),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.EMPLOYER,
            values: isDefault
              ? ['all']
              : getEmployerOptions()?.map(({ value }) => value),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.STATUS,
            values: isCameFromDashboard
              ? ISSUE_STATUS_DASHBOARD_MAP
              : ISSUE_STATUS_BROKER_TECH_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.ASSIGNED_TO,
            values: isDefault
              ? ['all']
              : [...getAssigneeOptions(), ...getAssigneeArchivedOptions()]?.map(
                  ({ value }) => value
                ),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.SUPPORT_TEAM,
            values: isCameFromSupportTeamDelete
              ? [searchParams.get(SUPPORT_TEAM_KEY)]
              : isDefault
              ? ['all']
              : getSupportTeamValueArray(),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.PRIORITY,
            values: PRIORITY_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.SUB_TYPE,
            values: ISSUE_SUBTYPE_DEFAULT_MAP,
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.DAYS_OPEN,
            values: jsonParseStringArray(ISSUE_DAYS_OPEN_DEFAULT_MAP),
          },
          {
            name: ISSUES_LOG_FILTER_TYPES.LAST_MODIFIED,
            values: jsonParseStringArray(ISSUE_LAST_MODIFIED_DEFAULT_MAP),
          },
        ];
        break;
      }
    }
    return filters;
  };

  useEffect(() => {
    if (savedFilterState.clickedSaved) {
      defaultIssuesRef.current = false;
    }
    if (defaultIssuesRef.current || !savedFilterState?.clickedSaved) {
      setSearchValue('');
      dispatch(
        saveFilter({
          clickedSaved: false,
          filters: {
            searchKey: '',
            filters: getSetDefaultFilters(true),
          },
          filterSaveType: saveFiltersID,
        })
      );
    }
    // eslint-disable-next-line
  }, [savedFilterState?.clickedSaved, params.type]);

  const getSearchSpanSize = () => {
    if (!isNotErAdmin) return 5;
    if ((isBrokerAdmin || isPlatformAdmin) && isEmployerView) return 5;
    return 4;
  };
  return (
    <div className={styles.filterWrapper}>
      <Row gutter={16}>
        <Col xs={getSearchSpanSize()}>
          <SearchBar
            placeholder="Search Items"
            className={styles.searchBar}
            value={searchValue}
            onChange={handleSearch}
            isIssueSearch={true}
          />
        </Col>
        {!isNotErAdmin ? (
          <ErAdminFilters filterRefs={filterRefs} />
        ) : (
          <BrTechAdminFilters
            filterRefs={filterRefs}
            showMoreFilters={showMoreFilters}
            employerOptions={getEmployerOptions() || []}
            employerTeamMembersOptions={getEmployerTeamOption() || []}
            supportTeamOptions={getSupportTeamOption() || []}
            brokeragesOptions={getAllBrokerages() || []}
            assigneeOptions={getAssigneeOptions() || []}
            assigneeArchivedOption={getAssigneeArchivedOptions() || []}
            supportArchivedOption={getSupportArchivedOptions() || []}
            supportTeamAllOptionsToReset={getSupportTeamValueArray() || []}
          />
        )}
      </Row>
    </div>
  );
};

export default FilterSection;
