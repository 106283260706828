import { FC, useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import InputForm from 'components/InputForm/InputForm';
import AuthContainer from 'containers/AuthContainer/AuthContainer';
import AuthHeading from 'modules/auth/components/AuthHeading/AuthHeading';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { LOGIN_PATH } from 'modules/auth/routes';
import {
  resetValidationState,
  validateEmailAndCode,
} from 'modules/auth/slices/codeRegistrationSlice';
import { REGISTER_WITH_CODE_ERROR_MESSAGE } from 'modules/auth/constants/authConstants';
import styles from './registerWithCodeAndEmail.module.less';

const RegisterWithCodAndEmail: FC = () => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    type: 'info',
    message: '',
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const emailCodeValidationState = useAppSelector(
    (state) => state.auth.registerWithCodeAndEmailValidation
  );

  useEffect(() => {
    if (emailCodeValidationState.error !== null) {
      setIsAlertVisible(true);
      setAlertInfo({
        type: 'error',
        message: REGISTER_WITH_CODE_ERROR_MESSAGE,
      });
    }
    return () => {
      dispatch(resetValidationState());
    };
  }, [emailCodeValidationState.error, dispatch]);

  const navigateToRegister = (token: string) => {
    navigate(`/register?token=${token}`);
  };

  const handleRequest = (formValues: any) => {
    const { email, code } = formValues;

    if (!email || !code) {
      setIsAlertVisible(true);
      setAlertInfo({
        type: 'error',
        message: REGISTER_WITH_CODE_ERROR_MESSAGE,
      });
    } else {
      setIsAlertVisible(false);
      dispatch(
        validateEmailAndCode(code.trim(), email.trim(), navigateToRegister)
      );
    }
  };

  return (
    <AuthContainer>
      <div className={styles.container}>
        {isAlertVisible && (
          <AlertMessage
            type={alertInfo.type}
            message={alertInfo.message}
            closeAlert={() => setIsAlertVisible(false)}
            wrapperClassName="authAlertWrapper"
          />
        )}
        <AuthHeading />
        <div className={styles.message}>
          Use the invite code received in your email for registration. If you
          <br /> don’t see it, please check your spam or security tool filters.
        </div>
        <InputForm layout="vertical" onFinish={handleRequest}>
          <Form.Item
            name="email"
            label="Email"
            validateStatus={
              isAlertVisible && alertInfo.type === 'error'
                ? 'error'
                : 'validating'
            }
          >
            <Input name="email" autoFocus />
          </Form.Item>
          <Form.Item
            name="code"
            label="Invite Code"
            validateStatus={
              isAlertVisible && alertInfo.type === 'error'
                ? 'error'
                : 'validating'
            }
          >
            <Input name="code" />
          </Form.Item>
          <SubmitButton
            type="primary"
            htmlType="submit"
            loading={emailCodeValidationState.inProgress}
            className={styles.submitButton}
          >
            Register
          </SubmitButton>
          <CancelButton>
            <div className={styles.cancelButton}>
              <Link
                to={LOGIN_PATH}
                className={styles.cancelButtonText}
                state={{ previousPath: location.pathname }}
              >
                <a> Back to Log In</a>
              </Link>
            </div>
          </CancelButton>
        </InputForm>
      </div>
    </AuthContainer>
  );
};

export default RegisterWithCodAndEmail;
