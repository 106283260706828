import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Form, Input, Modal } from 'antd';
import { isEmpty } from 'lodash';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { getIssuesLogConfigurationsByUniqueName } from 'modules/tools/services/IssuesLogService';
import { useProvisionIssuesLogMutation } from 'modules/tools/slices/issuesLogProvisioningSlice';
import { usePrevious } from 'hooks/usePrevious';
import AlertMessage from 'components/Alert/AlertMessage';

import alertWarning from 'assets/images/alert-warning.svg';

import styles from './provisionSetupModal.module.less';

type ProvisionSetupModalProps = {
  visible: boolean;
  onCancel: () => void;
  brokerName: string;
  onSave: () => void;
  brokerId: string;
};

const ProvisionSetupModal: FC<ProvisionSetupModalProps> = ({
  visible,
  onCancel,
  brokerName,
  onSave,
  brokerId,
}) => {
  const [form] = Form.useForm();
  const [provisionIssuesLog, { isSuccess, isLoading, isError }] =
    useProvisionIssuesLogMutation();

  const [accountEmail, setAccountEmail] = useState<string>('');
  const [supportEmail, setSupportEmail] = useState<string>('');
  const [uniqueName, setUniqueName] = useState<string>('');
  const [savingError, setSavingError] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      const cleanedName = brokerName?.replace(/[^A-Z0-9]/gi, '')?.toLowerCase();
      form.setFieldsValue({
        uniqueName: cleanedName,
      });
      setUniqueName(cleanedName);
      updateEmails(cleanedName);
    }
  }, [brokerName, form, visible]);

  const isLoadingPrevious = usePrevious(isLoading);

  useEffect(() => {
    if (isLoadingPrevious && !isLoading && isSuccess) {
      onSave();
    }
  }, [isLoadingPrevious, isSuccess, isLoading, onSave]);

  useEffect(() => {
    if (isLoadingPrevious && !isLoading && isError) {
      setSavingError(true);
    }
  }, [isLoadingPrevious, isError, isLoading, onSave]);

  const changedUniqueName = (event: ChangeEvent<HTMLInputElement>) => {
    setUniqueName(event?.target?.value);
  };

  const save = () => {
    form.validateFields().then(() => {
      provisionIssuesLog({
        organizationId: brokerId,
        uniqueName: uniqueName.toLowerCase(),
      });
    });
  };

  const duplicateValidator = async () => {
    try {
      // Configurations exist
      await getIssuesLogConfigurationsByUniqueName(uniqueName);
      return Promise.reject(new Error());
    } catch (error) {
      return Promise.resolve();
    }
  };

  const updateEmails = (value: string) => {
    setAccountEmail(`benefits@${value}.planyear.com`);
    setSupportEmail(`support@${value}.planyear.com`);
  };

  return (
    <Modal
      centered
      visible={visible}
      width={600}
      closeIcon={<></>}
      footer={false}
      className={styles.provisionSetupModal}
      destroyOnClose={true}
    >
      <div className={styles.provisionSetupModalWrapper}>
        <div className={styles.title}>Setup Inboxes</div>
        <div className={styles.subTitle}>{brokerName}</div>

        {savingError && (
          <div className={styles.errorMessageWrapper}>
            <AlertMessage
              className={styles.errorMessage}
              type="error"
              message="Failed to save email address. Please try again."
              closeAlert={() => {
                setSavingError(false);
              }}
            />
          </div>
        )}
        <div className={styles.description}>
          To enable account log for this broker, please confirm the provisioned
          email addresses below:
        </div>
        <div className={styles.inputWrapper}>
          <Form form={form} layout="vertical">
            <Form.Item
              className={styles.provisionField}
              label="Provision an Email Address *"
              name="uniqueName"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                  whitespace: true,
                },
                {
                  validator: duplicateValidator,
                  message:
                    'This name is already being used. Please choose another.',
                  validateTrigger: 'onSubmit',
                },
                {
                  validator: () => {
                    if (uniqueName.indexOf(' ') < 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error());
                    }
                  },
                  message: 'Invalid email format',
                  validateTrigger: 'onSubmit',
                },
                {
                  validator: () => {
                    if (
                      uniqueName.match('^[a-zA-Z0-9]+$') ||
                      isEmpty(uniqueName) ||
                      uniqueName.indexOf(' ') >= 0
                    ) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error());
                    }
                  },
                  message: 'Special characters are not supported',
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Input
                value={uniqueName}
                onChange={changedUniqueName}
                onBlur={() => {
                  updateEmails(uniqueName);
                }}
                maxLength={60}
              />
            </Form.Item>
          </Form>
          <div className={styles.basedDomain}>.planyear.com</div>
        </div>

        <div className={styles.emailWrapper}>
          <div className={styles.addressWrapper}>
            <div className={styles.label}></div>Account email:
            <div className={styles.email}>{accountEmail}</div>
          </div>
          <div className={styles.addressWrapper}>
            <div className={styles.label}></div>Support email:
            <div className={styles.email}>{supportEmail}</div>
          </div>
        </div>

        <div className={styles.warningWrapper}>
          <img src={alertWarning} alt="" />
          <span>
            Provisioned email address names cannot be edited after they are
            created.
          </span>
        </div>

        <div className={styles.footer}>
          <PageActionButton
            className={styles.saveBtn}
            type="primary"
            onClick={save}
            loading={isLoading}
          >
            Save
          </PageActionButton>
          <div onClick={onCancel} className={styles.cancelLink}>
            Cancel
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProvisionSetupModal;
