import { useState, useEffect, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { CloseOutlined } from '@ant-design/icons';
import { Form, Select, Spin, Switch, notification } from 'antd';
import { ReactComponent as DownloadIcon } from 'assets/images/icon-download-black.svg';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import InputForm from 'components/InputForm/InputForm';
import ExportOfferSelectSubModal from 'modules/renewals/components/ExportOfferSelectSubModal/ExportOfferSelectSubModal';
import {
  exportProposalSummary,
  useLazyGetProposalNamesByEmployerIdQuery,
  useLazyGetProposalSummaryExportFilterOptionsQuery,
} from 'modules/renewals/slices/proposalSlice';
import { useAppSelector } from 'hooks/redux';
import {
  exportOfferSummary,
  useLazyGetOffersExportFiltersQuery,
} from 'modules/renewals/slices/renewalsSlice';
import SearchBar from 'components/SearchBar/SearchBar';
import { removeSpecialCharactersFromString } from 'util/commonUtil';
import { BenefitCategory } from 'constants/commonConstants';

import ExportStylingModal from 'components/ExportStylingModal/ExportStylingModal';
import { RGBAModel, fontObjects } from 'util/exportStylingUtil';
import styles from './exportOfferProposalModal.module.less';

const { Option } = Select;
const MEDICAL = 'MEDICAL';
const DENTAL = 'DENTAL';
const VISION = 'VISION';
const LIFE = 'LIFE';
const STD = 'STD';
const LTD = 'LTD';

type Props = {
  disableProposalExportPopup: () => void;
  //  Offer Level Plan Year ID, used to get the offer summary export filter options
  planYearId: string | null | undefined;
  // Offer Level Upcoming Id, Used to get te upcoming offer leve
  offerUpcomingId?: string | null | undefined;
  isOfferExport: boolean;
  defaultDiscountsIncluded: boolean;
  // Proposal Level Plan Year ID, used to get the proposal summary export filter options
  proposalPlanYearId?: string | null | undefined;
};

const ExportOfferProposalModal = (props: Props) => {
  const { employerId } = useParams();
  const employerObj: any = useAppSelector((state) => state?.layout?.employer);
  const {
    disableProposalExportPopup,
    planYearId,
    isOfferExport,
    defaultDiscountsIncluded,
    proposalPlanYearId,
    offerUpcomingId,
  } = props;

  const [
    getProposalNamesByEmployerId,
    {
      data: proposalNames,
      isSuccess: isGetProposalNamesByEmployerIdSuccess,
      isLoading: isLoadingProposalNamesByEmployerId,
    },
  ] = useLazyGetProposalNamesByEmployerIdQuery();

  const [
    getProposalSummaryExportFilterOptions,
    { isFetching: isProposalOfferNamesFetching, data: proposalOfferNamesData },
  ] = useLazyGetProposalSummaryExportFilterOptionsQuery();

  const [
    getOffersExportFilters,
    { isFetching: isOfferNamesDataFetching, data: offerNamesData },
  ] = useLazyGetOffersExportFiltersQuery();

  const [displaySelectProposal, setDisplaySelectProposal] =
    useState<boolean>(true);
  const [displaySelectOffers, setDisplaySelectOffers] =
    useState<boolean>(isOfferExport);
  const [proposalIdToExport, setProposalIdToExport] = useState<string | null>(
    null
  );
  const [selectedMedicalOfferIds, setSelectedMedicalOfferIds] = useState<
    string[]
  >([]);
  const [selectedDentalOfferIds, setSelectedDentalOfferIds] = useState<
    string[]
  >([]);
  const [selectedVisionOfferIds, setSelectedVisionOfferIds] = useState<
    string[]
  >([]);
  const [selectedLifeOfferIds, setSelectedLifeOfferIds] = useState<string[]>(
    []
  );
  const [offerSearchText, setOfferSearchText] = useState<string>('');
  const [includeCreditsAndDiscounts, setIncludeCreditsAndDiscounts] =
    useState<boolean>(defaultDiscountsIncluded);

  const [selectedFont, setSelectedFont] = useState(fontObjects[0].fontName);
  const [tableHeaderColor, setTableHeaderColor] = useState<RGBAModel>({
    r: '168',
    g: '208',
    b: '141',
    a: '1',
  });
  const [tableHeaderFontColor, setTableHeaderFontColor] = useState<RGBAModel>({
    r: '0',
    g: '0',
    b: '0',
    a: '1',
  });

  useEffect(() => {
    if (isOfferExport) {
      getOffersExportFilters({
        employerId: employerId ?? '',
        upcomingPlanYearId: offerUpcomingId ?? undefined,
        planYearId: planYearId ?? undefined,
      });
    } else {
      getProposalNamesByEmployerId({
        employerId: employerId ?? '',
        upcomingPlanYearId: planYearId ?? undefined,
        planYearId: proposalPlanYearId ?? undefined,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportProposalSummaryFile = () => {
    if (!proposalIdToExport) {
      return;
    }
    const proposalId: string = proposalIdToExport;

    if (employerId && proposalId) {
      notification['info']({
        closeIcon: <></>,
        top: 40,
        message: 'Download Started',
        description:
          'Please wait while we export the summary excel. This process may take a few minutes',
        icon: <DownloadIcon className={styles.downloadIcon} />,
        duration: 3,
      });
      exportProposalSummary(
        employerId,
        planYearId,
        proposalId,
        proposalOfferNamesData.categories,
        employerObj.name,
        [
          ...proposalOfferNamesData.offersInProposal,
          ...selectedMedicalOfferIds,
          ...selectedDentalOfferIds,
          ...selectedVisionOfferIds,
          ...selectedLifeOfferIds,
        ],
        includeCreditsAndDiscounts,
        selectedFont,
        tableHeaderColor,
        tableHeaderFontColor
      ).catch((e: Error) =>
        notification['error']({
          closeIcon: <></>,
          top: 40,
          message: 'Download Failed',
          description: e.message,
          duration: 3,
          icon: <CloseOutlined className={styles.notificationErrorIcon} />,
        })
      );
    }
  };

  const exportOffersFile = () => {
    const selectedCategories: any[] = [];
    if (!isEmpty(selectedMedicalOfferIds)) {
      selectedCategories.push(BenefitCategory.MEDICAL.value);
    }
    if (!isEmpty(selectedDentalOfferIds)) {
      selectedCategories.push(BenefitCategory.DENTAL.value);
    }
    if (!isEmpty(selectedVisionOfferIds)) {
      selectedCategories.push(BenefitCategory.VISION.value);
    }
    if (!isEmpty(selectedLifeOfferIds)) {
      selectedCategories.push(BenefitCategory.LIFE.value);
    }
    if (
      (offerNamesData?.offers?.LIFE ?? []).some(
        (offer: { category: string }) => offer.category === 'LTD'
      ) &&
      !isEmpty(selectedLifeOfferIds)
    ) {
      selectedCategories.push('LTD');
    }
    if (
      (offerNamesData?.offers?.LIFE ?? []).some(
        (offer: { category: string }) => offer.category === 'STD'
      ) &&
      !isEmpty(selectedLifeOfferIds)
    ) {
      selectedCategories.push('STD');
    }

    if (employerId) {
      notification['info']({
        closeIcon: <></>,
        top: 40,
        message: 'Download Started',
        description:
          'Please wait while we export the summary excel. This process may take a few minutes',
        icon: <DownloadIcon className={styles.downloadIcon} />,
        duration: 3,
      });
      exportOfferSummary(
        employerId,
        planYearId,
        selectedCategories,
        [
          ...offerNamesData.offersInProposal,
          ...selectedMedicalOfferIds,
          ...selectedDentalOfferIds,
          ...selectedVisionOfferIds,
          ...selectedLifeOfferIds,
        ],
        removeSpecialCharactersFromString(employerObj.name),
        selectedFont,
        tableHeaderColor,
        tableHeaderFontColor
      ).catch((e: Error) =>
        notification['error']({
          closeIcon: <></>,
          top: 40,
          message: 'Download Failed',
          className: styles.notification,
          description: e.message,
          duration: 3,
          icon: <CloseOutlined className={styles.notificationErrorIcon} />,
        })
      );
    }
  };

  const onProposalToExportSelect = (proposalId: string) => {
    setProposalIdToExport(proposalId);
    getProposalSummaryExportFilterOptions({
      proposalId: proposalId,
      upcomingPlanYearId: planYearId ?? undefined,
    });
  };

  const handleSearchOffers = (e: ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = e.target;
    const searchText = value.toLowerCase().trim();
    setOfferSearchText(searchText);
  };

  const hasBenefitCategory = (type: string): boolean => {
    if (!isOfferExport) {
      if (!proposalOfferNamesData) {
        return false;
      }

      return proposalOfferNamesData.categories.includes(type);
    } else {
      if (!offerNamesData) {
        return false;
      }

      return offerNamesData.categories.includes(type);
    }
  };

  const isNoOffersSelected = (): boolean => {
    return [
      selectedMedicalOfferIds.length,
      selectedDentalOfferIds.length,
      selectedVisionOfferIds.length,
      selectedLifeOfferIds.length,
    ].every((length) => length === 0);
  };

  const isAdditionalOffersEmpty = (): boolean => {
    if (
      isOfferExport ||
      !proposalOfferNamesData ||
      !proposalOfferNamesData.offers
    ) {
      return false;
    } else {
      return Object.keys(proposalOfferNamesData.offers).length === 0;
    }
  };

  return (
    <div>
      <ConfirmationDialog
        confirmText={'Next'}
        visible={displaySelectProposal && !isOfferExport}
        onConfirm={() => {
          setDisplaySelectProposal(false);
          setDisplaySelectOffers(true);
        }}
        disableConfirmButton={!proposalIdToExport}
        confirmLoading={isProposalOfferNamesFetching}
        cancelText={'Cancel'}
        title={'Export Proposals'}
        isCancelLink={true}
        closeModal={disableProposalExportPopup}
        isCloseOnly={true}
        destroyOnClose={true}
      >
        {isLoadingProposalNamesByEmployerId ? (
          <Spin />
        ) : (
          <div>
            <p className={styles.exportText}>
              Select one proposal to export below. The total cost summary across
              all published proposals will also be included in this export.
            </p>
            {isGetProposalNamesByEmployerIdSuccess && (
              <div className={styles.exportFormWrapper}>
                <InputForm>
                  <Form.Item
                    label="Proposal:"
                    labelCol={{ span: 24 }}
                    name="proposalId"
                    rules={[
                      { required: false, message: 'Please select Proposal' },
                    ]}
                  >
                    <Select
                      showSearch
                      listHeight={125}
                      optionFilterProp="children"
                      dropdownClassName={styles.exportSelectDropdown}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onSelect={(value: string) => {
                        onProposalToExportSelect(value);
                      }}
                    >
                      {proposalNames
                        .filter((option: any) =>
                          [
                            'PUBLISHED',
                            'APPROVED',
                            'FINALISED',
                            'DRAFT',
                          ].includes(option.status)
                        )
                        .map((option: any) => (
                          <Option value={option.id} key={option.id}>
                            {option.name}
                            {option.status === 'DRAFT' && (
                              <span className={styles.draftTag}>DRAFT</span>
                            )}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </InputForm>
              </div>
            )}
          </div>
        )}
      </ConfirmationDialog>

      <ConfirmationDialog
        confirmText={isOfferExport ? 'Export' : 'Export Proposal'}
        visible={displaySelectOffers}
        onConfirm={() => {
          if (isOfferExport) {
            exportOffersFile();
          } else {
            exportProposalSummaryFile();
          }

          disableProposalExportPopup();
        }}
        cancelText={'Cancel'}
        title={isOfferExport ? 'Export Offers' : 'Export Additional Offers'}
        isCancelLink={true}
        closeModal={disableProposalExportPopup}
        isCloseOnly={true}
        destroyOnClose={true}
        width={600}
        confirmLoading={isOfferNamesDataFetching}
        disableConfirmButton={isOfferExport && isNoOffersSelected()}
        confirmBtnFullWidth={true}
        showPopover={isOfferExport && isNoOffersSelected()}
        popOverContent={
          'There is no offer to export, please choose the required options from the filter.'
        }
      >
        {isAdditionalOffersEmpty() ? (
          <p className={styles.emptyOffers}>
            There are no additional offers to display
          </p>
        ) : (
          <div>
            {!isOfferExport && (
              <p className={styles.exportText}>
                Do you wish to include any other offers in the export, in
                addition to what&apos;s selected in this Proposal?
              </p>
            )}

            <div className={styles.offerSearchBar}>
              <SearchBar
                placeholder={'Search'}
                onChange={handleSearchOffers}
                isLarge={false}
              />
            </div>
          </div>
        )}

        {isOfferNamesDataFetching && <Spin />}

        <div className={styles.checkBoxesWrapper}>
          {hasBenefitCategory(MEDICAL) && (
            <ExportOfferSelectSubModal
              categoryHeader={MEDICAL}
              offers={
                isOfferExport
                  ? offerNamesData.offers[MEDICAL]
                  : proposalOfferNamesData.offers[MEDICAL]
              }
              selectedOfferIds={selectedMedicalOfferIds}
              setSelectedOfferIds={setSelectedMedicalOfferIds}
              offerSearchText={offerSearchText}
            />
          )}
          {hasBenefitCategory(DENTAL) && (
            <ExportOfferSelectSubModal
              categoryHeader={DENTAL}
              offers={
                isOfferExport
                  ? offerNamesData.offers[DENTAL]
                  : proposalOfferNamesData.offers[DENTAL]
              }
              selectedOfferIds={selectedDentalOfferIds}
              setSelectedOfferIds={setSelectedDentalOfferIds}
              offerSearchText={offerSearchText}
            />
          )}
          {hasBenefitCategory(VISION) && (
            <ExportOfferSelectSubModal
              categoryHeader={VISION}
              offers={
                isOfferExport
                  ? offerNamesData.offers[VISION]
                  : proposalOfferNamesData.offers[VISION]
              }
              selectedOfferIds={selectedVisionOfferIds}
              setSelectedOfferIds={setSelectedVisionOfferIds}
              offerSearchText={offerSearchText}
            />
          )}
          {(hasBenefitCategory(LIFE) ||
            hasBenefitCategory(STD) ||
            hasBenefitCategory(LTD)) && (
            <ExportOfferSelectSubModal
              categoryHeader={'LIFE & DISABILITY'}
              offers={
                isOfferExport
                  ? offerNamesData.offers[LIFE]
                  : proposalOfferNamesData.offers[LIFE]
              }
              selectedOfferIds={selectedLifeOfferIds}
              setSelectedOfferIds={setSelectedLifeOfferIds}
              offerSearchText={offerSearchText}
            />
          )}
        </div>

        <ExportStylingModal
          tableHeaderColor={tableHeaderColor}
          setTableHeaderColor={setTableHeaderColor}
          tableHeaderFontColor={tableHeaderFontColor}
          setTableHeaderFontColor={setTableHeaderFontColor}
          selectedFont={selectedFont}
          setSelectedFont={setSelectedFont}
        />

        {!isOfferExport && (
          <div className={styles.switchWrapper}>
            <span>Include Costs & Credits in Export</span>
            <Switch
              defaultChecked={defaultDiscountsIncluded}
              onChange={(enabled) => {
                setIncludeCreditsAndDiscounts(enabled);
              }}
              className={styles.includeCreditsSwitch}
            />
          </div>
        )}
      </ConfirmationDialog>
    </div>
  );
};

export default ExportOfferProposalModal;
