import { FC, ReactNode } from 'react';
import { NativeButtonProps } from 'antd/lib/button/button';
import { Button as AntDButton } from 'antd';

import styles from './button.module.less';

interface ButtonProps extends NativeButtonProps {
  label: string | ReactNode;
}
const Button: FC<ButtonProps> = ({ label, ...rest }) => {
  return (
    <div className={styles.btnContainer}>
      <AntDButton {...rest}>{label}</AntDButton>
    </div>
  );
};

export default Button;
