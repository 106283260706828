import { DocumentExtractionSource } from './DocumentExtractionSource';
import { ReviewType } from './ReviewType';
import ProcessStatus from './SBCUploadStatus';

import UploaderType from './UploaderType';
/* eslint-disable no-unused-vars */
// Disabling ai warning due to avoid unused variable warning for AIRejectType inside of this file
export interface PostSBCReviewPayload {
  planUploderType: UploaderType;
  jobId: string;
}

export interface StatusType {
  status: ProcessStatus;
  type: ReviewType;
  benefitKind?: string | undefined;
  source: DocumentExtractionSource | undefined | null;
  sourcePlanId?: string | undefined | null;
  sourceOfferId?: string | undefined | null;
  sourceOfferName?: string | undefined | null;
  sourcePlanName?: string | undefined | null;
}

export interface LocationType {
  id: string;
  locationName: string;
  organizationId: string;
  key: string;
  enabled: boolean;
  lastUpdatedTs: string;
  lastUpdatedByLoginId: string;
  lastUpdatedByAdminName: string;
  isVisible?: boolean;
  rfpLocationCount: number;
  rfpEnabledBrokerAdminCount: number;
}

export interface LocationValueObject {
  locationName: string;
  enabled: boolean;
}

export interface BrokerType {
  id: string;
  brokerName: string;
  organizationId: string;
  key: string;
  enabled: boolean;
  lastUpdatedTs: string;
  lastUpdatedByLoginId: string;
  lastUpdatedByAdminName: string;
  allLocationsCount: number;
  enabledLocationsCount: number;
  locationVOS: LocationValueObject[];
}

export interface BrokerPopUpState {
  showLocationPopup: boolean;
  showDisablePopup: boolean;
}

export enum AIRejectType {
  PLANS_REJECT = 'PLANS_REJECT',
  QUOTE_REJECT = 'QUOTE_REJECT',
}

export interface AdditionalServices {
  service: string;
  value: string;
}

export enum EmailType {
  BROKER = 'BROKER',
  EMPLOYER = 'EMPLOYER',
}

export enum EmployerUsers {
  EMPLOYER_MEMBER = 'EMPLOYER_MEMBER',
  EMPLOYER_ADMIN = 'EMPLOYER_ADMIN',
}
