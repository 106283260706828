import { combineReducers } from '@reduxjs/toolkit';

import BrokerReducer from 'modules/brokers/slices/brokerBasicInfoSlice';
import BrokerAdminReducer from 'modules/brokers/slices/brokerAdminSlice';
import BrokerLocationReducer from 'modules/brokers/slices/brokerLocationSlice';
import BrokerDashBoardReducer from 'modules/brokers/slices/brokerDashboardSlice';

const brokerRootReducers = combineReducers({
  brokerBasicInfo: BrokerReducer,
  brokerAdmins: BrokerAdminReducer,
  brokerLocations: BrokerLocationReducer,
  brokerDashboard: BrokerDashBoardReducer,
});

export default brokerRootReducers;
