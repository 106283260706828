import React from 'react';
import { cloneDeep, isNull } from 'lodash';

import * as _ from 'lodash';
import AreaChart from 'components/Charts/AreaChart/AreaChart';

import { currencyFormatter, shortenCurrencyWithSuffix } from 'util/commonUtil';
import {
  COLOR_DARK_ORANGE,
  COLOR_MEDIUM_SEA_GREEN,
  COLOR_MEDIUM_LIGHT_ORANGE,
  COLOR_LIGHT_GREY,
  COLOR_WHITE,
  HIDDEN_MONTH_TEXT,
  COLOR_LOSS_RATIO_YELLOW,
  COLOR_LEGEND_LABEL_SHADE,
  COLOR_DUMMY_CHART_AREA,
  COLOR_DUMMY_CHART_AREA_PREMIUM,
} from 'modules/claims/constants/constants';

import styles from './lossRatioAreaChart.module.less';

const areaList = [
  {
    type: 'monotone',
    dataKey: 'totalCost',
    stroke: COLOR_MEDIUM_LIGHT_ORANGE,
    fill: COLOR_LOSS_RATIO_YELLOW,
    fillOpacity: 0.6,
    dot: { fill: COLOR_DARK_ORANGE },
    strokeWidth: 3.5,
    legendType: 'none',
  },
  {
    type: 'monotone',
    dataKey: 'totalPremium',
    stroke: COLOR_MEDIUM_SEA_GREEN,
    fill: COLOR_MEDIUM_SEA_GREEN,
    fillOpacity: 0.1,
    dot: { fill: COLOR_MEDIUM_SEA_GREEN },
    strokeWidth: 3.5,
    legendType: 'none',
  },
];
const lineList = [
  {
    type: 'monotone',
    dataKey: 'totalCost',
    stroke: COLOR_MEDIUM_LIGHT_ORANGE,
    fill: COLOR_LOSS_RATIO_YELLOW,
    dot: { fill: COLOR_DARK_ORANGE, r: 2 },
    strokeWidth: 3.5,
    legendType: 'circle',
  },
  {
    type: 'monotone',
    dataKey: 'totalPremium',
    stroke: COLOR_MEDIUM_SEA_GREEN,
    fill: COLOR_MEDIUM_SEA_GREEN,
    fillOpacity: 0.7,
    dot: { fill: COLOR_MEDIUM_SEA_GREEN, r: 2 },
    strokeWidth: 3.5,
    legendType: 'circle',
  },
];
const areaListDummyData = [
  {
    type: 'monotone',
    dataKey: 'totalCost',
    stroke: COLOR_MEDIUM_LIGHT_ORANGE,
    fill: COLOR_DUMMY_CHART_AREA,
    fillOpacity: 0.8,
    dot: { fill: COLOR_DARK_ORANGE, r: 2 },
    strokeWidth: 3.5,
    legendType: 'none',
  },
  {
    type: 'monotone',
    dataKey: 'totalPremium',
    stroke: COLOR_MEDIUM_SEA_GREEN,
    fill: COLOR_DUMMY_CHART_AREA_PREMIUM,
    fillOpacity: 0.6,
    dot: { fill: COLOR_LEGEND_LABEL_SHADE, r: 2 },
    strokeWidth: 3.5,
    legendType: 'none',
  },
];
const lineListDummyData = [
  {
    type: 'monotone',
    dataKey: 'totalCost',
    stroke: COLOR_LEGEND_LABEL_SHADE,
    fill: COLOR_LEGEND_LABEL_SHADE,
    dot: { fill: COLOR_DARK_ORANGE, r: 2 },
    strokeWidth: 3.5,
    legendType: 'circle',
  },
  {
    type: 'monotone',
    dataKey: 'totalPremium',
    stroke: COLOR_LEGEND_LABEL_SHADE,
    fill: COLOR_LEGEND_LABEL_SHADE,
    fillOpacity: 0.7,
    dot: { fill: COLOR_LEGEND_LABEL_SHADE, r: 2 },
    strokeWidth: 3.5,
    legendType: 'circle',
  },
];

const xAxisProps = {
  hide: false,
  axisLine: false,
  minTickGap: 0,
  tickMargin: 10,
  padding: { left: 0, right: 0 },
  label: '',
  tick: ({ payload, x, y }: any) => {
    const { value } = payload;
    return xAxisTick(value, x, y);
  },
  tickLine: { height: 40, stroke: COLOR_LIGHT_GREY },
  height: 50,
  scale: 'point',
};

const lineListOnePoint = [
  {
    type: 'monotone',
    dataKey: 'totalCost',
    stroke: COLOR_DARK_ORANGE,
    fill: 'transparent',
    dot: { fill: COLOR_DARK_ORANGE, strokeWidth: 3, r: 2 },
    strokeWidth: 0,
    legendType: 'circle',
  },
  {
    type: 'monotone',
    dataKey: 'totalPremium',
    stroke: COLOR_MEDIUM_SEA_GREEN,
    fill: 'transparent',
    dot: { fill: COLOR_MEDIUM_SEA_GREEN, strokeWidth: 3, r: 2 },
    strokeWidth: 0,
    legendType: 'circle',
  },
];

const xAxisPropsOnePoint = {
  ...xAxisProps,
  tick: ({ payload, x, y }: any) => {
    const { value } = payload;
    return value === HIDDEN_MONTH_TEXT ? (
      <text x={x} y={y}></text>
    ) : (
      xAxisTick(value, x, y)
    );
  },
  tickLine: { height: 0, stroke: COLOR_WHITE },
};
const xAxisTick = (
  value: string,
  x: string | number | undefined,
  y: number
) => {
  const splitted = value.split(' ');
  return (
    value && (
      <>
        <text x={x} y={y + 15} fill={COLOR_LEGEND_LABEL_SHADE}>
          {splitted[0]}
        </text>
        <text x={x} y={y + 35} fill={COLOR_LEGEND_LABEL_SHADE} fontWeight={400}>
          {splitted[1]}
        </text>
      </>
    )
  );
};

const areaListOnePoint = [
  {
    type: 'monotone',
    dataKey: 'totalCost',
    stroke: COLOR_DARK_ORANGE,
    fill: COLOR_WHITE,
    fillOpacity: 0,
    dot: { fill: COLOR_DARK_ORANGE, strokeWidth: 3 },
    strokeWidth: 0,
    legendType: 'none',
  },
  {
    type: 'monotone',
    dataKey: 'totalPremium',
    stroke: COLOR_MEDIUM_SEA_GREEN,
    fill: COLOR_WHITE,
    fillOpacity: 0,
    dot: { fill: COLOR_MEDIUM_SEA_GREEN, strokeWidth: 3 },
    strokeWidth: 0,
    legendType: 'none',
  },
];

const yAxisProps = {
  hide: false,
  tickLine: false,
  axisLine: false,
  minTickGap: 0,
  tickMargin: 10,
  padding: { top: 0, bottom: 0 },
  label: '',
  tickFormatter: (value: number) => {
    return shortenCurrencyWithSuffix(value, 2);
  },
  width: 69,
};

const legendProp = {
  align: 'right',
  verticalAlign: 'top',
  width: 250,
  height: 50,
  margin: {
    top: -18,
    left: 10,
    right: 10,
    bottom: 10,
  },
  formatter: (value: string) => {
    const label = value === 'totalCost' ? 'Total Claims' : 'Total Premium';
    return <span className="legend-label">{label}</span>;
  },
  iconSize: 10,
};
const tooltip = (bar: { payload: any; active: boolean; label: string }) => {
  const { payload = [], active, label } = bar;
  if (
    label !== HIDDEN_MONTH_TEXT &&
    active &&
    !_.isEmpty(payload) &&
    payload.length > 0
  ) {
    const lossRatioObj = payload && payload[0].payload;
    const {
      averageLossRatio,
      totalPremium,
      totalCost,
      displayLabel,
      year,
      adjustedLossRatio,
    } = lossRatioObj;

    return (
      <div className={styles.areaChartTooltip}>
        <h2 className={`text form-title ${styles.areaChartTooltipMonthLabel}`}>
          {`${displayLabel.split(' ')[0]} ${year}`}
        </h2>
        <span className="text form-title">{`${averageLossRatio || ''}%`}</span>
        <span className={styles.areaChartTooltipText}>Loss Ratio</span>
        {!isNull(adjustedLossRatio) && (
          <>
            <span className="text form-title">{`${
              adjustedLossRatio || ''
            }%`}</span>
            <span className={styles.areaChartTooltipText}>
              Adjusted Loss Ratio
            </span>
          </>
        )}
        <span className="text form-title">
          {currencyFormatter(Math.round(totalPremium), undefined, undefined, 0)}
        </span>
        <span className={styles.areaChartTooltipText}>Total Premium</span>
        <span className="text form-title">
          {currencyFormatter(Math.round(totalCost), undefined, undefined, 0)}
        </span>
        <span className={styles.areaChartTooltipText}>Total Claims</span>
      </div>
    );
  } else {
    return null;
  }
};

const LossRatioAreaChart = (props: {
  lossRatioVTot: any;
  isOnlyOneMonthAvailable: boolean;
  displayDummyData: boolean;
}) => {
  const { lossRatioVTot, isOnlyOneMonthAvailable, displayDummyData } = props;
  const lossRatioClone = cloneDeep(lossRatioVTot);
  const sortedLossRatioVTot = lossRatioClone.sort(
    (
      a: { year: number; month: number },
      b: { year: number; month: number }
    ) => {
      return (
        new Date(`${a.year}-${a.month}-1`).getTime() -
        new Date(`${b.year}-${b.month}-1`).getTime()
      );
    }
  );
  if (isOnlyOneMonthAvailable) {
    sortedLossRatioVTot.unshift({
      month: HIDDEN_MONTH_TEXT,
    });
  }
  return (
    <AreaChart
      data={sortedLossRatioVTot}
      dataKey="displayLabel"
      chartDimensions={{
        height: 300,
      }}
      chartMargins={{
        top: -20,
        right: 30,
        left: 40,
        bottom: 5,
      }}
      areaList={
        isOnlyOneMonthAvailable
          ? areaListOnePoint
          : displayDummyData
          ? areaListDummyData
          : areaList
      }
      lineList={
        isOnlyOneMonthAvailable
          ? lineListOnePoint
          : displayDummyData
          ? lineListDummyData
          : lineList
      }
      gridAlign={{ vertical: false, horizontal: true }}
      gridStroke="3 0"
      xAxisProps={isOnlyOneMonthAvailable ? xAxisPropsOnePoint : xAxisProps}
      yAxisProps={yAxisProps}
      legendProp={legendProp}
      tooltipContent={tooltip}
    />
  );
};

export default LossRatioAreaChart;
