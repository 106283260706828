import {
  createSlice,
  Dispatch,
  PayloadAction,
  ThunkAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { RootState } from 'app/store';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import UploaderType from 'modules/plans/enums/UploaderType';
import {
  PostSBCReviewPayload,
  StatusType,
} from 'modules/plans/enums/CommonTypes';
import { getLifePlanById } from 'modules/plans/services/LifePlanService';

import {
  getExtractedPlanDetails,
  getStatusAiSBC,
  validateSBCfile,
  extractSBCfile,
  fetchLLMType,
} from 'modules/plans/services/AiSBCUploaderService';
import FundingType from 'modules/plans/enums/FundingType';
import { LLM_PROMPT_TYPE } from 'constants/commonConstants';
import * as PlanService from 'modules/plans/services/PlanService';
import { getPlanServicesList } from 'modules/plans/services/PlanService';
import { DentalPlan } from 'model/plans/DentalPlan';
import { VisionPlan } from 'model/plans/VisionPlan';
import { LifePlan } from 'model/plans/LifePlan';
import MedicalPlan from 'model/plans/MedicalPlan';
import { TDocumentExtractionSource } from 'modules/plans/components/AddPlanModal/AddPlanModal';
import { DocumentExtractionSource } from 'modules/plans/enums/DocumentExtractionSource';
import { AiUploaderFileType, BasicPlanType } from 'modules/plans/types/types';
import { AI_FILE_TYPE } from 'modules/plans/constants';
import { ReviewType } from 'modules/plans/enums/ReviewType';
import { fixedUpMedicalPlanObj } from './medicalPlanSlice';
import { fixedUpDentalPlanObj } from './dentalPlanSlice';
import { fixedUpVisionPlanObj } from './visionPlanSlice';

type BasicThunkAction = ThunkAction<Promise<void>, RootState, unknown, any>;

type AiSbcUploaderStateType = {
  jobId?: string;
  status: ProcessStatus;
  errorCode?: string;
  isLoading: boolean;
  planUploderType: UploaderType;
  reviewType: ReviewType;
  isPlanLoading: boolean;
  skipFailedDocument?: boolean | undefined;
  benefitKind?: string | undefined;
  currentPage?: number;
  isDuelLLMEnable?: boolean;
};

const initialState: AiSbcUploaderStateType = {
  isLoading: false,
  isPlanLoading: false,
  jobId: '',
  errorCode: undefined,
  status: ProcessStatus.MANUAL,
  reviewType: ReviewType.MANUAL,
  planUploderType: UploaderType.ADMIN_VIEW,
  skipFailedDocument: undefined,
  benefitKind: undefined,
  currentPage: 0,
  isDuelLLMEnable: false,
};

const aiSbcUploaderSlice = createSlice({
  name: 'aiSbc',
  initialState,
  reducers: {
    clear: (state) => {
      state.isLoading = false;
      state.errorCode = undefined;
      state.jobId = undefined;
      state.isLoading = false;
      state.status = ProcessStatus.MANUAL;
      state.reviewType = ReviewType.MANUAL;
      state.planUploderType = UploaderType.ADMIN_VIEW;
      state.skipFailedDocument = undefined;
      state.benefitKind = undefined;
      state.currentPage = undefined;
    },
    cancel: (state) => {
      state.errorCode = undefined;
      state.jobId = undefined;
      state.isLoading = false;
      state.reviewType = ReviewType.MANUAL;
      state.status = ProcessStatus.CANCELLED;
      state.skipFailedDocument = undefined;
      state.benefitKind = undefined;
      state.currentPage = undefined;
    },
    validateInProgress: (state) => {
      state.status = ProcessStatus.VALIDATING;
      state.isLoading = true;
    },
    validateSuccess: (state, { payload }: PayloadAction<string>) => {
      state.jobId = payload;
      state.status = ProcessStatus.VALIDATED;
      state.isLoading = false;
    },
    validateFailed: (state, { payload }: PayloadAction<string>) => {
      state.status = ProcessStatus.FAILED;
      state.errorCode = payload;
      state.isLoading = false;
    },
    changePlanUploaderType: (
      state,
      { payload }: PayloadAction<UploaderType>
    ) => {
      state.planUploderType = payload;
    },
    setOPSView: (state, { payload }: PayloadAction<PostSBCReviewPayload>) => {
      state.planUploderType = payload.planUploderType;
      state.jobId = payload.jobId;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setPlanLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isPlanLoading = payload;
    },
    setStatus: (state, { payload }: PayloadAction<ProcessStatus>) => {
      state.status = payload;
      if (
        [
          ProcessStatus.PROCESSING,
          ProcessStatus.INITIALIZING,
          ProcessStatus.VALIDATING,
        ].includes(state.status)
      ) {
        state.isLoading = true;
      } else {
        state.isLoading = false;
      }
    },
    setReviewType: (state, { payload }: PayloadAction<ReviewType>) => {
      state.reviewType = payload;
    },
    setBenefitKind: (state, { payload }: PayloadAction<string>) => {
      state.benefitKind = payload;
    },
    setFailedPlanForManual: (state) => {
      state.errorCode = undefined;
      state.reviewType = ReviewType.AUTOMATIC;
      state.status = ProcessStatus.REVIEWED;
      state.skipFailedDocument = true;
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload;
    },
    setIsDuelLLMEnable: (state, { payload }: PayloadAction<boolean>) => {
      state.isDuelLLMEnable = payload;
    },
  },
});

export const {
  clear,
  cancel,
  changePlanUploaderType,
  validateInProgress,
  validateSuccess,
  validateFailed,
  setOPSView,
  setLoading,
  setStatus,
  setReviewType,
  setPlanLoading,
  setFailedPlanForManual,
  setBenefitKind,
  setCurrentPage,
  setIsDuelLLMEnable,
} = aiSbcUploaderSlice.actions;

/**
 * Validates the SBC file with the given parameters.
 * @param file - The SBC file to validate.
 * @param employerId - The ID of the employer.
 * @param brokerId - The ID of the broker.
 * @param benefitCategory - The benefit category.
 * @param benefitKind - Optional benefit kind in Life plans.
 */

export const validateFile =
  (
    file: File,
    employerId: string,
    brokerId: string,
    benefitCategory: 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE' = 'MEDICAL',
    fileType: AiUploaderFileType = AI_FILE_TYPE.SBC,
    benefitKind?: BasicPlanType | undefined,
    docExtractionSource?: TDocumentExtractionSource | undefined
  ): BasicThunkAction =>
  async (dispatch) => {
    // Dispatch the validateInProgress action to indicate validation is in progress
    dispatch(validateInProgress());

    try {
      // Call the validateSBCfile function with the provided parameters
      const res = await validateSBCfile(
        file,
        employerId,
        brokerId,
        benefitCategory,
        fileType,
        benefitKind,
        docExtractionSource
      );

      // Dispatch the validateSuccess action with the returned ID
      dispatch(validateSuccess(res?.id));
    } catch (error: any) {
      console.error(error);

      // Dispatch the validateFailed action with the error code from the response
      dispatch(validateFailed(error?.response?.data?.code));
    }
  };

/**
 * Extracts the SBC file for a given job ID.
 * @param jobId - The job ID.
 * @param onModalClose - Callback function to handle modal close.
 */

export const extractSBCFile =
  (
    jobId: string,
    benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE',
    fileType?: AiUploaderFileType
  ): BasicThunkAction =>
  async (dispatch) => {
    // Set the status to processing
    dispatch(setStatus(ProcessStatus.PROCESSING));
    try {
      // Extract the SBC file using the job ID
      await extractSBCfile(jobId, benefit, fileType);
    } catch (error) {
      console.log(error);
    } finally {
      // Clear the state
      dispatch(clear());
    }
  };

/**
 * Method to build the document extraction source details
 * @param responseStatusType
 * @returns docExtractionSource
 */

const buildDocExtractionSourceDetails = (
  responseStatusType: StatusType | undefined
): TDocumentExtractionSource | undefined => {
  let docExtractionSource: TDocumentExtractionSource | undefined = undefined;

  if (responseStatusType?.sourcePlanId) {
    let sourceOfferName = '';
    let planName = '';

    try {
      sourceOfferName = decodeURIComponent(
        responseStatusType?.sourceOfferName ?? ''
      );
    } catch (error) {
      if (error instanceof URIError) {
        console.error('Malformed sourceOfferName URL:', error);
        sourceOfferName = responseStatusType?.sourceOfferName ?? '';
      } else {
        throw error;
      }
    }

    try {
      planName = decodeURIComponent(responseStatusType?.sourcePlanName ?? '');
    } catch (error) {
      if (error instanceof URIError) {
        console.error('Malformed sourcePlanName URL:', error);
        planName = responseStatusType?.sourcePlanName ?? '';
      } else {
        throw error;
      }
    }

    docExtractionSource = {
      // @ts-ignore
      source: responseStatusType?.source,
      // @ts-ignore
      sourceOfferId: responseStatusType?.sourceOfferId,
      sourceOfferName: sourceOfferName,
      planName: planName ?? '',
      planId: responseStatusType?.sourcePlanId,
    };
  }

  return docExtractionSource;
};

/**
 * Fetches the job status for AI SBC uploader.
 * @param benefit - The benefit category.
 * @param employerId - The employer ID.
 * @param onSuccess - Optional callback function to handle success.
 * @param onFetchFailed - Optional callback function to modal close on fail.
 * @param fileName - Optional file name.
 */

export const fetchJobStatus =
  (
    employerId: string,
    benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE',
    isOpsAdmin: boolean,
    onSuccess?: (
      plan?: any,
      benefitCodes?: any,
      docExtractionSource?: TDocumentExtractionSource | undefined
    ) => any,
    onFetchFailed?: ({
      status,
      extractionSource,
      benefitKind,
    }: {
      status: ProcessStatus;
      extractionSource?: TDocumentExtractionSource;
      benefitKind?: string;
    }) => void,
    fileName?: string,
    setDocumentExtractionSource?: Function | undefined
  ): BasicThunkAction =>
  async (dispatch, getState) => {
    let statusResponse: StatusType | undefined;
    try {
      const { jobId, status } = getState().plan.aiSbc;
      if (![ProcessStatus.PROCESSING].includes(status)) {
        dispatch(setPlanLoading(true));
      }

      if (jobId) {
        if (![ProcessStatus.PROCESSING].includes(status)) {
          dispatch(setStatus(ProcessStatus.INITIALIZING));
        }

        // Get the status and plan uploader type from the API
        const responseStatusType: StatusType | undefined = (
          await getStatusAiSBC(jobId)
        )?.data;

        statusResponse = { ...(responseStatusType as any) };

        if (responseStatusType?.status) {
          if (isOpsAdmin) {
            if (responseStatusType.status === ProcessStatus.FAILED) {
              dispatch(setStatus(ProcessStatus.SUCCESS));
            } else {
              dispatch(setStatus(responseStatusType?.status));
            }
          } else {
            if (responseStatusType.status === ProcessStatus.SUCCESS) {
              dispatch(setStatus(ProcessStatus.PROCESSING));
            } else {
              dispatch(setStatus(responseStatusType?.status));
            }
          }
        }

        if (responseStatusType?.type) {
          dispatch(
            setReviewType(responseStatusType?.type ?? ReviewType.MANUAL)
          );
        }

        if (responseStatusType?.benefitKind) {
          dispatch(setBenefitKind(responseStatusType?.benefitKind));
        }

        setDocumentExtractionSource?.(
          buildDocExtractionSourceDetails(responseStatusType)
        );

        // If the status is success or reviewed, fetch the plan details and benefit codes
        if (
          [
            ProcessStatus.SUCCESS,
            ProcessStatus.REVIEWED,
            ProcessStatus.SAVED,
          ].includes(responseStatusType?.status!)
        ) {
          const planPromise = getExtractedPlanDetails(jobId, benefit);

          const buildPromiseArr = [planPromise];

          if (['MEDICAL', 'DENTAL', 'VISION'].includes(benefit)) {
            const benefitCodePromise = getPlanServicesList(benefit, employerId);
            buildPromiseArr.push(benefitCodePromise);
          }

          const [plan, benefitCodes] = await Promise.all(buildPromiseArr);

          const docExtractionSource =
            buildDocExtractionSourceDetails(responseStatusType);

          const updatedData = cloneDeep(plan?.data);

          await updatePlanAndMergeAttributes(
            updatedData,
            benefit,
            docExtractionSource
          );

          onSuccess?.(updatedData, benefitCodes?.data, docExtractionSource);
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(setStatus(ProcessStatus.FAILED));

      // Safely assign the document extraction source to onFetchFailed callback
      const docExtractionSource =
        buildDocExtractionSourceDetails(statusResponse);
      onFetchFailed?.({
        status: ProcessStatus.FAILED,
        extractionSource: docExtractionSource,
        benefitKind: statusResponse?.benefitKind,
      });
    } finally {
      dispatch(setPlanLoading(false));
    }
  };

export const finalizeSBCReview = async (
  _plan: MedicalPlan | DentalPlan | VisionPlan | LifePlan,
  jobId: string,
  benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE' = 'MEDICAL'
) => {
  let plan = _plan;
  if (benefit === 'MEDICAL') {
    plan = fixedUpMedicalPlanObj(_plan as MedicalPlan);
  } else if (benefit === 'DENTAL') {
    plan = fixedUpDentalPlanObj(_plan as DentalPlan);
  } else if (benefit === 'VISION') {
    plan = fixedUpVisionPlanObj(_plan as VisionPlan);
  }

  try {
    if (jobId) {
      await PlanService.saveOpsFinalizeReview(
        plan,
        jobId!,
        benefit,
        benefit === 'MEDICAL' ? AI_FILE_TYPE.SBC : AI_FILE_TYPE.BENEFIT_SUMMARY
      );
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error);
    }
  }
};

/**
 * Sends a request to redirect a failed extraction to OPS admin.
 * @param jobId - The job ID.
 * @param onModalClose - Callback function to handle modal close.
 */

export const redirectToOps =
  (
    jobId: string,
    benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE' = 'MEDICAL',
    successCallback: () => void,
    errorCallback: () => void
  ): BasicThunkAction =>
  async (dispatch) => {
    // Set the status to processing
    try {
      if (jobId) {
        dispatch(setStatus(ProcessStatus.PROCESSING));
        await PlanService.redirectFailedExtractionToOps(
          jobId!,
          benefit,
          benefit === 'MEDICAL'
            ? AI_FILE_TYPE.SBC
            : AI_FILE_TYPE.BENEFIT_SUMMARY
        );
        successCallback();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        errorCallback();
      }
    } finally {
      // Clear the state
      dispatch(clear());
    }
  };

/**
 * Fetches and handles failed plans based on job status.
 * @param employerId - The employer's identifier.
 * @param benefit - The category of the benefit (MEDICAL, DENTAL, VISION, LIFE).
 * @param onSuccess - Optional callback function to handle success when plan details are retrieved.
 * @param onFetchFailed - Optional callback function to modal close on fail.
 * @param fileName - Optional file name.
 */

export const fetchFailedPlan =
  (
    employerId: string,
    benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE',
    onSuccess?: (
      planData?: any,
      benefitCodes?: any,
      docExtractionSource?: TDocumentExtractionSource | undefined
    ) => void,
    onFetchFailed?: ({ status }: { status: ProcessStatus }) => void,
    fileName?: string
  ): BasicThunkAction =>
  async (dispatch, getState) => {
    try {
      const { jobId, status } = getState().plan.aiSbc;
      if (![ProcessStatus.PROCESSING, ProcessStatus.FAILED].includes(status)) {
        dispatch(setPlanLoading(true));
      }

      if (jobId && status === ProcessStatus.FAILED) {
        dispatch(setFailedPlanForManual());

        const planPromise = getExtractedPlanDetails(jobId, benefit);
        const buildPromiseArr = [planPromise];

        if (['MEDICAL', 'DENTAL', 'VISION'].includes(benefit)) {
          const benefitCodePromise = getPlanServicesList(benefit, employerId);
          buildPromiseArr.push(benefitCodePromise);
        }

        const [plan, benefitCodes] = await Promise.all(buildPromiseArr);

        onSuccess?.(plan?.data, benefitCodes?.data, undefined);
      }
    } catch (error) {
      console.error('Fetching failed plan encountered an error:', error);
      dispatch(setStatus(ProcessStatus.FAILED));
      onFetchFailed?.({ status: ProcessStatus.FAILED });
    } finally {
      dispatch(setPlanLoading(false));
    }
  };

export const getDuelLLMEnable =
  ({
    promptType,
    benefit,
  }: {
    promptType: keyof typeof LLM_PROMPT_TYPE;
    benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'BASIC_LIFE';
  }) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await fetchLLMType({
        promptType: promptType,
        benefit: benefit,
      });

      dispatch(setIsDuelLLMEnable(response?.data));
    } catch (error) {
      console.error('Error fetching LLM type:', error);
    }
  };

/**
 * Updates the funding type in the provided updatedData object based on the benefit type and document extraction source.
 *
 * @param {Record<string, any>} updatedData - The object that will be updated with the funding type. This object is expected to have a `fundingType` property.
 * @param {string} benefit - The benefit category (e.g., 'LIFE', 'MEDICAL', 'DENTAL', 'VISION').
 * @param {TDocumentExtractionSource} [docExtractionSource] - Optional. The source of the document extraction which includes details like the source type and plan ID.
 *
 * @return {Promise<Record<string, any>>} A Promise that resolves with the updated data object containing the updated funding type.
 *
 * @remarks
 * - If the `docExtractionSource` has a `planId`, the function will proceed to determine the funding type.
 * - If the `source` is `DocumentExtractionSource.UPDATE_QUOTE_PLAN`, the funding type will be set to `FundingType.FULLY_INSURED`.
 * - Depending on the benefit type and the document extraction source, the appropriate plan details will be fetched to update the funding type.
 */
const updatePlanAndMergeAttributes = async (
  updatedData: Record<string, any>,
  benefit: string,
  docExtractionSource?: TDocumentExtractionSource
): Promise<Record<string, any>> => {
  if (docExtractionSource?.planId) {
    if (
      docExtractionSource?.source === DocumentExtractionSource.UPDATE_QUOTE_PLAN
    ) {
      updatedData.fundingType = FundingType.FULLY_INSURED;
    } else if (
      docExtractionSource?.source ===
      DocumentExtractionSource.UPDATE_SYSTEM_PLAN
    ) {
      const planFetchers: Record<string, () => Promise<any>> = {
        MEDICAL: () =>
          PlanService.getMedicalPlanById(docExtractionSource.planId!),
        DENTAL: () =>
          PlanService.getDentalPlanById(docExtractionSource.planId!),
        VISION: () =>
          PlanService.getVisionPlanById(docExtractionSource.planId!),
        LIFE: () => getLifePlanById(docExtractionSource.planId!),
      };

      const fetchPlan = planFetchers[benefit];
      const response = fetchPlan ? await fetchPlan() : undefined;

      updatedData.fundingType =
        response?.data?.fundingType ?? FundingType.FULLY_INSURED;
    }
  }

  return updatedData;
};

export default aiSbcUploaderSlice.reducer;
