import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Checkbox, Col, Form, Input, Row } from 'antd';
import { find, isEqual } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { Option } from 'antd/lib/mentions';

import { EMPTY_MESSAGE } from 'modules/brokers/constants/brokerConstants';
import InputForm from 'components/InputForm/InputForm';
import SelectOptions from 'components/SelectOptions/SelectOptions';
import PlanYear from 'model/PlanYear';
import { getPlanYearRangeWithCurrent } from 'util/commonUtil';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useIsAuthorizedUserRole } from 'hooks/useIsAuthorizedUserRole';
import Carrier from 'model/Carrier';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import { useNavContext } from 'hooks/useNavContext';
import BenefitClassMultiSelect from 'components/BenefitClassMultiSelect/BenefitClassMultiSelect';
import {
  BasicInfoFields,
  DENTAL_PLAN_TYPE_OPTIONS,
  FUNDING_TYPES_LIST,
  VALIDATION_NAME_DUPLICATED,
} from 'modules/plans/constants';
import { getCarriersByBenefitKind } from 'modules/plans/slices/basicPlanInfoSlice';
import {
  clearDentalPlanApiErrors,
  validateDentalPlanName,
} from 'modules/plans/slices/dentalPlanSlice';
import { validateVisionPlanName } from 'modules/plans/slices/visionPlanSlice';
import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import { RENEWAL_COMMON_WARNING_MESSAGE_BASIC_INFO } from 'constants/benguideCollaborationConstants';
import {
  ADD_NEW_CARRIER_MODAL_CONSTANTS,
  BenefitCategory,
  addNewCarrierModalDescription,
  benefitGroupsChangedMsg,
  formVerificationMsg,
  maxPlanNameSizeMDV,
  planAlreadyExistsError,
  planYearChangedMsg,
  IndividualSubTypes,
} from 'constants/commonConstants';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import {
  buildRates,
  formatSFCValue,
  isNullOrUndefined,
} from 'modules/plans/utils';
import FundingType from 'modules/plans/enums/FundingType';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import { useLazyGetSFCValuesQuery } from 'modules/plans/slices/planSllice';
import { useAddNewCarrierState } from 'hooks/useAddNewCarrierState';
import SelectAddMoreButton from 'components/buttons/SelectAddMoreButton/SelectAddMoreButton';
import AddNewCarrierModal from 'components/AddNewCarrierModal/AddNewCarrierModal';
import ConfirmationWithThreeButtons from 'components/ConfirmationWithThreeButtons/ConfirmationWithThreeButtons';
import CarrierType from 'modules/carriers/enums/CarrierType';
import { loginTypes } from 'constants/authConstants';

import styles from './planBasicInfoForm.module.less';

export const planBasicInfoFormFields = {
  requiredFields: [
    'planName',
    'planYear',
    'groups',
    'benefitCarrier',
    'fundingType',
  ],
  formFields: [
    'planName',
    'planYear',
    'carrier',
    'groups',
    'planNetworkName',
    'fundingType',
    'administrationFee',
    'groupId',
    'type',
  ],
};

type BasicPlanInfoProps = {
  onChange: Function;
  plan: any;
  isCloseConfirmed: boolean;
  error: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  isDBGPlan?: boolean;
  dbgPlanYear?: string;
  isUPEdit?: boolean;
  benefitCategory: 'VISION' | 'DENTAL';
  originalPlanYear?: string;
  originalPlanBenGroups?: string[];
  hasSameContributions?: boolean;
  isReviewProp?: boolean;
  isReviewHighlight?: boolean;
  reviewNewPlanYear?: string;
  reviewBenefitClasses?: string[];
  isSingleColumn?: boolean;
  planAlreadyExists?: boolean;
  setPlanAlreadyExists?: Function;
  resetAlertMessage?: Function;
};
type MultiSelect = {
  groups: string[];
};
const PlanBasicInfoForm = forwardRef((props: BasicPlanInfoProps, ref) => {
  const {
    isCloseConfirmed,
    onChange,
    plan,
    isDBGPlan,
    error,
    dbgPlanYear,
    isUPEdit,
    benefitCategory,
    originalPlanYear,
    originalPlanBenGroups,
    hasSameContributions,
    isReviewProp = false,
    reviewNewPlanYear,
    reviewBenefitClasses,
    isReviewHighlight = false,
    isSingleColumn = false,
    planAlreadyExists,
    setPlanAlreadyExists,
    resetAlertMessage,
  } = props;
  const empInfo = useAppSelector((state) => state.layout?.employer);
  const dispatch = useAppDispatch();

  const { planYearsList, inProgress } = useAppSelector(
    (state) => state.employer.employer
  );
  const currentDentalPlan = useAppSelector(
    (state) => state.plan.dentalPlan.editedDentalPlan
  );
  const currentVisionPlan = useAppSelector(
    (state) => state.plan.visionPlan.editedVisionPlan
  );
  const basicPlanInfo = useAppSelector((state) => state.plan.planBasicInfo);

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);

  const { carriersList } = basicPlanInfo;

  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [benefitClassesList, setBenefitClassesList] = useState<string[]>([]);
  const [planYearDBG, setPlanYearDBG] = useState<PlanYear>();
  const [selectedPlanYear, setSelectedPlanYear] = useState<PlanYear | null>(
    null
  );
  const [tierChangeWarning, setTierChangeWarning] = useState<boolean>(false);
  const [selectedBenefitClasses, setSelectedBenefitClasses] = useState<
    string[] | null
  >(null);
  const [requiredFieldError, setRequiredFieldError] = error || [];
  const [multiSelect, setMultiSelect] = useState<MultiSelect>({
    groups: [],
  });
  const [isNextClicked, setNextClicked] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [showPlanYearWarning, setShowPlanYearWarning] =
    useState<boolean>(false);

  const {
    carrierCreateTypeConfirmation,
    setCarrierCreateTypeConfirmation,
    openCarrierModal,
    setOpenCarrierModal,
    newCarrierId,
    setNewCarrierId,
  } = useAddNewCarrierState();

  const [formData, setFormData] = useState({
    planName: '',
    benefitCarrier: null,
    planYear: '',
    groups: [] as string[],
    planNetworkName: '',
    fundingType: '',
    administrationFee: '',
    groupId: '',
    hraCompatible: null,
    type: '',
  });

  const groupsRef = useRef<any>(null);
  const apiError = useAppSelector(
    (state) => state.plan.dentalPlan.error || state.plan.visionPlan.error
  );
  const [form] = Form.useForm();
  const { brokerId, employerId } = useNavContext();

  const [getSFCValues, { data: sfcData }] = useLazyGetSFCValuesQuery();

  const benefitCarrierId =
    (formData as any)?.benefitCarrierId || plan?.benefitCarrier?.id;

  const [getUpcomingPlanYears, { data: upcomingPlanYearData }] =
    useLazyGetUpcomingPlanYearsByEmployerQuery();

  const isPlanYearInList = !!planYearsList?.find(
    (planYear: any) =>
      planYear?.id === currentDentalPlan?.planYearId ||
      currentVisionPlan?.planYearId ||
      dbgPlanYear
  )?.current;

  const hasUpcomingPlanYearWithNullId =
    upcomingPlanYearData?.upcomingPlanYears?.some(
      (obj: any) => obj?.planYearId === null
    );

  const isRenewalStarted = isPlanYearInList && hasUpcomingPlanYearWithNullId;
  const isErAdmin: boolean = useIsAuthorizedUserRole([loginTypes.erAdmin]);

  useEffect(() => {
    if (selectedPlanYear?.id && benefitCarrierId) {
      getSFCValues({
        benefitKind: benefitCategory,
        employerId: employerId,
        planYearId: selectedPlanYear?.id,
        carrierId: benefitCarrierId,
        currentTs: new Date().getTime(),
      });
    }
  }, [
    getSFCValues,
    employerId,
    selectedPlanYear?.id,
    benefitCarrierId,
    benefitCategory,
    plan?.id,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      administrationFee: formatSFCValue(sfcData?.administrationFee),
    });
    // Don't expect call hook everytime form change
    // eslint-disable-next-line
  }, [sfcData]);

  /**
   * Find carrier by id
   * @param {string} carrierId - carrier id
   * @returns {Carrier | undefined} - carrier object
   */
  const findCarrierById = useCallback(
    (carrierId: string): Carrier | undefined => {
      return carriersList.find((carrier) => carrier.id === carrierId);
    },
    [carriersList]
  );

  const setDefaultPlanYear = useCallback(() => {
    if (planYearsList[0] && !isDBGPlan) {
      setSelectedPlanYear(planYearsList[0]);
      form.setFieldsValue({
        planYear: planYearsList[0].id,
      });

      setBenefitClassesList(planYearsList[0].benefitGroups);
      if (planYearsList[0].benefitGroups.length === 1) {
        setMultiSelect((multiSelect) => ({
          ...multiSelect,
          groups: planYearsList[0].benefitGroups,
        }));
        setFormData({
          ...formData,
          planYear: planYearsList[0].id,
        });
        setSelectedBenefitClasses(planYearsList[0].benefitGroups);

        form.setFieldsValue({
          [BasicInfoFields.BENEFIT_GROUPS]: planYearsList[0].benefitGroups,
        });
        onChange({
          ...plan,
          [BasicInfoFields.BENEFIT_GROUPS]: planYearsList[0].benefitGroups,
        });
      }
    } else if (isDBGPlan) {
      const selectedPlanYear = planYearsList.find(
        (t) => t.id === plan.planYearId
      );
      setSelectedPlanYear(selectedPlanYear || null);
      form.setFieldsValue({
        planYear: selectedPlanYear?.id,
      });

      setBenefitClassesList(
        (isReviewProp
          ? reviewBenefitClasses
          : selectedPlanYear?.benefitGroups) || []
      );
      if (selectedPlanYear?.benefitGroups.length === 1) {
        setMultiSelect((multiSelect) => ({
          ...multiSelect,
          groups: selectedPlanYear?.benefitGroups,
        }));
        setFormData({
          ...formData,
          planYear: selectedPlanYear?.id,
        });
        setSelectedBenefitClasses(selectedPlanYear?.benefitGroups);

        form.setFieldsValue({
          [BasicInfoFields.BENEFIT_GROUPS]: selectedPlanYear?.benefitGroups,
        });
      }
    }
    // adding plan will reset plan year on every plan change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, onChange, planYearsList]);

  useImperativeHandle(ref, () => ({
    getIsComplete,
    validate() {
      setNextClicked(true);
      return getValidationResult();
    },

    resetForm() {
      form.resetFields();
      setMultiSelect({ groups: [] });
      setSelectedPlanYear(null);
      setDefaultPlanYear();
      setNextClicked(false);
      setRequiredFieldError(false);
      setShowWarning(false);
      setShowPlanYearWarning(false);
    },

    isValidForm() {
      validateBasicInfo();
      return validateForm();
    },

    setNameDuplicationError() {
      setRequiredFieldError(true);
      setAlertMessage({
        type: 'error',
        message: planAlreadyExistsError,
      });
      form.setFields([
        { name: BasicInfoFields.PLAN_NAME, errors: [EMPTY_MESSAGE] },
      ]);
    },
  }));

  useEffect(() => {
    const planYear = find(planYearsList, { id: dbgPlanYear });
    if (planYear) setPlanYearDBG(planYear);
  }, [dbgPlanYear, planYearsList]);

  useEffect(() => {
    if (planYearDBG != null) {
      const benefitClasses = planYearDBG.benefitGroups;
      setBenefitClassesList(benefitClasses);
      if (benefitClasses.length === 1) {
        setMultiSelect((multiSelect) => ({
          ...multiSelect,
          groups: benefitClasses,
        }));

        onChange({
          ...plan,
          [BasicInfoFields.BENEFIT_GROUPS]: benefitClasses,
        });

        setFormData({
          ...formData,
          groups: benefitClasses,
        });
        setSelectedBenefitClasses(benefitClasses);

        form.setFieldsValue({
          [BasicInfoFields.BENEFIT_GROUPS]: benefitClasses,
        });
      } else {
        form.setFieldsValue({
          [BasicInfoFields.BENEFIT_GROUPS]: null,
        });

        setMultiSelect({ ...multiSelect, groups: [] });
      }
    }
    // eslint-disable-next-line
  }, [planYearDBG]);

  useEffect(() => {
    if (plan && isDBGPlan) {
      const {
        name,
        benefitCarrier,
        planNetworkName,
        planYearId,
        groups,
        fundingType,
        administrationFee,
        groupId,
        hraCompatible,
        type,
      } = plan;
      const data = {
        planName: name,
        benefitCarrier: benefitCarrier?.name,
        planNetworkName: planNetworkName,
        planYear: planYearId,
        groups: groups,
        fundingType,
        administrationFee,
        groupId: groupId,
        hraCompatible: hraCompatible,
        type: type,
      };
      form.setFieldsValue(data);
      setMultiSelect({
        groups,
      });
      setFormData(data);
      setRequiredFieldError(false);
      groupsRef.current = groups;
    }
    // adding plan will reset plan year on every plan change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, plan, isDBGPlan, setRequiredFieldError]);

  useEffect(() => {
    if (employerId && brokerId) {
      dispatch(
        getCarriersByBenefitKind(
          benefitCategory || BenefitCategory.DENTAL.value,
          brokerId,
          employerId
        )
      );
      if (!isReviewProp) {
        dispatch(getPlanYears(employerId));
        getUpcomingPlanYears({ employerId });
      }
    }
    // eslint-disable-next-line
  }, [brokerId, dispatch, employerId, benefitCategory]);

  useEffect(() => {
    if (isCloseConfirmed) {
      setRequiredFieldError(false);
    }
  }, [isCloseConfirmed, form, setRequiredFieldError]);

  useEffect(() => {
    if (apiError?.data?.code === VALIDATION_NAME_DUPLICATED) {
      form.setFields([{ name: 'planName', errors: [EMPTY_MESSAGE] }]);
      setAlertMessage({ message: planAlreadyExistsError, type: 'error' });
      setRequiredFieldError(true);
      dispatch(clearDentalPlanApiErrors());
    }
  }, [apiError?.data?.code, dispatch, form, setRequiredFieldError]);

  const showTierChangeWarning = !isNullOrUndefined(plan?.hraPlanId);

  const validateForm = async () => {
    if (isDBGPlan) {
      const isFormValid = await validateBasicInfo();
      return isFormValid;
    }
  };

  const getCheckboxDataForGroups = (
    checkboxValues: string[],
    value: string,
    checked: boolean
  ): string[] => {
    let newCheckboxValues = cloneDeep(checkboxValues);
    if (checked) {
      newCheckboxValues = [...checkboxValues, value];
    } else {
      const index = checkboxValues.findIndex((element) => element === value);
      if (index > -1) {
        newCheckboxValues.splice(index, 1);
      }
    }
    return newCheckboxValues;
  };

  const onCheckboxSelection = async (event: any) => {
    const { name, value, checked } = event.target;

    const newCheckboxValues = getCheckboxDataForGroups(
      multiSelect.groups,
      value,
      checked
    );

    setMultiSelect((prevState) => ({
      ...prevState,
      [name]: newCheckboxValues,
    }));

    setSelectedBenefitClasses(newCheckboxValues);
    form.setFieldsValue({ groups: newCheckboxValues });
    if (isDBGPlan && !isUPEdit) {
      onChange({
        ...plan,
        groups: newCheckboxValues,
        rates: buildRates(newCheckboxValues, plan),
        hasSameContributions: hasSameContributions,
      });
    } else {
      onChange({
        ...plan,
        groups: newCheckboxValues,
        hasSameContributions: hasSameContributions,
      });
    }

    if (isNextClicked) {
      const isFormValid = await validateBasicInfo();
      if (isFormValid && selectedPlanYear?.id && value) {
        setRequiredFieldError(false);
      }
    }

    if (isDBGPlan) {
      groupsRef.current = newCheckboxValues;
      const isFormValid = await validateBasicInfo();
      if (isFormValid && newCheckboxValues) {
        if (
          isUPEdit &&
          !isReviewProp &&
          !isEqual(
            plan?.planYearId,
            planYearsList.find((t) => t.id === value)?.id
          )
        ) {
          setShowPlanYearWarning(true);
        }
        setShowWarning(true);
        setAlertMessage({
          type: 'warning',
          message: benefitGroupsChangedMsg,
        });
      } else if (!isFormValid) {
        setRequiredFieldError(true);
        setAlertMessage({
          type: 'error',
          message: formVerificationMsg,
        });
      }
    }
  };

  const onInputChange = async (changedValues: any, allValues: any) => {
    if (planAlreadyExists) {
      setPlanAlreadyExists?.(false);
      resetAlertMessage?.('');
    }
    if (isNextClicked) {
      const isFormValid = await validateBasicInfo();
      if (
        isFormValid &&
        selectedPlanYear?.id &&
        selectedBenefitClasses?.length
      ) {
        setRequiredFieldError(false);
      }
    }

    if (
      Object.keys(changedValues)[0] === BasicInfoFields.BENEFIT_CARRIER &&
      !isEmpty(carriersList) &&
      !isEmpty(Object.values(changedValues)[0])
    ) {
      const carrierObj = findCarrierById(
        Object.values(changedValues)[0] as string
      );
      changedValues[BasicInfoFields.BENEFIT_CARRIER] = carrierObj;
      changedValues[BasicInfoFields.BENEFIT_CARRIER_ID] = carrierObj?.id;
    }

    const {
      benefitCarrier,
      planName,
      planNetworkName,
      planYear,
      fundingType,
      administrationFee,
      groupId,
      hraCompatible,
      type,
    } = allValues;

    form.setFieldsValue({
      benefitCarrier,
      planName,
      planNetworkName,
      planYear,
      fundingType,
      administrationFee,
      groupId,
      hraCompatible,
      type,
    });

    setFormData({
      ...formData,
      ...changedValues,
      groups: formData.groups,
    });

    if (isDBGPlan) {
      const {
        planName,
        planNetworkName,
        fundingType,
        administrationFee,
        groupId,
        hraCompatible,
        type,
      } = allValues;

      const _planYear = planYearDBG
        ? planYearDBG
        : planYearsList.find((t) => t.id === planYear);

      let selectedCarrier;
      if (isUPEdit) {
        selectedCarrier = changedValues.hasOwnProperty(
          BasicInfoFields.BENEFIT_CARRIER
        )
          ? changedValues[BasicInfoFields.BENEFIT_CARRIER]
          : { ...plan.benefitCarrier };
      } else {
        selectedCarrier = findCarrierById(
          form.getFieldValue(BasicInfoFields.BENEFIT_CARRIER)
        );
      }

      const planCopy = {
        ...plan,
        name: planName,
        benefitCarrier: selectedCarrier,
        groups: multiSelect.groups,
        employerId,
        startDate: _planYear?.startDate,
        endDate: _planYear?.endDate,
        planNetworkName,
        fundingType,
        administrationFee,
        groupId,
        hraCompatible,
        type,
      };

      onChange(planCopy);
      setRequiredFieldError(false);
    }
  };

  useEffect(() => {
    if (newCarrierId) {
      const carrierObj = findCarrierById(newCarrierId);
      form.setFieldsValue({
        [BasicInfoFields.BENEFIT_CARRIER]: carrierObj?.id,
      });

      onChange({
        ...plan,
        [BasicInfoFields.BENEFIT_CARRIER]: carrierObj,
        [BasicInfoFields.BENEFIT_CARRIER_ID]: carrierObj?.id,
      });
    }
    // don't expect call hook every time form,onChange,dental/vision plan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCarrierId, carriersList]);

  const handleSetCarrierToForm = (carrier: {
    label: string;
    value: string;
  }): void => {
    const { value } = carrier;
    setNewCarrierId(value);
  };

  const onPlanYearSelect = async (value: any) => {
    if (isDBGPlan && !isReviewProp) {
      setAlertMessage({
        type: 'warning',
        message: benefitGroupsChangedMsg,
      });
      setShowWarning(true);
    }
    const planYear = planYearsList.find((t) => t.id === value);
    if (planYear) {
      setSelectedPlanYear(planYear);
      form.setFieldsValue({ planYear: planYear.id });
      if (isNextClicked) {
        const isFormValid = await validateBasicInfo();
        if (isFormValid && selectedPlanYear?.id && selectedBenefitClasses) {
          setRequiredFieldError(false);
        }
      }
      const benefitClasses = planYear.benefitGroups;
      setBenefitClassesList(benefitClasses);
      if (benefitClasses.length === 1) {
        setMultiSelect((multiSelect) => ({
          ...multiSelect,
          groups: benefitClasses,
        }));
        const rates = buildRates(benefitClasses, cloneDeep(plan));
        onChange({
          ...plan,
          [BasicInfoFields.BENEFIT_GROUPS]: benefitClasses,
          planYearId: planYear.id,
          rates,
        });
        setSelectedBenefitClasses(benefitClasses);

        form.setFieldsValue({
          planYear: planYear.id,
          [BasicInfoFields.BENEFIT_GROUPS]: benefitClasses,
        });
      } else {
        setSelectedBenefitClasses([]);
        form.setFieldsValue({
          [BasicInfoFields.BENEFIT_GROUPS]: [],
        });

        setMultiSelect({
          groups: [],
        });
        onChange({
          ...plan,
          [BasicInfoFields.BENEFIT_GROUPS]: [],
          planYearId: planYear.id,
        });
      }
      if (isNextClicked) {
        const isFormValid = await validateBasicInfo();
        if (isFormValid) {
          setRequiredFieldError(false);
        }
      }
    }

    if (isUPEdit && !isEqual(originalPlanYear, planYear?.id)) {
      setShowWarning(false);
      setShowPlanYearWarning(true);
      setAlertMessage({
        type: 'warning',
        message: planYearChangedMsg,
      });
    }
    if (
      isUPEdit &&
      !isReviewProp &&
      !isEqual(originalPlanYear, planYear?.id) &&
      !isEqual(originalPlanBenGroups, planYear?.benefitGroups)
    ) {
      setShowWarning(true);
      setShowPlanYearWarning(true);
      setAlertMessage({
        type: 'warning',
        message: benefitGroupsChangedMsg,
      });
    }
  };

  useEffect(() => {
    setDefaultPlanYear();
  }, [setDefaultPlanYear]);

  const trimPlanNameInput = (event: any) => {
    const { value } = event.target;
    form.setFieldsValue({ planName: value.trim() });
  };

  const trimPlanNetworkInput = (event: any) => {
    const { value } = event.target;
    form.setFieldsValue({ planNetworkName: value.trim() });
  };

  const trimPlanTypeInput = (event: any) => {
    const { value } = event.target;
    form.setFieldsValue({ type: value.trim() });
  };

  const trimGroupIdInput = (event: any) => {
    const { value } = event.target;
    form.setFieldsValue({ groupId: value.trim() });
  };

  const validateBasicInfo = async () => {
    try {
      let validateFields;

      if (isReviewProp) {
        validateFields = planBasicInfoFormFields.requiredFields.filter(
          (item) => item !== 'planYear'
        );
      } else {
        validateFields = planBasicInfoFormFields.requiredFields;
      }
      await form.validateFields(validateFields);
      return true;
    } catch (errorInfo: any) {
      if (errorInfo.errorFields.length > 0) {
        setRequiredFieldError(true);
        setAlertMessage({
          type: 'error',
          message: formVerificationMsg,
        });
      }
      return errorInfo.errorFields.length === 0;
    }
  };

  const validatePlanName = async () => {
    let isPlanNameValid;

    if (benefitCategory === BenefitCategory.DENTAL.value) {
      const dentalPlanObj = {
        employerId: employerId || '',
        planName: formData?.planName,
        planYearId: formData?.planYear,
      };
      return (isPlanNameValid = await dispatch(
        validateDentalPlanName(dentalPlanObj)
      ));
    } else if (benefitCategory === BenefitCategory.VISION.value) {
      const visionPlanObj = {
        employerId: employerId || '',
        planName: formData?.planName,
        planYearId: formData?.planYear,
      };
      return (isPlanNameValid = await dispatch(
        validateVisionPlanName(visionPlanObj)
      ));
    } else {
      isPlanNameValid = true;
    }

    return isPlanNameValid;
  };

  const getValidationResult = async () => {
    const isFormValid = await validateBasicInfo();

    let isPlanNameValid = true;
    if (
      benefitCategory === BenefitCategory.DENTAL.value ||
      benefitCategory === BenefitCategory.VISION.value
    ) {
      isPlanNameValid = (await validatePlanName()) ?? true;
    }

    if (isFormValid && isPlanNameValid) {
      const {
        planName,
        planNetworkName,
        fundingType,
        administrationFee,
        groupId,
        type,
      } = formData;
      const planYear = planYearDBG ? planYearDBG : selectedPlanYear;
      const selectedBenefitCarrier = findCarrierById(
        form.getFieldValue(BasicInfoFields.BENEFIT_CARRIER)
      );
      const planCopy = {
        ...plan,
        name: planName.trim(),
        planYearId: planYear?.id?.trim(),
        groups: selectedBenefitClasses,
        benefitCarrier: selectedBenefitCarrier,
        employerId,
        startDate: planYear?.startDate,
        endDate: planYear?.endDate,
        planNetworkName,
        fundingType,
        administrationFee,
        groupId,
        type,
      };
      onChange(planCopy);
      return true;
    } else {
      setRequiredFieldError(true);
      return false;
    }
  };

  const getIsComplete = () => {
    const {
      planName,
      benefitCarrier,
      planNetworkName,
      fundingType,
      groupId,
      type,
    } = formData;
    return [
      planName,
      benefitCarrier,
      planNetworkName,
      fundingType,
      groupId,
      type,
    ].every((value) => !isEmpty(value));
  };

  const { fundingType } = form.getFieldsValue();

  if (inProgress) {
    return <></>;
  }

  const updatedPlan = () => {
    const selectedCarrier = findCarrierById(
      form.getFieldValue(BasicInfoFields.BENEFIT_CARRIER)
    );

    const updatedPlanObject = {
      ...plan,
      name:
        form.getFieldsValue()?.planName !== plan.name
          ? form.getFieldsValue()?.planName
          : plan.name,
      planNetworkName:
        form.getFieldsValue()?.planNetworkName !== plan.planNetworkName
          ? form.getFieldsValue()?.planNetworkName
          : plan.planNetworkName,
      fundingType:
        form.getFieldsValue()?.fundingType !== plan.fundingType
          ? form.getFieldsValue()?.fundingType
          : plan.fundingType,
      groupId:
        form.getFieldsValue()?.groupId !== plan.groupId
          ? form.getFieldsValue()?.groupId
          : plan.groupId,
      benefitCarrier: selectedCarrier,
      type:
        form.getFieldsValue()?.type !== plan.type
          ? form.getFieldsValue()?.type
          : plan.type,
    };

    return updatedPlanObject;
  };

  const formItemColSpan = isSingleColumn ? 24 : 12;
  const labelProps = {
    span: isSingleColumn ? 6 : 24,
    className: styles.inputLabel,
  } as const;

  return (
    <div
      className={`${styles.wrapperForm} ${
        isDBGPlan && !isUPEdit ? styles.dbgWrapperForm : ''
      }`}
    >
      {isRenewalStarted && (isUPEdit || isDBGPlan) && (
        <FixedAlertMessage
          type={'warning'}
          message={RENEWAL_COMMON_WARNING_MESSAGE_BASIC_INFO}
        />
      )}
      {tierChangeWarning && showTierChangeWarning && (
        <AlertMessage
          type="warning"
          message={
            <div>
              Removing HRA eligibility will unlink the associated HRA plan.
              <br />
              <a
                className={`text-form-label ${styles.planRedirect}`}
                target="_blank"
                rel="noreferrer"
                href={`/brokers/${brokerId}/employers/${employerId}/tax-advantaged-accts/${plan?.hraPlanId}/overview`}
              >
                {' '}
                View Plan
              </a>
            </div>
          }
          closeAlert={() => {
            setTierChangeWarning(false);
          }}
          wrapperClassName={styles.alertMessage}
        />
      )}
      {(requiredFieldError ||
        ((showWarning || showPlanYearWarning) && !isReviewProp)) &&
        isDBGPlan && (
          <AlertMessage
            type={alertMessage.type}
            message={alertMessage.message}
            closeAlert={() => {
              setRequiredFieldError(false);
              setShowWarning(false);
              setShowPlanYearWarning(false);
            }}
            wrapperClassName={styles.alertMessage}
          />
        )}
      <InputForm
        form={form}
        onValuesChange={onInputChange}
        size={isUPEdit || isDBGPlan ? 'small' : 'middle'}
      >
        <Row gutter={48}>
          <Col span={formItemColSpan}>
            <Form.Item
              name="planName"
              label="Plan Name*"
              labelCol={labelProps}
              className={`${styles.planField} ${
                isReviewHighlight && styles.highlightField
              }`}
              rules={[
                {
                  required: true,
                  message: requiredFieldError
                    ? 'Please specify a valid plan name'
                    : EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
            >
              <Input
                data-cy="planName"
                onBlur={trimPlanNameInput}
                maxLength={maxPlanNameSizeMDV}
                title={formData.planName}
              />
            </Form.Item>
          </Col>
          <Col span={formItemColSpan}>
            <Form.Item
              className={
                showPlanYearWarning && !isReviewProp
                  ? styles.planYearWrapperWarning
                  : styles.filterWrapper
              }
              label="Effective Dates*"
              labelCol={labelProps}
              name="planYear"
              rules={[{ required: true, message: 'Please select Plan Year' }]}
            >
              <SelectOptions
                defaultValue={reviewNewPlanYear}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                onSelect={onPlanYearSelect}
                disabled={
                  (!isUPEdit && (isDBGPlan || dbgPlanYear != null)) ||
                  reviewNewPlanYear !== undefined
                }
                loading={inProgress}
              >
                {planYearsList &&
                  planYearsList
                    .filter(
                      (planYear) =>
                        planYear.id === plan?.planYearId || !planYear.previous
                    )
                    .map((planYear: PlanYear) => (
                      <Option value={planYear.id} key={planYear.id}>
                        {planYearDBG
                          ? planYearDBG.name +
                            ' ' +
                            getPlanYearRangeWithCurrent(planYearDBG)
                          : planYear.name +
                            ' ' +
                            getPlanYearRangeWithCurrent(planYear)}
                      </Option>
                    ))}
              </SelectOptions>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={formItemColSpan}>
            <Form.Item
              name={BasicInfoFields.BENEFIT_GROUPS}
              className={
                showWarning && !isReviewProp
                  ? styles.filterWrapperWarning
                  : styles.filterWrapper
              }
              label="Benefit Classes*"
              labelCol={labelProps}
              rules={[
                {
                  required: true,
                  message: 'Please select at least one benefit class',
                },
              ]}
            >
              <>
                <Input
                  hidden
                  value={
                    multiSelect.groups ? multiSelect.groups.join(', ') : ''
                  }
                />
                <Form.Item name={BasicInfoFields.BENEFIT_GROUPS}>
                  <BenefitClassMultiSelect
                    options={benefitClassesList}
                    onChange={onCheckboxSelection}
                    name="groups"
                    disabled={!isReviewProp && isEmpty(selectedPlanYear?.id)}
                    selectedItemValues={
                      multiSelect.groups ? multiSelect.groups : []
                    }
                    ref={groupsRef}
                  />
                </Form.Item>
              </>
            </Form.Item>
          </Col>
          <Col span={formItemColSpan}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please select a carrier',
                },
              ]}
              labelCol={labelProps}
              name={BasicInfoFields.BENEFIT_CARRIER}
              label="Carrier*"
              className={`${styles.carrierStyles}
                ${isSingleColumn && styles.fixOffset}
                ${
                  showWarning && !isReviewProp
                    ? styles.filterWrapperWarning
                    : styles.filterWrapper
                } ${isReviewHighlight ? styles.highlightField : ''}
              `}
            >
              <SelectOptions
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                loading={inProgress}
                showSearch
                filterOption={(input, option) =>
                  (option!.label as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase().trim())
                }
                wrapperClass={isReviewHighlight ? 'highlightField' : undefined}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    {!isReviewProp && (
                      <SelectAddMoreButton
                        onClick={() => {
                          !isErAdmin &&
                          appBootInfo?.individualSubType !==
                            IndividualSubTypes.BROKER_USER
                            ? setCarrierCreateTypeConfirmation({
                                show: true,
                                carrierType: '',
                              })
                            : setOpenCarrierModal(true);
                        }}
                        label="Add New Carrier"
                      />
                    )}
                  </>
                )}
                options={carriersList?.map((carrier: Carrier) => ({
                  label: carrier.name,
                  value: carrier.id,
                }))}
              ></SelectOptions>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={formItemColSpan}>
            <Form.Item
              name="groupId"
              label="Group ID / Policy #"
              labelCol={labelProps}
              className={styles.planField}
              rules={[
                {
                  whitespace: false,
                },
              ]}
            >
              <Input
                data-cy="groupId"
                onBlur={trimGroupIdInput}
                title={formData.groupId}
              />
            </Form.Item>
          </Col>
          <Col span={formItemColSpan}>
            <Form.Item
              name="planNetworkName"
              label="Plan Network"
              labelCol={labelProps}
              className={`${styles.planField} ${
                isReviewHighlight && styles.highlightField
              }`}
              rules={[
                {
                  whitespace: false,
                },
              ]}
            >
              <Input
                data-cy="planNetworkName"
                onBlur={trimPlanNetworkInput}
                title={formData.planNetworkName}
              />
            </Form.Item>
          </Col>
          {benefitCategory === BenefitCategory.VISION.value && (
            <Col span={formItemColSpan}>
              <Form.Item
                name={BasicInfoFields.PLAN_TYPE}
                label="Plan Type"
                labelCol={labelProps}
                className={`${styles.planField} ${
                  isReviewHighlight && styles.highlightField
                }`}
              >
                <Input
                  data-cy="type"
                  onBlur={trimPlanTypeInput}
                  title={formData.type}
                />
              </Form.Item>
            </Col>
          )}
          {benefitCategory === BenefitCategory.DENTAL.value && (
            <Col span={formItemColSpan}>
              <Form.Item
                name={BasicInfoFields.PLAN_TYPE}
                label="Plan Type"
                labelCol={labelProps}
              >
                <SelectOptions
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  options={DENTAL_PLAN_TYPE_OPTIONS}
                  allowClear
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row {...(isSingleColumn ? { style: { width: '100%' } } : {})}>
          <Col span={isSingleColumn ? 24 : undefined}>
            <Form.Item
              name={BasicInfoFields.HRA_COMPATIBLE}
              label="HRA Eligible"
              valuePropName="checked"
              className={styles.hsaEligibleWrapper}
              labelCol={labelProps}
            >
              <Checkbox
                onChange={(e) => {
                  if (
                    (benefitCategory === BenefitCategory.DENTAL.value &&
                      currentDentalPlan?.hraCompatible !== e.target?.checked) ||
                    (benefitCategory === BenefitCategory.VISION.value &&
                      currentVisionPlan?.hraCompatible !== e.target?.checked)
                  ) {
                    setTierChangeWarning(true);
                  } else {
                    setTierChangeWarning(false);
                  }

                  const currentPlan = isUPEdit ? plan : updatedPlan();

                  onChange({
                    ...currentPlan,
                    hraCompatible: e.target.checked,
                  });
                }}
              >
                Yes
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={formItemColSpan}>
            <Form.Item
              className={
                isReviewHighlight
                  ? styles.fundingTypeWrapper
                  : styles.filterWrapper
              }
              name="fundingType"
              label="Funding Type*"
              labelCol={labelProps}
              rules={[
                {
                  required: true,
                  message: requiredFieldError
                    ? 'Please select a funding type'
                    : EMPTY_MESSAGE,
                },
              ]}
            >
              <SelectOptions
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                options={FUNDING_TYPES_LIST}
              />
            </Form.Item>
          </Col>
          <Col span={formItemColSpan}>
            <Form.Item
              hidden={FundingType.SELF_FUNDED !== fundingType}
              name="administrationFee"
              label={
                <div className={styles.adminFeeColumn}>
                  Admin Fee (PEPM)<sup> 1</sup>
                </div>
              }
              labelCol={labelProps}
            >
              <NumberFormatInput
                className={
                  isReviewHighlight
                    ? styles.highlightAdminFee
                    : styles.planField
                }
                prefix="$"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
              />
            </Form.Item>
          </Col>
        </Row>
      </InputForm>
      {FundingType.SELF_FUNDED === fundingType && (
        <div
          className={`${styles.sfcText} ${
            isUPEdit || isDBGPlan ? styles.sfcEditMode : ''
          }`}
        >
          <span>1</span>
          This data is shared by all self-funded plans in this plan year with
          the same carrier.
        </div>
      )}

      <ConfirmationWithThreeButtons
        visible={carrierCreateTypeConfirmation.show}
        width={464}
        centered
        title={ADD_NEW_CARRIER_MODAL_CONSTANTS.title}
        firstButtonText={ADD_NEW_CARRIER_MODAL_CONSTANTS.firstButtonLabel}
        firstButtonAction={() => {
          setCarrierCreateTypeConfirmation({
            show: false,
            carrierType: CarrierType.BROKER,
          });
          setOpenCarrierModal(true);
        }}
        secondButtonText={ADD_NEW_CARRIER_MODAL_CONSTANTS.secondButtonLabel}
        secondButtonAction={() => {
          setCarrierCreateTypeConfirmation({
            show: false,
            carrierType: CarrierType.EMPLOYER,
          });
          setOpenCarrierModal(true);
        }}
        onCancel={() => {
          setCarrierCreateTypeConfirmation({
            show: false,
            carrierType: '',
          });
        }}
      >
        {addNewCarrierModalDescription(empInfo?.name!)}
      </ConfirmationWithThreeButtons>
      <AddNewCarrierModal
        isOpen={openCarrierModal}
        setIsOpen={setOpenCarrierModal}
        selectedCarrierType={
          isErAdmin ||
          appBootInfo?.individualSubType === IndividualSubTypes.BROKER_USER
            ? CarrierType.EMPLOYER
            : carrierCreateTypeConfirmation.carrierType
        }
        benefitType={benefitCategory}
        setCarrierToForm={handleSetCarrierToForm}
        brokerId={brokerId!}
        employerId={employerId!}
      />
    </div>
  );
});
PlanBasicInfoForm.displayName = 'PlanBasicInfoForm';
export default PlanBasicInfoForm;
