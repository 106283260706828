import { FC } from 'react';

import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import { EMPLOYER_MEMBER_RESTRICTED } from 'constants/commonConstants';

import styles from './overviewHeader.module.less';

type OverviewHeaderProps = {
  title?: string;
  onEditClick?: () => void;
  disabled?: boolean;
};

const OverviewHeader: FC<OverviewHeaderProps> = (
  props: OverviewHeaderProps
) => {
  const { title, onEditClick, disabled = false } = props;

  const headerEditButton = (
    <LinkButton
      disabled={disabled}
      enableDisableStyle={true}
      onClick={onEditClick}
    >
      Edit
    </LinkButton>
  );

  const permittedHeaderEditButton = usePermitByUserType(
    headerEditButton,
    EMPLOYER_MEMBER_RESTRICTED
  );

  return (
    <div className={styles.headerWrapper}>
      <div className="title-overview">{title}</div>
      {permittedHeaderEditButton}
    </div>
  );
};

export default OverviewHeader;
