import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { sortBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { Row, Col } from 'antd';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import PlanServiceObject from 'model/PlanService';
import { setDisplayedDentalServices } from 'modules/plans/slices/dentalPlanSlice';
import { setDisplayedVisionServices } from 'modules/plans/slices/visionPlanSlice';
import PanelSection from 'modules/plans/enums/PanelSection';
import CustomPlan from 'model/plans/CustomPlan';
import { DentalPlan } from 'model/plans/DentalPlan';
import ServiceRow from 'modules/plans/components/ServiceRow/ServiceRow';
import { VisionPlan } from 'model/plans/VisionPlan';
import {
  BenefitCategory,
  FEATURE_KEYS,
  maxDentalServiceValue,
  maxMedicalServiceValue,
  maxVisionServiceValue,
} from 'constants/commonConstants';
import {
  coverageOption,
  BenefitCovered,
  CostType,
  DENTAL_ADVANCED_PROFILE_PARAMETERS,
  VISION_ADVANCED_PROFILE_PARAMETERS,
} from 'modules/plans/constants';

import { getNonDefaultExistingAndCustomOrder } from 'modules/plans/utils';
import useGetUserFeatureStatus from 'hooks/useGetUserFeatureStatus';
import styles from './services.module.less';

type ServiceProps = {
  plan: DentalPlan | VisionPlan;
  onServiceRemove?: (index: number) => void;
  onServiceAdded?: (planServiceObject: PlanServiceObject[]) => void;
  onChange: Function;
  planType?: string;
  isUpEdit?: boolean;
  isDBGView?: boolean;
  isTouched: boolean;
  isSmall?: boolean;
  isAdvancedEmployerProfile?: boolean;
};

const Services = forwardRef((props: ServiceProps, ref) => {
  const {
    onChange,
    plan,
    planType,
    isDBGView = false,
    isTouched,
    isUpEdit,
    isSmall,
    isAdvancedEmployerProfile = false,
  } = props;
  const plansStore = useAppSelector((state) => state.plan.plans);
  const dentalPlanStore = useAppSelector((state) => state.plan.dentalPlan);
  const visionPlansStore = useAppSelector((state) => state.plan.visionPlan);

  const [serviceTableData, setServiceTableData] = useState<CustomPlan[]>([]);
  const dispatch = useAppDispatch();

  const { displayedServices } = plansStore;
  const { dentalPlan, displayedDentalServices } = dentalPlanStore;
  const { visionPlan, displayedVisionServices } = visionPlansStore;

  const [isSelected, setIsSelected] = useState<boolean | null>(null);
  const [selectText, setSelectText] = useState<string>('(Select all)');
  const [hasCostType, setHasCostType] = useState<boolean>(false);

  const { employerId, brokerId } = useNavContext();

  useImperativeHandle(ref, () => ({
    validate: () => ({ isComplete: getIsComplete(), isValid: getIsValid() }),
    reset() {
      setServiceTableData([]);
    },
  }));

  const getMaxServiceValue = (): number => {
    switch (planType) {
      case BenefitCategory.MEDICAL.value:
        return maxMedicalServiceValue;
      case BenefitCategory.DENTAL.value:
        return maxDentalServiceValue;
      default:
        return maxVisionServiceValue;
    }
  };

  /**
   * @return {boolean} true if valid
   */
  // eslint-disable-next-line
  const getIsValid = (): boolean => {
    const costType = ['inNetwork', 'outOfNetwork'] as const;
    const isInvalid =
      plan.customServices?.some(({ serviceValue }) =>
        costType.some(
          (type) =>
            parseFloat(serviceValue?.[type]?.copay) >= getMaxServiceValue()
        )
      ) ?? false;

    return !isInvalid;
  };

  /**
   * @return {boolean} true if complete
   */
  const getIsComplete = (): boolean => {
    if (plan.customServices?.length !== getDisplayedPlanServices().length)
      return false;
    return plan.customServices.every((service) => {
      const { inNetwork, outOfNetwork } = service.serviceValue;
      return [inNetwork, outOfNetwork].every(
        ({ costSharingPolicy, coinsurance, copay, info, copayFrequency }) => {
          if (isEmpty(costSharingPolicy)) return false; // In the case where no option is chosen at all
          if (costSharingPolicy === CostType.NOT_COVERED) return true;
          if (costSharingPolicy === CostType.NOT_APPLICABLE) return true; // This doesnt even exist in the dropdown

          const valuesToCheck = [];
          if (costSharingPolicy === CostType.OTHER) valuesToCheck.push(info);
          if (costSharingPolicy === CostType.COPAY)
            valuesToCheck.push(copay, copayFrequency);
          if (costSharingPolicy === CostType.COINSURANCE)
            valuesToCheck.push(coinsurance);
          return !valuesToCheck.some(isEmpty);
        }
      );
    });
  };

  const getDisplayedPlanServices = useCallback(() => {
    if (planType === BenefitCategory.MEDICAL.value) {
      return displayedServices;
    } else if (planType === BenefitCategory.DENTAL.value) {
      return displayedDentalServices;
    } else {
      return displayedVisionServices;
    }
  }, [
    planType,
    displayedServices,
    displayedDentalServices,
    displayedVisionServices,
  ]);

  const changeAllPTD = (selectAll: boolean) => {
    let updatedPlan = cloneDeep(plan);
    if (planType === BenefitCategory.DENTAL.value && isDBGView && ref) {
      updatedPlan = cloneDeep(dentalPlan);
    }
    updatedPlan.customServices?.map((service: CustomPlan) => {
      if (!isEmpty(service.serviceValue.inNetwork.costSharingPolicy)) {
        service.serviceValue.inNetwork.copayPriorToDeductible = selectAll
          ? 'YES'
          : 'NO';
      }
      if (!isEmpty(service.serviceValue.outOfNetwork.costSharingPolicy)) {
        service.serviceValue.outOfNetwork.copayPriorToDeductible = selectAll
          ? 'YES'
          : 'NO';
      }
    });
    onChange(updatedPlan);
  };

  useEffect(() => {
    const currentPlan = planType
      ? planType === BenefitCategory.DENTAL.value
        ? isUpEdit
          ? plan
          : dentalPlan
        : isUpEdit
        ? plan
        : visionPlan
      : plan;
    const customServices = getDisplayedPlanServices()
      // todo: uncomment after adding isSassSupported flag in BE
      .map((service: PlanServiceObject) => {
        const customService =
          currentPlan?.customServices?.find(
            (s: CustomPlan) =>
              s.benefitCode.shortName === service.shortName &&
              s.benefitCode.code === service.code
          ) || new CustomPlan();
        const sCopy = JSON.parse(JSON.stringify(customService));
        sCopy.benefitCode = service;
        sCopy.isDefault = service.isDefault;
        return sCopy;
      });
    if (isDBGView) {
      currentPlan?.customServices?.forEach((service: any) => {
        if (
          !(service in customServices) &&
          isEmpty(
            customServices.filter(
              (item) => item.benefitCode.code === service.benefitCode.code
            )
          )
        ) {
          customServices.push(service);
        }
      });
    }

    const newCustomServices = cloneDeep(customServices);
    newCustomServices.forEach((service) => {
      if (
        service.serviceValue.inNetwork.benefitCovered === BenefitCovered.NO &&
        service.serviceValue.inNetwork.costSharingPolicy !==
          BenefitCovered.CLEAR
      ) {
        service.serviceValue.inNetwork.costSharingPolicy =
          coverageOption.NOT_COVERED;
      }
      if (
        service.serviceValue.outOfNetwork.benefitCovered ===
          BenefitCovered.NO &&
        service.serviceValue.outOfNetwork.costSharingPolicy !==
          BenefitCovered.CLEAR
      ) {
        service.serviceValue.outOfNetwork.costSharingPolicy =
          coverageOption.NOT_COVERED;
      }
    });

    const { nonDefaultExistingServices, nonDefaultCustomServices } =
      getNonDefaultExistingAndCustomOrder(newCustomServices);
    setServiceTableData([
      ...newCustomServices.filter((service) => service.isDefault),
      ...sortBy(nonDefaultExistingServices, (service) => !service.added),
      ...sortBy(nonDefaultCustomServices, (service) => !service.added),
    ]);
  }, [
    getDisplayedPlanServices,
    plan,
    planType,
    dentalPlan,
    visionPlan,
    isDBGView,
    isUpEdit,
  ]);

  const onServiceRemove = (index: number) => {
    const updatedServices = getDisplayedPlanServices().filter(
      (_, i) => i !== index
    );
    if (planType === BenefitCategory.DENTAL.value) {
      dispatch(setDisplayedDentalServices(updatedServices));
    } else {
      dispatch(setDisplayedVisionServices(updatedServices));
    }
  };

  const isAdvancedProfile = useGetUserFeatureStatus(
    brokerId,
    employerId,
    FEATURE_KEYS.ADVANCED_PROFILE,
    false
  );

  const getServices = () =>
    serviceTableData
      .filter((service: CustomPlan) => {
        if (
          !(
            isAdvancedEmployerProfile ||
            (isAdvancedProfile && brokerId !== undefined)
          ) &&
          BenefitCategory.DENTAL.value === planType
        ) {
          return !DENTAL_ADVANCED_PROFILE_PARAMETERS.includes(
            service.benefitCode.shortName
          );
        }
        if (
          !(
            isAdvancedEmployerProfile ||
            (isAdvancedProfile && brokerId !== undefined)
          ) &&
          BenefitCategory.VISION.value === planType
        ) {
          return !VISION_ADVANCED_PROFILE_PARAMETERS.includes(
            service.benefitCode.shortName
          );
        }
        return true;
      })
      .map((serviceObject: CustomPlan, index) => {
        const displayedServiceIndex = getDisplayedPlanServices().findIndex(
          (service) => service.shortName === serviceObject.benefitCode.shortName
        );

        return (
          <ServiceRow
            isSmall={isSmall}
            index={displayedServiceIndex}
            onServiceRemove={onServiceRemove}
            customService={serviceObject}
            formType={PanelSection.SERVICES}
            title={serviceObject?.benefitCode?.shortName}
            key={index}
            onChange={onChange}
            planType={planType}
            plan={
              planType && !isUpEdit
                ? planType === BenefitCategory.DENTAL.value
                  ? dentalPlan
                  : visionPlan
                : plan
            }
            customServices={
              planType && !isUpEdit
                ? planType === BenefitCategory.DENTAL.value
                  ? dentalPlan.customServices || []
                  : visionPlan.customServices || []
                : plan.customServices || []
            }
            hsaCompatible={false}
            isTouched={isTouched}
            isDefault={serviceObject.isDefault || false}
            selectAll={isSelected}
            onSelectAllChange={(
              selectedText: string | ((prevState: string) => string)
            ) => setSelectText(selectedText)}
            isUpEdit={isUpEdit}
            setHasCostType={setHasCostType}
          />
        );
      });

  useEffect(() => {
    if (planType === BenefitCategory.DENTAL.value) {
      const t1 = serviceTableData.filter(
        (service) =>
          !isEmpty(service.serviceValue.inNetwork.costSharingPolicy) &&
          service.serviceValue.inNetwork.costSharingPolicy !==
            coverageOption.NOT_COVERED
      );
      const t2 = serviceTableData.filter(
        (service) =>
          !isEmpty(service.serviceValue.outOfNetwork.costSharingPolicy) &&
          service.serviceValue.outOfNetwork.costSharingPolicy !==
            coverageOption.NOT_COVERED
      );
      if (
        t1.every(
          (service) =>
            service.serviceValue.inNetwork.copayPriorToDeductible === 'YES'
        ) &&
        t2.every(
          (service) =>
            service.serviceValue.outOfNetwork.copayPriorToDeductible === 'YES'
        )
      ) {
        setSelectText('(Deselect All)');
      } else {
        setSelectText('(Select All)');
      }
    }
  }, [planType, serviceTableData]);

  return (
    <div className={`${styles.planServicesWrapper} `}>
      <div className="text service-table-col-header">
        <Row>
          {isUpEdit && hasCostType ? (
            <>
              <Col span={7}></Col>
              <Col span={5}>
                <div className={styles.coulmnWrapperFlexWrapper}>Cost Type</div>
              </Col>
              <Col span={4}>
                <div className={styles.coulmnWrapperFlexWrapper}>Amount</div>
              </Col>
              <Col span={5}>
                <div className={styles.coulmnWrapperFlexWrapper}>Frequency</div>
              </Col>
            </>
          ) : (
            <>
              <Col span={7}></Col>
              <Col span={13}>
                <div className={styles.coulmnWrapperFlexWrapper}>
                  Amount/Frequency
                </div>
              </Col>
            </>
          )}
          {planType !== BenefitCategory.VISION.value ? (
            <Col span={4} className={styles.selectAllWrapper}>
              Prior to Deductible <br />
              <a
                onClick={() => {
                  if (isSelected === null) {
                    if (selectText === '(Select All)') {
                      setIsSelected(true);
                      changeAllPTD(true);
                    }
                    if (selectText === '(Deselect All)') {
                      setIsSelected(false);
                      changeAllPTD(false);
                    }
                  } else {
                    if (selectText === '(Deselect All)') {
                      setSelectText('(Select All)');
                      setIsSelected(false);
                      changeAllPTD(false);
                    } else {
                      setSelectText('(Deselect All)');
                      setIsSelected(true);
                      changeAllPTD(true);
                    }
                  }
                }}
              >
                <div className={styles.selectAllText}>{selectText}</div>
              </a>
            </Col>
          ) : (
            ''
          )}
        </Row>
      </div>
      {getServices()}
    </div>
  );
});

Services.displayName = 'Services';
export default Services;
