import React from 'react';
import { OFFER_TYPES } from 'modules/renewals/constants/renewalsConstants';
import styles from './offerTypeLabel.module.less';

type Props = {
  type?: string | null;
};

const OfferTypeLabel = (props: Props) => {
  switch (props.type) {
    case OFFER_TYPES.PRIMARY: {
      return (
        <div className={styles.labelWrapper}>
          <span className={styles.dot} /> PRIMARY
        </div>
      );
    }
    case OFFER_TYPES.SUPPLEMENTARY: {
      return (
        <div className={styles.labelWrapper}>
          <span className={styles.borderDot} /> SUPPLEMENTARY
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

export default OfferTypeLabel;
