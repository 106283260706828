import { ChangeEvent, FC, useState } from 'react';
import { Popover, Row, Switch } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { isNull } from 'lodash';

import DataTable from 'components/DataTable/DataTable';
import {
  DataColumn,
  createRenderColumns,
} from 'components/DataTable/DataColumn';
import TablePagination from 'model/TablePagination';
import SearchBar from 'components/SearchBar/SearchBar';
import ConfigureSsoModal from 'modules/sso/components/ConfigureSsoModal/ConfigureSsoModal';

import { toStringToProperCase } from 'util/stringUtil';

import styles from 'modules/sso/components/BrokerSsoTable/BrokerSsoTable.module.less';
import Employer from 'model/Employer';
import { IndividualSubTypes } from 'constants/commonConstants';
import { useAppSelector } from 'hooks/redux';

type BrokerSsoTableProps = {
  paginationConfig: TablePagination;
  setPaginationConfig: Function;
  data: Employer[];
  totalData?: number;
  loading?: boolean;
  setSearchText?: Function;
  searchText?: string;
  onUpdate: Function;
};

const BrokerSsoTable: FC<BrokerSsoTableProps> = ({
  paginationConfig,
  setPaginationConfig,
  data,
  totalData = 0,
  loading,
  setSearchText,
  searchText = '',
  onUpdate,
}) => {
  const [isEnableSsoModalOpen, setIsEnableSsoModalOpen] =
    useState<boolean>(false);
  const [selectedSsoDetails, setSelectedSsoDetails] = useState<any>({});
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);

  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  const getSorter = () => (a: any, b: any) => 0;

  const handleEmployerSsoManageClick = (record: any) => {
    setSelectedSsoDetails({
      employerId: record?.id,
      name: record?.name,
      metadataUrl: record?.ssoConfig?.metadataUrl,
      vendor: record?.ssoConfig?.vendor,
    });
    setIsEnableSsoModalOpen(true);
  };

  const handleEmployerSsoToggleClick = (record: any) => {
    setSelectedSsoDetails({
      employerId: record?.id,
      name: record?.name,
    });
    setIsEnableSsoModalOpen(true);
  };

  const getData = (dataFilters: TablePagination) => {
    const { sorterInfo, paginationIndex, filterInfo, filters } = dataFilters;
    if (sorterInfo) {
      const paginationConfigData = {
        sorterInfo,
        paginationIndex,
        filterInfo: {
          limit: filterInfo.limit,
          offset: filterInfo.offset,
          searchText,
        },
        filters,
      };
      setPaginationConfig(paginationConfigData);
    } else {
      const paginationConfigData = {
        sorterInfo: {
          columnKey: 'name',
          field: 'name',
          order: 'ascend',
        } as SorterResult<any>,
        paginationIndex,
        filterInfo: {
          limit: filterInfo.limit,
          offset: filterInfo.offset,
          searchText,
        },
        filters,
      } as TablePagination;
      setPaginationConfig(paginationConfigData);
    }
  };

  const getSsoVendorCell = (record: any): React.ReactElement => {
    return isNull(record?.ssoConfig) ? (
      <div>
        <b>-</b>
      </div>
    ) : (
      <div>
        {toStringToProperCase(record?.ssoConfig?.vendor)} (
        <span
          className={styles.manageSsoBtn}
          onClick={() => handleEmployerSsoManageClick(record)}
        >
          manage
        </span>
        )
      </div>
    );
  };

  const restrictedUserTableColumns: DataColumn[] = [
    {
      title: 'EMPLOYER',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      sorter: getSorter(),
      defaultSortOrder: 'ascend',
      render: (data) => <div className={styles.employerName}>{data}</div>,
    },
    {
      title: 'SSO VENDOR',
      dataIndex: 'ssoConfig',
      key: 'ssoConfig',
      width: '20%',
      render: (_data, record) => getSsoVendorCell(record),
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      width: '10%',
      align: 'center',
      render: (_data, record) => (
        <div>
          <div className={styles.lastUpdatedTime}>
            {dayjs(record.lastUpdated).format('lll')}
          </div>
          <div className={styles.lastUpdatedBy}>
            {`by ${record?.lastUpdatedByName}`}
          </div>
        </div>
      ),
    },
  ];

  const tableColumns: DataColumn[] =
    appBootInfo?.individualSubType === IndividualSubTypes.BROKER_ADMIN
      ? restrictedUserTableColumns
      : [
          ...restrictedUserTableColumns,
          {
            title: 'ENABLED',
            dataIndex: 'ssoConfig',
            key: 'ssoConfig',
            width: '5%',
            align: 'center',
            render: (data, record) => (
              <div className={styles.ssoToggleSwitchCell}>
                <Popover
                  overlayClassName="popoverInner recommendedtooltip"
                  trigger={!isNull(data) ? 'hover' : []}
                  content={'Option locked'}
                >
                  <Switch
                    checked={!isNull(data)}
                    onClick={() => handleEmployerSsoToggleClick(record)}
                    disabled={!isNull(data)}
                  />
                </Popover>
              </div>
            ),
          },
        ];

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearchText && setSearchText(searchText);
    const config = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText },
    };
    setPaginationConfig(config);
  };

  return (
    <>
      <div>
        <Row
          justify={'start'}
          align={'middle'}
          className={styles.searchRowWrapper}
        >
          <SearchBar
            value={searchText}
            placeholder="Search Employers"
            isIssueSearch={true}
            onChange={handleSearch}
            isIconEndAligned
          />
        </Row>
        <DataTable
          columns={createRenderColumns(tableColumns)}
          data={data}
          pagination={true}
          getData={getData}
          total={totalData?.toString()}
          pageSize={limit}
          loading={loading}
        />
      </div>
      <ConfigureSsoModal
        isOpen={isEnableSsoModalOpen}
        onCancel={() => setIsEnableSsoModalOpen(false)}
        onUpdate={onUpdate}
        data={selectedSsoDetails}
      />
    </>
  );
};

export default BrokerSsoTable;
