import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { cloneDeep, isEmpty, toLower } from 'lodash';
import {
  Col,
  Empty,
  Form,
  Input,
  Modal,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Spin,
  Switch,
} from 'antd';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import SearchBar from 'components/SearchBar/SearchBar';
import {
  useGetLocationsQuery,
  useUpdateLocationFeatureStatusMutation,
} from 'api/featureControl';
import { ReactComponent as UsersNotSelected } from 'assets/images/users-rfp.svg';
import { ReactComponent as UsersSelected } from 'assets/images/users-rfp-selected.svg';
import { ReactComponent as NoDataIcon } from 'assets/images/no-data-icons.svg';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useStateCallback } from 'hooks/updateState';
import { LocationType } from 'modules/plans/enums/CommonTypes';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import {
  SELF_REVIEW_MESSAGE,
  CONFIRM_TOGGLE_TEXT,
} from 'constants/commonConstants';
import { isNullOrUndefined } from 'modules/plans/utils';
import { handleNumericKeyPress } from 'util/commonUtil';
import { ReactComponent as AlertWarning } from 'assets/images/alert-warning-outline.svg';
import styles from './locationBasedFeature.module.less';

type LocationBasedFeatureModalProps = {
  visible: boolean;
  onCancel: () => void;
  onSave: () => void;
  title?: string;
  isRfp?: boolean;
  featureType: string;
  refetchBrokers: Function;
  brokerName?: string;
  organizationId?: string;
  isSelfReview: boolean;
};

const LocationBasedFeatureModal: FC<LocationBasedFeatureModalProps> = (
  props
) => {
  const {
    visible,
    onCancel,
    onSave,
    title,
    isRfp,
    featureType,
    refetchBrokers,
    brokerName,
    organizationId,
    isSelfReview,
  } = props;
  const [updateLocation, { isLoading }] =
    useUpdateLocationFeatureStatusMutation({});
  const [searchText, setSearchText] = useStateCallback('');
  const [radioValue, setRadioValue] = useState<boolean | undefined>(undefined);
  const [manageRfp, setManageRfp] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const [userCount, setUserCount] = useState<number | null>(0);
  const [brokerAdminUsersCount, setBrokerAdminUsersCount] = useState<number>(0);
  const [isDisplayConfigModal, setIsDisplayConfigModal] =
    useState<boolean>(false);
  const [locations, setLocations] = useState<LocationType[]>([]);

  const [userConfirmText, setUserConfirmText] = useState<string>('');
  const [rfpLocation, setRfpLocation] = useState<LocationType | null>(null);
  const [form] = Form.useForm();
  const [validateRadioBtn, setValidateRadioBtn] = useState<boolean>(true);

  const {
    data: response = {},
    isFetching,
    refetch,
  } = useGetLocationsQuery({
    organizationId: organizationId!,
    key: featureType ?? '',
    query: searchText,
  });

  const onSubmit = async () => {
    let valdiateForm = true;

    if (!radioValue) {
      valdiateForm = await valdiateUserCount();
    }

    if (!valdiateForm) {
      return;
    }

    const isBrokerRfp = !radioValue;
    const rfpBrokerAdminCount = radioValue ? null : userCount;

    const locationStatusList =
      locations?.map((location) => ({
        locationId: location.id,
        enabled: location.enabled,
        rfpLocationCount: radioValue ? location?.rfpLocationCount : 0,
      })) ?? [];

    const object = {
      locationStatusList,
      isBrokerRfp,
      isLocationRfp: !isBrokerRfp,
      rfpLocationCount: rfpBrokerAdminCount,
    };

    updateLocation({
      data: object,
      key: featureType,
      organizationId,
    }).then(() => {
      onSave();
      refetch();
      refetchBrokers();
    });
  };

  const markAllLocationsAsVisible = (locations?: LocationType[]) => {
    setLocations(
      (locations === undefined ? response?.locationFeatures : locations)?.map(
        (location: LocationType) => {
          return { ...location, isVisible: true };
        }
      ) ?? []
    );
  };

  useEffect(() => {
    if (visible) {
      markAllLocationsAsVisible();
      refetch();
    }
    // Disabling warning because only doing the fetching once the modal is opened
    // eslint-disable-next-line
  }, [visible]);

  useEffect(() => {
    const isBrokerRfp = response?.isBrokerRfp;
    const isLocationRfp = response?.isLocationRfp;
    setRadioValue(isBrokerRfp ? false : isLocationRfp ? true : undefined);
    markAllLocationsAsVisible(response?.locationFeatures);
    if (!isNullOrUndefined(response?.rfpLocationCount)) {
      setUserCount(response?.rfpLocationCount);
      form.setFieldsValue({ userCount: response?.rfpLocationCount });
    } else {
      setUserCount(null);
    }
    return () => {
      setLocations([]);
    };
    // Disabling warnings because when unmounting the component, it should not refetch the data but clear locations array
    // eslint-disable-next-line
  }, [
    response?.locationFeatures,
    response?.rfpLocationCount,
    response?.isBrokerRfp,
    response?.isLocationRfp,
    response?.rfpEnabledBACount,
  ]);

  const areAllLocationsEnabled = () => {
    return locations!.every((location) => location.enabled);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    if (isEmpty(_searchText)) {
      markAllLocationsAsVisible(locations);
    } else {
      const updatedLocation = locations.map((location: LocationType) => {
        location.isVisible = location.locationName
          .toLowerCase()
          .includes(_searchText.toLowerCase());
        return location;
      });
      setLocations(updatedLocation);
    }
  };

  const switchOnChange = (id: string) => {
    const updatedLocations = locations.map((location) => {
      if (location.id === id) {
        return { ...location, enabled: !location.enabled };
      }
      return location;
    });
    setLocations(updatedLocations);
  };

  const toggleLocationSelection = () => {
    const locationData = cloneDeep(locations ?? []);

    locationData.map((location: LocationType) => {
      location.enabled = !areAllLocationsEnabled();
    });

    setLocations(locationData);
  };

  const getEnabledCount = () => {
    let count = 0;

    locations?.map((location: LocationType) => {
      if (location.enabled) {
        count++;
      }
    });

    return count;
  };

  const onChange = (e: RadioChangeEvent) => {
    setValidateRadioBtn(true);
    setRadioValue(e.target.value);
    if (!radioValue && radioValue !== undefined) {
      form.resetFields(['userCount']);
    } else {
      form.setFieldsValue({ userCount: userCount });
    }
  };

  const hasAnyChangesBeenDone = (): boolean => {
    return locations.some((location) => {
      const originalLocation = response?.locationFeatures?.find(
        (item: LocationType) => item.id === location.id
      );

      if (isRfp) {
        return (
          originalLocation?.enabled !== location.enabled ||
          originalLocation?.rfpLocationCount !== location.rfpLocationCount ||
          response?.rfpLocationCount !== userCount ||
          response?.isBrokerRfp !== !radioValue
        );
      }
      return originalLocation?.enabled !== location.enabled;
    });
  };

  const getInputClassName = () => {
    return !isEmpty(userConfirmText) && userConfirmText !== CONFIRM_TOGGLE_TEXT
      ? styles.disableTextInputError
      : styles.adminUsersText;
  };

  const valdiateUserCount = () => {
    return form.validateFields(['userCount']);
  };

  const addUserCountToLocation = () => {
    const updatedLocations = locations.map((location) => {
      if (location.id === rfpLocation?.id) {
        return { ...location, rfpLocationCount: brokerAdminUsersCount };
      }
      return location;
    });
    setLocations(updatedLocations);
  };

  const validateManageRfp = async () => {
    return form.validateFields(['brokerAdminUsers']);
  };

  const manageRfpOnSave = async () => {
    const isValid = await validateManageRfp();
    if (isValid) {
      addUserCountToLocation();
      setBrokerAdminUsersCount(brokerAdminUsersCount);
      setManageRfp(false);
    }
  };

  const saveConfig = async () => {
    if (isRfp && !validateRadioButtons()) return;

    if (isRfp && !radioValue && !(await valdiateUserCount())) return;

    if (!hasAnyChangesBeenDone()) {
      onSave();
      setSearchText('');
    } else {
      setIsDisplayConfigModal(true);
    }
  };

  const validateRadioButtons = () => {
    if (isNullOrUndefined(radioValue)) {
      setValidateRadioBtn(false);
      return false;
    } else {
      setValidateRadioBtn(true);
      return true;
    }
  };

  const getRadioButtonStyles = () => {
    return validateRadioBtn ? styles.radioGroup : styles.radioGroupError;
  };

  const setUserCountForLocation = (location: LocationType) => {
    setBrokerAdminUsersCount(location?.rfpLocationCount ?? 0);
    setSelectedLocation(location?.locationName);
    setManageRfp(true);
    setRfpLocation(location);
    form.setFieldsValue({
      brokerAdminUsers: location?.rfpLocationCount ?? 0,
    });
  };

  const cancelSaveCongif = () => {
    const isBrokerRfp = response?.isBrokerRfp;
    const isLocationRfp = response?.isLocationRfp;
    if (!isNullOrUndefined(isBrokerRfp) && !isNullOrUndefined(isLocationRfp)) {
      setRadioValue(isBrokerRfp ? false : isLocationRfp ? true : undefined);
    }
    setUserCount(response?.rfpLocationCount);
    form.setFieldsValue({ userCount: response?.rfpLocationCount });
    onCancel();
    setSearchText('');
  };

  const checkLocationVisibility = useMemo(() => {
    return locations.every((location) => location.isVisible === false);
  }, [locations]);

  return (
    <Modal
      centered
      visible={visible}
      width={600}
      closeIcon={<></>}
      footer={false}
      className={styles.locationBasedFeatureModal}
      destroyOnClose={true}
    >
      <div className={styles.locationBasedFeatureModalWrapper}>
        <div className={styles.title}>Manage {title}</div>
        <div className={styles.subTitle}>{brokerName}</div>
        <div className={styles.description}>
          {isRfp
            ? 'This tool can be activated for the broker and all of their locations below. You have the option to set the number of users at the brokerage level or at the individual locations level.'
            : 'This tool can be activated for the broker and all of their locations below.'}
        </div>
        {isRfp && (
          <div className={styles.countLocationArea}>
            <Radio.Group
              className={getRadioButtonStyles()}
              onChange={onChange}
              value={radioValue}
            >
              <Row gutter={[5, 5]} align="middle">
                <Col>
                  <Radio value={false}>
                    <b>Set the user count for the entire brokerage</b>
                  </Radio>
                </Col>
                <Col span={1}>
                  <Popover
                    content={'User Limit Exceed'}
                    placement="right"
                    overlayClassName="popoverInner"
                  >
                    {!radioValue &&
                      userCount &&
                      response?.rfpEnabledBACount >= userCount && (
                        <AlertWarning className={styles.warningIcon} />
                      )}
                  </Popover>
                </Col>
                <Col span={3} />
                <Col>
                  {radioValue === undefined || radioValue ? (
                    <UsersNotSelected />
                  ) : (
                    <UsersSelected />
                  )}
                </Col>
                <Col span={4}>
                  <Form form={form} className={styles.userCountForm}>
                    <Form.Item
                      name="userCount"
                      rules={[
                        {
                          required: true,
                          message: 'Required',
                        },
                      ]}
                    >
                      <Input
                        className={styles.locationUserCount}
                        disabled={radioValue !== undefined ? radioValue : true}
                        value={userCount || 0}
                        onKeyPress={handleNumericKeyPress}
                        onChange={(e) => {
                          setUserCount(Number(e.target.value));
                        }}
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Radio value={true}>
                  <b>Set the user count per location</b>
                </Radio>
              </Row>
            </Radio.Group>
          </div>
        )}
        <div className={styles.filterContainer}>
          <SearchBar
            placeholder="Search locations"
            onChange={handleSearch}
            isIconEndAligned={true}
            searchStyleClassName={styles.search}
          />
        </div>
        <div className={styles.deselectAll}>
          <span onClick={() => toggleLocationSelection()}>
            {areAllLocationsEnabled() ? 'De-Select All' : 'Select All'}
          </span>
        </div>
        {isFetching ? (
          <Row align="middle" justify="center">
            <Col>
              <div className={styles.loadingAreaDiv}>
                <Spin />
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <div className={styles.outlinedDiv}>
              {checkLocationVisibility && (
                <Empty description="" image={<NoDataIcon />}>
                  <div className={styles.noDataIconText}>No Data</div>
                </Empty>
              )}
              {locations
                .filter((location) => location.isVisible)
                .sort((a, b) =>
                  toLower(a.locationName).localeCompare(toLower(b.locationName))
                )
                ?.map((location: LocationType, index: number) => (
                  <Row
                    key={index}
                    className={styles.locationDiv}
                    gutter={[5, 5]}
                  >
                    <div className={styles.locationName}>
                      <Col>
                        {location?.locationName.length > 50 ? (
                          <>
                            {location?.locationName.slice(0, 50)}
                            <br />
                            {location?.locationName.slice(50)}
                          </>
                        ) : (
                          location?.locationName
                        )}{' '}
                        {radioValue && <b>-</b>}{' '}
                      </Col>
                      {radioValue && (
                        <Col>
                          {location?.enabled ? (
                            <div
                              className={styles.userRfpLocation}
                              onClick={() => setUserCountForLocation(location)}
                            >
                              <UsersSelected />{' '}
                              {location?.rfpLocationCount
                                ? location?.rfpLocationCount
                                : 0}
                            </div>
                          ) : (
                            <div>
                              <UsersNotSelected />{' '}
                              <span className={styles.userNotSelectedCount}>
                                {location?.rfpLocationCount
                                  ? location?.rfpLocationCount
                                  : 0}
                              </span>
                            </div>
                          )}
                        </Col>
                      )}
                      <div>
                        <Popover
                          content={'User Limit Exceed'}
                          placement="right"
                          overlayClassName="popoverInner"
                        >
                          {radioValue &&
                            location?.enabled &&
                            location?.rfpEnabledBrokerAdminCount >=
                              location?.rfpLocationCount && <AlertWarning />}
                        </Popover>
                      </div>
                    </div>
                    <div className={styles.switchWrapper}>
                      <Switch
                        onChange={() => switchOnChange(location?.id)}
                        checked={location?.enabled}
                      />
                    </div>
                  </Row>
                ))}
            </div>
          </>
        )}
        <div className={styles.locationsFooter}>
          <div className={styles.countDiv}>
            {getEnabledCount()} of {(locations ?? []).length} Locations enabled
          </div>

          {locations!.length > 50 && (
            <span className={styles.employerLimitMessage}>
              Employer limit of 50 reached
            </span>
          )}
        </div>
        <div className={styles.footer}>
          <PageActionButton
            disabled={isDisplayConfigModal || isLoading}
            className={styles.saveBtn}
            type="primary"
            onClick={saveConfig}
          >
            Save Configuration
          </PageActionButton>
          <CancelButton onClick={() => cancelSaveCongif()}>Cancel</CancelButton>
        </div>
      </div>
      {manageRfp && (
        <ConfirmationDialog
          confirmBtnFullWidth
          centered
          visible={manageRfp}
          isCancelLink
          onConfirm={manageRfpOnSave}
          closeModal={() => {
            setManageRfp(false);
            setRfpLocation(null);
          }}
          title={
            <>
              Manage RFP
              <p className={styles.selectedLocationText}>
                {brokerName} - {selectedLocation}
              </p>
            </>
          }
          confirmText="Save"
          cancelText="Cancel"
          destroyOnClose
        >
          <b># Broker Admin Users *</b>
          <Form form={form}>
            <Form.Item
              name="brokerAdminUsers"
              rules={[
                {
                  required: true,
                  message: 'Required',
                },
              ]}
            >
              <Input
                className={styles.adminUsersText}
                value={rfpLocation?.rfpLocationCount}
                onKeyPress={handleNumericKeyPress}
                onChange={(e) => {
                  setBrokerAdminUsersCount(Number(e.target.value));
                }}
              />
            </Form.Item>
          </Form>
        </ConfirmationDialog>
      )}

      {isDisplayConfigModal && (
        <ConfirmationDialog
          confirmBtnFullWidth
          width={600}
          centered
          visible={isDisplayConfigModal}
          disableConfirmButton={userConfirmText !== CONFIRM_TOGGLE_TEXT}
          isCancelLink
          onConfirm={() => {
            if (userConfirmText === CONFIRM_TOGGLE_TEXT) {
              onSubmit();
              setUserConfirmText('');
              setIsDisplayConfigModal(false);
              setSearchText('');
            }
          }}
          closeModal={() => {
            setUserConfirmText('');
            setIsDisplayConfigModal(false);
            setSearchText('');
          }}
          title={
            <>
              Save {title} Configuration
              <p className={styles.selectedLocationText}>{brokerName}</p>
            </>
          }
          confirmText="Save"
          cancelText="Cancel"
          destroyOnClose
        >
          <p>Are you sure you want to save this configuration?</p>
          <b>Type “CONFIRM” in the box below to confirm</b>
          <Input
            value={userConfirmText}
            className={getInputClassName()}
            onChange={(e) => {
              setUserConfirmText(e.target.value);
            }}
          />
          {isSelfReview && (
            <p className={styles.selfReviewText}>{SELF_REVIEW_MESSAGE}</p>
          )}
          {!isEmpty(userConfirmText) &&
            userConfirmText !== CONFIRM_TOGGLE_TEXT && (
              <div className={styles.invalidInput}>Invalid Input</div>
            )}
        </ConfirmationDialog>
      )}
    </Modal>
  );
};

export default LocationBasedFeatureModal;
