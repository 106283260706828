import { FC, ReactElement } from 'react';
import { Dropdown } from 'antd';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';

import styles from './planSelectButton.module.less';

type PlanSelectButtonProps = {
  overlay: ReactElement;
  selectLabel: string;
  className?: string;
};

const PlanSelectButton: FC<PlanSelectButtonProps> = ({
  overlay,
  selectLabel,
  className = styles.dropdownButton,
}) => {
  return (
    <Dropdown
      overlay={overlay}
      trigger={['click']}
      overlayClassName="planDropdownMenu"
      getPopupContainer={(triggerNode) =>
        triggerNode.parentElement ? triggerNode.parentElement : triggerNode
      }
    >
      <PageActionButton
        type="primary"
        onClick={(e) => e.preventDefault()}
        className={className}
      >
        {selectLabel}
      </PageActionButton>
    </Dropdown>
  );
};

export default PlanSelectButton;
