class SFCPlanConfig {
  employerId?: string;
  planYearId?: string;
  benefitKind?: string;
  carrierId?: string;
  individualStopLoss?: string;
  individualAdministrationFee?: string;
  aggregatedStopLoss?: string;
  aggregatedAdministrationFee?: string;
  thirdPartyAdministrationFee?: string;
  otherFees?: string;
  administrationFee?: string | null;

  constructor() {
    this.employerId = '';
    this.planYearId = '';
    this.benefitKind = '';
  }
}

export default SFCPlanConfig;
