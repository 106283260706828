import React, { ReactElement } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from 'recharts';
import * as _ from 'lodash';

import { Empty } from 'antd';

import styles from './doughnutChart.module.less';

type Props = {
  innerRadius: number;
  outerRadius: number;
  data: { id: number; value: any; name: any }[];
  startAngle: number;
  endAngle: number;
  chartWidth?: number;
  chartHeight: number;
  customLabel?: any;
  colors: string[];
  tooltip: any;
  legendProp?: any;
  chartDimensions: any;
  tooltipHidden?: boolean;
  showLegend?: boolean;
  chartMargins?: {
    top: number;
    right: number;
    left: number;
    bottom: number;
  };
  dataKey: string;
  paddingAngle?: number;
  labelLine?: any;
  centerTitleLabel?: ReactElement;
};

const defaultColor = '#ddd';

const DoughnutChart = (props: Props) => {
  const {
    innerRadius,
    outerRadius,
    data,
    startAngle,
    endAngle,
    customLabel,
    colors,
    tooltip,
    legendProp,
    chartMargins = {
      top: -18,
      right: 30,
      left: 10,
      bottom: 5,
    },
    tooltipHidden = false,
    showLegend = false,
    dataKey,
    paddingAngle = 0,
    labelLine,
    centerTitleLabel,
  } = props;

  return (
    <div className={styles.doughnutChart}>
      {!_.isEmpty(data) && data.length > 0 ? (
        <ResponsiveContainer>
          <PieChart margin={{ ...chartMargins }}>
            <Pie
              dataKey={dataKey}
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              startAngle={startAngle}
              isAnimationActive={false}
              endAngle={endAngle}
              paddingAngle={paddingAngle}
              labelLine={labelLine}
              label={customLabel}
            >
              {data &&
                data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      colors ? colors[colors.length - 1 - index] : defaultColor
                    }
                  />
                ))}
              {centerTitleLabel && (
                <Label
                  width={30}
                  position="center"
                  content={centerTitleLabel}
                ></Label>
              )}
            </Pie>
            {showLegend && <Legend {...legendProp} />}
            {!tooltipHidden && (
              <Tooltip
                active={false}
                isAnimationActive={false}
                cursor={false}
                content={tooltip}
              />
            )}
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <div className={styles.placeHolder}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </div>
  );
};
export default DoughnutChart;
