import { FC, useEffect, useState } from 'react';
import { Row, Divider, Input } from 'antd';
import { ReactComponent as EditIcon } from 'assets/images/icon-pencil.svg';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import styles from './offerCardOverview.module.less';

type OfferCardOverviewProps = {
  headerText: React.ReactNode | string;
  value: React.ReactNode;
  footerElement?: React.ReactNode;
  className?: string;
  valueSizeMagnified?: boolean;
  isDtqOrPending?: boolean;
  editIcon?: boolean;
  setValue?: Function;
  stringValue?: string;
  saveEdited?: Function;
  isLoading?: boolean;
  editTitle?: string;
  editBody?: string;
  showEllipsis?: boolean;
};

const OfferCardOverview: FC<OfferCardOverviewProps> = (
  props: OfferCardOverviewProps
) => {
  const {
    headerText,
    value,
    footerElement,
    className,
    valueSizeMagnified = false,
    isDtqOrPending = false,
    editIcon = false,
    setValue,
    stringValue = '',
    saveEdited,
    isLoading = false,
    editTitle = '',
    editBody = '',
    showEllipsis = false,
  } = props;

  const [tempValue, setTempValue] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    setTempValue(stringValue);
  }, [stringValue]);

  return (
    <div className={className}>
      <Row className={styles.subHeader}>{headerText}</Row>
      <Row
        className={
          valueSizeMagnified
            ? styles.subHeaderValueMagnified
            : styles.subHeaderValue
        }
      >
        <div className={showEllipsis ? styles.valueWrapper : ''}>
          {isDtqOrPending ? (
            '-'
          ) : showEllipsis ? (
            <OverflowPopover>{value}</OverflowPopover>
          ) : (
            value
          )}{' '}
        </div>
        {editIcon && (
          <EditIcon
            className={styles.editIcon}
            onClick={() => {
              setIsModalVisible(true);
            }}
          />
        )}
      </Row>
      <Divider />
      {!isDtqOrPending && (
        <Row className={styles.valueDetailText}>{footerElement}</Row>
      )}

      <ConfirmationDialog
        title={editTitle}
        isRecordApplicable={true}
        onConfirm={async () => {
          setValue && setValue(tempValue);
          saveEdited && (await saveEdited(tempValue));
          setIsModalVisible(false);
        }}
        closeModal={() => {
          setTempValue(stringValue);
          setIsModalVisible(false);
        }}
        isCancelLink={true}
        visible={isModalVisible}
        confirmText={'Save'}
        cancelText={'Cancel'}
        centered={true}
        width={600}
        confirmBtnFullWidth={true}
        confirmLoading={isLoading}
      >
        <div className={styles.editFormWrapper}>
          <div className={styles.editLabel}>{editBody}</div>
          <Input
            value={tempValue}
            className={styles.editInput}
            onChange={(event: any) => setTempValue(event.target.value)}
          />
        </div>
      </ConfirmationDialog>
    </div>
  );
};

export default OfferCardOverview;
