import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

import { Row, Select, SelectProps } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import isEmpty from 'lodash/isEmpty';
import filterIcon from 'assets/images/icon-funnel.svg';
import Option from 'model/Option';
import { useStateCallback } from 'hooks/updateState';
import { allOption } from 'constants/commonConstants';

import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import SearchBar from 'components/SearchBar/SearchBar';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import styles from './filter.module.less';

interface FilterProps extends SelectProps {
  placeholder?: string;
  options: Option[];
  onSelectItems: (selected: string[]) => void;
  filterValue: string;
  searchName: string;
  selected: string[];
  isFilterClose?: boolean;
  className?: string;
}

const Filter: FC<FilterProps> = (props) => {
  const {
    options,
    placeholder,
    onSelectItems,
    filterValue,
    searchName,
    selected,
    isFilterClose,
    className = styles.filter,
    ...rest
  } = props;

  const [selectedOptions, setSelectedOptions] = useState<string[]>(selected);
  const [selectedValues, setSelectedValues] = useStateCallback([]);
  const [tempselectedOptions, setTempSelectedOptions] =
    useState<string[]>(selected);
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchBarSelected, setSearchBarSelected] = useState<boolean>(false);
  const [searchOptions, setSearchOptions] = useState<Option[]>([]);
  const [searchItems, setSearchItems] = useState<Option[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [isUpdated, setUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (selected.length !== 0) {
      setSelectedValues(selected);
    }
  }, [selected, setSelectedValues]);

  const handleSelect = useCallback(
    (value: string[]) => {
      if (
        value.includes(allOption) &&
        (options.length === value.length ||
          !selectedOptions.includes(allOption))
      ) {
        setSelectedOptions(options.map((option) => option.value));
        setSelectedValues([allOption]);
      } else if (
        value.includes(allOption) &&
        selectedOptions.includes(allOption)
      ) {
        const selectedFilters = value.filter((item) => item !== allOption);
        setSelectedOptions(selectedFilters);
        setSelectedValues(selectedFilters);
      } else if (
        !value.includes(allOption) &&
        selectedOptions.includes(allOption)
      ) {
        const allOptions = options.map((option) => option.value);
        const selectedItems = allOptions.filter(
          (item) => value.indexOf(item) < 0 && item != 'all'
        );
        setSelectedOptions(selectedItems);
        setSelectedValues(selectedItems);
      } else {
        setSelectedOptions(value);
        setSelectedValues(value);
      }
      if (isOpen === false) {
        setSelectedValues(value);
        onSelectItems(value);
      }
    },
    [isOpen, options, selectedOptions, setSelectedValues, onSelectItems]
  );

  useEffect(() => {
    if (!isEmpty(filterValue) && options.length > 1 && !isRendered) {
      const filterValueArray = Array.from(filterValue.split(','));
      handleSelect(filterValueArray);
      setIsRendered(true);
    }
  }, [filterValue, handleSelect, isRendered, options.length]);

  useEffect(() => {
    if (isFilterClose) {
      setIsOpen(false);
      setSearchText('');
      setSearchOptions(searchItems);
    }
  }, [isFilterClose, searchItems]);

  useEffect(() => {
    if (options) {
      setSearchItems(
        options.map((item) => ({
          label:
            options[0] === item &&
            placeholder !== 'All Brokers' &&
            selectedValues.includes(allOption)
              ? 'Deselect All'
              : options[0] === item && placeholder !== 'All Brokers'
              ? 'Select All'
              : item.label,
          value: item.value,
        }))
      );
    }
    if (isUpdated) {
      setTempSelectedOptions(selectedValues);
      setUpdate(false);
    }
    // eslint-disable-next-line
  }, [options, placeholder, selectedValues]);

  const handleConfirm = () => {
    setIsOpen(false);
    setUpdate(true);
    setSelectedValues(selectedOptions);
    onSelectItems(selectedOptions);
    setTempSelectedOptions(selectedOptions);
    if (isEmpty(onSelectItems)) {
      setSearchText('');
      setSearchOptions(searchItems);
    }
  };

  const resetSelectedOptions = () => {
    setIsOpen(false);
    setUpdate(false);
    setSelectedValues(tempselectedOptions);
    setSelectedOptions(tempselectedOptions);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.toLowerCase().trim();
    setSearchText(searchText);
    const filteredLocations = searchItems.filter((locations) => {
      const location = locations.label.toLowerCase();
      return location.includes(searchText);
    });

    setSearchBarSelected(searchText !== '');

    setSearchOptions(filteredLocations);
  };

  return (
    <div className={styles.filterWrapper}>
      <Select
        mode="multiple"
        maxTagCount={2}
        className={className}
        placeholder={placeholder}
        optionLabelProp="label"
        onChange={handleSelect}
        onSearch={() => handleSearch}
        dropdownClassName={styles.dropdownWrapper}
        value={selectedValues.includes(allOption) ? undefined : selectedValues}
        suffixIcon={<img src={filterIcon} alt="filter-icon" />}
        open={isOpen}
        onDropdownVisibleChange={(open) => {
          setIsOpen(open);
          if (!open) {
            resetSelectedOptions();
          }
        }}
        dropdownRender={(menu) => (
          <>
            <div className={styles.searchFilter}>
              <SearchBar
                placeholder={searchName}
                isLarge={false}
                onChange={handleSearch}
                value={searchText}
              />
            </div>
            {menu}
            <Row justify="center">
              <SubmitButton
                type="primary"
                onClick={handleConfirm}
                className={styles.confirmButton}
              >
                Okay
              </SubmitButton>
            </Row>
            <Row justify="center">
              <CancelButton
                className={styles.cancelButton}
                onClick={resetSelectedOptions}
              >
                Cancel
              </CancelButton>
            </Row>
          </>
        )}
        showArrow
        {...rest}
      >
        {(!searchBarSelected ? options : searchOptions).map((option) => {
          const { value, label } = option;
          return (
            <Select.Option key={value} value={value} label={label}>
              <Checkbox
                className={
                  (options[0] === option &&
                    placeholder !== 'All Brokers' &&
                    selectedValues.includes(allOption)) ||
                  (searchItems[0] === option &&
                    placeholder !== 'All Brokers' &&
                    selectedValues.includes(allOption))
                    ? styles.allSelect
                    : styles.singleSelect
                }
                checked={selectedOptions?.includes(value)}
              >
                <span onClick={(e) => e.stopPropagation()}>
                  {options[0] === option &&
                  placeholder !== 'All Brokers' &&
                  selectedValues.includes(allOption)
                    ? 'Deselect All'
                    : options[0] === option && placeholder !== 'All Brokers'
                    ? 'Select All'
                    : label}
                </span>
              </Checkbox>
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default Filter;
