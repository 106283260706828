import React, { useCallback, useEffect, useState } from 'react';

import { Button, Col, Divider, Row, Spin, Tabs, Tooltip } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { isEmpty, sortBy } from 'lodash';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import RenewalCommonMainHeader from 'modules/renewals/components/helperComponents/RenewalCommonMainHeader/RenewalCommonMainHeader';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import RenewalCommonAlert from 'modules/renewals/components/renewalCommonAlert/RenewalCommonAlert';
import AddProposalModal from 'modules/renewals/components/addProposalsComponents/AddProposalModal/AddProposalModal';
import OfferCardOverview from 'modules/renewals/components/helperComponents/OfferCardOverview/OfferCardOverview';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import ProposalCard from 'modules/renewals/components/ProposalCard/ProposalCard';
import RenewalCommonSubHeader from 'modules/renewals/components/helperComponents/RenewalCommonSubHeader/RenewalCommonSubHeader';
import FromCurrentDetailsFooter from 'modules/renewals/components/FromCurrentDetailsFooter/FromCurrentDetailsFooter';
import ProposalSortFilter from 'modules/renewals/components/ProposalSortFilter/ProposalSortFilter';
import AddTargetAnnualPremiumModal from 'modules/renewals/components/AddTargetAnnualPremiumModal/AddTargetAnnualPremiumModal';
import {
  useLazyGetAfpPlanYearsQuery,
  useLazyGetProposalsQuery,
  useLazyGetUpcomingPlanYearsByEmployerQuery,
  useLazyGetPublishedOffersQuery,
  useLazyValidatePlanYearQuery,
} from 'modules/renewals/slices/renewalsSlice';
import {
  useProposalStatusUpdateMutation,
  useUpdateProposalStatusAsRecommendedMutation,
  useLazyGetProposalSummaryQuery,
} from 'modules/renewals/slices/proposalSlice';
import { getCarriersByBenefitKind } from 'modules/plans/slices/basicPlanInfoSlice';
import { useLazyGetIsSoldConfirmationCompleteQuery } from 'modules/renewals/slices/soldConfirmationSlice';
import { clearProposalObject } from 'modules/renewals/slices/proposalCreateStepperSlice';
import { showSuccessNotification } from 'components/Notification/Notification';
import AddProposalToPlanYearModal from 'modules/renewals/components/AppProposalToPlanYearComponents/AddProposalToPlanYearModal/AddProposalToPlanYearModal';
import CreditIncludedExcludedModal from 'modules/renewals/components/CreditIncludedExcludedModal/CreditIncludedExcludedModal';
import SoldConfirmationModal from 'modules/renewals/components/soldConfirmation/SoldConfirmationModal/SoldConfirmationModal';
import EmployerSoldConfirmationModal from 'modules/renewals/components/soldConfirmation/EmployerSoldConfirmationModal/EmployerSoldConfirmationModal';

import {
  ALL_DROPDOWN_ITEM,
  CREATE_NEW_PROPOSAL_BTN_TXT,
  CREATE_NEW_RENEWAL_TXT,
  CREDITS_INCLUSION,
  DRAFTS_TAB,
  FOR_REVIEW_TAB,
  IMPORT_OFFERS_TXT,
  OFFER_BENEFIT_TYPES,
  OFFER_NO_DATA_MAIN_TXT,
  OFFER_NO_DATA_SUB_TXT,
  OUTDATED_PROPOSALS_TOOLTIP_TEXT,
  PROPOSAL_CARD_TAGS,
  PROPOSAL_CRUD_DONE_BY_KEY,
  PROPOSAL_DRAFT_NO_DATA_MAIN_TXT,
  PROPOSAL_DRAFT_NO_DATA_SUB_TXT,
  PROPOSAL_FOR_REVIEW_NO_DATA_MAIN_TXT,
  PROPOSAL_FOR_REVIEW_NO_DATA_SUB_TXT,
  PROPOSAL_SORT_METHODS,
  PROPOSAL_STATUS_FINALISED,
  RENEWAL_NO_DATA_MAIN_TXT,
  RENEWAL_NO_DATA_SUB_TXT,
  UPCOMING_DROPDOWN_ITEM_CARRIER,
  UPCOMING_DROPDOWN_PROPOSAL,
} from 'modules/renewals/constants/renewalsConstants';
import { currencyFormatterWithoutZeros } from 'util/commonUtil';
import {
  CreditsInclusionType,
  IProposalCardData,
  IProposalCardOverviewData,
  IProposalCardTableRow,
  IProposalPageTab,
  ISelectOption,
  OfferBenefitValueType,
  ProposalCardTagKeyType,
  ProposalSortMethodType,
  SoldConfirmationDropDownType,
} from 'modules/renewals/types/renewalsTypes';
import { sanitizeRichText } from 'util/domUtils';
import ProposalListItemVO from 'model/ProposalListItemVO';
import { isNullOrUndefined } from 'modules/plans/utils';
import { ReactComponent as DropDownDarkArrow } from 'assets/images/dropdown-down-arrow.svg';
import { ReactComponent as TimerRefreshIcon } from 'assets/images/icon-timer-refresh.svg';
import editIcon from 'assets/images/icon-pencil.svg';
import infoIcon from 'assets/images/icon-info.svg';

import { loginTypes } from 'constants/authConstants';
import { useNavContext } from 'hooks/useNavContext';
import UpcomingPlanYearBasicDto from 'model/UpcomingPlanYearBasicDto';
import {
  clearIsFromProposalNavigatePY,
  setProposalsCreditMode,
} from 'modules/renewals/slices/offerReducerSlice';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import ExportOfferProposalModal from 'modules/renewals/components/ExportOfferProposalModal/ExportOfferProposalModal';
import { FEATURE_KEYS } from 'constants/commonConstants';
import EmployerFeatureDisabledBanner from 'modules/tools/components/EmployerFeatureDisabledBanner/EmployerFeatureDisabledBanner';

import {
  AfpBanner,
  PlanYearDetail,
} from 'modules/renewals/components/AfpBanner/AfpBanner';
import styles from './newProposalContainer.module.less';

const NewProposalContainer = () => {
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const creditsInclusion = useAppSelector(
    (state) => state.renewals.offers.selectedProposalsCreditMode
  );
  const { brokerId } = useNavContext();
  const navigate = useNavigate();
  const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;
  const { employerId } = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [planYears, setPlanYears] = useState<ISelectOption[]>([]);
  const [proposalId, setProposalId] = useState<string>('');
  const [proposalType, setProposalType] = useState<string>('');
  const [finalApprovedProposalId, setFinalApprovedProposalId] =
    useState<string>();
  const [effectiveDates, setEffectiveDates] = useState<ISelectOption[]>([]);
  const [planYearValue, setPlanYearValue] = useState<string | null>(
    UPCOMING_DROPDOWN_PROPOSAL.value
  );
  const [effectiveDateValue, setEffectiveDateValue] = useState<string>(
    ALL_DROPDOWN_ITEM.value
  );
  const [targetAnnualPremium] = useState<number>();
  const [differenceFromCurrent] = useState<{
    value?: number;
    percentage?: number;
  }>({});
  const [currentAnnualPremium] = useState<number>();
  const [proposalDataList, setProposalDataList] = useState<{
    recent: IProposalCardData[];
    outdated: IProposalCardData[];
  }>({ recent: [], outdated: [] });
  const [draftProposalDataList, setDraftProposalDataList] = useState<{
    recent: IProposalCardData[];
    outdated: IProposalCardData[];
  }>({ recent: [], outdated: [] });
  const [isAnnualTargetPremiumModalOpen, setIsAnnualTargetPremiumModalOpen] =
    useState<boolean>(false);
  const [isProposalCreateModalVisible, setIsProposalCreateModalVisible] =
    useState<boolean>(false);
  const [isCloneProposalOpen, setIsCloneProposalOpen] =
    useState<boolean>(false);
  const [isFinalApprovedExists, setIsFinalApprovedExists] =
    useState<boolean>(false);
  const [isNoPlanYearDataExist, setIsNoPlanYearDataExist] =
    useState<boolean>(false);

  useEffect(() => {
    finalApprovedProposalId
      ? setIsFinalApprovedExists(true)
      : setIsFinalApprovedExists(false);
  }, [finalApprovedProposalId]);

  const [
    isAddProposalToPlanYearModalOpen,
    setIsAddProposalToPlanYearModalOpen,
  ] = useState<boolean>(false);
  const [
    isProposalCardActionsModalOpenIdx,
    setIsProposalCardActionsModalOpenIdx,
  ] =
    useState<[index: number, tab: typeof FOR_REVIEW_TAB | typeof DRAFTS_TAB]>();
  const [isProposalAddToPlanYearVisible, setIsProposalAddToPlanYearVisible] =
    useState<boolean>(false);
  const [overlapPlanYear, setOverlapPlanYear] = useState<PlanYearDetail>();
  const [sort, setSort] = useState({
    [FOR_REVIEW_TAB]: PROPOSAL_SORT_METHODS.RECOMMENDED.value,
    [DRAFTS_TAB]: PROPOSAL_SORT_METHODS.MOST_RECENT.value,
  });

  const isFromProposalNavigatePY = useAppSelector(
    (state) => state.renewals.offers.isFromProposalNavigatePY
  );
  const state = location.state as { proposalStatus?: string };
  const publishStatus = state ? state.proposalStatus : null;

  const [showCreditModal, setShowCreditModal] = useState<{
    visible: boolean;
    type: CreditsInclusionType;
  }>({
    visible: false,
    type: CREDITS_INCLUSION.EXCLUDED.value,
  });
  const [showSolidConfirmation, setShowSolidConfirmation] = useState(false);
  const [isDraftPublished, setIsDraftPublished] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('');
  const [isClose, setClose] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [displayExportPopup, setDisplayExportPopup] = useState(false);
  const [isInitial, setIsInitial] = useState<boolean>(true);
  const [
    isEmployerSoldConfirmationModalOpen,
    setIsEmployerSoldConfirmationModalOpen,
  ] = useState<boolean>(false);
  const [selectedProposalId, setSelectedProposalId] = useState<String>('');
  const [finalApprovedProposalName, setFinalApprovedProposalName] =
    useState<string>('');
  const [effectiveDateRange, setEffectiveDateRange] = useState<string>('');
  const [isFinalApprovedCompleted, setIsFinalApprovedCompleted] =
    useState<boolean>(false);
  const [isAlreadyFinalApproved, setIsAlreadyFinalApproved] =
    useState<boolean>(false);
  const [isEmployerSoldConfirmationSent, setIsEmployerSoldConfirmationSent] =
    useState<boolean>(false);
  const [isProposalRecommended, setIsProposalRecommended] =
    useState<boolean>(false);

  const [
    getProposals,
    {
      isSuccess: isGetProposalsSuccess,
      currentData: proposalsRawData,
      isLoading: isGetProposalsLoading,
      isFetching: isGetProposalsFetching,
    },
  ] = useLazyGetProposalsQuery();

  const [
    getUpcomingPlanYears,
    {
      isSuccess: isUpcomingFetchSuccess,
      data: upcomingData,
      isLoading: isGetUpcomingPlanYearsLoading,
    },
  ] = useLazyGetUpcomingPlanYearsByEmployerQuery();

  const [
    getPublishedOffers,
    { data: publishedOffersRawData, isFetching: isPublishedOffersFetching },
  ] = useLazyGetPublishedOffersQuery();
  const [
    publishProposal,
    {
      isSuccess: isPublishProposalSuccess,
      reset,
      isLoading: isProposalStatusUpdating,
    },
  ] = useProposalStatusUpdateMutation();

  const [updateOfferStatusAsRecommended, { isSuccess: markedAsRecommended }] =
    useUpdateProposalStatusAsRecommendedMutation();

  const [getIsSoldConfirmationComplete, { data: soldConfirmCompleteData }] =
    useLazyGetIsSoldConfirmationCompleteQuery();

  const changeProposalStatus = (
    proposalId: string,
    updatedOfferType: string
  ) => {
    updateOfferStatusAsRecommended({
      proposalId: proposalId,
      isRecommended: PROPOSAL_CARD_TAGS.RECOMMENDED === updatedOfferType,
    });
    setIsProposalRecommended(
      PROPOSAL_CARD_TAGS.RECOMMENDED === updatedOfferType
    );
  };

  const setCloseCreateProposal = () => {
    setIsProposalCreateModalVisible(false);
  };

  const [getProposalSummary, { data: finalApprovedProposalSummary }] =
    useLazyGetProposalSummaryQuery();

  const [
    validatePlanYear,
    { error: validationError, isLoading: isValidationPlanYearLoading },
  ] = useLazyValidatePlanYearQuery();

  const effectiveDateIdParameter =
    effectiveDateValue === ALL_DROPDOWN_ITEM.value
      ? undefined
      : effectiveDateValue;

  const loadList = useCallback(() => {
    if (employerId) {
      getProposals({
        employerId,
        effectiveDateId: effectiveDateIdParameter,
        planYearId: planYearValue,
      });
      getUpcomingPlanYears({ employerId });
      getPublishedOffers({
        employerId: employerId,
        planYearId: planYearValue ?? null,
        isDraft: true,
      });
      setIsInitial(false);
      setIsFinalApprovedCompleted(false);
    }
  }, [
    effectiveDateIdParameter,
    employerId,
    planYearValue,
    getProposals,
    getPublishedOffers,
    getUpcomingPlanYears,
  ]);

  const setCloseCloneProposal = () => {
    setIsCloneProposalOpen(false);
  };

  useEffect(() => {
    if (finalApprovedProposalId) {
      getProposalSummary({
        proposalId: String(finalApprovedProposalId),
        employerId: String(employerId),
      });
    }
    // eslint-disable-next-line
  }, [finalApprovedProposalId, employerId]);

  useEffect(() => {
    if (finalApprovedProposalId && isEmployerSoldConfirmationSent) {
      getProposalSummary({
        proposalId: String(finalApprovedProposalId),
        employerId: String(employerId),
      });
    }
    // eslint-disable-next-line
  }, [finalApprovedProposalId, employerId, isEmployerSoldConfirmationSent]);

  useEffect(() => {
    if (isProposalCreateModalVisible) {
      setClose(false);
    }
  }, [isProposalCreateModalVisible]);

  // Initial data fetch
  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    if (markedAsRecommended) {
      loadList();
      showSuccessNotification({
        message: 'Successfully Updated',
        description: isProposalRecommended
          ? 'The Proposal has been successfully marked as Recommended'
          : 'The Proposal has been successfully removed from Recommended proposals',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markedAsRecommended]);

  const setPlanYearsForDropDown = () => {
    if (
      planYearsData &&
      !isEmpty(planYearsData) &&
      upcomingData?.upcomingPlanYears
    ) {
      setIsNoPlanYearDataExist(false);
      const planYearsList: any[] = planYearsData.map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }));

      const isRenewalStarted = upcomingData?.upcomingPlanYears.some(
        (obj: any) => obj.planYearId === null
      );

      if (
        !isEmpty(isFromProposalNavigatePY) ||
        !isNullOrUndefined(isFromProposalNavigatePY)
      ) {
        setPlanYearValue(isFromProposalNavigatePY);
        if (isRenewalStarted) {
          planYearsList.unshift(UPCOMING_DROPDOWN_ITEM_CARRIER);
        }
      } else if (isRenewalStarted) {
        planYearsList.unshift(UPCOMING_DROPDOWN_ITEM_CARRIER);
        setPlanYearValue(null);
      } else {
        setPlanYearValue(planYearsList[0]?.value);
      }
      setPlanYears(planYearsList);
    } else {
      setIsNoPlanYearDataExist(true);
      setPlanYears([UPCOMING_DROPDOWN_ITEM_CARRIER]);
      setPlanYearValue(null);
    }
  };

  useEffect(() => {
    setEffectiveDatesForDropDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planYearValue, isProposalCreateModalVisible]);

  useEffect(() => {
    if (employerId) {
      getAfpPlanYears({ employerId: String(employerId) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerId]);

  const [
    getAfpPlanYears,
    { isFetching: isPlanYearsFetching, data: planYearsData },
  ] = useLazyGetAfpPlanYearsQuery();

  useEffect(() => {
    setPlanYearsForDropDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcomingData, planYearsData, isFromProposalNavigatePY]);

  // If Mark as final approved succeeds. Reload list
  useEffect(() => {
    if (isPublishProposalSuccess) {
      setIsProposalCardActionsModalOpenIdx(undefined);
      setIsDraftPublished(false);
      loadList();
      reset();
    }
    // eslint-disable-next-line
  }, [isPublishProposalSuccess]);

  useEffect(() => {
    if (isFinalApprovedCompleted) {
      setIsProposalCardActionsModalOpenIdx(undefined);
      setIsEmployerSoldConfirmationModalOpen(false);
      setIsDraftPublished(false);
      loadList();
      reset();
    }
    // eslint-disable-next-line
  }, [isFinalApprovedCompleted]);

  useEffect(() => {
    if (!isInitial) {
      getProposals({
        employerId: employerId ?? '',
        effectiveDateId: effectiveDateIdParameter,
        planYearId: planYearValue,
      });
    }
    // eslint-disable-next-line
  }, [isAddProposalToPlanYearModalOpen]);

  useEffect(() => {
    if (isProposalCardActionsModalOpenIdx === undefined) {
      if (!isInitial) {
        getProposals({
          employerId: employerId ?? '',
          effectiveDateId: effectiveDateIdParameter,
          planYearId: planYearValue,
        });
      }
    }
    // eslint-disable-next-line
  }, [isProposalCardActionsModalOpenIdx]);

  // For handling retrieved effective date data and planyear data
  useEffect(() => {
    if (upcomingData) {
      setEffectiveDatesForDropDown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpcomingFetchSuccess, upcomingData]);

  const setEffectiveDatesForDropDown = () => {
    const effectiveDates: ISelectOption[] = [];

    if (upcomingData && upcomingData?.upcomingPlanYears.length > 0) {
      effectiveDates.push(ALL_DROPDOWN_ITEM);
      upcomingData?.upcomingPlanYears
        .filter(
          (obj: UpcomingPlanYearBasicDto) => obj.planYearId === planYearValue
        )
        .forEach(({ id, effectiveEndDate, effectiveStartDate }) => {
          effectiveDates.push({
            label: `${dayjs(effectiveStartDate).format('ll')} - ${dayjs(
              effectiveEndDate
            ).format('ll')}`,
            value: id || '',
          });
        });
    }
    setEffectiveDates(effectiveDates);
  };

  // For handling retrieved proposal data
  useEffect(() => {
    // TODO: Implement sorting logic. Might be better to use
    // separate queries for separate tabs
    let approvedProposalId: string | undefined;
    if (proposalsRawData) {
      setIsProposalAddToPlanYearVisible(
        getIsProposalsApplicableToFinalise(proposalsRawData)
      );
      if (getIsProposalsApplicableToFinalise(proposalsRawData)) {
        const finalApprovedProposal: ProposalListItemVO | undefined =
          proposalsRawData?.find((proposal) => proposal.status === 'APPROVED');
        validatePlanYear({
          employerId: String(employerId),
          upcomingPlanYearId: String(finalApprovedProposal?.effectiveDateId),
        });
      }
      const transformedData: IProposalCardData[] = proposalsRawData.map(
        (proposal) => {
          const {
            id,
            tags,
            status,
            period,
            isOutdated,
            description,
            name: title,
            changedByUser,
            changedActivity,
            createdTs,
            lastUpdatedTs,
            creditExcludeSummary,
            creditIncludeSummary,
            effectiveDateId,
          } = proposal;

          const changedBy = ` ${
            changedActivity === PROPOSAL_CRUD_DONE_BY_KEY.PROPOSAL_CREATED.value
              ? `Created by ${changedByUser || '-'} \n${dayjs(createdTs).format(
                  'MMM D, YYYY'
                )}`
              : `Last updated by ${changedByUser || '-'} \n${dayjs(
                  lastUpdatedTs
                ).format('MMM D, YYYY')}`
          } ${
            PROPOSAL_CRUD_DONE_BY_KEY[
              changedActivity as keyof typeof PROPOSAL_CRUD_DONE_BY_KEY
            ]?.label || ''
          }`;

          let consideredInclusionSummary = creditExcludeSummary;
          if (creditsInclusion === 'INCLUDED') {
            consideredInclusionSummary = creditIncludeSummary;
          }

          const {
            totalSummary,
            totalLifeSummary,
            totalStdSummary,
            totalLtdSummary,
            voluntarySummary,
            totalVisionSummary,
            totalDentalSummary,
            totalMedicalSummary,
          } = consideredInclusionSummary;

          const overviewData: IProposalCardOverviewData = {
            erHsaFunding: totalSummary.totalHsa,
            totalBenefitCost: {
              value: totalSummary.totalPremium.value,
              percentage: totalSummary.totalPremium.diffFromCurrentPercentage,
              diffFromCurrentValue:
                totalSummary.totalPremium.diffFromCurrentValue,
            },
            eeCost: {
              value: totalSummary.totalEePremium.value,
              percentage: totalSummary.totalEePremium.diffFromCurrentPercentage,
              diffFromCurrentValue:
                totalSummary.totalEePremium.diffFromCurrentValue,
            },
            erCost: {
              value: totalSummary.totalErPremium.value,
              percentage: totalSummary.totalErPremium.diffFromCurrentPercentage,
              diffFromCurrentValue:
                totalSummary.totalErPremium.diffFromCurrentValue,
            },
          };

          const tableSummaries = {
            Dental: totalDentalSummary,
            Medical: totalMedicalSummary,
            Vision: totalVisionSummary,
            'Life/AD&D': totalLifeSummary,
            'Short Term Disability': totalStdSummary,
            'Long Term Disability': totalLtdSummary,
            'Voluntary Benefits': voluntarySummary,
          };

          const editedTags = tags.map((item: any) => {
            if (item === 'APPROVED') {
              approvedProposalId = proposal.id;
              return 'FINAL_APPROVED';
            } else {
              return item;
            }
          }) as ProposalCardTagKeyType[];

          const tableData: IProposalCardTableRow[] = Object.entries(
            tableSummaries
          ).map(([benefit, summary]) => ({
            benefit: benefit as OfferBenefitValueType,
            benefitsCost:
              benefit === OFFER_BENEFIT_TYPES.VOLUNTARY_BENEFIT
                ? summary?.plans?.toString()
                : summary?.totalPremium?.value?.toString(),
            eeCost: summary?.totalEePremium?.value?.toString(),
            erCost: summary?.totalErPremium?.value?.toString(),
            hsaCost: summary?.totalHsa?.toString(),
            hraCost: summary?.totalHra?.toString(),
          }));

          const effectiveDateRange = effectiveDates.find(
            (item) => item.value === proposal.effectiveDateId
          )?.label;

          return {
            id,
            title,
            period,
            status,
            changedBy,
            changeByName: changedByUser,
            tableData,
            isOutdated,
            overviewData,
            tags: editedTags,
            effectiveDateRange,
            description: sanitizeRichText(description),
            effectiveDateId,
          };
        }
      );

      const drafts = transformedData.filter(({ status }) => status === 'DRAFT');
      const published = transformedData.filter(({ status }) =>
        ['PUBLISHED', 'APPROVED', PROPOSAL_STATUS_FINALISED].includes(
          status || ''
        )
      );

      setFinalApprovedProposalId(approvedProposalId);
      setDraftProposalDataList({
        recent: sortBy(
          drafts.filter((data) => !data.isOutdated),
          (item) => {
            const priority = {
              FINALISED: 0,
              APPROVED: 1,
              PUBLISHED: 2,
              DRAFT: 3,
            } as const;
            let basePriority =
              priority[(item?.status as keyof typeof priority) || 'DRAFT'];
            if (
              item?.tags &&
              item.tags.includes('RECOMMENDED') &&
              !(item?.status === 'APPROVED')
            ) {
              basePriority -= 0.5;
            }
            return basePriority;
          }
        ),
        outdated: drafts.filter((data) => data.isOutdated),
      });
      setProposalDataList({
        recent: sortBy(
          published.filter((data) => !data.isOutdated),
          (item) => {
            const priority = {
              FINALISED: 0,
              APPROVED: 1,
              PUBLISHED: 2,
              DRAFT: 3,
            } as const;
            let basePriority =
              priority[(item?.status as keyof typeof priority) || 'DRAFT'];

            if (
              item?.tags &&
              item.tags.includes('RECOMMENDED') &&
              !(item?.status === 'APPROVED')
            ) {
              basePriority -= 0.5;
            } else if (item?.status === 'PUBLISHED') {
              const totalBenefitCost =
                item?.overviewData?.totalBenefitCost?.value;
              if (totalBenefitCost !== undefined) {
                basePriority += totalBenefitCost / 1000;
              }
            }

            return basePriority;
          }
        ),
        outdated: published.filter((data) => data.isOutdated),
      });
    }
    // eslint-disable-next-line
  }, [
    effectiveDates,
    isGetProposalsSuccess,
    proposalsRawData,
    creditsInclusion,
    effectiveDateValue,
  ]);

  useEffect(() => {
    if (validationError) {
      const error = validationError as any;
      setOverlapPlanYear(error?.data?.details?.detail);
    } else {
      setOverlapPlanYear(undefined);
    }
  }, [validationError]);

  useEffect(() => {
    // Calculate the count of published and draft proposals
    const publishedCount =
      proposalDataList?.outdated.length +
      (proposalDataList.recent || []).length;
    const draftCount =
      draftProposalDataList?.outdated.length +
      (draftProposalDataList.recent || []).length;

    // Determine if there are no published or draft proposals
    const noPublishedProposals = publishedCount === 0;
    const noDraftProposals = draftCount === 0;

    // Update the active tab based on conditions
    if (publishStatus !== null) {
      if (publishStatus === DRAFTS_TAB && noDraftProposals) {
        setActiveTab(FOR_REVIEW_TAB);
      } else if (publishStatus === FOR_REVIEW_TAB && noPublishedProposals) {
        setActiveTab(DRAFTS_TAB);
      } else if (noDraftProposals && noPublishedProposals) {
        setActiveTab(FOR_REVIEW_TAB);
      } else {
        setActiveTab(
          publishStatus === DRAFTS_TAB ? DRAFTS_TAB : FOR_REVIEW_TAB
        );
      }
    } else {
      if (noDraftProposals) {
        setActiveTab(FOR_REVIEW_TAB);
      } else if (noPublishedProposals) {
        setActiveTab(DRAFTS_TAB);
      } else if (noDraftProposals && noPublishedProposals) {
        setActiveTab(FOR_REVIEW_TAB);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetProposalsFetching, draftProposalDataList, proposalDataList]);

  useEffect(() => {
    if (finalApprovedProposalId) {
      getIsSoldConfirmationComplete({ proposalId: finalApprovedProposalId });
    }
    // eslint-disable-next-line
  }, [finalApprovedProposalId]);

  const togglePrCreationModal = () => {
    dispatch(clearProposalObject());
    setIsProposalCreateModalVisible(!isProposalCreateModalVisible);
  };

  const toggleProposalEdit = (proposalId: string, data: IProposalCardData) => {
    setIsProposalCreateModalVisible(!isProposalCreateModalVisible);
    setProposalId(proposalId);
    setProposalType(data?.status ?? '');
  };

  const toggleProposalClone = (proposalId: string) => {
    setIsProposalCreateModalVisible(!isProposalCreateModalVisible);
    setProposalId(proposalId);
    setIsCloneProposalOpen(!isCloneProposalOpen);
  };

  const onDeleteConfirm = () => {
    setIsDelete(true);
  };

  const handleNoDataBtnClick = (type: 'OFFERS' | 'RENEWAL') => {
    // TODO: Handle offers and renewals no data button click here
    switch (type) {
      case 'OFFERS':
        navigate(
          `/brokers/${brokerId}/employers/${employerId}/renewals/carrier/summary`
        );
        break;
      case 'RENEWAL':
        navigate(
          `/brokers/${brokerId}/employers/${employerId}/renewals/carrier/summary`,
          {
            state: {
              isFromProposalRenewalDate: true,
            },
          }
        );
        break;
      default:
        break;
    }
  };

  const benefitTypeSelect = (value: any) => {
    if (brokerId && employerId) {
      dispatch(getCarriersByBenefitKind(value, brokerId, employerId));
    }
  };

  const handleNoProposalDataBtnClick = (type: IProposalPageTab['key']) => {
    dispatch(clearProposalObject());
    switch (type) {
      case 'Published to Employer':
        dispatch(clearProposalObject());
        setIsProposalCreateModalVisible(true);
        setProposalModalOpen(true);
        break;
      case 'Drafts':
        dispatch(clearProposalObject());
        setIsProposalCreateModalVisible(true);
        setProposalModalOpen(true);
        break;
      default:
        dispatch(clearProposalObject());
        setIsProposalCreateModalVisible(true);
        setProposalModalOpen(true);
        break;
    }
  };

  const handleAddTargetAnnualPremium = () => {
    setIsAnnualTargetPremiumModalOpen(true);
  };

  const handleSortChange = (
    newSort: ProposalSortMethodType,
    tab: keyof typeof sort
  ) => {
    // TODO: According to the type of proposal that was changed, sort relevant array and set
    setSort({ ...sort, [tab]: newSort });
  };

  const setProposalModalOpen = (isOpen: boolean) => {
    setIsProposalCreateModalVisible(isOpen);
    if (!isOpen) {
      setProposalId('');
      dispatch(clearProposalObject());
      if (employerId) {
        getProposals({ employerId, planYearId: planYearValue });
      }
    }
  };

  const handleTabChange = (key: string): void => {
    if (key === DRAFTS_TAB) {
      setActiveTab(DRAFTS_TAB);
    } else {
      setActiveTab(FOR_REVIEW_TAB);
    }
  };

  const handleMarkAsFinalApproved = (
    proposalId: string,
    proposalName: string,
    effectiveDate: string
  ): void => {
    setIsFinalApprovedCompleted(false);
    if (isErAdmin) {
      publishProposal({
        proposalId,
        proposalStatus: 'APPROVED',
        isNotificationActive: true,
      });
    } else {
      setIsAlreadyFinalApproved(false);
      setIsEmployerSoldConfirmationModalOpen(true);
      setSelectedProposalId(proposalId);
      setFinalApprovedProposalName(proposalName);
      setEffectiveDateRange(effectiveDate);
    }
  };
  const handleUnmarkAsFinalApproved = (proposalId: string) => {
    setIsFinalApprovedCompleted(false);
    publishProposal({ proposalId, proposalStatus: 'PUBLISHED' });
  };
  const handleMarkAsPublished = (proposalId: string): void => {
    setIsProposalCardActionsModalOpenIdx([0, FOR_REVIEW_TAB]);
    setIsDraftPublished(true);
    publishProposal({ proposalId, proposalStatus: 'PUBLISHED' });
    navigate(
      `/brokers/${brokerId}/employers/${employerId}/renewals/proposals`,
      { state: { proposalStatus: 'PUBLISHED' } }
    );
  };

  const handleMarkAsDraft = (proposalId: string): void => {
    setIsProposalCardActionsModalOpenIdx([1, DRAFTS_TAB]);
    setIsDraftPublished(true);
    publishProposal({ proposalId, proposalStatus: 'DRAFT' });
    navigate(
      `/brokers/${brokerId}/employers/${employerId}/renewals/proposals`,
      { state: { proposalStatus: DRAFTS_TAB } }
    );
  };

  const onConfirmCreditSave = () => {
    dispatch(setProposalsCreditMode(showCreditModal.type));
    setShowCreditModal({
      visible: false,
      type:
        creditsInclusion === CREDITS_INCLUSION.INCLUDED.value
          ? CREDITS_INCLUSION.EXCLUDED.value
          : CREDITS_INCLUSION.INCLUDED.value,
    });
  };

  const hasRenewals = effectiveDates.length !== 0;
  const hasOffers: boolean = (publishedOffersRawData?.length ?? 0) !== 0;

  const isPlanYearApplicable = (): boolean => {
    return null !== planYearValue;
  };

  /**
   * When extra tab is to be added. Simply add a new section to this array
   * along with appropriate state to the component
   */
  const tabData: IProposalPageTab[] = [
    {
      key: FOR_REVIEW_TAB,
      name: 'Published to Employer',
      data: proposalDataList.recent,
      outdatedData: proposalDataList?.outdated,
      show: true,
      alert: {
        subText: PROPOSAL_FOR_REVIEW_NO_DATA_SUB_TXT,
        mainText: PROPOSAL_FOR_REVIEW_NO_DATA_MAIN_TXT,
        mainBtnText: CREATE_NEW_PROPOSAL_BTN_TXT,
        isBtnDisable: isPlanYearApplicable(),
      },
    },
    {
      key: DRAFTS_TAB,
      name: 'Drafts',
      data: draftProposalDataList.recent,
      outdatedData: draftProposalDataList?.outdated,
      show: !isErAdmin,
      alert: {
        subText: PROPOSAL_DRAFT_NO_DATA_SUB_TXT,
        mainText: PROPOSAL_DRAFT_NO_DATA_MAIN_TXT,
        mainBtnText: CREATE_NEW_PROPOSAL_BTN_TXT,
        isBtnDisable: isPlanYearApplicable(),
      },
    },
  ];

  useEffect(() => {
    if ((activeTab === '' || activeTab !== publishStatus) && !isDelete) {
      if (publishStatus === DRAFTS_TAB) {
        setActiveTab(DRAFTS_TAB);
      } else {
        setActiveTab(FOR_REVIEW_TAB);
      }
    }
    if (isDelete) {
      setIsDelete(false);
    }

    // eslint-disable-next-line
  }, [publishStatus, proposalsRawData]);

  useEffect(() => {
    if (isPublishProposalSuccess) {
      if (publishStatus === DRAFTS_TAB) {
        setActiveTab(DRAFTS_TAB);
      } else {
        setActiveTab(FOR_REVIEW_TAB);
      }
    }
    // eslint-disable-next-line
  }, [isProposalStatusUpdating]);

  const getIsProposalsApplicableToFinalise = (
    proposalList: ProposalListItemVO[]
  ) => {
    return (
      proposalList?.find((proposal) => proposal.status === 'APPROVED') !==
      undefined
    );
  };
  if (
    isPublishedOffersFetching ||
    isGetProposalsLoading ||
    isGetUpcomingPlanYearsLoading ||
    isPlanYearsFetching ||
    isValidationPlanYearLoading
  ) {
    return <Spin />;
  }

  const handleSoldConfirmationsDropDown = (
    action: SoldConfirmationDropDownType
  ) => {
    switch (action) {
      case 'SEND_CARRIER_SOLD_CONFIRMATION':
        setShowSolidConfirmation(true);
        break;
      case 'VIEW_CARRIER_SOLD_CONFIRMATION':
        setShowSolidConfirmation(true);
        break;
      case 'SEND_EMPLOYER_SOLD_CONFIRMATION':
        setSelectedProposalId(String(finalApprovedProposalId));
        setIsAlreadyFinalApproved(true);
        setFinalApprovedProposalName(
          finalApprovedProposalSummary?.proposalName ?? ''
        );
        setEffectiveDateRange(
          `${dayjs(finalApprovedProposalSummary?.effectiveStartDate).format(
            'll'
          )} - ${dayjs(finalApprovedProposalSummary?.effectiveEndDate).format(
            'll'
          )}`
        );
        setIsEmployerSoldConfirmationModalOpen(true);
        break;
    }
  };

  const updateIsAddProposalToPlanYearModalOpen = () => {
    setIsAddProposalToPlanYearModalOpen(true);
  };
  return (
    <>
      <EmployerFeatureDisabledBanner
        feature={FEATURE_KEYS.RENEWAL}
        featureName="Renewals"
        employerId={employerId}
      />
      {/* TODO: Change back to finalApprovedProposalId. This is for testing */}
      <AfpBanner
        isProposalAddToPlanYearVisible={isProposalAddToPlanYearVisible}
        overlapPlanYear={overlapPlanYear}
        soldConfirmCompleteData={soldConfirmCompleteData}
        finalApprovedProposalSummary={finalApprovedProposalSummary}
        isEmployerSoldConfirmationSent={isEmployerSoldConfirmationSent}
        handleSoldConfirmationsDropDown={handleSoldConfirmationsDropDown}
        addProposalToPlanYearModalOpen={updateIsAddProposalToPlanYearModalOpen}
        proposalId={finalApprovedProposalId}
      />
      <div className={styles.newProposalContainer}>
        <Row justify={'space-between'}>
          <Col flex="auto">
            <Row className={styles.headerRow}>
              <RenewalCommonMainHeader mainText={'Proposals'} />
            </Row>
            <div className={styles.filterRow}>
              <div className={styles.filterItem}>
                <p className={styles.headerLabel}>Plan Year: </p>
                {isNoPlanYearDataExist &&
                upcomingData?.upcomingPlanYears?.length === 0 ? (
                  <span className={styles.emptyFilterDash}>-</span>
                ) : (
                  <SingleSelectFilter
                    data={planYears}
                    placeholder={'-'}
                    showSearch={false}
                    setFilterSelectedValue={(value: any) => {
                      dispatch(clearIsFromProposalNavigatePY());
                      setPlanYearValue(value);
                      setEffectiveDateValue(ALL_DROPDOWN_ITEM.value);
                    }}
                    selectedValue={planYearValue}
                    defaultValue={UPCOMING_DROPDOWN_PROPOSAL.value}
                    applicableIcon={<DropDownDarkArrow />}
                    inlineDropdown={true}
                    isWiderWrapper
                    isDisabled={false}
                  />
                )}
              </div>
              <div className={styles.filterItem}>
                <p className={styles.headerLabel}>Effective Dates: </p>
                {!effectiveDates || effectiveDates.length === 0 ? (
                  <span className={styles.emptyFilterDash}>-</span>
                ) : (
                  <SingleSelectFilter
                    data={effectiveDates}
                    placeholder={'-'}
                    showSearch={false}
                    setFilterSelectedValue={setEffectiveDateValue}
                    selectedValue={effectiveDateValue}
                    defaultValue={ALL_DROPDOWN_ITEM.value}
                    applicableIcon={<DropDownDarkArrow />}
                    isWiderWrapper
                    inlineDropdown={true}
                  />
                )}
              </div>
            </div>
          </Col>
          {hasRenewals && hasOffers && (
            <Col>
              <div className={styles.proposalActionButton}>
                <PageActionButton
                  type="primary"
                  onClick={togglePrCreationModal}
                  className={styles.actionButton}
                  disabled={isPlanYearApplicable()}
                >
                  + Create New Proposal
                </PageActionButton>

                {[FOR_REVIEW_TAB, DRAFTS_TAB].includes(activeTab) &&
                  (proposalDataList.recent.length === 0 &&
                  draftProposalDataList.recent.length === 0 ? (
                    <Tooltip
                      title={'There is no proposal to export.'}
                      placement="bottom"
                    >
                      <Button type="link" className={styles.exportButton}>
                        <div className={styles.exportButtonDisabled}>
                          Export Proposals
                        </div>
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      type="link"
                      onClick={(e) => {
                        e.preventDefault();
                        setDisplayExportPopup(!displayExportPopup);
                      }}
                      className={styles.exportButton}
                    >
                      <div className={styles.exportButtonStyle}>
                        Export Proposals
                      </div>
                    </Button>
                  ))}
              </div>
            </Col>
          )}
        </Row>

        {!hasOffers || !hasRenewals ? (
          <Row justify="center">
            {hasRenewals ? (
              <RenewalCommonAlert
                subText={OFFER_NO_DATA_SUB_TXT}
                mainText={OFFER_NO_DATA_MAIN_TXT}
                mainBtnTxt={IMPORT_OFFERS_TXT}
                mainBtnHandler={() => handleNoDataBtnClick('OFFERS')}
                alertIcon={<TimerRefreshIcon className={styles.noDataIcon} />}
                mainBtnStyling={styles.mainBtn}
                isErAdmin={isErAdmin}
                isMainBtnDisabled={isPlanYearApplicable()}
              />
            ) : (
              <RenewalCommonAlert
                subText={RENEWAL_NO_DATA_SUB_TXT}
                mainText={RENEWAL_NO_DATA_MAIN_TXT}
                mainBtnTxt={CREATE_NEW_RENEWAL_TXT}
                mainBtnHandler={() => handleNoDataBtnClick('RENEWAL')}
                alertIcon={<TimerRefreshIcon className={styles.noDataIcon} />}
                mainBtnStyling={styles.mainBtn}
                isErAdmin={isErAdmin}
                isMainBtnDisabled={isPlanYearApplicable()}
              />
            )}
          </Row>
        ) : (
          <>
            {false && (
              <Row className={styles.statHeaderRow}>
                <Col lg={5} xl={5}>
                  <OfferCardOverview
                    headerText={'Current Benefits Cost'}
                    value={
                      currentAnnualPremium
                        ? currencyFormatterWithoutZeros(
                            currentAnnualPremium || 0
                          )
                        : '-'
                    }
                  />
                </Col>
                <Col lg={5} xl={5}>
                  <OfferCardOverview
                    headerText={'Target Benefits Cost'}
                    value={
                      <Row
                        wrap={false}
                        align="middle"
                        className={styles.overviewFooter}
                      >
                        {targetAnnualPremium ? (
                          <>
                            {currencyFormatterWithoutZeros(
                              targetAnnualPremium || 0
                            )}
                            <div className={styles.editIconWrapper}>
                              <LinkButton
                                onClick={handleAddTargetAnnualPremium}
                                icon={<img src={editIcon} />}
                              />
                            </div>
                          </>
                        ) : (
                          <div
                            className={
                              styles.addTargetAnnualPremiumButtonWrapper
                            }
                          >
                            <Button
                              ghost
                              type="primary"
                              onClick={handleAddTargetAnnualPremium}
                            >
                              Add Target Annual Premium
                            </Button>
                          </div>
                        )}
                      </Row>
                    }
                    footerElement={
                      <FromCurrentDetailsFooter
                        value={differenceFromCurrent.value}
                        percentage={differenceFromCurrent.percentage}
                      />
                    }
                  />
                </Col>
              </Row>
            )}
            <Row className={styles.tabsSection}>
              <Tabs
                type="card"
                defaultActiveKey={activeTab}
                size="large"
                activeKey={activeTab}
                className={styles.proposalTabs}
                onChange={handleTabChange}
              >
                {tabData.map(
                  (tab) =>
                    tab.show && (
                      <Tabs.TabPane
                        tab={`${tab.name} (${tab.data?.length || 0})`}
                        key={tab.key}
                        tabKey={tab.key}
                      >
                        {isGetProposalsFetching ? (
                          <Spin />
                        ) : (tab?.data?.length || 0) > 0 ||
                          (tab?.outdatedData?.length || 0) > 0 ? (
                          <>
                            {false && (
                              <Row justify="end">
                                <ProposalSortFilter
                                  tabKey={tab.key}
                                  onSelectedValueChange={handleSortChange}
                                  selectedValue={
                                    sort[tab.key as keyof typeof sort]
                                  }
                                />
                              </Row>
                            )}
                            <div className={styles.proposalCardWrapper}>
                              {tab.data &&
                                tab.data.map((data, idx) => (
                                  <ProposalCard
                                    key={idx}
                                    data={data}
                                    planYear={planYearValue}
                                    showPeriod={
                                      effectiveDateValue ===
                                      ALL_DROPDOWN_ITEM.value
                                    }
                                    creditsInclusion={creditsInclusion}
                                    isActionsModalOpen={
                                      !isDraftPublished &&
                                      isProposalCardActionsModalOpenIdx !==
                                        undefined &&
                                      idx ===
                                        isProposalCardActionsModalOpenIdx[0] &&
                                      tab.key ===
                                        isProposalCardActionsModalOpenIdx[1]
                                    }
                                    setIsActionsModalOpen={(isOpen: boolean) =>
                                      setIsProposalCardActionsModalOpenIdx(
                                        isOpen ? [idx, tab.key] : undefined
                                      )
                                    }
                                    onCreditsInclusionChange={(value: any) => {
                                      setShowCreditModal({
                                        visible: true,
                                        type: value,
                                      });
                                    }}
                                    onMarkAsDraftConfirm={handleMarkAsDraft}
                                    onMarkAsFinalApprovedConfirm={
                                      handleMarkAsFinalApproved
                                    }
                                    onUnmarkAsFinalApproved={
                                      handleUnmarkAsFinalApproved
                                    }
                                    onMarkAsPublishedConfirm={
                                      handleMarkAsPublished
                                    }
                                    onProposalEdit={toggleProposalEdit}
                                    onProposalClone={toggleProposalClone}
                                    changeProposalStatus={changeProposalStatus}
                                    onBenefitTypeSelect={benefitTypeSelect}
                                    isFinalizeProposal={Boolean(
                                      finalApprovedProposalId
                                    )}
                                    isFinalApprovedExists={
                                      isFinalApprovedExists
                                    }
                                    deleteProposalConfirm={onDeleteConfirm}
                                    isUnmarkFinalLoading={
                                      isProposalStatusUpdating
                                    }
                                  />
                                ))}
                            </div>
                            {tab.outdatedData && tab.outdatedData.length > 0 && (
                              <>
                                <RenewalCommonSubHeader
                                  subText={
                                    <Row
                                      align="middle"
                                      className={styles.subHeader}
                                    >
                                      Outdated Proposals
                                      <Tooltip
                                        placement="right"
                                        title={OUTDATED_PROPOSALS_TOOLTIP_TEXT}
                                      >
                                        <img
                                          src={infoIcon}
                                          className={styles.infoIcon}
                                        />
                                      </Tooltip>
                                    </Row>
                                  }
                                />
                                <Divider />
                                {tab.outdatedData.map((data, idx) => (
                                  <ProposalCard
                                    key={idx}
                                    data={data}
                                    planYear={planYearValue}
                                    showPeriod={
                                      effectiveDateValue ===
                                      ALL_DROPDOWN_ITEM.value
                                    }
                                    creditsInclusion={creditsInclusion}
                                    isActionsModalOpen={
                                      !isDraftPublished &&
                                      isProposalCardActionsModalOpenIdx !==
                                        undefined &&
                                      idx ===
                                        isProposalCardActionsModalOpenIdx[0] &&
                                      tab.key ===
                                        isProposalCardActionsModalOpenIdx[1]
                                    }
                                    setIsActionsModalOpen={(isOpen: boolean) =>
                                      setIsProposalCardActionsModalOpenIdx(
                                        isOpen ? [idx, tab.key] : undefined
                                      )
                                    }
                                    onMarkAsDraftConfirm={handleMarkAsDraft}
                                    onCreditsInclusionChange={(value: any) => {
                                      setShowCreditModal({
                                        visible: true,
                                        type: value,
                                      });
                                    }}
                                    onMarkAsFinalApprovedConfirm={
                                      handleMarkAsFinalApproved
                                    }
                                    onUnmarkAsFinalApproved={
                                      handleUnmarkAsFinalApproved
                                    }
                                    onMarkAsPublishedConfirm={
                                      handleMarkAsPublished
                                    }
                                    onProposalEdit={toggleProposalEdit}
                                    onProposalClone={toggleProposalClone}
                                    onBenefitTypeSelect={benefitTypeSelect}
                                    changeProposalStatus={changeProposalStatus}
                                    isFinalizeProposal={Boolean(
                                      finalApprovedProposalId
                                    )}
                                    isFinalApprovedExists={
                                      isFinalApprovedExists
                                    }
                                    deleteProposalConfirm={onDeleteConfirm}
                                    isUnmarkFinalLoading={
                                      isProposalStatusUpdating
                                    }
                                  />
                                ))}
                              </>
                            )}
                          </>
                        ) : (
                          <div className={styles.noDataViewRow}>
                            <RenewalCommonAlert
                              subText={tab.alert.subText}
                              mainText={tab.alert.mainText}
                              mainBtnTxt={tab.alert.mainBtnText}
                              mainBtnHandler={() =>
                                handleNoProposalDataBtnClick(tab.key)
                              }
                              alertIcon={
                                <TimerRefreshIcon
                                  className={styles.noDataIcon}
                                />
                              }
                              mainBtnStyling={styles.mainBtn}
                              isErAdmin={isErAdmin}
                              isMainBtnDisabled={tab.alert.isBtnDisable}
                            />
                          </div>
                        )}
                      </Tabs.TabPane>
                    )
                )}
              </Tabs>
            </Row>
          </>
        )}
        <AddProposalModal
          proposalId={proposalId}
          isModalOpen={isProposalCreateModalVisible}
          setIsModalOpen={setProposalModalOpen}
          cloneProposal={isCloneProposalOpen}
          setIsCloneProposalOpen={setCloseCloneProposal}
          isProposalCreateModalOpen={isProposalCreateModalVisible}
          setIsProposalCreateModalOpen={setCloseCreateProposal}
          isCloseModal={isClose}
          setCloseModal={() => setClose(true)}
          changeProposalId={setProposalId}
          proposalType={proposalType}
          setProposalType={setProposalType}
        />
        <AddTargetAnnualPremiumModal
          isOpen={isAnnualTargetPremiumModalOpen}
          setIsOpen={setIsAnnualTargetPremiumModalOpen}
        />
        <CreditIncludedExcludedModal
          type={showCreditModal.type}
          visible={showCreditModal.visible}
          onClose={() =>
            setShowCreditModal({
              visible: false,
              type:
                creditsInclusion === CREDITS_INCLUSION.INCLUDED.value
                  ? CREDITS_INCLUSION.EXCLUDED.value
                  : CREDITS_INCLUSION.INCLUDED.value,
            })
          }
          onConfirm={onConfirmCreditSave}
        />
        <AddProposalToPlanYearModal
          finalApprovedProposalId={finalApprovedProposalId ?? ''}
          isOpen={isAddProposalToPlanYearModalOpen}
          setIsOpen={setIsAddProposalToPlanYearModalOpen}
          updateProposalStatus={publishProposal}
          setIsProposalAddToPlanYearVisible={setIsProposalAddToPlanYearVisible}
        />
      </div>

      <SoldConfirmationModal
        finalApprovalId={finalApprovedProposalId ?? ''}
        visible={showSolidConfirmation}
        onConfirm={() => {
          getIsSoldConfirmationComplete({
            proposalId: String(finalApprovedProposalId),
          });
          setShowSolidConfirmation(false);
        }}
        onClose={() => {
          getIsSoldConfirmationComplete({
            proposalId: String(finalApprovedProposalId),
          });
          setShowSolidConfirmation(false);
        }}
      />

      {displayExportPopup && (
        <ExportOfferProposalModal
          // Upcoming effective data id is passed here
          planYearId={effectiveDateValue}
          disableProposalExportPopup={() => setDisplayExportPopup(false)}
          isOfferExport={false}
          defaultDiscountsIncluded={creditsInclusion === 'INCLUDED'}
          proposalPlanYearId={planYearValue}
        />
      )}
      <EmployerSoldConfirmationModal
        finalApprovalId={selectedProposalId.toString() ?? ''}
        visible={isEmployerSoldConfirmationModalOpen}
        proposalName={finalApprovedProposalName}
        effectiveDateRange={effectiveDateRange}
        onClose={() => {
          setIsEmployerSoldConfirmationModalOpen(false);
          loadList();
        }}
        setIsFinalApprovedCompleted={setIsFinalApprovedCompleted}
        isAlreadyFinalApproved={isAlreadyFinalApproved}
        setIsEmployerSoldConfirmationSent={setIsEmployerSoldConfirmationSent}
      />
    </>
  );
};

export default NewProposalContainer;
