import axios from 'axios';
import { IndividualType } from 'constants/commonConstants';
import ErrorDetails from 'model/ErrorDetails';

import { baseApi, getPagingQueryString } from 'util/apiUtil';

const v2LoginUrl = `${baseApi}/v2/logins`;
const v2IndividualUrl = `${baseApi}/v2/individuals`;
const v1LoginUrl = `${baseApi}/v1/logins`;
const v2EmployerUrl = `${baseApi}/v2/employers`;

export const getBrokerAdmins = async (
  page: number,
  size: number,
  sort: string,
  query: string,
  organizationId: string
) => {
  try {
    return await axios.get(
      `${baseApi}/v2/individuals/admins?${getPagingQueryString(
        page,
        size,
        sort,
        query,
        'query'
      )}`,
      {
        params: {
          type: IndividualType.ORGANIZATION_ADMIN,
          organizationId,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const getLumityAdminList = async (
  page: number,
  size: number,
  sort: string,
  query: string
) => {
  return axios.get(
    `${baseApi}/v2/individuals/admins?${getPagingQueryString(
      page,
      size,
      sort,
      query,
      'query'
    )}`,
    {
      params: {
        type: IndividualType.LUMITY_ADMIN,
      },
    }
  );
};

export const getERAdminList = async (
  page: number,
  size: number,
  sort: string,
  query: string,
  organizationId: string,
  employerId: string
) => {
  return await axios.get(
    `${baseApi}/v2/individuals/admins?${getPagingQueryString(
      page,
      size,
      sort,
      query,
      'query'
    )}`,
    {
      params: {
        type: IndividualType.ER_ADMIN,
        organizationId,
        employerId,
      },
    }
  );
};

export const validateAdminEmail = async (email: string) => {
  try {
    const data = { emailAddress: email };
    await axios.post(`${v2IndividualUrl}/validate`, data);
  } catch (error: any) {
    if (error.response) {
      return { data: error.response.data } as ErrorDetails;
    } else {
      // TODO: Need proper error handling
      console.log('Exception thrown ' + JSON.stringify(error));
    }
  }
  return null;
};

export const uploadAdminProfileAvatar = (image: Blob, original?: boolean) => {
  const formData = new FormData();
  formData.append('avatar', image);
  if (original) {
    formData.append('original', 'true');
  }
  return axios.post(`${v2LoginUrl}/avatar`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const createAdminUser = (type: string, data: any) => {
  return axios.post(`${v2LoginUrl}/admin?type=${type}`, data);
};

export const updateAdminById = async (id: string, data: any) => {
  return await axios.put(`${v2IndividualUrl}/${id}`, data);
};

export const findAdminById = async (id: string) => {
  return await axios.get(`${v2IndividualUrl}/${id}`);
};

export const deleteAdminById = async (
  organizationId: string | null,
  individualId: string
) => {
  if (organizationId) {
    return await axios.delete(
      `${v2LoginUrl}/admin?organizationId=${organizationId}&individualId=${individualId}`
    );
  } else {
    return await axios.delete(
      `${v2LoginUrl}/admin?individualId=${individualId}`
    );
  }
};

export const inviteLumityTechAdmin = (data: any) => {
  return axios.post(`${v1LoginUrl}/invite/lumity-tech-admin`, data);
};

export const inviteBrokerAdmin = (data: any) => {
  return axios.post(`${v1LoginUrl}/invite/organization-admin`, data);
};

export const inviteErAdmin = (data: any) => {
  return axios.post(`${v1LoginUrl}/invite/er-admin`, data);
};

export const getBrokerAdminDetails = (teamMemberId: string) => {
  return axios.get(`${v2IndividualUrl}/team-members/${teamMemberId}`);
};

export const addTeamMember = (employerId: string, teamMemberId: any) => {
  return axios.put(
    `${v2EmployerUrl}/${employerId}/team-members/${teamMemberId}`
  );
};

export const removeTeamMember = (employerId: string, teamMemberId: string) => {
  return axios.delete(
    `${v2EmployerUrl}/${employerId}/team-members/${teamMemberId}`
  );
};

export const dactivateAdmin = (data: any) => {
  return axios.post(`${v1LoginUrl}/invite/revoke`, data);
};

export const findErAdminsByEmployer = (employerId: string) => {
  return axios.get(`${v2IndividualUrl}/admins/employer`, {
    params: {
      type: IndividualType.ER_ADMIN,
      employerId,
    },
  });
};
