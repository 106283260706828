import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import EditBasicPlanInfo from 'modules/plans/components/BasicPlanInfo/EditBasicPlanInfo/EditBasicPlanInfo';
import MedicalPlan from 'model/plans/MedicalPlan';
import { useAppDispatch } from 'hooks/redux';
import { updateMedicalPlan } from 'modules/plans/slices/medicalPlanSlice';

import styles from './EditBasicPlanInfo/editBasicPlanInfo.module.less';

type BasicPlanInfoDBGWrapperProps = {
  medicalPlan: MedicalPlan;
  dbgPlanYear?: string;
};

const BasicPlanInfoDBGWrapper = forwardRef(
  (props: BasicPlanInfoDBGWrapperProps, ref) => {
    const dispatch = useAppDispatch();
    const basicPlanInfoRef = useRef<any>();
    const { medicalPlan, dbgPlanYear } = props;
    const changeValues = (changedPlan: MedicalPlan) => {
      dispatch(updateMedicalPlan(changedPlan));
    };

    useEffect(() => {
      dispatch(updateMedicalPlan(medicalPlan));
    }, [dispatch, medicalPlan]);

    useImperativeHandle(ref, () => ({
      reset() {
        basicPlanInfoRef.current?.resetForm();
      },
      isValidForm() {
        return basicPlanInfoRef.current?.isValidForm();
      },
    }));

    return (
      <div className={styles.basicPlanInfoDBGWrapper}>
        <EditBasicPlanInfo
          onChange={changeValues}
          isDBGPlan={true}
          ref={basicPlanInfoRef}
          dbgPlanYear={dbgPlanYear}
        />
      </div>
    );
  }
);

BasicPlanInfoDBGWrapper.displayName = 'BasicPlanInfoDBGWrapper';
export default BasicPlanInfoDBGWrapper;
