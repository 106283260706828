import { useState } from 'react';
import { Alert, Checkbox } from 'antd';
import {
  OFFERED_PLAN_DOC_DESCRIPTION_MESSAGE,
  OFFERED_PLAN_UPDATE_WARNING_MESSAGE,
  OFFERED_PLAN_WARNING_WARNING_MESSAGE,
  PLAN_DOC_DESCRIPTION_MESSAGE,
  PLAN_UPDATE_WARNING_MESSAGE,
  PLAN_WARNING_WARNING_MESSAGE,
} from 'modules/renewals/constants/renewalsConstants';
import { DocumentExtractionSource } from 'modules/plans/enums/DocumentExtractionSource';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import styles from './planUpdateWarning.module.less';

export type UpdateDocumentExtractionSource = Pick<
  typeof DocumentExtractionSource,
  'UPDATE_QUOTE_PLAN' | 'UPDATE_SYSTEM_PLAN'
>[keyof Pick<
  typeof DocumentExtractionSource,
  'UPDATE_QUOTE_PLAN' | 'UPDATE_SYSTEM_PLAN'
>];

type Props = {
  type: UpdateDocumentExtractionSource;
  onClose: () => void;
  isLoading: boolean;
  isOpen: boolean;
  onConfirm: (skipForUpdate: boolean) => void;
  confirmText?: string;
};

const PlanUpdateWarningModal = ({
  type,
  isLoading,
  isOpen,
  onClose,
  onConfirm,
  confirmText = 'Continue',
}: Props) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const contentMap: Record<
    UpdateDocumentExtractionSource,
    {
      title: string;
      description: string;
      warning: string;
      confirmMessage?: string;
    }
  > = {
    [DocumentExtractionSource.UPDATE_SYSTEM_PLAN]: {
      title: PLAN_WARNING_WARNING_MESSAGE,
      description: PLAN_DOC_DESCRIPTION_MESSAGE,
      warning: PLAN_UPDATE_WARNING_MESSAGE,
      confirmMessage: PLAN_WARNING_WARNING_MESSAGE,
    },
    [DocumentExtractionSource.UPDATE_QUOTE_PLAN]: {
      title: OFFERED_PLAN_WARNING_WARNING_MESSAGE,
      description: OFFERED_PLAN_DOC_DESCRIPTION_MESSAGE,
      warning: OFFERED_PLAN_UPDATE_WARNING_MESSAGE,
      confirmMessage: OFFERED_PLAN_WARNING_WARNING_MESSAGE,
    },
  };

  const content = contentMap?.[type];

  return (
    <ConfirmationDialog
      confirmText={content?.confirmMessage ?? confirmText}
      isCancelLink
      title={<div className={styles.reviewModalWrapper}>{content.title}</div>}
      cancelText="Cancel"
      closeModal={() => {
        onClose();
        setIsCheckboxChecked(false);
      }}
      confirmLoading={isLoading}
      onConfirm={() => {
        onConfirm(true);
        setIsCheckboxChecked(false);
      }}
      disableConfirmButton={Boolean(content.warning) && !isCheckboxChecked}
      visible={isOpen}
      destroyOnClose
    >
      <div className={styles.reviewModalWrapper}>
        {content.description}
        {content.warning && (
          <Alert
            className={styles.confirmationMessage}
            type="warning"
            message={
              <div className={styles.alertContent}>
                <Checkbox
                  className={styles.checkbox}
                  checked={isCheckboxChecked}
                  onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                />
                {content.warning}
              </div>
            }
          />
        )}
      </div>
    </ConfirmationDialog>
  );
};

export default PlanUpdateWarningModal;
