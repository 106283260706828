import { forwardRef } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import EditPlanDocuments from 'modules/plans/components/EditPlanDocuments/EditPlanDocuments';
import {
  removeDocumentReference,
  uploadRetirementPlanDocs,
} from 'modules/plans/slices/retirementPlanSlice';
import { RetirementPlanDocumentType } from 'modules/plans/constants';
import { BenefitKind } from 'constants/commonConstants';

import styles from './fourOOneKAdditionalResources.module.less';

type FourOOneKAdditionalResourcesProps = {
  isEditMode?: boolean;
  removeUploadedDocument: Function;
  documents: { [key: string]: string };
  planId?: string;
  documentsRef: any;
  onValidateFails?: (validateSetting: string) => void;
  disableFileUpload?: boolean;
};

const FourOOneKAdditionalResources = forwardRef(
  (props: FourOOneKAdditionalResourcesProps, ref) => {
    const {
      isEditMode = false,
      removeUploadedDocument,
      documents,
      planId,
      documentsRef,
      onValidateFails,
      disableFileUpload = false,
    } = props;

    const { documentReferences } = useAppSelector(
      (state) => state.plan.retirementPlan
    );
    const dispatch = useAppDispatch();

    const onFileChange = (document: File, docType: string) => {
      dispatch(uploadRetirementPlanDocs(document, docType));
    };

    const handleFileRemove = (docType: string) => {
      if (isEditMode && Object.keys(documents).includes(docType)) {
        removeUploadedDocument(docType);
      }
      dispatch(removeDocumentReference(docType));
    };

    return (
      <>
        <div className={styles.headerText}>Plan Resources</div>
        <div className={styles.documentWrapper}>
          <EditPlanDocuments
            onUpload={onFileChange}
            onFileRemove={handleFileRemove}
            documents={documents}
            documentReferences={documentReferences}
            planDocumentTypes={RetirementPlanDocumentType}
            planId={planId}
            benefitKind={BenefitKind.RETIREMENT_401K.value}
            isSaveDisabled={disableFileUpload}
            ref={documentsRef}
            onValidateFails={onValidateFails}
          />
        </div>
      </>
    );
  }
);
FourOOneKAdditionalResources.displayName = 'FourOOneKAdditionalResources';
export default FourOOneKAdditionalResources;
