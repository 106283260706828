import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Col, Collapse, Form, Input, Row } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';

import Consultant from 'model/benefitsConsultation/Consultant';
import InputForm from 'components/InputForm/InputForm';

import iconRemove from 'assets/images/icon-remove-red.svg';
import iconRemoveDisabled from 'assets/images/icon-remove-disabled.svg';

import styles from './addExternalBC.module.less';

const { Panel } = Collapse;

const EXTERNAL_BC = 'EXTERNAL_BC';

type AddExternalBCProps = {
  existingList: Consultant[];
  consultantTypeText: string;
  activeAdminsList: Consultant[];
  setIsFormFilled: Function;
};

const AddExternalBC = forwardRef((props: AddExternalBCProps, ref) => {
  const [form] = Form.useForm();

  const {
    existingList,
    consultantTypeText,
    activeAdminsList,
    setIsFormFilled,
  } = props;

  const [activePanel, setActivePanel] = useState<string | string[]>([]);
  const [externalConsultants, setExternalConsultants] = useState<Consultant[]>(
    []
  );

  useImperativeHandle(ref, () => ({
    getExternalConsultantsToBeAdded() {
      return externalConsultants;
    },

    resetData() {
      setExternalConsultants([]);
      setActivePanel([]);
      form.resetFields();
    },
  }));

  const isFormFilled = () => {
    const addedConsultant = form.getFieldsValue();

    if (
      (!isEmpty(addedConsultant.fullName) && !isEmpty(addedConsultant.email)) ||
      externalConsultants.length !== 0
    ) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  };

  const collapseOnChange = (key: string | string[]) => {
    setActivePanel(key);
  };

  const trimValue = (fieldName: string) => {
    return (event: any) => {
      const { value } = event.target;
      form.setFieldsValue({ [fieldName]: value.trim() });
    };
  };

  const validateFields = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errorInfo: any) {
      return isEmpty(errorInfo.errorFields);
    }
  };

  const onAddConsultant = async () => {
    const isValid = await validateFields();
    if (isValid) {
      const addedConsultant = form.getFieldsValue();
      addedConsultant.isConsultant = true;
      setExternalConsultants([addedConsultant, ...externalConsultants]);
      form.resetFields();
    }
  };

  const onRemoveConsultant = (email: string) => {
    const reducedArray = externalConsultants.filter(
      (item) => item.email !== email
    );

    setExternalConsultants(reducedArray);
  };

  const emailExists = (email: string, existingList: Consultant[]) => {
    return existingList.map((consultant) => consultant.email).includes(email);
  };

  const emailValidator = (email: string) => {
    const consultantList = activeAdminsList.filter((list) => list.isConsultant);
    const adminList = activeAdminsList.filter((list) => !list.isConsultant);

    if (emailExists(email, consultantList.concat(existingList))) {
      return Promise.reject(
        new Error(
          `${
            consultantTypeText.split(' ')[0]
          } or External Consultant already exists.`
        )
      );
    } else if (emailExists(email, adminList)) {
      return Promise.reject(
        new Error(
          `${
            consultantTypeText.split(' ')[0] === 'Employer'
              ? `An Employer`
              : `A Broker`
          } Admin with the same email already exists.`
        )
      );
    } else if (emailExists(email, externalConsultants)) {
      return Promise.reject(
        new Error('External Consultant already added to the list.')
      );
    } else {
      return Promise.resolve();
    }
  };

  return (
    <div className={styles.addExternalBCWrapper}>
      <Collapse bordered={false} onChange={collapseOnChange}>
        <Panel
          header={`Add External ${consultantTypeText.split(' ')[0]} Consultant`}
          key={EXTERNAL_BC}
          showArrow={false}
          extra={
            activePanel[0] === EXTERNAL_BC ? <UpOutlined /> : <DownOutlined />
          }
        >
          <InputForm layout="vertical" form={form} onChange={isFormFilled}>
            <Row gutter={36}>
              <Col>
                <Form.Item
                  name="fullName"
                  label="Consultant Name"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please specify a name',
                      validateTrigger: 'onSubmit',
                    },
                  ]}
                >
                  <Input onBlur={trimValue('fullName')} />
                </Form.Item>
              </Col>
              <Col flex="auto">
                <Form.Item
                  name="email"
                  label="Email"
                  required
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please specify a valid email',
                      validateTrigger: ['onSubmit'],
                    },
                    {
                      validator: (rule: any, value: string) =>
                        emailValidator(value),
                      validateTrigger: 'onSubmit',
                    },
                  ]}
                >
                  <Input onBlur={trimValue('email')} />
                </Form.Item>
              </Col>
            </Row>
          </InputForm>
          <Button
            htmlType="submit"
            onClick={onAddConsultant}
            className={styles.addBtn}
          >
            Add
          </Button>

          <Row>
            {externalConsultants.map((consultant) => (
              <Col key={consultant.id}>
                <div className={styles.name}>
                  <img
                    src={iconRemove}
                    alt="remove-icon"
                    className={styles.iconRemove}
                    onClick={() => onRemoveConsultant(consultant.email)}
                  />
                  {consultant.fullName}
                </div>
                <div className={styles.email}>{consultant.email}</div>
              </Col>
            ))}
            {existingList.map((consultant) => (
              <Col key={consultant.id}>
                <div className={styles.name}>
                  <img
                    src={iconRemoveDisabled}
                    alt="remove-icon"
                    className={`${styles.iconRemove} ${styles.disabled}`}
                  />
                  {consultant.fullName}
                </div>
                <div className={styles.email}>{consultant.email}</div>
              </Col>
            ))}
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
});

AddExternalBC.displayName = 'AddExternalBC';

export default AddExternalBC;
