import { ReactElement } from 'react';

import { TAG_STATUSES } from 'constants/tagConstants';

import styles from './offerStatusTag.module.less';

type OfferStatusProps = {
  offerStatus: keyof typeof TAG_STATUSES;
  icon?: ReactElement;
  isApplicable?: boolean;
};

const OfferStatusTag = (props: OfferStatusProps) => {
  const { offerStatus, isApplicable = false } = props;
  const tagStatusConfig = TAG_STATUSES[offerStatus];

  if (!tagStatusConfig) {
    return <></>;
  }

  const { color, label } = tagStatusConfig;
  const startIcon =
    tagStatusConfig['startIcon' as keyof typeof TAG_STATUSES['CURRENT']];

  return isApplicable ? (
    <></>
  ) : (
    <div className={styles[color]}>
      {startIcon && <img src={startIcon} className={styles.startIcon} />}
      {label.toUpperCase()}
    </div>
  );
};

export default OfferStatusTag;
