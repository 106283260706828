import { FC, ReactNode, useEffect, useState, useRef } from 'react';
import { Col, Form, Input, Popover, Row, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import concat from 'lodash/concat';
import size from 'lodash/size';
import difference from 'lodash/difference';

import { v4 as uuidv4 } from 'uuid';
import { QuestionCircleOutlined } from '@ant-design/icons';

import OriginalImageCropParams from 'model/OriginalImageCropParams';
import Consultant from 'model/benefitsConsultation/Consultant';

import InputForm from 'components/InputForm/InputForm';
import ImageUploader, {
  FORMAT_VALIDATE,
  SIZE_VALIDATE,
} from 'components/ImageUploader/ImageUploader';
import CheckboxSelect from 'components/CheckboxSelect/CheckboxSelect';
import MultiSelectDropdown from 'components/MultiSelectDropdown/MultiSelectDropdown';
import AlertMessage from 'components/Alert/AlertMessage';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import InputPhoneNumber from 'components/InputPhoneNumber/InputPhoneNumber';
import InvitationTemplate from 'modules/brokers/components/SendInvitesForm/InvitationTemplate';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import {
  clearAdminAvatar,
  clearAdminCreation,
  clearUpdateAdminUpdation,
  createAdmin,
  updateAdmin,
  updateAdminAvatarMap,
  uploadAdminAvatar,
} from 'modules/admins/slices/adminSlice';
import { ping } from 'modules/auth/slices/authSlice';
import { getERConsultantsSummary } from 'modules/benefitsConsultation/slices/employerLevelSlice';
import { getConsultantDetails } from 'modules/benefitsConsultation/slices/commonSlice';
import {
  consultantValidateClear,
  getAdminConsultantValidation,
} from 'modules/admins/slices/adminConsultantValidateSlice';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';

import Admin from 'model/admin/Admin';
import EmployerLocation from 'model/EmployerLocation';
import {
  isValidEmailFormat,
  isValidPhoneNumber,
  SYSTEM_EMPLOYER,
} from 'util/commonUtil';
import { loginTypes } from 'constants/authConstants';
import {
  exitWithoutSavingMsg,
  formVerificationMsg,
  IndividualType,
  multiSelectTypes,
  uploadImageSizeValidationMsg,
  BROKER_ADMIN_TEXT,
  BROKER_ADMIN_CONFIRM_TEXT,
  TECH_ADMIN_TEXT,
  TEECH_ADMIN_CONFIRM_TEXT,
  getBrokerAdminType,
  IndividualSubTypes,
  EMPLOYER_USER_TYPES_DROPDOWN,
} from 'constants/commonConstants';
import {
  brokerAdminFormFields,
  CONSULTANT_EMAIL_WARNING_MSG,
  CONSULTANT_EMAIL_WARNING_MSG_ER_ADMIN,
  CONSULTANT_EMAIL_WARNING_MSG_IF_ASSIGN_TO_EMP,
  CONSULTANT_LOCATION_WARNING_MSG,
  EMPTY_MESSAGE,
} from 'modules/admins/constants/adminConstants';
import {
  EMAIL_DUPLICATED,
  EMAIL_IN_USE,
  EMPTY_EMPLOYER_TEAMS,
  EXISTING_LOGIN_FOUND,
} from 'modules/admins/constants/adminErrorConstants';
import ConsultantWarnModal from 'modules/admins/components/ConsultantWarnModal/ConsultantWarnModal';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import ListComponent from 'components/Helpers/ListComponent/ListComponent';
import { buildFullName } from 'util/stringUtil';
import {
  useGetBrokerAdminsRfpLocationsQuery,
  useLazySendNotificationForRfpExceedQuery,
} from 'api/featureControl';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import { isNullOrUndefined } from 'modules/plans/utils';
import { useLazyGetErAdminsAvailabilityQuery } from 'modules/admins/slices/erAdminRTKSlice';
import SelectOptions from 'components/SelectOptions/SelectOptions';

import CustomEmployerUserDropDown from 'modules/benefitGuide/components/CustomEmployerUserDropDown/CustomEmployerUserDropDown';
import { useLazyGetEmployeeByIndividualIdQuery } from 'modules/idCards/slices/idCardApiSlice';
import styles from './createAdmin.module.less';

const { Text } = Typography;
const { Item } = Form;

type CreateAdminProps = {
  type: string;
  preLoadInPlaceId?: string | null;
  admin?: any | null; // We have three types of admins
  visibleConfirmation: boolean;
  confirmCloseModal: Function;
  toggleConfirmationPopup: () => void;
  adminCreatedInviteSent?: () => void;
  adminCreatedInviteNotSent?: () => void;
  adminUpdated: () => void;
  toggleAdminDetailsView?: () => void;
  adminDetailsView?: boolean;
  brokerLocations?: {
    selectAll: boolean;
    employerLocations: EmployerLocation[];
  };
  adminAccountStatus?: string;
  addBrokerAdminModal?: boolean;
  setAddBrokerAdminModal?: Function;
};

const locationTooltipContent = () => (
  <div className={styles.popoverContent}>
    <p>
      If you want this person to be able to access all clients then assign them
      to all locations.
      <br /> If you want this person only be able to access clients at specific
      locations then choose
      <br /> specific locations.
    </p>
  </div>
);

const CreateAdmin: FC<CreateAdminProps> = (props: CreateAdminProps) => {
  const {
    type,
    admin,
    visibleConfirmation,
    confirmCloseModal,
    toggleConfirmationPopup,
    adminCreatedInviteSent,
    adminCreatedInviteNotSent,
    adminUpdated,
    adminDetailsView,
    toggleAdminDetailsView,
    brokerLocations,
    adminAccountStatus = '',
    addBrokerAdminModal,
    setAddBrokerAdminModal,
  } = props;

  const adminInitialState = {
    id: '',
    inPlaceId: uuidv4(),
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    title: '',
    avatarUrl: '',
    organizationId: '',
    employerId: '',
    primary: false,
    locations: [],
    accountStatus: '',
    invitedTime: '',
    individualType: '',
    individualSubType: '',
  };

  const userType = useAppSelector(
    (state) => state?.auth?.auth?.appBootupInfo?.individualSubType
  );

  const [primaryContact, setPrimaryContact] = useState<boolean>(false);
  const [confirmatinModalOpen, setConfirmatinModalOpen] =
    useState<boolean>(true);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [duplicateEmailFound, setDuplicateEmailFound] =
    useState<boolean>(false);
  const [requireFieldErrorFound, setRequireFieldErrorFound] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);
  const [uploadedImage, setUploadedImage] = useState<string>('');
  const dispatch = useAppDispatch();
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [showEmailTemplate, setShowEmailTemplate] = useState<any>({
    isShow: false,
    buttonText: 'Show a Preview of Invitation Email',
  });
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [imageValidateSetting, setImageValidateSetting] = useState<string>('');
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState<boolean>(false);
  const [invalidEmailFormat, setInvalidEmailFormat] = useState<boolean>(false);
  const [hasLocationError, setLocationError] = useState<boolean>(false);
  const [cropArea, setCropArea] = useState<OriginalImageCropParams>();
  const [originalAvatar, setOriginalAvatar] = useState<string>();
  const [isConsultantWarning, setIsConsultantWarning] =
    useState<boolean>(false);
  const [isLocationWarning, setIsLocationWarning] = useState<boolean>(false);
  const [updatedAdmin, setUpdatedAdmin] = useState<Admin>({} as Admin);
  const [warningMsg, setWarningMsg] = useState<ReactNode>(<></>);
  const [isConsultant, setIsConsultant] = useState<boolean>(false);
  const [noERAdmins, setNoERAdmins] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
  const [isOpsAdmin, setIsOpsAdmin] = useState<boolean>(false);
  const [isImplementAdmin, setIsImplementAdmin] = useState<boolean>(false);
  const [showSaveBrokerConfirmation, setShowSaveBrokerConfirmation] = useState<{
    invite: boolean;
    show: boolean;
  }>({ invite: false, show: false });
  const [isErAdminsExist, setIsErAdminsExist] = useState<boolean>(false);

  const { brokerId, employerId } = useNavContext();

  const [form] = Form.useForm();

  const [formData, setFormData] = useState<Admin>(adminInitialState);

  const templateRef = useRef<HTMLDivElement>(null);

  const adminAvatar = useAppSelector(
    (state) => state.admins.admins.adminAvatar
  );
  const avatarImageMap = useAppSelector(
    (state) => state.admins.admins.avatarImageMap
  );

  const adminStatus = useAppSelector((state) => state.admins.admins);
  const brokerAdmin: any = useAppSelector(
    (state) => state.admins.admins.adminObj
  );
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const originalAvatarUrl = useAppSelector(
    (state) => state.admins.admins.avatarOriginal
  );
  const createAdminInProgress = useAppSelector(
    (state) => state.admins.admins.createAdminInProgress
  );
  const createAdminCompleted = useAppSelector(
    (state) => state.admins.admins.createAdminCompleted
  );
  const activeBrokerAdminsList = useAppSelector(
    (state) => state.benefitsConsultation.brokerLevel.activeBrokerAdminsList
  );
  const activeEmployerAdminsList = useAppSelector(
    (state) => state.benefitsConsultation.employerLevel.activeEmployerAdminsList
  );
  const consultantDetails = useAppSelector(
    (state) => state.benefitsConsultation.common.consultant
  );
  const erAdmins = useAppSelector((state) => state.admins.erAdmins.erAdmins);
  const adminConsultantValidateState = useAppSelector(
    (state) => state.admins.adminConsultantValidate
  );
  const { content } = erAdmins || {};
  const isTechAdmin: boolean = appBootInfo?.type === loginTypes.platform;
  const isBrokerAdmin: boolean = appBootInfo?.type === loginTypes.bokerAdmin;
  // check  AdminConsultantValidateState.consultantValidateObj returns array
  const isAdminConsultantValidateArray = Array.isArray(
    adminConsultantValidateState.consultantValidateObj
  );

  const [sendNotificationForRfpExceed] =
    useLazySendNotificationForRfpExceedQuery();

  const { data: brokerLocationsData, refetch } =
    useGetBrokerAdminsRfpLocationsQuery({
      organizationId: brokerId,
    });

  useEffect(() => {
    if (addBrokerAdminModal) {
      refetch();
    }
    // eslint-disable-next-line
  }, [addBrokerAdminModal]);

  const [getErAdminsAvailability, { data: erAdminAvailability }] =
    useLazyGetErAdminsAvailabilityQuery();

  const [fetchEmployeeByIndividualId, { isSuccess: employeeFetchSuccess }] =
    useLazyGetEmployeeByIndividualIdQuery();

  useEffect(() => {
    fetchEmployeeByIndividualId({
      individualId: admin?.id,
    });
  }, [admin, fetchEmployeeByIndividualId]);

  useEffect(() => {
    if (
      !adminStatus.updateInProgress &&
      adminStatus.updateCompleted &&
      adminStatus?.adminUpdateError?.data?.code === EMPTY_EMPLOYER_TEAMS &&
      !isLocationWarning &&
      admin
    ) {
      setIsLocationWarning(true);
    }
    // eslint-disable-next-line
  }, [adminStatus?.updateCompleted, adminStatus?.updateInProgress]);

  useEffect(() => {
    if (createAdminCompleted) {
      sendNotificationForRfpExceed({
        organizationId: brokerId,
        individualId: brokerAdmin?.individual?.id,
      });
    }
    // eslint-disable-next-line
  }, [createAdminInProgress, createAdminCompleted, brokerAdmin]);

  useEffect(() => {
    if (!adminStatus.inProgress && !isEmpty(adminStatus.adminObj)) {
      const createdAdminObj = adminStatus.adminObj as any; // Object is differ.
      if (
        !editMode &&
        createdAdminObj.individual &&
        adminCreatedInviteSent &&
        adminCreatedInviteNotSent
      ) {
        if (createdAdminObj.individual.invite) {
          adminCreatedInviteSent();
        } else {
          adminCreatedInviteNotSent();
        }
      }
      dispatch(clearAdminCreation());
      form.resetFields();
      setPrimaryContact(false);
      setSelectedLocations([]);
      setAllSelected(false);
      confirmCloseModal();
      setAlertMessage('');
      setVisibleAlert(false);
      setRequireFieldErrorFound(false);
      setInvalidEmailFormat(false);
      setInvalidPhoneNumber(false);
      setLocationError(false);
      setCropArea(undefined);
      setOriginalAvatar(undefined);
      setIsOpsAdmin(false);
      setIsImplementAdmin(false);
    } else if (
      [EXISTING_LOGIN_FOUND, EMAIL_DUPLICATED].includes(
        adminStatus?.createAdminError?.data?.code
      )
    ) {
      dispatch(clearAdminCreation());
      setDuplicateEmailFound(true);
      setAlertMessage(EMAIL_IN_USE);
    }
    if (!adminStatus.updateInProgress && adminStatus.updateCompleted) {
      setIsConsultantWarning(false);
      if (
        adminStatus.adminUpdateError &&
        adminStatus.adminUpdateError.data &&
        adminStatus.adminUpdateError.data.code &&
        adminStatus.adminUpdateError.data.code === EMAIL_DUPLICATED
      ) {
        dispatch(clearUpdateAdminUpdation());
        setDuplicateEmailFound(true);
        setAlertMessage(EMAIL_IN_USE);
      } else if (!adminStatus.adminUpdateError) {
        adminUpdated();
        dispatch(clearUpdateAdminUpdation());
        form.resetFields();
        confirmCloseModal();
        setPrimaryContact(false);
        setSelectedLocations([]);
        setAllSelected(false);
        setAlertMessage('');
        setVisibleAlert(false);
        setRequireFieldErrorFound(false);
        setInvalidEmailFormat(false);
        setInvalidPhoneNumber(false);
        setLocationError(false);
        setIsLocationWarning(false);
        setIsOpsAdmin(false);
        setIsImplementAdmin(false);
      }
    }
  }, [
    dispatch,
    adminStatus?.inProgress,
    adminStatus?.adminObj,
    adminStatus?.updateInProgress,
    adminStatus?.adminUpdateError,
    adminStatus?.updateCompleted,
    adminStatus.createAdminError,
    brokerId,
    form,
    setPrimaryContact,
    confirmCloseModal,
    adminCreatedInviteSent,
    adminCreatedInviteNotSent,
    adminUpdated,
    editMode,
  ]);

  useEffect(() => {
    closeAlert();
    dispatch(clearAdminCreation());
    dispatch(clearUpdateAdminUpdation());
    dispatch(clearAdminAvatar());
    if (admin) {
      const data = {
        inPlaceId: '',
        email: admin.email,
        firstName: admin.firstName,
        lastName: admin.lastName,
        title: admin.title,
        phone: admin.phone,
        avatarUrl: admin.avatar,
        primary: admin.primary,
        organizationId: admin.organizationId,
        employerId:
          admin.type !== IndividualType.ER_ADMIN
            ? SYSTEM_EMPLOYER
            : admin.employerId,
        locations: !isEmpty(admin.locations)
          ? admin.locations
          : brokerLocations
          ? updateSelected(
              getBrokerLocations(brokerLocations.employerLocations),
              true
            )
          : [],
        individualType: admin.individualType,
        individualSubType: admin.individualSubType,
      } as Admin;
      setFormData(data);
      form.setFieldsValue(data);
      setPrimaryContact(admin.primary);
      setUploadedImage(admin.avatar);
      setCropArea(admin.originalImageCropParams);
      setIsOpsAdmin(admin?.individualType === IndividualType.OPS_ADMIN);
      setIsImplementAdmin(
        admin?.individualType === IndividualType.IMPLEMENTATION_ADMIN
      );
      if (!isEmpty(admin.locations)) {
        setSelectedLocations(admin.locations);
      }
      setOriginalAvatar(admin?.originalAvatar);
      setEditMode(true);
      setIsSaveDisabled(employeeFetchSuccess);
    } else {
      setEditMode(false);
      setPrimaryContact(false);
      setSelectedLocations([]);
      setUploadedImage('');
      form.resetFields();
    }
  }, [dispatch, admin, form, brokerLocations, employeeFetchSuccess]);

  useEffect(() => {
    if (invalidEmailFormat || duplicateEmailFound) {
      form.setFields([{ name: 'email', errors: [EMPTY_MESSAGE] }]);
    }
  }, [duplicateEmailFound, invalidEmailFormat, form]);

  useEffect(() => {
    if (invalidPhoneNumber) {
      form.setFields([{ name: 'phone', errors: [EMPTY_MESSAGE] }]);
    }
  }, [invalidPhoneNumber, form]);

  useEffect(() => {
    const { firstName } = formData;
    if (firstName?.trim() !== '') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      setShowEmailTemplate({
        isShow: false,
        buttonText: 'Show a Preview of Invitation Email',
      });
    }
  }, [formData]);

  useEffect(() => {
    if (showEmailTemplate.isShow) {
      const templateRefElement = templateRef.current;
      if (templateRefElement !== null) {
        templateRefElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [showEmailTemplate.isShow]);

  useEffect(() => {
    let summaryList = [] as Consultant[];
    if (editMode) {
      if (type === IndividualType.ORGANIZATION_ADMIN) {
        summaryList = activeBrokerAdminsList;
      }
      if (type === IndividualType.ER_ADMIN) {
        summaryList = activeEmployerAdminsList;
      }
      const consultant = summaryList.find(
        (consultantSummary) =>
          consultantSummary.id === admin?.id && consultantSummary.isConsultant
      );
      if (consultant) {
        dispatch(getConsultantDetails(consultant?.id));
        setIsConsultant(true);
      }
    }
  }, [
    activeBrokerAdminsList,
    activeEmployerAdminsList,
    admin?.id,
    dispatch,
    editMode,
    type,
  ]);

  useEffect(() => {
    if (admin) {
      dispatch(
        getAdminConsultantValidation({
          adminType: admin?.individualType,
          orgId: admin?.organizationId,
          individualId: admin?.id,
          employerId: admin?.employerId,
        })
      );
    }
    return () => {
      dispatch(consultantValidateClear());
    };
  }, [admin, dispatch]);

  useEffect(() => {
    if (isEmpty(content) && type === IndividualType.ER_ADMIN) {
      setPrimaryContact(!isErAdminsExist);
      setNoERAdmins(!isErAdminsExist);
    }
  }, [content, type, isErAdminsExist]);

  const onInputChange = async (changedValues: any, allValues: any) => {
    if (changedValues?.individualSubType) {
      if (changedValues?.individualSubType) {
        if (
          changedValues?.individualSubType !==
          IndividualSubTypes.SUPER_BROKER_ADMIN
        ) {
          setAllSelected(false);
        } else {
          setSelectedLocations(
            brokerLocations?.employerLocations.map((loc) => loc.locationId) ||
              []
          );
          setAllSelected(true);
        }
      }
      let individualType = {};
      if (changedValues?.individualSubType === IndividualType.OPS_ADMIN) {
        setIsOpsAdmin(true);
        setIsImplementAdmin(false);
        setPrimaryContact(false);
        individualType = { individualType: IndividualType.OPS_ADMIN };
      } else if (
        changedValues?.individualSubType === IndividualType.IMPLEMENTATION_ADMIN
      ) {
        setIsImplementAdmin(true);
        setIsOpsAdmin(false);
        setPrimaryContact(false);
        individualType = {
          individualType: IndividualType.IMPLEMENTATION_ADMIN,
        };
      } else {
        setIsOpsAdmin(false);
        setIsImplementAdmin(false);
        individualType = { individualType: IndividualType.ORGANIZATION_ADMIN };
      }
      form.setFieldsValue({ ...allValues, ...individualType });
      setFormData({ ...formData, ...allValues, ...individualType });
    } else {
      form.setFieldsValue(allValues);
      setFormData({ ...formData, ...allValues });
    }
    setDuplicateEmailFound(false);
    setInvalidPhoneNumber(false);
    setInvalidEmailFormat(false);
    if (visibleAlert) {
      const valid = await validateAdminInfo();
      if (valid && !hasLocationError) {
        setVisibleAlert(false);
        setRequireFieldErrorFound(false);
      }
    }
  };

  const selectedBrokerLocations = (): string[] => {
    if (brokerLocations) {
      return allSelected ? [] : selectedLocations;
    }
    return selectedLocations;
  };

  const locationsNotHavingAccessToAdmin = difference(
    admin?.locations,
    brokerLocations?.employerLocations?.map(
      (location) => location.locationId
    ) ?? []
  );

  const convertBrokerLocationsToOptions = (locations: EmployerLocation[]) => {
    if (locations) {
      return locations.map((location) => ({
        label: location.locationName,
        value: location.locationId,
      }));
    }
    return [];
  };

  const getBrokerLocations = (locations: EmployerLocation[]) => {
    const locationList: string[] = [];
    locations.forEach((location) => {
      locationList.push(location.locationId);
    });
    return locationList;
  };

  const isEmptyField = (value: string) => {
    if (requireFieldErrorFound && isEmpty(value)) {
      return styles.labelError;
    }
  };

  const closeAlert = () => {
    setRequireFieldErrorFound(false);
    setDuplicateEmailFound(false);
    setVisibleAlert(false);
    setAlertMessage('');
    setInvalidPhoneNumber(false);
  };

  const goToEditErAdmin = () => {
    if (toggleAdminDetailsView) {
      toggleAdminDetailsView();
      brokerId &&
        employerId &&
        dispatch(getERConsultantsSummary(brokerId, employerId));
    }
  };

  const validateAdminInfo = async () => {
    try {
      await form.validateFields(brokerAdminFormFields.requiredFields);
      return true;
    } catch (errorInfo: any) {
      return errorInfo.errorFields.length === 0;
    }
  };

  const handleEmployerClick = (empId: any) => {
    window.open(
      `/brokers/${brokerId}/employers/${empId}/broker-consultation`,
      '_blank'
    );
  };

  const viewEmployer = (item: any) => {
    window.open(`/brokers/${brokerId}/employers/${item.value}`, '_blank');
  };

  const empListCommonComp = () => (
    <ListComponent
      dataSource={adminConsultantValidateState?.consultantValidateObj?.map(
        (item: any) => ({
          label: item?.name,
          value: item?.id,
        })
      )}
      onClickItem={(data: any) => handleEmployerClick(data?.value)}
    />
  );

  const onClickSave = async (invite: boolean) => {
    let isFormValid: boolean = true;
    setVisibleAlert(false);
    isFormValid = await validateAdminInfo();
    if (type === IndividualType.ORGANIZATION_ADMIN) {
      isFormValid =
        isFormValid &&
        (allSelected || (!allSelected && !isEmpty(selectedLocations)));
      setLocationError(
        !(allSelected || (!allSelected && !isEmpty(selectedLocations)))
      );
    }
    if (isFormValid) {
      setRequireFieldErrorFound(false);
      const { email, firstName, lastName, phone, title, inPlaceId } = formData;
      const { data: avatar } = adminAvatar;
      if (!isValidEmailFormat(email.trim())) {
        setInvalidEmailFormat(true);
        setVisibleAlert(true);
        setAlertMessage(formVerificationMsg);
      } else if (!isEmpty(phone) && !isValidPhoneNumber(phone)) {
        setInvalidPhoneNumber(true);
        setVisibleAlert(true);
        setAlertMessage(formVerificationMsg);
      } else {
        try {
          const createAdminType =
            type === IndividualType.ORGANIZATION_ADMIN && isOpsAdmin
              ? IndividualType.OPS_ADMIN
              : isImplementAdmin
              ? IndividualType.IMPLEMENTATION_ADMIN
              : type;
          const adminData = {
            id: editMode && admin !== null ? admin.id : null,
            inPlaceId,
            email: email.trim(),
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            title: title?.trim(),
            phone,
            avatarUrl:
              editMode && admin !== null
                ? avatar
                  ? avatar
                  : uploadedImage !== ''
                  ? admin.avatarUrl
                  : '' // Image has removed in edit mode
                : avatar,
            primary: primaryContact,
            organizationId: brokerId ? brokerId : null,
            employerId:
              type !== IndividualType.ER_ADMIN ? SYSTEM_EMPLOYER : employerId,
            locations: concat(
              locationsNotHavingAccessToAdmin,
              selectedBrokerLocations()
            ),
            invite: !editMode ? invite : false,
            originalAvatarUrl:
              originalAvatarUrl || admin?.originalAvatarUrl || null,
            originalImageCropParams: cropArea,
            consultant: isConsultant,
            individualType: createAdminType,
            individualSubType: formData.individualSubType,
          } as Admin;

          if (editMode && admin !== null) {
            let delaySave = false;
            let isConsultantSave = false;
            const adminFullName =
              admin?.firstName?.trim() + ' ' + admin?.lastName?.trim();
            const adminDataFullName =
              adminData?.firstName?.trim() + ' ' + adminData?.lastName?.trim();

            if (isConsultant) {
              setUpdatedAdmin({ ...adminData });
            }

            if (adminFullName !== adminDataFullName) {
              isConsultantSave = true;
            }

            if (brokerId && type === IndividualType.ORGANIZATION_ADMIN) {
              if (isConsultant) {
                if (admin?.email !== adminData.email) {
                  if (consultantDetails?.status?.calendarSyncStatus) {
                    setIsConsultantWarning(true);
                    delaySave = true;
                    setWarningMsg(
                      <>
                        <p>{CONSULTANT_EMAIL_WARNING_MSG}</p>
                        {isAdminConsultantValidateArray &&
                        adminConsultantValidateState?.consultantValidateObj
                          ?.length !== 0 ? (
                          <>
                            <p>
                              {CONSULTANT_EMAIL_WARNING_MSG_IF_ASSIGN_TO_EMP}
                            </p>
                            {empListCommonComp()}
                          </>
                        ) : null}
                      </>
                    );
                  } else {
                    isConsultantSave = true;
                  }
                }

                if (!isEqual(admin?.locations, adminData.locations)) {
                  setIsConsultantWarning(true);
                  delaySave = true;
                  setWarningMsg(<p>{CONSULTANT_LOCATION_WARNING_MSG}</p>);
                }

                if (
                  admin?.email !== adminData.email &&
                  consultantDetails?.status?.calendarSyncStatus &&
                  !isEqual(admin?.locations, adminData.locations)
                ) {
                  setIsConsultantWarning(true);
                  delaySave = true;
                  setWarningMsg(
                    <>
                      <p>{CONSULTANT_EMAIL_WARNING_MSG}</p>
                      <p>{CONSULTANT_LOCATION_WARNING_MSG}</p>
                      {isAdminConsultantValidateArray &&
                      adminConsultantValidateState?.consultantValidateObj
                        ?.length !== 0 ? (
                        <>
                          <p>{CONSULTANT_EMAIL_WARNING_MSG_IF_ASSIGN_TO_EMP}</p>
                          {empListCommonComp()}
                        </>
                      ) : null}
                    </>
                  );
                }
              }
            }

            if (brokerId && employerId && type === IndividualType.ER_ADMIN) {
              if (isConsultant && admin?.email !== adminData.email) {
                if (consultantDetails?.status?.calendarSyncStatus) {
                  setIsConsultantWarning(true);
                  delaySave = true;
                  setWarningMsg(
                    <>
                      <p>
                        Editing the email address will reset the calendar sync
                        and the consultant will not be available for
                        consultation until the calendar is re-synced.
                      </p>
                      {Boolean(
                        adminConsultantValidateState?.consultantValidateObj
                      ) ? (
                        <p>{CONSULTANT_EMAIL_WARNING_MSG_ER_ADMIN}</p>
                      ) : null}
                    </>
                  );
                } else {
                  isConsultantSave = true;
                }
              }
            }

            adminData.consultant = isConsultant && isConsultantSave;
            if (!delaySave) {
              updateAdminOnSave(adminData);
            }
          } else {
            await dispatch(createAdmin(createAdminType, adminData));
          }
        } catch (errorInfo) {
          // todo we need a proper way to handle errors. logging to console for now.
          console.log(errorInfo);
        }
      }
    } else {
      setRequireFieldErrorFound(true);
      setVisibleAlert(true);
      setAlertMessage(formVerificationMsg);
    }
  };

  const onConfirmSaveBrokerAdmin = (invite: boolean) => {
    const checkSelectedLocationsExists = checkLocationIds();
    if (
      !checkSelectedLocationsExists ||
      formData.individualType === IndividualType.OPS_ADMIN ||
      formData.individualType === IndividualType.IMPLEMENTATION_ADMIN
    ) {
      onClickSave(invite);
      showHideEmailTemplate(false);
    } else {
      setShowSaveBrokerConfirmation({
        show: true,
        invite: invite,
      });
    }
  };

  const updateAdminOnSave = (adminData: Admin) => {
    dispatch(updateAdmin(admin.id, adminData)).then(() => {
      if (appBootInfo?.individualId === admin.id) {
        dispatch(ping());
      }
    });
  };

  const onPrimaryContactToggle = () => {
    setPrimaryContact(!primaryContact);
  };

  const updateSelected = (
    selectedOptions: string[],
    allOptionsSelected: boolean
  ) => {
    setAllSelected(allOptionsSelected);
    setSelectedLocations(selectedOptions);
    if (allOptionsSelected || !isEmpty(selectedOptions)) {
      setLocationError(false);
      setVisibleAlert(false);
    } else {
      setLocationError(true);
    }
  };

  const handleUpload = (
    image: string,
    originalImage?: string,
    cropArea?: OriginalImageCropParams
  ) => {
    dispatch(uploadAdminAvatar(formData.inPlaceId, image, originalImage)).then(
      () => {
        setCropArea(cropArea);
      }
    );
    setOriginalAvatar(originalImage);
    setImageValidateSetting('');
  };

  const handleAvatarRemove = () => {
    setUploadedImage('');
    dispatch(updateAdminAvatarMap({ id: formData.inPlaceId, image: '' }));
  };

  const saveButtonText = () => {
    if (type === IndividualType.ORGANIZATION_ADMIN) {
      return 'Save Broker Admin';
    } else if (type === IndividualType.ER_ADMIN) {
      return 'Save Employer User';
    } else {
      return 'Save Tech Admin';
    }
  };

  const saveInviteButtonText = () => {
    if (type === IndividualType.ORGANIZATION_ADMIN) {
      return 'Save Broker Admin and Send Invite';
    } else if (type === IndividualType.ER_ADMIN) {
      return 'Save Employer User and Send Invite';
    } else {
      return 'Save Tech Admin and Send Invite';
    }
  };

  const confirmClose = () => {
    setEditMode(false);
    setPrimaryContact(false);
    setSelectedLocations([]);
    setAllSelected(false);
    setUploadedImage('');
    form.resetFields();
    closeAlert();
    dispatch(clearAdminCreation());
    dispatch(clearUpdateAdminUpdation());
    dispatch(clearAdminAvatar());
    setImageValidateSetting('');
    setAlertMessage('');
    setVisibleAlert(false);

    setInvalidEmailFormat(false);
    setInvalidPhoneNumber(false);
    setLocationError(false);
    setFormData(adminInitialState);
    setShowEmailTemplate({
      isShow: false,
      buttonText: 'Show a Preview of Invitation Email',
    });
    setOriginalAvatar(undefined);
    setIsOpsAdmin(false);
  };

  useEffect(() => {
    if (visibleConfirmation) {
      setConfirmatinModalOpen(true);
    }
  }, [
    visibleConfirmation,
    setConfirmatinModalOpen,
    showSaveBrokerConfirmation,
  ]);

  const onValidateFails = (validateSetting: string) => {
    setImageValidateSetting(validateSetting);
  };

  const showHideEmailTemplate = (show?: boolean) => {
    if (showEmailTemplate.isShow || (show !== undefined && !show)) {
      setShowEmailTemplate({
        isShow: false,
        buttonText: 'Show a Preview of Invitation Email',
      });
    } else {
      setShowEmailTemplate({
        isShow: true,
        buttonText: 'Hide Preview',
      });
    }
  };

  const getImageValidationErrorMsg = (validateSetting: string): ReactNode => {
    switch (validateSetting) {
      case FORMAT_VALIDATE:
        return 'This image file type is not supported.';
      case SIZE_VALIDATE:
        return 'Maximum size allowed for this upload is 2 MB.';
      default:
        setImageValidateSetting('');
        return;
    }
  };

  const numberOfLocations = brokerLocationsData?.locations
    ? size(brokerLocationsData?.locations)
    : 0;

  const rfpLocations = brokerLocationsData?.locations ?? {};

  const getPopOverContent = () => (
    <>
      {Object.values(rfpLocations).map((locationName: any, index: any) => (
        <div key={index}>{locationName}</div>
      ))}
    </>
  );

  const ADD_BROKER_ADMIN_WARNING_FOR_LOCAIONS = (
    <>
      You have reached the user limit for RFP in{' '}
      <Popover content={getPopOverContent()}>
        <u className={styles.underline}>
          <span className={styles.locationCount}>
            {' '}
            {numberOfLocations} Location(s).
          </span>
        </u>
      </Popover>{' '}
      Adding additional users broker admins to these locations will incur
      additional charges per the terms of your contract. Please note that if you
      do add additional broker admins beyond your current user limit, your
      contract will be automatically updated to reflect the additinal users and
      associated costs. <br />
      <br />
      Once you click &quot;Save&quot;, a memeber of the sales team will reach
      out to you with this information.
    </>
  );

  const ADD_BROKER_ADMIN_WARNING_FOR_BROKARAGE = (
    <>
      Your account has reached the user limit for RFP. Adding additional broker
      admins to the <span className={styles.locationCount}> locations </span>
      with access to RFP will incur additional charges per the terms of your
      contract. Please note that if you do add additional broker admins beyond
      your current user limit, your contract will be automatically updated to
      reflect the additional users and associated costs. <br />
      <br />
      Once you click &quot;Save&quot;, a memeber of the sales team will reach
      out to you with this information.
    </>
  );

  const ADD_BROKER_ADMIN_WARNING_TECH_ADMIN_FOR_BROKARAGE = (
    <>
      This account has reached the user limit for RFP. Adding additional broker
      admins to the <span className={styles.locationCount}> location</span> with
      access to RFP will incur additional charges per the terms of the contract.
      Please ensure that the user limit is updated in the Platform once it has
      been finalized.
    </>
  );

  const ADD_BROKER_ADMIN_WARNING_TECH_ADMIN_FOR_LOCATIONS = (
    <>
      This account has reached the user limit for RFP in{' '}
      <Popover content={getPopOverContent()}>
        <u className={styles.underline}>
          <span className={styles.locationCount}>
            {' '}
            {numberOfLocations} location(s).
          </span>
        </u>
      </Popover>{' '}
      Adding additional broker admins to these locations will incur additional
      charges per the terms of the contract. Please ensure that the user limit
      is updated in the Platform once it has been finalized.
    </>
  );

  const getWarningMessage = () => {
    if (isBrokerAdmin) {
      if (brokerLocationsData?.brokerRfp) {
        return ADD_BROKER_ADMIN_WARNING_FOR_BROKARAGE;
      } else if (brokerLocationsData?.locationRfp) {
        return ADD_BROKER_ADMIN_WARNING_FOR_LOCAIONS;
      }
    } else if (isTechAdmin) {
      if (brokerLocationsData?.brokerRfp) {
        return ADD_BROKER_ADMIN_WARNING_TECH_ADMIN_FOR_BROKARAGE;
      } else if (brokerLocationsData?.locationRfp) {
        return ADD_BROKER_ADMIN_WARNING_TECH_ADMIN_FOR_LOCATIONS;
      }
    }
  };

  const checkLocationIds = () => {
    const locationIds = brokerLocationsData?.locations;
    if (!isNullOrUndefined(locationIds)) {
      for (const item of selectedLocations ?? []) {
        if (locationIds.hasOwnProperty(item)) {
          return true;
        }
      }
    }
    return false;
  };

  const renderWarningConfirmation = () => {
    return (
      <>
        <p className={styles.warningConfirmation}>
          {isTechAdmin ? TECH_ADMIN_TEXT : BROKER_ADMIN_TEXT}
        </p>
        <ul>
          {selectedLocations.map((item: any) => {
            const locationsMap = brokerLocationsData?.locations;
            if (locationsMap) {
              const locationName = locationsMap[item];
              if (locationName) {
                return <li key={item?.locationId}>{locationName}</li>;
              }
            }
          })}
        </ul>
        <p className={styles.warningConfirmation}>
          {isTechAdmin ? TEECH_ADMIN_CONFIRM_TEXT : BROKER_ADMIN_CONFIRM_TEXT}
        </p>
      </>
    );
  };

  useEffect(() => {
    if (employerId && brokerId) {
      getErAdminsAvailability({ employerId, organizationId: brokerId });
    }
  }, [employerId, brokerId, getErAdminsAvailability]);

  useEffect(() => {
    if (!isNullOrUndefined(erAdminAvailability)) {
      setIsErAdminsExist(erAdminAvailability);
    }
  }, [erAdminAvailability]);

  return (
    <>
      {type !== IndividualType.ER_ADMIN &&
        brokerLocationsData?.exceeded &&
        (isTechAdmin || isBrokerAdmin) && (
          <div>
            <FixedAlertMessage type="warning" message={getWarningMessage()} />
          </div>
        )}
      {adminDetailsView &&
      editMode &&
      admin !== null &&
      type === IndividualType.ER_ADMIN ? (
        <>
          <Col span={8} offset={8}>
            <Row justify="center" className={styles.rowStyle}>
              <Col className={styles.adminDetailLabel} span={12}>
                Name
              </Col>
              <Col span={12} className={styles.adminDetailValue}>
                <OverflowPopover
                  maxWidth="350px"
                  popoverContent={`${admin.firstName} ${admin.lastName}`}
                >{`${admin.firstName} ${admin.lastName}`}</OverflowPopover>
              </Col>
            </Row>
            <Row justify="center" className={styles.rowStyle}>
              <Col className={styles.adminDetailLabel} span={12}>
                Title
              </Col>
              <Col span={12} className={styles.adminDetailValue}>
                {admin.title}
              </Col>
            </Row>
            <Row justify="center" className={styles.rowStyle}>
              <Col className={styles.adminDetailLabel} span={12}>
                Email
              </Col>
              <Col span={12} className={styles.adminDetailValue}>
                <OverflowPopover popoverContent={admin.email} maxWidth="350px">
                  {admin.email}
                </OverflowPopover>
              </Col>
            </Row>
            <Row justify="center" className={styles.rowStyle}>
              <Col className={styles.adminDetailLabel} span={12}>
                Phone
              </Col>
              <Col span={12} className={styles.adminDetailValue}>
                {admin.phone}
              </Col>
            </Row>
            <Row justify="center" className={styles.rowStyle}>
              <Col className={styles.adminDetailLabel} span={12}>
                Primary Contact
              </Col>
              <Col span={12} className={styles.adminDetailValue}>
                {admin.primary ? 'Yes' : 'No'}
              </Col>
            </Row>
            <Row justify="center" className={styles.rowStyle}>
              <Col className={styles.adminDetailLabel} span={12}>
                Account Status
              </Col>
              <Col span={12} className={styles.adminDetailValue}>
                {adminAccountStatus}
              </Col>
            </Row>

            <PageActionButton
              onClick={() => goToEditErAdmin()}
              disabled={
                adminAvatar.inProgress ||
                (userType === IndividualSubTypes.EMPLOYER_MEMBER &&
                  appBootInfo?.individualId !== admin?.id)
              }
              className={styles.erAdminEditButton}
              type="primary"
            >
              Edit Employer User
            </PageActionButton>
          </Col>
        </>
      ) : (
        <>
          {(duplicateEmailFound || imageValidateSetting || visibleAlert) && (
            <div className={styles.alertWrapper}>
              <AlertMessage
                type="error"
                message={
                  isEmpty(imageValidateSetting)
                    ? alertMessage
                    : getImageValidationErrorMsg(imageValidateSetting)
                }
                closeAlert={() => {
                  if (!isEmpty(imageValidateSetting)) {
                    setImageValidateSetting('');
                  }
                  closeAlert();
                }}
              />
            </div>
          )}
          <InputForm onValuesChange={onInputChange} form={form}>
            <Row gutter={48}>
              <Col span={24}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: EMPTY_MESSAGE,
                      whitespace: true,
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  className={
                    duplicateEmailFound || invalidEmailFormat
                      ? styles.labelError
                      : isEmptyField(formData.email)
                  }
                >
                  <Input id="email" size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={48}>
              <Col span={12}>
                <Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: EMPTY_MESSAGE,
                      whitespace: true,
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  className={isEmptyField(formData.firstName)}
                >
                  <Input id="firstName" size="large" />
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: EMPTY_MESSAGE,
                      whitespace: true,
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  className={isEmptyField(formData.lastName)}
                >
                  <Input id="lastName" size="large" />
                </Item>
              </Col>
            </Row>
            <Row gutter={48}>
              <Col span={12}>
                <Item
                  label="Phone"
                  name="phone"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  className={invalidPhoneNumber ? styles.labelError : ''}
                >
                  <InputPhoneNumber />
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  label="Title"
                  name="title"
                  rules={[{ required: false }]}
                  labelCol={{ span: 24 }}
                >
                  <Input id="title" size="large" />
                </Item>
              </Col>
            </Row>
            <Row gutter={48}>
              <>
                {type === IndividualType.ORGANIZATION_ADMIN && (
                  <Col span={12}>
                    <Form.Item
                      name="individualSubType"
                      label="User Type"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: EMPTY_MESSAGE,
                        },
                      ]}
                      className={isEmptyField(formData.individualSubType)}
                    >
                      <SelectOptions
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentElement
                        }
                        dropdownMatchSelectWidth={false}
                        options={getBrokerAdminType(
                          appBootInfo?.type,
                          appBootInfo?.individualSubType,
                          false
                        )}
                        className={styles.brokerAdminTypeWrapper}
                      />
                    </Form.Item>
                  </Col>
                )}
                {type === IndividualType.ER_ADMIN && (
                  <>
                    <Col span={12}>
                      <CustomEmployerUserDropDown
                        options={EMPLOYER_USER_TYPES_DROPDOWN}
                        formData={formData}
                        isEmptyField={isEmptyField}
                      />
                    </Col>
                    <Col span={12}></Col>
                  </>
                )}
                <Col span={12}>
                  <Form.Item
                    name="photoAvatar"
                    label="Photo Avatar"
                    labelCol={{ span: 24 }}
                  >
                    <ImageUploader
                      cropShape="round"
                      aspect={1}
                      description="Fit photo within constraints below."
                      onConfirm={handleUpload}
                      loading={adminAvatar.inProgress}
                      uploadedImage={
                        avatarImageMap[formData.inPlaceId]
                          ? avatarImageMap[formData.inPlaceId]
                          : uploadedImage
                      }
                      helpText={uploadImageSizeValidationMsg}
                      onValidateFails={onValidateFails}
                      showOperations
                      onRemove={handleAvatarRemove}
                      defaultCropArea={cropArea}
                      unCroppedImage={originalAvatar}
                    />
                  </Form.Item>
                </Col>
              </>
            </Row>
            {type === IndividualType.ORGANIZATION_ADMIN && (
              <Row gutter={48}>
                <Col span={24}>
                  <Row className={styles.rowStyle}>
                    <Col>
                      <span
                        className={
                          hasLocationError && isEmpty(selectedLocations)
                            ? styles.tooltipErrorLabel
                            : styles.tooltipLabel
                        }
                      >
                        What Locations Should This Admin Be Able To Access? *
                      </span>
                    </Col>
                    <Col>
                      <Popover
                        content={locationTooltipContent}
                        placement="right"
                        overlayClassName="popoverInner"
                      >
                        <QuestionCircleOutlined
                          className={
                            hasLocationError && isEmpty(selectedLocations)
                              ? styles.iconErrorColor
                              : ''
                          }
                        />
                      </Popover>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="location" labelCol={{ span: 24 }}>
                        <MultiSelectDropdown
                          options={
                            brokerLocations
                              ? convertBrokerLocationsToOptions(
                                  brokerLocations.employerLocations
                                )
                              : []
                          }
                          mode="horizontal"
                          updateSelected={updateSelected}
                          selected={selectedLocations}
                          allOptionsSelected={allSelected}
                          type={multiSelectTypes.ALL_SELECTABLE}
                          allOption="Access to All"
                          description="(including any new locations in the future by default.)"
                          displayAllOption={
                            brokerLocations && brokerLocations.selectAll
                          }
                          disableAllOption={true}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            <Row gutter={48}>
              {type !== IndividualType.LUMITY_ADMIN && (
                <Col>
                  <CheckboxSelect
                    checked={primaryContact && !isOpsAdmin && !isImplementAdmin}
                    onChange={onPrimaryContactToggle}
                    disabled={
                      (editMode && admin && admin.primary) ||
                      noERAdmins ||
                      isOpsAdmin ||
                      isImplementAdmin
                    }
                  >
                    <Text className={styles.checkboxText}>
                      Make Primary Contact
                    </Text>
                  </CheckboxSelect>
                </Col>
              )}
            </Row>
            {!editMode && (
              <Row justify="center" className={styles.rowStyle}>
                <Col>
                  <PageActionButton
                    onClick={() => onConfirmSaveBrokerAdmin(true)}
                    type="primary"
                    className={styles.confirmButton}
                    disabled={adminAvatar.inProgress}
                    loading={createAdminInProgress}
                  >
                    {saveInviteButtonText()}
                  </PageActionButton>
                </Col>
              </Row>
            )}
            {!editMode ? (
              <Row justify="center" className={styles.rowStyle}>
                <Col>
                  <SubmitButton
                    onClick={() => onConfirmSaveBrokerAdmin(false)}
                    disabled={adminAvatar.inProgress}
                    loading={createAdminInProgress}
                  >
                    {saveButtonText()}
                  </SubmitButton>
                </Col>
              </Row>
            ) : (
              <Row justify="center" className={styles.rowStyle}>
                <PlanyearPopover
                  isDisabled={!isSaveDisabled}
                  content={`Can not edit ER Admins that have mobile access`}
                >
                  <Col>
                    <PageActionButton
                      onClick={() => onConfirmSaveBrokerAdmin(false)}
                      disabled={adminAvatar.inProgress || isSaveDisabled}
                      className={styles.confirmButton}
                      type="primary"
                      loading={
                        adminStatus?.updateInProgress && !isConsultantWarning
                      }
                    >
                      {saveButtonText()}
                    </PageActionButton>
                  </Col>
                </PlanyearPopover>
              </Row>
            )}

            <Row justify="center" className={styles.cancelWrapper}>
              <Col>
                {type === IndividualType.ER_ADMIN && !admin ? (
                  <CancelButton
                    htmlType="button"
                    onClick={() => showHideEmailTemplate()}
                    block
                    disabled={isDisabled}
                  >
                    {showEmailTemplate.buttonText}
                  </CancelButton>
                ) : (
                  <CancelButton
                    htmlType="button"
                    onClick={() => {
                      toggleConfirmationPopup();
                    }}
                    block
                  >
                    Cancel
                  </CancelButton>
                )}
              </Col>
            </Row>
          </InputForm>
        </>
      )}
      {showEmailTemplate.isShow && (
        <div className={styles.invitationTemplate} ref={templateRef}>
          <InvitationTemplate firstName={formData.firstName} />
        </div>
      )}
      {visibleConfirmation && confirmatinModalOpen && (
        <ConfirmationDialog
          title="Are you sure you want to close?"
          confirmText="Yes - close and do not save"
          cancelText="Cancel"
          closeModal={toggleConfirmationPopup}
          onConfirm={() => {
            !editMode && confirmClose();
            confirmCloseModal();
            setAddBrokerAdminModal?.(false);
          }}
          visible={visibleConfirmation}
        >
          <p className={styles.warningConfirmation}>{exitWithoutSavingMsg}</p>
        </ConfirmationDialog>
      )}
      {isConsultantWarning && (
        <ConsultantWarnModal
          closeModal={() => setIsConsultantWarning(false)}
          onConfirm={() => {
            updateAdminOnSave(updatedAdmin);
          }}
          title={`This ${
            type === IndividualType.ORGANIZATION_ADMIN ? 'Broker' : 'Employer'
          } Admin is also a Benefits Consultant in the System`}
          confirmLoading={adminStatus?.updateInProgress}
          visible={isConsultantWarning}
          warningMsg={warningMsg}
        />
      )}
      <ConfirmationDialog
        title="Edit Broker Admin"
        confirmText="Close"
        isCloseOnly
        closeModal={() => {
          setIsLocationWarning(false);
          dispatch(clearUpdateAdminUpdation());
        }}
        onConfirm={() => {
          setIsLocationWarning(false);
          dispatch(clearUpdateAdminUpdation());
        }}
        visible={isLocationWarning}
        recordName={buildFullName(admin?.firstName, admin?.lastName)}
        isRecordApplicable
      >
        <p className={styles.warningConfirmation}>
          This locations update cannot be completed as this broker admin is the
          only team member assigned to the following employers. You must add at
          least one other team member to these employers before removing this
          broker admin’s access.
        </p>
        {!isEmpty(
          adminStatus?.adminUpdateError?.data?.details?.affectedEmployers
        ) ? (
          <div className={styles.additionalWarningSectionList}>
            <ListComponent
              className={styles.employerList}
              dataSource={adminStatus?.adminUpdateError?.data?.details?.affectedEmployers.map(
                (item: any) => ({ label: item?.name, value: item?.id })
              )}
              onClickItem={viewEmployer}
            />
          </div>
        ) : (
          <></>
        )}
      </ConfirmationDialog>
      <ConfirmationDialog
        visible={showSaveBrokerConfirmation.show ?? false}
        title="Save Broker Admin"
        confirmText="Continue"
        cancelText="Cancel"
        closeModal={() => {
          setShowSaveBrokerConfirmation({
            show: false,
            invite: false,
          });
        }}
        onConfirm={() => {
          onClickSave(showSaveBrokerConfirmation.invite);
          showHideEmailTemplate(false);
          setShowSaveBrokerConfirmation({
            show: false,
            invite: false,
          });
        }}
        closeBtnAdditionalStyling={styles.cancelConfirmationBtn}
      >
        {renderWarningConfirmation()}
      </ConfirmationDialog>
    </>
  );
};

export default CreateAdmin;
