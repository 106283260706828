import axios from 'axios';
import { Dispatch } from 'redux';
import { createSlice } from '@reduxjs/toolkit';
import * as BrokerService from 'modules/brokers/services/BrokerService';
import Broker from 'model/Broker';
import ErrorDetails from 'model/ErrorDetails';
import Employer from 'model/Employer';
import { getEmployersByOrganizationId } from 'modules/brokers/services/BrokerService';

export interface BrokerDashBoardState {
  brokerObj: Broker;
  getBrokerDetailsInProgress: boolean;
  getbrokerDetailsError: ErrorDetails | null;
  getbrokerDetailsSuccess: boolean;
  employerList: Employer[];
  inProgress: boolean;
  error: ErrorDetails | null;
}

const initialState = {
  brokerObj: {} as Broker,
  getBrokerDetailsInProgress: false,
  getbrokerDetailsError: null,
  getbrokerDetailsSuccess: false,
  employerList: [],
  inProgress: false,
  error: null,
} as BrokerDashBoardState;

const brokerDashBoardSlice = createSlice({
  name: 'brokerDashboard',
  initialState,
  reducers: {
    getBrokerDetailsStarted: (state: {
      getBrokerDetailsInProgress: boolean;
    }) => {
      state.getBrokerDetailsInProgress = true;
    },
    getBrokerDetailsSuccess: (
      state: { getBrokerDetailsInProgress: boolean; brokerObj: any },
      { payload }: any
    ) => {
      state.getBrokerDetailsInProgress = false;
      state.brokerObj = payload;
    },
    getBrokerDetailsError: (
      state: {
        getBrokerDetailsInProgress: boolean;
        getbrokerDetailsError: any;
      },
      { payload }: any
    ) => {
      state.getBrokerDetailsInProgress = false;
      state.getbrokerDetailsError = { response: payload };
    },
    clearBrokerDashboard: (state) => {
      state.brokerObj = {} as Broker;
      state.getBrokerDetailsInProgress = false;
      state.getbrokerDetailsError = null;
      state.getbrokerDetailsSuccess = false;
    },
    getEmployerListInProgress: (state) => {
      state.inProgress = true;
      state.error = null;
    },
    getEmployerListSuccess: (state, { payload }) => {
      state.inProgress = false;
      state.employerList = payload;
      state.error = null;
    },
    getEmployerListFailed: (state, { payload }) => {
      state.inProgress = false;
      state.error = { data: payload?.data };
    },
  },
});

export const {
  getBrokerDetailsStarted,
  getBrokerDetailsSuccess,
  getBrokerDetailsError,
  clearBrokerDashboard,
  getEmployerListInProgress,
  getEmployerListSuccess,
  getEmployerListFailed,
} = brokerDashBoardSlice.actions;

export default brokerDashBoardSlice.reducer;

export const getBrokerSummary =
  (brokerId: string) => async (dispatch: Dispatch) => {
    dispatch(getBrokerDetailsStarted());
    try {
      const response = await BrokerService.getBrokerDetails(brokerId);
      dispatch(getBrokerDetailsSuccess(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getBrokerDetailsError(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };

export const getEmployerList = (id: string) => (dispatch: any) => {
  dispatch(getEmployerListInProgress());
  getEmployersByOrganizationId(id)
    .then(({ data }) => {
      dispatch(getEmployerListSuccess(data));
    })
    .catch((error) => {
      if (axios.isAxiosError(error)) {
        dispatch(
          getEmployerListFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    });
};
