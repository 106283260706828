import React from 'react';
import { Popover } from 'antd';
import { LlmInfo } from 'model/plans/LLMExtraction';
import { ReactComponent as InfoIconDisabled } from 'assets/images/ai-icon.svg';
import { ReactComponent as InfoIconEnabled } from 'assets/images/ai-icon-enabled.svg';
import { isNullOrUndefined } from 'modules/plans/utils';
import {
  LLM_EXTRACTION_POPOVER_TEXT_DISABLED,
  LLM_EXTRACTION_POPOVER_TEXT_ENABLED,
} from 'modules/renewals/constants/renewalsConstants';
import { useAppDispatch } from 'hooks/redux';
import { setCurrentPage } from 'modules/plans/slices/aiSbcUploaderSlice';

import styles from './infoIcon.module.less';

interface InfoIconComponentProps {
  showIcon: boolean;
  extractionInfo?: LlmInfo | undefined | null;
  className?: string;
}

const InfoIconComponent: React.FC<InfoIconComponentProps> = (props) => {
  const dispatch = useAppDispatch();
  if (!props.showIcon) return null;

  const { extractionInfo, className = '' } = props;
  const { page = null } = extractionInfo || {};

  const pageDisable = isNullOrUndefined(page) || (page && page === 0);

  const handlePageChange = () => {
    if (!pageDisable) {
      const currentSelectedPage = Number(page) - 1;
      dispatch(setCurrentPage(currentSelectedPage));
    }
  };

  return (
    <span className={className} onClick={handlePageChange}>
      <Popover
        overlayClassName="popoverInner recommendedtooltip"
        trigger={'hover'}
        content={
          pageDisable
            ? LLM_EXTRACTION_POPOVER_TEXT_DISABLED
            : LLM_EXTRACTION_POPOVER_TEXT_ENABLED
        }
      >
        {pageDisable ? (
          <InfoIconDisabled />
        ) : (
          <InfoIconEnabled className={styles.infoIcon} />
        )}
      </Popover>
    </span>
  );
};

export default InfoIconComponent;
