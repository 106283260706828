import { Dispatch } from 'redux';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as BrokerService from 'modules/brokers/services/BrokerService';
import * as EmployerService from 'modules/employers/services/EmployerService';
import * as CarrierService from 'modules/carriers/services/CarrierService';
import * as AssistantService from 'modules/assistant/services/AssistantServices';

import Broker from 'model/Broker';
import Employer from 'model/Employer';
import ErrorDetails from 'model/ErrorDetails';
import BenefitCarrier from 'model/BenefitCarrier';

import { navContexts } from 'constants/authConstants';
import { transformHistoryResponseToSideNav } from 'modules/assistant/utils/chatHistoryUtils';
import { benefitTypes } from 'modules/carriers/constants/carrierConstants';

import informationIcon from 'assets/images/navigation/Information.svg';
import InformationBenefit from 'assets/images/navigation/InformationBenefit.svg';
import { DESIGNATED_USER_ACTIVITY_EXPIRATION_TIME } from 'util/userActivityUtilis';

export interface LayoutState {
  inProgress: boolean;
  broker: Broker | null;
  employer: Employer | null;
  error: ErrorDetails | null;
  carrier: BenefitCarrier | null;
  carrierRoutes: any;
  aiAssistantChatRoutes: any;
  sessionExpirySeconds: number;
  sessionExpiryDto: {
    isRefreshRequired: boolean;
    isRefreshSwapped: boolean;
    isResetTriggered: boolean;
  };
}

const initialState = {
  inProgress: false,
  broker: {} as Broker,
  employer: {} as Employer,
  error: null,
  carrier: {} as BenefitCarrier,
  carrierRoutes: [],
  aiAssistantChatRoutes: [],
  sessionExpirySeconds: DESIGNATED_USER_ACTIVITY_EXPIRATION_TIME,
  sessionExpiryDto: {
    isRefreshRequired: false,
    isRefreshSwapped: true,
    isTimerStarted: true,
    sessionExpirySeconds: DESIGNATED_USER_ACTIVITY_EXPIRATION_TIME,
    isResetTriggered: false,
  },
} as LayoutState;

type Children = {
  path: string;
  name: string;
};

type BenefitCategory = {
  path: string;
  name: string;
  displayName: string;
  icon?: string;
  children?: Children[];
};

const getNavigateUrl = (context: string) => {
  if (context === navContexts.platform) {
    return `/carriers/:carrierId`;
  } else if (context === navContexts.broker) {
    return `/brokers/:brokerId/carriers/:carrierId`;
  } else {
    return `/brokers/:brokerId/employers/:employerId/carriers/:carrierId`;
  }
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    brokerFetchStarted: (state) => {
      state.inProgress = true;
      state.error = null;
    },
    brokerFetchSucceeded: (state, action: PayloadAction<Broker>) => {
      state.broker = action.payload;
      state.inProgress = false;
      state.error = null;
    },
    brokerFetchFailed: (state, { payload }) => {
      state.inProgress = false;
      state.error = payload;
    },
    employerFetchStarted: (state) => {
      state.inProgress = true;
      state.error = null;
    },
    employerFetchSucceeded: (state, action: PayloadAction<Employer>) => {
      state.employer = action.payload;
      state.inProgress = false;
      state.error = null;
    },
    employerFetchFailed: (state, { payload }) => {
      state.inProgress = false;
      state.error = payload;
    },
    clearEmployer: (state) => {
      state.employer = null;
    },
    clearBroker: (state) => {
      state.broker = null;
      state.employer = null;
      state.inProgress = false;
      state.error = null;
    },
    carrierFetchStarted: (state) => {
      state.inProgress = true;
      state.error = null;
    },
    carrierFetchSucceeded: (state, action: any) => {
      state.carrier = action.payload.data;
      const benefitCategoriesList = [
        {
          path: `${getNavigateUrl(action.payload.context)}/info`,
          name: 'Carrier Info',
          displayName: 'Carrier Info',
          icon: informationIcon,
        },
      ] as BenefitCategory[];
      if (
        state.carrier &&
        state.carrier.benefitCarrierVOS &&
        state.carrier.benefitCategories
      ) {
        const benCategories = state.carrier.benefitCategories;
        benCategories.sort((a, b) => {
          return (
            Object.keys(benefitTypes).indexOf(a) -
            Object.keys(benefitTypes).indexOf(b)
          );
        });
        benCategories.forEach(function (value: any) {
          const child =
            state.carrier &&
            state.carrier.benefitCarrierVOS.filter(
              (category: any) => category.benefitCategory === value
            );
          if (child.length === 1) {
            benefitCategoriesList.push({
              path: `${getNavigateUrl(action.payload.context)}/${
                benefitTypes[child[0].benefitCategory].value
              }/${child[0].id}`,
              name: benefitTypes[child[0].benefitCategory].label,
              displayName: benefitTypes[child[0].benefitCategory].label,
              icon: InformationBenefit,
            });
          } else if (child.length > 1) {
            const subCategories = [] as BenefitCategory[];
            child.forEach(function (value: any) {
              subCategories.push({
                path: `${getNavigateUrl(action.payload.context)}/${
                  benefitTypes[value.benefitCategory].value
                }/${value.id}`,
                name: value.name,
                displayName: value.name,
                icon: InformationBenefit,
                children: [
                  {
                    path: `${getNavigateUrl(action.payload.context)}/${
                      benefitTypes[value.benefitCategory].value
                    }/${value.id}`,
                    name: benefitTypes[value.benefitCategory].label,
                  },
                ],
              });
            });
            const subCategoriesWithChildren = [
              {
                path: `${getNavigateUrl(action.payload.context)}/${
                  benefitTypes[child[0].benefitCategory].value
                }/${child[0].id}`,
                name: `${benefitTypes[child[0].benefitCategory].label} (${
                  subCategories.length
                })`,
                displayName: `${
                  benefitTypes[child[0].benefitCategory].label
                } (${subCategories.length})`,
                icon: InformationBenefit,
                children: subCategories,
              },
            ];
            benefitCategoriesList.push(...subCategoriesWithChildren);
          }
        });
      }
      state.carrierRoutes = [
        {
          path: `${getNavigateUrl(action.payload.context)}/info`,
          name: 'Carriers',
          displayName: 'Carrier Info',
          children: benefitCategoriesList,
        },
      ];
      state.inProgress = false;
      state.error = null;
    },
    carrierFetchFailed: (state, { payload }) => {
      state.inProgress = false;
      state.error = payload;
    },
    clearCarrier: (state) => {
      state.carrier = null;
      state.inProgress = false;
      state.error = null;
      state.carrierRoutes = [];
    },
    chatHistoryFetchStarted: (state) => {
      state.inProgress = true;
      state.error = null;
    },
    chatHistoryFetchSucceeded: (state, { payload }: PayloadAction<any>) => {
      state.aiAssistantChatRoutes = payload;
      state.inProgress = false;
      state.error = null;
    },
    chatHistoryFetchFailed: (state, { payload }) => {
      state.inProgress = false;
      state.error = payload;
    },
    clearChatHistory: (state) => {
      state.aiAssistantChatRoutes = [];
      state.inProgress = false;
      state.error = null;
    },
    setTokenRefreshRequired: (state, { payload }) => {
      state.sessionExpiryDto = {
        ...state.sessionExpiryDto,
        isRefreshSwapped: false,
        isResetTriggered: false,
        isRefreshRequired: payload,
      };
    },
    resetTokenRefreshRequired: (state) => {
      state.sessionExpiryDto = {
        ...state.sessionExpiryDto,
        isRefreshSwapped: true,
        isRefreshRequired: false,
        isResetTriggered: true,
      };
    },
  },
});

export const {
  brokerFetchStarted,
  brokerFetchSucceeded,
  brokerFetchFailed,
  employerFetchFailed,
  employerFetchStarted,
  employerFetchSucceeded,
  clearBroker,
  clearEmployer,
  carrierFetchStarted,
  carrierFetchSucceeded,
  carrierFetchFailed,
  clearCarrier,
  chatHistoryFetchStarted,
  chatHistoryFetchSucceeded,
  chatHistoryFetchFailed,
  clearChatHistory,
  setTokenRefreshRequired,
  resetTokenRefreshRequired,
} = layoutSlice.actions;
export default layoutSlice.reducer;

export const getBroker = (brokerId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(brokerFetchStarted());
    try {
      const response = await BrokerService.findBroker(brokerId);
      const data = response.data;
      dispatch(brokerFetchSucceeded(data));
    } catch (error: any) {
      dispatch(brokerFetchFailed(error.response));
    }
  };
};

export const getEmployer = (employerId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(employerFetchStarted());
    try {
      const response = await EmployerService.findEmployer(employerId);
      const data = response.data;
      dispatch(employerFetchSucceeded(data));
    } catch (error: any) {
      dispatch(employerFetchFailed(error.response));
    }
  };
};

export const getCarrier = (
  organizationId: string | null | undefined,
  employerId: string | null | undefined,
  type: string,
  carrierId: string | null,
  context: string | null
) => {
  return async (dispatch: Dispatch) => {
    dispatch(carrierFetchStarted());
    try {
      const response = await CarrierService.getCarrierById(
        organizationId,
        employerId,
        type,
        carrierId
      );
      const data = { data: response.data, context: context };
      dispatch(carrierFetchSucceeded(data));
    } catch (error: any) {
      dispatch(carrierFetchFailed(error.response));
    }
  };
};

export const getChatHistory = () => async (dispatch: Dispatch) => {
  try {
    dispatch(chatHistoryFetchStarted());
    const response = await AssistantService.getChatHistoryList();
    const transformedArray = transformHistoryResponseToSideNav(response.data);
    dispatch(chatHistoryFetchSucceeded(transformedArray));
  } catch (error: any) {
    dispatch(chatHistoryFetchFailed(error.response));
  }
};

export const setRefreshTokenRequired =
  (isRequired: boolean) => (dispatch: Dispatch) => {
    dispatch(setTokenRefreshRequired(isRequired));
  };

export const resetRefreshRequirement = () => (dispatch: Dispatch) => {
  dispatch(resetTokenRefreshRequired());
};
