import { useState } from 'react';
import { Row } from 'antd';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import {
  COLOR_BILLING_CHART_STOKE,
  COLOR_LIGHT_GRAY,
  COLOR_LEGEND_LABEL_SHADE,
  COLOR_LIGHT_GREY,
} from 'modules/claims/constants/constants';
import { shortenCurrencyWithSuffix } from 'util/commonUtil';
import { ReactComponent as ChartEmptySection } from 'assets/images/dashboardUtils/chart-empty-section.svg';
import styles from 'modules/employers/components/EmptyBillingBarChart/billingBarChart.module.less';

const EmptyBillingBarChart = () => {
  const defaultScreenData = [
    {
      name: `Jan '22`,
      amount: 2400,
    },
    {
      name: `Feb '22`,
      amount: 1398,
    },
    {
      name: `Mar '22`,
      amount: 8900,
    },
    {
      name: `Apr '22`,
      amount: 6908,
    },
    {
      name: `May '22`,
      amount: 7900,
    },
  ];

  const [chartObj] = useState<any>(defaultScreenData);

  const xAxisTick = (value: string, x: number, y: number) => {
    return (
      <ChartEmptySection
        x={x - 115}
        y={y - 120}
        fill={COLOR_LEGEND_LABEL_SHADE}
      />
    );
  };

  return (
    <div className={styles.billingView}>
      <Row>
        <ResponsiveContainer width="99%" height={330}>
          <BarChart data={chartObj} className={styles.barChart}>
            <CartesianGrid
              vertical={false}
              stroke={COLOR_BILLING_CHART_STOKE}
            />
            <XAxis
              dataKey="name"
              hide={false}
              axisLine={false}
              minTickGap={0}
              tickMargin={10}
              interval={0}
              padding={{ left: 0, right: 0 }}
              label={''}
              tick={({ payload, x, y }: any) => {
                const { value } = payload;
                return xAxisTick(value, x, y);
              }}
              tickLine={{ height: 40, stroke: COLOR_LIGHT_GREY }}
              height={50}
            />
            <YAxis
              hide={false}
              axisLine={false}
              tickFormatter={(value: number) => {
                return `${shortenCurrencyWithSuffix(value, 2)}`;
              }}
              tick={(data) => {
                return (
                  <g
                    className={styles.yAxisTick}
                    transform={`translate(${0},${data.y})`}
                  >
                    <text
                      x={20}
                      y={data.index === 4 ? -2 : 5}
                      textAnchor="start"
                      fill="#666"
                      className={styles.yAxisTick}
                    >
                      <>$</>
                    </text>
                  </g>
                );
              }}
              minTickGap={0}
              width={70}
              tickMargin={10}
              label={''}
              tickLine={{ height: 40, stroke: COLOR_LIGHT_GREY }}
              height={50}
            />
            <Bar
              className={styles.barChart}
              fill={COLOR_LIGHT_GRAY}
              dataKey="amount"
              maxBarSize={60}
              radius={5}
              wordSpacing={20}
            >
              {chartObj.map((data: any, index: any) => {
                return <Cell fill={COLOR_LIGHT_GRAY} key={index} />;
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Row>
    </div>
  );
};
export default EmptyBillingBarChart;
