import { lumityApi } from 'api/apiSlice';
import { DocumentExtractionHistoryResponseVO } from 'modules/renewals/models/AiDocumentExtractionHistoryResponseVO';

const CARRIER_QUOTE_API_PREFIX = 'v1/document-extraction/carrier-quote';

const carrierQuoteFilesAiSlice = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecentUploadedQuoteFiles: builder.query<
      DocumentExtractionHistoryResponseVO[],
      { brokerId: string; employerId: string }
    >({
      query: ({ brokerId, employerId }) => {
        const queryParams = `?organization-id=${brokerId}&employer-id=${employerId}`;
        return {
          url: `${CARRIER_QUOTE_API_PREFIX}${queryParams}`,
          method: 'GET',
        };
      },
    }),

    getQuoteUploadHistory: builder.query<
      DocumentExtractionHistoryResponseVO[],
      { brokerId: string; employerId: string; category: string }
    >({
      query: ({ brokerId, employerId, category }) => {
        const modifiedCategory =
          category === 'LIFE-ADD' ? 'BASIC_LIFE' : category;
        const queryParams = `?organization-id=${brokerId}&employer-id=${employerId}&benefit-category=${modifiedCategory}`;
        return {
          url: `${CARRIER_QUOTE_API_PREFIX}${queryParams}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetRecentUploadedQuoteFilesQuery,
  useGetQuoteUploadHistoryQuery,
} = carrierQuoteFilesAiSlice;
