import {
  ChangeEvent,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Menu } from 'antd';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import {
  createRenderColumns,
  DataColumn,
} from 'components/DataTable/DataColumn';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import InfoBanner from 'components/InfoBanner/InfoBanner';
import SearchBar from 'components/SearchBar/SearchBar';
import DataTable from 'components/DataTable/DataTable';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';

import IncompleteDetailsPopover from 'modules/benefitsConsultation/components/IncompleteDetailsPopover/IncompleteDetailsPopover';
import AddEmployerConsultants from 'modules/benefitsConsultation/pages/AddEmployerConsultants/AddEmployerConsultants';
import SendInviteModal from 'modules/benefitsConsultation/components/SendInviteModal/SendInviteModal';
import EmployerConsultantDetails from 'modules/benefitsConsultation/pages/EmployerConsultantDetails/EmployerConsultantDetails';

import TablePagination from 'model/TablePagination';
import BenefitsConsultant from 'model/benefitsConsultation/BenefitsConsultant';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  addRemoveConsultantsSuccess,
  getEmployerERConsultantsList,
} from 'modules/benefitsConsultation/slices/employerLevelSlice';
import {
  deleteConsultant,
  deleteConsultantInProgress,
  deleteConsultantSuccess,
  editConsultantDetailsSuccess,
  getConsultantDetails,
  inviteConsultantsSuccess,
} from 'modules/benefitsConsultation/slices/commonSlice';

import {
  ConsultationTypes,
  SEND_INVITE_NOW,
} from 'modules/benefitsConsultation/constants/benefitsConsultation';

import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
  mapValueToLabel,
} from 'util/commonUtil';
import { buildInitials } from 'util/stringUtil';
import consultationIcon from 'assets/images/consultation-large.svg';

import {
  CONSULTANT_EMPLOYER_LEVEL_EMPLOYER_CONFIRMATION,
  CONSULTANT_EMPLOYER_LEVEL_EMPLOYER_NON_CONFIRMATION,
} from 'modules/admins/constants/adminConstants';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import {
  EMPLOYER_MEMBER_RESTRICTED,
  FEATURE_KEYS,
  IndividualSubTypes,
} from 'constants/commonConstants';
import EmployerFeatureDisabledBanner from 'modules/tools/components/EmployerFeatureDisabledBanner/EmployerFeatureDisabledBanner';
import styles from './employerConsultation.module.less';

const convertBrokerConsultantsForTableRender = (
  brokerConsultants: BenefitsConsultant[]
): any[] => {
  if (brokerConsultants != null) {
    return brokerConsultants.map((element) => ({
      key: element.id,
      consultant: element,
      name: element.fullName,
      email: element.email,
      type: element.type,
      status: element,
      actions: element,
    }));
  }
  return [];
};

const EmployerConsultation: FC = () => {
  const dispatch = useAppDispatch();

  const employerERConsultants = useAppSelector(
    (state) => state.benefitsConsultation.employerLevel.employerERConsultants
  );
  const individualSubType = useAppSelector(
    (state) => state?.auth?.auth?.appBootupInfo?.individualSubType
  );
  const requestType = useAppSelector(
    (state) => state.benefitsConsultation.employerLevel.requestType
  );

  const inProgress = useAppSelector(
    (state) => state.benefitsConsultation.employerLevel.inProgress
  );

  const pendingInviteList = useAppSelector(
    (state) =>
      state.benefitsConsultation.employerLevel.pendingInviteConsultantsList
  );

  const requestTypeCommon = useAppSelector(
    (state) => state.benefitsConsultation.common.requestType
  );
  const requestTypeInvite = useAppSelector(
    (state) => state.benefitsConsultation.common.requestTypeInvite
  );

  const consultant = useAppSelector(
    (state) => state.benefitsConsultation.common.consultant
  );

  const employerId = useAppSelector((state) => state.layout.employer?.id);
  const organizationId = useAppSelector(
    (state) => state.layout.employer?.organizationId
  );

  const [addConsultantsModalVisible, setAddConsultantsModalVisible] =
    useState<boolean>(false);
  const [sendInvitesVisible, setSendInvitesVisible] = useState<boolean>(false);
  const [visibleConfirmationDelete, setVisibleConfirmationDelete] =
    useState<boolean>(false);
  const [consultantDetailsModalVisible, setConsultantDetailsModalVisible] =
    useState<boolean>(false);
  const [showEditMode, setShowEditMode] = useState<boolean>(false);
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });

  const [consultantId, setConsultantId] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );

  const [isSingleConsultant, setIsSingleConsultant] = useState<boolean>(false);

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  const updateTableData = useCallback(() => {
    if (organizationId && employerId) {
      dispatch(
        getEmployerERConsultantsList(
          convertPaginationConfig(paginationConfig),
          organizationId,
          employerId
        )
      );
    }
  }, [dispatch, employerId, organizationId, paginationConfig]);

  useEffect(() => {
    updateTableData();
  }, [updateTableData]);

  const { content, metadata } = employerERConsultants || {};

  const { total } = metadata;
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  const getData = (filters: TablePagination) => {
    if (filters.sorterInfo) {
      const paginationConfigData = {
        sorterInfo: filters.sorterInfo,
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      };
      setPaginationConfig(paginationConfigData);
    } else {
      const paginationConfigData = {
        sorterInfo: {
          columnKey: 'name',
          field: 'name',
          order: 'ascend',
        },
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      } as TablePagination;
      setPaginationConfig(paginationConfigData);
    }
  };

  useEffect(() => {
    if (
      !addConsultantsModalVisible &&
      requestType === addRemoveConsultantsSuccess.type
    ) {
      updateTableData();
      setSendInvitesVisible(true);
    }
  }, [addConsultantsModalVisible, requestType, updateTableData]);

  useEffect(() => {
    if (requestTypeCommon === deleteConsultantSuccess.type) {
      setVisibleConfirmationDelete(false);
      setConsultantDetailsModalVisible(false);
      updateTableData();
    }
  }, [requestTypeCommon, updateTableData]);

  useEffect(() => {
    if (requestTypeInvite === inviteConsultantsSuccess.type) {
      updateTableData();
    }
  }, [requestTypeInvite, updateTableData]);

  useEffect(() => {
    if (requestType === editConsultantDetailsSuccess.type) {
      if (organizationId && employerId) {
        dispatch(
          getEmployerERConsultantsList(
            convertPaginationConfig(paginationConfig),
            organizationId,
            employerId
          )
        );
      }
    }
  }, [dispatch, employerId, organizationId, paginationConfig, requestType]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setSearchText(_searchText);
    const pageInfo = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText: _searchText },
    };
    debounceLoadData(pageInfo);
  };

  const confirmDelete = () => {
    if (!isEmpty(consultantId)) {
      dispatch(deleteConsultant(consultantId));
    }
  };
  const toggleConfirmationDeletePopup = () => {
    setVisibleConfirmationDelete(!visibleConfirmationDelete);
    setIsSingleConsultant(false);
  };

  const handleDelete = (consultant: BenefitsConsultant) => {
    setConsultantId(consultant.id);
    // status completed
    if (consultant.status.status) {
      const completedEmployers = employerERConsultants?.content.filter(
        (obj) => obj.status.status
      );
      if (completedEmployers?.length == 1) setIsSingleConsultant(true);
    }
    setVisibleConfirmationDelete(true);
  };

  const brokerConsultantsTableColumns: DataColumn[] = [
    {
      title: 'CONSULTANT',
      dataIndex: 'consultant',
      key: 'consultant',
      sorter: (a: any, b: any) =>
        a.consultant.fullName.localeCompare(b.consultant.fullName),
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: 'ascend',
      render: (consultant) => {
        const firstName = consultant.fullName.split(' ')[0] || '';
        const lastName = consultant.fullName.split(' ')[1] || '';
        return (
          <div className={styles.name}>
            <div className={styles.avatar}>
              <ProfileAvatar
                content={buildInitials(firstName, lastName)}
                src={consultant.avatarUrl}
              />
            </div>

            <span
              className={styles.nameColStyle}
              onClick={() => {
                dispatch(getConsultantDetails(consultant.id));
                setConsultantDetailsModalVisible(true);
              }}
            >
              <OverflowPopover
                popoverContent={consultant.fullName}
                maxWidth="200px"
              >
                {consultant.fullName}
              </OverflowPopover>
            </span>
          </div>
        );
      },
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      ellipsis: {
        showTitle: false,
      },
      render: (email) => (
        <OverflowPopover popoverContent={email}>{email}</OverflowPopover>
      ),
    },
    {
      title: 'CONSULTANT TYPE',
      dataIndex: 'type',
      key: 'type',
      render: (type) => <>{mapValueToLabel(type, ConsultationTypes)}</>,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      defaultSortOrder: 'ascend',
      sorter: (a: any, b: any) => a.status.status - b.status.status,

      render: (data: BenefitsConsultant) => {
        return (
          <b>
            {!data.status.status ? (
              <div>
                {individualSubType === IndividualSubTypes.EMPLOYER_MEMBER ? (
                  <span className={styles.incompleteTextDisabled}>
                    Incomplete
                  </span>
                ) : (
                  <IncompleteDetailsPopover
                    setAlertMessage={setAlertMessage}
                    setAlertVisible={setAlertVisible}
                    benefitsConsultant={data}
                    setShowEditMode={setShowEditMode}
                    setConsultantDetailsModalVisible={
                      setConsultantDetailsModalVisible
                    }
                  >
                    <span className={styles.incompleteText}>Incomplete</span>
                  </IncompleteDetailsPopover>
                )}
              </div>
            ) : (
              <>Complete</>
            )}
          </b>
        );
      },
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '12%',
      render: (consultant: BenefitsConsultant) => {
        return (
          <div className={styles.dropdownWrapper}>
            <SelectDropdown overlay={brokerActions(consultant)} />
          </div>
        );
      },
      align: 'left',
    },
  ];

  const brokerActions = (consultant: BenefitsConsultant) => {
    return (
      <Menu>
        <Menu.Item
          key="consultantInfo"
          onClick={() => {
            dispatch(getConsultantDetails(consultant.id));
            setShowEditMode(false);
            setConsultantDetailsModalVisible(true);
          }}
        >
          Consultant Details
        </Menu.Item>
        <SecureRemoveConsultation row={consultant} />
      </Menu>
    );
  };

  const SecureRemoveConsultation = (row: any): ReactElement | null => {
    return usePermitByUserType(
      <Menu.Item
        key="remove"
        onClick={() => {
          handleDelete(row.row);
        }}
      >
        Remove Consultant
      </Menu.Item>,
      EMPLOYER_MEMBER_RESTRICTED
    );
  };

  const addEmployerConsultantButton = (
    <PageActionButton
      type="primary"
      onClick={() => {
        setAddConsultantsModalVisible(true);
      }}
      className={`${styles.actionButton}`}
    >
      Add Employer Consultant
    </PageActionButton>
  );

  const securedAddEmployerConsultantButton = usePermitByUserType(
    addEmployerConsultantButton,
    EMPLOYER_MEMBER_RESTRICTED
  );

  return (
    <>
      <EmployerFeatureDisabledBanner
        feature={FEATURE_KEYS.BENEFIT_CONSULTATION}
        featureName="Employer Consultants"
        employerId={employerId}
      />
      <div className={styles.employerConsultationWrapper}>
        <div className={styles.alertWrapper}>
          {alertVisible && (
            <AlertMessage
              type={alertMessage.type}
              message={alertMessage.message}
              closeAlert={() => setAlertVisible(false)}
            />
          )}
        </div>
        <div className={styles.headerSection}>
          <div className={styles.heading}>
            <h1>Employer Consultants</h1>
          </div>
          {securedAddEmployerConsultantButton}
        </div>
        {!inProgress && isEmpty(content) && isEmpty(searchText) ? (
          <InfoBanner
            title="You have no Employer Consultants added!"
            description="Get started by adding your first Consultant."
            logo={<img src={consultationIcon} alt="next-icon" />}
            className={styles.infoBanner}
          />
        ) : (
          <>
            <div className={styles.searchBar}>
              <SearchBar
                placeholder="Search Employer Consultants"
                onChange={handleSearch}
              />
            </div>
            <div className={styles.summaryTable}>
              <DataTable
                currentIndex={paginationConfig.paginationIndex}
                data={convertBrokerConsultantsForTableRender(content)}
                columns={createRenderColumns(brokerConsultantsTableColumns)}
                pagination
                getData={getData}
                total={total}
                pageSize={limit}
                loading={inProgress}
              />
            </div>
          </>
        )}
        <AddEmployerConsultants
          visible={addConsultantsModalVisible}
          setVisible={setAddConsultantsModalVisible}
          organizationId={organizationId}
          employerId={employerId}
        />

        <SendInviteModal
          pendingInviteList={pendingInviteList}
          visible={!isEmpty(pendingInviteList) && sendInvitesVisible}
          onCancel={(event, type) => {
            setSendInvitesVisible(false);
            setAlertVisible(true);
            setAlertMessage({
              type: 'success',
              message: `${
                type === SEND_INVITE_NOW
                  ? 'Employer Consultant(s) have been successfully added and invites have been sent to sync their calendar.'
                  : 'Employer Consultant(s) have been successfully added.'
              }`,
            });
          }}
        />
        {!isEmpty(consultant) && (
          <EmployerConsultantDetails
            visible={consultantDetailsModalVisible}
            setVisible={setConsultantDetailsModalVisible}
            consultant={consultant}
            setAlertVisibleParent={setAlertVisible}
            setAlertMessageParent={setAlertMessage}
            showEditMode={showEditMode}
            updatedStatus={
              content?.find((cons) => cons.id === consultant.id)?.status
            }
            updateTableData={updateTableData}
          />
        )}
        <ConfirmationDialog
          title="Remove Employer Consultant"
          confirmText="Yes - Remove Consultant"
          cancelText="Cancel"
          closeModal={toggleConfirmationDeletePopup}
          onConfirm={confirmDelete}
          visible={visibleConfirmationDelete}
          confirmLoading={requestTypeCommon === deleteConsultantInProgress.type}
        >
          <div className={styles.warningConfirmation}>
            {isSingleConsultant
              ? CONSULTANT_EMPLOYER_LEVEL_EMPLOYER_CONFIRMATION
              : CONSULTANT_EMPLOYER_LEVEL_EMPLOYER_NON_CONFIRMATION}
          </div>
        </ConfirmationDialog>
      </div>
    </>
  );
};

export default EmployerConsultation;
