import React from 'react';
import { Col, Row } from 'antd';
import { useAppSelector } from 'hooks/redux';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import NextButton from 'components/buttons/NextButton/NextButton';
import TextButton from 'components/buttons/TextButton/TextButton';
import { loginTypes } from 'constants/authConstants';
import styles from './actionButtonContainer.module.less';

type Props = {
  onClickNext: () => void;
  onClickBack: () => void;
  onClickDraft?: () => void;
  handleNavigation: () => void;
  isProposalDetailedView?: boolean;
  submitButtonIsLoading: boolean;
};

const ActionButtonContainer = (props: Props) => {
  const {
    onClickNext,
    onClickBack,
    onClickDraft,
    handleNavigation,
    isProposalDetailedView,
    submitButtonIsLoading,
  } = props;
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;

  const handleDraft = (): void => {
    if (onClickDraft) {
      onClickDraft();
    }
    isProposalDetailedView && handleNavigation();
  };

  return (
    <Row className={styles.btnRow} gutter={[16, 16]}>
      <Col className={styles.backBtnWrapper} span={8}>
        <SubmitButton
          className={styles.cancelButton}
          onClick={onClickBack}
          disabled={submitButtonIsLoading}
        >
          Back
        </SubmitButton>
      </Col>
      <Col span={8}>
        <NextButton
          nextStep={onClickNext}
          className={styles.nextBtn}
          loading={submitButtonIsLoading}
        />
      </Col>
      {onClickDraft && !isErAdmin ? (
        <Col span={24} className={styles.saveDraftBtn}>
          <TextButton
            label="Save Draft & Close"
            onClick={handleDraft}
            type="primary"
            disabled={submitButtonIsLoading}
          />
        </Col>
      ) : null}
    </Row>
  );
};

export default ActionButtonContainer;
