import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import { Col, Image, Menu, Row, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';

import debounce from 'lodash/debounce';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import DataTable from 'components/DataTable/DataTable';
import {
  createRenderColumns,
  DataColumn,
} from 'components/DataTable/DataColumn';
import Broker from 'model/Broker';
import {
  getBrokerList,
  clearBrokerData,
  clearUpdateBrokerUpdation,
  getBrokerCount,
} from 'modules/brokers/slices/brokerBasicInfoSlice';
import TablePagination from 'model/TablePagination';
import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
} from 'util/commonUtil';
import SearchBar from 'components/SearchBar/SearchBar';
import AddBrokerModal from 'modules/brokers/pages/AddBroker/AddBrokerModal';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import EmptyContent from 'components/EmptyContent/EmptyContent';
import UpdateEmpCount from 'modules/brokers/components/UpdateEmpCount/UpdateEmpCount';
import {
  EMPLOYER_LIMIT_DROPDOWN,
  EMPLOYER_UNLIMITED_PASSING_VALUE,
} from 'modules/brokers/constants/brokerConstants';

import styles from './brokerList.module.less';

const convertBrokersForTableRender = (brokers: Broker[]): any[] => {
  if (brokers != null) {
    return brokers.map((element) => ({
      key: element.id,
      broker: element.name,
      name: element,
      primaryContact: element.primaryContact?.contactName,
      noOfEmployers: element.employersCount,
      actions: element,
      allowedEmployerCount: element.allowedEmployerCount,
    }));
  }
  return [];
};

const BrokerList: FC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isUpdateEmpCountModalOpen, setIsUpdateEmpCountModalOpen] =
    useState<boolean>(false);
  const [brokerItemData, setBrokerItemData] = useState<any>(null);
  const { setBrokerId } = useNavContext();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const broker = useAppSelector((state) => state.brokers.brokerBasicInfo);

  const {
    brokerList,
    brokerListInProgress,
    brokerCreateInProgress,
    brokerCount,
  } = broker;
  const { content, metadata } = brokerList || {};
  const total = metadata ? metadata.total : '0';
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  useEffect(() => {
    if (!brokerCreateInProgress) {
      dispatch(getBrokerList(convertPaginationConfig(paginationConfig)));
      dispatch(clearBrokerData());
      dispatch(clearUpdateBrokerUpdation());
    }
  }, [dispatch, paginationConfig, brokerCreateInProgress]);

  useEffect(() => {
    if (!brokerCreateInProgress) {
      dispatch(getBrokerCount());
    }
  }, [dispatch, brokerCreateInProgress]);

  const goToBroker = (text: string): void => {
    setBrokerId(text);
    navigate(`/brokers/${text}`);
  };

  const goToBrokerInfo = (id: string): void => {
    setBrokerId(id);
    navigate(`/brokers/${id}/basicInfo`);
  };

  const getData = (filters: TablePagination) => {
    if (filters.sorterInfo) {
      const paginationConfigData = {
        sorterInfo: filters.sorterInfo,
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      };
      setPaginationConfig(paginationConfigData);
    } else {
      const paginationConfigData = {
        sorterInfo: {
          columnKey: 'name',
          field: 'name',
          order: 'ascend',
        },
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      } as TablePagination;
      setPaginationConfig(paginationConfigData);
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setSearchText(_searchText);
    const pageInfo = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText: _searchText },
    };
    debounceLoadData(pageInfo);
  };

  const logoSkelton = (
    <div>
      <Skeleton.Input className={styles.logoImg} />
    </div>
  );

  const brokerTableColumns: DataColumn[] = [
    {
      title: 'BROKER',
      dataIndex: 'name',
      key: 'name',
      width: '10%',
      sorter: (a: any, b: any) => a.name.name.localeCompare(b.name.name),
      defaultSortOrder: 'ascend',
      render: (broker) => {
        return (
          <div className={styles.logoImg}>
            <a
              onClick={() => {
                return goToBroker(broker.id);
              }}
            >
              <Image
                src={broker.logoUrl}
                preview={false}
                alt={broker.name}
                className={styles.logoImg}
                placeholder={logoSkelton}
              />
            </a>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'broker',
      key: 'broker',
      width: '25%',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <a
          onClick={() => {
            return goToBroker(record.key);
          }}
        >
          <OverflowPopover popoverContent={text}>
            <span className="text table-item-link">{text}</span>
          </OverflowPopover>
        </a>
      ),
    },
    {
      title: 'PRIMARY CONTACT',
      dataIndex: 'primaryContact',
      key: 'primaryContact',
      width: '30%',
      ellipsis: {
        showTitle: false,
      },
      render: (contact) => (
        <OverflowPopover popoverContent={contact}>{contact}</OverflowPopover>
      ),
    },
    {
      title: 'EMPLOYERS LIMIT',
      dataIndex: 'noOfEmployers',
      key: 'noOfEmployers',
      width: '20%',
      render: (_, record) => {
        const { noOfEmployers, allowedEmployerCount } = record;
        const showCountOrUnlimited =
          allowedEmployerCount === EMPLOYER_UNLIMITED_PASSING_VALUE ||
          allowedEmployerCount === null;
        return (
          <p className={styles.employersLimitText}>
            {noOfEmployers}/
            {showCountOrUnlimited
              ? EMPLOYER_LIMIT_DROPDOWN[0].label
              : allowedEmployerCount}
          </p>
        );
      },
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '15%',
      render: (item: Broker) => {
        return (
          <div className={styles.dropdownWrapper}>
            <SelectDropdown
              overlay={brokerActions(item)}
              className="text-select-action"
            />
          </div>
        );
      },
      align: 'left',
    },
  ];

  const brokerActions = (broker: Broker) => {
    const { id } = broker;
    return (
      <Menu>
        <Menu.Item key="brokerDashboard" onClick={() => goToBroker(id)}>
          Broker Dashboard
        </Menu.Item>
        <Menu.Item key="brokerInfo" onClick={() => goToBrokerInfo(id)}>
          Broker Info
        </Menu.Item>
        <Menu.Item
          key="updateEmployerLimit"
          onClick={() => {
            setBrokerItemData(broker);
            setIsUpdateEmpCountModalOpen(true);
          }}
        >
          Update Employer Limit
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
      {!brokerListInProgress &&
        content &&
        content.length === 0 &&
        !searchText &&
        brokerCount?.count === 0 && (
          <EmptyContent
            setIsModalOpen={setIsModalOpen}
            header="All Brokers"
            title="You have no Brokers set up!"
            content="Get started by adding your first Broker"
            buttonText="+ Add Broker"
          />
        )}
      {brokerCount?.count !== 0 && (
        <div className={styles.BrokerTableWrapper}>
          <Row>
            <Col span={12}>
              <h1>All Brokers</h1>
            </Col>
            <Col span={12}>
              <PageActionButton
                type="primary"
                onClick={() => setIsModalOpen(true)}
                dataCy="addBrokerBtn"
                className={styles.actionButton}
              >
                Add Broker
              </PageActionButton>
            </Col>
          </Row>
          <br />
          <Row className={styles.searchBar}>
            <SearchBar placeholder="Search Brokers" onChange={handleSearch} />
          </Row>
          <br />
          <div className={styles.summaryTable}>
            <DataTable
              currentIndex={paginationConfig.paginationIndex}
              data={convertBrokersForTableRender(content)}
              columns={createRenderColumns(brokerTableColumns)}
              pagination={true}
              loading={brokerListInProgress}
              getData={getData}
              total={total}
              pageSize={limit}
            />
          </div>
        </div>
      )}
      <AddBrokerModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <UpdateEmpCount
        visible={isUpdateEmpCountModalOpen}
        setVisible={setIsUpdateEmpCountModalOpen}
        brokerItemData={brokerItemData}
        setBrokerItemData={setBrokerItemData}
        paginationConfig={paginationConfig}
      />
    </>
  );
};

export default BrokerList;
