import React, { FC, useState } from 'react';
import { Input } from 'antd';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { createNewTag } from 'modules/rfp/services/RfpTagService';
import { setTagsError } from 'modules/rfp/slices/rfpQuestionSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  TAG_ERROR,
  TAG_LIMIT_EXCEEDED_MESSAGE,
} from 'modules/rfp/constants/RfpConstants';
import styles from './addNewTagModal.module.less';

type Props = {
  setAddTagModal: (value: boolean) => void;
  newTagValue: string;
  manageTagsClicked: Function;
  addTagModal: boolean;
  setNewTagValue: (value: string) => void;
};

const AddNewTagModal: FC<Props> = (props: Props) => {
  const {
    setAddTagModal,
    newTagValue,
    manageTagsClicked,
    addTagModal,
    setNewTagValue,
  } = props;
  const { brokerId } = useParams();

  const dispatch = useAppDispatch();

  const isError = useAppSelector((state) => state?.rfp?.tags?.isError);

  const [maximumTagLimitModal, setMaximumTagLimitModal] =
    useState<boolean>(false);

  return (
    <div>
      <ConfirmationDialog
        centered
        destroyOnClose
        closeModal={() => {
          setAddTagModal(false);
        }}
        visible={addTagModal}
        title="Add New Tag"
        confirmText="Save"
        isCancelLink
        cancelText="Cancel"
        disableConfirmButton={
          newTagValue.trim().length > 16 || isEmpty(newTagValue.trim())
        }
        confirmBtnFullWidth
        resetZindex
        onConfirm={() => {
          createNewTag(brokerId!, newTagValue.trim())
            .then(() => {
              manageTagsClicked();
              setAddTagModal(false);
            })
            .catch((error: any) => {
              if (axios.isAxiosError(error)) {
                dispatch(setTagsError(error?.response));
                if (error?.response?.data?.code === TAG_ERROR.MAXIMUM_REACHED) {
                  setMaximumTagLimitModal(true);
                }
              } else {
                console.log(error);
              }
            });
        }}
      >
        <div className={styles.addNewTagModal}>
          <b>Tag Name</b>
          <Input
            value={newTagValue}
            onChange={(e) => {
              setNewTagValue(e.target.value);
            }}
            className={styles.addNewTagInput}
          />
          {newTagValue.trim().length > 16 && (
            <span className={styles.errorCharacterLimitSpan}>
              Limited to 16 characters
            </span>
          )}

          {isError?.data?.code === TAG_ERROR.DUPLICATE_TAGS ? (
            <span className={styles.errorCharacterLimitSpan}>
              Tags Cannot be duplicated
            </span>
          ) : isError?.data?.code === TAG_ERROR.SPECIAL_CHARACTERS_DETECTED ? (
            <span className={styles.errorCharacterLimitSpan}>
              Tags cannot contain special characters
            </span>
          ) : (
            <></>
          )}
        </div>
      </ConfirmationDialog>

      {maximumTagLimitModal && (
        <ConfirmationDialog
          className={styles.maximumTagModalConfirm}
          centered
          confirmBtnFullWidth
          visible={maximumTagLimitModal}
          onConfirm={() => setMaximumTagLimitModal(false)}
          title="Add New Tag"
          confirmText="Close"
        >
          {TAG_LIMIT_EXCEEDED_MESSAGE}
        </ConfirmationDialog>
      )}
    </div>
  );
};

export default AddNewTagModal;
