import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { saveFilterTypes } from 'modules/renewals/types/renewalsTypes';

interface openOfferFilterState {
  showMoreFilters: boolean;
  loadingFilters: boolean;
  page: any;
  size: number;
  [key: string]: any;
}
const initialState: openOfferFilterState = {
  showMoreFilters: false,
  loadingFilters: false,
  searchKey: '',
  page: 1,
  clickedSaved: false,
  brokers: [],
  statuses: [],
  employernames: [],
  types: [],
  categories: [],
  carriers: [],
  lastmodified: [],
  bidstatus: [],
  offerstatus: [],
  renewaldates: [],
  sort: '',
  size: 30,
};

export const openOfferFilterSlice = createSlice({
  name: 'openOfferFilter',
  initialState: initialState,
  reducers: {
    clickShowMore: (state, action: PayloadAction<boolean>) => {
      state.showMoreFilters = action.payload;
    },
    saveFilter: (state, action: PayloadAction<saveFilterTypes>) => {
      state.page = 1;
      state[action.payload.filterType] = action.payload.filterValues;
      state.searchKey = action.payload.searchKey;
      state.page = action.payload.page;
      state.size = action.payload.size;
    },
    resetFilter: (state) => {
      state.page = 1;
      state.clickedSaved = false;
      state.statuses = [];
      state.types = [];
      state.carriers = [];
      state.brokers = [];
      state.employernames = [];
      state.bidstatus = [];
      state.lastmodified = [];
      state.categories = [];
      state.searchKey = '';
    },
    setLoadingFilter: (state, action: PayloadAction<boolean>) => {
      state.loadingFilters = action.payload;
    },
    setChangePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setDefaultFilters: (state, action) => {
      state.page = 1;
      state.clickedSaved = false;
      state.employernames = action.payload.employers;
      state.bidstatus = action.payload.bidStatus;
      state.carriers = action.payload.carriers;
      state.categories = action.payload.categories;
      state.types = action.payload.types;
      state.statuses = action.payload.statuses;
      state.offerstatus = action.payload.offerStatus;
      state.renewaldates = action.payload.renewalDates;
      state.lastmodified = action.payload.lastModify;
      state.brokers = action.payload.brokers;
      state.searchKey = '';
    },
    replaceFilter: (state, action) => {
      state.employernames = action.payload.employernames;
      state.bidstatus = action.payload.bidstatus;
      state.carriers = action.payload.carriers;
      state.categories = action.payload.categories;
      state.types = action.payload.types;
      state.statuses = action.payload.statuses;
      state.offerstatus = action.payload.offerstatus;
      state.renewaldates = action.payload.renewaldates;
      state.lastmodified = action.payload.lastmodified;
      state.brokers = action.payload.brokers;
    },
  },
});

export const {
  clickShowMore,
  saveFilter,
  resetFilter,
  setLoadingFilter,
  setChangePage,
  setDefaultFilters,
  replaceFilter,
} = openOfferFilterSlice.actions;
export default openOfferFilterSlice.reducer;
