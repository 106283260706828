import { FC } from 'react';
import { Alert, Table } from 'antd';
import { isEmpty } from 'lodash';

import { useAppSelector } from 'hooks/redux';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import alertWarning from 'assets/images/alert-warning.svg';
import { DataColumn } from 'components/DataTable/DataColumn';
import PlanRecommender from 'model/PlanRecommender';
import {
  DBGStatus,
  planRecommenderStatusErrorMessages,
} from 'modules/plans/constants';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import styles from './medicalPlanRecommenderPopup.module.less';

type MedicalPlanRecommenderPopupProps = {
  isModalVisible?: boolean;
  setViewModal?: Function;
  planRecommender: PlanRecommender;
  setCloseModal: Function;
  savePlan: Function;
};

const MedicalPlanRecommenderPopup: FC<MedicalPlanRecommenderPopupProps> = ({
  isModalVisible,
  planRecommender,
  setCloseModal,
  savePlan,
}: MedicalPlanRecommenderPopupProps) => {
  const { inProgress } = useAppSelector((state) => state.plan.plans);

  const dataColumns: DataColumn[] = [
    {
      title: 'GUIDE NAME',
      dataIndex: 'name',
      key: 'name',
      width: '70%',
      showSorterTooltip: false,
      render: (colData) => {
        return (
          <OverflowPopover maxWidth={'300px'}>
            <div className={styles.tableGuideName}>{colData}</div>
          </OverflowPopover>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: '30%',
      showSorterTooltip: false,
      render: (colData) => {
        let val = <div></div>;
        DBGStatus.map((obj) => {
          if (obj.title === colData) {
            val = (
              <OverflowPopover>
                <div>{obj.message}</div>
              </OverflowPopover>
            );
          }
        });

        return val;
      },
    },
  ];

  const disableAndSavePlan = () => {
    savePlan();
    setCloseModal(false);
  };

  return (
    <>
      <ConfirmationDialog
        title={'Plan Recommender Update'}
        confirmText={'Yes - Save Changes'}
        cancelText={'No - Continue Editing'}
        visible={isModalVisible}
        centered={true}
        confirmLoading={inProgress}
        buttonsExpanded={true}
        zIndex={10000}
        onConfirm={() => {
          disableAndSavePlan();
        }}
        closeModal={() => {
          setCloseModal(false);
        }}
      >
        <div className={styles.initalText}>
          Based on these changes, the Plan Recommender will be automatically
          disabled in the following guides:
        </div>

        <div className={styles.tableWrapper}>
          <Table
            columns={dataColumns}
            dataSource={planRecommender?.benefitGuides ?? []}
            pagination={false}
          ></Table>
        </div>

        {!isEmpty(planRecommender.errorTypes) ? (
          <Alert
            message={
              <div className={styles.alertWrapper}>
                <span className={styles.alertTitleWrapper}>
                  <img
                    src={alertWarning}
                    alt="warning icon"
                    className={styles.warningIcon}
                  />
                  Reasons for Plan Recommender disablement:
                </span>
                <ul>
                  {planRecommenderStatusErrorMessages.map((item) => {
                    const exists = planRecommender.errorTypes.find((obj) => {
                      return obj === item.title;
                    });
                    return exists ? <li>{item.message}</li> : '';
                  })}
                </ul>
              </div>
            }
            type="warning"
            icon={<img src={alertWarning} alt="warning icon" />}
          />
        ) : (
          ''
        )}

        <div className={styles.sureText}>
          Are you sure you want to make these changes?
        </div>
      </ConfirmationDialog>
    </>
  );
};

export default MedicalPlanRecommenderPopup;
