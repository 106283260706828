import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import routes from 'routes';

export const useGAPageViews = () => {
  const location = useLocation();

  const pathToTitle = useCallback((path: any) => {
    return path
      .split('/')
      .filter(Boolean)
      .map((segment: any) => {
        return segment
          .replace(/:[^\s/]+/g, '')
          .replace(/-/g, ' ')
          .replace(/\b\w/g, (l: string) => l.toUpperCase());
      })
      .join(' - ');
  }, []);

  const getPageTitle = useCallback(
    (path: any) => {
      const route = routes.find((route) =>
        path.match(
          new RegExp(`^${route.path.replace(/:[^\s/]+/g, '([\\w-]+)')}$`)
        )
      );
      if (route && route.title) {
        return route.title;
      }
      // If title is not explicitly set, you might infer from path or element
      if (route && route.path) {
        return pathToTitle(route.path);
      }
      return route?.path;
    },
    [pathToTitle]
  );

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location?.pathname,
      title: getPageTitle(location?.pathname),
    });
  }, [location, getPageTitle]);
};
