import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  checkIfActivityPopUpIsTriggered,
  checkIfRefreshIsRequired,
  DESIGNATED_USER_ACTIVITY_EXPIRATION_TIME,
} from 'util/userActivityUtilis';
import {
  clearBroker,
  clearCarrier,
  clearEmployer,
  setRefreshTokenRequired,
} from 'layout/slices/layoutSlice';
import { LOGIN_PATH } from 'modules/auth/routes';
import {
  checkRefreshToken,
  logUserSessionAction,
  refreshToken,
} from 'modules/auth/services/AuthService';
import { sessionActionTypes } from 'constants/authConstants';
import { logout, sessionExpired } from 'modules/auth/slices/authSlice';
import { clearBrokerDashboard } from 'modules/brokers/slices/brokerDashboardSlice';

import styles from './userActivity.module.less';

const UserActivityPopUp: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const sessionDto = useAppSelector((state) => state.layout?.sessionExpiryDto);
  const navigate = useNavigate();
  const location = useLocation();

  const [seconds, setSeconds] = useState<number>(
    DESIGNATED_USER_ACTIVITY_EXPIRATION_TIME
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    dispatch(sessionExpired(false));
    // Add a listener to handle stay loggged in when user refreshes the browser
    const handleBeforeUnload = () => {
      handleStayLoggedInClick();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutAction = () => {
    logUserSessionAction(sessionActionTypes.logout);
    dispatch(
      logout(() => {
        navigate(LOGIN_PATH);
        dispatch(clearCarrier());
        dispatch(clearBroker());
        dispatch(clearEmployer());
        dispatch(clearBrokerDashboard());
      })
    );
    dispatch(sessionExpired(true));
  };

  useEffect(() => {
    if (seconds === 0) {
      logoutAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds, navigate, location]);

  useEffect(() => {
    if (sessionDto.isResetTriggered) {
      retrieveTokenAndReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionDto.isResetTriggered]);

  /**
   * Asynchronously retrieves a token and resets the user activity timer.
   *
   * @return {Promise<void>} A promise that resolves after retrieving the token and resetting the timer.
   */
  const retrieveTokenAndReset = async () => {
    try {
      const refreshResponse = await checkRefreshToken();
      await refreshToken(refreshResponse.data);
      await setIsModalVisible(false);
      setIsLoading(false);
      setSeconds(DESIGNATED_USER_ACTIVITY_EXPIRATION_TIME);
    } catch (e) {
      console.error(e);
    }
  };
  /**
   * A function that handles the click event for logging out.
   *
   * @return {void}
   */
  const handleLogOutClick = () => {
    logoutAction();
  };

  const handleStayLoggedInClick = () => {
    setIsLoading(true);
    logUserSessionAction(sessionActionTypes.extendSession);
    retrieveTokenAndReset();
  };

  useEffect(() => {
    if (checkIfRefreshIsRequired(seconds) && sessionDto.isRefreshSwapped) {
      !sessionDto.isResetTriggered &&
        logUserSessionAction(sessionActionTypes.refreshRequired); // TODO: this is not invoking the API properly
      dispatch(setRefreshTokenRequired(true));
    }
    if (checkIfActivityPopUpIsTriggered(seconds)) {
      !isModalVisible && logUserSessionAction(sessionActionTypes.expireSoon);
      setIsModalVisible(true);
    }
  }, [
    seconds,
    sessionDto.isRefreshSwapped,
    dispatch,
    sessionDto.isResetTriggered,
    isModalVisible,
  ]);

  return (
    <div>
      {isModalVisible && (
        <ConfirmationDialog
          visible={isModalVisible}
          centered={true}
          title="Are you there?"
          isCloseOnly={true}
          confirmText={'Stay logged In'}
          cancelText={'Log Out'}
          isCancelLink={true}
          closeModal={handleLogOutClick}
          onConfirm={handleStayLoggedInClick}
          zIndex={999999}
          confirmLoading={isLoading}
        >
          <div>
            We&apos;ve noticed a period of inactivity during your current
            session. Please refresh your session below to stay logged in and
            avoid automatic log out.
          </div>
          <Row justify="center">
            <Col className={styles.timer}>
              {Math.floor(seconds / 60)}:{('0' + (seconds % 60)).slice(-2)}
            </Col>
          </Row>
        </ConfirmationDialog>
      )}
    </div>
  );
};

export default UserActivityPopUp;
