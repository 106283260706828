import { FC, useEffect, useState } from 'react';

import { Form, Input } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { cloneDeep } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import BenefitClassMultiSelect from 'components/BenefitClassMultiSelect/BenefitClassMultiSelect';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import PlanYear from 'model/PlanYear';
import { IBenGuide, IPlan } from 'modules/employers/types/planBGCloneTypes';
import {
  CLONE_TYPE,
  PLAN_CLONE_BENEFIT_CATEGORIES,
} from 'modules/employers/constants/afpClonePlansConstants';
import { BENEFIT_GUIDE_CLONING_INFO_MESSAGE } from 'modules/benefitGuide/constants/benefitGuideConstants';
import {
  updateBenGuideList,
  updatePlanList,
} from 'modules/employers/slices/planBGCloneSlice';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';

import styles from './planDetailModal.module.less';

type PlanDetailModalProps = {
  data: any;
  visible: boolean;
  handleClose: () => void;
  type: keyof typeof CLONE_TYPE;
  changedIndex: number | null;
};

const PlanDetailModal: FC<PlanDetailModalProps> = (
  props: PlanDetailModalProps
) => {
  const { visible, handleClose, type, data, changedIndex } = props;

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [benefitsClassesSelect, setBenefitsClasses] = useState<string[]>([]);

  const selectedPlanYear: Partial<PlanYear> = useAppSelector(
    (state) => state.employer.planBGClone.selectedPlanYear
  );
  const clonePlanBGList = useAppSelector(
    (state) => state.employer.planBGClone.clonePlanBGList
  );

  useEffect(() => {
    if (visible) {
      setBenefitsClasses(data?.benefitClasses);
      form.setFieldsValue({
        name: data?.name,
        planYear: selectedPlanYear?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (benefitsClassesSelect) {
      form.setFieldsValue({
        benefitClasses: benefitsClassesSelect?.join(','),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benefitsClassesSelect]);

  const changeBenefitClass = (event: any) => {
    const value: string = event?.target?.value;
    const isInclude = benefitsClassesSelect.includes(value);
    if (isInclude) {
      setBenefitsClasses(
        benefitsClassesSelect.filter((element) => element != value)
      );
    } else {
      setBenefitsClasses([...benefitsClassesSelect, value]);
    }
  };

  const handlePlanUpdateState = (record: IPlan, index: number) => {
    const copiedPlanList = { ...clonePlanBGList.plans };
    const categoryKey =
      record.benefitCategory as keyof typeof PLAN_CLONE_BENEFIT_CATEGORIES;
    const copiedCategory = { ...copiedPlanList[categoryKey]! };
    const copiedPlans = [...copiedCategory.plan!];

    // Update the isRemoved property of the specific plan based on the 'remove' parameter
    copiedPlans[index] = { ...copiedPlans[index], ...record };
    copiedCategory.plan = copiedPlans;

    copiedPlanList[categoryKey] = copiedCategory;

    dispatch(updatePlanList(copiedPlanList));
  };

  const handleBenGuideUpdateState = (record: IBenGuide, index: number) => {
    const copiedBGList = cloneDeep(clonePlanBGList.benGuides);
    copiedBGList[index] = { ...record };
    dispatch(updateBenGuideList(copiedBGList));
  };

  // validate plan name according to same benefitKind cannot duplicate.
  // validate ben guide name within ben guides
  const validateNameIsNotDuplicate = async (_: RuleObject, value: string) => {
    if (type === CLONE_TYPE.BEN_GUIDE) {
      const bgNameArray = clonePlanBGList?.benGuides
        ?.filter((item) => item?.masterId !== data?.masterId)
        .map((item) => item?.name?.toLowerCase());
      if (bgNameArray.includes(value.toLowerCase().trim())) {
        throw Error('Benefit Guide name duplicated.');
      }
    }
    if (type === CLONE_TYPE.PLAN) {
      const categoryKey =
        data?.benefitCategory as keyof typeof PLAN_CLONE_BENEFIT_CATEGORIES;
      const planNameArray = clonePlanBGList?.plans[categoryKey]?.plan
        ?.filter(
          (item) =>
            (item?.benefitKind
              ? item.benefitKind === data?.benefitKind
              : true) && item.planId !== data?.planId
        )
        ?.map((item) => item?.name?.toLowerCase());
      if (planNameArray?.includes(value.toLowerCase().trim())) {
        throw Error('Plan name duplicated.');
      }
    }
  };

  const submit = () => {
    form.validateFields().then(async (values) => {
      const newObj = {
        ...data,
        name: values.name,
        benefitClasses: benefitsClassesSelect,
      };

      if (type === CLONE_TYPE.PLAN) {
        await handlePlanUpdateState(newObj, changedIndex!);
        handleClose();
      } else {
        await handleBenGuideUpdateState(newObj, changedIndex!);
        handleClose();
      }
    });
  };

  return (
    <ConfirmationDialog
      width={464}
      title={`Review ${
        type === CLONE_TYPE.PLAN ? 'Plan' : 'Benefits Guide'
      } Details`}
      confirmText={`Save ${
        type === CLONE_TYPE.PLAN ? 'Plan' : 'Benefits Guide'
      } Details`}
      cancelText="Cancel"
      closeModal={handleClose}
      onConfirm={submit}
      visible={visible}
      isCloseOnly={false}
      modalClassName={styles.cloneModalWrapper}
      isCancelLink={true}
      destroyOnClose={true}
    >
      {type === CLONE_TYPE.BEN_GUIDE && (
        <FixedAlertMessage
          wrapperClassName={styles.cloneMessageBox}
          className={styles.textMessage}
          message={<p>{BENEFIT_GUIDE_CLONING_INFO_MESSAGE}</p>}
          type="info"
        />
      )}
      <div className={styles.cloneBenefitGuideWrapper}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label={`${
              type === CLONE_TYPE.PLAN ? 'Plan' : 'Benefits Guide'
            } Name`}
            rules={[
              {
                required: true,
                message: `${
                  type === CLONE_TYPE.PLAN ? 'Plan' : 'Benefits Guide'
                }  name required`,
                whitespace: true,
              },
              {
                validator: validateNameIsNotDuplicate,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Plan Year"
            name="planYear"
            rules={[
              {
                required: true,
                message: 'Plan Year required',
                whitespace: true,
              },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="benefitClasses"
            label="Benefit Classes"
            rules={[
              {
                required: true,
                message: 'Benefit Classes required',
                whitespace: true,
              },
            ]}
          >
            <Input className={styles.hide} />
            <div className={`${styles.benefitClasses} `}>
              <BenefitClassMultiSelect
                options={selectedPlanYear?.benefitGroups || []}
                onChange={changeBenefitClass}
                name="groups"
                selectedItemValues={benefitsClassesSelect}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </ConfirmationDialog>
  );
};

export default PlanDetailModal;
