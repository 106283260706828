import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Row, Col, Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import reactHtmlParser from 'react-html-parser';

import { useAppSelector } from 'hooks/redux';
import { usePermitIf } from 'hooks/usePermitIf';
import { useNavContext } from 'hooks/useNavContext';

import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import CarrierCreateModal from 'modules/carriers/components/CarrierCreateModal/CarrierCreateModal';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';

import CarrierInfoLabel from 'modules/carriers/components/CarrierInfoLabel/CarrierInfoLabel';
import CustomCarrier from 'modules/carriers/components/CustomCarrier/customCarrier';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import { openLink } from 'util/commonUtil';
import {
  getCarrierLibraryType,
  isMDV,
  isRLS,
} from 'modules/carriers/components/util/carrierUtil';

import { allowedCommonButtonPermittedTypes } from 'constants/permittedConstants';
import { benefitTypes } from 'modules/carriers/constants/carrierConstants';

import { useStateCallback } from 'hooks/updateState';
import DeleteCarrier from 'modules/carriers/components/DeleteCarrier/DeleteCarrier';
import BenefitCarrier from 'model/BenefitCarrier';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import {
  ALLOWED_INDIVIDUAL_SUB_TYPES,
  EMPLOYER_MEMBER_RESTRICTED,
} from 'constants/commonConstants';
import CarrierType from 'modules/carriers/enums/CarrierType';

import styles from './carrierInfo.module.less';

type CarrierInfoProps = {};

const CarrierInfo: FC<CarrierInfoProps> = (props: CarrierInfoProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useStateCallback(false);
  const [isBenefitCarrierAdd, setIsBenefitCarrierAdd] =
    useState<boolean>(false);

  const { carrierDetails, isLoading } = useAppSelector((state) => ({
    carrierDetails: state.carriers.carrierObj,
    isLoading: state.carriers.carrierFetchInProgress,
  }));
  const { carrierRoutes } = useAppSelector((state) => state.layout);
  const params = useParams();
  const { setCarrier } = useNavContext();

  const getAllCarriersUrl = () => {
    if (params.brokerId && params.employerId) {
      return `/brokers/${params.brokerId}/employers/${params.employerId}/carriers`;
    } else if (params.brokerId) {
      return `/brokers/${params.brokerId}/carriers`;
    } else {
      return `/carriers`;
    }
  };

  const navigation = { name: 'All Carriers', link: getAllCarriersUrl() };
  const navigations: { name: string; link: string }[] = [];
  navigations[0] = navigation;

  useEffect(() => {
    if (params.carrierId) {
      setCarrier(params.carrierId, null, null);
    }
  }, [params.carrierId, setCarrier]);

  const SecuredBreadcrumb = usePermitIf(
    <BreadcrumbNavigation breadCrumbData={navigations} />,
    allowedCommonButtonPermittedTypes,
    []
  );

  const getBenefitCategoryList = (benefitCarrierList: any) => {
    const benefitCategories: any[] = [];
    if (benefitCarrierList) {
      benefitCarrierList.forEach(function (benefitCarrier: any) {
        benefitCategories.push(benefitCarrier);
      });
    }
    return benefitCategories.map((benefitCategory) => {
      return (
        <div
          className={styles.benefitCarrier}
          key={benefitTypes[benefitCategory]?.value}
        >
          <div className={styles.carrierBenefits}>
            {benefitTypes[benefitCategory]?.label}
          </div>
          {!carrierDetails?.customCarrier &&
            isCustomBenefitCarrierIncluded(
              benefitTypes[benefitCategory]?.value
            ) && <CustomCarrier />}
        </div>
      );
    });
  };

  const isCustomBenefitCarrierIncluded = (benefitCategory: string) => {
    const customBenefitCarriers: BenefitCarrier[] = [];
    carrierDetails?.benefitCarrierVOS.forEach(
      (benefitCarrier: BenefitCarrier) => {
        if (
          benefitCategory === benefitCarrier.benefitCategory &&
          benefitCarrier.customCarrier
        ) {
          customBenefitCarriers.push(benefitCarrier);
        }
      }
    );
    return !isEmpty(customBenefitCarriers);
  };

  const carrierEditBtn = (
    <PageActionButton
      type="primary"
      onClick={() => setIsModalOpen(true)}
      className={styles.actionButton}
    >
      Edit Carrier Info
    </PageActionButton>
  );

  const benefitCarrierEditCreate = (
    <>
      <Col span={8}>
        <SubmitButton
          onClick={() => {
            setIsModalOpen(true);
            setIsBenefitCarrierAdd(true);
          }}
          className={styles.addBenefitCarrier}
        >
          {`+ Add Another ${
            params.benefitCategory
              ? benefitTypes[params.benefitCategory]?.label
              : ''
          } Carrier`}
        </SubmitButton>
      </Col>
      <Col span={4}>
        <PageActionButton
          type="primary"
          onClick={() => setIsModalOpen(true)}
          className={styles.actionButton}
        >
          Edit Carrier
        </PageActionButton>
      </Col>
    </>
  );

  const permittedBenefitCarrierEditCreate = usePermitByUserType(
    benefitCarrierEditCreate,
    EMPLOYER_MEMBER_RESTRICTED
  );

  const carrierDeleteBtn = (
    <span
      className={styles.deleteBenefitCarrier}
      onClick={() => {
        setIsDeleteConfirmOpen(true);
      }}
    >{`Delete ${
      params.benefitCategory ? benefitTypes[params.benefitCategory]?.label : ''
    } Carrier`}</span>
  );

  const secureBenefitCarrierEditCreate = usePermitByUserType(
    benefitCarrierEditCreate,
    ALLOWED_INDIVIDUAL_SUB_TYPES
  );

  const secureCarrierEditBtn = usePermitByUserType(
    carrierEditBtn,
    ALLOWED_INDIVIDUAL_SUB_TYPES
  );

  const employerLevelCarrierEditBtn = usePermitByUserType(
    carrierEditBtn,
    EMPLOYER_MEMBER_RESTRICTED
  );

  const secureCarrierDeleteBtn = usePermitByUserType(
    carrierDeleteBtn,
    ALLOWED_INDIVIDUAL_SUB_TYPES
  );

  const currentCarrierLibType = getCarrierLibraryType(
    params?.employerId,
    params?.brokerId
  );

  if (isEmpty(carrierRoutes)) {
    // hide info until routes are set in layout
    return <></>;
  }
  return (
    <>
      <ContentContainer>
        {isLoading ? (
          <Spin />
        ) : (
          <Row className={styles.carrierInfo}>
            <Col span={24}>
              <Row>{SecuredBreadcrumb}</Row>
              <Row className={styles.headerRow}>
                <Col span={12} className={styles.titleLabel}>
                  <h1 className={styles.titleValue}>
                    <OverflowPopover>
                      {!isEmpty(carrierDetails) &&
                      carrierDetails.benefitCategory
                        ? `${carrierDetails?.name} - ${
                            benefitTypes[carrierDetails.benefitCategory].label
                          } Carrier Info`
                        : `${carrierDetails?.name} Carrier Info`}
                    </OverflowPopover>
                  </h1>
                  {carrierDetails && carrierDetails.customCarrier && (
                    <div className={styles.customCarrierWrapper}>
                      <CustomCarrier />
                    </div>
                  )}
                </Col>
                {params.id ? (
                  currentCarrierLibType === CarrierType.BROKER ? (
                    secureBenefitCarrierEditCreate
                  ) : (
                    permittedBenefitCarrierEditCreate
                  )
                ) : (
                  <Col span={12}>
                    {currentCarrierLibType === CarrierType.BROKER
                      ? secureCarrierEditBtn
                      : employerLevelCarrierEditBtn}
                  </Col>
                )}
              </Row>
              <br />
              <CarrierInfoLabel
                labelText="Carrier Logo*"
                value={
                  <img
                    src={carrierDetails ? carrierDetails.logoUrl : ''}
                    alt="info icon"
                  />
                }
              />
              <CarrierInfoLabel
                labelText="Name*"
                value={
                  <div className={styles.carriername}>
                    <OverflowPopover>
                      {isEmpty(carrierDetails?.name)
                        ? '-'
                        : carrierDetails?.name}
                    </OverflowPopover>
                  </div>
                }
              />
              <CarrierInfoLabel
                labelText="Website URL"
                value={
                  carrierDetails.url == '' ? (
                    '-'
                  ) : (
                    <LinkButton
                      onClick={() =>
                        openLink(carrierDetails ? carrierDetails.url : '')
                      }
                    >
                      <div className={styles.webUrl}>
                        <OverflowPopover>
                          {carrierDetails?.url}{' '}
                        </OverflowPopover>
                      </div>
                    </LinkButton>
                  )
                }
              />
              {!params.id && (
                <CarrierInfoLabel
                  labelText="Benefit Categories*"
                  value={
                    <div>
                      {getBenefitCategoryList(
                        carrierDetails?.benefitCategories
                      )}
                    </div>
                  }
                />
              )}
              {params.id && (
                <>
                  <CarrierInfoLabel
                    labelText="Phone Number"
                    value={
                      isEmpty(carrierDetails?.phoneNumber)
                        ? '-'
                        : carrierDetails?.phoneNumber
                    }
                  />
                  {isMDV(params) && (
                    <CarrierInfoLabel
                      labelText="Provider Search URL"
                      value={
                        isEmpty(carrierDetails?.providerSearchUrl) ? (
                          '-'
                        ) : (
                          <LinkButton
                            onClick={() =>
                              openLink(
                                carrierDetails
                                  ? carrierDetails.providerSearchUrl
                                  : ''
                              )
                            }
                          >
                            <div className={styles.webUrl}>
                              <OverflowPopover>
                                {carrierDetails?.providerSearchUrl}
                              </OverflowPopover>
                            </div>
                          </LinkButton>
                        )
                      }
                    />
                  )}
                  {!isRLS(params) && (
                    <CarrierInfoLabel
                      labelText={
                        isMDV(params)
                          ? 'Provider Search Instructions'
                          : 'Benefit Description'
                      }
                      value={
                        <Col xl={20} className={styles.benefitCarrierDes}>
                          {isMDV(params)
                            ? isEmpty(
                                carrierDetails?.providerSearchAdditionalInfo
                              )
                              ? '-'
                              : reactHtmlParser(
                                  carrierDetails?.providerSearchAdditionalInfo
                                )
                            : isEmpty(carrierDetails?.benefitDescription)
                            ? '-'
                            : reactHtmlParser(
                                carrierDetails?.benefitDescription
                              )}
                        </Col>
                      }
                    />
                  )}
                </>
              )}
              {carrierDetails.carrierType ===
                getCarrierLibraryType(params?.employerId, params?.brokerId) &&
                !carrierDetails.carrierSourceDetails &&
                (currentCarrierLibType === CarrierType.BROKER
                  ? secureCarrierDeleteBtn
                  : carrierDeleteBtn)}
            </Col>
          </Row>
        )}
      </ContentContainer>
      <CarrierCreateModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isEdit={!isBenefitCarrierAdd}
        benefitCategory={params.benefitCategory}
        setIsBenefitCarrierAdd={setIsBenefitCarrierAdd}
        isBenefitCarrierAdd={isBenefitCarrierAdd}
      />
      {isDeleteConfirmOpen && (
        <DeleteCarrier
          isConfirmOpen={isDeleteConfirmOpen}
          cancelModal={() => {
            setIsDeleteConfirmOpen(false);
          }}
          isBenefitCarrier={!!params.benefitCategory}
          benefitCategories={getBenefitCategoryList(
            carrierDetails?.benefitCategories
          )}
          allCarrierUrl={getAllCarriersUrl()}
        />
      )}
    </>
  );
};
export default CarrierInfo;
