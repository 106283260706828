import { PlusOutlined } from '@ant-design/icons';
import TextButton from 'components/buttons/TextButton/TextButton';
import styles from './selectAddMoreButton.module.less';

type Props = {
  onClick: () => void;
  label: string;
};

const SelectAddMoreButton = (props: Props) => {
  const { onClick, label } = props;
  return (
    <div className={styles.selectAddNewWrapper}>
      <TextButton
        type="default"
        onClick={onClick}
        label={
          <div className={styles.selectAddNewBtn}>
            <PlusOutlined />
            <span className={styles.label}>{label}</span>
          </div>
        }
      />
    </div>
  );
};

export default SelectAddMoreButton;
