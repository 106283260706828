import { FC, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  Alert,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
} from 'antd';
import { useParams } from 'react-router-dom';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { DefaultOptionType } from 'antd/lib/select';
import { isNullOrUndefined } from 'modules/plans/utils';
import MultiSelectScroll, {
  MultiScrollSelectOption,
} from 'modules/tools/components/MultiSelectScroll/MultiSelectScroll';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import {
  ISSUE_TYPE_ACCOUNT,
  ISSUE_TYPE_SUPPORT,
} from 'modules/issueslog/constants/IssuesLogConstants';
import alertInfo from 'assets/images/alert-info.svg';
import { filterByLabel, isValidEmailFormat } from 'util/commonUtil';
import { useUpdateAutomatedEmailMutation } from 'modules/tools/slices/issuesLogProvisioningSlice';
import {
  downloadInstructionPdf,
  useGetEmployersByBrokerIdQuery,
  useLazyGetBrokerAdminsForEmployerQuery,
} from 'modules/issueslog/slices/issuesLogSlice';
import * as issuesLogService from 'modules/tools/services/IssuesLogService';
import { usePrevious } from 'hooks/usePrevious';
import Employer from 'model/Employer';

import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import { useNavContext } from 'hooks/useNavContext';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';

import { AccountStatus } from 'modules/admins/constants/adminConstants';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import styles from './automatedEmailSetupModal.module.less';

type AutomatedEmailSetupModalProps = {
  visible: boolean;
  onCancel: () => void;
  employerName?: string;
  onSave: () => void;
  ownerId: string;
  type: string;
  supportTeamList?: any[];
  name?: string;
};

const AutomatedEmailSetupModal: FC<AutomatedEmailSetupModalProps> = ({
  visible,
  onCancel,
  employerName,
  onSave,
  ownerId,
  type,
  supportTeamList,
  name,
}) => {
  const params = useParams();
  const [form] = Form.useForm();
  const { brokerId } = useNavContext();
  const [updateAutomatedEmail, { isLoading, isSuccess, isError }] =
    useUpdateAutomatedEmailMutation();
  const [selectOptionsList, setSelectOptionsList] = useState<
    MultiScrollSelectOption[]
  >([]);

  const [formData, setFormData] = useState<any>({});
  const [allCompleted, setAllCompleted] = useState<boolean>(false);
  const [isNextClicked, setNextClicked] = useState<boolean>(false);
  const [savingError, setSavingError] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const { taggedEmployer } = formData;

  const allCompltedChanged = (event: CheckboxChangeEvent) => {
    setAllCompleted(event?.target.checked);
  };

  const navigateUserToBrokerAdmin = () => {
    window.open(`/brokers/${brokerId}/basicInfo?isBrokerAdmin=true`, '_blank');
  };

  const resetForm = () => {
    setAllCompleted(false);
    setNextClicked(false);
    setSelectOptionsList([]);
    setSavingError(false);
    setAlertMessage({
      type: undefined,
      message: '',
    });
    form.setFieldsValue({
      email: '',
      name: '',
      taggedEmployer: '',
      brokerAdmins: undefined,
    });
  };

  const isLoadingPrevious = usePrevious(isLoading);

  useEffect(() => {
    if (isLoadingPrevious && !isLoading && isSuccess) {
      onSave();
    }
  }, [isLoadingPrevious, isSuccess, isLoading, onSave]);

  useEffect(() => {
    if (isLoadingPrevious && !isLoading && isError) {
      setSavingError(true);
      setAlertMessage({
        type: 'error',
        message: 'Failed to save automated email address. Please try again.',
      });
    }
  }, [isLoadingPrevious, isError, isLoading, onSave]);

  const save = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }

    if (ISSUE_TYPE_SUPPORT === type) {
      const selectedIds = selectOptionsList
        .filter((option) => option?.name?.isChecked === true)
        .map((option) => option.name.value);

      if (!isNullOrUndefined(taggedEmployer) && isEmpty(selectedIds)) {
        setSavingError(true);
        setAlertMessage({
          type: 'error',
          message: 'Please select at least one broker admin',
        });
        return;
      }

      form.setFieldsValue({
        brokerAdmins: selectedIds,
      });
    }

    form.validateFields().then((data) => {
      let updatedData = { ...data };
      if (name) {
        updatedData = { ...data, name: name };
      }
      updateAutomatedEmail({
        email: updatedData,
        type: type,
        ownerId: ownerId,
      });
    });
  };
  const { data: employerData = [], isFetching: isLoadingEmpData } =
    useGetEmployersByBrokerIdQuery({
      brokerId: brokerId || '',
      individualId: null,
    });

  const employerList = employerData.map((employer: Employer) => {
    return { value: employer.id, label: employer.name };
  });

  const filterSort = (
    optionA: DefaultOptionType,
    optionB: DefaultOptionType
  ) => {
    return (optionA.label?.toString().toLowerCase() || '').localeCompare(
      optionB.label?.toString().toLowerCase() || ''
    );
  };

  const [
    getBrokerAdminsForEmployerQuery,
    { isFetching: loadingBrokerList, data: brokerAdminData = [] },
  ] = useLazyGetBrokerAdminsForEmployerQuery();

  const isNvApplicable = (broker: any): boolean => {
    return AccountStatus.ACCOUNT_ACTIVATED !== broker.accountStatus;
  };

  const getBrokerSelectOptionsList = (): MultiScrollSelectOption[] => {
    return brokerAdminData.map((broker: any) => {
      return {
        name: {
          value: broker?.id,
          label: (
            <Row>
              <OverflowPopover maxWidth={isNvApplicable(broker) ? 100 : 160}>
                {`${broker?.firstName} ${broker?.lastName} `}
              </OverflowPopover>
              {isNvApplicable(broker) && (
                <span className={styles.notVerifiedLabel}>(not verified)</span>
              )}
            </Row>
          ),
          isChecked: false,
        },
        email: {
          value: broker?.email,
          label: broker?.email,
        },
      };
    });
  };

  const updateFormData = (data: any) => {
    setFormData({ ...formData, ...data });
  };

  const handleOnSelect = (employerId: string) => {
    setSelectOptionsList([]);
    if (brokerId && employerId) {
      getBrokerAdminsForEmployerQuery({
        brokerId: brokerId,
        employerId: employerId,
        isActiveAndNonActive: true,
      });
    }
  };

  const duplicateValidateSupportTeamName = (name: string) => {
    if (
      supportTeamList?.find(
        (team) => team.name.toUpperCase() === name.toUpperCase()
      )
    ) {
      return Promise.reject(new Error());
    } else {
      return Promise.resolve();
    }
  };

  useEffect(() => {
    if (brokerAdminData?.length > 0) {
      if (isEmpty(taggedEmployer) || isNullOrUndefined(taggedEmployer)) {
        setSelectOptionsList([]);
      } else {
        setSelectOptionsList(getBrokerSelectOptionsList());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.taggedEmployer,
    getBrokerAdminsForEmployerQuery,
    brokerAdminData,
  ]);

  useEffect(() => {
    if (visible) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, form]);

  return (
    <Modal
      centered
      visible={visible}
      width={600}
      closeIcon={<></>}
      footer={false}
      className={styles.automatedEmailSetupModal}
      destroyOnClose={true}
    >
      <div className={styles.automatedEmailSetupModalWrapper}>
        <div className={styles.title}>Setup Automated Email</div>
        <div className={styles.subTitle}>
          {ISSUE_TYPE_ACCOUNT == type ? employerName : 'Support Team'}
        </div>

        {!isNextClicked ? (
          <>
            <div className={styles.description}>
              <p className={styles.descriptionOne}>
                To enable automated ticket creation for an already existing
                email address, please follow the instructions in this link:
                <span
                  className={styles.setupInstructions}
                  onClick={() => {
                    downloadInstructionPdf(params.brokerId);
                  }}
                >
                  Email Setup Instructions
                </span>
              </p>
              <p className={styles.descriptionTwo}>
                When you have completed the given instructions, check the box
                below and continue to the next screen.
              </p>
            </div>
            <div className={styles.verifyWrapper}>
              <Checkbox onChange={allCompltedChanged} />
              <span>
                I have completed all the steps in the setup instructions
              </span>
            </div>
            <PageActionButton
              className={`${styles.nextButton} ${
                !allCompleted ? styles.disableNext : ''
              }`}
              type="primary"
              onClick={() => {
                setNextClicked(true);
              }}
            >
              Next
            </PageActionButton>
          </>
        ) : (
          <>
            <div className={styles.description}>
              {ISSUE_TYPE_ACCOUNT == type ? (
                <p className={styles.descriptionOne}>
                  Please enter the email address below that has been set up to
                  work with the PlanYear account log. Once configured, we will
                  send a test email to that address to ensure it was properly
                  set up. The status will show in the &quot;Email Status&quot;
                  column of the configuration table.
                </p>
              ) : (
                <p className={styles.descriptionOne}>
                  Please enter a support team name and the email address below
                  that has been set up to work with the PlanYear account log.
                  Once configured, we will send a test email to that address to
                  ensure it was properly set up. The status will show in the
                  &quot;Email Status&quot; column of the configuration table.
                </p>
              )}
              {ISSUE_TYPE_ACCOUNT == type ? (
                <p className={styles.descriptionTwo}>
                  Confirming the connection may take a few minutes. If you still
                  see &quot;Connection pending&quot;, please confirm that the
                  setup instructions were followed properly.
                </p>
              ) : (
                <p className={styles.descriptionTwo}>
                  Confirming the connection may take a few minutes. If you still
                  see &quot;Connection pending&quot;, please confirm that the
                  setup instructions were followed properly.
                </p>
              )}
              <div className={styles.alertInfoWrapper}>
                <Alert
                  message={
                    <div className={styles.alertWrapper}>
                      <span className={styles.alertTitleWrapper}>
                        <img
                          src={alertInfo}
                          alt="info icon"
                          className={styles.infoIcon}
                        />
                        To send another test email, click the configuration icon
                        that will appear
                        <div className={styles.secondRow}>
                          when you hover over &quot;Connection pending&quot;.
                        </div>
                      </span>
                    </div>
                  }
                  type="info"
                  icon={<img src={alertInfo} alt="info icon" />}
                />
              </div>
              {savingError && (
                <div className={styles.errorWrapper}>
                  <AlertMessage
                    wrapperClassName={styles.errorWrapper}
                    type={alertMessage.type}
                    message={alertMessage.message}
                    closeAlert={() => {
                      setSavingError(false);
                    }}
                  />
                </div>
              )}
            </div>
            <Form
              form={form}
              layout="vertical"
              className={styles.inputForm}
              onValuesChange={updateFormData}
            >
              <Row justify="space-between">
                {ISSUE_TYPE_SUPPORT == type && isEmpty(name) && (
                  <Col xs={11}>
                    <Form.Item
                      name="name"
                      label="Support Team Name *"
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                          whitespace: true,
                        },
                        {
                          validator: (rule, name) => {
                            return duplicateValidateSupportTeamName(name);
                          },
                          message:
                            'Support Team Name is already being used. Please enter another name.',
                          validateTrigger: 'onSubmit',
                        },
                      ]}
                    >
                      <Input placeholder="e.g. General Support" />
                    </Form.Item>
                  </Col>
                )}
                <Col xs={ISSUE_TYPE_ACCOUNT === type ? 24 : 11}>
                  <Form.Item
                    name="email"
                    label={
                      ISSUE_TYPE_ACCOUNT === type
                        ? 'Assign an Email Address *'
                        : 'Support Email Address *'
                    }
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                        whitespace: true,
                      },
                      {
                        validator: (rule, email) => {
                          if (isValidEmailFormat(email) || isEmpty(email)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(new Error());
                          }
                        },
                        message: 'Please enter a valid email address',
                        validateTrigger: 'onSubmit',
                      },
                      {
                        validator: (rule, email) => {
                          if (!isEmpty(email) && isValidEmailFormat(email)) {
                            return Promise.all([
                              issuesLogService.validateDuplicateEmployerAutomatedEmails(
                                email
                              ),
                              issuesLogService.validateDuplicateOrganizationAutomatedEmails(
                                email
                              ),
                            ]);
                          } else {
                            return Promise.resolve();
                          }
                        },
                        message:
                          'Email address is already being used. Please enter a unique email address.',
                        validateTrigger: 'onSubmit',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              {ISSUE_TYPE_SUPPORT === type && (
                <>
                  <Divider />
                  <div className={styles.optionalCustomWrapper}>
                    <div className={styles.secondaryHeading}>
                      Employer Support Email Customization{' '}
                      <span className={styles.optionalSecondaryHeading}>
                        (optional)
                      </span>
                    </div>
                    <p className={styles.descriptionThree}>
                      Emails sent to the support address can also be forwarded
                      to broker admins.
                    </p>{' '}
                    <Form.Item
                      labelAlign="left"
                      label="Employer"
                      name="taggedEmployer"
                    >
                      <Select
                        disabled={isLoadingEmpData || isLoading}
                        showSearch
                        filterOption={filterByLabel}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentElement
                        }
                        options={employerList}
                        onChange={handleOnSelect}
                        allowClear
                        listHeight={128}
                        filterSort={filterSort}
                      ></Select>
                    </Form.Item>
                    <Popover
                      content={
                        'Click and follow the steps to add a new broker. After successfully adding, refresh the page to view the updated broker admins list.'
                      }
                      placement="top"
                      overlayClassName="popover"
                    >
                      <p
                        className={styles.addAdminHeader}
                        onClick={navigateUserToBrokerAdmin}
                      >
                        + Add Broker Admin
                      </p>
                    </Popover>
                    <Form.Item
                      label="Support Team Broker Admins *"
                      labelCol={{ span: 24 }}
                      name="brokerAdmins"
                      rules={[
                        {
                          required: false,
                          message: 'Please select Broker Admins',
                        },
                      ]}
                      dependencies={['taggedEmployer']}
                    >
                      <MultiSelectScroll
                        selectOptionsList={selectOptionsList}
                        setSelectOptionsList={setSelectOptionsList}
                        isLoading={loadingBrokerList}
                      />
                    </Form.Item>
                  </div>
                </>
              )}
            </Form>
            <PageActionButton
              className={styles.saveBtn}
              type="primary"
              onClick={save}
              loading={isLoading}
            >
              Save
            </PageActionButton>
          </>
        )}

        <Row justify="center" className={styles.cancelWrapper}>
          <CancelButton disabled={false} onClick={onCancel}>
            Cancel
          </CancelButton>
        </Row>
      </div>
    </Modal>
  );
};

export default AutomatedEmailSetupModal;
