import { FC, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { List, Typography } from 'antd';

import styles from './filterWOMultiSelect.module.less';

export type OptionProps = {
  label: string;
  value: string;
};
interface FilterWOMultiSelectProps {
  options: OptionProps[];
  placeholder?: string;
  onSelectOption: (value: OptionProps) => void;
}
const FilterWOMultiSelect: FC<FilterWOMultiSelectProps> = ({
  options,
  placeholder,
  onSelectOption,
}) => {
  const [filter, setFilter] = useState('');

  const filteredOption: OptionProps[] = options
    ?.filter(
      (f) =>
        f?.label?.toLowerCase().includes(filter.toLowerCase()) || filter === ''
    )
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className={styles.filter}>
      <div className={styles.inputWrapper}>
        <input
          placeholder={placeholder}
          className={styles.filterInput}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        <SearchOutlined className={styles.searchIcon} />
      </div>

      <div className={styles.filterList}>
        <List>
          {filteredOption.map((item) => (
            <List.Item
              className={styles.listItem}
              key={item?.value}
              onClick={() => {
                onSelectOption(item);
                setFilter('');
              }}
            >
              <Typography.Text ellipsis>{item?.label}</Typography.Text>
            </List.Item>
          ))}
        </List>
      </div>
    </div>
  );
};

export default FilterWOMultiSelect;
