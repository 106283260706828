/**
 * This value is used to identify the time slot in which the refresh token request should be triggered from the
 * UP in order to get the access token
 */
export const REFRESH_REQUIRED_THRESHOLD: number = 25200;
export const USER_ACTIVITY_POPUP_THRESHOLD: number = 120;
export const ACTIVITY_LOGGING_CONTEXT: string = '/log/user-session';
/**
 * Designated expiration time for the user
 */
export const DESIGNATED_USER_ACTIVITY_EXPIRATION_TIME: number = 28740;

/**
 * Checks if a refresh token is required based on the current time.
 *
 * @param {number} current - The current time in seconds.
 * @return {boolean} - True if a refresh token is required, false otherwise.
 */
export const checkIfRefreshIsRequired = (current: number): boolean => {
  // Check if the current time is greater than or equal to the refresh required threshold.
  return REFRESH_REQUIRED_THRESHOLD >= current && current >= 0;
};

/**
 * Checks if the activity popup should be triggered based on the current time.
 *
 * @param {number} currentTime - The current time in seconds.
 * @return {boolean} - True if the activity popup should be triggered, false otherwise.
 */
export const checkIfActivityPopUpIsTriggered = (
  currentTime: number
): boolean => {
  return currentTime <= USER_ACTIVITY_POPUP_THRESHOLD;
};
