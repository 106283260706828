import axios from 'axios';
import { baseApi } from 'util/apiUtil';
import { DEFAULT_RFP_PROMPT } from 'modules/rfp/constants/RfpConstants';

const RFP_EXTRACT_QUESTIONS = `${baseApi}/v1/ai-tooling/rfp`;

export const fetchRfpQuestions = async (
  brokerId: string | undefined,
  file: any
) => {
  const formData = new FormData();
  formData.append('file', file?.originFileObj);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const res = await axios.post(
    `${RFP_EXTRACT_QUESTIONS}/${brokerId}/extract`,
    formData,
    config
  );
  return res?.data;
};

export const getSingleRfp = async (brokerId: string, rfpId: string) => {
  const res = await axios.get(`${RFP_EXTRACT_QUESTIONS}/${brokerId}/${rfpId}`);
  return res?.data;
};

export const fetchAnswer = async (
  brokerId: string | undefined,
  question: string,
  index: number,
  rfpId: string,
  documentIdList: any[]
) => {
  try {
    const res = await axios.post(
      `${RFP_EXTRACT_QUESTIONS}/${brokerId}/answer/?rfp-id=${rfpId}`,
      {
        question: question,
        metadata: '',
        prompt: DEFAULT_RFP_PROMPT,
        documentIdList: documentIdList,
      }
    );
    const responseData = { ...res.data, index };
    return responseData;
  } catch (error) {
    throw error;
  }
};

export const optimizeAnswer = async (
  brokerId: string,
  answer: string,
  type: any
) => {
  const res = await axios.post(
    `${RFP_EXTRACT_QUESTIONS}/${brokerId}/optimize`,
    {
      answer: answer,
      type: type,
    }
  );

  return res.data;
};

export const sendFeedback = async (
  brokerId: string,
  type: string,
  text: string
) => {
  await axios.put(`${RFP_EXTRACT_QUESTIONS}/answers/${brokerId}`, {
    type: type,
    text: text,
  });
  return 'ok';
};

export const advancedOptimize = async (
  brokerId: string,
  question: string,
  includedChunks: any[],
  userContext: string,
  rfpId: string
) => {
  const res = await axios.post(
    `${RFP_EXTRACT_QUESTIONS}/${brokerId}/answer?advanced=true&rfp-id=${rfpId}`,
    {
      question: question,
      metaData: '',
      prompt: '',
      includedChunks: includedChunks,
      userContext: userContext,
    }
  );
  return res.data;
};

export const retrainAiUpload = (
  files: Array<any>,
  brokerId: string,
  locationId: string
) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const uploadPromises = [];

  for (const file of files) {
    const formData = new FormData();
    formData.append('file', file?.originFileObj);
    formData.append('metadata', '');
    formData.append('location-id', locationId);

    uploadPromises.push(
      axios.post(`${RFP_EXTRACT_QUESTIONS}/${brokerId}/train`, formData, config)
    );
  }

  return Promise.all(uploadPromises);
};

export const saveRfpList = (
  rfpList?: any,
  brokerId?: string,
  rfpName?: string,
  rfpStatus?: string
) => {
  const requestBody = {
    name: rfpName,
    questionsAndAnswers: rfpList,
    generatedRFP: '',
    status: rfpStatus,
  };

  return axios.post(`${RFP_EXTRACT_QUESTIONS}/${brokerId}`, requestBody);
};

export const getRfpList = (
  brokerId: string,
  pageStart: number,
  pageEnd: number,
  locationIds: string[]
) => {
  return axios.post(
    `${RFP_EXTRACT_QUESTIONS}/${brokerId}/search?page=${pageStart}&size=${pageEnd}`,
    { locationIds }
  );
};

export const updateRfp = (
  brokerId: string,
  rfpId: string,
  rfpName: string,
  qanda: any,
  generatedRfp: string,
  status: string
) => {
  const requestBody = {
    id: rfpId,
    name: rfpName,
    questionsAndAnswers: qanda,
    generatedRFP: generatedRfp,
    status: status,
  };

  return axios.put(
    `${RFP_EXTRACT_QUESTIONS}/${brokerId}/${rfpId}`,
    requestBody
  );
};

export const deleteRfp = (brokerId: string, rfpId: string) => {
  return axios.delete(`${RFP_EXTRACT_QUESTIONS}/${brokerId}/${rfpId}`);
};

export const getTrainingStatus = (brokerId: string) => {
  return axios.get(`${RFP_EXTRACT_QUESTIONS}/${brokerId}/train`);
};

export const getCompletedTrainings = (brokerId: string) => {
  return axios.get(`${RFP_EXTRACT_QUESTIONS}/${brokerId}/train`, {
    params: {
      statuses: 'COMPLETED',
    },
  });
};

export const deleteRetrainData = (brokerId: string, documentId: string) => {
  return axios.delete(
    `${RFP_EXTRACT_QUESTIONS}/${brokerId}/train/${documentId}`
  );
};

export const getRfpFile = async (
  brokerId: string,
  fileName: string,
  rfpId: string
) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${RFP_EXTRACT_QUESTIONS}/${brokerId}/${rfpId}/download`,
      responseType: 'arraybuffer',
    });
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
};

export const renameTrainFile = async (trainId: string, fileName: string) => {
  return await axios.put(
    `${RFP_EXTRACT_QUESTIONS}/${trainId}/${fileName}/rename`
  );
};

export const viewRfpTrainFile = async (rfpId: string) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${RFP_EXTRACT_QUESTIONS}/train/document`,
      responseType: 'arraybuffer',
      params: {
        'rfp-training-id': rfpId,
      },
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const newTab = window.open(url, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      throw new Error('Failed to open new tab.');
    }
  } catch (error) {
    console.error('Error opening the file in a new tab:', error);
  }
};

export const downloadRfpTrainFile = async (rfpId: string, rfpName: string) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${RFP_EXTRACT_QUESTIONS}/train/document`,
      responseType: 'arraybuffer',
      params: {
        'rfp-training-id': rfpId,
      },
    });
    const blob = new Blob([response.data]);
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', rfpName); // or any other extension
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(downloadUrl);
    link.remove();
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
};

export const addTagsToTrainFIle = async (trianId: string, tagIds: string[]) => {
  const requestBody = {
    rfpTrainingId: trianId,
    tagIds: tagIds,
  };

  return await axios.put(`${RFP_EXTRACT_QUESTIONS}/train/tags`, requestBody);
};

export const uploadRfpFile = async (
  brokerId: string | undefined,
  file: any,
  locationId: string
) => {
  const formData = new FormData();
  formData.append('location-id', locationId);
  formData.append('file', file?.originFileObj);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const res = await axios.post(
    `${RFP_EXTRACT_QUESTIONS}/${brokerId}/extract`,
    formData,
    config
  );
  return res?.data;
};

export const startRfpExtractProcess = async (
  brokerId: string | undefined,
  rfpId: string,
  documentIds: any[]
) => {
  const requestBody = {
    rfpId: rfpId,
    documentIds: documentIds,
  };

  const res = await axios.post(
    `${RFP_EXTRACT_QUESTIONS}/${brokerId}/extract/process`,
    requestBody
  );
  return res?.data;
};
