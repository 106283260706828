import React from 'react';
import { PLAN_REVIEW_CHANGE_MESSAGE } from 'constants/commonConstants';
import Button from 'components/buttons/Button/Button';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import thresholdIcon from 'assets/images/plan-opt-search-icon.svg';
import styles from './planReviewSwitchMessage.module.less';

type Props = {
  onConfirm: Function;
  visible: boolean;
};

const PlanReviewSwitchMessage = (props: Props) => {
  const { onConfirm, visible } = props;

  const CustomAlertMessage = () => {
    return (
      <div>
        {PLAN_REVIEW_CHANGE_MESSAGE}
        <Button
          onClick={() => onConfirm()}
          label="Opt for Manual Review"
          className={styles.button}
        >
          Opt for Manual Review
        </Button>
      </div>
    );
  };

  if (!visible) return <></>;

  return (
    <FixedAlertMessage
      message={<CustomAlertMessage />}
      closeAlert={() => {}}
      icon={<img src={thresholdIcon} alt="threshold icon" />}
      className={styles.alert}
    />
  );
};
export default PlanReviewSwitchMessage;
