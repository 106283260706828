import { isEmpty } from 'lodash';

import {
  SORT_MODELING_TOTAL_ENROLLMENTS,
  MDV_PLANS,
  OFFER_CATEGORY,
} from 'modules/renewals/constants/renewalsConstants';
import {
  CAP_PREMIUM,
  NULL_STRING,
} from 'modules/renewals/constants/planTableConstants';
import { BenefitCategory } from 'constants/commonConstants';
import FundingType from 'modules/plans/enums/FundingType';
import { baseApi } from 'util/apiUtil';

/**
 * Validates the provided value based on the given type.
 * @param {string} type The type of validation to perform.
 * @param {string} value The value to validate.
 *
 * @return {boolean} A boolean indicating whether the value is valid (true) or not (false).
 */
export const validate = (type: string, value: string): boolean => {
  // If the value is empty or null, it's considered valid
  if (value === '' || value === null) {
    return true;
  }
  // For CAP_PREMIUM type, remove non-numeric characters and check if it's a valid number
  if (type === CAP_PREMIUM) {
    value = value.replace('$', '');
    value = value.replace('.', '');
    value = value.replace(',', '');
    return !/^\d+$/.test(value);
  }
  // For SORT_MODELING_TOTAL_ENROLLMENTS type, check if it's a valid number
  if (type === SORT_MODELING_TOTAL_ENROLLMENTS) {
    return !/^\d+$/.test(value);
  }
  // Default case: return false (value is considered invalid)
  return false;
};

/**
 * Validates the provided plan data based on certain conditions.
 * @param {Object} planData The plan data to validate.
 * @param {Function} isNoCurrentPlans Function to check if there are no current plans.
 * @param {FundingType} fundingType The funding type of the plan (optional).
 * @param {string} benefitKind The kind of benefit (optional).
 *
 * @return {boolean} A boolean indicating whether the plan data is valid.
 */
export const validateObj = (
  planData: any,
  isNoCurrentPlans: boolean,
  fundingType?: FundingType,
  benefitKind?: string
): boolean => {
  // If plan name is empty or null, return true indicating invalid data
  if (planData?.name === '' || planData?.name === null) {
    return true;
  }
  // If the benefit kind is included in MDV_PLANS
  if (MDV_PLANS.includes(benefitKind?.toUpperCase() ?? '')) {
    // Check for specific conditions based on benefit kind and plan type
    if (
      ['', null, NULL_STRING].includes(planData?.planType) &&
      benefitKind?.toUpperCase() === OFFER_CATEGORY?.MEDICAL
    ) {
      return true;
    }
    // Check if monthly premiums are valid
    if (
      planData?.monthlyPremiums?.filter((item: any) =>
        validate(CAP_PREMIUM, item?.value)
      )?.length !== 0
    ) {
      return true;
    }
    // Check if enrolments are valid and if there are no current plans
    if (
      (planData?.enrolments?.filter((item: any) =>
        validate(
          SORT_MODELING_TOTAL_ENROLLMENTS,
          item?.value?.replaceAll(',', '')
        )
      )?.length !== 0 ||
        (planData?.enrolments ?? null) === null ||
        planData?.enrolments?.length === 0) &&
      isNoCurrentPlans
    ) {
      return true;
    }
    // Check specific conditions for dental and vision benefits with self-funded funding type
    if (
      [BenefitCategory.DENTAL.value, BenefitCategory.VISION.value].includes(
        benefitKind!.toUpperCase()
      ) &&
      fundingType === FundingType.SELF_FUNDED &&
      (isEmpty(planData?.administrationFee?.value) ||
        planData?.administrationFee?.value === NULL_STRING)
    ) {
      return true;
    }
    // If none of the above conditions are met, return false
    return false;
  }
  // If none of the above conditions are met, return false
  return false;
};

/**
 * Downloads a file object.
 * @param {File} file The file object to download.
 * @param {boolean} isEdit Indicates whether the file is being downloaded for editing purposes.
 * @param {Object} editData Data related to the editing process.
 * @param {Object} documentDetails Details of the document being edited.
 * @param {string} selectedRecentFileId The ID of the selected recent file.
 */
export const downloadUploadedFileObj = (
  file: File,
  isEdit: boolean,
  editData: any,
  documentDetails: any,
  selectedRecentFileId: string
): void => {
  // Check if file exists
  if (file) {
    // Create a temporary anchor element for downloading
    const a = document.createElement('a');
    document.body.appendChild(a);
    // Hide the anchor element
    a.style.display = 'none';
    let url = '';

    // Determine the URL based on whether it's an edit or a new file
    if (isEdit && editData?.s3DocumentDetails === documentDetails) {
      url = `${baseApi}/v1/renewals/offers/${editData?.offerId}/quote-document`;
    } else if (selectedRecentFileId) {
      url = `${baseApi}/v1/document-extraction/${selectedRecentFileId}/source-file`;
    } else {
      url = window.URL.createObjectURL(file);
    }

    a.href = url;
    a.download = file.name;
    a.click();
    window.URL.revokeObjectURL(url);
  }
};
