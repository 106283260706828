import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal, notification } from 'antd';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import IconInfo from 'assets/images/icon-info.svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import DeductiblesOOPMaxUPWrapper from 'modules/plans/components/DeductiblesOOPMax/DeductiblesOOPMaxUPWrapper';
import BasicPlanInfoUPWrapper from 'modules/plans/components/BasicPlanInfo/BasicPlanInfoUPWrapper';
import RatesUPWrapper from 'modules/plans/components/Rates/RatesUPWrapper';
import ServiceUPWrapper from 'modules/plans/components/Services/ServiceUPWrapper';
import RxCardInfoUPWrapper from 'modules/plans/components/RxCardInfo/RxCardInfoUPWrapper';
import MedicalPlan from 'model/plans/MedicalPlan';
import { EditMdvTypes } from 'modules/plans/constants';
import { formVerificationMsg } from 'constants/commonConstants';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import PanelSection from 'modules/plans/enums/PanelSection';
import AddEnrollments from 'modules/plans/components/Enrollments/AddEnrollments/AddEnrollments';
import {
  findMedicalPlanById,
  saveMedicalPlan,
} from 'modules/plans/slices/medicalPlanSlice';
import { buildRates } from 'modules/plans/utils';
import { getEditedPlanField } from 'util/commonUtil';
import { PLAN_NOTIFICATION_KEY } from 'constants/benguideCollaborationConstants';

import MedicalPlanRecommenderPopup from 'components/MedicalPlanRecommenderPopup/MedicalPlanRecommenderPopup';
import { getPlanRecommenderStatus } from 'modules/plans/slices/planRecommenderSllice';
import styles from './editMedicalPlanModal.module.less';

type EditType = {
  value: string;
  title: string;
  width: number;
};

type EditMedicalPlanModalProps = {
  plan: any;
  onClose: Function;
  isModalVisible: boolean;
  editType: EditType;
  setViewModal: Function;
};

const EditMedicalPlanModal: FC<EditMedicalPlanModalProps> = ({
  plan,
  onClose,
  editType,
  isModalVisible,
}: EditMedicalPlanModalProps) => {
  const [currentPlan, setCurrentPlan] = useState<MedicalPlan>(plan);

  const dispatch = useAppDispatch();
  const medicalPlanRef = useRef<any>();
  const medicalRatesRef = useRef<any>();
  const enrollmentsRef = useRef<any>();
  const [notificationKey] = useState(PLAN_NOTIFICATION_KEY);
  const [confirmationVisible, setConfirmationVisible] =
    useState<boolean>(false);
  const [dispatched, setDispatched] = useState<boolean>(false);
  const [initialRates, setInitialRates] = useState<any>();
  const [isInitialRate, setIsInitialRate] = useState<boolean>(true);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [requiredFieldError, setRequiredFieldError] = useState<boolean>(false);

  const { inProgress } = useAppSelector((state) => state.plan.plans);

  const { isLoading, planRecommender } = useAppSelector(
    (state) => state.planRecommender
  );

  useEffect(() => {
    setCurrentPlan(plan);
    if (isInitialRate) {
      setInitialRates(plan.rates);
      setIsInitialRate(false);
    }
    // eslint-disable-next-line
  }, [plan]);

  const cancelModal = () => {
    onClose();
  };

  const checkMedicalPlanChangedFields = (updatedMedicalPlan: MedicalPlan) => {
    return (
      (!isEqual(updatedMedicalPlan, plan) &&
        [
          EditMdvTypes.SERVICES.value,
          EditMdvTypes.DEDUCTIBLES_OOP_MAX.value,
          EditMdvTypes.RX.value,
          EditMdvTypes.RATES.value,
          EditMdvTypes.ENROLLMENTS.value,
        ].includes(editType.value)) ||
      (!isEqual(updatedMedicalPlan.rates, initialRates) &&
        editType.value === EditMdvTypes.RATES.value)
    );
  };

  const savePlan = useCallback(async () => {
    if (
      (editType.value === EditMdvTypes.BASIC_INFO.value &&
        (await medicalPlanRef?.current?.isValidForm())) ||
      editType.value !== EditMdvTypes.BASIC_INFO.value
    ) {
      let updatedMedicalPlan = currentPlan;
      if (editType.value === EditMdvTypes.RATES.value) {
        const { invalidFormFields = [] } =
          await medicalRatesRef?.current?.validate();
        if (!isEmpty(invalidFormFields)) {
          return;
        }
      }
      if (editType.value === EditMdvTypes.BASIC_INFO.value) {
        updatedMedicalPlan = {
          ...currentPlan,
          rates: buildRates(currentPlan.groups, currentPlan),
        };
      }
      if (checkMedicalPlanChangedFields(updatedMedicalPlan)) {
        updatedMedicalPlan = { ...updatedMedicalPlan };

        updatedMedicalPlan.planChangedFieldTypes = [
          getEditedPlanField(editType.value),
        ];
      }
      dispatch(
        saveMedicalPlan(updatedMedicalPlan, () => {
          onClose();
          medicalPlanRef?.current?.reset();
          if (plan && plan.id) {
            dispatch(findMedicalPlanById(plan.id));
          }
        })
      );
      notification.close(notificationKey);
    }
    // eslint-disable-next-line
  }, [currentPlan, dispatch, editType.value, notificationKey, onClose, plan]);

  useEffect(() => {
    if (!isLoading && dispatched) {
      if (
        planRecommender.anyChangesAffectToPlanRecommender &&
        planRecommender.planRecommenderEnabled
      ) {
        setConfirmationVisible(true);
        setDispatched(false);
      } else if (!planRecommender.anyChangesAffectToPlanRecommender) {
        savePlan();
        setDispatched(false);
      }
    }
  }, [isLoading, dispatched, planRecommender, savePlan]);

  const checkPlanRecommenderStatus = async () => {
    const isValid =
      editType.value === EditMdvTypes.BASIC_INFO.value
        ? await medicalPlanRef.current?.isValidForm()
        : true;

    if (isValid) {
      setDispatched(true);
      await dispatch(getPlanRecommenderStatus(currentPlan));
    } else {
      setRequiredFieldError(true);
      setAlertMessage({
        type: 'error',
        message: formVerificationMsg,
      });
    }
  };

  const getEditTypeComponent = () => {
    switch (editType.value) {
      case EditMdvTypes.BASIC_INFO.value:
        return (
          <BasicPlanInfoUPWrapper
            medicalPlan={currentPlan}
            setMedicalPlan={setCurrentPlan}
            basicPlanInfoRef={medicalPlanRef}
            medicalPlanYear={plan?.planYearId}
          />
        );
      case EditMdvTypes.DEDUCTIBLES_OOP_MAX.value:
        return (
          <DeductiblesOOPMaxUPWrapper
            medicalPlan={currentPlan}
            setMedicalPlan={setCurrentPlan}
          />
        );
      case EditMdvTypes.RATES.value:
        return (
          <RatesUPWrapper
            ratesRef={medicalRatesRef}
            medicalPlan={currentPlan}
            currentSection={PanelSection.RATES}
            dbgMetaData={''}
            setMedicalPlan={setCurrentPlan}
          />
        );
      case EditMdvTypes.RX.value:
        return (
          <ServiceUPWrapper
            medicalPlan={currentPlan}
            type={PanelSection.RX}
            setMedicalPlan={setCurrentPlan}
          />
        );
      case EditMdvTypes.SERVICES.value:
        return (
          <ServiceUPWrapper
            medicalPlan={currentPlan}
            type={PanelSection.SERVICES}
            setMedicalPlan={setCurrentPlan}
          />
        );
      case EditMdvTypes.RX_CARD_INFO.value:
        return (
          <RxCardInfoUPWrapper
            medicalPlan={currentPlan}
            setMedicalPlan={setCurrentPlan}
            medicalPlanRef={medicalPlanRef}
          />
        );
      case EditMdvTypes.ENROLLMENTS.value:
        return (
          <AddEnrollments
            plan={currentPlan}
            onChange={setCurrentPlan}
            isEdit={true}
            ref={enrollmentsRef}
          />
        );
    }
  };

  return (
    <>
      <Modal
        width={editType.width}
        title={<strong>{editType.title}</strong>}
        visible={isModalVisible}
        onCancel={cancelModal}
        okText="Done"
        footer={false}
        className={styles.medicalPlanChannelModal}
        closable={false}
        maskClosable={false}
        confirmLoading={isLoading || inProgress}
      >
        <div className={styles.medicalPlanChannelWrapper}>
          <div className={styles.leftBorder}></div>
          <div className={styles.medicalPlanChannel}>
            <div>
              <img src={IconInfo} alt="Icon Info" />
            </div>
            <div className={styles.defaultText}>
              Changing plan information here will change the plan information
              everywhere this plan is shown
            </div>
          </div>
          {requiredFieldError && (
            <AlertMessage
              type={alertMessage?.type}
              message={alertMessage?.message}
              closeAlert={() => {
                setRequiredFieldError(false);
              }}
              wrapperClassName={styles.alertMessage}
            />
          )}
          {getEditTypeComponent()}
          <div className={styles.buttons}>
            <Button onClick={cancelModal} className={styles.cancel}>
              Cancel
            </Button>
            <Button
              onClick={checkPlanRecommenderStatus}
              className={styles.done}
              loading={inProgress || isLoading}
            >
              Done
            </Button>
          </div>
        </div>
      </Modal>
      <MedicalPlanRecommenderPopup
        isModalVisible={confirmationVisible}
        planRecommender={planRecommender}
        setCloseModal={setConfirmationVisible}
        savePlan={savePlan}
      ></MedicalPlanRecommenderPopup>
    </>
  );
};

export default EditMedicalPlanModal;
