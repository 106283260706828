import { Checkbox, Col, Row } from 'antd';
import { StarFilled } from '@ant-design/icons';
import { isNullOrUndefined } from 'modules/plans/utils';

import styles from './exportOfferSelectSubModal.module.less';

type Props = {
  categoryHeader: string;
  offers: any[];
  selectedOfferIds: string[];
  setSelectedOfferIds: React.Dispatch<React.SetStateAction<string[]>>;
  offerSearchText: string;
};

const ExportOfferSelectSubModal = (props: Props) => {
  const {
    categoryHeader,
    offers,
    selectedOfferIds,
    setSelectedOfferIds,
    offerSearchText,
  } = props;

  if (!offers || offers.length === 0) {
    return <></>;
  }
  const filteredOfferNames = offers.filter((offer) => {
    return offer.name.toLowerCase().includes(offerSearchText.toLowerCase());
  });

  const isAllSelected = filteredOfferNames.every((offer) =>
    selectedOfferIds.includes(offer.id)
  );

  const toggleOfferSelection = (offerName: string) => {
    setSelectedOfferIds((prevSelectedOffers) => {
      if (prevSelectedOffers.includes(offerName)) {
        // Offer name is already selected, remove it.
        return prevSelectedOffers.filter(
          (selectedOffer) => selectedOffer !== offerName
        );
      } else {
        // Offer name is not selected, add it.
        return [...prevSelectedOffers, offerName];
      }
    });
  };

  const selectAll = () => {
    const offerNamesToAdd = filteredOfferNames
      .map((offer) => offer.id)
      .filter((offerName) => !selectedOfferIds.includes(offerName));

    setSelectedOfferIds((prevSelectedOffers) => [
      ...prevSelectedOffers,
      ...offerNamesToAdd,
    ]);
  };

  const deselectAll = () => {
    filteredOfferNames.forEach((offer) => {
      setSelectedOfferIds((prevSelectedOffers) =>
        prevSelectedOffers.filter((selectedOffer) => selectedOffer !== offer.id)
      );
    });
  };

  const selectOrDeselectAll = () => {
    if (!isAllSelected) {
      selectAll();
    } else {
      deselectAll();
    }
  };

  if (filteredOfferNames.length === 0) {
    return <></>;
  }

  return (
    <div>
      <div>
        <Row justify="space-between">
          <Col>
            <span className={styles.categoryHeader}>{categoryHeader}</span>
          </Col>
          <Col>
            <span
              className={styles.selectAllButton}
              onClick={() => selectOrDeselectAll()}
            >
              {isAllSelected ? 'Deselect All' : 'Select All'}
            </span>
          </Col>
        </Row>
      </div>
      <div className={styles.categoryWrapper}>
        {filteredOfferNames.map((offer: any) => {
          return (
            <div key={offer.id} className={styles.checkBoxBlock}>
              <Checkbox
                className={styles.checkbox}
                onClick={() => toggleOfferSelection(offer.id)}
                checked={selectedOfferIds.includes(offer.id)}
              >
                <span className={styles.checkboxLabel}>{offer.name}</span>
              </Checkbox>
              {!isNullOrUndefined(offer.recommended) && offer.recommended && (
                <span className={styles.recommendedTag}>
                  <StarFilled />
                </span>
              )}
              {!isNullOrUndefined(offer.renewal) && offer.renewal && (
                <span className={styles.renewalTag}>RENEWAL</span>
              )}
              {!isNullOrUndefined(offer.dtq) && offer.dtq && (
                <span className={styles.dtqTag}>DECLINED TO QUOTE</span>
              )}
              {!isNullOrUndefined(offer.draft) && offer.draft && (
                <span className={styles.draftTag}>DRAFT</span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ExportOfferSelectSubModal;
