import { ChatHistory } from 'modules/assistant/types/chatHistoryTypes';
import {
  CHAT_HISTORY_DURATION,
  CHAT_HISTORY_DURATION_LABEL,
} from 'modules/assistant/constants/constants';
import { ChildRoute, SEPARATOR } from 'routes/navigationTree';
import { topLevelMenus } from 'constants/authConstants';

export const transformHistoryResponseToSideNav = (
  response: ChatHistory
): ChildRoute[] => {
  const historyOrder = [
    CHAT_HISTORY_DURATION.TODAY,
    CHAT_HISTORY_DURATION.LAST_WEEK,
    CHAT_HISTORY_DURATION.LAST_30_DAYS,
  ];

  const sideNavArray: ChildRoute[] = [];

  historyOrder.forEach((historyKey) => {
    const history = response[historyKey as keyof ChatHistory] || [];
    if (history.length > 0) {
      sideNavArray.push({
        type: SEPARATOR,
        name: CHAT_HISTORY_DURATION_LABEL[historyKey],
        path: '',
      });
      history.forEach((chatObj) => {
        sideNavArray.push({
          path: `/brokers/:brokerId/assistant/${chatObj.conversationId}`,
          name: topLevelMenus.assistant,
          displayName: chatObj.subjectLine,
          icon: '',
        });
      });
    }
  });

  return sideNavArray;
};
