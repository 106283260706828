import { lumityApi } from 'api/apiSlice';
const dashboardRenewalApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardRenewal: builder.query<
      any,
      {
        organizationId: string | undefined;
      }
    >({
      query: (args) => {
        const { organizationId } = args;

        const renewalUrl: string = organizationId
          ? `v1/employers/renewal-dates?organizations-id=${organizationId}`
          : `v1/employers/renewal-dates`;

        return {
          url: renewalUrl,
          method: 'get',
        };
      },
    }),
  }),
});
export const { useGetDashboardRenewalQuery } = dashboardRenewalApi;
