import {
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import { Col, Collapse, Form, Row } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { cloneDeep, isEmpty, orderBy } from 'lodash';
import { isNullOrUndefined } from 'modules/plans/utils';
import {
  FourTier,
  RateType,
  NTier,
  N_TIER_PROPERTY,
  FOUR_TIER_PROPERTY,
  FourTierOrder,
  NTierOrder,
} from 'modules/plans/constants';
import { ENROLMENTS_INFO_WHEN_AGE_BAND_SELECTED } from 'constants/commonConstants';
import { DentalPlan } from 'model/plans/DentalPlan';
import { VisionPlan } from 'model/plans/VisionPlan';
import MedicalPlan from 'model/plans/MedicalPlan';
import PanelInputForm from 'modules/plans/components/PanelInputForm/PanelInputForm';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import Contribution from 'model/plans/Contribution';

import styles from './addEnrollments.module.less';

const { Panel } = Collapse;

type AddEnrollmentsProps = {
  onChange: Function;
  plan: MedicalPlan | DentalPlan | VisionPlan;
  benefitKind?: string;
  isEdit: boolean;
  isReviewHighlight?: boolean;
};

const AddEnrollments = forwardRef((props: AddEnrollmentsProps, ref) => {
  const { onChange, plan, isEdit = false, isReviewHighlight = false } = props;
  const [rates, setRates] = useState(plan?.rates ?? {});

  const firstTier: string = (Object.values(rates)[0] as any)?.type;

  const isTierAgeBanned: boolean = rates
    ? firstTier === RateType.AGE_BAND.value
    : false;

  const [form] = Form.useForm();

  const setRatesOnBlur = () => {
    onChange({ ...plan, rates });
  };

  const getValidationResult = async () => {
    let isComplete = true;
    const contributions = Object.values(rates);

    contributions?.forEach((group: any) => {
      const tierTypeExists =
        group?.type === RateType.N_TIER.value
          ? N_TIER_PROPERTY
          : group.type === RateType.FOUR_TIER.value
          ? FOUR_TIER_PROPERTY
          : null;

      if (tierTypeExists) {
        const contributionsArr = group?.[tierTypeExists]?.contributions;
        const enrollmentsExists = contributionsArr?.filter(
          (item: any) => item.enrollment
        );

        isComplete =
          isComplete && contributionsArr?.length === enrollmentsExists?.length;
      }
    });

    let isValid = false;
    try {
      await form.validateFields();
      isValid = true;
    } catch (error) {
      isValid = false;
    }
    return { isComplete, isValid };
  };

  useImperativeHandle(ref, () => ({
    validate() {
      return getValidationResult();
    },
  }));

  const updatePlanContribution = (
    group: string,
    contributionsProperty: string,
    index: number,
    value: string
  ) => {
    const newRates = cloneDeep(rates);

    if (
      isNullOrUndefined(
        rates?.[group]?.[contributionsProperty]?.contributions[index]
      )
    ) {
      return;
    }

    const contributionArr = [
      ...newRates[group]?.[contributionsProperty]?.contributions,
    ];

    const newUpdatedValue = {
      ...newRates[group]?.[contributionsProperty]?.contributions[index],
      enrollment: value,
    };

    contributionArr[index] = newUpdatedValue;

    newRates[group] = {
      ...newRates[group],
      [contributionsProperty]: {
        ...newRates[group]?.[contributionsProperty],
        contributions: contributionArr,
      },
    };
    setRates(newRates);
  };

  const updateEnrollment = (
    value: string,
    tierName: number,
    type: string,
    group: string
  ) => {
    const tierType =
      type === RateType.N_TIER.value ? N_TIER_PROPERTY : FOUR_TIER_PROPERTY;

    const currentIndex = rates?.[group]?.[tierType]?.contributions?.findIndex(
      (item: any) => item?.tierName === tierName
    );
    updatePlanContribution(group, tierType, currentIndex, value);
  };

  const renderSectionHeader = (title: string): ReactNode => {
    return (
      <div className={styles.enrollmentSectionHeader}>
        <span className={`text-form-label ${styles.enrollmentSectionLabel}`}>
          {title}
        </span>
        <hr />
      </div>
    );
  };

  const renderFourtierEnrollments = (
    group: string,
    index: number,
    contribution: any
  ) => {
    return (
      <Row
        justify="space-between"
        className={styles.rowWrapper}
        key={FourTier[contribution?.tierName]?.id}
      >
        <Col span={12} className={styles.tierName}>
          <span className="text-form-label">
            {FourTier[contribution?.tierName]?.label}
          </span>
        </Col>
        <Col span={7}>
          <Form.Item
            name={`${FourTier[contribution?.tierName]?.id}-${group}`}
            rules={[{ message: '' }]}
          >
            <NumberFormatInput
              allowNegative={false}
              decimalScale={0}
              maxLength={9}
              customClass={isReviewHighlight ? 'highlightField' : undefined}
              defaultValue={contribution?.enrollment ?? undefined}
              onChange={(event) => {
                const value = event.target.value;
                updateEnrollment(
                  value,
                  contribution?.tierName,
                  RateType.FOUR_TIER.value,
                  group
                );
              }}
              onBlur={setRatesOnBlur}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const renderNTiertierEnrollments = (
    group: string,
    index: number,
    contribution: any
  ) => {
    return (
      <Row
        justify="space-between"
        className={styles.rowWrapper}
        key={NTier[contribution?.tierName]?.id}
      >
        <Col span={12} className={styles.tierName}>
          <span className="text-form-label">
            {NTier[contribution?.tierName]?.label}
          </span>
        </Col>
        <Col span={7}>
          <Form.Item
            name={`${NTier[contribution?.tierName]?.id}-${group}`}
            rules={[{ message: '' }]}
          >
            <NumberFormatInput
              allowNegative={false}
              defaultValue={contribution?.enrollment ?? undefined}
              decimalScale={0}
              onChange={(event) => {
                const value = event.target.value;
                updateEnrollment(
                  value,
                  contribution?.tierName,
                  RateType.N_TIER.value,
                  group
                );
              }}
              onBlur={setRatesOnBlur}
              customClass={isReviewHighlight ? 'highlightField' : undefined}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const renderEnrollmentsByGroups = (group: string) => {
    const {
      type: tierType,
      fourTierContributions,
      ntierContributions,
    } = rates[group] ?? {
      type: firstTier,
      fourTierContributions: {},
      ntierContributions: {},
    };

    const F_TIER =
      (fourTierContributions?.contributions &&
        orderBy(
          fourTierContributions?.contributions,
          (val: any) => FourTierOrder[val?.tierName]
        )) ??
      [];

    const N_TIER =
      (ntierContributions?.contributions &&
        orderBy(
          ntierContributions?.contributions,
          (val: any) => NTierOrder[val?.tierName]
        )) ??
      [];

    if (isEmpty(F_TIER) && isEmpty(N_TIER)) {
      return <></>;
    }

    switch (tierType) {
      case RateType.N_TIER.value:
        return N_TIER?.map((contribution: Contribution, index: number) =>
          renderNTiertierEnrollments(group, index, contribution)
        );

      case RateType.FOUR_TIER.value:
        return F_TIER?.map((contribution: Contribution, index: number) =>
          renderFourtierEnrollments(group, index, contribution)
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    setRates(plan?.rates);
  }, [plan.rates]);

  return (
    <div className={styles.editEnrollmentContainer}>
      {isEdit && renderSectionHeader('Enrollments')}
      {isTierAgeBanned ? (
        <Row className={styles.infoWrapper}>
          <p>{ENROLMENTS_INFO_WHEN_AGE_BAND_SELECTED}</p>
        </Row>
      ) : (
        <div className={styles.itemWrapper}>
          {plan?.groups?.map((group: string, index: number) => (
            <div
              className={
                isEdit ? styles.panelWrapperEdit : styles.panelWrapperAdd
              }
              key={group}
            >
              <Collapse
                bordered={false}
                expandIconPosition={'right'}
                defaultActiveKey={0}
                expandIcon={({ isActive }) => (
                  <RightOutlined rotate={isActive ? -90 : 90} />
                )}
              >
                <Panel
                  header={group}
                  key={`$enrollmentForm-${group}`}
                  showArrow={true}
                >
                  <Row className={styles.enrollmentHeader} justify="end">
                    Enrollments
                  </Row>
                  <PanelInputForm
                    form={form}
                    name={`$enrollmentForm-${group}`}
                    layout="horizontal"
                    autoComplete="off"
                    key={index}
                    className={styles.enrollmentPanelForm}
                  >
                    {renderEnrollmentsByGroups(group)}
                  </PanelInputForm>
                </Panel>
              </Collapse>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

AddEnrollments.displayName = 'AddEnrollments';

export default AddEnrollments;
