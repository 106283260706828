import { StompSessionProvider } from 'react-stomp-hooks';
import FloatingChatBot from 'modules/chatbot/components/FloatingChatBot/FloatingChatBot';
import { BROKER_ADMIN_ASSISTANT_WEB_SOCKET_URL } from 'modules/assistant/services/BrokerAssistantConversationService';

const FloatingChatButton = () => {
  return (
    <StompSessionProvider
      url={BROKER_ADMIN_ASSISTANT_WEB_SOCKET_URL}
      onWebSocketError={(e) => console.error(e)}
      reconnectDelay={500}
    >
      <FloatingChatBot />
    </StompSessionProvider>
  );
};

export default FloatingChatButton;
