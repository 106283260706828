import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import get from 'lodash/get';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import DBGClient from 'modules/clients/DBGClient/DBGClient';
import {
  OPEN_ADD_NEW_PLAN_MODAL,
  OPEN_VOLUNTARY_BENEFITS_EDIT_MODAL,
  RELOAD_VOLUNTARY_BENEFITS_PLAN_LIST,
  RELOAD_VOLUNTARY_BENEFITS_SECTION,
  VOLUNTARY_BENEFITS_CHANNEL,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  EXIT_BENGUIDE,
  BENGUIDE_SAVE_COMPLETED,
  BENGUIDE_ACK_NOTIFICATION,
  CLOSE_MODALS,
} from 'modules/clients/DBGClient/DBGClientConts';
import { useNavContext } from 'hooks/useNavContext';
import useDBGClient from 'modules/clients/DBGClient/useDBGClient';
import iconInfo from 'assets/images/icon-info.svg';
import EventComponent from 'model/plans/EventComponent';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import EventDataType from 'model/plans/EventDataType';
import { BenefitCategory, BenefitKind } from 'constants/commonConstants';
import EditVoluntaryBenefitsPlan from 'modules/plans/voluntary/components/EditVoluntaryBenefitsPlan/EditVoluntaryBenefitsPlan';
import AddVoluntaryBenefitsPlanDBGWrapper from 'modules/plans/voluntary/components/AddVoluntaryBenefitPlan/AddVoluntaryBenefitsPlanDBGWrapper';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import { ERROR_MESSAGE_PLAN_EDITING } from 'constants/benguideCollaborationConstants';
import { changedBenguideModalState } from 'modules/benefitGuide/slices/benguideSlice';
import BenefitGuideNotificationsChannel from './BenefitGuideNotificationsChannel';

import styles from './voluntaryBenefitsPlansChannel.module.less';

const VoluntaryBenefitsPlansChannel = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [eventData, setEventData] = useState<EventDataType>();
  const [subscribedEvent, setSubscribedEvent] = useState('');
  const [disableSave, setDisableSave] = useState(false);
  const [planYearId, setPlanYearID] = useState<string>('');
  const dispatch = useAppDispatch();
  const [isRenewalStarted, setIsRenewalStarted] = useState(false);
  const { planYearsList } = useAppSelector((state) => state.employer.employer);
  const { employer } = useNavContext();
  const [getUpcomingPlanYears, { data: upcomingData }] =
    useLazyGetUpcomingPlanYearsByEmployerQuery();

  const cancelModalWhenRefresh = () => {
    setModalVisible(false);
  };

  const cancelModalWhenExit = () => {
    setModalVisible(false);
    dispatch(changedBenguideModalState(false));
  };

  const showOverlay = () => {
    if (disableSave) {
      dbgClient.postMessage({
        channel: VOLUNTARY_BENEFITS_CHANNEL,
        event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
        data: {},
      });
    }
  };

  const eventComponentMapper = {
    [OPEN_ADD_NEW_PLAN_MODAL]: {
      component: AddVoluntaryBenefitsPlanDBGWrapper,
      title: '',
      props: {
        visible: isModalVisible,
        onClose: () => {
          setModalVisible(false);
          dbgClient.postMessage({
            channel: VOLUNTARY_BENEFITS_CHANNEL,
            event: RELOAD_VOLUNTARY_BENEFITS_PLAN_LIST,
            data: {},
          });
          showOverlay();
        },
        benefitKind: eventData && eventData.item && eventData.item,
        isSaveDisabled: disableSave,
        isDBGPlan: true,
        dbgPlanYear: planYearId,
        isRenewalProcessStarted: isRenewalStarted,
      },
      useInbuiltModal: true,
    } as EventComponent,
    [OPEN_VOLUNTARY_BENEFITS_EDIT_MODAL]: {
      component: EditVoluntaryBenefitsPlan,
      title: `Edit ${
        get(BenefitKind, eventData?.benefitKind || '', '').label
      } Plan`,
      props: {
        onClose: () => {
          dbgClient.postMessage({
            channel: VOLUNTARY_BENEFITS_CHANNEL,
            event: RELOAD_VOLUNTARY_BENEFITS_SECTION,
            data: {},
          });
          showOverlay();
        },
        benefitKind: eventData && eventData.item && eventData.item,
        isDBGPlan: true,
        isSaveDisabled: disableSave,
        isModalVisible: isModalVisible,
      },
      useInbuiltModal: false,
      width: 600,
    } as EventComponent,
  } as any;

  const {
    component: Component,
    props,
    useInbuiltModal,
    width,
    title,
    cancelModal,
  } = eventComponentMapper[subscribedEvent] || {};

  const dbgClient = useDBGClient();

  useEffect(() => {
    if (employer) {
      dispatch(getPlanYears(employer?.id ?? ''));
      getUpcomingPlanYears({ employerId: employer?.id ?? '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employer?.id]);

  useEffect(() => {
    const isPlanYearInList = !!planYearsList?.find(
      (planYear: any) => planYear.id === planYearId
    )?.current;

    const hasUpcomingPlanYearWithNullId = upcomingData?.upcomingPlanYears?.some(
      (obj: any) => obj.planYearId === null
    );

    const isRenewalStarted = isPlanYearInList && hasUpcomingPlanYearWithNullId;

    setIsRenewalStarted(isRenewalStarted ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcomingData, planYearsList]);

  return (
    <>
      <DBGClient
        channel={VOLUNTARY_BENEFITS_CHANNEL}
        subscribeCommon={(event: string) => {
          if (CLOSE_MODALS === event) {
            setModalVisible(false);
          }
        }}
        subscribe={(event: string, data: any, dbgMetaData: any) => {
          switch (event) {
            case EXIT_BENGUIDE:
              cancelModalWhenExit();
              break;
            case BENGUIDE_SAVE_COMPLETED:
              cancelModalWhenRefresh();
              break;
            case BENGUIDE_ACK_NOTIFICATION:
              setDisableSave(true);
              break;
            default:
              setSubscribedEvent(event);
              setEventData(data);
              setModalVisible(true);
              setDisableSave(false);
              setPlanYearID(dbgMetaData?.planYearId);
              break;
          }
        }}
      />
      <BenefitGuideNotificationsChannel
        setDisableSave={() => setDisableSave(true)}
        isPlanEditModalOpen={isModalVisible}
        benefitKind={BenefitCategory.VOL_BENEFITS.value}
      />
      {Component && useInbuiltModal ? (
        <Component {...props} />
      ) : (
        <Modal
          width={width}
          visible={isModalVisible}
          onCancel={cancelModal}
          okText="Done"
          footer={false}
          className={styles.volBenefitsModal}
          closable={false}
          maskClosable={!disableSave}
          confirmLoading={true}
          destroyOnClose
        >
          <div className={styles.volBenefitsPlanChannelWrapper}>
            <div className={styles.title}>{title}</div>
            <div className={styles.leftBorder}></div>
            <div className={styles.volPlanChannel}>
              <div>
                <img src={iconInfo} alt="icon-info" />
              </div>
              <div className={styles.defaultText}>
                Changing plan information here will change the plan information
                everywhere this plan is shown.
              </div>
            </div>
            {disableSave && (
              <FixedAlertMessage
                type={'error'}
                message={ERROR_MESSAGE_PLAN_EDITING}
              />
            )}
            {Component && <Component plan={eventData} {...props} />}
          </div>
        </Modal>
      )}
    </>
  );
};

export default VoluntaryBenefitsPlansChannel;
