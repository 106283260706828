import { Divider, Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import styles from './summaryInfo.module.less';

type Props = {
  topLabel: string;
  amount: string;
  footer?: any;
  toolTipTitle?: string | undefined;
  toolTipPosition?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
    | undefined;
  noFooter: boolean;
  className?: string;
};
const SummaryInfo = (props: Props) => {
  const {
    topLabel,
    amount = 'N/A',
    footer,
    toolTipTitle,
    toolTipPosition,
    noFooter = false,
    className,
  } = props;
  return (
    <div className={styles.summaryInfo}>
      <label className={`${styles.summaryTitle} ${className}`}>
        {topLabel}
      </label>
      {toolTipTitle && (
        <Popover
          overlayClassName="popoverInner summaryInfotooltip"
          placement={toolTipPosition}
          content={toolTipTitle}
        >
          <QuestionCircleOutlined
            className={styles.questionIcon}
            style={{ marginLeft: '3px' }}
          />
        </Popover>
      )}
      <h1 className={styles.amountLabel}>{amount === null ? 'N/A' : amount}</h1>
      <Divider className={styles.footerDivider} />
      {!noFooter ? footer || <label>N/A</label> : ''}
    </div>
  );
};
export default SummaryInfo;
