import { FC } from 'react';

import { Row, Col } from 'antd';

import BrokerAdmin from 'model/BrokerAdmin';
import { AccountStatus } from 'modules/admins/constants/adminConstants';
import { buildFullName } from 'util/stringUtil';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { BROKER_ADMIN_TYPE_LABELS } from 'constants/commonConstants';

import styles from './brokerAdminDetails.module.less';

type BrokerAdminDetailsProps = {
  brokerAdminDetails: BrokerAdmin;
};

type AdminDetailRowProps = {
  fieldName: string;
  value: string;
};

const AdminDetailRow: FC<AdminDetailRowProps> = (
  props: AdminDetailRowProps
) => {
  const { fieldName, value } = props;
  return (
    <Row>
      <Col span={6} className={styles.columnHeader}>
        {fieldName}
      </Col>
      <Col className={styles.columnValue}>
        <OverflowPopover popoverContent={value} maxWidth="300px">
          {value}
        </OverflowPopover>
      </Col>
    </Row>
  );
};

const BrokerAdminDetailsForm: FC<BrokerAdminDetailsProps> = (
  props: BrokerAdminDetailsProps
) => {
  const { brokerAdminDetails } = props;
  const {
    firstName,
    lastName,
    title,
    email,
    locationMap,
    accountStatus,
    individualSubType,
  } = brokerAdminDetails;

  const getAccountStatus = () => {
    if (accountStatus === AccountStatus.ACCOUNT_ACTIVATED) {
      return 'Account Activated';
    } else if (accountStatus === AccountStatus.SEND_INVITE) {
      return 'Not Invited';
    } else if (accountStatus === AccountStatus.INVITED) {
      return 'Invited';
    } else {
      return 'Inactive';
    }
  };

  const getAdminLocations = () => {
    return locationMap ? Object.values(locationMap).join(', ') : '';
  };

  return (
    <div className={styles.brokerAdminDetailsWrapper}>
      <AdminDetailRow
        fieldName="Name"
        value={buildFullName(firstName, lastName)}
      />
      <AdminDetailRow
        fieldName="User Type"
        value={BROKER_ADMIN_TYPE_LABELS[individualSubType]}
      />
      <AdminDetailRow fieldName="Title" value={title} />
      <AdminDetailRow fieldName="Email" value={email} />
      <AdminDetailRow fieldName="Location Access" value={getAdminLocations()} />
      <AdminDetailRow fieldName="Account Status" value={getAccountStatus()} />
    </div>
  );
};

export default BrokerAdminDetailsForm;
