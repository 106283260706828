import { ChangeEvent, FC, useState } from 'react';
import { Popover, Row, Switch } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import DataTable from 'components/DataTable/DataTable';
import {
  DataColumn,
  createRenderColumns,
} from 'components/DataTable/DataColumn';
import SearchBar from 'components/SearchBar/SearchBar';
import ConfigureTechAdminSsoModal from 'modules/sso/components/ConfigureTechAdminSsoModal/ConfigureTechAdminSsoModal';

import TablePagination from 'model/TablePagination';

import styles from 'modules/sso/components/TechAdminSsoTable/TechAdminSsoTable.module.less';
import Broker from 'model/Broker';

type TechAdminSsoTableProps = {
  paginationConfig: TablePagination;
  setPaginationConfig: Function;
  data: Broker[];
  totalData?: number;
  loading?: boolean;
  setSearchText?: Function;
  searchText?: string;
  onUpdate: Function;
};

const TechAdminSsoTable: FC<TechAdminSsoTableProps> = ({
  paginationConfig,
  setPaginationConfig,
  data,
  totalData = 0,
  loading,
  setSearchText,
  searchText = '',
  onUpdate,
}) => {
  const [isEnableSsoModalOpen, setIsEnableSsoModalOpen] =
    useState<boolean>(false);
  const [selectedSsoDetails, setSelectedSsoDetails] = useState<any>({});

  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  const getSorter = () => (a: any, b: any) => 0;

  const handleBrokerSsoManageClick = (record: any) => {
    setSelectedSsoDetails({
      organizationId: record?.id,
      name: record?.name,
      metadataUrl: record?.ssoConfig?.metadataUrl,
      vendor: record?.ssoConfig?.vendor,
      isSsoEnabled: record?.ssoEnabled ?? false,
    });
    setIsEnableSsoModalOpen(true);
  };

  const handleBrokerSsoToggleClick = (record: any) => {
    setSelectedSsoDetails({
      organizationId: record?.id,
      name: record?.name,
    });
    setIsEnableSsoModalOpen(true);
  };

  const getData = (dataFilters: TablePagination) => {
    const { sorterInfo, paginationIndex, filterInfo, filters } = dataFilters;
    if (sorterInfo) {
      const paginationConfigData = {
        sorterInfo,
        paginationIndex,
        filterInfo: {
          limit: filterInfo.limit,
          offset: filterInfo.offset,
          searchText,
        },
        filters,
      };
      setPaginationConfig(paginationConfigData);
    } else {
      const paginationConfigData = {
        sorterInfo: {
          columnKey: 'name',
          field: 'name',
          order: 'ascend',
        } as SorterResult<any>,
        paginationIndex,
        filterInfo: {
          limit: filterInfo.limit,
          offset: filterInfo.offset,
          searchText,
        },
        filters,
      } as TablePagination;
      setPaginationConfig(paginationConfigData);
    }
  };

  const tableColumns: DataColumn[] = [
    {
      title: 'BROKER',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      sorter: getSorter(),
      defaultSortOrder: 'ascend',
      render: (data) => <div className={styles.brokerName}>{data}</div>,
    },
    {
      title: 'LAST STATUS UPDATED',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      width: '9%',
      align: 'center',
      render: (_data, record) => {
        return isEmpty(_data) ? (
          <div>-</div>
        ) : (
          <div>
            <div className={styles.lastUpdatedTime}>
              {dayjs(record?.lastUpdated).format('lll')}
            </div>
            <div className={styles.lastUpdatedBy}>
              {`by ${record?.lastUpdatedByName}`}
            </div>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'ssoConfig',
      key: 'ssoConfig',
      width: '3%',
      align: 'center',
      render: (_data, record) =>
        record.ssoEnabled ? (
          <div
            className={styles.manageSsoBtn}
            onClick={() => handleBrokerSsoManageClick(record)}
          >
            Manage
          </div>
        ) : (
          <div className={styles.disableSsoBtn}>Disabled</div>
        ),
    },
    {
      title: 'ENABLED',
      dataIndex: 'ssoConfig',
      key: 'ssoConfig',
      width: '5%',
      align: 'center',
      render: (_data, record) => (
        <div className={styles.ssoToggleSwitchCell}>
          <Popover
            overlayClassName="popoverInner recommendedtooltip"
            trigger={record.ssoEnabled ? 'hover' : []}
            content={
              <div>
                Use the <b>Manage</b> link
              </div>
            }
          >
            <Switch
              checked={record.ssoEnabled}
              onClick={() => handleBrokerSsoToggleClick(record)}
              disabled={record.ssoEnabled}
            />
          </Popover>
        </div>
      ),
    },
  ];

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setSearchText && setSearchText(searchText);
    const config = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText },
    };
    setPaginationConfig(config);
  };

  return (
    <>
      <div>
        <Row
          justify={'start'}
          align={'middle'}
          className={styles.searchRowWrapper}
        >
          <SearchBar
            value={searchText}
            placeholder="Search Brokers"
            isIssueSearch={true}
            onChange={handleSearch}
            isIconEndAligned
          />
        </Row>
        <div className={styles.columnHeaderAlignments}>
          <DataTable
            columns={createRenderColumns(tableColumns)}
            data={data}
            pagination={true}
            getData={getData}
            total={totalData?.toString()}
            pageSize={limit}
            loading={loading}
          />
        </div>
      </div>
      <ConfigureTechAdminSsoModal
        isOpen={isEnableSsoModalOpen}
        onCancel={() => setIsEnableSsoModalOpen(false)}
        onUpdate={onUpdate}
        data={selectedSsoDetails}
      />
    </>
  );
};

export default TechAdminSsoTable;
