import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';
import ImageUploader, {
  FORMAT_VALIDATE,
  SIZE_VALIDATE,
} from 'components/ImageUploader/ImageUploader';
import InputForm from 'components/InputForm/InputForm';
import CheckboxSelect from 'components/CheckboxSelect/CheckboxSelect';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import AlertMessage from 'components/Alert/AlertMessage';
import {
  CARRIER_BENEFIT_CATEGORY_TEXT,
  DUPLICATE_BENEFIT_CARRIER_ERROR_CODE,
  DUPLICATE_CARRIER_ERROR_CODE,
  benefitTypes,
} from 'modules/carriers/constants/carrierConstants';
import {
  formVerificationMsg,
  logoUploadHelperText,
} from 'constants/commonConstants';
import { isValidWeblink } from 'util/commonUtil';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import {
  addCarrier,
  handleCarrierLogoUpload,
  clearCarrierLogo,
  clearCarrierCreation,
  clearCarrierApiErrors,
  uploadCarrierLogoSuccess,
} from 'modules/carriers/slices/carrierSlice';
import { getCarriersByBenefitKind } from 'modules/plans/slices/basicPlanInfoSlice';
import { CARRIER_ALREADY_EXISTS_CODE } from 'modules/renewals/constants/renewalsConstants';
import CarrierType from 'modules/carriers/enums/CarrierType';
import styles from './addNewCarrierModal.module.less';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  selectedCarrierType: CarrierType.BROKER | CarrierType.EMPLOYER | '';
  benefitType: string;
  setCarrierToForm: (carrier: { label: string; value: string }) => void;
  brokerId: string;
  employerId: string;
};

export const basicFormFields = {
  requiredFields: ['name'],
  formFields: [
    'logoUrl',
    'name',
    'url',
    'phoneNumber',
    'providerSearchUrl',
    'providerSearchAdditionalInfo',
    'benefitCategories',
  ],
};
const AddNewCarrierModal = (props: Props) => {
  const {
    isOpen,
    setIsOpen,
    selectedCarrierType,
    benefitType,
    setCarrierToForm,
    brokerId,
    employerId,
  } = props;

  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const {
    logoUrl,
    inProgress,
    carrierCreateError,
    carrierCreationSuccess,
    logoReference,
    originalImgRef,
    requestType,
    carrierObj,
  } = useAppSelector((state) => state.carriers);

  const [formData, setFormData] = useState({
    logoUrl: '',
    name: '',
    url: '',
    carrierType: '',
    customCarrier: false,
    benefitCategories: [''],
    providerSearchAdditionalInfo: '',
    benefitDescription: '',
    providerSearchUrl: '',
    phoneNumber: '',
  });
  const [imageValidateSetting, setImageValidateSetting] = useState<string>('');
  const [
    isCarrierLogoValidationMsgRemoved,
    setCarrierLogoValidationMsgRemoved,
  ] = useState<boolean>(true);
  const [originalImg, setOriginalImg] = useState('');
  const [logoImg, setLogoImg] = useState<string>('');
  const [logoCropParams, setLogoCropParams] =
    useState<OriginalImageCropParams | null>(null);
  const [isImageError, setIsImageError] = useState<boolean>(false);
  const [requireFieldErrorFound, setRequireFieldErrorFound] =
    useState<boolean>(false);
  const [webURlError, setWebUrlError] = useState<boolean>(false);
  const [benefitError, setIsBenefitError] = useState<boolean>(false);
  const [selectedBenefitCategories, setSelectedBenefitCategories] = useState<
    string[]
  >([]);

  const [isSaveButtonLoading, setIsSaveButtonLoading] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const trimValue = (fieldName: string) => (event: any) => {
    const { value } = event.target;
    form.setFieldsValue({ [fieldName]: value.trim() });
  };

  useEffect(() => {
    if (isOpen) {
      setIsSaveButtonLoading(false);
      setSelectedBenefitCategories([benefitType]);
      const filledData = {
        ...formData,
        benefitCategories: [benefitType],
      };
      form.setFieldsValue(filledData);
      setFormData(filledData);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (!inProgress && requestType === uploadCarrierLogoSuccess.type) {
      setIsSaveButtonLoading(false);
    }
    // eslint-disable-next-line
  }, [inProgress]);

  useEffect(() => {
    if (carrierCreationSuccess && logoImg) {
      const findBenefitCarrier = carrierObj?.benefitCarrierVOS?.find(
        (item: any) => item?.benefitCategory === benefitType
      );
      dispatch(clearCarrierCreation());
      dispatch(clearCarrierLogo());
      dispatch(getCarriersByBenefitKind(benefitType, brokerId, employerId));
      setCarrierToForm({
        label: findBenefitCarrier?.name,
        value: findBenefitCarrier?.id,
      });
      closeModal();
    } else {
      if (carrierCreateError && carrierCreateError.data) {
        setIsSaveButtonLoading(false);
        setRequireFieldErrorFound(true);
        setAlertMessage(CARRIER_ALREADY_EXISTS_CODE);
      }
    }
    // eslint-disable-next-line
  }, [logoImg, carrierCreateError, carrierCreationSuccess]);

  const closeModal = useCallback(() => {
    form.resetFields();
    setLogoImg('');
    setSelectedBenefitCategories([]);
    setRequireFieldErrorFound(false);
    if (imageValidateSetting) {
      setImageValidateSetting('');
    }
    setIsBenefitError(false);
    setWebUrlError(false);
    setIsImageError(false);

    dispatch(clearCarrierApiErrors());
    setCarrierLogoValidationMsgRemoved(true);
    setFormData({
      logoUrl: '',
      name: '',
      url: '',
      carrierType: '',
      customCarrier: false,
      benefitCategories: [''],
      providerSearchAdditionalInfo: '',
      benefitDescription: '',
      providerSearchUrl: '',
      phoneNumber: '',
    });
    setIsOpen(false);
    // eslint-disable-next-line
  }, [imageValidateSetting]);

  const onConfirmCrop = async (
    image: string,
    originalImage?: string,
    cropParams?: OriginalImageCropParams
  ) => {
    if (originalImage) {
      dispatch(handleCarrierLogoUpload(image, originalImage));
      setOriginalImg(originalImage);
    }
    setLogoImg(image);
    if (cropParams) {
      setLogoCropParams(cropParams);
    }
    setCarrierLogoValidationMsgRemoved(true);
    setImageValidateSetting('');
    setIsImageError(false);
    setIsSaveButtonLoading(true);
  };

  const onLogoRemove = () => {
    form.setFieldsValue({ ...form, logoUrl: '' });
    setCarrierLogoValidationMsgRemoved(false);
    setImageValidateSetting('');
    setLogoImg('');
    setIsImageError(true);
    setOriginalImg('');
    setLogoCropParams(null);
  };

  const onValidateFails = (validateSetting: string) => {
    setImageValidateSetting(validateSetting);
    if (requireFieldErrorFound) {
      setRequireFieldErrorFound(false);
    }
  };

  const onSelectBenefitCategories = (e: any, benefitCategory: string) => {
    if (e.target.checked) {
      setSelectedBenefitCategories([
        ...selectedBenefitCategories,
        benefitCategory,
      ]);
    } else {
      setSelectedBenefitCategories(
        selectedBenefitCategories.filter(
          (category) => category !== benefitCategory
        )
      );
    }
    if (!isEmpty(selectedBenefitCategories)) {
      setIsBenefitError(false);
    }
  };

  const getBenefitCarrierUtilized = (value: string) => {
    return [benefitType].includes(value);
  };

  const getCheckedState = (value: string) =>
    selectedBenefitCategories.includes(value) ? true : false;

  const closeAlert = () => {
    setRequireFieldErrorFound(false);
    if (imageValidateSetting) {
      setImageValidateSetting('');
    }
    setIsBenefitError(false);
    setWebUrlError(false);

    setAlertMessage('');
  };
  const validateCarrierInfo = async () => {
    try {
      await form.validateFields(basicFormFields.requiredFields);
      if (isEmpty(selectedBenefitCategories)) {
        setIsBenefitError(true);
      }
      if (!logoImg || isEmpty(logoImg)) {
        setIsImageError(true);
        return false;
      }
      return true;
    } catch (errorInfo: any) {
      if (isEmpty(selectedBenefitCategories)) {
        setIsBenefitError(true);
      }
      if (!logoImg || isEmpty(logoImg)) {
        setIsImageError(true);
      }
      return errorInfo.errorFields.length === 0;
    }
  };

  const handleFormChange = (changedValues: any, values: any) => {
    if (
      [
        DUPLICATE_CARRIER_ERROR_CODE,
        DUPLICATE_BENEFIT_CARRIER_ERROR_CODE,
      ].includes(String(carrierCreateError?.data?.code)) ||
      alertMessage === CARRIER_ALREADY_EXISTS_CODE
    ) {
      dispatch(clearCarrierApiErrors());
    }
    form.setFieldsValue(values);
    setFormData({ ...formData, ...values });
    if (changedValues.logoUrl && imageValidateSetting) {
      form.setFieldsValue({ ...form, logoUrl: '' });
      setCarrierLogoValidationMsgRemoved(false);
    }
  };

  const getValidationMessage = (validateSetting: string): ReactNode => {
    switch (validateSetting) {
      case FORMAT_VALIDATE:
        return 'This image file type is not supported.';
      case SIZE_VALIDATE:
        return 'Maximum size allowed for this upload is 2 MB.';
      default:
        setImageValidateSetting('');
        return;
    }
  };

  const getAction = () => {
    if (!isImageError) {
      onConfirmSave();
    } else {
      setAlertMessage(formVerificationMsg);
      setRequireFieldErrorFound(true);
    }
  };

  const onClickSave = async () => {
    let isFormValid: boolean = true;
    isFormValid = await validateCarrierInfo();
    if (!logoUrl) {
      dispatch(clearCarrierLogo());
    }
    if (isFormValid) {
      if (isEmpty(selectedBenefitCategories)) {
        setAlertMessage(formVerificationMsg);
        setRequireFieldErrorFound(true);
        return;
      }
      const { url } = formData;

      if (url && !isValidWeblink(url)) {
        setAlertMessage(formVerificationMsg);
        setRequireFieldErrorFound(true);
        setWebUrlError(true);
        return;
      }

      setIsSaveButtonLoading(true);
      getAction();
    } else {
      setAlertMessage(formVerificationMsg);
      if (imageValidateSetting) {
        setImageValidateSetting('');
      }
      setRequireFieldErrorFound(true);
    }
  };

  const onConfirmSave = async () => {
    const { name, url } = formData;

    const newCarrier: any = {
      name: name.trim(),
      url: url ? url.trim() : '',
      carrierType: selectedCarrierType,
      customCarrier: true,
      benefitCategories: selectedBenefitCategories,
      logoUrl: '',
      logoReference: logoReference,
      originalImageCropParams: logoCropParams,
      originalLogoReference: originalImgRef,
    };

    if (brokerId) {
      newCarrier.organizationId = brokerId || '';
    }
    if (employerId && selectedCarrierType === 'EMPLOYER') {
      newCarrier.employerId = employerId;
    }

    await dispatch(addCarrier(newCarrier, selectedCarrierType));
  };
  return (
    <HalfScreenModal
      visible={isOpen}
      onCancel={() => {
        setIsOpen(false);
      }}
      width={710}
      title="Add New Carrier"
      destroyOnClose={true}
    >
      <>
        {(requireFieldErrorFound || imageValidateSetting) && (
          <AlertMessage
            type="error"
            message={
              imageValidateSetting
                ? getValidationMessage(imageValidateSetting)
                : alertMessage
            }
            closeAlert={closeAlert}
          />
        )}
        <InputForm form={form} onValuesChange={handleFormChange}>
          <Row>
            <Col>
              <Form.Item
                name="logoUrl"
                label="Carrier Logo"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
                validateStatus={
                  !isImageError &&
                  (!logoImg || !isEmpty(logoImg)) &&
                  !imageValidateSetting &&
                  isCarrierLogoValidationMsgRemoved
                    ? ''
                    : 'error'
                }
              >
                <ImageUploader
                  cropShape="rect"
                  aspect={4 / 3}
                  onConfirm={onConfirmCrop}
                  loading={inProgress}
                  uploadedImage={logoImg}
                  onRemove={onLogoRemove}
                  onValidateFails={onValidateFails}
                  showOperations
                  title="Carrier Logo"
                  helpText={logoUploadHelperText}
                  unCroppedImage={originalImg}
                  defaultCropArea={logoCropParams || undefined}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={18}>
            <Col xs={12}>
              <Form.Item
                name="name"
                label="Carrier Name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: 'Carrier Name required',
                    validateTrigger: ['onSubmit'],
                  },
                ]}
              >
                <Input onBlur={trimValue('name')} />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="url"
                label="Website URL"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: false,
                    message: '',
                    whitespace: true,
                  },
                ]}
                className={`ant-form-item-label ${
                  webURlError ? styles.errorCarrier : ''
                }`}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <>
            <Row>
              <label
                className={`ant-form-item-label ${
                  styles.benefitCategoryLabel
                } ${benefitError ? styles.errorCarrierBenefit : ''}`}
              >
                Benefit Categories *
              </label>
            </Row>
            <Row>
              <span className={styles.benefitCategorySelectText}>
                {CARRIER_BENEFIT_CATEGORY_TEXT}.
              </span>
            </Row>
            <Row className={styles.benefitTypes}>
              <Col span={8}>
                {Object.keys(benefitTypes).map((benefitType, index) => {
                  if ([0, 1, 2, 3, 4].includes(index)) {
                    return (
                      <Form.Item key={benefitType} name="benefitCategories">
                        <CheckboxSelect
                          onChange={(e) =>
                            onSelectBenefitCategories?.(e, benefitType)
                          }
                          disabled={getBenefitCarrierUtilized?.(benefitType)}
                          checked={getCheckedState?.(benefitType)}
                          className={`${
                            benefitError ? styles.errorCarrierBenefit : ''
                          }`}
                        >
                          <span className={styles.checkboxText}>
                            {benefitTypes[benefitType].label}
                          </span>
                        </CheckboxSelect>
                      </Form.Item>
                    );
                  }
                })}
              </Col>
              <Col span={8}>
                {Object.keys(benefitTypes).map((benefitType, index) => {
                  if ([5, 6, 7, 8, 9].includes(index)) {
                    return (
                      <Form.Item key={benefitType} name="benefitCategories">
                        <CheckboxSelect
                          onChange={(e) =>
                            onSelectBenefitCategories?.(e, benefitType)
                          }
                          disabled={getBenefitCarrierUtilized?.(benefitType)}
                          checked={getCheckedState?.(benefitType)}
                          className={`${
                            benefitError ? styles.errorCarrierBenefit : ''
                          }`}
                        >
                          <span className={styles.checkboxText}>
                            {benefitTypes[benefitType].label}
                          </span>
                        </CheckboxSelect>
                      </Form.Item>
                    );
                  }
                })}
              </Col>
              <Col span={8}>
                {Object.keys(benefitTypes).map((benefitType, index) => {
                  if ([10, 11, 12].includes(index)) {
                    return (
                      <Form.Item key={benefitType} name="benefitCategories">
                        <CheckboxSelect
                          onChange={(e) =>
                            onSelectBenefitCategories?.(e, benefitType)
                          }
                          disabled={getBenefitCarrierUtilized?.(benefitType)}
                          checked={getCheckedState?.(benefitType)}
                          className={`${
                            benefitError ? styles.errorCarrierBenefit : ''
                          }`}
                        >
                          <span className={styles.checkboxText}>
                            {benefitTypes[benefitType].label}
                          </span>
                        </CheckboxSelect>
                      </Form.Item>
                    );
                  }
                })}
              </Col>
            </Row>
          </>
        </InputForm>
        <Row justify="center" className={styles.margins}>
          <Col>
            <PageActionButton
              onClick={onClickSave}
              type="primary"
              className={styles.confirmButton}
              dataCy="saveCarrierBtn"
              loading={isSaveButtonLoading}
              disabled={!!carrierCreateError}
            >
              Save Carrier
            </PageActionButton>
          </Col>
        </Row>
        <Row justify="center" className={styles.margins}>
          <Col>
            <CancelButton
              htmlType="button"
              onClick={closeModal}
              className={styles.cancelBtnStyle}
            >
              Cancel
            </CancelButton>
          </Col>
        </Row>
      </>
    </HalfScreenModal>
  );
};

export default AddNewCarrierModal;
