import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import InAppNotification from 'model/InAppNotification';

import * as InAppNotificationService from 'modules/communication/services/InAppNotificationService';
import ErrorDetails from 'model/ErrorDetails';
import { handleAPIError } from 'util/apiUtil';

export interface inAppNotificationState {
  inProgress: boolean;
  fetchSuccess: boolean;
  fetchUnreadSuccess: boolean;
  error: ErrorDetails | null;
  requestType: string;
  notifications: Array<InAppNotification>;
  unreadNotificationCount: number;
  inAppNotificationObj: InAppNotification;
  inAppNotificationReadError: ErrorDetails | null;
  inAppNotificationGetError: ErrorDetails | null;
  unreadCountGetError: ErrorDetails | null;
}

const initialState: inAppNotificationState = {
  inProgress: false,
  fetchSuccess: false,
  fetchUnreadSuccess: false,
  error: null,
  requestType: '',
  notifications: [],
  unreadNotificationCount: 0,
  inAppNotificationObj: {} as InAppNotification,
  inAppNotificationReadError: null,
  inAppNotificationGetError: null,
  unreadCountGetError: null,
};

const inAppNotificationSlice = createSlice({
  name: 'inAppNotification',
  initialState,
  reducers: {
    inAppNotificationUnreadCountGetSucceeded: (
      state,
      action: PayloadAction<number>
    ) => {
      state.unreadNotificationCount = action.payload;
      state.requestType = action.type;
      state.fetchUnreadSuccess = true;
    },
    inAppNotificationUnreadCountGetFailed: (state, action) => {
      state.unreadNotificationCount = 0;
      state.unreadCountGetError = action.payload;
      state.requestType = action.type;
      state.fetchUnreadSuccess = false;
    },
    inAppNotificationGetStarted: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.fetchSuccess = false;
      state.requestType = action.type;
    },
    inAppNotificationGetSucceeded: (
      state,
      action: PayloadAction<Array<InAppNotification>>
    ) => {
      state.inProgress = false;
      state.fetchSuccess = true;
      state.notifications = action.payload;
      state.inAppNotificationGetError = null;
      state.requestType = action.type;
    },
    inAppNotificationGetFailed: (state, action) => {
      state.inProgress = false;
      state.fetchSuccess = false;
      state.inAppNotificationGetError = action.payload;
      state.inAppNotificationObj = {} as InAppNotification;
      state.requestType = action.type;
    },
    inAppNotificationReadStarted: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.requestType = action.type;
    },
    inAppNotificationReadSucceeded: (state, action) => {
      state.inProgress = false;
      state.inAppNotificationReadError = null;
      state.requestType = action.type;
    },
    inAppNotificationReadFailed: (state, action) => {
      state.inProgress = false;
      state.inAppNotificationReadError = action.payload;
      state.inAppNotificationObj = {} as InAppNotification;
      state.requestType = action.type;
    },
    inAppNotificationUnload: (state) => {
      state.inProgress = false;
      state.fetchSuccess = false;
      state.fetchUnreadSuccess = false;
      state.error = null;
      state.requestType = '';
      state.notifications = [];
      state.unreadNotificationCount = 0;
      state.inAppNotificationObj = {} as InAppNotification;
      state.inAppNotificationGetError = null;
      state.inAppNotificationReadError = null;
    },
  },
});

export const {
  inAppNotificationGetStarted,
  inAppNotificationGetSucceeded,
  inAppNotificationGetFailed,
  inAppNotificationReadStarted,
  inAppNotificationReadSucceeded,
  inAppNotificationReadFailed,
  inAppNotificationUnload,
  inAppNotificationUnreadCountGetSucceeded,
  inAppNotificationUnreadCountGetFailed,
} = inAppNotificationSlice.actions;

export default inAppNotificationSlice.reducer;

export const getNewNotificationCount = (individualId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await InAppNotificationService.getNewNotificationCount(
        individualId,
        30
      );
      const data = response.data;
      dispatch(inAppNotificationUnreadCountGetSucceeded(data));
    } catch (error: any) {
      dispatch(
        inAppNotificationUnreadCountGetFailed(
          JSON.parse(JSON.stringify(handleAPIError(error)))
        )
      );
    }
  };
};

export const getNotification = (individualId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(inAppNotificationGetStarted());
    try {
      const response = await InAppNotificationService.getNotifications(
        individualId,
        30
      );
      const data = response.data;
      dispatch(inAppNotificationGetSucceeded(data));
    } catch (error: any) {
      dispatch(
        inAppNotificationGetFailed(
          JSON.parse(JSON.stringify(handleAPIError(error)))
        )
      );
    }
  };
};

export const readNotification = (notificationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(inAppNotificationReadStarted());
    try {
      const response = await InAppNotificationService.readNotification(
        notificationId
      );
      dispatch(inAppNotificationReadSucceeded(response.status));
    } catch (error: any) {
      dispatch(
        inAppNotificationReadFailed(
          JSON.parse(JSON.stringify(handleAPIError(error)))
        )
      );
    }
  };
};
