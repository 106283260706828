import * as React from 'react';
import { ChangeEvent, FC, useEffect, useState, useRef } from 'react';
import {
  Col,
  Menu,
  Modal,
  notification,
  Row,
  Select,
  Table,
  TablePaginationConfig,
} from 'antd';
import { cloneDeep, isEmpty, capitalize, lowerCase } from 'lodash';
import { useParams } from 'react-router-dom';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import { useNavContext } from 'hooks/useNavContext';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import IconError from 'assets/images/alert-failure.svg';
import IconSuccess from 'assets/images/checkmark.svg';
import SearchBar from 'components/SearchBar/SearchBar';
import tableStyles from 'components/DataTable/datatable.module.less';
import selectIcon from 'assets/images/icon-caret-down.svg';
import { DataColumn } from 'components/DataTable/DataColumn';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import DocumentUploader from 'modules/billing/components/DocumentUploader/DocumentUploader';
import { useAppSelector } from 'hooks/redux';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import * as BillingService from 'modules/billing/services/BillingService';
import BillingMonthHistory from 'modules/billing/components/BillingMonthHistory/BillingMonthHistory';
import {
  useGetBillingFileHistoryQuery,
  useBillingUploadCarrierFileMutation,
} from 'modules/billing/slices/billingSlice';
import {
  ALLMONTHS,
  billingUploadFileTypes,
  carrierFileEditingAdmins,
  monthsShortNumbers,
} from 'modules/billing/constants/constants';
import {
  EMPLOYER_MEMBER_RESTRICTED,
  STATUS_200,
} from 'constants/commonConstants';
import { baseApi } from 'util/apiUtil';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import styles from './BillingFileHistory.module.less';

type NoBillingViewProps = {
  openImportBilling: Function;
  closeManageBillingModal: Function;
  isClosed: boolean;
  setIsBillingManageModalOpen: Function;
  setSelectedSummaryFile?: Function;
  setSelectedDetailFile?: Function;
  isPublishFile?: Function;
  isMonthDisabled?: Function;
  recordData?: Function;
};

const BillingFileHistory: FC<NoBillingViewProps> = (props) => {
  const {
    openImportBilling,
    closeManageBillingModal,
    isClosed,
    setIsBillingManageModalOpen,
    setSelectedSummaryFile,
    setSelectedDetailFile,
    isPublishFile,
    isMonthDisabled,
    recordData,
  } = props;

  const [
    billingUploadCarrierFile,
    {
      isLoading: fileUploadInProgress,
      isError: fileUploadFailed,
      error: fileUploadError,
      isSuccess: fileUploadedSuccessfully,
      isUninitialized: fileUploadPending,
    },
  ] = useBillingUploadCarrierFileMutation({});
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);

  const [searchText, setSearchText] = useState<string>();
  const [fileHistory, setFileHistory] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: pageSize,
    current: currentIndex,
  });
  const [sorterValue, setSorterValue] = useState<any>({});
  const [showCarrierFilesModal, setShowCarrierFilesModal] =
    useState<boolean>(false);
  const [fileDeleteIsLoading, setFileDeleteIsLoading] =
    useState<boolean>(false);
  const [currentBillingDate, setCurrentBilligDate] = useState<string>();
  const [currentFileName, setCurrentFileName] = useState<File | null>(null);
  const [fileRemoved, setFileRemoved] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState<boolean>(fileUploadFailed);
  const [currentBillingId, setCurrentBillingId] = useState<any>();
  const [fileError, setFileError] = useState<any>(fileUploadError);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedRecordId, setRecordId] = useState<string>('');
  const [deleteConfirm, setDeleteConfirmVisible] = useState<boolean>(false);
  const [fileHistoryModalOpen, setFileHistoryModalOpen] =
    useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<number>(1);
  const [selectedMonthString, setSelectedMonthString] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<string>('');

  const params = useParams();
  const { employer } = useNavContext();
  const documentUploaderRef = useRef(null);

  const {
    data: BillingFileHistory,
    isFetching,
    isUninitialized,
    refetch: refetchFileHistory,
  } = useGetBillingFileHistoryQuery({
    employerId: params.employerId,
  });

  useEffect(() => {
    if (!isFetching && !isUninitialized) {
      setFileHistory(BillingFileHistory?.records);
    }
  }, [isFetching, BillingFileHistory, isUninitialized]);

  useEffect(() => {
    if (openImportBilling) {
      setSearchText('');
    }
  }, [openImportBilling, refetchFileHistory]);

  useEffect(() => {
    setFileError(fileUploadError);
  }, [fileUploadError, fileUploadedSuccessfully]);

  useEffect(() => {
    setSorterValue({});
  }, [isClosed]);

  useEffect(() => {
    if (fileError) {
      if (fileError.data.code === 'invalid.file.extension') {
        setErrorMessage(
          'Invalid file type uploaded. Submitted files must be in .zip format.'
        );
      }
      if (fileError.data.code === 'exceed.maximum.file.size') {
        setErrorMessage(
          'The submitted file exceeds the file size limit of 100 MB. Please confirm that you are uploading the correct file or reduce the file size.'
        );
      }
      if (fileError.data.code === 'file.name.must.start.with.employer.name') {
        setErrorMessage(
          'Employer name on this zip file does not match this employer. Please rename the file to start with employer’s name given in the system and try again.'
        );
      }
      if (fileError && errorMessage && showCarrierFilesModal) {
        notification.open({
          message: 'File Upload Failed',
          description: errorMessage,
          placement: 'topRight',
          duration: 4,
          className: styles.notification,
          closeIcon: <></>,
          icon: (
            <img className={styles.notificationIcon} src={IconError} alt="" />
          ),
        });
      }
    }
  }, [errorMessage, fileError, showCarrierFilesModal]);

  useEffect(() => {
    if (!fileUploadPending && fileUploadedSuccessfully) {
      if (!fileUploadFailed && !fileUploadInProgress) {
        setShowCarrierFilesModal(false);
        reset();
      }
    }
  }, [
    fileUploadFailed,
    fileUploadInProgress,
    fileUploadPending,
    fileUploadedSuccessfully,
  ]);

  useEffect(() => {
    if (!fileUploadInProgress) {
      refetchFileHistory();
    }
  }, [fileUploadInProgress, refetchFileHistory]);

  const downloadBillingFile = async (fileId: string, fileType: string) => {
    try {
      await BillingService.getBillingDownloadFile(fileId, fileType);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      const url = `${baseApi}/v2/billing/${fileId}/files/export?type=${fileType}`;
      a.href = url;
      a.click();
      window.URL.revokeObjectURL(url);

      notification.open({
        message: 'Successfully Downloaded',
        description: 'Your billing files have been downloaded successfully.',
        placement: 'topRight',
        duration: 4,
        className: styles.notification,
        closeIcon: <></>,
        icon: (
          <img className={styles.notificationIcon} src={IconSuccess} alt="" />
        ),
      });
    } catch (e) {
      notification.open({
        message: 'Download Failed',
        description:
          'The requested download failed. Please check your connection and try again.',
        placement: 'topRight',
        duration: 4,
        className: styles.notification,
        closeIcon: <></>,
        icon: (
          <img className={styles.notificationIcon} src={IconError} alt="" />
        ),
      });
    }
  };

  const deleteBillingFile = async () => {
    setFileDeleteIsLoading(true);
    const response = await BillingService.deleteBillingFile(selectedRecordId);
    setFileDeleteIsLoading(false);
    if (response.status === STATUS_200) {
      refetchFileHistory();
      notification.open({
        message: 'Successfully Deleted',
        description: `Billing record has been successfully deleted.`,
        placement: 'topRight',
        duration: 3,
        className: styles.notification,
        closeIcon: <></>,
        key: 'billingFileDeleted',
        icon: (
          <img className={styles.notificationIcon} src={IconSuccess} alt="" />
        ),
      });
    } else {
      notification.open({
        message: 'File Delete Failed',
        description: ``,
        placement: 'topRight',
        duration: 3,
        className: styles.notification,
        closeIcon: <></>,
        key: 'billingFileDeleted',
        icon: (
          <img className={styles.notificationIcon} src={IconError} alt="" />
        ),
      });
    }

    setDeleteConfirmVisible(false);
  };

  const previewAndPublish = (data: any) => {
    const detailedBill = data?.files
      ?.filter((item: any) => item.type === billingUploadFileTypes.SAAS_DETAIL)
      ?.pop();

    const summaryBill = data?.files
      ?.filter((item: any) => item.type === billingUploadFileTypes.SAAS_SUMMARY)
      ?.pop();

    openImportBilling();
    setSelectedSummaryFile &&
      setSelectedSummaryFile(
        summaryBill !== undefined
          ? { name: summaryBill?.fileName, type: summaryBill?.type }
          : null
      );
    setSelectedDetailFile &&
      setSelectedDetailFile(
        detailedBill !== undefined
          ? { name: detailedBill?.fileName, type: detailedBill?.type }
          : null
      );
    isPublishFile && isPublishFile(true);
    isMonthDisabled && isMonthDisabled(true);
    recordData && recordData(data ?? null);
  };

  const getFileActions = (data: any) => {
    return (
      <Menu>
        <Menu.Item
          key="downloadBillingFile"
          onClick={() => downloadBillingFile(data.id, data.billingFileType)}
        >
          Download Billing File
        </Menu.Item>
        <Menu.Item
          onClick={() => previewAndPublish(data)}
          key="previewAndPublish"
        >
          {data.status === 'PUBLISHED' ? (
            <>Preview & Re-Publish</>
          ) : (
            <>Preview & Publish</>
          )}
        </Menu.Item>
        <Menu.Item
          key="billingFileHistory"
          onClick={() => {
            setSelectedMonth(monthsShortNumbers[data.billingMonth]);
            setSelectedMonthString(
              data.billingMonth.charAt(0).toUpperCase() +
                data.billingMonth.slice(1).toLowerCase()
            );
            setSelectedYear(data.year);
            setFileHistoryModalOpen(true);
          }}
        >
          File History
        </Menu.Item>
        <Menu.Item
          key="deleteBillingRecord"
          onClick={() => {
            setRecordId(data.id);
            setDeleteConfirmVisible(true);
          }}
        >
          Delete Billing Record
        </Menu.Item>
      </Menu>
    );
  };

  const fileHistoryTableColumns: DataColumn[] = [
    {
      title: 'BILLING MONTH',
      dataIndex: 'billingMonth',
      key: 'billingMonth',
      width: '30%',
      showSorterTooltip: false,
      sortOrder:
        (sorterValue?.columnKey ?? null) === 'billingMonth'
          ? sorterValue.order
          : isEmpty(sorterValue)
          ? 'descend'
          : null,
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => {
        if (a.year === b.year) {
          return (
            ALLMONTHS.indexOf(a.billingMonth) -
            ALLMONTHS.indexOf(b.billingMonth)
          );
        }
        return a.year > b.year ? 1 : -1;
      },
      render: (_, records) => {
        return (
          <span className={styles.billingMonth}>
            <OverflowPopover>
              {records.billingMonth.charAt(0).toUpperCase() +
                records.billingMonth.slice(1).toLowerCase()}{' '}
              {records.year}
            </OverflowPopover>
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: '30%',
      sortOrder:
        (sorterValue?.columnKey ?? null) === 'status'
          ? sorterValue.order
          : null,
      showSorterTooltip: false,
      sorter: (a, b) => {
        return a.status.localeCompare(b.status);
      },
      render: (status: string) => {
        if (status === 'PREVIEW') {
          status = 'Draft';
        } else if (status === 'PUBLISHED') {
          status = 'Published';
        }
        return (
          <span>
            <OverflowPopover>{status}</OverflowPopover>
          </span>
        );
      },
    },
    {
      title: 'FILE TYPE',
      dataIndex: 'billingFileType',
      key: 'billingFileType',
      width: '30%',
      showSorterTooltip: false,
      sortOrder:
        (sorterValue?.columnKey ?? null) === 'billingFileType'
          ? sorterValue.order
          : null,
      sorter: (a, b) => {
        return a.billingFileType.localeCompare(b.billingFileType);
      },
      render: (billingFileType: string) => {
        if (billingFileType === 'SAAS_SUMMARY') {
          billingFileType = 'Summary';
        } else if (billingFileType === 'SAAS_DETAIL') {
          billingFileType = 'Detail';
        } else if (billingFileType === 'SUMMARY_AND_DETAIL') {
          billingFileType = 'Summary and Detail';
        }
        return (
          <span>
            {!isEmpty(billingFileType) && (
              <OverflowPopover>{billingFileType}</OverflowPopover>
            )}
          </span>
        );
      },
    },
    {
      title: 'CARRIER FILES',
      dataIndex: 'carrierFileStatus',
      key: 'carrierFileStatus',
      sortOrder:
        (sorterValue?.columnKey ?? null) === 'carrierFileStatus'
          ? sorterValue.order
          : null,
      width: '30%',
      showSorterTooltip: false,
      sorter: (a, b) => {
        let aStatus: string;
        let bStatus: string;
        if (a && a.carrierFileStatus) {
          aStatus = 'Yes';
        } else {
          aStatus = 'No';
        }
        if (b && b.carrierFileStatus) {
          bStatus = 'Yes';
        } else {
          bStatus = 'No';
        }
        return aStatus.localeCompare(bStatus);
      },
      render: (carrierFileStatus: boolean, record) => {
        const carrierStatus = true;
        return (
          <div className={styles.carrierFileStatusYes}>
            {carrierFileEditingAdmins.includes(appBootInfo?.type ?? '') ? (
              carrierStatus === carrierFileStatus ? (
                <LinkButton
                  onClick={() => {
                    setCurrentBillingId(record.id);
                    setCurrentFileName(null);
                    setCurrentBillingId(record.id);
                    if ((record?.files?.length ?? 0) != 0) {
                      const carrierFileIndex = record.files.filter(
                        (data: any) => data.type === 'CARRIER'
                      );
                      carrierFileIndex?.length ?? 0
                        ? setCurrentFileName(
                            new File([], carrierFileIndex[0].fileName)
                          )
                        : '';
                    }
                    setShowCarrierFilesModal(true);
                    setCurrentBilligDate(
                      `${capitalize(record.billingMonth)} ${record.year}`
                    );
                  }}
                >
                  Yes
                </LinkButton>
              ) : (
                <LinkButton
                  onClick={() => {
                    setCurrentFileName(null);
                    setCurrentBillingId(record.id);
                    if ((record?.files?.length ?? 0) != 0) {
                      const carrierFileIndex = record.files.filter(
                        (data: any) => data.type === 'CARRIER'
                      );
                      carrierFileIndex?.length ?? 0
                        ? setCurrentFileName(
                            new File([], carrierFileIndex[0].fileName)
                          )
                        : '';
                    }

                    setShowCarrierFilesModal(true);
                    setCurrentBilligDate(
                      `${capitalize(record.billingMonth)} ${record.year}`
                    );
                  }}
                  className={styles.carrierFileStatusNo}
                >
                  <span className={styles.carrierFIleTitle}>No </span>(+Add)
                </LinkButton>
              )
            ) : carrierStatus === carrierFileStatus ? (
              <span>Yes</span>
            ) : (
              <span>No</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      align: 'right',
      showSorterTooltip: false,
      render: (text: string, data: any) => {
        return (
          <div className={styles.dropdownWrapper}>
            <SelectDropdown overlay={getFileActions(data)} />
          </div>
        );
      },
    },
  ];

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    const clonedFileHistory = cloneDeep(BillingFileHistory?.records);
    if (event.target.value) {
      setFileHistory(
        clonedFileHistory.filter((file: any) =>
          lowerCase(file.billingMonth + ' ' + file.year).includes(
            event.target.value.toLowerCase()
          )
        )
      );
    } else {
      setFileHistory(clonedFileHistory);
    }
  };

  const handleClientSideValidationFail = (failReason: string) => {
    let errorMessage = '';
    if (failReason === 'FORMAT') {
      errorMessage =
        'Invalid file type uploaded. Submitted files must be in .zip format.';
    } else if (failReason === 'SIZE') {
      errorMessage =
        'The submitted file exceeds the file size limit of 100 MB. Please confirm that you are uploading the correct file or reduce the file size.';
    }
    if (errorMessage !== '') {
      notification.open({
        message: 'File Upload Failed',
        description: errorMessage,
        placement: 'topRight',
        duration: 4,
        className: styles.notification,
        closeIcon: <></>,
        icon: (
          <img className={styles.notificationIcon} src={IconError} alt="" />
        ),
      });
    }
  };

  const reset = () => {
    setFile(null);
    setCurrentBilligDate('');
    setUploadError(false);
    setFileRemoved(true);
    setCurrentBillingId('');
    setErrorMessage('');
    setFileError(false);
  };

  const closeModal = () => {
    reset();
    setShowCarrierFilesModal(false);
  };

  useEffect(() => {
    setPagination({
      pageSize: pageSize,
      current: currentIndex,
    });
  }, [currentIndex, pageSize]);

  useEffect(() => {
    setPageSize(10);
    setCurrentIndex(1);
    setFileHistory([]);
    refetchFileHistory();
  }, [isClosed, refetchFileHistory]);

  const downloadCarrierFile = (billingRecordId: string) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const url = `${baseApi}/v2/billing/${billingRecordId}/files/export?type=CARRIER`;
    a.href = url;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const importBillingFileButton = (
    <LinkButton
      onClick={() => {
        openImportBilling();
      }}
    >
      + Import Billing File
    </LinkButton>
  );

  const employerLevelImportBillingFileButton = usePermitByUserType(
    importBillingFileButton,
    EMPLOYER_MEMBER_RESTRICTED
  );

  return (
    <>
      <ConfirmationDialog
        title="Delete Billing Record"
        confirmText="Yes - Delete Billing Record"
        cancelText="Cancel"
        isCancelLink={true}
        confirmLoading={fileDeleteIsLoading}
        closeModal={() => setDeleteConfirmVisible(false)}
        onConfirm={deleteBillingFile}
        visible={selectedRecordId !== '' && deleteConfirm}
      >
        <p className={styles.warningConfirmation}>
          <div>
            Are you sure you want to delete this billing record? This
            <br />
            action cannot be undone and will result in having any <br />
            attached files removed.
          </div>
        </p>
      </ConfirmationDialog>
      <Modal
        wrapClassName={`${styles.modalWrapper}`}
        visible={showCarrierFilesModal}
        footer={null}
        closeIcon={null}
        title="Manage Carrier Files"
        width={480}
        maskClosable={false}
        closable={false}
      >
        <Row className={styles.billingDate}>
          <div>{currentBillingDate}</div>
        </Row>
        <Row className={styles.instructionsSection}>
          <div>Please attach the appropriate carrier zip file</div>
          <div className={styles.namingConvention}>
            Name of zip file must start with the employer&lsquo;s name
          </div>
        </Row>
        <Row>
          <Col span={12}>
            <DocumentUploader
              ref={documentUploaderRef}
              onChange={(file: File) => {
                setFileRemoved(false);
                setFile(file);
                setCurrentFileName(null);
                setUploadError(false);
              }}
              onRemove={() => {
                setFileRemoved(true);
                setCurrentFileName(null);
              }}
              isRemoved={fileRemoved}
              allowedFileTypes="application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip"
              maxFileSizeMB={100}
              defaultFile={null}
              disabled={fileUploadInProgress}
              hideNoFileMessage={false}
              onFileNameClick={(file: File) =>
                downloadCarrierFile(currentBillingId)
              }
              onValidateFails={handleClientSideValidationFail}
              uploadError={uploadError}
              buttonText="+ Choose File"
              dummyFile={currentFileName}
            />
          </Col>
        </Row>
        <div className={styles.buttonWrapper}>
          <PageActionButton
            loading={fileUploadInProgress}
            onClick={() => {
              if (currentFileName === null) {
                billingUploadCarrierFile({
                  billingId: currentBillingId,
                  file: file ? file : null,
                });
              } else {
                closeModal();
              }
            }}
            type="primary"
            className={styles.billingSaveButton}
          >
            Save
          </PageActionButton>
          <div className={styles.cancelCarrierFileButton}>
            <LinkButton onClick={closeModal}>Cancel</LinkButton>
          </div>
        </div>
      </Modal>
      <div className={styles.manageBillingWrapper}>
        <div className={styles.manageBilling}>
          <Row className={styles.employerName}>
            <div>{employer?.name}</div>
          </Row>
          <Row className={styles.manageBillingTitle}>
            <Col span={12}>
              <h2>Billing History</h2>
            </Col>
            <Col span={12}>{employerLevelImportBillingFileButton}</Col>
          </Row>
          <SearchBar
            value={searchText}
            placeholder="Search"
            onChange={handleSearch}
          />
          <Row>
            <Col xs={24} className={styles.documentTable}>
              <Table
                className={`${tableStyles.tableWrapper} ${styles.tableWrapper}`}
                dataSource={fileHistory}
                columns={fileHistoryTableColumns}
                pagination={pagination}
                loading={isFetching}
                tableLayout="fixed"
                onChange={(pagination, filter, sorter) => {
                  const { current } = pagination;
                  setCurrentIndex(current ?? 0);
                  setSorterValue(sorter);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                value={pagination.pageSize}
                onChange={(e) => {
                  setPageSize(e);
                  setCurrentIndex(1);
                }}
                bordered={false}
                className={`${tableStyles.rowDropdown} ${styles.rowDropdown}`}
                suffixIcon={<img src={selectIcon} alt="select-icon" />}
              >
                {[10, 20, 50].map((pageSize) => (
                  <Select.Option key={pageSize} value={pageSize}>
                    View: {pageSize} Rows
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row className={styles.employerName}>
            <PageActionButton
              type="primary"
              onClick={() => {
                setPageSize(10);
                setCurrentIndex(1);
                closeManageBillingModal(false);
                setIsBillingManageModalOpen(!isClosed);
              }}
              className={styles.billingManageCloseButton}
            >
              Close
            </PageActionButton>
          </Row>
        </div>
      </div>
      <BillingMonthHistory
        onClose={setFileHistoryModalOpen}
        isVisible={fileHistoryModalOpen}
        billingMonth={selectedMonth}
        year={selectedYear}
        month={selectedMonthString}
      />
    </>
  );
};

export default BillingFileHistory;
