import { useState, useEffect } from 'react';
import { isUndefined } from 'lodash';
import { Divider, Switch } from 'antd';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useUpdateBrokerSsoMutation } from 'modules/sso/slices/ssoSlice';

import styles from 'modules/sso/components/ConfigureTechAdminSsoModal/ConfigureTechAdminSsoModal.module.less';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';

type Props = {
  isOpen?: boolean;
  onCancel: () => void;
  onUpdate: Function;
  data?: any;
};

const ConfigureTechAdminSsoModal = ({
  isOpen,
  onCancel,
  data,
  onUpdate,
}: Props) => {
  const [isSsoEnabled, setIsSsoEnabled] = useState<boolean>(false);
  const [updateBrokerSso, { isLoading: isUpdateBrokerSsoLoading }] =
    useUpdateBrokerSsoMutation();

  const isManaging =
    !isUndefined(data.metadataUrl) ||
    !isUndefined(data.vendor) ||
    !isUndefined(data.isSsoEnabled);

  useEffect(() => {
    if (data) {
      setIsSsoEnabled(data.isSsoEnabled ?? false);
    }
  }, [data]);

  const handleModalConfirm = async () => {
    await updateBrokerSso({
      organizationId: data.organizationId,
      isSsoEnabled: isSsoEnabled,
    })
      .unwrap()
      .then(() => {
        onUpdate();
        onCancel();
      });
  };

  return (
    <ConfirmationDialog
      centered
      modalClassName={styles.modal}
      width={600}
      title={isManaging ? 'Manage SSO' : 'Enable SSO'}
      recordName={data.name}
      visible={isOpen}
      confirmText={'Save'}
      onConfirm={() => handleModalConfirm()}
      onCancel={onCancel}
      closeModal={onCancel}
      cancelText="Cancel"
      isCancelLink
      destroyOnClose
      isRecordApplicable
      confirmBtnFullWidth
      confirmLoading={isUpdateBrokerSsoLoading}
    >
      <FixedAlertMessage
        type={'warning'}
        message={
          'Once SSO is enabled, it cannot be disabled. Please contact support if you need assistance.'
        }
      />
      <div className={styles.switchRow}>
        <div>
          <div>SSO</div>
          <div className={styles.enableState}>
            {isSsoEnabled ? 'Enabled' : 'Disabled'}
          </div>
        </div>
        <div className={styles.ssoToggleSwitchCell}>
          <Switch
            disabled={isManaging}
            checked={isSsoEnabled}
            onChange={(val) => setIsSsoEnabled(val)}
          />
        </div>
      </div>
      <Divider />
    </ConfirmationDialog>
  );
};

export default ConfigureTechAdminSsoModal;
