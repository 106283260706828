import React from 'react';
import { Popover, Row } from 'antd';
import { ReactComponent as EditIcon } from 'assets/images/icon-edit.svg';
import { ReactComponent as EditIconDisabled } from 'assets/images/icon-edit-disabled.svg';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import styles from './idCardEmployeeDataBody.module.less';

interface IdCardEmployeeDataBodyProps {
  data?: {
    field1: string;
    field2: string;
    field3?: string;
    phone?: string;
  };
  isEditApplicable?: boolean;
  isSaveVisible?: boolean;
  isNonEditable?: boolean;
  handleEditClick?: () => void;
  handleSaveClick?: () => void;
  isCompact?: boolean;
  withoutPrefixId?: string;
}

const IdCardEmployeeDataBody: React.FC<IdCardEmployeeDataBodyProps> = (
  props
) => {
  const {
    data,
    isEditApplicable,
    isSaveVisible,
    isNonEditable,
    handleEditClick,
    handleSaveClick,
    isCompact = false,
    withoutPrefixId,
  } = props;

  const renderEditableField = () => {
    if (isEditApplicable) {
      return (
        <div className={styles.editContainer}>
          {withoutPrefixId && !isSaveVisible && (
            <span>
              <OverflowPopover maxWidth={'100px'}>
                {data?.field1 ? data?.field1 : ' '}
              </OverflowPopover>
            </span>
          )}
          {isSaveVisible && (
            <span>
              <OverflowPopover maxWidth={'100px'}>
                {data?.field1 ? data?.field1 : ' '}
              </OverflowPopover>
            </span>
          )}
          {!isSaveVisible ? (
            isNonEditable ? (
              <Popover
                placement="top"
                overlayClassName="popover"
                content={
                  <div>
                    User has amended this ID #<br />
                    and cannot be further edited
                  </div>
                }
              >
                <EditIconDisabled className={styles.editIconDisabled} />
              </Popover>
            ) : (
              <>
                {!withoutPrefixId && (
                  <span
                    className={styles.addItemButton}
                    onClick={handleEditClick}
                  >
                    Add ID
                  </span>
                )}
                <span onClick={handleEditClick}>
                  <EditIcon className={styles.editIcon} />
                </span>
              </>
            )
          ) : (
            <span className={styles.saveBtn} onClick={handleSaveClick}>
              Save
            </span>
          )}
        </div>
      );
    } else {
      return (
        <span>
          <OverflowPopover maxWidth={isCompact ? '80px' : '130px'}>
            {data?.field1 ? data?.field1 : ' '}
          </OverflowPopover>
        </span>
      );
    }
  };

  return (
    <>
      <Row>{renderEditableField()}</Row>
      <Row>
        <span>
          <OverflowPopover maxWidth={isCompact ? '80px' : '130px'}>
            {data?.field2 ? data?.field2 : ' '}
          </OverflowPopover>
        </span>
      </Row>
      <Row>
        {data?.phone?.length ? (
          <span className={styles.phone}>
            <OverflowPopover maxWidth={'130px'}>{data.phone}</OverflowPopover>
          </span>
        ) : (
          <span>
            <OverflowPopover maxWidth={isCompact ? '80px' : '130px'}>
              {data?.field3}
            </OverflowPopover>
          </span>
        )}
      </Row>
    </>
  );
};

export default IdCardEmployeeDataBody;
