import { ReactElement, ReactNode } from 'react';

import { ColumnType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

import SelectDropdown from 'components/SelectDropdown/SelectDropdown';

export interface DataColumn extends ColumnType<any> {
  title: string | ReactNode;
  dataIndex: string;
  key: string;
  width?: string | number;
  sorter?: (eleOne: any, eleTwo: any) => any;
  render?: (colData: any, record: any, index: number) => ReactElement;
  defaultSortOrder?: SortOrder;
}

export const createRenderColumns = (brokerColumns: DataColumn[]): any[] => {
  if (brokerColumns != null) {
    return brokerColumns.map((element) => ({
      title: element.title,
      dataIndex: element.dataIndex,
      key: element.key,
      width: element.width,
      sorter: element.sorter,
      render: element.render,
      ellipsis: element.ellipsis,
      defaultSortOrder: element.defaultSortOrder,
    }));
  }
  return [];
};

export const getRowActions = (menu: ReactElement, colData?: any) => {
  return (
    // TODO refactor this. Currently this only allows DropDown
    <SelectDropdown overlay={menu} />
  );
};
