export const verifyEmployerNameInput = (
  employerName: string,
  employerNameLength: number
) => {
  return !employerName.match(/^[0-9a-zA-Z]+$/) && employerNameLength > 0;
};

export const removeSpecialCharacters = (employerName: string): string => {
  return employerName.replace(/[^a-zA-Z0-9]/g, '');
};
