import { FC, ReactNode } from 'react';
import { Alert } from 'antd';
import styles from './infoBanner.module.less';

type Props = {
  title: string;
  description: string | ReactNode;
  logo?: ReactNode;
  className?: string;
  showIcon?: boolean;
};

const InfoBanner: FC<Props> = ({
  title,
  description,
  logo,
  className = styles.infoBanner,
  showIcon = true,
}) => {
  return (
    <Alert
      message={title}
      description={description}
      className={className}
      icon={logo}
      showIcon={showIcon}
    />
  );
};

export default InfoBanner;
