import React from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  CONTACT_SALES_DESCRIPTION,
  CONTACT_SALES_HEADER,
  CONTACT_SALES_SECONDARY_DESCRIPTION,
} from 'constants/aiAsstantConfigurationConstants';

type Props = {
  onConfirm: Function;
  onCancel: () => void;
  visible: boolean;
};

const ContactSales = (props: Props) => {
  const { onConfirm, onCancel, visible } = props;
  return (
    <ConfirmationDialog
      title={CONTACT_SALES_HEADER}
      centered
      isDanger={false}
      onConfirm={() => {
        onConfirm();
      }}
      isCancelLink
      confirmBtnFullWidth
      cancelText="Cancel"
      confirmText="Send Notification"
      closeModal={() => onCancel()}
      visible={visible}
    >
      {CONTACT_SALES_DESCRIPTION}
      <br />
      <br />
      {CONTACT_SALES_SECONDARY_DESCRIPTION}
    </ConfirmationDialog>
  );
};

export default ContactSales;
