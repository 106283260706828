import { Col, Row, Tabs } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import CarrierCreateModal from 'modules/carriers/components/CarrierCreateModal/CarrierCreateModal';
import BrokerCarrierList from 'modules/carriers/components/BrokerCarrierList/BrokerCarrierList';
import CarrierContactsList from 'modules/carriers/components/CarrierContactList/CarrierContactsList';
import {
  CARRIERS,
  CARRIER_CONTACTS,
} from 'modules/carriers/constants/carrierConstants';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { navContexts } from 'constants/authConstants';
import { ALLOWED_INDIVIDUAL_SUB_TYPES } from 'constants/commonConstants';
import { useNavContext } from 'hooks/useNavContext';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import CarrierContactsCreateModal from 'modules/carriers/components/CarrierContactCreateModal/CarrierContactCreateModal';
import styles from './brokerEmployerCarrierTabView.module.less';

const BrokerEmployerCarrierTabView = () => {
  const carrierContactRef = useRef<any>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeTabKey, setActiveTabKey] = useState<string>(CARRIERS);
  const onTabClick = (key: string) => {
    setActiveTabKey(key);
    setIsModalOpen(false);
  };
  const { context, broker, employer } = useNavContext();

  const getCarrierListName = () => {
    if (context === navContexts.broker) {
      return `${broker?.name} `;
    } else {
      return `${employer?.name} `;
    }
  };

  const [queryParameters] = useSearchParams();
  useEffect(() => {
    if (queryParameters.get('carrier-contacts') === 'true') {
      setActiveTabKey(CARRIER_CONTACTS);
    }
  }, [queryParameters]);
  const { TabPane } = Tabs;

  const securedCarrierCreateBtn = usePermitByUserType(
    <PageActionButton
      className={styles.actionButton}
      type="primary"
      onClick={() => {
        setIsModalOpen(true);
      }}
    >
      Add Carrier
    </PageActionButton>,
    ALLOWED_INDIVIDUAL_SUB_TYPES
  );

  return (
    <div className={styles.employerInfo}>
      <Row className={styles.headerRow}>
        <Col span={12}>
          <h1 className={styles.titleWrapper}>
            <OverflowPopover>{getCarrierListName()}</OverflowPopover>
            &nbsp;Carriers
          </h1>
        </Col>
        <Col span={12}>
          {activeTabKey === CARRIERS ? (
            securedCarrierCreateBtn
          ) : (
            <PageActionButton
              className={styles.actionButton}
              type="primary"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Add Carrier Contact
            </PageActionButton>
          )}
        </Col>
      </Row>
      <br />
      <Tabs
        className={styles.employerTabs}
        type="card"
        defaultActiveKey={activeTabKey}
        size="large"
        onTabClick={onTabClick}
        activeKey={activeTabKey}
      >
        <TabPane tab="Carriers" key={CARRIERS}>
          <BrokerCarrierList />
        </TabPane>
        <TabPane tab="Contacts" key={CARRIER_CONTACTS}>
          <CarrierContactsList ref={carrierContactRef} />
        </TabPane>
      </Tabs>
      {activeTabKey === CARRIERS ? (
        <CarrierCreateModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isEdit={false}
        />
      ) : (
        <CarrierContactsCreateModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          carrierContactRef={carrierContactRef}
        />
      )}
    </div>
  );
};
export default BrokerEmployerCarrierTabView;
