import axios from 'axios';
import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as AdminService from 'modules/admins/services/AdminService';

import LumityAdmin from 'model/LumityAdmin';
import ErrorDetails from 'model/ErrorDetails';
import PaginationConfig from 'model/PaginationConfig';
import MetaData from 'model/MetaData';
export interface LumityAdminState {
  inProgress: boolean;
  error: ErrorDetails | null;
  requestType: string;
  lumityAdmins: { content: Array<LumityAdmin>; metadata: MetaData };
}

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  lumityAdmins: { content: [], metadata: {} },
} as LumityAdminState;

const lumityAdminSlice = createSlice({
  name: 'lumityAdmins',
  initialState,
  reducers: {
    getLumityAdminInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.error = null;
      state.lumityAdmins = { content: [], metadata: {} };
      state.requestType = action.type;
    },
    getLumityAdminCompleted: (
      state,
      action: PayloadAction<{ content: Array<LumityAdmin>; metadata: {} }>
    ) => {
      state.inProgress = false;
      state.lumityAdmins = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getLumityAdminFailed: (state, action) => {
      state.inProgress = false;
      state.lumityAdmins = { content: [], metadata: {} };
      state.error = { data: action.payload?.data };
      state.requestType = action.type;
    },
  },
});

export const {
  getLumityAdminFailed,
  getLumityAdminInProgress,
  getLumityAdminCompleted,
} = lumityAdminSlice.actions;
export default lumityAdminSlice.reducer;

export const getLumityAdmins =
  ({ page, size, sort, query }: PaginationConfig) =>
  async (dispatch: Dispatch) => {
    dispatch(getLumityAdminInProgress());
    try {
      const response = await AdminService.getLumityAdminList(
        page,
        size,
        sort,
        query
      );
      const data = response.data;
      dispatch(getLumityAdminCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getLumityAdminFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
