import { forwardRef, useEffect, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import InputForm from 'components/InputForm/InputForm';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';

import styles from './planInformationForm.module.less';

type PlanInformation = {
  setFormData: Function;
  setRequiredFieldError: Function;
  formData: any;
  form: any;
  isCloseConfirmed: boolean;
  isEditMode?: boolean;
};
const PlanInformationForm = forwardRef((props: PlanInformation, ref) => {
  const {
    setFormData,
    formData,
    form,
    isCloseConfirmed,
    setRequiredFieldError,
    isEditMode = false,
  } = props;

  const [noOfChars, setNoOfChars] = useState<number>(0);

  useEffect(() => {
    if (isCloseConfirmed) {
      setRequiredFieldError(false);
      form.resetFields();
    }
  }, [isCloseConfirmed, form, setRequiredFieldError]);

  useEffect(() => {
    if (formData && !isEmpty(formData.details) && !isCloseConfirmed) {
      setNoOfChars(formData.details.length);
    }
  }, [formData, setNoOfChars, isCloseConfirmed]);

  const onInputChange = (changedValues: any, allValues: any) => {
    form.setFieldsValue(allValues);
    setFormData({ ...formData, ...allValues });
  };

  const onInputDetailsChange = (data: React.ChangeEvent<HTMLInputElement>) => {
    setNoOfChars(data.target.value.length);
    form.setFieldsValue({ details: data.target.value });
  };

  return (
    <div className={styles.wrapperForm}>
      <div className={styles.header}>Plan Information</div>
      <InputForm
        form={form}
        onValuesChange={onInputChange}
        size={isEditMode ? 'small' : 'middle'}
      >
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="details"
              label="401(k) Match Policy"
              labelCol={{ span: 24 }}
            >
              <Input
                data-cy="details"
                maxLength={100}
                value={form.getFieldValue('details')}
                onChange={onInputDetailsChange}
              />
            </Form.Item>
            <span className={styles.charLimit}>
              Character Limit : {noOfChars}/100
            </span>
          </Col>
          <Col span={12}>
            <Form.Item
              name="annualMaxContribution"
              label="Annual Max Contribution"
              labelCol={{ span: 24 }}
            >
              <NumberFormatInput
                isBrokerScreen={true}
                thousandSeparator
                allowNegative={false}
                prefix="$"
                decimalSeparator="."
                fixedDecimalScale
                decimalScale={0}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="annualCatchupContribution"
              label="Annual Catchup Contribution 50 or Older"
              labelCol={{ span: 24 }}
            >
              <NumberFormatInput
                isBrokerScreen={true}
                thousandSeparator
                allowNegative={false}
                prefix="$"
                decimalSeparator="."
                fixedDecimalScale
                decimalScale={0}
              />
            </Form.Item>
          </Col>
          <Col span={12} />
        </Row>
      </InputForm>
    </div>
  );
});
PlanInformationForm.displayName = 'PlanInformationForm';
export default PlanInformationForm;
