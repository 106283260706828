import { benefitTypes } from 'modules/carriers/constants/carrierConstants';
import CarrierType from 'modules/carriers/enums/CarrierType';

export const isMDV = (params: any) => {
  if (params) {
    return (
      params.benefitCategory === benefitTypes['MEDICAL'].value ||
      params.benefitCategory === benefitTypes['DENTAL'].value ||
      params.benefitCategory === benefitTypes['VISION'].value
    );
  }
  return false;
};
export const getCarrierLibraryType = (
  employerId?: string,
  brokerId?: string
) => {
  if (employerId) {
    return CarrierType.EMPLOYER;
  } else if (brokerId) {
    return CarrierType.BROKER;
  } else {
    return CarrierType.MASTER;
  }
};

export const isRLS = (params: any) => {
  if (params) {
    return (
      params.benefitCategory === benefitTypes['RETIREMENT'].value ||
      params.benefitCategory === benefitTypes['LIFE'].value ||
      params.benefitCategory === benefitTypes['SAVING'].value
    );
  }
  return false;
};
