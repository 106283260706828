import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import ErrorDetails from 'model/ErrorDetails';
import { IndividualType } from 'constants/commonConstants';
import {
  fetchBrokerConsultantDeleteValidate,
  fetchEmployerConsultantDeleteValidate,
} from 'modules/admins/services/AdminConsultantValidateService';

export interface IGetAdminConsultantValidationPrams {
  adminType: any;
  orgId: any;
  individualId: any;
  employerId: any;

  consultantId?: any;
}

export interface IAdminConsultantValidate {
  consultantValidateObj: any | null;
  inProgress: boolean;
  error: ErrorDetails | null;
}
const initialState: IAdminConsultantValidate = {
  consultantValidateObj: null,
  inProgress: false,
  error: null,
};
const adminConsultantValidateSlice = createSlice({
  name: 'adminConsultantValidate',
  initialState: initialState,
  reducers: {
    consultantValidateStarted: (state) => {
      state.inProgress = true;
    },
    consultantValidateSuccess: (state, { payload }) => {
      state.inProgress = false;
      state.consultantValidateObj = payload;
    },
    consultantValidateError: (
      state,
      { payload }: { payload: ErrorDetails | null }
    ) => {
      state.inProgress = false;
      state.error = payload;
    },
    consultantValidateClear: (state) => {
      state.consultantValidateObj = null;
      state.error = null;
    },
  },
});

export const {
  consultantValidateStarted,
  consultantValidateSuccess,
  consultantValidateError,
  consultantValidateClear,
} = adminConsultantValidateSlice.actions;

export default adminConsultantValidateSlice.reducer;

export const getAdminConsultantValidation =
  ({
    adminType,
    orgId,
    individualId,
    employerId,
    consultantId,
  }: IGetAdminConsultantValidationPrams) =>
  async (dispatch: Dispatch) => {
    dispatch(consultantValidateStarted());
    try {
      let response;
      switch (adminType) {
        case IndividualType.ORGANIZATION_ADMIN: {
          response = await fetchBrokerConsultantDeleteValidate({
            orgId,
            individualId,
            consultantId,
          });
          break;
        }
        case IndividualType.ER_ADMIN: {
          response = await fetchEmployerConsultantDeleteValidate({
            orgId,
            individualId,
            employerId,
          });
          break;
        }
        default:
          response = {};
      }
      return dispatch(consultantValidateSuccess(response.data));
    } catch (err: any) {
      dispatch(consultantValidateError(err.response));
    }
  };
