/* eslint-disable no-unused-vars */
enum QuoteProcessStatus {
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  VALIDATING = 'VALIDATING',
  VALIDATED = 'VALIDATED',
  REVIEWED = 'REVIEWED',
  SAVED = 'SAVED',
  MANUAL = 'MANUAL',
  REJECTED = 'REJECTED',
}

export default QuoteProcessStatus;
