import React, { FC } from 'react';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Checkbox, Col, Row, Spin, Typography } from 'antd';

import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import styles from './MultiSelectScroll.module.less';

export type MultiScrollSelectOption = {
  [key: string]: ValueType;
};

type ValueType = {
  value: string;
  label: string;
  isChecked?: boolean;
};

type MultiSelectScrollProps = {
  selectOptionsList: MultiScrollSelectOption[];
  setSelectOptionsList: (updatedList: MultiScrollSelectOption[]) => void;
  isLoading?: boolean;
};

const MultiSelectItem: FC<{
  item: MultiScrollSelectOption;
  index: number;
  onChange: Function;
}> = ({ item: brokerDetails, index, onChange }) => {
  return (
    <Row key={index} className={styles.multiRow}>
      {Object.keys(brokerDetails).map((subKey, subIndex) => (
        <>
          {typeof brokerDetails[subKey]?.isChecked === 'boolean' ? (
            <Col span={14} key={subIndex} className={styles.leftAlignContent}>
              <Checkbox
                className={styles.textEllipsis}
                checked={Boolean(brokerDetails[subKey]?.isChecked)}
                onChange={(e) => {
                  onChange(e, subKey, index);
                }}
              >
                {brokerDetails[subKey]?.label}
              </Checkbox>{' '}
            </Col>
          ) : (
            <Col span={10} className={styles.leftAlignContent}>
              <i>
                <OverflowPopover maxWidth={250}>
                  <p className={`${styles.italicText} ${styles.textEllipsis}`}>
                    <Typography.Text
                      className={`${styles.italicText} ${styles.textEllipsis}`}
                      ellipsis
                    >
                      {brokerDetails[subKey]?.label}
                    </Typography.Text>
                  </p>
                </OverflowPopover>
              </i>
            </Col>
          )}
        </>
      ))}
    </Row>
  );
};

const MultiSelectScroll: FC<MultiSelectScrollProps> = ({
  selectOptionsList,
  setSelectOptionsList,
  isLoading = false,
}) => {
  const handleCheckBoxOnChange = (
    event: CheckboxChangeEvent,
    subKey: string,
    index: number
  ) => {
    if (subKey !== null) {
      const updatedList = [...selectOptionsList];
      updatedList.forEach((item, listIndex) => {
        if (listIndex === index && item[subKey]) {
          item[subKey].isChecked = event.target.checked;
        }
      });
      setSelectOptionsList(updatedList);
    }
  };

  return (
    <div className={styles.selectWrapperContainer}>
      {isLoading ? (
        <div className={styles.spinContainer}>
          <Spin />
        </div>
      ) : (
        <div
          className={`${styles.scrollContainer} ${
            selectOptionsList.length === 0 ? styles.flexCenterContainer : ''
          }`}
        >
          {selectOptionsList.length === 0 ? (
            <i>
              <p className={styles.italicText}>
                Select an Employer to view eligible broker admins
              </p>
            </i>
          ) : (
            selectOptionsList.map((item, currentIndex) => (
              <MultiSelectItem
                key={currentIndex}
                item={item}
                index={currentIndex}
                onChange={handleCheckBoxOnChange}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelectScroll;
