export const UPLOAD_PANEL_KEY = 'UPLOAD_PANEL_KEY';
export const PREVIEW_PANEL_KEY = 'PREVIEW_PANEL_KEY';
export const BASIC_INFO_TAB = 'BASIC_INFO_TAB';
export const DEPENDENTS_TAB = 'DEPENDENTS_TAB';
export const ID_CARD_TAB = 'ID_CARDS_TAB';
export const EMPLOYEE_DATA_SOURCE_TAGS = {
  USER_AMENDED: 'USER AMENDED',
  SELF_DECLARED: 'SELF DECLARED',
} as const;

export const IMPORT_FILE_TYPE_ERROR_MSG = `Invalid file type. Files must be in .xlsx format.`;
export const IMPORT_FILE_SIZE_ERROR_MSG = `The submitted file exceeds the file size limit of 100 MB. Please confirm that you are uploading the correct file or reduce the file’s size`;
export const IMPORT_COMMON_ERROR_MSG = `Looks like you have uploaded a wrong file. Please use the template file given to update the information successfully.`;
