import { useCallback, useRef, useState } from 'react';
import { Radio, Space } from 'antd';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import ClonePlanDropDown from 'modules/renewals/components/ClonePlanDropDown/ClonePlanDropDown';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { PLAN_CLONE_RADIO_OPTIONS } from 'modules/renewals/constants/planTableConstants';

import styles from './addPlanConfirmModal.module.less';

interface AddPlanConfirmModalProps {
  setVisible: Function;
  visible: boolean;
  addedPlans: any[];
  addPlanButtonRef: any;
  setUpdateData?: Function;
  mapDropdownSelectedValues?: any[];
  mapDropDownDataOnChange?: Function;
}

export function AddPlanConfirmModal({
  visible,
  setVisible,
  addedPlans,
  addPlanButtonRef,
  setUpdateData,
  mapDropdownSelectedValues = [],
  mapDropDownDataOnChange,
}: AddPlanConfirmModalProps) {
  const selectInput = useRef<any>(null);
  const [selectedValueType, setSelectedValueType] = useState<string>(
    PLAN_CLONE_RADIO_OPTIONS.MANUAL
  );
  const [selectedDropdownPlans, setSelectedDropdownPlans] = useState<string[]>(
    []
  );

  const handleCancel = () => {
    setSelectedDropdownPlans([]);
    setSelectedValueType(PLAN_CLONE_RADIO_OPTIONS.MANUAL);
    selectInput?.current.reset();
    setVisible(false);
  };

  const handleRadioChange = (e: any) => {
    setSelectedDropdownPlans([]);
    selectInput?.current.reset();
    setSelectedValueType(e.target.value);
  };

  const handleConfirm = useCallback(() => {
    if (selectedValueType === PLAN_CLONE_RADIO_OPTIONS.MANUAL) {
      if (addPlanButtonRef.current) {
        addPlanButtonRef.current.click();
      }
      handleCancel();
    } else if (
      selectedValueType === PLAN_CLONE_RADIO_OPTIONS.CLONE &&
      !isEmpty(selectedDropdownPlans) &&
      !isEmpty(addedPlans)
    ) {
      const clonedPlans = selectedDropdownPlans
        .map((planId) => {
          const foundPlan = addedPlans?.find(
            (plan) => planId === plan?.referenceId || planId === plan?.keyRefId
          );

          if (foundPlan) {
            return {
              ...foundPlan,
              name: `Clone ${foundPlan.name}`,
              keyRefId: uuidv4() + 'existingOfferPlan',
              referenceId: uuidv4(),
              attachedDocuments: [],
            };
          }
          return null;
        })
        .filter(Boolean); // Remove any null values from the array

      if (mapDropDownDataOnChange) {
        // Returning Undefined Do Add default values as - in the mapped plan drop down
        const updatedMapDropdownSelectedValues = selectedDropdownPlans?.map(
          () => ''
        );
        mapDropDownDataOnChange([
          ...mapDropdownSelectedValues,
          ...updatedMapDropdownSelectedValues,
        ]);
      }

      if (setUpdateData) {
        setUpdateData((prevData: any) => [...prevData, ...clonedPlans]);
      }
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValueType, selectedDropdownPlans, addedPlans]);

  const dropdownOptions =
    addedPlans?.map((plan) => ({
      label: plan?.name,
      value: plan?.keyRefId ?? plan?.referenceId,
    })) || [];

  return (
    <ConfirmationDialog
      title="Add Plan"
      confirmText="Add Plan"
      cancelText="Cancel"
      closeModal={() => handleCancel()}
      visible={visible}
      onConfirm={() => handleConfirm()}
      onCancel={() => handleCancel()}
      buttonsExpanded={true}
      isCancelLink={true}
      disableConfirmButton={
        selectedValueType === PLAN_CLONE_RADIO_OPTIONS.CLONE &&
        isEmpty(selectedDropdownPlans)
      }
    >
      <p>
        You can add a new plan manually or clone an existing plan within this
        offer.
      </p>
      <Radio.Group
        value={selectedValueType}
        onChange={handleRadioChange}
        className={styles.radioGroupAddPlan}
      >
        <Space direction="vertical" size={20}>
          <Radio value={PLAN_CLONE_RADIO_OPTIONS.MANUAL}>
            <b>Add New Plan Manually</b>
          </Radio>

          <Radio value={PLAN_CLONE_RADIO_OPTIONS.CLONE}>
            <b>Clone Offer Plans</b>{' '}
          </Radio>
        </Space>
      </Radio.Group>
      <div className={styles.multiselectWrapper}>
        <ClonePlanDropDown
          ref={selectInput}
          selectOptions={dropdownOptions}
          dropdownPlansSelected={selectedDropdownPlans}
          changeDropdownPlansSelected={setSelectedDropdownPlans}
          disabled={selectedValueType === PLAN_CLONE_RADIO_OPTIONS.MANUAL}
        />
      </div>
    </ConfirmationDialog>
  );
}
