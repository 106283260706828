import { FC } from 'react';

import styles from './countLabel.module.less';

type CountLabelProps = {
  count: number;
  label: string;
};

const CountLabel: FC<CountLabelProps> = (props: CountLabelProps) => {
  const { count, label } = props;

  return (
    <div className={styles.countLabelWrapper}>
      <span
        className={count > 1000 ? styles.countBadgeSmall : styles.countBadgeLg}
      >
        {count}
      </span>
      <span className={styles.badgeText}>{label} Selected</span>
    </div>
  );
};

export default CountLabel;
