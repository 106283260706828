import {
  ChangeEvent,
  useState,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { Menu, Row } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';
import debounce from 'lodash/debounce';

import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import DataTable from 'components/DataTable/DataTable';
import { DataColumn } from 'components/DataTable/DataColumn';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import SearchBar from 'components/SearchBar/SearchBar';

import TablePagination from 'model/TablePagination';
import {
  DEBOUNCE_WAIT_TIME_SEARCH,
  convertPaginationConfig,
} from 'util/commonUtil';

import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import CarrierContactsCreateModal from 'modules/carriers/components/CarrierContactCreateModal/CarrierContactCreateModal';
import { buildInitials } from 'util/stringUtil';
import CarrierContactModal from 'modules/carriers/components/CarrierContactModal/CarrierContactModal';
import {
  useDeleteCarrierContactByCarrierContactIdMutation,
  useLazyGetCarrierContactsListByBrokerIdQuery,
} from 'modules/carriers/slices/carrierContactSlice';
import { CarrierContactDetailTypes } from 'model/CarrierContact';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import styles from './carrierContactsList.module.less';

type CarrierContactsListProps = {};

const CarrierContactsList = forwardRef(
  (props: CarrierContactsListProps, ref: any) => {
    const [searchText, setSearchText] = useState<string>('');

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
      sorterInfo: {
        columnKey: 'fullName',
        field: 'fullName',
        order: 'ascend',
      },
      paginationIndex: 1,
      filterInfo: {
        limit: 10,
        offset: 0,
        searchText: searchText,
      },
      filters: {},
    });

    const [selectedCarrierContactId, setSelectedCarrierContactId] =
      useState<string>('');

    const debounceLoadData = useMemo(
      () =>
        debounce(
          (config) => setPaginationConfig(config),
          DEBOUNCE_WAIT_TIME_SEARCH
        ),
      []
    );

    const [isViewContactModalOpen, setIsViewContactModalOpen] = useState(false);
    const [isDeleteContactModalOpen, setIsDeleteContactModalOpen] =
      useState(false);

    const { brokerId } = useParams();
    const [queryParameters] = useSearchParams();

    const [
      getCarrierContactsList,
      { data: carrierContactsData, isLoading: isCarrierContactsListLoading },
    ] = useLazyGetCarrierContactsListByBrokerIdQuery();

    const isSetSelectCarrierContact = Boolean(
      queryParameters.get('carrier-contacts')
    );

    useEffect(() => {
      // if the query parameter is set, open the modal
      const carrierContactId = queryParameters.get('edit-id');
      if (isSetSelectCarrierContact && carrierContactId) {
        setSelectedCarrierContactId(carrierContactId);
        setIsModalOpen(true);
      }
      // eslint-disable-next-line
    }, [queryParameters]);

    useEffect(() => {
      getCarrierContactsList({
        brokerId: brokerId!,
        page: paginationConfig?.paginationIndex,
        size: paginationConfig?.filterInfo.limit,
        sort: convertPaginationConfig(paginationConfig).sort,
        searchKey: paginationConfig?.filterInfo?.searchText,
      });
      // eslint-disable-next-line
    }, [brokerId, paginationConfig]);

    const fetchData = () => {
      getCarrierContactsList({
        brokerId: brokerId!,
        page: paginationConfig?.paginationIndex,
        size: paginationConfig?.filterInfo.limit,
        sort: '',
        searchKey: searchText,
      });
    };

    useImperativeHandle(ref, () => ({
      fetchData: fetchData,
    }));

    const [deleteCarrierContact] =
      useDeleteCarrierContactByCarrierContactIdMutation();

    const handleDelete = async () => {
      if (selectedCarrierContactId) {
        await deleteCarrierContact({
          carrierContactId: selectedCarrierContactId,
        });
        fetchData();
      }
      setIsDeleteContactModalOpen(false);
    };

    const getData = (filters: TablePagination) => {
      if (filters.sorterInfo) {
        const paginationConfigData = {
          sorterInfo: filters.sorterInfo,
          paginationIndex: filters.paginationIndex,
          filterInfo: {
            limit: filters.filterInfo.limit,
            offset: filters.filterInfo.offset,
            searchText: searchText,
          },
          filters: filters.filters,
        };
        setPaginationConfig(paginationConfigData);
      } else {
        const paginationConfigData = {
          sorterInfo: {
            columnKey: 'fullName',
            field: 'fullName',
            order: 'ascend',
          },
          paginationIndex: filters.paginationIndex,
          filterInfo: {
            limit: filters.filterInfo.limit,
            offset: filters.filterInfo.offset,
            searchText: searchText,
          },
          filters: filters.filters,
        } as TablePagination;
        setPaginationConfig(paginationConfigData);
      }
    };

    const { metadata } = carrierContactsData || {};
    const total = metadata ? metadata.total : '0';
    const { filterInfo } = paginationConfig;
    const { limit } = filterInfo || {};

    const carrierActions = (carrierContactsData: CarrierContactDetailTypes) => {
      return (
        <Menu>
          <Menu.Item
            key="View"
            onClick={() => {
              setSelectedCarrierContactId(carrierContactsData?.id!);
              setIsViewContactModalOpen(true);
            }}
          >
            View Contact
          </Menu.Item>
          <Menu.Item
            key="Edit"
            onClick={() => {
              setSelectedCarrierContactId(carrierContactsData?.id!);
              setIsModalOpen(true);
            }}
          >
            Edit Contact
          </Menu.Item>
          <Menu.Item
            key="Delete"
            onClick={() => {
              setSelectedCarrierContactId(carrierContactsData?.id!);
              setIsDeleteContactModalOpen(true);
            }}
          >
            Remove Contact
          </Menu.Item>
        </Menu>
      );
    };

    const carrierContactsTableColumns: DataColumn[] = [
      {
        title: 'CARRIER CONTACT',
        dataIndex: 'fullName',
        key: 'fullName',
        width: '40%',
        defaultSortOrder: 'ascend',
        ellipsis: {
          showTitle: false,
        },
        sorter: (a: any, b: any) => 0, // this sort is done by backend.
        render: (_, record: CarrierContactDetailTypes) => {
          const { firstName, lastName, fullName, id } = record;
          return (
            <span
              className={styles.nameColStyle}
              onClick={() => {
                setSelectedCarrierContactId(id!);
                setIsViewContactModalOpen(true);
              }}
            >
              <ProfileAvatar
                content={buildInitials(firstName || '', lastName || '')}
              />
              <span className={styles.carrierName}>
                <OverflowPopover popoverContent={fullName} maxWidth={200}>
                  {fullName}
                </OverflowPopover>
              </span>
            </span>
          );
        },
      },
      {
        title: 'ASSOCIATED CARRIER',
        dataIndex: 'associatedCarrierName',
        key: 'associatedCarrierName',
        width: '40%',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => 0,
        render: (colData) => {
          return (
            <span>
              {colData ? (
                <OverflowPopover popoverContent={colData} maxWidth={230}>
                  {colData}
                </OverflowPopover>
              ) : (
                '-'
              )}
            </span>
          );
        },
      },
      {
        title: 'TITLE',
        dataIndex: 'title',
        key: 'title',
        width: '30%',
        defaultSortOrder: 'ascend',
        render: (_, record: CarrierContactDetailTypes) => {
          const { title } = record;
          return (
            <span>
              {title ? (
                <OverflowPopover popoverContent={title} maxWidth={230}>
                  {title}
                </OverflowPopover>
              ) : (
                '-'
              )}
            </span>
          );
        },
      },
      {
        title: 'EMAIL',
        dataIndex: 'email',
        key: 'email',
        width: '40%',
        defaultSortOrder: 'ascend',
        render: (colData) => {
          return (
            <span>
              <OverflowPopover popoverContent={colData} maxWidth={230}>
                {colData}
              </OverflowPopover>
            </span>
          );
        },
      },
      {
        title: 'ACTIONS',
        dataIndex: 'actions',
        key: 'actions',
        width: '20%',
        align: 'left',
        render: (_, item: CarrierContactDetailTypes) => {
          return <SelectDropdown overlay={carrierActions(item)} />;
        },
      },
    ];

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
      const _searchText = e.target.value.trim();
      setSearchText(_searchText);
      const pageInfo = {
        ...paginationConfig,
        paginationIndex: 1,
        filterInfo: { ...paginationConfig.filterInfo, searchText: _searchText },
      };
      debounceLoadData(pageInfo);
    };

    return (
      <div className={styles.carrierContactTableWrapper}>
        <br />
        <Row className={styles.filterContainer}>
          <SearchBar
            placeholder="Search Carrier Contacts"
            onChange={handleSearch}
          />
        </Row>
        <br />
        <DataTable
          currentIndex={paginationConfig.paginationIndex}
          data={carrierContactsData?.content || []}
          columns={carrierContactsTableColumns}
          pagination={true}
          getData={getData}
          loading={isCarrierContactsListLoading}
          total={String(total)}
          pageSize={limit}
        />
        <CarrierContactsCreateModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          carrierContactId={selectedCarrierContactId}
          fetchData={fetchData}
          navigateFromSomewhereElse={isSetSelectCarrierContact}
        />

        {isViewContactModalOpen && (
          <CarrierContactModal
            isModalOpen={isViewContactModalOpen}
            setIsModalOpen={setIsViewContactModalOpen}
            carrierContactId={selectedCarrierContactId}
            fetchData={fetchData}
          />
        )}

        {isDeleteContactModalOpen && (
          <ConfirmationDialog
            title="Remove Carrier Contact"
            confirmText="Yes - Remove Carrier Contact"
            cancelText="Cancel"
            closeModal={() => setIsDeleteContactModalOpen(false)}
            onConfirm={handleDelete}
            visible={isDeleteContactModalOpen}
          >
            <p className={styles.warningConfirmation}>
              Are you sure you want to remove this carrier contact? This
              <br /> action cannot be un-done.
            </p>
          </ConfirmationDialog>
        )}
      </div>
    );
  }
);

CarrierContactsList.displayName = 'CarrierContactsList';
export default CarrierContactsList;
