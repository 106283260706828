import { useState } from 'react';
import { Alert, Checkbox } from 'antd';
import { ReviewModalState } from 'modules/renewals/types/renewalsTypes';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import styles from './quoteReviewFinalizeModal.module.less';

type Props = {
  onClose: () => void;
  isLoading: boolean;
  reviewModalState: ReviewModalState;
  onConfirm: any;
  content?: { [key: string]: string };
  confirmText?: string;
};

const QuoteReviewModal = ({
  isLoading,
  reviewModalState,
  onClose,
  onConfirm,
  content,
  confirmText = 'Continue',
}: Props) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  return (
    <ConfirmationDialog
      confirmText={confirmText}
      isCancelLink
      title={<div className={styles.reviewModalWrapper}>{content?.title}</div>}
      cancelText="Cancel"
      closeModal={() => {
        onClose();
        setIsCheckboxChecked(false);
      }}
      confirmLoading={isLoading}
      onConfirm={() => {
        onConfirm();
        setIsCheckboxChecked(false);
      }}
      disableConfirmButton={Boolean(content?.warning) && !isCheckboxChecked}
      visible={reviewModalState.isOpen}
      destroyOnClose
    >
      <div className={styles.reviewModalWrapper}>
        {content?.description}
        {content?.warning && (
          <Alert
            className={styles.confirmationMessage}
            type="warning"
            message={
              <div className={styles.alertContent}>
                <Checkbox
                  className={styles.checkbox}
                  checked={isCheckboxChecked}
                  onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                />
                {content?.warning}
              </div>
            }
          />
        )}
      </div>
    </ConfirmationDialog>
  );
};

export default QuoteReviewModal;
