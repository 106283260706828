import { FC } from 'react';
import { Modal } from 'antd';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';

import {
  DLP_VERIFY_TOPIC,
  DLP_VERIFY_DESCRIPTION_1,
  DLP_VERIFY_DESCRIPTION_2,
  DLP_VERIFY_DESCRIPTION_3,
  DLP_VERIFY_DESCRIPTION_SPAN,
} from 'constants/dataLossPreventionConstants';
import styles from './dlpVerifyModal.module.less';
type DLPVerifyModalProps = {
  visible: boolean;
  dlpBulletPoints?: string[];
  onCancel: () => void;
  clearDlpBulletPoints: () => void;
};

const DLPVerifyModal: FC<DLPVerifyModalProps> = ({
  visible,
  dlpBulletPoints,
  onCancel,
  clearDlpBulletPoints,
}) => {
  const handleCloseModal = () => {
    clearDlpBulletPoints();
    onCancel();
  };
  return (
    <Modal
      centered
      visible={visible}
      width={600}
      closeIcon={<></>}
      footer={false}
      className={styles.dlpVerifyModal}
      destroyOnClose={true}
      onCancel={handleCloseModal}
    >
      <div className={styles.dlpVerifyModalWrapper}>
        <div className={styles.title}>{DLP_VERIFY_TOPIC}</div>

        <div className={styles.description}>{DLP_VERIFY_DESCRIPTION_1}</div>
        <br></br>
        <ul>
          {dlpBulletPoints?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <div className={styles.description}>
          {DLP_VERIFY_DESCRIPTION_2}
          <span className={styles.dlpSpan}>{DLP_VERIFY_DESCRIPTION_SPAN}</span>
          {DLP_VERIFY_DESCRIPTION_3}
        </div>

        <div className={styles.footer}>
          <PageActionButton
            className={styles.saveBtn}
            type="primary"
            onClick={handleCloseModal}
          >
            Back to Message
          </PageActionButton>
        </div>
      </div>
    </Modal>
  );
};

export default DLPVerifyModal;
