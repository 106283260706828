import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Menu } from 'antd';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import DataTable from 'components/DataTable/DataTable';
import { DataColumn } from 'components/DataTable/DataColumn';
import TablePagination from 'model/TablePagination';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import OutlinedText from 'components/OutlinedText/OutlinedText';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import PlanYear from 'model/PlanYear';
import {
  getPlanYears,
  getPlanYearsInProgress,
} from 'modules/employers/slices/employerSlice';
import {
  deletePlanYear,
  deletePlanYearCompleted,
  planYearCreateSucceeded,
  planYearUpdateSucceeded,
  setCurrentPlanYear,
  setCurrentPlanYearCompleted,
  deletePlanYearFailed,
  clearPlanYearStatus,
} from 'modules/employers/slices/employerPlanYearSlice';
import AddPlanYear from 'modules/employers/components/AddPlanYear/AddPlanYear';
import CloneStepController from 'modules/employers/components/AFPCloneDBGAndPlans/CloneStepController';
import * as BenguideService from 'modules/employers/services/BenguideService';
import {
  EMPLOYER_MEMBER_RESTRICTED,
  exitWithoutSavingMsg,
  successfullyClonedMessage,
} from 'constants/commonConstants';
import { PLAN_YEAR_CREATE_AFP_SUCCESS_MESSAGE } from 'modules/employers/constants/afpClonePlansConstants';
import { getInvitedTimeWithMonthName } from 'util/dateUtil';
import {
  clearClonePlanBG,
  clearSelectedPlanYear,
  setSelectedPlanYear,
} from 'modules/employers/slices/planBGCloneSlice';

import { getSubtypeAvailability } from 'modules/plans/utils';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import styles from './planYearList.module.less';

const CLONE_ACTION_KEY: string = 'clone';
const EDIT_ACTION_KEY: string = 'edit';
const DELETE_ACTION_KEY: string = 'delete';
const MAKE_CURRENT_YEAR_ACTION_KEY: string = 'makeCurrentYear';

type PlanYearListProps = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  setIsPlanYearDeleted: (isDeleted: boolean) => void;
};

const onActionMenuClick = (e: any) => {
  console.log('selected key ' + e.key); // TODO : Integration actions
};

const PlanYearList: FC<PlanYearListProps> = (props: PlanYearListProps) => {
  const { isModalOpen, setIsModalOpen, setIsPlanYearDeleted } = props;
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {},
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: '',
    },
    filters: {},
  });
  const [form] = Form.useForm();
  const location: any = useLocation();
  const navigate = useNavigate();
  const [planYear, setPlanYear] = useState<PlanYear | null>(null);

  const [visibleClonedSuccessModal, setVisibleClonedSuccessModal] = useState<
    boolean | undefined
  >(false);
  const [visiblePlanCreatedPopUp, setVisiblePlanCreatedPopUp] = useState<
    boolean | undefined
  >(false);
  const [isClonePlansModalVisible, setClonePlansModalVisible] =
    useState<boolean>(false);
  const [visibleConfirmationDelete, setVisibleConfirmationDelete] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [isBenGuideFound, setIsBenGuideFound] = useState<boolean>(false);
  const [visibleCloneCloseConfirmation, setVisibleCloneCloseConfirmation] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { planYearsList, requestType, inProgress } = useAppSelector(
    (state) => state.employer.employer
  );
  const planYearStatus: any = useAppSelector(
    (state) => state.employer.employerPlanYear
  );
  const { brokerId, employerId } = useNavContext();
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  useEffect(() => {
    if (brokerId && employerId) {
      dispatch(getPlanYears(employerId));

      if (planYearStatus.requestType === planYearCreateSucceeded.type) {
        setAlertVisible(true);
        setAlertMessage({
          type: 'success',
          message: 'Plan Year has been successfully added',
        });
      } else if (planYearStatus.requestType === planYearUpdateSucceeded.type) {
        setAlertVisible(true);
        setAlertMessage({
          type: 'success',
          message: 'Plan Year has been successfully edited',
        });
      }
    }
  }, [
    dispatch,
    paginationConfig,
    brokerId,
    employerId,
    planYearStatus.requestType,
  ]);

  useEffect(() => {
    if (
      location?.state &&
      location?.state?.afpPlanYearCreated &&
      planYearsList &&
      !planYearsList[0]?.current
    ) {
      setVisiblePlanCreatedPopUp(location?.state?.afpPlanYearCreated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.afpPlanYearCreated, planYearsList]);

  const getData = (filters: TablePagination) => {
    setPaginationConfig(filters);
  };
  const appBootInfo = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo
  );
  useEffect(() => {
    if (
      !planYearStatus.inProgress &&
      planYearStatus.requestType === deletePlanYearCompleted.type
    ) {
      setVisibleConfirmationDelete(false);
      if (brokerId && employerId) {
        dispatch(getPlanYears(employerId));
      }
      setAlertVisible(true);
      if (planYearStatus.error === null) {
        setAlertMessage({
          type: 'success',
          message: 'Plan Year has been removed.',
        });
      } else {
        setAlertMessage({
          type: 'error',
          message: 'Plan Year delete failed.',
        });
      }
      dispatch(clearPlanYearStatus());
    }
    if (
      !planYearStatus.inProgress &&
      planYearStatus.requestType === deletePlanYearFailed.type
    ) {
      setVisibleConfirmationDelete(false);
      if (brokerId && employerId) {
        dispatch(getPlanYears(employerId));
      }
      setAlertVisible(true);
      setAlertMessage({
        type: 'error',
        message: planYearStatus?.error?.data?.details?.msg,
      });
      dispatch(clearPlanYearStatus());
    }
  }, [
    dispatch,
    planYearStatus.inProgress,
    planYearStatus.requestType,
    planYearStatus.error,
    brokerId,
    employerId,
  ]);

  useEffect(() => {
    if (
      !planYearStatus.inProgress &&
      planYearStatus.requestType === setCurrentPlanYearCompleted.type
    ) {
      if (brokerId && employerId) {
        dispatch(getPlanYears(employerId));
      }
    }
  }, [
    dispatch,
    planYearStatus.inProgress,
    planYearStatus.requestType,
    brokerId,
    employerId,
  ]);

  useEffect(() => {
    visibleConfirmationDelete === false && setPlanYear(null);
  }, [visibleConfirmationDelete]);

  const closePlanYearModal = () => {
    form.resetFields();
    setIsModalOpen(false);
    setPlanYear(null);
    if (brokerId && employerId) {
      dispatch(getPlanYears(employerId));
    }
  };

  const closePlanYearCloneModal = async ({
    fromCancel,
  }: {
    fromCancel?: boolean;
  }) => {
    await setClonePlansModalVisible(false);
    dispatch(clearSelectedPlanYear());
    dispatch(clearClonePlanBG());
    navigate(location.pathname, { replace: true });
    if (brokerId && employerId) {
      dispatch(getPlanYears(employerId));
    }
    if (!fromCancel) {
      setVisibleClonedSuccessModal(true);
    }
  };

  const isLastPlanYear = (planYear: PlanYear) => {
    if (!isEmpty(planYearsList)) {
      const sortedPlanYears = sortBy(planYearsList, 'startDate');
      const lastPlanYear = last(sortedPlanYears);
      return isEqual(lastPlanYear, planYear);
    }
    return null;
  };

  type SecuredEditButtonProps = {
    selectedPlanYear: PlanYear;
  };
  const SecuredEditButton: FC<SecuredEditButtonProps> = ({
    selectedPlanYear,
  }) => {
    return usePermitByUserType(
      <Menu.Item
        key={EDIT_ACTION_KEY}
        onClick={() => handleEdit(selectedPlanYear)}
      >
        Edit
      </Menu.Item>,
      EMPLOYER_MEMBER_RESTRICTED
    );
  };

  const getActionMenu = (planYear: PlanYear) => {
    return (
      <Menu onClick={onActionMenuClick}>
        {!planYear.current && (
          <Menu.Item
            key={MAKE_CURRENT_YEAR_ACTION_KEY}
            onClick={() => handleMakeCurrentYear(planYear)}
          >
            Make Current Plan Year
          </Menu.Item>
        )}
        {/* REMOVED LOGIC => && planYear?.planYearSource !== null */}
        {!planYear?.current && (
          <Menu.Item
            key={CLONE_ACTION_KEY}
            onClick={() => toggleClonePlanModal(planYear)}
          >
            Clone Data from Current Plan Year
          </Menu.Item>
        )}
        <SecuredEditButton selectedPlanYear={planYear} />
        {!planYear.current && isLastPlanYear(planYear) && (
          <Menu.Item
            key={DELETE_ACTION_KEY}
            onClick={() => handleDelete(planYear)}
          >
            Remove
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const isActionsVisible = getSubtypeAvailability(
    EMPLOYER_MEMBER_RESTRICTED,
    appBootInfo
  );

  const convertPlanYearsForTableRender = (planYears: PlanYear[]): any[] => {
    return (
      planYears?.map((planYear) => ({
        name: planYear,
        startDate: getInvitedTimeWithMonthName(planYear.startDate),
        endDate: getInvitedTimeWithMonthName(planYear.endDate),
        actions: planYear,
      })) || []
    );
  };

  const handleEdit = (planYear: PlanYear) => {
    setPlanYear(planYear);
    setIsModalOpen(true);
  };

  const toggleConfirmationDeletePopup = () => {
    setVisibleConfirmationDelete(!visibleConfirmationDelete);
  };

  const toggleClonePlanModal = (planYear: PlanYear) => {
    if (visiblePlanCreatedPopUp) {
      togglePlanSuccessPopup();
    }
    dispatch(setSelectedPlanYear(planYear));
    setClonePlansModalVisible(true);
  };

  const togglePlanSuccessPopup = () => {
    navigate(location.pathname, { replace: true });
    setVisiblePlanCreatedPopUp(false);
  };

  const handleDelete = async (deletePlanYear: PlanYear) => {
    setPlanYear(deletePlanYear);
    try {
      const response = await BenguideService.isBenguideAssignedForPlanYear(
        deletePlanYear.id,
        deletePlanYear.employerId
      );
      if (response && response.data) {
        setIsBenGuideFound(true);
      } else {
        setIsBenGuideFound(false);
      }
    } catch (error) {
      setIsBenGuideFound(false);
    }
    toggleConfirmationDeletePopup();
  };

  const confirmDelete = () => {
    if (planYear) {
      dispatch(deletePlanYear(planYear));
      setIsPlanYearDeleted(true);
    }
  };

  const handleMakeCurrentYear = async (planYear: PlanYear) => {
    dispatch(setCurrentPlanYear(planYear));
    setAlertVisible(true);
    setAlertMessage({
      type: 'success',
      message: `${planYear.name} is now the Current Plan Year`,
    });
  };

  const closeAlert = () => {
    setAlertVisible(false);
    setAlertMessage({
      type: undefined,
      message: '',
    });
  };
  const dataColumns: DataColumn[] = isActionsVisible
    ? [
        {
          title: 'PLAN YEAR',
          dataIndex: 'name',
          key: 'name',
          align: 'left',
          render: (colData) => (
            <span className={styles.columnWrapper}>
              <b>{colData.name}</b>
              {colData.current && <OutlinedText text="CURRENT PLAN YEAR" />}
            </span>
          ),
        },
        {
          title: 'START DATE',
          dataIndex: 'startDate',
          key: 'startDate',
        },
        {
          title: 'END DATE',
          dataIndex: 'endDate',
          key: 'endDate',
        },
        {
          title: 'ACTIONS',
          dataIndex: 'actions',
          key: 'actions',
          align: 'center',
          render: (colData) => (
            <SelectDropdown overlay={getActionMenu(colData)} />
          ),
        },
      ]
    : [
        {
          title: 'PLAN YEAR',
          dataIndex: 'name',
          key: 'name',
          align: 'left',
          render: (colData) => (
            <span className={styles.columnWrapper}>
              <b>{colData.name}</b>
              {colData.current && <OutlinedText text="CURRENT PLAN YEAR" />}
            </span>
          ),
        },
        {
          title: 'START DATE',
          dataIndex: 'startDate',
          key: 'startDate',
        },
        {
          title: 'END DATE',
          dataIndex: 'endDate',
          key: 'endDate',
        },
      ];

  return (
    <>
      <ConfirmationDialog
        title="Successfully Cloned"
        confirmText="Close"
        zIndex={1099}
        closeModal={() => setVisibleClonedSuccessModal(false)}
        onConfirm={() => {
          setVisibleClonedSuccessModal(false);
        }}
        isCloseOnly
        visible={visibleClonedSuccessModal}
        centered
      >
        <p>{successfullyClonedMessage}</p>
      </ConfirmationDialog>

      <ConfirmationDialog
        title="Are you sure you want to close?"
        confirmText="Yes - close and do not save"
        cancelText="Cancel"
        zIndex={1099}
        closeModal={() => setVisibleCloneCloseConfirmation(false)}
        onConfirm={() => {
          setVisibleCloneCloseConfirmation(false);
          closePlanYearCloneModal({ fromCancel: true });
        }}
        visible={visibleCloneCloseConfirmation}
      >
        <p className={styles.warningConfirmation}>{exitWithoutSavingMsg}</p>
      </ConfirmationDialog>

      <div className={styles.planYearListWrapper}>
        {/* Display a Dialog once after a new plan is created! */}
        {visiblePlanCreatedPopUp && planYearsList[0] && (
          <ConfirmationDialog
            width={600}
            centered={true}
            title="Successfully Created New Plan Year"
            confirmText="Yes - Clone Additional Plans & Benefit Guides"
            closeModal={togglePlanSuccessPopup}
            onConfirm={() => toggleClonePlanModal(planYearsList[0])}
            visible={visiblePlanCreatedPopUp}
            cancelText="I'll do this later"
            recordName={planYearsList[0]?.name}
            isRecordApplicable
            confirmBtnFullWidth
            modalClassName={styles.modalContainer}
            closeBtnAdditionalStyling={styles.closeButton}
          >
            <div className={styles.dialogWrapper}>
              <p className={styles.successConfirmation}>
                The new Plan Year has been created with the finalized proposal
                plans. Additionally, you have the option to clone other benefit
                plans and benefits guides from the current Plan Year.
              </p>
              <p className={styles.successConfirmation}>
                Would you like to continue?
              </p>

              <FixedAlertMessage
                type="info"
                message={PLAN_YEAR_CREATE_AFP_SUCCESS_MESSAGE}
              />
            </div>
          </ConfirmationDialog>
        )}
        <div className={styles.alertWrapper}>
          {alertVisible && (
            <AlertMessage
              type={alertMessage.type}
              message={alertMessage.message}
              closeAlert={closeAlert}
            />
          )}
        </div>
        <DataTable
          data={convertPlanYearsForTableRender(planYearsList)}
          columns={dataColumns}
          pagination={true}
          getData={getData}
          total={!isEmpty(planYearsList) ? String(planYearsList.length) : '0'}
          loading={inProgress && requestType === getPlanYearsInProgress.type}
          pageSize={limit}
          clientSidePagination
        />

        {isBenGuideFound ? (
          <ConfirmationDialog
            title="Remove Plan Year"
            confirmText="Okay"
            closeModal={toggleConfirmationDeletePopup}
            onConfirm={toggleConfirmationDeletePopup}
            visible={visibleConfirmationDelete}
          >
            <p className={styles.warningConfirmation}>
              This Plan Year is currently in use by a Benefits Guide. Please
              remove it from all connected Guides to remove this Plan Year.
            </p>
          </ConfirmationDialog>
        ) : (
          <ConfirmationDialog
            title="Remove Plan Year"
            confirmText={`Yes - Remove ${
              planYear ? planYear.name : 'Plan Year'
            }`}
            cancelText="Cancel"
            closeModal={toggleConfirmationDeletePopup}
            onConfirm={confirmDelete}
            visible={visibleConfirmationDelete}
          >
            <p className={styles.warningConfirmation}>
              Are you sure you want to remove
            </p>
          </ConfirmationDialog>
        )}

        {/* ADD Or Delete Plans Modal */}
        <FullScreenModal
          visible={isModalOpen}
          onCancel={closePlanYearModal}
          footer={false}
          title={planYear ? 'Update Plan Year' : 'Add Plan Year'}
        >
          <AddPlanYear
            closeModal={closePlanYearModal}
            editPlanYear={planYear}
            form={form}
          />
        </FullScreenModal>

        {/* Clone Plans Modal */}

        <FullScreenModal
          visible={isClonePlansModalVisible}
          onCancel={() => setVisibleCloneCloseConfirmation(true)}
          footer={false}
          title="Clone Plans & Benefits Guides"
          destroyOnClose={true}
        >
          {/* Includes the steps in cloning a existing plan year */}
          <CloneStepController
            closeModal={() => closePlanYearCloneModal({ fromCancel: false })}
            onCancelModal={() => setVisibleCloneCloseConfirmation(true)}
            isClonePlansModalVisible={isClonePlansModalVisible}
          />
        </FullScreenModal>
      </div>
    </>
  );
};

export default PlanYearList;
