import { FC } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import isEmpty from 'lodash/isEmpty';
import iconCheck from 'assets/images/icon-check.svg';
import iconDash from 'assets/images/icon-dash.svg';
import PlanReference from 'model/PlanReference';
import PageSection from 'model/PageSection';
import styles from './benguidePlanCheck.module.less';

type BenguidePlanCheckProps = {
  sectionEnabled: boolean;
  planReferences: PlanReference[];
  pageSections: PageSection[];
  isPlan: boolean;
  pageName: string;
};

const BenguidePlanCheck: FC<BenguidePlanCheckProps> = (props) => {
  const { sectionEnabled, planReferences, pageSections, isPlan, pageName } =
    props;

  const getBenefitStyleClassName = (
    status: boolean,
    plans: PlanReference[] | PageSection[],
    isPlan: boolean
  ) => {
    if (status) {
      if (isPlan && isEmpty(plans)) {
        return styles.errorBenefit;
      } else {
        return '';
      }
    } else {
      return styles.disabledBenefit;
    }
  };

  const showToolTip =
    getBenefitStyleClassName(
      sectionEnabled,
      isPlan ? planReferences : pageSections,
      isPlan
    ) === styles.errorBenefit;

  const showDisableText =
    getBenefitStyleClassName(
      sectionEnabled,
      isPlan ? planReferences : pageSections,
      isPlan
    ) === styles.disabledBenefit;

  const benefitContent = () => {
    // ToDo This will change after plan data validation applies.
    return (
      <div>
        <p>
          {!showDisableText
            ? 'There are no plans in this section. Either remove this from Basic Info section or add plans.'
            : 'To add this content please go back to BasicInfo and check the boxes for items you want to include.'}
        </p>
      </div>
    );
  };

  const benefitLength = isPlan ? planReferences.length : pageSections.length;

  return (
    <div>
      {sectionEnabled ? (
        <img src={iconCheck} className={styles.icon} alt="iconCheck" />
      ) : (
        <img src={iconDash} className={styles.icon} alt="iconDash" />
      )}
      <span
        className={getBenefitStyleClassName(
          sectionEnabled,
          isPlan ? planReferences : pageSections,
          isPlan
        )}
      >
        {showDisableText && isPlan ? (
          <Popover
            content={benefitContent}
            placement="right"
            overlayClassName={`popoverInner ${styles.planCheckWrapper}`}
          >
            {pageName} {isPlan ? `(${benefitLength})` : ''}
          </Popover>
        ) : (
          <>
            {pageName} {isPlan ? `(${benefitLength})` : ''}
          </>
        )}
      </span>
      {showToolTip && isPlan && (
        <Popover
          content={benefitContent}
          placement="right"
          overlayClassName={`popoverInner ${styles.planCheckWrapper}`}
        >
          <ExclamationCircleOutlined className={styles.benguidePlanYear} />
        </Popover>
      )}
    </div>
  );
};

export default BenguidePlanCheck;
