import { useCallback, useEffect, useState } from 'react';
import {
  Breadcrumb,
  Col,
  Menu,
  notification,
  Row,
  Select,
  Table,
  TablePaginationConfig,
} from 'antd';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import { get, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import PlanYearDropdown from 'components/PlanYearDropdown/PlanYearDropdown';
import SearchBar from 'components/SearchBar/SearchBar';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import { useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import PlanYear from 'model/PlanYear';
import {
  clearDocumentState,
  createFolder,
  deleteDocument,
  downloadDocument,
  downloadFolder,
  fetchDocuments,
  getDocumentAvailability,
  renameFolder,
  uploadDocument,
} from 'modules/documents/slices/doucmentSlice';
import { DataColumn } from 'components/DataTable/DataColumn';
import IconFolder from 'assets/documents/icon_file_Folder.svg';
import IconDocument from 'assets/documents/icon_file_Document.svg';
import IconDownload from 'assets/images/icon-download.svg';

import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import FolderManagementModal from 'modules/documents/components/CreateFolderModal/FolderManagementModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import AlertMessage from 'components/Alert/AlertMessage';
import { formatDate } from 'util/dateUtil';
import {
  getFileIconBtExtension,
  getReadableFileSizeString,
} from 'modules/documents/utils/DocumentUtils';
import selectIcon from 'assets/images/icon-caret-down.svg';

import tableStyles from 'components/DataTable/datatable.module.less';
import PaginationNav from 'components/TableCustomPagination/TableCustomPagination';

import { loginTypes } from 'constants/authConstants';
import styles from './documents.module.less';

const Documents = () => {
  const documents = useAppSelector((state) => state.documents) as any;
  const { data, inProgress, uploadError } = documents;
  const dispatch = useDispatch();
  const appBootupInfo = useAppSelector(
    (state) => state?.auth?.auth?.appBootupInfo
  );

  const { employerId, employer } = useNavContext();
  const [selectedPlanYear, setSelectedPlanYear] = useState<PlanYear>({
    id: '',
    benefitGroups: [],
    employerId: employerId || '',
    startDate: '',
    endDate: '',
    current: false,
    name: '',
    next: false,
    previous: false,
  });
  const [searchText, setSearchText] = useState<string>('');
  const [isFolderCreateModalOpen, setFolderCreateModalOpen] =
    useState<boolean>(false);
  const [isFolderRenameModalOpen, setFolderRenameModalOpen] =
    useState<boolean>(false);
  const { name } = employer || {};
  const [_selectedParentId, setSelectedPatentId] = useState<any>(null);
  const selectedParentId = isEmpty(_selectedParentId)
    ? data?.parentDocument?.documentId
    : _selectedParentId;

  const [selectedDocumentForManagement, setSelectedDocumentForManagement] =
    useState<any | null>(null);
  const [isDeleteConfirmationOpen, setDeleteConfirmation] =
    useState<boolean>(false);
  const [isFileExistsModalOpen, setFileExistsModalOpen] =
    useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [invalidFileSize, setInvalidFileSize] = useState<boolean>(false);
  const [invalidFileType, setInvalidFileType] = useState<boolean>(false);
  const [uploadFailed, setUploadFailed] = useState<boolean>(false);
  const [downloadFailed, setDownloadFailed] = useState<boolean>(false);
  const [isDocumentSuccessfullyUpdated, setDocumentSucessfullyUpdated] =
    useState<boolean>(false);
  const [isFolderSuccessfullyUpdated, setFolderSucessfullyUpdated] =
    useState<boolean>(false);
  const [isSuccessfullyDeleted, setSuccessfullyDeleted] =
    useState<boolean>(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: 10,
    itemRender: PaginationNav,
    showTotal: (total, range) => (
      <div className={styles.totalCount}>
        {range[0]}-{range[1]} of {total}
      </div>
    ),
  });

  const parentDocumentId = documents?.data?.parentDocument?.documentId;

  const refreshDocumentList = useCallback(() => {
    if (employerId && selectedPlanYear.id && selectedParentId) {
      dispatch(
        fetchDocuments(
          employerId,
          selectedPlanYear.id,
          selectedParentId,
          searchText
        )
      );
    }
    // No need to render when changing  selectedPlanYear.id here
    // eslint-disable-next-line
  }, [dispatch, employerId, searchText, selectedParentId]);

  useEffect(() => {
    setSelectedPatentId('');
  }, [selectedPlanYear.id]);

  useEffect(() => {
    setSelectedPatentId(data?.parentDocument?.documentId);
  }, [data?.parentDocument?.documentId]);

  useEffect(() => {
    refreshDocumentList();
  }, [employerId, dispatch, refreshDocumentList]);

  useEffect(() => {
    if (uploadError) {
      setUploadFailed(true);
    }
  }, [uploadError]);

  useEffect(() => {
    return () => {
      dispatch(clearDocumentState());
    };
  }, [dispatch]);

  const onChangePlanYear = (value: PlanYear) => {
    if (employerId) {
      dispatch(fetchDocuments(employerId, value.id, null, searchText));
    }
    setSelectedPlanYear(value);
  };

  const searchDocument = ({ target }: any) => {
    setSearchText(target.value);
  };

  const convertToTableData = (data: any) => {
    return (
      (data.content || []).map((doc: any) => {
        return {
          ...doc,
          name: doc.name,
          size: doc.file
            ? getReadableFileSizeString(doc.fileSize)
            : `${doc.fileCount} Files`,
          updatedTs: formatDate(doc.updatedTs, 'MMM DD, YYYY'),
          updatedBy: doc.file || doc.editable ? doc.updatedBy : '-',
        };
      }) || []
    );
  };

  const rowClicked = (document: any) => {
    if (document.documentId && employerId && !document.file) {
      setSelectedPatentId(document.documentId);
      refreshDocumentList();
    }
  };

  const downloadFile = (documentId: string) => {
    dispatch(
      downloadDocument(documentId, () => {
        setDownloadFailed(true);
      })
    );
  };

  const deleteFile = (documentId: string) => {
    setDeleteConfirmation(false);
    setSuccessfullyDeleted(true);
    dispatch(deleteDocument(documentId, refreshDocumentList));
  };

  const renameFile = (documentId: string) => {
    setFolderRenameModalOpen(true);
  };

  const createFolderInCurrentFolder = (folderName: string) => {
    if (employerId) {
      dispatch(
        createFolder(employerId, parentDocumentId, folderName, () => {
          refreshDocumentList();
          setFolderCreateModalOpen(false);
          setFolderSucessfullyUpdated(true);
        })
      );
    }
  };

  const renameSelectedFolder = (folderName: string) => {
    if (employerId) {
      dispatch(
        renameFolder(
          selectedDocumentForManagement.documentId,
          selectedParentId,
          folderName,
          () => {
            refreshDocumentList();
            setFolderRenameModalOpen(false);
          }
        )
      );
    }
  };

  const showFolderDownloadNotification = () => {
    notification.open({
      message: ' Download Started',
      description: ` Please wait while documents are being downloaded. This process may take a few minutes.`,
      placement: 'bottomLeft',
      duration: 3,
      className: styles.notification,
      closeIcon: <></>,
      key: 'folderDownload',
      icon: (
        <img className={styles.folderDownloadIcon} src={IconDownload} alt="" />
      ),
    });
  };

  const canDeleteDocument = () => {
    const allowedRoles = [loginTypes.platform, loginTypes.bokerAdmin];
    return allowedRoles.includes(appBootupInfo?.type!);
  };

  const viewActionMenu = (document: any) => {
    setSelectedPatentId(document.documentId);
    refreshDocumentList();
  };

  const deleteActionMenu = (document: any) => {
    setSelectedDocumentForManagement(document);
    setDeleteConfirmation(true);
    setSuccessfullyDeleted(false);
  };

  const restrictedActionMenu = (document: any) => {
    return (
      <SelectDropdown
        className={styles.actionDropdown}
        overlay={
          <Menu>
            {!document.file && (
              <Menu.Item onClick={() => viewActionMenu(document)}>
                View
              </Menu.Item>
            )}

            {document.file && (
              <>
                <Menu.Item
                  onClick={() => {
                    setSelectedDocumentForManagement(document);
                    downloadFile(document.documentId);
                  }}
                >
                  Download
                </Menu.Item>
              </>
            )}

            {document.fileCount > 0 && (
              <Menu.Item
                onClick={() => {
                  employerId &&
                    dispatch(
                      downloadFolder(
                        employerId,
                        document.documentId,
                        showFolderDownloadNotification
                      )
                    );
                }}
              >
                Download
              </Menu.Item>
            )}
          </Menu>
        }
      >
        Select
      </SelectDropdown>
    );
  };

  const getActions = (document: any) => {
    return (
      <SelectDropdown
        className={styles.actionDropdown}
        overlay={
          <Menu>
            {!document.file && (
              <Menu.Item
                onClick={() => {
                  viewActionMenu(document);
                }}
              >
                View
              </Menu.Item>
            )}

            {document.file && (
              <Menu.Item
                onClick={() => {
                  setSelectedDocumentForManagement(document);
                  downloadFile(document.documentId);
                }}
              >
                Download
              </Menu.Item>
            )}

            {document.fileCount > 0 && (
              <Menu.Item
                onClick={() => {
                  employerId &&
                    dispatch(
                      downloadFolder(
                        employerId,
                        document.documentId,
                        showFolderDownloadNotification
                      )
                    );
                }}
              >
                Download
              </Menu.Item>
            )}

            {document.deletable && (
              <Menu.Item
                onClick={() => {
                  deleteActionMenu(document);
                }}
              >
                Delete
              </Menu.Item>
            )}

            {document.editable && (
              <Menu.Item
                onClick={() => {
                  setSelectedDocumentForManagement(document);
                  renameFile(document.documentId);
                }}
              >
                Rename
              </Menu.Item>
            )}
          </Menu>
        }
      >
        Select
      </SelectDropdown>
    );
  };

  const uploadFile = ({ target }: any) => {
    const file = target.files[0];
    if (!file) {
      return;
    }

    // Max SBC file size is 100MB
    if (file.size / (1024 * 1024) > 200) {
      setInvalidFileSize(true);
      return;
    }

    setSelectedFile(file);
    if (employerId) {
      dispatch(
        getDocumentAvailability(
          employerId,
          selectedParentId,
          encodeURIComponent(file.name),
          (isExits: boolean) => {
            if (!isExits) {
              uploadSelectedFile(false, file);
            } else {
              setFileExistsModalOpen(true);
            }
          }
        )
      );
    }
  };

  const uploadSelectedFile = (isDuplicate: boolean, file?: File) => {
    if (employerId) {
      dispatch(
        uploadDocument(
          employerId,
          selectedParentId,
          isDuplicate,
          file ? file : selectedFile,
          () => {
            setDocumentSucessfullyUpdated(true);
            refreshDocumentList();
          }
        )
      );
    }
  };

  const columns: DataColumn[] = [
    {
      title: 'FILE NAME',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      className: 'documentName',
      render: (value, row) => {
        const extension = row.file ? row.name.split('.').pop() : row.name;
        return (
          <div className={row.file ? 'file' : 'folder'}>
            <img
              className={styles.documentImage}
              src={!row.file ? IconFolder : getFileIconBtExtension(extension)}
              alt=""
            />
            {value.replace(`.${extension}`, '')}
          </div>
        );
      },
      sorter: (a: any, b: any) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: 'SIZE',
      dataIndex: 'size',
      key: 'size',
      width: '10%',
      sorter: (a: any, b: any) => {
        if (a.file & b.file) {
          return a.fileSize > b.fileSize ? 1 : -1;
        } else if (!a.file && !b.file) {
          return a.fileCount > b.fileCount ? 1 : -1;
        }
        return 0;
      },
    },
    {
      title: 'DATE UPDATED',
      dataIndex: 'updatedTs',
      key: 'updatedTs',
      width: '25%',
      sorter: (a: any, b: any) => {
        return new Date(a.updatedTs) > new Date(b.updatedTs) ? 1 : -1;
      },
    },
    {
      title: 'UPLOADED BY',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: '20%',
      sorter: (a: any, b: any) => {
        return a.updatedBy.localeCompare(b.updatedBy);
      },
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      align: 'right',
      className: 'actionColumn',
      render: (value, row, index) => {
        return (
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {appBootupInfo?.workEmail !== row?.createdBy && !canDeleteDocument()
              ? restrictedActionMenu(row)
              : getActions(row)}
          </div>
        );
      },
    },
  ];

  const breadcrumbData = [...(get(data, 'path', []) || [])];
  // First step of breadcrumb should be document
  breadcrumbData[0] = 'Documents';
  const materialPathChild = get(
    data,
    'parentDocument.materialPathChild',
    ''
  ).split(',');

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  return (
    <ContentContainer>
      <FolderManagementModal
        visible={isFolderCreateModalOpen}
        onClose={() => {
          setFolderCreateModalOpen(false);
        }}
        onCreate={createFolderInCurrentFolder}
        title="Add New Folder"
        saveButtonText="Add Folder"
        initialValue=""
        parentDocumentId={selectedParentId}
      />
      <FolderManagementModal
        visible={isFolderRenameModalOpen}
        onClose={() => {
          setFolderRenameModalOpen(false);
        }}
        onCreate={renameSelectedFolder}
        title="Rename Folder"
        saveButtonText="Save"
        initialValue={selectedDocumentForManagement?.name}
        parentDocumentId={selectedParentId}
      />
      <ConfirmationDialog
        modalClassName={styles.confirmationModal}
        title={`Delete ${
          selectedDocumentForManagement?.file ? 'Document' : 'Folder'
        }`}
        confirmText={`Yes-Delete ${
          selectedDocumentForManagement?.file ? 'Document' : 'Folder'
        }`}
        cancelText="Cancel"
        closeModal={() => {
          setDeleteConfirmation(false);
        }}
        onConfirm={() => {
          deleteFile(selectedDocumentForManagement?.documentId);
        }}
        visible={isDeleteConfirmationOpen}
      >
        <p>
          Are you sure you want to delete this
          {selectedDocumentForManagement?.file ? ' document' : ' folder'}?
        </p>
      </ConfirmationDialog>
      <ConfirmationDialog
        modalClassName={styles.documentUploadconfirmationModal}
        title={`Document Upload`}
        confirmText={``}
        cancelText="Cancel"
        closeModal={() => {
          setFileExistsModalOpen(false);
        }}
        onConfirm={() => {
          deleteFile(selectedDocumentForManagement?.documentId);
        }}
        visible={isFileExistsModalOpen}
      >
        <p>This file name already exists. Please select an option:</p>
        <Row>
          <Col xs={12}>
            <div className={styles.actionButtonWrapper}>
              <PageActionButton
                className={styles.actionButton}
                type="primary"
                onClick={() => {
                  uploadSelectedFile(true);
                  setFileExistsModalOpen(false);
                }}
              >
                Keep Both Files
              </PageActionButton>
            </div>
          </Col>
          <Col xs={12}>
            <div className={styles.actionButtonWrapper}>
              <PageActionButton
                className={styles.actionButton}
                type="primary"
                onClick={() => {
                  uploadSelectedFile(false);
                  setFileExistsModalOpen(false);
                }}
              >
                Overwrite Existing File
              </PageActionButton>
            </div>
          </Col>
        </Row>
      </ConfirmationDialog>
      <div className={styles.notificationsWrapper}>
        {invalidFileSize && (
          <AlertMessage
            type="error"
            message={
              'This file is over the file size limit of 200MB. Please try upload again.'
            }
            closeAlert={() => {
              setInvalidFileSize(false);
            }}
            wrapperClassName={styles.alertMessage}
          />
        )}
        {invalidFileType && (
          <AlertMessage
            type="error"
            message={'File type not supported. Please upload a different file.'}
            closeAlert={() => {
              setInvalidFileType(false);
            }}
            wrapperClassName={styles.alertMessage}
          />
        )}
        {uploadFailed && (
          <AlertMessage
            type="error"
            message={'Upload failed. Please try again.'}
            closeAlert={() => {
              setUploadFailed(false);
            }}
            wrapperClassName={styles.alertMessage}
          />
        )}
        {downloadFailed && (
          <AlertMessage
            type="error"
            message={'Download failed. Please try again.'}
            closeAlert={() => {
              setDownloadFailed(false);
            }}
            wrapperClassName={styles.alertMessage}
          />
        )}
        {isDocumentSuccessfullyUpdated && (
          <AlertMessage
            type="success"
            message={'New Document has been successfully uploaded.'}
            closeAlert={() => {
              setDocumentSucessfullyUpdated(false);
            }}
            wrapperClassName={styles.alertMessage}
          />
        )}
        {isFolderSuccessfullyUpdated && (
          <AlertMessage
            type="success"
            message={'New Folder has been successfully created.'}
            closeAlert={() => {
              setFolderSucessfullyUpdated(false);
            }}
            wrapperClassName={styles.alertMessage}
          />
        )}
        {isSuccessfullyDeleted && (
          <AlertMessage
            type="success"
            message={`${
              selectedDocumentForManagement?.file ? 'Document' : 'Folder'
            } has been deleted.`}
            closeAlert={() => {
              setSuccessfullyDeleted(false);
            }}
            wrapperClassName={styles.alertMessage}
          />
        )}
      </div>
      <div className={styles.documentsWrapper}>
        <Row className={styles.sectionTitle}>
          <Col span={12}>
            <h1>{name} Documents</h1>
          </Col>
        </Row>
        <Row className={styles.planYearFilter}>
          <PlanYearDropdown
            onChange={onChangePlanYear}
            className={styles.planYearLabel}
            defaultLatest={true}
          />
          <Breadcrumb className={styles.breadcrumb}>
            {breadcrumbData.map((item: any, index: number) => (
              <BreadcrumbItem
                key={index}
                onClick={() => {
                  if (employerId) {
                    setSearchText('');
                    setSelectedPatentId(materialPathChild[index + 1]);
                    refreshDocumentList();
                  }
                }}
              >
                {item}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </Row>
        <Row className={styles.filterContainer}>
          <Col xs={12}>
            <SearchBar
              placeholder="Search Documents"
              onChange={searchDocument}
              value={searchText}
            />
          </Col>
          <Col xs={12}>
            {parentDocumentId && (
              <Row justify="end">
                <Col>
                  <div
                    className={styles.documentActionLink}
                    onClick={() => {
                      setFolderCreateModalOpen(true);
                    }}
                  >
                    <img
                      className={styles.documentImage}
                      src={IconFolder}
                      alt=""
                    />
                    Add New Folder
                  </div>
                </Col>
                <Col>
                  <div className={styles.documentActionLink}>
                    <img
                      className={styles.documentImage}
                      src={IconDocument}
                      alt=""
                    />
                    Add New Document
                    <input
                      type="file"
                      className={styles.uploadFile}
                      onChange={uploadFile}
                      onClick={onInputClick}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={24} className={styles.documentTable}>
            <Table
              className={`${tableStyles.tableWrapper} ${styles.tableWrapper}`}
              dataSource={convertToTableData(data)}
              columns={columns}
              loading={inProgress}
              pagination={pagination}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setSearchText('');
                    rowClicked(record);
                  },
                };
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              value={pagination.pageSize}
              onChange={(e) => {
                setPagination({ ...pagination, pageSize: e });
              }}
              bordered={false}
              className={`${tableStyles.rowDropdown} ${styles.rowDropdown}`}
              suffixIcon={<img src={selectIcon} alt="select-icon" />}
            >
              {[10, 20, 50].map((pageSize) => (
                <Select.Option key={pageSize} value={pageSize}>
                  View: {pageSize} Rows
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>
    </ContentContainer>
  );
};

export default Documents;
