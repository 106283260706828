import React, { FC, useEffect, useState } from 'react';
import { Button, Dropdown, Menu, notification } from 'antd';
import { isEmpty } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CaretDownIcon } from 'assets/images/icon-caret-down-black.svg';
import { ReactComponent as CaretDownWhiteIcon } from 'assets/images/icon-caret-down.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/icon-download.svg';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { Offer } from 'modules/renewals/models/Offer';
import {
  OFFER_CARD_STATUS,
  OFFER_PUBLISH_NOT_OVERWRITTEN_WITH_PROPOSAL,
  OFFER_PUBLISH_OVERWRITTEN_WITH_PROPOSAL,
  OFFER_STATUS,
  OFFER_TAGS,
} from 'modules/renewals/constants/renewalsConstants';
import {
  exportOfferAuditLog,
  useLazyDeleteOfferQuery,
  useLazyExistProposalByOfferQuery,
  useLazyGetOfferExistsQuery,
  useUpdateOfferStatusFieldMutation,
} from 'modules/renewals/slices/offersSlice';

import { useAppSelector } from 'hooks/redux';
import { loginTypes } from 'constants/authConstants';
import { handleRedirectToProposalsDetails } from 'modules/renewals/utils/renewalsUtils';
import UpdateConfirmationListModal from 'modules/renewals/components/helperComponents/UpdateConfirmationListModal/UpdateConfirmationListModal';
import styles from './actionDropdown.module.less';

type ActionDropdownProps = {
  offer: Offer;
  setOfferStatusField: React.Dispatch<React.SetStateAction<string | null>>;
  onOfferRecommendedStateChange: Function;
  offerStatusField: string | null;
  offerDeleteSuccess?: Function;
  isPlanDetails?: boolean;
  offerEdit?: Function;
  offerClone: Function;
  loadData: Function;
  activeStatus?: string;
  changeOfferPublishStatus?: Function;
  isEditableCreateDisabled?: boolean;
  fromDetailsPage?: boolean;
  isChangeStyle?: boolean;
};
type ActionTypes = 'DELETE' | 'DTQ' | 'PUBLISH' | 'MOVE_TO_DRAFT' | '';

const ActionDropdown: FC<ActionDropdownProps> = ({
  offer,
  setOfferStatusField,
  onOfferRecommendedStateChange,
  offerStatusField,
  offerDeleteSuccess = () => {},
  isPlanDetails = false,
  offerEdit,
  offerClone,
  loadData,
  activeStatus,
  changeOfferPublishStatus,
  fromDetailsPage = false,
  isEditableCreateDisabled = false,
  isChangeStyle = false,
}) => {
  const params = useParams();
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const [isMarkDTQOpen, setIsMarkDTQOpen] = useState<boolean>(false);
  const [showChangingStatusPopUp, setShowChangingStatusPopUp] = useState({
    action: '',
    show: false,
  });
  const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);
  const [showPublishOfferInPropModal, setShowPublishOfferModal] = useState({
    data: [],
    show: false,
    isOverwrite: false,
  });
  const [existProposalConfirmVisible, setExistProposalConfirmVisible] =
    useState<{ show: boolean; type: ActionTypes }>({ show: false, type: '' });
  const [existProposalData, setExistProposalData] =
    useState<Array<{ id: string; proposalName: string }>>();

  const [isRecommendedModalOpen, setRecommendedModalOpen] =
    useState<boolean>(false);
  const [
    isMoveToDraftConfirmationVisible,
    setIsMoveToDraftConfirmationVisible,
  ] = useState<boolean>(false);
  const [
    existProposalConfirmVisibleForDraft,
    setExistProposalConfirmVisibleForDraft,
  ] = useState<boolean>(false);

  const [
    showDOfferPExistOnFinalProposalVisible,
    setShowDOfferPExistOnFinalProposalVisible,
  ] = useState<{ show: boolean; data: any }>({
    show: false,
    data: null,
  });

  const [updateOfferStatusField, { isSuccess: isUpdateStatusFieldSuccess }] =
    useUpdateOfferStatusFieldMutation();

  const [getOfferExists, {}] = useLazyGetOfferExistsQuery();

  const [deleteOffer, { isSuccess: isOfferDeleteSuccess }] =
    useLazyDeleteOfferQuery();

  useEffect(() => {
    if (isUpdateStatusFieldSuccess && isMarkDTQOpen) {
      setOfferStatusField(OFFER_TAGS.DTQ);
      setIsMarkDTQOpen(false);
      loadData();
    }
    // eslint-disable-next-line
  }, [isUpdateStatusFieldSuccess, isMarkDTQOpen, offer, setOfferStatusField]);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(
      `/brokers/${params.brokerId}/employers/${params.employerId}/renewals/carrier/${params.benefitKind}`,
      { state: { activeStatus } }
    );
  };

  useEffect(() => {
    if (isOfferDeleteSuccess) {
      if (isPlanDetails) {
        goBack();
      }
      offerDeleteSuccess();
    }
    // eslint-disable-next-line
  }, [offerDeleteSuccess, isOfferDeleteSuccess, isPlanDetails]);

  const [existProposalByOffer] = useLazyExistProposalByOfferQuery();

  const markAsRecommended = () => {
    onOfferRecommendedStateChange(true);
  };

  const unmarkedAsRecommended = () => {
    onOfferRecommendedStateChange(false);
  };

  const exportOfferActivityLog = (offerId: string | undefined) => {
    exportOfferAuditLog(offerId);
    notification['info']({
      closeIcon: <></>,
      top: 40,
      message: 'Download Started',
      description:
        'Please wait while we export the activity log. This process may take a few minutes',
      icon: <DownloadIcon className={styles.downloadIcon} />,
    });
  };

  const checkForExistingOffers = async (offer: Offer) => {
    const result = await getOfferExists({
      employerId: params?.employerId ?? '',
      status:
        offer?.status === OFFER_STATUS.PUBLISHED
          ? OFFER_STATUS.DRAFT
          : OFFER_STATUS.PUBLISHED,
      name: encodeURIComponent(offer?.name ?? ''),
      category: offer?.category ?? offer?.benefitCategory,
    }).unwrap();

    return result;
  };

  const checkExistProposal = async (offer: Offer, type: ActionTypes) => {
    try {
      const existsData = await existProposalByOffer({
        offerId: isPlanDetails ? offer.offerId : offer.id,
        currentTs: new Date().getTime(),
      }).unwrap();

      if (existsData?.length !== 0) {
        if (type === 'MOVE_TO_DRAFT') {
          setExistProposalConfirmVisibleForDraft(true);
        }
        if (type === 'DELETE') {
          setExistProposalConfirmVisible({ show: true, type: 'DELETE' });
        }
        if (type === 'PUBLISH') {
          const finalizedProposal = existsData?.find(
            (item: any) => item?.status === 'APPROVED'
          );
          const existsPublishedOffer = (await checkForExistingOffers(offer))
            .existsPublishedOffer;
          if (finalizedProposal) {
            setShowDOfferPExistOnFinalProposalVisible({
              data: finalizedProposal,
              show: Boolean(finalizedProposal),
            });
          } else if (!isEmpty(existsData)) {
            setShowPublishOfferModal({
              data: existsData,
              show: true,
              isOverwrite: existsPublishedOffer ?? false,
            });
          }
        } else {
          setExistProposalData(existsData);
          setExistProposalConfirmVisible({ show: true, type });
        }
      } else {
        switch (type) {
          case 'DELETE':
            setDeleteConfirmVisible(true);
            break;
          case 'DTQ':
            setIsMarkDTQOpen(true);
            break;
          case 'PUBLISH':
            const existsPublishedOffer = (await checkForExistingOffers(offer))
              .existsPublishedOffer;
            if (existsPublishedOffer) {
              setShowChangingStatusPopUp({
                action: 'PUBLISH',
                show: true,
              });
            } else {
              changeOfferPublishStatus?.(
                fromDetailsPage ? offer.offerId : offer.id,
                OFFER_CARD_STATUS.PUBLISHED
              );
            }
            break;
          case 'MOVE_TO_DRAFT':
            const existsDraftOffer = (await checkForExistingOffers(offer))
              .existsDraftOffer;
            if (existsDraftOffer) {
              setShowChangingStatusPopUp({
                action: 'MOVE_TO_DRAFT',
                show: true,
              });
            } else {
              setIsMoveToDraftConfirmationVisible(true);
            }
            break;
          case 'DELETE':
            setDeleteConfirmVisible(true);
            break;
          default:
            return null;
        }
      }
    } catch (error: any) {
      console.error(error?.response);
    }
  };

  const handleDelete = (): void => {
    deleteOffer({
      offerId: isPlanDetails ? offer.offerId ?? '' : offer.id ?? '',
    });
    setDeleteConfirmVisible(false);
  };

  const getActionMenu = (offer: Offer) => {
    const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;
    const isPlatformAdmin: boolean = appBootInfo?.type === loginTypes.platform;

    {
      if (isErAdmin) {
        return (
          <Menu
            className={
              isChangeStyle ? styles.actionsMenuView : styles.actionsMenu
            }
          >
            <Menu.Item>Offer History</Menu.Item>
          </Menu>
        );
      } else {
        return (
          <Menu className={styles.actionsMenu}>
            {!isPlanDetails && (
              <Menu.Item
                onClick={() => offerEdit && offerEdit(offer)}
                disabled={isEditableCreateDisabled}
              >
                Edit Offer
              </Menu.Item>
            )}
            {(fromDetailsPage ? !offer?.isRecommended : !offer?.recommended) &&
              offer.offerStatus !== OFFER_TAGS.DTQ &&
              offer.offerStatus !== OFFER_TAGS.PENDING && (
                <Menu.Item
                  onClick={markAsRecommended}
                  disabled={isEditableCreateDisabled}
                >
                  Recommend
                </Menu.Item>
              )}
            {(fromDetailsPage ? offer?.isRecommended : offer?.recommended) && (
              <Menu.Item
                onClick={unmarkedAsRecommended}
                disabled={isEditableCreateDisabled}
              >
                Remove from Recommended
              </Menu.Item>
            )}
            {offer.status !== OFFER_CARD_STATUS.PUBLISHED && (
              <Menu.Item
                onClick={() => checkExistProposal(offer, 'PUBLISH')}
                disabled={isEditableCreateDisabled}
              >
                Publish Offer
              </Menu.Item>
            )}
            {offer.status == OFFER_CARD_STATUS.PUBLISHED && (
              <Menu.Item
                disabled={isEditableCreateDisabled}
                onClick={() => {
                  checkExistProposal(offer, 'MOVE_TO_DRAFT');
                }}
              >
                Move Offer to Drafts
              </Menu.Item>
            )}
            <Menu.Item
              disabled={isEditableCreateDisabled}
              onClick={() => {
                offerClone && offerClone(offer);
              }}
            >
              Clone Offer
            </Menu.Item>

            <Menu.Item
              onClick={() => {
                checkExistProposal(offer, 'DELETE');
              }}
              disabled={isEditableCreateDisabled}
            >
              Delete Offer
            </Menu.Item>

            {offerStatusField !== OFFER_TAGS.DTQ && (
              <Menu.Item
                disabled={isEditableCreateDisabled}
                onClick={() => {
                  checkExistProposal(offer, 'DTQ');
                }}
              >
                Mark as DTQ
              </Menu.Item>
            )}
            {isPlatformAdmin && (
              <Menu.Item
                onClick={() => {
                  exportOfferActivityLog(
                    offer.id ? offer.id : (offer as any).offerId
                  );
                }}
              >
                Export Activity Log
              </Menu.Item>
            )}
          </Menu>
        );
      }
    }
  };

  return (
    <>
      <Dropdown
        overlay={getActionMenu(offer)}
        trigger={['click']}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentElement ? triggerNode.parentElement : triggerNode
        }
        className={isChangeStyle ? styles.dropdownWrapper : undefined}
      >
        <Button
          type="link"
          onClick={(e) => e.preventDefault()}
          className={styles.actionsButton}
        >
          <div
            className={
              isChangeStyle
                ? styles.actionsDropdownView
                : styles.actionsDropdown
            }
          >
            Actions
            {isChangeStyle ? (
              <CaretDownWhiteIcon className={styles.caretDownIcon} />
            ) : (
              <CaretDownIcon className={styles.caretDownIcon} />
            )}
          </div>
        </Button>
      </Dropdown>

      <ConfirmationDialog
        destroyOnClose={true}
        confirmText={`Yes - ${
          showChangingStatusPopUp.action === 'PUBLISH'
            ? 'Publish'
            : 'Move to Draft'
        } Offer`}
        onConfirm={() => {
          if (showChangingStatusPopUp.action === 'PUBLISH') {
            changeOfferPublishStatus?.(
              fromDetailsPage ? offer.offerId : offer.id,
              OFFER_CARD_STATUS.PUBLISHED
            );
          } else if (showChangingStatusPopUp.action === 'MOVE_TO_DRAFT') {
            changeOfferPublishStatus?.(
              fromDetailsPage ? offer.offerId : offer.id,
              OFFER_CARD_STATUS.IN_PROGRESS
            );
          }
          setShowChangingStatusPopUp({ action: '', show: false });
        }}
        visible={showChangingStatusPopUp.show}
        onCancel={() => {
          setShowChangingStatusPopUp({ action: '', show: false });
        }}
        closeModal={() => {
          setShowChangingStatusPopUp({ action: '', show: false });
        }}
        title={`Overwrite Existing ${
          showChangingStatusPopUp.action === 'PUBLISH' ? 'Published' : 'Draft'
        } Offer?`}
        cancelText="Cancel"
        buttonsExpanded={true}
      >
        <p className={styles.claimsConfirmation}>
          <div>
            A{' '}
            {showChangingStatusPopUp.action === 'PUBLISH'
              ? 'published '
              : 'draft '}
            version of this offer already exists.
            {showChangingStatusPopUp.action === 'PUBLISH'
              ? ' Publishing this offer '
              : ' Saving this offer as draft '}
            will overwrite that data. Do you wish to continue?
          </div>
        </p>
      </ConfirmationDialog>

      <ConfirmationDialog
        width={464}
        title="Delete Offer"
        confirmText="Yes - Delete Offer"
        cancelText="Cancel"
        closeModal={() => {
          setDeleteConfirmVisible(false);
        }}
        onConfirm={() => {
          handleDelete();
        }}
        visible={deleteConfirmVisible}
        confirmBtnFullWidth={true}
        isCancelLink={true}
      >
        <p className={styles.description}>
          Are you sure you want to delete this offer? This action cannot be
          undone.
        </p>
      </ConfirmationDialog>

      <UpdateConfirmationListModal
        visible={
          existProposalConfirmVisible.show &&
          (existProposalConfirmVisible?.type === 'DELETE' ||
            existProposalConfirmVisible?.type === 'DTQ')
        }
        confirmText="Close"
        onConfirm={() => {
          setExistProposalConfirmVisible({ show: false, type: '' });
          setExistProposalData([]);
        }}
        title={
          existProposalConfirmVisible?.type === 'DELETE'
            ? 'Delete Offer'
            : 'Mark as DTQ'
        }
        listTitle="Proposal name"
        description={`This offer exists in the proposals listed below. ${
          existProposalConfirmVisible?.type === 'DELETE'
            ? 'To delete the offer,'
            : 'To mark the offer as DTQ,'
        } it must be removed from any proposals beforehand.`}
        isCloseOnly={true}
        confirmBtnFullWidth={true}
        listData={
          existProposalData?.map((item) => ({
            label: item?.proposalName,
            value: item?.id,
          })) || []
        }
        closeOnlyBtnFullWidth={true}
        onClickItem={(item: any) =>
          handleRedirectToProposalsDetails(item.value, params)
        }
        centered={false}
      />

      <ConfirmationDialog
        width={464}
        title="Decline to Quote"
        confirmText="Yes - Mark DTQ"
        cancelText="Cancel"
        closeModal={() => {
          setIsMarkDTQOpen(false);
        }}
        onConfirm={() => {
          const offerId = isPlanDetails ? offer?.offerId : offer?.id;
          updateOfferStatusField({
            offerId: offerId,
            offerStatusField: OFFER_TAGS.DTQ,
          });
        }}
        visible={isMarkDTQOpen}
        isCancelLink={true}
        confirmBtnFullWidth={true}
      >
        {offer?.recommended && (
          <p className={styles.popupText}>
            {
              "This offer is currently marked as 'Recommended'. Changing its status to 'DTQ' will automatically remove the 'Recommended' label."
            }
          </p>
        )}
        {!offer?.recommended && (
          <p className={styles.popupText}>
            Are you sure you want to mark this Offer as Decline to Quote?
          </p>
        )}
      </ConfirmationDialog>

      <ConfirmationDialog
        width={464}
        modalClassName={styles.confirmationModal}
        title="Recommended Offer - TODO"
        confirmText="Recommended Offer - TODO"
        cancelText="Cancel"
        closeModal={() => {
          setRecommendedModalOpen(false);
        }}
        onConfirm={() => {
          onOfferRecommendedStateChange(offer?.recommended);
        }}
        visible={isRecommendedModalOpen}
      >
        <p className={styles.popupText}>Recommended Offer - TODO</p>
      </ConfirmationDialog>

      <ConfirmationDialog
        modalClassName={styles.confirmationDeleteModal}
        title="Move Offer to Drafts"
        confirmText="Yes - Move Offer to Drafts"
        cancelText="Cancel"
        closeModal={() => {
          setIsMoveToDraftConfirmationVisible(false);
        }}
        onConfirm={() => {
          changeOfferPublishStatus?.(
            fromDetailsPage ? offer.offerId : offer.id,
            OFFER_CARD_STATUS.IN_PROGRESS
          );
          setIsMoveToDraftConfirmationVisible(false);
        }}
        visible={isMoveToDraftConfirmationVisible}
      >
        <p className={styles.description}>
          Are you sure you want to move this offer to drafts?
        </p>
      </ConfirmationDialog>

      <ConfirmationDialog
        modalClassName={styles.confirmationModalProposal}
        title="Move Offer to Drafts"
        confirmText="Close"
        isCloseOnly={true}
        onConfirm={() => {
          setExistProposalConfirmVisibleForDraft(false);
        }}
        visible={existProposalConfirmVisibleForDraft}
      >
        <p className={styles.description}>
          This offer exists in the proposals listed below. To move the offer to
          drafts, it must be removed from any proposals beforehand.
        </p>
        <p className={styles.proposalTitle}>PROPOSAL NAME</p>
        <hr />
        {existProposalData &&
          existProposalData.map((proposal) => (
            <p
              className={styles.proposalName}
              key={proposal.id}
              onClick={() =>
                handleRedirectToProposalsDetails(proposal.id, params)
              }
            >
              {proposal.proposalName}
            </p>
          ))}
      </ConfirmationDialog>

      <UpdateConfirmationListModal
        visible={showPublishOfferInPropModal.show}
        confirmText="Publish Offer"
        description={
          showPublishOfferInPropModal.isOverwrite
            ? OFFER_PUBLISH_OVERWRITTEN_WITH_PROPOSAL
            : OFFER_PUBLISH_NOT_OVERWRITTEN_WITH_PROPOSAL
        }
        onConfirm={() => {
          setShowPublishOfferModal({
            data: [],
            show: false,
            isOverwrite: false,
          });
          changeOfferPublishStatus?.(
            fromDetailsPage ? offer.offerId : offer.id,
            OFFER_CARD_STATUS.PUBLISHED
          );
        }}
        closeModal={() => {
          setShowPublishOfferModal({
            data: [],
            show: false,
            isOverwrite: false,
          });
        }}
        cancelText="Cancel"
        title="Update Offers in Proposal"
        listTitle="Proposal name"
        confirmBtnFullWidth={true}
        isCancelLink={true}
        listData={
          showPublishOfferInPropModal?.data?.map((item: any) => ({
            label: item?.proposalName,
            value: item?.id,
          })) || []
        }
        onClickItem={(item: any) =>
          handleRedirectToProposalsDetails(item?.value, params)
        }
      />

      <ConfirmationDialog
        width={464}
        title="Publish Offer"
        confirmText="Close"
        onConfirm={() => {
          setShowDOfferPExistOnFinalProposalVisible({
            show: false,
            data: null,
          });
        }}
        visible={showDOfferPExistOnFinalProposalVisible.show}
        isCloseOnly={true}
        closeOnlyBtnFullWidth={true}
      >
        <p className={styles.publishModalContent}>
          A published version of this offer is associated with a{' '}
          <span
            onClick={() =>
              handleRedirectToProposalsDetails(
                showDOfferPExistOnFinalProposalVisible?.data?.id,
                params
              )
            }
          >
            finalized proposal
          </span>{' '}
          . To publish this draft, the proposal must be unmarked as final.
        </p>
      </ConfirmationDialog>
    </>
  );
};

export default ActionDropdown;
