import { Button, Modal, ModalProps, Spin } from 'antd';
import { FC, useState } from 'react';

import { useAppSelector } from 'hooks/redux';
import RenewalType from 'modules/renewals/enums/RenewalType';
import CloseType from 'modules/renewals/enums/CloseType';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { CLOSE_TYPE_TEXT } from 'modules/renewals/constants/renewalsConstants';

import { loginTypes } from 'constants/authConstants';
import styles from './closeModalPopup.module.less';

export interface CloseModalPopupProps extends ModalProps {
  visible: boolean;
  type: RenewalType;
  closeType: CloseType;
  onPublish?: Function;
  onSaveAsDraft?: Function;
  onCloseWithoutSaving: Function;
  onCancelClick: Function;
  isLoading?: boolean;
  isDisabled?: {
    /* eslint-disable-next-line no-unused-vars */
    ['PUBLISH']?: boolean;
  };
  overallLoader?: boolean;
}

/* eslint-disable no-unused-vars */
export enum ButtonType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  CANCEL = 'CANCEL',
}

const CloseModalPopup: FC<CloseModalPopupProps> = (props) => {
  const {
    visible,
    type,
    closeType,
    onPublish,
    onSaveAsDraft,
    onCloseWithoutSaving,
    onCancelClick,
    isLoading = false,
    overallLoader = false,
    isDisabled = {},
  } = props;

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const isProposalErAdmin: boolean =
    appBootInfo?.type === loginTypes.erAdmin && type === RenewalType.PROPOSAL;
  const isOpsAdmin: boolean = appBootInfo?.isOpsAdmin ?? false;
  const [clickedButton, setClickedButton] = useState<ButtonType | null>(null);

  //  get text to show in the body of the modal
  const getBodyText = () => {
    if (closeType === CloseType.ERROR) {
      return `Are you sure you want to close this ${type.toLowerCase()}? If you leave now, you will lose any unsaved data. Do you wish to continue?`;
    } else {
      return CLOSE_TYPE_TEXT?.[type];
    }
  };

  const assignButtonText = (buttonType: ButtonType) => {
    let primaryButtonText = null;
    let secondaryButtonText = null;
    const proposalErText = `Publish Proposal`;

    switch (closeType) {
      case CloseType.ERROR:
        primaryButtonText = `Close without Saving`;
        break;
      case CloseType.CREATE:
        primaryButtonText = isProposalErAdmin
          ? proposalErText
          : `Publish ${type} to Employer`;
        secondaryButtonText = `Save ${type} as Draft`;
        break;
      case CloseType.EDIT_PUBLISHED:
        primaryButtonText = isProposalErAdmin
          ? proposalErText
          : `Publish ${type} to Employer`;
        secondaryButtonText = `Save ${type} as New Draft`;
        break;
      case CloseType.EDIT_DRAFT:
        primaryButtonText = `Save Draft ${type}`;
        secondaryButtonText = isProposalErAdmin
          ? proposalErText
          : `Publish ${type} to Employer`;
        break;
    }

    return buttonType === ButtonType.PRIMARY
      ? primaryButtonText
      : secondaryButtonText;
  };

  //  handle primary button on click
  const primaryButtonOnClick = () => {
    setClickedButton(ButtonType.PRIMARY);
    if (closeType === CloseType.ERROR) {
      onCloseWithoutSaving();
    } else if (closeType === CloseType.EDIT_DRAFT) {
      onSaveAsDraft && onSaveAsDraft();
    } else {
      onPublish && onPublish();
    }
  };

  //  handle secondary button on click
  const secondaryButtonOnClick = () => {
    setClickedButton(ButtonType.SECONDARY);
    if (closeType === CloseType.EDIT_DRAFT) {
      onPublish && onPublish();
    } else {
      onSaveAsDraft && onSaveAsDraft();
    }
  };

  //  handle close without saving on click
  const closeWithoutSavingOnClick = () => {
    setClickedButton(ButtonType.TERTIARY);
    onCloseWithoutSaving();
  };

  //  handle cancel
  const cancelOnClick = () => {
    setClickedButton(null);
    onCancelClick();
  };

  //  check if button is loading
  const isButtonLoading = (buttonType: ButtonType) => {
    return isLoading && clickedButton === buttonType;
  };

  //  check if button is disabled
  const isButtonDisabled = (buttonType: ButtonType) => {
    const isBtnPublish =
      closeType === CloseType.EDIT_DRAFT
        ? buttonType === ButtonType.SECONDARY
        : buttonType === ButtonType.PRIMARY;

    const isPublishOrDraft =
      [ButtonType.SECONDARY].includes(buttonType) &&
      closeType !== CloseType.ERROR;

    return (
      (isLoading && clickedButton !== buttonType) ||
      (isBtnPublish && isDisabled?.PUBLISH) ||
      (isPublishOrDraft && type === RenewalType.OFFER && isOpsAdmin)
    );
  };

  const isOpsAdminAndOffer = type === RenewalType.OFFER && isOpsAdmin;

  return (
    <Modal
      title={<div className={styles.title}>{`Close ${type}`}</div>}
      visible={visible}
      footer={<></>}
      wrapClassName={styles.closeModalConfirmationContainer}
      destroyOnClose
    >
      {overallLoader ? (
        <Spin />
      ) : (
        <div className={styles.closeModalConfirmationBodyContainer}>
          {getBodyText()}
          {!isOpsAdminAndOffer && (
            <Button
              className={styles.primaryButton}
              onClick={primaryButtonOnClick}
              loading={isButtonLoading(ButtonType.PRIMARY)}
              disabled={isButtonDisabled(ButtonType.PRIMARY)}
            >
              {assignButtonText(ButtonType.PRIMARY)}
            </Button>
          )}

          {closeType !== CloseType.ERROR &&
            !isProposalErAdmin &&
            !isOpsAdminAndOffer && (
              <Button
                className={styles.secondaryButton}
                onClick={secondaryButtonOnClick}
                loading={isButtonLoading(ButtonType.SECONDARY)}
                disabled={isButtonDisabled(ButtonType.SECONDARY)}
              >
                {assignButtonText(ButtonType.SECONDARY)}
              </Button>
            )}
          {(closeType !== CloseType.ERROR || isOpsAdminAndOffer) && (
            <Button
              className={
                isOpsAdminAndOffer
                  ? styles.primaryButton
                  : styles.tertiaryButton
              }
              onClick={closeWithoutSavingOnClick}
              loading={isButtonLoading(ButtonType.TERTIARY)}
              disabled={isButtonDisabled(ButtonType.TERTIARY)}
            >{`Close without Saving`}</Button>
          )}
          <LinkButton
            containerClassName={styles.cancelButton}
            onClick={cancelOnClick}
            disabled={isButtonDisabled(ButtonType.CANCEL)}
          >
            Cancel
          </LinkButton>
        </div>
      )}
    </Modal>
  );
};

export default CloseModalPopup;
