import { Table } from 'antd';
import Column from 'antd/lib/table/Column';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import LinkButton from 'components/buttons/LinkButton/LinkButton';

import styles from 'modules/renewals/components/ProposalCard/ProposalCardReviewModal/ProposalCardReviewModal.module.less';

export type ProposalCardReviewModalTableRow = {
  offerName: string;
  change: string;
};

type ProposalCardReviewModalProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  data: ProposalCardReviewModalTableRow[];
};

const ProposalCardReviewModal = ({
  data,
  isOpen,
  onCancel,
  onConfirm,
}: ProposalCardReviewModalProps) => {
  const handleOffer = (offer: string): void => {
    console.log('Offer clicked...');
  };

  return (
    <ConfirmationDialog
      confirmText="Update Proposal"
      onConfirm={onConfirm}
      title="Review Changes"
      visible={isOpen}
      closeModal={onCancel}
      cancelText="Close"
      isCancelLink
    >
      <p>
        Offers within this proposal have changed. Please review the offer
        changes below to update the proposal.
      </p>
      <Table<ProposalCardReviewModalTableRow>
        size="small"
        pagination={false}
        dataSource={data}
        className={styles.tableWrapper}
      >
        <Column
          title="Offer Name"
          dataIndex="offerName"
          key="offerName"
          render={(value) => (
            <span className={styles.link}>
              <LinkButton onClick={() => handleOffer(value)}>
                {value}
              </LinkButton>
            </span>
          )}
        />
        <Column title="Change" dataIndex="change" key="change" />
      </Table>
    </ConfirmationDialog>
  );
};

export default ProposalCardReviewModal;
