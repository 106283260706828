import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BenefitCarrier from 'model/BenefitCarrier';

import * as PlanService from 'modules/plans/services/PlanService';

export interface BasicPlanInfoState {
  inProgress: boolean;
  error: any;
  requestType: string;
  statesAndTerritoriesList: Array<string>;
  carriersList: Array<BenefitCarrier>;
  carriersListLoading: boolean;
}

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  statesAndTerritoriesList: [],
  carriersList: [],
  carriersListLoading: false,
} as BasicPlanInfoState;

const basicPlanInfoSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    getStatesAndTerritoriesInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.statesAndTerritoriesList = [];
      state.error = null;
      state.requestType = action.type;
    },
    getStatesAndTerritoriesCompleted: (
      state,
      action: PayloadAction<Array<string>>
    ) => {
      state.inProgress = false;
      state.statesAndTerritoriesList = action.payload;
      state.error = null;
      state.requestType = action.type;
    },
    getStatesAndTerritoriesFailed: (state, action) => {
      state.inProgress = false;
      state.statesAndTerritoriesList = [];
      state.error = { response: action.payload };
      state.requestType = action.type;
    },
    getCarriersInProgress: (state, action: PayloadAction) => {
      state.carriersListLoading = true;
      state.carriersList = [];
      state.error = null;
      state.requestType = action.type;
    },
    getCarriersCompleted: (
      state,
      action: PayloadAction<Array<BenefitCarrier>>
    ) => {
      state.carriersList = action.payload;
      state.error = null;
      state.requestType = action.type;
      state.carriersListLoading = false;
    },
    getCarriersFailed: (state, action) => {
      state.carriersList = [];
      state.error = { response: action.payload };
      state.requestType = action.type;
      state.carriersListLoading = false;
    },
  },
});

export const {
  getStatesAndTerritoriesInProgress,
  getStatesAndTerritoriesCompleted,
  getStatesAndTerritoriesFailed,
  getCarriersInProgress,
  getCarriersCompleted,
  getCarriersFailed,
} = basicPlanInfoSlice.actions;

export default basicPlanInfoSlice.reducer;

export const getStatesAndTerritories = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getStatesAndTerritoriesInProgress());
      const response = await PlanService.getStatesAndTerritories();
      const data = response.data;
      dispatch(getStatesAndTerritoriesCompleted(data));
    } catch (error) {
      dispatch(getStatesAndTerritoriesFailed(error));
    }
  };
};

export const getCarriersByBenefitKind = (
  benefitCategory: string,
  organizationId: string,
  employerId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getCarriersInProgress());
    try {
      const response = await PlanService.getCarriersByBenefitKind(
        benefitCategory,
        organizationId,
        employerId
      );
      const sortedList = response.data?.sort(
        (a: BenefitCarrier, b: BenefitCarrier) =>
          a.name?.trim().localeCompare(b.name?.trim())
      );
      dispatch(getCarriersCompleted(sortedList));
    } catch (error) {
      dispatch(getCarriersFailed(error));
    }
  };
};
