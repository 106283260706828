import { FC } from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

import styles from './formInput.module.less';

interface Props extends InputProps {}

const FormInput: FC<Props> = (props: Props) => {
  const { ...rest } = props;
  return (
    <div className={styles.inputWithoutForm}>
      <Input {...rest} />
    </div>
  );
};

export default FormInput;
