import { RateTier } from 'modules/plans/constants';
import { IAddProposalToPlanYearState } from 'modules/renewals/types/renewalsTypes';

export const ADD_TO_PLAN_YEAR_FUNDING_TYPE = {
  FULLY_INSURED: {
    value: 'FULLY_INSURED',
    label: 'Fully-Insured',
  },
  LEVEL_FUNDED: {
    value: 'LEVEL_FUNDED',
    label: 'Level-Funded',
  },
  SELF_FUNDED: {
    value: 'SELF_FUNDED',
    label: 'Self-Funded',
  },
} as const;

export const BENEFIT_RESOURCE_PATH_MAP: {
  // eslint-disable-next-line
  [key in keyof IAddProposalToPlanYearState['plans']]?: string;
} = {
  DENTAL: 'dentals',
  MEDICAL: 'medicals',
  VISION: 'visions',
  LIFE_AND_DISABILITY: 'insurances',
  VOLUNTARY_BENEFIT: 'insurances',
} as const;

export const PLAN_OVERVIEW_URL_MAP: {
  [key: string]: string;
} = {
  DENTAL: 'dental',
  LIFE_AND_DISABILITY: 'life',
  MEDICAL: 'medical',
  VISION: 'vision',
  VOLUNTARY_BENEFIT: 'voluntary',
  TAX_ADVANTAGE_ACCOUNTS: 'tax-advantaged-accts',
} as const;

export const PLAN_TYPE_LABEL_MAP = {
  LTD: 'Basic Long-Term Disability',
  STD: 'Basic Short-Term Disability',
  BASIC_LIFE_AND_ADD: 'Basic Life w/AD&D',
  BASIC_LIFE: 'Basic Life',
  BASIC_ADD: 'Basic AD&D',
  VOLUNTARY_LIFE: 'Voluntary Life',
  VOLUNTARY_ADD: 'Voluntary AD&D',
  VOLUNTARY_LIFE_ADD: 'Voluntary Life w/AD&D',
  VOLUNTARY_STD: 'Voluntary Short-Term Disability',
  VOLUNTARY_LTD: 'Voluntary Long-Term Disability',
  ACCIDENT: 'Accident',
  CRITICAL_ILLNESS: 'Critical Illness',
  HOSPITAL: 'Hospital',
  HRA: 'HRA',
  HSA: 'HSA',
} as const;

export const MERGE_PRIOR_PLANS_WARNING =
  'If a plan gets merged with a current plan, the review step will highlight the fields populated with proposal plan data. These values can be edited as needed before saving the plans.';
export const MERGE_PRIOR_PLANS_AUTO_SELECTION_INFO =
  'In this step, a plan from the prior year will be auto-selected based on plan similarity. You can review the selected plan by clicking on the link next to the drop-down.';
export const PLAN_HAS_BEEN_MERGED_WARNING =
  'Plans have been merged with existing plan data. Please review.';
export const PLEASE_REVIEW_PLAN_WARNING = 'Please review this plan.';
export const PLANS_HAVE_BEEN_MERGED_WARNING =
  'Plans have been merged with current year plan data. Please review the plan details below to verify the changes.';
export const PLEASE_REVIEW_PLANS_WARNING = `Please review the plan details below to verify the changes.`;
export const FILE_IS_IN_QUEUE_WARNING =
  'This file is in queue to be processed and can take up to 24 hours to complete.';

export const GENERAL_ERROR_PLAN_YEAR_FAILED =
  'An unexpected error occurred. Please try again.';
export const OVERLAP_ERROR_PLAN_YEAR_FAILED =
  'Plan year having overlap with existing plan years';
export const OVERLAP_ERROR_PLAN_YEAR_CODE =
  'plan.year.date.range.invalid.overlap';

export const STANDARD_TIER_KEYS = [
  RateTier.EC.label,
  RateTier.ES.label,
  RateTier.EF.label,
];

export const BENEFIT_DISPLAY_NAMES: {
  [key: string]: string;
} = {
  medical: 'Medical',
  dental: 'Dental',
  vision: 'Vision',
  life: 'Life & Disability',
  voluntary: 'Voluntary Benefits',
};

export const CURRENT_PLANS_CONFIRMATION =
  'Please confirm that the plans above accurately and completely reflect all of the plans in the current plan year prior to starting the renewal. Once you begin the renewal, you will not be able to add or remove plans.';
