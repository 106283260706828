import { forwardRef, MutableRefObject, useImperativeHandle } from 'react';

import EditBasicPlanInfo from 'modules/plans/components/BasicPlanInfo/EditBasicPlanInfo/EditBasicPlanInfo';
import MedicalPlan from 'model/plans/MedicalPlan';

import styles from './EditBasicPlanInfo/editBasicPlanInfo.module.less';

type BasicPlanInfoDBGWrapperProps = {
  medicalPlan: MedicalPlan;
  setMedicalPlan: Function;
  basicPlanInfoRef?: MutableRefObject<any>;
  medicalPlanYear?: string;
  isReviewProp?: boolean;
  isReviewHighlight?: boolean;
  reviewNewPlanYear?: string;
  reviewBenefitClasses?: string[];
};

const BasicPlanInfoUPWrapper = forwardRef(
  (props: BasicPlanInfoDBGWrapperProps, ref) => {
    const {
      medicalPlan,
      setMedicalPlan,
      basicPlanInfoRef,
      medicalPlanYear,
      isReviewProp,
      isReviewHighlight,
      reviewNewPlanYear,
      reviewBenefitClasses,
    } = props;

    useImperativeHandle(ref, () => ({
      reset() {
        basicPlanInfoRef?.current?.resetForm();
      },
      isValidForm() {
        return basicPlanInfoRef?.current?.isValidForm();
      },
      setNameDuplicationError() {
        return basicPlanInfoRef?.current?.setNameDuplicationError();
      },
    }));

    return (
      <div className={styles.basicPlanInfoDBGWrapper}>
        <EditBasicPlanInfo
          onChange={setMedicalPlan}
          isDBGPlan={true}
          ref={basicPlanInfoRef}
          medicalPlan={medicalPlan}
          isUPEdit={true}
          medicalPlanYear={medicalPlanYear}
          isReviewProp={isReviewProp}
          isReviewHighlight={isReviewHighlight}
          reviewNewPlanYear={reviewNewPlanYear}
          reviewBenefitClasses={reviewBenefitClasses}
        />
      </div>
    );
  }
);

BasicPlanInfoUPWrapper.displayName = 'BasicPlanInfoUPWrapper';
export default BasicPlanInfoUPWrapper;
