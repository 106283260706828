import {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Col, Radio, RadioChangeEvent, Row, Select } from 'antd';

import TimeConfig from 'model/benefitsConsultation/TimeConfig';
import WeekTimeConfig from 'model/benefitsConsultation/WeekTimeConfig';

import { mapValueToLabel } from 'util/commonUtil';
import {
  DaysOfTheWeek,
  TIME_OPTIONS,
} from 'modules/benefitsConsultation/constants/benefitsConsultation';

import styles from './editAvailability.module.less';

type Availability = {
  isOpen: boolean | null;
  startTime: number;
  endTime: number;
};

type AvailabilityRowProps = {
  availability: Availability;
  validateData: boolean;
  setDayConfig: Function;
  day: string;
};

const AvailabilityRow: FC<AvailabilityRowProps> = (
  props: AvailabilityRowProps
) => {
  const { availability, validateData, setDayConfig, day } = props;
  const { isOpen, endTime, startTime } = availability;

  const [isAvailable, setIsAvailable] = useState<boolean | null>(isOpen);
  const [currentStartTime, setCurrentStartTime] = useState<string>(
    startTime.toString()
  );
  const [currentEndTime, setCurrentEndTime] = useState<string>(
    endTime.toString()
  );
  const [isRadioInvalid, setIsRadioInvalid] = useState<boolean>(false);
  const [isTimeInvalid, setisTimeInvalid] = useState<boolean>(false);

  useEffect(() => {
    if (validateData) {
      isAvailable === null ? setIsRadioInvalid(true) : setIsRadioInvalid(false);

      parseInt(currentStartTime) > parseInt(currentEndTime)
        ? setisTimeInvalid(true)
        : setisTimeInvalid(false);
    }

    setDayConfig((prevState: TimeConfig) => ({
      ...prevState,
      [day]: {
        startTime: parseInt(currentStartTime),
        endTime: parseInt(currentEndTime),
        isOpen: isAvailable,
        is24Hours: false,
      },
    }));
  }, [
    day,
    currentEndTime,
    isAvailable,
    setDayConfig,
    currentStartTime,
    validateData,
  ]);

  useEffect(() => {
    if (!isAvailable) {
      setCurrentStartTime('900');
      setCurrentEndTime('1700');
    }
  }, [isAvailable]);

  return (
    <Row>
      <Col span={4}>
        <div
          className={`${styles.item} ${isRadioInvalid ? styles.invalid : ''}`}
        >
          {mapValueToLabel(day, DaysOfTheWeek)}
        </div>
      </Col>
      <Col span={8}>
        <div className={styles.item}>
          <Radio.Group
            onChange={(e: RadioChangeEvent) => {
              setIsAvailable(e.target.value);
              setIsRadioInvalid(false);
            }}
            defaultValue={isAvailable}
          >
            <div
              className={
                isRadioInvalid ? styles.radioItemInvalid : styles.radioItem
              }
            >
              <Radio value={true}>
                <span>Available</span>
              </Radio>
            </div>

            <div className={isRadioInvalid ? styles.invalid : ''}>
              <Radio value={false}>
                <span>Unavailable</span>
              </Radio>
            </div>
          </Radio.Group>
        </div>
      </Col>
      <Col span={6}>
        <div className={isTimeInvalid ? styles.invalid : ''}>
          <Select
            disabled={!isAvailable}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            options={TIME_OPTIONS}
            value={currentStartTime}
            onSelect={(value: string) => {
              setCurrentStartTime(value);
              setisTimeInvalid(false);
            }}
          ></Select>
        </div>
      </Col>
      <Col span={6}>
        <div className={isTimeInvalid ? styles.invalid : ''}>
          <Select
            value={currentEndTime}
            disabled={!isAvailable}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            options={TIME_OPTIONS}
            onSelect={(value: string) => {
              setCurrentEndTime(value);
              setisTimeInvalid(false);
            }}
          ></Select>
        </div>
      </Col>
    </Row>
  );
};

type EditAvailabilityProps = {
  availabilityObj: WeekTimeConfig;
  setShowAvailabilityInvalid: Function;
};

const EditAvailability = forwardRef((props: EditAvailabilityProps, ref) => {
  const { availabilityObj, setShowAvailabilityInvalid } = props;

  const [validateData, setValidateData] = useState<boolean>(false);
  useState<boolean>(true);
  const [dayConfig, setDayConfig] = useState<WeekTimeConfig>(
    {} as WeekTimeConfig
  );

  const checkAvailability = useCallback(() => {
    return Object.values(dayConfig).some(
      (day: TimeConfig) => day.isOpen === null
    );
  }, [dayConfig]);

  const checkDuration = useCallback(() => {
    return Object.values(dayConfig)
      .filter((item: TimeConfig) => item.isOpen)
      .some((day: TimeConfig) => day.startTime >= day.endTime);
  }, [dayConfig]);

  useEffect(() => {
    const isAvailabilityEmpty = checkAvailability();
    const isDurationInvalid = checkDuration();
    if (!(isAvailabilityEmpty || isDurationInvalid))
      setShowAvailabilityInvalid(false);
  }, [checkAvailability, checkDuration, setShowAvailabilityInvalid]);

  useImperativeHandle(ref, () => ({
    validateData() {
      setValidateData(true);
      const isAvailabilityEmpty = checkAvailability();
      const isDurationInvalid = checkDuration();
      if (isAvailabilityEmpty || isDurationInvalid)
        setShowAvailabilityInvalid(true);

      return { dayConfig, isAvailabilityEmpty, isDurationInvalid };
    },

    resetData() {
      setValidateData(false);
    },
  }));

  return (
    <div className={styles.editAvailabilityWrapper}>
      <Row>
        <Col span={12}>
          <div className={styles.firstHeading}>Availability</div>
        </Col>
        <Col span={6}>
          <div className={styles.heading}>Start</div>
        </Col>
        <Col span={6}>
          <div className={styles.heading}>End</div>
        </Col>
      </Row>
      {Object.entries(availabilityObj).map((availability, key) => (
        <AvailabilityRow
          key={key}
          day={availability[0]}
          availability={availability[1]}
          validateData={validateData}
          setDayConfig={setDayConfig}
        />
      ))}
    </div>
  );
});

EditAvailability.displayName = 'EditAvailability';

export default EditAvailability;
