class RxCost {
  inNetwork: any;
  outOfNetwork: any;

  constructor() {
    this.inNetwork = {};
    this.outOfNetwork = {};
  }
}

export default RxCost;
