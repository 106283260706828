import { ChangeEvent, FC, useState, useEffect, useMemo } from 'react';

import { Menu, Row, Col, Image, Skeleton } from 'antd';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import DataTable from 'components/DataTable/DataTable';
import Filter from 'components/Filter/Filter';
import { DataColumn } from 'components/DataTable/DataColumn';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import CarrierCreateModal from 'modules/carriers/components/CarrierCreateModal/CarrierCreateModal';
import SearchBar from 'components/SearchBar/SearchBar';

import {
  allOption,
  ALLOWED_INDIVIDUAL_SUB_TYPES,
  EMPLOYER_MEMBER_RESTRICTED,
  MASTER,
} from 'constants/commonConstants';
import { benefitTypes } from 'modules/carriers/constants/carrierConstants';

import TablePagination from 'model/TablePagination';
import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
  getNavContext,
} from 'util/commonUtil';
import BenefitCarrier from 'model/BenefitCarrier';

import {
  clearCarrierCreation,
  getCarrierList,
} from 'modules/carriers/slices/carrierSlice';
import { navContexts } from 'constants/authConstants';
import CustomCarrier from 'modules/carriers/components/CustomCarrier/customCarrier';
import DeleteCarrier from 'modules/carriers/components/DeleteCarrier/DeleteCarrier';

import { getCarrierLibraryType } from 'modules/carriers/components/util/carrierUtil';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import CarrierType from 'modules/carriers/enums/CarrierType';
import { usePermitByUserType } from 'hooks/usePermitByUserType';

import styles from './carriersList.module.less';

type CarrierListProps = {};

const CarrierList: FC<CarrierListProps> = (props: CarrierListProps) => {
  const [searchText, setSearchText] = useState<string>('');
  const [selectedBenefitCategories, setSelectedBenefitCategories] =
    useState<string>('');
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] =
    useState<boolean>(false);
  const { state: stateValue } = useLocation();
  const { userId } = stateValue || ({} as any);

  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );

  const getData = (filters: TablePagination) => {
    if (filters.sorterInfo) {
      const paginationConfigData = {
        sorterInfo: filters.sorterInfo,
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      };
      setPaginationConfig(paginationConfigData);
    } else {
      const paginationConfigData = {
        sorterInfo: {
          columnKey: 'name',
          field: 'name',
          order: 'ascend',
        },
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      } as TablePagination;
      setPaginationConfig(paginationConfigData);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });
  const [selectedCarrier, setSelectedCarrier] = useState<BenefitCarrier>(
    {} as BenefitCarrier
  );
  const navigate = useNavigate();
  const { setCarrier, context, brokerId, employerId, broker, employer } =
    useNavContext();

  const dispatch = useAppDispatch();
  const carrierList = useAppSelector((state) => state.carriers.carrierList);
  const carrierListInProgress = useAppSelector(
    (state) => state.carriers.carrierListInProgress
  );

  const { metadata } = carrierList || {};
  const total = metadata ? metadata.total : '0';
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};
  const params = useParams();
  useEffect(() => {
    setCarrier(null, null, null);
    dispatch(
      getCarrierList(
        convertPaginationConfig(paginationConfig),
        brokerId,
        employerId,
        getNavContext(params) === navContexts.platform
          ? MASTER
          : getNavContext(params),
        selectedBenefitCategories
      )
    );
  }, [
    dispatch,
    employerId,
    paginationConfig,
    selectedBenefitCategories,
    params,
    brokerId,
    setCarrier,
  ]);

  const getBenefitCategoryList = (
    benefitCarrierList: any,
    isDelete?: boolean
  ) => {
    const benefitCategories: any[] = [];
    if (benefitCarrierList) {
      benefitCarrierList.forEach(function (benefitCarrier: any) {
        benefitCategories.push(benefitCarrier);
      });
    }
    if (isDelete) {
      return benefitCategories.map((benefitCategory) => (
        <div
          className={styles.carrierBenefits}
          key={benefitTypes[benefitCategory]?.value}
        >
          {benefitTypes[benefitCategory]?.label}
        </div>
      ));
    } else {
      return benefitCategories
        .map((benefitCategory) => benefitTypes[benefitCategory]?.label)
        .join(', ');
    }
  };

  const convertCarriersForTableRender = (carriers: BenefitCarrier[]): any[] => {
    if (carriers != null) {
      return carriers.map((element) => ({
        key: element.id,
        name: element,
        benefitCategory: (
          <OverflowPopover>
            {getBenefitCategoryList(element.benefitCategories)}
          </OverflowPopover>
        ),
        actions: element,
      }));
    }
    return [];
  };

  const getNavigateUrl = (id: string) => {
    if (context === navContexts.platform) {
      return `/carriers/${id}/info`;
    } else if (context === navContexts.broker) {
      return `/brokers/${brokerId}/carriers/${id}/info`;
    } else {
      return `/brokers/${brokerId}/employers/${employerId}/carriers/${id}/info`;
    }
  };

  const getCarrierListName = () => {
    if (context === navContexts.platform) {
      return 'Master Carrier List';
    } else if (context === navContexts.broker) {
      return `${broker?.name} `;
    } else {
      return `${employer?.name} `;
    }
  };
  const SecuredCarrierDeleteMenu = (carrier: any): any => {
    const deleteMenu = (
      <Menu.Item
        key="brokerInfo"
        onClick={() => {
          setSelectedCarrier(carrier?.carrier);
          setIsDeleteConfirmOpen(true);
        }}
      >
        Delete
      </Menu.Item>
    );
    const securedDeleteMenu = usePermitByUserType(
      deleteMenu,
      ALLOWED_INDIVIDUAL_SUB_TYPES
    );
    if (
      getCarrierLibraryType(params?.employerId, params?.brokerId) !==
      CarrierType.BROKER
    ) {
      return deleteMenu;
    }
    if (!isEmpty(securedDeleteMenu)) {
      return securedDeleteMenu;
    }
    return <></>;
  };

  const carrierActions = (carrier: BenefitCarrier) => {
    return (
      <Menu>
        <Menu.Item
          key="archive"
          onClick={() => {
            setCarrier(carrier.id, carrier.name, null);
            navigate(getNavigateUrl(carrier.id));
          }}
        >
          View
        </Menu.Item>
        {carrier.carrierType ===
          getCarrierLibraryType(params?.employerId, params?.brokerId) &&
          !carrier.carrierSourceDetails && (
            <SecuredCarrierDeleteMenu carrier={carrier} />
          )}
      </Menu>
    );
  };
  const logoSkelton = (
    <div>
      <Skeleton.Input className={styles.logoImg} />
    </div>
  );
  const carrierTableColumns: DataColumn[] = [
    {
      title: 'CARRIER',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      defaultSortOrder: 'ascend',
      ellipsis: {
        showTitle: false,
      },
      sorter: (a: any, b: any) => 0, // this sort is done by backend.
      render: (item: BenefitCarrier) => {
        const { id, name, customCarrier, logoUrl } = item;
        return (
          <span
            className={styles.nameColStyle}
            onClick={() => {
              setCarrier(id, name, null);
              navigate(getNavigateUrl(id));
            }}
          >
            <Image
              src={logoUrl}
              preview={false}
              alt={name}
              className={styles.logoImg}
              placeholder={logoSkelton}
            />
            <span className={styles.carrierName}>
              <OverflowPopover popoverContent={name} maxWidth={200}>
                {name}
              </OverflowPopover>
            </span>
            <>{customCarrier && <CustomCarrier />}</>
          </span>
        );
      },
    },
    {
      title: 'BENEFIT CATEGORIES',
      dataIndex: 'benefitCategory',
      key: 'benefitCategory',
      width: '40%',
      defaultSortOrder: 'ascend',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      render: (item: BenefitCarrier) => {
        return (
          <div className={styles.dropdownWrapper}>
            <SelectDropdown
              overlay={carrierActions(item)}
              className="text-select-action"
            />
          </div>
        );
      },
      align: 'left',
    },
  ];

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setSearchText(_searchText);
    const pageInfo = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText: _searchText },
    };
    debounceLoadData(pageInfo);
  };

  const filterBenefitCategories = () => {
    const allOptions = { label: 'All Benefit Categories', value: allOption };
    const options =
      Object.values(benefitTypes)
        ?.map((item) => ({
          label: item.label,
          value: item.value,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [];
    return [allOptions].concat(options);
  };

  const onSelectItems = (selectedItems: string[]) => {
    const filters = selectedItems.includes(allOption)
      ? ''
      : selectedItems.toString();
    setPaginationConfig({ ...paginationConfig, paginationIndex: 1 });
    return filters;
  };

  const onSelectBenefitCategories = (selectedItems: string[]) => {
    const filters = onSelectItems(selectedItems);
    setSelectedBenefitCategories(filters);
  };

  const getAllCarriersUrl = () => {
    if (params?.brokerId && params?.employerId) {
      return `/brokers/${params.brokerId}/employers/${params.employerId}/carriers`;
    } else if (params.brokerId) {
      return `/brokers/${params.brokerId}/carriers`;
    } else {
      return `/carriers`;
    }
  };

  const carrierCreateButton = (
    <PageActionButton
      type="primary"
      onClick={() => {
        dispatch(clearCarrierCreation());
        setIsModalOpen(true);
      }}
      className={styles.actionButton}
    >
      Add Carrier
    </PageActionButton>
  );

  const employerLevelCarrierCreateButton = usePermitByUserType(
    carrierCreateButton,
    EMPLOYER_MEMBER_RESTRICTED
  );

  const securedCarrierCreateButton = usePermitByUserType(
    carrierCreateButton,
    ALLOWED_INDIVIDUAL_SUB_TYPES
  );

  return (
    <div className={styles.carrierTableWrapper}>
      <Row>
        <Col span={12}>
          <h1 className={styles.titleWrapper}>
            <OverflowPopover>{getCarrierListName()}</OverflowPopover>
            {context !== navContexts.platform && 'Carriers'}
          </h1>
        </Col>
        <Col span={12}>
          {getCarrierLibraryType(params?.employerId, params?.brokerId) ===
          CarrierType.BROKER
            ? securedCarrierCreateButton
            : employerLevelCarrierCreateButton}
        </Col>
      </Row>
      <br />
      <Row className={styles.filterContainer}>
        <SearchBar placeholder="Search Carriers" onChange={handleSearch} />
        <Filter
          options={filterBenefitCategories()}
          placeholder="All Benefit Categories"
          onSelectItems={onSelectBenefitCategories}
          filterValue={userId}
          searchName="Search Benefit Categories"
          selected={[]}
          isFilterClose={isModalOpen}
        />
      </Row>
      <br />
      <DataTable
        currentIndex={paginationConfig.paginationIndex}
        data={convertCarriersForTableRender(carrierList.content)}
        columns={carrierTableColumns}
        pagination={true}
        loading={carrierListInProgress}
        getData={getData}
        total={total}
        pageSize={limit}
      />
      <CarrierCreateModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isEdit={false}
      />
      {isDeleteConfirmOpen && (
        <DeleteCarrier
          isConfirmOpen={isDeleteConfirmOpen}
          cancelModal={() => {
            setIsDeleteConfirmOpen(false);
          }}
          isBenefitCarrier={!!params.benefitCategory}
          benefitCategories={getBenefitCategoryList(
            selectedCarrier?.benefitCategories,
            true
          )}
          carrierIdFromList={selectedCarrier?.id}
          allCarrierUrl={getAllCarriersUrl()}
        />
      )}
    </div>
  );
};
export default CarrierList;
