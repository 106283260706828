import { FC } from 'react';
import DeductiblesOOPMax from 'modules/plans/components/DeductiblesOOPMax/DeductiblesOOPMax';

type DeductiblesOOPMaxSBCWrapperProps = {
  wrappedRef?: React.RefObject<any>;
  onChange: Function;
  isTouched: boolean;
};

const DeductiblesOOPMaxSBCWrapper: FC<DeductiblesOOPMaxSBCWrapperProps> = (
  props: DeductiblesOOPMaxSBCWrapperProps
) => {
  const { onChange, isTouched, wrappedRef } = props;

  return (
    <DeductiblesOOPMax
      onChange={onChange}
      isTouched={isTouched}
      ref={wrappedRef}
    />
  );
};

export default DeductiblesOOPMaxSBCWrapper;
