import { useRef } from 'react';
import { DeepPartial } from '@reduxjs/toolkit';

import { useAppDispatch } from 'hooks/redux';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import DentalPlanDocumentUPWrapper from 'modules/plans/dental/components/PlanDocuments/PlanDocumentsUPWrapper';
import MedicalPlanDocumentUPWrapper from 'modules/plans/medical/components/PlanDocuments/PlanDocumentsUPWrapper';
import VisionPlanDocumentUPWrapper from 'modules/plans/vision/components/PlanDocuments/PlanDocumentsUPWrapper';
import {
  updateCurrentSelectedPlan,
  uploadTemporaryDocument,
} from 'modules/renewals/slices/addProposalToPlanYearSlice';

import { FILE_IS_IN_QUEUE_WARNING } from 'modules/renewals/constants/addToPlanYearConstants';

import { Plan } from 'model/plans/Plan';
import { IAddProposalToPlanYearState } from 'modules/renewals/types/renewalsTypes';

import styles from './PlanDocuments.module.less';

type Props = {
  type?: keyof IAddProposalToPlanYearState['plans'];
  state?: DeepPartial<Plan>;
};

const PlanDocuments = ({ type, state }: Props) => {
  const wrappedRef = useRef(null);
  const dispatch = useAppDispatch();

  const hasUploadedDocuments =
    Object.values(state?.documentReferences ?? {}).length > 0;

  const handlePlanChange = (plan: Plan) => {
    dispatch(updateCurrentSelectedPlan(plan));
  };

  const handleFileChange = (document: File, docType: string) => {
    dispatch(uploadTemporaryDocument(document, docType));
  };

  const getPlanDocumentUPWrapper = () => {
    const componentMap: {
      // eslint-disable-next-line
      [key in NonNullable<typeof type>]?: React.ReactNode;
    } = {
      DENTAL: (
        <DentalPlanDocumentUPWrapper
          wrappedRef={wrappedRef}
          onChange={handlePlanChange}
          onFileChange={handleFileChange}
          plan={state}
          isAFPReview={true}
          isOnlyAfpVisible={true}
        />
      ),
      MEDICAL: (
        <MedicalPlanDocumentUPWrapper
          plan={state}
          onPlanChange={handlePlanChange}
          onFileChange={handleFileChange}
          isAFPReview={true}
          isOnlyAfpVisible={true}
        />
      ),
      VISION: (
        <VisionPlanDocumentUPWrapper
          onChange={handlePlanChange}
          onFileChange={handleFileChange}
          wrappedRef={wrappedRef}
          isAFPReview={true}
          plan={state}
          isOnlyAfpVisible={true}
        />
      ),
    };

    return type !== undefined && componentMap[type];
  };

  return (
    <div className={styles.wrapper}>
      {false && hasUploadedDocuments && (
        <FixedAlertMessage type="warning" message={FILE_IS_IN_QUEUE_WARNING} />
      )}
      {getPlanDocumentUPWrapper()}
    </div>
  );
};

export default PlanDocuments;
