import { FC, useEffect, useState } from 'react';
import { Select } from 'antd';
import { useLocation } from 'react-router-dom';

import PlanYear from 'model/PlanYear';
import SelectOptions from 'components/SelectOptions/SelectOptions';
import { useAppSelector } from 'hooks/redux';

import { SET_CURRENT_PLAN_YEAR } from 'modules/employers/constants/afpClonePlansConstants';

import styles from './planListPlanYearDropDown.module.less';

type PlanListPlanYearDropDownProps = {
  onPlanYearSelect: (planYear: PlanYear | null | undefined) => void;
};

const PlanListPlanYearDropDown: FC<PlanListPlanYearDropDownProps> = (props) => {
  const { onPlanYearSelect } = props;
  const { Option } = Select;
  const { planYearsList } = useAppSelector(
    (state) => state.benguide.employerSlice
  );
  const location = useLocation();
  const [selected, setSelected] = useState<PlanYear | null>();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const currentPlanYearSelected: string | null = queryParams.get(
      SET_CURRENT_PLAN_YEAR.URL_PRAM_NAME
    );

    if (currentPlanYearSelected === SET_CURRENT_PLAN_YEAR.VALUE) {
      const currentPlanYear = planYearsList.find((item) => item?.current);
      setSelected(currentPlanYear);
      if (onPlanYearSelect) {
        onPlanYearSelect(currentPlanYear);
      }
    }
    if (planYearsList && planYearsList.length !== 0 && !selected) {
      setSelected(planYearsList[0]);
      if (onPlanYearSelect) {
        onPlanYearSelect(planYearsList[0]);
      }
    }
  }, [onPlanYearSelect, planYearsList, selected, location]);

  const onValueChange = (planYearId: string) => {
    const filteredData = planYearsList.filter((t) => {
      return t.id === planYearId;
    });
    if (filteredData && filteredData.length === 1) {
      setSelected(filteredData[0]);
      if (onPlanYearSelect) {
        onPlanYearSelect(filteredData[0]);
      }
    }
  };

  return (
    <div className={styles.planYearSelectionWrapper}>
      {planYearsList && planYearsList.length !== 0 && (
        <>
          <div className={styles.planYearLabel}>
            <span>Plan Year:</span>
          </div>
          <SelectOptions
            onChange={onValueChange}
            value={selected?.name}
            dropdownMatchSelectWidth={false}
          >
            {planYearsList.map((value) => (
              <Option value={value.id} key={value.id}>
                {value.name}
              </Option>
            ))}
          </SelectOptions>
        </>
      )}
    </div>
  );
};

export default PlanListPlanYearDropDown;
