import { FC } from 'react';

import styles from './loadingStrip.module.less';

type LoadingStripProps = {
  value?: string;
};

const LoadingStrip: FC<LoadingStripProps> = (props: LoadingStripProps) => {
  const { value } = props;
  return (
    <div className={styles.loadingContainer}>
      {value}
      <div className={styles.dot1}></div>
      <div className={styles.dot2}></div>
      <div className={styles.dot3}></div>
    </div>
  );
};

export default LoadingStrip;
