import React, { useEffect, useState } from 'react';

import { Divider, Popover, Row, Spin } from 'antd';
import { useParams } from 'react-router-dom';

import OfferCardOverview from 'modules/renewals/components/helperComponents/OfferCardOverview/OfferCardOverview';
import RenewalCommonMainHeader from 'modules/renewals/components/helperComponents/RenewalCommonMainHeader/RenewalCommonMainHeader';
import RenewalCommonSubHeader from 'modules/renewals/components/helperComponents/RenewalCommonSubHeader/RenewalCommonSubHeader';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import CreditsDiscountsDetailsFooter from 'modules/renewals/components/CreditsDiscountsDetailsFooter/CreditsDiscountsDetailsFooter';
import FromCurrentDetailsFooter from 'modules/renewals/components/FromCurrentDetailsFooter/FromCurrentDetailsFooter';
import FromCurrentPercentage from 'modules/renewals/components/helperComponents/FromCurrentPercentage/FromCurrentPercentage';
import MultipleCreditsDiscountsDescriptionModal from 'modules/renewals/components/MultipleCreditsDiscountsDescriptionModal/MultipleCreditsDiscountsDescriptionModal';
import CreditIncludedExcludedModal from 'modules/renewals/components/CreditIncludedExcludedModal/CreditIncludedExcludedModal';

import {
  useGetProposalBenefitTypeDetailsQuery,
  useLazyGetProposalCreditsQuery,
  useLazyGetProposalDiscountsQuery,
  useLazyGetProposalSummaryQuery,
} from 'modules/renewals/slices/proposalSlice';
import {
  formatHeaderValues,
  getMultipleCreditDiscountModalData,
  getTypeIcon,
  hasCreditsDiscountsData,
  isFromCurrentExist,
} from 'modules/renewals/utils/renewalsUtils';
import {
  CREDITS_INCLUSION,
  LIFE_DISABILITY_BENEFITS,
  LIFE_INCLUDING_PLAN_TYPES,
  MDV_PLANS,
  OFFER_BENEFIT_TYPES,
  OFFER_BENEFIT_TYPES_PROPOSAL_DETAIL,
  OFFER_TYPE_NAMES,
  PREMIUMS_PRIORITY_ORDER,
  PROPOSAL_DETAILED_RENEWAL_TYPES,
  STD,
} from 'modules/renewals/constants/renewalsConstants';
import {
  CreditsInclusionType,
  ICreditsDiscountsDescriptionItem,
  IProposalBenefitsPageOverviewData,
  OfferBenefitType,
  ProposalOfferDetailsCardDataType,
} from 'modules/renewals/types/renewalsTypes';
import ProposalOfferDetailsCard from 'modules/renewals/components/ProposalOfferDetailsCard/ProposalOfferDetailsCard';
import ProposalOfferDetailSideBar from 'modules/renewals/components/ProposalOfferDetailSideBar/ProposalOfferDetailSideBar';

import { RowType } from 'model/ModelingBaseType';
import styles from 'modules/renewals/pages/proposals/ProposalsOfferDetails/ProposalsOfferDetails.module.less';
import { currencyFormatterWithoutZeros } from 'util/commonUtil';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setProposalsCreditMode } from 'modules/renewals/slices/offerReducerSlice';
import FundingType from 'modules/plans/enums/FundingType';

const ProposalsOfferDetails = () => {
  const {
    brokerId = '',
    employerId = '',
    proposalId = '',
    renewalsType = '',
  } = useParams();

  const dispatch = useAppDispatch();
  const creditsInclusion = useAppSelector(
    (state) => state.renewals.offers.selectedProposalsCreditMode
  );

  const [benefitType, setBenefitType] = useState<string>(
    renewalsType.toUpperCase()
  );
  const [overviewData, setOverviewData] =
    useState<IProposalBenefitsPageOverviewData>({});
  const [isCreditsDescriptionModalOpen, setIsCreditsDescriptionModalOpen] =
    useState<boolean>(false);
  const [creditsAndDiscountsData, setCreditsAndDiscountsData] = useState<{
    credits?: ICreditsDiscountsDescriptionItem[];
    discounts?: ICreditsDiscountsDescriptionItem[];
  }>({});
  const [showCreditModal, setShowCreditModal] = useState<{
    visible: boolean;
    type: CreditsInclusionType;
  }>({
    visible: false,
    type: CREDITS_INCLUSION.EXCLUDED.value,
  });

  const [openDrawerData, setOpenDrawerData] = useState({
    visible: false,
    offerData: null,
  });

  const {
    data: planDetailsData,
    isFetching: planDetailsIsFetching,
    isSuccess: planDetailsIsSuccess,
    isError: planDetailsIsError,
    refetch: refetchPlanDetailsData,
  } = useGetProposalBenefitTypeDetailsQuery({
    employerId: employerId,
    proposalId: proposalId,
    category: PROPOSAL_DETAILED_RENEWAL_TYPES?.[renewalsType],
  });

  const {
    data: planDetailsLTDData,
    isFetching: planDetailsLTDIsFetching,
    isError: planDetailsIsLTDError,
    refetch: refetchPlanDetailsLTDData,
  } = useGetProposalBenefitTypeDetailsQuery(
    {
      employerId: employerId,
      proposalId: proposalId,
      category: PROPOSAL_DETAILED_RENEWAL_TYPES.std,
    },
    {
      skip:
        renewalsType?.toUpperCase() !== PROPOSAL_DETAILED_RENEWAL_TYPES.life,
    }
  );
  const [getProposalDiscount, { data: rawProposalDiscountData }] =
    useLazyGetProposalDiscountsQuery();
  const [getProposalCredit, { data: rawProposalCreditData }] =
    useLazyGetProposalCreditsQuery();
  const [getProposalSummary, { data: rawProposalSummaryData }] =
    useLazyGetProposalSummaryQuery();

  const {
    data: planDetailsSTDData,
    isFetching: planDetailsSTDIsFetching,
    isError: planDetailsSTDIsError,
    refetch: refetchPlanDetailsSTDData,
  } = useGetProposalBenefitTypeDetailsQuery(
    {
      employerId: employerId,
      proposalId: proposalId,
      category: PROPOSAL_DETAILED_RENEWAL_TYPES.ltd,
    },
    {
      skip:
        renewalsType?.toUpperCase() !== PROPOSAL_DETAILED_RENEWAL_TYPES.life,
    }
  );

  useEffect(() => {
    if (renewalsType?.toUpperCase() === PROPOSAL_DETAILED_RENEWAL_TYPES.life) {
      refetchPlanDetailsData();
      refetchPlanDetailsLTDData();
      refetchPlanDetailsSTDData();
    } else {
      refetchPlanDetailsData();
    }
    // eslint-disable-next-line
  }, [renewalsType]);

  useEffect(() => {
    getProposalDiscount({ proposalId: String(proposalId) });
    getProposalCredit({ proposalId: String(proposalId) });
    getProposalSummary({
      proposalId: String(proposalId),
      employerId: String(employerId),
    });
    setBenefitType(renewalsType.toUpperCase());
    setOverviewData({});
    // eslint-disable-next-line
  }, []);

  const handleViewDescriptionClick = () => {
    const offerBenefitKeys = [benefitType];

    if (offerBenefitKeys?.includes('LIFE')) {
      offerBenefitKeys.push(...LIFE_DISABILITY_BENEFITS);
    }
    const data = getMultipleCreditDiscountModalData(
      rawProposalCreditData,
      rawProposalDiscountData,
      offerBenefitKeys as any
    );
    if (data.credits.length !== 0 || data.discounts.length !== 0) {
      setCreditsAndDiscountsData(data);
      setIsCreditsDescriptionModalOpen(true);
    }
  };

  // drawer show function
  const handlePlanDetailsClick = (data: any) => {
    setOpenDrawerData({ visible: true, offerData: data });
  };

  const fromCurrentFooter = (dollar: number, percentage: number) => {
    return (
      <Row>
        <span className={styles.footerFromCurrentDollar}>
          {formatHeaderValues(dollar, 'CURRENCY', true)}
        </span>
        {!isFromCurrentExist(percentage) ? (
          <FromCurrentPercentage
            percentage={percentage}
            showBrackets={true}
            decimalPlaces={2}
          />
        ) : (
          <span className={styles.offset}>(N/A)</span>
        )}{' '}
        <span className={styles.fromCurrent}>from Current</span>
      </Row>
    );
  };

  const getPlanContributions = (): any[] => {
    const getContributions = (data: any) => {
      const contributions = data?.planContributions ?? [];
      return contributions.map((item: any) => ({
        ...item,
        category: data?.category,
      }));
    };
    const parent = getContributions(planDetailsData);
    const ltd = getContributions(planDetailsLTDData);
    const std = getContributions(planDetailsSTDData);

    return [...parent, ...ltd, ...std];
  };

  const getOverViewValues = () => {
    const isCreditsIncluded = creditsInclusion === 'INCLUDED';

    const totalAnnual = isCreditsIncluded
      ? (planDetailsData?.annualContributionDiscounted?.totalContribution ??
          0) +
        (planDetailsLTDData?.annualContributionDiscounted?.totalContribution ??
          0) +
        (planDetailsSTDData?.annualContributionDiscounted?.totalContribution ??
          0)
      : (planDetailsData?.annualContributionDefault?.totalContribution ?? 0) +
        (planDetailsLTDData?.annualContributionDefault?.totalContribution ??
          0) +
        (planDetailsSTDData?.annualContributionDefault?.totalContribution ?? 0);

    const totalAnnualFromCurrent = isCreditsIncluded
      ? (planDetailsData?.comparisons?.discountedDiff ?? 0) +
        (planDetailsLTDData?.comparisons?.discountedDiff ?? 0) +
        (planDetailsSTDData?.comparisons?.discountedDiff ?? 0)
      : (planDetailsData?.comparisons?.totalImpact ?? 0) +
        (planDetailsLTDData?.comparisons?.totalImpact ?? 0) +
        (planDetailsSTDData?.comparisons?.totalImpact ?? 0);

    const employerCost = isCreditsIncluded
      ? (planDetailsData?.annualContributionDiscounted?.totalErContribution ??
          0) +
        (planDetailsLTDData?.annualContributionDiscounted
          ?.totalErContribution ?? 0) +
        (planDetailsSTDData?.annualContributionDiscounted
          ?.totalErContribution ?? 0)
      : (planDetailsData?.annualContributionDefault?.totalErContribution ?? 0) +
        (planDetailsLTDData?.annualContributionDefault?.totalErContribution ??
          0) +
        (planDetailsSTDData?.annualContributionDefault?.totalErContribution ??
          0);
    const employerCostFromCurrent = isCreditsIncluded
      ? (planDetailsData?.comparisons?.discountedErDiff ?? 0) +
        (planDetailsLTDData?.comparisons?.discountedErDiff ?? 0) +
        (planDetailsSTDData?.comparisons?.discountedErDiff ?? 0)
      : (planDetailsData?.comparisons?.totalErImpact ?? 0) +
        (planDetailsLTDData?.comparisons?.totalErImpact ?? 0) +
        (planDetailsSTDData?.comparisons?.totalErImpact ?? 0);

    return {
      totalAnnual: totalAnnual,
      totalAnnualFromCurrent: totalAnnualFromCurrent,
      totalAnnualFromCurrentPercent:
        totalAnnual - totalAnnualFromCurrent == 0
          ? 0
          : (totalAnnualFromCurrent * 100) /
            (totalAnnual - totalAnnualFromCurrent),
      employeeCost:
        (planDetailsData?.annualContributionDefault?.totalEePremium ?? 0) +
        (planDetailsLTDData?.annualContributionDefault?.totalEePremium ?? 0) +
        (planDetailsSTDData?.annualContributionDefault?.totalEePremium ?? 0),
      employeeCostFromCurrent: planDetailsData?.comparisons?.totalEeImpact ?? 0,
      employeeCostFromCurrentPercent:
        planDetailsData?.comparisons?.totalEeImpactPercent ?? 0,
      employerCost: employerCost,
      employerCostFromCurrent: employerCostFromCurrent,
      employerCostFromCurrentPercent:
        employerCost - employerCostFromCurrent == 0
          ? 0
          : (employerCostFromCurrent * 100) /
            (employerCost - employerCostFromCurrent),
      creditsAndDiscountsValue: getCreditsAndDiscountsValue(),
    };
  };

  const getCreditsAndDiscountsValue = () => {
    const predicate = (item: any): boolean =>
      benefitType === (OFFER_TYPE_NAMES.LIFE_AD as OfferBenefitType)
        ? LIFE_DISABILITY_BENEFITS.includes(item.category)
        : item.category === benefitType;
    const filteredDiscountsValue = rawProposalDiscountData?.discounts
      ?.filter(predicate)
      .reduce((prev = 0, current) => prev + (current.discountTotal || 0), 0);
    const filteredCreditsValue = rawProposalCreditData?.offerCredits
      ?.filter(predicate)
      .reduce((prev = 0, current) => prev + (current.credit || 0), 0);
    if (filteredDiscountsValue || filteredCreditsValue) {
      return (filteredCreditsValue || 0) + (filteredDiscountsValue || 0);
    } else {
      return undefined;
    }
  };

  const getCarriers = () => {
    const carriers = [] as string[];

    getPlanContributions()?.map((item: any) =>
      carriers.includes(item?.carrierName)
        ? ''
        : carriers.push(item?.carrierName)
    );
    return carriers
      ?.toString()
      .replace(/,(?=[^,]+$)/, ' and ')
      .replace(',', ', ');
  };

  const calculateERSplits = (tierContribution: RowType) => {
    const total =
      parseFloat(tierContribution?.eePremium) +
      parseFloat(tierContribution?.erPremium);
    return Math.round((parseFloat(tierContribution?.erPremium) / total) * 100);
  };

  const calculateEESplits = (tierContribution: RowType) => {
    const erSplit = calculateERSplits(tierContribution);
    return 100 - erSplit;
  };

  const reArrangeData = (data: any) => {
    const rowData = Object.keys(data?.tierContributionsDefault ?? {}).map(
      (item: any) => {
        return {
          type: data?.tierContributionsDefault?.[item]?.planType ?? '',
          name: data?.tierContributionsDefault?.[item]?.tierName ?? '',
          enrollment: LIFE_INCLUDING_PLAN_TYPES.includes(
            data?.tierContributionsDefault?.[item]?.insuranceType
          )
            ? data.category === STD
              ? data?.tierContributionsDefault?.[item]?.totalEligibleEmployees
              : data?.tierContributionsDefault?.[item]
                  ?.totalEligibleEnrollments || 0
            : data?.tierContributionsDefault?.[item]?.enrollments || 0,
          splits: `${
            calculateERSplits(data?.tierContributionsDefault?.[item]) ?? 0
          }/${calculateEESplits(data?.tierContributionsDefault?.[item]) ?? 0}`,
          erPaysPerMonth: data?.tierContributionsDefault?.[item]?.erPremium,
          erHsaContributions: parseFloat(
            data?.tierContributionsDefault?.[item]?.hsaContribution
          ),
          eePaysPerMonth: {
            value: data?.tierContributionsDefault?.[item]?.eePremium,
            fromCurrent: data?.tierContributionsDefault?.[item]?.diffEePremium,
          },
          totalMonthlyCost:
            data?.tierContributionsDefault?.[item]?.contribution,
          volume: data?.tierContributionsDefault?.[item]?.volume,
          rate: data?.tierContributionsDefault?.[item]?.lifeRate,
          lifeRate: data?.tierContributionsDefault?.[item]?.lifeRate,
          addRate: data?.tierContributionsDefault?.[item]?.basicLifeAdRate,
          hraFaceValue: data?.tierContributionsDefault?.[item]?.hraFaceValue,
          hraContribution:
            data?.tierContributionsDefault?.[item]?.hraContribution,
          premium: data?.tierContributionsDefault?.[item]?.premium,
          administrationFee:
            data?.tierContributionsDefault?.[item]?.administrationFee,
          annualEstimatedClaims:
            data?.tierContributionsDefault?.[item]?.annualEstimatedClaims,
        };
      }
    );

    rowData?.sort(
      (x: any, y: any) =>
        PREMIUMS_PRIORITY_ORDER?.indexOf(x?.name) -
        PREMIUMS_PRIORITY_ORDER?.indexOf(y?.name)
    );

    return {
      type: MDV_PLANS.includes(renewalsType.toUpperCase())
        ? renewalsType.toUpperCase()
        : PROPOSAL_DETAILED_RENEWAL_TYPES?.[data?.planType.toLowerCase()],
      id: data?.id,
      title: data?.planName,
      tableData: {
        imageUrl: data?.carrierLogoUrl,
        rowData: rowData,
      },
      isHra: data?.hraCompatible,
      isHsa: data?.hsaCompatible,
      hraUtilization: data?.hraUtilization,
      summaryData: {
        erPaysPerMonth: {
          month: data?.monthlyContributionDefault?.totalErPremium,
          year: data?.annualContributionDefault?.totalErPremium,
        },
        eePaysPerMonth: {
          month: data?.monthlyContributionDefault?.totalEePremium,
          year: data?.annualContributionDefault?.totalEePremium,
        },
        totalMonthlyCost: {
          month:
            data?.monthlyContributionDefault?.totalContribution -
            (data?.monthlyContributionDefault?.totalHraContribution ?? 0),
          year:
            data?.annualContributionDefault?.totalContribution -
            (data?.annualContributionDefault?.totalHraContribution ?? 0),
        },
        erHsaContributions: {
          month: data?.monthlyContributionDefault?.totalHsaContribution,
          year: data?.annualContributionDefault?.totalHsaContribution,
        },
        premiumContribution: {
          month: data?.monthlyContributionDefault?.totalPremium,
          year: data?.annualContributionDefault?.totalPremium,
        },
        erHraContributions: {
          month: data?.monthlyContributionDefault?.totalHraContribution,
          year: data?.annualContributionDefault?.totalHraContribution,
        },
        erHraFaceValueContributions: {
          month: calculateTotalWithUtilization(data, false),
          year: calculateTotalWithUtilization(data, true),
        },
      },
      newPlan: data?.newPlan,
      planId: data?.planId,
      stdFundingType: data?.fundingType
        ? data?.fundingType
        : FundingType.FULLY_INSURED,
      planYearId: rawProposalSummaryData?.planYearId,
    } as ProposalOfferDetailsCardDataType;
  };

  const calculateTotalWithUtilization = (
    data: any,
    isAnnual: boolean
  ): number => {
    const totalValue: number = Object.keys(
      data?.tierContributionsDefault || {}
    ).reduce(
      (total, key) =>
        total +
          (data?.tierContributionsDefault?.[key]?.hraFaceValue ?? 0) *
            data?.tierContributionsDefault?.[key]?.enrollments ?? 0,
      0
    );
    return isAnnual ? totalValue : totalValue / 12;
  };

  const onConfirmCreditSave = () => {
    dispatch(setProposalsCreditMode(showCreditModal.type));
    setShowCreditModal({
      visible: false,
      type:
        creditsInclusion === CREDITS_INCLUSION.INCLUDED.value
          ? CREDITS_INCLUSION.EXCLUDED.value
          : CREDITS_INCLUSION.INCLUDED.value,
    });
  };

  if (
    planDetailsIsFetching ||
    !planDetailsIsSuccess ||
    planDetailsIsError ||
    planDetailsLTDIsFetching ||
    planDetailsIsLTDError ||
    planDetailsSTDIsFetching ||
    planDetailsSTDIsError
  ) {
    return <Spin />;
  }

  const isHra = (): boolean => getHraData() !== 0;
  const isHsa = (): boolean => getHsaData() !== 0;

  const getHsaData = (): number => {
    return (
      planDetailsData?.annualContributionDefault?.totalHsaContribution ?? 0
    );
  };

  const getHraData = (): number => {
    return (
      planDetailsData?.annualContributionDefault?.totalHraContribution ?? 0
    );
  };

  return (
    <>
      <div className={styles.proposalsOfferDetails}>
        <Row justify="space-between">
          <div className={styles.titleSection}>
            <BreadcrumbNavigation
              breadCrumbData={[
                {
                  link: `/brokers/${brokerId}/employers/${employerId}/renewals/proposals/details/${proposalId}`,
                  name: 'Proposal',
                },
              ]}
            />
            <RenewalCommonMainHeader
              mainText={`${
                OFFER_BENEFIT_TYPES_PROPOSAL_DETAIL[benefitType]
              } - ${getCarriers()}`}
            />
          </div>
        </Row>
        <Row
          justify="space-between"
          className={styles.overviewSection}
          wrap={false}
        >
          <OfferCardOverview
            valueSizeMagnified={true}
            className={styles.overviewItem}
            headerText="Total Benefits Cost"
            value={formatHeaderValues(
              getOverViewValues()?.totalAnnual,
              'CURRENCY',
              false
            )}
            footerElement={
              <div>
                <div>
                  {fromCurrentFooter(
                    getOverViewValues()?.totalAnnualFromCurrent,
                    getOverViewValues()?.totalAnnualFromCurrentPercent
                  )}
                </div>
                <div className={styles.creditDropdownWrapper}>
                  <FromCurrentDetailsFooter
                    value={overviewData.totalBenefitsCost?.differenceValue}
                    percentage={
                      overviewData.totalBenefitsCost?.differencePercentage
                    }
                    showCreditsDropdown
                    creditsInclusion={creditsInclusion}
                    onCreditsInclusionChange={(value: any) => {
                      setShowCreditModal({ visible: true, type: value });
                    }}
                  />
                </div>
              </div>
            }
          />
          <OfferCardOverview
            valueSizeMagnified={true}
            className={styles.overviewItem}
            headerText={
              <span>
                Employer Cost{' '}
                {isHsa() && isHra() ? (
                  <Popover
                    content={
                      <>
                        <span>
                          ER HSA Funding :{' '}
                          {currencyFormatterWithoutZeros(getHsaData())}
                        </span>
                        <br />
                        <span>
                          ER HRA Cost :{' '}
                          {currencyFormatterWithoutZeros(getHraData())}
                        </span>
                      </>
                    }
                    overlayClassName={'popoverInner recommendedtooltip'}
                  >
                    <span className={styles.inclErHsaText}>
                      (Incl. ER HSA/HRA)
                    </span>
                  </Popover>
                ) : // only hra exist
                isHra() ? (
                  <Popover
                    content={`ER HRA Cost : ${currencyFormatterWithoutZeros(
                      getHraData()
                    )}`}
                    overlayClassName={'popoverInner recommendedtooltip'}
                  >
                    <span className={styles.inclErHsaText}>(Incl. ER HRA)</span>
                  </Popover>
                ) : (
                  // only hsa
                  isHsa() && (
                    <Popover
                      content={`ER HSA Funding : ${currencyFormatterWithoutZeros(
                        getHsaData()
                      )}`}
                      overlayClassName={'popoverInner recommendedtooltip'}
                    >
                      <span className={styles.inclErHsaText}>
                        (Incl. ER HSA)
                      </span>
                    </Popover>
                  )
                )}
              </span>
            }
            value={formatHeaderValues(
              getOverViewValues()?.employerCost,
              'CURRENCY',
              false
            )}
            footerElement={fromCurrentFooter(
              getOverViewValues()?.employerCostFromCurrent,
              getOverViewValues()?.employerCostFromCurrentPercent
            )}
          />
          {MDV_PLANS.includes(renewalsType.toUpperCase()) ? (
            <OfferCardOverview
              valueSizeMagnified={true}
              className={`${
                benefitType === 'LIFE_AND_DISABILITY'
                  ? styles.visibilityHidden
                  : ''
              } ${styles.overviewItem}`}
              headerText="Employee Cost"
              value={formatHeaderValues(
                planDetailsData?.annualContributionDefault?.totalEePremium || 0,
                'CURRENCY',
                false
              )}
              footerElement={fromCurrentFooter(
                planDetailsData?.comparisons?.totalEeImpact || 0,
                planDetailsData?.comparisons?.totalEeImpactPercent || 0
              )}
            />
          ) : (
            ''
          )}
          <OfferCardOverview
            valueSizeMagnified={true}
            className={styles.overviewItem}
            headerText="Credits & Discounts"
            value={
              getOverViewValues()?.creditsAndDiscountsValue === undefined
                ? '-'
                : formatHeaderValues(
                    getOverViewValues()?.creditsAndDiscountsValue as any,
                    'CURRENCY',
                    false
                  )
            }
            footerElement={
              <CreditsDiscountsDetailsFooter
                showViewDescription={hasCreditsDiscountsData(
                  rawProposalCreditData,
                  rawProposalDiscountData,
                  benefitType as any
                )}
                onViewDescriptionClick={() => handleViewDescriptionClick()}
              />
            }
          />
          {MDV_PLANS.includes(renewalsType.toUpperCase()) ? (
            ''
          ) : (
            <div className={styles.overviewItem}></div>
          )}
        </Row>
        <Row justify="space-between" align="top">
          <RenewalCommonSubHeader
            subText={
              <span className={styles.subHeader}>
                <img
                  className={styles.subHeaderIcon}
                  src={
                    getTypeIcon(
                      OFFER_BENEFIT_TYPES[benefitType as OfferBenefitType]
                    ).iconDisabled
                  }
                  alt="benefit type icon"
                />
                {`${OFFER_BENEFIT_TYPES_PROPOSAL_DETAIL[benefitType]} Plans (${
                  getPlanContributions()?.length
                })`}
              </span>
            }
          />
        </Row>
        <Divider />
        <div className={styles.cardSection}>
          {getPlanContributions()?.map((data: any, idx: number) => {
            return (
              <ProposalOfferDetailsCard
                key={idx}
                data={reArrangeData(data)}
                type={renewalsType?.toUpperCase()}
                onPlanDetailsClick={() => handlePlanDetailsClick(data)}
              />
            );
          })}
        </div>
      </div>

      {/* Drawer Component */}
      <ProposalOfferDetailSideBar
        visible={openDrawerData.visible}
        onClose={() => setOpenDrawerData({ visible: false, offerData: null })}
        offerData={openDrawerData.offerData}
      />
      <MultipleCreditsDiscountsDescriptionModal
        data={creditsAndDiscountsData}
        isOpen={isCreditsDescriptionModalOpen}
        setIsOpen={setIsCreditsDescriptionModalOpen}
      />
      <CreditIncludedExcludedModal
        type={showCreditModal.type}
        visible={showCreditModal.visible}
        onClose={() =>
          setShowCreditModal({
            visible: false,
            type:
              creditsInclusion === CREDITS_INCLUSION.INCLUDED.value
                ? CREDITS_INCLUSION.EXCLUDED.value
                : CREDITS_INCLUSION.INCLUDED.value,
          })
        }
        onConfirm={onConfirmCreditSave}
      />
    </>
  );
};

export default ProposalsOfferDetails;
