import React from 'react';
import { Col, Form, Input, notification } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FORMAT_VALIDATE, isRichTextEditorEmpty } from 'util/commonUtil';
import { getFileUploadErrorMessages } from 'util/fileUtil';

import InputEmailChip from 'components/InputEmailChip/InputEmailChip';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import InputFileUpload from 'components/InputFileUpload/InputFileUpload';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';

import { issueAllowedFileTypes } from 'modules/renewals/utils/soldConfirmationUtil';
import { EmailInputStateProps } from 'modules/renewals/components/soldConfirmation/SoldConfirmationModal/SoldConfirmationModal';
import styles from './sendConfirmationMailStep.module.less';

type Props = {
  emailBodyValue: any;
  toEmailInputState: any;
  ccEmailInputState: any;
  handleGetToEmailList: ({
    emailList,
    isValidList,
    isEmptyList,
    defaultMailList,
  }: Partial<EmailInputStateProps>) => void;
  handleGetCCEmailList: ({
    emailList,
    isValidList,
    isEmptyList,
    defaultMailList,
  }: Partial<EmailInputStateProps>) => void;
  isEmailBodyEmpty: boolean;
  setIsEmailBodyEmpty: (value: boolean) => void;
  loggedUser: string;
  handleFileUpload: (fileList: any) => void;
};
type NotificationType = 'success' | 'info' | 'warning' | 'error';
const SendConfirmationMailStep = (props: Props) => {
  const {
    emailBodyValue,
    toEmailInputState,
    ccEmailInputState,
    handleGetToEmailList,
    handleGetCCEmailList,
    isEmailBodyEmpty,
    setIsEmailBodyEmpty,
    loggedUser,
    handleFileUpload,
  } = props;

  // Notification Error Message
  const openNotificationWithIcon = (
    type: NotificationType,
    validateSetting: string
  ) => {
    let description: any = getFileUploadErrorMessages(validateSetting).message;
    if (FORMAT_VALIDATE === validateSetting) {
      description = (
        <Col>
          <span>
            Invalid file type uploaded. Submitted files must be in following
            formats.
          </span>
          <br />
          <span>
            pdf, csv, xlsx, xls, docx, doc, pptx, ppt, png, jpeg, jpg, zip
          </span>
        </Col>
      );
    }
    notification[type]({
      closeIcon: <></>,
      top: 40,
      message: getFileUploadErrorMessages(validateSetting).title,
      description: description,
      icon: <CloseOutlined className={styles.notificationErrorIcon} />,
    });
  };
  return (
    <div className={styles.container}>
      <Form.Item
        name="toList"
        label={<span className={styles.labelText}>To</span>}
        labelAlign="left"
        labelCol={{ xs: 2 }}
        wrapperCol={{ xs: 22 }}
        rules={[
          { required: true, message: 'Please enter an email' },
          {
            message: '',
            validator: (_) => {
              if (!toEmailInputState.isValidList) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error('Invalid email'));
              }
            },
          },
        ]}
        requiredMark="optional"
      >
        <InputEmailChip checkEmpty={true} getEmailList={handleGetToEmailList} />
      </Form.Item>
      <Form.Item
        name="ccList"
        label={<span className={styles.labelText}>Cc</span>}
        labelAlign="left"
        labelCol={{ xs: 2 }}
        wrapperCol={{ xs: 22 }}
        rules={[
          {
            message: '',
            validator: (_) => {
              if (!ccEmailInputState.isValidList) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error('Invalid email'));
              }
            },
          },
        ]}
      >
        <InputEmailChip
          checkEmpty={true}
          getEmailList={handleGetCCEmailList}
          loggedUser={{
            email: loggedUser,
            popoverMessage: `Your email is cc'd to ensure you receive updates about this sold confirmation.`,
          }}
          defaultMailList={ccEmailInputState.defaultMailList}
        />
      </Form.Item>

      <Form.Item
        name="subject"
        label={<span className={styles.labelText}>Subject</span>}
        labelAlign="left"
        labelCol={{ xs: 2 }}
        wrapperCol={{ xs: 22 }}
      >
        <Input className={styles.subjectInput} />
      </Form.Item>

      <FixedAlertMessage
        wrapperClassName={styles.fixedAlert}
        className={styles.alert}
        type="info"
        message="The email thread is for communication purposes only. It is recommended to not change any plan data below as it may conflict with the approved proposal. If you need to make a change to the proposal plan data, please do so in the respective offers or proposals screens."
      />

      <Form.Item
        name="emailBody"
        rules={[
          {
            required: true,
            message: 'Please enter body text.',
          },
          {
            message: 'Please enter body text.',
            validator: (_, value) => {
              if (!isRichTextEditorEmpty(value)) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error('Please enter body text.'));
              }
            },
          },
        ]}
        noStyle
      >
        <RichTextEditor
          onChange={() => setIsEmailBodyEmpty(false)}
          initialValue={emailBodyValue}
          defaultText={emailBodyValue}
          activateDefaultValue={true}
          maxHeight={380}
        />
      </Form.Item>
      {isEmailBodyEmpty && (
        <div className={styles.bodyTextErrorText}>Please enter body text.</div>
      )}

      <Form.Item name="files" noStyle>
        <InputFileUpload
          allowedFileTypes={issueAllowedFileTypes}
          uploadIconLabel="+ Include Attachment to Email"
          maxFileSizeMB={11}
          totalUploadSizeMB={20}
          getFileList={(fileList) => handleFileUpload(fileList)}
          onValidateFails={(validateSetting, validateCondition) => {
            openNotificationWithIcon('error', validateSetting);
          }}
        />
      </Form.Item>
    </div>
  );
};

export default SendConfirmationMailStep;
