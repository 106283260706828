import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Divider, Popover, Row, Switch } from 'antd';
import { cloneDeep, isEmpty } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import reactHtmlParser from 'react-html-parser';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import {
  currencyFormatterNoDecimals,
  currencyFormatterWithoutZeros,
  getKeyFromValue,
} from 'util/commonUtil';
import FromCurrentDetailsFooter from 'modules/renewals/components/FromCurrentDetailsFooter/FromCurrentDetailsFooter';
import CreditsDiscountsDetailsFooter from 'modules/renewals/components/CreditsDiscountsDetailsFooter/CreditsDiscountsDetailsFooter';
import OfferCardOverview from 'modules/renewals/components/helperComponents/OfferCardOverview/OfferCardOverview';
import MultipleCreditsDiscountsDescriptionModal from 'modules/renewals/components/MultipleCreditsDiscountsDescriptionModal/MultipleCreditsDiscountsDescriptionModal';
import RenewalCommonSubHeader from 'modules/renewals/components/helperComponents/RenewalCommonSubHeader/RenewalCommonSubHeader';
import ProposalsBenefitsCard from 'modules/renewals/components/ProposalsBenefitsCard/ProposalsBenefitsCard';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import CreditIncludedExcludedModal from 'modules/renewals/components/CreditIncludedExcludedModal/CreditIncludedExcludedModal';
import EditDescriptionModal from 'modules/renewals/components/EditDescriptionModal/EditDescriptionModal';
import {
  CreditsInclusionType,
  ICreditsDiscountsDescriptionItem,
  IProposalBenefitsCardData,
  IProposalBenefitsPageOverviewData,
  OfferBenefitValueType,
} from 'modules/renewals/types/renewalsTypes';
import {
  CREDITS_INCLUSION,
  DRAFT_OFFER_EXIST_ON_PROPOSAL_BUTTON_TITLE,
  DRAFT_OFFER_EXIST_ON_PROPOSAL_WARNING_MESSAGE,
  DRAFT_OFFER_EXIST_ON_PROPOSAL_WITH_SAME_NAME_WARNING_MESSAGE,
  LIFE_DISABILITY_BENEFITS,
  NOTIFICATION_PUBLISH_TOOLTIP,
  OFFER_BENEFIT_TYPES,
  OFFER_STATUS,
} from 'modules/renewals/constants/renewalsConstants';
import {
  getERTooltipText,
  getMultipleCreditDiscountModalData,
  getProposalBenefitsOverviewData,
  hasCreditsDiscountsData,
  isFromCurrentExist,
  transformContributionsToCardDataList,
} from 'modules/renewals/utils/renewalsUtils';
import {
  useLazyCheckDraftOfferExistForProposalQuery,
  useLazyGetProposalCreditsQuery,
  useLazyGetProposalDiscountsQuery,
  useLazyGetProposalSummaryQuery,
  useProposalStatusUpdateMutation,
  useUpdateProposalMutation,
} from 'modules/renewals/slices/proposalSlice';
import { attachProposal } from 'modules/renewals/slices/proposalCreateStepperSlice';
import { loginTypes } from 'constants/authConstants';
import { isNullOrUndefined } from 'modules/plans/utils';
import { setProposalsCreditMode } from 'modules/renewals/slices/offerReducerSlice';

import pencilIcon from 'assets/images/icon-pencil.svg';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import styles from './summary.module.less';

type SummaryProps = {
  isModalOpen?: boolean;
  proposalIdEdit?: string;
  closeModal: (showConfirmMessage: any) => void;
  isProposalDetailedView?: boolean;
  proposalType?: string;
  setIsPublishedOrDraft: Function;
};

const Summary: FC<SummaryProps> = (props) => {
  const {
    proposalIdEdit,
    closeModal,
    isProposalDetailedView,
    proposalType,
    setIsPublishedOrDraft,
  } = props;

  const dispatch = useAppDispatch();

  const creditsInclusionFromStore = useAppSelector(
    (state) => state.renewals.offers.selectedProposalsCreditMode
  );

  const [overviewData, setOverviewData] =
    useState<IProposalBenefitsPageOverviewData>({});
  const [isCreditsDescriptionModalOpen, setIsCreditsDescriptionModalOpen] =
    useState<boolean>(false);
  const [creditsInclusion, setCreditsInclusion] =
    useState<CreditsInclusionType>(creditsInclusionFromStore);
  const [creditsAndDiscountsData, setCreditsAndDiscountsData] = useState<{
    credits?: ICreditsDiscountsDescriptionItem[];
    additionalCredits?: ICreditsDiscountsDescriptionItem[];
    discounts?: ICreditsDiscountsDescriptionItem[];
  }>({});
  const [proposalsBenefitsDataList, setProposalBenefitsList] = useState<
    IProposalBenefitsCardData[]
  >([]);
  const [getProposalDiscount, { data: rawProposalDiscountData }] =
    useLazyGetProposalDiscountsQuery();
  const [getProposalCredit, { data: rawProposalCreditData }] =
    useLazyGetProposalCreditsQuery();

  const [showCreditModal, setShowCreditModal] = useState<{
    visible: boolean;
    type: CreditsInclusionType;
  }>({
    visible: false,
    type: 'EXCLUDED',
  });
  const [isPublishNotificationActive, setIsPublishNotificationActive] =
    useState<boolean>(false);

  const [showEditDescription, setShowEditDescription] = useState(false);
  const [description, setDescription] = useState<string>('');
  const [erHSAValue, setHSAValue] = useState<number>(0);
  const [erHRAValue, setHRAValue] = useState<number>(0);
  const [publishAsDraft, setPublishAsDraft] = useState<boolean>(false);
  const { employerId } = useParams();
  const [isDraftExistInProposal, setIsDraftExistInProposal] = useState(false);
  const [isDraftNamePublished, setIsDraftNamePublished] = useState(false);

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;

  const proposalId: string = useAppSelector(
    (state: any) => state.proposalOnBoarding.proposal?.payload?.id
  );
  const proposalDetails = useAppSelector(
    (state) => state.proposalOnBoarding.proposal
  );
  const [checkDraftExist] = useLazyCheckDraftOfferExistForProposalQuery();

  const handleNotificationToggle = (val: boolean) => {
    setIsPublishNotificationActive(val);
  };

  const [
    getProposalSummary,
    {
      isSuccess: isSummaryFetchSuccess,
      isLoading: isSummaryFetchLoading,
      data: summaryData,
    },
  ] = useLazyGetProposalSummaryQuery();

  const [
    publishProposal,
    { isSuccess: isPublishSuccess, isLoading: isPublishStatusUpdating, reset },
  ] = useProposalStatusUpdateMutation();

  const [
    updateProposal,
    { isSuccess: isProposalUpdateSuccess, data: updatedProposal },
  ] = useUpdateProposalMutation();

  useEffect(() => {
    getProposalDiscount({ proposalId: String(proposalId) });
    getProposalCredit({ proposalId: String(proposalId) });
    getProposalSummary({
      proposalId: String(proposalId),
      employerId: String(employerId),
    });
    // eslint-disable-next-line
  }, [employerId, proposalId]);

  useEffect(() => {
    if (!isSummaryFetchLoading && isSummaryFetchSuccess && summaryData) {
      setDescription(summaryData.proposalDescription || '');
      setOverviewData(
        getProposalBenefitsOverviewData(
          summaryData,
          creditsInclusion === CREDITS_INCLUSION.INCLUDED.value
        )
      );

      const proposalsBenefitsDataList = transformContributionsToCardDataList(
        summaryData?.bundleContributions || [],
        summaryData.discountSummary,
        rawProposalCreditData?.offerCredits,
        creditsInclusion === CREDITS_INCLUSION.INCLUDED.value
      );
      setProposalBenefitsList(proposalsBenefitsDataList);
      const medicalBundle = summaryData?.bundleContributions?.find(
        (bundle) => bundle.category === 'MEDICAL'
      );
      setHSAValue(
        medicalBundle?.annualContributionDefault?.totalHsaContribution!
      );
      setHRAValue(summaryData?.totalHraCost!);
    }
  }, [
    isSummaryFetchLoading,
    isSummaryFetchSuccess,
    summaryData,
    rawProposalCreditData,
    creditsInclusion,
  ]);

  useEffect(() => {
    if (isPublishSuccess && !isPublishStatusUpdating) {
      closeModal(true);
      !isProposalDetailedView && handleNavigation();
      reset();
    }
    // eslint-disable-next-line
  }, [isPublishSuccess, isPublishStatusUpdating]);

  useEffect(() => {
    if (isProposalUpdateSuccess) {
      dispatch(attachProposal(updatedProposal));
      getProposalSummary({
        proposalId: proposalIdEdit ? proposalIdEdit : String(proposalId),
        employerId: String(employerId),
      });
      setShowEditDescription(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProposalUpdateSuccess]);

  const params = useParams();
  const navigate = useNavigate();

  const handleNavigation = (): void => {
    const proposalStatus = publishAsDraft ? 'Drafts' : OFFER_STATUS.PUBLISHED;

    navigate(
      `/brokers/${params.brokerId}/employers/${params.employerId}/renewals/proposals`,
      { state: { proposalStatus: proposalStatus } }
    );
  };

  const handleViewDescriptionClick = (type?: OfferBenefitValueType) => {
    const offerBenefitKeys =
      type !== undefined
        ? [getKeyFromValue(OFFER_BENEFIT_TYPES, type)]
        : undefined;
    if (offerBenefitKeys?.includes('LIFE_AND_DISABILITY')) {
      offerBenefitKeys.push(...LIFE_DISABILITY_BENEFITS);
    }
    const data = getMultipleCreditDiscountModalData(
      rawProposalCreditData,
      rawProposalDiscountData,
      offerBenefitKeys
    );
    if (
      [data.credits, data.additionalCredits, data.discounts].some(
        (arr) => arr.length !== 0
      )
    ) {
      setCreditsAndDiscountsData(data);
      setIsCreditsDescriptionModalOpen(true);
    }
  };

  const onConfirmCreditSave = () => {
    dispatch(setProposalsCreditMode(showCreditModal.type));
    setCreditsInclusion(showCreditModal.type);
    setShowCreditModal({
      visible: false,
      type:
        creditsInclusion === CREDITS_INCLUSION.INCLUDED.value
          ? CREDITS_INCLUSION.EXCLUDED.value
          : CREDITS_INCLUSION.INCLUDED.value,
    });
  };

  const handleProposalPublish = async () => {
    const draftValidation = await checkDraftExist({
      id: proposalIdEdit ? proposalIdEdit : proposalId,
    });
    const { draftExist = false, publishedExist = false } =
      draftValidation?.data ?? {};
    setIsDraftExistInProposal(draftExist);
    setIsDraftNamePublished(publishedExist);
    if (!draftExist) {
      submitPublishingProposal();
    }
  };

  const submitPublishingProposal = () => {
    setIsDraftExistInProposal(false);
    publishProposal({
      proposalId: proposalIdEdit ? proposalIdEdit : proposalId,
      proposalStatus: OFFER_STATUS.PUBLISHED,
      isNotificationActive: isPublishNotificationActive,
    });
    setPublishAsDraft(false);
    if ((params?.proposalId ?? null) !== null) {
      navigateToDetailsPage();
    }
    setIsPublishedOrDraft(true);
  };

  const navigateToDetailsPage = () => {
    navigate(
      `/brokers/${params.brokerId}/employers/${
        params.employerId
      }/renewals/proposals/details/${
        proposalIdEdit ? proposalIdEdit : proposalId
      }`
    );
  };

  const renderCostsForToolTip = (isEr: boolean): JSX.Element[] => {
    return (
      rawProposalCreditData?.credits
        ?.filter((credit) => credit.type === 'COST')
        .map((credit) => (
          <span key={credit.description}>
            {credit.description}:{' '}
            {currencyFormatterWithoutZeros(
              isEr ? credit.erAmount ?? 0 : credit.eeAmount ?? 0
            )}
            <br />
          </span>
        )) ?? []
    );
  };

  const getErPopoverContent = (): JSX.Element | string | null => {
    const creditContent = renderCostsForToolTip(true);

    if (isHsa() && isHra()) {
      return (
        <>
          <span>
            ER HSA Funding : {currencyFormatterWithoutZeros(erHSAValue)}
          </span>
          <br />
          <span>ER HRA Cost : {currencyFormatterWithoutZeros(erHRAValue)}</span>
          <br />
          {isAdditionalCost() && creditContent}
        </>
      );
    } else if (isHra()) {
      return (
        <>
          ER HRA Cost : {currencyFormatterWithoutZeros(erHRAValue)}
          <br />
          {isAdditionalCost() && creditContent}
        </>
      );
    } else if (isHsa()) {
      return (
        <>
          ER HSA Funding : {currencyFormatterWithoutZeros(erHSAValue)}
          <br />
          {isAdditionalCost() && creditContent}
        </>
      );
    } else if (isAdditionalCost()) {
      return <>{creditContent}</>;
    }
    return null;
  };

  const getEETooltipText = (): string | null => {
    if (isAdditionalCost()) {
      return '(Incl. Additional costs)';
    }
    return null;
  };

  const getEEPopoverContent = (): JSX.Element | string | null => {
    const creditContent = renderCostsForToolTip(false);

    if (isAdditionalCost()) {
      return <>{creditContent}</>;
    }

    return null;
  };

  const isHra = (): boolean =>
    erHRAValue !== 0 && !isNullOrUndefined(erHRAValue);
  const isHsa = (): boolean =>
    erHSAValue !== 0 && !isNullOrUndefined(erHSAValue);
  const isAdditionalCost = (): boolean =>
    (rawProposalCreditData?.costExist ?? false) &&
    creditsInclusion === 'INCLUDED';

  return (
    <div className={styles.container}>
      <div className={styles.dataOverview}>
        <Row justify="space-between" wrap={false}>
          <Col lg={5}>
            <OfferCardOverview
              headerText="Total Benefits Cost"
              value={
                overviewData?.totalBenefitsCost?.value
                  ? currencyFormatterNoDecimals(
                      overviewData.totalBenefitsCost.value || 0
                    )
                  : '-'
              }
              footerElement={
                <FromCurrentDetailsFooter
                  value={overviewData.totalBenefitsCost?.differenceValue}
                  percentage={
                    overviewData.totalBenefitsCost?.differencePercentage
                  }
                  showCreditsDropdown
                  creditsInclusion={creditsInclusion}
                  onCreditsInclusionChange={(value: any) => {
                    setShowCreditModal({ visible: true, type: value });
                  }}
                  isNaApplicable={isFromCurrentExist(
                    overviewData?.totalBenefitsCost?.differencePercentage
                  )}
                />
              }
            />
          </Col>

          <Col lg={5}>
            <OfferCardOverview
              headerText={
                <>
                  Employer Cost
                  {getERTooltipText(isHsa(), isHra(), isAdditionalCost()) && (
                    <Popover
                      content={getErPopoverContent()}
                      overlayClassName={'popoverInner recommendedtooltip'}
                    >
                      <span className={styles.inclErHsaText}>
                        {getERTooltipText(isHsa(), isHra(), isAdditionalCost())}
                      </span>
                    </Popover>
                  )}
                </>
              }
              value={
                overviewData?.erCost?.value
                  ? currencyFormatterNoDecimals(overviewData.erCost.value || 0)
                  : '-'
              }
              footerElement={
                <FromCurrentDetailsFooter
                  value={overviewData.erCost?.differenceValue}
                  percentage={overviewData.erCost?.differencePercentage}
                  isNaApplicable={isFromCurrentExist(
                    overviewData.erCost?.differencePercentage
                  )}
                />
              }
            />
          </Col>
          <Col lg={5}>
            <OfferCardOverview
              headerText={
                <>
                  Employee Cost
                  {getEETooltipText() && (
                    <Popover
                      content={getEEPopoverContent()}
                      overlayClassName={'popoverInner recommendedtooltip'}
                    >
                      <span className={styles.inclErHsaText}>
                        {getEETooltipText()}
                      </span>
                    </Popover>
                  )}
                </>
              }
              value={
                overviewData?.eeCost?.value
                  ? currencyFormatterNoDecimals(overviewData.eeCost.value || 0)
                  : '-'
              }
              footerElement={
                <FromCurrentDetailsFooter
                  value={overviewData.eeCost?.differenceValue}
                  percentage={overviewData.eeCost?.differencePercentage}
                  isNaApplicable={isFromCurrentExist(
                    overviewData.eeCost?.differencePercentage
                  )}
                />
              }
            />
          </Col>
          <Col lg={5}>
            <OfferCardOverview
              headerText="Credits & Discounts"
              value={
                overviewData?.creditsAndDiscounts
                  ? currencyFormatterNoDecimals(
                      overviewData?.creditsAndDiscounts
                        .totalCreditsAndDiscounts || 0
                    )
                  : '-'
              }
              footerElement={
                <CreditsDiscountsDetailsFooter
                  credits={overviewData?.creditsAndDiscounts?.totalCredits}
                  discounts={overviewData?.creditsAndDiscounts?.totalDiscounts}
                  showViewDescription={hasCreditsDiscountsData(
                    rawProposalCreditData,
                    rawProposalDiscountData
                  )}
                  onViewDescriptionClick={() => handleViewDescriptionClick()}
                />
              }
            />
          </Col>
        </Row>
      </div>
      <div className={styles.proposalDescription}>
        <Row
          className={styles.descriptionHeader}
          justify="space-between"
          align="top"
        >
          <RenewalCommonSubHeader
            subText={
              <span className={styles.subHeader}>
                Proposal Description{' '}
                <LinkButton
                  icon={<img src={pencilIcon} />}
                  onClick={() => setShowEditDescription(true)}
                />
              </span>
            }
          />
        </Row>
        <Row>
          <p>{isEmpty(description) ? '-' : reactHtmlParser(description)}</p>
        </Row>
      </div>
      <div className={styles.proposalBenefits}>
        <Row className={styles.benefitsHeader}>
          <RenewalCommonSubHeader subText={'Proposal Benefits'} />
        </Row>
        <Divider />
        <div className={styles.cardSection}>
          {proposalsBenefitsDataList &&
            proposalsBenefitsDataList.length > 0 &&
            proposalsBenefitsDataList.map((data, idx) => (
              <div className={styles.benefitCard} key={idx}>
                <ProposalsBenefitsCard
                  data={data}
                  onViewDescriptionClick={() =>
                    handleViewDescriptionClick(data.type)
                  }
                  hasCreditDiscountData={hasCreditsDiscountsData(
                    rawProposalCreditData,
                    rawProposalDiscountData,
                    data.type === 'Life & Disability'
                      ? LIFE_DISABILITY_BENEFITS
                      : [getKeyFromValue(OFFER_BENEFIT_TYPES, data.type)]
                  )}
                />
              </div>
            ))}
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <div className={styles.sendNotificationWrapper}>
          <Row>
            <div className={styles.sendNotificationText}>
              Send Notification after Publish &nbsp;
              <Popover
                content={NOTIFICATION_PUBLISH_TOOLTIP}
                placement="top"
                overlayClassName="popoverInner proposalPublishTooltip"
              >
                <QuestionCircleOutlined />
              </Popover>
            </div>

            <Switch
              className={styles.sendNotificationSwitch}
              checked={isPublishNotificationActive}
              onChange={handleNotificationToggle}
            />
          </Row>
        </div>
        <PageActionButton
          type="primary"
          className={styles.publishProposalButton}
          loading={isPublishStatusUpdating}
          disabled={false}
          onClick={() => {
            handleProposalPublish();
          }}
        >
          {isErAdmin ? 'Publish Proposal' : 'Publish Proposal to Employer'}
        </PageActionButton>
        {!isErAdmin && (
          <div className={styles.saveAsDraft}>
            <Button
              loading={false}
              className={styles.saveAsDraftButton}
              onClick={() => {
                publishProposal({
                  proposalId: proposalIdEdit ? proposalIdEdit : proposalId,
                  proposalStatus: OFFER_STATUS.DRAFT,
                });
                setPublishAsDraft(true);
                if ((params?.proposalId ?? null) !== null) {
                  navigateToDetailsPage();
                }
                setIsPublishedOrDraft(true);
              }}
              disabled={false}
            >
              {proposalType === 'PUBLISHED' || proposalType === 'APPROVED'
                ? 'Save Proposal as New Draft'
                : 'Save Draft Proposal'}
            </Button>
          </div>
        )}
      </div>
      <MultipleCreditsDiscountsDescriptionModal
        isOpen={isCreditsDescriptionModalOpen}
        setIsOpen={setIsCreditsDescriptionModalOpen}
        data={creditsAndDiscountsData}
      />
      <CreditIncludedExcludedModal
        type={showCreditModal.type}
        visible={showCreditModal.visible}
        onClose={() =>
          setShowCreditModal({
            visible: false,
            type:
              creditsInclusion === CREDITS_INCLUSION.INCLUDED.value
                ? CREDITS_INCLUSION.EXCLUDED.value
                : CREDITS_INCLUSION.INCLUDED.value,
          })
        }
        onConfirm={onConfirmCreditSave}
      />
      <EditDescriptionModal
        visible={showEditDescription}
        description={description}
        label="Proposal Description"
        confirmText="Save Description"
        onClose={() => setShowEditDescription(false)}
        onConfirm={(description) => {
          const proposalUpdated = cloneDeep(proposalDetails.payload);
          proposalUpdated.description = description;
          updateProposal({
            proposalId: proposalId || proposalDetails?.payload?.id,
            proposal: proposalUpdated,
          });
        }}
      />
      <ConfirmationDialog
        title="Publish Proposal"
        confirmText={DRAFT_OFFER_EXIST_ON_PROPOSAL_BUTTON_TITLE}
        cancelText="Cancel"
        closeModal={() => {
          setIsDraftExistInProposal(false);
        }}
        onConfirm={submitPublishingProposal}
        confirmLoading={false}
        visible={isDraftExistInProposal}
        isCancelLink={true}
        zIndex={10000}
      >
        <>
          {DRAFT_OFFER_EXIST_ON_PROPOSAL_WARNING_MESSAGE}
          {isDraftNamePublished && (
            <div className={styles.draftOfferPopUpContainer}>
              {DRAFT_OFFER_EXIST_ON_PROPOSAL_WITH_SAME_NAME_WARNING_MESSAGE}
            </div>
          )}
        </>
      </ConfirmationDialog>
    </div>
  );
};

export default Summary;
