import { FC } from 'react';
import Markdown from 'markdown-to-jsx';
import { isEmpty, trim } from 'lodash';
import { Sender } from 'modules/chatbot/enums/Sender';
import { classNames } from 'modules/assistant/utils/commonUtils';
import BouncingDots from 'modules/assistant/components/BouncingDots/BouncingDots';
import { ReactComponent as UserIcon } from 'assets/images/chatbot/user-icon.svg';

import styles from './chatBubbleLayout.module.less';

type ChatBubbleProps = {
  content?: string;
  sender: Sender;
  reference?: string;
  isPending?: boolean;
};
const ChatBubble: FC<ChatBubbleProps> = ({
  content,
  sender,
  reference,
  isPending,
}) => {
  const isError = isEmpty(content);
  const isBot = sender === Sender.BOT;
  const isUser = sender === Sender.USER;

  const getDisplayContent = (
    content: string,

    isBot: boolean,
    isPending: boolean,
    isError?: boolean,
    reference?: string
  ): JSX.Element | string => {
    if (isPending && isBot) {
      return <BouncingDots />;
    } else if (isError) {
      return (
        <span className={styles.errorColor}>
          We are experiencing technical difficulties at this time, please visit
          back later
        </span>
      );
    } else if (isBot) {
      return (
        <div className={styles.contentWrapper}>
          <Markdown>{trim(content)}</Markdown>
          {!isEmpty(reference) && (
            <div className={styles.sourcesContainer}>
              <a
                href={reference}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.linkButton}
              >
                Sources
              </a>
            </div>
          )}
        </div>
      );
    } else {
      return trim(content);
    }
  };

  return (
    <div
      className={classNames(
        styles.chatBubble,
        isBot ? styles.botBubble : styles.userBubble,
        isUser ? styles.userBubbleMaxWidth : '',
        isUser ? styles.bubblePadding : ''
      )}
    >
      <div className={styles.layoutWrapper}>
        {isBot && (
          <div className={styles.icon}>
            <UserIcon />
          </div>
        )}
        <div
          className={classNames(
            styles.content,
            isBot ? styles.botBubbleWrapper : '',
            isError ? styles.errorColor : '',
            isPending ? styles.isPendingContainer : ''
          )}
        >
          {getDisplayContent(content!, isBot, isPending!, isError, reference)}
        </div>
      </div>
    </div>
  );
};

export default ChatBubble;
