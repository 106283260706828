import { FC } from 'react';
import { default as PatternFormat } from 'react-number-format';
import styles from './inputPatternFormat.module.less';

type InputPatternFormatProps = {
  id: string;
  format: string;
  placeholder: string;
};

const InputPatternFormat: FC<InputPatternFormatProps> = (props) => {
  const { format, id, placeholder, ...rest } = props;
  return (
    <PatternFormat
      id={id}
      className={styles.patternFormat}
      format={format}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default InputPatternFormat;
