import React, { ReactNode } from 'react';
import { Button } from 'antd';
import prevIcon from 'assets/images/icon-prev.svg';
import nextIcon from 'assets/images/icon-next.svg';
import { PaginationElementType } from 'constants/commonConstants';

import styles from './tableCustomPagination.module.less';

const PaginationNav = (
  page: number,
  type: string,
  originalElement: ReactNode
) => {
  if (type === PaginationElementType.prev) {
    return (
      <Button className={styles.tableCustomPaginationPrevButton}>
        <span>
          <img src={prevIcon} alt="prev-icon" />
        </span>
      </Button>
    );
  } else if (type === PaginationElementType.next) {
    return (
      <Button className={styles.tableCustomPaginationPrevButton}>
        <span>
          <img src={nextIcon} alt="next-icon" />
        </span>
      </Button>
    );
  }
  return originalElement;
};

export default PaginationNav;
