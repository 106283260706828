import { Row } from 'antd';
import { ReactComponent as Calendar } from 'assets/images/icon-calendar-small.svg';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import styles from './periodTooltip.module.less';

type PeriodTooltipProps = {
  monthCount: string;
  monthFrom: string;
  monthTo: string;
};

const PeriodTooltip = (props: PeriodTooltipProps) => {
  const { monthCount, monthFrom, monthTo } = props;

  return (
    <span className={styles.periodWrapper}>
      <PlanyearPopover content={`${monthFrom} - ${monthTo}`} placement="right">
        <Row>
          <Calendar />
          <span className={styles.monthText}>
            {monthCount} {Number(monthCount) === 1 ? 'Month' : 'Months'}
          </span>
        </Row>
      </PlanyearPopover>
    </span>
  );
};

export default PeriodTooltip;
