import {
  FC,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Col,
  Collapse,
  Form,
  FormInstance,
  Popover,
  Row,
  Select,
  Spin,
} from 'antd';
import Icon, {
  DownOutlined,
  ExclamationCircleTwoTone,
  UpOutlined,
} from '@ant-design/icons';
import {
  cloneDeep,
  find,
  get,
  isEmpty,
  isNumber,
  remove,
  set,
  setWith,
} from 'lodash';
import { useLocation } from 'react-router-dom';
import SelectOptions from 'components/SelectOptions/SelectOptions';
import PanelInputForm from 'modules/plans/components/PanelInputForm/PanelInputForm';
import AddTierButton from 'modules/plans/components/AddTierButton/AddTierButton';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import { usePrevious } from 'hooks/usePrevious';
import { useNavContext } from 'hooks/useNavContext';
import {
  choices,
  FourTier,
  MONTHLY_PREMIUM,
  N_TIER_DEFAULTS,
  NTier,
  NTiers,
  RateFrequency,
  RatesColumns,
  RateType,
} from 'modules/plans/constants';
import RatesFormFields from 'modules/plans/enums/RatesFormFields';
import { convertEnumToOptions } from 'util/commonUtil';
import iconRemove from 'assets/images/icon-remove-red.svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import MedicalPlan from 'model/plans/MedicalPlan';
import { checkBenefitGuidesRates } from 'modules/plans/slices/dbgInfoSlice';
import BenefitClassContribution from 'model/plans/BenefitClassContribution';
import FourTierContributions from 'model/plans/FourTierContributions';
import NTierContributions from 'model/plans/NTierContributions';
import { DentalPlan } from 'model/plans/DentalPlan';
import { VisionPlan } from 'model/plans/VisionPlan';
import BenguideBasicData from 'model/BenguideBasicData';
import Contribution from 'model/plans/Contribution';
import { BenefitCategory } from 'constants/commonConstants';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import AlertMessage from 'components/Alert/AlertMessage';
import {
  isNullOrUndefined,
  getContributionRateType,
  validateRates,
} from 'modules/plans/utils';
import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import {
  CONTRIBUTIONS_CHANGE_UP_EDIT_MESSAGE,
  RENEWAL_COMMON_WARNING_MESSAGE_PREMIUM_CONTRIBUTIONS,
} from 'constants/benguideCollaborationConstants';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import PanelSection from 'modules/plans/enums/PanelSection';
import { ReactComponent as IconLoading } from 'assets/images/spinner-gap.svg';
import { ReactComponent as Refresh } from 'assets/images/refresh.svg';
import styles from './rates.module.less';

const { Option } = Select;
const { Panel } = Collapse;
const ALL_CLASSES = 'allClasses';

type ContributionFieldsProps = {
  label: string;
  value: string;
  onRemove?: React.MouseEventHandler<HTMLImageElement>;
  showRemoveIcon?: boolean;
  isNextClicked: boolean;
  benGroup?: string;
  form: FormInstance;
  frequencies?: string[];
  benefitCategory?: string;
  frequency?: string;
  isDBGView: boolean;
  isUPEdit?: boolean;
  setValueChanged: Function;
  getContributionInProgress?: boolean;
  getCalculationData: Function;
  updateValidationMsg?: Function;
  showErrorMsg?: boolean;
  isReviewHighlight?: boolean;
};

type FormDataType = {
  rateType: string;
  sameRates: string;
  monthlyPremiums: any;
};

type CalculationData = {
  clonedPlan: any;
  currentField: string;
  currentBenGroup: string;
  currentTierName: string;
  currentRateType: string;
};

const getFieldName = (
  benGroup: string | undefined,
  tierName: string,
  columnName: string
): string => {
  if (benGroup) {
    return `${benGroup}.${tierName}.${columnName}`;
  }
  return `${tierName}.${columnName}`;
};

const getDBGFrequency = (
  benefitGuideObj: BenguideBasicData,
  benefitCategory: string,
  isDGBView: boolean
) => {
  const dbgFrequency = find(benefitGuideObj?.contributionFrequencies, {
    benefitKind: benefitCategory,
  });
  return isDGBView ? dbgFrequency?.frequency : '';
};
const getOtherGuideFrequencies = (
  dbgByFrequencies: object,
  currentDBGFrequency: string
) => {
  return Object.keys(dbgByFrequencies)
    .filter((freq) => freq !== currentDBGFrequency)
    .map((value) => RateFrequency[value].label)
    .join(',');
};

const ContributionRow: FC<ContributionFieldsProps> = (
  props: ContributionFieldsProps
) => {
  const {
    label,
    value,
    benGroup,
    form,
    frequencies,
    frequency,
    isDBGView,
    setValueChanged,
    getContributionInProgress,
    benefitCategory,
    getCalculationData,
    isReviewHighlight = false,
  } = props;

  const [showUpdate, setShowUpdate] = useState<boolean>(false);

  const eeColumn = getFieldName(
    benGroup,
    value,
    RatesColumns.TOTAL_EE_CONTRIBUTION
  );
  const eeBiWeeklyColumn = getFieldName(
    benGroup,
    value,
    RatesColumns.BI_WEEKLY__COST
  );
  const eeSemiMonthlyColumn = getFieldName(
    benGroup,
    value,
    RatesColumns.SEMI_MONTHLY__COST
  );
  const erColumn = getFieldName(
    benGroup,
    value,
    RatesColumns.TOTAL_ER_CONTRIBUTION
  );
  const totalCostColumn = getFieldName(
    benGroup,
    value,
    RatesColumns.TOTAL_MONTHLY_COST
  );

  const colSpan =
    frequencies?.length && frequencies?.length > 1
      ? 24 / frequencies?.length
      : 24;

  const eeContributionLimit = ({ floatValue }: any) => {
    if (isNullOrUndefined(floatValue)) {
      return true;
    }
    return floatValue <= form.getFieldValue(totalCostColumn);
  };

  return (
    <div>
      <Row className={styles.rowWrapper}>
        <Col span={2} className={styles.tierName}>
          <span className="text-form-label">{label}</span>
        </Col>
        <Col span={frequencies?.length && frequencies.length > 2 ? 12 : 7}>
          <Row>
            {frequencies?.includes(RateFrequency.BI_WEEKLY.value) && (
              <Col span={colSpan}>
                <Form.Item name={eeBiWeeklyColumn} rules={[{ message: '' }]}>
                  <NumberFormatInput
                    onKeyDown={() => {
                      setValueChanged(true);
                      setShowUpdate(true);
                    }}
                    thousandSeparator
                    allowNegative={false}
                    prefix="$"
                    outsidePrefix
                    decimalSeparator="."
                    fixedDecimalScale
                    decimalScale={2}
                    disabled={
                      (frequency !== RateFrequency.BI_WEEKLY.value &&
                        isDBGView) ||
                      getContributionInProgress
                    }
                    isAllowed={eeContributionLimit}
                    className={
                      isDBGView && frequency === RateFrequency.BI_WEEKLY.value
                        ? styles.contributionInput
                        : ''
                    }
                    onBlur={() => {
                      getCalculationData();
                      setShowUpdate(false);
                    }}
                  />
                </Form.Item>
                {showUpdate && (
                  <Refresh
                    className={`${styles.updateValues} ${
                      benefitCategory === BenefitCategory.MEDICAL.value
                        ? ''
                        : styles.dv
                    }`}
                    onClick={() => {
                      getCalculationData();
                    }}
                  />
                )}
              </Col>
            )}
            {frequencies?.includes(RateFrequency.SEMI_MONTHLY.value) && (
              <Col span={colSpan}>
                <Form.Item name={eeSemiMonthlyColumn} rules={[{ message: '' }]}>
                  <NumberFormatInput
                    thousandSeparator
                    allowNegative={false}
                    onKeyDown={() => {
                      setValueChanged(true);
                      setShowUpdate(true);
                    }}
                    prefix="$"
                    outsidePrefix
                    decimalSeparator="."
                    fixedDecimalScale
                    decimalScale={2}
                    className={
                      isDBGView &&
                      frequency === RateFrequency.SEMI_MONTHLY.value
                        ? styles.contributionInput
                        : ''
                    }
                    disabled={
                      (frequency !== RateFrequency.SEMI_MONTHLY.value &&
                        isDBGView) ||
                      getContributionInProgress
                    }
                    isAllowed={eeContributionLimit}
                    onBlur={() => {
                      getCalculationData();
                      setShowUpdate(false);
                    }}
                  />
                </Form.Item>
                {showUpdate && (
                  <Refresh
                    className={`${styles.updateValues} ${
                      benefitCategory === BenefitCategory.MEDICAL.value
                        ? ''
                        : styles.dv
                    }`}
                    onClick={() => {
                      getCalculationData();
                    }}
                  />
                )}
              </Col>
            )}
            {frequencies?.includes(RateFrequency.MONTHLY.value) && (
              <Col span={colSpan}>
                <Form.Item name={eeColumn} rules={[{ message: '' }]}>
                  <NumberFormatInput
                    onKeyDown={() => {
                      setValueChanged(true);
                      setShowUpdate(true);
                    }}
                    thousandSeparator
                    allowNegative={false}
                    prefix="$"
                    outsidePrefix
                    decimalSeparator="."
                    fixedDecimalScale
                    decimalScale={2}
                    className={
                      isDBGView && frequency === RateFrequency.MONTHLY.value
                        ? styles.contributionInput
                        : ''
                    }
                    disabled={
                      (frequency !== RateFrequency.MONTHLY.value &&
                        isDBGView) ||
                      getContributionInProgress
                    }
                    isAllowed={eeContributionLimit}
                    onBlur={() => {
                      getCalculationData();
                      setShowUpdate(false);
                    }}
                    customClass={
                      isReviewHighlight ? 'highlightField' : undefined
                    }
                  />
                </Form.Item>
                {showUpdate && (
                  <Refresh
                    className={`${styles.updateValues} ${
                      benefitCategory === BenefitCategory.MEDICAL.value
                        ? ''
                        : styles.dv
                    }`}
                    onClick={() => {
                      getCalculationData();
                    }}
                  />
                )}
              </Col>
            )}
          </Row>
        </Col>
        <Col span={frequencies?.length && frequencies.length > 2 ? 5 : 7}>
          <Form.Item name={erColumn} rules={[{ message: '' }]}>
            <NumberFormatInput
              thousandSeparator
              allowNegative={false}
              prefix="$"
              outsidePrefix
              decimalSeparator="."
              fixedDecimalScale
              decimalScale={2}
              disabled
              customClass={isReviewHighlight ? 'highlightField' : undefined}
            />
          </Form.Item>
        </Col>
        <Col span={frequencies?.length && frequencies.length > 2 ? 5 : 7}>
          <Form.Item name={totalCostColumn}>
            <NumberFormatInput
              thousandSeparator
              allowNegative={false}
              prefix="$"
              outsidePrefix
              decimalSeparator="."
              fixedDecimalScale
              decimalScale={2}
              disabled
              customClass={isReviewHighlight ? 'highlightField' : undefined}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

const isGroupExistsOnDBG = (planGroup: string, dbgGroups: string[]) => {
  return isEmpty(dbgGroups) ? false : dbgGroups.includes(planGroup);
};

const MonthlyPremiumSection: FC<ContributionFieldsProps> = (
  props: ContributionFieldsProps
) => {
  const {
    label,
    value,
    showRemoveIcon,
    onRemove,
    benefitCategory,
    isDBGView,
    isUPEdit,
    setValueChanged,
    getCalculationData,
    getContributionInProgress,
    isReviewHighlight = false,
  } = props;

  const totalCostColumn = getFieldName(
    MONTHLY_PREMIUM,
    value,
    RatesColumns.TOTAL_MONTHLY_COST
  );

  const [showUpdate, setShowUpdate] = useState<boolean>(false);

  return (
    <Row
      className={styles.rowWrapper}
      style={isDBGView ? { paddingLeft: '11px' } : {}}
    >
      {showRemoveIcon && (
        <img
          src={iconRemove}
          alt="remove-tier-icon"
          className={styles.iconRemove}
          onClick={(e) => {
            onRemove && onRemove(e);
          }}
        />
      )}
      <Col flex="50px" className={styles.tierName}>
        <span className="text-form-label">{label}</span>
      </Col>
      <Col
        flex="auto"
        span={
          benefitCategory === BenefitCategory.MEDICAL.value || isDBGView
            ? 24
            : isUPEdit && benefitCategory !== BenefitCategory.MEDICAL.value
            ? 18
            : 9
        }
      >
        <Form.Item name={totalCostColumn}>
          <NumberFormatInput
            thousandSeparator
            allowNegative={false}
            outsidePrefix
            decimalSeparator="."
            fixedDecimalScale
            decimalScale={2}
            prefix="$"
            disabled={isDBGView || getContributionInProgress}
            onKeyDown={() => {
              setValueChanged(true);
              setShowUpdate(true);
            }}
            onBlur={() => {
              getCalculationData();
              setShowUpdate(false);
            }}
            customClass={isReviewHighlight ? 'highlightField' : undefined}
          />
        </Form.Item>
        {showUpdate && (
          <Refresh
            className={`${styles.updateValues} ${
              benefitCategory === BenefitCategory.MEDICAL.value ? '' : styles.dv
            }`}
            onClick={() => {
              getCalculationData();
            }}
          />
        )}
      </Col>
    </Row>
  );
};

type RatesProps = {
  form: FormInstance;
  currentSection: string;
  dbgByFrequency?: object;
  rateValidations?: { hasMismatchContributions: boolean };
  isDBGView: boolean;
  plan: MedicalPlan | DentalPlan | VisionPlan;
  benefitGuideObj: BenguideBasicData;
  updatePlanByPathAction: Function;
  getContributionAction: Function;
  benefitCategory: string;
  isUPEdit?: boolean;
  requestType?: string;
  updateValidationMsg?: Function;
  getContributionInProgress?: boolean;
  error?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  isReviewProp?: boolean;
  isReviewHighlight?: boolean;
  onChange?: (plan: any) => void;
};

const Rates = forwardRef((props: RatesProps, ref) => {
  const dispatch = useAppDispatch();
  const {
    form,
    currentSection,
    dbgByFrequency,
    isDBGView,
    plan,
    benefitGuideObj,
    updatePlanByPathAction,
    getContributionAction,
    benefitCategory,
    rateValidations,
    isUPEdit,
    updateValidationMsg,
    getContributionInProgress = false,
    error,
    isReviewHighlight,
    onChange,
  } = props;

  const location = useLocation();
  const { plan: MDVplan } = (location.state as any) || {};

  const { planYearsList } = useAppSelector((state) => state.employer.employer);

  const frequencies = isDBGView
    ? dbgByFrequency
      ? Object.keys(dbgByFrequency)
      : []
    : [RateFrequency.MONTHLY.value];

  const dbgGroups = benefitGuideObj?.benefitClasses;
  const dbgFrequency = getDBGFrequency(
    benefitGuideObj,
    benefitCategory,
    isDBGView
  );
  const sortedRateGroups = cloneDeep(plan.groups);

  sortedRateGroups?.sort((a, b) => {
    return isGroupExistsOnDBG(a, dbgGroups) === isGroupExistsOnDBG(b, dbgGroups)
      ? 0
      : isGroupExistsOnDBG(a, dbgGroups)
      ? -1
      : 1;
  });

  // Disabling warinings due to only need to trigger the on change if the rates are changed in the plan. rates will be updated in redux onchange
  useEffect(() => {
    onChange?.(plan);
    // eslint-disable-next-line
  }, [plan.rates]);

  const getFrequenciesInProgress = useAppSelector(
    (state) => state.plan.dgbInfo.getFrequenciesInProgress
  );

  const [isNextClicked, setNextClicked] = useState<boolean>(false);
  const [valueChanged, setValueChanged] = useState<boolean>(false);
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);
  const [tierChangeWarning, setTierChangeWarning] = useState<boolean>(false);
  const [calculationData, setCalculationData] = useState<CalculationData>(
    {} as CalculationData
  );
  const [triggerReviewRefresh, setTriggerReviewRefresh] =
    useState<boolean>(false);
  const [requiredFieldError, setRequiredFieldError] = error || [
    false,
    () => {},
  ];
  const [getUpcomingPlanYears, { data: upcomingPlanYearData }] =
    useLazyGetUpcomingPlanYearsByEmployerQuery();

  const isPlanYearInList = !!planYearsList?.find(
    (planYear: any) => planYear?.id === plan.planYearId
  )?.current;

  const hasUpcomingPlanYearWithNullId =
    upcomingPlanYearData?.upcomingPlanYears?.some(
      (obj: any) => obj?.planYearId === null
    );

  const isRenewalStarted = isPlanYearInList && hasUpcomingPlanYearWithNullId;

  const updateValidation = useCallback(() => {
    const allValues = form.getFieldsValue();
    const valuesFilled = checkEachFieldIsEmpty(allValues);
    if (
      valuesFilled.every(Boolean) ||
      valuesFilled.every((val) => val === false)
    ) {
      setRequiredFieldError(false);
    }
  }, [form, setRequiredFieldError]);

  useEffect(() => {
    updateValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldsValue(), updateValidation]);

  useEffect(() => {
    setNextClicked(false);
  }, [currentSection]);

  useImperativeHandle(ref, () => ({
    validate() {
      return getCompleteness();
    },
    nextClicked() {
      setNextClicked(true);
    },
    showErrorMsg() {
      return requiredFieldError;
    },

    resetAll() {
      const { rateType } = form.getFieldsValue();
      form.resetFields();
      setShowValidationErrors(false);
      setFormData({
        rateType: rateType || RateType.FOUR_TIER.value,
        sameRates: 'No',
        monthlyPremiums: { ntierContributions: {}, fourTierContributions: {} },
      });
      form.setFieldsValue({
        [RatesFormFields.RATE_TYPE]: rateType || RateType.FOUR_TIER.value,
        [RatesFormFields.SAME_RATES_OPTION]: 'No',
      });
      didMount.current = false;
      setValueChanged(false);
    },

    prepareForReview: () => {
      const rateType = plan?.rates[plan?.groups[0]]?.type;
      setFormData((prev) => ({
        ...prev,
        sameRates: plan?.hasSameContributions ? 'Yes' : 'No',
        rateType: rateType || RateType.FOUR_TIER.value,
      }));

      form.setFieldsValue({
        [RatesFormFields.RATE_TYPE]: rateType || RateType.FOUR_TIER.value,
        [RatesFormFields.SAME_RATES_OPTION]: plan?.hasSameContributions
          ? 'Yes'
          : 'No',
      });
      setTriggerReviewRefresh(true);
    },
  }));

  const getCompleteness = () => {
    const { groups } = plan;
    const { rateType, sameRates } = form.getFieldsValue();

    let invalidFormFields = [] as string[];
    const benefitClasses = 'Yes' === sameRates ? [ALL_CLASSES] : groups;
    let isComplete = true;

    // Rate type is requied
    if (!rateType) {
      isComplete = false;
    }

    benefitClasses.forEach((group) => {
      const allFileds = getAllFiledsByGroup(group);
      const atLeastOneFiledFilled = Object.values(allFileds).filter(
        (value) => isNumber(value) || !isNaN(parseFloat(value as string))
      );
      // Should filled all fields if filled at least one field in the benefit class
      if (!isEmpty(atLeastOneFiledFilled)) {
        const invalidFields = Object.keys(allFileds).filter((field) => {
          const value = get(allFileds, field);
          return value === '' || value === undefined;
        });

        invalidFormFields = [...invalidFormFields, ...invalidFields];

        if (isNextClicked) {
          if (invalidFormFields.length === 0) {
            updateValidationMsg && updateValidationMsg(true);
          } else {
            updateValidationMsg && updateValidationMsg(false);
          }
        }
      } else {
        isComplete = false;
      }
    });

    isComplete = isComplete && invalidFormFields.length === 0;
    isComplete = RateType.AGE_BAND.value === rateType ? true : isComplete;
    setShowValidationErrors(!isEmpty(invalidFormFields));
    return { isComplete, invalidFormFields };
  };

  const getAllFiledsByGroup = (group: string) => {
    const { rateType, ...rest } = form.getFieldsValue();
    const fileds = {} as any;
    let tiers = [] as string[];

    if (RateType.FOUR_TIER.value === rateType) {
      tiers = Object.keys(FourTier);
    } else if (RateType.N_TIER.value === rateType) {
      const nTierMap = Object.values(NTier).reduce((a, b) => {
        return { ...a, [b.label]: b.value };
      }, {});
      tiers = nTiers.map((tier) => get(nTierMap, tier));
    }
    tiers.forEach((tier) => {
      const filedKeyEE = `${group}.${tier}.employeeCost`;
      const filedKeyER = `${group}.${tier}.employerCost`;

      fileds[filedKeyEE] = get(rest, filedKeyEE);
      fileds[filedKeyER] = get(rest, filedKeyER);
    });

    return fileds;
  };

  const [formData, setFormData] = useState<FormDataType>({
    rateType: RateType.FOUR_TIER.value,
    sameRates: plan.hasSameContributions ? choices[0] : choices[1],
    monthlyPremiums: { ntierContributions: {}, fourTierContributions: {} },
  });
  const didMount = useRef(false);

  const initializeTierContributions = (tierEnum: object, rateType: string) => {
    return Object.values(tierEnum).map((tier) => ({
      employeeCost:
        formData.monthlyPremiums[rateType] &&
        parseFloat(formData.monthlyPremiums[rateType][tier.value])
          ? 0.0
          : '',
      employeeBiWeeklyCost:
        formData.monthlyPremiums[rateType] &&
        parseFloat(formData.monthlyPremiums[rateType][tier.value])
          ? 0.0
          : '',
      employeeSemiMonthlyCost:
        formData.monthlyPremiums[rateType] &&
        parseFloat(formData.monthlyPremiums[rateType][tier.value])
          ? 0.0
          : '',
      employerCost:
        formData.monthlyPremiums[rateType] &&
        parseFloat(formData.monthlyPremiums[rateType][tier.value])
          ? parseFloat(formData.monthlyPremiums[rateType][tier.value])
          : '',
      totalCost:
        (formData.monthlyPremiums[rateType] &&
          parseFloat(formData.monthlyPremiums[rateType][tier.value])) ||
        '',
      tierName: tier.value,
      enrollment: tier.enrollment,
    }));
  };

  const [activePanel, setActivePanel] = useState<string[] | string>([
    plan.groups ? sortedRateGroups[0] : '',
  ]);
  const [nTiers, setNTiers] = useState<string[]>(NTiers.slice(0, 2));

  const formRef = useRef<any>();
  const prevBenGroups = usePrevious(plan.groups);
  const { employerId, brokerId } = useNavContext();

  useEffect(() => {
    const formFields = {};
    const rate: { [key: string]: {} } = {};
    plan.groups?.forEach((group) => {
      if (isEmpty(plan.rates) || !plan.rates[group]) {
        const rateTYpe = getContributionRateType(
          form.getFieldValue(RatesFormFields.RATE_TYPE)
        );
        const benefitClass = new BenefitClassContribution();
        if (
          form.getFieldValue(RatesFormFields.RATE_TYPE) ===
          RateType.FOUR_TIER.value
        ) {
          benefitClass.fourTierContributions.contributions =
            initializeTierContributions(FourTier, rateTYpe);
        } else if (
          form.getFieldValue(RatesFormFields.RATE_TYPE) ===
          RateType.N_TIER.value
        ) {
          benefitClass.ntierContributions.contributions =
            initializeTierContributions(N_TIER_DEFAULTS, rateTYpe);
        }
        benefitClass.type = formData.rateType;
        if (rateTYpe) {
          setContributionFormData(benefitClass, rateTYpe, formFields, group);
          rate[group] = benefitClass;
        }
      }
    });
    if (isEmpty(plan.rates)) {
      !isEmpty(rate) &&
        dispatch(updatePlanByPathAction([PanelSection.RATES], rate, isDBGView));
      form.setFieldsValue({
        ...formFields,
        [RatesFormFields.RATE_TYPE]:
          formData.rateType || RateType.FOUR_TIER.value,
        [RatesFormFields.SAME_RATES_OPTION]: getSameRateOption(plan.groups),
      });
      setFormData({
        ...formData,
        rateType: formData.rateType || RateType.FOUR_TIER.value,
        sameRates: getSameRateOption(plan.groups),
      });
    }

    // eslint-disable-next-line
  }, [prevBenGroups, plan.groups, plan.groups?.length]);

  useEffect(() => {
    if (!isEmpty(plan.rates)) {
      const firstRate: any = Object.values(plan.rates)[0];
      const rateType = getContributionRateType(firstRate.type);
      const premiums: { [key: string]: string | number | null } = {};
      firstRate[rateType]?.contributions.forEach((tier: Contribution) => {
        premiums[tier.tierName] = tier.totalCost;
      });
      if (firstRate.type === RateType.N_TIER.value && firstRate[rateType]) {
        setNTiers(NTiers.slice(0, firstRate[rateType].contributions.length));
      }
      setFormData({
        rateType: firstRate.type,
        sameRates: plan.hasSameContributions ? choices[0] : choices[1],
        monthlyPremiums: {
          ...formData.monthlyPremiums,
          [rateType]: premiums,
        },
      });
      const formFields = {};
      plan.groups.forEach((group) => {
        if (plan.rates[group]) {
          setContributionFormData(
            plan.rates[group],
            rateType,
            formFields,
            group
          );
        }
      });
      setContributionFormData(firstRate, rateType, formFields, MONTHLY_PREMIUM);
      if (plan.hasSameContributions) {
        setContributionFormData(firstRate, rateType, formFields, ALL_CLASSES);
      }
      form.setFieldsValue({
        ...formFields,
        [RatesFormFields.RATE_TYPE]: firstRate.type,
        [RatesFormFields.SAME_RATES_OPTION]: getSameRateOption(plan.groups),
      });
    }
    // eslint-disable-next-line
  }, [plan.id]);

  useEffect(() => {
    // contrib api call rerender
    if (!isEmpty(plan.rates) || triggerReviewRefresh) {
      plan.groups.forEach((group, index) => {
        if (plan.rates[group]) {
          const formFields = {};
          const rateType = getContributionRateType(formData.rateType);
          setContributionFormData(
            plan.rates[group],
            rateType,
            formFields,
            group
          );
          if (index === 0) {
            setContributionFormData(
              plan.rates[group],
              rateType,
              formFields,
              MONTHLY_PREMIUM
            );
            if (plan.hasSameContributions) {
              setContributionFormData(
                plan.rates[group],
                rateType,
                formFields,
                ALL_CLASSES
              );
            }
          }
          form.setFieldsValue(formFields);
        }
      });
      setTriggerReviewRefresh(false);
    }
    // eslint-disable-next-line
  }, [plan.rates, triggerReviewRefresh]);

  const getSameRateOption = (rateGroups: string[]) => {
    return isEmpty(rateGroups) || rateGroups.length === 1
      ? choices[1]
      : plan.hasSameContributions
      ? choices[0]
      : choices[1];
  };

  const setContributionFormData = (
    rateData: any,
    rateType: string,
    formFields: object,
    benefitGroup: string
  ) => {
    rateData[rateType]?.contributions.forEach(
      (tierData: {
        employeeCost: number | string;
        tierName: string;
        employerCost: number | string;
        totalCost: number | string;
        employeeBiWeeklyCost: number | string;
        employeeSemiMonthlyCost: number | string;
        enrollment: number | string;
      }) => {
        const eeColumn = getFieldName(
          benefitGroup,
          tierData.tierName,
          RatesColumns.TOTAL_EE_CONTRIBUTION
        );
        const eeBiWeeklyColumn = getFieldName(
          benefitGroup,
          tierData.tierName,
          RatesColumns.BI_WEEKLY__COST
        );
        const eeSemiMonthlyColumn = getFieldName(
          benefitGroup,
          tierData.tierName,
          RatesColumns.SEMI_MONTHLY__COST
        );
        const erColumn = getFieldName(
          benefitGroup,
          tierData.tierName,
          RatesColumns.TOTAL_ER_CONTRIBUTION
        );
        const totalCostColumn = getFieldName(
          benefitGroup,
          tierData.tierName,
          RatesColumns.TOTAL_MONTHLY_COST
        );
        setWith(
          formFields,
          [eeColumn],
          !isNumber(tierData.employeeCost) ? '' : tierData.employeeCost
        );
        setWith(
          formFields,
          [eeBiWeeklyColumn],
          !isNumber(tierData.employeeBiWeeklyCost)
            ? ''
            : tierData.employeeBiWeeklyCost
        );
        setWith(
          formFields,
          [eeSemiMonthlyColumn],
          !isNumber(tierData.employeeSemiMonthlyCost)
            ? ''
            : tierData.employeeSemiMonthlyCost
        );
        setWith(
          formFields,
          [erColumn],
          !isNumber(tierData.employerCost) ? '' : tierData.employerCost
        );
        setWith(
          formFields,
          [totalCostColumn],
          !isNumber(tierData.totalCost) ? '' : tierData.totalCost
        );
      }
    );
  };

  useEffect(() => {
    if (isDBGView) {
      employerId &&
        dispatch(
          checkBenefitGuidesRates(
            benefitGuideObj?.masterId,
            benefitCategory,
            benefitGuideObj?.revision
          )
        );
    }
  }, [
    benefitCategory,
    benefitGuideObj.masterId,
    benefitGuideObj.revision,
    dispatch,
    employerId,
    isDBGView,
    plan.id,
    plan.planYearId,
    benefitGuideObj?.latestRevision,
  ]);

  useEffect(() => {
    if (employerId && (isDBGView || isUPEdit)) {
      dispatch(getPlanYears(employerId));
      getUpcomingPlanYears({ employerId });
    }
    // eslint-disable-next-line
  }, [brokerId, dispatch, employerId, benefitCategory]);

  const updateRateType = (key: string, value: string) => {
    const { rates, groups } = plan;
    const clonedRates = cloneDeep(rates);
    groups.forEach((group, index) => {
      const benefitClass = clonedRates[group];
      if (value === RateType.FOUR_TIER.value) {
        if (
          isEmpty(rates[group].fourTierContributions) ||
          isEmpty(rates[group].fourTierContributions.contributions)
        ) {
          benefitClass.fourTierContributions = {};
          benefitClass.fourTierContributions.contributions =
            initializeTierContributions(
              FourTier,
              getContributionRateType(value)
            );
        }
      } else {
        if (
          isEmpty(rates[group].ntierContributions) ||
          isEmpty(rates[group].ntierContributions.contributions)
        ) {
          benefitClass.ntierContributions = {};
          benefitClass.ntierContributions.contributions =
            initializeTierContributions(
              N_TIER_DEFAULTS,
              getContributionRateType(value)
            );
        }
      }
      benefitClass.type = value;
      set(clonedRates, [group], benefitClass);
    });
    setFormData({ ...formData, rateType: value });

    dispatch(
      updatePlanByPathAction([PanelSection.RATES], clonedRates, isDBGView)
    );
  };

  const onInputChange = (changedValues: any, allValues: any): void => {
    updateValidation();
    if (isNextClicked) {
      const valuesFilled = checkEachFieldIsEmpty(allValues);
      if (
        valuesFilled.every(Boolean) ||
        valuesFilled.every((val) => val === false)
      ) {
        updateValidationMsg && updateValidationMsg(true);
      }
      if (Object.keys(changedValues)[0] === 'rateType') {
        updateValidationMsg && updateValidationMsg(true);
      }
    }

    const key = Object.keys(changedValues)[0];
    const value = changedValues[key];
    if (RatesFormFields.RATE_TYPE === key) {
      updateRateType(key, value);
    }

    const isContributionSection = Object.keys(changedValues)[0].includes('.');
    const { groups } = plan;
    const currentBenGroup =
      plan.hasSameContributions && plan?.groups?.length > 1
        ? ALL_CLASSES
        : Object.keys(changedValues)[0].split('.')[0];
    const currentTierName = Object.keys(changedValues)[0].split('.')[1];
    const currentField = Object.keys(changedValues)[0].split('.')[2];
    const newFieldValue = Object.values(changedValues)[0] as string;
    const totalCostKey = `${currentBenGroup}.${currentTierName}.totalCost`;
    const employerCostKey = `${currentBenGroup}.${currentTierName}.employerCost`;
    const employeeSemiMonthlyCostKey = `${currentBenGroup}.${currentTierName}.employeeSemiMonthlyCost`;
    const employeeBiWeeklyCostKey = `${currentBenGroup}.${currentTierName}.employeeBiWeeklyCost`;
    let eeCostKey = `${currentBenGroup}.${currentTierName}.employeeCost`;
    const contributionField = getContributionRateType(formData.rateType);
    const clonedPlan = cloneDeep(plan);
    if (Object.keys(changedValues)[0] === RatesFormFields.SAME_RATES_OPTION) {
      dispatch(
        updatePlanByPathAction(
          ['hasSameContributions'],
          newFieldValue === choices[0],
          isDBGView
        )
      );
      setFormData({ ...formData, sameRates: newFieldValue });
      if (Object.values(changedValues)[0] === choices[0]) {
        const firstData = clonedPlan.rates[sortedRateGroups[0]];
        const formFields = {};
        const rates: { [key: string]: {} } = {};
        Object.keys(clonedPlan.rates).forEach((group) => {
          rates[group] = firstData;
          setContributionFormData(
            firstData,
            contributionField,
            formFields,
            group
          );
        });
        setContributionFormData(
          firstData,
          contributionField,
          formFields,
          ALL_CLASSES
        );
        dispatch(
          updatePlanByPathAction([PanelSection.RATES], rates, isDBGView)
        );
        form.setFieldsValue(formFields);
      }
    }
    if (isContributionSection && valueChanged) {
      const benefitGroups =
        formData.sameRates === 'Yes' ||
        currentField === RatesColumns.TOTAL_MONTHLY_COST
          ? groups
          : [currentBenGroup];
      benefitGroups.forEach((group) => {
        if (!clonedPlan.rates[group][contributionField]) {
          if (contributionField === 'ntierContributions') {
            clonedPlan.rates[group][contributionField] =
              new NTierContributions();
          } else if (contributionField === 'fourTierContributions') {
            clonedPlan.rates[group][contributionField] =
              new FourTierContributions();
          }
        }

        if (allValues['rateType'] && isUPEdit) {
          clonedPlan.rates[group]['type'] = allValues['rateType'];
        }

        const currentContributions =
          clonedPlan?.rates?.[group]?.[contributionField]?.contributions ?? [];

        let index = isEmpty(currentContributions)
          ? 0
          : currentContributions.findIndex(
              (value: { tierName: string }) =>
                value.tierName === currentTierName
            );
        if (index < 0) {
          index = currentContributions.length;
        }
        if (currentField === RatesColumns.TOTAL_MONTHLY_COST) {
          eeCostKey =
            formData.sameRates === 'Yes'
              ? `${currentBenGroup}.${currentTierName}.employeeCost`
              : `${group}.${currentTierName}.employeeCost`;
          setFormData({
            ...formData,
            monthlyPremiums: {
              ...formData.monthlyPremiums,
              [contributionField]: {
                ...formData.monthlyPremiums[contributionField],
                [currentTierName]: parseFloat(newFieldValue),
              },
            },
          });
        }
        if (
          currentField === RatesColumns.TOTAL_MONTHLY_COST &&
          !newFieldValue
        ) {
          setWith(
            clonedPlan,
            `rates.${group}.${contributionField}.contributions[${index}]`,
            {
              tierName: currentTierName,
              totalCost: 0.0,
              employeeCost: '',
              employeeBiWeeklyCost: '',
              employeeSemiMonthlyCost: '',
              employerCost: '',
              enrollment: currentContributions?.[index]?.enrollment,
              [currentField]: parseFloat(newFieldValue),
            }
          );
        } else {
          setWith(
            clonedPlan,
            `rates.${group}.${contributionField}.contributions[${index}]`,
            {
              tierName: currentTierName,
              totalCost: isNullOrUndefined(form.getFieldValue(totalCostKey))
                ? 0.0
                : form.getFieldValue(totalCostKey),
              employerCost: isNullOrUndefined(
                form.getFieldValue(employerCostKey)
              )
                ? form.getFieldValue(totalCostKey) || 0.0
                : form.getFieldValue(employerCostKey),
              employeeCost: isNullOrUndefined(form.getFieldValue(eeCostKey))
                ? 0.0
                : form.getFieldValue(eeCostKey),
              employeeBiWeeklyCost: isNullOrUndefined(
                form.getFieldValue(employeeBiWeeklyCostKey)
              )
                ? 0.0
                : form.getFieldValue(employeeBiWeeklyCostKey),
              employeeSemiMonthlyCost: isNullOrUndefined(
                form.getFieldValue(employeeSemiMonthlyCostKey)
              )
                ? 0.0
                : form.getFieldValue(employeeSemiMonthlyCostKey),
              enrollment: currentContributions?.[index]?.enrollment,
              [currentField]: parseFloat(newFieldValue),
            }
          );
        }
      });

      const newCalculationData = {
        clonedPlan,
        currentField,
        currentBenGroup,
        currentTierName,
        currentRateType: formData.rateType,
      };

      setCalculationData(newCalculationData);
    }
  };

  const getCalculationData = () => {
    const group =
      calculationData.currentField === RatesColumns.TOTAL_MONTHLY_COST
        ? ALL_CLASSES
        : calculationData.currentBenGroup;
    if (group)
      dispatch(
        getContributionAction(
          calculationData.clonedPlan,
          dbgFrequency || RateFrequency.MONTHLY.value,
          group,
          calculationData.currentTierName,
          calculationData.currentRateType,
          isUPEdit
        )
      );
  };

  const checkEachFieldIsEmpty = (values: any) => {
    const valuesFilled: boolean[] = [];
    Object.keys(values).forEach((key) => {
      if (key.startsWith(MONTHLY_PREMIUM)) {
        valuesFilled.push(isEmpty(values[key]?.toString()));
      }
    });
    return valuesFilled;
  };

  const onRemoveTier = (tierName: string): void => {
    const updatedNTier = cloneDeep(nTiers);
    updatedNTier.splice(nTiers.length - 1, 1);
    setNTiers(updatedNTier);
    setTierChangeWarning(true);
    const updatedFormDataMonthlyPremiums =
      formData.monthlyPremiums.ntierContributions;
    delete updatedFormDataMonthlyPremiums[tierName];
    setFormData({
      ...formData,
      monthlyPremiums: {
        ...formData.monthlyPremiums,
        ntierContributions: updatedFormDataMonthlyPremiums,
      },
    });
    const clonedPlan = cloneDeep(plan);
    const rates: { [key: string]: {} } = {};
    const formFields = {};
    clonedPlan.groups.forEach((group: string) => {
      const rate = clonedPlan.rates[group];
      const eeColumn = getFieldName(
        group,
        tierName,
        RatesColumns.TOTAL_EE_CONTRIBUTION
      );
      const erColumn = getFieldName(
        group,
        tierName,
        RatesColumns.TOTAL_ER_CONTRIBUTION
      );
      const biWeeklyColumn = getFieldName(
        group,
        tierName,
        RatesColumns.BI_WEEKLY__COST
      );
      const semiMonthlyColumn = getFieldName(
        group,
        tierName,
        RatesColumns.SEMI_MONTHLY__COST
      );
      const totalCostColumn = getFieldName(
        group,
        tierName,
        RatesColumns.TOTAL_MONTHLY_COST
      );
      const monthlyPremiumColumn = getFieldName(
        MONTHLY_PREMIUM,
        tierName,
        RatesColumns.TOTAL_MONTHLY_COST
      );
      // clear out all the tier values from antd form instance
      setWith(formFields, [eeColumn], '');
      setWith(formFields, [erColumn], '');
      setWith(formFields, [totalCostColumn], '');
      setWith(formFields, [monthlyPremiumColumn], '');
      setWith(formFields, [biWeeklyColumn], '');
      setWith(formFields, [semiMonthlyColumn], '');
      remove(
        rate.ntierContributions.contributions,
        (contribution: Contribution) => {
          return contribution.tierName === tierName;
        }
      );
      rates[group] = rate;
    });
    form.setFieldsValue(formFields);
    dispatch(updatePlanByPathAction([PanelSection.RATES], rates, isDBGView));
  };

  const convertEnumsToRows = (
    tierEnum: object,
    group: string,
    frequency: string | undefined
  ): ReactNode => {
    return Object.values(tierEnum).map((tier, key) => (
      <ContributionRow
        key={key}
        label={tier.label}
        value={tier.value}
        benGroup={group}
        isNextClicked={isNextClicked}
        form={form}
        frequencies={frequencies}
        frequency={frequency}
        isDBGView={isDBGView}
        setValueChanged={setValueChanged}
        benefitCategory={benefitCategory}
        getContributionInProgress={getContributionInProgress}
        getCalculationData={getCalculationData}
        isReviewHighlight={isReviewHighlight}
      />
    ));
  };

  const convertedArrayToRows = (
    group: string,
    frequency: string | undefined
  ): ReactNode => {
    return nTiers.map((tier, key) => (
      <ContributionRow
        key={key}
        label={tier}
        value={Object.values(NTier)[key].value}
        benGroup={group}
        isNextClicked={isNextClicked}
        form={form}
        frequencies={frequencies}
        frequency={frequency}
        isDBGView={isDBGView}
        setValueChanged={setValueChanged}
        benefitCategory={benefitCategory}
        getContributionInProgress={getContributionInProgress}
        getCalculationData={getCalculationData}
        isReviewHighlight={isReviewHighlight}
      />
    ));
  };
  const convertMonthlyPremiumArrayToRows = (): ReactNode => {
    return nTiers.map((tier, key) => (
      <MonthlyPremiumSection
        key={key}
        label={tier}
        value={Object.values(NTier)[key].value}
        onRemove={() => onRemoveTier(Object.values(NTier)[key].value)}
        showRemoveIcon={key > 1 && key === nTiers.length - 1}
        isNextClicked={isNextClicked}
        form={form}
        benefitCategory={benefitCategory}
        isDBGView={isDBGView}
        isUPEdit={isUPEdit}
        setValueChanged={setValueChanged}
        getContributionInProgress={getContributionInProgress}
        getCalculationData={getCalculationData}
        updateValidationMsg={updateValidationMsg}
        showErrorMsg={requiredFieldError}
        isReviewHighlight={isReviewHighlight}
      />
    ));
  };
  const convertMonthlyPremiumEnumsToRows = (tierEnum: object): ReactNode => {
    return Object.values(tierEnum).map((tier, key) => (
      <MonthlyPremiumSection
        key={key}
        label={tier.label}
        value={tier.value}
        isNextClicked={isNextClicked}
        form={form}
        benefitCategory={benefitCategory}
        isDBGView={isDBGView}
        isUPEdit={isUPEdit}
        setValueChanged={setValueChanged}
        getContributionInProgress={getContributionInProgress}
        getCalculationData={getCalculationData}
        updateValidationMsg={updateValidationMsg}
        showErrorMsg={requiredFieldError}
        isReviewHighlight={isReviewHighlight}
      />
    ));
  };

  const getContributionRows = (
    group: string,
    frequency: string | undefined
  ): ReactNode => {
    switch (form.getFieldValue(RatesFormFields.RATE_TYPE)) {
      case RateType.N_TIER.value:
        return convertedArrayToRows(group, frequency);

      case RateType.FOUR_TIER.value:
        return convertEnumsToRows(FourTier, group, frequency);
    }
  };

  const showTierChangeWarning =
    tierChangeWarning && !isEmpty(plan?.hraPlanId) && isUPEdit && !isDBGView;

  const getMonthlyPremiumRows = (): ReactNode => {
    switch (form.getFieldValue(RatesFormFields.RATE_TYPE)) {
      case RateType.N_TIER.value:
        return convertMonthlyPremiumArrayToRows();

      case RateType.FOUR_TIER.value:
        return convertMonthlyPremiumEnumsToRows(FourTier);
    }
  };

  const getSectionHeader = (title: string): ReactNode => {
    return (
      <div className={styles.rateSectionHeader}>
        <span className={`text-form-label ${styles.rateSectionLabel}`}>
          {title}
        </span>
        <hr />
      </div>
    );
  };

  const getDBGCountText = (count: number): ReactNode => {
    if (count === 0) {
      return <></>;
    }
    if (count === 1) {
      return (
        <div
          className={styles.rateDBGCountIndicator}
        >{`(in ${count} guide)`}</div>
      );
    }
    return (
      <div
        className={styles.rateDBGCountIndicator}
      >{`(in ${count} guides)`}</div>
    );
  };
  const getDBGPerFrequencyComp = (dbgList: any[]) => {
    return dbgList.map((dbg) => {
      const { contributionFrequencies } = dbg;
      const contributionFrequencyByBenefitKind = find(contributionFrequencies, {
        benefitKind: benefitCategory,
      });
      const { enabled } = contributionFrequencyByBenefitKind;
      return (
        <span key={dbg.id}>{`${dbg.name} ${
          enabled ? '' : ' - Display Off'
        }`}</span>
      );
    });
  };
  const getDBGForBenefitGroup = (
    benefitGuides: any[],
    benefitGroup: string
  ) => {
    return (
      benefitGuides?.filter((dbg) => {
        return dbg?.benefitClasses?.includes(benefitGroup);
      }) || []
    );
  };

  const getContributionSection = (
    group: string,
    dbgByFrequency: any,
    isDGBView: boolean
  ): ReactNode => {
    let biWeeklyDBGCount = 0;
    let monthlyDBGCount = 0;
    let semiMonthlyDBGCount = 0;
    let biWeeklyBenefitGuides = [];
    let monthlyBenefitGuides = [];
    let semiMonthlyBenefitGuides = [];
    if (dbgByFrequency && !isEmpty(dbgByFrequency)) {
      biWeeklyBenefitGuides = getDBGForBenefitGroup(
        dbgByFrequency[RateFrequency.BI_WEEKLY.value],
        group
      );
      monthlyBenefitGuides = getDBGForBenefitGroup(
        dbgByFrequency[RateFrequency.MONTHLY.value],
        group
      );
      semiMonthlyBenefitGuides = getDBGForBenefitGroup(
        dbgByFrequency[RateFrequency.SEMI_MONTHLY.value],
        group
      );

      biWeeklyDBGCount = isEmpty(biWeeklyBenefitGuides)
        ? 0
        : biWeeklyBenefitGuides.length;
      monthlyDBGCount = isEmpty(monthlyBenefitGuides)
        ? 0
        : monthlyBenefitGuides.length;
      semiMonthlyDBGCount = isEmpty(semiMonthlyBenefitGuides)
        ? 0
        : semiMonthlyBenefitGuides.length;
    }
    const colSpan = frequencies?.length > 1 ? 24 / frequencies?.length : 24;
    const borderDivHeight =
      form.getFieldValue(RatesFormFields.RATE_TYPE) === RateType.N_TIER.value
        ? nTiers.length * 45 + 70
        : 250;

    const customSpinner = (
      <Icon component={IconLoading} className={styles.spinner} spin />
    );

    return (
      <>
        <div>
          <Row>
            <Col span={2}></Col>
            <Col span={10}>
              {isDGBView && (
                <div
                  className={styles.rateBorder}
                  style={{ height: borderDivHeight }}
                ></div>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={2}></Col>
            <Col span={frequencies.length > 2 ? 12 : 7}>
              <span
                className={`${styles.contributionHeader} ${
                  isDGBView && frequencies.length > 1 && styles.ratesEEDBGHeader
                }`}
              >
                EE Contribution
              </span>
            </Col>
            <Col span={frequencies.length > 2 ? 5 : 7}>
              <span className={styles.contributionHeader}>ER Contribution</span>
            </Col>
            <Col span={frequencies.length > 2 ? 5 : 7}>
              <span className={styles.contributionHeader}>Total Premiums</span>
            </Col>
          </Row>
          <Row className="text service-table-col-header" wrap={false}>
            {isDGBView ? (
              <>
                <Col span={2}></Col>
                <Col span={frequencies.length > 2 ? 12 : 7}>
                  <Row>
                    {frequencies?.includes(RateFrequency.BI_WEEKLY.value) && (
                      <Col span={colSpan}>
                        <div className={styles.ratesContributionSubHeader}>
                          Bi-weekly
                        </div>
                        <Popover
                          content={
                            <div className={styles.contributionSubHeaderText}>
                              {getDBGPerFrequencyComp(biWeeklyBenefitGuides)}
                            </div>
                          }
                          placement="top"
                          overlayClassName="eeContributionPopOver"
                        >
                          {getDBGCountText(biWeeklyDBGCount)}
                        </Popover>
                      </Col>
                    )}
                    {frequencies?.includes(
                      RateFrequency.SEMI_MONTHLY.value
                    ) && (
                      <Col span={colSpan}>
                        <div className={styles.ratesContributionSubHeader}>
                          Semi-Monthly
                        </div>
                        <Popover
                          content={
                            <div className={styles.contributionSubHeaderText}>
                              {getDBGPerFrequencyComp(semiMonthlyBenefitGuides)}
                            </div>
                          }
                          placement="top"
                          overlayClassName="eeContributionPopOver"
                        >
                          {getDBGCountText(semiMonthlyDBGCount)}
                        </Popover>
                      </Col>
                    )}
                    {frequencies?.includes(RateFrequency.MONTHLY.value) && (
                      <Col span={colSpan}>
                        <div className={styles.ratesContributionSubHeader}>
                          Monthly
                        </div>
                        <Popover
                          content={
                            <div className={styles.contributionSubHeaderText}>
                              {getDBGPerFrequencyComp(monthlyBenefitGuides)}
                            </div>
                          }
                          placement="top"
                          overlayClassName="eeContributionPopOver"
                        >
                          {getDBGCountText(monthlyDBGCount)}
                        </Popover>
                      </Col>
                    )}
                  </Row>
                </Col>
              </>
            ) : (
              <>
                <Col span={2}></Col>
                <Col span={7}>
                  <span className={styles.ratesContributionSubHeader}>
                    Monthly
                  </span>
                </Col>
              </>
            )}
            <Col span={frequencies.length > 2 ? 5 : 7}>
              <span className={styles.ratesContributionSubHeader}>Monthly</span>
            </Col>
            <Col span={isDGBView ? 5 : 7}>
              <span className={styles.ratesContributionSubHeader}>Monthly</span>
            </Col>
          </Row>
        </div>

        <div className={styles.contributionTable}>
          {isDBGView && getFrequenciesInProgress ? (
            <div className={styles.spinnerWrapper}>
              <Spin indicator={customSpinner} />
            </div>
          ) : (
            getContributionRows(group, dbgFrequency)
          )}
        </div>
      </>
    );
  };

  const getConflictingContributionMessage = () => {
    if (rateValidations?.hasMismatchContributions) {
      return 'Contributions are hidden from view in the Benefits Guide because contributions are different across Benefit Classes';
    }
    if (validateRates(plan.rates, dbgGroups)) {
      return 'Contributions will be hidden from view in the Benefits Guide when contributions are different across Benefit Classes';
    }
    return '';
  };

  const collapseOnChange = (key: string | string[]): void => {
    setActivePanel(key);
  };

  const onAddTier = (): void => {
    setTierChangeWarning(true);
    setNTiers([...nTiers, NTiers[nTiers.length]]);
  };

  const filteredRateType = Object.values(RateType).filter(
    (rate) =>
      !(
        rate.value === RateType.AGE_BAND.value &&
        benefitCategory === BenefitCategory.DENTAL.value
      )
  );

  return (
    <div className={`${styles.ratesWrapper} ${styles.smallInputs}`}>
      <PanelInputForm
        form={form}
        onValuesChange={onInputChange}
        name="ratesContribution"
        layout="horizontal"
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
        forwardedRef={formRef}
        preserve
      >
        {isRenewalStarted && (isUPEdit || isDBGView) && (
          <FixedAlertMessage
            type={'warning'}
            message={RENEWAL_COMMON_WARNING_MESSAGE_PREMIUM_CONTRIBUTIONS}
          />
        )}
        {showTierChangeWarning && (
          <FixedAlertMessage
            type="warning"
            message={
              <div>
                Changing the tier structure will unlink the associated HRA plan.
                <br />
                <a
                  className={`text-form-label ${styles.planRedirect}`}
                  target="_blank"
                  rel="noreferrer"
                  href={`/brokers/${brokerId}/employers/${employerId}/tax-advantaged-accts/${plan?.hraPlanId}/overview`}
                >
                  {' '}
                  View Plan
                </a>
              </div>
            }
            closeAlert={() => {
              setTierChangeWarning(false);
            }}
            closeText="Close"
            wrapperClassName={styles.alertMessage}
          />
        )}
        {showValidationErrors && (isDBGView || isUPEdit) && (
          <AlertMessage
            type="error"
            message="Please fill in the remaining fields to continue"
            closeAlert={() => {
              setShowValidationErrors(false);
            }}
            wrapperClassName={styles.alertMessage}
          />
        )}
        {isDBGView &&
          (rateValidations?.hasMismatchContributions ||
            validateRates(plan.rates, dbgGroups)) && (
            <Row className={styles.frequencyDetailsRow}>
              <div className={styles.disabledContributionNotice}>
                <Row>
                  <Col span={1}>
                    <ExclamationCircleTwoTone
                      width="3em"
                      height="3em"
                      twoToneColor="#f9c747"
                    />
                  </Col>
                  <Col span={23}>
                    <span>{getConflictingContributionMessage()}</span>
                  </Col>
                </Row>
              </div>
            </Row>
          )}
        {getSectionHeader('Premiums')}
        {isDBGView && (
          <Row className={styles.goToPlansSection}>
            <span className="text-form-label">
              To edit the premiums please access the plan information from
            </span>
            <a
              className={`text-form-label ${styles.planRedirect}`}
              target="_blank"
              rel="noreferrer"
              href={`/brokers/${brokerId}/employers/${employerId}/${benefitCategory.toLowerCase()}/${
                plan.id
              }/overview`}
            >
              here
            </a>
          </Row>
        )}
        <Form.Item
          name={RatesFormFields.RATE_TYPE}
          label="Premium Type"
          labelCol={{
            span:
              (benefitCategory === BenefitCategory.MEDICAL.value &&
                !isUPEdit) ||
              isDBGView
                ? 12
                : benefitCategory === BenefitCategory.MEDICAL.value && isUPEdit
                ? 11
                : 8,
          }}
          wrapperCol={{
            span:
              (benefitCategory === BenefitCategory.MEDICAL.value &&
                !isUPEdit) ||
              isDBGView ||
              (benefitCategory !== BenefitCategory.MEDICAL.value && isUPEdit)
                ? 12
                : benefitCategory === BenefitCategory.MEDICAL.value && isUPEdit
                ? 13
                : 6,
            prefixCls: `ant-col ${
              benefitCategory !== BenefitCategory.MEDICAL.value && !isDBGView
                ? 'ratesPremiumTypeWrapper ant-col'
                : benefitCategory === BenefitCategory.MEDICAL.value && isUPEdit
                ? 'ratesUPEditPremiumWrapper ant-col'
                : ''
            }`,
          }}
        >
          <SelectOptions
            options={convertEnumToOptions(filteredRateType)}
            getPopupContainer={(triggerNode) => triggerNode}
            disabled={isDBGView}
            onChange={(value) => {
              if (value !== plan?.rates[Object.keys(plan?.rates)[0]]?.type) {
                setTierChangeWarning(true);
              } else {
                setTierChangeWarning(false);
              }
            }}
          />
        </Form.Item>
        {form.getFieldValue('rateType') &&
          form.getFieldValue('rateType') !== RateType.AGE_BAND.value && (
            <>
              <Row
                gutter={
                  benefitCategory === BenefitCategory.MEDICAL.value || isDBGView
                    ? 110
                    : 0
                }
              >
                <Col span={8}>
                  <span className="text-form-label">Monthly Premiums</span>
                </Col>
                <Col span={16}>{getMonthlyPremiumRows()}</Col>
                <Col span={8}></Col>
                <Col span={16}>
                  <Col span={3}></Col>
                  <Col>
                    <div>
                      {form.getFieldValue(RatesFormFields.RATE_TYPE) ===
                        RateType.N_TIER.value && (
                        <AddTierButton
                          onAddBtnClicked={onAddTier}
                          btnName="Tier"
                          disabled={nTiers?.length === 7}
                        />
                      )}
                    </div>
                  </Col>
                </Col>
              </Row>
              {getSectionHeader('Employee Contributions')}
              {/* The below warning is shown when a plan is attached to a benguide,
              happens only on MDV plan, the whole plan state is tranfered via
              router state from the overview page */}
              {isUPEdit &&
                !isDBGView &&
                !isEmpty(MDVplan?.benGuideIDRevisions) && (
                  <FixedAlertMessage
                    type={'warning'}
                    message={CONTRIBUTIONS_CHANGE_UP_EDIT_MESSAGE}
                  />
                )}
            </>
          )}
        {form.getFieldValue('rateType') &&
          form.getFieldValue('rateType') !== RateType.AGE_BAND.value &&
          plan.groups?.length !== 1 && (
            <Form.Item
              name={RatesFormFields.SAME_RATES_OPTION}
              label="Same contributions for all Benefit Classes?"
              labelCol={{
                span:
                  benefitCategory === BenefitCategory.MEDICAL.value || isDBGView
                    ? 12
                    : 9,
              }}
              wrapperCol={{
                span:
                  benefitCategory === BenefitCategory.MEDICAL.value || isDBGView
                    ? 12
                    : 6,
                prefixCls: `ant-col ${
                  benefitCategory !== BenefitCategory.MEDICAL.value
                    ? 'sameContribution ant-col'
                    : ''
                }`,
              }}
            >
              <SelectOptions
                disabled={!form.getFieldValue(RatesFormFields.RATE_TYPE)}
                getPopupContainer={(triggerNode: any) =>
                  triggerNode.parentElement
                }
              >
                {choices.map((value, index) => (
                  <Option value={value} key={index}>
                    {value}
                  </Option>
                ))}
              </SelectOptions>
            </Form.Item>
          )}
        {isDBGView &&
          form.getFieldValue('rateType') &&
          form.getFieldValue('rateType') !== RateType.AGE_BAND.value &&
          dbgFrequency &&
          frequencies?.length > 1 && (
            <Row className={styles.frequencyDetailsRow}>
              <div className={styles.contributionEditWarning}>
                <Row>
                  <Col span={2}>
                    <ExclamationCircleTwoTone
                      width="3em"
                      height="3em"
                      twoToneColor="#2B66AA"
                    />
                  </Col>
                  <Col span={22}>
                    <span>
                      {`Editing <${RateFrequency[dbgFrequency].label}>
                    contributions for this benefits guide may affect
                    contributions in other guides using the
                    <${
                      dbgByFrequency
                        ? getOtherGuideFrequencies(dbgByFrequency, dbgFrequency)
                        : ''
                    }> frequencies`}
                    </span>
                  </Col>
                </Row>
              </div>
            </Row>
          )}
        {form.getFieldValue(RatesFormFields.SAME_RATES_OPTION) === choices[0] &&
          form.getFieldValue(RatesFormFields.RATE_TYPE) !==
            RateType.AGE_BAND.value && (
            <>
              <div className={styles.groupSection}>
                <span className="text section-filled-header">
                  All Benefit Classes
                </span>
                <Popover
                  getTooltipContainer={(triggerNode: any) =>
                    triggerNode.parentElement
                  }
                  content={
                    <div className={styles.contributionSubHeaderText}>
                      <span className={styles.popoverHeaderText}>
                        Benefit Classes:
                      </span>
                      {plan.groups.map((group) => (
                        <span key={group}>{group}</span>
                      ))}
                    </div>
                  }
                  placement="left"
                  overlayClassName={styles.allClassesPopOver}
                  trigger="click"
                >
                  <span className={styles.allClassesPopOverLabel}>
                    Show classes
                  </span>
                </Popover>
              </div>
              {getContributionSection(ALL_CLASSES, dbgByFrequency, isDBGView)}
            </>
          )}
        {form.getFieldValue(RatesFormFields.SAME_RATES_OPTION) === choices[1] &&
          form.getFieldValue(RatesFormFields.RATE_TYPE) !==
            RateType.AGE_BAND.value && (
            <Collapse
              bordered={false}
              activeKey={activePanel}
              onChange={collapseOnChange}
            >
              {sortedRateGroups?.map((group: string, index: number) => (
                <Panel
                  header={
                    <div className={styles.benefitGroupSectionHeader}>
                      <span className={styles.groupClassField}>{group}</span>
                      {isDBGView && isGroupExistsOnDBG(group, dbgGroups) && (
                        <span className={styles.groupInThisGuide}>
                          IN THIS GUIDE
                        </span>
                      )}
                    </div>
                  }
                  key={group}
                  showArrow={false}
                  extra={
                    activePanel.includes(group) ? (
                      <UpOutlined />
                    ) : (
                      <DownOutlined />
                    )
                  }
                >
                  {getContributionSection(group, dbgByFrequency, isDBGView)}
                </Panel>
              ))}
            </Collapse>
          )}
      </PanelInputForm>
    </div>
  );
});

Rates.displayName = 'Rates';

export default Rates;
