import { FC, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Col, Row, Tabs } from 'antd';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';

import {
  BASIC_INFO_TAB,
  BROKER_ADMINS_TAB,
  BROKER_LOCATIONS_TAB,
} from 'modules/employers/constants/employerConstants';

import { useNavContext } from 'hooks/useNavContext';
import { brokerBackButtonPermittedTypes } from 'constants/permittedConstants';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import { usePermitIf } from 'hooks/usePermitIf';
import { useAppSelector } from 'hooks/redux';
import { loginTypes } from 'constants/authConstants';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import BrokerAdminList from 'modules/admins/pages/BrokerAdminList/BrokerAdminList';
import BrokerInfoView from 'modules/brokers/pages/BasicInfoView/BrokerInfoView';
import LocationList from 'modules/brokers/pages/LocationList/LocationList';
import { IndividualSubTypes } from 'constants/commonConstants';

import styles from './brokerInfo.module.less';

const { TabPane } = Tabs;

const getActionButtonTitle = (tabKey: string) => {
  switch (tabKey) {
    case BROKER_ADMINS_TAB:
      return 'Add Broker Admin';
    case BROKER_LOCATIONS_TAB:
      return 'Add Location';
    case BASIC_INFO_TAB:
      return 'Edit Basic Info';
    default:
      return '';
  }
};

const BrokerInfo: FC = () => {
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const { state }: any = useLocation();
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(
    !(appBootInfo?.individualSubType === IndividualSubTypes.BROKER_USER)
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeTabKey, setActiveTabKey] = useState<string>(BROKER_ADMINS_TAB);
  const { broker } = useNavContext();
  const [addBrokerAdminModal, setAddBrokerAdminModal] =
    useState<boolean>(false);
  const onTabClick = (key: string) => {
    setActiveTabKey(key);
    setIsModalOpen(false);
    setIsButtonVisible(true);
    if (
      key === BROKER_LOCATIONS_TAB &&
      (appBootInfo?.individualSubType === IndividualSubTypes.BROKER_ADMIN ||
        appBootInfo?.individualSubType === IndividualSubTypes.OPS_ADMIN ||
        appBootInfo?.individualSubType === IndividualSubTypes.BROKER_USER ||
        appBootInfo?.individualSubType ===
          IndividualSubTypes.IMPLEMENTATION_ADMIN)
    ) {
      setIsButtonVisible(false);
    }
    if (
      key === BROKER_ADMINS_TAB &&
      appBootInfo?.individualSubType === IndividualSubTypes.BROKER_USER
    ) {
      setIsButtonVisible(false);
    }
  };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    // If coming from support team configuration page directly open broker admin creation
    if (searchParams.get('isBrokerAdmin')) {
      openBrokerAdminModalAndResetUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const openBrokerAdminModalAndResetUrl = () => {
    setIsModalOpen(true);
    searchParams.delete('isBrokerAdmin');
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    // Update the URL without triggering a page reload
    window.history.pushState({}, '', newUrl);
  };

  const { brokerId } = useNavContext();

  const navigationTechAdmin: { name: string; link: string }[] = [
    { name: 'All Brokers', link: '/brokers' },
  ];

  const navigationBrokerAdmin: { name: string; link: string }[] = [
    { name: 'All Brokers', link: `/brokers/${brokerId}` },
  ];

  const getBreadCrumbData = () => {
    if (appBootInfo?.type === loginTypes.platform) {
      return navigationTechAdmin;
    }

    if (appBootInfo?.type === loginTypes.bokerAdmin) {
      return navigationBrokerAdmin;
    }
  };

  const securedBreadcrumb = usePermitIf(
    <BreadcrumbNavigation breadCrumbData={getBreadCrumbData()} />,
    brokerBackButtonPermittedTypes,
    []
  );

  useEffect(() => {
    if (state && state.tab) {
      setActiveTabKey(state.tab);
    }
  }, [state]);

  return (
    <div className={styles.employerInfo}>
      <Row>
        <Col span={24}>
          <Row>{securedBreadcrumb}</Row>
          <Row className={styles.headerRow}>
            <Col span={12}>
              <h1 className={styles.titleWrapper}>
                <OverflowPopover>
                  {broker ? broker.name : 'Broker'}
                </OverflowPopover>
                &nbsp;Details
              </h1>
            </Col>
            <Col span={12}>
              <PageActionButton
                type="primary"
                className={styles.actionButton}
                onClick={() => {
                  setIsModalOpen(true);
                  setAddBrokerAdminModal(true);
                }}
                hidden={!isButtonVisible}
              >
                {getActionButtonTitle(activeTabKey)}
              </PageActionButton>
            </Col>
          </Row>
          <Tabs
            type="card"
            defaultActiveKey={(state && state.tab) || BROKER_ADMINS_TAB}
            size="large"
            className={styles.employerTabs}
            onTabClick={onTabClick}
          >
            <TabPane tab="Broker Admins" key={BROKER_ADMINS_TAB}>
              <BrokerAdminList
                isModalOpen={activeTabKey === BROKER_ADMINS_TAB && isModalOpen}
                setIsModalOpen={setIsModalOpen}
                setAddBrokerAdminModal={setAddBrokerAdminModal}
                addBrokerAdminModal={addBrokerAdminModal}
              />
            </TabPane>
            <TabPane tab="Locations" key={BROKER_LOCATIONS_TAB}>
              <LocationList
                isModalOpen={
                  activeTabKey === BROKER_LOCATIONS_TAB && isModalOpen
                }
                setIsModalOpen={setIsModalOpen}
                activeTabKey={activeTabKey}
                setShowAddLocationButton={setIsButtonVisible}
              />
            </TabPane>
            <TabPane tab="Basic Info" key={BASIC_INFO_TAB}>
              <BrokerInfoView
                isModalOpen={activeTabKey === BASIC_INFO_TAB && isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default BrokerInfo;
