import { useEffect, useRef, useState } from 'react';
import { Col, DatePicker, Row, Spin } from 'antd';
import moment from 'moment';
import Icon from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import dayjs from 'dayjs';
import BillingBarChart from 'modules/billing/components/BillingBarChart/BillingBarChart';
import BillingTable from 'modules/billing/components/BillingTable/BillingTable';
import { ReactComponent as CalendarIcon } from 'assets/images/icon-calendar.svg';
import { ReactComponent as PopoutIcon } from 'assets/images/icon-popout.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/icon-download-black.svg';
import BillingSummary from 'modules/billing/models/BillingSummary';
import BillingTableExpandable from 'modules/billing/components/BillingTableExpandable/BillingTableExpandable';
import DummyExpandedTable from 'modules/billing/components/DummyExpandedTable/DummyExpandedTable';
import { baseApi } from 'util/apiUtil';
import styles from './billingView.module.less';

const { MonthPicker } = DatePicker;

type BillingViewProps = {
  tableData?: BillingSummary;
  invoiceDate: any;
  setInvoiceDate: Function;
  isNoBillinView?: boolean;
  monthlyTrendFrom: any;
  setMonthlyTrendFrom: Function;
  monthlyTrendTo: any;
  setmonthlyTrendTo: Function;
  avaiableMonthsData: any;
  chartData: any;
  isCarrierFileEnabled?: boolean;
  billingHistoryIsOpen?: boolean;
  employerName?: string;
  billingMonth?: number;
  billingYear?: number;
  isLoading?: boolean;
};

const BillingView = (props: BillingViewProps) => {
  const {
    tableData = {} as BillingSummary,
    invoiceDate,
    setInvoiceDate,
    isNoBillinView = false,
    monthlyTrendFrom,
    setMonthlyTrendFrom,
    monthlyTrendTo,
    setmonthlyTrendTo,
    avaiableMonthsData,
    isCarrierFileEnabled = true,
    chartData,
    billingHistoryIsOpen = false,
    employerName,
    billingMonth,
    billingYear,
    isLoading = false,
  } = props;

  const [avaiableMonths, setAvaiableMonths] = useState<any>([]);
  const [toPickerOpen, setToPickerOpen] = useState<boolean>(false);
  const componentRef = useRef(null);
  const month = dayjs()
    .month(billingMonth ? billingMonth - 1 : 0)
    .format('MMMM');

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${employerName}_${month}_${billingYear}_Invoice.pdf`,
  });

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  useEffect(() => {
    if (avaiableMonthsData !== undefined) {
      const arr = [] as any;
      avaiableMonthsData.map((data: any) => {
        const month = capitalizeFirstLetter(data.month);
        const year = data.year;
        arr.push('01/' + month + '/' + year);
      });
      setAvaiableMonths(arr);
    }
  }, [avaiableMonthsData]);

  useEffect(() => {
    if (monthlyTrendTo === null && !billingHistoryIsOpen) setToPickerOpen(true);
    else setToPickerOpen(false);
  }, [monthlyTrendFrom, monthlyTrendTo, billingHistoryIsOpen]);

  useEffect(() => {
    if (monthlyTrendTo === null && !billingHistoryIsOpen) setToPickerOpen(true);
    else setToPickerOpen(false);
  }, [monthlyTrendFrom, monthlyTrendTo, billingHistoryIsOpen]);

  const downloadTemplateFile = (type: string) => {
    return `${baseApi}/v2/billing/${
      tableData?.billingId ?? null
    }/files/export?type=${type}`;
  };

  return (
    <div className={styles.billingView}>
      {isNoBillinView ? (
        ''
      ) : (
        <>
          <div className={styles.subTitle}>Monthly Trend</div>
          <Row>
            <Col xs={18} sm={18} lg={18} md={18}>
              <div className={styles.actionContainer}>
                <div className={`${styles.actions} ${styles.titleRight}`}>
                  <span className={styles.actionsTitle}>Viewing:</span>
                  <div className="insight-calendar-picker-container">
                    <MonthPicker
                      dropdownClassName="insights-range-picker"
                      className={styles.range}
                      format="MMM YYYY"
                      inputReadOnly
                      onClick={() => setToPickerOpen(false)}
                      allowClear={false}
                      value={monthlyTrendFrom}
                      onChange={(month) => {
                        setmonthlyTrendTo(null);
                        setMonthlyTrendFrom(month);
                        setToPickerOpen(true);
                      }}
                      suffixIcon={
                        <CalendarIcon className={styles.calenderIcon} />
                      }
                    />
                  </div>
                  <div className={styles.hypen}>&ndash;</div>
                  <div className="insight-calendar-picker-container">
                    <MonthPicker
                      dropdownClassName="insights-range-picker"
                      format="MMM YYYY"
                      inputReadOnly
                      open={toPickerOpen}
                      onClick={() => setToPickerOpen(true)}
                      disabledDate={(current) => {
                        let lowest = null as any;
                        let highest = null as any;
                        avaiableMonths.map((data: any, index: any) => {
                          const dataFormatted = moment(
                            moment(data).month() +
                              1 +
                              '/01/' +
                              moment(data).year()
                          );

                          if (lowest === null) {
                            if (
                              dataFormatted.isBetween(
                                monthlyTrendFrom,
                                moment(monthlyTrendFrom).add(24, 'months')
                              ) ||
                              dataFormatted.isSame(monthlyTrendFrom)
                            ) {
                              lowest = dataFormatted;
                              highest = dataFormatted;
                            }
                          } else {
                            if (
                              (dataFormatted.isBetween(
                                monthlyTrendFrom,
                                moment(monthlyTrendFrom).add(24, 'months')
                              ) ||
                                dataFormatted.isSame(monthlyTrendFrom)) &&
                              dataFormatted.isSameOrBefore(lowest)
                            )
                              lowest = dataFormatted;
                            if (
                              (dataFormatted.isBetween(
                                monthlyTrendFrom,
                                moment(monthlyTrendFrom).add(24, 'months')
                              ) ||
                                dataFormatted.isSame(monthlyTrendFrom)) &&
                              dataFormatted.isSameOrAfter(lowest)
                            )
                              highest = dataFormatted;
                          }
                        });
                        return (
                          moment(monthlyTrendFrom).add(24, 'months') <
                            current ||
                          moment(monthlyTrendFrom) >= current ||
                          lowest >= current ||
                          highest < moment(monthlyTrendFrom)
                        );
                      }}
                      className={styles.range}
                      allowClear={false}
                      value={monthlyTrendTo}
                      onChange={(month) => {
                        setmonthlyTrendTo(month);
                        setToPickerOpen(false);
                      }}
                      suffixIcon={
                        <CalendarIcon className={styles.calenderIcon} />
                      }
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      <BillingBarChart
        selectedBar={invoiceDate}
        setSelectedBar={setInvoiceDate}
        chartData={chartData}
        trendToClose={setToPickerOpen}
        isNoView={isNoBillinView ? true : false}
      />

      {isNoBillinView ? (
        <BillingTable
          benifitSummary={{}}
          fileType={tableData.billingFileType}
          isNoView={true}
        />
      ) : (
        <div className={styles.invoiceWrapper}>
          <div className={styles.subTitle}>Invoice</div>
          <span>
            <div className={styles.actionContainerInvoice}>
              <div className={`${styles.actions} ${styles.titleRight}`}>
                <span className={styles.actionsTitle}>Viewing Invoice:</span>
                <div className="insight-calendar-picker-container">
                  <MonthPicker
                    dropdownClassName="insights-range-picker"
                    className={styles.range}
                    format="MMM YYYY"
                    allowClear={false}
                    value={invoiceDate}
                    onClick={() => setToPickerOpen(false)}
                    onChange={(month) => {
                      setInvoiceDate(month);
                    }}
                    disabledDate={(current) => {
                      return !avaiableMonths.includes(
                        current.format('01/MMMM/YYYY')
                      );
                    }}
                    inputReadOnly
                    suffixIcon={
                      <CalendarIcon className={styles.calenderIcon} />
                    }
                  />
                </div>
              </div>
            </div>
            {!isLoading ? (
              <span className={styles.downloadLinks}>
                <a
                  className={styles.billingDownloadFileLink}
                  href={downloadTemplateFile('MODIFIED_SUMMARY')}
                >
                  Download Excel file
                  <DownloadIcon className={styles.billingDownloadFileIcon} />
                  <Icon type="file-text" />
                </a>
                {!isCarrierFileEnabled ? (
                  <a
                    className={styles.billingDownloadFileLink}
                    href={downloadTemplateFile('CARRIER')}
                  >
                    Download Carrier files
                    <DownloadIcon className={styles.billingDownloadFileIcon} />
                    <Icon type="file-text" />
                  </a>
                ) : (
                  ''
                )}
                <a
                  className={styles.billingDownloadFileLink}
                  onClick={handlePrint}
                >
                  Export as PDF
                  <PopoutIcon
                    type="file-text"
                    className={styles.billingPopoutFileIcon}
                  />
                </a>
              </span>
            ) : (
              ''
            )}
          </span>

          {isLoading ? (
            <Spin className={styles.spinner} />
          ) : (
            tableData?.carrierSummaries.map((item: any, index) => {
              return (
                <BillingTableExpandable
                  key={index}
                  benifitSummary={item ?? {}}
                  isPrintable={false}
                />
              );
            })
          )}
          <div className={styles.printableBillingContent}>
            {isLoading ? (
              <div></div>
            ) : (
              <DummyExpandedTable
                benifitSummary={tableData ?? {}}
                isPrintable={true}
                ref={componentRef}
                monthlyTotal={tableData.monthlyTotal}
                employerName={employerName}
                billingMonth={month}
                billingYear={billingYear}
              />
            )}
          </div>
        </div>
      )}

      {isNoBillinView || isLoading ? (
        ''
      ) : (
        <div>
          <hr className={styles.seperator}></hr>
          <div className={styles.totalDueText}>Total Due</div>
          <div className={styles.totalDueContent}>
            {tableData.monthlyTotal !== 0
              ? tableData.monthlyTotal.toString().indexOf('-') !== -1
                ? '($' +
                  tableData.monthlyTotal
                    .toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                    .toString()
                    .replace('-', '') +
                  ')'
                : '$' +
                  tableData.monthlyTotal
                    .toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                    .toString()
              : '-'}
          </div>
        </div>
      )}
    </div>
  );
};
export default BillingView;
