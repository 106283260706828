import notification, { ArgsProps, IconType } from 'antd/lib/notification';

import IconError from 'assets/images/alert-failure.svg';
import IconCheck from 'assets/images/checkmark.svg';
import styles from './notification.module.less';

const getIconSrc = (type: IconType) => {
  switch (type) {
    case 'error':
      return IconError;
    case 'success':
    default:
      return IconCheck;
  }
};

export const showNotification = ({ type, ...args }: ArgsProps) => {
  notification.open({
    placement: 'topRight',
    duration: 3,
    closeIcon: <></>,
    className: `${styles.notification} ${args.className}`,
    ...(type
      ? {
          icon: (
            <img
              className={styles.notificationIcon}
              alt=""
              src={getIconSrc(type)}
            />
          ),
        }
      : {}),
    ...args,
  });
};
