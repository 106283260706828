import axios from 'axios';
import {
  baseApi,
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_MULTIPART_FORM,
  getPagingQueryString,
} from 'util/apiUtil';
import { AdditionalPlan } from 'model/plans/AdditionalPlan';

const planUrl = baseApi + '/v1/plans';

export const createTelehealthRxPlan = (
  payload: AdditionalPlan,
  update?: boolean
) => {
  const newPayload = { ...payload };
  const formData = new FormData();
  const entries = Object.entries(newPayload);
  entries.forEach((item) => {
    if (item[1] !== null) {
      if (item[0] === 'groups') {
        const encodedGroups = item[1].map((group: string) =>
          encodeURIComponent(group)
        );
        formData.append(item[0], encodedGroups);
      } else {
        formData.append(item[0], item[1]);
      }
    }
  });

  delete newPayload.file;
  delete newPayload.fileName;

  const config = {
    headers: {
      [CONTENT_TYPE_HEADER]: CONTENT_TYPE_MULTIPART_FORM,
    },
  };
  if (update) {
    return axios.put(`${planUrl}/telehealth/${payload.id}`, formData, config);
  } else {
    return axios.post(`${planUrl}/telehealth`, formData, config);
  }
};

export const getTelehealthRxPlanList = async (
  page: number,
  size: number,
  sort: string,
  query: string,
  employerId: string,
  planYearId: string
) => {
  return axios.get(
    `${planUrl}/telehealth/roster?${getPagingQueryString(
      page,
      size,
      sort,
      query
    )}`,
    {
      params: {
        'employer-id': employerId,
        'plan-year-id': planYearId,
      },
    }
  );
};

export const getTelehealthPlanById = (planId: string) => {
  return axios.get(`${planUrl}/telehealth/${planId}`);
};
