import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Col, Form, Input, Row, Select, Spin } from 'antd';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useAddNewCarrierState } from 'hooks/useAddNewCarrierState';

import Carrier from 'model/Carrier';
import InputForm from 'components/InputForm/InputForm';
import NextButton from 'components/buttons/NextButton/NextButton';
import {
  ADD_NEW_CARRIER_MODAL_CONSTANTS,
  EMPTY_MESSAGE,
  addNewCarrierModalDescription,
  IndividualSubTypes,
} from 'constants/commonConstants';
import AlertMessage from 'components/Alert/AlertMessage';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import {
  ALTERNATIVE,
  BENEFIT_CARRIER_DROPDOWN_BENEFIT_TYPE,
  FIXED_ALERT_MESSAGE_IF_FINAL_APPROVED,
  OFFER_STATUSES,
  OFFER_TYPES,
  OFFER_BENEFIT_TYPES,
  RENEWAL,
  URL_PARAM_TO_BENEFIT_TYPE,
  WARNING_IS_ELIGIBLE_MODAL_DES,
  FUNDING_TYPES,
  OFFERED_PLAN_PROCESSING_WARNING,
} from 'modules/renewals/constants/renewalsConstants';
import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import { useLazyGetOfferExistsQuery } from 'modules/renewals/slices/offersSlice';
import { getCarriersByBenefitKind } from 'modules/plans/slices/basicPlanInfoSlice';
import {
  getBenefitTitleFromUrlParam,
  getEffectiveDateFormatted,
  handleRedirectToProposalsDetails,
} from 'modules/renewals/utils/renewalsUtils';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import CheckboxSelect from 'components/CheckboxSelect/CheckboxSelect';
import AddNewCarrierModal from 'components/AddNewCarrierModal/AddNewCarrierModal';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import ConfirmationWithThreeButtons from 'components/ConfirmationWithThreeButtons/ConfirmationWithThreeButtons';
import SelectAddMoreButton from 'components/buttons/SelectAddMoreButton/SelectAddMoreButton';
import UpdateConfirmationListModal from 'modules/renewals/components/helperComponents/UpdateConfirmationListModal/UpdateConfirmationListModal';

import FundingType from 'modules/plans/enums/FundingType';
import CarrierType from 'modules/carriers/enums/CarrierType';
import styles from './createOfferInfoForm.module.less';

type CreateOfferInfoFormProps = {
  edit: boolean;
  nextStep?: (formValues: any) => void;
  nextOnTopNav?: (formValues: any) => void;
  closeModal?: () => void;
  offer: any;
  setCarrierImage: Function;
  isDuplicateNameError: boolean;
  offerAttachedProposal: any;
  editInitialData: any;
  setCarrierName: Function;
  setUpdatedData: Function;
  setOfferStatus: Function;
  isClonedOffer?: boolean;
  updatedData: any;
};

type FromDataTypes = {
  offerName: string;
  carrier: string;
  carrierType: string;
  effectiveDates: string;
  offerStatus: string;
  offerDescription: string;
  carrierId: string;
  fundingType: string;
};

export type CreateOfferInfoFormRef = {
  validateForm: () => Promise<boolean>;
  getIsValidUpdate: () => boolean;
};

const CreateOfferInfoForm = (
  props: CreateOfferInfoFormProps,
  ref: Ref<any>
) => {
  const {
    nextStep,
    nextOnTopNav,
    closeModal,
    edit,
    offer,
    setCarrierImage,
    isDuplicateNameError,
    offerAttachedProposal,
    editInitialData,
    setCarrierName,
    setOfferStatus,
    isClonedOffer,
    setUpdatedData,
    updatedData,
  } = props;
  const params = useParams();
  const dispatch = useAppDispatch();
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);

  const [
    getUpcomingPlanYears,
    {
      isSuccess: isUpcomingFetchSuccess,
      isFetching: isUpcomingFetching,
      isLoading: isUpcomingLoading,
      data: upcomingData,
    },
  ] = useLazyGetUpcomingPlanYearsByEmployerQuery();

  const [getOfferNameExists] = useLazyGetOfferExistsQuery();
  const basicPlanInfo = useAppSelector((state) => state.plan.planBasicInfo);
  const empInfo = useAppSelector((state) => state.layout?.employer);
  const { carriersList, carriersListLoading } = basicPlanInfo;

  const [requireFieldErrorFound, setRequireFieldErrorFound] =
    useState<boolean>(false);
  const [characterLimitError, setCharacterLimitError] =
    useState<boolean>(false);
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [isRenewalOffer, setIsRenewalOffer] = useState<boolean>(false);
  const [effectiveDates, setEffectiveDates] = useState<string[]>([]);
  const [showNameExistsValidation, setShowNameExistsValidation] =
    useState<boolean>(false);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<FromDataTypes>({
    offerName: offer.offerName,
    carrier: '',
    carrierType: '',
    effectiveDates: '',
    offerStatus: '',
    offerDescription: '',
    carrierId: '',
    fundingType: offer.fundingType,
  });

  const [showUpdateOfferInPropModal, setShowUpdateOfferInPropModal] =
    useState(false);
  const [previousUpdatedData, setPreviousUpdatedData] = useState<any>(null);

  const {
    carrierCreateTypeConfirmation,
    setCarrierCreateTypeConfirmation,
    openCarrierModal,
    setOpenCarrierModal,
  } = useAddNewCarrierState();

  const isSTDOffer =
    OFFER_BENEFIT_TYPES.STD === getBenefitTitleFromUrlParam(params.benefitKind);

  useEffect(() => {
    const date = offer?.effectiveDates as any[];

    form.setFieldsValue({
      offerName: offer?.offerName,
      carrierId: offer?.carrierId,
      carrierType: offer?.carrierType,
      effectiveDates:
        offer?.effectiveDates?.length === 1
          ? date?.[0]?.label
          : offer?.effectiveDates,
      offerStatus: offer?.offerStatus,
      fundingType: offer?.fundingType ?? '',
    });
    setDescription(offer?.offerDescription);
    setIsRenewalOffer(offer?.type === RENEWAL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  const getCarrierImage = () => {
    return carriersList.find((item: any) => item?.id === offer?.carrierId);
  };

  const isMDVOffer = [
    OFFER_BENEFIT_TYPES.MEDICAL,
    OFFER_BENEFIT_TYPES.DENTAL,
    OFFER_BENEFIT_TYPES.VISION,
  ].includes(getBenefitTitleFromUrlParam(params.benefitKind) as any);

  const showFundingType = [
    OFFER_BENEFIT_TYPES.DENTAL,
    OFFER_BENEFIT_TYPES.VISION,
    OFFER_BENEFIT_TYPES.MEDICAL,
  ].includes(getBenefitTitleFromUrlParam(params.benefitKind) as any);

  const onClickNext = async () => {
    const isFormValid = await validateBasicInfo();

    if (isFormValid) {
      nextStep &&
        nextStep({
          ...form.getFieldsValue(),
          type: isRenewalOffer ? RENEWAL : ALTERNATIVE,
        });
    } else {
      setRequireFieldErrorFound(true);
    }
    setShowNameExistsValidation(isDuplicateNameError);
  };

  const validateBasicInfo = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (errorInfo: any) {
      return errorInfo.errorFields.length === 0;
    }
  };

  useImperativeHandle(ref, () => ({
    getOfferBasicInfoFormData: async () => {
      const fieldValues = await form.getFieldsValue();
      return { ...fieldValues, type: isRenewalOffer ? RENEWAL : ALTERNATIVE };
    },

    onSubmitNext: async () => {
      let isFormValid = await validateBasicInfo();

      const res = await getOfferNameExists({
        employerId: params?.employerId ?? '',
        status: 'PUBLISHED',
        name: encodeURIComponent(form?.getFieldValue('offerName')?.trim()),
        category: URL_PARAM_TO_BENEFIT_TYPE?.[params?.benefitKind ?? ''],
        offerId: offer?.offerId,
      }).unwrap();

      if (
        (isClonedOffer || !edit) &&
        (res?.existsDraftOffer || res?.existsPublishedOffer)
      ) {
        setShowNameExistsValidation(true);
        isFormValid = false;
      } else if (isFormValid) {
        nextOnTopNav &&
          nextOnTopNav({
            ...form.getFieldsValue(),
            type: isRenewalOffer ? RENEWAL : ALTERNATIVE,
          });
      } else {
        setRequireFieldErrorFound(true);
      }
      return isFormValid;
    },
    getIsValidUpdate: isValidUpdate,
  }));

  const onInputChange = (changedValues: any, allValues: any) => {
    form.setFieldsValue(allValues);
    setFormData({ ...formData, ...allValues });
  };

  const setEffectiveDatesForDropDown = () => {
    if (upcomingData) {
      const mappedSet: any[] =
        upcomingData?.upcomingPlanYears
          ?.filter((obj) => null === obj.planYearId)
          .map((obj: any, index: number) => {
            return {
              label: dayjs(obj.effectiveStartDate)
                .format('ll')
                .concat(' - ', dayjs(obj.effectiveEndDate).format('ll')),
              value: (index + 1).toString(),
            };
          }) || [];

      if (mappedSet?.length === 1) {
        form.setFieldsValue({
          offerName: offer?.offerName,
          carrierId: offer?.carrierId,
          carrierType: offer?.carrierType,
          effectiveDates:
            mappedSet?.length === 1
              ? mappedSet?.[0]?.label
              : offer?.effectiveDates,
          offerStatus: offer?.offerStatus,
          fundingType: offer?.fundingType ?? '',
        });
        setDescription(offer?.offerDescription);
        setIsRenewalOffer(offer?.type === RENEWAL);
      }

      setEffectiveDates(mappedSet);
    }
  };

  useEffect(() => {
    if (params.employerId) {
      getUpcomingPlanYears({ employerId: String(params.employerId) });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.employerId]);

  useEffect(() => {
    setCarrierImage(getCarrierImage()?.logoUrl);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer.carrierId]);

  useEffect(() => {
    if (isUpcomingFetchSuccess && upcomingData) {
      setEffectiveDatesForDropDown();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpcomingFetchSuccess, upcomingData, offer]);

  useEffect(() => {
    if (params.brokerId && params.employerId) {
      dispatch(
        getCarriersByBenefitKind(
          BENEFIT_CARRIER_DROPDOWN_BENEFIT_TYPE?.[
            params?.benefitKind?.toUpperCase() ?? ''
          ],
          params.brokerId,
          params.employerId
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setShowNameExistsValidation(isDuplicateNameError);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDuplicateNameError, offer?.offerName]);

  const isValidUpdate = useCallback((): boolean => {
    if (props.edit && offerAttachedProposal?.list?.length !== 0) {
      const { effectiveDateFrom, effectiveDateTo, offerStatus } =
        editInitialData || {};
      const currentEffectiveDates = form.getFieldValue('effectiveDates');
      const currentOfferStatus = [
        OFFER_STATUSES.DTQ.value,
        OFFER_STATUSES.PENDING.value,
      ].includes(form.getFieldValue('offerStatus'))
        ? form.getFieldValue('offerStatus')
        : false;
      const nullCheckedStatus =
        offerStatus === null ? OFFER_STATUSES.RECEIVED.value : offerStatus;
      const checkDate =
        currentEffectiveDates !==
        getEffectiveDateFormatted(effectiveDateFrom, effectiveDateTo);
      const checkOfferStatus = currentOfferStatus
        ? currentOfferStatus !== nullCheckedStatus
        : false;

      return checkDate || checkOfferStatus;
    }

    return false;
    // eslint-disable-next-line
  }, [form, editInitialData, offerAttachedProposal?.list]);

  const [showStatusChangeWarning, setShowStatusChangeWarning] = useState(false);

  const shouldShowStatusChangeWarning = useCallback(() => {
    if (editInitialData?.isRecommended ?? false) {
      const currentOfferStatus = form.getFieldValue('offerStatus');
      if (
        currentOfferStatus === OFFER_STATUSES.DTQ.value ||
        currentOfferStatus === OFFER_STATUSES.PENDING.value
      )
        return true;
    }
    return false;
  }, [form, editInitialData]);

  const handleOfferStatusChange = (value: any) => {
    setOfferStatus(value);
    setShowStatusChangeWarning(shouldShowStatusChangeWarning());
  };

  const handleSetCarrierToForm = (carrier: {
    label: string;
    value: string;
  }) => {
    form.setFieldsValue({
      carrierId: carrier?.value,
    });
    setFormData({ ...formData, carrierId: carrier?.value });
    setCarrierName(carrier?.label);
    if (carrier?.value === offer?.carrierId) {
      setUpdatedData(previousUpdatedData);
    } else {
      setPreviousUpdatedData(
        previousUpdatedData === null ? updatedData : previousUpdatedData
      );
      setUpdatedData(null);
    }
  };

  useEffect(() => {
    if (previousUpdatedData) {
      setUpdatedData(previousUpdatedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousUpdatedData, updatedData]);

  if (carriersListLoading || isUpcomingFetching || isUpcomingLoading) {
    return (
      <div className={styles.formWrapper}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <div className={styles.formWrapper}>
        {requireFieldErrorFound && (
          <AlertMessage
            type="error"
            message="Please correct all items highlighted in red."
            closeAlert={() => {
              setRequireFieldErrorFound(false);
            }}
            className={styles.alertMessage}
          />
        )}
        {showNameExistsValidation && (
          <AlertMessage
            type="error"
            message="An offer with this name already exists"
            closeAlert={() => {
              setShowNameExistsValidation(false);
            }}
            className={styles.alertMessage}
          />
        )}
        {offerAttachedProposal?.isFinalApproved ? (
          <FixedAlertMessage
            type="info"
            message={FIXED_ALERT_MESSAGE_IF_FINAL_APPROVED}
          />
        ) : null}

        {offer?.isOfferedPlanProcessing && (
          <FixedAlertMessage
            message={OFFERED_PLAN_PROCESSING_WARNING}
            type="warning"
          />
        )}
        <InputForm form={form} onValuesChange={onInputChange}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="offerName"
                label="Offer Name"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                  },
                ]}
              >
                <Input data-cy="offerName" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={48}>
            <Col span={isMDVOffer || isSTDOffer ? 12 : 24}>
              <Form.Item
                name="carrierId"
                label="Carrier"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  size="large"
                  onChange={(value, option: any) => {
                    setCarrierName(option?.label);
                    if (option?.value === offer?.carrierId) {
                      setUpdatedData(previousUpdatedData);
                    } else {
                      setPreviousUpdatedData(
                        previousUpdatedData === null
                          ? updatedData
                          : previousUpdatedData
                      );
                      setUpdatedData(updatedData);
                    }
                  }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    (option!.label as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase().trim())
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                  options={carriersList?.map((carrier: Carrier) => ({
                    label: carrier.name,
                    value: carrier.id,
                  }))}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <SelectAddMoreButton
                        onClick={() => {
                          appBootInfo?.individualSubType !==
                          IndividualSubTypes.BROKER_USER
                            ? setCarrierCreateTypeConfirmation({
                                show: true,
                                carrierType: '',
                              })
                            : setOpenCarrierModal(true);
                        }}
                        label="Add New Carrier"
                      />
                    </>
                  )}
                />
              </Form.Item>
            </Col>
            {isSTDOffer && (
              <Col span={12}>
                <Form.Item
                  name="fundingType"
                  label="Funding Type"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      message: EMPTY_MESSAGE,
                      whitespace: true,
                      required: true,
                    },
                  ]}
                >
                  <Select
                    size="large"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <Select.Option
                      key="fullyInsured"
                      value={FundingType.FULLY_INSURED}
                    >
                      Fully-Insured
                    </Select.Option>
                    <Select.Option
                      key="selfFunded"
                      value={FundingType.SELF_FUNDED}
                    >
                      Self-Funded
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            )}

            {isMDVOffer && (
              <Col span={12}>
                <Form.Item
                  name="carrierType"
                  label={'Carrier Type'}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      message: EMPTY_MESSAGE,
                      whitespace: true,
                      required: true,
                    },
                  ]}
                >
                  <Select
                    size="large"
                    onChange={(value) => {}}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <Select.Option
                      key="primaryCarrier"
                      value={OFFER_TYPES.PRIMARY}
                    >
                      Primary
                    </Select.Option>
                    <Select.Option
                      key="supplementaryCarrier"
                      value={OFFER_TYPES.SUPPLEMENTARY}
                    >
                      Supplementary
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={48}>
            <Col span={12}>
              <Form.Item
                name="effectiveDates"
                label="Effective Dates"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  size="large"
                  value={''}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  loading={isUpcomingFetching}
                >
                  {effectiveDates.map((effectiveDate) => {
                    const dateRange = effectiveDate as any;
                    return (
                      <Select.Option
                        key={dateRange.label}
                        value={dateRange.label}
                      >
                        {dateRange.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="offerStatus"
                label="Offer Status"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                    required: true,
                  },
                ]}
              >
                <Select
                  size="large"
                  onChange={handleOfferStatusChange}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Select.Option
                    key="receivedStatus"
                    value={OFFER_STATUSES.RECEIVED.value}
                  >
                    Received
                  </Select.Option>
                  <Select.Option
                    key="pendingStatus"
                    value={OFFER_STATUSES.PENDING.value}
                  >
                    Pending
                  </Select.Option>
                  <Select.Option
                    key="dtqStatus"
                    value={OFFER_STATUSES.DTQ.value}
                  >
                    DTQ
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {showFundingType && (
            <Row gutter={48}>
              <Col span={12}>
                <Form.Item
                  name="fundingType"
                  label="Funding Type"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: EMPTY_MESSAGE,
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    size="large"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <Select.Option
                      key="fullyInsured"
                      value={FUNDING_TYPES.FULLY_INSURED.value}
                    >
                      {FUNDING_TYPES.FULLY_INSURED.label}
                    </Select.Option>
                    <Select.Option
                      key="selfFunded"
                      value={FUNDING_TYPES.SELF_FUNDED.value}
                    >
                      {FUNDING_TYPES.SELF_FUNDED.label}
                    </Select.Option>
                    <Select.Option
                      key="levelFunded"
                      value={FUNDING_TYPES.LEVEL_FUNDED.value}
                    >
                      {FUNDING_TYPES.LEVEL_FUNDED.label}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <Form.Item
                name="offerDescription"
                label="Offer Description"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: false,
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                  },
                ]}
                className={characterLimitError ? styles.labelError : ''}
              >
                <div className={styles.inlineTextEditorWrapper}>
                  <RichTextEditor
                    initialValue={description}
                    placeholder={''}
                    onChange={(value: any) => {
                      const textString = value.replace(/(<([^>]+)>)/gi, '');
                      setDescription(value);
                      if (textString.length > 2000) {
                        setCharacterLimitError(true);
                      } else {
                        setCharacterLimitError(false);
                        form.setFieldsValue({
                          ['offerDescription']: value,
                        });
                      }
                    }}
                    limit={2000}
                    countString="Character Limit"
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: false,
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                  },
                ]}
              >
                <CheckboxSelect
                  checked={isRenewalOffer}
                  onChange={(e) => setIsRenewalOffer(e.target.checked)}
                >
                  Mark as Renewal Offer
                </CheckboxSelect>
              </Form.Item>
            </Col>
          </Row>
        </InputForm>

        {isValidUpdate() ? (
          <div className={styles.warningWrapper}>
            <p>
              This offer is associated with{' '}
              <span onClick={() => setShowUpdateOfferInPropModal(true)}>
                existing proposals
              </span>
              . To publish any changes, the offer must be removed from them
              first. You may still save this offer as a draft.
            </p>
          </div>
        ) : null}

        {showStatusChangeWarning ? (
          <div className={styles.warningWrapper}>
            <p>
              This offer is currently marked as {"'Recommended'"}. Changing its
              status to {"'DTQ'"} or {"'Pending'"} will automatically remove the{' '}
              {"'Recommended'"} label.
            </p>
          </div>
        ) : null}

        <NextButton
          nextStep={onClickNext}
          disabled={false}
          className={styles.nextBtn}
        />
        <Row justify="center" className={styles.margins}>
          <Col>
            <CancelButton htmlType="button" onClick={closeModal} block>
              Cancel
            </CancelButton>
          </Col>
        </Row>
      </div>
      <UpdateConfirmationListModal
        visible={showUpdateOfferInPropModal}
        confirmText="Close"
        onConfirm={() => setShowUpdateOfferInPropModal(false)}
        title="Update Offers in Proposal"
        listTitle="Proposal name"
        description={WARNING_IS_ELIGIBLE_MODAL_DES}
        isCloseOnly={true}
        confirmBtnFullWidth={true}
        listData={offerAttachedProposal.list}
        closeOnlyBtnFullWidth={true}
        onClickItem={(item: any) =>
          handleRedirectToProposalsDetails(item.value, params)
        }
      />
      <ConfirmationWithThreeButtons
        visible={carrierCreateTypeConfirmation.show}
        width={464}
        centered
        title={ADD_NEW_CARRIER_MODAL_CONSTANTS.title}
        firstButtonText={ADD_NEW_CARRIER_MODAL_CONSTANTS.firstButtonLabel}
        firstButtonAction={() => {
          setCarrierCreateTypeConfirmation({
            show: false,
            carrierType: CarrierType.BROKER,
          });
          setOpenCarrierModal(true);
        }}
        secondButtonText={ADD_NEW_CARRIER_MODAL_CONSTANTS.secondButtonLabel}
        secondButtonAction={() => {
          setCarrierCreateTypeConfirmation({
            show: false,
            carrierType: CarrierType.EMPLOYER,
          });
          setOpenCarrierModal(true);
        }}
        onCancel={() => {
          setCarrierCreateTypeConfirmation({
            show: false,
            carrierType: '',
          });
        }}
      >
        {addNewCarrierModalDescription(empInfo?.name!)}
      </ConfirmationWithThreeButtons>
      <AddNewCarrierModal
        isOpen={openCarrierModal}
        setIsOpen={setOpenCarrierModal}
        selectedCarrierType={
          appBootInfo?.individualSubType === IndividualSubTypes.BROKER_USER
            ? CarrierType.EMPLOYER
            : carrierCreateTypeConfirmation.carrierType
        }
        benefitType={
          BENEFIT_CARRIER_DROPDOWN_BENEFIT_TYPE?.[
            params?.benefitKind?.toUpperCase() ?? ''
          ]!
        }
        setCarrierToForm={handleSetCarrierToForm}
        brokerId={params.brokerId!}
        employerId={params.employerId!}
      />
    </>
  );
};

export default forwardRef(CreateOfferInfoForm);
