import { FC, useCallback, useEffect, useState } from 'react';

import { Row, Select, SelectProps } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import isEmpty from 'lodash/isEmpty';
import filterIcon from 'assets/images/icon-funnel.svg';
import Option from 'model/Option';
import { allOption } from 'constants/commonConstants';

import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import styles from './filter.module.less';

interface FilterProps extends SelectProps {
  placeholder?: string;
  options: Option[];
  onSelectItems: (selected: string[]) => void;
  filterValue: string;
  selected: string[];
}

const Filter: FC<FilterProps> = (props) => {
  const {
    options,
    placeholder,
    onSelectItems,
    filterValue,
    selected,
    ...rest
  } = props;

  const [selectedOptions, setSelectedOptions] = useState<string[]>(selected);
  const [prevSelectedOptions, setPrevSelectedOptions] =
    useState<string[]>(selected);
  const [tempSelected, setTempSelected] = useState<string[]>(selected);
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleConfirm = () => {
    setIsOpen(false);
    setPrevSelectedOptions(selectedOptions);
    setTempSelected(selectedOptions);
    onSelectItems(selectedOptions);
  };

  const resetSelectedOptions = () => {
    setIsOpen(false);
    setSelectedOptions(prevSelectedOptions);
    setTempSelected(prevSelectedOptions);
  };

  const handleSelect = useCallback(
    (value: string[]) => {
      if (!isOpen) {
        setSelectedOptions(value);
        setPrevSelectedOptions(value);
        setTempSelected(value);
        onSelectItems(value);
      } else if (tempSelected.includes(allOption)) {
        if (value.includes(allOption)) {
          setSelectedOptions([]);
          setTempSelected([]);
        } else {
          const selectedFilters = tempSelected.filter((item) => {
            return item !== allOption && !value.includes(item);
          });
          setSelectedOptions(selectedFilters);
          setTempSelected(selectedFilters);
        }
      } else {
        if (
          value.includes(allOption) ||
          (!value.includes(allOption) && value.length === options.length - 1)
        ) {
          const selectedFilters = options.map((option) => option.value);
          setSelectedOptions(selectedFilters);
          setTempSelected(selectedFilters);
        } else {
          setSelectedOptions(value);
          setTempSelected(value);
        }
      }
    },
    [isOpen, options, tempSelected, onSelectItems]
  );

  useEffect(() => {
    if (!isEmpty(filterValue) && options.length > 1 && !isRendered) {
      const filterValueArray = Array.from(filterValue.split(','));
      handleSelect(filterValueArray);
      setIsRendered(true);
    }
  }, [filterValue, handleSelect, isRendered, options.length]);

  return (
    <div className={styles.filterWrapper}>
      <Select
        mode="multiple"
        maxTagCount={2}
        className={styles.filter}
        placeholder={placeholder}
        optionLabelProp="label"
        onChange={handleSelect}
        dropdownClassName={styles.dropdownWrapper}
        value={
          selectedOptions.includes(allOption) ? undefined : selectedOptions
        }
        suffixIcon={<img src={filterIcon} alt="filter-icon" />}
        open={isOpen}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDropdownVisibleChange={(open) => {
          setIsOpen(open);
          if (!open) {
            resetSelectedOptions();
          }
        }}
        dropdownRender={(menu) => (
          <div className={styles.claimsTypeFilterWrapper}>
            {menu}
            <Row justify="center">
              <SubmitButton
                type="primary"
                onClick={handleConfirm}
                className={styles.confirmButton}
              >
                Okay
              </SubmitButton>
            </Row>
            <Row justify="center">
              <CancelButton
                className={styles.cancelButton}
                onClick={() => {
                  resetSelectedOptions();
                }}
              >
                Cancel
              </CancelButton>
            </Row>
          </div>
        )}
        showArrow
        showSearch={false}
        {...rest}
      >
        {options.map((option) => {
          const { value, label } = option;
          return (
            <Select.Option
              className={styles.claimsTypeOption}
              key={value}
              value={value}
              label={label}
            >
              <Checkbox
                className={
                  option.value === allOption &&
                  selectedOptions.includes(allOption)
                    ? styles.allSelect
                    : styles.singleSelect
                }
                checked={
                  selectedOptions?.includes(value) ||
                  selectedOptions?.includes(allOption)
                }
              >
                {option.value === allOption ? 'All' : option.label}
              </Checkbox>
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default Filter;
