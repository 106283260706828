import { FC, useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { LoadingOutlined } from '@ant-design/icons';

import { Spin } from 'antd';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import iconWarning from 'assets/images/icon-warning.svg';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { FORMAT_VALIDATE, SIZE_VALIDATE } from 'util/commonUtil';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import styles from './mdvEditDocumentUploader.module.less';

type MDVEditDocumentUploaderProps = {
  onChange: Function;
  onRemove: Function;
  onFileNameClick: Function;
  allowedFileTypes?: string;
  maxFileSizeMB: number;
  isLoading?: boolean;
  isRemoved?: boolean;
  file?: File;
  onValidateFails?: (validateSetting: string) => void;
  isDisable?: boolean;
};
const emptyFile = new File([''], '');

const MDVEditDocumentUploader: FC<MDVEditDocumentUploaderProps> = (
  props: MDVEditDocumentUploaderProps
) => {
  const [file, setFile] = useState<File>(emptyFile);

  const fileInput = useRef<HTMLInputElement>(null);

  const {
    onChange,
    onRemove,
    onFileNameClick,
    allowedFileTypes,
    maxFileSizeMB,
    isLoading = false,
    isRemoved = false,
    onValidateFails,
    isDisable = false,
  } = props;

  useEffect(() => {
    if (isRemoved) {
      setFile(emptyFile);
    }
  }, [isRemoved]);

  useEffect(() => {
    if (props.file) {
      setFile(props.file);
    } else {
      setFile(emptyFile);
    }
  }, [props.file]);

  const uploadFile = (): void => {
    if (fileInput.current !== null) {
      fileInput.current.click();
    }
  };

  const onSelectDocument = (event: any): void => {
    const file = event.target.files[0];
    const isallowedFileTypes = file.type === allowedFileTypes;
    if (!isallowedFileTypes) {
      if (onValidateFails) {
        onValidateFails(FORMAT_VALIDATE);
      }
      return;
    }
    const isallowedFileSize = file.size / 1024 / 1024 < maxFileSizeMB;
    if (!isallowedFileSize) {
      if (onValidateFails) {
        onValidateFails(SIZE_VALIDATE);
      }
      return;
    }
    if (!file) {
      return;
    } else {
      setFile(file);
      onChange(file);
    }
  };

  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

  return (
    <div className={styles.documentUploaderWrapper}>
      <PageActionButton
        className={styles.uploadButton}
        onClick={uploadFile}
        disabled={isDisable}
      >
        + Choose File
      </PageActionButton>

      <div className={styles.fileLabel}>
        {isLoading && (
          <>
            <Spin indicator={antIcon} /> &nbsp;
          </>
        )}
        {file.name && !isLoading && (
          <div
            onClick={() => onFileNameClick(file)}
            className={`${styles.fileNameArea} ${styles.mdvDocumentName} uploadedFileName`}
          >
            <OverflowPopover popoverContent={file.name}>
              {file.name}
            </OverflowPopover>
          </div>
        )}
      </div>

      <div className={styles.removeFile}>
        {isEmpty(file.name) ? (
          <span className="text label-disabled">No File Selected</span>
        ) : (
          !isLoading && (
            <ConfirmationModal
              title={
                <div className={styles.cancelUploadBody}>
                  Are you sure you want to remove this file?
                  <p>
                    Removing the file will not affect any plan information, but
                    it will no longer be available to download from a Digital
                    Benefits Guide.
                  </p>
                </div>
              }
              icon={
                <img
                  src={iconWarning}
                  alt="warning-icon"
                  className={styles.iconWarning}
                />
              }
              confirmModalTrigger="(remove)"
              onConfirm={() => onRemove()}
              okText="Yes, remove file"
              placement="topLeft"
            />
          )
        )}
      </div>
      <input
        type="file"
        name="file"
        onClick={onInputClick}
        onChange={onSelectDocument}
        ref={fileInput}
        accept={allowedFileTypes}
        hidden
      />
    </div>
  );
};

export default MDVEditDocumentUploader;
