import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'lodash/isEmpty';
import { Col, Form, Input, Menu, Popover, Row, Typography } from 'antd';
import { CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import iconInfo from 'assets/images/icon-info.svg';
import InfoBanner from 'components/InfoBanner/InfoBanner';
import Expandable from 'components/Expandable/Expandable';
import DataTable from 'components/DataTable/DataTable';
import InputForm from 'components/InputForm/InputForm';
import NextButton from 'components/buttons/NextButton/NextButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import { DataColumn } from 'components/DataTable/DataColumn';
import ImageUploader, {
  FORMAT_VALIDATE,
  SIZE_VALIDATE,
} from 'components/ImageUploader/ImageUploader';
import {
  addBrokerAdminFormFields,
  BROKER_ADMIN_EMPTY_ERROR,
  BROKER_ADMIN_REMOVE_ERROR,
  EMPTY_MESSAGE,
  EXISTING_LOGIN_FOUND,
  PRIMARY_CONTACT_ERROR,
  PRIMARY_CONTACT_REMOVE_ERROR,
} from 'modules/brokers/constants/brokerConstants';
import CheckboxSelect from 'components/CheckboxSelect/CheckboxSelect';
import { addBroker } from 'modules/brokers/slices/brokerBasicInfoSlice';
import MultiSelectDropdown from 'components/MultiSelectDropdown/MultiSelectDropdown';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import FooterText from 'components/FooterText/FooterText';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import InputPhoneNumber from 'components/InputPhoneNumber/InputPhoneNumber';
import BrokerAdmin from 'model/BrokerAdmin';
import {
  clearAdminAvatar,
  updateAdminAvatarMap,
  uploadBrokerAdminAvatar,
} from 'modules/brokers/slices/brokerAdminSlice';
import {
  BROKER_ADMIN_TYPE_LABELS,
  brokerFooterText,
  formVerificationMsg,
  getBrokerAdminType,
  IndividualSubTypes,
  IndividualType,
  multiSelectTypes,
  uploadImageSizeValidationMsg,
} from 'constants/commonConstants';
import {
  isValidEmailFormat,
  isValidPhoneNumber,
  SYSTEM_EMPLOYER,
} from 'util/commonUtil';
import { buildFullName, buildInitials } from 'util/stringUtil';
import { validateAdminEmail } from 'modules/admins/services/AdminService';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import SelectOptions from 'components/SelectOptions/SelectOptions';

import styles from './addBrokerAdmins.module.less';

const { Text } = Typography;
const { Item } = Form;

type AddBrokerAdminFormProps = {
  onClose?: (...args: any[]) => any;
  expanded: boolean;
  preLoadInPlaceId?: string | null;
};

type AddBrokerAdminProps = {
  nextStep: () => void;
};

type BrokerAdminTableData = {
  key: string;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  brokerAdminLocation: string;
  isPrimary?: boolean;
  actions?: BrokerAdmin;
  individualSubType: string;
};

type AvatarMapType = {
  [id: string]: { croppedAvatar: string; originalAvatar: string };
};

const locationTooltipContent = () => (
  <div className={styles.popoverContent}>
    <p>
      If you want this person to be able to access all clients then assign them
      to all locations.
      <br /> If you want this person only be able to access clients at specific
      locations then choose specific locations.
    </p>
  </div>
);

const AddBrokerAdminForm: FC<AddBrokerAdminFormProps> = (
  props: AddBrokerAdminFormProps
) => {
  const { onClose, expanded, preLoadInPlaceId } = props;
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [duplicateEmailFound, setDuplicateEmailFound] =
    useState<boolean>(false);
  const [requireFieldErrorFound, setRequireFieldErrorFound] =
    useState<boolean>(false);
  const [validEmailFormat, setValidEmailFormat] = useState<boolean>(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);
  const [clearAlert, setClearAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [imageValidateSetting, setImageValidateSetting] = useState<string>('');
  const [avatarEncodedString, setAvatarEncodedString] = useState<string>('');
  const [isLocationError, setIsLocationError] = useState<boolean>(false);
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [avatarCropArea, setAvatarCropArea] =
    useState<OriginalImageCropParams>();
  const [avatarOriginalString, setAvatarOriginalString] = useState<string>('');

  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const [formData, setFormData] = useState<BrokerAdmin>({
    inPlaceId: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    title: '',
    invite: false,
    primary: false,
    locations: [],
    invitedTime: '',
    id: '',
    avatarUrl: '',
    originalAvatarUrl: '',
    individualSubType: '',
  });

  const broker = useAppSelector(
    (state) => state.brokers.brokerBasicInfo.brokerObj
  );
  const adminAvatar = useAppSelector(
    (state) => state.brokers.brokerAdmins.adminAvatar
  );
  const avatarImageMap = useAppSelector(
    (state) => state.brokers.brokerAdmins.avatarImageMap
  );
  const unCroppedImgUrl = useAppSelector(
    (state) => state.brokers.brokerAdmins.originalAvatarUrl
  );
  const appBootupInfo = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );

  const checkedAndDisabled = broker.admins ? broker.admins.length === 0 : true;

  const editAdminObj = useMemo(() => {
    return broker.admins?.find(
      (admin) =>
        admin.inPlaceId.toLocaleLowerCase() ===
        preLoadInPlaceId?.toLocaleLowerCase()
    );
  }, [broker.admins, preLoadInPlaceId]);

  useEffect(() => {
    if (checkedAndDisabled) {
      form.setFieldsValue({ primary: true });
    }
  }, [form, checkedAndDisabled, expanded]);

  useEffect(() => {
    if (preLoadInPlaceId) {
      const brokerAdminObj = broker.admins?.find(
        (admin) =>
          admin.inPlaceId.toLowerCase() == preLoadInPlaceId.toLowerCase()
      );
      if (brokerAdminObj) {
        const {
          inPlaceId,
          email,
          firstName,
          lastName,
          phone,
          title,
          primary,
          locations,
          avatarUrl,
          originalImageCropParams,
          individualSubType,
        } = brokerAdminObj;
        const data = {
          inPlaceId,
          email,
          firstName,
          lastName,
          phone,
          title,
          primary,
          locations,
          avatarUrl,
          individualSubType,
        } as BrokerAdmin;
        setFormData(data);
        form.setFieldsValue({
          email,
          firstName,
          lastName,
          phone,
          title,
          primary,
          individualSubType,
        });
        setSelectedLocations(() => {
          if (isEmpty(brokerAdminObj.locations)) {
            setAllSelected(true);
            return (
              broker.locations.map(
                (brokerLocation) => brokerLocation.inPlaceId
              ) || []
            );
          } else {
            setAllSelected(false);
          }
          return (
            broker.locations
              .filter((brokerLocation) =>
                brokerAdminObj.locations.some(
                  (locationId) => brokerLocation.inPlaceId === locationId
                )
              )
              ?.map((selectLocation) => selectLocation.inPlaceId) || []
          );
        });
        setAvatarCropArea(originalImageCropParams);
        setRequireFieldErrorFound(false);
        setDuplicateEmailFound(false);
        setValidEmailFormat(false);
        setValidPhoneNumber(false);
        setIsLocationError(false);
        setAvatarEncodedString(
          avatarImageMap[brokerAdminObj.inPlaceId]
            ? avatarImageMap[brokerAdminObj.inPlaceId].croppedAvatar
            : ''
        );
        setAvatarOriginalString(
          avatarImageMap[brokerAdminObj.inPlaceId]
            ? avatarImageMap[brokerAdminObj.inPlaceId].originalAvatar
            : ''
        );
        brokerAdminObj.primary ? setIsDefault(true) : setIsDefault(false);
        setEditMode(true);
      }
    } else {
      setEditMode(false);
      setSelectedLocations([]);
      setAllSelected(false);
      form.resetFields(addBrokerAdminFormFields.formFields);
      if (!isEmpty(broker?.admins)) {
        form.setFieldsValue({ primary: false });
      }
      setAvatarEncodedString('');
      setAvatarOriginalString('');
      setIsLocationError(false);
    }
  }, [
    expanded,
    preLoadInPlaceId,
    broker?.admins,
    broker?.locations,
    form,
    avatarImageMap,
  ]);

  const emailInputRef = useRef<Input>(null);
  const firstRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expanded) {
      const emailElement = emailInputRef.current;
      const firstRowElement = firstRowRef.current;
      if (emailElement !== null && firstRowElement !== null) {
        setHasFocus(true);
      }
    }
  }, [expanded]);

  useEffect(() => {
    if (hasFocus && expanded) {
      const emailElement = emailInputRef.current;
      const firstRowElement = firstRowRef.current;
      if (emailElement !== null) {
        emailElement.focus();
      }
      if (firstRowElement !== null) {
        firstRowElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hasFocus, expanded]);

  useEffect(() => {
    if (validEmailFormat || duplicateEmailFound) {
      form.setFields([{ name: 'email', errors: [EMPTY_MESSAGE] }]);
    }
  }, [duplicateEmailFound, validEmailFormat, form]);

  useEffect(() => {
    if (!adminAvatar.inProgress && adminAvatar.data) {
      setFormData((prevState) => ({
        ...prevState,
        avatarUrl: adminAvatar.data,
      }));
    }
  }, [adminAvatar.inProgress, adminAvatar.data]);

  useEffect(() => {
    if (unCroppedImgUrl) {
      setFormData((prevState) => ({
        ...prevState,
        originalAvatarUrl: unCroppedImgUrl,
      }));
    }
  }, [unCroppedImgUrl]);

  const validateAdminInfo = async () => {
    try {
      await form.validateFields(addBrokerAdminFormFields.requiredFields);
      return true;
    } catch (errorInfo: any) {
      return isEmpty(errorInfo.errorFields);
    }
  };

  const removeAlert = async () => {
    const valid = await validateAdminInfo();
    setDuplicateEmailFound(false);
    setRequireFieldErrorFound(false);
    setValidEmailFormat(false);
    setValidPhoneNumber(false);
    if (valid) {
      setRequireFieldErrorFound(false);
    }
  };

  const onInputChange = async (changedValues: any, allValues: any) => {
    if (changedValues?.individualSubType) {
      if (
        changedValues?.individualSubType !==
        IndividualSubTypes.SUPER_BROKER_ADMIN
      ) {
        setAllSelected(false);
      } else {
        setAllSelected(true);
        setSelectedLocations(
          broker.locations?.map((item) => item.inPlaceId) || []
        );
      }
      let individualType = {};
      if (changedValues?.individualSubType === IndividualType.OPS_ADMIN) {
        individualType = { individualType: IndividualType.OPS_ADMIN };
      } else {
        individualType = { individualType: IndividualType.ORGANIZATION_ADMIN };
      }
      form.setFieldsValue({ ...allValues, ...individualType });
      setFormData({ ...formData, ...allValues, ...individualType });
    } else {
      form.setFieldsValue(allValues);
      setFormData({ ...formData, ...allValues });
    }
    if (clearAlert) {
      removeAlert();
    }
  };

  const selectedBrokerLocations = (): string[] => {
    return selectedLocations.length === broker.locations.length && allSelected
      ? []
      : selectedLocations;
  };

  const onClickSave = async () => {
    setClearAlert(true);
    const isFormValid = await validateAdminInfo();
    if (
      isFormValid &&
      (allSelected || (!allSelected && !isEmpty(selectedLocations)))
    ) {
      setRequireFieldErrorFound(false);
      const {
        inPlaceId,
        email,
        firstName,
        lastName,
        phone,
        title,
        primary,
        avatarUrl,
        originalAvatarUrl,
        individualSubType,
      } = formData;
      const trimmedEmail = email.trim();
      if (!isValidEmailFormat(trimmedEmail)) {
        setValidEmailFormat(true);
        setAlertMessage(formVerificationMsg);
      } else if (!isEmpty(phone) && !isValidPhoneNumber(phone)) {
        setValidPhoneNumber(true);
        setAlertMessage(formVerificationMsg);
      } else {
        const brokerAdmin = {
          inPlaceId,
          email: trimmedEmail,
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          title: title?.trim(),
          phone,
          avatarUrl: avatarUrl,
          locations: selectedBrokerLocations(),
          invite: false,
          primary,
          accountStatus: '',
          locationMap: {},
          invitedTime: '',
          originalAvatarUrl: originalAvatarUrl,
          originalImageCropParams: avatarCropArea,
          individualSubType,
        } as BrokerAdmin;
        brokerAdmin.employerId = SYSTEM_EMPLOYER;
        if (editMode) {
          const remaining = broker.admins.filter(
            (admin: BrokerAdmin) => admin.inPlaceId !== inPlaceId
          );
          if (!isEmpty(remaining)) {
            if (isEmailAlreadyAdded(trimmedEmail, remaining)) {
              setDuplicateEmailFound(true);
              setAlertMessage(
                'This email is already in use by another broker admin.'
              );
            } else {
              const brokerAdmins = primary
                ? removePrimaryContact(broker.admins)
                : [...broker.admins];
              const updatedBroker = {
                ...broker,
                admins: brokerAdmins.map((admin) => {
                  if (admin.inPlaceId === inPlaceId) {
                    admin = brokerAdmin;
                  }
                  return admin;
                }),
              };
              const validateEmailResult = await validateAdminEmail(
                trimmedEmail
              );
              if (
                validateEmailResult &&
                validateEmailResult.data &&
                validateEmailResult.data.code &&
                validateEmailResult.data.code === EXISTING_LOGIN_FOUND
              ) {
                setDuplicateEmailFound(true);
                setAlertMessage(
                  'This email is already in use by another broker admin.'
                );
              } else {
                dispatch(addBroker(JSON.parse(JSON.stringify(updatedBroker))));
                dispatch(
                  updateAdminAvatarMap({
                    id: brokerAdmin.inPlaceId,
                    image: avatarEncodedString,
                    originalImg: avatarOriginalString,
                  })
                );
                setEditMode(false);
                handleExpandableClose();
              }
            }
          } else {
            const updatedBroker = {
              ...broker,
              admins: [brokerAdmin],
            };
            const validateEmailResult = await validateAdminEmail(trimmedEmail);
            if (
              validateEmailResult &&
              validateEmailResult.data &&
              validateEmailResult.data.code &&
              validateEmailResult.data.code === EXISTING_LOGIN_FOUND
            ) {
              setDuplicateEmailFound(true);
              setAlertMessage(
                'This email is already in use by another broker admin.'
              );
            } else {
              dispatch(addBroker(JSON.parse(JSON.stringify(updatedBroker))));
              dispatch(
                updateAdminAvatarMap({
                  id: brokerAdmin.inPlaceId,
                  image: avatarEncodedString,
                  originalImg: avatarOriginalString,
                })
              );
              setEditMode(false);
              handleExpandableClose();
            }
          }
        } else {
          if (isEmailAlreadyAdded(trimmedEmail, broker.admins)) {
            setDuplicateEmailFound(true);
            setAlertMessage(
              'This email is already in use by another broker admin.'
            );
          } else {
            brokerAdmin.inPlaceId = uuidv4();
            setImageValidateSetting('');
            const otherAdmins = primary
              ? removePrimaryContact(broker.admins)
              : [...broker.admins];
            const updatedBroker = {
              ...broker,
              admins: [...otherAdmins, brokerAdmin],
            };
            const validateEmailResult = await validateAdminEmail(trimmedEmail);
            if (
              validateEmailResult &&
              validateEmailResult.data &&
              validateEmailResult.data.code &&
              validateEmailResult.data.code === EXISTING_LOGIN_FOUND
            ) {
              setDuplicateEmailFound(true);
              setAlertMessage(
                'This email is already in use by another broker admin.'
              );
            } else {
              dispatch(addBroker(updatedBroker));
              dispatch(
                updateAdminAvatarMap({
                  id: brokerAdmin.inPlaceId,
                  image: avatarEncodedString,
                  originalImg: avatarOriginalString,
                })
              );
              handleExpandableClose();
            }
          }
        }
      }
    } else {
      setRequireFieldErrorFound(true);
      setAlertMessage(formVerificationMsg);
    }
  };

  const closeAlert = () => {
    setDuplicateEmailFound(false);
    setRequireFieldErrorFound(false);
    setValidEmailFormat(false);
    setValidPhoneNumber(false);
  };

  const removePrimaryContact = (otherAdmins: BrokerAdmin[]) => {
    return (
      otherAdmins.map((admin) =>
        admin.primary ? { ...admin, primary: false } : { ...admin }
      ) || []
    );
  };

  const updateSelected = (
    selectedOptions: string[],
    allOptionsSelected: boolean
  ) => {
    setAllSelected(allOptionsSelected);
    setSelectedLocations(selectedOptions);
    if (allOptionsSelected || !isEmpty(selectedOptions)) {
      setIsLocationError(false);
    }
    if (clearAlert || preLoadInPlaceId) {
      removeAlert();
    }
  };

  const brokerLocationsOptionsList =
    broker.locations?.map((item) => ({
      label: item.name,
      value: item.inPlaceId,
    })) || [];

  const isEmailAlreadyAdded = (
    email: string,
    brokerAdmins: BrokerAdmin[]
  ): boolean => {
    if (email && brokerAdmins) {
      const filteredAdmins = brokerAdmins.filter(
        (admin: BrokerAdmin) =>
          admin.email !== undefined &&
          admin.email.trim().toLowerCase() === email.trim().toLowerCase()
      );
      if (filteredAdmins.length !== 0) {
        return true;
      }
    }
    return false;
  };

  const handleUpload = (
    image: string,
    originalImage?: string,
    cropArea?: OriginalImageCropParams
  ) => {
    setAvatarEncodedString(image);
    originalImage && setAvatarOriginalString(originalImage);
    dispatch(uploadBrokerAdminAvatar(image, originalImage || '')).then(() =>
      setAvatarCropArea(cropArea)
    );
    setImageValidateSetting('');
  };

  const handleExpandableClose = () => {
    resetFormData();
    dispatch(updateAdminAvatarMap({ id: '', image: '', originalImg: '' }));
    setImageValidateSetting('');
    if (onClose) {
      onClose();
    }
  };

  const resetFormData = () => {
    form.resetFields();
    setFormData({
      inPlaceId: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      title: '',
      invite: false,
      primary: false,
      locations: [],
      invitedTime: '',
      id: '',
      individualSubType: '',
    });
    setRequireFieldErrorFound(false);
    setDuplicateEmailFound(false);
    setValidEmailFormat(false);
    setValidPhoneNumber(false);
    setSelectedLocations([]);
    setAllSelected(false);
    setClearAlert(false);
    setImageValidateSetting('');
    dispatch(clearAdminAvatar());
    setIsLocationError(false);
  };

  const handleAvatarRemove = () => {
    setAvatarEncodedString('');
    setAvatarOriginalString('');
    setFormData({ ...formData, avatarUrl: null, originalAvatarUrl: null });
  };

  const onValidateFails = (validateSetting: string) => {
    setImageValidateSetting(validateSetting);
  };

  const getImageValidationErrorMsg = (validateSetting: string): ReactNode => {
    switch (validateSetting) {
      case FORMAT_VALIDATE:
        return 'This image file type is not supported.';
      case SIZE_VALIDATE:
        return 'Maximum size allowed for this upload is 2 MB.';
      default:
        setImageValidateSetting('');
        return;
    }
  };

  return (
    <>
      {(duplicateEmailFound ||
        requireFieldErrorFound ||
        validEmailFormat ||
        validPhoneNumber ||
        imageValidateSetting) && (
        <AlertMessage
          type="error"
          message={
            isEmpty(imageValidateSetting)
              ? alertMessage
              : getImageValidationErrorMsg(imageValidateSetting)
          }
          closeAlert={() => {
            if (!isEmpty(imageValidateSetting)) {
              setImageValidateSetting('');
            }
            closeAlert();
          }}
        />
      )}
      <InputForm onValuesChange={onInputChange} form={form}>
        <Row ref={firstRowRef} gutter={48}>
          <Col span={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
              labelCol={{ span: 24 }}
            >
              <Input
                id="email"
                size="large"
                ref={emailInputRef}
                onFocus={() => setHasFocus(true)}
                onBlur={() => setHasFocus(false)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
              labelCol={{ span: 24 }}
            >
              <Input id="firstName" size="large" />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
              labelCol={{ span: 24 }}
            >
              <Input id="lastName" size="large" />
            </Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Item
              label="Phone"
              name="phone"
              labelCol={{ span: 24 }}
              className={validPhoneNumber ? styles.labelError : ''}
            >
              <InputPhoneNumber />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Title"
              name="title"
              rules={[{ required: false }]}
              labelCol={{ span: 24 }}
            >
              <Input id="title" size="large" />
            </Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="individualSubType"
              label="User Type"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                },
              ]}
            >
              <SelectOptions
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                options={getBrokerAdminType(appBootupInfo?.type, '', true)}
                className={styles.brokerAdminTypeWrapper}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="photoAvatar"
              label="Photo Avatar"
              labelCol={{ span: 24 }}
              validateStatus={!imageValidateSetting ? '' : 'error'}
            >
              <ImageUploader
                cropShape="round"
                aspect={1}
                onConfirm={handleUpload}
                loading={adminAvatar.inProgress}
                uploadedImage={avatarEncodedString}
                showOperations
                onRemove={handleAvatarRemove}
                onValidateFails={onValidateFails}
                helpText={uploadImageSizeValidationMsg}
                defaultCropArea={avatarCropArea}
                unCroppedImage={
                  avatarOriginalString || editAdminObj?.originalAvatarUrl
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={24}>
            <Row className={styles.rowStyle}>
              <Col>
                <span
                  className={
                    isLocationError
                      ? styles.tooltipErrorLabel
                      : styles.tooltipLabel
                  }
                >
                  What Locations Should This Admin Be Able To Access? *
                </span>
              </Col>
              <Col>
                <Popover
                  content={locationTooltipContent}
                  placement="right"
                  overlayClassName="popoverInner"
                >
                  <QuestionCircleOutlined
                    className={isLocationError ? styles.iconErrorColor : ''}
                  />
                </Popover>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="location"
                  labelCol={{ span: 24 }}
                  validateStatus={isLocationError ? 'error' : 'validating'}
                  rules={[
                    {
                      validator: () => {
                        if (!allSelected && isEmpty(selectedLocations)) {
                          setIsLocationError(true);
                          return Promise.reject(new Error(''));
                        }
                        setIsLocationError(false);
                        return Promise.resolve();
                      },
                      validateTrigger: 'onSubmit',
                    },
                  ]}
                >
                  <MultiSelectDropdown
                    options={brokerLocationsOptionsList}
                    mode="horizontal"
                    updateSelected={updateSelected}
                    selected={selectedLocations}
                    allOptionsSelected={allSelected}
                    type={multiSelectTypes.ALL_SELECTABLE}
                    allOption="Access to All"
                    description="(including any new locations in the future by default.)"
                    disableAllOption={true}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={48} className={styles.primaryContact}>
          <Col>
            <Form.Item name="primary" valuePropName="checked">
              <CheckboxSelect
                disabled={
                  checkedAndDisabled ||
                  (broker.admins !== null && editMode && isDefault)
                }
              >
                <Text className="text checkbox">Make Primary Contact</Text>
              </CheckboxSelect>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" className={styles.rowStyle}>
          <Col>
            <SubmitButton
              onClick={onClickSave}
              disabled={adminAvatar.inProgress}
            >
              Save Broker Admin
            </SubmitButton>
          </Col>
        </Row>

        <Row justify="center" className={styles.rowStyle}>
          <Col>
            <CancelButton
              disabled={false}
              onClick={handleExpandableClose}
              className={styles.cancelBtnStyle}
            >
              Cancel
            </CancelButton>
          </Col>
        </Row>
      </InputForm>
    </>
  );
};

const AddBrokerAdmins: FC<AddBrokerAdminProps> = (
  props: AddBrokerAdminProps
) => {
  const { nextStep } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [editBrokerAdminInPlaceId, setEditBrokerAdminInPlaceId] = useState<
    string | null
  >(null);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);
  const [toBeDeleted, setToBeDeleted] = useState<any>({
    displayName: '',
    inPlaceId: '',
  });
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [currentAvatarMap, setCurrentAvatarMap] = useState<AvatarMapType>({});
  const broker = useAppSelector(
    (state) => state.brokers.brokerBasicInfo.brokerObj
  );
  const avatarMap = useAppSelector(
    (state) => state.brokers.brokerAdmins.avatarImageMap
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isEmpty(broker.admins)) {
      if (
        (broker.admins.length > 1 &&
          alertMessage.message === BROKER_ADMIN_REMOVE_ERROR) ||
        alertMessage.message === BROKER_ADMIN_EMPTY_ERROR
      ) {
        setVisibleAlert(false);
      }
    }
  }, [broker.admins, alertMessage]);

  useEffect(() => {
    if (!expanded) {
      setCurrentAvatarMap(avatarMap);
    }
  }, [avatarMap, expanded]);

  const handleBrokerAdminDelete = (record: BrokerAdmin) => {
    if (broker.admins.length === 1) {
      setVisibleAlert(true);
      setAlertMessage({
        type: 'error',
        message: BROKER_ADMIN_REMOVE_ERROR,
      });
    } else {
      if (record.primary) {
        setVisibleAlert(true);
        setAlertMessage({
          message: PRIMARY_CONTACT_REMOVE_ERROR,
          type: 'error',
        });
      } else {
        setToBeDeleted({
          inPlaceId: record.inPlaceId,
          displayName: buildFullName(record.firstName, record.lastName),
        });
        setVisibleConfirmation(true);
      }
    }
  };

  const handleBrokerAdminEdit = (record: BrokerAdmin) => {
    setExpanded(true);
    setEditBrokerAdminInPlaceId(record.inPlaceId);
  };

  const handlePrimaryContact = (admin: BrokerAdmin) => {
    const brokerAdminObj = broker.admins?.find(
      (brokerAdmin) =>
        brokerAdmin.inPlaceId.toLowerCase() == admin.inPlaceId.toLowerCase()
    );
    if (brokerAdminObj) {
      const updatedBroker = {
        ...broker,
        admins:
          broker.admins.map((brokerAdmin: BrokerAdmin) =>
            brokerAdmin.inPlaceId === brokerAdminObj.inPlaceId
              ? { ...brokerAdmin, primary: true }
              : { ...brokerAdmin, primary: false }
          ) || [],
      };
      dispatch(addBroker(JSON.parse(JSON.stringify(updatedBroker))));
      setVisibleAlert(true);
      setAlertMessage({
        type: 'success',
        message: `${admin.firstName} ${admin.lastName} is now the primary contact`,
      });
    }
  };

  const brokerAdminActionMenu = (colData: BrokerAdmin) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => handleBrokerAdminEdit(colData)}>
        Edit Info
      </Menu.Item>
      {!colData.primary && (
        <Menu.Item
          key="makePrimaryContact"
          onClick={() => handlePrimaryContact(colData)}
        >
          Make Primary Contact
        </Menu.Item>
      )}
      {broker.admins && broker.admins.length > 1 && (
        <Menu.Item
          key="remove"
          onClick={() => handleBrokerAdminDelete(colData)}
        >
          Delete User
        </Menu.Item>
      )}
    </Menu>
  );

  const closeAlert = () => setVisibleAlert(false);
  const closeModal = () => setVisibleConfirmation(false);

  const okDelete = () => {
    setVisibleConfirmation(false);
    const allOthers = broker.admins.filter(
      (t: BrokerAdmin) => t.inPlaceId !== toBeDeleted.inPlaceId
    );
    const updatedBroker = { ...broker, admins: [...allOthers] };
    dispatch(addBroker(JSON.parse(JSON.stringify(updatedBroker))));
    setToBeDeleted({});
    setVisibleAlert(true);
    setAlertMessage({
      type: 'success',
      message: 'Broker Admin has been removed.',
    });
  };
  const onClickNext = () => {
    if (isEmpty(broker.admins)) {
      setVisibleAlert(true);
      setAlertMessage({
        type: 'error',
        message: BROKER_ADMIN_EMPTY_ERROR,
      });
    } else if (
      isEmpty(
        broker.admins.filter((brokerAdmin: BrokerAdmin) => brokerAdmin.primary)
      )
    ) {
      setVisibleAlert(true);
      setAlertMessage({
        type: 'error',
        message: PRIMARY_CONTACT_ERROR,
      });
    } else {
      nextStep();
    }
  };

  const getLocationName = (locationPlaceId: string) => {
    const location = broker.locations.filter(
      (item) => item.inPlaceId === locationPlaceId
    );
    if (location && location.length === 1) {
      return location[0].name;
    }
  };

  const getBrokerAdminLocationColumnData = (brokerAdmin: BrokerAdmin) => {
    if (isEmpty(brokerAdmin.locations)) {
      return 'All';
    }
    return [...brokerAdmin.locations]
      .map((item) => getLocationName(item))
      .sort()
      .join(', ');
  };

  const convertBrokerAdminsForTableRender = (admins: BrokerAdmin[]): any[] => {
    if (admins != null) {
      const adminArray: BrokerAdminTableData[] = [];
      admins.forEach((item, index) => {
        const brokerAdmin: BrokerAdminTableData = {
          key: '',
          email: '',
          firstName: '',
          lastName: '',
          name: '',
          brokerAdminLocation: '',
          individualSubType: '',
        };
        brokerAdmin.key = item.inPlaceId;
        brokerAdmin.name = item.firstName + ' ' + item.lastName;
        brokerAdmin.brokerAdminLocation =
          getBrokerAdminLocationColumnData(item);
        brokerAdmin.email = item.email;
        brokerAdmin.isPrimary = item.primary;
        brokerAdmin.actions = item;
        brokerAdmin.firstName = item.firstName;
        brokerAdmin.lastName = item.lastName;
        brokerAdmin.individualSubType = item.individualSubType;

        adminArray[index] = brokerAdmin;
      });
      return adminArray;
    }
    return [];
  };

  const columns: DataColumn[] = [
    {
      title: 'BROKER ADMIN',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      sorter: (a, b) => {
        a = a.name || '';
        b = b.name || '';
        return a.localeCompare(b);
      },
      ellipsis: {
        showTitle: false,
      },
      className: styles.nameCell,
      render: (colData, record, index) => {
        let avatarImage = null;
        if (Object.keys(currentAvatarMap).includes(record.key)) {
          avatarImage = currentAvatarMap[record.key].croppedAvatar;
        }
        return (
          <>
            <div className={styles.profileColStyle}>
              <ProfileAvatar
                content={buildInitials(record.firstName, record.lastName)}
                src={avatarImage}
              />
            </div>
            <div className={styles.nameColStyle}>
              <OverflowPopover>
                <span>{colData}</span>
              </OverflowPopover>
            </div>
          </>
        );
      },
    },
    {
      title: 'USER TYPE',
      dataIndex: 'individualSubType',
      key: 'individualSubType',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (individualSubType) => {
        return <>{BROKER_ADMIN_TYPE_LABELS[individualSubType]}</>;
      },
    },
    {
      title: 'LOCATION',
      dataIndex: 'brokerAdminLocation',
      key: 'brokerAdminLocation',
      width: '20%',
      sorter: (a, b) => {
        a = a.brokerAdminLocation || '';
        b = b.brokerAdminLocation || '';
        return a.localeCompare(b);
      },
      ellipsis: {
        showTitle: false,
      },
      render: (locations) => <OverflowPopover>{locations}</OverflowPopover>,
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      render: (email) => <OverflowPopover>{email}</OverflowPopover>,
    },
    {
      title: 'PRIMARY CONTACT',
      dataIndex: 'isPrimary',
      key: 'primaryContact',
      width: '20%',
      className: styles.primaryContactCol,
      render: (colData) => (colData ? <CheckOutlined /> : <></>),
      align: 'center',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (colData) => (
        <SelectDropdown overlay={brokerAdminActionMenu(colData)} />
      ),
      align: 'center',
    },
  ];

  return (
    <div className={styles.addAdminsContainer}>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <InfoBanner
            title="Adding Broker Admins"
            description="Broker Admins are the broker representatives who have access to the system. Broker Admins will not be able to access the PlanYear platform until you send them an invitation."
            logo={<img src={iconInfo} alt="info icon" />}
          />
        </Col>
      </Row>
      {visibleAlert && (
        <AlertMessage
          type={alertMessage.type}
          message={alertMessage.message}
          closeAlert={closeAlert}
        />
      )}
      {visibleConfirmation && (
        <ConfirmationDialog
          title="Remove Broker Admin"
          confirmText={`Yes - Remove ${toBeDeleted.displayName}`}
          cancelText="Nevermind"
          closeModal={closeModal}
          onConfirm={okDelete}
          visible={visibleConfirmation}
        >
          <p className="text-confirmation-content">
            Are you sure you want to remove this broker admin? They will no
            longer be able to access the system; this action cannot be un-done.
          </p>
        </ConfirmationDialog>
      )}

      <div className={styles.tableWrapper}>
        <DataTable
          data={convertBrokerAdminsForTableRender(broker.admins)}
          columns={columns}
        />
      </div>

      <Expandable
        header={
          isEmpty(broker.admins)
            ? '+ Add Broker Admin'
            : '+ Add Another Broker Admin'
        }
        isExpanded={expanded}
        onChange={(expanded) => setExpanded(expanded)}
      >
        <AddBrokerAdminForm
          expanded={expanded}
          preLoadInPlaceId={editBrokerAdminInPlaceId}
          onClose={() => {
            setExpanded(false);
            setEditBrokerAdminInPlaceId(null);
          }}
        />
      </Expandable>

      {!expanded && (
        <div>
          <NextButton
            nextStep={onClickNext}
            disabled={visibleAlert && alertMessage.type === 'error'}
          />
          <FooterText messageText={brokerFooterText} />
        </div>
      )}
    </div>
  );
};
export default AddBrokerAdmins;
