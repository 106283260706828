import {
  OfferType,
  OFFER_TAGS,
  RECOMMENDED_OFFER,
  PROPOSAL_CARD_TAGS,
} from 'modules/renewals/constants/renewalsConstants';

import { CardTopStatusType } from 'modules/renewals/types/renewalsTypes';

import { ReactComponent as IconStar } from 'assets/images/icon-star.svg';
import styles from './cardTopStatusTag.module.less';

export type CardTopStatusTagPropsTypes = {
  status: CardTopStatusType | string;
};

const CardTopStatusTag = (props: CardTopStatusTagPropsTypes) => {
  switch (props.status) {
    case PROPOSAL_CARD_TAGS.RECOMMENDED:
    case RECOMMENDED_OFFER:
      return (
        <div className={`${styles.recommended}`}>
          <IconStar className={styles.starIcon} />
          RECOMMENDED
        </div>
      );
    case OfferType.RENEWAL:
      return <div className={`${styles.current}`}>RENEWAL</div>;
    case OFFER_TAGS.DTQ:
      return <div className={`${styles.dtq}`}>DECLINED TO QUOTE</div>;
    case OFFER_TAGS.PENDING:
      return <div className={`${styles.pending}`}>PENDING</div>;
    case PROPOSAL_CARD_TAGS.DRAFT:
      return <div className={`${styles.draft}`}>DRAFT</div>;
    case PROPOSAL_CARD_TAGS.FINAL_APPROVED:
    case PROPOSAL_CARD_TAGS.FINALISED:
      return <div className={`${styles.finalApproved}`}>FINAL APPROVED</div>;
    case PROPOSAL_CARD_TAGS.QUOTE_PROCESSING:
      return (
        <div className={`${styles.quoteProcessing}`}>QUOTE PROCESSING</div>
      );
    default:
      return <></>;
  }
};

export default CardTopStatusTag;
