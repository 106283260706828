import { FC } from 'react';

import { DentalPlan } from 'model/plans/DentalPlan';
import DeductiblesOOPMax from 'modules/plans/dental/components/DeductiblesOOPMax/DeductiblesOOPMax';

type DeductiblesOOPMaxUPEditWrapperProps = {
  dentalPlan: DentalPlan;
  setDentalPlan: Function;
  isReviewProp?: boolean;
};

const DeductiblesOOPMaxUPEditWrapper: FC<
  DeductiblesOOPMaxUPEditWrapperProps
> = (props: DeductiblesOOPMaxUPEditWrapperProps) => {
  const { dentalPlan, setDentalPlan, isReviewProp = false } = props;

  return (
    <DeductiblesOOPMax
      dentalPlan={dentalPlan || ({} as DentalPlan)}
      onChange={setDentalPlan}
      isTouched={false}
      isEdit
      isReviewProp={isReviewProp}
    />
  );
};

export default DeductiblesOOPMaxUPEditWrapper;
