import { Skeleton, Image } from 'antd';

import styles from './siderBarLogo.module.less';

type SiderBarLogoProps = {
  // displays skelton if logo is null/undefined
  logoUrl?: string | null;
};
const siderSkelton = (
  <div>
    <Skeleton.Input style={{ width: 100 }} />
  </div>
);
const SiderBarLogo = ({ logoUrl }: SiderBarLogoProps) => {
  return (
    <div className={styles.sideBarLogo}>
      {logoUrl ? (
        <Image
          height={32}
          src={logoUrl}
          preview={false}
          placeholder={siderSkelton}
        />
      ) : (
        siderSkelton
      )}
    </div>
  );
};
export default SiderBarLogo;
