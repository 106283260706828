import { Col, Form, Input, Row, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Title from 'antd/lib/typography/Title';
import { FC, useState } from 'react';

import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import SelectOptions from 'components/SelectOptions/SelectOptions';

import {
  BASIC_LIFE_ADDITIONAL_SERVICES,
  LTD_SERVICES,
  STD_SERVICES,
} from 'modules/renewals/constants/renewalsConstants';
import { BenefitCategory } from 'constants/commonConstants';
import styles from './renewalLifeAddServiceModal.module.less';

const { Option } = Select;

type RenewalLifeAddServiceModalProps = {
  visible: boolean;
  onClose: Function;
  benefitKind: string;
  onAddService: Function;
  planData?: any;
  renderRows: Function;
};

const RenewalCustomServiceModal: FC<RenewalLifeAddServiceModalProps> = (
  props: RenewalLifeAddServiceModalProps
) => {
  const { visible, onClose, benefitKind, onAddService, planData } = props;

  const [serviceType, setServiceType] = useState<string | null>(null);
  const [description, setDescription] = useState<string>('');

  const resetFields = () => {
    form.resetFields();
    setDescription('');
    setServiceType(null);
  };

  const validateForm = async () => {
    try {
      await form.validateFields();
    } catch (errorInfo) {
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    const valid = await validateForm();

    if (!valid) {
      return;
    }

    onAddService(serviceType, description.trim());
    resetFields();
    onClose();
  };

  const onCancel = () => {
    resetFields();
    onClose();
  };

  const renderOptions = () => {
    const additionalServices: string[] =
      planData?.lifeServices?.map((obj: { name: string }) => obj.name) ?? [];
    if (benefitKind === BenefitCategory.STD.value) {
      return Object.values(STD_SERVICES)
        .filter((service) => !additionalServices.includes(service.label))
        .map((service) => (
          <Option key={service.label} value={service.label}>
            {service.label}
          </Option>
        ));
    } else if (benefitKind === BenefitCategory.LTD.value) {
      return Object.values(LTD_SERVICES)
        .filter((service) => !additionalServices.includes(service.label))
        .map((service) => (
          <Option key={service.label} value={service.label}>
            {service.label}
          </Option>
        ));
    } else if (benefitKind === BenefitCategory.LIFE.value) {
      return Object.values(BASIC_LIFE_ADDITIONAL_SERVICES)
        .filter((service) => !additionalServices.includes(service.label))
        .map((service) => (
          <Option key={service.label} value={service.label}>
            {service.label}
          </Option>
        ));
    }
  };

  const modalTitle = (
    <div>
      <div>
        <Title level={3} className={styles.center}>
          Add {benefitKind} Service
        </Title>
      </div>
    </div>
  );

  const footer = (
    <div className={styles.footerWrapper}>
      <CancelButton
        onClick={onCancel}
        className={styles.cancelButton}
        withBorder={true}
      >
        Cancel
      </CancelButton>
      <SubmitButton
        key="submit"
        type="primary"
        onClick={onSubmit}
        className={styles.saveButton}
      >
        Add
      </SubmitButton>
    </div>
  );

  const [form] = Form.useForm();

  return (
    <Modal
      wrapClassName={styles.modalWrapper}
      title={modalTitle}
      width={740}
      footer={footer}
      visible={visible}
      onOk={() => onSubmit()}
      onCancel={() => onCancel()}
      maskClosable={false}
      closable={false}
    >
      <Form form={form}>
        <Row>
          <Col span={5}>
            <div className={styles.fieldLabel}>
              <strong>Select Service</strong>
            </div>
          </Col>
          <Col span={19}>
            <Form.Item
              name="Service Type"
              className={styles.planName}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please select a service',
                },
              ]}
            >
              <SelectOptions
                value={serviceType}
                onChange={(value) => {
                  setServiceType(value);

                  form.resetFields(['Service Name']);
                }}
              >
                {renderOptions()}
              </SelectOptions>
            </Form.Item>
          </Col>
        </Row>
        <Row className={styles.titleRow}>
          <Col span={5}></Col>
          <Col span={15} className={styles.descriptionTag}>
            Description
          </Col>
        </Row>
        <Row>
          <Col span={5}></Col>
          <Col span={19} className={styles.descrptionRow}>
            <Input
              value={description}
              onChange={(value) => {
                setDescription(value.target.value);
              }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default RenewalCustomServiceModal;
