import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Row, Col, Form, Input } from 'antd';
import { debounce, isEmpty } from 'lodash';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import InputForm from 'components/InputForm/InputForm';

import { EMPTY_MESSAGE } from 'constants/commonConstants';
import { BasicPlans, VoluntaryPlans } from 'modules/plans/constants';

import FundingType from 'modules/plans/enums/FundingType';
import {
  deleteNonMdvService,
  emptyValue,
  handleNonMdvDescChange,
} from 'modules/plans/utils';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import AddNonMDVCustomModal from 'modules/plans/components/AddCustomServicesLifeModal/AddNonMDVCustomModal';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { NonMDVServiceType } from 'model/plans/NonMDVServiceType';
import {
  changeLifePlanDescription,
  deleteLifePlanService,
} from 'modules/plans/slices/lifePlanSlice';
import LifeServiceSystemRow from 'modules/plans/life/components/LifeServiceSystemRow/LifeServiceSystemRow';
import { ReactComponent as AIFeedbackRedFlag } from 'assets/images/ai-red-flag.svg';
import InfoIconComponent from 'modules/plans/components/LLMInfoIconComponent/InfoIconComponent';
import { LlmExtractionInfo, LlmInfo } from 'model/plans/LLMExtraction';
import { TDocumentExtractionSource } from 'modules/plans/components/AddPlanModal/AddPlanModal';
import UpdatePlanParametersInfo from 'modules/plans/UpdatePlanParametersInfo/UpdatePlanParametersInfo';
import styles from './disabilityPlanInformation.module.less';

type DisabilityPlanInformationProps = {
  isCancelButtonClickConfirm: boolean;
  setFormData: Function;
  formData: any;
  lifePlanType: string;
  isEditMode: boolean;
  cancelButtonClicked: boolean;
  isReviewHighlight?: boolean;
  fundingType?: string;
  isTitleVisible?: boolean;
  isReview?: boolean;
  isAdvancedProfile?: boolean;
  docExtractionSource?: TDocumentExtractionSource;
};

const DisabilityPlanInformation = forwardRef(
  (props: DisabilityPlanInformationProps, ref) => {
    const {
      isCancelButtonClickConfirm,
      setFormData,
      formData,
      lifePlanType,
      isEditMode,
      cancelButtonClicked = false,
      isReviewHighlight = false,
      fundingType,
      isTitleVisible,
      isAdvancedProfile,
      docExtractionSource,
    } = props;

    const [form] = Form.useForm();

    const [label, setLabel] = useState<string>('');
    const [filedValue, setFieldValue] = useState<string>('');
    const [labelError, setLabelError] = useState<boolean>(false);

    // allValues (Spread Type) can only be created from object types
    const onInputChange = async (allValues: any, value: any) => {
      form.setFieldsValue(allValues);

      setFormData({ ...formData, ...allValues });
    };

    const [showLifeAddServiceModal, setShowLifeAddServiceModal] =
      useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setFieldsValue = useCallback(
      debounce((data) => {
        if (form) {
          form.setFieldsValue({ ...data });
        }
      }, 100),
      // Remove 'form' from dependencies if it's a stable reference,
      // or if the debounced function doesn't need to be recreated when 'form' changes
      [form]
    );

    useEffect(() => {
      // Only set up the debounced call if in edit mode
      if (isEditMode && formData) {
        setFieldsValue(formData);
      }

      // Cleanup function
      return () => setFieldsValue.cancel();
    }, [formData, isEditMode, setFieldsValue]);

    useEffect(() => {
      if (
        lifePlanType === BasicPlans.STD.value ||
        lifePlanType === VoluntaryPlans.VOLUNTARY_STD.value
      ) {
        setLabel('STD Benefit');
        setFieldValue('benefitPercentage');
      } else if (
        lifePlanType === BasicPlans.LTD.value ||
        lifePlanType === VoluntaryPlans.VOLUNTARY_LTD.value
      ) {
        setLabel('LTD Benefit');
        setFieldValue('benefitPercentage');
      }
    }, [lifePlanType]);

    useEffect(() => {
      if (isCancelButtonClickConfirm && cancelButtonClicked) {
        form.resetFields();
      }
    }, [isCancelButtonClickConfirm, form, cancelButtonClicked]);

    useImperativeHandle(ref, () => ({
      resetForm() {
        form.resetFields();
        setLabelError(false);
      },
      validate: async () => ({
        isComplete: getIsComplete(),
        isValid: await getIsValid(),
      }),
    }));

    const { lifePlanServiceList, lifePlan } = useAppSelector(
      (state) => state.plan.lifePlan
    );
    const { llmExtractionInfo, extractionFinalized, textractJobId } = lifePlan;

    const isUploadedPlan: boolean = !!textractJobId && textractJobId !== '';

    const getIsComplete = (): boolean => {
      const fieldsToCheck = ['benefitPercentage', 'waitingPeriod'];

      if (
        [BasicPlans.STD.value, VoluntaryPlans.VOLUNTARY_STD.value].includes(
          lifePlanType
        )
      ) {
        fieldsToCheck.push(
          'weeklyBenefitMax',
          'benefitDurationSTD',
          'definitionOfDisability',
          'preExistingConditions',
          'stateDisabilityIntegration'
        );
      }

      if (
        [BasicPlans.LTD.value, VoluntaryPlans.VOLUNTARY_LTD.value].includes(
          lifePlanType
        )
      ) {
        fieldsToCheck.push(
          'monthlyBenefitMax',
          'benefitDurationLTD',
          'definitionOfDisability',
          'ownOccupationPeriod',
          'volume'
        );
      }

      if ([BasicPlans.STD.value, BasicPlans.LTD.value].includes(lifePlanType)) {
        if (
          fundingType === FundingType.FULLY_INSURED ||
          lifePlanType === BasicPlans.LTD.value
        ) {
          fieldsToCheck.push('rate');
        }

        if (
          fundingType === FundingType.SELF_FUNDED &&
          lifePlanType === BasicPlans.STD.value
        ) {
          fieldsToCheck.push('annualEstimatedClaims');
        }

        if (
          !(
            fundingType === FundingType.SELF_FUNDED &&
            lifePlanType === BasicPlans.STD.value
          ) &&
          fundingType === FundingType.FULLY_INSURED
        ) {
          fieldsToCheck.push('volume');
        }
      }

      if (!isEmpty(lifePlanServiceList)) {
        return !getArrayValueEmptyCheck(lifePlanServiceList);
      }

      return Object.entries(formData)
        .filter(([key]) => fieldsToCheck.includes(key))
        .every(([_, value]) => !emptyValue(value));
    };

    const getIsValid = async (): Promise<boolean> => {
      return true; // FIXME: I don't think theres any validation logic in here?
    };
    const dispatch = useAppDispatch();
    const handleDelete = (service: string) => {
      const nonMDVServiceTypes: NonMDVServiceType[] = deleteNonMdvService(
        lifePlanServiceList,
        service
      );
      dispatch(deleteLifePlanService(nonMDVServiceTypes));
    };

    const handleServiceDescriptionChange = (value: string, service: string) => {
      const nonMDVServiceTypes: NonMDVServiceType[] = handleNonMdvDescChange(
        lifePlanServiceList,
        service,
        value
      );
      dispatch(changeLifePlanDescription(nonMDVServiceTypes));
    };

    const getArrayValueEmptyCheck = (array: any[]) => {
      return array?.some((item) => isEmpty(item?.description));
    };

    // check if a value is of type LlmInfo
    const isLlmInfo = (value: any): value is LlmInfo => {
      return value && typeof value.different !== 'undefined';
    };

    // to get custom styles class based on field type and review highlight status
    const getCustomStylesClass = (fieldType: keyof LlmExtractionInfo) => {
      if (isReviewHighlight) {
        return 'highlightField';
      }
      return getIsHighlightedField(fieldType) ? 'highlightedLLMTextField' : '';
    };

    const getIsHighlightedField = (fieldName: keyof LlmExtractionInfo) => {
      const fieldValue = llmExtractionInfo?.[fieldName];
      return (
        !extractionFinalized && isLlmInfo(fieldValue) && fieldValue.different
      );
    };

    return (
      <InputForm
        form={form}
        onValuesChange={onInputChange}
        size={isEditMode ? 'small' : 'middle'}
      >
        {isTitleVisible && (
          <div className={styles.headerText}>Plan Information</div>
        )}
        {!isEmpty(docExtractionSource) && <UpdatePlanParametersInfo />}
        <Row className={styles.lifeAddCol}>
          <Row className={styles.inlineRow}>
            <Col className={styles.iconCol}>
              <span className={styles.inlineIcon}>
                <InfoIconComponent
                  showIcon={isUploadedPlan}
                  extractionInfo={llmExtractionInfo?.benefitPercentage}
                />
              </span>
            </Col>
            <Col flex="auto">
              <div className={styles.inputWrapper}>
                <Form.Item
                  name={filedValue}
                  label={label}
                  labelAlign="left"
                  labelCol={{ span: 6 }}
                  rules={[
                    {
                      required: false,
                      message: EMPTY_MESSAGE,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    className={
                      getIsHighlightedField('benefitPercentage')
                        ? styles.highlightedLLMTextField
                        : ''
                    }
                  />
                </Form.Item>
                {getIsHighlightedField('benefitPercentage') && (
                  <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                )}
              </div>
            </Col>
          </Row>
          <Col span={24}>
            {(lifePlanType === BasicPlans.STD.value ||
              lifePlanType === VoluntaryPlans.VOLUNTARY_STD.value) && (
              <Row className={styles.inlineRow}>
                <Col className={styles.iconCol}>
                  <span className={styles.inlineIcon}>
                    <InfoIconComponent
                      showIcon={isUploadedPlan}
                      extractionInfo={llmExtractionInfo?.weeklyBenefitMax}
                    />
                  </span>
                </Col>
                <Col flex="auto">
                  <div className={styles.inputWrapper}>
                    <Form.Item
                      name="weeklyBenefitMax"
                      label={
                        <span className={styles.labelWithLineBreak}>
                          Max Weekly Benefit
                        </span>
                      }
                      labelAlign="left"
                      labelCol={{ span: 6 }}
                      rules={[
                        {
                          required: false,
                          message: EMPTY_MESSAGE,
                          whitespace: true,
                        },
                      ]}
                      className={labelError ? styles.errorLabel : ''}
                    >
                      <Input
                        className={
                          getIsHighlightedField('weeklyBenefitMax')
                            ? styles.highlightedLLMTextField
                            : ''
                        }
                      />
                    </Form.Item>
                    {getIsHighlightedField('weeklyBenefitMax') && (
                      <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                    )}
                  </div>
                </Col>
              </Row>
            )}
            {(lifePlanType === BasicPlans.LTD.value ||
              lifePlanType === VoluntaryPlans.VOLUNTARY_LTD.value) && (
              <Row className={styles.inlineRow}>
                <Col className={styles.iconCol}>
                  <span className={styles.inlineIcon}>
                    <InfoIconComponent
                      showIcon={isUploadedPlan}
                      extractionInfo={llmExtractionInfo?.monthlyBenefitMax}
                    />
                  </span>
                </Col>
                <Col flex="auto">
                  <div className={styles.inputWrapper}>
                    <Form.Item
                      name="monthlyBenefitMax"
                      label={
                        <span className={styles.labelWithLineBreak}>
                          Max Monthly Benefit
                        </span>
                      }
                      labelAlign="left"
                      labelCol={{ span: 6 }}
                      rules={[
                        {
                          required: false,
                          message: EMPTY_MESSAGE,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        className={
                          getIsHighlightedField('monthlyBenefitMax')
                            ? styles.highlightedLLMTextField
                            : ''
                        }
                      />
                    </Form.Item>
                    {getIsHighlightedField('monthlyBenefitMax') && (
                      <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          <Row className={styles.inlineRow}>
            <Col className={styles.iconCol}>
              <span className={styles.inlineIcon}>
                <InfoIconComponent
                  showIcon={isUploadedPlan}
                  extractionInfo={llmExtractionInfo?.waitingPeriod}
                />
              </span>
            </Col>
            <Col flex="auto">
              <div className={styles.inputWrapper}>
                <Form.Item
                  name="waitingPeriod"
                  label={
                    <span className={styles.labelWithLineBreak}>
                      Waiting Period
                    </span>
                  }
                  labelAlign="left"
                  labelCol={{ span: 6 }}
                  rules={[
                    {
                      required: false,
                      message: EMPTY_MESSAGE,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    className={
                      getIsHighlightedField('waitingPeriod')
                        ? styles.highlightedLLMTextField
                        : ''
                    }
                  />
                </Form.Item>
                {getIsHighlightedField('waitingPeriod') && (
                  <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                )}
              </div>
            </Col>
          </Row>
          <Col span={24}>
            {(lifePlanType === BasicPlans.STD.value ||
              lifePlanType === VoluntaryPlans.VOLUNTARY_STD.value) && (
              <Row className={styles.inlineRow}>
                <Col className={styles.iconCol}>
                  <span className={styles.inlineIcon}>
                    <InfoIconComponent
                      showIcon={isUploadedPlan}
                      extractionInfo={llmExtractionInfo?.benefitDurationSTD}
                    />
                  </span>
                </Col>
                <Col flex="auto">
                  <div className={styles.inputWrapper}>
                    <Form.Item
                      name="benefitDurationSTD"
                      label={
                        <span className={styles.labelWithLineBreak}>
                          Max Benefit Duration
                        </span>
                      }
                      labelAlign="left"
                      labelCol={{ span: 6 }}
                      rules={[
                        {
                          required: false,
                          message: EMPTY_MESSAGE,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        className={
                          getIsHighlightedField('benefitDurationSTD')
                            ? styles.highlightedLLMTextField
                            : ''
                        }
                      />
                    </Form.Item>
                    {getIsHighlightedField('benefitDurationSTD') && (
                      <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                    )}
                  </div>
                </Col>
              </Row>
            )}
            {(lifePlanType === BasicPlans.LTD.value ||
              lifePlanType === VoluntaryPlans.VOLUNTARY_LTD.value) && (
              <Row className={styles.inlineRow}>
                <Col className={styles.iconCol}>
                  <span className={styles.inlineIcon}>
                    <InfoIconComponent
                      showIcon={isUploadedPlan}
                      extractionInfo={llmExtractionInfo?.benefitDurationLTD}
                    />
                  </span>
                </Col>
                <Col flex="auto">
                  <div className={styles.inputWrapper}>
                    <Form.Item
                      name="benefitDurationLTD"
                      label={
                        <span className={styles.labelWithLineBreak}>
                          Max Benefit Duration
                        </span>
                      }
                      labelCol={{ span: 6 }}
                      rules={[
                        {
                          required: false,
                          message: EMPTY_MESSAGE,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        className={
                          isReviewHighlight
                            ? styles.highlightField
                            : getIsHighlightedField('benefitDurationLTD')
                            ? styles.highlightedLLMTextField
                            : ''
                        }
                      />
                    </Form.Item>
                    {getIsHighlightedField('benefitDurationLTD') && (
                      <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={24}>
            {lifePlanType === BasicPlans.STD.value && (
              <Row className={styles.inlineRow}>
                <Col className={styles.iconCol}>
                  <span className={styles.inlineIcon}>
                    <InfoIconComponent
                      showIcon={isUploadedPlan}
                      extractionInfo={llmExtractionInfo?.definitionOfDisability}
                    />
                  </span>
                </Col>
                <Col flex="auto">
                  <div className={styles.inputWrapper}>
                    <Form.Item
                      name="definitionOfDisability"
                      label={
                        <span className={styles.labelWithLineBreak}>
                          Definition of Disability
                        </span>
                      }
                      labelAlign="left"
                      labelCol={{ span: 6 }}
                      rules={[
                        {
                          required: false,
                          message: EMPTY_MESSAGE,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        className={
                          getIsHighlightedField('definitionOfDisability')
                            ? styles.highlightedLLMTextField
                            : ''
                        }
                      />
                    </Form.Item>
                    {getIsHighlightedField('definitionOfDisability') && (
                      <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                    )}
                  </div>
                </Col>
              </Row>
            )}
            {lifePlanType === BasicPlans.LTD.value && (
              <Row className={styles.inlineRow}>
                <Col className={styles.iconCol}>
                  <span className={styles.inlineIcon}>
                    <InfoIconComponent
                      showIcon={isUploadedPlan}
                      extractionInfo={llmExtractionInfo?.definitionOfDisability}
                    />
                  </span>
                </Col>
                <Col flex="auto">
                  <div className={styles.inputWrapper}>
                    <Form.Item
                      name="definitionOfDisability"
                      label={
                        <span className={styles.labelWithLineBreak}>
                          Definition of Disability
                        </span>
                      }
                      labelAlign="left"
                      labelCol={{ span: 6 }}
                      rules={[
                        {
                          required: false,
                          message: EMPTY_MESSAGE,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        className={
                          isReviewHighlight
                            ? styles.highlightField
                            : llmExtractionInfo?.definitionOfDisability
                                ?.different
                            ? styles.highlightedLLMTextField
                            : ''
                        }
                      />
                    </Form.Item>
                    {getIsHighlightedField('definitionOfDisability') && (
                      <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={24}>
            {lifePlanType === BasicPlans.STD.value && (
              <Row className={styles.inlineRow}>
                <Col className={styles.iconCol}>
                  <span className={styles.inlineIcon}>
                    <InfoIconComponent
                      showIcon={isUploadedPlan}
                      extractionInfo={llmExtractionInfo?.preExistingConditions}
                    />
                  </span>
                </Col>
                <Col flex="auto">
                  <div className={styles.inputWrapper}>
                    <Form.Item
                      name="preExistingConditions"
                      label={
                        <span className={styles.labelWithLineBreak}>
                          Pre-Existing Conditions
                        </span>
                      }
                      labelAlign="left"
                      labelCol={{ span: 6 }}
                      rules={[
                        {
                          required: false,
                          message: EMPTY_MESSAGE,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        className={
                          getIsHighlightedField('preExistingConditions')
                            ? styles.highlightedLLMTextField
                            : ''
                        }
                      />
                    </Form.Item>
                    {getIsHighlightedField('preExistingConditions') && (
                      <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={24}>
            {lifePlanType === BasicPlans.STD.value && (
              <Row className={styles.inlineRow}>
                <Col className={styles.iconCol}>
                  <span className={styles.inlineIcon}>
                    <InfoIconComponent
                      showIcon={isUploadedPlan}
                      extractionInfo={
                        llmExtractionInfo?.stateDisabilityIntegration
                      }
                    />
                  </span>
                </Col>
                <Col flex="auto">
                  <div className={styles.inputWrapper}>
                    <Form.Item
                      name="stateDisabilityIntegration"
                      label={
                        <span className={styles.labelWithLineBreak}>
                          State Disability Integration
                        </span>
                      }
                      labelAlign="left"
                      labelCol={{ span: 6 }}
                      rules={[
                        {
                          required: false,
                          message: EMPTY_MESSAGE,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        className={
                          llmExtractionInfo?.stateDisabilityIntegration
                            ?.different
                            ? styles.highlightedLLMTextField
                            : ''
                        }
                      />
                    </Form.Item>
                    {llmExtractionInfo?.stateDisabilityIntegration
                      ?.different && (
                      <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={24}>
            {lifePlanType === BasicPlans.LTD.value && (
              <Row className={styles.inlineRow}>
                <Col className={styles.iconCol}>
                  <span className={styles.inlineIcon}>
                    <InfoIconComponent
                      showIcon={isUploadedPlan}
                      extractionInfo={llmExtractionInfo?.ownOccupationPeriod}
                    />
                  </span>
                </Col>
                <Col flex="auto">
                  <div className={styles.inputWrapper}>
                    <Form.Item
                      name="ownOccupationPeriod"
                      label={
                        <span className={styles.labelWithLineBreak}>
                          Own Occupation Period
                        </span>
                      }
                      labelAlign="left"
                      labelCol={{ span: 6 }}
                      rules={[
                        {
                          required: false,
                          message: EMPTY_MESSAGE,
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        className={
                          getIsHighlightedField('ownOccupationPeriod')
                            ? styles.highlightedLLMTextField
                            : ''
                        }
                      />
                    </Form.Item>
                    {getIsHighlightedField('ownOccupationPeriod') && (
                      <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          {[BasicPlans.STD.value, BasicPlans.LTD.value].includes(
            lifePlanType
          ) && (
            <>
              {lifePlanType === BasicPlans.STD.value &&
                FundingType.SELF_FUNDED === fundingType && (
                  <Row className={styles.inlineRow}>
                    <Col className={styles.iconCol}>
                      <span className={styles.inlineIcon}>
                        <InfoIconComponent
                          showIcon={isUploadedPlan}
                          extractionInfo={
                            llmExtractionInfo?.annualEstimatedClaims
                          }
                        />
                      </span>
                    </Col>
                    <Col flex="auto">
                      <div className={styles.inputWrapper}>
                        <Form.Item
                          name="annualEstimatedClaims"
                          label={
                            <span className={styles.labelWithLineBreak}>
                              Annual Est. Claims
                            </span>
                          }
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          rules={[
                            {
                              required: false,
                              message: EMPTY_MESSAGE,
                              whitespace: true,
                            },
                          ]}
                          className={labelError ? styles.errorLabel : ''}
                        >
                          <NumberFormatInput
                            prefix="$"
                            isBrokerScreen={true}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            placeholder="$"
                            customClass={getCustomStylesClass(
                              'annualEstimatedClaims'
                            )}
                          />
                        </Form.Item>
                        {llmExtractionInfo?.annualEstimatedClaims
                          ?.different && (
                          <AIFeedbackRedFlag
                            className={styles.aiFeedbackLabel}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              {(lifePlanType === BasicPlans.STD.value &&
                FundingType.FULLY_INSURED === fundingType) ||
              lifePlanType === BasicPlans.LTD.value ? (
                <Row className={styles.inlineRow}>
                  <Col className={styles.iconCol}>
                    <span className={styles.inlineIcon}>
                      <InfoIconComponent
                        showIcon={isUploadedPlan}
                        extractionInfo={llmExtractionInfo?.rate}
                      />
                    </span>
                  </Col>
                  <Col flex="auto">
                    <div className={styles.inputWrapper}>
                      <Form.Item
                        name="rate"
                        label={
                          lifePlanType === BasicPlans.STD.value &&
                          FundingType.FULLY_INSURED === fundingType
                            ? 'STD Rate'
                            : lifePlanType === BasicPlans.LTD.value
                            ? 'LTD Rate'
                            : ''
                        }
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                        rules={[
                          {
                            required: false,
                            message: EMPTY_MESSAGE,
                            whitespace: true,
                          },
                        ]}
                      >
                        <NumberFormatInput
                          isBrokerScreen={true}
                          decimalScale={3}
                          fixedDecimalScale={true}
                          allowNegative={false}
                          customClass={getCustomStylesClass('rate')}
                        />
                      </Form.Item>
                      {getIsHighlightedField('rate') && (
                        <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                      )}
                    </div>
                    <div className={styles.rateText}>
                      {lifePlanType === BasicPlans.STD.value
                        ? 'STD Rate per $10 of Weekly Benefit'
                        : 'LTD Rate per $100 Monthly Covered Payroll'}
                    </div>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Row className={styles.inlineRow}>
                <Col className={styles.iconCol}>
                  <span className={styles.inlineIcon}>
                    <InfoIconComponent
                      showIcon={isUploadedPlan}
                      extractionInfo={llmExtractionInfo?.volume}
                    />
                  </span>
                </Col>
                <Col flex="auto">
                  <div className={styles.inputWrapper}>
                    <Form.Item
                      hidden={
                        lifePlanType === BasicPlans.STD.value &&
                        FundingType.FULLY_INSURED !== fundingType
                      }
                      name="volume"
                      label="Volume"
                      labelAlign="left"
                      labelCol={{ span: 6 }}
                      rules={[
                        {
                          required: false,
                          message: EMPTY_MESSAGE,
                          whitespace: true,
                        },
                      ]}
                    >
                      <NumberFormatInput
                        prefix="$"
                        isBrokerScreen={true}
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        placeholder="$"
                        customClass={getCustomStylesClass('volume')}
                      />
                    </Form.Item>
                    {getIsHighlightedField('volume') && (
                      <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )}
          {![BasicPlans.STD.value, BasicPlans.LTD.value].includes(
            lifePlanType
          ) && (
            <Row className={styles.inlineRow}>
              <Col className={styles.iconCol}>
                <span className={styles.inlineIcon}>
                  <InfoIconComponent
                    showIcon={isUploadedPlan}
                    extractionInfo={llmExtractionInfo?.volume}
                  />
                </span>
              </Col>
              <Col flex="auto">
                <div className={styles.inputWrapper}>
                  <Form.Item
                    name="volume"
                    label="Volume"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    rules={[
                      {
                        required: false,
                        message: EMPTY_MESSAGE,
                        whitespace: true,
                      },
                    ]}
                  >
                    <NumberFormatInput
                      prefix="$"
                      isBrokerScreen={true}
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale={true}
                      allowNegative={false}
                      placeholder="$"
                      customClass={getCustomStylesClass('volume')}
                    />
                  </Form.Item>
                  {getIsHighlightedField('volume') && (
                    <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                  )}
                </div>
              </Col>
            </Row>
          )}
          {isAdvancedProfile &&
            lifePlanServiceList.map((obj) => {
              return (
                <LifeServiceSystemRow
                  key={obj.service}
                  isDefault={false}
                  description={obj.description}
                  service={obj.service}
                  onConfirmClick={() => handleDelete(obj.service)}
                  handleServiceDescriptionChange={(value: string) =>
                    handleServiceDescriptionChange(value, obj.service)
                  }
                  llmExtractionInfo={llmExtractionInfo}
                  lifePlanType={lifePlanType}
                  isHighlighted={!extractionFinalized}
                  isUploadedPlan={isUploadedPlan}
                />
              );
            })}

          {isAdvancedProfile && (
            <div className={styles.addServiceLink}>
              <LinkButton
                onClick={() => {
                  setShowLifeAddServiceModal(true);
                }}
              >
                + Add Service
              </LinkButton>
            </div>
          )}
          <AddNonMDVCustomModal
            visible={showLifeAddServiceModal}
            benefitKind={lifePlanType}
            onClose={() => {
              setShowLifeAddServiceModal(false);
            }}
            planTypeName={lifePlanType}
            displayedServices={[]}
          />
        </Row>
      </InputForm>
    );
  }
);
DisabilityPlanInformation.displayName = 'DisabilityPlanInformation';

export default DisabilityPlanInformation;
