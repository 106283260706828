import React, { useState, useEffect } from 'react';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import style from './editDescriptionModal.module.less';

type Props = {
  visible: boolean;
  confirmText: string;
  description: any;
  label: string;
  onClose: () => void;
  onConfirm: (description: any) => void;
};

const EditDescriptionModal = (props: Props) => {
  const {
    visible,
    confirmText,
    onConfirm,
    onClose,
    description = '',
    label,
  } = props;
  const [editorText, setEditorText] = useState('');

  useEffect(() => {
    if (visible) {
      setEditorText(description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <ConfirmationDialog
      visible={visible}
      title={'Edit Description'}
      confirmText={confirmText}
      cancelText="Cancel"
      onConfirm={() => onConfirm(editorText)}
      closeModal={() => {
        setEditorText('');
        onClose();
      }}
      width={630}
      destroyOnClose={true}
      isCancelLink={true}
      confirmBtnFullWidth={true}
      centered={true}
    >
      <div>
        <div className={style.label}>{label}</div>
        <RichTextEditor
          initialValue={editorText}
          defaultText={editorText}
          onChange={(value: any) => setEditorText(value)}
          limit={2000}
        />
      </div>
    </ConfirmationDialog>
  );
};

export default EditDescriptionModal;
