import { lumityApi } from 'api/apiSlice';

const CARRIER_CONTACT_URI = 'v2/plans/carrier/carrier-contact';
const EMPLOYER_URI = 'v2/employers';

const carrierContactsApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getCarrierContactsByOrganizationId: builder.query<
      object[],
      { organizationId: string }
    >({
      query: ({ organizationId }) => {
        return {
          url: `${CARRIER_CONTACT_URI}/organizations/${organizationId}`,
          method: 'GET',
        };
      },
    }),
    getCarrierContactsByEmployerId: builder.query<any, { employerId: string }>({
      query: ({ employerId }) => {
        return {
          url: `${CARRIER_CONTACT_URI}/employers/${employerId}`,
          method: 'GET',
        };
      },
    }),

    updateSelectedCarrierContacts: builder.mutation<
      any,
      { employerId: string; carrierContacts: string[] }
    >({
      query: ({ employerId, carrierContacts }) => {
        return {
          url: `${CARRIER_CONTACT_URI}/employers/${employerId}`,
          method: 'PUT',
          data: carrierContacts,
          params: { employerId },
        };
      },
    }),

    updateCarrierContactVisibilityStatus: builder.mutation<
      any,
      { employerId: string; isCarrierContactsVisible: boolean }
    >({
      query: ({ employerId, isCarrierContactsVisible }) => {
        return {
          url: `${EMPLOYER_URI}/${employerId}/update-carrier-contacts-visibility`,
          method: 'PUT',
          params: { 'is-carrier-contacts-visible': isCarrierContactsVisible },
        };
      },
    }),
  }),
});

export const {
  useGetCarrierContactsByOrganizationIdQuery,
  useLazyGetCarrierContactsByOrganizationIdQuery,
  useUpdateSelectedCarrierContactsMutation,
  useLazyGetCarrierContactsByEmployerIdQuery,
  useUpdateCarrierContactVisibilityStatusMutation,
} = carrierContactsApi;
