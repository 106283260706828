import { Col, Popover, Row, Tabs } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ReactComponent as DownloadIcon } from 'assets/images/icon-download-black.svg';
import { loginTypes } from 'constants/authConstants';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import CreateIssue from 'modules/issueslog/components/CreateIssue/CreateIssue';
import {
  ANALYTICS_TAB,
  CREATE_NEW_ISSUE,
  ITEMS_TAB,
  NO_SUPPORT_TEAMS_FOR_BROKER,
} from 'modules/issueslog/constants/IssuesLogConstants';
import {
  createUniqueId,
  issueType,
} from 'modules/issueslog/utils/issueLogUtils';
import NoSupportTeamData from 'modules/issueslog/components/NoSupportTeamData/NoSupportTeamData';
import EmployerFeatureDisabledBanner from 'modules/tools/components/EmployerFeatureDisabledBanner/EmployerFeatureDisabledBanner';
import {
  clickShowMore,
  resetFilter,
} from 'modules/issueslog/slices/issuesLogFilterSlice';
import {
  exportIssuesLog,
  useGetArchivedSupportTeamsQuery,
} from 'modules/issueslog/slices/issuesLogSlice';
import {
  exitWithoutSavingMsg,
  FEATURE_KEYS,
  IndividualType,
} from 'constants/commonConstants';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import IssuesLogList from './IssuesLogList/IssuesLogList';
import Analytics from './Analytics/Analytics';
import styles from './homePage.module.less';

const { TabPane } = Tabs;

const Homepage = () => {
  const { setIssueLogType } = useNavContext();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const dispatch = useAppDispatch();

  const { employerId } = useNavContext();

  const layoutBrokerDetails: any = useAppSelector(
    (state) => state.layout.broker
  );
  const layoutEmployerDetails: any = useAppSelector(
    (state) => state.layout.employer
  );

  // This selector for get access info
  const appBootInfo: any = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );

  const filterState = useAppSelector((state) => state.filters);
  const { showMoreFilters } = filterState;

  const savedFilters = useAppSelector((state) => state.filters);

  // Constant
  const isEmployerView = !!params?.employerId;
  const isNotErAdmin: boolean = appBootInfo?.type !== loginTypes.erAdmin;
  const isTechAdmin: boolean = appBootInfo?.type === loginTypes.platform;
  const isBRAdmin: boolean = appBootInfo?.type === loginTypes.bokerAdmin;

  const {
    data: allArchivedSupportTeams,
    isFetching: allArchivedSupportLoading,
  } = useGetArchivedSupportTeamsQuery({
    brokerID: isBRAdmin ? String(params.brokerId) : null,
  });

  const checkIfSupportIssuesAreApplicable = () => {
    return layoutBrokerDetails?.issuesLogSupportEmails?.length === 0
      ? allArchivedSupportTeams?.length === 0
      : false;
  };

  const isShowSupportIssue: boolean =
    params?.type === issueType.SUPPORT &&
    !allArchivedSupportLoading &&
    checkIfSupportIssuesAreApplicable();

  const saveFiltersID = createUniqueId(
    params,
    isEmployerView,
    appBootInfo?.type
  );

  useEffect(() => {
    if (!employerId) {
      setIssueLogType(params?.type || '');
    }
    setActiveTabKey(ITEMS_TAB);
  }, [employerId, setIssueLogType, params, isShowSupportIssue]);

  const [isOpenCreateIssue, setOpenCreateIssue] = useState<boolean>(false);
  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [activeTabKey, setActiveTabKey] = useState<string>(ITEMS_TAB);

  const issueViewRef = useRef<any>();

  // filter refs, had to lift up the ref
  const watchingRef = useRef<any>();
  const brokerageRef = useRef<any>();
  const employerRef = useRef<any>();
  const supportTeamRef = useRef<any>();
  const statusRef = useRef<any>();
  const priorityRef = useRef<any>();
  const typeRef = useRef<any>();
  const assignedToRef = useRef<any>();
  const daysOpenRef = useRef<any>();
  const lastModifiedRef = useRef<any>();
  const viewableByEmployerRef = useRef<any>();

  // filter all reset
  const handelResetAll = () => {
    // replace the existing state in navigation
    navigate(location.pathname, { replace: true });
    watchingRef?.current?.reset();
    brokerageRef?.current?.reset();
    employerRef?.current?.reset();
    supportTeamRef?.current?.reset();
    statusRef?.current?.reset();
    priorityRef?.current?.reset();
    typeRef?.current?.reset();
    assignedToRef?.current?.reset();
    daysOpenRef?.current?.reset();
    lastModifiedRef?.current?.reset();
    viewableByEmployerRef?.current?.reset();
    issueViewRef?.current?.reset();
    dispatch(resetFilter({ filterSaveType: saveFiltersID }));
  };

  const getIssueTypeToName = (type: string) => {
    if (!isEmployerView && issueType.ACCOUNT === type) return 'Account';
    if (!isEmployerView && issueType.SUPPORT === type) return 'Support';
    if (isNotErAdmin && issueType.ACCOUNT === type)
      return `${layoutEmployerDetails?.name || ''} Account`;
    if (isNotErAdmin && issueType.SUPPORT === type)
      return `${layoutEmployerDetails?.name || ''} Support`;

    if (!isNotErAdmin) return `${layoutEmployerDetails?.name || ''} Account`;

    return '';
  };

  const showIssueLogTableLogics = () => {
    if (isTechAdmin && isEmployerView && isShowSupportIssue) return false;
    if (isBRAdmin && isShowSupportIssue) return false;
    return true;
  };

  const isSupportButtonDisabled = (): boolean => {
    return (
      params?.type === issueType.SUPPORT &&
      !layoutBrokerDetails?.issuesLogSupportEmails?.length
    );
  };

  const tabExtraContent = (
    <div className={styles.tabsExtraContentWrapper}>
      <LinkButton
        className={styles.tabExtraButtons}
        onClick={() => {
          setIsExporting(true);
          const employerIds: string[] = [];
          const organizationIds: string[] = [];

          params?.employerId && employerIds?.push(String(params?.employerId));
          params?.brokerId && organizationIds?.push(String(params?.brokerId));
          exportIssuesLog(
            savedFilters[saveFiltersID].filters.filters,
            savedFilters[saveFiltersID].filters.searchKey,
            isEmployerView
              ? IndividualType.ER_ADMIN
              : isTechAdmin
              ? IndividualType.PLATFORM_ADMIN
              : IndividualType.ORGANIZATION_ADMIN,
            params?.type?.toUpperCase(),
            organizationIds,
            employerIds
          ).then(() => {
            setIsExporting(false);
          });
        }}
        disabled={isExporting}
        loading={isExporting}
      >
        Export Excel File
        <DownloadIcon className={styles.downloadIcon} />
      </LinkButton>

      <LinkButton className={styles.tabExtraButtons} onClick={handelResetAll}>
        Reset All Filters
      </LinkButton>
      {!isEmployerView && (
        <LinkButton
          className={styles.tabExtraButtons}
          onClick={() => dispatch(clickShowMore(!showMoreFilters))}
        >
          {!showMoreFilters ? (
            <span>
              More Filters <CaretDownOutlined className={styles.downIcon} />
            </span>
          ) : (
            <span>
              Less Filters <CaretUpOutlined className={styles.downIcon} />
            </span>
          )}
        </LinkButton>
      )}
    </div>
  );

  return (
    <>
      <EmployerFeatureDisabledBanner
        feature={FEATURE_KEYS.ISSUES_LOG}
        featureName="Account Log"
        employerId={params.employerId}
        brokerId={params.brokerId}
      />
      <div className={styles.IssuesLogHome}>
        <div className={styles.issuesLogHomePageWrapper}>
          <Row>
            <Col span={24}>
              <Row className={styles.headerRow}>
                <Col span={16}>
                  <h1>{`${getIssueTypeToName(String(params?.type))} Log`}</h1>
                </Col>

                {!isTechAdmin && activeTabKey !== ANALYTICS_TAB ? (
                  !isShowSupportIssue && (
                    <Col span={8}>
                      {isSupportButtonDisabled() ? (
                        // disabled
                        <Popover
                          content={
                            <div className={styles.warning}>
                              {NO_SUPPORT_TEAMS_FOR_BROKER}
                            </div>
                          }
                          placement="topRight"
                          overlayClassName="popover"
                        >
                          <PageActionButton
                            type="primary"
                            className={styles.actionButtonDisabled}
                          >
                            {CREATE_NEW_ISSUE}
                          </PageActionButton>
                        </Popover>
                      ) : (
                        <PageActionButton
                          onClick={() => {
                            setOpenCreateIssue(!isOpenCreateIssue);
                          }}
                          type="primary"
                          className={styles.actionButton}
                        >
                          {CREATE_NEW_ISSUE}
                        </PageActionButton>
                      )}
                    </Col>
                  )
                ) : (
                  <div className={styles.emptyButton}></div>
                )}
              </Row>

              {showIssueLogTableLogics() ? (
                <Tabs
                  type="card"
                  size="large"
                  className={styles.employerTabs}
                  defaultActiveKey={ITEMS_TAB}
                  activeKey={activeTabKey}
                  tabBarExtraContent={
                    activeTabKey === ANALYTICS_TAB ? '' : tabExtraContent
                  }
                  onChange={(activeKey) => {
                    setActiveTabKey(activeKey);
                  }}
                >
                  <TabPane tab="Items" key={ITEMS_TAB}>
                    <IssuesLogList
                      ref={issueViewRef}
                      filterRefs={{
                        watchingRef: watchingRef,
                        brokerageRef: brokerageRef,
                        employerRef: employerRef,
                        supportTeamRef: supportTeamRef,
                        statusRef: statusRef,
                        priorityRef: priorityRef,
                        typeRef: typeRef,
                        assignedToRef: assignedToRef,
                        daysOpenRef: daysOpenRef,
                        lastModifiedRef: lastModifiedRef,
                        viewableByEmployerRef: viewableByEmployerRef,
                      }}
                    />
                  </TabPane>
                  {!isTechAdmin && !isEmployerView ? (
                    <TabPane tab="Analytics" key={ANALYTICS_TAB}>
                      <Analytics tab={activeTabKey}></Analytics>
                    </TabPane>
                  ) : (
                    ''
                  )}
                </Tabs>
              ) : (
                <Row justify="center">
                  <NoSupportTeamData />
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <FullScreenModal
        visible={isOpenCreateIssue}
        footer={false}
        onCancel={() => {
          setVisibleConfirmation(true);
        }}
        destroyOnClose={true}
        className={styles.Model}
      >
        <CreateIssue
          onSuccessfullySaved={(id: string) => {
            setOpenCreateIssue(false);
            navigate(id);
          }}
          onClickedCancel={() => {
            setVisibleConfirmation(true);
          }}
        />
        {visibleConfirmation && (
          <ConfirmationDialog
            title="Are you sure you want to close?"
            confirmText="Yes - close and do not save"
            cancelText="Cancel"
            closeModal={() => {
              setVisibleConfirmation(false);
            }}
            onConfirm={() => {
              setOpenCreateIssue(false), setVisibleConfirmation(false);
            }}
            visible={visibleConfirmation}
          >
            <p className={styles.warningConfirmation}>{exitWithoutSavingMsg}</p>
          </ConfirmationDialog>
        )}
      </FullScreenModal>
    </>
  );
};
export default Homepage;
