import { FC } from 'react';
import { Col, Row, Form, Input } from 'antd';
import { isEmpty } from 'lodash';

import { useParams } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import CheckboxSelect from 'components/CheckboxSelect/CheckboxSelect';
import InputPhoneNumber from 'components/InputPhoneNumber/InputPhoneNumber';
import ImageUploader from 'components/ImageUploader/ImageUploader';

import { isMDV, isRLS } from 'modules/carriers/components/util/carrierUtil';
import { benefitTypes } from 'modules/carriers/constants/carrierConstants';
import { logoUploadHelperText } from 'constants/commonConstants';
import OriginalImageCropParams from 'model/OriginalImageCropParams';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { useAppSelector } from 'hooks/redux';
import styles from './carrierBenefits.module.less';

type CarrierBenefitsPropTypes = {
  benefitError: boolean;
  onSelectBenefitCategories: any;
  handleTextArea: any;
  getCheckedState: any;
  invalidPhoneNumber: boolean;
  additioanlInfoURlError: boolean;
  additionalInfo: string;
  webURlError: boolean;
  isImageError: boolean;
  logoImg: string;
  originalImg: string;
  logoCropParams: OriginalImageCropParams | null;
  imageValidateSetting: string;
  isCarrierLogoValidationMsgRemoved: boolean;
  onLogoRemove: any;
  onValidateFails: any;
  onConfirmCrop: any;
  inProgress: boolean;
  trimValue: any;
  handleProvider: any;
  isEdit: boolean | undefined;
  getBenefitCarrierUtilized: any;
};

const CarrierBenefits: FC<CarrierBenefitsPropTypes> = (
  props: CarrierBenefitsPropTypes
) => {
  const {
    benefitError,
    onSelectBenefitCategories,
    getCheckedState,
    invalidPhoneNumber,
    additioanlInfoURlError,
    additionalInfo,
    handleTextArea,
    webURlError,
    isImageError,
    logoImg,
    imageValidateSetting,
    isCarrierLogoValidationMsgRemoved,
    onConfirmCrop,
    inProgress,
    onLogoRemove,
    onValidateFails,
    originalImg,
    logoCropParams,
    trimValue,
    handleProvider,
    isEdit,
    getBenefitCarrierUtilized,
  } = props;
  const params = useParams();
  const carrierDetails = useAppSelector((state) => state.carriers.carrierObj);

  function getInitialValue(isMDV: boolean, carrierDetails: any) {
    return isMDV
      ? carrierDetails?.providerSearchAdditionalInfo ?? ''
      : carrierDetails?.benefitDescription ?? '';
  }

  return (
    <>
      <Row>
        <Col span={16}>
          <Form.Item
            name="logoUrl"
            label="Carrier Logo"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
            validateStatus={
              !isImageError &&
              (!logoImg || !isEmpty(logoImg)) &&
              !imageValidateSetting &&
              isCarrierLogoValidationMsgRemoved
                ? ''
                : 'error'
            }
          >
            <ImageUploader
              cropShape="rect"
              aspect={4 / 3}
              onConfirm={onConfirmCrop}
              loading={inProgress}
              uploadedImage={logoImg}
              onRemove={onLogoRemove}
              onValidateFails={onValidateFails}
              showOperations
              title="Carrier Logo"
              helpText={logoUploadHelperText}
              unCroppedImage={originalImg}
              defaultCropArea={logoCropParams || undefined}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={110}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Carrier Name"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: 'Carrier Name required',
                validateTrigger: ['onSubmit'],
              },
            ]}
          >
            <Input onBlur={trimValue('name')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="url"
            label="Website URL"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: false,
                message: '',
                whitespace: true,
              },
            ]}
            className={`ant-form-item-label ${
              webURlError ? styles.errorCarrier : ''
            }`}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      {!params.id && (
        <>
          <Row>
            <label
              className={`ant-form-item-label ${styles.benefitCategoryLabel} ${
                benefitError ? styles.errorCarrierBenefit : ''
              }`}
            >
              Benefit Categories *
            </label>
          </Row>
          <Row className={styles.benefitTypes}>
            <Col span={8}>
              {Object.keys(benefitTypes).map((benefitType, index) => {
                if (
                  index === 0 ||
                  index === 1 ||
                  index === 2 ||
                  index === 3 ||
                  index === 4
                ) {
                  return (
                    <Form.Item key={benefitType} name="benefitCategories">
                      <CheckboxSelect
                        onChange={(e) =>
                          onSelectBenefitCategories(e, benefitType)
                        }
                        disabled={
                          isEdit && getBenefitCarrierUtilized(benefitType)
                        }
                        checked={getCheckedState(benefitType)}
                        className={`${
                          benefitError ? styles.errorCarrierBenefit : ''
                        }`}
                      >
                        <span className={styles.checkboxText}>
                          {benefitTypes[benefitType].label}
                        </span>
                      </CheckboxSelect>
                    </Form.Item>
                  );
                }
              })}
            </Col>
            <Col span={8}>
              {Object.keys(benefitTypes).map((benefitType, index) => {
                if (
                  index === 5 ||
                  index === 6 ||
                  index === 7 ||
                  index === 8 ||
                  index === 9
                ) {
                  return (
                    <Form.Item key={benefitType} name="benefitCategories">
                      <CheckboxSelect
                        onChange={(e) =>
                          onSelectBenefitCategories(e, benefitType)
                        }
                        disabled={
                          isEdit && getBenefitCarrierUtilized(benefitType)
                        }
                        checked={getCheckedState(benefitType)}
                        className={`${
                          benefitError ? styles.errorCarrierBenefit : ''
                        }`}
                      >
                        <span className={styles.checkboxText}>
                          {benefitTypes[benefitType].label}
                        </span>
                      </CheckboxSelect>
                    </Form.Item>
                  );
                }
              })}
            </Col>
            <Col span={8}>
              {Object.keys(benefitTypes).map((benefitType, index) => {
                if (index === 10 || index === 11 || index === 12) {
                  return (
                    <Form.Item key={benefitType} name="benefitCategories">
                      <CheckboxSelect
                        onChange={(e) =>
                          onSelectBenefitCategories(e, benefitType)
                        }
                        disabled={
                          isEdit && getBenefitCarrierUtilized(benefitType)
                        }
                        checked={getCheckedState(benefitType)}
                        className={`${
                          benefitError ? styles.errorCarrierBenefit : ''
                        }`}
                      >
                        <span className={styles.checkboxText}>
                          {benefitTypes[benefitType].label}
                        </span>
                      </CheckboxSelect>
                    </Form.Item>
                  );
                }
              })}
            </Col>
          </Row>
        </>
      )}
      {params.id && (
        <>
          <Row gutter={110}>
            <Col span={12}>
              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: false,
                    message: '',
                    whitespace: true,
                  },
                ]}
                className={`ant-form-item-label ${
                  invalidPhoneNumber ? styles.errorCarrier : ''
                }`}
              >
                <InputPhoneNumber />
              </Form.Item>
            </Col>
            {isMDV(params) && (
              <Col span={12}>
                <Form.Item
                  name="providerSearchUrl"
                  label="Provider Search URL"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: false,
                      message: '',
                      whitespace: true,
                    },
                  ]}
                  className={`ant-form-item-label ${
                    additioanlInfoURlError ? styles.errorCarrier : ''
                  }`}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={24} className={styles.benefitCountLabel}>
              {!isRLS(params) && (
                <>
                  <Form.Item
                    name={
                      isMDV(params)
                        ? 'providerSearchAdditionalInfo'
                        : 'benefitDescription'
                    }
                    label={
                      isMDV(params) ? (
                        'Provider Search Additional Information'
                      ) : (
                        <>
                          <span>Benefit Description</span>
                          <span className="providerTextInfo">
                            (Templated plan card text customizable in the DBG)
                          </span>
                        </>
                      )
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: false,
                        message: '',
                        whitespace: true,
                      },
                    ]}
                  >
                    {isMDV(params) ? (
                      <TextArea
                        className={styles.providerSearchAdditionalInfo}
                        onChange={
                          isMDV(params) ? handleProvider : handleTextArea
                        }
                        showCount
                        name={
                          isMDV(params)
                            ? 'providerSearchAdditionalInfo'
                            : 'benefitDescription'
                        }
                        value={additionalInfo}
                        maxLength={1500}
                      />
                    ) : (
                      <RichTextEditor
                        height={160}
                        initialValue={getInitialValue(
                          isMDV(params),
                          carrierDetails
                        )}
                        limit={1500}
                        countString={'Character Limit'}
                      />
                    )}
                  </Form.Item>
                  {isMDV(params) && (
                    <div className={styles.characterCountText}>
                      Character Limit:
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CarrierBenefits;
