import { FC, useEffect, useState } from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import SelectOptions from 'components/SelectOptions/SelectOptions';
import PlanTable from 'modules/renewals/components/PlanTable/PlanTable';
import {
  IN_NETWORK_VALUE,
  NAME_FIELD_PROPERTY,
  OFFER_PLAN_PROPERTY,
  OFFER_STATUS,
} from 'modules/renewals/constants/renewalsConstants';
import { useLazyComparePlansQuery } from 'modules/renewals/slices/offersSlice';
import { ColumnData } from 'modules/renewals/models/ColumnData';
import CustomerSpinner from 'components/Spinner/CustomSpinner';
import { getCategoryForUrlNavigation } from 'modules/renewals/utils/renewalsUtils';
import { getPlanTableColumnLabels } from 'modules/renewals/utils/planTableLabelUtil';
import FundingType from 'modules/plans/enums/FundingType';
import styles from './comparePlans.module.less';

type ComparePlansProps = {
  visible: boolean;
  onClose: Function;
  initialSelectedPlanIndex: number;
  offer?: any;
  plansData?: any;
  selectedPlanId?: any;
  benefitType: string | undefined;
  offerStatus: string | undefined;
  isProposal?: boolean;
  proposalBenefitTypes?: any[];
  proposalBenefitPlansData?: any;
  fundingType?: FundingType;
  planYearId?: string;
  isOffer?: boolean;
};
const ComparePlans: FC<ComparePlansProps> = ({
  visible,
  onClose,
  initialSelectedPlanIndex,
  offer,
  plansData,
  selectedPlanId,
  benefitType,
  offerStatus,
  isProposal,
  proposalBenefitTypes,
  proposalBenefitPlansData,
  fundingType,
  planYearId,
  isOffer,
}) => {
  const [selectedPlan, setSelectedPlan] = useState<any>(
    plansData?.[initialSelectedPlanIndex] ?? null
  );
  const [planDetails, setPlanDetails] = useState<any>(plansData);

  const { brokerId, employerId } = useParams();

  const [comparePlans, { data: comparedPlans, isLoading: isCompareLoading }] =
    useLazyComparePlansQuery();

  const [selectedBenefitCategory, setSelectedBenefitCategory] = useState<any>(
    'Select Benefit Category'
  );

  const [isPlanTableVisible, setIsPlanTableVisible] = useState<boolean>(true);

  const [selectBenefitPlanOptions, setSelectBenefitPlanOptions] = useState<any>(
    []
  );

  const [selectedValue, setSelectedValue] = useState<any>();

  const selectedNetworkType: string = IN_NETWORK_VALUE;

  useEffect(() => {
    const planId = String(
      selectedPlan?.id ?? selectedPlan?.planId ?? selectedPlanId
    );
    if (planId === 'undefined') return;
    comparePlans({
      employerId,
      planYearId: (isOffer === false ? planYearId : offer?.planYearId) ?? '',
      planId: planId,
      category: isProposal
        ? String(selectedBenefitCategory.toUpperCase())
        : String(benefitType),
      offerStatus: offerStatus ? String(offerStatus) : OFFER_STATUS.PUBLISHED,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan, planYearId]);

  useEffect(() => {
    if (planDetails === undefined) {
      switch (benefitType) {
        case 'MEDICAL':
          setPlanDetails(
            comparedPlans?.offeredMedicalPlans?.filter(
              (item: any, index: number) => index !== 0
            )
          );
          break;
        case 'DENTAL':
          setPlanDetails(
            comparedPlans?.offeredDentalPlans?.filter(
              (item: any, index: number) => index !== 0
            )
          );
          break;
        case 'VISION':
          setPlanDetails(
            comparedPlans?.offeredVisionPlans?.filter(
              (item: any, index: number) => index !== 0
            )
          );
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparedPlans]);

  useEffect(() => {
    setPlanDetails(plansData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansData]);

  useEffect(() => {
    arrangeColData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    comparePlans,
    selectedNetworkType,
    isCompareLoading,
    selectedPlan,
    visible,
  ]);

  const arrangeColData = (): ColumnData[] => {
    return getPlanTableColumnLabels({
      benefitType: comparedPlans?.benefitCategory,
      plans:
        comparedPlans?.[OFFER_PLAN_PROPERTY?.[comparedPlans?.benefitCategory]],
      fundingType: fundingType!,
      isComparePlans: true,
      isPlanDetails: false,
      showErrorHighlight: false,
      addFooterRow: false,
      addEnrollmentsForPremiums: false,
      isNTier: false,
    });
  };

  const getDefaultSelectPlanOption = () => {
    return {
      value: isProposal ? '' : initialSelectedPlanIndex,
      label: isProposal
        ? ''
        : planDetails?.[initialSelectedPlanIndex]?.name ?? '',
    };
  };

  const closeComparePlansModal = () => {
    onClose(false);
    if (isProposal) {
      setSelectedValue(null);
      setIsPlanTableVisible(false);
      setSelectBenefitPlanOptions(null);
      setSelectedBenefitCategory('Select Benefit Category');
    }
  };

  const proposalPlansMDV: any = (benefitType: string) => {
    const proposalPlans = proposalBenefitPlansData?.find(
      (item: any) => item.type === benefitType
    );
    const allPlans = [] as any;
    proposalPlans?.tableData?.forEach((item: any) => {
      item?.rowData?.forEach((rowItem: any) => {
        allPlans.push(rowItem);
      });
    });
    setPlanDetails(allPlans);
  };

  useEffect(() => {
    if (isProposal) {
      const options: any[] = [];
      (planDetails ?? [])
        .filter((obj: any) => !obj?.newPlan)
        .forEach((plan: any, index: number) => {
          options.push({
            value: index,
            label: plan?.name,
          });
        });
      setSelectBenefitPlanOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planDetails]);

  const getSelectPlanOptions = () => {
    const options: any[] = [];
    (planDetails ?? [])
      .filter((obj: any) => !obj?.newPlan)
      .forEach((plan: any, index: number) => {
        options.push({
          value: index,
          label: plan?.name,
        });
      });

    return options;
  };

  const getSelectBenefitTypeOptions = () => {
    const options = (proposalBenefitTypes ?? []).map((benType) => ({
      label: benType,
      value: benType,
    }));

    return options;
  };

  const handleOfferDetailClick = (bundleId: any) => {
    const category = getCategoryForUrlNavigation(
      isProposal ? selectedBenefitCategory.toUpperCase() : benefitType
    );
    window.open(
      `/brokers/${brokerId}/employers/${employerId}/renewals/carrier/${category}/${bundleId}/detail`,
      '_blank'
    );
  };

  const getPlansApplicable = (data: any[]) => {
    const planNames = new Set();
    return data?.filter((item: any) => {
      if (item?.referenceId !== null) return true; // Let current plan pass through.
      const name = item['name'];
      if (!planNames.has(name)) {
        planNames.add(name);
        return true;
      }
      return false;
    });
  };

  const handleSelectPlan = (newPlanIndex: number) => {
    setSelectedValue({
      value: newPlanIndex,
      label: planDetails?.[newPlanIndex].name,
    });
    setIsPlanTableVisible(true);
    setSelectedPlan(planDetails?.[newPlanIndex] ?? null);
  };

  const handleSelectBenefitCategory = (benefitType: String) => {
    setSelectedValue(null);
    setIsPlanTableVisible(false);
    proposalPlansMDV(benefitType);
    setSelectedBenefitCategory(benefitType);
  };

  return (
    <FullScreenModal
      visible={visible}
      destroyOnClose={true}
      onCancel={closeComparePlansModal}
      confirmTitle={false}
      footer={false}
    >
      {isCompareLoading ? (
        <CustomerSpinner />
      ) : (
        <div className={styles.compareOffersWrapper}>
          <Row justify={'center'} className={styles.comparePlansTitle}>
            Compare Plans
          </Row>
          <Row justify={'center'} className={styles.offerName}>
            {offer?.name ?? ''}
          </Row>
          {selectedPlanId === undefined && (
            <div className={styles.selectPlanDropdown}>
              <p className={styles.planSelectHeader}>Benefit</p>
              <span className={styles.planSelectOptions}>
                <SelectOptions
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  options={getSelectBenefitTypeOptions()}
                  defaultValue={'Select Benefit Category'}
                  value={selectedBenefitCategory}
                  onChange={handleSelectBenefitCategory}
                />
              </span>
            </div>
          )}
          {!isProposal && (
            <div className={styles.selectPlanDropdown}>
              <p className={styles.planSelectHeader}>Plan Name</p>
              <span className={styles.planSelectOptions}>
                <SelectOptions
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  options={getSelectPlanOptions()}
                  defaultValue={getDefaultSelectPlanOption()}
                  onChange={handleSelectPlan}
                />
              </span>
            </div>
          )}

          {isProposal && (
            <div className={styles.selectPlanDropdown}>
              <p className={styles.planSelectHeader}>Plan Name</p>
              <span className={styles.planSelectOptions}>
                <SelectOptions
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  options={selectBenefitPlanOptions}
                  onSelect={handleSelectPlan}
                  value={selectedValue}
                />
              </span>
            </div>
          )}
          <div className={styles.planTableWrapper}>
            {(comparedPlans?.[
              OFFER_PLAN_PROPERTY?.[comparedPlans?.benefitCategory]
            ] ?? 0) !== 0 &&
              isPlanTableVisible && (
                <PlanTable
                  data={getPlansApplicable(
                    comparedPlans?.[
                      OFFER_PLAN_PROPERTY?.[comparedPlans?.benefitCategory]
                    ]
                  )}
                  labelColumnData={arrangeColData()}
                  titleProperty={NAME_FIELD_PROPERTY}
                  logoProperty={comparedPlans?.carrier?.logoUrl}
                  benefitType={comparedPlans?.benefitCategory}
                  offer={offer}
                  isComparePlans={true}
                  offerStatus={offerStatus}
                  handleOfferDetailClick={handleOfferDetailClick}
                />
              )}
          </div>
          <PageActionButton
            onClick={closeComparePlansModal}
            type="primary"
            className={styles.cancelButton}
          >
            Close
          </PageActionButton>
        </div>
      )}
    </FullScreenModal>
  );
};

export default ComparePlans;
