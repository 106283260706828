import { FC, useEffect, useState } from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import Stepper from 'components/Stepper/Stepper';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import { getCurrentPlansBG } from 'modules/employers/slices/planBGCloneSlice';
import BenefitGuideDetails from 'modules/employers/components/AFPCloneDBGAndPlans/BenefitGuideDetails/BenefitGuideDetails';
import PlanDetails from 'modules/employers/components/AFPCloneDBGAndPlans/PlanDetails/PlanDetails';
import AdditionalBenefits from 'modules/employers/components/AFPCloneDBGAndPlans/AdditionalBenifits/AdditionalBenefits';
import ReviewPlanDetails from 'modules/employers/components/AFPCloneDBGAndPlans/ReviewPlanDetails/ReviewPlanDetails';
import PlanYear from 'model/PlanYear';

import styles from './cloneStepController.module.less';

type CloneStepControllerProps = {
  closeModal: () => void;
  onCancelModal: () => void;
  isClonePlansModalVisible: boolean;
};

const CloneStepController: FC<CloneStepControllerProps> = (
  props: CloneStepControllerProps
) => {
  const { closeModal, isClonePlansModalVisible, onCancelModal } = props;
  const { employerId } = useParams();
  const [currentStep, setCurrentStep] = useState<number>(0);

  const dispatch = useAppDispatch();
  const { planYearsList } = useAppSelector((state) => state.employer.employer);
  const selectedPlanYear: Partial<PlanYear> = useAppSelector(
    (state) => state.employer.planBGClone.selectedPlanYear
  );

  const currentPlanYear = planYearsList?.find((item) => item?.current);

  useEffect(() => {
    if (isClonePlansModalVisible) {
      dispatch(
        getCurrentPlansBG({
          currentPlanYearId: selectedPlanYear?.id || '',
          employerId: employerId || '',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClonePlansModalVisible]);

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className={styles.stepWrapper}>
            <AdditionalBenefits setCurrentStep={setCurrentStep} />
          </div>
        );
      case 1:
        return (
          <div className={styles.stepWrapper}>
            <PlanDetails setCurrentStep={setCurrentStep} />
          </div>
        );
      case 2:
        return (
          <div className={styles.stepWrapper}>
            <BenefitGuideDetails setCurrentStep={setCurrentStep} />
          </div>
        );

      case 3:
        return (
          <div className={styles.stepWrapper}>
            <ReviewPlanDetails
              setCurrentStep={setCurrentStep}
              closeModal={closeModal}
            />
          </div>
        );

      default:
        return;
    }
  };

  return (
    <div className={styles.modalBodyContainer}>
      <div>
        <p>From {currentPlanYear?.name}</p>
      </div>
      <div className={styles.stepContainer}>
        <Stepper
          headers={[
            'Select Plans',
            'Plans Details',
            'Benefit Guide Details',
            'Review',
          ]}
          activeStep={currentStep}
          changeCurrentStep={setCurrentStep}
          renderStepContent={renderStepContent}
          isLastStepActive={true}
        />
      </div>
      <Row justify="center" className={styles.stepFooter}>
        <CancelButton
          onClick={() => {
            onCancelModal();
          }}
        >
          Cancel
        </CancelButton>
      </Row>
    </div>
  );
};

export default CloneStepController;
