import React, { FC, useCallback, useState } from 'react';
import { Col, Popover, Row, Switch } from 'antd';

import { isNull } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { useNavContext } from 'hooks/useNavContext';
import {
  getBrokerInfoView,
  clearBrokerData,
  clearUpdateBrokerUpdation,
} from 'modules/brokers/slices/brokerBasicInfoSlice';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import { useIsAuthorizedUserRole } from 'hooks/useIsAuthorizedUserRole';
import {
  ALLOWED_INDIVIDUAL_SUB_TYPES,
  IndividualSubTypes,
} from 'constants/commonConstants';
import { loginTypes } from 'constants/authConstants';

import { getBroker } from 'layout/slices/layoutSlice';
import BrokerInfoEdit from 'modules/brokers/pages/BrokerInfoEdit/BrokerInfoEdit';

import InfoLabel from 'components/InfoLabel/InfoLabel';

import BrokerLocation from 'model/BrokerLocation';

import { buildAddress, toStringToProperCase } from 'util/stringUtil';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import ConfigureSsoModal from 'modules/sso/components/ConfigureSsoModal/ConfigureSsoModal';
import styles from './brokerInfoView.module.less';

type BrokerBasicInfoPropTypes = {
  isModalOpen: boolean;
  setIsModalOpen: Function;
};

const BrokerInfoView: FC<BrokerBasicInfoPropTypes> = (
  props: BrokerBasicInfoPropTypes
) => {
  const { isModalOpen, setIsModalOpen } = props;
  const dispatch = useAppDispatch();
  const brokerBasicInfo = useAppSelector(
    (state) => state.brokers.brokerBasicInfo
  );
  const { brokerObj } = brokerBasicInfo;
  const { brokerId } = useNavContext();
  const [isEnableSsoModalOpen, setIsEnableSsoModalOpen] =
    useState<boolean>(false);
  const [selectedSsoDetails, setSelectedSsoDetails] = useState<any>({});

  const isTechAdmin = useIsAuthorizedUserRole([loginTypes.platform]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    if (brokerId) {
      dispatch(getBrokerInfoView(brokerId));
      dispatch(getBroker(brokerId));
    }
    dispatch(clearBrokerData());
    dispatch(clearUpdateBrokerUpdation());
  }, [dispatch, brokerId, setIsModalOpen]);

  const primaryLocation =
    brokerObj.locations &&
    brokerObj.locations.filter(
      (location: BrokerLocation) => location.primary
    )[0];

  let address = '';
  if (primaryLocation) {
    address = buildAddress(primaryLocation.address);
  }

  const handleBrokerSsoToggleClick = (check: boolean): void => {
    if (check) {
      setSelectedSsoDetails({
        organizationId: brokerObj?.id,
        name: brokerObj?.name,
        metadataUrl: brokerObj?.ssoConfig?.metadataUrl,
        vendor: brokerObj?.ssoConfig?.vendor,
      });
      setIsEnableSsoModalOpen(true);
    }
  };

  /**
   * Fetch broker information
   */
  const onUpdate = (): void => {
    if (brokerId) {
      dispatch(getBrokerInfoView(brokerId));
    }
  };

  const securedSwitchBrokerUser = usePermitByUserType(
    <Row>
      <Col span={10}>
        <InfoLabel
          labelText="SSO"
          extraColValue3={12}
          value={
            <Row>
              <Col>
                <div className={styles.ssoToggleSwitchCell}>
                  <div className={styles.switchWrapper}>
                    <Switch
                      checked={!isNull(brokerObj?.ssoConfig)}
                      onClick={(checked) => handleBrokerSsoToggleClick(checked)}
                      disabled={true}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          }
        />
      </Col>
    </Row>,

    [IndividualSubTypes.BROKER_USER]
  );

  const securedSSOSection = usePermitByUserType(
    <Row>
      <Col span={10}>
        <InfoLabel
          labelText="SSO"
          extraColValue3={12}
          value={
            <Row>
              <Col>
                <div className={styles.ssoToggleSwitchCell}>
                  <Popover
                    overlayClassName="popoverInner recommendedtooltip"
                    trigger={!isNull(brokerObj?.ssoConfig) ? 'hover' : []}
                    content={
                      <div>
                        Use the <b>Manage</b> link
                      </div>
                    }
                  >
                    <div className={styles.switchWrapper}>
                      <Switch
                        checked={!isNull(brokerObj?.ssoConfig)}
                        onClick={(checked) =>
                          handleBrokerSsoToggleClick(checked)
                        }
                        disabled={!isNull(brokerObj?.ssoConfig)}
                      />
                    </div>
                  </Popover>
                </div>
              </Col>
              {!brokerObj?.ssoConfig && (
                <Col className={`${styles.infoValueSection} info-label-value`}>
                  Off
                </Col>
              )}
              {brokerObj?.ssoConfig && (
                <Col>
                  <div className={`${styles.vendorWrapper}`}>
                    {toStringToProperCase(brokerObj?.ssoConfig?.vendor ?? '')} (
                    <span
                      className={styles.manageSsoBtn}
                      onClick={() => handleBrokerSsoToggleClick(true)}
                    >
                      manage
                    </span>
                    )
                  </div>
                </Col>
              )}
            </Row>
          }
        />
      </Col>
    </Row>,
    ALLOWED_INDIVIDUAL_SUB_TYPES
  );

  return (
    <div>
      <div>
        <Row>
          <Col span={10}>
            <InfoLabel
              labelText="Broker Name"
              value={
                <OverflowPopover>
                  <span>{brokerObj.name}</span>
                </OverflowPopover>
              }
            />
          </Col>
          <Col span={14}>
            <InfoLabel
              labelText="Logo"
              value={
                brokerObj.logoUrl && (
                  <img src={brokerObj.logoUrl} className={styles.logoImg} />
                )
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <InfoLabel labelText="Address" value={address} />
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <InfoLabel
              labelText="Primary Contact"
              value={
                brokerObj.primaryContact && brokerObj.primaryContact.contactName
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <InfoLabel
              labelText="Company Email Domains"
              noExtraCol={false}
              extraColValue3={11}
              value={
                brokerObj?.emailDomains?.length ? (
                  brokerObj?.emailDomains.map((domain: string, index) => (
                    <div key={index}>
                      <OverflowPopover>{domain}</OverflowPopover>
                    </div>
                  ))
                ) : (
                  <span>-</span>
                )
              }
            />
          </Col>
        </Row>
        {brokerObj?.ssoEnabled && securedSSOSection}
        {brokerObj?.ssoEnabled && !isTechAdmin && securedSwitchBrokerUser}
      </div>
      <BrokerInfoEdit isModalOpen={isModalOpen} closeModal={closeModal} />
      <ConfigureSsoModal
        isOpen={isEnableSsoModalOpen}
        onCancel={() => setIsEnableSsoModalOpen(false)}
        onUpdate={onUpdate}
        data={selectedSsoDetails}
      />
    </div>
  );
};

export default BrokerInfoView;
