import { FC, useRef, useState } from 'react';
import { Row } from 'antd';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { ReactComponent as Bill } from 'assets/images/bill.svg';
import EmptyBillingBarChart from 'modules/employers/components/EmptyBillingBarChart/EmptyBillingBarChart';
import BillingUpload from 'modules/billing/pages/BillingUpload';
import {
  EMP_MEMBER_BILLING_RESTRICED,
  IndividualSubTypes,
} from 'constants/commonConstants';
import { useAppSelector } from 'hooks/redux';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import styles from './NoBillingViewDashboard.module.less';

type NoBillingViewProps = {
  isUploaderOpen: Function;
};

const NoBillingView: FC<NoBillingViewProps> = () => {
  const billingUploadRef = useRef();
  const [isUploaderOpen, setIsUploaderOpen] = useState<boolean>(false);
  const [, setIsBillingManageOpen] = useState<boolean>(false);
  // navigate to import section if
  const handleImportBillingFileClick = () => {
    setIsUploaderOpen(true);
  };

  const individualSubType = useAppSelector(
    (state) => state?.auth?.auth?.appBootupInfo?.individualSubType
  );

  return (
    <>
      <BillingUpload
        ref={billingUploadRef}
        isUploaderOpen={isUploaderOpen}
        setIsUploaderOpen={setIsUploaderOpen}
        openBilling={setIsBillingManageOpen}
        isDashboardUpload={true}
      />
      <div className={styles.noBillingPreviewContent}>
        {/* Empty Billing Section */}
        <EmptyBillingBarChart />
      </div>
      <div className={styles.noUploadContent}>
        <div className={styles.noBillingView}>
          <div className={styles.noBillingViewWrapper}>
            <Row className={styles.description}>
              <Bill />
            </Row>
            <Row className={styles.description}>
              <h2>You don’t have any Billing Data</h2>
            </Row>
            <Row className={styles.description}>
              <p>Start by importing billing data below:</p>
            </Row>
            <Row className={styles.description}>
              {[
                IndividualSubTypes.EMPLOYER_MEMBER,
                IndividualSubTypes.EMPLOYER_ADMIN,
              ].includes(individualSubType!) ? (
                <PlanyearPopover content={EMP_MEMBER_BILLING_RESTRICED}>
                  <PageActionButton
                    type="primary"
                    className={styles.disabledBillingButton}
                  >
                    Import Billing Files
                  </PageActionButton>
                </PlanyearPopover>
              ) : (
                <PageActionButton
                  type="primary"
                  onClick={handleImportBillingFileClick}
                  className={styles.uploadBillingButton}
                >
                  Import Billing Files
                </PageActionButton>
              )}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoBillingView;
