import { Row, Col, Form, Input } from 'antd';

import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  ReactNode,
  ChangeEventHandler,
  ChangeEvent,
} from 'react';
import InputForm from 'components/InputForm/InputForm';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import LabelWithTooltip from 'components/LabelWithTooltip/LabelWithTooltip';
import { TaxAdvantagedAccountFormData } from 'modules/plans/taxAdvantaged/components/AddTaxAdvantagedPlan/AddTaxAdvantagedPlan';
import { TaxAdvantageFields } from 'modules/plans/constants';

import styles from './commuterPlanInformation.module.less';

const { TextArea } = Input;

type CommuterPlanInformationProps = {
  isCancelButtonClickConfirm: boolean;
  setFormData: Function;
  formData: any;
  otherEmployerContributionsTooltipContent: ReactNode;
  onNumberInputChange: ChangeEventHandler<HTMLInputElement>;
  changeInputStyle: Function;
  isEditMode?: boolean;
};

const CommuterPlanInformation = forwardRef(
  (props: CommuterPlanInformationProps, ref) => {
    const {
      isCancelButtonClickConfirm,
      setFormData,
      formData,
      otherEmployerContributionsTooltipContent,
      onNumberInputChange,
      changeInputStyle,
      isEditMode = false,
    } = props;

    const [form] = Form.useForm();

    const onInputChange = async (allValues: any, value: any) => {
      form.setFieldsValue(allValues);

      setFormData({ ...formData, ...allValues });
    };

    useEffect(() => {
      if (formData && isEditMode) {
        form.setFieldsValue({
          employerMonthlyContribution: formData.employerMonthlyContribution,
        });
        form.setFieldsValue({
          maxMonthlyContribution: formData.maxMonthlyContribution,
        });
        form.setFieldsValue({ otherContribution: formData.otherContribution });
      }
    }, [formData, form, isEditMode]);

    useEffect(() => {
      if (isCancelButtonClickConfirm) {
        form.resetFields();
      }
    }, [isCancelButtonClickConfirm, form]);

    useImperativeHandle(ref, () => ({
      resetForm() {
        form.resetFields();
      },
    }));

    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      form.setFieldsValue({ [name]: value });

      setFormData((prevData: TaxAdvantagedAccountFormData) => ({
        ...prevData,
        [name]: value,
      }));
    };

    const handleStartEndSpaces = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      form.setFieldsValue({
        [name]: value.trimStart().trimEnd(),
      });

      setFormData((prevData: TaxAdvantagedAccountFormData) => ({
        ...prevData,

        [name]: value.trimStart().trimEnd(),
      }));
    };

    return (
      <>
        <InputForm
          form={form}
          onValuesChange={onInputChange}
          size={isEditMode ? 'small' : 'middle'}
        >
          <Row gutter={48}>
            <Col span={12}>
              <Form.Item
                name="employerMonthlyContribution"
                label="Employer Contribution (Monthly)"
                labelCol={{ span: 24 }}
              >
                <NumberFormatInput
                  prefix="$"
                  isBrokerScreen={true}
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  name={TaxAdvantageFields.EMPLOYER_MONTHLY_CONTRIBUTION}
                  className={
                    changeInputStyle(
                      TaxAdvantageFields.EMPLOYER_MONTHLY_CONTRIBUTION
                    )
                      ? styles.warningInput
                      : ''
                  }
                  onChange={onNumberInputChange}
                  allowNegative={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="maxMonthlyContribution"
                label="Max Monthly Contribution"
                labelCol={{ span: 24 }}
              >
                <NumberFormatInput
                  prefix="$"
                  isBrokerScreen={true}
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={48}>
            <Col span={12}>
              <Form.Item
                name="otherContribution"
                labelCol={{ span: 24 }}
                label={
                  <Row>
                    <LabelWithTooltip
                      content={otherEmployerContributionsTooltipContent}
                      mandatoryField={false}
                      fieldText={'Other Employer Contributions'}
                    />
                  </Row>
                }
              >
                <TextArea
                  showCount
                  maxLength={200}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  name="otherContribution"
                  value={formData.otherContribution}
                  onChange={handleTextAreaChange}
                  onBlur={handleStartEndSpaces}
                />
              </Form.Item>
              <div
                className={`${styles.characterCountText} ${
                  !isEditMode
                    ? styles.characterCountTextOther
                    : styles.characterCountTextOtherEdit
                }`}
              >
                Character Limit
              </div>
            </Col>
          </Row>
        </InputForm>
      </>
    );
  }
);

CommuterPlanInformation.displayName = 'CommuterPlanInformation';

export default CommuterPlanInformation;
