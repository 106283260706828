import { FC, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Col, Radio, Row, Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import FormInput from 'components/FormInput/FormInput';
import AlertMessage from 'components/Alert/AlertMessage';
import PasswordAssociation from 'model/PasswordAssociation';
import {
  clearPublishBenguideApiErrors,
  publishBenguide,
} from 'modules/employers/slices/benguideSlice';
import BenguidePublishPlanlist from 'modules/benefitGuide/components/BenguidePublish/BenguidePublishPlanlist';
import {
  changedEmployerIdState,
  previewBenguide,
} from 'modules/benefitGuide/slices/benguideSlice';
import {
  BENGUIDE_BENEFIT_CLASS_MISMATCH,
  BENGUIDE_PLAN_YEAR_MISMATCH,
} from 'modules/plans/constants';
import {
  BENEFIT_GUIDE_HIGHLIGHTED_ISSUES_WARNING_MESSAGE,
  BENEFIT_GUIDE_STATUS,
} from 'modules/benefitGuide/constants/benefitGuideConstants';
import { benguideUrl } from 'util/apiUtil';
import EmployerName from 'model/EmployerName';
import {
  removeSpecialCharacters,
  verifyEmployerNameInput,
} from 'util/benguideUtil';

import styles from './benguidePublish.module.less';

type BenguidePublishProps = {
  planYear: string;
  onCancel: () => void;
  isPublishedBenguide?: boolean;
  publishRevision?: number | undefined;
};

export const PasswordRadioTypes = {
  YES: 'yes',
  NO: 'no',
};

const BenguidePublish: FC<BenguidePublishProps> = (props) => {
  const { planYear, onCancel, isPublishedBenguide, publishRevision } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { benefitGuidePlanList, inProgress } = useAppSelector(
    (state) => state.employer.benguide
  );

  const error = useAppSelector((state) => state.employer.benguide);
  const location = useLocation();
  const [passwordRadio, setPasswordRadio] = useState<string>(
    PasswordRadioTypes.NO
  );
  const [password, setPassword] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [isAlertVisible, setAlertVisibility] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [employerNameInput, setEmployerNameInput] = useState<string>('');
  const [employerNameLength, setEmployerNameLength] = useState<number>(0);
  const [characterLimitError, setCharacterLimitError] =
    useState<boolean>(false);
  const [employerNameError, setEmployerNameError] = useState<boolean>(false);

  useEffect(() => {
    if (
      error?.benguidePublishError?.data?.code ===
      BENGUIDE_BENEFIT_CLASS_MISMATCH
    ) {
      const objectArray = Object.entries(
        error?.benguidePublishError?.data?.details
      );
      // remove object with object key = msg as it affects final result
      const objectArrayResult = objectArray.filter(
        (Object) => Object[0].toLowerCase() !== 'msg'
      );
      setAlertMessage(
        'The benefit classes for the following ' +
          formatBenefitType(
            Object.keys(Object.fromEntries(objectArrayResult))
          ) +
          ' plans do not match the benefit classes for this guide: ' +
          Object.values(Object.fromEntries(objectArrayResult))
      );
      setAlertVisibility(true);
    }

    if (
      error?.benguidePublishError?.data?.code === BENGUIDE_PLAN_YEAR_MISMATCH
    ) {
      const objectArray = Object.entries(
        error?.benguidePublishError?.data?.details
      );
      // remove object with object key = msg as it affects final result
      const objectArrayResult = objectArray.filter(
        (Object) => Object[0].toLowerCase() !== 'msg'
      );

      setAlertMessage(
        'Benefit guide`s plan year not match with the plan year of ' +
          formatBenefitType(
            Object.keys(Object.fromEntries(objectArrayResult))
          ) +
          ' plans ' +
          Object.values(Object.fromEntries(objectArrayResult))
      );
      setAlertVisibility(true);
    }
    dispatch(clearPublishBenguideApiErrors());
  }, [dispatch, error, benefitGuidePlanList]);

  useEffect(() => {
    setEmployerNameInput(benefitGuidePlanList.employerName);
    setEmployerNameLength(
      benefitGuidePlanList.employerName
        ? benefitGuidePlanList.employerName.length
        : 0
    );
  }, [benefitGuidePlanList.employerName]);

  const formatBenefitType = (benefits: string[]) => {
    const names: string[] = [];
    benefits.forEach((benefit) => {
      if (benefit === 'LIFE') {
        names.push('Life & Disability');
      } else if (benefit === 'ADDITIONAL_PERK') {
        names.push('Additional Perks');
      } else if (benefit === 'RETIREMENT') {
        names.push('401K');
      } else if (benefit === 'VOLUNTARY_BENEFIT') {
        names.push('Voluntary benefits');
      } else if (benefit === 'SAVING') {
        names.push('Tax Advantaged Accts');
      } else if (benefit === 'FAMILY_AND_LEAVE') {
        names.push('Family & Leave');
      } else if (benefit === 'HOLIDAYS_AND_TIME_OFF') {
        names.push('Holidays & Time Off');
      } else {
        names.push(
          benefit.charAt(0).toUpperCase() + benefit.slice(1).toLowerCase()
        );
      }
    });
    return names;
  };

  const onPublish = () => {
    // ToDo Add validation to check whether plans are completed
    if (passwordRadio === PasswordRadioTypes.YES && isEmpty(password)) {
      setPasswordError(true);
      setAlertVisibility(true);
      setAlertMessage('Please correct all highlighed issues and try again!');
    } else {
      const benguidePassword: PasswordAssociation = {
        enabled: false,
        password: '',
      };
      benguidePassword.enabled = passwordRadio === PasswordRadioTypes.YES;
      benguidePassword.password = password;

      if (verifyEmployerNameInput(employerNameInput, employerNameLength)) {
        setEmployerNameError(true);
        setAlertVisibility(true);
        setAlertMessage(BENEFIT_GUIDE_HIGHLIGHTED_ISSUES_WARNING_MESSAGE);
        return;
      }

      const employerName: EmployerName = {
        employerName: employerNameInput,
      };

      dispatch(
        publishBenguide(
          benefitGuidePlanList.masterId,
          benguidePassword,
          () => {
            cancelModal();
          },
          isPublishedBenguide,
          publishRevision,
          employerName
        )
      );
    }
  };

  const clearAlert = () => {
    setAlertVisibility(false);
    setAlertMessage('');
    dispatch(clearPublishBenguideApiErrors());
  };

  const savePassword = (value: string) => {
    clearAlert();
    setPasswordError(false);
    setPassword(value);
  };

  const savePasswordRadio = (value: string) => {
    setPasswordError(false);
    setPasswordRadio(value);
  };

  const cancelModal = () => {
    setPasswordRadio(PasswordRadioTypes.NO);
    setPassword('');
    onCancel();
    dispatch(changedEmployerIdState(''));
    navigate(
      !location.pathname.includes('benefit-guides')
        ? `${location.pathname}/benefit-guides`
        : location.pathname,
      {
        state: {
          dbgStatus: BENEFIT_GUIDE_STATUS.PUBLISHED,
          planYearId: benefitGuidePlanList.planYearId,
        },
      }
    );
  };
  const onPreview = () => {
    dispatch(previewBenguide(benefitGuidePlanList.masterId, publishRevision));
  };

  const publishBasicDetails = (detaliDescription: string, detailValue: any) => {
    return (
      <Row className={styles.benguidePlanYear}>
        <span className={`text-tab-header ${styles.basicInfoDetails}`}>
          {detaliDescription}
        </span>
        <span>{detailValue}</span>
      </Row>
    );
  };

  const saveEmployerName = (value: string) => {
    clearAlert();
    if (value.length > 40) {
      setCharacterLimitError(true);
      setEmployerNameInput(removeSpecialCharacters(value).slice(0, 40));
      setEmployerNameLength(removeSpecialCharacters(value).slice(0, 40).length);
    } else {
      setCharacterLimitError(false);
      setEmployerNameInput(removeSpecialCharacters(value));
      setEmployerNameLength(removeSpecialCharacters(value).length);
    }
  };

  const publishQuestion =
    'Do you want to require employees to enter a password to access this guide?';
  const previewDescription =
    'Previewing this guide allows you to see what employees are going to see once the guide is published. Make sure you preview the guide before publishing.';
  const shareableLinkDesciption =
    'You can share and customize the benefits guide URL';
  const getSectionDetails = (description: string) => {
    return <p className={styles.benguidePlanYear}>{description}</p>;
  };

  return (
    <div className={styles.benguidePublishWrapper}>
      {inProgress ? (
        <div className={styles.loaderContainer}>
          <Spin />
        </div>
      ) : (
        <>
          <h1 className={styles.publishHeader}>Publish Benefits Guide</h1>
          <p className={styles.publishDescription}>
            Publishing this benefits guide will create a URL that can be shared
            with employees. Republishing the guide will maintain the same URL.
          </p>
          {isAlertVisible && (
            <AlertMessage
              type="error"
              message={alertMessage}
              closeAlert={() => clearAlert()}
            />
          )}
          <div className={styles.basicInfo}>
            <span className="text-tab-header">1. Review Basic Info</span>
            <Row className={styles.benguideBasicInfo}>
              <span className={`text-tab-header ${styles.basicInfoDetails}`}>
                Guide Name:
              </span>
              <span>{benefitGuidePlanList && benefitGuidePlanList.name}</span>
            </Row>
            {publishBasicDetails('For PlanYear:', planYear)}
            {publishBasicDetails(
              'For BenefitClasses:',
              benefitGuidePlanList &&
                benefitGuidePlanList.benefitClasses &&
                benefitGuidePlanList.benefitClasses.join(', ')
            )}
          </div>
          <BenguidePublishPlanlist
            benefitGuidePlanList={benefitGuidePlanList}
          />
          <div className={styles.reviewContent}>
            <span className="text-tab-header">3. Preview the Guide</span>
            {getSectionDetails(previewDescription)}
            <PageActionButton
              type="primary"
              className={`${styles.publishButton} ${styles.publishPreview}`}
              onClick={() => onPreview()}
            >
              Preview Guide
            </PageActionButton>
          </div>
          <div className={styles.passwordProtect}>
            <span
              className={`text-tab-header ${
                passwordError ? styles.errorBenefit : ''
              }`}
            >
              4. Password protect this guide
            </span>
            {getSectionDetails(publishQuestion)}
            <Row>
              <Radio.Group
                name="radiogroup"
                className={styles.publishSave}
                onChange={(val) => savePasswordRadio(val.target.value)}
                value={passwordRadio}
              >
                <Radio value={PasswordRadioTypes.NO}>No</Radio>
                <Radio value={PasswordRadioTypes.YES}>Yes</Radio>
              </Radio.Group>
              <FormInput
                disabled={passwordRadio === PasswordRadioTypes.NO}
                type="text"
                placeholder="Enter Password"
                className={`${styles.benguidePassword} ${
                  passwordError ? styles.passwordBorder : ''
                }`}
                onChange={(val) => savePassword(val.target.value)}
                bordered={true}
                value={password}
              />
              {passwordError && (
                <span
                  className={`${styles.errorBenefit} ${styles.passwordError}`}
                >
                  Password is Required
                </span>
              )}
            </Row>
          </div>
          <div className={styles.shareableLink}>
            <span className="text-tab-header">5. Shareable Link</span>
            {getSectionDetails(shareableLinkDesciption)}
            <Row>
              <FormInput
                disabled={true}
                type="text"
                value={`${benguideUrl}/${benefitGuidePlanList.urlHash}`}
                className={`${styles.benefitUrlDefault}`}
                bordered={true}
              />
              <Col>
                <FormInput
                  type="text"
                  value={employerNameInput}
                  className={`${styles.benefitUrlEditable} ${
                    employerNameError || characterLimitError
                      ? styles.benefitUrlError
                      : ''
                  }`}
                  onChange={(val) => saveEmployerName(val.target.value)}
                />
                <span
                  className={`${styles.characterLimit} ${
                    characterLimitError ? styles.characterLimitError : ''
                  }`}
                >
                  Character Limit: {employerNameLength}/40
                </span>
              </Col>
            </Row>
          </div>
          <div className={styles.publishActions}>
            <Row>
              <Col>
                <PageActionButton
                  type="primary"
                  className={`${styles.publishButton} ${styles.publishCancel}`}
                  onClick={onCancel}
                >
                  Cancel
                </PageActionButton>
              </Col>
              <Col>
                <PageActionButton
                  type="primary"
                  className={`${styles.publishButton} ${styles.publishSave}`}
                  onClick={onPublish}
                >
                  Publish Benefits Guide
                </PageActionButton>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default BenguidePublish;
