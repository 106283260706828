import axios from 'axios';
import { LifePlan } from 'model/plans/LifePlan';
import { baseApi, getPagingQueryString } from 'util/apiUtil';

const lifePlanUrl = baseApi + '/v1/plans/insurances';

export const createLifePlan = (payload: LifePlan) => {
  return axios.post(`${lifePlanUrl}`, payload);
};

export const updateLifePlan = (payload: LifePlan) => {
  return axios.put(`${lifePlanUrl}/${payload.id}`, payload);
};

export const getLifePlansList = async (
  page: number,
  size: number,
  sort: string,
  query: string,
  employerId: string,
  planYearId: string
) => {
  return axios.get(
    `${lifePlanUrl}/roster?${getPagingQueryString(page, size, sort, query)}`,
    {
      params: {
        'employer-id': employerId,
        'plan-year-id': planYearId,
      },
    }
  );
};

export const getLifePlanById = (planId: string) => {
  return axios.get(`${lifePlanUrl}/${planId}`);
};
