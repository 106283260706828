import React, { useCallback, useEffect, useState } from 'react';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  Dropdown,
  Layout,
  Menu,
  Badge,
  Popover,
  Divider,
  Row,
  Col,
  Spin,
  Button,
} from 'antd';
import { cloneDeep } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useScreenSize from 'hooks/useScreenSize';
import { ReactComponent as IconArrowDown } from 'assets/images/icon-down-arrow.svg';
import { ReactComponent as IconNotifications } from 'assets/images/icon-notifications.svg';
import { ReactComponent as IconUnread } from 'assets/images/icon-unread.svg';
import { ReactComponent as NoteBook } from 'assets/images/notebook-dark.svg';
import { ReactComponent as ContactSupport } from 'assets/images/contact-support.svg';
import { ReactComponent as KnowlageBase } from 'assets/images/knowledge-base.svg';
import { ReactComponent as MoreInfo } from 'assets/images/more-info-icon.svg';
import { RouteProp } from 'routes/navigationTree';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';

import {
  loginTypes,
  navContexts,
  topLevelMenus,
} from 'constants/authConstants';
import { NotificationReadStatus } from 'modules/communication/constants/inAppNotificationConstants';
import InAppNotification from 'model/InAppNotification';
import { Offer } from 'modules/renewals/models/Offer';
import {
  getNotification,
  readNotification,
  inAppNotificationUnload,
  getNewNotificationCount,
} from 'modules/communication/slices/inAppNotificationSlice';
import { buildFullName, buildInitials, getTextEllipsis } from 'util/stringUtil';
import AppBootupInfo from 'model/AppBootupInfo';
import {
  IN_APP_NOTIFICATIONS,
  BILLING_NOTIFICATION_TYPES,
  MOBILE_DEMO_VISIBLE_BROKERS,
  IN_APP_NOTIFICATION_ACTIONS,
  KNOWLEDGE_BASE_URL,
  SUPPORT_EMAIL,
  MOBILE_DEMO_RESTRICTED_BROKER_SUBTYPES,
} from 'constants/commonConstants';
import { brokerChatBotEnabled } from 'util/apiUtil';
import { NEW_CHAT_NAV_CONSTANT } from 'modules/assistant/constants/constants';
import { useLazyGetNavigationsQuery } from 'api/navigationSlice';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { mailToSupport, navigateToKnowledgeBase } from 'util/commonUtil';
import UserActivityPopUp from 'components/UserActivityPopUp/UserActivityPopUp';
import { featuresKeys } from 'util/featureKeysUtil';
import MobileDemo from './components/MobileDemo/MobileDemo';
import styles from './layout.module.less';

const { Header } = Layout;

type AppHeaderProps = {
  brokerId?: string | null;
  menuItems: RouteProp[];
  defaultSelectedKeys: string[];
  logout: Function;
  logo: any;
  onClickMenuItem: Function;
  appBootupInfo: AppBootupInfo | null;
  employerId: string | null;
};

const AppHeaderNav = (props: AppHeaderProps) => {
  const {
    brokerId,
    menuItems,
    defaultSelectedKeys,
    logout,
    logo,
    onClickMenuItem,
    appBootupInfo,
    employerId,
  } = props;

  const params = useParams();
  const brokerAdminUser = appBootupInfo?.type == loginTypes.bokerAdmin;
  const [newNotificationCount, setNewNotificationCount] = useState(0);
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [notificationsCopy, setNotificationsCopy] = useState<
    InAppNotification[]
  >([]);
  const [notificationsOpenedOnce, setNotificationsOpenedOnce] = useState(false);
  const [isHelpModalVisible, setHelpModalView] = useState(false);
  const [colSpanCount, setColSpanCount] = useState<{
    menu: number;
    profile: number;
  }>({ menu: 17, profile: 7 });

  const dispatch = useAppDispatch();
  const notifications: Array<InAppNotification> = useAppSelector(
    (state) => state.inAppNotifications.notifications
  );
  const fetchSuccess: boolean = useAppSelector(
    (state) => state.inAppNotifications.fetchSuccess
  );
  const unreadNotificationCount: number = useAppSelector(
    (state) => state.inAppNotifications.unreadNotificationCount
  );
  const fetchUnreadSuccess: boolean = useAppSelector(
    (state) => state.inAppNotifications.fetchUnreadSuccess
  );

  const navigate = useNavigate();
  const screenSize = useScreenSize();

  const [getNavigations, { data: navigationFeature, isLoading }] =
    useLazyGetNavigationsQuery();

  useEffect(() => {
    if (brokerId) {
      getNavigations({
        brokerId: brokerId,
        employerId: null,
      });
    }
  }, [brokerId, employerId, getNavigations]);

  const { features = [] } = navigationFeature || {};
  const isErAdmin = appBootupInfo?.type == loginTypes.erAdmin;

  const availableFeaturesKeys = featuresKeys(features, isErAdmin);

  const overlay = (
    <Menu>
      <Menu.Item key="view" onClick={() => logout()}>
        Log Out
      </Menu.Item>
    </Menu>
  );

  const readNotifications = useCallback(() => {
    notificationsCopy.forEach(function (notification: InAppNotification) {
      if (notification.status === NotificationReadStatus.UNREAD) {
        dispatch(readNotification(notification.id));
      }
    });
  }, [dispatch, notificationsCopy]);

  const updateNotificationStatusLocally = useCallback(() => {
    notificationsCopy.forEach(function (notification: InAppNotification) {
      if (notification.status === NotificationReadStatus.UNREAD) {
        notification.status = NotificationReadStatus.READ;
      }
    });
    setNewNotificationCount(0);
  }, [notificationsCopy]);

  const goToDBG = (dbg: any, employerId: string) => {
    toggleNotifications();
    navigate(`/brokers/${brokerId}/employers/${employerId}/benefit-guides`, {
      state: {
        dbgStatus: dbg?.status,
      },
    });
  };

  const navigateToClaims = (employerId: string) => {
    toggleNotifications();
    navigate(`/brokers/${brokerId}/employers/${employerId}/claims`);
  };

  const navigateToBilling = (
    employerId: string,
    month: string,
    year: number
  ) => {
    toggleNotifications();
    navigate(
      `/brokers/${brokerId}/employers/${employerId}/billing?month=${month}&year=${year}`
    );
  };

  const navigateToProposal = (employerId: string, proposalId: string) => {
    toggleNotifications();
    navigate(
      `/brokers/${brokerId}/employers/${employerId}/renewals/proposals/details/${proposalId}`
    );
  };

  const navigateToOffers = (employerId: string) => {
    toggleNotifications();
    navigate(
      `brokers/${brokerId}/employers/${employerId}/renewals/carrier/summary`
    );
  };

  const navigateToOfferById = (
    employerId: string,
    offerId: string,
    artifact: Offer
  ) => {
    const benefitCategory = artifact?.benefitCategory?.toLowerCase();
    toggleNotifications();
    navigate(
      `brokers/${brokerId}/employers/${employerId}/renewals/carrier/${benefitCategory}/${offerId}/detail`
    );
  };

  const navigateToOfferByIdFinalized = (
    employerId: string,
    offerId: string,
    artifact: Offer
  ) => {
    const benefitCategory = artifact?.benefitCategory?.toLowerCase();
    toggleNotifications();
    navigate(
      `brokers/${brokerId}/employers/${employerId}/renewals/carrier/${benefitCategory}/${offerId}/detail?job-id="true"`
    );
  };

  const getNotificationContent = (notification: InAppNotification) => {
    switch (notification.source) {
      case IN_APP_NOTIFICATIONS.DBG:
        return (
          <>
            <b>
              {notification.recipientAdminType === loginTypes.bokerAdmin
                ? `${notification.artifactEmployer} - `
                : ''}
            </b>
            <span>
              {notification.recipientAdminType === loginTypes.bokerAdmin
                ? `${notification.actorName}`
                : ''}
            </span>
            <div>
              {`${
                notification.recipientAdminType === loginTypes.erAdmin
                  ? notification.actorName
                  : ''
              } (${notification.actorEmail}) ${notification.actionText} the `}
              {getNotificationLinkContent(notification)}
            </div>
          </>
        );
      case IN_APP_NOTIFICATIONS.CLAIMS:
        return (
          <>
            <b>
              {notification.recipientAdminType === loginTypes.bokerAdmin
                ? `${notification.artifactEmployer} - `
                : ''}
            </b>
            <span>{`${notification.actionText} `}</span>
            {getNotificationLinkContent(notification)}
          </>
        );
      case IN_APP_NOTIFICATIONS.BILLING:
        return (
          <>
            <b>
              {notification.recipientAdminType === loginTypes.bokerAdmin
                ? `${notification.artifactEmployer} - `
                : ''}
            </b>
            {getBillingNotificationActionContent(notification)}
            {getNotificationLinkContent(notification)}
          </>
        );
      case IN_APP_NOTIFICATIONS.PROPOSALS:
        return (
          <>
            <b>
              {notification.recipientAdminType === loginTypes.bokerAdmin
                ? `${notification.artifactEmployer} - `
                : ''}
            </b>
            <span>{`${notification.actionText} `}</span>
            {getNotificationLinkContent(notification)}
          </>
        );
      case IN_APP_NOTIFICATIONS.OFFERS:
        return (
          <>
            <b>
              {notification.recipientAdminType === loginTypes.bokerAdmin
                ? `${notification.artifactEmployer} - `
                : ''}
            </b>
            <span>{`${notification.actionText} `}</span>
            {getNotificationLinkContent(notification)}
          </>
        );
      default:
        return <></>;
    }
  };

  const getBillingNotificationActionContent = (
    notification: InAppNotification
  ) => {
    switch (notification.actionText) {
      case BILLING_NOTIFICATION_TYPES.NEW:
        return (
          <span>{`A new bill for ${notification.month} ${notification.year} has been published. `}</span>
        );
      case BILLING_NOTIFICATION_TYPES.UPDATED:
        return (
          <span>{`The ${notification.month} ${notification.year} bill has been updated. `}</span>
        );
      default:
        return '';
    }
  };

  const getOfferNotificationLinkContent = (notification: InAppNotification) => {
    switch (notification.actionType) {
      case IN_APP_NOTIFICATION_ACTIONS.OFFER_BULK_PUBLISHED:
        return (
          <a
            className={styles.notificationArtifactName}
            onClick={() => navigateToOffers(notification.artifactEmployerId)}
          >
            View Offers
          </a>
        );

      case IN_APP_NOTIFICATION_ACTIONS.OFFER_EXISTING_PUBLISHED:
      case IN_APP_NOTIFICATION_ACTIONS.OFFER_CREATE_PUBLISHED:
        return (
          <a
            className={styles.notificationArtifactName}
            onClick={() =>
              navigateToOfferById(
                notification?.artifactEmployerId,
                notification?.artifactId,
                notification?.artifact
              )
            }
          >
            View Offer
          </a>
        );
      case IN_APP_NOTIFICATION_ACTIONS.OFFER_QUOTE_FINALIZED:
        return (
          <a
            className={styles.notificationArtifactName}
            onClick={() =>
              navigateToOfferByIdFinalized(
                notification.artifactEmployerId,
                notification.artifactId,
                notification?.artifact
              )
            }
          >
            View Offer
          </a>
        );
      default:
        return '';
    }
  };

  const getNotificationLinkContent = (notification: InAppNotification) => {
    switch (notification.source) {
      case IN_APP_NOTIFICATIONS.DBG:
        return (
          <a
            className={styles.notificationArtifactName}
            onClick={() =>
              goToDBG(notification.artifact, notification.artifactEmployerId)
            }
          >
            {notification.artifactName}.
          </a>
        );

      case IN_APP_NOTIFICATIONS.CLAIMS:
        return (
          <a
            className={styles.notificationArtifactName}
            onClick={() => navigateToClaims(notification.artifactEmployerId)}
          >
            {notification.artifactName}
          </a>
        );
      case IN_APP_NOTIFICATIONS.BILLING:
        return (
          <a
            className={styles.notificationArtifactName}
            onClick={() =>
              navigateToBilling(
                notification.artifactEmployerId,
                notification.month,
                notification.year
              )
            }
          >
            {notification.artifactName}
          </a>
        );
      case IN_APP_NOTIFICATIONS.PROPOSALS:
        return (
          <a
            className={styles.notificationArtifactName}
            onClick={() =>
              navigateToProposal(
                notification.artifactEmployerId,
                notification.artifactId
              )
            }
          >
            View Proposal
          </a>
        );
      case IN_APP_NOTIFICATIONS.OFFERS:
        return getOfferNotificationLinkContent(notification);
      default:
        return '';
    }
  };

  const toggleNotifications = () => {
    setNotificationsVisible(!notificationsVisible);
    setNotificationsOpenedOnce(true);
  };

  const readStatus = (notification: InAppNotification) => {
    if (notification.status === NotificationReadStatus.UNREAD) {
      return <IconUnread />;
    }
  };

  const notificationsTitle = () => {
    if (!fetchSuccess) {
      return (
        <div className={styles.notificationsTitle}>
          <b>Notifications</b>
        </div>
      );
    } else if (newNotificationCount > 0) {
      return (
        <div className={styles.notificationsTitle}>
          <b>Notifications</b> ({newNotificationCount} New)
        </div>
      );
    } else {
      return (
        <b className={styles.notificationsTitle}>
          Notifications {notifications.length === 0 ? '(0)' : ''}
        </b>
      );
    }
  };

  const getTimestamp = (createdTs: string) => {
    const elapsedSeconds = Math.round(
      (new Date().getTime() - new Date(createdTs).getTime()) / 1000
    );
    if (elapsedSeconds < 60) {
      return `${elapsedSeconds} seconds ago`;
    }
    const elapsedMinutes = Math.round(elapsedSeconds / 60);
    if (elapsedMinutes < 60) {
      return `${elapsedMinutes} minutes ago`;
    }
    const elapsedHours = Math.round(elapsedMinutes / 60);
    if (elapsedHours < 24) {
      return `${elapsedHours} hours ago`;
    }
    const elapsedDays = Math.round(elapsedHours / 24);
    if (elapsedDays < 7) {
      return `${elapsedDays} days ago`;
    }
    const elapsedWeeks = Math.round(elapsedDays / 7);
    if (elapsedWeeks < 30) {
      return `${elapsedWeeks} weeks ago`;
    }

    return null;
  };

  const notificationOverlay = (
    <div className={styles.notificationOverlay}>
      {fetchSuccess ? (
        <>
          {notifications.length === 0 ? (
            <Row>
              <div className={styles.emptyNotifications}>
                <NoteBook />
                <br />
                You have no notifications yet.
                <br />
                Check back later!
              </div>
            </Row>
          ) : (
            <>
              {notificationsCopy?.map(
                (notification: InAppNotification, index) => (
                  <Row key={notification.id}>
                    <Col span={23}>
                      {getNotificationContent(notification)}
                      <p className={styles.notificationTimeStamp}>
                        {getTimestamp(notification.timestamp)}
                      </p>
                    </Col>
                    <Col>
                      <br></br>
                      {readStatus(notification)}
                    </Col>
                    {index + 1 < notifications.length ? (
                      <Divider className={styles.notificationDivider} />
                    ) : (
                      ''
                    )}
                  </Row>
                )
              )}
            </>
          )}
        </>
      ) : (
        <Spin />
      )}
    </div>
  );

  useEffect(() => {
    if (appBootupInfo?.individualId) {
      dispatch(getNotification(appBootupInfo?.individualId));
      dispatch(getNewNotificationCount(appBootupInfo?.individualId));
    }
  }, [dispatch, appBootupInfo]);

  useEffect(() => {
    if (fetchSuccess && notifications) {
      setNotificationsCopy(cloneDeep(notifications));
    }
    if (fetchUnreadSuccess) {
      setNewNotificationCount(unreadNotificationCount);
    }
  }, [
    fetchSuccess,
    notifications,
    fetchUnreadSuccess,
    unreadNotificationCount,
  ]);

  useEffect(() => {
    if (notificationsOpenedOnce) {
      if (notificationsVisible) {
        readNotifications();
      } else {
        updateNotificationStatusLocally();
      }
    }
  }, [
    notificationsOpenedOnce,
    notificationsVisible,
    readNotifications,
    updateNotificationStatusLocally,
  ]);

  const isTechAdminOrMobileDemoBrokerAdmin =
    appBootupInfo?.type === loginTypes.platform ||
    (MOBILE_DEMO_VISIBLE_BROKERS.includes(params.brokerId ?? '') &&
      appBootupInfo?.type === loginTypes.bokerAdmin &&
      !MOBILE_DEMO_RESTRICTED_BROKER_SUBTYPES.includes(
        appBootupInfo?.individualSubType
      ) &&
      !appBootupInfo?.isOpsAdmin);

  const isBrokerAdmin = appBootupInfo?.type == loginTypes.bokerAdmin;

  useEffect(() => {
    if (screenSize.width < 1320) {
      setColSpanCount({ menu: 15, profile: 9 });
    } else {
      setColSpanCount({
        menu: isTechAdminOrMobileDemoBrokerAdmin ? 16 : 17,
        profile: isTechAdminOrMobileDemoBrokerAdmin ? 8 : 7,
      });
    }
  }, [screenSize.width, isTechAdminOrMobileDemoBrokerAdmin]);

  useEffect(() => {
    return () => {
      dispatch(inAppNotificationUnload());
    };
  }, [dispatch]);

  return (
    <Header className={styles.appHeader}>
      <div className={styles.headerLogo}>{logo}</div>
      <Row>
        <Col xs={colSpanCount.menu}>
          <Menu
            theme="light"
            mode="horizontal"
            selectedKeys={defaultSelectedKeys}
            onClick={(item: any) => onClickMenuItem(item)}
          >
            {isLoading ? (
              <></>
            ) : (
              menuItems.map((item) => {
                let linkTo = item.path;
                if (brokerId && employerId) {
                  linkTo = generatePath(item.path, {
                    brokerId: brokerId,
                    employerId: employerId,
                    chatId: NEW_CHAT_NAV_CONSTANT,
                  });
                } else if (brokerId && !employerId) {
                  linkTo = generatePath(item.path, {
                    brokerId: brokerId,
                    chatId: NEW_CHAT_NAV_CONSTANT,
                  });
                }

                // checks if there are enabled features and hide configuration tabs if necessary
                // ** plan rates is disabled for tech admin and broker admin view by default
                if (
                  brokerAdminUser &&
                  availableFeaturesKeys.length === 0 &&
                  item.name === topLevelMenus.configuration
                ) {
                  return <></>;
                }

                if (item.validator) {
                  if (availableFeaturesKeys.includes(item.validator)) {
                    return (
                      <Menu.Item key={item.path}>
                        <Link to={linkTo}>{item.displayName}</Link>
                      </Menu.Item>
                    );
                  } else {
                    return <></>;
                  }
                }
                return (
                  <Menu.Item key={item.path}>
                    <Link to={linkTo}>{item.displayName}</Link>
                  </Menu.Item>
                );
              })
            )}
          </Menu>
        </Col>
        <Col xs={colSpanCount.profile}>
          <div id="loggedInHeaderTab">
            <div
              className={
                appBootupInfo?.type === loginTypes.bokerAdmin
                  ? styles.navBarIconsWrapper
                  : styles.techAdminNavbarView
              }
            >
              {appBootupInfo && isTechAdminOrMobileDemoBrokerAdmin ? (
                <div className={styles.mobileDemoWrapper}>
                  <MobileDemo />
                </div>
              ) : (
                ''
              )}
              {appBootupInfo &&
                !(isBrokerAdmin && brokerChatBotEnabled === 'true') && (
                  <div className={styles.moreInfoWrapper}>
                    <MoreInfo
                      className={styles.dashboardIcon}
                      onClick={() =>
                        setHelpModalView(() => !isHelpModalVisible)
                      }
                    />
                    <ConfirmationDialog
                      isCloseOnly={true}
                      width={464}
                      confirmText="Close"
                      title="Need Help?"
                      onConfirm={() =>
                        setHelpModalView(() => !isHelpModalVisible)
                      }
                      visible={isHelpModalVisible}
                      centered={true}
                      confirmBtnFullWidth={true}
                    >
                      <div className={styles.helpboxWrapper}>
                        <div className={styles.supportModalText}>
                          Use our Knowledge Base for general questions or
                          contact our support team where someone will respond
                          shortly.
                        </div>
                        <div className={styles.helpWrapper}>
                          <div
                            className={styles.iconBox}
                            onClick={() => mailToSupport(SUPPORT_EMAIL)}
                          >
                            <ContactSupport />
                            <span> Contact Support</span>
                          </div>

                          <div
                            className={styles.iconBox}
                            onClick={() =>
                              navigateToKnowledgeBase(KNOWLEDGE_BASE_URL)
                            }
                          >
                            <KnowlageBase />
                            <span> View Knowledge Base</span>
                          </div>
                        </div>
                      </div>
                    </ConfirmationDialog>
                  </div>
                )}
              {appBootupInfo && appBootupInfo.type !== navContexts.platform ? (
                <div>
                  <Popover
                    overlayClassName="notificationInner"
                    onVisibleChange={toggleNotifications}
                    placement="bottomRight"
                    title={notificationsTitle()}
                    content={notificationOverlay}
                    trigger="click"
                    visible={notificationsVisible}
                    destroyTooltipOnHide={true}
                    className={styles.notificationContainer}
                  >
                    <Button className={styles.notificationButton} type="link">
                      <Badge
                        color="#317ad0"
                        count={newNotificationCount}
                        overflowCount={9}
                      >
                        <IconNotifications className={styles.dashboardIcon} />
                      </Badge>
                    </Button>
                  </Popover>
                </div>
              ) : (
                ''
              )}

              {appBootupInfo ? (
                <Col className={styles.profileSectionWrapper}>
                  <Dropdown
                    overlay={overlay}
                    trigger={['click']}
                    overlayClassName="headerDropdownMenu"
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <ProfileAvatar
                        src={appBootupInfo.avatarUrl}
                        content={buildInitials(
                          appBootupInfo.firstName,
                          appBootupInfo.lastName
                        )}
                      />
                      {getTextEllipsis(
                        buildFullName(
                          appBootupInfo.firstName,
                          appBootupInfo.lastName
                        ),
                        22
                      )}
                      <IconArrowDown className={styles.downIcon} />
                    </a>
                  </Dropdown>
                </Col>
              ) : (
                ''
              )}
            </div>
          </div>
        </Col>
      </Row>
      <UserActivityPopUp />
    </Header>
  );
};

export default AppHeaderNav;
