import { FC, useEffect, useState, useCallback } from 'react';

import { StompSessionProvider } from 'react-stomp-hooks';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, PageHeader, Row } from 'antd';

import { usePermitIf } from 'hooks/usePermitIf';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import HRAHealthCareFSALimitedFSAPlanOverview from 'modules/plans/taxAdvantaged/components/HRAHealthCareFSALimitedFSAPlanOverview/HRAHealthCareFSALimitedFSAPlanOverview';
import DependentCareFSAPlanOverview from 'modules/plans/taxAdvantaged/components/DependentCareFSAPlanOverview/DependentCareFSAPlanOverview';
import HSAPlanOverview from 'modules/plans/taxAdvantaged/components/HSAPlanOverview/HSAPlanOverview';
import CommuterPlanOverview from 'modules/plans/taxAdvantaged/components/CommuterPlanOverview/CommuterPlanOverview';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import { allowedCommonButtonPermittedTypes } from 'constants/permittedConstants';
import { TaxAdvantagedAccountPlanType } from 'modules/plans/constants';
import BasicPlanInfoOverview from 'modules/plans/components/OverviewPage/BasicPlanInfoOverview/BasicPlanInfoOverview';
import { findPlanById } from 'modules/plans/slices/taxAdvantagedAccountPlanSlice';

import { wsBaseApi } from 'util/apiUtil';
import PlanCollaborationNotification from 'components/PlanCollaborationNotification/PlanCollaborationNotification';

import styles from './taxAdvantagedPlanOverview.module.less';

const TaxAdvantagedPlanOverview: FC = () => {
  const params = useParams();
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();

  const { taxAdvantagedAccountPlan } = useAppSelector(
    (state) => state.plan.taxAdvantagedAccountPlan
  );

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const navigate = useNavigate();

  const [viewPlanModal, setViewPlanModal] = useState(false);
  const [disableEditButton, setDisableEditButton] = useState(false);
  const [fetchPlanWhenUpdated, setFetchPlanWenUpdated] =
    useState<boolean>(false);

  const refreshPlan = useCallback(() => {
    if (employerId && brokerId && params.planId) {
      dispatch(findPlanById(params.planId));
    }
    setFetchPlanWenUpdated(false);
  }, [dispatch, employerId, brokerId, params.planId]);

  useEffect(() => {
    if (employerId && brokerId && params.planId && !taxAdvantagedAccountPlan) {
      dispatch(findPlanById(params.planId));
    }
    setFetchPlanWenUpdated(false);
  }, [dispatch, employerId, brokerId, params.planId, taxAdvantagedAccountPlan]);

  const exitPlan = () => {
    navigate(
      `/brokers/${brokerId}/employers/${employerId}/tax-advantaged-accts`
    );
  };

  const updateViewPlanModal = (value: boolean) => {
    setViewPlanModal(value);
  };

  const navigation = {
    name: 'All Tax Advantaged Accounts Plans',
    link: `/brokers/${brokerId}/employers/${employerId}/tax-advantaged-accts`,
  };

  const securedBreadcrumb = usePermitIf(
    <BreadcrumbNavigation breadCrumbData={[navigation]} />,
    allowedCommonButtonPermittedTypes,
    []
  );

  const getOtherDetails = () => {
    switch (taxAdvantagedAccountPlan.benefitKind) {
      case TaxAdvantagedAccountPlanType.HRA.value:
      case TaxAdvantagedAccountPlanType.HEALTHCARE_FSA.value:
      case TaxAdvantagedAccountPlanType.LIMITED_PURPOSE_FSA.value:
        return (
          <HRAHealthCareFSALimitedFSAPlanOverview
            plan={taxAdvantagedAccountPlan}
          />
        );
      case TaxAdvantagedAccountPlanType.DEPENDENT_CARE_FSA.value:
        return <DependentCareFSAPlanOverview plan={taxAdvantagedAccountPlan} />;
      case TaxAdvantagedAccountPlanType.HSA.value:
        return <HSAPlanOverview plan={taxAdvantagedAccountPlan} />;
      case TaxAdvantagedAccountPlanType.COMMUTER.value:
        return <CommuterPlanOverview plan={taxAdvantagedAccountPlan} />;
    }
  };

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col span={24}>
          <Row>{securedBreadcrumb}</Row>
          <Col span={18}>
            <PageHeader
              className={styles.pageTitle}
              title={taxAdvantagedAccountPlan.name}
            />
          </Col>
        </Col>
      </Row>
      <div className={styles.overview}>
        <StompSessionProvider
          url={`${wsBaseApi}/websocket-plan-update`}
          onWebSocketError={(e) => console.error(e)}
          reconnectDelay={500}
        >
          <PlanCollaborationNotification
            loggedInUserIndividualId={appBootInfo?.individualId}
            exitPlan={() => exitPlan()}
            refreshPlan={() => refreshPlan()}
            planId={taxAdvantagedAccountPlan.name}
            isInEdit={viewPlanModal}
            disableEdit={() => setDisableEditButton(true)}
            fetchPlan={() => setFetchPlanWenUpdated(true)}
          />
        </StompSessionProvider>
        <div
          className={
            taxAdvantagedAccountPlan.benefitKind ===
            TaxAdvantagedAccountPlanType.DEPENDENT_CARE_FSA.value
              ? styles.dependentCareWrapper
              : styles.basicInfoWrapper
          }
        >
          <BasicPlanInfoOverview
            heading={'Plan Details'}
            planName={taxAdvantagedAccountPlan.name}
            planYearName={taxAdvantagedAccountPlan.planYear?.name}
            benefitClasses={taxAdvantagedAccountPlan.groups}
            benefitKind={taxAdvantagedAccountPlan.benefitKind}
            carrierName={taxAdvantagedAccountPlan.benefitCarrier?.name}
            type={'TAX_ADVANTAGED'}
            viewPlanModal={viewPlanModal}
            setViewPlanModal={updateViewPlanModal}
            disableEditButton={disableEditButton}
            fetchPlanWhenUpdated={fetchPlanWhenUpdated}
            exitPlan={() => exitPlan()}
            groupId={taxAdvantagedAccountPlan.groupId}
          />
        </div>
        {getOtherDetails()}
      </div>
    </div>
  );
};

export default TaxAdvantagedPlanOverview;
