import { FC } from 'react';
import FooterText from 'components/FooterText/FooterText';
import CreateBrokerInfoForm from 'modules/brokers/components/CreateBrokerInfoForm/CreateBrokerInfoForm';
import { brokerFooterText } from 'constants/commonConstants';

type BasicInfoProps = {
  nextStep: () => void;
};

const BasicInfo: FC<BasicInfoProps> = (props: BasicInfoProps) => {
  const { nextStep } = props;
  return (
    <>
      <CreateBrokerInfoForm edit={false} nextStep={nextStep} />
      <FooterText messageText={brokerFooterText} />
    </>
  );
};

export default BasicInfo;
