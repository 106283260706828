import React, { useState } from 'react';
import TabView from 'components/TabView/TabView';
import TabType from 'model/TabType';
import { FEATURE_KEYS } from 'constants/commonConstants';
import BrokerSelfReviewList from './BrokerSelfReviewList';
import BrokerQuotesSelfReviewList from './BrokerQuotesSelfReviewList';
import styles from './featureList.module.less';

const getTabDataList = () => {
  const tabs: TabType[] = [];
  tabs.push({
    tabLabel: <b>Plan Documents</b>,
    tabKey: FEATURE_KEYS.SELF_REVIEW,
    tabData: (
      <div className={styles.tabItemWrapper}>
        <BrokerSelfReviewList />
      </div>
    ),
  } as TabType);
  tabs.push({
    tabLabel: <b>Quote Reading</b>,
    tabKey: FEATURE_KEYS.QUOTE_SELF_REVIEW,
    tabData: (
      <div className={styles.tabItemWrapper}>
        <BrokerQuotesSelfReviewList />
      </div>
    ),
  } as TabType);

  return tabs;
};

const SelfReviewWrapper = () => {
  const [selectedTab, setSelectedTab] = useState<string>(
    FEATURE_KEYS.SELF_REVIEW
  );

  return (
    <div className={styles.selfReviewMainWrapper}>
      <div className={styles.headingWrapper}>
        <h1>Self-Review</h1>
      </div>
      <TabView
        defaultTabKey={selectedTab}
        className={styles.tabsWrapper}
        tabOptions={getTabDataList()}
        onClick={() => {}}
        onChange={setSelectedTab}
      />
    </div>
  );
};

export default SelfReviewWrapper;
