// @flow
import React from 'react';
import * as _ from 'lodash';

import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import styles from './legendLabel.module.less';

type PrefixShape = {
  circle: boolean;
  color: string;
  width: string;
  height: string;
};

type Props = {
  className?: any;
  title: string;
  prefixShape: PrefixShape;
  showTitleTooltip?: boolean;
};

const LegendLabel = (props: Props) => {
  const {
    prefixShape,
    title,
    className = { wrapperClass: '', headerClass: '' },
    showTitleTooltip = false,
  } = props;
  const { circle, color, width, height } = prefixShape;

  return (
    <div className={`${styles.legendLabel} ${className.wrapperClass}`}>
      {color && (
        <div
          className={circle ? styles.circle : styles.square}
          style={
            prefixShape && {
              position: 'relative',
              backgroundColor: color,
              width: width,
              height: height,
            }
          }
        />
      )}
      {showTitleTooltip ? (
        <div className={styles.titleContainer}>
          <OverflowPopover popoverContent={title}>
            <span
              className={`${styles.headerTitles} ${className.headerClass} ${
                _.isEmpty(color) ? styles.labelOnly : ''
              }`}
            >
              {title}
            </span>
          </OverflowPopover>
        </div>
      ) : (
        <span
          className={`${styles.headerTitle} ${className.headerClass} ${
            _.isEmpty(color) ? styles.labelOnly : ''
          }`}
        >
          {title}
        </span>
      )}
    </div>
  );
};

export default LegendLabel;
