import { FC, useMemo } from 'react';
import { useParams } from 'react-router';
import { Row, Spin } from 'antd';

import { cloneDeep } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import NextButton from 'components/buttons/NextButton/NextButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import CheckboxSelect from 'components/CheckboxSelect/CheckboxSelect';

import { SET_CURRENT_PLAN_YEAR } from 'modules/employers/constants/afpClonePlansConstants';

import {
  IPlanBGCloneInitialState,
  IResultItem,
} from 'modules/employers/types/planBGCloneTypes';
import { updatePlanList } from 'modules/employers/slices/planBGCloneSlice';
import { PLAN_CLONE_TYPE_TEXT } from 'modules/employers/utils/clonePlanUtils';
import squareArrowIcon from 'assets/images/square-arrow-icon.svg';

import styles from './additionalBenefits.module.less';

type AdditionalBenefitsProps = {
  setCurrentStep: (step: number) => void;
};

const AdditionalBenefits: FC<AdditionalBenefitsProps> = (
  props: AdditionalBenefitsProps
) => {
  const { setCurrentStep } = props;
  const { brokerId, employerId } = useParams();
  const dispatch = useAppDispatch();
  const clonePlanBGState: IPlanBGCloneInitialState = useAppSelector(
    (state) => state.employer.planBGClone
  );
  const {
    clonePlanBGListLoading,
    clonePlanBGList: { plans },
  } = clonePlanBGState;

  const handleCurrentBenefitClick = (
    empId?: string,
    brokerId?: string,
    currentPlanSelected?: string
  ) => {
    const queryParams = new URLSearchParams();

    if (currentPlanSelected) {
      queryParams.append(
        SET_CURRENT_PLAN_YEAR.URL_PRAM_NAME,
        currentPlanSelected
      );
    }

    const queryString = queryParams.toString();
    const url = `/brokers/${brokerId}/employers/${empId}/medical${
      queryString ? `?${queryString}` : ''
    }`;

    window.open(url, '_blank');
  };

  const isEmpty = useMemo(() => {
    return Object.values(plans).every((item) => item?.plan?.length === 0);
  }, [plans]);

  return (
    <>
      <div className={styles.benefitContainer}>
        {clonePlanBGListLoading ? (
          <div className={styles.loadingWrapper}>
            <Spin />
          </div>
        ) : (
          <>
            <Row justify="space-between">
              <p className={styles.benefitHeader}>
                Select <strong>benefit plans</strong> to clone:
              </p>

              <LinkButton
                onClick={() =>
                  handleCurrentBenefitClick(
                    employerId,
                    brokerId,
                    SET_CURRENT_PLAN_YEAR.VALUE
                  )
                }
                icon={<img src={squareArrowIcon} />}
                customClass={styles.viewDescriptionButton}
              >
                View Current Benefits
              </LinkButton>
            </Row>
            {isEmpty ? (
              <div className={styles.emptyDataContainer}>
                <p className={styles.emptyDataText}>
                  You have no current benefits.
                </p>
              </div>
            ) : (
              Object.keys(plans).map((key: any, index: number) => {
                if (plans[key as keyof typeof plans]?.plan?.length !== 0) {
                  return (
                    <Row
                      key={index}
                      className={styles.selectionItemWrapper}
                      align="middle"
                    >
                      <CheckboxSelect
                        checked={plans[key as keyof typeof plans]?.isChecked}
                        onChange={(e) => {
                          const copiedCategory = cloneDeep(plans!);
                          copiedCategory[key as keyof typeof plans]!.isChecked =
                            e.target.checked;
                          dispatch(updatePlanList(copiedCategory));
                        }}
                      />
                      <p className={styles.label}>
                        {
                          PLAN_CLONE_TYPE_TEXT[
                            key as keyof typeof PLAN_CLONE_TYPE_TEXT
                          ]
                        }
                      </p>
                    </Row>
                  );
                }
              })
            )}
          </>
        )}
        <NextButton
          className={styles.nextButtonStyles}
          nextStep={() => {
            const hasCheckedPlans = Object.values(plans)?.some(
              (category?: IResultItem) =>
                category?.isChecked && category.plan.length > 0
            );

            if (hasCheckedPlans) {
              setCurrentStep(1);
            } else {
              setCurrentStep(2);
            }
          }}
        />
      </div>
    </>
  );
};

export default AdditionalBenefits;
