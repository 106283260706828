class RxForDeductiblesAndOopMax {
  individualDeductibleInNetworkCost: string;
  individualDeductibleOutOfNetworkCost: string;
  individualDeductibleInNetworkApplicable: string;
  individualDeductibleOutOfNetworkApplicable: string;

  familyDeductibleInNetworkCost: string;
  familyDeductibleOutOfNetworkCost: string;
  familyDeductibleInNetworkApplicable: string;
  familyDeductibleOutOfNetworkApplicable: string;

  individualWithinFamilyDeductibleInNetworkCost: string;
  individualWithinFamilyDeductibleOutOfNetworkCost: string;
  individualWithinFamilyDeductibleInNetworkApplicable: string;
  individualWithinFamilyDeductibleOutOfNetworkApplicable: string;

  individualOopMaxInNetworkCost: string;
  individualOopMaxOutOfNetworkCost: string;
  individualOopMaxInNetworkApplicable: string;
  individualOopMaxOutOfNetworkApplicable: string;

  familyOopMaxInNetworkCost: string;
  familyOopMaxOutOfNetworkCost: string;
  familyOopMaxInNetworkApplicable: string;
  familyOopMaxOutOfNetworkApplicable: string;

  individualWithinFamilyOopMaxInNetworkCost: string;
  individualWithinFamilyOopMaxOutOfNetworkCost: string;
  individualWithinFamilyOopMaxInNetworkApplicable: string;
  individualWithinFamilyOopMaxOutOfNetworkApplicable: string;

  constructor() {
    this.individualDeductibleInNetworkCost = '';
    this.individualDeductibleOutOfNetworkCost = '';
    this.individualDeductibleInNetworkApplicable = '';
    this.individualDeductibleOutOfNetworkApplicable = '';

    this.familyDeductibleInNetworkCost = '';
    this.familyDeductibleOutOfNetworkCost = '';
    this.familyDeductibleInNetworkApplicable = '';
    this.familyDeductibleOutOfNetworkApplicable = '';

    this.individualWithinFamilyDeductibleInNetworkCost = '';
    this.individualWithinFamilyDeductibleOutOfNetworkCost = '';
    this.individualWithinFamilyDeductibleInNetworkApplicable = '';
    this.individualWithinFamilyDeductibleOutOfNetworkApplicable = '';

    this.individualOopMaxInNetworkCost = '';
    this.individualOopMaxOutOfNetworkCost = '';
    this.individualOopMaxInNetworkApplicable = '';
    this.individualOopMaxOutOfNetworkApplicable = '';

    this.familyOopMaxInNetworkCost = '';
    this.familyOopMaxOutOfNetworkCost = '';
    this.familyOopMaxInNetworkApplicable = '';
    this.familyOopMaxOutOfNetworkApplicable = '';

    this.individualWithinFamilyOopMaxInNetworkCost = '';
    this.individualWithinFamilyOopMaxOutOfNetworkCost = '';
    this.individualWithinFamilyOopMaxInNetworkApplicable = '';
    this.individualWithinFamilyOopMaxOutOfNetworkApplicable = '';
  }
}
export default RxForDeductiblesAndOopMax;
