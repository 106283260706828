import { FC, useState } from 'react';
import { Row, Col } from 'antd';
import { RENEWALS_STARTED_WARNING } from 'constants/commonConstants';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import NextButton from 'components/buttons/NextButton/NextButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';

import styles from './panelFooter.module.less';

type PanelFooterProps = {
  serviceName?: string;
  nextStep: () => void;
  isLastStep?: boolean;
  showLoadingIcon?: boolean;
  onAddBtnClicked?: (e: any) => void;
  validationInfo?: string;
  showValidationInfo?: boolean;
  validationWarning?: string;
  isDisable?: boolean;
  planName?: string;
  isRenewalProcessStarted?: boolean;
  buttonText?: string;
  disableServices?: boolean;
};

const PanelFooter: FC<PanelFooterProps> = (props: PanelFooterProps) => {
  const {
    serviceName,
    nextStep,
    isLastStep = false,
    showLoadingIcon = false,
    onAddBtnClicked = () => {},
    showValidationInfo = false,
    validationInfo,
    validationWarning,
    isDisable = false,
    disableServices = false,
    planName,
    isRenewalProcessStarted,
    buttonText,
  } = props;

  const [renwalWarningModalVisible, setRenewalWarningModalVisible] =
    useState<boolean>(false);

  const saveMDVPlans = () => {
    setRenewalWarningModalVisible(false);
    if (isRenewalProcessStarted) {
      setRenewalWarningModalVisible(true);
    } else {
      nextStep();
    }
  };

  return (
    <div className={styles.panelFooterSection}>
      <Row>
        <div className={styles.validationWarning}>{validationWarning}</div>
      </Row>
      <Row>
        <Col>
          {serviceName && (
            <LinkButton
              onClick={onAddBtnClicked}
              className={styles.addServiceBtn}
              disabled={disableServices}
            >
              + Add {serviceName}
            </LinkButton>
          )}
        </Col>

        <Col flex="auto" className="actions-col">
          <div className={styles.planNextWrapper}>
            {isLastStep ? (
              <NextButton
                nextStep={saveMDVPlans}
                className={styles.planNextBtn}
                buttonText="Complete and Save Plan"
                disabled={showLoadingIcon || isDisable}
              />
            ) : (
              <NextButton
                nextStep={nextStep}
                className={styles.planNextBtn}
                loading={showLoadingIcon}
                disabled={isDisable}
                buttonText={buttonText}
              />
            )}
          </div>
        </Col>
      </Row>
      {showValidationInfo && (
        <div className={styles.validationInfo}>{validationInfo}</div>
      )}
      <ConfirmationDialog
        modalClassName={styles.deleteConfirmationModal}
        title={
          <Col span={24}>
            <Row justify="center">Add Plan</Row>
            <Row justify="center">
              <span className={styles.subHeading}>{planName}</span>
            </Row>
          </Col>
        }
        confirmText={'Add Plan'}
        cancelText="Cancel"
        isCloseOnly={false}
        closeModal={() => {
          setRenewalWarningModalVisible(false);
        }}
        onConfirm={() => {
          setRenewalWarningModalVisible(false);
          nextStep();
        }}
        visible={renwalWarningModalVisible}
        confirmLoading={isDisable || showLoadingIcon}
        centered
        destroyOnClose
      >
        <p>{RENEWALS_STARTED_WARNING}</p>
      </ConfirmationDialog>
    </div>
  );
};

export default PanelFooter;
