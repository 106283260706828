import axios from 'axios';
import { lumityApi } from 'api/apiSlice';
import { baseApi, CONTENT_TYPE_MULTIPART_FORM } from 'util/apiUtil';
import { UP_CONSOLIDATE_CLAIMS } from 'modules/claims/constants/constants';

const claimsApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<any, { file: File; employerId: string }>({
      query: (args) => {
        const { file, employerId } = args;
        const formData = new FormData();
        formData.append('file', file, file.name);
        return {
          url: `v3/analytics/employers/${employerId}/parse`,
          method: 'post',
          params: {
            type: UP_CONSOLIDATE_CLAIMS,
          },
          data: formData,
          headers: {
            'content-type': CONTENT_TYPE_MULTIPART_FORM,
          },
        };
      },
    }),
    getLossRatioChartData: builder.query<
      any,
      {
        employerId: string | undefined;
        benefitKind: string | undefined;
        from: string | undefined;
        to: string | undefined;
        preview: boolean;
      }
    >({
      query: (args) => {
        const { employerId, benefitKind, from, to, preview } = args;

        return {
          url: `v2/analytics/employers/${employerId}/loss-ratio`,
          method: 'get',
          params: {
            benefitKind: benefitKind,
            from: from,
            to: to,
            preview: preview,
          },
        };
      },
    }),
    getClaimsBreakdownChartData: builder.query<
      any,
      {
        employerId: string | undefined;
        benefitKind: string | undefined;
        from: string | undefined;
        to: string | undefined;
        preview: boolean;
      }
    >({
      query: (args) => {
        const { employerId, benefitKind, from, to, preview } = args;

        return {
          url: `v2/analytics/employers/${employerId}/claims-breakdown`,
          method: 'get',
          params: {
            benefitKind: benefitKind,
            from: from,
            to: to,
            preview: preview,
          },
        };
      },
    }),
    getLargeClaimsChartData: builder.query<
      any,
      {
        employerId: string | undefined;
        from: string | undefined;
        to: string | undefined;
        preview: boolean;
      }
    >({
      query: (args) => {
        const { employerId, from, to, preview } = args;

        return {
          url: `v2/analytics/employers/${employerId}/large-claims`,
          method: 'get',
          params: {
            from: from,
            to: to,
            preview: preview,
          },
        };
      },
    }),
    getLossRatioDateRange: builder.query<
      any,
      {
        employerId: string | undefined;
        benefitKind: string | undefined;
        preview: boolean;
      }
    >({
      query: (args) => {
        const { employerId, preview, benefitKind } = args;

        return {
          url: `v2/analytics/employers/${employerId}/range`,
          method: 'get',
          params: {
            documentType: 'LRCB',
            preview: preview,
            benefitKind: benefitKind,
          },
        };
      },
    }),
    getClaimsSummary: builder.query<
      any,
      {
        employerId: string | undefined;
        benefitKind: string | undefined;
        from: string | undefined;
        to: string | undefined;
        preview: boolean;
      }
    >({
      query: (args) => {
        const { employerId, benefitKind, from, to, preview } = args;

        return {
          url: `v2/analytics/employers/${employerId}/claims-summary`,
          method: 'get',
          params: {
            benefitKind: benefitKind,
            from: from,
            to: to,
            preview: preview,
          },
        };
      },
    }),
    getClaimsSettings: builder.query<
      any,
      {
        employerId: string | undefined;
        preview: boolean;
      }
    >({
      query: (args) => {
        const { employerId, preview } = args;

        return {
          url: `v3/analytics/employers/${employerId}/claims/settings`,
          method: 'get',
          params: { preview: preview },
        };
      },
    }),
    publishClaims: builder.mutation<
      any,
      {
        employerId: string | undefined;
        notification: boolean;
      }
    >({
      query: (args) => {
        const { employerId, notification } = args;
        return {
          url: `v3/analytics/employers/${employerId}/publish`,
          method: 'post',
          params: { notification: notification },
        };
      },
    }),
    getYears: builder.query<
      any,
      {
        benefitKind: string | undefined;
        employerId: string | undefined;
        preview: boolean | undefined;
        documentType: string;
        currentTs: number;
      }
    >({
      query: (args) => {
        const { employerId, benefitKind, preview, documentType, currentTs } =
          args;

        return {
          url: `v2/analytics/employers/${employerId}/years`,
          method: 'get',
          params: {
            currentTs: currentTs,
            benefitKind: benefitKind,
            preview: preview,
            documentType: documentType,
          },
        };
      },
    }),
    getClaimsFileHistory: builder.query<
      any,
      {
        employerId: string | undefined;
      }
    >({
      query: (args) => {
        const { employerId } = args;

        return {
          url: `v1/analytics/employers/${employerId}/file-history`,
          method: 'get',
          params: {
            documentType: UP_CONSOLIDATE_CLAIMS,
          },
        };
      },
    }),
    getClaimsLastUpdatedStatus: builder.query<
      any,
      {
        employerId: string | undefined;
      }
    >({
      query: (args) => {
        const { employerId } = args;

        return {
          url: `v3/analytics/employers/${employerId}/features/summary`,
          method: 'get',
          params: {
            type: UP_CONSOLIDATE_CLAIMS,
          },
        };
      },
    }),
    getSFCChartData: builder.query<
      any,
      {
        employerId: string | undefined;
        preview: boolean;
        from: string | undefined;
        to: string | undefined;
        benefitKind: string | undefined;
      }
    >({
      query: (args) => {
        const { employerId, preview, from, to, benefitKind } = args;

        return {
          url: `v3/analytics/employers/${employerId}/self-funded-chart`,
          method: 'get',
          params: {
            type: UP_CONSOLIDATE_CLAIMS,
            preview: preview,
            from: from,
            to: to,
            benefitKind: benefitKind,
          },
        };
      },
    }),
    getSFCDateRange: builder.query<
      any,
      {
        employerId: string | undefined;
        benefitKind: string | undefined;
        preview: boolean;
      }
    >({
      query: (args) => {
        const { employerId, preview, benefitKind } = args;

        return {
          url: `v3/analytics/employers/${employerId}/range`,
          method: 'get',
          params: {
            documentType: 'UP_SFC',
            preview: preview,
            benefitKind: benefitKind,
          },
        };
      },
    }),
    getSFCChartSummary: builder.query<
      any,
      {
        employerId: string | undefined;
        preview: boolean;
        from: string | undefined;
        to: string | undefined;
        benefitKind: string | undefined;
      }
    >({
      query: (args) => {
        const { employerId, preview, from, to, benefitKind } = args;

        return {
          url: `v3/analytics/employers/${employerId}/self-funded-chart-totals`,
          method: 'get',
          params: {
            preview: preview,
            from: from,
            to: to,
            benefitKind: benefitKind,
          },
        };
      },
    }),
    deleteClaimFIle: builder.mutation<
      any,
      {
        employerId: string | undefined;
        fileId: string;
      }
    >({
      query: (args) => {
        const { employerId, fileId } = args;

        return {
          url: `v3/analytics/employers/${employerId}/delete-file`,
          method: 'delete',
          params: {
            fileId: fileId,
          },
        };
      },
    }),
  }),
});

export const exportErrorFile = async (
  file: File | null,
  errors: any,
  errorColumns: any
) => {
  const errorData = {
    errorRecords: errors,
    errorColumns: errorColumns,
  };
  const formData = new FormData();
  formData.append('file', file ?? '');
  formData.append(
    'errors',
    new Blob([JSON.stringify(errorData)], { type: 'application/json' })
  );
  const response = await axios.post(
    `${baseApi}/v3/analytics/claims/error-file`,
    formData,
    {
      responseType: 'blob',
    }
  );
  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `Error File - ${file?.name}`);
  document.body && document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const {
  useUploadFileMutation,
  useLazyGetClaimsBreakdownChartDataQuery,
  useLazyGetLargeClaimsChartDataQuery,
  useLazyGetLossRatioChartDataQuery,
  useLazyGetLossRatioDateRangeQuery,
  useLazyGetClaimsSummaryQuery,
  useGetClaimsSettingsQuery,
  useLazyGetClaimsSettingsQuery,
  usePublishClaimsMutation,
  useGetYearsQuery,
  useLazyGetYearsQuery,
  useGetClaimsFileHistoryQuery,
  useGetClaimsLastUpdatedStatusQuery,
  useLazyGetSFCChartDataQuery,
  useLazyGetSFCDateRangeQuery,
  useLazyGetSFCChartSummaryQuery,
  useDeleteClaimFIleMutation,
} = claimsApi;
