import { FC } from 'react';

import { Row } from 'antd';

import planYearLogo from 'assets/images/navigation/PlanYearLogo.svg';
import styles from './sendInvitesForm.module.less';

type Props = {
  firstName: string;
};

const InvitationTemplate: FC<Props> = (props) => {
  const { firstName } = props;
  return (
    <Row className={styles.invitationWrapper}>
      <div className={styles.logoWrapper}>
        <img src={planYearLogo} alt="lumity logo" />
      </div>
      <Row className={styles.contentWrapper}>
        <Row>
          <span>Hi {firstName},</span>
        </Row>
        <Row className={styles.content}>
          <span>
            You’ve been invited to the PlanYear platform! To create an account,
            please copy/paste the code below and use the “Register with Invite
            Code” link on the PlanYear login screen:
          </span>
        </Row>
        <Row className={styles.code}>6-DIGIT CODE</Row>
        <span>
          This code will expire after 7 days. If you have any questions, please
          email support@planyear.com
        </span>
      </Row>
    </Row>
  );
};
export default InvitationTemplate;
