import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import HalfScreenModal from 'components/HalfScreenModal/HalfScreenModal';

import InfoBanner from 'components/InfoBanner/InfoBanner';
import BenefitsConsultant from 'model/benefitsConsultation/BenefitsConsultant';

import { inviteConsultants } from 'modules/benefitsConsultation/slices/commonSlice';
import { SEND_INVITE_NOW } from 'modules/benefitsConsultation/constants/benefitsConsultation';
import iconSendInvite from 'assets/images/icon-send-invite.svg';

import styles from './sendInviteModal.module.less';

type SendInviteModalProps = {
  disabled?: boolean;
  isLoading?: boolean;
  visible: boolean;
  pendingInviteList: BenefitsConsultant[];
  onCancel: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    type?: string
  ) => void;
};

const SendInviteModal: FC<SendInviteModalProps> = (
  props: SendInviteModalProps
) => {
  const { onCancel, visible, pendingInviteList } = props;
  const dispatch = useAppDispatch();
  const inProgress = useAppSelector(
    (state) => state.benefitsConsultation.common.inProgress
  );

  return (
    <HalfScreenModal onCancel={onCancel} visible={visible} width={574}>
      <div className={styles.sendInviteModalWrapper}>
        <div className={styles.infoBanner}>
          <InfoBanner
            title="Send Calendar Sync Invites to Created Consultants"
            description={
              <div>
                The newly added consultants will receive an invitation link via
                email to sync their calendar.
              </div>
            }
            logo={<img src={iconSendInvite} alt="send invite icon" />}
            className={styles.infoBanner}
          />
        </div>
        <div className={styles.footer}>
          <SubmitButton className={styles.cancelBtn} onClick={onCancel}>
            Send Later
          </SubmitButton>
          <PageActionButton
            onClick={(event) => {
              dispatch(inviteConsultants(pendingInviteList)).then(() => {
                onCancel(event, SEND_INVITE_NOW);
              });
            }}
            className={styles.sendBtn}
            type="primary"
            loading={inProgress}
          >
            {SEND_INVITE_NOW}
          </PageActionButton>
        </div>
      </div>
    </HalfScreenModal>
  );
};

export default SendInviteModal;
