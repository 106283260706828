import { FC } from 'react';

import LinkButton from 'components/buttons/LinkButton/LinkButton';
import styles from './headerNavigation.module.less';

type HeaderNavigationProps = {
  headerItems: HeaderDataSet[];
};

type HeaderDataSet = {
  headerName: string;
  headerNavigation: string;
};

const HeaderNavigation: FC<HeaderNavigationProps> = (
  props: HeaderNavigationProps
) => {
  const { headerItems } = props;

  return (
    <div className={styles.headerWrapper}>
      {headerItems &&
        headerItems.map((item, index) => {
          return (
            <>
              <LinkButton key={index} href={item.headerNavigation}>
                <div className={styles.name}> {item.headerName}</div>
              </LinkButton>
              {!(index === headerItems.length - 1) ? (
                <div className={styles.divider}> | </div>
              ) : (
                ''
              )}
            </>
          );
        })}
    </div>
  );
};

export default HeaderNavigation;
