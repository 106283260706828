import { useEffect, useState } from 'react';
import { Form, Input, Modal } from 'antd';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import PanelInputForm from 'modules/plans/components/PanelInputForm/PanelInputForm';
import { getFolderAvailability } from 'modules/documents/services/DocumentsService';
import { useNavContext } from 'hooks/useNavContext';

import styles from './folderManagementModal.module.less';

type FolderManagementModalProps = {
  visible: boolean;
  onClose: () => void;
  onCreate: (folderName: string) => void;
  title: string;
  saveButtonText: string;
  initialValue: string;
  parentDocumentId: string;
};

const FolderManagementModal = (props: FolderManagementModalProps) => {
  const [form] = Form.useForm();
  const { employerId } = useNavContext();
  const [text, setText] = useState<string>('');

  useEffect(() => {
    if (props.visible) {
      form.setFieldsValue({ folderName: props.initialValue });
      setText(props.initialValue);
    }
  }, [form, props.initialValue, props.visible]);

  const trimInput = (event: any) => {
    const { value } = event.target;
    form.setFieldsValue({ ['folderName']: value.trim() });
  };

  const onInputChange = (changedValues: any, allValues: any) => {
    const { folderName } = allValues;
    form.setFieldsValue({ folderName });
    setText(folderName);
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      props.onCreate(form.getFieldValue('folderName'));
    } catch (errorInfo: any) {
      return errorInfo.errorFields.length === 0;
    }
  };
  const footerComponent = (
    <div>
      <SubmitButton
        type="primary"
        onClick={handleSubmit}
        className={styles.cloneBtn}
        disabled={props.initialValue === text && text !== ''}
      >
        {props.saveButtonText}
      </SubmitButton>
      <CancelButton onClick={() => props.onClose()}>Cancel</CancelButton>
    </div>
  );
  return (
    <Modal
      wrapClassName={styles.modalWrapper}
      title={props.title}
      width={464}
      centered
      visible={props.visible}
      onCancel={props.onClose}
      footer={footerComponent}
      destroyOnClose={true}
    >
      <PanelInputForm
        form={form}
        onValuesChange={onInputChange}
        className={styles.formWrapper}
      >
        <Form.Item
          className={styles.planName}
          name="folderName"
          label="Folder Name*"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: 'Folder name is required',
              validateTrigger: ['onSubmit'],
            },
            {
              message: 'Folder name already exists',
              validateTrigger: ['onSubmit'],
              validator: (rule: any, value: string) => {
                return new Promise<void>((resolve, reject) => {
                  getFolderAvailability(
                    employerId || '',
                    props.parentDocumentId,
                    value
                  ).then(({ data }) => {
                    if (data.documentExist) {
                      reject(new Error('document exits'));
                    } else {
                      resolve();
                    }
                  });
                });
              },
            },
          ]}
        >
          <Input onBlur={trimInput} title="planName" maxLength={50} />
        </Form.Item>
      </PanelInputForm>
    </Modal>
  );
};

export default FolderManagementModal;
