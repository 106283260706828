import React, { useState } from 'react';
import { Col, Radio, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { isEmpty } from 'lodash';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import QuoteProcessStatus from 'modules/renewals/enums/QuoteUploadStatus';
import {
  REJECT_CUSTOM_MESSAGE,
  REJECT_FILE_MESSAGE,
  REJECT_IMAGE_READABLE,
  REJECT_IMAGE_READABLE_MESSAGE,
  REJECT_INCORRECT_BENEFIT,
  REJECT_INCORRECT_BENEFIT_MESSAGE,
} from 'modules/plans/constants';
import { rejectSelectedFile } from 'modules/plans/services/PlanService';
import { setStatus } from 'modules/plans/slices/aiSbcUploaderSlice';
import { setStatus as setCarrierQuoteStatus } from 'modules/renewals/slices/carrierQuoteAiSlice';
import { useAppDispatch } from 'hooks/redux';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import { AIRejectType } from 'modules/plans/enums/CommonTypes';
import styles from './rejectFileModal.module.less';

type Props = {
  jobId: string;
  isModalShow: boolean;
  setIsModalShow: (value: boolean) => void;
  type: AIRejectType;
};

const RejectFileModal = (props: Props) => {
  const { isModalShow, setIsModalShow, jobId, type } = props;
  const dispatch = useAppDispatch();

  const [value, setValue] = useState<number>(1);

  const [customMessage, setCustomMessage] = useState<string>('');

  const onChangeRadio = (e: any) => {
    setValue(e.target.value);
  };

  const resetRejectStatus = () => {
    setCustomMessage('');
    setValue(1);
    setIsModalShow(false);
  };

  const confirmModalSelection = async () => {
    switch (value) {
      case 1:
        await rejectSelectedFile(jobId, REJECT_INCORRECT_BENEFIT_MESSAGE);
        break;
      case 2:
        await rejectSelectedFile(jobId, REJECT_IMAGE_READABLE_MESSAGE);
        break;
      default:
        await rejectSelectedFile(jobId, customMessage);
    }

    switch (type) {
      case AIRejectType.PLANS_REJECT:
        dispatch(setStatus(ProcessStatus.REJECTED));
        break;
      case AIRejectType.QUOTE_REJECT:
        dispatch(
          setCarrierQuoteStatus({ jobId, status: QuoteProcessStatus.REJECTED })
        );
        break;
    }

    resetRejectStatus();
  };

  return (
    <div>
      <Radio.Group onChange={onChangeRadio} value={value}>
        <ConfirmationDialog
          destroyOnClose
          width={800}
          title="Reject File"
          onConfirm={confirmModalSelection}
          confirmText={'Reject File and Send Notification'}
          visible={isModalShow}
          closeModal={resetRejectStatus}
          isCancelLink
          cancelText="Cancel"
          disableConfirmButton={isEmpty(customMessage.trim()) && value === 3}
          confirmBtnFullWidth
        >
          <div className={styles.rejectFileModal}>
            <>{REJECT_FILE_MESSAGE}</>
            <div className={styles.modalContentArea}>
              <Row justify="center" gutter={[12, 12]} align="middle">
                <Col>
                  <Radio value={1} />
                </Col>
                <Col span={22}>
                  <Row className={styles.headerRow}>
                    <b>{REJECT_INCORRECT_BENEFIT}</b>
                  </Row>
                  <Row>
                    <TextArea
                      className={styles.disabledTextArea}
                      value={REJECT_INCORRECT_BENEFIT_MESSAGE}
                      disabled
                      rows={5}
                    />
                  </Row>
                </Col>
              </Row>
              <Row justify="center" gutter={[12, 12]} align="middle">
                <Col>
                  <Radio value={2} />
                </Col>
                <Col span={22}>
                  <Row className={styles.headerRow}>
                    <b>{REJECT_IMAGE_READABLE}</b>
                  </Row>
                  <Row>
                    <TextArea
                      className={styles.disabledTextArea}
                      value={REJECT_IMAGE_READABLE_MESSAGE}
                      disabled
                      rows={5}
                    />
                  </Row>
                </Col>
              </Row>
              <Row justify="center" gutter={[12, 12]} align="middle">
                <Col>
                  <Radio value={3} />
                </Col>
                <Col span={22}>
                  <Row className={styles.headerRow}>
                    <b>{REJECT_CUSTOM_MESSAGE}</b>
                  </Row>
                  <Row>
                    <TextArea
                      disabled={value !== 3}
                      className={styles.customMessageTextArea}
                      placeholder={'Enter custom message'}
                      rows={5}
                      value={customMessage}
                      onChange={(e) => setCustomMessage(e.target.value)}
                    />
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </ConfirmationDialog>
      </Radio.Group>
    </div>
  );
};

export default RejectFileModal;
