import { useEffect, useMemo, useState, useRef } from 'react';
import { Col, Modal, ModalProps, Row, Spin } from 'antd';
import { isEmpty } from 'lodash';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import Button from 'components/buttons/Button/Button';
import BasicInfo from 'modules/renewals/components/AppProposalToPlanYearComponents/ReviewModal/BasicInfo/BasicInfo';
import PremiumsAndContributions from 'modules/renewals/components/AppProposalToPlanYearComponents/ReviewModal/PremiumsAndContribution/PremiumsAndContributions';
import DeductiblesAndOOPMax from 'modules/renewals/components/AppProposalToPlanYearComponents/ReviewModal/DeductiblesAndOOPMax/DeductiblesAndOOPMax';
import Services from 'modules/renewals/components/AppProposalToPlanYearComponents/ReviewModal/Services/Services';
import RxTiers from 'modules/renewals/components/AppProposalToPlanYearComponents/ReviewModal/RxTiers/RxTiers';
import IdCardInformation from 'modules/renewals/components/AppProposalToPlanYearComponents/ReviewModal/IdCardInformation/IdCardInformation';
import PlanDocuments from 'modules/renewals/components/AppProposalToPlanYearComponents/ReviewModal/PlanDocuments/PlanDocuments';
import DeductiblesAndMaximums from 'modules/renewals/components/AppProposalToPlanYearComponents/ReviewModal/DeductiblesAndMaximums/DeductiblesAndMaximums';
import VoluntaryPlanReview from 'modules/renewals/components/AppProposalToPlanYearComponents/VoluntaryPlanReview/VoluntaryPlanReview';
import {
  getCurrentPlan,
  saveCurrentPlan,
  setIsUsingMergedPlanData,
  updateCurrentSelectedPlan,
  clearCurrentSelectedPlan,
} from 'modules/renewals/slices/addProposalToPlanYearSlice';
import { BenefitCategory } from 'constants/commonConstants';
import { buildRatesForReview } from 'modules/renewals/utils/addToPlanYearUtil';
import { PLAN_TYPE_LABEL_MAP } from 'modules/renewals/constants/addToPlanYearConstants';
import { MEDICAL } from 'modules/plans/planRates/pages/PlanRates/PlanRates';
import { Plan } from 'model/plans/Plan';
import { TransformedPlan } from 'modules/renewals/types/renewalsTypes';

import styles from './reviewModal.module.less';
import LifePlans from './LifePlans/LifePlans';
import TaxAdvantageAccountsReview from './TaxAdvantageAccountsPlans/TaxAdvantageAccountsPlanReview';
import EnrollmentsInformation from './EnrollmentInformation/EnrollemntsInformation';

interface Props extends ModalProps {
  data: TransformedPlan;
  handleClose: () => void;
}

const ReviewModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const { data, handleClose, visible, ...rest } = props;
  const [currentStep, setCurrentStep] = useState(0);

  const formRef = useRef<any>();

  const currentSelectedPlan = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.currentSelectedPlan
  );

  const plans = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.plans
  );

  const benefitGroupState = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.basicDetails.benefitClasses
  );
  const currentPlanLoading = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.currentSelectedPlanLoading
  );

  const planCreateType = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.selectPlanCreateType
  );

  const benefitGroups = useMemo(() => {
    return benefitGroupState?.data?.map((item) => item.label);
  }, [benefitGroupState]);

  const isVolOrLifePlanOrTaxAdvPlans = [
    'VOLUNTARY_BENEFIT',
    'LIFE_AND_DISABILITY',
    'TAX_ADVANTAGE_ACCOUNTS',
  ].includes(data?.benefit);

  useEffect(() => {
    dispatch(setIsUsingMergedPlanData(data?.isMerged));
    // eslint-disable-next-line
  }, [data.mergedPlanId]);

  useEffect(() => {
    const rates = buildRatesForReview(benefitGroups || [], data);
    dispatch(
      getCurrentPlan(
        data.benefit,
        `${
          data.benefit === 'TAX_ADVANTAGE_ACCOUNTS' ? data.taxAdvId : data.id
        }`,
        rates,
        data?.isMerged ? data.mergedPlanId : undefined,
        data.tempId
      )
    );
    // eslint-disable-next-line
  }, []);

  const handleSetCurrentPlan = (plan: Plan) => {
    if (isVolOrLifePlanOrTaxAdvPlans) {
      plan.documentReferences = currentSelectedPlan?.documentReferences;
      plan.documents = currentSelectedPlan?.documents;
      (plan as any).file = (currentSelectedPlan as any)?.file;
    }
    dispatch(updateCurrentSelectedPlan(plan));
  };

  const getStepsAccordingToTypes: {
    [key: string]: { title: string; component: React.ReactNode }[];
  } = {
    MEDICAL: [
      {
        title: 'Review Medical - Basic Info',
        component: (
          <BasicInfo
            state={currentSelectedPlan!}
            setState={handleSetCurrentPlan}
            type="MEDICAL"
            benefitClasses={benefitGroups}
            formRef={formRef}
          />
        ),
      },
      {
        title: 'Review Medical - Premiums & Contributions',
        component: (
          <PremiumsAndContributions
            state={currentSelectedPlan}
            setState={handleSetCurrentPlan}
            formRef={formRef}
          />
        ),
      },
      {
        title: 'Review Medical - Deductibles & OOP Max',
        component: (
          <DeductiblesAndOOPMax
            state={currentSelectedPlan}
            setState={handleSetCurrentPlan}
          />
        ),
      },
      {
        title: 'Review Medical - Services',
        component: (
          <Services
            type="MEDICAL"
            state={currentSelectedPlan!}
            setState={handleSetCurrentPlan}
          />
        ),
      },
      {
        title: 'Review Medical - Rx',
        component: (
          <RxTiers
            state={currentSelectedPlan}
            setState={handleSetCurrentPlan}
          />
        ),
      },
      {
        title: 'Review Medical - ID Card Information',
        component: (
          <IdCardInformation
            state={currentSelectedPlan}
            setState={handleSetCurrentPlan}
          />
        ),
      },
      {
        title: 'Review Medical - Enrollments Information',
        component: (
          <EnrollmentsInformation
            state={currentSelectedPlan}
            setState={handleSetCurrentPlan}
            benefitKind={BenefitCategory.MEDICAL.value}
          />
        ),
      },
      {
        title: 'Review Medical - Plan Resources',
        component: (
          <PlanDocuments type="MEDICAL" state={currentSelectedPlan!} />
        ),
      },
    ],
    DENTAL: [
      {
        title: 'Review Dental - Basic Info',
        component: (
          <BasicInfo
            state={currentSelectedPlan!}
            setState={handleSetCurrentPlan}
            type="DENTAL"
            benefitClasses={benefitGroups}
            formRef={formRef}
          />
        ),
      },
      {
        title: 'Review Dental - Premiums & Contributions',
        component: (
          <PremiumsAndContributions
            state={currentSelectedPlan}
            setState={handleSetCurrentPlan}
            formRef={formRef}
          />
        ),
      },
      {
        title: 'Review Dental - Deductibles & Maximums',
        component: (
          <DeductiblesAndMaximums
            state={currentSelectedPlan!}
            setState={handleSetCurrentPlan}
          />
        ),
      },
      {
        title: 'Review Dental - Services',
        component: (
          <Services
            type="DENTAL"
            state={currentSelectedPlan!}
            setState={handleSetCurrentPlan}
          />
        ),
      },
      {
        title: 'Review Dental - Enrollments Information',
        component: (
          <EnrollmentsInformation
            state={currentSelectedPlan}
            setState={handleSetCurrentPlan}
            benefitKind={BenefitCategory.DENTAL.value}
          />
        ),
      },
      {
        title: 'Review Dental - Plan Resources',
        component: <PlanDocuments type="DENTAL" state={currentSelectedPlan!} />,
      },
    ],
    VISION: [
      {
        title: 'Review Vision - Basic Info',
        component: (
          <BasicInfo
            state={currentSelectedPlan!}
            setState={handleSetCurrentPlan}
            type="VISION"
            benefitClasses={benefitGroups}
            formRef={formRef}
          />
        ),
      },
      {
        title: 'Review Vision - Premiums & Contributions',
        component: (
          <PremiumsAndContributions
            state={currentSelectedPlan}
            setState={handleSetCurrentPlan}
            formRef={formRef}
          />
        ),
      },
      {
        title: 'Review Vision - Services',
        component: (
          <Services
            type="VISION"
            state={currentSelectedPlan!}
            setState={handleSetCurrentPlan}
          />
        ),
      },
      {
        title: 'Review Vision - Enrollments Information',
        component: (
          <EnrollmentsInformation
            state={currentSelectedPlan}
            setState={handleSetCurrentPlan}
            benefitKind={BenefitCategory.VISION.value}
          />
        ),
      },
      {
        title: 'Review Vision - Plan Resources',
        component: <PlanDocuments type="VISION" state={currentSelectedPlan!} />,
      },
    ],
    LIFE_AND_DISABILITY: [
      {
        title: `Review ${PLAN_TYPE_LABEL_MAP[data.planType!]} Plan`,
        component: (
          <LifePlans
            state={currentSelectedPlan!}
            setState={handleSetCurrentPlan}
            type="LIFE_AND_DISABILITY"
            benefitClasses={benefitGroups}
            benefitKind={data.planType}
            formRef={formRef}
            isModalVisible={visible}
          />
        ),
      },
    ],
    VOLUNTARY_BENEFIT: [
      {
        title: `Review ${PLAN_TYPE_LABEL_MAP[data.planType!]} Plan`,
        component: (
          <VoluntaryPlanReview
            formRef={formRef}
            type="VOLUNTARY_BENEFIT"
            benefitKind={data.planType}
            state={currentSelectedPlan!}
            setState={handleSetCurrentPlan}
            benefitClasses={benefitGroups}
            isModalVisible={visible}
          />
        ),
      },
    ],
    TAX_ADVANTAGE_ACCOUNTS: [
      {
        title: `${data.planType === 'HRA' ? 'Review HRA' : 'Review HSA'} `,
        component: (
          <TaxAdvantageAccountsReview
            formRef={formRef}
            type="TAX_ADVANTAGE_ACCOUNTS"
            benefitKind={data.planType}
            state={currentSelectedPlan!}
            setState={handleSetCurrentPlan}
            benefitClasses={benefitGroups}
            isModalVisible={visible}
          />
        ),
      },
    ],
  };

  const disableOnUpload = () => {
    if (
      currentSelectedPlan &&
      !isEmpty(currentSelectedPlan?.documentReferences)
    ) {
      return Object.values(currentSelectedPlan?.documentReferences)?.some(
        (document: any) => document?.uploading
      );
    }
    return false;
  };

  const isDuplicate = () => {
    const prevArr = plans[data?.benefit];
    const previousPlan = data;
    const currentPlan = currentSelectedPlan;

    if (
      previousPlan.planName?.toLowerCase() !== currentPlan?.name?.toLowerCase()
    ) {
      if (!isVolOrLifePlanOrTaxAdvPlans) {
        if (
          prevArr.some(
            (plan: any) =>
              plan.planName?.toLowerCase() === currentPlan?.name?.toLowerCase()
          )
        ) {
          formRef?.current?.setNameDuplicationError();
          return true;
        }
      }
    }

    return false;
  };

  const onNextStep = async () => {
    const isLastStep =
      currentStep === getStepsAccordingToTypes[data?.benefit].length - 1;
    const isMedicalOrDentalOrVision = ['MEDICAL', 'DENTAL', 'VISION'].includes(
      data?.benefit
    );

    if (isLastStep) {
      if (isVolOrLifePlanOrTaxAdvPlans) {
        /* Vol, Vol-Life, Life modals are all single page. This is done to trigger 
        Saving and/or validation of form when submitted */
        try {
          await formRef.current?.isValidForm();
          dispatch(
            saveCurrentPlan({
              benefit: data.benefit,
              id:
                data.benefit === 'TAX_ADVANTAGE_ACCOUNTS'
                  ? data.tempId !== undefined
                    ? data.tempId
                    : ''
                  : data.id,
            })
          );
          handleClose();
          dispatch(clearCurrentSelectedPlan());
        } catch (error) {
          // Handle validation or save error if needed
        }
      } else {
        dispatch(saveCurrentPlan({ benefit: data.benefit, id: data.id }));
        handleClose();
        dispatch(clearCurrentSelectedPlan());
      }
    } else {
      const validation = await formRef.current?.isValidForm();

      if (
        (!validation || isDuplicate()) &&
        isMedicalOrDentalOrVision &&
        currentStep === 0
      ) {
        return;
      } else if (
        validation?.invalidFormFields?.length !== 0 &&
        isMedicalOrDentalOrVision &&
        currentStep === 1
      ) {
        await formRef?.current?.showErrorMsg();
        return;
      } else {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    }
  };

  const onPrevStep = () => {
    if (currentStep === 0) {
      dispatch(clearCurrentSelectedPlan());
      return handleClose();
    }
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const footer = (
    <>
      <Row gutter={10} className={styles.btnWrapper}>
        <Col xs={8}>
          <Button
            className={styles.btn}
            type="default"
            label={currentStep === 0 ? 'Cancel' : 'Back'}
            onClick={onPrevStep}
          />
        </Col>
        <Col xs={8}>
          <Button
            className={styles.btn}
            disabled={disableOnUpload()}
            type="primary"
            label={
              currentStep ===
              getStepsAccordingToTypes[data?.benefit]?.length - 1
                ? 'Save'
                : 'Save & Continue'
            }
            onClick={onNextStep}
          />
        </Col>
      </Row>
    </>
  );

  const getAlertMessage = () =>
    !getStepsAccordingToTypes[data?.benefit][currentStep]?.title.includes(
      'Plan Documents'
    ) && (
      <>
        {planCreateType === 'NEW' && (
          // Added a margin if it's a MEDICAL Form to avoid changes in other used forms
          <div
            className={
              (data?.benefit === MEDICAL && styles.fixedAlertWrapper) || ''
            }
          >
            {' '}
            <FixedAlertMessage
              message="These fields have been updated with new proposal plan data."
              type="info"
            />
          </div>
        )}
        {planCreateType === 'MERGE' && data.isMerged && !data.isReviewed && (
          <FixedAlertMessage
            type={'warning'}
            message={
              'Highlighted fields have been populated with proposal plan data.'
            }
          />
        )}
      </>
    );

  return (
    <Modal
      title={getStepsAccordingToTypes?.[data?.benefit][currentStep]?.title}
      width={565}
      className={styles.modalMain}
      closable={false}
      footer={!currentPlanLoading ? footer : null}
      visible={visible}
      {...rest}
    >
      {currentPlanLoading ? (
        <div className={styles.loadingWrapper}>
          <Spin />
        </div>
      ) : (
        <>
          {getAlertMessage()}
          {currentSelectedPlan &&
            getStepsAccordingToTypes[data?.benefit][currentStep]?.component}
        </>
      )}
    </Modal>
  );
};

export default ReviewModal;
