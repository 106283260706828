import { lumityApi } from 'api/apiSlice';
import Broker from 'model/Broker';
import Employer from 'model/Employer';
import Page from 'model/Page';
import PaginationConfig from 'model/PaginationConfig';

const ssoApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    employerList: builder.query<
      Page<Employer>,
      PaginationConfig & {
        organizationId: string;
      }
    >({
      query: (args) => {
        const { organizationId, query, page, size, sort } = args;
        return {
          url: `v1/employers`,
          method: 'GET',
          params: {
            organizationId,
            query,
            _page: page,
            _size: size,
            _sort: sort,
          },
        };
      },
    }),
    brokerList: builder.query<Page<Broker>, PaginationConfig>({
      query: (args) => {
        const { query, page, size, sort } = args;
        return {
          url: `v1/organizations`,
          method: 'GET',
          params: {
            query,
            _page: page,
            _size: size,
            _sort: sort,
          },
        };
      },
    }),
    validateMetadata: builder.mutation<
      void,
      {
        metadataUrl: string;
        vendor: string;
      }
    >({
      query: ({ metadataUrl, vendor }) => ({
        url: 'v1/sso/metadata/validate',
        method: 'GET',
        params: {
          metadataUrl,
          vendor,
        },
      }),
    }),
    toggleEmployerSso: builder.mutation<
      void,
      {
        employerId: string;
        shouldEnable: boolean;
        metadataUrl?: string;
        vendor?: string;
      }
    >({
      query: ({ metadataUrl, vendor, employerId, shouldEnable }) => ({
        url: `v2/sso/employers/${employerId}`,
        method: 'PUT',
        params: {
          employerId,
          shouldEnable,
        },
        data: {
          metadataUrl,
          vendor,
        },
      }),
    }),
    updateEmployerSso: builder.mutation<
      void,
      {
        employerId: string;
        metadataUrl?: string;
        vendor?: string;
      }
    >({
      query: ({ metadataUrl, vendor, employerId }) => ({
        url: `v1/employers/${employerId}/sso-config`,
        method: 'PUT',
        params: {
          employerId,
        },
        data: {
          metadataUrl,
          vendor,
        },
      }),
    }),
    updateBrokerSso: builder.mutation<
      void,
      {
        organizationId: string;
        metadataUrl?: string;
        vendor?: string;
        isSsoEnabled?: boolean;
      }
    >({
      query: ({ metadataUrl, vendor, organizationId, isSsoEnabled }) => ({
        url: `v2/organizations/${organizationId}/sso-config`,
        method: 'PUT',
        params: {
          organizationId,
          isSsoEnabled,
        },
        ...((vendor || metadataUrl) && {
          data: {
            metadataUrl,
            vendor,
          },
        }),
      }),
    }),
    enableBrokerSso: builder.mutation<
      void,
      {
        organizationId: string;
        metadataUrl?: string;
        vendor?: string;
      }
    >({
      query: ({ metadataUrl, vendor, organizationId }) => ({
        url: `v2/organizations/sso/${organizationId}`,
        method: 'PUT',
        params: {
          organizationId,
        },
        ...((vendor || metadataUrl) && {
          data: {
            metadataUrl,
            vendor,
          },
        }),
      }),
    }),
    updateBrokerSsoConfig: builder.mutation<
      void,
      {
        organizationId: string;
        metadataUrl?: string;
        vendor?: string;
      }
    >({
      query: ({ metadataUrl, vendor, organizationId }) => ({
        url: `v2/organizations/sso/${organizationId}/sso-config`,
        method: 'PUT',
        params: {
          organizationId,
        },
        ...((vendor || metadataUrl) && {
          data: {
            metadataUrl,
            vendor,
          },
        }),
      }),
    }),
  }),
});

export const {
  useEmployerListQuery,
  useBrokerListQuery,
  useValidateMetadataMutation,
  useToggleEmployerSsoMutation,
  useUpdateEmployerSsoMutation,
  useUpdateBrokerSsoMutation,
  useEnableBrokerSsoMutation,
  useUpdateBrokerSsoConfigMutation,
} = ssoApi;
