import RichTextEditor from 'components/RichTextEditor/RichTextEditor';

import styles from './ieRichTextEditor.module.less';

type IERichTextEditor = {
  value: any;
  onChange: Function;
  placeholder?: string;
  height?: number;
};
const IERichTextEditor = ({
  value,
  onChange,
  placeholder = 'Add a brief description',
  height,
}: IERichTextEditor) => {
  return (
    <div className={styles.inlineTextEditorWrapper}>
      <RichTextEditor
        initialValue={value}
        height={Number(height) > 280 ? height : 280}
        onChange={(value: any) => onChange(value)}
        placeholder={!!value ? '' : placeholder}
      />
    </div>
  );
};

export default IERichTextEditor;
