import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Divider, Spin, Alert, Collapse } from 'antd';
import {
  useGetPlanDetailsQuery,
  useGetDocumentExtractionByIdQuery,
  useGetDetailedDocumentExtractionByIdQuery,
} from 'modules/renewals/slices/offersSlice';
import { getTheZipFile } from 'modules/plans/services/AiSBCUploaderService';

const { Panel } = Collapse;

const ViewJson = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const offerId = queryParams.get('offerId');
  const { data, error, isLoading } = useGetPlanDetailsQuery({
    offerId: offerId ?? '',
  });

  const [planDetails, setPlanDetails] = useState<any>(null);
  const [jobId, setJobId] = useState<string | null>(null);
  const [documentName, setDocumentName] = useState<string | null>(null);

  useEffect(() => {
    if (data) {
      setPlanDetails(data);
      setJobId(data.extractionDetails.id);
    }
  }, [data]);

  const {
    data: docData,
    error: docError,
    isLoading: docLoading,
  } = useGetDocumentExtractionByIdQuery({ id: jobId ?? '' });
  const {
    data: detailedDocData,
    error: detailedDocError,
    isLoading: detailedDocLoading,
  } = useGetDetailedDocumentExtractionByIdQuery({ id: jobId ?? '' });

  useEffect(() => {
    if (docData) {
      setDocumentName(docData.documentName);
    }
  }, [docData]);

  const renderLoading = (loadingMessage: string) => (
    <Spin tip={loadingMessage} />
  );
  const renderError = (errorMessage: string) => (
    <Alert message="Error" description={errorMessage} type="error" />
  );

  /**
   * Renders a JSON object within a collapsible panel.
   *
   * @param {string} header - The title of the collapsible panel.
   * @param {string} key - The key to be used for the collapsible panel.
   * @param {any} jsonData - The JSON data to be displayed.
   * @return {JSX.Element} The collapsible panel with JSON data.
   */
  const renderJsonCollapsePanel = (
    header: string,
    key: string,
    jsonData: any
  ) => (
    <Collapse>
      <Panel header={header} key={key}>
        <pre>{JSON.stringify(jsonData, null, 2)}</pre>
      </Panel>
    </Collapse>
  );

  /**
   * Renders primary LLM values within a collapsible panel.
   * Each value is split by double newline characters and displayed in a new row.
   *
   * @param {string} values - The primary LLM values as a string.
   * @return {JSX.Element} The collapsible panel with formatted primary LLM values.
   */
  const renderPrimaryLLMValues = (values: string) => (
    <Collapse>
      <Panel header="Primary LLM Values" key="primary-llm">
        <pre>
          {values.split('\n\n').map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </pre>
      </Panel>
    </Collapse>
  );

  /**
   * Renders secondary LLM values within a collapsible panel.
   * Each value is split by backslashes and displayed in a new row.
   *
   * @param {string} values - The secondary LLM values as a string.
   * @return {JSX.Element} The collapsible panel with formatted secondary LLM values.
   */
  const renderSecondaryLLMValues = (values: string) => (
    <Collapse>
      <Panel header="Secondary LLM Values" key="secondary-llm">
        <pre>
          {values.split('\\').map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </pre>
      </Panel>
    </Collapse>
  );

  return (
    <div>
      <h1>View Details</h1>
      <Divider />
      <p>
        <strong>Document Name:</strong> {documentName}
      </p>
      <Divider />
      <p>
        <a onClick={() => jobId && getTheZipFile(jobId)}>
          <strong>Download Zip (link) </strong>
        </a>
      </p>
      <Divider />
      <p>
        <strong>Offer ID:</strong> {offerId}
      </p>
      <Divider />
      <p>
        <strong>Job ID:</strong> {jobId}
      </p>
      <Divider />
      {isLoading && renderLoading('Loading...')}
      {error && renderError(error.toString())}
      {planDetails &&
        renderJsonCollapsePanel('Offer Details', '1', planDetails)}
      {docLoading && renderLoading('Loading document extraction details...')}
      {docError && renderError(docError.toString())}
      {docData &&
        renderJsonCollapsePanel('Document Extraction Details', '2', docData)}
      {detailedDocLoading &&
        renderLoading('Loading detailed document extraction details...')}
      {detailedDocError && renderError(detailedDocError.toString())}
      {detailedDocData && (
        <>
          <Collapse>
            <Panel header="Detailed Document Extraction Details" key="3">
              <pre>{JSON.stringify(detailedDocData, null, 2)}</pre>
            </Panel>
          </Collapse>
          <Divider />
          <div>
            <h2>Plans</h2>
            <Collapse>
              {detailedDocData?.documentExtractionPlanVOS?.map(
                (
                  plan: {
                    planName: string;
                    primaryLLMRawResult: string;
                    secondaryLLMRawResult: string;
                  },
                  index: number
                ) => (
                  <Panel header={plan.planName} key={`plan-${index}`}>
                    <pre>{JSON.stringify(plan, null, 2)}</pre>
                    {plan.primaryLLMRawResult &&
                      renderPrimaryLLMValues(plan.primaryLLMRawResult)}
                    {plan.secondaryLLMRawResult &&
                      renderSecondaryLLMValues(plan.secondaryLLMRawResult)}
                  </Panel>
                )
              )}
            </Collapse>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewJson;
