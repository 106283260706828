import { FC } from 'react';
import { Form, FormInstance, Input } from 'antd';
import isEmpty from 'lodash/isEmpty';

import { EMPTY_MESSAGE } from 'constants/commonConstants';
import InputForm from 'components/InputForm/InputForm';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';

import {
  MINIMUM_PASSWORD_LENGTH,
  PASSWORD_MIN_CHAR_ERROR,
  PASSWORD_MISMATCH_ERROR,
} from 'modules/auth/constants/authConstants';

type Props = {
  form: FormInstance;
  onSubmit: (values: any) => void;
  onSubmitFailed: () => void;
  onValuesChange: (changedValues: any, allValues: any) => void;
  submitButtonText: string;
  loading: boolean;
  passwordLabel?: string;
};

const { Item } = Form;

const CreatePasswordForm: FC<Props> = (props) => {
  const {
    loading,
    form,
    onSubmit,
    onSubmitFailed,
    onValuesChange,
    submitButtonText,
    passwordLabel = 'Password',
  } = props;

  return (
    <>
      <InputForm
        layout="vertical"
        form={form}
        autoComplete="off"
        onFinish={onSubmit}
        onFinishFailed={onSubmitFailed}
        onValuesChange={onValuesChange}
      >
        <Item
          name="password"
          label={passwordLabel}
          required={false}
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (rule, value: string) => {
                if (value && value.length >= MINIMUM_PASSWORD_LENGTH) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(PASSWORD_MIN_CHAR_ERROR));
              },
              validateTrigger: 'onBlur',
            },
            {
              required: true,
              message: EMPTY_MESSAGE,
              validateTrigger: 'onChange',
            },
          ]}
        >
          <Input type="password" autoComplete="new-password" />
        </Item>
        <Item
          name="confirmPassword"
          label="Confirm Password"
          required={false}
          validateTrigger={['onBlur', 'onChange']}
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: EMPTY_MESSAGE,
              validateTrigger: 'onBlur',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (isEmpty(value) || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(PASSWORD_MISMATCH_ERROR));
              },
              validateTrigger: 'onBlur',
            }),
          ]}
        >
          <Input type="password" autoComplete="new-password" />
        </Item>
        <SubmitButton type="primary" htmlType="submit" loading={loading}>
          {submitButtonText}
        </SubmitButton>
      </InputForm>
    </>
  );
};

export default CreatePasswordForm;
