import DBGClient from 'modules/clients/DBGClient/DBGClient';
import {
  BENGUIDE_ACK_NOTIFICATION,
  BENGUIDE_NOTIFICATIONS,
  MEDICAL_PLAN_CHANNEL,
  BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
  DENTAL_PLAN_CHANNEL,
  TELEHEALTH_PLAN_CHANNEL,
  WELLBEING_PLAN_CHANNEL,
  FAMILY_AND_LEAVE_CHANNEL,
  HOLIDAY_AND_TIMEOFF_CHANNEL,
  VOLUNTARY_BENEFITS_CHANNEL,
  LIFE_AND_DISABILITY_CHANNEL,
  VISION_PLAN_CHANNEL,
  ADDITIONAL_PERKS_PLAN_CHANNEL,
  FOUR_O_ONE_K_PLAN_CHANNEL,
  TAX_ADVANTAGED_PLAN_CHANNEL,
} from 'modules/clients/DBGClient/DBGClientConts';
import useDBGClient from 'modules/clients/DBGClient/useDBGClient';
import { BenefitCategory } from 'constants/commonConstants';

interface BenefitGuideNotificationsChannelProps {
  setDisableSave: () => void;
  isPlanEditModalOpen?: boolean;
  benefitKind?: string;
}

const BenefitGuideNotificationsChannel = (
  props: BenefitGuideNotificationsChannelProps
) => {
  const dbgClient = useDBGClient();

  const getChannelBasedOnBenefitKind = (): string => {
    if (props.benefitKind === BenefitCategory.MEDICAL.value) {
      return MEDICAL_PLAN_CHANNEL;
    } else if (props.benefitKind === BenefitCategory.DENTAL.value) {
      return DENTAL_PLAN_CHANNEL;
    } else if (props.benefitKind === BenefitCategory.TELEHEALTH.value) {
      return TELEHEALTH_PLAN_CHANNEL;
    } else if (props.benefitKind === BenefitCategory.WELLBEING.value) {
      return WELLBEING_PLAN_CHANNEL;
    } else if (props.benefitKind === BenefitCategory.FAMILY_AND_LEAVE.value) {
      return FAMILY_AND_LEAVE_CHANNEL;
    } else if (
      props.benefitKind === BenefitCategory.HOLIDAYS_AND_TIME_OFF.value
    ) {
      return HOLIDAY_AND_TIMEOFF_CHANNEL;
    } else if (props.benefitKind === BenefitCategory.ADDITIONAL_PERK.value) {
      return ADDITIONAL_PERKS_PLAN_CHANNEL;
    } else if (props.benefitKind === BenefitCategory.VOL_BENEFITS.value) {
      return VOLUNTARY_BENEFITS_CHANNEL;
    } else if (props.benefitKind === BenefitCategory.LIFE.value) {
      return LIFE_AND_DISABILITY_CHANNEL;
    } else if (props.benefitKind === BenefitCategory.RETIREMENT.value) {
      return FOUR_O_ONE_K_PLAN_CHANNEL;
    } else if (props.benefitKind === BenefitCategory.TAX_ADVANTAGE.value) {
      return TAX_ADVANTAGED_PLAN_CHANNEL;
    }
    return VISION_PLAN_CHANNEL;
  };

  return (
    <DBGClient
      channel={BENGUIDE_NOTIFICATIONS}
      subscribe={(event: string, data: any) => {
        switch (event) {
          case BENGUIDE_ACK_NOTIFICATION:
            props.setDisableSave();
            if (props.isPlanEditModalOpen) {
              dbgClient.postMessage({
                channel: getChannelBasedOnBenefitKind(),
                event: BENGUIDE_NOTIFICATION_HIDE_OVERLAY,
                data: {},
              });
            }
            break;
        }
      }}
    />
  );
};

export default BenefitGuideNotificationsChannel;
