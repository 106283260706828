import {
  ReactNode,
  cloneElement,
  isValidElement,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isObject, isString } from 'lodash';
import useInterval from 'hooks/useInterval';
import { getCurrentNodeOverflow, getStringPosition } from 'util/stringUtil';
import {
  SCROLLING_DELAY,
  TYPING_DELAY,
} from 'modules/assistant/constants/constants';

interface TypeWriterProps {
  children: ReactNode;
  delay?: number;
  onFinish?: () => void;
  animate: boolean;
  layoutRef?: React.RefObject<any>;
}

const TypeWriter: React.FC<TypeWriterProps> = memo(
  ({
    children,
    delay = TYPING_DELAY,
    onFinish = () => {},
    animate,
    layoutRef,
  }: TypeWriterProps) => {
    const [pos, setPos] = useState<number>(0);
    const scrollIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const scrollToBottom = useCallback(() => {
      if (layoutRef?.current) {
        layoutRef.current.scrollToBottom?.();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animate, layoutRef]);

    const startScrollTimeout = () => {
      scrollIntervalRef.current = setTimeout(() => {
        scrollToBottom();
      }, SCROLLING_DELAY);
    };

    const clearScrollTimeout = () => {
      if (scrollIntervalRef.current) {
        clearTimeout(scrollIntervalRef.current);
        scrollIntervalRef.current = null;
      }
    };

    // Calculate the number of tokens in the children nodes
    const tokenLengths = useMemo(() => {
      if (!animate) return [];

      const arr: number[] = [];

      const traverseNodesAndCountTokens = (reactNode: ReactNode): number[] => {
        if (Array.isArray(reactNode)) {
          reactNode.forEach((node) => {
            if (isValidElement(node)) {
              traverseNodesAndCountTokens(node);
            } else if (isString(node)) {
              arr.push(node.split(' ').length);
            }
          });
        } else if (isValidElement(reactNode)) {
          const nodeChildren = reactNode.props.children;
          if (isObject(nodeChildren) || isString(nodeChildren)) {
            traverseNodesAndCountTokens(nodeChildren);
          }
        } else if (isString(reactNode)) {
          arr.push(reactNode.split(' ').length);
        }
        return arr;
      };

      return traverseNodesAndCountTokens(children);
    }, [children, animate]);

    const totalTokens = useMemo(
      () => tokenLengths.reduce((acc, curr) => acc + curr, 0),
      [tokenLengths]
    );

    // Traverse nodes and inject the typewriter effect
    const nodex = useMemo(() => {
      if (!animate) return children;

      let tmpCurrentLoopTokenPos = 0;
      let tmpCurrentLoopNodePos = 0;

      const traverseNodesAndInjectAIWriter = (
        reactNode: ReactNode
      ): ReactNode => {
        if (tmpCurrentLoopTokenPos > pos) {
          return null;
        }

        if (Array.isArray(reactNode)) {
          return reactNode.map((node: ReactNode, index: number) => {
            if (isValidElement(node)) {
              return traverseNodesAndInjectAIWriter(node);
            } else if (isString(node)) {
              tmpCurrentLoopTokenPos += node.split(' ').length;
              tmpCurrentLoopNodePos++;
              const [nodeIndex, currentNodePos] = getCurrentNodeOverflow(
                tokenLengths,
                pos
              );
              if (nodeIndex < tmpCurrentLoopNodePos) {
                return node.slice(
                  0,
                  getStringPosition(node, ' ', currentNodePos)
                );
              }
              return node;
            }
            return node;
          });
        } else if (isValidElement(reactNode)) {
          const nodeChildren = reactNode.props.children;
          if (isObject(nodeChildren) || isString(nodeChildren)) {
            return cloneElement(
              reactNode,
              reactNode.props,
              traverseNodesAndInjectAIWriter(nodeChildren)
            );
          }
        } else if (isString(reactNode)) {
          tmpCurrentLoopTokenPos += reactNode.split(' ').length;
          tmpCurrentLoopNodePos++;
          const [nodeIndex, currentNodePos] = getCurrentNodeOverflow(
            tokenLengths,
            pos
          );
          if (nodeIndex < tmpCurrentLoopNodePos) {
            return reactNode.slice(
              0,
              getStringPosition(reactNode, ' ', currentNodePos)
            );
          }
          return reactNode;
        }
        return reactNode;
      };

      return traverseNodesAndInjectAIWriter(children);
    }, [children, pos, tokenLengths, animate]);

    // Interval to update the position
    useInterval(
      () => {
        if (!animate) return;
        startScrollTimeout();

        setPos((prevPos) => {
          if (prevPos + 1 >= totalTokens) {
            clearScrollTimeout();
            onFinish();
          }

          return prevPos + 1;
        });
      },
      totalTokens > pos && animate ? delay : null
    );

    // Reset position when children change
    useEffect(() => {
      if (!animate) return;
      setPos(0);
    }, [children, animate]);

    return <>{nodex}</>;
  }
);

TypeWriter.displayName = 'TypeWriter';

export default TypeWriter;
