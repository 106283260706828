import Carrier from 'model/Carrier';
import PlanYear from 'model/PlanYear';
import BenefitCarrier from 'model/BenefitCarrier';
import FundingType from 'modules/plans/enums/FundingType';
import SFCPlanConfig from './SFCPlanConfig';
import EmployerContribution from './EmployerContribution';

export class Plan {
  id?: string;
  employerId: string;
  planYearId: string;
  name: string;
  startDate: string;
  endDate: string;
  source?: string;
  createdTs?: number;
  lastUpdatedTs?: number;
  revision?: number;
  latest?: boolean;
  groups: string[];
  carrier?: Carrier;
  planYear?: PlanYear;
  states: string[];
  documentReferences: any;
  documents: any;
  additionalDocuments: any;
  additionalDocumentReferences: any;
  additionalWeblinks: any;
  benefitCarrierId: string;
  benefitCarrier: BenefitCarrier;
  fundingType: FundingType | undefined;
  sfcPlanConfigVo: SFCPlanConfig;
  sfcPlanDataVO?: any;
  groupId?: string;
  benefitKind?: string;
  tierType?: string;
  tierCount?: number;
  employerContribution?: EmployerContribution;
  parentId?: string;
  category?: string;
  parentPlan?: any;
  initialPlanId?: string;
  hraPlanId?: string;
  planChangedFieldTypes?: string[];
  hraUtilization?: number;
  enrollment?: number;
  volume?: number;

  constructor() {
    this.id = '';
    this.employerId = '';
    this.planYearId = '';
    this.name = '';
    this.startDate = '';
    this.endDate = '';
    this.source = '';
    this.revision = 0;
    this.latest = false;
    this.groups = [];
    this.states = [];
    this.carrier = {} as Carrier;
    this.documentReferences = {};
    this.benefitCarrier = {} as BenefitCarrier;
    this.benefitCarrierId = '';
    this.sfcPlanConfigVo = new SFCPlanConfig();
    this.sfcPlanDataVO = {};
    this.groupId = '';
    this.benefitKind = '';
    this.planChangedFieldTypes = [];
    this.additionalDocumentReferences = {};
  }
}
