import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { isEmpty } from 'lodash';
import { Checkbox, Empty, Popover, Row, Select } from 'antd';
import SearchBar from 'components/SearchBar/SearchBar';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import {
  DENTAL,
  MEDICAL,
  VISION,
} from 'modules/plans/planRates/pages/PlanRates/PlanRates';
import { removeSpecificItemFromStringArray } from 'util/arrayUtil';
import styles from './multiSelectGoup.module.less';

const { Option, OptGroup } = Select;

type Props = {
  searchPlaceholder: string;
  options: OptionProps[];
  optionSort?: boolean;
  defaultOptions: any[];
  resetOptions: any[];
  selectName: string;
  listHeight?: number;
  isLoading?: boolean;
  handleOkayCallback: (value: string[], selectName: string) => void;
  handleResetCallback: (value: string[], selectName: string) => void;
  isReview?: boolean;
};

type OptionProps = {
  label: string;
  value: any;
  group: string;
  tier: string;
  tierCount: number;
  isDisabled: boolean;
};

const MultiSelectGroup = forwardRef((props: Props, ref) => {
  const {
    selectName,
    searchPlaceholder,
    optionSort = true,
    defaultOptions = [],
    resetOptions = [],
    isLoading,
    options,
    listHeight = 350,
    handleOkayCallback,
    handleResetCallback,
  } = props;

  const selectRef = useRef<any>(null);
  const [searchText, setSearchText] = useState('');
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const [tempSelectedValues, setTempSelectedValues] = useState<any[]>([]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const handleOkay = () => {
    setTempSelectedValues(selectedValues);
    handleOkayCallback(selectedValues, String(selectName));
    setSearchText('');
    setShowDropdown(false);
  };

  const handleReset = () => {
    setSelectedValues(resetOptions);
    setTempSelectedValues(resetOptions);
    handleResetCallback(resetOptions, String(selectName));
    setSearchText('');
    setShowDropdown(false);
  };

  const handleOnSelect = (value: any) => {
    if (selectedValues.includes(value)) {
      const removeDeselectedValue = removeSpecificItemFromStringArray(
        selectedValues,
        value
      );
      return setSelectedValues([...removeDeselectedValue]);
    }
    return setSelectedValues([...selectedValues, value]);
  };

  const handleDeselect = (value: any) => {
    const removeDeselectedValue = removeSpecificItemFromStringArray(
      selectedValues,
      value
    );
    return setSelectedValues([...removeDeselectedValue]);
  };

  useImperativeHandle(ref, () => ({
    reset: () => {
      handleReset();
    },
  }));

  const getFilteredOption = (options: OptionProps[]) => {
    const optionsArray: OptionProps[] = options?.filter(({ label }) =>
      label?.toLowerCase()?.includes(searchText?.toLowerCase().trim())
    );
    return optionsArray;
  };

  const getSortOptions = (options: any[]) => {
    let optionsArray: OptionProps[] = [];
    if (optionSort) {
      optionsArray = options?.sort((a, b) => a?.label?.localeCompare(b?.label));
    } else {
      optionsArray = options;
    }
    return optionsArray;
  };

  const getFilteredOptions = () => {
    const groupedOptions = getFilteredOption(getSortOptions(options)).reduce(
      (groups, option) => {
        const MDVgroup = option.group;
        if (MDVgroup !== undefined) {
          groups[MDVgroup] = groups[MDVgroup] || [];
          groups[MDVgroup].push(option);
        }
        return groups;
      },
      {} as Record<string, OptionProps[]>
    );

    const groupOrder = [MEDICAL, DENTAL, VISION];

    return groupOrder?.map((groupLabel, idx) => (
      <>
        {!isEmpty(groupedOptions[groupLabel]) && (
          <OptGroup key={idx} label={groupLabel}>
            {groupedOptions[groupLabel]?.map((option, idx) => (
              <Option
                key={option.value ?? idx}
                disabled={option.isDisabled}
                value={option?.value}
                label={option?.label}
              >
                {option.isDisabled ? (
                  <Popover
                    content={'This plan is already tagged to another HRA plan'}
                    overlayClassName={'popoverInner recommendedtooltip'}
                  >
                    <div className={styles.selectContainer}>
                      <Checkbox
                        disabled={option.isDisabled}
                        checked={selectedValues?.includes(option.value)}
                      />{' '}
                      {option?.label}
                    </div>
                  </Popover>
                ) : (
                  <div className={styles.selectContainer}>
                    <Checkbox
                      checked={selectedValues?.includes(option.value)}
                    />{' '}
                    {option?.label}
                  </div>
                )}
              </Option>
            ))}{' '}
          </OptGroup>
        )}
      </>
    ));
  };

  const tagRenderComp = (tag: any) => {
    return (
      <div className={styles.tags}>
        <span className={styles.tagSpan}>{tag.label}</span>
      </div>
    );
  };
  const maxRenderComp = (values: any) => {
    return (
      <div className={styles.maxTagPlaceholderTags}>
        <span className={styles.maxTagPlaceholderTagsSpan}>
          + {values.length}
        </span>
      </div>
    );
  };

  const getSelectedOptions = options
    ?.filter((item) => selectedValues?.includes(item?.value))
    ?.map((item) => item?.label)
    ?.toString()
    ?.split(',')
    ?.join(', ');

  const checkTierMatch = (selectedValues: any): boolean => {
    const tiers: Record<string, string> = {};
    const tierCounts: Record<string, number> = {};

    for (const option of options) {
      if (option.tier === 'N_TIER') {
        tierCounts[option.value] = option.tierCount;
      }
      tiers[option.value] = option.tier;
    }
    // Check if selected IDs have the same tier
    const uniqueTiers = new Set(selectedValues?.map((id: string) => tiers[id]));
    const uniqueTiersCount = new Set(
      selectedValues?.map((id: string) => tierCounts[id])
    );
    return uniqueTiers?.size > 1 || uniqueTiersCount?.size > 1;
  };

  const dropdownRenderComp = (menu: React.ReactNode) => {
    return (
      <>
        <div className={styles.searchFilter}>
          <SearchBar
            placeholder={searchPlaceholder}
            isLarge={false}
            value={searchText}
            onChange={(e) => setSearchText(e?.target?.value?.trimStart())}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </div>
        {menu}
        <Row justify="center">
          {checkTierMatch(selectedValues) ? (
            <Popover
              content={'Selected plan(s) have different tier structures.'}
              overlayClassName={'popoverInner recommendedtooltip'}
            >
              <span>
                <SubmitButton
                  type="primary"
                  className={styles.confirmButton}
                  disabled={checkTierMatch(selectedValues)}
                >
                  Okay
                </SubmitButton>
              </span>
            </Popover>
          ) : (
            <SubmitButton
              type="primary"
              className={styles.confirmButton}
              disabled={checkTierMatch(selectedValues)}
              onClick={handleOkay}
            >
              Okay
            </SubmitButton>
          )}
        </Row>
        <Row justify="center">
          <span className={styles.cancelButton} onClick={handleReset}>
            Reset
          </span>
        </Row>
      </>
    );
  };

  useEffect(() => {
    setSelectedValues(defaultOptions);
  }, [defaultOptions]);

  return (
    <Popover
      content={getSelectedOptions}
      placement="top"
      overlayClassName={
        getSelectedOptions && !showDropdown
          ? styles.basicPlanPopoverInner
          : styles.noBasicPlanPopoverInner
      }
    >
      <div className={styles.filterWrapper}>
        <Select
          ref={selectRef}
          mode="multiple"
          className={styles.filter}
          optionLabelProp="label"
          direction="ltr"
          maxTagCount="responsive"
          maxTagPlaceholder={maxRenderComp}
          listHeight={listHeight}
          showArrow={true}
          showSearch={false}
          placement="bottomRight"
          dropdownClassName={styles.dropdownWrapper}
          dropdownRender={dropdownRenderComp}
          tagRender={tagRenderComp}
          value={selectedValues}
          onSelect={handleOnSelect}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No HRA eligible plans available"
            />
          }
          onDeselect={handleDeselect}
          onInputKeyDown={(e: any) => {
            if (e.keyCode === 8 && e.target.value.length === 0) {
              return e.stopPropagation();
            }
          }}
          open={showDropdown}
          onDropdownVisibleChange={(open) => {
            setShowDropdown(open);
            if (!open) {
              setSelectedValues(tempSelectedValues);
            } else {
              setTempSelectedValues(selectedValues);
              selectRef?.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            }
          }}
          getPopupContainer={(trigger: any) =>
            trigger.parentElement.parentElement
          }
          loading={isLoading}
        >
          {getFilteredOptions()}
        </Select>
      </div>
    </Popover>
  );
});

MultiSelectGroup.displayName = 'MultiSelectGroup';

export default MultiSelectGroup;
