import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Badge, Col, Dropdown, Input, Menu, notification, Row } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { CloseOutlined } from '@ant-design/icons';
import { isEmpty, trim } from 'lodash';
import dayjs from 'dayjs';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import AlertMessage from 'components/Alert/AlertMessage';
import InputFileUpload from 'components/InputFileUpload/InputFileUpload';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import { FORMAT_VALIDATE, TOTAL_UPLOAD_SIZE } from 'util/commonUtil';
import { internalNotesAllowedFiles } from 'modules/renewals/utils/renewalsUtils';
import { getFileUploadErrorMessages } from 'util/fileUtil';
import { CommonNoteDto } from 'modules/renewals/models/CommonNoteDto';
import CustomerSpinner from 'components/Spinner/CustomSpinner';
import { ReactComponent as AttachmentClip } from 'assets/images/attachment-clip.svg';
import { buildFullName, buildInitials } from 'util/stringUtil';
import styles from './notesCommonContent.module.less';

const { TextArea } = Input;

type NotesCommonContentProps = {
  noteName: string;
  isCreateLoading: boolean;
  handleSubmit: (commonNoteDto: CommonNoteDto) => void;
  isFetchLoading: boolean;
  listOfComments: any[];
  individualId: string;
  visibilityToggle: boolean;
  handleFileItemClick: (s3Key: string, fileName: string) => void;
  notesHeading: string;
};
type NotificationType = 'success' | 'info' | 'warning' | 'error';

const openNotificationWithIcon = (
  type: NotificationType,
  validateSetting: string
) => {
  let description: any = getFileUploadErrorMessages(validateSetting).message;
  if (FORMAT_VALIDATE === validateSetting) {
    description = (
      <Col>
        <span>
          Invalid file type, please attach document in doc, pdf or xlsx (excel)
          format
        </span>
      </Col>
    );
  }
  if (TOTAL_UPLOAD_SIZE === validateSetting) {
    description = (
      <Col>
        <span>
          The submitted file exceeds the file size limit of 100 MB. Please
          confirm that you are uploading the correct file or reduce the file’s
          size
        </span>
      </Col>
    );
  }
  notification[type]({
    closeIcon: <></>,
    top: 40,
    message: getFileUploadErrorMessages(validateSetting).title,
    description: description,
    icon: <CloseOutlined className={styles.notificationErrorIcon} />,
  });
};

const NotesCommonContent: FC<NotesCommonContentProps> = ({
  noteName,
  isCreateLoading = false,
  handleSubmit,
  isFetchLoading = true,
  listOfComments,
  individualId,
  visibilityToggle,
  handleFileItemClick,
  notesHeading,
}: NotesCommonContentProps) => {
  const [attachments, setAttachments] = useState<UploadFile[]>([]);
  const [txtContent, setTxtContent] = useState<string>('');

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTxtContent(e.target.value);
  };

  const inputRef = useRef<any>(null);

  useEffect(() => {
    setTxtContent('');
    resetAttachments();
  }, [visibilityToggle]);

  const resetAttachments = () => {
    if (inputRef.current) {
      inputRef.current.reset();
    }
    setAttachments([]);
  };

  const handleSubmitClick = () => {
    const commonNoteDto: CommonNoteDto = {
      commentTxt: txtContent,
      fileList: attachments,
    };
    handleSubmit(commonNoteDto);
    setTxtContent('');
    resetAttachments();
  };

  const isViewedByTheUser = (viewedUsers: any[]): boolean => {
    return viewedUsers.includes(individualId);
  };

  const adminActionsMenu = (attachments: any[]) => {
    return (
      <Menu>
        {attachments.map((attachment) => {
          return (
            <Menu.Item
              key={attachment.downloadUrl}
              onClick={() => {
                handleFileItemClick(
                  attachment.downloadUrl,
                  attachment.fileName
                );
              }}
            >
              <span className={styles.noteAttachmentDropDownItem}>
                {attachment.fileName}
              </span>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  return isFetchLoading ? (
    <CustomerSpinner />
  ) : (
    <div className={styles.notesCommon}>
      <p className={styles.notedHeader}>{noteName}</p>
      <p className={styles.subHeader}>{notesHeading}</p>
      <div className={styles.alertPopUp}>
        <AlertMessage
          type="warning"
          message={'Notes posted here are only viewable by broker admins'}
          closeAlert={() => {}}
          closable={false}
        />
      </div>
      <p className={styles.commentHeader}>Add a Comment</p>
      <TextArea
        autoSize={{ minRows: 6, maxRows: 6 }}
        onChange={handleTextChange}
        value={txtContent}
      />

      <SubmitButton
        key="submit"
        type="primary"
        className={styles.submitBtn}
        disabled={isEmpty(trim(txtContent))}
        loading={isCreateLoading}
        onClick={handleSubmitClick}
      >
        Post
      </SubmitButton>
      <div>
        <InputFileUpload
          allowedFileTypes={internalNotesAllowedFiles}
          getFileList={(fileList) => setAttachments(fileList)}
          uploadIconLabel="+ Include Attachment"
          totalUploadSizeMB={100}
          onValidateFails={(validateSetting) => {
            openNotificationWithIcon('error', validateSetting);
          }}
          ref={inputRef}
        />
      </div>

      {listOfComments.map((comment) => {
        return (
          <div className={styles.individualComment} key={comment.id}>
            <Row>
              <Col span={2}>
                <ProfileAvatar
                  src={comment.avatarUrl}
                  content={buildInitials(comment.firstName, comment.lastName)}
                />
              </Col>
              <Col span={20}>
                <p className={styles.commentPersonHeader}>
                  {buildFullName(
                    comment.firstName || '',
                    comment.lastName || ''
                  )}
                  {!isViewedByTheUser(comment.viewedUsers) && (
                    <Badge color={'red'} className={styles.newComment} />
                  )}
                </p>
              </Col>
              <Col span={20} className={styles.commentContent}>
                {comment.comment}
              </Col>
            </Row>
            <Row className={styles.commentFooter}>
              <Col span={10}>
                <p className={styles.commentModified}>
                  {dayjs(comment.initialCreatedTs).format(
                    'MMM D, YYYY [at] h:mm A'
                  )}
                </p>
              </Col>
              {!isEmpty(comment.attachments) && (
                <Col span={14}>
                  <Dropdown
                    trigger={['click']}
                    overlay={adminActionsMenu(comment.attachments)}
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                        ? triggerNode.parentElement
                        : triggerNode
                    }
                  >
                    <p
                      className={styles.attachmentLabel}
                      onClick={(e) => e.preventDefault()}
                    >
                      <AttachmentClip className={styles.attachmentClipIcon} />
                      {comment.attachments.length} Attachments
                    </p>
                  </Dropdown>
                </Col>
              )}
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default NotesCommonContent;
