import { ReactNode, useEffect, useRef, useState } from 'react';

import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { ERROR_MESSAGE_PLAN_EDITING } from 'constants/benguideCollaborationConstants';
import DBGClient from 'modules/clients/DBGClient/DBGClient';
import {
  BENGUIDE_ACK_NOTIFICATION,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  BENGUIDE_SAVE_COMPLETED,
  DENTAL_PLAN_CHANNEL,
  EXIT_BENGUIDE,
  OPEN_ADD_NEW_PLAN_MODAL,
  OPEN_BASIC_INFO_MODAL,
  OPEN_DEDUCTIBLE_MODAL,
  OPEN_RATES_MODAL,
  OPEN_SERVICE_MODAL,
  RELOAD_DENTAL_PLAN,
  CLOSE_MODALS,
} from 'modules/clients/DBGClient/DBGClientConts';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useDBGClient from 'modules/clients/DBGClient/useDBGClient';
import {
  findDentalPlanById,
  saveDentalPlan,
} from 'modules/plans/slices/dentalPlanSlice';
import PanelSection from 'modules/plans/enums/PanelSection';
import iconInfo from 'assets/images/icon-info.svg';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ServiceDBGWrapper from 'modules/plans/dental/components/Services/ServicesDBGWrapper';
import DeductiblesOOPMaxDBGWrapper from 'modules/plans/dental/components/DeductiblesOOPMax/DeductiblesOOPMaxDBGWrapper';
import DentalBasicPlanInfoDBGWrapper from 'modules/plans/dental/components/PlanBasicInfo/DentalBasicPlanInfoDBGWrapper';
import { changedBenguideModalState } from 'modules/benefitGuide/slices/benguideSlice';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import {
  BenefitCategory,
  exitWithoutSavingMsg,
} from 'constants/commonConstants';
import RatesDBGDentalWrapper from 'modules/plans/components/Rates/RatesDBGDentalWrapper';
import AddPlanModal from 'modules/plans/components/AddPlanModal/AddPlanModal';
import BenefitGuideNotificationsChannel from './BenefitGuideNotificationsChannel';

import styles from './medicalPlansChannel.module.less';

type EventComponent = {
  component: ReactNode;
  title: string;
  props?: object;
};

type EventDataType = {
  item: {
    value: string;
    label: string;
  };
};

const DentalPlansChannel = () => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [eventData, setEventData] = useState<EventDataType>();
  const [subscribedEvent, setSubscribedEvent] = useState<string>('');
  const [disableSave, setDisableSave] = useState(false);
  const [isRenewalStarted, setIsRenewalStarted] = useState(false);
  const [planYearId, setPlanYearID] = useState<string>('');
  const [dbgMetaData, setDbgMetaData] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const dentalPlanRef = useRef<any>();
  const dentalPlanRefServices = useRef<any>();
  const { planYearsList } = useAppSelector((state) => state.employer.employer);
  const { employer } = useNavContext();
  const [getUpcomingPlanYears, { data: upcomingData }] =
    useLazyGetUpcomingPlanYearsByEmployerQuery();

  const resetPlanRef = () => {
    dentalPlanRef?.current?.reset();
    dentalPlanRefServices?.current?.reset();
    if ((eventData as any).id) {
      dispatch(findDentalPlanById((eventData as any).id));
    }
  };

  const cancelModal = () => {
    setModalVisible(false);
  };

  const cancelModalWhenExit = () => {
    cancelModal();
    dispatch(changedBenguideModalState(false));
  };

  const cancelModalWhenRefresh = () => {
    cancelModal();
    dbgClient.postMessage({
      channel: DENTAL_PLAN_CHANNEL,
      event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
      data: {},
    });
  };

  const eventComponentMapper = {
    [OPEN_DEDUCTIBLE_MODAL]: {
      component: DeductiblesOOPMaxDBGWrapper,
      title: 'Edit Deductibles & Maximums',
      width: 560,
    } as EventComponent,
    [OPEN_BASIC_INFO_MODAL]: {
      component: DentalBasicPlanInfoDBGWrapper,
      title: 'Edit Basic Info',
      width: 830,
      props: {
        ref: dentalPlanRef,
      },
    } as EventComponent,
    [OPEN_SERVICE_MODAL]: {
      component: ServiceDBGWrapper,
      title: 'Edit Services',
      props: {
        type: PanelSection.SERVICES,
        wrappedRef: dentalPlanRefServices,
      },
      width: 700,
    } as EventComponent,
    [OPEN_ADD_NEW_PLAN_MODAL]: {
      component: AddPlanModal,
      title: 'Add Plan',
      props: {
        benefit: 'DENTAL',
        isOpen: isModalVisible,
        onClose: () => {
          setModalVisible(false);
        },
        dbgPlanYear: planYearId,
        isDisable: disableSave,
        isRenewalProcessStarted: isRenewalStarted,
      },
      useInbuiltModal: true,
    } as EventComponent,
    [OPEN_RATES_MODAL]: {
      component: RatesDBGDentalWrapper,
      title: 'Edit Premiums & Contributions',
      width: 800,
      props: {
        ref: dentalPlanRef,
      },
    } as EventComponent,
  } as any;

  const {
    component: Component,
    title,
    props,
    useInbuiltModal,
    width,
  } = eventComponentMapper[subscribedEvent] || {};

  const dispatch = useAppDispatch();
  const plan = useAppSelector((state) => state.plan.dentalPlan);
  const { dentalPlan, inProgress } = plan;
  const dbgClient = useDBGClient();

  useEffect(() => {
    setLoading(inProgress);
  }, [inProgress]);

  useEffect(() => {
    if (employer) {
      dispatch(getPlanYears(employer?.id ?? ''));
      getUpcomingPlanYears({ employerId: employer?.id ?? '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employer?.id]);

  useEffect(() => {
    const isPlanYearInList = !!planYearsList?.find(
      (planYear: any) => planYear.id === planYearId
    )?.current;

    const hasUpcomingPlanYearWithNullId = upcomingData?.upcomingPlanYears?.some(
      (obj: any) => obj.planYearId === null
    );

    const isRenewalStarted = isPlanYearInList && hasUpcomingPlanYearWithNullId;

    setIsRenewalStarted(isRenewalStarted ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcomingData, planYearsList]);

  const savePlan = async () => {
    if (
      (subscribedEvent === OPEN_BASIC_INFO_MODAL &&
        (await dentalPlanRef?.current?.isValidForm())) ||
      subscribedEvent !== OPEN_BASIC_INFO_MODAL
    ) {
      setLoading(true);
      dispatch(
        saveDentalPlan(dentalPlan, () => {
          dbgClient.postMessage({
            channel: DENTAL_PLAN_CHANNEL,
            event: RELOAD_DENTAL_PLAN,
            data: dentalPlan,
          });
        })
      );
    }
  };

  return (
    <>
      <DBGClient
        channel={DENTAL_PLAN_CHANNEL}
        subscribeCommon={(event: string) => {
          if (CLOSE_MODALS === event) {
            setModalVisible(false);
            setLoading(false);
          }
        }}
        subscribe={(event: string, data: any, dbgMetaData: any) => {
          switch (event) {
            case EXIT_BENGUIDE:
              cancelModalWhenExit();
              break;
            case BENGUIDE_SAVE_COMPLETED:
              cancelModal();
              break;
            case BENGUIDE_ACK_NOTIFICATION:
              setDisableSave(true);
              break;
            default:
              setSubscribedEvent(event);
              setEventData(data);
              setDbgMetaData(dbgMetaData);
              setModalVisible(true);
              setDisableSave(false);
              setPlanYearID(dbgMetaData?.planYearId);
              break;
          }
        }}
      />
      <BenefitGuideNotificationsChannel
        setDisableSave={() => setDisableSave(true)}
        isPlanEditModalOpen={isModalVisible}
        benefitKind={BenefitCategory.DENTAL.value}
      />
      {Component && useInbuiltModal ? (
        <FullScreenModal
          visible={isModalVisible}
          onCancelConfirm={cancelModal}
          confirmTitle={
            <>
              Are you sure you want to close?
              <p>{exitWithoutSavingMsg}</p>
            </>
          }
          showConfirm
          footer={false}
          title={`${employer?.name} - Add Dental Plan`}
          className={styles.mediaScreen}
          destroyOnClose={true}
        >
          <Component {...props} />
        </FullScreenModal>
      ) : (
        <Modal
          width={width}
          title={<strong>{title}</strong>}
          visible={isModalVisible}
          onCancel={cancelModal}
          okText="Done"
          footer={false}
          className={styles.medicalPlanChannelModal}
          closable={false}
          maskClosable={false}
          destroyOnClose={true}
          afterClose={resetPlanRef}
        >
          <div className={styles.medicalPlanChannelWrapper}>
            <div className={styles.leftBorder} />
            <div className={styles.medicalPlanChannel}>
              <div>
                <img src={iconInfo} alt="Icon Info" />
              </div>
              <div className={styles.defaultText}>
                Changing plan information here will change the plan information
                everywhere this plan is shown
              </div>
            </div>
            {disableSave && (
              <FixedAlertMessage
                type={'error'}
                message={ERROR_MESSAGE_PLAN_EDITING}
              />
            )}
            {Component && (
              <Component
                dbgMetaData={dbgMetaData}
                dentalPlan={eventData}
                isRenewalProcessStarted={isRenewalStarted}
                {...props}
              />
            )}
            <div className={styles.buttons}>
              <Button
                onClick={disableSave ? cancelModalWhenRefresh : cancelModal}
                className={styles.cancel}
              >
                Cancel
              </Button>
              <Button
                disabled={disableSave}
                onClick={savePlan}
                className={disableSave ? styles.disabled : styles.done}
                loading={loading}
              >
                Done
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DentalPlansChannel;
