import { ChangeEvent, FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Menu } from 'antd';
import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';

import {
  createRenderColumns,
  DataColumn,
} from 'components/DataTable/DataColumn';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import InfoBanner from 'components/InfoBanner/InfoBanner';
import SearchBar from 'components/SearchBar/SearchBar';
import DataTable from 'components/DataTable/DataTable';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';

import TablePagination from 'model/TablePagination';
import BenefitsConsultant from 'model/benefitsConsultation/BenefitsConsultant';

import AssignERBrokerConsultants from 'modules/benefitsConsultation/pages/AssignERBrokerConsultants/AssignERBrokerConsultants';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  assignUnassignConsultantsSuccess,
  getEmployerConsultantsList,
  manageERBrokerConsultants,
} from 'modules/benefitsConsultation/slices/employerLevelSlice';
import { ConsultationTypes } from 'modules/benefitsConsultation/constants/benefitsConsultation';
import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
  mapValueToLabel,
} from 'util/commonUtil';
import { buildInitials } from 'util/stringUtil';
import consultationIcon from 'assets/images/consultation-large.svg';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { CONSULTANT_BROKER_LEVEL_EMPLOYER_CONFIRMATION } from 'modules/admins/constants/adminConstants';
import EmployerFeatureDisabledBanner from 'modules/tools/components/EmployerFeatureDisabledBanner/EmployerFeatureDisabledBanner';
import { FEATURE_KEYS } from 'constants/commonConstants';
import styles from './brokerConsultation.module.less';

const convertBrokerConsultantsForTableRender = (
  brokerConsultants: BenefitsConsultant[]
): any[] => {
  if (brokerConsultants != null) {
    return brokerConsultants.map((element) => ({
      key: element.id,
      consultant: element,
      email: element.email,
      type: element.type,
      actions: element,
    }));
  }
  return [];
};

const BrokerConsultation: FC = () => {
  const dispatch = useAppDispatch();

  const employerBrokerConsultants = useAppSelector(
    (state) =>
      state.benefitsConsultation.employerLevel.employerBrokerConsultants
  );

  const brokerConsultantsList = useAppSelector(
    (state) => state.benefitsConsultation.employerLevel.brokerConsultantsList
  );

  const requestType = useAppSelector(
    (state) => state.benefitsConsultation.employerLevel.requestType
  );

  const inProgress = useAppSelector(
    (state) => state.benefitsConsultation.employerLevel.inProgress
  );
  const employerName = useAppSelector((state) => state.layout.employer?.name);
  const employerId = useAppSelector((state) => state.layout.employer?.id);
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const organizationId = useAppSelector(
    (state) => state.layout.employer?.organizationId
  );

  const [assignConsultantsModalVisible, setAssignConsultantsModalVisible] =
    useState<boolean>(false);
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState<boolean>(false);
  const [updateTempConsultants, setUpdateTempConsultants] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  useEffect(() => {
    if (organizationId && employerId) {
      dispatch(
        getEmployerConsultantsList(
          convertPaginationConfig(paginationConfig),
          organizationId,
          employerId
        )
      );
    }
  }, [dispatch, paginationConfig, organizationId, employerId]);

  useEffect(() => {
    if (
      organizationId &&
      employerId &&
      !assignConsultantsModalVisible &&
      requestType === assignUnassignConsultantsSuccess.type
    ) {
      dispatch(
        getEmployerConsultantsList(
          convertPaginationConfig(paginationConfig),
          organizationId,
          employerId
        )
      );
    }
  }, [
    dispatch,
    paginationConfig,
    organizationId,
    employerId,
    assignConsultantsModalVisible,
    requestType,
  ]);

  const { content, metadata } = employerBrokerConsultants || {};

  const { total } = metadata;
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  const getData = (filters: TablePagination) => {
    if (filters.sorterInfo) {
      const paginationConfigData = {
        sorterInfo: filters.sorterInfo,
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      };
      setPaginationConfig(paginationConfigData);
    } else {
      const paginationConfigData = {
        sorterInfo: {
          columnKey: 'name',
          field: 'name',
          order: 'ascend',
        },
        paginationIndex: filters.paginationIndex,
        filterInfo: {
          limit: filters.filterInfo.limit,
          offset: filters.filterInfo.offset,
          searchText: searchText,
        },
        filters: filters.filters,
      } as TablePagination;
      setPaginationConfig(paginationConfigData);
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setSearchText(_searchText);
    const pageInfo = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText: _searchText },
    };
    debounceLoadData(pageInfo);
  };

  const brokerConsultantsTableColumns: DataColumn[] = [
    {
      title: 'CONSULTANT',
      dataIndex: 'consultant',
      key: 'consultant',
      sorter: (a: any, b: any) =>
        a.consultant.fullName.localeCompare(b.consultant.fullName),
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: 'ascend',
      render: (consultant) => {
        const firstName = consultant.fullName.split(' ')[0] || '';
        const lastName = consultant.fullName.split(' ')[1] || '';
        return (
          <div className={styles.name}>
            <div className={styles.avatar}>
              <ProfileAvatar
                content={buildInitials(firstName, lastName)}
                src={consultant.avatarUrl}
              />
            </div>

            <span className={styles.nameColStyle}>
              <OverflowPopover
                popoverContent={consultant.fullName}
                maxWidth="240px"
              >
                {consultant.fullName}
              </OverflowPopover>
            </span>
          </div>
        );
      },
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      ellipsis: {
        showTitle: false,
      },
      render: (email) => (
        <OverflowPopover popoverContent={email}>{email}</OverflowPopover>
      ),
    },
    {
      title: 'CONSULTANT TYPE',
      dataIndex: 'type',
      key: 'type',
      render: (type) => <>{mapValueToLabel(type, ConsultationTypes)}</>,
    },
  ];
  if (appBootInfo?.type !== 'ER_ADMIN') {
    brokerConsultantsTableColumns.push({
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '15%',
      render: (consultant: BenefitsConsultant) => {
        return (
          <div className={styles.dropdownWrapper}>
            <SelectDropdown overlay={brokerActions(consultant)} />
          </div>
        );
      },
      align: 'left',
    });
  }

  const brokerActions = (consultant: BenefitsConsultant) => {
    const unassignedConsultant: any = pick(consultant, [
      'fullName',
      'email',
      'id',
      'individualId',
      'type',
    ]);
    unassignedConsultant.organizationId = organizationId;
    unassignedConsultant.employerId =
      consultant.type === ConsultationTypes.BROKER_EXTERNAL.value
        ? null
        : employerId;
    unassignedConsultant.isConsultant = true;
    unassignedConsultant.isAssigned = false;

    const updatedList = brokerConsultantsList.filter(
      (consultant) => consultant.id !== unassignedConsultant.id
    );
    updatedList.push(unassignedConsultant);

    return (
      <Menu>
        <Menu.Item
          key="unassign"
          onClick={() => {
            const filteredUpdatedList = updatedList.filter(
              (obj) => obj.isAssigned
            );
            if (filteredUpdatedList.length >= 1) {
              employerId &&
                dispatch(manageERBrokerConsultants(updatedList, employerId));
            } else {
              setConfirmationModalVisible(true);
              setUpdateTempConsultants(updatedList);
            }
          }}
        >
          Unassign Consultant
        </Menu.Item>
      </Menu>
    );
  };

  const confirmUnassigned = () => {
    employerId &&
      dispatch(manageERBrokerConsultants(updateTempConsultants, employerId));
    setConfirmationModalVisible(!confirmationModalVisible);
  };

  const toggleConfirmationAssignPopup = () => {
    setConfirmationModalVisible(!confirmationModalVisible);
    setUpdateTempConsultants([]);
  };

  const renderDataTable = () => (
    <Fragment>
      <div className={styles.searchBar}>
        <SearchBar
          placeholder="Search Broker Consultants"
          onChange={handleSearch}
        />
      </div>
      <div className={styles.summaryTable}>
        <DataTable
          currentIndex={paginationConfig.paginationIndex}
          data={convertBrokerConsultantsForTableRender(content)}
          columns={createRenderColumns(brokerConsultantsTableColumns)}
          pagination
          getData={getData}
          total={total}
          pageSize={limit}
          loading={inProgress}
        />
      </div>
    </Fragment>
  );

  return (
    <>
      <EmployerFeatureDisabledBanner
        feature={FEATURE_KEYS.BENEFIT_CONSULTATION}
        featureName="Broker Consultants"
        employerId={employerId}
      />
      <div className={styles.brokerConsultationWrapper}>
        <div className={styles.headerSection}>
          <div className={styles.heading}>
            <h1>Broker Consultants</h1>
          </div>
          {appBootInfo?.type !== 'ER_ADMIN' && (
            <PageActionButton
              type="primary"
              onClick={() => {
                setAssignConsultantsModalVisible(true);
              }}
              className={`${styles.actionButton}`}
            >
              Assign Broker Consultants
            </PageActionButton>
          )}
        </div>
        {!inProgress && isEmpty(content) && isEmpty(searchText) ? (
          <InfoBanner
            title={`You have no Broker Consultants assigned to ${employerName}!`}
            description="Get started by assigning the first Consultant."
            logo={<img src={consultationIcon} alt="next-icon" />}
            className={styles.infoBanner}
          />
        ) : (
          renderDataTable()
        )}
        <AssignERBrokerConsultants
          visible={assignConsultantsModalVisible}
          setVisible={setAssignConsultantsModalVisible}
          employerId={employerId}
          employerName={employerName}
          organizationId={organizationId}
        />

        <ConfirmationDialog
          title="Unassign Broker Consultant"
          confirmText="Yes - Unassign Consultant"
          cancelText="Cancel"
          closeModal={toggleConfirmationAssignPopup}
          onConfirm={confirmUnassigned}
          visible={confirmationModalVisible}
        >
          <div className={styles.warningConfirmation}>
            {CONSULTANT_BROKER_LEVEL_EMPLOYER_CONFIRMATION}
          </div>
        </ConfirmationDialog>
      </div>
    </>
  );
};

export default BrokerConsultation;
