import { FC, MutableRefObject } from 'react';
import { useAppSelector } from 'hooks/redux';
import Services from 'modules/plans/components/DVServices/Services';
import { BenefitCategory } from 'constants/commonConstants';

type ServicesUPWrapperProps = {
  onChange: Function;
  wrappedRef: MutableRefObject<any>;
  isTouched: boolean;
};
const ServicesUPWrapper: FC<ServicesUPWrapperProps> = (props) => {
  const { onChange, wrappedRef, isTouched } = props;
  const { visionPlan } = useAppSelector((state) => state.plan.visionPlan);

  return (
    <Services
      isSmall
      plan={visionPlan}
      ref={wrappedRef}
      onChange={onChange}
      planType={BenefitCategory.VISION.value}
      isTouched={isTouched}
    />
  );
};

export default ServicesUPWrapper;
