import {
  FC,
  forwardRef,
  useRef,
  useState,
  ReactNode,
  useEffect,
  ChangeEvent,
  useCallback,
  useImperativeHandle,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';

import InputForm from 'components/InputForm/InputForm';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  FORMAT_VALIDATE,
  SIZE_VALIDATE,
} from 'components/ImageUploader/ImageUploader';
import AlertMessage from 'components/Alert/AlertMessage';
import CarrierBenefits from 'modules/carriers/components/CarrierBenefits/CarrierBenefits';
import BenefitCarrier from 'model/BenefitCarrier';
import {
  addCarrier,
  handleCarrierLogoUpload,
  clearCarrierLogo,
  clearCarrierCreation,
  attachCarrierLogo,
  getCarrier,
  getBenefitCarrierById,
  addBenefitCarrier,
  clearCarrierApiErrors,
  uploadCarrierLogoSuccess,
} from 'modules/carriers/slices/carrierSlice';

import {
  getNavContext,
  isValidWeblink,
  isValidPhoneNumber,
} from 'util/commonUtil';
import { isMDV } from 'modules/carriers/components/util/carrierUtil';
import { formVerificationMsg, MASTER } from 'constants/commonConstants';
import { navContexts } from 'constants/authConstants';
import {
  DUPLICATE_BENEFIT_CARRIER_ERROR_CODE,
  DUPLICATE_CARRIER_ERROR_CODE,
} from 'modules/carriers/constants/carrierConstants';
import OriginalImageCropParams from 'model/OriginalImageCropParams';

import styles from './carrierCreate.module.less';

type Props = {
  closeForm: () => void;
  isEdit?: boolean;
  isModalOpen: boolean;
  isBenefitCarrierAdd?: boolean;
  ref: React.Ref<any>;
  onCancel: () => void;
};
export const basicFormFields = {
  requiredFields: ['name'],
  formFields: [
    'logoUrl',
    'name',
    'url',
    'phoneNumber',
    'providerSearchUrl',
    'providerSearchAdditionalInfo',
    'benefitCategories',
  ],
};
const CarrierCreate: FC<Props> = forwardRef((props: Props, ref) => {
  const { closeForm, isEdit, isModalOpen, isBenefitCarrierAdd, onCancel } =
    props;
  const {
    logoUrl,
    inProgress,
    carrierObj,
    carrierCreateError,
    carrierCreationSuccess,
    logoReference,
    originalImgRef,
    requestType,
  } = useAppSelector((state) => state.carriers);
  const { brokerId, employerId } = useNavContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const [form] = Form.useForm();
  const createRef = useRef(null);
  const [selectedBenefitCategories, setSelectedBenefitCategories] = useState<
    string[]
  >([]);
  const [formData, setFormData] = useState({
    logoUrl: '',
    name: '',
    url: '',
    carrierType: '',
    customCarrier: false,
    benefitCategories: [''],
    providerSearchAdditionalInfo: '',
    benefitDescription: '',
    providerSearchUrl: '',
    phoneNumber: '',
  });
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [
    isCarrierLogoValidationMsgRemoved,
    setCarrierLogoValidationMsgRemoved,
  ] = useState<boolean>(true);
  const [imageValidateSetting, setImageValidateSetting] = useState<string>('');
  const [requireFieldErrorFound, setRequireFieldErrorFound] =
    useState<boolean>(false);
  const [logoImg, setLogoImg] = useState<string>('');
  const [isImageError, setIsImageError] = useState<boolean>(false);
  const [originalImg, setOriginalImg] = useState('');
  const [logoCropParams, setLogoCropParams] =
    useState<OriginalImageCropParams | null>(null);
  const [benefitError, setIsBenefitError] = useState<boolean>(false);
  const [webURlError, setWebUrlError] = useState<boolean>(false);
  const [additioanlInfoURlError, setAdditioanlInfoUrlError] =
    useState<boolean>(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState<boolean>(false);
  const [isConfirmAdd, setIsConfirmAdd] = useState<boolean>(false);
  const [previousLength, setPreviousLength] = useState<number>(0);
  const [isSaveButtonLoading, setIsSaveButtonLoading] =
    useState<boolean>(false);
  const closeModal = useCallback(() => {
    form.resetFields();
    setLogoImg('');
    setSelectedBenefitCategories([]);
    setRequireFieldErrorFound(false);
    if (imageValidateSetting) {
      setImageValidateSetting('');
    }
    setIsBenefitError(false);
    setWebUrlError(false);
    setIsImageError(false);
    setAdditioanlInfoUrlError(false);
    setInvalidPhoneNumber(false);
    setPreviousLength(0);
    closeForm();
    dispatch(clearCarrierApiErrors());
    setCarrierLogoValidationMsgRemoved(true);
  }, [form, imageValidateSetting, closeForm, dispatch]);

  const carrierCreateInProgress = useAppSelector(
    (state) => state.carriers.createInProgress
  );

  useImperativeHandle(
    ref,
    () => ({
      closeAll() {
        closeModal();
      },
      getEditorRef() {
        return createRef;
      },
    }),
    [closeModal]
  );
  const getBenefitCategories = (benefitCarrierList: any) => {
    const benefitCategories: any[] = [];
    if (benefitCarrierList) {
      benefitCarrierList.forEach(function (benefitCarrier: any) {
        benefitCategories.push(benefitCarrier);
      });
    }
    return benefitCategories;
  };

  useEffect(() => {
    if (isModalOpen) {
      setIsSaveButtonLoading(false);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (
      params &&
      params.carrierId &&
      (isEdit || isBenefitCarrierAdd) &&
      !inProgress &&
      !isModalOpen
    ) {
      if (params.id) {
        dispatch(getBenefitCarrierById(params.id));
      } else {
        dispatch(
          getCarrier(
            brokerId,
            employerId,
            getNavContext(params) === navContexts.platform
              ? MASTER
              : getNavContext(params),
            params.carrierId
          )
        );
      }
    }
  }, [
    params,
    dispatch,
    isEdit,
    employerId,
    inProgress,
    isBenefitCarrierAdd,
    brokerId,
    isModalOpen,
  ]);

  useEffect(() => {
    if (!inProgress && requestType === uploadCarrierLogoSuccess.type) {
      setIsSaveButtonLoading(false);
    }
    // eslint-disable-next-line
  }, [inProgress]);

  useEffect(() => {
    if (
      params &&
      params.carrierId &&
      isBenefitCarrierAdd &&
      isModalOpen &&
      isEmpty(logoImg) &&
      !isImageError
    ) {
      dispatch(
        getCarrier(
          brokerId,
          employerId,
          getNavContext(params) === navContexts.platform
            ? MASTER
            : getNavContext(params),
          params.carrierId
        )
      );
    }
  }, [
    params,
    dispatch,
    employerId,
    isBenefitCarrierAdd,
    logoImg,
    isImageError,
    isModalOpen,
    brokerId,
  ]);
  useEffect(() => {
    if (
      (isEdit || isBenefitCarrierAdd) &&
      (isBenefitCarrierAdd ? carrierObj.benefitCarrierVOS : carrierObj) &&
      isModalOpen &&
      isEmpty(logoImg) &&
      !isImageError
    ) {
      dispatch(attachCarrierLogo(carrierObj.logoUrl));
      form.resetFields();
      setRequireFieldErrorFound(false);
      setAlertMessage('');
      setIsImageError(false);
      setIsBenefitError(false);
      setWebUrlError(false);
      setAdditioanlInfoUrlError(false);
      setInvalidPhoneNumber(false);
      if (carrierObj.logoUrl) {
        setCarrierLogoValidationMsgRemoved(true);
      }
      if (!isEmpty(carrierObj.benefitCategories)) {
        setSelectedBenefitCategories(
          getBenefitCategories(carrierObj.benefitCategories)
        );
      }
      if (carrierObj.logoUrl && (isEdit || isBenefitCarrierAdd)) {
        setLogoImg(carrierObj.logoUrl);
      }
      if (carrierObj.originalImageCropParams && carrierObj.originalLogoUrl) {
        setOriginalImg(carrierObj.originalLogoUrl);
        setLogoCropParams(carrierObj.originalImageCropParams);
      }
      const formData = {
        name: carrierObj.name,
        url: carrierObj.url,
        phoneNumber: carrierObj.phoneNumber,
        providerSearchUrl: carrierObj.providerSearchUrl,
        providerSearchAdditionalInfo: carrierObj.providerSearchAdditionalInfo,
        benefitDescription: carrierObj.benefitDescription,
        benefitCategories: selectedBenefitCategories,
        logoUrl: carrierObj.logoUrl,
        carrierType: carrierObj.carrierType,
        customCarrier: carrierObj.customCarrier,
      };
      form.setFieldsValue(formData);
      setFormData(formData);
    }
  }, [
    carrierObj,
    dispatch,
    form,
    isEdit,
    selectedBenefitCategories,
    isModalOpen,
    params.id,
    logoImg,
    isBenefitCarrierAdd,
    isImageError,
  ]);
  const getCarrierType = useCallback(() => {
    return getNavContext(params) === navContexts.platform
      ? MASTER
      : getNavContext(params);
  }, [params]);
  useEffect(() => {
    if (carrierCreationSuccess && logoImg) {
      dispatch(clearCarrierCreation());
      dispatch(clearCarrierLogo());
      closeModal();
      if (params.id) {
        if (params?.brokerId && params?.employerId) {
          navigate(
            `/brokers/${brokerId}/employers/${employerId}/carriers/${params.carrierId}/${params.benefitCategory}/${carrierObj.id}`
          );
        } else if (params?.brokerId) {
          navigate(
            `/brokers/${brokerId}/carriers/${params.carrierId}/${params.benefitCategory}/${carrierObj.id}`
          );
        } else {
          navigate(
            `/carriers/${params.carrierId}/${params.benefitCategory}/${carrierObj.id}`
          );
        }
      } else {
        if (params?.brokerId && params?.employerId) {
          navigate(
            `/brokers/${brokerId}/employers/${employerId}/carriers/${carrierObj.id}/info`
          );
        } else if (params?.brokerId) {
          navigate(`/brokers/${brokerId}/carriers/${carrierObj.id}/info`);
        } else {
          navigate(`/carriers/${carrierObj.id}/info`);
        }
      }
    } else {
      if (carrierCreateError && carrierCreateError.data) {
        setIsSaveButtonLoading(false);
        setRequireFieldErrorFound(true);
        setAlertMessage(carrierCreateError.data.code);
      }
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    logoImg,
    carrierCreateError,
    isEdit,
    carrierCreationSuccess,
    brokerId,
    employerId,
    navigate,
    carrierObj.id,
    params,
    isBenefitCarrierAdd,
    getCarrierType,
  ]);
  const closeAlert = () => {
    setRequireFieldErrorFound(false);
    if (imageValidateSetting) {
      setImageValidateSetting('');
    }
    setIsBenefitError(false);
    setWebUrlError(false);
    setAdditioanlInfoUrlError(false);
    setInvalidPhoneNumber(false);
    setAlertMessage('');
  };

  const handleFormChange = (changedValues: any, values: any) => {
    if (
      [
        DUPLICATE_CARRIER_ERROR_CODE,
        DUPLICATE_BENEFIT_CARRIER_ERROR_CODE,
      ].includes(String(carrierCreateError?.data?.code))
    ) {
      dispatch(clearCarrierApiErrors());
    }
    form.setFieldsValue(values);
    setFormData({ ...formData, ...values });
    if (changedValues.logoUrl && imageValidateSetting) {
      form.setFieldsValue({ ...form, logoUrl: '' });
      setCarrierLogoValidationMsgRemoved(false);
    }
  };
  const onSelectBenefitCategories = (e: any, benefitCategory: string) => {
    if (e.target.checked) {
      setSelectedBenefitCategories([
        ...selectedBenefitCategories,
        benefitCategory,
      ]);
    } else {
      setSelectedBenefitCategories(
        selectedBenefitCategories.filter(
          (category) => category !== benefitCategory
        )
      );
    }
    if (!isEmpty(selectedBenefitCategories)) {
      setIsBenefitError(false);
    }
  };
  const validateAdminInfo = async () => {
    try {
      await form.validateFields(basicFormFields.requiredFields);
      if (isEmpty(selectedBenefitCategories) && !params.id) {
        setIsBenefitError(true);
      }
      if (!logoImg || isEmpty(logoImg)) {
        setIsImageError(true);
        return false;
      }
      return true;
    } catch (errorInfo: any) {
      if (isEmpty(selectedBenefitCategories)) {
        setIsBenefitError(true);
      }
      if (!logoImg || isEmpty(logoImg)) {
        setIsImageError(true);
      }
      return errorInfo.errorFields.length === 0;
    }
  };
  const getChangedValues = () => {
    const formFields = form.getFieldsValue();
    const changableFields = ['name', 'url'];
    if (params.id) {
      changableFields.push('phoneNumber');
      if (isMDV(params)) {
        changableFields.push(
          'providerSearchAdditionalInfo',
          'providerSearchUrl'
        );
      } else {
        changableFields.push('benefitDescription');
      }
    }
    const chagnedValues: string[] = [];
    changableFields.forEach(function (value: any) {
      if (formFields[value] !== (carrierObj as any)[value]) {
        chagnedValues.push(value);
      }
    });
    if (logoReference !== carrierObj.logoReference) {
      chagnedValues.push('logoReference');
      chagnedValues.push('originalLogoReference');
      chagnedValues.push('originalImageCropParams');
    }
    return chagnedValues;
  };
  const onClickSave = async () => {
    let isFormValid: boolean = true;
    isFormValid = await validateAdminInfo();
    let continueSave = true;
    if (!logoUrl) {
      dispatch(clearCarrierLogo());
    }

    if (!params.id && isEmpty(selectedBenefitCategories)) {
      setAlertMessage(formVerificationMsg);
      setRequireFieldErrorFound(true);
      continueSave = false;
    }
    const { url, phoneNumber, providerSearchUrl } = formData;

    if (!isFormValid) {
      setAlertMessage(formVerificationMsg);
      if (imageValidateSetting) {
        setImageValidateSetting('');
      }
      setRequireFieldErrorFound(true);
      continueSave = false;
    }

    if (url && !isValidWeblink(url)) {
      setAlertMessage(formVerificationMsg);
      setRequireFieldErrorFound(true);
      setWebUrlError(true);
      continueSave = false;
    }
    if (providerSearchUrl && !isValidWeblink(providerSearchUrl)) {
      setAlertMessage(formVerificationMsg);
      setRequireFieldErrorFound(true);
      setAdditioanlInfoUrlError(true);
      continueSave = false;
    }
    if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
      setAlertMessage(formVerificationMsg);
      setRequireFieldErrorFound(true);
      setInvalidPhoneNumber(true);
      continueSave = false;
    }

    if (continueSave) {
      setIsSaveButtonLoading(true);
      getAction();
    }
  };
  const getValidationMessage = (validateSetting: string): ReactNode => {
    switch (validateSetting) {
      case FORMAT_VALIDATE:
        return 'This image file type is not supported.';
      case SIZE_VALIDATE:
        return 'Maximum size allowed for this upload is 2 MB.';
      default:
        setImageValidateSetting('');
        return;
    }
  };
  const onConfirmCrop = async (
    image: string,
    originalImage?: string,
    cropParams?: OriginalImageCropParams
  ) => {
    if (originalImage) {
      dispatch(handleCarrierLogoUpload(image, originalImage));
      setOriginalImg(originalImage);
    }
    setLogoImg(image);
    if (cropParams) {
      setLogoCropParams(cropParams);
    }
    setCarrierLogoValidationMsgRemoved(true);
    setImageValidateSetting('');
    setIsImageError(false);
    setIsSaveButtonLoading(true);
  };

  const onLogoRemove = () => {
    form.setFieldsValue({ ...form, logoUrl: '' });
    setCarrierLogoValidationMsgRemoved(false);
    setImageValidateSetting('');
    setLogoImg('');
    setIsImageError(true);
    setOriginalImg('');
    setLogoCropParams(null);
  };

  const onValidateFails = (validateSetting: string) => {
    setImageValidateSetting(validateSetting);
    if (requireFieldErrorFound) {
      setRequireFieldErrorFound(false);
    }
  };
  const handleTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    form.setFieldsValue({ [name]: value });

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleProvider = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const bullet = '\u2022';
    const newLength = value.length;
    const characterCode = value.substr(-1).charCodeAt(0);

    if (newLength > previousLength) {
      if (characterCode === 10) {
        form.setFieldsValue({ [name]: `${value}${bullet} ` });
        setFormData((prevData: any) => ({
          ...prevData,
          [name]: value,
        }));
      } else if (newLength === 1) {
        form.setFieldsValue({ [name]: `${bullet} ${value}` });
        setFormData((prevData: any) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
    setPreviousLength(newLength);
  };

  const getCheckedState = (value: string) => {
    if (
      !isEmpty(selectedBenefitCategories) &&
      selectedBenefitCategories.includes(value)
    ) {
      return true;
    }
    return false;
  };

  const getBenefitCarrierUtilized = (value: string) => {
    return !!(
      !isEmpty(carrierObj?.benefitCategories) &&
      carrierObj?.benefitCategories.includes(value)
    );
  };

  const getAction = () => {
    if (!params.employerId) {
      if (!isImageError) {
        setIsConfirmAdd(true);
      } else {
        setAlertMessage(formVerificationMsg);
        setRequireFieldErrorFound(true);
      }
    } else {
      onConfirmSave();
    }
  };

  const trimValue = (fieldName: string) => {
    return (event: any) => {
      const { value } = event.target;
      form.setFieldsValue({ [fieldName]: value.trim() });
    };
  };

  const onConfirmSave = async () => {
    setIsConfirmAdd(false);
    const {
      name,
      url,
      phoneNumber,
      providerSearchAdditionalInfo,
      benefitDescription,
      providerSearchUrl,
    } = formData;

    const newCarrier = {
      name: name.trim(),
      url: url ? url.trim() : '',
      carrierType: !isEdit
        ? getNavContext(params) === navContexts.platform
          ? MASTER
          : getNavContext(params)
        : carrierObj.carrierType,
      customCarrier:
        getNavContext(params) !== navContexts.platform
          ? isEdit
            ? carrierObj.customCarrier
            : true
          : false,
      benefitCategories: selectedBenefitCategories,
      logoUrl: carrierObj ? carrierObj.logoUrl : '',
      logoReference: logoReference || carrierObj.logoReference,
      originalImageCropParams:
        logoCropParams || carrierObj.originalImageCropParams,
      originalLogoReference: originalImgRef || carrierObj.originalLogoReference,
      carrierSourceDetails: isEdit ? carrierObj.carrierSourceDetails : null,
    } as BenefitCarrier;
    if (params.id && params.carrierId) {
      newCarrier.benefitCategory = params.benefitCategory
        ? params.benefitCategory
        : '';
      newCarrier.phoneNumber = phoneNumber;
      if (isMDV(params)) {
        newCarrier.providerSearchAdditionalInfo = providerSearchAdditionalInfo
          ?.trimStart()
          .trimEnd();
        newCarrier.providerSearchUrl = providerSearchUrl;
      } else {
        newCarrier.benefitDescription = benefitDescription
          ?.trimStart()
          .trimEnd();
      }
      newCarrier.carrierId = params.carrierId;
    }
    if (carrierObj.id && isEdit) {
      newCarrier.id = carrierObj.id;
      if (!params.id) {
        newCarrier.benefitCarrierVOS = carrierObj.benefitCarrierVOS;
      }
      newCarrier.changedFields = getChangedValues();
      newCarrier.defaultCarrier = carrierObj.defaultCarrier;
    }
    if (brokerId) {
      newCarrier.organizationId = params?.brokerId;
    }
    if (employerId) {
      newCarrier.employerId = params?.employerId;
    }
    if (params.id) {
      await dispatch(addBenefitCarrier(newCarrier, getCarrierType()));
    } else {
      await dispatch(addCarrier(newCarrier, getCarrierType()));
    }
    setIsConfirmAdd(false);
  };

  return (
    <div className={styles.carrierCreate}>
      {(requireFieldErrorFound || imageValidateSetting) && (
        <AlertMessage
          type="error"
          message={
            imageValidateSetting
              ? getValidationMessage(imageValidateSetting)
              : alertMessage
          }
          closeAlert={closeAlert}
        />
      )}
      <InputForm form={form} onValuesChange={handleFormChange}>
        <CarrierBenefits
          benefitError={benefitError}
          onSelectBenefitCategories={onSelectBenefitCategories}
          getCheckedState={getCheckedState}
          invalidPhoneNumber={invalidPhoneNumber}
          additioanlInfoURlError={additioanlInfoURlError}
          additionalInfo={formData.providerSearchAdditionalInfo}
          handleTextArea={handleTextArea}
          webURlError={webURlError}
          isImageError={isImageError}
          logoImg={logoImg}
          imageValidateSetting={imageValidateSetting}
          isCarrierLogoValidationMsgRemoved={isCarrierLogoValidationMsgRemoved}
          onConfirmCrop={onConfirmCrop}
          inProgress={inProgress}
          onLogoRemove={onLogoRemove}
          onValidateFails={onValidateFails}
          originalImg={originalImg}
          logoCropParams={logoCropParams}
          trimValue={trimValue}
          handleProvider={handleProvider}
          isEdit={isEdit}
          getBenefitCarrierUtilized={getBenefitCarrierUtilized}
        />
        <Row justify="center" className={styles.margins}>
          <Col>
            <PageActionButton
              onClick={onClickSave}
              type="primary"
              className={styles.confirmButton}
              dataCy="saveCarrierBtn"
              loading={isSaveButtonLoading}
              disabled={!!carrierCreateError}
            >
              Save Carrier
            </PageActionButton>
          </Col>
        </Row>
        <Row justify="center" className={styles.margins}>
          <Col>
            <CancelButton
              htmlType="button"
              onClick={onCancel}
              className={styles.cancelBtnStyle}
            >
              Cancel
            </CancelButton>
          </Col>
        </Row>
      </InputForm>
      {isConfirmAdd && (
        <ConfirmationDialog
          title={isEdit ? `Save Carrier` : `Add Carrier`}
          confirmText={isEdit ? `Yes - Save Carrier` : `Yes - Add Carrier`}
          cancelText="Cancel"
          closeModal={() => {
            setIsConfirmAdd(false);
            setIsSaveButtonLoading(false);
          }}
          onConfirm={onConfirmSave}
          confirmLoading={carrierCreateInProgress}
          isCancelLink={true}
          visible={isConfirmAdd}
          disableConfirmButton={!!carrierCreateError}
        >
          <p className={styles.warningConfirmation}>
            {isEdit
              ? `Any changes to this carrier will occur in all libraries where 
              the carrier exists and only if the changed fields have not yet 
              been customized. Would you like to save these changes?`
              : `This carrier will be added to all libraries where the carrier 
              does not already exist. Would you like to add this carrier?`}
          </p>
        </ConfirmationDialog>
      )}
    </div>
  );
});
CarrierCreate.displayName = 'CarrierCreate';
export default CarrierCreate;
