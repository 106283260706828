import { lumityApi } from 'api/apiSlice';
import { IndividualType } from 'constants/commonConstants';

const erAdminRTKSlice = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getErAdminsAvailability: builder.query<
      any,
      { employerId: string; organizationId: string }
    >({
      query: ({ employerId, organizationId }) => {
        return {
          url: `v2/individuals/admins/employer/is-exist`,
          method: 'GET',
          params: {
            type: IndividualType.ER_ADMIN,
            'employer-id': employerId,
            'organization-id': organizationId,
          },
        };
      },
    }),
  }),
});

export const { useLazyGetErAdminsAvailabilityQuery } = erAdminRTKSlice;
