import { FC } from 'react';
import { Table } from 'antd';

import styles from './viewAssignedEmployers.module.less';

type AssignedEmployers = {
  employerId: string;
  name: string;
};

type ViewAssignedEmployersProps = {
  assignedEmployersList: AssignedEmployers[];
};

const convertTableDataForTableRender = (
  tableData: AssignedEmployers[]
): any[] => {
  return tableData?.map((data) => ({
    key: data.employerId,
    name: data.name,
  }));
};

const tableDataColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: {
      showTitle: false,
    },
  },
];

const ViewAssignedEmployers: FC<ViewAssignedEmployersProps> = (
  props: ViewAssignedEmployersProps
) => {
  const { assignedEmployersList } = props;

  return (
    <div className={styles.viewAssignedEmployersWrapper}>
      <Table
        showHeader={false}
        columns={tableDataColumns}
        dataSource={convertTableDataForTableRender(assignedEmployersList)}
        pagination={false}
        className={styles.tableWrapper}
      />
    </div>
  );
};

export default ViewAssignedEmployers;
