import { combineReducers } from '@reduxjs/toolkit';
import proposalCreateStepperSlice from 'modules/renewals/slices/proposalCreateStepperSlice';
import addProposalToPlanYearSlice from 'modules/renewals/slices/addProposalToPlanYearSlice';
import offerReducersSlice from 'modules/renewals/slices/offerReducerSlice';
import quoteReaderSlice from 'modules/renewals/slices/carrierQuoteAiSlice';

const RenewalRootReducer = combineReducers({
  proposal: proposalCreateStepperSlice,
  addProposalToPlanYear: addProposalToPlanYearSlice,
  offers: offerReducersSlice,
  quoteReaderSlice: quoteReaderSlice,
});

export default RenewalRootReducer;
