import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import DraftEmployer, { ERAdmin } from 'model/DraftEmployer';
import {
  createEmployer,
  uploadEmployerLogo,
} from 'modules/employers/services/EmployerService';
import { findBrokerLocationsByLoggedInUser } from 'modules/brokers/services/BrokerLocationService';
import Broker from 'model/Broker';
import {
  findBroker,
  getBrokerListWithoutPagination,
} from 'modules/brokers/services/BrokerService';
import BrokerAdmin from 'model/BrokerAdmin';
import { getBrokerAdminByLocation } from 'modules/employers/services/IndividualService';
import EmployerLocation from 'model/EmployerLocation';
import Employer from 'model/Employer';
import { getBlobFromEncodedImageString } from 'util/fileUtil';

export interface EmployerOnboardingState {
  inProgress: boolean;
  error: any;
  requestType: string;
  employer: DraftEmployer;
  brokerLocationList: {
    selectAll: boolean;
    employerLocations: EmployerLocation[];
  };
  employerLogo: string | undefined;
  employerLogoReference: string | null;
  brokerList: Broker[];
  benefitGroups: string[];
  brokerAdmins: BrokerAdmin[];
  createdEmployer: Employer | null;
  originalLogo: string | null;
  originalLogoUrl: string | null;
}

const initialState: EmployerOnboardingState = {
  inProgress: false,
  error: null,
  requestType: '',
  employer: {
    name: '',
    organizationId: '',
    erAdmins: [],
    isDemoAccount: false,
  },
  brokerLocationList: {
    selectAll: false,
    employerLocations: [],
  },
  employerLogo: undefined,
  employerLogoReference: null,
  brokerList: [],
  benefitGroups: ['All Full-Time Employees'],
  brokerAdmins: [],
  createdEmployer: null,
  originalLogo: null,
  originalLogoUrl: null,
};

const employerOnboardingSlice = createSlice({
  name: 'employerOnboarding',
  initialState,
  reducers: {
    updateDraftEmployer: (state, action: PayloadAction<DraftEmployer>) => {
      state.requestType = action.type;
      state.employer.name = action.payload.name;
      state.employer.address = action.payload.address;
      state.employer.einTaxId = action.payload.einTaxId;
      state.employer.organizationId = action.payload.organizationId;
      state.employer.locations = action.payload.locations;
      state.employer.logoUrl = action.payload.logoUrl;
      state.employer.emailDomains = action.payload.emailDomains;
      state.employer.allLocationsSelected = action.payload.allLocationsSelected;
      state.employer.originalImageCropParams =
        action.payload.originalImageCropParams;
      state.employer.originalLogoUrl = action.payload.originalLogoUrl;
      state.employer.isDemoAccount = action.payload.isDemoAccount;
    },
    getLocationListStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
    },
    getLocationListSuccess(
      state,
      action: PayloadAction<{
        selectAll: boolean;
        employerLocations: EmployerLocation[];
      }>
    ) {
      state.inProgress = false;
      state.requestType = '';
      state.brokerLocationList = action.payload;
    },
    getLocationListFailed(state, action: PayloadAction) {
      state.inProgress = false;
      state.requestType = action.type;
    },
    uploadEmployerLogoStart(
      state,
      action: PayloadAction<{
        employerLogo: string;
        originalLogo: string | undefined;
      }>
    ) {
      state.inProgress = true;
      state.employerLogo = action.payload.employerLogo;
      state.requestType = action.type;
      if (action.payload.originalLogo) {
        state.originalLogo = action.payload.originalLogo;
      }
    },
    uploadEmployerLogoSuccess(state, action: PayloadAction<string>) {
      state.inProgress = false;
      state.employerLogoReference = action.payload;
      state.error = null;
    },
    erOriginalLogoUploadSuccess(state, action: PayloadAction<string>) {
      state.originalLogoUrl = action.payload;
    },
    setEmployerLogo(state, action: PayloadAction<string>) {
      state.employerLogo = action.payload;
    },
    uploadEmployerLogoFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = action.payload;
      state.employerLogoReference = '';
      state.employerLogo = '';
    },
    getAllBrokersStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
    },
    getAllBrokersSuccess(state, action: PayloadAction<Broker[]>) {
      state.inProgress = false;
      state.brokerList = action.payload;
      state.error = null;
    },
    getAllBrokersFailed(state, action: PayloadAction<any>) {
      state.inProgress = true;
      state.requestType = action.type;
      state.error = action.payload;
    },
    addErAdmin(state, action: PayloadAction<ERAdmin>) {
      const savedAdmin = state.employer.erAdmins?.find(
        (admin) => admin.id === action.payload.id
      );

      // handle singular primary contact
      if (action.payload.primary) {
        const primaryAdmin = state.employer.erAdmins?.find(
          (admin) => admin.primary
        );
        if (primaryAdmin) {
          primaryAdmin.primary = false;
        }
      }

      // handles add and update
      if (savedAdmin) {
        const {
          firstName,
          lastName,
          title,
          primary,
          phone,
          email,
          individualSubType,
        } = action.payload;
        savedAdmin.firstName = firstName;
        savedAdmin.lastName = lastName;
        savedAdmin.phone = phone;
        savedAdmin.primary = primary;
        savedAdmin.email = email;
        savedAdmin.title = title;
        savedAdmin.individualSubType = individualSubType;
      } else {
        state.employer.erAdmins?.push(action.payload);
      }
    },
    removeErAdmin(state, action: PayloadAction<string>) {
      state.employer.erAdmins = state.employer.erAdmins?.filter(
        (admin) => admin.id !== action.payload
      );
    },
    saveBenefitGroup(state, action: PayloadAction<string>) {
      state.benefitGroups.push(action.payload);
    },
    saveAllBenefitGroup(state, action: PayloadAction<string[]>) {
      state.benefitGroups = action.payload;
    },
    removeBenefitGroup(state, action: PayloadAction<string>) {
      state.benefitGroups = state.benefitGroups.filter(
        (group) => group !== action.payload
      );
    },
    getBrokerAdminsStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
    },
    getBrokerAdminsSuccess(state, action: PayloadAction<BrokerAdmin[]>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.brokerAdmins = action.payload;
    },
    getBrokerAdminsFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
    },
    saveTeamMembers(state, action) {
      state.employer.teamMembers = action.payload;
    },
    createEmployerStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
    },
    createEmployerSuccess(state, action: PayloadAction<Employer>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = null;
      state.createdEmployer = action.payload;
    },
    createEmployerFailed(state, action) {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = action.payload;
    },
    clearEmployerLogo: (state) => {
      state.employerLogo = '';
      state.employerLogoReference = null;
      state.originalLogo = null;
      state.originalLogoUrl = null;
    },
    clearSavedTeamMembers: (state) => {
      state.employer.teamMembers = [];
    },
    findBrokerStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
    },
    findBrokerSuccess(state, action: PayloadAction<Broker>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.brokerList = [action.payload];
      state.error = null;
    },
    findBrokerFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = { response: action.payload };
      state.brokerList = [];
    },
    clearEmployerData() {
      return { ...initialState, employer: { ...initialState.employer } };
    },
  },
});

export const {
  updateDraftEmployer,
  getLocationListStarted,
  getLocationListSuccess,
  getLocationListFailed,
  uploadEmployerLogoStart,
  uploadEmployerLogoSuccess,
  uploadEmployerLogoFailed,
  getAllBrokersStarted,
  getAllBrokersSuccess,
  getAllBrokersFailed,
  addErAdmin,
  removeErAdmin,
  saveBenefitGroup,
  saveAllBenefitGroup,
  removeBenefitGroup,
  getBrokerAdminsStarted,
  getBrokerAdminsSuccess,
  getBrokerAdminsFailed,
  saveTeamMembers,
  createEmployerStarted,
  createEmployerSuccess,
  createEmployerFailed,
  clearEmployerLogo,
  clearSavedTeamMembers,
  findBrokerStarted,
  findBrokerSuccess,
  findBrokerFailed,
  clearEmployerData,
  setEmployerLogo,
  erOriginalLogoUploadSuccess,
} = employerOnboardingSlice.actions;

export default employerOnboardingSlice.reducer;

export const getBrokerLocations =
  (brokerId: string) => async (dispatch: Dispatch) => {
    dispatch(getLocationListStarted());
    try {
      const response = await findBrokerLocationsByLoggedInUser(brokerId);
      dispatch(getLocationListSuccess(response.data));
    } catch (error) {
      // TODO: handle errors
      dispatch(getLocationListFailed());
    }
  };

export const handleEmployerLogoUpload =
  (croppedImage: string, originalLogo?: string) =>
  async (dispatch: Dispatch) => {
    dispatch(
      uploadEmployerLogoStart({ employerLogo: croppedImage, originalLogo })
    );
    try {
      if (originalLogo) {
        const originalImageBlob = await getBlobFromEncodedImageString(
          originalLogo
        );
        const originalImageResponse = await uploadEmployerLogo(
          originalImageBlob,
          true
        );
        dispatch(
          erOriginalLogoUploadSuccess(originalImageResponse.data.logoUrl)
        );
      }
      const imageBlob = await getBlobFromEncodedImageString(croppedImage);
      const response = await uploadEmployerLogo(imageBlob);
      dispatch(uploadEmployerLogoSuccess(response.data.logoUrl));
    } catch (error) {
      dispatch(uploadEmployerLogoFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const getBrokerList = () => async (dispatch: Dispatch) => {
  dispatch(getAllBrokersStarted());
  try {
    const response = await getBrokerListWithoutPagination();
    dispatch(getAllBrokersSuccess(response.data));
  } catch (error) {
    dispatch(getAllBrokersFailed(error));
  }
};
export const getBrokerAdminsForLocations =
  (organizationId: string, locationIds: string[]) =>
  async (dispatch: Dispatch) => {
    dispatch(getBrokerAdminsStarted());
    try {
      const response = await getBrokerAdminByLocation(
        organizationId,
        locationIds
      );
      dispatch(getBrokerAdminsSuccess(response.data));
    } catch (error) {
      dispatch(getBrokerAdminsFailed(JSON.parse(JSON.stringify(error))));
    }
  };

export const processCreateEmployer =
  (employer: DraftEmployer) => async (dispatch: Dispatch) => {
    dispatch(createEmployerStarted());
    try {
      const response = await createEmployer(employer);
      dispatch(createEmployerSuccess(response.data));
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(createEmployerFailed(JSON.parse(JSON.stringify(error))));
        } else {
          // todo handle errors
          console.log('Employer creation throws ' + JSON.stringify(error));
        }
      } else {
        // todo handle errors
        console.log('Employer creation throws ' + JSON.stringify(error));
      }
    }
  };

export const processFindBroker =
  (brokerId: string) => async (dispatch: Dispatch) => {
    dispatch(findBrokerStarted());
    try {
      const response = await findBroker(brokerId);
      dispatch(findBrokerSuccess(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(findBrokerFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
export const attachEmployerLogo =
  (logoUrl: string) => async (dispatch: Dispatch) => {
    dispatch(setEmployerLogo(logoUrl));
  };
