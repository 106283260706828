import {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  PLACEMENT_BOTTOM,
  PLACEMENT_INLINE,
  INLINE_MX_WIDTH_100,
  INLINE_MX_WIDTH_150,
  INLINE_MX_WIDTH_200,
  INLINE_MX_WIDTH_250,
} from './constants/inlineEditConstant';

import styles from './inlieEditable.module.less';

type InlineEditableProps = {
  placeholderComponent: ReactNode | ReactElement;
  placement: typeof PLACEMENT_BOTTOM | typeof PLACEMENT_INLINE;
  showHover?: boolean;
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  minWidth?:
    | typeof INLINE_MX_WIDTH_100
    | typeof INLINE_MX_WIDTH_150
    | typeof INLINE_MX_WIDTH_200
    | typeof INLINE_MX_WIDTH_250;
  positionAbsolute?: boolean;
  placeholderVisibilityHidden?: boolean;
  showCursorHand?: boolean;
  outsideClickClose?: boolean;
  onOutsideClickSave?: () => void;
};

const InlineEditable = forwardRef(
  (
    {
      placeholderComponent,
      placement,
      showHover = true,
      disabled = false,
      className,
      children,
      minWidth,
      positionAbsolute = true,
      placeholderVisibilityHidden = false,
      showCursorHand = true,
      outsideClickClose = true,
      onOutsideClickSave,
    }: InlineEditableProps,
    ref: any
  ) => {
    const [isEditing, setEditing] = useState(false);
    const myRef = useRef<any>();

    const handleClickOutside = (e: any) => {
      if (!myRef?.current?.contains(e.target)) {
        if (outsideClickClose) {
          if (isEditing) {
            onOutsideClickSave && onOutsideClickSave();
          }
          setEditing(false);
        }
        return;
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () =>
        document.removeEventListener('mousedown', handleClickOutside);

      // eslint-disable-next-line
    });

    useImperativeHandle(ref, () => ({
      closeEdit: () => {
        setEditing(false);
      },
    }));

    if (placement === PLACEMENT_INLINE) {
      return (
        <div className={`${styles.inlineEditableWrapper} ${className}`}>
          {isEditing && (
            <div
              ref={myRef}
              className={`${
                positionAbsolute
                  ? styles.editableInputAbsoluteWrapper
                  : styles.editableInputWrapper
              }`}
            >
              {children}
            </div>
          )}
          <div
            className={`${styles.placeholderWrapper} ${
              showHover && !disabled && styles.showHover
            } 
            ${showCursorHand && !disabled && styles?.showCursorHand}
            ${
              isEditing &&
              placeholderVisibilityHidden &&
              styles.placeholderWrapperVHidden
            }
            ${
              isEditing &&
              !placeholderVisibilityHidden &&
              styles.placeholderWrapperDNone
            }
            `}
            onClick={(event) => {
              const target: HTMLAnchorElement =
                event.target as HTMLAnchorElement;
              target.origin === undefined && !disabled && setEditing(true);
            }}
          >
            {placeholderComponent}
          </div>
        </div>
      );
    }
    return (
      <div ref={myRef} className={styles.inlineEditableWrapper}>
        <div
          className={`${styles.placeholderWrapper} ${
            showCursorHand && !disabled && styles?.showCursorHand
          }
           ${showHover && !disabled && styles.showHover} ${
            isEditing && showHover && styles.backgroundShow
          }`}
          onClick={(e) => {
            e.stopPropagation();
            !disabled && setEditing(!isEditing);
          }}
        >
          {placeholderComponent}
        </div>

        {isEditing ? (
          <div
            className={`${styles.editableBottomInputWrapper} ${
              minWidth && styles[minWidth]
            }`}
          >
            {children}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
);
InlineEditable.displayName = 'InlineEditable';
export default InlineEditable;
