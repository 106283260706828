import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import ErrorDetails from 'model/ErrorDetails';
import { initiateResetPassword } from 'modules/auth/services/AuthService';

interface ResetPwdState {
  inProgress: boolean;
  error: ErrorDetails | null;
  actionType: string;
}

const initialState: ResetPwdState = {
  inProgress: false,
  error: null,
  actionType: '',
};

const resetPwdSlice = createSlice({
  name: 'resetPwd',
  initialState,
  reducers: {
    requestPwdStarted(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.actionType = action.type;
    },
    requestPwdSuccess(state, action: PayloadAction) {
      state.inProgress = false;
      state.error = null;
      state.actionType = action.type;
    },
    requestPwdFailed(state, action: PayloadAction<ErrorDetails>) {
      state.inProgress = false;
      state.error = action.payload;
      state.actionType = action.type;
    },
    resetRequestPwd() {
      return { ...initialState };
    },
  },
});

export const {
  requestPwdStarted,
  requestPwdSuccess,
  requestPwdFailed,
  resetRequestPwd,
} = resetPwdSlice.actions;

export default resetPwdSlice.reducer;

export const requestNewPassword =
  (username: string) => async (dispatch: Dispatch) => {
    dispatch(requestPwdStarted());
    try {
      await initiateResetPassword(username);
      dispatch(requestPwdSuccess());
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorDetails = error?.response;
        dispatch(requestPwdFailed(JSON.parse(JSON.stringify(errorDetails))));
      } else {
        console.error(error);
      }
    }
  };
