import { lumityApi } from 'api/apiSlice';
import { IFinalSubmitResult } from 'modules/employers/types/planBGCloneTypes';

const planBGCloneRTKSlice = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    clonePlanBG: builder.mutation<
      any,
      { employerId: string; newPlanYearId: string; data: IFinalSubmitResult }
    >({
      query: ({ employerId, newPlanYearId, data }) => {
        return {
          method: 'POST',
          url: `v1/plans/renewals/${employerId}/plan-years/${newPlanYearId}`,
          data: data,
        };
      },
    }),
  }),
});

export const { useClonePlanBGMutation } = planBGCloneRTKSlice;
