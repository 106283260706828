import axios from 'axios';
import moment from 'moment/moment';
import { lumityApi } from 'api/apiSlice';
import { baseApi, CONTENT_TYPE_MULTIPART_FORM } from 'util/apiUtil';
import ProposalDto from 'model/ProposalDto';
import ProposalSummaryVO, {
  ProposalContributionVO,
} from 'model/ProposalSummaryVO';
import ProposalCreditVO from 'model/ProposalCreditVO';
import ProposalDiscountVO from 'model/ProposalDiscountVO';
import {
  IN_PROGRESS,
  PUBLISHED,
} from 'constants/benguideCollaborationConstants';
import { RGBAModel } from 'util/exportStylingUtil';
import { isNullOrUndefined } from 'modules/plans/utils';

const PROPOSALS_API_PREFIX = 'v1/renewals/offer-proposals';

const proposalsAPI = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    createProposal: builder.mutation<any, { proposal: ProposalDto }>({
      query: (proposal) => {
        return {
          url: `${PROPOSALS_API_PREFIX}`,
          method: 'POST',
          headers: {
            'content-type': CONTENT_TYPE_MULTIPART_FORM,
          },
          data: proposal.proposal,
        };
      },
    }),
    updateProposal: builder.mutation<
      any,
      { proposalId: string; proposal: any }
    >({
      query: (args) => {
        const { proposalId, proposal } = args;
        return {
          url: `${PROPOSALS_API_PREFIX}/${proposalId}`,
          method: 'PUT',
          headers: {
            'content-type': CONTENT_TYPE_MULTIPART_FORM,
          },
          data: proposal,
        };
      },
    }),
    getProposalById: builder.query<any, { proposalId: string }>({
      query: ({ proposalId }) => {
        return {
          url: `${PROPOSALS_API_PREFIX}/${proposalId}`,
          method: 'GET',
        };
      },
    }),
    cloneProposalById: builder.mutation<
      any,
      { proposalId: string; proposal: ProposalDto }
    >({
      query: (args) => {
        const { proposalId, proposal } = args;
        return {
          url: `${PROPOSALS_API_PREFIX}/${proposalId}/clone`,
          method: 'POST',
          data: proposal,
        };
      },
    }),
    getProposalSummary: builder.query<
      ProposalSummaryVO,
      { proposalId: string; employerId: string }
    >({
      query: ({ proposalId, employerId }) => {
        return {
          url: `${PROPOSALS_API_PREFIX}/${proposalId}/summary`,
          method: 'GET',
          params: {
            'employer-id': employerId,
            currentTs: new Date().getTime(),
          },
        };
      },
    }),
    proposalStatusUpdate: builder.mutation<
      any,
      {
        proposalId: string;
        proposalStatus: string;
        isNotificationActive?: boolean;
        skipBrokerEmailNotification?: boolean;
      }
    >({
      query: ({
        proposalId,
        proposalStatus,
        isNotificationActive,
        skipBrokerEmailNotification,
      }) => {
        return {
          url: `${PROPOSALS_API_PREFIX}/${proposalId}/status/${proposalStatus}`,
          method: 'PUT',
          params: {
            'is-notification-active': isNotificationActive,
            'skip-broker-email-notification': skipBrokerEmailNotification,
          },
        };
      },
    }),
    deleteProposal: builder.mutation<any, { proposalId: string }>({
      query: ({ proposalId }) => {
        return {
          url: `${PROPOSALS_API_PREFIX}/${proposalId}/delete`,
          method: 'DELETE',
        };
      },
    }),
    getProposalBenefitTypeDetails: builder.query<
      ProposalContributionVO,
      { proposalId: string; employerId: string; category: string }
    >({
      query: ({ proposalId, employerId, category }) => {
        return {
          url: `${PROPOSALS_API_PREFIX}/${proposalId}/contributions`,
          method: 'GET',
          params: {
            employerId: employerId,
            category: category,
          },
        };
      },
    }),
    getPlanData: builder.query<
      any,
      { planId: string; benefitCategory: string }
    >({
      query: ({ planId, benefitCategory }) => {
        return {
          url: `${PROPOSALS_API_PREFIX}/details/${planId}`,
          method: 'GET',
          params: {
            'benefit-category': benefitCategory,
          },
        };
      },
    }),
    saveOfferDiscount: builder.mutation<
      any,
      { planId: string; proposalDiscount: any; type: string }
    >({
      query: ({ planId, proposalDiscount, type }) => {
        return {
          url: `${PROPOSALS_API_PREFIX}/${planId}/${type}`,
          method: 'PUT',
          data: proposalDiscount,
        };
      },
    }),
    getProposalDiscounts: builder.query<
      ProposalDiscountVO,
      { proposalId: string }
    >({
      query: ({ proposalId }) => {
        return {
          url: `${PROPOSALS_API_PREFIX}/${proposalId}/discounts`,
          method: 'GET',
        };
      },
    }),
    getProposalCredits: builder.query<ProposalCreditVO, { proposalId: string }>(
      {
        query: ({ proposalId }) => {
          return {
            url: `${PROPOSALS_API_PREFIX}/${proposalId}/credits`,
            method: 'GET',
          };
        },
      }
    ),
    updateProposalStatusAsRecommended: builder.mutation<
      any,
      { proposalId: string; isRecommended: boolean }
    >({
      query: ({ proposalId, isRecommended }) => {
        return {
          url: `${PROPOSALS_API_PREFIX}/${proposalId}/recommend?recommended=${isRecommended}`,
          method: 'PUT',
        };
      },
    }),
    getFinalApprovedProposal: builder.query<
      any,
      {
        employerId: string;
      }
    >({
      query: (args) => {
        const { employerId } = args;
        return {
          url: `${PROPOSALS_API_PREFIX}/${employerId}/final-approved`,
          method: 'GET',
        };
      },
    }),
    getProposalNamesByEmployerId: builder.query<
      any,
      {
        employerId: string;
        upcomingPlanYearId: string | undefined;
        planYearId: string | undefined;
      }
    >({
      query: (args) => {
        const { employerId, upcomingPlanYearId, planYearId } = args;
        return {
          url: `${PROPOSALS_API_PREFIX}/employers/${employerId}/proposals`,
          params: {
            'upcoming-plan-year-id':
              upcomingPlanYearId === undefined || upcomingPlanYearId === 'All'
                ? null
                : upcomingPlanYearId,
            'plan-year-id': planYearId,
          },
          method: 'GET',
        };
      },
    }),
    getProposalSummaryExportFilterOptions: builder.query<
      any,
      { proposalId: string; upcomingPlanYearId: string | undefined }
    >({
      query: ({ proposalId, upcomingPlanYearId }) => {
        const planYearIdParam =
          upcomingPlanYearId === 'All'
            ? ''
            : `?upcoming-plan-year-id=${upcomingPlanYearId}`;
        return {
          url: `${PROPOSALS_API_PREFIX}/${proposalId}/export-filters${planYearIdParam}`,
          method: 'GET',
        };
      },
    }),
    updateProposalNotificationStatus: builder.mutation<
      any,
      { proposalId: string; isActive: boolean }
    >({
      query: (args) => {
        const { proposalId, isActive } = args;
        return {
          url: `${PROPOSALS_API_PREFIX}/${proposalId}/published-notification`,
          method: 'PUT',
          params: {
            isActive: isActive,
          },
        };
      },
    }),
    createProposalClone: builder.mutation<
      any,
      { proposalId: string; isClone: boolean }
    >({
      query: (args) => {
        const { proposalId, isClone } = args;
        return {
          url: `${PROPOSALS_API_PREFIX}/${proposalId}/create-proposal-clone`,
          method: 'POST',
          params: {
            'is-clone': isClone,
          },
        };
      },
    }),
    getDefaultHRAPlans: builder.query<
      any,
      {
        id: string;
      }
    >({
      query: (args) => {
        const { id } = args;
        return {
          url: `${PROPOSALS_API_PREFIX}/${id}/hra-current-plans`,
          method: 'GET',
        };
      },
    }),
    checkDraftOfferExistForProposal: builder.query<
      {
        draftExist: boolean;
        publishedExist: boolean;
      },
      {
        id: string;
      }
    >({
      query: (args) => {
        const { id } = args;
        return {
          url: `${PROPOSALS_API_PREFIX}/${id}/draft-offer-exist`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const exportProposalAuditLog = async (
  proposalId: string | undefined
) => {
  const response = await axios.post(
    `${baseApi}/v1/renewals/audit/proposal/${proposalId}/export`,
    {},
    {
      responseType: 'blob',
    }
  );
  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `Proposal_ActivityLog_Export_${moment().format(
      'MM-DD-YYYY'
    )}_${proposalId}.xlsx`
  );
  document.body && document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const exportDetailedProposalExcel = async (
  proposalId: string | undefined,
  proposalStatus: string | undefined,
  proposalName: string | undefined
) => {
  try {
    const proposalStatusString =
      PUBLISHED === proposalStatus
        ? 'Published_'
        : IN_PROGRESS === proposalStatus
        ? 'Draft_'
        : '';
    const response = await axios.post(
      `${baseApi}/v1/renewals/exports/detailed-proposal?proposal-id=${proposalId}`,
      {},
      {
        responseType: 'blob',
      }
    );
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${proposalStatusString}${proposalName}_${moment().format(
        'MM-DD-YYYY'
      )}.xlsx`
    );
    document.body && document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  } catch (e) {
    console.error(e);
  }
};

export const exportProposalSummary = async (
  employerId: string | undefined,
  upcomingPlanYearId: string | undefined | null,
  proposalId: string,
  benefitType: string[],
  employerName: string,
  offerIds: string[],
  includeDiscounts: boolean,
  selectedFont?: string,
  tableHeaderColor?: RGBAModel,
  tableHeaderFontColor?: RGBAModel
) => {
  try {
    const response = await axios.post(
      `${baseApi}/v1/renewals/exports/proposal-summary`,
      {
        employerId: employerId,
        upcomingPlanYearId:
          upcomingPlanYearId === 'All' ? undefined : upcomingPlanYearId,
        proposalId: proposalId,
        benefitCategories: benefitType,
        offerIds: offerIds,
        includeDiscounts: includeDiscounts,
        selectedFont,
        tableHeaderColor,
        tableHeaderFontColor,
      },
      {
        responseType: 'blob',
      }
    );
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Export_proposal_${employerName}_${moment().format('MM-DD-YYYY')}.xlsx`
    );
    document.body && document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (e) {
    if (e && (e as any).response?.status === 500) {
      let errorMessage =
        'Oops! An unexpected error occurred. Please try again.';
      try {
        const errorData = await new Response((e as any).response.data).json();
        if (errorData?.code === 'no.data.to.generate.proposal.summary') {
          errorMessage = `The proposal you're looking for has no offers. Please update offers and try again`;
        }
      } catch (jsonError) {
        console.warn('Error parsing JSON response:', jsonError);
      }
      throw new Error(errorMessage);
    } else {
      console.error(e);
      const errorMessage =
        'Oops! An unexpected error occurred. Please try again.';
      throw new Error(errorMessage);
    }
  }
};

export const getExportProposalSummaryFile = async (
  employerId: string | undefined,
  upcomingPlanYearId: string | undefined,
  proposalId: string,
  benefitType: string[],
  employerName: string,
  offerIds: string[],
  includeDiscounts: boolean
) => {
  try {
    const response = await axios.post(
      `${baseApi}/v1/renewals/exports/proposal-summary`,
      {
        employerId: employerId,
        upcomingPlanYearId:
          upcomingPlanYearId === 'All' ? undefined : upcomingPlanYearId,
        proposalId: proposalId,
        benefitCategories: benefitType,
        offerIds: offerIds,
        includeDiscounts: includeDiscounts,
      },
      {
        responseType: 'blob',
      }
    );
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });

    const file = new File(
      [blob],
      `Export_proposal_${employerName}_${moment().format('MM-DD-YYYY')}.xlsx`,
      {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      }
    );

    return file;
  } catch (e) {
    console.log(e);
  }
};

// To download the plan comparison summary as an excel file
export const exportPlanComparisonSummary = async (
  employerId: string | undefined,
  planId: string,
  benefitType: string,
  planYearId: string,
  offerId: string,
  planName?: string,
  offerName?: string,
  employerName?: string
) => {
  try {
    const response = await axios.post(
      `${baseApi}/v1/renewals/exports/plan-comparison`,
      {
        employerId: employerId,
        planId: planId,
        benefitType: benefitType,
        planYearId: isNullOrUndefined(planYearId) ? '' : planYearId,
        offerId: offerId,
      },
      {
        responseType: 'blob',
      }
    );
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Export_${planName}_${offerName}_${employerName}_${moment().format(
        'MM-DD-YYYY'
      )}.xlsx`
    );
    document.body && document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {}
};

export const {
  useCreateProposalMutation,
  useUpdateProposalMutation,
  useLazyGetProposalByIdQuery,
  useGetProposalByIdQuery,
  useCloneProposalByIdMutation,
  useLazyGetProposalSummaryQuery,
  useGetProposalBenefitTypeDetailsQuery,
  useLazyGetPlanDataQuery,
  useProposalStatusUpdateMutation,
  useSaveOfferDiscountMutation,
  useGetProposalCreditsQuery,
  useGetProposalDiscountsQuery,
  useLazyGetProposalCreditsQuery,
  useLazyGetProposalDiscountsQuery,
  useDeleteProposalMutation,
  useUpdateProposalStatusAsRecommendedMutation,
  useUpdateProposalNotificationStatusMutation,
  useLazyGetFinalApprovedProposalQuery,
  useLazyGetProposalNamesByEmployerIdQuery,
  useLazyGetProposalSummaryExportFilterOptionsQuery,
  useCreateProposalCloneMutation,
  useGetDefaultHRAPlansQuery,
  useLazyGetDefaultHRAPlansQuery,
  useLazyCheckDraftOfferExistForProposalQuery,
} = proposalsAPI;
