import { useEffect, useRef, useState } from 'react';
import { Input, List, Popover, Typography } from 'antd';
import { ReactComponent as SearchIcon } from 'assets/images/icon-search.svg';
import { ReactComponent as CannotRemove } from 'assets/images/cannot-remove.svg';
import Button from 'components/buttons/Button/Button';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { ReactComponent as RemoveIcon } from 'assets/images/x-icon-remove.svg';
import { ReactComponent as CheckIcon } from 'assets/images/check-icon.svg';

import styles from './ieAdvanceSelect.module.less';

type OptionProps = {
  label: string;
  value: any;
};

type IEAdvanceSelectProps = {
  alreadySelectedList: OptionProps[];
  alreadySelectedListTitle?: string;
  alreadySelectedNotRemoveItem?: string;
  alreadySelectedNotRemovePopMessage?: string;
  sort?: boolean;
  showActionButton?: boolean;
  actionButtonLabel?: any;
  emptyText?: string;
  addMoreButtonShow?: boolean;
  addMoreButtonLabel?: string;
  searchPlaceholder: string;
  options: OptionProps[];
  onActionButtonClick?: () => void;
  onRemoveAdded?: (value: any) => void;
  onSelectOption: (value: any) => void;
  isNvTagApplicable?: boolean;
};

const IEAdvanceSelect = ({
  alreadySelectedList,
  alreadySelectedListTitle,
  sort,
  showActionButton,
  actionButtonLabel,
  emptyText,
  addMoreButtonShow,
  addMoreButtonLabel,
  searchPlaceholder,
  options,
  alreadySelectedNotRemoveItem,
  alreadySelectedNotRemovePopMessage,
  onActionButtonClick,
  onRemoveAdded,
  onSelectOption,
  isNvTagApplicable = false,
}: IEAdvanceSelectProps) => {
  const [filter, setFilter] = useState('');
  const [visibleSearch, setVisibleSearch] = useState(false);
  const advListRef = useRef<any>();

  const checkValueInFilters = !!filter;

  const handleClickOutside = (e: any) => {
    if (!advListRef?.current?.contains(e.target)) {
      setFilter('');
      setVisibleSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);

    // eslint-disable-next-line
  });

  const alreadySelectedListSorted = alreadySelectedList.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  // sort and filter when user input
  const getFilteredOption = (options: any[]) => {
    let optionsArray: any[] = [];
    if (sort) {
      optionsArray = options
        ?.filter(
          (f) =>
            f?.label?.toLowerCase().includes(filter.toLowerCase().trim()) ||
            filter === ''
        )
        .sort((a, b) => a.label.localeCompare(b.label));
    } else {
      optionsArray = options?.filter(
        (f) =>
          f?.label?.toLowerCase().includes(filter.toLowerCase().trim()) ||
          filter === ''
      );
    }

    return optionsArray;
  };
  // added matched tag to common items and disabled in list
  const getTagAddedToMatchedOptions = () => {
    const matched = options
      ?.filter((item) =>
        alreadySelectedList?.some((sub) => sub?.value === item?.value)
      )
      ?.map((item) => ({ ...item, matched: true }));
    const unMatched = options
      ?.filter(
        (item) =>
          !alreadySelectedList?.some((sub) => sub?.value === item?.value)
      )
      ?.map((item) => ({ ...item, matched: false }));

    return [...matched, ...unMatched];
  };

  // Tooltip content
  const popoverContent = () => (
    <p className={styles.popoverText}>{alreadySelectedNotRemovePopMessage}</p>
  );
  return (
    <div className={styles.selectWrapper}>
      <div className={styles.selectTopSection}>
        {showActionButton && (
          <>
            <LinkButton onClick={onActionButtonClick}>
              {actionButtonLabel}
            </LinkButton>
            <hr className={styles.actionHr} />
          </>
        )}

        <List
          className={styles.advanceListMain}
          header={
            alreadySelectedListSorted.length !== 0 &&
            alreadySelectedListTitle?.toUpperCase()
          }
          locale={{
            emptyText: null,
          }}
        >
          <div className={styles.advanceListItemsWrapper}>
            {alreadySelectedListSorted?.length !== 0
              ? alreadySelectedListSorted?.map((item) => {
                  if (item.value === String(alreadySelectedNotRemoveItem)) {
                    return (
                      <List.Item
                        key={item.value}
                        className={`${styles.advanceListItem} ${styles?.itemDisable}`}
                      >
                        <Typography.Text
                          className={styles.advanceListItemLabel}
                          ellipsis
                        >
                          {item.label}
                        </Typography.Text>
                        <Popover
                          content={popoverContent}
                          placement="left"
                          overlayClassName="advanceSelectPopoverInner"
                          trigger={['click']}
                        >
                          <CannotRemove className={styles?.cannotRemoveIcon} />
                        </Popover>
                      </List.Item>
                    );
                  }
                  return (
                    <List.Item
                      key={item.value}
                      className={styles.advanceListItem}
                    >
                      <Typography.Text ellipsis>{item.label}</Typography.Text>

                      <RemoveIcon
                        className={styles?.closeIcon}
                        onClick={() => onRemoveAdded && onRemoveAdded(item)}
                      />
                    </List.Item>
                  );
                })
              : null}
          </div>
        </List>
      </div>
      {!visibleSearch && addMoreButtonShow && (
        <div className={styles.addMoreButtonWrapper}>
          <Button
            className={styles.addMoreButton}
            label={addMoreButtonLabel}
            block
            onClick={() => setVisibleSearch(true)}
          />
        </div>
      )}

      {visibleSearch && (
        <div ref={advListRef} className={styles.advanceSelectListWrapper}>
          <div
            className={`${styles.searchInputWrapper} ${
              !checkValueInFilters && styles.searchInputListHideMB
            } `}
          >
            <Input
              placeholder={searchPlaceholder}
              className={styles.searchInput}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              suffix={<SearchIcon className={styles.searchIcon} />}
            />
          </div>

          {checkValueInFilters && (
            <div className={styles.selectOptionsList}>
              <List
                locale={{
                  emptyText: null,
                }}
              >
                {getFilteredOption(getTagAddedToMatchedOptions())?.length ===
                0 ? (
                  <div className={styles.emptyMessage}>{emptyText}</div>
                ) : (
                  getFilteredOption(getTagAddedToMatchedOptions())?.map(
                    (item) => {
                      return (
                        <List.Item
                          className={`${styles.listItem} ${
                            item?.matched && styles.loggedUserDisabled
                          } `}
                          key={item?.value}
                          onClick={() => {
                            // if nv is there disable the only click function for the record
                            if (!item.isNvApplicable) {
                              onSelectOption && onSelectOption(item);
                              setFilter('');
                              setVisibleSearch(false);
                            }
                          }}
                        >
                          <Typography.Text className={styles.label} ellipsis>
                            {item?.label}
                          </Typography.Text>
                          {isNvTagApplicable && item.isNvApplicable && (
                            <span className={styles.notVerifiedLabel}>
                              (not verified)
                            </span>
                          )}
                          {item?.matched && <CheckIcon />}
                        </List.Item>
                      );
                    }
                  )
                )}
              </List>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default IEAdvanceSelect;
