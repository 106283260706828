import { cloneDeep, isEmpty } from 'lodash';
import {
  ModelingBaseType,
  PlanContributionsEntity,
  RowType,
  TierContributionsDefaultOrTierContributionsNegotiated,
  TierRowType,
} from 'model/ModelingBaseType';
import {
  EC_TIER,
  EE_TIER,
  EF_TIER,
  ES_TIER,
  N_TIER_NAME_LIST,
  TIER_LIST_ENUMS,
  TIER_NAME_LIST,
} from 'modules/renewals/constants/renewalsConstants';

const isStandardTier = (tierContributionsDefault: any) => {
  return (
    Object?.keys(tierContributionsDefault)?.filter(
      (subItem: string) =>
        subItem === 'ES' || subItem === 'EC' || subItem === 'EF'
    ).length > 0
  );
};

export const transformPlanContributionToModelingTableData = (
  data: PlanContributionsEntity[]
) => {
  const planContributionData = data?.map((item) => {
    if (isStandardTier(item?.tierContributionsDefault)) {
      const sortedTierArray = TIER_NAME_LIST.map((tier) => ({
        ...item?.tierContributionsDefault?.[
          tier as keyof TierContributionsDefaultOrTierContributionsNegotiated
        ],
      }));
      return { ...item, tierContributionsDefault: sortedTierArray };
    } else {
      const keys = item?.tierContributionsDefault;
      const validKeys = N_TIER_NAME_LIST?.filter(
        (item) => Object.keys(keys)?.indexOf(item) > -1
      );
      const sortedTierArray = validKeys?.map((tier) => ({
        ...item?.tierContributionsDefault?.[
          tier as keyof TierContributionsDefaultOrTierContributionsNegotiated
        ],
      }));
      return { ...item, tierContributionsDefault: sortedTierArray };
    }
  });
  return planContributionData;
};

export const transformModelingTableDataToPlanContribution = (
  data: PlanContributionsEntity[]
) => {
  return data?.map((item: any) => {
    let tierContributionsDefault = {};
    if (isStandardTier(item?.tierContributionsDefault)) {
      tierContributionsDefault = {
        EC: item.tierContributionsDefault?.find(
          (subItem: TierRowType) => subItem?.tier === EC_TIER
        ),
        EF: item.tierContributionsDefault?.find(
          (subItem: TierRowType) => subItem?.tier === EF_TIER
        ),
        EE: item.tierContributionsDefault?.find(
          (subItem: RowType) => subItem?.tier === EE_TIER
        ),
        ES: item.tierContributionsDefault?.find(
          (subItem: TierRowType) => subItem?.tier === ES_TIER
        ),
      };
    } else {
      for (
        let index = 0;
        index < item?.tierContributionsDefault?.length;
        index++
      ) {
        tierContributionsDefault = {
          ...tierContributionsDefault,
          [item?.tierContributionsDefault?.[index]?.tier]:
            item?.tierContributionsDefault?.[index],
        };
      }
    }
    return { ...item, tierContributionsDefault };
  });
};

export const isUpdatedFieldNullCheck = (data: PlanContributionsEntity[]) => {
  // Check if every "updatedField" is null in all objects inside "tierContributionsDefault"
  return data?.every((item: any) => {
    for (const tier in item?.tierContributionsDefault) {
      if (item?.tierContributionsDefault[tier]?.updatedField !== null) {
        return false;
      }
    }
    return true;
  });
};

export const isUpdatedFieldPresent = (data: ModelingBaseType) => {
  return !!data?.planContributions?.find(
    (pc) =>
      !isEmpty(
        Object.values(pc.tierContributionsDefault).filter(
          (tc) => tc.updatedField !== null
        )
      )
  );
};

export const setBasePlanInitialUpdatedField = (data: ModelingBaseType) => {
  const modelingBaseTypesClone = cloneDeep(data);
  modelingBaseTypesClone?.planContributions?.forEach((pc) => {
    if (pc.planId === modelingBaseTypesClone?.baseBuyUpPlanId) {
      pc.tierContributionsDefault.EE.updatedField = TIER_LIST_ENUMS.ER_PREMIUM;
    }
  });
  return modelingBaseTypesClone;
};

/*
 * When rounding off we are always making sure that the initial calculation is not getting triggered
 * so in order to fix this we are intentionally updating the updated field flag in order to trigger out the
 * non initial function
 * @param Modeling Data
 */
export const setUpdatedFieldToSkipInitialCalculation = (
  data: ModelingBaseType
) => {
  const modelingBaseTypesClone = cloneDeep(data);
  modelingBaseTypesClone?.planContributions?.forEach((pc) => {
    pc.tierContributionsDefault.EE.updatedField =
      TIER_LIST_ENUMS.HSA_CONTRIBUTION;
  });
  return modelingBaseTypesClone;
};
