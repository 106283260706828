import { lumityApi } from 'api/apiSlice';

const dashboardApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<
      any,
      {
        employerId: string | undefined;
      }
    >({
      query: (args) => {
        const { employerId } = args;

        return {
          url: `v2/employers/${employerId}/dashboard`,
          method: 'get',
        };
      },
    }),

    getEmpDashboardIssues: builder.query<
      any,
      {
        employerId: string | undefined;
        organizationId: string | undefined;
      }
    >({
      query: (args) => {
        const { employerId, organizationId } = args;

        return {
          url: `v1/issues-log/dashboard`,
          method: 'get',
          params: {
            'organization-id': organizationId,
            'employer-id': employerId,
          },
        };
      },
    }),
  }),
});

export const { useGetDashboardQuery, useGetEmpDashboardIssuesQuery } =
  dashboardApi;
