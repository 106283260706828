import { FC } from 'react';
import { Button } from 'antd';

import { NativeButtonProps } from 'antd/lib/button/button';

import styles from './submitButton.module.less';

interface SubmitButtonProps extends NativeButtonProps {
  className?: string;
  dataCy?: string;
}

const SubmitButton: FC<SubmitButtonProps> = (props) => {
  const { className = styles.acceptBtn, dataCy, ...rest } = props;

  return (
    <div className={styles.acceptBtnWrapper} data-cy={`${dataCy}-wrapper`}>
      <Button data-cy={dataCy} className={className} {...rest} />
    </div>
  );
};

export default SubmitButton;
