import { loginTypes } from 'constants/authConstants';

export const brokerDashBoardPermittedTypes = [loginTypes.platform];
export const employerEditPermittedTypes = [
  loginTypes.platform,
  loginTypes.bokerAdmin,
];
export const employerBackButtonPermittedTypes = [
  loginTypes.platform,
  loginTypes.bokerAdmin,
];
export const brokerBackButtonPermittedTypes = [loginTypes.platform];

export const allowedCommonButtonPermittedTypes = [
  loginTypes.platform,
  loginTypes.bokerAdmin,
  loginTypes.erAdmin,
];
