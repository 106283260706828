import { FC, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import Rates from 'modules/plans/components/Rates/Rates';
import { getBenefitGuidesByFrequency } from 'modules/plans/slices/dbgInfoSlice';
import { BenefitCategory } from 'constants/commonConstants';
import { VisionPlan } from 'model/plans/VisionPlan';
import {
  updateVisionPlan,
  resetVisionPlanReduxStore,
  updateVisionPlanByPath,
  getPlanContributionByFrequency,
  getPlanContributionInProgress,
} from 'modules/plans/slices/visionPlanSlice';
import PanelSection from 'modules/plans/enums/PanelSection';

type RatesDBGWrapperProps = {
  currentSection: string;
  visionPlan: VisionPlan;
  dbgMetaData: any;
};

const RatesDBGVisionWrapper: FC<RatesDBGWrapperProps> = forwardRef(
  (props: RatesDBGWrapperProps, refs) => {
    const { editedVisionPlan: visionPlanState } = useAppSelector(
      (state) => state.plan.visionPlan
    );
    const requestTypePlans = useAppSelector(
      (state) => state.plan.visionPlan.requestType
    );
    const { dbgData, requestType } = useAppSelector(
      (state) => state.plan.dgbInfo
    );
    const { dbgMetaData, visionPlan } = props;
    const [ratesForm] = Form.useForm();
    const dispatch = useAppDispatch();
    const { employerId } = useNavContext();
    const { dbgByFrequency, rateValidations } = dbgData;
    const ratesRef = useRef<any>();

    useImperativeHandle(refs, () => ({
      reset() {
        dispatch(resetVisionPlanReduxStore());
        ratesRef?.current?.resetAll();
      },
    }));

    useEffect(() => {
      employerId &&
        visionPlan.id &&
        dispatch(
          getBenefitGuidesByFrequency(
            employerId,
            visionPlan.planYearId,
            BenefitCategory.VISION.value,
            visionPlan.id
          )
        );
      dispatch(updateVisionPlan(visionPlan));
    }, [dispatch, employerId, visionPlan]);

    if (isEmpty(visionPlanState)) {
      return <div></div>;
    }

    return (
      <Rates
        benefitCategory={BenefitCategory.VISION.value}
        updatePlanByPathAction={updateVisionPlanByPath}
        getContributionAction={getPlanContributionByFrequency}
        benefitGuideObj={dbgMetaData}
        plan={visionPlanState}
        form={ratesForm}
        currentSection={PanelSection.RATES}
        dbgByFrequency={dbgByFrequency}
        isDBGView={true}
        requestType={requestType}
        rateValidations={rateValidations}
        getContributionInProgress={
          requestTypePlans === getPlanContributionInProgress.type
        }
      />
    );
  }
);
RatesDBGVisionWrapper.displayName = 'RatesDBGVisionWrapper';
export default RatesDBGVisionWrapper;
