import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { Popover, Switch } from 'antd';
import { DataColumn } from 'components/DataTable/DataColumn';
import ToolList from 'components/ToolList/ToolList';
import {
  useGetEmployersQuery,
  useUpdateEmployerFeatureStatusMutation,
} from 'api/featureControl';
import TablePagination from 'model/TablePagination';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { EMPLOYER_ACCESS_LOCKED, allOption } from 'constants/commonConstants';
import { FUNDING_TYPE } from 'modules/claims/constants/constants';
import EmployerFeatureToggle from 'components/EmployerFeatureToggle/EmployerFeatureToggle';
import EmployerVisibilityOption from 'components/EmployerVisibilityOption/EmployerVisibilityOption';

import styles from './featureList.module.less';

type EmployerClaimsFeatureListProps = {};

const EmployerClaimsFeatureList: FC<EmployerClaimsFeatureListProps> = (
  props: EmployerClaimsFeatureListProps
) => {
  const ALL_FUNDING_LABEL = 'Fully Insured, Self Funded';
  const FULLY_INSURED_LABEL = 'Fully Insured';
  const SELF_FUNDED_LABEL = 'Self-Funded';
  const EMPTY_LABEL = '-';

  const navigate = useNavigate();
  const { brokerId } = useParams();
  const [updateStatus, { isSuccess }] = useUpdateEmployerFeatureStatusMutation(
    {}
  );
  const [searchText, setSearchText] = useState<string>('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState('name');
  const {
    data: featureWrappers = [],
    refetch,
    isFetching,
  } = useGetEmployersQuery({
    page: pageIndex,
    organizationId: brokerId as string,
    key: 'CLAIMS',
    query: searchText,
    size: pageSize,
    sort: sortOrder,
  });
  const [filteredWrappers, setFilteredWrappers] = useState(featureWrappers);

  const navigateToClaims = (employerName: string) => {
    featureWrappers.forEach((employer: any) => {
      if (employer.employerName === employerName) {
        navigate(
          `/brokers/${brokerId}/employers/${employer.employerId}/claims`,
          {
            state: { bypassInvalidRoute: true },
          }
        );
        return;
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [refetch, isSuccess]);

  useEffect(() => {
    refetch();
  }, [refetch, searchText]);

  const dataColumns: DataColumn[] = [
    {
      title: 'EMPLOYER',
      dataIndex: 'employerName',
      key: 'employerName',
      width: '28%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.employerName.localeCompare(b.employerName),
      defaultSortOrder: 'ascend',
      render: (item, index) => {
        return (
          <OverflowPopover key={index}>
            <a
              className={'text table-item-link'}
              onClick={() => navigateToClaims(item)}
            >
              {item}
            </a>
          </OverflowPopover>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'CLAIMS TYPE',
      dataIndex: 'fundingType',
      key: 'fundingType',
      width: '30%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        if (a.fundingType === EMPTY_LABEL) {
          return 1;
        } else if (b.fundingType === EMPTY_LABEL) {
          return -1;
        } else if (a.fundingType === FULLY_INSURED_LABEL) {
          return -1;
        } else if (b.fundingType === FULLY_INSURED_LABEL) {
          return 1;
        } else if (a.fundingType === SELF_FUNDED_LABEL) {
          return -1;
        } else if (b.fundingType === SELF_FUNDED_LABEL) {
          return 1;
        } else if (a.fundingType === ALL_FUNDING_LABEL) {
          return -1;
        } else if (b.fundingType === ALL_FUNDING_LABEL) {
          return 1;
        } else {
          return 0;
        }
      },
      render: (item, index) => {
        return isEmpty(item) ? (
          <div key={index}>-</div>
        ) : (
          <OverflowPopover key={index}>
            <span>{item}</span>
          </OverflowPopover>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'LATEST CLAIMS DATA',
      dataIndex: 'claimsLastUpdated',
      key: 'claimsLastUpdated',
      width: '40%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        if (a.claimsLastUpdated === EMPTY_LABEL) {
          return 1;
        }
        const dateStrA = a.claimsLastUpdated.split(' ');
        const monthA = dateStrA[0];
        const yearA = dateStrA[1];
        const dateStrB = b.claimsLastUpdated.split(' ');
        const monthB = dateStrB[0];
        const yearB = dateStrB[1];
        if (yearA !== yearB) {
          return yearA.localeCompare(yearB);
        } else if (monthA === 'January') {
          return -1;
        } else if (monthB === 'January') {
          return 1;
        } else if (monthA === 'February') {
          return -1;
        } else if (monthB === 'February') {
          return 1;
        } else if (monthA === 'March') {
          return -1;
        } else if (monthB === 'March') {
          return 1;
        } else if (monthA === 'April') {
          return -1;
        } else if (monthB === 'April') {
          return 1;
        } else if (monthA === 'May') {
          return -1;
        } else if (monthB === 'May') {
          return 1;
        } else if (monthA === 'June') {
          return -1;
        } else if (monthB === 'June') {
          return 1;
        } else if (monthA === 'July') {
          return -1;
        } else if (monthB === 'July') {
          return 1;
        } else if (monthA === 'August') {
          return -1;
        } else if (monthB === 'August') {
          return 1;
        } else if (monthA === 'September') {
          return -1;
        } else if (monthB === 'September') {
          return 1;
        } else if (monthA === 'October') {
          return -1;
        } else if (monthB === 'October') {
          return 1;
        } else if (monthA === 'November') {
          return -1;
        } else if (monthB === 'November') {
          return 1;
        } else if (monthA === 'December') {
          return -1;
        } else if (monthB === 'December') {
          return 1;
        } else {
          return 0;
        }
      },
      render: (item, index) => {
        return isEmpty(item) ? (
          <div key={index}>-</div>
        ) : (
          <OverflowPopover key={index}>
            <span>{item}</span>
          </OverflowPopover>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'lastUpdatedTs',
      key: 'lastUpdatedTs',
      width: '40%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (isEmpty(a.lastUpdatedTs)) {
          return 1;
        } else if (isEmpty(b.lastUpdatedTs)) {
          return -1;
        } else {
          return new Date(a.lastUpdatedTs).getTime() >
            new Date(b.lastUpdatedTs).getTime()
            ? 1
            : -1;
        }
      },
      render: (colData, record, index) => {
        const updatedAt = dayjs(colData).format('MMMM D, YYYY h:mm A');
        return isEmpty(colData) ? (
          <div key={index}>-</div>
        ) : (
          <div key={index}>
            {updatedAt}
            <br></br>
            <span className={styles.addedBy}>
              by {record.lastUpdatedByAdminName}
            </span>
          </div>
        );
      },
    },
    {
      title: 'EMPLOYER VISIBILITY',
      dataIndex: 'employerVisibilityEnabled',
      key: 'employerVisibilityEnabled',
      width: '20%',
      render: (colData, record) => {
        return (
          <>
            <EmployerVisibilityOption
              onConfirmToggle={() => {
                updateStatus({
                  enabled: record.enabled,
                  employerVisibilityEnabled: !colData,
                  organizationId: record.organizationId,
                  employerId: record.employerId,
                  key: 'CLAIMS',
                }).then(() => {
                  refetch();
                });
              }}
              enabled={record.enabled}
              employerVisibilityEnabled={colData}
              employerName={record.employerName}
              toolName={'Claims'}
            />
          </>
        );
      },
      align: 'left',
    },
    {
      title: 'ENABLED',
      dataIndex: 'enabled',
      key: 'enabled',
      width: '15%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (a.enabled) {
          return -1;
        } else {
          return 1;
        }
      },
      render: (colData, record, index) => {
        return (
          <>
            {record.locked ? (
              <Popover
                trigger="hover"
                content={EMPLOYER_ACCESS_LOCKED}
                placement="left"
                overlayClassName="provisionStatusPopover"
              >
                <Switch onChange={() => {}} checked={false} disabled={true} />
              </Popover>
            ) : (
              <EmployerFeatureToggle
                onConfirmToggle={() => {
                  updateStatus({
                    enabled: !colData,
                    employerVisibilityEnabled: record.employerVisibilityEnabled,
                    organizationId: record.organizationId,
                    employerId: record.employerId,
                    key: 'CLAIMS',
                  });
                }}
                enabled={colData}
                recordName={record.employerName}
                enableMessage={
                  'Are you sure you want to enable Claims? This employer will be able to access their claims data.'
                }
                disableMessage={
                  "Are you sure you want to disable Claims? This tool will be immediately hidden from the employer's view."
                }
                title={'Claims'}
                key={index}
              />
            )}
          </>
        );
      },
      align: 'left',
    },
  ];

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();

    setSearchText(_searchText);
  };

  const getMonthYearString = (dateObject: any) => {
    if (dateObject === null) {
      return EMPTY_LABEL;
    }
    const date = new Date(dateObject);
    return `${date.toLocaleString([], {
      month: 'long',
    })} ${date.getFullYear()}`;
  };

  const getFundingType = (data: any) => {
    if (data.fullyInsuredAndSelfFunded) {
      return ALL_FUNDING_LABEL;
    } else if (data.fullyInsured) {
      return FULLY_INSURED_LABEL;
    } else if (data.selfFunded) {
      return SELF_FUNDED_LABEL;
    } else {
      return EMPTY_LABEL;
    }
  };

  const buildFeatureData = useCallback((wrappers: []) => {
    return wrappers.map((employerFeature: any) => {
      if (employerFeature.featureData) {
        employerFeature = {
          ...employerFeature,
          fundingType: getFundingType(employerFeature.featureData),
          claimsLastUpdated: getMonthYearString(
            employerFeature.featureData.lastUpdated
          ),
        };
      } else {
        employerFeature = {
          ...employerFeature,
          fundingType: '-',
          claimsLastUpdated: '-',
        };
      }

      return employerFeature;
    });
  }, []);

  const onChangeClaimsTypeFilter = (selected: string[]) => {
    selected.includes(allOption)
      ? filterWrappers(allOption)
      : filterWrappers(selected[0]);
  };

  const filterWrappers = (claimsType: string) => {
    const builtFeatureWrappers = buildFeatureData(featureWrappers);
    if (!claimsType || claimsType === allOption) {
      setFilteredWrappers(builtFeatureWrappers);
    } else {
      const claimsLabel =
        claimsType === FUNDING_TYPE.FULLY_INSURED
          ? FULLY_INSURED_LABEL
          : SELF_FUNDED_LABEL;
      setFilteredWrappers(
        builtFeatureWrappers.filter((wrapper: any) => {
          return (
            claimsLabel === wrapper.fundingType ||
            wrapper.fundingType === ALL_FUNDING_LABEL
          );
        })
      );
    }
  };

  useEffect(() => {
    if (featureWrappers) {
      setFilteredWrappers(buildFeatureData(featureWrappers));
    }
  }, [featureWrappers, buildFeatureData, setFilteredWrappers]);

  useEffect(() => {
    setPaginationConfig(paginationConfig);
    setPageIndex(paginationConfig.paginationIndex);
    setPageSize(paginationConfig.filterInfo.limit);
    const { field, order } = (paginationConfig.sorterInfo as any) || {};
    if (isEmpty(order)) {
      setSortOrder('');
    } else if (field == 'employerName' && order == 'descend') {
      setSortOrder('-name');
    } else if (field == 'employerName' && order == 'ascend') {
      setSortOrder('name');
    }
  }, [paginationConfig, isFetching]);

  return (
    <div className={styles.featureTableWrapper}>
      <ToolList
        title="Claims Configuration"
        columns={dataColumns}
        data={filteredWrappers}
        hasClaimsTypeFilter={true}
        search={'Search Employers'}
        handleSearch={handleSearch}
        isLoading={isFetching}
        onSelectItems={onChangeClaimsTypeFilter}
      ></ToolList>
    </div>
  );
};

export default EmployerClaimsFeatureList;
