import { FC } from 'react';
import { Select, SelectProps } from 'antd';

import styles from './selectOptions.module.less';

const SelectOptions: FC<
  SelectProps<any> & { customClass?: string; wrapperClass?: string }
> = (props) => {
  const { customClass, wrapperClass, ...rest } = props;

  return (
    <div
      className={`${styles.selectOptionsContainer} ${customClass} ${
        styles?.[wrapperClass ?? '']
      }`}
    >
      <Select {...rest}></Select>
    </div>
  );
};

export default SelectOptions;
