import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchCheckOfferExistsInProposal } from 'modules/renewals/services/OfferServices';
import { CREDITS_INCLUSION } from 'modules/renewals/constants/renewalsConstants';

interface offerSelectionState {
  selectedCreditMode: number;
  selectedProposalsCreditMode: keyof typeof CREDITS_INCLUSION;
  associatedProposalList: any[];
  associatedProposalListLoading: boolean;
  associatedProposalListError: any;
  isFromProposalNavigatePY: string | null;
  isFromOfferNavigatePY: string | null;
}

const initialState: offerSelectionState = {
  selectedCreditMode: 0,
  selectedProposalsCreditMode: CREDITS_INCLUSION.EXCLUDED.value,
  associatedProposalList: [],
  associatedProposalListLoading: false,
  associatedProposalListError: null,
  isFromProposalNavigatePY: null,
  isFromOfferNavigatePY: null,
};

export const offerReducerSlice = createSlice({
  name: 'offers',
  initialState: initialState,
  reducers: {
    setCreditMode: (state, action) => {
      state.selectedCreditMode = action.payload.creditMode;
    },
    setProposalsCreditMode: (
      state,
      { payload }: PayloadAction<keyof typeof CREDITS_INCLUSION>
    ) => {
      state.selectedProposalsCreditMode = payload;
    },
    setAssociatedProposalListLoading: (state) => {
      state.associatedProposalListLoading = true;
    },
    setAssociatedProposalList: (state, action) => {
      state.associatedProposalList = action.payload;
      state.associatedProposalListLoading = false;
    },
    setAssociatedProposalListError: (state, action) => {
      state.associatedProposalListError = action.payload;
      state.associatedProposalListLoading = false;
    },
    clearAssociatedProposalList: (state) => {
      state.associatedProposalList = [];
      state.associatedProposalListLoading = false;
      state.associatedProposalListError = null;
    },
    setIsFromProposalNavigatePY: (state, { payload }) => {
      state.isFromProposalNavigatePY = payload;
    },
    clearIsFromProposalNavigatePY: (state) => {
      state.isFromProposalNavigatePY = null;
    },
    setIsFromOfferNavigatePY: (state, { payload }) => {
      state.isFromOfferNavigatePY = payload;
    },
    clearIsFromOfferNavigatePY: (state) => {
      state.isFromOfferNavigatePY = null;
    },
  },
});

export const {
  setCreditMode,
  setProposalsCreditMode,
  setAssociatedProposalListLoading,
  setAssociatedProposalList,
  setAssociatedProposalListError,
  clearAssociatedProposalList,
  setIsFromProposalNavigatePY,
  clearIsFromProposalNavigatePY,
  setIsFromOfferNavigatePY,
  clearIsFromOfferNavigatePY,
} = offerReducerSlice.actions;

export default offerReducerSlice.reducer;

export const getAssociatedProposals =
  (offerId: string) => async (dispatch: Dispatch) => {
    dispatch(setAssociatedProposalListLoading());

    try {
      const data = await fetchCheckOfferExistsInProposal(offerId);
      dispatch(setAssociatedProposalList(data));
    } catch (error) {
      dispatch(setAssociatedProposalListError(error));
    }
  };
