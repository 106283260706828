import { Fragment, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { isEmpty, last } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import RenderSelectionType from 'modules/assistant/enums/RenderSelectionType';
import TextBubble from 'modules/assistant/components/TextBubble/TextBubble';
import ConversationLayout from 'modules/assistant/layout/NewChatLayout/ConversationLayout';
import {
  clearChatHistoryConversation,
  getChatHistoryConversation,
  initialConversation,
  setConversationId,
  setSelectionType,
} from 'modules/assistant/slices/conversationSlice';
import { NEW_CHAT_NAV_CONSTANT } from 'modules/assistant/constants/constants';

const AssistantPage = () => {
  const location = useLocation();
  const { initialize = false } = (location.state as {
    initialize: boolean;
  }) ?? { initialize: false };
  const { chatId } = useParams();
  const dispatch = useAppDispatch();
  const { selectionType, promptType, messages, conversationId, inProgress } =
    useAppSelector((state) => state.assistant.current) ?? initialConversation;

  const lastMessage = last(messages ?? []);
  const isError = lastMessage?.error ?? false;
  const layoutRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isEmpty(chatId)) {
      if (chatId === NEW_CHAT_NAV_CONSTANT) {
        dispatch(clearChatHistoryConversation());
        dispatch(setSelectionType(RenderSelectionType.SELECTION));
      } else {
        dispatch(getChatHistoryConversation(chatId!));
        dispatch(setConversationId(chatId!));
        dispatch(setSelectionType(RenderSelectionType.COMPLETED));
      }
    }
  }, [chatId, dispatch]);

  useEffect(() => {
    if (!initialize) {
      dispatch(clearChatHistoryConversation());
    }
  }, [dispatch, initialize]);

  const renderMessagePairs = () =>
    messages?.map((message, index) => (
      <Fragment key={index}>
        <TextBubble content={message.question!} animate={false} isQuestion />
        {!message.error && (message.answer || message.isPending) && (
          <TextBubble
            id={message?.id!}
            content={message?.answer!}
            animate={message?.animate ?? false}
            layoutRef={layoutRef}
            isPending={message?.isPending ?? false}
          />
        )}
      </Fragment>
    ));

  return (
    <ConversationLayout
      conversationId={conversationId}
      ref={layoutRef}
      selectionType={selectionType!}
      type={promptType!}
      isLoading={inProgress}
      error={isError}
    >
      {selectionType === RenderSelectionType.COMPLETED && renderMessagePairs()}
    </ConversationLayout>
  );
};

export default AssistantPage;
