import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isEmpty, isNull } from 'lodash';
import { Spin } from 'antd';
import { loginTypes } from 'constants/authConstants';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import BillingFileHistory from 'modules/billing/components/BillingFileHistory/BillingFileHistory';
import { useNavContext } from 'hooks/useNavContext';
import { useAppSelector } from 'hooks/redux';
import {
  useAvaiableMonthsQuery,
  useBillingSummaryQuery,
  useBillingTrendQuery,
  useExportFileQuery,
  useGetBillingFileHistoryQuery,
} from 'modules/billing/slices/billingSlice';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import { FEATURE_KEYS } from 'constants/commonConstants';
import EmployerFeatureDisabledBanner from 'modules/tools/components/EmployerFeatureDisabledBanner/EmployerFeatureDisabledBanner';
import BillingView from './BillingView';
import BillingUpload from './BillingUpload';
import NoBillingView from './NoBillingView';
import styles from './billingHomePage.module.less';

const BillingHomePage = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const hyperlinkMonth = searchParams.get('month');
  const hyperlinkYear = searchParams.get('year');
  const [invoiceMonth, setInvoiceMonth] = useState<any>(moment());
  const [isBillingManageOpen, setIsBillingManageOpen] =
    useState<boolean>(false);
  const [isBillingManageModalOpen, setIsBillingManageModalOpen] =
    useState<boolean>(false);
  const onClickImportBilling = () => {
    setIsBillingManageOpen(false);
    setIsUploaderOpen(isBillingManageOpen);
  };
  const [isInitial, setIsInitial] = useState<boolean>(true);
  const [checkUrlParam, setCheckUrlParam] = useState<boolean>(false);
  const [monthlyTrendFrom, setmonthlyTrendFrom] = useState<any>(
    moment('01/01' + '/' + moment().year())
  );
  const [monthlyTrendTo, setmonthlyTrendTo] = useState<any>(
    moment('12/01' + '/' + moment().year())
  );
  const [errorCode, setErrorCode] = useState<any>();
  const [rtkSkipTrend, setrtkSkipTrend] = useState<boolean>(false);

  const [selectedDetailedFile, setSelectedDetailedFile] = useState<File | null>(
    null
  );
  const [selectedSummaryFile, setSelectedSummaryFile] = useState<File | null>(
    null
  );
  const [isPreiviewFile, setIsPreiviewFile] = useState<boolean>(false);
  const [isMonthDisbaled, setIsMonthDisbaled] = useState<boolean>(false);
  const [recordData, setRecordData] = useState<string | null>(null);

  const appBootInfo: any = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );

  // Constant
  const isNotErAdmin: boolean = appBootInfo?.type !== loginTypes.erAdmin;

  const { employer } = useNavContext();

  const {
    isFetching: avaiableMonthsIsFetching,
    data: avaiableMonthsData,
    refetch: avaiableMonthsRefetch,
  } = useAvaiableMonthsQuery({
    employerId: params.employerId ?? '',
  });

  const { isFetching, data, refetch, error } = useBillingSummaryQuery({
    employerId: params.employerId ?? '',
    month: invoiceMonth.month() + 1 ?? hyperlinkMonth,
    year: invoiceMonth.year() ?? hyperlinkYear,
  });

  const {
    isFetching: exportCarrierFileIsFetching,
    isError: exportCarrierFileIsError,
    refetch: exportCarrierFileRefetch,
  } = useExportFileQuery({
    billingId: data?.billingId ?? '',
    type: 'CARRIER',
  });

  const { data: billingTrendsData, refetch: billingTrendsRefetch } =
    useBillingTrendQuery(
      {
        employerId: params.employerId ?? '',
        startYear: monthlyTrendFrom.year(),
        startMonth: monthlyTrendFrom.month() + 1,
        endYear: monthlyTrendTo?.year() ?? 2022,
        endMonth: (monthlyTrendTo?.month() ?? 0) + 1,
      },
      { skip: rtkSkipTrend }
    );

  const {
    data: BillingFileHistoryList,
    refetch: billingFileHistoryListRefetch,
  } = useGetBillingFileHistoryQuery({
    employerId: params.employerId,
  });

  const billingUploadRef = useRef();
  const [isUploaderOpen, setIsUploaderOpen] = useState<boolean>(false);

  useEffect(() => {
    exportCarrierFileRefetch();
    billingFileHistoryListRefetch();
    avaiableMonthsRefetch();
    refetch();
    billingTrendsRefetch();
    setIsInitial(true);
  }, [
    isBillingManageModalOpen,
    exportCarrierFileRefetch,
    avaiableMonthsRefetch,
    billingFileHistoryListRefetch,
    billingTrendsRefetch,
    refetch,
  ]);

  useEffect(() => {
    refetch();
  }, [invoiceMonth, refetch]);

  useEffect(() => {
    if (!isEmpty(monthlyTrendTo) && !isNull(monthlyTrendTo)) {
      billingTrendsRefetch();
      refetch();
      setrtkSkipTrend(false);
    } else {
      setrtkSkipTrend(true);
    }
  }, [monthlyTrendTo, billingTrendsRefetch, refetch]);

  const isDraftAvaiable = (BillingFileHistoryList?.records?.length ?? 0) !== 0;

  useEffect(() => {
    avaiableMonthsRefetch();
    setCheckUrlParam(false);
    setIsInitial(true);
    // eslint-disable-next-line
  }, [employer]);

  useEffect(() => {
    exportCarrierFileRefetch();
  }, [data?.billingId, exportCarrierFileRefetch]);

  const getLatestMonth = () => {
    let dateInitial = '' as any;
    let indexInitial = 0;
    avaiableMonthsData.map((item: any, index: any) => {
      if (
        index === 0 ||
        moment(item?.month + '/01/' + item?.year).isSameOrAfter(dateInitial)
      ) {
        dateInitial = moment(item?.month + '/01/' + item?.year);
        indexInitial = index;
      }
    });
    const month = avaiableMonthsData[indexInitial]?.month;
    const year = avaiableMonthsData[indexInitial]?.year;
    setInvoiceMonth(moment('01/' + month + '/' + year));
    setmonthlyTrendFrom(moment('01/01/' + year));
    setmonthlyTrendTo(moment('12/01/' + year));
  };

  useEffect(() => {
    if (!avaiableMonthsIsFetching) {
      if (
        ((hyperlinkMonth === null && hyperlinkYear === null) ||
          checkUrlParam) &&
        isInitial
      ) {
        setIsInitial(false);
        setCheckUrlParam(true);
        if (avaiableMonthsData.length !== 0 && isInitial) {
          getLatestMonth();
        } else if ((avaiableMonthsData?.length ?? 0) === 0 && isInitial) {
          setInvoiceMonth(moment().add(5, 'years'));
          setmonthlyTrendFrom(moment('01/01' + '/' + moment().year()));
          setmonthlyTrendTo(moment('01/12' + '/' + moment().year()));
        }
      } else if (isInitial && !checkUrlParam) {
        setCheckUrlParam(true);
        setIsInitial(false);
        const dateAvailable = avaiableMonthsData?.filter((date: any) => {
          return (
            date.month === hyperlinkMonth?.toUpperCase() &&
            date.year.toString() === hyperlinkYear
          );
        });
        if ((dateAvailable?.length ?? 0) !== 0) {
          setInvoiceMonth(moment('01/' + hyperlinkMonth + '/' + hyperlinkYear));
          setmonthlyTrendFrom(moment('01/01/' + hyperlinkYear));
          setmonthlyTrendTo(moment('12/01/' + hyperlinkYear));
        } else {
          getLatestMonth();
        }
      }
    }
    // eslint-disable-next-line
  }, [avaiableMonthsIsFetching, refetch, avaiableMonthsData, isInitial]);

  useEffect(() => {
    setErrorCode(error);
  }, [error]);

  useEffect(() => {
    if (!isUploaderOpen) {
      billingFileHistoryListRefetch();
      avaiableMonthsRefetch();
      refetch();
      billingTrendsRefetch();
      setIsInitial(true);
      setSelectedDetailedFile(null);
      setSelectedSummaryFile(null);
      setIsPreiviewFile(false);
      setIsMonthDisbaled(false);
      setRecordData(null);
    }
  }, [
    isUploaderOpen,
    avaiableMonthsRefetch,
    billingFileHistoryListRefetch,
    billingTrendsRefetch,
    refetch,
  ]);

  if (avaiableMonthsIsFetching) {
    return <Spin />;
  }

  return data === undefined || errorCode !== undefined ? (
    <>
      <EmployerFeatureDisabledBanner
        feature={FEATURE_KEYS.BILLING}
        featureName="Billing"
        employerId={params.employerId}
      />
      {!isFetching && (
        <>
          <BillingUpload
            ref={billingUploadRef}
            isUploaderOpen={isUploaderOpen}
            setIsUploaderOpen={setIsUploaderOpen}
            openBilling={setIsBillingManageOpen}
            selectedDetailedFile={selectedDetailedFile}
            seclectedSummaryFile={selectedSummaryFile}
            isPreviewFile={isPreiviewFile}
            isMonthDisabled={isMonthDisbaled}
            recordData={recordData}
            setSelectedSummaryFile={setSelectedSummaryFile}
            setSelectedDetailFile={setSelectedDetailedFile}
            setIsPreviewFile={setIsPreiviewFile}
          />
          <NoBillingView
            isUploaderOpen={setIsUploaderOpen}
            isDraftsAvaialble={isDraftAvaiable}
            isBillingManageOpen={setIsBillingManageOpen}
          />
        </>
      )}
      <div>
        <FullScreenModal
          visible={isBillingManageOpen}
          onCancel={() => {
            setIsBillingManageOpen(false);
            setIsBillingManageModalOpen(!isBillingManageModalOpen);
          }}
          footer={false}
          title="Manage Billing"
        >
          <BillingFileHistory
            openImportBilling={onClickImportBilling}
            closeManageBillingModal={setIsBillingManageOpen}
            setIsBillingManageModalOpen={setIsBillingManageModalOpen}
            isClosed={isBillingManageModalOpen}
            setSelectedSummaryFile={setSelectedSummaryFile}
            setSelectedDetailFile={setSelectedDetailedFile}
            isPublishFile={setIsPreiviewFile}
            isMonthDisabled={setIsMonthDisbaled}
            recordData={setRecordData}
          />
        </FullScreenModal>
      </div>
    </>
  ) : (
    <>
      <EmployerFeatureDisabledBanner
        feature={FEATURE_KEYS.BILLING}
        featureName="Billing"
        employerId={params.employerId}
      />
      <div className={styles.billingHomePage}>
        <div className={styles.billingPageTitle}>
          <div className={styles.billingEmployerName}>
            <h1>{employer?.name} Billing</h1>
          </div>
          {isNotErAdmin && (
            <div className={styles.billingManage}>
              <div>
                <PageActionButton
                  type="primary"
                  onClick={() => {
                    setIsBillingManageOpen(true);
                  }}
                  className={styles.billingManageActionButton}
                >
                  <SettingOutlined /> Manage Billing
                </PageActionButton>
              </div>
            </div>
          )}
        </div>
        <div>
          <div className={styles.billingViewWrapper}>
            <BillingView
              isCarrierFileEnabled={exportCarrierFileIsError}
              tableData={data}
              invoiceDate={invoiceMonth}
              setInvoiceDate={setInvoiceMonth}
              monthlyTrendFrom={monthlyTrendFrom}
              setMonthlyTrendFrom={setmonthlyTrendFrom}
              monthlyTrendTo={monthlyTrendTo}
              setmonthlyTrendTo={setmonthlyTrendTo}
              avaiableMonthsData={avaiableMonthsData}
              chartData={billingTrendsData}
              billingHistoryIsOpen={isBillingManageOpen}
              employerName={employer?.name ?? ''}
              billingMonth={invoiceMonth.month() + 1}
              billingYear={invoiceMonth.year()}
              isLoading={
                (exportCarrierFileIsFetching && !exportCarrierFileIsError) ||
                isFetching
              }
            />
          </div>
        </div>
      </div>
      <BillingUpload
        openBilling={setIsBillingManageOpen}
        ref={billingUploadRef}
        isUploaderOpen={isUploaderOpen}
        setIsUploaderOpen={setIsUploaderOpen}
        selectedDetailedFile={selectedDetailedFile}
        seclectedSummaryFile={selectedSummaryFile}
        isPreviewFile={isPreiviewFile}
        isMonthDisabled={isMonthDisbaled}
        recordData={recordData}
        setSelectedSummaryFile={setSelectedSummaryFile}
        setSelectedDetailFile={setSelectedDetailedFile}
        setIsPreviewFile={setIsPreiviewFile}
      />
      <div>
        <FullScreenModal
          visible={isBillingManageOpen}
          onCancel={() => {
            setIsBillingManageOpen(false);
            setIsBillingManageModalOpen(!isBillingManageModalOpen);
          }}
          footer={false}
          title="Manage Billing"
        >
          <BillingFileHistory
            openImportBilling={onClickImportBilling}
            closeManageBillingModal={setIsBillingManageOpen}
            setIsBillingManageModalOpen={setIsBillingManageModalOpen}
            isClosed={isBillingManageModalOpen}
            setSelectedSummaryFile={setSelectedSummaryFile}
            setSelectedDetailFile={setSelectedDetailedFile}
            isPublishFile={setIsPreiviewFile}
            isMonthDisabled={setIsMonthDisbaled}
            recordData={setRecordData}
          />
        </FullScreenModal>
      </div>
    </>
  );
};
export default BillingHomePage;
