import axios from 'axios';
import { baseApi } from 'util/apiUtil';

export const getBillingDownloadFile = (fileId: string, fileType: string) => {
  return axios
    .get(`${baseApi}/v2/billing/${fileId}/files/export?type=${fileType}`)
    .catch(() => {
      return { status: 500 };
    });
};

export const deleteBillingFile = (billingId: string) => {
  return axios.delete(`${baseApi}/v2/billing/${billingId}/delete`).catch(() => {
    return { status: 500 };
  });
};
