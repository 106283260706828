import { FC, useRef } from 'react';

import { useStateCallback } from 'hooks/updateState';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import CarrierCreate from 'modules/carriers/components/CarrierCreateEdit/CarrierCreate';
import { benefitTypes } from 'modules/carriers/constants/carrierConstants';

import styles from './carrierCreateModal.module.less';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  isEdit: boolean;
  benefitCategory?: string;
  setIsBenefitCarrierAdd?: (isBenefitCarrierAdd: boolean) => void;
  isBenefitCarrierAdd?: boolean;
};

const CarrierCreateModal: FC<Props> = (props: Props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    isEdit,
    benefitCategory,
    setIsBenefitCarrierAdd,
    isBenefitCarrierAdd,
  } = props;
  const closeModalRef = useRef<any>(null);
  const [isConfirmOpen, setIsConfirmOpen] = useStateCallback(false);

  const handleCloseModal = () => {
    setIsConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setIsConfirmOpen(false, () => {
      setIsModalOpen(false);
      if (setIsBenefitCarrierAdd) {
        setIsBenefitCarrierAdd(false);
      }
    });
    if (closeModalRef.current) {
      closeModalRef.current.closeAll();
    }
  };

  const closeWithoutConfirm = () => {
    setIsConfirmOpen(false);
    setIsModalOpen(false);
    if (setIsBenefitCarrierAdd) {
      setIsBenefitCarrierAdd(false);
    }
  };

  return (
    <FullScreenModal
      visible={isModalOpen}
      onCancel={handleCloseModal}
      footer={false}
      title={`${isEdit ? 'Edit' : 'Add'} ${
        benefitCategory ? benefitTypes[benefitCategory].label : ''
      } Carrier`}
      forceRender
    >
      <CarrierCreate
        closeForm={closeWithoutConfirm}
        isEdit={isEdit}
        isModalOpen={isModalOpen}
        isBenefitCarrierAdd={isBenefitCarrierAdd}
        ref={closeModalRef}
        onCancel={handleCloseModal}
      />
      {isConfirmOpen && (
        <ConfirmationDialog
          title="Are you sure you want to close?"
          confirmText="Yes - close and do not save"
          cancelText="Cancel"
          closeModal={() => setIsConfirmOpen(false)}
          onConfirm={handleCloseConfirm}
          visible={isConfirmOpen}
        >
          <p className={styles.warningConfirmation}>
            If you leave now, you will lose any unsaved data. Do you wish to
            continue?
          </p>
        </ConfirmationDialog>
      )}
    </FullScreenModal>
  );
};

export default CarrierCreateModal;
