import { ChangeEvent, forwardRef, useEffect, useImperativeHandle } from 'react';
import { Row, Col, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import InputForm from 'components/InputForm/InputForm';
import LabelWithTooltip from 'components/LabelWithTooltip/LabelWithTooltip';
import { EMPTY_MESSAGE } from 'constants/commonConstants';
import { LifePlanFormData } from 'modules/plans/types/types';

import styles from './voluntaryPlanInformation.module.less';

const VoluntaryPlanInfoTooltipContent = () => (
  <div className={styles.popoverContent}>
    <p>
      <div className={styles.tooltipHeader}>Voluntary Life Benefit</div>
      <br />
      Type the benefit information as it appears in the
      <br />
      Plan Summary. For Example: Increments of
      <br />
      $10,000 not to exceed 5x annual salary up to
      <br />
      $500,000.
    </p>
  </div>
);

type VoluntaryPlanInformationProps = {
  isCancelButtonClickConfirm: boolean;
  setFormData: Function;
  formData: LifePlanFormData;
  lifePlanType: string;
  isEditMode: boolean;
  isTitleVisible?: boolean;
};

const VoluntaryPlanInformation = forwardRef(
  (props: VoluntaryPlanInformationProps, ref) => {
    const {
      isCancelButtonClickConfirm,
      setFormData,
      formData,
      isEditMode,
      isTitleVisible = true,
    } = props;
    const [form] = Form.useForm();

    const onInputChange = async (allValues: any, value: any) => {
      form.setFieldsValue(allValues);

      setFormData({ ...formData, ...allValues });
    };

    useEffect(() => {
      if (formData && isEditMode) {
        form.setFieldsValue({ ...formData });
      }
    }, [formData, form, isEditMode]);

    useEffect(() => {
      if (isCancelButtonClickConfirm) {
        form.resetFields();
      }
    }, [isCancelButtonClickConfirm, form]);

    useImperativeHandle(ref, () => ({
      resetForm() {
        form.resetFields();
      },
    }));

    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      form.setFieldsValue({ [name]: value });

      setFormData((prevData: LifePlanFormData) => ({
        ...prevData,
        [name]: value,
      }));
    };

    const handleStartEndSpaces = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      form.setFieldsValue({
        [name]: value.trimStart().trimEnd(),
      });
      setFormData((prevData: LifePlanFormData) => ({
        ...prevData,
        [name]: value.trimStart().trimEnd(),
      }));
    };

    return (
      <InputForm
        form={form}
        onValuesChange={onInputChange}
        size={isEditMode ? 'small' : 'middle'}
        className={isEditMode ? styles.editForm : ''}
      >
        {isTitleVisible && (
          <div className={styles.headerText}>Plan Information</div>
        )}
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="employeeBenefit"
              labelCol={{ span: 24 }}
              label={
                <Row>
                  <LabelWithTooltip
                    content={VoluntaryPlanInfoTooltipContent}
                    mandatoryField={false}
                    fieldText={'Employee Benefit'}
                  />
                </Row>
              }
            >
              <TextArea
                className={styles.benefitField}
                onChange={handleTextAreaChange}
                name="employeeBenefit"
                value={formData.employeeBenefit}
                onBlur={handleStartEndSpaces}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className={styles.inputWrapper}>
              <Form.Item
                name="employeeGuaranteedIssue"
                label="Employee Guaranteed Issue"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: false,
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="spouseBenefit"
              labelCol={{ span: 24 }}
              label={
                <Row>
                  <LabelWithTooltip
                    content={VoluntaryPlanInfoTooltipContent}
                    mandatoryField={false}
                    fieldText={' Spouse Benefit'}
                  />
                </Row>
              }
            >
              <TextArea
                className={styles.benefitField}
                onChange={handleTextAreaChange}
                name="spouseBenefit"
                value={formData.spouseBenefit}
                onBlur={handleStartEndSpaces}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className={styles.inputWrapper}>
              <Form.Item
                name="spouseGuaranteedIssue"
                label="Spouse Guaranteed Issue"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: false,
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="childBenefit"
              labelCol={{ span: 24 }}
              label={
                <Row>
                  <LabelWithTooltip
                    content={VoluntaryPlanInfoTooltipContent}
                    mandatoryField={false}
                    fieldText={' Child Benefit'}
                  />
                </Row>
              }
            >
              <TextArea
                className={styles.benefitField}
                onChange={handleTextAreaChange}
                name="childBenefit"
                value={formData.childBenefit}
                onBlur={handleStartEndSpaces}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className={styles.inputWrapper}>
              <Form.Item
                name="childGuaranteedIssue"
                label="Child Guaranteed Issue"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: false,
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </Col>
        </Row>{' '}
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name="volume"
              label="Volume"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: false,
                  message: EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
            >
              <NumberFormatInput
                prefix="$"
                isBrokerScreen={true}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                placeholder="$"
              />
            </Form.Item>
          </Col>
        </Row>
      </InputForm>
    );
  }
);

VoluntaryPlanInformation.displayName = 'VoluntaryPlanInformation';

export default VoluntaryPlanInformation;
