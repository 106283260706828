import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Checkbox, Form, Input, Select } from 'antd';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { find, pick, some } from 'lodash';

import PlanYear from 'model/PlanYear';
import Carrier from 'model/Carrier';
import CostSharing from 'model/plans/CostSharing';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import { useAddNewCarrierState } from 'hooks/useAddNewCarrierState';
import { useIsAuthorizedUserRole } from 'hooks/useIsAuthorizedUserRole';

import SelectOptions from 'components/SelectOptions/SelectOptions';
import PanelInputForm from 'modules/plans/components/PanelInputForm/PanelInputForm';
import MultiSelectDropdownSmall from 'components/MultiSelectDropdownSmall/MultiSelectDropdownSmall';
import BenefitClassMultiSelect from 'components/BenefitClassMultiSelect/BenefitClassMultiSelect';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import {
  getStatesAndTerritories,
  getCarriersByBenefitKind,
} from 'modules/plans/slices/basicPlanInfoSlice';

import {
  ADD_NEW_CARRIER_MODAL_CONSTANTS,
  BenefitCategory,
  addNewCarrierModalDescription,
  maxPlanNameSizeMDV,
  IndividualSubTypes,
} from 'constants/commonConstants';
import FundingType from 'modules/plans/enums/FundingType';
import CarrierType from 'modules/carriers/enums/CarrierType';

import { getPlanYearRangeWithCurrent } from 'util/commonUtil';

import {
  MEDICAL_PLAN_TYPE_OPTIONS,
  BasicInfoFields,
  VALIDATION_NAME_DUPLICATED,
  benefitCode,
  PriorToDeductible,
  FUNDING_TYPES_LIST,
} from 'modules/plans/constants';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import ConfirmationWithThreeButtons from 'components/ConfirmationWithThreeButtons/ConfirmationWithThreeButtons';
import SelectAddMoreButton from 'components/buttons/SelectAddMoreButton/SelectAddMoreButton';
import AddNewCarrierModal from 'components/AddNewCarrierModal/AddNewCarrierModal';
import { useLazyGetSFCValuesQuery } from 'modules/plans/slices/planSllice';
import { formatSFCValue } from 'modules/plans/utils';
import { loginTypes } from 'constants/authConstants';
import { validateMedicalPlanName } from 'modules/plans/slices/medicalPlanSlice';

import styles from './basicPlanInfo.module.less';

type BasicPlanInfoProps = {
  onChange: Function;
  isDBGPlan?: boolean;
  dbgPlanYear?: string;
};

type MultiSelect = {
  groups: string[];
  states: string[];
};

type FormDataType = {
  name: string;
  planYearId: string;
  carrier?: Carrier | null;
  type: any;
  hsaCompatible: boolean;
  hraCompatible: boolean;
  planNetworkName?: string;
  individualStopLoss?: string;
  individualAdministrationFee?: string;
  aggregatedStopLoss?: string;
  aggregatedAdministrationFee?: string;
  thirdPartyAdministrationFee?: string;
  otherFees?: string;
  startDate: string;
  endDate: string;
  employerId: string | null;
  fundingType: string;
  groupId?: string;
};

const BasicPlanInfo = forwardRef((props: BasicPlanInfoProps, ref) => {
  const basicPlanInfo = useAppSelector((state) => state.plan.planBasicInfo);
  const plan = useAppSelector((state) => state.plan.plans);
  const { medicalPlan, error } = plan;
  const employer = useAppSelector((state) => state.employer);
  const empInfo = useAppSelector((state) => state.layout?.employer);
  const { brokerId, employerId } = useNavContext();
  const dispatch = useAppDispatch();
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const [form] = Form.useForm();

  const { statesAndTerritoriesList, carriersList } = basicPlanInfo;
  const { planYearsList, inProgress } = employer.employer;

  const ALL_STATES = 'All';
  const statesAndTerritoriesWithAll = [ALL_STATES].concat(
    statesAndTerritoriesList
  );

  const [formData, setFormData] = useState<FormDataType>({
    name: '',
    planYearId: '',
    carrier: null,
    type: '',
    hsaCompatible: false,
    hraCompatible: false,
    planNetworkName: '',
    startDate: '',
    endDate: '',
    employerId: employerId,
    fundingType: '',
    groupId: '',
  });

  const [multiSelect, setMultiSelect] = useState<MultiSelect>({
    groups: [],
    states: statesAndTerritoriesWithAll,
  });
  const [benefitGroups, setBenefitGroups] = useState<string[]>([]);
  const [planYearDBG, setPlanYearDBG] = useState<PlanYear>();

  const {
    carrierCreateTypeConfirmation,
    setCarrierCreateTypeConfirmation,
    openCarrierModal,
    setOpenCarrierModal,
    newCarrierId,
    setNewCarrierId,
  } = useAddNewCarrierState();

  const { onChange, isDBGPlan, dbgPlanYear } = props;

  const [getSFCValues, { data: sfcData }] = useLazyGetSFCValuesQuery();

  const benefitCarrierId = (formData as any)?.benefitCarrierId;

  const isErAdmin: boolean = useIsAuthorizedUserRole([loginTypes.erAdmin]);

  useEffect(() => {
    getSFCValues({
      benefitKind: BenefitCategory.MEDICAL.value,
      employerId: medicalPlan.employerId,
      planYearId: formData.planYearId,
      carrierId: benefitCarrierId,
      currentTs: new Date().getTime(),
    });
  }, [
    getSFCValues,
    medicalPlan.employerId,
    formData.planYearId,
    benefitCarrierId,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      individualStopLoss: formatSFCValue(sfcData?.individualStopLoss),
      aggregatedAdministrationFee: formatSFCValue(
        sfcData?.aggregatedAdministrationFee
      ),
      individualAdministrationFee: formatSFCValue(
        sfcData?.individualAdministrationFee
      ),
      thirdPartyAdministrationFee: formatSFCValue(
        sfcData?.thirdPartyAdministrationFee
      ),
      otherFees: formatSFCValue(sfcData?.otherFees),
    });
    onChange({
      ...medicalPlan,
      individualStopLoss: formatSFCValue(sfcData?.individualStopLoss),
      aggregatedAdministrationFee: formatSFCValue(
        sfcData?.aggregatedAdministrationFee
      ),
      individualAdministrationFee: formatSFCValue(
        sfcData?.individualAdministrationFee
      ),
      thirdPartyAdministrationFee: formatSFCValue(
        sfcData?.thirdPartyAdministrationFee
      ),
      otherFees: formatSFCValue(sfcData?.otherFees),
      aggregatedStopLoss: '',
    });
    // Don't expect call hook everytime form change
    // eslint-disable-next-line
  }, [sfcData]);

  let defaultPlanYearName = '';
  if (planYearsList[0]) {
    defaultPlanYearName =
      planYearsList[0].name +
      ' ' +
      getPlanYearRangeWithCurrent(planYearsList[0]);
  }

  const getValidationResult = async () => {
    let sfcRequiredFields: string[] = [];

    if (FundingType.SELF_FUNDED === medicalPlan?.fundingType) {
      sfcRequiredFields = [
        'individualStopLoss',
        'individualAdministrationFee',
        'aggregatedStopLoss',
        'aggregatedAdministrationFee',
        'thirdPartyAdministrationFee',
        'otherFees',
      ];
    }

    const requiredFieldsForComplete = pick(medicalPlan, [
      'benefitCarrierId',
      'type',
      'states',
      'planNetworkName',
      'groupId',
      ...sfcRequiredFields,
    ]);
    const isComplete = !some(requiredFieldsForComplete, isEmpty);
    let isValid = false;
    try {
      await form.validateFields();
      isValid = true;
    } catch (error) {
      isValid = false;
    }
    const validatePlanNameObj = {
      employerId: employerId || '',
      planName: medicalPlan?.name,
      planYearId: medicalPlan?.planYearId,
    };
    const exists = error?.data?.code === VALIDATION_NAME_DUPLICATED;
    const isPlanNameValid =
      !exists && (await dispatch(validateMedicalPlanName(validatePlanNameObj)));
    if (!isPlanNameValid) {
      isValid = false;
    }

    return { isComplete, isValid };
  };

  useImperativeHandle(ref, () => ({
    validate() {
      return getValidationResult();
    },
  }));

  useEffect(() => {
    const planYear = find(planYearsList, { id: dbgPlanYear });
    if (planYear) setPlanYearDBG(planYear);
  }, [dbgPlanYear, planYearsList]);

  useEffect(() => {
    if (planYearDBG != null) {
      setBenefitGroups(planYearDBG.benefitGroups);
      if (planYearDBG.benefitGroups.length === 1) {
        setMultiSelect((multiSelect) => ({
          ...multiSelect,
          groups: planYearDBG.benefitGroups,
        }));
        onChange({
          ...medicalPlan,
          [BasicInfoFields.BENEFIT_GROUPS]: planYearDBG.benefitGroups,
        });
        form.setFieldsValue({
          [BasicInfoFields.BENEFIT_GROUPS]:
            planYearDBG.benefitGroups.join(', '),
        });
      } else {
        setMultiSelect({ ...multiSelect, groups: [] });
        form.setFieldsValue({ [BasicInfoFields.BENEFIT_GROUPS]: '' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planYearDBG]);

  useEffect(() => {
    if (planYearsList[0]) {
      form.setFieldsValue({
        planYearId: planYearsList[0].id,
      });
      setBenefitGroups(planYearsList[0].benefitGroups);
      if (planYearsList[0].benefitGroups.length === 1) {
        setMultiSelect((multiSelect) => ({
          ...multiSelect,
          groups: planYearsList[0].benefitGroups,
        }));
        onChange({
          ...medicalPlan,
          [BasicInfoFields.BENEFIT_GROUPS]: planYearsList[0].benefitGroups,
        });
        form.setFieldsValue({
          [BasicInfoFields.BENEFIT_GROUPS]:
            planYearsList[0].benefitGroups.join(', '),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPlanYearName, planYearsList]);

  useEffect(() => {
    dispatch(getStatesAndTerritories());
    if (brokerId && employerId) {
      dispatch(
        getCarriersByBenefitKind(
          BenefitCategory.MEDICAL.value,
          brokerId,
          employerId
        )
      );
      dispatch(getPlanYears(employerId));
    }
  }, [dispatch, brokerId, employerId]);

  const onInputChange = async (changedValues: any, allValues: any) => {
    if (
      Object.keys(changedValues)[0] === BasicInfoFields.BENEFIT_CARRIER &&
      !isEmpty(carriersList) &&
      !isEmpty(Object.values(changedValues)[0])
    ) {
      const carrierObj = carriersList.find(
        (carrier: Carrier) => carrier.id === Object.values(changedValues)[0]
      );
      changedValues[BasicInfoFields.BENEFIT_CARRIER] = carrierObj;
      changedValues[BasicInfoFields.BENEFIT_CARRIER_ID] = carrierObj?.id;
    }

    if (allValues.planYearId) {
      const selectedPlanYear: any = planYearsList.find(
        (year: PlanYear) => year.id === allValues.planYearId
      );
      changedValues[BasicInfoFields.PLAN_YEAR] = dbgPlanYear
        ? dbgPlanYear
        : allValues.planYearId;
      changedValues[BasicInfoFields.START_DATE] = selectedPlanYear.startDate;
      changedValues[BasicInfoFields.END_DATE] = selectedPlanYear.endDate;
      if (selectedPlanYear.benefitGroups.length === 1 && !allValues.groups) {
        changedValues[BasicInfoFields.BENEFIT_GROUPS] = multiSelect.groups;
      }
    }
    if (
      Object.keys(changedValues)[0] === BasicInfoFields.PLAN_YEAR &&
      !isEmpty(planYearsList) &&
      !isEmpty(Object.values(changedValues)[0])
    ) {
      const selectedPlanYear: any = planYearsList.find(
        (year: PlanYear) => year.id === Object.values(changedValues)[0]
      );
      changedValues[BasicInfoFields.START_DATE] = selectedPlanYear.startDate;
      changedValues[BasicInfoFields.END_DATE] = selectedPlanYear.endDate;
      setBenefitGroups(selectedPlanYear.benefitGroups);
      if (selectedPlanYear.benefitGroups.length === 1) {
        setMultiSelect({
          ...multiSelect,
          groups: selectedPlanYear.benefitGroups,
        });
        form.setFieldsValue({
          [BasicInfoFields.BENEFIT_GROUPS]:
            selectedPlanYear.benefitGroups.join(', '),
        });
        changedValues[BasicInfoFields.BENEFIT_GROUPS] =
          selectedPlanYear.benefitGroups;
      } else {
        changedValues[BasicInfoFields.BENEFIT_GROUPS] = [];
        setMultiSelect({ ...multiSelect, groups: [] });
        form.setFieldsValue({ [BasicInfoFields.BENEFIT_GROUPS]: '' });
      }
    }
    if (multiSelect.states.includes(ALL_STATES)) {
      changedValues.states = statesAndTerritoriesList;
    }
    setFormData({ ...formData, ...changedValues });

    const updatedCustomServices = cloneDeep(medicalPlan.customServices);
    const updatedRxCosts = cloneDeep(medicalPlan.rxCosts);
    const updatedMailOrderRxCosts = cloneDeep(medicalPlan.mailOrderRxCosts);

    if (Object.keys(changedValues)[0] === BasicInfoFields.HSA_COMPATIBLE) {
      updatedCustomServices?.forEach((service) => {
        if (
          service.benefitCode.code !== benefitCode.MEDICAL_PREVENTIVE_CARE.code
        ) {
          const inNetwork = service.serviceValue.inNetwork;
          const outOfNetwork = service.serviceValue.outOfNetwork;

          inNetwork.copayPriorToDeductible = Object.values(changedValues)[0]
            ? PriorToDeductible.NO
            : '';
          outOfNetwork.copayPriorToDeductible = Object.values(changedValues)[0]
            ? PriorToDeductible.NO
            : '';

          service.serviceValue.inNetwork = inNetwork;
          service.serviceValue.outOfNetwork = outOfNetwork;
        }
        return service;
      });

      if (updatedRxCosts?.inNetwork) {
        Object.keys(updatedRxCosts?.inNetwork).forEach((key) => {
          (
            updatedRxCosts?.inNetwork[key] as CostSharing
          ).copayPriorToDeductible = Object.values(changedValues)[0]
            ? PriorToDeductible.NO
            : '';
        });
      }

      if (updatedRxCosts?.outOfNetwork) {
        Object.keys(updatedRxCosts?.outOfNetwork).forEach((key) => {
          (
            updatedRxCosts?.outOfNetwork[key] as CostSharing
          ).copayPriorToDeductible = Object.values(changedValues)[0]
            ? PriorToDeductible.NO
            : '';
        });
      }

      if (updatedMailOrderRxCosts?.inNetwork) {
        Object.keys(updatedMailOrderRxCosts?.inNetwork).forEach((key) => {
          (
            updatedMailOrderRxCosts?.inNetwork[key] as CostSharing
          ).copayPriorToDeductible = Object.values(changedValues)[0]
            ? PriorToDeductible.NO
            : '';
        });
      }

      if (updatedMailOrderRxCosts?.outOfNetwork) {
        Object.keys(updatedMailOrderRxCosts?.outOfNetwork).forEach((key) => {
          (
            updatedMailOrderRxCosts?.outOfNetwork[key] as CostSharing
          ).copayPriorToDeductible = Object.values(changedValues)[0]
            ? PriorToDeductible.NO
            : '';
        });
      }
    }
    const updatedObject = {
      ...medicalPlan,
      ...formData,
      ...changedValues,
      customServices: updatedCustomServices,
      rxCosts: updatedRxCosts,
      mailOrderRxCosts: updatedMailOrderRxCosts,
      name: allValues.name,
      hasSameContributions: plan.editedMedicalPlan.hasSameContributions,
    };

    onChange({
      ...updatedObject,
      carrier: updatedObject.carrier ? updatedObject.carrier : null,
      type: updatedObject.type ? updatedObject.type : null,
    });
  };

  const onCheckboxSelection = (event: any) => {
    const { name, value, checked } = event.target;
    let newCheckboxValues: string[];
    if (name === BasicInfoFields.BENEFIT_GROUPS) {
      newCheckboxValues = getCheckboxDataForGroups(
        multiSelect.groups,
        value,
        checked
      );

      form.setFieldsValue({
        [BasicInfoFields.BENEFIT_GROUPS]: newCheckboxValues.join(', '),
      });
    } else {
      newCheckboxValues = getCheckboxDataForStates(
        multiSelect.states,
        value,
        checked
      );
      newCheckboxValues = newCheckboxValues
        .filter((value) => value === ALL_STATES)
        .concat(
          newCheckboxValues.filter((value) => value !== ALL_STATES).sort()
        );
    }
    setMultiSelect((prevState) => ({
      ...prevState,
      [name]: newCheckboxValues,
    }));

    let actualCheckboxValues = newCheckboxValues;
    if (
      newCheckboxValues.includes(ALL_STATES) &&
      name === BasicInfoFields.STATES_TERRITORIES
    ) {
      actualCheckboxValues = statesAndTerritoriesList;
    }
    setFormData({ ...formData, [name]: actualCheckboxValues });
    onChange({
      ...medicalPlan,
      ...formData,
      [name]: actualCheckboxValues,
      hasSameContributions: plan.editedMedicalPlan.hasSameContributions,
    });
  };

  const getCheckboxDataForGroups = (
    checkboxValues: string[],
    value: string,
    checked: boolean
  ): string[] => {
    let newCheckboxValues = cloneDeep(checkboxValues);
    if (checked) {
      newCheckboxValues = [...checkboxValues, value];
    } else {
      const index = checkboxValues.findIndex((element) => element === value);
      if (index > -1) {
        newCheckboxValues.splice(index, 1);
      }
    }
    return newCheckboxValues;
  };

  const getCheckboxDataForStates = (
    checkboxValues: string[],
    value: string,
    checked: boolean
  ): string[] => {
    let newCheckboxValues = cloneDeep(checkboxValues);

    if (checked) {
      newCheckboxValues = [...checkboxValues, value];
      if (checkboxValues.length === statesAndTerritoriesList.length - 1) {
        newCheckboxValues.unshift(ALL_STATES);
      }
      if (value === ALL_STATES) {
        newCheckboxValues = statesAndTerritoriesWithAll;
      }
    } else {
      const index = checkboxValues.findIndex((element) => element === value);

      if (checkboxValues.includes(ALL_STATES)) {
        const indexOfAll = checkboxValues.indexOf(ALL_STATES);
        newCheckboxValues.splice(indexOfAll, 1);
        newCheckboxValues.splice(index - 1, 1);
      } else {
        newCheckboxValues.splice(index, 1);
      }

      if (value === ALL_STATES) {
        newCheckboxValues = [];
      }
    }
    return newCheckboxValues;
  };

  const getDefaultStates = () => {
    let selectedItemValues: string;
    let showAll = false;
    if (
      multiSelect.states.length === 1 &&
      multiSelect.states.includes(ALL_STATES)
    ) {
      selectedItemValues = statesAndTerritoriesWithAll.join(', ');
      showAll = true;
    } else {
      selectedItemValues = multiSelect.states.join(', ');
      showAll = multiSelect.states.length > statesAndTerritoriesList.length;
    }
    return { selectedItemValues, showAll };
  };
  const { selectedItemValues, showAll } = getDefaultStates();

  const trimInput = (event: any) => {
    const { value } = event.target;
    form.setFieldsValue({ [BasicInfoFields.PLAN_NAME]: value.trim() });
  };

  const duplicateValidator = (rule: any, value: string) => {
    const exists = error?.data?.code === VALIDATION_NAME_DUPLICATED;

    if (exists) {
      return Promise.reject(new Error());
    } else {
      return Promise.resolve();
    }
  };

  useEffect(() => {
    if (newCarrierId) {
      const carrierObj = carriersList.find(
        (carrier: Carrier) => carrier.id === newCarrierId
      );

      form.setFieldsValue({
        [BasicInfoFields.BENEFIT_CARRIER]: carrierObj?.id,
      });
      onChange({
        ...medicalPlan,
        [BasicInfoFields.BENEFIT_CARRIER]: carrierObj,
        [BasicInfoFields.BENEFIT_CARRIER_ID]: carrierObj?.id,
      });
    }
    // don't expect call hook every time form,onChange,medicalPlan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCarrierId, carriersList]);

  const handleSetCarrierToForm = (carrier: {
    label: string;
    value: string;
  }): void => {
    const { value } = carrier;
    setNewCarrierId(value);
  };

  return (
    <div className={styles.basicInfoFormContainer}>
      <PanelInputForm
        form={form}
        name="basicPlanInfo"
        layout="horizontal"
        initialValues={{ remember: true }}
        autoComplete="off"
        onValuesChange={onInputChange}
      >
        <Form.Item
          className={styles.planName}
          name={BasicInfoFields.PLAN_NAME}
          label="Plan Name*"
          rules={[
            {
              required: true,
              message: 'Please specify a valid plan name',
              validateTrigger: ['onSubmit'],
            },
            {
              validator: duplicateValidator,
              message: 'This plan name already exists',
            },
          ]}
        >
          <Input
            maxLength={maxPlanNameSizeMDV}
            showCount
            onBlur={trimInput}
            title={formData.name}
          />
        </Form.Item>

        <Form.Item
          name={BasicInfoFields.PLAN_YEAR}
          label="Effective Dates*"
          rules={[{ required: true, message: 'Please select Plan Year' }]}
          className={`${styles.planYear}`}
        >
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            disabled={isDBGPlan || planYearDBG != null}
            loading={inProgress}
          >
            {planYearsList &&
              planYearsList
                .filter((planYear) => !planYear.previous)
                .map((planYear: PlanYear, index: number) => (
                  <Select.Option
                    value={planYear.id}
                    key={index}
                    label={planYear.id}
                  >
                    {planYearDBG
                      ? planYearDBG.name +
                        ' ' +
                        getPlanYearRangeWithCurrent(planYearDBG)
                      : planYear.name +
                        ' ' +
                        getPlanYearRangeWithCurrent(planYear)}
                  </Select.Option>
                ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Benefit Classes*"
          className={styles.benefitClassWrapper}
        >
          <Input hidden value={multiSelect.groups.join(', ')} />
          <Form.Item
            name={BasicInfoFields.BENEFIT_GROUPS}
            rules={[
              {
                required: true,
                message: 'Please select at least one benefit class',
                validateTrigger: ['onSubmit'],
              },
            ]}
          >
            <BenefitClassMultiSelect
              options={benefitGroups}
              onChange={onCheckboxSelection}
              name="groups"
              disabled={isEmpty(benefitGroups)}
              selectedItemValues={multiSelect.groups}
              showTooltip
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name={BasicInfoFields.BENEFIT_CARRIER}
          label="Carrier*"
          rules={[
            {
              required: true,
              message: 'Please select a carrier',
              validateTrigger: ['onSubmit'],
            },
          ]}
          className={styles.carrierStyles}
        >
          <SelectOptions
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            loading={inProgress}
            showSearch
            filterOption={(input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase().trim())
            }
            dropdownRender={(menu) => (
              <>
                {menu}
                <SelectAddMoreButton
                  onClick={() => {
                    !isErAdmin &&
                    appBootInfo?.individualSubType !==
                      IndividualSubTypes.BROKER_USER
                      ? setCarrierCreateTypeConfirmation({
                          show: true,
                          carrierType: '',
                        })
                      : setOpenCarrierModal(true);
                  }}
                  label="Add New Carrier"
                />
              </>
            )}
            options={carriersList?.map((carrier: Carrier) => ({
              label: carrier.name,
              value: carrier.id,
            }))}
          />
        </Form.Item>

        <Form.Item name={BasicInfoFields.GROUP_ID} label="Group ID / Policy #">
          <Input title={formData.groupId} />
        </Form.Item>

        <Form.Item
          name={BasicInfoFields.PLAN_NETWORK_NAME}
          label="Plan Network"
        >
          <Input title={formData.planNetworkName} />
        </Form.Item>

        <Form.Item name={BasicInfoFields.PLAN_TYPE} label="Plan Type">
          <SelectOptions
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            options={MEDICAL_PLAN_TYPE_OPTIONS}
            allowClear
          />
        </Form.Item>

        <Form.Item
          name={BasicInfoFields.HSA_COMPATIBLE}
          label="HSA Eligible"
          valuePropName="checked"
          className={styles.hsaEligibleWrapper}
        >
          <Checkbox checked={formData.hsaCompatible}>Yes</Checkbox>
        </Form.Item>

        <Form.Item
          name={BasicInfoFields.HRA_COMPATIBLE}
          label="HRA Eligible"
          valuePropName="checked"
          className={styles.hsaEligibleWrapper}
        >
          <Checkbox checked={formData.hraCompatible}>Yes</Checkbox>
        </Form.Item>

        <Form.Item
          name={BasicInfoFields.STATES_TERRITORIES}
          label="States & Territories"
          className={styles.stateAndTerritories}
        >
          <MultiSelectDropdownSmall
            options={statesAndTerritoriesWithAll}
            increased={true}
            onChange={onCheckboxSelection}
            name="states"
            showAll={showAll}
            selectedItemValues={selectedItemValues}
          />
        </Form.Item>

        <Form.Item
          className={styles.fundingType}
          name={BasicInfoFields.FUNDING_TYPE}
          label="Funding Type *"
          rules={[
            {
              required: true,
              message: 'Please select a funding type',
              validateTrigger: ['onSubmit'],
            },
          ]}
        >
          <SelectOptions
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            options={FUNDING_TYPES_LIST}
          />
        </Form.Item>

        {medicalPlan?.fundingType === FundingType.SELF_FUNDED && (
          <>
            <div className={styles.selfFundedClaims}>
              <div className={styles.sfcRow}>
                <Form.Item
                  name={BasicInfoFields.INDIVIDUAL_STOP_LOSS}
                  label={
                    <>
                      Individual Stop Loss<span>1</span>
                    </>
                  }
                  className={styles.sfcField}
                >
                  <NumberFormatInput
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                  />
                </Form.Item>
                <Form.Item
                  name={BasicInfoFields.INDIVIDUAL_ADMINISTRATION_FEE}
                  label={
                    <>
                      Individual Stop Loss Fee<span>1</span>
                    </>
                  }
                  className={`${styles.sfcField} ${styles.individualStopLossFee}`}
                >
                  <NumberFormatInput
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                  />
                </Form.Item>
              </div>
              <div className={styles.sfcRow}>
                <Form.Item
                  name={BasicInfoFields.AGGREGATE_STOP_LOSS}
                  label="Aggregate Stop Loss"
                >
                  <NumberFormatInput
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                  />
                </Form.Item>

                <Form.Item
                  name={BasicInfoFields.AGGREGATED_ADMINISTRATION_FEE}
                  label={
                    <>
                      Aggregate Stop Loss Fee<span>1</span>
                    </>
                  }
                  className={`${styles.sfcField} ${styles.aggregateStopLossFee}`}
                >
                  <NumberFormatInput
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                  />
                </Form.Item>
              </div>
              <div className={styles.sfcRow}>
                <Form.Item
                  name={BasicInfoFields.THIRD_PARTY_ADMINISTRATION_FEE}
                  label={
                    <>
                      3rd Party Admin Fee<span>1</span>
                    </>
                  }
                  className={`${styles.sfcField} ${styles.thirdPartyAdministrationFee}`}
                >
                  <NumberFormatInput
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                  />
                </Form.Item>

                <Form.Item
                  name={BasicInfoFields.OTHER_FEES}
                  label={
                    <>
                      Other Fees<span>1</span>
                    </>
                  }
                  className={`${styles.sfcField} ${styles.otherFees}`}
                >
                  <NumberFormatInput
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                  />
                </Form.Item>
              </div>
            </div>
            <div className={styles.sfcText}>
              <span>1</span>
              This data is shared by all self-funded plans in this plan year
              with the same carrier.
            </div>
          </>
        )}
      </PanelInputForm>
      <ConfirmationWithThreeButtons
        visible={carrierCreateTypeConfirmation.show}
        width={464}
        centered
        title={ADD_NEW_CARRIER_MODAL_CONSTANTS.title}
        firstButtonText={ADD_NEW_CARRIER_MODAL_CONSTANTS.firstButtonLabel}
        firstButtonAction={() => {
          setCarrierCreateTypeConfirmation({
            show: false,
            carrierType: CarrierType.BROKER,
          });
          setOpenCarrierModal(true);
        }}
        secondButtonText={ADD_NEW_CARRIER_MODAL_CONSTANTS.secondButtonLabel}
        secondButtonAction={() => {
          setCarrierCreateTypeConfirmation({
            show: false,
            carrierType: CarrierType.EMPLOYER,
          });
          setOpenCarrierModal(true);
        }}
        onCancel={() => {
          setCarrierCreateTypeConfirmation({
            show: false,
            carrierType: '',
          });
        }}
      >
        {addNewCarrierModalDescription(empInfo?.name!)}
      </ConfirmationWithThreeButtons>
      <AddNewCarrierModal
        isOpen={openCarrierModal}
        setIsOpen={setOpenCarrierModal}
        selectedCarrierType={
          isErAdmin ||
          appBootInfo?.individualSubType === IndividualSubTypes.BROKER_USER
            ? CarrierType.EMPLOYER
            : carrierCreateTypeConfirmation.carrierType
        }
        benefitType={BenefitCategory.MEDICAL.value}
        setCarrierToForm={handleSetCarrierToForm}
        brokerId={brokerId!}
        employerId={employerId!}
      />
    </div>
  );
});

BasicPlanInfo.displayName = 'BasicPlanInfo';

export default BasicPlanInfo;
