import { useEffect, useState } from 'react';
import { Input, Switch } from 'antd';
import { isEmpty } from 'lodash';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import styles from './featureToggle.module.less';

type EmployerFeatureToggleProps = {
  onConfirmToggle: any;
  recordName: any;
  enableMessage: string | React.ReactFragment;
  disableMessage: string | React.ReactFragment;
  title: string;
  enabled: boolean;
  disabled?: boolean;
  className?: string;
  width?: number;
  customConfirmText?: string;
  disableText?: string;
  enableText?: string;
};

const EmployerFeatureToggle = (props: EmployerFeatureToggleProps) => {
  const {
    onConfirmToggle,
    recordName,
    enableMessage,
    disableMessage,
    title,
    enabled,
    disabled,
    width,
    enableText,
    disableText,
    customConfirmText = 'Yes - Enable',
  } = props;
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [disbale, setDisable] = useState<boolean>(enabled);
  const [invalidInput, setinvalidInput] = useState<boolean>(false);
  const [inputText, setinputText] = useState('');

  const confirmToggle = () => {
    onConfirmToggle();
    setShowConfirm(false);
    setinvalidInput(false);
    setinputText('');
    if (enabled) setDisable(true);
  };

  useEffect(() => {
    setDisable(enabled);
  }, [enabled]);

  const getConfirmText = () => {
    if (enabled) {
      if (disableText !== undefined) {
        return disableText;
      }
      return `Disable ${title}`;
    }
    if (enableText !== undefined) {
      return enableText;
    }
    return `${customConfirmText} ${title}`;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setinputText(e.target.value);
    if (e.target.value === 'DISABLE') {
      setDisable(false);
      setinvalidInput(false);
    } else if (isEmpty(e.target.value)) {
      setDisable(true);
      setinvalidInput(false);
    } else {
      setDisable(true);
      setinvalidInput(true);
    }
  };

  return (
    <div className={styles.switchWrapper}>
      <Switch
        onChange={() => {
          setShowConfirm(true);
        }}
        checked={enabled}
        disabled={disabled}
      />
      <ConfirmationDialog
        title={`${enabled ? 'Disable' : 'Enable'} ${title}`}
        confirmText={getConfirmText()}
        cancelText="Cancel"
        closeModal={() => {
          setShowConfirm(false);
          setinvalidInput(false);
          setinputText('');
        }}
        onConfirm={confirmToggle}
        visible={showConfirm}
        isCancelLink
        isDanger={enabled}
        disableConfirmButton={disbale}
        recordName={recordName}
        width={width}
        modalClassName={props.className}
      >
        {enabled ? (
          <>
            <p className={styles.warningConfirmation}>{disableMessage}</p>
            <div className={styles.warningConfirmation}>
              <div
                className={styles.disableWarningText}
              >{`Type "DISABLE" in the box below to confirm`}</div>
              <Input
                value={inputText}
                className={`${
                  invalidInput
                    ? styles.disableTextInputError
                    : styles.disableTextInput
                } disableInputField`}
                type="text"
                onChange={(e) => handleInputChange(e)}
                bordered={true}
              />
              {invalidInput ? (
                <div className={styles.invalidInput}>Invalid Input</div>
              ) : (
                ''
              )}
            </div>
          </>
        ) : (
          <p className={styles.disableWarningTextEnable}>{enableMessage}</p>
        )}
      </ConfirmationDialog>
    </div>
  );
};

export default EmployerFeatureToggle;
