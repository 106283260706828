import PremiumType from 'modules/renewals/enums/PremiumType';
import { Enrollment, Plan } from 'modules/renewals/types/planTypes';

/**
 * Builds an array of N-tier enrollment objects based on the specified N-tier count.
 *
 * @param {number} nTierCount The number of N-tiers to generate..
 * @return {Enrollment}An array of N-tier enrollment objects.
 */
export const buildNTierEnrollments = (nTierCount: number): Enrollment[] => {
  let enrollmentList: Enrollment[] = [];
  for (let index = 0; index < nTierCount + 1; index++) {
    enrollmentList = [
      ...enrollmentList,
      {
        name:
          index === 0 ? 'ENROLLMENT_TIER_1' : `ENROLLMENT_TIER_${index + 4}`,
        fieldType: null,
        fieldSubType: `${index === 0 ? 1 : index}/${index === 0 ? 4 : 6}`,
        value: null,
      },
    ];
  }

  return enrollmentList;
};

/**
 *  Builds enrollments based on the premium type and initial data.
 *
 * @param {Plan[]} initialData The initial data to build enrollments from
 * @param {any} premiumType The selected premium type
 * @param {number} nTierCount The number of N-tiers to generate..
 * @param {Function} validate to validate the enrollment value
 * @return {any} updated data
 */
export const buildEnrollments = (
  initialData: Plan[],
  premiumType: any,
  nTierCount: number,
  validate: Function
): any => {
  if (
    premiumType === PremiumType.N_TIER &&
    initialData?.filter((item: any) =>
      validate('ENROLLMENT', item?.value?.replaceAll(',', ''))
    )?.length !== 0
  ) {
    return buildNTierEnrollments(nTierCount);
  } else {
    return initialData;
  }
};

/**
 * Finds an object in an array based on specified properties.
 * @param {any[]} array - The array to search.
 * @param {string | null} name - The name property to match.
 * @param {string | null} fieldType - The fieldType property to match.
 * @param {string | null} fieldSubType - The fieldSubType property to match.
 * @return {any | undefined} The first matching object, or undefined if not found.
 */
export const findInArray = (
  array: any[],
  name: string | null,
  fieldType: string | null,
  fieldSubType: string | null
) => {
  return array?.find(
    (item) =>
      item?.name === name &&
      item?.fieldType === fieldType &&
      item?.fieldSubType === fieldSubType
  );
};
