import { FC } from 'react';
import { Modal } from 'antd';

import EditWellbeingPlan from 'modules/plans/wellbeing/components/EditWellbeingPlan/EditWellbeingPlan';

import IconInfo from 'assets/images/icon-info.svg';

import styles from './editWellbeingPlanUPWrapper.module.less';

type EditWellbeingPlanUPWrapperProps = {
  plan: any;
  onClose: any;
  isModalVisible: boolean;
};

const EditWellbeingPlanUPWrapper: FC<EditWellbeingPlanUPWrapperProps> = ({
  plan,
  onClose,
  isModalVisible,
}: EditWellbeingPlanUPWrapperProps) => {
  return (
    <Modal
      width={600}
      visible={isModalVisible}
      okText="Done"
      footer={false}
      className={styles.wellbeingPlanChannelModal}
      closable={false}
    >
      <div className={styles.wellbeingPlanChannelWrapper}>
        <div className={styles.title}>Edit Wellbeing Plan</div>
        <div className={styles.leftBorder}></div>
        <div className={styles.wellbeingPlanChannel}>
          <div>
            <img src={IconInfo} alt="Icon Info" />
          </div>
          <div className={styles.defaultText}>
            Changing plan information here will change the plan information
            everywhere this plan is shown
          </div>
        </div>
        <EditWellbeingPlan
          plan={plan}
          onClose={onClose}
          isModalVisible={isModalVisible}
        />
      </div>
    </Modal>
  );
};

export default EditWellbeingPlanUPWrapper;
