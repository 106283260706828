import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

export interface ProposalOnboardingState {
  inProgress: boolean;
  error: any;
  requestType: string;
  proposal: any;
  isProposalBasicDataSubmitted: boolean;
  completedProposalSteps: string[];
}

const initialState: ProposalOnboardingState = {
  inProgress: false,
  error: null,
  requestType: '',
  proposal: {},
  isProposalBasicDataSubmitted: false,
  completedProposalSteps: [],
};

const proposalOnBoardingSlice = createSlice({
  name: 'proposalOnBoarding',
  initialState,
  reducers: {
    setProposal(state, proposal: any) {
      state.proposal = proposal;
    },
    clearProposal() {
      return initialState;
    },
    setCompletedSteps(state, action: PayloadAction<string[]>) {
      state.completedProposalSteps = action.payload;
    },
  },
});

export const attachProposal = (proposal: any) => async (dispatch: Dispatch) => {
  dispatch(setProposal(proposal));
};

export const clearProposalObject = () => async (dispatch: Dispatch) => {
  dispatch(clearProposal());
};

export const setCompletedProposalSteps =
  (completedStep: string[]) => async (dispatch: Dispatch) => {
    dispatch(setCompletedSteps(completedStep));
  };

export const { setProposal, clearProposal, setCompletedSteps } =
  proposalOnBoardingSlice.actions;

export default proposalOnBoardingSlice.reducer;
