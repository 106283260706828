import React, { FC } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import styles from './rfpTag.module.less';

type RfpTagProps = {
  tagValue: string;
  showClose?: boolean;
  onClose?: () => void;
};

const RfpTag: FC<RfpTagProps> = (props: RfpTagProps) => {
  const { tagValue, showClose, onClose } = props;
  return (
    <div className={styles.rfpTagWrapper}>
      {tagValue}
      <div className={styles.closeTagIcon}>
        {showClose && <CloseOutlined onClick={onClose!} />}
      </div>
    </div>
  );
};

export default RfpTag;
