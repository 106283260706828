import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import reactHtmlParser from 'react-html-parser';
import { isEmpty } from 'lodash';
import { Col, Popover, Row, Switch } from 'antd';
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useAppSelector } from 'hooks/redux';
import PlanTable from 'modules/renewals/components/PlanTable/PlanTable';
import {
  BASIC_LIFE_AND_ADD,
  CARRIER_DOCUMENTS,
  CREDIT_DROPDOWN_LABELS,
  FIXED_ALERT_MESSAGE_IF_FINAL_APPROVED,
  LIFE,
  LTD,
  MDV_PLANS,
  NAME_FIELD_PROPERTY,
  NOTIFICATION_PUBLISH_TOOLTIP,
  OFFER_BENEFIT_TYPE_NAVIGATION_LABELS,
  OFFER_CATEGORY,
  OFFER_PLAN_PROPERTY,
  OFFER_STATUSES,
  OFFER_TAGS_DTQ,
  ORTHODONTIA_MAX,
  QUOTE_ADDING_BUNDLE_PROPERTY,
  QUOTE_ADDING_PROPERTY,
  STANDARD_TIER,
  STD,
  URL_PARAM_TO_BENEFIT_TYPE,
  WARNING_IS_ELIGIBLE_MODAL_DES,
} from 'modules/renewals/constants/renewalsConstants';

import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import { ReactComponent as CaretDownIcon } from 'assets/images/icon-caret-down-black.svg';
import {
  useCreateOfferMutation,
  useLazyGetPlanDetailsQuery,
} from 'modules/renewals/slices/offersSlice';
import OfferCardOverview from 'modules/renewals/components/helperComponents/OfferCardOverview/OfferCardOverview';
import {
  buildPremiums,
  formatHeaderValues,
  getRateDetails,
  handleRedirectToProposalsDetails,
  isDtqOrPending,
} from 'modules/renewals/utils/renewalsUtils';
import { getPlanTableColumnLabels } from 'modules/renewals/utils/planTableLabelUtil';
import FromCurrentPercentage from 'modules/renewals/components/helperComponents/FromCurrentPercentage/FromCurrentPercentage';
import CreditInclusionPopup from 'modules/renewals/components/helperComponents/CreditInclusionPopup/CreditInclusionPopup';
import ViewCreditDescription from 'modules/renewals/components/helperComponents/ViewCreditDescription/ViewCreditDescription';
import UpdateConfirmationListModal from 'modules/renewals/components/helperComponents/UpdateConfirmationListModal/UpdateConfirmationListModal';
import { currencyFormatter } from 'util/commonUtil';
import { loginTypes } from 'constants/authConstants';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import TextButton from 'components/buttons/TextButton/TextButton';
import NextButton from 'components/buttons/NextButton/NextButton';
import PremiumType from 'modules/renewals/enums/PremiumType';
import CarrierDocuments from 'modules/renewals/components/helperComponents/CarrierDocuments/CarrierDocuments';
import DownloadIcon from 'assets/images/icon-download-black.svg';

import styles from './previewOffer.module.less';

type PreviewOfferProps = {
  planDetailsData: any;
  offer: any;
  publishOffer: () => void;
  saveAsDraft: () => void;
  benefitCategory: string;
  carrierImage: string;
  buttonLoading: boolean;
  setPreviewOffer: (offer: any) => void;
  publishButtonDisable?: boolean;
  offerAttachedProposal?: any;
  isEdit?: boolean;
  isNotEligibleToEdit?: boolean;
  changeOffer: Function;
  editData: any;
  notificationActive: (isActive: boolean) => void;
  isSaveAsDraftBtnDisabled?: boolean;
  currentStep: number;
  changeCurrentStep: (step: number) => any;
  premiumType?: PremiumType;
  nTierCount?: number;
  quoteMappings: any;
};

const PreviewOffer = (props: PreviewOfferProps) => {
  const {
    publishOffer,
    saveAsDraft,
    buttonLoading,
    offer,
    setPreviewOffer,
    publishButtonDisable = false,
    offerAttachedProposal,
    isEdit = false,
    isNotEligibleToEdit = false,
    changeOffer,
    editData,
    isSaveAsDraftBtnDisabled = false,
    notificationActive,
    currentStep,
    changeCurrentStep,
    premiumType = PremiumType.STANDARD_TIER,
    quoteMappings,
    nTierCount,
  } = props;
  const { jobId } = useAppSelector((state) => state.renewals.quoteReaderSlice);
  const { benefitKind, brokerId, employerId } = useParams();

  const [rateDetails, setRateDetails] = useState<string>('');

  const [isCarrierDocumentsVisible, setIsCarrierDocumentsVisible] =
    useState<boolean>(false);

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);

  const [
    createOffer,
    { data: previewOffer, isLoading: loading, isSuccess: isOfferCreateSuccess },
  ] = useCreateOfferMutation();

  const buildLifePlans = () => {
    const lifePlanList = (offer?.lifePlans ?? []).map((item: any) => ({
      ...item,
      volume: item?.volume?.formattedValue ?? item?.volume?.value ?? null,
      weeklyBenefitMax:
        item?.weeklyBenefitMax?.formattedValue ??
        item?.weeklyBenefitMax?.value ??
        null,
      insuranceType:
        isEmpty(item?.insuranceType) || (item?.insuranceType ?? null) === null
          ? BASIC_LIFE_AND_ADD
          : item?.insuranceType,
      benefitLevel:
        item?.benefitLevel?.formattedValue ?? item?.benefitLevel?.value ?? null,
      ageReductionFromBaseField: item?.ageReductionFromBase,
      guaranteedIssueField: item?.guaranteedIssue,
      lifeAndAddBenefitField: item?.lifeAndAddBenefit,
      lifeAndAddMaximumField: item?.lifeAndAddMaximum,
      totalEligibleEnrollmentsField: item?.totalEligibleEnrollments,
      eliminationPeriodField: item?.eliminationPeriod,
      definitionOfDisabilityField: item?.definitionOfDisability,
      benefitDurationField: item?.benefitDuration,
      preExistingConditionsField: item?.preExistingConditions,
      stateDisabilityIntegrationField: item?.stateDisabilityIntegration,
      monthlyBenefitMaxField: item?.monthlyBenefitMax,
      ownOccupationPeriodField: item?.ownOccupationPeriod,
      w2PreparationField: item?.w2Preparation,
      ageReduction: item?.ageReduction?.value,
      waiverOfPremium: item?.waiverOfPremium?.value,
      acceleratedLifeBenefit: item?.acceleratedLifeBenefit?.value,
      portability: item?.portability?.value,
      definitionOfEarnings: item?.definitionOfEarnings?.value,
      additionalServices: item?.additionalServices,
    }));

    return { ...offer, lifePlans: lifePlanList };
  };

  const modifyOrthodontiaMax = (orthodontiaMax: any[]) => {
    return (
      orthodontiaMax?.map((item) => {
        return {
          ...item,
          name: ORTHODONTIA_MAX,
        };
      }) ?? []
    );
  };

  const handleNotificationToggle = (val: boolean) => {
    notificationActive(val);
  };

  const buildPlans = () => {
    if (MDV_PLANS?.includes(offer?.benefitCategory)) {
      const plans =
        offer?.[QUOTE_ADDING_PROPERTY?.[offer?.benefitCategory ?? '']]?.map(
          (item: any) => {
            let modifiedItem = {
              ...item,
              monthlyPremiums: buildPremiums(
                item?.monthlyPremiums,
                premiumType
              ),
            };
            if (
              offer?.benefitCategory === OFFER_CATEGORY.DENTAL &&
              item?.orthodontiaMax
            ) {
              modifiedItem = {
                ...modifiedItem,
                orthodontiaMax: modifyOrthodontiaMax(item?.orthodontiaMax),
              };
            }
            return modifiedItem;
          }
        ) ?? [];
      return {
        ...offer,
        [QUOTE_ADDING_PROPERTY?.[offer?.benefitCategory ?? ''] ?? 'plans']:
          plans,
        quotePlanMapping: quoteMappings,
      };
    } else {
      return offer;
    }
  };

  const handleBack = () => {
    if (offer?.offerStatus === OFFER_STATUSES.DTQ.value) {
      changeCurrentStep(currentStep - 3);
    } else {
      changeCurrentStep(currentStep - 1);
    }
  };

  useEffect(() => {
    createOffer({
      offer:
        offer?.benefitCategory === LIFE ||
        offer?.benefitCategory === STD ||
        offer?.benefitCategory === LTD
          ? buildLifePlans()
          : buildPlans(),
      editOfferId: editData === undefined ? undefined : editData?.offerId,
      jobId: jobId,
    });
    // eslint-disable-next-line
  }, [offer, createOffer]);

  useEffect(() => {
    setPreviewOffer(previewOffer);
  }, [previewOffer, setPreviewOffer]);

  const getCurrentOfferId = (): string => {
    if (previewOffer !== undefined) {
      const offerId = previewOffer?.[
        QUOTE_ADDING_BUNDLE_PROPERTY?.[benefitKind?.toUpperCase() ?? '']
      ]?.find((item: any) => item?.type !== 'CURRENT');
      return offerId?.id;
    } else {
      return '';
    }
  };

  const [
    getOfferDetails,
    {
      data: planDetailsData,
      isFetching: planDetailsIsFetching,
      isLoading: planDetailsIsLoading,
    },
  ] = useLazyGetPlanDetailsQuery();

  useEffect(() => {
    if (isOfferCreateSuccess) {
      getOfferDetails({ offerId: getCurrentOfferId() });
    }
    // eslint-disable-next-line
  }, [isOfferCreateSuccess]);

  const [creditModes, setCreditModes] = useState<string[]>([]);
  const [selectedCreditDropDown, setSelectedCreditDropDown] = useState<any>(0);
  const [isCreditsExcludedModalVisible, setIsCreditsExcludedModalVisible] =
    useState<boolean>(false);
  const [isCreditsIncludedModalVisible, setIsCreditsIncludedModalVisible] =
    useState<boolean>(false);
  const [isCreditDescriptionModalOpen, setCreditDescriptionModalOpen] =
    useState<boolean>(false);
  const [showUpdateOfferInPropModal, setShowUpdateOfferInPropModal] =
    useState<boolean>(false);
  const selectedCreditMode = useAppSelector(
    (state) => state?.renewals.offers.selectedCreditMode
  );

  const arrangeColData = useMemo(() => {
    const isNTier =
      premiumType !== null &&
      premiumType !== undefined &&
      premiumType?.toString() !== STANDARD_TIER;

    return getPlanTableColumnLabels({
      benefitType: URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? ''],
      plans:
        planDetailsData?.[
          OFFER_PLAN_PROPERTY?.[URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? '']]
        ],
      fundingType: planDetailsData?.fundingType || '',
      isNTier: isNTier,
      isComparePlans: false,
      isPlanDetails: false,
      isPreview: true,
      addFooterRow: false,
      addEnrollmentsForPremiums: false,
      showErrorHighlight: false,
      nTierCount: nTierCount,
    });
  }, [planDetailsData, nTierCount, benefitKind, premiumType]);

  const setCreditsDropdown = () => {
    const mappedSet: any[] = CREDIT_DROPDOWN_LABELS.map(
      (creditMode: string, index: number) => {
        return {
          label: creditMode,
          value: index.toString(),
        };
      }
    );

    setCreditModes(mappedSet);
  };

  useEffect(() => {
    setCreditsDropdown();
  }, []);

  const changeCreditMode = (value: any) => {
    setSelectedCreditDropDown(value);
    if (value == 0) {
      setIsCreditsExcludedModalVisible(true);
    } else {
      setIsCreditsIncludedModalVisible(true);
    }
  };

  const totalAnnualPremiumFooter = () => {
    return (
      <span className={styles.footerLink}>
        <SingleSelectFilter
          data={creditModes}
          placeholder={'-'}
          showSearch={false}
          setFilterSelectedValue={(value: any) => changeCreditMode(value)}
          defaultValue={selectedCreditMode.toString()}
          selectedValue={selectedCreditMode.toString()}
          applicableIcon={<CaretDownIcon />}
          inlineDropdown={true}
          isDisabled={false}
          isWiderWrapper={true}
          isSelectionHighlightAndTickApplicable={false}
        />
      </span>
    );
  };

  const creditsFooter = () => {
    if (planDetailsData?.discountAndCreditDescription !== null) {
      return (
        <span
          className={styles.footerLink}
          onClick={() => setCreditDescriptionModalOpen(true)}
        >
          View Description
        </span>
      );
    }
  };

  const isPlaceHolderOffer = (): boolean => {
    return planDetailsData?.isPlaceholderCreated;
  };

  const getDtqOrPendingHeader = () => {
    return offer?.offerStatus === OFFER_TAGS_DTQ
      ? 'Carrier Declined to Quote'
      : 'Offer is Pending';
  };

  const getDtqOrPendingSubheader = () => {
    return offer?.offerStatus === OFFER_TAGS_DTQ
      ? 'This carrier is not providing a quote for this Offer'
      : 'Awaiting Offer details from this carrier';
  };

  const onRateDetailsSave = (rate: string) => {
    changeOffer({ ...offer, rate: rate });
  };

  useEffect(() => {
    setRateDetails(offer?.rate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  if (loading || planDetailsIsFetching || planDetailsIsLoading) {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingOutlined style={{ fontSize: 30 }} spin />
      </div>
    );
  }

  return (
    <>
      {isEdit && (
        <div className={styles.finalAlertWrapper}>
          {offerAttachedProposal?.isFinalApproved ? (
            <FixedAlertMessage
              type="info"
              message={FIXED_ALERT_MESSAGE_IF_FINAL_APPROVED}
            />
          ) : isNotEligibleToEdit ? (
            <FixedAlertMessage
              type="info"
              message={
                <p className={styles.warningPText}>
                  This offer is associated with{' '}
                  <span onClick={() => setShowUpdateOfferInPropModal(true)}>
                    existing proposals
                  </span>{' '}
                  and cannot be published as it contains changes that will
                  remove the offer from them. You may still save this offer as a
                  draft.
                </p>
              }
            />
          ) : null}
        </div>
      )}

      <div className={styles.previewOfferWrapper}>
        <Row
          className={styles.summaryWrapper}
          justify={'space-between'}
          gutter={24}
        >
          {benefitKind?.toUpperCase() !==
            OFFER_BENEFIT_TYPE_NAVIGATION_LABELS.VOLUNTARY_BENEFIT.toUpperCase() && (
            <>
              <Col span={5}>
                <OfferCardOverview
                  valueSizeMagnified={true}
                  headerText={'Total Est. Annual Premium'}
                  value={formatHeaderValues(
                    selectedCreditMode == 0
                      ? planDetailsData?.totalAnnualPremium
                      : planDetailsData?.totalAnnualPremiumWithCredit,
                    'CURRENCY',
                    false
                  )}
                  footerElement={totalAnnualPremiumFooter()}
                  isDtqOrPending={isDtqOrPending(offer?.offerStatus)}
                />
              </Col>
              <Col span={5}>
                <OfferCardOverview
                  valueSizeMagnified={true}
                  headerText={'From Current'}
                  value={
                    !isPlaceHolderOffer()
                      ? formatHeaderValues(
                          selectedCreditMode == 0
                            ? planDetailsData?.fromCurrent
                            : planDetailsData?.fromCurrentWithCredit,
                          'CURRENCY',
                          true
                        )
                      : formatHeaderValues(
                          selectedCreditMode == 0
                            ? planDetailsData?.totalAnnualPremium
                            : planDetailsData?.totalAnnualPremiumWithCredit,
                          'CURRENCY',
                          false
                        )
                  }
                  footerElement={
                    <Row>
                      {!isPlaceHolderOffer() &&
                        !planDetailsData?.noCurrentPlans && (
                          <FromCurrentPercentage
                            percentage={
                              selectedCreditMode == 0
                                ? planDetailsData?.fromCurrentPercentage
                                : planDetailsData?.fromCurrentPercentageWithCredit
                            }
                          />
                        )}
                      {isPlaceHolderOffer() ||
                      planDetailsData?.noCurrentPlans ? (
                        <span className={styles.naLabel}>N/A</span>
                      ) : (
                        ''
                      )}
                      <span className={styles.fromCurrent}>from Current</span>
                    </Row>
                  }
                  isDtqOrPending={isDtqOrPending(offer?.offerStatus)}
                />
              </Col>
              <Col span={5}>
                <OfferCardOverview
                  valueSizeMagnified={true}
                  headerText={'Credits'}
                  value={
                    planDetailsData?.discountAndCredit
                      ? currencyFormatter(
                          Math.round(planDetailsData?.discountAndCredit),
                          undefined,
                          undefined,
                          0
                        )
                      : '-'
                  }
                  footerElement={creditsFooter()}
                  isDtqOrPending={isDtqOrPending(offer?.offerStatus)}
                />
              </Col>
            </>
          )}
          <Col span={5}>
            <OfferCardOverview
              valueSizeMagnified={true}
              headerText={'Rate Details'}
              value={getRateDetails(rateDetails)}
              footerElement={<span></span>}
              isDtqOrPending={isDtqOrPending(offer?.offerStatus)}
              editIcon={true}
              setValue={setRateDetails}
              stringValue={rateDetails}
              saveEdited={onRateDetailsSave}
              editTitle={'Add Rate Details'}
              editBody={'Enter Rate Cap or Rate Guarantee'}
              showEllipsis={true}
            />
          </Col>
        </Row>

        <div className={styles.descriptionWrapper}>
          <div className={styles.title}>Offer Description</div>
          {isEmpty(offer?.offerDescription)
            ? '-'
            : reactHtmlParser(offer?.offerDescription)}
        </div>

        <div className={styles.planTableWrapper}>
          <div className={styles.title}>Plan Details</div>
          <Row>
            <Col span={8}>
              <span>
                <img src={DownloadIcon} alt=""></img>
              </span>
              <span
                className={styles.carrierDocuments}
                onClick={() => setIsCarrierDocumentsVisible(true)}
              >
                {CARRIER_DOCUMENTS}
              </span>
            </Col>
          </Row>
          {isDtqOrPending(offer?.offerStatus) ? (
            <div className={styles.planTableDtqPendingWrapper}>
              <h2 className={styles.dtqOrPendingHeader}>
                {getDtqOrPendingHeader()}
              </h2>
              <p className={styles.dtqOrPendingSubheader}>
                {getDtqOrPendingSubheader()}
              </p>
            </div>
          ) : (
            <div>
              <PlanTable
                data={
                  planDetailsData?.[
                    OFFER_PLAN_PROPERTY?.[
                      URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? '']
                    ]
                  ]
                }
                labelColumnData={arrangeColData}
                titleProperty={NAME_FIELD_PROPERTY}
                logoProperty={planDetailsData?.carrier?.logoUrl}
                benefitType={URL_PARAM_TO_BENEFIT_TYPE?.[benefitKind ?? '']}
                isPreviewSection={true}
                nTierCount={nTierCount}
              />
            </div>
          )}
        </div>
        <div className={styles.buttonWrapper}>
          <div className={styles.sendNotificationWrapper}>
            <Row>
              <div className={styles.sendNotificationText}>
                Send Notification after Publish &nbsp;
                <Popover
                  content={NOTIFICATION_PUBLISH_TOOLTIP}
                  placement="top"
                  overlayClassName="popoverInner proposalPublishTooltip"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </div>

              <Switch
                className={styles.sendNotificationSwitch}
                onChange={handleNotificationToggle}
              />
            </Row>
          </div>

          {/*  Next and Back section */}
          <Row className={styles.btnRow} gutter={16}>
            <Col className={styles.backBtnWrapper} span={8}>
              <SubmitButton
                className={styles.cancelButton}
                onClick={() => handleBack()}
              >
                Back
              </SubmitButton>
            </Col>
            <Col span={8}>
              <NextButton
                nextStep={() => {
                  publishOffer();
                }}
                buttonText={
                  appBootInfo?.type === loginTypes.erAdmin
                    ? 'Publish Offer'
                    : 'Publish Offer to Employer'
                }
                className={styles.nextBtn}
                disabled={buttonLoading || publishButtonDisable}
              />
            </Col>
            <Col span={24} className={styles.saveDraftBtn}>
              <TextButton
                label="Save Draft & Close"
                disabled={isSaveAsDraftBtnDisabled}
                onClick={saveAsDraft}
                type="primary"
              />
            </Col>
          </Row>
        </div>

        <ViewCreditDescription
          carrier={planDetailsData?.carrier.name}
          isModalVisible={isCreditDescriptionModalOpen}
          credit={planDetailsData?.discountAndCredit}
          creditDescription={planDetailsData?.discountAndCreditDescription}
          handleClose={setCreditDescriptionModalOpen}
        />

        <CreditInclusionPopup
          selectedCreditDropDown={selectedCreditDropDown}
          isCreditsExcludedModalVisible={isCreditsExcludedModalVisible}
          isCreditsIncludedModalVisible={isCreditsIncludedModalVisible}
          setIsCreditsExcludedModalVisible={setIsCreditsExcludedModalVisible}
          setIsCreditsIncludedModalVisible={setIsCreditsIncludedModalVisible}
        />
      </div>
      <UpdateConfirmationListModal
        visible={showUpdateOfferInPropModal}
        confirmText="Close"
        onConfirm={() => setShowUpdateOfferInPropModal(false)}
        title="Update Offers in Proposal"
        listTitle="Proposal name"
        description={WARNING_IS_ELIGIBLE_MODAL_DES}
        isCloseOnly={true}
        confirmBtnFullWidth={true}
        listData={offerAttachedProposal.list}
        closeOnlyBtnFullWidth={true}
        onClickItem={(item: any) =>
          handleRedirectToProposalsDetails(item.value, { brokerId, employerId })
        }
      />
      <CarrierDocuments
        isModalVisible={isCarrierDocumentsVisible}
        setIsModalVisible={setIsCarrierDocumentsVisible}
        offerId={planDetailsData?.offerId}
        carrierQuote={planDetailsData?.carrierQuote}
        otherDocumentsFile={planDetailsData?.otherDocumentsFile}
        editOfferId={offer?.offerId}
        isPreviewScreen={true}
      />
    </>
  );
};

export default PreviewOffer;
