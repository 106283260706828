import { FC, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { notification, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';

import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import OverviewHeader from 'modules/plans/components/OverviewPage/OverviewHeader/OverviewHeader';
import EditDocumentUploadModal from 'modules/plans/components/EditDocumentUploadModal/EditDocumentUploadModal';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';

import { BenefitCategory, BenefitKind } from 'constants/commonConstants';
import {
  DentalPlanDocumentType,
  MedicalPlanDocumentType,
  VisionPlanDocumentType,
  SBC,
  COC,
  PLAN_SUMMARY,
  PLAN_DOCUMENT,
  NO_NAME,
  RETIREMENT_401K,
  VoluntaryPlans,
  ViewLifePlanDocumentType,
  LTDPlanDocumentType,
  STDPlanDocumentType,
  RetirementPlanDocumentType,
  TaxAdvantagedPlanDocumentType,
  VoluntaryPlanDocumentType,
  BasicPlans,
} from 'modules/plans/constants';
import { PLAN_NOTIFICATION_KEY } from 'constants/benguideCollaborationConstants';
import { findMedicalPlanById } from 'modules/plans/slices/medicalPlanSlice';
import { findDentalPlanById } from 'modules/plans/slices/dentalPlanSlice';
import { findVisionPlanById } from 'modules/plans/slices/visionPlanSlice';
import { getPlanDocument } from 'modules/plans/services/PlanService';
import { findWellbeingPlanById } from 'modules/plans/slices/wellbeingPlanSlice';
import { findPlanById as findWorkLifePlanById } from 'modules/plans/slices/workLifePlanSlice';
import { findAdditionalPerkPlanById } from 'modules/plans/slices/additionalPerkPlanSlice';
import { findPlanById as findVoluntaryPlanById } from 'modules/plans/slices/voluntaryBenefitPlanSlice';
import { findPlanById as findLifePlanById } from 'modules/plans/slices/lifePlanSlice';
import { findPlanById as findTaxAdvantagedPlanById } from 'modules/plans/slices/taxAdvantagedAccountPlanSlice';
import { findPlanById as findRetirementPlanById } from 'modules/plans/slices/retirementPlanSlice';
import { findPlanById as findTeleHealthPlanById } from 'modules/plans/slices/telehealthRxPlanSlice';
import { Plan } from 'model/plans/Plan';
import { convertToOptions, convertToWebLinkType } from 'util/commonUtil';
import WebLinkType from 'model/plans/WebLinkType';
import Option from 'model/Option';
import styles from './planDocumentOverview.module.less';

type PlanDocumentsOverviewProps = {
  heading: string;
  plan: Plan;
  benefitKind: string;
  documents?: { [key: string]: string } | undefined;
  fetchPlanWhenUpdated?: any;
  disableEditButton?: boolean;
  viewPlanDocumentsModal: boolean;
  setViewPlanDocumentsModal: (value: boolean) => void;
};

const PlanDocumentsOverview: FC<PlanDocumentsOverviewProps> = (props) => {
  const {
    heading,
    plan,
    benefitKind,
    documents,
    fetchPlanWhenUpdated,
    disableEditButton,
    viewPlanDocumentsModal,
    setViewPlanDocumentsModal,
  } = props;
  const params = useParams();
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();

  const { medicalDocumentReferences } = useAppSelector(
    (state) => state.plan.plans
  );
  const { dentalDocumentReferences } = useAppSelector(
    (state) => state.plan.dentalPlan
  );

  const { visionDocumentReferences } = useAppSelector(
    (state) => state.plan.visionPlan
  );

  const documentsRef = useRef<any>();
  const additionalPlanDocumentTypes = convertToOptions(
    plan.additionalDocuments
  );
  const additionalWeblinks = convertToWebLinkType(plan.additionalWeblinks);
  const MDVBenefitKind = [
    BenefitCategory.MEDICAL.value,
    BenefitCategory.VISION.value,
    BenefitCategory.DENTAL.value,
  ];

  const taxAdvantageBenefitKinds = [
    BenefitKind.HRA.value,
    BenefitKind.FSA.value,
    BenefitKind.DCAP_FSA.value,
    BenefitKind.LIMITED_FSA.value,
    BenefitKind.HSA.value,
    BenefitKind.COMMUTER_GENERIC.value,
  ];

  const lifeVoluntaryBenefitKinds = [
    BenefitCategory.BASIC_ADD.value,
    BenefitCategory.BASIC_LIFE.value,
    BenefitCategory.BASIC_LIFE_AND_ADD.value,
    BenefitCategory.STD.value,
    BenefitCategory.LTD.value,
    BenefitCategory.LIFE.value,
    VoluntaryPlans.VOLUNTARY_LIFE.value,
    VoluntaryPlans.VOLUNTARY_ADD.value,
    VoluntaryPlans.VOLUNTARY_LIFE_ADD.value,
    VoluntaryPlans.VOLUNTARY_STD.value,
    VoluntaryPlans.VOLUNTARY_LTD.value,
  ];

  const retirementBenefitKind = [BenefitKind.RETIREMENT_401K.value];

  useEffect(() => {
    if (employerId && brokerId && params.planId && !plan) {
      switch (benefitKind) {
        case BenefitCategory.MEDICAL.value:
          dispatch(findMedicalPlanById(params.planId));
          break;
        case BenefitCategory.DENTAL.value:
          dispatch(findDentalPlanById(params.planId));
          break;
        case BenefitCategory.VISION.value:
          dispatch(findVisionPlanById(params.planId));
          break;
      }
    }
  }, [dispatch, employerId, brokerId, params.planId, benefitKind, plan]);

  const onClick = async (
    planId: string | undefined,
    file: string,
    fileName: string
  ) => {
    if (planId) {
      const response = await getPlanDocument(planId, file, benefitKind);
      const blob = new Blob([response.data], {
        type: response?.data?.type || 'application/pdf',
      });
      const url = window.URL.createObjectURL(blob);
      const aDom = document.createElement('a');
      aDom.setAttribute('style', 'display:none');
      aDom.setAttribute('href', url);
      aDom.setAttribute('download', fileName);
      document.body.appendChild(aDom);
      aDom.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(aDom);
    }
  };

  const getDocumentTypesForLifeBenefitKind = (benefitKind: string) => {
    let documentTypes = { ...ViewLifePlanDocumentType };

    switch (benefitKind) {
      case BasicPlans.STD.value:
      case VoluntaryPlans.VOLUNTARY_STD.value:
        documentTypes = { ...documentTypes, ...STDPlanDocumentType };
        break;
      case BasicPlans.LTD.value:
      case VoluntaryPlans.VOLUNTARY_LTD.value:
        documentTypes = { ...documentTypes, ...LTDPlanDocumentType };
        break;

      case VoluntaryPlans.VOLUNTARY_LIFE.value:
      case VoluntaryPlans.VOLUNTARY_ADD.value:
      case VoluntaryPlans.VOLUNTARY_LIFE_ADD.value:
        documentTypes = { ...documentTypes, ...VoluntaryPlanDocumentType };
        break;
    }

    return documentTypes;
  };

  const renderDocument = (
    documents?: { [key: string]: string } | undefined,
    docKey?: string
  ) => {
    if (documents && documents[docKey!]) {
      return (
        <LinkButton
          onClick={() => onClick(plan.id, docKey!, documents[docKey!])}
        >
          <span className={styles.fileName}>{documents[docKey!]}</span>
        </LinkButton>
      );
    }
    return '-';
  };

  const lifeVoluntaryDocumentList = (
    documents?: { [key: string]: string } | undefined,
    benefitKind?: string
  ) => {
    const documentTypes = getDocumentTypesForLifeBenefitKind(benefitKind!);

    return (
      <>
        {Object.keys(documentTypes)?.map((docKey) => (
          <InfoLabelSection
            key={docKey}
            labelText={
              documentTypes[docKey as keyof typeof documentTypes]?.label
            }
            value={renderDocument(documents, docKey)}
          />
        ))}
      </>
    );
  };

  const retirementDocumentList = (documents?: { [key: string]: string }) => {
    const documentTypes = RetirementPlanDocumentType;

    return (
      <>
        {Object.keys(documentTypes).map((docKey) => {
          const documentKey =
            docKey === ViewLifePlanDocumentType.PLAN_SUMMARY.value
              ? RetirementPlanDocumentType.PLAN_SUMMARY.value
              : docKey;

          return (
            <InfoLabelSection
              key={docKey}
              labelText={
                documentTypes[docKey as keyof typeof documentTypes].label
              }
              value={renderDocument(documents, documentKey)}
            />
          );
        })}
      </>
    );
  };

  const renderWeblink = (weblink: string) => {
    if (weblink) {
      return (
        <LinkButton onClick={() => window.open(weblink)}>
          <span className={styles.fileName}>{weblink}</span>
        </LinkButton>
      );
    }
  };

  const editPlan = () => {
    setViewPlanDocumentsModal && setViewPlanDocumentsModal(true);
    if (fetchPlanWhenUpdated) {
      fetchPlanOnNotificationReceived();
      notification.close(PLAN_NOTIFICATION_KEY);
    }
  };

  const onClose = () => {
    documentsRef.current?.resetAll();
    setViewPlanDocumentsModal && setViewPlanDocumentsModal(false);
    if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
      switch (benefitKind) {
        case BenefitCategory.MEDICAL.value:
          dispatch(findMedicalPlanById(params.planId));
          break;
        case BenefitCategory.DENTAL.value:
          dispatch(findDentalPlanById(params.planId));
          break;
        case BenefitCategory.VISION.value:
          dispatch(findVisionPlanById(params.planId));
          break;
        case RETIREMENT_401K:
          dispatch(findRetirementPlanById(params.planId));
          break;
        case BenefitKind.WELLBEING_GENERIC.value:
        case BenefitCategory.WELLBEING.value:
          dispatch(findWellbeingPlanById(params.planId));
          break;
        case BenefitCategory.TELEHEALTH.value:
        case BenefitCategory.RX_DELIVERY.value:
        case BenefitCategory.RX_COUPONS.value:
          dispatch(findTeleHealthPlanById(params.planId));
          break;
        case BenefitKind.PAID_TIME_OFF.value:
        case BenefitKind.HOLIDAY.value:
        case BenefitKind.SICK.value:
        case BenefitKind.FLEXIBLE_WORKING_HOURS.value:
        case BenefitKind.FAMILY_AND_LEAVE_OTHER.value:
        case BenefitKind.FAMILY_AND_LEAVE.value:
        case BenefitKind.PARENTAL_LEAVE.value:
        case BenefitKind.BACKUP_CHILDCARE.value:
        case BenefitKind.FERTILITY.value:
        case BenefitKind.BEREAVEMENT.value:
          dispatch(findWorkLifePlanById(params.planId));
          break;
        case BenefitCategory.ADDITIONAL_PERK.value:
          dispatch(findAdditionalPerkPlanById(params.planId));
          break;
        case BenefitKind.CUSTOM_VOLUNTARY_BENEFIT.value:
        case BenefitKind.ACCIDENT.value:
        case BenefitKind.CRITICAL_ILLNESS.value:
        case BenefitKind.HOSPITAL.value:
          dispatch(findVoluntaryPlanById(params.planId));
          break;
        case BenefitCategory.BASIC_ADD.value:
        case BenefitCategory.BASIC_LIFE.value:
        case BenefitCategory.BASIC_LIFE_AND_ADD.value:
        case BenefitCategory.STD.value:
        case BenefitCategory.LTD.value:
        case BenefitCategory.LIFE.value:
        case VoluntaryPlans.VOLUNTARY_LIFE.value:
        case VoluntaryPlans.VOLUNTARY_ADD.value:
        case VoluntaryPlans.VOLUNTARY_LIFE_ADD.value:
        case VoluntaryPlans.VOLUNTARY_STD.value:
        case VoluntaryPlans.VOLUNTARY_LTD.value:
          dispatch(findLifePlanById(params.planId));
          break;
        case BenefitKind.HRA.value:
        case BenefitKind.FSA.value:
        case BenefitKind.DCAP_FSA.value:
        case BenefitKind.LIMITED_FSA.value:
        case BenefitKind.HSA.value:
        case BenefitKind.COMMUTER_GENERIC.value:
          dispatch(findTaxAdvantagedPlanById(params.planId));
          break;
      }
    }
  };

  const fetchPlanOnNotificationReceived = () => {
    if (params.planId) {
      switch (benefitKind) {
        case BenefitCategory.MEDICAL.value:
          dispatch(findMedicalPlanById(params.planId));
          break;
        case BenefitCategory.DENTAL.value:
          dispatch(findDentalPlanById(params.planId));
          break;
        case BenefitCategory.VISION.value:
          dispatch(findVisionPlanById(params.planId));
          break;
      }
    }
  };

  const getDocuments = () => {
    switch (benefitKind) {
      case BenefitCategory.MEDICAL.value:
        return medicalDocumentReferences;
      case BenefitCategory.DENTAL.value:
        return dentalDocumentReferences;
      case BenefitCategory.VISION.value:
        return visionDocumentReferences;
      default:
        return documents;
    }
  };
  const getDocumentType = () => {
    switch (benefitKind) {
      case BenefitCategory.MEDICAL.value:
        return MedicalPlanDocumentType;
      case BenefitCategory.DENTAL.value:
        return DentalPlanDocumentType;
      case BenefitCategory.VISION.value:
        return VisionPlanDocumentType;
      case BenefitCategory.BASIC_ADD.value:
      case BenefitCategory.BASIC_LIFE.value:
      case BenefitCategory.BASIC_LIFE_AND_ADD.value:
      case BenefitCategory.STD.value:
      case BenefitCategory.LTD.value:
      case BenefitCategory.LIFE.value:
      case VoluntaryPlans.VOLUNTARY_LIFE.value:
      case VoluntaryPlans.VOLUNTARY_ADD.value:
      case VoluntaryPlans.VOLUNTARY_LIFE_ADD.value:
      case VoluntaryPlans.VOLUNTARY_STD.value:
      case VoluntaryPlans.VOLUNTARY_LTD.value:
        return getDocumentTypesForLifeBenefitKind(benefitKind);
      case BenefitKind.RETIREMENT_401K.value:
        return RetirementPlanDocumentType;
      case BenefitKind.HRA.value:
      case BenefitKind.FSA.value:
      case BenefitKind.DCAP_FSA.value:
      case BenefitKind.LIMITED_FSA.value:
      case BenefitKind.HSA.value:
      case BenefitKind.COMMUTER_GENERIC.value:
        return TaxAdvantagedPlanDocumentType;
      default:
        return {};
    }
  };

  return (
    <div>
      <Row>
        <OverviewHeader
          title={heading}
          onEditClick={editPlan}
          disabled={disableEditButton}
        />
      </Row>
      <div className={styles.tableWrapper}>
        {benefitKind === 'MEDICAL' && (
          <InfoLabelSection
            labelText="SBC File"
            value={
              documents && !isEmpty(documents.SBC)
                ? renderDocument(documents, SBC)
                : '-'
            }
          />
        )}
        {MDVBenefitKind.includes(benefitKind) && (
          <>
            <InfoLabelSection
              labelText="COC File Upload"
              value={
                documents && !isEmpty(documents.COC)
                  ? renderDocument(documents, COC)
                  : '-'
              }
            />
            <InfoLabelSection
              labelText="Plan Summary File Upload"
              value={
                documents && !isEmpty(documents.PLAN_SUMMARY)
                  ? renderDocument(documents, PLAN_SUMMARY)
                  : '-'
              }
            />
          </>
        )}
        {taxAdvantageBenefitKinds.includes(benefitKind) && (
          <>
            <InfoLabelSection
              labelText="Plan Summary"
              value={
                documents && !isEmpty(documents.PLAN_SUMMARY)
                  ? renderDocument(documents, PLAN_SUMMARY)
                  : '-'
              }
            />
            <InfoLabelSection
              labelText="Plan Document"
              value={
                documents && !isEmpty(documents.PLAN_DOCUMENT)
                  ? renderDocument(documents, PLAN_DOCUMENT)
                  : '-'
              }
            />
          </>
        )}
        {lifeVoluntaryBenefitKinds.includes(benefitKind) &&
          lifeVoluntaryDocumentList(documents, benefitKind)}

        {retirementBenefitKind.includes(benefitKind) &&
          retirementDocumentList(documents)}

        {additionalPlanDocumentTypes.map((doc: Option, index: number) => {
          return (
            <div key={index}>
              <InfoLabelSection
                labelText={doc.value.startsWith(NO_NAME) ? '-' : doc.value}
                value={
                  plan.additionalDocuments &&
                  renderDocument(plan.additionalDocuments, doc.value)
                }
              />
            </div>
          );
        })}

        {additionalWeblinks.map((weblink: WebLinkType, index: number) => {
          return (
            <div key={index}>
              <InfoLabelSection
                labelText={
                  weblink.planDocumentName.startsWith(NO_NAME)
                    ? '-'
                    : weblink.planDocumentName
                }
                value={renderWeblink(weblink.weblink)}
              />
            </div>
          );
        })}
      </div>
      <EditDocumentUploadModal
        visible={viewPlanDocumentsModal}
        onClose={onClose}
        plan={plan}
        benefitKind={benefitKind}
        documentTypes={getDocumentType()}
        documentReferences={getDocuments()}
        ref={documentsRef}
      />
    </div>
  );
};

export default PlanDocumentsOverview;
