import React, { useEffect, useState } from 'react';
import loaderGif from 'assets/images/rfp-file-upload-pending.gif';

import styles from './loadingBar.module.less';

interface LoadingBarProps {
  duration: number;
}

const LoadingBar: React.FC<LoadingBarProps> = ({ duration }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const startTime = Date.now();
    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      if (elapsedTime >= duration) {
        clearInterval(intervalId);
        setProgress(100);
      } else {
        const newProgress = (elapsedTime / duration) * 100;
        setProgress(newProgress);
      }
    }, 16);
    return () => {
      clearInterval(intervalId);
    };
  }, [duration]);

  return (
    <div>
      <img src={loaderGif} alt="scanning" className={styles.loadingGif} />
      <div className={styles.loadingBarContainer}>
        <div
          className={styles.loadingBar}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default LoadingBar;
