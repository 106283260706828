import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { DataColumn } from 'components/DataTable/DataColumn';
import ToolList from 'components/ToolList/ToolList';
import {
  useGetBrokersQuery,
  useUpdateBrokerFeatureStatusMutation,
} from 'api/featureControl';
import FeatureToggle from 'components/FeatureToggle/FeatureToggle';
import TablePagination from 'model/TablePagination';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { getEnabledLocations } from 'util/commonUtil';
import DisableFeatureMessage from 'components/DisableFeatureMessage/DisableFeatureMessage';
import BrokerLocationStatus from 'modules/tools/components/GetLocationTextList/BrokerLocationStatus';

import styles from './featureList.module.less';

type BrokerIdCardsListProps = {};

const BrokerIdCardsList: FC<BrokerIdCardsListProps> = (
  props: BrokerIdCardsListProps
) => {
  const locationModalRef = useRef<any>();

  const [updateStatus] = useUpdateBrokerFeatureStatusMutation({});
  const [searchText, setSearchText] = useState<string>('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState('name');
  const {
    data: featureWrappers = [],
    refetch,
    isFetching,
  } = useGetBrokersQuery({
    page: pageIndex,
    key: 'ID_CARDS',
    query: searchText,
    size: pageSize,
    sort: sortOrder,
  });

  useEffect(() => {
    refetch();
  }, [refetch, searchText]);

  const dataColumns: DataColumn[] = [
    {
      title: 'BROKER',
      dataIndex: 'brokerName',
      key: 'brokerName',
      width: '70%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.brokerName.localeCompare(b.brokerName),
      defaultSortOrder: 'ascend',
      render: (item) => {
        return (
          <OverflowPopover>
            <span className="text table-item-link">{item}</span>
          </OverflowPopover>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'LAST STATUS UPDATED',
      dataIndex: 'lastUpdatedTs',
      key: 'lastUpdatedTs',
      width: '50%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (isEmpty(a.lastUpdatedTs)) {
          return 1;
        } else {
          return a.lastUpdatedTs.localeCompare(b.lastUpdatedTs);
        }
      },
      render: (colData, record) => {
        const updatedAt = dayjs(colData).format('MMMM D, YYYY h:mm A');
        return isEmpty(colData) ? (
          <div>-</div>
        ) : (
          <div>
            {updatedAt}
            <br></br>
            <span className={styles.addedBy}>
              by {record.lastUpdatedByAdminName}
            </span>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'enabled',
      key: 'locationCount',
      width: '17%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      render: (colData, record, index) => {
        return (
          <BrokerLocationStatus
            locationModalRef={locationModalRef}
            record={record}
          />
        );
      },
      align: 'left',
    },
    {
      title: 'ENABLED',
      dataIndex: 'enabled',
      key: 'enabled',
      width: '15%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (a.enabled) {
          return -1;
        } else {
          return 1;
        }
      },
      render: (colData, record, index) => {
        const enabledLocations = getEnabledLocations(record?.locationVOS);
        return (
          <FeatureToggle
            record={record}
            allLocationEnabled={!isEmpty(enabledLocations)}
            ref={locationModalRef}
            refetchBrokers={refetch}
            onConfirmToggle={() => {
              locationModalRef.current?.closeDisableModal();
              updateStatus({
                enabled: !colData,
                organizationId: record.organizationId,
                key: 'ID_CARDS',
              }).then(() => {
                refetch();
              });
            }}
            enabled={colData}
            recordName={record.brokerName}
            organizationId={record.organizationId}
            enableMessage={
              <div>
                Are you sure you want to enable the ID Cards? This tool will be
                immediately available to the employer and their employees via
                the Mobile app or Benefits Guide.
                <br />
                <br />
                Employees must use their company email to register in the app.
              </div>
            }
            disableMessage={
              <DisableFeatureMessage
                locations={enabledLocations}
                featureKey="ID Cards"
              />
            }
            title={'ID Cards'}
            enableText="Enable ID Cards"
            featureType={'ID_CARDS'}
          />
        );
      },
      align: 'left',
    },
  ];

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();

    setSearchText(_searchText);
  };

  useEffect(() => {
    setPaginationConfig(paginationConfig);
    setPageIndex(paginationConfig.paginationIndex);
    setPageSize(paginationConfig.filterInfo.limit);
    const { field, order } = (paginationConfig.sorterInfo as any) || {};
    if (isEmpty(order)) {
      setSortOrder('');
    } else if (field == 'brokerName' && order == 'descend') {
      setSortOrder('-name');
    } else if (field == 'brokerName' && order == 'ascend') {
      setSortOrder('name');
    }
  }, [paginationConfig, isFetching]);

  return (
    <div className={styles.featureTableWrapper}>
      <ToolList
        title="ID Cards"
        columns={dataColumns}
        data={featureWrappers}
        hasBenefitsCategoryFilter={false}
        search={'Search Broker'}
        handleSearch={handleSearch}
        isLoading={isFetching}
      ></ToolList>
    </div>
  );
};

export default BrokerIdCardsList;
