import { FC } from 'react';

import iconRemove from 'assets/images/icon-remove-red.svg';
import { NewPlanTag } from 'modules/renewals/components/NewPlanTag/NewPlanTag';

import styles from './colHeader.module.less';

type ColHeaderProps = {
  titleData: any;
  dataIndex: number;
  removePlans: boolean;
  addPlanIndex?: number[];
  updatedData: any;
  updateData?: Function;
  titleProperty: string;
  shrinkedHeader?: boolean;
  isComparePlans?: boolean;
  logoProperty: string;
  isNewTagApplicable: Function;
  removeServiceIconClicked: Function;
};

const colHeader: FC<ColHeaderProps> = ({
  titleData,
  dataIndex,
  removePlans,
  addPlanIndex,
  updatedData,
  updateData,
  titleProperty,
  shrinkedHeader,
  isComparePlans,
  logoProperty,
  isNewTagApplicable,
  removeServiceIconClicked,
}) => {
  return (
    <div className={styles.titleWrapper}>
      {removePlans &&
      !(
        addPlanIndex?.length === 1 &&
        addPlanIndex.includes(0) &&
        (updatedData === null ||
          updatedData === undefined ||
          updateData?.length === 1)
      ) ? (
        <img
          src={iconRemove}
          alt="remove-service-icon"
          className={styles.iconRemove}
          onClick={() => {
            removeServiceIconClicked(dataIndex);
          }}
        />
      ) : (
        ''
      )}
      <span className={styles.title}>{titleData?.[titleProperty]}</span>
      {!shrinkedHeader && (
        <>
          <div className={`${isComparePlans && styles.comparingLogo}`}>
            <img
              src={isComparePlans ? titleData.carrierLogo : logoProperty}
              className={styles.logo}
            />
          </div>
        </>
      )}
      {isNewTagApplicable(dataIndex, titleData) && (
        <>
          <div className={`${isComparePlans && styles.comparingLogo}`}>
            <NewPlanTag className={styles.newPlanTagOfferPreview} />
          </div>
        </>
      )}
      <div className={styles.titleTag} />
    </div>
  );
};

export default colHeader;
