import { FC, useEffect, useRef, useState } from 'react';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';

import Consultant from 'model/benefitsConsultation/Consultant';

import AddBenefitConsultant from 'modules/benefitsConsultation/components/AddBenefitConsultant/AddBenefitConsultant';

import {
  addRemoveERConsultants,
  getERConsultantsSummary,
  addRemoveConsultantsStarted,
  addRemoveConsultantsSuccess,
  getConsultantsSummaryStarted,
  getEmployerERConsultantsListCompleted,
  addRemoveConsultantsFailed,
} from 'modules/benefitsConsultation/slices/employerLevelSlice';
import ConsultantType from 'modules/benefitsConsultation/enums/ConsultantType';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

type AddBrokerConsultantsProps = {
  visible: boolean;
  setVisible: Function;
  employerId?: string;
  organizationId?: string;
};

const AddEmployerConsultants: FC<AddBrokerConsultantsProps> = (
  props: AddBrokerConsultantsProps
) => {
  const { visible, setVisible, organizationId, employerId } = props;

  const [isFieldsEmpty, setIsFieldsEmpty] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const activeEmployerAdminsList = useAppSelector(
    (state) => state.benefitsConsultation.employerLevel.activeEmployerAdminsList
  );
  const externalEmployerConsultantsList = useAppSelector(
    (state) =>
      state.benefitsConsultation.employerLevel.externalEmployerConsultantsList
  );
  const requestType = useAppSelector(
    (state) => state.benefitsConsultation.employerLevel.requestType
  );
  const inProgress = useAppSelector(
    (state) => state.benefitsConsultation.employerLevel.inProgress
  );

  const checkboxListSelectorRef = useRef<any>(null);
  const addExternalBCRef = useRef<any>(null);

  useEffect(() => {
    organizationId &&
      employerId &&
      dispatch(getERConsultantsSummary(organizationId, employerId));
  }, [organizationId, dispatch, employerId]);

  useEffect(() => {
    if (requestType === getEmployerERConsultantsListCompleted.type) {
      organizationId &&
        employerId &&
        dispatch(getERConsultantsSummary(organizationId, employerId));
    }
  }, [dispatch, employerId, organizationId, requestType]);

  useEffect(() => {
    if (requestType === addRemoveConsultantsSuccess.type) setVisible(false);
  }, [requestType, setVisible]);

  const addEmployerConsultants = () => {
    const newConsultants =
      checkboxListSelectorRef.current.getConsultantsToBeAdded();
    let addedConsultantsList = newConsultants.filter(
      (consultant: Consultant) => consultant.isConsultant
    );
    addedConsultantsList = addedConsultantsList.map((consultant: Consultant) =>
      omit(consultant, ['previous'])
    );
    const consultantsList = activeEmployerAdminsList.map(
      (obj) =>
        addedConsultantsList.find((o: Consultant) => o.id === obj.id) || obj
    );

    let newExternalConsultants =
      addExternalBCRef.current.getExternalConsultantsToBeAdded();

    newExternalConsultants = newExternalConsultants.map((data: Consultant) => ({
      ...data,
      type: ConsultantType.EMPLOYER_EXTERNAL,
      organizationId: organizationId,
      employerId: employerId,
      isAssigned: true,
    }));

    if (!isEmpty(newExternalConsultants) || !isEmpty(addedConsultantsList)) {
      dispatch(
        addRemoveERConsultants(
          addedConsultantsList,
          consultantsList,
          newExternalConsultants,
          externalEmployerConsultantsList.concat(newExternalConsultants)
        )
      ).then(() => {
        addExternalBCRef.current.resetData();
      });
    } else {
      if (isFieldsEmpty) {
        addExternalBCRef.current.resetData();
        setVisible(false);
      }
    }
  };

  return (
    <AddBenefitConsultant
      modalHeading="Add Employer Consultant"
      visible={visible}
      setVisible={setVisible}
      saveInProgress={
        inProgress && requestType === addRemoveConsultantsStarted.type
      }
      listIsLoading={
        inProgress && requestType === getConsultantsSummaryStarted.type
      }
      onSave={addEmployerConsultants}
      addExternalBCRef={addExternalBCRef}
      checkboxListSelectorRef={checkboxListSelectorRef}
      activeAdminsList={activeEmployerAdminsList}
      externalConsultantsList={externalEmployerConsultantsList}
      consultantTypeText="Employer Users"
      addRemoveFailed={requestType === addRemoveConsultantsFailed.type}
      isFieldsEmpty={isFieldsEmpty}
      setIsFieldsEmpty={setIsFieldsEmpty}
    />
  );
};

export default AddEmployerConsultants;
