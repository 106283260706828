import { Dispatch } from 'redux';
import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdditionalPlan } from 'model/plans/AdditionalPlan';
import * as WorkLifePlanService from 'modules/plans/services/WorkLifePlanService';
import MetaData from 'model/MetaData';
import PaginationConfig from 'model/PaginationConfig';
import ErrorDetails from 'model/ErrorDetails';
import ClonePlanDocument from 'model/plans/ClonePlanDocument';
import { clonePlanDocuments } from './planDocumentsSlice';

export type WorkLifePlanServiceState = {
  inProgress: boolean;
  error: any;
  requestType: string;
  workLifePlan: AdditionalPlan;
  workLifePlanList: {
    content: Array<AdditionalPlan>;
    metadata: MetaData;
  };
};

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  workLifePlan: {} as AdditionalPlan,
  workLifePlanList: { content: [], metadata: {} },
} as WorkLifePlanServiceState;

const workLifePlanSlice = createSlice({
  name: 'WorkLifePlan',
  initialState,
  reducers: {
    updateWorkLifePlanStarted: (state) => {
      state.inProgress = true;
    },
    createWorkLifePlan: (state, action: PayloadAction<AdditionalPlan>) => {
      state.workLifePlan = action.payload;
      state.inProgress = false;
      state.error = null;
      state.requestType = action.type;
    },
    updateWorkLifePlanCompleted: (
      state,
      action: PayloadAction<AdditionalPlan>
    ) => {
      state.inProgress = false;
      state.workLifePlan = {
        ...state.workLifePlan,
        ...action.payload,
      };
      state.error = null;
      state.requestType = action.type;
    },
    updateWorkLifePlanFailed: (state, action) => {
      state.inProgress = false;
      state.error = JSON.parse(JSON.stringify(action.payload));
      state.requestType = action.type;
    },
    getWorkLifeListInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
    },
    getWorkLifePlansListCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.inProgress = false;
      state.error = null;
      state.workLifePlanList = payload;
    },
    getWorkLifePlansListFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = { response: action.payload };
    },
    getPlanByIdStarted: (state) => {
      state.inProgress = true;
      state.workLifePlan = {} as AdditionalPlan;
      state.error = null;
    },
    getPlanByIdCompleted: (state, action: PayloadAction<AdditionalPlan>) => {
      state.inProgress = false;
      state.workLifePlan = action.payload;
      state.error = null;
    },
    getPlanByIdFailed: (state, action) => {
      state.inProgress = false;
      state.workLifePlan = {} as AdditionalPlan;
      state.error = { response: action.payload };
    },
    clearApiErrors: (state) => {
      state.error = null;
    },
  },
});

export const {
  createWorkLifePlan,
  updateWorkLifePlanStarted,
  updateWorkLifePlanCompleted,
  updateWorkLifePlanFailed,
  getWorkLifeListInProgress,
  getWorkLifePlansListCompleted,
  getWorkLifePlansListFailed,
  getPlanByIdStarted,
  getPlanByIdCompleted,
  getPlanByIdFailed,
  clearApiErrors,
} = workLifePlanSlice.actions;

export default workLifePlanSlice.reducer;

export const saveWorkLifePlan = (
  plan: AdditionalPlan,
  onSave: Function,
  cloneDocuments?: boolean,
  sourcePlanId?: string,
  benefitCategory?: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateWorkLifePlanStarted());
    try {
      let response = {} as any;
      if (plan.id) {
        response = await WorkLifePlanService.createWorkLifePlan(plan, true);
      } else {
        response = await WorkLifePlanService.createWorkLifePlan(plan);
      }
      const data = response.data;
      dispatch(updateWorkLifePlanCompleted(JSON.parse(JSON.stringify(data))));
      if (
        data &&
        cloneDocuments &&
        plan.documents &&
        sourcePlanId &&
        benefitCategory
      ) {
        const clonePlanDoc: ClonePlanDocument = {
          employerId: data.employerId,
          sourcePlanId: sourcePlanId,
          targetPlanId: data.id,
          benefitCategory: benefitCategory,
          benefitKind: plan.benefitKind,
        };
        dispatch(clonePlanDocuments(clonePlanDoc));
      }
      onSave(data.id);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            updateWorkLifePlanFailed({
              data: error.response.data,
            } as ErrorDetails)
          );
        }
      }
    }
  };
};

export const getWorkLifePlanList = (
  { page, size, sort, query }: PaginationConfig,
  employerId: string,
  planYearId: string,
  benefitCategory: string | null
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getWorkLifeListInProgress());
    try {
      const response = await WorkLifePlanService.getWorkLifePlans(
        page,
        size,
        sort,
        query,
        employerId,
        planYearId,
        benefitCategory
      );
      dispatch(getWorkLifePlansListCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getWorkLifePlansListFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const findPlanById = (planId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPlanByIdStarted());
    try {
      const response = await WorkLifePlanService.getPlanById(planId);
      dispatch(getPlanByIdCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(getPlanByIdFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
};
