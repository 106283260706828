import BrokerList from 'modules/brokers/pages/BrokerList/BrokerList';
import EmployerList from 'modules/employers/pages/EmployerList/EmployerList';
import BrokerDashboard from 'modules/brokers/pages/Dashboard/BrokerDashboard';
import ERDashboard from 'modules/employers/pages/Dashboard/ERDashboard';
import PlatformDashboard from 'modules/platformDashboard/PlatformDashboard';
import PlanIntro from 'modules/plans/medical/pages/PlanIntro/PlanIntro';
import MedicalOverviewPage from 'modules/plans/medical/pages/MedicalOverviewPage/MedicalOverviewPage';
import LumityAdminList from 'modules/admins/pages/LumityAdminList/LumityAdminList';
import EmployerInfo from 'modules/employers/pages/EmployerInfo/EmployerInfo';
import BenefitGuideHome from 'modules/benefitGuide/pages/BenfitGuideHome';
import WellbeingPlanList from 'modules/plans/wellbeing/pages/WellbeingPlanList/WellbeingPlanList';
import AdditionalPerkPlanList from 'modules/plans/additionalPerk/pages/AdditionalPerkPlanList/AdditionalPerkPlanList';
import DentalPlanList from 'modules/plans/dental/pages/DentalPlan/DentalPlanList';
import DentalOverviewPage from 'modules/plans/dental/pages/DentalOverviewPage/DentalOverviewPage';
import FourOOneKPlanList from 'modules/plans/retirement/pages/FourOOneKPlan/FourOOneKPlanList';
import TelehealthRxPlanList from 'modules/plans/telehealthRx/pages/TelehealthRxPlanList/TelehealthRxPlanList';
import LifePlansList from 'modules/plans/life/pages/LifePlansList/LifePlansList';
import VisionOverviewPage from 'modules/plans/vision/pages/VisionOverviewPage/VisionOverviewPage';
import LifePlanPlanOverview from 'modules/plans/life/pages/LifePlanOverview/LifePlanOverview';
import TaxAdvantagedPlanOverview from 'modules/plans/taxAdvantaged/pages/TaxAdvantagedPlanOverview/TaxAdvantagedPlanOverview';

import { loginTypes } from 'constants/authConstants';
import VisionPlansList from 'modules/plans/vision/pages/VisionPlansList/VisionPlansList';
import HolidaysTimeOffPlanList from 'modules/plans/holidaysTimeOff/pages/HolidaysTimeOffPlanList/HolidaysTimeOffPlanList';
import FamilyAndLeavePlanList from 'modules/plans/familyAndLeave/pages/FamilyAndLeavePlanList/FamilyAndLeavePlanList';
import VoluntaryBenefitList from 'modules/plans/voluntary/pages/VoluntaryBenefitList';
import FourOOneKPlanOverview from 'modules/plans/retirement/pages/FourOOneKPlanOverview/FourOOneKPlanOverview';
import WorkLifePlanOverview from 'modules/plans/components/WorkLifePlanOverview/WorkLifePlanOverview';
import TelehealthRxPlanOverview from 'modules/plans/telehealthRx/pages/TelehealthRxPlanOverview/TelehealthRxPlanOverview';
import VoluntaryOverViewPage from 'modules/plans/voluntary/components/VoluntaryPlanOverview/VoluntaryPlanOverview';
import TaxAdvantagedPlanList from 'modules/plans/taxAdvantaged/pages/TaxAdvantagedPlanList/TaxAdvantagedPlanList';
import CarrierList from 'modules/carriers/components/CarrierList/CarriersList';
import CarrierInfo from 'modules/carriers/components/CarrierInfo/CarrierInfo';
import BrokerConsultation from 'modules/benefitsConsultation/pages/BrokerConsultation/BrokerConsultation';
import EmployerConsultation from 'modules/benefitsConsultation/pages/EmployerConsultation/EmployerConsultation';
import Documents from 'modules/documents/pages/Documents/Documents';
import BrokerInfo from 'modules/brokers/pages/BrokerInfo/BrokerInfo';
import BrokerClaimsList from 'modules/tools/pages/BrokerClaimsList';
import ClaimsHomePage from 'modules/claims/pages/ClaimsHomePage';
import BrokerBillingList from 'modules/tools/pages/BrokerBillingList';
import IssuesLogFeatureList from 'modules/tools/pages/BrokerIssuesLogList';
import Homepage from 'modules/issueslog/pages/Home/Homepage';
import BillingHomePage from 'modules/billing/pages/BillingHomePage';
import EmployerClaimsList from 'modules/tools/pages/EmployerClaimsList';
import EmployerBillingList from 'modules/tools/pages/EmployerBillingList';
import ViewIssue from 'modules/issueslog/pages/ViewIssue/ViewIssue';
import AccountTeamProvision from 'modules/tools/pages/AccountTeamProvision';
import SupportTeamProvision from 'modules/tools/pages/SupportTeamProvision';
import BrokerIdCardsList from 'modules/tools/pages/BrokerIdCardsList';
import PlanRates from 'modules/plans/planRates/pages/PlanRates/PlanRates';
import EmployerRenewalList from 'modules/tools/pages/EmployerRenewalList';
import BrokerRenewalList from 'modules/tools/pages/BrokerRenewalList';
import CarrierOffers from 'modules/renewals/pages/offers/CarierOffers/CarrierOffers';
import NewProposalContainer from 'modules/renewals/pages/proposals/NewProposalContainer/NewProposalContainer';
import OfferCardList from 'modules/renewals/components/OfferCardList/OfferCardList';
import PlanDetails from 'modules/renewals/pages/offers/PlanDetails/PlanDetails';
import ProposalsBenefits from 'modules/renewals/pages/proposals/ProposalsBenefits/ProposalsBenefits';
import OpenOffers from 'modules/renewals/pages/offers/OpenOffers/OpenOffers';
import ProposalsOfferDetails from 'modules/renewals/pages/proposals/ProposalsOfferDetails/ProposalsOfferDetails';
import IdCardHome from 'modules/idCards/pages/IdCardHome/IdCardHome';
import EmployeeDetails from 'modules/idCards/pages/EmployerDetails/EmployeeDetails';
import BrokerAdminSsoConfigPage from 'modules/sso/pages/BrokerAdminSsoConfigPage/BrokerAdminSsoConfigPage';
import TechAdminSsoConfigPage from 'modules/sso/pages/TechAdminSsoConfigPage/TechAdminSsoConfigPage';
import BrokerRfpList from 'modules/tools/pages/BrokerRfpList';
import EmployerRfpList from 'modules/tools/pages/EmployerRfpList';
import BrokerEmployerCarrierBoard from 'modules/carriers/pages/BrokerEmployerCarrierBoard/BrokerEmployerCarrierBoard';
import BrokerBenguideList from 'modules/tools/pages/BrokerBenguideList';
import EmployerBenguideList from 'modules/tools/pages/EmployerBenguideList';
import BrokerBenConsultantList from 'modules/tools/pages/BrokerBenConsultantList';
import EmployerBenConsultantList from 'modules/tools/pages/EmployerBenConsultantList';
import NoFeaturesView from 'modules/tools/pages/NoFeaturesView';
import BenefitsConsultation from 'modules/benefitsConsultation/pages/BenefitsConsultation/BenefitsConsultation';
import SelfReviewWrapper from 'modules/tools/pages/SelfReviewWrapper';
import AssistantLayout from 'modules/assistant/layout/AssistantLayout/AssistantLayout';
import EmployerIdCardsList from 'modules/tools/pages/EmployerIdCardsList';
import BrokerAdvancedProfileList from 'modules/tools/pages/BrokerAdvancedProfileList';
import EmployerAIAssistantWrapper from 'modules/tools/pages/EmployerAIAssistantWrapper';
import TechAIAssistantList from 'modules/tools/pages/TechAIAssistantList';
import ViewJson from 'modules/renewals/pages/offers/ViewJson/ViewJson';
import ViewPlanJson from 'modules/plans/pages/ViewPlanJson';

const routes = [
  {
    path: '/',
    element: <PlatformDashboard />,
    allowedLogins: [loginTypes.platform],
    title: 'PlanYear Admin Dashboard',
  },
  {
    path: '/dashboard',
    element: <PlatformDashboard />,
    allowedLogins: [loginTypes.platform],
    title: 'PlanYear Admin Dashboard',
  },
  {
    path: '/brokers/:brokerId',
    element: <BrokerDashboard />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    title: 'Broker Dashboard',
  },
  {
    path: '/brokers/:brokerId/basicInfo',
    element: <BrokerInfo />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    title: 'Broker Details',
  },
  {
    path: '/brokers/:brokerId/configuration/billing',
    element: <EmployerBillingList />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    validator: 'BILLING',
    title: 'Broker Configuration Billing',
  },
  {
    path: '/brokers/:brokerId/configuration/claims',
    element: <EmployerClaimsList />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    validator: 'CLAIMS',
    title: 'Broker Configuration Claims',
  },
  {
    path: '/brokers/:brokerId/rfp',
    element: <EmployerRfpList />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    validator: 'RFP',
    title: 'Broker RFP',
  },
  {
    path: '/brokers/:brokerId/configuration',
    element: <NoFeaturesView />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    title: 'Broker Configuration',
  },
  {
    path: '/configuration',
    element: <NoFeaturesView />,
    allowedLogins: [loginTypes.platform],
    title: 'PlanYear Configuration',
  },
  {
    path: '/configuration/rfp',
    element: <BrokerRfpList />,
    allowedLogins: [loginTypes.platform],
    validator: 'RFP',
    title: 'PlanYear Configuration RFP',
  },
  {
    path: '/configuration/benefits-consultation',
    element: <BrokerBenConsultantList />,
    allowedLogins: [loginTypes.platform],
    validator: 'BENEFIT_CONSULTATION',
    title: 'PlanYear Configuration Benefits Consultation',
  },
  {
    path: '/configuration/benefit-guide',
    element: <BrokerBenguideList />,
    allowedLogins: [loginTypes.platform],
    validator: 'BENEFIT_GUIDE',
    title: 'PlanYear Configuration Benefit Guide',
  },
  {
    path: '/brokers/:brokerId/configuration/benefits-consultation',
    element: <EmployerBenConsultantList />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    validator: 'BENEFIT_CONSULTATION',
    title: 'Broker Configuration Benefits Consultation',
  },
  {
    path: '/brokers/:brokerId/benefits-consultation',
    element: <BenefitsConsultation />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    validator: 'BENEFIT_CONSULTATION',
    title: 'Broker Benefits Consultation',
  },
  {
    path: '/brokers/:brokerId/configuration/benefit-guide',
    element: <EmployerBenguideList />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    validator: 'BENEFIT_GUIDE',
    title: 'Broker Configuration Benefit Guide',
  },
  {
    path: '/brokers/:brokerId/carriers',
    element: <BrokerEmployerCarrierBoard />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    title: 'Broker Carriers',
  },
  {
    // TODO: I dont think this route is used anywhere. Please check and remove.
    path: '/brokers/:brokerId/rfp',
    element: <EmployerRfpList />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
  },
  {
    path: '/brokers/:brokerId/configuration/rfp',
    element: <EmployerRfpList />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    validator: 'RFP',
    title: 'Broker Configuration RFP',
  },
  {
    path: '/brokers/:brokerId/carriers/:carrierId/info',
    element: <CarrierInfo />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Broker Carriers Info',
  },
  {
    path: '/brokers/:brokerId/carriers/:carrierId/:benefitCategory/:id',
    element: <CarrierInfo />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Broker Carriers Category Info',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId',
    element: <ERDashboard />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Dashboard',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/benefit-guides',
    element: <BenefitGuideHome />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    validator: 'BENEFIT_GUIDE',
    title: 'Employer Benefit Guides',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/info',
    element: <EmployerInfo />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/carriers',
    element: <CarrierList />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Carriers',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/carriers/:carrierId/info',
    element: <CarrierInfo />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Carriers Info',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/carriers/:carrierId/:benefitCategory/:id',
    element: <CarrierInfo />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Carriers Category Info',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/medical',
    element: <PlanIntro />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Medical',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/medical/:planId/overview',
    element: <MedicalOverviewPage />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Medical Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/dental',
    element: <DentalPlanList />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Dental',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/dental/:planId/overview',
    element: <DentalOverviewPage />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Dental Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/vision',
    element: <VisionPlansList />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Vision',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/vision/:planId/overview',
    element: <VisionOverviewPage />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Vision Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/telehealth-rx',
    element: <TelehealthRxPlanList />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Telehealth Rx',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/telehealth-rx/:planId/overview',
    element: <TelehealthRxPlanOverview />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Telehealth Rx Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/life',
    element: <LifePlansList />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Life',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/life/:planId/overview',
    element: <LifePlanPlanOverview />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Life Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/voluntary',
    element: <VoluntaryBenefitList />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Voluntary Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/wellbeing',
    element: <WellbeingPlanList />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Wellbeing',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/wellbeing/:planId/overview',
    element: <WorkLifePlanOverview type="WELLBEING" />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Wellbeing Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/401k',
    element: <FourOOneKPlanList />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan 401k',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/401k/:planId/overview',
    element: <FourOOneKPlanOverview />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan 401k Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/tax-advantaged-accts',
    element: <TaxAdvantagedPlanList />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Tax Advantaged Accounts',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/tax-advantaged-accts/:planId/overview',
    element: <TaxAdvantagedPlanOverview />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Tax Advantaged Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/holiday-time-off',
    element: <HolidaysTimeOffPlanList />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Holiday Time Off',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/holiday-time-off/:planId/overview',
    element: <WorkLifePlanOverview type="FAMILY_HOLIDAY_TIME_OFF" />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Holiday Time Off Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/family-and-leave',
    element: <FamilyAndLeavePlanList />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Family And Leave',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/family-and-leave/:planId/overview',
    element: <WorkLifePlanOverview type="FAMILY_HOLIDAY_TIME_OFF" />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Family And Leave Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/additional-perks',
    element: <AdditionalPerkPlanList />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Additional Perks',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/additional-perks/:planId/overview',
    element: <WorkLifePlanOverview type="ADDITIONAL_PERKS" />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Additional Perks Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/resouces',
    element: <div>Resources</div>,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Resources',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/broker-consultation',
    element: <BrokerConsultation />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin, // can they access?
    ],
    validator: 'BENEFIT_CONSULTATION',
    title: 'Employer Broker Consultation',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/employer-consultation',
    element: <EmployerConsultation />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    validator: 'BENEFIT_CONSULTATION',
    title: 'Employer Employer Consultation',
  },
  {
    path: '/brokers/:brokerId/employers',
    element: <EmployerList />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    title: 'Broker Employers',
  },
  {
    path: '/brokers',
    element: <BrokerList />,
    allowedLogins: [loginTypes.platform],
    title: 'PlanYear Brokers',
  },
  {
    path: '/employers',
    element: <EmployerList />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    title: 'PlanYear All Employers',
  },
  {
    path: '/renewals/open-offers',
    element: <OpenOffers />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    validator: 'RENEWAL',
    title: 'PlanYear All Renewal Offers',
  },
  {
    path: '/brokers/:brokerId/renewals/open-offers',
    element: <OpenOffers />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    validator: 'RENEWAL',
    title: 'Broker All Renewal Offers',
  },
  {
    path: '/carriers',
    element: <CarrierList />,
    allowedLogins: [loginTypes.platform],
    title: 'PlanYear Master Carriers',
  },
  {
    path: '/carriers/:carrierId/info',
    element: <CarrierInfo />,
    allowedLogins: [loginTypes.platform],
    title: 'PlanYear Master Carriers Info',
  },
  {
    path: '/carriers/:carrierId/:benefitCategory/:id',
    element: <CarrierInfo />,
    allowedLogins: [loginTypes.platform],
    title: 'PlanYear Master Carriers Category Info',
  },
  {
    path: '/configuration/issues-log',
    element: <IssuesLogFeatureList />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    title: 'PlanYear Configuration Account Log',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/issues-log/:type',
    element: <Homepage />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    validator: 'ISSUES_LOG',
    title: 'Employer Account Log',
  },
  {
    path: '/issues-log/list',
    element: <Homepage />,
    allowedLogins: [loginTypes.platform],
    title: 'PlanYear Account Log List',
  },
  {
    path: '/brokers/:brokerId/issues-log/:type',
    element: <Homepage />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    title: 'Broker Account Log',
  },
  {
    path: '/issues-log/:type',
    element: <Homepage />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    title: 'PlanYear Account Log',
  },
  {
    path: '/lumity-admins',
    element: <LumityAdminList />,
    allowedLogins: [loginTypes.platform],
    title: 'PlanYear Admins List',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/voluntary/:planId/overview',
    element: <VoluntaryOverViewPage />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Voluntary Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/plan-rates',
    element: <PlanRates />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Plan Rates',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/documents',
    element: <Documents />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    title: 'Employer Documents',
  },
  {
    path: '/configuration/billing',
    element: <BrokerBillingList />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    title: 'PlanYear Configuration Billing',
  },
  {
    path: '/configuration/claims',
    element: <BrokerClaimsList />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    title: 'PlanYear Configuration Claims',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/billing',
    element: <BillingHomePage />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    validator: 'BILLING',
    title: 'Employer Billing',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/claims',
    element: <ClaimsHomePage />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    validator: 'CLAIMS',
    title: 'Employer Claims',
  },
  // renewals
  {
    path: '/configuration/renewals',
    element: <BrokerRenewalList />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    title: 'PlanYear Configuration Renewals',
  },
  {
    path: '/configuration/rfp',
    element: <BrokerRfpList />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    title: 'PlanYear Configuration RFP',
  },
  {
    path: '/brokers/:brokerId/configuration/renewals',
    element: <EmployerRenewalList />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    validator: 'RENEWAL',
    title: 'Broker Configuration Renewals',
  },
  {
    path: '/configuration/id-cards',
    element: <BrokerIdCardsList />,
    allowedLogins: [loginTypes.platform],
    title: 'PlanYear Configuration ID Cards',
  },
  {
    path: '/configuration/ai-assistant',
    element: <TechAIAssistantList />,
    allowedLogins: [loginTypes.platform],
    title: 'PlanYear Configuration AI Assistant',
  },
  {
    path: '/brokers/:brokerId/configuration/ai-assistant',
    element: <EmployerAIAssistantWrapper />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    validator: 'AI_ASSISTANT',
  },
  {
    path: '/brokers/:brokerId/configuration/id-cards',
    element: <EmployerIdCardsList />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    validator: 'ID_CARDS',
    title: 'Broker Configuration ID Cards',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/id-cards',
    element: <IdCardHome />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    validator: 'ID_CARDS',
    title: 'Employer ID Cards',
  },
  {
    path: '/brokers/:brokerId/configuration/sso',
    element: <BrokerAdminSsoConfigPage />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    validator: 'SSO',
    title: 'Broker Configuration SSO',
  },
  {
    path: '/configuration/sso',
    element: <TechAdminSsoConfigPage />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    validator: 'SSO',
    title: 'PlanYear Configuration SSO',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/id-cards/:employeeId',
    element: <EmployeeDetails />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    validator: 'ID_CARDS',
    title: 'Employer ID Cards Employee Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/issues-log/:type/:issueId',
    element: <ViewIssue />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    validator: 'ISSUES_LOG',
    title: 'Employer Account Log Item Detail',
  },
  {
    path: '/brokers/:brokerId/issues-log/:type/:issueId',
    element: <ViewIssue />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    validator: 'ISSUES_LOG',
    title: 'Broker Account Log Item Detail',
  },
  {
    path: 'issues-log/:type/:issueId',
    element: <ViewIssue />,
    allowedLogins: [loginTypes.platform],
    validator: 'ISSUES_LOG',
    title: 'PlanYear Account Log Item Detail',
  },
  {
    path: '/brokers/:brokerId/configuration/issues-log-provisioning/account',
    element: <AccountTeamProvision />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    validator: 'ISSUES_LOG',
    title: 'Broker Configuration Account Log Provisioning Account',
  },
  {
    path: '/brokers/:brokerId/configuration/issues-log-provisioning/support',
    element: <SupportTeamProvision />,
    allowedLogins: [loginTypes.platform, loginTypes.bokerAdmin],
    validator: 'ISSUES_LOG',
    title: 'Broker Configuration Account Log Provisioning Support',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/renewals/:renewalsType/:benefitKind',
    element: <OfferCardList />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    validator: 'RENEWAL',
    title: 'Employer Renewals',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/renewals/:renewalsType',
    element: <> </>,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    validator: 'RENEWAL',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/renewals/carrier/summary',
    element: <CarrierOffers />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    validator: 'RENEWAL',
    title: 'Employer Renewals Carrier Offers',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/renewals/carrier/:benefitKind/:offerId/detail',
    element: <PlanDetails />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    validator: 'RENEWAL',
    title: 'Employer Renewals Carrier Offer Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/renewals/proposals',
    element: <NewProposalContainer />,
    allowedLogins: [
      loginTypes.platform,
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
    ],
    validator: 'RENEWAL',
    title: 'Employer Renewals Proposals',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/renewals/proposals/details/:proposalId',
    element: <ProposalsBenefits />,
    allowedLogins: [
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
      loginTypes.platform,
    ],
    validator: 'RENEWAL',
    title: 'Employer Renewals Proposals Details',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/renewals/proposals/details/:proposalId/offer-details/:renewalsType',
    element: <ProposalsOfferDetails />,
    allowedLogins: [
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
      loginTypes.platform,
    ],
    validator: 'RENEWAL',
    title: 'Employer Renewals Proposals Offer Details',
  },
  {
    path: 'configuration/self-review',
    element: <SelfReviewWrapper />,
    allowedLogins: [loginTypes.platform],
    validator: 'SELF_REVIEW',
    title: 'PlanYear Configuration Self Review',
  },
  {
    path: '/brokers/:brokerId/assistant/:chatId',
    element: <AssistantLayout />,
    allowedLogins: [
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
      loginTypes.platform,
    ],
    validator: 'AI_ASSISTANT_CHAT',
    title: 'Broker AI Assistant',
  },
  {
    path: 'configuration/advanced-profile',
    element: <BrokerAdvancedProfileList />,
    allowedLogins: [loginTypes.platform],
    validator: 'ADVANCED_PROFILE',
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/renewals/carrier/:benefitKind/jsonerror',
    element: <ViewJson />,
    allowedLogins: [
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
      loginTypes.platform,
    ],
  },
  {
    path: '/brokers/:brokerId/employers/:employerId/:benefitKind/jsonerror',
    element: <ViewPlanJson />,
    allowedLogins: [
      loginTypes.bokerAdmin,
      loginTypes.erAdmin,
      loginTypes.platform,
    ],
  },
];

export default routes;
