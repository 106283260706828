import { lumityApi } from 'api/apiSlice';
import { AiUploaderFileType } from 'modules/plans/types/types';

const BASE_AI_URL = 'v1/document-extraction';

const documentExtractionSlice = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getAiExtractionHistory: builder.query<
      Record<'id' | 'documentName' | 'status' | 'startedTime', string>[],
      {
        benefit: string;
        employerId: string;
        brokerId: string;
        fileType: AiUploaderFileType;
      }
    >({
      query: ({ benefit, brokerId, employerId, fileType }) => {
        const benefitSegment = benefit.toLowerCase();
        const fileSegment = fileType.toLowerCase().replaceAll('_', '-');

        return {
          url: `${BASE_AI_URL}/${benefitSegment}/${fileSegment}`,
          method: 'GET',
          params: {
            'employer-id': employerId,
            'organization-id': brokerId,
            'benefit-kind': benefit,
          },
        };
      },
    }),
    getDocumentExtractionById: builder.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `v1/document-extraction/${id}`,
        method: 'GET',
      }),
    }),
    getDocumentExtractionDetailsById: builder.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `v1/document-extraction/${id}/details`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetAiExtractionHistoryQuery,
  useGetDocumentExtractionByIdQuery,
  useGetDocumentExtractionDetailsByIdQuery,
  useLazyGetDocumentExtractionByIdQuery,
} = documentExtractionSlice;
