import { FC } from 'react';
import get from 'lodash/get';

import { BenefitKind } from 'constants/commonConstants';
import EditPlanModal from 'modules/plans/components/EditPlanModal/EditPlanModal';
import AddTaxAdvantagedPlan from 'modules/plans/taxAdvantaged/components/AddTaxAdvantagedPlan/AddTaxAdvantagedPlan';
import { TaxAdvantagedAccountPlan } from 'model/plans/TaxAdvantagedAccountPlan';
import { useAppDispatch } from 'hooks/redux';
import { clearDocuments } from 'modules/plans/slices/taxAdvantagedAccountPlanSlice';

type EditTaxAdvantagedPlanModalProps = {
  plan: TaxAdvantagedAccountPlan;
  onClose: () => void;
  isModalVisible: boolean;
  onSave: () => void;
  isDBGPlan?: boolean;
  isSaveDisabled?: boolean;
};

const EditTaxAdvantagedPlanModal: FC<EditTaxAdvantagedPlanModalProps> = ({
  plan,
  onClose,
  isModalVisible,
  onSave,
  isDBGPlan,
  isSaveDisabled,
}: EditTaxAdvantagedPlanModalProps) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(clearDocuments());
    onClose();
  };

  const handleSave = () => {
    dispatch(clearDocuments());
    onSave();
  };

  return (
    <EditPlanModal
      visible={isModalVisible}
      onCancel={onClose}
      title={`Edit ${get(BenefitKind, plan?.benefitKind || '', '').label} Plan`}
      isSaveDisabled={isSaveDisabled}
    >
      <AddTaxAdvantagedPlan
        plan={plan}
        closePlanAddModal={handleClose}
        onReset={handleClose}
        onSaveClose={handleSave}
        isCancelButtonClickConfirm={!isModalVisible}
        isPlanUpdateMode
        planType={plan.benefitKind}
        isDBGPlan={isDBGPlan}
        isSaveDisabled={isSaveDisabled}
        isModalVisible={isModalVisible}
      />
    </EditPlanModal>
  );
};

export default EditTaxAdvantagedPlanModal;
