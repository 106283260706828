import { useEffect, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { Select } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import cloneDeep from 'lodash/cloneDeep';
import calenderIcon from 'assets/images/icon-calendar.svg';

import styles from './yearSelector.module.less';

const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

const { Option } = Select;

type YearSelector = {
  benefitKind: string | undefined;
  preview: boolean | undefined;
  onSelectYear?: (year: string) => void;
  dateRange?: Dayjs[];
  yearListAr: [];
};

const YearSelector = (props: YearSelector) => {
  const { onSelectYear, dateRange = [], yearListAr } = props;
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [yearList, setYearList] = useState<string[]>([]);

  const getYearMonth = (date: any) => {
    const dateSplit = date.split('-');
    if (dateSplit.length < 2) return '';
    return dateSplit[1] + '-' + dateSplit[0];
  };

  useEffect(() => {
    if (yearListAr && yearListAr.length > 0) {
      const sortedYearListAr = cloneDeep(yearListAr);
      const sortedYearList: string[] = sortedYearListAr.sort(
        (a: any, b: any) => {
          const aSplit = a.split(' - ');
          const bSplit = b.split(' - ');
          const aYearMonthStart = getYearMonth(aSplit[0]);
          const bYearMonthStart = getYearMonth(bSplit[0]);
          if (aYearMonthStart > bYearMonthStart) return -1;
          if (aYearMonthStart < bYearMonthStart) return 1;
          const aYearMonthEnd = getYearMonth(aSplit[1]);
          const bYearMonthEnd = getYearMonth(bSplit[1]);
          if (aYearMonthEnd > bYearMonthEnd) return -1;
          if (aYearMonthEnd < bYearMonthEnd) return 1;
          return 0; // Equal dates
        }
      );
      setYearList(sortedYearList);
      if (sortedYearList && sortedYearList.length > 0) {
        setSelectedValue(sortedYearList[0]);
        onSelectYear && onSelectYear(sortedYearList[0]);
      } else {
        setSelectedValue('');
        onSelectYear && onSelectYear('');
      }
    }
    // Need to call first time only
    // eslint-disable-next-line
  }, [yearListAr, dateRange]);

  const getYearLabel = (year: string) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const yearParts = year.split('-');
    if (yearParts.length === 4) {
      return `${months[parseInt(yearParts[0]) - 1]} ${yearParts[1]} - ${
        months[parseInt(yearParts[2]) - 1]
      } ${yearParts[3]}`;
    }
    return '';
  };

  return (
    <div className={styles.yearSelector}>
      <span className={styles.label}>Viewing:</span>
      <img src={calenderIcon} alt="" className={styles.calenderIcon} />
      <Select
        suffixIcon={<CaretDownFilled className={styles.caretDownFilled} />}
        value={selectedValue}
        onChange={(year: string) => {
          setSelectedValue(year);
          onSelectYear && onSelectYear(year);
        }}
        className={styles.selector}
      >
        {yearList.map((year: string, index: number) => (
          <Option key={index} value={year}>
            {getYearLabel(year)}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default YearSelector;
