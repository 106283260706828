import React from 'react';
import { isEmpty } from 'lodash';
import styles from './disableFeatureMessage.module.less';

type Props = {
  locations: string[];
  featureKey: string;
};

const DisableFeatureMessage = (props: Props) => {
  const { locations, featureKey } = props;

  const renderLocations = () => {
    return locations.map((location, index) => (
      <div className={styles.boldLocationName} key={index}>
        {location}
        {locations.length !== index + 1 && ', '}
      </div>
    ));
  };

  return (
    <div>
      {!isEmpty(locations) ? (
        <>
          {`Are you sure you want to disable ${featureKey} for `}
          {renderLocations()}
          {`${
            locations.length > 1 ? ' locations' : ' location'
          }? These locations will lose access to this tool immediately.`}
        </>
      ) : (
        <>
          {`Are you sure you want to disable ${featureKey}? This broker and all associated locations will lose access to this tool immediately.`}
        </>
      )}
    </div>
  );
};

export default DisableFeatureMessage;
