import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row, Spin, Table } from 'antd';
import { has, isEmpty, isNull } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { ReactComponent as DropDownDarkArrow } from 'assets/images/dropdown-down-arrow.svg';
import { ReactComponent as ExpandedArrowIcon } from 'assets/images/icon-expanded-light.svg';
import { ReactComponent as NoRenewalsAlertIcon } from 'assets/images/no-renewals-alert-icon.svg';
import { ReactComponent as PencilIcon } from 'assets/images/icon-pencil.svg';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import RenewalCommonAlert from 'modules/renewals/components/renewalCommonAlert/RenewalCommonAlert';
import { ReactComponent as TimerRefreshIcon } from 'assets/images/icon-timer-refresh.svg';
import {
  ALL_DROPDOWN_ITEM,
  EXPORT_PROPOSAL_BENEFIT_TYPE_DROPDOWN_OPTIONS,
  NO_OFFERS,
  NO_OFFERS_ER_ADMIN,
  NO_RENEWAL_DATA,
  NO_RENEWAL_DATA_DESCRIPTION,
  NO_RENEWAL_PRIMARY_BTN_TXT,
  OFFER_BENEFIT_SUMMARY_TYPES,
  OFFER_BENEFIT_TYPE_NAVIGATION_LABELS,
  OFFER_TAG_RENEWAL,
  UPCOMING_DROPDOWN_ITEM_CARRIER,
  UPCOMING_DROPDOWN_PROPOSAL,
} from 'modules/renewals/constants/renewalsConstants';
import { DataColumn } from 'components/DataTable/DataColumn';
import RenewalCommonMainHeader from 'modules/renewals/components/helperComponents/RenewalCommonMainHeader/RenewalCommonMainHeader';
import RenewalCommonSubHeader from 'modules/renewals/components/helperComponents/RenewalCommonSubHeader/RenewalCommonSubHeader';
import OfferStatusTag from 'components/OfferStatusTag/OfferStatusTag';
import RecommendedTooltip from 'modules/renewals/components/helperComponents/RecommendedToolTip/RecommendedTooltip';
import PeriodTooltip from 'modules/renewals/components/helperComponents/PeriodTooltip/PeriodTooltip';
import FromCurrentPercentage from 'modules/renewals/components/helperComponents/FromCurrentPercentage/FromCurrentPercentage';
import { ReactComponent as CalendarIcon } from 'assets/images/icon-calendar.svg';
import {
  formatHeaderValues,
  getBenefitInfo,
  isDraftOffer,
  isDtqOrPending,
} from 'modules/renewals/utils/renewalsUtils';
import CustomerSpinner from 'components/Spinner/CustomSpinner';
import UpcomingPlanYearBasicDto from 'model/UpcomingPlanYearBasicDto';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setIsFromOfferNavigatePY } from 'modules/renewals/slices/offerReducerSlice';
import {
  useLazyGetAfpPlanYearsQuery,
  useLazyGetCarrierOfferSummaryQuery,
  useLazyGetOffersExportFiltersQuery,
  useLazyGetProposalsQuery,
  useLazyGetUpcomingPlanYearsByEmployerQuery,
  useLazyValidatePlanYearQuery,
  useModifyCarrierOfferDueDateMutation,
} from 'modules/renewals/slices/renewalsSlice';
import { dateFormat } from 'constants/dateFormat';
import DatePickerInput from 'components/DatePickerInput/DatePickerInput';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import ExportOffers from 'modules/renewals/components/helperComponents/ExportOffers/ExportOffers';
import { loginTypes } from 'constants/authConstants';
import InputForm from 'components/InputForm/InputForm';
import { currencyFormatterWithoutZeros } from 'util/commonUtil';
import StartNewRenewalModal from 'modules/renewals/components/StartNewRenewalModal/StartNewRenewalModal';
import ExportOfferProposalModal from 'modules/renewals/components/ExportOfferProposalModal/ExportOfferProposalModal';
import {
  PlanYearDropDownType,
  SoldConfirmationDropDownType,
} from 'modules/renewals/types/renewalsTypes';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';

import { FundingTypeTag } from 'components/StdFundingTypeTag/FundingTypeTag';
import {
  BenefitCategory,
  FEATURE_KEYS,
  IndividualSubTypes,
} from 'constants/commonConstants';
import EmployerFeatureDisabledBanner from 'modules/tools/components/EmployerFeatureDisabledBanner/EmployerFeatureDisabledBanner';
import {
  AfpBanner,
  PlanYearDetail,
} from 'modules/renewals/components/AfpBanner/AfpBanner';
import ProposalListItemVO from 'model/ProposalListItemVO';
import { useLazyGetIsSoldConfirmationCompleteQuery } from 'modules/renewals/slices/soldConfirmationSlice';
import {
  useLazyGetProposalSummaryQuery,
  useProposalStatusUpdateMutation,
} from 'modules/renewals/slices/proposalSlice';
import EmployerSoldConfirmationModal from 'modules/renewals/components/soldConfirmation/EmployerSoldConfirmationModal/EmployerSoldConfirmationModal';
import SoldConfirmationModal from 'modules/renewals/components/soldConfirmation/SoldConfirmationModal/SoldConfirmationModal';
import AddProposalToPlanYearModal from 'modules/renewals/components/AppProposalToPlanYearComponents/AddProposalToPlanYearModal/AddProposalToPlanYearModal';
import styles from 'modules/renewals/pages/offers/CarierOffers/carrierOffer.module.less';

const CarrierOffers = () => {
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;
  const isEmpMember =
    appBootInfo?.individualSubType === IndividualSubTypes.EMPLOYER_MEMBER;
  const params = useParams();
  const [dueDateForm] = Form.useForm();
  const [planYears, setPlanYears] = useState<PlanYearDropDownType[]>([]);
  const [planYearValue, setPlanYearValue] = useState<string | null>(
    UPCOMING_DROPDOWN_PROPOSAL.value
  );
  const [effectiveDates, setEffectiveDates] = useState<string[]>([]);
  const [selectedEffectiveDate, setSelectedEffectiveDate] = useState<any>(0);
  const [expanded, setExpanded] = useState<any>({});
  const [isNoDataExist, setIsNoDataExist] = useState<boolean>(false);
  const [isNoPlanYearDataExist, setIsNoPlanYearDataExist] =
    useState<boolean>(false);
  /** flag to toggle full screen modal for start new renewal date */
  const [isCreateNewRenewalModalOpen, setIsCreateNewRenewalModalOpen] =
    useState<boolean>(false);

  const [isExportOffersOpen, setIsExportOffersOpen] = useState<boolean>(false);
  const [upcomingPlanYearId, setUpcomingPlanYearId] = useState<string>('');
  const [isDueDataModalVisible, setIsDueDataModalVisible] =
    useState<boolean>(false);
  const [isOfferExportVisible, setIsOfferExportVisible] =
    useState<boolean>(false);
  const [benefitTypes, setBenefitTypes] = useState<any[]>([]);
  const [selectedBenefitType, setSelectedBenefitType] = useState<string[]>([]);

  const [offerNameOptions, setOfferNameOptions] = useState<string[]>([]);
  const [selectedOfferNames, setSelectedOfferNames] = useState<string[]>([]);

  const selectedBenefitTypeRef = useRef<string[]>(selectedBenefitType);
  const selectedOfferNamesRef = useRef<string[]>(selectedOfferNames);
  const [showExportOffersPopover, setShowExportOffersPopover] =
    useState<boolean>(false);

  const [isProposalAddToPlanYearVisible, setIsProposalAddToPlanYearVisible] =
    useState<boolean>(false);
  const [finalApprovedProposalName, setFinalApprovedProposalName] =
    useState<string>('');
  const [isFinalApprovedCompleted, setIsFinalApprovedCompleted] =
    useState<boolean>(false);
  const [
    isAddProposalToPlanYearModalOpen,
    setIsAddProposalToPlanYearModalOpen,
  ] = useState<boolean>(false);

  const [
    isEmployerSoldConfirmationModalOpen,
    setIsEmployerSoldConfirmationModalOpen,
  ] = useState<boolean>(false);

  const [effectiveDateValue, setEffectiveDateValue] = useState<string>(
    ALL_DROPDOWN_ITEM.value
  );

  const [overlapPlanYear, setOverlapPlanYear] = useState<PlanYearDetail>();
  const [finalApprovedProposalId, setFinalApprovedProposalId] =
    useState<string>();
  const [showSolidConfirmation, setShowSolidConfirmation] = useState(false);
  const [isAlreadyFinalApproved, setIsAlreadyFinalApproved] =
    useState<boolean>(false);
  const [effectiveDateRange, setEffectiveDateRange] = useState<string>('');

  const [getIsSoldConfirmationComplete, { data: soldConfirmCompleteData }] =
    useLazyGetIsSoldConfirmationCompleteQuery();

  const [getProposalSummary, { data: finalApprovedProposalSummary }] =
    useLazyGetProposalSummaryQuery();

  const [isEmployerSoldConfirmationSent, setIsEmployerSoldConfirmationSent] =
    useState<boolean>(false);
  const [isInitial, setIsInitial] = useState<boolean>(true);

  const [
    getProposals,
    { isSuccess: isGetProposalsSuccess, currentData: proposalsRawData },
  ] = useLazyGetProposalsQuery();

  const [
    validatePlanYear,
    { error: validationError, isLoading: isValidationPlanYearLoading },
  ] = useLazyValidatePlanYearQuery();

  const [
    updateCarrierOfferDate,
    {
      isSuccess: updateCarrierOfferDueDateSuccess,
      isLoading: updateCarrierOfferDueDateLoading,
    },
  ] = useModifyCarrierOfferDueDateMutation();

  const { employerId = '' } = useParams();
  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // As StartNewRenewalModal was completely inside CarrierOffers (this component) previously.
  // This useEffect was meant to open the modal if the Start New Renewal button is clicked
  // on the proposals side.
  // TODO: We should instead just put the newly separated component into the NewProposalContainer page
  useEffect(() => {
    if (location?.state?.isFromProposalRenewalDate) {
      setIsCreateNewRenewalModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.isFromProposalRenewalDate]);

  const [
    getUpcomingPlanYears,
    {
      isFetching: isUpcomingFetching,
      isSuccess: isUpcomingFetchSuccess,
      isLoading: isUpcomingFetchLoading,
      isError: isUpcomingFetchError,
      data: upcomingData,
    },
  ] = useLazyGetUpcomingPlanYearsByEmployerQuery();

  const [
    getAfpPlanYears,
    { isSuccess: isPlanYearsSuccess, data: planYearsData },
  ] = useLazyGetAfpPlanYearsQuery();

  const [
    getCarrierOfferSummary,
    {
      isSuccess: isCarrierSummaryFetchSuccess,
      data: carrierSummaryData,
      isFetching: isOffersDataFetching,
    },
  ] = useLazyGetCarrierOfferSummaryQuery();

  const [
    getOffersExportFilters,
    {
      isSuccess: isFilterOptionsFetchSuccess,
      isFetching: isFilterOptionsFetching,
      data: filterOptionsData,
    },
  ] = useLazyGetOffersExportFiltersQuery();
  const [publishProposal, { isSuccess: isPublishProposalSuccess, reset }] =
    useProposalStatusUpdateMutation();

  const effectiveDateIdParameter =
    effectiveDateValue === ALL_DROPDOWN_ITEM.value
      ? undefined
      : effectiveDateValue;

  const loadList = useCallback(() => {
    if (employerId) {
      getProposals({
        employerId,
        effectiveDateId: effectiveDateIdParameter,
        planYearId: planYearValue,
      });
      setIsInitial(false);
    }
  }, [effectiveDateIdParameter, employerId, getProposals, planYearValue]);

  const callGetCarrierSummaryApi = useCallback(async () => {
    const effectiveDateStrings = (
      effectiveDates[selectedEffectiveDate] as any
    )?.label.split(' - ');
    let fromDate = null;
    let toDate = null;
    if (effectiveDateStrings?.length === 2) {
      fromDate = dayjs(effectiveDateStrings[0]).format('YYYY-MM-DD');
      toDate = dayjs(effectiveDateStrings[1]).format('YYYY-MM-DD');
    }

    getCarrierOfferSummary({
      employerId: String(employerId),
      allDates: effectiveDateStrings[0] === 'All',
      fromDate: fromDate,
      toDate: toDate,
      planYearId: planYearValue,
    });
    loadList();

    // for Prevent infinite loop - loadList
    // eslint-disable-next-line
  }, [
    effectiveDates,
    selectedEffectiveDate,
    employerId,
    getCarrierOfferSummary,
    planYearValue,
  ]);

  const getIsProposalsApplicableToFinalise = (
    proposalList: ProposalListItemVO[]
  ) => {
    return (
      proposalList?.find((proposal) => proposal.status === 'APPROVED') !==
      undefined
    );
  };

  useEffect(() => {
    if (isPublishProposalSuccess) {
      loadList();
      reset();
    }
    // eslint-disable-next-line
  }, [isPublishProposalSuccess]);

  useEffect(() => {
    if (isFinalApprovedCompleted) {
      setIsEmployerSoldConfirmationModalOpen(false);
      loadList();
    }
    // eslint-disable-next-line
  }, [isFinalApprovedCompleted]);

  useEffect(() => {
    if (finalApprovedProposalId) {
      getIsSoldConfirmationComplete({ proposalId: finalApprovedProposalId });
    }
    // eslint-disable-next-line
  }, [finalApprovedProposalId]);

  useEffect(() => {
    if (!isInitial) {
      getProposals({
        employerId: employerId ?? '',
        effectiveDateId: effectiveDateIdParameter,
        planYearId: planYearValue,
      });
    }
    // eslint-disable-next-line
  }, [isAddProposalToPlanYearModalOpen]);

  useEffect(() => {
    if (proposalsRawData) {
      setIsProposalAddToPlanYearVisible(
        getIsProposalsApplicableToFinalise(proposalsRawData)
      );
      if (getIsProposalsApplicableToFinalise(proposalsRawData)) {
        const finalApprovedProposal: ProposalListItemVO | undefined =
          proposalsRawData?.find((proposal) => proposal.status === 'APPROVED');
        validatePlanYear({
          employerId: String(employerId),
          upcomingPlanYearId: String(finalApprovedProposal?.effectiveDateId),
        });
      }
      proposalsRawData.map((proposal) => {
        const { id, tags, name } = proposal;
        tags.forEach((tag: string) => {
          if (tag === 'APPROVED') {
            setFinalApprovedProposalId(id);
            setFinalApprovedProposalName(name);
          }
        });
      }, []);
    }
    // Prevent infinite loop - validationError
    // eslint-disable-next-line
  }, [
    employerId,
    proposalsRawData,
    getProposals,
    validatePlanYear,
    isGetProposalsSuccess,
  ]);

  useEffect(() => {
    if (validationError) {
      const error = validationError as any;
      setOverlapPlanYear(error?.data?.details?.detail);
    } else {
      setOverlapPlanYear(undefined);
    }
  }, [validationError]);

  useEffect(() => {
    if (finalApprovedProposalId) {
      getProposalSummary({
        proposalId: String(finalApprovedProposalId),
        employerId: String(employerId),
      });
    }
    // eslint-disable-next-line
  }, [finalApprovedProposalId, employerId]);

  /** Runs on page load / anytime employerId changes */
  useEffect(() => {
    getAfpPlanYears({ employerId });
    getUpcomingPlanYears({ employerId });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerId]);

  useEffect(() => {
    if (
      effectiveDates &&
      !isNaN(selectedEffectiveDate) &&
      effectiveDates[selectedEffectiveDate]
    ) {
      callGetCarrierSummaryApi();
    }
  }, [effectiveDates, selectedEffectiveDate, callGetCarrierSummaryApi]);

  useEffect(() => {
    setPlanYearsForDropDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcomingData, planYearsData]);

  useEffect(() => {
    if (isUpcomingFetchSuccess) {
      setIsNoDataExist(upcomingData?.upcomingPlanYears?.length === 0);
      setEffectiveDatesForDropDown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpcomingFetchSuccess, isUpcomingFetchLoading, isUpcomingFetching]);

  const setPlanYearsForDropDown = () => {
    if (
      planYearsData &&
      !isEmpty(planYearsData) &&
      upcomingData?.upcomingPlanYears
    ) {
      const planYearsList: any[] = planYearsData.map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }));

      const isRenewalStarted = upcomingData?.upcomingPlanYears.some(
        (obj: any) => obj.planYearId === null
      );

      if (isRenewalStarted) {
        planYearsList.unshift(UPCOMING_DROPDOWN_ITEM_CARRIER);
        setPlanYearValue(null);
      } else {
        setPlanYearValue(planYearsList[0]?.value);
      }

      setPlanYears(planYearsList);
      setIsNoPlanYearDataExist(false);
    } else {
      setIsNoPlanYearDataExist(true);
      setPlanYears([UPCOMING_DROPDOWN_ITEM_CARRIER]);
      setPlanYearValue(null);
    }
  };

  useEffect(() => {
    if (selectedEffectiveDate == 0) {
      setUpcomingPlanYearId('all');
    } else if (selectedEffectiveDate >= 1) {
      // filter upcoming data on selected plan year
      const filterOverPlanYear =
        upcomingData?.upcomingPlanYears?.filter(
          (obj: any) => obj?.planYearId === planYearValue
        ) || [];
      // set upcoming data based on the plan year filtered lis
      setUpcomingPlanYearId(
        filterOverPlanYear[selectedEffectiveDate - 1]?.id !== undefined
          ? filterOverPlanYear[selectedEffectiveDate - 1]?.id || ''
          : ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEffectiveDate, upcomingData]);

  useEffect(() => {
    setEffectiveDatesForDropDown();
    dueDateForm.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planYearValue]);

  useEffect(() => {
    if (isFilterOptionsFetchSuccess) {
      const benefitTypes = EXPORT_PROPOSAL_BENEFIT_TYPE_DROPDOWN_OPTIONS.filter(
        (option) => {
          return filterOptionsData?.categories?.includes(option?.value);
        }
      );
      setSelectedBenefitType(
        benefitTypes?.map((data) => {
          return data?.value;
        })
      );
      setBenefitTypes(benefitTypes);
      updateOfferAndCarrierFilters(benefitTypes);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterOptionsFetchSuccess, isFilterOptionsFetching]);

  useEffect(() => {
    selectedBenefitTypeRef.current = selectedBenefitType;
    selectedOfferNamesRef.current = selectedOfferNames;
  }, [selectedBenefitType, offerNameOptions, selectedOfferNames]);

  useEffect(() => {
    if (!benefitTypes || !isFilterOptionsFetchSuccess) {
      return;
    }

    updateOfferAndCarrierFilters(
      benefitTypes?.filter((type) => {
        return selectedBenefitType?.includes(type?.value);
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBenefitType]);

  const setEffectiveDatesForDropDown = () => {
    const mappedSet: any[] =
      upcomingData?.upcomingPlanYears
        ?.filter(
          (obj: UpcomingPlanYearBasicDto) => obj.planYearId === planYearValue
        )
        .map((obj: UpcomingPlanYearBasicDto, index: number) => {
          return {
            label: dayjs(obj.effectiveStartDate)
              .format('ll')
              .concat(' - ', dayjs(obj.effectiveEndDate).format('ll')),
            value: (index + 1).toString(),
          };
        }) || [];

    mappedSet.unshift({ label: 'All', value: 0 });
    // set default selected value
    if (mappedSet.length) {
      setSelectedEffectiveDate(mappedSet[0].value);
    }

    setEffectiveDates(mappedSet);
  };

  const handleDueDateSubmit = () => {
    dueDateForm.validateFields().then((res) => {
      updateCarrierOfferDate({
        employerId: String(employerId),
        dueDate: res.dueDate,
        planYearId: planYearValue,
      });
    });
  };

  useEffect(() => {
    if (updateCarrierOfferDueDateSuccess) {
      setIsDueDataModalVisible(false);
      callGetCarrierSummaryApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCarrierOfferDueDateLoading]);

  useEffect(() => {
    if (carrierSummaryData) {
      const initialExpandObj: any = {};
      Object?.keys(carrierSummaryData)
        ?.filter((key) => key != 'carrierOfferDueDate')
        ?.forEach((item) => {
          initialExpandObj[item] = carrierSummaryData[item]?.length !== 0;
        });

      setExpanded(initialExpandObj);
    }
  }, [selectedEffectiveDate, carrierSummaryData]);

  const hasNoOffers = () => {
    return ![
      carrierSummaryData?.medical.length,
      carrierSummaryData?.dental.length,
      carrierSummaryData?.vision.length,
      carrierSummaryData?.ltd.length,
      carrierSummaryData?.std.length,
      carrierSummaryData?.lifeAdd.length,
      carrierSummaryData?.voluntaryBenefits.length,
    ].some(Boolean);
  };

  // Function to generate body based on the backend call
  const generateBodyBasedOnUpcomingData = () => {
    if (!isUpcomingFetchError) {
      if (isUpcomingFetchLoading || isValidationPlanYearLoading) {
        return <CustomerSpinner />;
      } else {
        if (!isEmpty(upcomingData?.upcomingPlanYears) && carrierSummaryData) {
          return (
            <>
              <Table
                columns={summaryTableColumns}
                pagination={false}
                className={styles.headerTableWrapper}
              />
              {Object?.keys(carrierSummaryData)
                ?.filter((key) => key != 'carrierOfferDueDate')
                .map((item: string) => {
                  const tableData = carrierSummaryData;

                  return (
                    <Table
                      key={item}
                      columns={
                        expanded[item] ||
                        (!has(expanded, item) &&
                          (tableData[item]?.length ?? 0) !== 0)
                          ? summaryTableColumns
                          : collpasedTableColumns
                      }
                      dataSource={
                        expanded[item] ||
                        (!has(expanded, item) &&
                          (tableData[item]?.length ?? 0) !== 0)
                          ? tableData[item] ?? []
                          : getOfferCount(item, tableData[item]?.length ?? 0)
                      }
                      pagination={false}
                      showHeader={false}
                      className={styles.tabs}
                    />
                  );
                })}
              {upcomingData?.upcomingPlanYears?.length &&
                isErAdmin &&
                hasNoOffers() && (
                  <div className={styles.noUploadContent}>
                    <RenewalCommonAlert
                      subText={''}
                      mainText={NO_OFFERS_ER_ADMIN}
                      alertIcon={<NoRenewalsAlertIcon />}
                      isFloatingNoDataView={true}
                      isErAdmin={isErAdmin}
                    />
                  </div>
                )}
            </>
          );
        } else {
          return (
            <>
              <Table
                columns={summaryTableColumns}
                pagination={false}
                className={styles.headerTableWrapper}
              />
              {Object?.keys(emptyOffersTableData)?.map((item: string) => {
                const tableData: any = emptyOffersTableData;
                return (
                  <Table
                    key={item}
                    columns={
                      expanded[item] ||
                      (!has(expanded, item) &&
                        (tableData[item]?.length ?? 0) !== 0)
                        ? summaryTableColumns
                        : collpasedTableColumns
                    }
                    dataSource={
                      expanded[item] ||
                      (!has(expanded, item) &&
                        (tableData[item]?.length ?? 0) !== 0)
                        ? tableData[item] ?? []
                        : getOfferCount(item, tableData[item]?.length ?? 0)
                    }
                    pagination={false}
                    showHeader={false}
                    className={styles.tabs}
                  />
                );
              })}
              {!upcomingData?.upcomingPlanYears?.length && (
                <div className={styles.noUploadContent}>
                  <RenewalCommonAlert
                    subText={NO_RENEWAL_DATA_DESCRIPTION}
                    mainText={NO_RENEWAL_DATA}
                    mainBtnTxt={NO_RENEWAL_PRIMARY_BTN_TXT}
                    mainBtnHandler={() => setIsCreateNewRenewalModalOpen(true)}
                    alertIcon={<NoRenewalsAlertIcon />}
                    mainBtnStyling={styles.mainBtn}
                    isFloatingNoDataView={true}
                    isErAdmin={isErAdmin}
                  />
                </div>
              )}
            </>
          );
        }
      }
    } else {
      return <></>;
    }
  };

  const emptyOffersTableData = {
    medical: [],
    dental: [],
    vision: [],
    lifeAdd: [],
    std: [],
    ltd: [],
    voluntaryBenefits: [],
  };

  const data =
    upcomingData?.upcomingPlanYears?.length &&
    !isUpcomingFetchError &&
    isCarrierSummaryFetchSuccess
      ? carrierSummaryData
      : emptyOffersTableData;

  const getType = (type: string) => {
    return OFFER_BENEFIT_SUMMARY_TYPES?.[type] ?? type;
  };

  const onExpandIconClick = (type: string) => {
    setExpanded({
      ...expanded,
      [getType(type)]: !has(expanded, getType(type))
        ? false
        : !expanded[getType(type)],
    });
  };

  const getOfferCount = (type: string, count: number) => {
    return [{ type: type, count: count }];
  };

  const displayText = (text: string | number, isPlanCount?: boolean) => {
    if (isPlanCount) {
      text = `${text} ${text === 1 ? 'Plan' : 'Plans'}`;
    }

    return isEmpty(text) ? '-' : text;
  };

  const handleCarrierDueEditClick = () => {
    dueDateForm.setFieldsValue({
      dueDate: dayjs(carrierSummaryData?.carrierOfferDueDate),
    });
    setIsDueDataModalVisible(true);
  };

  const getOfferStatusTag = (offer: any) => {
    if (isDtqOrPending(offer.offerStatus)) {
      return offer.offerStatus;
    }
  };

  const getDraftTag = (offer: any) => {
    if (isDraftOffer(offer.status)) {
      return offer.status;
    }
  };

  const handleOfferDetailNavigate = (data: any) => {
    dispatch(setIsFromOfferNavigatePY(planYearValue));
    navigate(
      `/brokers/${params?.brokerId}/employers/${
        params?.employerId
      }/renewals/carrier/${
        OFFER_BENEFIT_TYPE_NAVIGATION_LABELS?.[data?.type ?? '']
      }/${data?.offerId}/detail`,
      {
        state: { status, planYear: planYearValue },
      }
    );
  };

  const getOffersByCategory = (type: string) => {
    if (!carrierSummaryData) {
      return [];
    }

    switch (type.toLowerCase()) {
      case 'medical':
        return carrierSummaryData?.medical;
      case 'dental':
        return carrierSummaryData?.dental;
      case 'vision':
        return carrierSummaryData?.vision;
      case 'lifeadd':
      case 'life':
        return carrierSummaryData?.lifeAdd;
      case 'ltd':
        return carrierSummaryData?.ltd;
      case 'std':
        return carrierSummaryData?.std;
      case 'voluntarybenefits':
      case 'voluntary_benefit':
        return carrierSummaryData?.voluntaryBenefits;
      default:
        [];
    }
  };

  const summaryTableColumns: DataColumn[] = [
    {
      title: '',
      dataIndex: 'type',
      key: 'type',
      width: '17%',
      showSorterTooltip: false,
      render: (colData, record, index) => {
        return index === 0 ? (
          <Row
            justify={'space-between'}
            align={'middle'}
            className={styles.typeCol}
          >
            <span className={styles.categoryLabel}>
              {
                <img
                  src={getBenefitInfo(colData.toLowerCase()).icon}
                  className={styles.typeIcon}
                />
              }
              <span className={styles.typeText}>
                {getBenefitInfo(colData.toLowerCase()).name}
              </span>
            </span>

            {getOffersByCategory(colData)?.length <= 1 ? (
              ''
            ) : (
              <ExpandedArrowIcon
                className={styles.expandIcon}
                onClick={() => onExpandIconClick(colData)}
              />
            )}
          </Row>
        ) : (
          <div></div>
        );
      },
    },
    {
      title: 'CARRIER',
      dataIndex: 'carrierLogoUrl',
      key: 'carrierLogoUrl',
      width: '20%',
      ellipsis: true,
      showSorterTooltip: false,
      render: (colData) => {
        return (
          <img
            src={colData}
            alt="carrier-logo"
            className={styles.carrierLogo}
          />
        );
      },
    },
    {
      title: 'OFFER NAME',
      dataIndex: '',
      key: 'offerName',
      width: '25%',
      ellipsis: true,
      showSorterTooltip: false,
      render: (colData) => {
        return (
          <div>
            <Row
              className={styles.offerNameWrapper}
              onClick={() => handleOfferDetailNavigate(colData)}
            >
              <span className={styles.recommenderWrapper}>
                {colData.recommended && <RecommendedTooltip />}
              </span>
              <span className={styles.offerName}>
                {displayText(colData.offerName)}
              </span>
            </Row>
            {selectedEffectiveDate === 0 && (
              <Row>
                <PeriodTooltip
                  monthCount={colData.noOfEffectiveMonths}
                  monthFrom={colData.effectiveDateFrom}
                  monthTo={colData.effectiveDateTo}
                />
              </Row>
            )}
            <Row className={styles.recommenderTagWrapper}>
              {colData.renewal && (
                <OfferStatusTag offerStatus={OFFER_TAG_RENEWAL} />
              )}
              <OfferStatusTag offerStatus={getOfferStatusTag(colData)} />
              <OfferStatusTag offerStatus={getDraftTag(colData)} />
              {[
                BenefitCategory.DENTAL.value,
                BenefitCategory.VISION.value,
                BenefitCategory.MEDICAL.value,
              ].includes(colData.type) && (
                <FundingTypeTag fundingType={colData.fundingType} />
              )}
            </Row>
          </div>
        );
      },
    },
    {
      title: '# PLANS',
      dataIndex: '',
      key: 'noOfPlans',
      width: '10%',
      ellipsis: true,
      showSorterTooltip: false,
      render: (colData) => {
        return isDtqOrPending(colData.offerStatus) ? (
          <span>-</span>
        ) : (
          <div className={styles.planCount}>
            {displayText(colData.noOfPlans, true)}
          </div>
        );
      },
    },
    {
      title: 'EST. ANNUAL PREMIUM',
      dataIndex: '',
      key: 'annualPremium',
      ellipsis: true,
      width: '15%',
      showSorterTooltip: false,
      render: (colData) => {
        return isDtqOrPending(colData.offerStatus) ? (
          <span>-</span>
        ) : (
          <div>
            {displayText(
              formatHeaderValues(colData.annualPremium, 'CURRENCY', false)
            )}
          </div>
        );
      },
    },
    {
      title: 'FROM CURRENT',
      dataIndex: '',
      key: 'fromCurrent',
      ellipsis: true,
      width: '15%',
      showSorterTooltip: false,
      render: (colData) => {
        return isDtqOrPending(colData.offerStatus) ? (
          <span>-</span>
        ) : (
          <div>
            {colData?.placeholderCreated ? (
              <span className={styles.notApplicableVsCurrent}>-</span>
            ) : (
              <>
                {isNull(colData.fromCurrentValue || colData.fromCurrent) ? (
                  <>-</>
                ) : (
                  <Row gutter={2}>
                    <Col>
                      {currencyFormatterWithoutZeros(
                        colData.fromCurrentValue ? colData.fromCurrentValue : 0
                      )}
                    </Col>
                    <Col>
                      <FromCurrentPercentage
                        showBrackets
                        percentage={
                          colData.fromCurrent ? colData.fromCurrent : 0
                        }
                        showHyphenOnZero={colData.noCurrentPlans}
                        decimalPlaces={2}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];

  const handleSetDueDateClick = () => {
    setIsDueDataModalVisible(true);
  };

  const collpasedTableColumns: DataColumn[] = [
    {
      title: '',
      dataIndex: 'type',
      key: 'type',
      width: '17%',
      showSorterTooltip: false,
      render: (colData) => {
        const tableData = data;
        return (
          <Row
            justify={'space-between'}
            align={'middle'}
            className={
              tableData[colData]?.length === 0
                ? styles.typeColDisabled
                : styles.typeCol
            }
          >
            <span className={styles.categoryLabel}>
              <img
                src={
                  tableData[colData]?.length === 0
                    ? getBenefitInfo(colData.toLowerCase()).iconDisabled
                    : getBenefitInfo(colData.toLowerCase()).icon
                }
                className={styles.typeIcon}
              />
              <span className={styles.typeText}>
                {getBenefitInfo(colData.toLowerCase()).name}
              </span>
            </span>
            {getOffersByCategory(colData)?.length <= 1 ? (
              ''
            ) : (
              <ExpandedArrowIcon
                className={styles.downExpandIcon}
                onClick={() => {
                  onExpandIconClick(colData);
                }}
              />
            )}
          </Row>
        );
      },
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      width: '85%',
      ellipsis: true,
      align: 'center',
      showSorterTooltip: false,
      render: (colData: number, record: any) => {
        return colData === 0 ? (
          <div className={styles.noOffers}>
            {isErAdmin ? NO_OFFERS_ER_ADMIN : NO_OFFERS}
          </div>
        ) : (
          <div>
            <span className={styles.offerCount}>{colData} Offers</span>{' '}
            <span
              className={styles.offerListView}
              onClick={() => onExpandIconClick(record?.type)}
            >
              (View)
            </span>
          </div>
        );
      },
    },
  ];

  const handleCancel = () => {
    dueDateForm.resetFields();
    setIsDueDataModalVisible(false);
  };

  const isNewPlanYearAndUpcomingExist = (): boolean => {
    const isPlanYearExistExceptErAdmin =
      !isNoPlanYearDataExist && appBootInfo?.type !== loginTypes.erAdmin;

    return (
      !planYears.some((obj: any) => obj.value === null) &&
      isPlanYearExistExceptErAdmin
    );
  };

  const generateEmptyDropDown = (txt: string): JSX.Element => {
    return (
      <p className={styles.noDataDropDownLabel}>
        {txt}
        <span className={styles.blueDash}>-</span>
      </p>
    );
  };

  const handleExportOffersOpen = () => {
    setIsOfferExportVisible(true);
    getOffersExportFilters({
      employerId: String(employerId),
      upcomingPlanYearId,
      planYearId: planYearValue,
    });
  };

  const updateOfferAndCarrierFilters = (currSelectedBenefitTypes: any[]) => {
    const offerNames: Set<string> = new Set();
    const allOffers: Set<string> = new Set();
    const currentOffers: Set<string> = new Set();
    const renewalOffers: Set<string> = new Set();

    currSelectedBenefitTypes?.forEach((data) => {
      filterOptionsData?.offerNames?.[data?.value]?.forEach(
        (offerName: string) => {
          offerNames.add(offerName);
          allOffers.add(offerName);
        }
      );
      filterOptionsData?.currentOfferNames?.[data?.value]?.forEach(
        (offerName: string) => {
          currentOffers.add(offerName);
          allOffers.add(offerName);
        }
      );
      filterOptionsData?.renewalOfferNames?.[data?.value]?.forEach(
        (offerName: string) => {
          renewalOffers.add(offerName);
          allOffers.add(offerName);
        }
      );
    });

    setOfferNameOptions(Array.from(offerNames));

    setSelectedOfferNames(Array.from(allOffers));
  };

  const handleSoldConfirmationsDropDown = (
    action: SoldConfirmationDropDownType
  ) => {
    switch (action) {
      case 'SEND_CARRIER_SOLD_CONFIRMATION':
        setShowSolidConfirmation(true);
        break;
      case 'VIEW_CARRIER_SOLD_CONFIRMATION':
        setShowSolidConfirmation(true);
        break;
      case 'SEND_EMPLOYER_SOLD_CONFIRMATION':
        setIsAlreadyFinalApproved(true);
        setFinalApprovedProposalName(
          finalApprovedProposalSummary?.proposalName ?? ''
        );
        setEffectiveDateRange(
          `${dayjs(finalApprovedProposalSummary?.effectiveStartDate).format(
            'll'
          )} - ${dayjs(finalApprovedProposalSummary?.effectiveEndDate).format(
            'll'
          )}`
        );
        setIsEmployerSoldConfirmationModalOpen(true);
        break;
    }
  };

  const updateIsAddProposalToPlanYearModalOpen = () => {
    setIsAddProposalToPlanYearModalOpen(true);
  };

  return (isUpcomingFetching || isOffersDataFetching) &&
    !isCreateNewRenewalModalOpen ? (
    <Spin />
  ) : (
    <>
      <AfpBanner
        isProposalAddToPlanYearVisible={isProposalAddToPlanYearVisible}
        overlapPlanYear={overlapPlanYear}
        soldConfirmCompleteData={soldConfirmCompleteData}
        finalApprovedProposalSummary={finalApprovedProposalSummary}
        isEmployerSoldConfirmationSent={isEmployerSoldConfirmationSent}
        handleSoldConfirmationsDropDown={handleSoldConfirmationsDropDown}
        addProposalToPlanYearModalOpen={updateIsAddProposalToPlanYearModalOpen}
        proposalId={finalApprovedProposalId}
      />
      <EmployerFeatureDisabledBanner
        feature={FEATURE_KEYS.RENEWAL}
        featureName="Renewals"
        employerId={params.employerId}
      />
      {isNewPlanYearAndUpcomingExist() && (
        <Row className={styles.reviewApprovedRow} wrap={false}>
          <Col>
            <TimerRefreshIcon className={styles.reviewApprovedIcon} />
          </Col>
          <Col flex={'auto'}>
            <Row className={styles.reviewApprovedHeading}>New Renewal</Row>
            <Row>Get started on the upcoming renewal</Row>
          </Col>
          <Col>
            <Button
              size="large"
              disabled={isEmpMember}
              className={styles.reviewApprovedButton}
              onClick={() => setIsCreateNewRenewalModalOpen(true)}
            >
              Start New Renewal
            </Button>
          </Col>
        </Row>
      )}
      <div className={styles.carrierOfferWrapper}>
        <Row justify={'space-between'}>
          <RenewalCommonMainHeader mainText={'Carrier Offers'} />
        </Row>
        <Row justify={'space-between'} align="middle">
          <Row align="middle">
            {isPlanYearsSuccess && (
              <Col>
                {isNoPlanYearDataExist && isNoDataExist ? (
                  generateEmptyDropDown('Plan Year: ')
                ) : (
                  <Row>
                    <p className={styles.headerLabel}>Plan Year: </p>
                    <SingleSelectFilter
                      data={planYears}
                      placeholder={''}
                      showSearch={false}
                      setFilterSelectedValue={(value: any) => {
                        setPlanYearValue(value);
                        setEffectiveDateValue(ALL_DROPDOWN_ITEM.value);
                      }}
                      selectedValue={planYearValue}
                      defaultValue={UPCOMING_DROPDOWN_PROPOSAL.value}
                      applicableIcon={<DropDownDarkArrow />}
                      inlineDropdown={true}
                      isDisabled={false}
                    />
                  </Row>
                )}
              </Col>
            )}

            {isUpcomingFetchSuccess && (
              <Col>
                {isNoDataExist ? (
                  generateEmptyDropDown('Effective Dates: ')
                ) : (
                  <Row>
                    <p className={styles.headerLabel}>Effective Dates:</p>
                    <SingleSelectFilter
                      data={effectiveDates}
                      placeholder={'-'}
                      showSearch={false}
                      setFilterSelectedValue={setSelectedEffectiveDate}
                      defaultValue={selectedEffectiveDate}
                      selectedValue={selectedEffectiveDate}
                      applicableIcon={<DropDownDarkArrow />}
                      inlineDropdown={true}
                      isDisabled={isNoDataExist}
                      isWiderWrapper={true}
                      isSelectionHighlightAndTickApplicable={false}
                    />
                  </Row>
                )}
              </Col>
            )}
          </Row>

          <div>
            {isUpcomingFetchSuccess &&
              !isNoDataExist &&
              appBootInfo?.type !== loginTypes.erAdmin && (
                <div>
                  <Button
                    type="link"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!hasNoOffers()) {
                        handleExportOffersOpen();
                      }
                    }}
                    className={styles.exportButton}
                    onMouseEnter={() => setShowExportOffersPopover(true)}
                    onMouseLeave={() => setShowExportOffersPopover(false)}
                  >
                    <PlanyearPopover
                      content={`There is no offer to export.`}
                      placement="bottom"
                      zIndex={9999}
                      visible={showExportOffersPopover && hasNoOffers()}
                    >
                      <div
                        className={
                          hasNoOffers()
                            ? styles.exportButtonDisabled
                            : styles.exportButtonStyle
                        }
                      >
                        Export Offers
                      </div>
                    </PlanyearPopover>
                  </Button>
                </div>
              )}
          </div>
        </Row>

        {null === carrierSummaryData?.carrierOfferDueDate ? (
          !isErAdmin && (
            <Row className={styles.setDueCarrierDateRow}>
              <Col span={8}>
                <p className={styles.carrierOfferDateDue}>Carrier Offers Due</p>
              </Col>
              <Col span={24}>
                <Button
                  className={styles.dueDateBtn}
                  onClick={handleSetDueDateClick}
                >
                  Set Due Date
                </Button>
                <div className={styles.lineSeperator} />
              </Col>
            </Row>
          )
        ) : (
          <Row>
            <Col span={8}>
              <p className={styles.carrierOfferDateDue}>Carrier Offers Due</p>
            </Col>
            <Col span={24}>
              <p className={styles.carrierDueLabel}>
                {dayjs(carrierSummaryData?.carrierOfferDueDate).format(
                  'MMM DD, YYYY'
                )}
                {!isErAdmin && (
                  <PencilIcon
                    className={styles.dueDateEditButton}
                    onClick={handleCarrierDueEditClick}
                  />
                )}
              </p>
            </Col>
            <hr />
          </Row>
        )}

        <Row className={styles.subSummaryRow} justify="space-between">
          <Col>
            <RenewalCommonSubHeader subText={'Offers Summary'} />
          </Col>
        </Row>

        {generateBodyBasedOnUpcomingData()}
        <StartNewRenewalModal
          employerId={params?.employerId ?? ''}
          isOpen={isCreateNewRenewalModalOpen}
          onCancel={() => {
            setIsCreateNewRenewalModalOpen(false);
            getUpcomingPlanYears({ employerId });
            getAfpPlanYears({ employerId });
          }}
          onSubmit={() => {
            setPlanYearValue(null);
          }}
        />
        <ExportOffers
          isModalVisible={isExportOffersOpen}
          handleClose={setIsExportOffersOpen}
          upcomingPlanYearId={upcomingPlanYearId}
          employerId={employerId}
        />

        <ConfirmationDialog
          visible={isDueDataModalVisible}
          confirmText={'Save'}
          cancelText="Cancel"
          isCancelLink
          title={'Carrier Offers Due Date'}
          buttonsExpanded={true}
          destroyOnClose={true}
          closeModal={handleCancel}
          onCancel={handleCancel}
          onConfirm={handleDueDateSubmit}
          confirmLoading={updateCarrierOfferDueDateLoading}
        >
          <p className={styles.carrierDueDateDescription}>
            Set up the Carrier Offers Due Date to indicate the last day by which
            offers must be submitted for this employer.
          </p>
          <InputForm form={dueDateForm}>
            <Form.Item
              name={'dueDate'}
              label={'Due Date'}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: 'Due Date is required',
                },
              ]}
            >
              <DatePickerInput
                placeholder={dateFormat}
                suffixIcon={<CalendarIcon />}
                disabled={false}
                value={null}
              />
            </Form.Item>
          </InputForm>
        </ConfirmationDialog>

        {isOfferExportVisible && (
          <ExportOfferProposalModal
            planYearId={planYearValue}
            disableProposalExportPopup={() => setIsOfferExportVisible(false)}
            isOfferExport={true}
            defaultDiscountsIncluded={false}
            offerUpcomingId={upcomingPlanYearId}
          />
        )}
      </div>
      <AddProposalToPlanYearModal
        finalApprovedProposalId={finalApprovedProposalId ?? ''}
        isOpen={isAddProposalToPlanYearModalOpen}
        setIsOpen={setIsAddProposalToPlanYearModalOpen}
        updateProposalStatus={publishProposal}
        setIsProposalAddToPlanYearVisible={setIsProposalAddToPlanYearVisible}
      />
      <SoldConfirmationModal
        finalApprovalId={finalApprovedProposalId ?? ''}
        visible={showSolidConfirmation}
        onConfirm={() => {
          getIsSoldConfirmationComplete({
            proposalId: String(finalApprovedProposalId),
          });
          setShowSolidConfirmation(false);
        }}
        onClose={() => {
          getIsSoldConfirmationComplete({
            proposalId: String(finalApprovedProposalId),
          });
          setShowSolidConfirmation(false);
        }}
      />
      <EmployerSoldConfirmationModal
        finalApprovalId={finalApprovedProposalId ?? ''}
        visible={isEmployerSoldConfirmationModalOpen}
        proposalName={finalApprovedProposalName}
        effectiveDateRange={effectiveDateRange}
        onClose={() => {
          setIsEmployerSoldConfirmationModalOpen(false);
          loadList();
        }}
        setIsFinalApprovedCompleted={setIsFinalApprovedCompleted}
        isAlreadyFinalApproved={isAlreadyFinalApproved}
        setIsEmployerSoldConfirmationSent={setIsEmployerSoldConfirmationSent}
      />
    </>
  );
};

export default CarrierOffers;
