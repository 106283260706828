import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import axios, { AxiosError } from 'axios';

import CustomPlan from 'model/plans/CustomPlan';
import { VisionPlan } from 'model/plans/VisionPlan';
import * as PlanService from 'modules/plans/services/PlanService';
import PaginationConfig from 'model/PaginationConfig';
import MetaData from 'model/MetaData';
import ErrorDetails from 'model/ErrorDetails';
import PlanServiceObject from 'model/PlanService';
import ClonePlanDocument from 'model/plans/ClonePlanDocument';
import { getOrderedDisplayServices } from 'modules/plans/slices/medicalPlanSlice';
import { removeBenefitDocumentsFromPlan } from 'modules/plans/utils';
import { BenefitCategory } from 'constants/commonConstants';
import {
  BenefitCovered,
  ContributionType,
  CostType,
  CUSTOM_SERVICE_CODE_RANGE,
  DEFAULT_SERVICE_CODES,
} from 'modules/plans/constants';
import FundingType from 'modules/plans/enums/FundingType';
import { AIUploaderFeedback } from 'model/plans/UserFeedback';
import { clonePlanDocuments } from './planDocumentsSlice';

export interface VisionPlanServiceState {
  inProgress: boolean;
  error: any;
  requestType: string;
  visionPlanList: { content: Array<VisionPlan>; metadata: MetaData };
  visionPlan: VisionPlan;
  visionPlanServiceList: {
    inProgress: boolean;
    error: any;
    defaults: PlanServiceObject[];
    options: PlanServiceObject[];
    benefitKind: string;
  };
  displayedVisionServices: PlanServiceObject[];
  visionRates: any;
  editedVisionPlan: VisionPlan;
  visionDocumentReferences: {};
}

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  visionPlanList: { content: [], metadata: {} },
  visionPlan: {
    rates: {},
    groups: [] as string[],
    planFeedback: [] as AIUploaderFeedback[],
  } as VisionPlan,
  visionPlanServiceList: {
    defaults: [] as PlanServiceObject[],
    options: [],
    inProgress: false,
    benefitKind: '',
    error: null,
  },
  displayedVisionServices: [] as PlanServiceObject[],
  visionRates: {},
  editedVisionPlan: { rates: {}, groups: [] as string[] } as VisionPlan,
  visionDocumentReferences: {},
} as VisionPlanServiceState;

const visionPlanSlice = createSlice({
  name: 'visionPlan',
  initialState,
  reducers: {
    injectVisionPlanDetails: (
      state,
      { payload }: PayloadAction<Partial<VisionPlan>>
    ) => {
      state.inProgress = false;
      state.visionPlan = {
        ...state.visionPlan,
        ...payload,
      };
    },
    getVisionPlansListInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
    },
    getVisionPlansListCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.inProgress = false;
      state.error = null;
      state.visionPlanList = payload;
    },
    getVisionPlansListFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = action.payload;
    },
    updateVisionPlanInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    updateVisionPlanCompleted: (state, action: PayloadAction<VisionPlan>) => {
      state.inProgress = false;
      state.visionPlan = { ...state.visionPlan, ...action.payload };
      state.editedVisionPlan = { ...state.editedVisionPlan, ...action.payload };
      state.error = null;
      state.requestType = action.type;
    },
    updateVisionPlanByPathCompleted: (
      state,
      { payload, type }: PayloadAction<any>
    ) => {
      set(state.editedVisionPlan, payload.path, payload.value);
      if (payload.isDBGView) {
        set(state.visionPlan, payload.path, payload.value);
      }
      state.inProgress = false;
      state.error = null;
      state.requestType = type;
    },
    updateVisionPlanFailed: (state, action) => {
      state.inProgress = false;
      state.error = JSON.parse(JSON.stringify(action.payload));
      state.requestType = action.type;
    },
    documentUploadStarted: (state, { payload }: PayloadAction<any>) => {
      state.visionPlan.documentReferences = {
        ...state.visionPlan.documentReferences,
        [payload.documentType]: {
          uploading: true,
        },
      };
    },
    documentUploadCompleted: (state, { payload }: PayloadAction<any>) => {
      state.visionPlan.documentReferences = {
        ...state.visionPlan.documentReferences,
        [payload.documentType]: {
          uploading: false,
          reference: payload.reference,
          blobUrl: payload.blobUrl,
          fileName: payload.fileName,
        },
      };
    },
    documentUploadFailed: (state, { payload }: PayloadAction<any>) => {
      state.visionPlan.documentReferences = {
        ...state.visionPlan.documentReferences,
        [payload.documentType]: {
          uploading: false,
          error: { response: payload.error },
        },
      };
    },
    updateVisionPlanServiceCompleted: (
      state,
      action: PayloadAction<CustomPlan>
    ) => {
      const { payload } = action;
      const { customServices = [] } = state.visionPlan;
      const existingServiceIndex = customServices.findIndex(
        (s) =>
          (payload.benefitCode.code &&
            payload.benefitCode.code === s.benefitCode.code) ||
          payload.benefitCode.shortName === s.benefitCode.shortName
      );
      if (existingServiceIndex !== -1) {
        customServices[existingServiceIndex] = payload;
      } else {
        customServices.push(payload);
      }
      state.visionPlan = {
        ...state.visionPlan,
        customServices: [...customServices],
      };
      state.editedVisionPlan = {
        ...state.editedVisionPlan,
        customServices: [...customServices],
      };
    },
    removeBenefitDocuments: (state) => {
      removeBenefitDocumentsFromPlan(
        state.visionPlan,
        state.visionDocumentReferences,
        state.editedVisionPlan
      );
    },
    resetStateCompleted: (state) => {
      state.visionPlan = { rates: {}, groups: [] as string[] } as VisionPlan;
      state.editedVisionPlan = {
        rates: {},
        groups: [] as string[],
      } as VisionPlan;
      state.visionPlanServiceList.defaults = [];
      state.visionPlanServiceList.options = [];
    },
    getPlanContributionInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.requestType = action.type;
    },
    getPlanContributionCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.visionPlan = payload;
      state.editedVisionPlan = payload;
      state.visionRates = payload.rates;
      state.requestType = action.type;
      state.inProgress = false;
    },
    getLatestPlanContributionCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.editedVisionPlan = payload;
      state.visionRates = payload.rates;
      state.requestType = action.type;
      state.inProgress = false;
    },
    getPlanContributionFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = action.payload;
      state.requestType = action.type;
    },
    getPlanByIdStarted: (state) => {
      state.inProgress = true;
      state.visionPlan = {} as VisionPlan;
      state.editedVisionPlan = {} as VisionPlan;
      state.error = null;
    },
    getPlanByIdCompleted: (state, action: PayloadAction<VisionPlan>) => {
      state.inProgress = false;
      state.visionPlan = action.payload;
      state.editedVisionPlan = action.payload;
      state.error = null;
      state.visionRates = action.payload.rates;
      state.visionDocumentReferences = action.payload?.documents;
    },
    getPlanByIdFailed: (state, action) => {
      state.inProgress = false;
      state.visionPlan = {} as VisionPlan;
      state.editedVisionPlan = {} as VisionPlan;
      state.error = { response: action.payload };
    },
    setDisplayedVisionServicesCompleted: (
      state,
      action: PayloadAction<PlanServiceObject[]>
    ) => {
      const { payload } = action;
      state.displayedVisionServices = getOrderedDisplayServices(payload);
    },
    visionPlanServiceListInProgress: (state, { payload }) => {
      state.visionPlanServiceList.inProgress = true;
      state.visionPlanServiceList.benefitKind = payload;
    },
    visionPlanServiceListCompleted: (state, { payload }) => {
      state.visionPlanServiceList.inProgress = false;
      state.visionPlanServiceList = payload;
    },
    visionPlanServiceListFailed: (state, { payload }) => {
      state.visionPlanServiceList.inProgress = false;
      state.visionPlanServiceList.defaults = [];
      state.visionPlanServiceList.options = [];
      state.visionPlanServiceList.error = { response: payload };
    },
    clearVisionPlanApiErrors: (state) => {
      state.error = null;
    },
    setUserAiFeedback: (
      state,
      { payload }: PayloadAction<AIUploaderFeedback>
    ) => {
      if (isEmpty(state.visionPlan.planFeedback)) {
        state.visionPlan.planFeedback = [];
      }
      const planFeedback = state.visionPlan.planFeedback;
      const existingFeedbackIndex =
        planFeedback?.findIndex(
          (feedback) => feedback.section === payload.section
        ) ?? 0;

      if (existingFeedbackIndex !== -1) {
        planFeedback?.splice(existingFeedbackIndex!, 1, payload);
      } else {
        planFeedback?.push(payload);
      }
    },
    removeUserAiFeedback: (state, { payload }: PayloadAction<string>) => {
      if (isEmpty(state.visionPlan.planFeedback)) {
        state.visionPlan.planFeedback = [];
      }
      const sectionToRemove = payload;
      state.visionPlan.planFeedback = state.visionPlan?.planFeedback?.filter(
        (feedback) => feedback?.section !== sectionToRemove
      );
    },
  },
});

export const {
  injectVisionPlanDetails,
  getVisionPlansListInProgress,
  getVisionPlansListCompleted,
  getVisionPlansListFailed,
  updateVisionPlanInProgress,
  updateVisionPlanCompleted,
  updateVisionPlanByPathCompleted,
  updateVisionPlanFailed,
  documentUploadStarted,
  documentUploadCompleted,
  documentUploadFailed,
  updateVisionPlanServiceCompleted,
  getPlanContributionInProgress,
  getPlanContributionCompleted,
  getLatestPlanContributionCompleted,
  getPlanContributionFailed,
  resetStateCompleted,
  getPlanByIdStarted,
  getPlanByIdCompleted,
  getPlanByIdFailed,
  setDisplayedVisionServicesCompleted,
  visionPlanServiceListInProgress,
  visionPlanServiceListCompleted,
  visionPlanServiceListFailed,
  clearVisionPlanApiErrors,
  removeUserAiFeedback,
  setUserAiFeedback,
  removeBenefitDocuments,
} = visionPlanSlice.actions;

export default visionPlanSlice.reducer;

export const getVisionPlansList = (
  { page, size, sort, query }: PaginationConfig,
  employerId: string,
  planYearId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getVisionPlansListInProgress());
    try {
      const response = await PlanService.getVisionPlansList(
        page,
        size,
        sort,
        query,
        employerId,
        planYearId
      );
      dispatch(getVisionPlansListCompleted(response?.data));
    } catch (error) {
      dispatch(getVisionPlansListFailed(error));
    }
  };
};

export const saveVisionPlan = (
  _plan: VisionPlan,
  onSave: (isSuccess: boolean, id: string) => void,
  cloneDocuments?: boolean,
  sourcePlanId?: string
) => {
  return async (dispatch: Dispatch) => {
    const plan = fixedUpVisionPlanObj(_plan);
    try {
      dispatch(updateVisionPlanInProgress());
      let response = {} as any;
      if (plan.id) {
        response = await PlanService.updateVisionPlan(plan);
      } else {
        response = await PlanService.createVisionPlan(plan);
      }
      const data = response.data;
      dispatch(updateVisionPlanCompleted(JSON.parse(JSON.stringify(data))));
      if (data && cloneDocuments && plan.documents && sourcePlanId) {
        const clonePlanDoc: ClonePlanDocument = {
          employerId: data.employerId,
          sourcePlanId: sourcePlanId,
          targetPlanId: data.id,
          benefitCategory: BenefitCategory.VISION.value,
          benefitKind: 'VISION',
        };
        dispatch(clonePlanDocuments(clonePlanDoc));
      }
      onSave(response?.status === 200, data.id);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        dispatch(
          updateVisionPlanFailed({
            data: err.response.data,
          } as ErrorDetails)
        );
      } else {
        // TODO: Need proper error handling
        console.log('Exception thrown ' + JSON.stringify(error));
      }
    }
  };
};

export const uploadVisionTemporaryDocument = (
  file: File,
  documentType: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(documentUploadStarted({ documentType }));
      const response = await PlanService.uploadPlanDocuments(file.name, file);
      dispatch(
        documentUploadCompleted({
          documentType,
          reference: response.data,
          fileName: file.name,
          blobUrl: URL.createObjectURL(file),
        })
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          documentUploadFailed({
            documentType,
            error: JSON.parse(JSON.stringify(error.response)),
          })
        );
      } else {
        console.error(error);
      }
    }
  };
};
export const updateVisionPlanService = (data: CustomPlan) => {
  return (dispatch: Dispatch) => {
    dispatch(
      updateVisionPlanServiceCompleted(JSON.parse(JSON.stringify(data)))
    );
  };
};
let abortController: AbortController;
let changedTier: string;
export const getPlanContributionByFrequency = (
  contributions: any,
  frequency: string,
  benefitGroup: string,
  rateTier: string,
  currentTier: string,
  isEdit?: boolean
) => {
  return async (dispatch: Dispatch) => {
    if (abortController && changedTier === rateTier) {
      abortController.abort(); // Tell the browser to abort request
    }
    if (typeof 'AbortController' !== 'undefined') {
      abortController = new AbortController();
    }
    changedTier = rateTier;
    try {
      dispatch(getPlanContributionInProgress());
      const response = await PlanService.calculateVisionPlanContributions(
        frequency,
        contributions,
        encodeURIComponent(benefitGroup),
        rateTier,
        currentTier,
        abortController.signal
      );
      const data = response.data;
      if (isEdit) {
        dispatch(
          getLatestPlanContributionCompleted(JSON.parse(JSON.stringify(data)))
        );
      } else {
        dispatch(
          getPlanContributionCompleted(JSON.parse(JSON.stringify(data)))
        );
      }
    } catch (error: any) {
      if (error.name === 'AbortError') {
        return; // Aborting request will return an error we don't want to handle in redux
      }
      dispatch(getPlanContributionFailed(error));
    }
  };
};

export const updateVisionPlan = (plan: VisionPlan) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateVisionPlanCompleted(JSON.parse(JSON.stringify(plan))));
  };
};

export const setDisplayedVisionServices = (data: PlanServiceObject[]) => {
  return (dispatch: Dispatch) => {
    dispatch(setDisplayedVisionServicesCompleted(data));
  };
};

export const getVisionPlanServicesList =
  (benefitKind: string, employerId: string): any =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(visionPlanServiceListInProgress(benefitKind));
      const response = await PlanService.getPlanServicesList(
        benefitKind,
        employerId
      );
      sortAndStoreBenefitCodes(benefitKind, response.data, dispatch);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          visionPlanServiceListFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };

const sortAndStoreBenefitCodes = (
  benefitKind: string,
  allServiceTypes: PlanServiceObject[],
  dispatch: Dispatch
) => {
  const serviceTypes = DEFAULT_SERVICE_CODES[benefitKind]
    .map((serviceCode) => {
      return (
        allServiceTypes.find(
          (serviceType: PlanServiceObject) => serviceType.code === serviceCode
        ) || ({} as PlanServiceObject)
      );
    })
    .filter((s) => s.shortName);

  const displayedList: PlanServiceObject[] = serviceTypes.filter(
    (s) => s?.isDefault
  );
  dispatch(setDisplayedVisionServicesCompleted([...displayedList]));

  // custom services
  serviceTypes.push(
    ...allServiceTypes.filter(
      (serviceType: PlanServiceObject) =>
        serviceType.code && serviceType.code >= CUSTOM_SERVICE_CODE_RANGE
    )
  );

  const payload = {
    options: allServiceTypes,
    defaults: serviceTypes,
    inProgress: false,
    benefitKind,
    error: null,
  };

  dispatch(visionPlanServiceListCompleted(payload));
};

export const updateVisionPlanByPath = (
  path: string[],
  value: any,
  isDBGView: boolean = false
) => {
  return async (dispatch: Dispatch) => {
    dispatch(
      updateVisionPlanByPathCompleted(
        JSON.parse(JSON.stringify({ path, value, isDBGView }))
      )
    );
  };
};

export const findVisionPlanById = (planId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPlanByIdStarted());
    try {
      const { data } = await PlanService.getVisionPlanById(planId);

      // Set SFC data
      data.administrationFee = data?.sfcPlanConfigVo?.administrationFee;

      dispatch(getPlanByIdCompleted(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(getPlanByIdFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
};

export const resetVisionPlanReduxStore = () => {
  return (dispatch: Dispatch) => {
    dispatch(resetStateCompleted());
  };
};

export const fixedUpVisionPlanObj = (plan: VisionPlan) => {
  const copyObject: VisionPlan = cloneDeep(plan);
  copyObject.name = plan.name?.trim();
  copyObject.groupId = plan.groupId?.trim();

  if (copyObject.rates) {
    Object.values(copyObject.rates)?.forEach((rate: any) => {
      if (!rate.contributionType) {
        rate.contributionType = ContributionType.FIXED.value;
      } else {
        return;
      }
    });
  }

  copyObject.customServices = copyObject.customServices?.map((service) => {
    if (get(service, 'serviceValue.inNetwork')) {
      if (
        get(service, 'serviceValue.inNetwork.costSharingPolicy') ===
        BenefitCovered.CLEAR
      ) {
        set(
          service,
          'serviceValue.inNetwork.benefitCovered',
          BenefitCovered.CLEAR
        );
      }

      switch (get(service, 'serviceValue.inNetwork.costSharingPolicy')) {
        case CostType.OTHER:
          set(
            service,
            'serviceValue.inNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(get(service, 'serviceValue.inNetwork.info')) &&
            set(service, 'serviceValue.inNetwork', {});
          break;

        case CostType.COPAY: {
          set(
            service,
            'serviceValue.inNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(get(service, 'serviceValue.inNetwork.copay')?.toString()) &&
            set(service, 'serviceValue.inNetwork', {});
          break;
        }

        case CostType.COINSURANCE: {
          set(
            service,
            'serviceValue.inNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(
            get(service, 'serviceValue.inNetwork.coinsurance')?.toString()
          ) && set(service, 'serviceValue.inNetwork', {});
          break;
        }

        case CostType.NOT_COVERED: {
          set(
            service,
            'serviceValue.inNetwork.benefitCovered',
            BenefitCovered.NO
          );
          set(service, 'serviceValue.inNetwork.costSharingPolicy', null);
          break;
        }
      }
    }

    if (get(service, 'serviceValue.outOfNetwork')) {
      if (
        get(service, 'serviceValue.outOfNetwork.costSharingPolicy') ===
        BenefitCovered.CLEAR
      ) {
        set(
          service,
          'serviceValue.outOfNetwork.benefitCovered',
          BenefitCovered.CLEAR
        );
      }

      switch (get(service, 'serviceValue.outOfNetwork.costSharingPolicy')) {
        case CostType.OTHER:
          set(
            service,
            'serviceValue.outOfNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(get(service, 'serviceValue.outOfNetwork.info')) &&
            set(service, 'serviceValue.outOfNetwork', {});
          break;

        case CostType.COPAY:
          set(
            service,
            'serviceValue.outOfNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(
            get(service, 'serviceValue.outOfNetwork.copay')?.toString()
          ) && set(service, 'serviceValue.outOfNetwork', {});
          break;

        case CostType.COINSURANCE:
          set(
            service,
            'serviceValue.outOfNetwork.benefitCovered',
            BenefitCovered.YES
          );
          isEmpty(
            get(service, 'serviceValue.outOfNetwork.coinsurance')?.toString()
          ) && set(service, 'serviceValue.outOfNetwork', {});
          break;

        case CostType.NOT_COVERED: {
          set(
            service,
            'serviceValue.outOfNetwork.benefitCovered',
            BenefitCovered.NO
          );
          set(service, 'serviceValue.outOfNetwork.costSharingPolicy', null);
          break;
        }
      }
    }

    return service;
  });

  if (copyObject.fundingType === FundingType.SELF_FUNDED) {
    copyObject.sfcPlanConfigVo = {
      employerId: plan.employerId,
      planYearId: plan.planYearId,
      carrierId: plan.benefitCarrier?.id,
      benefitKind: BenefitCategory.VISION.value,
      administrationFee:
        copyObject?.administrationFee === '' ||
        copyObject?.administrationFee === undefined ||
        copyObject?.administrationFee === null
          ? plan?.sfcPlanConfigVo?.administrationFee === ''
            ? null
            : plan?.sfcPlanConfigVo?.administrationFee
          : copyObject?.administrationFee,
    };
    copyObject.sfcPlanDataVO = {
      selfFundedClaimsStatus: true,
    };
  } else {
    copyObject.sfcPlanDataVO = {
      selfFundedClaimsStatus: false,
    };
  }

  return copyObject;
};

export const validateVisionPlanName = (paylaod: any) => {
  return async (dispatch: Dispatch) => {
    try {
      await PlanService.validatePlanName(paylaod, 'visions');
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            updateVisionPlanFailed({
              data: error.response.data,
            } as ErrorDetails)
          );
          return false;
        }
      }
    }
  };
};
