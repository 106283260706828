import { ReactElement } from 'react';
import { isEmpty } from 'lodash';
import { useAppSelector } from 'hooks/redux';

// Use this hook as a top order component to secure a given component with the permitted individual subtypes
export const usePermitByUserType = (
  ComponentName: ReactElement,
  permittedTypes: string[]
) => {
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const individualSubType = appBootInfo?.individualSubType
    ? appBootInfo.individualSubType
    : '';
  // render component when user doesn't have a subtype or the subtype is in the permitted types
  if (
    isEmpty(individualSubType) ||
    permittedTypes.includes(individualSubType)
  ) {
    return ComponentName;
  } else {
    return null;
  }
};
