import axios from 'axios';
import { baseApi, wsBaseApi } from 'util/apiUtil';
import PromptType from 'modules/assistant/enums/PromptRenderType';
import { AssistantChatResponseVO } from 'modules/assistant/models/AssistantChatResponseVO';

const v1ConversationURL = `${baseApi}/v1/broker-assistant`;

export const BROKER_ADMIN_ASSISTANT_WEB_SOCKET_URL =
  wsBaseApi + '/websocket-chat-bot';

export const APP_PREFIX = '/app';
export const TOPIC_PREFIX = '/topic';

export const BASE_DESTINATION_URL = '/broker/chat/';

export const assistantWSURL = (conversationId: string): string =>
  `${BASE_DESTINATION_URL}${conversationId}`;

export const publishingURL = (conversationId: string): string =>
  `${APP_PREFIX}${assistantWSURL(conversationId)}`;

export const receivingURL = (conversationId: string): string =>
  `${TOPIC_PREFIX}${assistantWSURL(conversationId)}`;

const buildDownloadUrl = (documentId: string) => {
  return `${baseApi}/v2/documents/${documentId}?isDownload=false`;
};

export interface ConversationServiceInterface {
  type: PromptType;
  question: string;
  documentReference?: string;
  refreshHistory?: () => void;
  onFinish?: (conversationId: string) => void;
}

export interface ConversationInputPayload {
  question?: string;
  answer?: string;
  id?: string;
  conversationId?: string;
  error?: boolean | string;
}

export const initializeConversationService = async ({
  type,
  question,
  documentReference,
}: Partial<ConversationServiceInterface>) => {
  const response = await axios.post(`${v1ConversationURL}`, {
    type: type,
    question: question,
    documentReference: documentReference,
  });
  return response.data as AssistantChatResponseVO;
};

export const getChatHistoryConversationService = async (
  conversationId: string
) => {
  const { data } = await axios.get(`${v1ConversationURL}/${conversationId}`);
  return data as AssistantChatResponseVO;
};

const validateDocument = async (documentId: string) => {
  const { data } = await axios.get(
    `${baseApi}/v2/documents/${documentId}/validate`
  );
  return data as boolean;
};

const ConversationService = {
  validateDocument,
  buildDownloadUrl,
};

export default ConversationService;
