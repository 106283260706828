import { ReactNode, useState } from 'react';
import { Modal } from 'antd';
import get from 'lodash/get';

import { useAppDispatch } from 'hooks/redux';

import DBGClient from 'modules/clients/DBGClient/DBGClient';
import {
  HOLIDAY_AND_TIMEOFF_CHANNEL,
  OPEN_ADD_NEW_PLAN_MODAL,
  OPEN_HOLIDAY_AND_TIMEOFF_EDIT_MODAL,
  RELOAD_HOLIDAY_AND_TIMEOFF_PLAN_LIST,
  RELOAD_HOLIDAY_AND_TIMEOFF_SECTION,
  BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
  EXIT_BENGUIDE,
  BENGUIDE_SAVE_COMPLETED,
  BENGUIDE_ACK_NOTIFICATION,
  CLOSE_MODALS,
} from 'modules/clients/DBGClient/DBGClientConts';
import useDBGClient from 'modules/clients/DBGClient/useDBGClient';
import IconInfo from 'assets/images/icon-info.svg';
import EditWorkLifePlan from 'modules/plans/holidaysTimeOff/components/EditWorkLifePlan/EditWorkLifePlan';
import AddWorkLifePlanDBGWrapper from 'modules/plans/components/AddWorkLifePlan/AddWorkLifePlanDBGWrapper';
import { BenefitCategory, BenefitKind } from 'constants/commonConstants';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import { ERROR_MESSAGE_PLAN_EDITING } from 'constants/benguideCollaborationConstants';
import { changedBenguideModalState } from 'modules/benefitGuide/slices/benguideSlice';
import BenefitGuideNotificationsChannel from './BenefitGuideNotificationsChannel';

import styles from './holidayAndTimeOffChannel.module.less';

type EventComponent = {
  component: ReactNode;
  title: string;
  props?: object;
};

type EventDataType = {
  item: {
    value: string;
    label: string;
  };
  benefitKind: string;
};

const HolidayAndimeOffPlansChannel = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [eventData, setEventData] = useState<EventDataType>();
  const [subscribedEvent, setSubscribedEvent] = useState('');
  const [disableSave, setDisableSave] = useState(false);
  const [planYearId, setPlanYearID] = useState<string>('');
  const dispatch = useAppDispatch();

  const cancelModalWhenRefresh = () => {
    setModalVisible(false);
  };

  const cancelModalWhenExit = () => {
    setModalVisible(false);
    dispatch(changedBenguideModalState(false));
  };
  const showOverlay = () => {
    if (disableSave) {
      dbgClient.postMessage({
        channel: HOLIDAY_AND_TIMEOFF_CHANNEL,
        event: BENGUIDE_NOTIFICATION_SHOW_OVERLAY,
        data: {},
      });
    }
  };

  const eventComponentMapper = {
    [OPEN_ADD_NEW_PLAN_MODAL]: {
      component: AddWorkLifePlanDBGWrapper,
      title: '',
      props: {
        visible: isModalVisible,
        onClose: () => {
          setModalVisible(false);
          dbgClient.postMessage({
            channel: HOLIDAY_AND_TIMEOFF_CHANNEL,
            event: RELOAD_HOLIDAY_AND_TIMEOFF_PLAN_LIST,
            data: {},
          });
          showOverlay();
        },
        benefitKind: eventData && eventData.item && eventData.item,
        isSaveDisabled: disableSave,
        isDBGPlan: true,
        dbgPlanYear: planYearId,
      },
      useInbuiltModal: true,
    } as EventComponent,
    [OPEN_HOLIDAY_AND_TIMEOFF_EDIT_MODAL]: {
      component: EditWorkLifePlan,
      title: `Edit ${
        get(BenefitKind, eventData?.benefitKind || '', '').label
      } Plan`,
      props: {
        onClose: () => {
          dbgClient.postMessage({
            channel: HOLIDAY_AND_TIMEOFF_CHANNEL,
            event: RELOAD_HOLIDAY_AND_TIMEOFF_SECTION,
            data: {},
          });
          showOverlay();
        },
        benefitKind: eventData && eventData.item && eventData.item,
        isDBGPlan: true,
        isSaveDisabled: disableSave,
        isModalVisible: isModalVisible,
      },
      useInbuiltModal: false,
      width: 600,
    } as EventComponent,
  } as any;

  const {
    component: Component,
    props,
    useInbuiltModal,
    width,
    title,
    cancelModal,
  } = eventComponentMapper[subscribedEvent] || {};

  const dbgClient = useDBGClient();

  return (
    <>
      <DBGClient
        channel={HOLIDAY_AND_TIMEOFF_CHANNEL}
        subscribeCommon={(event: string) => {
          if (CLOSE_MODALS === event) {
            setModalVisible(false);
          }
        }}
        subscribe={(event: string, data: any, dbgMetaData: any) => {
          switch (event) {
            case EXIT_BENGUIDE:
              cancelModalWhenExit();
              break;
            case BENGUIDE_SAVE_COMPLETED:
              cancelModalWhenRefresh();
              break;
            case BENGUIDE_ACK_NOTIFICATION:
              setDisableSave(true);
              break;
            default:
              setSubscribedEvent(event);
              setEventData(data);
              setModalVisible(true);
              setDisableSave(false);
              setPlanYearID(dbgMetaData?.planYearId);
              break;
          }
        }}
      />
      <BenefitGuideNotificationsChannel
        setDisableSave={() => setDisableSave(true)}
        isPlanEditModalOpen={isModalVisible}
        benefitKind={BenefitCategory.HOLIDAYS_AND_TIME_OFF.value}
      />
      {Component && useInbuiltModal ? (
        <Component medicalPlan={eventData} {...props} />
      ) : (
        <Modal
          width={width}
          visible={isModalVisible}
          onCancel={cancelModal}
          okText="Done"
          footer={false}
          className={styles.holidayAndTineOffPlanChannelModal}
          closable={false}
          maskClosable={!disableSave}
          confirmLoading={true}
          destroyOnClose
        >
          <div className={styles.holidayAndTineOffPlanChannelWrapper}>
            <div className={styles.title}>{title}</div>
            <div className={styles.leftBorder}></div>
            <div className={styles.medicalPlanChannel}>
              <div>
                <img src={IconInfo} alt="Icon Info" />
              </div>
              <div className={styles.defaultText}>
                Changing plan information here will change the plan information
                everywhere this plan is shown.
              </div>
            </div>
            {disableSave && (
              <FixedAlertMessage
                type={'error'}
                message={ERROR_MESSAGE_PLAN_EDITING}
              />
            )}
            {Component && <Component plan={eventData} {...props} />}
          </div>
        </Modal>
      )}
    </>
  );
};

export default HolidayAndimeOffPlansChannel;
