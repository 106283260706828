import React, { FC } from 'react';

import { Col, Row } from 'antd';

import dayjs from 'dayjs';
import PrintableIdCardEmployeeDataBody from 'modules/idCards/components/PrintableIdCardDataBody/PrintableIdCardEmployeeDataBody';
import PrintableIdCardEmployeeData from 'modules/idCards/components/PrintableIdCardEmployeeData/PrintableIdCardEmployeeData';
import { usDataFormat } from 'constants/dateFormat';

import { OFFER_CATEGORY } from 'modules/renewals/constants/renewalsConstants';
import styles from './printableIdCard.module.less';

const PrintableIdCard: FC<any> = ({ idData, benefitKind }) => {
  const getApplicableMemberData = (): string => {
    switch (benefitKind) {
      case 'MEDICAL':
        return concatAlphaIfExist(idData?.medicalMemberId);
      case 'DENTAL':
        return idData?.dentalMemberId;
      case 'VISION':
        return idData?.visionMemberId;
      default:
        return '';
    }
  };

  const getMemberId = (): any => {
    return <>{getApplicableMemberData()}</>;
  };

  const concatAlphaIfExist = (id: string) => {
    if (idData?.alphaValue) {
      const alpha: string = idData?.alphaValue;
      return alpha.concat(id);
    }
    return id;
  };

  const isMedical = (): boolean => {
    return OFFER_CATEGORY.MEDICAL === benefitKind;
  };

  return (
    <div className={styles.idCard}>
      <div className={styles.cardHeader}>
        <Row justify={'space-between'}>
          <Col>
            <img src={idData?.carrierLogoUrl} className={styles.logo1} />
          </Col>
          <Col>
            <img src={idData?.employerLogoUrl} className={styles.logo1} />
          </Col>
        </Row>
        <Row className={idData?.basicInfo && styles.basicInfo}>
          {!idData?.basicInfo && (
            <Col>
              <h1 className={styles.heading}>{idData?.planName}</h1>
              <span className={styles.effectiveDates}>
                Effective Dates: {dayjs(idData?.startDate).format(usDataFormat)}
              </span>
            </Col>
          )}
        </Row>
      </div>
      <div className={styles.cardBody}>
        <Row>
          <Col span={14}>
            <PrintableIdCardEmployeeData data={{ name: 'Name' }} />
          </Col>
          <Col span={10}>
            <PrintableIdCardEmployeeData
              data={{
                details: `${idData?.individualName?.firstName} ${idData?.individualName?.lastName}`,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={14}>
            <PrintableIdCardEmployeeData
              data={{ name: 'Primary Subscriber' }}
            />
          </Col>
          <Col span={10}>
            <PrintableIdCardEmployeeData
              data={{
                details: `${idData?.subscriberName?.firstName} ${idData?.subscriberName?.lastName}`,
                printable: true,
              }}
            />
          </Col>
        </Row>
        <hr className={styles.line} />
        <Row>
          <Col span={24}>
            <PrintableIdCardEmployeeDataBody
              memberIdentification={'ID #'}
              memberData={getMemberId()}
              additionalDetailTopic={isMedical() ? 'Rx Bin #' : ''}
              additionalDetailTopicData={isMedical() ? idData?.rxBin : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <PrintableIdCardEmployeeDataBody
              memberIdentification={'Group ID #'}
              memberData={idData?.groupId ?? ''}
              additionalDetailTopic={isMedical() ? 'Rx Pcn #' : ''}
              additionalDetailTopicData={isMedical() ? idData?.rxPcn : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <PrintableIdCardEmployeeDataBody
              memberIdentification={'Phone'}
              memberData={idData?.carrierPhone ?? ' '}
              additionalDetailTopic={isMedical() ? 'Rx Group #' : ''}
              additionalDetailTopicData={isMedical() ? idData?.rxGroup : ''}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PrintableIdCard;
