import { FC, useEffect, useState, useCallback } from 'react';
import { Checkbox, Col, Row, Select, Spin } from 'antd';

import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';

import SearchBar from 'components/SearchBar/SearchBar';
import styles from './MultiSelectDropDown.module.less';

type PlanDropDownProps = {
  selectOptions: any[];
  dropdownItemsSelected: string[];
  changeDropdownItemsSelected: Function;
  onSystemItemSave: Function;
  displaySearch: boolean;
  sortOptions: boolean;
  listLoading?: boolean;
  disabled?: boolean;
};

const MultiSelectDropDown: FC<PlanDropDownProps> = ({
  selectOptions,
  dropdownItemsSelected,
  changeDropdownItemsSelected,
  onSystemItemSave,
  displaySearch,
  sortOptions,
  listLoading = false,
  disabled = false,
}) => {
  const [tempSelectedValues, setTempSelectedValues] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectOptionsList, setSelectOptionsList] = useState<any[]>([]);
  const [displaySelectAll, setDisplaySelectAll] = useState(true);
  const [searchText, setSearchText] = useState('');

  const sortedSelectOptionsList = sortOptions
    ? selectOptions?.sort((a: any, b: any) => {
        const carrierA = a.label.toLowerCase();
        const carrierB = b.label.toLowerCase();
        if (carrierA < carrierB) {
          return -1;
        } else if (carrierA > carrierB) {
          return 1;
        }
        return 0;
      })
    : selectOptions;

  useEffect(() => {
    setSelectOptionsList(sortedSelectOptionsList);
  }, [selectOptions, sortedSelectOptionsList, listLoading]);

  const isSelectedAll = tempSelectedValues?.length === selectOptions?.length;

  const handleSelectAllClick = () => {
    if (isSelectedAll) {
      const disabledSelectOptions = selectOptions
        .filter((option) => {
          return option?.current;
        })
        .map((option) => {
          return option.value;
        });
      setTempSelectedValues(disabledSelectOptions);
    } else {
      setTempSelectedValues(selectOptions.map((option) => option.value));
    }
  };

  const filterByLabel = useCallback(
    (value: any) => {
      setSearchText(value);
      if (value.trim().length === 0) {
        setDisplaySelectAll(true);
      } else {
        setDisplaySelectAll(false);
      }
      setSelectOptionsList(
        selectOptions.filter((obj) => obj.label.toLowerCase().includes(value))
      );
    },
    // eslint-disable-next-line
    [searchText]
  );

  return (
    <div className={styles.planDropdownWrapper}>
      <Select
        disabled={disabled}
        listHeight={200}
        showSearch={false}
        filterOption={() => {
          return true;
        }}
        size="large"
        onSearch={(value) => filterByLabel(value)}
        optionLabelProp="label"
        mode={'multiple'}
        value={!showDropdown ? dropdownItemsSelected : tempSelectedValues}
        dropdownClassName={styles.addingOptionDropdown}
        maxTagCount={'responsive'}
        showArrow={true}
        getPopupContainer={(trigger) => trigger.parentNode}
        dropdownRender={(menu) => (
          <div>
            {displaySearch && (
              <div className={styles.searchFilter}>
                <SearchBar
                  placeholder="search"
                  isLarge={false}
                  value={searchText}
                  onChange={(e) => filterByLabel(e?.target?.value?.trimStart())}
                  onKeyDown={(e) => e.stopPropagation()}
                />
              </div>
            )}
            {listLoading ? (
              <div className={styles.spinner}>
                <Spin />
              </div>
            ) : (
              menu
            )}
            <Row className={styles.buttonWrapper} justify="center">
              <Col>
                <SubmitButton
                  type="primary"
                  className={styles.confirmButton}
                  onClick={() => {
                    changeDropdownItemsSelected(tempSelectedValues);
                    setTempSelectedValues([]);
                    onSystemItemSave(tempSelectedValues);
                    setShowDropdown(false);
                    setDisplaySelectAll(true);
                    setSearchText('');
                  }}
                >
                  Okay
                </SubmitButton>
              </Col>
              <Col>
                <CancelButton
                  htmlType="button"
                  onClick={() => {
                    changeDropdownItemsSelected(
                      sortedSelectOptionsList?.map((item) => item.value)
                    );
                    onSystemItemSave(
                      sortedSelectOptionsList?.map((item) => item.value)
                    );
                    setShowDropdown(false);
                    setDisplaySelectAll(true);
                    setSearchText('');
                  }}
                  className={styles.resetButton}
                >
                  Reset
                </CancelButton>
              </Col>
            </Row>
          </div>
        )}
        tagRender={(tag) => {
          return (
            <div>
              {tag?.value !==
              (showDropdown
                ? tempSelectedValues?.[0]
                : dropdownItemsSelected?.[0])
                ? ','
                : ''}
              &nbsp;
              {selectOptions?.find((item) => item?.value === tag?.value)?.label}
            </div>
          );
        }}
        open={showDropdown}
        onDropdownVisibleChange={(open) => {
          if (!open) {
            setDisplaySelectAll(true);
            setSearchText('');
            setSelectOptionsList(sortedSelectOptionsList);
          } else {
            setTempSelectedValues(dropdownItemsSelected);
          }
          setShowDropdown(open);
        }}
        menuItemSelectedIcon={false}
      >
        {displaySelectAll && (selectOptionsList?.length ?? 0) !== 0 && (
          <Select.Option value={'all'} key={'all'}>
            <Checkbox
              onClick={() => {
                handleSelectAllClick();
              }}
              checked={isSelectedAll}
              className={`${styles.selectAll} ${
                isSelectedAll ? styles.selectAllChecked : ''
              }`}
            >
              <span className={styles.selectLabel}>{`${
                isSelectedAll ? 'Deselect' : 'Select'
              } All`}</span>
            </Checkbox>
          </Select.Option>
        )}
        {selectOptionsList?.map(
          (option: {
            value: string;
            label: string;
            renewal?: boolean;
            current?: boolean;
            offersInProposal?: boolean;
          }) => (
            <Select.Option value={option.value} key={option.value}>
              <Checkbox
                name={option.value}
                checked={
                  tempSelectedValues?.includes(option.value) ||
                  option.current ||
                  option.offersInProposal
                }
                className={styles.singleSelect}
                disabled={option.current || option.offersInProposal}
                onClick={(event: any) => {
                  const selectedOption = event.target.getAttribute('name');
                  if (tempSelectedValues.includes(selectedOption)) {
                    setTempSelectedValues(
                      tempSelectedValues.filter((option) => {
                        return option !== selectedOption;
                      })
                    );
                  } else {
                    setTempSelectedValues(
                      tempSelectedValues.concat(selectedOption)
                    );
                  }
                }}
              >
                <span>
                  <span className={styles.selectLabel}>{option.label}</span>
                  {option?.current ? (
                    <span className={styles.currentTag}>
                      &nbsp;Current&nbsp;
                    </span>
                  ) : (
                    ''
                  )}
                  {option?.renewal ? (
                    <span className={styles.renewalTag}>
                      &nbsp;Renewal&nbsp;
                    </span>
                  ) : (
                    ''
                  )}
                </span>
              </Checkbox>
            </Select.Option>
          )
        )}
      </Select>
    </div>
  );
};

export default MultiSelectDropDown;
