import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Row, Col, Table } from 'antd';
import { useParams } from 'react-router-dom';
import CarrierContactInfo from 'modules/carriers/components/CarrierContactInfo/CarrierContactInfo';
import SearchBar from 'components/SearchBar/SearchBar';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import { buildInitials } from 'util/stringUtil';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { useLazyGetCarrierContactsByBrokerIdQuery } from 'modules/carriers/slices/carrierContactSlice';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CarrierContactsCreateModal from 'modules/carriers/components/CarrierContactCreateModal/CarrierContactCreateModal';
import styles from './carrierContactModal.module.less';

type CarrierContactModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  carrierContactId: string | null;
  fetchData?: () => void;
};

const CarrierContactModal: FC<CarrierContactModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  carrierContactId,
  fetchData,
}) => {
  const [searchText, setSearchText] = useState<string>('');

  const [isEdit, setIsEdit] = useState<boolean>();

  const { brokerId } = useParams();

  const [
    getCarrierContactData,
    { data: carrierContactData, isLoading: isCarrierContactDataLoading },
  ] = useLazyGetCarrierContactsByBrokerIdQuery();

  useEffect(() => {
    if (isModalOpen && carrierContactId) {
      getCarrierContactData({
        brokerId: brokerId!,
        carrierContactId: carrierContactId!,
        isEditMode: false,
      });
    }
    // eslint-disable-next-line
  }, [brokerId, carrierContactId, isModalOpen]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = e.target;
    const _searchText = value.trim().toLowerCase();
    setSearchText(_searchText);
  };

  const filterSearch = (data: any[]) => {
    const filteredList = data.filter((item: any) => {
      return item.name.toLowerCase().includes(searchText.trim().toLowerCase());
    });

    return filteredList;
  };

  const employersColumn = [
    {
      title: 'Employer Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => <OverflowPopover>{name}</OverflowPopover>,
    },
  ];

  const editCarrierContact = () => {
    setIsEdit(true);
  };

  return (
    <FullScreenModal
      visible={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={false}
      title="Carrier Contact Details"
      destroyOnClose
    >
      <div className={styles.manageBrokerAdminWrapper}>
        {carrierContactData?.firstName && (
          <Row className={styles.avatarWrapper}>
            <Col span={1}>
              <ProfileAvatar
                content={buildInitials(
                  carrierContactData?.firstName,
                  carrierContactData?.lastName
                )}
                className={styles.avatar}
              />
            </Col>
          </Row>
        )}
        <CarrierContactInfo carrierContactData={carrierContactData} />
        <Row className={styles.carrierContactTitle}>Carrier Contact for</Row>
        <SearchBar
          className={styles.sechBar}
          placeholder="Search to filter employers"
          onChange={handleSearch}
          isLarge={true}
        />
        <Table
          loading={isCarrierContactDataLoading}
          showHeader={false}
          columns={employersColumn}
          dataSource={filterSearch(
            carrierContactData?.employersDetailedInfoList?.map(
              (employer: { name: any }) => ({
                name: employer?.name,
              })
            ) || []
          )}
          pagination={false}
          scroll={{ y: 300 }}
          className={styles.scrollableTableWrapper}
        />

        <Row justify="center" className={styles.rowStyle}>
          <SubmitButton onClick={editCarrierContact} type="primary">
            Edit Carrier Contact
          </SubmitButton>
        </Row>

        {isEdit && (
          <CarrierContactsCreateModal
            isModalOpen={isEdit}
            setIsModalOpen={setIsEdit}
            carrierContactId={carrierContactId!}
            fetchData={fetchData}
            isEditFromDetail={true}
            setIsDetailModalOpen={setIsModalOpen}
          />
        )}
      </div>
    </FullScreenModal>
  );
};

export default CarrierContactModal;
