import React from 'react';
import * as _ from 'lodash';
import { Row } from 'antd';

import styles from './progressBar.module.less';

type Props = {
  segments: any[];
  maxValue: number;
  barHeight: number;
  borderRadius: boolean;
  className?: string;
};

const widthGenerator = (value: number, maxValue: number) => {
  return (parseFloat(String(value)) * 100) / parseFloat(String(maxValue));
};

const segmentMarginColor = '#fff';

const ProgressBar = (props: Props) => {
  const { segments, barHeight, borderRadius, className, maxValue } = props;

  const bars =
    !_.isEmpty(segments) &&
    segments.map((item: { value: number; color: string }, i) => {
      if (item.value > 0) {
        return (
          <div
            className={`${borderRadius ? 'bar-with-radius' : 'bar'} ${
              className || ''
            }${styles.multiColoredBar}`}
            style={{
              background: `linear-gradient(90deg, ${item.color} 0%, ${item.color} 99.5%, ${segmentMarginColor} 99.5%, ${segmentMarginColor} 100%)`,
              width: widthGenerator(item.value, maxValue) + '%',
              height: barHeight + 'px',
            }}
            key={`segment_${i}`}
          >
            <div className="segment-devider"></div>
          </div>
        );
      }
      return null;
    });

  return (
    <div className={styles.multiColoredBar}>
      <Row className={styles.bars}>{bars}</Row>
    </div>
  );
};

export default ProgressBar;
