import { Dispatch } from 'redux';
import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdditionalPlan } from 'model/plans/AdditionalPlan';
import * as TelehealthRxPlanService from 'modules/plans/services/TelehealthRxPlanService';
import MetaData from 'model/MetaData';
import PaginationConfig from 'model/PaginationConfig';
import ErrorDetails from 'model/ErrorDetails';
import ClonePlanDocument from 'model/plans/ClonePlanDocument';
import { clonePlanDocuments } from './planDocumentsSlice';

export type TelehealthRxPlanServiceState = {
  inProgress: boolean;
  error: any;
  requestType: string;
  telehealthRxPlan: AdditionalPlan;
  telehealthRxPlanList: { content: Array<AdditionalPlan>; metadata: MetaData };
};

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  telehealthRxPlan: {} as AdditionalPlan,
  telehealthRxPlanList: { content: [], metadata: {} },
} as TelehealthRxPlanServiceState;

const telehealthRxPlanSlice = createSlice({
  name: 'telehealthRxPlan',
  initialState,
  reducers: {
    createTelehealthRxPlan: (state, action: PayloadAction<AdditionalPlan>) => {
      state.telehealthRxPlan = action.payload;
      state.inProgress = false;
      state.error = null;
      state.requestType = action.type;
    },
    updateTelehealthRxPlanInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    updateTelehealthRxPlanCompleted: (
      state,
      action: PayloadAction<AdditionalPlan>
    ) => {
      state.inProgress = false;
      state.telehealthRxPlan = { ...state.telehealthRxPlan, ...action.payload };
      state.error = null;
      state.requestType = action.type;
    },
    updateTelehealthRxPlanFailed: (state, action) => {
      state.inProgress = false;
      state.error = JSON.parse(JSON.stringify(action.payload));
      state.requestType = action.type;
    },
    getTelehealthRxPlansListInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    getTelehealthRxPlansListCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.inProgress = false;
      state.error = null;
      state.telehealthRxPlanList = payload;
    },
    getTelehealthRxPlansListFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = { response: action.payload };
    },
    getPlanByIdStarted: (state) => {
      state.inProgress = true;
      state.telehealthRxPlan = {} as AdditionalPlan;
      state.error = null;
    },
    getPlanByIdCompleted: (state, action: PayloadAction<AdditionalPlan>) => {
      state.inProgress = false;
      state.telehealthRxPlan = action.payload;
      state.error = null;
    },
    getPlanByIdFailed: (state, action) => {
      state.inProgress = false;
      state.telehealthRxPlan = {} as AdditionalPlan;
      state.error = { response: action.payload };
    },
    clearTelehealthPlanApiErrors: (state) => {
      state.error = null;
    },
  },
});

export const {
  createTelehealthRxPlan,
  updateTelehealthRxPlanInProgress,
  updateTelehealthRxPlanCompleted,
  updateTelehealthRxPlanFailed,
  getTelehealthRxPlansListInProgress,
  getTelehealthRxPlansListCompleted,
  getTelehealthRxPlansListFailed,
  getPlanByIdStarted,
  getPlanByIdCompleted,
  getPlanByIdFailed,
  clearTelehealthPlanApiErrors,
} = telehealthRxPlanSlice.actions;

export default telehealthRxPlanSlice.reducer;

export const createTelehealthRxPlanBasicInfo =
  (data: AdditionalPlan) => (dispatch: Dispatch) => {
    dispatch(createTelehealthRxPlan(data));
  };

export const saveTelehealthRxPlan = (
  plan: AdditionalPlan,
  onSave: Function,
  cloneDocuments?: boolean,
  sourcePlanId?: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateTelehealthRxPlanInProgress());
      let response = {} as any;
      if (plan.id) {
        response = await TelehealthRxPlanService.createTelehealthRxPlan(
          plan,
          true
        );
      } else {
        response = await TelehealthRxPlanService.createTelehealthRxPlan(plan);
      }
      const data = response.data;
      dispatch(
        updateTelehealthRxPlanCompleted(JSON.parse(JSON.stringify(data)))
      );
      if (data && cloneDocuments && sourcePlanId) {
        const clonePlanDoc: ClonePlanDocument = {
          employerId: data.employerId,
          sourcePlanId: sourcePlanId,
          targetPlanId: data.id,
          benefitCategory: 'TELEHEALTH',
          benefitKind: plan.benefitKind,
        };
        dispatch(clonePlanDocuments(clonePlanDoc));
      }
      onSave(data.id);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            updateTelehealthRxPlanFailed({
              data: error.response.data,
            } as ErrorDetails)
          );
        }
      }
    }
  };
};

export const getTelehealthRxPlanList = (
  { page, size, sort, query }: PaginationConfig,
  employerId: string,
  planYearId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getTelehealthRxPlansListInProgress());
    try {
      const response = await TelehealthRxPlanService.getTelehealthRxPlanList(
        page,
        size,
        sort,
        query,
        employerId,
        planYearId
      );
      dispatch(getTelehealthRxPlansListCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getTelehealthRxPlansListFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const findPlanById = (planId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPlanByIdStarted());
    try {
      const response = await TelehealthRxPlanService.getTelehealthPlanById(
        planId
      );
      dispatch(getPlanByIdCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(getPlanByIdFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
};
