import axios from 'axios';
import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import PlanYear from 'model/PlanYear';

import ErrorDetails from 'model/ErrorDetails';

import * as EmployerService from 'modules/employers/services/EmployerService';

export interface EmployerPlanYearState {
  inProgress: boolean;
  error: ErrorDetails | null;
  requestType: string;
  planYearCreateError: ErrorDetails | null;
  planYearObj: PlanYear;
  planYearUpdateError: ErrorDetails | null;
}

const initialState: EmployerPlanYearState = {
  inProgress: false,
  error: null,
  requestType: '',
  planYearCreateError: null,
  planYearObj: {} as PlanYear,
  planYearUpdateError: null,
};

const employerPlanYearSlice = createSlice({
  name: 'employerPlanYear',
  initialState,
  reducers: {
    planYearCreateStarted: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.requestType = action.type;
    },
    planYearCreateSucceeded: (state, action: PayloadAction<PlanYear>) => {
      state.inProgress = false;
      state.planYearObj = action.payload;
      state.planYearCreateError = null;
      state.requestType = action.type;
    },
    planYearCreateFailed: (state, action: PayloadAction<any>) => {
      state.inProgress = false;
      state.planYearCreateError = action.payload;
      state.planYearObj = {} as PlanYear;
      state.requestType = action.type;
    },
    clearPlanYearCreation: (state) => {
      state.inProgress = false;
      state.planYearCreateError = null;
      state.planYearUpdateError = null;
      state.planYearObj = {} as PlanYear;
      state.requestType = '';
    },
    planYearUpdateStarted: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.requestType = action.type;
    },
    planYearUpdateSucceeded: (state, action) => {
      state.inProgress = false;
      state.planYearObj = action.payload;
      state.planYearUpdateError = null;
      state.requestType = action.type;
    },
    planYearUpdateFailed: (state, action) => {
      state.inProgress = false;
      state.planYearUpdateError = action.payload;
      state.planYearObj = {} as PlanYear;
      state.requestType = action.type;
    },
    deletePlanYearStarted: (state) => {
      state.inProgress = true;
      state.error = null;
    },
    deletePlanYearCompleted: (state, action: PayloadAction) => {
      state.inProgress = false;
      state.requestType = action.type;
    },
    deletePlanYearFailed: (state, action: PayloadAction<ErrorDetails>) => {
      state.inProgress = false;
      state.requestType = action.type;
      state.error = action.payload;
    },
    clearPlanYearStatus: (state) => {
      state.inProgress = false;
      state.requestType = '';
      state.error = null;
    },
    setCurrentPlanYearStarted: (state) => {
      state.inProgress = true;
    },
    setCurrentPlanYearCompleted: (state, action: PayloadAction) => {
      state.inProgress = false;
      state.requestType = action.type;
    },
    setCurrentPlanYearFailed: (state, action: PayloadAction<ErrorDetails>) => {
      state.inProgress = false;
      state.error = action.payload;
    },
  },
});

export const {
  planYearCreateStarted,
  planYearCreateSucceeded,
  planYearCreateFailed,
  clearPlanYearCreation,
  planYearUpdateStarted,
  planYearUpdateSucceeded,
  planYearUpdateFailed,
  deletePlanYearStarted,
  deletePlanYearCompleted,
  deletePlanYearFailed,
  setCurrentPlanYearStarted,
  setCurrentPlanYearCompleted,
  setCurrentPlanYearFailed,
  clearPlanYearStatus,
} = employerPlanYearSlice.actions;

export default employerPlanYearSlice.reducer;

export const addPlanYear = (data: PlanYear) => {
  return async (dispatch: Dispatch) => {
    dispatch(planYearCreateStarted());
    try {
      const response = await EmployerService.createPlanYear(
        data.employerId,
        data
      );
      dispatch(planYearCreateSucceeded(response.data));
    } catch (error: any) {
      dispatch(planYearCreateFailed(error.response));
    }
  };
};

export const updatePlanYear = (data: PlanYear) => {
  return async (dispatch: Dispatch) => {
    dispatch(planYearUpdateStarted());
    try {
      const response = await EmployerService.updatePlanYear(
        data.employerId,
        data.id,
        data
      );
      dispatch(planYearUpdateSucceeded(response.status));
    } catch (error: any) {
      dispatch(planYearUpdateFailed(error.response));
    }
  };
};

export const deletePlanYear = (data: PlanYear) => {
  return async (dispatch: Dispatch) => {
    dispatch(deletePlanYearStarted());
    try {
      await EmployerService.deletePlanYear(data.employerId, data.id);
      dispatch(deletePlanYearCompleted());
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          deletePlanYearFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const setCurrentPlanYear = (data: PlanYear) => {
  return async (dispatch: Dispatch) => {
    dispatch(setCurrentPlanYearStarted());
    try {
      await EmployerService.setCurrentPlanYear(data.employerId, data.id);
      dispatch(setCurrentPlanYearCompleted());
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          setCurrentPlanYearFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};
