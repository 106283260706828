import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { CONFIRM_DELETE_TAG } from 'modules/rfp/constants/RfpConstants';
import { deleteTag } from 'modules/rfp/services/RfpTagService';

type Props = {
  confirmDeleteTag: boolean;
  setConfirmDeleteTag: (value: boolean) => void;
  manageTagsClicked: Function;
  setEditSingleTagModal: Function;
  editSingleTagModal: any;
  pingTrainingStatus: any;
};

const ConfirmDeleteTagModal: FC<Props> = (props: Props) => {
  const { brokerId } = useParams();
  const {
    confirmDeleteTag,
    setConfirmDeleteTag,
    manageTagsClicked,
    setEditSingleTagModal,
    editSingleTagModal,
    pingTrainingStatus,
  } = props;
  return (
    <div>
      <ConfirmationDialog
        centered
        destroyOnClose
        onCancel={() => {
          setConfirmDeleteTag(false);
        }}
        closeModal={() => {
          setConfirmDeleteTag(false);
        }}
        visible={confirmDeleteTag}
        title="Delete Tag"
        confirmText="Yes - Delete Tag"
        isCancelLink
        cancelText="Cancel"
        confirmBtnFullWidth
        onConfirm={() => {
          deleteTag(brokerId!, editSingleTagModal.item?.value).then(() => {
            manageTagsClicked();
            setConfirmDeleteTag(false);
            setEditSingleTagModal({
              show: false,
              item: [],
            });
            pingTrainingStatus();
          });
        }}
      >
        <p>{CONFIRM_DELETE_TAG}</p>
      </ConfirmationDialog>
    </div>
  );
};

export default ConfirmDeleteTagModal;
