import { ChangeEvent, FC, useEffect, useState } from 'react';
import { isUndefined } from 'lodash';
import { useParams } from 'react-router-dom';
import { Button, Menu, Row } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { useAppSelector } from 'hooks/redux';

import {
  useDeleteFileMutation,
  useFileHistoryQuery,
  useLazySendNotificationOnEnrollmentFileQuery,
  useGetEmployerPlanYearsListQuery,
} from 'modules/idCards/slices/idCardApiSlice';

import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { baseApi } from 'util/apiUtil';
import DataTable from 'components/DataTable/DataTable';
import TablePagination from 'model/TablePagination';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ImportFile from 'modules/idCards/pages/ImportFile/ImportFile';
import SearchBar from 'components/SearchBar/SearchBar';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import { DataColumn } from 'components/DataTable/DataColumn';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { showNotification } from 'modules/idCards/components/Notification/Notification';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import { EMPLOYER_MEMBER_RESTRICTED } from 'constants/commonConstants';

import styles from './fileHistory.module.less';

type ImportFileHistoryProps = {
  onClose: Function;
};

const ImportFileHistory: FC<ImportFileHistoryProps> = ({ onClose }) => {
  const params = useParams();
  const { employerId = '' } = params;

  const employer = useAppSelector((state: any) => state.layout.employer?.name);

  const [selectedRecordId, setSelectedRecordId] = useState<string>();
  const [importModalVisible, setImportModalVisible] = useState<boolean>(false);
  const [deleteRecordModalVisible, setDeleteRecordModalVisible] =
    useState<boolean>(false);
  const [modalCloseConfirmation, setModalCloseConfirmation] =
    useState<boolean>(false);
  const [isNotificationModalVisible, setIsNotificationModalVisible] =
    useState<boolean>(false);
  const [applicableRecord, setApplicableRecord] = useState<string>('');
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'createdTs',
      field: 'createdTs',
      order: 'descend',
    } as SorterResult<any>,
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: '',
    },
    filters: {},
  });
  const [searchText, setSearchText] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [applicablePlanYearId, setApplicablePlanYearId] = useState<string>('');

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);

  const { columnKey, order } = (paginationConfig.sorterInfo as any) || {};
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};

  const {
    data: importHistoryData,
    isFetching: importHistoryIsLoading,
    refetch: importHistoryRefetch,
  } = useFileHistoryQuery(
    {
      employerId: employerId ?? '',
      size: paginationConfig.filterInfo.limit,
      page: paginationConfig.paginationIndex,
      searchKey: paginationConfig.filterInfo.searchText,
      sort:
        order === undefined
          ? ''
          : order === 'descend'
          ? `-${columnKey}`
          : columnKey,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [
    deleteFIle,
    {
      isSuccess: deleteIsSuccess,
      reset: deleteReset,
      isLoading: deleteIsLoading,
    },
  ] = useDeleteFileMutation();

  const { data: planYearsList } = useGetEmployerPlanYearsListQuery({
    employerId,
  });

  const [sendEnrollmentNotification, { isError: isNotificationError }] =
    useLazySendNotificationOnEnrollmentFileQuery();
  const handleDownloadFile = (recordId: string) => {
    window.location.href = `${baseApi}/v1/employers/enrolment-file/${recordId}`;
  };

  const handleDeleteFile = (recordId: string) => {
    setSelectedRecordId(recordId);
    setDeleteRecordModalVisible(true);
  };

  const handleDeleteFileConfirm = () => {
    if (selectedRecordId !== undefined) {
      deleteFIle({ recordId: selectedRecordId });
      setSelectedRecordId(undefined);
      setDeleteRecordModalVisible(false);
    }
  };

  const isCurrentPlanYear = (planYearId: string) => {
    const planYear = planYearsList?.find(
      (planYear) => planYear.id === planYearId
    );
    if (!isUndefined(planYear)) {
      return planYear.current;
    } else {
      return false;
    }
  };

  const handleNotificationConfirm = (planYearId: string) => {
    const currentPlanYear = isCurrentPlanYear(planYearId);

    sendEnrollmentNotification({
      recordId: applicableRecord,
      isCurrentPlanYear: currentPlanYear ?? false,
    });
    handleNotificationCancel();
  };

  const handleNotificationCancel = () => {
    setIsNotificationModalVisible(false);
    setApplicableRecord('');
  };

  const handleDeleteFileCancel = () => {
    setSelectedRecordId(undefined);
    setDeleteRecordModalVisible(false);
  };

  const formatFilename = (fileName: string) =>
    fileName.substring(0, fileName.lastIndexOf('.')) || fileName;

  const isOpsAdmin = () => {
    return appBootInfo?.isOpsAdmin;
  };

  const securedDeleteButton = usePermitByUserType(
    <Menu.Item key="delete" onClick={() => handleDeleteFile(selectedRecordId!)}>
      Delete File
    </Menu.Item>,
    EMPLOYER_MEMBER_RESTRICTED
  );

  const selectOptions = (recordId: string, row: any) => {
    return (
      <Menu>
        {row?.current && (
          <Menu.Item
            key="sendNotification"
            onClick={() => {
              setApplicableRecord(recordId);
              setApplicablePlanYearId(row.planYearId);
              setIsNotificationModalVisible(true);
            }}
          >
            Send Notification
          </Menu.Item>
        )}
        {!isOpsAdmin() && (
          <Menu.Item
            key="download"
            onClick={() => handleDownloadFile(recordId)}
          >
            Download File
          </Menu.Item>
        )}
        {securedDeleteButton}
      </Menu>
    );
  };

  const fileHistoryTableColumns: DataColumn[] = [
    {
      title: 'FILE NAME',
      dataIndex: 'fileName',
      key: 'fileName',
      width: '20%',
      render: (data: string, { planYearDeleted = false }: any) => {
        return (
          <OverflowPopover maxWidth={'196px'}>
            <span
              className={`${styles.fileName} ${
                planYearDeleted && styles.greyText
              }`}
            >
              {data ? formatFilename(data) : '-'}
            </span>
          </OverflowPopover>
        );
      },
    },
    {
      title: 'PLAN YEAR',
      dataIndex: 'planYearName',
      key: 'planYearName',
      width: '20%',
      render: (data: string | null, { planYearDeleted = false }: any) => {
        return (
          <OverflowPopover maxWidth={'196px'}>
            <span
              className={`${styles.fileName} ${
                planYearDeleted && styles.greyText
              }`}
            >
              {data ?? 'Plan Year Deleted'}
            </span>
          </OverflowPopover>
        );
      },
    },
    {
      title: 'DATE UPLOADED',
      dataIndex: 'uploadedAt',
      key: 'uploadedAt',
      width: '50%',
      align: 'left',
      render: (data: string, row) => {
        return (
          <div>
            <div
              className={`${styles.date} ${
                row.planYearDeleted && styles.greyText
              }`}
            >
              {dayjs(data).format('MMMM D, YYYY h:mm A')}
            </div>
            <div className={styles.uploadedBy}>by&nbsp;{row?.uploadedBy}</div>
          </div>
        );
      },
    },
    {
      title: 'ACTIONS',
      dataIndex: 'recordId',
      key: 'recordId',
      width: '10%',
      align: 'left',
      render: (data: string, row) => {
        return (
          <div className={styles.dropdownWrapper}>
            <SelectDropdown overlay={selectOptions(data, row)} />
          </div>
        );
      },
    },
  ];

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setSearchText && setSearchText(searchText);
    const config = {
      ...paginationConfig,
      paginationIndex: 1,
      filterInfo: { ...paginationConfig.filterInfo, searchText },
    };
    setPaginationConfig(config);
  };

  const handleCancel = () => {
    if (selectedFile === null) {
      setImportModalVisible(false);
    } else {
      setModalCloseConfirmation(true);
    }
  };

  const getData = (dataFilters: TablePagination) => {
    const { sorterInfo, paginationIndex, filterInfo, filters } = dataFilters;
    if (sorterInfo) {
      const paginationConfigData = {
        sorterInfo,
        paginationIndex,
        filterInfo: {
          limit: filterInfo.limit,
          offset: filterInfo.offset,
          searchText,
        },
        filters,
      };
      setPaginationConfig(paginationConfigData);
    } else {
      const paginationConfigData = {
        sorterInfo: {
          columnKey: 'createdTs',
          field: 'createdTs',
          order: 'descend',
        } as SorterResult<any>,
        paginationIndex,
        filterInfo: {
          limit: filterInfo.limit,
          offset: filterInfo.offset,
          searchText,
        },
        filters,
      } as TablePagination;
      setPaginationConfig(paginationConfigData);
    }
  };

  const reset = () => {
    setSearchText('');
    setPaginationConfig({
      sorterInfo: {
        columnKey: 'createdTs',
        field: 'createdTs',
        order: 'descend',
      } as SorterResult<any>,
      paginationIndex: 1,
      filterInfo: {
        limit: 10,
        offset: 0,
        searchText: '',
      },
      filters: {},
    });
  };

  useEffect(() => {
    if (!importModalVisible) importHistoryRefetch();
    else reset();
  }, [importModalVisible, importHistoryRefetch]);

  useEffect(() => {
    if (deleteIsSuccess) {
      deleteReset();
      importHistoryRefetch();
    }
  }, [deleteIsSuccess, deleteReset, importHistoryRefetch]);

  useEffect(() => {
    if (isNotificationError) {
      showNotification({
        type: 'error',
        message: 'Failed',
        description: 'Failed to send notification',
      });
    }
  }, [isNotificationError]);
  const importIdCardFileButton = (
    <LinkButton
      containerClassName={styles.importIdCardButton}
      onClick={() => setImportModalVisible(true)}
    >
      {' '}
      + Import ID Card File{' '}
    </LinkButton>
  );

  const secureImportIdCardFileButton = usePermitByUserType(
    importIdCardFileButton,
    EMPLOYER_MEMBER_RESTRICTED
  );
  return (
    <div className={styles.fileHistoryWrapper}>
      <Row justify={'center'} className={styles.employerName}>
        {employer}
      </Row>
      <Row justify={'space-between'} className={styles.subTitleWrapper}>
        <div className={styles.subTitle}>ID Card Import History</div>
        {secureImportIdCardFileButton}
      </Row>
      <div className={styles.searchBar}>
        <SearchBar
          value={searchText}
          placeholder="Search"
          onChange={handleSearch}
          isIconEndAligned
        />
      </div>
      <DataTable
        data={importHistoryData?.content}
        columns={fileHistoryTableColumns}
        pagination={true}
        getData={getData}
        total={importHistoryData?.totalElements ?? 0}
        pageSize={limit}
        loading={importHistoryIsLoading || deleteIsLoading}
      />
      <Row justify={'center'}>
        <Button className={styles.closeButton} onClick={() => onClose(false)}>
          Close
        </Button>
      </Row>
      <FullScreenModal
        title={'Import ID Card File'}
        visible={importModalVisible}
        onCancel={handleCancel}
        footer={false}
        confirmTitle={false}
        destroyOnClose={true}
      >
        <ImportFile
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          onClose={() => setImportModalVisible(false)}
          onCancel={handleCancel}
        />
      </FullScreenModal>
      <ConfirmationDialog
        confirmText="Yes - close and do not save"
        onConfirm={() => {
          setSelectedFile(null);
          setImportModalVisible(false);
          setModalCloseConfirmation(false);
        }}
        visible={modalCloseConfirmation}
        onCancel={() => setModalCloseConfirmation(false)}
        closeModal={() => setModalCloseConfirmation(false)}
        title="Are you sure you want to close?"
        cancelText="Cancel"
      >
        <p>
          <div>
            If you leave now, you will lose any unsaved data. Do you wish to
            continue?
          </div>
        </p>
      </ConfirmationDialog>
      <ConfirmationDialog
        confirmText="Yes - Delete ID Card File"
        onConfirm={handleDeleteFileConfirm}
        visible={deleteRecordModalVisible}
        onCancel={handleDeleteFileCancel}
        closeModal={handleDeleteFileCancel}
        title="Delete ID Card File"
        cancelText="Cancel"
        confirmBtnFullWidth
        isCancelLink
        centered
      >
        <p>
          <div>
            Are you sure you want to delete this ID Card file? This action
            cannot be undone
          </div>
        </p>
      </ConfirmationDialog>
      <ConfirmationDialog
        confirmText="Send Notification"
        onConfirm={() => handleNotificationConfirm(applicablePlanYearId)}
        visible={isNotificationModalVisible}
        closeModal={handleNotificationCancel}
        title="Send Notification"
        cancelText="Cancel"
        confirmBtnFullWidth
        centered
        isCancelLink
      >
        <p>
          <div>
            Do you want to send a notification to employees with new member
            ID&apos;s from this file import?
          </div>
        </p>
      </ConfirmationDialog>
    </div>
  );
};

export default ImportFileHistory;
