import React, { FC, useEffect, useState, useRef } from 'react';

import { isEmpty } from 'lodash';
import { useAppSelector } from 'hooks/redux';
import UploadAndLearn from './RfpForm/UploadAndLearn/UploadAndLearn';
import ReviewResponses from './RfpForm/ReviewResponse/ReviewResponses';
import SaveExport from './RfpForm/SaveAndExport/SaveExport';
import ProvideContext from './RfpForm/ContextManagement/ProvideContext';

import RfpStepper from './RfpStepper/RfpStepper';

import styles from './rfpModal.module.less';

type Props = {
  closeModal: (isForce?: boolean) => void;
  currentStep: number;
  onNextStep: () => void;
  changeCurrentStep: (step: number) => void;
  isModalOpen: boolean;
  setIsModalOpen?: (isModalOpen: boolean) => void;
  rfpName?: string;
  refetch?: () => void;
  setRfpSavedAlert?: (value: boolean) => void;
  isEditMode?: boolean;
};
const AddRfp: FC<Props> = (props) => {
  const {
    currentStep,
    onNextStep,
    changeCurrentStep,
    closeModal,
    isModalOpen,
    setIsModalOpen,
    rfpName,
    refetch,
    isEditMode,
    setRfpSavedAlert,
  } = props;
  const questionAnswered = useRef(false);
  const questions = useAppSelector((state) => state.rfp.rfpQuestions);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className={styles.rfpModalWrapper}>
            <UploadAndLearn
              isEditMode={isEditMode}
              refetch={refetch}
              nextStep={onNextStep}
              closeModal={closeModal}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
        );
      case 1:
        return (
          <div className={styles.rfpContextWrapper}>
            <ProvideContext
              setIsModalOpen={setIsModalOpen}
              nextStep={onNextStep}
              closeModal={closeModal}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              isModalOpen={isModalOpen}
            />
          </div>
        );
      case 2:
        return (
          <div className={styles.rfpModalWrapper}>
            <ReviewResponses nextStep={onNextStep} closeModal={closeModal} />
          </div>
        );
      case 3:
        return (
          <div className={styles.rfpModalWrapper}>
            <SaveExport
              refetch={refetch}
              rfpName={rfpName}
              closeModal={closeModal}
              setRfpSavedAlert={setRfpSavedAlert}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
        );

      default:
        return;
    }
  };

  useEffect(() => {
    if (
      !questionAnswered.current &&
      questions?.some((item) => !isEmpty(item?.answer))
    ) {
      onNextStep();
      questionAnswered.current = true;
    }
  }, [questions, onNextStep]);

  return (
    <div className={styles.rfpWrapper}>
      <div className={styles.stepperWrapper}>
        <RfpStepper
          isEditMode={isEditMode}
          headers={[
            'Upload',
            'Provide Context',
            'Review Responses',
            'Save & Export',
          ]}
          activeStep={currentStep}
          changeCurrentStep={changeCurrentStep}
          renderStepContent={renderStepContent}
        />
      </div>
    </div>
  );
};

export default AddRfp;
