import { FC, ReactNode } from 'react';

import { Popconfirm, PopconfirmProps } from 'antd';

import styles from './confirmationModalAi.module.less';

interface ConfirmModalProps extends PopconfirmProps {
  confirmModalTrigger: ReactNode;
}

const ConfirmationModalAi: FC<ConfirmModalProps> = (props) => {
  const { icon, title, confirmModalTrigger, okText, ...rest } = props;

  return (
    <Popconfirm
      title={title}
      okText={okText}
      icon={icon}
      overlayClassName={styles.confirmModalWrapper}
      {...rest}
    >
      {confirmModalTrigger}
    </Popconfirm>
  );
};

export default ConfirmationModalAi;
