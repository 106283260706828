export const ASSISTANT_INITIALIZE_TEXT = 'Talk with AI Assistant';

export const ASSISTANT_INITIALIZE_WARNING =
  'AI Assistant can make mistakes. Consider checking important information.';

export const ASSISTANT_COMMON_ERROR = `Sorry, I'm unable to retrieve the information you're looking for at the moment. Please try again later or refine your query.`;

export const TYPING_DELAY = 30;

export const SCROLLING_DELAY = 5000;

export const GENERAL_ASSISTANT_TEXT =
  'Have a general question? Ask me anything.';

export const COMPLIANCE_ASSISTANT_TEXT =
  'What compliance questions do you have?';

export const PLAN_ASSISTANT_TEXT =
  'What would you like to know about these plans?';

export const SELECTION_PLAN_ASSISTANCE_TEXT =
  'Use our AI Assistant to inquire about an employer’s specific benefits plan.';

export const SELECTION_GENERAL_ASSISTANT_TEXT =
  'Have a general question outside of specific plans? Ask anything.';

export const SELECTION_COMPLIANCE_ASSISTANT_TEXT =
  'Compliance can be confusing. Our AI Assistant is trained to provide compliance help.';

export const COMPLIANCE_HEADER_TEXT = 'Compliance';

export const GENERAL_HEADER_TEXT = 'General Questions';

export const PLAN_HEADER_TEXT = 'Plan Assistance';

export const HELP_HEADER_TEXT = 'How can I help you today?';
export const NEW_CHAT_NAV_CONSTANT = 'newChat';

export const CHAT_HISTORY_DURATION = {
  TODAY: 'TODAY',
  LAST_WEEK: 'LAST_WEEK',
  LAST_30_DAYS: 'LAST_30_DAYS',
};

export const CHAT_HISTORY_DURATION_LABEL = {
  [CHAT_HISTORY_DURATION.TODAY]: 'Today',
  [CHAT_HISTORY_DURATION.LAST_WEEK]: 'Last Week',
  [CHAT_HISTORY_DURATION.LAST_30_DAYS]: 'Last 30 Days',
};

export const ASSISTANT_FAILED_WARNING = `This chat cannot be continued as either the document or employer has been removed from the system. Please `;

export const ASSISTANT_FAILED_WARNING_LINK = 'start a new chat';

export const ASSISTANT_FAILED_WARNING_SUB = ` to have your questions answered.`;

export const EMPLOYER_CHANGE_TOOLTIP = `Start a new chat to change the employer`;

export const DOCUMENT_NOT_FOUND_ERROR = 'document.not.found';
