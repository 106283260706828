import { useState, useCallback, useEffect } from 'react';
import { useStompClient } from 'react-stomp-hooks';
import { Button, Col, Form, FormProps, Input, Popover, Row, Spin } from 'antd';
import { isEmpty, last } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getChatHistory } from 'layout/slices/layoutSlice';
import { Message } from 'modules/assistant/models/Message';
import { showNotification } from 'components/Notification/Notification';
import {
  ConversationServiceInterface,
  publishingURL,
} from 'modules/assistant/services/ConversationService';
import {
  continueConversation,
  downloadDocument,
  initialConversation,
  initializeConversation,
} from 'modules/assistant/slices/conversationSlice';
import { ReactComponent as SubmitIcon } from 'assets/images/assistant-submit.svg';
import { ReactComponent as InfoIcon } from 'assets/images/more-info-black.svg';
import {
  ASSISTANT_INITIALIZE_TEXT,
  DOCUMENT_NOT_FOUND_ERROR,
  EMPLOYER_CHANGE_TOOLTIP,
  NEW_CHAT_NAV_CONSTANT,
} from 'modules/assistant/constants/constants';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import { ReactComponent as PopoutIcon } from 'assets/images/popout-icon-blue.svg';
import { isNullOrUndefined } from 'modules/plans/utils';
import PromptType from 'modules/assistant/enums/PromptRenderType';

import styles from './assistantInput.module.less';

const { TextArea } = Input;

type FormValues = {
  input?: string;
};

type AssistantInputProps = {
  scrollRef: React.RefObject<HTMLDivElement>;
};

const AssistantInput = ({ scrollRef }: AssistantInputProps) => {
  const stompClient = useStompClient();
  const {
    conversationId,
    promptType,
    messages = [],
    documentId,
    documentName,
    employerName,
    downloadDocument: {
      status: { isError: isDownloadDocumentError },
    },
    error,
  } = useAppSelector((state) => state.assistant.current) ?? initialConversation;
  const lastMessage = last(messages ?? []) as Message;

  const isSubmitDisabled =
    (lastMessage?.error || lastMessage?.isPending || lastMessage?.animate) ??
    false;

  const lastMessageIsPending =
    !lastMessage?.error && (lastMessage?.isPending || lastMessage?.animate);

  const documentNotFound = error === DOCUMENT_NOT_FOUND_ERROR;

  const dispatch = useAppDispatch();
  const [inputData, setInputData] = useState<FormValues>({ input: '' });
  const [form] = Form.useForm();

  const publishMessage = (message: string, client: any): void => {
    if (!client) {
      console.error("Stomp client undefined. Can't send message.");
      return;
    }
    try {
      client.publish({
        destination: publishingURL(conversationId!),
        body: JSON.stringify({ conversationId, message }),
      });
    } catch (error) {
      console.error('Error while sending message:', error);
    }
  };

  const handleFormSubmit: FormProps<FormValues>['onFinish'] = (values) => {
    const { input } = values;
    if (isEmpty(input) || isSubmitDisabled) return;

    const trimmedInput = input!.trim();

    if (isEmpty(conversationId) || conversationId === NEW_CHAT_NAV_CONSTANT) {
      dispatch(
        initializeConversation({
          type: promptType,
          question: trimmedInput,
          documentReference: documentId,
          refreshHistory,
        } as ConversationServiceInterface)
      );
    } else {
      dispatch(continueConversation({ question: trimmedInput }));
      publishMessage(trimmedInput, stompClient);
    }
    scrollToBottom({ behavior: 'smooth' });
    form.resetFields();
  };

  const refreshHistory = () => {
    dispatch(getChatHistory());
  };

  const scrollToBottom = useCallback(
    (arg) => {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView(arg);
      }
    },
    [scrollRef]
  );

  const handleFormSubmitFailed: FormProps<FormValues>['onFinishFailed'] = (
    errorInfo
  ) => {
    console.log('Failed:', errorInfo);
  };

  const handlePressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.shiftKey) {
      return;
    }
    e.preventDefault();
    form.submit();
  };

  const handleValuesChange = async (
    changedValues: any,
    allValues: FormValues
  ) => {
    setInputData({ ...allValues });
  };

  const handleDocumentClick = async () => {
    if (documentNotFound) return;
    dispatch(downloadDocument({ documentId: documentId! }));
  };

  useEffect(() => {
    if (isDownloadDocumentError) {
      showNotification({
        type: 'error',
        message: 'Download failed. Please try again.',
      });
    }
  }, [isDownloadDocumentError]);

  useEffect(() => {
    if (conversationId) {
      form.resetFields();
    }
    // disabling because the form isn't a direct dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  return (
    <Row align="middle" justify="center">
      <Col
        xs={{ span: 24 }}
        sm={{ span: 18 }}
        md={{ span: 14 }}
        lg={{ span: 14 }}
        xl={{ span: 14 }}
      >
        {promptType == PromptType.PLANS && !isNullOrUndefined(documentId) && (
          <Row gutter={24} align="middle" className={styles.inputHeader}>
            <Col>
              <b>Employer: </b>
              <Popover
                content={EMPLOYER_CHANGE_TOOLTIP}
                placement="top"
                overlayClassName="popover"
              >
                <b className={styles.disabledText}>{employerName}</b>
              </Popover>
            </Col>
            <Col>
              <Row align="middle" gutter={12}>
                <Col>
                  <b>{`Document Name:`}</b>
                </Col>
                <Col>
                  <Row
                    align="middle"
                    justify="center"
                    gutter={6}
                    onClick={handleDocumentClick}
                  >
                    <Col
                      className={`${
                        documentNotFound
                          ? styles.strikeThrough
                          : styles.linkText
                      }`}
                    >
                      {documentName}
                    </Col>
                    <Col>
                      {documentNotFound ? (
                        <PlanyearPopover content="This document has been removed in the system">
                          <InfoIcon />
                        </PlanyearPopover>
                      ) : (
                        <PopoutIcon />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <div
          className={`${styles.inputWrapper} ${
            isSubmitDisabled && styles.disabled
          }`}
        >
          {isSubmitDisabled && <div className={styles.disabledWrapper} />}
          <Form
            className={styles.inputContainer}
            form={form}
            onFinish={handleFormSubmit}
            onFinishFailed={handleFormSubmitFailed}
            onValuesChange={handleValuesChange}
          >
            <Row gutter={16}>
              <Col xs={20} sm={21} md={21} lg={21} xl={22}>
                <Form.Item<FormValues> name="input">
                  <TextArea
                    className={styles.assistantInput}
                    placeholder={ASSISTANT_INITIALIZE_TEXT}
                    onPressEnter={handlePressEnter}
                    autoSize={{ maxRows: 10 }}
                    disabled={isSubmitDisabled}
                    autoFocus
                  />
                </Form.Item>
              </Col>
              <Col
                xs={4}
                sm={3}
                md={3}
                lg={3}
                xl={2}
                className={styles.flexCenterContainer}
              >
                <Button
                  className={styles.assistantSubmitButton}
                  type="primary"
                  htmlType="submit"
                  disabled={isEmpty(inputData?.input) || isSubmitDisabled}
                  icon={
                    lastMessageIsPending ? (
                      <Spin size="small" />
                    ) : (
                      <SubmitIcon />
                    )
                  }
                />
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default AssistantInput;
