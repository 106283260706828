import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';
import { Col, Form, FormInstance, Input, Row, Select } from 'antd';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import InputForm from 'components/InputForm/InputForm';
import SelectOptions from 'components/SelectOptions/SelectOptions';
import LabelWithTooltip from 'components/LabelWithTooltip/LabelWithTooltip';
import MultiSelectDropdownSmall from 'components/MultiSelectDropdownSmall/MultiSelectDropdownSmall';
import BenefitClassMultiSelect from 'components/BenefitClassMultiSelect/BenefitClassMultiSelect';
import { EMPTY_MESSAGE } from 'modules/brokers/constants/brokerConstants';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useIsAuthorizedUserRole } from 'hooks/useIsAuthorizedUserRole';
import { useNavContext } from 'hooks/useNavContext';
import { getPlanYearRangeWithCurrent } from 'util/commonUtil';
import PlanYear from 'model/PlanYear';
import Carrier from 'model/Carrier';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import {
  ADD_NEW_CARRIER_MODAL_CONSTANTS,
  BenefitCategory,
  IndividualSubTypes,
  addNewCarrierModalDescription,
  maxPlanNameSize,
} from 'constants/commonConstants';
import BenefitCarrier from 'model/BenefitCarrier';
import MultiSelectGroup from 'components/MultiSelectGroup/MultiSelectGroup';
import {
  BasicInfoFields,
  BasicPlans,
  FUNDING_TYPES_LIST,
  TaxAdvantagedAccountPlanType,
  VoluntaryPlans,
} from 'modules/plans/constants';
import { LTD, STD } from 'modules/renewals/constants/renewalsConstants';
import { setChangeService } from 'modules/plans/slices/lifePlanSlice';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import FundingType from 'modules/plans/enums/FundingType';
import { useLazyGetSFCValuesQuery } from 'modules/plans/slices/planSllice';
import { buildReferencePlanOptions, formatSFCValue } from 'modules/plans/utils';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import { useAddNewCarrierState } from 'hooks/useAddNewCarrierState';
import SelectAddMoreButton from 'components/buttons/SelectAddMoreButton/SelectAddMoreButton';
import AddNewCarrierModal from 'components/AddNewCarrierModal/AddNewCarrierModal';
import ConfirmationWithThreeButtons from 'components/ConfirmationWithThreeButtons/ConfirmationWithThreeButtons';
import CarrierType from 'modules/carriers/enums/CarrierType';
import { loginTypes } from 'constants/authConstants';
import styles from './planBasicForm.module.less';

type PlanBasicFormProps = {
  setFormData: Function;
  setRequiredFieldError: Function;
  formData: any;
  form: FormInstance;
  carriersList: BenefitCarrier[];
  isPlanUpdateMode?: boolean;
  benefitCategory?: any;
  defaultPlanName?: string;
  isSavingsPlan?: boolean;
  associateMedicalPlansElements?: {
    onMedicalPlanCheckboxSelection: Function;
    selectedItemValues: string;
    showAll: boolean;
    options: string[];
    tooltipContent: ReactNode;
    onResetSelection: () => void;
  };
  showWarning?: boolean;
  dbgPlanYear?: string;
  isDBGPlan?: boolean;
  isModalVisible?: boolean;
  isCarrierRequired?: boolean;
  showPlanYearWarning?: boolean;
  enableFundingType?: boolean;
  reviewNewPlanYear?: string;
  isReviewHighlight?: boolean;
  reviewBenefitClasses?: string[];
  planType?: string;
  hraPlanList?: any;
  onChangeReferencePlans?: (value: any) => void;
  selectedReferencePlans?: string[];
  currentPlanId?: string;
  isHightlightCarrier?: boolean;
  isReview?: boolean;
  isTitleVisible?: boolean;
  isSingleColumn?: boolean;
  lifePlanType?: string;
};

type MultiSelect = {
  groups: string[];
};

const PlanBasicForm = forwardRef((props: PlanBasicFormProps, ref) => {
  const {
    isTitleVisible = true,
    setFormData,
    formData,
    form,
    setRequiredFieldError,
    carriersList,
    isPlanUpdateMode,
    defaultPlanName,
    isSavingsPlan,
    associateMedicalPlansElements,
    showWarning,
    dbgPlanYear,
    isDBGPlan = false,
    isModalVisible,
    isCarrierRequired = true,
    showPlanYearWarning,
    enableFundingType,
    reviewNewPlanYear,
    isReviewHighlight = false,
    reviewBenefitClasses,
    planType,
    hraPlanList,
    currentPlanId,
    onChangeReferencePlans,
    isHightlightCarrier = false,
    isReview = false,
    benefitCategory,
    isSingleColumn,
    lifePlanType,
  } = props;
  const [benefitClassesList, setBenefitClassesList] = useState<string[]>([]);
  const [selectedBenefitClasses, setSelectedBenefitClasses] = useState<
    string[] | null
  >(null);
  const [multiSelect, setMultiSelect] = useState<MultiSelect>({
    groups: [],
  });
  const hraSelectRef = useRef<any>(null);
  const [getSFCValues, { data: sfcData }] = useLazyGetSFCValuesQuery();

  const defaultHRAValues = [
    ...(formData?.selectedMedicalPlans || []),
    ...(formData?.selectedDentalPlans || []),
    ...(formData?.selectedVisionPlans || []),
  ];

  const dispatch = useAppDispatch();
  const { employerId, brokerId } = useNavContext();
  const { planYearsList, inProgress } = useAppSelector(
    (state) => state.employer.employer
  );
  const { inProgress: hraPlanListLoading } = useAppSelector(
    (state) => state.plan.dbg.hraPlans
  );
  const { carriersListLoading } = useAppSelector(
    (state) => state.plan.planBasicInfo
  );
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);

  const trimGroupIdInput = (event: any) => {
    const { value } = event.target;
    form.setFieldsValue({ groupId: value.trim() });
  };
  const empInfo = useAppSelector((state) => state.layout?.employer);
  const isErAdmin: boolean = useIsAuthorizedUserRole([loginTypes.erAdmin]);

  const {
    carrierCreateTypeConfirmation,
    setCarrierCreateTypeConfirmation,
    openCarrierModal,
    setOpenCarrierModal,
    setNewCarrierId,
  } = useAddNewCarrierState();

  const handleSetCarrierToForm = (carrier: {
    label: string;
    value: string;
  }): void => {
    const { value } = carrier;
    setNewCarrierId(value);
    setFormData({ ...formData, benefitCarrier: value });
    form.setFieldsValue({ benefitCarrier: value });
  };
  useEffect(() => {
    if (reviewNewPlanYear !== undefined) {
      setBenefitClassesList(reviewBenefitClasses ?? []);
    }
    // eslint-disable-next-line
  }, [reviewNewPlanYear]);

  useEffect(() => {
    if (enableFundingType) {
      getSFCValues({
        benefitKind: BasicPlans.STD.value,
        employerId: employerId,
        planYearId: formData?.planYear,
        carrierId: formData.benefitCarrier,
        currentTs: new Date().getTime(),
      });
    }
  }, [
    getSFCValues,
    employerId,
    formData.planYear,
    formData.benefitCarrier,
    enableFundingType,
  ]);

  useEffect(() => {
    if (!isReview) {
      form.setFieldsValue({
        ...formData,
        administrationFee: formatSFCValue(sfcData?.administrationFee),
      });
    }
    // Don't expect call hook everytime form change
    // eslint-disable-next-line
  }, [sfcData]);

  const onPlanYearSelect = useCallback(
    (value: string) => {
      const planYear = planYearsList.find((t) => t.id === value);
      if (planYear) {
        setFormData((prevData: any) => ({
          ...prevData,
          planYear: planYear.id,
        }));
        if (planYear.id && selectedBenefitClasses) {
          setRequiredFieldError(false);
        }
        setBenefitClassesList(planYear.benefitGroups);
        setSelectedBenefitClasses(null);
        const benefitClasses =
          planYear.benefitGroups.length === 1 ? planYear.benefitGroups : [];
        form.setFieldsValue({
          planYear: planYear.id,
          benefitClass: benefitClasses,
        });

        setMultiSelect({
          groups: benefitClasses,
        });
        form.setFieldsValue({
          benefitClass: benefitClasses,
        });
        setFormData((prevData: any) => ({
          ...prevData,
          benefitClass: benefitClasses,
        }));
        if (isSavingsPlan && associateMedicalPlansElements) {
          associateMedicalPlansElements.onResetSelection();
        }
      }
    },
    [
      planYearsList,
      form,
      selectedBenefitClasses,
      setRequiredFieldError,
      setFormData,
      isSavingsPlan,
      associateMedicalPlansElements,
    ]
  );

  useEffect(() => {
    if (employerId && !reviewNewPlanYear) {
      dispatch(getPlanYears(employerId));
    }
  }, [dispatch, employerId, reviewNewPlanYear]);

  useEffect(() => {
    if (formData.benefitClass) {
      setMultiSelect({ groups: formData.benefitClass });
    }
  }, [formData.benefitClass]);

  useEffect(() => {
    if (formData.planYear && !isEmpty(planYearsList)) {
      const planYear = planYearsList.find((t) => t.id === formData.planYear);
      setBenefitClassesList(planYear?.benefitGroups || []);
    }
  }, [formData.planYear, planYearsList]);

  useImperativeHandle(ref, () => ({
    resetForm() {
      form.resetFields();
      setMultiSelect({ groups: [] });
    },
    validate: async () => ({
      isComplete: getIsComplete(),
      isValid: await getIsValid(),
    }),
  }));

  const getIsComplete = () => {
    const {
      benefitCarrier,
      planName,
      planYear,
      fundingType,
      administrationFee,
      groupId,
      benefitClass,
      selectedMedicalPlans,
    } = formData;

    const fieldsToCheck = [
      planName,
      planYear,
      benefitCarrier,
      benefitClass,
      groupId,
    ];

    if (BenefitCategory.LIFE.value === benefitCategory) {
      fieldsToCheck.push(formData?.[BasicInfoFields.ENROLLMENT]);
    }

    if (enableFundingType) {
      fieldsToCheck.push(fundingType);
      if (fundingType === FundingType.SELF_FUNDED) {
        fieldsToCheck.push(administrationFee);
      }
    }

    if (isShowReferencePlan) {
      fieldsToCheck.push(selectedMedicalPlans);
    }

    return !fieldsToCheck.some(isEmpty);
  };

  const getIsValid = async () => {
    return await form.validateFields([
      'planName',
      'planYear',
      'benefitCarrier',
      'benefitClass',
      'fundingType',
    ]);
  };

  const setDefaultValues = useCallback(() => {
    setMultiSelect({ groups: [] });
    if (defaultPlanName) {
      form.setFieldsValue({ planName: defaultPlanName });
      setFormData((prevData: any) => ({
        ...prevData,
        planName: defaultPlanName,
      }));
    }

    if (planYearsList && planYearsList.length > 0) {
      if (isDBGPlan) {
        const planYearDBG = find(planYearsList, { id: dbgPlanYear });
        if (planYearDBG) {
          form.setFieldsValue({ planYear: planYearDBG.id });
          setFormData((prevData: any) => ({
            ...prevData,
            planYear: planYearDBG.id,
          }));
          setBenefitClassesList(planYearDBG.benefitGroups);
          if (planYearDBG.benefitGroups.length === 1) {
            setMultiSelect({ groups: planYearDBG.benefitGroups });
            setFormData((prevData: any) => ({
              ...prevData,
              benefitClass: planYearDBG.benefitGroups,
            }));
            form.setFieldsValue({
              benefitClass: planYearDBG.benefitGroups,
            });
          }
        }
      } else {
        form.setFieldsValue({ planYear: planYearsList[0].id });
        setFormData((prevData: any) => ({
          ...prevData,
          planYear: planYearsList[0].id,
        }));
        setBenefitClassesList(planYearsList[0].benefitGroups);
        if (planYearsList[0].benefitGroups.length === 1) {
          setMultiSelect({ groups: planYearsList[0].benefitGroups });
          setFormData((prevData: any) => ({
            ...prevData,
            benefitClass: planYearsList[0].benefitGroups,
          }));
          form.setFieldsValue({
            benefitClass: planYearsList[0].benefitGroups,
          });
        }
      }
    }
  }, [
    defaultPlanName,
    form,
    planYearsList,
    setFormData,
    dbgPlanYear,
    isDBGPlan,
  ]);

  useEffect(() => {
    if (!isModalVisible) {
      setRequiredFieldError(false);
      if (!isPlanUpdateMode) {
        form.resetFields();
      }
    } else if (!isPlanUpdateMode) {
      setDefaultValues();
    }
  }, [
    isPlanUpdateMode,
    setDefaultValues,
    setRequiredFieldError,
    isModalVisible,
    form,
  ]);

  useEffect(() => {
    if (formData?.stdServices && lifePlanType === STD) {
      dispatch(setChangeService(formData?.stdServices));
    } else if (formData?.ltdServices && lifePlanType === LTD) {
      dispatch(setChangeService(formData?.ltdServices));
    }
  }, [dispatch, formData?.stdServices, formData?.ltdServices, lifePlanType]);

  const onInputChange = (changedValues: any, allValues: any) => {
    const {
      benefitCarrier,
      planName,
      planYear,
      fundingTypes,
      administrationFee,
      groupId,
    } = allValues;

    form.setFieldsValue({
      benefitCarrier,
      planName,
      planYear,
      fundingTypes,
      administrationFee,
      groupId,
    });

    setFormData({
      ...formData,
      ...changedValues,
      benefitClass: formData.benefitClass,
    });
  };

  const onCarrierSelect = (value: any) => {
    if (carriersList) {
      const carrier = carriersList.find((c) => c.id === value);
      if (carrier) {
        form.setFieldsValue({ benefitCarrier: carrier.id });
        setFormData({ ...formData, benefitCarrier: carrier.id });
      }
    }
  };

  const getCheckboxDataForGroups = (
    checkboxValues: string[],
    value: string,
    checked: boolean
  ): string[] => {
    let newCheckboxValues = cloneDeep(checkboxValues);
    if (checked) {
      newCheckboxValues = [...checkboxValues, value];
    } else {
      const index = checkboxValues.findIndex((element) => element === value);
      if (index > -1) {
        newCheckboxValues.splice(index, 1);
      }
    }
    return newCheckboxValues;
  };

  const resetHRAPlansOnBenefitClassesSelect = (benefitClasses: string[]) => {
    if (
      planType === TaxAdvantagedAccountPlanType.HRA.value &&
      !isEmpty(benefitClasses)
    ) {
      const filteredHraPlanList = Object.values(hraPlanList)?.flat();

      const matchingPlans = filteredHraPlanList
        ?.filter((item: any) => defaultHRAValues?.includes(item?.id))
        ?.filter((item: any) =>
          item?.groups?.some(
            (group: string) => !benefitClasses?.includes(group)
          )
        );

      if (!isEmpty(matchingPlans)) {
        hraSelectRef?.current?.reset();
        form.setFieldsValue({ ['hraReferencePlans']: [] });

        setFormData({
          ...formData,
          benefitClass: benefitClasses,
          selectedMedicalPlans: [],
          selectedDentalPlans: [],
          selectedVisionPlans: [],
        });
      }
    } else {
      setFormData({
        ...formData,
        benefitClass: benefitClasses,
        selectedMedicalPlans: [],
        selectedDentalPlans: [],
        selectedVisionPlans: [],
      });
    }
  };

  const onCheckboxSelection = (event: any) => {
    const { name, value, checked } = event.target;
    const newCheckboxValues = getCheckboxDataForGroups(
      multiSelect.groups,
      value,
      checked
    );
    setMultiSelect((prevState) => ({
      ...prevState,
      [name]: newCheckboxValues,
    }));

    setSelectedBenefitClasses(newCheckboxValues);
    form.setFieldsValue({ benefitClass: newCheckboxValues });
    setFormData({
      ...formData,
      benefitClass: newCheckboxValues,
    });
    if (!checked) {
      resetHRAPlansOnBenefitClassesSelect(newCheckboxValues);
    }
  };

  const { Option } = Select;

  const { fundingType } = form.getFieldsValue();

  const isShowReferencePlan =
    (TaxAdvantagedAccountPlanType.HSA.value === planType &&
      !isEmpty(associateMedicalPlansElements?.options)) ||
    (TaxAdvantagedAccountPlanType.HRA.value === planType &&
      !isEmpty(formData.benefitClass));

  const inputSpan = isSingleColumn ? 24 : 12;
  const labelProps = {
    span: isSingleColumn ? 6 : 24,
    className: styles.inputLabel,
  } as const;

  // FIXME: Carrier field cursor is misplaced when field is focused in single column mode

  return (
    <div className={styles.formWrapper}>
      {isTitleVisible && <div className={styles.header}>Basic Information</div>}
      <InputForm
        form={form}
        onValuesChange={onInputChange}
        size={isPlanUpdateMode || isSingleColumn ? 'small' : 'middle'}
      >
        <Row gutter={48}>
          <Col span={inputSpan}>
            <Form.Item
              name="planName"
              label="Plan Name*"
              labelCol={labelProps}
              rules={[
                {
                  required: true,
                  message: EMPTY_MESSAGE,
                  whitespace: true,
                },
              ]}
            >
              <Input
                className={isReviewHighlight ? styles.highlightField : ''}
                data-cy="planName"
                maxLength={maxPlanNameSize}
                title={formData.planName}
              />
            </Form.Item>
          </Col>
          <Col span={inputSpan} className={styles.inputColumn}>
            {reviewNewPlanYear !== undefined ? (
              <Form.Item
                className={
                  showPlanYearWarning
                    ? styles.planYearWrapperWarning
                    : styles.filterWrapper
                }
                label="Effective Dates*"
                labelCol={labelProps}
                name="reviewPlanYear"
                rules={[{ required: true, message: 'Please select Plan Year' }]}
              >
                <Input
                  disabled
                  defaultValue={reviewNewPlanYear}
                  className={styles.reviewPlanYearInput}
                />
              </Form.Item>
            ) : (
              <Form.Item
                className={
                  showPlanYearWarning
                    ? styles.planYearWrapperWarning
                    : styles.filterWrapperPlanYear
                }
                label="Effective Dates*"
                labelCol={labelProps}
                name="planYear"
                rules={[{ required: true, message: 'Please select Plan Year' }]}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  onSelect={onPlanYearSelect}
                  disabled={isDBGPlan}
                  loading={inProgress}
                >
                  {planYearsList &&
                    planYearsList
                      .filter(
                        (planYear) =>
                          planYear.id === formData?.planYear ||
                          !planYear.previous
                      )
                      .map((planYear: PlanYear) => (
                        <Select.Option value={planYear.id} key={planYear.id}>
                          {`${planYear.name} ${getPlanYearRangeWithCurrent(
                            planYear
                          )}`}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={inputSpan}>
            <Form.Item
              className={styles.filterWrapper}
              name="benefitCarrier"
              label={'Carrier' + (isCarrierRequired ? '*' : '')}
              rules={[
                {
                  required: isCarrierRequired,
                  message: 'Please select a carrier',
                },
              ]}
              labelCol={labelProps}
            >
              <SelectOptions
                className={
                  isReviewHighlight && isHightlightCarrier
                    ? styles.highlightSelector
                    : ''
                }
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                onSelect={onCarrierSelect}
                loading={carriersListLoading}
                allowClear={!isCarrierRequired}
                showSearch
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase().trim())
                }
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    {!isReview && (
                      <SelectAddMoreButton
                        onClick={() => {
                          !isErAdmin &&
                          appBootInfo?.individualSubType !==
                            IndividualSubTypes.BROKER_USER
                            ? setCarrierCreateTypeConfirmation({
                                show: true,
                                carrierType: '',
                              })
                            : setOpenCarrierModal(true);
                        }}
                        label="Add New Carrier"
                      />
                    )}
                  </>
                )}
              >
                {!isEmpty(carriersList) &&
                  carriersList.map((carrier: Carrier) => (
                    <Option value={carrier.id} key={carrier.id}>
                      {carrier.name}
                    </Option>
                  ))}
              </SelectOptions>
            </Form.Item>
          </Col>
          <Col span={inputSpan}>
            <Form.Item
              name="benefitClass"
              className={
                showWarning
                  ? styles.benefitClassWrapperWarning
                  : styles.filterWrapper
              }
              label="Benefit Classes*"
              labelCol={labelProps}
              rules={[
                {
                  required: true,
                  message: 'Please select at least one benefit class',
                },
              ]}
            >
              <>
                <Input hidden value={multiSelect.groups.join(', ')} />
                <Form.Item
                  name="benefitClass"
                  className={styles.benefitClasses}
                >
                  <BenefitClassMultiSelect
                    options={benefitClassesList}
                    onChange={onCheckboxSelection}
                    name="groups"
                    disabled={
                      reviewNewPlanYear !== undefined
                        ? false
                        : !formData.planYear
                    }
                    selectedItemValues={multiSelect.groups}
                  />
                </Form.Item>
              </>
            </Form.Item>
          </Col>
          {isShowReferencePlan && (
            <Col span={inputSpan}>
              <Form.Item
                name="selectedMedicalPlans"
                className={
                  isReviewHighlight
                    ? styles.filterWrapperWithHightlight
                    : styles.filterWrapper
                }
                label={
                  <Row>
                    <LabelWithTooltip
                      content={associateMedicalPlansElements?.tooltipContent}
                      mandatoryField={false}
                      fieldText={
                        TaxAdvantagedAccountPlanType.HSA.value === planType
                          ? 'Select Associated Medical Plans'
                          : 'Select Associated Plans'
                      }
                    />
                  </Row>
                }
                labelCol={labelProps}
              >
                {TaxAdvantagedAccountPlanType.HSA.value === planType && (
                  <MultiSelectDropdownSmall
                    options={associateMedicalPlansElements?.options || []}
                    increased={false}
                    onChange={
                      associateMedicalPlansElements?.onMedicalPlanCheckboxSelection ||
                      (() => {})
                    }
                    name="groups"
                    showAll={associateMedicalPlansElements?.showAll}
                    selectedItemValues={
                      associateMedicalPlansElements?.selectedItemValues || ''
                    }
                    disableInput={isEmpty(formData?.benefitClass)}
                  />
                )}

                {TaxAdvantagedAccountPlanType.HRA.value === planType && (
                  <div
                    className={`${styles.hsaAssociatePlanWrapper} ${
                      isPlanUpdateMode ? styles.update : styles.create
                    }`}
                  >
                    <MultiSelectGroup
                      ref={hraSelectRef}
                      selectName="hraReferencePlans"
                      defaultOptions={defaultHRAValues}
                      resetOptions={[]}
                      handleOkayCallback={(value, selectName) => {
                        form.setFieldsValue({ [selectName]: value });
                        onChangeReferencePlans && onChangeReferencePlans(value);
                      }}
                      handleResetCallback={(value, selectName) => {
                        form.setFieldsValue({ [selectName]: value });
                        setFormData({
                          ...formData,
                          selectedMedicalPlans: [],
                          selectedDentalPlans: [],
                          selectedVisionPlans: [],
                        });
                      }}
                      options={buildReferencePlanOptions(
                        hraPlanList,
                        currentPlanId
                      )}
                      searchPlaceholder="Search Plans"
                      isLoading={hraPlanListLoading}
                      isReview={isReview}
                    />
                  </div>
                )}
              </Form.Item>
            </Col>
          )}
          <Col span={inputSpan}>
            <Form.Item
              name="groupId"
              label="Group ID / Policy #"
              labelCol={labelProps}
              rules={[
                {
                  whitespace: false,
                },
              ]}
            >
              <Input
                data-cy="groupId"
                onBlur={trimGroupIdInput}
                title={formData.groupId}
              />
            </Form.Item>
          </Col>
          {lifePlanType && Object.keys(VoluntaryPlans).includes(lifePlanType) && (
            <Col span={inputSpan}>
              <Form.Item
                name={BasicInfoFields.ENROLLMENT}
                label={enableFundingType ? 'Enrollments' : 'Enrollments'}
                labelCol={labelProps}
              >
                <NumberFormatInput
                  className={styles.fundingType}
                  allowNegative={false}
                  decimalScale={0}
                  maxLength={9}
                  customClass={isReviewHighlight ? 'highlightField' : ''}
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        {enableFundingType ? (
          <>
            <Row gutter={48}>
              <Col span={inputSpan}>
                <Form.Item
                  className={`${styles.fundTypeStyles}
                ${
                  showWarning
                    ? styles.filterWrapperWarning
                    : styles.filterWrapper
                }
              `}
                  name="fundingType"
                  label="Funding Type*"
                  labelCol={labelProps}
                  rules={[
                    {
                      required: true,
                      message: 'Please select a funding type',
                    },
                  ]}
                >
                  <SelectOptions
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    options={FUNDING_TYPES_LIST.filter(
                      (type) => FundingType.LEVEL_FUNDED !== type.value
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={inputSpan}>
                <Form.Item
                  hidden={FundingType.SELF_FUNDED !== fundingType}
                  name="administrationFee"
                  className={`${styles.filterWrapper} ${styles.adminFee}`}
                  label={
                    <div className={styles.adminFeeColumn}>
                      Admin Fee<sup> 1</sup>
                    </div>
                  }
                  labelCol={labelProps}
                >
                  <NumberFormatInput
                    className={styles.fundingType}
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    placeholder="$"
                    customClass={isReviewHighlight ? 'highlightField' : ''}
                  />
                </Form.Item>
              </Col>
            </Row>

            {FundingType.SELF_FUNDED === fundingType && (
              <div className={styles.sfcText}>
                <span>1</span>
                This data is shared by all self-funded plans in this plan year
                with the same carrier.
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </InputForm>
      <ConfirmationWithThreeButtons
        visible={carrierCreateTypeConfirmation.show}
        width={464}
        centered
        title={ADD_NEW_CARRIER_MODAL_CONSTANTS.title}
        firstButtonText={ADD_NEW_CARRIER_MODAL_CONSTANTS.firstButtonLabel}
        firstButtonAction={() => {
          setCarrierCreateTypeConfirmation({
            show: false,
            carrierType: CarrierType.BROKER,
          });
          setOpenCarrierModal(true);
        }}
        secondButtonText={ADD_NEW_CARRIER_MODAL_CONSTANTS.secondButtonLabel}
        secondButtonAction={() => {
          setCarrierCreateTypeConfirmation({
            show: false,
            carrierType: CarrierType.EMPLOYER,
          });
          setOpenCarrierModal(true);
        }}
        onCancel={() => {
          setCarrierCreateTypeConfirmation({
            show: false,
            carrierType: '',
          });
        }}
      >
        {addNewCarrierModalDescription(empInfo?.name!)}
      </ConfirmationWithThreeButtons>
      <AddNewCarrierModal
        isOpen={openCarrierModal}
        setIsOpen={setOpenCarrierModal}
        selectedCarrierType={
          isErAdmin ||
          appBootInfo?.individualSubType === IndividualSubTypes.BROKER_USER
            ? CarrierType.EMPLOYER
            : carrierCreateTypeConfirmation.carrierType
        }
        benefitType={benefitCategory}
        setCarrierToForm={handleSetCarrierToForm}
        brokerId={brokerId!}
        employerId={employerId!}
      />
    </div>
  );
});
PlanBasicForm.displayName = 'PlanBasicForm';
export default PlanBasicForm;
