import { FC, useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import AddBenefitConsultant from 'modules/benefitsConsultation/components/AddBenefitConsultant/AddBenefitConsultant';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import {
  assignUnassignConsultantsStarted,
  assignUnassignConsultantsSuccess,
  getAllBrokerConsultants,
  getAllBrokerConsultantsStarted,
  manageERBrokerConsultants,
} from 'modules/benefitsConsultation/slices/employerLevelSlice';
import ConsultationLevel from 'modules/benefitsConsultation/enums/ConsultationLevel';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import styles from 'modules/benefitsConsultation/pages/BrokerConsultantDetails/brokerConsultantDetails.module.less';
import { CONSULTANT_EMPLOYER_LEVEL_ALL_CONFIRMATION } from 'modules/admins/constants/adminConstants';

type AssignERBrokerConsultantsProps = {
  visible: boolean;
  setVisible: Function;
  organizationId?: string;
  employerId?: string;
  employerName?: string;
};

const AssignERBrokerConsultants: FC<AssignERBrokerConsultantsProps> = (
  props: AssignERBrokerConsultantsProps
) => {
  const {
    visible,
    setVisible,
    organizationId,
    employerId,
    employerName = '',
  } = props;

  const dispatch = useAppDispatch();
  const { brokerId } = useNavContext();

  const brokerConsultantsList = useAppSelector(
    (state) => state.benefitsConsultation.employerLevel.brokerConsultantsList
  );

  const requestType = useAppSelector(
    (state) => state.benefitsConsultation.employerLevel.requestType
  );
  const inProgress = useAppSelector(
    (state) => state.benefitsConsultation.employerLevel.inProgress
  );

  const checkboxListSelectorRef = useRef<any>(null);

  const [editEmployerConfirmationVisible, setEditEmployerConfirmationVisible] =
    useState<boolean>(false);

  const [validatedTempConsultantData, setValidatedTempConsultantData] =
    useState<any>({
      empId: {},
      consultantList: [],
    });

  useEffect(() => {
    organizationId &&
      employerId &&
      dispatch(getAllBrokerConsultants(organizationId, employerId));
  }, [organizationId, employerId, brokerId, dispatch]);

  useEffect(() => {
    if (requestType === assignUnassignConsultantsSuccess.type) {
      setVisible(false);
    }
  }, [requestType, setVisible]);

  const addBrokerConsultants = () => {
    const consultantsList =
      checkboxListSelectorRef?.current?.getConsultantsToBeAdded();

    if (!isEmpty(consultantsList) && employerId) {
      const assignedList = consultantsList.filter((obj: any) => obj.isAssigned);
      if (isEmpty(assignedList)) {
        setEditEmployerConfirmationVisible(true);
        setValidatedTempConsultantData({
          empId: employerId,
          consultantList: consultantsList,
        });
        return;
      }

      dispatch(manageERBrokerConsultants(consultantsList, employerId));
    }
  };

  const handleConfirmation = () => {
    if (
      validatedTempConsultantData.empId &&
      validatedTempConsultantData.consultantList
    ) {
      setEditEmployerConfirmationVisible(!editEmployerConfirmationVisible);
      dispatch(
        manageERBrokerConsultants(
          validatedTempConsultantData.consultantList,
          validatedTempConsultantData.empId
        )
      );
    }
    return;
  };

  const handleConfirmationClose = () => {
    setEditEmployerConfirmationVisible(!editEmployerConfirmationVisible);
    setValidatedTempConsultantData({
      validation: {},
      mappedEmployers: [],
    });
  };

  return (
    <>
      <ConfirmationDialog
        title="Assign Broker Consultants"
        confirmText="Yes - Unassign Consultants"
        cancelText="Cancel"
        closeModal={handleConfirmationClose}
        onConfirm={handleConfirmation}
        visible={editEmployerConfirmationVisible}
      >
        <div className={styles.warningConfirmation}>
          {CONSULTANT_EMPLOYER_LEVEL_ALL_CONFIRMATION}
        </div>
      </ConfirmationDialog>
      <AddBenefitConsultant
        modalHeading="Assign Broker Consultants"
        visible={visible}
        setVisible={setVisible}
        saveInProgress={
          inProgress && requestType === assignUnassignConsultantsStarted.type
        }
        listIsLoading={
          inProgress && requestType === getAllBrokerConsultantsStarted.type
        }
        onSave={addBrokerConsultants}
        checkboxListSelectorRef={checkboxListSelectorRef}
        activeAdminsList={brokerConsultantsList}
        consultantTypeText="Broker Consultants"
        level={ConsultationLevel.ER_BROKERS}
        isRemovableMode
        employerName={employerName}
      />
    </>
  );
};

export default AssignERBrokerConsultants;
