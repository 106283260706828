import { useEffect, useState } from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import TabView from 'components/TabView/TabView';
import TabType from 'model/TabType';
import {
  BENEFIT_KIND_LABEL_MAP,
  BENEFIT_KIND_LIST,
  FUNDING_TYPE,
} from 'modules/claims/constants/constants';

import ClaimTabDetails from 'modules/claims/components/ClaimTabDetails/ClaimTabDetails';
import FundingTypeSelector from 'modules/claims/components/FundingTypeSelector/FundingTypeSelector';

import { useLazyGetClaimsSettingsQuery } from 'modules/claims/slices/claimsSlice';
import styles from './claimsView.module.less';

type ClaimsViewProps = {
  preview: boolean;
  displayDummyData: boolean;
  isRemoved?: boolean;
  claimManageOpen?: boolean;
  claimsFileDeleted?: boolean;
};

const ClaimsView = (props: ClaimsViewProps) => {
  const {
    preview,
    displayDummyData,
    isRemoved = false,
    claimManageOpen = false,
    claimsFileDeleted = false,
  } = props;

  const params = useParams();

  const [getClaimsSettings, { data: claimsSettings }] =
    useLazyGetClaimsSettingsQuery();

  const getAllBenefitTypes = () => {
    let benefitTypes: string[] = [];
    if (!isEmpty(claimsSettings?.settings)) {
      Object.keys(FUNDING_TYPE).forEach((type) => {
        if (!isEmpty(claimsSettings?.settings[type])) {
          benefitTypes = benefitTypes.concat(claimsSettings?.settings[type]);
        }
      });
    }
    return benefitTypes;
  };

  const getAvailableFundingTypes = () => {
    const fundingTypes: string[] = [];
    if (!isEmpty(claimsSettings?.settings)) {
      Object.keys(FUNDING_TYPE).forEach((type) => {
        if (
          !isEmpty(claimsSettings?.settings[type]) &&
          claimsSettings?.settings[type].includes(selectedTab?.toUpperCase())
        ) {
          fundingTypes.push(type);
        }
      });
    }
    return fundingTypes;
  };

  const firstTab = BENEFIT_KIND_LIST.find(
    (type) =>
      getAllBenefitTypes()
        .map((setting: string) => setting.toLowerCase())
        .includes(type.toLowerCase()) || displayDummyData
  );

  const [selectedTab, setSelectedTab] = useState<string>();
  const [fundingType, setFundingType] = useState<string>();
  const [availableFundingTypes, setAvailableFundingTypes] =
    useState<string[]>();

  useEffect(() => {
    setSelectedTab(firstTab);
  }, [firstTab, isRemoved]);

  useEffect(() => {
    getClaimsSettings({
      employerId: params.employerId,
      preview: preview,
    });
  }, [getClaimsSettings, params.employerId, preview]);

  useEffect(() => {
    if (!isEmpty(claimsSettings?.settings)) {
      const types = getAvailableFundingTypes();
      setAvailableFundingTypes(types);
      selectedTab &&
        setFundingType(
          claimsSettings?.latestClaimsType[selectedTab.toUpperCase()]
        );
    }
    // eslint-disable-next-line
  }, [preview, claimsSettings, selectedTab]);

  useEffect(() => {
    if (claimsFileDeleted) {
      getClaimsSettings({
        employerId: params.employerId,
        preview: preview,
      });
    }
  }, [claimsFileDeleted, getClaimsSettings, params.employerId, preview]);

  const getTabDataList = () => {
    const tabs: TabType[] = [];
    BENEFIT_KIND_LIST.filter(
      (type) =>
        getAllBenefitTypes()
          .map((setting: string) => setting.toLowerCase())
          .includes(type.toLowerCase()) || displayDummyData
    ).forEach((benefitType: string) => {
      const tabType: TabType = {
        tabLabel: BENEFIT_KIND_LABEL_MAP[benefitType],
        tabKey: benefitType,
        tabData: (
          <ClaimTabDetails
            preview={preview}
            benefitKind={benefitType}
            displayDummyData={displayDummyData}
            claimManageOpen={claimManageOpen}
            fundingType={fundingType}
            claimsFileDeleted={claimsFileDeleted}
          />
        ),
      };
      tabs.push(tabType);
    });
    return tabs;
  };

  const onTabClick = (key: string) => {
    setSelectedTab(key);
  };

  return (
    <div className={styles.claimsView}>
      <FundingTypeSelector
        fundingMode={fundingType}
        setFundingMode={setFundingType}
        availableFundingTypes={availableFundingTypes}
      />
      <Row className={styles.claimsChartContainer}>
        <TabView
          tabOptions={getTabDataList()}
          defaultTabKey={selectedTab || ''}
          onClick={() => {}}
          onChange={onTabClick}
          className={styles.claimsTabViewWrapper}
        />
      </Row>
    </div>
  );
};
export default ClaimsView;
