import { useAppSelector } from 'hooks/redux';
import MedicalBasicPlanInfoUPWrapper from 'modules/plans/components/BasicPlanInfo/BasicPlanInfoUPWrapper';
import DentalBasicPlanInfoUPWrapper from 'modules/plans/dental/components/PlanBasicInfo/DentalBasicPlanInfoUPWrapper';
import VisionBasicPlanInfoUPWrapper from 'modules/plans/vision/components/PlanBasicInfo/VisionBasicPlanInfoUPWrapper';

import { ReviewModalFragment } from 'modules/renewals/types/renewalsTypes';
import { DentalPlan } from 'model/plans/DentalPlan';
import MedicalPlan from 'model/plans/MedicalPlan';
import { VisionPlan } from 'model/plans/VisionPlan';

type Props = ReviewModalFragment;

const BasicInfo = ({
  setState,
  state,
  type,
  benefitClasses,
  formRef,
}: Props) => {
  const planYearName = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.basicDetails.planYearName
  );
  const planCreateType = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.selectPlanCreateType
  );
  const isPlanReviewed = useAppSelector(
    (state) =>
      state.renewals.addProposalToPlanYear.isCurrentSelectedPlanReviewed
  );
  const isPlanMerged = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.isCurrentSelectedPlanMerged
  );

  const isMerging =
    planCreateType === 'MERGE' && !(isPlanReviewed === isPlanMerged);

  const getBasicInfoUPWrapper = () => {
    const componentMap: {
      // eslint-disable-next-line
      [key in NonNullable<typeof type>]?: React.ReactNode;
    } = {
      MEDICAL: (
        <MedicalBasicPlanInfoUPWrapper
          setMedicalPlan={setState}
          medicalPlan={{ ...state } as MedicalPlan}
          isReviewProp={true}
          isReviewHighlight={isMerging}
          reviewNewPlanYear={planYearName}
          reviewBenefitClasses={benefitClasses || []}
          basicPlanInfoRef={formRef}
        />
      ),
      DENTAL: (
        <DentalBasicPlanInfoUPWrapper
          setDentalPlan={setState}
          dentalPlan={state as DentalPlan}
          isReviewProp={true}
          isReviewHighlight={isMerging}
          basicPlanInfoRef={formRef as any}
          reviewNewPlanYear={planYearName}
          reviewBenefitClasses={benefitClasses || []}
        />
      ),
      VISION: (
        <VisionBasicPlanInfoUPWrapper
          visionPlan={state as VisionPlan}
          setVisionPlan={setState}
          isReviewProp={true}
          isReviewHighlight={isMerging}
          basicPlanInfoRef={formRef as any}
          reviewNewPlanYear={planYearName}
          reviewBenefitClasses={benefitClasses || []}
        />
      ),
    };

    return type !== undefined && componentMap[type];
  };

  return <div>{getBasicInfoUPWrapper()}</div>;
};

export default BasicInfo;
