import React from 'react';
import RxCardInfoUPWrapper from 'modules/plans/components/RxCardInfo/RxCardInfoUPWrapper';

type Props = {
  setState: Function;
  state: any;
};

const IdCardInformation = ({ setState, state }: Props) => {
  return (
    <div>
      <RxCardInfoUPWrapper medicalPlan={state} setMedicalPlan={setState} />
    </div>
  );
};

export default IdCardInformation;
