import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Form } from 'antd';
import { useAppSelector } from 'hooks/redux';
import Rates from 'modules/plans/components/Rates/Rates';
import BenguideBasicData from 'model/BenguideBasicData';
import {
  getPlanContributionByFrequency,
  updateVisionPlanByPath,
  getPlanContributionInProgress,
} from 'modules/plans/slices/visionPlanSlice';
import { BenefitCategory } from 'constants/commonConstants';
import styles from './ratesUPWrapper.module.less';

type RatesSBCWrapperProps = {
  currentSection: string;
  error: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  onChange?: (plan: any) => void;
};

const RatesUPWrapper = forwardRef((props: RatesSBCWrapperProps, ref) => {
  const { editedVisionPlan } = useAppSelector((state) => state.plan.visionPlan);
  const requestType = useAppSelector(
    (state) => state.plan.visionPlan.requestType
  );
  const { currentSection, error, onChange } = props;
  const [ratesForm] = Form.useForm();
  const formRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const { invalidFormFields = [], isComplete } =
        await formRef.current.validate();
      return {
        isValid: invalidFormFields.length === 0,
        isComplete,
      };
    },
  }));

  return (
    <div className={styles.ratesWrapper}>
      <Rates
        isUPEdit
        benefitCategory={BenefitCategory.VISION.value}
        updatePlanByPathAction={updateVisionPlanByPath}
        getContributionAction={getPlanContributionByFrequency}
        benefitGuideObj={{} as BenguideBasicData}
        plan={editedVisionPlan}
        ref={formRef}
        currentSection={currentSection}
        form={ratesForm}
        isDBGView={false}
        getContributionInProgress={
          requestType === getPlanContributionInProgress.type
        }
        error={error}
        onChange={onChange}
      />
    </div>
  );
});

RatesUPWrapper.displayName = 'RatesUPWrapper';

export default RatesUPWrapper;
