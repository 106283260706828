import { FC } from 'react';
import { Col, Row, Form } from 'antd';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import DataTable from 'components/DataTable/DataTable';
import { DataColumn } from 'components/DataTable/DataColumn';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import InfoBanner from 'components/InfoBanner/InfoBanner';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import InputForm from 'components/InputForm/InputForm';
import InvitationTemplate from 'modules/brokers/components/SendInvitesForm/InvitationTemplate';

import Admin from 'model/admin/Admin';

import { buildFullName, buildInitials } from 'util/stringUtil';
import iconSendInvite from 'assets/images/icon-send-invite.svg';

import { IndividualType } from 'constants/commonConstants';
import styles from './sendInviteTemplate.module.less';

type SendInviteTemplateProps = {
  admin?: any | null; // We have three types of admins
  closeBrokerModal: () => void;
  sendInvite: () => void;
  locationMap?: Map<string, string>;
};

const getBrokerLocations = (locations: string[]): string => {
  const sortedLocations = [...locations].sort();
  return sortedLocations.map((item) => item).join(', ');
};

const SendInviteTemplate: FC<SendInviteTemplateProps> = (
  props: SendInviteTemplateProps
) => {
  const { admin, closeBrokerModal, sendInvite, locationMap } = props;

  const convertBrokerLocations = (
    locations: Map<string, string>
  ): string | string[] => {
    return Object.values(locations);
  };

  const convertBrokerAdminForTableRender = (admin: Admin): any[] => {
    if (admin != null) {
      const adminData = {
        key: admin.inPlaceId,
        admin: admin,
        location: convertBrokerLocations(locationMap || new Map()),
      };
      return [adminData];
    }
    return [];
  };

  const adminTableColumns: DataColumn[] = [
    {
      title: 'ADMIN NAME',
      dataIndex: 'admin',
      key: 'admin',
      width: '60%',
      sorter: (a: any, b: any) =>
        a.admin.firstName.localeCompare(b.admin.firstName),
      ellipsis: {
        showTitle: false,
      },
      render: (admin) => {
        return (
          <div className={styles.name}>
            <ProfileAvatar
              content={buildInitials(admin.firstName, admin.lastName)}
              src={admin.avatarUrl}
            />
            <span>
              <OverflowPopover
                maxWidth="350px"
                popoverContent={buildFullName(admin.firstName, admin.lastName)}
              >
                {buildFullName(admin.firstName, admin.lastName)}
              </OverflowPopover>
            </span>
          </div>
        );
      },
      align: 'left',
    },
    {
      title: 'LOCATION ACCESS',
      dataIndex: 'location',
      key: 'location',
      width: '40%',
      ellipsis: {
        showTitle: false,
      },
      render: (colData) => (
        <OverflowPopover>{getBrokerLocations(colData)}</OverflowPopover>
      ),
    },
  ];

  const getTitle = (adminType: string) => {
    switch (adminType) {
      case IndividualType.LUMITY_ADMIN:
        return 'Send Invitation to New Tech Admins';
      case IndividualType.ORGANIZATION_ADMIN:
        return 'Send Invitation to New Broker Admins';
      case IndividualType.ER_ADMIN:
        return 'Send Invitation to New Employer Users';
      default:
        return '';
    }
  };

  const getDescription = (adminType: string) => {
    switch (adminType) {
      case IndividualType.LUMITY_ADMIN:
        return 'The tech admin will receive a registration link via email to join the PlanYear platform';
      case IndividualType.ORGANIZATION_ADMIN:
        return 'The broker admin will receive a registration link via email to join the PlanYear platform';
      case IndividualType.ER_ADMIN:
        return 'The employer User will receive a registration link via email to join the PlanYear platform';
      default:
        return '';
    }
  };

  return (
    <div className={styles.inviteTemplate}>
      <div className={styles.infoBanner}>
        <InfoBanner
          title={getTitle(admin.individualType)}
          description={getDescription(admin.individualType)}
          logo={<img src={iconSendInvite} alt="send invite icon" />}
        />
      </div>

      <DataTable
        data={convertBrokerAdminForTableRender(admin)}
        columns={adminTableColumns}
        showHeader={false}
      />
      <InputForm className={styles.templateWrapper}>
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              name="message"
              label="Here's what we'll send: "
              labelCol={{ span: 24 }}
              colon={false}
            >
              {/* TODO: need to change logo */}
              <InvitationTemplate firstName="John" />
            </Form.Item>
          </Col>
        </Row>
      </InputForm>
      <PageActionButton
        onClick={sendInvite}
        className={styles.sendInvitetButton}
        type="primary"
      >
        Send Invite
      </PageActionButton>
      <CancelButton htmlType="button" onClick={closeBrokerModal} block>
        Cancel
      </CancelButton>
    </div>
  );
};

export default SendInviteTemplate;
