import { FC, useEffect, useState, useCallback } from 'react';
import { StompSessionProvider } from 'react-stomp-hooks';

import { useParams, useNavigate } from 'react-router-dom';
import { Col, PageHeader, Row } from 'antd';

import { usePermitIf } from 'hooks/usePermitIf';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { findPlanById } from 'modules/plans/slices/lifePlanSlice';
import BasicPlanInfoOverview from 'modules/plans/components/OverviewPage/BasicPlanInfoOverview/BasicPlanInfoOverview';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import BasicPlanInformationOverview from 'modules/plans/life/components/BasicPlanInformationOverview/BasicPlanInformationOverview';
import DisabilityPlanInformationOverview from 'modules/plans/life/components/DisabilityPlanInformationOverview/DisabilityPlanInformationOverview';
import VoluntaryPlanInformationOverview from 'modules/plans/life/components/VoluntaryPlanInformationOverview/VoluntaryPlanInformationOverview';
import PlanCollaborationNotification from 'components/PlanCollaborationNotification/PlanCollaborationNotification';
import { allowedCommonButtonPermittedTypes } from 'constants/permittedConstants';
import { BasicPlans, VoluntaryPlans } from 'modules/plans/constants';
import { wsBaseApi } from 'util/apiUtil';
import useGetUserFeatureStatus from 'hooks/useGetUserFeatureStatus';
import { FEATURE_KEYS } from 'constants/commonConstants';

import styles from './lifePlanOverview.module.less';

const LifePlanPlanOverview: FC = () => {
  const params = useParams();
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();
  const isAdvancedProfile = useGetUserFeatureStatus(
    brokerId,
    employerId,
    FEATURE_KEYS.ADVANCED_PROFILE,
    false
  );
  const { lifePlan } = useAppSelector((state) => state.plan.lifePlan);

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const navigate = useNavigate();

  const [viewPlanModal, setViewPlanModal] = useState(false);
  const [disableEditButton, setDisableEditButton] = useState(false);
  const [fetchPlanWhenUpdated, setFetchPlanWenUpdated] =
    useState<boolean>(false);

  const refreshPlan = useCallback(() => {
    if (employerId && brokerId && params.planId) {
      dispatch(findPlanById(params.planId));
    }
    setFetchPlanWenUpdated(false);
  }, [dispatch, employerId, brokerId, params.planId]);

  useEffect(() => {
    if (employerId && brokerId && params.planId && !lifePlan) {
      dispatch(findPlanById(params.planId));
    }
    setFetchPlanWenUpdated(false);
  }, [dispatch, employerId, brokerId, params.planId, lifePlan]);

  const exitPlan = () => {
    navigate(`/brokers/${brokerId}/employers/${employerId}/life`);
  };

  const updateViewPlanModal = (value: boolean) => {
    setViewPlanModal(value);
  };

  const navigation: { name: string; link: string }[] = [
    {
      name: 'All Life & Disability Plans',
      link: `/brokers/${brokerId}/employers/${employerId}/life`,
    },
  ];
  const securedBreadcrumb = usePermitIf(
    <BreadcrumbNavigation breadCrumbData={navigation} />,
    allowedCommonButtonPermittedTypes,
    []
  );

  const getOtherDetails = () => {
    switch (lifePlan.benefitKind) {
      case BasicPlans.BASIC_ADD.value:
      case BasicPlans.BASIC_LIFE.value:
      case BasicPlans.BASIC_LIFE_AND_ADD.value:
        return (
          <BasicPlanInformationOverview
            plan={lifePlan}
            isAdvancedProfile={isAdvancedProfile ?? false}
          />
        );
      case BasicPlans.STD.value:
      case BasicPlans.LTD.value:
      case VoluntaryPlans.VOLUNTARY_STD.value:
      case VoluntaryPlans.VOLUNTARY_LTD.value:
        return (
          <DisabilityPlanInformationOverview
            plan={lifePlan}
            isAdvancedProfile={isAdvancedProfile ?? false}
          />
        );
      case VoluntaryPlans.VOLUNTARY_LIFE.value:
      case VoluntaryPlans.VOLUNTARY_ADD.value:
      case VoluntaryPlans.VOLUNTARY_LIFE_ADD.value:
        return <VoluntaryPlanInformationOverview plan={lifePlan} />;
    }
  };

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col span={24}>
          <Row>{securedBreadcrumb}</Row>
          <Col span={18}>
            <PageHeader className={styles.pageTitle} title={lifePlan.name} />
          </Col>
        </Col>
      </Row>
      <div className={styles.overview}>
        <StompSessionProvider
          url={`${wsBaseApi}/websocket-plan-update`}
          onWebSocketError={(e) => console.error(e)}
          reconnectDelay={500}
        >
          <PlanCollaborationNotification
            loggedInUserIndividualId={appBootInfo?.individualId}
            exitPlan={() => exitPlan()}
            refreshPlan={() => refreshPlan()}
            planId={lifePlan.name}
            isInEdit={viewPlanModal}
            disableEdit={() => setDisableEditButton(true)}
            fetchPlan={() => setFetchPlanWenUpdated(true)}
          />
        </StompSessionProvider>
        <div className={styles.basicInfoWrapper}>
          <BasicPlanInfoOverview
            heading={'Plan Details'}
            planName={lifePlan.name}
            planYearName={lifePlan.planYear?.name}
            benefitClasses={lifePlan.groups}
            carrierName={lifePlan.benefitCarrier?.name}
            type={'LIFE'}
            viewPlanModal={viewPlanModal}
            setViewPlanModal={updateViewPlanModal}
            disableEditButton={disableEditButton}
            fetchPlanWhenUpdated={fetchPlanWhenUpdated}
            exitPlan={() => exitPlan()}
            fundingType={
              lifePlan.benefitKind === BasicPlans.STD.value
                ? lifePlan.fundingType
                : undefined
            }
            administrationFee={
              lifePlan?.sfcPlanConfigVo?.administrationFee ?? ''
            }
            groupId={lifePlan.groupId}
            portability={lifePlan.portability}
            waiverOfPremium={lifePlan.waiverOfPremium}
            acceleratedLifeBenefit={lifePlan.acceleratedLifeBenefit}
            definitionOfEarnings={lifePlan.definitionOfEarnings}
            ageReduction={lifePlan.ageReduction}
            guaranteedIssue={lifePlan.guaranteedIssue}
            additionalServices={lifePlan.lifeServices}
            benefitKind={lifePlan.benefitKind}
          />
        </div>
        {getOtherDetails()}
      </div>
    </div>
  );
};

export default LifePlanPlanOverview;
