import React from 'react';
import { Col, Row } from 'antd';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import styles from 'modules/renewals/components/StartNewRenewalModal/components/EnrollmentRatesSection/enrollmentRatesSection.module.less';

type EnrollmentRateInputProps = {
  label: string;
  value: any;
  isInvalid: boolean;
  onChange: (target: any) => void;
};

const EnrollmentRateInput = (props: EnrollmentRateInputProps) => {
  const { label, value, isInvalid, onChange } = props;
  return (
    <Row className={styles.enrollmentRateInputWrapper}>
      <Col xs={3} className={styles.tierName}>
        <span className="text-form-label">{label}</span>
      </Col>
      <Col xs={21}>
        <NumberFormatInput
          value={value}
          name={label}
          onChange={onChange}
          thousandSeparator
          allowNegative={false}
          outsidePrefix
          decimalSeparator="."
          fixedDecimalScale
          decimalScale={0}
          isInvalid={isInvalid}
        />
      </Col>
    </Row>
  );
};

export default EnrollmentRateInput;
