import { Divider, Spin } from 'antd';

import styles from './summaryInfo.module.less';

type Props = {
  topLabel: string;
  amount: string | null;
  toolTipPosition?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
    | undefined;
  isLoading: boolean;
};
const SummaryInfo = (props: Props) => {
  const { topLabel, amount = 'N/A', isLoading } = props;
  return (
    <div className={styles.summaryInfo}>
      <label className={styles.summaryTitle}>{topLabel}</label>
      {isLoading ? (
        <div>
          <Spin />
        </div>
      ) : (
        <h1 className={styles.amountLabel}>
          {amount === null ? 'N/A' : amount}
        </h1>
      )}
      <Divider className={styles.footerDivider} />
    </div>
  );
};
export default SummaryInfo;
