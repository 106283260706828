import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SorterResult } from 'antd/lib/table/interface';
import { isNumber } from 'lodash';

import BrokerSsoTable from 'modules/sso/components/BrokerSsoTable/BrokerSsoTable';
import { useEmployerListQuery } from 'modules/sso/slices/ssoSlice';

import TablePagination from 'model/TablePagination';

import styles from 'modules/sso/pages/BrokerAdminSsoConfigPage/BrokerAdminSsoConfigPage.module.less';

const BrokerAdminSsoConfigPage = () => {
  const params = useParams();
  const { brokerId } = params;

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    } as SorterResult<any>,
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: '',
    },
    filters: {},
  });
  const [searchText, setSearchText] = useState<string>('');
  const { columnKey, order } = (paginationConfig.sorterInfo as any) || {};

  const {
    data: employerList,
    isFetching: employerListIsLoading,
    refetch: employerListRefetch,
  } = useEmployerListQuery(
    {
      organizationId: brokerId ?? '',
      size: paginationConfig.filterInfo.limit,
      page: paginationConfig.paginationIndex,
      query: paginationConfig.filterInfo.searchText,
      sort:
        order === undefined
          ? ''
          : order === 'descend'
          ? `-${columnKey}`
          : columnKey,
    },
    { refetchOnMountOrArgChange: true }
  );

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.title}>Single Sign-On (SSO) Configuration</div>
      <div className={styles.tableWrapper}>
        <BrokerSsoTable
          paginationConfig={paginationConfig}
          setPaginationConfig={setPaginationConfig}
          data={employerList?.content ?? []}
          totalData={
            isNumber(employerList?.metadata?.total)
              ? Number(employerList?.metadata?.total)
              : 0
          }
          loading={employerListIsLoading}
          setSearchText={setSearchText}
          searchText={searchText}
          onUpdate={employerListRefetch}
        />
      </div>
    </div>
  );
};

export default BrokerAdminSsoConfigPage;
