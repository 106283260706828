import axios, { AxiosResponse } from 'axios';
import { baseApi } from 'util/apiUtil';
import { DocumentExtractionHistoryResponseVO } from 'modules/renewals/models/AiDocumentExtractionHistoryResponseVO';
import { LLM_PROMPT_TYPE } from 'constants/commonConstants';

const CARRIER_QUOTE_API_PREFIX = `${baseApi}/v1/document-extraction/carrier-quote`;
const DOCUMENT_EXTRACTION_API_PREFIX = `${baseApi}/v1/document-extraction`;

// Fetch recent uploaded quote files
export const fetchRecentUploadedQuoteFiles = async (
  organizationId: string,
  employerId: string
): Promise<AxiosResponse<DocumentExtractionHistoryResponseVO[]>> => {
  return axios.get(`${CARRIER_QUOTE_API_PREFIX}`, {
    params: {
      'organization-id': organizationId,
      'employer-id': employerId,
    },
  });
};

// Fetch quote upload history for a particular benefit category
export const fetchQuoteUploadHistory = async (
  organizationId: string,
  employerId: string,
  category: string
): Promise<AxiosResponse<DocumentExtractionHistoryResponseVO[]>> => {
  return axios.get(`${CARRIER_QUOTE_API_PREFIX}`, {
    params: {
      'organization-id': organizationId,
      'employer-id': employerId,
      'benefit-category': category,
    },
  });
};

// validate
export const uploadCarrierQuote = async (
  file: File,
  employerId: string,
  brokerId: string,
  benefit: string
) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const data = new FormData();
  data.append('document', file);
  data.append('employer-id', employerId!);
  data.append('organization-id', brokerId!);
  data.append('benefit-category', benefit!);

  const res = await axios.post(
    `${CARRIER_QUOTE_API_PREFIX}/validate-document`,
    data,
    config
  );
  return res?.data;
};

// Fetch quote upload history for a particular benefit category
export const validatePlanNames = async (
  jobId: string,
  planNames: string[]
): Promise<any> => {
  return axios.put(`${CARRIER_QUOTE_API_PREFIX}/${jobId}/validate-plan-names`, {
    planNames: planNames,
  });
};

// Extract quotes from the uploaded file
export const extractQuotesEndpoint = async (
  jobId: string,
  offerId: string
): Promise<any> => {
  return axios.put(`${CARRIER_QUOTE_API_PREFIX}/${jobId}/extract`, {
    offerId: offerId,
  });
};

export const uploadCarrierQuoteFileFromRecentFiles = async (
  employerId: string,
  brokerId: string,
  benefit: string,
  jobId: string
) => {
  const data = new FormData();
  data.append('employer-id', employerId!);
  data.append('organization-id', brokerId!);
  data.append('benefit-category', benefit!);
  data.append('recent-job-id', jobId);

  const res = await axios.post(
    `${CARRIER_QUOTE_API_PREFIX}/validate-document`,
    data
  );
  return res.data;
};

export const getCarrerQuoteStatus = async (jobId: string): Promise<any> => {
  return axios.get(`${DOCUMENT_EXTRACTION_API_PREFIX}/${jobId}/status`);
};

// Extract quotes from the uploaded file
export const getExtractedQuotes = async (
  jobId: string
): Promise<AxiosResponse<any>> => {
  return axios.get(`${CARRIER_QUOTE_API_PREFIX}/${jobId}/plans`);
};

export const redirectFailedExtractionToOps = async (
  jobId: string,
  offerId: string
): Promise<any> => {
  const data = new FormData();
  data.append('offer-id', offerId!);
  return axios.put(
    `${CARRIER_QUOTE_API_PREFIX}/${jobId}/redirect-to-ops`,
    data
  );
};

export const fetchLLMType = ({
  promptType,
  benefit,
}: {
  promptType: keyof typeof LLM_PROMPT_TYPE;
  benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'BASIC_LIFE' | 'LTD' | 'STD';
}) => {
  return axios.get(`${DOCUMENT_EXTRACTION_API_PREFIX}/dual-llm-enabled`, {
    params: {
      'prompt-type': promptType,
      'benefit-kind': benefit,
    },
  });
};
