import { Col, Row } from 'antd';
import { FC, ReactNode } from 'react';
import InfoLabel from 'components/InfoLabel/InfoLabel';

type BasicInfoLabelSectionPropTypes = {
  labelText: string | ReactNode;
  value: ReactNode;
  extraColValue1?: ReactNode;
  extraColValue2?: ReactNode;
  extraTitleColValue?: number;
  className?: string;
};

const InfoLabelSection: FC<BasicInfoLabelSectionPropTypes> = (
  props: BasicInfoLabelSectionPropTypes
) => {
  const {
    labelText,
    value,
    extraColValue1,
    extraColValue2,
    extraTitleColValue,
    className,
  } = props;
  return (
    <Row>
      <Col xs={extraTitleColValue ? 22 : 18} className={className}>
        <InfoLabel
          labelText={labelText}
          value={value}
          extraColValue1={extraColValue1}
          extraColValue2={extraColValue2}
          extraTitleColValue={extraTitleColValue}
        />
      </Col>
    </Row>
  );
};

export default InfoLabelSection;
