import { FC, ReactNode } from 'react';
import { Drawer as AntDDrawer, DrawerProps } from 'antd';

interface DrawerPropsTypes extends DrawerProps {
  children: ReactNode;
}

const Drawer: FC<DrawerPropsTypes> = (props) => {
  const { children, ...rest } = props;
  return (
    <AntDDrawer
      {...rest}
      contentWrapperStyle={{
        boxShadow: `-6px 0 2px -2px rgba(0, 0, 0, 0.08),
      -2px 0 2px 0 rgba(0, 0, 0, 0.08), -2px 0 48px 16px rgba(0, 0, 0, 0.03)`,
      }}
      maskStyle={{
        backgroundColor: '#FFFFFF',
        opacity: 0.8,
      }}
    >
      {children}
    </AntDDrawer>
  );
};

export default Drawer;
