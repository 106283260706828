import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  forwardRef,
  useContext,
} from 'react';
import { isEmpty } from 'lodash';
import { Editor } from '@tinymce/tinymce-react';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import { NotificationContext } from 'context/NotificationContext';
import {
  getFilteredOptionArray,
  getSortOptionsCaseInsensitive,
  removeDuplicates,
} from 'util/arrayUtil';

import styles from './richTextEditor.module.less';

const ERROR_MESSAGE_PLAN_EDITING = (
  <span>
    Changes cannot be saved until guide is refreshed. Please take note of your
    changes as they <u>will not</u> be saved when you refresh this page.
  </span>
);
export type MentionOptionProps = {
  value: any;
  label: any;
};
type MentionsShowProps = {
  isMentionEnable: true;
  fetchMentionItems: () => MentionOptionProps[];
};
type MentionsHideProps = {
  isMentionEnable?: false;
  fetchMentionItems?: () => MentionOptionProps[];
};
type RichTextEditorProps = {
  limit?: number;
  onChange?: Function;
  initialValue?: string;
  resetValue?: string;
  defaultText?: string;
  ref: React.Ref<any>;
  disabled?: boolean;
  height?: number | string;
  activateDefaultValue?: boolean;
  maxHeight?: number;
  detectUserInput?: Function;
  revision?: number;
  toolbarOptions?: string[];
  errorMessage?: string;
  placeholder?: string;
  countString?: string;
} & (MentionsShowProps | MentionsHideProps);

const MENTION_SEPARATE_KEY = '116F67A2239D23612C8383F753CD3';

const RichTextEditor = forwardRef((props: RichTextEditorProps, ref) => {
  const {
    limit,
    onChange,
    initialValue = '',
    defaultText = '',
    disabled = false,
    activateDefaultValue = true,
    height = 280,
    maxHeight,
    detectUserInput,
    resetValue = '',
    toolbarOptions = [],
    errorMessage,
    placeholder,
    countString = 'Max Character Count',
    isMentionEnable = false,
    fetchMentionItems,
  } = props;

  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState<string>(initialValue);
  const [editorContentLength, setEditorContentLength] = useState<number>(0);
  const [, setInitialEditorContent] = useState<string>(initialValue);
  const [isNotificationReceived, setNotificationReceived] =
    useState<boolean>(false);
  const notificationFlag = useContext(NotificationContext);

  const checkLimit: boolean = editorContentLength >= Number(limit);

  const setNotificationReceiveFlag = useCallback(() => {
    if (notificationFlag) {
      setNotificationReceived(true);
    }
  }, [notificationFlag]);

  useEffect(() => {
    if (activateDefaultValue) {
      setEditorContent(defaultText);
    }
    setNotificationReceiveFlag();
  }, [activateDefaultValue, defaultText, setNotificationReceiveFlag]);

  useEffect(() => {
    setInitialEditorContent(resetValue);
  }, [resetValue]);

  const checkCharacterCount = (event: any) => {
    detectUserInput && detectUserInput();
    if (limit) {
      if (editorContentLength >= limit && event.key !== 'Backspace') {
        event.preventDefault();
      }
    }
    if ((editorRef.current as any)?.editor) {
      const content = (editorRef.current as any).editor.contentDocument.body
        .innerText;
      const strippedContent = stripeContent(content);
      if (strippedContent === '' && event.key === 'Backspace') {
        setEditorContentLength(0);
      } else if (strippedContent === '' && event.key === ' ') {
        setEditorContentLength(1);
      }
    }
  };

  const stripeContent = (content: string) => {
    let strippedContent = content.replace(/(<([^>]+)>)/gi, '');
    strippedContent = strippedContent.replace(/\n/gi, '');
    strippedContent = strippedContent.replace(/&nbsp;/g, ' ');

    return strippedContent;
  };

  const handleEditorChange = (content: string) => {
    setEditorContent(content);
    if (onChange)
      onChange(content, (editorRef.current as any).editor.isDirty());
    if ((editorRef.current as any)?.editor) {
      const content = (editorRef.current as any).editor.contentDocument.body
        .innerText;
      const strippedContent = stripeContent(content);
      if (strippedContent === ' ') {
        setEditorContentLength(0);
      } else {
        setEditorContentLength(strippedContent.length);
      }
    }
  };

  const clearContent = useCallback(
    (value) => {
      if (value) {
        setEditorContent(value);
      } else {
        setInitialEditorContent(initialValue);
      }
    },
    [initialValue]
  );

  useImperativeHandle(
    ref,
    () => ({
      clearTextEditorContent(value: any) {
        clearContent(value);
      },
      getEditorRef() {
        return editorRef;
      },
      changeEditorContent(value: string) {
        setEditorContent(value);
      },
      resetUndo() {
        (editorRef.current as any).editor.undoManager.clear();
      },
    }),
    [clearContent]
  );

  const toolbarList: string[] = removeDuplicates([
    'formatselect',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'numlist',
    'bullist',
    'indent',
    'outdent',
    'customchecklist',
    'removeformat',
    'link',
    ...toolbarOptions,
  ]);

  return (
    <>
      <div className={styles.textEditor}>
        <Editor
          ref={editorRef}
          value={editorContent}
          onClick={checkCharacterCount}
          onKeyDown={checkCharacterCount}
          onEditorChange={handleEditorChange}
          disabled={disabled}
          onInit={(evt, editor) => {
            editor.setContent(initialValue);
          }}
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
          init={{
            placeholder: placeholder,
            height: maxHeight ? undefined : height,
            max_height: maxHeight,
            menubar: false,
            statusbar: false,
            media_poster: false,
            branding: false,
            paste_webkit_styles:
              'font-weight color font-size font-family margin',
            plugins:
              'paste lists link image lists advlist emoticons media hr autoresize table',
            default_link_target: '_blank',
            target_list: [{ title: 'New Window', value: '_blank' }],
            content_css: `${process.env.PUBLIC_URL}/tinymce/skins/content/default/custom.css`,
            noneditable_noneditable_class: 'fa',
            extended_valid_elements: 'span[*]',
            toolbar: toolbarList.join(' '),
            toolbar_mode: 'wrap',
            fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
            block_formats:
              'Header 1=h1;Header 2=h2;Header 3=h3;Header 4=h4;Header 5=h5;Header 6=h6; Paragraph=p;',
            contextmenu: false,
            paste_preprocess: function (plugin: any, args: any) {
              if (limit) {
                const content = (editorRef.current as any).editor
                  .contentDocument.body.innerText;
                const strippedContent = stripeContent(content);

                const pasteContent = stripeContent(args.content);
                const pasteLength = pasteContent.length;

                if (strippedContent.length + pasteLength >= limit) {
                  args.content = '';
                }
              }
            },

            setup: (editor) => {
              editor.on('undo', function () {
                const content = (editorRef.current as any).editor
                  .contentDocument.body.innerText;
                if (isEmpty(content?.trim())) {
                  (
                    editorRef.current as any
                  ).editor.contentDocument.body.innerHTML = initialValue;
                }
              });
              editor.on('ExecCommand', (event) => {
                const command = event.command;
                if (command === 'mceMedia') {
                  const tabElemsParent: any = document.querySelector(
                    '.tox-dialog__body-nav'
                  );
                  const tabElems = document.querySelectorAll(
                    'div[role="tablist"] .tox-tab'
                  );
                  tabElems.forEach((tabElem: any) => {
                    if (
                      tabElem.innerText === 'General' ||
                      'Embed' ||
                      'Advanced'
                    ) {
                      tabElem.style.display = 'none';
                      if (tabElemsParent) {
                        tabElemsParent.style.padding = '0px';
                      }
                    }
                  });
                }
              });
              if (isMentionEnable) {
                editor.ui.registry.addAutocompleter('autoCompleter-mentions', {
                  ch: '@',
                  minChars: 0,
                  columns: 1,
                  fetch: (pattern) => {
                    return new Promise(async (resolve) => {
                      const result: MentionOptionProps[] =
                        (fetchMentionItems && (await fetchMentionItems())) ||
                        [];

                      const filteredOptions = !!pattern
                        ? getFilteredOptionArray(
                            getSortOptionsCaseInsensitive(result, 'label'),
                            'label',
                            pattern
                          )
                        : getSortOptionsCaseInsensitive(result, 'label');

                      const dropdownOption = filteredOptions
                        ?.slice(0, 5)
                        ?.map((item) => ({
                          value: `${item.value} ${MENTION_SEPARATE_KEY} ${item.label}`,
                          text: item.label,
                        }));

                      resolve(dropdownOption);
                    });
                  },
                  onAction: (autocompleteApi, rng, value) => {
                    const name = `@${value
                      .split(MENTION_SEPARATE_KEY)[1]
                      .trim()}`;
                    const id = value.split(MENTION_SEPARATE_KEY)[0].trim();
                    const mention = `<span style="color: #317ad0" contenteditable=false ><span id="mention_id" name=${id}></span>${name}</span> `;

                    editor.selection.setRng(rng);
                    editor.insertContent(mention);
                    autocompleteApi.hide();
                  },
                });
              }
            },
          }}
        />
      </div>

      {limit && (
        <div
          className={`${styles.maxCountLabel} ${
            checkLimit && styles.maxCountLabelError
          }`}
        >
          <span>{`${countString} :`}</span> {`${editorContentLength}/${limit}`}
        </div>
      )}

      {isNotificationReceived && (
        <FixedAlertMessage
          className={styles.alertMessage}
          type="error"
          message={errorMessage || ERROR_MESSAGE_PLAN_EDITING}
        />
      )}
    </>
  );
});

RichTextEditor.displayName = 'RichTextEditor';

export default RichTextEditor;
