import { FC, useEffect } from 'react';

import { Badge, Col, notification, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch } from 'hooks/redux';
import OverviewHeader from 'modules/plans/components/OverviewPage/OverviewHeader/OverviewHeader';
import PlanInfoHeaderLabel from 'modules/employers/components/PlanInfoHeaderLabel/PlanInfoHeaderLabel';
import EditMedicalPlanModal from 'modules/plans/medical/components/EditMedicalPlanModal/EditMedicalPlanModal';
import {
  BenefitCovered,
  EditMdvTypes,
  rxFrequencies,
  RxTier,
  RxTierMailOrders,
} from 'modules/plans/constants';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';
import RxCost from 'model/plans/RxCost';
import MedicalPlan from 'model/plans/MedicalPlan';
import { findMedicalPlanById } from 'modules/plans/slices/medicalPlanSlice';
import { PLAN_NOTIFICATION_KEY } from 'constants/benguideCollaborationConstants';
import { BenefitCategory } from 'constants/commonConstants';
import {
  displayNumberWithDecimals,
  isNullOrUndefined,
} from 'modules/plans/utils';

import styles from './rxOverview.module.less';

type RXOverviewProps = {
  heading: string;
  rxCosts?: RxCost;
  mailOrderRxCosts?: RxCost;
  plan?: MedicalPlan;
  benefitKind?: string;
  fetchPlanWhenUpdated?: any;
  disableEditButton?: boolean;
  isLoading?: boolean;
  viewPlanRxModal?: boolean;
  setViewPlanRxModal: (value: boolean) => void;
};

const RXOverview: FC<RXOverviewProps> = (props) => {
  const {
    heading,
    rxCosts,
    mailOrderRxCosts,
    plan,
    benefitKind,
    fetchPlanWhenUpdated,
    disableEditButton,
    isLoading,
    viewPlanRxModal,
    setViewPlanRxModal,
  } = props;

  const params = useParams();
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (employerId && brokerId && params.planId && !plan) {
      dispatch(findMedicalPlanById(params.planId));
    }
  }, [dispatch, employerId, brokerId, params.planId, plan]);

  const editPlan = () => {
    setViewPlanRxModal && setViewPlanRxModal(true);
    if (fetchPlanWhenUpdated) {
      fetchPlanOnNotificationReceived();
      notification.close(PLAN_NOTIFICATION_KEY);
    }
  };

  const onClose = () => {
    setViewPlanRxModal && setViewPlanRxModal(false);
    if (employerId && brokerId && params.planId && !fetchPlanWhenUpdated) {
      dispatch(findMedicalPlanById(params.planId));
    }
  };

  const fetchPlanOnNotificationReceived = () => {
    if (params.planId) {
      dispatch(findMedicalPlanById(params.planId));
    }
  };

  const getInNetworkValue = (
    tierName: string,
    tierCosts: RxCost | undefined
  ) => {
    let inNetworkValue: string = '-';
    if (tierCosts && tierCosts?.inNetwork) {
      Object.keys(tierCosts?.inNetwork).forEach((tier) => {
        if (tier === tierName) {
          if (
            tierCosts?.inNetwork[tier].costSharingPolicy === 'NOT_APPLICABLE'
          ) {
            inNetworkValue = 'Not Applicable';
          } else {
            inNetworkValue = !isNullOrUndefined(
              tierCosts?.inNetwork[tier].copay
            )
              ? `$${displayNumberWithDecimals(
                  tierCosts?.inNetwork[tier].copay
                )} ` +
                formatFrequencyText(tierCosts?.inNetwork[tier].copayFrequency)
              : !isNullOrUndefined(tierCosts?.inNetwork[tier].coinsurance)
              ? tierCosts?.inNetwork[tier].coinsurance + '% '
              : tierCosts?.inNetwork[tier].info
              ? tierCosts?.inNetwork[tier].info
              : tierCosts?.inNetwork[tier].benefitCovered === BenefitCovered.NO
              ? 'Not Covered'
              : '-';
          }
        }
      });
    }
    return inNetworkValue;
  };

  const getOutNetworkValue = (
    tierName: string,
    tierCosts: RxCost | undefined
  ) => {
    let outNetworkValue: string = '-';
    if (tierCosts && tierCosts?.outOfNetwork) {
      Object.keys(tierCosts?.outOfNetwork).forEach((tier) => {
        if (tier === tierName) {
          outNetworkValue = !isNullOrUndefined(
            tierCosts?.outOfNetwork[tier].copay
          )
            ? `$${displayNumberWithDecimals(
                tierCosts?.outOfNetwork[tier].copay
              )} ` +
              formatFrequencyText(tierCosts?.outOfNetwork[tier].copayFrequency)
            : !isNullOrUndefined(tierCosts?.outOfNetwork[tier].coinsurance)
            ? tierCosts?.outOfNetwork[tier].coinsurance + '% '
            : tierCosts?.outOfNetwork[tier].info
            ? tierCosts?.outOfNetwork[tier].info
            : tierCosts?.outOfNetwork[tier].benefitCovered === BenefitCovered.NO
            ? 'Not Covered'
            : '-';
        }
      });
    }
    return outNetworkValue;
  };

  const formatTierLabelName = (tierName: string) => {
    if (Object.keys(RxTier).includes(tierName)) {
      return RxTier[tierName].label;
    }
  };

  const formatTierMailOrderLabelName = (tierName: string) => {
    if (Object.keys(RxTierMailOrders).includes(tierName)) {
      return RxTierMailOrders[tierName].label;
    }
  };

  const formatFrequencyText = (copayFrequency: string) => {
    if (copayFrequency === rxFrequencies[0].value) {
      return 'Per script';
    }
    if (copayFrequency === rxFrequencies[1].value) {
      return 'No info';
    }
    return '';
  };

  const checkInNetworkRxPriorToDeductible = (
    tierName: string,
    tierCosts: RxCost | undefined
  ) => {
    let isInNetworkPriorToDeductible: boolean = false;

    if (tierCosts && tierCosts?.inNetwork && tierCosts?.inNetwork[tierName]) {
      if (
        tierName &&
        !isEmpty(tierCosts?.inNetwork[tierName].copayPriorToDeductible) &&
        tierCosts?.inNetwork[tierName].benefitCovered === 'YES'
      ) {
        if (tierCosts?.inNetwork[tierName].copayPriorToDeductible === 'YES') {
          isInNetworkPriorToDeductible = true;
        } else {
          isInNetworkPriorToDeductible = false;
        }
      } else if (
        isNullOrUndefined(tierCosts?.inNetwork[tierName]?.coinsurance) &&
        isNullOrUndefined(tierCosts?.inNetwork[tierName]?.copay) &&
        isEmpty(tierCosts?.inNetwork[tierName]?.info)
      ) {
        isInNetworkPriorToDeductible = true;
      } else {
        isInNetworkPriorToDeductible = false;
      }
    }
    return isInNetworkPriorToDeductible;
  };

  const checkOutOfNetworkRxPriorToDeductible = (
    tierName: string,
    tierCosts: RxCost | undefined
  ) => {
    let isOutOfNetworkPriorToDeductible: boolean = false;

    if (
      tierCosts &&
      tierCosts?.outOfNetwork &&
      tierCosts?.outOfNetwork[tierName]
    ) {
      if (
        tierName &&
        !isEmpty(tierCosts?.outOfNetwork[tierName].copayPriorToDeductible) &&
        tierCosts?.outOfNetwork[tierName].benefitCovered === 'YES'
      ) {
        if (
          tierCosts?.outOfNetwork[tierName].copayPriorToDeductible === 'YES'
        ) {
          isOutOfNetworkPriorToDeductible = true;
        } else {
          isOutOfNetworkPriorToDeductible = false;
        }
      } else if (
        isNullOrUndefined(tierCosts?.outOfNetwork[tierName]?.coinsurance) &&
        isNullOrUndefined(tierCosts?.outOfNetwork[tierName]?.copay) &&
        isEmpty(tierCosts?.outOfNetwork[tierName]?.info)
      ) {
        isOutOfNetworkPriorToDeductible = true;
      } else {
        isOutOfNetworkPriorToDeductible = false;
      }
    }
    return isOutOfNetworkPriorToDeductible;
  };

  const showRxDeductibleFields = () => {
    return benefitKind === BenefitCategory.MEDICAL.value;
  };

  const showRxTierFields = (rxCost: RxCost | undefined, tier: string) => {
    return (
      ![
        RxTier.TIER_5.value,
        RxTier.TIER_6.value,
        RxTier.TIER_7.value,
        RxTier.TIER_8.value,
      ].includes(tier) ||
      Object.keys(rxCosts?.inNetwork).includes(tier) ||
      Object.keys(rxCosts?.outOfNetwork).includes(tier)
    );
  };

  return (
    <div>
      <Row>
        <OverviewHeader
          title={heading}
          onEditClick={() => {
            editPlan();
          }}
          disabled={disableEditButton}
        />
      </Row>
      {showRxDeductibleFields() && (
        <div className={styles.tableWrapper}>
          <Row>
            <Col span={18} className={styles.mainText}>
              <PlanInfoHeaderLabel
                labelHeaderText={['In Network', 'Out Of Network']}
              />
            </Col>
          </Row>
          <>
            {
              <InfoLabelSection
                labelText="Individual Rx Deductible"
                value={
                  !isNullOrUndefined(
                    (plan as MedicalPlan).rxDeductiblesAndOop
                      ?.individualDeductibleInNetworkCost
                  )
                    ? `${
                        (plan as MedicalPlan).rxDeductiblesAndOop
                          ?.individualDeductibleInNetworkCost
                      }`
                    : '-'
                }
                extraColValue1={
                  !isNullOrUndefined(
                    (plan as MedicalPlan).rxDeductiblesAndOop
                      ?.individualDeductibleOutOfNetworkCost
                  )
                    ? (plan as MedicalPlan).rxDeductiblesAndOop
                        ?.individualDeductibleOutOfNetworkCost
                    : '-'
                }
              />
            }
            {
              <InfoLabelSection
                labelText="Family Rx Deductible"
                value={
                  !isNullOrUndefined(
                    (plan as MedicalPlan).rxDeductiblesAndOop
                      ?.familyDeductibleInNetworkCost
                  )
                    ? `${
                        (plan as MedicalPlan).rxDeductiblesAndOop
                          ?.familyDeductibleInNetworkCost
                      }`
                    : '-'
                }
                extraColValue1={
                  !isNullOrUndefined(
                    (plan as MedicalPlan).rxDeductiblesAndOop
                      ?.familyDeductibleOutOfNetworkCost
                  )
                    ? (plan as MedicalPlan).rxDeductiblesAndOop
                        ?.familyDeductibleOutOfNetworkCost
                    : '-'
                }
              />
            }
            {
              <InfoLabelSection
                labelText="Individual Rx OOP Max"
                value={
                  !isNullOrUndefined(
                    (plan as MedicalPlan).rxDeductiblesAndOop
                      ?.individualOopMaxInNetworkCost
                  )
                    ? `${
                        (plan as MedicalPlan).rxDeductiblesAndOop
                          ?.individualOopMaxInNetworkCost
                      }`
                    : '-'
                }
                extraColValue1={
                  !isNullOrUndefined(
                    (plan as MedicalPlan).rxDeductiblesAndOop
                      ?.individualOopMaxOutOfNetworkCost
                  )
                    ? (plan as MedicalPlan).rxDeductiblesAndOop
                        ?.individualOopMaxOutOfNetworkCost
                    : '-'
                }
              />
            }
            {
              <InfoLabelSection
                labelText="Family Rx OOP Max"
                value={
                  !isNullOrUndefined(
                    (plan as MedicalPlan).rxDeductiblesAndOop
                      ?.familyOopMaxInNetworkCost
                  )
                    ? `${
                        (plan as MedicalPlan).rxDeductiblesAndOop
                          ?.familyOopMaxInNetworkCost
                      }`
                    : '-'
                }
                extraColValue1={
                  !isNullOrUndefined(
                    (plan as MedicalPlan).rxDeductiblesAndOop
                      ?.familyOopMaxOutOfNetworkCost
                  )
                    ? (plan as MedicalPlan).rxDeductiblesAndOop
                        ?.familyOopMaxOutOfNetworkCost
                    : '-'
                }
              />
            }
          </>
        </div>
      )}
      <div className={styles.tableWrapper}>
        <Row>
          <Col span={18} className={styles.mainText}>
            <PlanInfoHeaderLabel
              labelHeaderText={['In Network', 'Out Of Network']}
            />
          </Col>
        </Row>
        {Object.keys(RxTier).map((tier, index) => {
          if (
            isLoading &&
            ![
              RxTier.TIER_5.value,
              RxTier.TIER_6.value,
              RxTier.TIER_7.value,
              RxTier.TIER_8.value,
            ].includes(tier)
          ) {
            return (
              <InfoLabelSection
                key={index}
                labelText={formatTierLabelName(tier)}
                value={'-'}
                extraColValue1={'-'}
              />
            );
          } else if (
            !isLoading &&
            !isEmpty(rxCosts) &&
            showRxTierFields(rxCosts, tier)
          ) {
            return (
              <InfoLabelSection
                key={index}
                labelText={formatTierLabelName(tier)}
                value={
                  rxCosts && Object.keys(rxCosts?.inNetwork).includes(tier) ? (
                    checkInNetworkRxPriorToDeductible(tier, rxCosts) ||
                    isEmpty(rxCosts?.inNetwork[tier]?.info) ? (
                      getInNetworkValue(tier, rxCosts)
                    ) : (
                      <Badge count={1} size={'small'} offset={[7, 0]}>
                        {getInNetworkValue(tier, rxCosts)}
                      </Badge>
                    )
                  ) : (
                    '-'
                  )
                }
                extraColValue1={
                  rxCosts &&
                  Object.keys(rxCosts?.outOfNetwork).includes(tier) ? (
                    checkOutOfNetworkRxPriorToDeductible(tier, rxCosts) ||
                    isEmpty(rxCosts?.inNetwork[tier]?.info) ? (
                      getOutNetworkValue(tier, rxCosts)
                    ) : (
                      <Badge count={1} size={'small'} offset={[7, 0]}>
                        {getOutNetworkValue(tier, rxCosts)}
                      </Badge>
                    )
                  ) : (
                    '-'
                  )
                }
              />
            );
          }
        })}
        {Object.keys(RxTierMailOrders).map((tier, index) => {
          if (
            !isEmpty(mailOrderRxCosts) &&
            (!isEmpty(mailOrderRxCosts?.inNetwork) ||
              !isEmpty(mailOrderRxCosts?.outOfNetwork)) &&
            showRxTierFields(mailOrderRxCosts, tier)
          ) {
            return (
              <InfoLabelSection
                key={index}
                labelText={formatTierMailOrderLabelName(tier)}
                value={
                  mailOrderRxCosts &&
                  Object.keys(mailOrderRxCosts?.inNetwork).includes(tier) ? (
                    checkInNetworkRxPriorToDeductible(
                      tier,
                      mailOrderRxCosts
                    ) ? (
                      getInNetworkValue(tier, mailOrderRxCosts)
                    ) : !isLoading ? (
                      <Badge count={1} size={'small'} offset={[7, 0]}>
                        {getInNetworkValue(tier, mailOrderRxCosts)}
                      </Badge>
                    ) : (
                      '-'
                    )
                  ) : (
                    '-'
                  )
                }
                extraColValue1={
                  mailOrderRxCosts &&
                  Object.keys(mailOrderRxCosts?.outOfNetwork).includes(tier) ? (
                    checkOutOfNetworkRxPriorToDeductible(
                      tier,
                      mailOrderRxCosts
                    ) ? (
                      getOutNetworkValue(tier, mailOrderRxCosts)
                    ) : !isLoading ? (
                      <Badge count={1} size={'small'} offset={[7, 0]}>
                        {getOutNetworkValue(tier, mailOrderRxCosts)}
                      </Badge>
                    ) : (
                      '-'
                    )
                  ) : (
                    '-'
                  )
                }
              />
            );
          }
        })}
        <div className={styles.textWrapper}>
          <sup className={styles.superText}>1</sup> After Deductible is met
        </div>
        {benefitKind === BenefitCategory.MEDICAL.value && viewPlanRxModal && (
          <EditMedicalPlanModal
            plan={plan}
            onClose={onClose}
            isModalVisible={viewPlanRxModal}
            editType={EditMdvTypes.RX}
            setViewModal={setViewPlanRxModal}
          />
        )}
      </div>
    </div>
  );
};

export default RXOverview;
