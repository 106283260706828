import React, { FC, useEffect, useState } from 'react';
import { Col, Row, Select, Table, TablePaginationConfig } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import _, { isEmpty } from 'lodash';
import Icon from '@ant-design/icons';
import Filter from 'components/Filter/Filter';
import { DataColumn } from 'components/DataTable/DataColumn';
import SearchBar from 'components/SearchBar/SearchBar';
import {
  allOption,
  ALLOWED_INDIVIDUAL_SUB_TYPES,
} from 'constants/commonConstants';
import { benefitTypes } from 'modules/carriers/constants/carrierConstants';
import { claimsTypes } from 'modules/claims/constants/constants';
import ClaimsTypeFilter from 'components/Filter/ClaimsTypeFilter';
import { downloadInstructionPdf } from 'modules/issueslog/slices/issuesLogSlice';
import tableStyles from 'components/DataTable/datatable.module.less';
import selectIcon from 'assets/images/icon-caret-down.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/icon-download-black.svg';
import PaginationNav from 'components/TableCustomPagination/TableCustomPagination';

import { EMP_FOOTER_NOTE } from 'constants/aiAsstantConfigurationConstants';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import styles from './toolList.module.less';

interface ToolListProps {
  title: string;
  columns: DataColumn[];
  data: any[];
  hasBenefitsCategoryFilter?: boolean;
  hasClaimsTypeFilter?: boolean;
  isAccountIssuesLog?: boolean;
  search: string;
  handleSearch: any;
  isLoading: boolean;
  onSelectItems?: (selected: string[]) => void;
  filters?: React.ReactFragment;
  showSearch?: boolean;
  supporGuide?: React.ReactFragment;
  showHeader?: boolean;
  showFooter?: boolean;
}

const ToolList: FC<ToolListProps> = (props) => {
  const {
    title,
    columns,
    data,
    hasBenefitsCategoryFilter = false,
    hasClaimsTypeFilter = false,
    isAccountIssuesLog = false,
    search,
    handleSearch,
    isLoading,
    onSelectItems = () => {},
    filters,
    showSearch = true,
    supporGuide,
    showHeader = true,
    showFooter = false,
  } = props;

  const params = useParams();
  const { state: stateValue } = useLocation();
  const { userId } = stateValue || ({} as any);
  const [pageSize, setPageSize] = useState(10);
  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const [sorterValue, setSorterValue] = useState({});
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: pageSize,
    current: currentIndex,
    itemRender: PaginationNav,
    showTotal: (total, range) => (
      <div className={styles.totalCount}>
        {range[0]}-{range[1]} of {total}
      </div>
    ),
  });

  const filterBenefitCategories = () => {
    const allOptions = { label: 'All Benefit Categories', value: allOption };
    const options =
      Object.values(benefitTypes)
        ?.map((item) => ({
          label: item.label,
          value: item.value,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [];
    return [allOptions].concat(options);
  };

  const filterClaimsTypes = () => {
    const allOptions = { label: 'All Claims Types', value: allOption };
    const options =
      Object.values(claimsTypes)
        ?.map((item) => ({
          label: item.label,
          value: item.value,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [];
    return [allOptions].concat(options);
  };

  useEffect(() => {
    setPagination({
      ...pagination,
      pageSize: pageSize,
      current: currentIndex,
    });
    // eslint-disable-next-line
  }, [currentIndex, pageSize]);

  const securedDownloadProvisionInstructions = usePermitByUserType(
    <span className={styles.downloadLinks}>
      <a
        className={styles.downloadFileLink}
        onClick={() => {
          downloadInstructionPdf(params.brokerId);
        }}
      >
        Download Email Setup Instructions
        <DownloadIcon className={styles.downloadFileIcon} />
        <Icon type="file-text" />
      </a>
    </span>,
    ALLOWED_INDIVIDUAL_SUB_TYPES
  );

  return (
    <div className={styles.featureTableWrapper}>
      {showHeader && (
        <Row>
          <Col span={12}>
            <h1>{title}</h1>
            {supporGuide}
          </Col>
        </Row>
      )}
      <br />
      <Row className={styles.filterContainer}>
        {showSearch && (
          <div className={`${!showHeader && styles.noHeaderSearchWrapper} `}>
            <SearchBar placeholder={search} onChange={handleSearch} />
          </div>
        )}
        {hasBenefitsCategoryFilter && (
          <Filter
            options={filterBenefitCategories()}
            placeholder="All Benefit Categories"
            onSelectItems={() => {}}
            filterValue={userId}
            searchName="Search Benefit Categories"
            selected={[]}
            isFilterClose
          />
        )}
        {hasClaimsTypeFilter && (
          <ClaimsTypeFilter
            options={filterClaimsTypes()}
            placeholder="Claims Type"
            onSelectItems={onSelectItems}
            filterValue={userId}
            selected={[]}
          />
        )}
        {filters}
        {isAccountIssuesLog && securedDownloadProvisionInstructions}
      </Row>
      <br />

      <Row>
        <Col xs={24} className={styles.documentTable}>
          <Table
            className={`${tableStyles.tableWrapper} ${styles.tableWrapper}`}
            dataSource={data}
            columns={columns}
            pagination={pagination}
            loading={isLoading}
            tableLayout="fixed"
            onChange={(pagination, filter, sorter) => {
              const { current } = pagination;
              setCurrentIndex(current ?? 0);

              if (!_.isEqual(sorterValue, sorter) && !isEmpty(sorterValue)) {
                setCurrentIndex(1);
                setSorterValue(sorter);
              }
            }}
          />
        </Col>
      </Row>
      {showFooter && (
        <div className={styles.employerFooterNote}>
          <i>{EMP_FOOTER_NOTE}</i>
        </div>
      )}
      <Row>
        <Col>
          <Select
            value={pagination.pageSize}
            onChange={(e) => {
              setPageSize(e);
              setCurrentIndex(1);
            }}
            bordered={false}
            className={`${tableStyles.rowDropdown} ${styles.rowDropdown}`}
            suffixIcon={<img src={selectIcon} alt="select-icon" />}
          >
            {[10, 20, 50].map((pageSize) => (
              <Select.Option key={pageSize} value={pageSize}>
                View: {pageSize} Rows
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </div>
  );
};

export default ToolList;
