import { Input, Row, Select, Table } from 'antd';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { ReviewType } from 'modules/renewals/enums/ReviewType';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import iconRemove from 'assets/images/icon-remove-red.svg';
import { setStatus } from 'modules/renewals/slices/carrierQuoteAiSlice';
import LabelWithTooltip from 'components/LabelWithTooltip/LabelWithTooltip';
import { isEmptyOrUndefined } from 'util/stringUtil';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import ProcessStatus from 'modules/renewals/enums/QuoteUploadStatus';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import {
  PLAN_IDENTIFIER_TOOLTIP_CONTENT,
  PROCESSING_STARTED_TEXT,
  QUOTES_IDENTIFIER_ERROR,
  getQuoteReviewMessage,
} from 'modules/renewals/constants/renewalsConstants';

import styles from './quoteMapToPlan.module.less';

const { Option } = Select;

type CurrentPlan = { planIdentifier: string; currentPlan: string };

type Props = {
  quoteMapping: any[];
  changeQuoteMapping: Function;
  currentPlans: any;
  showErrorHighlight: boolean;
  setMappedPlans: Function;
  mappedPlans: string[];
  benefitKind?: string;
  showPlanIdentifierError?: boolean;
  handleQuoteProcessing: () => void;
  validationSuccessOrError?: () => JSX.Element | undefined;
  resetMappingErrors: Function;
  isOfferCreateLoading?: boolean;
  isAllDisabled?: boolean;
};

const QuoteMapToPlan = (props: Props) => {
  const {
    quoteMapping = [],
    changeQuoteMapping,
    currentPlans = {},
    showErrorHighlight,
    mappedPlans,
    setMappedPlans,
    showPlanIdentifierError,
    handleQuoteProcessing,
    validationSuccessOrError,
    resetMappingErrors,
    isOfferCreateLoading,
    isAllDisabled,
  } = props;
  const dispatch = useAppDispatch();
  const {
    status,
    isLoading: isLoadingQuoteAI,
    isPlanNamesValid,
    reviewType,
  } = useAppSelector((state) => state.renewals.quoteReaderSlice);

  const isLoading = isOfferCreateLoading || isLoadingQuoteAI || isAllDisabled;

  const isSelfReview = reviewType === ReviewType.AUTOMATIC;

  const isSelfReviewProcessing =
    (isSelfReview &&
      isPlanNamesValid &&
      [ProcessStatus.PROCESSING, ProcessStatus.VALIDATED].includes(status)) ??
    false;

  const isProcessing =
    [ProcessStatus.PROCESSING].includes(status) ||
    isLoading ||
    isSelfReviewProcessing;

  const loadingOnValidate = status === ProcessStatus.VALIDATING;

  const disableValidateButton = () => {
    const disableOnStatus: boolean = [
      ProcessStatus.UPLOADING,
      ProcessStatus.VALIDATING,
      ProcessStatus.VALIDATED,
      ProcessStatus.SUCCESS,
      ProcessStatus.PROCESSING,
      ProcessStatus.REVIEWED,
      ProcessStatus.FAILED,
    ].includes(status);
    return (
      disableOnStatus ||
      quoteMapping?.some((item) => isEmptyOrUndefined(item?.planIdentifier))
    );
  };

  const removeMappingField = (removedIndex: number) => {
    if (isLoading) return;

    if (status === ProcessStatus.VALIDATED) {
      dispatch(setStatus({ status: ProcessStatus.UPLOADED }));
    }

    const updatedQuoteMapping = [] as any[];

    quoteMapping?.map((item: any, index: number) => {
      if (index !== removedIndex) {
        updatedQuoteMapping.push(item);
      }
    });
    const currentPlansSelected = updatedQuoteMapping?.map(
      (item) => item?.currentPlan
    );

    const mappedPlansIds = Object.entries(currentPlans)
      ?.filter(([, value]) => currentPlansSelected?.includes(value))
      ?.map(([key]) => key);

    setMappedPlans(mappedPlansIds);
    changeQuoteMapping(updatedQuoteMapping);
    resetMappingErrors();
  };

  const filterMappedPlans = (currentSelected: string) => {
    const currentPlanArr: CurrentPlan[] = Object?.values(currentPlans);
    const mappedPlanNames = Object.entries(currentPlans)
      ?.filter(([key, _]) => mappedPlans.includes(key))
      ?.map(([_, value]) => value)
      ?.filter((planName) => planName !== currentSelected); // Exclude the currentSelected plan name

    return currentPlanArr?.filter(
      (plan: any) => !mappedPlanNames?.includes(plan)
    );
  };

  const addMappingField = () => {
    changeQuoteMapping([...quoteMapping, {}]);
    if (status === ProcessStatus.VALIDATED) {
      dispatch(setStatus({ status: ProcessStatus.UPLOADED }));
    }
    resetMappingErrors();
  };

  const selectOnChange = (selected: string, index: number) => {
    const updatedQuote = [...quoteMapping];
    updatedQuote[index] = {
      planIdentifier: updatedQuote?.[index]?.planIdentifier ?? '',
      currentPlan: selected,
    };

    const currentPlansSelected = updatedQuote.map((item) => item?.currentPlan);

    // Filter the currentPlans object's entries by checking if the value is included in the selected plans,
    // then map to get the keys (plan identifiers) for the matching entries
    const mappedPlansIds = Object.entries(currentPlans)
      ?.filter(([, value]) => currentPlansSelected?.includes(value))
      ?.map(([key]) => key);

    setMappedPlans(mappedPlansIds);
    changeQuoteMapping(updatedQuote);
    resetMappingErrors();
  };

  const identifierOnChange = (text: string, index: number) => {
    const updatedQuote = [...quoteMapping];
    updatedQuote[index] = {
      planIdentifier: text.trim(),
      currentPlan: updatedQuote?.[index]?.currentPlan ?? '',
    };
    changeQuoteMapping(updatedQuote);
    resetMappingErrors();
    if (status === ProcessStatus.VALIDATED) {
      dispatch(setStatus({ status: ProcessStatus.UPLOADED }));
    }
  };

  const findNonUniquePlanIdentifiers = (quoteMapping: any[]) => {
    const identifierCount = quoteMapping?.reduce((acc: any, item: any) => {
      if (item?.planIdentifier) {
        acc[item.planIdentifier] = (acc[item.planIdentifier] || 0) + 1;
      }
      return acc;
    }, {});

    return Object.keys(identifierCount).filter(
      (identifier) => identifierCount[identifier] > 1
    );
  };

  const teamMembersColumns = [
    {
      title: (
        <div>
          PLAN NAME *{' '}
          <LabelWithTooltip
            content={
              <div className={styles.popOverContent}>
                {PLAN_IDENTIFIER_TOOLTIP_CONTENT}
              </div>
            }
            mandatoryField={false}
            fieldText={''}
          />
        </div>
      ),
      dataIndex: 'planIdentifier',
      key: 'planIdentifier',
      width: '50%',
      render: (planIdentifier: string, data: any, index: number) => (
        <Row>
          {index !== 0 && (
            <img
              src={iconRemove}
              alt="remove-tier-icon"
              className={styles.iconRemove}
              onClick={(e) => {
                removeMappingField(index);
              }}
            />
          )}
          <div
            className={`${
              index !== 0 ? styles.inputWrapper : styles.inputWrapperInitial
            } ${
              showErrorHighlight &&
              isEmptyOrUndefined(quoteMapping?.[index]?.planIdentifier) &&
              !isEmptyOrUndefined(quoteMapping?.[index]?.currentPlan) &&
              styles.inputError
            }  ${
              (findNonUniquePlanIdentifiers(quoteMapping).includes(
                quoteMapping?.[index]?.planIdentifier
              ) ||
                quoteMapping?.[index]?.isError) &&
              styles.inputError
            }`}
          >
            <Input
              data-cy="planIdentifier"
              onChange={(e) =>
                identifierOnChange(e?.target?.value ?? '', index)
              }
              disabled={isProcessing}
              defaultValue={planIdentifier}
            />
            <Row justify="end" className={styles.inputError}>
              {quoteMapping?.[index]?.isError && 'Cannot be identified'}
            </Row>
          </div>
        </Row>
      ),
    },
    {
      title: '',
      dataIndex: 'icon',
      key: 'icon',
      width: '5%',
      render: () => <div className={styles.transitionArrow}>→</div>,
    },
    {
      title: 'MAP TO CURRENT PLANS *',
      dataIndex: 'currentPlan',
      key: 'currentPlan',
      width: '70%',
      render: (currentPlan: string, data: any, index: number) => (
        <div
          className={`${
            showErrorHighlight &&
            isEmptyOrUndefined(quoteMapping?.[index]?.currentPlan) &&
            !isEmptyOrUndefined(quoteMapping?.[index]?.planIdentifier) &&
            styles.errorSelect
          }`}
        >
          <Select
            data-cy="offerName"
            className={styles.inputWrapper}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(selected) => selectOnChange(selected, index)}
            value={quoteMapping?.[index]?.currentPlan}
            disabled={isProcessing}
          >
            <Option value={'N/A'} label={'N/A'} key={0}>
              N/A
            </Option>
            {filterMappedPlans(quoteMapping?.[index]?.currentPlan)?.map(
              (item, index) => {
                return (
                  <Option value={item} label={item} key={index + 1}>
                    {item}
                  </Option>
                );
              }
            )}
          </Select>
        </div>
      ),
    },
  ];

  const planIndentifierError = () => {
    const planIdentifiers = quoteMapping?.some(
      (item) => item.isError && item.isError
    );
    if (planIdentifiers) {
      return (
        <FixedAlertMessage type="error" message={QUOTES_IDENTIFIER_ERROR} />
      );
    }
  };

  useEffect(() => {
    if (!isEmpty(quoteMapping)) {
      const mappedPlanIds = Object.entries(currentPlans)
        ?.filter(([_, value]) =>
          quoteMapping?.map((item) => item?.currentPlan).includes(value)
        )
        ?.map(([key, _]) => key);
      setMappedPlans(mappedPlanIds);
    } else {
      setMappedPlans([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.quoteMapToPlanContainer}>
      {showPlanIdentifierError && (
        <FixedAlertMessage
          type="error"
          message={`Plan Name cannot be duplicated.`}
        />
      )}
      <div className={styles.wrapperContainer}>
        <Table
          key={quoteMapping.length}
          columns={teamMembersColumns}
          dataSource={quoteMapping}
          pagination={false}
          className={styles.tableContainer}
        />
        <div className={styles.buttonWrapper}>
          <LinkButton disabled={isLoading} onClick={() => addMappingField()}>
            + Add Another Plan
          </LinkButton>

          <PageActionButton
            type="primary"
            onClick={() => handleQuoteProcessing()}
            loading={(loadingOnValidate || isProcessing) && !isAllDisabled}
            disabled={disableValidateButton()}
            className={styles.processingButton}
          >
            Start Processing
          </PageActionButton>
        </div>
        <div className={styles.columnContainer}>
          <p className={styles.infoMessages}>
            {isSelfReviewProcessing && PROCESSING_STARTED_TEXT}
          </p>
          <p className={styles.infoMessages}>
            {getQuoteReviewMessage(
              status,
              reviewType,
              isSelfReviewProcessing,
              isPlanNamesValid!
            )}
          </p>
        </div>
        <div className={styles.opsWarningWrapper}>
          {planIndentifierError()}
          {validationSuccessOrError?.()}
        </div>
      </div>
    </div>
  );
};

export default QuoteMapToPlan;
