import { useState } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { isNumber } from 'lodash';

import TechAdminSsoTable from 'modules/sso/components/TechAdminSsoTable/TechAdminSsoTable';
import { useBrokerListQuery } from 'modules/sso/slices/ssoSlice';

import TablePagination from 'model/TablePagination';

import styles from 'modules/sso/pages/TechAdminSsoConfigPage/TechAdminSsoConfigPage.module.less';

const TechAdminSsoConfigPage = () => {
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    } as SorterResult<any>,
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: '',
    },
    filters: {},
  });
  const [searchText, setSearchText] = useState<string>('');
  const { columnKey, order } = (paginationConfig.sorterInfo as any) || {};

  const {
    data: brokerList,
    isFetching: brokerListIsLoading,
    refetch: brokerListRefetch,
  } = useBrokerListQuery(
    {
      size: paginationConfig.filterInfo.limit,
      page: paginationConfig.paginationIndex,
      query: paginationConfig.filterInfo.searchText,
      sort:
        order === undefined
          ? ''
          : order === 'descend'
          ? `-${columnKey}`
          : columnKey,
    },
    { refetchOnMountOrArgChange: true }
  );

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.title}>Single Sign-On (SSO)</div>
      <div className={styles.tableWrapper}>
        <TechAdminSsoTable
          paginationConfig={paginationConfig}
          setPaginationConfig={setPaginationConfig}
          data={brokerList?.content ?? []}
          totalData={
            isNumber(brokerList?.metadata?.total)
              ? Number(brokerList?.metadata?.total)
              : 0
          }
          loading={brokerListIsLoading}
          setSearchText={setSearchText}
          searchText={searchText}
          onUpdate={brokerListRefetch}
        />
      </div>
    </div>
  );
};

export default TechAdminSsoConfigPage;
