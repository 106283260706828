import { combineReducers } from '@reduxjs/toolkit';

import AdminReducer from 'modules/admins/slices/adminSlice';
import BrokerAdminListReducer from 'modules/admins/slices/brokerAdminSlice';
import LumityAdminReducer from 'modules/admins/slices/lumityAdminSlice';
import ERAdminReducer from 'modules/admins/slices/erAdminSlice';
import AdminConsultantValidateReducer from 'modules/admins/slices/adminConsultantValidateSlice';

const adminRootReducers = combineReducers({
  admins: AdminReducer,
  brokerAdmins: BrokerAdminListReducer,
  lumityAdmins: LumityAdminReducer,
  erAdmins: ERAdminReducer,
  adminConsultantValidate: AdminConsultantValidateReducer,
});

export default adminRootReducers;
