import axios from 'axios';
import DraftEmployer from 'model/DraftEmployer';
import { baseApi, getPagingQueryString } from 'util/apiUtil';
import ErrorDetails from 'model/ErrorDetails';

const employerUrl = baseApi + '/v1/employers';
const v2Url = baseApi + '/v2/employers';

export const getPlanYears = async (employerId: string) => {
  try {
    return await axios.get(`${employerUrl}/${employerId}/plan-years`);
  } catch (error) {
    throw error;
  }
};

export const uploadEmployerLogo = async (logo: Blob, original?: boolean) => {
  const formData = new FormData();
  formData.append('file', logo);
  if (original) {
    formData.append('original', 'true');
  }
  return axios.post(`${v2Url}/logo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getEmployerList = async (
  page: number,
  size: number,
  sort: string,
  query: string,
  organizations: string,
  locations: string,
  members: string,
  renewalDates: string,
  featureTools: string,
  archived: string
) => {
  try {
    return axios.get(
      `${v2Url}/?${getPagingQueryString(page, size, sort, query, 'query')}`,
      {
        params: {
          organizations,
          locations,
          members,
          renewalDates,
          featureTools,
          archived,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const createEmployer = async (employer: DraftEmployer) => {
  return axios.post(`${v2Url}`, employer);
};

export const validateEmployer = async (
  employerName: string,
  organizationId: string,
  employerId?: string
) => {
  try {
    if (employerId) {
      const payload = {
        employerName: employerName,
        organizationId: organizationId,
        employerId: employerId,
      };
      await axios.post(`${baseApi}/v2/employers/validate`, payload);
    } else {
      const payload = {
        employerName: employerName,
        organizationId: organizationId,
      };
      await axios.post(`${baseApi}/v2/employers/validate`, payload);
    }
  } catch (error: any) {
    if (error.response) {
      return { data: error.response.data } as ErrorDetails;
    } else {
      // TODO: need proper error handling
      console.log('Exception thrown ' + JSON.stringify(error));
    }
  }
  return null;
};

export const getBrokerEmployers = async (
  page: number,
  size: number,
  sort: string,
  query: string,
  organizationId: string,
  locations: string,
  members: string,
  renewalDates: string,
  featureTools: string
) => {
  try {
    return axios.get(
      `${v2Url}/detail?${getPagingQueryString(
        page,
        size,
        sort,
        query,
        'query'
      )}`,
      {
        params: {
          organizationId,
          locations,
          members,
          renewalDates,
          featureTools,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const findEmployer = (employerId: string) => {
  return axios.get(`${v2Url}/${employerId}`);
};

export const updateEmployer = async (employer: DraftEmployer) => {
  return axios.put(`${v2Url}`, employer);
};

export const createPlanYear = (employerId: string | undefined, data: any) => {
  return axios.post(`${employerUrl}/${employerId}/plan-years`, data);
};

export const updatePlanYear = (
  employerId: string | undefined,
  planYearId: string | undefined,
  data: any
) => {
  return axios.put(
    `${employerUrl}/${employerId}/plan-years/${planYearId}`,
    data
  );
};

export const deletePlanYear = (
  employerId: string | undefined,
  id: string | undefined
) => {
  return axios.delete(`${employerUrl}/${employerId}/plan-years/${id}`);
};

export const setCurrentPlanYear = (
  employerId: string | undefined,
  id: string | undefined
) => {
  return axios.post(`${employerUrl}/${employerId}/plan-years/current/${id}`);
};

export const getAccessibleTeamMembers = (employerId: string) => {
  return axios.get(`${v2Url}/${employerId}/team-members`);
};

export const updateTeam = (
  organizationId: string,
  employerId: string,
  individuals: string[]
) => {
  return axios.put(
    `${v2Url}/update/team?organizationId=${organizationId}&employerId=${employerId}&individuals=${individuals}`
  );
};

export const createBenefitClass = async (
  employerId: string,
  benefitClass: string[],
  planYearId: string
) => {
  try {
    return await axios.post(
      `${employerUrl}/${employerId}/plan-years/${planYearId}/groups`,
      benefitClass
    );
  } catch (error) {
    throw error;
  }
};

export const deleteBenefitClass = async (
  employerId: string,
  benefitClass: string,
  planYearId: string
) => {
  try {
    return axios.delete(
      `${employerUrl}/${employerId}/plan-years/${planYearId}/groups?group=${encodeURIComponent(
        benefitClass
      )}`
    );
  } catch (error) {
    throw error;
  }
};

export const editBenefitClass = async (
  employerId: string,
  planYearId: string,
  oldBenefitClass: string,
  newBenefitClass: string
) => {
  return axios.put(
    `${employerUrl}/${employerId}/plan-years/${planYearId}/benefit-class`,
    {
      oldBenefitClass,
      newBenefitClass,
    }
  );
};

export const getEmployerCount = (organizationId: string | null | undefined) => {
  return axios.get(`${v2Url}/count?organization-id=${organizationId}`);
};

export const getRenewalDates = (organizationId: string) => {
  return axios.get(
    `${employerUrl}/renewal-dates?organization-id=${organizationId}`
  );
};

export const validateEmployerEmailDomain = async (
  emailDomains: [],
  employerId: any
) => {
  if (emailDomains) {
    try {
      await axios.post(
        `${baseApi}/v2/employers/email-domain/validate?email-domains=${emailDomains}&employerId=${employerId}`
      );
    } catch (error: any) {
      if (error.response) {
        return { data: error.response.data } as ErrorDetails;
      } else {
        // TODO: need proper error handling
        console.error('Exception thrown ' + JSON.stringify(error));
      }
    }
  }
  return null;
};
