import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { cloneDeep, isArray, isEmpty, trim } from 'lodash';
import { Button, Popover } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import FilterWOMultiSelect, {
  OptionProps,
} from 'components/Filter/FilterWOMultiSelect';
import { removeDuplicateEmails, removeDuplicates } from 'util/arrayUtil';
import { isValidEmailFormat } from 'util/commonUtil';

import styles from './inputEmailChip.module.less';

type EmailList = string[];

type ShowAddMoreProps = {
  showAddMore: true;
  popoverOptions: OptionProps[];
  popDataLoading: boolean;
};

type HideAddMoreProps = {
  showAddMore?: false;
  popoverOptions?: OptionProps[];
  popDataLoading?: boolean;
};

type InputEmailChipProps = {
  defaultMailList?: EmailList;
  loggedUser?: { email: string; popoverMessage: string | React.ReactNode };
  checkEmpty?: boolean;
  removableDefaultMailList?: EmailList;
  getEmailList: ({
    emailList,
    isValidList,
    isEmptyList,
    defaultMailList,
    removableDefaultMails,
  }: {
    emailList: EmailList;
    isValidList: boolean;
    isEmptyList: boolean;
    defaultMailList: EmailList;
    removableDefaultMails: EmailList;
  }) => void;
  isEmployerSoldConfirmation?: boolean;
  setShowAlertMessage?: (isVisible: boolean) => void;
  alertEmails?: string[];
} & (ShowAddMoreProps | HideAddMoreProps);

const InputEmailChip = forwardRef((props: InputEmailChipProps, ref) => {
  const {
    defaultMailList = [],
    loggedUser,
    checkEmpty = false,
    showAddMore = false,
    popoverOptions = [],
    popDataLoading = false,
    getEmailList,
    removableDefaultMailList = [],
    isEmployerSoldConfirmation = false,
    setShowAlertMessage = () => {},
    alertEmails = [],
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const [emailList, setEmailList] = useState<EmailList>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [, setEmptyEmailList] = useState<boolean>(false);

  // state for popover add more
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [options, setOptions] = useState<OptionProps[]>([]);
  const [selectedOption, setSelectedOption] = useState<OptionProps[]>([]);

  // checking constant
  const isInvalidEmailList: boolean = isEmailValid(emailList);
  const disabledAddMore: boolean =
    popoverOptions?.length === selectedOption.length;

  useEffect(() => {
    if (showAddMore && !isEmployerSoldConfirmation) {
      const defRemovedPop = popoverOptions.filter(
        (item) =>
          ![...defaultMailList, ...removableDefaultMailList].some(
            (subItem) => subItem?.toLowerCase() === item.value.toLowerCase()
          )
      );
      setOptions(defRemovedPop);
    }
    // eslint-disable-next-line
  }, [showAddMore, popDataLoading, removableDefaultMailList]);

  useEffect(() => {
    if (showAddMore && isEmployerSoldConfirmation) {
      const defRemovedPop = popoverOptions.filter(
        (item) =>
          ![...defaultMailList, ...removableDefaultMailList].some(
            (subItem) => subItem?.toLowerCase() === item.value.toLowerCase()
          )
      );
      setOptions(defRemovedPop);
    }
    // eslint-disable-next-line
  }, [showAddMore, popDataLoading]);

  useEffect(() => {
    if (defaultMailList.length !== 0) {
      setEmailList(removeDuplicateEmails([...defaultMailList]));
    }
  }, [defaultMailList]);

  useEffect(() => {
    if (!isEmpty(removableDefaultMailList)) {
      setEmailList(
        removeDuplicateEmails([
          ...defaultMailList,
          ...removableDefaultMailList,
          ...emailList,
        ])
      );
    }
    // eslint-disable-next-line
  }, [removableDefaultMailList]);

  // function used because Variable 'isEmailValid' is used before being assigned
  function isEmailValid(emailList: EmailList) {
    return emailList?.some(
      (element) => trim(element) && !isValidEmailFormat(trim(element))
    );
  }

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handelSetNewItemToList = (value: string | string[]) => {
    let updatedList: EmailList;
    if (isArray(value)) {
      updatedList = removeDuplicateEmails([...emailList, ...value]);
    } else {
      updatedList = removeDuplicateEmails([...emailList, value]);
    }
    updatedList.forEach((emailAddress) => {
      const findValueOptions = popoverOptions?.find(
        (item) => item?.value.toLowerCase() === emailAddress?.toLowerCase()
      );
      if (findValueOptions) {
        const popOverOption = cloneDeep(options);
        const emailAddressIndex = popOverOption.findIndex(
          (item: OptionProps) =>
            item?.value.toLowerCase() === emailAddress?.toLowerCase()
        );
        if (emailAddressIndex > -1) {
          popOverOption.splice(emailAddressIndex, 1);
          setOptions([...popOverOption]);
        }
      }
    });
    setEmailList(updatedList);
    setInputValue('');
    isEmailListEmpty(updatedList);
    getEmailList &&
      getEmailList({
        emailList: updatedList,
        isValidList: isEmailValid(updatedList),
        isEmptyList: updatedList.length === 0,
        defaultMailList: defaultMailList,
        removableDefaultMails: removableDefaultMailList,
      });
  };

  const removeEmailId = (removableIndex: number, value: string) => {
    const updatedEmailIds: EmailList = [...emailList];

    const defaultCheck = defaultMailList.includes(value);

    const removableDefaultCheck =
      removableDefaultMailList.includes(value) || alertEmails.includes(value);

    if (removableDefaultCheck && isEmployerSoldConfirmation) {
      setShowAlertMessage(true);
    }

    if (!defaultCheck) {
      updatedEmailIds.splice(removableIndex, 1);
      setEmailList(updatedEmailIds);
      isEmailListEmpty(updatedEmailIds);
      getEmailList &&
        getEmailList({
          emailList: updatedEmailIds,
          isValidList: isEmailValid(updatedEmailIds),
          isEmptyList: updatedEmailIds.length === 0,
          defaultMailList: defaultMailList,
          removableDefaultMails: removableDefaultMailList,
        });
    }

    if (showAddMore) {
      const checkOptionExists = selectedOption?.some(
        (item) => item?.value.toLowerCase() === value?.toLowerCase()
      );
      const findValueOptions = popoverOptions?.find(
        (item) => item?.value.toLowerCase() === value?.toLowerCase()
      );
      if (findValueOptions) {
        setOptions([...options, findValueOptions!]);
      }
      if (Boolean(checkOptionExists)) {
        const findValueOptions = popoverOptions?.find(
          (item) => item?.value.toLowerCase() === value?.toLowerCase()
        );

        setOptions([...options, findValueOptions!]);
        setSelectedOption(selectedOption.filter((i) => i?.value !== value));
      }
    }
  };

  const handleEmailKeyEvents = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
      case 'Tab':
      case ',':
      case ' ':
        e.preventDefault();
        if (inputValue !== '') {
          addEmailId();
        }
        break;
      case 'Backspace':
        if (inputValue === '' && emailList.length > 0) {
          const lastIndex = emailList.length - 1;
          removeEmailId(lastIndex, emailList[lastIndex]);
        }
        break;
      default:
        return;
    }
  };

  const addEmailId = () => {
    const trimmedInputEmailValue = inputValue && inputValue.trim();
    if (trimmedInputEmailValue) {
      handelSetNewItemToList(trimmedInputEmailValue);
    }
  };

  const onBlur = () => {
    addEmailId();
  };

  const isEmailListEmpty = (emailList: EmailList) => {
    if (checkEmpty) {
      setEmptyEmailList(isEmpty(emailList));
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      getEmptyError(list: string[]) {
        if (checkEmpty) {
          isEmailListEmpty(list);
        }
      },
    }),
    // eslint-disable-next-line
    [checkEmpty]
  );

  const onContainerClick = () => {
    if (inputRef.current) {
      return inputRef.current.focus();
    }
  };

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const pasteContent = inputValue + e.clipboardData.getData('text');
    const pastedEmails = pasteContent
      .split(',')
      .map((email) => email.trim())
      .filter((email) => Boolean(email));

    if (pastedEmails) {
      handelSetNewItemToList(pastedEmails);
    }
  };

  // popover functions
  const onVisibleChange = (visible: boolean) => {
    setIsModalOpen(visible);
  };

  const onSelectOption = (option: OptionProps) => {
    handelSetNewItemToList(option.value);
    setOptions(options.filter((i) => i.value !== option.value));
    setSelectedOption(removeDuplicates([...selectedOption, option]));
    onVisibleChange(false);
  };

  const renderEmailChips = () => {
    const content = loggedUser?.popoverMessage;
    return emailList?.map((item, index) => {
      const defaultCheck = defaultMailList.includes(item);
      const loggedUserCheck =
        defaultMailList.includes(String(loggedUser?.email)) &&
        item === loggedUser?.email;

      return loggedUserCheck ? (
        <Popover
          key={index}
          placement="right"
          overlayClassName="popoverEmailChip"
          content={content}
        >
          <div
            className={`${styles.tagItem} ${
              defaultCheck ? styles.defaultCheck : ''
            } ${!isValidEmailFormat(item) && styles.invalidTag}`}
            onClick={onContainerClick}
            onFocus={onContainerClick}
          >
            <span className={styles.tagText}>{item}</span>
            {!defaultCheck && (
              <button
                type="button"
                className={`${styles.button} ${
                  !isValidEmailFormat(item) && styles.buttonInvalid
                }`}
                onClick={() => removeEmailId(index, item)}
              >
                &times;
              </button>
            )}
          </div>
        </Popover>
      ) : (
        <div
          key={index}
          className={`${styles.tagItem} ${
            defaultCheck ? styles.defaultCheck : ''
          } ${!isValidEmailFormat(item) && styles.invalidTag}`}
          onClick={onContainerClick}
          onFocus={onContainerClick}
        >
          <span className={styles.tagText}>{item}</span>
          {!defaultCheck && (
            <button
              type="button"
              className={`${styles.button} ${
                !isValidEmailFormat(item) && styles.buttonInvalid
              }`}
              onClick={() => removeEmailId(index, item)}
            >
              &times;
            </button>
          )}
        </div>
      );
    });
  };

  const content = (
    <FilterWOMultiSelect options={options} onSelectOption={onSelectOption} />
  );

  return (
    <div>
      <div
        className={`${styles.emailChipsInputContainer} ${
          isInvalidEmailList && styles.invalidList
        }`}
      >
        {showAddMore && (
          <Popover
            content={content}
            trigger="click"
            placement="bottomLeft"
            visible={
              isModalOpen === true &&
              disabledAddMore === false &&
              options.length !== 0
            }
            onVisibleChange={onVisibleChange}
            getPopupContainer={(trigger: any) => {
              return trigger.parentElement.parentElement;
            }}
          >
            <Button
              disabled={disabledAddMore || options.length === 0}
              className={styles.addMore}
              shape="circle"
              size="small"
              icon={<PlusOutlined className={styles.plusIcon} />}
            />
          </Popover>
        )}

        {renderEmailChips()}
        <input
          className={styles.emailInput}
          ref={inputRef}
          value={inputValue}
          onChange={handleEmailChange}
          onKeyDown={handleEmailKeyEvents}
          onBlur={onBlur}
          onPaste={onPaste}
        />
      </div>
      {isInvalidEmailList && (
        <div className={styles.invalidErrorMessage}>
          Please enter a valid email
        </div>
      )}
    </div>
  );
});
InputEmailChip.displayName = 'InputEmailChip';
export default InputEmailChip;
