import { FC, useEffect, useState, useCallback } from 'react';
import { StompSessionProvider } from 'react-stomp-hooks';

import { Col, Row } from 'antd';

import { useParams, useNavigate } from 'react-router-dom';
import { usePermitIf } from 'hooks/usePermitIf';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import EnrollmentOverview from 'modules/plans/components/Enrollments/EnrollmentOverview/EnrollmentOverview';
import BasicPlanInfoOverview from 'modules/plans/components/OverviewPage/BasicPlanInfoOverview/BasicPlanInfoOverview';
import PremiumsOverview from 'modules/plans/components/OverviewPage/PremiumsOverview/PremiumsOverview';
import ServicesOverview from 'modules/plans/components/OverviewPage/ServicesOverview/ServicesOverview';
import PlanDocumentsOverview from 'modules/plans/components/OverviewPage/PlanDocumentsOverview/PlanDocumentOverview';
import RXOverview from 'modules/plans/medical/components/RXOverview/RXOverview';
import DeductiblesOverview from 'modules/plans/components/OverviewPage/DeductiblesOverview/DeductiblesOverview';
import RxCardInfoOverview from 'modules/plans/components/OverviewPage/RxCardInfoOverview/RxCardInfoOverview';
import PlanCollaborationNotification from 'components/PlanCollaborationNotification/PlanCollaborationNotification';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import HeaderNavigation from 'modules/plans/components/HeaderNavigation/HeaderNavigation';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { allowedCommonButtonPermittedTypes } from 'constants/permittedConstants';
import { BenefitCategory } from 'constants/commonConstants';
import { wsBaseApi } from 'util/apiUtil';
import {
  findMedicalPlanById,
  getPlanServicesList,
} from 'modules/plans/slices/medicalPlanSlice';

import styles from './medicalOverviewPage.module.less';

const MedicalOverviewPage: FC = () => {
  const params = useParams();
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();

  const { medicalPlan, editedMedicalPlan, planServiceList, inProgress } =
    useAppSelector((state) => state.plan.plans);

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const navigate = useNavigate();

  const [viewPlanModal, setViewPlanModal] = useState(false);
  const [viewPlanPremiumModal, setViewPlanPremiumModal] = useState(false);
  const [viewPlanEnrollmentModal, setViewEnrollmentModal] = useState(false);
  const [viewPlanDeductibleModal, setViewPlanDeductibleModal] = useState(false);
  const [viewPlanServicesModal, setViewPlanServicesModal] = useState(false);
  const [viewPlanDocumentsModal, setViewPlanDocumentsModal] = useState(false);
  const [viewPlanRxModal, setViewPlanRxModal] = useState(false);

  const [disableEditButton, setDisableEditButton] = useState(false);
  const [fetchPlanWhenUpdated, setFetchPlanWenUpdated] =
    useState<boolean>(false);

  const refreshPlan = useCallback(() => {
    if (employerId && brokerId && params.planId) {
      dispatch(findMedicalPlanById(params.planId));
    }
    setFetchPlanWenUpdated(false);
  }, [dispatch, employerId, brokerId, params.planId]);

  useEffect(() => {
    if (employerId && brokerId && params.planId && !medicalPlan) {
      dispatch(findMedicalPlanById(params.planId));
    }
    setFetchPlanWenUpdated(false);
  }, [dispatch, employerId, brokerId, params.planId, medicalPlan]);

  useEffect(() => {
    dispatch(
      getPlanServicesList(BenefitCategory.MEDICAL.value, employerId || '')
    );
  }, [dispatch, employerId]);

  const getDefaultMedicalServices = () => {
    return planServiceList.defaults
      .filter((service) => service.isDefault && service.isSaasSupported)
      .map((service) => ({
        shortName: service.shortName,
        code: service.code,
      }));
  };

  const exitPlan = () => {
    navigate(`/brokers/${brokerId}/employers/${employerId}/medical`);
  };

  const updateViewPlanModal = (value: boolean) => {
    setViewPlanModal(value);
  };

  const updateViewPlanPremiumModal = (value: boolean) => {
    setViewPlanPremiumModal(value);
  };

  const updateViewPlanEnrollment = (value: boolean) => {
    setViewEnrollmentModal(value);
  };

  const updateViewPlanDeductibleModal = (value: boolean) => {
    setViewPlanDeductibleModal(value);
  };

  const updateViewPlanServicesModal = (value: boolean) => {
    setViewPlanServicesModal(value);
  };

  const updateViewPlanDocumentsModal = (value: boolean) => {
    setViewPlanDocumentsModal(value);
  };

  const updateViewPlanRxModal = (value: boolean) => {
    setViewPlanRxModal(value);
  };

  const navigation = {
    name: 'All Medical Plans',
    link: `/brokers/${brokerId}/employers/${employerId}/medical`,
  };

  const securedBreadcrumb = usePermitIf(
    <BreadcrumbNavigation breadCrumbData={[navigation]} />,
    allowedCommonButtonPermittedTypes,
    []
  );

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col span={24}>
          <Row>{securedBreadcrumb}</Row>
          <Row>
            <Col span={18}>
              <h1 className={styles.pageTitle}>
                {medicalPlan?.name && (
                  <OverflowPopover>{`${medicalPlan.name}`}</OverflowPopover>
                )}
              </h1>
            </Col>
          </Row>
          <Row>
            <HeaderNavigation
              headerItems={[
                {
                  headerName: 'Basic Plan Info',
                  headerNavigation: '#Plan-Info',
                },
                {
                  headerName: 'Premiums & Contributions',
                  headerNavigation: '#Premium',
                },
                {
                  headerName: 'Deductibles & OOP Max',
                  headerNavigation: '#Deductible',
                },
                { headerName: 'Services', headerNavigation: '#Service' },
                { headerName: 'RX', headerNavigation: '#RX' },
                {
                  headerName: 'ID Card Information',
                  headerNavigation: '#Rx-Card-Info',
                },
                {
                  headerName: 'Enrollments',
                  headerNavigation: '#Enrollments',
                },
                { headerName: 'Plan Resources', headerNavigation: '#Plan_Doc' },
              ]}
            />
          </Row>
          <div className={styles.overview} id={'Plan-Info'}>
            <StompSessionProvider
              url={`${wsBaseApi}/websocket-plan-update`}
              onWebSocketError={(e) => console.error(e)}
              reconnectDelay={500}
            >
              <PlanCollaborationNotification
                loggedInUserIndividualId={appBootInfo?.individualId}
                exitPlan={() => exitPlan()}
                refreshPlan={() => refreshPlan()}
                planId={medicalPlan.name}
                isInEdit={
                  viewPlanModal ||
                  viewPlanPremiumModal ||
                  viewPlanDeductibleModal ||
                  viewPlanServicesModal ||
                  viewPlanDocumentsModal ||
                  viewPlanEnrollmentModal ||
                  viewPlanRxModal
                }
                disableEdit={() => setDisableEditButton(true)}
                fetchPlan={() => setFetchPlanWenUpdated(true)}
              />
            </StompSessionProvider>
            <BasicPlanInfoOverview
              heading={'Basic Plan Info '}
              planName={medicalPlan.name}
              planYearName={medicalPlan.planYear?.name}
              benefitClasses={medicalPlan.groups}
              carrierName={
                medicalPlan.benefitCarrier
                  ? medicalPlan.benefitCarrier?.name
                  : ''
              }
              groupId={medicalPlan.groupId}
              planNetwork={medicalPlan.planNetworkName}
              planType={medicalPlan.type}
              hsaEligibile={medicalPlan.hsaCompatible}
              statesTerritories={medicalPlan.states}
              viewPlanModal={viewPlanModal}
              setViewPlanModal={updateViewPlanModal}
              disableEditButton={disableEditButton}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              type={'MEDICAL'}
              exitPlan={() => exitPlan()}
              fundingType={medicalPlan.fundingType}
              individualStopLoss={medicalPlan.individualStopLoss}
              individualAdministrationFee={
                medicalPlan.individualAdministrationFee
              }
              aggregatedStopLoss={medicalPlan.aggregatedStopLoss}
              aggregatedAdministrationFee={
                medicalPlan.aggregatedAdministrationFee
              }
              thirdPartyAdministrationFee={
                medicalPlan.thirdPartyAdministrationFee
              }
              otherFees={medicalPlan.otherFees}
              hraEligibile={medicalPlan.hraCompatible}
            />
          </div>
          <div className={styles.overview} id={'Premium'}>
            <PremiumsOverview
              heading={'Premiums & Contributions'}
              plan={medicalPlan}
              editedPlan={editedMedicalPlan}
              benefitKind={BenefitCategory.MEDICAL.value}
              disableEditButton={disableEditButton}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              viewPlanPremiumModal={viewPlanPremiumModal}
              setViewPlanPremiumModal={(value) =>
                updateViewPlanPremiumModal(value)
              }
            />
          </div>
          <div className={styles.overview} id={'Deductible'}>
            <DeductiblesOverview
              heading={'Deductibles & OOP Max'}
              plan={medicalPlan}
              benefitKind={BenefitCategory.MEDICAL.value}
              disableEditButton={disableEditButton}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              viewPlanDeductibleModal={viewPlanDeductibleModal}
              setViewPlanDeductibleModal={(value) =>
                updateViewPlanDeductibleModal(value)
              }
            />
          </div>
          <div className={styles.overview} id={'Service'}>
            <ServicesOverview
              heading={'Services'}
              plan={medicalPlan}
              benefitKind={BenefitCategory.MEDICAL.value}
              disableEditButton={disableEditButton}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              defaultServices={getDefaultMedicalServices()}
              isLoading={inProgress}
              viewPlanServicesModal={viewPlanServicesModal}
              setViewPlanServicesModal={(value) =>
                updateViewPlanServicesModal(value)
              }
            />
          </div>
          <div className={styles.overview} id={'RX'}>
            <RXOverview
              heading={'Rx'}
              rxCosts={medicalPlan.rxCosts}
              mailOrderRxCosts={medicalPlan.mailOrderRxCosts}
              benefitKind={BenefitCategory.MEDICAL.value}
              disableEditButton={disableEditButton}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              plan={medicalPlan}
              isLoading={inProgress}
              viewPlanRxModal={viewPlanRxModal}
              setViewPlanRxModal={(value) => updateViewPlanRxModal(value)}
            />
          </div>
          <div className={styles.overview} id={'Rx-Card-Info'}>
            <RxCardInfoOverview
              heading={'ID Card Information'}
              disableEditButton={disableEditButton}
              benefitKind={BenefitCategory.MEDICAL.value}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              rxBinNumber={medicalPlan.rxBinNumber}
              rxPcnNumber={medicalPlan.rxPcnNumber}
              rxGroupNumber={medicalPlan.rxGroupNumber}
              medicalPlan={medicalPlan}
            />
          </div>
          <div className={styles.overview} id={'Enrollments'}>
            <EnrollmentOverview
              heading={'Enrollments'}
              plan={medicalPlan}
              editedPlan={editedMedicalPlan}
              benefitKind={BenefitCategory.MEDICAL.value}
              disableEditButton={disableEditButton}
              fetchPlanWhenUpdated={fetchPlanWhenUpdated}
              viewPlanEnrollmentModal={viewPlanEnrollmentModal}
              setViewPlanEnrollmentModal={(value) =>
                updateViewPlanEnrollment(value)
              }
            />
          </div>
          <div className={styles.overview} id={'Plan_Doc'}>
            <PlanDocumentsOverview
              heading={'Plan Resources'}
              plan={medicalPlan}
              documents={medicalPlan.documents}
              benefitKind={BenefitCategory.MEDICAL.value}
              viewPlanDocumentsModal={viewPlanDocumentsModal}
              setViewPlanDocumentsModal={(value) =>
                updateViewPlanDocumentsModal(value)
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MedicalOverviewPage;
