import { ChangeEvent, useState } from 'react';
import { Divider, Input, Popover, Row } from 'antd';
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';
import { useNavContext } from 'hooks/useNavContext';
import TextButton from 'components/buttons/TextButton/TextButton';
import {
  DENTAL_ADDITIONAL_SERVICE_PRIORITY_ORDER,
  IN_NETWORK_VALUE,
  OFFER_CATEGORY,
  OUT_NETWORK_VALUE,
  SERVICE_PRIORITY_ORDER,
  VISION_ADDITIONAL_SERVICE_BASIC_PROFILE_PRIORITY_ORDER,
  VISION_ADDITIONAL_SERVICE_PRIORITY_ORDER,
  TOOLTIP_CONST,
} from 'modules/renewals/constants/renewalsConstants';
import { Plan } from 'modules/renewals/types/planTypes';
import { findInArray } from 'modules/renewals/utils/addPlanDetailUtil';
import iconRemove from 'assets/images/icon-remove-red.svg';
import AdditionalServiceModal from 'modules/renewals/components/AddPlanDetailsModal/AddPlanForm/MDVServicesSection/AddAdditionalServiceModal/AdditionalServiceModal';
import useGetUserFeatureStatus from 'hooks/useGetUserFeatureStatus';
import { FEATURE_KEYS } from 'constants/commonConstants';
import { ReactComponent as AIFeedbackRedFlag } from 'assets/images/ai-red-flag.svg';
import styles from 'modules/renewals/components/AddPlanDetailsModal/AddPlanForm/addPlanForm.module.less';

type Props = {
  selectedData: Plan | any;
  setSelectedData: (data: Plan | any) => void;
  benefitType: string;
  updateObject: (
    property: string,
    value: string | any,
    isArray: boolean,
    findObj?: any
  ) => void;
  isQuotePlansApproved?: boolean;
};

const MDVServicesSection = (props: Props) => {
  const {
    selectedData,
    benefitType,
    setSelectedData,
    updateObject,
    isQuotePlansApproved,
  } = props;

  const [isAddAdditionalServiceModalShow, setAddAdditionalServiceModalShow] =
    useState<boolean>(false);

  const [isAddServiceDisable, setAddServiceDisable] = useState<boolean>(false);

  const { brokerId, employerId } = useNavContext();

  const getDefaultServicesList = () => {
    let services = [] as string[];

    selectedData?.services?.map((item: any) => {
      if (!services?.includes(item?.name)) {
        services = [...services, item?.name];
      }
    });

    services = services?.sort(
      (x: string, y: string) =>
        SERVICE_PRIORITY_ORDER?.[benefitType?.toUpperCase()]?.indexOf(x) -
        SERVICE_PRIORITY_ORDER?.[benefitType?.toUpperCase()]?.indexOf(y)
    );

    return services;
  };

  const getAdditionalServicesWithValues = (
    isAdvancedProfile: boolean | undefined,
    benefitType: string
  ) => {
    let additionalServicesWithValues = [] as string[];

    // get additional services based on the benefit type and profile
    const getDVServices = () => {
      return {
        DENTAL: isAdvancedProfile
          ? DENTAL_ADDITIONAL_SERVICE_PRIORITY_ORDER
          : [],
        VISION: isAdvancedProfile
          ? VISION_ADDITIONAL_SERVICE_PRIORITY_ORDER
          : VISION_ADDITIONAL_SERVICE_BASIC_PROFILE_PRIORITY_ORDER,
      };
    };

    const services = getDVServices();

    selectedData?.additionalServices?.forEach((item: any) => {
      if (!additionalServicesWithValues?.includes(item?.name)) {
        additionalServicesWithValues = [
          ...additionalServicesWithValues,
          item?.name,
        ];
      }
    });

    if (benefitType.toUpperCase() in services) {
      const validServices =
        services[benefitType.toUpperCase() as keyof typeof services];

      additionalServicesWithValues = additionalServicesWithValues
        .filter((service) => validServices.includes(service))
        .sort((x, y) => validServices.indexOf(x) - validServices.indexOf(y));
    }

    return additionalServicesWithValues;
  };

  const addAdditionalService = (additionalServices: any) => {
    const updatedAdditionalServices = [...selectedData.additionalServices];
    additionalServices.forEach((service: any) => {
      const index = updatedAdditionalServices.findIndex(
        (item: any) =>
          item.name === service.name && item.fieldType === service.fieldType
      );
      const updatedService = { ...service, added: true };
      if (index !== -1) {
        updatedAdditionalServices[index] = updatedService;
      } else {
        updatedAdditionalServices.push(updatedService);
      }
    });
    setSelectedData({
      ...selectedData,
      additionalServices: updatedAdditionalServices,
    });
  };

  const handleRemoveAdditionalService = (name: string) => {
    const updatedAdditionalServices = selectedData?.additionalServices?.filter(
      (item: any) => item.name !== name
    );

    setSelectedData({
      ...selectedData,
      additionalServices: updatedAdditionalServices,
    });
  };

  const isAdvancedProfile = useGetUserFeatureStatus(
    brokerId,
    employerId,
    FEATURE_KEYS.ADVANCED_PROFILE,
    false
  );

  return (
    <>
      <div>
        <div className={styles.subTitle}>Services</div>
        <Divider className={styles.divider} />
        <div className={styles.innerWrapper}>
          <Row justify={'end'}>
            <div className={styles.inNetworkText}>In-Network</div>
            <div className={styles.outNetworkText}>Out-of-Network</div>
          </Row>

          {getDefaultServicesList()?.map((item: string, index: number) => {
            const inNetworkServiceLLMObj = findInArray(
              selectedData?.llmExtractionInfo?.services,
              item,
              IN_NETWORK_VALUE,
              null
            );
            const outNetworkServiceLLMObj = findInArray(
              selectedData?.llmExtractionInfo?.services,
              item,
              OUT_NETWORK_VALUE,
              null
            );

            const inNetworkObject = findInArray(
              selectedData?.services,
              item,
              IN_NETWORK_VALUE,
              null
            );

            const outNetworkObject = findInArray(
              selectedData?.services,
              item,
              OUT_NETWORK_VALUE,
              null
            );

            const tooltipContent = TOOLTIP_CONST[item];

            return (
              <Row
                justify={'space-between'}
                className={styles.subFieldItems}
                key={index}
              >
                <div className={styles.subFieldItemLabelWrapper}>
                  {item}
                  {tooltipContent && (
                    <Popover
                      content={<div>{tooltipContent.subtitle}</div>}
                      placement="bottomLeft"
                      overlayClassName="popoverInnerPlanTooltip"
                    >
                      <QuestionCircleOutlined
                        className={styles.serviceQuestionIconGeneral}
                      />
                    </Popover>
                  )}
                </div>
                <div className={styles.servicesInputWrapper}>
                  <div
                    className={`${
                      !isQuotePlansApproved &&
                      inNetworkServiceLLMObj?.different &&
                      !inNetworkObject?.added
                        ? styles.llmDifferentWrapper
                        : ''
                    }`}
                  >
                    <Input
                      onChange={(e) =>
                        updateObject('services', e.target.value, true, {
                          fieldSubType: null,
                          fieldType: IN_NETWORK_VALUE,
                          name: item,
                        })
                      }
                      value={inNetworkObject?.value ?? ''}
                      className={styles.premiumInput}
                    />
                    {!isQuotePlansApproved &&
                      inNetworkServiceLLMObj?.different &&
                      !inNetworkObject?.added && (
                        <AIFeedbackRedFlag
                          className={styles.aiFeedbackFlagAdditionalPadding}
                        />
                      )}
                  </div>
                  <div
                    className={`${
                      !isQuotePlansApproved &&
                      outNetworkServiceLLMObj?.different &&
                      !outNetworkObject?.added
                        ? styles.llmDifferentWrapper
                        : ''
                    }`}
                  >
                    <Input
                      onChange={(e) =>
                        updateObject('services', e.target.value, true, {
                          fieldSubType: null,
                          fieldType: OUT_NETWORK_VALUE,
                          name: item,
                        })
                      }
                      value={outNetworkObject?.value ?? ''}
                    />
                    {!isQuotePlansApproved &&
                      outNetworkServiceLLMObj?.different &&
                      !outNetworkObject?.added && (
                        <AIFeedbackRedFlag className={styles.aiFeedbackFlag} />
                      )}
                  </div>
                </div>
              </Row>
            );
          })}

          {getAdditionalServicesWithValues(isAdvancedProfile, benefitType)?.map(
            (item: string, index: number) => {
              const inNetworkServiceLLMObj = findInArray(
                selectedData?.llmExtractionInfo?.additionalServices,
                item,
                IN_NETWORK_VALUE,
                null
              );
              const outNetworkServiceLLMObj = findInArray(
                selectedData?.llmExtractionInfo?.additionalServices,
                item,
                OUT_NETWORK_VALUE,
                null
              );

              const inNetworkAdditionalServiceObj = findInArray(
                selectedData?.additionalServices,
                item,
                IN_NETWORK_VALUE,
                null
              );

              const outOfNetworkAdditionalServiceObj = findInArray(
                selectedData?.additionalServices,
                item,
                OUT_NETWORK_VALUE,
                null
              );

              return (
                <Row
                  justify={'space-between'}
                  className={styles.subFieldItems}
                  key={index}
                >
                  <div className={styles.subFieldItemLabelWrapper}>
                    <img
                      src={iconRemove}
                      alt="remove-service-icon"
                      className={styles.iconRemove}
                      onClick={() => handleRemoveAdditionalService(item)}
                    />
                    {item}
                  </div>
                  <div className={styles.servicesInputWrapper}>
                    <div
                      className={`${
                        !isQuotePlansApproved &&
                        inNetworkServiceLLMObj?.different &&
                        !inNetworkAdditionalServiceObj?.added
                          ? styles.llmDifferentWrapper
                          : ''
                      }`}
                    >
                      <Input
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          updateObject(
                            'additionalServices',
                            e.target.value,
                            true,
                            {
                              fieldSubType: null,
                              fieldType: IN_NETWORK_VALUE,
                              name: item,
                            }
                          )
                        }
                        value={inNetworkAdditionalServiceObj?.value ?? ''}
                        className={styles.premiumInput}
                      />
                      {!isQuotePlansApproved &&
                        inNetworkServiceLLMObj?.different &&
                        !inNetworkAdditionalServiceObj?.added && (
                          <AIFeedbackRedFlag
                            className={styles.aiFeedbackFlagAdditionalPadding}
                          />
                        )}
                    </div>
                    <div
                      className={`${
                        !isQuotePlansApproved &&
                        outNetworkServiceLLMObj?.different &&
                        !outOfNetworkAdditionalServiceObj?.added
                          ? styles.llmDifferentWrapper
                          : ''
                      }`}
                    >
                      <Input
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          updateObject(
                            'additionalServices',
                            e.target.value,
                            true,
                            {
                              fieldSubType: null,
                              fieldType: OUT_NETWORK_VALUE,
                              name: item,
                            }
                          )
                        }
                        value={outOfNetworkAdditionalServiceObj?.value ?? ''}
                      />
                      {!isQuotePlansApproved &&
                        outNetworkServiceLLMObj?.different &&
                        !outOfNetworkAdditionalServiceObj?.added && (
                          <AIFeedbackRedFlag
                            className={styles.aiFeedbackFlag}
                          />
                        )}
                    </div>
                  </div>
                </Row>
              );
            }
          )}
        </div>
        {benefitType !== OFFER_CATEGORY.MEDICAL &&
          (isAdvancedProfile || benefitType === OFFER_CATEGORY.VISION) && (
            <>
              <TextButton
                className={styles.addTierBtn}
                type="primary"
                label="+ Add Service"
                onClick={() => {
                  setAddAdditionalServiceModalShow(true);
                }}
                disabled={isAddServiceDisable}
              />
              <AdditionalServiceModal
                visible={isAddAdditionalServiceModalShow}
                availableServices={getAdditionalServicesWithValues(
                  isAdvancedProfile,
                  benefitType
                )}
                onClose={() => setAddAdditionalServiceModalShow(false)}
                benefitType={benefitType}
                addAdditionalService={addAdditionalService}
                setAddServiceDisable={setAddServiceDisable}
              />
            </>
          )}
      </div>
    </>
  );
};

export default MDVServicesSection;
