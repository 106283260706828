import { FC, useState } from 'react';

import { Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import NextButton from 'components/buttons/NextButton/NextButton';
import SummeryCommonTable from 'modules/renewals/components/helperComponents/SummeryCommonTable/SummeryCommonTable';
import { PLAN_CLONE_BENEFIT_CATEGORIES } from 'modules/employers/constants/afpClonePlansConstants';
import TextButton from 'components/buttons/TextButton/TextButton';
import PlanDetailModal from 'modules/employers/components/AFPCloneDBGAndPlans/PlanDetailModal/PlanDetailModal';
import {
  PLAN_CLONE_TYPE_TEXT,
  getClonePlanIcon,
} from 'modules/employers/utils/clonePlanUtils';
import { IPlan, IResultItem } from 'modules/employers/types/planBGCloneTypes';
import { updatePlanList } from 'modules/employers/slices/planBGCloneSlice';

import { ReactComponent as RemoveIcon } from 'assets/images/icon-remove-red.svg';
import styles from './planDetails.module.less';

type PlanDetailsProps = {
  setCurrentStep: (step: number) => void;
};

const PlanDetails: FC<PlanDetailsProps> = (props: PlanDetailsProps) => {
  const { setCurrentStep } = props;
  const dispatch = useAppDispatch();
  const clonePlanList = useAppSelector(
    (state) => state.employer.planBGClone.clonePlanBGList.plans
  );

  const [showPlanDetails, setViewPlanDetails] = useState<{
    show: boolean;
    planData: Partial<IPlan>;
    index: number | null;
  }>({
    show: false,
    planData: {},
    index: null,
  });

  const handleTogglePlanRemovedState = (
    record: IPlan,
    index: number,
    remove: boolean
  ) => {
    const copiedPlanList = { ...clonePlanList };
    const categoryKey =
      record.benefitCategory as keyof typeof PLAN_CLONE_BENEFIT_CATEGORIES;
    const copiedCategory = { ...copiedPlanList[categoryKey]! };
    const copiedPlans = [...copiedCategory.plan!];

    // Update the isRemoved property of the specific plan based on the 'remove' parameter
    copiedPlans[index] = { ...copiedPlans[index], isRemoved: remove };
    copiedCategory.plan = copiedPlans;

    copiedPlanList[categoryKey] = copiedCategory;

    dispatch(updatePlanList(copiedPlanList));
  };

  const handleViewDetailAndUndo = (record: IPlan, index: number) => {
    if (record?.isRemoved) {
      handleTogglePlanRemovedState(record, index, false); // Set isRemoved to false
    } else {
      setViewPlanDetails({ show: true, planData: record, index: index });
    }
  };

  const handleRemovePlan = (record: IPlan, index: number) => {
    handleTogglePlanRemovedState(record, index, true); // Set isRemoved to true
  };

  const planTableColumns: ColumnsType<IPlan> = [
    {
      title: <p className={styles.planTitle}>Plan Name</p>,
      key: 'name',
      dataIndex: 'name',
      render: (data, record, index) => (
        <div className={styles.planHeaderContainer}>
          {!record.isRemoved && (
            <RemoveIcon
              className={styles.removedIcon}
              onClick={() => handleRemovePlan(record, index)}
            />
          )}
          <p
            className={
              record.isRemoved ? styles.removedStateStyle : styles.planText
            }
          >
            {data}
          </p>
        </div>
      ),
    },
    {
      title: '',
      key: '',
      dataIndex: '',
      align: 'right',
      render: (_, record, index) => {
        return (
          <div className={styles.actionContainer}>
            {record?.isRemoved && (
              <p className={styles.removedTextStyle}>Plan Removed</p>
            )}

            <TextButton
              type="primary"
              label={record?.isRemoved ? '(Undo)' : 'Plan Details'}
              onClick={() => handleViewDetailAndUndo(record, index)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.planContainer}>
      {!Object.values(clonePlanList)?.some(
        (category?: IResultItem) =>
          category?.isChecked && category?.plan?.length > 0
      ) || Object.entries(clonePlanList).length === 0 ? (
        <div className={styles.emptyDataContainer}>
          <p className={styles.emptyDataText}>You have no current benefits.</p>
        </div>
      ) : (
        Object.entries(clonePlanList).map(([benefit, values]) => {
          if (values?.isChecked && values?.plan.length !== 0) {
            return (
              <div key={benefit} className={styles.tableComponentWrapper}>
                <Row align="middle">
                  <img
                    className={styles.headerIcon}
                    src={getClonePlanIcon(
                      benefit as keyof typeof PLAN_CLONE_BENEFIT_CATEGORIES
                    )}
                  />
                  <span className={styles.headerText}>
                    {
                      PLAN_CLONE_TYPE_TEXT[
                        benefit as keyof typeof PLAN_CLONE_BENEFIT_CATEGORIES
                      ]
                    }
                  </span>
                </Row>

                <SummeryCommonTable
                  data={values?.plan}
                  columns={planTableColumns}
                  emptyMessage={
                    <span className={styles.tableEmptyText}>
                      There are no plans for this benefit type.
                    </span>
                  }
                />
              </div>
            );
          }
        })
      )}

      <PlanDetailModal
        visible={showPlanDetails.show}
        data={showPlanDetails.planData}
        changedIndex={showPlanDetails.index}
        handleClose={() => {
          setViewPlanDetails({ show: false, planData: {}, index: null });
        }}
        type="PLAN"
      />

      <NextButton
        className={styles.nextButtonStyles}
        nextStep={() => {
          setCurrentStep(2);
        }}
      />
    </div>
  );
};

export default PlanDetails;
