import { set } from 'lodash';
import { FC } from 'react';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
  SourceInfo,
} from 'react-number-format';

import styles from './formInput.module.less';

interface Props extends NumberFormatProps {
  isBrokerScreen?: boolean;
  isInvalid?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  dataCy?: string;
  outsidePrefix?: boolean;
  setIsFieldEmpty?: Function;
  customClass?: string;
}

const NumberFormatInput: FC<Props> = (props: Props) => {
  const {
    isBrokerScreen = false,
    isInvalid = false,
    dataCy,
    onChange,
    prefix,
    outsidePrefix,
    className = '',
    setIsFieldEmpty,
    customClass = '',
    ...rest
  } = props;

  const removeCurrencyOrPercentageFromString = (event: any) => {
    let updatedValue = event.target.value;
    if (updatedValue.startsWith('$')) {
      updatedValue = updatedValue.slice(1);
    }
    if (updatedValue.endsWith('%')) {
      updatedValue = updatedValue.slice(0, updatedValue.length - 1);
    }
    if (updatedValue.endsWith('x')) {
      updatedValue = updatedValue.slice(0, updatedValue.length - 1);
    }
    if (updatedValue.endsWith('weeks')) {
      updatedValue = updatedValue.split('weeks')[0];
    }
    if (updatedValue.endsWith('days')) {
      updatedValue = updatedValue.split('days')[0];
    }
    if (updatedValue.endsWith('months')) {
      updatedValue = updatedValue.split('months')[0];
    }

    // Remove comma separator from the numbers
    updatedValue = updatedValue.replace(',', '');

    set(event, 'target.value', updatedValue);
    if (onChange) onChange(event);
  };

  const onValueChange = (values: NumberFormatValues, event: SourceInfo) => {
    if (setIsFieldEmpty && values.value === '') {
      setIsFieldEmpty(true);
    } else if (setIsFieldEmpty && values.value !== '') {
      setIsFieldEmpty(false);
    }
    removeCurrencyOrPercentageFromString({
      target: {
        value: values.value,
        name: event.event?.target.name,
      },
    });
  };

  return (
    <div
      className={`${styles.numberFormatInputWrapper} ${styles?.[customClass]}`}
    >
      {outsidePrefix && (
        <div className={styles.prefixWrapper}>
          <label>{prefix}</label>
        </div>
      )}
      <NumberFormat
        {...rest}
        prefix={outsidePrefix ? undefined : prefix}
        data-cy={dataCy}
        className={`${styles.numberFormatInput} ${
          isBrokerScreen ? styles.broker : ''
        } ${isInvalid ? styles.invalid : ''} ${className}`}
        onValueChange={onValueChange}
      />
    </div>
  );
};

const preventNegativeValues = (values: NumberFormatValues): boolean => {
  const { floatValue = 0 } = values;
  return floatValue >= 0;
};

const preventMoreThanHundredForPercentages = (
  values: NumberFormatValues
): boolean => {
  const { floatValue = 0 } = values;
  return floatValue <= 100;
};

export { preventNegativeValues, preventMoreThanHundredForPercentages };

export default NumberFormatInput;
