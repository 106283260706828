import { combineReducers } from '@reduxjs/toolkit';
import employerCreateStepperSlice from 'modules/employers/slices/employerCreateStepperSlice';
import employerSlice from 'modules/employers/slices/employerSlice';
import employerPlanYearSlice from 'modules/employers/slices/employerPlanYearSlice';
import benguideSlice from 'modules/employers/slices/benguideSlice';
import planBGCloneSlice from 'modules/employers/slices/planBGCloneSlice';

const EmployerRootReducer = combineReducers({
  employer: employerSlice,
  employerCreateStepper: employerCreateStepperSlice,
  employerPlanYear: employerPlanYearSlice,
  benguide: benguideSlice,
  planBGClone: planBGCloneSlice,
});

export default EmployerRootReducer;
