import { FC, useCallback, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Col, Input, Row, Spin, Switch, Alert } from 'antd';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import { StompSessionProvider } from 'react-stomp-hooks';
import { wsBaseApi, benguideUrl, baseApi } from 'util/apiUtil';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import BenguideCard from 'components/Card/BenguideCard';
import TabView from 'components/TabView/TabView';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import PlanYearDropdown from 'components/PlanYearDropdown/PlanYearDropdown';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import AlertMessage from 'components/Alert/AlertMessage';
import CollaborationNotification from 'components/CollaborationNotification/CollaborationNotification';
import PlanYear from 'model/PlanYear';
import BenguideBasicData from 'model/BenguideBasicData';
import TabType from 'model/TabType';
import DBGClient from 'modules/clients/DBGClient/DBGClient';

import BenguideHelp from 'modules/benefitGuide/components/BenguideHelp/BenguideHelp';
import {
  getBenguidesByEmployerIdAndPlanYearId,
  clearDBGObject,
  getBenefitGuidePlanList,
  archiveBenguide,
  resetBenguideList,
} from 'modules/employers/slices/benguideSlice';
import NoBenguideView from 'modules/benefitGuide/components/NoBenguideView/NoBenguideView';
import NoBenguideCard from 'modules/benefitGuide/components/NoBenguideView/NoBenguideCard';
import BenguideContainer from 'modules/benefitGuide/components/BenguideContainer/BenguideContainer';

import {
  BENEFIT_GUIDE_ALREADY_EXISTS,
  BENEFIT_GUIDE_DOESNT_EXIST,
  BENEFIT_GUIDE_HIGHLIGHTED_ISSUES_WARNING_MESSAGE,
  BENEFIT_GUIDE_STATUS,
  BENEFIT_GUIDE_STATUS_ARRAY,
} from 'modules/benefitGuide/constants/benefitGuideConstants';
import { PUBLISHED } from 'constants/benguideCollaborationConstants';
import {
  CLOSE_CREATE_BENGUIDE_MODAL,
  CREATE_BENGUIDE_CHANNEL,
  EDIT_BENGUIDE_CHANNEL,
  EDIT_BENGUIDE_STARTED,
} from 'modules/clients/DBGClient/DBGClientConts';
import { getEmployer } from 'modules/employers/slices/employerSlice';
import {
  changedBenguideModalState,
  clearBenefitGuideCloning,
  deleteBenguide,
  enableEditModeForPublish,
  fetchBenguidePassword,
  moveToBenguideInProgress,
  previewBenguide,
  resetBenguideDeleteState,
  saveBenguidePassword,
  updateEmployerName,
} from 'modules/benefitGuide/slices/benguideSlice';

import CloneBenefitGuideModal from 'modules/benefitGuide/components/CloneBenefitGuideModal/CloneBenefitGuideModal';
import BenguidePublish from 'modules/benefitGuide/components/BenguidePublish/BenguidePublish';
import { formatLocalized } from 'util/dateUtil';
import EmployerName from 'model/EmployerName';
import {
  removeSpecialCharacters,
  verifyEmployerNameInput,
} from 'util/benguideUtil';

import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import EmployerFeatureDisabledBanner from 'modules/tools/components/EmployerFeatureDisabledBanner/EmployerFeatureDisabledBanner';
import {
  EMPLOYER_MEMBER_RESTRICTED,
  FEATURE_KEYS,
} from 'constants/commonConstants';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import styles from './benefitGuideHome.module.less';

const BenefitGuideHome: FC = () => {
  const dispatch = useAppDispatch();
  const [currentlySelectedBenGuide, setCurrentlySelectedBenGuide] =
    useState('');
  const { employerObj, planYearsList } = useAppSelector(
    (state) => state.benguide.employerSlice
  );
  const {
    benguideList,
    benguideListSuccess,
    benguidePublishSuccess,
    benguideArchived,
    benguideListInProgress,
    inProgress,
    error,
  } = useAppSelector((state) => state.employer.benguide);

  const {
    deleteBenguide: _deleteBenguide,
    benguidePassword,
    previewData,
    cloneBenguide,
  } = useAppSelector((state) => state.benguide.benguideSlice);
  const { deleteSuccess, inprogress: deleteInprogress } = _deleteBenguide;

  const { data: secured = { enabled: false, password: '' } } = benguidePassword;

  const benguideSlice = useAppSelector((state) => state.benguide.benguideSlice);
  const { isBenguideModalOpen } = benguideSlice;

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);

  const { employerId } = useNavContext();

  const setBenguideModal = (state: boolean) => {
    dispatch(changedBenguideModalState(state));
  };

  const [editBenguideHash, setEditBenguideHash] = useState('');
  const [isCreateMode, setCreateMode] = useState<boolean>(false);
  const [revision, setRevision] = useState<number>();
  const [status, setStatus] = useState<string>('');

  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    BENEFIT_GUIDE_STATUS.PUBLISHED
  );
  const [selectedPlanYear, setSelectedPlanYear] = useState<PlanYear>({
    id: '',
    benefitGroups: [],
    employerId: '',
    startDate: '',
    endDate: '',
    current: false,
    name: '',
    next: false,
    previous: false,
  });
  const [archivedModalOpen, setArchivedModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [moveInProgressModalOpen, setMoveInProgressModalOpen] =
    useState<boolean>(false);
  const [selectedBenguideId, setSelectedBenguideId] = useState<string>('');
  const [archiveAlertVisible, setArchiveAlertVisible] =
    useState<boolean>(false);
  const [deleteAlertVisible, setDeleteAlertVisible] = useState<boolean>(false);
  const [publishAlertVisible, setPublishAlertVisible] =
    useState<boolean>(false);
  const [copyAlertVisible, setCopyAlertVisible] = useState<boolean>(false);
  const [isPasswordModalOpen, setPasswordModalOpen] = useState<boolean>(false);
  const [isChangePasswordEnabled, setChangePasswordEnable] =
    useState<boolean>(false);
  const [isPasswordSectionEnabled, setPasswordSectionEnable] =
    useState<boolean>(false);
  const [dbgPassword, setDBGPassword] = useState<string>('');
  const [isPasswordRequiredTriggered, setPasswordRequired] =
    useState<boolean>(false);
  const [isCloneModalOpen, setCloneModalOpen] = useState<boolean>(false);
  const [selectedBenguide, setSelectedBenguide] = useState<BenguideBasicData>();
  const [passwordChangeAlertVisible, setPasswordChangeAlertVisible] =
    useState<boolean>(false);
  const [errorAlertVisible, setErrorAlertVisible] = useState<boolean>(false);
  const [isEditUrlModalOpen, setEditUrlModalOpen] = useState<boolean>(false);
  const [employerNameInput, setEmployerNameInput] = useState<string>('');
  const [employerNameLength, setEmployerNameLength] = useState<number>(0);
  const [editUrlAlertVisible, setEditUrlAlertVisible] =
    useState<boolean>(false);
  const [characterLimitError, setCharacterLimitError] =
    useState<boolean>(false);
  const [employerNameError, setEmployerNameError] = useState<boolean>(false);
  const [urlErrorMessage, setUrlErrorMessage] = useState<string>('');
  const [urlEmployerName, setUrlEmployerName] = useState<string>('');

  const location = useLocation();
  const match: any = location.state;
  const navigate = useNavigate();
  const matchRef = useRef(match);

  const switchTabBasedOnResult = useCallback(
    (dbgList?: BenguideBasicData[]) => {
      if (!match?.dbgStatus) {
        if (
          dbgList?.find(
            (guide) => guide.status === BENEFIT_GUIDE_STATUS.PUBLISHED
          )
        ) {
          setSelectedTab(BENEFIT_GUIDE_STATUS.PUBLISHED);
        } else if (
          dbgList?.find(
            (guide) => guide.status === BENEFIT_GUIDE_STATUS.IN_PROGRESS
          )
        ) {
          setSelectedTab(BENEFIT_GUIDE_STATUS.IN_PROGRESS);
        } else if (
          dbgList?.find(
            (guide) => guide.status === BENEFIT_GUIDE_STATUS.ARCHIVED
          )
        ) {
          setSelectedTab(BENEFIT_GUIDE_STATUS.ARCHIVED);
        }
      }
    },
    [match?.dbgStatus]
  );

  useEffect(() => {
    if (
      match?.dbgStatus &&
      (match.dbgStatus === BENEFIT_GUIDE_STATUS.IN_PROGRESS ||
        match.dbgStatus === BENEFIT_GUIDE_STATUS.PUBLISHED ||
        match.dbgStatus === BENEFIT_GUIDE_STATUS.ARCHIVED)
    ) {
      setSelectedTab(match.dbgStatus);
    }
  }, [match]);

  useEffect(() => {
    if (benguideList) {
      switchTabBasedOnResult(benguideList);
    }
  }, [benguideList, switchTabBasedOnResult]);

  useEffect(() => {
    if (
      planYearsList?.length &&
      match?.planYearId &&
      matchRef?.current?.planYearId !== match.planYearId
    ) {
      const defaultPlanYear = planYearsList.find(
        (year) => year.id === match.planYearId
      );
      if (defaultPlanYear) {
        setSelectedPlanYear(defaultPlanYear);
      }
    }
  }, [match, planYearsList]);

  useEffect(() => {
    if (isPasswordModalOpen) {
      dispatch(fetchBenguidePassword(selectedBenguideId));
    }
  }, [dispatch, isPasswordModalOpen, selectedBenguideId]);

  useEffect(() => {
    setPasswordRequired(false);
  }, [isPasswordSectionEnabled]);

  useEffect(() => {
    setPasswordSectionEnable(secured?.enabled || false);
    setDBGPassword(secured?.password || '');
    setChangePasswordEnable(false);
    setPasswordRequired(false);
  }, [secured]);

  useEffect(() => {
    if (employerId) {
      dispatch(getEmployer(employerId));
    }
  }, [dispatch, employerId]);

  useEffect(() => {
    return () => {
      dispatch(changedBenguideModalState(false));
      dispatch(resetBenguideList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      (benguidePublishSuccess || benguideArchived || !isBenguideModalOpen) &&
      employerId &&
      selectedPlanYear.id
    ) {
      dispatch(
        getBenguidesByEmployerIdAndPlanYearId(selectedPlanYear.id, employerId)
      );
    }
  }, [
    benguideArchived,
    benguidePublishSuccess,
    dispatch,
    employerId,
    selectedPlanYear.id,
    isBenguideModalOpen,
  ]);

  useEffect(() => {
    setArchivedModalOpen(false);
    setDeleteModalOpen(false);
    setMoveInProgressModalOpen(false);
  }, [benguideList]);

  useEffect(() => {
    if (deleteSuccess) {
      setDeleteAlertVisible(true);
      dispatch(resetBenguideDeleteState());
    }
    // eslint-disable-next-line
  }, [deleteSuccess]);

  useEffect(() => {
    if (benguidePublishSuccess) {
      setPublishAlertVisible(true);
      if (selectedTab !== BENEFIT_GUIDE_STATUS.PUBLISHED) {
        navigate(location.pathname, {
          state: { dbgStatus: BENEFIT_GUIDE_STATUS.PUBLISHED },
        });
      }
    }
  }, [benguidePublishSuccess, navigate, location.pathname, selectedTab]);

  useEffect(() => {
    if (!inProgress && error !== null) {
      setErrorAlertVisible(true);
      setIsPublishModalOpen(false);
    } else if (
      previewData &&
      !previewData.inProgress &&
      previewData.error !== null
    ) {
      setErrorAlertVisible(true);
    } else if (
      cloneBenguide &&
      !cloneBenguide.inprogress &&
      cloneBenguide.error !== null &&
      cloneBenguide.error.code === BENEFIT_GUIDE_DOESNT_EXIST
    ) {
      setErrorAlertVisible(true);
      setCloneModalOpen(false);
    }
  }, [cloneBenguide, error, inProgress, previewData]);

  const onTabClick = (key: string) => {
    setSelectedTab(key);
  };

  const onChangePlanYear = useCallback(
    (value: PlanYear) => {
      setSelectedPlanYear(value);
      navigate(location.pathname, {
        state: {
          dbgStatus: undefined,
        },
      });
    },
    [navigate, location.pathname]
  );

  const deleteDBG = () => {
    dispatch(
      deleteBenguide(selectedBenguideId, selectedPlanYear.id, employerId || '')
    );
  };

  const moveToInProgress = () => {
    dispatch(
      moveToBenguideInProgress(
        selectedBenguideId,
        selectedPlanYear.id,
        employerId || ''
      )
    ).then(() => {
      navigate(location.pathname, {
        state: {
          dbgStatus: BENEFIT_GUIDE_STATUS.IN_PROGRESS,
        },
      });
    });
  };

  const downloadExportFile = (masterId: string) => {
    window.location.href = `${baseApi}/v2/benefit-guides/${masterId}/export`;
  };

  const getBenguideCardList = (
    selectedBenguideList: BenguideBasicData[],
    benguideStatus: string
  ) => {
    return (
      <Row className={styles.benguideCardList}>
        <DBGClient
          channel={EDIT_BENGUIDE_CHANNEL}
          subscribe={(event: string, data: any) => {
            if (event === EDIT_BENGUIDE_STARTED && data) {
              setCurrentlySelectedBenGuide(data);
            }
          }}
        />
        {!isEmpty(selectedBenguideList) ? (
          <>
            {selectedBenguideList.map((benguide) => (
              <Col key={benguide.masterId} className={styles.benguideCards}>
                <BenguideCard
                  selectedBenguide={benguide}
                  benguideStatus={benguideStatus}
                  onBenguideEdit={() => {
                    dispatch(
                      enableEditModeForPublish(
                        benguide.masterId,
                        (response: any) => {
                          setRevision(response?.revision);
                          setEditBenguideHash(benguide.urlHash);
                          setCreateMode(false);
                          setBenguideModal(!isBenguideModalOpen);
                          setStatus(benguide.status);
                          setUrlEmployerName(benguide.employerName);
                        }
                      )
                    ).catch(() => {
                      setErrorAlertVisible(true);
                    });
                  }}
                  onClickPublish={() => {
                    onClickPublish(benguide.masterId);
                    setCurrentlySelectedBenGuide(benguide.masterId);
                  }}
                  onExport={() => {
                    setSelectedBenguideId(benguide.masterId);
                    downloadExportFile(benguide.masterId);
                  }}
                  onClickArchived={() => {
                    setArchivedModalOpen(!archivedModalOpen);
                    setSelectedBenguideId(benguide.masterId);
                  }}
                  onDelete={() => {
                    setSelectedBenguideId(benguide.masterId);
                    setDeleteModalOpen(true);
                  }}
                  onMoveToInProgress={() => {
                    setSelectedBenguideId(benguide.masterId);
                    setMoveInProgressModalOpen(true);
                  }}
                  onClickCopyURL={(urlHash: string, employerName: string) => {
                    setCopyAlertVisible(true);
                    navigator.clipboard.writeText(
                      `${benguideUrl}/${urlHash}${
                        employerName ? '/' + employerName : ''
                      }`
                    );
                  }}
                  onPreview={() => {
                    dispatch(previewBenguide(benguide.masterId));
                  }}
                  onViewPassword={() => {
                    setPasswordModalOpen(true);
                    setSelectedBenguideId(benguide.masterId);
                  }}
                  onView={() => {
                    window.open(
                      `${benguideUrl}/${benguide.urlHash}${
                        benguide.employerName ? '/' + benguide.employerName : ''
                      }`
                    );
                  }}
                  onClone={() => {
                    setSelectedBenguideId(benguide.masterId);
                    setSelectedBenguide(benguide);
                    setCloneModalOpen(true);
                  }}
                  onEditUrl={() => {
                    setEditUrlModalOpen(true);
                    setSelectedBenguide(benguide);
                    setSelectedBenguideId(benguide.masterId);
                    setEmployerNameInput(
                      benguide.employerName ? benguide.employerName : ''
                    );
                    setEmployerNameLength(
                      benguide.employerName ? benguide.employerName.length : 0
                    );
                    setCharacterLimitError(false);
                    setEmployerNameError(false);
                    setUrlErrorMessage('');
                  }}
                />
              </Col>
            ))}
          </>
        ) : (
          <NoBenguideCard benguideStatus={benguideStatus} />
        )}
      </Row>
    );
  };

  const getTabDataList = () => {
    const benguides: TabType[] = [];
    let draftBenguides: BenguideBasicData[] = [];
    let publishedBenguides: BenguideBasicData[] = [];
    let archivedBenguides: BenguideBasicData[] = [];

    benguideList.forEach((benguide) => {
      if (benguide.status === BENEFIT_GUIDE_STATUS.IN_PROGRESS) {
        draftBenguides.push(benguide);
      } else if (benguide.status === BENEFIT_GUIDE_STATUS.PUBLISHED) {
        publishedBenguides.push(benguide);
      } else if (benguide.status === BENEFIT_GUIDE_STATUS.ARCHIVED) {
        archivedBenguides.push(benguide);
      }
    });

    !isEmpty(benguideList) &&
      BENEFIT_GUIDE_STATUS_ARRAY.forEach((type, index) => {
        const tabType: TabType = {
          tabLabel: '',
          tabKey: '',
          tabData: null,
        };
        tabType.tabKey = type;
        if (type === BENEFIT_GUIDE_STATUS.IN_PROGRESS) {
          tabType.tabLabel = `In Progress (${draftBenguides.length})`;
          draftBenguides = [...draftBenguides].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          tabType.tabData = getBenguideCardList(draftBenguides, type);
        } else if (type === BENEFIT_GUIDE_STATUS.PUBLISHED) {
          tabType.tabLabel = `Published (${publishedBenguides.length})`;
          publishedBenguides = [...publishedBenguides].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          tabType.tabData = getBenguideCardList(publishedBenguides, type);
        } else if (type === BENEFIT_GUIDE_STATUS.ARCHIVED) {
          tabType.tabLabel = `Archived (${archivedBenguides.length})`;
          archivedBenguides = [...archivedBenguides].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          tabType.tabData = getBenguideCardList(archivedBenguides, type);
        }
        benguides[index] = tabType;
      });
    return benguides;
  };

  const handleExit = (event: string, data: any) => {
    if (event === CLOSE_CREATE_BENGUIDE_MODAL) {
      setBenguideModal(false);
    }
  };

  const path = isCreateMode
    ? `create?employerId=${employerId}`
    : status === PUBLISHED
    ? `${editBenguideHash}${urlEmployerName ? '/' + urlEmployerName : ''}`
    : `${editBenguideHash}/basic-info/edit`;

  const onClickPublish = (id: string) => {
    setIsPublishModalOpen(!isPublishModalOpen);
    dispatch(getBenefitGuidePlanList(id));
  };

  const refreshBenefitGuideList = () => {
    if (employerId) {
      dispatch(
        getBenguidesByEmployerIdAndPlanYearId(selectedPlanYear.id, employerId)
      );
    }
  };

  const archiveSelectedBenguide = () => {
    dispatch(
      archiveBenguide(selectedBenguideId, selectedPlanYear.id, employerId || '')
    ).then(() => {
      navigate(location.pathname, {
        state: {
          dbgStatus: BENEFIT_GUIDE_STATUS.ARCHIVED,
        },
      });
    });
    setSelectedBenguideId('');
    setArchiveAlertVisible(true);
    setArchivedModalOpen(!archivedModalOpen);
  };

  const closeAlert = () => {
    setArchiveAlertVisible(false);
  };

  const planYearName = `${selectedPlanYear.name} (${formatLocalized(
    selectedPlanYear.startDate
  )} - ${formatLocalized(selectedPlanYear.endDate)})`;

  const archiveSuccessMsg = () => {
    return (
      <>
        <p className={styles.archiveMsg}>Guide Has Been Archived.</p>
        <p className={styles.archiveMsg}>
          If you want to edit or publish it, move it to In Progress.
        </p>
      </>
    );
  };

  const saveDBGPassword = () => {
    if (isEmpty(dbgPassword)) {
      setPasswordRequired(true);
      return;
    }
    dispatch(
      saveBenguidePassword(selectedBenguideId, {
        enabled: isPasswordSectionEnabled,
        password: dbgPassword,
      })
    );
    setPasswordModalOpen(false);
    setPasswordChangeAlertVisible(true);
  };

  const getMessage = () => {
    if (
      cloneBenguide &&
      !cloneBenguide.inprogress &&
      cloneBenguide.error !== null &&
      cloneBenguide.error.response &&
      cloneBenguide.error.response.data &&
      cloneBenguide.error.response.data.code === BENEFIT_GUIDE_ALREADY_EXISTS
    ) {
      return 'Name already exists';
    }
    return "Benefit Guide doesn't exist";
  };

  const saveEmployerName = (value: string) => {
    setUrlErrorMessage('');
    setEmployerNameError(false);
    if (value.length > 40) {
      setCharacterLimitError(true);
      setEmployerNameInput(removeSpecialCharacters(value).slice(0, 40));
      setEmployerNameLength(removeSpecialCharacters(value).slice(0, 40).length);
    } else {
      setCharacterLimitError(false);
      setEmployerNameInput(removeSpecialCharacters(value));
      setEmployerNameLength(removeSpecialCharacters(value).length);
    }
  };

  const savePublicUrl = () => {
    if (verifyEmployerNameInput(employerNameInput, employerNameLength)) {
      setEmployerNameError(true);
      setUrlErrorMessage(BENEFIT_GUIDE_HIGHLIGHTED_ISSUES_WARNING_MESSAGE);
      return;
    }

    const employerName: EmployerName = {
      employerName: employerNameInput,
    };

    dispatch(
      updateEmployerName(
        selectedBenguideId,
        employerName,
        selectedPlanYear.id,
        employerId || ''
      )
    );
    setEditUrlModalOpen(false);
    setEditUrlAlertVisible(true);
  };

  const createBenefitGuidesButton = (
    <Col span={12}>
      {!isEmpty(benguideList) && (
        <PageActionButton
          type="primary"
          onClick={() => {
            setCreateMode(true);
            setBenguideModal(true);
          }}
          className={`${styles.benguidePageActionsButton} ${styles.benefitguideHelp}`}
        >
          Create Benefits Guides
        </PageActionButton>
      )}
    </Col>
  );

  const securedCreateBenefitButton = usePermitByUserType(
    createBenefitGuidesButton,
    EMPLOYER_MEMBER_RESTRICTED
  );

  return (
    <ContentContainer>
      <DBGClient channel={CREATE_BENGUIDE_CHANNEL} subscribe={handleExit} />
      {
        /*
         * Creates ws connection here based if the benguide is in edit mode
         * Subscriptions to each topic happens in <CollaborationNotification /> component
         *
         */
        (isBenguideModalOpen || isPublishModalOpen) && (
          <StompSessionProvider
            url={`${wsBaseApi}/websocket`}
            onWebSocketError={(e) => console.error(e)}
            reconnectDelay={500}
          >
            <CollaborationNotification
              loggedInUserIndividualId={appBootInfo?.individualId}
              isBenguideModalOpen={isBenguideModalOpen || isPublishModalOpen}
              benGuideId={currentlySelectedBenGuide}
              exitBenguide={() => setBenguideModal(false)}
              exitPublishModal={() => {
                setIsPublishModalOpen(false);
                refreshBenefitGuideList();
              }}
              inPublishMode={isPublishModalOpen}
            />
          </StompSessionProvider>
        )
      }
      <EmployerFeatureDisabledBanner
        feature={FEATURE_KEYS.BENEFIT_GUIDE}
        featureName="Benefits Guides"
        employerId={employerId!}
      />
      <div className={styles.benefitGuideHome}>
        {passwordChangeAlertVisible && (
          <AlertMessage
            type={'success'}
            message="This Guide's Password Has Been Updated Successfully"
            closeAlert={() => {
              setPasswordChangeAlertVisible(false);
            }}
            className={`${styles.dbgActionMessage} ${styles.dbgPasswordMessage}`}
          />
        )}
        {publishAlertVisible && (
          <AlertMessage
            type={'success'}
            message="Guide Has Been Published Successfully"
            closeAlert={() => {
              setPublishAlertVisible(false);
            }}
            className={styles.dbgActionMessage}
          />
        )}
        {deleteAlertVisible && (
          <AlertMessage
            type={'success'}
            message="Guide Has been Deleted successfully"
            closeAlert={() => {
              setDeleteAlertVisible(false);
            }}
            className={styles.dbgActionMessage}
          />
        )}
        {copyAlertVisible && (
          <AlertMessage
            type={'success'}
            message="Copied the URL to Clipboard"
            closeAlert={() => {
              setCopyAlertVisible(false);
            }}
            className={styles.dbgActionMessage}
          />
        )}

        {archiveAlertVisible && benguideArchived && (
          <div className={styles.archiveBenguideAlert}>
            <AlertMessage
              type="success"
              message={archiveSuccessMsg()}
              closeAlert={() => closeAlert()}
              wrapperClassName={styles.archiveBenguideAlert}
            />
          </div>
        )}
        {errorAlertVisible && (
          <div className={styles.errorAlert}>
            <AlertMessage
              type="error"
              message={getMessage()}
              closeAlert={() => {
                setErrorAlertVisible(false);
                dispatch(clearBenefitGuideCloning());
              }}
              wrapperClassName={styles.errorAlert}
            />
          </div>
        )}
        {editUrlAlertVisible && (
          <AlertMessage
            type={'success'}
            message="Public URL has been updated successfully"
            closeAlert={() => {
              setEditUrlAlertVisible(false);
            }}
            className={styles.dbgActionMessage}
          />
        )}

        <Row className={styles.benefitGuideName}>
          <Col span={12}>
            <Row className={styles.benefitGuideNameRow}>
              <OverflowPopover maxWidth={350} popoverContent={employerObj.name}>
                {employerObj.name}
              </OverflowPopover>
              Benefits Guides
            </Row>
          </Col>
          {securedCreateBenefitButton}
        </Row>
        <Row className={styles.benefitGuidePlanYearFilter}>
          <PlanYearDropdown
            onChange={onChangePlanYear}
            className={styles.planYearLabel}
            newPlanYear={selectedPlanYear}
            planYearOptionsStyle={styles.planYearOptions}
          />
        </Row>
        <div
          className={`${styles.benefitguideHelp} ${
            isEmpty(benguideList) ? '' : styles.benefitguideHelpCards
          }`}
        >
          {false && <BenguideHelp />}
        </div>
        {benguideListInProgress ? (
          <Spin />
        ) : benguideListSuccess && isEmpty(benguideList) ? (
          <NoBenguideView
            className={styles.benguidePageActionsButton}
            primaryButtonAction={() => {
              setCreateMode(true);
              setBenguideModal(!isBenguideModalOpen);
            }}
          />
        ) : (
          <span className={styles.benTabsWrapper}>
            <TabView
              tabOptions={getTabDataList()}
              defaultTabKey={selectedTab}
              onClick={() => {}}
              onChange={onTabClick}
            />
          </span>
        )}
        <FullScreenModal
          visible={isBenguideModalOpen}
          showConfirm={false}
          onCancel={() => {
            dispatch(clearDBGObject());
            setBenguideModal(!isBenguideModalOpen);
          }}
          className={styles.benGuideModalWrapper}
          forceRender
          footer={false}
        >
          {isBenguideModalOpen && (
            <BenguideContainer
              path={path}
              revision={revision}
              id="dbg-home-iframe"
            />
          )}
        </FullScreenModal>
        <FullScreenModal
          key={2}
          visible={isPublishModalOpen}
          showConfirm={false}
          onCancel={() => setIsPublishModalOpen(false)}
          destroyOnClose
          footer={false}
        >
          <BenguidePublish
            planYear={planYearName}
            onCancel={() => setIsPublishModalOpen(false)}
          />
        </FullScreenModal>
        <ConfirmationDialog
          title="Are You Sure You Want To Archive This Guide?"
          confirmText="Archive Guide"
          cancelText="Cancel"
          closeModal={() => setArchivedModalOpen(!archivedModalOpen)}
          onConfirm={archiveSelectedBenguide}
          visible={archivedModalOpen}
          isCloseOnly={false}
        >
          <p className={styles.archiveText}>
            If you archive this guide it will no longer be available to anyone
            who may have a link to it on the web.
          </p>
          <p className={styles.archiveText}>
            The guide will be moved to the archive section. You will still be
            able to view the guide.
          </p>
        </ConfirmationDialog>
        <ConfirmationDialog
          title="Are You Sure You Want To Delete This Guide?"
          confirmText="Delete Guide"
          cancelText="Cancel"
          closeModal={() => setDeleteModalOpen(!deleteModalOpen)}
          onConfirm={deleteDBG}
          visible={deleteModalOpen}
          isCloseOnly={false}
          confirmLoading={deleteInprogress}
          disableConfirmButton={deleteInprogress}
        >
          <p className={styles.archiveText}>
            If you delete this guide it will no longer exist in any format. You
            will not be able to view, copy, or edit this guide.
          </p>
        </ConfirmationDialog>
        <ConfirmationDialog
          title="Are You Sure You Want To Move this to In Progress?"
          confirmText="Move Guide to In Progress"
          cancelText="Cancel"
          closeModal={() =>
            setMoveInProgressModalOpen(!moveInProgressModalOpen)
          }
          onConfirm={moveToInProgress}
          visible={moveInProgressModalOpen}
          isCloseOnly={false}
        >
          <p className={styles.archiveText}>
            When a guide is In Progress it is not available to employees via the
            URL until the guide is republished.
          </p>
        </ConfirmationDialog>
        <ConfirmationDialog
          title="View Password"
          confirmText="Save"
          cancelText="Cancel"
          closeModal={() => setPasswordModalOpen(!isPasswordModalOpen)}
          onConfirm={saveDBGPassword}
          visible={isPasswordModalOpen}
          isCloseOnly={false}
          disableConfirmButton={isEqual(
            {
              enabled: secured?.enabled,
              password: secured?.password,
            },
            {
              enabled: isPasswordSectionEnabled,
              password: isEmpty(dbgPassword) ? undefined : dbgPassword,
            }
          )}
          modalClassName={styles.viewPasswordWrapper}
        >
          <div className={styles.viewPasswordWrapper}>
            <div className={styles.row}>
              <div className={styles.passwordEnabledText}>Password Enabled</div>
              <Switch
                className={styles.switch}
                checked={isPasswordSectionEnabled}
                onChange={(isEnabled) => {
                  setChangePasswordEnable(isEnabled);
                  setPasswordSectionEnable(isEnabled);
                }}
              />
            </div>
            <div className={styles.row}>
              <div>Password</div>
              {isPasswordSectionEnabled && (
                <div
                  className={styles.changeLink}
                  onClick={() => {
                    setChangePasswordEnable(!isChangePasswordEnabled);
                  }}
                >
                  Change
                </div>
              )}
            </div>
            {isPasswordRequiredTriggered && isEmpty(dbgPassword) && (
              <div className={styles.passwordRequired}>
                “Password is required”
              </div>
            )}
            <Input
              className={`${styles.passwordField} ${
                isPasswordRequiredTriggered && isEmpty(dbgPassword)
                  ? styles.invalid
                  : ''
              }`}
              disabled={!isChangePasswordEnabled}
              value={isPasswordSectionEnabled ? dbgPassword : ''}
              onChange={({ target = {} }) => {
                const { value } = target;
                setDBGPassword(value || '');
              }}
              autoComplete="off"
            />
          </div>
        </ConfirmationDialog>
        <CloneBenefitGuideModal
          visible={isCloneModalOpen}
          setVisible={setCloneModalOpen}
          guideName={selectedBenguide?.name || ''}
          guidePlanYear={selectedPlanYear}
          guideId={selectedBenguide?.masterId || ''}
          employerId={selectedBenguide?.employerId || ''}
          onCloned={(value: string) => {
            navigate(location.pathname, {
              state: { dbgStatus: BENEFIT_GUIDE_STATUS.IN_PROGRESS },
            });
            const planYear = filter(planYearsList, function (planYear) {
              return planYear.id === value;
            });
            if (planYear) {
              setSelectedPlanYear(planYear[0]);
            }
          }}
        />
        <ConfirmationDialog
          title="Edit URL"
          confirmText="Save"
          cancelText="Cancel"
          closeModal={() => setEditUrlModalOpen(!isEditUrlModalOpen)}
          onConfirm={savePublicUrl}
          visible={isEditUrlModalOpen}
          isCloseOnly={false}
          modalClassName={styles.viewEditUrlWrapper}
          isCancelLink={true}
        >
          <div className={styles.viewEditUrlWrapper}>
            <Alert
              className={styles.alertMessage}
              description="Changing the URL will make the guide unavailable at the previous URL. 
              If changing, please share the new link with all relevant parties."
              type="warning"
              showIcon
            />
            <div className={styles.row}>
              <span>Public URL</span>
            </div>
            <Row>
              <Input
                className={styles.benefitUrlDefault}
                value={`${benguideUrl}/${selectedBenguide?.urlHash || ''}`}
                disabled={true}
                bordered={true}
              />
              <Input
                type="text"
                className={`${styles.benefitUrlEditable} ${
                  characterLimitError || employerNameError
                    ? styles.benefitUrlError
                    : ''
                }`}
                value={employerNameInput}
                onChange={(val) => saveEmployerName(val.target.value)}
              />
            </Row>
            <span
              className={`${styles.characterLimit} ${
                characterLimitError ? styles.characterLimitError : ''
              }`}
            >
              Character Limit: {employerNameLength}/40
            </span>
            {urlErrorMessage && (
              <div className={styles.urlErrorMessage}>{urlErrorMessage}</div>
            )}
          </div>
        </ConfirmationDialog>
      </div>
    </ContentContainer>
  );
};
export default BenefitGuideHome;
