import React, { FC, useState } from 'react';
import { Card, Col, Popover, Row, Table } from 'antd';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { ReactComponent as DashboardNoData } from 'assets/images/dashboardUtils/no-data-issue-icon.svg';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import CustomSpinner from 'components/Spinner/CustomSpinner';
import IssueStatusBadge from 'modules/issueslog/components/IssueStatusBadge/IssueStatusBadge';
import IssuePriorityBadge from 'modules/issueslog/components/IssuePriorityBadge/IssuePriorityBadge';
import { handleNullOrEmpty } from 'modules/issueslog/utils/issueLogUtils';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { ACCOUNT_TYPE_ACCOUNT } from 'modules/brokers/constants/dashboardConstants';
import { exitWithoutSavingMsg } from 'constants/commonConstants';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import CreateIssue from 'modules/issueslog/components/CreateIssue/CreateIssue';
import { useAppSelector } from 'hooks/redux';
import { loginTypes } from 'constants/authConstants';
import { ISSUE_SUBTYPE_MAP } from 'modules/issueslog/constants/IssueLogFilterConstants';
import styles from './IssueMgtDashboardPane.module.less';

type IProps = {
  isTechAdmin: boolean;
  isBrokerLevel: boolean;
  dataSet: {
    isLoading: boolean;
    isSuccess: boolean;
    data: any;
    isError: boolean;
  };
};

const IssueMgtDashboardPane: FC<IProps> = ({
  dataSet,
  isTechAdmin,
  isBrokerLevel,
}) => {
  const navigate = useNavigate();
  const { auth } = useAppSelector((state) => state.auth);
  const [isOpenCreateIssue, setOpenCreateIssue] = useState<boolean>(false);
  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);
  const { brokerId, employerId } = useParams();
  const { appBootupInfo } = auth;

  const issueLogEmplBaseUrl = `/brokers/${brokerId}/employers/${employerId}/issues-log`;
  const issueLogBrokerlBaseUrl = `/brokers/${brokerId}/issues-log`;
  const locationTooltipContent = () => (
    <div className={styles.popoverContent}>
      This is the number of items resolved
      <br /> in the past 2 weeks.
    </div>
  );

  const dashboardTransferData = {
    isCameFromDashboard: true,
    brokerId,
  };

  const handleIssueViewMoreClick = () => {
    const issueType: string = dataSet.data.issueLinkType;
    if (employerId) {
      if (ACCOUNT_TYPE_ACCOUNT === issueType) {
        navigate(`${issueLogEmplBaseUrl}/account`, {
          state: dashboardTransferData,
        });
      } else {
        navigate(`${issueLogEmplBaseUrl}/support}`, {
          state: dashboardTransferData,
        });
      }
    } else {
      if (isTechAdmin) {
        if (ACCOUNT_TYPE_ACCOUNT === issueType) {
          navigate(`/issues-log/account`, { state: dashboardTransferData });
        } else {
          navigate(`/issues-log/support`, { state: dashboardTransferData });
        }
      } else {
        if (ACCOUNT_TYPE_ACCOUNT === issueType) {
          navigate(`${issueLogBrokerlBaseUrl}/account`, {
            state: dashboardTransferData,
          });
        } else {
          navigate(`${issueLogBrokerlBaseUrl}/support`, {
            state: dashboardTransferData,
          });
        }
      }
    }
  };

  const tableColumns: any[] = [
    {
      title: 'EMPLOYER',
      dataIndex: 'employerName',
      key: 'id',
      width: '25%',
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: 'ascend',
      hidden: !isBrokerLevel,
      render: (val: any, colData: any) => {
        return (
          <div>
            <IssuePriorityBadge
              issuePriority={colData.priority}
              label={handleNullOrEmpty(colData.employerName)}
            />
            <br />
          </div>
        );
      },
    },
    {
      title: 'ITEM NAME',
      dataIndex: 'subject',
      key: 'id',
      width: '55%',
      ellipsis: {
        showTitle: false,
      },
      hidden: false,
      defaultSortOrder: 'ascend',
      render: (val: any, colData: any) => {
        return (
          <div>
            <Row>
              {!isBrokerLevel ? (
                <Col className={styles.employeePriorityBadge}>
                  <IssuePriorityBadge
                    issuePriority={colData.priority}
                    label={''}
                  />
                </Col>
              ) : (
                <></>
              )}
              <Col className={styles.issueName}>
                <OverflowPopover>
                  <span
                    className={styles.issueText}
                    onClick={() =>
                      handleNavigateToIssueLogView(colData.id, colData.type)
                    }
                  >
                    {handleNullOrEmpty(colData.subject)}
                  </span>
                </OverflowPopover>
                <IssueStatusBadge issueStatus={colData.status} />
              </Col>
            </Row>
          </div>
        );
      },
    },
    {
      title: 'TYPE',
      dataIndex: 'subtype',
      key: 'id',
      width: '20%',
      ellipsis: {
        showTitle: false,
      },
      defaultSortOrder: 'ascend',
      hidden: isBrokerLevel,
      render: (val: any, colData: any) => {
        return (
          <p className={styles.mainIssueTitleNrml}>
            {!isEmpty(colData.subType) ? (
              ISSUE_SUBTYPE_MAP.find((value) => value.value === colData.subType)
                ?.label
            ) : (
              <>--</>
            )}
          </p>
        );
      },
    },
    {
      title: 'LAST MODIFIED',
      dataIndex: 'modifiedTime',
      key: 'id',
      width: '30%',
      hidden: false,
      render: (val: any, colData: any) => {
        return (
          <div>
            <p className={styles.tableDateTime}>
              {moment(colData.modifiedTime).format('lll')}
            </p>
            <p className={styles.mainIssueTitleMutedMargined}>
              By{' '}
              {isEmpty(colData.modifiedByName)
                ? colData.createdByName
                : colData.modifiedByName}
            </p>
          </div>
        );
      },
    },
    {
      title: 'ASSIGNED TO',
      dataIndex: 'assigneeName',
      key: 'assigneeId',
      width: '30%',
      hidden: !isBrokerLevel,
      render: (val: any, colData: any) => {
        return (
          <div className={styles.assigneeText}>
            <OverflowPopover>
              <span className={styles.assigneeName}>
                {handleNullOrEmpty(colData.assigneeName)}
              </span>
            </OverflowPopover>
          </div>
        );
      },
    },
  ];

  const handleNavigateToIssueLog = () => {
    {
      (appBootupInfo && appBootupInfo.type === loginTypes.platform) ||
      loginTypes.bokerAdmin
        ? employerId
          ? navigate(`${issueLogEmplBaseUrl}/account`)
          : navigate(`issues-log/account`)
        : navigate(`${issueLogEmplBaseUrl}/account`);
    }
  };
  const handleNavigateToIssueLogView = (id: any, type: any) => {
    const issueId: string = id;
    {
      isTechAdmin
        ? employerId
          ? type === 'ACCOUNT'
            ? navigate(`${issueLogEmplBaseUrl}/account/${issueId}`)
            : navigate(`${issueLogEmplBaseUrl}/support/${issueId}`)
          : type === 'ACCOUNT'
          ? navigate(`/issues-log/account/${issueId}`)
          : navigate(`/issues-log/support/${issueId}`)
        : employerId
        ? type === 'ACCOUNT'
          ? navigate(`${issueLogEmplBaseUrl}/account/${issueId}`)
          : navigate(`${issueLogEmplBaseUrl}/support/${issueId}`)
        : type === 'ACCOUNT'
        ? navigate(`${issueLogBrokerlBaseUrl}/account/${issueId}`)
        : navigate(`${issueLogBrokerlBaseUrl}/support/${issueId}`);
    }
  };

  const getApplicableSizeForIssuePage = (): number =>
    isBrokerLevel && !isTechAdmin ? 7 : 11;

  const segregateColumns = (set: any[]): any[] => {
    return set.filter((obj) => !obj.hidden);
  };

  return (
    <div className={styles.issueMstDashboard}>
      <Row>
        {/*  Title Section */}
        <Col xl={12} lg={12} sm={12} md={12} xs={12}>
          <h3 className={styles.mainIssueTitle}>
            {isBrokerLevel
              ? isTechAdmin
                ? 'Recent Items'
                : 'My Items'
              : 'Recent Items'}
          </h3>
          <p
            className={
              isBrokerLevel
                ? isTechAdmin
                  ? styles.displayNoneUtil
                  : styles.displayUtil
                : styles.displayNoneUtil
            }
          >
            Items you are assigned to or watching
          </p>
        </Col>
        {/*  Button Section*/}
        <Col
          xl={12}
          lg={12}
          sm={12}
          md={12}
          xs={12}
          style={{ alignContent: 'end' }}
        >
          <div className={styles.issueBtnGroup}>
            {appBootupInfo && appBootupInfo.type === loginTypes.platform ? (
              employerId ? (
                <></>
              ) : (
                <></>
              )
            ) : employerId ? (
              <PageActionButton
                className={styles.newIssueBtn}
                onClick={() => {
                  setOpenCreateIssue(!isOpenCreateIssue);
                }}
              >
                <PlusOutlined />
                New Item
              </PageActionButton>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>

      {/*    Table Row Section*/}
      <Card className={styles.issueTableCard}>
        <Row style={{ marginBottom: '35px' }}>
          <Col
            xs={getApplicableSizeForIssuePage()}
            className={styles.issuePane}
          >
            <p className={styles.issuePaneHeading}>Open Items</p>
            <p className={styles.issuePaneNumeric}>
              {dataSet.data?.openIssueCount}
            </p>
          </Col>

          {isBrokerLevel ? (
            !isTechAdmin ? (
              <Col
                xs={getApplicableSizeForIssuePage()}
                className={styles.issuePane}
              >
                <p className={styles.issuePaneHeading}>Assigned to Me</p>
                <p className={styles.issuePaneNumeric}>
                  {dataSet.data?.assignedIssuesCount}
                </p>
              </Col>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          <Col
            xs={getApplicableSizeForIssuePage()}
            className={styles.issuePane}
          >
            <div className={styles.recentResolved}>
              <p className={styles.issuePaneHeading}>Recently Resolved</p>
              <Popover
                content={locationTooltipContent}
                placement="right"
                overlayClassName="popoverInner"
              >
                <QuestionCircleOutlined className={styles.popoverIcon} />
              </Popover>
            </div>
            <p className={styles.issuePaneNumeric}>
              {dataSet.data?.resolvedIssuesCount}
            </p>
          </Col>
        </Row>
        {!dataSet.isError ? (
          dataSet.isLoading ? (
            <CustomSpinner />
          ) : dataSet.isSuccess && dataSet.data?.recentIssues.length ? (
            <Row className={styles.issueTable}>
              <Table
                columns={segregateColumns(tableColumns)}
                dataSource={dataSet.isSuccess ? dataSet.data.recentIssues : []}
                loading={dataSet.isLoading}
                pagination={false}
              />
              <p
                className={styles.tableEndText}
                onClick={handleIssueViewMoreClick}
              >
                View More
              </p>
            </Row>
          ) : (
            <Row className={styles.emptyIssue}>
              <Col xl={12}>
                <DashboardNoData />
                <p className={styles.emptyIssueText}>You have no open items</p>
                <p className={styles.emptyIssueParaText}>
                  You can still view your main account log table below
                </p>
                <PageActionButton
                  type="primary"
                  className={styles.uploadClaimsButton}
                  onClick={handleNavigateToIssueLog}
                >
                  Open Account Log
                </PageActionButton>
              </Col>
            </Row>
          )
        ) : (
          // TODO - Change this to error massage after confirmation with the client
          <Row className={styles.emptyIssue}>
            <Col xl={8}>
              <DashboardNoData />
              <p className={styles.emptyIssueText}>You have no open items</p>
              <p className={styles.emptyIssueParaText}>
                You can still view your main account log table below
              </p>
              <PageActionButton
                type="primary"
                className={styles.uploadClaimsButton}
                onClick={handleNavigateToIssueLog}
              >
                Open Account Log
              </PageActionButton>
            </Col>
          </Row>
        )}
      </Card>

      <FullScreenModal
        visible={isOpenCreateIssue}
        footer={false}
        onCancel={() => {
          setVisibleConfirmation(true);
        }}
        destroyOnClose={true}
        className={styles.Model}
      >
        <CreateIssue
          onSuccessfullySaved={(id: string) => {
            setOpenCreateIssue(false);
            navigate(`issues-log/account/${id}`);
          }}
          onClickedCancel={() => {
            setVisibleConfirmation(true);
          }}
          triggerFromDashboard={true}
        />
        {visibleConfirmation && (
          <ConfirmationDialog
            title="Are you sure you want to close?"
            confirmText="Yes - close and do not save"
            cancelText="Cancel"
            closeModal={() => {
              setVisibleConfirmation(false);
            }}
            onConfirm={() => {
              setOpenCreateIssue(false), setVisibleConfirmation(false);
            }}
            visible={visibleConfirmation}
          >
            <p className={styles.warningConfirmation}>{exitWithoutSavingMsg}</p>
          </ConfirmationDialog>
        )}
      </FullScreenModal>
    </div>
  );
};

export default IssueMgtDashboardPane;
