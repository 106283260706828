import { Col, Form, Input, Row, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Title from 'antd/lib/typography/Title';
import { FC, useEffect, useState } from 'react';
import { useNavContext } from 'hooks/useNavContext';

import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import SelectOptions from 'components/SelectOptions/SelectOptions';

import { DENTAL } from 'modules/plans/planRates/pages/PlanRates/PlanRates';
import {
  ADDITIONAL_OPTIONS,
  OFFER_BENEFIT_TYPES,
} from 'modules/renewals/constants/renewalsConstants';
import useGetUserFeatureStatus from 'hooks/useGetUserFeatureStatus';
import { BenefitCategory, FEATURE_KEYS } from 'constants/commonConstants';
import { VISION_ADVANCED_PROFILE_PARAMETERS } from 'modules/plans/constants';
import styles from './additionalServiceModal.module.less';

const { Option } = Select;

type AdditionalServiceModalProps = {
  availableServices: String[];
  visible: boolean;
  onClose: Function;
  benefitType: string;
  addAdditionalService: Function;
  setAddServiceDisable: any;
};

type DefaultValues = {
  name: string;
  fieldType: string;
  fieldSubType: string;
  value: string;
  added?: boolean;
};

const AdditionalServiceModal: FC<AdditionalServiceModalProps> = (
  props: AdditionalServiceModalProps
) => {
  const {
    visible,
    onClose,
    benefitType,
    availableServices,
    addAdditionalService,
    setAddServiceDisable,
  } = props;

  const defaultValues: DefaultValues = {
    name: '',
    fieldType: '',
    fieldSubType: '',
    value: '',
    added: false,
  };

  const [serviceName, setServiceName] = useState<string>('');
  const [inNetwork, setInNetwork] = useState<DefaultValues>(defaultValues);
  const [outNetwork, setOutNetwork] = useState<DefaultValues>(defaultValues);

  const resetFields = () => {
    form.resetFields();
    setServiceName('');
    setInNetwork(defaultValues);
    setOutNetwork(defaultValues);
  };

  const validateForm = async () => {
    try {
      await form.validateFields();
    } catch (errorInfo) {
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    const valid = await validateForm();

    if (!valid) {
      return;
    }

    const inNetworkService = {
      name: serviceName,
      fieldType: 'In Network',
      fieldSubType: null,
      value: inNetwork.value,
      added: inNetwork?.added,
    };

    const outNetworkService = {
      name: serviceName,
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: outNetwork.value,
      added: outNetwork?.added,
    };

    addAdditionalService([inNetworkService, outNetworkService]);

    resetFields();
    onClose();
  };

  const onCancel = () => {
    resetFields();
    onClose();
  };

  const modalTitle = (
    <div>
      <div>
        <Title level={3} className={styles.center}>
          Add{' '}
          {benefitType === DENTAL
            ? OFFER_BENEFIT_TYPES.DENTAL
            : OFFER_BENEFIT_TYPES.VISION}{' '}
          Service
        </Title>
      </div>
    </div>
  );

  const footer = (
    <div className={styles.footerWrapper}>
      <CancelButton
        onClick={onCancel}
        className={styles.cancelButton}
        withBorder={true}
      >
        Cancel
      </CancelButton>
      <SubmitButton
        key="submit"
        type="primary"
        onClick={onSubmit}
        className={styles.saveButton}
      >
        Add
      </SubmitButton>
    </div>
  );

  const [form] = Form.useForm();

  const { brokerId, employerId } = useNavContext();

  const isAdvancedProfile = useGetUserFeatureStatus(
    brokerId,
    employerId,
    FEATURE_KEYS.ADVANCED_PROFILE,
    false
  );

  const getFilteredOptions = () => {
    const currentOptions =
      ADDITIONAL_OPTIONS[benefitType as 'DENTAL' | 'VISION'] || [];
    const filtered = currentOptions.filter(
      (option: any) =>
        !availableServices.includes(option.value) &&
        (isAdvancedProfile ||
          BenefitCategory.VISION.value !== benefitType ||
          !VISION_ADVANCED_PROFILE_PARAMETERS.includes(option.value))
    );

    return filtered;
  };

  const filteredOptions = getFilteredOptions();

  useEffect(() => {
    setAddServiceDisable(filteredOptions.length === 0);

    // the useEffect needs to run when the filteredOptions array updates to get the updated array length
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredOptions]);

  return (
    <Modal
      wrapClassName={styles.modalWrapper}
      title={modalTitle}
      width={740}
      footer={footer}
      visible={visible}
      onOk={() => onSubmit()}
      onCancel={() => onCancel()}
      maskClosable={false}
      closable={false}
    >
      <Form form={form}>
        <Row>
          <Col span={5}>
            <div className={styles.fieldLabel}>
              <strong>Select Service</strong>
            </div>
          </Col>
          <Col span={19}>
            <Form.Item
              name="Service Type"
              className={styles.planName}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please select a service',
                },
              ]}
            >
              <SelectOptions
                value={serviceName}
                onChange={(value) => {
                  setServiceName(value);
                  form.resetFields(['Service Name']);
                }}
              >
                {getFilteredOptions()?.map((option: any, index: any) => (
                  <Option value={option.value} key={index}>
                    {option.label}
                  </Option>
                ))}
              </SelectOptions>
            </Form.Item>
          </Col>
        </Row>
        <Row className={styles.titleRow}>
          <Col span={5}></Col>
          <Col span={15}>Amount/Frequency </Col>
        </Row>
        <Row>
          <Col span={5} className={styles.fieldLabel}>
            In-Network
          </Col>
          <Col span={14}>
            <Input
              onChange={(event: any) =>
                setInNetwork({ ...inNetwork, value: event.target.value })
              }
              value={inNetwork.value}
            />
          </Col>
        </Row>
        <Row className={styles.mt10}>
          <Col span={5} className={styles.fieldLabel}>
            Out-of-Network
          </Col>
          <Col span={14}>
            <Input
              onChange={(event: any) =>
                setOutNetwork({ ...outNetwork, value: event.target.value })
              }
              value={outNetwork.value}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AdditionalServiceModal;
