import { FC, MutableRefObject, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  AddLifePlanDocumentType,
  BasicPlans,
  LTDPlanDocumentType,
  STDPlanDocumentType,
  VoluntaryPlanDocumentType,
  VoluntaryPlans,
} from 'modules/plans/constants';
import {
  removeDocumentReference,
  uploadTemporaryDocument,
} from 'modules/plans/slices/lifePlanSlice';
import EditPlanDocuments from 'modules/plans/components/EditPlanDocuments/EditPlanDocuments';

import styles from './resourceInformation.module.less';

type ResourceInformationProps = {
  onPlanChange: Function;
  onFileUpload?: Function;
  onFileRemoveAction?: Function;
  lifePlanDocsRef: MutableRefObject<any>;
  planType?: string;
  isEditMode?: boolean;
  removeUploadedDocument: Function;
  documents: { [key: string]: string };
  documentReferences?: any;
  planId?: string;
  onValidateFails?: (validateSetting: string) => void;
  isSaveDisabled?: boolean;
  isTitleVisible?: boolean;
};

const ResourceInformation: FC<ResourceInformationProps> = (
  props: ResourceInformationProps
) => {
  const {
    planType,
    isEditMode = false,
    removeUploadedDocument,
    documents,
    planId,
    lifePlanDocsRef,
    onValidateFails,
    isSaveDisabled = false,
    onFileUpload = (document: File, docType: string) => {
      dispatch(uploadTemporaryDocument(document, docType));
    },
    onFileRemoveAction,
    documentReferences,
    isTitleVisible,
  } = props;

  const { documentReferences: lifeDocumentReferences } = useAppSelector(
    (state) => state.plan.lifePlan
  );
  const dispatch = useAppDispatch();

  const extraDocumentationType = useMemo(() => {
    switch (planType) {
      case BasicPlans.STD.value:
      case VoluntaryPlans.VOLUNTARY_STD.value:
        return STDPlanDocumentType;
      case BasicPlans.LTD.value:
      case VoluntaryPlans.VOLUNTARY_LTD.value:
        return LTDPlanDocumentType;
      case VoluntaryPlans.VOLUNTARY_LIFE.value:
      case VoluntaryPlans.VOLUNTARY_ADD.value:
      case VoluntaryPlans.VOLUNTARY_LIFE_ADD.value:
        return VoluntaryPlanDocumentType;
      default:
        return {};
    }
  }, [planType]);

  const handleFileRemove = (docType: string) => {
    if (isEditMode && Object.keys(documents).includes(docType)) {
      removeUploadedDocument(docType);
    }
    if (onFileRemoveAction) {
      dispatch(onFileRemoveAction(docType));
    } else {
      dispatch(removeDocumentReference(docType));
    }
  };

  return (
    <div className={isEditMode ? styles.editWrapper : ''}>
      {isTitleVisible && (
        <div className={styles.headerText}>Plan Resources</div>
      )}
      <div className={styles.documentWrapper}>
        <EditPlanDocuments
          onUpload={onFileUpload}
          onFileRemove={handleFileRemove}
          documents={documents}
          documentReferences={documentReferences ?? lifeDocumentReferences}
          planDocumentTypes={{
            ...AddLifePlanDocumentType,
            ...extraDocumentationType,
          }}
          planId={planId}
          benefitKind={planType}
          ref={lifePlanDocsRef}
          onValidateFails={onValidateFails}
          isSaveDisabled={isSaveDisabled}
        />
      </div>
    </div>
  );
};

export default ResourceInformation;
