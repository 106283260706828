import { Dropdown, Menu, Row } from 'antd';
import { isArray, isPlainObject } from 'lodash';
import { ColumnData } from 'modules/renewals/models/ColumnData';
import {
  ADDITIONAL_SERVICES_FIELD_PROPERTY,
  BENEFIT_FIELD_PROPERTY,
  CONTACT_LENSES_FREQUENCY,
  DEDUCTIBLE_TYPE,
  DEDUCTIBLES_FIELD_PROPERTY,
  FROM_CURRENT_PROPERTY,
  IN_NETWORK_VALUE,
  INN_OON_SUFFIX,
  LIFE,
  LIFE_PLANS,
  LTD,
  MDV_PLANS,
  MONTH,
  OFFER_BENEFIT_TYPE_NAVIGATION_LABELS,
  OFFER_BENEFIT_TYPES,
  OOP_FIELD_PROPERTY,
  OUT_NETWORK_DEDUCTIBLE_FIELD_PROPERTY,
  OUT_NETWORK_OOP_FIELD_PROPERTY,
  OUT_NETWORK_RX_DEDUCTIBLE_PROPERTY,
  OUT_NETWORK_RX_OOP_PROPERTY,
  OUT_NETWORK_RX_TIER_PROPERTY,
  PLAN_ATTACHMENTS_LABEL,
  PLAN_DOCUMENTS_FIELD_PROPERTY,
  PLAN_TABLE_HEADER_TITLES,
  PLAN_TABLE_INITIAL_TITLES,
  PLAN_TYPE_FIELD_PROPERTY,
  PREMIUMS_FIELD_PROPERTY,
  PREMIUMS_PRIORITY_ORDER,
  RX_DEDUCTIBLE_FIELD_PROPERTY,
  RX_OOP_FIELD_PROPERTY,
  RX_TIERS_FIELD_PROPERTY,
  SERVICE_PRIORITY_ORDER,
  SERVICES_FIELD_PROPERTY,
  STD,
  VISION_ADDITIONAL_SERVICE_PRIORITY_ORDER,
  VOLUME_FIELD_PROPERTY,
} from 'modules/renewals/constants/renewalsConstants';
import { handleFileItemDownloadClickForPopulatedFileItem } from 'modules/renewals/services/RenewalService';
import { getFileNameEllipsis } from 'util/stringUtil';
import {
  formatEmptyValues,
  formatLabel,
  formatValueForPlanType,
  formatValuesToCurrencyOrFreeText,
  getPlanPropertyOrder,
  isObjectProperties,
  isSingleColumnProperty,
  isTitleProperty,
  validateLifeRateError,
  validatePlanTableFields,
} from 'modules/renewals/utils/planTableOrderAndOtherUtils';
import {
  BenefitType,
  GetPlanTableColumnLabelsParams,
  NetworkType,
} from 'modules/renewals/models/PlanTable';
import {
  ArrayItemTemplate,
  PartialArrayItemTemplate,
  Plan,
  ServiceArrayItemTemplate,
} from 'modules/renewals/types/planTypes';
import FromCurrentPercentage from 'modules/renewals/components/helperComponents/FromCurrentPercentage/FromCurrentPercentage';
import styles from 'modules/renewals/utils/planDetailUtil.module.less';
import {
  buildPremiumShortForm,
  transformMDVPlanDataToTablePlan,
  transformMissingFilledPlans,
} from './planTableDataTransformerUtil';

/**
 * Generates a dropdown menu for displaying attachment options.
 *
 * @param {Object[]} attachments - An array of attachment objects.
 * @param {string} attachments[].referenceId - The reference ID of the attachment.
 * @param {string} attachments[].downloadUrl - The download URL of the attachment.
 * @param {string} attachments[].fileName - The name of the attachment file.
 *
 * @return {JSX.Element} A dropdown menu for attachment options.
 */
const dropdownOverlayActionsMenu = ({
  attachments,
}: {
  attachments: {
    referenceId: string;
    downloadUrl: string;
    fileName: string;
  }[];
}): JSX.Element => {
  return attachments?.length ? (
    <Menu className={styles.scrollableMenu}>
      {attachments?.map((attachment) => {
        return (
          <Menu.Item
            key={attachment?.referenceId}
            onClick={() => {
              handleFileItemDownloadClickForPopulatedFileItem(
                attachment?.referenceId
                  ? attachment?.referenceId
                  : attachment?.downloadUrl,
                attachment?.fileName
              );
            }}
          >
            <span className={styles.dropDownItem}>
              {getFileNameEllipsis({
                fileName: String(attachment?.fileName),
                limit: 25,
              })}
            </span>
          </Menu.Item>
        );
      })}
    </Menu>
  ) : (
    <></>
  );
};

const findLLMExtractionInfo = ({
  array,
  fieldType,
  fieldSubType,
  isNot,
}: {
  array: PartialArrayItemTemplate[];
  fieldType: NetworkType;
  fieldSubType?: string;
  isNot?: boolean;
}): PartialArrayItemTemplate | undefined => {
  const result = array?.find(
    ({
      fieldSubType: itemSubType,
      fieldType: itemType,
    }: PartialArrayItemTemplate) => {
      const isFieldSubType = fieldSubType
        ? isNot
          ? itemSubType !== fieldSubType
          : itemSubType === fieldSubType
        : true;
      return itemType === fieldType && isFieldSubType;
    }
  );
  return result;
};

/**
 * Transforms an array of plans into an array of table plans.
 * @param {Object} options - The options for transforming the plans.
 * @param {Partial<Plan>[]} options.plans - The array of plans to transform.
 * @param {BenefitType} options.benefitType - The type of benefit.
 * @param {boolean} [options.isComparePlans=false] - Indicates whether the plans are being compared.
 * @return {Partial<Plan>[]} The transformed array of table plans.
 */
export const transformPlansToTablePlans = ({
  plans,
  benefitType,
  isComparePlans = false,
  isNTier,
  nTierCount,
  isPlanDetails,
  isPreview,
  isPlanDetailSidebar,
  isNoCurrentPlans,
}: {
  plans: any[];
  benefitType: BenefitType;
  isComparePlans?: boolean | undefined;
  isNTier?: boolean;
  nTierCount?: number;
  isPlanDetails?: boolean;
  isPreview?: boolean;
  isPlanDetailSidebar?: boolean;
  isNoCurrentPlans?: boolean;
}): any[] => {
  const missingFilledPlans =
    isComparePlans || isPlanDetails || isPreview || isPlanDetailSidebar
      ? plans
      : transformMissingFilledPlans({
          plans: plans,
          isNTier: isNTier,
          nTierCount: nTierCount,
          benefitType: benefitType,
          isNoCurrentPlans,
        });

  if (MDV_PLANS.includes(benefitType)) {
    return missingFilledPlans?.map((plan: any, index: number) => {
      return plan?.isTransformed
        ? plan
        : {
            ...transformMDVPlanDataToTablePlan({
              plan,
              benefitType,
              isCurrentPlan: index === 0 && isComparePlans,
            }),
          };
    });
  } else {
    return missingFilledPlans;
  }
};

/**
 * Adds a title column to the columnDataArray if it doesn't already exist.
 * The title column is based on the provided property and its corresponding header label.
 *
 * @param {string} property - The property used to determine the header label and render the column.
 * @param {ColumnData[]} columnDataArray - The array of column data to add the title column to.
 */
const addTitleColumns = ({
  property,
  columnDataArray,
}: {
  property: string;
  columnDataArray: ColumnData[];
}): void => {
  const headerLabel = PLAN_TABLE_HEADER_TITLES[property]?.toUpperCase();
  if (!columnDataArray.some((item) => item.label === headerLabel)) {
    columnDataArray.push({
      label: headerLabel,
      isTableBreak: true,
      isWhiteBG: false,
      render: () => {
        return property || '-';
      },
    });
  }
};

/**
 * Adds single columns to the columnDataArray based on the provided property, propertyValues, and columnDataArray.
 * This combine multiple values into a single column. like Ind/Fam Deductibles, Ind/Fam OOPs, etc.
 *
 * @param {object} options - The options object.
 * @param {string} options.property - The property to check.
 * @param {any[]} options.propertyValues - The property values to process.
 * @param {ColumnData[]} options.columnDataArray - The array of column data.
 * @return {void}
 */
const addSingleColumns = ({
  property,
  propertyValues = [],
  columnDataArray,
}: {
  property: string;
  propertyValues: any[];
  columnDataArray: ColumnData[];
}): void => {
  if (
    [
      DEDUCTIBLES_FIELD_PROPERTY,
      OOP_FIELD_PROPERTY,
      OUT_NETWORK_OOP_FIELD_PROPERTY,
      OUT_NETWORK_DEDUCTIBLE_FIELD_PROPERTY,
      RX_DEDUCTIBLE_FIELD_PROPERTY,
      RX_OOP_FIELD_PROPERTY,
      OUT_NETWORK_RX_DEDUCTIBLE_PROPERTY,
      OUT_NETWORK_RX_OOP_PROPERTY,
    ].includes(property)
  ) {
    const tierNames =
      propertyValues?.length !== 0
        ? propertyValues?.map(
            (item: ArrayItemTemplate) =>
              DEDUCTIBLE_TYPE[item.fieldSubType!] || ''
          )
        : [DEDUCTIBLE_TYPE.Single, DEDUCTIBLE_TYPE.Family];

    const label = formatLabel(property, tierNames, false);

    columnDataArray.push({
      label,
      isTableBreak: false,
      isWhiteBG: false,
      render: (payload: any) => {
        const sortedValues = payload?.[property]?.map(
          (item: ArrayItemTemplate) => {
            const llmObj = findLLMExtractionInfo({
              array: payload?.llmExtractionInfo?.[property],
              fieldType: item.fieldType!,
              fieldSubType: item.fieldSubType!,
              isNot: true,
            });

            return {
              different: llmObj?.different,
              value: formatValuesToCurrencyOrFreeText(
                property,
                item?.formattedValue || item?.value
              ),
            };
          }
        );

        const isLLMDifferent = sortedValues?.some(
          (item: { value: string; different: boolean }) => item?.different
        );

        return sortedValues?.length === 0 ? (
          '- / -'
        ) : (
          <div className={`${isLLMDifferent ? 'llmDifferent' : ''}`}>
            {sortedValues
              ?.map(
                (
                  item: { value: string; different: boolean },
                  index: number
                ) => {
                  return <span key={index}>{item?.value}</span>;
                }
              )
              .reduce(
                (
                  prev: { value: string; different: boolean },
                  curr: { value: string; different: boolean }
                ) => [prev, ' / ', curr]
              )}
          </div>
        );
      },
    });
  }
};

/**
 * Adds Rx Tiers columns to the columnDataArray based on the provided parameters checking max tier in plans
 * and generate the tiers.
 * @param {string} property - The property to be used for column data.
 * @param {Plan[]} transformedPlans - The array of transformed plans.
 * @param {ColumnData[]} columnDataArray - The array of column data.
 * @return {void}
 */
const addRxTiersColumns = ({
  property,
  transformedPlans,
  columnDataArray,
}: {
  property: string;
  transformedPlans: Plan[];
  columnDataArray: ColumnData[];
}): void => {
  const maxTierCount: number = Math.max(
    ...transformedPlans?.map((plan: Plan) => plan?.rxTiers?.length || 1)
  );

  for (let i = 0; i < maxTierCount; i++) {
    columnDataArray.push({
      label: `${PLAN_TABLE_INITIAL_TITLES[property]} Tier ${i + 1}`,
      isTableBreak: false,
      isWhiteBG: false,
      render: (payload: any) => {
        const sortRxTierValues =
          payload?.[property]?.sort(
            (a: ArrayItemTemplate, b: ArrayItemTemplate) =>
              a?.name > b?.name ? 1 : -1
          ) || [];
        const value =
          sortRxTierValues[i]?.formattedValue || sortRxTierValues[i]?.value;
        const llmObj = payload?.llmExtractionInfo?.[property]?.find(
          (item: ArrayItemTemplate) => item?.name === sortRxTierValues[i]?.name
        );

        return (
          <span className={`${llmObj?.different ? `llmDifferent` : ''}`}>
            {formatValuesToCurrencyOrFreeText(property, value) || '-'}
          </span>
        );
      },
    });
  }
};

/**
 * Aggregates unique services from the provided array of plans.
 *
 * @param {Plan[]} plans - Array of Plan objects to extract unique services from.
 * @return {Service[]} - Array of unique Service objects derived from the plans.
 */
const aggregateUniqueServices = (plans: Plan[]): ServiceArrayItemTemplate[] => {
  const allServicesMap = new Map<string, ServiceArrayItemTemplate>();

  plans.forEach((plan) => {
    if (plan?.displayServices && Array.isArray(plan?.displayServices)) {
      plan?.displayServices.forEach((service) => {
        const serviceKey = `${service.name}`;
        if (!allServicesMap.has(serviceKey)) {
          const serviceItem: ServiceArrayItemTemplate = {
            name: service.name ?? '',
            inNetworkValue: null,
            outOfNetworkValue: null,
            formattedValue: null,
            value: null,
            fieldType: service.fieldType,
            fieldSubType: service.fieldSubType,
          };
          allServicesMap.set(serviceKey, serviceItem);
        }
      });
    }
  });

  return Array.from(allServicesMap.values());
};

const addServicesColumns = ({
  property,
  columnDataArray,
  benefitType,
  transformedPlans,
}: {
  property: string;
  columnDataArray: ColumnData[];
  benefitType: BenefitType;
  transformedPlans: Plan[];
}): void => {
  const aggregatedPropertyValues = aggregateUniqueServices(
    transformedPlans ?? []
  );
  const sortedServices = aggregatedPropertyValues
    ?.sort(
      (a: ArrayItemTemplate, b: ArrayItemTemplate) =>
        SERVICE_PRIORITY_ORDER?.[benefitType]?.indexOf(
          a?.name?.replace(INN_OON_SUFFIX, '')?.trim()
        ) -
        SERVICE_PRIORITY_ORDER?.[benefitType]?.indexOf(
          b?.name.replace(INN_OON_SUFFIX, '')?.trim()
        )
    )
    .filter((item) => item?.fieldType === IN_NETWORK_VALUE);

  sortedServices?.forEach((service: ServiceArrayItemTemplate) => {
    columnDataArray.push({
      label: service.name,
      isTableBreak: false,
      isWhiteBG: false,
      render: (payload: any) => {
        const servicesValue = payload?.[property]?.find(
          (item: ServiceArrayItemTemplate) => item?.name === service?.name
        );
        const llmObj = payload?.llmExtractionInfo?.[property]?.find(
          (item: ArrayItemTemplate) => item?.name === service?.name
        );

        return (
          <div
            className={`${
              llmObj?.inNetworkDifferent || llmObj?.outOfNetworkDifferent
                ? 'llmDifferent'
                : ''
            }`}
          >
            <span>{servicesValue?.inNetworkValue || '-'} </span>/
            <span> {servicesValue?.outOfNetworkValue || '-'}</span>
          </div>
        );
      },
    });
  });
};

/**
 * Adds columns for additional services frequencies to the provided column data array.
 *
 * This function processes a list of transformed plans to create a union of additional
 * service names, excluding 'Contact Lenses Frequency', and sorts them based on a predefined
 * priority order. It then adds these services as columns to the column data array.
 *
 * @param {string} property - The property name to look for in the payload.
 * @param {ColumnData[]} columnDataArray - The array to which the additional service columns will be added.
 * @param {Plan[]} transformedPlans - An array of transformed Plan objects to be processed.
 * @return {void}
 */

const addAdditionalServicesFrequencyColumns = ({
  property,
  columnDataArray,
  transformedPlans,
}: {
  property: string;
  columnDataArray: ColumnData[];
  transformedPlans: Plan[];
}): void => {
  // Get union of additionalServices
  const unionOfAdditionalServicesNames: Set<string> = new Set();

  transformedPlans?.forEach((plan: Plan) => {
    plan.additionalServices?.forEach((service: ServiceArrayItemTemplate) => {
      unionOfAdditionalServicesNames.add(service.name);
    });
  });

  // Exclude the 'Contact Lenses Frequency' always
  const allAdditionalServicesNames = Array.from(
    unionOfAdditionalServicesNames
  ).filter((name) => name !== CONTACT_LENSES_FREQUENCY);

  const sortedServiceNames = allAdditionalServicesNames?.sort(
    (a: string, b: string) =>
      VISION_ADDITIONAL_SERVICE_PRIORITY_ORDER?.indexOf(a?.trim()) -
      VISION_ADDITIONAL_SERVICE_PRIORITY_ORDER?.indexOf(b?.trim())
  );

  sortedServiceNames?.forEach((serviceName: string) => {
    columnDataArray.push({
      label: serviceName.replace(' Frequency', ''),
      isTableBreak: false,
      isWhiteBG: false,
      render: (payload: any) => {
        const servicesValue = payload?.[property]?.find(
          (item: ServiceArrayItemTemplate) =>
            item?.name === serviceName && item?.fieldType === IN_NETWORK_VALUE
        );

        const llmObj = payload?.llmExtractionInfo?.[property]?.find(
          (item: ArrayItemTemplate) =>
            item?.name === serviceName && item?.fieldType === IN_NETWORK_VALUE
        );

        return (
          <div
            className={`
              ${llmObj?.different ? 'llmDifferent' : ''}
              }`}
          >
            <span>
              {formatValuesToCurrencyOrFreeText(property, servicesValue?.value)}
            </span>
          </div>
        );
      },
    });
  });
};

const addPremiumsColumns = ({
  property,
  propertyValues,
  columnDataArray,
  isNTier,
  addEnrollmentsForPremiums,
  showErrorHighlight,
  isAddEditPlan,
  nTierCount,
}: {
  property: string;
  propertyValues: ArrayItemTemplate[];
  columnDataArray: ColumnData[];
  isNTier: boolean | undefined;
  addEnrollmentsForPremiums: boolean | undefined;
  showErrorHighlight: boolean | undefined;
  isAddEditPlan: boolean | undefined;
  nTierCount: number | undefined;
}): void => {
  const sortedPremiums = [...propertyValues]?.sort(
    (a: ArrayItemTemplate, b: ArrayItemTemplate) =>
      PREMIUMS_PRIORITY_ORDER?.indexOf(a.name) -
      PREMIUMS_PRIORITY_ORDER?.indexOf(b.name)
  );
  const premiumShortForm = buildPremiumShortForm(
    isNTier!,
    nTierCount!
  ).shortPremiumNames;

  sortedPremiums?.forEach((premium: ArrayItemTemplate) => {
    const label = (
      <span>
        {(premiumShortForm[premium?.name] || premium?.name) +
          (isAddEditPlan ? ' *' : '')}
        {!isNTier ? (
          <span className={styles.superscriptText}>{MONTH}</span>
        ) : (
          ''
        )}
      </span>
    );
    columnDataArray.push({
      label: label,
      isTableBreak: false,
      isWhiteBG: false,
      render: (payload) => {
        const selectedValue: ArrayItemTemplate = payload?.[property]?.find(
          (item: ArrayItemTemplate) =>
            item?.name === premium?.name &&
            item?.fieldType === premium?.fieldType &&
            item?.fieldSubType === premium?.fieldSubType
        );
        const findEnrollmentValue = !addEnrollmentsForPremiums
          ? payload?.enrolments?.find(
              (item: ArrayItemTemplate) =>
                item?.fieldSubType === premium?.fieldSubType
            )
          : null;
        const formattedValue = formatValuesToCurrencyOrFreeText(
          property,
          selectedValue?.formattedValue || selectedValue?.value
        );
        const llmObj = payload?.llmExtractionInfo?.[property]?.find(
          (item: ArrayItemTemplate) =>
            (premiumShortForm[item?.name] || item?.name) === premium?.name &&
            item?.fieldType === premium?.fieldType &&
            item?.fieldSubType === premium?.fieldSubType
        );

        return (
          <span
            className={`
              ${llmObj?.different ? 'llmDifferent' : ''}

              ${
                showErrorHighlight &&
                validatePlanTableFields(property, formattedValue)
                  ? 'errorCell'
                  : ''
              }`}
          >
            <span>{formattedValue}</span>
            {!addEnrollmentsForPremiums ? (
              <span
                className={styles.superscriptText}
              >{` / ${formatValuesToCurrencyOrFreeText(
                'enrolments',
                findEnrollmentValue?.value
              )} enrolled`}</span>
            ) : null}
          </span>
        );
      },
    });
  });
};

const addOtherArrayColumns = ({
  property,
  propertyValues,
  columnDataArray,
  benefitType,
  showErrorHighlight,
}: {
  property: string;
  propertyValues: ArrayItemTemplate[];
  columnDataArray: ColumnData[];
  benefitType: BenefitType;
  showErrorHighlight: boolean | undefined;
}): void => {
  const isLifePlans = LIFE_PLANS.includes(benefitType);
  const label = isLifePlans
    ? propertyValues[0]?.name
    : PLAN_TABLE_INITIAL_TITLES[property];

  columnDataArray.push({
    label: label || property,
    isTableBreak: false,
    isWhiteBG: false,
    render: (payload: any) => {
      const selectedValue = payload?.[property]?.[0];
      const llmObj = payload?.llmExtractionInfo?.[property]?.[0] || {};

      const formattedValue = formatValuesToCurrencyOrFreeText(
        property,
        selectedValue?.formattedValue || selectedValue?.value
      );
      return (
        <span
          className={`${
            showErrorHighlight &&
            validatePlanTableFields(property, formattedValue) &&
            validateLifeRateError(
              property,
              formattedValue,
              payload?.insuranceType
            )
              ? 'errorCell'
              : ''
          } ${llmObj?.different ? `llmDifferent` : ''}`}
        >
          {formattedValue}
        </span>
      );
    },
  });
};

const addDocumentsColumns = ({
  columnDataArray,
}: {
  columnDataArray: ColumnData[];
}): void => {
  columnDataArray.push({
    label: PLAN_ATTACHMENTS_LABEL,
    isTableBreak: false,
    isWhiteBG: false,
    render: (payload: any) => {
      const documentsArray = payload?.attachedDocuments;
      return (
        <>
          <Dropdown
            trigger={['click']}
            overlay={dropdownOverlayActionsMenu({
              attachments: documentsArray,
            })}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentElement
                ? triggerNode.parentElement
                : triggerNode
            }
          >
            <p
              className={styles.attachmentLabel}
              onClick={(e) => e.preventDefault()}
            >
              {!documentsArray?.length ? (
                <span>-</span>
              ) : (
                <span className={styles.attachmentText}>
                  {`${documentsArray?.length} ${
                    documentsArray?.length === 1 ? 'Document' : 'Documents'
                  }`}
                </span>
              )}
            </p>
          </Dropdown>
        </>
      );
    },
  });
};

/**
 * Retrieves an array of column data based on the provided parameters.
 *
 * @param {Object} options - The options object.
 * @param {string[]} options.planOrder - The order of the plan properties.
 * @param {any} options.singlePlan - The single plan object.
 * @param {Plan[]} options.transformedPlans - The transformed plans.
 * @param {BenefitType} options.benefitType - The type of benefit.
 * @param {boolean | undefined} options.isNTier - Indicates if it is an N-tier plan.
 * @param {boolean | undefined} options.showErrorHighlight - Indicates if error highlighting is enabled.
 * @param {boolean | undefined} options.addEnrollmentsForPremiums - Indicates if enrollments should be added for premiums.
 * @param {boolean | undefined} options.addFooterRow - Indicates if a footer row should be added.
 * @return {ColumnData[]} - An array of column data.
 */
const getColumnDataArray = ({
  planOrder,
  transformedPlans,
  singlePlan,
  benefitType,
  isNTier,
  addEnrollmentsForPremiums,
  showErrorHighlight,
  nTierCount,
  addFooterRow,
  isAddEditPlan,
}: {
  planOrder: string[];
  transformedPlans: Plan[];
  singlePlan: any;
  benefitType: BenefitType;
  isNTier: boolean | undefined;
  showErrorHighlight: boolean | undefined;
  addEnrollmentsForPremiums: boolean | undefined;
  addFooterRow: boolean | undefined;
  isAddEditPlan: boolean | undefined;
  nTierCount: number | undefined;
}): ColumnData[] => {
  const columnDataArray: ColumnData[] = [];

  planOrder?.forEach((property) => {
    let propertyValues;

    if (singlePlan && property in singlePlan) {
      propertyValues = singlePlan[property];
    }
    // Check if the property is a title property
    if (isTitleProperty(property, benefitType)) {
      addTitleColumns({ property, columnDataArray });
    }

    // Check if the property values is an array
    if (isArray(propertyValues)) {
      // Check if the property is a single column property
      if (isSingleColumnProperty(property)) {
        addSingleColumns({ property, propertyValues, columnDataArray });
      } else if (
        [RX_TIERS_FIELD_PROPERTY, OUT_NETWORK_RX_TIER_PROPERTY].includes(
          property
        )
      ) {
        addRxTiersColumns({ property, transformedPlans, columnDataArray });
      } else if (property === SERVICES_FIELD_PROPERTY) {
        addServicesColumns({
          property: 'displayServices',
          columnDataArray,
          benefitType,
          transformedPlans,
        });
      } else if (
        benefitType === OFFER_BENEFIT_TYPES.VISION.toUpperCase() &&
        property === ADDITIONAL_SERVICES_FIELD_PROPERTY
      ) {
        addAdditionalServicesFrequencyColumns({
          property,
          columnDataArray,
          transformedPlans,
        });
      } else if (property === PREMIUMS_FIELD_PROPERTY) {
        addPremiumsColumns({
          property,
          propertyValues,
          columnDataArray,
          isNTier,
          addEnrollmentsForPremiums,
          showErrorHighlight,
          isAddEditPlan,
          nTierCount,
        });
      } else if (property === PLAN_DOCUMENTS_FIELD_PROPERTY) {
        addDocumentsColumns({ columnDataArray });
      } else {
        addOtherArrayColumns({
          property,
          propertyValues,
          columnDataArray,
          benefitType,
          showErrorHighlight,
        });
      }
    } else if (isPlainObject(propertyValues) || isObjectProperties(property)) {
      const isSTDBenefitLevel =
        property === BENEFIT_FIELD_PROPERTY &&
        benefitType === OFFER_BENEFIT_TYPE_NAVIGATION_LABELS.STD.toUpperCase();
      const isLTDBenefitLevel =
        property === BENEFIT_FIELD_PROPERTY &&
        benefitType === OFFER_BENEFIT_TYPE_NAVIGATION_LABELS.LTD.toUpperCase();

      const label = isSTDBenefitLevel
        ? `STD ${PLAN_TABLE_INITIAL_TITLES[property]}`
        : isLTDBenefitLevel
        ? `LTD ${PLAN_TABLE_INITIAL_TITLES[property]}`
        : PLAN_TABLE_INITIAL_TITLES[property];

      columnDataArray.push({
        label: `${label || property}${addManditoryStatusToPlanLabels(
          property,
          benefitType,
          isAddEditPlan ?? false
        )}`,
        isTableBreak: false,
        isWhiteBG: false,
        render: (payload: any) => {
          const formattedValue = formatValuesToCurrencyOrFreeText(
            property,
            payload[property]?.formattedValue || payload[property]?.value
          );

          const llmObj = payload?.llmExtractionInfo?.[property] || {};
          const isValidate =
            showErrorHighlight &&
            (validatePlanTableFields(property, formattedValue) ||
              validateLifeRateError(
                property,
                formattedValue,
                payload?.insuranceType
              ));
          return (
            <span
              className={`
                ${llmObj?.different ? `llmDifferent` : ''}
                ${isValidate ? 'errorCell' : ''}`}
            >
              {formattedValue}
            </span>
          );
        },
      });
    } else {
      if (property === PLAN_TYPE_FIELD_PROPERTY) {
        columnDataArray.push({
          label: `${
            PLAN_TABLE_INITIAL_TITLES[property] || property
          }${addManditoryStatusToPlanLabels(
            property,
            benefitType,
            isAddEditPlan ?? false
          )}`,
          isTableBreak: false,
          isWhiteBG: false,
          render: (payload: any) => {
            const llmObj = payload?.llmExtractionInfo?.[property] || {};
            const isValidate =
              showErrorHighlight &&
              benefitType === OFFER_BENEFIT_TYPES.MEDICAL.toUpperCase() &&
              validatePlanTableFields(
                property,
                formatEmptyValues(payload?.[property]) || '-'
              );
            return (
              <span
                className={`${llmObj?.different ? `llmDifferent` : ''} ${
                  isValidate ? 'errorCell' : ''
                }`}
              >
                {formatValueForPlanType(payload, property) || '-'}
              </span>
            );
          },
        });
      } else if (property === FROM_CURRENT_PROPERTY) {
        columnDataArray.push({
          label: PLAN_TABLE_INITIAL_TITLES[property] || property,
          isTableBreak: false,
          isWhiteBG: false,
          render: (payload: any) => {
            return (
              <Row>
                <div className={styles.currencyText}>
                  {formatValuesToCurrencyOrFreeText(
                    property,
                    payload[property]
                  )}{' '}
                </div>
                <FromCurrentPercentage
                  percentage={payload?.fromCurrentPercentage || 0}
                  showBrackets={true}
                  decimalPlaces={2}
                  fontSize={11}
                />
              </Row>
            );
          },
        });
      } else {
        columnDataArray.push({
          label: PLAN_TABLE_INITIAL_TITLES[property] || property,
          isTableBreak: false,
          isWhiteBG: false,
          render: (payload: any) => {
            const llmObj = payload?.llmExtractionInfo?.[property] || {};

            return (
              <span className={`${llmObj?.different ? `llmDifferent` : ''}`}>
                {formatValuesToCurrencyOrFreeText(property, payload[property])}
              </span>
            );
          },
        });
      }
    }
  });

  if (addFooterRow) {
    columnDataArray.push({
      label: 'PLAN MAPPING',
      isTableBreak: true,
      isWhiteBG: false,
      isFooterValue: true,
      render: (payload: any) => {
        return '-';
      },
    });

    columnDataArray.push({
      label: 'Map to Plan',
      isTableBreak: false,
      isWhiteBG: false,
      isFooterValue: true,
      render: (payload: any) => {
        return '-';
      },
    });
  }
  return columnDataArray;
};

/**
 * Retrieves the column labels for the plan table.
 *
 * @param {GetPlanTableColumnLabelsParams} params - The parameters for retrieving the column labels.
 * @return {ColumnData[]} - An array of column data for the plan table.
 */
export const getPlanTableColumnLabels = ({
  benefitType,
  plans,
  fundingType,
  isNTier,
  nTierCount,
  isComparePlans,
  isPlanDetails,
  isPreview,
  addEnrollmentsForPremiums,
  showErrorHighlight,
  addFooterRow,
  isPlanDetailSidebar,
  isNoCurrentPlans,
  isAddEditPlan = false,
}: GetPlanTableColumnLabelsParams): ColumnData[] => {
  const planOrder = getPlanPropertyOrder({
    plans,
    benefitType,
    fundingType,
    isComparePlans,
    isPlanDetails,
    isPreview,
    isPlanDetailSidebar,
  });

  const transformedPlan = transformPlansToTablePlans({
    plans,
    benefitType,
    isComparePlans,
    isNTier,
    nTierCount,
    isPlanDetails,
    isPreview,
    isPlanDetailSidebar,
    isNoCurrentPlans,
  });

  let singlePlan = {};
  // if compare plans then get the second plan plan to generate the column data
  // because the first plan is the current plan
  if (transformedPlan?.length > 0) {
    if (isComparePlans) {
      singlePlan = transformedPlan[1];
    } else {
      singlePlan = transformedPlan[0];
    }
  }

  const columnDataArray = getColumnDataArray({
    planOrder,
    singlePlan,
    benefitType,
    isNTier,
    showErrorHighlight,
    addEnrollmentsForPremiums,
    addFooterRow,
    nTierCount,
    transformedPlans: transformedPlan,
    isAddEditPlan,
  });

  return columnDataArray;
};

export const addManditoryStatusToPlanLabels = (
  property: String,
  benefitType: String,
  isAddEditPlans: boolean
) => {
  if (isAddEditPlans) {
    switch (benefitType) {
      case OFFER_BENEFIT_TYPES.MEDICAL.toUpperCase(): {
        switch (property) {
          case PLAN_TYPE_FIELD_PROPERTY:
            return '*';
          default: {
            return '';
          }
        }
      }
      case LTD: {
        switch (property) {
          case VOLUME_FIELD_PROPERTY:
            return '*';
          default: {
            return '';
          }
        }
      }
      case STD: {
        switch (property) {
          case VOLUME_FIELD_PROPERTY:
            return '*';
          default: {
            return '';
          }
        }
      }
      case LIFE: {
        switch (property) {
          case VOLUME_FIELD_PROPERTY:
            return '*';
          default: {
            return '';
          }
        }
      }

      default: {
        return '';
      }
    }
  }

  return '';
};
