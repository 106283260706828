import { Input, Row, Select, Table } from 'antd';
import LabelWithTooltip from 'components/LabelWithTooltip/LabelWithTooltip';
import { PLAN_IDENTIFIER_TOOLTIP_CONTENT } from 'modules/renewals/constants/renewalsConstants';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { ReactComponent as ArrowIcon } from 'assets/images/plan-mapping-arrow.svg';

import styles from './planParametersModal.module.less';

type Props = {
  quoteMapping: any[];
  benefitKind?: string;
  isOpen?: boolean;
  onClose?: () => any;
  offer: any;
};

const PlanParametersModal = (props: Props) => {
  const {
    quoteMapping = [],

    isOpen,
    onClose = () => {},
    offer,
  } = props;

  const teamMembersColumns = [
    {
      title: (
        <div className={styles.inputWrapper}>
          PLAN NAME *{' '}
          <LabelWithTooltip
            content={
              <div className={styles.popOverContent}>
                {PLAN_IDENTIFIER_TOOLTIP_CONTENT}
              </div>
            }
            mandatoryField={false}
            fieldText={''}
            zIndex={9999}
          />
        </div>
      ),
      dataIndex: 'planIdentifier',
      key: 'planIdentifier',
      width: '50%',
      render: (planIdentifier: string, data: any, index: number) => (
        <Row>
          <div className={styles.inputWrapper}>
            <Input
              data-cy="planIdentifier"
              value={planIdentifier}
              onChange={() => {}}
              readOnly={true}
            />
          </div>
        </Row>
      ),
    },
    {
      title: '',
      dataIndex: 'icon',
      key: 'icon',
      width: '5%',
      render: () => (
        <>
          <ArrowIcon />
        </>
      ),
    },
    {
      title: <div className={styles.selectWrapper}>MAP TO CURRENT PLANS *</div>,
      dataIndex: 'currentPlan',
      key: 'currentPlan',
      width: '70%',
      render: (currentPlan: string, data: any, index: number) => (
        <div className={styles.selectViewOnly}>
          <Select
            data-cy="offerName"
            getPopupContainer={(trigger) => trigger.parentNode}
            value={quoteMapping?.[index]?.currentPlan}
            onDropdownVisibleChange={() => false}
            disabled
          ></Select>
        </div>
      ),
    },
  ];

  return (
    <>
      <ConfirmationDialog
        title={'Plan Parameters'}
        className={styles.quoteMapToPlanContainer}
        centered
        confirmBtnFullWidth
        width={684}
        visible={isOpen}
        closable
        closeModal={onClose}
        destroyOnClose
        confirmText="Close"
        onConfirm={onClose}
        isCancelApplicable={false}
      >
        <p className={styles.offerName}>{offer.offerName}</p>
        <div className={styles.outerWrapper}>
          <Table
            key={quoteMapping.length}
            columns={teamMembersColumns}
            dataSource={quoteMapping}
            pagination={false}
          />
        </div>
      </ConfirmationDialog>
    </>
  );
};

export default PlanParametersModal;
