import { combineReducers } from '@reduxjs/toolkit';
import conversationSlice from './conversationSlice';
import planAssistanceModalSlice from './planAssistanceModalSlice';

const AssistantReducer = combineReducers({
  current: conversationSlice,
  planAssistanceModal: planAssistanceModalSlice,
});

export default AssistantReducer;
