import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input, Popover, Select } from 'antd';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import PlanYear from 'model/PlanYear';
import Carrier from 'model/Carrier';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import { useAddNewCarrierState } from 'hooks/useAddNewCarrierState';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import SelectOptions from 'components/SelectOptions/SelectOptions';
import InputForm from 'components/InputForm/InputForm';
import MultiSelectDropdownSmall from 'components/MultiSelectDropdownSmall/MultiSelectDropdownSmall';
import BenefitClassMultiSelect from 'components/BenefitClassMultiSelect/BenefitClassMultiSelect';
import MedicalPlan from 'model/plans/MedicalPlan';
import CostSharing from 'model/plans/CostSharing';
import { getPlanYears } from 'modules/employers/slices/employerSlice';
import {
  getCarriersByBenefitKind,
  getStatesAndTerritories,
} from 'modules/plans/slices/basicPlanInfoSlice';
import {
  ADD_NEW_CARRIER_MODAL_CONSTANTS,
  addNewCarrierModalDescription,
  BenefitCategory,
  benefitGroupsChangedMsg,
  EMPTY_MESSAGE,
  formVerificationMsg,
  IndividualSubTypes,
  maxPlanNameSizeMDV,
  planAlreadyExistsError,
  planYearChangedMsg,
} from 'constants/commonConstants';
import { getPlanYearRangeWithCurrent } from 'util/commonUtil';
import {
  BasicInfoFields,
  benefitCode,
  FUNDING_TYPES_LIST,
  MEDICAL_PLAN_TYPE_OPTIONS,
  PriorToDeductible,
  VALIDATION_NAME_DUPLICATED,
} from 'modules/plans/constants';
import { clearMedicalPlanApiErrors } from 'modules/plans/slices/medicalPlanSlice';
import BenefitCarrier from 'model/BenefitCarrier';
import {
  buildRates,
  formatSFCValue,
  isNullOrUndefined,
} from 'modules/plans/utils';
import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import { RENEWAL_COMMON_WARNING_MESSAGE_BASIC_INFO } from 'constants/benguideCollaborationConstants';
import FundingType from 'modules/plans/enums/FundingType';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import { useLazyGetSFCValuesQuery } from 'modules/plans/slices/planSllice';

import ConfirmationWithThreeButtons from 'components/ConfirmationWithThreeButtons/ConfirmationWithThreeButtons';
import AddNewCarrierModal from 'components/AddNewCarrierModal/AddNewCarrierModal';
import CarrierType from 'modules/carriers/enums/CarrierType';
import SelectAddMoreButton from 'components/buttons/SelectAddMoreButton/SelectAddMoreButton';
import { loginTypes } from 'constants/authConstants';

import styles from './editBasicPlanInfo.module.less';

const { Option } = Select;

type BasicPlanInfoProps = {
  onChange: Function;
  isDBGPlan?: boolean;
  medicalPlan?: MedicalPlan;
  isUPEdit?: boolean;
  dbgPlanYear?: string;
  medicalPlanYear?: string;
  isReviewProp?: boolean;
  isReviewHighlight?: boolean;
  reviewNewPlanYear?: string;
  reviewBenefitClasses?: string[];
};

type MultiSelect = {
  groups: string[];
  states: string[];
};

type FormDataType = {
  name: string;
  planYearId: string;
  benefitCarrier?: BenefitCarrier | null;
  type: any;
  hsaCompatible: boolean;
  planNetworkName?: string;
  groupId?: string;
  startDate: string;
  endDate: string;
  employerId: string | null;
  individualStopLoss?: string;
  individualAdministrationFee?: string;
  aggregatedStopLoss?: string;
  aggregatedAdministrationFee?: string;
  thirdPartyAdministrationFee?: string;
  otherFees?: string;
  hraCompatible?: boolean;
  hraPlanId?: string;
};

const planBasicInfoFormFields = {
  requiredFields: ['name', 'planYear', 'groups', 'benefitCarrier'],
  requiredFieldsWithoutGroups: ['name', 'planYear', 'benefitCarrier'],
};

const networkTooltipContent = () => (
  <div className={styles.popoverContent}>
    <>
      The plan network will be visible to employees under <br /> the Provider
      Search link.
    </>
  </div>
);

const statesTooltipContent = () => (
  <div className={styles.popoverContent}>
    <>
      The states selected will determine which plans an <br /> employee will see
      when using the Plan Recommender.
    </>
  </div>
);

const EditBasicPlanInfo = forwardRef((props: BasicPlanInfoProps, ref) => {
  const {
    onChange: _onChange,
    isDBGPlan,
    isUPEdit,
    dbgPlanYear,
    medicalPlanYear,
    isReviewProp,
    isReviewHighlight,
    reviewNewPlanYear,
    reviewBenefitClasses,
  } = props;

  const basicPlanInfo = useAppSelector((state) => state.plan.planBasicInfo);
  const plan = useAppSelector((state) => state.plan.plans);
  const medicalPlan = props.medicalPlan ? props.medicalPlan : plan.medicalPlan;
  const employer = useAppSelector((state) => state.employer);
  const empInfo = useAppSelector((state) => state.layout?.employer);
  const { brokerId, employerId } = useNavContext();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const { statesAndTerritoriesList, carriersList } = basicPlanInfo;
  const { planYearsList, inProgress } = employer.employer;

  const ALL_STATES = 'All';
  const statesAndTerritoriesWithAll = [ALL_STATES].concat(
    statesAndTerritoriesList
  );

  const [formData, setFormData] = useState<FormDataType>({
    name: '',
    planYearId: '',
    benefitCarrier: null,
    type: '',
    planNetworkName: '',
    groupId: '',
    startDate: '',
    endDate: '',
    employerId: employerId,
    individualStopLoss: '',
    individualAdministrationFee: undefined,
    aggregatedStopLoss: undefined,
    aggregatedAdministrationFee: undefined,
    thirdPartyAdministrationFee: undefined,
    otherFees: undefined,
    hraCompatible: false,
    hsaCompatible: false,
    hraPlanId: undefined,
  });

  const [multiSelect, setMultiSelect] = useState<MultiSelect>({
    groups: [],
    states: statesAndTerritoriesWithAll,
  });
  const [tierChangeWarning, setTierChangeWarning] = useState<boolean>(false);

  const [benefitGroups, setBenefitGroups] = useState<string[]>([]);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [requiredFieldError, setRequiredFieldError] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [showPlanYearWarning, setShowPlanYearWarning] =
    useState<boolean>(false);

  const {
    carrierCreateTypeConfirmation,
    setCarrierCreateTypeConfirmation,
    openCarrierModal,
    setOpenCarrierModal,
    newCarrierId,
    setNewCarrierId,
  } = useAddNewCarrierState();

  const [getUpcomingPlanYears, { data: upcomingPlanYearData }] =
    useLazyGetUpcomingPlanYearsByEmployerQuery();

  const isPlanYearInList = !!planYearsList?.find(
    (planYear: any) => planYear?.id === medicalPlan?.planYearId || dbgPlanYear
  )?.current;

  const hasUpcomingPlanYearWithNullId =
    upcomingPlanYearData?.upcomingPlanYears?.some(
      (obj: any) => obj?.planYearId === null
    );

  const isRenewalStarted = isPlanYearInList && hasUpcomingPlanYearWithNullId;
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;

  // Update SFC config values
  const onChange = (plan: any) => {
    const updatedPlan = {
      ...plan,
      sfcPlanConfigVo: {
        employerId: plan.employerId,
        planYearId: plan.planYearId,
        carrierId: plan.benefitCarrier?.id,
        individualStopLoss: plan.individualStopLoss,
        individualAdministrationFee: plan.individualAdministrationFee,
        aggregatedStopLoss: plan.aggregatedStopLoss,
        aggregatedAdministrationFee: plan.aggregatedAdministrationFee,
        thirdPartyAdministrationFee: plan.thirdPartyAdministrationFee,
        otherFees: plan.otherFees,
        benefitKind: BenefitCategory.MEDICAL.value,
      },
      sfcPlanDataVO: {
        selfFundedClaimsStatus: true,
        aggregatedStopLoss: plan.aggregatedStopLoss,
      },
    };

    _onChange(updatedPlan);
  };

  const error = useAppSelector((state) => state.plan.plans.error);

  const [getSFCValues, { data: sfcData }] = useLazyGetSFCValuesQuery();

  useImperativeHandle(ref, () => ({
    resetForm() {
      setRequiredFieldError(false);
      setShowWarning(false);
      setShowPlanYearWarning(false);
    },
    isValidForm() {
      return getValidationResult();
    },
    setNameDuplicationError() {
      setRequiredFieldError(true);
      setAlertMessage({
        type: 'error',
        message: planAlreadyExistsError,
      });
      form.setFields([
        { name: BasicInfoFields.PLAN_NAME, errors: [EMPTY_MESSAGE] },
      ]);
    },
  }));

  const benefitCarrierId = medicalPlan?.benefitCarrier?.id;

  useEffect(() => {
    if (
      formData.planYearId &&
      benefitCarrierId &&
      medicalPlan?.fundingType === FundingType.SELF_FUNDED &&
      !isReviewProp
    ) {
      getSFCValues({
        benefitKind: BenefitCategory.MEDICAL.value,
        employerId: medicalPlan.employerId,
        planYearId: formData.planYearId,
        carrierId: benefitCarrierId,
        currentTs: new Date().getTime(),
      });
    }
    // eslint-disable-next-line
  }, [
    getSFCValues,
    medicalPlan.employerId,
    formData.planYearId,
    benefitCarrierId,
    medicalPlan?.fundingType,
  ]);

  useEffect(() => {
    if (!isReviewProp) {
      onChange({
        ...medicalPlan,
        individualStopLoss: formatSFCValue(
          sfcData?.individualStopLoss ??
            medicalPlan?.sfcPlanConfigVo?.individualStopLoss
        ),
        aggregatedAdministrationFee: formatSFCValue(
          sfcData?.aggregatedAdministrationFee ??
            medicalPlan?.sfcPlanConfigVo?.aggregatedAdministrationFee
        ),
        individualAdministrationFee: formatSFCValue(
          sfcData?.individualAdministrationFee ??
            medicalPlan?.sfcPlanConfigVo?.individualAdministrationFee
        ),
        thirdPartyAdministrationFee: formatSFCValue(
          sfcData?.thirdPartyAdministrationFee ??
            medicalPlan?.sfcPlanConfigVo?.thirdPartyAdministrationFee
        ),
        otherFees: formatSFCValue(
          sfcData?.otherFees ?? medicalPlan?.sfcPlanConfigVo?.otherFees
        ),
      });
    } else {
      onChange({
        ...medicalPlan,
        individualStopLoss: formatSFCValue(
          medicalPlan?.sfcPlanConfigVo?.individualStopLoss
        ),
        aggregatedAdministrationFee: formatSFCValue(
          medicalPlan?.sfcPlanConfigVo?.aggregatedAdministrationFee
        ),
        individualAdministrationFee: formatSFCValue(
          medicalPlan?.sfcPlanConfigVo?.individualAdministrationFee
        ),
        thirdPartyAdministrationFee: formatSFCValue(
          medicalPlan?.sfcPlanConfigVo?.thirdPartyAdministrationFee
        ),
        otherFees: formatSFCValue(medicalPlan?.sfcPlanConfigVo?.otherFees),
        aggregatedStopLoss: formatSFCValue(
          medicalPlan?.sfcPlanDataVO?.aggregatedStopLoss
        ),
      });
    }
    // Don't expect call hook everytime form change
    // eslint-disable-next-line
  }, [sfcData]);

  const getValidationResult = async () => {
    const isFormValid = await validateBasicInfo(BasicInfoFields.BENEFIT_GROUPS);
    return isFormValid;
  };

  const showTierChangeWarning = !isNullOrUndefined(medicalPlan?.hraPlanId);

  useEffect(() => {
    if (planYearsList && planYearsList.length > 0 && !isUPEdit) {
      if (isDBGPlan) {
        const planYearDBG = find(planYearsList, { id: dbgPlanYear });
        if (planYearDBG) {
          form.setFieldsValue({ planYearId: planYearDBG.id });
          setFormData((prevData: any) => ({
            ...prevData,
            planYearId: planYearDBG.id,
          }));
          setBenefitGroups(planYearDBG.benefitGroups);
          if (planYearDBG.benefitGroups.length === 1) {
            setMultiSelect((multiSelect) => ({
              ...multiSelect,
              groups: planYearDBG.benefitGroups,
            }));
            setFormData((prevData: any) => ({
              ...prevData,
              [BasicInfoFields.BENEFIT_GROUPS]: planYearDBG.benefitGroups,
            }));
            form.setFieldsValue({
              [BasicInfoFields.BENEFIT_GROUPS]: planYearDBG.benefitGroups,
            });
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [planYearsList, form, dbgPlanYear, isDBGPlan, isUPEdit]);

  useEffect(() => {
    setRequiredFieldError(false);

    dispatch(getStatesAndTerritories());
    if (brokerId && employerId) {
      dispatch(
        getCarriersByBenefitKind(
          BenefitCategory.MEDICAL.value,
          brokerId,
          employerId
        )
      );
      if (!isReviewProp) {
        dispatch(getPlanYears(employerId));
        getUpcomingPlanYears({ employerId });
      }
    }
    // eslint-disable-next-line
  }, [dispatch, brokerId, employerId]);

  useEffect(() => {
    if (isDBGPlan && !isEmpty(medicalPlan)) {
      setRequiredFieldError(false);
      const selectedCarrier =
        medicalPlan.benefitCarrier && medicalPlan.benefitCarrier.name;
      form.setFieldsValue({ ...medicalPlan, benefitCarrier: selectedCarrier });
      if (isReviewProp) {
        form.setFieldsValue({
          planYearName: reviewNewPlanYear,
        });
      }
      setMultiSelect({
        states:
          medicalPlan.states?.length >= statesAndTerritoriesList.length
            ? [ALL_STATES]
            : medicalPlan?.states,
        groups: medicalPlan.groups,
      });
      setFormData(medicalPlan);
    }
    // eslint-disable-next-line
  }, [form, isDBGPlan, medicalPlan, statesAndTerritoriesList, isReviewProp]);

  useEffect(() => {
    if (isReviewProp) {
      setBenefitGroups(reviewBenefitClasses || []);
    }
    // eslint-disable-next-line
  }, [isReviewProp]);

  useEffect(() => {
    if (formData.planYearId && !isEmpty(planYearsList)) {
      const planYear = planYearsList.find((t) => t.id === formData.planYearId);
      setBenefitGroups(planYear?.benefitGroups || []);
    }
  }, [formData.planYearId, planYearsList]);

  useEffect(() => {
    if (isEqual(medicalPlan?.groups, multiSelect.groups)) {
      setRequiredFieldError(false);
    }
  }, [medicalPlan, multiSelect.groups]);

  useEffect(() => {
    if (error?.data?.code === VALIDATION_NAME_DUPLICATED) {
      form.setFields([
        { name: BasicInfoFields.PLAN_NAME, errors: [EMPTY_MESSAGE] },
      ]);
      setAlertMessage({ message: planAlreadyExistsError, type: 'error' });
      setRequiredFieldError(true);
      dispatch(clearMedicalPlanApiErrors());
    }
  }, [dispatch, error, form]);

  const validateBasicInfo = async (changedField?: string) => {
    // need to pass groups as changeFields in the method param when checking entire form validations
    try {
      let requiredFields = planBasicInfoFormFields.requiredFields;
      if (changedField !== BasicInfoFields.BENEFIT_GROUPS) {
        requiredFields = planBasicInfoFormFields.requiredFieldsWithoutGroups;
      }
      await form.validateFields(requiredFields);
      return true;
    } catch (errorInfo: any) {
      let errors = errorInfo.errorFields;
      if (changedField !== BasicInfoFields.BENEFIT_GROUPS && !isEmpty(errors)) {
        // check if the changed field is groups. if not remove groups from error object because plan year change can cause
        // empty groups and, we don't want to show empty field message in that scenario
        // this is happening when SFC API call is triggering onChange method of the form
        errors = errors.filter(
          (errorField: { name: string[] }) =>
            errorField.name[0] !== BasicInfoFields.BENEFIT_GROUPS
        );
      }
      return errors.length === 0;
    }
  };

  const onPlanYearSelect = async (value: any) => {
    const planYear = planYearsList.find((t) => t.id === value);
    if (planYear) {
      form.setFieldsValue({ planYearId: planYear.id });
      setFormData({ ...formData, planYearId: planYear.id });
      const benefitClasses = planYear.benefitGroups;
      setBenefitGroups(benefitClasses);
      if (benefitClasses.length === 1) {
        setMultiSelect((multiSelect) => ({
          ...multiSelect,
          groups: benefitClasses,
        }));
        form.setFieldsValue({
          planYear: planYear.id,
          [BasicInfoFields.BENEFIT_GROUPS]: benefitClasses,
        });
        const rates = buildRates(benefitClasses, cloneDeep(medicalPlan));
        onChange({
          ...medicalPlan,
          planYearId: planYear.id,
          startDate: planYear.startDate,
          endDate: planYear.endDate,
          [BasicInfoFields.BENEFIT_GROUPS]: benefitClasses,
          rates,
        });
      } else {
        setMultiSelect({
          groups: [],
          states: multiSelect.states,
        });
        const rates = buildRates(benefitClasses, cloneDeep(medicalPlan));
        onChange({
          ...medicalPlan,
          planYearId: planYear.id,
          startDate: planYear.startDate,
          endDate: planYear.endDate,
          [BasicInfoFields.BENEFIT_GROUPS]: [],
          rates,
        });
      }
    }

    if (isUPEdit && !isEqual(medicalPlanYear, planYear?.id)) {
      setShowWarning(false);
      setShowPlanYearWarning(true);
      setAlertMessage({
        type: 'warning',
        message: planYearChangedMsg,
      });
    }
    if (
      isUPEdit &&
      !isEqual(medicalPlanYear, planYear?.id) &&
      !isEqual(medicalPlan?.groups, planYear?.benefitGroups)
    ) {
      setShowWarning(true);
      setShowPlanYearWarning(true);
      setAlertMessage({
        type: 'warning',
        message: benefitGroupsChangedMsg,
      });
    }
  };

  const onInputChange = async (changedValues: any, allValues: any) => {
    if (
      Object.keys(changedValues)[0] === BasicInfoFields.BENEFIT_CARRIER &&
      !isEmpty(carriersList) &&
      !isEmpty(Object.values(changedValues)[0])
    ) {
      const carrierObj = carriersList.find(
        (carrier: Carrier) => carrier.id === Object.values(changedValues)[0]
      );
      changedValues[BasicInfoFields.BENEFIT_CARRIER] = carrierObj;
    }

    if (allValues.planYearId) {
      const selectedPlanYear: any = planYearsList.find(
        (year: PlanYear) => year.id === allValues.planYearId
      );
      changedValues[BasicInfoFields.PLAN_YEAR] = allValues.planYearId;
      changedValues[BasicInfoFields.START_DATE] = selectedPlanYear?.startDate;
      changedValues[BasicInfoFields.END_DATE] = selectedPlanYear?.endDate;
      if (selectedPlanYear?.benefitGroups.length === 1 && !allValues.groups) {
        changedValues[BasicInfoFields.BENEFIT_GROUPS] = multiSelect.groups;
      }
    }
    if (
      Object.keys(changedValues)[0] === BasicInfoFields.PLAN_YEAR &&
      !isEmpty(planYearsList) &&
      !isEmpty(Object.values(changedValues)[0])
    ) {
      const selectedPlanYear: any = planYearsList.find(
        (year: PlanYear) => year.id === Object.values(changedValues)[0]
      );
      changedValues[BasicInfoFields.START_DATE] = selectedPlanYear?.startDate;
      changedValues[BasicInfoFields.END_DATE] = selectedPlanYear?.endDate;

      setBenefitGroups(selectedPlanYear.benefitGroups);
      if (selectedPlanYear.benefitGroups.length === 1) {
        setMultiSelect({
          ...multiSelect,
          groups: selectedPlanYear.benefitGroups,
        });
        form.setFieldsValue({
          [BasicInfoFields.BENEFIT_GROUPS]:
            selectedPlanYear.benefitGroups.join(', '),
        });
        changedValues[BasicInfoFields.BENEFIT_GROUPS] =
          selectedPlanYear.benefitGroups;
      } else {
        changedValues[BasicInfoFields.BENEFIT_GROUPS] = [];
        setMultiSelect({ ...multiSelect, groups: [] });
        form.setFieldsValue({ [BasicInfoFields.BENEFIT_GROUPS]: '' });
      }
    }
    if (multiSelect.states?.includes(ALL_STATES)) {
      changedValues.states = statesAndTerritoriesList;
    }

    const updatedCustomServices = cloneDeep(medicalPlan.customServices);
    const updatedRXCosts = cloneDeep(medicalPlan.rxCosts);
    const updatedMailOrderRXCosts = cloneDeep(medicalPlan.mailOrderRxCosts);

    if (Object.keys(changedValues)[0] === BasicInfoFields.HSA_COMPATIBLE) {
      updatedCustomServices?.forEach((service: any) => {
        if (
          service.benefitCode.code !== benefitCode.MEDICAL_PREVENTIVE_CARE.code
        ) {
          const inNetwork = service.serviceValue.inNetwork;
          const outOfNetwork = service.serviceValue.outOfNetwork;

          inNetwork.copayPriorToDeductible = Object.values(changedValues)[0]
            ? PriorToDeductible.NO
            : !isEmpty(medicalPlan.id)
            ? PriorToDeductible.YES
            : '';
          outOfNetwork.copayPriorToDeductible = Object.values(changedValues)[0]
            ? PriorToDeductible.NO
            : !isEmpty(medicalPlan.id)
            ? PriorToDeductible.YES
            : '';

          service.serviceValue.inNetwork = inNetwork;
          service.serviceValue.outOfNetwork = outOfNetwork;
        }
        return service;
      });

      Object.keys(updatedRXCosts?.inNetwork).forEach((key) => {
        (updatedRXCosts?.inNetwork[key] as CostSharing).copayPriorToDeductible =
          Object.values(changedValues)[0]
            ? PriorToDeductible.NO
            : !isEmpty(medicalPlan.id)
            ? PriorToDeductible.YES
            : '';
      });

      Object.keys(updatedRXCosts?.outOfNetwork).forEach((key) => {
        (
          updatedRXCosts?.outOfNetwork[key] as CostSharing
        ).copayPriorToDeductible = Object.values(changedValues)[0]
          ? PriorToDeductible.NO
          : !isEmpty(medicalPlan.id)
          ? PriorToDeductible.YES
          : '';
      });

      Object.keys(updatedMailOrderRXCosts?.inNetwork).forEach((key) => {
        (
          updatedMailOrderRXCosts?.inNetwork[key] as CostSharing
        ).copayPriorToDeductible = Object.values(changedValues)[0]
          ? PriorToDeductible.NO
          : !isEmpty(medicalPlan.id)
          ? PriorToDeductible.YES
          : '';
      });

      Object.keys(updatedMailOrderRXCosts?.outOfNetwork).forEach((key) => {
        (
          updatedMailOrderRXCosts?.outOfNetwork[key] as CostSharing
        ).copayPriorToDeductible = Object.values(changedValues)[0]
          ? PriorToDeductible.NO
          : !isEmpty(medicalPlan.id)
          ? PriorToDeductible.YES
          : '';
      });
    }

    const changedItems = [
      BasicInfoFields.PLAN_NAME,
      BasicInfoFields.HSA_COMPATIBLE,
      BasicInfoFields.PLAN_NETWORK_NAME,
      BasicInfoFields.BENEFIT_CARRIER,
      BasicInfoFields.PLAN_TYPE,
      BasicInfoFields.FUNDING_TYPE,
      BasicInfoFields.INDIVIDUAL_STOP_LOSS,
      BasicInfoFields.INDIVIDUAL_ADMINISTRATION_FEE,
      BasicInfoFields.AGGREGATE_STOP_LOSS,
      BasicInfoFields.AGGREGATED_ADMINISTRATION_FEE,
      BasicInfoFields.THIRD_PARTY_ADMINISTRATION_FEE,
      BasicInfoFields.OTHER_FEES,
      BasicInfoFields.GROUP_ID,
      BasicInfoFields.HRA_COMPATIBLE,
    ];
    if (
      changedValues.states &&
      changedValues.states.length > 0 &&
      changedItems.some((item) => changedValues.hasOwnProperty(item))
    ) {
      delete changedValues.states;
    }
    if (
      (!changedValues.groups || typeof changedValues.groups !== 'string') &&
      !changedValues.states
    ) {
      const isFormValid = await validateBasicInfo(
        Object.keys(changedValues)[0]
      );
      if (isFormValid && isDBGPlan) {
        setRequiredFieldError(false);
        setFormData({ ...formData, ...changedValues });
        const updatedObject = {
          ...medicalPlan,
          ...formData,
          ...changedValues,
          customServices: updatedCustomServices,
          rxCosts: updatedRXCosts,
          mailOrderRxCosts: updatedMailOrderRXCosts,
          name: allValues.name,
        };
        onChange({
          ...updatedObject,
          carrier: updatedObject.carrier ? updatedObject.carrier : null,
          type: updatedObject.type ? updatedObject.type : null,
        });
      }
    }
  };

  const onCheckboxSelection = async (event: any) => {
    const { name, value, checked } = event.target;
    let newCheckboxValues: string[];
    let rates = medicalPlan.rates;
    if (name === BasicInfoFields.BENEFIT_GROUPS) {
      newCheckboxValues = getCheckboxDataForGroups(
        multiSelect.groups,
        value,
        checked
      );
      form.setFieldsValue({
        [BasicInfoFields.BENEFIT_GROUPS]: newCheckboxValues.join(', '),
      });
      if (isReviewProp) {
        rates = buildRates(newCheckboxValues, cloneDeep(medicalPlan));
      } else {
        if (isDBGPlan && !isUPEdit) {
          rates = buildRates(newCheckboxValues, cloneDeep(medicalPlan));
        }
      }
    } else {
      newCheckboxValues = getCheckboxDataForStates(
        multiSelect.states.includes(ALL_STATES)
          ? statesAndTerritoriesWithAll
          : multiSelect.states,
        value,
        checked
      );

      newCheckboxValues = newCheckboxValues
        .filter((value) => value === ALL_STATES)
        .concat(
          newCheckboxValues.filter((value) => value !== ALL_STATES).sort()
        );
    }
    setMultiSelect((prevState) => ({
      ...prevState,
      [name]: newCheckboxValues,
    }));

    let actualCheckboxValues = newCheckboxValues;
    if (
      newCheckboxValues.includes(ALL_STATES) &&
      name === BasicInfoFields.STATES_TERRITORIES
    ) {
      actualCheckboxValues = statesAndTerritoriesList;
    }
    const isFormValid = await validateBasicInfo(name);
    if (isFormValid) {
      if (name === BasicInfoFields.BENEFIT_GROUPS && newCheckboxValues) {
        if (
          isUPEdit &&
          !isEqual(
            medicalPlanYear,
            planYearsList.find((t) => t.id === value)?.id
          )
        ) {
          setShowPlanYearWarning(true);
        }
        setShowWarning(true);
        setAlertMessage({
          type: 'warning',
          message: benefitGroupsChangedMsg,
        });
      }
      setRequiredFieldError(false);
      setFormData({ ...formData, [name]: actualCheckboxValues });
      onChange({
        ...medicalPlan,
        ...formData,
        [name]: actualCheckboxValues,
        rates: rates,
      });
    } else {
      setRequiredFieldError(true);
      setAlertMessage({
        type: 'error',
        message: formVerificationMsg,
      });
    }
  };

  const getCheckboxDataForGroups = (
    checkboxValues: string[],
    value: string,
    checked: boolean
  ): string[] => {
    let newCheckboxValues = cloneDeep(checkboxValues);
    if (checked) {
      newCheckboxValues = [...checkboxValues, value];
    } else {
      const index = checkboxValues.findIndex((element) => element === value);
      if (index > -1) {
        newCheckboxValues.splice(index, 1);
      }
    }
    return newCheckboxValues;
  };

  const getCheckboxDataForStates = (
    checkboxValues: string[],
    value: string,
    checked: boolean
  ): string[] => {
    let newCheckboxValues = cloneDeep(checkboxValues);

    if (checked) {
      newCheckboxValues = [...checkboxValues, value];
      if (checkboxValues.length === statesAndTerritoriesList.length - 1) {
        newCheckboxValues.unshift(ALL_STATES);
      }
      if (value === ALL_STATES) {
        newCheckboxValues = statesAndTerritoriesWithAll;
      }
    } else {
      const index = checkboxValues.findIndex((element) => element === value);

      if (checkboxValues.includes(ALL_STATES)) {
        const indexOfAll = checkboxValues.indexOf(ALL_STATES);
        newCheckboxValues.splice(indexOfAll, 1);
        newCheckboxValues.splice(index - 1, 1);
      } else {
        newCheckboxValues.splice(index, 1);
      }

      if (value === ALL_STATES) {
        newCheckboxValues = [];
      }
    }
    return newCheckboxValues;
  };

  const getDefaultStates = () => {
    let selectedItemValues: string;
    let showAll = false;
    if (
      multiSelect.states?.length === 1 &&
      multiSelect.states?.includes(ALL_STATES)
    ) {
      selectedItemValues = statesAndTerritoriesWithAll.join(', ');
      showAll = true;
    } else {
      selectedItemValues = multiSelect.states?.join(', ');
      showAll = multiSelect.states?.length > statesAndTerritoriesList.length;
    }
    return { selectedItemValues, showAll };
  };
  const { selectedItemValues, showAll } = getDefaultStates();

  const trimInput = (event: any) => {
    const { value } = event.target;
    form.setFieldsValue({ [BasicInfoFields.PLAN_NAME]: value.trim() });
  };

  useEffect(() => {
    if (newCarrierId) {
      const carrierObj = carriersList.find(
        (carrier: Carrier) => carrier.id === newCarrierId
      );
      setFormData((prev) => ({ ...prev, benefitCarrier: carrierObj }));
      form.setFieldsValue({
        [BasicInfoFields.BENEFIT_CARRIER]: carrierObj?.id,
      });
      onChange({
        ...medicalPlan,
        [BasicInfoFields.BENEFIT_CARRIER]: carrierObj,
      });
    }
    // don't expect call hook every time form,onChange,medicalPlan
    // eslint-disable-next-line
  }, [newCarrierId, carriersList]);

  const handleSetCarrierToForm = (carrier: {
    label: string;
    value: string;
  }): void => {
    const { value } = carrier;
    setNewCarrierId(value);
  };
  return (
    <div className={styles.editBasicInfoFormContainer}>
      {isRenewalStarted && (
        <FixedAlertMessage
          type={'warning'}
          message={RENEWAL_COMMON_WARNING_MESSAGE_BASIC_INFO}
        />
      )}
      {tierChangeWarning && !!showTierChangeWarning && (
        <AlertMessage
          type="warning"
          message={
            <div>
              Removing HRA eligibility will unlink the associated HRA plan.
              <br />
              <a
                className={`text-form-label ${styles.planRedirect}`}
                target="_blank"
                rel="noreferrer"
                href={`/brokers/${brokerId}/employers/${employerId}/tax-advantaged-accts/${medicalPlan?.hraPlanId}/overview`}
              >
                {' '}
                View Plan
              </a>
            </div>
          }
          closeAlert={() => {
            setTierChangeWarning(false);
          }}
          wrapperClassName={styles.alertMessage}
        />
      )}
      {(requiredFieldError ||
        ((showWarning || showPlanYearWarning) && !isReviewProp)) &&
        isDBGPlan && (
          <AlertMessage
            type={alertMessage.type}
            message={alertMessage.message}
            closeAlert={() => {
              setRequiredFieldError(false);
              setShowWarning(false);
              setShowPlanYearWarning(false);
            }}
            wrapperClassName={styles.alertMessage}
          />
        )}
      <InputForm form={form} onValuesChange={onInputChange}>
        <Form.Item
          name={BasicInfoFields.PLAN_NAME}
          label="Plan Name"
          labelCol={{ span: 24 }}
          className={`${styles.planName} ${
            isReviewHighlight ? styles.highlightFieldPlanName : ''
          }`}
          rules={[
            {
              required: true,
              message: EMPTY_MESSAGE,
              whitespace: true,
            },
          ]}
        >
          <Input
            maxLength={maxPlanNameSizeMDV}
            showCount
            onBlur={trimInput}
            title={formData.name}
          />
        </Form.Item>
        {isReviewProp ? (
          <Form.Item
            name={'planYearName'}
            label="Effective Dates"
            rules={[{ required: true, message: EMPTY_MESSAGE }]}
          >
            <Input
              defaultValue={reviewNewPlanYear}
              disabled={isReviewProp}
              className={styles.planYearInput}
            />
          </Form.Item>
        ) : (
          <Form.Item
            name={BasicInfoFields.PLAN_YEAR}
            label="Effective Dates"
            rules={[{ required: true, message: EMPTY_MESSAGE }]}
            className={
              isUPEdit
                ? showPlanYearWarning
                  ? styles.planYearWrapperWarning
                  : styles.filterWrapper
                : styles.planYear
            }
          >
            <SelectOptions
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              disabled={!isUPEdit}
              onSelect={onPlanYearSelect}
              loading={inProgress}
            >
              {planYearsList &&
                planYearsList
                  .filter(
                    (planYear) =>
                      planYear.id === medicalPlan?.planYearId ||
                      !planYear.previous
                  )
                  .map((planYear: PlanYear, index: number) => (
                    <Option value={planYear.id} key={index} label={planYear.id}>
                      {`${planYear.name} ${getPlanYearRangeWithCurrent(
                        planYear
                      )}`}
                    </Option>
                  ))}
            </SelectOptions>
          </Form.Item>
        )}
        <Form.Item
          name={BasicInfoFields.BENEFIT_CARRIER}
          label="Carrier"
          rules={[
            {
              required: true,
              message: 'Please select a carrier',
            },
          ]}
          className={`${styles.carrierStyles} ${
            isReviewHighlight && styles.highlightField
          }`}
        >
          <SelectOptions
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            loading={inProgress}
            showSearch
            filterOption={(input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase().trim())
            }
            wrapperClass={isReviewHighlight ? 'highlightField' : undefined}
            dropdownRender={(menu) => (
              <>
                {menu}
                {!isReviewProp && (
                  <SelectAddMoreButton
                    onClick={() => {
                      !isErAdmin &&
                      appBootInfo?.individualSubType !==
                        IndividualSubTypes.BROKER_USER
                        ? setCarrierCreateTypeConfirmation({
                            show: true,
                            carrierType: '',
                          })
                        : setOpenCarrierModal(true);
                    }}
                    label="Add New Carrier"
                  />
                )}
              </>
            )}
            options={carriersList?.map((carrier: Carrier) => ({
              label: carrier.name,
              value: carrier.id,
            }))}
          />
        </Form.Item>
        <Form.Item
          name={BasicInfoFields.BENEFIT_GROUPS}
          label="Benefit Classes"
          className={
            showWarning && !isReviewProp
              ? styles.benefitClassWrapperWarning
              : styles.benefitClassWrapper
          }
          rules={[
            {
              required: true,
              message: EMPTY_MESSAGE,
            },
          ]}
        >
          <Input hidden value={multiSelect?.groups?.join(', ')} />
          <Form.Item name={BasicInfoFields.BENEFIT_GROUPS}>
            <BenefitClassMultiSelect
              options={benefitGroups}
              onChange={onCheckboxSelection}
              name="groups"
              disabled={isEmpty(benefitGroups)}
              selectedItemValues={multiSelect.groups}
              showTooltip
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name={BasicInfoFields.GROUP_ID}
          label="Group ID / Policy #"
          className={styles.groupId}
        >
          <Input title={formData.groupId} />
        </Form.Item>

        <Form.Item
          name={BasicInfoFields.PLAN_TYPE}
          label="Plan Type"
          className={`${isReviewHighlight && styles.highlightField}`}
        >
          <SelectOptions
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            options={MEDICAL_PLAN_TYPE_OPTIONS}
            allowClear
            wrapperClass={isReviewHighlight ? 'highlightField' : undefined}
          />
        </Form.Item>

        <Form.Item
          name={BasicInfoFields.PLAN_NETWORK_NAME}
          label={
            <>
              Plan Network
              <Popover
                content={networkTooltipContent}
                placement="left"
                overlayClassName={styles.leftPopover}
              >
                <QuestionCircleOutlined className={styles.tooltipIcon} />
              </Popover>
            </>
          }
          className={`${styles.planNetwork} ${
            isReviewHighlight && styles.highlightField
          }`}
        >
          <Input title={formData.planNetworkName} />
        </Form.Item>

        <div className={styles.checkboxWrapper}>
          <Form.Item
            name={BasicInfoFields.HSA_COMPATIBLE}
            label="HSA Eligible"
            valuePropName="checked"
            className={styles.hsaEligibleWrapper}
          >
            <Checkbox checked={formData.hsaCompatible}>Yes</Checkbox>
          </Form.Item>

          <Form.Item
            name={BasicInfoFields.HRA_COMPATIBLE}
            label="HRA Eligible"
            valuePropName="checked"
            className={styles.hsaEligibleWrapper}
          >
            <Checkbox
              onChange={(e) => {
                if (plan?.medicalPlan?.hraCompatible !== e.target?.checked) {
                  setTierChangeWarning(true);
                } else {
                  setTierChangeWarning(false);
                }
              }}
              checked={formData.hraCompatible}
            >
              Yes
            </Checkbox>
          </Form.Item>
        </div>

        <Form.Item
          name={BasicInfoFields.STATES_TERRITORIES}
          label={
            <>
              States & Territories
              <Popover
                content={statesTooltipContent}
                placement="left"
                overlayClassName={styles.leftPopover}
              >
                <QuestionCircleOutlined className={styles.tooltipIcon} />
              </Popover>
            </>
          }
          className={styles.stateAndTerritories}
        >
          <MultiSelectDropdownSmall
            options={statesAndTerritoriesWithAll}
            increased={true}
            onChange={onCheckboxSelection}
            name="states"
            showAll={showAll}
            selectedItemValues={selectedItemValues}
          />
        </Form.Item>

        <Form.Item
          className={styles.fundingType}
          name={BasicInfoFields.FUNDING_TYPE}
          label="Funding Type"
          rules={[
            {
              required: true,
              message: 'Please select a funding type',
              validateTrigger: ['onSubmit'],
            },
          ]}
        >
          <SelectOptions
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            options={FUNDING_TYPES_LIST}
            wrapperClass={isReviewHighlight ? 'highlightField' : ''}
          />
        </Form.Item>

        {medicalPlan?.fundingType === FundingType.SELF_FUNDED && (
          <>
            <div className={styles.selfFundedClaims}>
              <div className={styles.sfcRow}>
                <Form.Item
                  name={BasicInfoFields.INDIVIDUAL_STOP_LOSS}
                  label={
                    <>
                      Individual Stop Loss<span>1</span>
                    </>
                  }
                  className={styles.sfcField}
                >
                  <NumberFormatInput
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    customClass={isReviewHighlight ? 'highlightField' : ''}
                  />
                </Form.Item>
                <Form.Item
                  name={BasicInfoFields.INDIVIDUAL_ADMINISTRATION_FEE}
                  label={
                    <>
                      Individual Stop Loss Fee<span>1</span>
                    </>
                  }
                  className={`${styles.sfcField} ${styles.individualStopLossFee}`}
                >
                  <NumberFormatInput
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    customClass={isReviewHighlight ? 'highlightField' : ''}
                  />
                </Form.Item>
              </div>
              <div className={styles.sfcRow}>
                <Form.Item
                  name={BasicInfoFields.AGGREGATE_STOP_LOSS}
                  label="Aggregate Stop Loss"
                >
                  <NumberFormatInput
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    customClass={isReviewHighlight ? 'highlightField' : ''}
                  />
                </Form.Item>

                <Form.Item
                  name={BasicInfoFields.AGGREGATED_ADMINISTRATION_FEE}
                  label={
                    <>
                      Aggregate Stop Loss Fee<span>1</span>
                    </>
                  }
                  className={`${styles.sfcField} ${styles.aggregateStopLossFee}`}
                >
                  <NumberFormatInput
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    customClass={isReviewHighlight ? 'highlightField' : ''}
                  />
                </Form.Item>
              </div>
              <div className={styles.sfcRow}>
                <Form.Item
                  name={BasicInfoFields.THIRD_PARTY_ADMINISTRATION_FEE}
                  label={
                    <>
                      3rd Party Admin Fee<span>1</span>
                    </>
                  }
                  className={`${styles.sfcField} ${styles.aggregateStopLossFee}`}
                >
                  <NumberFormatInput
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    customClass={isReviewHighlight ? 'highlightField' : ''}
                  />
                </Form.Item>

                <Form.Item
                  name={BasicInfoFields.OTHER_FEES}
                  label={
                    <>
                      Other Fees<span>1</span>
                    </>
                  }
                  className={`${styles.sfcField} ${styles.otherFees}`}
                >
                  <NumberFormatInput
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    customClass={isReviewHighlight ? 'highlightField' : ''}
                  />
                </Form.Item>
              </div>
            </div>
            <div className={styles.sfcText}>
              <span>1</span>
              This data is shared by all self-funded plans in this plan year
              with the same carrier.
            </div>
          </>
        )}
      </InputForm>
      <ConfirmationWithThreeButtons
        visible={carrierCreateTypeConfirmation.show}
        width={464}
        centered
        title={ADD_NEW_CARRIER_MODAL_CONSTANTS.title}
        firstButtonText={ADD_NEW_CARRIER_MODAL_CONSTANTS.firstButtonLabel}
        firstButtonAction={() => {
          setCarrierCreateTypeConfirmation({
            show: false,
            carrierType: CarrierType.BROKER,
          });
          setOpenCarrierModal(true);
        }}
        secondButtonText={ADD_NEW_CARRIER_MODAL_CONSTANTS.secondButtonLabel}
        secondButtonAction={() => {
          setCarrierCreateTypeConfirmation({
            show: false,
            carrierType: CarrierType.EMPLOYER,
          });
          setOpenCarrierModal(true);
        }}
        onCancel={() => {
          setCarrierCreateTypeConfirmation({
            show: false,
            carrierType: '',
          });
        }}
      >
        {addNewCarrierModalDescription(empInfo?.name!)}
      </ConfirmationWithThreeButtons>
      <AddNewCarrierModal
        isOpen={openCarrierModal}
        setIsOpen={setOpenCarrierModal}
        selectedCarrierType={
          isErAdmin ||
          appBootInfo?.individualSubType === IndividualSubTypes.BROKER_USER
            ? CarrierType.EMPLOYER
            : carrierCreateTypeConfirmation.carrierType
        }
        benefitType={BenefitCategory.MEDICAL.value}
        setCarrierToForm={handleSetCarrierToForm}
        brokerId={brokerId!}
        employerId={employerId!}
      />
    </div>
  );
});

EditBasicPlanInfo.displayName = 'EditBasicPlanInfo';

export default EditBasicPlanInfo;
