export const EXPIRE_TIME = 'expire_time'; // expire key for local storage.
export const LOGGED_IN_USER_EMAIL = 'logged_user'; // expire key for local storage.

export const MINIMUM_PASSWORD_LENGTH = 8;

export const PASSWORD_MISMATCH_ERROR = 'Password does not match.';
export const PASSWORD_MIN_CHAR_ERROR =
  'Password must be a minimum of 8 characters';

export const UNIFIED_PORTAL = 'UNIFIED_PORTAL';

export const INVALID_PWD_COMPROMISED = 'invalid.password.compromised';
const INVALID_TOKEN = 'invalid.token';
const EXPIRED_TOKEN = 'token.expired';
const INACTIVE_LOGIN = 'inactive.login';

export const SET_PASSWORD_ERROR_MESSAGES: { [code: string]: string } = {
  [INVALID_PWD_COMPROMISED]:
    'This password has been found on a database of compromised password, please use a more secure one.',
  [INVALID_TOKEN]:
    'The token from your email link is no longer valid or expired',
  [EXPIRED_TOKEN]:
    'The token from your email link is no longer valid or expired',
  [INACTIVE_LOGIN]:
    'Your account has been terminated. Please contact Lumity support with any questions',
};

export const CREATE_PASSWORD_ERROR_MSG =
  'Creating new password failed. Please correct all highlighted errors and try again.';

export const PASSWORD_VALIDATION_ERROR_MSG =
  'Please choose a different password with 8 or more characters, Upper & lower case letters, number and a special character.';

export const REGISTER_FAILED_ERROR_MSG =
  'Registration failed. Please correct all highlighted errors and try again.';

export const RESET_ALERT_MESSAGE =
  'We have received your request. If a valid account exists you will receive password reset instructions at that e-mail address. Contact support if you need any assistance.';
const INVALID_CREDENTIALS = 'invalid.credentials';
const NO_PERMISSIONS_FROM_LEGACY = 'no.permissions.from.legacy';
const NO_PERMISSIONS = 'no.permissions';
const RESTRICTED_LOGIN = 'restricted.login';

export const LOGIN_ERROR_MESSAGES: { [code: string]: string } = {
  [INVALID_CREDENTIALS]: `We couldn't find an admin account matching this email address and password. If you have not yet registered an account with us, please click on the 'Register with invite code' link to create a new account.`,
  [NO_PERMISSIONS_FROM_LEGACY]: `We couldn't find an admin account matching this email address and password. If you have not yet registered an account with us, please click on the 'Register with invite code' link to create a new account.`,
  [NO_PERMISSIONS]: `We couldn't find an admin account matching this email address and password. If you have not yet registered an account with us, please click on the 'Register with invite code' link to create a new account.`,
  EMPTY_EMAIL: `Please enter a valid email to proceed.`,
  [RESTRICTED_LOGIN]: `We couldn't find an account matching this email address.`,
};

export const REGISTER_SUCCESS_MESSAGE =
  'You’ve successfully registered. Please log in below to access your account.';
export const RESET_PWD_SUCCESS_MESSAGE =
  'You’ve successfully set a new password. Please log in below to access your account.';

export const LOGIN_TYPE_PWD = 'PWD';
export const LOGIN_TYPE_SSO = 'SSO';

export const REGISTER_WITH_CODE_ERROR_MESSAGE =
  'We couldn’t find a matching invite code or email. Please try again.';
