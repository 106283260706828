import React, { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Col, Modal, Row, Spin } from 'antd';
import isNull from 'lodash/isNull';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { usePermitIf } from 'hooks/usePermitIf';
import { useNavContext } from 'hooks/useNavContext';
import { ReactComponent as PencilIcon } from 'assets/images/icon-pencil.svg';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import Contact from 'components/Contact/Contact';
import EditBrokerTeamModal from 'modules/employers/components/EditBrokerTeamModal/EditBrokerTeamModal';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import QuickLinks from 'modules/benefitGuide/components/QuickLinks/QuickLinks';
import { employerBackButtonPermittedTypes } from 'constants/permittedConstants';
import { loginTypes } from 'constants/authConstants';
import { LUMITY_ORGANIZATION_ID } from 'modules/employers/constants/employerConstants';
import { buildFullName } from 'util/stringUtil';
import {
  getAccessibleTeamInProgress,
  getAccessibleTeamMembers,
} from 'modules/employers/slices/employerSlice';
import { getPlanYearBenefitGuideInfo } from 'modules/employers/slices/benguideSlice';
import { changedBenguideModalState } from 'modules/benefitGuide/slices/benguideSlice';
import BenguideList from 'modules/benefitGuide/components/BenguideList/BenguideList';
import {
  useGetDashboardQuery,
  useGetEmpDashboardIssuesQuery,
} from 'modules/employers/slices/employerDashboardSlice';
import DashboardBillingClaimChart from 'modules/employers/components/DashboardBillingClaimChart/DashboardBillingClaimChart';
import ClaimsChartDashboard from 'modules/claims/components/ClaimsChartDashboard/ClaimsChartDashboard';
import { useGetDashboardClaimsQuery } from 'modules/employers/slices/claimsDashboardSlice';

import IssueMgtDashboardPane from 'modules/brokers/components/IssueMgtDashboardPane/IssueMgtDashboardPane';
import NoClaimsViewDashboard from 'modules/claims/components/NoClaimsViewDashboard/NoClaimsViewDashboard';
import CustomerSpinner from 'components/Spinner/CustomSpinner';
import EditCarrierContactsModal from 'modules/employers/components/EditCarrierContactsModal/EditCarrierContactsModal';
import { useLazyGetCarrierContactsByEmployerIdQuery } from 'modules/renewals/slices/carrierContactsSlice';
import CarrierContact from 'components/CarrierContact/CarrierContact';
import { mapToSelectedCarrierContact } from 'modules/employers/utils/policyNumberAndCarrierContact';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import PolicyNumbersAndCarrierContactModal from 'modules/employers/components/PolicyNumbersAndCarrierContactModal/PolicyNumbersAndCarrierContactModal';
import { Feature, useGetNavigationsQuery } from 'api/navigationSlice';
import { FEATURE_KEYS } from 'constants/commonConstants';
import Terms from 'modules/auth/components/Terms/Terms';
import { useIsAuthorizedUserRole } from 'hooks/useIsAuthorizedUserRole';
import styles from './erDashboard.module.less';

const ERDashboard: FC = () => {
  const params = useParams();
  const {
    data: DashboardStatus,
    isFetching,
    refetch: refetchFileHistory,
    isUninitialized,
  } = useGetDashboardQuery({
    employerId: params.employerId,
  });
  const [isTeamModalOpen, setIsTeamModalOpen] = useState<boolean>(false);
  const [isCarrierContactsModalOpen, setIsCarrierContactsModalOpen] =
    useState<boolean>(false);
  const [showPolicyNAndCarrierContacts, setShowPolicyNAndCarrierContacts] =
    useState<boolean>(false);
  const { employerId, brokerId, setBrokerId } = useNavContext();
  const [isTermsModalVisible, setIsTermsModalVisible] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const accessibleTeamMembers = useAppSelector(
    (state) =>
      state.employer.employer.accessibleTeamMembers?.accessibleTeamMembers
  );
  const employerObj = useAppSelector((state) => state.layout.employer);
  const isBenguideModalOpen = useAppSelector(
    (state) => state.benguide.benguideSlice.isBenguideModalOpen
  );
  const { name, organizationId } = employerObj || {};
  const {
    inProgress: teamMembersInProgress,
    requestType: teamMembersRequestType,
  } = useAppSelector((state) => state.employer.employer);

  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);

  const location = useLocation();
  const match: any = location.state;

  const {
    data: claimsData,
    isSuccess,
    isLoading,
    isError,
    refetch: refetchClaimsData,
  } = useGetDashboardClaimsQuery({
    employerId: params.employerId,
  });
  const {
    data: erIssueDashData,
    isLoading: erIssueIsLoading,
    isSuccess: erIssueIsSuccess,
    isError: erIssueIsError,
    refetch: refetchIssueData,
  } = useGetEmpDashboardIssuesQuery({
    employerId: params.employerId,
    organizationId: params.brokerId,
  });

  const { data } = useGetNavigationsQuery({
    brokerId: brokerId || null,
    employerId: employerId || null,
  });

  const { features = [] } = data || {};

  const isErAdmin = useIsAuthorizedUserRole([loginTypes.erAdmin]);

  const featureKeysToCheck = [
    FEATURE_KEYS.BENEFIT_GUIDE,
    FEATURE_KEYS.BILLING,
    FEATURE_KEYS.CLAIMS,
    FEATURE_KEYS.ISSUES_LOG,
  ];

  const featureVisibilityStatus: Record<string, boolean> = {};
  const checkFeatureEnabled = (
    features: Feature[],
    featureKey: string,
    isErAdmin: boolean
  ) => {
    return features.some((feature: Feature) => {
      if (isErAdmin) {
        return (
          feature.feature === featureKey &&
          feature.enabled &&
          feature.employerVisibilityApplicable &&
          feature.employerVisibilityEnabled
        );
      }
      return feature.feature === featureKey && feature.enabled;
    });
  };

  featureKeysToCheck.forEach((key) => {
    featureVisibilityStatus[key] = checkFeatureEnabled(
      features,
      key,
      isErAdmin
    );
  });

  const isDBGEnabled = featureVisibilityStatus[FEATURE_KEYS.BENEFIT_GUIDE];
  const isBillingEnabled = featureVisibilityStatus[FEATURE_KEYS.BILLING];
  const isClaimsEnabled = featureVisibilityStatus[FEATURE_KEYS.CLAIMS];
  const isIssuesLogEnabled = featureVisibilityStatus[FEATURE_KEYS.ISSUES_LOG];

  const [getCarrierContactsByEmpId, { data: carrierContactsByEmpId }] =
    useLazyGetCarrierContactsByEmployerIdQuery();

  useEffect(() => {
    if (!isFetching && !isUninitialized) {
      refetchFileHistory();
      refetchIssueData();
      refetchClaimsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimsData, refetchFileHistory, refetchIssueData, refetchClaimsData]);

  useEffect(() => {
    if (params.employerId) {
      getCarrierContactsByEmpId({ employerId: params.employerId! });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.employerId]);

  const platformAdminCheck = appBootInfo?.type == loginTypes.platform;
  const brokerAdminCheck = appBootInfo?.type == loginTypes.bokerAdmin;
  const isCarrierContactsVisible = DashboardStatus?.carrierContactsVisible;
  const carrierContactsViewCheckEr = isErAdmin && isCarrierContactsVisible;
  useEffect(() => {
    if (employerId && !isBenguideModalOpen) {
      dispatch(getPlanYearBenefitGuideInfo(employerId));
    }
  }, [dispatch, employerId, isBenguideModalOpen]);

  useEffect(() => {
    if (employerId && !isTeamModalOpen) {
      dispatch(getAccessibleTeamMembers(employerId));
    }
  }, [dispatch, employerId, isTeamModalOpen]);

  useEffect(() => {
    return () => {
      dispatch(changedBenguideModalState(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!appBootInfo?.isTermsAccepted) {
      setIsTermsModalVisible(true);
    }
  }, [appBootInfo]);

  const brokerageTeam = () => {
    if (!isTeamModalOpen) {
      const assignMembers = accessibleTeamMembers?.filter(
        (member) => member.assigned
      );
      return assignMembers?.map((teamMember) => {
        return (
          <Contact
            key={teamMember.id}
            contactName={buildFullName(
              teamMember.firstName,
              teamMember.lastName
            )}
            avatarUrl={teamMember.avatarUrl}
            email={teamMember.email}
            title={teamMember.title}
          />
        );
      });
    }
  };

  const carrierContactsTeam = () => {
    if (!isCarrierContactsModalOpen) {
      return carrierContactsByEmpId
        ?.map((x: any) => mapToSelectedCarrierContact(x))
        .map((carrierContact: any) => {
          return (
            <CarrierContact
              key={carrierContact.id}
              carrier={carrierContact.insuranceCarrierName}
              contactName={carrierContact.name}
              avatarUrl={''}
              email={carrierContact.email}
              title={carrierContact.title}
              contactNumber={carrierContact.phoneNumber}
            />
          );
        });
    }
  };
  const handleEditBrokerTeam = () => {
    setIsTeamModalOpen(true);
  };

  const handleCarrierContactsTeam = () => {
    setIsCarrierContactsModalOpen(true);
  };
  const navigationTechAdmin: {
    name: string;
    link: string;
    state?: { loggedInUserBroker: string; search: string };
  }[] = [
    {
      name: 'Employers',
      link: '/employers',
      state: {
        loggedInUserBroker: match?.filters?.brokers,
        search: match?.filters?.search,
      },
    },
  ];

  const navigationBrokerAdmin: {
    name: string;
    link: string;
    state?: {
      locationId: string;
      userId: string;
      search: string;
    };
  }[] = [
    {
      name: 'Employers',
      link: `/brokers/${brokerId}/employers `,
      state: {
        locationId: match?.filters?.locations,
        userId: match?.filters?.teamMembers,
        search: match?.filters?.search,
      },
    },
  ];

  const getBreadCrumbData = () => {
    if (appBootInfo?.type === loginTypes.platform) {
      return navigationTechAdmin;
    }

    if (appBootInfo?.type === loginTypes.bokerAdmin) {
      return navigationBrokerAdmin;
    }
  };

  const securedBreadcrumb = usePermitIf(
    <BreadcrumbNavigation
      breadCrumbData={getBreadCrumbData()}
      clearEmployerContext
    />,
    employerBackButtonPermittedTypes,
    []
  );

  const secureBrokerTeamEdit = usePermitIf(
    <LinkButton onClick={handleEditBrokerTeam}>
      <PencilIcon className={styles.editButton} />
    </LinkButton>,
    employerBackButtonPermittedTypes,
    []
  );

  const onClickBack = () => {
    if (appBootInfo?.type === loginTypes.platform) setBrokerId(null);
  };

  return (
    <ContentContainer>
      <div className={styles.dashboardWrapper}>
        <Row>
          <Col span={20}>
            <div className={styles.colWrapper}>
              <Row onClick={onClickBack}>{securedBreadcrumb}</Row>
              <Row>
                <Col>
                  <h1 className={styles.titleWrapper}>
                    <OverflowPopover maxWidth={700}>{name}</OverflowPopover>
                    &nbsp;Overview
                  </h1>
                </Col>
              </Row>
              <Row>
                {DashboardStatus?.enabledIssueLog && isIssuesLogEnabled ? (
                  <Col xs={24} className={styles.recentIssues}>
                    <div className={styles.issuePane}>
                      <IssueMgtDashboardPane
                        isTechAdmin={false}
                        isBrokerLevel={false}
                        dataSet={{
                          data: erIssueDashData ? erIssueDashData : {},
                          isLoading: erIssueIsLoading,
                          isSuccess: erIssueIsSuccess,
                          isError: erIssueIsError,
                        }}
                      />
                    </div>
                  </Col>
                ) : !DashboardStatus?.enabledIssuLog &&
                  !isIssuesLogEnabled &&
                  DashboardStatus?.enabledDbgs &&
                  isDBGEnabled ? (
                  <Col xs={24} className={styles.recentBenTitle}>
                    <p className={styles.benguideTitle}>Your Benefits Guides</p>
                    <BenguideList />
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
              <Row className={styles.recentBillingAndClaims}>
                {DashboardStatus?.enabledBilling &&
                isBillingEnabled &&
                DashboardStatus?.enabledClaims &&
                isClaimsEnabled ? (
                  <>
                    <Col xs={12} className={styles.recentBilling}>
                      <DashboardBillingClaimChart
                        isBillingFullyAvailable={false}
                      />
                    </Col>
                    <Col xs={12} className={styles.recentMedicalClaims}>
                      <p className={styles.recentTitle}>
                        Recent Medical Claims
                      </p>
                      {!isError ? (
                        isLoading ? (
                          <CustomerSpinner />
                        ) : isSuccess && claimsData?.content.length ? (
                          <ClaimsChartDashboard
                            isSelfFunded={false}
                            claimsBreakdown={
                              isSuccess ? claimsData.content : []
                            }
                          />
                        ) : (
                          <NoClaimsViewDashboard
                            className={styles.NoClaimsViewDashboard}
                            primaryButtonAction={() => {}}
                            isMedicalClaimsUploaded={
                              claimsData?.medicalClaimsUploaded
                            }
                          />
                        )
                      ) : (
                        <NoClaimsViewDashboard
                          className={styles.NoClaimsViewDashboard}
                          primaryButtonAction={() => {}}
                          isMedicalClaimsUploaded={false}
                        />
                      )}
                    </Col>
                  </>
                ) : !DashboardStatus?.enabledBilling &&
                  !isBillingEnabled &&
                  !DashboardStatus?.enabledClaims &&
                  !isClaimsEnabled ? (
                  <></>
                ) : // Only claims available
                DashboardStatus?.enabledClaims && isClaimsEnabled ? (
                  <>
                    <Col xs={24} className={styles.recentMedicalClaims}>
                      <p className={styles.recentTitle}>
                        Recent Medical Claims
                      </p>

                      {}
                      {!isError ? (
                        isLoading ? (
                          <CustomerSpinner />
                        ) : isSuccess && claimsData?.content.length ? (
                          <ClaimsChartDashboard
                            isSelfFunded={false}
                            claimsBreakdown={
                              isSuccess ? claimsData.content : []
                            }
                          />
                        ) : (
                          <NoClaimsViewDashboard
                            className={styles.NoClaimsViewDashboard}
                            primaryButtonAction={() => {}}
                            isMedicalClaimsUploaded={
                              claimsData?.medicalClaimsUploaded
                            }
                          />
                        )
                      ) : (
                        <NoClaimsViewDashboard
                          className={styles.NoClaimsViewDashboard}
                          primaryButtonAction={() => {}}
                          isMedicalClaimsUploaded={false}
                        />
                      )}
                    </Col>
                  </>
                ) : (
                  // Only billing available
                  DashboardStatus?.enabledBilling &&
                  isBillingEnabled && (
                    <Col xs={24} className={styles.recentBilling}>
                      <DashboardBillingClaimChart
                        isBillingFullyAvailable={true}
                      />
                    </Col>
                  )
                )}
              </Row>
              <Row>
                {DashboardStatus?.enabledDbgs &&
                DashboardStatus?.enabledIssueLog &&
                isIssuesLogEnabled &&
                isDBGEnabled ? (
                  <Col xs={24} className={styles.benifitGuide}>
                    <p className={styles.recentTitle}>Your Benefits Guides</p>
                    <BenguideList />
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </div>
          </Col>

          <Col className={styles.quickLinkWrapper}>
            <QuickLinks />

            <div className={styles.pnAndCcBtnWrapper}>
              <PageActionButton
                type="primary"
                className={styles.actionBtn}
                onClick={() => setShowPolicyNAndCarrierContacts(true)}
              >
                Policy Numbers & Carrier Contacts
              </PageActionButton>
            </div>

            <div className={styles.teamWrapper}>
              <h2 className={styles.BCTitle}>
                {organizationId === LUMITY_ORGANIZATION_ID
                  ? 'Your Lumity Team'
                  : 'Benefits Consultant Team'}
              </h2>
              {secureBrokerTeamEdit}
              <EditBrokerTeamModal
                isModalOpen={isTeamModalOpen}
                setIsModalOpen={setIsTeamModalOpen}
                brokerName={name ? name : 'Employer'}
                selectedEmployer={!isNull(employerId) ? employerId : ''}
              />
            </div>
            {teamMembersInProgress &&
              teamMembersRequestType === getAccessibleTeamInProgress.type && (
                <div className={styles.loadingIconStyle}>
                  <Spin />
                </div>
              )}
            {brokerageTeam()}
            {(platformAdminCheck ||
              brokerAdminCheck ||
              carrierContactsViewCheckEr) && (
              <div className={styles.teamWrapper}>
                <div className={styles.flexContainer}>
                  <h2 className={styles.carrierTeamTitle}> Carrier Team</h2>
                  <LinkButton
                    onClick={handleCarrierContactsTeam}
                    icon={
                      (platformAdminCheck || brokerAdminCheck) && <PencilIcon />
                    }
                  />
                </div>
                <EditCarrierContactsModal
                  isModalOpen={isCarrierContactsModalOpen}
                  setIsModalOpen={setIsCarrierContactsModalOpen}
                  employerName={String(name)}
                  isCarrierContactsVisible={isCarrierContactsVisible}
                  refetchCarrierContacts={() => {
                    refetchFileHistory();
                    getCarrierContactsByEmpId({
                      employerId: params.employerId!,
                    });
                  }}
                  selectedCarrierContacts={carrierContactsByEmpId}
                />
              </div>
            )}

            {(platformAdminCheck ||
              brokerAdminCheck ||
              carrierContactsViewCheckEr) &&
              carrierContactsTeam()}
          </Col>
        </Row>
      </div>
      <PolicyNumbersAndCarrierContactModal
        isModalOpen={showPolicyNAndCarrierContacts}
        setIsModalOpen={setShowPolicyNAndCarrierContacts}
      />
      <Modal
        width={600}
        maskClosable={false}
        closable={false}
        wrapClassName={styles.modalMain}
        visible={isTermsModalVisible}
        onCancel={() => {}}
        footer={null}
      >
        <Terms
          setIsTermsModalOpen={setIsTermsModalVisible}
          individualId={appBootInfo?.individualId}
        />
      </Modal>
    </ContentContainer>
  );
};

export default ERDashboard;
