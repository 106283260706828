import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import MedicalPlan from 'model/plans/MedicalPlan';
import {
  checkPlanRecommenderStatus,
  planRecommenderToggle,
} from 'modules/plans/services/PlanService';
import PlanRecommender from 'model/PlanRecommender';

type PlanRecommenderState = {
  isLoading: boolean;
  error: {};
  planRecommender: PlanRecommender;
};

const initialState = {
  isLoading: false,
  error: {},
  planRecommender: {},
} as PlanRecommenderState;

const PlanRecommenderSlice = createSlice({
  name: 'PlanRecommenderSlice',
  initialState,
  reducers: {
    editPlanRecommenderInProgress(state) {
      state.isLoading = true;
    },
    editPlanRecommenderCompleted: (state, action) => {
      state.isLoading = false;
      state.planRecommender = action.payload;
    },
    editPlanRecommenderFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getPlanRecommenderInProgress(state) {
      state.isLoading = true;
    },
    getPlanRecommenderCompleted: (state, action) => {
      state.isLoading = false;
      state.planRecommender = action.payload;
    },
    getPlanRecommenderFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.planRecommender = {} as PlanRecommender;
    },
  },
});

export default PlanRecommenderSlice.reducer;

export const {
  editPlanRecommenderInProgress,
  editPlanRecommenderCompleted,
  editPlanRecommenderFailed,
  getPlanRecommenderInProgress,
  getPlanRecommenderCompleted,
  getPlanRecommenderFailed,
} = PlanRecommenderSlice.actions;

export const getPlanRecommenderStatus = (plan: MedicalPlan) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(editPlanRecommenderInProgress());
      const response = await checkPlanRecommenderStatus(plan);
      const data = response.data;
      dispatch(getPlanRecommenderCompleted(data));
    } catch (error) {
      dispatch(getPlanRecommenderFailed(error));
    }
  };
};

export const setPlanRecommenderStatus = (
  benguideId: string,
  section: string,
  status: any
) => {
  return async (dispatch: Dispatch) => {
    const enabled = {
      enabled: status,
    };
    try {
      dispatch(editPlanRecommenderInProgress());
      const response = await planRecommenderToggle(
        benguideId,
        section,
        enabled
      );
      const data = response.data;
      dispatch(getPlanRecommenderCompleted(data));
    } catch (error) {
      dispatch(getPlanRecommenderFailed(error));
    }
  };
};
