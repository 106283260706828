import { TreeDataNode } from 'antd';
import IconCSV from 'assets/documents/icon_file_CSV.svg';
import IconDOC from 'assets/documents/icon_file_DOC.svg';
import IconImage from 'assets/documents/icon_file_Image.svg';
import IconPDF from 'assets/documents/icon_file_PDF.svg';
import IconPPT from 'assets/documents/icon_file_PPT.svg';
import IconVideo from 'assets/documents/icon_file_Video.svg';
import IconXLS from 'assets/documents/icon_file_XLS.svg';
import IconDocument from 'assets/documents/icon_file_Document.svg';
import IconFolder from 'assets/documents/icon_file_Folder.svg';
import DocumentVO from 'model/DocumentVO';
import { MIME_TYPES } from 'util/fileUtil';

export const getFileIconByExtension = (extension: string) => {
  switch (extension.toLowerCase()) {
    case 'pdf':
      return IconPDF;
    case 'png':
    case 'jpg':
    case 'jpeg':
      return IconImage;
    case 'xls':
    case 'xlsx':
      return IconXLS;
    case 'csv':
      return IconCSV;
    case 'pptx':
    case 'ppt':
      return IconPPT;
    case 'docx':
    case 'doc':
      return IconDOC;
    case 'mp4':
      return IconVideo;
    case 'folder':
      return IconFolder;
    default:
      return IconDocument;
  }
};

export const transformDocumentsToNodes = (
  documents: DocumentVO[],
  validFileTypes: string[] = [
    MIME_TYPES.PDF,
    MIME_TYPES.DOCX,
    MIME_TYPES.DOC,
    MIME_TYPES.XLS,
    MIME_TYPES.XLSX,
  ] as string[]
): TreeDataNode[] => {
  return documents
    .filter(
      (document) => !document.file || validFileTypes.includes(document.mimeType)
    )
    .map(createNodeFromDocument);
};

export const createNodeFromDocument = (
  document: Partial<DocumentVO>
): TreeDataNode => {
  if (!document.file) {
    return {
      key: document.documentId + '/folder',
      title: document.name,
      isLeaf: false,
      checkable: false,
      selectable: false,
      children: [],
    } as TreeDataNode;
  } else {
    return {
      key: document.documentId,
      title: document.name,
      isLeaf: true,
      checkable: false,
      selectable: false,
    } as TreeDataNode;
  }
};

export const findAndUpdate = (
  currentNode: TreeDataNode,
  toFind: string,
  newChildren: TreeDataNode[]
): boolean => {
  if (currentNode.key === toFind) {
    currentNode.children = newChildren;
    return true;
  }

  if (currentNode.children) {
    return currentNode.children
      .map((child) => findAndUpdate(child, toFind, newChildren))
      .some((found) => found);
  }

  return false;
};

export const findAndAdd = (
  currentNode: TreeDataNode,
  toFind: string,
  added: TreeDataNode[]
): boolean => {
  const key = (currentNode.key as string).split('/')[0];
  if (key === toFind) {
    currentNode.children = sortNodes([
      ...(currentNode.children || []),
      ...added,
    ]);
    return true;
  }

  if (currentNode.children) {
    return currentNode.children
      .map((child) => findAndAdd(child, toFind, added))
      .some((found) => found);
  }

  return false;
};

export const findAndReplace = (
  currentNode: TreeDataNode,
  toFind: string,
  newNode: TreeDataNode
): boolean => {
  if (currentNode.key === toFind) {
    currentNode.key = newNode.key;
    currentNode.title = newNode.title;
    currentNode.isLeaf = newNode.isLeaf;
    return true;
  }

  if (currentNode.children) {
    return currentNode.children
      .map((child) => findAndReplace(child, toFind, newNode))
      .some((found) => found);
  }

  return false;
};

export const findNode = (
  currentNode: TreeDataNode,
  toFind: string
): TreeDataNode | undefined => {
  if (currentNode.key === toFind) {
    return currentNode;
  }

  if (currentNode.children) {
    return currentNode.children
      .map((child) => findNode(child, toFind))
      .filter((node) => node !== undefined)[0];
  }

  return undefined;
};

export const findHierarchy = (
  root: TreeDataNode,
  targetKey: string
): string[] => {
  const path: string[] = [];
  const dfs = (node: TreeDataNode, key: string): boolean => {
    path.push(String(node.key));
    if (node.key === key) {
      return true;
    }
    if (node.children) {
      for (const child of node.children) {
        if (dfs(child, key)) {
          return true;
        }
      }
    }
    path.pop();
    return false;
  };
  if (dfs(root, targetKey)) {
    return path;
  } else {
    return []; // If the key is not found, return null or an empty array based on preference
  }
};

const sortNodes = (nodes: TreeDataNode[]): TreeDataNode[] => {
  return nodes.sort((a, b) => {
    if (a.isLeaf && !b.isLeaf) {
      return 1;
    }
    if (!a.isLeaf && b.isLeaf) {
      return -1;
    }
    return (a.title as string).localeCompare(b.title as string);
  });
};
