import { Dispatch } from 'redux';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FourOOneKPlan } from 'model/plans/FourOOneKPlan';
import * as RetirementPlanService from 'modules/plans/services/RetirementPlanService';
import MetaData from 'model/MetaData';
import PaginationConfig from 'model/PaginationConfig';
import * as PlanService from 'modules/plans/services/PlanService';
import ErrorDetails from 'model/ErrorDetails';
import DocumentUploadStatus from 'model/DocumentUploadStatus';
import ClonePlanDocument from 'model/plans/ClonePlanDocument';
import { BenefitCategory } from 'constants/commonConstants';
import { clonePlanDocuments } from './planDocumentsSlice';

export type RetirementPlanServiceState = {
  inProgress: boolean;
  error: any;
  requestType: string;
  retirementPlan: FourOOneKPlan;
  retirementPlanList: { content: Array<FourOOneKPlan>; metadata: MetaData };
  documentReferences: {
    [key: string]: {
      uploading: boolean;
      reference: string;
      blobUrl: string;
      fileName: string;
      documentUploadStatus: DocumentUploadStatus;
    };
  };
  cancelDocumentUpload: boolean;
};

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  retirementPlan: {} as FourOOneKPlan,
  retirementPlanList: { content: [], metadata: {} },
  documentReferences: {},
  cancelDocumentUpload: false,
} as RetirementPlanServiceState;

const retirementPlanSlice = createSlice({
  name: 'retirementPlan',
  initialState,
  reducers: {
    createRetirementPlan: (state, action: PayloadAction<FourOOneKPlan>) => {
      state.retirementPlan = action.payload;
      state.inProgress = false;
      state.error = null;
      state.requestType = action.type;
    },
    updateRetirementPlanInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    updateRetirementPlanCompleted: (
      state,
      action: PayloadAction<FourOOneKPlan>
    ) => {
      state.inProgress = false;
      state.retirementPlan = { ...state.retirementPlan, ...action.payload };
      state.error = null;
      state.requestType = action.type;
    },
    updateRetirementPlanFailed: (state, action) => {
      state.inProgress = false;
      state.error = JSON.parse(JSON.stringify(action.payload));
      state.requestType = action.type;
    },
    getRetirementPlansListInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
    },
    getRetirementPlansListCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.inProgress = false;
      state.error = null;
      state.retirementPlanList = payload;
    },
    getRetirementPlansListFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = { response: action.payload };
    },
    getPlanByIdStarted: (state) => {
      state.inProgress = true;
      state.retirementPlan = {} as FourOOneKPlan;
      state.error = null;
    },
    getPlanByIdCompleted: (state, action: PayloadAction<FourOOneKPlan>) => {
      state.inProgress = false;
      state.retirementPlan = action.payload;
      state.error = null;
    },
    getPlanByIdFailed: (state, action) => {
      state.inProgress = false;
      state.retirementPlan = {} as FourOOneKPlan;
      state.error = { response: action.payload };
    },
    documentUploadStarted: (state, { payload }: PayloadAction<any>) => {
      state.cancelDocumentUpload = false;
      state.inProgress = true;
      state.documentReferences = {
        ...state.documentReferences,
        [payload.documentType]: {
          uploading: true,
        },
      };
    },
    documentUploadCompleted: (state, { payload }: PayloadAction<any>) => {
      state.inProgress = false;
      if (!state.cancelDocumentUpload) {
        state.documentReferences = {
          ...state.documentReferences,
          [payload.documentType]: {
            uploading: false,
            reference: payload.reference,
            blobUrl: payload.blobUrl,
            fileName: payload.fileName,
            documentUploadStatus: 'ADDED',
          },
        };
      }
    },
    updateDocumentStatus: (
      state,
      { payload }: PayloadAction<{ documentType: string; status: string }>
    ) => {
      state.retirementPlan.documentReferences = {
        ...state.retirementPlan.documentReferences,
        [payload.documentType]: {
          documentUploadStatus: payload.status,
        },
      };
    },
    documentUploadFailed: (state, { payload }: PayloadAction<any>) => {
      state.inProgress = false;
      if (!state.cancelDocumentUpload) {
        state.documentReferences = {
          ...state.documentReferences,
          [payload.documentType]: {
            uploading: false,
            error: { response: payload.error },
          },
        };
      }
    },
    removeDocumentReference: (state, { payload }: PayloadAction<any>) => {
      state.documentReferences = {
        ...state.documentReferences,
        [payload]: {
          documentUploadStatus: 'REMOVED',
        },
      };
    },
    clearDocuments: (state) => {
      state.documentReferences = {};
      state.cancelDocumentUpload = true;
      state.inProgress = false;
    },
    clearRetirementPlanApiErrors: (state) => {
      state.error = null;
    },
  },
});

export const {
  createRetirementPlan,
  updateRetirementPlanInProgress,
  updateRetirementPlanCompleted,
  updateRetirementPlanFailed,
  getRetirementPlansListInProgress,
  getRetirementPlansListCompleted,
  getRetirementPlansListFailed,
  getPlanByIdStarted,
  getPlanByIdCompleted,
  getPlanByIdFailed,
  documentUploadStarted,
  documentUploadCompleted,
  documentUploadFailed,
  clearDocuments,
  clearRetirementPlanApiErrors,
  updateDocumentStatus,
  removeDocumentReference,
} = retirementPlanSlice.actions;

export default retirementPlanSlice.reducer;

export const createRetirementPlanBasicInfo =
  (data: FourOOneKPlan) => (dispatch: Dispatch) => {
    dispatch(createRetirementPlan(data));
  };

export const clearDocumentsAttached = () => (dispatch: Dispatch) => {
  dispatch(clearDocuments());
};

export const saveRetirementPlan = (
  plan: FourOOneKPlan,
  onSave: Function,
  cloneDocuments?: boolean,
  sourcePlanId?: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateRetirementPlanInProgress());
      let response = {} as any;
      if (plan.id) {
        response = await RetirementPlanService.updateRetirementPlan(plan);
      } else {
        response = await RetirementPlanService.createRetirementPlan(plan);
      }
      const data = response.data;
      dispatch(updateRetirementPlanCompleted(JSON.parse(JSON.stringify(data))));
      if (data && cloneDocuments && plan.documents && sourcePlanId) {
        const req: ClonePlanDocument = {
          employerId: data.employerId,
          sourcePlanId: sourcePlanId,
          targetPlanId: data.id,
          benefitCategory: BenefitCategory.RETIREMENT.value,
          benefitKind: plan.benefitKind,
        };
        dispatch(clonePlanDocuments(req));
      }
      onSave(data.id);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        dispatch(
          updateRetirementPlanFailed({
            data: err.response.data,
          } as ErrorDetails)
        );
      }
    }
  };
};

export const getRetirementPlansList = (
  { page, size, sort, query }: PaginationConfig,
  employerId: string,
  planYearId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getRetirementPlansListInProgress());
    try {
      const response = await RetirementPlanService.getRetirementPlanList(
        page,
        size,
        sort,
        query,
        employerId,
        planYearId
      );
      dispatch(getRetirementPlansListCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getRetirementPlansListFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const findPlanById = (planId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPlanByIdStarted());
    try {
      const response = await RetirementPlanService.getRetirementPlanById(
        planId
      );
      dispatch(getPlanByIdCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(getPlanByIdFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
};

export const uploadRetirementPlanDocs = (file: File, documentType: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(documentUploadStarted({ documentType }));
    PlanService.uploadPlanDocuments(file.name, file)
      .then(({ data }: AxiosResponse) => {
        dispatch(
          documentUploadCompleted({
            documentType,
            reference: data,
            fileName: file.name,
            blobUrl: URL.createObjectURL(file),
          })
        );
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          dispatch(
            documentUploadFailed({
              documentType,
              error: JSON.parse(JSON.stringify(error.response)),
            })
          );
        } else {
          console.error(error);
        }
      });
  };
};
