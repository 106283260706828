import { Row, Col, Form, Input } from 'antd';

import {
  ChangeEvent,
  ChangeEventHandler,
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
} from 'react';
import InputForm from 'components/InputForm/InputForm';
import NumberFormatInput from 'components/FormInput/NumberFormatInput';
import LabelWithTooltip from 'components/LabelWithTooltip/LabelWithTooltip';
import { TaxAdvantagedAccountFormData } from 'modules/plans/taxAdvantaged/components/AddTaxAdvantagedPlan/AddTaxAdvantagedPlan';
import FrequencySelect from 'modules/plans/taxAdvantaged/components/FrequencySelect/FrequencySelect';
import { TaxAdvantageFields } from 'modules/plans/constants';

import styles from './dependentCareFSAPlanInfo.module.less';

const { TextArea } = Input;

type DependentCareFSAPlanInformationProps = {
  isCancelButtonClickConfirm: boolean;
  setFormData: Function;
  formData: any;
  otherEmployerContributionsTooltipContent: ReactNode;
  endOfYearPolicyTooltipContent: ReactNode;
  onNumberInputChange: ChangeEventHandler<HTMLInputElement>;
  changeInputStyle: Function;
  isFrequencyError: boolean;
  isEditMode?: boolean;
};

const DependentCareFSAPlanInformation = forwardRef(
  (props: DependentCareFSAPlanInformationProps, ref) => {
    const {
      isCancelButtonClickConfirm,
      setFormData,
      formData,
      otherEmployerContributionsTooltipContent,
      endOfYearPolicyTooltipContent,
      onNumberInputChange,
      changeInputStyle,
      isFrequencyError,
      isEditMode = false,
    } = props;

    const [form] = Form.useForm();

    const onInputChange = async (allValues: any, value: any) => {
      form.setFieldsValue(allValues);

      setFormData({ ...formData, ...allValues });
    };

    useEffect(() => {
      if (formData && isEditMode) {
        form.setFieldsValue({ frequency: formData.frequency });
        form.setFieldsValue({
          empContribution: formData.empContribution,
        });
        form.setFieldsValue({ otherContribution: formData.otherContribution });
        form.setFieldsValue({
          maxIndividualContributionForMarriedButFilledAsSeparately:
            formData.maxIndividualContributionForMarriedButFilledAsSeparately,
        });
        form.setFieldsValue({
          maxMarriedContributionForFillingJointlyOrSingleParentAsHead:
            formData.maxMarriedContributionForFillingJointlyOrSingleParentAsHead,
        });
        form.setFieldsValue({ endOfYearPolicy: formData.endOfYearPolicy });
      }
    }, [formData, form, isEditMode]);

    useEffect(() => {
      if (isCancelButtonClickConfirm) {
        form.resetFields();
      }
    }, [isCancelButtonClickConfirm, form]);

    useImperativeHandle(ref, () => ({
      resetForm() {
        form.resetFields();
      },
    }));

    const setContributionsDisable = () => {
      if (!form.getFieldValue('frequency')) {
        form.setFieldsValue({
          empContribution: '',
        });
        return true;
      }
      return false;
    };

    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      form.setFieldsValue({ [name]: value });

      setFormData((prevData: TaxAdvantagedAccountFormData) => ({
        ...prevData,
        [name]: value,
      }));
    };

    const handleStartEndSpaces = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      form.setFieldsValue({
        [name]: value.trimStart().trimEnd(),
      });

      setFormData((prevData: TaxAdvantagedAccountFormData) => ({
        ...prevData,
        [name]: value.trimStart().trimEnd(),
      }));
    };

    return (
      <>
        <InputForm
          form={form}
          onValuesChange={onInputChange}
          size={isEditMode ? 'small' : 'middle'}
        >
          <Row gutter={48}>
            <Col span={12}>
              <Form.Item
                name="frequency"
                label="Employer Contribution Frequency"
                labelCol={{ span: 24 }}
              >
                <FrequencySelect isWarning={isFrequencyError} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="empContribution"
                label="Employer Contribution"
                labelCol={{ span: 24 }}
              >
                <NumberFormatInput
                  prefix="$"
                  isBrokerScreen={true}
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  name={TaxAdvantageFields.EMPLOYER_CONTRIBUTION}
                  className={
                    changeInputStyle(TaxAdvantageFields.EMPLOYER_CONTRIBUTION)
                      ? styles.warningInput
                      : ''
                  }
                  onChange={onNumberInputChange}
                  disabled={setContributionsDisable()}
                  allowNegative={false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={48}>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item
                name="otherContribution"
                labelCol={{ span: 24 }}
                label={
                  <Row>
                    <LabelWithTooltip
                      content={otherEmployerContributionsTooltipContent}
                      mandatoryField={false}
                      fieldText={'Other Employer Contributions'}
                    />
                  </Row>
                }
              >
                <TextArea
                  onChange={handleTextAreaChange}
                  name="otherContribution"
                  value={formData.otherContribution}
                  showCount
                  maxLength={200}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  onBlur={handleStartEndSpaces}
                />
              </Form.Item>
              <div
                className={`${styles.characterCountText} ${
                  !isEditMode
                    ? styles.characterCountTextOther
                    : styles.characterCountTextOtherEdit
                }`}
              >
                Character Limit
              </div>
            </Col>
          </Row>

          <Row gutter={48}>
            <Col span={12}>
              <Form.Item
                name="maxIndividualContributionForMarriedButFilledAsSeparately"
                label="Max Yearly Contribution Married Filing Separately"
                labelCol={{ span: 24 }}
                className={` ${isEditMode ? styles.longLabel : ''}`}
              >
                <NumberFormatInput
                  prefix="$"
                  isBrokerScreen={true}
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="maxMarriedContributionForFillingJointlyOrSingleParentAsHead"
                label="Max Yearly Contribution Married Filing Jointly or Single Parent"
                labelCol={{ span: 24 }}
                className={` ${isEditMode ? styles.longLabel : ''}`}
              >
                <NumberFormatInput
                  prefix="$"
                  isBrokerScreen={true}
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={48}>
            <Col span={12}>
              <Form.Item
                name="endOfYearPolicy"
                labelCol={{ span: 24 }}
                label={
                  <Row>
                    <LabelWithTooltip
                      content={endOfYearPolicyTooltipContent}
                      mandatoryField={false}
                      fieldText={'End of Year Policy'}
                    />
                  </Row>
                }
              >
                <TextArea
                  onChange={handleTextAreaChange}
                  name="endOfYearPolicy"
                  value={formData.endOfYearPolicy}
                  showCount
                  maxLength={70}
                  onBlur={handleStartEndSpaces}
                />
              </Form.Item>
              <div
                className={`${styles.characterCountText} ${
                  !isEditMode
                    ? styles.characterCountTextOther
                    : styles.characterCountTextOtherEdit
                }`}
              >
                Character Limit
              </div>
            </Col>
          </Row>
        </InputForm>
      </>
    );
  }
);

DependentCareFSAPlanInformation.displayName = 'DependentCareFSAPlanInformation';

export default DependentCareFSAPlanInformation;
