import { FC, useEffect } from 'react';
import { addClient } from 'modules/clients/slices/clientSlice';

import { benguideUrl } from 'util/apiUtil';
import { useAppDispatch } from 'hooks/redux';
import { CLIENT_DBG } from 'modules/clients/DBGClient/DBGClientConts';

import styles from './benguideContainer.module.less';

type BenguideContainerProps = {
  path: string;
  revision?: number;
  id: string;
};

const BenguideContainer: FC<BenguideContainerProps> = (
  props: BenguideContainerProps
) => {
  const { path, id, revision } = props;
  const dispatch = useAppDispatch();

  const src = path.includes('?')
    ? `${benguideUrl}/${path}&revision=${revision}`
    : `${benguideUrl}/${path}?revision=${revision}`;

  useEffect(() => {
    dispatch(addClient({ id: CLIENT_DBG, clientId: id }));
  }, [id, dispatch]);

  return (
    <div className={styles.benguideFrame}>
      <iframe id={id} src={src}></iframe>
    </div>
  );
};

export default BenguideContainer;
