import { FC, useState } from 'react';
import { size, isEmpty, isUndefined } from 'lodash';
import { Col, Row } from 'antd';
import { useAppSelector } from 'hooks/redux';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { TaxAdvantagedAccountPlanType } from 'modules/plans/constants';
import { TaxAdvantagedAccountPlan } from 'model/plans/TaxAdvantagedAccountPlan';
import { getFrequencyAccordingToPlanType } from 'modules/plans/utils';
import PlanDocumentsOverview from 'modules/plans/components/OverviewPage/PlanDocumentsOverview/PlanDocumentOverview';

import {
  FOUR_TIER,
  N_TIER,
  N_TIER_PLAN_PROPERTIES,
} from 'modules/renewals/constants/renewalsConstants';

import styles from './hsaPlanOverview.module.less';

type HSAPlanOverviewProps = {
  plan: TaxAdvantagedAccountPlan;
  heading?: string;
};

const HSAPlanOverview: FC<HSAPlanOverviewProps> = (props) => {
  const { plan } = props;
  const { hsaMedicalPlansList } = useAppSelector((state) => state.plan.plans);
  const [viewPlanDocumentsModal, setViewPlanDocumentsModal] = useState(false);

  const getFrequencyValue = (frequency: string) => {
    const selectedFrequency = getFrequencyAccordingToPlanType(
      TaxAdvantagedAccountPlanType.HSA.value
    ).find((item) => item.value === frequency);
    return selectedFrequency?.label;
  };

  const getFormattedDecimalValue = (value: number) => {
    return `$${Number(parseFloat(`${value}`).toFixed(2)).toLocaleString('en', {
      minimumFractionDigits: 2,
    })}`;
  };

  const contributionOverview = [
    {
      label: 'Selected Associated Medical Plans',
      value: !isEmpty(plan.selectedMedicalPlanVOs)
        ? plan.selectedMedicalPlanVOs.map((data) => data.name).join(' , ')
        : '-',
    },
    {
      label: 'Employer Contribution Frequency',
      value: plan.employerContribution?.frequency
        ? getFrequencyValue(plan.employerContribution?.frequency)
        : '-',
    },
    {
      label: 'Employer EE Contribution',
      value:
        plan.employerContribution.employerEEContribution ||
        plan.employerContribution.employerEEContribution === 0
          ? getFormattedDecimalValue(
              plan.employerContribution.employerEEContribution
            )
          : '-',
    },
  ];

  const getHSAOverview = () => {
    let initialLabels = contributionOverview;
    const hsaPlan = plan as any;

    let nTierCount = 0;
    let tierType;

    if (!isEmpty(hsaMedicalPlansList)) {
      // get the first plan and decide the number of tiers
      const getTierPlan: any = hsaMedicalPlansList[0];

      const rates = Object.entries(getTierPlan?.rates || {})[0];
      const [key, value]: any = rates || [];

      if (key && value?.ntierContributions?.contributions) {
        tierType = N_TIER;
        nTierCount = size(value?.ntierContributions?.contributions) - 2;
      } else if (key && value?.fourTierContributions) {
        tierType = FOUR_TIER;
      }
    }

    if (
      tierType === FOUR_TIER ||
      !isUndefined(plan.employerContribution?.employerESContribution) ||
      !isUndefined(plan.employerContribution?.employerECContribution) ||
      !isUndefined(plan.employerContribution?.employerEFContribution)
    ) {
      initialLabels = [
        ...initialLabels,
        {
          label: 'Employer ES Contribution',
          value:
            plan.employerContribution?.employerESContribution ||
            plan.employerContribution?.employerESContribution === 0
              ? getFormattedDecimalValue(
                  plan.employerContribution?.employerESContribution
                )
              : '-',
        },
        {
          label: 'Employer EC Contribution',
          value:
            plan.employerContribution?.employerECContribution ||
            plan.employerContribution?.employerECContribution === 0
              ? getFormattedDecimalValue(
                  plan.employerContribution?.employerECContribution
                )
              : '-',
        },
        {
          label: 'Employer EF Contribution',
          value:
            plan.employerContribution?.employerEFContribution ||
            plan.employerContribution?.employerEFContribution === 0
              ? getFormattedDecimalValue(
                  plan.employerContribution?.employerEFContribution
                )
              : '-',
        },
      ];
    } else {
      N_TIER_PLAN_PROPERTIES?.map((item: string, index: number) => {
        // excluding EE, other contribution and frequency
        nTierCount = size(hsaPlan?.employerContribution) - 3;
        if (index < nTierCount) {
          initialLabels = [
            ...initialLabels,
            {
              label: `Employer EE + ${index + 1} Contribution`,
              value: hsaPlan?.employerContribution?.[item]
                ? getFormattedDecimalValue(hsaPlan.employerContribution?.[item])
                : '-',
            },
          ];
        }
      });
    }

    return [
      ...initialLabels,
      {
        label: 'Other Employer Contributions',
        value: (
          <OverflowPopover>
            {plan.employerContribution?.otherContribution
              ? `${plan.employerContribution?.otherContribution}`
              : '-'}
          </OverflowPopover>
        ),
      },
      {
        label: 'Max Yearly Contribution Individual',
        value:
          plan?.maxYearlyContributionIndividual ||
          plan?.maxYearlyContributionIndividual === 0
            ? getFormattedDecimalValue(plan?.maxYearlyContributionIndividual)
            : '-',
      },
      {
        label: 'Max Yearly Contribution Family',
        value:
          plan?.maxYearlyContributionFamily ||
          plan?.maxYearlyContributionFamily === 0
            ? getFormattedDecimalValue(plan?.maxYearlyContributionFamily)
            : '-',
      },
    ];
  };

  const updateViewPlanDocumentsModal = (value: boolean) => {
    setViewPlanDocumentsModal(value);
  };

  return (
    <div className={styles.wrapper}>
      {getHSAOverview()?.map((item, Index) => (
        <Row key={Index}>
          <Col span={24}>
            <InfoLabelSection labelText={item.label} value={item.value} />
          </Col>
        </Row>
      ))}
      <div className={styles.overview} id={'Plan_Doc'}>
        <PlanDocumentsOverview
          heading={'Plan Resources'}
          plan={plan}
          documents={plan?.documents}
          benefitKind={plan.benefitKind}
          viewPlanDocumentsModal={viewPlanDocumentsModal}
          setViewPlanDocumentsModal={(value) =>
            updateViewPlanDocumentsModal(value)
          }
        />
      </div>
    </div>
  );
};

export default HSAPlanOverview;
