import { FC } from 'react';
import { Button } from 'antd';

import { NativeButtonProps } from 'antd/lib/button/button';

import styles from './cancelButton.module.less';

interface CancelButtonProps extends NativeButtonProps {
  withBorder?: boolean;
}

const CancelButton: FC<CancelButtonProps> = (props) => {
  const { className, withBorder = false, ...rest } = props;
  return (
    <div className={styles.cancelBtnWrapper}>
      <Button
        type="text"
        className={`${
          !withBorder ? styles.cancelBtn : styles.btnWithBorder
        } ${className}`}
        {...rest}
      />
    </div>
  );
};

export default CancelButton;
