import { FC, ReactNode } from 'react';
import { Modal, ModalProps } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import styles from './halfScreenModal.module.less';

interface Props extends ModalProps {
  children: ReactNode;
  visible: boolean;
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  className?: string;
  heading?: string;
  width?: number | string;
}

const HalfScreenModal: FC<Props> = (props: Props) => {
  const {
    children,
    heading,
    visible,
    onCancel,
    className = '',
    width = 'auto',
    ...rest
  } = props;
  return (
    <Modal
      width={width}
      centered
      title={heading}
      visible={visible}
      wrapClassName={`${styles.halfModalScreenWrapper} ${className}`}
      onCancel={onCancel}
      footer={false}
      closeIcon={<CloseCircleOutlined />}
      closable={false}
      maskClosable={false}
      {...rest}
    >
      {children}
    </Modal>
  );
};
export default HalfScreenModal;
