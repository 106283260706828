import { FC, MutableRefObject } from 'react';
import { FormInstance } from 'antd';
import Rates from 'modules/plans/components/Rates/Rates';
import { useAppSelector } from 'hooks/redux';
import MedicalPlan from 'model/plans/MedicalPlan';
import {
  updateMedicalPlanByPath,
  getPlanContributionByFrequency,
  getPlanContributionInProgress,
} from 'modules/plans/slices/medicalPlanSlice';
import { BenefitCategory } from 'constants/commonConstants';

type RatesSBCWrapperProps = {
  wrappedRef: MutableRefObject<any>;
  form: FormInstance;
  currentSection: string;
  updateValidationMsg: Function;
};

const RatesSBCWrapper: FC<RatesSBCWrapperProps> = (
  props: RatesSBCWrapperProps
) => {
  const { wrappedRef, form, currentSection, updateValidationMsg } = props;
  const { editedMedicalPlan = {} as MedicalPlan } = useAppSelector(
    (state) => state.plan.plans
  );
  const requestType = useAppSelector((state) => state.plan.plans.requestType);
  const { dbgData } = useAppSelector((state) => state.plan.dgbInfo);
  const { benefitGuideObj } = useAppSelector(
    (state) => state.employer.benguide
  );
  const { dbgByFrequency, rateValidations } = dbgData;
  return (
    <Rates
      benefitCategory={BenefitCategory.MEDICAL.value}
      updatePlanByPathAction={updateMedicalPlanByPath}
      getContributionAction={getPlanContributionByFrequency}
      benefitGuideObj={benefitGuideObj}
      plan={editedMedicalPlan}
      ref={wrappedRef}
      currentSection={currentSection}
      form={form}
      dbgByFrequency={dbgByFrequency}
      isDBGView={false}
      rateValidations={rateValidations}
      updateValidationMsg={updateValidationMsg}
      getContributionInProgress={
        requestType === getPlanContributionInProgress.type
      }
    />
  );
};

export default RatesSBCWrapper;
