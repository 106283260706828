import { ReactNode, memo } from 'react';
import { Row, Button, Col, Tooltip } from 'antd';
import { isString } from 'lodash';

// import { ReactComponent as ThumbsUp } from 'assets/images/sbc-ai-feedback-response-thumbs-up-not-selected.svg';
// import { ReactComponent as ThumbsDown } from 'assets/images/sbc-ai-feedback-thumbs-down-not-selected.svg';
import { ReactComponent as CopyIcon } from 'assets/images/icon-copy.svg';

import styles from './feedback.module.less';

interface FeedbackProps {
  content: ReactNode;
}

interface Control {
  label: string;
  onClick: () => void;
  icon: ReactNode;
}

const Feedback = memo(({ content }: FeedbackProps) => {
  const handleCopy = (content: ReactNode) => () => {
    if (isString(content)) {
      navigator.clipboard.writeText(content);
    }
  };

  const controls: Control[] = [
    // {
    //   label: 'Thumbs Up',
    //   onClick: () => console.log('Thumbs Up clicked'),
    //   icon: <ThumbsUp />,
    // },
    // {
    //   label: 'Thumbs Down',
    //   onClick: () => console.log('Thumbs Down clicked'),
    //   icon: <ThumbsDown />,
    // },
    {
      label: 'Copy',
      onClick: handleCopy(content),
      icon: <CopyIcon />,
    },
  ];

  const renderControls = () => {
    return controls.map((control, index) => (
      <Col key={index}>
        <Tooltip placement="bottom" title={control.label}>
          <Button
            onClick={control.onClick}
            icon={control.icon}
            aria-label={control.label}
            className={styles.feedbackButton}
          />
        </Tooltip>
      </Col>
    ));
  };

  return (
    <Row gutter={1} className={styles.feedbackRow}>
      {renderControls()}
    </Row>
  );
});

Feedback.displayName = 'Feedback';

export default Feedback;
