import { forwardRef, useImperativeHandle, useState } from 'react';
import { Checkbox, Row, Select } from 'antd';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';

import styles from './clonePlanDropdown.module.less';

type ClonePlanDropDownProps = {
  selectOptions: any[];
  dropdownPlansSelected: string[];
  changeDropdownPlansSelected: Function;
  disabled?: boolean;
};

const ClonePlanDropDown = forwardRef(
  (
    {
      selectOptions = [],
      changeDropdownPlansSelected,
      dropdownPlansSelected,
      disabled,
    }: ClonePlanDropDownProps,
    ref
  ) => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [tempDropdownPlans, setTempDropdownPlans] = useState<string[]>([]);
    const handleConfirm = () => {
      changeDropdownPlansSelected(tempDropdownPlans);
      setShowDropdown(false);
    };

    const handleSelect = (value: string[]) => {
      setTempDropdownPlans((prev: string[]) => prev.concat(value));
    };

    const onDeselect = (value: string[]) => {
      setTempDropdownPlans((prev: string[]) =>
        prev.filter((item: string) => item !== value?.toString())
      );
    };

    const onReset = () => {
      setTempDropdownPlans([]);
    };

    useImperativeHandle(ref, () => ({
      reset() {
        onReset();
      },
    }));

    return (
      <div className={styles.planDropdownWrapper}>
        <Select
          size="large"
          optionLabelProp="label"
          mode={'multiple'}
          value={!showDropdown ? dropdownPlansSelected : tempDropdownPlans}
          showSearch={false}
          dropdownClassName={styles.addingOptionDropdown}
          maxTagCount={'responsive'}
          showArrow={true}
          getPopupContainer={(trigger) => trigger.parentNode}
          open={showDropdown}
          onDropdownVisibleChange={setShowDropdown}
          disabled={disabled}
          tagRender={(tag) => {
            return (
              <>
                <p className={styles.tagHeading}>
                  {tag?.value !== null &&
                  tag?.value !==
                    (showDropdown
                      ? tempDropdownPlans?.[0]
                      : dropdownPlansSelected?.[0])
                    ? ','
                    : ''}{' '}
                  {tempDropdownPlans?.includes(tag?.value) &&
                    selectOptions?.find((item) => item?.value === tag?.value)
                      ?.label}
                </p>
              </>
            );
          }}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Row justify="center">
                <SubmitButton
                  type="primary"
                  className={styles.confirmButton}
                  onClick={handleConfirm}
                >
                  Okay
                </SubmitButton>
              </Row>
            </div>
          )}
          menuItemSelectedIcon={false}
          onSelect={(value: string[]) => {
            handleSelect(value);
            setShowDropdown(true);
          }}
          onDeselect={(value: string[]) => {
            onDeselect(value);
            setShowDropdown(true);
          }}
        >
          {selectOptions?.map((option: { value: string; label: string }) => (
            <Select.Option value={option.value} key={option.value}>
              <Checkbox
                checked={tempDropdownPlans?.includes(option.value)}
                className={styles.singleSelect}
              />
              <span className={styles.selectLabel}>{option.label}</span>
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  }
);

ClonePlanDropDown.displayName = 'ClonePlanDropDown';

export default ClonePlanDropDown;
