import { Col, Divider, Popover, Row } from 'antd';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import { currencyFormatterWithoutZeros } from 'util/commonUtil';
import { ICreditsDiscountsDescriptionItem } from 'modules/renewals/types/renewalsTypes';
import { DISCOUNT_DISCLAIMER_TXT } from 'modules/renewals/constants/renewalsConstants';

import { ReactComponent as IconInfo } from 'assets/images/icon-info.svg';
import styles from 'modules/renewals/components/MultipleCreditsDiscountsDescriptionModal/MultipleCreditsDiscountsDescriptionModal.module.less';

type Props = {
  data: {
    credits?: ICreditsDiscountsDescriptionItem[];
    additionalCredits?: ICreditsDiscountsDescriptionItem[];
    discounts?: ICreditsDiscountsDescriptionItem[];
  };
  isOpen: boolean;
  setIsOpen: Function;
};

const MultipleCreditsDiscountsDescriptionModal = ({
  data: { credits, additionalCredits, discounts },
  isOpen,
  setIsOpen,
}: Props) => {
  const getTotal = (arr: ICreditsDiscountsDescriptionItem[]): string => {
    return currencyFormatterWithoutZeros(
      arr?.map((item) => item.value).reduce((a, b) => a + b) || 0
    );
  };

  const getTitle = (item: ICreditsDiscountsDescriptionItem): string => {
    const value = currencyFormatterWithoutZeros(item.value);
    if (item.offerName) {
      if (!item.percentage) {
        return `${item.offerName} - ${value}`;
      } else {
        return `${item.offerName} - ${item.percentage}% (${value})`;
      }
    } else {
      return value;
    }
  };

  const getAdditionalSubTitle = (
    item: ICreditsDiscountsDescriptionItem
  ): string => {
    const prefix = item.type === 'CREDIT' ? 'Credit' : 'Cost';
    return `(ER ${prefix} ${currencyFormatterWithoutZeros(
      item?.erAmount ?? 0
    )}/ EE ${prefix} ${currencyFormatterWithoutZeros(item?.eeAmount ?? 0)})`;
  };

  const handleCancelBtnClick = (): void => {
    setIsOpen(false);
  };

  return (
    <ConfirmationDialog
      title="Credits & Discounts"
      visible={isOpen}
      closeModal={handleCancelBtnClick}
      confirmText={''}
      onConfirm={() => {}}
      cancelText="Close"
      modalClassName={styles.modalWrapper}
      isConfirmApplicable={false}
    >
      {credits && credits.length > 0 && (
        <>
          <Row justify="space-between">
            <Col flex="auto" className={styles.sectionHeader}>
              Credits (from Offers)
            </Col>
            <Col className={styles.totalValue}>{getTotal(credits)}</Col>
          </Row>
          <Divider />
          <div className={styles.itemSection}>
            {credits.map((item, idx) => (
              <div key={idx}>
                <div className={styles.itemHeader}>{getTitle(item)}</div>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        </>
      )}
      {discounts && discounts.length > 0 && (
        <>
          <Row justify="space-between" className={styles.discountsHeaderRow}>
            <Col flex="auto" className={styles.sectionHeader}>
              Discounts
            </Col>
            <Col className={styles.totalValue}>{getTotal(discounts)}</Col>
            <Popover
              content={DISCOUNT_DISCLAIMER_TXT}
              overlayClassName={`popoverInner recommendedtooltip  ${styles.discountsPopover}`}
            >
              <div className={styles.tooltipWrapper}>
                <IconInfo />
              </div>
            </Popover>
          </Row>
          <Divider />
          <div className={styles.itemSection}>
            {discounts.map((item, idx) => (
              <div key={idx}>
                <div className={styles.itemHeader}>{getTitle(item)}</div>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        </>
      )}
      {additionalCredits && additionalCredits.length > 0 && (
        <>
          <Row justify="space-between">
            <Col flex="auto" className={styles.sectionHeader}>
              Additional Credits
            </Col>
            <Col className={styles.totalValue}>
              {getTotal(additionalCredits)}
            </Col>
          </Row>
          <Divider />
          <div className={styles.itemSection}>
            {additionalCredits.map((item, idx) => (
              <div key={idx}>
                <div className={styles.itemHeader}>
                  {getTitle(item)}{' '}
                  <span className={styles.itemAdditionalSubtitle}>
                    {getAdditionalSubTitle(item)}
                  </span>
                </div>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        </>
      )}
    </ConfirmationDialog>
  );
};

export default MultipleCreditsDiscountsDescriptionModal;
