import { FC, useEffect, useRef, useState } from 'react';
import { Col, Form, FormInstance, Input, Row, Typography } from 'antd';
import InputForm from 'components/InputForm/InputForm';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import StateSelect from 'components/StateSelect/StateSelect';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import CheckboxSelect from 'components/CheckboxSelect/CheckboxSelect';

import { Location } from 'model/broker/Location';

import { EMPTY_MESSAGE } from 'modules/brokers/constants/brokerConstants';

import styles from './createBrokerLocationForm.module.less';

const { Text } = Typography;

type CreateBrokerLocationFormProps = {
  duplicateLocationFound?: boolean;
  form: FormInstance;
  onInputChange: any;
  expanded: boolean;
  isChecked: boolean;
  handleOnChange: () => void;
  onClickSave: () => void;
  onReset: () => void;
  isDirect: boolean;
  isEdit: boolean;
  isDefaultLocation: boolean;
  locationData: Location | null;
};

const CreateBrokerLocationForm: FC<CreateBrokerLocationFormProps> = (
  props: CreateBrokerLocationFormProps
) => {
  const {
    form,
    onInputChange,
    expanded,
    isChecked,
    handleOnChange,
    onClickSave,
    onReset,
    isDirect,
    isEdit,
    isDefaultLocation,
    locationData,
  } = props;
  const addressLineOneRef = useRef<Input>(null);
  const firstRowRef = useRef<HTMLDivElement>(null);
  const [hasFocus, setFocus] = useState(false);

  useEffect(() => {
    if (expanded) {
      const element = addressLineOneRef.current;
      if (element !== null) {
        setFocus(true);
      }
    }
  }, [expanded]);

  useEffect(() => {
    if (hasFocus && expanded) {
      const element = addressLineOneRef.current;
      if (element !== null) {
        element.focus();
      }
      const firstRowElement = firstRowRef.current;
      if (firstRowElement !== null) {
        firstRowElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hasFocus, expanded]);
  return (
    <InputForm
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      autoComplete="off"
      onValuesChange={onInputChange}
    >
      <Row ref={firstRowRef} gutter={48}>
        <Col span={12}>
          <Form.Item
            name="addressLine1"
            label="Address Line 1"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: EMPTY_MESSAGE,
                whitespace: true,
              },
            ]}
          >
            <Input
              ref={addressLineOneRef}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              data-cy="LocAddressLine1"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="addressLine2"
            label="Address Line 2"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input data-cy="LocAddressLine2" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={48}>
        <Col span={12}>
          <Form.Item
            name="city"
            label="City"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: EMPTY_MESSAGE,
                whitespace: true,
              },
            ]}
          >
            <Input data-cy="LocCity" />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Row gutter={48}>
            <Col span={10}>
              <Form.Item
                name="state"
                label="State"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: EMPTY_MESSAGE,
                  },
                ]}
              >
                <StateSelect dataCy="LocState" />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item
                name="zipCode"
                label="Zip"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: EMPTY_MESSAGE,
                    whitespace: true,
                  },
                ]}
              >
                <Input data-cy="LocZip" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={48}>
        <Col span={24}>
          <Form.Item
            name="name"
            label="Location Name"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: EMPTY_MESSAGE,
                whitespace: true,
              },
            ]}
          >
            <Input data-cy="LocName" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col>
          <CheckboxSelect
            checked={isChecked}
            onChange={handleOnChange}
            disabled={
              (isEdit && isDefaultLocation) ||
              (locationData !== null && locationData.primary && isEdit)
            }
          >
            <Text className={styles.checkboxText}>Make Primary Location</Text>
          </CheckboxSelect>
        </Col>
      </Row>

      {!isDirect && (
        <>
          <Row justify="center" className={styles.margins}>
            <Col>
              <SubmitButton onClick={onClickSave} dataCy="saveLocationBtn">
                Save Location
              </SubmitButton>
            </Col>
          </Row>
          <Row justify="center" className={styles.margins}>
            <Col>
              <CancelButton
                htmlType="button"
                onClick={onReset}
                block
                className={styles.cancelBtnStyle}
              >
                Cancel
              </CancelButton>
            </Col>
          </Row>
        </>
      )}
      {isDirect && (
        <>
          <Row justify="center" className={styles.margins}>
            <Col>
              <PageActionButton
                onClick={onClickSave}
                type="primary"
                className={styles.confirmButton}
                dataCy="saveLocationBtn"
              >
                Save Location
              </PageActionButton>
            </Col>
          </Row>
          <Row justify="center" className={styles.margins}>
            <Col>
              <CancelButton
                htmlType="button"
                onClick={onReset}
                block
                className={styles.cancelBtnStyle}
              >
                Cancel
              </CancelButton>
            </Col>
          </Row>
        </>
      )}
    </InputForm>
  );
};

export default CreateBrokerLocationForm;
