import { notification } from 'antd';
import axios from 'axios';

const LOCAL_BASE = 'http://localhost:6010';
const LOCAL_WS_BASE = 'ws://localhost:6010';
const LOCAL_ENVIRONMENT = 'local';
export const baseApi: string =
  (window as any)._serverConfig.apiUrl || LOCAL_BASE;
export const benguideUrl =
  (window as any)._serverConfig.benguideUrl ||
  process.env.REACT_APP_BENGUIDE_URL;
export const measurementID =
  (window as any)._serverConfig.measurementID ||
  process.env.REACT_APP_LOCAL_GA_KEY;
export const gtmID =
  (window as any)._serverConfig.gtmID || process.env.REACT_APP_LOCAL_GTM_KEY;

export const environment =
  (window as any)._serverConfig.environment || LOCAL_ENVIRONMENT;
export const wsBaseApi = (window as any)._serverConfig.apiUrl
  ? (window as any)._serverConfig.apiUrl.replace(/^https:\/\//i, 'wss://')
  : LOCAL_WS_BASE;
export const brokerChatBotEnabled =
  (window as any)._serverConfig.brokerChatBotEnabled ||
  process.env.REACT_APP_BROKER_CHATBOT_ENABLED;

export const PDF_DIST_URL = `${process.env.PUBLIC_URL}/pdfjs/pdf.worker.min.js`;
const api = {
  baseApi,
  wsBaseApi,
};

export const getPagingQueryString = (
  page: number,
  size: number,
  sort: string,
  query: string,
  searchKey?: string
) => {
  const queryStrings = [];
  if (page) {
    queryStrings.push(`_page=${page}`);
  }
  if (size) {
    queryStrings.push(`_size=${size}`);
  }
  if (sort) {
    queryStrings.push(`_sort=${sort}`);
  }
  if (query) {
    queryStrings.push(
      searchKey
        ? `${searchKey}=${encodeURIComponent(query)}`
        : `search-key=${encodeURIComponent(query)}`
    );
  }
  return queryStrings.join('&');
};

export default api;

export const CONTENT_TYPE_HEADER = 'Content-Type';
export const CONTENT_TYPE_MULTIPART_FORM = 'multipart/form-data';

export const configureAxios = () => {
  axios.defaults.withCredentials = true;
};

export function handleAPIError(error: any) {
  if (axios.isAxiosError(error) && error.response) {
    return error.response;
  } else {
    notification.error({ message: 'Network Error, Please try again later' });
    return { data: { code: 'network.error' } };
  }
}
