import { createContext, FC, ReactNode } from 'react';

export const NotificationContext = createContext<boolean>(false);

// this uses to show error message in text editor need to wrap with provider and pass boolen value show hide error

interface NotificationContextProviderProps {
  children?: ReactNode;
  isDisableSave: boolean;
}

export const NotificationContextProvider: FC<
  NotificationContextProviderProps
> = ({ children, isDisableSave }: NotificationContextProviderProps) => {
  return (
    <NotificationContext.Provider value={isDisableSave}>
      {children}
    </NotificationContext.Provider>
  );
};
