import React, {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { cloneDeep, isEmpty, sortBy } from 'lodash';
import { EventDataNode, TreeProps } from 'antd/lib/tree';
import { Col, Radio, Row, Select, Tree } from 'antd';
import { v4 as uuid } from 'uuid';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import AlertMessage from 'components/Alert/AlertMessage';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { MIME_TYPES } from 'util/fileUtil';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  fetchPlanYears,
  fetchDocuments,
  fetchEmployers,
  clearDocuments,
  clearEmployers,
  clearPlanYears,
  setNodes,
  checkIsDocumentPresent,
  uploadDocument,
} from 'modules/assistant/slices/planAssistanceModalSlice';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import { ReactComponent as IconDocument } from 'assets/documents/icon_file_Document.svg';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import {
  createNodeFromDocument,
  findAndAdd,
  findAndReplace,
  findAndUpdate,
  findHierarchy,
  findNode,
  getFileIconByExtension,
  transformDocumentsToNodes,
} from 'modules/assistant/utils/treeUtils';
import { ReactComponent as DropDownDarkArrow } from 'assets/images/dropdown-down-arrow.svg';
import { ReactComponent as IconAI } from 'assets/images/Rfp-ai-selected.svg';

import styles from './planAssistanceModal.module.less';

export type PlanAssistanceModalProps = {
  isOpen: boolean;
  onConfirm?: (result: {
    documentId: string;
    documentName: string;
    employerName: string;
    planYearId: string;
    planYearName: string;
    employerId: string;
  }) => void;
  onCancel?: () => void;
  organizationId?: string;
};

const SUPPORTED_MIME_TYPES = [
  MIME_TYPES.PDF,
  MIME_TYPES.DOCX,
  MIME_TYPES.DOC,
  MIME_TYPES.XLS,
  MIME_TYPES.XLSX,
] as string[];

const Icon = ({ fileName }: { fileName: any }) => {
  const extension = fileName.split('.').pop();
  const image = getFileIconByExtension(extension || '');
  return <img className={styles.documentImage} src={image} />;
};

/**
 * NOTE: There is no straightforward way to pass extra params than the ones allowed into the tree nodes.
 * This is an issue considering things like different node states. Therefore, this is managed by apending
 * That data to the key of the data node then 'popping' it off to read it
 */

const PlanAssistanceModal = forwardRef(
  (
    { isOpen, onCancel, onConfirm, organizationId }: PlanAssistanceModalProps,
    ref
  ) => {
    const dispatch = useAppDispatch();
    const {
      documentUpload: {
        data: uploadedDocument,
        status: {
          isLoading: isDocumentUploadLoading,
          isError: isDocumentUploadError,
        },
      },
      employers: {
        status: { isLoading: isEmployersLoading },
        data: employers,
      },
      planYears: {
        status: { isLoading: isPlanYearsLoading },
        data: planYears,
      },
      nodes,
    } = useAppSelector((state) => state.assistant.planAssistanceModal);
    const [uploadErrorMessage, setUploadErrorMessage] = useState<string>();
    const [selectedEmployer, setSelectedEmployer] = useState<string>();
    const [selectedPlanYear, setSelectedPlanYear] = useState<string>();
    const [selectedDocument, setSelectedDocument] = useState<string>();
    const [loadedNodeKeys, setLoadedNodeKeys] = useState<string[]>([]);
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [rootDocumentId, setRootDocumentId] = useState<string>(); // The root document of a given plan-year

    const isEmployerSelected = !isEmpty(selectedEmployer);
    const deepestExpandedFolder =
      expandedKeys.length > 0
        ? expandedKeys[expandedKeys.length - 1].split('/')[0]
        : rootDocumentId;
    const isConfirmDisabled =
      isEmpty(selectedEmployer) ||
      isEmpty(selectedPlanYear) ||
      isEmpty(selectedDocument);
    const isUploadDisabled =
      !isEmployerSelected || isDocumentUploadLoading || isEmpty(expandedKeys);
    const sortedEmployers = sortBy(employers, 'name');

    // On initial load
    useEffect(() => {
      reset();
      if (organizationId && isOpen) {
        dispatch(fetchEmployers(organizationId));
      }
      // If we add 'reset' to the deps this useEffect will run on every load
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, dispatch, isOpen]);

    // This update needs to be done in a use effect because doing it inside
    // async handleUploadFile() causes issues when the file upload is done due to state
    // the fn is reading being out of date.
    useEffect(() => {
      if (!isDocumentUploadLoading && uploadedDocument) {
        const clonedNodes = cloneDeep(nodes);
        clonedNodes.map((node) =>
          findAndReplace(
            node,
            uploadedDocument?.tempId!,
            createNodeFromDocument(uploadedDocument!)
          )
        );
        dispatch(setNodes(clonedNodes));
        setSelectedDocument(uploadedDocument!.documentId);
      }
      // If we add 'nodes' to the deps there will be an infinite loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedDocument, isDocumentUploadLoading, dispatch]);

    useEffect(() => {
      if (isDocumentUploadError) {
        setUploadErrorMessage('Upload failed. Please try again.');
      }
    }, [isDocumentUploadError]);

    const reset = useCallback(() => {
      dispatch(clearEmployers());
      dispatch(clearDocuments());
      dispatch(clearPlanYears());
      dispatch(setNodes([]));
      setSelectedDocument(undefined);
      setSelectedEmployer(undefined);
      setSelectedPlanYear(undefined);
      setLoadedNodeKeys([]);
      setExpandedKeys([]);
      setUploadErrorMessage(undefined);
    }, [dispatch]);

    const initializeTree = async (employerId: string, planYearId: string) => {
      setExpandedKeys([]);
      setLoadedNodeKeys([]);
      dispatch(clearDocuments());
      setSelectedDocument(undefined);
      const { content, parentDocument } = await dispatch(
        fetchDocuments({
          employerId: employerId!,
          planYearIds: planYearId!,
        })
      );

      const filterContent = content?.filter(
        (doc) => doc.name === 'Benefits' && !doc.file
      );

      setRootDocumentId(parentDocument?.documentId);
      dispatch(setNodes(transformDocumentsToNodes(filterContent)));
    };

    const handleLoadNode = async ({ key }: EventDataNode) => {
      // This needs to be done as we append extra data to the key
      const sanitizedKey = (key as string).split('/')[0];
      const { content } = await dispatch(
        fetchDocuments({
          employerId: selectedEmployer!,
          planYearIds: selectedPlanYear!,
          documentId: sanitizedKey,
        })
      );
      const transformedContent = transformDocumentsToNodes(content);
      const clonedNodes = cloneDeep(nodes);
      clonedNodes.map((node) =>
        findAndUpdate(node, key as string, transformedContent)
      );
      setLoadedNodeKeys((prev) => [...prev, key as string]);
      dispatch(setNodes(clonedNodes));
    };

    const handleExpandNode: TreeProps['onExpand'] = (_, { expanded, node }) => {
      const hierarchy: (string | number)[] = nodes
        ?.map((n) => findHierarchy(n, String(node.key)))
        .flat();

      if (hierarchy) {
        if (!expanded) {
          hierarchy.pop();
        }
      }
      setExpandedKeys(hierarchy as string[]);
    };

    const handleNodeClick = (key: string) => {
      setSelectedDocument((prev) => (prev === key ? undefined : key));
    };

    const handleUploadInputClick = (
      event: React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => {
      const element = event.target as HTMLInputElement;
      element.value = '';
    };

    const handleUploadFile = async ({ target }: any) => {
      const file = target.files[0] as File;
      if (!file) {
        return;
      }

      if (!SUPPORTED_MIME_TYPES.includes(file.type)) {
        setUploadErrorMessage(
          'Invalid file type uploaded. Submitted files must be in the following formats: pdf, docx, doc, xls, xlsx.'
        );
        return;
      }

      // Max SBC file size is 100MB
      if (file.size / (1024 * 1024) > 100) {
        setUploadErrorMessage(
          'This file is over the file size limit of 100MB. Please try upload again.'
        );
        return;
      }

      if (selectedEmployer && deepestExpandedFolder) {
        const isDuplicate = await dispatch(
          checkIsDocumentPresent({
            employerId: selectedEmployer,
            parentDocumentId: deepestExpandedFolder,
            fileName: file.name,
          })
        );

        if (!isDuplicate) {
          const tempKey = uuid() + '/processing';
          const tempNode = createNodeFromDocument({
            documentId: tempKey,
            name: file.name,
            file: true,
          });
          const clonedNodes = cloneDeep(nodes);
          if (rootDocumentId != deepestExpandedFolder) {
            clonedNodes.map((node) =>
              findAndAdd(node, deepestExpandedFolder, [tempNode])
            );
          } else {
            clonedNodes.push(tempNode);
          }
          dispatch(setNodes(clonedNodes));
          dispatch(
            uploadDocument({
              tempId: tempKey,
              employerId: selectedEmployer,
              file,
              isDuplicate: false,
              parentDocumentId: deepestExpandedFolder,
            })
          );
        } else {
          setUploadErrorMessage('This file name already exists.');
        }
      }
    };

    const handleEmployerSelectChange = async (value: string) => {
      // We shouldn't clear the selectedPlanYear here before we load the plan years
      // as it cause a flash/blink on the UI
      const data = await dispatch(fetchPlanYears(value));
      const currentPlanyearId = data.filter((py) => py.current)?.[0].id;
      setSelectedEmployer(value);
      setSelectedPlanYear(currentPlanyearId);
      initializeTree(value, currentPlanyearId);
    };

    const handleCloseErrorAlert = () => {
      setUploadErrorMessage('');
    };

    const handleConfirmClick = () => {
      const node = nodes
        .map((n) => findNode(n, selectedDocument!))
        .filter((n) => n)[0];
      const documentName = node?.title;
      const employer = employers?.find((emp) => emp.id === selectedEmployer);
      const employerName = employer?.name;
      const planYear = planYears?.find((py) => py.id === selectedPlanYear);
      const planYearName = planYear?.name;

      onConfirm?.({
        documentId: selectedDocument!,
        documentName: documentName! as string,
        employerId: selectedEmployer!,
        employerName: employerName!,
        planYearId: selectedPlanYear!,
        planYearName: planYearName!,
      });
    };

    useImperativeHandle(
      ref,
      () => ({
        reset: reset,
      }),
      [reset]
    );

    return (
      <ConfirmationDialog
        centered
        isCancelLink
        isCancelApplicable
        destroyOnClose
        visible={isOpen}
        icon={<IconAI />}
        title="Plan Assistance"
        confirmText="Continue"
        onConfirm={handleConfirmClick}
        onCancel={() => {
          reset();
          onCancel?.();
        }}
        closeModal={() => {
          reset();
          onCancel?.();
        }}
        disableConfirmButton={isConfirmDisabled}
        cancelText="Cancel"
        confirmBtnFullWidth
        maskClosable={false}
      >
        <Col>
          <p>
            The AI Assistant is available to assist with any inquiries regarding
            a particular employer&apos;s plans and documents. Begin by selecting
            the employer and plan document from the options below.
          </p>
          <div className={styles.label}>Employer</div>
          <Select
            showSearch
            value={selectedEmployer}
            disabled={isEmployersLoading}
            loading={isEmployersLoading}
            size="large"
            className={styles.employerSelect}
            onChange={handleEmployerSelectChange}
            optionFilterProp="children"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            {sortedEmployers?.map((employer) => (
              <Select.Option key={employer.id} value={employer.id}>
                {employer.name}
              </Select.Option>
            ))}
          </Select>
          <p />
          <div className={styles.alertWrapper}>
            {uploadErrorMessage && (
              <AlertMessage
                type="error"
                closeAlert={handleCloseErrorAlert}
                message={uploadErrorMessage}
              />
            )}
          </div>
          <Row
            className={styles.filterRow}
            align="middle"
            justify="space-between"
            wrap={false}
          >
            <Row wrap={false}>
              <div className={styles.label}>Plan Document:</div>
              <SingleSelectFilter
                isOverflowHide
                data={(planYears || []).map((planYear) => ({
                  value: planYear.id,
                  label: planYear.name,
                }))}
                placeholder={'Plan Year'}
                showSearch={false}
                setFilterSelectedValue={(value: any) => {
                  setSelectedPlanYear(value);
                  initializeTree(selectedEmployer!, value);
                }}
                className={styles.planYearSelect}
                selectedValue={selectedPlanYear!}
                defaultValue={selectedPlanYear!}
                applicableIcon={<DropDownDarkArrow />}
                inlineDropdown={true}
                isDisabled={isPlanYearsLoading || !isEmployerSelected}
                width={'200px'}
              />
            </Row>
            <LinkButton
              icon={<IconDocument width={24} />}
              disabled={isUploadDisabled}
              enableDisableStyle={true}
            >
              Add New Document
              <input
                type="file"
                className={styles.uploadFileInput}
                onChange={handleUploadFile}
                onClick={handleUploadInputClick}
              />
            </LinkButton>
          </Row>
          <Tree
            showIcon
            blockNode
            treeData={nodes}
            className={styles.tree}
            checkable={false}
            selectable={false}
            multiple={false}
            height={250}
            // Without the below prop, the tree won't reload stuff it has already loaded once leading to a bug
            loadedKeys={loadedNodeKeys}
            loadData={handleLoadNode}
            // Without below prop we wont be able to restrict expansion to a single hierarchy at a time
            expandedKeys={expandedKeys}
            onExpand={handleExpandNode}
            titleRender={({ key, title }) => {
              const isFolder = (key as string).split('/').pop() === 'folder';
              const isProcessing =
                (key as string).split('/').pop() === 'processing';
              return (
                <Fragment key={key}>
                  {!isFolder && (
                    <Radio
                      className={styles.radio}
                      disabled={isProcessing}
                      checked={selectedDocument === key}
                      onChange={() => handleNodeClick(key as string)}
                    />
                  )}
                  <Icon fileName={isFolder ? 'folder' : title} />
                  <span className={styles.titleRow}>
                    <OverflowPopover popoverZIndex={99999}>
                      {isFolder ? <b>{title}</b> : title}
                    </OverflowPopover>
                    {isProcessing && (
                      <span className={styles.tag}>Processing...</span>
                    )}
                  </span>
                </Fragment>
              );
            }}
          />
        </Col>
      </ConfirmationDialog>
    );
  }
);

PlanAssistanceModal.displayName = 'PlanAssistanceModal';

export default PlanAssistanceModal;
