import { FC, useEffect } from 'react';

import { Col, Collapse, notification, Row } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import isUndefined from 'lodash/isUndefined';
import { useParams } from 'react-router-dom';
import { useNavContext } from 'hooks/useNavContext';
import { useAppDispatch } from 'hooks/redux';
import { PLAN_NOTIFICATION_KEY } from 'constants/benguideCollaborationConstants';
import EditMedicalPlanModal from 'modules/plans/medical/components/EditMedicalPlanModal/EditMedicalPlanModal';
import EditDentalPlanModal from 'modules/plans/dental/components/EditDentalPlanModal/EditDentalPlanModal';
import EditVisionPlanModal from 'modules/plans/vision/components/EditVisionPlanModal/EditVisionPlanModal';
import OverviewHeader from 'modules/plans/components/OverviewPage/OverviewHeader/OverviewHeader';
import InfoLabel from 'components/InfoLabel/InfoLabel';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';
import { BenefitCategory } from 'constants/commonConstants';
import { DentalPlan } from 'model/plans/DentalPlan';
import { VisionPlan } from 'model/plans/VisionPlan';
import MedicalPlan from 'model/plans/MedicalPlan';
import PlanInfoHeaderLabel from 'modules/employers/components/PlanInfoHeaderLabel/PlanInfoHeaderLabel';
import {
  EditMdvTypes,
  FourTier,
  FourTierOrder,
  NTier,
  NTierOrder,
  RateType,
} from 'modules/plans/constants';
import { findMedicalPlanById } from 'modules/plans/slices/medicalPlanSlice';
import { findDentalPlanById } from 'modules/plans/slices/dentalPlanSlice';
import { findVisionPlanById } from 'modules/plans/slices/visionPlanSlice';
import { displayNumberWithDecimalValue } from 'modules/plans/utils';

import styles from './premiumsOverview.module.less';

type PremiumsOverviewProps = {
  heading: string;
  plan?: DentalPlan | VisionPlan | MedicalPlan;
  editedPlan?: DentalPlan | VisionPlan | MedicalPlan;
  benefitKind?: string;
  fetchPlanWhenUpdated?: any;
  disableEditButton?: boolean;
  viewPlanPremiumModal?: boolean;
  setViewPlanPremiumModal?: (value: boolean) => void;
};

type BenefitClassRenderSet = {
  benefitClass: string;
  renderData: RenderDataSet[];
  type: string;
};

type RenderDataSet = {
  tierName: string;
  employeeCost: string;
  employerCost: string;
  totalCost: string;
};

const PremiumsOverview: FC<PremiumsOverviewProps> = (props) => {
  const {
    heading,
    plan,
    benefitKind,
    fetchPlanWhenUpdated,
    disableEditButton,
    editedPlan,
    setViewPlanPremiumModal,
    viewPlanPremiumModal,
  } = props;

  const { Panel } = Collapse;

  const params = useParams();
  const { employerId, brokerId } = useNavContext();
  const dispatch = useAppDispatch();

  const sortingArray = ['EE', 'ES', 'EC', 'EF'];

  useEffect(() => {
    if (employerId && brokerId && params.planId && !plan) {
      switch (benefitKind) {
        case BenefitCategory.MEDICAL.value:
          dispatch(findMedicalPlanById(params.planId));
          break;
        case BenefitCategory.DENTAL.value:
          dispatch(findDentalPlanById(params.planId));
          break;
        case BenefitCategory.VISION.value:
          dispatch(findVisionPlanById(params.planId));
          break;
      }
    }
  }, [dispatch, employerId, brokerId, params.planId, benefitKind, plan]);

  const fetchPlanOnNotificationReceived = () => {
    if (params.planId) {
      switch (benefitKind) {
        case BenefitCategory.MEDICAL.value:
          dispatch(findMedicalPlanById(params.planId));
          break;
        case BenefitCategory.DENTAL.value:
          dispatch(findDentalPlanById(params.planId));
          break;
        case BenefitCategory.VISION.value:
          dispatch(findVisionPlanById(params.planId));
          break;
      }
    }
  };

  const editPlan = () => {
    setViewPlanPremiumModal && setViewPlanPremiumModal(true);
    if (fetchPlanWhenUpdated) {
      fetchPlanOnNotificationReceived();
      notification.close(PLAN_NOTIFICATION_KEY);
    }
  };

  const onClose = () => {
    setViewPlanPremiumModal && setViewPlanPremiumModal(false);
    if (params.planId && !fetchPlanWhenUpdated) {
      switch (benefitKind) {
        case BenefitCategory.MEDICAL.value:
          dispatch(findMedicalPlanById(params.planId));
          break;
        case BenefitCategory.DENTAL.value:
          dispatch(findDentalPlanById(params.planId));
          break;
        case BenefitCategory.VISION.value:
          dispatch(findVisionPlanById(params.planId));
          break;
      }
    }
  };

  const getPremiumType = (benGroups: string[]) => {
    if (benGroups && plan && plan.rates) {
      return formatPremiumTypeName(
        !isEmpty(plan.rates) &&
          Object.keys(plan.rates).includes(benGroups[0]) &&
          plan.rates?.[benGroups[0]].type
      );
    }
  };

  const getRatesToRender = (
    plan: MedicalPlan | DentalPlan | VisionPlan | undefined
  ): BenefitClassRenderSet[] => {
    if (plan && plan.rates) {
      const finalDataArray: BenefitClassRenderSet[] = [];
      Object.keys(plan.rates).forEach(function (benefitClass) {
        const dataArray: RenderDataSet[] = [];
        Object.keys(plan.rates[benefitClass]).forEach(function (rate) {
          if (
            rate === RateType.FOUR_TIER.fieldName ||
            rate === RateType.N_TIER.fieldName
          ) {
            const data = plan.rates[benefitClass][rate]['contributions'];

            data.forEach((t: RenderDataSet) => {
              dataArray.push(t);
            });
          }
        });

        dataArray.sort(function (data1, data2) {
          return (
            sortingArray.indexOf(data1.tierName) -
            sortingArray.indexOf(data2.tierName)
          );
        });

        const finalData = {
          benefitClass: benefitClass,
          renderData: dataArray,
          type: plan.rates[benefitClass].type,
        } as BenefitClassRenderSet;
        finalDataArray.push(finalData);
      });
      return finalDataArray;
    }
    return [];
  };

  const formatPremiumTypeName = (typeName: string) => {
    if (typeName === RateType.FOUR_TIER.value) {
      return 'Standard 4 Tier';
    } else if (typeName === RateType.N_TIER.value) {
      return 'N Tier';
    } else if (typeName === RateType.AGE_BAND.value) {
      return 'Age Band';
    }
    return '';
  };

  const formatTierLabelName = (tierName: string, type: string) => {
    if (type === RateType.N_TIER.value) {
      return NTier[tierName].label;
    }
    if (type === RateType.FOUR_TIER.value) {
      return FourTier[tierName].label;
    }
    return '';
  };

  return (
    <div>
      <Row>
        <OverviewHeader
          title={heading}
          onEditClick={() => {
            editPlan();
          }}
          disabled={disableEditButton}
        />
      </Row>
      <div className={styles.tableWrapper}>
        <InfoLabelSection
          className={styles.rateType}
          labelText="Premium Type"
          value={plan?.rates ? getPremiumType(plan?.groups) : '-'}
        />
        {plan?.rates &&
          plan?.groups?.length > 1 &&
          !Object.values(plan?.rates || {}).some(
            (rate: any) => rate.type === RateType.AGE_BAND.value
          ) && (
            <InfoLabelSection
              labelText={'Same contributions for All Benefit Classes?'}
              value={plan?.hasSameContributions ? 'Yes' : 'No'}
            />
          )}
        {plan?.rates && getPremiumType(plan?.groups) !== 'Age Band' && (
          <div className={styles.itemWrapper}>
            {plan?.groups.map((benClass, index) => {
              return (
                <div className={styles.panelWrapper} key={index}>
                  <Collapse
                    expandIconPosition={'right'}
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <RightOutlined rotate={isActive ? -90 : 90} />
                    )}
                  >
                    <Panel header={benClass} key={index} showArrow={true}>
                      <div className={styles.tableWrapper}>
                        <Row>
                          <Col span={18} className={styles.mainText}>
                            <PlanInfoHeaderLabel
                              labelHeaderText={[
                                'EE Contribution',
                                'ER Contribution',
                                'Total Premiums',
                              ]}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={18} className={styles.subText}>
                            <PlanInfoHeaderLabel
                              labelHeaderText={[
                                'Monthly',
                                'Monthly',
                                'Monthly',
                              ]}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={18} className={styles.tableText}>
                            {getRatesToRender(plan).map(
                              (val: BenefitClassRenderSet) => {
                                if (val.benefitClass === benClass) {
                                  const orderedData =
                                    val.type === RateType.FOUR_TIER.value
                                      ? orderBy(
                                          val.renderData,
                                          (val) => FourTierOrder[val.tierName]
                                        )
                                      : val.type === RateType.N_TIER.value
                                      ? orderBy(
                                          val.renderData,
                                          (val) => NTierOrder[val.tierName]
                                        )
                                      : val.renderData;

                                  return orderedData.map(
                                    (data: RenderDataSet, i) => {
                                      return (
                                        <InfoLabel
                                          labelText={formatTierLabelName(
                                            data.tierName,
                                            val.type
                                          )}
                                          key={i}
                                          value={
                                            !isUndefined(data.employeeCost)
                                              ? `$${displayNumberWithDecimalValue(
                                                  data.employeeCost
                                                )}`
                                              : '-'
                                          }
                                          extraColValue1={
                                            !isUndefined(data.employerCost)
                                              ? `$${displayNumberWithDecimalValue(
                                                  data.employerCost
                                                )}`
                                              : '-'
                                          }
                                          extraColValue2={
                                            !isUndefined(data.totalCost)
                                              ? `$${displayNumberWithDecimalValue(
                                                  data.totalCost
                                                )}`
                                              : '-'
                                          }
                                        />
                                      );
                                    }
                                  );
                                } else {
                                  return <></>;
                                }
                              }
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {benefitKind === BenefitCategory.MEDICAL.value &&
        viewPlanPremiumModal && (
          <EditMedicalPlanModal
            plan={editedPlan}
            onClose={onClose}
            isModalVisible={viewPlanPremiumModal}
            editType={EditMdvTypes.RATES}
            setViewModal={() => setViewPlanPremiumModal}
          />
        )}
      {benefitKind === BenefitCategory.DENTAL.value && viewPlanPremiumModal && (
        <EditDentalPlanModal
          plan={editedPlan}
          onClose={onClose}
          isModalVisible={viewPlanPremiumModal}
          editType={EditMdvTypes.RATES}
          setViewModal={() => setViewPlanPremiumModal}
        />
      )}
      {benefitKind === BenefitCategory.VISION.value && viewPlanPremiumModal && (
        <EditVisionPlanModal
          plan={editedPlan}
          onClose={onClose}
          isModalVisible={viewPlanPremiumModal}
          editType={EditMdvTypes.RATES}
          setViewModal={() => setViewPlanPremiumModal}
        />
      )}
    </div>
  );
};

export default PremiumsOverview;
