import { FC } from 'react';

import { Col, Row } from 'antd';
import Modal, { ModalProps } from 'antd/lib/modal/Modal';

import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';

import styles from './alertDialog.module.less';

interface AlertDialogProps extends ModalProps {
  closeModal: () => void | boolean;
  onCancel: () => void;
  footerEmpty?: boolean;
}

const AlertDialog: FC<AlertDialogProps> = (props) => {
  const { title, children, visible, onCancel, footerEmpty = false } = props;

  const headerTitle = <span className={styles.alertTitle}>{title}</span>;

  const footerComponent = footerEmpty ? (
    ''
  ) : (
    <Row>
      <Col span={24}>
        <PageActionButton className={styles.cancelButton} onClick={onCancel}>
          Close
        </PageActionButton>
      </Col>
    </Row>
  );

  return (
    <Modal
      title={headerTitle}
      visible={visible}
      wrapClassName={styles.alertContainer}
      footer={footerComponent}
    >
      {children}
    </Modal>
  );
};

export default AlertDialog;
