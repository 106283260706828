import {
  ChangeEvent,
  FC,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import {
  Col,
  Divider,
  Menu,
  Row,
  notification,
  List,
  Spin,
  Typography,
} from 'antd';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import { loginTypes } from 'constants/authConstants';

import AddBrokerLocation from 'modules/brokers/pages/AddBrokerLocation/AddBrokerLocation';
import RemoveBrokerLocation from 'modules/brokers/pages/RemoveBrokerLocation/RemoveBrokerLocation';
import { useGetLocationsQuery } from 'api/featureControl';

import {
  getBrokerLocationList,
  clearBrokerLocationDeletion,
  getPaginatedBrokerLocationList,
  clearLocationFind,
  findLocationById,
  fetchBrokerLocationSummary,
} from 'modules/brokers/slices/brokerLocationSlice';
import DataTable from 'components/DataTable/DataTable';
import { DataColumn } from 'components/DataTable/DataColumn';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import SearchBar from 'components/SearchBar/SearchBar';
import OutlinedText from 'components/OutlinedText/OutlinedText';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import IconSuccess from 'assets/images/checkmark.svg';
import { ReactComponent as VectorLeft } from 'assets/images/vector-left-black.svg';

import InfoLabel from 'components/InfoLabel/InfoLabel';
import { Location } from 'model/broker/Location';
import TablePagination from 'model/TablePagination';
import EmployerLocation from 'model/EmployerLocation';
import {
  BASIC_INFO_TAB,
  BROKER_LOCATIONS_TAB,
} from 'modules/employers/constants/employerConstants';

import {
  convertPaginationConfig,
  DEBOUNCE_WAIT_TIME_SEARCH,
} from 'util/commonUtil';
import { getStateAbbreviation } from 'util/stringUtil';
import { IndividualSubTypes } from 'constants/commonConstants';
import styles from './locationList.module.less';

const convertLocationsForTableRender = (locations: Location[]): any[] => {
  return (
    locations?.map((location) => ({
      location: location,
      name: location.name,
    })) || []
  );
};

type LocationListPropTypes = {
  isModalOpen: boolean;
  setIsModalOpen: Function;
  activeTabKey: string;
  setShowAddLocationButton: Function;
};

const LocationList: FC<LocationListPropTypes> = (
  props: LocationListPropTypes
) => {
  const {
    isModalOpen,
    setIsModalOpen,
    activeTabKey,
    setShowAddLocationButton,
  } = props;
  const debounceLoadData = useMemo(
    () =>
      debounce(
        (config) => setPaginationConfig(config),
        DEBOUNCE_WAIT_TIME_SEARCH
      ),
    []
  );
  const [searchText, setSearchText] = useState<string>('');
  const [location, setLocation] = useState<Location | null>(null);
  const [filterdLocations, setFilteredLocation] = useState<
    Array<EmployerLocation>
  >([]);
  const [removableLocationId, setRemovableLocationId] = useState<string>('');
  const [visibleConfirmation, setVisibleConfirmation] =
    useState<boolean>(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'location',
      field: 'location',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText,
    },
    filters: {},
  });
  const [visibleConfirmationClosePopup, setVisibleConfirmationClosePopup] =
    useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [isLocationInfoOpen, setIsLocationInfoOpen] = useState(false);
  const [filteredEmployers, setFilteredEmployers] = useState<string[]>([]);
  const [filteredAdmins, setFilteredAdmins] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const brokerLocationStatus = useAppSelector(
    (state) => state.brokers.brokerLocations
  );
  const brokerLocations = useAppSelector(
    (state) => state.brokers.brokerLocations.brokerLocations
  );
  const brokerLocationList = useAppSelector(
    (state) => state.brokers.brokerLocations.brokerLocationList
  );
  const inProgress = useAppSelector(
    (state) => state.brokers.brokerLocations.inProgress
  );
  const userType = useAppSelector(
    (state) => state.auth.auth.appBootupInfo?.type
  );
  const appBootupInfo = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );

  const { content, metadata } = brokerLocationList || {};
  const { total } = metadata;
  const { filterInfo } = paginationConfig;
  const { limit } = filterInfo || {};
  const { brokerId } = useNavContext();
  const [deletingLocation, setDeletingLocation] = useState<string>('');
  const {
    data: locationRfpStatus,
    isLoading: isLocationRfpStatusLoading,
    refetch: refetchLocationRfpStatus,
  } = useGetLocationsQuery({
    organizationId: brokerId,
    key: 'RFP',
  });

  const refreshLocationsList = useCallback(() => {
    if (brokerId) {
      dispatch(
        getPaginatedBrokerLocationList(
          convertPaginationConfig(paginationConfig),
          brokerId
        )
      );
    }
  }, [dispatch, brokerId, paginationConfig]);

  useEffect(() => {
    if (brokerId && location) {
      dispatch(fetchBrokerLocationSummary(location.id, brokerId));
    }
  }, [dispatch, brokerId, location]);

  useEffect(() => {
    if (brokerLocationStatus.fetchSummarySucceeded) {
      setFilteredEmployers(
        brokerLocationStatus.brokerLocationSummary.employerNames
      );
      setFilteredAdmins(
        brokerLocationStatus.brokerLocationSummary.brokerAdminNames
      );
    }
  }, [
    brokerLocationStatus.fetchSummarySucceeded,
    brokerLocationStatus.brokerLocationSummary.brokerAdminNames,
    brokerLocationStatus.brokerLocationSummary.employerNames,
  ]);

  useEffect(() => {
    if (brokerId) {
      dispatch(getBrokerLocationList(brokerId));
    }
  }, [dispatch, brokerId]);

  useEffect(() => {
    refreshLocationsList();
    refetchLocationRfpStatus();
  }, [refreshLocationsList, refetchLocationRfpStatus]);

  useEffect(() => {
    if (activeTabKey !== BROKER_LOCATIONS_TAB) {
      refreshLocationsList();
      setIsLocationInfoOpen(false);
      if (
        appBootupInfo?.individualSubType === IndividualSubTypes.BROKER_USER &&
        activeTabKey !== BASIC_INFO_TAB
      ) {
        setShowAddLocationButton(false);
      } else {
        setShowAddLocationButton(true);
      }
      setLocation(null);
    }
  }, [
    activeTabKey,
    isLocationInfoOpen,
    setShowAddLocationButton,
    refreshLocationsList,
    appBootupInfo?.individualSubType,
  ]);

  useEffect(() => {
    if (
      isLocationInfoOpen &&
      !brokerLocationStatus.locationFindInProgress &&
      !isEmpty(brokerLocationStatus.brokerLocationObj) &&
      brokerLocationStatus.error === null
    ) {
      if (!isModalOpen) {
        setLocation(brokerLocationStatus.brokerLocationObj);
        setVisibleConfirmationClosePopup(false);
        dispatch(clearLocationFind());
      }
    }
  }, [
    dispatch,
    brokerLocationStatus?.locationFindInProgress,
    brokerLocationStatus?.brokerLocationObj,
    brokerLocationStatus?.error,
    isLocationInfoOpen,
    isModalOpen,
  ]);

  useEffect(() => {
    if (isModalOpen) {
      setVisibleConfirmationClosePopup(false);
    }
  }, [setVisibleConfirmationClosePopup, isModalOpen]);

  useEffect(() => {
    if (
      !brokerLocationStatus.deleteInProgress &&
      brokerLocationStatus.deleteCompleted
    ) {
      setVisible(true);
      dispatch(clearBrokerLocationDeletion());
      setLocation(null);
      if (brokerId) {
        dispatch(getBrokerLocationList(brokerId));
        dispatch(
          getPaginatedBrokerLocationList(
            convertPaginationConfig(paginationConfig),
            brokerId
          )
        );
      }
      if (brokerLocationStatus.error === null) {
        setAlertMessage({
          type: 'success',
          message: 'Location has been removed.',
        });
      } else {
        setAlertMessage({
          type: 'error',
          message: 'Location delete and reassign failed.',
        });
      }
    }
  }, [
    dispatch,
    brokerLocationStatus.deleteInProgress,
    brokerLocationStatus.deleteCompleted,
    brokerLocationStatus.error,
    brokerId,
    paginationConfig,
  ]);

  const locationUpdated = () => {
    if (location?.id) {
      dispatch(findLocationById(location.id));
    }
    notification.open({
      message: 'Successfully Updated',
      description: 'Location has been successfully updated',
      placement: 'topRight',
      duration: 3,
      className: styles.notification,
      closeIcon: <></>,
      key: 'locationUpdated',
      icon: (
        <img className={styles.notificationIcon} src={IconSuccess} alt="" />
      ),
      onClick: () => notification.close('locationUpdated'),
    });
  };

  const locationCreated = () => {
    refreshLocationsList();
    setAlertMessage({
      type: 'success',
      message: 'Location has been successfully added',
    });
    setVisible(true);
  };

  const locationTableColumns: DataColumn[] = [
    {
      title: 'LOCATION',
      dataIndex: 'location',
      key: 'location',
      width: '85%',
      align: 'left',
      ellipsis: {
        showTitle: false,
      },
      sorter: (a: any, b: any) => 0, // this sort is done by backend
      defaultSortOrder: 'ascend',
      render: (location) => (
        <span
          className={styles.nameColStyle}
          onClick={() => goToLocationInfo(location)}
        >
          <OverflowPopover
            popoverContent={location.name}
            maxWidth={location.primary ? '500px' : '600px'}
          >
            {location.name}
          </OverflowPopover>
          {location.primary && <OutlinedText text="PRIMARY LOCATION" />}
        </span>
      ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'location',
      key: 'location',
      width: '15%',
      render: (location) => (
        <SelectDropdown overlay={locationActionsMenu(location)} />
      ),
      align: 'center',
    },
  ];

  const locationActionsMenu = (location: Location) => {
    return (
      <Menu>
        <Menu.Item key="view" onClick={() => goToLocationInfo(location)}>
          View
        </Menu.Item>
        {!location.primary &&
          appBootupInfo?.individualSubType !==
            IndividualSubTypes.BROKER_ADMIN &&
          appBootupInfo?.individualSubType !== IndividualSubTypes.OPS_ADMIN &&
          appBootupInfo?.individualSubType !==
            IndividualSubTypes.IMPLEMENTATION_ADMIN &&
          appBootupInfo?.individualSubType !==
            IndividualSubTypes.BROKER_USER && (
            <Menu.Item
              key="delete"
              onClick={() => handleDelete(location)}
              disabled={isLocationRfpStatusLoading}
            >
              Remove
            </Menu.Item>
          )}
      </Menu>
    );
  };

  const confirmCloseModal = () => {
    toggleConfirmationPopup();
    setIsModalOpen(false);
  };

  const toggleConfirmationPopup = () => {
    setVisibleConfirmationClosePopup(!visibleConfirmationClosePopup);
  };

  const closeLocationModal = () => {
    toggleConfirmationPopup();
  };

  const getData = (filters: TablePagination) => {
    if (searchText) {
      const pageInfo = {
        ...filters,
        filterInfo: { ...filters.filterInfo, searchText },
      };
      setPaginationConfig(pageInfo);
    } else {
      setPaginationConfig(filters);
    }
    if (brokerId) {
      dispatch(
        getPaginatedBrokerLocationList(
          convertPaginationConfig(paginationConfig),
          brokerId
        )
      );
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.trim();
    setSearchText(searchText);
    const pageInfo = {
      ...paginationConfig,
      filterInfo: { ...paginationConfig.filterInfo, searchText },
    };
    setPaginationConfig(pageInfo);
    debounceLoadData(pageInfo);
  };

  const handleDelete = (location: Location) => {
    closeAlert();
    const filterByPreloadLocations = brokerLocations.employerLocations
      .filter((loc) => loc.locationId !== location.id)
      .map((loc) => {
        const isRfpEnabled = locationRfpStatus?.locationFeatures.some(
          (rfpLoc: any) => {
            return rfpLoc.id === loc.locationId && rfpLoc.enabled;
          }
        );
        return { ...loc, isRfpEnabled };
      });
    const removeLocationName = brokerLocations.employerLocations.find((loc) => {
      return loc.locationId === location.id;
    })?.locationName;
    setFilteredLocation(filterByPreloadLocations);
    setDeletingLocation(removeLocationName ?? '');
    setRemovableLocationId(location.id);
    setVisibleConfirmation(true);
  };

  const handleEdit = () => {
    closeAlert();
    setIsModalOpen(true);
  };

  const handleSearchEmployers = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.trim().toLocaleLowerCase();

    setFilteredEmployers(
      brokerLocationStatus.brokerLocationSummary.employerNames.filter(
        (name: string) => {
          return name.toLowerCase().includes(searchText);
        }
      )
    );
  };

  const handleSearchAdmins = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.trim().toLowerCase();

    setFilteredAdmins(
      brokerLocationStatus.brokerLocationSummary.brokerAdminNames.filter(
        (name: string) => {
          return name.toLowerCase().includes(searchText);
        }
      )
    );
  };

  const goToAllLocations = () => {
    refreshLocationsList();
    closeAlert();
    setIsLocationInfoOpen(false);
    if (
      appBootupInfo?.individualSubType === IndividualSubTypes.BROKER_ADMIN ||
      appBootupInfo?.individualSubType === IndividualSubTypes.OPS_ADMIN ||
      appBootupInfo?.individualSubType ===
        IndividualSubTypes.IMPLEMENTATION_ADMIN ||
      appBootupInfo?.individualSubType === IndividualSubTypes.BROKER_USER
    ) {
      setShowAddLocationButton(false);
    } else {
      setShowAddLocationButton(true);
    }
    setLocation(null);
  };

  const goToLocationInfo = (location: Location) => {
    closeAlert();
    setIsLocationInfoOpen(true);
    setShowAddLocationButton(false);
    dispatch(findLocationById(location.id));
  };

  const closeDeleteConfirmationModal = () => {
    setVisibleConfirmation(false);
    dispatch(clearBrokerLocationDeletion());
    confirmCloseModal();
  };

  const closeAlert = () => {
    dispatch(clearBrokerLocationDeletion());
    setVisible(false);
    setAlertMessage({
      type: undefined,
      message: '',
    });
  };

  const employerNamesSearchBar = () => {
    return (
      <SearchBar
        placeholder="Search to filter employers"
        onChange={handleSearchEmployers}
      />
    );
  };

  const adminNamesSearchBar = () => {
    return (
      <SearchBar
        placeholder="Search to filter broker admins"
        onChange={handleSearchAdmins}
      />
    );
  };

  const formatList = (list: string[]) => {
    return list?.map((item, index) => {
      return (
        <Typography.Text key={index} ellipsis>
          {item}
        </Typography.Text>
      );
    });
  };

  const addressLine2Content = (location: Location) => {
    return location.address.addressLine2?.length > 0
      ? `${location.address.addressLine2}\n`
      : '';
  };

  const addressContent = (location: Location) => {
    return `${location.address.addressLine1}\n${addressLine2Content(location)}${
      location.address.city
    }, ${getStateAbbreviation(location.address.state)}\n${
      location.address.zipCode
    }`;
  };

  return isLocationInfoOpen ? (
    location && brokerLocationStatus.fetchSummarySucceeded ? (
      <div className={styles.locationInfoWrapper}>
        <div className={styles.alertWrapper}>
          {visible && (
            <AlertMessage
              type={alertMessage.type}
              message={alertMessage.message}
              closeAlert={closeAlert}
            />
          )}
        </div>
        <div className={styles.titleWrapper} onClick={goToAllLocations}>
          <VectorLeft />
          <span className={styles.locationTitle}>{location.name}</span>
          <span className={styles.primaryBadge}>
            {location.primary && <OutlinedText text="PRIMARY LOCATION" />}
          </span>
        </div>

        <div className={styles.bodyWrapper}>
          <div className={styles.sectionHeader}>
            <p className={styles.subHeaderText}>Basic Information</p>
            {appBootupInfo?.individualSubType !==
              IndividualSubTypes.BROKER_USER && (
              <p className={styles.editButton} onClick={handleEdit}>
                Edit
              </p>
            )}
            <Divider />
          </div>
          <Row>
            <Col span={24}>
              <InfoLabel
                labelText="Location Name"
                value={location.name}
                noExtraCol
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InfoLabel
                labelText="Address"
                value={addressContent(location)}
                noExtraCol
              />
            </Col>
          </Row>

          <Row className={styles.locationListWrapper}>
            <Col span={11}>
              <div className={styles.sectionHeader}>
                <p className={styles.subHeaderText}>Employers</p>
                <Divider />
              </div>
              {brokerLocationStatus.brokerLocationSummary?.employerNames
                ?.length === 0 ? (
                <Row className={styles.infoLabelText}>
                  <Col span={8} className={styles.listLabel}>
                    <span>Employer Names</span>
                  </Col>
                  <Col>There are no assigned employers at this time</Col>
                </Row>
              ) : (
                <>
                  <InfoLabel
                    labelText="Employer Names"
                    value={employerNamesSearchBar()}
                  />
                  <List
                    className={styles.scrollList}
                    dataSource={formatList(filteredEmployers)}
                    bordered
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  />
                </>
              )}
            </Col>
            <Col span={2} />
            <Col span={11}>
              <div className={styles.sectionHeader}>
                <p className={styles.subHeaderText}>Broker Admins</p>
                <Divider />
              </div>
              {brokerLocationStatus.brokerLocationSummary?.brokerAdminNames
                ?.length === 0 ? (
                <Row className={styles.infoLabelText}>
                  <Col span={8} className={styles.listLabel}>
                    <span>Admin Names</span>
                  </Col>
                  <Col>There are no assigned broker admins at this time</Col>
                </Row>
              ) : (
                <>
                  <InfoLabel
                    labelText="Admin Names"
                    value={adminNamesSearchBar()}
                  />
                  <List
                    className={styles.scrollList}
                    dataSource={formatList(filteredAdmins)}
                    bordered
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  />
                </>
              )}
            </Col>
          </Row>
        </div>

        <FullScreenModal
          visible={isModalOpen}
          onCancel={closeLocationModal}
          footer={false}
          title="Edit Location"
          className={styles.mediaScreen}
          destroyOnClose
        >
          <AddBrokerLocation
            location={location}
            visible={isModalOpen}
            confirmCloseModal={confirmCloseModal}
            visibleConfirmationClosePopup={visibleConfirmationClosePopup}
            toggleConfirmationPopup={toggleConfirmationPopup}
            locationUpdated={locationUpdated}
          />
        </FullScreenModal>
      </div>
    ) : (
      <Spin />
    )
  ) : (
    <div className={styles.adminTableWrapper}>
      <div className={styles.alertWrapper}>
        {visible && (
          <AlertMessage
            type={alertMessage.type}
            message={alertMessage.message}
            closeAlert={closeAlert}
          />
        )}
      </div>
      {visibleConfirmation && (
        <FullScreenModal
          visible={visibleConfirmation}
          onCancel={closeDeleteConfirmationModal}
          footer={false}
          title="Remove Broker Location"
        >
          <RemoveBrokerLocation
            filterdLocations={filterdLocations}
            closeModal={closeDeleteConfirmationModal}
            removableLocationId={removableLocationId}
            brokerId={brokerId ? brokerId : null}
            removableLocationName={deletingLocation}
            isTechAdmin={userType === loginTypes.platform}
            isRfpEnabled={locationRfpStatus.locationFeatures.some(
              (loc: any) =>
                loc.id === removableLocationId &&
                (loc.enabled || loc.dataPresent)
            )}
          />
        </FullScreenModal>
      )}

      <Row className={styles.searchBarWrapper}>
        <SearchBar
          placeholder="Search Locations"
          onChange={handleSearch}
          value={searchText}
        />
      </Row>
      <DataTable
        currentIndex={paginationConfig.paginationIndex}
        data={convertLocationsForTableRender(content)}
        columns={locationTableColumns}
        pagination={true}
        getData={getData}
        total={total}
        pageSize={limit}
        loading={inProgress}
        sorterInfo={paginationConfig.sorterInfo}
      />
      <FullScreenModal
        visible={isModalOpen}
        onCancel={closeLocationModal}
        footer={false}
        title={'Add Broker Location'}
        className={styles.mediaScreen}
      >
        <AddBrokerLocation
          location={location}
          visible={isModalOpen}
          confirmCloseModal={confirmCloseModal}
          visibleConfirmationClosePopup={visibleConfirmationClosePopup}
          toggleConfirmationPopup={toggleConfirmationPopup}
          locationCreated={locationCreated}
        />
      </FullScreenModal>
    </div>
  );
};

export default LocationList;
