import React, { FC, useEffect } from 'react';

import { useAppDispatch } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';

import { getBrokerInfoView } from 'modules/brokers/slices/brokerBasicInfoSlice';
import CreateBrokerInfoForm from 'modules/brokers/components/CreateBrokerInfoForm/CreateBrokerInfoForm';

import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useStateCallback } from 'hooks/updateState';
import { getBroker } from 'layout/slices/layoutSlice';
import { exitWithoutSavingMsg } from 'constants/commonConstants';

import styles from './brokerInfoEdit.module.less';

type BrokerInfoEditProps = {
  isModalOpen: boolean;
  closeModal: () => void;
};

const BrokerInfoEdit: FC<BrokerInfoEditProps> = (
  props: BrokerInfoEditProps
) => {
  const { isModalOpen, closeModal } = props;
  const dispatch = useAppDispatch();
  const { brokerId } = useNavContext();
  const [isConfirmOpen, setIsConfirmOpen] = useStateCallback(false);

  useEffect(() => {
    if (brokerId) {
      dispatch(getBrokerInfoView(brokerId));
    }
  }, [dispatch, brokerId]);

  const closeBasicInfoEditModal = () => {
    setIsConfirmOpen(true);
  };
  const handleCloseConfirm = () => {
    setIsConfirmOpen(false, () => {
      if (brokerId) {
        dispatch(getBroker(brokerId));
      }
      if (closeModal) {
        closeModal();
      }
    });
  };

  return (
    <>
      <FullScreenModal
        visible={isModalOpen}
        onCancel={closeBasicInfoEditModal}
        footer={false}
        title="Edit Broker Basic Info"
        className={styles.mediaScreen}
      >
        <CreateBrokerInfoForm
          edit={true}
          closeModal={closeModal}
          brokerId={brokerId}
        />
      </FullScreenModal>
      {isConfirmOpen && (
        <ConfirmationDialog
          title="Are you sure you want to close?"
          confirmText="Yes - close and do not save"
          cancelText="Cancel"
          closeModal={() => setIsConfirmOpen(false)}
          onConfirm={handleCloseConfirm}
          visible={isConfirmOpen}
        >
          <p className={styles.warningConfirmation}>{exitWithoutSavingMsg}</p>
        </ConfirmationDialog>
      )}
    </>
  );
};

export default BrokerInfoEdit;
