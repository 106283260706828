import { lumityApi } from 'api/apiSlice';

/**
 * API to get list of ben-guides basing employerID and planYearId for dashboard
 */

const SUB_BASE_API_ENDPOINT = 'v2/benefit-guides/employers';

const dashboardApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getListOfGuides: builder.query<
      any,
      {
        employerId: string | undefined;
        planYearId: string | undefined;
      }
    >({
      query: (args) => {
        const { employerId, planYearId } = args;
        return {
          url: `${SUB_BASE_API_ENDPOINT}/${employerId}/plan-year/${planYearId}/dashboard`,
          method: 'get',
        };
      },
    }),
  }),
});

export const { useGetListOfGuidesQuery } = dashboardApi;
