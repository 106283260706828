import React from 'react';
import styles from 'modules/plans/UpdatePlanParametersInfo/updatePlanParametersInfo.module.less';
import IconInfo from 'assets/images/icon-info.svg';

const UpdatePlanParametersInfo = () => {
  return (
    <div className={styles.updatePlanParametersInfo}>
      <div>
        <img src={IconInfo} alt="Icon Info" />
      </div>
      <div className={styles.updatePlanParametersInfoText}>
        Changing plan information here will change the plan information
        everywhere this plan is shown
      </div>
    </div>
  );
};

export default UpdatePlanParametersInfo;
