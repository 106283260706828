import { FC } from 'react';
import { Row } from 'antd';

import styles from './featureList.module.less';

type NoFeaturesViewProps = {
  className?: string;
};

const NoFeaturesView: FC<NoFeaturesViewProps> = ({
  className,
}: NoFeaturesViewProps) => {
  return (
    <div className={styles.noFeaturesContainer}>
      <Row className={styles.subHeader}>
        To configure features, please select from the feature list available on
        the left sidebar.
      </Row>
    </div>
  );
};

export default NoFeaturesView;
