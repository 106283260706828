import { forwardRef, useImperativeHandle, useState } from 'react';
import { isEmpty, pick, some } from 'lodash';
import { Form, Input } from 'antd';
import { useAppSelector } from 'hooks/redux';
import PanelInputForm from 'modules/plans/components/PanelInputForm/PanelInputForm';

import { RxCardInfoFields } from 'modules/plans/constants';

import styles from './rxCardInfo.module.less';

type IdCardInfoProps = {
  onChange: Function;
};

type FormDataType = {
  rxBinNumber?: string;
  rxPcnNumber?: string;
  rxGroupNumber?: string;
};

const RxCardInfo = forwardRef((props: IdCardInfoProps, ref) => {
  const { onChange } = props;
  const [form] = Form.useForm();

  const plan = useAppSelector((state) => state.plan.plans);
  const { medicalPlan } = plan;
  const [formData] = useState<FormDataType>({
    rxBinNumber: '',
    rxPcnNumber: '',
    rxGroupNumber: '',
  });

  const getValidationResult = async () => {
    const requiredFieldsForComplete = pick(medicalPlan, [
      'rxBinNumber',
      'rxPcnNumber',
      'rxGroupNumber',
    ]);
    const isComplete = !some(requiredFieldsForComplete, isEmpty);
    let isValid = false;
    try {
      await form.validateFields();
      isValid = true;
    } catch (error) {
      isValid = false;
    }
    return { isComplete, isValid };
  };

  useImperativeHandle(ref, () => ({
    validate() {
      return getValidationResult();
    },
  }));

  const onInputChange = async (currentChange: any, allValues: any) => {
    const updatedObject = {
      ...medicalPlan,
      ...formData,
      ...allValues,
    };

    onChange({ ...updatedObject });
  };

  return (
    <div className={styles.rxCardInfoFormContainer}>
      <PanelInputForm
        form={form}
        name="rxCardInfo"
        layout="horizontal"
        initialValues={{ remember: true }}
        autoComplete="off"
        onValuesChange={onInputChange}
      >
        <Form.Item
          className={styles.fieldItem}
          name={RxCardInfoFields.RX_BIN_NUMBER}
          label="Rx Bin #"
        >
          <Input title={formData.rxBinNumber} />
        </Form.Item>

        <Form.Item
          className={styles.fieldItem}
          name={RxCardInfoFields.RX_PCN_NUMBER}
          label="Rx PCN #"
        >
          <Input title={formData.rxPcnNumber} />
        </Form.Item>

        <Form.Item
          className={styles.fieldItem}
          name={RxCardInfoFields.RX_GROUP_NUMBER}
          label="Rx Group #"
        >
          <Input title={formData.rxGroupNumber} />
        </Form.Item>
      </PanelInputForm>
    </div>
  );
});

RxCardInfo.displayName = 'RxCardInfo';

export default RxCardInfo;
