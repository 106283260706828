import { FC, useState } from 'react';

import { useStateCallback } from 'hooks/updateState';
import { useAppDispatch } from 'hooks/redux';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import AddEmployer from 'modules/employers/components/AddEmployer/AddEmployer';
import { clearEmployerData } from 'modules/employers/slices/employerCreateStepperSlice';
import { exitWithoutSavingMsg } from 'constants/commonConstants';

import styles from './addEmployerModal.module.less';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

const AddEmployerModal: FC<Props> = (props: Props) => {
  const { isModalOpen, setIsModalOpen } = props;

  const [currentStep, setCurrentStep] = useState(0);
  const [isConfirmOpen, setIsConfirmOpen] = useStateCallback(false);

  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    if (currentStep === 4) {
      setCurrentStep(0);
      dispatch(clearEmployerData());
      setIsModalOpen(false);
    } else {
      setIsConfirmOpen(true);
    }
  };

  const handleCloseConfirm = () => {
    setIsConfirmOpen(false, () => {
      dispatch(clearEmployerData());
      setIsModalOpen(false);
      setCurrentStep(0);
    });
  };

  return (
    <FullScreenModal
      visible={isModalOpen}
      onCancel={handleCloseModal}
      footer={false}
      title="Add Employer"
      forceRender
    >
      <AddEmployer
        closeModal={handleCloseModal}
        currentStep={currentStep}
        onNextStep={() => setCurrentStep(currentStep + 1)}
        changeCurrentStep={(step) => setCurrentStep(step)}
        isModalOpen={isModalOpen}
      />
      {isConfirmOpen && (
        <ConfirmationDialog
          title="Are you sure you want to close?"
          confirmText="Yes - close and do not save"
          cancelText="Cancel"
          closeModal={() => setIsConfirmOpen(false)}
          onConfirm={handleCloseConfirm}
          visible={isConfirmOpen}
        >
          <p className={styles.warningConfirmation}>{exitWithoutSavingMsg}</p>
        </ConfirmationDialog>
      )}
    </FullScreenModal>
  );
};

export default AddEmployerModal;
