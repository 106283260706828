export const exportToTxt = (html: string, documentName: string) => {
  const header =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
    "xmlns:w='urn:schemas-microsoft-com:office:word' " +
    "xmlns='http://www.w3.org/TR/REC-html40'>" +
    "<head><meta charset='utf-8'><title>Document</title></head><body>";
  const footer = '</body></html>';
  const sourceHTML = header + html + footer;

  const source =
    'data:application/vnd.ms-word;charset=utf-8,' +
    encodeURIComponent(sourceHTML);
  const fileDownload = document.createElement('a');
  document.body.appendChild(fileDownload);
  fileDownload.href = source;
  fileDownload.download = `${documentName}.doc`;
  fileDownload.click();
  document.body.removeChild(fileDownload);
};

export const getQuestions = (questions: any) => {
  return questions
    .map((obj: any) => `<i>${obj.question}</i><br><br>${obj.answer}`)
    .join('<br><br>');
};

export const htmlToText = (html: string) => {
  const brRegex = /<br\s*\/?>/gi;
  const withLineBreaks = html.replace(brRegex, '\n');
  const htmlRegex = /<[^>]*>/g;
  const cleanText = withLineBreaks.replace(htmlRegex, '');
  return cleanText;
};

export const formatDate = (timestamp: string) => {
  const dateObj = new Date(timestamp);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const month = months[dateObj.getMonth()];
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  const formattedDateAndTime = `${month} ${day}, ${year}`;

  return formattedDateAndTime;
};

export const formatDateAndTime = (timestamp: string) => {
  const dateObj = new Date(timestamp);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const month = months[dateObj.getMonth()];
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  const amOrPm = hours >= 12 ? 'PM' : 'AM';

  const formattedHours = hours % 12 || 12;

  const formattedDateAndTime = `${month} ${day} ${year} - ${formattedHours}:${minutes
    .toString()
    .padStart(2, '0')}${amOrPm}`;

  return formattedDateAndTime;
};
