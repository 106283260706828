import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Sender } from 'modules/chatbot/enums/Sender';

type Message = {
  sender: Sender;
  content: string;
  reference?: string;
};

type ChatState = {
  isPending: boolean;
  conversationId: string | null;
  messages: Message[];
};

const initialState: ChatState = {
  isPending: false,
  conversationId: null,
  messages: [
    {
      sender: Sender.BOT,
      content:
        "Hi! I'm here to answer any questions you may have about using this website. You can type any question below.",
    },
  ],
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setPending(state, action: PayloadAction<boolean>) {
      state.isPending = action.payload;
    },
    setConversationId(state, action: PayloadAction<string | null>) {
      state.conversationId = action.payload;
    },
    addMessage(state, action: PayloadAction<Message>) {
      state.messages.push(action.payload);
    },
    setNewChat(state) {
      state.isPending = false;
      state.messages = [
        {
          sender: Sender.BOT,
          content:
            "Hi! I'm here to answer any questions you may have about using this website. You can type any question below.",
        },
      ];
      state.conversationId = null;
    },
  },
});

export const { setPending, setConversationId, addMessage, setNewChat } =
  chatSlice.actions;
export default chatSlice.reducer;
