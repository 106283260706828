import { FC } from 'react';
import styles from './noOffersScreen.module.less';

type EmptyScreenProps = {
  screenText: string;
};
const EmptyOfferScreen: FC<EmptyScreenProps> = (props: EmptyScreenProps) => {
  const { screenText } = props;
  return (
    <div className={styles.emptyOffersWrapper}>
      <p className={styles.emptyTxt}>{screenText}</p>
    </div>
  );
};

export default EmptyOfferScreen;
