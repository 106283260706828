import { useEffect, useState } from 'react';
import { ReviewType } from 'modules/renewals/enums/ReviewType';

const usePollingHook = (
  isProcessing: boolean,
  reviewType: ReviewType,
  intervalTime: number
) => {
  const [pollingCount, setPollingCount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isProcessing && reviewType === ReviewType.AUTOMATIC) {
        setPollingCount((prevCount) => prevCount + 1);
      }
    }, intervalTime);

    return () => clearInterval(intervalId);
  }, [isProcessing, reviewType, intervalTime]);

  return pollingCount;
};

export default usePollingHook;
