import React, { useEffect, useRef } from 'react';
import { first, isEmpty } from 'lodash';
import { useStompClient, useSubscription } from 'react-stomp-hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ReactComponent as CloseIcon } from 'assets/images/chatbot/close-icon.svg';
import { ReactComponent as MinimizeIcon } from 'assets/images/chatbot/minimize-close-icon.svg';
import { ReactComponent as NewChatIcon } from 'assets/images/chatbot/new-chat-icon.svg';
import ChatBubble from 'modules/chatbot/layouts/ChatBubbleLayout/ChatBubbleLayout';
import {
  Control as Controls,
  ControlsProps,
} from 'modules/chatbot/types/types';

import {
  initializeConversationService,
  publishingURL,
  receivingURL,
} from 'modules/assistant/services/BrokerAssistantConversationService';
import { AssistantChatResponseVO } from 'modules/assistant/models/AssistantChatResponseVO';
import BrokerChatInput from 'modules/chatbot/components/BrokerChatInput/BrokerChatInput';
import {
  addMessage,
  setConversationId,
  setNewChat,
  setPending,
} from 'modules/chatbot/slices/chatbotSlice';
import PromptType from 'modules/assistant/enums/PromptRenderType';
import { Sender } from 'modules/chatbot/enums/Sender';

import styles from './supportChatLayout.module.less';

const createControls = (
  dispatch: React.Dispatch<any>,
  handleModalClose: () => void
): Controls[] => {
  return [
    {
      icon: <NewChatIcon />,
      action: () => dispatch(setNewChat()),
    },
    {
      icon: <MinimizeIcon />,
      action: handleModalClose,
    },
    {
      icon: <CloseIcon />,
      action: () => {
        dispatch(setNewChat());
        handleModalClose();
      },
    },
  ];
};

const SupportChatLayout = ({
  children,
  handleModalClose,
  visible,
}: {
  children: React.ReactNode;
  handleModalClose: () => void;
  visible: boolean;
}) => {
  const stompClient = useStompClient();
  const dispatch = useAppDispatch();
  const { messages, isPending, conversationId } = useAppSelector(
    (state) => state.chatBot
  );
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // Subscription to receive messages from the server
  useSubscription(receivingURL(conversationId!), (message: any) => {
    const content = JSON.parse(message.body);
    dispatch(setConversationId(content.conversationId));
    dispatch(setPending(false));
    dispatch(
      addMessage({
        sender: Sender.BOT,
        content: content?.message,
        reference: content?.reference,
      })
    );
  });

  // create control components
  const ChatControls = ({ components }: ControlsProps) => {
    return (
      <div className={styles.chatControls}>
        {components?.map((component, index) => (
          <div
            key={index}
            className={styles?.controlIcon}
            onClick={component?.action}
          >
            {component.icon}
          </div>
        ))}
      </div>
    );
  };

  // Function to publish messages to the server
  const publishMessage = (message: string): void => {
    if (!stompClient) {
      console.error("Stomp client undefined. Can't send message.");
      return;
    }
    try {
      stompClient?.publish({
        destination: publishingURL(conversationId!),
        body: JSON.stringify({ conversationId, message }),
      });
    } catch (error) {
      console.error('Error while sending message:', error);
    }
  };

  const handleSubmit = async (value: string) => {
    if (!isPending && isEmpty(value)) return;
    dispatch(setPending(true));
    dispatch(
      addMessage({
        sender: Sender.USER,
        content: value!,
      })
    );

    if (isEmpty(conversationId)) {
      const response: AssistantChatResponseVO =
        await initializeConversationService({
          type: PromptType.GENERAL_BENEFITS,
          question: value,
        });
      if (response) {
        dispatch(setConversationId(response.conversationId));
        dispatch(
          addMessage({
            sender: Sender.BOT,
            content: first(response.messages)?.content.content!,
            reference: first(response.messages)?.reference,
          })
        );
        dispatch(setPending(false));
      }
    } else {
      publishMessage(value);
    }
  };

  // Scroll to the bottom of the messages when a new message is added
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (visible) {
      messagesEndRef.current?.scrollIntoView();
    }
  }, [visible]);

  return (
    <div className={styles.chatLayoutWrapper}>
      <div className={styles.chatHeader}>
        <div className={styles.chatControls}>
          <ChatControls
            components={createControls(dispatch, handleModalClose)}
          />
        </div>
        <div className={styles.chatHeaderTextWrapper}>
          <p className={styles.subHeaderText}>Ask Anything</p>
          <h1 className={styles.headerText}>AI Support Chatbot</h1>
        </div>
      </div>
      <div className={styles.chatBody}>
        <div className={styles.messagesContainer}>
          {children}
          {isPending && (
            <ChatBubble sender={Sender.BOT} isPending={isPending} />
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className={styles.chatFooter}>
        <BrokerChatInput onSubmit={handleSubmit} loading={isPending} />
      </div>
    </div>
  );
};

export default SupportChatLayout;
