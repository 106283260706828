import { FC, useState } from 'react';

import { Col, Row } from 'antd';
import InfoLabelSection from 'components/InfoLabeSection/InfoLabelSection';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { TaxAdvantagedAccountPlan } from 'model/plans/TaxAdvantagedAccountPlan';
import PlanDocumentsOverview from 'modules/plans/components/OverviewPage/PlanDocumentsOverview/PlanDocumentOverview';

import styles from './commuterPlanOverview.module.less';

type CommuterPlanOverviewProps = {
  plan: TaxAdvantagedAccountPlan;
  heading?: string;
};

const CommuterPlanOverview: FC<CommuterPlanOverviewProps> = (props) => {
  const { plan } = props;

  const [viewPlanDocumentsModal, setViewPlanDocumentsModal] = useState(false);

  const getFormattedDecimalValue = (value: number) => {
    return `$${Number(parseFloat(`${value}`).toFixed(2)).toLocaleString('en', {
      minimumFractionDigits: 2,
    })}`;
  };

  const contributionOverview = [
    {
      label: 'Employer Contribution (Monthly)',
      value:
        plan.employerMonthlyContribution ||
        plan.employerMonthlyContribution === 0
          ? getFormattedDecimalValue(plan.employerMonthlyContribution)
          : '-',
    },
    {
      label: 'Max Monthly Contribution',
      value:
        plan.maxMonthlyContribution || plan.maxMonthlyContribution === 0
          ? getFormattedDecimalValue(plan.maxMonthlyContribution)
          : '-',
    },
    {
      label: 'Other Employer Contributions',
      value: (
        <OverflowPopover>
          {plan.employerContribution?.otherContribution
            ? `${plan.employerContribution?.otherContribution}`
            : '-'}
        </OverflowPopover>
      ),
    },
  ];

  const updateViewPlanDocumentsModal = (value: boolean) => {
    setViewPlanDocumentsModal(value);
  };
  return (
    <div className={styles.wrapper}>
      {contributionOverview.map((item, Index) => (
        <Row key={Index}>
          <Col span={24}>
            <InfoLabelSection labelText={item.label} value={item.value} />
          </Col>
        </Row>
      ))}
      <div className={styles.overview} id={'Plan_Doc'}>
        <PlanDocumentsOverview
          heading={'Plan Resources'}
          plan={plan}
          documents={plan?.documents}
          benefitKind={plan.benefitKind}
          viewPlanDocumentsModal={viewPlanDocumentsModal}
          setViewPlanDocumentsModal={(value) =>
            updateViewPlanDocumentsModal(value)
          }
        />
      </div>
    </div>
  );
};

export default CommuterPlanOverview;
