import { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'antd';

import dayjs from 'dayjs';
import { toLower } from 'lodash';
import { useAppSelector } from 'hooks/redux';
import Button from 'components/buttons/Button/Button';
import TextButton from 'components/buttons/TextButton/TextButton';

import SendConfirmationStep from 'modules/renewals/components/soldConfirmation/SendConfirmationStep/SendConfirmationStep';
import SendConfirmationMailStep from 'modules/renewals/components/soldConfirmation/SendConfirmationMailStep/SendConfirmationMailStep';
import { generateEmailBody } from 'modules/renewals/utils/soldConfirmationUtil';
import { getBenefitInfo } from 'modules/renewals/utils/renewalsUtils';

import {
  useLazyGetIndividualByIdQuery,
  useLazyGetSoldConfirmationCarrierListQuery,
  useLazyGetSoldConfirmationDetailQuery,
  useSendSoldConfirmationMailMutation,
} from 'modules/renewals/slices/soldConfirmationSlice';

import { ReactComponent as LeftArrow } from 'assets/images/vector-left.svg';
import styles from './soldConfirmationModal.module.less';

type Props = {
  finalApprovalId: string;
  visible: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export type EmailInputStateProps = {
  defaultMailList: string[];
  emailList: string[];
  isValidList: boolean;
  isEmptyList: boolean;
  removableDefaultMailList: string[];
};

const SoldConfirmationModal = (props: Props) => {
  const { visible, onClose, finalApprovalId } = props;

  const [form] = Form.useForm();
  const [showSendMailStep, setShowSendMailStep] = useState({
    visible: false,
  });
  const [soldConfirmationId, setSoldConfirmationId] = useState('');

  const [toEmailInputState, setToEmailInputState] =
    useState<EmailInputStateProps>({
      defaultMailList: [],
      emailList: [],
      isValidList: false,
      isEmptyList: false,
      removableDefaultMailList: [],
    });

  const [ccEmailInputState, setCCEmailInputState] =
    useState<EmailInputStateProps>({
      defaultMailList: [],
      emailList: [],
      isValidList: false,
      isEmptyList: false,
      removableDefaultMailList: [],
    });
  const [emailBodyValue, setEmailBodyValue] = useState('');
  const [isEmailBodyEmpty, setIsEmailBodyEmpty] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState({
    type: '',
    visible: false,
    message: '',
  });

  // Selectors
  const appBootInfo: any = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );
  // RTK queriers
  const [
    getSoldConfirmationCarrierList,
    { data: carrierList, isLoading: isCarrierListLoading },
  ] = useLazyGetSoldConfirmationCarrierListQuery();

  const [
    getSoldConfirmationDetails,
    { data: soldDetail, isFetching: isSoldFetching },
  ] = useLazyGetSoldConfirmationDetailQuery();

  const [getIndividualByIdQuery, { data: loggedUser }] =
    useLazyGetIndividualByIdQuery();

  const [sendSoldConfirmation, { isSuccess, isLoading, reset }] =
    useSendSoldConfirmationMailMutation();

  useEffect(() => {
    if (visible && finalApprovalId) {
      getSoldConfirmationCarrierList({ proposalId: finalApprovalId });
      getIndividualByIdQuery({ individualId: appBootInfo?.individualId });
    }
    // eslint-disable-next-line
  }, [visible, finalApprovalId]);

  useEffect(() => {
    if (showSendMailStep.visible && soldDetail) {
      const defaultMail = [loggedUser?.email];
      setCCEmailInputState({
        ...ccEmailInputState,
        defaultMailList: defaultMail,
      });
      form.setFieldsValue({
        subject: `${soldDetail?.employerName} - Sold Confirmation - ${soldDetail?.carrierName}`,
        ccList: defaultMail,
      });

      setEmailBodyValue(
        generateEmailBody({
          employerName: soldDetail?.employerName || '',
          effectiveDate: dayjs(carrierList?.effectiveStartDate).format('ll'),
          planDuration: dayjs(carrierList?.effectiveStartDate)
            .format('ll')
            .concat(' - ', dayjs(carrierList?.effectiveEndDate).format('ll')),
          carrierName: soldDetail?.carrierName,
          benefitType: soldDetail?.benefitCategories
            ?.map((item: string) => getBenefitInfo(toLower(item)).name)
            .join(', '),
          offerDetails: soldDetail?.offerDetails,
        })
      );
    }
    // eslint-disable-next-line
  }, [showSendMailStep.visible, soldDetail]);

  useEffect(() => {
    if (isSoldFetching) {
      setShowSendMailStep({ visible: true });
    }
    // eslint-disable-next-line
  }, [isSoldFetching]);

  useEffect(() => {
    if (isSuccess) {
      handleResetStates();
      setShowAlertMessage({
        type: 'success',
        visible: true,
        message: 'Sold Confirmation email has been successfully sent.',
      });
      getSoldConfirmationCarrierList({ proposalId: finalApprovalId });
      reset();
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    let time: any;
    if (showAlertMessage.visible) {
      time = setTimeout(() => {
        setShowAlertMessage({ type: '', visible: false, message: '' });
      }, 15000);
    }
    return () => clearTimeout(time);
    // eslint-disable-next-line
  }, [showAlertMessage.visible]);

  // handle TO list in email chip
  const handleGetToEmailList = ({
    emailList = [],
    isValidList = false,
    isEmptyList = false,
    defaultMailList = [],
  }: Partial<EmailInputStateProps>) => {
    setToEmailInputState({
      defaultMailList: defaultMailList,
      emailList,
      isValidList,
      isEmptyList,
      removableDefaultMailList: [],
    });
    form.setFieldsValue({ toList: emailList });
  };

  // handle CC list in email chip
  const handleGetCCEmailList = ({
    emailList = [],
    isValidList = false,
    isEmptyList = false,
    defaultMailList = [],
  }: Partial<EmailInputStateProps>) => {
    setCCEmailInputState({
      defaultMailList: defaultMailList,
      emailList,
      isValidList,
      isEmptyList,
      removableDefaultMailList: [],
    });
    form.setFieldsValue({ ccList: emailList });
  };

  const handleFileUpload = (fileList: any) => {
    form.setFieldsValue({ files: fileList });
  };

  const handleSubmitEmail = () => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          soldConfirmationId: soldConfirmationId,
          soldConfirmationSubject: values.subject,
          soldConfirmationBody: values.emailBody,
          soldConfirmationTo: values.toList,
          soldConfirmationCC: values.ccList,
          files: values.files,
        };

        sendSoldConfirmation(data);
      })
      .catch((err) => {
        const emailBodyCheck = err.errorFields?.some((item: any) =>
          item.name?.includes('emailBody')
        );
        emailBodyCheck && setIsEmailBodyEmpty(true);
      });
  };
  const handleResetStates = () => {
    setShowSendMailStep({ visible: false });
    setSoldConfirmationId('');
    form.resetFields();
    setEmailBodyValue('');
    setToEmailInputState({
      defaultMailList: [],
      emailList: [],
      isValidList: false,
      isEmptyList: false,
      removableDefaultMailList: [],
    });
    setCCEmailInputState({
      defaultMailList: [],
      emailList: [],
      isValidList: false,
      isEmptyList: false,
      removableDefaultMailList: [],
    });
  };

  const title = (
    <>
      <Row>
        <Col xs={24} className={styles.titleWrapper}>
          <span className={styles.title}>Send Sold Confirmations</span>
          {showSendMailStep.visible && (
            <TextButton
              className={styles.goBackBtn}
              type="primary"
              label={
                <div className={styles.goBackBtnLabel}>
                  <LeftArrow className={styles.arrow} /> Go Back
                </div>
              }
              onClick={handleResetStates}
            />
          )}
        </Col>
        <Col xs={24} className={styles.subTitleWrapper}>
          {showSendMailStep.visible && `${soldDetail?.carrierName} - `}
          {carrierList?.proposalName} -{' '}
          {dayjs(carrierList?.effectiveStartDate)
            .format('ll')
            .concat(' - ', dayjs(carrierList?.effectiveEndDate).format('ll'))}
        </Col>
      </Row>
    </>
  );

  const footer = (
    <>
      {!showSendMailStep.visible ? (
        <Button
          className={styles.closeBtn}
          type="primary"
          label="Close"
          onClick={() => {
            handleResetStates();
            onClose();
          }}
        />
      ) : (
        <Row>
          <Col xs={24}>
            <Button
              className={styles.sendBtn}
              type="primary"
              label="Send Email"
              onClick={handleSubmitEmail}
              loading={isLoading}
            />
          </Col>
          <Col xs={24}>
            <TextButton
              type="primary"
              label="Cancel"
              onClick={handleResetStates}
            />
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <Modal
      wrapClassName={styles.modalWrapperContainer}
      visible={visible}
      width={850}
      destroyOnClose={true}
      centered={true}
      title={title}
      footer={footer}
    >
      {!showSendMailStep.visible ? (
        <SendConfirmationStep
          tableData={carrierList?.soldConfirmationSummaryList || []}
          tableLoading={isCarrierListLoading}
          employerName={carrierList?.employerName}
          onHandleSendSoldConfirm={(rowData: any) => {
            setSoldConfirmationId(rowData?.id);
            getSoldConfirmationDetails({ soldConfirmationId: rowData?.id });
          }}
          showAlertMessage={showAlertMessage}
        />
      ) : (
        <Form form={form} preserve={false}>
          <SendConfirmationMailStep
            emailBodyValue={emailBodyValue}
            toEmailInputState={toEmailInputState}
            ccEmailInputState={ccEmailInputState}
            handleGetToEmailList={handleGetToEmailList}
            handleGetCCEmailList={handleGetCCEmailList}
            isEmailBodyEmpty={isEmailBodyEmpty}
            setIsEmailBodyEmpty={setIsEmailBodyEmpty}
            loggedUser={loggedUser?.email}
            handleFileUpload={handleFileUpload}
          />
        </Form>
      )}
    </Modal>
  );
};

export default SoldConfirmationModal;
