import { useEffect, useState } from 'react';

import { Badge, Col, Row } from 'antd';
import { useParams } from 'react-router-dom';

import IssueVelocityBarChart from 'modules/issueslog/components/IssueVelocityBarChart/IssueVelocityBarChart';
import { useGetLogVelocityChatDataQuery } from 'modules/issueslog/slices/issuesLogSlice';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import {
  analyticsDateRangeNameMap,
  issueAnalyticsDateRange,
  issueType,
} from 'modules/issueslog/utils/issueLogUtils';
import NoApplicableAnalytics from 'modules/issueslog/components/IssueVelocity/NoIssueVelocity/NoApplicableAnalytics';
import CustomerSpinner from 'components/Spinner/CustomSpinner';
import { ReactComponent as CalendarIcon } from 'assets/images/icon-calendar.svg';

import {
  ISSUE_VELOCITY_CHART_PRIMARY_COLOR,
  ISSUE_VELOCITY_CHART_SECONDARY_COLOR,
} from 'modules/issueslog/constants/IssuesLogConstants';
import {
  allOptionsEmployers,
  singleSelectAllOptions,
} from 'constants/commonConstants';

import styles from './issuevelocity.module.less';

interface IPops {
  isAccount: boolean;
  employerData: any[];
  supportGroup: string | null;
}

const IssueVelocity = (props: IPops) => {
  const { brokerId } = useParams();

  const [filterSelectedValue, setFilterSelectedValue] = useState<string | null>(
    null
  );

  const [dateRangeDropDown, setDateRangeDropDown] = useState<string>(
    issueAnalyticsDateRange.TWELVE_MONTHS
  );

  const generateAccountType = (): string => {
    return props.isAccount
      ? issueType.ACCOUNT.toUpperCase()
      : issueType.SUPPORT.toUpperCase();
  };

  const generateApplicableEmployers = (): string | undefined | null => {
    return allOptionsEmployers == filterSelectedValue
      ? undefined
      : filterSelectedValue;
  };

  const generateSupportTeam = (): string | undefined | null => {
    return singleSelectAllOptions == props.supportGroup || props.isAccount
      ? undefined
      : props.supportGroup;
  };

  const {
    data: velocityData,
    isLoading,
    isError,
    refetch: refetchVelocityData,
  } = useGetLogVelocityChatDataQuery({
    employerId: generateApplicableEmployers(),
    timeRange: dateRangeDropDown,
    issueType: generateAccountType(),
    supportTeam: generateSupportTeam(),
    brokerId,
  });

  useEffect(() => {
    refetchVelocityData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelectedValue, dateRangeDropDown, props.supportGroup]);

  const generateDateRange = () => {
    return analyticsDateRangeNameMap.map((obj) => {
      return {
        key: obj.name,
        value: obj.name,
        label: obj.value,
      };
    });
  };

  if (isError) {
    return <NoApplicableAnalytics />;
  } else if (isLoading) {
    return <CustomerSpinner />;
  } else {
    return (
      <div className={styles.issueVelocity}>
        <h3 className={styles.mainHeader}>Item Velocity</h3>
        <div>
          <Row className={styles.topFilterRow}>
            {/* Dropdown Section */}
            <Col lg={8} xl={8}>
              <Row>
                <span className={styles.dropDownLabel}>Employer:</span>
                <SingleSelectFilter
                  showSearch={true}
                  inlineDropdown={true}
                  searchPlaceholder={`Search Employers`}
                  placeholder={allOptionsEmployers}
                  data={props.employerData}
                  setFilterSelectedValue={setFilterSelectedValue}
                  defaultValue={null}
                  addAllValue={true}
                  allLabel={allOptionsEmployers}
                  selectedValue={filterSelectedValue}
                />
              </Row>
            </Col>

            <Col lg={16} xl={16}>
              <Row className={styles.rightSectionSelector}>
                <Col>
                  <span className={styles.dropDownLabel}>Viewing:</span>
                </Col>
                <Col className={styles.dropDownFilter}>
                  <SingleSelectFilter
                    showSearch={false}
                    inlineDropdown={true}
                    searchPlaceholder={`Search Employers`}
                    placeholder={allOptionsEmployers}
                    data={generateDateRange()}
                    setFilterSelectedValue={setDateRangeDropDown}
                    defaultValue={null}
                    addAllValue={false}
                    allLabel={allOptionsEmployers}
                    selectedValue={dateRangeDropDown}
                    applicableIcon={
                      <CalendarIcon className={styles.calenderIcon} />
                    }
                  />
                </Col>
              </Row>
              <Row className={styles.rightSectionSelectorIssues}>
                <Col>
                  <Badge color={ISSUE_VELOCITY_CHART_PRIMARY_COLOR} />
                  <span className={styles.chartRepText}>New Items</span>
                </Col>
                <Col>
                  <Badge color={ISSUE_VELOCITY_CHART_SECONDARY_COLOR} />
                  <span className={styles.chartRepText}>Resolved Items</span>
                </Col>
              </Row>
            </Col>

            <Col></Col>
          </Row>
          {/* TODO -Refactor this with the existing bar chart component */}
          <IssueVelocityBarChart
            chartData={velocityData?.chartData}
            selectedDateRange={dateRangeDropDown}
          />
        </div>
      </div>
    );
  }
};
export default IssueVelocity;
