import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Col, notification, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import TabView from 'components/TabView/TabView';
import { Offer } from 'modules/renewals/models/Offer';
import TabType from 'model/TabType';
import {
  NO_CATEGORY_OFFER_DATA,
  NO_RENEWAL_DATA,
  NO_RENEWAL_DATA_DESCRIPTION,
  NO_RENEWAL_PRIMARY_BTN_TXT,
  OFFER_BENEFIT_TYPE_NAVIGATION_LABELS,
  OFFER_BENEFIT_TYPES,
  OFFER_CARD_STATUS,
  OFFER_CATEGORY,
  offeredPlanUpdate,
  UPCOMING_DROPDOWN_ITEM_CARRIER,
  UPCOMING_DROPDOWN_OFFER,
  UPDATE_PROPOSAL_TITLE_WARNING_NAME,
  WARNING_OFFERED_PLAN_UPDATE,
} from 'modules/renewals/constants/renewalsConstants';
import RenewalCommonAlert from 'modules/renewals/components/renewalCommonAlert/RenewalCommonAlert';
import {
  BenefitCategory,
  IndividualSubTypes,
  OFFER_NOT_FOUND_MESSAGE,
  SBC_PARAMS,
  sbcfetchError,
  planOfferFetchError,
  sbcReviewStarted,
} from 'constants/commonConstants';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import AddOfferModal from 'modules/renewals/components/AddOfferModal/AddOfferModal';
import OfferCard from 'modules/renewals/components/helperComponents/OfferCard/OfferCard';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import { ReactComponent as DropDownDarkArrow } from 'assets/images/dropdown-down-arrow.svg';
import { ReactComponent as NoRenewalsAlertIcon } from 'assets/images/no-renewals-alert-icon.svg';
import { ReactComponent as RedDotIcon } from 'assets/images/red-icon-quotes.svg';
import IconSuccess from 'assets/images/checkmark.svg';
import {
  useLazyGetAfpPlanYearsQuery,
  useLazyGetCarrierDentalDraftOffersQuery,
  useLazyGetCarrierDentalPublishedOffersQuery,
  useLazyGetCarrierLifeDraftOffersQuery,
  useLazyGetCarrierLifePublishedOffersQuery,
  useLazyGetCarrierLtdDraftOffersQuery,
  useLazyGetCarrierLtdPublishedOffersQuery,
  useLazyGetCarrierMedicalDraftOffersQuery,
  useLazyGetCarrierMedicalPublishedOffersQuery,
  useLazyGetCarrierStdDraftOffersQuery,
  useLazyGetCarrierStdPublishedOffersQuery,
  useLazyGetCarrierVisionDraftOffersQuery,
  useLazyGetCarrierVisionPublishedOffersQuery,
  useLazyGetCarrierVoluntaryBenefitDraftOffersQuery,
  useLazyGetCarrierVoluntaryBenefitPublishedOffersQuery,
  useLazyGetUpcomingPlanYearsByEmployerQuery,
} from 'modules/renewals/slices/renewalsSlice';
import {
  useCreateOfferNoteMutation,
  useLazyExistProposalByOfferQuery,
  useLazyGetIndividualOfferNotesForIdQuery,
  usePublishOrDraftSingleOfferMutation,
  useUpdateOfferNoteAsViewedMutation,
  useUpdateOfferStatusAsRecommendedMutation,
} from 'modules/renewals/slices/offersSlice';
import {
  convertBenefitKindToBenefitCategory,
  getBenefitTitleFromUrlParam,
  handleRedirectToProposalsDetails,
} from 'modules/renewals/utils/renewalsUtils';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { loginTypes } from 'constants/authConstants';
import { clear } from 'modules/renewals/slices/carrierQuoteAiSlice';
import Drawer from 'components/Drawer/Drawer';
import NotesCommonContent from 'modules/renewals/components/NotesCommonContent/NotesCommonContent';
import { CommonNoteDto } from 'modules/renewals/models/CommonNoteDto';
import { OfferNoteDto } from 'modules/renewals/models/OfferNoteDto';
import { baseApi } from 'util/apiUtil';
import UpcomingPlanYearBasicDto from 'model/UpcomingPlanYearBasicDto';
import { clearIsFromOfferNavigatePY } from 'modules/renewals/slices/offerReducerSlice';
import {
  createModalCloseHandler,
  getParamsFromUrlParams,
  isNullOrUndefined,
  removeSearchParams,
} from 'modules/plans/utils';
import { PlanYearDropDownType } from 'modules/renewals/types/renewalsTypes';
import { ReactComponent as RfpIconSelected } from 'assets/images/Rfp-ai-selected.svg';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import QuoteUploadHistoryModal from 'modules/renewals/components/QuoteProcessing/QuoteUploadHistoryModal/QuoteUploadHistoryModal';
import { BenefitType } from 'modules/renewals/models/PlanTable';
import { useGetQuoteUploadHistoryQuery } from 'modules/renewals/slices/carrierQuoteFilesAiSlice';
import ProcessStatus from 'modules/plans/enums/SBCUploadStatus';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import QuoteProcessStatus from 'modules/renewals/enums/QuoteUploadStatus';
import AlertMessageWrapper from 'modules/plans/components/AlertMessageWrapper/AlertMessageWrapper';
import AddPlanModal, {
  SelectedPlanProps,
  TDocumentExtractionSource,
} from 'modules/plans/components/AddPlanModal/AddPlanModal';
import {
  clear as clearAiSbcUploader,
  setOPSView,
} from 'modules/plans/slices/aiSbcUploaderSlice';
import { DocumentExtractionSource } from 'modules/plans/enums/DocumentExtractionSource';
import { resetDentalPlanReduxStore } from 'modules/plans/slices/dentalPlanSlice';
import { resetPlanReduxStore } from 'modules/plans/slices/medicalPlanSlice';
import { resetVisionPlanReduxStore } from 'modules/plans/slices/visionPlanSlice';
import { resetLifePlans } from 'modules/plans/slices/lifePlanSlice';

import { AlertInfo } from 'components/Alert/AlertMessage';
import SendSelfToOPSSuccessComponent from 'modules/plans/components/SendSelfToOPSSuccessComponent/SendSelfToOPSSuccessComponent';
import UpdateConfirmationListModal from 'modules/renewals/components/helperComponents/UpdateConfirmationListModal/UpdateConfirmationListModal';
import UploaderType from 'modules/plans/enums/UploaderType';

import styles from './offerCardList.module.less';

type PlansBenefitType = 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE';

type AttachedProposalAlert = {
  isVisible: boolean;
  data: SelectedPlanProps | undefined;
};

type PlanModalOpenType = {
  jobId: string;
  docExtractionSource: TDocumentExtractionSource;
};

const resetAttachedProposalAlert = {
  isVisible: false,
  data: undefined,
};

const RESET_ACTION_CREATOR = {
  MEDICAL: resetPlanReduxStore,
  DENTAL: resetDentalPlanReduxStore,
  VISION: resetVisionPlanReduxStore,
  LIFE: resetLifePlans,
} as const;

const OfferCardList = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const routerState = location?.state as any;
  const { status } = useAppSelector((state) => state.plan.aiSbc);
  const [quoteProcessingBannerDetails, setQuoteProcessingBannerDetails] =
    useState<{
      visible: boolean;
      content: ReactNode;
    }>({ content: '', visible: false });
  const [showUpdateOfferInPropModal, setShowUpdateOfferInPropModal] =
    useState<AttachedProposalAlert>(resetAttachedProposalAlert);
  const [visible, setVisible] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [addPlanPropsToState, setAddPlansPropsToState] = useState<
    SelectedPlanProps | undefined
  >(undefined);
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;
  const isEmpMember =
    appBootInfo?.individualSubType === IndividualSubTypes.EMPLOYER_MEMBER;
  const isOpsAdmin = useAppSelector(
    (state) => state.auth.auth.appBootupInfo?.isOpsAdmin
  );
  const [existProposalByOffer, { data: attachedProposalList }] =
    useLazyExistProposalByOfferQuery();
  const params = useParams();
  const navigate = useNavigate();
  const [
    getUpcomingPlanYears,
    {
      isFetching: isUpcomingFetching,
      isSuccess: isUpcomingFetchSuccess,
      isLoading: isUpcomingFetchLoading,
      data: upcomingData,
    },
  ] = useLazyGetUpcomingPlanYearsByEmployerQuery();

  const [
    getMedicalPublishedOffers,
    {
      isSuccess: isMedicalPublishedOffersFetchSuccess,
      isFetching: isMedicalPublishedOffersFetching,
      data: medicalPublishedData,
    },
  ] = useLazyGetCarrierMedicalPublishedOffersQuery();

  const [
    getMedicalDraftOffers,
    {
      isSuccess: isMedicalDraftOffersFetchSuccess,
      isFetching: isMedicalDraftOffersFetching,
      data: medicalDraftOfferData,
    },
  ] = useLazyGetCarrierMedicalDraftOffersQuery();

  const [
    getDentalPublishedOffers,
    {
      isSuccess: isDentalPublishedOffersFetchSuccess,
      isFetching: isDentalPublishedOffersFetching,
      data: dentalPublishedData,
    },
  ] = useLazyGetCarrierDentalPublishedOffersQuery();

  const [
    getDentalDraftOffers,
    {
      isSuccess: isDentalDraftOffersFetchSuccess,
      isFetching: isDentalDraftOffersFetching,
      data: dentalDraftOfferData,
    },
  ] = useLazyGetCarrierDentalDraftOffersQuery();

  const [
    getVisionPublishedOffers,
    {
      isSuccess: isVisionPublishedOffersFetchSuccess,
      isFetching: isVisionPublishedOffersFetching,
      data: visionPublishedData,
    },
  ] = useLazyGetCarrierVisionPublishedOffersQuery();

  const [
    getVisionDraftOffers,
    {
      isSuccess: isVisionDraftOffersFetchSuccess,
      isFetching: isVisionDraftOffersFetching,
      data: visionDraftOfferData,
    },
  ] = useLazyGetCarrierVisionDraftOffersQuery();

  const [
    getLifePublishedOffers,
    {
      isSuccess: isLifePublishedOffersFetchSuccess,
      isFetching: isLifePublishedOffersFetching,
      data: lifePublishedData,
    },
  ] = useLazyGetCarrierLifePublishedOffersQuery();

  const [
    getLifeDraftOffers,
    {
      isSuccess: isLifeDraftOffersFetchSuccess,
      isFetching: isLifeDraftOffersFetching,
      data: lifeDraftOfferData,
    },
  ] = useLazyGetCarrierLifeDraftOffersQuery();

  const [
    getStdPublishedOffers,
    {
      isSuccess: isStdPublishedOffersFetchSuccess,
      isFetching: isStdPublishedOffersFetching,
      data: stdPublishedData,
    },
  ] = useLazyGetCarrierStdPublishedOffersQuery();

  const [
    getStdDraftOffers,
    {
      isSuccess: isStdDraftOffersFetchSuccess,
      isFetching: isStdDraftOffersFetching,
      data: stdDraftOfferData,
    },
  ] = useLazyGetCarrierStdDraftOffersQuery();

  const [
    getLtdPublishedOffers,
    {
      isSuccess: isLtdPublishedOffersFetchSuccess,
      isFetching: isLtdPublishedOffersFetching,
      data: ltdPublishedData,
    },
  ] = useLazyGetCarrierLtdPublishedOffersQuery();

  const [
    getLtdDraftOffers,
    {
      isSuccess: isLtdDraftOffersFetchSuccess,
      isFetching: isLtdDraftOffersFetching,
      data: ltdDraftOfferData,
    },
  ] = useLazyGetCarrierLtdDraftOffersQuery();

  const [
    getVoluntaryBenefitPublishedOffers,
    {
      isSuccess: isVoluntaryBenefitPublishedOffersFetchSuccess,
      isFetching: isVoluntaryBenefitPublishedOffersFetching,
      data: voluntaryBenefitPublishedData,
    },
  ] = useLazyGetCarrierVoluntaryBenefitPublishedOffersQuery();

  const [
    getVoluntaryBenefitDraftOffers,
    {
      isSuccess: isVoluntaryBenefitDraftOffersFetchSuccess,
      isFetching: isVoluntaryBenefitDraftOffersFetching,
      data: voluntaryBenefitDraftOfferData,
    },
  ] = useLazyGetCarrierVoluntaryBenefitDraftOffersQuery();

  const [updateOfferStatusAsRecommended, { isSuccess: markedAsRecommended }] =
    useUpdateOfferStatusAsRecommendedMutation();

  const [publishOrDraftSingleOffer, { isSuccess: isPublishOrDraftSuccess }] =
    usePublishOrDraftSingleOfferMutation();

  const [planYears, setPlanYears] = useState<PlanYearDropDownType[]>([]);
  const [planYear, setPlanYear] = useState<any>(UPCOMING_DROPDOWN_OFFER.value);
  const [effectiveDates, setEffectiveDates] = useState<string[]>([]);
  const [selectedEffectiveDate, setSelectedEffectiveDate] = useState<any>(0);
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [isNoDataExist, setIsNoDataExist] = useState<boolean>(false);
  const [creditDropDownDefault, setCreditDropDownDefault] = useState<any>(0);
  const [isRecommendedStatusMarked, setRecommendedStatusMarked] =
    useState<boolean>(false);
  const [isAddOfferModalOpen, setIsAddOfferModalOpen] =
    useState<boolean>(false);
  const [isDeleteOfferSuccess, setDeleteOfferSuccess] =
    useState<boolean>(false);
  const [editOfferVisible, setEditOfferVisible] = useState<boolean>(false);
  const [selectedOfferId, setSelectedOfferId] = useState<string>();
  const [isCloneOffer, setCloneOffer] = useState<boolean>(false);
  const [isNotesVisible, setIsNotesVisible] = useState<boolean>(false);
  const [isSelfReviewToOps, setIsSelfReviewToOps] = useState<boolean>(false);
  const [selectedOfferForNote, setSelectedOfferForNote] = useState<any>({});
  const [isNoPlanYearDataExist, setIsNoPlanYearDataExist] =
    useState<boolean>(false);

  const [isAiPlanModalOpen, setIsAiPlanModalOpen] = useState<boolean>(false);

  const [getAfpPlanYears, { data: planYearsData }] =
    useLazyGetAfpPlanYearsQuery();

  const isFromOfferNavigatePY = useAppSelector(
    (state) => state.renewals.offers.isFromOfferNavigatePY
  );

  const planBenefitType = convertBenefitKindToBenefitCategory(
    params.benefitKind?.toUpperCase()
  ) as PlansBenefitType;

  useEffect(() => {
    const offerNotFoundParam = getParamsFromUrlParams(['error']);
    if (offerNotFoundParam?.error) {
      setAlertMessage({
        type: 'error',
        message: OFFER_NOT_FOUND_MESSAGE,
      });
      setVisible(true);
    }
    return () => {
      setVisible(false);
      setAlertMessage({
        type: undefined,
        message: undefined,
      });
    };
  }, []);

  useEffect(() => {
    setPlanYearsForDropDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcomingData, planYearsData]);
  const [tabToMove, setTabToMove] = useState<string>('');

  useEffect(() => {
    if (params.employerId) {
      getUpcomingPlanYears({ employerId: String(params.employerId) });
      getAfpPlanYears({ employerId: String(params.employerId) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.employerId]);

  const setPlanYearsForDropDown = () => {
    if (
      planYearsData &&
      !isEmpty(planYearsData) &&
      upcomingData?.upcomingPlanYears
    ) {
      setIsNoPlanYearDataExist(false);
      const planYearsList: any[] = planYearsData.map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }));

      const isRenewalStarted = upcomingData?.upcomingPlanYears.some(
        (obj: any) => obj.planYearId === null
      );

      if (
        !isEmpty(isFromOfferNavigatePY) ||
        !isNullOrUndefined(isFromOfferNavigatePY)
      ) {
        setPlanYear(isFromOfferNavigatePY);
        if (isRenewalStarted) {
          planYearsList.unshift(UPCOMING_DROPDOWN_ITEM_CARRIER);
        }
      } else if (isRenewalStarted) {
        planYearsList.unshift(UPCOMING_DROPDOWN_ITEM_CARRIER);
        setPlanYear(null);
      } else {
        setPlanYear(planYearsList[0]?.value);
      }
      setPlanYears(planYearsList);
    } else {
      setIsNoPlanYearDataExist(true);
      setPlanYears([UPCOMING_DROPDOWN_ITEM_CARRIER]);
      setPlanYear(null);
    }
  };

  useEffect(() => {
    setEffectiveDatesForDropDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planYear]);

  useEffect(() => {
    if (isUpcomingFetchSuccess) {
      setIsNoDataExist(upcomingData?.upcomingPlanYears?.length === 0);
      setEffectiveDatesForDropDown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpcomingFetchSuccess, isUpcomingFetchLoading, isUpcomingFetching]);

  const handleNotesDrawerClose = () => {
    updateOfferNoteAsViewed({
      offerId: selectedOfferForNote.id,
      individualId: String(appBootInfo?.individualId),
    });
  };

  const handleOpsRedirect = () => {
    setIsSelfReviewToOps(true);
  };

  useEffect(() => {
    if (markedAsRecommended) {
      notification.open({
        message: 'Successfully Updated',
        description: isRecommendedStatusMarked
          ? 'The offer has been successfully marked as Recommended'
          : 'The offer has been successfully removed from Recommended offers',
        placement: 'topRight',
        duration: 3,
        className: styles.notification,
        closeIcon: <></>,
        key: 'locationUpdated',
        icon: (
          <img className={styles.notificationIcon} src={IconSuccess} alt="" />
        ),
      });
    }
    // eslint-disable-next-line
  }, [markedAsRecommended]);

  const { state: activeTabState }: any = useLocation();

  const checkOfferDeleteSuccess = () => setDeleteOfferSuccess((prev) => !prev);

  const handleInternalOfferNoteClick = (offer: any) => {
    getIndividualOfferNotesForOfferId({ offerId: offer.id });
    setIsNotesVisible(true);
    setSelectedOfferForNote(offer);
  };

  const getFetchSuccessStatus = (
    category: string | undefined,
    isPublished: boolean
  ) => {
    if (category === BenefitCategory.MEDICAL.value) {
      return isPublished
        ? isMedicalPublishedOffersFetchSuccess
        : isMedicalDraftOffersFetchSuccess;
    } else if (category === BenefitCategory.DENTAL.value) {
      return isPublished
        ? isDentalPublishedOffersFetchSuccess
        : isDentalDraftOffersFetchSuccess;
    } else if (category === BenefitCategory.VISION.value) {
      return isPublished
        ? isVisionPublishedOffersFetchSuccess
        : isVisionDraftOffersFetchSuccess;
    } else if (category === 'LIFE-ADD') {
      return isPublished
        ? isLifePublishedOffersFetchSuccess
        : isLifeDraftOffersFetchSuccess;
    } else if (category === 'LTD') {
      return isPublished
        ? isLtdPublishedOffersFetchSuccess
        : isLtdDraftOffersFetchSuccess;
    } else if (category === 'STD') {
      return isPublished
        ? isStdPublishedOffersFetchSuccess
        : isStdDraftOffersFetchSuccess;
    } else if (category === 'VOL-LIFE') {
      return isPublished
        ? isVoluntaryBenefitPublishedOffersFetchSuccess
        : isVoluntaryBenefitDraftOffersFetchSuccess;
    }

    return false;
  };

  const loadList = () => {
    if (effectiveDates.length > 0 && !isNaN(selectedEffectiveDate)) {
      const effectiveDateStrings = (
        effectiveDates[selectedEffectiveDate] as any
      ).label.split(' - ');
      let fromDate = null;
      let toDate = null;
      if (effectiveDateStrings.length === 2) {
        fromDate = dayjs(effectiveDateStrings[0]).format('YYYY-MM-DD');
        toDate = dayjs(effectiveDateStrings[1]).format('YYYY-MM-DD');
      }
      const category = params.benefitKind?.toUpperCase();
      if (
        category === OFFER_BENEFIT_TYPE_NAVIGATION_LABELS.MEDICAL.toUpperCase()
      ) {
        getMedicalPublishedOffers({
          employerId: String(params.employerId),
          fromEffectiveDate: fromDate,
          toEffectiveDate: toDate,
          planYearId: planYear,
          isVersioned: null === planYear,
        });
        getMedicalDraftOffers({
          employerId: String(params.employerId),
          fromEffectiveDate: fromDate,
          toEffectiveDate: toDate,
          planYearId: planYear,
        });
      } else if (
        category === OFFER_BENEFIT_TYPE_NAVIGATION_LABELS.DENTAL.toUpperCase()
      ) {
        getDentalPublishedOffers({
          employerId: String(params.employerId),
          fromEffectiveDate: fromDate,
          toEffectiveDate: toDate,
          planYearId: planYear,
          isVersioned: null === planYear,
        });
        getDentalDraftOffers({
          employerId: String(params.employerId),
          fromEffectiveDate: fromDate,
          toEffectiveDate: toDate,
          planYearId: planYear,
        });
      } else if (
        category === OFFER_BENEFIT_TYPE_NAVIGATION_LABELS.VISION.toUpperCase()
      ) {
        getVisionPublishedOffers({
          employerId: String(params.employerId),
          fromEffectiveDate: fromDate,
          toEffectiveDate: toDate,
          planYearId: planYear,
          isVersioned: null === planYear,
        });
        getVisionDraftOffers({
          employerId: String(params.employerId),
          fromEffectiveDate: fromDate,
          toEffectiveDate: toDate,
          planYearId: planYear,
        });
      } else if (
        category === OFFER_BENEFIT_TYPE_NAVIGATION_LABELS.LIFE.toUpperCase()
      ) {
        getLifePublishedOffers({
          employerId: String(params.employerId),
          fromEffectiveDate: fromDate,
          toEffectiveDate: toDate,
          planYearId: planYear,
          isVersioned: null === planYear,
        });
        getLifeDraftOffers({
          employerId: String(params.employerId),
          fromEffectiveDate: fromDate,
          toEffectiveDate: toDate,
          planYearId: planYear,
        });
      } else if (
        category === OFFER_BENEFIT_TYPE_NAVIGATION_LABELS.LTD.toUpperCase()
      ) {
        getLtdPublishedOffers({
          employerId: String(params.employerId),
          fromEffectiveDate: fromDate,
          toEffectiveDate: toDate,
          planYearId: planYear,
          isVersioned: null === planYear,
        });
        getLtdDraftOffers({
          employerId: String(params.employerId),
          fromEffectiveDate: fromDate,
          toEffectiveDate: toDate,
          planYearId: planYear,
        });
      } else if (
        category === OFFER_BENEFIT_TYPE_NAVIGATION_LABELS.STD.toUpperCase()
      ) {
        getStdPublishedOffers({
          employerId: String(params.employerId),
          fromEffectiveDate: fromDate,
          toEffectiveDate: toDate,
          planYearId: planYear,
          isVersioned: null === planYear,
        });
        getStdDraftOffers({
          employerId: String(params.employerId),
          fromEffectiveDate: fromDate,
          toEffectiveDate: toDate,
          planYearId: planYear,
        });
      } else if (
        category ===
        OFFER_BENEFIT_TYPE_NAVIGATION_LABELS.VOLUNTARY_BENEFIT.toUpperCase()
      ) {
        getVoluntaryBenefitPublishedOffers({
          employerId: String(params.employerId),
          fromEffectiveDate: fromDate,
          toEffectiveDate: toDate,
          planYearId: planYear,
          isVersioned: null === planYear,
        });
        getVoluntaryBenefitDraftOffers({
          employerId: String(params.employerId),
          fromEffectiveDate: fromDate,
          toEffectiveDate: toDate,
          planYearId: planYear,
        });
      }
    }
  };

  const [
    createOfferInternalNote,
    {
      isLoading: isCreateOfferNoteLoading,
      isSuccess: isCreateOfferNoteSuccess,
    },
  ] = useCreateOfferNoteMutation();

  const [
    getIndividualOfferNotesForOfferId,
    {
      data: offerNotes,
      isLoading: offerNoteDataIsLoading,
      isSuccess: offerNoteDataIsSuccess,
    },
  ] = useLazyGetIndividualOfferNotesForIdQuery();

  const [
    updateOfferNoteAsViewed,
    {
      isLoading: isUpdateOfferNoteAsViewedLoading,
      isSuccess: isUpdateOfferNoteAsViewedSuccess,
    },
  ] = useUpdateOfferNoteAsViewedMutation();

  useEffect(() => {
    if (isUpdateOfferNoteAsViewedSuccess) {
      setIsNotesVisible(false);
      loadList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateOfferNoteAsViewedLoading, isUpdateOfferNoteAsViewedSuccess]);

  useEffect(() => {
    loadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    effectiveDates,
    params.employerId,
    params.benefitKind,
    selectedEffectiveDate,
    planYear,
  ]);

  useEffect(() => {
    if (isCreateOfferNoteSuccess) {
      getIndividualOfferNotesForOfferId({ offerId: selectedOfferForNote.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateOfferNoteSuccess, isCreateOfferNoteLoading]);

  useEffect(() => {
    if (markedAsRecommended) {
      loadList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markedAsRecommended]);

  useEffect(() => {
    if (isDeleteOfferSuccess) {
      loadList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteOfferSuccess]);

  useEffect(() => {
    setActiveTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabState]);

  useEffect(() => {
    if (isPublishOrDraftSuccess) {
      loadList();
      setSelectedTab(tabToMove);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublishOrDraftSuccess]);

  const setEffectiveDatesForDropDown = () => {
    const mappedSet: any[] =
      upcomingData?.upcomingPlanYears
        ?.filter((obj: UpcomingPlanYearBasicDto) => obj.planYearId === planYear)
        .map((obj, index) => {
          return {
            label: dayjs(obj.effectiveStartDate)
              .format('ll')
              .concat(' - ', dayjs(obj.effectiveEndDate).format('ll')),
            value: (index + 1).toString(),
          };
        }) || [];

    mappedSet.unshift({ label: 'All', value: 0 });
    // set default selected value
    if (mappedSet.length) {
      setSelectedEffectiveDate(mappedSet[0].value);
    }

    setEffectiveDates(mappedSet);
  };

  const getButtonText = (category: string | undefined) => {
    if (!category) {
      return '-';
    }

    if (category === BenefitCategory.MEDICAL.value) {
      return '+ Add Medical Offer';
    } else if (category === BenefitCategory.DENTAL.value) {
      return '+ Add Dental Offer';
    } else if (category === BenefitCategory.VISION.value) {
      return '+ Add Vision Offer';
    } else if (category === 'LIFE-ADD') {
      return '+ Add Life/AD&D Offer';
    } else if (category === 'LTD') {
      return '+ Add LTD';
    } else if (category === 'STD') {
      return '+ Add STD';
    } else if (category === 'VOL-LIFE') {
      return '+ Add Voluntary Benefits Offer';
    }
  };

  const checkCategory = (category?: string): boolean => {
    return category === 'VOL-LIFE';
  };

  const setActiveTab = () => {
    if (activeTabState != null && activeTabState.activeStatus === 'DRAFT') {
      setSelectedTab(OFFER_CARD_STATUS.IN_PROGRESS);
    }
  };

  const getBenefitTypeText = (category: string | undefined) => {
    if (!category) {
      return '-';
    }
    if (category === BenefitCategory.MEDICAL.value) {
      return 'Medical';
    } else if (category === BenefitCategory.DENTAL.value) {
      return 'Dental';
    } else if (category === BenefitCategory.VISION.value) {
      return 'Vision';
    } else if (category === 'LIFE-ADD') {
      return 'Life/AD&D';
    } else if (category === 'LTD') {
      return 'LTD';
    } else if (category === 'STD') {
      return 'STD';
    } else if (category === 'VOL-LIFE') {
      return 'Voluntary Benefits';
    }
  };

  const getNoDataComponent = () => {
    if (effectiveDates?.length === 1) {
      return (
        <RenewalCommonAlert
          subText={NO_RENEWAL_DATA_DESCRIPTION}
          mainText={NO_RENEWAL_DATA}
          mainBtnTxt={NO_RENEWAL_PRIMARY_BTN_TXT}
          mainBtnHandler={() => {
            navigate(
              `/brokers/${params?.brokerId}/employers/${params.employerId}/renewals/carrier/summary`,
              {
                state: {
                  isFromProposalRenewalDate: true,
                },
              }
            );
          }}
          alertIcon={<NoRenewalsAlertIcon />}
          mainBtnStyling={styles.mainBtn}
          isFloatingNoDataView={false}
          isErAdmin={isErAdmin}
          isMainBtnDisabled={planYear != null}
        />
      );
    } else {
      return (
        <RenewalCommonAlert
          subText={NO_CATEGORY_OFFER_DATA}
          mainText={
            selectedTab === OFFER_CARD_STATUS.PUBLISHED
              ? isErAdmin
                ? `You don't have any ${getBenefitTypeText(
                    params.benefitKind?.toUpperCase()
                  )} Offers`
                : "You don't have any published offers"
              : "You don't have any offers saved as drafts"
          }
          mainBtnTxt={getButtonText(params.benefitKind?.toUpperCase())}
          mainBtnHandler={() => setIsAddOfferModalOpen(true)}
          alertIcon={<NoRenewalsAlertIcon />}
          mainBtnStyling={styles.mainBtn}
          isFloatingNoDataView={false}
          isErAdmin={isErAdmin}
          isMainBtnDisabled={planYear !== null}
        />
      );
    }
  };

  const getFetchPendingStatus = (
    category: string | undefined,
    isPublished: boolean
  ) => {
    if (category === BenefitCategory.MEDICAL.value) {
      return isPublished
        ? isMedicalPublishedOffersFetching
        : isMedicalDraftOffersFetching;
    } else if (category === BenefitCategory.DENTAL.value) {
      return isPublished
        ? isDentalPublishedOffersFetching
        : isDentalDraftOffersFetching;
    } else if (category === BenefitCategory.VISION.value) {
      return isPublished
        ? isVisionPublishedOffersFetching
        : isVisionDraftOffersFetching;
    } else if (category === 'LIFE-ADD') {
      return isPublished
        ? isLifePublishedOffersFetching
        : isLifeDraftOffersFetching;
    } else if (category === 'LTD') {
      return isPublished
        ? isLtdPublishedOffersFetching
        : isLtdDraftOffersFetching;
    } else if (category === 'STD') {
      return isPublished
        ? isStdPublishedOffersFetching
        : isStdDraftOffersFetching;
    } else if (category === 'VOL-LIFE') {
      return isPublished
        ? isVoluntaryBenefitPublishedOffersFetching
        : isVoluntaryBenefitDraftOffersFetching;
    }

    return false;
  };

  const changeOfferStatus = (offerId: string, isRecommended: boolean) => {
    setRecommendedStatusMarked(isRecommended);
    updateOfferStatusAsRecommended({
      offerId: offerId,
      isRecommended: isRecommended,
    });
  };

  const changeOfferPublishStatus = (offerId: string, status: string) => {
    setTabToMove(status);
    publishOrDraftSingleOffer({
      offerId: offerId,
      saveStatus: status,
    });
  };

  const validateAndSetStateProps = async (stateProps: SelectedPlanProps) => {
    setShowUpdateOfferInPropModal({
      isVisible: false,
      data: undefined,
    });
    const { docExtractionSource } = stateProps;
    if (!docExtractionSource?.sourceOfferId) return;

    const existsData = await existProposalByOffer({
      offerId: stateProps?.docExtractionSource?.sourceOfferId!,
      currentTs: new Date().getTime(),
    }).unwrap();

    if (!isEmpty(existsData)) {
      return setShowUpdateOfferInPropModal({
        isVisible: true,
        data: stateProps,
      });
    }
    setAddPlansPropsToState(stateProps);
  };

  const hasADraftOfferWithSameName = (
    publishedOfferName: any,
    draftOffers: any
  ) => {
    const existingDraftOffers = Array.isArray(draftOffers) ? draftOffers : [];
    return existingDraftOffers.some(
      (draftOffer) => draftOffer.name === publishedOfferName
    );
  };

  const getOfferCardList = (offers: Offer[] | null, isPublished: any) => {
    const draftOffers = getOffersByCategory(
      params.benefitKind?.toUpperCase(),
      false
    );

    return (
      <>
        {getFetchPendingStatus(
          params.benefitKind?.toUpperCase(),
          selectedTab === OFFER_CARD_STATUS.PUBLISHED
        ) ? (
          <Spin />
        ) : offers && offers.length > 0 ? (
          offers.map((offer: any, index: number) => {
            const hasSameNameDraftOffer = isPublished
              ? hasADraftOfferWithSameName(offer.name, draftOffers)
              : false;

            return (
              <OfferCard
                handleInternalNotesClick={handleInternalOfferNoteClick}
                status={selectedTab}
                detailPageNavigateUrl={`/brokers/${params?.brokerId}/employers/${params?.employerId}/renewals/carrier/${params.benefitKind}/${offer?.id}/detail`}
                offer={offer}
                key={index}
                selectedEffectiveDate={selectedEffectiveDate}
                isHide={checkCategory(params.benefitKind?.toUpperCase())}
                creditDropDownDefault={creditDropDownDefault}
                setCreditDropDownDefault={setCreditDropDownDefault}
                changeOfferStatus={changeOfferStatus}
                offerDeleteSuccess={checkOfferDeleteSuccess}
                offerEdit={onOfferEdit}
                offerClone={onCloneOffer}
                loadList={loadList}
                changeOfferPublishStatus={changeOfferPublishStatus}
                planYearId={planYear}
                hasSameNameDraftOffer={hasSameNameDraftOffer}
                setAddPlansPropsToState={validateAndSetStateProps}
              />
            );
          })
        ) : (
          getFetchSuccessStatus(
            params.benefitKind?.toUpperCase(),
            selectedTab === OFFER_CARD_STATUS.PUBLISHED
          ) &&
          offers?.length === 0 &&
          effectiveDates?.length > 0 && (
            <span className={styles.noUploadContent}>
              {getNoDataComponent()}
            </span>
          )
        )}
      </>
    );
  };

  const getOffersByCategory = (
    category: string | undefined,
    isPublished: boolean
  ) => {
    if (category === BenefitCategory.MEDICAL.value) {
      return isPublished ? medicalPublishedData : medicalDraftOfferData;
    } else if (category === BenefitCategory.DENTAL.value) {
      return isPublished ? dentalPublishedData : dentalDraftOfferData;
    } else if (category === BenefitCategory.VISION.value) {
      return isPublished ? visionPublishedData : visionDraftOfferData;
    } else if (category === 'LIFE-ADD') {
      return isPublished ? lifePublishedData : lifeDraftOfferData;
    } else if (category === 'LTD') {
      return isPublished ? ltdPublishedData : ltdDraftOfferData;
    } else if (category === 'STD') {
      return isPublished ? stdPublishedData : stdDraftOfferData;
    } else if (category === 'VOL-LIFE') {
      return isPublished
        ? voluntaryBenefitPublishedData
        : voluntaryBenefitDraftOfferData;
    }

    return [];
  };

  const getCategoryCount = (
    category: string | undefined,
    isPublished: boolean
  ) => {
    const offers = getOffersByCategory(category?.toUpperCase(), isPublished);
    return offers ? offers.length : '0';
  };

  useEffect(() => {
    const publishedCount = getCategoryCount(params.benefitKind, true);
    const draftCount = getCategoryCount(params.benefitKind, false);

    const noPublishedOffers = publishedCount === 0 && draftCount > 0;

    if (!isEmpMember) {
      setSelectedTab(
        noPublishedOffers
          ? OFFER_CARD_STATUS.IN_PROGRESS
          : OFFER_CARD_STATUS.PUBLISHED
      );
    } else {
      setSelectedTab(OFFER_CARD_STATUS.PUBLISHED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.benefitKind,
    isMedicalDraftOffersFetchSuccess,
    isMedicalPublishedOffersFetchSuccess,
    isDentalDraftOffersFetchSuccess,
    isDentalPublishedOffersFetchSuccess,
    isVisionDraftOffersFetchSuccess,
    isVisionPublishedOffersFetchSuccess,
    isLifeDraftOffersFetchSuccess,
    isLifePublishedOffersFetchSuccess,
    isStdDraftOffersFetchSuccess,
    isStdPublishedOffersFetchSuccess,
    isLtdDraftOffersFetchSuccess,
    isLtdPublishedOffersFetchSuccess,
    isVoluntaryBenefitDraftOffersFetchSuccess,
    isVoluntaryBenefitPublishedOffersFetchSuccess,
  ]);

  const getTabDataList = () => {
    const tabs: TabType[] = [];
    const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;
    tabs.push({
      tabLabel: ` Published to Employer (${getCategoryCount(
        params.benefitKind,
        true
      )})`,
      tabKey: OFFER_CARD_STATUS.PUBLISHED,
      tabData: getOfferCardList(
        getOffersByCategory(params.benefitKind?.toUpperCase(), true),
        true
      ),
    } as TabType);
    if (!isErAdmin) {
      tabs.push({
        tabLabel: `Draft (${getCategoryCount(params.benefitKind, false)})`,
        tabKey: OFFER_CARD_STATUS.IN_PROGRESS,
        tabData: getOfferCardList(
          getOffersByCategory(params.benefitKind?.toUpperCase(), false),
          false
        ),
      } as TabType);
    }

    return tabs;
  };

  const getAddBenefitsButtonText = (benefitKind: string | undefined) => {
    if (benefitKind === OFFER_BENEFIT_TYPES.MEDICAL.toUpperCase()) {
      return OFFER_BENEFIT_TYPES.MEDICAL;
    } else if (benefitKind === OFFER_BENEFIT_TYPES.DENTAL.toUpperCase()) {
      return OFFER_BENEFIT_TYPES.DENTAL;
    } else if (benefitKind === OFFER_BENEFIT_TYPES.VISION.toUpperCase()) {
      return OFFER_BENEFIT_TYPES.VISION;
    } else if (benefitKind === 'LIFE-ADD') {
      return OFFER_BENEFIT_TYPES.LIFE;
    } else if (benefitKind === 'STD') {
      return OFFER_CATEGORY.SHORT_TERM_DISABILITY;
    } else if (benefitKind === 'LTD') {
      return OFFER_CATEGORY.LONG_TERM_DISABILITY;
    } else if (benefitKind === 'VOL-LIFE') {
      return OFFER_BENEFIT_TYPES.VOLUNTARY_BENEFIT;
    }

    return null;
  };

  const generateEmptyDropDown = (txt: string): JSX.Element => {
    return (
      <p className={styles.noDataDropDownLabel}>
        {txt}
        <span className={styles.blueDash}>-</span>
      </p>
    );
  };

  const onOfferEdit = (offerId: string) => {
    setEditOfferVisible(true);
    setSelectedOfferId(offerId);
  };

  const onCloneOffer = (offerId: string) => {
    setCloneOffer(true);
    setSelectedOfferId(offerId);
    setEditOfferVisible(true);
  };

  useEffect(() => {
    if (!editOfferVisible) {
      setCloneOffer(false);
      dispatch(clear());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOfferVisible]);

  useEffect(() => {
    if (!isAddOfferModalOpen) {
      dispatch(clear());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddOfferModalOpen]);

  const { brokerId, employerId } = useParams();

  const handleCreateOfferNoteSubmit = (offerNote: CommonNoteDto) => {
    const offerNoteDto: OfferNoteDto = {
      offerId: selectedOfferForNote.id,
      comment: offerNote.commentTxt,
      files: offerNote.fileList,
      organizationId: String(brokerId),
      individualId: String(appBootInfo?.individualId),
    };

    createOfferInternalNote({ offerNote: offerNoteDto });
  };

  const handleFileItemDownloadClickForInternalNote = (
    s3Key: string,
    item: string
  ) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const url = `${baseApi}/v1/renewals/offers/offer-notes/download?s3-key=${s3Key}&file-name=${item}`;
    a.href = url;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const category = (params.benefitKind?.toUpperCase() as BenefitType) ?? '';

  const shouldSkip = !brokerId || !employerId || !category;

  const { data: quoteUploadList } = useGetQuoteUploadHistoryQuery(
    {
      brokerId: brokerId!,
      employerId: employerId!,
      category: category,
    },
    {
      skip: shouldSkip,
    }
  );

  const getReadyForReviewCount = () => {
    if (isOpsAdmin) {
      return quoteUploadList?.filter(
        (item) => item.status === ProcessStatus.SUCCESS && !item?.canceled
      ).length;
    } else {
      return quoteUploadList?.filter(
        (item) => item.status === ProcessStatus.REVIEWED && !item?.canceled
      ).length;
    }
  };

  const closeAlert = () => {
    setVisible(false);
    setAlertMessage({
      type: undefined,
      message: undefined,
    });
  };

  const onSavePlan = (
    isSuccess: boolean,
    successMessage?: string | undefined | null
  ) => {
    if (isSuccess) {
      setAlertMessage({
        type: 'success',
        message: successMessage ?? offeredPlanUpdate,
      });
      setVisible(true);
    }
    setAddPlansPropsToState(undefined);
    dispatch(
      RESET_ACTION_CREATOR[planBenefitType]?.(planBenefitType, employerId!)
    );
    dispatch(clearAiSbcUploader());
    loadList();
  };

  const onClosePlanModal = () => {
    dispatch(
      RESET_ACTION_CREATOR[planBenefitType]?.(planBenefitType, employerId!)
    );
    setAddPlansPropsToState(undefined);
    loadList();
  };

  const handleOpenModal = useCallback(
    ({ jobId, docExtractionSource }: PlanModalOpenType) => {
      setAddPlansPropsToState({ isOpen: true, jobId, docExtractionSource });
      dispatch(
        RESET_ACTION_CREATOR[planBenefitType]?.(planBenefitType, employerId!)
      );
    },
    [dispatch, employerId, planBenefitType]
  );

  const openDocExtractionPlanModal = useCallback(
    ({ jobId, docExtractionSource }: PlanModalOpenType) => {
      dispatch(
        setOPSView({
          jobId: jobId,
          planUploderType: isOpsAdmin
            ? UploaderType.OPS_VIEW
            : UploaderType.ADMIN_VIEW,
        })
      );
      handleOpenModal({ jobId, docExtractionSource });
    },
    [dispatch, handleOpenModal, isOpsAdmin]
  );

  const handleModalClose = (
    extractStatus: ProcessStatus,
    sendToOpsFailed?: boolean,
    docExtractionSource?: TDocumentExtractionSource
  ) => {
    const sendSelfToOpsSuccessMessage = sendToOpsFailed ? (
      <SendSelfToOPSSuccessComponent
        isSendOpsAdmin={true}
        onClick={() => setIsAiPlanModalOpen(true)}
      />
    ) : (
      sbcReviewStarted
    );

    const constructFailedMessage = (
      source?: DocumentExtractionSource | undefined
    ) => {
      if (source === DocumentExtractionSource.UPDATE_QUOTE_PLAN) {
        return planOfferFetchError;
      }
      return sbcfetchError;
    };

    return createModalCloseHandler({
      clearAction: () => dispatch(clearAiSbcUploader()),
      removeParamsAction: () =>
        removeSearchParams([SBC_PARAMS.isReview, SBC_PARAMS.jobId]),
      handleCloseAction: onClosePlanModal,
      setAlertMessageAction: setAlertMessage,
      setVisibleAction: setVisible,
      status: status,
      messages: {
        [ProcessStatus.PROCESSING]: sendSelfToOpsSuccessMessage,
        [ProcessStatus.FAILED]: constructFailedMessage(
          docExtractionSource?.source
        ),
      },
    })(extractStatus);
  };

  const handleUpdateOfferInPropModal = () => {
    setShowUpdateOfferInPropModal({
      isVisible: false,
      data: undefined,
    });
    setAddPlansPropsToState(showUpdateOfferInPropModal?.data);
  };

  const memoizedProposalOptions = useMemo(
    () =>
      attachedProposalList?.map((item: any) => ({
        label: item?.proposalName,
        value: item?.id,
      })),
    [attachedProposalList]
  );

  useEffect(() => {
    const quoteProcessingStatus = routerState?.quoteProcessingStatus;
    const planUpdatedMessage = routerState?.warning as AlertInfo;

    if (planUpdatedMessage) {
      setAlertMessage({
        type: planUpdatedMessage?.type,
        message: planUpdatedMessage?.message,
      });
      setVisible(true);
    }

    if (quoteProcessingStatus === QuoteProcessStatus.SUCCESS) {
      setQuoteProcessingBannerDetails({
        visible: true,
        content: (
          <div>
            {'The file has been saved under the '}
            <span
              className={styles.quotesUploadLink}
              onClick={() => setIsAiPlanModalOpen(true)}
            >
              Offer Uploads
            </span>
            {
              ' link and is currently being processed. You’ll receive a notification when processing is complete.'
            }
          </div>
        ),
      });
    }
  }, [routerState, navigate, location.pathname]);

  return (
    <div className={styles.offerCardsWrapper}>
      {quoteProcessingBannerDetails?.visible && (
        <FixedAlertMessage
          type="success"
          message={quoteProcessingBannerDetails?.content}
          closeAlert={() =>
            setQuoteProcessingBannerDetails({ visible: false, content: '' })
          }
        />
      )}
      {isSelfReviewToOps && (
        <FixedAlertMessage
          type="success"
          message={
            <p>
              The offer has been saved under the{' '}
              <span
                className={styles.quotesUploadLink}
                onClick={() => {
                  setIsAiPlanModalOpen(true);
                }}
              >
                Offer Uploads link
              </span>{' '}
              and will be processed within 1 business day. You’ll receive a
              notification when processing is complete.
            </p>
          }
          closeAlert={() => setIsSelfReviewToOps(false)}
        />
      )}
      <AlertMessageWrapper
        type={alertMessage.type}
        message={alertMessage.message}
        closeAlert={closeAlert}
        visible={visible}
      />
      <Row justify={'space-between'}>
        <h1>
          Carrier Offers -{' '}
          {getBenefitTitleFromUrlParam(params.benefitKind?.toUpperCase())}
        </h1>
        {isUpcomingFetchSuccess &&
          !isNoDataExist &&
          appBootInfo?.type !== loginTypes.erAdmin && (
            <div className={styles.buttonWrapperColumn}>
              <PageActionButton
                type="primary"
                onClick={() => {
                  setIsAddOfferModalOpen(true);
                }}
                dataCy="addBrokerBtn"
                className={styles.actionButton}
                disabled={null !== planYear}
              >
                + Add{' '}
                {getAddBenefitsButtonText(params.benefitKind?.toUpperCase())}{' '}
                Offer
              </PageActionButton>
              {params.benefitKind !==
                OFFER_BENEFIT_TYPE_NAVIGATION_LABELS.VOLUNTARY_BENEFIT && (
                <LinkButton
                  className={
                    getReadyForReviewCount() != 0
                      ? styles.linkButton
                      : styles.linkButtonWithoutRedDot
                  }
                  onClick={() => {
                    setIsAiPlanModalOpen(true);
                  }}
                >
                  <RfpIconSelected /> Offer Uploads
                  {getReadyForReviewCount() != 0 && (
                    <div className={styles.redDotIconArea}>
                      <RedDotIcon />({getReadyForReviewCount()})
                    </div>
                  )}
                </LinkButton>
              )}
            </div>
          )}
      </Row>
      <Row className={styles.filterationRow}>
        {isUpcomingFetchSuccess && (
          <Col>
            {isNoPlanYearDataExist && isNoDataExist ? (
              generateEmptyDropDown('Plan Year: ')
            ) : (
              <Row>
                <p className={styles.headerLabel}>Plan Year: </p>
                <SingleSelectFilter
                  data={planYears}
                  placeholder={''}
                  showSearch={false}
                  setFilterSelectedValue={(value: any) => {
                    dispatch(clearIsFromOfferNavigatePY());
                    setPlanYear(value);
                  }}
                  selectedValue={planYear}
                  defaultValue={null}
                  applicableIcon={<DropDownDarkArrow />}
                  inlineDropdown={true}
                  isDisabled={false}
                />
              </Row>
            )}
          </Col>
        )}

        {isUpcomingFetchSuccess && (
          <Col>
            {isNoDataExist ? (
              generateEmptyDropDown('Effective Dates: ')
            ) : (
              <Row>
                <p className={styles.headerLabel}>Effective Dates:</p>
                <SingleSelectFilter
                  data={effectiveDates}
                  placeholder={'-'}
                  showSearch={false}
                  setFilterSelectedValue={setSelectedEffectiveDate}
                  defaultValue={selectedEffectiveDate}
                  selectedValue={selectedEffectiveDate}
                  applicableIcon={<DropDownDarkArrow />}
                  inlineDropdown={true}
                  isDisabled={isNoDataExist}
                  isWiderWrapper={true}
                  isSelectionHighlightAndTickApplicable={false}
                />
              </Row>
            )}
          </Col>
        )}
      </Row>
      <TabView
        className={styles.tabsWrapper}
        tabOptions={getTabDataList()}
        defaultTabKey={selectedTab}
        onClick={() => {}}
        onChange={setSelectedTab}
      />
      <AddOfferModal
        isModalOpen={isAddOfferModalOpen}
        setIsModalOpen={setIsAddOfferModalOpen}
        isEdit={false}
        onAddOffer={loadList}
        setSelectedTab={setSelectedTab}
      />
      <AddOfferModal
        isModalOpen={editOfferVisible}
        setIsModalOpen={setEditOfferVisible}
        isEdit={true}
        offerId={selectedOfferId}
        onAddOffer={() => {
          loadList();
        }}
        isClonedOffer={isCloneOffer}
        setSelectedTab={setSelectedTab}
        onOpsRedirect={handleOpsRedirect}
      />
      <Drawer
        visible={isNotesVisible}
        onClose={handleNotesDrawerClose}
        closable={false}
        width={550}
        bodyStyle={{ padding: '40px 34px' }}
      >
        <NotesCommonContent
          handleFileItemClick={handleFileItemDownloadClickForInternalNote}
          noteName={selectedOfferForNote.name}
          isCreateLoading={isCreateOfferNoteLoading}
          handleSubmit={handleCreateOfferNoteSubmit}
          isFetchLoading={offerNoteDataIsLoading}
          listOfComments={offerNotes}
          individualId={String(appBootInfo?.individualId)}
          visibilityToggle={isNotesVisible}
          notesHeading={`Broker Notes (${
            !offerNoteDataIsSuccess ? '' : offerNotes.length
          })`}
        />
      </Drawer>
      <QuoteUploadHistoryModal
        benefit={params.benefitKind?.toUpperCase() as BenefitType}
        isOpen={isAiPlanModalOpen}
        onClose={() => setIsAiPlanModalOpen(false)}
        setEditOfferVisible={setEditOfferVisible}
        setSelectedOfferId={setSelectedOfferId}
        openDocExtractionPlanModal={openDocExtractionPlanModal}
        refetchOfferList={loadList}
      />
      <AddPlanModal
        {...addPlanPropsToState}
        benefit={planBenefitType}
        isRenewalProcessStarted={true}
        onClose={handleModalClose}
        onSave={onSavePlan}
        isOpen={addPlanPropsToState?.isOpen ?? false}
      />
      <UpdateConfirmationListModal
        visible={showUpdateOfferInPropModal.isVisible}
        confirmText="Continue"
        onConfirm={handleUpdateOfferInPropModal}
        title={UPDATE_PROPOSAL_TITLE_WARNING_NAME}
        listTitle="Proposal name"
        description={WARNING_OFFERED_PLAN_UPDATE}
        isCloseOnly={true}
        closeModal={() =>
          setShowUpdateOfferInPropModal(resetAttachedProposalAlert)
        }
        confirmBtnFullWidth={true}
        listData={memoizedProposalOptions}
        closeOnlyBtnFullWidth={true}
        onClickItem={(item: any) =>
          handleRedirectToProposalsDetails(item.value, { brokerId, employerId })
        }
        cancelText={'Cancel'}
        isCancelLink={true}
        isCancelApplicable={true}
      />
    </div>
  );
};

export default OfferCardList;
