import { FC, ReactNode } from 'react';

import Modal, { ModalProps } from 'antd/lib/modal/Modal';
import { CloseCircleOutlined } from '@ant-design/icons';

import { useStateCallback } from 'hooks/updateState';

import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

import iconWarning from 'assets/images/icon-warning.svg';

import ConfirmationModalAi from 'components/ConfirmationModalAi/ConfirmationModalAi';
import styles from './fullScreenModal.module.less';

export interface FullScreenModalProps extends ModalProps {
  showConfirm?: boolean;
  onCancelConfirm?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCancelCancel?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  confirmTitle?: ReactNode;
  showAiConfirm?: boolean;
}

const FullScreenModal: FC<FullScreenModalProps> = (props) => {
  const {
    showConfirm = false,
    onCancelConfirm,
    confirmTitle,
    onCancelCancel,
    children,
    className = '',
    okText,
    cancelText,
    showAiConfirm,
    ...rest
  } = props;

  return (
    <Modal
      width="100%"
      wrapClassName={`${styles.modalWrapper} ${className}`}
      className={styles.modalContent}
      maskClosable={false}
      closeIcon={
        showConfirm ? (
          onCancelConfirm && (
            <CloseIconWithConfirm
              onConfirm={onCancelConfirm}
              onCancel={onCancelCancel}
              confirmTitle={confirmTitle}
              okText={okText}
              cancelText={cancelText}
            />
          )
        ) : showAiConfirm ? (
          <CloseIconWithConfirmAi
            onConfirm={onCancelConfirm!}
            onCancel={onCancelCancel}
            confirmTitle={confirmTitle}
            okText={okText}
            cancelText={cancelText}
          />
        ) : (
          <CloseIcon onConfirm={onCancelConfirm} />
        )
      }
      {...rest}
    >
      {children}
    </Modal>
  );
};

const CloseIcon: FC<{
  onConfirm?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}> = ({ onConfirm }) => {
  return (
    <div onClick={onConfirm}>
      <CloseCircleOutlined />
      <div className={styles.closeText}>close</div>
    </div>
  );
};

type confirmProps = {
  onConfirm: Function;
  onCancel?: Function;
  confirmTitle?: ReactNode;
  okText?: ReactNode;
  cancelText?: ReactNode;
};
const CloseIconWithConfirm: FC<confirmProps> = ({
  onConfirm,
  onCancel,
  confirmTitle,
  okText = 'Yes, close window',
  cancelText = 'No, stay here',
}) => {
  const [visible, setVisible] = useStateCallback(false);

  const handleCancel = () => {
    onCancel?.();
    setVisible(false);
  };
  const onConfirmClick = () => {
    setVisible(false, () => {
      onConfirm();
    });
  };

  return (
    <ConfirmationModal
      title={<div className={styles.cancelBody}>{confirmTitle}</div>}
      icon={
        <img
          src={iconWarning}
          alt="warning-icon"
          className={styles.iconWarning}
        />
      }
      confirmModalTrigger={
        <div>
          <CloseIcon />
        </div>
      }
      onVisibleChange={() => {
        !visible && setVisible(true);
      }}
      visible={visible}
      onConfirm={onConfirmClick}
      onCancel={handleCancel}
      placement="bottomRight"
      okText={okText}
      cancelText={cancelText}
    />
  );
};

type confirmPropsAi = {
  onConfirm: Function;
  onCancel?: Function;
  confirmTitle?: ReactNode;
  okText?: ReactNode;
  cancelText?: ReactNode;
};
const CloseIconWithConfirmAi: FC<confirmPropsAi> = ({
  onConfirm,
  onCancel,
  confirmTitle,
  okText = 'Yes, close window',
  cancelText = 'No, stay here',
}) => {
  const [visible, setVisible] = useStateCallback(false);

  const handleCancel = () => {
    onCancel?.();
    setVisible(false);
  };
  const onConfirmClick = () => {
    setVisible(false, () => {
      onConfirm();
    });
  };

  return (
    <ConfirmationModalAi
      title={<div className={styles.cancelBody}>{confirmTitle}</div>}
      icon={
        <img
          src={iconWarning}
          alt="warning-icon"
          className={styles.iconWarning}
        />
      }
      confirmModalTrigger={
        <div>
          <CloseIcon />
        </div>
      }
      onVisibleChange={() => {
        !visible && setVisible(true);
      }}
      visible={visible}
      onConfirm={onConfirmClick}
      onCancel={handleCancel}
      placement="bottomRight"
      okText={okText}
      cancelText={cancelText}
    />
  );
};

export default FullScreenModal;
