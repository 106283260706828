import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Divider, Row } from 'antd';
import TextButton from 'components/buttons/TextButton/TextButton';
import {
  EDIT_PLAN_TABLE,
  IN_NETWORK_VALUE,
  OUT_NETWORK_VALUE,
} from 'modules/renewals/constants/renewalsConstants';
import AddPlanInputField from 'modules/renewals/components/AddPlanDetailsModal/AddPlanForm/AddPlanInputField/AddPlanInputField';
import {
  PartialArrayItemTemplate,
  Plan,
} from 'modules/renewals/types/planTypes';
import { findInArray } from 'modules/renewals/utils/addPlanDetailUtil';
import { EditDetailRxTiersTypes } from 'modules/renewals/types/renewalsTypes';
import styles from 'modules/renewals/components/AddPlanDetailsModal/AddPlanForm/addPlanForm.module.less';

type Props = {
  selectedData: Plan | any;
  setSelectedData: (data: Plan | any) => void;
  updateObject: (
    property: string,
    value: string | any,
    isArray: boolean,
    findObj?: any,
    isObj?: boolean,
    validations?: any
  ) => void;
  isQuotePlansApproved?: boolean;
};

const RX_TIER_COUNT = 8;

const RxSection = (props: Props) => {
  const { selectedData, setSelectedData, updateObject, isQuotePlansApproved } =
    props;
  const [rxTiers, setRxTiers] = useState<EditDetailRxTiersTypes>(
    EDIT_PLAN_TABLE?.MEDICAL?.RX_DEDUCTIBLES?.rxTiers
  );

  useEffect(() => {
    const filteredInNetworkValue = selectedData?.rxTiers?.filter(
      (item: PartialArrayItemTemplate) => item?.fieldType === IN_NETWORK_VALUE
    );
    const filteredOutOfNetworkValue = selectedData?.rxTiers?.filter(
      (item: PartialArrayItemTemplate) => item?.fieldType === OUT_NETWORK_VALUE
    );
    const tempRxTiers: any = {};
    filteredInNetworkValue?.forEach((item: PartialArrayItemTemplate) => {
      const findOutOfNetworkValue = findInArray(
        filteredOutOfNetworkValue,
        item?.name!,
        OUT_NETWORK_VALUE,
        null
      );

      tempRxTiers[item?.name!.replaceAll('Rx ', '')] = {
        inNetwork: {
          property: 'rxTiers',
          name: item?.name,
          fieldType: IN_NETWORK_VALUE,
          fieldSubType: null,
          added: item?.added,
        },
        outNetwork: {
          property: 'rxTiers',
          name: findOutOfNetworkValue?.name,
          fieldType: OUT_NETWORK_VALUE,
          fieldSubType: null,
          added: item?.added,
        },
      };
    });
    setRxTiers(tempRxTiers);
    // only need to run this once to generate fields
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rxDeductiblesAndOopMaxArray = Object.keys(
    EDIT_PLAN_TABLE?.MEDICAL?.RX_DEDUCTIBLES?.rxDeductiblesAndOopMax
  );
  const rxTierArray = Object.keys(rxTiers);

  /**
   * Adds a new tier to the Rx section. and added obj to selectedData
   */
  const handleAddTier = () => {
    const copyTiers = cloneDeep(rxTiers);
    const copySelectedData = cloneDeep(selectedData);
    const addedNewTierToDataObj = [
      ...copySelectedData?.rxTiers,
      {
        name: `Rx Tier ${rxTierArray.length + 1}`,
        fieldType: IN_NETWORK_VALUE,
        fieldSubType: null,
        value: null,
        formattedValue: null,
        added: true,
      },
      {
        name: `Rx Tier ${rxTierArray.length + 1}`,
        fieldType: OUT_NETWORK_VALUE,
        fieldSubType: null,
        value: null,
        formattedValue: null,
        added: true,
      },
    ];

    copyTiers[`Tier ${rxTierArray.length + 1}`] = {
      inNetwork: {
        property: 'rxTiers',
        name: `Rx Tier ${rxTierArray.length + 1}`,
        fieldType: IN_NETWORK_VALUE,
        fieldSubType: null,
        added: true,
      },
      outNetwork: {
        property: 'rxTiers',
        name: `Rx Tier ${rxTierArray.length + 1}`,
        fieldType: OUT_NETWORK_VALUE,
        fieldSubType: null,
        added: true,
      },
    };

    setSelectedData({ ...copySelectedData, rxTiers: addedNewTierToDataObj });
    setRxTiers(copyTiers);
  };

  /**
   * Handles the removal of a tier row. Removes the tier row from the Rx section and from selectedData
   * removed the added object.
   *
   * @param {string} value - The value associated with the tier row.
   * @param {PartialArrayItemTemplate} inNetworkObj - The in-network object of the tier row.
   * @param {PartialArrayItemTemplate} outOfNetworkObj - The out-of-network object of the tier row.
   */
  const handleRemoveTierRow = (
    value: string,
    inNetworkObj: PartialArrayItemTemplate,
    outOfNetworkObj: PartialArrayItemTemplate
  ) => {
    const copyTiers = cloneDeep(rxTiers);
    const copySelectedData = cloneDeep(selectedData);
    delete copyTiers[value];
    const removedTierArray = copySelectedData?.rxTiers?.filter(
      (item: PartialArrayItemTemplate) => item?.name !== inNetworkObj?.name
    );
    setSelectedData({ ...copySelectedData, rxTiers: removedTierArray });
    setRxTiers(copyTiers);
  };

  return (
    <>
      <div className={styles.subTitle}>Rx</div>
      <Divider className={styles.divider} />
      <div className={styles.innerWrapper}>
        <Row justify={'end'}>
          <div className={styles.inNetworkText}>In-Network</div>
          <div className={styles.outNetworkText}>Out-of-Network</div>
        </Row>

        {rxDeductiblesAndOopMaxArray?.map((item, index: number) => {
          return (
            <AddPlanInputField
              key={index}
              item={item}
              section={
                EDIT_PLAN_TABLE?.MEDICAL?.RX_DEDUCTIBLES
                  ?.rxDeductiblesAndOopMax[item]
              }
              updateObject={updateObject}
              selectedData={selectedData}
              isQuotePlansApproved={isQuotePlansApproved}
            />
          );
        })}

        {rxTierArray?.map((item, index: number) => {
          const removeLastItem =
            index === rxTierArray.length - 1 && rxTierArray.length > 1;
          return (
            <AddPlanInputField
              key={index}
              item={item}
              section={rxTiers[item]}
              updateObject={updateObject}
              selectedData={selectedData}
              enableRemoveRow={removeLastItem}
              handleRemoveRow={handleRemoveTierRow}
              isQuotePlansApproved={isQuotePlansApproved}
            />
          );
        })}
        <TextButton
          className={styles.addTierBtn}
          type="primary"
          label="+ Add Tier"
          onClick={handleAddTier}
          disabled={rxTierArray.length >= RX_TIER_COUNT}
        />
      </div>
    </>
  );
};

export default RxSection;
