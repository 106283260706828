import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import * as BrokerService from 'modules/brokers/services/BrokerService';
import Broker from 'model/Broker';
import ErrorDetails from 'model/ErrorDetails';
import PaginationConfig from 'model/PaginationConfig';
import MetaData from 'model/MetaData';

import { STATUS_200 } from 'constants/commonConstants';
import BrokerCount from 'model/BrokerCount';
import { getBlobFromEncodedImageString } from 'util/fileUtil';

export interface BrokerState {
  basicInfoCompleted: boolean;
  brokerCreateInProgress: boolean;
  brokerList: { content: Array<Broker>; metadata: MetaData };
  brokerCreateError: ErrorDetails | null;
  brokerCreationSuccess: boolean;
  brokerObj: Broker;
  brokerListInProgress: boolean;
  brokerListSuccess: boolean;
  logoUploadInProgress: boolean;
  logoUploadError: any;
  logoUrl: string | null;
  originalLogoUrl: string | null;
  originalLogo: string | null;
  brokerLogo: string | null;
  brokerInfoViewSuccess: boolean;
  brokerInfoViewError: ErrorDetails | null;
  brokerInfoViewInprogress: boolean;
  updateInProgress: boolean;
  brokerUpdateError: ErrorDetails | null;
  updateCompleted: boolean;
  savedBroker: { id: string };
  brokerCountInProgress: boolean;
  brokerCountError: ErrorDetails | null;
  brokerCount: BrokerCount;
}

const initialState = {
  brokerCreateInProgress: false,
  brokerObj: {} as Broker,
  brokerList: { content: [], metadata: {} },
  basicInfoCompleted: false,
  brokerCreateError: null,
  brokerCreationSuccess: false,
  brokerListInProgress: false,
  brokerListSuccess: false,
  logoUploadInProgress: false,
  logoUploadError: null,
  logoUrl: null,
  originalLogoUrl: null,
  originalLogo: null,
  brokerLogo: null,
  brokerInfoViewSuccess: false,
  brokerInfoViewError: null,
  brokerInfoViewInprogress: false,
  updateInProgress: false,
  brokerUpdateError: null,
  updateCompleted: false,
  savedBroker: { id: '' },
  brokerCountInProgress: false,
  brokerCountError: null,
  brokerCount: {} as BrokerCount,
} as BrokerState;

const brokerBasicInfoSlice = createSlice({
  name: 'brokers',
  initialState,
  reducers: {
    brokerCreateStarted: (state) => {
      state.brokerCreateInProgress = true;
      state.brokerCreateError = null;
      state.brokerCreationSuccess = false;
    },
    brokerCreateSucceeded: (state, action: PayloadAction<Broker>) => {
      state.brokerObj = action.payload;
      state.basicInfoCompleted = true;
      state.brokerCreationSuccess = false;
      state.brokerCreateInProgress = false;
    },
    brokerCreateAPISucceeded: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      state.savedBroker = action.payload;
      state.brokerCreationSuccess = true;
      state.brokerCreateInProgress = false;
    },
    brokerCreateFailed: (state, action: PayloadAction<ErrorDetails | null>) => {
      state.brokerCreateInProgress = false;
      state.brokerCreateError = action.payload;
      state.brokerCreationSuccess = false;
    },
    brokerListFetchStarted: (state) => {
      state.brokerListInProgress = true;
      state.brokerList = { content: [], metadata: {} };
      state.brokerListSuccess = false;
    },
    brokerListFetchSuccess: (state, { payload }) => {
      state.brokerListInProgress = false;
      state.brokerList = payload;
      state.brokerListSuccess = true;
    },
    brokerListFetchError: (state, { payload }) => {
      state.brokerListInProgress = false;
      state.brokerList = { content: [], metadata: {} };
      state.brokerListSuccess = false;
    },
    clearBrokerObject: (state) => {
      state.brokerObj = {} as Broker;
      state.brokerLogo = null;
      state.logoUrl = null;
      state.originalLogoUrl = null;
      state.originalLogo = null;
    },
    brokerLogoUploadStarted: (state, { payload }) => {
      state.logoUploadInProgress = true;
      state.brokerLogo = payload.brokerLogo;
      state.originalLogo = payload.originalLogo || null;
    },
    brokerLogoOriginalUploadSuccess(state, { payload }) {
      state.originalLogoUrl = payload.logoUrl;
    },
    brokerLogoUploadSuccess: (state, { payload }) => {
      state.logoUploadInProgress = false;
      state.logoUploadError = null;
      state.logoUrl = payload.logoUrl;
    },
    brokerLogoUploadFailed: (state, { payload }) => {
      state.logoUploadInProgress = false;
      state.logoUploadError = { response: payload };
    },
    clearBrokerCreateErr: (state) => {
      state.brokerCreateError = null;
    },
    resetSuccessFlag: (state) => {
      state.brokerCreationSuccess = false;
    },
    clearBrokerLogo: (state) => {
      state.brokerLogo = '';
      state.logoUrl = null;
      state.originalLogoUrl = null;
      state.originalLogo = null;
    },
    brokerInfoViewInprogress: (state) => {
      state.brokerInfoViewInprogress = true;
      state.brokerInfoViewError = null;
      state.brokerObj = {} as Broker;
      state.brokerInfoViewSuccess = false;
    },
    brokerInfoViewSuccess: (state, action: PayloadAction<Broker>) => {
      state.brokerObj = action.payload;
      state.brokerInfoViewSuccess = true;
      state.brokerInfoViewError = null;
    },
    brokerInfoViewFailed: (state, { payload }) => {
      state.brokerInfoViewInprogress = false;
      state.brokerInfoViewError = { data: payload?.data };
      state.brokerObj = {} as Broker;
      state.brokerInfoViewSuccess = false;
    },
    updateBrokerStarted: (state) => {
      state.updateInProgress = true;
      state.brokerUpdateError = null;
    },
    updateBrokerCompleted: (state, action) => {
      state.updateInProgress = false;
      state.updateCompleted = action.payload === STATUS_200;
      state.brokerUpdateError = null;
    },
    clearUpdateBrokerUpdation: (state) => {
      state.updateInProgress = false;
      state.updateCompleted = false;
      state.brokerUpdateError = null;
    },
    updateeBrokerFailed: (state, action) => {
      state.updateInProgress = false;
      state.updateCompleted = true;
      state.brokerUpdateError = action.payload;
    },
    brokerListCountInprogress: (state) => {
      state.brokerCountInProgress = true;
      state.brokerCountError = null;
    },
    brokerListCountSuccess: (state, action) => {
      state.brokerCount = action.payload;
      state.brokerCountInProgress = false;
      state.brokerCountError = null;
    },
    brokerListCountFailed: (state, { payload }) => {
      state.brokerCountInProgress = false;
      state.brokerCountError = { data: payload?.data };
    },
  },
});

export const {
  brokerCreateFailed,
  brokerCreateStarted,
  brokerCreateSucceeded,
  brokerListFetchError,
  brokerListFetchStarted,
  brokerListFetchSuccess,
  clearBrokerObject,
  brokerLogoUploadStarted,
  brokerLogoOriginalUploadSuccess,
  brokerLogoUploadSuccess,
  brokerLogoUploadFailed,
  clearBrokerCreateErr,
  clearBrokerLogo,
  brokerCreateAPISucceeded,
  resetSuccessFlag,
  brokerInfoViewInprogress,
  brokerInfoViewSuccess,
  brokerInfoViewFailed,
  updateBrokerStarted,
  updateBrokerCompleted,
  updateeBrokerFailed,
  clearUpdateBrokerUpdation,
  brokerListCountInprogress,
  brokerListCountSuccess,
  brokerListCountFailed,
} = brokerBasicInfoSlice.actions;
export default brokerBasicInfoSlice.reducer;

export const addBroker = (data: Broker) => (dispatch: Dispatch) => {
  dispatch(brokerCreateSucceeded(data));
};

export const createBroker = (data: Broker) => async (dispatch: Dispatch) => {
  dispatch(brokerCreateStarted());
  try {
    const response = await BrokerService.createBroker(data);
    dispatch(brokerCreateSucceeded(data));
    dispatch(brokerCreateAPISucceeded(response.data));
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        dispatch(brokerCreateFailed({ data: error.response.data }));
      } else {
        // todo handle errors
        console.log('Broker creation throws ' + JSON.stringify(error));
      }
    } else {
      // todo handle errors
      console.log('Broker creation throws ' + JSON.stringify(error));
    }
  }
};

export const getBrokerList = ({
  page,
  size,
  sort,
  query,
}: PaginationConfig) => {
  return async (dispatch: Dispatch) => {
    dispatch(brokerListFetchStarted());
    try {
      const response = await BrokerService.getBrokerList(
        page,
        size,
        sort,
        query
      );
      const data = response.data;
      dispatch(brokerListFetchSuccess(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          brokerListFetchError(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const clearBrokerData = () => (dispatch: Dispatch) => {
  dispatch(clearBrokerObject());
};

export const uploadBrokerLogo =
  (image: string, originalImage?: string) => async (dispatch: Dispatch) => {
    if (image) {
      dispatch(
        brokerLogoUploadStarted({
          brokerLogo: image,
          originalLogo: originalImage,
        })
      );
      try {
        if (originalImage) {
          const originalImageBlob = await getBlobFromEncodedImageString(
            originalImage
          );
          const originalImageResponse = await BrokerService.uploadBrokerLogo(
            originalImageBlob,
            true
          );
          dispatch(brokerLogoOriginalUploadSuccess(originalImageResponse.data));
        }
        const imageBlob = await getBlobFromEncodedImageString(image);
        const response = await BrokerService.uploadBrokerLogo(imageBlob);
        dispatch(brokerLogoUploadSuccess(response.data));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          dispatch(
            brokerLogoUploadFailed(JSON.parse(JSON.stringify(error.response)))
          );
        } else {
          console.error(error);
        }
      }
    }
  };

export const getBrokerInfoView = (brokerId: string) => {
  return async (dispatch: Dispatch) => {
    if (brokerId) {
      dispatch(brokerInfoViewInprogress());
      try {
        const response = await BrokerService.findBroker(brokerId);
        const data = response.data;
        dispatch(brokerInfoViewSuccess(data));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          dispatch(
            brokerInfoViewFailed(JSON.parse(JSON.stringify(error.response)))
          );
        } else {
          console.error(error);
        }
      }
    }
  };
};

export const updateBroker = (brokerId: string, data: Broker) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateBrokerStarted());
    try {
      const response = await BrokerService.updateBroker(brokerId, data);
      dispatch(updateBrokerCompleted(response.status));
    } catch (error: any) {
      dispatch(updateeBrokerFailed(error.response));
    }
  };
};

export const getBrokerCount = () => {
  return async (dispatch: Dispatch) => {
    dispatch(brokerListCountInprogress());
    try {
      const response = await BrokerService.getBrokerCount();
      const data = response.data;
      dispatch(brokerListCountSuccess(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          brokerListCountFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};
