/**
 * Regex to check if string has mix of uppercase and lowercase characters with
 * at least one special character and number
 */
const PASSWORD_COMPLEXITY_REGEX =
  /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[0-9]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,}).{8,}$/;

export function validatePasswordComplexity(password: string): boolean {
  return PASSWORD_COMPLEXITY_REGEX.test(password);
}
