import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Form, notification } from 'antd';
import { isEmpty, isEqual } from 'lodash';

import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import PlanBasicForm from 'modules/plans/components/PlanBasicForm/PlanBasicForm';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { AdditionalPlan } from 'model/plans/AdditionalPlan';
import { useNavContext } from 'hooks/useNavContext';
import {
  AdditionalResourcesRadioTypes,
  VALIDATION_NAME_DUPLICATED,
} from 'modules/plans/constants';
import {
  BenefitCategory,
  benefitGroupsChangedMsg,
  BenefitKind,
  EMPTY_MESSAGE,
  formVerificationMsg,
  planAlreadyExistsError,
  planYearChangedMsg,
} from 'constants/commonConstants';
import {
  clearWellBeingPlanApiErrors,
  findWellbeingPlanById,
  saveWellbeingPlan,
} from 'modules/plans/slices/wellbeingPlanSlice';
import { getCarriersByBenefitKind } from 'modules/plans/slices/basicPlanInfoSlice';
import {
  ERROR_MESSAGE_PLAN_EDITING,
  PLAN_NOTIFICATION_KEY,
} from 'constants/benguideCollaborationConstants';

import AdditionalPlanResources from 'modules/plans/components/AdditionalPlanResources/AdditionalPlanResources';
import FileType from 'model/plans/FileType';
import WebLinkType from 'model/plans/WebLinkType';
import {
  removePlanDocuments,
  updateWeblinks,
  uploadPlanDocument,
  uploadRemovePlanDocument,
} from 'modules/plans/slices/planDocumentsSlice';
import styles from './addWellbeingPlan.module.less';

export const WellbeingPlanBasicInfoFormFields = {
  requiredFields: ['planName', 'planYear', 'benefitClass'],
  formFields: [
    'planName',
    'planYear',
    'benefitCarrier',
    'benefitClass',
    'file',
    'weblink',
    'groupId',
  ],
};
type AddWellbeingPlanProps = {
  onClose: () => void;
  closePlanAddModal: () => void;
  isCloseConfirmed: boolean;
  isPlanUpdateMode?: boolean;
  plan?: any;
  isSaveDisabled?: boolean;
  dbgPlanYear?: string;
  isDBGPlan?: boolean;
  isModalVisible?: boolean;
};
const formInitialValues = {
  planDocumentName: '',
  benefitCarrier: '',
  planYear: '',
  benefitClass: [],
  groupId: '',
};

const AddWellbeingPlan = forwardRef((props: AddWellbeingPlanProps, ref) => {
  const {
    isCloseConfirmed,
    onClose,
    closePlanAddModal,
    isPlanUpdateMode,
    plan,
    isSaveDisabled = false,
    dbgPlanYear,
    isDBGPlan,
    isModalVisible,
  } = props;

  const dispatch = useAppDispatch();
  const { employerId, brokerId } = useNavContext();
  const { planYearsList } = useAppSelector((state) => state.employer.employer);
  const basicPlanInfo = useAppSelector((state) => state.plan.planBasicInfo);
  const { error, inProgress } = useAppSelector((state) => state.plan.wellbeing);
  const { inProgress: documentUploadInProgress } = useAppSelector(
    (state) => state.plan.planDocuments
  );

  const { carriersList } = basicPlanInfo;

  const [form] = Form.useForm();
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const [alertMessage, setAlertMessage] = useState<AlertInfo>({
    type: undefined,
    message: '',
  });
  const [additionalResourceType, setAdditionalResourceType] =
    useState<string>('');
  const [formData, setFormData] = useState(formInitialValues);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const additionalResourcesRef = useRef<any>();
  const basicPlanInfoRef = useRef<any>();
  const [notificationKey] = useState(PLAN_NOTIFICATION_KEY);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPlanYearWarning, setShowPlanYearWarning] =
    useState<boolean>(false);

  const [selectedFileList, setSelectedFileList] = useState<FileType[]>([]);
  const [selectedWeblink, setSelectedWeblink] = useState<WebLinkType[]>([]);
  const [isDocRemoved, setIsDocRemoved] = useState<{
    [key: string]: boolean;
  }>({});

  useImperativeHandle(ref, () => ({
    validate() {
      return getValidationResult();
    },
    reset() {
      setSelectedFileList([]);
      setSelectedWeblink([]);
      setShowWarning(false);
      setShowPlanYearWarning(false);
      form.resetFields();
      setAdditionalResourceType('');
    },
  }));

  useEffect(() => {
    if (isCloseConfirmed) {
      setFormData(formInitialValues);
      basicPlanInfoRef.current?.resetForm();
      setAdditionalResourceType('');
      setShowAlert(false);
    }
  }, [form, isCloseConfirmed]);

  useEffect(() => {
    if (brokerId && employerId && isModalVisible) {
      dispatch(
        getCarriersByBenefitKind(
          BenefitCategory.WELLBEING.value,
          brokerId,
          employerId
        )
      );
    }
  }, [dispatch, brokerId, employerId, isModalVisible]);
  useEffect(() => {
    if (error?.data?.code === VALIDATION_NAME_DUPLICATED) {
      form.setFields([{ name: 'planName', errors: [EMPTY_MESSAGE] }]);

      setAlertMessage({
        type: 'error',
        message: planAlreadyExistsError,
      });
      setShowAlert(true);
      setShowAlert(true);
      dispatch(clearWellBeingPlanApiErrors());
      setLoading(false);
    }
  }, [error, form, dispatch]);

  useEffect(() => {
    if (additionalResourceType === AdditionalResourcesRadioTypes.FILE) {
      form.setFieldsValue({ weblink: '' });
    } else if (
      additionalResourceType === AdditionalResourcesRadioTypes.WEBLINK
    ) {
    }
  }, [form, additionalResourceType]);

  useEffect(() => {
    setShowAlert(false);
    setShowWarning(false);
    setShowPlanYearWarning(false);
    if (isPlanUpdateMode && !isEqual(plan?.groups, formData.benefitClass)) {
      setShowWarning(true);
      setShowPlanYearWarning(false);
      setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
      setShowAlert(true);
    }
    if (
      isPlanUpdateMode &&
      !isEqual(plan?.planYearId, formData.planYear?.trim())
    ) {
      setShowPlanYearWarning(true);
      setAlertMessage({ message: planYearChangedMsg, type: 'warning' });
      setShowAlert(true);
    }
    if (
      isPlanUpdateMode &&
      !isEqual(plan?.planYearId, formData.planYear?.trim()) &&
      !isEqual(plan?.groups, formData.benefitClass)
    ) {
      setShowPlanYearWarning(true);
      setShowWarning(true);
      setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
      setShowAlert(true);
    }
  }, [plan, formData.benefitClass, isPlanUpdateMode, formData.planYear]);

  useEffect(() => {
    if (plan) {
      const { name, groups, planYearId, benefitCarrier, groupId } = plan;

      const { id } = benefitCarrier || {};
      const formData = {
        planName: name,
        benefitClass: groups,
        planYear: planYearId,
        benefitCarrier: id ? id : null,
        groupId: groupId,
        planDocumentName: '',
      };

      form.setFieldsValue(formData);
      setFormData(formData);
    }
  }, [setFormData, form, plan]);

  const validateBasicInfo = async () => {
    try {
      await form.validateFields(
        WellbeingPlanBasicInfoFormFields.requiredFields
      );
      return true;
    } catch (errorInfo: any) {
      return errorInfo.errorFields.length === 0;
    }
  };

  const getValidationResult = async () => {
    const isFormValid = await validateBasicInfo();
    if (isFormValid) {
      return true;
    } else {
      setShowAlert(true);
      return false;
    }
  };

  const closeOnSave = async (id: string) => {
    await uploadDocument(id);
  };

  const uploadDocument = async (id: string) => {
    const uploadDocuementSuccess = () => {
      setSelectedFileList([]);
      setSelectedWeblink([]);
      setShowWarning(false);
      setShowPlanYearWarning(false);
      setAdditionalResourceType('');
      setShowAlert(false);
      onClose();
      if (plan && plan.id) {
        dispatch(findWellbeingPlanById(plan.id));
      }
    };

    const planId = id ?? plan.id;
    const removeDocs = Object.keys(isDocRemoved)
      .filter((documentType) => isDocRemoved[documentType])
      .map((documentType) => ({
        docType: 'PLAN_ADDITIONAL_DOCUMENT',
        planDocumentName: documentType,
      }));
    if (!isEmpty(selectedFileList) && isEmpty(removeDocs)) {
      await dispatch(
        uploadPlanDocument(
          selectedFileList,
          planId,
          BenefitKind.WELLBEING_GENERIC.value
        )
      );
    } else if (!isEmpty(removeDocs) && isEmpty(selectedFileList)) {
      await dispatch(
        removePlanDocuments(
          planId,
          BenefitKind.WELLBEING_GENERIC.value,
          removeDocs
        )
      );
    } else if (!isEmpty(removeDocs) && !isEmpty(selectedFileList)) {
      await dispatch(
        uploadRemovePlanDocument(
          selectedFileList,
          planId,
          BenefitKind.WELLBEING_GENERIC.value,
          removeDocs
        )
      );
    }
    if (selectedWeblink.length > 0) {
      await dispatch(
        updateWeblinks(
          selectedWeblink,
          planId,
          BenefitKind.WELLBEING_GENERIC.value
        )
      );
    }
    uploadDocuementSuccess();
  };

  const onClickSave = async () => {
    setShowAlert(false);
    setShowWarning(false);
    setShowPlanYearWarning(false);
    const isFormValid = await getValidationResult();

    const formValues = form.getFieldsValue();
    const { benefitClass, planName, planYear, benefitCarrier, groupId } =
      formValues;
    const selectedPlanYear = planYearsList.find((t) => t.id === planYear);
    const newPlan = {
      ...plan,
      benefitKind: BenefitKind.WELLBEING_GENERIC.value,
      employerId: employerId,
      planYearId: planYear?.trim(),
      name: planName,
      benefitCarrierId: benefitCarrier ? benefitCarrier.trim() : null,
      startDate: selectedPlanYear?.startDate,
      endDate: selectedPlanYear?.endDate,
      groups: benefitClass,
      groupId: groupId ? groupId : '',
    } as AdditionalPlan;

    delete newPlan.additionalDocumentReferences;
    delete newPlan.additionalWeblinks;

    if (isEmpty(newPlan?.documentReferences)) {
      delete newPlan.documentReferences;
    }

    if (isPlanUpdateMode) {
      newPlan.id = plan.id;
    }

    if (isFormValid) {
      if (isPlanUpdateMode && isDBGPlan) {
        setLoading(true);
      }

      await dispatch(
        saveWellbeingPlan(newPlan, (id: string) => {
          closeOnSave(id);
        })
      );
      notification.close(notificationKey);
    } else {
      setAlertMessage({
        type: 'error',
        message: formVerificationMsg,
      });
      setShowAlert(true);
      setShowAlert(true);
    }
  };

  const onCancel = () => {
    setShowWarning(false);
    setShowPlanYearWarning(false);
    closePlanAddModal();
  };

  return (
    <div
      className={`${
        isPlanUpdateMode ? styles.planEditModeWrapper : styles.wrapperForm
      }  `}
    >
      {showAlert && (
        <AlertMessage
          type={alertMessage.type}
          className={
            isPlanUpdateMode ? styles.alertWrapper : styles.alertMessageWrapper
          }
          message={alertMessage.message}
          closeAlert={() => {
            setShowAlert(false);
          }}
        />
      )}
      {isSaveDisabled && !plan && (
        <FixedAlertMessage
          type={'error'}
          message={ERROR_MESSAGE_PLAN_EDITING}
        />
      )}
      <PlanBasicForm
        carriersList={carriersList}
        setRequiredFieldError={setShowAlert}
        setFormData={setFormData}
        formData={formData}
        form={form}
        ref={basicPlanInfoRef}
        isPlanUpdateMode={isPlanUpdateMode}
        benefitCategory={BenefitCategory.WELLBEING.value}
        showWarning={showWarning}
        dbgPlanYear={dbgPlanYear}
        isDBGPlan={isDBGPlan}
        isModalVisible={isModalVisible}
        isCarrierRequired={false}
        showPlanYearWarning={showPlanYearWarning}
      />
      <div className={styles.header}>Plan Resources</div>
      <AdditionalPlanResources
        ref={additionalResourcesRef}
        plan={plan}
        benefitKind={BenefitCategory.WELLBEING.value}
        isCloseConfirmed={isCloseConfirmed}
        selectedFileList={selectedFileList}
        setSelectedFileList={setSelectedFileList}
        selectedWebLinkList={selectedWeblink}
        setSelectedWebLinkList={setSelectedWeblink}
        setIsDocRemoved={setIsDocRemoved}
      />
      <div className={styles.btnWrapper}>
        <SubmitButton
          disabled={isSaveDisabled}
          type="primary"
          className={styles.saveButtonWrapper}
          onClick={() => onClickSave()}
          loading={inProgress || loading || documentUploadInProgress}
        >
          {isPlanUpdateMode ? 'Done' : 'Save'}
        </SubmitButton>
        <CancelButton
          className={styles.cancelButtonWrapper}
          onClick={() => onCancel()}
          withBorder={isPlanUpdateMode}
        >
          Cancel
        </CancelButton>
      </div>
    </div>
  );
});
AddWellbeingPlan.displayName = 'AddWellbeingPlan';
export default AddWellbeingPlan;
