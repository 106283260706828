// @flow
import React from 'react';

import { Table } from 'antd';

import { useNavigate } from 'react-router-dom';
import { useNavContext } from 'hooks/useNavContext';
import styles from './LargeClaimsTableDashboard.module.less';

type Props = {
  prefixClass?: string;
  title?: string;
  squareProps?: any;
  tableHeaders: any[];
  dataSource: any[];
};

const getColumns = (tableHeaders: any[]) => {
  return (
    tableHeaders &&
    tableHeaders.map((value, index) => ({
      title: index < 2 ? '' : value.title,
      dataIndex: value.key,
      key: value.key,
      width: value.width,
      className: `cell ${value.key}`,
    }))
  );
};

const LargeClaimsTable = (props: Props) => {
  const { tableHeaders, dataSource } = props;
  const navigate = useNavigate();
  const { brokerId, employerId } = useNavContext();
  return (
    <div className={styles.largeClaimsTable}>
      <p
        className={styles.viewMore}
        onClick={() => {
          navigate(`/brokers/${brokerId}/employers/${employerId}/claims`);
        }}
      >
        View More
      </p>
      <Table
        rowKey={(record: { month: any }, index: any) =>
          `${record.month}_${index}`
        }
        columns={getColumns(tableHeaders)}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  );
};

export default LargeClaimsTable;
