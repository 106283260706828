import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { useNavContext } from 'hooks/useNavContext';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';

import styles from './quickLinks.module.less';

type QuickLinksProps = {
  createBenefitGuideAction?: () => void;
};

const benguideHelpButton = (label: string, onClick: () => void) => {
  return (
    <div className={styles.benguideHelpOptions}>
      <PageActionButton
        type="primary"
        className={styles.actionButton}
        onClick={onClick}
      >
        <span className="benguide-help-option">{label}</span>
      </PageActionButton>
    </div>
  );
};

const QuickLinks: FC<QuickLinksProps> = (props) => {
  const { brokerId, employerId } = useNavContext();
  const navigate = useNavigate();

  return (
    <div className={styles.quickLink}>
      <h2>Quick Links</h2>
      {/* TODO: need to set onClick */}
      {benguideHelpButton('Manage Benefits', () =>
        navigate(`/brokers/${brokerId}/employers/${employerId}/medical`)
      )}
      {benguideHelpButton('Manage Employer Users', () =>
        navigate(`/brokers/${brokerId}/employers/${employerId}/info`)
      )}
    </div>
  );
};

export default QuickLinks;
