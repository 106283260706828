import { FC, ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Menu, Popover, Select, Table, TablePaginationConfig } from 'antd';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import Icon from '@ant-design/icons';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import { DataColumn } from 'components/DataTable/DataColumn';
import AutomatedEmailSetupModal from 'modules/tools/components/AutomatedEmailSetupModal/AutomatedEmailSetupModal';
import { ReactComponent as DownloadIcon } from 'assets/images/icon-download-black.svg';
import iconConnectionPending from 'assets/images/icon-connection-pending.svg';
import { ISSUE_TYPE_SUPPORT } from 'modules/issueslog/constants/IssuesLogConstants';
import iconWarning from 'assets/images/alert-warning.svg';
import iconQuestion from 'assets/images/icon-question.svg';
import { useLazyGetSupportEmailsQuery } from 'modules/tools/slices/issuesLogProvisioningSlice';
import { downloadInstructionPdf } from 'modules/issueslog/slices/issuesLogSlice';
import { showSuccessNotification } from 'components/Notification/Notification';
import AutomatedEmailDeleteModal from 'modules/tools/components/AutomatedEmailDeleteModal/AutomatedEmailDeleteModal';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import ConfigureAutomatedEmailModal from 'modules/tools/components/ConfigureAutomatedEmailModal/ConfigureAutomatedEmailModal';
import ResendAutomatedEmailModal from 'modules/tools/components/ResendAutomatedEmailModal/ResendAutomatedEmailModal';
import selectIcon from 'assets/images/icon-caret-down.svg';
import tableStyles from 'components/DataTable/datatable.module.less';
import PaginationNav from 'components/TableCustomPagination/TableCustomPagination';
import { usePermitByUserType } from 'hooks/usePermitByUserType';
import {
  ALLOWED_INDIVIDUAL_SUB_TYPES,
  IndividualSubTypes,
} from 'constants/commonConstants';

import styles from './featureList.module.less';

type SupportTeamProvisionProps = {};

const SupportTeamProvision: FC<SupportTeamProvisionProps> = (
  props: SupportTeamProvisionProps
) => {
  const { brokerId } = useParams();

  const [automatedEmailSetupModalOpen, setAutomatedEmailSetupModalOpen] =
    useState<boolean>(false);
  const [configurationModalOpen, setConfigurationModalOpen] =
    useState<boolean>(false);
  const [automatedEmailDeleteModal, setAutomatedEmailDeleteModal] =
    useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [deleteSupportTeam, setDeleteSupportTeam] = useState<boolean>(false);
  const [resendEmailModalOpen, setResendEmailModal] = useState<boolean>(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: 10,
    showTotal: (total, range) => (
      <div className={styles.totalCount}>
        {range[0]}-{range[1]} of {total}
      </div>
    ),
    itemRender: PaginationNav,
  });
  const [getSupportEmails, { data: supportTeamList, isLoading }] =
    useLazyGetSupportEmailsQuery();

  useEffect(() => {
    getSupportEmails({
      organizationId: brokerId,
      currentTs: new Date().getTime(),
    });
    // eslint-disable-next-line
  }, []);

  const showSaveNotification = () => {
    showSuccessNotification({
      message: (
        <div className={styles.successMailTitle}>Automated Email Added</div>
      ),
      description: `Confirmation of email connection will take a few minutes. Please refresh the page to check the status.`,
      key: 'automatedEmailAdded',
    });
  };

  const SecureDeleteSupportTeam = (row: any): ReactElement | null => {
    return usePermitByUserType(
      <Menu.Item
        key="delete"
        onClick={() => {
          setSelectedRow(row?.row);
          setDeleteSupportTeam(true);
          setAutomatedEmailDeleteModal(true);
        }}
      >
        Delete
      </Menu.Item>,
      ALLOWED_INDIVIDUAL_SUB_TYPES
    );
  };

  const brokerSupportActionsMenu = (row: any) => {
    return (
      <Menu>
        <Menu.Item
          key="view"
          onClick={() => {
            setSelectedRow(row);
            setConfigurationModalOpen(true);
          }}
        >
          Manage Configuration
        </Menu.Item>
        <SecureDeleteSupportTeam row={row} />
      </Menu>
    );
  };

  const SecuredSetupEmail = (row: any): any =>
    usePermitByUserType(
      <div
        className={styles.setUpMailLink}
        onClick={() => {
          setSelectedRow(row?.row);
          setAutomatedEmailSetupModalOpen(true);
        }}
      >
        Setup automated email
      </div>,
      [IndividualSubTypes.BROKER_ADMIN, IndividualSubTypes.SUPER_BROKER_ADMIN]
    );

  const SecuredConnectionConfirmed = (row: any): ReactElement => {
    return (
      usePermitByUserType(
        <img
          className={styles.iconRetry}
          src={iconConnectionPending}
          alt=""
          onClick={() => {
            setSelectedRow(row?.row);
            setResendEmailModal(true);
          }}
        />,
        [IndividualSubTypes.BROKER_ADMIN, IndividualSubTypes.SUPER_BROKER_ADMIN]
      ) || <></>
    );
  };

  const SecuredConnectionPopover = (row: any): ReactElement => {
    return (
      usePermitByUserType(
        <Popover
          overlayClassName="provisionStatusPopover"
          placement="left"
          content={
            'Confirming the connection may take several minutes. Return to this page later to see an updated status or send another test email.'
          }
        >
          <img src={iconWarning} className={styles.iconWarning} alt="" />
        </Popover>,
        ALLOWED_INDIVIDUAL_SUB_TYPES
      ) || <></>
    );
  };

  const dataColumns: DataColumn[] = [
    {
      title: 'SUPPORT TEAM NAME',
      dataIndex: 'name',
      key: 'name',
      width: '30%',

      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      defaultSortOrder: 'ascend',
      showSorterTooltip: false,
    },
    {
      title: 'AUTOMATED EMAIL',
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
      render: (current: any, row: any) => {
        if (row?.email) {
          return (
            <div className={styles.automatedEmail}>
              <OverflowPopover popoverContent={row?.email}>
                {row?.email}
              </OverflowPopover>
            </div>
          );
        }

        return <SecuredSetupEmail row={row} />;
      },
    },
    {
      title: 'AUTOMATED EMAIL STATUS',
      dataIndex: 'automatedEmailStatus',
      key: 'automatedEmailStatus',
      width: '30%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        if (!a?.email) {
          return 1;
        }
        if (a?.isVerified && !b?.isVerified) {
          return 1;
        } else {
          return -1;
        }
      },
      render(_, row, index) {
        if (!row?.email) {
          return <div>-</div>;
        }
        if (row?.isVerified) {
          return <>Connection confirmed</>;
        }
        return (
          <div className={styles.inProgresStatusWrapper}>
            <SecuredConnectionPopover row={row} />
            Connection pending
            <SecuredConnectionConfirmed row={row} />
          </div>
        );
      },
    },
    {
      title: '# OPEN ITEMS',
      dataIndex: 'openIssues',
      key: 'openIssues',
      width: '20%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        if (a?.summary?.openIssues > b?.summary?.openIssues) {
          return 1;
        } else {
          return -1;
        }
      },
      render: (_, record, index) => {
        const openIssues = parseInt(record?.summary?.openIssues || '0');
        return <>{openIssues ? openIssues : '-'}</>;
      },
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'lastUpdatedTs',
      key: 'lastUpdatedTs',
      width: '30%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (isEmpty(a?.summary?.lastUpdatedTs)) {
          return 1;
        } else if (isEmpty(b?.summary?.lastUpdatedTs)) {
          return -1;
        } else {
          return new Date(a?.summary?.lastUpdatedTs).getTime() >
            new Date(b?.summary?.lastUpdatedTs).getTime()
            ? 1
            : -1;
        }
      },
      render: (_, record, index) => {
        const updatedAt = dayjs(record?.summary?.lastUpdatedTs).format(
          'MMMM D, YYYY h:mm A'
        );
        return isEmpty(record?.summary?.lastUpdatedTs) ? (
          <div key={index}>-</div>
        ) : (
          <div key={index}>
            {updatedAt}
            <br></br>
            <span className={styles.addedBy}>
              <OverflowPopover
                popoverContent={record?.summary?.lastUpdatedUser}
                maxWidth="85%"
              >
                by {record?.summary?.lastUpdatedUser}
              </OverflowPopover>
            </span>
          </div>
        );
      },
    },

    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '15%',
      render(_, row) {
        return <SelectDropdown overlay={brokerSupportActionsMenu(row)} />;
      },
    },
  ];

  const securedDownloadProvisionInstructions = usePermitByUserType(
    <>
      <span className={styles.downloadLinks}>
        <a
          className={styles.downloadFileLink}
          onClick={() => {
            downloadInstructionPdf(brokerId);
          }}
        >
          Download Email Setup Instructions
          <DownloadIcon className={styles.downloadFileIcon} />
          <Icon type="file-text" />
        </a>
      </span>
      <div
        className={`${styles.addSupportTeamWrapper} ${
          isEmpty(supportTeamList) ? styles.noData : ''
        }`}
      >
        <span
          onClick={() => {
            setSelectedRow(null);
            setAutomatedEmailSetupModalOpen(true);
          }}
        >
          + Add Support Team
        </span>
        <Popover
          overlayClassName="provisionStatusPopover"
          placement="left"
          content={
            'Click here to add a support team inbox for automated ticket creation.'
          }
        >
          <img src={iconQuestion} alt="" />
        </Popover>
      </div>
    </>,
    ALLOWED_INDIVIDUAL_SUB_TYPES
  );

  return (
    <div className={styles.accountTeamProvisionWrapper}>
      <div className={styles.title}>
        <h1>Support Log Configuration</h1>
      </div>
      {!isLoading && securedDownloadProvisionInstructions}
      <Table
        className={`${tableStyles.tableWrapper} ${styles.tableWrapper}`}
        dataSource={supportTeamList}
        columns={dataColumns}
        tableLayout="fixed"
        loading={isLoading}
        pagination={isEmpty(supportTeamList) ? false : pagination}
      />

      {!isEmpty(supportTeamList) && (
        <Select
          value={pagination.pageSize}
          onChange={(e) => {
            setPagination({ ...pagination, pageSize: e });
          }}
          bordered={false}
          className={`${tableStyles.rowDropdown} ${styles.rowDropdown}`}
          suffixIcon={<img src={selectIcon} alt="select-icon" />}
        >
          {[10, 20, 50].map((pageSize) => (
            <Select.Option key={pageSize} value={pageSize}>
              View: {pageSize} Rows
            </Select.Option>
          ))}
        </Select>
      )}

      <ConfigureAutomatedEmailModal
        isModalOpen={configurationModalOpen}
        setIsModalOpen={setConfigurationModalOpen}
        supportTeam={selectedRow}
        refetchForSupportEmails={() =>
          getSupportEmails({
            organizationId: brokerId,
            currentTs: new Date().getTime(),
          })
        }
      />

      <AutomatedEmailSetupModal
        visible={automatedEmailSetupModalOpen}
        onCancel={() => {
          setAutomatedEmailSetupModalOpen(false);
        }}
        onSave={function (): void {
          getSupportEmails({
            organizationId: brokerId,
            currentTs: new Date().getTime(),
          });
          setAutomatedEmailSetupModalOpen(false);
          showSaveNotification();
        }}
        type={ISSUE_TYPE_SUPPORT}
        ownerId={brokerId || ''}
        supportTeamList={supportTeamList}
        name={selectedRow?.name}
      />

      <AutomatedEmailDeleteModal
        isSupport={true}
        visible={automatedEmailDeleteModal}
        email={selectedRow?.email}
        type={ISSUE_TYPE_SUPPORT}
        onCancel={() => {
          setAutomatedEmailDeleteModal(false);
        }}
        onDelete={() => {
          setAutomatedEmailDeleteModal(false);
          getSupportEmails({
            organizationId: brokerId,
            currentTs: new Date().getTime(),
          });
        }}
        ownerId={brokerId || ''}
        deleteSupportTeam={deleteSupportTeam}
        teamName={selectedRow?.name}
        unresolvedCount={selectedRow?.summary?.unresolvedIssues}
      />

      <ResendAutomatedEmailModal
        visible={resendEmailModalOpen}
        email={selectedRow?.email}
        onCancel={() => {
          setResendEmailModal(false);
        }}
        onComplete={() => {
          setResendEmailModal(false);
        }}
      />
    </div>
  );
};

export default SupportTeamProvision;
