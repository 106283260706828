export const SUPPORT_TEAM_VALUE_UNKNOWN = 'Unknown';
export const SUPPORT_TEAM_LABEL_UNKNOWN = '- Unknown -';

export const WATCHING_YES = true;
export const WATCHING_NO = false;

export const WATCHING_MAP = [
  { value: WATCHING_YES, label: 'Yes' },
  { value: WATCHING_NO, label: 'No' },
];

export const WATCHING_FILTER_DEFAULT_MAP = [WATCHING_YES, WATCHING_NO];

export const STATUS_NEW = 1;
export const STATUS_IN_PROGRESS = 2;
export const STATUS_RESOLVED = 3;
export const STATUS_BLOCKED = 6;
export const STATUS_RE_OPENED = 8;

export const ISSUE_EMPLOYER_STATUS_MAP = [
  { value: STATUS_NEW, label: 'New' },
  { value: `${STATUS_IN_PROGRESS},${STATUS_BLOCKED}`, label: 'In Progress' },
  { value: STATUS_RE_OPENED, label: 'Re-Opened' },
  { value: STATUS_RESOLVED, label: 'Resolved' },
];

export const ISSUE_EMPLOYER_STATUS_DEFAULT_MAP = [
  STATUS_NEW,
  `${STATUS_IN_PROGRESS},${STATUS_BLOCKED}`,
  STATUS_RE_OPENED,
];

export const ISSUE_STATUS_BROKER_TECH_MAP = [
  { value: STATUS_NEW, label: 'New' },
  { value: STATUS_IN_PROGRESS, label: 'In Progress' },
  { value: STATUS_RE_OPENED, label: 'Re-Opened' },
  { value: STATUS_BLOCKED, label: 'Blocked' },
  { value: STATUS_RESOLVED, label: 'Resolved' },
];

export const ISSUE_STATUS_BROKER_TECH_DEFAULT_MAP = [
  STATUS_NEW,
  STATUS_IN_PROGRESS,
  STATUS_BLOCKED,
  STATUS_RE_OPENED,
];

export const ISSUE_STATUS_DASHBOARD_MAP = [
  STATUS_NEW,
  STATUS_IN_PROGRESS,
  STATUS_BLOCKED,
  STATUS_RE_OPENED,
];

export const PRIORITY_HIGH = 2;
export const PRIORITY_MEDIUM = 3;
export const PRIORITY_LOW = 4;
export const PRIORITY_UNKNOWN = 1;

export const PRIORITY_MAP = [
  { value: PRIORITY_LOW, label: 'Low' },
  { value: PRIORITY_MEDIUM, label: 'Medium' },
  { value: PRIORITY_HIGH, label: 'High' },
  { value: PRIORITY_UNKNOWN, label: 'Unknown' },
];

export const PRIORITY_DEFAULT_MAP = [
  PRIORITY_LOW,
  PRIORITY_MEDIUM,
  PRIORITY_HIGH,
  PRIORITY_UNKNOWN,
];

export const SUBTYPE_BENEFITS_QUESTION = 'BENEFITS_QUESTION';
export const SUBTYPE_BILLING = 'BILLING';
export const SUBTYPE_CARRIER = 'CARRIER';
export const SUBTYPE_COBRA = 'COBRA';
export const SUBTYPE_COMPLIANCE = 'COMPLIANCE';
export const SUBTYPE_DEMOGRAPHIC_DATA = 'DEMOGRAPHIC_DATA';
export const SUBTYPE_EDI = 'EDI';
export const SUBTYPE_ELIGIBILITY = 'ELIGIBILITY';
export const SUBTYPE_EOI = 'EOI';
export const SUBTYPE_GENERAL = 'GENERAL';
export const SUBTYPE_ID_CARDS = 'ID_CARDS';
export const SUBTYPE_INTEGRATIONS = 'INTEGRATIONS';
export const SUBTYPE_LOGIN_ISSUE = 'LOGIN_ISSUE';
export const SUBTYPE_PAYROLL = 'PAYROLL';
export const SUBTYPE_PROVIDER_SEARCH = 'PROVIDER_SEARCH';
export const SUBTYPE_QLE = 'QLE';
export const SUBTYPE_RENEWAL = 'RENEWAL';
export const SUBTYPE_CLAIMS_ISSUE = 'CLAIMS_ISSUE';
export const SUBTYPE_SPENDING_ACCOUNTS = 'SPENDING_ACCOUNTS';
export const SUBTYPE_URGENT_ENROLLMENTS = 'URGENT_ENROLLMENTS';
export const SUBTYPE_UNKNOWN = null;
export const SUBTYPE_SPAM = 'SPAM';

export const ISSUE_SUBTYPE_MAP = [
  { value: SUBTYPE_BENEFITS_QUESTION, label: 'Benefits Question' },
  { value: SUBTYPE_BILLING, label: 'Billing' },
  { value: SUBTYPE_CARRIER, label: 'Carrier' },
  { value: SUBTYPE_CLAIMS_ISSUE, label: 'Claims Issue' },
  { value: SUBTYPE_COBRA, label: 'Cobra' },
  { value: SUBTYPE_COMPLIANCE, label: 'Compliance' },
  { value: SUBTYPE_DEMOGRAPHIC_DATA, label: 'Demographic Data' },
  { value: SUBTYPE_EDI, label: 'EDI' },
  { value: SUBTYPE_ELIGIBILITY, label: 'Eligibility' },
  { value: SUBTYPE_EOI, label: 'EOI' },
  { value: SUBTYPE_GENERAL, label: 'General' },
  { value: SUBTYPE_ID_CARDS, label: 'ID Cards' },
  { value: SUBTYPE_INTEGRATIONS, label: 'Integrations' },
  { value: SUBTYPE_LOGIN_ISSUE, label: 'Login Issue' },
  { value: SUBTYPE_PAYROLL, label: 'Payroll' },
  { value: SUBTYPE_PROVIDER_SEARCH, label: 'Provider Search' },
  { value: SUBTYPE_QLE, label: 'QLE' },
  { value: SUBTYPE_RENEWAL, label: 'Renewal' },
  { value: SUBTYPE_SPENDING_ACCOUNTS, label: 'Spending Accounts' },
  { value: SUBTYPE_URGENT_ENROLLMENTS, label: 'Urgent Enrollments' },
  { value: SUBTYPE_UNKNOWN, label: 'Unknown' },
  { value: SUBTYPE_SPAM, label: 'Not Tracked' },
];

export const ISSUE_SUBTYPE_DEFAULT_MAP = [
  SUBTYPE_BENEFITS_QUESTION,
  SUBTYPE_BILLING,
  SUBTYPE_CARRIER,
  SUBTYPE_CLAIMS_ISSUE,
  SUBTYPE_COBRA,
  SUBTYPE_COMPLIANCE,
  SUBTYPE_DEMOGRAPHIC_DATA,
  SUBTYPE_EDI,
  SUBTYPE_ELIGIBILITY,
  SUBTYPE_EOI,
  SUBTYPE_GENERAL,
  SUBTYPE_ID_CARDS,
  SUBTYPE_INTEGRATIONS,
  SUBTYPE_LOGIN_ISSUE,
  SUBTYPE_PAYROLL,
  SUBTYPE_PROVIDER_SEARCH,
  SUBTYPE_QLE,
  SUBTYPE_RENEWAL,
  SUBTYPE_SPENDING_ACCOUNTS,
  SUBTYPE_URGENT_ENROLLMENTS,
  SUBTYPE_UNKNOWN,
];

export const STATUS_FIVE_DAYS = '[0,4]';
export const STATUS_FIVE_TO_TEN_DAYS = '[5,10]';
export const STATUS_ELEVEN_THIRTY_DAYS = '[11,30]';
export const STATUS_THIRTY_DAYS = '[31,100]';

export const ISSUE_DAYS_OPEN_MAP = [
  { value: STATUS_FIVE_DAYS, label: '< 5 days' },
  { value: STATUS_FIVE_TO_TEN_DAYS, label: '5 - 10 days' },
  { value: STATUS_ELEVEN_THIRTY_DAYS, label: '11 - 30 days' },
  { value: STATUS_THIRTY_DAYS, label: '30+ days' },
];

export const ISSUE_DAYS_OPEN_DEFAULT_MAP = [
  STATUS_FIVE_DAYS,
  STATUS_FIVE_TO_TEN_DAYS,
  STATUS_ELEVEN_THIRTY_DAYS,
  STATUS_THIRTY_DAYS,
];

export const ISSUE_LAST_MODIFIED_MAP = [
  { value: STATUS_FIVE_DAYS, label: '< 5 days' },
  { value: STATUS_FIVE_TO_TEN_DAYS, label: '5 - 10 days' },
  { value: STATUS_ELEVEN_THIRTY_DAYS, label: '11 - 30 days' },
  { value: STATUS_THIRTY_DAYS, label: '30+ days' },
];

export const ISSUE_LAST_MODIFIED_DEFAULT_MAP = [
  STATUS_FIVE_DAYS,
  STATUS_FIVE_TO_TEN_DAYS,
  STATUS_ELEVEN_THIRTY_DAYS,
  STATUS_THIRTY_DAYS,
];

export const VIEWABLE_YES = true;
export const VIEWABLE_NO = false;

export const VIEWABLE_MAP = [
  { value: VIEWABLE_YES, label: 'Yes' },
  { value: VIEWABLE_NO, label: 'No' },
];

export const VIEWABLE_DEFAULT_MAP = [VIEWABLE_YES, VIEWABLE_NO];

export const ISSUES_LOG_FILTER_TYPES = {
  WATCHING: 'WATCHING',
  BROKERAGE: 'ORGANIZATION',
  EMPLOYER: 'EMPLOYER',
  SUPPORT_TEAM: 'SUPPORTTEAM',
  STATUS: 'STATUS',
  PRIORITY: 'PRIORITY',
  SUB_TYPE: 'SUBTYPE',
  ASSIGNED_TO: 'ASSIGNEE',
  DAYS_OPEN: 'DAYSOPEN',
  LAST_MODIFIED: 'LASTUPDATEDTS',
  VIEWABLE_BY_EMPLOYER: 'SHOWINEMPLOYER',
  ISSUE_TYPE: 'TYPE',
};

export const ISSUE_LOG_SAVE_FILTER_ID_TYPES = {
  BR_ACCOUNT: 'BR_ACCOUNT',
  BR_SUPPORT: 'BR_SUPPORT',
  TEC_ACCOUNT: 'TEC_ACCOUNT',
  TEC_SUPPORT: 'TEC_SUPPORT',
  EMPLOYER: 'EMPLOYER',
  EMPLOYER_ACCOUNT: 'EMPLOYER_ACCOUNT',
  EMPLOYER_SUPPORT: 'EMPLOYER_SUPPORT',
};
