import { Col, Row } from 'antd';

import FromCurrentPercentage from 'modules/renewals/components/helperComponents/FromCurrentPercentage/FromCurrentPercentage';
import { currencyFormatter } from 'util/commonUtil';
import { CreditsInclusionType } from 'modules/renewals/types/renewalsTypes';

import styles from 'modules/renewals/components/FromCurrentDetailsFooter/FromCurrentDetailsFooter.module.less';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import { CREDITS_INCLUSION } from 'modules/renewals/constants/renewalsConstants';

type Props = {
  value?: number;
  percentage?: number;
  showCreditsDropdown?: boolean;
  onCreditsInclusionChange?: Function;
  creditsInclusion?: CreditsInclusionType;
  isNaApplicable?: boolean;
  isProposalBenefits?: boolean;
};

const FromCurrentDetailsFooter = ({
  value,
  percentage,
  creditsInclusion,
  onCreditsInclusionChange = () => {},
  showCreditsDropdown,
  isNaApplicable = false,
  isProposalBenefits = false,
}: Props): JSX.Element => {
  const getAmount = (): string => {
    const str = currencyFormatter(
      Math.round(value || 0),
      undefined,
      undefined,
      0
    );
    if ((value || 0) > -1) {
      return `+${str}`;
    } else {
      return `${str}`;
    }
  };

  return (
    <>
      {value !== undefined && percentage !== undefined && (
        <Row>
          <Col span={24} className={styles.changeDescription}>
            <span className={styles.changeInAmount}>{`${getAmount()} `}</span>
            {!isNaApplicable ? (
              <FromCurrentPercentage
                percentage={percentage}
                showBrackets={true}
                singleLine={true}
                decimalPlaces={2}
              />
            ) : (
              <span className={styles.offset}>(N/A)</span>
            )}{' '}
            from Current
          </Col>
        </Row>
      )}
      {showCreditsDropdown && (
        <Col
          span={24}
          className={isProposalBenefits ? styles.creditsDropdownTop : ''}
        >
          <SingleSelectFilter
            inlineDropdown
            placeholder="Action"
            showSearch={false}
            selectedValue={creditsInclusion || null}
            className={styles.creditsDropdown}
            setFilterSelectedValue={onCreditsInclusionChange}
            data={Object.values(CREDITS_INCLUSION)}
            defaultValue={CREDITS_INCLUSION.EXCLUDED.value}
            isSelectionHighlightAndTickApplicable={false}
            isNoWrapWrapper
          />
        </Col>
      )}
    </>
  );
};

export default FromCurrentDetailsFooter;
