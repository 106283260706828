import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

import { FC } from 'react';
import { Row, Col, Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useStateCallback } from 'hooks/updateState';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import NetworkTypeRow from 'modules/plans/components/NetworkTypeRow/NetworkTypeRow';
import InfoIconComponent from 'modules/plans/components/LLMInfoIconComponent/InfoIconComponent';

import iconRemove from 'assets/images/icon-remove-red.svg';
import iconWarning from 'assets/images/icon-warning.svg';
import { getLlmServiceInfo } from 'modules/plans/utils';
import {
  BenefitCovered,
  coverageOption,
  IN_NETWORK,
  OUT_OF_NETWORK,
  TOOLTIP_CONST,
} from 'modules/plans/constants';
import BenefitCode from 'model/plans/BenefitCode';
import CustomPlan from 'model/plans/CustomPlan';

import styles from './services.module.less';

type ServiceRowProps = {
  customService: CustomPlan;
  formType?: string;
  title: string;
  index: number;
  onServiceRemove: (index: number) => void;
  onChange: Function;
  plan: any;
  hsaCompatible: boolean;
  customServices: CustomPlan[];
  isTouched: boolean;
  isDefault: boolean;
  planType?: string;
  selectAll?: boolean | null;
  onSelectAllChange?: Function;
  isSmall?: boolean;
  isUpEdit?: boolean;
  setHasCostType?: Function;
};

const networkTypeKey = {
  IN_NETWORK: 'inNetwork',
  OUT_NETWORK: 'outOfNetwork',
};
const ServiceRow: FC<ServiceRowProps> = (props: ServiceRowProps) => {
  const {
    customService,
    formType,
    index,
    onServiceRemove,
    plan,
    onChange: _onChange,
    hsaCompatible,
    customServices,
    isTouched,
    isDefault,
    planType = '',
    selectAll = false,
    onSelectAllChange,
    isSmall,
    isUpEdit = false,
    setHasCostType,
  } = props;

  const { benefitCode, serviceValue } = customService;
  const { inNetwork, outOfNetwork } = serviceValue;

  const [visible, setVisible] = useStateCallback(false);

  const isHighlightVisible = !plan.extractionFinalized;
  const isUploadedPlan: boolean =
    !!plan?.textractJobId && plan?.textractJobId !== '';
  const llmServiceInfo = getLlmServiceInfo(
    benefitCode?.shortName,
    plan?.llmExtractionInfo?.customServices ?? []
  );

  const llmExtractionServiceInNetwork = llmServiceInfo?.inNetwork ?? null;
  const llmExtractionServiceOutNetwork = llmServiceInfo?.outOfNetwork ?? null;

  const handleNetworkRowChange = (pathKey: string, changes: []) => {
    const customServiceCopy = cloneDeep(customService);
    changes.forEach((change) => {
      const { key, value } = change;
      set(customServiceCopy, `serviceValue.${pathKey}.${key}`, value);
      if (key === 'costSharingPolicy' && value !== coverageOption.NOT_COVERED) {
        set(
          customServiceCopy,
          `serviceValue.${pathKey}.benefitCovered`,
          BenefitCovered.YES
        );
      }
    });

    const customServicesCopy = [...(customServices || [])];
    const existingServiceIndex = customServicesCopy.findIndex(
      (s) =>
        (customServiceCopy.benefitCode.code &&
          customServiceCopy.benefitCode.code === s.benefitCode.code) ||
        customServiceCopy.benefitCode.shortName === s.benefitCode.shortName
    );
    if (existingServiceIndex !== -1) {
      customServicesCopy[existingServiceIndex] = customServiceCopy;
    } else {
      customServicesCopy.push(customServiceCopy);
    }
    const updatedPlan = {
      ...plan,
      customServices: customServicesCopy,
    };
    _onChange(updatedPlan);
  };

  const removeService = (benefitCode: BenefitCode) => {
    const { code, shortName } = benefitCode;

    const newCustomServices = customServices.filter(
      (service) =>
        (code && code !== service.benefitCode.code) ||
        shortName !== service.benefitCode.shortName
    );

    const updatedPlan = {
      ...plan,
      customServices: newCustomServices,
    };
    _onChange(updatedPlan);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onConfirmClick = () => {
    setVisible(false, () => {
      removeService(benefitCode);
      onServiceRemove(index);
    });
  };

  const isAddOrRemoveIconVisible = !isDefault;

  const code = benefitCode?.code;
  const tooltipContent = code !== null && TOOLTIP_CONST[code];

  return (
    <div className={styles.tableSelector}>
      <Row>
        <Col>
          {isAddOrRemoveIconVisible && (
            <ConfirmationModal
              onVisibleChange={() => {
                !visible && setVisible(true);
              }}
              title="Are you sure you want to remove this service?"
              okText="Yes, remove service"
              icon={
                <img
                  src={iconWarning}
                  alt="warning-icon"
                  className={styles.iconWarning}
                />
              }
              confirmModalTrigger={
                <img
                  src={iconRemove}
                  alt="remove-service-icon"
                  className={styles.iconRemove}
                />
              }
              visible={visible}
              onConfirm={onConfirmClick}
              onCancel={onCancel}
              placement="topLeft"
            />
          )}
        </Col>
        <Col xs={12}>
          <OverflowPopover popoverContent={benefitCode?.shortName}>
            <div
              className={`text table-row-title ${styles.benefitNameContainer}`}
            >
              {!isAddOrRemoveIconVisible && (
                <InfoIconComponent
                  showIcon={isUploadedPlan}
                  extractionInfo={llmExtractionServiceInNetwork}
                  className={styles.infoIconServices}
                />
              )}
              <span className={styles.benefitNameText}>
                {benefitCode?.shortName}
                {isAddOrRemoveIconVisible && (
                  <span className={styles.inlineIcon}>
                    <InfoIconComponent
                      showIcon={isUploadedPlan}
                      extractionInfo={llmExtractionServiceInNetwork}
                    />
                  </span>
                )}
                {tooltipContent ? (
                  <Popover
                    content={<div>{tooltipContent.subtitle}</div>}
                    placement="bottomLeft"
                    overlayClassName="popoverInnerPlanTooltip"
                  >
                    <QuestionCircleOutlined className={styles.inlineIcon} />
                  </Popover>
                ) : null}
              </span>
            </div>
          </OverflowPopover>
        </Col>
      </Row>
      <div
        className={`${styles.networkTypeRow} ${isSmall && styles.smallInputs}`}
      >
        <NetworkTypeRow
          networkType={IN_NETWORK}
          networkObject={inNetwork}
          formType={formType}
          onChange={(changes: []) => {
            handleNetworkRowChange(networkTypeKey.IN_NETWORK, changes);
          }}
          hsaCompatible={hsaCompatible}
          isTouched={isTouched}
          isFromDV
          plan={planType}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          isDefault={isDefault}
          isUpEdit={isUpEdit}
          setHasCostType={setHasCostType}
          highlightLLMValue={
            llmExtractionServiceInNetwork?.different && isHighlightVisible
          }
        />
      </div>
      <div
        className={`${styles.networkTypeRow} ${isSmall && styles.smallInputs}`}
      >
        <NetworkTypeRow
          networkType={OUT_OF_NETWORK}
          networkObject={outOfNetwork}
          formType={formType}
          onChange={(changes: any) => {
            handleNetworkRowChange(networkTypeKey.OUT_NETWORK, changes);
          }}
          hsaCompatible={hsaCompatible}
          isTouched={isTouched}
          isFromDV
          plan={planType}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          isUpEdit={isUpEdit}
          setHasCostType={setHasCostType}
          highlightLLMValue={
            llmExtractionServiceOutNetwork?.different && isHighlightVisible
          }
        />
      </div>
    </div>
  );
};

export default ServiceRow;
