import { lumityApi } from 'api/apiSlice';

const loginApi = lumityApi.injectEndpoints({
  endpoints: (builder) => ({
    getLoginType: builder.mutation<any, { username: string }>({
      query: (args) => {
        const { username } = args;
        return {
          url: `v1/logins/flow`,
          method: 'POST',
          data: {
            username: username,
            unifiedPortal: true,
          },
        };
      },
    }),
  }),
});

export const { useGetLoginTypeMutation } = loginApi;
