import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import { removeBenefitDocumentsFromPlan } from 'modules/plans/utils';
import MetaData from 'model/MetaData';
import { LifePlan } from 'model/plans/LifePlan';
import { AIUploaderFeedback } from 'model/plans/UserFeedback';
import * as PlanService from 'modules/plans/services/PlanService';
import * as LifePlanService from 'modules/plans/services/LifePlanService';
import PaginationConfig from 'model/PaginationConfig';
import ErrorDetails from 'model/ErrorDetails';
import DocumentUploadStatus from 'model/DocumentUploadStatus';
import ClonePlanDocument from 'model/plans/ClonePlanDocument';
import { NonMDVServiceType } from 'model/plans/NonMDVServiceType';
import { clonePlanDocuments } from './planDocumentsSlice';

export interface LifePlanInfoServiceState {
  inProgress: boolean;
  error: ErrorDetails | null;
  lifePlan: LifePlan;
  requestType: string;
  lifePlanList: { content: Array<LifePlan>; metadata: MetaData };
  documentReferences: {
    [key: string]: {
      uploading: boolean;
      reference: string;
      blobUrl: string;
      fileName: string;
      documentUploadStatus: DocumentUploadStatus;
      error: any;
    };
  };
  cancelDocumentUpload: boolean;
  planFeedback: AIUploaderFeedback[];
  lifePlanServiceList: NonMDVServiceType[];
}

const initialState: LifePlanInfoServiceState = {
  inProgress: false,
  error: null,
  requestType: '',
  lifePlan: {} as LifePlan,
  lifePlanList: { content: [], metadata: {} },
  documentReferences: {},
  planFeedback: [],
  cancelDocumentUpload: false,
  lifePlanServiceList: [],
};

const lifePlanSlice = createSlice({
  name: 'lifePlan',
  initialState,
  reducers: {
    injectLifePlanDetails: (
      state,
      { payload }: PayloadAction<Partial<LifePlan>>
    ) => {
      state.inProgress = false;
      if (payload?.documentReferences) {
        state.documentReferences = payload?.documentReferences;
      }
      state.lifePlan = {
        ...state.lifePlan,
        ...payload,
      };
    },
    createLifePlan: (state, action: PayloadAction<LifePlan>) => {
      state.lifePlan = action.payload;
      state.inProgress = false;
      state.error = null;
      state.requestType = action.type;
    },
    updateLifePlanInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    updateLifePlanCompleted: (state, action: PayloadAction<LifePlan>) => {
      state.inProgress = false;
      state.lifePlan = action.payload;
      state.error = null;
      state.requestType = action.type;
    },

    documentUploadStarted: (state, { payload }: PayloadAction<any>) => {
      state.cancelDocumentUpload = false;
      state.inProgress = true;
      state.documentReferences = {
        ...state.documentReferences,
        [payload.documentType]: {
          uploading: true,
        },
      };
    },
    documentUploadCompleted: (state, { payload }: PayloadAction<any>) => {
      state.inProgress = false;
      if (!state.cancelDocumentUpload) {
        state.documentReferences = {
          ...state.documentReferences,
          [payload.documentType]: {
            uploading: false,
            reference: payload.reference,
            blobUrl: payload.blobUrl,
            fileName: payload.fileName,
            documentUploadStatus: 'ADDED',
          },
        };
      }
    },
    documentUploadFailed: (state, { payload }: PayloadAction<any>) => {
      state.inProgress = false;
      if (!state.cancelDocumentUpload) {
        state.documentReferences = {
          ...state.documentReferences,
          [payload.documentType]: {
            uploading: false,
            error: { response: payload },
          },
        };
      }
    },
    removeDocumentReference: (state, { payload }: PayloadAction<any>) => {
      state.documentReferences = {
        ...state.documentReferences,
        [payload]: {
          documentUploadStatus: 'REMOVED',
        },
      };
    },
    removeBenefitDocuments: (state) => {
      removeBenefitDocumentsFromPlan(state.lifePlan, state.documentReferences);
    },
    updateLifePlanFailed: (state, action) => {
      state.inProgress = false;
      state.error = JSON.parse(JSON.stringify(action.payload));
      state.requestType = action.type;
    },
    getLifePlansListInProgress(state, action: PayloadAction) {
      state.inProgress = true;
      state.error = null;
      state.requestType = action.type;
    },
    getLifePlansListCompleted: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.inProgress = false;
      state.error = null;
      state.lifePlanList = payload;
      state.requestType = action.type;
    },
    getLifePlansListFailed(state, action: PayloadAction<any>) {
      state.inProgress = false;
      state.error = action.payload;
      state.requestType = action.type;
    },
    getPlanByIdStarted: (state) => {
      state.inProgress = true;
      state.lifePlan = {} as LifePlan;
      state.error = null;
    },
    getPlanByIdCompleted: (state, action: PayloadAction<LifePlan>) => {
      state.inProgress = false;
      state.lifePlan = action.payload;
      state.error = null;
    },
    getPlanByIdFailed: (state, action) => {
      state.inProgress = false;
      state.lifePlan = {} as LifePlan;
      state.error = action.payload;
    },
    clearDocuments: (state) => {
      state.documentReferences = {};
      state.cancelDocumentUpload = true;
      state.inProgress = false;
      state.planFeedback = [];
    },
    clearLifePlanApiErrors: (state) => {
      state.error = null;
    },
    resetPlanReduxStore: (state) => {
      const lifePlanId = state.lifePlan.id;
      state.lifePlan = {
        id: lifePlanId,
      } as LifePlan;
      state.documentReferences = {};
      state.lifePlanServiceList = [];
    },
    setUserAiFeedback: (
      state,
      { payload }: PayloadAction<AIUploaderFeedback>
    ) => {
      const existingFeedbackIndex = state.planFeedback?.findIndex(
        (feedback) => feedback.section === payload.section
      );

      if (existingFeedbackIndex !== -1) {
        state.planFeedback?.splice(existingFeedbackIndex!, 1, payload);
      } else {
        state.planFeedback?.push(payload);
      }
    },

    removeUserAiFeedback: (state, { payload }: PayloadAction<string>) => {
      const sectionToRemove = payload;
      state.lifePlan.planFeedback = state.lifePlan?.planFeedback?.filter(
        (feedback) => feedback?.section !== sectionToRemove
      );
    },
    addLifeService: (state, action: PayloadAction<NonMDVServiceType>) => {
      state.inProgress = false;
      state.lifePlanServiceList = [
        ...state.lifePlanServiceList,
        action.payload,
      ];
    },
    setLifeServices: (state, action: PayloadAction<NonMDVServiceType[]>) => {
      if (state.lifePlan.benefitKind === 'STD') {
        state.inProgress = false;
        state.lifePlanServiceList = state.lifePlan.stdServices ?? [];
      } else if (state.lifePlan.benefitKind === 'LTD') {
        state.inProgress = false;
        state.lifePlanServiceList = state.lifePlan.ltdServices ?? [];
      } else {
        state.inProgress = false;
        state.lifePlanServiceList = action.payload;
      }
    },
    setChangeService: (state, action: PayloadAction<NonMDVServiceType[]>) => {
      state.inProgress = false;
      state.lifePlanServiceList = action.payload;
    },
    resetLifeServices: (state) => {
      state.inProgress = false;
      state.lifePlanServiceList = [];
    },
  },
});

export const {
  createLifePlan,
  injectLifePlanDetails,
  updateLifePlanInProgress,
  updateLifePlanCompleted,
  updateLifePlanFailed,
  getLifePlansListInProgress,
  getLifePlansListCompleted,
  getLifePlansListFailed,
  documentUploadStarted,
  documentUploadCompleted,
  documentUploadFailed,
  getPlanByIdStarted,
  getPlanByIdCompleted,
  getPlanByIdFailed,
  clearDocuments,
  removeDocumentReference,
  clearLifePlanApiErrors,
  resetPlanReduxStore,
  removeUserAiFeedback,
  setUserAiFeedback,
  removeBenefitDocuments,
  addLifeService,
  setLifeServices,
  setChangeService,
  resetLifeServices,
} = lifePlanSlice.actions;

export default lifePlanSlice.reducer;

export const uploadTemporaryDocument = (file: File, documentType: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(documentUploadStarted({ documentType }));
    PlanService.uploadPlanDocuments(file.name, file)
      .then(({ data }: AxiosResponse) => {
        dispatch(
          documentUploadCompleted({
            documentType,
            reference: data,
            fileName: file.name,
            blobUrl: URL.createObjectURL(file),
          })
        );
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          dispatch(
            documentUploadFailed({
              documentType,
              error: JSON.parse(JSON.stringify(error.response)),
            })
          );
        } else {
          console.error(error);
        }
      });
  };
};

export const saveLifePlan = (
  plan: LifePlan,
  onSave: (isSuccess?: boolean, id?: string) => void,
  cloneDocuments?: boolean,
  sourcePlanId?: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateLifePlanInProgress());
      let response = {} as any;
      if (plan.id) {
        response = await LifePlanService.updateLifePlan(plan);
      } else {
        response = await LifePlanService.createLifePlan(plan);
      }
      dispatch(updateLifePlanCompleted(response.data));

      if (response?.data && cloneDocuments && plan.documents && sourcePlanId) {
        const clonePlanDoc: ClonePlanDocument = {
          employerId: response.data.employerId,
          sourcePlanId: sourcePlanId,
          targetPlanId: response.data.id,
          benefitCategory: 'LIFE',
          benefitKind: plan.benefitKind,
        };
        dispatch(clonePlanDocuments(clonePlanDoc));
      }
      onSave(response.status == 200, response.data.id);
      dispatch(clearDocuments());
      dispatch(resetLifeServices());
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            updateLifePlanFailed({
              data: error.response.data,
            } as ErrorDetails)
          );
        }
      }
    }
  };
};

export const getLifePlansList = (
  { page, size, sort, query }: PaginationConfig,
  employerId: string,
  planYearId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getLifePlansListInProgress());
    try {
      const response = await LifePlanService.getLifePlansList(
        page,
        size,
        sort,
        query,
        employerId,
        planYearId
      );
      dispatch(getLifePlansListCompleted(response?.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getLifePlansListFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const findPlanById = (planId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getPlanByIdStarted());
    try {
      const response = await LifePlanService.getLifePlanById(planId);
      if (response.data.benefitKind === 'STD') {
        dispatch(getPlanByIdCompleted(response.data));
        dispatch(setChangeService(response.data.stdServices));
      } else if (response.data.benefitKind === 'LTD') {
        dispatch(getPlanByIdCompleted(response.data));
        dispatch(setChangeService(response.data.ltdServices));
      } else {
        dispatch(getPlanByIdCompleted(response.data));
        dispatch(setChangeService(response.data.lifeServices));
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(getPlanByIdFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
};

export const clearDocumentsAttached = () => (dispatch: Dispatch) => {
  dispatch(clearDocuments());
};

export const updateLifePlan = (plan: LifePlan) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateLifePlanCompleted(cloneDeep(plan)));
  };
};

export const addLifePlanService =
  (data: NonMDVServiceType) => (dispatch: Dispatch) => {
    dispatch(addLifeService(data));
  };

export const deleteLifePlanService =
  (data: NonMDVServiceType[]) => (dispatch: Dispatch) => {
    dispatch(setChangeService(data));
  };

export const changeLifePlanDescription =
  (data: NonMDVServiceType[]) => (dispatch: Dispatch) => {
    dispatch(setChangeService(data));
  };

export const resetLifePlans = () => (dispatch: Dispatch) => {
  dispatch(resetLifeServices());
};
