import React from 'react';
import { Col, Row } from 'antd';

import styles from './printableIdCardEmployeeDataBody.module.less';

interface PrintableIdCardEmployeeDataBodyProps {
  memberIdentification: string;
  memberData: string;
  additionalDetailTopic: string;
  additionalDetailTopicData: string;
}

const PrintableIdCardEmployeeDataBody: React.FC<
  PrintableIdCardEmployeeDataBodyProps
> = (props) => {
  const {
    memberIdentification,
    memberData,
    additionalDetailTopic,
    additionalDetailTopicData,
  } = props;

  return (
    <>
      <Row gutter={5}>
        <Col span={5}>
          <div className={styles.printableTextData}>
            <span>{memberIdentification}</span>
          </div>
        </Col>
        <Col span={9}>
          {memberData == 'Phone' ? (
            <>
              <div className={styles.phone}>
                <span>{memberData}</span>
              </div>
            </>
          ) : (
            <>
              <div className={styles.printableTextData}>
                <span>{memberData}</span>
              </div>
            </>
          )}
        </Col>
        <Col span={5}>
          <div className={styles.printableTextData}>
            <span>{additionalDetailTopic}</span>
          </div>
        </Col>
        <Col span={5}>
          <div className={styles.printableTextData}>
            <span>{additionalDetailTopicData}</span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PrintableIdCardEmployeeDataBody;
