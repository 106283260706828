import { forwardRef } from 'react';
import { Table } from 'antd';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import { MDVPLANS } from 'modules/billing/constants/constants';
import styles from './dummyExpandedTable.module.less';

const { Column, ColumnGroup } = Table;

type DummyExpandedTableProps = {
  benifitSummary: any;
  isPrintable?: boolean;
  employerName?: string;
  billingMonth?: string;
  billingYear?: number;
  monthlyTotal?: any;
};

// eslint-disable-next-line react/display-name
const DummyExpandedTable = forwardRef(
  (props: DummyExpandedTableProps, ref: any) => {
    const {
      benifitSummary,
      isPrintable,
      employerName,
      billingMonth,
      billingYear,
      monthlyTotal,
    } = props;

    const getPageMargins = () => {
      return `
        @page {
          size:250mm 320mm;
          margin-top: 10px !important;
          margin-bottom: 10px !important;
          margin-left: 30px !important;
          margin-right: 30px !important;
        }
      
        @media all {
          .pagebreak {
            display: block;
          }
        }
      
        @media print {
          html,body {
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
          }
          .page-break {
            display: block;
          }
          .tableWrapper{
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
          }
        }
      `;
    };

    const getData = (benifitItem: any) => {
      const previewData = [] as any;
      if (
        benifitItem?.benefitSummary !== undefined &&
        benifitItem?.benefitSummary !== null
      ) {
        Object.keys(benifitItem?.benefitSummary ?? []).map(
          (key: any, index: any) => {
            const obj = {
              key: <div className={styles.benifitWrapper}>{key}</div>,
              level: 1,
              benifit: <div className={styles.parentBenifit}>{key}</div>,
              count:
                (benifitItem?.benefitSummary?.[key]?.count ?? 0) === 0 ? (
                  <div className={styles.hyphen}>---</div>
                ) : (
                  benifitItem?.benefitSummary?.[key]?.count
                ),
              totalCurrentVolume: getFormattedAmount(
                benifitItem?.benefitSummary?.[key]?.totalCurrentVolume
              ),
              totalCurrentPremium: getFormattedAmount(
                benifitItem?.benefitSummary?.[key]?.totalCurrentPremium
              ),
              totalRetroVolume: getFormattedAmount(
                benifitItem?.benefitSummary?.[key]?.totalRetroVolume
              ),
              totalRetroPremium: getFormattedAmount(
                benifitItem?.benefitSummary?.[key]?.totalRetroPremium
              ),
              totalVolume: getFormattedAmount(
                benifitItem?.benefitSummary?.[key]?.totalVolume
              ),
              totalPremium: getFormattedAmount(
                benifitItem?.benefitSummary?.[key]?.totalPremium
              ),
              billingStatusSummaries: getFormattedAmount(
                benifitItem?.benefitSummary?.[key]?.billingStatusSummaries
              ),
            };
            previewData.push(obj);
            if (MDVPLANS.includes(key)) {
              if (
                benifitItem?.benefitSummary?.[key]?.billingStatusSummaries
                  ?.length !== 0
              ) {
                benifitItem?.benefitSummary?.[key]?.billingStatusSummaries.map(
                  (item: any) => {
                    const obj = reArrageTiers(item, 'status', 2);
                    previewData.push(obj);

                    if (item?.planSummaries?.length !== 0) {
                      item?.planSummaries?.map((plan: any) => {
                        const obj = reArrageTiers(plan, 'planName', 3);
                        previewData.push(obj);
                        if (plan?.tierSummaries?.length !== 0) {
                          plan?.tierSummaries?.map((tier: any) => {
                            const obj = reArrageTiers(tier, 'tier', 4);
                            previewData.push(obj);
                          });
                        }
                      });
                    }
                  }
                );
              }
            } else {
              if (
                benifitItem?.benefitSummary?.[key]?.billingStatusSummaries
                  ?.length !== 0
              ) {
                if (
                  benifitItem?.benefitSummary?.[key]?.billingStatusSummaries[0]
                    ?.planSummaries?.length !== 0
                ) {
                  benifitItem?.benefitSummary?.[
                    key
                  ]?.billingStatusSummaries[0]?.planSummaries?.map(
                    (plan: any) => {
                      const obj = reArrageTiers(plan, 'planName', 2);
                      previewData.push(obj);
                      if (plan?.tierSummaries?.length !== 0) {
                        plan?.tierSummaries?.map((tier: any) => {
                          const obj = reArrageTiers(tier, 'tier', 3);
                          previewData.push(obj);
                        });
                      }
                    }
                  );
                }
              }
            }
          }
        );
        const obj = {
          key: 'SUB-TOTAL',
          benifit: <div className={styles.childBenifits}>SUB-TOTAL</div>,
          level: 0,
          count:
            (benifitItem?.count ?? 0) === 0 ? (
              <div className={styles.hyphen}>---</div>
            ) : (
              benifitItem.count
            ),
          totalCurrentVolume: getFormattedAmount(
            benifitItem.totalCurrentVolume
          ),
          totalCurrentPremium: getFormattedAmount(
            benifitItem.totalCurrentPremium
          ),
          totalRetroVolume: getFormattedAmount(benifitItem.totalRetroVolume),
          totalRetroPremium: getFormattedAmount(benifitItem.totalRetroPremium),
          totalVolume: getFormattedAmount(benifitItem.totalVolume),
          totalPremium: getFormattedAmount(benifitItem.totalPremium),
          billingStatusSummaries: [],
        };
        previewData.push(obj);
      }

      return previewData;
    };

    const getFormattedAmount = (item: any) => {
      return (item ?? 0) === 0 ? (
        <div className={styles.hyphen}>---</div>
      ) : item.toString().indexOf('-') !== -1 ? (
        '($' +
        item
          .toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .toString()
          .replace('-', '') +
        ')'
      ) : (
        '$' +
        item.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    };

    const reArrageTiers = (item: any, titleName: string, level: number) => {
      return {
        key: item[titleName] ?? '',
        level: level,
        benifit: (
          <div className={`${styles['level' + level]} ${styles.childBenifits}`}>
            {item[titleName] ?? ''}
          </div>
        ),
        count:
          (benifitSummary?.count ?? 0) === 0 ? (
            <div className={styles.hyphen}>---</div>
          ) : (
            benifitSummary.count
          ),
        totalCurrentVolume: getFormattedAmount(item?.totalCurrentVolume),
        totalCurrentPremium: getFormattedAmount(item?.totalCurrentPremium),
        totalRetroVolume: getFormattedAmount(item?.totalRetroVolume),
        totalRetroPremium: getFormattedAmount(item?.totalRetroPremium),
        totalVolume: getFormattedAmount(item?.totalVolume),
        totalPremium: getFormattedAmount(item?.totalPremium),
        billingStatusSummaries: item?.billingStatusSummaries,
      };
    };

    const showEllipsis = !isPrintable;

    return (
      <ContentContainer>
        <div className={`${styles.tableWrapper} page-break`} ref={ref}>
          <style>{getPageMargins()}</style>
          {isPrintable ? (
            <>
              <div className={styles.printTitle}>
                <h1>{employerName ?? ''}</h1>
              </div>
              <div className={styles.printTitle}>
                <h1>{`${billingMonth} ${billingYear} Invoice`}</h1>
              </div>
            </>
          ) : null}

          {benifitSummary?.carrierSummaries.map((item: any, index: any) => {
            return (
              <div className={styles.singleTableWrapper} key={index}>
                <div className={styles.singleTableInnerWrapper}>
                  <Table
                    pagination={false}
                    dataSource={getData(item)}
                    rowClassName={(record, index) => {
                      return record.level === 0
                        ? styles.totalRow
                        : record.level === 1
                        ? styles.parentRows
                        : styles.childRows;
                    }}
                  >
                    <ColumnGroup
                      title={
                        <img
                          src={item?.carrierUrl ?? ''}
                          className={styles.carrierImage}
                        />
                      }
                      width={12}
                    >
                      <Column
                        title={
                          <div className={styles.carrierTitle}>
                            Carrier/Benefit
                          </div>
                        }
                        dataIndex="benifit"
                        key="benifit"
                        ellipsis={showEllipsis}
                        width={isPrintable ? '9%' : '15.04%'}
                        align="left"
                      />
                    </ColumnGroup>
                    <ColumnGroup title="Current">
                      <Column
                        title="Count"
                        dataIndex="count"
                        key="count"
                        ellipsis={showEllipsis}
                        width={isPrintable ? '6%' : '12.28%'}
                        align="right"
                      />
                      <Column
                        title="Volume"
                        dataIndex="totalCurrentVolume"
                        key="totalCurrentVolume"
                        ellipsis={showEllipsis}
                        width={isPrintable ? '11.5%' : '12.28%'}
                        align="right"
                      />
                      <Column
                        title="Premium"
                        dataIndex="totalCurrentPremium"
                        key="totalCurrentPremium"
                        ellipsis={showEllipsis}
                        width={isPrintable ? '11.5%' : '12.28%'}
                        align="right"
                      />
                    </ColumnGroup>
                    <ColumnGroup title="RetroActivity">
                      <Column
                        title="Volume"
                        dataIndex="totalRetroVolume"
                        key="totalRetroVolume"
                        ellipsis={showEllipsis}
                        width={isPrintable ? '11.5%' : '12.28%'}
                        align="right"
                      />
                      <Column
                        title="Premium"
                        dataIndex="totalRetroPremium"
                        key="totalRetroPremium"
                        ellipsis={showEllipsis}
                        width={isPrintable ? '11.5%' : '12.28%'}
                        align="right"
                      />
                    </ColumnGroup>
                    <ColumnGroup title="Total">
                      <Column
                        title="Volume"
                        dataIndex="totalVolume"
                        key="totalVolume"
                        width={isPrintable ? '11.5%' : '12.28%'}
                        ellipsis={showEllipsis}
                        align="right"
                      />
                      <Column
                        title="Premium"
                        dataIndex="totalPremium"
                        key="totalPremium"
                        width={isPrintable ? '11.5%' : '12.28%'}
                        ellipsis={showEllipsis}
                        align="right"
                      />
                    </ColumnGroup>
                  </Table>
                </div>
              </div>
            );
          })}

          <div>
            <hr className={styles.seperator}></hr>
            <div className={styles.totalDueText}>Total Due</div>
            <div className={styles.totalDueContent}>
              {getFormattedAmount(monthlyTotal)}
            </div>
          </div>
        </div>
      </ContentContainer>
    );
  }
);

export default DummyExpandedTable;
