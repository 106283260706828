import React, { FC, useRef, useEffect } from 'react';
import { isEmpty, trim } from 'lodash';
import { Form, Input } from 'antd';
import { CHATBOT_INITIALIZE_TEXT } from 'modules/chatbot/constants/constants';

import styles from './brokerChatInput.module.less';

const { TextArea } = Input;

type FormValues = {
  chatInput: string;
};

type ChatInputProps = {
  onSubmit?: (value: string) => void;
  loading?: boolean;
};

const BrokerChatInput: FC<ChatInputProps> = ({ onSubmit, loading }) => {
  const [form] = Form.useForm<FormValues>();
  const textAreaRef = useRef<any>(null);

  const handleFinish = ({ chatInput }: FormValues) => {
    if (isEmpty(chatInput)) return;
    onSubmit?.(trim(chatInput));
    form.resetFields();
    textAreaRef.current?.focus();
  };

  const handlePressEnter = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      form.submit();
    }
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus(); // Ensure focus on update
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreaRef.current]);

  return (
    <Form form={form} className={styles.chatInputForm} onFinish={handleFinish}>
      <Form.Item name="chatInput">
        <TextArea
          ref={textAreaRef}
          className={styles.chatInput}
          placeholder={CHATBOT_INITIALIZE_TEXT}
          onPressEnter={handlePressEnter}
          autoSize={{ maxRows: 5 }}
          disabled={loading}
        />
      </Form.Item>
    </Form>
  );
};

export default BrokerChatInput;
