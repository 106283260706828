import { FC } from 'react';
import { Input } from 'antd';

import { SearchProps } from 'antd/lib/input';

import { ReactComponent as SearchIcon } from 'assets/images/icon-search.svg';

import styles from './searchBar.module.less';

const { Search } = Input;

interface Props extends SearchProps {
  isLarge?: boolean;
  allowClear?: boolean;
  isIssueSearch?: boolean;
  isRenewalSearch?: boolean;
  isIconEndAligned?: boolean;
  searchStyleClassName?: string;
}

const SearchBar: FC<Props> = (props: Props) => {
  const {
    placeholder,
    isLarge = false,
    onChange,
    allowClear,
    value,
    isIssueSearch,
    isRenewalSearch,
    onKeyDown,
    isIconEndAligned = false,
    searchStyleClassName,
  } = props;

  return (
    <div className={styles.searchWrapper}>
      <Search
        placeholder={placeholder}
        onChange={onChange}
        className={`${searchStyleClassName} ${
          isLarge ? styles.lgSearch : styles.smallSearch
        } ${isIssueSearch || isRenewalSearch ? styles.issueSearch : ''} ${
          isIconEndAligned ? styles.isIconEndAligned : ''
        }`}
        enterButton={<SearchIcon />}
        allowClear={allowClear}
        value={value}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default SearchBar;
