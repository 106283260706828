import { useEffect, useState } from 'react';
import { Empty, Radio, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useNavContext } from 'hooks/useNavContext';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { getBenefitTitleForRecentFiles } from 'modules/renewals/utils/renewalsUtils';
import { useGetRecentUploadedQuoteFilesQuery } from 'modules/renewals/slices/carrierQuoteFilesAiSlice';
import { uploadCarrierQuoteFileFromRecent } from 'modules/renewals/slices/carrierQuoteAiSlice';
import { useAppDispatch } from 'hooks/redux';
import { ReactComponent as NoDataIcon } from 'assets/images/no-data-icons.svg';
import styles from './recentFilesModal.module.less';

type RecentFilesModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  benefit: string;
  changeSelectedFile: (file: File, selectedFileId: string) => void;
};

const RecentFilesModal = ({
  isOpen,
  onClose = () => {},
  benefit,
  changeSelectedFile,
}: RecentFilesModalProps) => {
  const dispatch = useAppDispatch();
  const { brokerId, employerId } = useNavContext();
  const [selectedDocument, setSelectedDocument] = useState('');
  const [selectedDocumentName, setSelectedDocumentName] = useState('');

  const { data, refetch, isFetching, isLoading } =
    useGetRecentUploadedQuoteFilesQuery({
      brokerId: brokerId ?? '',
      employerId: employerId ?? '',
    });

  const mappedData = data?.map((item: any) => ({
    key: item?.id,
    documentName: item?.documentName,
    startedTime: item?.startedTime,
    category: getBenefitTitleForRecentFiles(item?.category.toLowerCase()),
    createdBy: item?.createdBy,
  }));

  const formatDateTime = (dateTime: string) => {
    return dayjs(dateTime).format('MMM DD, YYYY hh:mm A');
  };

  const onUpload = async (key: any) => {
    if (selectedDocument) {
      const file = new File([''], selectedDocumentName);
      changeSelectedFile(file, selectedDocument);
      dispatch(
        uploadCarrierQuoteFileFromRecent(
          employerId || '',
          brokerId || '',
          benefit,
          selectedDocument
        )
      );
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setSelectedDocument('');
    onClose();
  };

  const handleRadioChange = (key: any, documentName: string) => {
    setSelectedDocument(key);
    setSelectedDocumentName(documentName);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'FILE NAME',
      dataIndex: 'documentName',
      key: 'documentName',
      width: '38%',
      render: (text: string, record: any) => (
        <b>
          <Radio
            checked={record.key === selectedDocument}
            onChange={() => handleRadioChange(record.key, record.documentName)}
          >
            {text}
          </Radio>
        </b>
      ),
    },
    {
      title: 'BENEFITS',
      dataIndex: 'category',
      key: 'category',
      render: (text: string) => <>{text}</>,
      width: '24%',
    },
    {
      title: 'DATE UPLOADED',
      dataIndex: 'startedTime',
      key: 'startedTime',
      width: '38%',
      sorter: (a: any, b: any) => a.startedTime.localeCompare(b.startedTime),
      render: (text: string, record) => {
        return (
          <>
            {formatDateTime(text)} by {record.createdBy}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const locale = {
    emptyText: (
      <Empty
        description="No recent files available"
        image={<NoDataIcon />}
      ></Empty>
    ),
  };

  return (
    <>
      <ConfirmationDialog
        title={'Recent Files'}
        className={styles.recentFilesModal}
        centered
        confirmBtnFullWidth
        width={850}
        visible={isOpen}
        closable
        cancelText="Cancel"
        isCancelLink
        closeModal={handleModalClose}
        destroyOnClose
        confirmText="Upload"
        onConfirm={() => onUpload(selectedDocument)}
        isCancelApplicable={false}
      >
        <p className={styles.text}>
          Select from the latest uploads to include in the carrier quote
          submission
        </p>
        <Table
          loading={isFetching || isLoading}
          pagination={false}
          columns={columns}
          dataSource={mappedData}
          scroll={{ y: 400 }}
          className={styles.recentFilesTable}
          locale={locale}
        />
      </ConfirmationDialog>
    </>
  );
};

export default RecentFilesModal;
