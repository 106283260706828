import { Dispatch } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import Admin from 'model/admin/Admin';
import ErrorDetails from 'model/ErrorDetails';
import {
  uploadAdminProfileAvatar,
  createAdminUser,
  updateAdminById,
  findAdminById,
  deleteAdminById,
  inviteBrokerAdmin,
  inviteLumityTechAdmin,
  inviteErAdmin,
  dactivateAdmin,
} from 'modules/admins/services/AdminService';

import { STATUS_200, IndividualType } from 'constants/commonConstants';
import { getBlobFromEncodedImageString } from 'util/fileUtil';

export interface AdminListState {
  inProgress: boolean;
  error: any;
  requestType: string;
  adminAvatar: {
    inProgress: boolean;
    error: ErrorDetails | null;
    data: string | null;
  };
  avatarOriginal: string | null;
  avatarImageMap: { [id: string]: string };
  createAdminInProgress: boolean;
  createAdminCompleted: boolean;
  createAdminError: any;
  adminObj: Admin;
  updateInProgress: boolean;
  updateCompleted: boolean;
  adminUpdateError: ErrorDetails | null;
  adminFindInProgress: boolean;
  deleteInProgress: boolean;
  deleteCompleted: boolean;
  inviteInProgress: boolean;
  inviteCompleted: boolean;
  deactivateInProgress: boolean;
  deactivateCompleted: boolean;
}

const initialState = {
  inProgress: false,
  error: null,
  requestType: '',
  adminAvatar: {
    inProgress: false,
    error: null,
    data: null,
  },
  avatarOriginal: null,
  avatarImageMap: {},
  createAdminInProgress: false,
  createAdminCompleted: false,
  createAdminError: null,
  adminObj: {},
  updateInProgress: false,
  updateCompleted: false,
  adminUpdateError: null,
  adminFindInProgress: false,
  deleteInProgress: false,
  deleteCompleted: false,
  inviteInProgress: false,
  inviteCompleted: false,
  deactivateInProgress: false,
  deactivateCompleted: false,
} as AdminListState;

const adminSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    uploadAdminAvatarStarted: (state, { payload }) => {
      state.adminAvatar.inProgress = true;
      state.avatarImageMap[payload.id] = payload.image;
    },
    uploadAdminAvatarSuccess: (state, { payload }) => {
      state.adminAvatar.inProgress = false;
      state.adminAvatar.data = payload;
    },
    uploadAdminAvatarFailed: (state, { payload }) => {
      state.adminAvatar.inProgress = false;
      state.adminAvatar.error = { data: payload?.data };
    },
    clearAdminAvatar: (state) => {
      state.adminAvatar.inProgress = false;
      state.adminAvatar.data = null;
      state.adminAvatar.error = null;
      state.avatarImageMap = {};
      state.avatarOriginal = null;
    },
    createAdminStarted: (state) => {
      state.createAdminInProgress = true;
      state.createAdminError = null;
      state.adminObj = {} as Admin;
      state.createAdminCompleted = false;
    },
    createAdminSuccess: (state, action: PayloadAction<Admin>) => {
      state.createAdminInProgress = false;
      state.adminObj = action.payload;
      state.createAdminError = null;
      state.createAdminCompleted = true;
    },
    createAdminFailed: (state, action: PayloadAction<ErrorDetails | null>) => {
      state.createAdminInProgress = false;
      state.createAdminError = action.payload;
      state.adminObj = {} as Admin;
      state.createAdminCompleted = false;
    },
    updateAvatarOriginal(state, action: PayloadAction<string>) {
      state.avatarOriginal = action.payload;
    },
    clearAdminCreation: (state) => {
      state.createAdminInProgress = false;
      state.adminObj = {} as Admin;
      state.createAdminError = null;
      state.avatarOriginal = null;
      state.createAdminCompleted = false;
    },
    updateAdminStarted: (state) => {
      state.updateInProgress = true;
      state.adminUpdateError = null;
    },
    updateAdminCompleted: (state, action) => {
      state.updateInProgress = false;
      state.updateCompleted = action.payload === STATUS_200;
      state.adminUpdateError = null;
    },
    clearUpdateAdminUpdation: (state) => {
      state.updateInProgress = false;
      state.updateCompleted = false;
      state.adminUpdateError = null;
    },
    updateAdminFailed: (state, action) => {
      state.updateInProgress = false;
      state.updateCompleted = true;
      state.adminUpdateError = action.payload;
    },
    getAdminByIdStarted: (state) => {
      state.adminFindInProgress = true;
      state.adminObj = {} as Admin;
      state.error = null;
    },
    getAdminByIdCompleted: (state, action: PayloadAction<Admin>) => {
      state.adminFindInProgress = false;
      state.adminObj = action.payload;
      state.error = null;
    },
    getAdminByIdFailed: (state, action) => {
      state.adminFindInProgress = false;
      state.adminObj = {} as Admin;
      state.error = { response: action.payload };
    },
    clearAdminFind: (state) => {
      state.adminFindInProgress = false;
      state.adminObj = {} as Admin;
      state.error = null;
    },
    deleteAdminStarted: (state) => {
      state.deleteInProgress = true;
    },
    deleteAdminCompleted: (state) => {
      state.deleteInProgress = false;
      state.deleteCompleted = true;
      state.error = null;
    },
    clearAdminDeletion: (state) => {
      state.deleteInProgress = false;
      state.deleteCompleted = false;
      state.error = null;
    },
    deleteAdminFailed: (state, action) => {
      state.deleteInProgress = false;
      state.deleteCompleted = true;
      state.error = action.payload;
    },
    inviteAdminStarted: (state) => {
      state.inviteInProgress = true;
    },
    inviteAdminCompleted: (state) => {
      state.inviteInProgress = false;
      state.inviteCompleted = true;
      state.error = null;
    },
    clearinviteAdmin: (state) => {
      state.inviteInProgress = false;
      state.inviteCompleted = false;
      state.error = null;
    },
    inviteAdminFailed: (state, action) => {
      state.inviteInProgress = false;
      state.inviteCompleted = true;
      state.error = { response: action.payload };
    },
    updateAdminAvatarMap: (state, { payload }) => {
      state.avatarImageMap[payload.id] = payload.image;
      // handles removing avatar
      if (!payload.image) {
        state.adminAvatar.data = '';
      }
    },
    deactivateAdminInviteStarted: (state) => {
      state.deactivateInProgress = true;
    },
    deactivateAdminInviteCompleted: (state) => {
      state.deactivateInProgress = false;
      state.deactivateCompleted = true;
      state.error = null;
    },
    clearDeactivateAdminInvite: (state) => {
      state.deactivateInProgress = false;
      state.deactivateCompleted = false;
      state.error = null;
    },
    deactivateAdminInviteFailed: (state, action) => {
      state.deactivateInProgress = false;
      state.deactivateCompleted = true;
      state.error = { response: action.payload };
    },
  },
});

export const {
  uploadAdminAvatarStarted,
  uploadAdminAvatarSuccess,
  uploadAdminAvatarFailed,
  clearAdminAvatar,
  createAdminStarted,
  createAdminSuccess,
  createAdminFailed,
  clearAdminCreation,
  updateAdminStarted,
  updateAdminCompleted,
  updateAdminFailed,
  clearUpdateAdminUpdation,
  getAdminByIdStarted,
  getAdminByIdCompleted,
  getAdminByIdFailed,
  clearAdminFind,
  deleteAdminStarted,
  deleteAdminCompleted,
  deleteAdminFailed,
  clearAdminDeletion,
  inviteAdminStarted,
  inviteAdminCompleted,
  inviteAdminFailed,
  clearinviteAdmin,
  updateAdminAvatarMap,
  deactivateAdminInviteStarted,
  deactivateAdminInviteCompleted,
  deactivateAdminInviteFailed,
  clearDeactivateAdminInvite,
  updateAvatarOriginal,
} = adminSlice.actions;

export default adminSlice.reducer;

export const uploadAdminAvatar =
  (id: string, image: string, originalImage?: string) =>
  async (dispatch: any) => {
    if (image) {
      dispatch(uploadAdminAvatarStarted({ id, image }));
      try {
        if (originalImage) {
          const originalImageBlob = await getBlobFromEncodedImageString(
            originalImage
          );
          const originalImageRes = await uploadAdminProfileAvatar(
            originalImageBlob
          );
          dispatch(updateAvatarOriginal(originalImageRes.data.avatarUrl));
        }
        const imageBlob = await getBlobFromEncodedImageString(image);
        const response = await uploadAdminProfileAvatar(imageBlob);
        dispatch(uploadAdminAvatarSuccess(response.data.avatarUrl));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          dispatch(
            uploadAdminAvatarFailed(JSON.parse(JSON.stringify(error.response)))
          );
        } else {
          console.error(error);
        }
      }
    }
  };

export const createAdmin =
  (type: string, data: Admin) => async (dispatch: Dispatch) => {
    dispatch(createAdminStarted());
    try {
      const individual = {
        individual: data,
      };
      const response = await createAdminUser(type, individual);
      dispatch(clearAdminAvatar());
      dispatch(createAdminSuccess(response.data));
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(
            createAdminFailed({ data: error.response.data } as ErrorDetails)
          );
        } else {
          // TODO: handle errors
          console.log('Admin creation throws ' + JSON.stringify(error));
        }
      } else {
        // TODO: handle errors
        console.log('Admin creation throws ' + JSON.stringify(error));
      }
    }
  };

export const updateAdmin = (adminId: string, data: Admin) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateAdminStarted());
    try {
      const response = await updateAdminById(adminId, data);
      dispatch(clearAdminAvatar());
      dispatch(updateAdminCompleted(response.status));
    } catch (error: any) {
      dispatch(updateAdminFailed(error.response));
    }
  };
};

export const findAdminByAdminId = (adminId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getAdminByIdStarted());
    try {
      const response = await findAdminById(adminId);
      dispatch(getAdminByIdCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getAdminByIdFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const deleteAdmin = (
  organizationId: string | null,
  individualId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteAdminStarted());
    try {
      await deleteAdminById(organizationId, individualId);
      dispatch(deleteAdminCompleted());
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          dispatch(deleteAdminFailed(error.response));
          dispatch(deleteAdminCompleted());
        } else {
          console.log('Admin delete throws ' + JSON.stringify(error));
        }
      } else {
        console.log('Admin delete throws ' + JSON.stringify(error));
      }
    }
  };
};

export const inviteAdmin = (type: string, individualId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(inviteAdminStarted());
    try {
      const data = {
        individualId: individualId,
        invitedRole: 'ADMIN',
        application: 'UNIFIED_PORTAL',
      };
      if (IndividualType.ORGANIZATION_ADMIN === type) {
        await inviteBrokerAdmin(data);
      } else if (IndividualType.LUMITY_ADMIN === type) {
        await inviteLumityTechAdmin(data);
      } else if (IndividualType.ER_ADMIN === type) {
        await inviteErAdmin(data);
      }
      dispatch(inviteAdminCompleted());
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(inviteAdminFailed(JSON.parse(JSON.stringify(error.response))));
      } else {
        console.error(error);
      }
    }
  };
};

export const deactivateAdminInvite = (individualId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(deactivateAdminInviteStarted());
    try {
      const data = {
        individualId: individualId,
      };
      await dactivateAdmin(data);
      dispatch(deactivateAdminInviteCompleted());
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          deactivateAdminInviteFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};
