import { FC, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useStateCallback } from 'hooks/updateState';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import {
  clearProposalObject,
  setCompletedSteps,
} from 'modules/renewals/slices/proposalCreateStepperSlice';
import AddProposal from 'modules/renewals/pages/proposals/NewProposalCreateView/AddProposal';
import {
  CLONE_PROPOSAL_TXT,
  CREATE_NEW_PROPOSAL_TXT,
  DRAFT_OFFER_EXIST_ON_PROPOSAL_BUTTON_TITLE,
  DRAFT_OFFER_EXIST_ON_PROPOSAL_WARNING_MESSAGE,
  DRAFT_OFFER_EXIST_ON_PROPOSAL_WITH_SAME_NAME_WARNING_MESSAGE,
  DRAFTS_TAB,
  EDIT_PROPOSAL_TXT,
  FOR_REVIEW_TAB,
  OFFER_STATUS,
} from 'modules/renewals/constants/renewalsConstants';
import RenewalType from 'modules/renewals/enums/RenewalType';
import CloseModalPopup from 'modules/renewals/components/CloseModalPopup/CloseModalPopup';
import CloseType from 'modules/renewals/enums/CloseType';
import {
  useCreateProposalCloneMutation,
  useLazyCheckDraftOfferExistForProposalQuery,
  useLazyGetDefaultHRAPlansQuery,
  useProposalStatusUpdateMutation,
} from 'modules/renewals/slices/proposalSlice';
import { isEmptyOrUndefined } from 'util/stringUtil';
import UpdateHRAValues from 'modules/renewals/components/UpdateHRAValues/UpdateHRAValues';

import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import styles from './addProposalModal.module.less';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  proposalId?: string;
  setIsCloneProposalOpen?: Function;
  cloneProposal?: boolean;
  isProposalDetailedView?: boolean;
  isProposalCreateModalOpen?: boolean;
  isCloseModal?: boolean;
  setIsProposalCreateModalOpen?: () => void;
  setCloseModal?: () => void;
  changeProposalId?: Function;
  proposalType?: string;
  setProposalType?: Function;
};

const AddProposalModal: FC<Props> = (props: Props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    proposalId,
    setIsCloneProposalOpen,
    cloneProposal,
    isProposalDetailedView = false,
    isProposalCreateModalOpen = false,
    isCloseModal = false,
    setIsProposalCreateModalOpen,
    setCloseModal,
    changeProposalId,
    proposalType,
    setProposalType,
  } = props;

  const containerRef = useRef<any>();

  const [publishProposal, { isLoading }] = useProposalStatusUpdateMutation();
  const [createProposalClone, { isLoading: createCloneProposal }] =
    useCreateProposalCloneMutation();

  const { employerId, brokerId } = useParams();
  const proposalDetails = useAppSelector(
    (state) => state.proposalOnBoarding.proposal
  );

  const [currentStep, setCurrentStep] = useState(0);
  const [isConfirmOpen, setIsConfirmOpen] = useStateCallback(false);
  const [isNext, setIsNext] = useState(false);
  const [formDataIsValidating, setFormDataIsValidating] = useState(false);
  const [isFormValidated, setIsFormValidated] = useState(true);
  const [closeProposalId, setCloseProposalId] = useState<string>(
    proposalId ?? ''
  );
  const [nextButtonIsLoading, setNextButtonIsLoading] =
    useState<boolean>(false);
  const [lastUpdatedProposalId, setLastUpdatedProposalId] = useState<
    string | null
  >('');
  const [showHRAUpdateModal, setShowHRAUpdateModal] = useState(false);
  const [isPublishedOrDraft, setIsPublishedOrDraft] = useState(false);
  const [isDraftExistInProposal, setIsDraftExistInProposal] = useState(false);

  const [getHRAPlans, { data: hraPlans = [] }] =
    useLazyGetDefaultHRAPlansQuery();
  const [checkDraftExist] = useLazyCheckDraftOfferExistForProposalQuery();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isDraftNamePublished, setIsDraftNamePublished] = useState(false);

  const [getUpcomingPlanYears, { data: upcomingData }] =
    useLazyGetUpcomingPlanYearsByEmployerQuery();

  const [newTitle, setNewTitle] = useState('');

  useEffect(() => {
    if (employerId) {
      getUpcomingPlanYears({ employerId: String(employerId) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !isModalOpen &&
      lastUpdatedProposalId &&
      hraPlans?.length > 0 &&
      isPublishedOrDraft
    ) {
      setShowHRAUpdateModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, hraPlans?.length, lastUpdatedProposalId]);

  const handleCloseModal = async (
    notShowConfirmMessage: any,
    proposalStatus?: string | undefined
  ) => {
    if (lastUpdatedProposalId) {
      getHRAPlans({ id: lastUpdatedProposalId });
    }

    if (currentStep === 5 || notShowConfirmMessage === true) {
      setNewTitle(title);
      setIsConfirmOpen(false);
      setCloseModal && setCloseModal();
      dispatch(clearProposalObject());
      setCurrentStep(0);
      dispatch(setCompletedSteps([]));
      setIsModalOpen(false);
      setIsProposalCreateModalOpen && setIsProposalCreateModalOpen();
      setIsCloneProposalOpen && setIsCloneProposalOpen();
      setCloseProposalId('');
      changeProposalId && changeProposalId(null);
      setIsNext(false);
      setProposalType && setProposalType('');
      navigateOnSave(
        proposalStatus
          ? proposalStatus
          : proposalType === OFFER_STATUS.DRAFT
          ? OFFER_STATUS.DRAFT
          : OFFER_STATUS.PUBLISHED
      );
    } else {
      if (currentStep === 0) {
        setFormDataIsValidating(true);
        const validated = await containerRef?.current?.validateBasicInfoForm();
        setIsFormValidated(validated);
        setFormDataIsValidating(false);
      }
      setIsConfirmOpen(true);
    }
  };

  const setNextStep = () => {
    setIsNext(true);
  };

  const getMatchingEffectiveDate = () => {
    const matchedDate = upcomingData?.upcomingPlanYears?.find(
      (item) => item.id === proposalDetails?.payload?.effectiveDateId
    );
    return ` ${
      matchedDate !== undefined
        ? ' - ' +
          dayjs(matchedDate?.effectiveStartDate)
            .format('ll')
            .concat(' - ', dayjs(matchedDate?.effectiveEndDate).format('ll'))
        : ''
    }`;
  };

  const getProposalTitle = () => {
    if (proposalId) {
      return cloneProposal ? CLONE_PROPOSAL_TXT : EDIT_PROPOSAL_TXT;
    } else {
      return CREATE_NEW_PROPOSAL_TXT;
    }
  };

  const navigateOnSave = (status: string) => {
    if (status === OFFER_STATUS.DRAFT) {
      navigate(
        `/brokers/${brokerId}/employers/${employerId}/renewals/proposals`,
        { state: { proposalStatus: DRAFTS_TAB } }
      );
    } else {
      navigate(
        `/brokers/${brokerId}/employers/${employerId}/renewals/proposals`,
        { state: { proposalStatus: FOR_REVIEW_TAB } }
      );
    }
  };

  const handlePublishProposal = async () => {
    setNextButtonIsLoading(true);
    const updatedProposalId =
      await containerRef?.current?.nextEventForCurrentTab();
    setNextButtonIsLoading(false);
    const proposalId =
      closeProposalId === null ||
      closeProposalId === undefined ||
      isEmpty(closeProposalId)
        ? updatedProposalId
        : closeProposalId ?? '';
    const draftValidation = await checkDraftExist({
      id: proposalId,
    });
    const { draftExist = false, publishedExist = false } =
      draftValidation?.data ?? {};
    setIsDraftNamePublished(publishedExist);
    setIsDraftExistInProposal(draftExist);
    if (!draftExist) {
      await publishProposalOnConfirm();
    }
  };

  const publishProposalOnConfirm = async () => {
    const updatedProposalId =
      await containerRef?.current?.nextEventForCurrentTab();
    const proposalId =
      closeProposalId === null ||
      closeProposalId === undefined ||
      isEmpty(closeProposalId)
        ? updatedProposalId
        : closeProposalId ?? '';
    await publishProposal({
      proposalId,
      proposalStatus: OFFER_STATUS.PUBLISHED,
      isNotificationActive: false,
    });
    setIsPublishedOrDraft(true);
    handleCloseModal(true);
    setIsDraftExistInProposal(false);
    navigateOnSave(OFFER_STATUS.PUBLISHED);
  };

  const handleDraftProposal = async () => {
    setNextButtonIsLoading(true);
    const updatedProposalId =
      await containerRef?.current?.nextEventForCurrentTab();
    setNextButtonIsLoading(false);
    await publishProposal({
      proposalId:
        closeProposalId === null ||
        closeProposalId === undefined ||
        isEmpty(closeProposalId)
          ? updatedProposalId
          : closeProposalId ?? '',
      proposalStatus: OFFER_STATUS.DRAFT,
      isNotificationActive: false,
    });
    setIsPublishedOrDraft(true);
    handleCloseModal(true);
    navigateOnSave(OFFER_STATUS.DRAFT);
  };

  const createProposalClonePreview = async () => {
    const { id } = await createProposalClone({
      proposalId: proposalId ?? '',
      isClone: cloneProposal ?? false,
    }).unwrap();
    changeProposalId && changeProposalId(id);
    setCloseProposalId(id);
  };

  useEffect(() => {
    if (
      isModalOpen &&
      !isEmpty(proposalId) &&
      proposalId !== null &&
      proposalId !== undefined &&
      !cloneProposal
    ) {
      createProposalClonePreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const assignCloseType = () => {
    if (!isFormValidated) {
      return CloseType.ERROR;
    } else if (
      !isEmptyOrUndefined(proposalId) &&
      (proposalType === 'PUBLISHED' || proposalType === 'APPROVED')
    ) {
      return CloseType.EDIT_PUBLISHED;
    } else if (!isEmptyOrUndefined(proposalId) && proposalType === 'DRAFT') {
      return CloseType.EDIT_DRAFT;
    } else {
      return CloseType.CREATE;
    }
  };

  const title = getProposalTitle();

  if (showHRAUpdateModal) {
    return (
      <UpdateHRAValues
        hraPlans={hraPlans}
        onSaved={() => {
          setShowHRAUpdateModal(false);
          handleCloseModal(true);
          setLastUpdatedProposalId(null);
          setIsPublishedOrDraft(false);
        }}
        visible={showHRAUpdateModal}
        onCancel={() => {
          setShowHRAUpdateModal(false);
          setLastUpdatedProposalId(null);
          setIsPublishedOrDraft(false);
        }}
      />
    );
  }

  return (
    <FullScreenModal
      visible={isModalOpen}
      onCancel={handleCloseModal}
      footer={false}
      title={
        <>
          <span>{!isCloseModal ? title : newTitle}</span>
          <br />
          {proposalDetails ? (
            currentStep === 0 && cloneProposal ? null : (
              <span className={styles.modalSubText}>
                {proposalDetails?.payload?.name} {getMatchingEffectiveDate()}
              </span>
            )
          ) : null}
        </>
      }
      forceRender
      destroyOnClose={true}
    >
      <div className={styles.contentWrapper}>
        <AddProposal
          title={title}
          ref={containerRef}
          closeModal={handleCloseModal}
          currentStep={currentStep}
          onNextStep={() => setCurrentStep(currentStep + 1)}
          onPreviousStep={() => setCurrentStep(currentStep - 1)}
          changeCurrentStep={(step) => setCurrentStep(step)}
          isModalOpen={isModalOpen}
          proposalId={cloneProposal ? proposalId : closeProposalId}
          cloneProposal={cloneProposal}
          isProposalDetailedView={isProposalDetailedView}
          isProposalCreateModalOpen={isProposalCreateModalOpen}
          isNext={isNext}
          setIsNextStep={setNextStep}
          changeProposalId={setCloseProposalId}
          isLoading={createCloneProposal}
          proposalType={proposalType}
          onChangedProposalId={setLastUpdatedProposalId}
          setIsPublishedOrDraft={setIsPublishedOrDraft}
        />
      </div>
      <CloseModalPopup
        visible={isConfirmOpen}
        type={RenewalType.PROPOSAL}
        closeType={assignCloseType()}
        onPublish={handlePublishProposal}
        onSaveAsDraft={handleDraftProposal}
        onCloseWithoutSaving={() => handleCloseModal(true)}
        onCancelClick={() => setIsConfirmOpen(false)}
        isLoading={isLoading || nextButtonIsLoading}
        overallLoader={formDataIsValidating}
      />
      <ConfirmationDialog
        title="Publish Proposal"
        confirmText={DRAFT_OFFER_EXIST_ON_PROPOSAL_BUTTON_TITLE}
        cancelText="Cancel"
        closeModal={() => {
          setIsDraftExistInProposal(false);
        }}
        onConfirm={publishProposalOnConfirm}
        confirmLoading={false}
        visible={isDraftExistInProposal}
        isCancelLink={true}
        zIndex={10000}
      >
        <>
          {DRAFT_OFFER_EXIST_ON_PROPOSAL_WARNING_MESSAGE}
          {isDraftNamePublished && (
            <div className={styles.draftOfferPopUpContainer}>
              {DRAFT_OFFER_EXIST_ON_PROPOSAL_WITH_SAME_NAME_WARNING_MESSAGE}
            </div>
          )}
        </>
      </ConfirmationDialog>
    </FullScreenModal>
  );
};

export default AddProposalModal;
