import { useState } from 'react';
import { Card, Col, Dropdown, Menu, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useParams } from 'react-router-dom';
import { cloneDeep, isEmpty } from 'lodash';
import reactHtmlParser from 'react-html-parser';
import DraftTagOnPublishedOffer from 'modules/renewals/components/helperComponents/DraftTagOnPublishedOffer/DraftTagOnPublishedOffer';
import { useAppSelector } from 'hooks/redux';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';

import CardTopStatusTag from 'modules/renewals/components/helperComponents/CardTopStatusTag/CardTopStatusTag';
import OfferTypeLabel from 'modules/renewals/components/helperComponents/OfferTypeLabel/OfferTypeLabel';
import RenewalCommonMainHeader from 'modules/renewals/components/helperComponents/RenewalCommonMainHeader/RenewalCommonMainHeader';
import OfferCardOverview from 'modules/renewals/components/helperComponents/OfferCardOverview/OfferCardOverview';
import SummeryCommonTable from 'modules/renewals/components/helperComponents/SummeryCommonTable/SummeryCommonTable';
import CheckboxButton from 'modules/renewals/components/helperComponents/CheckboxButton/CheckboxButton';
import FromCurrentPercentage from 'modules/renewals/components/helperComponents/FromCurrentPercentage/FromCurrentPercentage';

import {
  OFFER_BENEFIT_TYPE_NAVIGATION_LABELS,
  OFFER_CATEGORY,
  OFFER_NAME_DUPLICATED_IN_PROPOSAL_ERROR_MESSAGE,
  ONLY_CREDITS_INCLUSION,
  STD,
} from 'modules/renewals/constants/renewalsConstants';
import { loginTypes } from 'constants/authConstants';
import { NewPlanTag } from 'modules/renewals/components/NewPlanTag/NewPlanTag';

import {
  currencyFormatter,
  currencyFormatterNoDecimals,
} from 'util/commonUtil';
import { getFileNameEllipsis } from 'util/stringUtil';
import {
  CardTopStatusType,
  CreditsInclusionType,
} from 'modules/renewals/types/renewalsTypes';
import { ReactComponent as IconPopout } from 'assets/images/icon-popout.svg';
import { handleFileItemDownloadClickForPopulatedFileItem } from 'modules/renewals/services/RenewalService';
import styles from './proposalCreateSelectOfferCard.module.less';

type Props = {
  id: string;
  planType: keyof typeof OFFER_CATEGORY;
  planData?: any[];
  itemDetails?: any;
  tags?: string[];
  offerType?: string | null;
  logoUrl?: string;
  creditSelectedValue?: string;
  showSummerySection?: boolean;
  checkSelected?: boolean;
  selectButtonDisable?: {
    isPrimaryValidation: boolean;
    isNameDuplicated: boolean;
  };
  setCreditSelectedValue: (value: any) => void;
  isNonMdv: boolean;
  handleSelectOffer: ({
    id,
    offerType,
  }: {
    id: string;
    offerType?: string | null;
  }) => void;
  hasADraftOfferWithSameName: boolean;
};

const ProposalCreateSelectOfferCard = (props: Props) => {
  const {
    id,
    itemDetails,
    planType,
    tags,
    logoUrl,
    planData,
    offerType,
    creditSelectedValue = ONLY_CREDITS_INCLUSION.EXCLUDED.value,
    showSummerySection = true,
    checkSelected,
    selectButtonDisable,
    setCreditSelectedValue,
    handleSelectOffer,
    isNonMdv = false,
    hasADraftOfferWithSameName,
  } = props;

  const params = useParams();
  const [showDescription, setShowDescription] = useState(false);
  const isSelectDisabled: boolean =
    (selectButtonDisable?.isPrimaryValidation ||
      selectButtonDisable?.isNameDuplicated) ??
    false;
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const isBrokerAdmin: boolean = appBootInfo?.type === loginTypes.bokerAdmin;
  const isPlatformAdmin: boolean = appBootInfo?.type === loginTypes.platform;

  const isPlaceHolderOffer = (): boolean => {
    return itemDetails?.placeholderCreated;
  };

  const addPlusSignFromCurrentFormat = (fromCurrent: number) => {
    const formattedString: string = currencyFormatterNoDecimals(fromCurrent);
    if (!formattedString.includes('-')) {
      return '+' + formattedString;
    }

    return formattedString;
  };

  const sortPlanData = () => {
    if (!planData) return [];
    const planDataClone = cloneDeep(planData);
    return planDataClone?.sort((a, b) => {
      if (a?.newPlan == b?.newPlan) {
        const totalPremiumA = a?.annualContributionDefault?.totalPremium ?? 0;
        const totalPremiumB = b?.annualContributionDefault?.totalPremium ?? 0;
        return totalPremiumB - totalPremiumA;
      } else {
        return a?.newPlan ? 1 : -1;
      }
    });
  };

  const getTableColumns = (type: any) => {
    let columns: ColumnsType<any> = [];
    switch (type) {
      case OFFER_CATEGORY.VOLUNTARY: {
        columns = [
          {
            width: '80%',
            title: (
              <>
                <img
                  src={logoUrl}
                  alt={'carrier logo'}
                  className={styles.carrierLogo}
                />
              </>
            ),
            key: 'name',
            dataIndex: 'name',
            render: (data, record: any) => {
              return <span className={styles.planName}>{data}</span>;
            },
          },
          {
            width: '20%',
            title: 'Plan Documents',
            key: 'attachedDocuments',
            dataIndex: 'attachedDocuments',
            render: (data, record) => {
              const documentsArray = record.attachedDocuments
                ? record.attachedDocuments
                : [];
              const adminActionsMenu = (attachments: any[]) => {
                return record.attachedDocuments?.length ? (
                  <Menu className={styles.scrollableMenu}>
                    {attachments.map((attachment) => {
                      return (
                        <Menu.Item
                          key={attachment.referenceId}
                          onClick={() => {
                            handleFileItemDownloadClickForPopulatedFileItem(
                              attachment.downloadUrl,
                              attachment.fileName
                            );
                          }}
                        >
                          <span className={styles.dropDownItem}>
                            {getFileNameEllipsis({
                              fileName: String(attachment?.fileName),
                              limit: 25,
                            })}
                          </span>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                ) : (
                  <></>
                );
              };
              return (
                <>
                  <Dropdown
                    trigger={['click']}
                    overlay={adminActionsMenu(documentsArray)}
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                        ? triggerNode.parentElement
                        : triggerNode
                    }
                  >
                    <p
                      className={styles.attachmentLabel}
                      onClick={(e) => e.preventDefault()}
                    >
                      {!record.attachedDocuments?.length ? (
                        <span>-</span>
                      ) : (
                        <span className={styles.attachmentText}>
                          {`${record.attachedDocuments?.length} ${
                            record.attachedDocuments?.length === 1
                              ? 'Document'
                              : 'Documents'
                          }`}
                        </span>
                      )}
                      {}
                    </p>
                  </Dropdown>
                </>
              );
            },
          },
        ];
        return columns;
      }
      default: {
        columns = [
          {
            width: '60%',
            title: (
              <>
                <img
                  src={logoUrl}
                  alt={'carrier logo'}
                  className={styles.carrierLogo}
                />
              </>
            ),
            key: 'name',
            render: (_, record: any) => {
              return (
                <Row>
                  <span className={styles.planName}>{record.planName}</span>
                  {record?.newPlan && (
                    <NewPlanTag className={styles.newPlanTagWrapper} />
                  )}
                </Row>
              );
            },
          },
          {
            width: '20%',
            title: 'ANNUAL PREMIUM',
            key: 'premium',
            render: (_, record: any) => {
              return (
                <span>
                  {record?.annualContributionDefault?.totalPremium
                    ? currencyFormatterNoDecimals(
                        record?.annualContributionDefault?.totalPremium
                      )
                    : '-'}
                </span>
              );
            },
          },
          {
            width: '20%',
            title: 'FROM CURRENT',
            key: 'fromCurrent',
            render: (_, record: any) => {
              if (isNonMdv) {
                return getNonMdvFromCurrentData(record);
              }
              if (record?.newPlan) {
                return '-';
              }
              return (
                <>
                  {record?.comparisons
                    ? `${
                        record?.comparisons?.initialDiff >= 0 ? '+' : ''
                      }${currencyFormatterNoDecimals(
                        record?.comparisons?.initialDiff
                      )}`
                    : '-'}{' '}
                  <FromCurrentPercentage
                    percentage={record?.comparisons?.initialDiffPercent}
                    decimalPlaces={2}
                    showBrackets={true}
                    singleLine={true}
                  />
                </>
              );
            },
          },
        ];
        return columns;
      }
    }
  };

  const getNonMdvFromCurrentData = (record: any) => {
    return (
      <>
        {record?.comparisons
          ? `${
              record?.comparisons?.initialDiff >= 0 ? '+' : ''
            }${currencyFormatterNoDecimals(record?.comparisons?.initialDiff)}`
          : '-'}{' '}
        {isPlaceHolderOffer() || record.newPlan ? (
          <span className={styles.notApplicablePercentageChange}>(N/A)</span>
        ) : (
          <FromCurrentPercentage
            percentage={record?.comparisons?.initialDiffPercent}
            decimalPlaces={2}
            showBrackets={true}
            singleLine={true}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Card
        className={`${styles.mainCard} ${
          checkSelected ? styles.selectedCard : ''
        }`}
        bodyStyle={{ padding: '24px 35px' }}
      >
        <div className={styles.tagWrapper}>
          {tags?.map((item) => (
            <CardTopStatusTag key={item} status={item as CardTopStatusType} />
          ))}
          {(isBrokerAdmin || isPlatformAdmin) && hasADraftOfferWithSameName && (
            <DraftTagOnPublishedOffer />
          )}
        </div>
        <Row>
          <Col
            span={14}
            className={`${
              itemDetails?.category !== OFFER_CATEGORY.MEDICAL
                ? styles.actionBtnWrapperNotMedical
                : ''
            }`}
          >
            <OfferTypeLabel type={offerType} />
            <RenewalCommonMainHeader
              mainText={itemDetails.name}
              showFundingTypeLabel={[
                STD,
                OFFER_CATEGORY.VISION,
                OFFER_CATEGORY.DENTAL,
                OFFER_CATEGORY.MEDICAL,
              ].includes(itemDetails.category)}
              isProposalCreateSelectOfferCard={true}
              fundingType={itemDetails.fundingType}
            />
          </Col>
          <Col span={10} className={`${styles.actionBtnWrapper} `}>
            <Row justify="end" align="middle" gutter={16}>
              <Col span={6}>
                <LinkButton
                  onClick={() =>
                    window.open(
                      `/brokers/${params?.brokerId}/employers/${
                        params?.employerId
                      }/renewals/carrier/${
                        OFFER_BENEFIT_TYPE_NAVIGATION_LABELS[
                          itemDetails?.category
                        ]
                      }/${itemDetails?.id}/detail`,
                      '_blank'
                    )
                  }
                >
                  Details <IconPopout className={styles.popoutIcon} />
                </LinkButton>
              </Col>
              <Col span={12}>
                <CheckboxButton
                  value={id}
                  checked={Boolean(checkSelected)}
                  onHandleSelected={(value) => {
                    handleSelectOffer({
                      id: value,
                      offerType: offerType,
                    });
                  }}
                  label="Select This Offer"
                  disabled={isSelectDisabled}
                  showTooltip={isSelectDisabled}
                  tooltipContent={
                    selectButtonDisable?.isNameDuplicated
                      ? OFFER_NAME_DUPLICATED_IN_PROPOSAL_ERROR_MESSAGE
                      : `Only one primary ${planType.toLowerCase()} offer can be selected`
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {showSummerySection && (
          <Row gutter={16}>
            <Col span={8}>
              <OfferCardOverview
                headerText="Total Est. Annual Premium"
                value={`${
                  creditSelectedValue === ONLY_CREDITS_INCLUSION.INCLUDED.value
                    ? currencyFormatterNoDecimals(
                        itemDetails.totalContributionIncludeCredits
                      )
                    : currencyFormatterNoDecimals(
                        itemDetails.offerContributions.annualContributionDefault
                          .totalPremium
                      )
                }`}
                footerElement={
                  <SingleSelectFilter
                    data={Object.values(ONLY_CREDITS_INCLUSION)}
                    setFilterSelectedValue={(value: any) => {
                      setCreditSelectedValue(value);
                    }}
                    inlineDropdown={true}
                    selectedValue={
                      ONLY_CREDITS_INCLUSION[
                        creditSelectedValue as CreditsInclusionType
                      ].value
                    }
                    defaultValue=""
                    placeholder="-"
                    showSearch={false}
                    isSelectionHighlightAndTickApplicable={false}
                  />
                }
              />
            </Col>
            <Col span={8}>
              <OfferCardOverview
                headerText="From Current"
                value={`${
                  creditSelectedValue === ONLY_CREDITS_INCLUSION.INCLUDED.value
                    ? itemDetails.totalContributionDiff
                      ? addPlusSignFromCurrentFormat(
                          itemDetails.totalContributionDiff
                        )
                      : '-'
                    : itemDetails.comparisons.initialDiff
                    ? addPlusSignFromCurrentFormat(
                        itemDetails.comparisons.initialDiff
                      )
                    : '-'
                }`}
                footerElement={
                  <>
                    {isPlaceHolderOffer() ? (
                      <span className={styles.naLabel}>N/A</span>
                    ) : (
                      <FromCurrentPercentage
                        percentage={
                          creditSelectedValue ===
                          ONLY_CREDITS_INCLUSION.INCLUDED.value
                            ? itemDetails.totalContributionDiffPercent
                            : itemDetails.comparisons.initialDiffPercent
                        }
                        decimalPlaces={2}
                        showBrackets={false}
                        singleLine={true}
                      />
                    )}
                    &nbsp; from Current
                  </>
                }
              />
            </Col>
            <Col span={8}>
              <OfferCardOverview
                headerText="Credits"
                value={
                  itemDetails?.discountAndCredit
                    ? currencyFormatterNoDecimals(
                        itemDetails?.discountAndCredit
                      )
                    : '-'
                }
                footerElement={
                  itemDetails?.discountAndCreditDescription && (
                    <span
                      className={styles.overviewViewDescription}
                      onClick={() => setShowDescription(true)}
                    >
                      View Description
                    </span>
                  )
                }
              />
            </Col>
          </Row>
        )}

        {itemDetails?.description ? (
          <Row
            className={`${styles.description} ${
              itemDetails?.category === OFFER_CATEGORY.VOLUNTARY
                ? styles.removeDescriptionMargin
                : ''
            }`}
          >
            <p>
              {isEmpty(itemDetails?.description) ||
              itemDetails?.description === null
                ? '-'
                : reactHtmlParser(itemDetails?.description)}
            </p>
          </Row>
        ) : null}

        <SummeryCommonTable
          data={sortPlanData()}
          columns={getTableColumns(planType)}
        />
      </Card>

      <ConfirmationDialog
        title="Credits"
        isCloseOnly={true}
        centered
        confirmText="Close"
        onConfirm={() => {
          setShowDescription(false);
        }}
        visible={showDescription}
        isRecordApplicable={true}
        recordName={itemDetails?.carrier}
      >
        <p className={styles.creditModelTextWrapper}>
          <span className={styles.creditAmount}>
            {currencyFormatter(
              itemDetails?.discountAndCredit,
              undefined,
              undefined,
              0
            )}
          </span>{' '}
          <br />
          {itemDetails?.discountAndCreditDescription}
        </p>
      </ConfirmationDialog>
    </>
  );
};

export default ProposalCreateSelectOfferCard;
