import { ColumnsType } from 'antd/lib/table';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Table } from 'antd';
import { isEmpty } from 'lodash';
import expandedIcon from 'assets/images/icon-expanded.svg';
import collapseIcon from 'assets/images/icon-collapse.svg';
import { MDVPLANS } from 'modules/billing/constants/constants';
import styles from './billingTableExpandable.module.less';

const { Column, ColumnGroup } = Table;

type BillingTableExpandableProps = {
  benifitSummary: any;
  isPrintable?: boolean;
  employerName?: string;
  billingMonth?: string;
  billingYear?: number;
  isModel?: boolean;
};

// eslint-disable-next-line react/display-name
const BillingTableExpandable = forwardRef(
  (props: BillingTableExpandableProps, ref: any) => {
    const {
      benifitSummary,
      isPrintable,
      employerName,
      billingMonth,
      billingYear,
      isModel = false,
    } = props;

    const tableRef = useRef<HTMLDivElement>(null);
    const [colWidths, setColWidths] = useState<Record<string, number>>({});

    useEffect(() => {
      const table = tableRef.current;
      if (!table) return;

      const measureColumnWidths = () => {
        const rows = table.querySelectorAll('tr');
        if (rows.length > 1) {
          const cells = rows[1].querySelectorAll('th');
          const newColWidths: Record<string, number> = {};
          for (let i = 0; i < cells.length; i++) {
            newColWidths[i.toString()] = cells[i].getBoundingClientRect().width;
          }
          setColWidths(newColWidths);
        }
      };

      let observer: MutationObserver | null = null;
      if (typeof MutationObserver !== 'undefined') {
        observer = new MutationObserver(measureColumnWidths);
        const config = { attributes: true, childList: true, subtree: true };
        observer.observe(table, config);
      }

      window.addEventListener('resize', measureColumnWidths);

      return () => {
        if (observer) {
          observer.disconnect();
        }
        window.removeEventListener('resize', measureColumnWidths);
      };
    }, []);

    const keyArr: number[] = [];

    const getNestedColumns = (tier: string, indentLevel: number) => {
      if (isEmpty(colWidths)) {
        return [];
      }

      const isShowingEllipsis = !isPrintable;
      const offsetWidth = indentLevel * 48;
      const titleWidth = colWidths['0'] - offsetWidth;
      const columns: ColumnsType<any> = [
        {
          title: 'Title',
          dataIndex: tier,
          key: 'title',
          width: titleWidth + 'px',
          ellipsis: isShowingEllipsis,
        },
        {
          title: 'Count',
          dataIndex: 'count',
          key: 'count',
          width: colWidths['1'] + 'px',
          ellipsis: true,
        },
        {
          title: 'Volume',
          dataIndex: 'totalCurrentVolume',
          key: 'totalCurrentVolume',
          width: colWidths['2'] + '%',
          ellipsis: true,
        },
        {
          title: 'Premium',
          dataIndex: 'totalCurrentPremium',
          key: 'totalCurrentPremium',
          width: colWidths['3'] + 'px',
          ellipsis: true,
        },
        {
          title: 'Volume',
          dataIndex: 'totalRetroVolume',
          key: 'totalRetroVolume',
          width: colWidths['4'] + 'px',
          ellipsis: true,
        },
        {
          title: 'Premium',
          dataIndex: 'totalRetroPremium',
          key: 'totalRetroPremium',
          width: colWidths['5'] + 'px',
          ellipsis: true,
        },
        {
          title: 'Volume',
          dataIndex: 'totalVolume',
          key: 'totalVolume',
          width: colWidths['6'] + 'px',
          ellipsis: true,
        },
        {
          title: 'Premium',
          dataIndex: 'totalPremium',
          key: 'totalPremium',
          width: colWidths['7'] + 'px',
          ellipsis: true,
        },
      ];

      return columns;
    };

    const getData = () => {
      const previewData = [] as any;
      if (
        benifitSummary?.benefitSummary !== undefined &&
        benifitSummary?.benefitSummary !== null
      ) {
        Object.keys(benifitSummary?.benefitSummary ?? []).map(
          (key: any, index: any) => {
            const obj = {
              key: <div className={styles.benifitWrapper}>{key}</div>,
              benifit: <div className={styles.benifitWrapper}>{key}</div>,
              count: benifitSummary?.benefitSummary?.[key]?.count,
              totalCurrentVolume:
                benifitSummary?.benefitSummary?.[key]?.totalCurrentVolume,
              totalCurrentPremium:
                benifitSummary?.benefitSummary?.[key]?.totalCurrentPremium,
              totalRetroVolume:
                benifitSummary?.benefitSummary?.[key]?.totalRetroVolume,
              totalRetroPremium:
                benifitSummary?.benefitSummary?.[key]?.totalRetroPremium,
              totalVolume: benifitSummary?.benefitSummary?.[key]?.totalVolume,
              totalPremium: benifitSummary?.benefitSummary?.[key]?.totalPremium,
              billingStatusSummaries:
                benifitSummary?.benefitSummary?.[key]?.billingStatusSummaries,
            };
            previewData.push(obj);
          }
        );
        const obj = {
          key: 'SUB-TOTAL',
          benifit: 'SUB-TOTAL',
          count: benifitSummary.count,
          totalCurrentVolume: benifitSummary.totalCurrentVolume,
          totalCurrentPremium: benifitSummary.totalCurrentPremium,
          totalRetroVolume: benifitSummary.totalRetroVolume,
          totalRetroPremium: benifitSummary.totalRetroPremium,
          totalVolume: benifitSummary.totalVolume,
          totalPremium: benifitSummary.totalPremium,
          billingStatusSummaries: [],
        };
        previewData.push(obj);
      }

      return previewData;
    };

    const previewData = getData() as any[];

    const typeTier = (record: []) => {
      return (
        <Table
          pagination={false}
          columns={getNestedColumns('status', 1)}
          showHeader={false}
          expandable={{
            ...(isPrintable ? { expandedRowKeys: keyArr } : {}),
            expandIcon: (record) =>
              (addKey(record?.record?.planSummaries ?? [])?.length ?? 0) !==
                0 && !isPrintable
                ? customExpandIcon(record)
                : false,
            expandedRowRender: (record) =>
              planTier(record?.planSummaries ?? [], false, 2),
            rowExpandable: (record) =>
              (addKey(record?.planSummaries ?? [])?.length ?? 0) !== 0,
          }}
          dataSource={addKey(record)}
        />
      );
    };

    const planTier = (record: [], isMdv: boolean, indentLevel: number) => {
      return (
        <Table
          className={styles.planNamesTable}
          pagination={false}
          columns={getNestedColumns('planName', indentLevel)}
          showHeader={false}
          rowClassName={(record) =>
            (addKey(record?.tierSummaries ?? [])?.length ?? 0) !== 0
              ? 'isExpandable'
              : 'isNotExpandable'
          }
          expandable={{
            ...(isPrintable ? { expandedRowKeys: keyArr } : {}),
            expandIcon: (record) =>
              (addKey(record?.record?.tierSummaries ?? [])?.length ?? 0) !==
                0 && !isPrintable
                ? customExpandIcon(record)
                : false,
            expandedRowRender: (record) =>
              tier(record?.tierSummaries ?? [], isMdv, indentLevel),
            rowExpandable: (record) =>
              (addKey(record?.tierSummaries ?? [])?.length ?? 0) !== 0,
          }}
          dataSource={addKey(record)}
        />
      );
    };

    const tier = (record: [], isMdv: boolean, indentLevel: number) => {
      return (
        <Table
          className={styles.tierTable}
          columns={getNestedColumns('tier', indentLevel)}
          pagination={false}
          showHeader={false}
          dataSource={addKey(record)}
        />
      );
    };

    const customExpandIcon = (data: any) => {
      if (data.expanded) {
        return (
          <a
            style={{ color: 'black' }}
            onClick={(e) => {
              data.onExpand(data.record, e);
            }}
          >
            <img src={expandedIcon} />
          </a>
        );
      } else {
        return (
          <a
            style={{ color: 'black' }}
            onClick={(e) => {
              data.onExpand(data.record, e);
            }}
          >
            <img src={collapseIcon} />
          </a>
        );
      }
    };

    const addKey = (data: any[]) => {
      const arr = [] as any[];
      data.map((item, index) => {
        if (!keyArr.includes(index)) keyArr.push(index);
        if (
          !isEmpty(item['benifit']) ||
          !isEmpty(item['status']) ||
          !isEmpty(item['planName']) ||
          !isEmpty(item['tier'])
        ) {
          const obj = {
            ...item,
            align: 'right' as const,
            key: (item?.key ?? 'empty') === 'SUB-TOTAL' ? 'SUB-TOTAL' : index,
            count:
              (item?.count ?? 0) === 0 ? (
                <div className={styles.hyphen}>---</div>
              ) : (
                item.count
              ),
            totalCurrentVolume:
              (item?.totalCurrentVolume ?? 0) === 0 ? (
                <div className={styles.hyphen}>---</div>
              ) : item.totalCurrentVolume.toString().indexOf('-') !== -1 ? (
                '($' +
                item.totalCurrentVolume
                  .toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  .toString()
                  .replace('-', '') +
                ')'
              ) : (
                '$' +
                item.totalCurrentVolume.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              ),
            totalCurrentPremium:
              (item?.totalCurrentPremium ?? 0) === 0 ? (
                <div className={styles.hyphen}>---</div>
              ) : item.totalCurrentPremium.toString().indexOf('-') !== -1 ? (
                '($' +
                item.totalCurrentPremium
                  .toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  .toString()
                  .replace('-', '') +
                ')'
              ) : (
                '$' +
                item.totalCurrentPremium.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              ),
            totalRetroVolume:
              (item?.totalRetroVolume ?? 0) === 0 ? (
                <div className={styles.hyphen}>---</div>
              ) : item.totalRetroVolume.toString().indexOf('-') !== -1 ? (
                '($' +
                item.totalRetroVolume
                  .toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  .toString()
                  .replace('-', '') +
                ')'
              ) : (
                '$' +
                item.totalRetroVolume.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              ),
            totalRetroPremium:
              (item?.totalRetroPremium ?? 0) === 0 ? (
                <div className={styles.hyphen}>---</div>
              ) : item.totalRetroPremium.toString().indexOf('-') !== -1 ? (
                '($' +
                item.totalRetroPremium
                  .toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  .toString()
                  .replace('-', '') +
                ')'
              ) : (
                '$' +
                item.totalRetroPremium.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              ),
            totalVolume:
              (item?.totalVolume ?? 0) === 0 ? (
                <div className={styles.hyphen}>---</div>
              ) : item.totalVolume.toString().indexOf('-') !== -1 ? (
                '($' +
                item.totalVolume
                  .toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  .toString()
                  .replace('-', '') +
                ')'
              ) : (
                '$' +
                item.totalVolume.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              ),
            totalPremium:
              (item?.totalPremium ?? 0) === 0 ? (
                <div className={styles.hyphen}>---</div>
              ) : item.totalPremium.toString().indexOf('-') !== -1 ? (
                '($' +
                item.totalPremium
                  .toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  .toString()
                  .replace('-', '') +
                ')'
              ) : (
                '$' +
                item.totalPremium.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              ),
          };
          arr.push(obj);
        }
      });
      return arr as any[];
    };

    const getPageMargins = () => {
      return `
        @page {
          size:210mm 297mm;
          margin: 10px !important;
        }
      
        @media all {
          .pagebreak {
            display: none;
          }
        }
      
        @media print {
          .pagebreak {
            page-break-before: always;
          }
        }
      `;
    };

    const showEllipsis = !isPrintable;

    return (
      <div
        className={isModel ? styles.tableWrapperModelOpen : styles.tableWrapper}
        ref={ref}
      >
        <style>{getPageMargins()}</style>
        {isPrintable ? (
          <>
            <div className={styles.printTitle}>
              <h1>{employerName ?? ''}</h1>
            </div>
            <div className={styles.printTitle}>
              <h1>{`${billingMonth} ${billingYear} Invoice`}</h1>
            </div>
          </>
        ) : null}
        <Table
          ref={tableRef}
          pagination={false}
          expandable={{
            ...(isPrintable ? { expandedRowKeys: keyArr } : {}),
            expandIcon: (record) => {
              return MDVPLANS.includes(
                record?.record?.benifit?.props?.children ?? ''
              )
                ? (addKey(record?.record?.billingStatusSummaries ?? [])
                    ?.length ?? 0) !== 0
                  ? customExpandIcon(record)
                  : false
                : (addKey(
                    record?.record?.billingStatusSummaries[0]?.planSummaries ??
                      []
                  )?.length ?? 0) !== 0
                ? customExpandIcon(record)
                : false;
            },
            expandedRowRender: (record) =>
              MDVPLANS.includes(record?.benifit?.props?.children ?? '')
                ? typeTier(record.billingStatusSummaries ?? [])
                : planTier(
                    (record?.billingStatusSummaries?.length ?? 0) !== 0
                      ? record?.billingStatusSummaries[0].planSummaries
                      : [],
                    true,
                    1
                  ),
            rowExpandable: (record) => {
              return MDVPLANS.includes(record?.benifit?.props?.children ?? '')
                ? (addKey(record?.billingStatusSummaries ?? [])?.length ??
                    0) !== 0
                : (addKey(
                    record?.billingStatusSummaries[0]?.planSummaries ?? []
                  )?.length ?? 0) !== 0;
            },
          }}
          dataSource={addKey(previewData)}
        >
          <ColumnGroup
            title={
              <img
                src={benifitSummary?.carrierUrl ?? ''}
                className={styles.carrierImage}
              />
            }
          >
            <Column
              title={<div className={styles.carrierTitle}>Carrier/Benefit</div>}
              dataIndex="benifit"
              key="benifit"
              ellipsis={showEllipsis}
              width={isPrintable ? '19%' : '15.04%'}
              align="left"
            />
          </ColumnGroup>
          <ColumnGroup title="Current">
            <Column
              title="Count"
              dataIndex="count"
              key="count"
              ellipsis={showEllipsis}
              width={isPrintable ? '11.5%' : '12.28%'}
              align="right"
            />
            <Column
              title="Volume"
              dataIndex="totalCurrentVolume"
              key="totalCurrentVolume"
              ellipsis={showEllipsis}
              width={isPrintable ? '11.5%' : '12.28%'}
              align="right"
            />
            <Column
              title="Premium"
              dataIndex="totalCurrentPremium"
              key="totalCurrentPremium"
              ellipsis={showEllipsis}
              width={isPrintable ? '11.5%' : '12.28%'}
              align="right"
            />
          </ColumnGroup>
          <ColumnGroup title="RetroActivity">
            <Column
              title="Volume"
              dataIndex="totalRetroVolume"
              key="totalRetroVolume"
              ellipsis={showEllipsis}
              width={isPrintable ? '11.5%' : '12.28%'}
              align="right"
            />
            <Column
              title="Premium"
              dataIndex="totalRetroPremium"
              key="totalRetroPremium"
              ellipsis={showEllipsis}
              width={isPrintable ? '11.5%' : '12.28%'}
              align="right"
            />
          </ColumnGroup>
          <ColumnGroup title="Total">
            <Column
              title="Volume"
              dataIndex="totalVolume"
              key="totalVolume"
              width={isPrintable ? '11.5%' : '12.28%'}
              ellipsis={showEllipsis}
              align="right"
            />
            <Column
              title="Premium"
              dataIndex="totalPremium"
              key="totalPremium"
              width={isPrintable ? '11.5%' : '12.28%'}
              ellipsis={showEllipsis}
              align="right"
            />
          </ColumnGroup>
        </Table>
      </div>
    );
  }
);

export default BillingTableExpandable;
