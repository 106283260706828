import { FC } from 'react';

import { useAppSelector } from 'hooks/redux';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import styles from './modelingHelpPopup.module.less';

type MedicalPlanRecommenderPopupProps = {
  modalVisibility: boolean;
  setModalVisibility: (val: boolean) => void;
};

const ModelingHelpPopup: FC<MedicalPlanRecommenderPopupProps> = ({
  modalVisibility,
  setModalVisibility,
}: MedicalPlanRecommenderPopupProps) => {
  const { inProgress } = useAppSelector((state) => state.plan.plans);

  return (
    <>
      <ConfirmationDialog
        title={'Modeling Help and Info'}
        confirmText={''}
        cancelText={'Close'}
        visible={modalVisibility}
        centered={true}
        confirmLoading={inProgress}
        isCloseOnly={false}
        closable={true}
        buttonsExpanded={true}
        zIndex={10000}
        closeModal={() => setModalVisibility(!modalVisibility)}
        onConfirm={() => {}}
        isConfirmApplicable={false}
        width={768}
        closeBtnAdditionalStyling={styles.closeBtn}
      >
        <div className={styles.initalText}>
          <p className={styles.title}>Proportional</p>
          <p className={styles.paragraph}>
            This models a shared increase/decrease in premiums between the
            employer and the employees on a per plan basis. For example, if a
            plan’s rates increase by 5%, the employee and employer costs for
            that plan will both increase by about 5%.
          </p>
          <p className={styles.paragraph}>
            If the employer currently pays 100% of a plan and the rates
            increase, this model will not introduce a cost to the employee. The
            employer will pick up the additional cost.
          </p>

          <p className={styles.title}>Base Plan Buy Up</p>
          <p className={styles.paragraph}>
            This models the employer contributing an identical dollar amount
            across all plans based on the employer contributions in the
            designated “Base Plan”. Employees can “buy up” to other plans and
            will be responsible for the difference in cost.
          </p>

          <p className={styles.paragraph}>
            When the Base Plan’s contributions are changed in this modeling
            tool, the contributions change in the other plans to reflect an
            identical “ER Pays” subsidy. Changing the non-base plan
            contributions will not affect the other plans.
          </p>

          <p className={styles.title}>Pass through to ER</p>
          <p className={styles.paragraph}>
            This models the employer absorbing any premium increases/decreases
            to the plans. The employee contributions for each plan will remain
            the same as the current plan year regardless of any rate changes.
          </p>

          <p className={styles.title}>Pass through to EE</p>
          <p className={styles.paragraph}>
            This models the employee absorbing any premium increases/decreases
            to the plans. The employer contributions for each plan will remain
            the same as the current plan year regardless of any rate changes.
          </p>

          <p className={styles.title}>50/50 Split</p>
          <p className={styles.paragraph}>
            This approach involves an equal distribution of any changes
            (increases or decreases) in the total cost of the plan for the
            upcoming year. The adjustment in dollar terms is shared equally
            between the employer and employee contributions.
          </p>
        </div>
      </ConfirmationDialog>
    </>
  );
};

export default ModelingHelpPopup;
