// should be imported first so our styles are not overridden
import 'styles/antd.less';
import 'styles/typography.less';
import 'styles/defaults.less';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from 'routes';
import PrivateRoute from 'routes/PrivateRoute';
import LayoutWrapper from 'layout/LayoutWrapper';
import AppLayout from 'layout/AppLayout';
import authRoutes from 'modules/auth/routes';
import FloatingChatButton from 'modules/chatbot/components/FloatingButton/FloatingChatButton';
import MedicalPlansChannel from 'modules/clients/DBGClient/channels/MedicalPlansChannel';
import TelehealthPlansChannel from 'modules/clients/DBGClient/channels/TelehealthPlansChannel';
import WellbeingPlansChannel from 'modules/clients/DBGClient/channels/WellbeingPlansChannel';
import BenguideCommonChannel from 'modules/clients/DBGClient/channels/BenguideCommonChannel';
import DentalPlansChannel from 'modules/clients/DBGClient/channels/DentalPlansChannel';
import VisionPlansChannel from 'modules/clients/DBGClient/channels/VisionPlansChannel';
import HolidayAndimeOffPlansChannel from 'modules/clients/DBGClient/channels/HolidayAndTimeOffChannel';
import LifeAndDisabilityChannel from 'modules/clients/DBGClient/channels/LifeAndDisabilityChannel';
import FamilyAndLeaveChannel from 'modules/clients/DBGClient/channels/FamilyAndLeaveChannel';
import AdditionalPerksPlansChannel from 'modules/clients/DBGClient/channels/AdditionalPerksPlansChannel';
import VoluntaryBenefitsPlansChannel from 'modules/clients/DBGClient/channels/VoluntaryBenefitsPlansChannel';
import FourOOneKPlansChannel from 'modules/clients/DBGClient/channels/FourOOneKPlansChannel';
import TaxAdvantageChannel from 'modules/clients/DBGClient/channels/TaxAdvantagedAccountChannel';

import { NavigationContextProvider } from './context/NavigationContext';

function App() {
  return (
    <NavigationContextProvider>
      <BrowserRouter>
        <MedicalPlansChannel />
        <TelehealthPlansChannel />
        <BenguideCommonChannel />
        <WellbeingPlansChannel />
        <DentalPlansChannel />
        <VisionPlansChannel />
        <HolidayAndimeOffPlansChannel />
        <LifeAndDisabilityChannel />
        <FamilyAndLeaveChannel />
        <AdditionalPerksPlansChannel />
        <VoluntaryBenefitsPlansChannel />
        <FourOOneKPlansChannel />
        <TaxAdvantageChannel />
        <LayoutWrapper>
          <Routes>
            {authRoutes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              );
            })}
            <Route path="/" element={<AppLayout />}>
              {routes.map((route) => {
                return (
                  <Route
                    path={route.path}
                    element={
                      <PrivateRoute
                        allowedLoginTypes={route.allowedLogins}
                        validator={route.validator}
                        path={route.path}
                      >
                        {route.element}
                      </PrivateRoute>
                    }
                    key={route.path}
                  />
                );
              })}
            </Route>
          </Routes>
        </LayoutWrapper>
        <FloatingChatButton />
      </BrowserRouter>
    </NavigationContextProvider>
  );
}

export default App;
