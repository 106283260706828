import { FC } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { isEmpty } from 'lodash';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  RFP_HOVER_EXCLUDE,
  RFP_HOVER_INCLUDE,
} from 'modules/rfp/constants/RfpConstants';

import { setChunkBoolean } from 'modules/rfp/slices/rfpQuestionSlice';
import styles from './chunkArea.module.less';

type ChunkProps = {
  text: string;
  chunkId: string;
  chunkIdValues: string[];
  setChunkIdValues: any;
  contexts: any;
  questionNumber: number;
  isIncludedIn: boolean;
  documentId: string;
};

const ChunkArea: FC<ChunkProps> = (props: ChunkProps) => {
  const {
    text,
    chunkId,
    setChunkIdValues,
    chunkIdValues,
    contexts,
    questionNumber,
    isIncludedIn,
    documentId,
  } = props;

  const dispatch = useAppDispatch();
  const checkIdIsIncluded = chunkIdValues?.find((item) => item === chunkId);
  const trainData = useAppSelector((state) => state.rfp.trainData);

  const fileName = trainData?.find(
    (item: any) => item?.id === documentId
  )?.documentName;

  const chunkAreaClick = () => {
    const arr = [...chunkIdValues];

    if (!isIncludedIn) {
      const selectedContext = contexts.find(
        (obj: any) => obj?.chunkId === chunkId
      );
      arr.push(chunkId);
      setChunkIdValues(arr);

      dispatch(
        setChunkBoolean({
          index: questionNumber,
          value: true,
          selectedChunk: selectedContext,
        })
      );
    } else {
      const selectedContext = contexts.find(
        (obj: any) => obj?.chunkId === chunkId
      );

      const arr = chunkIdValues.filter((item) => item !== checkIdIsIncluded);
      setChunkIdValues(arr);
      dispatch(
        setChunkBoolean({
          index: questionNumber,
          value: false,
          selectedChunk: selectedContext,
        })
      );
    }
  };

  return (
    <div>
      {fileName ? (
        <>
          {fileName}
          <div className={styles.chunkWrapper}>
            <TextArea
              contentEditable={false}
              className={styles.responseField}
              value={text}
              disabled={!isIncludedIn}
            />
            {
              <div
                className={styles.includeText}
                onClick={() => chunkAreaClick()}
              >
                {!isIncludedIn ? 'Include' : 'Exclude'}

                {isEmpty(checkIdIsIncluded) ? (
                  <PlanyearPopover
                    placement={'left'}
                    content={RFP_HOVER_INCLUDE}
                    zIndex={9999}
                  >
                    <QuestionCircleOutlined
                      className={styles.questionIconChunk}
                    />
                  </PlanyearPopover>
                ) : (
                  <PlanyearPopover
                    placement={'left'}
                    content={RFP_HOVER_EXCLUDE}
                    zIndex={9999}
                  >
                    <QuestionCircleOutlined
                      className={styles.questionIconChunk}
                    />
                  </PlanyearPopover>
                )}
              </div>
            }
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ChunkArea;
