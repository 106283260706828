import {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Col, Form, Row, notification } from 'antd';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { isEqual } from 'lodash';
import { Plan } from 'model/plans/Plan';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import AlertMessage, { AlertInfo } from 'components/Alert/AlertMessage';
import PlanBasicForm from 'modules/plans/components/PlanBasicForm/PlanBasicForm';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { AdditionalPlan } from 'model/plans/AdditionalPlan';
import { useNavContext } from 'hooks/useNavContext';
import {
  PLAN_ADDITIONAL_DOCUMENT,
  VALIDATION_NAME_DUPLICATED,
} from 'modules/plans/constants';
import {
  BenefitCategory,
  benefitGroupsChangedMsg,
  EMPTY_MESSAGE,
  formVerificationMsg,
  planAlreadyExistsError,
  planYearChangedMsg,
  RENEWALS_STARTED_WARNING,
  VolBenefitBenefitKinds,
} from 'constants/commonConstants';
import { getCarriersByBenefitKind } from 'modules/plans/slices/basicPlanInfoSlice';
import {
  clearVolBenefitPlanApiErrors,
  findPlanById,
  saveVoluntaryBenefitPlan,
} from 'modules/plans/slices/voluntaryBenefitPlanSlice';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { useLazyGetUpcomingPlanYearsByEmployerQuery } from 'modules/renewals/slices/renewalsSlice';
import {
  ERROR_MESSAGE_PLAN_EDITING,
  PLAN_NOTIFICATION_KEY,
  RENEWAL_COMMON_WARNING_MESSAGE_BASIC_INFO,
} from 'constants/benguideCollaborationConstants';
import { benefitTypes } from 'modules/carriers/constants/carrierConstants';
import { IAddProposalToPlanYearState } from 'modules/renewals/types/renewalsTypes';

import AdditionalPlanResources, {
  InvalidField,
} from 'modules/plans/components/AdditionalPlanResources/AdditionalPlanResources';
import {
  removePlanDocuments,
  updateWeblinks,
  uploadPlanDocument,
  uploadRemovePlanDocument,
} from 'modules/plans/slices/planDocumentsSlice';
import FileType from 'model/plans/FileType';
import WebLinkType from 'model/plans/WebLinkType';
import styles from './addVoluntaryBenefitPlan.module.less';

export const VoluntaryBenefitPlanBasicInfoFormFields = {
  requiredFields: ['planName', 'planYear', 'benefitClass', 'benefitCarrier'],
  formFields: [
    'planName',
    'planYear',
    'benefitCarrier',
    'benefitClass',
    'file',
    'weblink',
    'groupId',
  ],
};
type AddVoluntaryBenefitPlanProps = {
  onClose: () => void;
  closePlanAddModal: () => void;
  isCloseConfirmed: boolean;
  benefitCategory: any;
  isEdit?: boolean;
  plan?: AdditionalPlan;
  isSaveDisabled?: boolean;
  dbgPlanYear?: string;
  isDBGPlan?: boolean;
  isModalVisible?: boolean;
  isReview?: boolean;
  isReviewHighlight?: boolean;
  isReviewType?: keyof IAddProposalToPlanYearState['plans'] | undefined;
  reviewNewPlanYear?: string;
  onFileChangeAction?: Function;
  onFileRemoveAction?: Function;
  documentReferences?: any;
  isUploading?: boolean;
  onPlanSave?: Function;
  reviewBenefitClasses?: string[];
  isRenewalProcessStarted?: boolean;
  actionTriggered?: Function;
};

const AddVoluntaryBenefitPlan = forwardRef(
  (props: AddVoluntaryBenefitPlanProps, ref) => {
    const {
      isCloseConfirmed,
      benefitCategory,
      onClose,
      closePlanAddModal,
      isEdit,
      plan,
      isSaveDisabled = false,
      dbgPlanYear,
      isReviewType,
      isDBGPlan,
      isModalVisible,
      isReview = false,
      onPlanSave,
      reviewNewPlanYear,
      reviewBenefitClasses,
      isReviewHighlight,
      isRenewalProcessStarted = false,
      actionTriggered,
    } = props;

    type formDataType = {
      planName: '';
      benefitCarrier: '';
      planYear: '';
      benefitClass: string[];
      weblink: '';
      fileName: '';
      groupId: '';
    };

    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { employerId, brokerId } = useNavContext();
    const { planYearsList } = useAppSelector(
      (state) => state.employer.employer
    );
    const basicPlanInfo = useAppSelector((state) => state.plan.planBasicInfo);
    const { error, inProgress } = useAppSelector(
      (state) => state.plan.voluntaryBenefitPlan
    );
    const { inProgress: documentUploadInProgress } = useAppSelector(
      (state) => state.plan.planDocuments
    );

    // Calling the plans array in redux state to get the previous plan array and comparing,
    // it with latest change to check duplicates
    const plans = useAppSelector(
      (state) => state.renewals.addProposalToPlanYear.plans
    );

    const { carriersList } = basicPlanInfo;

    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [file, setFile] = useState<any | null>(null);
    const [alertMessage, setAlertMessage] = useState<AlertInfo>({
      type: undefined,
      message: '',
    });
    const [formData, setFormData] = useState<formDataType>({
      planName: '',
      benefitCarrier: '',
      planYear: '',
      benefitClass: [],
      weblink: '',
      fileName: '',
      groupId: '',
    });
    useState<InvalidField>('');
    const [renwalWarningModalVisible, setRenewalWarningModalVisible] =
      useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const basicPlanInfoRef = useRef<any>();
    const additionalResourcesRef = useRef<any>();
    const [notificationKey] = useState(PLAN_NOTIFICATION_KEY);
    const [loading, setLoading] = useState<boolean>(false);
    const [showPlanYearWarning, setShowPlanYearWarning] =
      useState<boolean>(false);
    const [selectedFileList, setSelectedFileList] = useState<FileType[]>([]);
    const [isDocRemoved, setIsDocRemoved] = useState<{
      [key: string]: boolean;
    }>({});
    const [selectedWeblink, setSelectedWeblink] = useState<WebLinkType[]>([]);

    const [
      getUpcomingPlanYears,
      { isLoading: isUpcomingFetchLoading, data: upcomingData },
    ] = useLazyGetUpcomingPlanYearsByEmployerQuery();

    const isPlanYearInList = !!planYearsList.find(
      (planYear: any) => planYear.id === plan?.planYearId || dbgPlanYear
    )?.current;

    const hasUpcomingPlanYearWithNullId = upcomingData?.upcomingPlanYears?.some(
      (obj: any) => obj.planYearId === null
    );

    const isRenewalStarted = !isEdit
      ? isRenewalProcessStarted
      : isPlanYearInList && hasUpcomingPlanYearWithNullId;

    useImperativeHandle(ref, () => ({
      reset() {
        setSelectedFileList([]);
        setSelectedWeblink([]);
        form.resetFields();
        setFile(null);
      },
      isValidForm: async () => {
        await onClickSave();
      },
    }));

    useEffect(() => {
      if (brokerId && employerId && isModalVisible) {
        dispatch(
          getCarriersByBenefitKind(
            benefitTypes['VOLUNTARY_BENEFIT'].value,
            brokerId,
            employerId
          )
        );
      }
    }, [dispatch, brokerId, benefitCategory.value, employerId, isModalVisible]);

    useEffect(() => {
      if (error?.data?.code === VALIDATION_NAME_DUPLICATED) {
        form.setFields([{ name: 'planName', errors: [EMPTY_MESSAGE] }]);
        setAlertMessage({ message: planAlreadyExistsError, type: 'error' });
        setShowAlert(true);
        dispatch(clearVolBenefitPlanApiErrors());
        setLoading(false);
      }
    }, [dispatch, error, form]);

    useEffect(() => {
      if (isCloseConfirmed) {
        setShowAlert(false);
        setFile(null);
        basicPlanInfoRef.current?.resetForm();
      }
    }, [isCloseConfirmed, form, setShowAlert]);

    // Load plan data for edit mode
    useEffect(() => {
      if (isEdit && plan) {
        const {
          name,
          planYearId,
          benefitCarrier,
          groups,
          webLink,
          documents,
          groupId,
        } = plan;
        const attachedFileName = get(documents, 'PLAN_SUMMARY');

        const editPlanData = {
          planName: name,
          planYear: planYearId,
          benefitCarrier: benefitCarrier ? benefitCarrier.id : '',
          benefitClass: groups,
          weblink: webLink || null,
          fileName: attachedFileName,
          groupId: groupId,
        } as formDataType;

        setFormData(editPlanData);
        form.setFieldsValue({ ...editPlanData });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, plan, isEdit]);

    useEffect(() => {
      setShowAlert(false);
      setShowWarning(false);
      setShowPlanYearWarning(false);
      if (
        isEdit &&
        !isReview &&
        !isEqual(plan?.groups, formData.benefitClass)
      ) {
        setShowPlanYearWarning(false);
        setShowWarning(true);
        setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
        setShowAlert(true);
      }
      if (isEdit && !isEqual(plan?.planYearId, formData.planYear?.trim())) {
        setShowPlanYearWarning(true);
        setAlertMessage({ message: planYearChangedMsg, type: 'warning' });
        setShowAlert(true);
      }
      if (
        isEdit &&
        !isReview &&
        !isEqual(plan?.planYearId, formData.planYear?.trim()) &&
        !isEqual(plan?.groups, formData.benefitClass)
      ) {
        setShowPlanYearWarning(true);
        setShowWarning(true);
        setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
        setShowAlert(true);
      }
    }, [plan, formData.benefitClass, isEdit, formData.planYear, isReview]);

    const validateBasicInfo = async () => {
      let isFormValid = false;
      try {
        let validateFields;
        if (isReview) {
          validateFields =
            VoluntaryBenefitPlanBasicInfoFormFields.requiredFields.filter(
              (item) => item !== 'planYear'
            );
        } else {
          validateFields =
            VoluntaryBenefitPlanBasicInfoFormFields.requiredFields;
        }

        await form.validateFields(validateFields);
        isFormValid = true;
      } catch (errorInfo: any) {
        isFormValid = errorInfo.errorFields.length === 0;
      }

      if (isFormValid) {
        return true;
      } else {
        setShowAlert(true);
        return false;
      }
    };

    const closeOnSave = async (id: string) => {
      await uploadDocument(id);
    };

    const getData = () => {
      const selectedPlanYear = planYearsList.find(
        (t) => t.id === formData.planYear
      );
      const formValues = form.getFieldsValue();
      const { benefitCarrier } = formValues;
      const selectedBenefitCarrier = carriersList.find(
        (carrier) => carrier.id === benefitCarrier
      );

      const newPlan = {
        benefitKind: benefitCategory.value,
        employerId: employerId,
        planYearId: formData.planYear?.trim(),
        name: formData.planName,

        benefitCarrierId: benefitCarrier ? benefitCarrier.trim() : null,
        benefitCarrier: {
          id: benefitCarrier,
          name: selectedBenefitCarrier?.name ?? '',
          customCarrier: selectedBenefitCarrier?.customCarrier ?? false,
        },
        startDate: selectedPlanYear?.startDate,
        endDate: selectedPlanYear?.endDate,
        groups: formData.benefitClass,
        groupId: formData.groupId ? formData.groupId : '',
      } as AdditionalPlan;

      delete newPlan.additionalDocumentReferences;
      delete newPlan.additionalWeblinks;

      if (newPlan?.groupId) {
        newPlan.groupId = newPlan?.groupId.trim();
      }

      if (isEdit) {
        newPlan.id = plan?.id;
      }
      return newPlan;
    };

    const isDuplicate = (updated: Plan) => {
      const prevArr = plans[isReviewType!];
      const previousPlan = plan;

      if (previousPlan?.name !== updated?.name) {
        if (
          prevArr
            .filter(
              (plan) =>
                plan.planType === benefitCategory &&
                plan.planName != previousPlan?.name
            )
            .some((plan: any) => plan.planName === updated?.name)
        ) {
          form.setFields([{ name: 'planName', errors: [EMPTY_MESSAGE] }]);
          return true;
        }
      }
      return false;
    };

    const onClickSave = async () => {
      setRenewalWarningModalVisible(false);
      setShowAlert(false);
      const isFormValid = await validateBasicInfo();

      if (
        isEdit &&
        !isReview &&
        formData.benefitClass.filter((i) => plan?.groups.includes(i)).length !==
          formData.benefitClass.length
      ) {
        setAlertMessage({ message: benefitGroupsChangedMsg, type: 'warning' });
        setShowWarning(true);
        setShowAlert(true);
      }

      if (isFormValid) {
        if (isReview) {
          if (isDuplicate(getData())) {
            setShowAlert(true);
            setAlertMessage({
              message: planAlreadyExistsError,
              type: 'error',
            });
            throw error;
          }
          return onPlanSave?.({ ...getData(), file });
        }

        if (isEdit && isDBGPlan) {
          setLoading(true);
        }
        if (isRenewalStarted && !isEdit) {
          setRenewalWarningModalVisible(true);
        } else {
          dispatch(
            saveVoluntaryBenefitPlan(getData(), (id: string) => {
              closeOnSave(id);
            })
          );
          notification.close(notificationKey);
        }
      } else {
        setAlertMessage({ message: formVerificationMsg, type: 'error' });
        setShowAlert(true);
        if (isReview) {
          throw Error;
        }
      }
    };

    const onCancel = () => {
      closePlanAddModal();
    };

    useEffect(() => {
      if (employerId) {
        getUpcomingPlanYears({ employerId });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employerId, isEdit]);

    const uploadDocument = async (id: string) => {
      const uploadDocumentSuccess = () => {
        setSelectedFileList([]);
        setSelectedWeblink([]);
        setFile(null);
        setShowAlert(false);
        onClose();
        if (plan && plan.id) {
          dispatch(findPlanById(plan.id));
        }
      };

      const planId = id ?? (plan?.id as string);
      const removeDocs = Object.keys(isDocRemoved)
        .filter((documentType) => isDocRemoved[documentType])
        .map((documentType) => ({
          docType: PLAN_ADDITIONAL_DOCUMENT,
          planDocumentName: documentType,
        }));
      if (selectedWeblink.length > 0) {
        await dispatch(
          updateWeblinks(selectedWeblink, planId, benefitCategory.value)
        );
      }
      if (!isEmpty(selectedFileList) && isEmpty(removeDocs)) {
        await dispatch(
          uploadPlanDocument(selectedFileList, planId, benefitCategory.value)
        );
      } else if (!isEmpty(removeDocs) && isEmpty(selectedFileList)) {
        await dispatch(
          removePlanDocuments(planId, benefitCategory.value, removeDocs)
        );
      } else if (!isEmpty(removeDocs) && !isEmpty(selectedFileList)) {
        await dispatch(
          uploadRemovePlanDocument(
            selectedFileList,
            planId,
            benefitCategory.value,
            removeDocs
          )
        );
      }
      uploadDocumentSuccess();
    };

    return (
      <div
        className={`${
          isEdit ? styles.planEditModeWrapper : styles.wrapperForm
        }  `}
      >
        {showAlert && (
          <AlertMessage
            type={alertMessage.type}
            message={alertMessage.message}
            closeAlert={() => {
              setShowAlert(false);
            }}
            wrapperClassName={isEdit ? styles.alertWrapper : ''}
          />
        )}
        {isEdit && isRenewalStarted && (
          <FixedAlertMessage
            type={'warning'}
            message={RENEWAL_COMMON_WARNING_MESSAGE_BASIC_INFO}
          />
        )}
        {isSaveDisabled && !plan && (
          <FixedAlertMessage
            type={'error'}
            message={ERROR_MESSAGE_PLAN_EDITING}
          />
        )}

        <PlanBasicForm
          carriersList={carriersList}
          setRequiredFieldError={setShowAlert}
          setFormData={setFormData}
          formData={formData}
          benefitCategory={BenefitCategory.VOLUNTARY_BENEFIT.value}
          form={form}
          ref={basicPlanInfoRef}
          defaultPlanName={
            benefitCategory === VolBenefitBenefitKinds.CUSTOM_PLAN
              ? null
              : benefitCategory.label
          }
          isPlanUpdateMode={isEdit}
          showWarning={showWarning}
          dbgPlanYear={dbgPlanYear}
          isDBGPlan={isDBGPlan}
          isModalVisible={isModalVisible}
          showPlanYearWarning={showPlanYearWarning}
          reviewNewPlanYear={reviewNewPlanYear}
          reviewBenefitClasses={reviewBenefitClasses}
          isReviewHighlight={isReviewHighlight}
          isHightlightCarrier={isReviewHighlight}
        />
        <div className={styles.header}>Plan Resources</div>
        <AdditionalPlanResources
          ref={additionalResourcesRef}
          plan={plan}
          benefitKind={benefitCategory.value}
          isCloseConfirmed={isCloseConfirmed}
          selectedFileList={selectedFileList}
          setSelectedFileList={setSelectedFileList}
          selectedWebLinkList={selectedWeblink}
          setSelectedWebLinkList={setSelectedWeblink}
          setIsDocRemoved={setIsDocRemoved}
          actionTriggered={actionTriggered}
        />
        {!isReview && (
          <div className={styles.btnWrapper}>
            <SubmitButton
              disabled={isSaveDisabled}
              type="primary"
              className={isEdit ? styles.saveButtonWrapper : styles.saveButton}
              onClick={onClickSave}
              loading={
                inProgress ||
                loading ||
                isUpcomingFetchLoading ||
                documentUploadInProgress
              }
            >
              {isEdit ? 'Done' : 'Save'}
            </SubmitButton>
            {isEdit ? (
              <CancelButton
                onClick={() => onCancel()}
                withBorder={true}
                className={styles.cancelButtonWrapper}
              >
                Cancel
              </CancelButton>
            ) : (
              <CancelButton>
                <span
                  onClick={() => onCancel()}
                  className={styles.cancelButton}
                >
                  Cancel
                </span>
              </CancelButton>
            )}
          </div>
        )}
        {!isEdit && !isReview && (
          <>
            <ConfirmationDialog
              modalClassName={styles.deleteConfirmationModal}
              title={
                <Col span={24}>
                  <Row justify="center">Add Plan</Row>
                  <Row justify="center">
                    <span className={styles.subHeading}>
                      {formData?.planName ?? ''}
                    </span>
                  </Row>
                </Col>
              }
              confirmText={'Add Plan'}
              cancelText="Cancel"
              isCloseOnly={false}
              closeModal={() => {
                setRenewalWarningModalVisible(false);
              }}
              onConfirm={() => {
                dispatch(
                  saveVoluntaryBenefitPlan(getData(), (id: string) => {
                    closeOnSave(id);
                  })
                );
                notification.close(notificationKey);
                setRenewalWarningModalVisible(false);
              }}
              visible={renwalWarningModalVisible}
              confirmLoading={inProgress || loading || isUpcomingFetchLoading}
              centered
              destroyOnClose
            >
              <p>{RENEWALS_STARTED_WARNING}</p>
            </ConfirmationDialog>
          </>
        )}
      </div>
    );
  }
);
AddVoluntaryBenefitPlan.displayName = 'AddVoluntaryBenefitPlan';
export default AddVoluntaryBenefitPlan;
