import { FC } from 'react';

import AddAdditionalPerkPlan from 'modules/plans/additionalPerk/components/AddAdditionalPerkPlan/AddAdditionalPerkPlan';
import { AdditionalPlan } from 'model/plans/AdditionalPlan';

type EditAdditionalPerkPlanProps = {
  onClose: () => {};
  plan: AdditionalPlan;
  isSaveDisabled?: boolean;
  isDBGPlan?: boolean;
  isModalVisible?: boolean;
};

const EditAdditionalPerkPlan: FC<EditAdditionalPerkPlanProps> = ({
  onClose,
  plan,
  isSaveDisabled,
  isDBGPlan,
  isModalVisible,
}: EditAdditionalPerkPlanProps) => {
  return (
    <div>
      <AddAdditionalPerkPlan
        closePlanAddModal={onClose}
        isCloseConfirmed={true}
        onClose={onClose}
        isPlanUpdateMode={true}
        plan={plan}
        isSaveDisabled={isSaveDisabled}
        isDBGPlan={isDBGPlan}
        isModalVisible={isModalVisible}
      />
    </div>
  );
};

export default EditAdditionalPerkPlan;
