import { useState } from 'react';

import { Input } from 'antd';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import styles from 'modules/renewals/components/AddTargetAnnualPremiumModal/AddTargetAnnualPremiumModal.module.less';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const AddTargetAnnualPremiumModal = ({ isOpen, setIsOpen }: Props) => {
  const [targetAnnualPremium, setTargetAnnualPremium] = useState<string>('');

  const handleConfirm = () => {
    // TODO: Send API Request to update target annual premium here
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <ConfirmationDialog
      visible={isOpen}
      title="Add Target Annual Premium"
      confirmText="Save"
      cancelText="Cancel"
      onConfirm={handleConfirm}
      closeModal={handleCancel}
      confirmBtnFullWidth
      isCancelLink
    >
      <div className={styles.inputLabel}>Target Annual Premium *</div>
      <Input
        prefix="$"
        size="large"
        value={targetAnnualPremium}
        onChange={(e) => setTargetAnnualPremium(e.target.value)}
      />
    </ConfirmationDialog>
  );
};

export default AddTargetAnnualPremiumModal;
