import { FC } from 'react';

import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';

import { checkboxGroupMode } from 'constants/commonConstants';

import styles from './checkboxSelectGroup.module.less';

const CheckboxGroup = Checkbox.Group;

interface CheckboxSelectGroupProps extends CheckboxGroupProps {
  mode?: 'vertical' | 'horizontal' | 'inline';
}

const CheckboxSelectGroup: FC<CheckboxSelectGroupProps> = (
  props: CheckboxSelectGroupProps
) => {
  const { options, value, onChange, mode, ...rest } = props;
  return (
    <div
      className={
        mode === checkboxGroupMode.VERTICAL
          ? styles.verticalCheckboxContainer
          : styles.checkboxContainer
      }
    >
      <CheckboxGroup
        options={options}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};

export default CheckboxSelectGroup;
