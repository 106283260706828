import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import PolicyNumberAndCarrierContactContent from 'modules/employers/components/PolicyNumbersAndCarrierContactModal/PolicyNumberAndCarrierContactTable/PolicyNumberAndCarrierContactContent';
import { useLazyGetCarrierPlanListByEmployerIdQuery } from 'modules/employers/slices/policyNumberCarrierContactSlice';

import styles from './policyNumbersAndCarrierContactModal.module.less';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

const PolicyNumbersAndCarrierContactModal = (props: Props) => {
  const { isModalOpen, setIsModalOpen } = props;
  const { employerId } = useParams();
  const [
    getCarrierPlanListByEmployerId,
    { data: planListData, isFetching: isPlanListFetching },
  ] = useLazyGetCarrierPlanListByEmployerIdQuery();

  useEffect(() => {
    if (isModalOpen) {
      getCarrierPlanListByEmployerId({ employerId: employerId! });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  return (
    <FullScreenModal
      visible={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={false}
      title={
        <div className={styles.titleWrapper}>
          <div>Policy Numbers & Carrier Contacts</div>
          <div className={styles.textSecondary}>
            {planListData?.currentPlanYear?.name || ''}
          </div>
        </div>
      }
      destroyOnClose={true}
    >
      <PolicyNumberAndCarrierContactContent
        planListData={planListData?.plans || {}}
        isPlanListLoading={isPlanListFetching}
        fetchTableData={() => {
          getCarrierPlanListByEmployerId({ employerId: employerId! });
        }}
      />
    </FullScreenModal>
  );
};

export default PolicyNumbersAndCarrierContactModal;
