import { Dispatch } from '@reduxjs/toolkit';
import { NEW_CHAT_NAV_CONSTANT } from 'modules/assistant/constants/constants';
import {
  resetConversationHistory,
  setConversationId,
  setSelectionType,
} from 'modules/assistant/slices/conversationSlice';
import RenderSelectionType from 'modules/assistant/enums/RenderSelectionType';

export const createNewChat =
  (navigate: any, brokerId: string) => (dispatch: Dispatch) => {
    dispatch(resetConversationHistory());
    dispatch(setConversationId(NEW_CHAT_NAV_CONSTANT));
    dispatch(setSelectionType(RenderSelectionType.SELECTION));
    navigate(`/brokers/${brokerId}/assistant/${NEW_CHAT_NAV_CONSTANT}`);
  };

/**
 * Utility function to merge CSS classes
 *
 * @param {string} classes - The tier name (e.g., "TIER_1").
 * @return {string []} - A single string with all class names merged
 */
export const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(' ');
};
