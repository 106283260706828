import { FC, useEffect, useState, useCallback } from 'react';
import { useSubscription } from 'react-stomp-hooks';
import { Button, notification } from 'antd';
import { InfoCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import { DELETE, GENERAL_UPDATE } from 'constants/notificationState';
import {
  GENERAL_UPDATE_TITLE,
  GENERAL_UPDATE_MESSAGE,
  DELETE_TITLE,
  DELETE_MESSAGE,
} from 'constants/planCollaborationConstants';
import { PLAN_NOTIFICATION_KEY } from 'constants/benguideCollaborationConstants';

import styles from './planCollaborationNotification.module.less';

interface PlanCollaborationNotificationProps {
  planId?: string | null;
  loggedInUserIndividualId?: string;
  exitPlan: () => void;
  refreshPlan: () => void;
  isInEdit?: boolean;
  disableEdit: () => void;
  fetchPlan: () => void;
}

const PlanCollaborationNotification: FC<PlanCollaborationNotificationProps> = (
  props: PlanCollaborationNotificationProps
) => {
  const params = useParams();
  const [lastMessage, setLastMessage] = useState<any>({});
  const [notificationKey] = useState(PLAN_NOTIFICATION_KEY);
  const [showOverlay, setShowOverlay] = useState(false);

  useSubscription(`/topic/plans/${params.planId}`, (message) =>
    setLastMessage(JSON.parse(message.body))
  );

  const refreshPlan = useCallback(() => {
    notification.close(notificationKey);
    props.refreshPlan();
    setShowOverlay(false);
  }, [notificationKey, props]);

  const exitPlan = useCallback(() => {
    notification.close(notificationKey);
    props.exitPlan();
    setShowOverlay(false);
  }, [notificationKey, props]);

  const refreshButton = useCallback(() => {
    return (
      <Button type="link" size="small" onClick={() => refreshPlan()}>
        Refresh to update
      </Button>
    );
  }, [refreshPlan]);

  const exitButton = useCallback(() => {
    return (
      <Button type="link" size="small" onClick={() => exitPlan()}>
        Exit Plan
      </Button>
    );
  }, [exitPlan]);

  const openNotification = useCallback(
    (updateMessage: any, title: string, message: string, event: string) => {
      notification.open({
        message: title,
        description: `${updateMessage.firstName} ${updateMessage.lastName} 
                         (${updateMessage.email}) ${message}`,
        btn: event === DELETE ? exitButton() : refreshButton(),
        placement: 'bottomLeft',
        duration: null,
        className: styles.notification,
        closeIcon: <></>,
        key: notificationKey,
        icon:
          event === DELETE ? (
            <CloseOutlined className={styles.exitIcon} size={20} />
          ) : (
            <InfoCircleOutlined size={20} className={styles.refreshIcon} />
          ),
      });
    },
    [notificationKey, refreshButton, exitButton]
  );

  useEffect(() => {
    if (lastMessage && params.planId && lastMessage.planId === params.planId) {
      if (props.loggedInUserIndividualId !== lastMessage.individualId) {
        switch (lastMessage.status) {
          case GENERAL_UPDATE:
            openNotification(
              lastMessage,
              GENERAL_UPDATE_TITLE,
              GENERAL_UPDATE_MESSAGE,
              GENERAL_UPDATE
            );
            props.fetchPlan();
            break;
          case DELETE:
            openNotification(lastMessage, DELETE_TITLE, DELETE_MESSAGE, DELETE);
            break;
        }
        setLastMessage('');
        if (props.isInEdit && lastMessage.status === DELETE) {
          setShowOverlay(true);
        } else if (lastMessage.status === DELETE) {
          props.disableEdit();
        }
      }
    }
  }, [
    props.loggedInUserIndividualId,
    lastMessage,
    props.planId,
    refreshButton,
    notificationKey,
    openNotification,
    params.planId,
    props.isInEdit,
    props.disableEdit,
    props,
  ]);

  return <>{showOverlay && <div className={styles.notificationOverlay} />}</>;
};

export default PlanCollaborationNotification;
