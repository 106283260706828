import React, { ChangeEvent, FC } from 'react';
import { Row, Input } from 'antd';
import NumberFormatInput, {
  preventMoreThanHundredForPercentages,
} from 'components/FormInput/NumberFormatInput';
import FundingType from 'modules/plans/enums/FundingType';
import {
  EDIT_PLAN_TABLE,
  NON_MDV_REQUIRED_FIELDS,
  TIME_PERIOD_VALIDATION,
  BASIC_ADD_RATE,
  BASIC_LIFE_RATE,
  NO_VALIDATION_FIELDS,
} from 'modules/renewals/constants/renewalsConstants';
import { findInArray } from 'modules/renewals/utils/addPlanDetailUtil';
import { ReactComponent as AIFeedbackRedFlag } from 'assets/images/ai-red-flag.svg';
import styles from 'modules/renewals/components/AddPlanDetailsModal/AddPlanForm/addPlanForm.module.less';

type NonMDVInputsProps = {
  benefitType: string;
  updateObject: Function;
  selectedData?: any;
  item: string;
  type: string;
  showRequired?: boolean;
  showValidationHighlight?: boolean;
  triggerPlanNameValidation?: boolean;
  triggerAllValidations?: boolean;
  disableADDRate?: boolean;
  disableLifeRate?: boolean;
  fundingType?: any;
  showErrorValidation: any;
  validate: any;
  isQuotePlansApproved?: boolean;
};

const NonMDVInputs: FC<NonMDVInputsProps> = (props: NonMDVInputsProps) => {
  const {
    benefitType,
    updateObject,
    selectedData,
    item,
    type,
    showRequired,
    showValidationHighlight,
    triggerPlanNameValidation,
    triggerAllValidations,
    disableADDRate,
    disableLifeRate,
    fundingType,
    showErrorValidation,
    validate,
    isQuotePlansApproved,
  } = props;

  let benefitCategoryType = benefitType.toUpperCase();
  if (benefitType === 'STD') {
    benefitCategoryType =
      fundingType === FundingType.SELF_FUNDED
        ? 'STD_SELF_FUNDED'
        : 'STD_FULLY_INSURED';
  }
  const field = EDIT_PLAN_TABLE?.[benefitCategoryType]?.[type]?.[item];
  const fieldValue = field?.isObj
    ? selectedData?.[field?.property]?.formattedValue ??
      selectedData?.[field?.property]?.value
    : findInArray(
        selectedData?.[field?.property],
        field?.name,
        field?.fieldType,
        field?.fieldSubType
      )?.formattedValue ??
      findInArray(
        selectedData?.[field?.property],
        field?.name,
        field?.fieldType,
        field?.fieldSubType
      )?.value ??
      '';

  const llmObj = field?.isObj
    ? selectedData?.llmExtractionInfo?.[field?.property]
    : findInArray(
        selectedData?.llmExtractionInfo?.[field?.property],
        field?.name,
        field?.fieldType,
        field?.fieldSubType
      );

  const showError: boolean =
    NON_MDV_REQUIRED_FIELDS?.includes(field?.property) &&
    validate(field?.property, fieldValue) &&
    (showValidationHighlight || triggerPlanNameValidation) &&
    (!triggerAllValidations ?? false);

  const getSuffix = () => {
    if (field?.validations?.percentage) {
      return ' %';
    } else if (field?.validations?.timePeriod === TIME_PERIOD_VALIDATION.DAYS) {
      return ' days';
    } else if (
      field?.validations?.timePeriod === TIME_PERIOD_VALIDATION.WEEKS
    ) {
      return ' weeks';
    }
    return '';
  };

  return (
    <Row justify={'space-between'} className={styles.subFieldItems}>
      <div
        className={
          showErrorValidation(showError, field?.property)
            ? styles.errorText
            : ''
        }
      >
        {showRequired ? item : item.replace('*', '')}
      </div>
      <div>
        {field?.validations?.number ? (
          <div
            className={`${
              !isQuotePlansApproved && llmObj?.different
                ? styles.llmDifferentWrapper
                : ''
            } ${
              showErrorValidation(showError, field?.property)
                ? styles.inputError
                : ''
            }`}
          >
            {NO_VALIDATION_FIELDS[benefitType.toUpperCase()]?.includes(item) ? (
              <Input />
            ) : (
              <NumberFormatInput
                prefix={field?.validations?.currency && '$ '}
                suffix={getSuffix()}
                placeholder={getSuffix()}
                thousandSeparator={field?.validations?.currency ? true : false}
                allowNegative={false}
                decimalScale={field?.validations?.decimalScale}
                fixedDecimalScale={true}
                isAllowed={
                  field?.validations?.percentage &&
                  preventMoreThanHundredForPercentages
                }
                onChange={(event: any) => {
                  updateObject(
                    field?.property,
                    event.target.value,
                    !field?.isObj,
                    {
                      fieldSubType: field?.fieldSubType,
                      fieldType: field?.fieldType,
                      name: field?.name,
                    },
                    field?.isObj,
                    field?.validations
                  );
                }}
                disabled={
                  field?.property === BASIC_ADD_RATE
                    ? disableADDRate
                    : field?.property === BASIC_LIFE_RATE
                    ? disableLifeRate
                    : false
                }
                value={(fieldValue ?? '') === 'null' ? '' : fieldValue ?? ''}
                className={`${styles.numberInput} ${
                  !isQuotePlansApproved && llmObj?.different
                    ? styles.llmDifferentNumberField
                    : ''
                } ${field?.validations?.currency && styles.currency}`}
              />
            )}
            {!isQuotePlansApproved && llmObj?.different && (
              <AIFeedbackRedFlag className={styles.aiFeedbackFlag} />
            )}
          </div>
        ) : (
          <div
            className={`${
              !isQuotePlansApproved && llmObj?.different
                ? styles.llmDifferentWrapper
                : ''
            } ${showError ? styles.inputErrorEnrollments : ''}`}
          >
            <Input
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                updateObject(
                  field?.property,
                  e.target.value,
                  !field?.isObj,
                  {
                    fieldSubType: field?.fieldSubType,
                    fieldType: field?.fieldType,
                    name: field?.name,
                  },
                  field?.isObj
                )
              }
              value={(fieldValue ?? '') === 'null' ? '' : fieldValue ?? ''}
            />
            {!isQuotePlansApproved && llmObj?.different && (
              <AIFeedbackRedFlag className={styles.aiFeedbackFlag} />
            )}
          </div>
        )}
      </div>
    </Row>
  );
};

export default NonMDVInputs;
