import { FC, ReactNode } from 'react';
import { Collapse, Divider } from 'antd';
import styles from './expandable.module.less';
const { Panel } = Collapse;

type ExpandableProps = {
  icon?: ReactNode;
  header: ReactNode;
  actions?: ReactNode;
  isExpanded?: boolean;
  onChange?: (expanded: boolean) => void;
  dataCy?: string;
};

const key = 'key';

const Expandable: FC<ExpandableProps> = (props) => {
  const { children, actions, isExpanded, header, onChange, icon, dataCy } =
    props;

  const onCollapseChange = (expanded: string | string[]) => {
    onChange && onChange(expanded.includes(key));
  };

  const headerPanel = (
    <div className="header-wrapper">
      <div className="header">
        {!isExpanded ? <div className={styles.iconBtn}>{icon}</div> : null}
        <span
          className={!isExpanded ? 'text-info' : 'text-gray'}
          data-cy={dataCy}
        >
          {header}
        </span>
      </div>
      <Divider />
    </div>
  );

  return (
    <div>
      <Collapse
        className={styles.expandableWrapper}
        activeKey={isExpanded ? [key] : []}
        defaultActiveKey={isExpanded ? [key] : []}
        onChange={onCollapseChange}
        ghost
      >
        <Panel
          collapsible={!isExpanded ? 'header' : 'disabled'}
          header={headerPanel}
          showArrow={false}
          key={key}
        >
          <div>{children}</div>
          <div className="actions-panel">{actions}</div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default Expandable;
