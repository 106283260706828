import React, { FC } from 'react';
import { FundingTypeTag } from 'components/StdFundingTypeTag/FundingTypeTag';
import styles from './RenewalCommonMainHeader.module.less';

type IProps = {
  mainText: React.ReactNode;
  showFundingTypeLabel?: boolean;
  isProposalCreateSelectOfferCard?: boolean;
  fundingType?: string;
};

const RenewalCommonMainHeader: FC<IProps> = ({
  mainText,
  showFundingTypeLabel: isStdOfferCard,
  isProposalCreateSelectOfferCard,
  fundingType,
}) => {
  return (
    <p className={styles.mainHeader}>
      {mainText}
      <div className={styles.fundingType}>
        {isStdOfferCard && isProposalCreateSelectOfferCard && (
          <FundingTypeTag fundingType={fundingType} />
        )}
      </div>
    </p>
  );
};

export default RenewalCommonMainHeader;
