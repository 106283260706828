import { useEffect, useState, useCallback, useRef } from 'react';
import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { useAppSelector } from 'hooks/redux';
import {
  useCreateUpcomingPlanYearMutation,
  useLazyGetCurrentPlanEnrollmentsQuery,
  useLazyGetLatestPlanYearQuery,
  useLazyGetUpcomingPlanYearsByEmployerQuery,
  useSubmitCurrentPlansMutation,
} from 'modules/renewals/slices/renewalsSlice';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import Stepper from 'components/Stepper/Stepper';
import { getPlansForEmployerByPlanYear } from 'modules/plans/services/PlanService';
import { BenefitPlans } from 'modules/renewals/models/CurrentSystemPlansRequestDto';
import { isVolLifePlan } from 'modules/renewals/utils/addToPlanYearUtil';
import EffectiveDates from 'modules/renewals/components/StartNewRenewalModal/components/EffectiveDates/EffectiveDates';
import CurrentPlanInfo from 'modules/renewals/components/StartNewRenewalModal/components/CurrentPlanInfo/CurrentPlanInfo';
import {
  CLOSE_POPUP_TITLE_TEXT,
  CLOSE_POPUP_CONFIRM_TEXT,
  CLOSE_POPUP_CANCEL_TEXT,
  exitWithoutSavingMsg,
} from 'constants/commonConstants';

import UpcomingPlanYearDto from 'model/UpcomingPlanYearDto';

import {
  mapAndSort,
  benefitSpecificMapAndSort,
} from 'modules/renewals/utils/startRenewalUtils';
import {
  OFFER_BENEFIT_TYPES,
  PAYLOAD_PLAN_TIER_TYPES,
  TIER_TYPES,
} from 'modules/renewals/constants/renewalsConstants';
import styles from 'modules/renewals/pages/offers/CarierOffers/carrierOffer.module.less';
import UpcomingPlanYearBasicDto from 'model/UpcomingPlanYearBasicDto';

type Props = {
  employerId: string;
  isOpen: boolean;
  onCancel: Function;
  onSubmit: Function;
};

export interface Plan {
  masterId: string;
  name: string;
  isVoluntary?: boolean;
  [key: string]: any;
  isPlanAlreadyCreated?: boolean;
  fundingType?: string;
}

export interface PlansState {
  MEDICAL: Plan[];
  DENTAL: Plan[];
  VISION: Plan[];
  LIFE: Plan[];
  VOLUNTARY_BENEFIT: Plan[];
}

const StartNewRenewalModal = (props: Props) => {
  const { isOpen, onCancel, onSubmit, employerId } = props;

  const navigate = useNavigate();

  const employerObj: any = useAppSelector((state) => state?.layout?.employer);

  const [effectiveDatesForm] = Form.useForm();

  const [renewalDate, setRenewalDate] = useState<any>(
    dayjs(employerObj?.renewalDate)
  );
  const [medicalPremiumType, setMedicalPremiumType] = useState<string>('');
  const [dentalPremiumType, setDentalPremiumType] = useState<string>('');
  const [visionPremiumType, setVisionPremiumType] = useState<string>('');

  const [plans, setPlans] = useState<PlansState>({
    MEDICAL: [],
    DENTAL: [],
    VISION: [],
    LIFE: [],
    VOLUNTARY_BENEFIT: [],
  });
  const effectiveDatesRef = useRef<any>(null);
  const [isConfirmationModalOpen, setIsConfirmationModelOpen] =
    useState<boolean>(false);
  const [upcomingPlanYear, setUpcomingPlanYear] =
    useState<UpcomingPlanYearDto | null>(null);
  const [effectiveDatesHistory, setEffectiveDatesHistory] =
    useState<UpcomingPlanYearBasicDto[]>();
  const [currentStep, setCurrentStep] = useState<number>(0);

  const [getUpcomingPlanYears, { isLoading: isGetUpcomingPlanYearsLoading }] =
    useLazyGetUpcomingPlanYearsByEmployerQuery();
  const [
    getCurrentPlanEnrollments,
    { isLoading: isGetCurrentPlanEnrollmentsLoading },
  ] = useLazyGetCurrentPlanEnrollmentsQuery();

  const [
    createUpcomingPlanYear,
    { isLoading: isCreateUpcomingPlanYearLoading },
  ] = useCreateUpcomingPlanYearMutation();
  const [submitCurrentPlans, { isLoading: isSubmitCurrentPlansLoading }] =
    useSubmitCurrentPlansMutation();

  const [
    fetchLatestPlanYear,
    { data: latestPlanYear, isSuccess: latestPlanYearFetchSuccess },
  ] = useLazyGetLatestPlanYearQuery();
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(false);

  const isLoading =
    isGetCurrentPlanEnrollmentsLoading ||
    isGetUpcomingPlanYearsLoading ||
    isCreateUpcomingPlanYearLoading ||
    isSubmitCurrentPlansLoading;
  /** Runs every time the modal is opened. */
  useEffect(() => {
    if (isOpen) {
      getUpcomingPlanYears({ employerId });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && employerId) {
      fetchLatestPlanYear({ employerId });
      setRenewalDate(dayjs(employerObj?.renewalDate));
      fetchPlans(employerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerId, employerObj, isOpen]);

  useEffect(() => {
    if (latestPlanYearFetchSuccess) {
      setUpcomingPlanYear(latestPlanYear);
    }
  }, [latestPlanYearFetchSuccess, latestPlanYear]);

  const createBenefitPlansMDV = (plan: any, benefitClassList: any[]) => {
    const createdBenefitPlans = plan.benefitClassIds.map(
      (benefitClassId: any) => {
        const benefitClass = benefitClassList?.find(
          (x) => x?.id === benefitClassId
        )?.name;

        return {
          rates: plan.rates,
          planName: plan.name,
          benefitClassName: benefitClass,
          benefitClassExclusivePlanName: `${plan.name} - ${benefitClass}`,
          benefitClassId: benefitClassId,
          masterId: plan.masterId,
          fundingType: plan.fundingType,
        };
      }
    );

    return createdBenefitPlans;
  };

  const returnBenefitPlansArray = (
    planList: any[],
    benefitClassesList: any[]
  ) => {
    let benefitSpecificPlanList: any[] = [];

    planList.forEach((x) => {
      const benefitSpecificPlans = createBenefitPlansMDV(
        x.plan,
        benefitClassesList
      );

      benefitSpecificPlanList =
        benefitSpecificPlanList.concat(benefitSpecificPlans);
    });
    return benefitSpecificPlanList;
  };

  const fetchPlans = useCallback(
    async (employerId: string) => {
      try {
        const response = await getPlansForEmployerByPlanYear(employerId);
        const enrollmentResponse = await getCurrentPlanEnrollments({
          employerId,
        });

        const benefitClasses = response.data?.benefitClasses;
        const isMultipleEmpBenClass = response?.data?.benefitClasses.length > 1;
        const sortedPlans = {
          MEDICAL: benefitSpecificMapAndSort(
            returnBenefitPlansArray(response.data?.medical, benefitClasses),

            isMultipleEmpBenClass
          ),
          DENTAL: benefitSpecificMapAndSort(
            returnBenefitPlansArray(response.data?.dental, benefitClasses),

            isMultipleEmpBenClass
          ),
          VISION: benefitSpecificMapAndSort(
            returnBenefitPlansArray(response?.data?.vision, benefitClasses),

            isMultipleEmpBenClass
          ),
          LIFE: response.data.lifeInsurancePlans
            .map((item: any) => {
              return {
                masterId: item.plan.masterId,
                name: item.plan.name,
                volume: item?.plan?.volume || '',
                employees: item?.plan?.enrollment || '',
                administrationFee: item?.plan?.administrationFee || '',
                isVoluntary: isVolLifePlan(item.plan.benefitKind),
                fundingType: item.plan.fundingType,
              };
            })
            .sort((a: any, b: any) => a.name.localeCompare(b.name)),
          VOLUNTARY_BENEFIT: mapAndSort(
            response.data.voluntaryBenefitPlans,
            enrollmentResponse?.data?.benefitPlans?.VOLUNTARY_BENEFIT!
          ),
        };

        setPlans(sortedPlans);

        if ((sortedPlans?.MEDICAL?.length ?? 0) === 0) {
          setMedicalPremiumType(
            enrollmentResponse?.data?.medicalCurrentPlanType ?? ''
          );
        }
        if ((sortedPlans?.DENTAL?.length ?? 0) === 0) {
          setDentalPremiumType(
            enrollmentResponse?.data?.dentalCurrentPlanType ?? ''
          );
        }
        if ((sortedPlans?.VISION?.length ?? 0) === 0) {
          setVisionPremiumType(
            enrollmentResponse?.data?.visionCurrentPlanType ?? ''
          );
        }
      } catch (error) {
        setNextButtonDisabled(true);
        console.error('Error fetching plans:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleAttemptCloseModal = () => {
    setIsConfirmationModelOpen(true);
  };

  const handleCancelCloseModal = () => {
    setIsConfirmationModelOpen(false);
  };

  const mapMDV = (planList: any[]) => {
    const mappedPlansArray = planList.map((planInfo) => {
      if (planInfo.tierType === TIER_TYPES.N_TIER) {
        return {
          benefitSpecificPlanName: planInfo.name,
          tierType: PAYLOAD_PLAN_TIER_TYPES.N_TIER,
          masterId: planInfo.masterId,
          nTierEnrollments: planInfo.nTierEnrollments,
          benefitClassId: planInfo.benefitClassId,
          benefitClassName: planInfo.benefitClassName,
        };
      } else if (planInfo.tierType === TIER_TYPES.STANDARD_TIER) {
        return {
          benefitSpecificPlanName: planInfo.name,
          tierType: PAYLOAD_PLAN_TIER_TYPES.STANDARD_TIER,
          masterId: planInfo.masterId,
          nTierEnrollments: planInfo.nTierEnrollments,
          benefitClassId: planInfo.benefitClassId,
          benefitClassName: planInfo.benefitClassName,
          ['EE TIER']: planInfo.fourTier[0],
          ['ES TIER']: planInfo.fourTier[1],
          ['EC TIER']: planInfo.fourTier[2],
          ['EF TIER']: planInfo.fourTier[3],
        };
      }
    });
    return mappedPlansArray;
  };

  const mapLife = (planlist: any[]) => {
    return planlist?.map((plan) => {
      return {
        masterId: plan.masterId,
        volume: plan.volume,
        employees: plan.employees,
        administrationFee: plan.administrationFee,
      };
    });
  };
  // function to handle onclick of no renewals
  const toggleRenewalDateModalVisibility = (toggleVisibility = true) => {
    if (isOpen) {
      effectiveDatesForm.resetFields();
      setRenewalDate(dayjs(employerObj?.renewalDate));
      navigate(location.pathname, { replace: true });
    }
    setIsConfirmationModelOpen(false);
    setNextButtonDisabled(false);
    resetErrorFields();

    if (toggleVisibility) {
      onCancel(!!isOpen);
      setCurrentStep(0);
    }
  };

  const resetErrorFields = () => {
    const dateValues = effectiveDatesForm.getFieldsValue();
    effectiveDatesForm.setFields(
      Object.keys(dateValues).map((key) => ({ name: key, errors: [] }))
    );
  };

  const handleSetCurrentStep = (step: number) => {
    if (step === 1) {
      if (latestPlanYear?.current) {
        effectiveDatesRef?.current?.handleSubmitEffectiveDates();
        return;
      } else return;
    }
    setCurrentStep(step);
  };

  /**
   * Handles all logic that runs when submit/next button is clicked
   * @param {Object} options options object
   */
  const handleNextStep = async (
    options: {
      step: number;
      hasCurrentPlans?: boolean;
    } = { step: 0 }
  ) => {
    const { step } = options;

    // Only runs if this is not the final step.
    if (step !== 1) return setCurrentStep(step + 1);

    if (!upcomingPlanYear) return;

    await createUpcomingPlanYear({ upcomingPlanYear }).unwrap();
    await submitCurrentPlans({
      employerId,
      medicalCurrentPlanType: medicalPremiumType,
      dentalCurrentPlanType: dentalPremiumType,
      visionCurrentPlanType: visionPremiumType,
      ...transformData(plans),
    }).unwrap();
    onSubmit();
    onCancel();
  };

  const transformData = (state: PlansState): { benefitPlans: BenefitPlans } => {
    const benefitPlans: any = {
      MEDICAL: mapMDV(plans.MEDICAL),
      DENTAL: mapMDV(plans.DENTAL),
      VISION: mapMDV(plans.VISION),
      LIFE: mapLife(plans.LIFE),
      VOLUNTARY_BENEFIT: [...state.VOLUNTARY_BENEFIT],
    };
    return { benefitPlans };
  };

  const getPremiumTypeSelectedValues = () => {
    return {
      [OFFER_BENEFIT_TYPES.MEDICAL]: medicalPremiumType,
      [OFFER_BENEFIT_TYPES.DENTAL]: dentalPremiumType,
      [OFFER_BENEFIT_TYPES.VISION]: visionPremiumType,
    };
  };

  const isPlansEmpty = Object.values(plans ?? []).every(
    (planArr) => planArr.length === 0
  );

  const getTitle = () => {
    if (isPlansEmpty) {
      return 'Start New Plan Year';
    } else {
      return 'Start New Renewal';
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <EffectiveDates
            ref={effectiveDatesRef}
            form={effectiveDatesForm}
            initialData={effectiveDatesHistory}
            plans={plans}
            isLoading={isLoading}
            employerId={employerId!}
            onNextStep={(options) => handleNextStep({ ...options, step: 0 })}
            onCancel={handleAttemptCloseModal}
            renewalDate={renewalDate}
            setUpcomingPlanYear={setUpcomingPlanYear}
            setEffectiveDatesHistory={setEffectiveDatesHistory}
            nextButtonDisabled={nextButtonDisabled}
          />
        );
      case 1:
        return (
          <CurrentPlanInfo
            plans={plans}
            isLoading={isLoading}
            setPlans={(plansState: PlansState) => setPlans(plansState)}
            onNextStep={(options) => handleNextStep({ ...options, step: 1 })}
            onCancel={handleAttemptCloseModal}
            changeMedicalPremiumType={setMedicalPremiumType}
            changeDentalPremiumType={setDentalPremiumType}
            changeVisionPremiumType={setVisionPremiumType}
            selectedPremiumTypes={getPremiumTypeSelectedValues()}
          />
        );
      default:
        return;
    }
  };

  return (
    <>
      <FullScreenModal
        visible={isOpen}
        footer={false}
        title={getTitle()}
        onCancel={() => handleAttemptCloseModal()}
        destroyOnClose
      >
        <div className={styles.stepWrapper}>
          <Stepper
            headers={['Effective Dates', 'Current Plan Info']}
            activeStep={currentStep}
            isEditMode={true}
            isLastStepActive={true}
            changeCurrentStep={handleSetCurrentStep}
            renderStepContent={renderStepContent}
            stepperRightMargin={false}
          />
        </div>
      </FullScreenModal>
      <ConfirmationDialog
        title={CLOSE_POPUP_TITLE_TEXT}
        confirmText={CLOSE_POPUP_CONFIRM_TEXT}
        cancelText={CLOSE_POPUP_CANCEL_TEXT}
        closeModal={handleCancelCloseModal}
        onConfirm={toggleRenewalDateModalVisibility}
        visible={isConfirmationModalOpen}
        destroyOnClose
      >
        <p className={styles.warningConfirmation}>{exitWithoutSavingMsg}</p>
      </ConfirmationDialog>
    </>
  );
};

export default StartNewRenewalModal;
