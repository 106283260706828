import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import visionIcon from 'assets/images/benefitCategories/icon-vision.svg';
import dentalIcon from 'assets/images/benefitCategories/icon-dental.svg';
import medicalIcon from 'assets/images/benefitCategories/icon-medical.svg';
import lifeIcon from 'assets/images/benefitCategories/icon-life-and-disability.svg';
import voluntaryIcon from 'assets/images/benefitCategories/icon-voluntary-benefits.svg';
import taxAdvIcon from 'assets/images/benefitCategories/icon-tax-adv.svg';
import visionIconDisabled from 'assets/images/benefitCategoriesDisabled/icon-vision.svg';
import dentalIconDisabled from 'assets/images/benefitCategoriesDisabled/icon-dental.svg';
import medicalIconDisabled from 'assets/images/benefitCategoriesDisabled/icon-medical.svg';
import voluntaryIconDisabled from 'assets/images/benefitCategoriesDisabled/icon-voluntary-benefits.svg';
import lifeIconDisabled from 'assets/images/benefitCategoriesDisabled/icon-life-and-disability.svg';
import taxAdvIconDisabled from 'assets/images/benefitCategoriesDisabled/icon-tax-adv.svg';
import {
  BASIC_ADD,
  BASIC_LIFE,
  BASIC_LIFE_AND_ADD,
  CREDIT_DROPDOWN_ENUMS,
  CREDITS_INCLUSION,
  LIFE,
  LTD,
  OFFER_BENEFIT_TYPE_NAVIGATION_LABELS,
  OFFER_BENEFIT_TYPES,
  OFFER_CATEGORY,
  OFFER_TAGS,
  PREMIUM_NAMES,
  PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS,
  PROPOSAL_CARD_ACTION_OPTIONS,
  STD,
} from 'modules/renewals/constants/renewalsConstants';
import {
  ICreditsDiscountsDescriptionItem,
  IProposalBenefitsCardData,
  IProposalBenefitsPageOverviewData,
  IProposalBenefitsTableRow,
  OfferBenefitType,
  OfferBenefitValueType,
  ProposalCardActionOptionType,
} from 'modules/renewals/types/renewalsTypes';
import {
  currencyFormatter,
  currencyFormatterWithDecimalPlaceIfExists,
} from 'util/commonUtil';
import ProposalSummaryVO, {
  DiscountSummaryVO,
  DiscountVO,
  PlanContributionVO,
  ProposalContributionVO,
  VolLifeSummaryVO,
} from 'model/ProposalSummaryVO';
import { buildCommaSeparatedString } from 'util/stringUtil';
import ProposalCreditVO, { OfferCreditVO } from 'model/ProposalCreditVO';
import ProposalDiscountVO from 'model/ProposalDiscountVO';
import { EMPTY_PLAN_TABLE_DATA } from 'modules/renewals/constants/planTableConstants';
import PremiumType from 'modules/renewals/enums/PremiumType';
import FundingType from 'modules/plans/enums/FundingType';
import { isNullOrUndefined } from 'modules/plans/utils';
import { Offer } from 'modules/renewals/models/Offer';

export const getBenefitInfo = (
  type: string
): { icon: any; iconDisabled: any; name: string } => {
  switch (type) {
    case 'medical':
      return {
        icon: medicalIcon,
        iconDisabled: medicalIconDisabled,
        name: OFFER_BENEFIT_TYPES.MEDICAL,
      };
    case 'dental':
      return {
        icon: dentalIcon,
        iconDisabled: dentalIconDisabled,
        name: OFFER_BENEFIT_TYPES.DENTAL,
      };
    case 'vision':
      return {
        icon: visionIcon,
        iconDisabled: visionIconDisabled,
        name: OFFER_BENEFIT_TYPES.VISION,
      };
    case 'lifeadd':
    case 'life':
    case 'ltd':
    case 'std':
      return {
        icon: lifeIcon,
        iconDisabled: lifeIconDisabled,
        name:
          type === 'life' || type === 'lifeadd'
            ? OFFER_BENEFIT_TYPES.LIFE
            : type === 'std'
            ? OFFER_BENEFIT_TYPES.STD
            : OFFER_BENEFIT_TYPES.LTD,
      };
    case 'voluntarybenefits':
    case 'voluntary_benefit':
      return {
        icon: voluntaryIcon,
        iconDisabled: voluntaryIconDisabled,
        name: OFFER_BENEFIT_TYPES.VOLUNTARY_BENEFIT,
      };
    default:
      return {
        icon: null,
        iconDisabled: null,
        name: '',
      };
  }
};

export const getTypeIcon = (
  type: OfferBenefitValueType | string
): { icon?: string; iconDisabled?: string } => {
  switch (type) {
    case OFFER_BENEFIT_TYPES.MEDICAL:
      return {
        icon: medicalIcon,
        iconDisabled: medicalIconDisabled,
      };
    case OFFER_BENEFIT_TYPES.DENTAL:
      return {
        icon: dentalIcon,
        iconDisabled: dentalIconDisabled,
      };
    case OFFER_BENEFIT_TYPES.VISION:
      return {
        icon: visionIcon,
        iconDisabled: visionIconDisabled,
      };
    case OFFER_BENEFIT_TYPES.LIFE_AND_DISABILITY:
    case OFFER_BENEFIT_TYPES.LIFE:
    case OFFER_BENEFIT_TYPES.LTD:
    case OFFER_BENEFIT_TYPES.STD:
      return {
        icon: lifeIcon,
        iconDisabled: lifeIconDisabled,
      };
    case OFFER_BENEFIT_TYPES.VOLUNTARY_BENEFIT:
      return {
        icon: voluntaryIcon,
        iconDisabled: voluntaryIcon,
      };
    case OFFER_BENEFIT_TYPES.TAX_ADVANTAGE_ACCOUNTS:
      return {
        icon: taxAdvIcon,
        iconDisabled: taxAdvIconDisabled,
      };
    default:
      return {};
  }
};

export const formatHeaderValues = (
  value: number,
  type: 'CURRENCY' | 'PERCENTAGE',
  showPositive: boolean,
  decimalPoints?: number
) => {
  if (type === 'CURRENCY' && value !== null) {
    return `${showPositive && value > 0 ? '+' : ''}${
      decimalPoints
        ? currencyFormatterWithDecimalPlaceIfExists(value)
        : currencyFormatter(Math.round(value), 'en-US', 'USD', 0)
    }`;
  } else if (type === 'PERCENTAGE') {
    return `${value}%`;
  } else {
    return '-';
  }
};

export const getRateDetails = (rate: string | null) => {
  if (isEmpty(rate) || rate === null) {
    return '-';
  } else {
    return rate;
  }
};

export const getSelectedOffersBasedOnTheType = (
  proposal: any,
  type: string
): any => {
  switch (type) {
    case OFFER_CATEGORY.MEDICAL:
      return proposal?.supplementaryMedicalOffers;
    case OFFER_CATEGORY.DENTAL:
      return proposal?.supplementaryDentalOffers;
    case OFFER_CATEGORY.VISION:
      return proposal?.supplementaryVisionOffers;
    case OFFER_CATEGORY.LIFE_AD:
      return proposal?.lifeAddOffers;
    case OFFER_CATEGORY.LONG_TERM_DISABILITY:
      return proposal?.lifeLTDOffers;
    case OFFER_CATEGORY.SHORT_TERM_DISABILITY:
      return proposal?.lifeSTDOffers;
    case OFFER_CATEGORY.VOLUNTARY:
      return proposal?.volLifeOffers;
    default:
      return [];
  }
};

export const isDtqOrPending = (offerStatusField: string | null) => {
  return (
    offerStatusField === OFFER_TAGS.DTQ ||
    offerStatusField === OFFER_TAGS.PENDING
  );
};

export const isDraftOffer = (offerStatusField: string | null) => {
  return offerStatusField === OFFER_TAGS.DRAFT;
};

export const transformContributionsToCardDataList = (
  contributions: ProposalContributionVO[],
  discountSummary?: DiscountSummaryVO,
  offerCredits?: OfferCreditVO[],
  isCreditsIncluded?: boolean
): IProposalBenefitsCardData[] => {
  const benefitData: IProposalBenefitsCardData[] = [];
  const getItemOfCategories = (categories: string[]) => (item: any) =>
    categories.includes(item?.category);

  ['MEDICAL', 'DENTAL', 'VISION'].forEach((category) => {
    const contributionOfCategory = contributions?.find(
      getItemOfCategories([category])
    );
    const discountOfCategory = discountSummary?.discountInfo?.find(
      getItemOfCategories([category])
    );
    const offerCreditsOfCategory = offerCredits?.filter(
      getItemOfCategories([category])
    );
    // Get relevant discountVO
    if (contributionOfCategory) {
      benefitData.push(
        getFormattedMDVBenefitCardData(
          contributionOfCategory,
          discountOfCategory,
          offerCreditsOfCategory,
          isCreditsIncluded
        )
      );
    }
  });

  const lifeDisabilityContributions = contributions?.filter(
    getItemOfCategories(['LIFE', 'LTD', 'STD'])
  );
  const lifeDisabilityDiscounts = discountSummary?.discountInfo?.filter(
    getItemOfCategories(['LIFE', 'LTD', 'STD'])
  );
  const lifeDisabilityOfferCredits = offerCredits?.filter(
    getItemOfCategories(['LIFE', 'LTD', 'STD'])
  );
  if (lifeDisabilityContributions && lifeDisabilityContributions.length > 0) {
    benefitData.push(
      getFormattedLifeDisabilityBenefitCardData(
        lifeDisabilityContributions,
        lifeDisabilityDiscounts,
        lifeDisabilityOfferCredits,
        isCreditsIncluded
      )
    );
  }

  const voluntaryBenefitsContribution = contributions?.find(
    getItemOfCategories(['VOLUNTARY_BENEFIT'])
  );

  if (voluntaryBenefitsContribution) {
    benefitData.push(
      getFormattedVoluntaryBenefitCardData(voluntaryBenefitsContribution)
    );
  }

  return benefitData;
};

const getFormattedVoluntaryBenefitCardData = (
  data: ProposalContributionVO
): IProposalBenefitsCardData => {
  const overviewData: IProposalBenefitsCardData['overviewData'] = {
    eeCost: {},
    erCost: {},
    estimatedAnnualPremium: {},
  };

  // Creating Tables after plan has been sorted.
  const tableData: IProposalBenefitsCardData['tableData'] = [];

  // Map summaries to carrier based on id
  const carrierPlanMap = new Map<string, VolLifeSummaryVO[]>();
  const carrierNames: string[] = [];
  data.volLifePlans?.map((item) => {
    if (item.carrierId) {
      if (!carrierPlanMap.has(item.carrierId)) {
        carrierPlanMap.set(item.carrierId, []);
        carrierNames.push(item.carrier || '');
      }
      carrierPlanMap.get(item.carrierId)?.push(item);
    }
  });

  // Go through each carrier and create table data
  carrierPlanMap.forEach((summaryArr) => {
    const carrierTableData = {
      carrierLogo: summaryArr[0].carrierLogoUrl || '',
      rowData: [] as IProposalBenefitsTableRow[],
    };
    summaryArr.forEach((summary) => {
      carrierTableData.rowData.push({
        name: summary.name || '',
        planDocuments: summary?.attachedDocuments,
        newPlan: summary?.newPlan,
        planId: summary?.planId,
      });
    });
    tableData.push(carrierTableData);
  });

  return {
    name: buildCommaSeparatedString(carrierNames) || '',
    overviewData,
    tableData,
    type: OFFER_BENEFIT_TYPES[data?.category || 'MEDICAL'],
    isPlaceHolder: data.placeHolderCreated,
  };
};

const getFormattedLifeDisabilityBenefitCardData = (
  data: ProposalContributionVO[],
  discountDataArr?: DiscountVO[],
  offerCreditsDataArr?: OfferCreditVO[],
  isCreditsIncluded?: boolean
): IProposalBenefitsCardData => {
  const estimatedAnnualPremium = {
    value: 0,
    differenceValue: 0,
    differencePercentage: 0,
  };

  const eeCost = {
    value: 0,
    differenceValue: 0,
    differencePercentage: 0,
  };

  const erCost = {
    value: 0,
    differenceValue: 0,
    differencePercentage: 0,
  };

  const credits = {
    value: 0,
  };
  let placeHolderFlag: boolean | undefined = false;

  const tableData: IProposalBenefitsCardData['tableData'] = [];
  let name: string = '';

  const carrierPlanMap = new Map<string, PlanContributionVO[]>();
  const carrierNames: string[] = [];
  data.forEach((contribution) => {
    placeHolderFlag = contribution?.placeHolderCreated;
    erCost.value += isCreditsIncluded
      ? contribution.annualContributionDiscounted?.totalErContribution || 0
      : contribution.annualContributionDefault?.totalErContribution || 0;
    erCost.differenceValue += isCreditsIncluded
      ? contribution.comparisons?.discountedErDiff || 0
      : contribution.comparisons?.totalErImpact || 0;

    estimatedAnnualPremium.value += isCreditsIncluded
      ? contribution.annualContributionDiscounted?.totalContribution || 0
      : contribution.annualContributionDefault?.totalContribution || 0;
    estimatedAnnualPremium.differenceValue += isCreditsIncluded
      ? contribution.comparisons?.discountedDiff || 0
      : contribution.comparisons?.initialDiff || 0;

    contribution.planContributions?.forEach((item) => {
      if (item.carrierId) {
        if (!carrierPlanMap.has(item.carrierId)) {
          carrierPlanMap.set(item.carrierId, []);
          carrierNames.push(item.carrierName || '');
        }
        carrierPlanMap
          .get(item.carrierId)
          ?.push({ category: contribution?.category, ...item });
      }
    });
  });

  erCost.differencePercentage =
    erCost.value - erCost.differenceValue !== 0
      ? (100 * erCost.differenceValue) / (erCost.value - erCost.differenceValue)
      : 0;
  estimatedAnnualPremium.differencePercentage =
    estimatedAnnualPremium.value - estimatedAnnualPremium.differenceValue !== 0
      ? (100 * estimatedAnnualPremium.differenceValue) /
        (estimatedAnnualPremium.value - estimatedAnnualPremium.differenceValue)
      : 0;

  discountDataArr?.forEach((discount) => {
    credits.value += discount?.discountTotal || 0;
  });
  offerCreditsDataArr?.forEach(
    (offerCredit) => (credits.value += offerCredit.credit || 0)
  );

  carrierPlanMap.forEach((plans) => {
    const carrierTableData = {
      carrierLogo: plans[0].carrierLogoUrl || '',
      rowData: [] as IProposalBenefitsTableRow[],
    };
    plans.forEach((pc) => {
      const enrollment =
        pc?.category === STD
          ? pc.tierContributionsDefault?.EE?.totalEligibleEmployees
          : (pc.tierContributionsDefault?.EE?.totalEligibleEnrollments || 0) +
            (pc.tierContributionsDefault?.EF?.totalEligibleEnrollments || 0) +
            (pc.tierContributionsDefault?.ES?.totalEligibleEnrollments || 0) +
            (pc.tierContributionsDefault?.EC?.totalEligibleEnrollments || 0);

      carrierTableData.rowData.push({
        name: pc.planName || '',
        enrollment: enrollment,
        annualPremium: pc.annualContributionDefault?.totalPremium,
        fromCurrent: {
          value: pc.comparisons?.initialDiff || 0,
          percentage: pc.comparisons?.initialDiffPercent || 0,
        },
        newPlan: pc?.newPlan,
        isPlaceHolder: pc.placeHolderCreated,
        planId: pc?.planId,
      });
    });

    carrierTableData.rowData.sort(
      (a, b) => (b?.annualPremium ?? 0) - (a?.annualPremium ?? 0)
    );

    tableData.push(carrierTableData);
  });

  name = buildCommaSeparatedString(carrierNames) || '';

  return {
    name,
    overviewData: { eeCost, erCost, estimatedAnnualPremium, credits },
    tableData,
    type: 'Life & Disability',
    isPlaceHolder: placeHolderFlag,
  };
};

/**
 * One function from a set used to transform data to fit the schema required on ProposalBenefitCard
 * component. Combines data proposal, credit and discount data from 3 different endpoints to format
 * it in the required way.
 * @param {Object} data
 * @param {Object} discountData
 * @param {Object} offerCreditData
 * @param {boolean} isCreditsIncluded
 * @return {Object} An object in the schema required by ProposalBenefitCard
 */
const getFormattedMDVBenefitCardData = (
  data: ProposalContributionVO,
  discountData?: DiscountVO,
  offerCreditData?: OfferCreditVO[],
  isCreditsIncluded?: boolean
): IProposalBenefitsCardData => {
  // Creating overview data
  const creditValue =
    discountData?.discountTotal ||
    (offerCreditData && offerCreditData?.length > 0)
      ? (discountData?.discountTotal || 0) +
        (offerCreditData?.reduce(
          (prev, current) => prev + (current.credit || 0),
          0
        ) || 0)
      : undefined;

  const getOverviewData = (): IProposalBenefitsCardData['overviewData'] => {
    const contribution = isCreditsIncluded
      ? data.annualContributionDiscounted
      : data.annualContributionDefault;
    const comparisons = data.comparisons;
    return {
      eeCost: {
        value: data.annualContributionDefault?.totalEePremium,
        differenceValue: comparisons?.totalEeImpact,
        differencePercentage: comparisons?.totalEeImpactPercent,
      },
      erCost: {
        value: contribution?.totalErContribution,
        differenceValue: isCreditsIncluded
          ? comparisons?.discountedErDiff
          : comparisons?.totalErImpact,
        differencePercentage: isCreditsIncluded
          ? comparisons?.discountedErDiffPercent
          : comparisons?.totalErImpactPercent,
        erHSA: contribution?.totalHsaContribution,
      },
      estimatedAnnualPremium: {
        value: contribution?.totalContribution,
        differenceValue: isCreditsIncluded
          ? comparisons?.discountedDiff
          : comparisons?.initialDiff,
        differencePercentage: isCreditsIncluded
          ? comparisons?.discountedDiffPercent
          : comparisons?.initialDiffPercent,
      },
      credits: {
        value: creditValue,
      },
      totalHraCost: contribution?.totalHraContribution ?? 0,
    };
  };

  // Sorting plans by carrier
  const carrierPlanMap = new Map<string, PlanContributionVO[]>();
  const carrierNames: string[] = [];
  data.planContributions?.map((item) => {
    if (item.carrierId) {
      if (!carrierPlanMap.has(item.carrierId)) {
        carrierPlanMap.set(item.carrierId, []);
        carrierNames.push(item.carrierName || '');
      }
      carrierPlanMap.get(item.carrierId)?.push(item);
    }
  });

  // Creating Tables after plan has been sorted.
  const tableData: IProposalBenefitsCardData['tableData'] = [];
  carrierPlanMap.forEach((plans) => {
    const carrierTableData = {
      carrierLogo: plans[0].carrierLogoUrl || '',
      rowData: [] as IProposalBenefitsTableRow[],
    };
    plans.forEach((pc) => {
      carrierTableData.rowData.push({
        name: pc.planName || '',
        enrollment:
          (pc.tierContributionsDefault?.EE?.enrollments || 0) +
          (pc.tierContributionsDefault?.EF?.enrollments || 0) +
          (pc.tierContributionsDefault?.ES?.enrollments || 0) +
          (pc.tierContributionsDefault?.EC?.enrollments || 0) +
          (pc.tierContributionsDefault?.EE1?.enrollments || 0) +
          (pc.tierContributionsDefault?.EE2?.enrollments || 0) +
          (pc.tierContributionsDefault?.EE3?.enrollments || 0) +
          (pc.tierContributionsDefault?.EE4?.enrollments || 0) +
          (pc.tierContributionsDefault?.EE5?.enrollments || 0) +
          (pc.tierContributionsDefault?.EE6?.enrollments || 0),
        splits: `${Math.round(pc.planSplit?.erSplit ?? 0)}/${Math.round(
          pc.planSplit?.eeSplit ?? 0
        )}`,
        annualPremium: pc.annualContributionDefault?.totalPremium,
        fromCurrent: {
          value: pc.comparisons?.initialDiff || 0,
          percentage: pc.comparisons?.initialDiffPercent || 0,
        },
        newPlan: pc.newPlan,
        planId: pc?.planId,
        fundingType: pc?.fundingType,
      });
    });

    carrierTableData.rowData.sort((a, b) => {
      if (a?.newPlan == b?.newPlan) {
        return (b?.annualPremium ?? 0) - (a?.annualPremium ?? 0);
      } else {
        return a.newPlan ? 1 : -1;
      }
    });

    tableData.push(carrierTableData);
  });

  return {
    name: buildCommaSeparatedString(carrierNames) || '',
    overviewData: getOverviewData(),
    tableData,
    type: OFFER_BENEFIT_TYPES[data?.category || 'MEDICAL'],
  };
};

export const getCreditIncludedExcludedText = (type: any) => {
  switch (type) {
    case CREDIT_DROPDOWN_ENUMS[0].value:
    case CREDITS_INCLUSION.EXCLUDED.value:
      return {
        title: 'Show with Credits & Costs Excluded',
        content:
          'By toggling this view, all premium amounts across Renewals will show values with Credits & Costs Excluded. You may change this back to Credits Included at anytime.',
        saveBtnText: 'Show with Credits & Costs Excluded',
      };
    case CREDIT_DROPDOWN_ENUMS[1].value:
    case CREDITS_INCLUSION.INCLUDED.value:
      return {
        title: 'Show with Credits & Costs Included',
        content:
          'By toggling this view, all premium amounts across Renewals will show values with Credits & Costs Included. You may change this back to Credits Excluded at anytime.',
        saveBtnText: 'Show with Credits & Costs Included',
      };
    default:
      return {
        title: '',
        content: '',
        saveBtnText: '',
      };
  }
};

export const isVoluntaryBenefit = (benefitType: string) => {
  return (
    benefitType === OFFER_BENEFIT_TYPE_NAVIGATION_LABELS.VOLUNTARY_BENEFIT ||
    benefitType === 'voluntary_benefit'
  );
};

export const getBenefitTitleFromUrlParam = (
  benefitKind: string | undefined
) => {
  const toUpperCase = benefitKind?.toUpperCase();

  if (toUpperCase === OFFER_BENEFIT_TYPES.MEDICAL.toUpperCase()) {
    return OFFER_BENEFIT_TYPES.MEDICAL;
  } else if (toUpperCase === OFFER_BENEFIT_TYPES.DENTAL.toUpperCase()) {
    return OFFER_BENEFIT_TYPES.DENTAL;
  } else if (toUpperCase === OFFER_BENEFIT_TYPES.VISION.toUpperCase()) {
    return OFFER_BENEFIT_TYPES.VISION;
  } else if (toUpperCase === 'LIFE-ADD' || toUpperCase === 'LIFE') {
    return OFFER_BENEFIT_TYPES.LIFE;
  } else if (toUpperCase === 'STD') {
    return OFFER_BENEFIT_TYPES.STD;
  } else if (toUpperCase === 'LTD') {
    return OFFER_BENEFIT_TYPES.LTD;
  } else if (
    toUpperCase === 'VOL-LIFE' ||
    toUpperCase === 'VOLUNTARY_BENEFIT'
  ) {
    return OFFER_BENEFIT_TYPES.VOLUNTARY_BENEFIT;
  }

  return null;
};

export const convertBenefitKindToBenefitCategory = (
  benefitKind: string | undefined
) => {
  const value = benefitKind?.toUpperCase();

  if (['LIFE-ADD', 'STD', 'LTD'].includes(value!)) {
    return 'LIFE';
  }

  return value;
};

export const getBenefitTitleForRecentFiles = (
  benefitKind: string | undefined
) => {
  const benefit = benefitKind?.toUpperCase();

  if (benefit === OFFER_BENEFIT_TYPES.MEDICAL.toUpperCase()) {
    return OFFER_BENEFIT_TYPES.MEDICAL;
  } else if (benefit === OFFER_BENEFIT_TYPES.DENTAL.toUpperCase()) {
    return OFFER_BENEFIT_TYPES.DENTAL;
  } else if (benefit === OFFER_BENEFIT_TYPES.VISION.toUpperCase()) {
    return OFFER_BENEFIT_TYPES.VISION;
  } else if (
    ['LIFE', 'LIFE-ADD', 'BASIC_LIFE', 'BASIC_ADD'].includes(benefit ?? '')
  ) {
    return OFFER_BENEFIT_TYPES.LIFE;
  } else if (benefit === 'STD') {
    return OFFER_BENEFIT_TYPES.STD;
  } else if (benefit === 'LTD') {
    return OFFER_BENEFIT_TYPES.LTD;
  } else if (benefit === 'VOL-LIFE' || benefit === 'VOLUNTARY_BENEFIT') {
    return OFFER_BENEFIT_TYPES.VOLUNTARY_BENEFIT;
  }
  return null;
};

export const filterProposalOptionsForPlanYear = (
  options: ProposalCardActionOptionType[]
): ProposalCardActionOptionType[] => {
  return options.map((obj) => {
    if (
      obj === PROPOSAL_CARD_ACTION_OPTIONS.EDIT_PROPOSAL ||
      obj === PROPOSAL_CARD_ACTION_OPTIONS.CLONE_PROPOSAL ||
      obj === PROPOSAL_CARD_ACTION_OPTIONS.RECOMMEND ||
      obj === PROPOSAL_CARD_ACTION_OPTIONS.MOVE_TO_DRAFTS ||
      obj === PROPOSAL_CARD_ACTION_OPTIONS.PUBLISH ||
      obj === PROPOSAL_CARD_ACTION_OPTIONS.FINAL_APPROVED ||
      obj === PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.EDIT_PROPOSAL ||
      obj === PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.CLONE_PROPOSAL ||
      obj === PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.RECOMMEND ||
      obj === PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.FINAL_APPROVED ||
      obj === PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.MOVE_TO_DRAFTS ||
      obj === PROPOSAL_BENEFITS_PAGE_ACTIONS_OPTIONS.DELETE_PROPOSAL ||
      obj === PROPOSAL_CARD_ACTION_OPTIONS.DELETE_PROPOSAL
    ) {
      return { ...obj, disable: true };
    }
    return obj;
  });
};

export const getProposalBenefitsOverviewData = (
  summary: ProposalSummaryVO,
  isCreditsIncluded?: boolean
): IProposalBenefitsPageOverviewData => {
  return {
    totalBenefitsCost: {
      value: !isCreditsIncluded
        ? summary.totalBenefitCost
        : summary.totalDiscountedBenefitCost,
      differenceValue: !isCreditsIncluded
        ? summary.totalBenefitDiffFromCurrent
        : summary.totalDiscountedBenefitDiffFromCurrent,
      differencePercentage: !isCreditsIncluded
        ? summary.totalBenefitDiffFromCurrentPercent
        : summary.totalDiscountedBenefitDiffFromCurrentPercent,
    },
    erCost: {
      value: !isCreditsIncluded
        ? summary.totalErContribution
        : summary.totalDiscountedErCost,
      differenceValue: !isCreditsIncluded
        ? summary.totalErContributionDiffFromCurrent
        : summary.totalDiscountedErCostDiffFromCurrent,
      differencePercentage: !isCreditsIncluded
        ? summary.totalErContributionDiffFromCurrentPercent
        : summary.totalDiscountedErCostDiffFromCurrentPercent,
    },
    eeCost: {
      value: !isCreditsIncluded
        ? summary.totalEeCost
        : summary.totalDiscountedEECost,
      differenceValue: summary.totalEeDiffFromCurrent,
      differencePercentage: summary.totalEeDiffFromCurrentPercent,
    },
    creditsAndDiscounts: {
      totalCredits: summary.totalCredits,
      totalDiscounts: summary.totalDiscounts,
      totalCreditsAndDiscounts: summary.totalCreditAndDiscounts,
    },
  };
};

export const getMultipleCreditDiscountModalData = (
  creditData?: ProposalCreditVO,
  discountData?: ProposalDiscountVO,
  type?: OfferBenefitType[]
) => {
  const formattedData = {
    credits: [] as ICreditsDiscountsDescriptionItem[],
    additionalCredits: [] as ICreditsDiscountsDescriptionItem[],
    discounts: [] as ICreditsDiscountsDescriptionItem[],
  };
  if (creditData) {
    creditData.offerCredits?.forEach((item) => {
      if (!type || type.includes(item.category as any)) {
        formattedData.credits.push({
          description: item.description || '',
          value: item.credit || 0,
          offerName: item.offerName,
        });
      }
    });
    creditData.credits
      ?.filter((obj) => 'CREDIT' === obj?.type)
      .forEach((item) => {
        if (!type) {
          formattedData.additionalCredits.push({
            value: item.total || 0,
            description: item.description || '',
            type: item.type,
            eeAmount: item.eeAmount || 0,
            erAmount: item.erAmount || 0,
          });
        }
      });
  }

  if (discountData) {
    discountData.discounts?.forEach((item) => {
      if (!type || type.includes(item.category as any)) {
        formattedData.discounts.push({
          offerName: item.offerName,
          percentage: item.discount,
          value: item.discountTotal || 0,
          description: item.description || '',
        });
      }
    });
  }

  return formattedData;
};

export const hasCreditsDiscountsData = (
  creditData?: ProposalCreditVO,
  discountData?: ProposalDiscountVO,
  type?: OfferBenefitType[]
): boolean => {
  if (creditData) {
    if (
      (creditData.offerCredits?.length ?? 0 > 0) ||
      (creditData.credits?.length ?? 0 > 0)
    ) {
      return true;
    }
  }

  if (discountData) {
    if (discountData.discounts?.length ?? 0 > 0) {
      return true;
    }
  }

  return false;
};

export const addProposalCompletedSteps = (
  completedProposalSteps: string[],
  currentStep: string
) => {
  if (completedProposalSteps.includes(currentStep)) {
    return completedProposalSteps;
  }
  return [...completedProposalSteps, currentStep];
};

export const getEffectiveDateFormatted = (start: string, end: string) => {
  return `${dayjs(start).format('MMM D, YYYY')} - ${dayjs(end).format(
    'MMM D, YYYY'
  )}`;
};

export const handleRedirectToProposalsDetails = (id: string, params: any) => {
  const url = `/brokers/${params.brokerId}/employers/${params.employerId}/renewals/proposals/details/${id}`;
  return window.open(url, '_blank');
};

export const validateNonMDVObj = (
  selectedData: any,
  benefitType: string,
  fundingType: string | undefined
): boolean => {
  if (selectedData?.name?.trim() === '' || selectedData?.name === null) {
    return true;
  } else if (
    (fundingType &&
      fundingType !== FundingType.SELF_FUNDED &&
      isEmpty(
        selectedData?.volume?.formattedValue?.trim() ??
          selectedData?.volume?.value?.trim()
      )) ||
    ((fundingType &&
      fundingType !== FundingType.SELF_FUNDED &&
      selectedData?.volume?.formattedValue?.trim()) ??
      selectedData?.volume?.value?.trim()) === null
  ) {
    return true;
  } else if (
    benefitType === LIFE ||
    benefitType === STD ||
    benefitType === LTD
  ) {
    if (
      isEmpty(selectedData?.insuranceType) ||
      selectedData?.insuranceType === null
    ) {
      return true;
    }
    if (
      (isEmpty(
        selectedData?.basicAddRate?.[0]?.formattedValue?.trim() ??
          selectedData?.basicAddRate?.[0]?.value?.trim()
      ) ||
        (selectedData?.volume?.basicAddRate?.[0]?.trim() ??
          selectedData?.basicAddRate?.[0]?.value?.trim()) === null) &&
      (selectedData?.insuranceType === BASIC_ADD ||
        selectedData?.insuranceType === BASIC_LIFE_AND_ADD)
    ) {
      return true;
    } else if (
      (isEmpty(
        selectedData?.lifeRate?.[0]?.formattedValue?.trim() ??
          selectedData?.lifeRate?.[0]?.value?.trim()
      ) ||
        (selectedData?.volume?.lifeRate?.[0]?.trim() ??
          selectedData?.lifeRate?.[0]?.value?.trim()) === null) &&
      (selectedData?.insuranceType === BASIC_LIFE ||
        selectedData?.insuranceType === BASIC_LIFE_AND_ADD ||
        fundingType === FundingType.FULLY_INSURED ||
        selectedData?.insuranceType === LTD)
    ) {
      return true;
    } else if (
      benefitType === STD &&
      fundingType === FundingType.SELF_FUNDED &&
      (isEmpty(
        selectedData?.annualEstimatedClaims?.formattedValue?.trim() ??
          selectedData?.annualEstimatedClaims?.value?.trim()
      ) ||
        isEmpty(selectedData?.administrationFee?.value ?? ''))
    ) {
      return true;
    } else if (
      benefitType === STD &&
      fundingType === FundingType.SELF_FUNDED &&
      isEmpty(
        selectedData?.totalEligibleEmployees?.formattedValue?.trim() ??
          selectedData?.totalEligibleEmployees?.value?.trim()
      )
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const sortCarrierList = (carrierList: any[]) => {
  const sortedCarriers = carrierList?.sort((a: any, b: any) =>
    a?.label?.toLowerCase()?.localeCompare(b?.label?.toLowerCase())
  );
  const currentCarriers = sortedCarriers?.filter((item: any) => item?.current);
  const renewalCarriers = sortedCarriers?.filter(
    (item: any) => item?.renewal && !item?.current
  );
  const otherCarriers = sortedCarriers?.filter(
    (item: any) => !item?.current && !item?.renewal
  );

  return currentCarriers.concat(renewalCarriers.concat(otherCarriers));
};

export const sortOfferNamesList = (offerList: any[]) => {
  const sortedOffers = offerList?.sort((a: any, b: any) =>
    a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase())
  );
  const currentOffers = sortedOffers?.filter((item: any) => item?.current);
  const renewalOffers = sortedOffers?.filter(
    (item: any) => item?.renewal && !item?.current
  );
  const otherOffers = sortedOffers?.filter(
    (item: any) => !item?.current && !item?.renewal
  );

  return currentOffers.concat(renewalOffers.concat(otherOffers));
};

export const internalNotesAllowedFiles = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const getCategoryForUrlNavigation = (category: any): string => {
  switch (category?.toUpperCase()) {
    case 'MEDICAL':
      return 'medical';
    case 'DENTAL':
      return 'dental';
    case 'VISION':
      return 'vision';
    case 'LIFE':
    case 'BASIC_ADD':
    case 'BASIC_LIFE':
    case 'BASIC_LIFE_AND_ADD':
      return 'life-add';
    case 'VOLUNTARY_BENEFIT':
      return 'vol-life';
    case 'STD':
      return 'std';
    case 'LTD':
      return 'ltd';
    default:
      return '';
  }
};

export const compareMappedPlansAndGetUnMappedPlanList = (
  currentPlanList: any[],
  mappedPlans: string[]
): any[] => {
  return currentPlanList?.filter(
    (element) => !mappedPlans?.includes(element?.value)
  );
};

export const compareMappedPlansForQuotes = (
  mappedPlans: string[],
  currentPlans: { [key: string]: string },
  discontinuedPlan?: { [key: string]: any }
): boolean => {
  const nonEmptyValues = Object.entries(currentPlans)
    .filter(
      ([key, value]) =>
        mappedPlans?.includes(key) && value !== '' && value !== 'N/A'
    )
    .map(([_, value]) => value);

  // Calculate the number of active plans (not discontinued, not empty, not 'N/A')
  const activePlanCount = nonEmptyValues?.length;

  if (isNullOrUndefined(discontinuedPlan)) {
    return Object.keys(currentPlans)?.length === activePlanCount;
  }

  const isMismatch =
    Object.keys(currentPlans)?.length - activePlanCount !==
    Object.keys(discontinuedPlan ?? {})?.length;

  return (
    !isMismatch &&
    Object.keys(currentPlans)?.length ===
      mappedPlans?.length + Object.keys(discontinuedPlan ?? {})?.length
  );
};

export const getCurrentPlansForSubmit = (
  mappedPlans: string[],
  updatedData: []
): string[] => {
  return mappedPlans?.length !== updatedData?.length
    ? updatedData?.map((item: any, index: number) =>
        mappedPlans[index] === '-' ? '' : mappedPlans[index] || ''
      )
    : mappedPlans?.map((currPlanId: string) =>
        currPlanId === '-' ? '' : currPlanId
      );
};

export const isFromCurrentExist = (data: any): boolean => {
  return data === null || data === undefined || data === 0;
};

const buildNTierMonthlyPremiums = (nTierCount: number) => {
  let premiumList = [] as any[];
  for (let index = 0; index < nTierCount + 1; index++) {
    premiumList = [
      ...premiumList,
      {
        name: index === 0 ? 'EE Only' : `EE + ${index}`,
        fieldType: null,
        fieldSubType: `${index === 0 ? 1 : index}/${index === 0 ? 4 : 6}`,
        value: null,
      },
    ];
  }

  return premiumList;
};

export const getEmptyPlan = (
  premiumType: PremiumType,
  benefitKind: string,
  nTierCount: number
) => {
  if (premiumType === PremiumType.N_TIER) {
    return {
      ...EMPTY_PLAN_TABLE_DATA?.[benefitKind ?? ''],
      monthlyPremiums: buildNTierMonthlyPremiums(nTierCount),
    };
  } else {
    return EMPTY_PLAN_TABLE_DATA?.[benefitKind ?? ''];
  }
};

export const buildPremiums = (premiums: any, premiumType: PremiumType) => {
  if (premiumType === PremiumType.N_TIER) {
    return premiums;
  } else {
    return (
      premiums?.map((item: any) => {
        return {
          ...item,
          name: PREMIUM_NAMES?.[item?.name] ?? '',
        };
      }) ?? []
    );
  }
};

export const requiredFileQuoteTypes = [
  'application/pdf',
  'application/msword', // DOC
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
  'application/vnd.ms-excel', // XLS
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
  'application/vnd.ms-excel.sheet.macroEnabled.12', // XLSM
  'application/vnd.ms-word.document.macroEnabled.12', // DOCM (Macro-enabled DOC)
  'application/vnd.ms-excel.sheet.macroenabled.12', // XLSM
  'application/vnd.ms-word.document.macroenabled.12', // DOCM
];

export const initialQuoteMappingData = [
  { planIdentifier: '', currentPlan: '' },
];

/**
 *
 * Check if the already selected offers have the same name as the item being selected
 *
 * @param {any} item - Iterating object
 * @param {string[]} selectedOffers - Offers which has been already selected
 * @param {Offer[]} offers - Offers fetched from the api
 *
 * @return {boolean} - flag to check if the selected offer names are duplicated
 */
export const checkIfSelectedOfferNamesDuplicated = (
  item: any,
  selectedOffers: string[],
  offers: Offer[]
): boolean => {
  const offerNames: string[] = offers
    .filter((obj) => selectedOffers.includes(obj.id))
    .map((obj) => obj.name);
  return offerNames.includes(item.name) && !selectedOffers.includes(item.id);
};

type OfferedPlanAnyObject = Record<string, any>;

/**
 * Recursively removes the specified property from an object or array of objects.
 *
 * @param {OfferedPlanAnyObject} obj - The object from which properties will be removed.
 * @param {string} keyToRemove - The key of the property to be removed.
 */
export const removeKeyRecursively = (
  obj: OfferedPlanAnyObject,
  keyToRemove: string
): void => {
  /**
   * Helper function to recursively remove specified properties.
   *
   * @param {OfferedPlanAnyObject | OfferedPlanAnyObject[]} data - The data to be processed recursively.
   */
  const recursiveRemove = (
    data: OfferedPlanAnyObject | OfferedPlanAnyObject[]
  ): void => {
    if (Array.isArray(data)) {
      data.forEach((item) => recursiveRemove(item));
    } else if (typeof data === 'object' && data !== null) {
      Object.keys(data).forEach((key) => {
        if (key === keyToRemove) {
          data[key] = null;
        } else if (typeof data[key] === 'object' || Array.isArray(data[key])) {
          recursiveRemove(data[key]);
        }
      });
    }
  };

  recursiveRemove(obj);
};

export const getERTooltipText = (
  isHsa: boolean,
  isHra: boolean,
  isAdditionalCost: boolean
): string | null => {
  if (isHsa && isHra && isAdditionalCost) {
    return '(Incl. ER HSA, HRA, & Add’l Costs)';
  } else if (isHsa && isHra) {
    return '(Incl. ER HSA/HRA)';
  } else if (isHra && isAdditionalCost) {
    return '(Incl. ER HRA & Add’l Costs)';
  } else if (isHsa && isAdditionalCost) {
    return '(Incl. ER HSA & Add’l Costs)';
  } else if (isHra) {
    return '(Incl. ER HRA)';
  } else if (isHsa) {
    return '(Incl. ER HSA)';
  } else if (isAdditionalCost) {
    return '(Incl. Additional costs)';
  }
  return null;
};
