import { useCallback, useMemo } from 'react';
import { get } from 'lodash';
import { useAppSelector } from 'hooks/redux';
import { DBGEvent } from 'modules/clients/DBGClient/DBGClient';
import { CLIENT_DBG } from 'modules/clients/DBGClient/DBGClientConts';
import { benguideUrl } from 'util/apiUtil';

const useDBGClient = () => {
  const clients = useAppSelector((state) => state.client.clients);
  const dbgClientId = useMemo(() => {
    return get(clients, CLIENT_DBG);
  }, [clients]);

  const postMessage = useCallback(
    (event: DBGEvent) => {
      if (dbgClientId) {
        const iframe = document.getElementById(dbgClientId);
        if (isIFrame(iframe) && iframe.contentWindow) {
          iframe.contentWindow.postMessage(event, benguideUrl);
        }
      }
    },
    [dbgClientId]
  );

  return { postMessage };
};

const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
  input !== null && input.tagName === 'IFRAME';

export default useDBGClient;
