import { useAppSelector } from 'hooks/redux';
import DeductiblesOOPMax from 'modules/plans/components/DeductiblesOOPMax/DeductiblesOOPMax';

type Props = {
  setState: Function;
  state: any;
};

const DeductiblesAndOOPMax = ({ setState, state }: Props) => {
  const planCreateType = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.selectPlanCreateType
  );
  const isPlanReviewed = useAppSelector(
    (state) =>
      state.renewals.addProposalToPlanYear.isCurrentSelectedPlanReviewed
  );
  const isPlanMerged = useAppSelector(
    (state) => state.renewals.addProposalToPlanYear.isCurrentSelectedPlanMerged
  );

  const isMerging =
    planCreateType === 'MERGE' && !(isPlanReviewed === isPlanMerged);

  return (
    <div>
      <DeductiblesOOPMax
        onChange={(plan: any) => setState(plan)}
        medicalPlan={state}
        isTouched={false}
        isReviewProp={isMerging}
      />
    </div>
  );
};

export default DeductiblesAndOOPMax;
