import { combineReducers } from '@reduxjs/toolkit';

import AuthReducer from 'modules/auth/slices/authSlice';
import RegisterReducer from 'modules/auth/slices/registerSlice';
import ResetPwdReducer from 'modules/auth/slices/resetPwdSlice';
import CreatePwdReducer from 'modules/auth/slices/createPwdSlice';
import RegisterWithCodeAndEmailReducer from 'modules/auth/slices/codeRegistrationSlice';

const authRootReducers = combineReducers({
  auth: AuthReducer,
  register: RegisterReducer,
  resetPwd: ResetPwdReducer,
  createPwd: CreatePwdReducer,
  registerWithCodeAndEmailValidation: RegisterWithCodeAndEmailReducer,
});

export default authRootReducers;
