import { forwardRef, useImperativeHandle, useState } from 'react';
import { Col, Row } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getPDFViewURL } from 'modules/plans/services/AiSBCUploaderService';
import { MedicalPlanDocumentType } from 'modules/plans/constants';
import { useAppSelector } from 'hooks/redux';
import { convertEnumToOptions } from 'util/commonUtil';
import PlanDocumentUploader from 'modules/plans/components/SBCDocumentUploader/PlanDocumentUploader';
import Option from 'model/Option';
import { getPlanDocument } from 'modules/plans/services/RetirementPlanService';
import { getDownloadFileObj } from 'util/fileUtil';

import styles from './editPlanDocuments.module.less';

type EditPlanDocumentsProps = {
  onUpload: Function;
  onFileRemove: Function;
  documents: { [key: string]: string };
  documentReferences: any;
  planDocumentTypes: { [key: string]: Option };
  planId?: string;
  benefitKind?: string;
  onValidateFails?: (validateSetting: string) => void;
  isSaveDisabled?: boolean;
  onFileRemoveAction?: Function;
  isReview?: boolean;
};

interface DocumentReference {
  uploading: boolean;
  reference: string | null | undefined;
  blobUrl: string;
  fileName: string;
  documentUploadStatus: string;
}

interface PlanDocumentTypes {
  [key: string]: Option;
}

const EditPlanDocuments = forwardRef((props: EditPlanDocumentsProps, ref) => {
  const {
    documentReferences,
    documents,
    planDocumentTypes,
    onUpload,
    onFileRemove,
    planId,
    benefitKind,
    onValidateFails,
    isSaveDisabled = false,
    onFileRemoveAction,
    isReview,
  } = props;

  const [isRemoved, setIsRemoved] = useState<{ [key: string]: boolean }>({});
  const { jobId } = useAppSelector((state) => state.plan.aiSbc);

  const documentTypes = convertEnumToOptions(planDocumentTypes);

  const isSBCFlow = (docType: string) => {
    return Boolean(
      docType === MedicalPlanDocumentType.PLAN_SUMMARY.value && jobId
    );
  };

  const validateDocumentReferences = (
    documentReferences: DocumentReference[],
    planDocumentTypes: PlanDocumentTypes
  ): { isComplete: boolean; isValid: boolean } => {
    const isComplete = Object.values(planDocumentTypes).every((docType) => {
      const docRef = documentReferences[docType.value];
      return docRef && !isEmpty(docRef.reference);
    });

    const isValid = true;
    return { isComplete, isValid };
  };

  useImperativeHandle(ref, () => ({
    resetAll() {
      documentTypes.forEach((type) => onRemove(type.value));
    },

    validate() {
      return validateDocumentReferences(documentReferences, planDocumentTypes);
    },
  }));

  const onRemove = (docType: string) => {
    if (isSBCFlow(docType)) return;
    setIsRemoved((prevState) => ({
      ...prevState,
      [docType]: true,
    }));

    if (
      (!isEmpty(documentReferences) && !isEmpty(documentReferences[docType])) ||
      documents[docType]
    ) {
      onFileRemove(docType);
    }
  };

  const downloadFileObj = async (data: File, docType: string) => {
    let url = window.URL.createObjectURL(data);

    if (isSBCFlow(docType)) {
      url = getPDFViewURL(jobId!);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';

      a.href = url;
      a.download = data.name;
      a.click();
      window.URL.revokeObjectURL(url);
      return;
    }

    if (
      planId &&
      benefitKind &&
      documents[docType] &&
      documentReferences[docType] === undefined &&
      !isReview
    ) {
      const response = await getPlanDocument(planId, docType, benefitKind);
      const data = new File([response.data], documents[docType], {
        type: 'application/pdf',
      });

      getDownloadFileObj(data);
    }

    if (isReview) {
      getDownloadFileObj(data);
    }

    if (documentReferences[docType]) {
      getDownloadFileObj(data);
    }
  };

  const disableFileSelection = () => {
    let isDisable = false;
    if (!isEmpty(documentReferences)) {
      Object.keys(documentReferences).forEach((doc) => {
        if (documentReferences[doc].uploading) {
          isDisable = true;
        }
      });
    }
    return isDisable;
  };

  return (
    <div className={`${styles.planDocumentsWrapper} plan-document-wrapper`}>
      {documentTypes.map((docType, index) => {
        const blobUrl = get(
          documentReferences,
          [docType.value, 'blobUrl'],
          undefined
        );
        const reference = get(
          documentReferences,
          [docType.value, 'reference'],
          undefined
        );
        const fileName = get(
          documentReferences,
          [docType.value, 'fileName'],
          undefined
        );
        let file;
        const savedFileName = get(documents, docType.value);
        if (savedFileName) {
          file = new File([''], savedFileName);
        }
        if (blobUrl) {
          file = new File([blobUrl], fileName);
        }

        return (
          <Row key={reference ?? index} gutter={6}>
            <Col span={6}>
              <div className={styles.docType}> {docType.label}</div>
            </Col>
            <Col span={18}>
              <PlanDocumentUploader
                onChange={(e: File) => {
                  setIsRemoved((prevState) => ({
                    ...prevState,
                    [docType.value]: false,
                  }));
                  onUpload(e, docType.value);
                }}
                onRemove={() => {
                  onRemove(docType.value);
                  onFileRemoveAction?.();
                }}
                onFileNameClick={(file: File) =>
                  downloadFileObj(file, docType.value)
                }
                isLoading={get(
                  documentReferences,
                  [docType.value, 'uploading'],
                  false
                )}
                allowedFileTypes="application/pdf"
                maxFileSizeMB={100}
                isRemoved={isRemoved[docType.value]}
                file={file}
                docType={docType.value}
                onValidateFails={onValidateFails}
                isDisable={isSaveDisabled || isSBCFlow(docType.value)}
                isDisableFileSelection={
                  disableFileSelection() || isSBCFlow(docType.value)
                }
              />
            </Col>
          </Row>
        );
      })}
    </div>
  );
});

EditPlanDocuments.displayName = 'EditPlanDocuments';

export default EditPlanDocuments;
