import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Spin, Typography } from 'antd';
import { useAppSelector } from 'hooks/redux';
import AnalyticsHeader from 'modules/issueslog/components/AnalyticsHeader/AnalyticsHeader';
import AnalyticsTable from 'modules/issueslog/components/AnalyticsTable/AnalyticsTable';
import SummaryInfo from 'modules/issueslog/components/SummaryInfo/SummaryInfo';
import {
  ANALYTICS_TAB,
  BR_ADMIN_ANALYTICS,
  ER_ANALYTICS,
} from 'modules/issueslog/constants/IssuesLogConstants';
import {
  singleSelectAllOptions,
  IndividualType,
} from 'constants/commonConstants';
import { getDuration, issueType } from 'modules/issueslog/utils/issueLogUtils';
import {
  useCheckIfIssueExistForAnalyticsQuery,
  useGetAllIndividualsByOrganizationIdAndLocationsQuery,
  useGetAnalyticsAdminDataQuery,
  useGetAnalyticsEmployerDataQuery,
  useGetAnalyticsSummaryQuery,
  useGetArchivedSupportTeamsQuery,
  useGetEmployersByBrokerIdQuery,
  useGetIndividualByIdQuery,
  useGetOrganizationByIdQuery,
} from 'modules/issueslog/slices/issuesLogSlice';
import IssueVelocity from 'modules/issueslog/components/IssueVelocity/IssueVelocity';
import CustomerSpinner from 'components/Spinner/CustomSpinner';
import NoApplicableAnalytics from 'modules/issueslog/components/IssueVelocity/NoIssueVelocity/NoApplicableAnalytics';
import { getSortOptionsCaseInsensitive } from 'util/arrayUtil';
import styles from './analytics.module.less';

const { Text } = Typography;

type Props = {
  tab: string;
};

const Analytics = (props: Props) => {
  const { tab } = props;
  const params = useParams();
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const [supportGroup, setSupportGroup] = useState<string | null>(null);

  const [filterEmployerSelectedValue, setFilterEmployerSelectedValue] =
    useState<string | null>(null);
  const [filterAdminSelectedValue, setFilterAdminSelectedValue] = useState<
    string | null
  >(null);

  const {
    data: availabilityData,
    isSuccess,
    isLoading,
    isError,
    refetch: refetchAvailabilityData,
  } = useCheckIfIssueExistForAnalyticsQuery({
    issueType: params.type?.toUpperCase(),
    supportTeam:
      supportGroup == singleSelectAllOptions ? undefined : supportGroup,
    brokerId: params.brokerId,
  });

  const {
    data: supportTeams,
    isFetching: supportTeamsLoading,
    refetch: supportTeamsRefetch,
  } = useGetOrganizationByIdQuery(
    {
      brokerId: String(params.brokerId),
    },
    { skip: params.type !== issueType.SUPPORT }
  );

  const {
    data: individualData,
    isFetching: individualDataIsFetching,
    refetch: individualDataRefetch,
  } = useGetIndividualByIdQuery({
    individualId: appBootInfo?.individualId ?? '',
  });

  const {
    data: employerData,
    isFetching: employerDataIsFetching,
    refetch: employerDataRefetch,
  } = useGetEmployersByBrokerIdQuery(
    {
      brokerId: appBootInfo?.organizationId ?? '',
      individualId: null,
    },
    { skip: individualDataIsFetching }
  );

  const {
    data: brokerAdminData,
    isFetching: brokerAdminDataIsFetching,
    refetch: brokerAdminDataRefetch,
  } = useGetAllIndividualsByOrganizationIdAndLocationsQuery(
    {
      type: IndividualType.ORGANIZATION_ADMIN,
      brokerID: appBootInfo?.organizationId ?? '',
      locations: individualData?.locations ?? [],
    },
    { skip: individualDataIsFetching }
  );

  const {
    data: analyticsEmployerTableData,
    isFetching: analyticsEmployerTableIsFetching,
    refetch: analyticsEmployerTableRefetch,
  } = useGetAnalyticsEmployerDataQuery({
    brokerID: appBootInfo?.organizationId ?? '',
    type: params?.type?.toUpperCase() ?? '',
    adminId: filterEmployerSelectedValue,
    supportTeam: supportGroup,
  });

  const {
    data: analyticsAdminTableData,
    isFetching: analyticsAdminTableIsFetching,
    refetch: analyticsAdminTableRefetch,
  } = useGetAnalyticsAdminDataQuery({
    brokerID: appBootInfo?.organizationId ?? '',
    type: params?.type?.toUpperCase() ?? '',
    employerId: filterAdminSelectedValue,
    supportTeam: supportGroup,
  });

  const {
    data: analyticsSummaryData,
    isFetching: analyticsSummaryIsFetching,
    refetch: analyticsSummaryRefetch,
  } = useGetAnalyticsSummaryQuery({
    brokerID: appBootInfo?.organizationId ?? '',
    type: params?.type?.toUpperCase() ?? '',
    supportTeam: supportGroup,
  });

  const {
    data: allArchivedSupportTeams,
    isFetching: allArchivedSupportLoading,
    refetch: refetchArchivedSupportTeams,
  } = useGetArchivedSupportTeamsQuery({
    brokerID: String(params.brokerId),
  });

  useEffect(() => {
    if (tab === ANALYTICS_TAB) {
      refetchAvailabilityData();
      analyticsEmployerTableRefetch();
      analyticsAdminTableRefetch();
      analyticsSummaryRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, supportGroup]);

  const reset = () => {
    supportTeamsRefetch();
    individualDataRefetch();
    employerDataRefetch();
    brokerAdminDataRefetch();
    analyticsEmployerTableRefetch();
    analyticsAdminTableRefetch();
    analyticsSummaryRefetch();
    refetchArchivedSupportTeams();
  };

  useEffect(() => {
    if (tab === ANALYTICS_TAB) reset();
    // eslint-disable-next-line
  }, [tab, tab]);

  useEffect(() => {
    analyticsEmployerTableRefetch();
    // eslint-disable-next-line
  }, [filterEmployerSelectedValue, analyticsEmployerTableRefetch]);

  useEffect(() => {
    analyticsAdminTableRefetch();
    // eslint-disable-next-line
  }, [filterAdminSelectedValue, analyticsAdminTableRefetch]);

  const getSupportTeams = () => {
    const optionArray: any[] =
      supportTeams?.issuesLogSupportEmails?.map((supportTeam: any) => ({
        value: supportTeam?.name,
        label: supportTeam?.name,
      })) || [];
    return optionArray;
  };

  const getArchivedTeams = (): any[] => {
    const optionArray: any[] =
      allArchivedSupportTeams?.map((supportTeam: any) => ({
        value: supportTeam,
        label: supportTeam,
      })) || [];
    return optionArray;
  };

  const getEmployers = () => {
    const optionArray: any[] =
      employerData?.map((employer: any) => ({
        value: employer?.id,
        label: employer?.name,
      })) || [];
    getSortOptionsCaseInsensitive(optionArray, 'label');
    return optionArray;
  };

  const getBrokerAdmins = () => {
    const optionArray: any[] =
      brokerAdminData?.map((broker: any) => ({
        value: broker?.id,
        label: broker?.firstName + ' ' + broker?.lastName,
      })) || [];
    getSortOptionsCaseInsensitive(optionArray, 'label');
    return optionArray;
  };

  const summaryHeaderList = () => {
    const returnObjArr = [];
    const summaryData = [
      {
        label: 'Avg. First Response Time',
        amount: getDuration(analyticsSummaryData?.avgFirstResponseTime ?? 0),
      },
      {
        label: 'Avg. Resolution Time',
        amount: getDuration(analyticsSummaryData?.avgResolutionTime ?? 0),
      },
    ];
    returnObjArr.push(
      summaryData.map(({ label, amount }, index) => (
        <Col
          key={`header-${index}`}
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          lg={{ span: 6 }}
          md={{ span: 6 }}
        >
          <SummaryInfo
            topLabel={label}
            amount={amount}
            toolTipPosition={'top'}
            isLoading={analyticsSummaryIsFetching}
          />
        </Col>
      ))
    );
    return returnObjArr;
  };

  if (
    supportTeamsLoading ||
    individualDataIsFetching ||
    employerDataIsFetching ||
    brokerAdminDataIsFetching ||
    allArchivedSupportLoading
  ) {
    return <Spin></Spin>;
  }

  const handleSupportTeamDropDownChange = (val: string) => {
    setSupportGroup(val);
  };

  return !isError ? (
    isLoading ? (
      <CustomerSpinner />
    ) : isSuccess && availabilityData ? (
      <>
        <AnalyticsHeader
          supportTeamDropDown={handleSupportTeamDropDownChange}
          summaryDetail={summaryHeaderList()}
          supportTeams={getSupportTeams()}
          isBadgesApplicable={availabilityData}
          archivedSupportTeams={getArchivedTeams()}
        />
        {params.type === issueType.ACCOUNT && (
          <Text className={styles.metricsVerbiage}>
            Metrics only include items initiated by non-broker users with
            employer client email domain.
          </Text>
        )}
        <div className={styles.issueVelocity}>
          <IssueVelocity
            isAccount={params.type === issueType.ACCOUNT}
            employerData={getEmployers()}
            supportGroup={supportGroup}
          />
        </div>
        <AnalyticsTable
          type={ER_ANALYTICS}
          tab={tab}
          filterData={getBrokerAdmins()}
          tableData={analyticsEmployerTableData}
          isLoading={analyticsEmployerTableIsFetching}
          setFilterValue={setFilterEmployerSelectedValue}
          selectedSupport={supportGroup}
        />
        <AnalyticsTable
          type={BR_ADMIN_ANALYTICS}
          tab={tab}
          filterData={getEmployers()}
          tableData={analyticsAdminTableData}
          isLoading={analyticsAdminTableIsFetching}
          setFilterValue={setFilterAdminSelectedValue}
          selectedSupport={supportGroup}
        />
      </>
    ) : (
      <>
        <AnalyticsHeader
          archivedSupportTeams={getArchivedTeams()}
          supportTeamDropDown={handleSupportTeamDropDownChange}
          summaryDetail={summaryHeaderList()}
          supportTeams={getSupportTeams()}
          isBadgesApplicable={false}
        />
        <NoApplicableAnalytics />
      </>
    )
  ) : (
    <NoApplicableAnalytics />
  );
};
export default Analytics;
