import { clearInterval, setInterval } from 'timers';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';

import Dragger from 'antd/lib/upload/Dragger';

import { Button, Col, Input, Menu, Row, Select, UploadProps } from 'antd';
import {
  FileAddTwoTone,
  InboxOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { debounce, toUpper } from 'lodash';
import axios from 'axios';
import LinkButton from 'components/buttons/LinkButton/LinkButton';

import { DataColumn } from 'components/DataTable/DataColumn';
import ToolList from 'components/ToolList/ToolList';
import TablePagination from 'model/TablePagination';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';

import RFPIcon from 'assets/images/Rfp-star-icon.svg';
import { ReactComponent as RfpIconSelected } from 'assets/images/Rfp-ai-selected.svg';

import ExportWord from 'assets/images/export-DOC.svg';
import AttachmentIcon from 'assets/images/icon-attachment.svg';

import AddRfpmodal from 'modules/rfp/components/AddRfpmodal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import {
  addTagsToTrainFIle,
  deleteRetrainData,
  deleteRfp,
  getCompletedTrainings,
  getRfpFile,
  getRfpList,
  getTrainingStatus,
  renameTrainFile,
  retrainAiUpload,
  downloadRfpTrainFile,
} from 'modules/rfp/services/RfpService';
import FixedAlertMessage from 'components/Alert/FixedAlert/FixedAlertMessage';
import loaderGif from 'assets/images/rfp-loading-file-white-background.gif';
import {
  ADD_TO_TRAIN_SET_MESSAGE,
  FILTER_RFP_BY_DATE,
  RETRAIN_INFO_MESSAGE,
  RFP_ACTIONS,
  RFP_RETRAIN_SUCCESS,
  RFP_RETRAIN_TOOLTIP_PROMPT,
  RFP_TRAIN_ACTIONS,
  RFP_TRAIN_TOOLTIP_PROMPT,
  TRAINING_AI_HOVER,
  DEFAULT_UPLOADED_AT_FILTER_VALUES,
  NOT_ALLOWED_FILES_MESSAGE,
  TAG_ERROR,
  RFP_TRAIN_OVER_TEN_PAGES,
  RFP_QNA_FORMAT_ONLY,
  RFP_PROCESSING_DELAYED_MESSAGE,
} from 'modules/rfp/constants/RfpConstants';
import { useGetRfpMutation } from 'modules/rfp/slices/rfpSlice';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  getAvailableSystemTags,
  setClearState,
  setCompletedTrainData,
  setRfpFailed,
  setRfpList,
  setRfpQuestions,
  setSelectedRfpId,
  setSelectedTrainFile,
  setTagsError,
  setTrainData,
} from 'modules/rfp/slices/rfpQuestionSlice';
import { useGetLocationsQuery } from 'api/featureControl';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import {
  exportToTxt,
  formatDate,
  formatDateAndTime,
  getQuestions,
} from 'modules/rfp/utils/exportUtil';
import LoadingStrip from 'modules/rfp/components/RfpForm/ReviewResponse/LoadingStrip/LoadingStrip';
import { isNullOrUndefined } from 'modules/plans/utils';
import RfpActionTag from 'modules/rfp/components/RfpForm/ActionTag/RfpStatusTag';
import { ReactComponent as PopoutIcon } from 'assets/images/popout-icon-blue.svg';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import DataTable from 'components/DataTable/DataTable';
import NextButton from 'components/buttons/NextButton/NextButton';
import FilterDropDown from 'components/FilterDropDown/FilterDropDown';
import SearchBar from 'components/SearchBar/SearchBar';
import ListComponent from 'components/Helpers/ListComponent/ListComponent';
import { getAllTags, updateTag } from 'modules/rfp/services/RfpTagService';
import ConfirmDeleteTagModal from 'modules/tools/components/RfpListComponents/ConfirmDeleteTagModal/ConfirmDeleteTagModal';
import RfpTag from 'modules/rfp/components/RfpForm/RfpTag/RfpTag';
import ConfirmDeleteTrainFileModal from 'modules/tools/components/RfpListComponents/ConfirmDeleteTrainFileModal/ConfirmDeleteTrainFileModal';
import AddNewTagModal from 'modules/tools/components/RfpListComponents/AddNewTagModal/AddNewTagModa';
import { useGetAllBrokerAdminsQuery } from 'modules/issueslog/slices/issuesLogSlice';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';

import SelectOption from 'model/SelectOption';

import styles from './featureList.module.less';

const initialPaginationTrainingConfig: TablePagination = {
  sorterInfo: {
    columnKey: 'documentName',
    field: 'documentName',
    order: 'ascend',
  },
  paginationIndex: 1,
  filterInfo: {
    limit: 10,
    offset: 0,
    searchText: '',
  },
  filters: {},
};

type EmployerRfpListProps = {};

const EmployerRfpList: FC<EmployerRfpListProps> = () => {
  const { brokerId } = useParams();
  const adminLocations = useAppSelector(
    (state) => state.auth.auth?.appBootupInfo?.locationIds ?? []
  );
  const selectedRfpId = useAppSelector((state) => state?.rfp?.selectedRfpId);
  const selectedTrainFile = useAppSelector(
    (state) => state?.rfp?.selectedTrainFile
  );

  const dispatch = useAppDispatch();
  const systemTags = useAppSelector((state) => state?.rfp?.tags);

  const [searchText, setSearchText] = useState<string>('');
  const [searchTrainText, setSearchTrainText] = useState<string>('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState('name');
  const [retrainAi, setRetrainAi] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [loadingflag, setLoadingFlag] = useState<boolean>(false);

  const [selectedRfpName, setSelectedRfpName] = useState<string>('');
  const [selectedRetrainId, setSelectedRetrainId] = useState<string>('');

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [aiRetrainedAlert, setAiRetrainedAlert] = useState<boolean>(false);
  const [showRetrainWarning, setShowRetrainWarning] = useState<boolean>(false);

  const [isTrainingCompleted, setTrainingComplete] = useState<boolean>(false);
  const [hasTrainingData, setHasTrainingData] = useState<boolean>(false);

  const [deleteConfirmRfp, setDeleteConfirmRfp] = useState<boolean>(false);

  const [renameTrainRfp, setRenameTrainRfp] = useState<boolean>(false);

  const [renameFileValue, setRenameFileValue] = useState<string>('');

  const [rfpSavedAlert, setRfpSavedAlert] = useState<boolean>(false);
  const [isErrorFile, setIsErrorFile] = useState<boolean>(false);

  interface TrainTagState {
    alreadyAdded: any;
    temporary: any;
  }

  interface EditTagState {
    show: boolean;
    item: any;
  }

  const [editTagsModal, setEditTagsModal] = useState<boolean>(false);
  const [addToTrainModal, setAddToTrainModal] = useState<boolean>(false);
  const [manageTagsModal, setManageTagsModal] = useState<boolean>(false);
  const [addTagModal, setAddTagModal] = useState<boolean>(false);
  const [newTagValue, setNewTagValue] = useState<string>('');

  const [selectedTagValue, setSelectedTagValue] = useState<string>('');

  const [filterTagSearch, setFilterTagSearch] = useState<string>('');
  const [checkedTagList, setCheckedTagList] = useState<any[]>([]);

  const [paginationConfigTraining, setPaginationConfigTraining] =
    useState<TablePagination>(initialPaginationTrainingConfig);

  const { paginationIndex, filterInfo } = paginationConfigTraining;
  const { limit, offset } = filterInfo;

  const startIndex = (paginationIndex - 1) * limit + offset;
  const endIndex = startIndex + limit;

  interface TrainTagState {
    alreadyAdded: any;
    temporary: any;
  }
  const initialTagState = {
    alreadyAdded: [],
    temporary: [],
  };

  const [trainFileAddedTags, setTrainFileAddedTags] =
    useState<TrainTagState>(initialTagState);

  interface EditTagState {
    show: boolean;
    item: any;
  }

  const initialEditTag = {
    show: false,
    item: null,
  };

  const [editSingleTagModal, setEditSingleTagModal] =
    useState<EditTagState>(initialEditTag);
  const [confirmDeleteTag, setConfirmDeleteTag] = useState<boolean>(false);

  const [isDisableTrainUpload, setIsDisableTrainUpload] =
    useState<boolean>(false);

  const [addedTags, setAddedTags] = useState<any[]>([]);

  const { data: allBrokerAdminsData, isLoading: isLoadingBrokerData } =
    useGetAllBrokerAdminsQuery({
      organizationId: brokerId!,
      onlyActiveIndividuals: false,
    });
  const { data: allLocations, refetch: refetchAllLocations } =
    useGetLocationsQuery({
      organizationId: brokerId!,
      key: 'RFP',
    });

  const [rfpTrainingHistory, setRfpTrainingHistory] = useState<any[]>([]);
  const [featureWrapperContent, setFeatureWrapperContent] = useState<any[]>([]);
  const [filteredRFPData, setFilteredRFPData] = useState<any[]>([]);

  const [completedTrainingsCount, setCompletedTrainingsCount] =
    useState<number>(0);

  const [fileList, setFileList] = useState<File[]>([]);

  const trainingDataCheckingInterval = useRef<any>();
  const fetchRfpListInterval = useRef<any>();

  const initialState = {
    type: undefined,
    data: null,
  };

  interface RfpModalState {
    type: any;
    data: any;
  }

  const [modalSwitchState, setModalSwitchState] =
    useState<RfpModalState>(initialState);

  const [filteredRfpTrainingHistory, setFilteredRfpTrainingHistory] = useState<
    any[]
  >([]);
  const [uploadedAtFilterValues, setUploadedAtFilterValues] = useState<any[]>(
    DEFAULT_UPLOADED_AT_FILTER_VALUES
  );
  const [uploadedByFilterValues, setUploadedByFilterValues] = useState<any[]>(
    []
  );
  const [tagsFilterValues, setTagsFilterValues] = useState<any[]>([]);

  const [locationDropdownOptions, setLocationDropdownOptions] = useState<
    SelectOption[]
  >([]);
  const [selectedLocationValues, setSelectedLocationValues] = useState<
    string[]
  >([]);
  const [uploadLocationValue, setUploadLocationValue] = useState<string>('');

  const [getRfp, { data: featureWrappers = [], isLoading }] =
    useGetRfpMutation();

  const rfpTagSelectRef = useRef<any>(null);

  const isUserInMultipleLocations =
    isEmpty(adminLocations) || adminLocations.length > 1;

  const getFetchedLocations = () => {
    let fetchedLocations = [];
    if (!isEmpty(adminLocations)) {
      fetchedLocations = adminLocations ?? [];
    } else {
      fetchedLocations =
        allLocations?.locationFeatures?.map((location: any) => location.id) ??
        [];
    }

    if (!isEmpty(selectedLocationValues)) {
      fetchedLocations = selectedLocationValues;
    }

    return fetchedLocations;
  };

  const refetch = () =>
    getRfp({
      page: pageIndex,
      brokerId: brokerId as string,
      key: 'RFP',
      query: searchText,
      size: pageSize,
      sort: sortOrder,
      locationIds: getFetchedLocations(),
    });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageIndex,
    brokerId,
    searchText,
    pageSize,
    sortOrder,
    selectedLocationValues,
  ]);

  useEffect(() => {
    if (allLocations) {
      const options = allLocations?.locationFeatures
        .filter(({ enabled }: any) => enabled)
        .filter(
          ({ id }: any) =>
            adminLocations.includes(id) || isEmpty(adminLocations)
        )
        .map(
          ({ id, locationName }: any) =>
            ({ label: locationName, value: id } as SelectOption)
        );
      setLocationDropdownOptions(options);
    }
    // Without this lint react will complain about adminLocations not being in the
    // dependency list. When adminLocation were included, re-renders kept
    // continuosly happening.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLocations]);

  useEffect(() => {
    setUploadLocationValue(isUserInMultipleLocations ? '' : adminLocations[0]);
  }, [retrainAi, adminLocations, isUserInMultipleLocations]);

  const searchTagPredicate = (tag: any) => {
    return (
      isEmpty(filterTagSearch.trim()) ||
      tag.tagName.toLowerCase().includes(filterTagSearch.toLowerCase())
    );
  };

  const searchTextPredicate = (item: any, searchText: string) => {
    // Check if searchText is empty, if so, return true to include all items
    if (!searchText) return true;
    const lowerSearchText = searchText?.toLowerCase();
    const nameMatch = item?.documentName
      ?.toLowerCase()
      ?.includes(lowerSearchText);
    const tagMatch = item?.tags?.some((tag: any) =>
      tag?.tagName?.toLowerCase()?.includes(lowerSearchText)
    );
    // Return true if either nameMatch or tagMatch is true
    return nameMatch || tagMatch;
  };

  const uploadedAtPredicate = (record: any) => {
    if (!uploadedAtFilterValues?.length) return false;
    const uploadedAt = dayjs(record?.createdTs);

    const rangedDates = uploadedAtFilterValues.map(([a, b]) => [
      dayjs().subtract(a, 'd'),
      dayjs().subtract(b, 'd'),
    ]);

    return rangedDates.some(([a, b]) => {
      if (a.isBefore(b)) {
        // Normal case
        return uploadedAt.isAfter(b) && uploadedAt.isBefore(a);
      } else {
        // Handle leap year case
        return (
          (uploadedAt.isAfter(b) && uploadedAt.isBefore(a)) ||
          (uploadedAt.month() === 2 && uploadedAt.date() === 29)
        );
      }
    });
  };

  const tagsFilterPredicate = (record: any) => {
    if (!tagsFilterValues?.length) return true;

    const tagMatch = record?.tags?.some((tag: any) =>
      tagsFilterValues.includes(tag?.id)
    );

    return tagMatch;
  };

  const uploadedByPredicate = (record: any) => {
    if (!uploadedByFilterValues?.length) return true;

    const isUploadedBy = uploadedByFilterValues
      .map((email) => email?.toLowerCase())
      .some((email) => email === record?.lastUpdatedBy?.toLowerCase());
    return isUploadedBy;
  };

  const locationEqualsPredicate = (record: any) => {
    if (!adminLocations?.length) return true;
    return adminLocations.includes(record?.locationId);
  };

  const applyFilters = () => {
    const temp = rfpTrainingHistory.filter(
      (items) =>
        searchTextPredicate(items, searchTrainText) &&
        uploadedAtPredicate(items) &&
        uploadedByPredicate(items) &&
        tagsFilterPredicate(items) &&
        locationEqualsPredicate(items)
    );

    setFilteredRfpTrainingHistory(temp);

    // This is to set the pagination to largest possible page index
    const pageCount = Math.ceil(temp.length / pageSize) || 1;

    if (pageCount <= paginationConfigTraining.paginationIndex) {
      setPaginationConfigTraining((prev) => ({
        ...prev,
        paginationIndex: pageCount,
      }));
    }
  };

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rfpTrainingHistory,
    uploadedAtFilterValues,
    uploadedByFilterValues,
    tagsFilterValues,
    searchTrainText,
  ]);

  // eslint-disable-next-line
  const debouncedRefetchAndPing = useCallback(
    debounce(() => {
      refetch();
      startPingRfpStatus();
    }, 100),
    [allLocations, selectedLocationValues]
  ); // Empty dependency array means this is created only once

  useEffect(() => {
    // Call the debounced function every time the component is rendered
    refetchAllLocations();
    if (!isEmpty(allLocations)) {
      debouncedRefetchAndPing();
    }

    return () => debouncedRefetchAndPing.cancel();
  }, [debouncedRefetchAndPing, allLocations, refetchAllLocations]);

  useEffect(() => {
    pingTrainingStatus();
    startPingingTraingStatus();
    return () => {
      clearInterval(trainingDataCheckingInterval.current);
      clearInterval(fetchRfpListInterval.current);
    };
    // eslint-disable-next-line
  }, [brokerId]);

  const startPingingTraingStatus = () => {
    trainingDataCheckingInterval.current = setInterval(() => {
      pingTrainingStatus();
    }, 10000);
  };

  const startPingRfpStatus = () => {
    fetchRfpListInterval.current = setInterval(() => {
      pingRfpStatus();
    }, 10000);
  };

  const pingRfpStatus = async () => {
    const data = await getRfpList(
      brokerId!,
      1,
      pageSize,
      getFetchedLocations()
    );
    setFeatureWrapperContent(data?.data?.content);
  };

  const pingTrainingStatus = () => {
    getCompletedTrainings(brokerId!).then((data) => {
      dispatch(setCompletedTrainData(data?.data));
    });

    getTrainingStatus(brokerId || '').then(({ data }) => {
      setRfpTrainingHistory(
        data.sort(
          (a: any, b: any) =>
            new Date(b.createdTs).getTime() - new Date(a.createdTs).getTime()
        )
      );
      dispatch(setTrainData(data));

      setLoadingFlag(true);
      if (data?.length > 0) {
        setHasTrainingData(true);
      }

      const completedTrainings = data.filter(
        (training: any) => training.status === 'COMPLETED'
      );

      const failedTrainings = data.filter(
        (training: any) => training.status === 'FAILED'
      );

      setCompletedTrainingsCount(completedTrainings.length);

      if (
        data?.length ===
        completedTrainings?.length + failedTrainings?.length
      ) {
        setTrainingComplete(true);
        clearInterval(trainingDataCheckingInterval.current);
      } else {
        setTrainingComplete(false);
      }
    });
  };

  function editRfp(rfpId: string) {
    refetch();
    const rfp = featureWrapperContent?.filter((rfp: any) => rfp?.id === rfpId);
    setSelectedRfpName(rfp[0]?.name);
    if (!isNullOrUndefined(rfp[0]?.questionsAndAnswers)) {
      dispatch(setRfpQuestions(rfp[0]?.questionsAndAnswers));
      setIsEditModalOpen(true);
    }
  }

  // This action menu is shown rfp main list
  const rfpMainListActionmenu = (rfp: any) => {
    return (
      <Menu>
        {rfp?.status !== 'FAILED' && (
          <>
            <Menu.Item
              onClick={() => {
                pingTrainingStatus();
                dispatch(setSelectedRfpId(rfp?.id));
                setModalSwitchState({
                  type: RFP_ACTIONS.EDIT.value,
                  data: featureWrappers?.content,
                });
                setIsEditMode(true);
                editRfp(rfp?.id);
              }}
            >
              {RFP_ACTIONS.EDIT.label}
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                pingTrainingStatus();
                dispatch(setSelectedRfpId(rfp?.id));
                setModalSwitchState({
                  type: RFP_ACTIONS.EXPORT.value,
                  data: featureWrappers?.content,
                });
              }}
            >
              {RFP_ACTIONS.EXPORT.label}
            </Menu.Item>
          </>
        )}
        <>
          <Menu.Item
            onClick={() => {
              pingTrainingStatus();
              dispatch(setSelectedRfpId(rfp?.id));
              setModalSwitchState({
                type: RFP_ACTIONS.DELETE.value,
                data: featureWrappers?.content,
              });
            }}
          >
            {RFP_ACTIONS.DELETE.label}
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              pingTrainingStatus();
              dispatch(setSelectedRfpId(rfp?.id));
              setModalSwitchState({
                type: RFP_ACTIONS.DOWNLOAD.value,
                data: featureWrappers?.content,
              });
              getRfpFile(brokerId!, rfp?.rfpFileName, rfp?.id);
            }}
          >
            {RFP_ACTIONS.DOWNLOAD.label}
          </Menu.Item>
        </>
      </Menu>
    );
  };

  // Show the menu for delayed processing
  const rfpProcessingDelayedMenu = (rfp: any) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            pingTrainingStatus();
            dispatch(setSelectedRfpId(rfp?.id));
            setModalSwitchState({
              type: RFP_ACTIONS.DELETE.value,
              data: featureWrappers?.content,
            });
          }}
        >
          {RFP_ACTIONS.DELETE.label}
        </Menu.Item>
      </Menu>
    );
  };

  // Data columns for Main rfp table
  const dataColumns: DataColumn[] = [
    {
      title: 'RFP NAME',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
      render: (item, rfp) => {
        return (
          <>
            {item.length > 50 ? (
              <OverflowPopover>
                <Row justify="start" gutter={12}>
                  <Col>
                    <b>
                      <div
                        className={
                          rfp?.status === 'FAILED'
                            ? styles.disabledFileName
                            : ''
                        }
                      >
                        {item}
                      </div>
                    </b>
                  </Col>
                </Row>
              </OverflowPopover>
            ) : (
              <Row justify="start" gutter={12}>
                <Col>
                  <b>
                    <div
                      className={
                        rfp?.status === 'FAILED' ? styles.disabledFileName : ''
                      }
                    >
                      {item}
                    </div>
                  </b>
                </Col>
              </Row>
            )}
            <>
              {rfp?.status === 'FAILED' || rfp?.status === 'COMPLETED' ? (
                <RfpActionTag />
              ) : (
                <></>
              )}
            </>
          </>
        );
      },
      showSorterTooltip: false,
    },
    isUserInMultipleLocations
      ? {
          title: 'LOCATION',
          dataIndex: 'location',
          key: 'location',
          width: '20%',
          sortDirections: ['ascend', 'descend', 'ascend'],
          showSorterTooltip: false,
          sorter: (a: any, b: any) => a.location?.localeCompare(b.location),
          render: (data, rfp) => (
            <OverflowPopover>{`${data || '-'}`}</OverflowPopover>
          ),
        }
      : ({} as any),
    {
      title: 'STATUS',
      dataIndex: 'statusValue',
      key: 'statusValue',
      width: '20%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.statusValue?.localeCompare(b.statusValue),
      render: (item, rfp) => {
        return (
          <>
            {rfp?.status === 'STARTED' || rfp?.status === 'EXTRACTED' ? (
              <p>
                <b>Processing Started...</b>
              </p>
            ) : rfp?.status === 'COMPLETED' ? (
              <div className={styles.readyForReview}>
                <b>Ready for Review</b>
              </div>
            ) : rfp?.status === 'FAILED' ? (
              <div className={styles.rfpFailedTag}>
                <div className={styles.rfpFailedStatus}>
                  <b>Processing Failed</b>
                </div>
                <PlanyearPopover
                  width={300}
                  placement="left"
                  content={
                    <>
                      <p>
                        {`RFP failures are rare events and don't necessarily indicate issues with your RFP. Delete this attempt and try again.  Please contact`}{' '}
                        <a
                          href="mailto:support@planyear.com"
                          className={styles.emailLinkClickableArea}
                        >
                          support@planyear.com
                        </a>
                        {` if this problem persists.`}
                      </p>
                    </>
                  }
                >
                  <QuestionCircleOutlined />
                </PlanyearPopover>
              </div>
            ) : rfp?.status === 'SAVED' ? (
              <>RFP Saved</>
            ) : rfp?.status === 'PROCESSING_DELAYED' ? (
              <div className={styles.rfpProcessingDelayedArea}>
                <>RFP Processing Delayed</>
                <div>
                  <PlanyearPopover
                    width={300}
                    placement="left"
                    content={RFP_PROCESSING_DELAYED_MESSAGE}
                  >
                    <QuestionCircleOutlined />
                  </PlanyearPopover>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'lastUpdatedTs',
      key: 'lastUpdatedTs',
      width: '30%',
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) =>
        a.lastUpdatedTs?.localeCompare(b.lastUpdatedTs),
      render: (_, item) => {
        return (
          <OverflowPopover>
            {formatDateAndTime(item?.lastUpdatedTs)} <br />
            by {item?.lastUpdatedByLoginId}
          </OverflowPopover>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (data, rfp) => {
        return (
          <>
            {rfp?.status === 'STARTED' || rfp?.status === 'EXTRACTED' ? (
              <SelectDropdown
                className={styles.rfpDisabledTagArea}
                overlay={<Menu></Menu>}
              />
            ) : rfp?.status === 'PROCESSING_DELAYED' ? (
              <SelectDropdown overlay={rfpProcessingDelayedMenu(rfp)} />
            ) : (
              <SelectDropdown overlay={rfpMainListActionmenu(rfp)} />
            )}
          </>
        );
      },
      showSorterTooltip: false,
    },
  ];

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setSearchText(_searchText);
    if (!isEmpty(_searchText.trim())) {
      const filteredContent = featureWrapperContent?.filter((item) => {
        const itemName = item?.name?.toLowerCase();
        return itemName?.includes(_searchText.trim().toLowerCase());
      });
      setFilteredRFPData(filteredContent);
    }
  };

  useEffect(() => {
    setPaginationConfig(paginationConfig);
    setPageIndex(paginationConfig.paginationIndex);
    setPageSize(paginationConfig.filterInfo.limit);
    const { field, order } = (paginationConfig.sorterInfo as any) || {};
    if (isEmpty(order)) {
      setSortOrder('');
    } else if (field == 'employerName' && order == 'descend') {
      setSortOrder('-name');
    } else if (field == 'employerName' && order == 'ascend') {
      setSortOrder('name');
    }
  }, [paginationConfig, isLoading]);

  const allowedFormats = ['doc', 'docx', 'pdf'];

  const uploadFile: UploadProps = {
    name: 'file',
    action: '',
    customRequest: (options) => {
      return new Promise((resolve, reject) => {
        // Simulating a file upload process
        setTimeout(() => {
          const response = {
            status: 'success',
            message: 'File uploaded successfully',
          };
          if (options.onSuccess) {
            options.onSuccess(response);
          }
          resolve(response);
        }, 10);
      });
    },
    onChange(info) {
      const { status, name } = info.file;

      setIsErrorFile(false);
      const fileExt = name.split('.').pop().toLowerCase();
      if (!allowedFormats.includes(fileExt)) {
        setIsErrorFile(true);
      } else {
        if (status !== 'uploading') {
          setFileList((prevFileList) => [...prevFileList, info.file]);
          setShowRetrainWarning(true);
        } else {
          setShowRetrainWarning(false);
        }
      }
    },
  };

  const deleteRetrainConfirm = (documentId: string) => {
    deleteRetrainData(brokerId!, documentId).then(() => {
      pingTrainingStatus();
    });
  };

  // This menu is for train ai modal actions
  const completedTrainMenu = (id: string, item: any) => {
    return (
      <Menu>
        {item?.status !== 'FAILED' && (
          <Menu.Item
            onClick={() => {
              setCheckedTagList(item?.tags.map((tag: any) => tag?.id));
              dispatch(setSelectedTrainFile(item));
              setTrainFileAddedTags({
                alreadyAdded: item?.tags,
                temporary: item?.tags,
              });
              setEditTagsModal(true);
            }}
          >
            {RFP_TRAIN_ACTIONS.EDIT.label}
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            dispatch(setSelectedTrainFile(item));
            setDeleteConfirmRfp(true);
            setSelectedRetrainId(id);
          }}
        >
          {RFP_TRAIN_ACTIONS.DELETE.label}
        </Menu.Item>
      </Menu>
    );
  };

  // Data columns for Train data
  const trainDataColumns: ColumnsType<any> = [
    {
      title: 'TRAINING FILE NAME',
      dataIndex: 'fileName',
      key: 'fileName',
      width: '40%',
      render: (text: any, rfp: any) => (
        <div className={styles.nameAndPopIconArea}>
          <div className={styles.popOutIcon}>
            <b>{text}</b>
            <PopoutIcon
              onClick={() => {
                downloadRfpTrainFile(rfp?.key, text);
              }}
            />
          </div>
          {rfp?.tags?.length ? (
            <div className={styles.mappedTagArea}>
              {rfp.tags.map((tag: any) => (
                <RfpTag key={tag?.tagName} tagValue={toUpper(tag?.tagName)} />
              ))}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
    isUserInMultipleLocations
      ? {
          title: 'LOCATION',
          dataIndex: 'location',
          key: 'location',
          width: '20%',
          render: (data) => data || '-',
        }
      : {},
    {
      title: 'STATUS',
      width: '20%',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'UPLOADED BY',
      dataIndex: 'createdTs',
      width: '30%',
      key: 'createdTs',
      render: (text: string, item: any) => {
        return (
          <>
            {formatDate(text)} <br />
            <div className={styles.lastEditedAreaDiv}>
              by {item?.lastUpdatedBy}
            </div>
          </>
        );
      },
    },
    {
      title: 'ACTIONS',
      key: 'key',
      width: '10%',
      dataIndex: 'key',
      render: (id: string, item: any) => {
        return (
          <div className={styles.singleSelectFilterDiv}>
            {item?.status === 'STARTED' ? (
              <SelectDropdown
                className={styles.rfpDisabledTagArea}
                overlay={<Menu></Menu>}
              />
            ) : (
              <SelectDropdown overlay={completedTrainMenu(id, item)} />
            )}
          </div>
        );
      },
    },
  ];

  const mappedData = filteredRfpTrainingHistory.map((item: any) => ({
    key: item?.id,
    fileName: item?.documentName,
    status: item?.status,
    tags: item?.tags,
    createdTs: item?.createdTs,
    lastUpdatedBy: item?.lastUpdatedBy,
    location: item.location,
  }));

  const addedTagList = addedTags
    .filter(searchTagPredicate)
    ?.map((tag: any) => ({
      label: toUpper(tag?.tagName),
      value: tag?.id,
      type: 'tag',
    }));

  const isTrainProgressed =
    rfpTrainingHistory.some((item) => item && item.status === 'STARTED') ||
    isEmpty(rfpTrainingHistory);

  const isStarted = rfpTrainingHistory.some(
    (item) => item && item.status === 'STARTED'
  );

  const isAllFailed = rfpTrainingHistory.every(
    (item) => item && item.status === 'FAILED'
  );

  const isSomeFailed = rfpTrainingHistory.some(
    (item) => item && item.status === 'FAILED'
  );

  const trainAiBool =
    !isEmpty(featureWrappers?.content) || !isEmpty(rfpTrainingHistory);

  useEffect(() => {
    setFeatureWrapperContent(featureWrappers?.content);
    dispatch(setRfpList(featureWrappers?.content));
    // eslint-disable-next-line
  }, [featureWrappers]);

  const handleTrainSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setPaginationConfigTraining(initialPaginationTrainingConfig);
    setSearchTrainText(_searchText);
  };

  const manageTagsClicked = () => {
    getAllTags(brokerId!).then((response: any) => {
      setAddedTags(response);
    });
    dispatch(getAvailableSystemTags(brokerId!));
  };

  const filterAddedTags = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();
    setFilterTagSearch(_searchText);
  };

  // clears filter when modal is closed
  const clearSearchFilters = () => {
    setSearchTrainText('');
    setUploadedAtFilterValues(DEFAULT_UPLOADED_AT_FILTER_VALUES);
    setUploadedByFilterValues([]);
    setTagsFilterValues([]);
  };

  // reset pagination
  const resetPaginationConfig = () => {
    setPaginationConfigTraining(initialPaginationTrainingConfig);
  };

  const getRfpTagList = (value: any[]) => {
    const objArray = value.map((id) => {
      const tag = addedTags.find((tag) => tag?.id === id);
      return tag ? tag : null;
    });
    return objArray;
  };

  const getData = (filters: TablePagination) => {
    const paginationConfigData = {
      paginationIndex: filters?.paginationIndex,
      filterInfo: {
        limit: filters?.filterInfo?.limit,
        offset: filters?.filterInfo?.offset,
        searchText: searchText,
      },
      filters: filters.filters,
    } as TablePagination;
    setPaginationConfigTraining(paginationConfigData);
  };

  const handleStartUploadClick = () => {
    setIsDisableTrainUpload(true);
    retrainAiUpload(fileList, brokerId!, uploadLocationValue)
      .then(() => {
        pingTrainingStatus();
        startPingingTraingStatus();
        setRetrainAi(false);
        setIsDisableTrainUpload(false);
        setUploadLocationValue('');
      })
      .catch(() => {
        setIsDisableTrainUpload(false);
      });
  };

  return (
    <div className={styles.featureTableWrapper}>
      {rfpSavedAlert && (
        <FixedAlertMessage
          className={styles.rfpSavedAlertMessage}
          closeText="close"
          message="RFP successfully saved"
          type="success"
          closable={true}
        />
      )}

      <ConfirmationDialog
        closeModal={() => {
          setModalSwitchState(initialState);
        }}
        visible={modalSwitchState.type === RFP_ACTIONS.DELETE.value}
        cancelText="Cancel"
        confirmText="Yes - Delete RFP"
        title="Delete RFP"
        onConfirm={() => {
          deleteRfp(brokerId!, selectedRfpId).then(() => {
            refetch();
          });
          setModalSwitchState(initialState);
        }}
        isCancelLink
      >
        <p>Are you sure you want to delete this RFP?</p>
      </ConfirmationDialog>

      <ConfirmationDialog
        onConfirm={() => {
          setModalSwitchState(initialState);
        }}
        visible={modalSwitchState.type === RFP_ACTIONS.EXPORT.value}
        confirmText="Close"
        title="Export RFP"
        confirmBtnFullWidth
      >
        <p>Select the file types to be exported:</p>
        <LinkButton
          onClick={() => {
            const rfp = featureWrapperContent?.filter(
              (rfp: any) => rfp?.id === selectedRfpId
            );
            const elementToExport = rfp[0]?.questionsAndAnswers;
            exportToTxt(
              getQuestions(elementToExport).replace(/\n/g, '<br/>'),
              rfp[0]?.name
            );
          }}
        >
          <img
            src={ExportWord}
            alt="ExportWord"
            className={styles.rfpButtonIcon}
          />
          Download Word Doc
        </LinkButton>
      </ConfirmationDialog>

      <div className={styles.rfpWrapper}>
        <div className={styles.rfpWrapperForButton}>
          <div className={styles.statusAndButtonDiv}>
            {loadingflag &&
            !isTrainingCompleted &&
            !isEmpty(featureWrapperContent) ? (
              <PlanyearPopover
                content={TRAINING_AI_HOVER}
                width={300}
                placement="left"
              >
                <div className={styles.trainingLabel}>
                  {<LoadingStrip value=" Training AI Model" />}
                </div>
              </PlanyearPopover>
            ) : (
              <></>
            )}
            {trainAiBool && (
              <div className={styles.buttonAndRetrain}>
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(setClearState());
                    dispatch(setRfpFailed(false));
                    setIsModalOpen(true);
                    pingTrainingStatus();
                    if (
                      featureWrapperContent.slice(-1)[0]?.status === 'STARTED'
                    ) {
                      return;
                    } else {
                      setIsModalOpen(true);
                    }
                  }}
                  className={styles.rfpResponder}
                  disabled={completedTrainingsCount < 1 || !hasTrainingData}
                >
                  <img
                    src={RFPIcon}
                    alt="Button Icon"
                    className={styles.rfpButtonIcon}
                  />
                  Create AI Response
                </Button>
              </div>
            )}
          </div>
          {trainAiBool && (
            <div className={styles.retrainAiWrapper}>
              <LinkButton
                onClick={() => {
                  setAiRetrainedAlert(false);
                  setShowRetrainWarning(false);
                  pingTrainingStatus();
                  setRetrainAi(true);
                  manageTagsClicked();
                  setFileList([]);
                  if (!isUserInMultipleLocations) {
                    setUploadLocationValue(adminLocations[0]);
                  }
                }}
              >
                Train AI
              </LinkButton>
              <PlanyearPopover
                placement="left"
                content={
                  hasTrainingData
                    ? RFP_RETRAIN_TOOLTIP_PROMPT
                    : RFP_TRAIN_TOOLTIP_PROMPT
                }
                width={450}
                zIndex={9999}
              >
                <div className={styles.questionTagIcon}>
                  <QuestionCircleOutlined />
                </div>
              </PlanyearPopover>
            </div>
          )}
        </div>
      </div>
      <AddRfpmodal
        refetch={async () => {
          await pingRfpStatus();
        }}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setRfpSavedAlert={setRfpSavedAlert}
        setModalSwitchState={setModalSwitchState}
      />
      <AddRfpmodal
        setRfpSavedAlert={setRfpSavedAlert}
        refetch={() => {
          refetch();
        }}
        rfpName={selectedRfpName}
        isEditMode={isEditMode}
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
      />

      {featureWrapperContent?.length === 0 &&
      selectedLocationValues.length === 0 ? (
        <div>
          <h1>RFPs</h1>
          {!isTrainProgressed && !isAllFailed ? (
            <div className={styles.noRfpDetectedArea}>
              <FileAddTwoTone className={styles.fileUploadIcon} />
              <p>
                <b>
                  {isSomeFailed
                    ? 'Ready to Create AI Responses'
                    : 'RFP Training Complete'}
                </b>
              </p>
              <p>
                {isSomeFailed
                  ? "Some of your training files did not process successfully. For detailed status and to retrain, please click 'Train AI'"
                  : 'Use our RFP tool to automate the creation of RFP responses'}
              </p>
              <button
                onClick={() => {
                  setIsModalOpen(true);
                }}
                className={styles.getStartedbtn}
              >
                Get Started
              </button>
            </div>
          ) : (
            <div className={styles.noRfpDetectedArea}>
              {!isStarted ? (
                <FileAddTwoTone className={styles.fileUploadIcon} />
              ) : (
                <img src={loaderGif} alt="scanning" />
              )}
              <p>
                <b>
                  {!isStarted
                    ? 'You don’t have any RFPs'
                    : 'Training the AI Model'}
                </b>
              </p>
              <p className={styles.rfpTrainCompletedTag}>
                {!isStarted
                  ? 'Use our AI RFP tool to automate the creation of responses to RFPs. First step is to train the AI with a minimum of one well written RFP response from your past responses'
                  : 'This may take several minutes. Feel free to browse around and come back to check status.'}
              </p>
              <button
                onClick={() => {
                  setRetrainAi(true);
                  if (!isUserInMultipleLocations) {
                    setUploadLocationValue(adminLocations[0]);
                  }
                }}
                className={styles.getStartedbtn}
              >
                {!isStarted ? 'Train AI' : 'See Status'}
              </button>
            </div>
          )}
        </div>
      ) : (
        <ToolList
          title="Completed Responses"
          columns={dataColumns}
          data={!isEmpty(searchText) ? filteredRFPData : featureWrapperContent}
          search={'Search'}
          handleSearch={handleSearch}
          isLoading={isLoading}
          filters={
            isUserInMultipleLocations && (
              <div className={styles.locationFilter}>
                <FilterDropDown
                  showSearch
                  placeholder="Location"
                  filterName="Location"
                  searchPlaceholder="Location"
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  resetOptions={[]}
                  defaultOptions={selectedLocationValues}
                  options={locationDropdownOptions}
                  showCustomButton={false}
                  handleResetCallback={() => {
                    clearInterval(fetchRfpListInterval.current);
                    setSelectedLocationValues([]);
                  }}
                  handleOkayCallback={(value) => {
                    // We have to clear the interval like this so prior ping stops goign through
                    // This will afterwards be replaced with the new ping
                    clearInterval(fetchRfpListInterval.current);
                    setSelectedLocationValues(value);
                  }}
                />
              </div>
            )
          }
          supporGuide={
            <a
              rel="noreferrer"
              className={styles.supportGuideLink}
              target="_blank"
              href="https://coda.io/@planyear-product/rfp-responder-user-guide"
            >
              User Guide
            </a>
          }
        />
      )}

      {retrainAi && (
        <FullScreenModal
          className={styles.fullScreenTrainModal}
          visible={retrainAi}
          onCancel={() => {
            if (fetchRfpListInterval.current) {
              clearInterval(fetchRfpListInterval.current);
            }
            setRetrainAi(false);
            setAiRetrainedAlert(false);
            setFileList([]);
            setShowRetrainWarning(false);
            startPingRfpStatus();
            clearSearchFilters();
            resetPaginationConfig();
          }}
          footer={false}
          title={
            <>
              <RfpIconSelected /> Train AI
            </>
          }
        >
          <div className={styles.trainAiForm}>
            <div className={styles.trainRfpModalSubHeader}>
              To train our AI RFP model, please upload
              <b>
                <u> at least 1 </u>
              </b>
              <b> or more previously answered RFPs (limit of 25 files) </b>
              and specify the location for which the file should be used for
              training. Please note that RFPs longer than 10 pages may time out
              and be invalid.
            </div>
            <FixedAlertMessage
              className={styles.reviewWarningMessage}
              type="warning"
              message={RFP_TRAIN_OVER_TEN_PAGES}
            />
            <FixedAlertMessage
              className={styles.reviewWarningMessage}
              type="warning"
              message={RFP_QNA_FORMAT_ONLY}
            />
            {isErrorFile && (
              <FixedAlertMessage
                type="error"
                closeAlert={() => {
                  setIsErrorFile(false);
                }}
                message={NOT_ALLOWED_FILES_MESSAGE}
              />
            )}
            <div className={styles.uploadFileDragger}>
              <Dragger
                multiple
                accept=".pdf,.docx,.doc"
                height={150}
                showUploadList={false}
                {...uploadFile}
              >
                {!(fileList?.length > 0) && (
                  <>
                    <p>
                      <InboxOutlined />
                    </p>
                    <p>Drag and drop files or click to upload</p>
                    <b>PDF, DOC and DOCX formats are supported.</b>
                  </>
                )}
                <p>
                  <div className={styles.uploadedFile}>
                    {fileList
                      ?.map((file) => file.name)
                      .map((filename, key) => (
                        <div key={key} className={styles.attachment}>
                          <img src={AttachmentIcon} />
                          <span className={styles.attachmentText}>
                            {filename}
                          </span>
                        </div>
                      ))}
                  </div>
                </p>
              </Dragger>
            </div>
            <Row
              align="middle"
              justify="center"
              className={styles.startUploadRow}
            >
              {isUserInMultipleLocations && (
                <Col>
                  <Row align="middle" justify="center">
                    <b>Location*</b>
                    <Select
                      value={uploadLocationValue}
                      onChange={setUploadLocationValue}
                      className={styles.locationSelector}
                      dropdownClassName={styles.locationDropdown}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                    >
                      {locationDropdownOptions.map((loc) => (
                        <Select.Option key={loc.value}>
                          {loc.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              )}
              <Col>
                <Button
                  disabled={
                    !uploadLocationValue ||
                    !(fileList?.length > 0) ||
                    isDisableTrainUpload
                  }
                  onClick={handleStartUploadClick}
                  className={styles.uploadStartBtn}
                >
                  Start Upload
                </Button>
              </Col>
            </Row>
            {showRetrainWarning && (
              <FixedAlertMessage
                className={styles.retrainAiWarning}
                type="warning"
                message={RETRAIN_INFO_MESSAGE}
              />
            )}
            <div className={styles.filterAreaDiv}>
              <div className={styles.searchAndFilters}>
                <SearchBar onChange={handleTrainSearch} placeholder="Search" />
                <div className={styles.filterDropDownArea}>
                  <FilterDropDown
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    defaultOptions={tagsFilterValues}
                    resetOptions={[]}
                    options={addedTagList}
                    showSearch
                    showCustomButton={false}
                    searchPlaceholder="Search Tags"
                    handleResetCallback={() => {
                      setTagsFilterValues([]);
                    }}
                    handleOkayCallback={(value) => {
                      setTagsFilterValues(value);
                    }}
                    placeholder="Filter by Tag"
                    filterName="Filter by Tag"
                  />
                </div>
                <div className={styles.filterDropDownArea}>
                  <FilterDropDown
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    defaultOptions={uploadedByFilterValues}
                    resetOptions={[]}
                    options={allBrokerAdminsData?.map((broker: any) => {
                      return {
                        value: broker?.email,
                        label: broker?.firstName + ' ' + broker?.lastName,
                      };
                    })}
                    showSearch
                    showCustomButton={false}
                    isLoading={isLoadingBrokerData}
                    searchPlaceholder="Search Admins"
                    handleResetCallback={() => {
                      setUploadedByFilterValues([]);
                    }}
                    handleOkayCallback={(value) => {
                      setUploadedByFilterValues(value);
                    }}
                    placeholder="Uploaded By"
                    filterName="Uploaded By"
                  />
                </div>
                <div className={styles.filterDropDownArea}>
                  <FilterDropDown
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    isUseLabelForPlaceholder
                    showCustomButton={false}
                    defaultOptions={uploadedAtFilterValues}
                    resetOptions={DEFAULT_UPLOADED_AT_FILTER_VALUES}
                    showSearch={false}
                    optionSort={false}
                    options={FILTER_RFP_BY_DATE}
                    handleResetCallback={() => {
                      setUploadedAtFilterValues(
                        DEFAULT_UPLOADED_AT_FILTER_VALUES
                      );
                    }}
                    handleOkayCallback={(value) => {
                      setUploadedAtFilterValues(value);
                    }}
                    placeholder="Uploaded Date"
                    filterName="Uploaded Date"
                  />
                </div>
              </div>
              <div>
                <LinkButton
                  onClick={() => {
                    dispatch(setTagsError(null));
                    setManageTagsModal(true);
                    manageTagsClicked();
                  }}
                >
                  Manage Tags
                </LinkButton>
              </div>
            </div>
            <DataTable
              columns={trainDataColumns}
              data={mappedData?.slice(startIndex, endIndex)}
              pagination
              getData={getData}
              currentIndex={paginationConfigTraining?.paginationIndex}
              total={String(mappedData?.length)}
              pageSize={paginationConfigTraining?.filterInfo?.limit}
            />
            <div>
              <NextButton
                className={styles.paginationCloseButton}
                buttonText="Close"
                onClick={() => {
                  setRetrainAi(false);
                  clearSearchFilters();
                  resetPaginationConfig();
                }}
                nextStep={() => {}}
              ></NextButton>
            </div>

            {aiRetrainedAlert && (
              <FixedAlertMessage
                className={styles.alertForSuccesRetrain}
                type="success"
                message={RFP_RETRAIN_SUCCESS}
              />
            )}
          </div>
        </FullScreenModal>
      )}

      {deleteConfirmRfp && (
        <ConfirmDeleteTrainFileModal
          setDeleteConfirmRfp={setDeleteConfirmRfp}
          deleteConfirmRfp={deleteConfirmRfp}
          deleteRetrainConfirm={deleteRetrainConfirm}
          selectedRetrainId={selectedRetrainId}
        />
      )}

      {renameTrainRfp && (
        <ConfirmationDialog
          centered
          destroyOnClose
          closeModal={() => {
            setRenameTrainRfp(false);
          }}
          visible={renameTrainRfp}
          disableConfirmButton={isEmpty(renameFileValue.trim())}
          title="Rename File"
          isCancelLink
          cancelText="Cancel"
          confirmBtnFullWidth
          confirmText="Save"
          onConfirm={() => {
            renameTrainFile(selectedTrainFile?.key, renameFileValue).then(
              () => {
                setRenameTrainRfp(false);
                pingTrainingStatus();
              }
            );
          }}
        >
          <p>
            <b>File Name</b>
          </p>
          <Input
            width={400}
            className={styles.fileNameInput}
            value={renameFileValue}
            onChange={(e) => {
              setRenameFileValue(e.target.value);
            }}
          />
        </ConfirmationDialog>
      )}

      {editTagsModal && (
        <ConfirmationDialog
          centered
          destroyOnClose
          confirmBtnFullWidth
          closeModal={() => {
            setEditTagsModal(false);
            setCheckedTagList(
              trainFileAddedTags.alreadyAdded.map((tag: any) => tag?.id)
            );
          }}
          visible={editTagsModal}
          title={
            <>
              <p>Edit File Tags</p>
              <p className={styles.fileNameinEditTags}>
                {selectedTrainFile?.fileName}
              </p>
            </>
          }
          isCancelLink
          cancelText="Cancel"
          confirmText="Save"
          onConfirm={() => {
            const allIds = [
              ...trainFileAddedTags.temporary.map((tag: any) => tag.id),
            ];
            addTagsToTrainFIle(selectedTrainFile?.key, allIds).then(() => {
              pingTrainingStatus();
            });
            setEditTagsModal(false);
          }}
        >
          <b>Choose Tags</b>
          <MultiSelect
            ref={rfpTagSelectRef}
            selectName="rfpTags"
            listHeight={150}
            defaultOptions={checkedTagList}
            resetOptions={[]}
            optionHeading={'ALL TAGS'}
            handleOkayCallback={(value) => {
              const objArray = value.map((id) => {
                const tag = addedTags.find((tag) => tag?.id === id);
                return tag ? tag : null;
              });
              setTrainFileAddedTags((prevState) => ({
                temporary: objArray,
                alreadyAdded: [...prevState.alreadyAdded],
              }));
              setCheckedTagList(objArray.map((tag) => tag?.id));
            }}
            handleResetCallback={(value) => {
              setCheckedTagList([]);
              setTrainFileAddedTags((prevState) => ({
                temporary: [],
                alreadyAdded: [...prevState.alreadyAdded],
              }));
            }}
            options={addedTagList}
            searchPlaceholder="Search Tags"
          />

          <div className={styles.tagWrapper}>
            {getRfpTagList(checkedTagList).map((tag: any) => {
              return (
                <RfpTag
                  key={tag?.tagName}
                  tagValue={toUpper(tag?.tagName)}
                  showClose
                  onClose={() => {
                    setTrainFileAddedTags((prevState) => ({
                      ...prevState,
                      temporary: prevState.temporary.filter(
                        (item: any) => item?.tagName !== tag?.tagName
                      ),
                    }));
                    setCheckedTagList((preState) => {
                      return preState?.filter(
                        (tagId: any) => tagId !== tag?.id
                      );
                    });
                    if (checkedTagList.length === 1) {
                      rfpTagSelectRef.current?.reset();
                    }
                  }}
                />
              );
            })}
          </div>
        </ConfirmationDialog>
      )}

      {addToTrainModal && (
        <ConfirmationDialog
          centered
          destroyOnClose
          closeModal={() => {
            setAddToTrainModal(false);
          }}
          visible={addToTrainModal}
          title="Add to Training Set"
          isCancelLink
          cancelText="Cancel"
          confirmText="Add File to Training Set"
          onConfirm={() => {
            setAddToTrainModal(false);
          }}
        >
          <p>{ADD_TO_TRAIN_SET_MESSAGE}</p>
        </ConfirmationDialog>
      )}

      {manageTagsModal && (
        <ConfirmationDialog
          centered
          destroyOnClose
          closeModal={() => {
            setManageTagsModal(false);
            setFilterTagSearch('');
          }}
          visible={manageTagsModal}
          title="Manage Tags"
          confirmText="Close"
          confirmBtnFullWidth
          onConfirm={() => {
            setManageTagsModal(false);
            setFilterTagSearch('');
          }}
          resetZindex
        >
          <div className={styles.ManageTagsModalDiv}>
            <SearchBar
              value={filterTagSearch}
              onChange={filterAddedTags}
              placeholder="Search"
              isLarge
            />
            <ListComponent
              header={<div className={styles.allTagsArea}>ALL TAGS</div>}
              listItemClassName={styles.listItemClass}
              onEdit={(item: any) => {
                dispatch(setTagsError(null));
                setEditSingleTagModal({
                  show: true,
                  item: item,
                });
                setSelectedTagValue(item?.label);
              }}
              editClassName={styles.editPencil}
              showEditIcon
              className={styles.listOfTags}
              dataSource={addedTagList}
            />
            <div className={styles.addNewTagLink}>
              <LinkButton
                onClick={() => {
                  setNewTagValue('');
                  dispatch(setTagsError(null));
                  setAddTagModal(true);
                }}
              >
                + Add New Tag
              </LinkButton>
            </div>
          </div>
        </ConfirmationDialog>
      )}

      {addTagModal && (
        <AddNewTagModal
          manageTagsClicked={manageTagsClicked}
          setAddTagModal={setAddTagModal}
          addTagModal={addTagModal}
          newTagValue={newTagValue}
          setNewTagValue={setNewTagValue}
        />
      )}

      {editSingleTagModal.show && (
        <ConfirmationDialog
          centered
          destroyOnClose
          closeModal={() => {
            setEditSingleTagModal({
              show: false,
              item: [],
            });
          }}
          visible={editSingleTagModal.show}
          disableConfirmButton={
            isEmpty(selectedTagValue.trim()) ||
            selectedTagValue.trim().length > 16
          }
          title="Edit Tag"
          confirmText="Save"
          isCancelLink
          cancelText="Cancel"
          confirmBtnFullWidth
          resetZindex
          onDelete={() => {
            setConfirmDeleteTag(true);
          }}
          deleteText="Delete Tag"
          onConfirm={() => {
            updateTag(editSingleTagModal.item?.value, selectedTagValue.trim())
              .then(() => {
                setEditSingleTagModal({
                  show: false,
                  item: [],
                });
                manageTagsClicked();
              })
              .catch((error: any) => {
                if (axios.isAxiosError(error)) {
                  dispatch(setTagsError(error?.response));
                } else {
                  console.log(error);
                }
              });
          }}
        >
          <div className={styles.addNewTagModal}>
            <b>Tag Name</b>
            <Input
              value={selectedTagValue}
              onChange={(e) => {
                setSelectedTagValue(e.target.value);
              }}
              className={styles.addNewTagInput}
            />
            {systemTags?.isError?.data?.code === TAG_ERROR.DUPLICATE_TAGS ? (
              <span className={styles.errorCharacterLimitSpan}>
                Tags Cannot be duplicated
              </span>
            ) : systemTags?.isError?.data?.code ===
              TAG_ERROR.SPECIAL_CHARACTERS_DETECTED ? (
              <span className={styles.errorCharacterLimitSpan}>
                Tags cannot contain special characters
              </span>
            ) : (
              <></>
            )}
            {selectedTagValue.trim().length > 16 && (
              <span className={styles.errorCharacterLimitSpan}>
                Limited to 16 characters
              </span>
            )}
          </div>
        </ConfirmationDialog>
      )}

      {confirmDeleteTag && (
        <ConfirmDeleteTagModal
          pingTrainingStatus={pingTrainingStatus}
          confirmDeleteTag={confirmDeleteTag}
          setConfirmDeleteTag={setConfirmDeleteTag}
          setEditSingleTagModal={setEditSingleTagModal}
          manageTagsClicked={manageTagsClicked}
          editSingleTagModal={editSingleTagModal}
        />
      )}
    </div>
  );
};

export default EmployerRfpList;
