import { PartialArrayItemTemplate } from 'modules/renewals/types/planTypes';

const MEDICAL = {
  id: null,
  referenceId: null,
  name: null,
  planType: null,
  carriedId: null,
  carrierName: null,
  carrierLogo: null,
  planNetwork: null,
  attachedDocuments: [],
  services: [
    {
      name: 'Primary Care Visit',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Specialist Visit',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Emergency Room',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Infertility Treatment',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Urgent Care',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Inpatient',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Outpatient',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Primary Care Visit',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Specialist Visit',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Emergency Room',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Infertility Treatment',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Urgent Care',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Inpatient',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Outpatient',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
  ],
  deductibles: [
    {
      name: 'Deductible',
      fieldType: 'In Network',
      fieldSubType: 'Single',
      value: null,
    },
    {
      name: 'Deductible',
      fieldType: 'Out of Network',
      fieldSubType: 'Single',
      value: null,
    },
    {
      name: 'Deductible',
      fieldType: 'In Network',
      fieldSubType: 'Family',
      value: null,
    },
    {
      name: 'Deductible',
      fieldType: 'Out of Network',
      fieldSubType: 'Family',
      value: null,
    },
  ],
  monthlyPremiums: [
    {
      name: 'EE Only',
      fieldType: null,
      fieldSubType: '1/4',
      value: null,
    },
    {
      name: 'EE + Spouse',
      fieldType: null,
      fieldSubType: '2/4',
      value: null,
    },
    {
      name: 'EE + Child',
      fieldType: null,
      fieldSubType: '3/4',
      value: null,
    },
    {
      name: 'EE + Family',
      fieldType: null,
      fieldSubType: '4/4',
      value: null,
    },
  ],
  rxDeductible: [
    {
      name: 'RxDeductibleAndOopIndividual',
      fieldType: 'In Network',
      fieldSubType: 'Single',
      value: null,
    },
    {
      name: 'RxDeductibleAndOopIndividual',
      fieldType: 'Out of Network',
      fieldSubType: 'Single',
      value: null,
    },
    {
      name: 'RxDeductibleAndOopFamily',
      fieldType: 'In Network',
      fieldSubType: 'Family',
      value: null,
    },
    {
      name: 'RxDeductibleAndOopFamily',
      fieldType: 'Out of Network',
      fieldSubType: 'Family',
      value: null,
    },
  ],
  rxOop: [
    {
      name: 'RxDeductibleAndOopIndividual',
      fieldType: 'In Network',
      fieldSubType: 'Single',
      value: null,
    },
    {
      name: 'RxDeductibleAndOopIndividual',
      fieldType: 'Out of Network',
      fieldSubType: 'Single',
      value: null,
    },
    {
      name: 'RxDeductibleAndOopFamily',
      fieldType: 'In Network',
      fieldSubType: 'Family',
      value: null,
    },
    {
      name: 'RxDeductibleAndOopFamily',
      fieldType: 'Out of Network',
      fieldSubType: 'Family',
      value: null,
    },
  ],
  monthlyHsa: null,
  offerTypes: null,
  oops: [
    {
      name: 'OOP Max',
      fieldType: 'In Network',
      fieldSubType: 'Single',
      value: null,
    },
    {
      name: 'OOP Max',
      fieldType: 'In Network',
      fieldSubType: 'Family',
      value: null,
    },
    {
      name: 'OOP Max',
      fieldType: 'Out of Network',
      fieldSubType: 'Single',
      value: null,
    },
    {
      name: 'OOP Max',
      fieldType: 'Out of Network',
      fieldSubType: 'Family',
      value: null,
    },
  ],
  rxTiers: [
    {
      name: 'Rx Tier 1',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Rx Tier 2',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Rx Tier 3',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Rx Tier 4',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Rx Tier 1',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Rx Tier 2',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Rx Tier 3',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Rx Tier 4',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
  ],
  enrolments: [
    {
      name: 'ENROLLMENT_TIER_1',
      fieldType: null,
      fieldSubType: '1/4',
      value: null,
    },
    {
      name: 'ENROLLMENT_TIER_2',
      fieldType: null,
      fieldSubType: '2/4',
      value: null,
    },
    {
      name: 'ENROLLMENT_TIER_3',
      fieldType: null,
      fieldSubType: '3/4',
      value: null,
    },
    {
      name: 'ENROLLMENT_TIER_4',
      fieldType: null,
      fieldSubType: '4/4',
      value: null,
    },
  ],
};

const DENTAL = {
  id: null,
  referenceId: null,
  name: null,
  planType: null,
  carriedId: null,
  carrierName: null,
  carrierLogo: null,
  planNetwork: null,
  attachedDocuments: [],
  additionalServices: [],
  services: [
    {
      name: 'Preventive Dental Care',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Basic Dental Care',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Major Dental Care',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Orthodontia',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'OON Reimbursement',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Preventive Dental Care',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Basic Dental Care',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Major Dental Care',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Orthodontia',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'OON Reimbursement',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
  ],
  deductibles: [
    {
      name: 'Deductible',
      fieldType: 'In Network',
      fieldSubType: 'Single',
      value: null,
    },
    {
      name: 'Deductible',
      fieldType: 'Out of Network',
      fieldSubType: 'Single',
      value: null,
    },
    {
      name: 'Deductible',
      fieldType: 'In Network',
      fieldSubType: 'Family',
      value: null,
    },
    {
      name: 'Deductible',
      fieldType: 'Out of Network',
      fieldSubType: 'Family',
      value: null,
    },
  ],
  monthlyPremiums: [
    {
      name: 'EE Only',
      fieldType: null,
      fieldSubType: '1/4',
      value: null,
    },
    {
      name: 'EE + Spouse',
      fieldType: null,
      fieldSubType: '2/4',
      value: null,
    },
    {
      name: 'EE + Child',
      fieldType: null,
      fieldSubType: '3/4',
      value: null,
    },
    {
      name: 'EE + Family',
      fieldType: null,
      fieldSubType: '4/4',
      value: null,
    },
  ],
  rxDeductible: [],
  offerTypes: null,
  maximumBenefits: [
    {
      name: 'Plan Year Max',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
      formattedValue: null,
    },
    {
      name: 'Plan Year Max',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
      formattedValue: null,
    },
  ],
  orthodontiaMax: [
    {
      name: 'Orthodontia Max',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
      formattedValue: null,
      dataFormat: null,
    },
    {
      name: 'Orthodontia Max',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
      formattedValue: null,
      dataFormat: null,
    },
  ],
  enrolments: [
    {
      name: 'ENROLLMENT_TIER_1',
      fieldType: null,
      fieldSubType: '1/4',
      value: null,
    },
    {
      name: 'ENROLLMENT_TIER_2',
      fieldType: null,
      fieldSubType: '2/4',
      value: null,
    },
    {
      name: 'ENROLLMENT_TIER_3',
      fieldType: null,
      fieldSubType: '3/4',
      value: null,
    },
    {
      name: 'ENROLLMENT_TIER_4',
      fieldType: null,
      fieldSubType: '4/4',
      value: null,
    },
  ],
};

const VISION = {
  id: null,
  referenceId: null,
  name: null,
  planType: null,
  carriedId: null,
  carrierName: null,
  carrierLogo: null,
  planNetwork: null,
  frequencyOfServices: null,
  attachedDocuments: [],
  additionalServices: [],
  services: [
    {
      name: 'Frequency of Services',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Routine Eye Exam',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Frames',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Lenses (Single)',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Lenses (Bi)',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Lenses (Tri)',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Medical Nec. Contacts',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Elective Contacts',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Frequency of Services',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Routine Eye Exam',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Frames',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Lenses (Single)',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Lenses (Bi)',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Lenses (Tri)',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Medical Nec. Contacts',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
    {
      name: 'Elective Contacts',
      fieldType: 'Out of Network',
      fieldSubType: null,
      value: null,
    },
  ],
  deductibles: [],
  monthlyPremiums: [
    {
      name: 'EE Only',
      fieldType: null,
      fieldSubType: '1/4',
      value: null,
    },
    {
      name: 'EE + Spouse',
      fieldType: null,
      fieldSubType: '2/4',
      value: null,
    },
    {
      name: 'EE + Child',
      fieldType: null,
      fieldSubType: '3/4',
      value: null,
    },
    {
      name: 'EE + Family',
      fieldType: null,
      fieldSubType: '4/4',
      value: null,
    },
  ],
  rxDeductible: [
    {
      name: 'Rx - Deductible',
      fieldType: 'In Network',
      fieldSubType: null,
      value: null,
    },
  ],
  offerTypes: null,
  enrollments: [
    {
      name: 'ENROLLMENT_TIER_1',
      fieldType: null,
      fieldSubType: '1/4',
      value: null,
      formattedValue: null,
      dataFormat: null,
    },
    {
      name: 'ENROLLMENT_TIER_2',
      fieldType: null,
      fieldSubType: '2/4',
      value: null,
      formattedValue: null,
      dataFormat: null,
    },
    {
      name: 'ENROLLMENT_TIER_3',
      fieldType: null,
      fieldSubType: '3/4',
      value: null,
      formattedValue: null,
      dataFormat: null,
    },
    {
      name: 'ENROLLMENT_TIER_4',
      fieldType: null,
      fieldSubType: '4/4',
      value: null,
      formattedValue: null,
      dataFormat: null,
    },
  ],
};

const LIFE = {
  id: null,
  referenceId: null,
  name: null,
  planType: null,
  carriedId: null,
  carrierName: null,
  carrierLogo: null,
  benefitLevel: null,
  attachedDocuments: [],
  volume: {
    name: 'Volume*',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  lifeRate: [
    {
      name: 'Basic Life Rate*',
      fieldType: null,
      fieldSubType: null,
      value: null,
    },
  ],
  lifeAndAddBenefit: {
    name: 'Benefit',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  lifeAndAddMaximum: {
    name: 'Benefit Maximum',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  guaranteedIssue: {
    name: 'Guaranteed Issue',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  ageReductionFromBase: {
    name: 'Age Reduction from Base',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  basicAddRate: [
    {
      name: 'Basic AD&D Rate*',
      fieldType: null,
      fieldSubType: null,
      value: null,
    },
  ],
  weeklyBenefitMax: null,
  monthlyBenefitMax: null,
  eliminationPeriod: null,
  benefitDuration: null,
  totalEligibleEnrollments: null,
  definitionOfDisability: null,
  preExistingConditions: null,
  stateDisabilityIntegration: null,
  ownOccupationPeriod: null,
  w2Preparation: null,
  insuranceType: null,
};

const STD = {
  id: null,
  referenceId: null,
  name: null,
  planType: null,
  carriedId: null,
  carrierName: null,
  carrierLogo: null,
  attachedDocuments: [],
  benefitLevel: {
    name: 'Benefit',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  volume: {
    name: 'Volume',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  lifeRate: [
    {
      name: 'STD Rate*',
      fieldType: null,
      fieldSubType: null,
      value: null,
      formattedValue: null,
      dataFormat: null,
    },
  ],
  annualEstimatedClaims: {
    name: 'Annual Est. Claims',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  totalEligibleEmployees: {
    name: 'Total Eligible Employees',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  administrationFee: {
    name: 'Admin Fee',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  weeklyBenefitMax: {
    name: 'Max Weekly Benefit',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  monthlyBenefitMax: null,
  eliminationPeriod: {
    name: 'Waiting Period',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  benefitDuration: {
    name: 'Max Benefit Duration',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  totalEligibleEnrollments: {
    name: 'Total Eligible Enrollment',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  definitionOfDisability: {
    name: 'Definition of Disability',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  preExistingConditions: {
    name: 'Pre-Existing Conditions',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  stateDisabilityIntegration: {
    name: 'State Disability Integration',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  ownOccupationPeriod: null,
  w2Preparation: null,
  lifeAndAddBenefit: null,
  lifeAndAddMaximum: null,
  guaranteedIssue: null,
  ageReductionFromBase: null,
  basicAddRate: [],
  insuranceType: 'STD',
};

const LTD = {
  id: null,
  referenceId: null,
  name: null,
  planType: null,
  carriedId: null,
  carrierName: null,
  carrierLogo: null,
  attachedDocuments: [],
  benefitLevel: {
    name: 'Benefit',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  volume: {
    name: 'Volume',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  lifeRate: [
    {
      name: 'LTD Rate*',
      fieldType: null,
      fieldSubType: null,
      value: null,
    },
  ],
  weeklyBenefitMax: null,
  monthlyBenefitMax: {
    name: 'Max Monthly Benefit',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  eliminationPeriod: {
    name: 'Waiting Period',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  benefitDuration: {
    name: 'Max Benefit Duration',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  totalEligibleEnrollments: {
    name: 'Total Eligible Enrollment',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  definitionOfDisability: {
    name: 'Definition of Disability',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  preExistingConditions: null,
  stateDisabilityIntegration: null,
  ownOccupationPeriod: {
    name: 'Own Occupation Period',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  w2Preparation: {
    name: 'W-2 Preparation',
    fieldType: null,
    fieldSubType: null,
    value: null,
    formattedValue: null,
    dataFormat: null,
  },
  lifeAndAddBenefit: null,
  lifeAndAddMaximum: null,
  guaranteedIssue: null,
  ageReductionFromBase: null,
  basicAddRate: [],
  insuranceType: 'LTD',
};

const VOLUNTARY_BENEFIT = {
  attachedDocuments: [],
};

export const EMPTY_PLAN_TABLE_DATA = {
  MEDICAL: MEDICAL,
  DENTAL: DENTAL,
  VISION: VISION,
  LIFE: LIFE,
  STD: STD,
  LTD: LTD,
  VOLUNTARY_BENEFIT: VOLUNTARY_BENEFIT,
} as any;

export const PLAN_CLONE_RADIO_OPTIONS = {
  CLONE: 'CLONE',
  MANUAL: 'MANUAL',
} as any;

export const STANDARD_TIER_DEFAULT = [
  {
    name: 'EE Only',
    fieldType: null,
    fieldSubType: '1/4',
    value: null,
  },
  {
    name: 'EE + Spouse',
    fieldType: null,
    fieldSubType: '2/4',
    value: null,
  },
  {
    name: 'EE + Child',
    fieldType: null,
    fieldSubType: '3/4',
    value: null,
  },
  {
    name: 'EE + Family',
    fieldType: null,
    fieldSubType: '4/4',
    value: null,
  },
];
export const STANDARD_ENROLLMENTS_DEFAULT = [
  {
    name: 'ENROLLMENT_TIER_1',
    fieldType: null,
    fieldSubType: '1/4',
    value: null,
  },
  {
    name: 'ENROLLMENT_TIER_2',
    fieldType: null,
    fieldSubType: '2/4',
    value: null,
  },
  {
    name: 'ENROLLMENT_TIER_3',
    fieldType: null,
    fieldSubType: '3/4',
    value: null,
  },
  {
    name: 'ENROLLMENT_TIER_4',
    fieldType: null,
    fieldSubType: '4/4',
    value: null,
  },
];
export const STANDARD_DEDUCTIBLES_DEFAULT: PartialArrayItemTemplate[] = [
  {
    name: 'Deductible',
    fieldType: 'In Network',
    fieldSubType: 'Single',
    value: null,
  },
  {
    name: 'Deductible',
    fieldType: 'Out of Network',
    fieldSubType: 'Single',
    value: null,
  },
  {
    name: 'Deductible',
    fieldType: 'In Network',
    fieldSubType: 'Family',
    value: null,
  },
  {
    name: 'Deductible',
    fieldType: 'Out of Network',
    fieldSubType: 'Family',
    value: null,
  },
];

export const STANDARD_OOP_DEFAULT: PartialArrayItemTemplate[] = [
  {
    name: 'OOP Max',
    fieldType: 'In Network',
    fieldSubType: 'Single',
    value: null,
  },
  {
    name: 'OOP Max',
    fieldType: 'In Network',
    fieldSubType: 'Family',
    value: null,
  },
  {
    name: 'OOP Max',
    fieldType: 'Out of Network',
    fieldSubType: 'Single',
    value: null,
  },
  {
    name: 'OOP Max',
    fieldType: 'Out of Network',
    fieldSubType: 'Family',
    value: null,
  },
];

export const STANDARD_RX_TIERS_DEFAULT: PartialArrayItemTemplate[] = [
  {
    name: 'Rx Tier 1',
    fieldType: 'In Network',
    fieldSubType: null,
    value: null,
  },
  {
    name: 'Rx Tier 2',
    fieldType: 'In Network',
    fieldSubType: null,
    value: null,
  },
  {
    name: 'Rx Tier 3',
    fieldType: 'In Network',
    fieldSubType: null,
    value: null,
  },
  {
    name: 'Rx Tier 4',
    fieldType: 'In Network',
    fieldSubType: null,
    value: null,
  },
  {
    name: 'Rx Tier 1',
    fieldType: 'Out of Network',
    fieldSubType: null,
    value: null,
  },
  {
    name: 'Rx Tier 2',
    fieldType: 'Out of Network',
    fieldSubType: null,
    value: null,
  },
  {
    name: 'Rx Tier 3',
    fieldType: 'Out of Network',
    fieldSubType: null,
    value: null,
  },
  {
    name: 'Rx Tier 4',
    fieldType: 'Out of Network',
    fieldSubType: null,
    value: null,
  },
];

export const STANDARD_RX_DEDUCTIBLES_DEFAULT: PartialArrayItemTemplate[] = [
  {
    name: 'RxDeductibleAndOopIndividual',
    fieldType: 'In Network',
    fieldSubType: 'Single',
    value: null,
  },
  {
    name: 'RxDeductibleAndOopIndividual',
    fieldType: 'Out of Network',
    fieldSubType: 'Single',
    value: null,
  },
  {
    name: 'RxDeductibleAndOopFamily',
    fieldType: 'In Network',
    fieldSubType: 'Family',
    value: null,
  },
  {
    name: 'RxDeductibleAndOopFamily',
    fieldType: 'Out of Network',
    fieldSubType: 'Family',
    value: null,
  },
];

export const STANDARD_RX_OOP_DEFAULT: PartialArrayItemTemplate[] = [
  {
    name: 'RxDeductibleAndOopIndividual',
    fieldType: 'In Network',
    fieldSubType: 'Single',
    value: null,
  },
  {
    name: 'RxDeductibleAndOopIndividual',
    fieldType: 'Out of Network',
    fieldSubType: 'Single',
    value: null,
  },
  {
    name: 'RxDeductibleAndOopFamily',
    fieldType: 'In Network',
    fieldSubType: 'Family',
    value: null,
  },
  {
    name: 'RxDeductibleAndOopFamily',
    fieldType: 'Out of Network',
    fieldSubType: 'Family',
    value: null,
  },
];

export const STANDARD_DENTAL_DEDUCTIBLES_DEFAULT: PartialArrayItemTemplate[] = [
  {
    name: 'Deductible',
    fieldType: 'In Network',
    fieldSubType: 'Single',
    value: null,
  },
  {
    name: 'Deductible',
    fieldType: 'Out of Network',
    fieldSubType: 'Single',
    value: null,
  },
  {
    name: 'Deductible',
    fieldType: 'In Network',
    fieldSubType: 'Family',
    value: null,
  },
  {
    name: 'Deductible',
    fieldType: 'Out of Network',
    fieldSubType: 'Family',
    value: null,
  },
];

export const STANDARD_MAX_BENEFITS_DEFAULT: PartialArrayItemTemplate[] = [
  {
    name: 'Plan Year Max',
    fieldType: 'In Network',
    fieldSubType: null,
    value: null,
    formattedValue: null,
  },
  {
    name: 'Plan Year Max',
    fieldType: 'Out of Network',
    fieldSubType: null,
    value: null,
    formattedValue: null,
  },
];

export const STANDARD_ORTHODONTIA_MAX_DEFAULT: PartialArrayItemTemplate[] = [
  {
    name: 'Orthodontia Max',
    fieldType: 'In Network',
    fieldSubType: null,
    value: null,
    formattedValue: '',
    dataFormat: null,
    optionalFormattedValue: null,
    currencyFormattedValue: null,
  },
  {
    name: 'Orthodontia Max',
    fieldType: 'Out of Network',
    fieldSubType: null,
    value: null,
    formattedValue: '',
    dataFormat: null,
    optionalFormattedValue: null,
    currencyFormattedValue: null,
  },
];

export const ENROLLMENTS = 'enrolments';
export const HSA_COMPATIBLE = 'hsaCompatible';
export const NULL_STRING = 'null';
export const CAP_PREMIUM = 'PREMIUM';
export const EE_ONLY = 'EE Only*';
export const NEW_OFFER_PLAN = 'newOfferPlan';
export const QUOTE = 'QUOTE';
