import { FC, MutableRefObject } from 'react';
import { useAppDispatch } from 'hooks/redux';
import MedicalPlan from 'model/plans/MedicalPlan';
import Services from 'modules/plans/components/Services/Services';
import { updateMedicalPlan } from 'modules/plans/slices/medicalPlanSlice';

type ServicesSBCWrapperProps = {
  type: string;
  wrappedRef?: MutableRefObject<any>;
  isTouched: boolean;
};

const ServicesSBCWrapper: FC<ServicesSBCWrapperProps> = (
  props: ServicesSBCWrapperProps
) => {
  const { type, wrappedRef, isTouched } = props;

  const dispatch = useAppDispatch();

  const onChange = (medicalPlan: MedicalPlan) => {
    dispatch(updateMedicalPlan(medicalPlan));
  };

  return (
    <Services
      ref={wrappedRef}
      formType={type}
      onChange={onChange}
      isTouched={isTouched}
    />
  );
};

export default ServicesSBCWrapper;
