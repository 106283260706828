import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useNavigate, useParams } from 'react-router-dom';
import {
  COLOR_BILLING_CHART_NON_HOVER,
  COLOR_BILLING_CHART_STOKE,
  COLOR_DARK_GREY_LIGHT_SHADE,
  COLOR_LEGEND_LABEL_SHADE,
  COLOR_LIGHT_GREY,
  COLOR_MEDIUM_SEA_GREEN,
} from 'modules/claims/constants/constants';
import { shortenCurrencyWithSuffix } from 'util/commonUtil';
import { useGetListOfBillingsQuery } from 'modules/employers/slices/billingDashboardSlice';
import { monthsShort } from 'modules/billing/constants/constants';
import { ReactComponent as DashboardNoData } from 'assets/images/dashboardUtils/no-data-issue-log.svg';
import {
  BILLING_CLAIM_TXT_X,
  BILLING_CLAIM_TXT_Y,
  BILLING_CLAIM_X,
  BILLING_CLAIM_Y,
} from 'modules/employers/constants/employerConstants';
import NoBillingViewDashboard from 'modules/billing/components/NoBillingViewDashboard/NoBillingViewDashboard';
import CustomSpinner from 'components/Spinner/CustomSpinner';
import { useNavContext } from 'hooks/useNavContext';
import styles from './dashboardBillingClaimr.module.less';

interface IProps {
  isBillingFullyAvailable: boolean;
}

interface BillingMonthlySummary {
  year: number;
  month: string;
  amount: number;
  name?: string;
  isExist?: boolean;
}

const DashboardBillingClaimChart = (props: IProps) => {
  const { employerId } = useParams();
  const navigate = useNavigate();
  const { brokerId } = useNavContext();
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    refetch: refetchListOfBilling,
  } = useGetListOfBillingsQuery({
    employerId,
  });

  useEffect(() => {
    refetchListOfBilling();
  }, [refetchListOfBilling]);

  useEffect(() => {
    if (!isLoading && !isError) {
      // Claim True --> only five records are taken to consideration
      const dataSet = data.content;
      const reversed = dataSet.slice(
        dataSet.length - getRowCount(props.isBillingFullyAvailable),
        dataSet.length
      );
      setChartObj(makeDatSetPluginAcceptable(reversed));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, props.isBillingFullyAvailable, isLoading, isError]);

  const getRowCount = (flag: boolean): number => (flag ? 12 : 5);

  // Get the maximum numerical number of the billing arrayt
  const getMaximumAmountOfArray = (data: BillingMonthlySummary[]): number => {
    return Math.max(...data.map((o) => o.amount));
  };

  // Generate a random number is the value is not available
  const generateRandomValue = (amount: number, maxLimit: number): number => {
    // if number exist no need of a random number
    if (amount) {
      return amount;
    }
    // generate random number less than the max limit
    return Math.floor(Math.random() * maxLimit);
  };

  // convert backend fetched dataset to component acceptable dataset and check if all values exist
  const makeDatSetPluginAcceptable = (data: BillingMonthlySummary[]): any[] => {
    const maximumAmount: number = getMaximumAmountOfArray(data);

    return data.map((data: any) => {
      const currentYear = data?.year.toString() as string;
      return {
        name: monthsShort[data.month] + ` '` + currentYear.slice(-2),
        amount: generateRandomValue(data.amount, maximumAmount),
        isExist: Boolean(data.amount),
      };
    });
  };

  const [chartObj, setChartObj] = useState<BillingMonthlySummary[]>([]);
  const checkIfDataExistForRecords = (val: string): boolean => {
    const foundRecord = chartObj.find(
      (obj) => obj.name === val && obj.amount > 0 && obj.isExist
    );
    return foundRecord != undefined;
  };

  const xAxisTick = (value: string, x: number, y: number) => {
    const isDataExist = checkIfDataExistForRecords(value);
    const splitted = value.split(' ');
    return isDataExist ? (
      <>
        <text
          x={x - BILLING_CLAIM_X}
          y={y + BILLING_CLAIM_Y}
          fill={COLOR_LEGEND_LABEL_SHADE}
          fontWeight={600}
        >
          {splitted[0]}
        </text>
        <text
          x={x - BILLING_CLAIM_TXT_X}
          y={y + BILLING_CLAIM_TXT_Y}
          fill={COLOR_LEGEND_LABEL_SHADE}
          fontWeight={600}
        >
          {splitted[1]}
        </text>
      </>
    ) : (
      <DashboardNoData
        x={x - BILLING_CLAIM_X - 10}
        y={y + BILLING_CLAIM_Y}
        width="43"
        height="16"
        radius={1}
      />
    );
  };

  return (
    <div className={styles.billingView}>
      <Row className={styles.titleRow}>
        <Col span={12}>
          <p className={styles.recentTitle}>Recent Billing</p>
        </Col>
        <Col span={8} offset={4}>
          {isSuccess && chartObj.length ? (
            <p
              className={styles.tableEndText}
              onClick={() => {
                navigate(
                  `/brokers/${brokerId}/employers/${employerId}/billing`
                );
              }}
            >
              View More
            </p>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      {!isError ? (
        isLoading ? (
          <CustomSpinner />
        ) : isSuccess && chartObj.length ? (
          <Row>
            <ResponsiveContainer width="99%" height={330}>
              <BarChart data={chartObj}>
                <CartesianGrid
                  vertical={false}
                  stroke={COLOR_BILLING_CHART_STOKE}
                />
                <XAxis
                  dataKey="name"
                  hide={false}
                  axisLine={false}
                  minTickGap={0}
                  tickMargin={10}
                  interval={0}
                  padding={{ left: 0, right: 0 }}
                  label={''}
                  tick={({ payload, x, y }: any) => {
                    const { value } = payload;
                    return xAxisTick(value, x, y);
                  }}
                  tickLine={{ height: 40, stroke: COLOR_LIGHT_GREY }}
                  height={50}
                />
                <YAxis
                  hide={false}
                  axisLine={false}
                  tickFormatter={(value: number) => {
                    return `${shortenCurrencyWithSuffix(value, 2)}`;
                  }}
                  tick={(data) => {
                    return (
                      <g transform={`translate(${0},${data.y})`}>
                        <text
                          x={0}
                          y={data.index === 4 ? -2 : 5}
                          textAnchor="start"
                          fill="#666"
                          className={styles.yAxisTick}
                          fontWeight={600}
                        >
                          <>
                            $
                            {shortenCurrencyWithSuffix(
                              data.payload.value,
                              2
                            ).substring(1)}
                          </>
                        </text>
                      </g>
                    );
                  }}
                  minTickGap={0}
                  width={70}
                  tickMargin={10}
                  label={''}
                  tickLine={{ height: 40, stroke: COLOR_LIGHT_GREY }}
                  height={50}
                />
                <Bar
                  className={styles.barChart}
                  fill={COLOR_BILLING_CHART_NON_HOVER}
                  dataKey="amount"
                  maxBarSize={60}
                >
                  {chartObj.map((data: any, index: any) => {
                    return (
                      <Cell
                        fill={
                          index == chartObj.length - 1
                            ? COLOR_MEDIUM_SEA_GREEN
                            : data.isExist
                            ? COLOR_BILLING_CHART_NON_HOVER
                            : COLOR_DARK_GREY_LIGHT_SHADE
                        }
                        key={index}
                      />
                    );
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Row>
        ) : (
          <NoBillingViewDashboard isUploaderOpen={checkIfDataExistForRecords} />
        )
      ) : (
        <NoBillingViewDashboard isUploaderOpen={checkIfDataExistForRecords} />
      )}
    </div>
  );
};

export default DashboardBillingClaimChart;
