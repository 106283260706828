import { getPlanDocument } from 'modules/plans/services/PlanService';
import {
  FORMAT_VALIDATE,
  SIZE_VALIDATE,
  TOTAL_UPLOAD_SIZE,
} from 'util/commonUtil';

/**
 * Causes a download to occur for the specified file. Useful in cases where
 * we want user to redownloaded a file they've uploaded
 * @param {File} data The file to be downloaded
 */
export const getDownloadFileObj = (data: File) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  const url = window.URL.createObjectURL(data);
  a.href = url;
  a.download = data.name;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const openPlanDocument = async (
  planId: string,
  docType: string,
  benefitKind: string
) => {
  const response = await getPlanDocument(planId, docType, benefitKind);
  const blob = new Blob([response.data], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);

  window.open(url);
};

export const openAndDownloadPlanDocument = async (
  planId: string,
  docType: string,
  benefitKind: string,
  file: File
) => {
  const response = await getPlanDocument(planId, docType, benefitKind);
  const blob = new Blob([response.data], { type: 'application/pdf' });

  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = file.name;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const getBlobFromEncodedImageString = async (imageString: string) => {
  const imageResponse = await fetch(imageString);
  const imageBlob = await imageResponse.blob();
  return imageBlob;
};

export const getFileSizeInMB = (fileSize: number) => {
  return fileSize / 1024 / 1024;
};

export const getFileUploadErrorMessages = (
  validateSetting: string,
  individualFileUploadLimit: number = 10,
  totalFileUploadLimit: number = 20
) => {
  switch (validateSetting) {
    case FORMAT_VALIDATE:
      return {
        title: 'File Upload Failed',
        message:
          'Invalid file type uploaded. Submitted files must be in following formats.\npdf, csv, xlsx, xls, docx, doc, pptx, ppt, png, jpeg, jpg, zip',
      };
    case SIZE_VALIDATE:
      return {
        title: 'File Upload Failed',
        message: `The submitted file exceeds the file size limit of ${individualFileUploadLimit} MB.\nPlease confirm that you are uploading the correct file or reduce the file’s size.`,
      };
    case TOTAL_UPLOAD_SIZE:
      return {
        title: 'File Upload Failed',
        message: `The submitted files exceed the total file size limit of ${totalFileUploadLimit} MB.\nPlease confirm that you are uploading the correct files or reduce the size of the files.`,
      };

    default:
      return {
        title: '',
        message: '',
      };
  }
};

/**
 * Enum for maintaining all file-types and corresponding mime-types used the system.
 * Please extend this object if trying to use a file-type not present in the list.
 */
export const MIME_TYPES = {
  PDF: 'application/pdf',
  CSV: 'text/csv',
  XLS: 'application/vnd.ms-excel',
  DOC: 'application/msword',
  PPT: 'application/vnd.ms-powerpoint',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  POTX: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  PPSX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  ZIP: 'application/zip',
} as const;

export const incrementNumberInFileName = (fileName: string): string => {
  const pattern = /\((\d+)\)$/;
  const match = fileName.match(pattern);

  if (match) {
    let number = parseInt(match[1]);
    number++;
    const incrementedNumber = `(${number})`;
    return fileName.replace(pattern, incrementedNumber);
  } else {
    return `${fileName} (1)`;
  }
};

export const getDocumentNameWithHighestNumber = (
  resourceNameList: string[],
  baseName: string
): string => {
  const baseNamePattern = new RegExp(`^${baseName}(?: \\((\\d+)\\))?$`);

  if (resourceNameList.length > 0) {
    // Filter documents that match the base name pattern
    const filteredDocuments = resourceNameList.filter((resource) =>
      baseNamePattern.test(resource)
    );
    if (filteredDocuments.length === 0) {
      return baseName; // No matching documents
    }
    const highest = resourceNameList.reduce((highestDoc, currentDoc) => {
      const highestNumber = highestDoc.match(baseNamePattern)?.[1];
      const currentNumber = currentDoc.match(baseNamePattern)?.[1];

      const highestNum = highestNumber ? parseInt(highestNumber) : 0;
      const currentNum = currentNumber ? parseInt(currentNumber) : 0;

      return currentNum > highestNum ? currentDoc : highestDoc;
    });
    return highest ? highest : baseName;
  } else {
    return baseName;
  }
};
