import { useState } from 'react';
import { Empty, Input, List, Row, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { ReactComponent as SearchIcon } from 'assets/images/icon-search.svg';

import styles from './ieSelect.module.less';

type OptionProps = {
  label: string;
  value: any;
  isUnVerified?: boolean;
};

type ShowSelectedProps = {
  showSelect: true;
  value: any;
};

type HideSelectedProps = {
  showSelect?: false;
  value?: any;
};

type IESelectProps = {
  options: OptionProps[];
  placeholder?: string;
  showSearch?: boolean;
  sort?: boolean;
  onSelectOption: (value: OptionProps) => void;
} & (ShowSelectedProps | HideSelectedProps);

const IESelect = (props: IESelectProps) => {
  const {
    options,
    placeholder,
    showSearch = true,
    showSelect = false,
    value,
    sort = false,
    onSelectOption,
  } = props;

  const [filter, setFilter] = useState('');

  const getFilteredOption = (options: any[]) => {
    let optionsArray: OptionProps[] = [];
    if (sort) {
      optionsArray = options
        ?.filter(
          (f) =>
            f?.label?.toLowerCase().includes(filter.toLowerCase()?.trim()) ||
            filter === ''
        )
        .sort((a, b) => a.label.localeCompare(b.label));
    } else {
      optionsArray = options?.filter(
        (f) =>
          f?.label?.toLowerCase().includes(filter.toLowerCase()?.trim()) ||
          filter === ''
      );
    }

    return optionsArray;
  };
  return (
    <div className={styles.selectWrapper}>
      <>
        {showSearch && (
          <div className={styles.searchInputWrapper}>
            <Input
              placeholder={placeholder}
              className={styles.searchInput}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              suffix={<SearchIcon className={styles.searchIcon} />}
            />
          </div>
        )}
        <div className={styles.selectOptionsList}>
          <List locale={{ emptyText: null }}>
            {getFilteredOption(options)?.length === 0 ? (
              <div className={styles.emptyMessage}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            ) : (
              getFilteredOption(options)?.map((item) => {
                return (
                  <List.Item
                    className={`${styles.listItem} ${
                      showSelect && item.value === value && styles.selected
                    } `}
                    key={item?.value}
                    onClick={() => {
                      if (!item?.isUnVerified) {
                        onSelectOption(item);
                        setFilter('');
                      }
                    }}
                  >
                    <Row
                      justify="space-between"
                      align="middle"
                      className={styles.isTaggedContainer}
                    >
                      <div>
                        <CheckOutlined
                          className={`${styles.checkIcon} ${
                            showSelect &&
                            item.value === value &&
                            styles.showCheckItem
                          }`}
                        />
                        <Typography.Text className={styles.label} ellipsis>
                          {item?.label}
                        </Typography.Text>

                        {item?.isUnVerified && (
                          <span className={styles.notVerifiedLabel}>
                            (not verified)
                          </span>
                        )}
                      </div>
                    </Row>
                  </List.Item>
                );
              })
            )}
          </List>
        </div>
      </>
    </div>
  );
};

export default IESelect;
