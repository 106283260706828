import { FC } from 'react';
import { Dropdown, Menu, Row, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cloneDeep from 'lodash/cloneDeep';
import { useAppSelector } from 'hooks/redux';
import { getFileNameEllipsis } from 'util/stringUtil';
import PlanyearPopover from 'components/PlanyearPopover/PlanyearPopover';
import { OFFERED_PLAN_PROCESSING_POP_UP } from 'modules/renewals/constants/renewalsConstants';
import FromCurrentPercentage from 'modules/renewals/components/helperComponents/FromCurrentPercentage/FromCurrentPercentage';
import { formatHeaderValues } from 'modules/renewals/utils/renewalsUtils';
import { SelectedPlanProps } from 'modules/plans/components/AddPlanModal/AddPlanModal';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import { ReactComponent as AIFlashIcon } from 'assets/images/ai-flash-white.svg';
import { DocumentExtractionSource } from 'modules/plans/enums/DocumentExtractionSource';
import { handleFileItemDownloadClickForPopulatedFileItem } from 'modules/renewals/services/RenewalService';
import { NewPlanTag } from 'modules/renewals/components/NewPlanTag/NewPlanTag';

import { useNavContext } from 'hooks/useNavContext';
import { exportPlanComparisonSummary } from 'modules/renewals/slices/proposalSlice';
import styles from './offerCardPlansTable.module.less';

export type PlanProcessing = {
  isLoading: boolean;
  isProcessing: boolean;
};

type OfferCardPlansTableProps = {
  plans: any;
  headerElement: JSX.Element | null;
  isVoluntaryBenefit: boolean;
  isDtqOrPending?: boolean;
  isPlaceHolderCreated?: boolean;
  isNonMdv?: boolean;
  planDocuments?: any;
  setAddPlansPropsToState: (props: SelectedPlanProps) => void;
  offer: any;
  planProcessing?: PlanProcessing;
  planYearId?: string | null;
  category?: string;
};

const OfferCardPlansTable: FC<OfferCardPlansTableProps> = (
  props: OfferCardPlansTableProps
) => {
  const {
    plans,
    headerElement,
    isVoluntaryBenefit,
    isDtqOrPending,
    isPlaceHolderCreated = false,
    isNonMdv = false,
    planDocuments,
    setAddPlansPropsToState,
    offer,
    planProcessing,
    planYearId,
    category,
  } = props;

  const { employerId } = useNavContext();
  const { isOpsAdmin } = useAppSelector(
    (state: any) => state.auth.auth.appBootupInfo ?? {}
  );

  const getFromCurrent = (plan: any) => {
    if (
      plan.comparisons?.initialDiff === undefined &&
      plan.comparisons?.initialDiffPercent === undefined
    ) {
      return <Row>-</Row>;
    }

    return (
      <Row>
        {formatHeaderValues(plan.comparisons?.initialDiff, 'CURRENCY', true)}
        <span className={styles.fromCurrentPercentage}>
          {!isPlaceHolderCreated && !plan?.newPlan ? (
            <FromCurrentPercentage
              percentage={plan.comparisons?.initialDiffPercent}
              showBrackets={true}
              decimalPlaces={2}
            />
          ) : (
            <span className={styles.notApplicablePercentageChange}>(N/A)</span>
          )}
        </span>
      </Row>
    );
  };

  const columns: ColumnsType<any> = [
    {
      width: '60%',
      title: headerElement,
      key: 'planName',
      render: (_, plan: any) => (
        <Row>
          <span className={styles.planName}>
            {plan.planName ? plan.planName : '-'}
          </span>
          {plan?.newPlan && (
            <NewPlanTag className={styles.offerCardNewPlanTagWrapper} />
          )}
        </Row>
      ),
    },
    {
      width: '20%',
      title: <span className={styles.headerText}>ANNUAL PREMIUM</span>,
      key: 'annualPremium',
      render: (_, plan: any) => (
        <span>
          {plan.annualContributionDefault?.totalPremium
            ? formatHeaderValues(
                plan.annualContributionDefault?.totalPremium,
                'CURRENCY',
                false
              )
            : '-'}
        </span>
      ),
    },
    {
      width: '20%',
      title: <span className={styles.headerText}>FROM CURRENT</span>,
      key: 'fromCurrent',
      render: (_, plan: any) => {
        return isNonMdv
          ? getFromCurrent(plan)
          : plan?.newPlan
          ? '-'
          : getFromCurrent(plan);
      },
    },
    {
      title: <span className={styles.headerText}>ACTIONS</span>,
      dataIndex: 'actions',
      key: 'actions',
      align: 'center' as const,
      width: '10%',
      render: (_: any, plan: any) => (
        <SelectDropdown overlay={getActionMenu(plan)} />
      ),
    },
  ];

  const getActionMenu = (plan: any) => {
    return (
      <Menu>
        {planProcessing?.isLoading ? (
          <Spin />
        ) : planProcessing?.isProcessing ? (
          <PlanyearPopover
            content={OFFERED_PLAN_PROCESSING_POP_UP}
            zIndex={9999}
            placement="top"
          >
            <Menu.Item
              disabled={isOpsAdmin}
              className={styles.disabledMenuOption}
            >
              <div className={styles.gridContainer}>
                <AIFlashIcon />
                <span>Update Plan Parameters</span>
              </div>
            </Menu.Item>
          </PlanyearPopover>
        ) : (
          <Menu.Item
            disabled={isOpsAdmin}
            onClick={() => redirectPlanModal(plan)}
          >
            <div className={styles.gridContainer}>
              <AIFlashIcon />
              <span>Update Plan Parameters</span>
            </div>
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            {
              exportPlanComparisonSummary(
                employerId!,
                plan?.planId,
                category!,
                planYearId!,
                offer?.id,
                plan?.planName,
                offer?.name,
                offer?.createdByLoginId
              ).catch((error) => {});
            }
          }}
        >
          <div className={styles.gridContainer}>
            <div></div>
            <span>Compare to Current Plans</span>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const voluntaryBenefitColumns: ColumnsType<any> = [
    {
      width: '60%',
      title: headerElement,
      key: '',
      render: (_, planName: any) => (
        <span className={styles.planName}>{planName ? planName : '-'}</span>
      ),
    },
    {
      width: '40%',
      title: <span className={styles.headerText}>PLAN DOCUMENTS</span>,
      key: '',
      render: (_, planName: any) => {
        {
          const documentsArray = planDocuments[planName];
          const adminActionsMenu = (attachments: any[]) => {
            return planDocuments[planName]?.length ? (
              <Menu className={styles.scrollableMenu}>
                {attachments.map((attachment) => {
                  return (
                    <Menu.Item
                      key={attachment.referenceId}
                      onClick={() => {
                        handleFileItemDownloadClickForPopulatedFileItem(
                          attachment.downloadUrl,
                          attachment.fileName
                        );
                      }}
                    >
                      <span className={styles.dropDownItem}>
                        {getFileNameEllipsis({
                          fileName: String(attachment?.fileName),
                          limit: 25,
                        })}
                      </span>
                    </Menu.Item>
                  );
                })}
              </Menu>
            ) : (
              <></>
            );
          };
          return (
            <>
              <Dropdown
                trigger={['click']}
                overlay={adminActionsMenu(documentsArray)}
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentElement
                    ? triggerNode.parentElement
                    : triggerNode
                }
              >
                <p
                  className={styles.attachmentLabel}
                  onClick={(e) => e.preventDefault()}
                >
                  {!planDocuments[planName]?.length ? (
                    <span>-</span>
                  ) : (
                    <span className={styles.attachmentText}>
                      {`${planDocuments[planName]?.length} ${
                        planDocuments[planName]?.length === 1
                          ? 'Document'
                          : 'Documents'
                      }`}
                    </span>
                  )}
                  {}
                </p>
              </Dropdown>
            </>
          );
        }
      },
    },
  ];

  const getPlans = () => {
    if (isDtqOrPending) {
      if (isVoluntaryBenefit) {
        return ['-'];
      } else {
        return [{}];
      }
    }
    const clonedPlans = cloneDeep(plans);
    return plans
      ? clonedPlans?.sort((a: any, b: any) => (a.newPlan ? 1 : -1))
      : [];
  };

  const redirectPlanModal = (plan: any) => {
    setAddPlansPropsToState({
      isOpen: true,
      subType: plan?.planType,
      docExtractionSource: {
        sourceOfferId: offer?.id,
        sourceOfferName: offer?.name,
        planId: plan?.planId,
        planName: plan?.planName,
        source: DocumentExtractionSource.UPDATE_QUOTE_PLAN,
      },
    } as SelectedPlanProps);
  };

  return (
    <Table
      columns={isVoluntaryBenefit ? voluntaryBenefitColumns : columns}
      dataSource={getPlans()}
      pagination={false}
    />
  );
};

export default OfferCardPlansTable;
