import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { Popover, Switch } from 'antd';
import { DataColumn } from 'components/DataTable/DataColumn';
import ToolList from 'components/ToolList/ToolList';
import {
  useGetEmployersQuery,
  useUpdateEmployerFeatureStatusMutation,
} from 'api/featureControl';
import TablePagination from 'model/TablePagination';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { EMPLOYER_ACCESS_LOCKED } from 'constants/commonConstants';
import EmployerFeatureToggle from 'components/EmployerFeatureToggle/EmployerFeatureToggle';
import EmployerVisibilityOption from 'components/EmployerVisibilityOption/EmployerVisibilityOption';

import styles from './featureList.module.less';

type EmployerBillingFeatureListProps = {};

const EmployerBillingFeatureList: FC<EmployerBillingFeatureListProps> = (
  props: EmployerBillingFeatureListProps
) => {
  const EMPTY_LABEL = '-';

  const navigate = useNavigate();
  const { brokerId } = useParams();
  const [updateStatus, { isSuccess }] = useUpdateEmployerFeatureStatusMutation(
    {}
  );
  const [searchText, setSearchText] = useState<string>('');
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState('name');
  const {
    data: featureWrappers = [],
    refetch,
    isFetching,
  } = useGetEmployersQuery({
    page: pageIndex,
    organizationId: brokerId as string,
    key: 'BILLING',
    query: searchText,
    size: pageSize,
    sort: sortOrder,
  });

  const navigateToBilling = (employerName: string) => {
    featureWrappers.forEach((employer: any) => {
      if (employer.employerName === employerName) {
        navigate(
          `/brokers/${brokerId}/employers/${employer.employerId}/billing`,
          {
            state: { bypassInvalidRoute: true },
          }
        );
        return;
      }
    });
  };

  const buildFeatureData = (wrappers: []) => {
    return wrappers.map((employerFeature: any) => {
      if (
        employerFeature.featureData &&
        employerFeature.featureData.latestPublishedData
      ) {
        employerFeature = {
          ...employerFeature,
          latestPublishedData: employerFeature.featureData.latestPublishedData,
        };
      } else {
        employerFeature = {
          ...employerFeature,
          latestPublishedData: EMPTY_LABEL,
        };
      }

      return employerFeature;
    });
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [refetch, isSuccess]);

  useEffect(() => {
    refetch();
  }, [refetch, searchText]);

  const dataColumns: DataColumn[] = [
    {
      title: 'EMPLOYER',
      dataIndex: 'employerName',
      key: 'employerName',
      width: '58%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => a.employerName.localeCompare(b.employerName),
      defaultSortOrder: 'ascend',
      render: (item, index) => {
        return (
          <OverflowPopover key={index}>
            <a
              className={'text table-item-link'}
              onClick={() => navigateToBilling(item)}
            >
              {item}
            </a>
          </OverflowPopover>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'LATEST BILLING DATA',
      dataIndex: 'latestPublishedData',
      key: 'latestPublishedData',
      width: '40%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: any, b: any) => {
        if (a.latestPublishedData === EMPTY_LABEL) {
          return 1;
        }
        const dateStrA = a.latestPublishedData.split(' ');
        const monthA = dateStrA[0];
        const yearA = dateStrA[1];
        const dateStrB = b.latestPublishedData.split(' ');
        const monthB = dateStrB[0];
        const yearB = dateStrB[1];
        if (yearA !== yearB) {
          return yearA.localeCompare(yearB);
        } else if (monthA === 'January') {
          return -1;
        } else if (monthB === 'January') {
          return 1;
        } else if (monthA === 'February') {
          return -1;
        } else if (monthB === 'February') {
          return 1;
        } else if (monthA === 'March') {
          return -1;
        } else if (monthB === 'March') {
          return 1;
        } else if (monthA === 'April') {
          return -1;
        } else if (monthB === 'April') {
          return 1;
        } else if (monthA === 'May') {
          return -1;
        } else if (monthB === 'May') {
          return 1;
        } else if (monthA === 'June') {
          return -1;
        } else if (monthB === 'June') {
          return 1;
        } else if (monthA === 'July') {
          return -1;
        } else if (monthB === 'July') {
          return 1;
        } else if (monthA === 'August') {
          return -1;
        } else if (monthB === 'August') {
          return 1;
        } else if (monthA === 'September') {
          return -1;
        } else if (monthB === 'September') {
          return 1;
        } else if (monthA === 'October') {
          return -1;
        } else if (monthB === 'October') {
          return 1;
        } else if (monthA === 'November') {
          return -1;
        } else if (monthB === 'November') {
          return 1;
        } else if (monthA === 'December') {
          return -1;
        } else if (monthB === 'December') {
          return 1;
        } else {
          return 0;
        }
      },
      render: (item, index) => {
        return isEmpty(item) ? (
          <div key={index}>-</div>
        ) : (
          <OverflowPopover key={index}>
            <span>{item}</span>
          </OverflowPopover>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'lastUpdatedTs',
      key: 'lastUpdatedTs',
      width: '40%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (isEmpty(a.lastUpdatedTs)) {
          return 1;
        } else if (isEmpty(b.lastUpdatedTs)) {
          return -1;
        } else {
          return new Date(a.lastUpdatedTs).getTime() >
            new Date(b.lastUpdatedTs).getTime()
            ? 1
            : -1;
        }
      },
      render: (colData, record, index) => {
        const updatedAt = dayjs(colData).format('MMMM D, YYYY h:mm A');
        return isEmpty(colData) ? (
          <div key={index}>-</div>
        ) : (
          <div key={index}>
            {updatedAt}
            <br></br>
            <span className={styles.addedBy}>
              by {record.lastUpdatedByAdminName}
            </span>
          </div>
        );
      },
    },
    {
      title: 'EMPLOYER VISIBILITY',
      dataIndex: 'employerVisibilityEnabled',
      key: 'employerVisibilityEnabled',
      width: '20%',
      render: (colData, record) => {
        return (
          <>
            <EmployerVisibilityOption
              onConfirmToggle={() => {
                updateStatus({
                  enabled: record.enabled,
                  employerVisibilityEnabled: !colData,
                  organizationId: record.organizationId,
                  employerId: record.employerId,
                  key: 'BILLING',
                }).then(() => {
                  refetch();
                });
              }}
              enabled={record.enabled}
              employerVisibilityEnabled={colData}
              employerName={record.employerName}
              toolName={'Billing'}
            />
          </>
        );
      },
      align: 'left',
    },
    {
      title: 'ENABLED',
      dataIndex: 'enabled',
      key: 'enabled',
      width: '15%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      sorter: (a: any, b: any) => {
        if (a.enabled) {
          return -1;
        } else {
          return 1;
        }
      },
      render: (colData, record, index) => {
        return (
          <>
            {record.locked ? (
              <Popover
                trigger="hover"
                content={EMPLOYER_ACCESS_LOCKED}
                placement="left"
                overlayClassName="provisionStatusPopover"
              >
                <Switch onChange={() => {}} checked={false} disabled={true} />
              </Popover>
            ) : (
              <EmployerFeatureToggle
                onConfirmToggle={() => {
                  updateStatus({
                    enabled: !colData,
                    employerVisibilityEnabled: record.employerVisibilityEnabled,
                    organizationId: record.organizationId,
                    employerId: record.employerId,
                    key: 'BILLING',
                  });
                }}
                enabled={colData}
                recordName={record.employerName}
                enableMessage={
                  'Are you sure you want to enable billing? This employer will be able to access their billing data.'
                }
                disableMessage={
                  "Are you sure you want to disable Billing? This tool will be immediately hidden from the employer's view."
                }
                title={'Billing'}
                key={index}
              />
            )}
          </>
        );
      },
      align: 'left',
    },
  ];

  const [paginationConfig, setPaginationConfig] = useState<TablePagination>({
    sorterInfo: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
    paginationIndex: 1,
    filterInfo: {
      limit: 10,
      offset: 0,
      searchText: searchText,
    },
    filters: {},
  });

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const _searchText = e.target.value.trim();

    setSearchText(_searchText);
  };

  useEffect(() => {
    setPaginationConfig(paginationConfig);
    setPageIndex(paginationConfig.paginationIndex);
    setPageSize(paginationConfig.filterInfo.limit);
    const { field, order } = (paginationConfig.sorterInfo as any) || {};
    if (isEmpty(order)) {
      setSortOrder('');
    } else if (field == 'employerName' && order == 'descend') {
      setSortOrder('-name');
    } else if (field == 'employerName' && order == 'ascend') {
      setSortOrder('name');
    }
  }, [paginationConfig, isFetching]);

  return (
    <div className={styles.featureTableWrapper}>
      <ToolList
        title="Billing Configuration"
        columns={dataColumns}
        data={buildFeatureData(featureWrappers)}
        search={'Search Employers'}
        handleSearch={handleSearch}
        isLoading={isFetching}
      ></ToolList>
    </div>
  );
};

export default EmployerBillingFeatureList;
