import axios from 'axios';
import { baseApi } from 'util/apiUtil';

const baseUrl = baseApi + '/v1/communications/in-app-notifications';

export const getNewNotificationCount = (
  individualId: string,
  daysOld: number
) => {
  const params = {
    'days-old': daysOld,
  };
  return axios.get(
    `${baseUrl}/individuals/${individualId}/new-notifications-count`,
    { params }
  );
};

export const getNotifications = (individualId: string, daysOld: number) => {
  const params = {
    'days-old': daysOld,
  };
  return axios.get(`${baseUrl}/individuals/${individualId}`, { params });
};

export const readNotification = (notificationId: string) => {
  return axios.put(`${baseUrl}/${notificationId}`);
};
