import { FC, useState, useRef, useEffect } from 'react';

import isEqual from 'lodash/isEqual';

import { Col, Row, Select } from 'antd';

import {
  Color,
  RGBAModel,
  convertHexToRgba,
  convertToColorString,
  exportStylingColorConfigurationTypes,
  fontObjects,
  rgbaToHex,
  validateHex,
} from 'util/exportStylingUtil';
import ColorPicker from 'components/ColorPicker/ColorPicker';
import SearchBar from 'components/SearchBar/SearchBar';
import styles from './exportStylingModal.module.less';

type ExportStylingModalProps = {
  tableHeaderColor: RGBAModel;
  setTableHeaderColor: (color: RGBAModel) => void;
  tableHeaderFontColor: RGBAModel;
  setTableHeaderFontColor: (color: RGBAModel) => void;
  selectedFont: any;
  setSelectedFont: (font: any) => void;
};

const ExportStylingModal: FC<ExportStylingModalProps> = (
  props: ExportStylingModalProps
) => {
  const {
    tableHeaderColor,
    setTableHeaderColor,
    tableHeaderFontColor,
    setTableHeaderFontColor,
    selectedFont,
    setSelectedFont,
  } = props;

  const { TABLE_HEADER_FONT_COLOR, TABLE_HEADER_COLOR } =
    exportStylingColorConfigurationTypes;

  const [isVisibleTableHeaderColorPicker, setIsVisibleTableHeaderColorPicker] =
    useState<boolean>(false);
  const [
    isVisibleTableHeaderFontColorPicker,
    setIsVisibleTableHeaderFontColorPicker,
  ] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredFonts, setFilteredFonts] = useState<any>(fontObjects);

  const getDefaultStyle = (type: RGBAModel) => {
    return {
      width: '19px',
      height: '17px',
      background: `rgb(
            ${type.r !== null ? type.r : 0},
            ${type.g !== null ? type.g : 0},
            ${type.b !== null ? type.b : 0})`,
    };
  };
  const tableHeaderColorPickerRef = useRef<any>(null);
  const tableHeaderFontColorPickerRef = useRef<any>(null);

  const colorPickerStyles = () => {
    return {
      tableHeaderColor: {
        ...getDefaultStyle(tableHeaderColor),
        borderRight: 'none',
      },
      tableHeaderFontColor: {
        ...getDefaultStyle(tableHeaderFontColor),
        borderRight: 'none',
      },
    };
  };

  const handleClose = (type: string) => {
    if (type === TABLE_HEADER_COLOR) {
      setIsVisibleTableHeaderColorPicker(false);
    } else if (type === TABLE_HEADER_FONT_COLOR) {
      setIsVisibleTableHeaderFontColorPicker(false);
    }
  };

  const handleColorChange = (color: Color, type: string) => {
    if (type === TABLE_HEADER_COLOR) {
      setTableHeaderColor(color.rgb);
    } else if (type === TABLE_HEADER_FONT_COLOR) {
      setTableHeaderFontColor(color.rgb);
    }
  };

  const handleClick = (type: string) => {
    if (type === TABLE_HEADER_COLOR) {
      setIsVisibleTableHeaderColorPicker(!isVisibleTableHeaderColorPicker);
    } else if (type === TABLE_HEADER_FONT_COLOR) {
      setIsVisibleTableHeaderFontColorPicker(
        !isVisibleTableHeaderFontColorPicker
      );
    }
  };

  const handleColorInput = (event: any) => {
    const { name, value } = event.target;
    const _value = isEqual(value.charAt(0), '#') ? value : '#'.concat(value);
    if (validateHex(_value) && _value.length === 7) {
      const rgba = convertHexToRgba(_value);
      if (name === TABLE_HEADER_COLOR) {
        setTableHeaderColor(rgba);
      } else if (name === TABLE_HEADER_FONT_COLOR) {
        setTableHeaderFontColor(rgba);
      }
    }
  };

  useEffect(() => {
    const lowercasedInput = searchInput.toLowerCase();
    let filtered = fontObjects.filter((font) =>
      font.fontName.toLowerCase().includes(lowercasedInput)
    );

    if (selectedFont && lowercasedInput === '') {
      const selectedFontObject = fontObjects.find(
        (font) => font.fontName === selectedFont
      );
      if (selectedFontObject) {
        filtered = filtered.filter((font) => font.fontName !== selectedFont);
        filtered.unshift(selectedFontObject);
      }
    }

    setFilteredFonts(filtered);
  }, [searchInput, selectedFont]);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleFontSelection = (value: any) => {
    setSelectedFont(value);
    setSearchInput('');
  };

  const fontOptions = () => {
    return filteredFonts.map((font: any) => (
      <Select.Option
        key={font.fontName}
        value={font.fontStyle}
        style={{ fontFamily: font.fontStyle }}
      >
        {font.fontName}
      </Select.Option>
    ));
  };

  return (
    <div>
      <div className={styles.title}>Export Styling:</div>
      <Row>
        <Col span={12}>
          <div className={styles.fontTitle}>Font</div>
          <div className={styles.customSelectContainer}>
            <Select
              value={selectedFont}
              onChange={handleFontSelection}
              dropdownRender={(menu) => (
                <>
                  <div className={styles.searchFilter}>
                    <SearchBar
                      placeholder={'Search Fonts'}
                      isLarge={false}
                      onChange={handleSearchInputChange}
                      value={searchInput}
                    />
                  </div>
                  {menu}
                </>
              )}
            >
              {fontOptions()}
            </Select>
          </div>
        </Col>
        <Col span={6}>
          <div className={styles.columnTitle}> Table Header</div>
          <ColorPicker
            name="tableHeaderColor"
            styleColor={colorPickerStyles().tableHeaderColor}
            textColor={
              validateHex(rgbaToHex(convertToColorString(tableHeaderColor)))
                ? rgbaToHex(convertToColorString(tableHeaderColor))
                : '#'
            }
            placeholder={rgbaToHex(convertToColorString(tableHeaderColor))}
            value={rgbaToHex(convertToColorString(tableHeaderColor))}
            handleClick={() => handleClick(TABLE_HEADER_COLOR)}
            handleClose={() => handleClose(TABLE_HEADER_COLOR)}
            handleChange={(color: Color) =>
              handleColorChange(color, TABLE_HEADER_COLOR)
            }
            displayColorPicker={isVisibleTableHeaderColorPicker}
            handleInputChange={handleColorInput}
            ref={tableHeaderColorPickerRef}
          />
        </Col>
        <Col span={6}>
          <div className={styles.columnTitle}>Table Header Font</div>
          <ColorPicker
            name="tableHeaderFontColor"
            styleColor={colorPickerStyles().tableHeaderFontColor}
            textColor={
              validateHex(rgbaToHex(convertToColorString(tableHeaderFontColor)))
                ? rgbaToHex(convertToColorString(tableHeaderFontColor))
                : '#'
            }
            placeholder={rgbaToHex(convertToColorString(tableHeaderFontColor))}
            value={rgbaToHex(convertToColorString(tableHeaderFontColor))}
            handleClick={() => handleClick(TABLE_HEADER_FONT_COLOR)}
            handleClose={() => handleClose(TABLE_HEADER_FONT_COLOR)}
            handleChange={(color: Color) =>
              handleColorChange(color, TABLE_HEADER_FONT_COLOR)
            }
            displayColorPicker={isVisibleTableHeaderFontColorPicker}
            handleInputChange={handleColorInput}
            ref={tableHeaderFontColorPickerRef}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ExportStylingModal;
