import { FC, useEffect, useState } from 'react';
import { Tabs, TabsProps } from 'antd';
import TabType from 'model/TabType';

const { TabPane } = Tabs;

import styles from './tabView.module.less';

interface TabViewProps extends TabsProps {
  onClick: () => void;
  tabOptions: TabType[];
  defaultTabKey: string;
  onChange: (key: string) => void;
  className?: string;
}

const TabView: FC<TabViewProps> = (props) => {
  const { onChange, tabOptions, defaultTabKey, className, tabBarExtraContent } =
    props;
  const [activeKey, setActiveKey] = useState<string>(defaultTabKey);

  useEffect(() => {
    setActiveKey(defaultTabKey);
  }, [defaultTabKey]);

  return (
    <div className={`${styles.tabViewWrapper} ${className}`}>
      <Tabs
        type="card"
        defaultActiveKey={defaultTabKey}
        size="large"
        onTabClick={(key) => {
          setActiveKey(key);
          onChange(key);
        }}
        activeKey={activeKey}
        tabBarExtraContent={!!tabBarExtraContent && tabBarExtraContent}
      >
        {tabOptions.map((tabOption) => (
          <TabPane tab={tabOption.tabLabel} key={tabOption.tabKey}>
            {tabOption.tabData}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default TabView;
