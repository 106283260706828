import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Col, notification, Row } from 'antd';

import CardTopStatusTag from 'modules/renewals/components/helperComponents/CardTopStatusTag/CardTopStatusTag';
import ProposalCardTable from 'modules/renewals/components/ProposalCard/ProposalCardTable/ProposalCardTable';
import ProposalCardOverview from 'modules/renewals/components/ProposalCard/ProposalCardOverview/ProposalCardOverview';
import ProfileAvatar from 'components/Avatar/ProfileAvatar';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import ProposalCardReviewModal from 'modules/renewals/components/ProposalCard/ProposalCardReviewModal/ProposalCardReviewModal';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import {
  CreditsInclusionType,
  IProposalActionsDropDownData,
  IProposalCardData,
  ProposalCardActionOptionType,
  ProposalCardActionType,
} from 'modules/renewals/types/renewalsTypes';
import { getTextEllipsis } from 'util/stringUtil';
import {
  MARK_AS_FINAL_PROPOSAL_MODAL_BROKER_BODY_TEXT,
  MARK_AS_FINAL_PROPOSAL_MODAL_CONFIRMATION_TEXT,
  MARK_AS_FINAL_PROPOSAL_MODAL_EMPLOYER_BODY_TEXT,
  PROPOSAL_CARD_ACTION_OPTIONS,
  PROPOSAL_CARD_DESCRIPTION_TEXT_LIMIT,
  MARK_AS_FINAL_APPROVED_MODAL_BROKER_BODY_TEXT_SECOND_PARA,
  DRAFT_OFFER_EXIST_ON_PROPOSAL_WARNING_MESSAGE,
  DRAFT_OFFER_EXIST_ON_PROPOSAL_BUTTON_TITLE,
  DRAFT_OFFER_EXIST_ON_PROPOSAL_WITH_SAME_NAME_WARNING_MESSAGE,
} from 'modules/renewals/constants/renewalsConstants';
import {
  exportDetailedProposalExcel,
  exportProposalAuditLog,
  useDeleteProposalMutation,
  useLazyCheckDraftOfferExistForProposalQuery,
} from 'modules/renewals/slices/proposalSlice';

import xlsIcon from 'assets/images/xls-icon.svg';
import calenderIcon from 'assets/images/icon-calendar-small.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/icon-download.svg';
import { showSuccessNotification } from 'components/Notification/Notification';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { loginTypes } from 'constants/authConstants';
import { setIsFromProposalNavigatePY } from 'modules/renewals/slices/offerReducerSlice';
import { filterProposalOptionsForPlanYear } from 'modules/renewals/utils/renewalsUtils';

import styles from 'modules/renewals/components/ProposalCard/ProposalCard.module.less';
import { IndividualSubTypes } from 'constants/commonConstants';

type ProposalCardProps = {
  data: IProposalCardData;
  creditsInclusion: CreditsInclusionType;
  isActionsModalOpen: boolean;
  setIsActionsModalOpen: (isOpen: boolean) => void;
  onCreditsInclusionChange: Function;
  onProposalEdit: (proposalId: string, data: IProposalCardData) => void;
  onProposalClone: (proposalId: string) => void;
  onMarkAsFinalApprovedConfirm: (
    id: string,
    name: string,
    effectiveDate: string
  ) => void;
  onUnmarkAsFinalApproved: (id: string) => void;
  onMarkAsPublishedConfirm: (id: string) => void;
  onMarkAsDraftConfirm: (id: string) => void;
  showPeriod?: boolean;
  changeProposalStatus: Function;
  onBenefitTypeSelect: Function;
  isFinalizeProposal: boolean;
  isFinalApprovedExists?: boolean;
  planYear?: string | null;
  deleteProposalConfirm: () => void;
  isUnmarkFinalLoading: boolean;
};

const ProposalCard = ({
  data,
  creditsInclusion,
  isActionsModalOpen,
  setIsActionsModalOpen,
  onMarkAsFinalApprovedConfirm,
  onCreditsInclusionChange,
  onProposalEdit,
  onProposalClone,
  showPeriod,
  changeProposalStatus,
  onMarkAsPublishedConfirm,
  onUnmarkAsFinalApproved,
  onMarkAsDraftConfirm,
  isFinalApprovedExists,
  planYear,
  deleteProposalConfirm,
  isUnmarkFinalLoading,
}: ProposalCardProps) => {
  const {
    id,
    tags,
    title,
    period,
    changedBy,
    changeByName,
    tableData,
    isOutdated,
    description,
    overviewData,
    effectiveDateRange,
  } = data;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // Selectors
  const appBootInfo: any = useAppSelector(
    (state) => state.auth.auth.appBootupInfo
  );
  const isErAdmin: boolean = appBootInfo?.type === loginTypes.erAdmin;
  const isPlatformAdmin: boolean = appBootInfo?.type === loginTypes.platform;
  const employerObj: any = useAppSelector((state) => state?.layout?.employer);

  const [isMouseHoveringOnOutdated, setIsMouseHoveringOnOutdated] =
    useState<boolean>(false);
  const [isReviewChangesModalOpen, setIsReviewChangesModalOpen] =
    useState<boolean>(false);
  const [actionsDropDownValues, setActionsDropDownValues] =
    useState<IProposalActionsDropDownData | null>(null);

  const [
    deleteProposal,
    {
      isLoading: isProposalDeleteLoading,
      isSuccess: isProposalDeleted,
      reset: resetProposalDelete,
    },
  ] = useDeleteProposalMutation();
  const [checkDraftExist] = useLazyCheckDraftOfferExistForProposalQuery();

  useEffect(() => {
    if (isProposalDeleted) {
      resetProposalDelete();
      setIsActionsModalOpen(false);
      showSuccessNotification({
        message: 'Successfully Deleted',
        description: 'Proposal has been successfully deleted.',
      });
    }
    // eslint-disable-next-line
  }, [isProposalDeleteLoading, isProposalDeleted]);

  const getDescription = (): React.ReactNode => {
    if ((description?.length || 0) > PROPOSAL_CARD_DESCRIPTION_TEXT_LIMIT) {
      return (
        <>
          {getTextEllipsis(
            description || '',
            PROPOSAL_CARD_DESCRIPTION_TEXT_LIMIT
          )}{' '}
          <LinkButton onClick={handleNavigation}>more</LinkButton>
        </>
      );
    } else {
      return description;
    }
  };

  const checkIfOffersExist = (): boolean => {
    return (
      tableData?.some(
        (obj) => obj.benefitsCost !== undefined && obj.benefitsCost !== null
      ) ?? false
    );
  };

  const handlePublish = async () => {
    const isOffersExist: boolean = checkIfOffersExist();
    const draftValidation = await checkDraftExist({
      id: id ?? '',
    });
    const { draftExist = false, publishedExist = false } =
      draftValidation?.data ?? {};
    if (!isOffersExist) {
      setActionsDropDownValues({
        title: 'Publish Proposal',
        body: 'Are you sure you want to publish this proposal? This proposal currently does not have any offers selected',
        confirmTitle: 'Yes - Publish Proposal',
        onConfirm: () => {
          onMarkAsPublishedConfirm(id ?? '');
        },
      });
      setIsActionsModalOpen(true);
    } else if (draftExist) {
      setActionsDropDownValues({
        title: 'Publish Proposal',
        body: (
          <div>
            {DRAFT_OFFER_EXIST_ON_PROPOSAL_WARNING_MESSAGE}
            {publishedExist && (
              <div className={styles.draftOfferPopUpContainer}>
                {DRAFT_OFFER_EXIST_ON_PROPOSAL_WITH_SAME_NAME_WARNING_MESSAGE}
              </div>
            )}
          </div>
        ),
        confirmTitle: DRAFT_OFFER_EXIST_ON_PROPOSAL_BUTTON_TITLE,
        onConfirm: () => {
          onMarkAsPublishedConfirm(id ?? '');
        },
      });
      setIsActionsModalOpen(true);
    } else {
      onMarkAsPublishedConfirm(id ?? '');
    }
  };

  const isEmpMemberCreatedProposal =
    appBootInfo?.workEmail === data?.changeByName &&
    appBootInfo?.individualSubType === IndividualSubTypes.EMPLOYER_MEMBER;

  const isEmpMember =
    appBootInfo?.individualSubType === IndividualSubTypes.EMPLOYER_MEMBER;

  /**
   * Builds up the array that will be used to display
   * the actions for each proposal card based on tags and other proposal
   * statuses.
   * @return {ProposalCardActionOptionType[]} An array of options
   */
  const getActionsOptions = (): ProposalCardActionOptionType[] => {
    let options: ProposalCardActionOptionType[] = [];

    if (isEmpMemberCreatedProposal) {
      if (!isOutdated) {
        options?.push(PROPOSAL_CARD_ACTION_OPTIONS.EDIT_PROPOSAL);
      }
      if (!(tags?.includes('DRAFT') || isOutdated)) {
        if (tags?.includes('RECOMMENDED')) {
          options.push(PROPOSAL_CARD_ACTION_OPTIONS.REMOVE_RECOMMEND);
        } else {
          options.push(PROPOSAL_CARD_ACTION_OPTIONS.RECOMMEND);
        }
      }
      options.push(PROPOSAL_CARD_ACTION_OPTIONS.CLONE_PROPOSAL);
      if (!tags?.includes('FINAL_APPROVED')) {
        options.push(PROPOSAL_CARD_ACTION_OPTIONS.DELETE_PROPOSAL);
      }
    } else if (
      appBootInfo?.individualSubType !== IndividualSubTypes.EMPLOYER_MEMBER
    ) {
      if (!isOutdated) {
        options?.push(PROPOSAL_CARD_ACTION_OPTIONS.EDIT_PROPOSAL);
      } else {
        options?.push(PROPOSAL_CARD_ACTION_OPTIONS.UPDATE_PROPOSAL);
      }
      options.push(PROPOSAL_CARD_ACTION_OPTIONS.CLONE_PROPOSAL);

      if (!(tags?.includes('DRAFT') || isOutdated)) {
        if (tags?.includes('RECOMMENDED')) {
          options.push(PROPOSAL_CARD_ACTION_OPTIONS.REMOVE_RECOMMEND);
        } else {
          options.push(PROPOSAL_CARD_ACTION_OPTIONS.RECOMMEND);
        }
      }
      if (!tags?.includes('FINAL_APPROVED')) {
        options.push(PROPOSAL_CARD_ACTION_OPTIONS.DELETE_PROPOSAL);
      }

      if (!tags?.includes('DRAFT')) {
        if (!isErAdmin) {
          options.push(PROPOSAL_CARD_ACTION_OPTIONS.MOVE_TO_DRAFTS);
        }
      } else {
        options.push(PROPOSAL_CARD_ACTION_OPTIONS.PUBLISH);
      }

      if (!(tags?.includes('DRAFT') || isOutdated)) {
        if (tags?.includes('FINAL_APPROVED')) {
          options.push(PROPOSAL_CARD_ACTION_OPTIONS.UNMARK_FINAL_APPROVED);
        } else {
          isFinalApprovedExists
            ? options.push(PROPOSAL_CARD_ACTION_OPTIONS.FINAL_APPROVED_DISABLED)
            : options.push(PROPOSAL_CARD_ACTION_OPTIONS.FINAL_APPROVED);
        }
      }
      if (isPlatformAdmin) {
        // hiding this option from Er admins and broker admins until the feature is properly built
        options.push(PROPOSAL_CARD_ACTION_OPTIONS.EXPORT_AUDIT_LOG);
      }
    }

    // if plan year exist disable edit options
    if (isPlanYearApplicable()) {
      options = filterProposalOptionsForPlanYear(options);
    }

    return options;
  };

  const isPlanYearApplicable = (): boolean => {
    return null !== planYear;
  };

  const handleDelete = (): void => {
    deleteProposalConfirm();
    deleteProposal({ proposalId: id ?? '' });
  };

  const handleCloneProposal = (id: string) => {
    onProposalClone(id || '');
  };

  // TODO: Add logic here for each possible option in actions dropdown
  const handleActionSelect = (action: ProposalCardActionType): void => {
    switch (action) {
      case 'DELETE_PROPOSAL': {
        setActionsDropDownValues({
          title: 'Delete Proposal',
          body: 'Are you sure you want to delete this proposal? This action cannot be undone.',
          confirmTitle: 'Yes - Delete Proposal',
          onConfirm: () => {
            handleDelete();
          },
        });
        setIsActionsModalOpen(true);
        break;
      }
      case 'EDIT_PROPOSAL':
        onProposalEdit(data.id || '', data);
        break;
      case 'CLONE_PROPOSAL':
        handleCloneProposal(data.id || '');
        break;
      case 'FINAL_APPROVED': {
        // TODO: Get details of selected proposal
        setActionsDropDownValues({
          title: 'Mark as Final Approved',
          subTitle: isErAdmin
            ? `${title} - ${effectiveDateRange}`
            : `${employerObj.name} - ${title} - ${effectiveDateRange}`,
          body: !isErAdmin ? (
            <div className={styles.soldConfirmationPopup}>
              <p>{MARK_AS_FINAL_PROPOSAL_MODAL_BROKER_BODY_TEXT}</p>
              <p>{MARK_AS_FINAL_APPROVED_MODAL_BROKER_BODY_TEXT_SECOND_PARA}</p>

              {/* <Row
                justify="space-between"
                className={styles.sendNotificationToEmployerRow}
              >
                <Col className={styles.textCol}>
                  Send Notification To Employer
                  <Popover
                    content={MARK_AS_FINAL_APPROVED_POPOVER_TXT}
                    overlayClassName={`popoverInner recommendedtooltip ${styles.popover}`}
                  >
                    <span className={styles.tooltipWrapper}>
                      <IconInfo />
                    </span>
                  </Popover>
                </Col>
                <Col>
                  <Switch
                    onChange={(value) =>
                      setShouldSendNotificationToEmployer(value)
                    }
                  />
                </Col>
              </Row> */}
            </div>
          ) : (
            <div>
              <p>{MARK_AS_FINAL_PROPOSAL_MODAL_EMPLOYER_BODY_TEXT}</p>
            </div>
          ),
          confirmTitle: isErAdmin
            ? MARK_AS_FINAL_PROPOSAL_MODAL_CONFIRMATION_TEXT
            : 'Continue',
          onConfirm: () => {
            onMarkAsFinalApprovedConfirm(
              id ?? '',
              title ?? '',
              effectiveDateRange ?? ''
            );
            setIsActionsModalOpen(false);
          },
        });
        setIsActionsModalOpen(true);
        break;
      }
      case 'MOVE_TO_DRAFTS': {
        if ('APPROVED' === data.status) {
          setActionsDropDownValues({
            title: 'Move to Drafts',
            body: 'Confirming the action will move the proposal to the draft section and will remove the final approved',
            confirmTitle: 'Yes - Move Proposal to Drafts',
            onConfirm: () => {
              onMarkAsDraftConfirm(id ?? '');
            },
          });
          setIsActionsModalOpen(true);
        } else {
          setActionsDropDownValues({
            title: 'Move to Drafts',
            body: 'Are you sure you want to move this proposal to drafts? It will only be available in the draft tab of admin viewing.',
            confirmTitle: 'Yes - Move Proposal to Drafts',
            onConfirm: () => {
              onMarkAsDraftConfirm(id ?? '');
            },
          });
          setIsActionsModalOpen(true);
        }
        break;
      }
      case 'PUBLISH':
        handlePublish();
        break;
      case 'RECOMMEND':
        changeProposalStatus(data.id!, 'RECOMMENDED');
        break;
      case 'UNMARK_FINAL_APPROVED': {
        setActionsDropDownValues({
          title: 'Unmark as Final Approved',
          body: (
            <div>
              <p>
                Are you sure you want to remove Final Approved from this
                proposal?
              </p>
              <p>
                If you have already sent the Sold Confirmation or added this
                proposal to a new Plan Year, you may need to perform these
                actions again after re-finalizing a proposal.
              </p>
            </div>
          ),
          confirmTitle: 'Yes - Unmark as Final Approved',
          onConfirm: () => {
            onUnmarkAsFinalApproved(id || '');
          },
        });
        setIsActionsModalOpen(true);
        break;
      }
      case 'EXPORT_AUDIT_LOG': {
        exportProposalAuditLog(id);
        notification['info']({
          closeIcon: <></>,
          top: 40,
          message: 'Download Started',
          description:
            'Please wait while we export the activity log. This process may take a few minutes',
          icon: <DownloadIcon className={styles.downloadIcon} />,
        });
        break;
      }
      case 'REMOVE_RECOMMEND':
        changeProposalStatus(data.id!, 'REMOVE_RECOMMEND');
        break;
      case 'UPDATE_PROPOSAL':
        break;
      default:
        break;
    }
  };

  const handleNavigation = (): void => {
    dispatch(setIsFromProposalNavigatePY(planYear));
    navigate(`details/${id}`, { state: { planYearId: planYear } });
  };

  const handleReviewChanges = (): void => {
    setIsReviewChangesModalOpen(true);
  };

  const handleReviewChangesConfirm = (): void => {};

  useEffect(() => {
    if (!isActionsModalOpen) {
      setActionsDropDownValues(null);
    }
  }, [isActionsModalOpen]);

  const isActionInProgress = (action: IProposalActionsDropDownData | null) => {
    if (action?.title === PROPOSAL_CARD_ACTION_OPTIONS.DELETE_PROPOSAL.label) {
      return isProposalDeleteLoading;
    }
    if (
      action?.title === PROPOSAL_CARD_ACTION_OPTIONS.UNMARK_FINAL_APPROVED.label
    ) {
      return isUnmarkFinalLoading;
    }
    return false;
  };

  const proposalActionDropdown =
    isEmpMember && !isEmpMemberCreatedProposal ? (
      <></>
    ) : (
      <SingleSelectFilter
        inlineDropdown
        placeholder="Action"
        showSearch={false}
        selectedValue={'Actions'}
        defaultValue={'Actions'}
        className={styles.actionsDropdown}
        isSelectionHighlightAndTickApplicable={false}
        setFilterSelectedValue={(value: ProposalCardActionType) => {
          handleActionSelect(value);
        }}
        data={getActionsOptions()}
        placement="bottomLeft"
        isWiderWrapper
        listHeight={300}
      />
    );

  const exportDetailedExcel = () => {
    notification['info']({
      closeIcon: <></>,
      top: 40,
      message: 'Download Started',
      description:
        'Please wait while we export the detailed excel. This process may take a few minutes',
      icon: <DownloadIcon className={styles.downloadIcon} />,
    });
    exportDetailedProposalExcel(data.id, data.status, data.title);
  };

  return (
    <>
      <Row wrap={false} className={styles.card}>
        <Col span={9} className={styles.detailSection}>
          <span className={styles.tagSection}>
            {tags &&
              tags.map((tag, idx) => (
                <CardTopStatusTag key={idx} status={tag} />
              ))}
          </span>
          <div>
            {showPeriod && (
              <Row className={styles.periodWrapper}>
                <img src={calenderIcon} />{' '}
                <span className={styles.periodText}>
                  {period?.concat(' Months') || '-'}
                </span>
              </Row>
            )}
            <Row className={styles.titleText}>{title || '-'}</Row>
            <Row className={styles.avatarWrapper}>
              <ProfileAvatar content={changeByName || '-'} />
              <span className={styles.avatarText}>{changedBy || '-'}</span>
            </Row>
            <Row>
              <ProposalCardOverview
                data={overviewData ?? {}}
                onCreditsInclusionChange={onCreditsInclusionChange}
                creditsInclusion={creditsInclusion}
                tableData={tableData ?? []}
              />
            </Row>
          </div>
          <div className={styles.actionsSection}>
            <Row className={styles.viewDetailsButtonWrapper}>
              <Button type="primary" ghost onClick={handleNavigation}>
                View Proposal Details
              </Button>
            </Row>
            <Row justify="center">{proposalActionDropdown}</Row>
          </div>
        </Col>
        <Col flex={'auto'} className={styles.tableSection}>
          {isOutdated && (
            <div
              className={styles.outdatedOverlay}
              onMouseEnter={() => setIsMouseHoveringOnOutdated(true)}
              onMouseLeave={() => setIsMouseHoveringOnOutdated(false)}
            >
              {isMouseHoveringOnOutdated && (
                <div className={styles.outdatedPopup}>
                  Rates have changed for this proposal.
                  <br />
                  Please{' '}
                  <LinkButton onClick={handleReviewChanges}>
                    review changes
                  </LinkButton>{' '}
                  and update.
                </div>
              )}
            </div>
          )}
          <ProposalCardTable tableRows={tableData} />
          <p className={styles.description}>{getDescription()}</p>
        </Col>
      </Row>
      <ProposalCardReviewModal
        data={[]}
        isOpen={isReviewChangesModalOpen}
        onCancel={() => setIsReviewChangesModalOpen(false)}
        onConfirm={handleReviewChangesConfirm}
      />
      <ConfirmationDialog
        visible={isActionsModalOpen}
        isRecordApplicable={actionsDropDownValues?.subTitle !== undefined}
        recordName={actionsDropDownValues?.subTitle}
        onConfirm={() => actionsDropDownValues?.onConfirm()}
        confirmBtnFullWidth={true}
        cancelText={actionsDropDownValues?.showFileLinks ? '' : 'Cancel'}
        title={actionsDropDownValues?.title ?? ''}
        isCancelLink={!actionsDropDownValues?.showFileLinks}
        closeModal={() => setIsActionsModalOpen(false)}
        confirmText={actionsDropDownValues?.confirmTitle ?? ''}
        isCloseOnly={true}
        confirmLoading={isActionInProgress(actionsDropDownValues)}
        destroyOnClose={true}
      >
        <div className={styles.actionsDropdownBodyWrapper}>
          {actionsDropDownValues?.body}
          {actionsDropDownValues?.showFileLinks ?? false ? (
            <div className={styles.downloadLinkWrapper}>
              <Row>
                <img src={xlsIcon} className={styles.exportIcon} />
                <LinkButton onClick={exportDetailedExcel}>
                  Download Detailed Excel
                </LinkButton>
              </Row>
            </div>
          ) : (
            ''
          )}
        </div>
      </ConfirmationDialog>
    </>
  );
};

export default ProposalCard;
