import { FC } from 'react';

import { Row, Col, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import arrowRightIcon from 'assets/images/arrow-arc-right.svg';
import benefitIcon from 'assets/images/icon-benefits-next.svg';
import completedIcon from 'assets/images/icon-completed.svg';

import { useAppSelector } from 'hooks/redux';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';

import styles from './completeStep.module.less';

const { Text } = Typography;

type Props = {
  closeModal: () => void;
};

const CompleteStep: FC<Props> = (props) => {
  const { closeModal } = props;

  const navigate = useNavigate();

  const createdEmployer = useAppSelector(
    (state) => state.employer.employerCreateStepper.createdEmployer
  );

  const goToEmployerDashboard = () => {
    if (createdEmployer) {
      const { organizationId, id } = createdEmployer;
      navigate(`/brokers/${organizationId}/employers/${id}`);
    }
    closeModal();
  };

  return (
    <Row>
      <Col span={10}>
        <div className={styles.completeStepWrapper}>
          <img
            src={completedIcon}
            className={styles.completedIcon}
            alt="completed-icon"
          />
          <Row>
            <Col className={styles.successContainer}>
              <h2>
                <Text className={styles.successHeader}>Employer Added!</Text>
              </h2>
              <p className={styles.successMessage}>
                Great job! You have added the basic
                <br />
                information for this employer.
              </p>
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={4} className={styles.arrow}>
        <img src={arrowRightIcon} alt="arrow-right-icon" />
      </Col>
      <Col span={10}>
        <div className={styles.nextStepWrapper}>
          <img
            src={benefitIcon}
            className={styles.checkIcon}
            alt="benefit-icon"
          />
          <Row>
            <Col className={styles.successContainer}>
              <h2>
                <Text className={styles.successHeader}>
                  NEXT: View Employer
                </Text>
              </h2>
              <p className={styles.successMessage}>
                This is the page that you and any
                <br />
                employer admins will see.
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={24} className={styles.rightColumn}>
              <SubmitButton
                type="primary"
                onClick={goToEmployerDashboard}
                className={styles.completedButton}
              >
                View Employer & Create Content
              </SubmitButton>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
export default CompleteStep;
