export default class BenefitCode {
  id: string;
  code: number | null;
  description: string;
  shortName: string;
  benefitKind: string;

  constructor() {
    this.id = '';
    this.code = null;
    this.description = '';
    this.shortName = '';
    this.benefitKind = '';
  }
}
