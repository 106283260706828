import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import uniqWith from 'lodash/uniqWith';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, get, trim } from 'lodash';
import CustomPlan from 'model/plans/CustomPlan';
import { Deductibles } from 'model/plans/Deductibles';
import DeductiblesForIndividualWithFamily from 'model/plans/DeductiblesForIndividualWithFamily';
import { DentalPlan } from 'model/plans/DentalPlan';
import MedicalPlan from 'model/plans/MedicalPlan';
import {
  AI_FILE_TYPE,
  BasicPlans,
  benefitCode,
  ContributionType,
  coverageOption,
  defaultBenefitTypes,
  DentalPlanDocumentType,
  EmployerContributionFrequency,
  EmployerContributionHSAFrequency,
  MedicalPlanDocumentType,
  NON_MDV_ADDITIONAL_SERVICES,
  PriorToDeductible,
  RateType,
  TaxAdvantagedAccountPlanType,
  VisionPlanDocumentType,
} from 'modules/plans/constants';
import {
  LlmCustomService,
  LlmExtractionInfo,
  LlmInfo,
  LlmLifeService,
  LlmRxCosts,
  LlmServiceInfo,
  LlmServiceInfoKeys,
} from 'model/plans/LLMExtraction';
import Contribution from 'model/plans/Contribution';
import BenefitClassContribution from 'model/plans/BenefitClassContribution';
import { VisionPlan } from 'model/plans/VisionPlan';
import CostSharing from 'model/plans/CostSharing';
import { BenefitCategory, BenefitKind } from 'constants/commonConstants';
import RxForDeductiblesAndOopMax from 'model/plans/RxForDeductiblesAndOopMax';
import PlanService from 'model/PlanService';
import { LifePlan } from 'model/plans/LifePlan';
import { NonMDVServiceType } from 'model/plans/NonMDVServiceType';
import { DENTAL, MEDICAL, VISION } from './planRates/pages/PlanRates/PlanRates';
import {
  AI_FILE_TYPE_KEYS,
  LifePlanFormData,
  ModalCloseSettings,
} from './types/types';
import ProcessStatus from './enums/SBCUploadStatus';
import PanelSection from './enums/PanelSection';

// If the number has decimal places will format to given number, if not displays without decimal places
// formats to 2 decimal places by default
export const displayNumberWithDecimals = (
  value: number | string | undefined,
  decimals: number | undefined = 2
): string => {
  if (value === null || value === undefined) {
    return '-';
  }
  if (typeof value === 'number') {
    return value
      .toLocaleString('en', { minimumFractionDigits: decimals })
      .replace(/[.,]00$/, '');
  }
  if (typeof value === 'string') {
    const convertedValue = Number(value);
    return !isNaN(convertedValue)
      ? convertedValue
          .toLocaleString('en', { minimumFractionDigits: decimals })
          .replace(/[.,]00$/, '')
      : '-';
  }
  return value;
};

export const buildReferencePlanOptions = (
  hraPlanList: any,
  currentPlanId: string = ''
) => {
  const mOptions =
    hraPlanList?.medical?.map((plan: any) => {
      const rate = plan?.rates?.[Object.keys(plan?.rates)[0]];
      return {
        label: plan.name,
        value: plan.id,
        group: MEDICAL,
        tier:
          rate?.type === RateType.N_TIER.value
            ? RateType.N_TIER.value
            : RateType.FOUR_TIER.value,
        tierCount:
          rate?.type === RateType.N_TIER.value
            ? rate.ntierContributions?.contributions?.length ?? 0
            : 4,
        isDisabled: !!plan?.hraPlanId
          ? currentPlanId !== plan?.hraPlanId
          : false,
      };
    }) || [];
  const dOptions =
    hraPlanList?.dental?.map((plan: any) => {
      const rate = plan?.rates?.[Object.keys(plan?.rates)[0]];
      return {
        label: plan.name,
        value: plan.id,
        group: DENTAL,
        tier:
          rate?.type === RateType.N_TIER.value
            ? RateType.N_TIER.value
            : RateType.FOUR_TIER.value,
        tierCount:
          rate?.type === RateType.N_TIER.value
            ? rate.ntierContributions?.contributions?.length ?? 0
            : 4,
        isDisabled: !!plan?.hraPlanId
          ? currentPlanId !== plan?.hraPlanId
          : false,
      };
    }) || [];
  const vOptions =
    hraPlanList?.vision?.map((plan: any) => {
      const rate = plan?.rates?.[Object.keys(plan?.rates)[0]];
      return {
        label: plan.name,
        value: plan.id,
        group: VISION,
        tier:
          rate?.type === RateType.N_TIER.value
            ? RateType.N_TIER.value
            : RateType.FOUR_TIER.value,
        tierCount:
          rate?.type === RateType.N_TIER.value
            ? rate.ntierContributions?.contributions?.length ?? 0
            : 4,
        isDisabled: !!plan?.hraPlanId
          ? currentPlanId !== plan?.hraPlanId
          : false,
      };
    }) || [];
  return [...mOptions, ...dOptions, ...vOptions];
};

// Format value to 2 decimal places
export const displayNumberWithDecimalValue = (
  value: number | string,
  decimals: number | undefined = 2
): string => {
  if (typeof value === 'number') {
    return value.toLocaleString('en', { minimumFractionDigits: decimals });
  }
  if (typeof value === 'string') {
    const convertedValue = Number(value);
    return !isNaN(convertedValue)
      ? convertedValue.toLocaleString('en', { minimumFractionDigits: decimals })
      : '-';
  }
  return value;
};

export const copyServicesForCloning = (
  services: CustomPlan[]
): CustomPlan[] => {
  return services.map((service) => {
    return {
      id: '',
      planId: '',
      benefitCode: {
        ...service.benefitCode,
        id: '',
      },
      serviceValue: {
        inNetwork: {
          ...service.serviceValue.inNetwork,
        },
        outOfNetwork: {
          ...service.serviceValue.outOfNetwork,
        },
      },
      createdTs: 0,
      lastUpdatedTs: 0,
      source: '',
      isDefault: true,
    } as CustomPlan;
  });
};
export const isNullOrUndefined = (value: any) => {
  return value === undefined || value === null;
};

const getCoverageType = (
  existingType: string,
  contribution: string | number
) => {
  if (coverageOption.NOT_COVERED === existingType) {
    return coverageOption.NOT_COVERED;
  } else if (
    coverageOption.COVERED === existingType &&
    !isEmpty(contribution?.toString())
  ) {
    return coverageOption.COVERED;
  } else {
    return coverageOption.NOT_APPLICABLE;
  }
};

export const transformDeductibleForSave = (plan: MedicalPlan | DentalPlan) => {
  if (!plan.deductibles) {
    plan.deductibles = {} as DeductiblesForIndividualWithFamily;
  }
  plan.deductibles.individualInNetworkApplicable = getCoverageType(
    plan.deductibles.individualInNetworkApplicable,
    plan.deductibles?.individualInNetworkCost
  );
  plan.deductibles.individualOutOfNetworkApplicable = getCoverageType(
    plan.deductibles.individualOutOfNetworkApplicable,
    plan.deductibles?.individualOutOfNetworkCost
  );
  plan.deductibles.familyInNetworkApplicable = getCoverageType(
    plan.deductibles.familyInNetworkApplicable,
    plan.deductibles?.familyInNetworkCost
  );
  plan.deductibles.familyOutOfNetworkApplicable = getCoverageType(
    plan.deductibles.familyOutOfNetworkApplicable,
    plan.deductibles?.familyOutOfNetworkCost
  );
  plan.deductibles.individualWithinFamilyInNetworkApplicable = getCoverageType(
    plan.deductibles.individualWithinFamilyInNetworkApplicable,
    plan.deductibles?.individualWithinFamilyInNetworkCost
  );
  plan.deductibles.individualWithinFamilyOutOfNetworkApplicable =
    getCoverageType(
      plan.deductibles.individualWithinFamilyOutOfNetworkApplicable,
      plan.deductibles?.individualWithinFamilyOutOfNetworkCost
    );
  return plan;
};

export const transformOOPMaxForSave = (plan: MedicalPlan) => {
  if (!plan.outOfPocket) {
    plan.outOfPocket = {} as DeductiblesForIndividualWithFamily;
  }
  plan.outOfPocket.individualInNetworkApplicable = getCoverageType(
    plan.outOfPocket.individualInNetworkApplicable,
    plan.outOfPocket?.individualInNetworkCost
  );
  plan.outOfPocket.individualOutOfNetworkApplicable = getCoverageType(
    plan.outOfPocket.individualOutOfNetworkApplicable,
    plan.outOfPocket?.individualOutOfNetworkCost
  );
  plan.outOfPocket.familyInNetworkApplicable = getCoverageType(
    plan.outOfPocket.familyInNetworkApplicable,
    plan.outOfPocket?.familyInNetworkCost
  );
  plan.outOfPocket.familyOutOfNetworkApplicable = getCoverageType(
    plan.outOfPocket.familyOutOfNetworkApplicable,
    plan.outOfPocket?.familyOutOfNetworkCost
  );
  plan.outOfPocket.individualWithinFamilyInNetworkApplicable = getCoverageType(
    plan.outOfPocket.individualWithinFamilyInNetworkApplicable,
    plan.outOfPocket?.individualWithinFamilyInNetworkCost
  );
  plan.outOfPocket.individualWithinFamilyOutOfNetworkApplicable =
    getCoverageType(
      plan.outOfPocket.individualWithinFamilyOutOfNetworkApplicable,
      plan.outOfPocket?.individualWithinFamilyOutOfNetworkCost
    );
  return plan;
};

export const transformDeductiblesAndOopMaxForSave = (plan: MedicalPlan) => {
  if (!plan.rxDeductiblesAndOop) {
    plan.rxDeductiblesAndOop = {} as RxForDeductiblesAndOopMax;
  }
  plan.rxDeductiblesAndOop.individualDeductibleInNetworkApplicable =
    getCoverageType(
      plan.rxDeductiblesAndOop.individualDeductibleInNetworkApplicable,
      plan.rxDeductiblesAndOop?.individualDeductibleInNetworkCost
    );
  plan.rxDeductiblesAndOop.individualDeductibleOutOfNetworkApplicable =
    getCoverageType(
      plan.rxDeductiblesAndOop.individualDeductibleOutOfNetworkApplicable,
      plan.rxDeductiblesAndOop?.individualDeductibleOutOfNetworkCost
    );
  plan.rxDeductiblesAndOop.familyDeductibleInNetworkApplicable =
    getCoverageType(
      plan.rxDeductiblesAndOop.familyDeductibleInNetworkApplicable,
      plan.rxDeductiblesAndOop?.familyDeductibleInNetworkCost
    );
  plan.rxDeductiblesAndOop.familyDeductibleOutOfNetworkApplicable =
    getCoverageType(
      plan.rxDeductiblesAndOop.familyDeductibleOutOfNetworkApplicable,
      plan.rxDeductiblesAndOop?.familyDeductibleOutOfNetworkCost
    );
  plan.rxDeductiblesAndOop.individualWithinFamilyDeductibleInNetworkApplicable =
    getCoverageType(
      plan.rxDeductiblesAndOop
        .individualWithinFamilyDeductibleInNetworkApplicable,
      plan.rxDeductiblesAndOop?.individualWithinFamilyDeductibleInNetworkCost
    );
  plan.rxDeductiblesAndOop.individualWithinFamilyDeductibleOutOfNetworkApplicable =
    getCoverageType(
      plan.rxDeductiblesAndOop
        .individualWithinFamilyDeductibleOutOfNetworkApplicable,
      plan.rxDeductiblesAndOop?.individualWithinFamilyDeductibleOutOfNetworkCost
    );

  plan.rxDeductiblesAndOop.individualOopMaxInNetworkApplicable =
    getCoverageType(
      plan.rxDeductiblesAndOop.individualOopMaxInNetworkApplicable,
      plan.rxDeductiblesAndOop?.individualOopMaxInNetworkCost
    );
  plan.rxDeductiblesAndOop.individualOopMaxOutOfNetworkApplicable =
    getCoverageType(
      plan.rxDeductiblesAndOop.individualOopMaxOutOfNetworkApplicable,
      plan.rxDeductiblesAndOop?.individualOopMaxOutOfNetworkCost
    );
  plan.rxDeductiblesAndOop.familyOopMaxInNetworkApplicable = getCoverageType(
    plan.rxDeductiblesAndOop.familyOopMaxInNetworkApplicable,
    plan.rxDeductiblesAndOop?.familyOopMaxInNetworkCost
  );
  plan.rxDeductiblesAndOop.familyOopMaxOutOfNetworkApplicable = getCoverageType(
    plan.rxDeductiblesAndOop.familyOopMaxOutOfNetworkApplicable,
    plan.rxDeductiblesAndOop?.familyOopMaxOutOfNetworkCost
  );
  plan.rxDeductiblesAndOop.individualWithinFamilyOopMaxInNetworkApplicable =
    getCoverageType(
      plan.rxDeductiblesAndOop.individualWithinFamilyOopMaxInNetworkApplicable,
      plan.rxDeductiblesAndOop?.individualWithinFamilyOopMaxInNetworkCost
    );
  plan.rxDeductiblesAndOop.individualWithinFamilyOopMaxOutOfNetworkApplicable =
    getCoverageType(
      plan.rxDeductiblesAndOop
        .individualWithinFamilyOopMaxOutOfNetworkApplicable,
      plan.rxDeductiblesAndOop?.individualWithinFamilyOopMaxOutOfNetworkCost
    );

  return plan;
};

export const transformOrthodontiaMaxForSave = (plan: DentalPlan) => {
  if (!plan.orthodontiaLifetimeMax) {
    plan.orthodontiaLifetimeMax = {} as Deductibles;
  }

  if (!plan.calendarYearMax) {
    plan.calendarYearMax = {} as Deductibles;
  }

  plan.orthodontiaLifetimeMax.inNetworkApplicable = getCoverageType(
    plan.orthodontiaLifetimeMax.inNetworkApplicable,
    plan.orthodontiaLifetimeMax.inNetworkCost
  );

  plan.orthodontiaLifetimeMax.outOfNetworkApplicable = getCoverageType(
    plan.orthodontiaLifetimeMax.outOfNetworkApplicable,
    plan.orthodontiaLifetimeMax.outOfNetworkCost
  );

  plan.calendarYearMax.inNetworkApplicable = getCoverageType(
    plan.calendarYearMax.inNetworkApplicable,
    plan.calendarYearMax.inNetworkCost
  );

  plan.calendarYearMax.outOfNetworkApplicable = getCoverageType(
    plan.calendarYearMax.outOfNetworkApplicable,
    plan.calendarYearMax.outOfNetworkCost
  );
  return plan;
};

export const initializeTierContributions = (contributions: []) => {
  return contributions?.map(({ tierName, totalCost }) => ({
    employeeCost: totalCost && parseFloat(totalCost) ? 0.0 : '',
    employeeBiWeeklyCost: totalCost && parseFloat(totalCost) ? 0.0 : '',
    employeeSemiMonthlyCost: totalCost && parseFloat(totalCost) ? 0.0 : '',
    employerCost:
      totalCost && parseFloat(totalCost) ? parseFloat(totalCost) : '',
    totalCost: (totalCost && parseFloat(totalCost)) || '',
    tierName: tierName,
    enrollment: undefined,
  }));
};

export const getContributionRateType = (rateType: string) => {
  if (rateType === RateType.FOUR_TIER.value) {
    return 'fourTierContributions';
  } else if (rateType === RateType.N_TIER.value) {
    return 'ntierContributions';
  } else {
    return '';
  }
};
export const buildRates = (
  benefitGroups: string[],
  plan: MedicalPlan | DentalPlan | VisionPlan
) => {
  const rate: { [key: string]: {} } = {};
  !isEmpty(plan.rates) &&
    benefitGroups?.forEach((group: string) => {
      const firstRate: any = Object.values(plan.rates)[0];
      const rateType = getContributionRateType(firstRate.type);
      const premiums: { [key: string]: string | number | null } = {};
      firstRate[rateType]?.contributions.forEach((tier: Contribution) => {
        premiums[tier.tierName] = tier.totalCost;
      });
      if (plan.rates[group]) {
        rate[group] = plan.rates[group];
      } else {
        const benefitClass = new BenefitClassContribution();
        benefitClass.type = firstRate.type;
        if (firstRate.type === RateType.FOUR_TIER.value) {
          benefitClass.fourTierContributions.contributions =
            plan.hasSameContributions && benefitGroups?.length > 1
              ? firstRate.fourTierContributions.contributions
              : initializeTierContributions(
                  firstRate.fourTierContributions.contributions
                );
        } else if (firstRate.type === RateType.N_TIER.value) {
          benefitClass.ntierContributions.contributions =
            plan.hasSameContributions && benefitGroups?.length > 1
              ? firstRate.ntierContributions.contributions
              : initializeTierContributions(
                  firstRate.ntierContributions.contributions
                );
        }
        rate[group] = benefitClass;
      }
    });
  return rate;
};

export const validateRates = (
  rates: {
    [key: string]: BenefitClassContribution;
  },
  dbgGroups: string[]
) => {
  if (!isEmpty(rates) && !isEmpty(dbgGroups)) {
    const rateType = Object.values(rates)[0].type;
    let contributions: Contribution[] = [];
    Object.keys(rates).forEach((group) => {
      if (dbgGroups.includes(group)) {
        if (RateType.N_TIER.value === rateType) {
          contributions = contributions.concat(
            rates[group]?.ntierContributions?.contributions
          );
        } else if (RateType.FOUR_TIER.value === rateType) {
          contributions = contributions.concat(
            rates[group]?.fourTierContributions?.contributions
          );
        }
      }
    });
    const contributionGroups = groupBy(contributions, 'tierName');
    let conflictsPresent = false;
    Object.values(contributionGroups).forEach((ratesContributionTier) => {
      if (!conflictsPresent) {
        conflictsPresent =
          uniqWith(
            ratesContributionTier,
            (prev, current) => prev.employeeCost === current.employeeCost
          ).length > 1;
      }
    });
    return conflictsPresent;
  }
};

export const getNonDefaultExistingAndCustomOrder = (
  newCustomServices: any[],
  isMedical?: boolean
) => {
  if (isMedical) {
    const nonDefaultServices = newCustomServices.filter(
      (service) => !service.isDefault
    );
    const nonDefaultExistingServices = nonDefaultServices.filter((service) => {
      const isDefault = !defaultBenefitTypes.find((item) => {
        return trim(item.shortName) === trim(service.benefitCode.shortName);
      });

      return (
        service.benefitCode.code < 9000 &&
        service.benefitCode.code !== null &&
        isDefault
      );
    });

    const nonDefaultCustomServices = newCustomServices.filter(
      (service) =>
        service.benefitCode.code >= 9000 || service.benefitCode.code === null
    );

    return { nonDefaultExistingServices, nonDefaultCustomServices };
  } else {
    const nonDefaultServices = newCustomServices.filter(
      (service) => !service.isDefault
    );
    const nonDefaultExistingServices = nonDefaultServices.filter(
      (service) =>
        service.benefitCode.code < 9000 && service.benefitCode.code !== null
    );

    const nonDefaultCustomServices = nonDefaultServices.filter(
      (service) =>
        service.benefitCode.code >= 9000 || service.benefitCode.code === null
    );

    return { nonDefaultExistingServices, nonDefaultCustomServices };
  }
};

export const formatSFCValue = (value: any | undefined) => {
  if (typeof value === 'undefined' || value === null) {
    return '';
  }
  if (value.toString().trim().length !== 0) {
    return value.toString().trim();
  }
  return '';
};

export const holidayAndTimeOffPlanTypes = [
  BenefitKind.PAID_TIME_OFF.value,
  BenefitKind.SICK.value,
  BenefitKind.HOLIDAY.value,
  BenefitKind.FLEXIBLE_WORKING_HOURS.value,
  BenefitKind.OTHER_TIME_OFF.value,
];

export const getFrequencyAccordingToPlanType = (planType: string) => {
  switch (planType) {
    case TaxAdvantagedAccountPlanType.HSA.value:
      return EmployerContributionHSAFrequency;
    default:
      return EmployerContributionFrequency;
  }
};

/**
 * Used to transform a plan from its VO representation we get from the backend, to a structure
 * we can directly merge into the state. This is for Medical plans but there are similar
 * functions for other benefit types.
 * @param {any} plan The plan to transform.
 * @param {any[]} codes array of benefit codes for this plan type. Used in service transformation
 * @param {boolean} isHsaCompatible Whether this medical plan is HSA compatible.
 * @return {Partial<MedicalPlan>} The transformed plan.
 */
export const transformProcessedMedicalPlanForStateInjection = (
  plan: any,
  codes: any,
  isHsaCompatible: any
) => {
  const {
    deductibles,
    outOfPocket,
    customServices,
    rxCosts,
    mailOrderRxCosts,
    textractJobId,
    documentReferences,
    rxDeductiblesAndOop,
    llmExtractionInfo,
    extractionFinalized,
  } = plan;

  const defaultServiceCodes = codes
    ?.filter(
      (service: PlanService) =>
        service &&
        service.isSaasSupported &&
        service.isDefault &&
        service.benefitKind === BenefitCategory.MEDICAL.value
    )
    .map((service: PlanService) => service.code);

  const updatedCustomServices = cloneDeep(customServices);
  const updatedDocuments = {
    [MedicalPlanDocumentType.SBC.value]:
      documentReferences?.[MedicalPlanDocumentType.SBC.value]?.fileName,
    [MedicalPlanDocumentType.PLAN_SUMMARY.value]:
      documentReferences?.[MedicalPlanDocumentType.PLAN_SUMMARY.value]
        ?.fileName,
  };
  updatedCustomServices?.forEach((service: any) => {
    service.isDefault = defaultServiceCodes.includes(service.benefitCode.code);
  });

  const updatedRxCosts = cloneDeep(rxCosts);
  const updatedMailOrderRxCosts = cloneDeep(mailOrderRxCosts);

  updatedCustomServices?.forEach((service: any) => {
    if (service.benefitCode.code !== benefitCode.MEDICAL_PREVENTIVE_CARE.code) {
      const inNetwork = service.serviceValue.inNetwork;
      const outOfNetwork = service.serviceValue.outOfNetwork;

      inNetwork.copayPriorToDeductible = isHsaCompatible
        ? PriorToDeductible.NO
        : '';
      outOfNetwork.copayPriorToDeductible = isHsaCompatible
        ? PriorToDeductible.NO
        : '';

      service.serviceValue.inNetwork = inNetwork;
      service.serviceValue.outOfNetwork = outOfNetwork;
    }
    return service;
  });

  if (updatedRxCosts?.inNetwork) {
    Object.keys(updatedRxCosts?.inNetwork).forEach((key) => {
      (updatedRxCosts?.inNetwork[key] as CostSharing).copayPriorToDeductible =
        isHsaCompatible ? PriorToDeductible.NO : '';
    });
  }

  if (updatedRxCosts?.outOfNetwork) {
    Object.keys(updatedRxCosts?.outOfNetwork).forEach((key) => {
      (
        updatedRxCosts?.outOfNetwork[key] as CostSharing
      ).copayPriorToDeductible = isHsaCompatible ? PriorToDeductible.NO : '';
    });
  }

  if (updatedMailOrderRxCosts?.inNetwork) {
    Object.keys(updatedMailOrderRxCosts?.inNetwork).forEach((key) => {
      (
        updatedMailOrderRxCosts?.inNetwork[key] as CostSharing
      ).copayPriorToDeductible = isHsaCompatible ? PriorToDeductible.NO : '';
    });
  }

  if (updatedMailOrderRxCosts?.outOfNetwork) {
    Object.keys(updatedMailOrderRxCosts?.outOfNetwork).forEach((key) => {
      (
        updatedMailOrderRxCosts?.outOfNetwork[key] as CostSharing
      ).copayPriorToDeductible = isHsaCompatible ? PriorToDeductible.NO : '';
    });
  }

  return {
    deductibles,
    outOfPocket,
    customServices: updatedCustomServices,
    rxCosts: updatedRxCosts,
    mailOrderRxCosts: updatedMailOrderRxCosts,
    textractJobId,
    documentReferences: documentReferences,
    documents: updatedDocuments,
    rxDeductiblesAndOop: rxDeductiblesAndOop,
    llmExtractionInfo,
    extractionFinalized,
  };
};

/**
 * Used to transform a plan from its VO representation we get from the backend, to a structure
 * we can directly merge into the state. This is for Dental plans but there are similar
 * functions for other benefit types.
 * @param {any} plan The plan to transform.
 * @param {any[]} codes array of benefit codes for this plan type. Used in service transformation
 * @return {Partial<DentalPlan>} The transformed plan.
 */
export const transformProcessedDentalPlanForStateInjection = (
  plan: any,
  codes: any
) => {
  const {
    deductibles,
    outOfPocket,
    customServices,
    textractJobId,
    calendarYearMax,
    orthodontiaLifetimeMax,
    documentReferences,
    llmExtractionInfo,
    extractionFinalized,
  } = plan;

  const defaultServiceCodes = codes
    .filter(
      (service: PlanService) =>
        service &&
        service.isSaasSupported &&
        service.isDefault &&
        service.benefitKind === BenefitCategory.DENTAL.value
    )
    .map((service: PlanService) => service.code);

  /**
   * TODO: In the PY-15 implementation of AiSBCUploader the custom services for dental and
   * vision are not editable by the OPS admin. Only default services are visible and can
   * be edited. This is done as to fix to the issue described here
   * https://planalchemy.atlassian.net/browse/PLAT-26027
   * This will be fixed in a future release.
   */
  const updatedCustomServices: any[] = (cloneDeep(customServices) ?? [])
    .map((service: any) => ({
      ...service,
      isDefault: defaultServiceCodes.includes(service.benefitCode.code),
    }))
    .filter((service: any) => service?.isDefault === true)
    .map((service: any) => {
      const inNetwork = service.serviceValue?.inNetwork || {};
      const outOfNetwork = service.serviceValue?.outOfNetwork || {};
      inNetwork.copayPriorToDeductible = PriorToDeductible.NO;
      outOfNetwork.copayPriorToDeductible = PriorToDeductible.NO;
      return service;
    });

  const updatedDocuments = {
    PLAN_SUMMARY:
      documentReferences?.[DentalPlanDocumentType.PLAN_SUMMARY.value]?.fileName,
  };

  return {
    deductibles,
    outOfPocket,
    customServices: updatedCustomServices,
    textractJobId,
    calendarYearMax,
    orthodontiaLifetimeMax,
    documents: updatedDocuments,
    documentReferences,
    llmExtractionInfo,
    extractionFinalized,
  };
};

/**
 * Used to transform a plan from its VO representation we get from the backend to a structure
 * we can directly merge into the state. This is for Vision plans but there are similar
 * functions for other benefit types.
 * @param {any} plan The plan to transform.
 * @param {any[]} codes array of benefit codes for this plan type. Used in service transformation
 * @return {Partial<VisionPlan>} The transformed plan.
 */
export const transformProcessedVisionPlanForStateInjection = (
  plan: any,
  codes: any
) => {
  const {
    customServices,
    textractJobId,
    documentReferences,
    llmExtractionInfo,
    extractionFinalized,
  } = plan;

  const defaultServiceCodes = codes
    .filter(
      (service: PlanService) =>
        service &&
        service.isSaasSupported &&
        service.isDefault &&
        service.benefitKind === BenefitCategory.VISION.value
    )
    .map((service: PlanService) => service.code);

  /**
   * TODO: In the PY-15 implementation of AiSBCUploader the custom services for dental and
   * vision are not editable by the OPS admin. Only default services are visible and can
   * be edited. This is done as to fix to the issue described here
   * https://planalchemy.atlassian.net/browse/PLAT-26027
   * This will be fixed in a future release.
   */
  const updatedCustomServices: any[] = (cloneDeep(customServices) ?? [])
    .map((service: any) => ({
      ...service,
      isDefault: defaultServiceCodes.includes(service.benefitCode.code),
    }))
    .filter((service: any) => service?.isDefault === true)
    .map((service: any) => {
      const inNetwork = service.serviceValue.inNetwork || {};
      const outOfNetwork = service.serviceValue.outOfNetwork || {};
      inNetwork.copayPriorToDeductible = PriorToDeductible.NO;
      outOfNetwork.copayPriorToDeductible = PriorToDeductible.NO;
      return service;
    });

  const updatedDocuments = {
    PLAN_SUMMARY:
      documentReferences?.[VisionPlanDocumentType.PLAN_SUMMARY.value]?.fileName,
  };

  return {
    customServices: updatedCustomServices,
    textractJobId,
    documents: updatedDocuments,
    documentReferences,
    llmExtractionInfo,
    extractionFinalized,
  };
};

/**
 * Used to transform a plan from its VO representation we get from the backend a structure
 * we can directly merge into the state. This is for Life plans.
 * @param {LifePlan} plan The plan to transform.
 * @return {LifePlan} The transformed plan.
 */
export const transformProcessedLifePlanForStateInjection = (plan: LifePlan) => {
  const updatedDocuments = {
    PLAN_SUMMARY:
      plan.documentReferences?.[MedicalPlanDocumentType.PLAN_SUMMARY.value]
        ?.fileName,
  };

  const updatedPlan = {
    ...plan,
    documents: updatedDocuments,
    llmExtractionInfo: plan?.llmExtractionInfo,
  };
  return updatedPlan;
};

export const getParamsFromUrlParams = (params: string[]) => {
  const currentURL = window.location.search;
  const urlSearchParams = new URLSearchParams(currentURL);
  const result: { [key: string]: string | null } = {};

  params?.forEach((param) => {
    if (urlSearchParams.has(param)) {
      result[param] = urlSearchParams.get(param);
    }
  });

  return result;
};

export const removeSearchParams = (params: string[]) => {
  const url = new URL(window.location.href);
  params?.forEach((param) => {
    url.searchParams.delete(param);
  });
  window.history.replaceState({}, '', url.toString());
};

export const isEmptyValue = (value: any) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};

/**
 * Transforms the life plan data to a format that can be directly inserted into the life plan form.
 * This function exists as the AddLifePlan form actually relies on an internal form rather than the redux state
 * like other components. To inject data into this internal form, it needs to be transformed as such.
 *
 * The above is also why this is not included in the transformProcessedLifePlanForStateInjection
 * function
 *
 * @param {LifePlan} planData Life plan data to be transformed
 * @return {LifePlanFormData} data that can inserted directly into the life plan form
 */
export const transformToFormData = (planData: LifePlan): LifePlanFormData => {
  const formData: LifePlanFormData = {
    planYear: planData.planYearId,
    benefitCarrier: planData.benefitCarrier?.id,
    planName: planData.name,
    benefitClass: planData.groups,
    multiplier: planData.multiplier,
    flatAmount: planData.flatAmount,
    benefitMaximum: planData.benefitMaximum,
    guaranteedIssue: planData.guaranteedIssue,
    lifeRate: planData.lifeRate,
    lifeBenefit: planData.lifeBenefit ?? '',
    addRate: planData.addRate,
    benefitPercentage: planData.benefitPercentage,
    weeklyBenefitMax: planData.weeklyBenefitMax,
    monthlyBenefitMax: planData.monthlyBenefitMax,
    waitingPeriod: planData.waitingPeriod ?? '',
    benefitDurationSTD: planData.benefitDurationSTD,
    benefitDurationLTD: planData.benefitDurationLTD,
    rate: planData.rate,
    employeeBenefit: planData.employeeBenefit ?? '',
    employeeGuaranteedIssue: planData.employeeGuaranteedIssue,
    spouseBenefit: planData.spouseBenefit ?? '',
    spouseGuaranteedIssue: planData.spouseGuaranteedIssue,
    childBenefit: planData.childBenefit ?? '',
    childGuaranteedIssue: planData.childGuaranteedIssue,
    documents: planData.documents,
    fundingType: planData.fundingType,
    groupId: planData.groupId,
    volume: planData.volume,
    enrollment: planData.enrollment,
    administrationFee: planData.administrationFee,
    annualEstimatedClaims: planData.annualEstimatedClaims,
    ageReduction: planData.ageReduction?.trim(),
    waiverOfPremium: planData.waiverOfPremium,
    acceleratedLifeBenefit: planData.acceleratedLifeBenefit,
    portability: planData.portability,
    definitionOfEarnings: planData.definitionOfEarnings,
    additionalServices: planData.additionalServices,
    definitionOfDisability: planData.definitionOfDisability,
    preExistingConditions: planData.preExistingConditions,
    stateDisabilityIntegration: planData.stateDisabilityIntegration,
    ownOccupationPeriod: planData.ownOccupationPeriod,
    w2Preparation: planData.w2Preparation,
    lifeServices: planData.lifeServices,
    stdServices: planData.stdServices,
    ltdServices: planData.ltdServices,
  };

  if (planData.sfcPlanConfigVo) {
    formData.administrationFee =
      planData.sfcPlanConfigVo.administrationFee ?? '';
  }

  return formData;
};

/**
 * Creates a function that can be used for executing the common closing logic for the new AddPlanModal
 * function.
 * @param {ModalCloseSettings} modalCloseSettings An options object used to configure the created close handler
 * @return {Function} A function that handles closing the AddPlanModal
 */
export const createModalCloseHandler = ({
  clearAction,
  removeParamsAction,
  handleCloseAction,
  setAlertMessageAction,
  setVisibleAction,
  status,
  messages,
}: ModalCloseSettings) => {
  return (extractStatus?: ProcessStatus) => {
    // Determine the current status based on the provided extractStatus or the existing status.
    const currentStatus = extractStatus ?? status;

    const relevantStatuses = [
      ProcessStatus.PROCESSING,
      ProcessStatus.SUCCESS,
      ProcessStatus.REVIEWED,
      ProcessStatus.VALIDATED,
      ProcessStatus.FAILED,
      ProcessStatus.CANCELLED,
    ];

    // Check if the current status is one of the relevant statuses.
    if (relevantStatuses.includes(currentStatus)) {
      clearAction();
      removeParamsAction();

      // Set the alert message based on the extracted status, if applicable.
      const message = messages[extractStatus!];
      if (message) {
        setAlertMessageAction({
          type: extractStatus === ProcessStatus.FAILED ? 'error' : 'success',
          message,
        });
        setVisibleAction(true);
      }
    }
    handleCloseAction();
  };
};

/**
 * Gets the default plan rates if the current plan's rates are empty.
 * This function ensures that a MDV plan has the necessary rate information.
 * If the rates are not present, it adds default rates to the plan.
 *
 * @param {MedicalPlan | DentalPlan | VisionPlan } editedPlan - The plan to check and possibly augment with default rates.
 * @return {MedicalPlan | DentalPlan | VisionPlan } The plan with guaranteed rate information.
 */
export const getDefaultPlanRates = (
  editedPlan: MedicalPlan | DentalPlan | VisionPlan
): MedicalPlan | DentalPlan | VisionPlan => {
  if (isEmpty(editedPlan.rates)) {
    return {
      ...editedPlan,
      rates: {
        defaultBenefitClass: {
          type: RateType.FOUR_TIER.value,
          contributionType: ContributionType.FIXED.value,
          fourTierContributions: {
            contributions: ['EE', 'EC', 'ES', 'EF'].map((tier) => ({
              tierName: tier,
            })),
          },
        },
      },
    };
  }
  return editedPlan;
};

/**
 * Sets the active panel based on the provided benefit.
 * @param {'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE'} benefit - The type of benefit ('MEDICAL', 'DENTAL', 'VISION', 'LIFE').
 * @param {React.RefObject} addPlanRef - Optional React ref object containing the setActivePanel function.
 * @return {void}
 */
export const setBenefitActivePanel = (
  benefit: 'MEDICAL' | 'DENTAL' | 'VISION' | 'LIFE',
  addPlanRef?: React.RefObject<{
    setActivePanel: (panel: PanelSection) => void;
  }>
): void => {
  // Ensure that the setActivePanel function is defined before calling it
  if (!addPlanRef?.current?.setActivePanel) {
    return;
  }

  switch (benefit) {
    case 'MEDICAL':
    case 'DENTAL':
      addPlanRef.current.setActivePanel(PanelSection.DEDUCTIBLES_OOP_MAX);
      break;
    case 'VISION':
      addPlanRef.current.setActivePanel(PanelSection.SERVICES);
      break;
    case 'LIFE':
      addPlanRef.current.setActivePanel(PanelSection.PLAN_INFORMATION);
      break;
  }
};

/**
 * Removes benefit documents from a plan state.
 * @param {MedicalPlan | DentalPlan | VisionPlan | LifePlan} plan - The plan from which to remove the benefit documents.
 * @param {any} documentReferences - The document references object.
 * @param {MedicalPlan | DentalPlan | VisionPlan | LifePlan} editedPlan - The edited plan (optional).
 * @return {void}
 */
export const removeBenefitDocumentsFromPlan = (
  plan: MedicalPlan | DentalPlan | VisionPlan | LifePlan,
  documentReferences: { [key: string]: any },
  editedPlan?: MedicalPlan | DentalPlan | VisionPlan | LifePlan
): void => {
  Object.values(AI_FILE_TYPE).forEach((fileType: AI_FILE_TYPE_KEYS) => {
    if (plan.documentReferences && fileType in plan.documentReferences) {
      delete plan.documentReferences[
        fileType as keyof typeof plan.documentReferences
      ];
    }
    if (
      editedPlan?.documentReferences &&
      fileType in editedPlan.documentReferences
    ) {
      delete editedPlan.documentReferences[
        fileType as keyof typeof editedPlan.documentReferences
      ];
    }
    if (plan.documents && fileType in plan.documents) {
      delete plan.documents[fileType as keyof typeof plan.documents];
    }
    if (editedPlan?.documents && fileType in editedPlan.documents) {
      delete editedPlan.documents[
        fileType as keyof typeof editedPlan.documents
      ];
    }
    if (documentReferences && fileType in documentReferences) {
      delete documentReferences[fileType as keyof typeof documentReferences];
    }
  });
};

export const emptyValue = (value: any): boolean => {
  let check: boolean = false;

  if (value === undefined || value === null || value === '') {
    check = true;
  }

  return check;
};

export const scrollToCollapsedPanel = (ref: any, yPosition: number) => {
  ref?.current?.scrollTo(0, yPosition);
};

export const handleScrollToNextPanel = (currentKey: PanelSection, ref: any) => {
  switch (currentKey) {
    case PanelSection.BASIC_PLAN_INFO:
      scrollToCollapsedPanel(ref, 70);
      break;
    case PanelSection.RATES:
      scrollToCollapsedPanel(ref, 150);
      break;
    case PanelSection.DEDUCTIBLES_OOP_MAX:
      scrollToCollapsedPanel(ref, 220);
      break;
    case PanelSection.SERVICES:
      scrollToCollapsedPanel(ref, 300);
      break;
    default:
      return;
  }
};

export const getNonMdvAdditionalServices = (
  benefitKind: string,
  existing: any[]
): any[] => {
  // Return the services directly if 'existing' is empty or null
  if (!existing || existing.length === 0) {
    return NON_MDV_ADDITIONAL_SERVICES[benefitKind] ?? [];
  }

  // Create a set of existing services for faster lookup
  const existingServicesSet = new Set(
    existing.map((item: any) => item.service)
  );

  // Filter the additional services based on the set
  return NON_MDV_ADDITIONAL_SERVICES[benefitKind].filter(
    (service) => !existingServicesSet.has(service)
  );
};

/**
 *
 * Iterage over the list of services and delete the service accordingly
 * @param {NonMDVServiceType[]} existing list of non mdv services
 * @param {string} service changing services
 * @return {NonMDVServiceType[]} list of changed services
 */
export const deleteNonMdvService = (
  existing: NonMDVServiceType[],
  service: string
): NonMDVServiceType[] => {
  return existing.filter((obj) => obj.service !== service) ?? [];
};

/**
 *
 * Iterage over the list of services and change the description accordingly
 * @param {NonMDVServiceType[]} existing list of non mdv services
 * @param {string} service changing services
 * @param {string} value description value
 * @return {NonMDVServiceType[]} list of changed services
 */
export const handleNonMdvDescChange = (
  existing: NonMDVServiceType[],
  service: string,
  value: string
): NonMDVServiceType[] => {
  return existing.map((item) => {
    if (item.service === service) {
      return {
        ...item,
        description: value,
      };
    }
    return item;
  });
};

/**
 * Retrieves the service information based on the short name and network type only for LLM.
 *
 * @param {string} shortName - The short name of the benefit.
 * @param {LlmCustomService[] | undefined} customServices - LLM custom services.
 * @return {LlmServiceInfo | undefined} - The service information object or undefined if not found.
 */
export const getLlmServiceInfo = (
  shortName: string,
  customServices: LlmCustomService[] | undefined
): LlmServiceInfo | undefined => {
  const service = customServices?.find(
    (service) =>
      get(service, 'benefitCode.shortName')
        .replace(/\s+/g, '')
        .toLowerCase() === shortName.replace(/\s+/g, '').toLowerCase()
  );
  if (!service) {
    return undefined;
  }
  return {
    inNetwork: get(service, 'serviceValue.inNetwork.info'),
    outOfNetwork: get(service, 'serviceValue.outOfNetwork.info'),
  };
};

/**
 * Retrieves the cost info object based on the tier and network type.
 *
 * @param {string} tier - The tier name (e.g., "TIER_1").
 * @param {LlmServiceInfoKeys} networkType - The network type (inNetwork or outOfNetwork).
 * @param {RxCosts} llmRxCosts - The llmRxCosts object.
 * @return {CostInfo | undefined} - The cost info object or undefined if not found.
 */
export const getLlmSCostInfo = (
  tier: string,
  networkType: LlmServiceInfoKeys,
  llmRxCosts: LlmRxCosts | undefined | null
): LlmInfo | undefined => {
  return get(llmRxCosts, `${networkType}.${tier}.info`);
};

export const getSubtypeAvailability = (
  permittedList: String[],
  appBootInfo: any
) => {
  const individualSubType = appBootInfo?.individualSubType
    ? appBootInfo.individualSubType
    : '';
  return (
    isEmpty(individualSubType) || permittedList.includes(individualSubType)
  );
};

/**
 * Retrieves the description of a specified life additional service based on the additional service name and life plan type.
 *
 * @param {string} serviceName - The name of the additional service.
 * @param {LlmExtractionInfo | undefined} llmExtractionInfo - LLM extraction information with various life additional services arrays.
 * @param {string} lifePlanType - The life plan type. It determines which services array will be searched.
 * @return {LlmInfo | undefined} - The LLM additional service description object or undefined if not found.
 */
export const getLifeServiceInfo = (
  serviceName: string,
  llmExtractionInfo: LlmExtractionInfo | null | undefined,
  lifePlanType: string
): LlmInfo | undefined => {
  let services: LlmLifeService[] | null | undefined;

  switch (lifePlanType) {
    case BasicPlans.LTD.value:
      services = llmExtractionInfo?.ltdServices;
      break;
    case BasicPlans.STD.value:
      services = llmExtractionInfo?.stdServices;
      break;
    default:
      // for Basic Life, Basic AD&D, Basic Life w/AD&D
      services = llmExtractionInfo?.lifeServices;
      break;
  }

  const service = services?.find(
    (service) =>
      service.service.replace(/\s+/g, '').toLowerCase() ===
      serviceName.replace(/\s+/g, '').toLowerCase()
  );

  if (!service) {
    return undefined;
  }

  return service.description;
};

/**
 * Creates a mock plan object with randomized UUIDs and predefined values.
 *
 * @return {Object} A mock plan object with fields such as id, employerId, planYearId,
 *                   name, startDate, endDate, groupId, hsaCompatible, fsaCompatible,
 *                   planNetworkName, and fundingType.
 *
 * @example
 * const mockPlan = createMockPlanObject();
 * console.log(mockPlan);
 *
 *  Output:
 * {
 *  id:'random-uuid',
 *  employerId: 'random-uuid',
 *  planYearId: 'random-uuid',
 *  name: 'Plan Name - Dummy - Updated Plan',
 *  startDate: '2024-08-05T12:34:56.789Z',
 *  endDate: '2024-08-05T12:34:56.789Z',
 *  groupId: 'random-uuid',
 *  hsaCompatible: true,
 *  fsaCompatible: false,
 *  planNetworkName: '3232',
 *  fundingType: 'FULLY_INSURED'
 *  }
 */

export const createMockPlanObject = (): object => {
  return {
    employerId: uuidv4(),
    planYearId: uuidv4(),
    name: 'Plan Name - Dummy - Updated Plan',
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    groupId: uuidv4(),
    hsaCompatible: true,
    fsaCompatible: false,
    planNetworkName: uuidv4(),
    fundingType: 'FULLY_INSURED',
  };
};
