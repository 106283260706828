import axios from 'axios';
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

import ErrorDetails from 'model/ErrorDetails';

import * as BrokerLocationService from 'modules/brokers/services/BrokerLocationService';
import { Location } from 'model/broker/Location';
import { BrokerLocationSummary } from 'model/broker/BrokerLocationSummary';
import LocationReassign from 'model/LocationReassign';
import PaginationConfig from 'model/PaginationConfig';
import EmployerLocation from 'model/EmployerLocation';
import MetaData from 'model/MetaData';

import { STATUS_204 } from 'constants/commonConstants';
export interface BrokerLocationState {
  inProgress: boolean;
  brokerLocations: {
    selectAll: boolean;
    employerLocations: EmployerLocation[];
  };
  error: ErrorDetails | null;
  deleteInProgress: boolean;
  deleteCompleted: boolean;
  createInProgress: boolean;
  brokerLocationCreateError: ErrorDetails | null;
  brokerLocationCreationSuccess: boolean;
  brokerLocationObj: Location;
  updateInProgress: boolean;
  updateCompleted: boolean;
  brokerLocationUpdateError: ErrorDetails | null;
  brokerLocationList: { content: Array<Location>; metadata: MetaData };
  locationFindInProgress: boolean;
  brokerLocationSummary: BrokerLocationSummary;
  fetchSummaryInProgress: boolean;
  fetchSummarySucceeded: boolean;
}
const initialState: BrokerLocationState = {
  inProgress: false,
  brokerLocations: {
    selectAll: false,
    employerLocations: [],
  },
  error: null,
  deleteInProgress: false,
  deleteCompleted: false,
  createInProgress: false,
  brokerLocationCreateError: null,
  brokerLocationCreationSuccess: false,
  brokerLocationObj: {} as Location,
  updateInProgress: false,
  updateCompleted: false,
  brokerLocationUpdateError: null,
  brokerLocationList: { content: [], metadata: {} },
  locationFindInProgress: false,
  brokerLocationSummary: {} as BrokerLocationSummary,
  fetchSummaryInProgress: false,
  fetchSummarySucceeded: false,
};

const brokerLocationSlice = createSlice({
  name: 'brokerLocations',
  initialState,
  reducers: {
    getBrokerLocationListInProgress: (state, action: PayloadAction) => {
      state.inProgress = true;
      state.error = null;
    },
    getBrokerLocationListSuccess: (
      state,
      action: PayloadAction<{
        selectAll: boolean;
        employerLocations: EmployerLocation[];
      }>
    ) => {
      state.inProgress = false;
      state.brokerLocations = action.payload;
      state.error = null;
    },
    getBrokerLocationListFailed: (state, action) => {
      state.inProgress = false;
      state.error = action.payload;
    },
    deleteBrokerLocationStarted: (state) => {
      state.deleteInProgress = true;
    },
    deleteBrokerLocationCompleted: (state) => {
      state.deleteInProgress = false;
      state.deleteCompleted = true;
      state.error = null;
    },
    clearBrokerLocationDeletion: (state) => {
      state.deleteInProgress = false;
      state.deleteCompleted = false;
      state.error = null;
    },
    deleteBrokerLocationFailed: (state, action) => {
      state.deleteInProgress = false;
      state.deleteCompleted = true;
      state.error = action.payload;
    },
    getBrokerLocationSummaryStarted: (state) => {
      state.fetchSummaryInProgress = true;
      state.fetchSummarySucceeded = false;
      state.brokerLocationSummary = {} as BrokerLocationSummary;
    },
    getBrokerLocationSummarySucceeded: (
      state,
      action: PayloadAction<BrokerLocationSummary>
    ) => {
      state.brokerLocationSummary = action.payload;
      state.fetchSummaryInProgress = false;
      state.fetchSummarySucceeded = true;
    },
    addBrokerLocationCreateStarted: (state) => {
      state.createInProgress = true;
      state.brokerLocationCreateError = null;
      state.brokerLocationObj = {} as Location;
      state.brokerLocationCreationSuccess = false;
    },
    addBrokerLocationCreateSucceeded: (
      state,
      action: PayloadAction<Location>
    ) => {
      state.brokerLocationObj = action.payload;
      state.brokerLocationCreationSuccess = true;
      state.brokerLocationCreateError = null;
      state.createInProgress = false;
    },
    brokerLocationCreateFailed: (state, action) => {
      state.createInProgress = false;
      state.brokerLocationCreateError = action.payload;
      state.brokerLocationObj = {} as Location;
      state.brokerLocationCreationSuccess = false;
    },
    clearBrokerLocationCreation: (state) => {
      state.createInProgress = false;
      state.brokerLocationObj = {} as Location;
      state.brokerLocationCreateError = null;
      state.brokerLocationCreationSuccess = false;
    },
    updateBrokerLocationStarted: (state) => {
      state.updateInProgress = true;
      state.brokerLocationUpdateError = null;
    },
    updateBrokerLocationCompleted: (state, action) => {
      state.updateInProgress = false;
      state.updateCompleted = action.payload === STATUS_204;
      state.brokerLocationUpdateError = null;
    },
    clearUpdateBrokerLocationUpdation: (state) => {
      state.updateInProgress = false;
      state.updateCompleted = false;
      state.brokerLocationUpdateError = null;
    },
    updateeBrokerLocationFailed: (state, action) => {
      state.updateInProgress = false;
      state.updateCompleted = true;
      state.brokerLocationUpdateError = action.payload;
    },
    getPaginatedBrokerLocationListInProgress: (state) => {
      state.inProgress = true;
      state.brokerLocationList = { content: [], metadata: {} };
      state.error = null;
    },
    getPaginatedBrokerLocationListSuccess: (
      state,
      action: PayloadAction<{ content: Array<Location>; metadata: {} }>
    ) => {
      state.inProgress = false;
      state.brokerLocationList = action.payload;
      state.error = null;
    },
    getPaginatedBrokerLocationListFailed: (state, action) => {
      state.inProgress = false;
      state.brokerLocationList = { content: [], metadata: {} };
      state.error = action.payload;
    },
    getLocationByIdStarted: (state) => {
      state.locationFindInProgress = true;
      state.brokerLocationObj = {} as Location;
      state.error = null;
    },
    getLocationByIdCompleted: (state, action: PayloadAction<Location>) => {
      state.locationFindInProgress = false;
      state.brokerLocationObj = action.payload;
      state.error = null;
    },
    getLocationByIdFailed: (state, action) => {
      state.locationFindInProgress = false;
      state.brokerLocationObj = {} as Location;
      state.error = action.payload;
    },
    clearLocationFind: (state) => {
      state.locationFindInProgress = false;
      state.brokerLocationObj = {} as Location;
      state.error = null;
    },
  },
});

export const {
  getBrokerLocationListInProgress,
  getBrokerLocationListSuccess,
  getBrokerLocationListFailed,
  deleteBrokerLocationCompleted,
  deleteBrokerLocationStarted,
  clearBrokerLocationDeletion,
  deleteBrokerLocationFailed,
  addBrokerLocationCreateStarted,
  addBrokerLocationCreateSucceeded,
  brokerLocationCreateFailed,
  clearBrokerLocationCreation,
  updateBrokerLocationStarted,
  updateBrokerLocationCompleted,
  clearUpdateBrokerLocationUpdation,
  updateeBrokerLocationFailed,
  getPaginatedBrokerLocationListInProgress,
  getPaginatedBrokerLocationListSuccess,
  getPaginatedBrokerLocationListFailed,
  getLocationByIdStarted,
  getLocationByIdCompleted,
  getLocationByIdFailed,
  clearLocationFind,
  getBrokerLocationSummaryStarted,
  getBrokerLocationSummarySucceeded,
} = brokerLocationSlice.actions;

export default brokerLocationSlice.reducer;

export const getBrokerLocationList = (organizationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getBrokerLocationListInProgress());
    try {
      const response =
        await BrokerLocationService.findBrokerLocationsByLoggedInUser(
          organizationId
        );
      const data = response.data;
      dispatch(getBrokerLocationListSuccess(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getBrokerLocationListFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const deleteLocationAndReassign = (
  id: string,
  data: LocationReassign
) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteBrokerLocationStarted());
    try {
      await BrokerLocationService.reassignBrokerLocation(id, data);
      await BrokerLocationService.deleteBrokerLocation(id);
      dispatch(deleteBrokerLocationCompleted());
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          deleteBrokerLocationFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const addBrokerLocation = (data: Location) => {
  return async (dispatch: Dispatch) => {
    dispatch(addBrokerLocationCreateStarted());
    try {
      const response = await BrokerLocationService.createBrokerLocation(data);
      dispatch(addBrokerLocationCreateSucceeded(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          brokerLocationCreateFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const updateBrokerLocation = (locationId: string, data: Location) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateBrokerLocationStarted());
    try {
      const response = await BrokerLocationService.updateBrokerLocationById(
        locationId,
        data
      );
      dispatch(updateBrokerLocationCompleted(response.status));
    } catch (error: any) {
      dispatch(updateeBrokerLocationFailed(error.response));
    }
  };
};

export const getPaginatedBrokerLocationList = (
  { page, size, sort, query }: PaginationConfig,
  organizationId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getBrokerLocationListInProgress());
    try {
      const response =
        await BrokerLocationService.getPaginatedBrokerLocationList(
          page,
          size,
          sort,
          query,
          organizationId
        );
      const data = response.data;
      dispatch(getPaginatedBrokerLocationListSuccess(data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getPaginatedBrokerLocationListFailed(
            JSON.parse(JSON.stringify(error.response))
          )
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const findLocationById = (locationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getLocationByIdStarted());
    try {
      const response = await BrokerLocationService.findLocationById(locationId);
      dispatch(getLocationByIdCompleted(response.data));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getLocationByIdFailed(JSON.parse(JSON.stringify(error.response)))
        );
      } else {
        console.error(error);
      }
    }
  };
};

export const fetchBrokerLocationSummary = (
  locationId: string,
  organizationId: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(getBrokerLocationSummaryStarted());
    try {
      const response = await BrokerLocationService.fetchBrokerLocationSummary(
        locationId,
        organizationId
      );
      dispatch(getBrokerLocationSummarySucceeded(response.data));
    } catch (error) {
      console.error(error);
    }
  };
};
