import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import styles from 'modules/issueslog/components/AnalyticsHeader/analyticsHeader.module.less';
import SingleSelectFilter from 'components/SingleSelectFilter/SingleSelectFilter';
import { issueType } from 'modules/issueslog/utils/issueLogUtils';
import { singleSelectAllLabel } from 'constants/commonConstants';

type Props = {
  summaryDetail: any[];
  supportTeamDropDown: Function;
  supportTeams: any[];
  isBadgesApplicable: boolean;
  archivedSupportTeams: any[];
};

const AnalyticsHeader = (props: Props) => {
  const {
    summaryDetail,
    supportTeamDropDown,
    supportTeams,
    archivedSupportTeams,
  } = props;
  const params = useParams();

  const [filterSelectedValue, setFilterSelectedValue] = useState<string | null>(
    null
  );

  useEffect(() => {
    supportTeamDropDown(filterSelectedValue);
  }, [filterSelectedValue, supportTeamDropDown]);

  return (
    <>
      {params.type === issueType.SUPPORT ? (
        <Row className={styles.supportTeamSelector}>
          <Col>
            <span className={styles.dropDownLabel}>Support Team:</span>
          </Col>
          <Col>
            <SingleSelectFilter
              data={supportTeams}
              placeholder={singleSelectAllLabel}
              showSearch={false}
              setFilterSelectedValue={setFilterSelectedValue}
              defaultValue={null}
              inlineDropdown={true}
              addAllValue={true}
              allLabel={singleSelectAllLabel}
              selectedValue={filterSelectedValue}
              archivedList={archivedSupportTeams}
              archivedText={'ARCHIVED TEAMS'}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      {props.isBadgesApplicable ? (
        <Row gutter={70} className={styles.headerRow}>
          <Row className={styles.infoContainer}>{summaryDetail}</Row>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

export default AnalyticsHeader;
