import React, { FC, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Checkbox, Col, Input, Popover, Row, Select } from 'antd';

import NumberFormatInput, {
  preventMoreThanHundredForPercentages,
} from 'components/FormInput/NumberFormatInput';
import PlanDeductibleOOPNetwork from 'model/PlanDeductibleOOPNetwork';

import {
  BenefitCovered,
  CostType,
  costTypes,
  coverageOption,
  dentalServiceFrequencies,
  IN_NETWORK,
  inNetworkMedicalCostTypes,
  medicalServiceFrequencies,
  NetworkRowFields,
  PriorToDeductible,
  rxFrequencies,
  visionServiceFrequencies,
} from 'modules/plans/constants';

import PanelSection from 'modules/plans/enums/PanelSection';
import CostSharing from 'model/plans/CostSharing';
import { isNullOrUndefined } from 'modules/plans/utils';
import { BenefitCategory } from 'constants/commonConstants';
import { generateID } from 'util/commonUtil';
import { ReactComponent as AIFeedbackRedFlag } from 'assets/images/ai-red-flag.svg';

import styles from './networkTypeRow.module.less';

const { Option } = Select;

const DEFAULT_MAX_VALUE = 999999;

type NetworkTypeRowProps = {
  networkType: string;
  networkDeductibleOOPObject?: PlanDeductibleOOPNetwork;
  networkObject?: CostSharing;
  formType?: string;
  onChange?: Function;
  onRowChange?: Function;
  hsaCompatible?: boolean;
  deductibleLabelSpan?: number;
  isTouched: boolean;
  decimalScale?: number;
  isFromDV?: boolean;
  isDisabled?: boolean;
  isOnlyCovered?: boolean;
  plan?: string;
  selectAll?: boolean | null;
  onSelectAllChange?: Function;
  trigger?: boolean;
  serviceTitle?: string;
  id?: string;
  isReviewProp?: boolean;
  isDefault?: boolean;
  isUpEdit?: boolean;
  setHasCostType?: Function;
  isDBGView?: boolean;
  showNumberInput?: boolean;
  highlightLLMValue?: boolean;
};

const ValueMapping = {
  COVERAGE: 'COVERAGE',
  COST: 'COST',
  costSharingPolicy: 'costSharingPolicy',
  copayFrequency: 'copayFrequency',
  copay: 'copay',
  coinsurance: 'coinsurance',
  copayPriorToDeductible: 'copayPriorToDeductible',
  info: 'info',
};

const NetworkTypeRow: FC<NetworkTypeRowProps> = (
  props: NetworkTypeRowProps
) => {
  const {
    networkObject,
    networkType,
    formType,
    networkDeductibleOOPObject,
    onChange,
    onRowChange,
    deductibleLabelSpan,
    hsaCompatible,
    isFromDV = false,
    isTouched,
    decimalScale,
    isDisabled = false,
    isOnlyCovered = false,
    plan = '',
    selectAll,
    serviceTitle,
    id,
    isReviewProp = false,
    highlightLLMValue = false,
    isDefault = false,
    setHasCostType,
    showNumberInput = false,
  } = props;

  const {
    costSharingPolicy,
    copayFrequency,
    copayPriorToDeductible,
    info: description,
    copay,
    coinsurance,
  } = networkObject || ({} as CostSharing);

  const { covered, coveredAmount = '' } = networkDeductibleOOPObject || {};

  const [selectedCostType, setSelectedCostType] =
    useState<string>(costSharingPolicy);
  const [selectedFrequency, setSelectedFrequency] =
    useState<string>(copayFrequency);
  const [selectedSingleFrequency, setSelectedSingleFrequency] = useState<
    string | null
  >(null);

  const [selectedDeductiblePrior, setDeductiblePrior] = useState<string>(
    copayPriorToDeductible
  );

  const [isValidAmount, setValidAmount] = useState<boolean>(true);

  const [info, setInfo] = useState<string>(description);

  const getCopayOrCoinsurance =
    networkObject?.costSharingPolicy === CostType.COPAY
      ? networkObject.copay
      : networkObject?.costSharingPolicy === CostType.COINSURANCE
      ? networkObject?.coinsurance
      : '';

  const [amount, setAmount] = useState<number | string>(getCopayOrCoinsurance);
  const [selectedCoverage, setSelectedCoverage] = useState<string | undefined>(
    covered
  );

  const [checkboxSelectedStatus, setCheckboxSelectedStatus] =
    useState<boolean>(false);
  const [selectedCoveredAmount, setSelectedCoveredAmount] = useState<
    number | string
  >(coveredAmount);
  const [currentSelectedCostType, setCurrentSelectedCostType] =
    useState<string>();

  const isCopayOrCoinsurance = [CostType.COPAY, CostType.COINSURANCE].includes(
    networkObject?.costSharingPolicy ?? ''
  );

  const isNotCoveredOrNotApplicable = [
    CostType.NOT_COVERED,
    CostType.NOT_APPLICABLE,
  ].includes(networkObject?.costSharingPolicy ?? '');

  const [costTypeState, setCostTypeState] = useState<any[]>(costTypes);
  const [inNetworkMedicalCostTypesState, setInNetworkMedicalCostTypesState] =
    useState<any[]>(inNetworkMedicalCostTypes);

  useEffect(() => {
    setAmount(getCopayOrCoinsurance);
  }, [getCopayOrCoinsurance]);

  const removeOption = () => {
    if (
      !isFromDV &&
      networkType === IN_NETWORK &&
      isDefault &&
      selectedCostType !== CostType.OTHER
    ) {
      setInNetworkMedicalCostTypesState(
        inNetworkMedicalCostTypesState.filter(
          (option) => option.value !== selectedCostType
        )
      );
    } else if (selectedCostType !== CostType.OTHER) {
      setCostTypeState(
        costTypeState.filter((option) => option.value !== selectedCostType)
      );
    }
  };

  useEffect(() => {
    setSelectedCostType(costSharingPolicy);
    removeOption();
    if (isCopayOrCoinsurance || isNotCoveredOrNotApplicable) {
      setHasCostType && setHasCostType(true);
    }
    // we don't need to include removeOption in the dependency array because it
    // doesn't directly depend on any of the variables that change over time.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    costSharingPolicy,
    setHasCostType,
    isCopayOrCoinsurance,
    isNotCoveredOrNotApplicable,
  ]);

  useEffect(() => {
    setSelectedFrequency(copayFrequency);
  }, [copayFrequency]);

  useEffect(() => {
    setDeductiblePrior(selectedDeductiblePrior);
  }, [selectedDeductiblePrior]);

  useEffect(() => {
    if (
      costSharingPolicy !== CostType.NOT_COVERED &&
      costSharingPolicy !== CostType.NOT_APPLICABLE &&
      !isEmpty(costSharingPolicy)
    ) {
      setCheckboxSelectedStatus(
        copayPriorToDeductible === PriorToDeductible.YES
      );
      setDeductiblePrior(copayPriorToDeductible);
    } else {
      setCheckboxSelectedStatus(false);
    }
  }, [copayPriorToDeductible, costSharingPolicy, copay, coinsurance]);

  useEffect(() => {
    if (isFromDV) {
      return;
    }
    if (isEmpty(copayPriorToDeductible)) {
      setDeductiblePrior(hsaCompatible ? PriorToDeductible.NO : '');
    }
  }, [copayPriorToDeductible, hsaCompatible, isFromDV]);

  useEffect(() => {
    if (isOnlyCovered) {
      setSelectedCoverage(coverageOption.COVERED);
    } else if (covered === coverageOption.NOT_APPLICABLE) {
      // reset select if NOT_APPLICABLE
      setSelectedCoverage(undefined);
    } else {
      setSelectedCoverage(covered);
    }
  }, [covered, isOnlyCovered]);

  useEffect(() => {
    setSelectedCoveredAmount(
      !isNullOrUndefined(coveredAmount) ? coveredAmount : ''
    );
  }, [coveredAmount]);

  useEffect(() => {
    setInfo(description);
  }, [description]);

  useEffect(() => {
    setValidAmount(
      ((amount as number) > DEFAULT_MAX_VALUE ||
        (selectedCoveredAmount as number) > DEFAULT_MAX_VALUE) &&
        !!isTouched
    );
  }, [amount, selectedCoveredAmount, isTouched]);

  useEffect(() => {
    if (
      selectedCostType === CostType.NOT_COVERED ||
      selectedCostType === CostType.NOT_APPLICABLE
    ) {
      setCheckboxSelectedStatus(false);
    }
  }, [selectedCostType]);

  const serviceFrequencies =
    medicalServiceFrequencies.find(
      (freq) => freq.name.localeCompare(serviceTitle?.trim() || '') === 0
    ) || medicalServiceFrequencies[medicalServiceFrequencies.length - 1];

  const frequencyOptions =
    formType === PanelSection.SERVICES
      ? plan == BenefitCategory.VISION.value
        ? visionServiceFrequencies
        : plan == BenefitCategory.DENTAL.value
        ? dentalServiceFrequencies
        : serviceFrequencies.options
      : rxFrequencies;

  const handleChangeForCostType = () => {
    handleChange(
      selectedCostType === CostType.COPAY
        ? frequencyOptions.length === 1 && selectedFrequency !== undefined
          ? selectedSingleFrequency == null
            ? frequencyOptions[0].value
            : selectedSingleFrequency
          : selectedFrequency
        : '',
      NetworkRowFields.FREQUENCY
    );
  };

  const handleChange = (
    value: any,
    column: string,
    costSharingPolicy?: string
  ) => {
    onRowChange &&
      onRowChange(
        column,
        value?.target &&
          (typeof value.target.value === 'string' ||
            typeof value.target.value === 'number')
          ? value.target.value
          : value,
        selectedDeductiblePrior,
        costSharingPolicy
      );

    if (value === CostType.OTHER) {
      setCurrentSelectedCostType(value);
    }

    switch (column) {
      case NetworkRowFields.COST_TYPE:
        resetFields();
        setSelectedCostType(value);
        onChange &&
          onChange([
            {
              key: ValueMapping.costSharingPolicy,
              value: !value ? BenefitCovered.CLEAR : value,
            },
            {
              key: ValueMapping.copay,
              value: null,
            },
            {
              key: ValueMapping.coinsurance,
              value: null,
            },
            {
              key: ValueMapping.copayFrequency,
              value: null,
            },
            {
              key: ValueMapping.info,
              value: null,
            },
            {
              key: ValueMapping.copayPriorToDeductible,
              value: !value ? null : selectedDeductiblePrior,
            },
          ]);
        break;

      case NetworkRowFields.FREQUENCY:
        setSelectedFrequency(value);
        onChange &&
          onChange([
            {
              key: ValueMapping.copayFrequency,
              value,
            },
          ]);
        break;

      case NetworkRowFields.AMOUNT:
        setAmount(value.target.value);
        const keyMap: any = {
          [CostType.COPAY]: ValueMapping.copay,
          [CostType.COINSURANCE]: ValueMapping.coinsurance,
        };
        onChange &&
          onChange([
            {
              key: keyMap[selectedCostType],
              value: value.target.value,
            },
          ]);
        break;

      case NetworkRowFields.DEDUCTIBLE_PRIOR:
        setDeductiblePrior(value);
        if (!isNotCoveredOrNotApplicable && !isCopayOrCoinsurance) {
          onChange &&
            onChange([
              {
                key: ValueMapping.costSharingPolicy,
                value: CostType.OTHER,
              },
              {
                key: ValueMapping.copayPriorToDeductible,
                value,
              },
            ]);
        } else {
          onChange &&
            onChange([
              {
                key: ValueMapping.copayPriorToDeductible,
                value,
              },
            ]);
        }
        break;

      case NetworkRowFields.COVERAGE:
        setSelectedCoverage(value);
        onChange &&
          onChange([
            {
              key: ValueMapping.COVERAGE,
              value,
            },
          ]);
        resetFields();
        break;

      case NetworkRowFields.COVERED_AMOUNT:
        setSelectedCoveredAmount(value.target.value);
        onChange &&
          onChange([
            {
              key: ValueMapping.COST,
              value: value.target.value,
            },
          ]);
        break;

      case NetworkRowFields.INFO:
        setInfo(value.target.value);
        if (!isNotCoveredOrNotApplicable && !isCopayOrCoinsurance) {
          onChange &&
            onChange([
              {
                key: ValueMapping.costSharingPolicy,
                value: CostType.OTHER,
              },
              {
                key: ValueMapping.info,
                value: value.target.value,
              },
            ]);
        } else {
          onChange &&
            onChange([
              {
                key: ValueMapping.costSharingPolicy,
                value: CostType.OTHER,
              },
              {
                key: ValueMapping.info,
                value: value.target.value,
              },
            ]);
        }
        break;
      default:
        break;
    }
  };

  const resetFields = () => {
    setSelectedFrequency('');
    setSelectedCoveredAmount('');
    setInfo('');
    setAmount('');
    setCheckboxSelectedStatus(false);
    setDeductiblePrior('');
  };

  const Component = (isDisabled ? Popover : React.Fragment) as any;

  return (
    <Row className={styles.networkTypeRowrapper} key={selectAll?.toString()}>
      {networkDeductibleOOPObject && (
        <>
          {networkType === IN_NETWORK ? (
            <Col span={deductibleLabelSpan || 10}>
              <Component
                content='These non-default services were imported from the SBC. You can add them by clicking the "+" icon to the left of the service.'
                placement="rightTop"
                overlayClassName="nonDefaultPopover"
              >
                <div className={styles.netMargin}>In-Network</div>
              </Component>
            </Col>
          ) : (
            <Component
              content='These non-default services were imported from the SBC. You can add them by clicking the "+" icon to the left of the service.'
              placement="rightTop"
            >
              <Col span={deductibleLabelSpan || 10}>Out-of-Network</Col>
            </Component>
          )}
          <Col span={14}>
            {showNumberInput ? (
              <div className={styles.inputWrapper}>
                <NumberFormatInput
                  id={`${id}_COVERED_AMOUNT`.replace(/ /g, '_').toLowerCase()}
                  value={selectedCoveredAmount}
                  onChange={(event) => {
                    handleChange(event, NetworkRowFields.COVERED_AMOUNT);
                  }}
                  onFocus={(event) => {
                    if (isEmpty(event.target.value)) {
                      handleChange(selectedCoverage, NetworkRowFields.COVERAGE);
                    }
                  }}
                  thousandSeparator
                  allowNegative={false}
                  prefix="$"
                  title={(amount && amount.toString()) || ''}
                  isInvalid={isValidAmount}
                  decimalScale={decimalScale}
                  customClass={
                    isReviewProp &&
                    !(
                      selectedCoverage !== coverageOption.COVERED &&
                      !isOnlyCovered
                    )
                      ? 'highlightField'
                      : highlightLLMValue
                      ? styles.highlightedLLMTextField
                      : ''
                  }
                />
                {highlightLLMValue && (
                  <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                )}
              </div>
            ) : (
              <div className={styles.inputWrapper}>
                <Input
                  id={`${id}_COVERED_AMOUNT`.replace(/ /g, '_').toLowerCase()}
                  value={selectedCoveredAmount}
                  onChange={(event) => {
                    handleChange(event, NetworkRowFields.COVERED_AMOUNT);
                  }}
                  onFocus={(event) => {
                    if (isEmpty(event.target.value)) {
                      handleChange(selectedCoverage, NetworkRowFields.COVERAGE);
                    }
                  }}
                  title={(amount && amount.toString()) || ''}
                  className={
                    isReviewProp &&
                    !(
                      selectedCoverage !== coverageOption.COVERED &&
                      !isOnlyCovered
                    )
                      ? styles.highlightedTextField
                      : highlightLLMValue
                      ? styles.highlightedLLMTextField
                      : ''
                  }
                />
                {highlightLLMValue && (
                  <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                )}
              </div>
            )}
          </Col>
        </>
      )}
      {networkObject && (
        <>
          {networkType === IN_NETWORK ? (
            <Col span={isFromDV ? 6 : 6}>
              <Component
                content='These non-default services were imported from the SBC. You can add them by clicking the "+" icon to the left of the service.'
                placement="rightTop"
                overlayClassName="nonDefaultPopover"
              >
                <div className={styles.inNetMargin}>In-Network</div>
              </Component>
            </Col>
          ) : (
            <Col span={isFromDV ? 6 : 6}>
              <Component
                content='These non-default services were imported from the SBC. You can add them by clicking the "+" icon to the left of the service.'
                placement="rightTop"
                overlayClassName="nonDefaultPopover"
              >
                <div className={styles.outNetMargin}>Out-of-Network</div>
              </Component>
            </Col>
          )}

          <>
            {(isCopayOrCoinsurance ||
              isNotCoveredOrNotApplicable ||
              currentSelectedCostType === CostType.OTHER) && (
              <Col span={5}>
                <Select
                  data-id={generateID(
                    `${formType}_${serviceTitle}_${networkType}_COST_TYPE`
                  )}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  value={selectedCostType}
                  onChange={(value: any) =>
                    handleChange(value, NetworkRowFields.COST_TYPE)
                  }
                >
                  {!isFromDV && networkType === IN_NETWORK && isDefault
                    ? inNetworkMedicalCostTypesState
                        .filter(
                          (value) =>
                            value.value === CostType.OTHER ||
                            value.value === costSharingPolicy
                        )
                        .map((value, index) => (
                          <Option value={value.value} key={index}>
                            {value.label}
                          </Option>
                        ))
                    : costTypeState
                        .filter(
                          (value) =>
                            value.value === CostType.OTHER ||
                            value.value === costSharingPolicy
                        )
                        .map((value, index) => (
                          <Option value={value.value} key={index}>
                            {value.label}
                          </Option>
                        ))}
                </Select>
              </Col>
            )}
            {!isCopayOrCoinsurance && !isNotCoveredOrNotApplicable && (
              <Col
                span={
                  isCopayOrCoinsurance ||
                  isNotCoveredOrNotApplicable ||
                  currentSelectedCostType
                    ? 9
                    : 14
                }
                className={isReviewProp ? styles.highlightField : ''}
              >
                <div className={styles.inputWrapper}>
                  <Input
                    value={info}
                    onChange={(value) =>
                      handleChange(value, NetworkRowFields.INFO)
                    }
                    className={`${styles.OtherDescription} ${
                      highlightLLMValue ? styles.highlightedLLMTextField : ''
                    }`}
                    title={info || ''}
                  />
                  {highlightLLMValue && (
                    <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                  )}
                </div>
              </Col>
            )}

            <>
              {(isCopayOrCoinsurance || isNotCoveredOrNotApplicable) && (
                <Col span={4}>
                  {selectedCostType === CostType.COPAY && (
                    <div className={styles.inputWrapper}>
                      <NumberFormatInput
                        data-id={generateID(
                          `${formType}_${serviceTitle}_${networkType}_AMOUNT`
                        )}
                        value={amount}
                        onChange={(event) =>
                          handleChange(
                            event,
                            NetworkRowFields.AMOUNT,
                            CostType.COPAY
                          )
                        }
                        onFocus={(event) => {
                          if (isEmpty(event.target.value)) {
                            handleChangeForCostType();
                          }
                        }}
                        thousandSeparator
                        allowNegative={false}
                        prefix="$"
                        decimalSeparator="."
                        fixedDecimalScale
                        decimalScale={2}
                        title={(amount && amount.toString()) || ''}
                        isInvalid={isValidAmount}
                        customClass={
                          isReviewProp
                            ? 'highlightField'
                            : highlightLLMValue
                            ? styles.highlightedLLMTextField
                            : ''
                        }
                      />
                      {highlightLLMValue && (
                        <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                      )}
                    </div>
                  )}
                  {selectedCostType === CostType.COINSURANCE && (
                    <div className={styles.inputWrapper}>
                      <NumberFormatInput
                        data-id={generateID(
                          `${formType}_${serviceTitle}_${networkType}_AMOUNT`
                        )}
                        value={amount}
                        onChange={(event) =>
                          handleChange(
                            event,
                            NetworkRowFields.AMOUNT,
                            CostType.COINSURANCE
                          )
                        }
                        onFocus={(event) => {
                          if (isEmpty(event.target.value)) {
                            handleChangeForCostType();
                          }
                        }}
                        thousandSeparator
                        allowNegative={false}
                        suffix="%"
                        decimalScale={0}
                        title={(amount && amount.toString()) || ''}
                        isAllowed={preventMoreThanHundredForPercentages}
                        isInvalid={isValidAmount}
                        customClass={
                          isReviewProp
                            ? 'highlightField'
                            : highlightLLMValue
                            ? styles.highlightedLLMTextField
                            : ''
                        }
                      />{' '}
                      {highlightLLMValue && (
                        <AIFeedbackRedFlag className={styles.aiFeedbackLabel} />
                      )}
                    </div>
                  )}
                  {(selectedCostType === CostType.NOT_COVERED ||
                    selectedCostType === CostType.NOT_APPLICABLE ||
                    !selectedCostType) && <NumberFormatInput disabled={true} />}
                </Col>
              )}

              {(isCopayOrCoinsurance || isNotCoveredOrNotApplicable) && (
                <Col span={5}>
                  <Select
                    data-id={generateID(
                      `${formType}_${serviceTitle}_${networkType}_FREQUENCY`
                    )}
                    allowClear={true}
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    value={
                      selectedCostType === CostType.COPAY
                        ? frequencyOptions.length === 1 &&
                          selectedFrequency !== undefined
                          ? selectedSingleFrequency == null
                            ? frequencyOptions[0].value
                            : selectedSingleFrequency
                          : selectedFrequency
                        : ''
                    }
                    onClear={() => {
                      setSelectedSingleFrequency('');
                    }}
                    onChange={(value: any) => {
                      frequencyOptions.length === 1 &&
                      value == frequencyOptions[0].value
                        ? setSelectedSingleFrequency(frequencyOptions[0].value)
                        : setSelectedSingleFrequency('');
                      handleChange(value, NetworkRowFields.FREQUENCY);
                    }}
                    disabled={selectedCostType !== CostType.COPAY}
                  >
                    {frequencyOptions.map((value, index) => (
                      <Option value={value.value} key={index}>
                        {value.label}
                      </Option>
                    ))}
                  </Select>
                </Col>
              )}
            </>
          </>

          {plan !== BenefitCategory.VISION.value ? (
            <Col span={isFromDV ? 4 : 1}>
              <Checkbox
                className={styles.checkboxTable}
                disabled={
                  selectedCostType === CostType.NOT_COVERED ||
                  selectedCostType === CostType.NOT_APPLICABLE
                }
                onChange={(e) => {
                  handleChange(
                    e.target.checked ? 'YES' : 'NO',
                    NetworkRowFields.DEDUCTIBLE_PRIOR
                  );
                  setCheckboxSelectedStatus(!checkboxSelectedStatus);
                }}
                checked={checkboxSelectedStatus}
              ></Checkbox>
            </Col>
          ) : (
            ''
          )}
        </>
      )}
    </Row>
  );
};

export default NetworkTypeRow;
