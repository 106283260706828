import React, { useState } from 'react';
import TabView from 'components/TabView/TabView';
import TabType from 'model/TabType';
import { FEATURE_KEYS } from 'constants/commonConstants';
import { useAppSelector } from 'hooks/redux';
import BrokerAIList from './BrokerAIList';
import EmployerAIList from './EmployerAIList';
import styles from './featureList.module.less';

const EmployerAIAssistantWrapper = () => {
  const [selectedTab, setSelectedTab] = useState<string>(
    FEATURE_KEYS.AI_ASSISTANT
  );

  const userRole = useAppSelector(
    (state) => state.auth.auth.appBootupInfo?.type
  );

  const isPlatformAdmin = userRole === 'PLATFORM_ADMIN';

  const getTabDataList = () => {
    const tabs: TabType[] = [];
    tabs.push({
      tabLabel: <b>AI Assistant (Employers)</b>,
      tabKey: FEATURE_KEYS.AI_ASSISTANT,
      tabData: (
        <div className={styles.tabItemWrapper}>
          <EmployerAIList isPlatformAdmin={isPlatformAdmin} />
        </div>
      ),
    } as TabType);
    tabs.push({
      tabLabel: <b>AI Assistant (Brokers)</b>,
      tabKey: FEATURE_KEYS.BROKER_AI_ASSISTANT,
      tabData: (
        <div className={styles.tabItemWrapper}>
          <BrokerAIList isPlatformAdmin={isPlatformAdmin} />
        </div>
      ),
    } as TabType);

    return tabs;
  };

  return (
    <div className={styles.selfReviewMainWrapper}>
      <div className={styles.headingWrapper}>
        <h1>AI Assistant</h1>
      </div>
      <TabView
        defaultTabKey={selectedTab}
        className={styles.tabsWrapper}
        tabOptions={getTabDataList()}
        onClick={() => {}}
        onChange={setSelectedTab}
      />
    </div>
  );
};

export default EmployerAIAssistantWrapper;
