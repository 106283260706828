import { Row, Col, Form, Input } from 'antd';
import { isEmpty } from 'lodash';
import {
  ChangeEvent,
  ChangeEventHandler,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
} from 'react';

import InputForm from 'components/InputForm/InputForm';
import NumberFormatInput, {
  preventMoreThanHundredForPercentages,
} from 'components/FormInput/NumberFormatInput';
import LabelWithTooltip from 'components/LabelWithTooltip/LabelWithTooltip';
import EmployerContributionDetails from 'modules/plans/taxAdvantaged/components/EmployerContributionDetails/EmployerContributionDetails';
import FrequencySelect from 'modules/plans/taxAdvantaged/components/FrequencySelect/FrequencySelect';
import { TaxAdvantagedAccountFormData } from 'modules/plans/taxAdvantaged/components/AddTaxAdvantagedPlan/AddTaxAdvantagedPlan';
import {
  EmployerContributionFrequency,
  TaxAdvantagedAccountPlanType,
  TaxAdvantageFields,
} from 'modules/plans/constants';
import { BenefitKind } from 'constants/commonConstants';

import styles from './hraHealthCareFSALimitedFSAPlanInfo.module.less';

const { TextArea } = Input;

type HRAHealthCareFSALimitedFSAPlanInfoProps = {
  isCancelButtonClickConfirm: boolean;
  setFormData: Function;
  form: any;
  formData: any;
  savingsPlanType?: string;
  otherEmployerContributionsTooltipContent: ReactNode;
  endOfYearPolicyTooltipContent: ReactNode;
  onNumberInputChange: ChangeEventHandler<HTMLInputElement>;
  changeInputStyle: Function;
  isFrequencyError: boolean;
  isEditMode?: boolean;
  tierCount: number;
  plan?: any;
  isReviewHighlight?: boolean;
  isReview?: boolean;
};

const HRAHealthCareFSALimitedFSAPlanInfo = forwardRef(
  (props: HRAHealthCareFSALimitedFSAPlanInfoProps, ref) => {
    const {
      isCancelButtonClickConfirm,
      setFormData,
      form,
      formData,
      savingsPlanType,
      otherEmployerContributionsTooltipContent,
      endOfYearPolicyTooltipContent,
      onNumberInputChange,
      changeInputStyle,
      isFrequencyError,
      isEditMode = false,
      tierCount = 0,
      plan,
      isReviewHighlight = false,
      isReview,
    } = props;
    const MDVPlans =
      formData &&
      [].concat(
        formData.selectedMedicalPlans ? formData.selectedMedicalPlans : [],
        formData.selectedDentalPlans ? formData.selectedDentalPlans : [],
        formData.selectedVisionPlans ? formData.selectedVisionPlans : []
      );

    const onInputChange = async (allValues: any, value: any) => {
      form.setFieldsValue(allValues);

      setFormData({ ...formData, ...allValues });
    };

    const setDefaultValues = useCallback(() => {
      if (savingsPlanType === TaxAdvantagedAccountPlanType.HRA.value) {
        form.setFieldsValue({
          frequency: EmployerContributionFrequency[2].label,
        });
        setFormData((prevData: any) => ({
          ...prevData,
          frequency: EmployerContributionFrequency[2].value,
        }));
      }
    }, [savingsPlanType, form, setFormData]);

    const setContributionsDisable = () => {
      if (!form.getFieldValue('frequency')) {
        form.setFieldsValue({
          employerEEContribution: '',
        });
        form.setFieldsValue({
          employerESContribution: '',
        });
        form.setFieldsValue({
          employerECContribution: '',
        });
        form.setFieldsValue({
          employerEFContribution: '',
        });
        return true;
      }
      return false;
    };

    useEffect(() => {
      if (isEditMode) {
        form.setFieldsValue({
          otherContribution: plan.employerContribution.otherContribution,
          frequency: plan.employerContribution.frequency,
          employerEEContribution:
            plan.employerContribution.employerEEContribution,
          employerESContribution:
            plan.employerContribution.employerESContribution,
          employerEFContribution:
            plan.employerContribution.employerEFContribution,
          employerECContribution:
            plan.employerContribution.employerECContribution,
          employerEE1Contribution:
            plan.employerContribution.employerEE1Contribution,
          employerEE2Contribution:
            plan.employerContribution.employerEE2Contribution,
          employerEE3Contribution:
            plan.employerContribution.employerEE3Contribution,
          employerEE4Contribution:
            plan.employerContribution.employerEE4Contribution,
          employerEE5Contribution:
            plan.employerContribution.employerEE5Contribution,
          employerEE6Contribution:
            plan.employerContribution.employerEE6Contribution,
          maxYearlyContribution: plan.maxYearlyContribution,
          hraUtilization: plan.hraUtilization,
        });
        setDefaultValues();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plan, isEditMode]);

    useEffect(() => {
      if (isCancelButtonClickConfirm) {
        form.resetFields();
      } else if (savingsPlanType === TaxAdvantagedAccountPlanType.HRA.value) {
        setDefaultValues();
      }
    }, [isCancelButtonClickConfirm, form, setDefaultValues, savingsPlanType]);

    useImperativeHandle(ref, () => ({
      resetForm() {
        form.resetFields();
      },

      resetOnTierChange() {
        setFormData((prevData: any) => ({
          ...prevData,
          employerEEContribution: undefined,
          employerESContribution: undefined,
          employerEFContribution: undefined,
          employerECContribution: undefined,
          employerEE1Contribution: undefined,
          employerEE2Contribution: undefined,
          employerEE3Contribution: undefined,
          employerEE4Contribution: undefined,
          employerEE5Contribution: undefined,
          employerEE6Contribution: undefined,
          maxYearlyContribution: undefined,
          otherContribution: undefined,
        }));

        form.resetFields([
          'employerEEContribution',
          'employerESContribution',
          'employerEFContribution',
          'employerECContribution',
          'employerEE1Contribution',
          'employerEE2Contribution',
          'employerEE3Contribution',
          'employerEE4Contribution',
          'employerEE5Contribution',
          'employerEE6Contribution',
          'maxYearlyContribution',
          'otherContribution',
        ]);
      },
    }));

    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      form.setFieldsValue({ [name]: value });

      setFormData((prevData: TaxAdvantagedAccountFormData) => ({
        ...prevData,
        [name]: value,
      }));
    };

    const handleStartEndSpaces = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      form.setFieldsValue({
        [name]: value.trimStart().trimEnd(),
      });

      setFormData((prevData: TaxAdvantagedAccountFormData) => ({
        ...prevData,
        [name]: value.trimStart().trimEnd(),
      }));
    };

    return (
      <>
        {isEmpty(MDVPlans) &&
        savingsPlanType === TaxAdvantagedAccountPlanType.HRA.value ? (
          <div className={styles.emptyPlansWrapper}>
            <p>Please select the associated plans for this HRA</p>
          </div>
        ) : (
          <InputForm
            form={form}
            onValuesChange={onInputChange}
            size={isEditMode ? 'small' : 'middle'}
          >
            <Row gutter={48}>
              <Col span={12}>
                <Form.Item
                  name="frequency"
                  label="Employer Allowance Frequency"
                  labelCol={{ span: 24 }}
                >
                  <FrequencySelect
                    disabled={
                      savingsPlanType === TaxAdvantagedAccountPlanType.HRA.value
                    }
                    isWarning={isFrequencyError}
                  />
                </Form.Item>
              </Col>

              <Col span={isEditMode ? 12 : 6}>
                <Form.Item
                  name="employerEEContribution"
                  label="Employer EE Allowance"
                  labelCol={{ span: 24 }}
                >
                  <NumberFormatInput
                    name={TaxAdvantageFields.EMPLOYER_EE_CONTRIBUTION}
                    prefix="$"
                    isBrokerScreen={true}
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    className={
                      changeInputStyle(
                        TaxAdvantageFields.EMPLOYER_EE_CONTRIBUTION
                      )
                        ? styles.warningInput
                        : isReviewHighlight
                        ? styles.highlightField
                        : ''
                    }
                    onChange={onNumberInputChange}
                    disabled={setContributionsDisable()}
                    allowNegative={false}
                  />
                </Form.Item>
              </Col>
              {!isEditMode && (
                <Col span={6}>
                  <Form.Item
                    name={
                      tierCount === 0
                        ? 'employerESContribution'
                        : 'employerEE1Contribution'
                    }
                    label={
                      tierCount === 0
                        ? 'Employer ES Allowance'
                        : 'Employer EE + 1 Allowance'
                    }
                    labelCol={{ span: 24 }}
                  >
                    <NumberFormatInput
                      prefix="$"
                      isBrokerScreen={true}
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      name={
                        tierCount === 0
                          ? TaxAdvantageFields.EMPLOYER_ES_CONTRIBUTION
                          : 'employerESContribution'
                      }
                      className={
                        changeInputStyle(
                          tierCount === 0
                            ? TaxAdvantageFields.EMPLOYER_ES_CONTRIBUTION
                            : 'employerESContribution'
                        )
                          ? styles.warningInput
                          : ''
                      }
                      onChange={onNumberInputChange}
                      disabled={setContributionsDisable()}
                      allowNegative={false}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={48}>
              <Col span={12}>
                {savingsPlanType === TaxAdvantagedAccountPlanType.HRA.value && (
                  <Form.Item
                    name="hraUtilization"
                    label="HRA Utilization Percentage"
                    labelCol={{ span: 24 }}
                  >
                    <NumberFormatInput
                      suffix="%"
                      isBrokerScreen={true}
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale={true}
                      allowNegative={false}
                      placeholder="%"
                      className={styles.percentage}
                      onChange={onNumberInputChange}
                      isAllowed={preventMoreThanHundredForPercentages}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col span={12}>
                <EmployerContributionDetails
                  planType={TaxAdvantagedAccountPlanType.HRA.value}
                  isEditMode={isEditMode || isReview}
                  changeInputStyle={changeInputStyle}
                  onNumberInputChange={onNumberInputChange}
                  setContributionsDisable={setContributionsDisable()}
                  tierCount={tierCount}
                  isReviewHighlight={isReviewHighlight}
                  benefitKind={BenefitKind.HRA.value}
                />
              </Col>
            </Row>
            <Row gutter={48}>
              <Col span={12}></Col>
              <Col span={12}>
                <Form.Item
                  name="otherContribution"
                  labelCol={{ span: 24 }}
                  label={
                    <Row>
                      <LabelWithTooltip
                        content={otherEmployerContributionsTooltipContent}
                        mandatoryField={false}
                        fieldText={'Other Employer Allowances'}
                      />
                    </Row>
                  }
                >
                  <TextArea
                    onChange={handleTextAreaChange}
                    name="otherContribution"
                    value={formData.otherContribution}
                    showCount
                    maxLength={200}
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    onBlur={handleStartEndSpaces}
                  />
                </Form.Item>
                <div
                  className={`${styles.characterCountText} ${
                    !isEditMode
                      ? styles.characterCountTextOther
                      : styles.characterCountTextOtherEdit
                  }`}
                >
                  Character Limit
                </div>
              </Col>
            </Row>
            {savingsPlanType !== TaxAdvantagedAccountPlanType.HRA.value && (
              <Row gutter={48}>
                <Col span={12}>
                  <Form.Item
                    name="maxYearlyContribution"
                    label="Max Yearly Contribution"
                    labelCol={{ span: 24 }}
                  >
                    <NumberFormatInput
                      prefix="$"
                      isBrokerScreen={true}
                      thousandSeparator
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="endOfYearPolicy"
                    labelCol={{ span: 24 }}
                    label={
                      <Row>
                        <LabelWithTooltip
                          content={endOfYearPolicyTooltipContent}
                          mandatoryField={false}
                          fieldText={'End of Year Policy'}
                        />
                      </Row>
                    }
                  >
                    <TextArea
                      onChange={handleTextAreaChange}
                      name="endOfYearPolicy"
                      value={formData.endOfYearPolicy}
                      showCount
                      maxLength={70}
                      onBlur={handleStartEndSpaces}
                    />
                    <div className={styles.characterCountText}>
                      Character Limit
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </InputForm>
        )}
      </>
    );
  }
);

HRAHealthCareFSALimitedFSAPlanInfo.displayName =
  'HRAHealthCareFSALimitedFSAPlanInfo';

export default HRAHealthCareFSALimitedFSAPlanInfo;
