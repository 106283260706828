import React from 'react';

import MedicalServiceUPWrapper from 'modules/plans/components/Services/ServiceUPWrapper';
import DentalServiceUPEditWrapper from 'modules/plans/dental/components/Services/ServicesUPEditWrapper';
import VisionServiceUPEditWrapper from 'modules/plans/vision/components/Services/ServicesUPEditWrapper';

import PanelSection from 'modules/plans/enums/PanelSection';
import { ReviewModalFragment } from 'modules/renewals/types/renewalsTypes';
import MedicalPlan from 'model/plans/MedicalPlan';
import { DentalPlan } from 'model/plans/DentalPlan';
import { VisionPlan } from 'model/plans/VisionPlan';

type Props = ReviewModalFragment;

const Services = ({ setState, state, type }: Props) => {
  const getBasicInfoUPWrapper = () => {
    const componentMap: {
      // eslint-disable-next-line
      [key in NonNullable<typeof type>]?: React.ReactNode;
    } = {
      MEDICAL: (
        <MedicalServiceUPWrapper
          type={PanelSection.SERVICES}
          medicalPlan={state as MedicalPlan}
          setMedicalPlan={setState}
        />
      ),
      DENTAL: (
        <DentalServiceUPEditWrapper
          type={PanelSection.SERVICES}
          dentalPlan={state as DentalPlan}
          setDentalPlan={setState}
        />
      ),
      VISION: (
        <VisionServiceUPEditWrapper
          type={PanelSection.SERVICES}
          visionPlan={state as VisionPlan}
          setVisionPlan={setState}
        />
      ),
    };

    return type !== undefined && componentMap[type];
  };

  return <div>{getBasicInfoUPWrapper()}</div>;
};

export default Services;
