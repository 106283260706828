import { FC, useEffect, useState } from 'react';
import { Col, Menu, Modal, Popover, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import BreadcrumbNavigation from 'components/Breadcrumb/BreadcrumbNavigation';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useNavContext } from 'hooks/useNavContext';
import { usePermitIf } from 'hooks/usePermitIf';
import ContentContainer from 'containers/ContentContainer/ContentContainer';
import DataTable from 'components/DataTable/DataTable';
import {
  createRenderColumns,
  DataColumn,
  getRowActions,
} from 'components/DataTable/DataColumn';
import PrimaryContact from 'components/Contact/Contact';
import FullScreenModal from 'components/FullScreenModal/FullScreenModal';
import AddBrokerLocation from 'modules/brokers/pages/AddBrokerLocation/AddBrokerLocation';
import {
  getBrokerSummary,
  getEmployerList,
} from 'modules/brokers/slices/brokerDashboardSlice';
import {
  clearLocationFind,
  findLocationById,
} from 'modules/brokers/slices/brokerLocationSlice';
import { brokerDashBoardPermittedTypes } from 'constants/permittedConstants';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import AddBenguideModal from 'modules/employers/components/AddBenguideModal/AddBenguideModal';

import BrokerLocation from 'model/BrokerLocation';
import { loginTypes } from 'constants/authConstants';
import {
  EMP_LIMIT_REACH_POPOVER_MSG,
  FEATURE_KEYS,
  IndividualSubTypes,
} from 'constants/commonConstants';
import PageActionButton from 'components/buttons/PageActionButton/PageActionButton';
import { Location } from 'model/broker/Location';
import IssueMgtDashboardPane from 'modules/brokers/components/IssueMgtDashboardPane/IssueMgtDashboardPane';
import AddEmployerModal from 'modules/employers/components/AddEmployerModal/AddEmployerModal';
import {
  useGetBrokerDashboardIssuesQuery,
  useGetDashboardBrokerQuery,
} from 'modules/brokers/slices/dashboardBrokerSlice';
import RenewalInfo from 'modules/brokers/pages/RenewalInformation/RenewalInfo';
import { useGetNavigationsQuery } from 'api/navigationSlice';
import Terms from 'modules/auth/components/Terms/Terms';

import styles from './brokerDashboard.module.less';

const menu = (
  type: string,
  locationId: string,
  onEditLocation: (locationId: string) => void
) => (
  <Menu>
    <Menu.Item key="edit" onClick={() => onEditLocation(locationId)}>
      Edit Location
    </Menu.Item>
  </Menu>
);

const getEmployerCount = (
  employerCount: number,
  locationId: string,
  onClick: (employerCount: number, locationId: string) => void
) => {
  const employerString = employerCount === 1 ? 'Employer' : 'Employers';
  return (
    <>
      <OverflowPopover>
        <a
          className={employerCount > 0 ? styles.count : styles.noCount}
          onClick={() => onClick(employerCount, locationId)}
        >{`${employerCount} ${employerString}`}</a>
      </OverflowPopover>
    </>
  );
};

const getMyEmployerCount = (
  employerCount: number,
  locationId: string,
  isMyEmployers: boolean,
  onClick: (
    locationId: string,
    isMyEmployers: boolean,
    employerCount: number
  ) => void
) => {
  const employerString = employerCount === 1 ? 'Employer' : 'Employers';
  return (
    <>
      <OverflowPopover>
        <a
          className={employerCount > 0 ? styles.count : styles.noCount}
          onClick={() => onClick(locationId, isMyEmployers, employerCount)}
        >{`${employerCount} ${employerString}`}</a>
      </OverflowPopover>
    </>
  );
};

const BrokerDashboard: FC = () => {
  const params = useParams();
  const { data: DashboardStatus } = useGetDashboardBrokerQuery({
    brokerId: params.brokerId,
  });
  const [isTermsModalVisible, setIsTermsModalVisible] =
    useState<boolean>(false);

  const {
    data: brokerIssueDashData,
    isLoading: brokerIssueIsLoading,
    isSuccess: brokerIssueIsSuccess,
    isError: brokerIssueIsError,
    refetch: refetchIssueData,
  } = useGetBrokerDashboardIssuesQuery({
    brokerId: params.brokerId,
  });

  useEffect(() => {
    refetchIssueData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchIssueData]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isBenguideModalOpen, setIsBenguideModalOpen] =
    useState<boolean>(false);
  const [isLocationModalOpen, setIsLocationModalOpen] =
    useState<boolean>(false);
  const [visibleConfirmationClosePopup, setVisibleConfirmationClosePopup] =
    useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );
  const appBootInfo = useAppSelector((state) => state.auth.auth.appBootupInfo);
  const { brokerObj: brokerDetails, getBrokerDetailsInProgress } =
    useAppSelector((state) => state.brokers.brokerDashboard);
  const brokerLocationStatus = useAppSelector(
    (state) => state.brokers.brokerLocations
  );

  const dispatch = useAppDispatch();
  const { brokerId } = useNavContext();

  const contactName =
    brokerDetails && brokerDetails.primaryContact
      ? brokerDetails.primaryContact?.contactName
      : '';
  const avatarUrl =
    brokerDetails && brokerDetails.primaryContact
      ? brokerDetails.primaryContact?.avatarUrl
      : '';
  const email =
    brokerDetails && brokerDetails.primaryContact
      ? brokerDetails.primaryContact?.email
      : '';
  const title =
    brokerDetails && brokerDetails.primaryContact
      ? brokerDetails.primaryContact?.title
      : '';

  const locations = brokerDetails && brokerDetails.locations;

  const employerLimitReached = brokerDetails?.employerLimitReached;

  const securedPrimaryContact = usePermitIf(
    <PrimaryContact
      contactName={contactName}
      avatarUrl={avatarUrl}
      email={email}
      title={title}
      contactTitle="Primary Contact"
    />,
    brokerDashBoardPermittedTypes,
    []
  );

  const navigation = { name: 'Brokers', link: '/brokers' };
  const navigations: { name: string; link: string }[] = [];
  navigations[0] = navigation;

  const SecuredBreadcrumb = usePermitIf(
    <BreadcrumbNavigation breadCrumbData={navigations} />,
    brokerDashBoardPermittedTypes,
    []
  );

  const { data } = useGetNavigationsQuery({
    brokerId: brokerId || null,
    employerId: null,
  });

  const { features = [] } = data || {};

  const featureNamesToCheck = [FEATURE_KEYS.RENEWAL];

  const isRenewalsEnabled = features.some(
    (feature: any) =>
      feature?.enabled && featureNamesToCheck.includes(feature?.feature)
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (brokerId) {
      dispatch(getBrokerSummary(brokerId));
    }
  }, [dispatch, brokerId, isLocationModalOpen]);

  useEffect(() => {
    if (brokerId) {
      dispatch(getEmployerList(brokerId));
    }
  }, [dispatch, brokerId]);

  useEffect(() => {
    if (
      !brokerLocationStatus.locationFindInProgress &&
      !isEmpty(brokerLocationStatus.brokerLocationObj) &&
      brokerLocationStatus.error === null
    ) {
      if (!isModalOpen) {
        setSelectedLocation(brokerLocationStatus.brokerLocationObj);
        setIsLocationModalOpen(true);
        setVisibleConfirmationClosePopup(false);
        dispatch(clearLocationFind());
      }
    }
  }, [
    dispatch,
    brokerLocationStatus?.locationFindInProgress,
    brokerLocationStatus?.brokerLocationObj,
    brokerLocationStatus?.error,
    isModalOpen,
  ]);

  useEffect(() => {
    if (!appBootInfo?.isTermsAccepted) {
      setIsTermsModalVisible(true);
    }
  }, [appBootInfo]);

  const goToAllEmployers = (employerCount: number, id: string) => {
    if (employerCount > 0) {
      navigate('/employers', {
        state: { loggedInUserBroker: brokerId, locationId: id },
      });
    }
  };

  const goToMyEmployers = (
    id: string,
    isMyEmployers: boolean,
    employerCount: number
  ) => {
    if (employerCount > 0) {
      navigate(`/brokers/${brokerId}/employers`, {
        state: {
          locationId: id,
          userId: isMyEmployers ? appBootInfo?.individualId : '',
        },
      });
    }
  };

  const handleEdit = (locationId: string) => {
    dispatch(findLocationById(locationId));
  };

  const toggleConfirmationPopup = () => {
    setVisibleConfirmationClosePopup(!visibleConfirmationClosePopup);
  };

  const closeLocationModal = () => {
    toggleConfirmationPopup();
  };

  const confirmCloseModal = () => {
    setIsLocationModalOpen(false);
  };

  const isTechAdmin = (): boolean => {
    return appBootInfo?.type === loginTypes.platform;
  };

  const closeEmployerModal = (employerModal: boolean) => {
    setIsModalOpen(employerModal);
    if (brokerId) {
      dispatch(getBrokerSummary(brokerId));
    }
  };

  const brokerTableColumns: DataColumn[] = [
    {
      title: 'LOCATIONS',
      dataIndex: 'locationName',
      key: 'locationName',
      width: '35%',
      ellipsis: {
        showTitle: false,
      },
      render: (colData) => {
        return <OverflowPopover>{colData}</OverflowPopover>;
      },
    },
    {
      title: 'EMPLOYERS',
      dataIndex: 'employerCount',
      key: 'employerCount',
      width: '35%',
      ellipsis: {
        showTitle: false,
      },
      render: (count, record: BrokerLocation) => {
        if (appBootInfo?.type === loginTypes.platform) {
          return getEmployerCount(count, record.id || '', () =>
            goToAllEmployers(count, record.id || '')
          );
        } else {
          return getMyEmployerCount(count, record.id || '', false, () =>
            goToMyEmployers(record.id || '', false, count)
          );
        }
      },
    },
    {
      title: 'MY EMPLOYERS',
      dataIndex: 'myEmployerCount',
      key: 'myEmployerCount',
      width: '30%',
      ellipsis: {
        showTitle: false,
      },
      render: (count, record: BrokerLocation) =>
        getMyEmployerCount(count, record.id || '', true, () =>
          goToMyEmployers(record.id || '', true, count)
        ),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      render: (count, record: BrokerLocation) =>
        getRowActions(
          menu(appBootInfo?.type || '', record.id || '', () =>
            handleEdit(record.id || '')
          )
        ),
      align: 'center',
    },
  ];

  const selectedColumns =
    appBootInfo?.type === 'PLATFORM_ADMIN'
      ? createRenderColumns(
          brokerTableColumns.filter(
            (column) => column.key !== 'myEmployerCount'
          )
        )
      : appBootInfo?.individualSubType === IndividualSubTypes.BROKER_USER
      ? createRenderColumns(
          brokerTableColumns.filter((column) => column.key !== 'actions')
        )
      : createRenderColumns(brokerTableColumns);

  if (getBrokerDetailsInProgress) {
    return <></>;
  }

  return (
    <ContentContainer>
      <Row className={styles.brokerDashboard}>
        <Col lg={18}>
          <Row className={styles.breadcrumb}>{SecuredBreadcrumb}</Row>
          <Row>
            <Col>
              <Row
                className={`${styles.dashboard} ${
                  appBootInfo?.type === 'PLATFORM_ADMIN'
                    ? styles.dashboardUserSuper
                    : styles.dashboardUser
                }`}
              >
                Dashboard
              </Row>
              <Row
                className={`${styles.dashboard} ${
                  appBootInfo?.type === 'PLATFORM_ADMIN'
                    ? styles.dashboardMessageSuper
                    : styles.dashboardMessage
                }`}
              ></Row>
            </Col>
          </Row>
        </Col>

        {DashboardStatus?.enabledIssueLog ? (
          <Col lg={18}>
            <IssueMgtDashboardPane
              isTechAdmin={isTechAdmin()}
              isBrokerLevel={true}
              dataSet={{
                data: brokerIssueIsSuccess ? brokerIssueDashData : {},
                isLoading: brokerIssueIsLoading,
                isSuccess: brokerIssueIsSuccess,
                isError: brokerIssueIsError,
              }}
            />
          </Col>
        ) : (
          <></>
        )}

        <Col lg={3} className={styles.quickLink}>
          <h2 className={styles.quickLinkTitle}>Quick Links</h2>
          {appBootInfo?.individualSubType !==
            IndividualSubTypes.BROKER_USER && (
            <>
              <Popover
                content={EMP_LIMIT_REACH_POPOVER_MSG}
                overlayClassName="popoverInner employerListLimitDashboardPopover"
                visible={employerLimitReached ? undefined : false}
                placement="right"
                align={{
                  offset: [-435, 5],
                }}
              >
                <div
                  className={`${
                    employerLimitReached ? styles.employerLimitReached : ''
                  }`}
                >
                  <PageActionButton
                    type="primary"
                    onClick={() => setIsModalOpen(!employerLimitReached)}
                    className={styles.actionButton}
                  >
                    Add Employer
                  </PageActionButton>
                </div>
              </Popover>
              <br />
            </>
          )}
          <PageActionButton
            type="primary"
            onClick={() => {
              navigate(`/brokers/${brokerId}/basicInfo`);
            }}
            className={styles.actionButton}
          >
            Manage Broker Admins
          </PageActionButton>
          <div className={styles.primaryContants}>{securedPrimaryContact}</div>
        </Col>
        <br />
      </Row>
      <Row
        className={
          DashboardStatus?.enabledIssueLog
            ? styles.issLogNonMargin
            : isTechAdmin()
            ? styles.issueLogMarginTech
            : styles.issueLogMarginBroker
        }
      >
        <Col lg={18}>
          <h3 className={styles.summaryTable}>
            {DashboardStatus?.enabledRenewals && isRenewalsEnabled && (
              <RenewalInfo issueLogEnabled={DashboardStatus?.enabledIssueLog} />
            )}
          </h3>
        </Col>
      </Row>
      <Row>
        {DashboardStatus?.enabledDbgs && (
          <Col lg={18}>
            <h3 className={styles.summaryTable}>Brokerage Summary</h3>
            <div className={styles.summaryTable}>
              <DataTable data={locations} columns={selectedColumns} />
            </div>
          </Col>
        )}
      </Row>
      <AddEmployerModal
        isModalOpen={isModalOpen}
        setIsModalOpen={closeEmployerModal}
      />
      {brokerId && (
        <AddBenguideModal
          isModalOpen={isBenguideModalOpen}
          setIsModalOpen={() => setIsBenguideModalOpen(false)}
          brokerId={brokerId}
        />
      )}
      <Row></Row>

      <FullScreenModal
        visible={isLocationModalOpen}
        onCancel={closeLocationModal}
        footer={false}
        title={location ? 'Edit Location' : 'Add Broker Location'}
      >
        <AddBrokerLocation
          location={selectedLocation}
          visible={isLocationModalOpen}
          confirmCloseModal={confirmCloseModal}
          visibleConfirmationClosePopup={visibleConfirmationClosePopup}
          toggleConfirmationPopup={toggleConfirmationPopup}
        />
      </FullScreenModal>
      <Modal
        width={600}
        maskClosable={false}
        closable={false}
        wrapClassName={styles.modalMain}
        visible={isTermsModalVisible}
        onCancel={() => {}}
        footer={null}
      >
        <Terms
          setIsTermsModalOpen={setIsTermsModalVisible}
          individualId={appBootInfo?.individualId}
        />
      </Modal>
    </ContentContainer>
  );
};

export default BrokerDashboard;
