import { createSlice, Dispatch } from '@reduxjs/toolkit';

import * as documentsService from 'modules/documents/services/DocumentsService';

const initialState = {
  data: [],
  inProgress: false,
  uploadError: false,
  downloadFolderError: false,
};

const documentSlice = createSlice({
  name: 'documentSlice',
  initialState,
  reducers: {
    fetchDocumentsStarted: (state) => {
      state.inProgress = true;
    },
    fetchDocumentsCompleted: (state, { payload }) => {
      state.data = payload;
      state.inProgress = false;
    },
    fetchDocumentsFailed: (state) => {
      state.inProgress = false;
    },
    downloadDocumentStarted: (state) => {
      state.inProgress = true;
    },
    downloadDocumentCompleted: (state) => {
      state.inProgress = false;
    },
    donwloadDocumentFailed: (state) => {
      state.inProgress = false;
    },
    deleteDocumentStarted: (state) => {
      state.inProgress = true;
    },
    deleteDocumentCompleted: (state) => {
      state.inProgress = false;
    },
    deleteDocumentFailed: (state) => {
      state.inProgress = false;
    },
    createFolderStarted: (state) => {
      state.inProgress = true;
    },
    createFolderCompleted: (state) => {
      state.inProgress = false;
    },
    createFolderFailed: (state) => {
      state.inProgress = false;
    },
    renameFolderStarted: (state) => {
      state.inProgress = true;
    },
    renameFolderCompleted: (state) => {
      state.inProgress = false;
    },
    renameFolderFailed: (state) => {
      state.inProgress = false;
    },
    uploadDocumentStarted: (state) => {
      state.inProgress = true;
      state.uploadError = false;
    },
    uploadDocumentCompleted: (state) => {
      state.inProgress = false;
    },
    uploadDocumentFailed: (state) => {
      state.inProgress = false;
      state.uploadError = true;
    },
    downloadFolderStarted: (state) => {
      state.inProgress = true;
      state.downloadFolderError = false;
    },
    downloadFolderCompleted: (state) => {
      state.inProgress = false;
    },
    downloadFolderFailed: (state) => {
      state.inProgress = false;
      state.downloadFolderError = true;
    },
    clearDocumentState: (state) => {
      state.data = [];
      state.inProgress = false;
    },
  },
});

export const {
  fetchDocumentsStarted,
  fetchDocumentsCompleted,
  fetchDocumentsFailed,
  downloadDocumentStarted,
  downloadDocumentCompleted,
  donwloadDocumentFailed,
  deleteDocumentStarted,
  deleteDocumentCompleted,
  deleteDocumentFailed,
  createFolderCompleted,
  createFolderFailed,
  createFolderStarted,
  renameFolderCompleted,
  renameFolderFailed,
  renameFolderStarted,
  uploadDocumentCompleted,
  uploadDocumentFailed,
  uploadDocumentStarted,
  downloadFolderCompleted,
  downloadFolderFailed,
  downloadFolderStarted,
  clearDocumentState,
} = documentSlice.actions;

export default documentSlice.reducer;

export const fetchDocuments = (
  employerId: string,
  planYearId: string,
  documentId: string | null,
  searchQuery: string | null
) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchDocumentsStarted());
    documentsService
      .getDocuments(employerId, planYearId, documentId, searchQuery)
      .then(({ data }) => {
        dispatch(fetchDocumentsCompleted(data));
      })
      .catch(() => {
        dispatch(fetchDocumentsFailed());
      });
  };
};

export const downloadDocument = (documentId: string, onFailed: Function) => {
  return async (dispatch: Dispatch) => {
    dispatch(downloadDocumentStarted());
    documentsService
      .validateDocument(documentId)
      .then(({ data }) => {
        if (data) {
          const url = documentsService.getDownloadUrl(documentId);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.display = 'none';
          a.href = url;
          a.download = data.name;
          a.click();
          window.URL.revokeObjectURL(url);
          dispatch(downloadDocumentCompleted());
        } else {
          dispatch(donwloadDocumentFailed());
          onFailed();
        }
      })
      .catch(() => {
        dispatch(donwloadDocumentFailed());
        onFailed();
      });
  };
};

export const deleteDocument = (documentId: string, callBack: Function) => {
  return async (dispatch: Dispatch) => {
    dispatch(deleteDocumentStarted());
    documentsService
      .deleteDocument(documentId)
      .then(() => {
        dispatch(deleteDocumentCompleted());
        callBack();
      })
      .catch(() => {
        dispatch(deleteDocumentFailed());
      });
  };
};

export const createFolder = (
  employerId: string,
  parentDocumentId: string,
  folderName: string,
  callBack: Function
) => {
  return async (dispatch: Dispatch) => {
    dispatch(createFolderStarted());
    documentsService
      .createFolder(employerId, parentDocumentId, folderName)
      .then(() => {
        dispatch(createFolderCompleted());
        callBack();
      })
      .catch(() => {
        dispatch(createFolderFailed());
      });
  };
};

export const renameFolder = (
  documentId: string,
  parentDocumentId: string,
  folderName: string,
  callBack: Function
) => {
  return async (dispatch: Dispatch) => {
    dispatch(renameFolderStarted());
    documentsService
      .renameFolder(documentId, parentDocumentId, folderName)
      .then(() => {
        dispatch(renameFolderCompleted());
        callBack();
      })
      .catch(() => {
        dispatch(renameFolderFailed());
      });
  };
};

export const getDocumentAvailability = (
  employerId: string,
  parentDocumentId: string,
  fileName: string,
  onDocumentExist: Function
) => {
  return async () => {
    documentsService
      .getDocumentAvailability(employerId, parentDocumentId, fileName)
      .then(({ data }) => {
        onDocumentExist(data.documentExist);
      });
  };
};

export const uploadDocument = (
  employerId: string,
  parentDocumentId: string,
  isDuplicate: boolean,
  file: any,
  onUploadComplete: Function
) => {
  return async (dispatch: Dispatch) => {
    dispatch(uploadDocumentStarted());
    documentsService
      .uploadDocument(employerId, parentDocumentId, isDuplicate, file)
      .then(({ data }) => {
        dispatch(uploadDocumentCompleted());
        onUploadComplete();
      })
      .catch((res) => {
        dispatch(uploadDocumentFailed());
      });
  };
};

export const downloadFolder = (
  employerId: string,
  documentId: string,
  callBack: Function
) => {
  return async (dispatch: Dispatch) => {
    dispatch(downloadFolderStarted());
    documentsService
      .downloadFolder(employerId, documentId)
      .then(({ data }) => {
        dispatch(downloadFolderCompleted());
        callBack();
      })
      .catch((res) => {
        dispatch(downloadFolderFailed());
      });
  };
};
