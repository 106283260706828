import { Col, Form, Input, Row, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Title from 'antd/lib/typography/Title';
import { FC, useEffect, useState } from 'react';

import CancelButton from 'components/buttons/formButtons/CancelButton/CancelButton';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import SelectOptions from 'components/SelectOptions/SelectOptions';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getNonMdvAdditionalServices } from 'modules/plans/utils';
import { addLifePlanService } from 'modules/plans/slices/lifePlanSlice';
import { NonMDVServiceType } from 'model/plans/NonMDVServiceType';
import { getDisplayName } from 'model/plans/ServiceType';
import styles from './addNonMDVCustomModal.module.less';

const { Option } = Select;

type AddNonMDVCustomModalProps = {
  displayedServices?: any[];
  visible: boolean;
  onClose: Function;
  planTypeName: string;
  benefitKind: string;
  onPlanChange?: Function;
};
const AddNonMDVCustomModal: FC<AddNonMDVCustomModalProps> = (
  props: AddNonMDVCustomModalProps
) => {
  const { visible, onClose, planTypeName, benefitKind } = props;

  const { lifePlanServiceList } = useAppSelector(
    (state) => state.plan.lifePlan
  );

  const [serviceType, setServiceType] = useState<string | null>(null);
  const [description, setDescription] = useState<string>('');
  const [serviceList, setServiceList] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setServiceList(
      getNonMdvAdditionalServices(benefitKind, lifePlanServiceList)
    );
  }, [lifePlanServiceList, benefitKind]);

  const resetFields = () => {
    form.resetFields();
    setServiceType(null);
    setDescription('');
  };

  const validateForm = async () => {
    try {
      await form.validateFields();
    } catch (errorInfo) {
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    const valid = await validateForm();

    if (!valid) {
      return;
    }

    const data: NonMDVServiceType = {
      service: serviceType ?? '',
      description,
    };
    dispatch(addLifePlanService(data));

    resetFields();
    onClose();
  };

  const onCancel = () => {
    resetFields();
    onClose();
  };

  const modalTitle = (
    <div>
      <div>
        <Title level={3} className={styles.center}>
          Add {planTypeName} Service
        </Title>
      </div>
    </div>
  );

  const footer = (
    <div className={styles.footerWrapper}>
      <CancelButton
        onClick={onCancel}
        className={styles.cancelButton}
        withBorder={true}
      >
        Cancel
      </CancelButton>
      <SubmitButton
        key="submit"
        type="primary"
        onClick={onSubmit}
        className={styles.saveButton}
      >
        Add
      </SubmitButton>
    </div>
  );

  const [form] = Form.useForm();

  return (
    <Modal
      wrapClassName={styles.modalWrapper}
      title={modalTitle}
      width={740}
      footer={footer}
      visible={visible}
      onOk={() => onSubmit()}
      onCancel={() => onCancel()}
      maskClosable={false}
      closable={false}
    >
      <Form form={form}>
        <Row>
          <Col span={5}>
            <div className={styles.fieldLabel}>
              <strong>Select Service</strong>
            </div>
          </Col>
          <Col span={19}>
            <Form.Item
              name="Service Type"
              className={styles.planName}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please select a service',
                },
              ]}
            >
              <SelectOptions
                value={serviceType}
                onChange={(value) => {
                  setServiceType(value);
                  form.resetFields(['Service Name']);
                }}
              >
                {serviceList.map((service) => (
                  <Option value={service} key={service}>
                    {getDisplayName(service)}
                  </Option>
                ))}
              </SelectOptions>
            </Form.Item>
          </Col>
        </Row>
        <Row className={styles.titleRow}>
          <Col span={5}></Col>
          <Col span={15} className={styles.descriptionArea}>
            Description
          </Col>
          <Col span={5}></Col>
          <Col span={19}>
            <Input
              value={description}
              onChange={(value) => {
                setDescription(value.target.value);
              }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddNonMDVCustomModal;
