import { FC, useEffect } from 'react';

import { Modal, Row, Spin, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import SubmitButton from 'components/buttons/formButtons/SubmitButton/SubmitButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { DataColumn } from 'components/DataTable/DataColumn';
import OverflowPopover from 'components/OverflowPopover/OverflowPopover';
import { BenefitGuideStatus } from 'modules/plans/constants';
import { getBenefitGuidesBasicInfo } from 'modules/plans/slices/dbgInfoSlice';
import { previewBenguide } from 'modules/benefitGuide/slices/benguideSlice';

import styles from './associatedBenguideModal.module.less';

type AssociatedBenguideModalProps = {
  benefitGuideIdList: any[];
  planYearName: string;
  modalVisible: boolean;
  setModalVisible: Function;
  header?: string;
  description?: string;
};

const AssociatedBenguideModal: FC<AssociatedBenguideModalProps> = (
  props: AssociatedBenguideModalProps
) => {
  const {
    benefitGuideIdList,
    planYearName,
    modalVisible,
    setModalVisible,
    header,
    description,
  } = props;

  const dispatch = useAppDispatch();
  const { dbgBasicInfoList, inProgress } = useAppSelector(
    (state) => state.plan.dgbInfo
  );

  useEffect(() => {
    if (benefitGuideIdList) {
      dispatch(getBenefitGuidesBasicInfo(benefitGuideIdList));
    }
  }, [dispatch, benefitGuideIdList]);

  const dataColumns: DataColumn[] = [
    {
      title: 'GUIDE NAME',
      dataIndex: 'name',
      key: 'name',
      width: '70%',
      ellipsis: {
        showTitle: false,
      },
      render: (plan) => renderGuide(plan),
    },

    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: '30%',
      ellipsis: {
        showTitle: false,
      },
      render: (status) => (
        <OverflowPopover popoverContent={status}>{status}</OverflowPopover>
      ),
    },
  ];

  const getStatus = (status: string) => {
    if (status) {
      return BenefitGuideStatus?.map((item) => {
        if (item.value === status) {
          return item.label;
        }
        return '';
      });
    }
  };

  const convertGuidesForTableRender = (benguides: any[]): any[] => {
    return (
      benguides?.map((benguide) => ({
        name: benguide,
        status: getStatus(benguide.status),
      })) || []
    );
  };

  const renderGuide = (benguide: any) => {
    return (
      <div className={styles.linkBtnWrapper}>
        <LinkButton
          onClick={() => {
            dispatch(previewBenguide(benguide.id));
          }}
        >
          <OverflowPopover popoverContent={benguide.name} maxWidth="100%">
            {benguide.name}
          </OverflowPopover>
        </LinkButton>
      </div>
    );
  };

  const footerComponent = (
    <Row>
      <SubmitButton
        type="primary"
        className={styles.cancelButton}
        onClick={() => {
          setModalVisible(false);
        }}
      >
        Close
      </SubmitButton>
    </Row>
  );

  const titleComponent = () => {
    return (
      <div>
        {`Associated Benefits Guides (${benefitGuideIdList?.length})`}

        <div className={styles.planYearName}>
          <Row>{planYearName} </Row>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        wrapClassName={styles.modalWrapper}
        title={header ? header : titleComponent()}
        width={550}
        centered
        visible={modalVisible}
        footer={footerComponent}
      >
        {description && (
          <p className={styles.modalDescription}>{description}</p>
        )}
        {!inProgress ? (
          <Table
            dataSource={convertGuidesForTableRender(dbgBasicInfoList)}
            columns={dataColumns}
            className={styles.benguideTableWrapper}
            pagination={false}
          />
        ) : (
          <Spin className={styles.spinWrapper} />
        )}
      </Modal>
    </div>
  );
};

export default AssociatedBenguideModal;
